/**
 * ******************************************************
 * Copyright (C) 2015-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * reconnectApplicationSessionsCtrl.js--
 *
 *      middleware to reconnect application sessions by
 *      sending multiple get-application-session-connection,
 *      will be deleted when JSCDK refactor by just using
 *      callback. It currently is used to solve the naming
 *      conflict and separate the logic out.
 *
 *      the repeating codes will be totally removed when JSCDK
 *      refactor.
 *
 *      can't be called when last request is still being processed.
 */

import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import GetApplicationSessionConnectionHandler from "./getApplicationSessionConnectionHandler";
import { ErrorObj, ReconnectSuccessAction } from "../jscdk-interface";

export default function ReconnectAllApplicationSessionsCtrl() {
   // member variables below
   this.messageName = "reconnect-application-sessions";
   this.messageText = "reconnect-application-sessions";
   this.responseTag = "reconnect-application-sessions";
   this.presetInfo = {};
}

// inherits MessageHandler prototype
ReconnectAllApplicationSessionsCtrl.prototype = new MessageHandler();
// constructor
ReconnectAllApplicationSessionsCtrl.constructor = ReconnectAllApplicationSessionsCtrl;

/**
 * public, standard exit point
 *
 * serve as the callback to handle all of the getApplicationSessionConnect XML
 * responses. will return a merged response to UI layer.
 */
ReconnectAllApplicationSessionsCtrl.prototype.dealWithResponses = function (dataList, self) {
   let i,
      data,
      router = globalArray["router"],
      errorObj,
      failIdList = [],
      successList = [];

   if (!dataList || !Array.isArray(dataList) || dataList.length <= 0) {
      Logger.error("the response list is invalid");
      return;
   }
   // here assume all error are of the same type, so only store the last one
   for (i = 0; i < dataList.length; i++) {
      data = dataList[i];
      if (data.success) {
         successList.push(data);
      } else {
         failIdList.push(data.sessionId);
         errorObj = data.error;
      }
   }
   if (failIdList.length === 0) {
      // reconnect application sessions success
      self.pushReconnectSuccessToUser(dataList);
   } else {
      // this is for reusing pushErrorToUser, will be brief after jscdk
      // refactor, didn't rename error now
      errorObj.errorDetails = { failedSessionIdList: failIdList, successSessionList: successList };
      self.content = {
         error: {
            reconnectError: errorObj
         }
      };
      router.pushErrorToUser(self);
   }
};

/**
 * private, util
 * get application session list from the parent handler, i.e. the
 * getLaunchItemHandler
 */
ReconnectAllApplicationSessionsCtrl.prototype.getApplicationSessions = function () {
   let getLaunchItemsObject;

   getLaunchItemsObject = this.parentHandler;
   if (!getLaunchItemsObject) {
      getLaunchItemsObject = globalArray[this.messageName + "get-launch-items"];
   }
   if (!!getLaunchItemsObject && !!getLaunchItemsObject.content["parsedResult"]) {
      return getLaunchItemsObject.content["parsedResult"]["application-sessions"];
   }
   return null;
};

/**
 * private
 *
 * @param targetIdList out: of array
 *      will be filled with application session Ids
 * @param applicationSessions in: of Object
 *      formatted as part of get-launch-item response
 */
ReconnectAllApplicationSessionsCtrl.prototype.getAllValidAppSession = function (applicationSessions) {
   let key,
      applicationSessionId,
      sessionOriginId,
      sessionSessionId,
      idList = [],
      originIdList = [],
      excludedId = this.excludedSessionId;

   for (key in applicationSessions) {
      if (applicationSessions.hasOwnProperty(key)) {
         applicationSessionId = applicationSessions[key].id;
         sessionOriginId = applicationSessions[key]["origin-id"];
         sessionSessionId = applicationSessions[key]["session-id"];
         if (
            !!applicationSessionId &&
            applicationSessionId.trim() !== "" &&
            sessionOriginId !== excludedId &&
            (!sessionSessionId || sessionSessionId !== excludedId)
         ) {
            idList.push(applicationSessionId);
            originIdList.push(sessionOriginId);
         }
      }
   }

   return { idList: idList, originIdList: originIdList };
};

/**
 * private
 *
 * pick out those invalid ids in the targetIdList, and return it, the
 * definition of invalid is that the application session ID is requested but
 * not found in the valid application session list that returned from broker or
 * only consist of blanks.
 *
 * @param targetIdList in: of Array
 *     the request application session list
 * @param applicationSessions in: of Object
 *     the active application session list get from the broker.
 */
ReconnectAllApplicationSessionsCtrl.prototype.getInvalidIdList = function (targetIdList, applicationSessions) {
   let key,
      applicationSessionId,
      invalidRequestedSessionIdList = [],
      i,
      targetId,
      targetSessionIdExist;

   for (i = 0; i < targetIdList.length; i++) {
      targetId = targetIdList[i];
      targetSessionIdExist = false;
      for (key in applicationSessions) {
         if (applicationSessions.hasOwnProperty(key)) {
            applicationSessionId = applicationSessions[key].id;
            if (!!applicationSessionId && applicationSessionId.trim() !== "" && targetId === applicationSessionId) {
               targetSessionIdExist = true;
               break;
            }
         }
      }
      if (!targetSessionIdExist) {
         invalidRequestedSessionIdList.push(targetId);
      }
   }
   return invalidRequestedSessionIdList;
};

/**
 * private
 */
ReconnectAllApplicationSessionsCtrl.prototype.pushReconnectFailToUser = function (errorDetails) {
   let errorObj = {} as ErrorObj,
      router = globalArray["router"],
      errorMessage;

   errorObj.name = "JSCDK_ERROR_RECONNECT_POOL_FAILED";
   errorMessage = util._("JSCDK_ERROR_APP_SESSION_CANT_RECONNECTED");
   router.pushSelfDefinedError(errorObj, errorMessage, errorDetails);
   this.setState(StateEnum.FAIL);
};

/**
 * private
 */
ReconnectAllApplicationSessionsCtrl.prototype.pushReconnectSuccessToUser = function (successSessionContent) {
   const reconnectSuccessAction = {} as ReconnectSuccessAction;

   reconnectSuccessAction.name = "ReconnectAppSession";
   reconnectSuccessAction.content = successSessionContent;
   JSCDKSetUI(JSON.stringify(reconnectSuccessAction));
   this.setState(StateEnum.DONE);
};

/**
 * private
 * return corresponding Origin Id in a list for each applicationSession that in
 * the targetIdList(input param). the searching process is done with the input
 * param applicationSessions
 */
ReconnectAllApplicationSessionsCtrl.prototype.getOriginIdList = function (targetIdList, applicationSessions) {
   let key,
      applicationSessionId,
      originIdList = [],
      i,
      targetId;

   for (i = 0; i < targetIdList.length; i++) {
      targetId = targetIdList[i];
      for (key in applicationSessions) {
         if (applicationSessions.hasOwnProperty(key)) {
            applicationSessionId = applicationSessions[key].id;
            if (targetId === applicationSessionId) {
               originIdList[i] = applicationSessions[key]["origin-id"];
               break;
            }
         }
      }
   }
   return originIdList;
};

/**
 * public, the only entry point
 *
 * Triggered by the parent handler
 * invoke getApplicationSessionConnection
 */
ReconnectAllApplicationSessionsCtrl.prototype.triggerExecution = function () {
   let invalidIdList,
      allValidAppSessionInfo,
      targetIds,
      targetOriginIds,
      targetId,
      targetOriginId,
      handlerList,
      router = globalArray["router"],
      AppSessionReconnector,
      i,
      infoCombiner;

   MessageHandler.prototype.triggerExecution.apply(this);

   targetIds = this.targetSessionIds;
   if (targetIds.length === 0) {
      allValidAppSessionInfo = this.getAllValidAppSession(this.getApplicationSessions());
      targetIds = allValidAppSessionInfo.idList;
      targetOriginIds = allValidAppSessionInfo.originIdList;
      if (targetIds.length === 0) {
         // still no targets, then just treat as success
         this.pushReconnectSuccessToUser([]);
         return;
      }
   } else {
      invalidIdList = this.getInvalidIdList(targetIds, this.getApplicationSessions());
      if (!!invalidIdList && invalidIdList.length > 0) {
         this.pushReconnectFailToUser({ notExistingSessionIdList: invalidIdList });
         return;
      }
      targetOriginIds = this.getOriginIdList(targetIds, this.getApplicationSessions());
   }

   // sending reconnect sessions
   infoCombiner = new util.InfoCombiner(targetIds.length, this.dealWithResponses, this);
   for (i = 0; i < targetIds.length; i++) {
      targetId = targetIds[i];
      targetOriginId = targetOriginIds[i];

      // get AppSessionReconnector
      AppSessionReconnector = globalArray["get-application-session-connection" + targetId];
      if (!AppSessionReconnector) {
         AppSessionReconnector = new GetApplicationSessionConnectionHandler();
         globalArray[AppSessionReconnector.messageName + targetId] = AppSessionReconnector;
         globalArray[AppSessionReconnector.responseTag + targetId] = AppSessionReconnector;
      } else {
         AppSessionReconnector.resetData();
      }

      // config and send request
      AppSessionReconnector.setCallbackFunction(infoCombiner.onInfoReceived);
      AppSessionReconnector.setOriginId(targetOriginId);
      AppSessionReconnector.setRequestXML(
         targetId,
         this.presetInfo.protocol,
         this.presetInfo.environmentInfo,
         this.presetInfo.disconnectAll
      );
      handlerList = AppSessionReconnector.composeHandlerList();
      router.postMessage(handlerList);
   }
   this.setState(StateEnum.DONE);
};
