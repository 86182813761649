/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * setViewURICommand.js --
 *
 * Pass the View URI to JSCDK.
 *
 */

import UrlHandler from "../controllers/urlHandler";
import util from "../util";
import { globalArray } from "../jscdkClient";

export default function SetViewURICommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param setViewURIAction[in]
 *        - its property 'method' has the value 'SetViewURI'
 */
SetViewURICommand.prototype.execute = function (setViewURIAction) {
   let urlHandler = util.getObject(globalArray, "url-handler"),
      url = setViewURIAction ? setViewURIAction.queryString : "";

   if (!urlHandler) {
      urlHandler = new UrlHandler();
      globalArray[urlHandler.name] = urlHandler;
   }

   urlHandler.handleUrlParams(url);
};
