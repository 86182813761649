<div class="ui-content-area login-bg">
   <div class="container">
      <div class="ui-center-panel">
         <div class="ui-pattern-logo">
            <img [src]="this.imageAsset.logo" alt="{{logoAltText}}">
         </div>
         <div>
            <div id="warningContainer" ngClass="{'ui-browser-mobile': isMobileBrowser}" *ngIf="!onProcessing">
               <div class="ui-browser-warning ui-browser-warning-unsupport">
                     <p *ngIf="!isWinARM" class="top-warning"><span class="ui-warning-logo"></span>{{"browser_compatibility_warn" | i18nv2}}</p>
                     <p *ngIf="isWinARM" class="top-warning"><span class="ui-warning-logo"></span>{{"cpu_compatibility_warn" | i18nv2}}</p>
                     <p class="top-warning" id="cookieWarning" [hidden]="!showCookieWarning">{{"browser_compatibility_cookie" | i18nv2}}</p>
               </div>
               <div class="portal-ui-list">
                  <div class="portal-list-item pull-left">
                     <a id="nativeClient" [href]="nativeInstallerLink" title="">
                        <div class="portal-native-client"></div>
                        <div class="portal-list-title">{{"install_native_client" | i18nv2}}</div>
                     </a>
                  </div>
                  <div class="middle-line"></div>
                  <div class="portal-list-item pull-right" [hidden]="!continueAllowed">
                     <a (click)="goNext()" id="browserWarningContinue">
                        <div class="portal-list-title">{{"browser_compatibility_continue" | i18nv2}}</div>
                        <div class="portal-list-title">{{"browser_compatibility_not_recommended" | i18nv2}}</div>
                     </a>
                  </div>
               </div>
            </div>
            <div *ngIf="onProcessing">
               <div class="loginLoadingTextForWaiting">{{'dialog_waiting_text' | i18nv2}}</div>
               <loading-image-animation [showLoading]="true"></loading-image-animation>
            </div>
         </div>
      </div>
   </div>
   <div class="bottom-logo">
   </div>
</div>