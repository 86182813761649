/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, AfterViewInit, OnDestroy, Optional } from "@angular/core";
import { ModalDialogService, Modal } from "../dialog.service";
import { Subscription } from "rxjs";
import { NgbModal, NgbModalRef, NgbActiveModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AboutDialogComponent } from "../../aboutdialog/about-dialog.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { AbstractModalComponent } from "../abstract-modal.component";
import { AbstractModalComponentWithCheckBox } from "../abstract-modal.component.with-checkbox";
import { CancelConfirmDialogComponent } from "../cancel-confirm-dialog/cancel-confirm-dialog.component";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { ClipFTHelpDialogComponent } from "../../../desktop/common/clipboard-filetransfer-help-dialog/clipboard-filetransfer-help-dialog.component";
import Logger from "../../../../core/libs/logger";
import { Ws1Service } from "../../service/ws1.service";
import { SDKService } from "../../../../chrome-client/SDK/sdk-service";
import { ReauthDialogComponent } from "../../../desktop/re-auth/reauth-dialog.component";
import { SettingDialogComponent } from "../../setting-dialog/setting-dialog.component";
import { SettingMultiLaunchDialogComponent } from "../../multi-launch-setting/setting-multi-launch-dialog.component";
import { SettingMultiMonitorDialogComponent } from "../../multi-monitor-setting/setting-multi-monitor-dialog.component";
import { SettingCDRDialogComponent } from "../../../launcher/cdr-setting/setting-cdr-dialog.component";
import { SettingAutoUSBDialogComponent } from "../../../launcher/auto-usb-setting/setting-auto-usb-dialog.component";
import { PreSettingDialogComponent } from "../../../launcher/common/pre-setting/pre-setting-dialog.component";
import { SettingTrustedAppsDialogComponent } from "../../../launcher/trusted-apps-setting/setting-trusted-apps-dialog.component";
import { DebugConfigurationDialogComponent } from "../../../launcher/debug-configuration-setting/debug-configuration-dialog.component";
import { clientUtil, BusEvent, EventBusService, TranslateService } from "@html-core";
import { CancelConfirmDialogWithCheckBoxComponent } from "../cancel-confirm-dialog-with-checkbox/cancel-confirm-dialog-with-checkbox.component";
import { FileAssociationDefaultAppComponent } from "../../../../chrome-client/file-association/file-association-default-app/file-association-default-app-dialog.component";
import { CancelConfirmDialogWithInputTextComponent } from "../cancel-confirm-dialog-with-input-text/cancel-confirm-dialog-with-input-text.component";
import { GestureHelpComponent } from "../../gesture-help/gesture-help.component";
import { KeyBoardSettingComponent } from "../../keyboard-setting/keyboard-setting.component";
import { SettingRtavDialogComponent } from "../../rtav-setting/setting-rtav-dialog.component";

@Component({
   selector: "custom-modal-dialog",
   templateUrl: "./custom-modal-dialog.component.html"
})
export class CustomModalDialogComponent implements OnDestroy, AfterViewInit {
   private dialogSubscription: Subscription;
   public modalRef: NgbModalRef = null;

   constructor(
      private modalDialogService: ModalDialogService,
      private modalService: NgbModal,
      public activeModal: NgbActiveModal,
      private translateService: TranslateService,
      private ws1Service: Ws1Service,
      @Optional()
      private sdkService: SDKService,
      private eventBusService: EventBusService
   ) {}

   ngAfterViewInit() {
      this.dialogSubscription = this.modalDialogService.subscribe((msg: Modal.ModalOption) => {
         switch (msg.name) {
            case Modal.ABOUT_DIALOG:
               this.modalRef = this.modalService.open(AboutDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.GESTURE_HELP: {
               this.modalRef = this.modalService.open(GestureHelpComponent, msg as NgbModalOptions);
               const gestureHelp: Modal.GestureHelpModalOption = msg as Modal.GestureHelpModalOption;
               this.modalRef.componentInstance.currentTouchMode = gestureHelp.currentTouchMode;
               break;
            }
            case Modal.REAUTH_DIALOG:
               this.modalRef = this.modalService.open(ReauthDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.PRE_SETTING_DIALOG:
               this.modalRef = this.modalService.open(PreSettingDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.KEYBOARD_SETTING:
               this.modalRef = this.modalService.open(KeyBoardSettingComponent, msg as NgbModalOptions);
               break;
            case Modal.SETTING_DIALOG: {
               this.modalRef = this.modalService.open(SettingDialogComponent, msg as NgbModalOptions);
               const setting: Modal.SettingModalOption = msg as Modal.SettingModalOption;
               if (setting.callback) {
                  this.modalRef.result.then(
                     () => {
                        setting.callback();
                     },
                     () => {
                        setting.callback();
                     }
                  );
               }
               break;
            }
            case Modal.SETTING_MULTI_LAUNCH_DIALOG:
               this.modalRef = this.modalService.open(SettingMultiLaunchDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.SETTING_RTAV_DIALOG:
               this.modalRef = this.modalService.open(SettingRtavDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.SETTING_MULTI_MONITOR_DIALOG:
               this.modalRef = this.modalService.open(SettingMultiMonitorDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.SETTING_CDR_DIALOG:
               this.modalRef = this.modalService.open(SettingCDRDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.SETTING_AUTO_USB_DIALOG:
               this.modalRef = this.modalService.open(SettingAutoUSBDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.SETTING_TRUSTED_APPS_DIALOG:
               this.modalRef = this.modalService.open(SettingTrustedAppsDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.SETTING_FA_DIALOG:
               this.modalRef = this.modalService.open(FileAssociationDefaultAppComponent, msg as NgbModalOptions);
               break;
            case Modal.DEBUG_CONFIG_DIALOG:
               this.modalRef = this.modalService.open(DebugConfigurationDialogComponent, msg as NgbModalOptions);
               break;
            case Modal.CONFIRM_DIALOG: {
               this.modalRef = this.modalService.open(ConfirmDialogComponent, msg as NgbModalOptions);
               const confirm: Modal.ConfirmModalOption = msg as Modal.ConfirmModalOption;
               if (confirm.option.data.hasOwnProperty("title")) {
                  this.modalRef.componentInstance.title = confirm.option.data["title"];
               } else {
                  this.modalRef.componentInstance.title = this.translateService._T(confirm.option.data["titleKey"]);
               }
               if (confirm.option.data.hasOwnProperty("content")) {
                  this.modalRef.componentInstance.message = confirm.option.data["content"];
               } else {
                  this.modalRef.componentInstance.message = this.translateService._T(confirm.option.data["contentKey"]);
               }
               if (confirm.option.data.showWarning) {
                  this.modalRef.componentInstance.showWarning = confirm.option.data.showWarning;
               } else {
                  this.modalRef.componentInstance.showWarning = false;
               }
               if (confirm.option.data.isTransferringFile) {
                  this.modalRef.componentInstance.isTransferringFile = confirm.option.data.isTransferringFile;
               } else {
                  this.modalRef.componentInstance.isTransferringFile = false;
               }
               if (confirm.option.data.isLogOffDesktop) {
                  this.modalRef.componentInstance.isLogOffDesktop = confirm.option.data.isLogOffDesktop;
               } else {
                  this.modalRef.componentInstance.isLogOffDesktop = false;
               }
               if (confirm.option.data.hasOwnProperty("buttonLabelConfirm")) {
                  this.modalRef.componentInstance.buttonLabelConfirm = confirm.option.data["buttonLabelConfirm"];
               } else {
                  this.modalRef.componentInstance.buttonLabelConfirm = this.translateService._T(
                     confirm.option.data["buttonLabelConfirmKey"]
                  );
               }
               if (confirm.option.data.hasOwnProperty("buttonLabelCancel")) {
                  this.modalRef.componentInstance.buttonLabelCancel = confirm.option.data["buttonLabelCancel"];
               } else {
                  this.modalRef.componentInstance.buttonLabelCancel = this.translateService._T(
                     confirm.option.data["buttonLabelCancelKey"]
                  );
               }

               this.modalRef.result.then(
                  (result) => {
                     if (result === AbstractModalComponent.BTN_OK) {
                        confirm.option.callbacks.confirm();
                     } else if (result == AbstractModalComponent.BTN_CANCEl && confirm.option.callbacks.cancel) {
                        confirm.option.callbacks.cancel();
                     }
                  },
                  () => {
                     if (confirm.option.callbacks.cancel) {
                        confirm.option.callbacks.cancel();
                     }
                  }
               );
               break;
            }
            case Modal.CANCEL_CONFIRM_DIALOG: {
               this.modalRef = this.modalService.open(CancelConfirmDialogComponent, msg as NgbModalOptions);
               const cancelConfirm: Modal.CancelConfirmModalOption = msg as Modal.CancelConfirmModalOption;
               if (cancelConfirm.option.data["title"]) {
                  Logger.debug("cancelConfirm has title");
               }
               if (cancelConfirm.option.data.hasOwnProperty("title")) {
                  this.modalRef.componentInstance.title = cancelConfirm.option.data["title"];
               } else {
                  this.modalRef.componentInstance.title = this.translateService._T(
                     cancelConfirm.option.data["titleKey"]
                  );
               }
               if (cancelConfirm.option.data.hasOwnProperty("content")) {
                  this.modalRef.componentInstance.message = cancelConfirm.option.data["content"];
               } else {
                  this.modalRef.componentInstance.message = this.translateService._T(
                     cancelConfirm.option.data["contentKey"]
                  );
               }
               if (cancelConfirm.option.data.showWarning) {
                  this.modalRef.componentInstance.showWarning = cancelConfirm.option.data.showWarning;
               } else {
                  this.modalRef.componentInstance.showWarning = false;
               }
               if (cancelConfirm.option.data.isTransferringFile) {
                  this.modalRef.componentInstance.isTransferringFile = cancelConfirm.option.data.isTransferringFile;
               } else {
                  this.modalRef.componentInstance.isTransferringFile = false;
               }
               if (cancelConfirm.option.data.hasOwnProperty("buttonLabelConfirm")) {
                  this.modalRef.componentInstance.buttonLabelConfirm = cancelConfirm.option.data["buttonLabelConfirm"];
               } else {
                  this.modalRef.componentInstance.buttonLabelConfirm = this.translateService._T(
                     cancelConfirm.option.data["buttonLabelConfirmKey"]
                  );
               }
               if (cancelConfirm.option.data.hasOwnProperty("buttonLabelCancel")) {
                  this.modalRef.componentInstance.buttonLabelCancel = cancelConfirm.option.data["buttonLabelCancel"];
               } else {
                  this.modalRef.componentInstance.buttonLabelCancel = this.translateService._T(
                     cancelConfirm.option.data["buttonLabelCancelKey"]
                  );
               }
               this.modalRef.result.then(
                  (result) => {
                     if (result === AbstractModalComponent.BTN_OK) {
                        cancelConfirm.option.callbacks.confirm();
                     } else if (result == AbstractModalComponent.BTN_CANCEl && cancelConfirm.option.callbacks.cancel) {
                        cancelConfirm.option.callbacks.cancel();
                     }
                  },
                  () => {
                     if (cancelConfirm.option.callbacks.cancel) {
                        cancelConfirm.option.callbacks.cancel();
                     }
                  }
               );
               break;
            }
            case Modal.CANCEL_CONFIRM_DIALOG_WITH_CHECKBOX: {
               this.modalRef = this.modalService.open(CancelConfirmDialogWithCheckBoxComponent, msg as NgbModalOptions);
               const cancelConfirmWithCheckBox: Modal.CancelConfirmModalOptionWithCheckBox =
                  msg as Modal.CancelConfirmModalOptionWithCheckBox;

               if (cancelConfirmWithCheckBox.option.data.hasOwnProperty("titleKey")) {
                  this.modalRef.componentInstance.title = this.translateService._T(
                     cancelConfirmWithCheckBox.option.data["titleKey"]
                  );
               }
               if (cancelConfirmWithCheckBox.option.data.hasOwnProperty("contentKey")) {
                  this.modalRef.componentInstance.message = this.translateService._T(
                     cancelConfirmWithCheckBox.option.data["contentKey"]
                  );
               }
               if (cancelConfirmWithCheckBox.option.data.hasOwnProperty("checkboxTitleKey")) {
                  this.modalRef.componentInstance.checkboxTitle = this.translateService._T(
                     cancelConfirmWithCheckBox.option.data["checkboxTitleKey"]
                  );
               }
               if (cancelConfirmWithCheckBox.option.data.hasOwnProperty("isCheckBoxCheckedKey")) {
                  this.modalRef.componentInstance.isCheckBoxChecked =
                     cancelConfirmWithCheckBox.option.data["isCheckBoxCheckedKey"] === "true";
               }
               if (cancelConfirmWithCheckBox.option.data.hasOwnProperty("buttonLabelConfirmKey")) {
                  this.modalRef.componentInstance.buttonLabelConfirm = this.translateService._T(
                     cancelConfirmWithCheckBox.option.data["buttonLabelConfirmKey"]
                  );
               }
               if (cancelConfirmWithCheckBox.option.data.hasOwnProperty("buttonLabelCancelKey")) {
                  this.modalRef.componentInstance.buttonLabelCancel = this.translateService._T(
                     cancelConfirmWithCheckBox.option.data["buttonLabelCancelKey"]
                  );
               }
               if (cancelConfirmWithCheckBox.option.data.hasOwnProperty("showCancelButton")) {
                  this.modalRef.componentInstance.showCancelButton =
                     cancelConfirmWithCheckBox.option.data["showCancelButton"];
               } else {
                  this.modalRef.componentInstance.showCancelButton = true;
               }
               this.modalRef.result.then(
                  (result) => {
                     Logger.info("Result returned by cancelConfirmWithCheckBox is " + JSON.stringify(result));
                     if (result.BUTTON_KEY === AbstractModalComponentWithCheckBox.BTN_OK) {
                        cancelConfirmWithCheckBox.option.callbacks.setCheckBoxValue(result.CHECKBOX_KEY);
                        cancelConfirmWithCheckBox.option.callbacks.confirm();
                     } else if (result.BUTTON_KEY == AbstractModalComponent.BTN_CANCEl) {
                        cancelConfirmWithCheckBox.option.callbacks.setCheckBoxValue(result.CHECKBOX_KEY);
                        cancelConfirmWithCheckBox.option.callbacks.cancel();
                     }
                  },
                  /* dialog closed, without using Allow or Deny button,
                   * Keep 'do not show this dialog' to false,
                   * as user is yet to make any selection.
                   */
                  () => {
                     Logger.info("Dialog cancelConfirmWithCheckBox closed abnormally.");
                     cancelConfirmWithCheckBox.option.callbacks.setCheckBoxValue(false);
                     cancelConfirmWithCheckBox.option.callbacks.cancel();
                  }
               );
               break;
            }
            case Modal.CANCEL_CONFIRM_DIALOG_WITH_INPUTTEXT: {
               this.modalRef = this.modalService.open(
                  CancelConfirmDialogWithInputTextComponent,
                  msg as NgbModalOptions
               );
               const cancelConfirmWithEditText: Modal.CancelConfirmModalOptionWithInputText =
                  msg as Modal.CancelConfirmModalOptionWithInputText;

               this.modalRef.componentInstance.title = cancelConfirmWithEditText.option.data.hasOwnProperty("title")
                  ? cancelConfirmWithEditText.option.data["title"]
                  : this.translateService._T(cancelConfirmWithEditText.option.data["titleKey"]);

               this.modalRef.componentInstance.message = cancelConfirmWithEditText.option.data.hasOwnProperty("content")
                  ? cancelConfirmWithEditText.option.data["content"]
                  : this.translateService._T(cancelConfirmWithEditText.option.data["contentKey"]);

               this.modalRef.componentInstance.inputTextData = cancelConfirmWithEditText.option.data.hasOwnProperty(
                  "inputTextData"
               )
                  ? cancelConfirmWithEditText.option.data["inputTextData"]
                  : " ";

               this.modalRef.componentInstance.buttonLabelConfirm =
                  cancelConfirmWithEditText.option.data.hasOwnProperty("buttonLabelConfirm")
                     ? cancelConfirmWithEditText.option.data["buttonLabelConfirm"]
                     : this.translateService._T(cancelConfirmWithEditText.option.data["buttonLabelConfirmKey"]);

               this.modalRef.componentInstance.buttonLabelCancel = cancelConfirmWithEditText.option.data.hasOwnProperty(
                  "buttonLabelCancel"
               )
                  ? cancelConfirmWithEditText.option.data.hasOwnProperty("buttonLabelCancel")
                  : this.translateService._T(cancelConfirmWithEditText.option.data["buttonLabelCancelKey"]);

               this.modalRef.result.then((result) => {
                  Logger.info("Result returned by cancelConfirmWithEditText is " + JSON.stringify(result));
                  if (result.BUTTON_KEY === AbstractModalComponent.BTN_OK) {
                     cancelConfirmWithEditText.option.callbacks.confirm(result);
                  } else if (result.BUTTON_KEY == AbstractModalComponent.BTN_CANCEl) {
                     cancelConfirmWithEditText.option.callbacks.cancel();
                  }
               });
               break;
            }
            case Modal.ERROR_DIALOG: {
               this.modalRef = this.modalService.open(ErrorDialogComponent, msg as NgbModalOptions);
               const error: Modal.ErrorModalOption = msg as Modal.ErrorModalOption;
               if (error.option.data.hasOwnProperty("title")) {
                  this.modalRef.componentInstance.title = error.option.data["title"];
               } else {
                  this.modalRef.componentInstance.title = this.translateService._T(error.option.data["titleKey"]);
               }
               if (error.option.data.hasOwnProperty("content")) {
                  this.modalRef.componentInstance.errorMessage = error.option.data["content"];
               } else if (error.option.data.hasOwnProperty("contentSubstitutionsKey")) {
                  this.modalRef.componentInstance.errorMessage = this.translateService._T(
                     error.option.data["contentKey"],
                     error.option.data["contentSubstitutionsKey"]
                  );
               } else {
                  this.modalRef.componentInstance.errorMessage = this.translateService._T(
                     error.option.data["contentKey"]
                  );
               }
               if (error.option.data.hasOwnProperty("showCopy")) {
                  this.modalRef.componentInstance.showCopy = error.option.data["showCopy"];
               }
               if (error.option.data.hasOwnProperty("buttonLabelConfirm")) {
                  this.modalRef.componentInstance.buttonLabel = error.option.data["buttonLabelConfirm"];
               }
               this.modalRef.result.then(
                  (result) => {
                     if (result === AbstractModalComponent.BTN_OK && error.option.callbacks) {
                        error.option.callbacks.confirm();
                     }
                  },
                  () => {
                     if (error.option.callbacks) {
                        error.option.callbacks.confirm();
                     }
                  }
               );
               break;
            }
            case Modal.CLIP_FT_DIALOG: {
               this.modalRef = this.modalService.open(ClipFTHelpDialogComponent, msg as NgbModalOptions);
               const clipFTHelp: Modal.ClipFTHelpModalOption = msg as Modal.ClipFTHelpModalOption;
               this.modalRef.componentInstance.title = clipFTHelp.option.data.title;
               this.modalRef.componentInstance.msg = clipFTHelp.option.data.msg;
               this.modalRef.componentInstance.modKey = clipFTHelp.option.data.modKey;
               this.modalRef.result.then(
                  () => {
                     clipFTHelp.option.callback();
                  },
                  () => {
                     clipFTHelp.option.callback();
                  }
               );
               break;
            }
         }
         this.modalRef.componentInstance.windowClass = msg.windowClass;
         this.modalDialogService.dialogMap.set(msg.id, this.modalRef);
         if (
            this.ws1Service?.isWS1Mode() === true ||
            (this.sdkService?.isHideClientEnabled() === true && this.sdkService?.isShowErrorEnabled() === true)
         ) {
            clientUtil.showClientForWS1orSDK("Opening dialog.");
            if (window?.chromeClient) {
               window.chromeClient.isWS1CloseClient = true;
            }
         }
         if (clientUtil.isChromeClient()) {
            const window = chrome.app.window.current();
            if (window) {
               window.focus();
            }
         }
         this.modalRef.result.then(
            () => {
               this.modalDialogService.dialogMap.delete(msg.id);
               if (this.ws1Service?.isWS1Mode() === true || this.sdkService?.isHideClientEnabled() === true) {
                  /* bug 2989880 Open the display settings then click OK or Cancel button
                     should not dismiss the whole client setting */
                  if (this.modalDialogService.dialogMap.size === 0) {
                     clientUtil.hideClientForWS1orSDK("Closing dialog");
                  }
                  window.chromeClient.isWS1CloseClient = false;
               }
            },
            (reason) => {
               if (reason === AbstractModalComponent.CLOSE_REASON.ClickBackDrop) {
                  Logger.info("dialog closed by clicked backdrop.");
               } else if (reason === AbstractModalComponent.CLOSE_REASON.ExitByESC) {
                  Logger.info("dialog closed by pressed ESC key.");
               }
               if (msg.name === Modal.SETTING_DIALOG) {
                  this.eventBusService.dispatch(new BusEvent.UpdateSettingDialogStatus(true));
               }
               this.modalDialogService.dialogMap.delete(msg.id);
            }
         );
         if (this.modalRef && this.modalRef.componentInstance.eventEntry) {
            this.modalRef.componentInstance.eventEntry.subscribe((msg?: string) => {
               if (this.modalRef.componentInstance) {
                  // close modal dialog when there only one opened modal dialog
                  this.modalRef.close(msg);
               } else {
                  /*
                   * if there are two or more opened modal dialog, after close last opened modal dialog,
                   * this.modalRef.componentInstance will be undefined. to close following modal dialog,
                   * we should get this.modalRef from modalDialogService.dialogMap
                   */
                  const dialogRefs = this.modalDialogService.dialogMap.values();
                  const dialogRefsArr = Array.from(dialogRefs);
                  const dialogRef = dialogRefsArr.pop();
                  if (dialogRef) {
                     dialogRef.close(msg);
                  }
               }
            });
         }
      });
   }

   ngOnDestroy() {
      if (this.dialogSubscription) {
         this.dialogSubscription.unsubscribe();
      }
   }
}
