/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import Logger from "../../../core/libs/logger";
import { clientUtil } from "@html-core";
import { WmksService } from "../common/wmks.service";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { Injectable } from "@angular/core";
import { EventBusService, BusEvent } from "@html-core";
@Injectable({
   providedIn: "root"
})
export class H264FreezeFixService {
   constructor(
      private wmksService: WmksService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private eventBusService: EventBusService
   ) {
      this.init();
   }

   public init = () => {
      document.addEventListener("visibilitychange", () => {
         if (document.hidden) {
            Logger.debug("Browser tab is hidden");
         } else {
            Logger.debug("Browser tab is visible");
            this.updateResolutionForH264();
         }
      });

      // Fix bug 3115675
      this.eventBusService.listen(BusEvent.FrameBufferInited.MSG_TYPE).subscribe(() => {
         if (this.htmlRemoteSessionManager.isCurrentSessionApp()) {
            // Call this function directly without timer is too early, which can't fix the issue.
            setTimeout(() => {
               this.updateResolutionForH264();
            }, 2 * 1000);
         }
      });
   };

   /**
    * To fix bug https://bugzilla.eng.vmware.com/show_bug.cgi?id=3015330
    * Using Horizon HTML based client we are noticing intermittent freezes
    * where the session locks up and is unresponsive. It requires resizing the desktop browser to workaround the issue.
    * Just call resize is not enough, need to update resolution.
    * Use a workaround to first add 2px to update, then change back to screensize.
    */
   public updateResolutionForH264 = () => {
      this.wmksService.whenNotInMultimon(() => {
         const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
         if (currentSession && currentSession.option.enableMP4) {
            const screenSize = clientUtil.getWindowResolution();
            Logger.info("update resolution for h264 issue");
            screenSize[0] = screenSize[0] - 2;
            screenSize[1] = screenSize[1] - 2;
            currentSession.updateResolution(screenSize, true);
            setTimeout(() => {
               screenSize[0] = screenSize[0] + 2;
               screenSize[1] = screenSize[1] + 2;
               currentSession.updateResolution(screenSize, true);
            }, 500);
         }
      });
   };
}
