/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Remote session manager is used for both launcher and desktop module.
 * It focuses on the management work of desktop/app sessions. As a result, it
 * doesn't care about how the session is launched, which should be the
 * responsibility of JSCDK and JSCDK caller.
 * Another job remote session manager will do is, sending notification to other
 * modules about the session change event. However, this job is not done by
 * the manager itself. Here we have a event service called
 * 'RemoteSessionEventService'. Analogously, there will be
 * 'clipboardEventService', 'printEventService'. There is no logic code in these
 * services. This is because we want to decouple the code as much as possible.
 * For example, 'unity-service.js' cares about the session change event, but it
 * doesn't have to control how the session is changed. So we don't have to
 * inject session manager into it. Only injecting RemoteSessionEventService
 * is enough for it.
 *
 * Another thing is, session manager should not inject blastWmks directly,
 * because blastWmks has lots of other dependencies, which could bring more
 * complex dependency management. Instead, session instance should be passed in.
 * With less dependencies, re-usage of code/module can be much easier.
 *
 */

import { clientUtil } from "@html-core";
import { BlastWmks } from "../../desktop/common/blast-wmks.service";
import { SessionDataService } from "../service/session-data.service";

export abstract class CommonRemoteSessionManager {
   protected _sessionMap: Map<string, BlastWmks>;
   protected _currentSession: BlastWmks;
   private _sessionDataService: SessionDataService;

   constructor(sessionDataService: SessionDataService) {
      this._sessionMap = new Map<string, BlastWmks>();
      this._currentSession = null;
      this._sessionDataService = sessionDataService;
   }

   public addSession = (id: string, inst: BlastWmks): boolean => {
      if (this._sessionMap.has(id)) {
         return false;
      }
      this._sessionMap.set(id, inst);
      this.onAddSession(id, inst);
      return true;
   };

   public removeSession = (key: string): boolean => {
      if (!this._sessionMap.has(key)) {
         return false;
      }

      const session = this._sessionMap.get(key);
      this._sessionMap.delete(key);
      this.OnRemoveSession(key, session);
      return true;
   };

   public activeSession = (id: string, isNewSession: boolean = false): boolean => {
      if (clientUtil.isSeamlessMode()) {
         return false;
      }

      if (!this._sessionMap.has(id)) {
         return false;
      }

      if (!this._currentSession) {
         this._currentSession = this._sessionMap.get(id);
         this._currentSession.setVisibility(true);
         this.OnActiveSession(this._currentSession, isNewSession);
         return true;
      }

      if (this._currentSession.key === id) {
         return false;
      }

      for (const [key, session] of this._sessionMap) {
         session.setVisibility(id === key);
      }

      this._currentSession = this._sessionMap.get(id);
      this.OnActiveSession(this._currentSession, isNewSession);

      return true;
   };

   public hasApplicationSession = (): boolean => {
      for (const session of this._sessionMap.values()) {
         if (session.isApplicationSession && !session.isEmpty) {
            return true;
         }
      }
      return false;
   };

   public getSessionById = (id): BlastWmks => {
      if (this._sessionMap.has(id)) {
         return this._sessionMap.get(id);
      }
      return null;
   };

   public getAllSessions = (): Map<string, BlastWmks> => {
      return this._sessionMap;
   };

   public isCurrentSession = (id): boolean => {
      return this._currentSession ? id === this._currentSession.key : false;
   };

   public isCurrentSessionApp = (): boolean => {
      return !!this._currentSession && this._currentSession.isApplicationSession;
   };

   public isCurrentSessionDesktop = (): boolean => {
      return !!this._currentSession && !this._currentSession.isApplicationSession;
   };

   public getCurrentSession = (): BlastWmks => {
      return this._currentSession;
   };

   public needSwitchSession = (id): boolean => {
      return this._currentSession && this._currentSession.key !== id;
   };

   public sessionResetDone = (ids) => {
      if (!ids) {
         return;
      }
      for (let i = 0; i < ids.length; i++) {
         console.warn("Failed to reset session: " + ids[i]);
         if (this._sessionMap.has(ids[i])) {
            this._sessionMap.get(ids[i]).requestedDisconnect = false;
         }
      }
   };

   public getRemoteSessionInfo = (): Array<RemoteSessionInfo> => {
      const tempArray = new Array<RemoteSessionInfo>();
      for (const [id, session] of this._sessionMap) {
         const info = {} as RemoteSessionInfo;
         info.key = session.key;
         info.url = session.url;
         info.isActive = session.isActive;
         info.isApplicationSession = session.isApplicationSession;
         info.isShadow = session.isShadow;
         info.name = session.name;
         info.reconnectToken = session.reconnectToken;
         info.triedSSLVerify = session.triedSSLVerify;
         info.isConnected = session.isWmksConnected;
         info.enableUsb = session.enableUsb;
         info.usbTicket = session.usbTicket;
         info.redirectSetting = session.redirectSetting;
         info.brokerUrl = session?.brokerUrl;
         if (session.isWindows365) {
            info.isWindows365 = session.isWindows365;
         }
         if (session.dspecId) {
            info.dspecId = session.dspecId;
         }
         if (session.sessionId) {
            info.sessionId = session.sessionId;
         }
         tempArray.push(info);
      }
      return tempArray;
   };

   public getSessionTempArray = () => {
      const tempArray = [];

      for (const id in this._sessionMap) {
         if (this._sessionMap.has(id)) {
            const session = this._sessionMap.get(id);
            tempArray.push({
               key: session.key,
               url: session.url,
               isActive: session.isActive,
               isApplicationSession: session.isApplicationSession,
               isShadow: session.isShadow,
               name: session.name,
               reconnectToken: session.reconnectToken,
               triedSSLVerify: session.triedSSLVerify,
               isConnected: session.isWmksConnected
            });
         }
      }
      return tempArray;
   };

   public hasConflictWithStorage = () => {
      const tempArray = this.getSessionTempArray();
      return this._sessionDataService.detectBaseConflict(tempArray);
   };

   protected abstract onAddSession(id: string, inst: BlastWmks);
   protected abstract OnRemoveSession(id: string, session: BlastWmks);
   protected abstract OnActiveSession(session: BlastWmks, isNewSession: boolean);
}
