/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { ChromeServerModel } from "./chrome-server-model";
import { PolicyModel } from "./policy-model";

// After inited, will detect whether the window.onServerChosen is set, if yes,
// request to input the broker.

/**
 * All functions are wrapped into promise.
 * We don't want callback
 */

@Injectable({
   providedIn: "root"
})
export class ChromeServerService {
   private static readonly REGISTERED_SERVERS_KEY = "REGISTERED_SERVERS_KEY";
   private static readonly IP_REGEX =
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
   private static readonly HTTPS_PREFIX = "https://";
   private static readonly HTTP_PREFIX = "http://";
   private hideSecondaryServer: boolean = true;
   constructor(
      private chromeServerModel: ChromeServerModel,
      private policyModel: PolicyModel
   ) {}

   public getRegisteredServers = () => {
      return new Promise((resolve, reject) => {
         chrome.storage.local.get(ChromeServerService.REGISTERED_SERVERS_KEY, (serversObj) => {
            const servers = serversObj[ChromeServerService.REGISTERED_SERVERS_KEY];
            if (!servers || servers.length === 0) {
               resolve([]);
            }
            resolve(servers);
         });
      });
   };

   public registerNewServer = (server) => {
      if (!server) {
         throw "Null server object is passed in!";
      }

      return new Promise((resolve, reject) => {
         const model = this.chromeServerModel.getModel(
            server.address,
            server.autoConnect,
            server.description,
            server.secondaryServers,
            new Date().toISOString(),
            server.isTitan
         );

         chrome.storage.local.get(ChromeServerService.REGISTERED_SERVERS_KEY, (serversObj) => {
            let servers = serversObj[ChromeServerService.REGISTERED_SERVERS_KEY];
            if (!servers) {
               servers = [];
            }
            let update = false;
            for (let i = 0; i < servers.length; i++) {
               const tempServer = servers[i];
               if (model.equals(tempServer)) {
                  // Already registered
                  resolve(servers);
                  return;
               } else if (model.sameEntry(tempServer)) {
                  // always assign since we have cancel button in UI.
                  tempServer["description"] = server.description || "";
                  tempServer["isTitan"] = server.isTitan;
                  update = true;
                  break;
               }
            }

            if (!update) {
               servers.push(model);
            }

            const storedObj = {};
            storedObj[ChromeServerService.REGISTERED_SERVERS_KEY] = servers;
            chrome.storage.local.set(storedObj, () => {
               resolve(servers);
            });
         });
      });
   };

   public registerSecondaryServer = (originServer, secondaryServer: string) => {
      if (!originServer || !secondaryServer) {
         throw "Null server object is passed in!";
      }

      return new Promise((resolve, reject) => {
         const primaryModel = this.chromeServerModel.getModel(
            originServer.address,
            originServer.autoConnect,
            originServer.description,
            originServer.secondaryServers,
            originServer.updateTime,
            originServer.isTitan
         );

         secondaryServer = secondaryServer.toLowerCase().replace(/:\d+/, "");
         chrome.storage.local.get(ChromeServerService.REGISTERED_SERVERS_KEY, (serversObj) => {
            const servers = serversObj[ChromeServerService.REGISTERED_SERVERS_KEY];

            for (let i = 0; i < servers.length; i++) {
               const tempServer = servers[i];
               if (primaryModel.sameEntry(tempServer)) {
                  //Registered servers are alreay stored in local storage, but may not have secondaryServers property.
                  //If not, initiate first then add value to it
                  if (!servers[i].secondaryServers) {
                     servers[i].secondaryServers = [];
                  } else {
                     for (let j = 0; j < servers[i].secondaryServers.length; j++) {
                        if (secondaryServer === servers[i].secondaryServers[j]) {
                           servers[i].secondaryServers.splice(j, 1);
                           break;
                        }
                     }
                  }
                  servers[i].secondaryServers.unshift(secondaryServer);
                  servers[i].updateTime = new Date().toISOString();
               }
            }

            const storedObj = {};
            storedObj[ChromeServerService.REGISTERED_SERVERS_KEY] = servers;
            chrome.storage.local.set(storedObj, () => {
               resolve(servers);
            });
         });
      });
   };

   public getOriginServer = (server: string): Promise<string> => {
      if (!server) {
         throw "Illegal server address passed in!";
      }

      return new Promise((resolve, reject) => {
         this.policyModel.getModel().then((model) => {
            if (model) {
               const commonAdminSettings = model.getCommonAdminSettings();
               this.hideSecondaryServer = commonAdminSettings.hideSecondaryServer !== false;
            }

            if (!this.hideSecondaryServer) {
               resolve("");
               return;
            }

            server = server.toLowerCase();
            const tempServers = [];

            chrome.storage.local.get(ChromeServerService.REGISTERED_SERVERS_KEY, (serversObj) => {
               const servers = serversObj[ChromeServerService.REGISTERED_SERVERS_KEY];
               for (let i = 0; i < servers.length; i++) {
                  if (servers[i].secondaryServers) {
                     for (let j = 0; j < servers[i].secondaryServers.length; j++) {
                        if (server === servers[i].secondaryServers[j]) {
                           tempServers.unshift(servers[i]);
                        }
                     }
                  }
               }
               if (tempServers.length === 0) {
                  resolve("");
                  return;
               } else {
                  // Return the most recent server.
                  tempServers.sort((a, b) => {
                     return b.updateTime > a.updateTime ? 1 : -1;
                  });
                  const originServer: string = tempServers[0].address;
                  resolve(originServer);
                  return;
               }
            });
         });
      });
   };

   public removeRegisteredServer = (server) => {
      if (!server) {
         throw "Null server object is passed in!";
      }

      return new Promise((resolve, reject) => {
         const model = this.chromeServerModel.getModel(
            server.address,
            server.autoConnect,
            server.description,
            server.secondaryServers,
            server.updateTime,
            server.isTitan
         );

         chrome.storage.local.get(ChromeServerService.REGISTERED_SERVERS_KEY, (serversObj) => {
            let servers = serversObj[ChromeServerService.REGISTERED_SERVERS_KEY];
            if (!servers) {
               servers = [];
            }

            let serverToDelete = null;
            for (let i = 0; i < servers.length; i++) {
               const tempServer = servers[i];

               // we don't use the equal here since the previous release (< 4.9)
               // doesn't store the description in to the storage.
               if (model.sameEntry(tempServer)) {
                  // Found it and delete it
                  serverToDelete = i;
                  break;
               }
            }

            if (serverToDelete === null) {
               // No found server to delete
               resolve(servers);
               return;
            }

            // Delete the server and save back to storage
            servers.splice(serverToDelete, 1);
            const storedObj = {};
            storedObj[ChromeServerService.REGISTERED_SERVERS_KEY] = servers;
            chrome.storage.local.set(storedObj, () => {
               resolve(servers);
            });
         });
      });
   };

   public getPlainAddress = (brokerUrl) => {
      if (!brokerUrl) {
         throw "Illegal server address passed in!";
      }

      brokerUrl = brokerUrl.toLowerCase();
      // truncate prefix
      if (brokerUrl.indexOf(ChromeServerService.HTTPS_PREFIX) === 0) {
         brokerUrl = brokerUrl.slice(ChromeServerService.HTTPS_PREFIX.length);
      } else if (brokerUrl.indexOf(ChromeServerService.HTTP_PREFIX) === 0) {
         brokerUrl = brokerUrl.slice(ChromeServerService.HTTP_PREFIX.length);
      }

      // truncate suffix
      while (brokerUrl.endsWith("/")) {
         brokerUrl = brokerUrl.substring(0, brokerUrl.length - 1);
      }

      return brokerUrl;
   };

   public isServerValid = (server) => {
      if (!server) {
         return false;
      }

      /**
       * return true always for now.
       * In the feature UDP mode, the address should first be checked before
       * connect to it.
       */
      return true;
   };

   public updateRegisteredServer(server) {}
}
