/**
 * ******************************************************
 * Copyright (C) 2014-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import $ from "jquery";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class Localize {
   // Mapping of supported locales to the corresponding JSON file.
   private static readonly SUPPORT_LOCALE = {
      en: "en",
      es: "es",
      de: "de",
      fr: "fr",
      ja: "ja",
      ko: "ko",
      zh: "zh-CN",
      "en-us": "en",
      "es-es": "es",
      "de-de": "de",
      "fr-fr": "fr",
      "ja-jp": "ja",
      "ko-kr": "ko",
      "zh-cn": "zh-CN",
      "zh-hans": "zh-CN",
      "zh-hant": "zh-TW",
      "zh-tw": "zh-TW",
      "zh-hk": "en",
      "zh-mo": "en",
      "zh-hans-hk": "en",
      "zh-hant-hk": "en",
      "zh-hans-mo": "en",
      "zh-hant-mo": "en"
   };

   // Locale to be used during translation. Default is English.
   private locale: string = "en";

   //locale to be used during translate the text can't be rebrand. Default is English.
   private locale_rebrand_disabled: string = "en_rebrandDisabled";

   // JSON object containing the translated text.
   private translatedTable = null;

   // JSON object containing the translated text which can't be rebrand.
   private translatedTable_rebrandDisabled = null;

   // Create a BehaviorSubject and the component will always get locale ready when constructed.
   public localeReady$ = new BehaviorSubject(false);
   // Get Locale info
   public getLocale = () => {
      return this.locale;
   };

   // Set the locale and load the corresponding JSON translation table.
   public setLocaleAndLoad = (acceptLanguage) => {
      return new Promise((resolve, reject) => {
         let url, url_rebrand_disabled, selectedLocale;

         if (!acceptLanguage) {
            // The language version of the platform
            acceptLanguage =
               window.navigator.language ||
               //@ts-ignore
               window.navigator.userLanguage;
         }

         if (acceptLanguage) {
            // Determine the corresponding JSON translation file.
            selectedLocale = this.getBestLocale(acceptLanguage);
            if (selectedLocale) {
               this.locale = selectedLocale;
               this.locale_rebrand_disabled = selectedLocale + "_rebrandDisabled";
            }
         }

         Logger.debug("Load translation table for locale: " + this.locale);

         /**
          * Load language JSON file according to browser's locale setting
          * using AJAX synchronous call. TODO: change to server side code
          */
         url = "./locale/" + this.locale + ".json?v=" + __BUILD_NUMBER__;
         $.ajax({
            type: "GET",
            url: url,
            dataType: "json",
            async: true,
            cache: false,
            success: (data) => {
               if (data) {
                  this.translatedTable = data;
                  window.translateTable = data;
               } else {
                  Logger.error("Invalid translation data from url: " + url);
               }

               /**
                * Load can't rebrand language JSON file according to browser's locale
                * setting using AJAX synchronous call. TODO: change to server side code
                */
               url_rebrand_disabled = "./locale/" + this.locale_rebrand_disabled + ".json?v=" + __BUILD_NUMBER__;
               $.ajax({
                  type: "GET",
                  url: url_rebrand_disabled,
                  dataType: "json",
                  async: true,
                  cache: false,
                  success: (data) => {
                     if (data) {
                        this.translatedTable_rebrandDisabled = data;
                     } else {
                        Logger.error("Invalid translation data from url: " + url_rebrand_disabled);
                     }
                     const lang = this.getHTMLLang(this.locale);
                     $("html").attr("lang", lang);
                     this.localeReady$.next(true);
                     // @ts-ignore
                     resolve();
                  },
                  error: function (jqXHR, textStatus, errorThrown) {
                     Logger.error(
                        "Failed to load translation data, url: " +
                           url_rebrand_disabled +
                           ", status: " +
                           textStatus +
                           ", errorThrown: " +
                           errorThrown
                     );
                     reject();
                  }
               });
            },
            error: function (jqXHR, textStatus, errorThrown) {
               Logger.error(
                  "Failed to load translation data, url: " +
                     url +
                     ", status: " +
                     textStatus +
                     ", errorThrown: " +
                     errorThrown
               );
               reject();
            }
         });
      });
   };

   // set html lang attribute to fix accessibility
   private getHTMLLang = (locale) => {
      //https://www.w3schools.com/tags/ref_language_codes.asp
      if (locale === "zh-CN") {
         locale = "zh-Hans";
      }
      if (locale === "zh-TW") {
         locale = "zh-Hant";
      }

      return locale;
   };

   public translate = (...params: any[]) => {
      let translatedStr,
         i,
         msgid = params[0],
         args = [];

      // Skip the first parameter for it is the key.
      for (i = 1; i < params.length; i++) {
         args.push(params[i]);
      }
      /**
       * If the translated msgid exists, return the translated string,
       * else return the original string.
       */
      if (!!this.translatedTable && !!this.translatedTable[msgid]) {
         translatedStr = this.translatedTable[msgid];
      } else if (!!this.translatedTable_rebrandDisabled && !!this.translatedTable_rebrandDisabled[msgid]) {
         translatedStr = this.translatedTable_rebrandDisabled[msgid];
      } else {
         translatedStr = msgid;
      }

      if (args) {
         // If args, replace placeholders with the args values passed.
         if ($.isArray(args)) {
            // Iterate & replace placeHolders matching number of input args.
            for (i = 0; i < args.length; i++) {
               translatedStr = translatedStr.replace("{" + i + "}", args[i]);
            }
         } else {
            // Replace the first place holder {0}
            translatedStr = translatedStr.replace("{0}", args);
         }
      }

      return translatedStr;
   };

   /**
    * For a given list of desired locales, determine which language
    * out of our supported languages we should display.
    */
   private getBestLocale = (acceptLanguage) => {
      let matchedLocale = null,
         matchedRank = 0,
         accepted,
         i,
         kv,
         parts,
         thisRank,
         localeAndRank;

      if (!acceptLanguage) {
         return null;
      }

      /**
       * For each of the languages in the acceptLanguage param, see
       * if getSupportedLocale likes it. If so, set the currently
       * preferred language and its rank ('q=' value.) Do this for
       * all languages.
       */
      accepted = acceptLanguage.split(",");
      for (i = 0; i < accepted.length; i++) {
         if (accepted[i].indexOf("*") >= 0) {
            // Ignore the wildcard.
            continue;
         }

         /*
          * There may be a 'q=' section with a rank for this language.
          * Set rank value to 1 by default.
          */
         thisRank = 1;
         parts = $.trim(accepted[i]).split(";", 2);
         if (parts.length === 2) {
            kv = parts[1].split("=", 2);
            if (kv[0] === "q" && kv.length === 2) {
               thisRank = parseFloat(kv[1]);
            }
         } else if (parts.length < 1) {
            continue;
         }

         /**
          * If this locale entry is supported, compare its rank to the
          * highest matched rank thus far.
          */
         localeAndRank = this.getSupportedLocale(parts[0], thisRank);
         if (localeAndRank) {
            if (localeAndRank[1] > matchedRank) {
               matchedLocale = localeAndRank[0];
               matchedRank = localeAndRank[1];
            }
         }
      }

      return matchedLocale;
   };

   /**
    * For a given locale, determine the best supported localization
    * fit for that language using the supportedLocale dictionary.
    */
   public getSupportedLocale = (locale, returnRank) => {
      let langCode, selectedLocale, specifiers;

      // Convert to lower-case.
      locale = locale.toLowerCase();

      if (Localize.SUPPORT_LOCALE.hasOwnProperty(locale)) {
         // The full locale is specifically supported.
         selectedLocale = Localize.SUPPORT_LOCALE[locale];
      } else {
         specifiers = locale.split("-");
         if (specifiers.length > 2) {
            // Locale string like 'zh-hant' or 'zh-hant'.
            langCode = specifiers[0] + "-" + specifiers[1];
            if (Localize.SUPPORT_LOCALE.hasOwnProperty(langCode)) {
               // The language is zh-hans or zh-hant
               selectedLocale = Localize.SUPPORT_LOCALE[langCode];
            }
         }
         if (!selectedLocale && specifiers.length > 0) {
            // Locale string like 'en-us' or 'zh-tw'.
            langCode = specifiers[0];
            if (Localize.SUPPORT_LOCALE.hasOwnProperty(langCode)) {
               /**
                * The language is supported, but not in the desired
                * locale, the request is generic (xx instead of xx-YY)
                */
               selectedLocale = Localize.SUPPORT_LOCALE[langCode];
            }
         }
      }

      if (selectedLocale) {
         return [selectedLocale, returnRank];
      }
      return null;
   };
}
