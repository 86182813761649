/**
 * *****************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */
import { Component, AfterViewInit, ElementRef } from "@angular/core";
import { TranslateService } from "@html-core";

enum NotificationMap {
   TRACKPAD_MODE = "notification-trackpad",
   NATIVETOUCH_MODE = "notification-nativetouch"
}

@Component({
   selector: "panel-notification",
   template: "<div show-notification=’showNotification()‘></div>"
})
export class panelNotification implements AfterViewInit {
   public element;
   public currentNotification;
   constructor(
      private translate: TranslateService,
      private _el: ElementRef
   ) {
      this.element = $(this._el.nativeElement);
   }
   ngAfterViewInit() {
      this.element.addClass("hidden");
      this.element.addClass("clipboard-notification");
      this.element.addClass("noselect");

      // Removes notification element from display at end of fade
      // animation
      this.element.bind("transitionend oTransitionEnd webkitTransitionEnd", () => {
         this.element.addClass("hidden");
         this.element.removeClass("hideme");
         if (this.element.hasClass(this.currentNotification)) {
            this.element.removeClass(this.currentNotification);
         }
      });
   }

   // Shows a popup notification with the given text that then fades
   // out
   public showNotification = (msgKey) => {
      if (this.element.hasClass("imgNotification")) {
         return;
      }
      this.element.html(this.translate._T(msgKey));
      this.element.removeClass("hidden");
      this.element.removeClass("hideme");
      /*
       * Accessing offsetHeight forces reflow, which updates classes and
       * styling and restarts the fadeout animation. We don't need to use
       * the value, but we have to assign it to something to prevent lint
       * errors.
       */
      this.element.addClass("hideme");
   };

   public showImgNotification = (notificationKey: string) => {
      if (this.element.hasClass("imgNotification")) {
         const self = this;
         this.currentNotification = NotificationMap[notificationKey];
         this.element.addClass(this.currentNotification);
         this.element.removeClass("hidden");
         this.element.removeClass("hideme");
         setTimeout(() => {
            self.element.addClass("hideme");
         }, 1);
      }
   };
}
