/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export type TransferType = "bulk" | "interrupt" | "isochronous";

export abstract class HorizonUsbDevice {
   public deviceId: number;
   public vendorId: number;
   public productId: number;

   /*
    *---------------------------------------------------------------------------
    *
    * open --
    *
    * Opens a USB device returned by getDevices.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract open(): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * close --
    *
    * Closes the connection handle. Invoking operations on a handle after it has
    * been closed is a safe operation but causes no action to be taken.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract close(): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * selectConfiguration --
    *
    * Select a device configuration.
    * This function effectively resets the device by selecting one of the device's
    * available configurations. Only configuration values greater than 0 are valid
    * however some buggy devices have a working configuration 0 and so this value
    * is allowed.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract selectConfiguration(configurationValue: number): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * claimInterface --
    *
    * Claims an interface on a USB device. Before data can be transferred to an
    * interface or associated endpoints the interface must be claimed. Only one
    * connection handle can claim an interface at any given time.
    * If the interface is already claimed, this call will fail.
    *
    * releaseInterface should be called when the interface is no longer needed.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract claimInterface(interfaceNumber: number): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * releaseInterface --
    *
    * Releases a claimed interface.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract releaseInterface(interfaceNumber: number): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * selectAlternateInterface --
    *
    * Selects an alternate setting on a previously claimed interface.
    *
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract selectAlternateInterface(interfaceNumber: number, alternateSetting: number): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * controlTransferIn --
    *
    * Performs a control transfer on the specified device.
    * Control transfers refer to either the device, an interface or an endpoint.
    * Transfers to an interface or endpoint require the interface to be claimed.
    *
    * Results:
    *      Promise<USBInTransferResult>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract controlTransferIn(setup: USBControlTransferParameters, length: number): Promise<USBInTransferResult>;

   /*
    *---------------------------------------------------------------------------
    *
    * controlTransferOut --
    *
    * Performs a control transfer on the specified device.
    * Control transfers refer to either the device, an interface or an endpoint.
    * Transfers to an interface or endpoint require the interface to be claimed.
    *
    * Results:
    *      Promise<USBInTransferResult>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract controlTransferOut(
      setup: USBControlTransferParameters,
      data?: BufferSource
   ): Promise<USBOutTransferResult>;

   //public abstract clearHalt(direction: USBDirection, endpointNumber: number): Promise<void>;

   /*
    *---------------------------------------------------------------------------
    *
    * transferIn --
    *
    * Performs a bulk/interrupt transfer on the specified device.
    *
    * Results:
    *      Promise<USBInTransferResult>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract transferIn(type: TransferType, endpointNumber: number, length: number): Promise<USBInTransferResult>;

   /*
    *---------------------------------------------------------------------------
    *
    * transferOut --
    *
    * Performs a bulk/interrupt transfer on the specified device.
    *
    * Results:
    *      Promise<USBInTransferResult>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract transferOut(
      type: TransferType,
      endpointNumber: number,
      data: BufferSource
   ): Promise<USBOutTransferResult>;

   //public abstract isochronousTransferIn(endpointNumber: number, packetLengths: number[]): Promise<USBIsochronousInTransferResult>;
   //public abstract isochronousTransferOut(endpointNumber: number, data: BufferSource, packetLengths: number[]): Promise<USBIsochronousOutTransferResult>;

   /*
    *---------------------------------------------------------------------------
    *
    * reset --
    *
    * Tries to reset the USB device. If the reset fails, the given connection
    * handle will be closed and the USB device will appear to be disconnected
    * then reconnected.
    *
    * In this case getDevices or findDevices must be called again to acquire the device.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract reset(): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * reset --
    *
    * Gets the configuration descriptor for the currently selected configuration.
    *
    * Results:
    *      Promise<void>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract getConfiguration(): Promise<USBConfiguration>;

   public abstract getRaw(): any;

   public abstract getProductName(): string;

   public abstract getSerialNumber(): string;

   public abstract getManufacturerName(): string;
}
