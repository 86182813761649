/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable } from "@angular/core";
import { TabStatusEnum } from "./tabEnum";

@Injectable()
export class TabUtilsService {
   public tabs: any[] = [];
   public config: any = {};
   constructor() {}

   public _remove = (tab: any) => {
      const index = this.tabs.findIndex((t) => t.id === tab.id);
      this.tabs.splice(index, 1);
   };

   public _preProcessMessage = (msg: any) => {
      try {
         msg = JSON.parse(msg);
      } catch (e) {
         throw new Error("INVALID_JSON");
      }

      if (msg && msg.indexOf("PARENT_COMMUNICATED") === -1) {
         msg = "PARENT_COMMUNICATED" + msg;
      }
      return msg;
   };

   public addNew = (tab: any) => {
      this.tabs.push(tab);
   };

   public getOpened = () => {
      return this.tabs.filter((tab) => tab.status === TabStatusEnum.OPEN);
   };

   public getClosed() {
      return this.tabs.filter((tab) => tab.status === TabStatusEnum.CLOSE);
   }

   public getAll = () => {
      return this.tabs;
   };

   public broadCastAll = (msg: any) => {
      const tabs = this.getOpened();
      tabs.forEach((tab) => {
         this.sendMessage(tab, msg);
      });

      return this;
   };

   public sendMessage = (target: any, msg: any) => {
      const origin = this.config.origin || "*";

      if (target.ref) {
         target.ref.postMessage(msg, origin);
      }
   };

   public generateTabId = () => {
      const randomString = Math.random().toString(36).slice(2, 12);

      return randomString;
   };

   public searchByKeyName = (data, key, value, returnPreference?) => {
      const returnPreferenceEnum = {
         INDEX: "index",
         OBJECT: "object",
         BOTH: "both"
      };
      if (!data || !key) {
         return false;
      }

      returnPreference = returnPreference || returnPreferenceEnum[1];
      let i,
         obj,
         returnData,
         index = -1;

      for (i = 0; i < data.length; i++) {
         obj = data[i];
         if (!isNaN(value) && parseInt(obj[key], 10) === parseInt(value, 10)) {
            index = i;
            break;
         } else if (isNaN(value) && obj[key] === value) {
            index = i;
            break;
         }
      }

      if (index === -1) {
         data[index] = {};
      }

      switch (returnPreference) {
         case returnPreferenceEnum.INDEX:
            returnData = index;
            break;
         case returnPreferenceEnum.BOTH:
            returnData = {
               obj: data[index],
               index: index
            };
            break;
         case returnPreferenceEnum.OBJECT:
         default:
            returnData = data[index];
            break;
      }

      return returnData;
   };
}
