/**
 * ******************************************************
 * Copyright (C) 2013-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getTunnelConnectionCommand.js --
 *
 * get tunnel connection.
 *
 */

import { globalArray } from "../jscdkClient";
import GetTunnelConnectionHandler from "../controllers/getTunnelConnectionHandler";

export default function GetTunnelConnectionCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param getTunnelConnAction[in]
 *        - its property 'method' has the value 'GetTunnelConnection'
 */
GetTunnelConnectionCommand.prototype.execute = function (getTunnelConnAction) {
   let getTunnelConnObject,
      handlerList,
      router = globalArray["router"];

   getTunnelConnObject = globalArray["get-tunnel-connection"];
   if (!getTunnelConnObject) {
      getTunnelConnObject = new GetTunnelConnectionHandler();
      globalArray[getTunnelConnObject.messageName] = getTunnelConnObject;
      globalArray[getTunnelConnObject.responseTag] = getTunnelConnObject;
   } else {
      getTunnelConnObject.resetData();
   }

   getTunnelConnObject.setRequestXML(getTunnelConnAction.bypassTunnel);
   handlerList = getTunnelConnObject.composeHandlerList();
   router.postMessage(handlerList);
};
