/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

import {EventHelperBase, SDKEvent, SDKRawEvent, RequestBundle} from "./event-helper.base";
// format validation check could be added here, first version of format, reusable for different version of SDK
// initial messsage Exchange would always be in the first version of Events.
// if using this helper, array buffer content would get lost.
export class EventHelperPlain extends EventHelperBase {
   public getRequest = (messageType: string, data: any): RequestBundle => {
      const requestContent = this._getRequestPackage(messageType, data);
      return {
         request: JSON.stringify(requestContent),
         requestId: requestContent.requestId
      };
   };
   public parseResponse = (rawResponse: SDKRawEvent): SDKEvent => {
      return JSON.parse(rawResponse) as SDKEvent;
   };
   public getResponse = (messageType: string, data: any, requestId: string): SDKRawEvent => {
      return JSON.stringify(this._getResponsePackage(messageType, data, requestId));
   };
   public parseRequest = (rawRequest: SDKRawEvent): SDKEvent => {
      return JSON.parse(rawRequest) as SDKEvent;
   };
}
