/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export enum PanelEvent {
   HIDE,
   SHOW,
   TOGGLE
}

export const PROTOCOL_MISMATCH_ERROR_CODE = "AGENT_ERR_PROTOCOL_FAMILY_MISMATCH";
export const PROTOCOL_ERROR = "Please try connecting via another protocol or logoff the existing session first";
