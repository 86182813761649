/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import * as CST from "@html-core";
import { ValidatorFn, AbstractControl } from "@angular/forms";

export function repeatValidator(firstKey: string, secondKey: string): ValidatorFn {
   return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value.hasOwnProperty(firstKey) || !control.value.hasOwnProperty(secondKey)) {
         Logger.warning("the repeat validator would never work for invalid keys");
         return null;
      }
      const notSame = control.value[firstKey] !== control.value[secondKey];
      return notSame ? { notSame: true } : null;
   };
}
