/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Logger } from "../../core/libs/logger";
import { Injectable, Optional } from "@angular/core";
import { SDKEventService, SDKRequestProcessers, SDKRequestIds } from "../../../../SDK/src/util/event-service";
import { cryptoService, CryptoAlgorithmType } from "../../../../SDK/src/util/crypto-service";
import { SessionManagementCenterManager } from "../launcher/launchitems/session-service/session-management-center-manager.service";
import {
   VmwHorizonLaunchItemType,
   VmwHorizonClientProtocol,
   VmwHorizonClientAuthType,
   VmwHorizonServerType
} from "../../../../SDK/src/lib/model/enum";
import { VmwHorizonRemoteSessionBaseInfo, VmwHorizonServerInfo } from "../../../../SDK/src/lib/interfaces/struct-v1";

import { clientUtil, BusEvent, EventBusService } from "@html-core";
import { EventExchangeType } from "../../../../SDK/src/util/event-exchange/event-exchange";
import { CommonSDKService } from "../../shared/common/service/sdk.service";

// keeps below for now, and remove after finish object-> event refactor
type ItemLaunchFailedEvent = {
   type: string;
   data: {
      itemType: VmwHorizonLaunchItemType;
      launchItemId: string;
      errorMessage: string;
      clientData?: any;
   };
};

export type ProtocolSessionInfo = {
   type: string;
   data: {
      remoteSession: VmwHorizonRemoteSessionBaseInfo;
      clientData?: any;
   };
};

export type ProtocolSessionDisconnectedInfo = {
   type: string;
   data: {
      remoteSession: VmwHorizonRemoteSessionBaseInfo;
      connectionFailed: boolean;
      errorMessage: string;
      clientData?: any;
   };
};

export type IdleLockedInfo = {
   type: string;
   data: {
      hasAppDisconnection: boolean;
      clientData?: any;
   };
};

type AuthenticationDeclinedInfo = {
   type: string;
   data: {
      authType: VmwHorizonClientAuthType;
      clientData?: any;
   };
};

type AuthenticationFailedInfo = {
   type: string;
   data: {
      authType: VmwHorizonClientAuthType;
      errorMessage: string;
      retryAllowed: boolean;
      clientData?: any;
   };
};

export type ConnectingBrokerInfo = {
   type: string;
   data: {
      serverInfo: VmwHorizonServerInfo;
      clientData?: any;
   };
};

type ConnectBrokerFailedInfo = {
   type: string;
   data: {
      errorMessage: string;
      clientData?: any;
   };
};

type SDKOptionArgs = {
   allowNonSAMLAuth?: boolean;
   hideClient?: boolean;
   vChanOptions?: string;
   showError?: boolean;
   disableAppSessionAutoResume?: boolean;
   authSecret?: string;
   crypto?: {
      algoSelected: string;
      decryptoKey: any;
   };
};

export interface ChromeSDKVchanSettings {
   channelOptions: string;
   parentAppId: string;
   uuid: string;
   workingVersion: number;
}

/**
 * expire the secret after a while
 * crypto is done out side of this, and encrypted info is return for getAuthSecret
 */
export class SDKOptions {
   public allowNonSAMLAuth: boolean;
   public hideClient: boolean;
   public vChanOptions: string;
   public showError: boolean;
   public disableAppSessionAutoResume: boolean;
   private authSecret: string;
   public hadAuthSecret: boolean;
   private logger = new Logger(Logger.SDK);
   constructor(options: SDKOptionArgs) {
      this.allowNonSAMLAuth = false;
      this.hideClient = false;
      this.disableAppSessionAutoResume = true;
      this.vChanOptions = "";
      this.showError = false;
      this.hadAuthSecret = false;
      if (!options) {
         return;
      }
      if (typeof options.authSecret === "string") {
         this.setAuthSecret(options.authSecret);
         this.hadAuthSecret = true;
      } else {
         this.hadAuthSecret = false;
      }
      if (typeof options.allowNonSAMLAuth === "boolean") {
         this.allowNonSAMLAuth = options.allowNonSAMLAuth;
      }
      if (typeof options.disableAppSessionAutoResume === "boolean") {
         this.disableAppSessionAutoResume = options.disableAppSessionAutoResume;
      }
      if (typeof options.hideClient === "boolean") {
         this.hideClient = options.hideClient;
      }
      if (typeof options.vChanOptions === "string") {
         this.vChanOptions = options.vChanOptions;
      }
      if (typeof options.showError === "boolean") {
         this.showError = options.showError;
      }
      if (!!options.crypto && options.crypto.algoSelected === CryptoAlgorithmType.RSA_OAEP) {
         cryptoService.setAlgorithm(options.crypto.algoSelected);
         cryptoService.setDecryptoKeyObject(options.crypto.decryptoKey);
      }
   }
   /**
    * get encoded secret
    */
   public getAuthSecret = (): string => {
      const secret = this.authSecret;
      this.clearAuthSecret("for client already use it once");
      return secret;
   };
   public hasAuthSecret = (): boolean => {
      return !!this.authSecret;
   };
   // set encoded secret
   public setAuthSecret = (authSecret: string) => {
      this.authSecret = authSecret;
      this.logger.info("SDK auth info set");
      return true;
   };

   public clearAuthSecret = (reason: string) => {
      this.authSecret = null;
      this.logger.info("SDK auth info cleared for " + reason);
   };
}

@Injectable()
export class SDKService extends CommonSDKService {
   private parentAppId: string;
   private workingVersion: number;
   private instanceId: number;
   private eventService: SDKEventService;
   private processerType: string;
   public sdkOptions: SDKOptions;
   public serverId: string;
   public serverAddress: string;
   private logger: Logger;
   constructor(
      private eventBusService: EventBusService,
      @Optional()
      private sessionManagementCenterManager: SessionManagementCenterManager
   ) {
      super();
      this.logger = new Logger(Logger.SDK, null, Logger.Modules.ChromeSDK);
   }

   public isSDKSession = () => {
      return !!this.parentAppId;
   };

   public init = (): Promise<CommonSDKService> => {
      this.eventBusService.listen(BusEvent.DiscardAuthInfo.MSG_TYPE).subscribe(() => {
         this.clearAuthSecret("for client should not use it anymore");
      });
      return new Promise((resolve, reject) => {
         this._waitBackGround()
            .then(() => {
               if (window.chromeClient && window.chromeClient.sdkParentAppId && window.chromeClient.sdkAssignedId) {
                  this.logger.info("init SDK module");
                  this._init(
                     window.chromeClient.sdkParentAppId,
                     window.chromeClient.sdkAssignedId,
                     window.chromeClient.workingVersion,
                     window.chromeClient.sdkOptions
                  );
               } else {
                  this.logger.info("skip init SDK module");
                  this._setSDKHideMode(false);
               }
               resolve(this);
            })
            .catch((e) => {
               this.logger.error("fail to wait background bounding");
               this.logger.exception(e);
               resolve(null);
            });
      });
   };

   // when session locked, or reused, clear the previous auth secret
   private clearAuthSecret = (reason: string) => {
      if (this.sdkOptions) {
         this.sdkOptions.clearAuthSecret(reason);
      }
   };

   private _init = (parentAppId: string, instanceId = 1, workingVersion, sdkOptionArgs: SDKOptionArgs = {}) => {
      if (!parentAppId) {
         this.logger.info("found no parent app using SDK, disable SDK function");
         this._setSDKHideMode(false);
         return;
      }
      this.logger.info(
         "found parent app id " +
            parentAppId +
            " , init SDK function for client instance " +
            instanceId +
            " , at version " +
            workingVersion
      );
      this.parentAppId = parentAppId;
      this.workingVersion = workingVersion;
      this.instanceId = instanceId;

      this.sdkOptions = new SDKOptions(sdkOptionArgs);
      this.logger.dump("sdkOptions: " + JSON.stringify(this.sdkOptions));

      const validOrigins = ["chrome-extension://" + parentAppId];
      this.eventService = new SDKEventService(
         workingVersion === 2 ? EventExchangeType.LongLived : EventExchangeType.OneTime,
         validOrigins
      );
      this.processerType = SDKRequestProcessers.chromeClient + instanceId;
      this.eventService.addListener(validOrigins[0], this.processerType, (message) => {
         const data = message.request.data;
         const requestName = data.name;
         const requestParam = data.param;
         this.logger.dump("Get message in sdk-service: " + JSON.stringify(data));
         switch (requestName) {
            case SDKRequestIds.disconnectProtocolSession:
               this.logger.error("get request to disconnect blast session");
               this.eventBusService.dispatch({
                  type: "disconnectSession",
                  data: {
                     sessionId: requestParam.session.sessionId
                  }
               });
               break;
            default:
               this.logger.error("invalid requestName");
            //response with invalidType error
         }
         message.sendResponse(null);
      });
      this._setSDKHideMode(this.isHideClientEnabled());
      this.boundEvents(workingVersion);
   };

   private _onStopBlockLaunchingFromSDK = (reason?: string) => {
      this.onStopBlockLaunchingFromSDK(new BusEvent.StopBlockLaunchingFromSDK(reason));
   };

   private _setSDKHideMode = (requestHideClient) => {
      if (
         window.chromeClient &&
         window.chromeClient.onSDKModeInited &&
         typeof window.chromeClient.onSDKModeInited === "function"
      ) {
         window.chromeClient.onSDKModeInited(requestHideClient);
         if (requestHideClient) {
            this.logger.info("SDK request to hide client");
         } else {
            this.logger.info("no need to hide client for SDK");
         }
      } else {
         this.logger.error("onSDKModeInited no usable when init SDK service");
      }
   };
   public isHideClientEnabled = (): boolean => {
      if (clientUtil.isChromeClient()) {
         return !!this.sdkOptions && this.sdkOptions.hideClient;
      }
      return false;
   };

   public isShowErrorEnabled = (): boolean => {
      if (clientUtil.isChromeClient()) {
         return !!this.sdkOptions && this.sdkOptions.showError;
      }
      return false;
   };

   public isAppSessionAutoResumeDisabled = (): boolean => {
      if (clientUtil.isChromeClient()) {
         return !!this.sdkOptions && this.sdkOptions.disableAppSessionAutoResume;
      }
      return false;
   };

   private _waitBackGround = () => {
      const maxWaitTime = 1000;
      return new Promise((resolve, reject) => {
         const timeoutTimer = setTimeout(() => {
            clearInterval(pullTimer);
            clearTimeout(timeoutTimer);
            reject();
         }, maxWaitTime);
         const pullTimer = setInterval(() => {
            if (window.chromeClient && window.chromeClient.queryModel !== undefined) {
               clearInterval(pullTimer);
               clearTimeout(timeoutTimer);
               resolve(window.chromeClient);
            }
         }, 100);
      });
   };

   /**
    * define event callbacks for v1
    *
    * "connectingBroker",
    * "connectBrokerFailed",
    * "authenticationDeclined",
    * "authenticationFailed",
    * "itemLaunchFailed",
    * "idleTimeout",
    * "newProtocolSessionCreated",
    * "protocolSessionDisconnected"
    * refer https://confluence.eng.vmware.com/display/CNVDES/SDK+interface+--+VMware+Horizon+Client+for+Chrome+SDK#SDKinterface--VMwareHorizonClientforChromeSDK-EventCallbacks
    */
   public boundEvents = (workingVersion: number) => {
      this.eventBusService.listen("connectingBroker").subscribe(this.onConnectingBroker);
      this.eventBusService.listen(BusEvent.ConnectBrokerFailed.MSG_TYPE).subscribe(this.onConnectBrokerFailed);
      this.eventBusService.listen(BusEvent.AuthenticationDeclined.MSG_TYPE).subscribe(this.onAuthenticationDeclined);
      this.eventBusService.listen(BusEvent.AuthFailedMsg.MSG_TYPE).subscribe(this.onAuthenticationFailed);
      this.eventBusService.listen(BusEvent.ItemLaunchFailed.MSG_TYPE).subscribe(this.onItemLaunchFailed);
      this.eventBusService.listen(BusEvent.ItemLaunchSucceeded.MSG_TYPE).subscribe(this.onItemLaunchSucceeded);
      this.eventBusService.listen("idleTimeout").subscribe(this.onIdleLocked);
      this.eventBusService.listen("newProtocolSessionCreated").subscribe(this.onNewProtocolSessionCreated);
      this.eventBusService.listen("protocolSessionDisconnected").subscribe(this.onProtocolSessionDisconnected);
      this.eventBusService.listen(BusEvent.AuthenticationNext.MSG_TYPE).subscribe(this.handleAuthScreenMsg);
      this.eventBusService
         .listen(BusEvent.StopBlockLaunchingFromSDK.MSG_TYPE)
         .subscribe(this.onStopBlockLaunchingFromSDK);
   };

   private sendEvent = async (eventType: SDKRequestIds, data: any) => {
      const eventData = {
         name: eventType,
         param: data
      };
      try {
         await this.eventService.sendMessage(this.parentAppId, this.processerType, eventData, null);
         this.logger.dump(
            "Send message from sdk-service: " +
               JSON.stringify({
                  parentAppId: this.parentAppId,
                  processerType: this.processerType,
                  eventData: eventData
               })
         );
      } catch (e) {
         Logger.exception(e);
      }
   };

   public onStopBlockLaunchingFromSDK = (event: BusEvent.StopBlockLaunchingFromSDK) => {
      this.logger.trace("onStopBlockLaunchingFromSDK called");
      if (!!window.chromeClient && typeof window.chromeClient.stopWaitForLaunch === "function") {
         this.logger.info("stop blocking launches from SDK, due to " + event.reason);
         window.chromeClient.stopWaitForLaunch();
      }
   };

   public onConnectingBroker = async (event: ConnectingBrokerInfo) => {
      this.logger.info("onConnectingBroker");
      const serverInfo: VmwHorizonServerInfo = event.data.serverInfo;
      this.serverId = await this.getUuid(serverInfo.serverAddress + this.instanceId);
      this.serverAddress = serverInfo.serverAddress;
      serverInfo.serverId = this.serverId;
      serverInfo.serverType = <VmwHorizonServerType>"Horizon";
      //not useful for now, would always be "VmwHorizonServerType.Horizon", keep here to consistent with C-client-SDK
      const eventData = {
         serverInfo: serverInfo,
         clientData: event.data.clientData
      };
      this.sendEvent(SDKRequestIds.connectingBroker, eventData);
   };

   public onConnectBrokerFailed = (event: BusEvent.AuthenticationDeclined) => {
      this.logger.info("onConnectBrokerFailed");
      const eventData = {
         serverId: this.serverId,
         errorMessage: event.data.errorMessage,
         clientData: event.data.clientData
      };
      this._onStopBlockLaunchingFromSDK("broker connection failure rejected: " + event.data.errorMessage);
      this.sendEvent(SDKRequestIds.connectBrokerFailed, eventData);
   };

   public onAuthenticationDeclined = (event: BusEvent.AuthenticationDeclined) => {
      this.logger.info("onAuthenticationDeclined");
      const eventData = {
         serverId: this.serverId,
         authType: event.data.authType,
         clientData: event.data.clientData
      };
      this._onStopBlockLaunchingFromSDK("auth rejected: " + event.data.authType);
      this.sendEvent(SDKRequestIds.authenticationDeclined, eventData);
   };

   public onAuthenticationNext = (event: BusEvent.AuthenticationNext) => {
      this.logger.info("onAuthenticationNext");
      const renderData = event.data;
      const authType = event.authType;
   };

   public onAuthenticationFailed = (event: BusEvent.AuthFailedMsg) => {
      this.logger.info("onAuthenticationFailed");
      const eventData = {
         serverId: this.serverId,
         authType: event.authType,
         errorMessage: event.errorMessage,
         retryAllowed: event.retryAllowed, //always be false for now
         clientData: null
      };
      this._onStopBlockLaunchingFromSDK("auth failure: " + event.errorMessage);
      this.sendEvent(SDKRequestIds.authenticationFailed, eventData);
      setTimeout(() => {
         this.eventBusService.dispatch({
            type: "exitClient"
         });
      });
   };

   public onItemLaunchFailed = (event: BusEvent.ItemLaunchFailed) => {
      this.logger.info("onItemLaunchFailed for " + JSON.stringify(event.data));
      const eventData = {
         serverId: this.serverId,
         type: event.data.itemType,
         launchItemId: event.data.launchItemId,
         errorMessage: event.data.errorMessage,
         clientData: event.data.clientData
      };
      this._onStopBlockLaunchingFromSDK("launch failure: " + event.data.errorMessage);
      this.sendEvent(SDKRequestIds.itemLaunchFailed, eventData);
      // If no connected session, exit client.
      if (!this.sessionManagementCenterManager.hasConnectedSession()) {
         setTimeout(() => {
            this.eventBusService.dispatch({
               type: "exitClient"
            });
         });
      }
   };

   public onItemLaunchSucceeded = (event: BusEvent.ItemLaunchSucceeded) => {
      this.logger.info("onItemLaunchSucceeded for " + JSON.stringify(event.data));
      const eventData = {
         serverId: this.serverId,
         type: event.data.itemType,
         launchItemId: event.data.launchItemId,
         clientData: event.data.clientData
      };
      this._onStopBlockLaunchingFromSDK("launch succeed: " + event.data.launchItemId);
      this.sendEvent(SDKRequestIds.itemLaunchSucceeded, eventData);
   };

   public onIdleLocked = (event: IdleLockedInfo) => {
      this.logger.info("onIdleLocked");
      const eventData = {
         serverId: this.serverId,
         hasAppDisconnection: event.data.hasAppDisconnection
      };
      this.sendEvent(SDKRequestIds.idleTimeout, eventData);
   };

   public onNewProtocolSessionCreated = (event: ProtocolSessionInfo) => {
      const sessionInfo: VmwHorizonRemoteSessionBaseInfo = event.data.remoteSession;
      sessionInfo.serverId = this.serverId;
      sessionInfo.clientInstanceId = this.instanceId;
      this.logger.info("onNewProtocolSessionCreated");
      const eventData = {
         remoteSession: sessionInfo,
         protocol: <VmwHorizonClientProtocol>"Blast",
         clientData: event.data.clientData
      };
      this.sendEvent(SDKRequestIds.newProtocolSessionCreated, eventData);
   };

   public onProtocolSessionDisconnected = (event: ProtocolSessionDisconnectedInfo) => {
      const sessionInfo: VmwHorizonRemoteSessionBaseInfo = event.data.remoteSession;
      sessionInfo.serverId = this.serverId;
      sessionInfo.clientInstanceId = this.instanceId;
      this.logger.info("onProtocolSessionDisconnected");
      const eventData = {
         remoteSession: sessionInfo,
         connectionFailed: event.data.connectionFailed,
         errorMessage: event.data.errorMessage,
         clientData: event.data.clientData
      };
      this.sendEvent(SDKRequestIds.protocolSessionDisconnected, eventData);
   };

   private handleAuthScreenMsg = (msg: BusEvent.AuthenticationNext) => {
      this.logger.info("checking auth screen of " + msg.authType);
      let supportAuthList = [];
      if (this.sdkOptions && (this.sdkOptions.allowNonSAMLAuth !== true || !this.sdkOptions.hadAuthSecret)) {
         supportAuthList = ["Disclaimer", "Waiting"];
      } else {
         supportAuthList = ["Disclaimer", "Waiting", "WindowsPassword"];
      }

      if (!supportAuthList.some((supportedType) => supportedType === msg.authType)) {
         this._onUnsupportedAuthFound(msg.authType);
         return;
      }
      this.logger.info("continue processing auth screen for " + msg.authType);
   };

   private _onUnsupportedAuthFound = (authTypeName: string) => {
      this.logger.info("Quit client since not supported authentication method involved: " + authTypeName);
      const eventData = {
         serverId: this.serverId,
         authType: <VmwHorizonClientAuthType>"Not_Allowed",
         errorMessage: "not supported:" + authTypeName + " as Auth type",
         retryAllowed: false,
         clientData: null
      };

      this._onStopBlockLaunchingFromSDK("auth failure: invalid auth type");
      this.sendEvent(SDKRequestIds.authenticationFailed, eventData);
   };

   /**
    *
    * @param sourceData hash input
    * @returns Base64 encoded hash using SHA-256
    */
   private getHash = async (sourceData: Uint8Array): Promise<string> => {
      const algorithm = "SHA-256"; // FIPS 180-4 section 6.2
      const digest = await window.crypto.subtle.digest(algorithm, sourceData);
      const decoder = new TextDecoder("ascii");
      const b64encodedHash = btoa(unescape(encodeURIComponent(decoder.decode(digest))));
      return b64encodedHash;
   };

   /**
    * Not often called, thus prefer readability over efficiency
    * introduce randomness to avoid predictable uid for blast session.
    * @param sourceString part of string for generating random hash
    * @returns Base64 encoded SHA-256
    */
   private getUuid = async (sourceString: string): Promise<string> => {
      const randomArrayLength = 256;
      const randomArray = new Uint8Array(randomArrayLength);
      window.crypto.getRandomValues(randomArray);
      const encoder = new TextEncoder();
      const sourceArray = encoder.encode(sourceString);

      const randomlizedArray = new Uint8Array(randomArray.length + sourceArray.length);
      randomlizedArray.set(randomArray);
      randomlizedArray.set(sourceArray, randomArray.length);
      return this.getHash(randomlizedArray);
   };

   public getSDKSettingForLaunch = async (sessionKey): Promise<ChromeSDKVchanSettings> => {
      if (!this.parentAppId) {
         this.logger.info("disable virtual channel");
         return null;
      }
      const vChanOptions = this.sdkOptions ? this.sdkOptions.vChanOptions : "";
      const uuid = await this.getUuid(sessionKey);
      this.logger.info("enable virtual channel for " + sessionKey);
      const SDKSetting = {
         channelOptions: vChanOptions,
         parentAppId: this.parentAppId,
         uuid: uuid,
         workingVersion: this.workingVersion
      };
      this.logger.dump("SDKSetting for Launch: " + JSON.stringify(SDKSetting));
      return SDKSetting;
   };

   // can only read one time.
   public getAuthSecret = async (): Promise<string> => {
      const rawSecret = !!this.sdkOptions && this.sdkOptions.getAuthSecret();
      if (rawSecret) {
         try {
            return await cryptoService.decryptString(rawSecret);
         } catch (e) {
            Logger.exception(e);
         }
      }
      throw "failed to read the secret";
   };
   public hasAuthSecret = () => {
      return !!this.sdkOptions && !!this.sdkOptions.hasAuthSecret();
   };
   /**
    * expire the secret after a while
    */
   public setAuthSecret = (authSecret: string): boolean => {
      return !!this.sdkOptions && this.sdkOptions.setAuthSecret(authSecret);
   };
}
