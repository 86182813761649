/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { UsbProtocol } from "./usb-protocol";

class UsbUtil {
   private logger = new Logger("usb-dump");
   private debug: boolean = false;
   constructor() {
      //read from storage
      this.debug = true;
   }
   public dump_rpc = function (rpc) {
      this.logger.debug(
         " RPC Channel onInvoke = " +
            rpc.command +
            " rpc.returnCode = " +
            rpc.returnCode +
            " rpc.returnParams.length = " +
            rpc.returnParams.length
      );
      for (let i = 0; i < rpc.params.length; i++) {
         this.logger.debug(
            "RPC Channel onInvoke params[" + i + "](" + rpc.params[i].length + ") = [" + rpc.params[i] + "]"
         );
      }
      switch (rpc.command) {
         case UsbProtocol.PACKET_TYPE.USBREDIRECTION_SENDASYNCMSG:
            this.logger.debug("USBREDIRECTION_SENDASYNCMSG");
            break;
         case UsbProtocol.PACKET_TYPE.USBREDIRECTION_POSTMSG:
            this.logger.debug("USBREDIRECTION_POSTMSG");
            break;
         case UsbProtocol.PACKET_TYPE.USBREDIRECTION_POSTMSGFAST:
            this.logger.debug("USBREDIRECTION_POSTMSGFAST");
            break;
         default:
            this.logger.error("received unexpected command: " + rpc.command);
      }
   };

   public dumpRawDesc = (prefix, data: DataView, isSplitDevice?: boolean) => {
      this.logger.debug("-----------------------------------");
      if (isSplitDevice) {
         this.logger.debug("----------Rewrite for split device------------");
      }
      this.logger.debug(prefix);
      this.logger.debug("length = " + data.buffer.byteLength);
      let s = "";
      for (let i = 0; i < data.buffer.byteLength; ++i) {
         s += d2h(data.getUint8(i)) + " ";
      }
      this.logger.debug(s);
      this.logger.debug("-----------------------------------");

      function d2h(d) {
         let hex = Number(d).toString(16);
         hex = "000000".substr(0, 2 - hex.length) + hex;
         return hex;
      }
   };
}

export const util = new UsbUtil();
