/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Add kill switch for each new feature client.
 * https://confluence.eng.vmware.com/display/HADD/Add+kill+switch+for+each+feature
 *
 */

import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { FeatureConfigs } from "../../../shared/common/model/feature-configs";
import { KillSwitchService } from "../../../shared/common/service/kill-switch.service";
import { ViewClientModel } from "../../../shared/common/model/viewclient-model";

@Injectable()
export class HTML5KillSwitchService extends KillSwitchService {
   private htmlFeatures: object;

   constructor(
      private FeatureConfigs: FeatureConfigs,
      private viewclientModel: ViewClientModel
   ) {
      super();
      this.htmlFeatures = {
         "perftracker.v1": true,
         "anonymouslogin.worker": true,
         Touch: true,
         VscanKeyboard: true,
         PanelFreeClipboard: true,
         fullScreen: true,
         AzureConnectionRetry: true,
         MultiSessionSingleUser: true,
         PrintRedirection: true,
         WindowReplacementApi: true,
         EnterFullscreenIfOneMonitor: true
      };
   }

   public init = (feature) => {
      this.htmlFeatures["WindowReplacementApi"] =
         this.viewclientModel.useWindowReplacementApi && typeof window.getScreenDetails === "function";
      this.htmlFeatures["EnterFullscreenIfOneMonitor"] = this.viewclientModel.enterFullscreenIfOneMonitor;
      if (!feature) {
         this.setFeatureModel();
         return;
      }

      // Update local htmlFeatures first
      for (const key in feature) {
         if (feature[key] !== true && feature[key] !== false) {
            continue;
         }
         if (!this.htmlFeatures.hasOwnProperty(key)) {
            continue;
         }
         this.htmlFeatures[key] = feature[key];
      }
      this.setFeatureModel();
   };

   private setFeatureModel = () => {
      for (const key in this.htmlFeatures) {
         this.FeatureConfigs.setConfig(this.killSwitchPrefix + key, this.htmlFeatures[key]);
         if (key === "VscanKeyboard" && this.viewclientModel.mIsVrDevice) {
            Logger.info("Disable VScanCode webmks solution for VR browser.");
            this.FeatureConfigs.setConfig(this.killSwitchPrefix + key, false);
         }
      }
   };
}
