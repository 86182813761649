/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";

/**
 * io-optimizor.ts
 *
 * Not fully implemented yet.
 * Should be used to optimize the read/write later.
 * Put it under shared for now, since we don't have the HTML Access version of this file.
 * separate from other since this should contains the io only, which is more optimizable
 */

type FuncOnWrite = (FileWriter) => void;
type FuncOnLoad = (File) => void;
type FuncOnError = (FileError) => void;

interface FileEntry {
   file: (FuncOnLoad, FuncOnError?) => void;
   createWriter: (FuncOnWrite, FuncOnError?) => void;
   writer?: any;
}

export class IOOptimizer {
   private fileReaders: Map<number, FileReader>;
   constructor() {
      this.fileReaders = new Map<number, FileReader>();
   }
   public read = (fileEntry: FileEntry, offset: number, length: number, id: number): Promise<any> => {
      if (!this.fileReaders.has(id)) {
         this.fileReaders.set(id, new FileReader());
      }

      const fileReader = this.fileReaders.get(id);
      return new Promise((resolve, reject) => {
         const onLoaded = function () {
            const readResult = fileReader.result;
            resolve(readResult);
         };
         const onError = function () {
            const error = fileReader.error;
            Logger.error("Read error: " + error);
            reject(error);
         };
         fileEntry.file((file) => {
            this.active(fileReader, file, offset, length, onLoaded, onError);
         });
      });
   };

   /**
    * Active a segment of entry to reduce possible reading delay later.
    * Currently private, but can be used outside this file also
    */
   private active = (
      fileReader: FileReader,
      file: Blob,
      offset: number,
      length: number,
      onLoaded: () => void,
      onError: () => void
   ): void => {
      const readBuffer = file.slice(offset, offset + length);
      fileReader.onload = onLoaded;
      fileReader.onerror = onError;
      fileReader.onabort = onError;
      fileReader.readAsArrayBuffer(readBuffer);
   };

   public write = (
      fileEntry: FileEntry,
      buffer: ArrayBuffer,
      offset: number,
      length: number,
      cacheService?
   ): Promise<number> => {
      return new Promise((resolve, reject) => {
         fileEntry.createWriter(
            async (fileWriter) => {
               const blob = new Blob([new Uint8Array(buffer)], {
                  type: "application/octet-binary"
               });
               //@ts-ignore
               if (fileWriter instanceof FileSystemWritableFileStream) {
                  this._saveWriterToCache(fileEntry, fileWriter, cacheService);
                  try {
                     if (offset !== 0) {
                        fileWriter.seek(offset);
                     }
                     await fileWriter.write(blob);
                     resolve(length);
                  } catch (e) {
                     reject(e);
                  }
               } else {
                  if (offset >= fileWriter.length) {
                     //on or over EOF
                     //adding 0 in between as defined by MS
                  } else if (offset !== 0) {
                     fileWriter.seek(offset);
                  }
                  fileWriter.onwrite = () => {
                     resolve(length);
                  };
                  fileWriter.onerror = reject;
                  fileWriter.write(blob);
               }
            },
            (e) => {
               Logger.exception(e);
               reject();
            }
         );
      });
   };

   public truncate = (fileEntry: FileEntry, length: number, cacheService?): Promise<number> => {
      return new Promise((resolve, reject) => {
         fileEntry.createWriter((fileWriter) => {
            //@ts-ignore
            if (fileWriter instanceof FileSystemWritableFileStream) {
               this._saveWriterToCache(fileEntry, fileWriter, cacheService);
               fileWriter
                  .truncate(length)
                  .then(() => {
                     if (length === 0 && cacheService) {
                        cacheService.releaseWriteHandler(fileEntry);
                     }
                     resolve(length);
                  })
                  .catch(() => {
                     reject();
                  });
            } else {
               fileWriter.onwrite = () => {
                  resolve(length);
               };
               fileWriter.onerror = reject;
               fileWriter.truncate(length);
            }
         });
      });
   };

   private _saveWriterToCache = (fileEntry, fileWriter, cacheService) => {
      if (!fileEntry.writer.addedInCache && fileWriter) {
         cacheService.addFileHandlerToCacheList(fileEntry, fileWriter);
         fileEntry.writer.addedInCache = true;
      }
   };
}
