/** @format */

import { VMSize } from "../../channels/html5MMR-client/model/html5MMR.media.models";

/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
export class MediaStat {
   public id: string;
   public srcSize: VMSize;
   public overlaySize: VMSize;
   public fps: number = 0;
   public bwe: number = 0;
   public dirty: boolean = false;
   public lastUpdated: number = Date.now();

   private _propString = (tag: string, prop: any) => {
      let result = "";
      if (prop) {
         result = prop;
      }
      return `${tag} ${result}`;
   };

   private _sizeString = (tag: string, size: VMSize) => {
      let result = "";
      if (size) {
         result = `${size.width} x ${size.height}`;
      }
      return `${tag} ${result}`;
   };

   public updateOverlaySize = (size: VMSize) => {
      if (!size.equal(this.overlaySize)) {
         this.overlaySize = new VMSize(size.width, size.height);
         this.dirty = true;
         this.lastUpdated = Date.now();
      }
   };

   public updateSourceSize = (size: VMSize) => {
      if (!size.equal(this.srcSize)) {
         this.srcSize = new VMSize(size.width, size.height);
         this.dirty = true;
         this.lastUpdated = Date.now();
      }
   };

   public updateFPS = (fps: number) => {
      if (fps == Infinity) {
         // Do not perform update when given fps value is reported as Infinity from MediaTrackSettings
         return;
      }
      const currentTime = Date.now();
      if (fps !== this.fps || (!this.dirty && currentTime - this.lastUpdated > 3000)) {
         this.lastUpdated = currentTime;
         this.dirty = true;
      }
      this.fps = fps;
   };

   public updateBWE = (bwe: number) => {
      if (bwe !== this.bwe) {
         this.bwe = bwe;
         this.dirty = true;
         this.lastUpdated = Date.now();
      }
   };

   public get idString(): string {
      return this._propString("ID", this.id);
   }

   public get fpsString(): string {
      return this._propString("fps", this.fps);
   }

   public get bweString(): string {
      return this._propString("bwe", this.bwe);
   }

   public get srcSizeString(): string {
      return this._sizeString("Source", this.srcSize);
   }

   public get overlaySizeString(): string {
      return this._sizeString("Overlay", this.overlaySize);
   }

   public get description(): string {
      return `${this.idString} ${this.fpsString}\n${this.bweString}\n${this.srcSizeString}\n${this.overlaySizeString}`;
   }
}
