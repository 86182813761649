/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { IMessage } from "./abstract-message.service";

export class RenewHeartBeatEvent implements IMessage {
   public static readonly MSG_TYPE = "RenewHeartBeat";
   public type: string = RenewHeartBeatEvent.MSG_TYPE;
}

export class CanvasEvent implements IMessage {
   public static readonly MSG_TYPE = "CanvasEvent";
   public type: string = CanvasEvent.MSG_TYPE;
   public show: boolean;
   constructor(show: boolean) {
      this.show = show;
   }
}
