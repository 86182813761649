/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * securIDNextTokenCodeHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler for SecurIDNextTokenCode
 *      authentication.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function SecurIDNextTokenCodeHandler() {
   let router;
   // member variables below
   this.messageName = "securid-nexttokencode";
   this.messageText = "securid-nexttokencode";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
SecurIDNextTokenCodeHandler.prototype = new MessageHandler();
// constructor
SecurIDNextTokenCodeHandler.constructor = SecurIDNextTokenCodeHandler;

/**
 * Set the request XML for next token code authentication.
 *
 * @param tokenCode [in] token code for the securid authentication.
 */

SecurIDNextTokenCodeHandler.prototype.setRequestXML = function (tokenCode) {
   let tokenCodeText;
   let paramText;
   tokenCodeText = util.createElement("name", "tokencode");
   tokenCodeText += util.createElement("values", util.createElement("value", tokenCode));
   paramText = util.createElement("param", tokenCodeText);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", this.messageName);
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
