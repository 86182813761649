/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input, Output, EventEmitter, OnDestroy } from "@angular/core";

import { AB } from "../../../../shared/desktop/common/appblast-util.service";
import { BusEvent, CLIENT_TYPE, ENTITLE_TYPE, EventBusService, LAUNCH_CLIENT_TYPE, TitanMsg } from "@html-core";
import { HtmlRemoteSessionManager } from "../../../../html5-client/common/remote-session/html-remote-session-manager";
import { EntitledItemsModel } from "../../common/entitleditems-model";
import { WmksService } from "../../common/wmks.service";
import { PanelEvent } from "../sidebar-constant";
import { Logger } from "../../../../core/libs/logger";
import { clientUtil } from "@html-core";
import { MessageHandlerService } from "../../common/message-handler.service";
import { ClientSettingModel } from "../../../common/model/client-setting-model";
import { AnonymousService } from "../../../common/service/anonymous.service";
import { Ws1Service } from "../../../common/service/ws1.service";
import { XmlApiService } from "../xml-api.service";
import { ContextMenuUtilService } from "../../../common/context-menu/context-menu-util.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ViewClientModel } from "../../../common/model/viewclient-model";
import { getDesktopImage, imageAsset } from "../../../common/image-asset/image-asset";
import { ConnectToItemService } from "../../../common/service/connect-to-item.service";

@Component({
   selector: "sidebar-entitled-items",
   templateUrl: "./sidebar-entitled-items.component.html"
})
export class SidebarEntitledItems implements OnDestroy {
   public entitledItems: Array<EntitlementItem> = null;
   @Input() query: string;
   @Output() panelEvent = new EventEmitter<PanelEvent>();
   @Output() preferEvent = new EventEmitter<boolean>();
   public util = AB;
   public imageAsset = imageAsset;
   public showPreference: boolean = true;
   private ngUnsubscribe = new Subject();

   // Whether to show only favorites or not
   public showOnlyFavorites: boolean = false;

   // control whether show the available item panel for user to launch
   // them, will be true for HWS
   public showLaunchPanel: boolean = false;
   public isDiffApp: boolean;
   public paneClass = ".content-panel-container";
   public isTitanClient: boolean = clientUtil.isTitanClient();
   public contextmenuName = "htmlaccess";

   constructor(
      private entitledItemsModel: EntitledItemsModel,
      private eventBusService: EventBusService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private wmksService: WmksService,
      private messageHandlerService: MessageHandlerService,
      private clientSettingModel: ClientSettingModel,
      private anonymousService: AnonymousService,
      private ws1Service: Ws1Service,
      private xmlApiService: XmlApiService,
      private contextMenuUtilService: ContextMenuUtilService,
      private viewClientModel: ViewClientModel,
      private connectToItemService: ConnectToItemService
   ) {
      if (this.isTitanClient) {
         if (this.viewClientModel.mIsIOS || this.viewClientModel.mIsAndroid) {
            this.contextmenuName = "titan";
         }
      }

      this.entitledItems = entitledItemsModel.getEntitledItems();
      this.entitledItemsModel.entitleChange$.subscribe(() => {
         setTimeout(() => {
            this.entitledItems = this.entitledItemsModel.getEntitledItems();
            this._updateFavorites();
         }, 0);
      });

      this.eventBusService.listen(BusEvent.PendingLaunchOps.MSG_TYPE).subscribe((msg: BusEvent.PendingLaunchOps) => {
         let type: ENTITLE_TYPE = ENTITLE_TYPE.DESKTOP;
         if (msg.isApp) {
            type = ENTITLE_TYPE.APPLICATION;
         }
         const item: EntitlementItem = this.entitledItemsModel.isItemExisting(type, msg.id);
         if (item && item.clientType === CLIENT_TYPE.TITAN) {
            const titanItem: TitanEntitlementItem = item as TitanEntitlementItem;
            titanItem.spec = msg.spec;
            this.launchItem(item);
         }
      });

      this.eventBusService
         .listen(BusEvent.URIActionMsg.MSG_TYPE)
         .pipe(takeUntil(this.ngUnsubscribe))
         .subscribe(this.handleUriLaunch);

      this.anonymousService.subscribe((mode) => {
         this.showPreference = !mode;
      });

      this.showLaunchPanel = !this.ws1Service.isWS1Mode();

      /*
       * Handler to update the list of favorites once user global prefer are read
       */
      this.eventBusService.listen(BusEvent.UpdateFavoritesMsg.MSG_TYPE).subscribe(() => {
         this._updateFavorites();
      });

      this.eventBusService.listen(BusEvent.PanelEventMsg.MSG_TYPE).subscribe((msg: BusEvent.PanelEventMsg) => {
         if (msg.isHide) {
            this.panelEvent.emit(PanelEvent.HIDE);
         }
      });

      this.messageHandlerService.registerLaunchCallback(this.launchItem4HWS);
      this.eventBusService.dispatch(new BusEvent.RefreshEntitlementMsg());

      jQuery(document).ready(function ($) {
         $("li").tooltip();
      });
   }

   private handleUriLaunch = (msg: BusEvent.URIActionMsg) => {
      if (msg.action === "start-session") {
         this.launchItem(msg.entitlement);
      } else {
         Logger.info(`sidebar handleUriLaunch unsupported action ${msg.action}`);
      }
   };

   public ngOnDestroy(): void {
      this.ngUnsubscribe.next("");
      this.ngUnsubscribe.complete();
   }

   private _updateFavorites = () => {
      const favorites = this.xmlApiService.getFavorites();
      if (favorites) {
         for (const item of this.entitledItems) {
            item.favorite = favorites.indexOf(item.id) !== -1;
         }
      }
   };

   public showAvailableItemTooltip = () => {
      $(".icon-and-name-container").tooltip({
         close: function () {
            $(".ui-helper-hidden-accessible > *:not(:last)").remove();
         }
      });
   };

   public showFavoriteBtnTooltip = () => {
      $(".available-app-favorite-button").tooltip();
   };

   public isApp = (item: EntitlementItem) => {
      return item.type === ENTITLE_TYPE.APPLICATION;
   };

   public isDesktop = (item: EntitlementItem) => {
      return item.type == ENTITLE_TYPE.DESKTOP;
   };

   /*
    * toggleFavorite
    *
    * Callback to toggle whether an item should be a favorite or not.
    */
   public toggleFavorite = (item: EntitlementItem) => {
      item.favorite = !item.favorite;
      const action = item.favorite ? "FavOn" : "FavOff";
      this.clientSettingModel.updateSetting(action, item.id);
      this.clientSettingModel.saveSetting();

      if (item.favorite === true) {
         const favOnId = item.id + "FavOn";
         setTimeout(() => {
            document.getElementById(favOnId).focus();
         }, 1000);
      } else {
         const favOffId = item.id + "FavOff";
         setTimeout(() => {
            document.getElementById(favOffId).focus();
         }, 1000);
      }

      // ensure close button's tooltip disappear automatically after click it
      if ($(".ui-tooltip-content").length > 0) {
         $(".ui-tooltip-content").parent("div").remove();
      }

      // Mark the prefer as dirty so we persist them when the sidebar is
      // closed.
      this.preferEvent.emit(true);
   };

   public favoriteKeydown = (event, item: EntitlementItem) => {
      if (!!event && event.keyCode === 13) {
         this.toggleFavorite(item);
      }
   };

   /*
    * enableShowOnlyFavorites
    *
    * Callback to enable favorites only mode for the available item list.
    */
   public enableShowOnlyFavorites = () => {
      this.showOnlyFavorites = true;
   };

   /*
    * disableShowOnlyFavorites
    *
    * Callback to disable favorites only mode for the available item list.
    */
   public disableShowOnlyFavorites = () => {
      this.showOnlyFavorites = false;
   };

   /*
    * check before launch to ensure user will not loss the data for multimon
    */
   public launchItem = (item: EntitlementItem) => {
      this.eventBusService.dispatch(new BusEvent.WindowReplacementPermission());
      const currentSessionIsDesktop = this.htmlRemoteSessionManager.isCurrentSessionDesktop();
      if (item.isRunning) {
         this.htmlRemoteSessionManager.activeSession(item.id);
         this.panelEvent.emit(PanelEvent.HIDE);
         return;
      }
      if (this.htmlRemoteSessionManager.isCurrentSession(item.id) && currentSessionIsDesktop) {
         this.panelEvent.emit(PanelEvent.HIDE);
         return;
      } else if (this.htmlRemoteSessionManager.isCurrentSession(item.id)) {
         this.connectToItemService.launchItem(item);
         return;
      }
      this.wmksService.whenNotInMultimon(() => {
         this.connectToItemService.launchItem(item);
      });
      // ensure close button's tooltip disappear automatically after click it
      if ($(".ui-tooltip-content").length > 0) {
         $(".ui-tooltip-content").parent("div").remove();
      }
   };

   public titanLaunchNativeItem = (item: EntitlementItem, launchType: LAUNCH_CLIENT_TYPE) => {
      this.xmlApiService.launchNativeItem(item, launchType);
   };

   public launchItem4HWS = (itemType, itemId) => {
      let item: EntitlementItem,
         itemIsApp,
         targetItem = null;

      for (let i = 0; i < this.entitledItems.length; i++) {
         item = this.entitledItems[i];
         itemIsApp = item.type === ENTITLE_TYPE.APPLICATION;
         if (
            (item.id === itemId || item.name === itemId) &&
            ((!itemIsApp && itemType === "Desktop") || (itemIsApp && itemType === "Application"))
         ) {
            targetItem = item;
         }
      }
      if (targetItem) {
         this.launchItem(targetItem);
      }
   };

   // below function is only for titan.
   public toggleContextMenu(horizonApp: TitanEntitlementItem, event: any): void {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }

      this.isDiffApp = this.contextMenuUtilService.isDiffApp(horizonApp);
      horizonApp.showContextMenu = !horizonApp.showContextMenu;

      if (this.isDiffApp && this.contextMenuUtilService.existingAppWithContextMenu) {
         this.contextMenuUtilService.existingAppWithContextMenu.showContextMenu = false;
      }
      this.contextMenuUtilService.updateExistingContextAppId(horizonApp.id);
      this.contextMenuUtilService.updateExistingContext(horizonApp);
   }

   public onUpdateSelectedProtocol(event) {
      const item = event.item,
         data = event.data;
      item.selectedProtocol = data;
      this.eventBusService.dispatch(new TitanMsg.TitanSetProtocolMsg(item.id, data));
      this.contextMenuUtilService.existingAppWithContextMenu.showContextMenu = false;
   }

   public onLaunchHorizonAppFromContextMenu(event) {
      const item = event.item,
         client = event.client;
      let launchType: LAUNCH_CLIENT_TYPE = LAUNCH_CLIENT_TYPE[client];
      if (launchType === undefined) {
         launchType = this.xmlApiService.getLaunchMethodForItem(item);
      }
      if (launchType === LAUNCH_CLIENT_TYPE.BROWSER) {
         this.launchItem(item);
      } else {
         this.titanLaunchNativeItem(item, launchType);
      }
      this.contextMenuUtilService.existingAppWithContextMenu.showContextMenu = false;
   }

   public launchItemKeydown = (event, entitledItem) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.launchItem(entitledItem);
      }
   };

   public toggleContextMenuKeydown = (event, entitledItem) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.toggleContextMenu(entitledItem, event);
      }
   };

   public disableShowOnlyFavoritesKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.disableShowOnlyFavorites();
      }
   };

   public enableShowOnlyFavoritesKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.enableShowOnlyFavorites();
      }
   };

   public getDesktopIcon = (item: EntitlementItem) => {
      return getDesktopImage(item);
   };
}
