/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 * Enter point of angular version of App
 * @format
 */

import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
   selector: "chrome-contextmenu",
   templateUrl: "./context-menu.component.html",
   styleUrls: ["../contextmenu-root/context-menu-content.component.less"]
})
export class ChromeContextmenuComponent {
   @Input() server;
   @Output() connectServerEvent: EventEmitter<any> = new EventEmitter();
   @Output() editServerEvent: EventEmitter<any> = new EventEmitter();
   @Output() removeServerEvent: EventEmitter<any> = new EventEmitter();
   constructor() {}

   public connectToServer = (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      this.connectServerEvent.emit(this.server);
      this.server.showContextMenu = false;
   };

   public editServer = (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      this.editServerEvent.emit(this.server);
      this.server.showContextMenu = false;
   };

   public removeServer = (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      this.removeServerEvent.emit(this.server);
      this.server.showContextMenu = false;
   };
}
