/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * ws1.service.ts
 *
 * Service for Workspace one integration
 */

export abstract class Ws1Service {
   public abstract isWS1Mode(): boolean;
   public abstract storeSpId(spID: string): void;
   public abstract redirectToWS1(ws1ServerName: string, windowObject: any): void;
   public abstract isLaunchedFromWS1(): boolean;

   constructor() {}
}
