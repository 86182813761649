/**
 * ******************************************************
 * Copyright (C) 2021-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../../core/libs/logger";
import { BCR_CONST } from "./html5MMR-consts";

export class BCRHelper {
   private whitelistPattern: string;
   private navWhitelistPattern: string;
   private enhWhitelistPattern: string;
   private enhBcrInjectedScript: string;

   private whiteListURLs: Set<string> = new Set();
   private enhWhiteListUrls: Set<string> = new Set();
   private allUrlsWhitelisted: boolean = false;
   private regexAllUrls = /^(\*|http|https):\/\/(\*|(?:\*\.)?(?:[^/*:]+)(?::[0-9]+)?)\/(.*)$/;

   /**
    * Cert errors referenced from CEF source code
    * https://github.com/chromium/chromium/blob/08f4b00a87c2e15a7517c88c12a394d7e61546c8/net/base/net_error_list.h#L457
    *
    * SSL_PINNED_KEY_NOT_IN_CERT_CHAIN, -150
    * CERT_COMMON_NAME_INVALID, -200
    * CERT_DATE_INVALID,-201
    * CERT_AUTHORITY_INVALID, -202
    * CERT_CONTAINS_ERRORS, -203
    * CERT_NO_REVOCATION_MECHANISM, -204
    * CERT_UNABLE_TO_CHECK_REVOCATION, -205
    * CERT_REVOKED, -206
    * CERT_INVALID, -207
    * CERT_WEAK_SIGNATURE_ALGORITHM, -208
    * CERT_NON_UNIQUE_NAME, -210
    * CERT_WEAK_KEY, -211
    * CERT_NAME_CONSTRAINT_VIOLATION, -212
    * CERT_VALIDITY_TOO_LONG, -213
    * CERTIFICATE_TRANSPARENCY_REQUIRED, -214
    * CERT_SYMANTEC_LEGACY, -215
    * CERT_KNOWN_INTERCEPTION_BLOCKED, -217
    */
   static certErrorList: Set<number> = new Set([
      -150, -200, -201, -202, -203, -204, -205, -206, -207, -208, -210, -211, -212, -213, -214, -215, -217
   ]);

   public constructor() {
      this.reset();
   }

   public set whitelist(pattern: string) {
      this.whitelistPattern = pattern;
   }

   public set navWhitelist(pattern: string) {
      this.navWhitelistPattern = pattern;
   }

   public set enhWhitelist(pattern: string) {
      this.enhWhitelistPattern = pattern;
   }

   public set enhancedBcrInjectedScript(script: string) {
      this.enhBcrInjectedScript = script;
   }

   public getUrlsPattern = (enhanced = false): Array<string> => {
      return enhanced
         ? Array.from(this.enhWhiteListUrls)
         : this.allUrlsWhitelisted
           ? [BCR_CONST.ALL_MATCH]
           : Array.from(this.whiteListURLs);
   };

   public getEnhBcrInjectedScript(): string {
      return this.enhBcrInjectedScript;
   }

   /**
    * Clear all buffer in the class
    * which includes parsed urls and flags.
    */
   private reset = () => {
      this.whiteListURLs.clear();
      this.enhWhiteListUrls.clear();
      this.allUrlsWhitelisted = false;
   };

   /**
    * Parse the pattern(s) sent by server
    * allUrlsWhitelisted = true,  if <all_urls> is present in whitelistPattern or navWhitelistPattern.
    *
    * If<all_urls> is not present in pattern, parse the pattern
    * and fill the set whiteListURLs.
    *
    * @param : none
    */
   public parseUrls = () => {
      let output;
      this.reset();

      this.parseWhiteList(this.whitelistPattern);
      if (!this.allUrlsWhitelisted) {
         this.parseWhiteList(this.navWhitelistPattern);
      }

      if (this.allUrlsWhitelisted) {
         Logger.info("All Urls are whitelisted.", Logger.BCR);
      } else {
         output = this.whiteListURLs.size > 0 ? JSON.stringify(Array.from(this.whiteListURLs), null, 1) : "null";
         output = output.replace(/\n|\r/g, "");
         Logger.info("Whitelisted URls are : " + output, Logger.BCR);
      }

      this.parseWhiteList(this.enhWhitelistPattern, true);
      output = this.enhWhiteListUrls.size > 0 ? JSON.stringify(Array.from(this.enhWhiteListUrls), null, 1) : "null";
      output = output.replace(/\n|\r/g, "");
      Logger.info("enhWhitelisted URLs are : " + output, Logger.BCR);
   };

   /**
    * Parse the pattern(s) sent by server.
    * if <all_urls> is present in the pattern, it means any of the website is whitelisted.
    * In above case keep the result in a boolean flag and return true for any url.
    *
    * If <all_urls> is not present, we may not have other patterns, separated by ","
    * In that case parse the "," separated urls and keep it inside an array.
    * The parsed url can contain wildcard "*" which means 0 - multiple chars.
    *
    * @param {string} pattern : Whitelisted pattern sent from server
    * @returns boolean - True if <all_urls> found in the pattern
    */
   private parseWhiteList = (
      pattern: string, //In
      isEnhBcr = false //In
   ): boolean => {
      try {
         if (!pattern || pattern.length === 0) {
            return false;
         }

         const allMatchFound: boolean = false;
         // Remove brackets []
         const input = pattern.slice(1, -1).trim();
         //if only [] is passed as input return;
         if (input.length === 0) {
            return false;
         }

         const allURLs = input.split(",");
         if (!allURLs) {
            return false;
         }

         for (let index = 0; index < allURLs.length; index++) {
            //Remove quotes from first and last position.
            const temp = allURLs[index].slice(1, -1).trim();
            if (temp === BCR_CONST.ALL_MATCH) {
               this.allUrlsWhitelisted = true;
               this.whiteListURLs.clear();
               break;
            } else if (temp && temp.length > 0 && temp.length < BCR_CONST.MAX_URL_LEN) {
               if (this.regexAllUrls.test(temp)) {
                  isEnhBcr ? this.enhWhiteListUrls.add(temp) : this.whiteListURLs.add(temp);
               } else {
                  Logger.error("Invalid pattern found: " + temp);
               }
            }
         }
         return allMatchFound;
      } catch (e) {
         this.whiteListURLs.clear();
         this.enhWhiteListUrls.clear();
         Logger.error("Parsing error inside parseWhiteList. " + (e as Error).message, Logger.BCR);
         return false;
      }
   };

   /**
    * Match an Url and a pattern.
    * Pattern Should follow - https://developer.chrome.com/docs/extensions/mv3/match_patterns/
    * Using Dynamic Programming to find a match.
    * Logic copied from Horizon Client code, Link : https://tinyurl.com/2x2zsvpu
    *
    * @param {string} inputUrl , {string} whiteListPattern
    * @returns boolean
    */
   private matchPattern = (inputUrl: string, whiteListPattern: string): boolean => {
      if (inputUrl === whiteListPattern) {
         return true;
      }

      const urlLen = inputUrl.length;
      const pattrenLength = whiteListPattern.length;

      //Init array of row = urlLen+1, col = patternLength+1, fill all with false
      const matchTable: boolean[][] = new Array(urlLen + 1)
         .fill(false)
         .map(() => new Array(pattrenLength + 1).fill(false));
      matchTable[0][0] = true;

      for (let col = 1; col < pattrenLength + 1; col++) {
         if (whiteListPattern[col - 1] == "*") {
            matchTable[0][col] = matchTable[0][col - 1];
         }
      }

      for (let row = 1; row < urlLen + 1; row++) {
         for (let col = 1; col < pattrenLength + 1; col++) {
            if (whiteListPattern[col - 1] == "*") {
               matchTable[row][col] = matchTable[row - 1][col] || matchTable[row][col - 1];
            }

            if (inputUrl[row - 1] == whiteListPattern[col - 1]) {
               matchTable[row][col] = matchTable[row - 1][col - 1];
            }
         }
      }
      const match = matchTable[urlLen][pattrenLength];
      return match;
   };

   /**
    * Check in case url matches with any of the pattern inside urlPattern
    *
    * @param {string} url
    * @returns boolean
    */
   private isURLAllowed = (url: string, whiteListSet = this.whiteListURLs): boolean => {
      if (whiteListSet.size === 0) {
         return false;
      }

      //If exact match found
      if (whiteListSet.has(url)) {
         return true;
      }

      //Look for pattern match and compare with each item.
      for (const currentPattern of whiteListSet) {
         if (this.matchPattern(url, currentPattern)) {
            Logger.info("url: " + url + " matched the pattern: " + currentPattern, Logger.BCR);
            return true;
         }
      }
      return false;
   };

   /**
    * If WhitelistUrl have <all_urls>
    * it means url is white listed.
    *
    * Else check whether the Url matched with any of the URL/pattern present
    * inside whiteListURLs.
    *
    * @param {string} url
    * @returns boolean
    */
   public isUrlWhiteListed = (url: string): boolean => {
      if (this.allUrlsWhitelisted) {
         return true;
      } else if (this.isURLAllowed(url)) {
         return true;
      }
      return false;
   };

   public isUrlEnhWhiteListed = (url: string): boolean => {
      if (this.isURLAllowed(url, this.enhWhiteListUrls)) {
         return true;
      }
      return false;
   };
}

/**
 * This const class is used to store JS and HTML code that is injected
 * into webview. Webview has a known issue where it cannot find files from a
 * relative path, so a work around is to use the code as a string instead of keeping
 * it in its own file.
 */
export const BCR_INJECTED_CODE = {
   BCR_TITLE_CONTENT_SCRIPT(instanceId: string): string {
      return `(${bcrTitleContentScript})(${instanceId});`;
   },
   ENHANCED_BCR_CONTENT_SCRIPT(instanceId: string): string {
      return `(${enhancedBcrContentScript})(${instanceId});`;
   },
   ABORT_ERROR_FLAG(url: string, reason: string, code: number): string {
      return `<html><head><title>Page failed to load</title></head>
      <body bgcolor="white"><h2>Page failed to load.</h2>
      URL:  ${url} <br/>Error:  ${reason} (${code})
      <br/>Please contact your administrator</body></html>`;
   }
};

/**
 * JS code that is injected into the webview's webpage
 * This script is vanilla JS since the guest page it is being
 * injected into might not support TS or our custom classes.
 */
export function bcrTitleContentScript(instanceId) {
   //prevent double injection
   let bcrContentScript = null;
   bcrContentScript =
      bcrContentScript ||
      (function () {
         let port = null;
         const BCR_CONST = {
            BCR: "BCR",
            TAB_TITLE: "tabTitle",
            FULLSCREEN: "fullScreen"
         };

         //Send message to port connection with website info
         const postTitle = function (title) {
            console.log("<bcr> received title change, sending title: " + title + "to webview with ID: " + instanceId);
            const data = {
               type: BCR_CONST.TAB_TITLE,
               tabInstanceId: instanceId,
               title: title,
               url: window.location.href
            };
            port.postMessage(data);
         };

         const handleFullScreenChange = function (e) {
            const isFullscreen = document.fullscreenElement != null;
            console.log(`<bcr> handleFullScreenChange called with isFullscreen=${isFullscreen}`);
            const data = {
               type: BCR_CONST.FULLSCREEN,
               tabInstanceId: instanceId,
               fullScreen: isFullscreen
            };
            port.postMessage(data);
         };

         //Create MutationObserver for title element if it exists and send initial title
         const init = function () {
            console.log(
               "<bcr> Initializing injected script with MutationObservers on a title element for WebView ID:" +
                  instanceId
            );
            port = chrome.runtime.connect({ name: BCR_CONST.BCR });
            const titleElement = document.querySelector("title");
            if (titleElement) {
               console.log("<bcr> title exists, adding MutationObserver");
               new MutationObserver(function (mutations) {
                  if (mutations[0].type === "childList") {
                     postTitle(mutations[0].target.firstChild.nodeValue);
                  }
               }).observe(document.querySelector("title"), {
                  subtree: true,
                  characterData: true,
                  childList: true
               });
               postTitle(document.title);
            } else {
               console.log("<bcr> title element does not exists");
            }

            document.onfullscreenchange = (event) => {
               handleFullScreenChange(event);
            };
         };

         //Run init when DOM has loaded or now if its already loaded
         if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", init);
         } else {
            init();
         }
      })();
}

/**
 * Injection script for enhanced BCR.
 * @param instanceId
 * @param bcrSide
 */
export function enhancedBcrContentScript(instanceId) {
   let enhancedBCRScript = null;
   enhancedBCRScript =
      enhancedBCRScript ||
      (function () {
         let port = null;
         let enhBcrScriptElement = null;

         /**
          * Message types facilitating communication between API injected script
          * and Webview. 0-3 are used directly for API calls and resolving promises.
          * 5-8 are used by Webview and the enhancedBcrContentScript to send messages to
          * the extension and properly inject the needed script.
          */
         const InjectedScriptMessageType = {
            INVALID: 0,
            OVERLAY_VISIBILITY: 1,
            SEND_MESSAGE: 2,
            REQUEST_DONE: 3,
            ENH_RESPONSE: 4,
            ENH_BCR_INITIALIZED: 5,
            INJECT_ENH_BCR_API_SCRIPT: 6,
            SEND_ENH_BCR_RESPONSE: 7,
            ENH_BCR_RECEIVED_MESSAGE: 8,
            REMOVE_ENH_BCR_API_SCRIPT: 9
         };

         /**
          * Near indicates the Webview running on client side.
          * Far indicates the browser running on agent side.
          */
         const EnhancedBCRSide = {
            INVALID: 0,
            NEAR: 1,
            FAR: 2
         };

         const dummyObject = `var bcrHelper = (function() {
         /*
         * API exposed to the website.
         */
         return {
            getSide: function() {
               return Promise.reject("Dummy Object, please wait for injection");
            },
            hideWebViewOverlay: function() {
               return Promise.reject("Dummy Object, please wait for injection");
            },
            showWebViewOverlay: function() {
               return Promise.reject("Dummy Object, please wait for injection");
            },
            sendMessageToOtherSide: function(message) {
               return Promise.reject("Dummy Object, please wait for injection");
            }
         };
      })();
      `;

         /**
          * emitEnhancedBCRAPIState
          *
          *   Posts an event indicating whether or not the injected script is ready
          *   and available.
          */
         const emitEnhancedBCRAPIState = function (state) {
            window.dispatchEvent(
               new CustomEvent("enhancedBCRAPIState", {
                  detail: {
                     ready: state
                  }
               })
            );
         };

         /**
          * Inject the enhBcr API script into the webpage
          * TODO: remove the script after it has been loaded into memory
          * @param enhancedAPIScript
          */
         const injectEnhBcrScript = function (enhancedAPIScript, emitState = true) {
            console.log("<enhBcr> Inject enhanced BCR script into webpage " + instanceId);
            if (enhBcrScriptElement) {
               document.getElementById("bcrHelper").remove();
               enhBcrScriptElement = null;
            }
            enhBcrScriptElement = document.createElement("script");
            enhBcrScriptElement.setAttribute("id", "bcrHelper");
            if (enhancedAPIScript !== null) {
               enhBcrScriptElement.innerHTML = enhancedAPIScript;
            }
            enhBcrScriptElement.dataset.browser = "Chrome";
            enhBcrScriptElement.dataset.tabId = instanceId;
            enhBcrScriptElement.dataset.enhancedBCRSide = "" + EnhancedBCRSide.NEAR;
            (document.head || document.documentElement).appendChild(enhBcrScriptElement);
            if (emitState) {
               //Emit API ready state. Since we are using .innerHTML we have to do it here
               console.log("<enhBcr> Injected script ready");
               emitEnhancedBCRAPIState(true);
            }
         };

         const sendMessageToInjectedScript = function (ref, val, obj) {
            console.log("<enhBcr> sendingMessageToInjectedScript val: " + val + " obj: " + obj);
            window.dispatchEvent(
               new CustomEvent("contentScriptMsg", {
                  detail: {
                     type: InjectedScriptMessageType.REQUEST_DONE,
                     ref: ref,
                     result: val,
                     returnObj: obj
                  }
               })
            );
         };

         const sendMessageToWebview = function (type, payload) {
            const data = {
               type: type,
               instanceId: instanceId,
               payload: payload
            };
            port.postMessage(data);
         };

         /**
          * After the content script loads webview sends the API script to be injected
          * into the webpage. This function then mediates communication between the client side
          * and agent side injected scripts. Messages get passed between then through here.
          * @param msg
          */
         const onMessageFromWebview = function (msg) {
            console.log("<enhBCR> Message received: " + msg.commandId + " id: " + msg.instanceId);
            if (msg.instanceId != instanceId) {
               return;
            }
            switch (msg.commandId) {
               case InjectedScriptMessageType.INJECT_ENH_BCR_API_SCRIPT:
                  injectEnhBcrScript(msg.script);
                  console.log("<enhBCR> API script has been injected");
                  break;
               case InjectedScriptMessageType.SEND_ENH_BCR_RESPONSE:
                  sendMessageToInjectedScript(msg.payload.ref, msg.payload.result, msg.payload.returnObj);
                  break;
               case InjectedScriptMessageType.ENH_BCR_RECEIVED_MESSAGE: {
                  window.dispatchEvent(
                     new CustomEvent("enhancedBCRMessage", {
                        detail: {
                           message: msg.payload.data
                        }
                     })
                  );
                  const payload = {
                     type: InjectedScriptMessageType.REQUEST_DONE,
                     ref: msg.payload.ref,
                     result: true,
                     returnObj: true
                  };
                  sendMessageToWebview(InjectedScriptMessageType.ENH_RESPONSE, payload);
                  break;
               }
               case InjectedScriptMessageType.REMOVE_ENH_BCR_API_SCRIPT:
                  document.getElementById("bcrHelper").remove();
                  enhBcrScriptElement = null;
                  emitEnhancedBCRAPIState(false);
                  console.log("<enhBCR> Removed API script from the webpage id:  " + msg.instanceId);
                  break;
               default:
                  console.log(
                     "<enhBCR> Unknown message received from Webview " + msg.commandId + " id: " + msg.instanceId
                  );
                  break;
            }
         };

         /**
          * Function handler for the custom event being fired by the injected API script.
          * Handles the API calls that need to pass information to the agent side. Forwards the
          * messages to Webview to be sent to agent. Mainly handles show/hideOverlay and sendMessage()
          * API calls.
          * @param event Custom event fired by the injected API script
          * @returns
          */
         const injectedScriptMsgListener = function (event) {
            if (typeof event.detail === "undefined" || event.detail === null) {
               console.log("injectedScriptMsgListener(): data is null in content script");
               return;
            }

            const msg = event.detail;
            console.log("injectedScriptMsgListener(): Received message from injected script " + JSON.stringify(msg));

            if (typeof msg.tabId === "undefined" || msg.tabId === null) {
               console.log("injectedScriptMsgListener(): tabId is not available");
               return;
            }

            if (msg.tabId != instanceId) {
               console.log("injectedScriptMsgListener(): tab ID sent does not match current ID (" + instanceId + ")");
               return;
            }

            if (typeof msg.type === "undefined" || msg.type === null) {
               console.log("injectedScriptMsgListener(): type is not available");
               return;
            }

            if (typeof msg.payload === "undefined" || msg.payload === null) {
               console.log("injectedScriptMsgListener(): payload property not found");
               return;
            }

            if (typeof msg.payload.ref === "undefined" || msg.payload.ref === null) {
               console.log("injectedScriptMsgListener(): payload ref not found");
               return;
            }

            switch (msg.type) {
               case InjectedScriptMessageType.OVERLAY_VISIBILITY:
                  {
                     if (typeof msg.payload.show === "undefined" || msg.payload.show === null) {
                        console.log("injectedScriptMsgListener(): show property not found");
                        break;
                     }
                     sendMessageToWebview(msg.type, msg.payload);
                  }
                  break;
               case InjectedScriptMessageType.SEND_MESSAGE:
                  {
                     if (typeof msg.payload.data === "undefined" || msg.payload.data === null) {
                        console.log("injectedScriptMsgListener(): data property not found");
                        break;
                     }
                     sendMessageToWebview(msg.type, msg.payload);
                  }
                  break;
               default:
                  {
                     console.log("injectedScriptMsgListener(): Invalid message type " + msg.type);
                  }
                  break;
            }
         };

         const init = function () {
            console.log("<enhBCR> Initializing content script" + instanceId);
            //Initially add dummy object to the webpage
            injectEnhBcrScript(null, false);
            port = chrome.runtime.connect({ name: "ENH_BCR" });
            sendMessageToWebview(InjectedScriptMessageType.ENH_BCR_INITIALIZED, "");
            port.onMessage.addListener(onMessageFromWebview);

            if (window && window.addEventListener) {
               window.addEventListener("injectedScriptMsg", injectedScriptMsgListener);
            }
         };

         if (window.top == window.self) {
            init();
         }
      })();
}
