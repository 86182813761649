/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

//import Logger from '../../../webapp/webclient/core/libs/logger';
import {SDKEvent} from "./event-helper/event-helper"

export type SDKResponseInterface = {
   response: SDKEvent,
   callbackParamter: any
};

type AnyCallback = (any) => void;
type ResponseCallback = (ResponseCallbackData) => void;
class RequestInfoCache {
   constructor(
      public requestId: string,
      public callbackParamter: any,
      public resolve: ResponseCallback,
      public reject: AnyCallback,
   ){}
}

interface ResponseContent {
   success: boolean
   data?: any
}
export type ResponseCallbackData = {
   responseContent: ResponseContent
   callbackParamter: any
};
class EventQueue {
   private _requestInfoCaches = new Map<string, RequestInfoCache>()
   constructor (private _peerId:string) {}
   public onSendingRequest = (requestId: string, callbackParamter, resolve: ResponseCallback, reject: AnyCallback) => {
      this._requestInfoCaches.set(requestId, new RequestInfoCache(requestId, callbackParamter, resolve, reject));
   };
   public onReceivingResponse = (response: SDKEvent) => {
      const cachedInfo = this._requestInfoCaches.get(response.requestId);
      if (!cachedInfo || typeof cachedInfo.resolve !== "function") {
         //Logger.error("failed to find cached info");
         return;
      }
      cachedInfo.resolve({
         responseContent: response,
         callbackParamter: cachedInfo.callbackParamter
      });
      this._requestInfoCaches.delete(response.requestId);
   };
}

export class EventManager {
   private _peerMap = new Map<string, EventQueue>()
   constructor () {}
   public onSendingRequest = (peerAddress: string, requestId:string, callbackParamter, resolve: ResponseCallback, reject: AnyCallback) => {
      if(!this._peerMap.has(peerAddress)){
         this._peerMap.set(peerAddress, new EventQueue(peerAddress));
      }
      this._peerMap.get(peerAddress).onSendingRequest(requestId, callbackParamter, resolve, reject);
   };
   public onReceivingResponse = (peerAddress: string, response: SDKEvent) => {
      if(!this._peerMap.has(peerAddress)){
         //Logger.error("missing peerAddress record for " + peerAddress);
      }
      this._peerMap.get(peerAddress).onReceivingResponse(response);
   };
}

