/**
 * ******************************************************
 * Copyright (C) 2012-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * logger.js --
 *
 *      Log class for the web client.
 */

import $ from "jquery";

/**
 * get caller details, such as caller function name, line num, file name
 *
 * @return Object detail JSON
 */
function getCallerDetail() {
   // now support Firefox 3.5 or higher, IE 8 or higher, Chrome 3 or higher,
   // Safari 5.0 or higher IE before version 10 and Safari don't support
   // Error.stack, so no caller detail will be showed
   const callerDetail: any = {}; // caller detail JSON to return
   let err, callerLine, index, content, str, callStack, callerIndex;
   let functionName = "",
      fileName = "",
      lineNum = "";
   let fileNameAndLineNumStr, fileNameAndLineNumArray;
   let objFuncRegExp, funcRegExp, lineNumRegExp;

   // throw error to get caller details
   try {
      throw new Error("");
   } catch (error) {
      err = error;
   }

   if (!err || !err.stack) {
      return callerDetail;
   }

   try {
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
         // the index of line containing caller's detail is 3
         callerLine = err.stack.split("\n")[3];
         fileNameAndLineNumStr = $.trim(callerLine.substring(callerLine.lastIndexOf("/") + 1, callerLine.length));
         fileNameAndLineNumArray = fileNameAndLineNumStr.split(":");
         // the array only has two elements
         fileName = fileNameAndLineNumArray[0];
         lineNum = fileNameAndLineNumArray[1];
         // there is no function name in error stack other than some function
         // related data, so put empty string to it
         functionName = "";
      } else {
         /**
          * For error stack information is used here, details of direct
          * caller of Logger are on the line with index 4 or 5,
          * depending on the browser.
          */
         callStack = err.stack.split("\n");
         if (callStack.length < 2) {
            return callerDetail;
         }
         callerIndex = 3; // set default value
         const ua = window.navigator.userAgent.toLowerCase();
         const isIE = ua.indexOf("msie") !== -1 || ua.indexOf("trident") !== -1 || ua.indexOf("edge") !== -1;
         if (navigator.userAgent.toLowerCase().indexOf("AppleWebKit") > -1) {
            // Chrome puts Error in the callstack, however, error stack on
            // chrome may contains "at Error..." in the second line or not,
            // so add a branch to determine callerIndex
            if (callStack[1].indexOf("at Error") !== -1) {
               callerIndex = 5;
            } else {
               callerIndex = 4;
            }
         } else if (isIE) {
            // old-version IE except IE10 don't support Error.stack, so this
            // branch will not be entered by them IE10 doesn't put Error in
            // the callstack, so we look at index 4.
            callerIndex = 4;
         }
         if (callStack.length <= callerIndex) {
            return callerDetail;
         }
         callerLine = callStack[callerIndex];
         index = callerLine.indexOf("at ");
         // 3 represents the length of 'at '
         content = callerLine.slice(index + 3, callerLine.length);

         objFuncRegExp = /Object\.(.*?)\(/; // regexp to handle function in
         // Object
         funcRegExp = /(.*?)\(/; // regexp to handle normal function

         // get function name
         str = objFuncRegExp.exec(content);
         // handle functions in object and normal functions below
         functionName = $.trim(str ? str[1] : funcRegExp.exec(content)[1]) + "()";

         // get line number
         lineNumRegExp = /(.*?):(.*?):(.*?):/;
         // 3 represents index of lineNum in results which match this regular
         // expression
         lineNum = $.trim(lineNumRegExp.exec(content)[3]);

         // get file name
         // 2 represents index of string which contains file name to be
         // parsed
         str = lineNumRegExp.exec(content)[2];
         fileName = $.trim(str.substring(str.lastIndexOf("/") + 1, str.length));
      }
   } catch (e) {
      console.error(e);
   }

   callerDetail.functionName = functionName;
   callerDetail.fileName = fileName;
   callerDetail.lineNum = lineNum;

   return callerDetail;
}

/**
 * Helper function used to create a string with a desired length by padding
 * it with preceding zeroes, if necessary.
 *   pad(1, 5) will return "00001".
 *   pad("100", 1) will return "100".
 *   pad(-1, 5) will return "-00001".
 *   pad("-10", 4) will return "-0010".
 *
 * @param value[in] value that will be padded, if necessary.
 * @param maxLen[in] length that will be used to pad zeroes, if necessary.
 * @return string padded to appropriate length.
 */
function pad(value, maxLen) {
   const string = value.toString(),
      negative = string.indexOf("-") === 0,
      length = negative ? string.length - 1 : string.length;
   return length < maxLen ? pad(negative ? "-0" + string.slice(1) : "0" + string, maxLen) : string;
}

export class Logger {
   public static readonly UNITY = "unity";
   public static readonly UNITY_BORDER = "unity.border";
   public static readonly PRINTER_RD = "PrintRd";
   public static readonly PRINTER_FRAME = "PrintFrame";
   public static readonly WMKS = "wmks";
   public static readonly AUDIO = "audio";
   public static readonly DPI = "dpi";
   public static readonly CLIPBOARD = "clipboard";
   public static readonly CDR = "cdr";
   public static readonly RTAV = "rtav";
   public static readonly RDPDR = "rdp.dr";
   public static readonly SMART_CARD = "smart_card";
   public static readonly VDP_UTIL = "vdp.util";
   public static readonly IMAGE_CACHE = "i.cache";
   public static readonly ROUTE = "route";
   public static readonly JSCDK = "jscdk";
   public static readonly DISPLAY = "monitor";
   public static readonly VDP = "vdp";
   public static readonly UI = "ui";
   public static readonly FILE_TRANSFER = "ft";
   public static readonly USB = "usb";
   public static readonly SDK = "sdk_event";
   public static readonly CHAN_UTIL = "channel.util";
   public static readonly RDPVCBRIDGE = "rdpvcbridge";
   public static readonly SDK_BLAST = "sdk_blast";
   public static readonly HTML5MMR = "Html5MMR";
   public static readonly MEDIA = "media";
   public static readonly WEBRTC = "webrtc";
   public static readonly WEBRTCMEDIA = "webrtc.media";
   public static readonly TITAN = "titan";
   public static readonly GEOLOCATION = "geolocation";
   public static readonly NORMALIZATION = "normalization";
   public static readonly SYSTRAY = "systray";
   public static readonly IDLETIMEOUT = "idle_timeout";
   public static readonly BCR = "bcr";
   public static readonly AUTO_USB = "auto_usb";
   public static readonly SPLIT_USB = "split_usb";
   public static readonly POLICY = "policy";
   public static readonly NETWORK_STATE = "network_state";
   public static readonly NOTCH_MONITOR = "notch_monitor";
   public static readonly ON_RAMP = "onramp";

   public static readonly LEVEL_TRACE: number = 0;
   public static readonly LEVEL_DEBUG: number = 1;
   public static readonly LEVEL_INFO: number = 2;
   public static readonly LEVEL_WARNING: number = 3;
   public static readonly LEVEL_ERROR: number = 4;
   public static readonly LEVEL_DESC = ["T", "D", "I", "W", "E"];

   public static LOG_LEVEL = 1;
   public static readonly Modules = {
      RDPVCBridge: "RDPVCBridge",
      ChromeSDK: "ChromeSDK",
      Unity: "Unity"
   };

   private static logAppendix: string = null;
   private static logFunction: Function = null;
   private static extendedLogFunction: Function = null;

   private prefix: string;
   private moduleName: string;
   private static debugConfiguration: any;

   constructor(name: string, session: string = null, moduleName: string = null) {
      this.prefix = name;
      this.moduleName = moduleName;
      if (session) {
         this.prefix = this.prefix + ":" + session;
      }
   }

   private static isModuleDebugEnabled = (moduleName: string): boolean => {
      let flag = false;
      if (
         !!Logger.debugConfiguration &&
         Logger.debugConfiguration.isEnabled &&
         !!Logger.debugConfiguration.debugModuleList
      ) {
         Logger.debugConfiguration.debugModuleList.forEach((entry) => {
            if (entry.moduleName === moduleName) {
               flag = entry.isEnabled;
            }
         });
      }
      return flag;
   };

   public static setDebugConfiguration = (debugConfiguration: any) => {
      Logger.debugConfiguration = debugConfiguration;
   };

   public trace = (args: string) => {
      Logger.trace(args, this.prefix);
   };
   public debug = (args: string) => {
      Logger.debug(args, this.prefix);
   };
   public info = (args: string) => {
      Logger.info(args, this.prefix);
   };
   public warning = (args: string) => {
      Logger.warning(args, this.prefix);
   };
   public error = (args: string) => {
      Logger.error(args, this.prefix);
   };
   public dump = (arg: string) => {
      if (Logger.isModuleDebugEnabled(this.moduleName)) {
         this.trace(arg);
      }
   };

   public exception = (exception: Error) => {
      Logger.exception(exception, this.prefix);
   };

   public static trace(args: string, prefix: string = null) {
      Logger.log(args, Logger.LEVEL_TRACE, prefix);
   }

   public static debug(args: string, prefix: string = null) {
      Logger.log(args, Logger.LEVEL_DEBUG, prefix);
   }

   public static info(args: string, prefix: string = null) {
      Logger.log(args, Logger.LEVEL_INFO, prefix);
   }

   public static warning(args: string, prefix: string = null) {
      Logger.log(args, Logger.LEVEL_WARNING, prefix);
   }

   public static error(args: string, prefix: string = null) {
      Logger.log(args, Logger.LEVEL_ERROR, prefix);
   }
   public static dump = (arg: string, prefix: string = null, moduleName: string = null) => {
      if (Logger.isModuleDebugEnabled(moduleName)) {
         Logger.trace(arg, prefix);
      }
   };

   public static exception(exception: Error, prefix: string = null) {
      if (!exception || !(exception instanceof Error)) {
         Logger.error(String(exception), prefix);
      } else {
         Logger.error(exception.stack || exception.message, prefix);
      }
   }

   public static addRawLog(logString) {
      if (typeof Logger.logFunction === "function") {
         Logger.logFunction(logString);
      }
      if (!!window.console && typeof window.console.log === "function") {
         window.console.log(logString);
      }
   }

   public static addExtendedLog(logString, level) {
      if (typeof Logger.extendedLogFunction === "function") {
         Logger.extendedLogFunction(logString, level);
      }
   }

   public static setExtendedLogFun(logFn) {
      Logger.extendedLogFunction = logFn;
   }

   public static log(args: string, level: number = Logger.LEVEL_DEBUG, prefix: string = null): void {
      let logTime,
         logString,
         callerDetail: any = {},
         functionName,
         fileName,
         lineNum;

      if (level >= Logger.LOG_LEVEL) {
         if (args) {
            logTime = new Date();
            callerDetail = getCallerDetail();
            functionName = callerDetail.functionName ? callerDetail.functionName : "";
            fileName = callerDetail.fileName ? callerDetail.fileName : "";
            lineNum = callerDetail.lineNum ? callerDetail.lineNum : "";
            logString =
               "[" +
               logTime.getFullYear() +
               "-" +
               pad(logTime.getMonth() + 1, 2) +
               "-" +
               pad(logTime.getDate(), 2) +
               "T" +
               pad(logTime.getHours(), 2) +
               ":" +
               pad(logTime.getMinutes(), 2) +
               ":" +
               pad(logTime.getSeconds(), 2) +
               "." +
               pad(logTime.getMilliseconds(), 3) +
               "][" +
               Logger.LEVEL_DESC[level] +
               "]";

            if (prefix) {
               logString += " <" + prefix + ">";
            }

            logString += ": " + args;
         }
         if (Logger.logAppendix) {
            $(Logger.logAppendix).append(logString + "<br />");
         }
         if (typeof Logger.logFunction === "function") {
            Logger.logFunction(logString);
         }
         if (typeof Logger.addExtendedLog === "function") {
            Logger.addExtendedLog(logString, level);
         }
         if (!!window.console && typeof window.console.log === "function") {
            window.console.log(logString);
         }
      }
   }

   /**
    * Set the log level.
    *
    * @param logLevel [in] the log level of the application.
    */
   public static setLogLevel(logLevel) {
      Logger.LOG_LEVEL = logLevel;
   }

   public static getLogLevel() {
      return Logger.LOG_LEVEL;
   }

   /**
    * Set the log appendix.
    *
    * @param logAppendix [in] a div object to show the logs.
    */
   public static setLogAppendix(logAppendix) {
      Logger.logAppendix = logAppendix;
   }

   /**
    * Set the custom log function.
    *
    * @param logFn [in] a function used to log.
    */
   public static setLogFunction(logFn) {
      Logger.logFunction = logFn;
   }

   public static isTraceEnabled() {
      return Logger.LOG_LEVEL === Logger.LEVEL_TRACE;
   }

   public static stringify(object: any) {
      let stringifyStr: string = "";
      try {
         stringifyStr = JSON.stringify(object);
      } catch (e) {
         Logger.error(e);
      }
      return stringifyStr;
   }
}

export default Logger;
