/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export enum NetWorkMonitorType {
   GLOBAL_MONITOR,
   UAG_MONITOR
}
