/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * use this to manage the session status
 */

// prepare to accept connections from the window for this session.
import { Injectable } from "@angular/core";
import Logger from "../../../../core/libs/logger";
import { BusEvent, clientUtil, EventBusService, TranslateService, TitanMsg } from "@html-core";
import { Port } from "../../../base/model/rx-bridge-type";
import { ConnectedMessageService } from "../../../base/service/connected-message.service";
import { RXBridgeSource } from "./rx-bridge-source.service";
import { RemoteSettingManager } from "./remote-setting-manager.service";
import { SessionUtil } from "../../../../shared/common/service/session-util";
import { SessionManagementCenterManager } from "./session-management-center-manager.service";
import { IdleSessionService } from "../../../../shared/common/service/idle-session.service";
import { Ws1Service } from "../../../../shared/common/service/ws1.service";
import { AjaxBusyService } from "../../../../shared/common/ajax-busy/ajax-busy.service";
import { ModalDialogService } from "../../../../shared/common/commondialog/dialog.service";
import { RootModel } from "../../../../shared/common/model/root-model";
import { AutoFowardUSBService } from "../../../../shared/launcher/auto-usb-setting/auto-usb-foward.service";
import { RestfulService } from "../../../../titan/services/restful.service";
import { ConnectionServerModel } from "../../../../shared/common/model/connection-server-model";

/*
 *
 * Keep this service injected with the provider in the Common Module rather than with
 * the @Injectable() decorator.
 *
 * It is used in the shared codes with the @Optional() decorator.
 */
@Injectable()
export class RemoteSessionManager {
   private _sessionMap = {};
   private _jscdkWrapper = null;
   private nextWindowCounter = 1;
   private onSessionListAddedCallback: Function = null;
   private onSessionListRemovedCallback: Function = null;
   private onFirstAppLaunchedCallback: Function = null;
   private allSessionRemoved: boolean = false;
   public focusedSession;

   constructor(
      private connectedMessageService: ConnectedMessageService,
      private rxBridgeSource: RXBridgeSource,
      private remoteSettingManager: RemoteSettingManager,
      private sessionUtil: SessionUtil,
      private sessionManagementCenterManager: SessionManagementCenterManager,
      private idleSessionService: IdleSessionService,
      private ws1Service: Ws1Service,
      private ajaxBusyService: AjaxBusyService,
      private modalDialogService: ModalDialogService,
      private eventBusService: EventBusService,
      private rootModel: RootModel,
      private translate: TranslateService,
      private autoFowardUSBService: AutoFowardUSBService,
      private restfulService: RestfulService,
      private connectionServerModel: ConnectionServerModel
   ) {
      this.sessionManagementCenterManager.init(this);
      this.idleSessionService.addEventListener("idleSessionWarning", this.focusOnLauncher);
      this.idleSessionService.addEventListener("idleSessionTimeout", this.focusOnLauncher);
      this.eventBusService.listen("lastSessionRemoved").subscribe(() => {
         this.allSessionRemoved = true;
         this.eventBusService.dispatch(
            new BusEvent.PolicyTriggeredQuit("enableDisconnectionTriggeredQuit", this.connectionServerModel.host)
         );
      });
   }

   // use (new Date).getTime() to avoid using same id
   public getNextSessionWindowId = () => {
      return "session-window-" + this.nextWindowCounter++ + "_" + new Date().getTime();
   };

   public getSessionWindowByWmksKey = (wmksKey) => {
      if (!this._sessionMap[wmksKey]) {
         Logger.debug(wmksKey + " doesn't in sessionMap");
         // To fix bug 2887056. wmksKey is different with entitleId
         for (const key in this._sessionMap) {
            if (this._sessionMap[key].entitleId === wmksKey) {
               return chrome.app.window.get(this._sessionMap[key].windowId);
            }
         }
         return null;
      }

      if (!this._sessionMap[wmksKey].windowId) {
         // Please see VHCH-3715 for details.
         // Add log here to triage later if the logic enter here.
         Logger.debug(wmksKey + " doesn't have a window");
         return null;
      }
      return chrome.app.window.get(this._sessionMap[wmksKey].windowId);
   };

   public setJscdkWrapper = (jscdkWrapper) => {
      this._jscdkWrapper = jscdkWrapper;
   };

   public _logoffDesktop = (content) => {
      if (clientUtil.isTitanClient()) {
         this.restfulService.handleSessionOps(content.sessionId, TitanMsg.TITAN_VM_OPS.LOGOFF).subscribe((msg) => {
            Logger.info("log off desktop from top bar operation");
         });
      } else {
         this._jscdkWrapper.killItem(content.entitleId, "Desktop", () => {
            console.log("log off desktop from top bar operation");
         });
      }
   };

   public _disconnectDesktop = (sessionId) => {
      this.connectedMessageService.sendMessage(sessionId, "Session", {
         type: "requestSessionDisconnect"
      });
   };

   private _closeAllWindows = (timeout) => {
      if (this.allSessionRemoved && !this.rootModel.get("sessionExpired")) {
         window.chromeClient.waitingForClose = true;
         if (timeout === 0) {
            clientUtil.closeAllWindows();
         } else {
            setTimeout(() => {
               if (window.chromeClient.waitingForClose) {
                  clientUtil.closeAllWindows();
               }
            }, timeout);
         }
      }
   };

   public closeAppsInWS1 = () => {
      let timeout = 0;
      if (this.ws1Service.isWS1Mode()) {
         this.modalDialogService.dialogMap.forEach((dialog, _) => {
            const title = dialog.componentInstance.title;
            const errMsg = dialog.componentInstance.errorMessage;
            if (
               title === this.translate._T("dialog_title_locked") ||
               title === this.translate._T("dialog_title_about_to_timeout")
            ) {
               timeout = 30000;
            } else if (title === this.translate._T("DESKTOP_DISCONNECTED_T")) {
               timeout = 30000;
               //fix bug 2787287, add callback func for dialog
               dialog.closed.subscribe(() => {
                  const _timeout = 3000;
                  this._closeAllWindows(_timeout);
               });
            } else if (errMsg === this.translate._T("error_not_authenticated")) {
               //fix bug 2774286, client won't quit till session expire dialog is closed
               this.rootModel.set("sessionExpired", true);
            }
         });
         this._closeAllWindows(timeout);
      }
   };

   private setSessionConnected = (sessionKey: string, status: boolean) => {
      if (!this._sessionMap.hasOwnProperty(sessionKey)) {
         Logger.error("no session to set the connected status");
      }
      this._sessionMap[sessionKey].connected = status;
   };

   public isSessionConnected = (sessionKey: string): boolean => {
      if (!this._sessionMap.hasOwnProperty(sessionKey)) {
         return false;
      }
      return !!this._sessionMap[sessionKey].connected;
   };

   public registerNewSession = (sessionKey, sessionInfo) => {
      if (!clientUtil.isSeamlessMode()) {
         Logger.error("remoteSessionManager should only be used for seamless window mode for now");
         return;
      }
      this.remoteSettingManager.registerNewSession(sessionKey);
      Logger.info("registering new sessionKey for seamless window mode: " + sessionKey);
      this.connectedMessageService.addAcceptedId(sessionKey);
      this.connectedMessageService.onMessage(sessionKey, Port.ChannelName.Session, (message) => {
         if (!message) {
            Logger.error("empty message" + message);
            return;
         }
         Logger.trace("get session message on " + sessionKey + " of :\n" + JSON.stringify(message));
         switch (message.type) {
            case Port.SessionMsg.onConnecting: {
               this.rxBridgeSource.onSessionConnecting(sessionKey);
               break;
            }
            case Port.SessionMsg.onConnected: {
               this.rxBridgeSource.onSessionConnected(sessionKey);
               this.ajaxBusyService.setAjaxBusy(false);
               this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
               this.setSessionConnected(sessionKey, true);
               break;
            }
            case Port.SessionMsg.onDisconnecting: {
               break;
            }
            case Port.SessionMsg.onDisconnected: {
               this.setSessionConnected(sessionKey, false);
               this.rxBridgeSource.onSessionDisconnected(sessionKey);
               this.ajaxBusyService.setAjaxBusy(false);
               this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
               this.closeAppsInWS1();
               break;
            }
            case Port.SessionMsg.onClosed: {
               this.setSessionConnected(sessionKey, false);
               this.ajaxBusyService.setAjaxBusy(false);
               this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
               this.sessionUtil.onSessionRemoved(undefined, sessionKey);
               if (this._sessionMap.hasOwnProperty(sessionKey)) {
                  let firstAppLaunched: boolean = false;
                  if (
                     this._sessionMap[sessionKey].isDesktopSession === false &&
                     this._sessionMap[sessionKey].isMultiSession === false
                  ) {
                     this._sessionMap[sessionKey].closeAppSession = true;
                     for (const key in this._sessionMap) {
                        if (
                           this._sessionMap[key].isDesktopSession === false &&
                           this._sessionMap[key].isMultiSession === false &&
                           this._sessionMap[key].closeAppSession !== true
                        ) {
                           firstAppLaunched = true;
                        }
                     }
                     if (this.onFirstAppLaunchedCallback) {
                        this.onFirstAppLaunchedCallback(firstAppLaunched);
                     }
                  }
                  Logger.info("close window since session disconnected");
                  this._sessionMap[sessionKey].blastWindow.close();
                  this.closeAppsInWS1();
                  return;
               }
               break;
            }
            case Port.SessionMsg.onReconnectToken: {
               const reconnectToken = message.content;
               this.sessionUtil.updateReconnectToken(sessionKey, reconnectToken);
               break;
            }
            case Port.SessionMsg.onSessionActive: {
               this.focusOn(sessionKey);
               break;
            }
            case Port.SessionMsg.sessionMessage: {
               const messageDetails = message.content;
               this.showMessage(sessionKey, messageDetails);
               break;
            }
            case Port.SessionMsg.desktopSessionLogOut: {
               this._logoffDesktop(message.content);
               break;
            }
            case Port.SessionMsg.desktopSessionDisconnect: {
               this._disconnectDesktop(message.content.sessionId);
               break;
            }
            default: {
               Logger.error("invalid message type" + JSON.stringify(message));
            }
         }
         return;
      });
      this._sessionMap[sessionKey] = sessionInfo;
      if (typeof this.onSessionListAddedCallback === "function") {
         this.onSessionListAddedCallback(sessionKey, sessionInfo);
      }
   };

   /**
    * remove the session from session map, which could be triggered
    * - close/kill app window
    * - network disconnect
    * - logout/sign-out/restart in remote RDSH server.
    * - other disconnect reason
    */
   public unregisterSession = (sessionKey) => {
      const sessionWindowId = this._sessionMap[sessionKey].windowId;
      this.connectedMessageService.releaseConnection(sessionKey);
      this.rxBridgeSource.onSessionDisconnected(sessionKey);
      if (typeof this.onSessionListRemovedCallback === "function") {
         this.onSessionListRemovedCallback(sessionKey, this._sessionMap[sessionKey]);
      }
      delete this._sessionMap[sessionKey];

      this.clearUpWindowsGeneratedBy(sessionWindowId);
      this.focusOn();
   };

   public clearUpWindowsGeneratedBy = (blastWindowId) => {
      const allWindows = chrome.app.window.getAll();
      allWindows.forEach((window, index) => {
         if (window.id !== blastWindowId && window.id.includes(blastWindowId)) {
            Logger.info("remove session related window due to session ends: " + window.id);
            window.close();
         }
      });
   };

   public focusOn = (sessionKey?: any) => {
      if (sessionKey) {
         if (!this._sessionMap.hasOwnProperty(sessionKey)) {
            Logger.error("focusing on a invalid session " + sessionKey);
            return;
         }
         this._sessionMap[sessionKey].blastWindow.focus();
         Logger.info("focus on session " + sessionKey);
      } else {
         // if not find any session, focus on launcher.
         this.focusOnLauncher();
      }
   };

   public focusOnLauncher = () => {
      if (this.hasApplicationSession()) {
         chrome.app.window.current().focus();
      }
   };

   public onWindowCreated = (sessionKey, blastWindow) => {
      if (!this._sessionMap.hasOwnProperty(sessionKey)) {
         Logger.error("invalid session key for created blast window");
         return;
      }
      this._sessionMap[sessionKey].blastWindow = blastWindow;
      this._sessionMap[sessionKey].windowId = blastWindow.id;
   };

   public getSessionMap = () => {
      return this._sessionMap;
   };

   public onSessionListAdded = (callback) => {
      this.onSessionListAddedCallback = callback;
   };

   public onSessionListRemoved = (callback) => {
      this.onSessionListRemovedCallback = callback;
   };

   public onFirstAppLaunched = (callback) => {
      this.onFirstAppLaunchedCallback = callback;
   };

   public hasApplicationSession = () => {
      for (const sessionKey in this._sessionMap) {
         if (this._sessionMap[sessionKey].isDesktopSession === false) {
            return true;
         }
      }
      return false;
   };

   public messageHandled = (sessionKey, response) => {
      this.connectedMessageService.sendMessage(sessionKey, Port.SessionMsg.sessionMessageHandled, response);
   };

   public hasDesktopSession = () => {
      for (const sessionKey in this._sessionMap) {
         if (this._sessionMap[sessionKey].isDesktopSession) {
            return true;
         }
      }
      return false;
   };

   public showMessage = (sessionKey, messageDetails) => {
      if (!this._sessionMap.hasOwnProperty(sessionKey)) {
         return;
      }
      const session = this._sessionMap[sessionKey];

      // if disconnected dialog or timeout error message dialog is already show, do not show disconnect dialog
      if (this.modalDialogService.isDialogOpen(session.remoteDialogId) || this.idleSessionService.sessionTimedOut) {
         this.messageHandled(sessionKey, {
            handled: false,
            originalParameters: messageDetails
         });
         return;
      }

      if (messageDetails.type === "Error") {
         Logger.debug("showing dialog for" + sessionKey);
         session.remoteDialogId = this.modalDialogService.showError({
            data: {
               title: messageDetails.data.title,
               content: messageDetails.data.errorMessage
            },
            callbacks: {
               confirm: () => {
                  this.messageHandled(sessionKey, {
                     handled: true,
                     originalParameters: messageDetails
                  });
               }
            }
         });
      }
   };
}
