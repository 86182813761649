<div class="modal-title dialog-title">{{title}}</div>
<div class="modal-body dialog-content">
   <div class="session-ops-window-text">{{message}}</div>
   <div id="session-ops-warning-ft" class="session-ops-window-text warning" [hidden]="!showWarning || !isTransferringFile">{{ 'UNSAVED_WORK_FT_M' | i18nv2 }}</div>
   <div id="session-ops-warning-print" class="session-ops-window-text warning" [hidden]="!showWarning || !isPrintingFile">{{ 'UNSAVED_WORK_PRINT_M' | i18nv2 }}</div>
   <div id="session-ops-warning-logoff" class="session-ops-window-text warning" [hidden]="!showWarning || !isLogOffDesktop">{{ 'warningcontent' | i18nv2 }}</div>
</div>
<div class="modal-footer dialog-button-row">
   <button type="button" class="modal-button-base modal-button-right modal-button-grey" id="cancelDialogBtn" (click)="closeModal()">
      {{ (buttonLabelCancel) || ('CANCEL' | i18nv2)}}
   </button>
   <button type="button" class="modal-button-base modal-button-right modal-button-blue ok-button" id="okDialogBtn" (click)="confirmModal()" ngbAutofocus>
      {{ (buttonLabelConfirm) || ('OK' | i18nv2) }}
   </button>
</div>