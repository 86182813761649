/**
 * *************************************************************
 * Copyright (C) 2015-2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview rtavSession.ts
 *
 * The Class that contains all information for a rtav session.
 * @param {string} sessionId  The ID of vvcSession
 * @param {object} vvcSession The vvcSession object passed from wmks, with send and openChannel API.
 *                            Is null in seamless window mode.
 */

import { Injectable } from "@angular/core";
import { RtavChannel } from "./rtavChannel";
import { RtavSettingManager } from "./rtavSettingManager";
import { RtavStatus } from "./rtavStatus";
import { RTAVProtocolService } from "./rtavProtocol.service";
import { DeviceMessageManager } from "./deviceMessageManager";
import { RtavSessionController } from "./rtavSessionController";
import { CodecTypes, RTAV_AUDIO_DISABLED, RTAV_VIDEO_DISABLED, RtavTypes } from "./rtav.constants";
import { ResourceManager } from "./resourceManager";
import { RtavDialogService } from "./rtavDialog.service";
import { EventBusService } from "../../../core/services/event";
import { MessageWaitManager } from "./messageWaitManager";
import { DeviceMessageManagerV2 } from "./v2/deviceMessageV2Manager";
import { RTAVProtocolServiceV2 } from "./v2/rtavProtocolV2.service";
import { RtavSessionControllerV2 } from "./v2/rtavSessionV2Controller";
import { RtavSettingManagerV2 } from "./rtavSettingManagerV2";
import { ResourceManagerV2 } from "./v2/resourceManagerV2";
import { settings } from "./rtav.settings";
import { VideoDeviceResolution } from "./v2/device-manager.model";
import { DeviceManagerService } from "./v2/device-manager.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { StringUtils, Logger, clientUtil } from "@html-core";
import { DevicePermissionService } from "./v2/device-permission.service";
import { DeviceEnumeratorService } from "./v2/device-enumerator.service";

const RTAV_AUDIO_VIDEO_DISABLED = RTAV_AUDIO_DISABLED | RTAV_VIDEO_DISABLED;

@Injectable()
export class RTAVSession {
   private sessionId;
   private vvcSession;
   private settingManager;
   private channel;
   private status;
   private controller;
   private controllerV2;
   private deviceMessageManager;
   private deviceMessageManagerV2;
   private v2mode;

   private audioDeviceConfigCallback = (devices) => {
      const binaryData = this.protocolV2.getSendingBinary("audioDeviceList", 0, devices);
      this.channel.send(binaryData);
   };

   private audioHeaderCallback = (headData) => {
      Logger.debug(
         "audio header callback, index is: " + headData.deviceIndex + ", header is: " + headData.header,
         Logger.RTAV
      );
   };
   private audioDataCallback = (data) => {
      // TODO don't use getSendingBinary
      const binaryData = this.protocolV2.getSendingBinary("sendStreamAudio", data.deviceIndex, data);
      this.channel.send(binaryData);
   };
   // TODO
   private videoDeviceConfigCallback = (devices) => {
      const binaryData = this.protocolV2.getSendingBinary("videoDeviceList", 0, devices);
      this.channel.send(binaryData);
   };
   private videoHeaderCallback = (headData) => {
      Logger.debug(
         "video header callback, index is " + headData.deviceIndex + ", header is: " + headData.header,
         Logger.RTAV
      );
   };
   private videoDataCallback = (data) => {
      if (data.data !== null && data.data.byteLength !== 0) {
         const binaryData = this.protocolV2.getSendingBinary("sendStreamVideo", data.deviceIndex, data);
         this.channel.send(binaryData);
      }
      data.others.getNextFrame(data.others.sessionId);
   };

   constructor(
      private resourceManager: ResourceManager,
      private resourceManagerV2: ResourceManagerV2,
      private protocol: RTAVProtocolService,
      private protocolV2: RTAVProtocolServiceV2,
      private dialogService: RtavDialogService,
      private eventBusService: EventBusService,
      private messageWaitManager: MessageWaitManager,
      private modalDialogService: ModalDialogService,
      private settingManagerV2: RtavSettingManagerV2,
      private deviceManagerService: DeviceManagerService,
      private deviceEnumeratorService: DeviceEnumeratorService,
      private devicePermissionService: DevicePermissionService
   ) {
      this.v2mode = false;
   }

   public init = (sessionId, vvcSession) => {
      this.sessionId = sessionId;
      this.vvcSession = vvcSession;
      this.settingManager = new RtavSettingManager();
      this.channel = new RtavChannel();
      this.channel.init(vvcSession);
      this.status = new RtavStatus();
      this.controller = new RtavSessionController(
         this.resourceManager,
         this.dialogService,
         this.modalDialogService,
         this.eventBusService
      );
      this.controller.init(sessionId, vvcSession.hardwareAccelerationOption);
      this.deviceMessageManager = new DeviceMessageManager(this.resourceManager, this.messageWaitManager);
      this.controller.setDeviceManager(this.deviceMessageManager);
      this.controllerV2 = new RtavSessionControllerV2(
         this.resourceManagerV2,
         this.dialogService,
         this.modalDialogService,
         this.eventBusService
      );
      this.controllerV2.init(sessionId, vvcSession.hardwareAccelerationOption);
      this.deviceMessageManager.init(this.controller);
      this.deviceMessageManagerV2 = new DeviceMessageManagerV2(this.messageWaitManager);
      this.deviceMessageManagerV2.init(this.controllerV2);
   };

   private getRTAVCodec = (parsedMessage) => {
      let localConfig;
      let enableRTAVH264CodecKillSwitch = true; // default video codec is H.264
      let enableRTAVOpusCodecKillSwitch = true; // default audio codec is Opus
      if (this.vvcSession.enableRTAVH264Codec === true || this.vvcSession.enableRTAVH264Codec === false) {
         enableRTAVH264CodecKillSwitch = this.vvcSession.enableRTAVH264Codec;
      }
      if (this.vvcSession.enableRTAVOpusCodec === true || this.vvcSession.enableRTAVOpusCodec === false) {
         enableRTAVOpusCodecKillSwitch = this.vvcSession.enableRTAVOpusCodec;
      }
      Logger.debug("h264 encode kill switch value is " + enableRTAVH264CodecKillSwitch, Logger.RTAV);
      Logger.debug("opus encode kill switch value is " + enableRTAVOpusCodecKillSwitch, Logger.RTAV);
      localConfig = this.settingManager.getLocalConfig();
      if (
         enableRTAVH264CodecKillSwitch === true &&
         enableRTAVOpusCodecKillSwitch === true &&
         parsedMessage.data.codecPrefs === CodecTypes["CodecVmwH264Opus"]
      ) {
         /**
          * In this situation, if it's web client the browser is chrome/Edge or it's Chrome Client,
          * both clients can support WebCodecs API and Opus, which means client codecs preferences
          * is H264Opus and client received agent side support codec
          * H264Opus, then after negotiation, codec preference should be
          * H264Opus.
          */
         localConfig.codecPref = CodecTypes["CodecVmwH264Opus"];
         Logger.debug("after negotiation, final codec type is CodecVmwH264Opus", Logger.RTAV);
      } else if (
         enableRTAVH264CodecKillSwitch === true &&
         enableRTAVOpusCodecKillSwitch === false &&
         parsedMessage.data.codecPrefs === CodecTypes["CodecVmwH264Opus"]
      ) {
         /**
          * In this situation, if it's web client, the browser is chrome/Edge or it's Chrome Client,
          * both client can support WebCodecs API but not Opus, which means client codecs preferences
          * is H264Speex and client received agent side support codec H264Opus, then after negotiation,
          * codec preference should be H264Speex.
          */
         localConfig.codecPref = CodecTypes["CodecVmwH264Speex"];
         Logger.debug("after negotiation, final codec type is CodecVmwH264Speex", Logger.RTAV);
      } else if (
         enableRTAVH264CodecKillSwitch === true &&
         parsedMessage.data.codecPrefs === CodecTypes["CodecVmwH264Speex"]
      ) {
         /**
          * In this situation, if it's web client, the browser is chrome/Edge or it's Chrome Client,
          * both client can support WebCodecs API, which means client codecs preferences
          * is H264 but not sure whether support Opus and client received agent side support codec
          * H264Speex, then after negotiation, codec preference should be
          * H264Speex.
          */
         localConfig.codecPref = CodecTypes["CodecVmwH264Speex"];
         Logger.debug("after negotiation, final codec type is CodecVmwH264Speex", Logger.RTAV);
      } else {
         localConfig.codecPref = CodecTypes["CodecVmwTheoraSpeex"];
         Logger.debug("after negotiation, final codec type is CodecVmwTheoraSpeex", Logger.RTAV);
         //@ts-ignore
         if (!window.VideoEncoder || !window.AudioEncoder) {
            Logger.debug("this browser do not support API webCodecs", Logger.RTAV);
         }
      }
      return localConfig.codecPref;
   };

   private isAgentSupportAudioDTX = () => {
      let localConfig;
      localConfig = this.settingManager.getLocalConfig();
      if (
         (localConfig.versionNum > 0x00000001 && localConfig.versionNum < 0x02000000) ||
         localConfig.versionNum > 0x02000000
      ) {
         // If agent (version > 0.0.1 && version < 2.0.0) or version > 2.0.0
         return true;
      } else {
         return false;
      }
   };

   private isAgentSupportAudioDTXV2 = () => {
      let localConfig;
      localConfig = this.settingManagerV2.getLocalConfig();
      if (
         (localConfig.versionNum > 0x00000001 && localConfig.versionNum < 0x02000000) ||
         localConfig.versionNum > 0x02000000
      ) {
         // If agent (version > 0.0.1 && version < 2.0.0) or version > 2.0.0
         return true;
      } else {
         return false;
      }
   };

   public onMessage = (message) => {
      let parsedMessage = this.protocol.parseRecievedMessage(message),
         adminPolicy,
         localConfig,
         deviceConfig,
         rtavMessageTypes = RtavTypes,
         binaryData,
         finalCodecPref;

      if (!parsedMessage) {
         Logger.warning("skip processing for unknown message", Logger.RTAV);
         return;
      }
      switch (parsedMessage.type) {
         case rtavMessageTypes["PMsgGetConfig"]:
            Logger.debug("on receive PMsgGetConfig", Logger.RTAV);
            // set admin policy, and set user config back to the Agent
            adminPolicy = parsedMessage.data;
            this.settingManager.setAdminPolicy(adminPolicy);
            localConfig = this.settingManager.getLocalConfig();
            binaryData = this.protocol.getSendingBinary("sendConfig", localConfig);
            this.controller.setAudioDTXMode(this.vvcSession.enableRTAVDTX ? this.isAgentSupportAudioDTX() : false);
            this.channel.send(binaryData);
            break;
         case rtavMessageTypes["PMsgGetCliSettings"]:
            Logger.debug("on receive PMsgGetCliSettings", Logger.RTAV);
            finalCodecPref = this.getRTAVCodec(parsedMessage);
            deviceConfig = {
               audio: this.settingManager.getAudioDeviceSetting(),
               video: this.settingManager.getVideoDeviceSetting(),
               codecPref: finalCodecPref
            };
            binaryData = this.protocol.getSendingBinary("sendClientSettings", deviceConfig);
            this.channel.send(binaryData);
            break;
         case rtavMessageTypes["PMsgStart_A"]:
            Logger.debug("on receive PMsgStart_A", Logger.RTAV);
            this.deviceMessageManager.onMessage("PMsgStart_A", (success) => {
               if (success) {
                  binaryData = this.protocol.getSendingBinary("sendStartAudioAck");
               } else {
                  binaryData = this.protocol.getSendingBinary("sendStartAudioNak");
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStop_A"]:
            Logger.debug("on receive PMsgStop_A", Logger.RTAV);
            this.deviceMessageManager.onMessage("PMsgStop_A", (success) => {
               if (success) {
                  binaryData = this.protocol.getSendingBinary("sendStopAudioAck");
               } else {
                  binaryData = this.protocol.getSendingBinary("sendStopAudioNak");
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStart_V"]:
            Logger.debug("on receive PMsgStart_V", Logger.RTAV);
            finalCodecPref = this.getRTAVCodec(parsedMessage);
            this.deviceMessageManager.onMessage("PMsgStart_V", (success) => {
               if (success) {
                  deviceConfig = {
                     audio: this.settingManager.getAudioDeviceSetting(),
                     video: this.settingManager.getVideoDeviceSetting(),
                     codecPref: finalCodecPref
                  };
                  binaryData = this.protocol.getSendingBinary("sendStartVideoAck", deviceConfig);
               } else {
                  binaryData = this.protocol.getSendingBinary("sendStartVideoNak");
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStop_V"]:
            Logger.debug("on receive PMsgStop_V", Logger.RTAV);
            this.deviceMessageManager.onMessage("PMsgStop_V", (success) => {
               if (success) {
                  binaryData = this.protocol.getSendingBinary("sendStopVideoAck");
               } else {
                  binaryData = this.protocol.getSendingBinary("sendStopVideoNak");
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStartStream"]:
            Logger.debug("on receive PMsgStartStream", Logger.RTAV);
            localConfig = this.settingManager.getLocalConfig();
            this.deviceMessageManager.onMessage(
               "PMsgStartStream",
               (data) => {
                  let binaryStream = this.protocol.getSendingBinary("sendStream", data);
                  this.channel.send(binaryStream);
               },
               {
                  audio: this.settingManager.getAudioDeviceSetting(),
                  video: this.settingManager.getVideoDeviceSetting(),
                  codecPref: localConfig.codecPref
               }
            );
            break;
         case rtavMessageTypes["PMsgStopStream"]:
            Logger.debug("on receive PMsgStopStream", Logger.RTAV);
            this.controller.stopMediaIn();
            break;
         default:
            Logger.error("unknown msg", Logger.RTAV);
            break;
      }
   };

   private setAudioDevicePrefs = (deviceId, audioDevicePrefs) => {
      let sampleRateAgent = audioDevicePrefs.agentSamplesRate;
      let sampleRateClient = sampleRateAgent;
      let testAudioContext = new window.AudioContext();
      if (testAudioContext.sampleRate < sampleRateAgent) {
         Logger.debug(
            "AudioContext preferred sample rate " +
               testAudioContext.sampleRate +
               " smaller than agent expected " +
               sampleRateAgent,
            Logger.RTAV
         );
         sampleRateClient = testAudioContext.sampleRate >= 16000 ? 16000 : 8000;
      }
      if (sampleRateClient === 48000 && audioDevicePrefs.codec === CodecTypes.CodecVmwSpeex) {
         sampleRateClient = 16000;
      }
      testAudioContext.close();
      let item = settings.audioV2.audioDevicesParam.find((item) => item.deviceId === deviceId);
      if (item !== undefined) {
         item.sampleRate = sampleRateClient;
         item.channels = audioDevicePrefs.channels;
         item.bitsPerSample = audioDevicePrefs.bitsPerSample;
         item.codec = audioDevicePrefs.codec;
      } else {
         let devicePrefs = {
            deviceId: null,
            sampleRate: null,
            channels: null,
            bitsPerSample: null,
            codec: null
         };
         devicePrefs.deviceId = deviceId;
         devicePrefs.sampleRate = sampleRateClient;
         devicePrefs.channels = audioDevicePrefs.channels;
         devicePrefs.bitsPerSample = audioDevicePrefs.bitsPerSample;
         devicePrefs.codec = audioDevicePrefs.codec;
         settings.audioV2.audioDevicesParam.push(devicePrefs);
      }
   };

   private setVideoDevicePrefs = (deviceId, videoDevicePrefs) => {
      let item = settings.videoV2.videoDevicesParam.find((item) => item.deviceId === deviceId);
      if (item !== undefined) {
         item.frameWidth = videoDevicePrefs.frameWidth;
         item.frameHeight = videoDevicePrefs.frameHeight;
         item.frameRate = videoDevicePrefs.frameRate;
         item.codec = videoDevicePrefs.codec;
      } else {
         let devicePrefs = {
            deviceId: null,
            frameWidth: null,
            frameHeight: null,
            frameRate: null,
            codec: null
         };
         devicePrefs.deviceId = deviceId;
         devicePrefs.frameWidth = videoDevicePrefs.frameWidth;
         devicePrefs.frameHeight = videoDevicePrefs.frameHeight;
         devicePrefs.frameRate = videoDevicePrefs.frameRate;
         devicePrefs.codec = videoDevicePrefs.codec;
         settings.videoV2.videoDevicesParam.push(devicePrefs);
      }
   };

   public onMessagev2 = async (message) => {
      let parsedMessage = this.protocolV2.parseReceivedMessageV2(message),
         adminPolicy,
         localConfig,
         rtavMessageTypes = RtavTypes,
         binaryData,
         deviceIndex;

      if (!parsedMessage) {
         Logger.warning("skip processing for unknown message", Logger.RTAV);
         return;
      }
      deviceIndex = parsedMessage.index;
      let audioDeviceConfigCallbackPass = this.audioDeviceConfigCallback;
      let audioHeaderCallbackPass = this.audioHeaderCallback;
      let audioDataCallbackPass = this.audioDataCallback;
      let videoDeviceConfigCallbackPass = this.videoDeviceConfigCallback;
      let videoHeaderCallbackPass = this.videoHeaderCallback;
      let videoDataCallbackPass = this.videoDataCallback;
      this.deviceManagerService.setCallbacks(
         { channel: this.channel, callback: audioDeviceConfigCallbackPass },
         audioHeaderCallbackPass,
         audioDataCallbackPass,
         { channel: this.channel, callback: videoDeviceConfigCallbackPass },
         videoHeaderCallbackPass,
         videoDataCallbackPass,
         this.resourceManagerV2.emitDeviceStatusChanged
      );
      switch (parsedMessage.type) {
         case rtavMessageTypes["PMsgGetConfig"]:
            Logger.debug("on receive PMsgGetConfig", Logger.RTAV);
            // set admin policy, and set user config back to the Agent
            adminPolicy = parsedMessage.data;
            Logger.debug("adminPolicy's disabledComponent is " + adminPolicy.disabledComponent, Logger.RTAV);
            await this.settingManagerV2.setAdminPolicy(adminPolicy);
            localConfig = this.settingManagerV2.getLocalConfig();
            Logger.debug("local config is " + JSON.stringify(localConfig), Logger.RTAV);
            binaryData = this.protocolV2.getSendingBinary("sendConfig", 0, localConfig);
            this.controllerV2.setAudioDTXMode(this.vvcSession.enableRTAVDTX ? this.isAgentSupportAudioDTXV2() : false);
            this.channel.send(binaryData);
            // ask for permission when the popup is confirmed
            if (localConfig.isEnabled === true) {
               audioDeviceConfigCallbackPass =
                  adminPolicy.disabledComponent === RTAV_AUDIO_DISABLED ? () => {} : this.audioDeviceConfigCallback;
               audioHeaderCallbackPass =
                  adminPolicy.disabledComponent === RTAV_AUDIO_DISABLED ? () => {} : this.audioHeaderCallback;
               audioDataCallbackPass =
                  adminPolicy.disabledComponent === RTAV_AUDIO_DISABLED ? () => {} : this.audioDataCallback;
               videoDeviceConfigCallbackPass =
                  adminPolicy.disabledComponent === RTAV_VIDEO_DISABLED ? () => {} : this.videoDeviceConfigCallback;
               videoHeaderCallbackPass =
                  adminPolicy.disabledComponent === RTAV_VIDEO_DISABLED ? () => {} : this.videoHeaderCallback;
               videoDataCallbackPass =
                  adminPolicy.disabledComponent === RTAV_VIDEO_DISABLED ? () => {} : this.videoDataCallback;
               this.deviceManagerService.setCallbacks(
                  { channel: this.channel, callback: audioDeviceConfigCallbackPass },
                  audioHeaderCallbackPass,
                  audioDataCallbackPass,
                  { channel: this.channel, callback: videoDeviceConfigCallbackPass },
                  videoHeaderCallbackPass,
                  videoDataCallbackPass,
                  this.resourceManagerV2.emitDeviceStatusChanged
               );
               let videoDeviceResolution: VideoDeviceResolution = {
                  width: localConfig.vdoResWidth,
                  height: localConfig.vdoResHeight
               };
               Logger.debug("videoDeviceResolution is " + JSON.stringify(videoDeviceResolution), Logger.RTAV);
               this.controllerV2.setDeviceManager(this.deviceManagerService);
               this.deviceManagerService.startListening();
            }
            break;
         case rtavMessageTypes["PMsgStart_A"]:
            Logger.debug("on receive PMsgStart_A", Logger.RTAV);
            let audioDevicePrefs = parsedMessage.data;
            this.setAudioDevicePrefs(deviceIndex, audioDevicePrefs);
            audioDevicePrefs.clientSamplesRate = settings.audioV2.audioDevicesParam.find(
               (item) => item.deviceId === deviceIndex
            ).sampleRate;
            this.deviceMessageManagerV2.onMessage("PMsgStart_A", deviceIndex, (success) => {
               if (success) {
                  binaryData = this.protocolV2.getSendingBinary("sendStartAudioAck", deviceIndex, audioDevicePrefs);
               } else {
                  binaryData = this.protocolV2.getSendingBinary("sendStartAudioNak", deviceIndex, audioDevicePrefs);
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStop_A"]:
            Logger.debug("on receive PMsgStop_A", Logger.RTAV);
            this.deviceMessageManagerV2.onMessage("PMsgStop_A", deviceIndex, (success) => {
               if (success) {
                  binaryData = this.protocolV2.getSendingBinary("sendStopAudioAck", deviceIndex);
               } else {
                  binaryData = this.protocolV2.getSendingBinary("sendStopAudioNak", deviceIndex);
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStart_V"]:
            Logger.debug("on receive PMsgStart_V", Logger.RTAV);
            let videoDevicePrefs = parsedMessage.data;
            Logger.debug("video device prefs", Logger.RTAV);
            Logger.debug(videoDevicePrefs, Logger.RTAV);
            this.setVideoDevicePrefs(deviceIndex, videoDevicePrefs);
            this.deviceMessageManagerV2.onMessage("PMsgStart_V", deviceIndex, (success) => {
               if (success) {
                  binaryData = this.protocolV2.getSendingBinary("sendStartVideoAck", deviceIndex, videoDevicePrefs);
               } else {
                  binaryData = this.protocolV2.getSendingBinary("sendStartVideoNak", deviceIndex);
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStop_V"]:
            Logger.debug("on receive PMsgStop_V", Logger.RTAV);
            this.deviceMessageManagerV2.onMessage("PMsgStop_V", deviceIndex, (success) => {
               if (success) {
                  binaryData = this.protocolV2.getSendingBinary("sendStopVideoAck", deviceIndex);
               } else {
                  binaryData = this.protocolV2.getSendingBinary("sendStopVideoNak", deviceIndex);
               }
               this.channel.send(binaryData);
            });
            break;
         case rtavMessageTypes["PMsgStartStream_A"]:
            Logger.debug("on receive PMsgStartStream_A", Logger.RTAV);
            this.deviceMessageManagerV2.onMessage("PMsgStartStream_A", deviceIndex, (success) => {});
            break;
         case rtavMessageTypes["PMsgStartStream_V"]:
            Logger.debug("on receive PMsgStartStream_V", Logger.RTAV);
            this.deviceMessageManagerV2.onMessage("PMsgStartStream_V", deviceIndex, (success) => {});
            break;
         default:
            Logger.error("unknown msg", Logger.RTAV);
            break;
      }
   };

   private sendStopStream = () => {
      let binaryData;
      binaryData = this.protocol.getSendingBinary("sendStopStream");
      this.channel.send(binaryData);
   };

   /**
    * Will always start the rtav session, but might fail to get device and thus session has no data to send.
    */
   public start = () => {
      Logger.debug("start RTAV", Logger.RTAV);
      this.channel.initialize();
      this.channel.setChannelOnOpenListener((data) => {
         Logger.debug("rtav channel is opened", Logger.RTAV);
         const capabilityAgent = StringUtils.uInt8ArrayToUInt32(data.data, false);
         Logger.debug(`agent rtav v2 supported: ${!!(capabilityAgent & 0x8000)}`, Logger.RTAV);
         if (
            (capabilityAgent & 0x8000) > 0 &&
            this.vvcSession.enableRTAVH264Codec === true &&
            this.vvcSession.enableRTAVOpusCodec === true
         ) {
            // work on v2
            Logger.debug(`client will work on RTAV v2 mode`, Logger.RTAV);
            this.v2mode = true;
            if (clientUtil.isChromium() || clientUtil.isFirefox()) {
               // don't ask for permission on Safari
               this.devicePermissionService.checkAndAskForPermission(true);
            }
         } else {
            // work on v0
            Logger.debug(`client will work on RTAV v0 mode`, Logger.RTAV);
            this.v2mode = false;
         }
         Logger.debug("v2mode is " + this.v2mode, Logger.RTAV);
         if (!!this.v2mode === true) {
            Logger.debug("is v2mode === true", Logger.RTAV);
            //this.resourceManager = resourceManagerV2;
            this.channel.setReceivedListener(this.onMessagev2);
            this.controllerV2.initialize();
         } else {
            Logger.debug("is v2mode === false", Logger.RTAV);
            this.channel.setReceivedListener(this.onMessage);
            this.controller.initialize();
         }
         this.status.set("STChannelOpen");
      });
   };

   /**
    * Stop the whole rtav, will clear all resources and disconnect from agent.
    */
   public stop = () => {
      Logger.debug("stop RTAV by force", Logger.RTAV);
      this.channel.stop();
      if (!!this.v2mode === false) {
         this.controller.stop();
      } else {
         this.controllerV2.stop();
      }
      this.status.set("STChannelClose");
   };

   /**
    * User want to stop the rtav from working for now, but several resources will be kept for later usage.
    */
   public userStop = () => {
      Logger.debug("stop RTAV by userAction", Logger.RTAV);
      if (!!this.v2mode === false) {
         this.controller.userStop();
         this.sendStopStream();
      } else {
         this.controllerV2.userStop();
      }
      this.status.set("STOff");
   };

   /**
    * [isUsingDevices description]
    * @param  {string}  type Optional Indicate whether asking for audio or video, when undefined, ask for either
    * @return {boolean} This returns whether the asked type is in usage
    */
   public isUsingDevices = (type) => {
      if (!!this.v2mode === false) {
         return this.controller.isSendingStream(type);
      } else {
         return this.controllerV2.isSendingStream(type);
      }
   };

   /**
    * [isUsingDevices description]
    * @param  {string}  type Optional Indicate whether asking for audio or video, when undefined, ask for either
    * @return {boolean} This returns whether the asked type is being asked for permission
    */
   public isAskingPermission = (type) => {
      return this.deviceMessageManager.isPermissionPending(type);
   };
}
