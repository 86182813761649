/**
 * ******************************************************
 * Copyright (C) 2019-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Used at the channel part mainly for settings of RX feature
 */

import { clientUtil } from "@html-core";
import { Injectable } from "@angular/core";
import {
   SessionMsg,
   RemoteSessionEventService
} from "../../../shared/common/remote-session/remote-session-event.service";
import { Port } from "../../base/model/rx-bridge-type";
import { FeatureLogger } from "../../../shared/desktop/common/feature-logger.service";
import { ConnectedMessageService } from "../../base/service/connected-message.service";
import { FeatureConfigs } from "../../../shared/common/model/feature-configs";
import { ClientSettingModel, SettingItem } from "../../../shared/common/model/client-setting-model";
import { CoordinateConverter } from "../../../shared/utils/coordinate-converter";
import Logger from "../../../core/libs/logger";
import { ViewClientModel } from "../../../shared/common/model/viewclient-model";
import { EventBusService } from "../../../core/services/event";
import { MultimonService } from "../../../shared/desktop/multimon/main-page/multimon.service";
import { MonitorManageService } from "../../../shared/desktop/multimon/main-page/monitor-manage.service";
import { MultiMonitorChromeService } from "../../../shared/desktop/common/multi-monitor-chrome.service";
import { UrlParameterService } from "./url-parameter.service";
import { ChromeShadowGlobalPref } from "../../common/service/chrome-shadow-global-pref.service";

@Injectable()
export class SessionSettingService {
   private mLog: any = FeatureLogger("SessionSettingService")(Logger);
   private pendingSetting: any = {};
   public clientStatus: any = {};
   private wmksSession = null;
   private sessionKey = null;
   constructor(
      private connectedMessageService: ConnectedMessageService,
      private remoteSessionEventService: RemoteSessionEventService,
      private featureConfigs: FeatureConfigs,
      private clientSettingModel: ClientSettingModel,
      private coordinateConverter: CoordinateConverter,
      private viewClientModel: ViewClientModel,
      private eventBusService: EventBusService,
      private multimonService: MultimonService,
      private monitorManageService: MonitorManageService,
      private multiMonitorChromeService: MultiMonitorChromeService,
      private urlParameterService: UrlParameterService,
      private chromeShadowGlobalPref: ChromeShadowGlobalPref
   ) {
      this.chromeShadowGlobalPref.setSyncBackFunction(this.syncBackSettings);
   }

   private _setWMKSSession = (wmksSession) => {
      this.wmksSession = wmksSession;
      if (this.pendingSetting["Resolution"]) {
         this._setResolution(this.pendingSetting["Resolution"]);
         delete this.pendingSetting["Resolution"];
      }
      if (this.pendingSetting["enableHighResolution"]) {
         this._switchFeatureStatus("enableHighResolution", this.pendingSetting["enableHighResolution"]);
         delete this.pendingSetting["enableHighResolution"];
      }
      if (this.pendingSetting["enableWindowsKey"]) {
         this._switchFeatureStatus("enableWindowsKey", this.pendingSetting["enableWindowsKey"]);
         delete this.pendingSetting["enableWindowsKey"];
      }
      if (this.pendingSetting["enableWindowsDeleteKey"]) {
         this._switchFeatureStatus("enableWindowsDeleteKey", this.pendingSetting["enableWindowsDeleteKey"]);
         delete this.pendingSetting["enableWindowsDeleteKey"];
      }
   };

   private _unsetWMKSSession = () => {
      this.wmksSession = null;
   };

   private _setResolution = (resolution) => {
      const screenSize = clientUtil.getWindowResolution();
      this.mLog.trace(
         "target resolution " + JSON.stringify(resolution) + ", set resolution as " + JSON.stringify(screenSize)
      );
      if (!this.clientStatus.isInMultimon) {
         this.wmksSession.updateResolution(screenSize, true);
         if (this.wmksSession.isApplicationSession) {
            this.coordinateConverter.updateClientScreenInfo();
         }
      } else {
         this.mLog.debug("skip resize for session wmksKey: " + this.sessionKey);
      }
   };

   private _switchFeatureStatus = (key, value) => {
      if (typeof value === "boolean") {
         this.mLog.info("change " + key + " to " + value);
         switch (key) {
            case Port.SettingMsg.enableHighResolution:
               this.wmksSession.wmks("option", "useNativePixels", value);
               if (this.wmksSession.isApplicationSession) {
                  const enableDPISync = !value;
                  this.coordinateConverter.onDPISyncSettingChanged(enableDPISync);
               }
               break;
            case Port.SettingMsg.enableWindowsKey:
               this.wmksSession.enableWindowsKey(value);
               break;
            case Port.SettingMsg.enableWindowsDeleteKey:
               this.wmksSession.enableWindowsDeleteKey(value);
               break;
            case Port.SettingMsg.disableNetworkStateDisplay:
               this.wmksSession.option.disableNetworkStateDisplay = value;
               break;
         }
      } else {
         this.mLog.error("invalid parameters");
      }
   };

   // public
   public init = (sessionKey, initialSettings) => {
      this.clientStatus = {
         isInMultimon: false
      };
      this.sessionKey = sessionKey;
      this.chromeShadowGlobalPref.importSettings(initialSettings);
      this.connectedMessageService.initConnection(sessionKey, Port.ChannelName.Settings);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTING_MSG, this.onSessionConnecting);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_DISCONNECTED_MSG, this.onSessionDisconnected);

      // setting part
      this.connectedMessageService.onMessage(this.sessionKey, Port.ChannelName.Settings, (msg) => {
         this.mLog.trace("setting response get" + JSON.stringify(msg));
         switch (msg.type) {
            case Port.SettingMsg.Resolution:
               if (!msg.content) {
                  this.mLog.error("empty parameters for resolution setting");
                  return;
               }
               this.setResolution(msg.content);
               break;
            case Port.SettingMsg.enableHighResolution: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for high resolution setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for high resolution setting");
                  return;
               }
               this.setHighResolution(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.disableNetworkStateDisplay: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for disable network state display setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for disable network state display setting");
                  return;
               }
               this.setDisableNetworkStateDisplay(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.enableWindowsKey: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for windows key setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for windows key setting");
                  return;
               }
               this.setWindowsKey(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.enableWindowsDeleteKey: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for windows key setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for windows key setting");
                  return;
               }
               this.setWindowsDeleteKey(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.enableGeolocationSharing: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for enableGeolocationSharing key setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for enableGeolocationSharing key setting");
                  return;
               }
               this.setEnableGeolocationSharing(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.donotShowGeolocationDialog: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for donotShowGeolocationDialog key setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for donotShowGeolocationDialog key setting");
                  return;
               }
               this.setDonotShowGeolocationDialog(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.enableMediaStreamPermission: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for enableMediaStreamPermission key setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for enableMediaStreamPermission key setting");
                  return;
               }
               this.setEnableMediaStreamPermission(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.donotShowMediaStreamPermissionDialog: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for donotShowMediaStreamPermissionDialog key setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for donotShowMediaStreamPermissionDialog key setting");
                  return;
               }
               this.setDoNotShowMediaStreamPermissionDialog(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.enableScreenSharing: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for enable screen sharing setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for enable screen sharing setting");
                  return;
               }
               this.setEnableScreenSharing(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.enableWebRTCRedirection: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for enable WebRTC redirection setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for enable WebRTC redirection setting");
                  return;
               }
               this.setEnableWebRTCRedirection(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.enableBCR: {
               if (typeof msg.content !== "string") {
                  this.mLog.error("invalid parameters for enable browser redirection key setting");
                  return;
               }
               const enableStatus = JSON.parse(msg.content);
               if (typeof enableStatus !== "boolean") {
                  this.mLog.error("invalid parameters for enable browser redirection key setting");
                  return;
               }
               this.setEnableBCR(msg.type, enableStatus);
               break;
            }
            case Port.SettingMsg.checkAnyInMultimon: {
               this.eventBusService.dispatch({
                  type: "checkAnyInMultimon",
                  data: msg.data
               });
               break;
            }
            case Port.SettingMsg.exitMultimon: {
               this.eventBusService.dispatch({
                  type: "exitMultimon"
               });
               break;
            }
            case Port.SettingMsg.enterMultimon: {
               this.eventBusService.dispatch({
                  type: "enterMultimon"
               });
               break;
            }
            case Port.SettingMsg.selectedMonitors: {
               this.eventBusService.dispatch({
                  type: "selectedMonitors",
                  data: msg.data
               });
               break;
            }
         }
      });
      this.featureConfigs.setConfig("KillSwitch-CDR", true);
      this.featureConfigs.setConfig("KillSwitch-VscanKeyboard", true);
      this.featureConfigs.setConfig("KillSwitch-Touch", true);
   };

   public onSessionConnecting = (wmksSession) => {
      this._setWMKSSession(wmksSession);
   };

   public onSessionDisconnected = (session) => {
      this._unsetWMKSSession();
   };

   public setResolution = (resolution) => {
      if (!this.wmksSession) {
         this.pendingSetting["Resolution"] = resolution;
         return;
      }
      this._setResolution(resolution);
   };

   public setHighResolution = (key, value) => {
      if (this.multimonService.isInMultiMonitorStatus()) {
         this.multiMonitorChromeService.reOpenAndDoSth(() => {
            this._setHighResolution(key, value);
         });
      } else {
         this._setHighResolution(key, value);
         if (this.wmksSession.isApplicationSession && this.multiMonitorChromeService.canEnterMultimon()) {
            this.multiMonitorChromeService.isMultimonEnabled();
         }
      }
   };

   private _setHighResolution(key, value) {
      this.clientSettingModel.updateSetting("enableHighResolution", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableHighResolution"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   }

   public setDisableNetworkStateDisplay = (key, value) => {
      if (!this.wmksSession) {
         this.pendingSetting["disableNetworkStateDisplay"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setEnableScreenSharing = (key, value) => {
      this.clientSettingModel.updateSetting("enableScreenSharing", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableScreenSharing"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setEnableWebRTCRedirection = (key, value) => {
      this.clientSettingModel.updateSetting("enableWebRTCRedirection", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableWebRTCRedirection"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setWindowsKey = (key, value) => {
      this.clientSettingModel.updateSetting("enableWindowsKey", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableWindowsKey"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setWindowsDeleteKey = (key, value) => {
      this.clientSettingModel.updateSetting("enableWindowsDeleteKey", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableWindowsDeleteKey"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setEnableGeolocationSharing = (key, value) => {
      this.clientSettingModel.updateSetting("enableGeolocationSharing", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableGeolocationSharing"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setDonotShowGeolocationDialog = (key, value) => {
      this.clientSettingModel.updateSetting("donotShowGeolocationDialog", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["donotShowGeolocationDialog"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setEnableMediaStreamPermission = (key, value) => {
      this.clientSettingModel.updateSetting("enableMediaStreamPermission", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableMediaStreamPermission"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   public setDoNotShowMediaStreamPermissionDialog = (key, value) => {
      this.clientSettingModel.updateSetting("donotShowMediaStreamPermissionDialog", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["donotShowMediaStreamPermissionDialog"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };

   private syncBackSettings = (settings: Array<SettingItem>) => {
      const message = {
         type: Port.SettingMsg.syncBackSettings,
         content: settings
      };
      this.connectedMessageService.sendMessage(this.sessionKey, Port.ChannelName.Settings, message);
   };

   public setEnableBCR = (key, value) => {
      this.clientSettingModel.updateSetting("enableBCR", value.toString());
      if (!this.wmksSession) {
         this.pendingSetting["enableBCR"] = value;
         return;
      }
      this._switchFeatureStatus(key, value);
   };
}
