/**
 * ******************************************************
 * Copyright (C) 2016-2017, 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview videoCapture.ts -- VideoCapture
 * Class to handle video capturing
 */

import { Injectable } from "@angular/core";
import { FrameRateController } from "./frameRateController";
import { EventBusService } from "@html-core";
import { VideoParams } from "./v2/video-capture.model";
import { Logger } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class VideoCapture {
   private inited;
   private started = false;
   private sessionID: number;
   private sessionCount: number; // total amount of created sessions
   private ctx: CanvasRenderingContext2D;
   private video: HTMLVideoElement;
   private canvas: HTMLCanvasElement;
   private videoParam: VideoParams;
   private localStream: MediaStream;
   private dataCallback = null;

   private lastValidTime: number;
   private freezeCount: number;
   private losingDevice: boolean;
   private deviceUnplugged: boolean;
   private syncTimer;

   public statusEnum;

   constructor(
      private frameRateController: FrameRateController,
      private eventBusService: EventBusService
   ) {
      this.inited = false;
      this.statusEnum = {
         Uninited: "Uninited",
         Inited: "Inited",
         Working: "Working"
      };
      this.videoParam = {
         width: null,
         height: null,
         fps: null,
         logLevel: null,
         codecPref: null,
         hardwareAccelerationOption: null
      };
   }

   /**
    * Init capturing video by binding events to the passed in stream, and later for each frame, call
    * the callback with it.
    * Similar to mVdoInput.Open for native clients, use prefs and timer, but here also pass in stream
    * and callback which is done differently for native clients.
    * and the caller should be similar to VCamServer::InitVideoSrcDev, to reset sync timer if needed.
    * it looks like "open" but the real open didn't happen here, it's acctually init the opened source,
    * we do not open it here to avoid anoying hint diaglog which is forced by some browsers like firefox.
    *
    * @param  {object} videoParam The parameter object that contains width, height and fps
    * @param  {object} syncTimer The timer object user to sync the audio and video
    * @param  {function} callback The frame dealing function for each frame
    */
   public init = (videoParam, syncTimer, callback) => {
      if (this.inited) {
         Logger.error("this video capture has already been inited, init fail", Logger.RTAV);
         return;
      }
      if (typeof callback !== "function") {
         Logger.error("this video capture callback is not a function, init fail", Logger.RTAV);
         return;
      }

      this.videoParam.width = videoParam.width;
      this.videoParam.height = videoParam.height;
      this.videoParam.fps = videoParam.fps;
      this.frameRateController.setFPS(videoParam.fps);

      this.syncTimer = syncTimer;
      this.dataCallback = callback;
      // Adjust size
      this.video = document.getElementById("video") as HTMLVideoElement;
      if (!this.video) {
         throw new Error("VideoCapture can't find the video element");
      }
      this.video.setAttribute("width", videoParam.width.toString());
      this.video.setAttribute("height", videoParam.height.toString());
      // mute the video to fix bug 1587389
      this.video.volume = 0;
      // Adjust size
      this.canvas = document.getElementById("canvas") as HTMLCanvasElement;
      if (!this.canvas) {
         Logger.error("can't find the canvas element", Logger.RTAV);
         return;
      }
      this.canvas.setAttribute("width", videoParam.width.toString());
      this.canvas.setAttribute("height", videoParam.height.toString());
      this.ctx = this.canvas.getContext("2d");
      if (
         !this.video ||
         !this.canvas ||
         !this.ctx ||
         videoParam.width !== this.canvas.width ||
         videoParam.height !== this.canvas.height
      ) {
         return;
      }
      /**
       * use sessionID without sessionCount can also controll the workflow, but one can
       * not detect the error for calling start or stop with undesired timing, so do not
       * pick that design.
       */
      this.sessionID = -1; //current working sesssion ID
      this.sessionCount = 0; //total working session number ever created

      this.inited = true;
   };

   /**
    * Start capturing video, and if there is another working session, do nothing.
    * @param  {object} stream The stream object obtained by the getUserMedia
    */
   public start = (stream) => {
      if (!stream) {
         Logger.error("the video stream is not valid, start fail", Logger.RTAV);
         return;
      }
      if (!this.inited) {
         Logger.error("the video capture is not being inited, start fail", Logger.RTAV);
         return;
      }
      if (this.sessionID >= 0) {
         Logger.error("find existing video capturing session, start fail", Logger.RTAV);
         return;
      }

      /**
       * Swtich to new API of srcObject, if createObjectURL don't take stream anymore.
       * See detail in the bug 2218657
       */
      try {
         this.video.src = window.URL.createObjectURL(stream) as string;
      } catch (e) {
         this.video.srcObject = stream;
      }
      this.localStream = stream;

      this.sessionID = this.sessionCount;
      this.sessionCount++;
      this.lastValidTime = -1;
      this.freezeCount = 0;
      this.losingDevice = false;
      this.deviceUnplugged = false;
      this.getNextFrame(this.sessionID);
   };

   /**
    * Will fetch a new frame if this.sessionID is the same of this.sessionID to handle
    * the async workflow of stop_V and start_V
    * maxFPS not supported yet, which it's a trival feature(would do later).
    *
    * @private
    * @param {number} sessionID The number use to mark the video srcource session,
    *     which is identical from a start_V to a stop_V to avoid parallel capturing.
    */
   private getNextFrame = (sessionID) => {
      if (this.video.paused) {
         this.video.play();
      }
      setTimeout(() => {
         if (!this.inited) {
            return;
         }
         if (this.sessionID < 0 && this.sessionID !== sessionID) {
            return;
         }
         let imgData,
            timestamp = this.syncTimer.getTime();

         this.updateActiveStatus();
         this.ctx?.drawImage(this.video, 0, 0, this.videoParam.width, this.videoParam.height);
         imgData = this.ctx?.getImageData(0, 0, this.canvas.width, this.canvas.height);
         this.frameRateController.onFrameCaptured();
         this.dataCallback({
            data: imgData.data.buffer,
            timestamp: timestamp,
            others: {
               callbackParam: sessionID,
               callback: this.getNextFrame
            }
         });
      }, this.frameRateController.getWaitTime());
   };

   /**
    * Update the device active status
    */
   public updateActiveStatus = () => {
      let freezeTolerance = this.videoParam.fps / 2 + 2;

      if (this.video.currentTime) {
         if (this.video.currentTime === this.lastValidTime) {
            this.freezeCount = this.freezeCount + 1;
            if (!this.losingDevice && this.freezeCount > freezeTolerance) {
               this.losingDevice = true;
               Logger.debug("negative: seems the video device becomes invalid, please check", Logger.RTAV);
               this.eventBusService.dispatch({ type: "rtavDeviceStatusChanged" });
            }
         } else {
            this.freezeCount = 0;
            if (this.video.currentTime > 0) {
               this.lastValidTime = this.video.currentTime;
            }
            if (this.losingDevice) {
               this.losingDevice = false;
               Logger.debug(
                  "negative: seems the video device becomes valid again, which means it might unstable",
                  Logger.RTAV
               );
               this.eventBusService.dispatch({ type: "rtavDeviceStatusChanged" });
            }
         }
      }

      if (!!this.localStream && typeof this.localStream.active === "boolean") {
         if (!this.localStream.active && !this.deviceUnplugged) {
            this.deviceUnplugged = true;
            Logger.debug("negative: seems the audio device is unpluged", Logger.RTAV);
            this.eventBusService.dispatch({ type: "rtavDeviceStatusChanged" });
         }
      }
   };

   /**
    * Get what status this video src is in, cuurently only Uinited is detected, but keep the API as it
    * for future extension and debugging
    * @return {string} This returns one of the VideoCapture.statusEnum of 'Uninited', 'Inited', 'Working'
    */
   public getStatus = () => {
      let status;
      if (!this.inited) {
         status = this.statusEnum["Uninited"];
      } else {
         if (this.sessionID < 0) {
            status = this.statusEnum["Inited"];
         } else {
            status = this.statusEnum["Working"];
         }
      }
      return status;
   };

   /**
    * De-construct function, should be called when want to change the param, nor
    * we want a brand now capturing session.
    */
   public clear = () => {
      if (!this.inited) {
         Logger.error("this video capture is not inited, clear fail", Logger.RTAV);
         return;
      }
      if (this.sessionID >= 0) {
         Logger.debug("negative: the video capture is not stopped yet, stop it before clear it.", Logger.RTAV);
         this.stop();
      }
      this.video = null;
      this.canvas = null;
      this.ctx = null;
      this.inited = false;
      this.syncTimer = null;
      this.dataCallback = null;
      this.losingDevice = false;
      this.deviceUnplugged = false;
      const tracks = this.localStream?.getTracks();
      tracks?.forEach((track) => {
         track.stop();
      });
      this.localStream = null;
   };

   /**
    * Stop capturing data, and enter waiting status.
    * The coming getNextFrame with old sessionID will not be processed after this call.
    */
   public stop = () => {
      if (!this.inited) {
         Logger.error("the video capture is not being inited, stop fail", Logger.RTAV);
         return;
      }
      if (this.sessionID < 0) {
         Logger.debug("find no video capturing session, skip stop", Logger.RTAV);
         return;
      }
      this.losingDevice = false;
      this.deviceUnplugged = false;
      this.sessionID = -1;
   };

   /**
    * This returns whether the device can provide valid data
    */
   public isActive = () => {
      return !this.losingDevice && !this.deviceUnplugged;
   };
}
