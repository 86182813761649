/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 *
 *    session-monitor.service.ts
 *
 * This Service will handle all the session launch related task in
 * desktop page.
 *
 */

import * as CST from "@html-core";
import Logger from "../../../core/libs/logger";
import { Injector } from "@angular/core";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { TransitionDataModel } from "../../common/model/transition-data-model";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { ReconnectService } from "./reconnect.service";
import { MessageHandlerService } from "./message-handler.service";
import { AudioService } from "./audio.service";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { SessionDataService } from "../../common/service/session-data.service";
import { SessionUtil } from "../../common/service/session-util";
import { WmksService } from "./wmks.service";
import { BusEvent, EventBusService, clientUtil, TitanMsg } from "@html-core";
import { XmlApiService } from "../sidebar/xml-api.service";
import { Ws1Service } from "../../common/service/ws1.service";
import { XMLPreference } from "../../common/service/prefdata";

export class SessionMonitorService {
   public static readonly RELOAD_DURATION = 5000;
   private isRefreshPage: boolean = false;
   private _allAppsReconnected: boolean = false;
   /**
    * Remove workaround for bug 2099815 in first "reloadingDuration" ms after
    * page reloading for bypassing bug 2160420, since 2099815 couldn't happen
    * during this time.
    */
   private _reloadingDurationTimer = null;

   private viewClientModel: ViewClientModel;
   private transitionDataModel: TransitionDataModel;
   private localStorageService: LocalStorageService;
   private reconnectService: ReconnectService;
   private messageHandlerService: MessageHandlerService;
   private xmlApiService: XmlApiService;
   private audioService: AudioService;
   private wmksService: WmksService;
   private remoteSessionEventService: RemoteSessionEventService;
   private htmlRemoteSessionManager: HtmlRemoteSessionManager;
   private sessionDataService: SessionDataService;
   private sessionUtil: SessionUtil;
   private eventBusService: EventBusService;
   private ws1Service: Ws1Service;

   constructor(injector: Injector, webmksService: WmksService) {
      this.wmksService = webmksService;
      this.viewClientModel = injector.get(ViewClientModel);
      this.transitionDataModel = injector.get(TransitionDataModel);
      this.localStorageService = injector.get(LocalStorageService);
      this.reconnectService = injector.get(ReconnectService);
      this.messageHandlerService = injector.get(MessageHandlerService);
      this.xmlApiService = injector.get(XmlApiService);
      this.audioService = injector.get(AudioService);
      this.remoteSessionEventService = injector.get(RemoteSessionEventService);
      this.htmlRemoteSessionManager = injector.get(HtmlRemoteSessionManager);
      this.sessionDataService = injector.get(SessionDataService);
      this.sessionUtil = injector.get(SessionUtil);
      this.eventBusService = injector.get(EventBusService);
      this.ws1Service = injector.get(Ws1Service);
      this.isRefreshPage =
         this.transitionDataModel.isApplicationSession === null || this.transitionDataModel.blastURL === null;
   }
   public init = () => {
      this.viewClientModel.clientInfoSubject.subscribe(() => {
         this.createOrResumeBlastSession();
      });
      this.eventBusService
         .listen(BusEvent.ReconnectAllSessionsMsg.MSG_TYPE)
         .subscribe((msg: BusEvent.ReconnectAllSessionsMsg) => {
            this.triggerAppReconnect(msg.isMultiSession, msg.skipped);
         });

      this.eventBusService
         .listen(TitanMsg.TitanAppSessionReplyMsg.MSG_TYPE)
         .subscribe((msg: TitanMsg.TitanAppSessionReplyMsg) => {
            this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
            if (msg.status === TitanMsg.APP_SESSION_STATE.OK) {
               this.handleTitanAppResumption(msg);
            }
         });
   };

   private handleTitanAppResumption = (msg: TitanMsg.TitanAppSessionReplyMsg) => {
      Logger.info("sessionMonitor - resumption titan application session" + msg.sessionId);
      this.connectToApplication(msg.opt);
   };

   private createOrResumeBlastSession = () => {
      // Init the messageHandlerService with current user info, currently,
      // only use the horizonId
      const horizonId = this.localStorageService.get(CST.COOKIE.HORIZON_ID) || "";
      const userName = this.localStorageService.get(CST.COOKIE.USER_NAME) || "";
      const domainName = this.localStorageService.get(CST.COOKIE.DOMAIN_NAME) || "";
      this.messageHandlerService.init({
         horizonId: horizonId,
         userName: userName,
         domainName: domainName,
         brokerAddress: location.host
      });
      this.reconnectService.init({
         horizonId: horizonId,
         reconnectDesktopSessions: (sessions): Promise<void> => {
            return new Promise((resolve, reject) => {
               if (sessions && sessions.length > 0) {
                  Logger.info("reconnecting desktop sessions", Logger.WMKS);
                  this._executeRunningSession(sessions, true).then(resolve).catch(reject);
               } else {
                  resolve();
               }
            });
         },
         reconnectAppSessions: (): Promise<void> => {
            return new Promise((resolve, reject) => {
               if (!this._allAppsReconnected) {
                  this._allAppsReconnected = true;
                  this._connectToAllApplications(null, resolve, null);
               } else {
                  resolve();
               }
            });
         }
      });

      this.xmlApiService.init().then(() => {
         this.isRefreshPage =
            this.transitionDataModel.isApplicationSession === null || this.transitionDataModel.blastURL === null;
         if (this.isRefreshPage) {
            this.resumeBlastSession();
         } else {
            this.createBlastSession();
         }
      });
   };

   private createBlastSession = () => {
      // We launch an item from portal page.
      let focusedSession: any;
      let excludedSessionId = null;
      let needReconnectApps = false;
      if (this.transitionDataModel.isApplicationSession) {
         if (!this.transitionDataModel.originApp) {
            Logger.error("There is no application id information during bootstrap process.", Logger.WMKS);
            return;
         }

         focusedSession = {
            appId: this.transitionDataModel.originApp.appId,
            originId: this.transitionDataModel.originApp.originId,
            sessionId: this.transitionDataModel.sessionId,
            url: this.transitionDataModel.blastURL,
            isActive: true,
            isApplicationSession: true,
            enableUsb: this.transitionDataModel.enableUsb
         };
      } else {
         if (!this.transitionDataModel.originDesktop) {
            Logger.error("There is no desktop information during bootstrap process.", Logger.WMKS);
            return;
         }

         focusedSession = {
            key: this.transitionDataModel.originDesktop.id,
            name: this.transitionDataModel.originDesktop.name,
            url: this.transitionDataModel.blastURL,
            isActive: true,
            isApplicationSession: false,
            isShadow: this.transitionDataModel.isShadow,
            enableUsb: this.transitionDataModel.enableUsb,
            sessionId: this.transitionDataModel.sessionId
         };
         excludedSessionId = focusedSession.key;
         needReconnectApps = true;
      }

      this._executeRunningSession(focusedSession, false).then(() => {
         this.reconnectService
            .reconnectForHWS(excludedSessionId, needReconnectApps)
            .then(() => {
               Logger.info("WS1 desktop sessions reconnected for horizon session", Logger.WMKS);
               setTimeout(() => {
                  this.htmlRemoteSessionManager.activeSession(focusedSession.key, false);
               });
            })
            .catch((e) => {
               Logger.info(e, Logger.WMKS);
            });
      });
   };

   private resumeBlastSession = () => {
      // let handleAutoConnection = (item) => {
      //    if (item.isApplication) {
      //       Logger.info(
      //          "reconnect Application " + item.name + ".",
      //          Logger.WMKS
      //       );
      //       this.connectToApplication(item.id, item["origin-id"]);
      //    } else {
      //       Logger.info("reconnect Desktop " + item.name + ".", Logger.WMKS);
      //       this.connectToDesktop(item.id, item.name);
      //    }
      // };
      this.audioService.onPageReloaded();
      this.eventBusService.dispatch({
         type: "reFreshStatus",
         data: true
      });
      const runningSessions = this.sessionDataService.getRunningSessions();
      if (runningSessions === null || runningSessions.length === 0) {
         Logger.warning("Cannot read any running session" + " from session storage!", Logger.WMKS);

         // //auto reconnect for self-signed cert on IE, when no URL invoked.
         // this.jumpCacheService
         //    .readCachedDataFor("certAcceptReturnAddress")
         //    .then(handleAutoConnection)
         //    .catch(() => {});

         this.xmlApiService.getUserGlobalPref();

         return;
      }

      for (let i = 0; i < runningSessions.length; i++) {
         if (runningSessions[i].isApplicationSession) {
            this._allAppsReconnected = true;
            break;
         }
      }
      this.remoteSessionEventService.emit("updateReloadingStatus", true);
      if (this._reloadingDurationTimer) {
         clearTimeout(this._reloadingDurationTimer);
      }
      this._reloadingDurationTimer = setTimeout(() => {
         this.remoteSessionEventService.emit("updateReloadingStatus", false);
         this._reloadingDurationTimer = null;
      }, SessionMonitorService.RELOAD_DURATION);
      this._executeRunningSession(runningSessions, true).then(() => {
         //auto reconnect for self-signed cert on IE, when no URL invoked.
         // this.jumpCacheService
         //    .readCachedDataFor("certAcceptReturnAddress")
         //    .then(handleAutoConnection)
         //    .catch(() => {});
         /** check and update again each 1000ms, in 5000ms after reloaded to bypass race condition for bug 2584191
          *  extra logic needed for other legacy issues when multi-tabs need to be officially supported.
          */
         const maxConflictWaitTime = 5000;
         const handleConflict = () => {
            if (this.htmlRemoteSessionManager.hasConflictWithStorage()) {
               this.htmlRemoteSessionManager.updateRunningSessions();
               Logger.info("reconnect list has been overwritten after detecting conflict", Logger.WMKS);
            }
         };
         const conflictIntervalTimer = setInterval(handleConflict, 1000);
         setTimeout(() => {
            clearInterval(conflictIntervalTimer);
         }, maxConflictWaitTime);
      });
   };

   private _executeRunningSession = async (runningSessions: any, sort?: boolean): Promise<void> => {
      let currentSession = null;
      let runningSession: any[];
      if (runningSessions instanceof Array) {
         runningSession = runningSessions;
      } else {
         runningSession = [runningSessions];
      }

      if (sort) {
         this._sortRunningSession(runningSession);
      }

      // If there is a session left in the array try to resume it
      if (runningSession.length > 0) {
         currentSession = runningSession.shift();

         Logger.debug(
            "Starting to load" + (currentSession.isActive ? " active" : " inactive") + " key " + currentSession.key ||
               currentSession.appId +
                  (!currentSession.isShadow ? " primary" : " shadow") +
                  (currentSession.isApplicationSession ? " application" : " desktop") +
                  " session with url " +
                  currentSession.url,
            Logger.WMKS
         );

         // Try to resume the current session. If this is the last session
         // in the array then schedule resolve as the callback for when
         // that session resumes (or fails to resume). If there are more
         // than one remaining sessions then schedule the handling of
         // these sessions as a callback. This is necessary because
         // connectToApplication/connectToADesktop make an async call so
         // the callback is needed to force the calls to follow a sequence.
         const opt = {} as BlastConnectInfo;
         if (currentSession.isWindows365) {
            Logger.warning(`skip resume blast session for windows 365 entitlement - ${currentSession.name}`);
         } else if (currentSession.isApplicationSession) {
            let originId, sessionId, isMultiSession, appId;
            if (currentSession.key) {
               // triggered by reloading, reuse key in the sessionData
               const parsedIds = this.sessionUtil.getIdsFromSessionKey(currentSession.key);
               originId = parsedIds.originId;
               sessionId = parsedIds.sessionId;
               isMultiSession = parsedIds.isMultiSession;
               appId = undefined;
            } else {
               // from param, where is triggered by single app launching or launch from launcher page
               originId = currentSession.originId;
               appId = currentSession.appId;
               sessionId = currentSession.sessionId;
               isMultiSession = await this.sessionUtil.isMultiSession(appId);
            }
            opt.sessionId = currentSession.sessionId;
            opt.isApplicationSession = true;
            opt.isMultiSession = isMultiSession;
            opt.isShadow = false;
            if (currentSession.url) {
               opt.key = SessionUtil.getSessionKey(isMultiSession, originId, sessionId);
            } else {
               opt.key = null;
            }
            opt.name = undefined;
            opt.reconnectToken = currentSession.reconnectToken;
            opt.targetUrl = currentSession.url;
            opt.triedSSLVerify = currentSession.triedSSLVerify;
            opt.enableUsb = currentSession.enableUsb;
            opt.brokerUrl = currentSession?.brokerUrl;
            opt.dspecId = currentSession.dspecId;
            opt.redirectSetting = currentSession.redirectSetting;
            await this.connectToApplication(opt);
         } else {
            opt.isApplicationSession = false;
            opt.isMultiSession = false;
            opt.isShadow = currentSession.isShadow;
            opt.key = currentSession.key;
            opt.name = currentSession.name;
            opt.reconnectToken = currentSession.reconnectToken;
            opt.targetUrl = currentSession.url;
            opt.triedSSLVerify = currentSession.triedSSLVerify;
            opt.enableUsb = currentSession.enableUsb;
            opt.brokerUrl = currentSession?.brokerUrl;
            opt.redirectSetting = currentSession.redirectSetting;
            opt.sessionId = currentSession.sessionId;
            opt.dspecId = currentSession.dspecId;
            await this.connectToDesktop(opt);
         }

         if (runningSession.length > 0) {
            await this._executeRunningSession(runningSession, false);
         }
      }
   };

   /**
    * sortRunningSession
    *
    * Sort an array of running sessions such that the last element in the
    * array is the active session.
    */
   private _sortRunningSession = (runningSession) => {
      if (runningSession.length < 2) {
         return;
      }

      // Move the active session to the end of the list
      for (let i = 0; i < runningSession.length; i++) {
         // Assume there is only one active item in the array.
         if (runningSession[i].isActive) {
            runningSession.push(runningSession.splice(i, 1)[0]);
            break;
         }
      }
   };

   /**
    * connectToAllApplications
    *
    * Connect to all the running app session, currently only limited to single sessions.
    * Using XML to get the session List and do the reconnection in the JSCDK.
    *
    * @params focusedSessionKey focused session key.
    */
   private _connectToAllApplications = (focusedSessionKey, onDone?: any, callbackParam?: any) => {
      Logger.info(
         "Start to reload all the applications which is triggered by single session: " + focusedSessionKey,
         Logger.WMKS
      );

      this.xmlApiService.reconnectApp(focusedSessionKey).then((response) => {
         let sessionList = [],
            i = 0;
         Logger.info(response, Logger.WMKS);
         if (response && response.success && response.successSessionInfo && response.successSessionInfo.length > 0) {
            for (i = 0; i < response.successSessionInfo.length; i++) {
               const originId = response.successSessionInfo[i].originId;
               const sessionId = response.successSessionInfo[i].sessionId;
               const applicationId = response.successSessionInfo[i].id;
               // We only reconnect for the single sessions, so still use originId to reduce code change.
               const isMultiSessionMode = false;
               const sessionKey = SessionUtil.getSessionKey(isMultiSessionMode, originId, sessionId);
               // Do not try to reconnect to a session with the same
               // origin ID as the already running focused session
               if (sessionKey === focusedSessionKey) {
                  Logger.error("should not reload a loaded session", Logger.WMKS);
                  continue;
               }
               sessionList.push({
                  originId: originId,
                  sessionId: sessionId,
                  url: response.successSessionInfo[i].blastUrl,
                  isActive: sessionKey === focusedSessionKey,
                  isApplicationSession: true
               });
            }
            // Launch the other sessions and schedule changing the
            // the focus to focusedSession as a callback
            this._executeRunningSession(sessionList, true).then(() => {
               if (focusedSessionKey) {
                  this.htmlRemoteSessionManager.activeSession(focusedSessionKey, false);
               }
               if (onDone) {
                  onDone(callbackParam);
               }
            });
         } else {
            // No sessions to reconnect just bring the focused session
            // to the front
            if (focusedSessionKey) {
               this.htmlRemoteSessionManager.activeSession(focusedSessionKey, false);
            }
            if (onDone) {
               onDone(callbackParam);
            }
         }
      });

      /*
       *  Multiple session resumption for HTML5 client. (Regardless of ws1 mode or not)
       *  - : Only resume foreground session.
       *    (There is a limitation for resume multiple sessions, refer 2786210, 2856170 for more detail)
       */
      if (!clientUtil.isChromeClient()) {
         // For bug 2859402, do not resume the launched multi-session application to prevent repeated connections to the same websocket.
         this.resumeMultiBlastSession([focusedSessionKey]);
      }
   };

   /**
    * connectToApplication
    *
    * Connect to a specified application. If it is the bootstrap time,
    * we will connect the specified url. If it is launched by sidebar,
    * we try to reuse the session from the same farm and launch an app
    * from it. Otherwise, launch a new app session.
    *
    * replace originId with sessionId as key for multisession single user feature
    */
   public connectToApplication = async (blastInfo: BlastConnectInfo): Promise<void> => {
      if (blastInfo.isMultiSession === undefined) {
         blastInfo.isMultiSession = await this.sessionUtil.isMultiSession(blastInfo.key);
      }

      if (!blastInfo.targetUrl) {
         await this.xmlApiService.checkAuthStatus();
      }

      const prefData = await this.xmlApiService.getUserGlobalPref();
      this.wmksService.setPreference(prefData);

      this.wmksService.connectToBlast(blastInfo);
      this.htmlRemoteSessionManager.activeSession(blastInfo.key, true);
      this.eventBusService.dispatch(new BusEvent.ReconnectAllSessionsMsg(blastInfo.isMultiSession, blastInfo.key));
   };

   /**
    * connectToDesktop
    *
    * Connect to a specified desktop. If it is the bootstrap time,
    * we will connect the specified url. If it is launched by sidebar,
    * we will get a new session. Otherwise, we will display the hidden
    * desktop.
    *
    * @params desktopId unique desktopId.
    * @params desktopName desktop name .
    * @params targetUrl target url for connecting.
    * @params isShadow a shadow desktop
    * @params onDone the callback when completing the request.
    */
   public connectToDesktop = (blastInfo: BlastConnectInfo): Promise<void> => {
      return this.xmlApiService.getUserGlobalPref().then((prefData: XMLPreference) => {
         this.wmksService.setPreference(prefData);
         const wmksSession = this.htmlRemoteSessionManager.getSessionById(blastInfo.key);
         if (!wmksSession) {
            this.wmksService.connectToBlast(blastInfo);
            this.htmlRemoteSessionManager.activeSession(blastInfo.key, true);
         } else {
            this.htmlRemoteSessionManager.activeSession(blastInfo.key);
         }
      });
   };

   public triggerAppReconnect = (isMultiSession: boolean, key: string) => {
      let shouldTriggerReconnection = !isMultiSession;
      // For bug 2775720, when ws1 mode enabled in web client,
      // normal remote apps are recovered when multi-session mode enabled app is connected
      // For bug 2856170, enable multi session application reconnection for all scenarios.
      if (!clientUtil.isChromeClient() && !clientUtil.isTitanClient()) {
         shouldTriggerReconnection = true;
      }
      if (!this._allAppsReconnected && shouldTriggerReconnection) {
         this._allAppsReconnected = true;
         this._connectToAllApplications(key);
      }
   };

   /**
    * Resume multi-session applications
    * @param ignoredSessionKeys The multi-session application keys that do not need to resumed
    */
   private resumeMultiBlastSession = (ignoredSessionKeys: string[] = []): void => {
      const preHorizonId = this.sessionDataService.getHorizonId();
      const horizonId = this.localStorageService.get(CST.COOKIE.HORIZON_ID) || "";
      this.sessionDataService.updateHorizonId(horizonId);
      if (!horizonId) {
         Logger.warning("Non-WS1 session, cancel reconnection for multi-sessions", Logger.WMKS);
         return;
      }

      if (preHorizonId !== horizonId) {
         Logger.warning("Detecting WS1 session change, cancel reconnection for multi-sessions", Logger.WMKS);
         return;
      }

      const runningMultiSessions = (this.sessionDataService.getMultiRunningSessions() || []).filter(
         ({ key }) => !ignoredSessionKeys.includes(key)
      );
      if (runningMultiSessions.length === 0) {
         Logger.warning("Cannot read any running multi session from session storage!", Logger.WMKS);
         return;
      }

      this._executeRunningSession(runningMultiSessions, true).then(() => {
         const maxConflictWaitTime = 5000;
         const handleConflict = () => {
            if (this.htmlRemoteSessionManager.hasConflictWithStorage()) {
               this.htmlRemoteSessionManager.updateRunningSessions();
               Logger.info("reconnect list has been overwritten after detecting conflict", Logger.WMKS);
            }
         };
         const conflictIntervalTimer = setInterval(handleConflict, 1000);
         setTimeout(() => {
            clearInterval(conflictIntervalTimer);
         }, maxConflictWaitTime);
      });
   };
}
