/**
 * ******************************************************
 * Copyright (C) 2014-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { ConnectionURIModel } from "./connection-uri-model";
import { clientUtil } from "@html-core";
import { SyncPolicyRedirectClientRuleAction } from "../../jscdk/jscdk-interface";

//will be replace with real class later
type googleAdminList = any;

@Injectable()
export class ConnectionServerModel {
   username: string;
   host: string;
   supportedProtocols: string[];
   connectionProtocol: string;
   domain: string;
   tokenUsername: string;
   horizonId: string;
   profixForF5: string;
   googleAdminSettings: googleAdminList;
   googleCommonAdminSettings;
   comingHost: string;
   policyUrlFilteringRules: SyncPolicyRedirectClientRuleAction;

   constructor() {
      this.reset();
      // Server host address.
      this.host = "";

      // An array of supported protocols.
      this.supportedProtocols = ["BLAST"];

      // Launch an appropriate client for the connection protocol
      this.connectionProtocol = "BLAST";

      // Username
      this.username = "";

      // Domain
      this.domain = "";

      // Token username
      this.tokenUsername = "";

      this.profixForF5 = "";

      /*
       * A HWS session identifier.
       * wiki: https://wiki.eng.vmware.com/View/ViewWorkspaceSessionSynchronization
       */
      this.horizonId = "";
   }

   // Reset server model, not including reset broker.
   public reset = (): void => {
      this.username = "";
      this.domain = "";
      this.tokenUsername = "";
      this.horizonId = "";
      this.comingHost = "";
      if (clientUtil.isChromeClient()) {
         this.host = "";
      }
   };

   public onHostChosen = (hostAddress: string): void => {
      this.host = hostAddress;
   };

   public updateComingHost = (url) => {
      this.comingHost = url;
   };

   /*
    * load the data from the URL model, should be trigger when the info in
    * the URL is valid as the current connected info.
    */
   public loadFromURIModel = (connectionURIModel: ConnectionURIModel): void => {
      let i: number,
         key: string,
         srcKey: string,
         params: any,
         keyList = ["username", "domain", "horizonId", "tokenUsername"],
         specialKeyMap = {
            domain: "domainName",
            username: "userName"
         };

      if (!connectionURIModel || !connectionURIModel.params) {
         return;
      }
      params = connectionURIModel.params;
      for (i = 0; i < keyList.length; i++) {
         key = keyList[i];
         srcKey = specialKeyMap[key] || key;
         if (params[srcKey]) {
            this[key] = params[srcKey];
         }
      }
      this.profixForF5 = connectionURIModel.getF5postFix();
   };

   // used for Chrome Client only, thus use simplified version is enough
   public getXMLServerURL = (): string => {
      if (!this.host) {
         return "";
      }
      return (
         "https://" + this.host.replace("https://", "") + "/broker/xml" + (this.profixForF5 ? this.profixForF5 : "")
      );
   };
}
