/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { Subject } from "rxjs";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { repeatValidator } from "../common/validators";
import { LoginService } from "./../login-root/login.service";

interface ChangePasswordData {
   content: {
      username: string;
      error?: string;
      oldpassword?: string;
      reAuth?: boolean;
   };
}

class ChangePasswordCredential {
   constructor(
      public username: string,
      public oldPassword: string,
      public newPassword: string,
      public newPasswordRepeat: string, //redundant, but requested by server.
      public isAuth: boolean
   ) {}
}

type ChangeListener = (string) => void;
@Component({
   selector: "login-change-password",
   templateUrl: "./change-password.component.html"
})
export class ChangePasswordComponent extends BaseViewComponent implements BaseViewComponentInterface {
   @Input() fromData;
   // private static refreshSubject$ = new Subject<any>();
   // public static redraw = function(data: any) {
   //    ChangePasswordComponent.refreshSubject$.next(data);
   // };

   public error: string = "";
   public cancelLoading: boolean = false;
   public allowClear: {
      oldPassword: boolean;
      newPassword: boolean;
      newPasswordRepeat: boolean;
   };

   public usernameControl: UntypedFormControl;
   public oldPasswordControl: UntypedFormControl;
   public newPasswordControl: UntypedFormControl;
   public newPasswordRepeatControl: UntypedFormControl;
   public changePasswordForm: UntypedFormGroup;

   private _reAuth: boolean;
   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private modalDialogService: ModalDialogService,
      private loginService: LoginService
   ) {
      super(changeDetector, "WindowsPasswordExpired");
      // ChangePasswordComponent.refreshSubject$.subscribe((data) => {
      //    this.onRefreshed(data);
      // });

      this.loginService.changPasswordRedraw$.subscribe((data) => {
         this.onRefreshed(data);
      });

      this.allowClear = {
         oldPassword: false,
         newPassword: false,
         newPasswordRepeat: false
      };
      this.usernameControl = new UntypedFormControl("", []);
      this.oldPasswordControl = new UntypedFormControl("", [Validators.required]);
      this.newPasswordControl = new UntypedFormControl("", [Validators.required]);
      this.newPasswordRepeatControl = new UntypedFormControl("", [Validators.required]);
      this.changePasswordForm = new UntypedFormGroup(
         {
            username: this.usernameControl,
            oldPassword: this.oldPasswordControl,
            newPassword: this.newPasswordControl,
            newPasswordRepeat: this.newPasswordRepeatControl
         },
         [repeatValidator("newPassword", "newPasswordRepeat")]
      );
   }

   private _initData = (redrawData?) => {
      let data;
      if (redrawData) {
         data = redrawData;
      } else {
         data = this.data as ChangePasswordData;
      }
      this.onAuthScreen(this.componentName, data);
      this.usernameControl.setValue(data.content.username);
      if (data.content.oldpassword) {
         this.oldPasswordControl.setValue(data.content.oldpassword);
      } else {
         this.oldPasswordControl.setValue("");
      }

      this.newPasswordControl.setValue("");
      this.newPasswordRepeatControl.setValue("");
      this._reAuth = !!data.content.reAuth;
      // Set error message.
      if (data.content.error) {
         this.error = data.content.error;
      } else {
         this.error = "";
      }
   };

   public onRefreshed(data?) {
      super.onRefreshed();
      if (data) {
         this._initData(data);
      } else {
         this._initData();
      }
   }

   private _getChangePasswordCredential(): ChangePasswordCredential {
      const fromValue = this.changePasswordForm.value;
      return new ChangePasswordCredential(
         fromValue.username,
         fromValue.oldPassword,
         fromValue.newPassword,
         fromValue.newPasswordRepeat,
         this._reAuth
      );
   }
   public onSubmit(event) {
      this.cancelLoading = false;
      /**
       * web API 'event.submitter' is not supported in IE and Safari
       * so add condition to verified if event.submitter is undefined here
       */
      if (
         !this.changePasswordForm.invalid &&
         !this.blankOutContent &&
         (!event.submitter || (event.submitter && event.submitter.id !== "changePasswordCancelBtn"))
      ) {
         const credential = this._getChangePasswordCredential();
         this.jscdkCommonInvoker.changePassword(
            credential.oldPassword,
            credential.newPassword,
            credential.newPasswordRepeat,
            this._reAuth
         );
      }
   }

   public cancel() {
      this.cancelLoading = true;
      if (this.blankOutContent) {
         this.jscdkCommonInvoker.cancelCurrentRequest({
            name: "WindowsPasswordExpired",
            content: {
               oldpassword: this.oldPasswordControl.value(),
               error: this.error
            }
         });
      } else {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"Unknown");
         if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
            this.modalDialogService.closeDialogByType("reauth-window");
         }
      }
   }

   public clearOldPassword() {
      this.oldPasswordControl.setValue("");
   }

   public clearNewPassword() {
      this.newPasswordControl.setValue("");
   }

   public clearNewPasswordRepeat() {
      this.newPasswordRepeatControl.setValue("");
   }

   private _getChangeListener(type: string): ChangeListener {
      return (value) => {
         this.allowClear[type] = !!value;
      };
   }

   public renderData = () => {
      this.usernameControl.valueChanges.subscribe(this._getChangeListener("username"));
      this.oldPasswordControl.valueChanges.subscribe(this._getChangeListener("oldPassword"));
      this.newPasswordControl.valueChanges.subscribe(this._getChangeListener("newPassword"));
      this.newPasswordRepeatControl.valueChanges.subscribe(this._getChangeListener("newPasswordRepeat"));
      this._initData();
   };
}
