/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import * as CST from "@html-core";
import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { ConnectionServerModel } from "../../../shared/common/model/connection-server-model";
import { ReauthDialogService } from "../../../shared/desktop/re-auth/reauth-dialog.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { Subject } from "rxjs";
import { TranslateService } from "@html-core";
import { RootModel } from "../../common/model/root-model";
import { LoginService } from "./../login-root/login.service";

interface SecurIDPasscodeData {
   content: {
      label?: string;
      titleString?: string;
      username?: string;
      error?: string;
      authType?: string;
      usernameLabel?: string;
      passcodeLabel?: string;
      reAuth?: boolean;
      usernameReadOnly: string;
   };
}
class SecurIDPasscodeCredential {
   constructor(
      public username: string,
      public passcode: string,
      public reAuth: boolean
   ) {}
}

@Component({
   selector: "login-securid-passcode",
   templateUrl: "./securid-passcode.component.html"
})
export class SecurIDPasscodeComponent extends BaseViewComponent implements BaseViewComponentInterface {
   @Input() fromData;
   public cancelLoading: boolean = false;
   public error: string;
   public titleString: string;
   public usernamePlaceholder: string;
   public passcodePlaceholder: string;
   public authType: string;
   public usernameReadOnly: boolean;
   private _reAuth: boolean;
   private _initialUsername: string;

   public securIDUsernameControl: UntypedFormControl;
   public securIDPasscodeControl: UntypedFormControl;
   public securIDPasscodeForm: UntypedFormGroup;
   public faServer: string = "";

   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private connectionServerModel: ConnectionServerModel,
      private reauthDialogService: ReauthDialogService,
      private translationService: TranslateService,
      private modalDialogService: ModalDialogService,
      private rootModel: RootModel,
      private loginService: LoginService
   ) {
      super(changeDetector, "SecurIDPasscode");
      this.loginService.securIDPasscodeRedraw$.subscribe((data) => {
         this.onRefreshed();
      });

      this.securIDUsernameControl = new UntypedFormControl("", [Validators.required]);
      if (this.authType !== "RADIUS") {
         this.securIDPasscodeControl = new UntypedFormControl("", [Validators.required]);
      } else {
         this.securIDPasscodeControl = new UntypedFormControl("");
      }
      this._initData();
      this.securIDPasscodeForm = new UntypedFormGroup({
         username: this.securIDUsernameControl,
         passcode: this.securIDPasscodeControl
      });
      const faServer = this.rootModel.get("faServer");
      if (faServer && faServer !== "") {
         this.faServer = faServer;
         this.rootModel.set("faServer", "");
      }
   }

   public onRefreshed = () => {
      super.onRefreshed();
      this._initData();
   };

   private _initData = () => {
      const data = this.data as SecurIDPasscodeData;
      this.onAuthScreen(this.componentName, data);
      if (data.content.username) {
         this._initialUsername = data.content.username;
         this.securIDUsernameControl.setValue(this._initialUsername);
      }
      this.usernameReadOnly = data.content.usernameReadOnly === "yes";

      if (data.content.error) {
         this.error = data.content.error;
      }
      if (data.content.label) {
         this.titleString = this.translationService._T("dialog_prompt_passcode", data.content.label);
         this.usernamePlaceholder = this.translationService._T("dialog_placeholder_username");
         this.passcodePlaceholder = this.translationService._T("dialog_placeholder_passcode");

         if (data.content.usernameLabel && !data.content.passcodeLabel) {
            this.titleString = this.translationService._T(
               "dialog_prompt_authLabel_usernameLabel",
               data.content.label,
               data.content.usernameLabel
            );
            this.usernamePlaceholder = data.content.usernameLabel;
         } else if (!data.content.usernameLabel && data.content.passcodeLabel) {
            this.titleString = this.translationService._T(
               "dialog_prompt_authLabel_passcodeLabel",
               data.content.label,
               data.content.passcodeLabel
            );
            this.passcodePlaceholder = data.content.passcodeLabel;
         } else if (data.content.usernameLabel && data.content.passcodeLabel) {
            this.titleString = this.translationService._T(
               "dialog_prompt_authLabel_usernameLabel_passcodeLabel",
               data.content.label,
               data.content.usernameLabel,
               data.content.passcodeLabel
            );
            this.usernamePlaceholder = data.content.usernameLabel;
            this.passcodePlaceholder = data.content.passcodeLabel;
         }
      } else if (data.content.titleString && data.content.usernameLabel && data.content.passcodeLabel) {
         this.titleString = data.content.titleString;
         this.usernamePlaceholder = data.content.usernameLabel;
         this.passcodePlaceholder = data.content.passcodeLabel;
      } else {
         Logger.error("invalid response data for securIDPasscode:" + JSON.stringify(data));
      }

      if (data.content.authType) {
         this.authType = data.content.authType.toUpperCase();
      } else {
         this.authType = "RSA";
      }

      if (data.content.reAuth) {
         this._reAuth = data.content.reAuth;
      }

      this.cancelLoading = false;
      setTimeout(() => {
         this.blankOutContent = false;
         this.changeDetector.detectChanges();
      });
   };

   private _getCredential = (): SecurIDPasscodeCredential => {
      const fromValue = this.securIDPasscodeForm.value;
      return new SecurIDPasscodeCredential(fromValue.username, fromValue.passcode, this._reAuth);
   };

   public onSubmit = () => {
      this.cancelLoading = false;
      setTimeout(() => {
         this.blankOutContent = true;
         this.clearSecuridPasscode();
         this.changeDetector.detectChanges();
      });
      if (!this.securIDPasscodeForm.invalid && !this.blankOutContent) {
         const credential = this._getCredential();
         this.connectionServerModel.tokenUsername = credential.username;
         if (credential.reAuth) {
            this.reauthDialogService.setReAuthCallBack();
         }
         this.jscdkCommonInvoker.submitSecurIDPasscode(credential.username, credential.passcode, credential.reAuth);
      }
   };

   public cancel = () => {
      this.cancelLoading = true;
      this.data = {
         name: "SecurIDPasscode",
         content: {
            username: this.securIDUsernameControl.value,
            titleString: this.titleString,
            usernameLabel: this.usernamePlaceholder,
            passcodeLabel: this.passcodePlaceholder,
            authType: this.authType,
            reAuth: this._reAuth,
            usernameReadOnly: String(this.usernameReadOnly)
         }
      };

      if (this.blankOutContent) {
         this.jscdkCommonInvoker.cancelCurrentRequest(this.data);
         setTimeout(() => {
            this.blankOutContent = false;
            this.onRefreshed();
            // SecurIDPasscodeComponent.redraw(this.data);
         });
      } else {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"SecurID_Passcode");
         if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
            this.modalDialogService.closeDialogByType("reauth-window");
         }
      }
   };

   public clearSecuridUsername = () => {
      this.securIDUsernameControl.setValue("");
   };
   public clearSecuridPasscode = () => {
      this.securIDPasscodeControl.setValue("");
   };

   public onKeypress = (event) => {
      setTimeout(() => {
         this.changeDetector.detectChanges();
      });
   };
}
