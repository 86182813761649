/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { EventEmitter, Output, Directive, Input } from "@angular/core";
import { AbstractModalComponent } from "./abstract-modal.component";

@Directive()
export class AbstractModalComponentWithCheckBox extends AbstractModalComponent {
   public static readonly BUTTON_KEY = "buttonName";
   public static readonly CHECKBOX_KEY = "isChecked";

   @Input()
   public isCheckBoxChecked: boolean = false;

   private getResult(buttonName: string) {
      return { BUTTON_KEY: buttonName, CHECKBOX_KEY: this.isCheckBoxChecked === true };
   }

   @Output()
   public eventEntry: EventEmitter<any> = new EventEmitter();

   public confirmModalWithCheckBox = () => {
      this.eventEntry.emit(this.getResult(AbstractModalComponent.BTN_OK));
   };

   public closeModalWithCheckBox = () => {
      this.eventEntry.emit(this.getResult(AbstractModalComponent.BTN_CANCEl));
   };
}
