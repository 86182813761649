/*********************************************************
 * Copyright (C) 2017-2021 VMware, Inc. All rights reserved.
 *********************************************************
 *
 * @format
 */

/**
 * render-cache.service.ts -- renderCacheService
 *
 * Service to handle cache for rendering.
 *
 */

import { Injectable } from "@angular/core";
import { BitBuffer } from "./bitbuffer";
import { Point, Rect, ImageRect, RectStore } from "./base-types";
import Logger from "../../../../core/libs/logger";

// update Information
class Update {
   public imageWidth: number;
   public imageHeight: number;
}

// cache format
export class CacheRect {
   public data: ArrayBuffer;
   public image: ImageBitmap;
   public updateCacheEntries: number;
   public slot: number;
   public dataLength: 0;
   public imageWidth: number;
   public imageHeight: number;
   public encodedMaskRect: Rect;
   public mask: ArrayBuffer;
   public maskLength: number;
   constructor() {
      this.data = null;
      this.image = null;
      this.updateCacheEntries = 0;
      this.slot = 0;
      this.dataLength = 0;
      this.imageWidth = 0;
      this.imageHeight = 0;
      this.encodedMaskRect = null;
      this.mask = null;
      this.maskLength = 0;
   }
}
enum UpdateCacheType {
   updateCacheOpInit = 0,
   updateCacheOpBegin = 1,
   updateCacheOpEnd = 2,
   updateCacheOpReplay = 3
}
@Injectable({
   providedIn: "root"
})
// interface class
export class RenderCacheService {
   private decodeToCacheEntry: number;
   private updateCache: Array<CacheRect>;
   private updateCacheEntries: number;
   private vncDecoder: any;

   constructor() {
      this.decodeToCacheEntry = -1;
      this.updateCache = [];
      this.updateCacheEntries = 0;
   }

   private fail = (message: string): boolean => {
      Logger.error(message);
      return false;
   };

   public releaseImage = (rect: RectStore) => {
      if (rect.image) {
         if (typeof rect.image.close === "function") {
            rect.image.close();
         }
         delete rect.image;
      }
      if (rect.data) {
         delete rect.data;
      }
   };

   /*
    *------------------------------------------------------------------------------
    *
    * _evictUpdateCacheEntry
    *
    *    Evict one entry from the update cache.  This is done in response
    *    to the payload of the Begin opcode as well as the destination
    *    slot of the Begin opcode.
    *
    * Results:
    *    None.
    *
    *------------------------------------------------------------------------------
    */
   private _evictUpdateCacheEntry = (slot: number) => {
      this.releaseImage(this.updateCache[slot]);
      this.updateCache[slot] = new CacheRect();
   };

   /*
    *----------------------------------------------------------------------------
    *
    * _executeUpdateCacheInit --
    *
    *      Handle the UPDATE_CACHE_OP_INIT subcommand.  This resets the
    *      cache, evicting all entries and resets the cache sizes and
    *      flags.  The sizes and flags must be a subset of those which
    *      the client advertised in the capability packet.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      Resets update cache.
    *
    *----------------------------------------------------------------------------
    */

   private _executeUpdateCacheInit = (rect: ImageRect) => {
      let i: number;

      for (i = 0; i < this.updateCacheEntries; i++) {
         this._evictUpdateCacheEntry(i);
      }

      this.updateCache = [];
      this.updateCacheEntries = rect.updateCacheEntries;

      for (i = 0; i < this.updateCacheEntries; i++) {
         this.updateCache[i] = new CacheRect();
      }
   };

   /*
    *----------------------------------------------------------------------------
    *
    * _updateCacheInsideBeginEnd --
    *
    *      Returns true if the decoder has received in the current
    *      framebuffer update message a VNC_UPDATECACHE_OP_BEGIN message
    *      but not yet received the corresponding OP_END.
    *
    * Side effects:
    *      None.
    *
    *----------------------------------------------------------------------------
    */

   private _updateCacheInsideBeginEnd = () => {
      return this.decodeToCacheEntry !== -1;
   };

   /*
    *----------------------------------------------------------------------------
    *
    * _updateCacheInitialized --
    *
    *      Returns true if the decoder has been configured to have an
    *      active UpdateCache and the cache size negotiation has
    *      completed..
    *
    * Side effects:
    *      None.
    *
    *----------------------------------------------------------------------------
    */

   private _updateCacheInitialized = () => {
      return this.updateCacheEntries !== 0;
   };

   /*
    *----------------------------------------------------------------------------
    *
    * _executeUpdateCacheBegin --
    *
    *      Handle the UPDATE_CACHE_OP_BEGIN subcommand.  Process the
    *      message payload, which is a mask of cache entries to evict.
    *      Evict any existing entry at the destination slot, and create a
    *      new entry there.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      Evicts elements of the update cache.
    *      Creates a new cache entry.
    *
    *----------------------------------------------------------------------------
    */

   private _executeUpdateCacheBegin = (rect: ImageRect): boolean => {
      let maskBitBuf: BitBuffer, maskState: boolean, maskCount: number, i: number, j: number;

      if (!this._updateCacheInitialized()) {
         return false;
      }

      if (this._updateCacheInsideBeginEnd() || rect.slot >= this.updateCacheEntries) {
         return this.fail("error: cache status wrong for cache begin");
      }

      maskBitBuf = new BitBuffer(rect.data, rect.dataLength);
      maskState = !maskBitBuf.readBits(1);
      maskCount = 0;
      j = 0;

      do {
         maskCount = maskBitBuf.readEliasGamma();
         maskState = !maskState;

         if (maskState) {
            for (i = 0; i < maskCount && i < this.updateCacheEntries; i++) {
               this._evictUpdateCacheEntry(i + j);
            }
         }

         j += maskCount;
      } while (j < this.updateCacheEntries && !maskBitBuf.overflow);

      this.decodeToCacheEntry = rect.slot;
      this._evictUpdateCacheEntry(rect.slot);

      this.updateCache[this.decodeToCacheEntry].imageWidth = rect.width;
      this.updateCache[this.decodeToCacheEntry].imageHeight = rect.height;
      return true;
   };

   /*
    *----------------------------------------------------------------------------
    *
    * _executeUpdateCacheEnd --
    *
    *      Handle the UPDATE_CACHE_OP_END subcommand.  Process the
    *      message payload, which is a serialized bitmask of screen
    *      regions to scatter the update image to.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      Draws to the canvas.
    *
    *----------------------------------------------------------------------------
    */
   private _executeUpdateCacheEnd = (rect: ImageRect, offset: number): boolean => {
      if (!this._updateCacheInitialized()) {
         return false;
      }
      if (!this._updateCacheInsideBeginEnd()) {
         return this.fail("error: cache status wrong for cache end");
      }
      let update: CacheRect = this.updateCache[this.decodeToCacheEntry],
         state: boolean,
         count: number,
         dstx: number = 0,
         dsty: number = 0,
         dstw: number = Math.ceil(rect.width / 16),
         dsth: number = Math.ceil(rect.height / 16),
         srcx: number = 0,
         srcy: number = 0,
         srcw: number = update.imageWidth / 16,
         availwidth: number,
         bitbuf: BitBuffer,
         targetPoint: Point;

      if (
         !this._updateCacheInitialized() ||
         !this._updateCacheInsideBeginEnd() ||
         rect.slot !== this.decodeToCacheEntry ||
         rect.slot >= this.updateCacheEntries
      ) {
         return this.fail("error: requested cache slot invalid");
      }

      update.encodedMaskRect = {
         x: rect.x,
         y: rect.y,
         width: rect.width,
         height: rect.height
      };
      update.mask = rect.data;
      update.maskLength = rect.dataLength;

      bitbuf = new BitBuffer(update.mask, update.maskLength);
      state = !bitbuf.readBits(1);
      count = 0;

      do {
         if (count === 0) {
            count = bitbuf.readEliasGamma();
            state = !state;
         }

         availwidth = Math.min(srcw - srcx, dstw - dstx);
         availwidth = Math.min(availwidth, count);

         if (state) {
            targetPoint = this.vncDecoder._normalize(
               {
                  x: rect.x + dstx * 16,
                  y: rect.y + dsty * 16
               },
               offset
            );
            // Don't worry if we don't have a full 16-wide mcu at the
            // screen edge.  The canvas will trim the drawImage
            // coordinates for us.
            //
            try {
               this.vncDecoder._context.drawImage(
                  update.image,
                  srcx * 16,
                  srcy * 16,
                  availwidth * 16,
                  16,
                  targetPoint.x,
                  targetPoint.y,
                  availwidth * 16,
                  16
               );
               srcx += availwidth;
               if (srcx === srcw) {
                  srcx = 0;
                  srcy++;
               }
            } catch (e) {
               Logger.exception(e);
            }
         }

         dstx += availwidth;
         if (dstx === dstw) {
            dstx = 0;
            dsty++;
         }

         count -= availwidth;
      } while (dsty < dsth && !bitbuf.overflow);

      this.decodeToCacheEntry = -1;
      return true;
   };

   /*
    *----------------------------------------------------------------------------
    *
    * _executeUpdateCacheReplay --
    *
    *      Handle the UPDATE_CACHE_OP_REPLAY subcommand.  Process the
    *      message payload, which is a serialized mask used to subset the
    *      bitmask provided at the time the cache entry being replayed
    *      was created.  Scatters the specified subset of the cached
    *      image to the canvas.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      Draws to the canvas.
    *
    *----------------------------------------------------------------------------
    */

   private _executeUpdateCacheReplay = (rect: ImageRect, offset: number) => {
      if (!this._updateCacheInitialized()) {
         return false;
      }

      if (rect.slot >= this.updateCacheEntries) {
         return this.fail("error: requested cache slot invalid");
      }

      if (!this.updateCache[rect.slot] || !this.updateCache[rect.slot].encodedMaskRect) {
         return this.fail("error: requested cache slot data invalid");
      }

      let update: CacheRect = this.updateCache[rect.slot],
         encodedMaskRect: Rect = this.updateCache[rect.slot].encodedMaskRect,
         dstx: number = 0,
         dsty: number = 0,
         dstw: number = Math.ceil(encodedMaskRect.width / 16),
         dsth: number = Math.ceil(encodedMaskRect.height / 16),
         availwidth: number,
         srcx: number = 0,
         srcy: number = 0,
         srcw: number = update.imageWidth / 16,
         targetPoint: Point,
         maskBitBuf: BitBuffer = new BitBuffer(rect.data, rect.dataLength),
         updateBitBuf: BitBuffer = new BitBuffer(update.mask, update.maskLength),
         updateState: boolean = !updateBitBuf.readBits(1),
         updateCount: number = 0,
         maskState: boolean = !maskBitBuf.readBits(1),
         maskCount: number = 0;

      if (
         !this._updateCacheInitialized() ||
         this._updateCacheInsideBeginEnd() ||
         rect.slot >= this.updateCacheEntries
      ) {
         return this.fail("");
      }

      do {
         if (updateCount === 0) {
            updateCount = updateBitBuf.readEliasGamma();
            updateState = !updateState;
         }
         if (maskCount === 0) {
            maskCount = maskBitBuf.readEliasGamma();
            maskState = !maskState;
         }

         availwidth = dstw - dstx;
         availwidth = Math.min(availwidth, updateCount);

         if (updateState) {
            availwidth = Math.min(availwidth, srcw - srcx);
            availwidth = Math.min(availwidth, maskCount);

            if (maskState) {
               targetPoint = this.vncDecoder._normalize(
                  {
                     x: encodedMaskRect.x + dstx * 16,
                     y: encodedMaskRect.y + dsty * 16
                  },
                  offset
               );

               try {
                  this.vncDecoder._context.drawImage(
                     update.image,
                     srcx * 16,
                     srcy * 16,
                     availwidth * 16,
                     16,
                     targetPoint.x,
                     targetPoint.y,
                     availwidth * 16,
                     16
                  );
               } catch (e) {
                  Logger.exception(e);
               }
            }

            srcx += availwidth;
            if (srcx === srcw) {
               srcx = 0;
               srcy++;
            }

            maskCount -= availwidth;
         }

         dstx += availwidth;
         if (dstx === dstw) {
            dstx = 0;
            dsty++;
         }

         updateCount -= availwidth;
      } while (dsty < dsth && !maskBitBuf.overflow && !updateBitBuf.overflow);
      return true;
   };

   /*
    *------------------------------------------------------------------------------
    *
    * _executeUpdateCacheReplay
    *
    *    Dispatch the updateCache commands according to their opcode.
    *    data is in various length as maskLength
    *
    * Results:
    *    None.
    *
    * Side Effects:
    *    None.
    *
    *------------------------------------------------------------------------------
    */

   public operateOnCache = (rect: ImageRect, offset): boolean => {
      switch (rect.opcode) {
         case UpdateCacheType.updateCacheOpInit:
            this._executeUpdateCacheInit(rect);
            break;
         case UpdateCacheType.updateCacheOpBegin:
            this._executeUpdateCacheBegin(rect);
            break;
         case UpdateCacheType.updateCacheOpEnd:
            this._executeUpdateCacheEnd(rect, offset);
            break;
         case UpdateCacheType.updateCacheOpReplay:
            this._executeUpdateCacheReplay(rect, offset);
            break;
         default:
            return this.fail("error: requested cache opcode invalid");
      }
      return true;
   };

   public setImage = (rect: ImageRect) => {
      this.updateCache[this.decodeToCacheEntry].image = rect.image;
   };

   public setVncDecoder = (decoder: any) => {
      this.vncDecoder = decoder;
   };

   public decodingCache = (): boolean => {
      return this.decodeToCacheEntry !== -1;
   };
}
