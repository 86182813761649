/**
 * *****************************************************
 * Copyright 2020-2023 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { ClientInfoService } from "../../shared/common/service/client-info.service";
import { HTML5ClientInfoService } from "./service/html5-client-info.service";
import { KillSwitchService } from "../../shared/common/service/kill-switch.service";
import { HTML5KillSwitchService } from "./service/kill-switch.service";
import { SharedCommonModule } from "../../shared/common/shared.common.module";
import { CommonModule } from "@angular/common";
import { ClipboardModule } from "../desktop/clipboard/clipboard.module";
import { RTAVService } from "../../shared/desktop/rtav/rtav.service";
import { CertHTML5Service } from "../base/service/cert.service";
import { HTML5ForeignCertService } from "../base/service/foreign-cert.service";

import { ForeignCertService } from "../../shared/base/foreign-cert.service";
import { CertService } from "../../shared/base/cert.service";
import { FTModule } from "../../shared/desktop/filetransfer/module";
import { RemoteFeatureModule } from "../../shared/rx/rx.module";
import { HTML5TranslateService } from "./service/html5-translate.service";
import { TranslateService } from "@html-core";
import { FullscreenHTML5Service } from "../../shared/utils/fullscreen-html5.service";
import { FullscreenService } from "../../shared/utils/fullscreen-service";
import { UnityService, UnityPrimaryWindowService } from "../../shared/desktop/remoteapp";
import { CDRModule } from "../../shared/desktop/cdr/cdr.module";

import { DisplayPresentationService } from "../../shared/desktop/common/display/display-presentation.service";
import { DisplayWindowreplacementService } from "../../shared/desktop/common/display/display-windowreplacement.service";
import { DisplayService } from "../../shared/desktop/common/display/display.service";
import { ExtendedApiService } from "./service/extended-api.service";
import { HorizonKMStorageService } from "../../shared/common/service/horizon-keymapping-storage.service";
import { KeyMappingStorageService } from "../../shared/common/service/keymapping-storage.service";
import { USBService } from "../../shared/desktop/usb/usb-service";
import { Html5UsbService } from "../../shared/desktop/usb/usb-service/html5-usb.service";
import { PrePrintDataService } from "../../shared/desktop/printer-redirection/pre-print-redirection.service";
import { HTMLPrePrintDataService } from "../../shared/desktop/printer-redirection/html-pre-print-redirection.service";
import { CommonSDKService } from "../../shared/common/service/sdk.service";
import { Html5SDKService } from "../SDK/sdk-service";

@NgModule({
   imports: [SharedCommonModule, CommonModule, RemoteFeatureModule, ClipboardModule, FTModule, CDRModule],
   declarations: [],
   providers: [
      RTAVService,
      HTML5ClientInfoService,
      HTML5ForeignCertService,
      HTML5TranslateService,
      CertHTML5Service,
      HTML5KillSwitchService,
      FullscreenHTML5Service,
      UnityPrimaryWindowService,
      DisplayPresentationService,
      DisplayWindowreplacementService,
      {
         provide: ClientInfoService,
         useExisting: HTML5ClientInfoService
      },
      {
         provide: KillSwitchService,
         useExisting: HTML5KillSwitchService
      },
      {
         provide: CertService,
         useExisting: CertHTML5Service
      },
      {
         provide: ForeignCertService,
         useExisting: HTML5ForeignCertService
      },
      {
         provide: TranslateService,
         useExisting: HTML5TranslateService
      },
      {
         provide: FullscreenService,
         useExisting: FullscreenHTML5Service
      },
      {
         provide: UnityService,
         useExisting: UnityPrimaryWindowService
      },
      {
         provide: DisplayService,
         // @ts-ignore
         useExisting:
            typeof window.getScreenDetails === "function" ? DisplayWindowreplacementService : DisplayPresentationService
      },
      ExtendedApiService,
      Html5SDKService,
      {
         provide: CommonSDKService,
         useExisting: Html5SDKService
      },
      HorizonKMStorageService,
      {
         provide: KeyMappingStorageService,
         useExisting: HorizonKMStorageService
      },
      {
         provide: USBService,
         useExisting: Html5UsbService
      },
      {
         provide: PrePrintDataService,
         useExisting: HTMLPrePrintDataService
      }
   ]
})
export class HTML5CommonModule {}
