/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { AfterViewInit, Component } from "@angular/core";
import { EventBusService, TranslateService, clientUtil } from "@html-core";
import { Subscription } from "rxjs";
import Logger from "../../../core/libs/logger";
import { DeviceEnumeratorService } from "../../desktop/rtav/v2/device-enumerator.service";
import {
   PreferredDeviceType,
   PreferredDeviceValue,
   devicePermissionChangeEvent,
   getPreferredDeviceKey,
   preferredDeviceChangeEvent,
   resolutionNotSet,
   videoResolutionBuiltInList
} from "../../desktop/rtav/v2/device-manager.model";
import { DevicePermissionService } from "../../desktop/rtav/v2/device-permission.service";
import { AbstractModalComponent } from "../commondialog/abstract-modal.component";
import { ViewClientModel } from "../model/viewclient-model";
import { PreferredRTAVDeviceService } from "../service/preferred-RTAV-device.service";

type DeviceOption = {
   value: string;
   name: string;
};
@Component({
   selector: "setting-rtav-dialog",
   templateUrl: "./setting-rtav-dialog.component.html"
})
export class SettingRtavDialogComponent extends AbstractModalComponent implements AfterViewInit {
   public supportAudioOutAndResolution = true;
   public supportV2Features = true;
   public isChromeClient = clientUtil.isChromeClient();
   public noDeviceMessage: string;
   public hasAudioPermission = false;
   public hasVideoPermission = false;
   public audioList: DeviceOption[] = [];
   public videoList: DeviceOption[] = [];
   public audioOutList: DeviceOption[] = [];
   public videoResolutionList: DeviceOption[] = [];
   public audioSelection: string = PreferredDeviceValue.all;
   public videoSelection: string = PreferredDeviceValue.all;
   public audioOutSelection: string = PreferredDeviceValue.all;
   public videoResolutionSelection: string = resolutionNotSet;

   private eventBusSubscription: Subscription;

   constructor(
      private eventBusService: EventBusService,
      private preferredRTAVDeviceService: PreferredRTAVDeviceService,
      private devicePermissionService: DevicePermissionService,
      private deviceEnumeratorService: DeviceEnumeratorService,
      private translate: TranslateService,
      private viewClientModel: ViewClientModel
   ) {
      super();
      this.noDeviceMessage = this.translate._T("CHOOSE_DEVICE_NO_DEVICE_M");
      this.videoResolutionList = [
         { value: resolutionNotSet, name: this.translate._T("VIDEO_RESOLUTION_NOT_SET") },
         ...videoResolutionBuiltInList.map((res): DeviceOption => {
            return {
               value: JSON.stringify(res),
               name: `${res.width}x${res.height}`
            };
         })
      ];
      if (!clientUtil.isChromium()) {
         this.supportAudioOutAndResolution = false;
      }
      if (!this.viewClientModel.enableRTAVH264Codec || !this.viewClientModel.enableRTAVOpusCodec) {
         this.supportV2Features = false;
      }
      if (this.isChromeClient) {
         this.preferredRTAVDeviceService
            .readKey(getPreferredDeviceKey(PreferredDeviceType.audioOut))
            .then((preferredLabel: string) => {
               if (preferredLabel === PreferredDeviceValue.default || preferredLabel === PreferredDeviceValue.all) {
                  this.audioOutSelection = preferredLabel;
               }
            });
      }
   }

   async ngOnInit() {
      this.eventBusSubscription = this.eventBusService.listen(devicePermissionChangeEvent).subscribe(() => {
         this.onPermissionChange();
      });
      await this.devicePermissionService.checkAndAskForPermission(false);
      await this.onPermissionChange();
   }

   ngAfterViewInit() {}

   ngOnDestroy() {
      if (this.eventBusSubscription) {
         this.eventBusSubscription.unsubscribe();
      }
   }

   public async okClicked() {
      if (this.audioSelection && this.audioSelection !== this.noDeviceMessage) {
         Logger.info(`SettingRTAV setting preferred microphone to ${this.audioSelection}`);
         await this.preferredRTAVDeviceService.setPreferredDeviceLabel(PreferredDeviceType.audio, this.audioSelection);
      }
      if (this.videoSelection && this.videoSelection !== this.noDeviceMessage) {
         Logger.info(`SettingRTAV setting preferred camera to ${this.videoSelection}`);
         await this.preferredRTAVDeviceService.setPreferredDeviceLabel(PreferredDeviceType.video, this.videoSelection);
      }
      if (this.audioOutSelection && this.audioOutSelection !== this.noDeviceMessage) {
         Logger.info(`SettingRTAV setting preferred speaker to ${this.audioOutSelection}`);
         await this.preferredRTAVDeviceService.setPreferredDeviceLabel(
            PreferredDeviceType.audioOut,
            this.audioOutSelection
         );
      }
      if (this.videoResolutionSelection) {
         Logger.info(`SettingRTAV setting preferred resolution to ${this.videoResolutionSelection}`);
         await this.preferredRTAVDeviceService.setPreferredDeviceLabel(
            PreferredDeviceType.videoResolution,
            this.videoResolutionSelection
         );
      }
      this.eventBusService.dispatch({
         type: "speaker",
         data: this.audioOutSelection
      });
      this.eventBusService.dispatch({
         type: preferredDeviceChangeEvent
      });
      this.closeModal();
   }

   public audioSelectChange = (event) => {
      if (event?.target?.value) {
         this.audioSelection = event.target.value;
      }
   };

   public videoSelectChange = (event) => {
      if (event?.target?.value) {
         this.videoSelection = event.target.value;
      }
   };

   public audioOutSelectChange = (event) => {
      if (event?.target?.value) {
         this.audioOutSelection = event.target.value;
      }
   };

   public videoResolutionSelectChange = (event) => {
      if (event?.target?.value) {
         this.videoResolutionSelection = event.target.value;
      }
   };

   private async onPermissionChange() {
      const permissions = await this.devicePermissionService.checkPermission();
      this.hasAudioPermission = permissions.audio === "granted";
      this.hasVideoPermission = permissions.video === "granted";
      await this.getListAndPreferredDevice();
   }

   private async getListAndPreferredDevice() {
      try {
         // maintain the list
         const info = await this.preferredRTAVDeviceService.getPreferredDeviceInfo();
         const defaultAudioDevice = this.deviceEnumeratorService.getDefaultAudioInfo();
         const defaultAudioOutDevice = this.deviceEnumeratorService.getDefaultAudioOutInfo();
         this.audioList = [{ value: PreferredDeviceValue.all, name: this.translate._T("ALL_AUDIO_OPTIONS") }];
         this.videoList = [{ value: PreferredDeviceValue.all, name: this.translate._T("ALL_VIDEO_OPTIONS") }];
         if (info) {
            if (this.hasAudioPermission) {
               if (info.audio.length && defaultAudioDevice) {
                  this.audioList.push(
                     {
                        value: PreferredDeviceValue.default,
                        name: `${this.translate._T("INITIAL_AUDIO_OPTIONS")} (${defaultAudioDevice?.friendlyName})`
                     },
                     ...info.audio.map((device) => ({ value: device.friendlyName, name: device.friendlyName }))
                  );
               } else {
                  this.audioSelection = this.noDeviceMessage;
                  this.audioList = [];
               }
            }
            if (this.hasVideoPermission) {
               if (info.video.length) {
                  // video doesn't have default
                  this.videoList.push(
                     ...info.video.map((device) => ({ value: device.friendlyName, name: device.friendlyName }))
                  );
               } else {
                  this.videoSelection = this.noDeviceMessage;
                  this.videoList = [];
               }
            }
            let defaultDeviceName = this.translate._T("INITIAL_AUDIO_OPTIONS");
            if (this.hasAudioPermission && defaultAudioOutDevice) {
               defaultDeviceName += ` (${defaultAudioOutDevice.label})`;
            }
            // audio out list should contain all and default if the following conditions are met:
            // 1. no audio permission; 2. has audio permission && device list is not empty; 3. is Firefox or Safari
            if (
               !this.hasAudioPermission ||
               (this.hasAudioPermission && info.audioOut.length) ||
               !this.supportAudioOutAndResolution
            ) {
               this.audioOutList = [
                  { value: PreferredDeviceValue.all, name: this.translate._T("ALL_AUDIO_OPTIONS") },
                  {
                     value: PreferredDeviceValue.default,
                     name: defaultDeviceName
                  }
               ];
            }
            if (!this.audioOutList.length) {
               this.audioOutSelection = this.noDeviceMessage;
            }
         }
         // edge case: user denied the permission AND doesn't have any device
         const noPermissionAndNoDevice = await this.devicePermissionService.noPermissionAndNoDevice();
         if (noPermissionAndNoDevice.audio) {
            this.audioSelection = this.noDeviceMessage;
            this.audioList = [];
         }
         if (noPermissionAndNoDevice.video) {
            this.videoSelection = this.noDeviceMessage;
            this.videoList = [];
         }
         if (this.supportAudioOutAndResolution && noPermissionAndNoDevice.audioOut) {
            this.audioOutSelection = this.noDeviceMessage;
            this.audioOutList = [];
         }

         // maintain the selection
         // also match the id for backward compatibility
         if (info) {
            if (this.hasAudioPermission && info.preferredLabels.audio) {
               if (info.preferredLabels.audio === PreferredDeviceValue.default) {
                  this.audioSelection = PreferredDeviceValue.default;
               } else {
                  for (const device of info.audio) {
                     if (
                        info.preferredLabels.audio === device.friendlyName ||
                        info.preferredLabels.audio === device.deviceId
                     ) {
                        this.audioSelection = device.friendlyName;
                        break;
                     }
                  }
               }
            }
            if (this.hasVideoPermission && info.preferredLabels.video) {
               if (info.preferredLabels.video === PreferredDeviceValue.default) {
                  this.videoSelection = PreferredDeviceValue.default;
               } else {
                  for (const device of info.video) {
                     if (
                        info.preferredLabels.video === device.friendlyName ||
                        info.preferredLabels.video === device.deviceId
                     ) {
                        this.videoSelection = device.friendlyName;
                        break;
                     }
                  }
               }
            }
            if (this.hasAudioPermission && this.supportAudioOutAndResolution && info.preferredLabels.audioOut) {
               if (info.preferredLabels.audioOut === PreferredDeviceValue.default) {
                  this.audioOutSelection = PreferredDeviceValue.default;
               } else {
                  for (const device of info.audioOut) {
                     if (
                        info.preferredLabels.audioOut === device.label ||
                        info.preferredLabels.audioOut === device.deviceId
                     ) {
                        this.audioOutSelection = device.label;
                        break;
                     }
                  }
               }
            }
            if (this.hasVideoPermission && info.preferredLabels.videoResolution) {
               for (const res of this.videoResolutionList) {
                  if (info.preferredLabels.videoResolution === res.value) {
                     this.videoResolutionSelection = info.preferredLabels.videoResolution;
                     break;
                  }
               }
            }
         }
      } catch (e) {
         Logger.error(e);
      }
   }
}
