<div class="modal-title dialog-title">{{ 'MULTI_LAUNCH_T' | i18nv2 }}</div>
<div class="modal-body dialog-content" fuzzy-free>
    <div class="multi-launch-container">
        <div class="multi-launch-applications">
            <div class="multi-launch-title">
                {{ 'MULTI_LAUNCH_T2' | i18nv2 }}
            </div>
            <br>
            <div class="multi-launch-setting-dialog">
                <ul class="applications-list">
                    <li *ngFor="let setting of configurableApplications" id="{{settingProfix + setting.id}}">
                        <div class="multi-launch-application" tabindex="0" (keyup)="keyboardHandler($event, setting)">
                            <label for="{{checkBoxIdProfix + setting.id}}" class="hidecontentlabel" tabindex="-1">{{setting.name}}</label>
                            <!--<input type="checkbox" on-finish-render="ngRepeatFinished" id="{{checkBoxIdProfix + setting.id}}" checked (click)="checkboxClicked(setting)">-->
                            <input type="checkbox" id="{{checkBoxIdProfix + setting.id}}" checked (click)="checkboxClicked(setting)" tabindex="-1">
                            <img class="multi-launch-app-img" [src]="this.imageAsset.deAppIcon" alt="{{setting.name}} icon" icon-load url="{{setting.iconSrc}}" title="{{setting.name}}" tabindex="-1"><span class="application-name" title="{{setting.name}}">&nbsp;{{setting.name}}</span>
                        </div>
                    </li>
                    <li *ngFor="let setting of enforcedApplications" id="{{settingProfix + setting.id}}">
                        <div class="multi-launch-application" tabindex="0" (keyup)="keyboardHandler($event, setting)">
                            <label for="{{checkBoxIdProfix + setting.id}}" class="hidecontentlabel" tabindex="-1">{{setting.name}}</label>
                            <input input type="checkbox" checked onclick="return false;" disabled="disabled" id="{{checkBoxIdProfix + setting.id}}" tabindex="-1">
                            <img class="multi-launch-app-img" [src]="this.imageAsset.deAppIcon" alt="{{setting.name}} icon" icon-load url="{{setting.iconSrc}}" title="{{setting.name}}" tabindex="-1"><span class="application-name" title="{{setting.name}}">&nbsp;{{setting.name}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer dialog-button-row">
    <div id="multi-launch-setting-ok" tabindex="0" class="modal-button-base modal-button-right modal-button-blue" (click)="multiLaunchOkClicked()" (keydown)="multiLaunchOkKeydown($event)">
        {{ 'OK' | i18nv2 }}
    </div>
    <div id="multi-launch-setting-cancel" tabindex="0" class="modal-button-base modal-button-right modal-button-blue" (click)="closeModal()" (keydown)="multiLaunchCancelKeydown($event)">
        {{'CANCEL' | i18nv2 }}
    </div>
    <div id="multi-launch-setting-apply" tabindex="0" class="modal-button-base modal-button-right modal-button-blue" (click)="multiLaunchApplyClicked()" (keydown)="multiLaunchApplyKeydown($event)" [ngClass]="{disable: disableApplyBtn===true}">
        {{ 'APPLY' | i18nv2 }}
    </div>
</div>