/**
 * ******************************************************
 * Copyright (C) 2012-2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * doCancelAuthenticationHandler.js --
 *
 *      Implementation of the message handler to cancel authentication.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { StateEnum, MessageHandler } from "./messageHandler";

export default function DoCancelAuthenticationHandler() {
   let router;

   // member variables below
   this.messageName = "do-cancel-authentication";
   this.messageText = "do-cancel-authentication";
   this.responseTag = "cancel-authentication";

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
}

// inherits MessageHandler prototype
DoCancelAuthenticationHandler.prototype = new MessageHandler();

// constructor
DoCancelAuthenticationHandler.constructor = DoCancelAuthenticationHandler;

/**
 * parse configuration information from the response XML of GetConfiguration
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return null
 */
DoCancelAuthenticationHandler.prototype.parseResult = function (responseXML) {
   let cancelAuthResponse = {},
      xmlDoc = $(responseXML),
      brokerTag,
      responses,
      result;

   brokerTag = $(xmlDoc.children()[0]);
   if (!brokerTag) {
      Logger.error("response of doCancelAuthenticationHandler error");
      return null;
   }

   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   util.addItemForJson(cancelAuthResponse, "result", result);
   return cancelAuthResponse;
};

DoCancelAuthenticationHandler.prototype.setCallback = function (onDone) {
   this.onDone = onDone;
};

/**
 * Wait 200 ms on safari to bypass bug 2222220 and other similar bugs
 * related to the scoket disconnection triggered by this XML.
 */
DoCancelAuthenticationHandler.prototype.onUpdated = function () {
   const ua = window.navigator.userAgent.toLowerCase();
   const isSafari = ua.indexOf("applewebkit") > -1 && ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
   if (isSafari) {
      setTimeout(this._onUpdated.bind(this), 200);
   } else {
      this._onUpdated();
   }
};

DoCancelAuthenticationHandler.prototype._onUpdated = function () {
   const cancelAuthAction = { name: "Portal" };
   const router = util.getObject(globalArray, "router");

   MessageHandler.prototype.onUpdated.apply(this);
   /**
    * Currently we ignore failure because some older brokers do not support
    * this message.  The only valid error from a broker that supports this
    * feature is ALREADY_AUTHENTICATED, but we will hopefully never get that
    * error because we will only trigger this handler if we aren't fully
    * authenticated.
    */
   this.setState(StateEnum.DONE);

   if (this.onDone) {
      this.onDone();
      this.onDone = null;
   } else {
      JSCDKSetUI(JSON.stringify(cancelAuthAction));
   }

   if (router) {
      router.setDoCancelAuthentication();
      Logger.info("Cancel authentication completed.");
   }
   if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
      Logger.debug("Cancel authentication result is OK.");
   } else {
      Logger.error("Cancel authentication failed.");
   }
};
