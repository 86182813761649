/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { GeolocationNotification } from "../../common/service/geolocation-notification";
import { GeolocationService } from "../../common/service/geolocation-service";
import { MediastreamNotification } from "../../common/service/mediastream-notification";
import { Html5MMRService } from "../channels/html5MMR-client/html5MMR.service";
import { MediaManager } from "./media-manager";
import { MediaOverlayComponent } from "./media-overlay.component";

@NgModule({
   declarations: [MediaOverlayComponent],
   providers: [GeolocationNotification, GeolocationService, Html5MMRService, MediastreamNotification],
   exports: [MediaOverlayComponent]
})
export class MediaModule {}
