/**
 * ******************************************************
 * Copyright (C) 2012-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getApplicationConnectionHandler.js --
 *
 *      Implementation of the message handler to get application connection.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import SetUserGlobalPrefHandler from "./setUserGlobalPrefHandler";
import ProtocolRedirectionController from "./redirect/protocol-redirection-controller";
import { ShowApplicationAction } from "../jscdk-interface";
import { CryptoKeyDerivationService } from "../../common/service/crypto-key-derivation.service";
import { getUuid } from "@html-core";

const BlastType = {
   CURRENT: "current",
   NEW: "new"
};

export default function GetApplicationConnectionHandler() {
   // member variables
   this.messageName = "get-application-connection";
   this.messageText = "get-application-connection";
   this.responseTag = "application-connection";
   // Use current blastType by default.
   this.blastType = BlastType.CURRENT;

   this.preferences = {};

   this.originId = null;

   this.callbackParams = null;

   this.itemId = null;
   this.redirectMsg = false;

   // register dependencies here
   let getLaunchItemsObject;
   let getTunnelConnObject, setPrefObject;
   let router;
   getLaunchItemsObject = util.getObject(globalArray, "get-launch-items");
   if (getLaunchItemsObject) {
      this.registerHandler(getLaunchItemsObject, "state");
   }
   getTunnelConnObject = util.getObject(globalArray, "get-tunnel-connection");
   if (getTunnelConnObject) {
      this.registerHandler(getTunnelConnObject, "state");
   }
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }

   // maybe not good to invoke the SetUserGlobalPrefHandler every time, would
   // be changed when re-factorying
   setPrefObject = util.getObject(globalArray, "set-user-global-preferences");
   if (!setPrefObject) {
      setPrefObject = new SetUserGlobalPrefHandler();
      globalArray["set-user-global-preferences"] = setPrefObject;
   }
   this.composedHandlerList = [this, setPrefObject];
}

GetApplicationConnectionHandler.prototype = new MessageHandler(); // inherits
// MessageHandler
// prototype
GetApplicationConnectionHandler.constructor = GetApplicationConnectionHandler;

GetApplicationConnectionHandler.prototype.combineWithPerf = function () {
   const setPrefObject = util.getObject(globalArray, "set-user-global-preferences");
   this.composedHandlerList = [this, setPrefObject];
};
GetApplicationConnectionHandler.prototype.sendAlong = function () {
   this.composedHandlerList = [];
};

GetApplicationConnectionHandler.prototype.saveOriginId = function (originId) {
   this.originId = originId;
   if (this.cachedRequestInfo) {
      this.cachedRequestInfo["origin-id"] = originId;
   }
};

GetApplicationConnectionHandler.prototype.isAppParamSupported = function () {
   return parseFloat(globalArray["brokerVersion"]) >= parseFloat(globalArray["applicationParamVersion"]);
};

/**
 * Generate xml  for launching params, written in a format that
 *     could be easily extended to support other params
 * @param  {object} launchParam The Object that contains launching params could
 *    be
 *     {args:["C:\Users\uesr01\Documents\test.docx",
 *    C:\Users\uesr01\Documents\the_other_test.docx]}
 * @return {string} The xml in the form of string
 */
GetApplicationConnectionHandler.prototype.getParamElement = function (launchParam) {
   let args,
      i,
      nameElement = "",
      paramElements = "",
      commandLineElement = "",
      fileAssociationElement = "";

   if (globalArray["file-association"] && globalArray["file-association"].length > 0) {
      fileAssociationElement = this.createFileAssociationElement();
      paramElements += fileAssociationElement;
   }

   if (typeof launchParam !== "object" || !this.isAppParamSupported()) {
      return paramElements;
   }

   args = launchParam.args;
   if (Array.isArray(args) && args.length > 0) {
      nameElement = util.createElement("name", "appCommandLine");
      for (i = 0; i < args.length; i++) {
         if (args[i] !== "") {
            commandLineElement += util.createElement("value", args[i]);
         }
      }
      if (commandLineElement !== "") {
         commandLineElement = util.createElement("values", commandLineElement);
         paramElements += util.createElement("param", nameElement + commandLineElement);
      }
   }

   if (paramElements !== "") {
      paramElements = util.createElement("params", paramElements);
   }
   return paramElements;
};

/**
 * Set the request XML for getApplicationConnection.
 *
 * @param applicationId [in] application id to connect
 * @param protocol [in] which protocol to use
 * @param environmentInfo [in] optional object containing client info key/value
 *    pairs
 * @param multiSession [in] inform the broker the user launched the application in
 *    single-session or multi-session mode
 */
GetApplicationConnectionHandler.prototype.setRequestXML = function (
   applicationId,
   protocol,
   environmentInfo,
   maximized,
   launchParam,
   multiSession,
   callbackParams,
   reverseToken,
   rdsLicenseInfo
) {
   let isWS1Mode = globalArray["ws1Mode"],
      urlHandler,
      applicationIdElement = util.createElement("application-id", applicationId),
      nameElement = util.createElement("name", protocol),
      protocolElement = util.createElement("protocol", nameElement),
      maximizedElement =
         typeof maximized === "boolean"
            ? util.createElement("maximized", maximized.toString())
            : util.createElement("maximized", "true"),
      environmentElement = "",
      infoElements = "",
      reverseConnectElement = null,
      samlElement = "",
      key,
      paramElements,
      multiSessionElement = util.createElement("multi-session", multiSession ? "true" : "false"),
      fileAssociationSubElement,
      fileAssociationElement,
      rdsLicenseElement = "",
      rdsLicenseInfoElements = "";
   this.itemId = applicationId;
   if (isWS1Mode) {
      urlHandler = util.getObject(globalArray, "url-handler");
      if (!!urlHandler && !!urlHandler.params && !!urlHandler.params.samlArt) {
         samlElement = util.createElement("saml-artifact", urlHandler.params.samlArt);
      }
   }

   // Generate the <environment-info> section if it was specified.
   if (typeof environmentInfo === "object") {
      for (key in environmentInfo) {
         if (environmentInfo.hasOwnProperty(key)) {
            infoElements += util.createElement("info", environmentInfo[key], {
               name: key
            });
         }
      }
      environmentElement = util.createElement("environment-information", infoElements);
   }

   if (reverseToken) {
      reverseConnectElement = util.createElement("reverse-connect-token", reverseToken);
   }

   // Generate the <rds-license> section if it was specified.
   if (rdsLicenseInfo) {
      for (key in rdsLicenseInfo) {
         if (rdsLicenseInfo.hasOwnProperty(key)) {
            rdsLicenseInfoElements += util.createElement(key, rdsLicenseInfo[key]);
         }
      }
      rdsLicenseElement = util.createElement("rds-license", rdsLicenseInfoElements);
   }

   paramElements = this.getParamElement(launchParam);

   this.requestXML =
      applicationIdElement +
      samlElement +
      maximizedElement +
      protocolElement +
      environmentElement +
      paramElements +
      multiSessionElement;

   if (reverseConnectElement) {
      this.requestXML += reverseConnectElement;
   }
   if (rdsLicenseElement) {
      this.requestXML += rdsLicenseElement;
   }

   this.callbackParams = callbackParams;
   this.cachedRequestInfo = {
      isApplication: true,
      id: applicationId,
      "origin-id": null,
      isShadow: false,
      name: ""
   };
};

/**
 * Set the preferences for getApplicationConnection.
 *
 * @param applicationPreferences [in] the application preferences JSON.
 */

GetApplicationConnectionHandler.prototype.setPreferences = function (applicationPreferences) {
   this.preferences = applicationPreferences;
   Logger.debug("App preferences is " + JSON.stringify(this.preferences));
   this.cachedRequestInfo.name = applicationPreferences.applicationName;
};

GetApplicationConnectionHandler.prototype.onUpdated = function () {
   this.decryptResult();
   this.redirectMsg = false;
   if (
      this.content["parsedResult"] &&
      this.content["parsedResult"]["result"] === "ok" &&
      this.content["parsedResult"]["redirect-settings"]
   ) {
      let protocolRedirectionController = util.getObject(globalArray, "protocol-redirect-controller");
      if (!protocolRedirectionController) {
         protocolRedirectionController = new ProtocolRedirectionController();
         globalArray["protocol-redirect-controller"] = protocolRedirectionController;
      }
      protocolRedirectionController
         .redirect("Application", this.content["parsedResult"], this.cachedRequestInfo)
         .then((fullResponse) => {
            this.content["parsedResult"] = fullResponse;
            this.redirectMsg = true;
            this._onUpdated();
         })
         .catch((errorObject) => {
            this.redirectMsg = true;
            this._onUpdated();
         });
   } else {
      this._onUpdated();
   }
};

/**
 * Create element for file association, the format like:
 *  <params>
 *    <param>
 *       <name>file-path</name>
 *       <values>
 *          <value>C:\Users\uesr01\Documents\test.docx</value>
 *          <value>C:\Users\uesr01\Documents\the_other_test.docx</value>
 *       </values>
 *    </param>
 *  </params>
 */

GetApplicationConnectionHandler.prototype.createFileAssociationElement = function () {
   let fileAssociationSubElement;
   for (let i = 0; i < globalArray["file-association"].length; i++) {
      fileAssociationSubElement = util.createElement("value", "\\\\tsclient\\" + globalArray["file-association"][i]);
   }
   const valuesEle = util.createElement("values", fileAssociationSubElement);
   const nameEle = util.createElement("name", "file-path");
   const param = util.createElement("param", nameEle + valuesEle);
   //clear globalArray["file-association"] to not impact next launch status
   globalArray["file-association"] = null;
   return util.createElement("params", param);
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetApplicationConnectionHandler.prototype._onUpdated = function () {
   let parsedResult,
      needConnectionText,
      protocolName,
      token,
      launchArgs,
      address,
      port,
      userMessage,
      showApplicationAction = {} as ShowApplicationAction,
      applicationResolution = {},
      content = {} as ShowApplicationAction["content"],
      router,
      id,
      sessionId,
      userName,
      domainName,
      enableUsb,
      usbTicket,
      enableMmr,
      rdsLicense,
      storeRedirectInfo,
      connectionRetryController = util.getObject(globalArray, "connection-retry-controller");

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   /**
    * Add triggerItemInfo for all launch
    * for bug 3161107 VCART-483
    */
   this.triggerItemInfo = {
      type: "HorizonApp",
      id: this.cachedRequestInfo.id,
      triggerName: this.preferences.applicationName
   };
   content.triggerItemInfo = this.triggerItemInfo;

   if (this.state === StateEnum.DONE) {
      if (this.content["parsedResult"]) {
         parsedResult = this.content["parsedResult"];
         needConnectionText = parsedResult["new-connection-needed"];

         if (needConnectionText === "true") {
            protocolName = parsedResult["protocol"];
            if (protocolName.toLowerCase() === "blast") {
               id = parsedResult["id"];
               sessionId = parsedResult["session-id"];
               userName = parsedResult["user-name"];
               domainName = parsedResult["domain-name"];
               address = parsedResult["address"];
               port = parsedResult["port"];
               token = parsedResult["token"];
               if (token === "") {
                  return;
               }
               if (token) {
                  try {
                     launchArgs = JSON.parse(token);
                  } catch (err) {
                     Logger.error("Parse blast token has been error: " + err);
                  }
                  if (!launchArgs) {
                     userMessage = util._("JSCDK_TOKEN_ERROR_JSON_FORMAT");
                     Logger.error("Blast token is not a valid JSON.");
                  } else if (!launchArgs["a"]) {
                     userMessage = util._("JSCDK_TOKEN_ERROR_JSON_PROPERTY");
                     Logger.error("Blast token has no 'a' property.");
                  }
                  if (userMessage) {
                     // push error to UI
                     Router.prototype.pushSelfDefinedError(this, userMessage);
                  }
               } else {
                  //throw an error
                  userMessage = util._("JSCDK_ERROR_BLAST_TOKEN_ERROR");
                  Logger.error("Blast token does not exist.");
                  Router.prototype.pushSelfDefinedError(this, userMessage);
               }
               enableUsb = parsedResult["enable-usb"];
               enableMmr = parsedResult["enable-mmr"];
               usbTicket = parsedResult["framework-channel-ticket"];
               storeRedirectInfo = parsedResult["protocol-redirect-recovery"] || true;
               if (parsedResult["rds-license"]) {
                  rdsLicense = parsedResult["rds-license"];
               }
               showApplicationAction.name = "ShowAppBlastApplication";
               content.id = id;
               content.sessionId = sessionId;
               content.userName = userName;
               content.domainName = domainName;
               content.url = this.createAppBlastLaunchApplicationAction(address, port, launchArgs);
               content.enableUsb = enableUsb;
               content.enableMmr = enableMmr;
               content.usbTicket = usbTicket;
               content.rdsLicense = rdsLicense;
               content.preferences = this.preferences;
               content.preferences.blastType = this.blastType;
               content.isApplicationSession = true;
               content.originId = this.originId;
               showApplicationAction.content = content;
               if (storeRedirectInfo) {
                  content.redirectSetting = {} as ProtocolRedirectSettings;
                  if (parsedResult["redirect-settings"]) {
                     content.redirectSetting.url = parsedResult["redirect-settings"]["url"];
                     content.redirectSetting.protocolToken =
                        parsedResult["redirect-settings"]["protocol-redirect-token"];
                     content.redirectSetting.tunnelToken = parsedResult["redirect-settings"]["tunnel-redirect-token"];
                  }
               }
            } else {
               // PCoIP or RDP just tells the UI about all of the parameters.
               showApplicationAction.name = "LaunchApplication";
               showApplicationAction.content = parsedResult;
            }
         } else {
            // no need to connect to a application that is currently connected
            showApplicationAction.name = "ApplicationAlreadyConnected";
            id = parsedResult["id"];
            sessionId = parsedResult["session-id"];
            content.id = id;
            content.sessionId = sessionId;
            showApplicationAction.content = content;
            //can throw an exception here instead if needed
         }
         router = globalArray["router"];
         // Common code for appblast or others.
         if (router) {
            showApplicationAction.content.brokerUrl = encodeURIComponent(router.brokerUrl);
         }

         connectionRetryController.onReconnectionDone("Reconnected");
         /**
          * parsed results of getApplicationConnection are of no use after this
          * function; reset data before UI refresh to avoid it triggered by
          * dependencies for multiple times reset data should be before
          * JSCDKSetUI to avoid unpredictable error during SetUI
          */
         this.content = {};
         showApplicationAction.content.callbackParams = this.callbackParams;
         JSCDKSetUI(JSON.stringify(showApplicationAction));
      }
   }
   // if the error is special for this handler, alert
   Router.prototype.pushErrorToUser(this);
};

/**
 * parse application connection information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */

GetApplicationConnectionHandler.prototype.parseResult = function (responseXML) {
   let brokerTag;
   let responses;
   let result;
   let tokenText;
   const applicationConnectionResponse = {};
   let xmlDoc;
   let listeners;
   let listenersNode;
   let listenerNode;
   let listener;
   let agentResponseNode;
   let agentResponse;
   let errorCode;
   let protocol;
   let protocolObj;
   let i;

   xmlDoc = $(responseXML);
   brokerTag = $(xmlDoc.children()[0]);
   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases
       */
      //make no check for the new-connection-needed property existence with
      // result set "ok", the absent of this property will cause response.name
      // = "ApplicationAlreadyConnected" in onUpdated(), if <id> is at least
      // provided
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         if (nameText === "redirect-settings") {
            applicationConnectionResponse["redirect-settings"] = util.parseXML($(this).children());
         } else {
            util.addItemForJson(applicationConnectionResponse, nameText, $(this).text());
         }
      });

      listenersNode = util.getChildNode($(responses)[0], "additional-listeners", 0);
      if (listenersNode) {
         listenerNode = listenersNode.getElementsByTagName("additional-listener");
      }
      if (listenerNode) {
         listeners = [];
         for (i = 0; i < listenerNode.length; i++) {
            listener = {};
            util.addItemForJson(listener, "name", listenerNode[i].getAttribute("name"));
            util.addItemForJson(listener, "value", $(listenerNode[i]).text());
            listeners[i] = listener;
         }

         util.addItemForJson(applicationConnectionResponse, "additional-listeners", listeners);
      }
      // handle token and default values here
      tokenText = applicationConnectionResponse["protocol-settings"];
      if (tokenText) {
         util.addItemForJson(applicationConnectionResponse, "token", tokenText);
      }
      return applicationConnectionResponse;
   } else if (result === "error") {
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(applicationConnectionResponse, nameText, $(this).text());
      });

      agentResponseNode = util.getChildNode($(responses)[0], "agent-response", 0);
      if (agentResponseNode) {
         agentResponse = {};
         errorCode = util.getChildNode(agentResponseNode, "error-code", 0);
         util.addItemForJson(agentResponse, "error-code", $(errorCode).text());

         protocol = util.getChildNode(agentResponseNode, "protocol", 0);
         if (protocol) {
            protocolObj = {};
            util.addItemForJson(protocolObj, "name", $(util.getChildNode(protocol, "name", 0)).text());
            util.addItemForJson(protocolObj, "error-code", $(util.getChildNode(protocol, "error-code", 0)).text());
            util.addItemForJson(agentResponse, "protocol", protocolObj);
         }

         util.addItemForJson(applicationConnectionResponse, "agent-response", agentResponse);
      }

      return applicationConnectionResponse;
   }
   return applicationConnectionResponse;
};

/**
 * Create the action to launch Blast application.
 *
 * @param host[in] the host for Blast.
 * @param port[in] the port for Blast.
 * @param args[in] the launch arguments for Blast.
 * @return launchUrl as a string for the application connection.
 */

GetApplicationConnectionHandler.prototype.createAppBlastLaunchApplicationAction = function (host, port, args) {
   /*
    * Construct appblast application url.
    * https://view.corp.com/r/r-token-value/?vauth=auth-token-value (BSG)
    * OR
    * https://view.corp.com/d/d-token-value/?vauth=auth-token-value (direct)
    */
   let r, launchUrl;

   if (args.r) {
      r = "/r/" + args.r;
   } else {
      r = "/d/" + getUuid();
   }
   launchUrl = ["https://", host, ":", port, encodeURI(r), "/?vauth=", encodeURIComponent(args.a)].join("");
   Logger.trace("Blast url generated");
   return launchUrl;
};

/**
 * Decrypt the XML-API protection data.
 */
GetApplicationConnectionHandler.prototype.decryptResult = function () {
   const parsedResult = this.content["parsedResult"];
   const cryptoKeyDerivationService = util.getObject(
      globalArray,
      "cryptoKeyDerivationService"
   ) as CryptoKeyDerivationService;

   if (!cryptoKeyDerivationService) {
      Logger.error("The CryptoKeyDerivationService missing.");
      return;
   }

   if (parsedResult && cryptoKeyDerivationService.isXmlApiDataProtected()) {
      // INTERNAL: only used by VMware client types.
      if (parsedResult["framework-channel-ticket"]) {
         parsedResult["framework-channel-ticket"] = cryptoKeyDerivationService.AESDecryptData(
            parsedResult["framework-channel-ticket"]
         );
      }
      if (parsedResult["password"]) {
         parsedResult["password"] = cryptoKeyDerivationService.AESDecryptData(parsedResult["password"]);
      }
      // Optional: used for protocol specific negotiation, such as the PCoIP token.
      if (parsedResult["protocol-settings"]) {
         parsedResult["protocol-settings"] = cryptoKeyDerivationService.AESDecryptData(
            parsedResult["protocol-settings"]
         );
      }
      // The field "token" and the field "protocol-settings" are the same value in "parseResult" function.
      if (parsedResult["token"]) {
         parsedResult["token"] = cryptoKeyDerivationService.AESDecryptData(parsedResult["token"]);
      }
      // Since 15.0, settings for protocol and tunnel connection redirection
      if (parsedResult["redirect-settings"]) {
         const redirectSettings = parsedResult["redirect-settings"];
         if (redirectSettings["protocol-redirect-token"]) {
            redirectSettings["protocol-redirect-token"] = cryptoKeyDerivationService.AESDecryptData(
               redirectSettings["protocol-redirect-token"]
            );
         }
         if (redirectSettings["tunnel-redirect-token"]) {
            redirectSettings["tunnel-redirect-token"] = cryptoKeyDerivationService.AESDecryptData(
               redirectSettings["tunnel-redirect-token"]
            );
         }
      }
   }
};
