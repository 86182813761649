/**
 * ******************************************************
 * Copyright (C) 2019-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * coordinate-converter.js -- coordinateConverter
 *
 * used to maintain the mouse coordination converting due to
 * high resolution and display scale
 *
 * wrapper based on normalizationService, to simplify the reference.
 *
 * not functional after entering Multimon mode, currently used for
 * seamless window mode only
 *
 *
 * Risk: Without caching original data, and use callback,
 * if Agent DPI is not the same with expected value, the app property will
 * be wrong, since first a few data are calculated based on target DPI instead of
 * real DPI.
 *
 * So we'd better avoid to apply changed High Resolution setting
 * after already connected to a app session.
 */

import Logger from "../../core/libs/logger";
import { Injectable } from "@angular/core";
import { CoordinatePoint, NormalizationService } from "./normalization-service";
import { clientUtil } from "@html-core";

export interface CoordinateRect {
   left: number;
   top: number;
   right: number;
   bottom: number;
}

@Injectable()
export class CoordinateConverter {
   private model: any = null;
   constructor(private normalizationService: NormalizationService) {}

   public init = (DPIEnable, agentDPI) => {
      const screenInfo: DisplayBaseInfo = {
         x: 0,
         y: 0,
         height: screen.height,
         width: clientUtil.getScreenWidth(),
         devicePixelRatio: window.devicePixelRatio
      };
      this.normalizationService.setDPISync(DPIEnable);
      this.normalizationService.setAgentDPI(agentDPI);
      this.normalizationService.setRawSetting("0", screenInfo);
      this.normalizationService.calculate();
      this.model = this.normalizationService.getNormalizationModel().get("0");
      Logger.info(
         "init coordinationConvertor with Agent DPI: " +
            agentDPI +
            ", DPI target: " +
            (DPIEnable ? '"DPI sync"' : '"High Resolution"')
      );
   };

   public onDPISyncSettingChanged = (DPIEnable) => {
      this.normalizationService.setDPISync(DPIEnable);
      this.recalculate("since DPI target updated to: " + (DPIEnable ? '"DPI sync"' : '"High Resolution"'));
   };

   public onAgentDPIChanged = (agentDPI) => {
      this.normalizationService.setAgentDPI(agentDPI);
      this.recalculate("since Agent DPI updated to:" + agentDPI);
   };

   public updateClientScreenInfo = () => {
      const screenInfo: DisplayBaseInfo = {
         x: 0,
         y: 0,
         height: screen.height,
         width: clientUtil.getScreenWidth(),
         devicePixelRatio: window.devicePixelRatio
      };
      this.normalizationService.setRawSetting("0", screenInfo);
      this.recalculate("since screen info updated to:" + JSON.stringify(screenInfo));
   };

   public recalculate = (updateReasonDescription) => {
      updateReasonDescription = updateReasonDescription || " for untracked reason";
      this.normalizationService.calculate();
      this.model = this.normalizationService.getNormalizationModel().get("0");
      Logger.info("update coordinationConvertor " + updateReasonDescription);
   };

   /**
    * @param  {Object} position Client event position in form of {x:int,y:int}
    * @return {Object} This returns remote event position in form of {x:int,y:int}
    */
   public normalize = (position) => {
      return this.normalizationService.normalize(position, this.model);
   };

   /**
    * @param  {Object} position Remote event position in form of {x:int,y:int}
    * @return {Object} This returns client event position in form of {x:int,y:int}
    */
   public revert = (position) => {
      return this.normalizationService.revert(position, this.model);
   };

   /**
    * @param  {object} rect Client rect in form {left,top,width,height}
    * @return {object}      This returns the remote session rect in form of  {left,top,width,height}
    */
   public normalizeRect = (rect) => {
      const leftUpPoint: CoordinatePoint = this.normalize({
         x: rect.left,
         y: rect.top
      });
      const rightBottomPoint: CoordinatePoint = this.normalize({
         x: rect.left + rect.width,
         y: rect.top + rect.height
      });

      return {
         left: leftUpPoint.x,
         top: leftUpPoint.y,
         width: Math.round(Math.max(0, rightBottomPoint.x - leftUpPoint.x)),
         height: Math.round(Math.max(0, rightBottomPoint.y - leftUpPoint.y))
      };
   };

   /**
    * @param  {object} rect Client point in form {left,top,bottom,right}
    * @return {object}      This returns the remote session rect in form of  {left,top,bottom,right}
    */
   public normalizePoint = (rect) => {
      const leftUpPoint: CoordinatePoint = this.normalize({
         x: rect.left,
         y: rect.top
      });
      const rightBottomPoint: CoordinatePoint = this.normalize({
         x: rect.right,
         y: rect.bottom
      });
      return {
         left: leftUpPoint.x,
         top: leftUpPoint.y,
         right: rightBottomPoint.x,
         bottom: rightBottomPoint.y
      };
   };

   /**
    * @param  {object} rect Remote Session rect in form {left,top,right,bottom}
    * @return {object}      This returns the client rect in form of {left,top,right,bottom}
    *
    * Do not use appid as key to maintain the origin data since after
    * DPI changed/resolution changed, the unity should resend all message
    */
   public revertRect = (rect) => {
      const leftUpPoint: CoordinatePoint = this.revert({
         x: rect.left,
         y: rect.top
      });
      const rightBottomPoint: CoordinatePoint = this.revert({
         x: rect.right,
         y: rect.bottom
      });
      return {
         left: leftUpPoint.x,
         top: leftUpPoint.y,
         right: rightBottomPoint.x,
         bottom: rightBottomPoint.y
      };
   };

   // position: {x:number,y:number}
   public scale = (position) => {
      return this.normalizationService.scale(position, this.model);
   };
}
