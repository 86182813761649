/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import $ from "jquery";
import { forOwn, fromPairs, get } from "lodash";
import { Logger } from "../../../core/libs";
import { SetUrlRedirectClientRuleAction as SetUrlRedirectClientRuleAction } from "../jscdk-interface";
import { JSCDKSetUI } from "../jscdkClient";
import { StateEnum } from "./messageHandler";
import { MessageHandlerV2 } from "./MessageHandlerV2";
import Router from "./router";

/**
 * Handle the get-feature-configurations XML-API service
 */
export class GetFeatureConfigurationsHandler extends MessageHandlerV2 {
   static readonly MESSAGE_NAME = "get-feature-configurations";
   static readonly MESSAGE_TEXT = "get-feature-configurations";
   static readonly RESPONSE_TAG = "feature-configurations";

   /**
    * Handle the url-filtering part of the response data
    */
   private urlFilteringHandler: GetFeatureConfigurationsHandler.UrlFilteringHandler;

   constructor() {
      super();
      this.messageName = GetFeatureConfigurationsHandler.MESSAGE_NAME;
      this.messageText = GetFeatureConfigurationsHandler.MESSAGE_TEXT;
      this.responseTag = GetFeatureConfigurationsHandler.RESPONSE_TAG;
      this.urlFilteringHandler = new GetFeatureConfigurationsHandler.UrlFilteringHandler();
   }

   public onUpdated() {
      super.onUpdated();

      if (this.state === StateEnum.DONE) {
         this.urlFilteringHandler.handleParsedResult(this.content);
      } else if (this.state === StateEnum.FAIL) {
         this.urlFilteringHandler.handleNoUrlFilteringInXML();
      }

      Router.prototype.pushErrorToUser(this);
   }

   public parseResult(responseXML: XMLDocument): any {
      const brokerTag = $(responseXML).children("broker");
      const featureConfigurationsTag = brokerTag.children(this.responseTag);
      if (!brokerTag.length || !featureConfigurationsTag.length) {
         Logger.error("response of GetFeatureConfigurations error.");
         return null;
      }

      const parsedResult: any = { features: {} };
      parsedResult.result = featureConfigurationsTag.children("result").text().trim();

      if (parsedResult.result === "ok") {
         parsedResult.features["url-filtering"] = this.urlFilteringHandler.parseResult(responseXML);
      } else if (parsedResult.result === "error") {
         parsedResult["error-code"] = featureConfigurationsTag.children("error-code").text();
         parsedResult["error-message"] = featureConfigurationsTag.children("error-message").text();
         parsedResult["user-message"] = featureConfigurationsTag.children("user-message").text();
      }

      return parsedResult;
   }
}

export namespace GetFeatureConfigurationsHandler {
   /**
    * Handle the url-filtering feature
    */
   export class UrlFilteringHandler {
      /**
       * The url-filtering data is the client rules of URL content redirection.
       * Synchronize those rules to browser extension.
       */
      public handleParsedResult(content: any) {
         const action = {
            name: "SetBrokerUrlRedirectionClientRule",
            content: { protocols: [] }
         } as SetUrlRedirectClientRuleAction;

         const urlFiltering = get(content, "parsedResult.features.['url-filtering']");
         if (urlFiltering) {
            action.content.enabled = urlFiltering.enabled === "true";
            // Join the agent/client rule URLs, use a semicolon as a separator
            const [agentRules, clientRules] = ["agent-urls", "client-urls"].map((propName) =>
               ((urlFiltering[propName] as string[]) || []).join(";")
            );
            if (agentRules || clientRules) {
               // Check if there are rules in the XML-API service's response
               forOwn(urlFiltering["protocols"] || {}, (remoteItems: string[], protocol) => {
                  (remoteItems || []).forEach((remoteItem) => {
                     action.content.protocols.push({ protocol, remoteItem, agentRules, clientRules });
                  });
               });
            }
         }

         if (action.content.protocols.length) {
            JSCDKSetUI(JSON.stringify(action));
         } else {
            // Synchronize rules from the google admin privacy when there is no rule in the XML-API service's response
            Logger.info("No client rule is available from the feature-configurations of response.");
            this.handleNoUrlFilteringInXML();
         }
      }

      /**
       * When no rule is available from the response,
       * Synchronize the client rules from the Google Admin Policy.
       */
      public handleNoUrlFilteringInXML() {
         const action = { name: "SyncPolicyUrlRedirectionClientRule" };
         JSCDKSetUI(JSON.stringify(action));
      }

      /**
       * Parse the url-filtering of features
       */
      public parseResult(responseXML: XMLDocument) {
         const featureTag = [...$(responseXML).find("features").children("feature")].find(
            (dom) => $(dom).children("name").text() === "url-filtering"
         );

         if (!featureTag) {
            return undefined;
         }

         const enabled = $(featureTag).children("enabled").text();
         const params = this.parseParamsTag($(featureTag).children("params"));
         return { enabled, ...params };
      }

      /**
       * Parse the params tag from url-filtering
       */
      private parseParamsTag(dom: HTMLElement | JQLite): any {
         const param = this.parseParamTags(dom);
         const paramList = this.parseParamListTags(dom);
         return { ...param, ...paramList };
      }

      /**
       * Parse the param tags from url-filtering
       */
      private parseParamTags(dom: HTMLElement | JQLite) {
         return fromPairs(
            [...$(dom).children("param")].map((dom) => {
               const name = $(dom).children("name").text();
               const values = this.parseValuesTag(dom);
               return [name, values];
            })
         );
      }

      /**
       * Parse the param-list tags from url-filtering
       */
      private parseParamListTags(dom: HTMLElement | JQLite) {
         return fromPairs(
            [...$(dom).children("param-list")].map((dom) => {
               const name = $(dom).children("name").text();
               const params = this.parseParamsTag(dom);
               return [name, params];
            })
         );
      }

      /**
       * Parse the values tag from url-filtering
       */
      private parseValuesTag(dom: HTMLElement | JQLite) {
         return [...$(dom).children("values").children("value")].map((dom) => $(dom).text());
      }
   }
}
