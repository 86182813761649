/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { HorizonAppImageService } from "../shared/common/service/horizon-app-image.service";
import { HorizonUserPrefAdaptor } from "../shared/common/service/horizon-user-pref-adapter.service";
import { RdsLicenseInfoService } from "../shared/common/service/rds-license-info.service";
import { RemoteImageService } from "../shared/common/service/remote-image.service";
import { UserGlobalPref } from "../shared/common/service/user-global-pref";
import { Ws1Service } from "../shared/common/service/ws1.service";
import { XmlApiService } from "../shared/desktop/sidebar/xml-api.service";
import { HorizonRdsLicenseInfoService } from "./common/service/horizon-rds-license-info.service";
import { HorizonWSOneService } from "./common/service/horizon-ws-one.service";
import { HorizonXmlApiService } from "./common/service/horizon-xml-api.service";
import { RebrandImage } from "../shared/common/service/rebrand-image.service";
import { HorizonRebrandImageService } from "./common/service/horizon-rebrand-image.service";
import { UserInfoService } from "../shared/common/service/user-info.service";
import { HorizonUserInfoService } from "../shared/common/service/horizon-user-info.service";

@NgModule({
   providers: [
      HorizonUserInfoService,
      {
         provide: XmlApiService,
         useExisting: HorizonXmlApiService
      },
      {
         provide: Ws1Service,
         useExisting: HorizonWSOneService
      },
      {
         provide: RemoteImageService,
         useExisting: HorizonAppImageService
      },
      {
         provide: UserGlobalPref,
         useExisting: HorizonUserPrefAdaptor
      },
      {
         provide: RdsLicenseInfoService,
         useExisting: HorizonRdsLicenseInfoService
      },
      {
         provide: RebrandImage,
         useExisting: HorizonRebrandImageService
      },
      {
         provide: UserInfoService,
         useExisting: HorizonUserInfoService
      }
   ]
})
export class Html5DynamicModule {}
