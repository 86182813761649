/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * PrintRd.js --
 *
 * PrintRdChannel
 *    Used to do PrintRd redirection over vvc channel. Consumers of this interface
 * should set the following optional listeners in the object after it is init.
 *   .onReady       Called when PrintRedirChannel is set.  Takes parameters:
 *       none
 *   .onPrinterMessage  called when PrintRedirChannel receive an redirection message. Takes
 *       data           printer redirection raw data in Uint8Array
 *
 */

import { PrintRdProtocol, PrinterTransStreamHdr, PrintMessage, PrintClientInfo } from "./print-redirection-protocol";
import { VDP_CONSTS, VDPService } from "../vdpservice";
import { Logger } from "@html-core";
import { PrePrintDataService } from "./pre-print-redirection.service";
import { printUtil } from "./print-util.service";

export interface IPrintRdChannelCB {
   onPrinterMessage(key: string, data: Uint8Array): void;
}

export class PrintRdChannel {
   public static readonly NAME_PREFIX = "RPC#PrintRedir#";
   public static readonly CONTROL_OBJ_NAME = "PrinterRedirControlObj";
   public static readonly DATA_OBJ_NAME = "PrinterRedirDataObj";
   public printerCheckTimer = null;
   private logger: Logger = new Logger(Logger.PRINTER_RD);

   PrintRdDataObject: any = null;
   PrintRdControlObject: any = null;
   vdpService: any = null;
   prePrintDataService: any = null;
   key: string = null;
   mainChannel: any = null;
   callback: IPrintRdChannelCB = null;
   printFile: Map<number, any>;
   metaData = {};

   constructor(
      vdp: VDPService,
      wmksKey: string,
      callback: IPrintRdChannelCB,
      prePrintDataService: PrePrintDataService
   ) {
      this.vdpService = vdp;
      this.key = wmksKey;
      this.callback = callback;
      this.prePrintDataService = prePrintDataService;
      this.printFile = new Map<number, any>();
      this.initialize();
   }

   private initialize = () => {
      const self: PrintRdChannel = this;
      if (!this.vdpService) {
         this.logger.error("PrintRdChannel Init failed: invalid vdpService instance");
         return;
      }
      this.vdpService.addChannelCreatedListener((channel) => {
         if (channel.name.indexOf(PrintRdChannel.NAME_PREFIX) === 0) {
            if (self.vdpService.connectChannel(channel)) {
               this.logger.info("Successfully accepted PrintRd main channel");
            } else {
               this.logger.error("Failed to open PrintRd main channel");
               return;
            }

            channel.enableArbitrarySideChannel(true);
            self.mainChannel = channel;

            channel.onReady = (object) => {
               if (object.name === PrintRdChannel.DATA_OBJ_NAME) {
                  self.PrintRdDataObject = object;
               } else if (object.name === PrintRdChannel.CONTROL_OBJ_NAME) {
                  self.PrintRdControlObject = object;
               }
            };

            channel.onDisconnect = (object) => {
               if (object) {
                  if (self.PrintRdControlObject && self.PrintRdControlObject.id === object.id) {
                     self.PrintRdControlObject = null;
                  } else if (self.PrintRdControlObject && self.PrintRdDataObject.id === object.id) {
                     self.PrintRdDataObject = null;
                  }
               } else {
                  self.PrintRdDataObject = null;
                  self.PrintRdControlObject = null;
               }
               // stop watching printer list timer
               if (this.printerCheckTimer) {
                  clearInterval(this.printerCheckTimer);
                  this.printerCheckTimer = null;
               }
            };

            channel.onInvoke = (rpc) => {
               self.handleRPCFromServer(rpc);
            };
         }
      });
   };

   private handleRPCFromServer = (rpc: any) => {
      if (
         (rpc.type === "POST" || rpc.type === "REQUEST") &&
         rpc.params.length === 1 &&
         rpc.params[0] instanceof Uint8Array
      ) {
         if (this.callback) {
            this.callback.onPrinterMessage(this.key, rpc.params[0]);
         }
      }
   };

   public sendPostMsgFastRPC(packet, onDone, onAbort) {
      let packetData;
      if (!this.mainChannel) {
         this.logger.error("RPC send failed: PrintRdChannel not initialized");
         onAbort();
         return;
      }

      if (typeof packet.getData === "function") {
         packetData = packet ? [packet.getData()] : [];
      } else {
         packetData = [packet.subarray(0, packet.length)];
      }

      return !!this.mainChannel.invoke({
         object: this.PrintRdDataObject,
         command: "VdpServiceMsgCmd",
         type: VDP_CONSTS.RPC_TYPE.REQUEST,
         params: packetData,
         onDone: onDone,
         onAbort: onAbort
      });
   }

   private setSupportVersion = (vPrinter) => {
      const version = vPrinter.tramsferTaskData.setting;
      const v1 = (version & 0xff000000) >> 24,
         v2 = (version & 0x00ff0000) >> 16,
         v3 = (version & 0x0000ff00) >> 8;
      const agentVersion = v1 + "." + v2 + "." + v3;
      Logger.info("agentVersion: " + agentVersion);
      if (version >= 0x03020000) {
         Logger.info("support new Chrome printer redireciton solution");
         printUtil.setSupportChromeNewPrinterVersion(true);
      } else {
         Logger.info("Use old printer redireciton solution");
         printUtil.setSupportChromeNewPrinterVersion(false);
      }
   };

   public handlePrinterRedirectionMessage = (vPrinter: PrintMessage, packet, clientInfo: PrintClientInfo, callback) => {
      if (vPrinter.pduHeader.type === PrintRdProtocol.PDU_TYPE.PDU_TYPE_TASK) {
         const reply: any = {};
         switch (vPrinter.transferTaskHeader.taskType) {
            case PrintRdProtocol.TASK_TYPE.TASK_TYPE_EXCHANGE_VERSION:
               this.setSupportVersion(vPrinter);
               return this.handleExchangeVersion(callback);
            case PrintRdProtocol.TASK_TYPE.TASK_TYPE_EXCHANGE_PLATFORM:
               return this.handleExchangePlatform(callback);
            case PrintRdProtocol.TASK_TYPE.TASK_TYPE_GET_CLIENT_INFO:
               return this.handleExchangeClientInfo(clientInfo, callback);
            case PrintRdProtocol.TASK_TYPE.TASK_TYPE_START_MONITOR:
               if (printUtil.isSupportChromeNewPrinter()) {
                  this.handleExchangePrinterList(callback);
               }
               return;
            case PrintRdProtocol.TASK_TYPE.TASK_TYPE_STOP_MONITOR:
               // stop watching printer list timer
               if (this.printerCheckTimer) {
                  clearInterval(this.printerCheckTimer);
                  this.printerCheckTimer = null;
               }
               return;
            case PrintRdProtocol.TASK_TYPE.TASK_TYPE_GET_GPO:
               // GPO name in agent: disable native printing for Chrome client
               // 1 disable disablePrinter
               // 0 enable disablePrinter
               this.logger.info(
                  "Printer redirection GPO setting: " +
                     (vPrinter.tramsferTaskData.setting === 0
                        ? "Disable  printer redirection"
                        : "Enable printer redirection")
               );
               break;
            case PrintRdProtocol.TASK_TYPE.TASK_TYPE_START_MSPDFPRINT:
               reply.isStartFlag = true;
               return callback(reply);
            default:
               this.logger.debug("unimplemented message" + vPrinter.transferTaskHeader.taskType);
         }
      } else if (vPrinter.pduHeader.type === PrintRdProtocol.PDU_TYPE.PDU_TYPE_STREAM) {
         return this.handlePrintDataInfo(vPrinter, packet, callback);
      } else {
         this.logger.debug("unsupported PDU type");
      }
   };

   private handleExchangeVersion = function (callback) {
      const reply = PrintRdProtocol.constructExchangeVersionReply();
      callback(reply);
   };

   private handleExchangePlatform = function (callback) {
      const reply = PrintRdProtocol.constructExchangePlatformReply();
      callback(reply);
   };

   private handleExchangeClientInfo = function (clientInfo, callback) {
      let reply;
      if (printUtil.isSupportChromeNewPrinter()) {
         const clientinfoMessage = this.prePrintDataService.constructExchangeClientInfoReply();
         reply = PrintRdProtocol.constructExchangeClientInfoReply(clientInfo, clientinfoMessage);
      } else {
         reply = PrintRdProtocol.constructExchangeClientInfoReply(clientInfo);
      }
      callback(reply);
   };

   private handleExchangePrinterList = function (callback) {
      // Change to 60s to reduce the call times for getPrinterInfo API.
      // The maximum number of times that getPrinterInfo can be called per minute is 20.
      const checkPrinterTime = 60 * 1000;
      this.prePrintDataService.getExchangePrinterList((message) => {
         for (let i = 0; i < message.length; i++) {
            const reply = PrintRdProtocol.constructExchangePrinterListReply(message[i].binaryPrinterInfo, "add");
            callback(reply);
         }
      });
      if (this.printerCheckTimer == null) {
         this.printerCheckTimer = setInterval(() => {
            this.prePrintDataService.checkPrinterList((message) => {
               if (!message.isChanged) {
                  this.logger.debug("There's no change in the printer list");
                  return;
               }
               const addList = message.add;
               for (let i = 0; i < addList.length; i++) {
                  const reply = PrintRdProtocol.constructExchangePrinterListReply(addList[i].binaryPrinterInfo, "add");
                  callback(reply);
               }
               const removeList = message.remove;
               for (let i = 0; i < removeList.length; i++) {
                  const reply = PrintRdProtocol.constructExchangePrinterListReply(
                     removeList[i].binaryPrinterInfo,
                     "remove"
                  );
                  callback(reply);
               }
            });
         }, checkPrinterTime);
      } else {
         this.logger.error("There's already a printer list timer.");
      }
   };

   private concatenate(arrays: any): any {
      let totalLength = 0;
      for (const arr of arrays) {
         totalLength += arr.length;
      }
      if (totalLength === 0) {
         this.logger.error("failed to concatenate arrays");
      }
      const result = new Uint8Array(totalLength);
      let offset = 0;
      for (const arr of arrays) {
         result.set(arr, offset);
         offset += arr.length;
      }
      return result;
   }

   private handlePrintDataInfo = (vPrinter: PrintMessage, packet, callback) => {
      const reply: any = {};
      const hdr: PrinterTransStreamHdr = vPrinter.transferStreamHdr;
      reply.isStreamDataReady = false;
      reply.isStreamData = true;
      switch (hdr.streamFlag) {
         case PrintRdProtocol.STREAM_FLAG.STREAM_FLAG_START:
            this.logger.info("PRINTJOB[" + hdr.jobId + "] Start transporting, total file size = " + hdr.dataSize);
            this.printFile.set(hdr.jobId, []);
            this.metaData = hdr.metaData;
            break;
         case PrintRdProtocol.STREAM_FLAG.STREAM_FLAG_DATA:
            this.logger.info("PRINTJOB[" + hdr.jobId + "] Received chunk#" + hdr.printerId);
            if (this.printFile.has(hdr.jobId)) {
               this.printFile.get(hdr.jobId)[hdr.printerId] = vPrinter.transferStreamData;
            } else {
               this.logger.info("PRINTJOB[" + hdr.jobId + "] was not started");
            }
            break;
         case PrintRdProtocol.STREAM_FLAG.STREAM_FLAG_END:
            this.logger.info("PRINTJOB[" + hdr.jobId + "] transport Done");
            if (this.printFile.has(hdr.jobId)) {
               reply.finalPdfFile = this.concatenate(this.printFile.get(hdr.jobId));
               reply.jobId = hdr.jobId;
            } else {
               this.logger.info("PRINTJOB[" + hdr.jobId + "] was not not paired");
            }
            reply.metaData = this.metaData;
            reply.isStreamDataReady = true;
            break;
         default:
            this.logger.debug("unsupported stream data type");
            break;
      }
      callback(reply);
   };
}
