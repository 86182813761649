/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { EventBusService, BusEvent } from "../../../../core/services/event";
import { Monitor } from "../../multimon/common/monitor-message";
import { DisplayService } from "./display.service";
import { DISPLAY } from "../../../common/service/monitor-info.service";
import { NormalizationService } from "../../../utils/normalization-service";
import { Logger } from "../../../../core/libs";
import { DisplayPresentationService } from "./display-presentation.service";
import { DisplayCheckService } from "../display-check.service";
import { FeatureConfigs } from "../../../common/model/feature-configs";
import { UserGlobalPref } from "../../../common/service/user-global-pref";

@Injectable()
export class DisplayWindowreplacementService extends DisplayService {
   private _screenDetails = null;
   private _extendedScreenCount = 0;
   private _url = "";
   private _windowMessageMap = new Map();
   private _openCount = 0;
   private _screens = [];
   private _screensJSONString = "[]";
   private _lastScreenPos = null;
   private _presentationApi = null;
   private _wmksSession = null;
   private _shouldSendScreenChangedMessage: boolean = false;

   public isSupportedPlatform = true;

   public constructor(
      private _eventBusService: EventBusService,
      private _userGlobalPref: UserGlobalPref,
      private _normalizationService: NormalizationService,
      private _displayCheckService: DisplayCheckService,
      private _featureConfigs: FeatureConfigs
   ) {
      super();

      // kill-switch
      const useWindowReplaceApi = this._featureConfigs.getConfig("KillSwitch-WindowReplacementApi");
      Logger.info("UseWindowReplacementApi is " + useWindowReplaceApi, Logger.DISPLAY);
      if (!useWindowReplaceApi) {
         this._presentationApi = new DisplayPresentationService();
         this.isSupportedPlatform = this._presentationApi.isSupportedPlatform;
         return;
      }

      // @ts-ignore
      navigator.permissions.query({ name: "window-placement" }).then((permission) => {
         this.windowReplacementPermissionStatus = permission.state;
         if (permission.state !== "granted") {
            permission.onchange = (e) => {
               this.windowReplacementPermissionStatus = permission.state;
               if (permission.state === "granted") {
                  this._eventBusService.dispatch(new BusEvent.GainWindowReplacementPermission());
               }
            };
         }
      });

      this._eventBusService
         .listen(BusEvent.WindowReplacementPermission.MSG_TYPE)
         .subscribe(this.checkPermissionAndExtraDisplay);
   }

   public getAndInitScreenDetails = () => {
      if (this._screenDetails) {
         return Promise.resolve(this._screenDetails);
      }
      // @ts-ignore
      return window
         .getScreenDetails()
         .then((screenDetails) => {
            Logger.info("Get screenDetails", Logger.DISPLAY);
            this.windowReplacementPermissionStatus = "granted";
            this._screenDetails = screenDetails;
            this._saveLastScreenPos(screenDetails.currentScreen);
            this._displayCheckService.setCurrentMonitorDPI(screenDetails.currentScreen.devicePixelRatio);
            this._extendedScreenCount = this._screenDetails.screens.length - 1;
            if (this._extendedScreenCount > 0) {
               this._computeScreens();
            }

            window.addEventListener("message", (evt) => {
               if (evt.data.uid) {
                  const extendedWindow = this._windowMessageMap.get(evt.data.uid);
                  if (extendedWindow) {
                     extendedWindow.onPostMessage(evt);
                  }
               }
            });

            this._screenDetails.addEventListener("currentscreenchange", () => {
               // when currentScreen changed
               if (!this.isCurrentScreen(this._screenDetails.currentScreen)) {
                  Logger.info("Current screen changed", Logger.DISPLAY);
                  this._computeScreens();
                  this._saveLastScreenPos(this._screenDetails.currentScreen);
               }
            });

            this._screenDetails.addEventListener("screenschange", () => {
               Logger.info("Screens changed", Logger.DISPLAY);
               const option = this._userGlobalPref.getPrefNumberItem("displaySetting");
               if (option === DISPLAY.DisplayOption.ALL) {
                  if (this.displayReady) {
                     this.close();
                  }
                  this._extendedScreenCount = this._screenDetails.screens.length - 1;
                  if (this._extendedScreenCount > 0) {
                     // workaround for google bug (https://bugs.chromium.org/p/chromium/issues/detail?id=1348850)
                     // 0.3s is the critical value
                     setTimeout(() => {
                        this._computeScreens();
                        this._sendScreenChangedMessage();
                     }, 1000);
                  } else {
                     this._setScreens([]);
                     this._sendScreenChangedMessage();
                  }
               }
            });

            return Promise.resolve(this._screenDetails);
         })
         .catch((e) => {
            Logger.info("Window.getScreenDetails failed", Logger.DISPLAY);
            return Promise.reject(e);
         });
   };

   private _sendScreenChangedMessage = () => {
      if (this._shouldSendScreenChangedMessage) {
         this._shouldSendScreenChangedMessage = false;
         Logger.info("Send screenChanged message", Logger.DISPLAY);
         this._eventBusService.dispatch(new BusEvent.ScreenChanged(this._wmksSession));
      }
   };

   public checkPermissionAndExtraDisplay = (wmksSession) => {
      if (this._screenDetails) {
         return;
      }
      this._wmksSession = wmksSession;
      this.getAndInitScreenDetails().catch((e) => {});
   };

   public getHasExtraDisplay = () => {
      if (this._presentationApi) {
         return this._presentationApi.hasExtraDisplay;
      }
      return this._extendedScreenCount > 0;
   };

   public getDisplayReady = () => {
      if (this._presentationApi) {
         return this._presentationApi.displayReady;
      }
      return this.displayReady;
   };

   public init = (url) => {
      this._url = url;
      if (this._presentationApi) {
         this._presentationApi.init(url);
      }
   };

   public getExtendedScreens = () => {
      this._initSelectedScreen();
      return this._screens.filter((v) => v.isSelected && !this._checkSameMonitor(v, this._screenDetails.currentScreen));
   };

   public getPrimaryScreen = () => {
      return {
         x: this._screenDetails.currentScreen.left,
         y: this._screenDetails.currentScreen.top,
         width: this._screenDetails.currentScreen.width,
         height: this._screenDetails.currentScreen.height,
         devicePixelRatio: this._screenDetails.currentScreen.devicePixelRatio
      };
   };

   public sendToDisplay = (data, window?) => {
      if (this._presentationApi) {
         return this._presentationApi.sendToDisplay(data);
      }
      if (window) {
         return window.postMessage(data);
      }
   };

   public onConnectionRejected = (e) => {
      this._presentationApi.onConnectionRejected(e);
   };

   public setExtendedMonitorEnterFullscreen = () => {
      for (const window of this._windowMessageMap.values()) {
         window.sendMessage(new Monitor.EnterFullscreen());
      }
   };

   public setExtendedMonitorClipboardChanged = (text, html) => {
      for (const window of this._windowMessageMap.values()) {
         window.sendMessage(new Monitor.CopyMsg({ text, html }));
      }
   };

   public entendDisplay = (extendedWindow?) => {
      if (this._presentationApi) {
         return this._presentationApi.entendDisplay();
      }
      Logger.info(
         "open extended monitor at (" +
            extendedWindow.screenSetting.left +
            ", " +
            extendedWindow.screenSetting.top +
            ")"
      );
      const w = window.open(
         this._url +
            "&uid=" +
            extendedWindow.id +
            "&left=" +
            extendedWindow.screenSetting.left +
            "&top=" +
            extendedWindow.screenSetting.top +
            "&dpi=" +
            extendedWindow.screenSetting.devicePixelRatio,
         "_blank",
         `left=${extendedWindow.screenSetting.left},top=${extendedWindow.screenSetting.top},width=${extendedWindow.screenSetting.width},height=${extendedWindow.screenSetting.height}`
      );
      this._windowMessageMap.set(extendedWindow.id, extendedWindow);
      extendedWindow.onDisplayConnected();
      if (++this._openCount >= this._extendedScreenCount) {
         this.displayReady = true;
      }
      return w;
   };

   public setConnection = (newConnection) => {
      this._presentationApi.setConnection(newConnection);
   };

   public close = () => {
      if (this._presentationApi) {
         this._presentationApi.close();
         return;
      }
      if (this._windowMessageMap.size > 0) {
         for (const [key, window] of this._windowMessageMap) {
            this._windowMessageMap.delete(key);
            window.sendMessage(new Monitor.ForceCloseMsg());
            window.clearHeartBeat();
            window.onDisplayDisconnected();
         }
         this.displayReady = false;
      }
   };

   public setSelectedMonitor = (displayOption, selectedMonitor) => {
      if (displayOption === DISPLAY.DisplayOption.ALL) {
         for (let i = 0; i < this._screens.length; i++) {
            this._screens[i].isSelected = true;
         }
      } else if (displayOption === DISPLAY.DisplayOption.SELECTED) {
         if (this._screens.length === 0) {
            return;
         }
         let selectedExtendedMonitorCount = 0;
         if (selectedMonitor !== null) {
            for (let i = 0; i < this._screens.length; i++) {
               this._screens[i].isSelected = false;
               // check if it's selected
               for (let j = 0; j < selectedMonitor.length; j++) {
                  if (!selectedMonitor[j].selected) {
                     continue;
                  }
                  // because there is no id to identify monitor, so we use label and position + width to identify it
                  // problem: label is not stable, because monitor's label may be changed when change monitors
                  if (this._checkSameMonitor(this._screens[i], selectedMonitor[j].settings)) {
                     selectedExtendedMonitorCount++;
                     this._screens[i].isSelected = true;
                     break;
                  }
               }
            }
         }
         this._extendedScreenCount = selectedExtendedMonitorCount;
      } else {
         for (let i = 1; i < this._screens.length; i++) {
            this._screens[i].isSelected = false;
         }
      }
      Logger.info("Multi screens are selected: " + JSON.stringify(this._screens), Logger.DISPLAY);
   };

   public addEventListener = (event, fn) => {
      if (this._presentationApi) {
         this._presentationApi.addEventListener(event, fn);
      }
   };

   public removeEventListener = (event, fn) => {
      if (this._presentationApi) {
         this._presentationApi.removeEventListener(event, fn);
      }
   };

   private _initSelectedScreen = () => {
      let option = this._userGlobalPref.getPrefNumberItem("displaySetting");
      let selectedMonitors = null;

      // some options are not supportive
      switch (option) {
         case DISPLAY.DisplayOption.SMALL_WINDOW:
         case DISPLAY.DisplayOption.LARGE_WINDOW:
         case DISPLAY.DisplayOption.CUSTOM_WINDOW:
            option = DISPLAY.DisplayOption.SINGLE;
            break;
         case DISPLAY.DisplayOption.SELECTED:
            try {
               selectedMonitors = JSON.parse(this._userGlobalPref.getPrefStringItem("selectedMonitors"));
            } catch (error) {
               Logger.error("Local storage data selectedMonitors is wrong", Logger.DISPLAY);
            }
            break;
      }

      if (!option) {
         option = DISPLAY.DisplayOption.ALL;
      }

      this.setSelectedMonitor(option, selectedMonitors);
   };

   private _applyShiftToScreen = (screens: Array<any>, i: number, shift: number, xShifts: Array<number>) => {
      /**
       * avoid dup shift or over shift for topology like
       * 1 2
       *     4
       *   3
       */
      const affectX = screens[i].left + screens[i].width;
      /**
       * always use initial connect topology, which would cause issue when loop present and unequal API shifting error happens,
       * which never observed so far
       * Change to adjusted connect topology if need enhancement
       */
      for (let j = 0; j < screens.length; j++) {
         if (screens[j].left === affectX) {
            if (xShifts[j] === 0) {
               xShifts[j] = shift;
            } else {
               xShifts[j] = Math.min(xShifts[j], shift);
            }
            this._applyShiftToScreen(screens, j, xShifts[j], xShifts);
         }
      }
   };

   private copyScreenDetails = (screen) => {
      return {
         devicePixelRatio: screen.devicePixelRatio,
         isExtended: screen.isExtended,
         isInternal: screen.isInternal,
         isPrimary: screen.isPrimary,
         label: screen.label,
         left: screen.left,
         top: screen.top,
         width: screen.width,
         height: screen.height,
         // for bug: https://bugzilla.eng.vmware.com/show_bug.cgi?id=3108211
         beforeModifiedWidth: screen.width,
         beforeModifiedLeft: screen.left
      };
   };
   /**
    * this workaround is not perfect and should be good enough, it can handle the API failure for topology for
    * 1234
    * or
    * 12
    * 34
    * or
    * 12
    * 3
    * or
    * 12
    *  3
    * but would suffers issue for some corner settings like some arrangement as below
    * 12
    *  34
    * or
    * 1
    *  2
    * 3
    *
    * Adjust monitors one by one, this might gives 1~3 pixel mouse offset, which should not be a seirous issue, since root cause lies in API
    */
   private _workaroundOversizeAPIIssue = (screens): Array<any> => {
      const monidifiedScreens = [];
      const workaroundTarget = 3840;
      const workaroundTolerant = 3;
      const xShifts = new Array<number>(screens.length).fill(0);
      for (let i = 0; i < screens.length; i++) {
         const monidifiedScreen = this.copyScreenDetails(screens[i]);
         const screenWidth = monidifiedScreen.width * monidifiedScreen.devicePixelRatio;
         let shift = 0;
         if (screenWidth > workaroundTarget && screenWidth <= workaroundTarget + workaroundTolerant) {
            // add workaround of 0.0001 to avoid floating error
            shift = Math.ceil((screenWidth - workaroundTarget) / screens[i].devicePixelRatio - 0.0001);
            monidifiedScreen.width -= shift;

            Logger.warning(
               "found API failure for monitor " +
                  i +
                  " with width as " +
                  screens[i].width +
                  " and DPI scale as " +
                  screens[i].devicePixelRatio +
                  "\nadjust width with -" +
                  shift
            );
            const xShiftsForI = new Array<number>(screens.length).fill(0);

            this._applyShiftToScreen(screens, i, shift, xShiftsForI);
            Logger.info("offset adjustment for screen " + i + " are " + JSON.stringify(xShiftsForI));

            /**
             * always use initial connect topology, thus could acumulate the shift for each API error.
             * which would cause issue when loop present and unequal API shifting error happens, which never observed so far
             */
            for (let j = 0; j < screens.length; j++) {
               xShifts[j] += xShiftsForI[j];
            }
         }
         monidifiedScreens[i] = monidifiedScreen;
      }
      for (let j = 0; j < screens.length; j++) {
         // if (xShifts[j]) {
         Logger.info("API warning: shift x of monitor " + j + " by offset " + xShifts[j]);
         // }
         monidifiedScreens[j].left -= xShifts[j];
      }
      Logger.info("modified screens are " + JSON.stringify(monidifiedScreens));

      return monidifiedScreens;
   };

   private _computeScreens = () => {
      const screens = [null];
      // workaround for API issue, check details in bug 2977384 and 2994736
      const modifiedScreens = this._workaroundOversizeAPIIssue(this._screenDetails.screens);
      modifiedScreens.forEach((screen) => {
         screen.isSelected = true;
         if (screen.baseKey) {
            delete screen.baseKey;
         }
         //re-order
         if (!this._checkSameMonitor(screen, this._screenDetails.currentScreen)) {
            screens.push(screen);
         } else {
            screens[0] = screen;
            this._eventBusService.dispatch(
               new BusEvent.OnPrimaryScreenAPIWorkaroundApplied({
                  x: screen.left,
                  y: screen.top,
                  height: screen.height,
                  width: screen.width,
                  devicePixelRatio: screen.devicePixelRatio
               })
            );
         }
      });
      this._setScreens(this._normalizationService.calculateBaseKey(screens));
   };

   private _setScreens = (screens) => {
      const screensJSONString = JSON.stringify(screens);
      if (this._screensJSONString !== screensJSONString) {
         this._screensJSONString = screensJSONString;
         this._shouldSendScreenChangedMessage = true;
         this._screens = screens;
         Logger.info("updated screens: " + screensJSONString, Logger.DISPLAY);
      }
   };

   private _saveLastScreenPos = (currentScreen) => {
      this._lastScreenPos = {
         left: currentScreen.left,
         top: currentScreen.top
      };
   };

   public isCurrentScreen = (currentScreen) => {
      return this._lastScreenPos.left === currentScreen.left && this._lastScreenPos.top === currentScreen.top;
   };

   // for bug: https://bugzilla.eng.vmware.com/show_bug.cgi?id=3108211
   private _checkSameMonitor = (monitor1, monitor2) => {
      const tmpScreen = {
         left: monitor1.beforeModifiedLeft,
         width: monitor1.beforeModifiedWidth,
         top: monitor1.top,
         height: monitor1.height,
         label: monitor1.label
      };
      return this._displayCheckService.isSameMonitor(tmpScreen, monitor2);
   };
}
