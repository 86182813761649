/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * AddClientInfoHandler.js --
 *
 *      Implementation of the message handler to add client info.
 */

import { MessageHandler } from "./messageHandler";
import util from "../util";
import Logger from "../../../core/libs/logger";
import { globalArray } from "../jscdkClient";
import $ from "jquery";

export default function AddClientInfoHandler() {
   let router;
   // member variables below
   this.messageName = "add-client-info";
   this.messageText = "add-client-info";
   this.responseTag = "addClientInfo";

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}
// inherits MessageHandler prototype
AddClientInfoHandler.prototype = new MessageHandler();
// constructor
AddClientInfoHandler.constructor = AddClientInfoHandler;

/**
 * Add the request XML for add-client-info.
 */
AddClientInfoHandler.prototype.setRequestXML = function () {
   let addClientStatsListElements = "",
      key,
      addClientCommonInfo = this.clientInfo.addClientCommonInfo,
      addClientStatsList = this.clientInfo.addClientStats,
      clientCommonInfo = this.clientInfo.clientCommonInfo,
      clientStatsList = this.clientInfo.clientStats;

   this.requestXML = "";

   for (key in addClientCommonInfo) {
      if (addClientCommonInfo.hasOwnProperty(key)) {
         this.requestXML += util.createElement(key, addClientCommonInfo[key]);
      }
   }
   // Generate the <client-stats> section.
   for (key in addClientStatsList) {
      if (addClientStatsList.hasOwnProperty(key)) {
         addClientStatsListElements += util.createElement(key, addClientStatsList[key], { scrub: "NONE" });
      }
   }

   for (key in clientCommonInfo) {
      if (clientCommonInfo.hasOwnProperty(key)) {
         addClientStatsListElements += util.createElement("stat", clientCommonInfo[key], { scrub: "NONE", name: key });
      }
   }

   for (key in clientStatsList) {
      if (clientStatsList.hasOwnProperty(key)) {
         addClientStatsListElements += util.createElement("stat", clientStatsList[key], { scrub: "NONE", name: key });
      }
   }

   this.requestXML += addClientStatsListElements;
};

/**
 * Parse client information from the response XML of GetConfiguration
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return
 *    null
 */
AddClientInfoHandler.prototype.parseResult = function (responseXML) {
   let xmlDoc = $(responseXML),
      ret = {},
      brokerElem,
      resultText;

   brokerElem = $(xmlDoc.children()[0]);
   if (!brokerElem) {
      Logger.error("response of " + this.getMessageName() + " error");
      return null;
   }

   resultText = brokerElem.find("result").text();
   if (!!resultText && resultText.trim() !== "") {
      ret["result"] = resultText;
   } else {
      return null;
   }
   return ret;
};
