/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

// Chrome Client & web client

import { Subject } from "rxjs";
import { Logger } from "../logger";
import { SDKRawEvent, SDKEvent} from "../event-helper/event-helper";
//import { Logger } from "../../../../webapp/webclient/core/libs/logger";

export type SDKReceivedRawRequest = {
   request: SDKRawEvent;
   sendResponse: (SDKRawEvent) => void;
   peerAddress: string;
};

export class EventExchangeBase {
   public message$ = new Subject<SDKReceivedRawRequest>();
   protected allowedPeerAddresss: Array<string> = [];
   protected getTrustOriginList: (string)=>Promise<Array<string>>;
   protected isTrustSyncMessage: (SDKRawEvent)=>boolean;
   protected cachedTrustList: Array<string>;

   constructor(allowedPeerAddresss: Array<string>, getTrustOriginList?: (string)=>Promise<Array<string>>, isTrustSyncMessage?:(SDKRawEvent)=>boolean) {
      this.allowedPeerAddresss = allowedPeerAddresss;
      this.getTrustOriginList = getTrustOriginList;
      this.isTrustSyncMessage = isTrustSyncMessage;
   }
   public sendMessage: (
      peerAddress: string,
      data: SDKRawEvent,
      portId?: string
   ) => Promise<SDKRawEvent>;

   public reset = () => {
      this.cachedTrustList = undefined;
   }

   public disconnectPort: (portId: string, reason?: string) => void;

   protected getOrigin = (url: string): string => {
      const protocolSplit = url.split("://");
      if (protocolSplit.length < 2) {
         Logger.error("ignore message from address with invalid format" + url);
         return "";
      }
      const protocol = protocolSplit[0];
      if (protocol !== "chrome-extension") {
         // could accept "https" for PWA
         Logger.error("ignore message from address with invalid protocol" + url);
         return "";
      }
      const address = protocolSplit[1];
      if (!address) {
         Logger.error("ignore message from address with invalid address" + url);
         return "";
      }
      const senderOrigin = address.split("#")[0].split("/")[0]; //include port

      return protocol + "://" + senderOrigin;
   };

   protected getAllowedPeerAddress = (origin, message): Promise<string> => {
      return new Promise((resolve, reject) => {
         let matchedAddress = "";
         if (this.allowedPeerAddresss !== null) {
            if (this.allowedPeerAddresss.indexOf(origin) !== -1) {
               matchedAddress = origin;
            }
            resolve(matchedAddress);
            // strict match for dynamic permission
         } else {
            if (this.isTrustSyncMessage(message)) {
               this.getTrustOriginList(origin)
                  .then((trustList) => {
                     Logger.info("use list of " + trustList);
                     this.cachedTrustList = trustList;
                     if (trustList && trustList.indexOf(origin) !== -1) {
                        matchedAddress = origin;
                     }
                     resolve(matchedAddress);
                  })
                  .catch(reject);
            } else {
               if (this.cachedTrustList && this.cachedTrustList.indexOf(origin) !== -1) {
                  Logger.info("check " + origin + " with cached trust list of" + JSON.stringify(this.cachedTrustList));
                  matchedAddress = origin;
               }
               resolve(matchedAddress);
            }
         }
      });
   };
}
