/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import { RemoteUsbDevice } from "../../usb-remote";
import { PathosisDevice } from "./pathosis-device";
import { ThermalPrinter } from "./thermal-printer";

/*
 * PathosisDevices
 * used to handle buggy devices not follow USB Standard.
 *
 */
export class PathosisDeviceFilter {
   public static check(vid: number, pid: number, remoteUsb: RemoteUsbDevice): PathosisDevice {
      if (clientUtil.isChromeClient()) {
         // Thermal Printer @ CITIZEN
         if (vid === 0x1d90 && pid === 0x20f4) {
            return new ThermalPrinter(remoteUsb);
         }
      }
      return null;
   }
}
