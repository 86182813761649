/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import * as CST from "@html-core";
import { ViewClientModel } from "../model/viewclient-model";

@Injectable({
   providedIn: "root"
})
export class AnonymousService {
   private anonymousMode: boolean = false;
   private anonymousModeChanged: boolean = false;
   // Create a BehaviorSubject with anonymousMode off
   private source$ = new BehaviorSubject(false);

   constructor(
      private localStorageService: CST.LocalStorageService,
      private viewClientModel: ViewClientModel
   ) {
      this.anonymousMode = this.localStorageService.getSession(CST.CLIENT.ANONYMOUS_MODE_KEY) === "true";
      this.viewClientModel.isAnonymousMode = this.anonymousMode;
   }

   public setMode = (mode: boolean) => {
      this.anonymousModeChanged = this.anonymousMode != mode;
      this.anonymousMode = mode;
      this.viewClientModel.isAnonymousMode = this.anonymousMode;
      this.syncMode();
   };

   public isAnonymousModeChanged = (): boolean => {
      return this.anonymousModeChanged;
   };

   public syncMode = () => {
      this.localStorageService.setSession(CST.CLIENT.ANONYMOUS_MODE_KEY, this.anonymousMode);
      this.source$.next(this.anonymousMode);
   };

   public getMode = (): boolean => {
      return this.anonymousMode;
   };

   public subscribe = (callback: (boolean) => void): Subscription => {
      return this.source$.subscribe({
         next: callback
      });
   };
}
