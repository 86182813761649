/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import * as CST from "@html-core";
import { Component } from "@angular/core";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { IdleSessionService } from "../../common/service/idle-session.service";

@Component({
   selector: "html5-idle",
   template: ""
})
export class IdleComponent {
   // Stores the idle timeout warning dialog, if open
   private idleTimeoutWarningDialogId = null;
   // Stores the idle timeout dialog, if open
   private idleTimeoutDialogId = null;

   constructor(
      private idleSessionService: IdleSessionService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private modalDialogService: ModalDialogService,
      private remoteSessionEventService: RemoteSessionEventService
   ) {
      // Start to set the interval to save system active time
      CST.clientUtil.setSystemActiveTime();

      this.idleSessionService.addEventListener("idleSessionTimeout", (unlockCallback) => {
         if (!this.htmlRemoteSessionManager.hasApplicationSession()) {
            return;
         }
         this.showIdleDialog(unlockCallback);
      });
      this.idleSessionService.addEventListener("appIdleTimeout", this.showIdleDialog);

      this.idleSessionService.addEventListener("idleSessionWarning", (refreshCallback) => {
         if (!this.htmlRemoteSessionManager.hasApplicationSession()) {
            return;
         }
         if (this.idleTimeoutWarningDialogId) {
            return;
         }
         this.idleTimeoutWarningDialogId = this.modalDialogService.showError({
            data: {
               titleKey: "dialog_title_about_to_timeout",
               contentKey: "dialog_confirm_about_to_timeout",
               buttonLabelConfirmKey: "OK"
            },
            callbacks: {
               confirm: () => {
                  if (refreshCallback instanceof Function) {
                     refreshCallback();
                  }
                  this.idleTimeoutWarningDialogId = null;
               }
            }
         });
      });

      this.remoteSessionEventService.addEventListener("sessionDisconnected", (session) => {
         if (this.modalDialogService.isDialogOpen(this.idleTimeoutWarningDialogId)) {
            setTimeout(() => {
               this.modalDialogService.close(this.idleTimeoutWarningDialogId);
            });
         }
      });
   }

   private showIdleDialog = (unlockCallback) => {
      if (this.modalDialogService.isDialogOpen(this.idleTimeoutWarningDialogId)) {
         this.modalDialogService.close(this.idleTimeoutWarningDialogId);
      }
      if (this.modalDialogService.isDialogOpen(this.idleTimeoutDialogId)) {
         return;
      }
      this.idleTimeoutDialogId = this.modalDialogService.showError({
         data: {
            titleKey: "dialog_title_locked",
            contentKey: "dialog_confirm_locked"
         }
      });
   };
}
