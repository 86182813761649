/**
 * ******************************************************
 * Copyright (C) 2016-2020, 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview MessageWaitManager.ts -- MessageWaitManager
 * Class contains the logic for device related messages, mainly works
 * as a interval control since device can't be operated too frequently,
 * and no extra cancel logic are added since driver in the agent should
 * do this.
 *
 * And we can't process start right after stop, or stop right after start,
 * since the browser init is async and some will cause unexpected errors.
 * and the 600ms is experimental interval value to safely process actions
 *
 * Although adding minimal processing interval for all messages are more safer,
 * and easy to implement, it look not needed and will decrease the user
 * experience somehow, so not use that design.
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";

@Injectable()
export class MessageWaitManager {
   private previousActions;
   private actionQueue;
   private actionWaitMap;
   private defaultWaitTime;
   private maxRecordDuration;
   private waitTimer;

   public getPreviousActions;
   public getActionQueue;
   public getMaxRecordDuration;
   public getWaitTimer;

   constructor() {
      this.init();
   }

   private init = () => {
      /**
       * @private
       * @type {Object}
       * The format is as: {
       *    previousActionName: {
       *       name: laterActionName,
       *       time: intervalTime
       *    },
       *    ...
       * }
       * and it means if previously we process the message of previousActionName,
       * we must at least wait intervalTime before processing later action of name
       * laterActionName.
       * Choose the same value as the highDelay in the Agent as 600.
       */
      this.actionWaitMap = {
         PMsgStart_V: {
            name: "PMsgStop_V",
            time: 600 //ms
         },
         PMsgStop_V: {
            name: "PMsgStart_V",
            time: 600 //ms
         },
         PMsgStart_A: {
            name: "PMsgStop_A",
            time: 600 //ms
         },
         PMsgStop_A: {
            name: "PMsgStart_A",
            time: 600 //ms
         }
      };
      this.defaultWaitTime = 0; //ms for any messages
      this.setMaxRecordDuration();
      this.reset();
   };

   private reset = () => {
      this.previousActions = [];
      this.actionQueue = [];
      this.waitTimer = null;
   };

   /**
    * it should be >= max(actionWaitMap[k].time, defaultWaitTime), for all valid k
    */
   private setMaxRecordDuration = () => {
      let key;

      this.maxRecordDuration = this.defaultWaitTime;
      for (key in this.actionWaitMap) {
         if (this.actionWaitMap.hasOwnProperty(key)) {
            this.maxRecordDuration = Math.max(this.maxRecordDuration, this.actionWaitMap[key].time);
         }
      }
   };

   private getNow = () => {
      return new Date().getTime();
   };

   /**
    * clear outdated record in previousActions, which is sorted by time order
    */
   private updateBufferedActions = () => {
      let i,
         oldestRecordTime = this.getNow() - this.maxRecordDuration,
         clipIndex = -1;

      for (i = 0; i < this.previousActions.length; i++) {
         if (this.previousActions[i].time < oldestRecordTime) {
            clipIndex = i;
         } else {
            break;
         }
      }
      if (clipIndex >= 0) {
         this.previousActions.splice(0, clipIndex + 1);
      }
   };

   /**
    * get wait time for a specified action
    */
   private getWaitTimeForAction = (messageType, currentTime, previousAction) => {
      let alreadyWaitTime, actionWaitTime, waitInfo;

      if (!this.actionWaitMap.hasOwnProperty(previousAction.name)) {
         return 0;
      }
      waitInfo = this.actionWaitMap[previousAction.name];
      if (waitInfo.name !== messageType) {
         return 0;
      }
      actionWaitTime = waitInfo.time;
      alreadyWaitTime = currentTime - previousAction.time;
      return Math.max(0, actionWaitTime - alreadyWaitTime);
   };

   /**
    * get wait time for default action, which apply to all messages
    */
   private getDefaultActionWaitTime = (currentTime) => {
      let alreadyWaitTime;

      if (this.previousActions.length === 0) {
         return 0;
      }
      alreadyWaitTime = currentTime - this.previousActions[this.previousActions.length - 1].time;

      return Math.max(0, this.defaultWaitTime - alreadyWaitTime);
   };

   /**
    * get overall wait time
    */
   private getWaitTime = (messageType) => {
      let i,
         currentTime = this.getNow(),
         waitTime = this.getDefaultActionWaitTime(currentTime);

      for (i = 0; i < this.previousActions.length; i++) {
         waitTime = Math.max(waitTime, this.getWaitTimeForAction(messageType, currentTime, this.previousActions[i]));
      }
      return waitTime;
   };

   private processQueue = () => {
      let nextAction;
      if (this.actionQueue.length === 0) {
         return;
      }
      nextAction = this.actionQueue[0];
      if (this.waitTimer !== null) {
         Logger.trace("there is a message waiting, wait that to be processed first", Logger.RTAV);
         return;
      }

      Logger.trace("start timer for " + nextAction.messageType, Logger.RTAV);
      this.waitTimer = setTimeout(() => {
         this.actionQueue.shift();
         this.waitTimer = null;
         this.onProcess(nextAction.messageType);
         nextAction.processingFunction();
         this.processQueue();
      }, this.getWaitTime(nextAction.messageType));
   };

   private canProcess = (messageType) => {
      return this.getWaitTime(messageType) === 0 && this.actionQueue.length === 0;
   };

   private onProcess = (messageType) => {
      Logger.debug("start executing message " + messageType, Logger.RTAV);
      this.updateBufferedActions();
      this.previousActions.push({
         name: messageType,
         time: this.getNow()
      });
   };

   private onWait = (messageType, processingFunction) => {
      this.actionQueue.push({
         messageType: messageType,
         processingFunction: processingFunction
      });
      this.processQueue();
   };

   /**
    * This function will run the processingFunction as the order they passed
    * in, and will keep them apart from each other to leave enough interval
    * as defined in the actionWaitMap
    * @param {string} messageType The action Name
    * @param {funciton} processingFunction The function for the action
    */
   public process = (messageType, processingFunction) => {
      if (this.canProcess(messageType)) {
         this.onProcess(messageType);
         processingFunction();
      } else {
         this.onWait(messageType, processingFunction);
         Logger.debug("waiting for message " + messageType, Logger.RTAV);
      }
   };

   /**
    * This is for UT only, and should not be used by program.
    * @type {Object}
    */
   public APIforUT = () => {
      (this.getPreviousActions = () => {
         return this.previousActions;
      }),
         (this.getActionQueue = () => {
            return this.actionQueue;
         }),
         (this.getMaxRecordDuration = () => {
            return this.maxRecordDuration;
         }),
         (this.getWaitTimer = () => {
            return this.waitTimer;
         });
   };
}
