/**
 * ******************************************************
 * Copyright (C) 2016-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * RestartDesktopCommand.js --
 *
 * Restart desktop.
 *
 */

import { globalArray } from "../jscdkClient";
import RestartDesktopHandler from "../controllers/restartDesktopHandler";
import { MessageHandler } from "../controllers/messageHandler";

export default function RestartDesktopCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param RestartDesktopAction[in]
 *        - its property 'method' has the value 'RestartDesktop'
 */
RestartDesktopCommand.prototype.execute = function (RestartDesktopAction) {
   let handlerList,
      desktopId,
      RestartDesktopObject,
      router = globalArray["router"];

   desktopId = RestartDesktopAction.desktopId;
   RestartDesktopObject = router.getHandler("restart-desktop", desktopId);

   if (!RestartDesktopObject) {
      RestartDesktopObject = new RestartDesktopHandler();
      globalArray[RestartDesktopObject.messageName + RestartDesktopObject.requestId] = RestartDesktopObject;
      globalArray[RestartDesktopObject.responseTag + RestartDesktopObject.requestId] = RestartDesktopObject;
      /**
       * update MessageHandler.prototype.requestIdKV
       * the format is {responseTag1+desktop-id1 : requestId1,
       * responseTag2+desktop-id2 : requestId2} such as key of
       * RestartDesktopHandler is "Restart-desktop"+desktopId
       */
      MessageHandler.prototype.requestIdKV[RestartDesktopObject.responseTag + desktopId] =
         RestartDesktopObject.requestId;
   } else {
      RestartDesktopObject.RestartData();
   }

   if (desktopId) {
      RestartDesktopObject.setRequestXML(desktopId);
      handlerList = RestartDesktopObject.composeHandlerList();

      router.postMessage(handlerList);
   }
};
