/**
 * ******************************************************
 * Copyright (C) 2014-2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * doLockHandler.js --
 *
 *      Implementation of the message handler to send doLock.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function DoLockHandler() {
   // member variables below
   this.messageName = "do-lock";
   this.messageText = "do-lock";
   this.responseTag = "lock";

   // register dependencies here
   const router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
DoLockHandler.prototype = new MessageHandler();
DoLockHandler.constructor = DoLockHandler;

DoLockHandler.prototype.setRequestXML = function () {
   this.requestXML = "";
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
DoLockHandler.prototype.onUpdated = function () {
   let doLockAction,
      router = util.getObject(globalArray, "router"),
      idleTimeoutTimerController = util.getObject(globalArray, "idle-timeout-timer");

   if (!idleTimeoutTimerController) {
      Logger.error("do lock should only be triggered by the idleTimeoutTimerController");
      return;
   }

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
      Logger.debug("doLock result is OK.");
      if (idleTimeoutTimerController.needDisplayDialogs) {
         doLockAction = { name: "DoLock", result: "ok" };
         JSCDKSetUI(JSON.stringify(doLockAction));
      } else if (idleTimeoutTimerController.notifyLockEventToUI) {
         JSCDKSetUI(JSON.stringify({ name: "AlreadyLock" }));
      }
   } else {
      Logger.error("doLock failed.");
   }

   // push error to UI
   router.pushErrorToUser(this);
};

/**
 * parse locale information from the response XML of GetConfiguration
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return
 *    null
 */
DoLockHandler.prototype.parseResult = function (responseXML) {
   let responseBody,
      brokerTag,
      responses,
      resultText,
      doLockResponse = {},
      errorCodeElem,
      errorMessageElem,
      userMessageElem;

   if (!responseXML || responseXML === "") {
      return null;
   }
   responseBody = $(responseXML);
   brokerTag = $(responseBody.children()[0]);
   if (!brokerTag) {
      Logger.error("response of getAuthenticationStatus error");
      return null;
   }
   responses = brokerTag.children(this.responseTag);
   resultText = responses.children("result").text();
   if (!!resultText && resultText.trim() !== "") {
      doLockResponse["result"] = resultText;
   } else {
      Logger.error("response of getAuthenticationStatus error, no valid result tag");
      return null;
   }
   if (resultText === "error") {
      errorCodeElem = responseBody.find("error-code");
      doLockResponse["error-code"] = errorCodeElem.text();
      errorMessageElem = responseBody.find("error-message");
      doLockResponse["error-message"] = errorMessageElem.text();
      userMessageElem = responseBody.find("user-message");
      doLockResponse["user-message"] = userMessageElem.text();
   }
   return doLockResponse;
};
