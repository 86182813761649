/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil, TranslateService } from "@html-core";
import { ViewClientModel } from "../../../common/model/viewclient-model";
import { Component } from "@angular/core";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { Localize } from "../../../common/service/localize.service";
import { Subscription } from "rxjs";
@Component({
   selector: "advanced-set",
   templateUrl: "./advanced-setting.component.html"
})
export class AdvancedSettingComponent {
   public isChromeClient: boolean;
   public hideUI: boolean;
   public advancedButtonTitle: string;
   public advancedButtonName: string;
   private _localizationDone: Subscription;
   constructor(
      private viewClientModel: ViewClientModel,
      private modalDialogService: ModalDialogService,
      private translate: TranslateService,
      private localize: Localize
   ) {
      this.isChromeClient = clientUtil.isChromeClient();
   }

   ngOnInit(): void {
      this.hideUI = this.viewClientModel.disableCEIP && !this.isChromeClient;
      this.advancedButtonTitle = this.translate._T("ADVANCED_SETTINGS");
      this.advancedButtonName = this.translate._T("ADVANCED_SETTINGS_BUTTON");
      // need to reset the title to handle the case of localization not inited.
      this._localizationDone = this.localize.localeReady$.subscribe((ready) => {
         if (ready) {
            this.advancedButtonTitle = this.translate._T("ADVANCED_SETTINGS");
            this.advancedButtonName = this.translate._T("ADVANCED_SETTINGS_BUTTON");
         }
      });
      $("#preSettingsBtn").tooltip();
   }

   ngOnDestroy(): void {
      if (this._localizationDone) {
         this._localizationDone.unsubscribe();
      }
   }

   public showPreSetting = () => {
      this.modalDialogService.showPreSetting();
   };

   public autoBlur = (ev) => {
      ev.preventDefault();
   };
}
