/**
 * ******************************************************
 * Copyright (C) 2012-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * router.js --
 *
 *      Implementation of the router for posting to the view broker and parsing
 * the response.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import JSCDKBrokerError from "../model/jscdk-broker-error.consts";
import { prepareForAlreadyAuthenticated } from "../timer/timer-util";
import { ContextInfo, SuccessData, SubError, ErrorAction } from "../jscdk-interface";

import CsrfTokenService from "../model/csrf-token.service";
import { clientUtil } from "@html-core";

export default function Router() {
   // member variables
   this.name = "router";
   this.messageName = "Router";
   this.doLogout = "doLogout"; // attribute "doLogout" to be registered
   this.doCancelAuthentication = "doCancelAuthentication";
   this.receiver = "receiver"; // attribute "receiver" to be registered
   this.observerList = [];
   this.brokerUrl = "";
   this.brokerUrlWithTimestamp = false;
   this.content = {}; // data generated (key-value) by router, such as error,
   // etc.
   this.recentXhrObject = {}; // Recent XHR object for AJAX request.
}

Router.prototype.ajaxWasAborted = false;

Router.prototype.setDoLogout = function () {
   // traverse the observerList of attribute "doLogout", and call each
   // handler's onBroadcast()
   let key, handler;
   if (this.observerList[this.doLogout]) {
      for (key in this.observerList[this.doLogout]) {
         if (this.observerList[this.doLogout].hasOwnProperty(key)) {
            handler = this.observerList[this.doLogout][key];
            handler.onBroadcast(this.doLogout); // onBroadcast callback here
         }
      }
   }
};

Router.prototype.setDoCancelAuthentication = function () {
   /*
    * Traverse the observerList of attribute "doCancelAuthentication", and call
    * each handler's onBroadcast().
    */
   let key;
   let handler;
   if (this.observerList[this.doCancelAuthentication]) {
      for (key in this.observerList[this.doCancelAuthentication]) {
         if (this.observerList[this.doCancelAuthentication].hasOwnProperty(key)) {
            handler = this.observerList[this.doCancelAuthentication][key];
            handler.onBroadcast(this.doCancelAuthentication); // onBroadcast
            // callback
            // here
         }
      }
   }
};

/**
 * Set the request URL of the broker and specify
 * whether timestamp is needed to appended to each request.
 *
 * Appending a timestamp to the URL is to fix a iOS6 Ajax cache problem.
 *
 * @param brokerUrl [in] The URL address of the broker
 */
Router.prototype.setBrokerUrl = function (brokerUrl) {
   this.brokerUrl = brokerUrl;

   // only enable in DEV MODE
   // this.brokerUrl = "https://localhost/portal/broker/xml";

   // For iOS6 system, a timestamp is appended to each post request, so that
   // browser doesn't cache the response.
   this.brokerUrlWithTimestamp = !!navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/i);
};

/**
 * Replace the secret field's value when logging get-desktop-connection response
 *
 * @param message [in] XML response from broker.
 * @return the censored string, or "CENSORING FAILED" when translation fails.
 */

Router.prototype.censorResponse = function (message) {
   try {
      let censoredMessage = message,
         secretParams = ["framework-channel-ticket", "password", "token", "framework-channel-certificate-thumbprint"],
         xmlDoc = $.parseXML(censoredMessage),
         $xml = $(xmlDoc),
         i,
         secretNode,
         secretKey;
      secretNode = $xml.find("desktop-connection");
      secretNode = Array.prototype.slice.call(secretNode);
      for (i = 0; i < secretParams.length; i++) {
         secretKey = secretParams[i];
         if (
            typeof $(secretNode).find(secretKey).text() !== "undefined" &&
            $(secretNode).find(secretKey).text() !== "" &&
            $(secretNode).find(secretKey).text() !== null
         ) {
            $(secretNode).find(secretKey).text("[REDACTED]");
         }
      }

      return util.XMLToString(xmlDoc);
   } catch (e) {
      // Parse document fail
      return "CENSORING FAILED";
   }
};

/**
 * Get the query parameter value
 * @param url the URL
 * @param name the parameter name
 * @returns the parameter value if the url has the parameter.  Otherwise,
 * return null
 */
Router.prototype.getParameterByName = function (url, name) {
   let param, regex, results;

   param = name.replace(/[[]]/g, "\\$&");
   regex = new RegExp("[?&]" + param + "(=([^&#]*)|&|#|$)");
   results = regex.exec(url);
   if (!results) {
      return null;
   }
   if (!results[2]) {
      return "";
   }
   return decodeURIComponent(results[2].replace(/\+/g, " "));
};

Router.prototype.postMessageWithDSpec = function (handlerList, dspec: DSpecToken) {
   const contextInfo = {} as ContextInfo;
   const postMessageText = this.composeMessages(handlerList);
   if (postMessageText) {
      for (let i = 0; i < handlerList.length; ++i) {
         const handler = handlerList[i];
         handler.setState(StateEnum.PENDING);
      }
   }
   contextInfo.messageText = postMessageText;
   contextInfo.requestId = handlerList[0].requestId;
   contextInfo.requestHandlerList = handlerList;

   const xhrObject = this.jscdkPost(
      `${dspec.brokerUrl}/broker/xml`,
      contextInfo,
      this.jscdkSuccess,
      true,
      undefined,
      undefined,
      undefined,
      dspec
   );

   this.recentXhrObject = xhrObject;
   for (let i = 0; i < handlerList.length; i++) {
      const handler = handlerList[i];
      handler.setXhrObject(xhrObject);
   }
   Logger.trace(util.censorMessage(postMessageText));
};
/**
 * post messages using XHR here, if response is ready, callback
 * onResponse() will be called
 *
 * @param handlerList[in] handlerList is list of handlers who can be sent
 *    together in a single message
 */
Router.prototype.postMessage = function (handlerList, async, timeout, skipNetworkError, sendFetch) {
   let postMessageText = this.composeMessages(handlerList),
      handler,
      i,
      contextInfo = {} as ContextInfo,
      xhrObject,
      brokerUrl,
      connector;

   if (this.brokerUrlWithTimestamp) {
      connector = this.getParameterByName(this.brokerUrl, "mid") ? "&" : "?";
      brokerUrl = this.brokerUrl + connector + "_ab=" + $.now();
   } else {
      brokerUrl = this.brokerUrl;
   }

   if (postMessageText) {
      for (i = 0; i < handlerList.length; ++i) {
         handler = handlerList[i];
         handler.setState(StateEnum.PENDING);
      }
   }

   contextInfo.messageText = postMessageText;
   contextInfo.requestId = handlerList[0].requestId;
   contextInfo.requestHandlerList = handlerList;
   if (async !== undefined && !!timeout) {
      xhrObject = this.jscdkPost(
         brokerUrl,
         contextInfo,
         this.jscdkSuccess,
         async,
         timeout,
         skipNetworkError,
         sendFetch
      );
   } else if (async !== undefined) {
      xhrObject = this.jscdkPost(
         brokerUrl,
         contextInfo,
         this.jscdkSuccess,
         async,
         undefined,
         skipNetworkError,
         sendFetch
      );
   } else {
      xhrObject = this.jscdkPost(
         brokerUrl,
         contextInfo,
         this.jscdkSuccess,
         true,
         undefined,
         skipNetworkError,
         sendFetch
      );
   }
   this.recentXhrObject = xhrObject;
   for (i = 0; i < handlerList.length; i++) {
      handler = handlerList[i];
      handler.setXhrObject(xhrObject);
   }
   Logger.trace(util.censorMessage(postMessageText));
};

/**
 * compose all messages of handlers in handlerList to a single message
 *
 * @param handlerList[in] handlerList is list of handlers who can be sent
 *    together in a single message
 * @return the single message composed
 */
Router.prototype.composeMessages = function (handlerList) {
   let composedMessage = "";
   let brokerVersion = "";
   if (globalArray) {
      brokerVersion = globalArray["brokerVersion"];
   }
   composedMessage = util.createXML(handlerList, brokerVersion);
   return composedMessage;
};

/**
 * when router receives messages, it will notify message handlers who are
 * registered to it and own these responses.
 *
 * @param responseText [in] responseText to be handled and distributed to
 *    corresponding handlers
 */
Router.prototype.onResponse = function (responseData) {
   let i,
      j,
      handler,
      requestId = responseData.requestId,
      responseText = responseData.data,
      requestHandlerList = responseData.requestHandlerList,
      requestHandler,
      responseTag,
      foundHandler = false;

   Logger.trace(this.censorResponse(util.XMLToString(responseText)));
   const responseTagList = this.parseResponseTagList(responseText);
   for (i = 0; i < responseTagList.length; ++i) {
      responseTag = responseTagList[i];
      // get the request handler
      for (j = 0; j < requestHandlerList.length; j++) {
         requestHandler = requestHandlerList[j];
         if (requestHandler.responseTag === responseTag) {
            // The handler's responseTag equals that of the XML response.
            handler = requestHandler;
            break;
         }
      }
      if (!handler) {
         // if a handler is not allow to have multiple instances, just use
         // responseTag with no suffix to locate this handler in global array
         handler = util.getObject(globalArray, responseTag);
      }
      if (!handler) {
         // for handlers who can have multiple instances
         // locate this handler by responseTag with requestId suffixed
         handler = util.getObject(globalArray, responseTag + requestId);
      }
      if (handler) {
         this.pushResponseToHandler(handler, responseText);
         foundHandler = true;
      }
   }
   if (!foundHandler) {
      requestHandler = requestHandlerList[0];
      if (requestHandler) {
         this.pushResponseToHandler(requestHandler, responseText);
      } else {
         Logger.error("Unable to locate any handler for response.");
      }
   }
   /*
    * Don't clear the handler here because if we do, canceling a broker request and
    * canceling authentication at about the same time will not be handled correctly.
    */
};

/**
 * handles a response from the broker by pushing the response text to the
 * specified handler.
 *
 * @param  handler [in] the handler to be updated with the response
 * @param  responseText [in] the parsed response text to send to the handler.
 */
Router.prototype.pushResponseToHandler = function (handler, responseText) {
   let parsedResult,
      content = {};

   parsedResult = handler.parseResult(responseText);
   if (parsedResult && parsedResult["result"]) {
      Logger.debug(handler.messageName + " result: " + parsedResult["result"]);
   }
   content["parsedResult"] = parsedResult;
   handler.appendContent(content); // store key-value information(state, etc.)
   // in relative handler's "content"
   handler.onUpdated(); // handlers' onUpdated callback here
};

/**
 * add a new observer into observerList
 *
 * @param  inputHandler [in] the observer handler to be added
 * @param  attributeName [in] name of the attribute whose observerList is
 *    handled
 */
Router.prototype.addObserver = function (inputHandler, attributeName) {
   if (this.observerList[attributeName] !== null) {
      if (typeof this.observerList[attributeName] === "undefined") {
         this.observerList[attributeName] = [];
      }
      this.observerList[attributeName].push(inputHandler);
   }
};

/**
 * remove a specific observer from observerList
 *
 * @param  inputHandler [in] the observer handler to be removed
 * @param  attributeName [in] name of the attribute whose observerList is
 *    handled
 */
Router.prototype.removeObserver = function (inputHandler, attributeName) {
   let index;
   const list = this.observerList[attributeName];
   if (list) {
      index = list.indexOf(inputHandler);
      if (index > -1) {
         list.splice(index, 1);
      }
   }
};

/**
 * parse response tags from the response XML
 *
 * @param responseText[in]  response XML of http request
 * @return a list containing all response tags
 */

Router.prototype.parseResponseTagList = function (responseText) {
   if (!responseText || responseText === "") {
      return [];
   }
   const responseTagList = [];
   const responseBody = $(responseText);
   const brokerElem = responseBody.find("broker");
   if (!brokerElem || !brokerElem.length) {
      Logger.error("parse response tag error.");
      return [];
   }

   const responseTagElements = brokerElem[0].childNodes;
   let i;
   let currentElem;
   let responseTag;
   for (i = 0; i < responseTagElements.length; ++i) {
      currentElem = responseTagElements[i];
      responseTag = currentElem.nodeName;
      if (responseTag !== "#text") {
         responseTagList.push(responseTag);
      }
   }
   return responseTagList;
};

Router.prototype.AddDSpecForPost = function (request, dedicatedToken: DSpecToken) {
   let dspec: DSpecToken = {} as DSpecToken;
   if (clientUtil.isTitanClient()) {
      if (dedicatedToken) {
         dspec = dedicatedToken;
      } else {
         let rawToken: string = sessionStorage.getItem("dspec_token");
         if (clientUtil.isChromeClient()) {
            rawToken = globalArray["dspec_token"];
         }
         try {
            dspec = JSON.parse(rawToken);
         } catch {
            Logger.error("invalid dspec token");
         }
      }
      const accessTokenHeader = `Bearer ${dspec.specToken}`;
      request.setRequestHeader("Authorization", accessTokenHeader);
      if (dspec.elasticApmTraceId) {
         request.setRequestHeader("elastic-apm-traceparent", dspec.elasticApmTraceId);
      }
      if (dspec.traceId) {
         request.setRequestHeader("traceparent", dspec.traceId);
      }
   }
};

Router.prototype.AddDSpecForFetch = function (request) {
   if (clientUtil.isTitanClient()) {
      let rawToken: string = sessionStorage.getItem("dspec_token");
      if (clientUtil.isChromeClient()) {
         rawToken = globalArray["dspec_token"];
      }
      try {
         const dspec: DSpecToken = JSON.parse(rawToken);
         const accessTokenHeader = `Bearer ${dspec.specToken}`;
         request["Authorization"] = accessTokenHeader;
         if (dspec.elasticApmTraceId) {
            request["elastic-apm-traceparent"] = dspec.elasticApmTraceId;
         }
         if (dspec.traceId) {
            request["traceparent"] = dspec.traceId;
         }
      } catch {
         Logger.error("invalid dspec token");
      }
   }
};

/**
 * post data to server
 *
 * @param  brokerUrl  broker URL
 * @param  contextInfo context data for posting message.
 * @param  jscdkSuccess  callback which will be called
 * @param  async  true(default) represents async, while false(used in test)
 *    represents sync
 * @param  timeout limit of the request posted
 * @return the post status for test
 */
Router.prototype.jscdkPost = function (
   brokerUrl,
   contextInfo,
   jscdkSuccess,
   async,
   timeout,
   skipNetworkError,
   sendFetch,
   dspec
) {
   const defParamLength = Router.prototype.jscdkPost.length; // Length of
   // parameters
   // defined in
   // function
   const actParamLength = arguments.length; // Length of actual parameters
   let settings;
   let ret; // for test
   const cacheThis = this;
   const messageText = contextInfo.messageText;
   const requestId = contextInfo.requestId;
   const requestHandlerList = contextInfo.requestHandlerList;
   let csrfToken;
   let headers;
   let csrfTokenService = globalArray["csrf-token-service"];

   if (typeof messageText === "undefined" || typeof requestId === "undefined") {
      Logger.error("ContextInfo struct illegal.");
      return null;
   }

   if (!csrfTokenService) {
      csrfTokenService = new CsrfTokenService();
      globalArray[csrfTokenService.globalName] = csrfTokenService;
   }

   try {
      csrfToken = csrfTokenService.getCsrfToken();
      if (defParamLength === actParamLength) {
         settings = {
            type: "POST",
            processData: false,
            url: brokerUrl,
            data: messageText,
            cache: false,
            beforeSend: (request) => {
               if (csrfToken) {
                  request.setRequestHeader("X-CSRF-TOKEN", csrfToken);
               }
               this.AddDSpecForPost(request, dspec);
            },
            success: function (data, textStatus, jscdkXHR) {
               const successData = {} as SuccessData;
               successData.data = data;
               successData.requestId = requestId;
               successData.requestHandlerList = requestHandlerList;
               ret = textStatus;
               if (textStatus === "success") {
                  Logger.debug("jscdkPost with 5 parameters success");
                  jscdkSuccess(cacheThis, successData, textStatus, jscdkXHR);
               } else if (textStatus === "error") {
                  Logger.debug("receive response of xmlhttprequest error");
               }
            },
            error: function (jscdkXHR, textStatus, errorThrown) {
               cacheThis.jscdkPostError(messageText, jscdkXHR, textStatus, errorThrown, skipNetworkError);
            },
            async: async,
            timeout: timeout
         };
      } else if (actParamLength === defParamLength - 1) {
         settings = {
            type: "POST",
            processData: false,
            url: brokerUrl,
            data: messageText,
            cache: false,
            beforeSend: (request) => {
               if (csrfToken) {
                  request.setRequestHeader("X-CSRF-TOKEN", csrfToken);
               }
               this.AddDSpecForPost(request, dspec);
            },
            success: function (data, textStatus, jscdkXHR) {
               const successData = {} as SuccessData;
               successData.data = data;
               successData.requestId = requestId;
               successData.requestHandlerList = requestHandlerList;
               ret = textStatus;
               if (textStatus === "success") {
                  Logger.debug("jscdkPost with 4 parameters success");
                  jscdkSuccess(cacheThis, successData, textStatus, jscdkXHR);
               } else if (textStatus === "error") {
                  Logger.debug("receive response of xmlhttprequest error");
               }
            },
            error: function (jscdkXHR, textStatus, errorThrown) {
               cacheThis.jscdkPostError(messageText, jscdkXHR, textStatus, errorThrown, skipNetworkError);
            },
            async: async
         };
      } else if (actParamLength === defParamLength - 2) {
         settings = {
            type: "POST",
            processData: false,
            url: brokerUrl,
            data: messageText,
            cache: false,
            beforeSend: (request) => {
               if (csrfToken) {
                  request.setRequestHeader("X-CSRF-TOKEN", csrfToken);
               }
               this.AddDSpecForPost(request, dspec);
            },
            success: function (data, textStatus, jscdkXHR) {
               const successData = {} as SuccessData;
               successData.data = data;
               successData.requestId = requestId;
               successData.requestHandlerList = requestHandlerList;
               ret = textStatus;
               if (textStatus === "success") {
                  Logger.debug("jscdkPost with 3 parameters success");
                  jscdkSuccess(cacheThis, successData, textStatus, jscdkXHR);
               } else if (textStatus === "error") {
                  Logger.debug("receive response of xmlhttprequest error");
               }
            },
            error: function (jscdkXHR, textStatus, errorThrown) {
               cacheThis.jscdkPostError(messageText, jscdkXHR, textStatus, errorThrown, skipNetworkError);
            }
         };
      } else {
         Logger.error("post parameter error.");
         return null;
      }

      if (sendFetch && window.fetch) {
         headers = {
            "Content-Type": "text/xml;charset=UTF-8"
         };
         if (csrfToken) {
            headers["X-CSRF-TOKEN"] = csrfToken;
         }
         this.AddDSpecForFetch(headers);
         if (clientUtil.isFirefox()) {
            const blob = new Blob([
               messageText,
               {
                  type: "text/xml;charset=UTF-8"
               }
            ]);
            navigator.sendBeacon(brokerUrl, blob);
         } else {
            window.fetch(brokerUrl, {
               method: "POST",
               mode: "same-origin",
               cache: "no-cache",
               credentials: "same-origin",
               headers: headers,
               referrerPolicy: "no-referrer",
               keepalive: true,
               body: messageText
            });
         }
      } else if (this.ajaxWasAborted) {
         ret = null;
         this.ajaxWasAborted = false;
      } else {
         ret = $.ajax(settings);
      }
   } catch (err) {
      Logger.error("post request error: " + err);
      ret = null;
   }
   return ret;
};

/**
 * callback for the successful response
 *
 * @param  reference[in]  reference of object to call
 * @param  data[in]  a JSON data contains requestId, the reference of request
 *    handler and response data.
 * @param  textStatus  response status, can be "timeout", "error",
 *    "notmodified", "success", "parsererror"
 * @param  jscdkXHR  a super class of XMLHttpRequest
 */
Router.prototype.jscdkSuccess = function (reference, data, textStatus, jscdkXHR) {
   reference.recentXhrObject = {};
   reference.onResponse(data); // onResponse callback here
};

/**
 * set handlers' state to FAIL when jscdkPost error occurs
 * @param contextInfo[in] contextInfo for jscdkPost
 * @return null if request message is invalid, else return "done"
 */
Router.prototype.jscdkPostError = function (contextInfo, jscdkXHR, textStatus, errorThrown, skipNetworkError) {
   let error = {},
      subError = {} as SubError,
      msgError = {},
      errorObject,
      messageText = contextInfo.messageText;

   this.recentXhrObject = {};

   /*
    * If user cancels http request, don't treat it as an error. In this case,
    * UI should be controlled by UI layer, so just return "done" here.
    */
   if (textStatus === "abort") {
      Logger.debug("User aborts http request.");
      return "done";
   }

   if (textStatus === "timeout") {
      errorObject = JSCDKBrokerError.getError(JSCDKBrokerError.JSCDK_BROKER_ERROR_TIMEOUT);
   } else if (textStatus === "error") {
      errorObject = JSCDKBrokerError.getError(JSCDKBrokerError.JSCDK_BROKER_ERROR_CONNECT_TO_BROKER_ERROR);
   } else {
      errorObject = JSCDKBrokerError.getError(JSCDKBrokerError.JSCDK_BROKER_ERROR_UNKNOWN);
   }

   Logger.debug("jscdkPostError: " + this.name);
   subError.errorCode = errorObject.getErrorCode();
   subError.errorMessage = errorObject.getErrorMessage();
   subError.userMessage = errorObject.getErrorMessage();
   subError.skipNetworkError = skipNetworkError;
   subError.httpStatus = jscdkXHR.status;
   subError.responseText = jscdkXHR.responseText;
   msgError[this.name] = subError;
   error["error"] = msgError;
   this.appendContent(error);
   this.pushErrorToUser(this);
   return "done";
};

function ensureTimerStopped() {
   let idleTimerObject;
   if (util.brokerSupportApplication()) {
      idleTimerObject = util.getObject(globalArray, "idle-timeout-timer");
      if (idleTimerObject) {
         idleTimerObject.ensureStopped();
      }
   }
}
Router.prototype.getMessageName = function () {
   Logger.debug("handler is router, and get message name Router");
   return this.messageName;
};

/**
 * push corresponding error to user if its condition is satisfied
 * @param handler[in] which handler's error to push
 */
Router.prototype.pushErrorToUser = function (handler) {
   // if the error is special for this handler, alert
   let messageText,
      errorAction = {} as ErrorAction,
      actionContent = {} as ErrorAction["content"],
      cacheThis = this,
      authInfoObject,
      getDesktopsObject,
      getLaunchItemsObject,
      getTunnelConnObject,
      getConfigObject,
      setLocaleAndGetConfiguration = function () {
         let handlerList,
            setLocaleObject = globalArray["set-locale"],
            getConfigurationObject = globalArray["get-configuration"],
            router = globalArray["router"];

         if (!getConfigurationObject) {
            Logger.error("getConfigurationObject do not exist");
            return;
         }
         getConfigurationObject.setRequestXML();
         handlerList = getConfigurationObject.composeHandlerList();
         router.postMessage(handlerList);
      },
      connectionRetryController = globalArray["connection-retry-controller"];

   if (handler.content["error"]) {
      if (typeof handler.getMessageName === "function") {
         actionContent.handler = handler.getMessageName();
      }
      Logger.info("pushErrorToUser: " + JSON.stringify(handler.content["error"]));
      $.each(handler.content["error"], function (errorKey, errorValue) {
         if (handler.name) {
            actionContent.brokerUrl = encodeURIComponent(cacheThis.brokerUrl);
         } else if (handler.getMessageName() === "get-configuration") {
            if (errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_CONNECT_TO_BROKER_ERROR) {
               actionContent.brokerUrl = encodeURIComponent(cacheThis.brokerUrl);
            } else if (
               errorValue.errorCode === "CONFIGURATION_ERROR" ||
               errorValue.errorCode === "COMPATIBILITY_ERROR"
            ) {
               //This is for bug 2766947
               //When 8.3+ Chrome client connect to low version broker, dismiss the error msg
               //and send get-configuration to broker without IPv6
               const ipv6Service = globalArray["ipv6-service"];
               if (ipv6Service && !ipv6Service.isIPv6Disabled()) {
                  Logger.info("send get-configuration again without Ipv6 due to " + errorValue.errorCode);
                  const getConfigurationObject = globalArray["get-configuration"],
                     router = globalArray["router"];

                  ipv6Service.setIPv6Disabled(true);
                  getConfigurationObject.setRequestXML();
                  const handlerList = getConfigurationObject.composeHandlerList();
                  router.postMessage(handlerList);
               }
               return;
            }
         } else if (handler.getMessageName() === "do-submit-authentication") {
            if (errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_AUTHENTICATION_FAILED) {
               actionContent.authType = handler.authAction.type;
               getConfigObject = globalArray["get-configuration"];
               ensureTimerStopped();
            }
         } else if (handler.getMessageName() === "get-authentication-status") {
            // broker with new version but session is not auth
            if (errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_AUTHENTICATED) {
               if (handler.needCallbackFlag) {
                  handler.needCallbackFlag = false;
                  Logger.info("tab sync fail, the session is expired now");
               } else if (handler.workForSilentModeFlag) {
                  handler.workForSilentModeFlag = false;
                  Logger.info(
                     "deal with getAuthenticationStatus response before act in desktop-select page failed, the session is expired now"
                  );
               } else {
                  setLocaleAndGetConfiguration();
                  return;
               }
            }
            // broker with old version
            if (
               errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_UNRECOGNIZED_CONTENT ||
               errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_UNSUPPORTED_VERSION
            ) {
               // ToCheck:
               // need
               // this
               // condition?
               if (!util.brokerSupportApplication()) {
                  //ignore this error and work as old version
                  setLocaleAndGetConfiguration();
                  return;
               } else {
                  Logger.debug("with new broker but meet error when sending <get-authentication-status>");
               }
            }
         } else if (
            handler.getMessageName() === "get-application-connection" ||
            handler.getMessageName() === "get-desktop-connection"
         ) {
            const type: string = handler.getMessageName() === "get-desktop-connection" ? "desktop" : "application";
            actionContent.itemType = type;
            actionContent.itemId = handler.itemId || handler.originId;

            if (
               errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_LAUNCH_HEADROOM_ERROR &&
               errorValue.clientRetryTimeoutSeconds >= 0 &&
               errorValue.clientRetryIntervalSeconds >= 0
            ) {
               if (util.brokerSupportAzureReconnect() && !connectionRetryController.isDisabled()) {
                  Logger.debug("will reconnect item");
                  const action = globalArray["connection-retry-action"];

                  if (!action.connectionRetry) {
                     action.connectionRetry = true;
                     action.disableCombine = true;
                     const timeout = errorValue.clientRetryTimeoutSeconds;
                     const interval = errorValue.clientRetryIntervalSeconds;
                     connectionRetryController.init(timeout, interval, type, action);
                  } else {
                     connectionRetryController.update(action);
                  }
                  // Don't push this error here, connectionRetryController will push more details
                  // message to UI.
                  return;
               }
            } else {
               connectionRetryController.onReconnectionError("Broker Error");
            }
         }

         if (
            handler.getMessageName() === "get-application-connection" ||
            handler.getMessageName() === "get-desktop-connection" ||
            handler.getMessageName() === "get-application-session-connection"
         ) {
            actionContent.triggerItemInfo = handler.triggerItemInfo;
         }

         if (errorValue.errorCode === "OUT_OF_SEQUENCE_ERROR") {
            // ignore this error. "OUT_OF_SEQUENCE_ERROR" is only used in
            // JSCDk, so have no need to be added in JSCDKBrokerError
            Logger.debug("ignore the error OUT_OF_SEQUENCE_ERROR");
            return;
         } else if (errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_ALREADY_AUTHENTICATED) {
            prepareForAlreadyAuthenticated();
         } else if (errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_AUTHENTICATED) {
            // Not authenticated
            if (
               handler.messageName === "set-last-user-activity" ||
               handler.messageName === "get-authentication-status"
            ) {
               // ignore the error "NOT_AUTHENTICATED" under XML <set-last-user-activity>
               // to let behaviour to be align with Native Client
               Logger.debug("ignore the error NOT_AUTHENTICATED under XML <set-last-user-activity>");
               return;
            }
            if (globalArray) {
               // Get DoSubmitAuthenticationHandler
               authInfoObject = globalArray["do-submit-authentication"];
               if (authInfoObject) {
                  // Initialize DoSubmitAuthenticationHandler
                  authInfoObject.resetData();
               }
            }
            ensureTimerStopped();
            if (connectionRetryController) {
               connectionRetryController.onReconnectionError("Auth Invalid");
            }
         }

         Logger.error("error from " + errorKey.toString() + ": " + errorValue.errorMessage);
         if (!!errorValue.userMessage || !!errorValue.errorMessage) {
            if (errorValue.userMessage) {
               messageText = errorValue.userMessage;
            } else {
               if (errorValue.errorCode === JSCDKBrokerError.JSCDK_BROKER_ERROR_DESKTOPS_ERROR) {
                  // The error message of 'DESKTOPS_ERROR' is not user
                  // friendly. return the error message here.
                  messageText = util._("JSCDK_ERROR_SERVER_TIME_OUT");
               } else {
                  messageText = errorValue.errorMessage;
               }
            }
            actionContent.errorType = errorValue.errorCode;
            actionContent.errorText = messageText;
            actionContent.errorMessage = errorValue.errorMessage;
            if (errorValue.errorDetails) {
               actionContent.errorDetails = errorValue.errorDetails;
            }
            if (errorValue.workspaceOneServerHostname) {
               actionContent.workspaceOneServerHostname = errorValue.workspaceOneServerHostname;
            }
            actionContent.skipNetworkError = errorValue.skipNetworkError;
            actionContent.srcHandlerName = errorKey;
            actionContent.redirect = !!handler.redirectMsg;
            actionContent.httpStatus = errorValue.httpStatus;
            actionContent.responseText = errorValue.responseText;
            errorAction.name = "ShowError";
            errorAction.content = actionContent;
            JSCDKSetUI(JSON.stringify(errorAction));
            handler.removeFromContent("error"); // clear error data after error
            // is pushed to user
         }
      });
   }
};

/**
 * append relative content(key-value pairs) to the content of router
 *
 * @param content[in] key-value pair to be appended
 */
Router.prototype.appendContent = function (content) {
   let key;
   for (key in content) {
      if (content.hasOwnProperty(key)) {
         this.content[key] = content[key];
         Logger.debug(this.name + " this.content[" + key + "]: " + JSON.stringify(this.content[key]));
      }
   }
};

/**
 * remove corresponding content(key-value pairs) from this.content of router
 * @param key[in] key of the item to remove
 */
Router.prototype.removeFromContent = function (key) {
   if (this.content.hasOwnProperty(key)) {
      delete this.content[key];
   }
};

/**
 * get handler by responseTag and suffix
 * @param responseTag[in] responseTag of handler
 * @param suffix[in] desktopId or other to be combined with responseTag
 * @return the available handler
 */
Router.prototype.getHandler = function (responseTag, suffix) {
   let requestId;
   let handler;
   requestId = MessageHandler.prototype.requestIdKV[responseTag + suffix];
   if (requestId) {
      handler = util.getObject(globalArray, responseTag + requestId);
   }
   return handler;
};

/**
 * push self-defined errors to UI which are not returned from broker.
 * @param object[in] object instance who generates this error
 * @param userMessage[in] user message to push
 * @param errorDetails[in] extra info, could be of type object(not including
 *    null)
 */
Router.prototype.pushSelfDefinedError = function (object, userMessage, errorDetails) {
   const errorAction = {} as ErrorAction;
   const actionContent = {} as ErrorAction["content"];
   let errorKey;

   errorKey = object.name || object.messageName || "self-defined error";
   Logger.error("error from " + errorKey + ": " + userMessage);

   actionContent.errorType = errorKey;
   actionContent.errorText = userMessage;
   if (errorDetails) {
      actionContent.errorDetails = errorDetails;
   }
   errorAction.name = "ShowError";
   errorAction.content = actionContent;
   JSCDKSetUI(JSON.stringify(errorAction));
};
