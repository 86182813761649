/**
 * ******************************************************
 * Copyright (C) 2012-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getDesktopsHandler.js --
 *
 *      Implementation of the message handler to get desktops.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import DoLogoutHandler from "./doLogoutHandler";
import KillSessionHandler from "./killSessionHandler";
import ResetDesktopHandler from "./resetDesktopHandler";
import { GetDesktopAction } from "../jscdk-interface";

export default function GetDesktopsHandler() {
   let doSubmitAuthenticationObject;
   let router;
   let getTunnelConnObject;

   // member variables
   this.messageName = "get-desktops";
   this.messageText = "get-desktops";
   this.responseTag = "desktops";

   // whether to notify UI on get-desktops response.
   this.notifyUI = true;

   this.subHandlerList = [];
   this.composedHandlerList = [];

   // register dependencies here
   getTunnelConnObject = util.getObject(globalArray, "get-tunnel-connection");
   if (getTunnelConnObject) {
      this.registerHandler(getTunnelConnObject, "state");
   } else {
      Logger.error("The get-tunnel-connection object is null!");
   }
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
GetDesktopsHandler.prototype = new MessageHandler();
GetDesktopsHandler.constructor = GetDesktopsHandler;

/**
 * Reset handler's state and content.
 *
 */
GetDesktopsHandler.prototype.resetData = function () {
   MessageHandler.prototype.resetData.apply(this);

   this.notifyUI = true;
};

/**
 * Set the request XML for getDesktops.
 *
 * @param supportedProtocols [in] array for supported protocols.
 */
GetDesktopsHandler.prototype.setRequestXML = function (supportedProtocols, notifyUI) {
   let nameElement,
      protocolElement,
      protocolsElement = "",
      i;
   for (i = 0; i < supportedProtocols.length; i++) {
      nameElement = util.createElement("name", supportedProtocols[i]);
      protocolElement = util.createElement("protocol", nameElement);
      protocolsElement += protocolElement;
   }
   this.requestXML = util.createElement("supported-protocols", protocolsElement);

   if (typeof notifyUI === "boolean") {
      // Whether to notify UI on get-desktops response.
      this.notifyUI = notifyUI;
   } else {
      // Always notify UI on get-desktops response.
      this.notifyUI = true;
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetDesktopsHandler.prototype.onUpdated = function () {
   let getDesktopsAction = {} as GetDesktopAction,
      actionContent = {} as GetDesktopAction["content"],
      router,
      urlHandler,
      urlParams;

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.state === StateEnum.DONE) {
      if (this.notifyUI) {
         // Compose refresh UI request.
         getDesktopsAction.name = "GetLaunchItems";
         if (globalArray) {
            router = globalArray["router"];
            if (router) {
               actionContent.brokerUrl = router.brokerUrl;
            }
         }
         if (this.content["parsedResult"]) {
            actionContent.desktops = this.content.parsedResult.desktops;
         }
         // Handle the desktopId passed through URI.
         urlHandler = util.getObject(globalArray, "url-handler");
         if (urlHandler) {
            urlParams = urlHandler.params;
            if (urlParams.desktopId) {
               actionContent.desktopId = urlParams.desktopId;
               actionContent.action = urlParams.action;
               if (urlParams.desktopProtocol) {
                  actionContent.targetProtocol = urlParams.desktopProtocol.toUpperCase();
               }
            }
         }

         getDesktopsAction.content = actionContent;
         JSCDKSetUI(JSON.stringify(getDesktopsAction));
      }
   }
   // if the error is special for this handler, alert
   Router.prototype.pushErrorToUser(this);
};

/**
 * parse desktops information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
GetDesktopsHandler.prototype.parseResult = function (responseXML) {
   const responseBody = responseXML;
   const desktopResponse = {};
   let result;
   let resultElem;
   const desktops = {};
   let desktopsNodes;
   let desktopsLength;
   let errorCodeNode;
   let errorMessageNode;
   let desktop;
   let i;
   const brokerTag = util.getChildNode(responseBody, "broker", 0);
   if (!brokerTag) {
      Logger.error("response of GetDesktops error.");
      return null;
   }
   resultElem = util.getChildNode(brokerTag, "result", 0);
   if (!resultElem || !resultElem.hasChildNodes()) {
      return null;
   }
   result = resultElem.childNodes[0].nodeValue;
   desktopResponse["result"] = result;
   if (result === "ok") {
      desktopsNodes = brokerTag.getElementsByTagName("desktop");
      desktopsLength = desktopsNodes.length;
      for (i = 0; i < desktopsLength; i++) {
         const aDesktopInstance = {};

         // set desktop name
         this.setDesktopName(aDesktopInstance, desktopsNodes[i]);

         // set desktop id
         this.setDesktopId(aDesktopInstance, desktopsNodes[i]);

         // set response state
         this.setResponseState(aDesktopInstance, desktopsNodes[i]);

         // set sessionId
         this.setSessionId(aDesktopInstance, desktopsNodes[i]);

         // set checkedOut
         this.setCheckedOut(aDesktopInstance, desktopsNodes[i]);

         // set inMaintenanceMode
         this.setInMaintenanceMode(aDesktopInstance, desktopsNodes[i]);

         // set protocol match
         this.setProtocolMatch(aDesktopInstance, desktopsNodes[i]);

         // set preferred protocol, defaultProtocol and supportedProtocol
         this.setProtocols(aDesktopInstance, desktopsNodes[i]);

         // set desktop user preferences
         this.setDesktopUserPrefs(aDesktopInstance, desktopsNodes[i]);

         // set canLogoff: whether the desktop can logoff
         this.setCanLogoff(aDesktopInstance, desktopsNodes[i]);

         // set resetAllowed
         this.setResetAllowed(aDesktopInstance, desktopsNodes[i]);

         // set resetAllowedOnSession
         this.setResetAllowedOnSession(aDesktopInstance, desktopsNodes[i]);

         // set canRollback: whether the desktop can rollback
         this.setCanRollback(aDesktopInstance, desktopsNodes[i]);

         util.addItemForJson(desktops, i, aDesktopInstance);
         Logger.debug("aDesktopInstance: " + JSON.stringify(aDesktopInstance));
      }
      util.addItemForJson(desktopResponse, "desktops", desktops);
   } else if (result === "error") {
      errorCodeNode = util.getChildNode(responseBody, "error-code", 0);
      if (errorCodeNode) {
         util.addItemForJson(desktopResponse, "error-code", $(errorCodeNode).text());
      }
      errorMessageNode = util.getChildNode(responseBody, "error-message", 0);
      if (errorMessageNode) {
         util.addItemForJson(desktopResponse, "error-message", $(errorMessageNode).text());
      }
   }
   return desktopResponse;
};

/**
 * set protocol match or not
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setProtocolMatch = function (aDesktopInstance, desktopNode) {
   const matchNode = util.getChildNode(desktopNode, "protocol-match", 0),
      match = matchNode ? $(matchNode).text() : "true";

   util.addItemForJson(aDesktopInstance, "protocol-match", match);
};

/**
 * set preferredProtocol, defaultProtocol and supportedProtocol
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setProtocols = function (aDesktopInstance, desktopNode) {
   let userPreferencesNode;
   let preferenceNode;
   let j;
   let hasDefault = false;
   let protocolsNode;
   let protocolNode;
   let protocolName;
   const protocols = {};
   let preferenceNameAttr;
   let isDefaultNode;
   let supportPreferredProtocol = false;
   userPreferencesNode = util.getChildNode(desktopNode, "user-preferences", 0);
   if (userPreferencesNode) {
      preferenceNode = userPreferencesNode.getElementsByTagName("preference");
   }
   if (preferenceNode) {
      for (j = 0; j < preferenceNode.length; j++) {
         preferenceNameAttr = preferenceNode[j].getAttribute("name");
         // set preferred protocol here
         if (preferenceNameAttr === "protocol") {
            util.addItemForJson(aDesktopInstance, "preferredProtocol", $(preferenceNode[j]).text());
         }
      }
   }
   protocolsNode = util.getChildNode(desktopNode, "protocols", 0);
   if (protocolsNode) {
      protocolNode = protocolsNode.getElementsByTagName("protocol");
   }
   if (protocolNode) {
      for (j = 0; j < protocolNode.length; j++) {
         protocolName = $(util.getChildNode(protocolNode[j], "name", 0)).text();
         protocols[j] = protocolName;
         if (protocolName === aDesktopInstance["preferredProtocol"]) {
            supportPreferredProtocol = true;
         }
         isDefaultNode = util.getChildNode(protocolNode[j], "is-default", 0);
         if (!!isDefaultNode && $(isDefaultNode).text() === "true") {
            // set default protocol if "is-default" is not null
            hasDefault = true;
            util.addItemForJson(aDesktopInstance, "defaultProtocol", protocolName);
         }
      }
   }
   // if "is-default" is null, set first protocol as default protocol
   if (!hasDefault && !!protocolNode && protocolNode.length > 0) {
      aDesktopInstance["defaultProtocol"] = $(util.getChildNode(protocolNode[0], "name", 0)).text();
   }
   // if no preferred protocol in user preference, set default protocol as it
   if (!aDesktopInstance["preferredProtocol"] || !supportPreferredProtocol) {
      util.addItemForJson(aDesktopInstance, "preferredProtocol", aDesktopInstance["defaultProtocol"]);
   }
   // set supported protocols here
   util.addItemForJson(aDesktopInstance, "protocols", protocols);
};

/**
 * set desktop user preferences
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setDesktopUserPrefs = function (aDesktopInstance, desktopNode) {
   let preferenceNameAttr;
   let j;
   let userPreferencesNode;
   let preferenceNode;
   userPreferencesNode = util.getChildNode(desktopNode, "user-preferences", 0);
   if (userPreferencesNode) {
      preferenceNode = userPreferencesNode.getElementsByTagName("preference");
   }
   if (preferenceNode) {
      for (j = 0; j < preferenceNode.length; j++) {
         preferenceNameAttr = preferenceNode[j].getAttribute("name");
         if (preferenceNameAttr === "alwaysConnect") {
            // set alwaysConnect
            util.addItemForJson(aDesktopInstance, "alwaysConnect", $(preferenceNode[j]).text());
         }
         if (preferenceNameAttr === "screenSize") {
            // set screenSize
            util.addItemForJson(aDesktopInstance, "screenSize", $(preferenceNode[j]).text());
         }
      }
   }
};

/**
 * set desktop id
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setDesktopId = function (aDesktopInstance, desktopNode) {
   const desktopIdNode = util.getChildNode(desktopNode, "id", 0),
      desktopId = desktopIdNode ? $(desktopIdNode).text() : "";

   util.addItemForJson(aDesktopInstance, "id", desktopId);
};

/**
 * set desktop name
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setDesktopName = function (aDesktopInstance, desktopNode) {
   const desktopNameNode = util.getChildNode(desktopNode, "name", 0),
      desktopName = desktopNameNode ? $(desktopNameNode).text() : "";

   Logger.debug("desktopName: " + desktopName);
   util.addItemForJson(aDesktopInstance, "name", desktopName);
};

/**
 * set canLogoff
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setCanLogoff = function (aDesktopInstance, desktopNode) {
   const inMaintenanceModeNode = util.getChildNode(desktopNode, "in-maintenance-mode", 0),
      checkoutGuid = util.getChildNode(desktopNode, "checkout-guid", 0),
      inMaintenanceMode = inMaintenanceModeNode ? $(inMaintenanceModeNode).text() : "false",
      checkedOut = !!checkoutGuid && $(checkoutGuid).text() !== "" ? "true" : "false",
      responseStateNode = util.getChildNode(desktopNode, "state", 0),
      responseState = responseStateNode ? $(responseStateNode).text() : "No Session",
      responseStateLower = responseState.toLowerCase();

   if (
      inMaintenanceMode === "true" ||
      checkedOut === "true" ||
      (responseStateLower !== "connected" && responseStateLower !== "disconnected")
   ) {
      /*
       * Logoff of a desktop from the desktop selector is not possible if it is
       * in maintenance mode, if it is checked out by another machine, or if
       * the desktop state is something other than "connected" or "disconnected".
       */
      util.addItemForJson(aDesktopInstance, "canLogoff", "false");
   } else {
      util.addItemForJson(aDesktopInstance, "canLogoff", "true");
   }
};

/**
 * set reset-allowed
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setResetAllowed = function (aDesktopInstance, desktopNode) {
   const resetAllowedNode = util.getChildNode(desktopNode, "reset-allowed", 0),
      resetAllowed = resetAllowedNode ? $(resetAllowedNode).text() : "false";

   util.addItemForJson(aDesktopInstance, "reset-allowed", resetAllowed);
};

/**
 * set reset-allowed-on-session
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setResetAllowedOnSession = function (aDesktopInstance, desktopNode) {
   const resetAllowedOnSessionNode = util.getChildNode(desktopNode, "reset-allowed-on-session", 0),
      resetAllowedOnSession = resetAllowedOnSessionNode ? $(resetAllowedOnSessionNode).text() : "false";

   util.addItemForJson(aDesktopInstance, "reset-allowed-on-session", resetAllowedOnSession);
};

/**
 * set in-maintenance-mode
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setInMaintenanceMode = function (aDesktopInstance, desktopNode) {
   const inMaintenanceModeNode = util.getChildNode(desktopNode, "in-maintenance-mode", 0),
      inMaintenanceMode = inMaintenanceModeNode ? $(inMaintenanceModeNode).text() : "false";

   util.addItemForJson(aDesktopInstance, "in-maintenance-mode", inMaintenanceMode);
};

/**
 * set checkedOut
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setCheckedOut = function (aDesktopInstance, desktopNode) {
   let checkedOut = "false";
   const checkedOutNode = util.getChildNode(desktopNode, "checkout-guid", 0);
   if (!!checkedOutNode && $(checkedOutNode).text().length > 0) {
      checkedOut = "true";
   }

   util.addItemForJson(aDesktopInstance, "checkedOut", checkedOut);
};

/**
 * set response state
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setResponseState = function (aDesktopInstance, desktopNode) {
   const responseStateNode = util.getChildNode(desktopNode, "state", 0),
      responseState = responseStateNode ? $(responseStateNode).text() : "No Session";

   util.addItemForJson(aDesktopInstance, "state", responseState);
};

/**
 * set session-id
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setSessionId = function (aDesktopInstance, desktopNode) {
   const sessionIdNode = util.getChildNode(desktopNode, "session-id", 0),
      sessionId = sessionIdNode ? $(sessionIdNode).text() : "";

   util.addItemForJson(aDesktopInstance, "session-id", sessionId);
};

/**
 * set canRollback
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetDesktopsHandler.prototype.setCanRollback = function (aDesktopInstance, desktopNode) {
   const offlineStateNode = util.getChildNode(desktopNode, "offline-state", 0),
      offlineState = offlineStateNode ? $(offlineStateNode).text() : "";

   if (offlineState === "checked out") {
      util.addItemForJson(aDesktopInstance, "canRollback", "true");
   } else {
      util.addItemForJson(aDesktopInstance, "canRollback", "false");
   }
};

/**
 * action to disconnect desktop
 *
 */
GetDesktopsHandler.prototype.disconnectDesktop = function () {
   let logoutBrokerObject;
   let router;
   let handlerList;

   logoutBrokerObject = util.getObject(globalArray, "do-logout");
   if (!logoutBrokerObject) {
      logoutBrokerObject = new DoLogoutHandler();
      globalArray[logoutBrokerObject.messageName] = logoutBrokerObject;
      globalArray[logoutBrokerObject.responseTag] = logoutBrokerObject;
   } else {
      logoutBrokerObject.resetData();
   }

   router = util.getObject(globalArray, "router");

   if (!!logoutBrokerObject && !!router) {
      handlerList = logoutBrokerObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};

/**
 * action to reset desktop
 *
 * @param desktopId [in] desktop's id used to reset desktop
 */
GetDesktopsHandler.prototype.resetDesktop = function (desktopId) {
   let resetDesktopObject;
   let router;
   let handlerList;

   router = util.getObject(globalArray, "router");
   if (!!router && !!desktopId) {
      /**
       * each reset-desktop object corresponds to a desktop-id
       * if the object already exists, reset data; otherwise create a new
       * instance firstly locate handler from
       * MessageHandler.prototype.requestIdKV
       */
      resetDesktopObject = router.getHandler("reset-desktop", desktopId);
      if (!resetDesktopObject) {
         resetDesktopObject = new ResetDesktopHandler();
         globalArray[resetDesktopObject.messageName + resetDesktopObject.requestId] = resetDesktopObject;
         globalArray[resetDesktopObject.responseTag + resetDesktopObject.requestId] = resetDesktopObject;
         /**
          * update MessageHandler.prototype.requestIdKV
          * the format is {responseTag1+desktop-id1 : requestId1,
          * responseTag2+desktop-id2 : requestId2} such as key of
          * ResetDesktopHandler is "reset-desktop"+desktopId
          */
         MessageHandler.prototype.requestIdKV[resetDesktopObject.responseTag + desktopId] =
            resetDesktopObject.requestId;
      } else {
         resetDesktopObject.resetData();
      }

      if (!!resetDesktopObject && !!router) {
         resetDesktopObject.setRequestXML(desktopId);
         handlerList = resetDesktopObject.composeHandlerList();
         router.postMessage(handlerList);
      }
   }
};

/**
 * action to logoff desktop
 *
 * @param desktopId [in] desktop's id used to log off desktop
 * @param sessionId [in] desktop's sessionId to use
 */
GetDesktopsHandler.prototype.logoffDesktop = function (desktopId, sessionId) {
   let killSessionObject;
   let router;
   let handlerList;

   router = util.getObject(globalArray, "router");

   if (!!router && !!desktopId && !!sessionId && sessionId.trim() !== "") {
      /**
       * each kill-session object corresponds to a desktop-id
       * if the object already exists, reset data; otherwise create a new
       * instance firstly locate handler from
       * MessageHandler.prototype.requestIdKV
       */
      killSessionObject = router.getHandler("kill-session", desktopId);
      if (!killSessionObject) {
         killSessionObject = new KillSessionHandler();
         globalArray[killSessionObject.messageName + killSessionObject.requestId] = killSessionObject;
         globalArray[killSessionObject.responseTag + killSessionObject.requestId] = killSessionObject;
         /**
          * update MessageHandler.prototype.requestIdKV
          * the format is {responseTag1+desktop-id1 : requestId1,
          * responseTag2+desktop-id2 : requestId2} such as key of
          * KillSessionHandler is "kill-session"+desktopId
          */
         MessageHandler.prototype.requestIdKV[killSessionObject.responseTag + desktopId] = killSessionObject.requestId;
      } else {
         killSessionObject.resetData();
      }

      if (!!killSessionObject && !!router) {
         killSessionObject.setRequestXML(sessionId);
         handlerList = killSessionObject.composeHandlerList();
         router.postMessage(handlerList);
      }
   }
};

/**
 * find desktop by desktopId(desktop id or desktop name)
 *
 * @param desktopId [in] desktop id to use.
 * @param desktops [in] desktop list to be located.
 * @return target desktop whose id equals desktopId
 */
GetDesktopsHandler.prototype.findDesktop = function (desktopId, desktops) {
   let targetDesktop;
   let oneDesktop;

   if (desktopId) {
      for (oneDesktop in desktops) {
         /*
          * Use normalized (URL encoded and lower case) DN as the desktop
          * ID to follow Horizon's new rule.
          * Also, user may use the desktop as the desktop ID.
          */
         if (
            encodeURIComponent(desktops[oneDesktop].id.toLowerCase()) === encodeURIComponent(desktopId.toLowerCase()) ||
            desktops[oneDesktop].name.toLowerCase() === desktopId.toLowerCase()
         ) {
            targetDesktop = desktops[oneDesktop];
            break;
         }
      }
   }
   return targetDesktop;
};
// add more functions here
