/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * - keyboard-ock-service.ts
 * This service client ability to change default browser behavior
 * for responding key events.
 *
 * Currently only add ESC related support.
 */

import { Injectable } from "@angular/core";
import Logger from "../../core/libs/logger";

@Injectable({
   providedIn: "root"
})
export class KeyboardLockService {
   private lockApiSupport: boolean;
   constructor() {
      this.lockApiSupport =
         !!navigator.keyboard &&
         typeof navigator.keyboard.lock === "function" &&
         typeof navigator.keyboard.unlock === "function";
   }

   private _overrideKeys = (keys: Array<string>): boolean => {
      if (!this.lockApiSupport) {
         return false;
      }
      try {
         navigator.keyboard.lock(keys);
         Logger.debug("override key responser for " + keys, Logger.UI);
         return true;
      } catch (e) {
         Logger.debug("failed to override key responser for " + keys, Logger.UI);
         return false;
      }
   };

   private _releaseKeys = (keys: Array<string>): boolean => {
      if (!this.lockApiSupport) {
         return false;
      }
      try {
         navigator.keyboard.unlock(keys);
         Logger.debug("set key responser to default for " + keys, Logger.UI);
         return true;
      } catch (e) {
         Logger.debug("failed to set key responser to default  " + keys, Logger.UI);
         return false;
      }
   };

   // add F11 for bug: https://jira-euc.eng.vmware.com/jira/browse/VCART-1444
   public overrideESCAndF11 = (): boolean => {
      return this._overrideKeys(["Escape", "F11"]);
   };
   public releaseESCAndF11 = (): boolean => {
      return this._releaseKeys(["Escape", "F11"]);
   };
}
