/**
 * ******************************************************
 * Copyright (C) 2020-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * unity-service.js --
 *
 * Module for wrapping unity to angular factory service. Unity Service is a
 * singleton service.
 *
 */

import { Injectable } from "@angular/core";
import Logger from "../../../../core/libs/logger";
import { Monitor } from "../../multimon/common/monitor-message";
import { clientUtil } from "../../../../core/libs";
import { DisplayService } from "./display.service";

@Injectable()
export class DisplayPresentationService extends DisplayService {
   private inited = false;
   private request = null;
   private connection = null;

   constructor() {
      super();
      this.addSignal("displayConnected");
      this.addSignal("displayDisconnected");
      this.addSignal("displayMessage");
      // @ts-ignore
      this.isSupportedPlatform = typeof window.PresentationRequest === "function";
   }

   private onDisplayConnected = () => {
      this.emit("displayConnected");
      this.displayReady = true;
      Logger.info("display connected");
   };

   /**
    * @param  {boolean} recoverable Whether the disconnected connection is recoverable
    */
   private onDisplayDisconnected = (recoverable) => {
      if (recoverable) {
         Logger.info("display closed");
      } else {
         Logger.info("display terminated");
         this.emit("displayDisconnected");
      }
      this.displayReady = false;
   };

   private onDisplayMessage = (event) => {
      this.emit("displayMessage", event);
   };

   private handleAvailabilityChange = (available) => {
      this.hasExtraDisplay = available;
   };

   /**
    * @param  {sting} url The address of the extended monitor displaying page.
    */
   public init = (url) => {
      if (this.inited) {
         Logger.error("don't support reinit displayService for now");
         return;
      }
      //@ts-ignore
      if (typeof window.PresentationRequest !== "function") {
         Logger.info("This browser can't support multimon");
         return;
      }
      this.inited = true;
      this.hasExtraDisplay = false;
      this.displayReady = false;

      if (!clientUtil.isChromeClient()) {
         //@ts-ignore
         this.request = new window.PresentationRequest(url);
      }
      if (!this.request || typeof this.request.getAvailability !== "function") {
         Logger.info("This browser can't support multimon");
         return;
      }

      this.request
         .getAvailability()
         .then((availability) => {
            if (!availability) {
               this.handleAvailabilityChange(false);
               return;
            }
            // availability.value may be kept up-to-date by the controlling UA as long
            // as the availability object is alive. It is advised for the Web developers
            // to discard the object as soon as it's not needed.
            this.handleAvailabilityChange(availability.value);
            availability.onchange = () => {
               this.handleAvailabilityChange(availability.value);
            };
         })
         .catch(() => {
            // Availability monitoring is not supported by the platform, so discovery of
            // presentation displays will happen only after request.start() is called.
            // Pretend the devices are available for simplicity; or, one could implement
            // a third state for the button.
            Logger.info("error for checking Availability of extended monitor, pretend it can work");
            this.handleAvailabilityChange(true);
         });
   };

   /**
    * [description]
    * @param  {[type]} data [description]
    * @return {[type]}      [description]
    */
   public sendToDisplay = (data: Monitor.MonitorMessage): boolean => {
      if (!this.inited) {
         Logger.error("displayService had not been inited");
         return false;
      }
      if (!this.displayReady) {
         Logger.debug("display is not ready");
         return false;
      }
      try {
         this.connection.send(JSON.stringify(data));
         return true;
      } catch (e) {
         Logger.info("failed sending data to extended monitor");
         return false;
      }
   };

   /**
    * When user reject or ignore the browser permission asking for using another monitor
    * @param  {object} e Error object
    */
   public onConnectionRejected = (e) => {
      this.onDisplayDisconnected(false);
   };

   /**
    * Try to entend the webapp to another display
    */
   public entendDisplay = () => {
      if (!this.inited) {
         Logger.error("displayService had not been inited");
         return;
      }
      if (!this.hasExtraDisplay) {
         Logger.info("detect no extended monitor, can't extend display");
         return;
      }
      if (this.displayReady) {
         Logger.info("display is already ready");
         return;
      }
      // Start new presentation.
      this.request
         .start()
         // The this.connection to the presentation will be passed to setConnection on
         // success.
         .then(this.setConnection)
         .catch(this.onConnectionRejected);
      // Otherwise, the user canceled the selection dialog or no screens were
      // found.
   };

   /**
    * After geting control over entended monitor,
    * this function would start using it
    * @param  {object} newConnection The connection object returned by browser
    *                                where onconnect, onmessage, onclose, onterminate
    *                                should be defined.
    */
   public setConnection = (newConnection) => {
      if (!this.inited) {
         Logger.error("displayService had not been inited");
         return;
      }
      // Disconnect from existing presentation, if not attempting to reconnect
      if (this.connection && this.connection !== newConnection && this.connection.state !== "closed") {
         this.connection.onclosed = undefined;
         this.connection.close();
      }

      this.connection = newConnection;

      // Monitor the this.connection state
      this.connection.onconnect = (_) => {
         this.onDisplayConnected();

         // Register message handler
         this.connection.onmessage = (message) => {
            this.onDisplayMessage(message);
         };
      };

      this.connection.onclose = (_) => {
         this.connection = null;
         this.onDisplayDisconnected(true);
      };

      this.connection.onterminate = (_) => {
         this.connection = null;
         this.onDisplayDisconnected(false);
      };
   };

   /**
    * stop using extended monitor
    */
   public close = () => {
      if (this.connection) {
         this.connection.terminate();
      }
   };
}
