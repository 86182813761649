/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { PipeTransform, Pipe } from "@angular/core";
import { AB } from "../../desktop/common/appblast-util.service";

@Pipe({
   name: "availableItemsFilter",
   pure: false
})
export class AvailableItemsPipe implements PipeTransform {
   constructor() {}
   transform(availableItems, query, onlyFavorites) {
      const getAvailableItem = (availableItem) => {
         if (!AB.itemNameContainsQuery(availableItem, query) || (onlyFavorites && !availableItem.favorite)) {
            return false;
         } else {
            return true;
         }
      };
      return availableItems.filter(getAvailableItem);
   }
}
