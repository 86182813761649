/**
 * ******************************************************
 * Copyright (C) 2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * timer-util.js --
 *
 *      common functions for idle timeout.
 *
 */

import Logger from "../../../core/libs/logger";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import util from "../util";
import GetTunnelConnectionHandler from "../controllers/getTunnelConnectionHandler";
import { StateEnum } from "../controllers/messageHandler";

/**
 * The method the jscdk can get last user active time from detecting layer
 *
 * The last active time will be returned using async method in
 * timerCtrlCommand
 *
 */
function requestLastActiveTime(ActionName) {
   const requestLastUserActiveTimeAction: any = {};

   if (!util.brokerSupportApplication()) {
      return;
   }
   Logger.debug("request last active time for:" + ActionName);
   requestLastUserActiveTimeAction.name = ActionName;
   JSCDKSetUI(JSON.stringify(requestLastUserActiveTimeAction));
}

/**
 * The method the jscdk can get last user active time from Portal page,
 * i.e. the page before enter desktop page or applications page for both
 * Client.
 */
export function requestLastActiveTimeFromPortal() {
   requestLastActiveTime("RequestLastUserActiveTime");
}

/**
 * The method the jscdk can get last user active time from desktop page
 * or
 * applications page for WebClient
 *
 * currently is identical to requestLastActiveTimeFromUI, 'Cause the
 * redirection
 */
export function requestLastActiveTimeFromRemoteSession() {
   requestLastActiveTime("RequestLastUserActiveTime");
}

export function prepareForAlreadyAuthenticated() {
   let getDesktopsObject, getLaunchItemsObject, getTunnelConnObject;

   if (!globalArray) {
      return;
   }
   /*
    * getDesktops or getLaunchItems depends on getTunnelConnection, but
    * getTunnelConnection's state will be set to StateEnum.DONE in the
    * successive code segment, thus getDesktops or getLaunchItems behaves
    * wrongly to update UI to turn to desktop/items select page. We need to
    * reset data for getDesktops or getLaunchItems to avoid such behavior.
    */
   Logger.debug("prepare for already authenticated");
   getDesktopsObject = globalArray["get-desktops"];
   if (getDesktopsObject) {
      getDesktopsObject.resetData();
   }

   getLaunchItemsObject = globalArray["get-launch-items"];
   if (getLaunchItemsObject) {
      getLaunchItemsObject.resetData();
   }

   // Get GetTunnelConnectionHandler
   getTunnelConnObject = globalArray["get-tunnel-connection"];
   if (getTunnelConnObject) {
      // Update the state of GetTunnelConnectionHandler to DONE to fake
      // sending success
      getTunnelConnObject.setState(StateEnum.DONE);
   } else {
      // The existence of GetTunnelConnectionHandler
      // is required when already authenticated.
      getTunnelConnObject = new GetTunnelConnectionHandler();
      globalArray[getTunnelConnObject.messageName] = getTunnelConnObject;
      globalArray[getTunnelConnObject.responseTag] = getTunnelConnObject;
      // Update the state of GetTunnelConnectionHandler to DONE to fake
      // sending success
      getTunnelConnObject.setState(StateEnum.DONE);
   }
}
