/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { EventBusService, Logger, BusEvent } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class LoginModel {
   public isLoginInitialized: boolean = false;
   public certAuth: boolean = false;
   constructor(eventBusService: EventBusService) {
      Logger.info("init isLoginInitialized as false");
      eventBusService.listen(BusEvent.CertAuth.MSG_TYPE).subscribe((msg: BusEvent.CertAuth) => {
         this.certAuth = msg.enable;
      });
   }
}
