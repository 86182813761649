/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * securIDPinChangeHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler for securIDPinChange
 *      authentication.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function SecurIDPinChangeHandler() {
   let router;
   // member variables below
   this.messageName = "securid-pinchange";
   this.messageText = "securid-pinchange";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
SecurIDPinChangeHandler.prototype = new MessageHandler();
// constructor
SecurIDPinChangeHandler.constructor = SecurIDPinChangeHandler;

/**
 * Set the request XML for securIDPinChange authentication.
 *
 * @param pin1 [in] new PIN input
 * @param pin2 [in] comfirm PIN input
 */

SecurIDPinChangeHandler.prototype.setRequestXML = function (pin1, pin2) {
   let pin1Text;
   let pin2Text;
   let paramText;
   pin1Text = util.createElement("name", "pin1");
   pin1Text += util.createElement("values", util.createElement("value", pin1));
   pin2Text = util.createElement("name", "pin2");
   pin2Text += util.createElement("values", util.createElement("value", pin2));
   paramText = util.createElement("param", pin1Text);
   paramText += util.createElement("param", pin2Text);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", this.messageName);
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
