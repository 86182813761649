/**
 * ******************************************************
 * Copyright (C) 2016-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * restartDesktopHandler.js --
 *
 *      Implementation of the message handler to restart desktop.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";

export default function RestartDesktopHandler() {
   let router;

   // member variables below
   this.messageName = "perform-action";
   this.messageText = "perform-action";
   this.responseTag = "perform-action";
   this.composedHandlerList = [];
   // requestId will increase when a new instance is created
   // use prototype requestId to share in all instances
   RestartDesktopHandler.prototype.requestId += 1;

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
}

// inherits MessageHandler prototype
RestartDesktopHandler.prototype = new MessageHandler();
// constructor
RestartDesktopHandler.constructor = RestartDesktopHandler;

/**
 * parse information from the response XML of restart-desktop
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
RestartDesktopHandler.prototype.parseResult = function (responseXML) {
   const responseBody = responseXML;
   let brokerTag;
   let responses;
   let result;
   let tokenText;
   const restartDesktopResponse = {};
   let xmlDoc;

   xmlDoc = $(responseXML);
   brokerTag = $(xmlDoc.children()[0]);
   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(restartDesktopResponse, nameText, $(this).text());
      });
   }
   return restartDesktopResponse;
};

/**
 * Set the request XML for restart-desktop
 * @param desktopId[in] desktop id to restart
 *
 */
RestartDesktopHandler.prototype.setRequestXML = function (desktopId) {
   const idElem = util.createElement("id", desktopId),
      typeElem = util.createElement("type", "restart"),
      content = idElem + typeElem,
      desktopElem = util.createElement("desktop", content);

   this.requestXML = desktopElem;
};

/**
 * callback when received notification from handlers in dependency list or
 * router
 *
 */
RestartDesktopHandler.prototype.onUpdated = function () {
   let restartDesktopAction;

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.state === StateEnum.DONE) {
      if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
         Logger.debug("Restart desktop succeeds.");

         // Kill session succeeds
         restartDesktopAction = { name: "RestartDesktop", result: "ok" };
         JSCDKSetUI(JSON.stringify(restartDesktopAction));
      }
   }

   // push error if it exists
   Router.prototype.pushErrorToUser(this);
};
