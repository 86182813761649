/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable, Optional } from "@angular/core";
import { ClientSettingModel } from "../model/client-setting-model";
import { HTML5MMR_CONST } from "../../desktop/channels/html5MMR-client/html5MMR-consts";
import Logger from "../../../core/libs/logger";
import { EventBusService, BusEvent } from "../../../core/services/event/index";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { clientUtil } from "@html-core";
import { ConnectedMessageService } from "../../../chrome-client/base/service/connected-message.service";
import { GeolocationNotification } from "./geolocation-notification";

@Injectable()
export class GeolocationService {
   private lastGeolocationPosition: GeolocationPosition = null;
   private geoLocationWatchId: number = null;
   private localStorageService: LocalStorageService = null;
   private subscribers = new Set();
   private unityWindowReady: boolean = false;
   private initConnectionCalled: boolean = false;

   constructor(
      private clientSettingModel: ClientSettingModel,
      private eventBusService: EventBusService,
      private geolocationNotification: GeolocationNotification,
      @Optional()
      private connectedMessageService: ConnectedMessageService
   ) {
      this.localStorageService = new LocalStorageService();
      this.subscribeToOnUnityReady();
      this.subscribeToStopGeolocationService();
   }

   public subscribeToStopGeolocationService = () => {
      this.eventBusService.listen(BusEvent.StopGeolocationService.MSG_TYPE).subscribe(() => {
         Logger.info("StopGeolocationService message came, call resetState", Logger.GEOLOCATION);
         this.resetState();
      });
   };

   public subscribeToOnUnityReady = () => {
      this.eventBusService.listen(BusEvent.OnUnityReady.MSG_TYPE).subscribe(() => {
         this.unityWindowReady = true;
         Logger.info(
            "OnUnityReady message came to GeolocationService, we can show geo pop-up now.",
            Logger.GEOLOCATION
         );
      });
   };

   public shouldBeAbleToSubscribe = (): Promise<void> => {
      return clientUtil.isChromeClient() || clientUtil.isChromium()
         ? this.maybeShowPermissionPrompt()
         : new Promise((resolve, reject) => {
              navigator.permissions.query({ name: "geolocation" }).then((result) => {
                 result.state == "granted" || result.state == "prompt" ? resolve() : reject();
              });
           });
   };

   private isUnityWindowReady = (timout: number): Promise<void> => {
      const obj = this;
      return new Promise((resolve, reject) => {
         const end_time = Date.now() + timout;
         function checkFlag() {
            if (obj.unityWindowReady === true) {
               resolve();
            } else if (Date.now() > end_time) {
               reject();
            } else {
               window.setTimeout(checkFlag, 200);
            }
         }
         checkFlag();
      });
   };

   /**
    * showGeoPromptForRemoteAppInChromeBook
    * @param sessionKey : string
    * @return Promise
    *
    * this function is only for Remote App session in Chromebook
    * When the geo notification dialog appears on top of a remote app
    * Some of it's area gets clipped. It's a known issue.
    * To fix this, make the parent window of geo pop-up as main window
    * Open the geo Pop-up only when the unity window is ready.
    * Wait for 15 secs for the unity window to get ready.
    * Case 1- Once unity window is ready send 'geoPermissionPopup' message
    * to session window to display geo pop-up.
    * Case 2- If unity window is not ready, return reject from this function and
    * do not show the geo location pop-up.
    */
   public showGeoPromptForRemoteAppInChromeBook(sessionKey: string): Promise<void> {
      if (!this.connectedMessageService) {
         Logger.info("This condition inside Geolocation should not hit.", Logger.GEOLOCATION);
         return Promise.reject();
      }
      if (this.showGeolocationPermissionPrompt() === true) {
         Logger.info(
            "Show geolocation permission pop-up for Chrome remote App session on top of main window",
            Logger.GEOLOCATION
         );
         this.isUnityWindowReady(15000)
            .then(() => {
               if (this.initConnectionCalled === false) {
                  this.connectedMessageService.initConnection(sessionKey, "geoPermissionPopup");
                  this.initConnectionCalled = true;
               }
               Logger.info("geoPermissionPopup message sent to session management.");
               this.connectedMessageService.sendMessage(sessionKey, "geoPermissionPopup", {
                  type: "showGeoPermissionPopup",
                  wmksKey: sessionKey
               });
               this.setGeolocationDialogAppearedOnce(true);
            })
            .catch(() => {
               Logger.error("Issue in Unity window to get ready can't who GeoNotification", Logger.GEOLOCATION);
            });
         //geoPermissionPopup message will call startGpsUpdate if geo sharing is allowed, so return reject from here.
         return Promise.reject();
      } else if (this.getGeolocationSharingEnabled()) {
         Logger.info("No need to show geo popup and Geo location sharing is enabled.", Logger.GEOLOCATION);
         return Promise.resolve();
      }
      return Promise.reject();
   }

   public maybeShowPermissionPrompt = (): Promise<void> => {
      // Geo pop-up can appear max once per client session.
      if (this.showGeolocationPermissionPrompt() === true) {
         Logger.info("Showing geolocation prompt.", Logger.GEOLOCATION);
         this.setGeolocationDialogAppearedOnce(true);
         return this.geolocationNotification.showGeolocationPermissionPopup();
      }
      // Start gps update only when geo sharing is enabled
      else if (this.getGeolocationSharingEnabled()) {
         return Promise.resolve();
      }
      return Promise.reject();
   };

   public setGeolocationDialogAppearedOnce = (appeared: boolean): void => {
      Logger.info(
         "Setting geolocation prompt appeared once flag to: " + (appeared ? "true" : "false"),
         Logger.GEOLOCATION
      );
      this.localStorageService.set("geolocationDialogAppearedOnce", appeared ? "true" : "false");
   };

   public getGeolocationDialogAppearedOnce = (): boolean => {
      return this.localStorageService.get("geolocationDialogAppearedOnce") === "true";
   };

   public showGeolocationPermissionPrompt = (): boolean => {
      return this.getGeolocationDialogAppearedOnce() === false && this.getDonotShowGeolocationDialog() === false;
   };

   public getDonotShowGeolocationDialog = (): boolean => {
      const enabled = this.clientSettingModel.getBooleanItem("donotShowGeolocationDialog");
      return enabled;
   };

   public getGeolocationSharingEnabled = (): boolean => {
      const enabled = this.clientSettingModel.getBooleanItem("enableGeolocationSharing");
      return enabled;
   };

   public resetLastGeolocationPosition = () => {
      this.lastGeolocationPosition = null;
   };

   public resetState = (): void => {
      Logger.info("Clearing state during logtout of webclient.", Logger.GEOLOCATION);
      this.lastGeolocationPosition = null;
      this.subscribers.clear();
      this.stopWatchingGeoLocation();
      this.localStorageService.remove("geolocationDialogAppearedOnce");
   };

   public getLastGeolocationPosition = (): GeolocationPosition => {
      return this.lastGeolocationPosition;
   };

   /**
    * subscribe
    * @param client : Html5MMRChan.Client
    * @return boolean
    *
    * Max there will be only one running instance of watchPosition API
    * When there are One or more subscribers, it will get trigerred.
    * When there are no subscribers, stopWatchingGeoLocation will get called
    * to stop watching geolocation.
    */
   public subscribe = (subscriberId: any): void => {
      if (this.subscribers.has(subscriberId) === true) {
         Logger.info(subscriberId + " already subscribed to geoservice", Logger.GEOLOCATION);
      } else {
         this.subscribers.add(subscriberId);
         Logger.info(
            "Added new subscriber " + subscriberId + " for geolocation. Subscriber count = " + this.subscribers.size,
            Logger.GEOLOCATION
         );
         if (this.geoLocationWatchId === null) {
            this.startWatchingGeoLocation();
         }
      }
   };

   /**
    * unsubscribe
    * @param client : Html5MMRChan.Client
    * @return None
    *
    * Client can call unsubscribe to stop getting geolocation events.
    * In case there are no clients, subscribed to geolocation
    * stopWatchingGeoLocation will get called to stop watching geolocation.
    */
   public unsubscribe(subscriberId: any) {
      if (this.subscribers.has(subscriberId) === true) {
         this.subscribers.delete(subscriberId);
         Logger.info(
            "Removed subscriber " + subscriberId + " from geolocation. Subscriber count = " + this.subscribers.size,
            Logger.GEOLOCATION
         );
         if (this.subscribers.size === 0) {
            Logger.info("No subscriber exists for geolocation, calling clearWatch api.", Logger.GEOLOCATION);
            this.stopWatchingGeoLocation();
         }
      } else {
         Logger.info("Subscriber has not subscribed to geolocationservice", Logger.GEOLOCATION);
      }
   }

   /**
    * startWatchingGeoLocation
    * @param None
    * @return None
    *
    * callbacks notifyAllSubscribers if successful
    * callbacks handleGeoError in case of exception
    */
   private startWatchingGeoLocation = () => {
      if (navigator.geolocation) {
         this.stopWatchingGeoLocation();
         this.geoLocationWatchId = navigator.geolocation.watchPosition(this.notifyAllSubscribers, this.handleGeoError, {
            timeout: HTML5MMR_CONST.GPSREDIR_WATCH_TIMEOUT,
            enableHighAccuracy: false,
            maximumAge: HTML5MMR_CONST.GPSREDIR_MAXIMUMAGE
         });
         Logger.info("Started watching geo location with id = " + this.geoLocationWatchId, Logger.GEOLOCATION);
      } else {
         Logger.error("Client does not support Geolocation.", Logger.GEOLOCATION);
      }
   };

   /**
    * Error handler for API watchPosition
    * @param {GeolocationPositionError} positionError
    *
    */
   private handleGeoError = (positionError: GeolocationPositionError) => {
      this.stopWatchingGeoLocation();
      switch (positionError.code) {
         case GeolocationPositionError.TIMEOUT:
            Logger.error("Geolocation time out happened.", Logger.GEOLOCATION);
            break;
         case GeolocationPositionError.PERMISSION_DENIED:
            Logger.error("Geolocation permission denied.", Logger.GEOLOCATION);
            break;
         case GeolocationPositionError.POSITION_UNAVAILABLE:
            Logger.error("Geolocation is unavailable.", Logger.GEOLOCATION);
            break;
         default:
            Logger.error("Unknown error occured while getting Geolocation.", Logger.GEOLOCATION);
            break;
      }
   };

   /**
    * stopWatchingGeoLocation
    *
    * In any of the three conditions
    * 1)There are no subscribers to the geolocation
    * 2)watchPosition throws error
    * 3)USer logged out
    * stop watching geolocation & clear all states
    * @param None
    * @returns None
    */
   private stopWatchingGeoLocation() {
      if (navigator.geolocation && this.geoLocationWatchId != null) {
         Logger.info("Stopped watching geo location with id = " + this.geoLocationWatchId, Logger.GEOLOCATION);
         navigator.geolocation.clearWatch(this.geoLocationWatchId);
      } else {
         Logger.info("No need to call clearWatch.", Logger.GEOLOCATION);
      }
      this.geoLocationWatchId = null;
   }

   /**
    * notifyAllSubscribers
    * Notify all subscriberes about the updates geolocation
    *
    * Many times firefox keeps on sending gps location for same position.
    * Donot send dispatch event if location are same till 6 decimal points.
    * @param  {GeolocationPosition} position
    * Current Geo position of the Client.
    */
   private notifyAllSubscribers = (position: GeolocationPosition) => {
      if (position !== null && position.coords !== undefined) {
         if (this.isSameGeolocation(position, this.lastGeolocationPosition)) {
            Logger.info("Same geolocation sent by API is ignored.", Logger.GEOLOCATION);
            return;
         }
         Logger.info("New geoposition is sent to all subscribers", Logger.GEOLOCATION);
         this.lastGeolocationPosition = position;
         this.eventBusService.dispatch(new BusEvent.GeolocationInfo(position));
      }
   };

   /**
    * isSameGeolocation
    * Check whether both the GEO location are nearly same
    * Sometimes firefox gives 14 decimal points for location, or keeps sending frequent location.
    * 6 decimal points are enough to get precise location.
    * https://en.wikipedia.org/wiki/Decimal_degrees
    * So ignore repeatative calls returned by Firefox.
    * @param  Two sets of {GeolocationPosition} position
    * @return boolean
    */
   private isSameGeolocation = (current: GeolocationPosition, prev: GeolocationPosition): boolean => {
      if (prev === null || prev.coords === null || prev.coords === undefined) {
         return false;
      }
      const cLatitude = current.coords.latitude !== undefined ? current.coords.latitude.toFixed(6) : 0;
      const cLongitude = current.coords.latitude !== undefined ? current.coords.longitude.toFixed(6) : 0;

      const pLatitude = current.coords.latitude !== undefined ? current.coords.latitude.toFixed(6) : 0;
      const pLongitude = current.coords.latitude !== undefined ? current.coords.longitude.toFixed(6) : 0;

      const pAltitude = current.coords.latitude !== undefined ? current.coords.altitude : 0;
      const cAltitude = current.coords.latitude !== undefined ? current.coords.altitude : 0;

      return cLatitude === pLatitude && cLongitude === pLongitude && pAltitude === cAltitude;
   };
}
