/**
 * ******************************************************
 * Copyright (C) 2012-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * doSubmitAuthenticationHandler.js --
 *
 *      Implementation of the message handler to submit authentication.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import WinCredsHandler from "./winCredsHandler";
import SecurIDPasscodeHandler from "./securIDPasscodeHandler";
import UnauthHandler from "./unauthHandler";
import UnauthSolutionHandler from "./unauthSolutionHandler";
import SecurIDNextTokenCodeHandler from "./securIDNextTokenCodeHandler";
import SecurIDPinChangeHandler from "./securIDPinChangeHandler";
import SecurIDWaitHandler from "./securIDWaitHandler";
import DisclaimerHandler from "./disclaimerHandler";
import ChangeWindowsPasswordHandler from "./changeWindowsPasswordHandler";
import SamlHandler from "./samlHandler";
import CertAuthHandler from "./certAuthHandler";
import GetDesktopsHandler from "./getDesktopsHandler";
import GetLaunchItemsHandler from "./getLaunchItemsHandler";
import GetTunnelConnectionHandler from "./getTunnelConnectionHandler";
import IdleTimeoutTimerController from "../timer/idleTimeoutTimerController";
import { PartialError } from "../jscdk-interface";
import CsrfTokenService from "../model/csrf-token.service";
import BrokerSessionTimeoutTimerController from "../timer/brokerSessionTimeoutTimerController";
import OfflineSSOCacheTimerController from "../timer/offlineSSOCacheTimerController";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { CryptoKeyDerivationService } from "../../common/service/crypto-key-derivation.service";
import { GetFeatureConfigurationsHandler } from "./GetFeatureConfigurationsHandler";
import { clientUtil, setOnRampMode } from "../../../core/libs";

export default function DoSubmitAuthenticationHandler() {
   let getConfigurationObject, router;

   // member variables below
   this.messageName = "do-submit-authentication";
   this.messageText = "do-submit-authentication";
   this.responseTag = "submit-authentication";

   this.authAction = {};
   this.reAuthFlag = false;
   // Cache the credential handler, clear it after sending login info
   this.credsHandler = null;

   // register dependencies below
   getConfigurationObject = util.getObject(globalArray, "get-configuration");
   if (getConfigurationObject) {
      this.registerHandler(getConfigurationObject, "state");
   }
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
DoSubmitAuthenticationHandler.prototype = new MessageHandler();
// constructor
DoSubmitAuthenticationHandler.constructor = DoSubmitAuthenticationHandler;

DoSubmitAuthenticationHandler.prototype.setReAuthFlag = function (isReAuth) {
   this.reAuthFlag = isReAuth || false;
};

DoSubmitAuthenticationHandler.prototype.setAllowDataSharing = function (allowDataSharing) {
   // when in anonymous login, allowdatasharing won't pass again, so keep the first value
   if (allowDataSharing === undefined) {
      return;
   }
   this.allowDataSharing = !!allowDataSharing;
};

DoSubmitAuthenticationHandler.prototype.setDesktopReAuthFlag = function (isDesktopReAuth) {
   this.desktopReAuthFlag = isDesktopReAuth || false;
};

/**
 * Set the authentication action JSON.
 * @param action[in] the authentication action.
 */

DoSubmitAuthenticationHandler.prototype.setAuthAction = function (action) {
   let handler;
   this.authAction = action;
   if (action.type === "WindowsPassword") {
      handler = util.getObject(globalArray, "windows-password");
      if (!handler) {
         handler = new WinCredsHandler();
      }
      handler.setRequestXML(action.domain, action.username, action.secret);
   } else if (action.type === "SecurIDPasscode") {
      handler = util.getObject(globalArray, "securid-passcode");
      if (!handler) {
         handler = new SecurIDPasscodeHandler();
      }
      handler.setRequestXML(action.username, action.secret);
   } else if (action.type === "SecurIDNextTokenCode") {
      handler = util.getObject(globalArray, "securid-nexttokencode");
      if (!handler) {
         handler = new SecurIDNextTokenCodeHandler();
      }
      handler.setRequestXML(action.secret);
   } else if (action.type === "SecurIDPinChange") {
      handler = util.getObject(globalArray, "securid-pinchange");
      if (!handler) {
         handler = new SecurIDPinChangeHandler();
      }
      handler.setRequestXML(action.pin1, action.pin2);
   } else if (action.type === "SecurIDWait") {
      handler = util.getObject(globalArray, "securid-wait");
      if (!handler) {
         handler = new SecurIDWaitHandler();
      }
      handler.setRequestXML();
   } else if (action.type === "Disclaimer") {
      handler = util.getObject(globalArray, "disclaimer");
      if (!handler) {
         handler = new DisclaimerHandler();
      }
      handler.setRequestXML();
   } else if (action.type === "WindowsPasswordExpired") {
      handler = util.getObject(globalArray, "windows-password-expired");
      if (!handler) {
         handler = new ChangeWindowsPasswordHandler();
      }
      handler.setRequestXML(action.oldPassword, action.newPassword1, action.newPassword2);
   } else if (action.type === "SAML") {
      handler = util.getObject(globalArray, "saml");
      if (!handler) {
         handler = new SamlHandler();
      }
      handler.setRequestXML(action.secret);
   } else if (action.type === "CertAuth") {
      handler = util.getObject(globalArray, "cert-auth");
      if (!handler) {
         handler = new CertAuthHandler();
      }
      handler.setRequestXML(action.accept);
   } else if (action.type === "Unauthenticated") {
      handler = util.getObject(globalArray, "unauthenticated");
      if (!handler) {
         handler = new UnauthHandler();
      }
      handler.setRequestXML(action.username);
   } else if (action.type === "UnauthenticatedSolution") {
      handler = util.getObject(globalArray, "UnauthenticatedSolution");
      if (!handler) {
         handler = new UnauthSolutionHandler();
      }
      handler.setRequestXML(action.solutionList);
   }

   if (!globalArray[handler.messageText]) {
      globalArray[handler.messageText] = handler;
   }
   if (!this.subHandlerList[handler.messageText]) {
      this.subHandlerList[handler.messageText] = handler;
   }

   if (handler) {
      this.requestXML = handler.requestXML;
      this.credsHandler = handler.messageName;
   } else {
      Logger.error("Sub handler for authentication is not defined!");
   }
};

/**
 * parse the response of doSubmitAuthentication
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */

DoSubmitAuthenticationHandler.prototype.parseResult = function (responseXML) {
   let authResponse = {},
      brokerTag,
      result,
      xmlDoc = $(responseXML),
      responses,
      screenXML,
      partialError = {} as PartialError,
      csrfToken,
      gatewayNoModify,
      onRampEnabled,
      csrfTokenService = globalArray["csrf-token-service"];

   brokerTag = $(xmlDoc.children()[0]);

   if (!brokerTag) {
      Logger.error("response of doSubmitAuthentication error");
      return null;
   }

   if (!csrfTokenService) {
      csrfTokenService = new CsrfTokenService();
      globalArray[csrfTokenService.globalName] = csrfTokenService;
   }

   responses = brokerTag.children(this.responseTag);
   csrfToken = responses.children("csrf-token");
   gatewayNoModify = responses.children("gateway-no-modify");

   if (gatewayNoModify.length > 0) {
      gatewayNoModify.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(authResponse, nameText, $(this).text());
      });

      onRampEnabled = gatewayNoModify.children("on-ramp-enabled").text();
      if (onRampEnabled === "true" && !clientUtil.isChromeClient()) {
         setOnRampMode(true);
      }
   } else {
      if (!clientUtil.isChromeClient()) {
         setOnRampMode(false);
      }
   }

   if (csrfToken.length > 0) {
      csrfTokenService.storeCsrfToken(csrfToken.text());
   } else {
      if (csrfTokenService.isCsrfEnforced()) {
         const csrfCheckFailed = { name: "csrfCheckFailed", result: "ok" };
         JSCDKSetUI(JSON.stringify(csrfCheckFailed));
         Logger.debug("No csrfToken included in response.");
         return;
      }
   }

   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(authResponse, nameText, $(this).text());
      });
   } else if (result === "partial") {
      /*
       * For the "partial" result, different dialogues will be shown to user
       * depending on different response types. Here calling the sub-handler's
       * parseResult function to parse them.
       */
      screenXML = responses.find("screen");
      util.addItemForJson(partialError, "screen", $(screenXML).children("name").text());
      $(screenXML)
         .children("params")
         .children("param")
         .each(function () {
            const child = $(this),
               nameText = child.find("name").text(),
               isReadOnly = child.find("readonly").length,
               valueList = [];
            if (isReadOnly !== 0) {
               util.addItemForJson(partialError, nameText + "ReadOnly", "yes");
            } else {
               util.addItemForJson(partialError, nameText + "ReadOnly", "no");
            }
            child.find("value").each(function () {
               // Special handle for unauthenticated login
               if (partialError.screen === "unauthenticated" && nameText === "username") {
                  valueList.push({
                     value: $(this).text(),
                     default: !!$(this).attr("default")
                  });
               } else if (partialError.screen === "clientpuzzle" && nameText === "puzzles") {
                  valueList.push({
                     value: $(this).text(),
                     hash: $(this).attr("hash")
                  });
               } else {
                  valueList.push($(this).text());
               }
            });
            // If user input domain\username, adjust the username
            if (partialError.screen === "windows-password" && nameText === "username") {
               const winCredsHandler = util.getObject(globalArray, "windows-password");
               if (!!winCredsHandler && !!winCredsHandler.cachedDomainUsername) {
                  valueList[0] = winCredsHandler.cachedDomainUsername;
               }
            }
            util.addItemForJson(partialError, nameText, valueList);
         });

      util.addItemForJson(authResponse, "result", result);
      $.extend(authResponse, partialError);
   }
   return authResponse;
};

/**
 * Do the desktops list connections after the authentication is done.
 *
 */
DoSubmitAuthenticationHandler.prototype.onAuthenticationDone = function () {
   let handlerList = [],
      getDesktopsObject,
      getLaunchItemsObject,
      addClientInfoObject,
      getTunnelConnObject,
      protocolsJson = [],
      supportedAppProtocols,
      type,
      router = globalArray["router"],
      idleTimerObject,
      parsedResult,
      userActivityInterval,
      offlineSSOCacheTimeout,
      doReAuthAction,
      idleTimeout,
      sessionTimeout,
      onRampEnabled,
      getConfigurationObject = globalArray["get-configuration"],
      brokerSessionTimeoutTimerController = globalArray["broker-session-timeout-timer"],
      offlineSSOCacheTimeoutTimerController = globalArray["offline-sso-cache-timer"];

   //clear Timeout for get configuration
   if (getConfigurationObject.getConfigurationTimeout != null) {
      clearTimeout(getConfigurationObject.getConfigurationTimeout);
      getConfigurationObject.getConfigurationTimeout = null;
      getConfigurationObject.resendGetConfiguration = false;
   }

   const localStorageService = new LocalStorageService();
   localStorageService.set("geolocationDialogAppearedOnce", "false");
   localStorageService.set("mediastreamDialogAppearedOnce", "false");

   if (
      !this.reAuthFlag && // not re-auth
      clientUtil.isChromeClient() && // only support chrome client
      util.brokerSupportUrlContentRedirection() // broker support URL redirection feature
   ) {
      // Call the get-feature-configurations XML-API service after authentication
      this.postMessageOfGetFeatureConfigurations();
   }

   parsedResult = this.content["parsedResult"];
   if (!this.reAuthFlag) {
      sessionTimeout = parseInt(parsedResult["max-broker-session-time"], 10); //in s
      if (!!sessionTimeout && sessionTimeout > 0) {
         sessionTimeout = sessionTimeout * 1000; //in ms
         Logger.debug("get broker session target time from xml: " + sessionTimeout);
         if (!brokerSessionTimeoutTimerController) {
            brokerSessionTimeoutTimerController = new BrokerSessionTimeoutTimerController();
            globalArray["broker-session-timeout-timer"] = brokerSessionTimeoutTimerController;
         }
         brokerSessionTimeoutTimerController.start(sessionTimeout); //in ms
      } else {
         Logger.debug("max-broker-session-time is not found in xml");
      }
   }

   if (!this.reAuthFlag) {
      // "add-client-info" work after broker>= 9.0
      if (this.allowDataSharing && util.brokerSupportApplication()) {
         // send addClientInfo request
         addClientInfoObject = globalArray["add-client-info"];
         if (!!addClientInfoObject && addClientInfoObject.hasOwnProperty("clientInfo")) {
            Logger.info("Send client info in do sumbmit authentication");
            addClientInfoObject.setRequestXML();
            if (router) {
               /**
                * [DPM-13251] Reduce TCP connection number by reducing http request numbers
                *  at the same time and better caching.
                * Push all object into handlerList and send them in one xml
                */
               handlerList.push(addClientInfoObject);
            }
         }
      } else {
         Logger.info("Don't send client info in do sumbmit authentication");
      }

      // send getTunnelConnection request
      getTunnelConnObject = util.getObject(globalArray, "get-tunnel-connection");
      if (!getTunnelConnObject) {
         getTunnelConnObject = new GetTunnelConnectionHandler();
         globalArray[getTunnelConnObject.messageName] = getTunnelConnObject;
         globalArray[getTunnelConnObject.responseTag] = getTunnelConnObject;
      } else {
         getTunnelConnObject.resetData();
      }

      getTunnelConnObject.setRequestXML(globalArray["bypassTunnel"]);
      if (!!getTunnelConnObject && !!router) {
         /**
          * [DPM-13251] Reduce TCP connection number by reducing http request numbers
          *  at the same time and better caching.
          * Push all object into handlerList and send them in one xml
          */
         handlerList.push(getTunnelConnObject);
      }
   }

   if (!util.brokerSupportApplication()) {
      // send getDesktops request
      getDesktopsObject = util.getObject(globalArray, "get-desktops");
      if (!getDesktopsObject) {
         getDesktopsObject = new GetDesktopsHandler();
      } else {
         getDesktopsObject.resetData();
      }
      globalArray[getDesktopsObject.messageName] = getDesktopsObject;
      globalArray[getDesktopsObject.responseTag] = getDesktopsObject;

      protocolsJson = util.getObject(globalArray, "supportedProtocols");

      //In desktop page, after reauth, post a unlock message to UI.
      if (this.reAuthFlag) {
         doReAuthAction = { name: "unLock", authType: this.authAction.type, result: "ok" };
         JSCDKSetUI(JSON.stringify(doReAuthAction));
      }

      if (!protocolsJson) {
         Logger.error("Supported protocols missing.");
         return;
      }

      getDesktopsObject.setRequestXML(protocolsJson);
      if (!!getDesktopsObject && !!router) {
         /**
          * [DPM-13251] Reduce TCP connection number by reducing http request numbers
          *  at the same time and better caching.
          * Push all object into handlerList and send them in one xml
          */
         handlerList.push(getDesktopsObject);
      }
   } else {
      //launch idle timer
      idleTimerObject = globalArray["idle-timeout-timer"];
      if (!idleTimerObject) {
         idleTimerObject = new IdleTimeoutTimerController();
         globalArray[idleTimerObject.globalName] = idleTimerObject;
      }
      userActivityInterval = parseInt(parsedResult["user-activity-interval"], 10);
      idleTimeout = parseInt(parsedResult["idle-timeout"], 10);
      //init every time user logs in, could only happen in the UI
      if (this.desktopReAuthFlag) {
         idleTimerObject.init(userActivityInterval, idleTimeout, "Desktop");
      } else {
         idleTimerObject.init(userActivityInterval, idleTimeout, "Portal");
      }
      idleTimerObject.ensureUpdatedOrStarted();

      //In desktop page, after reauth, post a unlock message to UI.
      if (this.reAuthFlag) {
         doReAuthAction = { name: "unLock", authType: this.authAction.type, result: "ok" };
         JSCDKSetUI(JSON.stringify(doReAuthAction));
      }

      // send getLaunchItems request
      getLaunchItemsObject = util.getObject(globalArray, "get-launch-items");
      if (!getLaunchItemsObject) {
         getLaunchItemsObject = new GetLaunchItemsHandler();
         globalArray[getLaunchItemsObject.messageName] = getLaunchItemsObject;
         globalArray[getLaunchItemsObject.responseTag] = getLaunchItemsObject;
      } else {
         getLaunchItemsObject.resetData();
      }

      protocolsJson = util.getObject(globalArray, "supportedProtocols");
      if (!protocolsJson) {
         Logger.error("Supported protocols missing.");
         return;
      }

      if (!!getLaunchItemsObject && !!router) {
         type = {};
         type.name = "remote";
         type.protocols = protocolsJson;
         supportedAppProtocols = [];
         supportedAppProtocols[0] = type;
         if (protocolsJson) {
            getLaunchItemsObject.setRequestXML(protocolsJson, supportedAppProtocols);
            /**
             * [DPM-13251] Reduce TCP connection number by reducing http request numbers
             *  at the same time and better caching.
             * Push all object into handlerList and send them in one xml
             */
            if (getLaunchItemsObject.composedHandlerList?.length > 1) {
               handlerList.push(...getLaunchItemsObject.composedHandlerList);
            } else {
               handlerList.push(getLaunchItemsObject);
            }
         } else {
            Logger.error("supported protocols is invalid.");
         }
      }
   }
   if (handlerList.length > 0) {
      router.postMessage(handlerList);
   }
   // To fix Bug 2214490, connect to application after do-submit-authentication successfully
   if (this.authAction.type === "CertAuth") {
      const certAuthSubmit = { name: "certAuthSubmit", result: "ok" };
      JSCDKSetUI(JSON.stringify(certAuthSubmit));
   }
   // Try to tear down calculation worker if auth done
   const puzzleHandler = util.getObject(globalArray, "unauthPuzzleHandler");
   if (puzzleHandler) {
      puzzleHandler.tearDownWorker();
   }

   offlineSSOCacheTimeout = parseInt(parsedResult["offline-sso-cache-timeout"], 10); // in min
   if (offlineSSOCacheTimeout > 0 && clientUtil.isChromeClient()) {
      offlineSSOCacheTimeout = offlineSSOCacheTimeout * 60000; //in ms
      Logger.debug("get discard sso timeout from xml: " + offlineSSOCacheTimeout);
      if (!offlineSSOCacheTimeoutTimerController) {
         offlineSSOCacheTimeoutTimerController = new OfflineSSOCacheTimerController();
         globalArray["offline-sso-cache-timer"] = offlineSSOCacheTimeoutTimerController;
      }
      offlineSSOCacheTimeoutTimerController.start(offlineSSOCacheTimeout);
   } else {
      Logger.debug("offline-sso-cache-timeout is not valid or suitable for this client");
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */

DoSubmitAuthenticationHandler.prototype.onUpdated = function () {
   this.decryptResult();

   let errorCode,
      parsedResult,
      getConfigurationObject = globalArray["get-configuration"];

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.content["parsedResult"]) {
      parsedResult = this.content["parsedResult"];
      if (parsedResult["error-code"]) {
         errorCode = parsedResult["error-code"];
         if (errorCode === "ALREADY_AUTHENTICATED") {
            this.setState(StateEnum.DONE);
         }
      }

      if (parsedResult["result"] === "partial") {
         if (getConfigurationObject) {
            /**
             * GetConfigurationHandler handles the authentication related
             * dialogs.
             */
            getConfigurationObject.handleParsedResult(this.content["parsedResult"], this.reAuthFlag);
         } else {
            Logger.error("Wrong case, no get-configuration but has do-submit-authentication");
         }
      }
   }
   // ensure handler has done its task and request is from "WindowsPassword" to
   // JSCDK
   if (this.state === StateEnum.DONE) {
      this.onAuthenticationDone();
   }
   // push error to UI
   Router.prototype.pushErrorToUser(this);
};

/**
 * This function is used to clear all the credential info stored in cache
 * It is requested from bug 1537589
 */
DoSubmitAuthenticationHandler.prototype.clearCreds = function () {
   if (this.credsHandler) {
      globalArray[this.credsHandler].requestXML = "";
   }
};

/**
 * Decrypt the XML-API protection data.
 */
DoSubmitAuthenticationHandler.prototype.decryptResult = function () {
   const parsedResult = this.content["parsedResult"];
   const cryptoKeyDerivationService = util.getObject(
      globalArray,
      "cryptoKeyDerivationService"
   ) as CryptoKeyDerivationService;

   if (!cryptoKeyDerivationService) {
      Logger.error("The CryptoKeyDerivationService missing.");
      return;
   }

   // If the screen of request is securid-passcode
   if (
      parsedResult &&
      parsedResult["result"] === "partial" &&
      parsedResult["screen"] === "securid-pinchange" &&
      parsedResult["pin1"] &&
      parsedResult["pin1"][0]
   ) {
      parsedResult["pin1"][0] = cryptoKeyDerivationService.decryptIfXmlApiDataProtected(parsedResult["pin1"][0]);
   }
};

/**
 * Call the get-feature-configurations XML-API service
 */
DoSubmitAuthenticationHandler.prototype.postMessageOfGetFeatureConfigurations = function () {
   let getFeatureConfigurationsObject: GetFeatureConfigurationsHandler =
      globalArray[GetFeatureConfigurationsHandler.MESSAGE_NAME];
   if (getFeatureConfigurationsObject) {
      getFeatureConfigurationsObject.resetData();
   } else {
      getFeatureConfigurationsObject = new GetFeatureConfigurationsHandler();
      globalArray[getFeatureConfigurationsObject.messageName] = getFeatureConfigurationsObject;
      globalArray[getFeatureConfigurationsObject.responseTag] = getFeatureConfigurationsObject;
   }

   if (globalArray["router"]) {
      getFeatureConfigurationsObject.setRequestXML();
      const handlerList = getFeatureConfigurationsObject.composeHandlerList();
      globalArray["router"].postMessage(handlerList);
   }
};
