/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable } from "@angular/core";
import { RdsLicenseInfoService } from "../../../shared/common/service/rds-license-info.service";

@Injectable({
   providedIn: "root"
})
export class HorizonRdsLicenseInfoService extends RdsLicenseInfoService {
   public getRdsLicense = () => {
      return "";
   };
   public isRdsLicenseEnabled = () => {
      return false;
   };
}
