/**
 * ******************************************************
 * Copyright (C) 2016-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * primary-monitor.service.ts -- primaryMonitorService
 *
 * service to control the primary monitor.
 * will write data into the model
 *
 *
 * in the detect mode, dispatch all events to other windows using model
 */

import { Injectable } from "@angular/core";
import Logger from "../../../../core/libs/logger";
import { BusEvent, clientUtil, EventBusService } from "@html-core";
import { FullscreenService } from "../../../../shared/utils/fullscreen-service";
import { WmksBaseService } from "../../../../shared/utils/wmks-base-service";
import { VNCDecoder } from "../common/vnc-decoder";
import { NormalizationService } from "../../../utils/normalization-service";
import { MonitorWatcherService } from "../common/monitor-watcher.service";
import { DisplayCheckService } from "../../common/display-check.service";
import { ClipboardPrimaryDesktopMonitorService } from "../../../../chrome-client/desktop/clipboard/common-clipboard-desktop-monitor.service";
import { UrlParameterService } from "../../../../chrome-client/desktop/blast-common/url-parameter.service";

@Injectable({
   providedIn: "root"
})
export class PrimaryMonitorService {
   private enabled = false;
   private primaryCanvas = null;
   private canvasContent = null;
   private offset = null;
   private conflictEventTime = 0;
   private browserSizeFactor = 1;
   private mksPaused = false;
   //A number indicate the button status, and 0 represent no button is being pressed.
   private buttonStatus = 0;
   private wmksSession = null;
   private detectTimer = null;
   private onRenderingDone = null;
   private fullscreenSubscribed = false;
   private modifiedScreenInfo = null;
   private notchHeight = 0;
   private lastRegion = null;
   private blockKeyEvents = false;

   constructor(
      private fullscreenService: FullscreenService,
      private wmksBaseService: WmksBaseService,
      private vncDecoder: VNCDecoder,
      private normalizationService: NormalizationService,
      private monitorWatcherService: MonitorWatcherService,
      private eventBusService: EventBusService,
      private displayCheck: DisplayCheckService,
      private clipboardDesktopService: ClipboardPrimaryDesktopMonitorService,
      private urlParameterService: UrlParameterService
   ) {
      this.eventBusService
         .listen(BusEvent.OnPrimaryScreenAPIWorkaroundApplied.MSG_TYPE)
         .subscribe(this.onAPIworkaroundApplied);
   }

   public setNotchHeight = (notchHeight) => {
      this.notchHeight = notchHeight;
   };

   public onAPIworkaroundApplied = (modifiedScreenInfoEvent: BusEvent.OnPrimaryScreenAPIWorkaroundApplied) => {
      this.modifiedScreenInfo = modifiedScreenInfoEvent.screenInfo;
   };
   /**
    * factor might be dpi_b or dpi_a*dpi_b
    * @param  {[type]} factor [description]
    * @return {[type]}        [description]
    */
   public onDPISettingChanged = (factor) => {
      if (this.primaryCanvas) {
         this.browserSizeFactor = factor;
         const height = this.displayCheck.getScreenHeightWithoutNotchHeight();
         if (
            this.primaryCanvas.width !== clientUtil.getScreenWidth() * this.browserSizeFactor ||
            this.primaryCanvas.height !== height * this.browserSizeFactor
         ) {
            this.primaryCanvas.width = clientUtil.getScreenWidth() * this.browserSizeFactor;
            this.primaryCanvas.height = height * this.browserSizeFactor;
            this.vncDecoder.onDPIChanged(this.primaryCanvas);
         }
      }
   };

   /**
    * @private
    * @param  {[type]} wmksSession [description]
    * @return {[type]}             [description]
    */
   public createPrimaryCanvas = (wmksSession) => {
      let primaryCanvas, canvasContainner;
      const height = this.displayCheck.getScreenHeightWithoutNotchHeight();
      canvasContainner = document.getElementById("canvas-container");
      primaryCanvas = document.createElement("canvas");
      primaryCanvas.id = "primary-monitor";
      primaryCanvas.width = clientUtil.getScreenWidth() * this.browserSizeFactor;
      primaryCanvas.height = height * this.browserSizeFactor;
      primaryCanvas.style.width = clientUtil.getScreenWidth() + "px";
      primaryCanvas.style.height = height + "px";
      primaryCanvas.style.zIndex = 8;
      canvasContainner.appendChild(primaryCanvas);
      primaryCanvas.oncontextmenu = function (e) {
         e.preventDefault();
      };
      /**
       * Detect on document instead of canvas to resolve drag outside the
       * browser issue for mousemove and mouseup;
       * Since only bind part of event on to document looks wired, bound all
       * onto it, and disable the default wmks handling to avoid bugs caused
       * by conflicts.
       */
      this.wmksSession = wmksSession;
      $(document).on("mousemove", this.onMouseMove);
      $(document).on("mousedown", this.onMouseDown);
      $(document).on("mouseup", this.onMouseUp);
      $(document).on("mousewheel", this.onMouseWheel);
      $(canvasContainner).on("touchstart", this.onTouchEvent);
      $(canvasContainner).on("touchend", this.onTouchEvent);
      $(canvasContainner).on("touchmove", this.onTouchEvent);
      if (clientUtil.isSeamlessMode()) {
         $(document).on("keyup", this.onKeyUp);
         $(document).on("keydown", this.onKeyDown);
         $(document).on("keypress", this.onKeyPress);
      } else {
         $(canvasContainner).on("keyup", this.onKeyUp);
         $(canvasContainner).on("keydown", this.onKeyDown);
         $(canvasContainner).on("keypress", this.onKeyPress);
      }

      $(window).on("blur", this.onBlur);
      $(window).on("focus", this.onFocus);

      this.primaryCanvas = primaryCanvas;
      this.buttonStatus = 0;
      this.vncDecoder.setCanvas(primaryCanvas);
      this.canvasContent = this.primaryCanvas.getContext("2d");
   };
   private onFocus = () => {
      if (clientUtil.isChromeClient() && this.urlParameterService.connectOptions?.delayKeysWhenMoveInside) {
         let timeOnFoucsCurrentWindow = new Date().getTime();
         this.clipboardDesktopService.blockKeyEventOnFoucs(timeOnFoucsCurrentWindow, this.setIgnoreAllKeys);
      }
   };

   private setIgnoreAllKeys = (value: boolean) => {
      this.blockKeyEvents = value;
   };

   /**
    * @private
    * @param  {object} event
    */
   public isPrimaryCanvasEvent = (event?: any) => {
      const evt = event || window.event;
      const element = evt.target || evt.srcElement;
      // Allow drag through the sidebar or panels.
      if (this.buttonStatus !== 0) {
         return true;
      } else {
         return element === this.primaryCanvas;
      }
   };

   /**
    * @private
    * @param  {object} event
    */
   public onBlur = (event) => {
      Logger.debug("focus leave primary monitor", Logger.DISPLAY);
      this.buttonStatus = 0;
      this.wmksSession.wmksContainer.wmks("onBlur");
      $(document).trigger("primarymonitoruse");
   };

   /**
    * @private
    * @param  {object} event
    */
   public onMouseMove = (event) => {
      if (this.mksPaused) {
         return;
      }
      let position;

      if (this.isPrimaryCanvasEvent()) {
         position = this.wmksBaseService.getEventPosition(event, this.offset);
         this.wmksSession.wmksContainer.wmks("sendMouseMoveMessage", position);
      }
      $(document).trigger("primarymonitoruse");
   };

   /**
    * @private
    * @param  {object} event
    */
   public onMouseDown = (event) => {
      if (this.mksPaused) {
         return;
      }
      let position, bmask;

      if (this.isPrimaryCanvasEvent()) {
         event.preventDefault();
         position = this.wmksBaseService.getEventPosition(event, this.offset);
         bmask = this.wmksBaseService.getMouseBmask(event);
         this.wmksSession.wmksContainer.wmks("sendMouseButtonMessage", position, 1, bmask);
         this.buttonStatus |= 1 << event.button;
      }
      $(document).trigger("primarymonitoruse");
   };

   /**
    * @private
    * @param  {object} event
    */
   public onMouseUp = (event) => {
      if (this.mksPaused) {
         return;
      }
      let position, bmask;

      if (this.isPrimaryCanvasEvent()) {
         event.preventDefault();
         position = this.wmksBaseService.getEventPosition(event, this.offset);
         bmask = this.wmksBaseService.getMouseBmask(event);
         this.wmksSession.wmksContainer.wmks("sendMouseButtonMessage", position, 0, bmask);
         this.buttonStatus &= ~(1 << event.button);
      }
      $(document).trigger("primarymonitoruse");
   };

   /**
    * @private
    * @param  {object} event
    */
   public onMouseWheel = (event) => {
      if (this.mksPaused) {
         return;
      }
      let position, dx, dy;

      if (this.isPrimaryCanvasEvent()) {
         position = this.wmksBaseService.getEventPosition(event, this.offset);
         dx = Math.max(Math.min(event.wheelDeltaX, 1), -1);
         dy = Math.max(Math.min(event.wheelDeltaY, 1), -1);
         this.wmksSession.wmksContainer.wmks("sendScrollMessageWithEvent", event, position);
      }
      $(document).trigger("primarymonitoruse");
   };

   /**
    * @private
    * @param  {object} event
    */
   public onKeyUp = (event) => {
      if (this.blockKeyEvents) {
         return;
      }
      this.onKeyEvent("KeyUp", event);
   };

   /**
    * @private
    * @param  {object} event
    */
   public onKeyDown = (event) => {
      if (this.blockKeyEvents) {
         return;
      }
      this.onKeyEvent("KeyDown", event);
   };

   /**
    * @private
    * @param  {object} event
    */
   public onKeyPress = (event) => {
      if (this.blockKeyEvents) {
         return;
      }
      this.onKeyEvent("KeyPress", event);
   };

   /**
    * @private
    * @param  {string} type
    * @param  {object} event
    */
   public onKeyEvent = (type, event) => {
      if (this.mksPaused) {
         return;
      }
      if (this.wmksBaseService.shouldPreventDefault(event)) {
         event.preventDefault();
      }
      event.stopPropagation();
      this.wmksSession.wmksContainer.wmks("sendKeyMessage", {
         type: type,
         event: event
      });
      $(document).trigger("primarymonitoruse");
   };

   /**
    * @private
    * @param  {object} event
    */
   public onTouchEvent = (event) => {
      event.preventDefault();
      const e = event.originalEvent;
      const positions = [];
      for (let i = 0; i < e.changedTouches.length; i++) {
         positions.push(this.wmksBaseService.getEventPosition(e.changedTouches[i], this.offset));
      }
      this.wmksSession.wmksContainer.wmks("onTouch", e, positions);
   };

   /**
    * @private
    * Destroy the primary canvas with the mks event for it and reconnect
    * default events back.
    *
    * Add a try catch block since the wmksSession might fail.
    */
   public destroyPrimaryCanvas = () => {
      const canvasContainner = document.getElementById("canvas-container");
      canvasContainner.removeChild(this.primaryCanvas);
      $(document).off("mousemove", this.onMouseMove);
      $(document).off("mousedown", this.onMouseDown);
      $(document).off("mouseup", this.onMouseUp);
      $(document).off("mousewheel", this.onMouseWheel);
      $(canvasContainner).off("touchstart", this.onTouchEvent);
      $(canvasContainner).off("touchend", this.onTouchEvent);
      $(canvasContainner).off("touchmove", this.onTouchEvent);
      if (clientUtil.isSeamlessMode()) {
         $(document).off("keyup", this.onKeyUp);
         $(document).off("keydown", this.onKeyDown);
         $(document).off("keypress", this.onKeyPress);
      } else {
         $(canvasContainner).off("keyup", this.onKeyUp);
         $(canvasContainner).off("keydown", this.onKeyDown);
         $(canvasContainner).off("keypress", this.onKeyPress);
      }
      $(window).off("blur", this.onBlur);
   };

   private _detectRegionChange = (stillUpdate, onRegionUpdated, macNotchHeight) => {
      let currentRegion = {
         x: screen.availLeft,
         y: screen.availTop,
         height: screen.availHeight,
         width: screen.availWidth,
         devicePixelRatio: devicePixelRatio
      } as DisplayBaseInfo;

      // overwrite the real data with modified version to bypass API issue for bug 2994736 & 2977384
      if (this.modifiedScreenInfo) {
         currentRegion = this.modifiedScreenInfo;
      }
      currentRegion.macNotchHeight = macNotchHeight;
      if (!this.lastRegion || JSON.stringify(this.lastRegion) !== JSON.stringify(currentRegion)) {
         // deep copy to avoid later modification affect this, like append screenId
         this.lastRegion = JSON.parse(JSON.stringify(currentRegion));
         onRegionUpdated("0", currentRegion, stillUpdate);
      }
   };

   /**
    * @private
    * @return {[type]} [description]
    *
    * TODO: this region update flow is not needed anymore since the HTML5 API get stronger
    * remove in later releases
    */
   public startRegionDetector = (onRegionUpdated) => {
      const macNotchHeight = this.displayCheck.getMacNotchHeight();
      this._detectRegionChange(true, onRegionUpdated, macNotchHeight);
      this.detectTimer = setInterval(this._detectRegionChange, 1000, false, onRegionUpdated, macNotchHeight);
   };
   /**
    * @private
    * @return {[type]} [description]
    */
   private stopRegionDetector() {
      if (this.detectTimer) {
         clearInterval(this.detectTimer);
      }
   }

   /**
    * Although very very corner, this function also be implemented to avoid
    * primary monitor move after extended monitor connected for 3+ display case.
    */
   public updateUnmaxizableRegions(allRegions) {
      Logger.info("the setting is updated as" + JSON.stringify(allRegions), Logger.DISPLAY);
   }

   public onCursorChanged = (cursorAddress) => {
      if (!this.enabled) {
         return;
      }
      this.primaryCanvas.style.cursor = cursorAddress;
   };

   public onDisplayChanged = (rect, renderingIndex) => {
      if (!this.enabled) {
         return;
      }
      this.vncDecoder.render(rect, this.offset, () => {
         this.onRenderingDone(0, renderingIndex);
      });
   };

   /**
    * Init the primary monitor
    * @param  {function} onRegionUpdated The callback should be called when
    *    region changed
    */
   public init = (onRegionUpdated) => {
      this.startRegionDetector(onRegionUpdated);
   };

   private enterFullscreen = () => {
      this.fullscreenService.enterFullscreen(document.body);
   };

   public exitFullscreen = () => {
      this.fullscreenService.exitFullscreen();
   };

   private onConflictEvent = () => {
      this.conflictEventTime = Date.now();
   };

   /**
    * Enable primary monitor
    * @param  {object} wmksSession The session that want to use the primary
    *    monitor
    * @param  {function} onDone The callback after the enable done.
    * @param  {object} screenBase The object that include property x and y for
    *     the screen base offset.
    */
   public enable = (wmksSession, onDone, onQuit, onRenderingDone, quitMultimon) => {
      if (this.enabled) {
         return;
      }
      if (this.fullscreenService.isInFullscreen()) {
         this.fullscreenService.unbindFullScreen();
      }
      this.stopRegionDetector();
      this.createPrimaryCanvas(wmksSession);
      this.monitorWatcherService.startWatching({
         onRemoved: quitMultimon
      });

      this.enabled = true;
      $(window).on("fileDownloaded", this.onConflictEvent);
      this.onRenderingDone = onRenderingDone;

      // not remoteapp
      if (window.location.href.indexOf("app-window.html") === -1) {
         if (!this.fullscreenSubscribed) {
            this.fullscreenService.fullScreen$.subscribe((fullscreen: boolean) => {
               // ensure only execute when enter multimon mode, not enter fullscreen in single monitor mode.
               if (this.enabled) {
                  if (fullscreen) {
                     if (onDone) {
                        onDone();
                     }
                  } else {
                     let mayHasConflict,
                        conflictTime = 100,
                        currentTime = Date.now();
                     console.log(currentTime, this.conflictEventTime);
                     mayHasConflict = currentTime - this.conflictEventTime < conflictTime;
                     onQuit(mayHasConflict);
                  }
               }
            });
            this.fullscreenSubscribed = true;
         }
         // https://bugzilla.eng.vmware.com/show_bug.cgi?id=3117746
         this.displayCheck.syncScreenHeight();
         this.fullscreenService.bindFullScreen();
         this.enterFullscreen();
      } else {
         onDone();
      }
   };

   /**
    * Disable primary monitor
    */
   public disable = () => {
      if (!this.enabled) {
         return;
      }
      this.monitorWatcherService.stopWatching();
      this.exitFullscreen();
      this.offset = null;
      this.stopRegionDetector();
      this.destroyPrimaryCanvas();
      this.primaryCanvas = null;
      this.canvasContent = null;
      this.enabled = false;
      this.lastRegion = null;
      $(window).off("fileDownloaded", this.onConflictEvent);
   };

   /**
    * return the monitor setting
    */
   public getSetting = (): DisplayBaseInfo => {
      return this.wmksBaseService.getScreenSetting();
   };

   /**
    * set the offset
    * @param {object} screenBase The base offset in the co-ordination
    */
   public setScreenBase = (screenBase) => {
      this.offset = screenBase;
      this.offset.y -= this.notchHeight;
   };

   public setScreenModel = (model) => {
      this.normalizationService.setScreenModel(model);
   };

   public initScreen = () => {
      this.vncDecoder.initScreen();
   };

   public showDisplay = () => {
      this.primaryCanvas.style.display = "";
   };

   public hideDisplay = () => {
      this.primaryCanvas.style.display = "none";
   };

   /**
    * Could reuse hideDisplay, but this function for better user experience
    * Don't hidden display while changing since it might not be safe to assume
    * resolution always come and processed ahead of displaying rects
    */
   public adjustDisplay = (screenBase, screenModel, sizeFactor) => {
      this.mksPaused = true;
      this.setScreenBase(screenBase);
      this.setScreenModel(screenModel);
      this.onDPISettingChanged(sizeFactor);
      this.initScreen();
   };

   /**
    * When resolution is confirmed changed from Agent, resume mks
    */
   public adjustDisplayDone = () => {
      this.mksPaused = false;
   };
}
