/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * The USB string store is used to cache USB string by language and index.
 * The cache is a flat blob so it can be easy sent over the wire.
 *
 * The USB client version > 3 need to add the string info in the bodyBag.
 * https://opengrok.eng.vmware.com/source/xref/main.perforce.1666/bora/apps/viewusb/framework/usb/lib/ws_vdpvhub/vhubService.cpp#923
 *
 * So we create this file to keep binary compatible with bora/apps/viewusb/framework/usb/lib/stringStore/
 * Please notice
 *    1. The default arch for X86 is little endian.
 *    2. The default arch for javascript is big endian.
 *
 * We need to to endian translate for the number in UsbStringStore.
 *
 * @format
 */

import WMKS from "WMKS";

const SizeStringStoreEntry: number = 7;
interface StringStoreEntry {
   mLength: number; //uint32
   mIndex: number; //uint8
   mLangId: number; //uint16
   mStringDescriptor: ArrayBuffer;
}

// use the same naming rule as c++
export class UsbStringStore {
   private static readonly MAGIC: number = 0x73736d76;
   private mLength: number = 12;
   private mCount: number = 0;
   private items: Array<StringStoreEntry>;

   constructor() {
      this.items = new Array<StringStoreEntry>();
   }

   public addStringDesc(index: number, langId: number, desc: ArrayBuffer) {
      const stringDesc: StringStoreEntry = {} as StringStoreEntry;
      stringDesc.mIndex = index;
      stringDesc.mLangId = langId;
      stringDesc.mLength = SizeStringStoreEntry + desc.byteLength;
      stringDesc.mStringDescriptor = desc;

      this.mCount += 1;
      this.mLength += stringDesc.mLength;
      this.items.push(stringDesc);
   }

   public toBlobBytes(): string {
      const packet = WMKS.Packet.createNewPacketLE();
      packet.writeUint32(UsbStringStore.MAGIC);
      packet.writeUint32(this.mLength);
      packet.writeUint32(this.mCount);
      for (const desc of this.items) {
         packet.writeUint32(desc.mLength);
         packet.writeUint8(desc.mIndex);
         packet.writeUint16(desc.mLangId);
         packet.writeArray(new Uint8Array(desc.mStringDescriptor));
      }
      const plainText: Uint8Array = packet.getData();
      return btoa(String.fromCharCode.apply(null, plainText));
   }
}
