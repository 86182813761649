/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedCommonModule } from "../../../shared/common/shared.common.module";
import { ClipboardComponent } from "./clipboard.component";
import { panelFreeClipboardComponent } from "./panel-free-clipboard.component";

@NgModule({
   imports: [SharedCommonModule, CommonModule],
   declarations: [ClipboardComponent, panelFreeClipboardComponent],
   providers: [],
   exports: [ClipboardComponent, panelFreeClipboardComponent]
})
export class ClipboardModule {}
