/**
 * ******************************************************
 * Copyright (C) 2012-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * connectToDesktopCommand.js --
 *
 * Called to setup and retrieve the parameters for a desktop connection.
 * The returned parameters should be used to launch an appropriate client for
 * the connection protocol, e.g. an RDP client when <protocol>RDP</protocol> is
 * returned.
 *    1. GetDesktopConnection
 *
 */

import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import Router from "../controllers/router";
import GetDesktopConnectionHandler from "../controllers/getDesktopConnectionHandler";
import SetUserGlobalPrefHandler from "../controllers/setUserGlobalPrefHandler";
import ConnectionRetryController from "../timer/connectionRetryController";
import { SelectDesktopAction } from "../../common/jscdk/jscdk-interface";

export default function ConnectToDesktopCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param selectDesktopAction[in]
 *        - its property 'method' has the value 'ConnectToDesktop'
 */
ConnectToDesktopCommand.prototype.execute = function (selectDesktopAction: SelectDesktopAction) {
   let handlerList,
      getDesktopConnObject = globalArray["get-desktop-connection"],
      desktopPreferences,
      router = globalArray["router"],
      setPrefHandler = globalArray["set-user-global-preferences"],
      connectionRetryController = globalArray["connection-retry-controller"];

   const checkExist = function (handler) {
      return handler !== undefined;
   };

   if (!router) {
      Logger.info("router is null.");

      router = new Router();
      globalArray[router.name] = router;
   }

   if (selectDesktopAction.prefData) {
      if (!setPrefHandler) {
         setPrefHandler = new SetUserGlobalPrefHandler();
         globalArray[setPrefHandler.messageName] = setPrefHandler;
      }
      setPrefHandler.setRequestXML(selectDesktopAction.prefData);
   }

   if (!connectionRetryController) {
      connectionRetryController = new ConnectionRetryController();
      globalArray[connectionRetryController.globalName] = connectionRetryController;
   }
   connectionRetryController.setConnectingStatus(true);

   if (!getDesktopConnObject) {
      getDesktopConnObject = new GetDesktopConnectionHandler();
      globalArray[getDesktopConnObject.messageName] = getDesktopConnObject;
      globalArray[getDesktopConnObject.responseTag] = getDesktopConnObject;
   } else {
      getDesktopConnObject.resetData();
   }

   if (selectDesktopAction.disableCombine) {
      getDesktopConnObject.sendAlong();
   } else {
      getDesktopConnObject.combineWithPerf();
   }

   if (!selectDesktopAction.connectionRetry) {
      globalArray["connection-retry-action"] = selectDesktopAction;
   }

   desktopPreferences = selectDesktopAction.preferences;

   getDesktopConnObject.setRequestXML(
      selectDesktopAction.desktopId,
      selectDesktopAction.protocol,
      selectDesktopAction.environmentInfo,
      selectDesktopAction.isShadow,
      selectDesktopAction.reverseToken || null,
      selectDesktopAction.rdsLicenseInfo || null
   );

   getDesktopConnObject.setPreferences(desktopPreferences);

   handlerList = getDesktopConnObject.composeHandlerList();
   handlerList = handlerList.filter(checkExist);
   router.postMessage(handlerList);
};
