/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * cancelAuthenticationCommand.js --
 *
 *      Cancels any currently active authentication.  Should be triggered by
 *      the application if the user cancels while partially through
 * authentication.
 *
 */

import { globalArray } from "../jscdkClient";
import DoCancelAuthenticationHandler from "../controllers/doCancelAuthenticationHandler";
import Router from "../controllers/router";

export default function CancelAuthenticationCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param cancelAuthenticationAction[in]
 *        - its property 'method' has the value 'CancelAuthentication'
 */
CancelAuthenticationCommand.prototype.execute = function (cancelAuthenticationAction) {
   let handlerList,
      cancelAuthObject = globalArray["do-cancel-authentication"],
      router = globalArray["router"];

   if (!router) {
      router = new Router();
      globalArray[router.name] = router;
   }

   if (!cancelAuthObject) {
      cancelAuthObject = new DoCancelAuthenticationHandler();
      globalArray[cancelAuthObject.messageName] = cancelAuthObject;
      globalArray[cancelAuthObject.responseTag] = cancelAuthObject;
   } else {
      cancelAuthObject.resetData();
   }

   handlerList = cancelAuthObject.composeHandlerList();

   router.postMessage(handlerList, true, undefined, undefined, cancelAuthenticationAction.sendFetch);
};
