/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../../core/libs/logger";
import { HorizonUsbDevice, TransferType } from "./horizon-usb-device";

type TransferFunc = (
   handle: chrome.usb.ConnectionHandle,
   transferInfo: chrome.usb.GenericTransferInfo,
   callback: (info: chrome.usb.TransferResultInfo) => void
) => void;

enum DeviceStatus {
   OK = "ok",
   STALL = "chrome device in stall state",
   BABBLE = "chrome device in babble state"
}

export class ChromeUsbDevice extends HorizonUsbDevice {
   private device: chrome.usb.Device;
   public connectionHandle: chrome.usb.ConnectionHandle;

   constructor(raw: chrome.usb.Device, id: number) {
      super();
      this.device = raw;
      this.vendorId = raw.vendorId;
      this.productId = raw.productId;
      this.deviceId = raw.device;
   }

   //@Override
   public open = (): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
         chrome.usb.openDevice(this.device, (handle: chrome.usb.ConnectionHandle) => {
            if (!handle) {
               Logger.error(
                  "Failed to open device [" +
                     this.device.productId +
                     ":" +
                     this.device.vendorId +
                     "] with error" +
                     chrome.runtime.lastError
               );
               if (chrome.runtime.lastError) {
                  reject(chrome.runtime.lastError.message);
               } else {
                  reject("failed to open device");
               }
            }
            this.connectionHandle = handle;
            resolve();
         });
      });
   };

   //@Override
   public close = (): Promise<any> => {
      return new Promise<void>((resolve, reject) => {
         chrome.usb.closeDevice(this.connectionHandle, () => {
            if (chrome.runtime.lastError !== undefined) {
               reject(chrome.runtime.lastError.message);
            } else {
               resolve(null);
            }
         });
      });
   };

   //@Override
   public selectConfiguration(configurationValue: number): Promise<any> {
      return new Promise<void>((resolve, reject) => {
         chrome.usb.setConfiguration(this.connectionHandle, configurationValue, () => {
            if (chrome.runtime.lastError !== undefined) {
               reject(chrome.runtime.lastError.message);
            } else {
               // @ts-ignore
               resolve();
            }
         });
      });
   }

   //@Override
   public claimInterface(interfaceNumber: number): Promise<any> {
      return new Promise<void>((resolve, reject) => {
         chrome.usb.claimInterface(this.connectionHandle, interfaceNumber, () => {
            if (chrome.runtime.lastError !== undefined) {
               reject(chrome.runtime.lastError.message);
            } else {
               // @ts-ignore
               resolve();
            }
         });
      });
   }

   //@Override
   public releaseInterface(interfaceNumber: number): Promise<any> {
      return new Promise<void>((resolve, reject) => {
         chrome.usb.releaseInterface(this.connectionHandle, interfaceNumber, () => {
            if (chrome.runtime.lastError !== undefined) {
               reject(chrome.runtime.lastError.message);
            } else {
               // @ts-ignore
               resolve();
            }
         });
      });
   }

   //@Override
   public controlTransferIn(setup: USBControlTransferParameters, length: number): Promise<USBInTransferResult> {
      const info: chrome.usb.TransferInfo = setup as chrome.usb.TransferInfo;
      info.direction = "in";
      info.length = length;
      return new Promise<USBInTransferResult>((resolve, reject) => {
         chrome.usb.controlTransfer(this.connectionHandle, info, (result: chrome.usb.TransferResultInfo) => {
            let transferResult: USBInTransferResult = null;
            if (!result) {
               reject(DeviceStatus.BABBLE);
            } else if (result.resultCode !== 0) {
               reject(DeviceStatus.STALL);
            } else {
               transferResult = new USBInTransferResult(DeviceStatus.OK, new DataView(result.data));
            }
            resolve(transferResult);
         });
      });
   }

   //@Override
   public controlTransferOut(setup: USBControlTransferParameters, data?: BufferSource): Promise<USBOutTransferResult> {
      const info: chrome.usb.TransferInfo = setup as chrome.usb.TransferInfo;
      info.direction = "out";
      info.data = data as ArrayBuffer;
      return new Promise<USBOutTransferResult>((resolve, reject) => {
         chrome.usb.controlTransfer(this.connectionHandle, info, (result: chrome.usb.TransferResultInfo) => {
            let transferResult: USBOutTransferResult = null;
            if (!result) {
               reject(DeviceStatus.BABBLE);
            } else if (result.resultCode !== 0) {
               reject(DeviceStatus.STALL);
            } else {
               transferResult = new USBOutTransferResult(DeviceStatus.OK, data.byteLength);
               resolve(transferResult);
            }
         });
      });
   }

   //@Override
   public transferIn(type: TransferType, endpointNumber: number, length: number): Promise<USBInTransferResult> {
      let transferFunc: TransferFunc = null;
      const info: chrome.usb.GenericTransferInfo = {} as chrome.usb.GenericTransferInfo;
      info.direction = "in";
      info.endpoint = endpointNumber;
      info.length = length;
      if (type === "bulk") {
         transferFunc = chrome.usb.bulkTransfer;
      } else {
         transferFunc = chrome.usb.interruptTransfer;
      }
      return new Promise((resolve, reject) => {
         transferFunc(this.connectionHandle, info, (result: chrome.usb.TransferResultInfo) => {
            let transferResult: USBInTransferResult = null;
            if (!result) {
               reject(DeviceStatus.BABBLE);
            } else if (result.resultCode !== 0) {
               reject(DeviceStatus.STALL);
            } else {
               transferResult = new USBInTransferResult(DeviceStatus.OK, new DataView(result.data));
               resolve(transferResult);
            }
         });
      });
   }

   //@Override
   public transferOut(type: TransferType, endpointNumber: number, data: BufferSource): Promise<USBOutTransferResult> {
      let transferFunc: TransferFunc = null;
      const info: chrome.usb.GenericTransferInfo = {} as chrome.usb.GenericTransferInfo;
      info.direction = "out";
      info.endpoint = endpointNumber;
      info.data = data as ArrayBuffer;
      if (type === "bulk") {
         transferFunc = chrome.usb.bulkTransfer;
      } else {
         transferFunc = chrome.usb.interruptTransfer;
      }
      return new Promise((resolve, reject) => {
         transferFunc(this.connectionHandle, info, (result: chrome.usb.TransferResultInfo) => {
            let transferResult: USBOutTransferResult = null;
            if (!result) {
               reject(DeviceStatus.BABBLE);
            } else if (result.resultCode !== 0) {
               reject(DeviceStatus.STALL);
            } else {
               transferResult = new USBOutTransferResult(DeviceStatus.OK, data.byteLength);
               resolve(transferResult);
            }
         });
      });
   }

   //@Override
   public reset = (): Promise<any> => {
      return new Promise((resolve, reject) => {
         chrome.usb.resetDevice(this.connectionHandle, (success: boolean) => {
            if (success) {
               resolve(true);
               return;
            }
            reject(chrome.runtime.lastError.message);
         });
      });
   };

   //@Override
   public selectAlternateInterface = (interfaceNumber: number, alternateSetting: number): Promise<any> => {
      return new Promise((resolve, reject) => {
         chrome.usb.setInterfaceAlternateSetting(this.connectionHandle, interfaceNumber, alternateSetting, () => {
            if (chrome.runtime.lastError !== undefined) {
               reject(chrome.runtime.lastError.message);
            } else {
               resolve(true);
            }
         });
      });
   };

   //@Override
   public getConfiguration = (): Promise<USBConfiguration> => {
      /*
       * convert from chrome.usb.ConfigDescriptor to w3c USBConfiguration
       * The HorizonUSBConfiguration and other interface with Horizon prefix is a
       * workaround for the chrome browser.
       *
       * We use the W3C USB interface to support USB redirection in HTML5 and chrome
       * native client. The interface is common but the implementation is difference
       * for different browser.
       * https://github.com/DefinitelyTyped/DefinitelyTyped
       *
       * Although we can't use the W3C USB interface directly in chrome native client,
       * the chrome OS have the implementation for W3C USB API.
       * Most of the Javascript class like USBConfiguration, USBInterface are implemented
       * with native codes in C++. So we can't create a USBConfiguration with
       * ```let config:USBConfiguration = new USBConfiguration(x,y)```
       *
       * Since we don't know the parameters for constructor and it will create instance
       * in native world.
       *
       * So, I declare a set of USB interface, which is compatible with the W3C interface
       * in Javascript world.
       */

      const usbConfig: HorizonUSBConfiguration = {} as HorizonUSBConfiguration;
      return new Promise((resolve, reject) => {
         chrome.usb.getConfiguration(this.connectionHandle, (config: chrome.usb.ConfigDescriptor) => {
            if (!config) {
               reject(chrome.runtime.lastError.message);
               return;
            }

            usbConfig.configurationValue = config.configurationValue;
            usbConfig.configurationName = config.description;
            usbConfig.interfaces = [] as HorizonUSBInterface[];
            //interfaces
            for (const chromeInter of config.interfaces) {
               if (chromeInter.alternateSetting === 1) {
                  //Remove duplicate interfaces via alternateSetting
                  //Please see https://reviewboard.eng.vmware.com/r/2019605/
                  continue;
               }
               const usbInterface = {} as HorizonUSBInterface;
               usbInterface.interfaceNumber = chromeInter.interfaceNumber;

               usbInterface.alternate = {} as HorizonUSBAlternateInterface;
               usbInterface.alternate.alternateSetting = chromeInter.alternateSetting;
               usbInterface.alternate.interfaceClass = chromeInter.interfaceClass;
               usbInterface.alternate.interfaceSubclass = chromeInter.interfaceSubclass;
               usbInterface.alternate.interfaceProtocol = chromeInter.interfaceProtocol;
               usbInterface.alternate.interfaceName = chromeInter.description;
               usbInterface.alternate.endpoints = [] as HorizonUSBEndpoint[];

               //endpoints
               for (const chromeEP of chromeInter.endpoints) {
                  const endPoint = {} as HorizonUSBEndpoint;
                  endPoint.direction = chromeEP.direction;
                  endPoint.endpointNumber = chromeEP.address;
                  //@ts-ignore
                  endPoint.type = chromeEP.type;
                  endPoint.packetSize = chromeEP.maximumPacketSize;
                  usbInterface.alternate.endpoints.push(endPoint);
               }
               usbInterface.alternates = new Array<HorizonUSBAlternateInterface>();
               usbInterface.alternates.push(usbInterface.alternate);
               usbConfig.interfaces.push(usbInterface);
            }
            resolve(usbConfig as USBConfiguration);
         });
      });
   };

   //@Override
   public getRaw = (): any => {
      return this.device;
   };

   //@Override
   public getProductName = (): string => {
      return this.device.productName;
   };

   //@Override
   public getSerialNumber = (): string => {
      return this.device.serialNumber;
   };

   //@Override
   public getManufacturerName = (): string => {
      return this.device.manufacturerName;
   };
}
