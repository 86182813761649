/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { WarningTextEnum, PostMessageEventNamesEnum } from "./utils/tabEnum";

@Injectable()
export class ChildTabService {
   public sessionStorageKey;
   public tabName;
   public tabId;
   public tabParentName;
   public isSessionStorageSupported;
   public config = {
      onParentDisconnect: null,
      onParentCommunication: null,
      origin: "*"
   };

   constructor() {
      this.sessionStorageKey = "_new_tab_info__";
      this.tabName = window.name;
      this.tabId = null;
      this.tabParentName = null;
   }

   public setFunctions = (config) => {
      this.config.onParentDisconnect = config.onParentDisconnect;
      this.config.onParentCommunication = config.onParentCommunication;
   };

   public init = (config) => {
      this.setFunctions(config);
      this.isSessionStorageSupported = this._isSessionStorage();
      this.addListeners();
      this._restoreData();
      this.sendMessageToParent(this.getTabInfo(), PostMessageEventNamesEnum.LOADED);
   };

   public _isSessionStorage = () => {
      try {
         if ("sessionStorage" in window && window.sessionStorage) {
            return true;
         }
         return false;
      } catch (e) {
         return false;
      }
   };

   public _getData = () => {
      if (!this.isSessionStorageSupported) {
         return false;
      }

      return window.sessionStorage.getItem(this.sessionStorageKey);
   };

   public _setData = (dataReceived) => {
      if (!this.isSessionStorageSupported) {
         return false;
      }

      window.sessionStorage.setItem(this.sessionStorageKey, dataReceived);
      return dataReceived;
   };

   public _restoreData = () => {
      if (!this.isSessionStorageSupported) {
         return false;
      }

      if (this.isSessionStorageSupported) {
         const storedData = this._getData();

         this._parseData(storedData);
      }
   };

   public _parseData = (dataReceived) => {
      let actualData;
      try {
         actualData = JSON.parse(dataReceived);
         this.tabId = actualData && actualData.id;
         this.tabName = actualData && actualData.name;
         this.tabParentName = actualData && actualData.parentName;
      } catch (e) {
         throw new Error(WarningTextEnum.INVALID_DATA);
      }
   };

   public onCommunication = (message) => {
      let dataReceived,
         data = message.data;

      if (!data || typeof data !== "string") {
         return;
      }
      if (data.indexOf(PostMessageEventNamesEnum.HANDSHAKE_WITH_PARENT) > -1) {
         this.config.origin = message.origin;
      }
      if (this.config.origin && this.config.origin !== message.origin) {
         return;
      }

      if (data.indexOf(PostMessageEventNamesEnum.PARENT_DISCONNECTED) > -1) {
         if (this.config.onParentDisconnect) {
            this.config.onParentDisconnect();
         }
         window.removeEventListener("message", (evt) => this.onCommunication(evt));
      }

      if (data.indexOf(PostMessageEventNamesEnum.HANDSHAKE_WITH_PARENT) > -1) {
         let msg;

         dataReceived = data.split(PostMessageEventNamesEnum.HANDSHAKE_WITH_PARENT)[1];

         this._setData(dataReceived);
         this._parseData(dataReceived);
         msg = { id: this.tabId };
         this.sendMessageToParent(msg, PostMessageEventNamesEnum.HANDSHAKE);
      }
      if (data.indexOf(PostMessageEventNamesEnum.PARENT_COMMUNICATED) > -1) {
         dataReceived = data.split(PostMessageEventNamesEnum.PARENT_COMMUNICATED)[1];

         try {
            dataReceived = JSON.parse(dataReceived);
         } catch (e) {
            throw new Error(WarningTextEnum.INVALID_JSON);
         }
         if (this.config.onParentCommunication) {
            this.config.onParentCommunication(dataReceived);
         }
      }
   };

   public addListeners = () => {
      window.onbeforeunload = (evt) => {
         const msg = {
            id: this.tabId
         };
         this.sendMessageToParent(msg, PostMessageEventNamesEnum.ON_BEFORE_UNLOAD);
      };

      window.removeEventListener("message", (evt) => this.onCommunication(evt));
      window.addEventListener("message", (evt) => this.onCommunication(evt));
   };

   public sendMessageToParent = (msg, _prefixType) => {
      let origin;
      const type = _prefixType || PostMessageEventNamesEnum.CUSTOM;
      msg = type + JSON.stringify(msg);

      if (window.top.opener) {
         origin = this.config.origin || "*";
         window.top.opener.postMessage(msg, origin);
      }
   };

   public getTabInfo = () => {
      return {
         id: this.tabId,
         name: this.tabName,
         parentName: this.tabParentName
      };
   };
}
