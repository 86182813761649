/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class HorizonFileSystem {
   /*
    *---------------------------------------------------------------------------
    *
    * isRestorable --
    *
    * Returns whether the app has permission to restore the entry with the given id.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract isRestorable(id, callback): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * chooseEntry --
    *
    * Ask the user to choose a file or directory.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract chooseEntry(): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * retainEntry --
    *
    * Returns an id that can be passed to restoreEntry to regain access to a given
    * file entry. Only the 500 most recently used entries are retained, where calls
    * to retainEntry and restoreEntry count as use. If the app has the 'retainEntries'
    * permission under 'fileSystem',entries are retained indefinitely. Otherwise,
    * entries are retained only while the app is running and across restarts.
    *
    * Results:
    *      string
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract retainEntry(entry): string;

   /*
    *---------------------------------------------------------------------------
    *
    * getWritableEntry --
    *
    * Get a writable Entry from another Entry.
    *
    * Results:
    *      Promise<any>
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract getWritableEntry(oentry): Promise<any>;

   /*
    *---------------------------------------------------------------------------
    *
    * getDisplayPath  --
    *
    * Get the display path of an Entry object. The display path is based on the
    * full path of the file or directory on the local file system, but may be made
    * more readable for display purposes.
    *
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public abstract getDisplayPath(rootEntry, callback): any;
}
