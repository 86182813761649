/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable, Renderer2 } from "@angular/core";
@Injectable({
   providedIn: "root"
})
export class ContextMenuUtilService {
   private static readonly ribbonHeight: number = 50;
   private static readonly DIRECTION_CLASS: Array<string> = [
      "north",
      "northeast",
      "northwest",
      "south",
      "southeast",
      "southwest"
   ];

   public existingAppWithContextMenu: TitanEntitlementItem;
   public existingAppId: string;

   public setDirection(elem: HTMLElement, renderer: Renderer2, boundingElmClass: string): void {
      let horizontal: string = "";
      let vertical: string = "";
      const contextMenuContent: HTMLElement = elem.querySelector(".context-menu-container");
      const contextMenuContentRect = this.getContextMenuRect(contextMenuContent);
      const pane: Element = elem.closest(boundingElmClass);
      const paneRect = this.getPaneRect(pane);

      if (contextMenuContentRect.right > paneRect.right) {
         horizontal = "east";
      } else {
         horizontal = "west";
      }

      if (contextMenuContentRect.bottom + ContextMenuUtilService.ribbonHeight > paneRect.bottom) {
         vertical = "north";
      } else if (contextMenuContentRect.bottom + ContextMenuUtilService.ribbonHeight < paneRect.bottom) {
         vertical = "south";
      }

      const direction = vertical + horizontal;
      const classLists = contextMenuContent.getAttribute("class").split(" ");
      if (classLists[1] && ContextMenuUtilService.DIRECTION_CLASS.includes(classLists[1])) {
         renderer.removeClass(contextMenuContent, classLists[1]);
      }
      renderer.addClass(contextMenuContent, direction);
   }

   public updateExistingContext(horizonApp: TitanEntitlementItem) {
      this.existingAppWithContextMenu = horizonApp;
   }

   public updateExistingContextAppId(appId: string): void {
      this.existingAppId = appId;
   }

   public isDiffApp(horizonApp: any): boolean {
      return horizonApp.id !== this.existingAppId;
   }

   private getContextMenuRect(cm: HTMLElement): DOMRect | ClientRect {
      return this.getBoundingRectForElem(cm);
   }

   private getPaneRect(p: Element): DOMRect | ClientRect {
      return this.getBoundingRectForElem(p);
   }

   private getBoundingRectForElem(elem: Element): DOMRect | ClientRect {
      return elem.getBoundingClientRect();
   }
}
