/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Use this only for fixing bug 2099708, and not implement.
 * Should share codes with Chrome Client after implementing
 * 2 storageService for HTML and Chrome client.
 */

import { BrokerSettingService } from "../../shared/launcher/common/broker-setting.service";
import { Injectable } from "@angular/core";

@Injectable()
export class BrokerSettingHTML5Service extends BrokerSettingService {
   constructor() {
      super();
   }

   public setLastADConnectionInfo = (brokerAddress, userName, domain) => {
      return new Promise(function (resolve, reject) {
         // @ts-ignore
         resolve();
      });
   };

   public getLastADConnectionInfo = (brokerAddress) => {
      return new Promise(function (resolve, reject) {
         resolve({});
      });
   };
}
