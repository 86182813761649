/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { BlastWmksSession } from "../../../common/blast-wmks.service";
import { CommonSvcService } from "../../commonSvc.service";

export namespace HTML5MMR {
   export class SessionModel {
      private wmksSession: BlastWmksSession;
      private commonSvcService: CommonSvcService;
      private defaultDPI: number;

      constructor(wmksSession: BlastWmksSession, commonSVCService: CommonSvcService, defaultDPI: number) {
         this.wmksSession = wmksSession;
         this.commonSvcService = commonSVCService;
         this.defaultDPI = defaultDPI;
      }

      /**
       * Retrieve wmks session key
       *
       * @returns string
       */
      public get key(): string {
         return this.wmksSession.key;
      }

      /**
       * Retrieve remote DPI for this wmks session
       *
       * @returns number
       */
      public get remoteDPI(): number {
         return this.commonSvcService.getRemoteDPI(this.key) || this.defaultDPI;
      }
   }
}
