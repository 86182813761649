/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { RebrandingItemTO } from "./Rebrand";
export interface TenantProperty {
   brandType: string;
   brandURL: string;
   globalDomain: string;
   backgroundImg: string;
   idpTenantDomain: string;
   preLoginMessage: string;
   rebrandInfo: RebrandingItemTO[];
}

export enum BRAND_URL_TYPE {
   UNKNOWN,
   VANITY_URL,
   CUSTOM_DEFINED_URL
}
