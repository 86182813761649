/**
 * ******************************************************
 * Copyright (C) 2020-201 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { ViewClientModel } from "../model/viewclient-model";
import { Localize } from "../service/localize.service";
import { clientUtil } from "../../../core/libs";
import { AbstractModalComponent } from "../commondialog/abstract-modal.component";

@Component({
   selector: "about-dialog",
   templateUrl: "./about-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class AboutDialogComponent extends AbstractModalComponent {
   helpUrl: string = "";
   eulaUrl: string = "";
   oslUrl: string = "";
   patentUrl: string = "";
   showMore: boolean;
   buildNum1: string = "";
   buildNum2: string = "";
   versionNum: string = "";
   linkRelValue: string = "";
   VERSION_NUMBER: string = "";
   BUILD_NUMBER: string = "";

   constructor(
      private viewClientModel: ViewClientModel,
      private localize: Localize
   ) {
      super();
      this.helpUrl = this.viewClientModel.rebrandHelpUrl || this.getLinkByName("helpPages");
      this.eulaUrl = this.getLinkByName("eulaPages");
      this.oslUrl = this.getLinkByName("oslPages");
      this.patentUrl = this.getLinkByName("patentPages");
      this.showMore = clientUtil.isChromeClient();
      this.versionNum = __MARKET_VERSION__;
      this.buildNum1 = __VDM_WEB_CLIENT_VERSION__;
      this.buildNum2 = __BUILD_NUMBER__;
      this.linkRelValue = __REL_FOR_LINK__;
   }

   /**
    * When continute the page merge, this part need to be merged with the one
    * for the launcher also.
    */
   private getLinkByName = (name) => {
      const locale = this.localize.getLocale(),
         map = this.viewClientModel.linkModel[name];

      if (map.hasOwnProperty(locale)) {
         return map[locale];
      }

      return map.en;
   };

   public openHelpLink = () => {
      window.open(this.helpUrl, "_blank");
   };
}
