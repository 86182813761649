/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * this service do not support multiple calling at the same time, so please
 * make sure the UI is designed to forbidden user to do so.
 *
 * this service is not ready at once it's loaded for chromeClient, and the code
 * changes need to be done for that.
 *
 * currently that's the only service in blast side calling JSCDK, so I think
 * it's ok to mimic the mainService function here before the JSCDK refactory is
 * done. after that, the mainService will be needed no more, and the new
 * callback API of JSCDK is also fit for "many mainService pattern".
 *
 * if the including of entitledItemsModel cause any dependency error(like
 * loop), one can set it as a refer at outside with a extra entitledItemsModel
 * setter API.
 *
 * when try launch the same desktop, this service will return blastURL, and
 * it's not good for the user, so the UI should forbidden user to do so, or pre
 * deal this case for better user experience.
 *
 * the locked status check will be done using a will-check-in patch, that patch
 * can get the locked status without asking broker, thus save time and network
 * traffic.
 *
 * the blastUrl in returned response as the param of callback could be changed
 * to an object that contains all info like item id, name , type, therefor ease
 * the info cache in outside if needed.
 *
 * disconnected-related logic and cache should not be handled here, but put it
 * here for the 1st patch.
 *
 * the null segment are optional once JSCDK is using callback.
 *
 * currently the response of resetAllApplicationSessions is ok only when all of
 * the application-session- killing is ok, and error if any of them fails. This
 * could be changed to return a list of the success info, but currently I see
 * no need, so merge them here.
 */

import Logger from "../../../core/libs/logger";
import {
   globalArray,
   UIEventToJSCDK,
   jscdkClientSetUIController,
   jscdkClientSetFeatureStatus,
   jscdkClientSetCertFunction,
   jscdkClientSetStoreFunction
} from "../../jscdk/jscdkClient";
import { Injectable, Optional } from "@angular/core";
import util from "../../jscdk/util";
import { BusEvent, CLIENT, clientUtil, ENTITLE_TYPE, EventBusService, TranslateService } from "@html-core";
import { JSCDKHandler } from "./jscdk-handler";
import { ModalDialogService } from "../commondialog/dialog.service";
import { EntitledItemsModel } from "../../desktop/common/entitleditems-model";
import { ApplicationSessionsModel } from "../../desktop/common/applicationsessions-model";
import { BlastSessionsModel } from "../model/blastsessions-model";
import { IdleSessionService } from "../service/idle-session.service";
import { ViewClientModel } from "../model/viewclient-model";
import { BaseViewService } from "../service/base-view.service";
import { SessionDataService } from "../service/session-data.service";
import { FeatureConfigs } from "../model/feature-configs";
import { MultiSessionSingleUserService } from "../service/multisession-singleuser.service";
import { JscdkCommonInvoker } from "./jscdk-common-invoker";
import { ClientModeService } from "../service/client-mode.service";
import { UtilService } from "../../launcher/common/util-service";
import { Ws1Service } from "../service/ws1.service";
import { SessionManagementService } from "./session-management.service";
import { JscdkAuthService } from "./jscdk-auth.service";
import { TransitionDataModel } from "../model/transition-data-model";
import { ForeignCertService } from "../../../shared/base/foreign-cert.service";
import { JumpCacheService } from "../service/jump-cache.service";
import { ReconnectService } from "../../desktop/common/reconnect.service";
import { SessionDataUpdateService } from "./session-data-update.service";
import { ErrorService } from "./error-service";
import { AjaxBusyService } from "../ajax-busy/ajax-busy.service";
import { LoginModel } from "../../login/common/login-model";
import { AppViewService } from "../../common/service/app-view.service";

import { ConnectedMessageService } from "../../../chrome-client/base/service/connected-message.service";
import { RemoteSessionManager } from "../../../chrome-client/launcher/launchitems/session-service/remote-session-manager.service";
import { SessionManagementCenterManager } from "../../../chrome-client/launcher/launchitems/session-service/session-management-center-manager.service";
import { RootModel } from "../model/root-model";
import Router from "../../jscdk/controllers/router";
import { FileExtensionService } from "../../../chrome-client/file-association/file-extensions.service";
import { UserGlobalPref } from "../service/user-global-pref";
import { XMLPreference } from "../service/prefdata";
import { ConnectionServerModel } from "../model/connection-server-model";
import { CryptoKeyDerivationService } from "../service/crypto-key-derivation.service";

class PromiseWrapper {
   public promise;
   public resolve;
   public reject;
   constructor() {
      this.promise = new Promise((resolve, reject) => {
         this.resolve = resolve;
         this.reject = reject;
      });
   }
}

@Injectable({
   providedIn: "root"
})
export class JscdkWrapper {
   //private:
   _applicationSessions = [];
   //private self = this,
   private _jscdkHandler = null; //this will be defined near the end of the file

   public currentController = null;
   public getUserGlobalPrefDeferred: PromiseWrapper = null;
   public initDoneDeferred: PromiseWrapper = null;
   //used in wmks-service and side-bar
   public itemTypeEnum = {
      desktop: "Desktop",
      application: "Application"
   };

   public data = null;
   public appConnectCallBack = null;

   constructor(
      private entitledItemsModel: EntitledItemsModel,
      private applicationSessionsModel: ApplicationSessionsModel,
      private blastSessionsModel: BlastSessionsModel,
      private userGlobalPref: UserGlobalPref,
      private loginModel: LoginModel,
      private translate: TranslateService,
      private idleSessionService: IdleSessionService,
      private viewClientModel: ViewClientModel,
      private baseViewService: BaseViewService,
      private sessionDataService: SessionDataService,
      private featureConfigs: FeatureConfigs,
      private multiSessionSingleUserService: MultiSessionSingleUserService,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private clientModeService: ClientModeService,
      private utilService: UtilService,
      private ws1Service: Ws1Service,
      private errorService: ErrorService,
      private sessionManagementService: SessionManagementService,
      private sessionDataUpdateService: SessionDataUpdateService,
      private jscdkAuthService: JscdkAuthService,
      @Optional()
      private sessionManagementCenterManager: SessionManagementCenterManager,
      @Optional()
      private remoteSessionManager: RemoteSessionManager,
      private transitionDataModel: TransitionDataModel,
      @Optional()
      private connectedMessageService: ConnectedMessageService,
      private foreignCertService: ForeignCertService,
      private jumpCacheService: JumpCacheService,
      private eventBusService: EventBusService,
      private reconnectService: ReconnectService,
      private ajaxBusyService: AjaxBusyService,
      private modalDialogService: ModalDialogService,
      private appViewService: AppViewService,
      private fileExtensionService: FileExtensionService,
      private connectionServerModel: ConnectionServerModel,
      private rootModel: RootModel,
      private cryptoKeyDerivationService: CryptoKeyDerivationService
   ) {
      this.jscdkCommonInvoker.mainService = this;
      jscdkClientSetUIController(this);
      if (!foreignCertService || typeof this.foreignCertService.displayCert !== "function") {
         Logger.error("no this.foreignCertService.displayCert found, protocol redirect can't be supported");
      } else {
         jscdkClientSetCertFunction(this.foreignCertService.displayCert);
      }

      if (!window.localStorage) {
         Logger.error("window.localStorage is not supported");
      } else {
         jscdkClientSetStoreFunction(
            window.localStorage.getItem.bind(window.localStorage),
            window.localStorage.setItem.bind(window.localStorage),
            window.localStorage.removeItem.bind(window.localStorage)
         );
      }

      jscdkClientSetFeatureStatus({
         AzureConnectionRetry: this.featureConfigs.getConfig("KillSwitch-AzureConnectionRetry")
      });

      if (this.clientModeService.clientMode === "launcher") {
         this.clientModeService.setMainService(this);
      }
      if (connectedMessageService) {
         this.connectedMessageService.addAcceptedId("sessionMessage");
         this.connectedMessageService.addAcceptedId("sessionManagementCenter");
      }

      this._jscdkHandler = new JSCDKHandler(
         this,
         this.translate,
         this.idleSessionService,
         this.blastSessionsModel,
         this.transitionDataModel,
         this.jumpCacheService,
         this.eventBusService,
         this.ws1Service,
         this.reconnectService,
         this.baseViewService,
         this.jscdkCommonInvoker,
         this.utilService,
         this.featureConfigs,
         this.modalDialogService,
         this.rootModel,
         this.ajaxBusyService,
         this.translate,
         this.cryptoKeyDerivationService
      );
      this.jscdkCommonInvoker.jscdkHandler = this._jscdkHandler;
   }

   public _findDesktop = (desktopId: string): EntitlementItem => {
      return this.entitledItemsModel.isItemExisting(ENTITLE_TYPE.DESKTOP, desktopId);
   };

   //TODO-TITAN - This is only for PoC
   // we need to refactor the code to pass url to the router.
   public setBrokerUrl = (brokerUrl: string) => {
      let jscdkRouter = globalArray["router"];
      if (!jscdkRouter) {
         Logger.info("jscdkRouter is inited.");
         jscdkRouter = new Router();
         globalArray[jscdkRouter.name] = jscdkRouter;
      } else {
         Logger.info("jscdkRouter has already been inited.");
      }
      jscdkRouter.setBrokerUrl(brokerUrl);
   };

   public _findApplication = (applicationId) => {
      return this.entitledItemsModel.isItemExisting(ENTITLE_TYPE.APPLICATION, applicationId);
   };

   /**
    * this function is to launch target desktop, and the desktopId can be
    * replaced with desktop object and exclude the entitleditemsModle
    */
   public _launchDesktop = (desktopId) => {
      const desktop: EntitlementItem = this._findDesktop(desktopId);

      if (!desktop) {
         Logger.error("can't find the desktop with ID: " + desktopId + " in the entitledList");
         return;
      }

      // here assume the desktop launching always want to see the result
      // url.
      this.jscdkCommonInvoker.launchDesktop(desktop);
   };

   public _launchApplication = async (applicationId) => {
      const application: EntitlementItem = this._findApplication(applicationId);

      if (!application) {
         Logger.error("can't find the application with ID: " + applicationId + " in the entitledList");
         return;
      }
      this.jscdkCommonInvoker.launchApplication({
         application: application
      });
   };

   /**
    * JSCDK response handler
    *
    * action will never be null, so don't check here
    * complex as mainservice in web.js and will be deleted after
    * refactory
    */
   public _show = (actionObj) => {
      let actionName = actionObj.name,
         actionData = actionObj.content,
         favoriteHandler = null;

      Logger.debug("JSCDK response to UI : " + actionName);
      //If there's a response from jscdk,set the value to true.
      this.rootModel.set("isJSCDKResponse", true);
      switch (actionName) {
         case "TitanClient":
            this.eventBusService.dispatch(new BusEvent.SetTitanModeMsg(true));
            break;
         case "Disclaimer":
            if (this.clientModeService.clientMode === "desktop") {
               this.baseViewService.gotoPortal();
            }
            break;
         case "ShowError":
            if (this.rootModel.get("ClosingClient")) {
               clientUtil.closeAllWindows();
            }
            if (this.clientModeService.clientMode === "launcher") {
               this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
               this.currentController.enable();
               if (clientUtil.isChromeClient() && actionData.errorType === "KILL_SESSION_ERROR") {
                  if (this.connectedMessageService) {
                     this.connectedMessageService.sendMessage("sessionMessage", actionData.errorType, {
                        type: "killSessionError",
                        content: {
                           errorText: actionData.errorText
                        }
                     });
                  }
               } else {
                  this.errorService.showError(this.data, this.currentController);
               }
               return;
            } else {
               // handlerError
               if (clientUtil.isTitanClient()) {
                  this._jscdkHandler.handleErrorInTitan(actionObj);
                  this._jscdkHandler.prepareParamForCallback(this.data.content);
                  return;
               }
               this._jscdkHandler.handleError(this.data.content);
            }
            break;
         case "DisconnectSession":
         case "DisconnectSessionFailed":
            this.sessionManagementService.disconnectSessionFailed(this.currentController, this._jscdkHandler);
            break;
         case "ShowAppBlastApplication":
            this.sessionManagementService.cacheBlastApplication(actionData);
            this.sessionManagementService.showAppBlastSession(
               this.data,
               this.currentController,
               this._jscdkHandler,
               this
            );
            this.idleSessionService.setHasAppLaunched(true);
            break;
         case "ShowAppBlastDesktop":
            this.sessionManagementService.showAppBlastSession(
               this.data,
               this.currentController,
               this._jscdkHandler,
               this
            );
            break;
         case "ApplicationAlreadyConnected":
            this.sessionManagementService.applicationAlreadyConnected(this.data, this._jscdkHandler);
            break;
         case "CertAuth":
            // Certificate authentication.
            if (this.clientModeService.clientMode === "launcher") {
               this.loginModel.certAuth = true;
               //VCART-1091 Hidden domain list feature doesn't work with web client
               if (this.data?.content?.clientConfig) {
                  this.sessionDataService.updateLocalAndRemoteClientConfig(this.data.content.clientConfig);
               }
            } else {
               if (this.appConnectCallBack) {
                  this.appConnectCallBack();
               }
               this._jscdkHandler.invokeCallback(actionObj);
            }
            return;
         case "KillAllApplicationSessions":
            this.idleSessionService.setHasAppLaunched(false);
            this.sessionManagementService.onKillAllApplicationSessionsDone(this.currentController, this._jscdkHandler);
            break;
         case "KillSession":
         case "ResetDesktop":
         case "RestartDesktop":
            this._jscdkHandler.handleSuccessResponse();
            break;
         case "Brokers": // used by URI and legacy client jumping logic to start initializing whole client
         case "Logout": // logout broker response
            if (
               actionObj.params &&
               actionObj.params.isFA &&
               actionObj.params.host &&
               this.connectionServerModel.comingHost === this.connectionServerModel.host
            ) {
               Logger.info("Don't close current client for FA.");
            } else {
               this.userGlobalPref.clearPrefData();
               this.idleSessionService.onSessionEnded();
               this.rootModel.set("isAlreadyLogin", false);
               this.eventBusService.dispatch({ type: "logOutSuccess" });
            }
            if (this.clientModeService.clientMode === "desktop") {
               /**
                * If client can enter desktop page, localStorage must be
                * supported. No need to check it
                */
               if (actionName === "Logout" && !!window.localStorage) {
                  window.localStorage.removeItem(CLIENT.CLIENT_LOGIN_KEY);
               }
               this._jscdkHandler.handleSuccessResponse();
            }
            break;
         case "GetUserGlobalPref":
            favoriteHandler = globalArray["get-user-global-preferences"];
            this.userGlobalPref.setPrefData(favoriteHandler.prefData);

            if (this.clientModeService.clientMode === "launcher") {
               if (!!this.data && !!this.data.content) {
                  if (this.data.content.applications) {
                     favoriteHandler.markFavorite(this.data.content.applications);
                  }
                  if (this.data.content.desktops) {
                     favoriteHandler.markFavorite(this.data.content.desktops);
                  }
               }
            } else {
               if (this.getUserGlobalPrefDeferred) {
                  this.getUserGlobalPrefDeferred.resolve(this.userGlobalPref.getPrefData());
                  this.getUserGlobalPrefDeferred = null;
               }
               this.eventBusService.dispatch(new BusEvent.UpdateFavoritesMsg());
            }
            break;
         case "SetUserGlobalPref":
            if (this.clientModeService.clientMode === "launcher") {
               this.userGlobalPref.onDataSent();
            }
            break;
         case "SetPrefErrorMsg":
            if (!!this.data && !!this.data.content) {
               this.eventBusService.dispatch(new BusEvent.SetPrefErrorMsg(this.data["content"]));
            }
            break;
         // This action will be handled by the function of RequestAborted in
         // desktop page, so don't need to break if CLIENT_MODE is desktop
         case "RequestAborted":
            break;
         case "GetLaunchItems": {
            const applications = actionObj.content.applications;
            if (clientUtil.isChromeClient() && Object.keys(applications).length > 0) {
               this.fileExtensionService.filterInfo(applications);
            }

            if (this.rootModel.get("partialAuthedWithSAML")) {
               this.rootModel.set("partialAuthedWithSAML", false);
            }
            this.rootModel.set("isAlreadyLogin", true);
            this.eventBusService.dispatch({
               type: "GetLaunchItems",
               data: {
                  warning: actionObj.content.warning,
                  chrome: clientUtil.isChromeClient()
               }
            });
            if (this._jscdkHandler.handlerGetLaunchItems) {
               this._jscdkHandler.handlerGetLaunchItems(actionObj);
            }
            this.idleSessionService.setLockedStatus(false);
            this.rootModel.set("sessionExpired", false);
            break;
         }
         case "ReconnectAppSession":
            if (!clientUtil.isChromeClient()) {
               this._jscdkHandler.handlerReconnectResponse(actionData);
            } else {
               if (!window.isKioskSession) {
                  this.sessionManagementService.reconnectAllApplications(
                     actionData,
                     this.currentController,
                     this._jscdkHandler,
                     this
                  );
               } else {
                  Logger.info("Don't bring apps back in other farm under kiosk mode");
               }
               this.idleSessionService.setHasAppLaunched(true);
            }
            break;
         case "RequestLastUserActiveTime":
         case "AboutToTimeout":
         case "Timeout":
            this._jscdkHandler.handleLastUserActiveTime(actionName);
            break;
         case "showAboutToTimeoutDialog":
            this.idleSessionService.onIdleWarning();
            break;
         case "Locking":
            if (!clientUtil.isChromeClient()) {
               this.idleSessionService.setLockedStatus(true);
            }
            Logger.info("discarding SSO for idle timeout");
            this.eventBusService.dispatch(new BusEvent.DiscardAuthInfo());
            return;
         case "AlreadyLock":
            if (!clientUtil.isChromeClient()) {
               this.idleSessionService.onIdleLocked();
            }
            Logger.info("SSO already discarded for idle timeout");
            this.eventBusService.dispatch(new BusEvent.DiscardAuthInfo());
            if (this.viewClientModel.isAnonymousMode) {
               if (this.clientModeService.clientMode === "desktop") {
                  this._jscdkHandler._setCallbackFunction(() => {
                     this.baseViewService.gotoPortal();
                  });
               }
               this.jscdkCommonInvoker.logout();
               return;
            }
            break;
         case "DoLock":
            if (!clientUtil.isChromeClient()) {
               this.idleSessionService.onIdleLocked();
            }
            Logger.info("SSO discarded for idle timeout");
            this.eventBusService.dispatch(new BusEvent.DiscardAuthInfo());
            this.idleSessionService.setLockedStatus(true);
            break;
         case "SessionUnlocked":
            this.eventBusService.dispatch(new BusEvent.SessionUnlocked());
            this.jscdkAuthService.handleSessionUnlocked();
            return;
         //This action will be handled by the function of LockedNewTab in
         // desktop page, so don't need to break if CLIENT_MODE is desktop
         case "LockedNewTab":
            this.jscdkAuthService.handleLockedNewTab(this._jscdkHandler, actionObj);
            this.idleSessionService.setLockedStatus(true);
            break;
         case "AuthenticationStatusUnlocked":
            this.jscdkAuthService.handleAuthenticationStatusUnlocked(this);
            this.idleSessionService.setLockedStatus(false);
            break;
         case "AuthenticationStatusLocked":
            this.jscdkAuthService.sendDoUnlock();
            this.idleSessionService.setLockedStatus(true);
            break;
         case "unLock":
            this.idleSessionService.onSessionUnlockDone();
            this._jscdkHandler.handleAuthenticationStatusReAuth(actionObj);
            break;
         case "WindowsPassword":
            this.jscdkAuthService.handleWindowsPassword(this._jscdkHandler, actionObj);
            Logger.info("set isLoginInitialized for AD");
            this.loginModel.isLoginInitialized = true;
            break;
         case "WindowsPasswordExpired":
            this.jscdkAuthService.handleWindowsPasswordExpired(this._jscdkHandler, actionObj);
            break;
         case "SecurIDPasscode":
         case "SecurIDNextTokenCode":
         case "SecurIDPinChange":
         case "SecurIDWait":
            this.jscdkAuthService.authEventToUI(actionObj);
            if (actionData.reAuth) {
               this._jscdkHandler.handleReauthResponse(actionObj);
            }
            break;
         case "certAuthSubmit":
            if (this.appConnectCallBack) {
               this.appConnectCallBack();
            }
            break;
         case "UpdateAzureWaitingUI": {
            const waitMinutes = Math.ceil(actionData.maxWaitTime / 60 / 1000);
            const type = actionData.type;
            this.eventBusService.dispatch({
               type: "UpdateAzureWaitingUI",
               data: {
                  type: type,
                  waitMinutes: waitMinutes
               }
            });
            return;
         }
         case "DestroyAzureWaitingUI":
            this.eventBusService.dispatch({ type: "DestroyAzureWaitingUI" });
            return;
         case "AzureReconnectionErrorUI":
            this.eventBusService.dispatch(new BusEvent.HeadRoomTimeoutErrorMsg(actionObj));
            return;
         case "closeOnExit":
            if (this.clientModeService.clientMode === "launcher") {
               this.utilService.runFunctionIfNotHWSOrF5();
            } else {
               // Horizon workspace will launch us into a separate tab, so we
               // want to close on exit in this case. To fix 2163187

               // NOTE: This won't work in any modern browser when the window
               // is not opened via script / ahref.
               window.open("", "_self", "");
               window.close();
            }
            return;
         case "SAML":
            Logger.info("set isLoginInitialized for SAML");
            this.loginModel.isLoginInitialized = true;
            this.rootModel.set("partialAuthedWithSAML", true);
            break;
         case "csrfCheckFailed": {
            this.modalDialogService.showError({
               data: {
                  titleKey: "ERROR",
                  contentKey: "SRF_TOKEN_ERROR"
               },
               callbacks: {
                  confirm: () => {
                     this.baseViewService.gotoPortal();
                  }
               }
            });
            break;
         }
         case "SetBrokerUrlRedirectionClientRule":
            this.eventBusService.dispatch(new BusEvent.UpdateUrlRedirectionClientRuleMsg(actionData, "broker"));
            break;
         case "SyncPolicyUrlRedirectionClientRule":
            this.eventBusService.dispatch({
               type: "syncPolicyUrlRedirectionClientRule"
            });
            break;
         case "Portal":
            if (this.appViewService.isAuthCanceledOnSAML()) {
               this.eventBusService.dispatch({ type: "AuthCanceledForSAML" });
            }
            return;
         case "GetOnRampConfig":
            this.eventBusService.dispatch(new BusEvent.SetRampConfig(actionData));
            break;
         default:
            Logger.error("unknown type of response returned");
      }

      /**
       * Add triggerItemInfo for all launch
       * Add ItemLaunchSucceeded event for all item launch
       * for bug 3161107 VCART-483
       */
      switch (actionName) {
         case "ShowAppBlastApplication":
         case "ApplicationAlreadyConnected":
         case "ShowAppBlastApplicationSession":
         case "ApplicationSessionAlreadyConnected":
         case "ShowAppBlastDesktop":
            Logger.debug(`Launch item successful for ${actionName}`);
            this.eventBusService.dispatch(
               new BusEvent.ItemLaunchSucceeded({
                  itemType: actionData.triggerItemInfo.type,
                  launchItemId: actionData.triggerItemInfo.id,
                  clientData: {
                     triggerItemInfo: actionData.triggerItemInfo
                  }
               })
            );
            break;
         default:
            Logger.debug(`No item launch for ${actionName}`);
            break;
      }

      this._jscdkHandler.ajaxBusy = false;
      if (this.clientModeService.clientMode === "desktop") {
         switch (actionName) {
            case "GetLaunchItems":
               this.entitledItemsModel.init(actionObj);
               this.multiSessionSingleUserService.setApps(actionData.applications);
               this.initDoneDeferred.resolve();
               break;
            case "WindowsPassword":
            case "SecurIDPasscode":
            case "SecurIDNextTokenCode":
            case "SecurIDPinChange":
            case "SecurIDWait":
               //For reAuth dialog update
               if (this.data.content && this.data.content.reAuth) {
                  this.appViewService.handleResponse(actionObj);
               }
               break;
            default:
               Logger.debug("no extra action for jscdk response on desktop: " + actionName);
         }
      } else {
         if (!!this.data && !!this.data.content && !!this.data.content.clientConfig) {
            this.sessionDataService.updateLocalAndRemoteClientConfig(this.data.content.clientConfig);
         }

         if (this.appViewService.hadBeenSwitchedToAngular(actionObj)) {
            this.appViewService.handleResponse(actionObj);
         } else {
            this.sessionDataUpdateService.handleEvent(this.currentController, actionName, actionObj);
         }
      }
   };
   public init = () => {
      if (!this.initDoneDeferred) {
         this.initDoneDeferred = new PromiseWrapper();
      }

      // Configure rebrand name to replace the title element
      if (this.viewClientModel.rebrandName) {
         this.utilService.rebrand(this.viewClientModel.rebrandName);
      }

      const data = this.sessionDataService.getSessionData();
      {
         if (this.clientModeService.clientMode === "desktop" && !data.brokerUrl) {
            this.baseViewService.gotoPortal();
            return;
         }

         this._jscdkHandler.initJSCDK(this._jscdkHandler, data.brokerUrl);
         this.jscdkCommonInvoker.jscdkHandler = this._jscdkHandler;
         if (this.sessionManagementCenterManager) {
            this.sessionManagementCenterManager.setJscdkWrapper(this);
         }
         if (clientUtil.isChromeClient()) {
            this.remoteSessionManager.setJscdkWrapper(this);
         }
         // pass in this._jscdkHandler as main service for quickly resolve
         // a blocking defect for Q2, after refactor it could
         // easily changed to look better.
         if (this.clientModeService.clientMode === "desktop") {
            this.applicationSessionsModel.getApplicationSessions((applicationSessions) => {
               this._applicationSessions = applicationSessions;
            }, this._jscdkHandler);
         }

         this.idleSessionService.init(
            () => {
               this._jscdkHandler.handleLastUserActiveTime("showAboutToTimeoutDialog");
            },
            this.jscdkCommonInvoker.sendDoLock,
            this.jscdkCommonInvoker.sendDoUnlock
         );
         if (this.clientModeService.clientMode === "desktop") {
            this.jscdkCommonInvoker.startIdleTimer("Desktop", {
               brokerUrl: data.brokerUrl
            });
         }
      }
   };

   public tearDown = () => {
      this.data = null;
      this.currentController = null;

      this.idleSessionService.tearDown();
      this.jscdkCommonInvoker.stopIdleTimer();
      this.initDoneDeferred = null;
   };

   /**
    * try launch a user-entitled item.
    *
    * @itemId: of string,
    *    be the target entitled itemID, do not use object to decompose
    *    the
    *    model of view
    *    (might stored in the sub-controller of view) and the one for
    *    higher level.
    * @type: of itemTypeEnum,
    *    be the type of the item.
    * @currentConnectedSessionKey: of string or null,
    *    be the mode + orignal ID(running environment ID of virtal agent?),
    *    and used to check whether the current session can be reused for new
    *    application. when launching a desktop, this param will be
    *    ignored.
    * @callbackFunc: of function,
    *    be the callback function that handle the response like:
    *    {
    *       success: of bool,
    *       sessionLocked: of bool or null(when return error),
    *       blastURL: of string or null,
    *       error: of object,
    *    }
    * @return: of bool
    *    indicate whether the params used for calling is valid
    */
   public launch = (itemId, type, callbackFunc) => {
      if (this._jscdkHandler.ajaxBusy) {
         Logger.debug("Error: do not support do multiple actions at the same time in the jscdkWrapper");
         return false;
      }
      this._jscdkHandler._setCallbackFunction(callbackFunc);

      if (type === this.itemTypeEnum.desktop) {
         this._launchDesktop(itemId);
      } else if (type === this.itemTypeEnum.application) {
         this._launchApplication(itemId);
      } else {
         Logger.debug("Error: unknown item type in jscdkWrapper.launch()");
         return false;
      }
      return true;
   };

   /**
    * This function is different from wmksService.hasApplicationSession
    * This function will return the whether user has exsiting application
    * session, from get-launch-item msg. While
    * wmksService.hasApplicationSession will return whether user has
    * running application session.
    */
   public hasApplicationSession = () => {
      const deferred = new PromiseWrapper();

      this.applicationSessionsModel.getApplicationSessions((applicationSessions) => {
         deferred.resolve(applicationSessions);
      }, this._jscdkHandler);

      return deferred.promise;
   };

   public resetAllApplications = (callbackFunc, hasSingleSession) => {
      if (hasSingleSession) {
         if (this._jscdkHandler.ajaxBusy) {
            Logger.debug("Error: do not support do multiple actions at the same time in the jscdkWrapper");
            return false;
         }
         this._jscdkHandler._setCallbackFunction(callbackFunc);
         this.jscdkCommonInvoker.resetAllApplicationSessions();
      }
      // for multi sessions, it's safe to call even when no session exist
      setTimeout(() => {
         this.multiSessionSingleUserService.resetAllMultiSessions();
      }, 0);
   };

   /**
    * if kill an application, this function will send killSession to the
    * broker, and it would made the current session invalid, so a dialog
    * might be needed.
    *
    * if kill a desktop, this function will send resetDesktop to the
    * broker, and it would make the current session invalid, so might
    * need to disconnect the wmks also.
    */
   public killItem = (itemId, type, callbackFunc) => {
      let isDesktop;

      if (this._jscdkHandler.ajaxBusy) {
         Logger.debug("Error: do not support do multiple actions at the same time in the jscdkWrapper");
         return false;
      }

      this._jscdkHandler._setCallbackFunction(callbackFunc);
      if (type === this.itemTypeEnum.desktop) {
         isDesktop = true;
      } else if (type === this.itemTypeEnum.application) {
         isDesktop = false;
      } else {
         Logger.debug("Error: unknown item type in jscdkWrapper.launch()");
         return false;
      }
      this.jscdkCommonInvoker.resetItem(itemId, isDesktop);
      return true;
   };

   public resetDesktop = (desktopId, callbackFunc) => {
      if (this._jscdkHandler.ajaxBusy) {
         Logger.debug("Error: do not support do multiple actions at the same time in the jscdkWrapper");
         return false;
      }
      this._jscdkHandler._setCallbackFunction(callbackFunc);

      this.jscdkCommonInvoker.reset(desktopId);
   };

   public restartDesktop = (desktopId, callbackFunc) => {
      if (this._jscdkHandler.ajaxBusy) {
         Logger.debug("Error: do not support do multiple actions at the same time in the jscdkWrapper");
         return false;
      }
      this._jscdkHandler._setCallbackFunction(callbackFunc);

      this.jscdkCommonInvoker.restart(desktopId);
   };

   /*
    * logout
    *
    * Logout the user from the broker and then invoke the callback
    *
    * @param callbackFunc callback to call once the user is logged out
    */
   public logout = (callbackFunc) => {
      this._jscdkHandler._setCallbackFunction(callbackFunc);
      this.jscdkCommonInvoker.logout();
   };

   public reconnectApplicationSessions = (callbackFunc, callbackParam, targetAppSessionIdList, excludedSessionId) => {
      if (this._jscdkHandler.ajaxBusy) {
         Logger.debug("Error: do not support do multiple actions at the same time in the jscdkWrapper");
         return false;
      }
      this._jscdkHandler._setCallbackFunction(callbackFunc, callbackParam);
      this.jscdkCommonInvoker.reconnectApplicationSessions(targetAppSessionIdList, excludedSessionId);
   };

   public getUserGlobalPref = (): Promise<XMLPreference> => {
      // the promise we will return
      let deferred;

      // If there is promise in flight use that promise
      if (this.getUserGlobalPrefDeferred) {
         deferred = this.getUserGlobalPrefDeferred;
      } else {
         deferred = new PromiseWrapper();

         // If there is no promise in flight but
         // get-user-global-preferences exists then that means that the
         // preferences where read already and that the process of
         // getting the preferences has completed.
         if (globalArray["get-user-global-preferences"]) {
            deferred.resolve(this.userGlobalPref.getPrefData());
         } else {
            // Otherwise we need to read the preferences because they
            // have not been read yet.
            this.getUserGlobalPrefDeferred = deferred;
            this.jscdkCommonInvoker.getUserGlobalPref();
         }
      }

      return deferred.promise;
   };

   public addInitDoneCallback = (callback) => {
      if (!this.initDoneDeferred) {
         this.initDoneDeferred = new PromiseWrapper();
      }
      this.initDoneDeferred.promise.then(callback);
   };

   public abortRequest = (onDone, doNotBlock) => {
      this._jscdkHandler.ajaxBusy = false;
      this.jscdkCommonInvoker.cancelCurrentRequest(onDone, doNotBlock);
   };

   public setCallBackForReAuth = (callback) => {
      this._jscdkHandler._setCallbackFunction(callback, this.appConnectCallBack);
   };

   /**
    * Call timer controller in JSCDK lib.
    *
    * need this for chrome client, or this is no valid currentController
    * for this.currentController.connecting(); and the truth that sending
    * a request to timerCtrl should not change the UI in any way.
    *
    * @param action Object:
    *     the action sent to timer controller
    * @return nothing
    */
   public invokeTimerCtrl = (action) => {
      const actionString = JSON.stringify(action);

      if (action.method !== "TimerCtrl") {
         Logger.debug("invokeTimerCtrl must be used to send action with method = 'TimerCtrl'\nreturn");
         return;
      }
      Logger.debug("UI request to TimerCtrl : " + util.censorMessage(actionString, "JSON"));
      UIEventToJSCDK(actionString);
   };

   public sendLaunchItemsXML = () => {
      this.jscdkCommonInvoker.sendLaunchItemsXML();
   };

   public invokeJSCDK = (action, doNotBlock?, doNotBusy?) => {
      this._jscdkHandler.invokeJSCDK(action, doNotBlock, doNotBusy);
   };

   /**
    * realize the old API to handle the JSCDK response, will be changed
    * after JSCDK refactor the locked status is checked before sending
    * any XML, so not included here.
    */
   public responseToJSCDKEvent = (action) => {
      this._jscdkHandler.responseToJSCDKEvent(action);
   };
}
