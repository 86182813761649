/**
 * ******************************************************
 * Copyright (C) 2016-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * multimon.service.ts -- multimonService
 *
 * The top level service of multimon which provide 2 APIs:
 *  - addMonitor
 *  - switchToSingleMon
 */

import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import Logger from "../../../../core/libs/logger";
import namedPromise from "../../../common/service/named-promise";
import { clientUtil, EventBusService, BusEvent } from "@html-core";
import { MonitorManageService } from "./monitor-manage.service";
import { MultimonModel } from "./multimon-model";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { DisplayTopologyBriefInfo } from "../../channels/commonSvcChannel";

@Injectable({
   providedIn: "root"
})
export class MultimonService {
   private static readonly MAX_WIDTH = 3840;
   private static readonly MAX_HEIGHT = 2560;

   private onQuitUICallback = null;
   private _namedPromise: any = {};
   private uiCallback = null;
   private closeSidebar = null;
   private handleResize = null;
   private _appliedTopology;
   private multiMonitorSubscriber: Subscription = null;
   private _cacheDIPMsg: BusEvent.DPIDataChangeEvent = null;

   constructor(
      private monitorManageService: MonitorManageService,
      private multimonModel: MultimonModel,
      private eventBusService: EventBusService,
      private ModalDialogService: ModalDialogService
   ) {
      namedPromise.makeObservable(this._namedPromise);
      this._namedPromise.addPromise("multimonEntered");
      this.eventBusService
         .listen(BusEvent.DPIDataChangeEvent.MSG_TYPE)
         .subscribe((msg: BusEvent.DPIDataChangeEvent) => {
            this._cacheDIPMsg = msg;
         });
   }

   public onTopologyChanged = () => {
      if (!this._isValidTopology()) {
         Logger.error("invalid topology found", Logger.DISPLAY);
         // disable quit multimon dialog, because invalidTopology dialog will pop up, no need to pop this dialog ( avoid too many dialogs pop up )
         this.monitorManageService.showQuitMultimonDialog = false;
         // not auto quit, instead, ask user to quit.
         this.uiCallback("onInvalidTopology");
         this.monitorManageService.switchToSingleMonitor();
         return;
      }
      this.sendTopologyRequest();
   };

   public onQuitMultimon = () => {
      Logger.info("quit multimon", Logger.DISPLAY);
      // clear hint here if PM want to
      this.uiCallback("onQuitMultimon", Logger.DISPLAY);
   };

   public onDPIChanged = (key, DPI) => {
      if (!this.multimonModel.wmksSession || this.multimonModel.wmksSession.key !== key) {
         return;
      }
      Logger.info("DPI Changed to " + DPI + " for multi-mon session: " + key), Logger.DISPLAY;
      this._namedPromise.then("multimonEntered", (MultiMonSessionKey) => {
         // already entered Multimon and ready to update DPI.
         if (key !== MultiMonSessionKey) {
            Logger.error(
               "session mismatch for DPI change when in multimon, " +
                  "DPI changed for : " +
                  key +
                  ", Multimon-session: " +
                  MultiMonSessionKey,
               Logger.DISPLAY
            );
            return;
         }
         this.monitorManageService.onDisplayInfoChanged({
            type: "AgentDPI",
            value: DPI
         });
      });
   };

   public onEnterMultimon = () => {
      Logger.info("enter multimon", Logger.DISPLAY);
      // pop out hint here if PM want to
      this.closeSidebar();
      this.uiCallback("onEnterMultimon");
   };

   public onSingleMonitor = () => {
      this.handleResize();
      this.uiCallback("onSingleMonitor");
      this._appliedTopology = "";
      if (this.onQuitUICallback) {
         this.onQuitUICallback();
         this.onQuitUICallback = null;
      }

      if (this.multiMonitorSubscriber) {
         this.multiMonitorSubscriber.unsubscribe();
         this.multiMonitorSubscriber = null;
      }
      this._namedPromise.resetStatus("multimonEntered");
   };

   public onMultiMonitor = () => {
      const multimonStatusDelay = 3000;
      setTimeout(() => {
         if (this.multimonModel.wmksSession) {
            this._namedPromise.resolve("multimonEntered", this.multimonModel.wmksSession.key);
         }
      }, multimonStatusDelay);
      this.uiCallback("onMultiMonitor");
   };

   public onMonitorChanged = (settings, allReady, stillUpdating) => {
      const changedStatus = {
         settings: settings,
         allReady: allReady,
         stillUpdating: !!stillUpdating
      };
      Logger.debug("monitor status changed" + JSON.stringify(changedStatus), Logger.DISPLAY);
      this.uiCallback("onMonitorChanged", changedStatus);
   };

   public setPortForExtendMonitor = (id, port) => {
      this.multimonModel.monitors[id].setPort(port);
   };

   public switchToMultimon = (
      wmksSession,
      sessionContainer,
      uiCallback,
      closeSidebar,
      handleResize,
      options,
      screenSetting
   ) => {
      Logger.debug("switching to Multimon", Logger.DISPLAY);
      if (this.multimonModel.wmksSession) {
         Logger.debug("already in multimon, skip entering again", Logger.DISPLAY);
         return;
      }
      this.closeSidebar = closeSidebar;
      this.uiCallback = uiCallback;
      this.handleResize = handleResize;
      this.monitorManageService.switchToMultiMonitor(
         wmksSession,
         sessionContainer,
         this.onQuitMultimon,
         this.onEnterMultimon,
         this.onSingleMonitor,
         this.onMultiMonitor,
         this.onTopologyChanged,
         this.onMonitorChanged,
         this.handleResize,
         options,
         screenSetting
      );
      this.onQuitUICallback = null;
   };

   public sendTopologyRequest = () => {
      const monitorSettings = this.multimonModel.monitorSettings,
         topologySettings = [],
         session = this.multimonModel.wmksSession;

      if (!session) {
         Logger.error("skip topology send due to missing multimon session");
         return;
      }

      // we can round the settings here.
      Logger.info("set display settings to " + JSON.stringify(monitorSettings), Logger.DISPLAY);
      this.multimonModel.forEachSettings((key, setting) => {
         topologySettings.push({
            rect: {
               left: setting.x,
               top: setting.y,
               right: setting.x + setting.width,
               bottom: setting.y + setting.height
            },
            monitorDPI: setting.devicePixelRatio,
            isPrimary: key === "0"
         } as DisplayTopologyBriefInfo);
      });
      if (clientUtil.isChromeClient() && this._isAppliedTopology(topologySettings)) {
         Logger.info("skip apply topology since already applied", Logger.DISPLAY);
         return;
      }
      this._appliedTopology = topologySettings;
      session.OnWmksTopologyUpdated(topologySettings, true);
   };

   public switchToSingleMon = (wmksSession) => {
      if (!this.multimonModel.wmksSession || wmksSession !== this.multimonModel.wmksSession) {
         return;
      }
      this.monitorManageService.switchToSingleMonitor();
   };

   public destroyMultimon = () => {
      if (!this.multimonModel.wmksSession) {
         return;
      }
      this.monitorManageService.switchToSingleMonitor();
   };

   public addMonitor = (
      wmksSession,
      sessionContainer,
      uiCallback,
      closeSidebar,
      handleResize,
      options,
      screenSetting
   ) => {
      if (!this.multimonModel.wmksSession) {
         this.switchToMultimon(
            wmksSession,
            sessionContainer,
            uiCallback,
            closeSidebar,
            handleResize,
            options,
            screenSetting
         );
      } else if (wmksSession === this.multimonModel.wmksSession) {
         this.monitorManageService.addMonitor(screenSetting);
      }
   };

   public isInMultiMonitorStatus = (): boolean => {
      if (this.multimonModel.wmksSession) {
         return true;
      } else {
         return false;
      }
   };

   public whenNotInMultimon = (processingFunction) => {
      if (!this.multimonModel.wmksSession) {
         processingFunction();
         return;
      }
      this.onQuitUICallback = function () {
         setTimeout(processingFunction);
      };
      if (!clientUtil.isChromeClient()) {
         this.ModalDialogService.showConfirm({
            data: {
               titleKey: "MM_QUIT_MULTIMON_T",
               contentKey: "MM_QUIT_MULTIMON_M"
            },
            callbacks: {
               confirm: () => {
                  this.monitorManageService.switchToSingleMonitor();
               },
               cancel: () => {
                  Logger.debug("cancel action to continue use multimon", Logger.DISPLAY);
               }
            }
         });
      } else {
         this.monitorManageService.switchToSingleMonitor();
      }
   };

   /**
    * Return the multimon using session
    * @return {string} return null if no session is using multimon
    */
   public getMultimonSession() {
      if (!this.multimonModel.usingMultimon) {
         return null;
      }
      return this.multimonModel.wmksSession;
   }

   /**
    * Enter the multimon mode
    */
   public enterMultimonMode = () => {
      if (!this.multimonModel.usingMultimon) {
         this.monitorManageService.enterMultimonMode();
      }
   };

   public _isValidTopology = () => {
      let hasOverSizeScreen = false;
      this.multimonModel.forEachSettings(function (key, setting) {
         hasOverSizeScreen =
            hasOverSizeScreen ||
            // some 4k monitor may have a width value of 2561, and dpi is 1.5,  2561*1.5=3941.5
            // so as a workaround, we can tolerate 2px bigger
            setting.width > MultimonService.MAX_WIDTH + 2 ||
            setting.height > MultimonService.MAX_HEIGHT + 2;
      });
      return !hasOverSizeScreen && this.multimonModel.usingMultimon;
   };

   public _isAppliedTopology = (targetTopology) => {
      return JSON.stringify(targetTopology) === JSON.stringify(this._appliedTopology);
   };
   public preparingForMultimon = () => {
      this._namedPromise.resetStatus("multimonEntered");
      if (
         this._cacheDIPMsg === null ||
         !this.multimonModel.wmksSession ||
         this._cacheDIPMsg.wmksKey !== this.multimonModel.wmksSession.key
      ) {
         this.multiMonitorSubscriber = this.eventBusService
            .listen(BusEvent.DPIDataChangeEvent.MSG_TYPE)
            .subscribe((msg: BusEvent.DPIDataChangeEvent) => {
               this.onDPIChanged(msg.wmksKey, msg.remoteScaleDPI);
            });
         Logger.debug("subscribe to DPI change", Logger.DISPLAY);
      } else {
         this.onDPIChanged(this._cacheDIPMsg.wmksKey, this._cacheDIPMsg.remoteScaleDPI);
         Logger.debug("enter multimon based on DPI" + this._cacheDIPMsg.remoteScaleDPI, Logger.DISPLAY);
         this._cacheDIPMsg = null;
      }
   };

   public cancelForMultimon = () => {
      this._namedPromise.resetStatus("multimonEntered");
      if (this.multiMonitorSubscriber) {
         this.multiMonitorSubscriber.unsubscribe();
         this.multiMonitorSubscriber = null;
      }
   };

   public waitNotInMultimon = (key, processingFunction) => {
      if (
         !this.multimonModel.wmksSession ||
         !this.multimonModel.wmksSession.key ||
         this.multimonModel.wmksSession.key !== key
      ) {
         processingFunction();
         return;
      }
      this.onQuitUICallback = () => {
         setTimeout(processingFunction);
      };
   };

   public checkSessionInMultimon = (key: string, callback: Function) => {
      if (
         !this.multimonModel.wmksSession ||
         !this.multimonModel.wmksSession.key ||
         this.multimonModel.wmksSession.key !== key
      ) {
         callback();
         return;
      }
      this.onQuitUICallback = () => {
         setTimeout(callback);
      };
   };
}
