/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export interface IStorage {
   getItem(inKey: string): any;
   setItem(inKey: string, value: any): void;
   removeItem(inKey: string): void;
}

export abstract class IStorageService {
   public abstract set(key: string, value: any);
   public abstract get(key: string): any;
   public abstract remove(key: string);
   public abstract setSession(key: string, value: any);
   public abstract getSession(key: string): string;
   public abstract removeSession(key: string);
   public abstract clearSession();
}
