/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { HTML5MMR_CONST, JKEYS, WEB_COMMAND } from "./html5MMR-consts";
import { WebRTCInstance } from "./webRTCInstance";
import Logger from "../../../../core/libs/logger";
import { getUuid } from "@html-core";

export class VMPeerConnection {
   private peerId: number;
   private webRTCInstance: WebRTCInstance;
   private peerConnection: any;

   public isUnifiedPlan: boolean; // other modules need to refer this value
   private receiverTrackTimer: any;
   private receiverInfos: Map<string, ReceiverInfo>;

   private senderTrackTimer: any;
   private preSendersInfo: string;

   private audioSender: RTCRtpSender;
   private videoSender: RTCRtpSender;
   private localStreams: Map<string, MediaStream>;
   private bwe: number;

   // for WebRTC 1.0
   private transceiverInfos: Map<string, TransceiverInfo>;
   private transceiverTrackTimer: any;
   private preTransInfos: string;

   // for datachannel
   private datachannels: Map<number, vmDatachannel>;

   private logger = new Logger(Logger.WEBRTC);

   // global transceiver id suffix for transceivers created on client side
   static randomTransceiverId = 0;

   constructor(peerId: number, webRTCInstance: WebRTCInstance, configuration: object, options: object) {
      this.peerId = peerId;
      this.receiverTrackTimer = null;
      this.senderTrackTimer = null;
      this.preSendersInfo = "";
      this.receiverInfos = new Map<string, ReceiverInfo>();
      this.localStreams = new Map<string, MediaStream>();
      this.webRTCInstance = webRTCInstance;

      // for WebRTC 1.0
      this.transceiverInfos = new Map<string, TransceiverInfo>();
      this.transceiverTrackTimer = null;
      this.preTransInfos = "";

      // for datachannel
      this.datachannels = new Map<number, vmDatachannel>();

      let sdpSemantics = "";
      if (configuration) {
         sdpSemantics = configuration[JKEYS.SDP_SEMANTICS];
      }
      this.isUnifiedPlan = false;
      if (sdpSemantics && sdpSemantics === HTML5MMR_CONST.UNIFIED_PLAN) {
         this.isUnifiedPlan = true;
      }
      this.logger.info("RTCPeerConnection => constructor with: isUnifiedPlan: " + this.isUnifiedPlan);
      // Only support Chrome now
      // @ts-ignore
      const PeerConnection: any = window.webkitRTCPeerConnection; // || window.mozRTCPeerConnection || window.RTCPeerConnection
      if (PeerConnection) {
         this.peerConnection = new PeerConnection(configuration, options);
      } else {
         return null;
      }
   }

   public updateBWE = (bwe: number) => {
      this.bwe = bwe;
      this.localStreams.forEach((stream) => {
         this._updateLocalStreamTracks(stream);
      });
   };

   private _updateLocalStreamTracks = (stream: MediaStream) => {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
         track["_bwe"] = this.bwe;
      });
   };

   /**
    * Bind call back functions for RTCPeerConnection
    *
    */
   public init = () => {
      const pc = this.peerConnection; // for short name
      if (!pc) {
         this.logger.error("RTCPeerConnection => Init peer connection failed since peer connection is not created.");
         return;
      }
      pc.onnegotiationneeded = (evt) => {
         this.logger.info("RTCPeerConnection => onnegotiationneeded for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "negotiationneeded"
         });
      };

      pc.onicecandidate = (evt) => {
         this.logger.info("RTCPeerConnection => onicecandidate for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "icecandidate",
            candidate: evt.candidate
         });
      };

      pc.onsignalingstatechange = (evt) => {
         this.logger.info("RTCPeerConnection => onsignalingstatechange for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "signalingstatechange"
         });
      };

      pc.oniceconnectionstatechange = (evt) => {
         this.logger.info("RTCPeerConnection => oniceconnectionstatechange for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "iceconnectionstatechange"
         });
      };

      pc.onicegatheringstatechange = (evt) => {
         this.logger.info("RTCPeerConnection => onicegatheringstatechange for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "icegatheringstatechange",
            iceGatheringState: evt[JKEYS.ICE_GATHERING_STATE]
         });
      };

      pc.ontrack = (evt) => {
         this.logger.info("RTCPeerConnection => ontrack for peer: " + this.peerId);
         if (!this.isUnifiedPlan) {
            this.logger.info("RTCPeerConnection => ignore ontrack event for pre webrtc 1.0.");
            return;
         }
         const stream = evt.streams && evt.streams[0]; // there should be only 1 stream in this array

         if (stream) {
            //Add onended event for the first track
            const track = stream.getTracks().length ? stream.getTracks()[0] : null;
            if (track) {
               track.onended = (evt) => {
                  this.sendWebRTCResponse({
                     evt: "removeStream",
                     stream: stream.id
                  });
               };
            }
            // update each stream and track
            stream.getTracks().forEach((t) => {
               this.webRTCInstance.updateTrack(t.id, t);
            });
            this.webRTCInstance.updateStream(stream.id, stream);

            if (evt.transceiver && evt.transceiver.receiver) {
               const result = {};
               this.transceiverInfos.forEach((transceiverInfo) => {
                  const transceiver = transceiverInfo.getTransceiver();
                  if (transceiver === evt.transceiver) {
                     result[JKEYS.TRANSCEIVER] = transceiverInfo.getTransceiverInfo(true);
                  }
               });
               // add new transceiver info if we could not find matched one
               if (!result[JKEYS.TRANSCEIVER]) {
                  const transceiverInfo = this.addTransceiverInfo(evt.transceiver, "");
                  result[JKEYS.TRANSCEIVER] = transceiverInfo.getTransceiverInfo(true);
               }
               result[JKEYS.STREAM] = {};
               result[JKEYS.STREAM][JKEYS.ID] = stream.id;
               result[JKEYS.STREAM][JKEYS.ACTIVE] = "true";
               result[JKEYS.STREAM][JKEYS.TRACK] = [];
               result[JKEYS.STREAM][JKEYS.TRACK][0] = ReceiverInfo.getRemoteTrackInfo(evt.track);
               result[JKEYS.ID] = -1;
               result[JKEYS.PEER] = this.peerId;
               result[JKEYS.EVT] = HTML5MMR_CONST.ON_TRACK;

               this.webRTCInstance.sendRTCResponse(WEB_COMMAND.EVENT_ONTRACK, result);
            }
         }
      };

      pc.onaddstream = (evt) => {
         this.logger.info("RTCPeerConnection => onaddstream for peer: " + this.peerId);
         const mediaStream = evt.stream;
         mediaStream.getTracks().forEach((t) => {
            this.webRTCInstance.updateTrack(t.id, t);
         });
         this._updateLocalStreamTracks(mediaStream);
         this.webRTCInstance.updateStream(mediaStream.id, mediaStream);

         mediaStream.onremovetrack = (event) => {
            this.sendWebRTCResponse({
               evt: "removeStream",
               stream: mediaStream.id,
               trackId: event.track.id
            });
         };

         if (this.isUnifiedPlan) {
            this.logger.info("RTCPeerConnection => ignore onaddstream event for webrtc 1.0.");
            return;
         }
         this.sendWebRTCResponse(
            {
               evt: "addStream",
               stream: this.remoteMediaStreamToObj(mediaStream)
            },
            false
         );
      };

      pc.onremovestream = (evt) => {
         this.logger.info("RTCPeerConnection => onremovestream for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "removeStream",
            stream: evt.stream.id
         });
      };

      pc.onconnectionstatechange = (evt) => {
         this.logger.info("RTCPeerConnection => onconnectionstatechange for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "connectionstatechange"
         });
      };

      pc.ondatachannel = (evt) => {
         this.logger.info("RTCPeerConnection => ondatachannel for peer: " + this.peerId);
         const dc = evt.channel;
         // Data channel is created by the other side. We should create a record to track it
         if (!this.datachannels.get(dc.id)) {
            const _vmDatachannel = new vmDatachannel(this.webRTCInstance, this, this.peerId, -1, dc.label, {}, dc);
            this.datachannels.set(dc.id, _vmDatachannel);
         }

         if (dc) {
            const event = {};
            event[JKEYS.EVT] = "ondatachannel";
            event[JKEYS.PEER] = this.peerId;
            event[JKEYS.CHANNEL] = {};

            event[JKEYS.CHANNEL][JKEYS.ID] = dc.id;
            event[JKEYS.CHANNEL][JKEYS.LABEL] = dc.label;
            event[JKEYS.CHANNEL][JKEYS.RELIABLE] = dc.reliable;
            event[JKEYS.CHANNEL][JKEYS.ORDERED] = dc.ordered;
            event[JKEYS.CHANNEL][JKEYS.PROTOCOL] = dc.protocol;
            event[JKEYS.CHANNEL][JKEYS.NEGOTIATED] = dc.negotiated;
            event[JKEYS.CHANNEL][JKEYS.MAXRETRANSMITS] = dc.maxRetransmits;
            event[JKEYS.CHANNEL][JKEYS.NEGOTIATED] = dc.negotiated;
            if (dc.maxPacketLifeTime) {
               event[JKEYS.CHANNEL][JKEYS.MAXPACKETLIFETIME] = dc.maxPacketLifeTime;
            }
            event[JKEYS.CHANNEL][JKEYS.READY_STATE] = dc.readyState;

            this.webRTCInstance.sendRTCResponse(WEB_COMMAND.EVENT_ONDATACHANNEL, event);
         }
      };

      pc.onconnectionstatechange = (evt) => {
         this.logger.info("RTCPeerConnection => onconnectionstatechange for peer: " + this.peerId);
         this.sendWebRTCResponse({
            evt: "connectionstatechange"
         });
      };
   };

   /**
    *  Close peer connection
    * @returns
    */
   public close = () => {
      // clearing an interval timer of null is fine
      clearInterval(this.receiverTrackTimer);
      this.receiverTrackTimer = null;
      clearInterval(this.senderTrackTimer);
      this.senderTrackTimer = null;
      clearInterval(this.transceiverTrackTimer);
      this.transceiverTrackTimer = null;

      this.receiverInfos.clear();
      this.preSendersInfo = "";
      this.preTransInfos = "";
      this.audioSender = null;
      this.videoSender = null;
      this.localStreams.clear();
      this.transceiverInfos.clear();
      this.datachannels.forEach((dc) => {
         dc.close();
      });
      this.datachannels.clear();
      return this.peerConnection.close();
   };

   /**
    *  Create an offer
    * @param {object} options for create offer
    * @returns {Promise}
    */
   public createOffer = (option: any) => {
      return this.peerConnection.createOffer(option);
   };

   /**
    *  Create an answer
    *
    * @returns {Promise}
    */
   public createAnswer = () => {
      return this.peerConnection.createAnswer();
   };

   /**
    *  Set RTCSessionDescription for local peer connection
    * @param {RTCSessionDescription} desc local descrition
    * @returns {Promise}
    */
   public setLocalDescription = async (desc: any) => {
      return this.peerConnection.setLocalDescription(desc);
   };

   /**
    *  Set RTCSessionDescription for remote end point
    * @param {RTCSessionDescription} desc remote descrition
    * @returns {Promise}
    */
   public setRemoteDescription = async (desc: any) => {
      return this.peerConnection.setRemoteDescription(desc);
   };

   /**
    *  Add media stream to local peer connection
    * @param {mediaStream} MediaStream
    * @param {streamId} string, the streamId on agent side, NOT mediaStream.id
    */
   public addStream = (mediaStream: MediaStream, streamId: string) => {
      const [audioTrack] = mediaStream.getAudioTracks();
      const [videoTrack] = mediaStream.getVideoTracks();

      // Keep the records for sending out information
      this.localStreams.set(streamId, mediaStream);
      this._updateLocalStreamTracks(mediaStream);

      if (audioTrack) {
         this.audioSender = this.peerConnection.addTrack(audioTrack, mediaStream);
      }

      if (videoTrack) {
         this.videoSender = this.peerConnection.addTrack(videoTrack, mediaStream);
      }
   };

   /**
    *  Add media track to local peer connection
    *
    * @param {object} cmdObj given command json object
    * @param {MediaStreamTrack} mediaTrack
    * @param {MediaStream} mediaStream
    */
   public addTrack = (cmdObj, mediaTrack: MediaStreamTrack, mediaStream: MediaStream) => {
      this.logger.info("RTCPeerConnection => handle addTrack cmd.");
      const streamId = cmdObj[JKEYS.SID];
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      // Keep the records for sending out information
      this.localStreams.set(streamId, mediaStream);
      this._updateLocalStreamTracks(mediaStream);

      if (this.isUnifiedPlan) {
         let sender: RTCRtpSender;
         try {
            sender = this.peerConnection.addTrack(mediaTrack);
         } catch (error) {
            const internalError = HTML5MMR_CONST.RTCErrorType.INTERNAL_ERROR;
            this.webRTCInstance.sendRTCErrorResult(cmdObj, internalError, "add track failed.");
            return;
         }
         const transceivers = this.peerConnection.getTransceivers();
         for (let i = 0; i < transceivers.length; i++) {
            // update local transceiver info record with given id
            if (transceivers[i].sender === sender) {
               const transceiverInfo = new TransceiverInfo(transceiverId, transceivers[i], this.webRTCInstance);
               this.transceiverInfos.set(transceiverId, transceiverInfo);
               break;
            }
         }
         this.startTrackTransceivers();
         const result = {};
         result[JKEYS.TRANSCEIVERS] = [];
         result[JKEYS.TRANSCEIVERS][0] = this.transceiverInfos.get(transceiverId).getTransceiverInfo(true);
         this.webRTCInstance.sendSuccessResult(cmdObj, result, JKEYS.TRANSCEIVERS);
      } else {
         if (mediaTrack.kind === "audio") {
            this.audioSender = this.peerConnection.addTrack(mediaTrack, mediaStream);
            this.audioSender[JKEYS.ID] = mediaTrack.id;
         } else if (mediaTrack.kind === "video") {
            this.videoSender = this.peerConnection.addTrack(mediaTrack, mediaStream);
            this.videoSender[JKEYS.ID] = mediaTrack.id;
         } else {
            this.logger.error("RTCPeerConnection => handle addTrack, invalid track type.");
            return;
         }
         this.startTrackSenders();
         this.webRTCInstance.sendSuccessResult(cmdObj);
      }
   };

   /**
    *  Remove media stream from local peer connection
    * @param {mediaStream} MediaStream
    * @param {streamId} string, the streamId on agent side, NOT mediaStream.id
    */
   public removeStream = (mediaStream: MediaStream, streamId: string) => {
      const audioTracks = mediaStream.getAudioTracks();
      const videoTracks = mediaStream.getVideoTracks();

      this.localStreams.delete(streamId);

      if (audioTracks && audioTracks.length > 0 && this.audioSender) {
         this.peerConnection.removeTrack(this.audioSender);
         this.audioSender = null;
      }

      if (videoTracks && videoTracks.length > 0 && this.videoSender) {
         this.peerConnection.removeTrack(this.videoSender);
         this.videoSender = null;
      }
   };

   /**
    *  Stop the sender which carries related media track
    *
    * @param {string} senderId, actually the trackId on agent side
    */
   public removeTrack = (senderId: string) => {
      const senders = this.peerConnection.getSenders();
      for (let i = 0; i < senders.length; i++) {
         if (senders[i].id === this.webRTCInstance.replaceServerStreamId(senderId)) {
            this.peerConnection.removeTrack(senders[i]);
            break;
         }
      }
      if (this.peerConnection.getSenders().length === 0) {
         // stop track senders if all senders are stopped
         clearInterval(this.senderTrackTimer);
      }
   };

   /**
    *  For pre WebRTC 1.0
    *  Get statistical data of this peer connection
    * @returns {Promise}
    */
   public getStatsV0 = (onSuccess, onFailure) => {
      this.peerConnection.getStats(onSuccess, onFailure);
   };

   /**
    *  Get statistical data of this peer connection
    * @returns {Promise}
    */
   public getStats = () => {
      return this.peerConnection.getStats();
   };

   private sendWebRTCResponse = (data: any, needDetails = true) => {
      data[JKEYS.ID] = -1;
      data[JKEYS.PEER] = this.peerId;
      if (needDetails) {
         data[JKEYS.DETAILS] = this.getPCDetailsEx();
      }
      this.webRTCInstance.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVT, data);
   };

   public getPCDetailsEx = () => {
      const details = this.getPCDetails();
      if (this.peerConnection.localDescription) {
         details[JKEYS.LOCAL_DESCRIPTION] = this.peerConnection.localDescription;
      }
      if (this.peerConnection.remoteDescription) {
         details[JKEYS.REMOTE_DESCRIPTION] = this.peerConnection.remoteDescription;
      }

      // Replace mid in sdp
      if (details[JKEYS.LOCAL_DESCRIPTION] && details[JKEYS.LOCAL_DESCRIPTION][JKEYS.SDP]) {
         details[JKEYS.LOCAL_DESCRIPTION][JKEYS.SDP] = this.webRTCInstance.replaceClientStreamId(
            details[JKEYS.LOCAL_DESCRIPTION][JKEYS.SDP]
         );
      }
      return details;
   };

   /**
    * Start the timer to poll receiver information and send to agent every 1 second
    *
    */
   public startTrackReceivers = () => {
      if (this.receiverTrackTimer) {
         // already started
         return;
      }

      this.receiverTrackTimer = setInterval(() => {
         // get receivers info every 1 second
         this.onPollReceivers();
      }, 1000);
      this.onPollReceivers();
   };

   /**
    * Poll receiver information and send to agent every 1 second
    *
    */
   private onPollReceivers = () => {
      try {
         const newInfos = this.getReceiverInfos();
         const diff = this.getReceiverInfosDiff(newInfos);
         this.receiverInfos = newInfos;
         if (Object.keys(diff).length > 0) {
            diff[JKEYS.PEER] = this.peerId;
            diff[JKEYS.EVT] = "onreceivers";
            this.webRTCInstance.sendRTCResponse(WEB_COMMAND.EVENT_ONRECEIVERS, diff);
         }
      } catch (error) {
         this.logger.error("RTCPeerConnection => Failed to poll receivers info.");
      }
   };

   /**
    * Collection information of current receivers
    * @returns { Map<string, ReceiverInfo> }
    */
   private getReceiverInfos = () => {
      const receivers = this.peerConnection.getReceivers();
      const receiverInfos = new Map();
      receivers.forEach((receiver) => {
         const track = receiver.track;
         const trackId = track.id;
         const receiverInfo = new ReceiverInfo(receiver);

         // use trackId as the receiver id(key)
         receiverInfos.set(trackId, receiverInfo);
      });
      return receiverInfos;
   };

   /**
    * Compare the diff of new recevier infos with old one
    *
    * @param {Map<string, ReceiverInfo>} newInfos
    * @returns {object} the diff object
    */
   private getReceiverInfosDiff = (newInfos) => {
      const diff = {};
      newInfos.forEach((newInfo, id) => {
         let oldInfo = this.receiverInfos.get(id);
         if (!oldInfo) {
            oldInfo = new ReceiverInfo(null); // just for reuse the rest of the code
         }
         const receiverDiff = oldInfo.diff(newInfo.getReceiverInfo());

         if (Object.keys(receiverDiff).length > 0) {
            // has diff
            receiverDiff[JKEYS.ID] = id;
            diff[JKEYS.RECEIVERS] = diff[JKEYS.RECEIVERS] || [];
            diff[JKEYS.RECEIVERS].push(receiverDiff);
         }
      });

      // remove items
      this.receiverInfos.forEach((info, id) => {
         if (!newInfos.get(id)) {
            diff[JKEYS.RECEIVERS] = diff[JKEYS.RECEIVERS] || [];
            diff[JKEYS.RECEIVERS].push({
               op: "-",
               id: id
            });
         }
      });
      return diff;
   };

   /**
    * Start the timer to poll senders information and send to agent every 1 second
    *
    */
   public startTrackSenders = () => {
      if (this.senderTrackTimer) {
         // already started
         return;
      }

      this.senderTrackTimer = setInterval(() => {
         // get sender info every 1 second
         this.onPollSenders();
      }, 1000);
      this.onPollSenders();
   };

   /**
    * Poll senders information and send to agent every 1 second
    *
    */
   private onPollSenders = () => {
      const senders = this.peerConnection.getSenders();
      if (senders.length > 0) {
         const evt = {};
         evt[JKEYS.PEER] = this.peerId;
         evt[JKEYS.EVT] = HTML5MMR_CONST.ON_SENDERS;

         senders.forEach((sender) => {
            const parameters = sender.getParameters();
            if (Object.keys(parameters).length > 0) {
               const senderInfo = {};
               if (!sender.id) {
                  if (sender.track && sender.track.id) {
                     sender.id = sender.track.id;
                  } else {
                     sender.id = getUuid();
                  }
               }
               senderInfo[JKEYS.ID] = sender.id;
               senderInfo[JKEYS.PARAMETERS] = parameters;
               evt[JKEYS.SENDERS] = evt[JKEYS.SENDERS] || [];
               evt[JKEYS.SENDERS].push(senderInfo);
            }
         });
         if (JSON.stringify(evt) === this.preSendersInfo) {
            // Compare with previous record
            return;
         }
         this.preSendersInfo = JSON.stringify(evt); // save the new info
         this.webRTCInstance.sendRTCResponse(WEB_COMMAND.EVENT_ONSENDERS, evt);
      }
   };

   private remoteMediaStreamToObj = (stream) => {
      if (!(stream instanceof MediaStream)) {
         return {};
      }

      const obj = {
         active: stream.active.toString(),
         id: stream.id,
         track: []
      };
      const tracks = stream.getTracks();
      tracks.forEach((track: any) => {
         const trackInfo = ReceiverInfo.getRemoteTrackInfo(track);
         obj.track.push(trackInfo);
      });

      return obj;
   };

   public getPCDetails = () => {
      const details = {
         iceConnectionState: this.peerConnection.iceConnectionState,
         iceGatheringState: this.peerConnection.iceGatheringState,
         signalingState: this.peerConnection.signalingState,
         connectionState: this.peerConnection.connectionState
      };
      if (this.localStreams.size > 0) {
         details[JKEYS.LOCAL_STREAMS] = [];
         this.localStreams.forEach((stream, streamId) => {
            details[JKEYS.LOCAL_STREAMS].push(streamId);
         });
      }

      return details;
   };

   /**
    * Add ice candidate for pres WebRTC 1.0
    * @param {object} candidate, ice candidate
    * @param {function} onSuccess, success callback function
    * @param {function} onFailure, failure callback function
    */
   public addIceCandidateV0 = (candidate, onSuccess, onFailure) => {
      this.peerConnection.addIceCandidate(candidate, onSuccess, onFailure);
   };

   /**
    * Add ice candidate for WebRTC 1.0
    * @param {object} candidate, ice candidate
    * @returns {Promise}
    */
   public addIceCandidateV1 = (candidate) => {
      return this.peerConnection.addIceCandidate(candidate);
   };

   /**
    * Handle add transceiver command
    *
    * @param {object} cmdObj given command json object
    */
   public addTranscevier = (cmdObj) => {
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const trackOrKind = cmdObj[JKEYS.TRACK_OR_KIND];
      const init = cmdObj[JKEYS.INIT];

      // only support kind of audio and video now
      if (trackOrKind !== "audio" && trackOrKind !== "video") {
         this.webRTCInstance.sendRTCErrorResult(
            cmdObj,
            HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER,
            "unsupported kind value."
         );
         return;
      }

      const transceiver = this.peerConnection.addTransceiver(trackOrKind, init);
      const transceiverInfo = new TransceiverInfo(transceiverId, transceiver, this.webRTCInstance);
      this.transceiverInfos.set(transceiverId, transceiverInfo);

      const infoObj = transceiverInfo.getTransceiverInfo(true);
      this.startTrackTransceivers();
      const result = {};
      result[JKEYS.TRANSCEIVERS] = [infoObj];
      this.webRTCInstance.sendSuccessResult(cmdObj, result, JKEYS.TRANSCEIVERS);
   };

   /**
    * Handle set transceiver codec preference command
    *
    * @param {string} transceiverId the transceiver id
    * @param {Array} codecs
    *
    * @returns {boolean} success or failure
    */
   public setTransceiverCodecPreference = (transceiverId: string, codecs: Array<RTCRtpCodecCapability>) => {
      const transceiverInfo = this.transceiverInfos.get(transceiverId);
      if (transceiverInfo) {
         const transceiver = transceiverInfo.getTransceiver();
         if (transceiver) {
            try {
               //@ts-ignore
               transceiver.setCodecPreferences(codecs);
               return true;
            } catch (error) {
               this.logger.error("RTCPeerConnection => failed to set transceiver codec.");
               return false;
            }
         }
      }
      return false;
   };

   /**
    * Handle stop transceiver command
    *
    * @param {string} transceiverId the transceiver id
    *
    * @returns {boolean} success or failure
    */
   public stopTransceiver = (transceiverId: string) => {
      const transceiverInfo = this.transceiverInfos.get(transceiverId);
      if (transceiverInfo) {
         const transceiver = transceiverInfo.getTransceiver();
         if (transceiver) {
            transceiver.stop();
            return true;
         }
      }
      return false;
   };

   /**
    * Handle set transceiver direction command
    *
    * @param {string} transceiverId the transceiver id
    * @param {string} direction "sendrecv" || "sendonly" || "recvonly" || "inactive"
    *
    * @returns {boolean} success or failure
    */
   public setTransceiverDirection = (transceiverId: string, direction: string) => {
      const transceiverInfo = this.transceiverInfos.get(transceiverId);
      if (transceiverInfo) {
         const transceiver = transceiverInfo.getTransceiver();
         if (transceiver && transceiver.currentDirection !== "stopped") {
            // @ts-ignore
            transceiver.direction = direction;
            return true;
         }
      }
      return false;
   };

   /**
    * Start the timer to poll transceivers information and send to agent every 1 second
    *
    */
   private startTrackTransceivers = () => {
      if (this.transceiverTrackTimer) {
         // already started
         return;
      }

      this.transceiverTrackTimer = setInterval(() => {
         // get transceivers info every 1 second
         this.onPollTransceivers();
      }, 1000);
   };

   /**
    * Poll transceivers information and send to agent every 1 second
    *
    */
   private onPollTransceivers = () => {
      const newTransceiverInfos = this.getTransceiversInfo();

      const strNewTransInfos = JSON.stringify(newTransceiverInfos);
      if (strNewTransInfos === this.preTransInfos) {
         return;
      }
      this.preTransInfos = strNewTransInfos;
      const evt = {};
      evt[JKEYS.PEER] = this.peerId;
      evt[JKEYS.EVT] = HTML5MMR_CONST.ON_TRANSCEIVERS;
      evt[JKEYS.TRANSCEIVERS] = newTransceiverInfos;
      this.webRTCInstance.sendRTCResponse(WEB_COMMAND.EVENT_ONTRANSCEIVERS, evt);
   };

   /**
    * Get info of all transceivers and put into an array
    *
    * @param {boolean} fullValue, 'true' for full info, 'false' for diff
    * @returns{Array} items of transceiver info
    */
   public getTransceiversInfo = (fullValue = false) => {
      const transceiverInfos = [];
      this.transceiverInfos.forEach((transceiverInfo) => {
         const newInfo = transceiverInfo.getTransceiverInfo(fullValue);
         if (Object.keys(newInfo).length > 0) {
            transceiverInfos.push(newInfo);
         }
      });

      return transceiverInfos;
   };

   /**
    * Update transceiver info records with current transceivers
    *
    * @returns{Array} items of transceiver info that are needed to be removed
    */
   public UpdateTransceivers = () => {
      if (!this.isUnifiedPlan) {
         this.logger.info("RTCPeerConnection => ignore update transceivers for pre webrtc 1.0.");
         return [];
      }
      const transceivers = this.peerConnection.getTransceivers();
      this.logger.info(
         "RTCPeerConnection => update transceivers, existing " +
            transceivers.length +
            " transceivers, recorded " +
            this.transceiverInfos.size +
            " transceivers."
      );

      // Add new transceiver info
      transceivers.forEach((transceiver) => {
         let found = false;
         for (const [transId, transInfo] of this.transceiverInfos) {
            if (transInfo.getTransceiver() === transceiver) {
               found = true;
               break;
            }
         }
         if (!found) {
            this.addTransceiverInfo(transceiver, "");
         }
      });

      // Remove transceiver no longer exists
      const result = [];
      this.transceiverInfos.forEach((transceiverInfo, transId) => {
         let found = false;
         for (let i = 0; i < transceivers.length; i++) {
            if (transceiverInfo.getTransceiver() === transceivers[i]) {
               found = true;
               break;
            }
         }
         if (!found) {
            result.push(transId);
            this.transceiverInfos.delete(transId);
         }
      });
      return result;
   };

   /**
    * Add new transceiver info record
    *
    * @param {RTCRtpTransceiver} transceiver
    * @param {string} transceiverId
    *
    * @returns {TransceiverInfo} the new transceiverInfo just added
    */
   public addTransceiverInfo = (transceiver: RTCRtpTransceiver, transceiverId: string) => {
      // transceiver id might be "" for some cases
      const transId = transceiverId || HTML5MMR_CONST.RMTRANSCEIVER_ID + VMPeerConnection.randomTransceiverId++;

      const transceiverInfo = new TransceiverInfo(transId, transceiver, this.webRTCInstance);
      this.transceiverInfos.set(transId, transceiverInfo);
      this.startTrackTransceivers();
      return transceiverInfo;
   };

   private stopPollTransceivers = () => {
      clearInterval(this.transceiverTrackTimer);
   };

   /**
    * Handle set sender parameter command
    *
    * @param {object} cmdObj given command json object
    */
   public setSenderParameters = async (cmdObj) => {
      this.logger.info("RTCPeerConnection => handle set sender parameter cmd.");
      const unSupportOperation = HTML5MMR_CONST.RTCErrorType.UNSUPPORTED_OPERATION;
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const transceiverInfo = this.transceiverInfos.get(transceiverId);
      if (!transceiverInfo || !transceiverInfo.getTransceiver()) {
         this.webRTCInstance.sendRTCErrorResult(
            cmdObj,
            unSupportOperation,
            "could not find transceiver with given id."
         );
         return;
      }
      const sender = transceiverInfo.getTransceiver().sender; // @ts-ignore
      if (!sender || sender.id !== cmdObj[JKEYS.SENDER_ID]) {
         this.webRTCInstance.sendRTCErrorResult(cmdObj, unSupportOperation, "could not find sender with given id.");
         return;
      }
      const oldParams = sender.getParameters(); // oldParams will be overwritten in the next line
      const newParams = this.mergeSenderParamters(oldParams, cmdObj[JKEYS.PARAMETERS]);
      if (!newParams) {
         this.webRTCInstance.sendRTCErrorResult(cmdObj, unSupportOperation, "invalid sender parameters.");
         return;
      }

      try {
         await sender.setParameters(newParams);
      } catch (e) {
         this.webRTCInstance.sendRTCErrorResult(cmdObj, unSupportOperation, "set sender parameters failed");
         return;
      }
      const result = {};
      result[JKEYS.SENDERS] = [];
      result[JKEYS.SENDERS][0] = {};
      result[JKEYS.SENDERS][0][JKEYS.ID] = sender[JKEYS.ID];
      result[JKEYS.SENDERS][0][JKEYS.PARAMETERS] = sender.getParameters();

      this.webRTCInstance.sendSuccessResult(cmdObj, result, JKEYS.SENDERS);
   };

   /**
    * Handle replace sender track command
    *
    * @param {object} cmdObj given command json object
    * @param {MediaStreamTrack} newTrack
    */
   public replaceSenderTrack = async (cmdObj, newTrack) => {
      this.logger.info("RTCPeerConnection => handle replace sender track cmd.");
      const unSupportOperation = HTML5MMR_CONST.RTCErrorType.UNSUPPORTED_OPERATION;
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const transceiverInfo = this.transceiverInfos.get(transceiverId);
      if (!transceiverInfo || !transceiverInfo.getTransceiver()) {
         this.webRTCInstance.sendRTCErrorResult(
            cmdObj,
            unSupportOperation,
            "could not find transceiver with given id."
         );
         this.logger.error("RTCPeerConnection => replace sender track, could not find transceiver with given id.");
         return;
      }
      const sender = transceiverInfo.getTransceiver().sender;
      if (!sender) {
         this.webRTCInstance.sendRTCErrorResult(
            cmdObj,
            unSupportOperation,
            "could not find sender with given transceiver."
         );
         this.logger.error("RTCPeerConnection => replace sender track, could not find sender with given transceiver.");
         return;
      }

      try {
         await sender.replaceTrack(newTrack);
      } catch (e) {
         this.webRTCInstance.sendRTCErrorResult(cmdObj, unSupportOperation, "replace sender track failed.");
         this.logger.error("RTCPeerConnection => replace sender track failed.");
         return;
      }
      this.isUnifiedPlan ? this.onPollTransceivers() : this.onPollSenders();
      this.webRTCInstance.sendSuccessResult(cmdObj);
   };

   /**
    * Merge new sender parameters with old ones
    * overwrite the value in old one and return the update object as newParams
    *
    * @param {object} oldParam
    * @param {object} givenParams
    *
    * @returns {object} result
    */
   private mergeSenderParamters = (oldParam, givenParams) => {
      if (givenParams[JKEYS.TRANSACTION_ID]) {
         oldParam[JKEYS.TRANSACTION_ID] = givenParams[JKEYS.TRANSACTION_ID];
      }
      if (givenParams[JKEYS.DEGRADATION_PREFERENCE]) {
         // optional value
         oldParam[JKEYS.DEGRADATION_PREFERENCE] = givenParams[JKEYS.DEGRADATION_PREFERENCE];
      }
      if (givenParams[JKEYS.PRIORITY]) {
         // TODO: enable this line later
         // oldParam[JKEYS.PRIORITY] = givenParams[JKEYS.PRIORITY];
      }
      const givenEncodings = givenParams[JKEYS.ENCODINGS];
      const oldEncodings = oldParam[JKEYS.ENCODINGS];
      if (!Array.isArray(givenEncodings) || !Array.isArray(oldEncodings)) {
         return null;
      }

      // use for loop to apply index based sync
      for (let i = 0; i < givenEncodings.length && i < oldEncodings.length; i++) {
         const newCodingItem = givenEncodings[i];
         const oldCodingItem = oldEncodings[i];

         // this value could be true, false or null(not given)
         if (newCodingItem[JKEYS.ACTIVE]) {
            oldCodingItem[JKEYS.ACTIVE] = newCodingItem[JKEYS.ACTIVE];
         } else if (newCodingItem[JKEYS.ACTIVE] === false) {
            oldCodingItem[JKEYS.ACTIVE] = false;
         }

         if (newCodingItem[JKEYS.CODEC_PAYLOAD_TYPE]) {
            // Do nothing now
            // oldCodingItem[JKEYS.CODEC_PAYLOAD_TYPE] = newCodingItem[JKEYS.CODEC_PAYLOAD_TYPE];
         }
         if (newCodingItem[JKEYS.DTX]) {
            // Do nothing now
            // oldCodingItem[JKEYS.DTX] = newCodingItem[JKEYS.DTX];
         }
         if (newCodingItem[JKEYS.P_TIME]) {
            // Do nothing now
            // oldCodingItem[JKEYS.P_TIME] = newCodingItem[JKEYS.P_TIME];
         }
         if (newCodingItem[JKEYS.MAX_BITRATE]) {
            oldCodingItem[JKEYS.MAX_BITRATE] = newCodingItem[JKEYS.MAX_BITRATE];
         }
         if (newCodingItem[JKEYS.MAX_FRAMERATE]) {
            oldCodingItem[JKEYS.MAX_FRAMERATE] = newCodingItem[JKEYS.MAX_FRAMERATE];
         }
         if (newCodingItem[JKEYS.SCALE_RESOLUTION_DOWN_BY]) {
            oldCodingItem[JKEYS.SCALE_RESOLUTION_DOWN_BY] = newCodingItem[JKEYS.SCALE_RESOLUTION_DOWN_BY];
         }
      }

      return oldParam;
   };

   /**
    * Handle insert DTMF command from RtcRtpSender level
    * @param {string} transceiverId
    * @param {string} senderId
    * @param {string} trackId
    * @param {string} tones
    * @param {number} duration
    * @param {number} gap
    *
    * @returns {boolean}
    */
   public insertDTMF = (transceiverId, senderId, trackId, tones, duration, gap) => {
      this.logger.info("RTCPeerConnection => handle insertDTMF cmd.");
      let sender;
      if (!this.isUnifiedPlan) {
         const senders = this.peerConnection.getSenders();
         for (let i = 0; i < senders.length; i++) {
            if (senders[i].id === senderId) {
               sender = senders[i];
               break;
            }
         }
      } else {
         const transceiverInfo = this.transceiverInfos.get(transceiverId);
         if (!transceiverInfo || !transceiverInfo.getTransceiver()) {
            this.logger.error("RTCPeerConnection => insertDTMF, could not find transceiver with given id.");
            return false;
         }
         sender = transceiverInfo.getTransceiver().sender;
      }
      if (!sender) {
         this.logger.error("RTCPeerConnection => insertDTMF, could not find sender with given transceiver.");
         return false;
      }

      const dtmfSender = sender.dtmf;
      if (!dtmfSender.ontonechange) {
         dtmfSender.ontonechange = (evt) => {
            const data = {};
            data[JKEYS.EVT] = HTML5MMR_CONST.TONE_CHANGE;
            data[JKEYS.TRANSCEIVER_ID] = transceiverId ? transceiverId : "";
            data[JKEYS.SENDER_ID] = senderId ? senderId : "";
            data[JKEYS.TID] = trackId;
            data[JKEYS.TONE] = evt.tone;
            data[JKEYS.TONE_BUFFER] = dtmfSender.toneBuffer;

            this.sendWebRTCResponse(data, false);
         };
      }
      try {
         dtmfSender.insertDTMF(tones, duration, gap);
         return true;
      } catch (error) {
         this.logger.error("RTCPeerConnection => failed to insertDTMF.");
         return false;
      }
   };

   /**
    * Handle Datachannel related command
    * @param {object} cmdObj given command json object
    *
    * @returns {boolean}
    */
   public handleDataChannelCommand = (cmdObj) => {
      if (!this.peerConnection) {
         this.logger.error("RTCPeerConnection => peer connection was not created yet.");
         return false;
      }
      const commandId = cmdObj[JKEYS.DATACHANNEL_CMD];
      if (!commandId) {
         this.logger.error("RTCPeerConnection => Invalid datachannel cmd id.");
         return false;
      }

      if (commandId === HTML5MMR_CONST.DC_CMD.CREATE) {
         const shimId = cmdObj[JKEYS.SHIM_ID];
         const label = cmdObj[JKEYS.LABEL];
         const options = cmdObj[JKEYS.OPTIONS];
         if (this.datachannels.get(shimId)) {
            this.logger.error("RTCPeerConnection => datachannel already existed.");
            return false;
         }
         const _vmDatachannel = new vmDatachannel(this.webRTCInstance, this, this.peerId, shimId, label, options, null);

         this.datachannels.set(_vmDatachannel.getId(), _vmDatachannel);
      } else {
         const id = cmdObj[JKEYS.DATACHANNEL_ID];
         const vmDatachannel = this.datachannels.get(id);
         if (!vmDatachannel) {
            this.logger.error("RTCPeerConnection => Could not find datachannel with given id.");
            return false;
         }
         vmDatachannel.handleCmd(commandId, cmdObj);

         if (commandId === HTML5MMR_CONST.DC_CMD.CLOSE) {
            this.datachannels.delete(id);
         }
      }
      return true;
   };

   /**
    * Interface to create real RTCDataChannel
    * @param {string} label, name for the channel
    * @param {object} options, additional options
    *
    * @returns {RTCDataChannel}
    */
   public createDataChannel = (label, options) => {
      return this.peerConnection.createDataChannel(label, options);
   };

   /**
    * Update RTCDataChannel Map while data channel id is available
    *
    * @param {number} id, id for the channel
    * @param {vmDatachannel} dc, related vmDatachannel instance
    */
   public updateDataChannelID = (id, dc) => {
      this.datachannels.set(id, dc);
   };
}

/**
 *  The class to keep transceiver record and provide interface to
 *  accquire transceiver information
 */
class TransceiverInfo {
   private transceiverId: string;
   private transceiver: RTCRtpTransceiver;
   private webRTCInstance: WebRTCInstance;

   private preTransceiverInfo: string;
   private preSenderInfo: string;
   private preReceiverInfo: ReceiverInfo;

   constructor(transceiverId: string, transceiver: RTCRtpTransceiver, webRTCInstance: WebRTCInstance) {
      this.transceiverId = transceiverId;
      this.transceiver = transceiver;
      this.webRTCInstance = webRTCInstance;

      this.preTransceiverInfo = "";
      this.preSenderInfo = "";
      this.preReceiverInfo = new ReceiverInfo(null);
   }

   /**
    * Get RTCRtpTransceiver(including senders and receivers) information
    *
    * @param {boolean} fullValue, 'true' for full info, 'false' for diff
    * @returns {object} sender info
    */
   public getTransceiverInfo = (fullValue = false) => {
      let reuslt = {};

      if (fullValue) {
         this.preTransceiverInfo = "";
         this.preSenderInfo = "";
         this.preReceiverInfo.reset();
      }

      // get transceiver info
      const transceiverInfo = this._getTransceiverInfo();
      if (JSON.stringify(transceiverInfo) !== this.preTransceiverInfo) {
         reuslt = transceiverInfo;
         this.preTransceiverInfo = JSON.stringify(transceiverInfo);
      }

      // get sender info
      const senderInfo = this.getSenderInfo(this.transceiver.sender);
      if (senderInfo) {
         if (JSON.stringify(senderInfo) !== this.preSenderInfo) {
            reuslt[JKEYS.SENDER] = senderInfo;
            this.preSenderInfo = JSON.stringify(senderInfo);
         }
      }

      // get receiver info
      const newReceiverInfo = new ReceiverInfo(this.transceiver.receiver);
      const diff = this.preReceiverInfo.diff(newReceiverInfo.getReceiverInfo());
      diff[JKEYS.ID] = this.transceiver.receiver.track.id;

      this.preReceiverInfo = newReceiverInfo;
      if (Object.keys(diff).length > 0) {
         reuslt[JKEYS.RECEIVER] = diff;
      }

      if (Object.keys(reuslt).length > 0) {
         reuslt[JKEYS.ID] = this.transceiverId;
      }
      return reuslt;
   };

   public getTransceiver = () => {
      return this.transceiver;
   };

   /**
    * Get RTCRtpTransceiver information
    *
    * @param {RTCRtpSender} sender
    * @returns {object} sender info
    */
   private _getTransceiverInfo = () => {
      const result = {};
      if (!this.transceiver) {
         return result;
      }
      if (this.transceiver.currentDirection) {
         result[JKEYS.CURRENT_DIRECTION] = this.transceiver.currentDirection;
      }
      if (this.transceiver.direction) {
         result[JKEYS.DIRECTION] = this.transceiver.direction;
      }
      if (this.transceiver.mid) {
         result[JKEYS.MID] = this.webRTCInstance.replaceClientStreamId(this.transceiver.mid);
      }
      // @ts-ignore
      if (this.transceiver.stopped) {
         // @ts-ignore
         result[JKEYS.DIRECTION] = this.transceiver.stopped; // Deprecated value
      }
      return result;
   };

   /**
    * Get RTCRtpSender information
    *
    * @param {RTCRtpSender} sender
    * @returns {object} sender info
    */
   public getSenderInfo = (sender) => {
      let result = null;
      const parameters = sender.getParameters();
      if (Object.keys(parameters).length > 0) {
         result = {};
         if (!sender.id) {
            if (sender.track && sender.track.id) {
               sender.id = sender.track.id;
            } else {
               sender.id = getUuid();
            }
         }
         result[JKEYS.ID] = sender.id;
         result[JKEYS.PARAMETERS] = parameters;
      }
      return result;
   };
}

/**
 *  The class to keep receiver record and provide interface to
 *  accquire receiver information
 */
class ReceiverInfo {
   private receiverInfoObj: object;

   constructor(receiver: RTCRtpReceiver) {
      this.receiverInfoObj = this._getReceiverInfo(receiver);
   }

   public getReceiverInfo = () => {
      return this.receiverInfoObj;
   };

   public reset = () => {
      this.receiverInfoObj = {};
   };

   /**
    * Get informaation for single RTCRtpTransceiver
    *
    * @param {RTCRtpTransceiver} receiver
    * @returns {object} result
    */
   private _getReceiverInfo = (receiver) => {
      if (!receiver) {
         return {};
      }
      const track = receiver.track;
      const trackInfo = ReceiverInfo.getRemoteTrackInfo(track);
      const trackId = track.id;

      const sourceInfos = new Map();
      let sources = receiver.getContributingSources();
      let sourceType = 1; // 0: SSRC ; 1: CSRC
      if (sources.length <= 0) {
         sources = receiver.getSynchronizationSources();
         sourceType = 0;
      }
      sources.forEach((source) => {
         const sourceId = source.source;
         const sourceInfo = {};
         sourceInfo[JKEYS.TYPE] = sourceType;
         if (source.audioLevel) {
            // Seems Chrome does not provice audio level info in source now.
            sourceInfo[JKEYS.AUDIO_LEVEL] = source.audioLevel;
         }
         sourceInfo[JKEYS.SOURCE] = sourceId;
         sourceInfo[JKEYS.TIME_STAMP] = source.timestamp;

         sourceInfos.set(sourceId, sourceInfo);
      });
      const result = {
         trackInfo: trackInfo,
         sourceInfos: sourceInfos
      };
      return result;
   };

   static getRemoteTrackInfo = (track) => {
      const trackInfo = {};

      (trackInfo[JKEYS.CONTENT_HINT] = track.kind === "audio" ? "remote audio" : "remote video"),
         (trackInfo[JKEYS.ENABLED] = track.enabled);
      trackInfo[JKEYS.ID] = track.id;
      trackInfo[JKEYS.ISOLATED] = false;
      trackInfo[JKEYS.KIND] = track.kind;
      trackInfo[JKEYS.LABEL] = track.label;
      trackInfo[JKEYS.MUTED] = track.muted;
      trackInfo[JKEYS.READ_ONLY] = false;
      trackInfo[JKEYS.READY_STATE] = track.readyState;
      trackInfo[JKEYS.REMOTE] = true;
      trackInfo[JKEYS.SETTINGS] = {};
      trackInfo[JKEYS.SETTINGS][JKEYS.DEVID] = track.id;

      return trackInfo;
   };

   /**
    * Compare the diff of new recevier info with the old one
    *
    * @param {object} newInfo, the new receiver info object
    * @returns {object} the diff object
    */
   public diff = (newInfo) => {
      const receiverDiff = {};
      const oldInfo = this.receiverInfoObj;
      if (Object.keys(oldInfo).length <= 0) {
         // new item
         receiverDiff[JKEYS.OP] = "+";
      }

      // compare trackInfo(object)
      if (!oldInfo[JKEYS.TRACKINFO]) {
         receiverDiff[JKEYS.TRACK] = newInfo.trackInfo;
      } else {
         HTML5MMR_CONST.RECEIVER_TRACKINFO_KEYS.forEach((key) => {
            if (newInfo[JKEYS.TRACKINFO][key] !== oldInfo[JKEYS.TRACKINFO][key]) {
               receiverDiff[JKEYS.TRACK] = receiverDiff[JKEYS.TRACK] || {};
               receiverDiff[JKEYS.TRACK][key] = newInfo[JKEYS.TRACKINFO][key];
            }
         });
         if (
            newInfo[JKEYS.TRACKINFO][JKEYS.SETTINGS][JKEYS.DEVID] !==
            oldInfo[JKEYS.TRACKINFO][JKEYS.SETTINGS][JKEYS.DEVID]
         ) {
            receiverDiff[JKEYS.TRACK][JKEYS.SETTINGS][JKEYS.DEVID] =
               newInfo[JKEYS.TRACKINFO][JKEYS.SETTINGS][JKEYS.DEVID];
         }
      }

      // compare sourceInfos(map)
      const oldSrcInfos = oldInfo[JKEYS.SOURCEINFOS] || new Map();
      const newSrcInfos = newInfo[JKEYS.SOURCEINFOS];
      newSrcInfos.forEach((newSInfo, id) => {
         let sourceDiff = {};
         const oldSInfo = oldSrcInfos.get(id);
         if (oldSInfo) {
            HTML5MMR_CONST.RECEIVER_SOURCE_KEYS.forEach((key) => {
               if (newSInfo[key] !== oldSInfo[key]) {
                  sourceDiff[key] = newSInfo[key];
               }
            });

            HTML5MMR_CONST.RECEIVER_SOURCE_OPTIONAL_KEYS.forEach((key) => {
               if (newSInfo[key]) {
                  sourceDiff[key] = newSInfo[key];
               }
            });
         } else {
            // new item
            sourceDiff = newSInfo;
            sourceDiff[JKEYS.OP] = "+";
         }

         if (Object.keys(sourceDiff).length > 0) {
            // has diff
            sourceDiff[JKEYS.SOURCE] = id;
            receiverDiff[JKEYS.SOURCES] = receiverDiff[JKEYS.SOURCES] || [];
            receiverDiff[JKEYS.SOURCES].push(sourceDiff);
         }
      });

      // remove items
      oldSrcInfos.forEach((oldSInfo, id) => {
         if (!newSrcInfos.get(id)) {
            receiverDiff[JKEYS.SOURCES] = receiverDiff[JKEYS.SOURCES] || [];
            receiverDiff[JKEYS.SOURCES].push({
               op: "-",
               source: id
            });
         }
      });

      return receiverDiff;
   };
}

/**
 *  The class is for keeping datachannels record
 *
 */
class vmDatachannel {
   private shimId: number;
   private label: string;
   private webRTCInstance: WebRTCInstance;
   private pc: VMPeerConnection;
   private peerId: number;
   private datachannel: RTCDataChannel;
   private bufferAmountLowThreshold = 0;

   private logger = new Logger(Logger.WEBRTC);

   constructor(
      webRTCInstance: WebRTCInstance,
      pc: VMPeerConnection,
      peerId: number,
      shimId: number,
      label: string,
      options: Object,
      dc: Object
   ) {
      this.shimId = shimId || -1;
      this.webRTCInstance = webRTCInstance;
      this.pc = pc;
      this.peerId = peerId;
      this.label = label;
      this.datachannel = dc || pc.createDataChannel(label, options);

      this.initEventHandlers();
   }

   private initEventHandlers = () => {
      this.datachannel.onbufferedamountlow = () => {
         this.logger.info("RTCDataChannel => onbufferedamountlow for shimId: " + this.shimId);
         const evt = {};
         evt[JKEYS.EVT] = "dconbufferedamountlow";
         this.sendDatachannelEvent(evt);
      };

      this.datachannel.onclose = () => {
         this.logger.info("RTCDataChannel => onclose for shimId: " + this.shimId);
         const evt = {};
         evt[JKEYS.EVT] = "dconclose";
         this.sendDatachannelEvent(evt);
      };

      // @ts-ignore experimental event
      this.datachannel.onclosing = () => {
         this.logger.info("RTCDataChannel => onclosing for shimId: " + this.shimId);
         const evt = {};
         evt[JKEYS.EVT] = "dconclosing";
         this.sendDatachannelEvent(evt);
      };

      this.datachannel.onerror = () => {
         this.logger.info("RTCDataChannel => onerror for shimId: " + this.shimId);
      };

      this.datachannel.onmessage = (event) => {
         this.logger.info("RTCDataChannel => onmessage for shimId: " + this.shimId);
         const evt = {};
         evt[JKEYS.EVT] = "dconmessage";
         if (event.data && event.data instanceof ArrayBuffer) {
            evt[JKEYS.DATA_TYPE] = "binary";
            let dataString: string;
            try {
               // convert arraybuffer to string
               dataString = String.fromCharCode.apply(null, Array.from(new Uint8Array(event.data)));
               // Base64 encode
               dataString = btoa(dataString);
               evt[JKEYS.DATA] = dataString;
            } catch (error) {
               this.logger.error("RTCDataChannel => onmessage, failed to convert incoming arraybuffer.");
               return;
            }
         } else {
            // TODO: for blob
         }

         this.sendDatachannelEvent(evt);
      };

      this.datachannel.onopen = (event) => {
         this.logger.info("RTCDataChannel => onopen for shimId: " + this.shimId);
         const evt = {};
         evt[JKEYS.EVT] = "dconopen";
         evt[JKEYS.CHANNEL] = {}; // @ts-ignore
         if (this.datachannel.reliable) {
            // @ts-ignore
            evt[JKEYS.CHANNEL][JKEYS.RELIABLE] = this.datachannel.reliable;
         }
         evt[JKEYS.CHANNEL][JKEYS.ORDERED] = this.datachannel.ordered;
         evt[JKEYS.CHANNEL][JKEYS.PROTOCOL] = this.datachannel.protocol;
         evt[JKEYS.CHANNEL][JKEYS.NEGOTIATED] = this.datachannel.negotiated;
         evt[JKEYS.CHANNEL][JKEYS.MAXRETRANSMITS] = this.datachannel.maxRetransmits;
         evt[JKEYS.CHANNEL][JKEYS.MAXPACKETLIFETIME] = this.datachannel.maxPacketLifeTime;

         evt[JKEYS.CHANNEL][JKEYS.READY_STATE] = this.datachannel.readyState;
         // make sure datachannel id is assigned by browser
         if (this.datachannel.id || this.datachannel.id === 0) {
            this.pc.updateDataChannelID(this.datachannel.id, this);
         }
         this.sendDatachannelEvent(evt);
      };
   };

   public getId = () => {
      return this.datachannel.id || null;
   };

   public close = () => {
      this.logger.info("RTCDataChannel => close datachannle for shimId: " + this.shimId);
      this.datachannel.close();
   };

   /**
    *  Handle all datachannel comands except 'CREATE'
    * @param {number} commandId command id
    * @param {object} cmdObj given command json object
    */
   public handleCmd = (commandId, cmdObj) => {
      this.logger.info("RTCDataChannel => handle datachannle cmd:" + JSON.stringify(cmdObj));
      const shimId = cmdObj[JKEYS.SHIM_ID];
      if (shimId || shimId === 0) {
         if (this.shimId === -1) {
            this.logger.info("RTCDataChannel => handle datachannle cmd, set shimId to: " + shimId);
         } else if (this.shimId !== shimId) {
            this.logger.warning(
               "RTCDataChannel => handle datachannle cmd, shimId are inconsistant. Local: " +
                  this.shimId +
                  ", in cmd: " +
                  shimId
            );
         }
         this.shimId = shimId;
      }
      switch (commandId) {
         case HTML5MMR_CONST.DC_CMD.SEND:
            this.doSend(cmdObj);
            break;
         case HTML5MMR_CONST.DC_CMD.SET_BUFFER_AMOUNT_LOW_THRESHOLD:
            this.setBufferAmountLowThreshol(cmdObj);
            break;
         case HTML5MMR_CONST.DC_CMD.CLOSE:
            this.close();
            break;
         default:
            this.logger.error("RTCDataChannel => Unexpected datachannel command: " + commandId);
      }
   };

   /**
    *  Handle datachannel send comands
    * @param {object} cmdObj given command json object
    */
   private doSend = (cmdObj) => {
      let dataString = cmdObj[JKEYS.DATA];
      if (typeof dataString !== "string") {
         this.logger.error("RTCDataChannel => doSend command receive invalid data: " + dataString);
         return;
      }
      dataString = atob(dataString);
      const dataBuffer = new ArrayBuffer(dataString.length);
      const dataArrary = new Uint8Array(dataBuffer);
      for (let i = 0; i < dataString.length; i++) {
         dataArrary[i] = dataString.charCodeAt(i);
      }
      this.datachannel.send(dataArrary);
   };

   /**
    *  Handle datachannel set buffer amount low threshold comands
    * @param {object} cmdObj given command json object
    */
   private setBufferAmountLowThreshol = (cmdObj) => {
      this.bufferAmountLowThreshold = cmdObj[JKEYS.THRESHOLD] || this.bufferAmountLowThreshold;
   };

   /**
    *  Add common values and send out datachannel event
    * @param {object} evt, the event data object
    */
   private sendDatachannelEvent = (evt) => {
      evt[JKEYS.SHIM_ID] = this.shimId;
      evt[JKEYS.PEER] = this.peerId;

      evt[JKEYS.CHANNEL] = evt[JKEYS.CHANNEL] || {};
      evt[JKEYS.CHANNEL][JKEYS.ID] = this.datachannel.id;
      evt[JKEYS.CHANNEL][JKEYS.LABEL] = this.label;

      this.webRTCInstance.sendRTCResponse(WEB_COMMAND.WEBRTC_EVENT_DATACHANNEL, evt);
   };
}
