/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * connectionRetryController.js --
 *
 * This handler is added for Azure connection retry, where Agent pool need time be prepared.
 *
 * Client will showing an UI waiting while get below XML response
 * <error-code>LAUNCH_HEADROOM_ERROR</error-code>
 * <error-message>Unable to connect. Please try again after some time.</error-message>
 * <user-message>Unable to connect. Please try again after some time.</user-message>
 * <client-retry-timeout-seconds>900</client-retry-timeout-seconds>
 * <client-retry-interval-seconds>60</client-retry-interval-seconds>
 * The text for displaying is "Your desktop will take a few minutes to set up. It may take as
 * long as ({client-retry-timeout-seconds/60}) minutes. We will connect you to the desktop as
 * soon as it is ready."
 *
 * init
 * update
 * onReconnectionDone
 * onReconnectionError
 * waitingConnectionResponse
 */

import { JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { Timer, timerTypeEnum } from "./timer";
import ConnectToDesktopCommand from "../commands/connectToDesktopCommand";
import ConnectToApplicationCommand from "../commands/connectToApplicationCommand";
import jscdkFeatureStatus from "../model/jscdk-feature-status";
import { ConnectionRetryAction, ConnectionRetryErrorAction, UpdateWaitingUIAction } from "../jscdk-interface";

export default function ConnectionRetryController() {
   this.launchAction = null;
   Logger.debug("ConnectionRetryController created");
   this.globalName = "connection-retry-controller";
   this.reconnectionTimeout = -1;
   this.reconnectionInterval = -1;
   this.initialLaunchTime = -1;
   this.launchParam = null;
   this.type = "";
   this.reconnectIntervalTimer = new Timer(timerTypeEnum.interval);
   this.reconnectTimeoutTimer = new Timer(timerTypeEnum.timeout);
   this.reconnectIntervalTimer.setEnable();
   this.reconnectTimeoutTimer.setEnable();
}

// only store functions
ConnectionRetryController.prototype = {};

ConnectionRetryController.constructor = ConnectionRetryController;

(function () {
   //private functions:
   //----------------------------------------------------------------------------------------------
   function reconnectDesktop(self) {
      ConnectToDesktopCommand.prototype.execute(self.launchAction);
   }

   function reconnectApp(self) {
      ConnectToApplicationCommand.prototype.execute(self.launchAction);
   }

   function updateWaitingUIEvent(self) {
      const action: UpdateWaitingUIAction = {
         name: "UpdateAzureWaitingUI",
         content: {
            maxWaitTime: self.reconnectionTimeout * 1000,
            type: self.type
         }
      };
      Logger.debug("updateWaitingUIEvent event reached");
      if (self.type === "" || self.reconnectionTimeout === -1 || self.reconnectionInterval === -1) {
         Logger.debug("skip updateWaitingUIEvent event since the process has been finished");
         return;
      }
      if (!(action.content.maxWaitTime > 0)) {
         action.content.maxWaitTime = 0;
      }
      JSCDKSetUI(JSON.stringify(action));
   }

   function resendConnectionEvent(self) {
      if (!self.getSkipableStatus()) {
         return;
      }
      if (self.type === "desktop") {
         reconnectDesktop(self);
      } else if (self.type === "application") {
         reconnectApp(self);
      } else {
         Logger.error("unknown item type for connection retry: " + self.type);
      }
   }

   function destroyWaitingUIEvent(self) {
      const action = {} as ConnectionRetryAction;

      if (self.type === "") {
         Logger.debug("connect retry process already stopped, but still forward destroy event to UI.");
      }
      Logger.debug("DestroyAzureWaitingUI event reached");
      cleanUp(self);
      action.name = "DestroyAzureWaitingUI";
      JSCDKSetUI(JSON.stringify(action));
   }

   function connectionMaxWaitTimeoutEvent(self) {
      self.alreadyTimeout = true;
      resendConnectionEvent(self);
   }

   function reconnectionErrorUIEvent(self) {
      const action: ConnectionRetryErrorAction = {} as ConnectionRetryErrorAction;
      action.name = "AzureReconnectionErrorUI";
      action.content = {
         id: self?.launchAction?.applicationId || self?.launchAction?.desktopId,
         handler: self?.launchAction.method,
         redirect: false,
         errorType: "LAUNCH_HEADROOM_TIMEOUT",
         errorMessage: `Failed to get response from connection service within ${self.reconnectionTimeout} seconds`
      };
      Logger.debug("changeToErrorUIEvent event reached");
      action.name = "AzureReconnectionErrorUI";
      JSCDKSetUI(JSON.stringify(action));
      cleanUp(self);
   }

   function initTimers() {
      this.reconnectIntervalTimer.setEnable();
      this.reconnectTimeoutTimer.setEnable();
      this.reconnectIntervalTimer.init(resendConnectionEvent, this.reconnectionInterval * 1000, this);
      this.reconnectTimeoutTimer.init(connectionMaxWaitTimeoutEvent, this.reconnectionTimeout * 1000, this);
      this.reconnectIntervalTimer.start();
      this.reconnectTimeoutTimer.start();
   }

   function cleanUp(self) {
      if (self.reconnectIntervalTimer) {
         self.reconnectIntervalTimer.ensureStopped();
      }
      if (self.reconnectTimeoutTimer) {
         self.reconnectTimeoutTimer.ensureStopped();
      }

      self.reconnectionTimeout = -1;
      self.reconnectionInterval = -1;
      self.initialLaunchTime = -1;
      self.launchParam = null;
      self.type = "";
   }

   // public functions:
   //----------------------------------------------------------------------------------------------
   // class method to varify the version validation(using
   // ConnectionRetryController as a namespace)
   ConnectionRetryController.prototype.init = function (timeout, interval, type, launchAction) {
      if (this.isDisabled()) {
         return;
      }
      if (this.type !== "" || this.reconnectionTimeout !== -1 || this.reconnectionInterval !== -1) {
         Logger.error("Previous reconnection timer not destroyed for Azure");
         return;
      }

      //copy a set of time value
      this.type = type;
      this.reconnectionTimeout = timeout; // in s
      this.reconnectionInterval = interval; // in s
      launchAction.initialLaunchTime = Timer.prototype.getCurrentTime();
      this.initialLaunchTime = launchAction.initialLaunchTime;
      this.launchAction = launchAction;
      this.alreadyTimeout = false;
      Logger.debug(
         "init reconnection controller with interval:" +
            interval +
            ", timeout:" +
            timeout +
            ", for launch started at :" +
            this.initialLaunchTime
      );

      this.setConnectingStatus(false);
      initTimers.call(this);
      updateWaitingUIEvent(this);
   };

   ConnectionRetryController.prototype.onReconnectionDone = function (reason) {
      if (this.isDisabled()) {
         return;
      }
      Logger.debug("reconnect stop, reason: " + reason);

      this.setConnectingStatus(false);
      destroyWaitingUIEvent(this);
   };

   ConnectionRetryController.prototype.onReconnectionError = ConnectionRetryController.prototype.onReconnectionDone;

   ConnectionRetryController.prototype.brokerVersionInvalid = function () {
      if (!util.brokerSupportAzureReconnect()) {
         Logger.error(
            "Azure reconnection related functions should only be involved when is broker version is new enough"
         );
         return true;
      }
      return false;
   };

   ConnectionRetryController.prototype.isDisabled = function () {
      return this.brokerVersionInvalid() || !jscdkFeatureStatus.getStatus("AzureConnectionRetry");
   };

   ConnectionRetryController.prototype.update = function (action) {
      if (this.isDisabled()) {
         return;
      }

      this.setConnectingStatus(false);
      if (this.initialLaunchTime === -1) {
         Logger.debug("the previous reconnect process discarded, skip updating");
      } else if (this.initialLaunchTime !== action.initialLaunchTime) {
         Logger.error("fatal error: the reconnection is disordered");
      } else if (this.alreadyTimeout) {
         reconnectionErrorUIEvent(this);
      } else {
         Logger.debug("reconnect still failed, trying for " + this.initialLaunchTime);
      }
   };

   /**
    * [getSkipableStatus description]
    * @return {[type]} [description]
    */
   ConnectionRetryController.prototype.getSkipableStatus = function () {
      if (this.isDisabled()) {
         return false;
      }
      if (this.initialLaunchTime === -1) {
         return false;
      }
      return !this.connecting;
   };

   ConnectionRetryController.prototype.setConnectingStatus = function (status) {
      if (this.isDisabled()) {
         return;
      }
      Logger.debug("item XML connecting status changed to : " + status);
      this.connecting = status;
   };
})();
