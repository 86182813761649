/**
 * **************************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */
import { Injectable } from "@angular/core";
import { Logger } from "@html-core";
import { CodecTypes, StreamHeaderLengthV2 } from "../rtav.constants";
import { AudioParams } from "./audio-capture.model";
import {
   AudioCallbackData,
   AudioVideoSample,
   Callback,
   Header,
   HeaderCallbackData,
   LimitedSet,
   arrayCopy
} from "./encoder.model";

@Injectable({ providedIn: "root" })
export class AudioEncoderWorkerFactory {
   constructor() {}
   public newAudioEncoderWorker(
      deviceIndex: number,
      headerCallback: Callback<HeaderCallbackData>,
      dataCallback: Callback<AudioCallbackData>,
      param: AudioParams
   ) {
      return new AudioEncoderWorker(deviceIndex, headerCallback, dataCallback, param);
   }
}

// avoid naming conflict with AudioEncoder
export class AudioEncoderWorker {
   private worker: Worker;
   private header: Header;
   private codecPref: CodecTypes;
   private resourceCount: number;
   private encodingEnv: LimitedSet;
   private dataCount = 0;

   // throwable
   constructor(
      private deviceIndex: number,
      private headerCallback: Callback<HeaderCallbackData>,
      private dataCallback: Callback<AudioCallbackData>,
      param: AudioParams
   ) {
      this.resetEncoder();
      this.worker = new Worker("./audioworker." + __BUILD_NUMBER__ + ".js");
      param.logLevel = Logger.getLogLevel();
      this.codecPref = param.codecPref;
      this.worker.onmessage = this.handleMessage.bind(this);
      this.worker.postMessage({
         type: "Init",
         data: param,
         headerSize: StreamHeaderLengthV2
      });
      this.worker.onerror = (err) => {
         Logger.error("AudioWorker error: " + err.toString(), Logger.RTAV);
      };
   }

   public encode(sample: AudioVideoSample) {
      if (!this.worker || !sample || !sample.data) {
         return;
      }
      if (this.resourceCount > 0) {
         this.resourceCount--;
         const dataBuffer = sample.data;
         const envId = this.encodingEnv.insert({
            metaData: sample.timestamp,
            timestamp: sample.timestamp
         });
         if (envId < 0) {
            Logger.error("AudioEncoder save environment object failed", Logger.RTAV);
            return;
         }
         this.worker.postMessage(
            {
               type: "Encode",
               data: dataBuffer,
               envId: envId
            },
            [dataBuffer]
         );
      }
   }

   public clear() {
      this.worker?.postMessage({
         type: "Clear"
      });
      this.worker?.terminate();
      this.resetEncoder();
   }

   private resetEncoder() {
      this.header = null;
      /**
       * number used for PV operation avoid taking too much of resources in the event queue,
       * and the values are get by testing on different machines, the bigger the number
       * the less data is dropped, but the more delay might exist.
       * in the release phase, if this object contains like 1, 1, we can change them into boolean,
       * but better to keep it for now without testing with the finalized codes.
       * @type {object}
       */
      this.resourceCount = 50;
      this.encodingEnv = new LimitedSet(this.resourceCount);
   }

   private handleMessage(evt: MessageEvent) {
      const message = evt.data;
      switch (message.type) {
         case "InitFail":
            Logger.debug("AudioWorker init failed", Logger.RTAV);
            break;
         case "InitDone":
            this.header = message.data;
            const mergedHeader = this.mergeHeader();
            // TODO: only support Opus
            if (this.codecPref === CodecTypes.CodecVmwH264Opus || this.codecPref === CodecTypes.CodecVmwOpus) {
               this.headerCallback({
                  deviceIndex: this.deviceIndex,
                  header: mergedHeader
               });
            } else {
               Logger.debug("AudioEncoder codec not supported", Logger.RTAV);
            }
            break;
         case "Encoded":
            const env = this.encodingEnv.fetch(message.envId);
            if (!env || !env.timestamp) {
               Logger.debug(
                  "AudioEncoder bad env read, might caused by switching devices to other desktops",
                  Logger.RTAV
               );
               Logger.debug("env's timestamp is: " + env.timestamp, Logger.RTAV);
               Logger.debug("env's metadata is: " + env.metaData, Logger.RTAV);
               return;
            }
            if (message.data?.buffer) {
               const buffer = message.data.buffer as ArrayBuffer;
               this.dataCallback({
                  deviceIndex: this.deviceIndex,
                  data: buffer,
                  timestamp: env.timestamp,
                  dataCount: this.getDataCount()
               });
            }
            this.resourceCount++;
            break;
         case "Cleared":
            Logger.debug("AudioEncoder cleared: " + message.success, Logger.RTAV);
            // TODO
            break;
      }
   }

   private mergeHeader() {
      const mergedHeaderBuffer = new ArrayBuffer(this.header.length + StreamHeaderLengthV2);
      arrayCopy(this.header.buffer, 0, mergedHeaderBuffer, StreamHeaderLengthV2, this.header.length);
      const mergedHeader: Header = {
         buffer: mergedHeaderBuffer,
         length: this.header.length
      };
      return mergedHeader;
   }

   private getDataCount() {
      this.dataCount++;
      return this.dataCount;
   }
}
