/**
 * *******************************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * ********************************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { PreDataSetModel } from "../../common/model/pre-data-set-model";
import { NgbToastService } from "../../common/service/ngb-toast.service";
import { clientUtil, Logger, TranslateService } from "@html-core";
import { BlastWmks } from "../common/blast-wmks.service";
import { ConnectionServerModel } from "../../common/model/connection-server-model";
import { PolicyModel } from "../../../chrome-client/launcher/server-connect/policy-model";
import { ViewClientModel } from "../../common/model/viewclient-model";

export interface NetworkstateConfig {
   networkStateTcpRttMSLow: number;
   networkStateTcpRttMSHigh: number;
   networkStateQualityScoreTcpThresholdGood: number;
   networkStateQualityScoreTcpThresholdPoor: number;
   networkStateRttCheckPeriodMs: number;
}
export const defaultNetworkStateConfig: NetworkstateConfig = {
   networkStateTcpRttMSLow: 2,
   networkStateTcpRttMSHigh: 400,
   networkStateQualityScoreTcpThresholdGood: 85,
   networkStateQualityScoreTcpThresholdPoor: 40,
   networkStateRttCheckPeriodMs: 15000
};

@Injectable({
   providedIn: "root"
})
export class NetworkStateService {
   private _notificationIntervalMs: number = 300000;
   private _notificationTimerMs: number = 0;
   private _notificationID: number = 0;
   private _killSwitchFromPolicy: boolean = true;
   private _enableNetworkIndicator: boolean = true;
   private _configFromPolicy: NetworkstateConfig = JSON.parse(JSON.stringify(defaultNetworkStateConfig));
   private _networkstateConfig: NetworkstateConfig = JSON.parse(JSON.stringify(defaultNetworkStateConfig));
   private logger = new Logger(Logger.NETWORK_STATE);
   constructor(
      private connectionServerModel: ConnectionServerModel,
      private toastService: NgbToastService,
      private translate: TranslateService,
      private policyModel: PolicyModel,
      private viewClientModel: ViewClientModel
   ) {
      if (clientUtil.isChromeClient()) {
         const commonAdminSettings = this.connectionServerModel.googleCommonAdminSettings;
         if (commonAdminSettings) {
            this._getSettingFromPolicy(commonAdminSettings);
         } else {
            this._pullFromGoogle()
               .then((commonAdminSettings) => {
                  this._getSettingFromPolicy(commonAdminSettings);
               })
               .catch((res) => {
                  this.logger.info("No polciy from google");
               });
         }
      }
   }

   private _pullFromGoogle = async () => {
      return new Promise((resolve, reject) => {
         this.policyModel.getModel().then((model) => {
            if (!model) {
               reject();
            } else {
               resolve(model.getCommonAdminSettings());
            }
         });
      });
   };

   private _getSettingFromPolicy = (commonAdminSettings: any) => {
      if (!!commonAdminSettings && commonAdminSettings.hasOwnProperty("enableNetworkIndicator")) {
         if (typeof commonAdminSettings["enableNetworkIndicator"] === "boolean") {
            this._killSwitchFromPolicy = commonAdminSettings["enableNetworkIndicator"];
         }
      }
      if (!!commonAdminSettings && commonAdminSettings.hasOwnProperty("networkStateConfig")) {
         const originalConfigFromPolicy = commonAdminSettings["networkStateConfig"];
         Object.keys(defaultNetworkStateConfig).forEach((key) => {
            if (originalConfigFromPolicy.hasOwnProperty(key) && !isNaN(originalConfigFromPolicy[key])) {
               this._configFromPolicy[key] = originalConfigFromPolicy[key];
            } else {
               this._configFromPolicy[key] = defaultNetworkStateConfig[key];
            }
         });
      }
   };

   private _isValidConfig = (networkStateConfig: NetworkstateConfig): boolean => {
      if (networkStateConfig.networkStateTcpRttMSLow <= 0) {
         return false;
      }
      if (networkStateConfig.networkStateQualityScoreTcpThresholdGood >= 100) {
         return false;
      }
      if (networkStateConfig.networkStateQualityScoreTcpThresholdPoor <= 0) {
         return false;
      }
      if (networkStateConfig.networkStateRttCheckPeriodMs < 2000) {
         return false;
      }
      if (
         networkStateConfig.networkStateQualityScoreTcpThresholdPoor >=
         networkStateConfig.networkStateQualityScoreTcpThresholdGood
      ) {
         return false;
      }
      if (networkStateConfig.networkStateTcpRttMSLow > networkStateConfig.networkStateTcpRttMSHigh) {
         return false;
      }
      return true;
   };

   public getNetworkStateConfig = () => {
      return this._networkstateConfig;
   };

   public getKillSwitch = () => {
      return this._enableNetworkIndicator;
   };

   public getOptions = () => {
      let enableNetworkIndicator = true;
      const tempConfig = JSON.parse(JSON.stringify(this._configFromPolicy));
      if (clientUtil.isChromeClient()) {
         enableNetworkIndicator = this._killSwitchFromPolicy;
      } else {
         enableNetworkIndicator =
            this._killSwitchFromPolicy === this.viewClientModel["enableNetworkIndicator"]
               ? this._killSwitchFromPolicy
               : this.viewClientModel["enableNetworkIndicator"];

         Object.keys(defaultNetworkStateConfig).forEach((key) => {
            if (tempConfig[key] !== this.viewClientModel[key]) {
               tempConfig[key] = this.viewClientModel[key];
            }
         });
      }
      if (this._isValidConfig(tempConfig)) {
         return {
            enableNetworkIndicator: enableNetworkIndicator,
            networkStateConfig: tempConfig
         };
      } else {
         this.logger.error("You are set wrong config value, use the default config to evaluate network connection");
         return {
            enableNetworkIndicator: enableNetworkIndicator,
            networkStateConfig: defaultNetworkStateConfig
         };
      }
   };

   public networkStateHandler = (wmksSession: BlastWmks) => {
      if (this.isNeedRunHandler(wmksSession)) {
         const title = this.translate._T("NETWORK_STATE_WARNING_T");
         const message = this.translate._T("NETWORK_STATE_WARNING_M");
         this._notificationTimerMs = Date.now();
         if (clientUtil.isChromeClient() && !window.isKioskSession) {
            const notificationID = "Horizonnetworkstate#" + this._notificationID;
            this._notificationID = this._notificationID + 1;
            const options: any = {
               type: "basic",
               iconUrl: "../../bad-network.svg",
               title: title,
               message: message,
               expandedMessage: message
            };
            chrome.notifications.create(notificationID, options, () => {
               setTimeout(() => {
                  chrome.notifications.clear(notificationID, (wasCleared) => {
                     this.logger.info("Internet connection is unstable");
                  });
               }, 3000);
            });
         } else {
            this.toastService.clearEarlyToast(this.toastService.TOAST_TYPE.NETWORK);
            this.toastService.warning(message, this.toastService.TOAST_TYPE.NETWORK);
         }
      }
   };

   public isNeedRunHandler = (wmksSession: BlastWmks): boolean => {
      if (
         Date.now() - this._notificationTimerMs < this._notificationIntervalMs ||
         !wmksSession.isActive ||
         (clientUtil.isChromeClient() && !document.hasFocus())
      ) {
         return false;
      }
      return true;
   };
}
