/**
 * ******************************************************
 * Copyright (C) 2015-2018, 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview wmksAdapter.ts -- WmksAdapter
 * Service to handle wmks session management
 *
 * Currently only support one wmks to use RTAV at the same time
 * and others have to wait the resources get released by that session
 * when it's closed.
 *
 * Should also support RTAV pause when the RTAV-active session
 * is hidden(P2, minor, feature)
 *
 * Call userStopSession can stop working session, but not add
 * for now, current kill switch setting will be applied to new
 * launch session, and one can continue to use the RTAV on the
 * already launched sessions.
 */

import { Injectable } from "@angular/core";
import { settings } from "./rtav.settings";
import { RTAVSession } from "./rtavSession";
import { EventBusService } from "@html-core";
import { ResourceManager } from "./resourceManager";
import { RtavDialogService } from "./rtavDialog.service";
import { MessageWaitManager } from "./messageWaitManager";
import { ResourceManagerV2 } from "./v2/resourceManagerV2";
import { RTAVProtocolService } from "./rtavProtocol.service";
import { RTAVProtocolServiceV2 } from "./v2/rtavProtocolV2.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { RtavSettingManagerV2 } from "./rtavSettingManagerV2";
import { Logger } from "@html-core";
import { DeviceManagerService } from "./v2/device-manager.service";
import { DevicePermissionService } from "./v2/device-permission.service";
import { DeviceEnumeratorService } from "./v2/device-enumerator.service";

@Injectable()
export class WmksAdapter {
   private sessionMap = {};

   constructor(
      private eventBusService: EventBusService,
      private resourceManager: ResourceManager,
      private resourceManagerV2: ResourceManagerV2,
      private dialogService: RtavDialogService,
      private messageWaitManager: MessageWaitManager,
      private protocolService: RTAVProtocolService,
      private protocolServiceV2: RTAVProtocolServiceV2,
      private modalDialogService: ModalDialogService,
      private settingManagerV2: RtavSettingManagerV2,
      private deviceManagerService: DeviceManagerService,
      private deviceEnumeratorService: DeviceEnumeratorService,
      private devicePermissionService: DevicePermissionService
   ) {
      this.eventBusService.listen("rtavIsUsingDevices").subscribe((msg) => {
         Logger.debug("check is using device", Logger.RTAV);
         let isUsingDevices = this.isUsingDevices(msg.data);
         this.eventBusService.dispatch({
            type: "rtavShowConflictDialog",
            data: isUsingDevices
         });
      });
      this.eventBusService.listen("rtavUserStopSession").subscribe((msg) => {
         this.userStopSession(msg.data);
      });
   }

   /**
    * The function used to add in a wmks session
    * @param {string} sessionId The ID of the session
    * @param {object} session The vvcSession object that passed from wmks
    */
   public addSession = (sessionId, session) => {
      if (!settings.enabled) {
         Logger.trace("RTAV has been disabled from being added", Logger.RTAV);
         return;
      }
      if (!sessionId || !session) {
         Logger.error("can't add a invalid session" + sessionId, Logger.RTAV);
         return;
      }
      this.sessionMap[sessionId] = new RTAVSession(
         this.resourceManager,
         this.resourceManagerV2,
         this.protocolService,
         this.protocolServiceV2,
         this.dialogService,
         this.eventBusService,
         this.messageWaitManager,
         this.modalDialogService,
         this.settingManagerV2,
         this.deviceManagerService,
         this.deviceEnumeratorService,
         this.devicePermissionService
      );
      this.sessionMap[sessionId].init(sessionId, session);
   };

   /**
    * The function used to remove a wmks session, should be call when the corresponding
    * session becomes invalid
    * @param {string} sessionId The ID of the session
    */
   public removeSession = (sessionId) => {
      if (!settings.enabled) {
         Logger.debug("RTAV has been disabled from being removed", Logger.RTAV);
         return;
      }
      Logger.debug("remove session for RTAV:" + sessionId, Logger.RTAV);
      if (!sessionId || !this.sessionMap[sessionId]) {
         Logger.debug("can't remove a not-exist session" + sessionId, Logger.RTAV);
         return;
      }
      this.sessionMap[sessionId].stop();
      if (this.sessionMap.hasOwnProperty(sessionId)) {
         delete this.sessionMap[sessionId];
      }
   };

   /**
    * The function used to active the rtav session on a valid wmks session, should be
    * called when the corresponding session becomes valid
    * The active means establish rtav sub vvc channel, and finishing the configuring steps,
    * and also tries to process the agent request when device can be obtained.
    * Currently, There can many actived sessions, which means there are many rtav sessions on
    * going, but only one session can send streaming data to the agent since we only allow one
    * to access to the device and encoder to avoid cosuming too much of resources.
    * @param {string} sessionId The ID of the session
    */
   public activeSession = (sessionId) => {
      if (!settings.enabled) {
         Logger.trace("RTAV has been disabled from being active", Logger.RTAV);
         return;
      }
      if (!sessionId || !this.sessionMap[sessionId]) {
         Logger.error("can't active a not-exist session" + sessionId, Logger.RTAV);
         return;
      }
      this.sessionMap[sessionId].start();
   };

   public userStopSession = (sessionId) => {
      if (!settings.enabled) {
         Logger.trace("RTAV has been disabled from being stopped by user", Logger.RTAV);
         return;
      }
      if (!sessionId || !this.sessionMap[sessionId]) {
         Logger.error("can't stop a not-exist session" + sessionId, Logger.RTAV);
         return;
      }
      this.sessionMap[sessionId].userStop();
   };

   public isUsingDevices = (sessionId, type?) => {
      if (!settings.enabled || !sessionId || !this.sessionMap[sessionId]) {
         return false;
      }
      return this.sessionMap[sessionId].isUsingDevices(type);
   };

   public isAskingPermission = (sessionId, type) => {
      if (!settings.enabled || !sessionId || !this.sessionMap[sessionId]) {
         return false;
      }
      return this.sessionMap[sessionId].isAskingPermission(type);
   };

   public hasSession = (sessionId) => {
      if (this.sessionMap[sessionId]) {
         return true;
      } else {
         return false;
      }
   };
}
