/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getAuthenticationStatusHandler.js --
 *
 *      Implementation of the message handler to send getAuthenticationStatus.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import { prepareForAlreadyAuthenticated } from "../timer/timer-util";

export default function GetAuthenticationStatusHandler() {
   // member variables below
   this.messageName = "get-authentication-status";
   this.messageText = "get-authentication-status";
   this.responseTag = "authentication-status";

   // register dependencies here
   const router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
   this.needCallbackFlag = false;
   this.workForSilentModeFlag = false;
   this.requestXML = "";
}

// inherits MessageHandler prototype
GetAuthenticationStatusHandler.prototype = new MessageHandler();
GetAuthenticationStatusHandler.constructor = GetAuthenticationStatusHandler;

GetAuthenticationStatusHandler.prototype.setWorkForSilentModeFlag = function () {
   this.workForSilentModeFlag = true;
};

GetAuthenticationStatusHandler.prototype.resetData = function () {
   this.workForSilentModeFlag = false;
   MessageHandler.prototype.resetData.apply(this);
};

GetAuthenticationStatusHandler.prototype.setRequestXML = function (needCallback) {
   if (needCallback === true) {
      this.needCallbackFlag = true;
   } else if (needCallback === false) {
      this.needCallbackFlag = false;
   } else {
      Logger.debug("error: needCallback should be of bool type");
      this.needCallbackFlag = false;
      return;
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetAuthenticationStatusHandler.prototype.onUpdated = function () {
   let response = this.content["parsedResult"],
      userActiveTimeSyncCtrl,
      router = util.getObject(globalArray, "router");

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.state === StateEnum.DONE) {
      //deal with ok response according to callback type
      Logger.debug("get authentication status succeeds.");
      if (!this.needCallbackFlag && !this.workForSilentModeFlag) {
         Logger.info("deal with getAuthenticationStatus response with normal process");
         this.dealWithAuthenticationStatusXML(response);
      } else if (this.needCallbackFlag) {
         //use timer controller to deal with response
         userActiveTimeSyncCtrl = util.getObject(globalArray, "get-broker-sso-time-ctrl");
         if (userActiveTimeSyncCtrl) {
            userActiveTimeSyncCtrl.dealWithAuthenticationStatusXML(response);
         } else {
            Logger.error("find userActiveTimeSyncCtrl undefined when getAuthenticationStatus.needCallbackFlag is set");
         }
      } else {
         // this.workForSilentModeFlag === true
         this.workForSilentModeFlag = false;
         Logger.info("deal with getAuthenticationStatus response before act in desktop-select page");
         this.returnAuthenticationStatus(response);
      }
   }

   //push error to handle old version broker, and deal with not authed error
   // case
   router.pushErrorToUser(this);
};

GetAuthenticationStatusHandler.prototype.returnAuthenticationStatus = function (response) {
   let authStatusAction;

   if (response.sso === "unlocked") {
      authStatusAction = { name: "AuthenticationStatusUnlocked" };
      // when launch >1 sessions from ws1 potal,
      // the saml credentials is not cleared, refert to VCART-1304
      Logger.info("delete local stored saml after auth done");
      delete globalArray["samlArt"];
   } else {
      // cann't reuse name: DoLock cause the dialog should not jump out in
      // silent mode.
      authStatusAction = { name: "AuthenticationStatusLocked" };
   }
   JSCDKSetUI(JSON.stringify(authStatusAction));
};

/* normal deal with AuthenticationStatusXML
 *
 * should only be used when init page
 */
GetAuthenticationStatusHandler.prototype.dealWithAuthenticationStatusXML = function (response) {
   let authStatusAction,
      idleTimeoutTimerController = util.getObject(globalArray, "idle-timeout-timer");

   if (response.sso === "unlocked") {
      //prepare for already authentication case
      prepareForAlreadyAuthenticated();
      //already authenticated, send to UI to read timer info back from
      // cookie or session storage
      // Clear saml after unlocked, fix bug 2594162
      delete globalArray["samlArt"];
      authStatusAction = { name: "SessionUnlocked" };
      JSCDKSetUI(JSON.stringify(authStatusAction));
   } else {
      authStatusAction = { name: "LockedNewTab" };
      JSCDKSetUI(JSON.stringify(authStatusAction));
   }
};
/**
 * parse locale information from the response XML of GetAuthenticationStatus
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
GetAuthenticationStatusHandler.prototype.parseResult = function (responseXML) {
   let responseBody,
      brokerTag,
      brokerElem,
      responses,
      resultText,
      authStatusResponse = {},
      ssoNode,
      ssoText,
      timeToSsoLockNode;

   if (!responseXML || responseXML === "") {
      return null;
   }
   responseBody = $(responseXML);
   //broker version in XML response is stored for later usage
   brokerElem = responseBody.find("broker"); //TOPolish: need to delete repeat
   // code with brokerTag
   globalArray["brokerVersion"] = brokerElem.attr("version");

   //parse data
   brokerTag = $(responseBody.children()[0]);
   if (!brokerTag) {
      Logger.error("response of getAuthenticationStatus error");
      return null;
   }
   responses = brokerTag.children(this.responseTag);
   resultText = responses.children("result").text();
   if (!!resultText && resultText.trim() !== "") {
      authStatusResponse["result"] = resultText;
   } else {
      Logger.error("response of getAuthenticationStatus error, no valid result tag");
      return null;
   }
   if (resultText === "ok") {
      ssoNode = responses.children("sso");
      if (!ssoNode) {
         Logger.error("response of getAuthenticationStatus error, no sso tag with result set ok");
         return null;
      }
      ssoText = $(ssoNode).text();
      util.addItemForJson(authStatusResponse, "sso", ssoText);

      if (ssoText === "unlocked") {
         timeToSsoLockNode = responses.children("time-to-sso-lock");
         if (!timeToSsoLockNode) {
            Logger.error("response of getAuthenticationStatus error, no time-to-sso-lock tag with sso set unlocked");
            return null;
         }
         util.addItemForJson(authStatusResponse, "time-to-sso-lock", $(timeToSsoLockNode).text());
      }
   } else if (resultText === "error") {
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(authStatusResponse, nameText, $(this).text());
      });
   }
   return authStatusResponse;
};
