/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../core/libs/logger";
import { Subscription } from "rxjs";
import { OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, Injectable, Inject } from "@angular/core";
import { AjaxBusyService } from "../common/ajax-busy/ajax-busy.service";
import { JscdkWrapper } from "../common/jscdk/jscdk-wrapper";
import { AppInjector } from "../../core/libs/app-injector";
import { BusEvent, EventBusService } from "@html-core";

export type ViewOption = {
   /**
    * default as false
    * when set as true, would not change the ajax busy status for rendering lifecycle,
    * and blankOutContent would always be false.
    */
   noBusyStatus: boolean;
};

export interface BaseViewComponentInterface extends BaseViewComponent {
   renderData: (isRefreshing?: boolean) => void;
}

@Injectable()
export abstract class BaseViewComponent implements OnInit, OnDestroy, AfterViewInit {
   /**
    * child component need to hide the elements when loading and submitting
    */
   public blankOutContent: boolean;
   private _blankSubscription: Subscription;
   private _inited: boolean;
   protected data: any;
   protected ajaxBusyService: AjaxBusyService;
   private _eventBusService: EventBusService;
   public jscdkWrapper: JscdkWrapper;
   public renderData = (isRefreshing?: boolean) => {};
   constructor(
      protected changeDetector: ChangeDetectorRef,
      @Inject(String) protected componentName: string,
      @Inject(Object) private _options?: ViewOption
   ) {
      this.ajaxBusyService = <AjaxBusyService>AppInjector.get(AjaxBusyService);
      this._eventBusService = <EventBusService>AppInjector.get(EventBusService);
      this.jscdkWrapper = <JscdkWrapper>AppInjector.get(JscdkWrapper);
      this.data = this.jscdkWrapper.data;
      this._inited = false;
   }

   private _hasBusyStatus = () => {
      return !this._options || !this._options.noBusyStatus;
   };

   public onAuthScreen = (componentName: string, renderData: any) => {
      if (!!renderData && !!renderData.content && !!renderData.content.error) {
         const authType = renderData.content.authType || componentName;
         const errorMessage = renderData.content.error;
         // 1st version would not allow retry from SDK
         this._eventBusService.dispatch(new BusEvent.AuthFailedMsg(authType, errorMessage, false));
         return;
      }
      this._eventBusService.dispatch(new BusEvent.AuthenticationNext(componentName, renderData));
   };

   public changeType = (id) => {
      setTimeout(() => {
         const pwdEle = $("#" + id);
         pwdEle.prop("type", "password");
      }, 200);
   };

   public changeBusyCursor = (isBusy: boolean) => {
      Logger.info("busy cursor changed to " + isBusy);
      setTimeout(() => {
         this.blankOutContent = isBusy;
      });
   };

   public ngOnInit() {
      this.blankOutContent = false;
      this._inited = true;

      if (this._hasBusyStatus()) {
         this._blankSubscription = this.ajaxBusyService.subscribe(this.changeBusyCursor);
      }
      this.renderData(false);
      Logger.debug("init component:" + this.componentName, Logger.ROUTE);
   }

   public ngOnDestroy() {
      if (!this._inited) {
         Logger.debug("ignore destroy for un-init component:" + this.componentName, Logger.ROUTE);
         return;
      }
      if (this._blankSubscription) {
         this._blankSubscription.unsubscribe();
      }
      this.tearDown("Destroy");
      Logger.debug("leave component:" + this.componentName, Logger.ROUTE);
   }

   public ngAfterViewInit() {
      // Cancel the loading status when component finish rendering
      if (this._hasBusyStatus()) {
         this.ajaxBusyService.setAjaxBusy(false);
      }
      Logger.debug("render done for component:" + this.componentName, Logger.ROUTE);
      if ($(".ui-tooltip").length > 0) {
         $(".ui-tooltip").css("top", "145px");
      }
   }

   public connecting() {
      Logger.trace(this.componentName + " is connecting", Logger.ROUTE);
   }
   public tearDown(name?: string) {
      Logger.trace(this.componentName + " is tear down", Logger.ROUTE);
   }
   public enable() {
      Logger.trace(this.componentName + " is enabled", Logger.ROUTE);
      setTimeout(() => {
         Logger.info("remove busy cursor");
         this.blankOutContent = false;
      });
      this.data = this.jscdkWrapper.data;
      this.renderData(true);
   }

   public onRefreshed() {
      Logger.debug(this.componentName + " is refreshed", Logger.ROUTE);
      this.data = this.jscdkWrapper.data;
   }
}
