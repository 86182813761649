/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * media-manager.ts
 * Media Manager
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { VMSize } from "../channels/html5MMR-client/model/html5MMR.media.models";
import { AudioResourceManager } from "./audio-resource-manager";
import { MediaResourceInterface, MediaType } from "./media-resource-manager";
import { MediaResourceUtil } from "./media-resource-util";
import { MediaStat } from "./model/media.models";
import { VideoResourceManager } from "./video-resource-manager";

@Injectable({
   providedIn: "root"
})

/**
 * Media manager class that manages media resources
 * Currently used by MS Teams media redirect feature: VHCH-5217
 */
export class MediaManager {
   private logger = new Logger(Logger.MEDIA);

   private streams: Map<string, MediaStream> = new Map<string, MediaStream>();
   private trackElementIdMap: Map<string, MediaStreamTrack> = new Map<string, MediaStreamTrack>();
   private mediaStatsMap: Map<string, MediaStat> = new Map<string, MediaStat>();

   private resourceManagers: Map<string, MediaResourceInterface> = new Map<string, MediaResourceInterface>();

   /**
    * Injected audio and video resource managers
    *
    * @param  {AudioResourceManager} audioResourceManager audio resource manager that implements MediaResourceManager
    * @param  {VideoResourceManager} videoResourceManager video resource manager that implements MediaResourceManager
    */
   constructor(
      private audioResourceManager: AudioResourceManager,
      private videoResourceManager: VideoResourceManager
   ) {
      this.logger.info("MediaManager created");
      this.resourceManagers.set(MediaType.audio, audioResourceManager);
      this.resourceManagers.set(MediaType.video, videoResourceManager);
   }

   /**
    * This is acting as destructor. It is responsible for cleaning up all media (video|audio) resources.
    */
   public clear = () => {
      this.audioResourceManager.clear();
      this.videoResourceManager.clear();

      Array.from(this.streams.values()).forEach((each) => {
         this.removeStream(each);
      });

      this.trackElementIdMap.clear();
      this.mediaStatsMap.clear();
   };

   /**
    * Helper function that retrieves media resource manager by given media type enum
    *
    * @param  {MediaType} type   possible media type values: audio|video
    * @returns MediaResourceInterface  media resource manager instance by given media type
    */
   private _getResourceManager = (type: MediaType): MediaResourceInterface | undefined => {
      const resourceManager: MediaResourceInterface = this.resourceManagers.get(type);
      if (!resourceManager) {
         this.logger.error("Resource manager cannot be found: " + type);
      }
      return resourceManager;
   };

   /**
    * Helper function that construct medial element id by given media (video|audio) id
    * Note should always use this function to build proper media element id
    *
    * @param  {string} id  given origin video|audio id
    * @param  {MediaType} mediaType either video|audio
    * @returns string
    */
   private _getMediaElementId = (id: string, mediaType: MediaType): string => {
      switch (mediaType) {
         case MediaType.audio:
            return `${MediaResourceUtil.AUDIO_ELEMENT_PREFIX}${id}`;
         case MediaType.video:
            return `${MediaResourceUtil.VIDEO_ELEMENT_PREFIX}${id}`;
         default:
            return id;
      }
   };

   /**
    * Create a new HTMLMediaElement by media type, id and other parameters
    *
    * @param  {MediaType} type   given media type. Possible values: audio|video
    * @param  {string} id  given media html element id
    * @param  {any} params extra parameters specified to describe the media element init state
    *                      e.g. for video element, might need to specify its initial location and size
    * @returns HTMLMediaElement or undefined if element cannot be created
    */
   public createElement = (type: MediaType, id: string, params: any): HTMLMediaElement | undefined => {
      const resourceManager: MediaResourceInterface = this._getResourceManager(type);
      const mediaId: string = this._getMediaElementId(id, type);
      this.mediaStatsMap.set(mediaId, new MediaStat());
      return resourceManager.getElement(mediaId) || resourceManager.createElement(mediaId, params);
   };

   /**
    * Update global volumn/mute status for all media elements
    *
    * @param  {boolean} mute  given mute status
    * @param  {number} volume given volumn in float
    */
   public updateVolume = (mute: boolean, volume: number) => {
      this.audioResourceManager.updateVolume(mute, volume);
      this.videoResourceManager.updateVolume(mute, volume);
   };

   /**
    * Retrieve media element based on media type and given Id
    *
    * @param  {MediaType} type   given media type. Possible values: audio|video
    * @param  {string} id given media html element id
    * @returns HTMLMediaElement  or undefined if element cannot be created
    */
   public getElement = (type: MediaType, id: string): HTMLMediaElement => {
      const resourceManager: MediaResourceInterface = this._getResourceManager(type);
      const mediaId: string = this._getMediaElementId(id, type);
      return resourceManager.getElement(mediaId);
   };

   /**
    * Update media element by id and parameters
    *
    * @param  {MediaType} type   given media type (either video|audio)
    * @param  {string} id  given media element id
    * @param  {any} params media element parameters JSON
    */
   public updateElement = (type: MediaType, id: string, params: any) => {
      const resourceManager: MediaResourceInterface = this._getResourceManager(type);
      const mediaId: string = this._getMediaElementId(id, type);
      resourceManager.updateElement(mediaId, params);
   };

   /**
    * Delete media element based on media type and given Id
    *
    * @param  {MediaType} type   given media type. Possible values: audio|video
    * @param  {string} id  given media html element id
    * @returns HTMLMediaElement  or undefined if element cannot be found
    */
   public deleteElement = (type: MediaType, id: string): HTMLMediaElement => {
      const resourceManager: MediaResourceInterface = this._getResourceManager(type);
      const mediaId: string = this._getMediaElementId(id, type);
      this.trackElementIdMap.delete(mediaId);
      this.mediaStatsMap.delete(mediaId);
      return resourceManager.removeElement(mediaId);
   };

   /**
    * Wrapper function of navigator.mediaDevices.enumerateDevices
    * This retrieves list of available media input/output devices
    * such as microphones, cameras ... etc.
    *
    * @returns Promise<MediaDeviceInfo[]>
    */
   public getMediaDevices = () => {
      return new Promise((resolve, reject) => {
         navigator.mediaDevices
            .enumerateDevices()
            .then(function (devices) {
               resolve(devices);
            })
            .catch(function (err) {
               reject(err);
            });
      });
   };

   /**
    * Add new media stream to corresponding media resource manager
    *
    * @param  {MediaStream} stream media stream to be added
    */
   public addStream = (stream: MediaStream) => {
      const streamId: string = stream.id,
         tracks: MediaStreamTrack[] = stream.getTracks();

      this.streams[streamId] = stream;
      tracks.forEach((track: MediaStreamTrack) => {
         const resourceManager: MediaResourceInterface = this._getResourceManager(MediaType[track.kind]);
         resourceManager.addTrack(track);
      });
   };

   /**
    * Update media stream by given stream id in local cache
    *
    * @param  {string} sId given stream id
    * @param  {MediaStream} stream  media stream to be cached
    */
   public updateStream = (sId: string, stream: MediaStream) => {
      this.streams[sId] = stream;
   };

   /**
    * Update media track by given track id in local cache
    *
    * @param  {string} tId given track id
    * @param  {MediaStreamTrack} track media track to be cached
    */
   public updateTrack = (tId: string, track: MediaStreamTrack) => {
      const resourceManager: MediaResourceInterface = this._getResourceManager(MediaType[track.kind]);
      resourceManager.updateTrack(tId, track);
   };

   /**
    * Remove given media stream
    *
    * @param  {MediaStream} stream given media stream to be removed
    */
   public removeStream = (stream: MediaStream) => {
      const streamId: string = stream.id,
         tempStream: MediaStream = this.streams[streamId],
         tempTracks: MediaStreamTrack[] = tempStream.getTracks();

      tempTracks.forEach((track) => {
         const resourceManager: MediaResourceInterface = this._getResourceManager(MediaType[track.kind]);
         resourceManager.removeTrack(track);
      });

      this.streams[streamId] = null;
   };

   /**
    * Retrieve media stream by given stream id
    *
    * @param  {string} streamId given stream id
    * @returns MediaStream media stream by given stream id or undefined if not found
    */
   public getStream = (streamId: string): MediaStream | undefined => {
      return this.streams[streamId];
   };

   /**
    * Retrieve media stream track by given track id
    *
    * @param  {string} trackId given media track id
    * @returns MediaStreamTrack  media stream track object by given track id or undefined if not found
    */
   public getTrack = (trackId: string): MediaStreamTrack | undefined => {
      const videoTrack = this.videoResourceManager.getTrack(trackId),
         audioTrack = this.audioResourceManager.getTrack(trackId);
      if (!videoTrack && !audioTrack) {
         this.logger.error("Retrieve media track that no longer exists: " + trackId);
      }
      return videoTrack || audioTrack;
   };

   /**
    * Change of media stream track enablement status
    *
    * @param  {string} trackId given track id
    * @param  {boolean} enabled boolean flag to indicate if given media track is enabled or disabled
    */
   public enableTrack = (trackId: string, enabled: boolean) => {
      const track = this.getTrack(trackId);
      if (!track) {
         this.logger.error("Enable track failed because track is not available: " + trackId);
         return;
      }

      const resourceManager: MediaResourceInterface = this._getResourceManager(MediaType[track.kind]);
      resourceManager.enableTrack(trackId, enabled);
   };

   /**
    * Wrapper of navigator.mediaDevices.getUserMedia that prompts the user to use a media input
    * which produces a MediaStream with tracks containing the requested types of media.
    * Reference: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    *
    * @param  {MediaStreamConstraints} constraints given media stream constraints that specifies what type of media input device it's requesting
    * @returns Promise that resolves to a MediaStream object
    */
   public getUserMedia = (constraints: MediaStreamConstraints): Promise<MediaStream> => {
      return navigator.mediaDevices.getUserMedia(constraints).then((stream: MediaStream) => {
         this.addStream(stream);
         return stream;
      });
   };

   /**
    * Stop media track by given track id
    *
    * @param  {string} trackId given media track id
    */
   public stopTrack = (trackId: string) => {
      const track: MediaStreamTrack = this.getTrack(trackId);

      if (!track) {
         this.logger.error("Stop track failed because track is not available: " + trackId);
         return;
      }

      const resourceManager: MediaResourceInterface = this._getResourceManager(MediaType[track.kind]);
      resourceManager.stopTrack(trackId);
   };

   /**
    * Update srcObject property of the media element by given Id and MediaType
    *
    * @param  {MediaType} type media type that canbe either audio|video
    * @param  {string} streamId given media stream id
    */
   public updateSrcObject = (type: MediaType, streamId: string, elementId: string) => {
      const stream: MediaStream = this.getStream(streamId),
         resourceManager: MediaResourceInterface = this._getResourceManager(type);

      const mediaId: string = this._getMediaElementId(elementId, type);

      if (stream && stream.getTracks().length > 0) {
         this.trackElementIdMap.set(mediaId, stream.getVideoTracks()[0]);
      } else {
         this.trackElementIdMap.delete(mediaId);
      }

      resourceManager.updateSourceObject(mediaId, stream);
   };

   /**
    * Play Media (video|audio) source by given media id
    *
    * @param  {MediaType} type   media type that canbe either audio|video
    * @param  {string} id  given media id
    * @param  {string} src specifies the location (URL) of the media file.
    * @param  {boolean} loop  specifies that the media will start over again, every time it is finished
    */
   public playMediaSource = (type: MediaType, id: string, src: string, loop: boolean) => {
      const element = this.createElement(type, id, null);

      if (!element) {
         this.logger.error("Cannot play media resource as element cannot be created.");
         return;
      }

      element.loop = loop;
      // We don't play this source right now.
      // Because most likely the src will be CORS
      // We wait the binary data below comes and to be decoded and play.
   };

   /**
    * Play Binar notify audio data
    *
    * @param {number} audioId, audio id to find audio element
    * @param {Uint8Array} audioData
    */
   public onBinaryNotifyAudio = (audioId: number, audioData: Uint8Array) => {
      const blob = new Blob([audioData]);

      const audioElement = this.createElement(MediaType.audio, audioId.toString(), null);
      audioElement.src = URL.createObjectURL(blob);
      audioElement.play();
   };

   /**
    * Pause media (video|audio) source by given media id
    *
    * @param  {MediaType} type  media type that canbe either audio|video
    * @param  {string} id  given media id
    */
   public pauseMedia = (type: MediaType, id: string) => {
      const element = this.getElement(type, id);

      if (!element) {
         this.logger.error("Cannot pause media resource as element cannot be found.");
         return;
      }

      element.pause();
      this.trackElementIdMap.delete(id);
      this.deleteElement(type, id);
   };

   /**
    * Update stats for meta data from media stream track setting
    *
    * @param  {MediaType} type
    * @param  {string} elementId
    */
   public updateMediaStreamTrackStat = (type: MediaType, elementId: string) => {
      const mediaTrack = this.trackElementIdMap.get(elementId) as any;
      if (mediaTrack) {
         const trackSettings = mediaTrack.getSettings(),
            mediaSourceSize =
               mediaTrack._width && mediaTrack._height
                  ? new VMSize(mediaTrack._width, mediaTrack._height)
                  : new VMSize(trackSettings.width, trackSettings.height),
            mediaSourceFPS = mediaTrack._frameRate ? mediaTrack._frameRate : Number(trackSettings.frameRate.toFixed(2));
         this._updateSourceSize(type, elementId, mediaSourceSize);
         this._updateSourceFPS(type, elementId, mediaSourceFPS);
         const mediaSourceBWE = mediaTrack["_bwe"];
         this._updateSourceBWE(type, elementId, mediaSourceBWE);
      }
   };

   /**
    * Update overlay size stats by given media element id
    *
    * @param  {MediaType} type
    * @param  {string} elementId
    * @param  {VMSize} size
    */
   public updateOverlaySize = (type: MediaType, elementId: string, size: VMSize) => {
      const mediaStats: MediaStat = this.mediaStatsMap.get(elementId);
      if (mediaStats) {
         mediaStats.updateOverlaySize(size);
      }
      this._updateStats(type, elementId);
   };

   /**
    * Update media content size from media source.
    *
    * @param  {MediaType} type
    * @param  {string} elementId
    * @param  {VMSize} size
    */
   private _updateSourceSize = (type: MediaType, elementId: string, size: VMSize) => {
      const mediaStats: MediaStat = this.mediaStatsMap.get(elementId);
      if (mediaStats) {
         mediaStats.updateSourceSize(size);
      }
      this._updateStats(type, elementId);
   };

   /**
    * Update media source frame rate (fps)
    *
    * @param  {MediaType} type
    * @param  {string} elementId
    * @param  {number} fps
    */
   private _updateSourceFPS = (type: MediaType, elementId: string, fps: number) => {
      const mediaStats: MediaStat = this.mediaStatsMap.get(elementId);
      if (mediaStats) {
         mediaStats.updateFPS(fps);
      }
      this._updateStats(type, elementId);
   };

   /**
    * Update media source bwe
    *
    * @param  {MediaType} type
    * @param  {string} elementId
    * @param  {number} bwe
    */
   private _updateSourceBWE = (type: MediaType, elementId: string, bwe: number) => {
      const mediaStats: MediaStat = this.mediaStatsMap.get(elementId);
      if (mediaStats) {
         mediaStats.updateBWE(bwe);
      }
      this._updateStats(type, elementId);
   };

   /**
    * Update media statistics for given media element id and type.
    * Will only rerender the stats overlay if each MediaStat model is marked as dirty.
    *
    * @param  {MediaType} type
    * @param  {string} elementId
    */
   private _updateStats = (type: MediaType, elementId: string) => {
      const resourceManager: MediaResourceInterface = this._getResourceManager(type);
      const mediaStats: MediaStat = this.mediaStatsMap.get(elementId);
      if (mediaStats && mediaStats.dirty) {
         mediaStats.dirty = false;
         resourceManager.updateStats(elementId, mediaStats);
      }
   };
}
