<div id="header-container">
   <launcher-header [isChromeWS1Mode]="isChromeWS1Mode" [option]="headerOptions" (targetViewChange)="onOnlyFavorites($event)" (searchChange)="onSearchChanged($event)" (settingDialogStatus)="onSettingDialog($event)"></launcher-header>
</div>
<div class="launch-page content-panel-container">
   <div class="hideLoadingDialog" *ngIf="isChromeWS1Mode"></div>
   <div class="container" [ngClass]="blankOutContent ? 'hidePage' : 'showPage'" *ngIf="!isChromeWS1Mode" [hidden]="!showLaunchPanel || isUrlLaunching" [attr.role]="'main' | i18nv2">
         <ul class="ui-desktop-list">
            <li *ngFor="let desktop of (desktops | availableItemsFilter: search: showOnlyFavorites| orderBy: 'name')" id="{{desktop.id}}" tabindex="-1" (keydown)="keyboardHandler($event, desktop, true)" (focus)="highlightItems(desktop, $event)" (blur)="removeHighlight()" (contextmenu)="toggleContextMenu(desktop, true, $event)">
               <button class="ui-btn ui-btn-a ui-desktop-corner" id="launch-{{desktop.id}}"  title="{{desktop.name}}" tabindex="0" (click)="launchDesktop(desktop, $event)" [ngClass]="{highlight:activeItem===desktop}" (blur)="removeHighlight()" [attr.aria-label]= "'ITEM_BUTTON_LABEL' | i18nv2">
                     <img [src]="this.imageAsset.deDesktopIcon" alt="{{desktop.name}} icon" class="ui-desktop-icon" [hidden]="desktop.isShadow">
                     <img [src]="this.imageAsset.deShadowIcon" alt="desktop icon" class="ui-desktop-icon" [hidden]="!desktop.isShadow">
                  <button *ngIf="showPreference && desktop.favorite" class="favorite-btn favorite-on" id="{{desktop.id}}Favon"
                  title="{{favTip}}" (click)="makeFavorite(desktop,$event);" (keydown)="makeFavorite(desktop,$event);">
                  </button>
                  <button *ngIf="showPreference && !desktop.favorite" class="favorite-btn favorite-off" id="{{desktop.id}}Favoff"
                  title="{{unfavTip}}" (click)="makeFavorite(desktop,$event);" (keydown)="makeFavorite(desktop,$event); ">
                  </button>
                  <button class="overflow-button app-item-action primary-text-color"
                  [attr.aria-label]="'apps.aria.label.context.menu.overflow' | i18nv2" aria-haspopup="true"
                  aria-expanded="false" (click)="toggleContextMenu(desktop, true, $event)">
                  </button>
                  <share-contextmenu *ngIf="desktop.showContextMenu" class="content-menu"
                     [contextmenuName]="contextmenuName" [item]="desktop" (connectEvent)="launchDesktop($event)"
                     [paneClass]="paneClass" >
                  </share-contextmenu>
               </button>
               <div class="ellipsis">
                  <p class="ui-desktop-name" title="{{desktop.name}}">{{desktop.name}}</p>
               </div>
            </li>
         </ul>
         <div class="ui-desktop-list-line" [hidden]="!((desktops | availableItemsFilter: search: showOnlyFavorites).length > 0 && (applications | availableItemsFilter: search: showOnlyFavorites).length > 0)"></div>
         <ul class="ui-desktop-list">
            <li *ngFor="let application of (applications | availableItemsFilter: search: showOnlyFavorites| orderBy: 'name')" id="{{application.id}}" tabindex="-1" (keydown)="keyboardHandler($event, application, false)" (focus)="highlightItems(application, $event)" (blur)="removeHighlight()" (contextmenu)="toggleContextMenu(application, false, $event)">
               <button class="ui-btn ui-btn-a ui-desktop-corner" id="launch-{{application.id}}" title="{{application.name}}" tabindex="0" (click)="launchApplication(application, $event)"  [ngClass]="{highlight:activeItem===application}" (blur)="removeHighlight()" [attr.aria-label]= "'ITEM_BUTTON_LABEL' | i18nv2">
                  <img [src]="this.imageAsset.deAppIcon" alt="{{application.name}} icon" class="ui-desktop-icon ui-app-icon" icon-load url="{{application.icon? application.icon.path : ''}}">
                  <button *ngIf="showPreference && application.favorite" class="favorite-btn favorite-on" id="{{application.id}}Favon"
                  title="{{favTip}}" (click)="makeFavorite(application,$event);" (keydown)="makeFavorite(application,$event);">
                  </button>
                  <button *ngIf="showPreference && !application.favorite" class="favorite-btn favorite-off" id="{{application.id}}Favoff"
                  title="{{unfavTip}}" (click)="makeFavorite(application,$event);" (keydown)="makeFavorite(application,$event);">
                  </button>
                  <share-contextmenu *ngIf="application.showContextMenu" class="content-menu"
                     [contextmenuName]="contextmenuName" [item]="application" (connectEvent)="launchApplication($event)"
                     [paneClass]="paneClass" >
                  </share-contextmenu>
               </button>
               <div class="ellipsis">
                  <p class="ui-desktop-name" title="{{application.name}}">{{application.name}}</p>
               </div>
            </li>
         </ul>
      </div>
      <div class="container" [hidden]="!showNoFavorites(desktops,applications)">
         <div class="no-favorites"></div>
         <div class="no-favorites-word">{{'noFavorites' | i18nv2}}</div>
      </div>
   <div [hidden]="!blankOutContent && !isUrlLaunching" class="ui-icon-loading" [attr.role]="'LOADING' | i18nv2">
      <div class="loginLoadingTextForWaiting">{{ 'LOADING' | i18nv2 }}</div>
      <loading-image-animation [showLoading]="blankOutContent || isUrlLaunching"> </loading-image-animation>
   </div>
   <div class="titanLaunchBar" *ngIf="isRampEnabled">
      <button id="titanLaunchButton"  (click)="launchTitanInNewTab()">{{'LAUNCH_TITAN_BUTTON' | i18nv2}}</button>
   </div>
</div>