/**
 * ***************************************************************************
 * Copyright 2018-2021 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

/**
 *------------------------------------------------------------------------------
 *
 * rdpdr-channel-manager.js
 *
 * Mainly used to process RDPDR messages
 *
 * Smart card uses same protocol but can be totally decoupled from CDR, since
 * Agent is designed well, so we don't have to worry about smartcard when handling
 * CDR, and vice versa.
 *
 *------------------------------------------------------------------------------
 */

import { TDSR_TYPE, RDPDR_TYPE, RDPDR_POLICY, NT_STATUS, tsdrSchema } from "./index";
import { Injectable } from "@angular/core";
import { ProtocolUtil } from "../vdpservice/util/protocol-util";
import { ProtocolHelper } from "../vdpservice/util/protocol-helper";
import Logger from "../../../core/libs/logger";
import { SharedFolderManager } from "./shared-folder-manager";
import { FsObserver } from "./fs-observer";
import { CDRConfig } from "./cdr-config";
import { FileSystem } from "./file-system";

@Injectable()
export class RdpdrChannelManager {
   private logger = new Logger(Logger.RDPDR);
   private userLoggedOn = false;
   private pPolicy = null;
   private serverVersion = TDSR_TYPE.VERSION.TSDR_VERSION_UNKNOWN;
   // For CDR on Chromebook, we hard code client capacity as read & write
   private readonly = false;
   private tsdrChannel = null;
   private tsdrDataObject = null;
   private protocolHelper: ProtocolHelper = ProtocolUtil.getHelper(tsdrSchema);

   constructor(
      private sharedFolderManager: SharedFolderManager,
      private fsObserver: FsObserver,
      private cdrConfig: CDRConfig,
      private fileSystem: FileSystem
   ) {}

   public init = (tsdrChannel, tsdrDataObject) => {
      this.tsdrDataObject = tsdrDataObject;
      this.tsdrChannel = tsdrChannel;
   };

   private _resetStatus = () => {
      this.userLoggedOn = false;
      this.pPolicy = null;
      this.serverVersion = TDSR_TYPE.VERSION.TSDR_VERSION_UNKNOWN;
   };

   private _handleVersionExchange = (streamReader) => {
      const serverVersionInfo = this.protocolHelper.parse(streamReader, "TSDR_CAPS_VERSION_EXCHANGE_RQE");
      this.logger.debug("<==handleVersionExchange" + JSON.stringify(serverVersionInfo));
      return true;
   };

   private _handleTsdrPolicy = (streamReader) => {
      this.logger.debug("<==handleTsdrPolicy");
      //not parse since it's not in the support scope.
      this.pPolicy = streamReader.getRest();
      this.logger.trace(JSON.stringify(this.pPolicy));
      return true;
   };

   /**
    * Will make sure the string length is no more than length-1, since last char
    * must be /0
    * @param  {[type]} string [description]
    * @param  {[type]} length [description]
    * @return {[type]}        [description]
    */
   private _clipCString = (string, length) => {
      return string.slice(0, length - 1);
   };

   /**
    * Support only one folder for now, will change API later if time allow.
    * folder name is in UTF8
    * @folderName  {string}
    * @deviceId  {number}
    */
   private _deviceListAnnounce = (folderName, deviceId) => {
      /*if (fileSystem.noDevice()) {
         return;
      }TODO*/
      const deviceType = RDPDR_TYPE.DEVICE_TYPE;
      const folderPermission = RDPDR_POLICY.TSDR_FOLDER_PERMISSION.TSDR_PERM_NORMAL; //TODO

      const encodedFolderName = ProtocolUtil.encodeUTF8(folderName, RDPDR_TYPE.DEVICE_DATA_LOCAL_NAME_LENGTH - 1, true);

      //clip folder name
      const dosName = this._clipCString(folderName, RDPDR_TYPE.PREFERRED_DOS_NAME_LENGTH);

      // Use 1st Byte in device id for access control
      deviceId |= folderPermission * (1 << RDPDR_POLICY.TSDR_DEVID_AC_SHIFT);

      const deviceAnnounce = {
         header: {
            component: RDPDR_TYPE.RDPDR_CTYP_CORE,
            packetId: RDPDR_TYPE.PAKID_CORE_DEVICELIST_ANNOUNCE
         },
         deviceCount: 1,
         deviceList: [
            {
               deviceType: deviceType,
               deviceId: deviceId,
               dosName: dosName,
               deviceDataLength: encodedFolderName.length + 1,
               deviceData: encodedFolderName
            }
         ]
      };
      const param = {};

      this.sendResponse("DR_CORE_DEVICELIST_ANNOUNCE_REQ", deviceAnnounce, param);
   };

   public removeSelectedFolder = (entry, deviceId) => {
      this.logger.info("folder " + entry.name + " removed");
      const deviceRemove = {
         header: {
            component: RDPDR_TYPE.RDPDR_CTYP_CORE,
            packetId: RDPDR_TYPE.PAKID_CORE_DEVICELIST_REMOVE
         },
         deviceCount: 1,
         deviceIds: [deviceId]
      };
      this.sharedFolderManager.removeSharedFolderById(deviceId);
      this.sendResponse("DR_DEVICELIST_REMOVE", deviceRemove);
   };

   private redirectSelectedFolders = async () => {
      await this.sharedFolderManager.afterInited();
      this.sharedFolderManager.forEach((sharedFolder) => {
         this.logger.info("redirect folder: " + sharedFolder.deviceName);
         this.logger.debug("redirect folder id: " + sharedFolder.id);
         sharedFolder.setOnRemoved(this.removeSelectedFolder);
         this._deviceListAnnounce(sharedFolder.deviceName, sharedFolder.id);
      });
      this.fsObserver.startMonitor();
   };

   private onUserReady = () => {
      this.logger.info("CDR onUserReady");
      this.redirectSelectedFolders();
   };

   private sendRPC = (streamWriter) => {
      if (!this.tsdrChannel) {
         this.logger.error("RPC send failed: tsdrChannel not initialized");
         return;
      }
      const command = 0;
      const result = this.tsdrChannel.invoke({
         object: this.tsdrDataObject,
         command: command,
         type: "POST",
         params: streamWriter.hasData() ? [streamWriter.getStream()] : [],
         onDone: () => {
            this.logger.trace("send rpc done");
         },
         onAbort: () => {
            this.logger.error("send rpc aborted");
         }
      });
      return !!result;
   };

   private sendResponse = (type, data, param?: any) => {
      param = param || {};
      this.logger.trace("sending response of type " + type + " :" + JSON.stringify(data));
      const streamWriter = ProtocolUtil.getWriter(false);
      this.protocolHelper.streamify(streamWriter, type, data, param);
      this.sendRPC(streamWriter); //commandMap[type] instead of type
   };

   private handleServerAnnounce = (streamReader) => {
      this._resetStatus();
      const serverVersionInfo = this.protocolHelper.parse(streamReader, "DR_CORE_SERVER_ANNOUNCE_REQ");

      this.logger.trace("==>handleServerAnnounce" + JSON.stringify(serverVersionInfo));
      this.serverVersion = serverVersionInfo.versionMajor;

      const clientVersionInfo = serverVersionInfo;

      clientVersionInfo.versionMinor = this.cdrConfig.getMinorVersion();
      clientVersionInfo.header.packetId = RDPDR_TYPE.PAKID_CORE_CLIENTID_CONFIRM;

      this.sendResponse("DR_CORE_CLIENT_ANNOUNCE_RSP", clientVersionInfo);
      this.logger.trace("<==sendClientVersionInfo" + JSON.stringify(clientVersionInfo));
      const tsdrInfo = this.cdrConfig.getTsdrInfo();
      const tsdrClientVersionInfo = {
         header: {
            component: TDSR_TYPE.TSDR_COMPONENT_CAPS,
            packetId: TDSR_TYPE.TSDR_PACKID_VERSION_EXCHANGE
         },
         version: tsdrInfo.version,
         caps: tsdrInfo.caps
      };
      this.sendResponse("TSDR_CAPS_VERSION_EXCHANGE_RQE", tsdrClientVersionInfo);
      this.logger.trace("<==sendTSDRClientVersionInfo" + JSON.stringify(tsdrClientVersionInfo));
   };

   private sendClientCapability = () => {
      //TODO: set server capacity
      const clientCapability = this.cdrConfig.getCapability();
      const param = {
         capabilityMessage: [
            "GENERAL_CAPS_SET",
            "PRINTER_CAPS_SET",
            "PORT_CAPS_SET",
            "DRIVE_CAPS_SET",
            "SMARTCARD_CAPS_SET"
         ]
      };
      this.sendResponse("DR_CORE_CAPABILITY_REQ", clientCapability, param);
      this.logger.trace("<==sendClientCapability");
   };

   private sendClientName = () => {
      const machineName = "Horizon";
      const machineNameLength = (machineName.length + 1) * 2;
      const clientName = {
         header: {
            component: RDPDR_TYPE.RDPDR_CTYP_CORE,
            packetId: RDPDR_TYPE.PAKID_CORE_CLIENT_NAME
         },
         unicodeFlag: 0x00000001,
         codePage: 0,
         computerNameLen: machineNameLength, //(cchComputerNameLength + 1) * sizeof(WCHAR),
         computerName: machineName
      };
      this.sendResponse("DR_CORE_CLIENT_NAME_REQ", clientName);
      this.logger.trace("<==sendClientName");
   };

   /**
    * like Mobile clients webclient also will simplify the capacity to only one.
    * RDSDRQueueClientCapabiltyResponse
    */
   private handleServerCapability = (streamReader) => {
      this.logger.trace("==>handleServerCapability");

      this.sendClientCapability();
      this.sendClientName();
      return true;
   };

   private handleDeviceReply = () => {
      this.logger.trace("==>handleDeviceReply");
      return true;
   };

   //https://jameshfisher.com/2017/02/24/what-is-mode_t.html
   //https://www.gnu.org/software/libc/manual/html_node/Permission-Bits.html#Permission-Bits
   private getStatus = (permission, fileType) => {
      // TODO using S_TYPES
   };

   private sendIoResponse = (ioCompletion, type) => {
      const ioResponse = {
         header: {
            component: RDPDR_TYPE.RDPDR_CTYP_CORE,
            packetId: RDPDR_TYPE.PAKID_CORE_DEVICE_IOCOMPLETION
         },
         ioCompletion: ioCompletion
      };
      const param = {
         parameters: type
      };
      this.sendResponse("DR_DEVICE_IOCOMPLETION", ioResponse, param);
   };

   private getIoResponse = (ioRequest, ioStatus, parameters) => {
      return {
         deviceId: ioRequest.deviceId,
         completionId: ioRequest.completionId,
         ioStatus: ioStatus,
         parameters: parameters
      };
   };

   private getIoResponseWithoutPayload = (ioRequest, ioStatus) => {
      return this.getIoResponse(ioRequest, ioStatus, {
         padding: [0, 0, 0, 0, 0]
      });
   };

   private sendIoResponseWithoutPayload = (ioRequest, ioStatus) => {
      const ioCompletion = this.getIoResponseWithoutPayload(ioRequest, ioStatus);
      this.sendIoResponse(ioCompletion, "None");
   };

   public processIoRequest = async (ioRequest) => {
      let ioCompletion = null;
      this.logger.trace("-->" + ioRequest.parameters.unionType + JSON.stringify(ioRequest));
      switch (ioRequest.parameters.unionType) {
         case "Create": {
            const create = ioRequest.parameters;
            try {
               const creatInfo = await this.fileSystem.redirectedCreateFile(
                  ioRequest.deviceId,
                  create.desiredAccess,
                  create.path,
                  create.allocationSize,
                  create.fileAttributes,
                  create.sharedAccess,
                  create.createDisposition,
                  create.createOptions,
                  this.readonly
               );
               ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, creatInfo);
            } catch (e) {
               if (e === "STATUS_NO_SUCH_FILE") {
                  throw "STATUS_OBJECT_NAME_NOT_FOUND";
               } else {
                  throw e;
               }
            }
            break;
         }
         case "QueryInformation": {
            const queriedInfo = this.fileSystem.redirectedQueryInformationFile(
               ioRequest.deviceId,
               ioRequest.fileId,
               ioRequest.parameters.fsInformationClass
            );
            ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, queriedInfo);
            break;
         }
         case "SetInformation": {
            const setInfo = await this.fileSystem.redirectedSetInformationFile(
               ioRequest.deviceId,
               ioRequest.fileId,
               ioRequest.parameters.fileInformationClass,
               ioRequest.parameters.length,
               ioRequest.parameters.information
            );
            ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, setInfo);
            break;
         }
         case "Close":
            await this.fileSystem.redirectedCloseFile(ioRequest.deviceId, ioRequest.fileId);
            ioCompletion = this.getIoResponseWithoutPayload(ioRequest, NT_STATUS.STATUS_SUCCESS);
            break;
         case "NotifyChangeDirectory": {
            const changed = ioRequest.parameters;
            ioCompletion = this.getIoResponseWithoutPayload(ioRequest, NT_STATUS.STATUS_SUCCESS);
            break;
         }
         case "QueryDirectory": {
            const directorControl = await this.fileSystem.redirectedQueryDirectorFile(
               ioRequest.deviceId,
               ioRequest.fileId,
               ioRequest.parameters.initialQuery,
               ioRequest.parameters.szPath, //reg exp to filter folder content
               ioRequest.parameters.fileInformationClass
            );

            ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, directorControl);
            break;
         }
         case "Read": {
            const readResult = await this.fileSystem.redirectedReadFile(
               ioRequest.deviceId,
               ioRequest.fileId,
               ioRequest.parameters.length,
               ioRequest.parameters.offset.getValue(),
               {
                  requestId: ioRequest.completionId
               }
            );
            ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, readResult);
            break;
         }
         case "Write": {
            const writeResult = await this.fileSystem.redirectedWriteFile(
               ioRequest.deviceId,
               ioRequest.fileId,
               ioRequest.parameters.writeData,
               ioRequest.parameters.length,
               ioRequest.parameters.offset.getValue()
            );
            ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, writeResult);
            break;
         }
         case "QueryVolumeInformation": {
            const volumeInformation = this.fileSystem.redirectedQueryVolumeInformationFile(
               ioRequest.deviceId,
               ioRequest.fileId,
               ioRequest.parameters.fsInformationClass
            );
            ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, volumeInformation);
            break;
         }
         case "DeviceControl": {
            const ioControlResult = this.fileSystem.redirectedDeviceIoControlFile(
               ioRequest.deviceId,
               ioRequest.fileId,
               ioRequest.parameters.ioControlCode,
               ioRequest.parameters.inputBuffer,
               ioRequest.parameters.outputBufferLength
            );
            ioCompletion = this.getIoResponse(ioRequest, NT_STATUS.STATUS_SUCCESS, ioControlResult);
            break;
         }
         case "LockControl":
            ioCompletion = this.getIoResponseWithoutPayload(ioRequest, NT_STATUS.STATUS_SUCCESS);
            break;
         default:
            this.logger.trace(
               "-->message type no implemented: " + ioRequest.parameters.unionType + JSON.stringify(ioRequest)
            );
            throw NT_STATUS.STATUS_NOT_IMPLEMENTED;
      }
      return ioCompletion;
   };

   private handleIoRequest = (streamReader) => {
      this.logger.trace("==>handleIoRequest");
      const param = {
         parameters: function (parsed) {
            const typeMap = {};
            typeMap[RDPDR_TYPE.IRP_MJ_CREATE] = "Create";
            typeMap[RDPDR_TYPE.IRP_MJ_CLOSE] = "Close";
            typeMap[RDPDR_TYPE.IRP_MJ_READ] = "Read";
            typeMap[RDPDR_TYPE.IRP_MJ_WRITE] = "Write";
            typeMap[RDPDR_TYPE.IRP_MJ_DEVICE_CONTROL] = "DeviceControl";
            typeMap[RDPDR_TYPE.IRP_MJ_QUERY_VOLUME_INFORMATION] = "QueryVolumeInformation";
            typeMap[RDPDR_TYPE.IRP_MJ_SET_VOLUME_INFORMATION] = "SetVolumeInformation";
            typeMap[RDPDR_TYPE.IRP_MJ_QUERY_INFORMATION] = "QueryInformation";
            typeMap[RDPDR_TYPE.IRP_MJ_SET_INFORMATION] = "SetInformation";
            typeMap[RDPDR_TYPE.IRP_MJ_DIRECTORY_CONTROL] = function () {
               const minorMap = {};
               minorMap[RDPDR_TYPE.IRP_MN_QUERY_DIRECTORY] = "QueryDirectory";
               minorMap[RDPDR_TYPE.IRP_MN_NOTIFY_CHANGE_DIRECTORY] = "NotifyChangeDirectory";
               if (minorMap.hasOwnProperty(parsed.minorFunction)) {
                  return minorMap[parsed.minorFunction];
               }
               throw "Not supported type";
            };
            typeMap[RDPDR_TYPE.IRP_MJ_LOCK_CONTROL] = "LockControl";
            if (!typeMap.hasOwnProperty(parsed.majorFunction)) {
               return null;
            }
            const resultType = typeMap[parsed.majorFunction];
            if (typeof resultType === "function") {
               return resultType();
            }
            return resultType;
         },
         information: function (parsed) {
            const typeMap = {};
            typeMap[RDPDR_TYPE.FILE_INFORMATION_CLASS.FileBasicInformation] = "FileBasicInformation";
            typeMap[RDPDR_TYPE.FILE_INFORMATION_CLASS.FileEndOfFileInformation] = "FileEndOfFileInformation";
            typeMap[RDPDR_TYPE.FILE_INFORMATION_CLASS.FileAllocationInformation] = "FileAllocationInformation";
            typeMap[RDPDR_TYPE.FILE_INFORMATION_CLASS.FileDispositionInformation] = () => {
               if (parsed.length === 0) {
                  return "None";
               }
               return "FileDispositionInformation";
            };
            typeMap[RDPDR_TYPE.FILE_INFORMATION_CLASS.FileRenameInformation] = "FileRenameInformation";

            if (!typeMap.hasOwnProperty(parsed.fileInformationClass)) {
               return null;
            }
            const resultType = typeMap[parsed.fileInformationClass];
            if (typeof resultType === "function") {
               return resultType();
            }
            return resultType;
         }
      };
      let ioRequest;
      const onDone = (ioCompletion) => {
         this.logger.trace("<--ioResponse: " + JSON.stringify(ioCompletion));
         this.sendIoResponse(ioCompletion, ioRequest.parameters.unionType);
      };

      const onError = (errorCode) => {
         if (NT_STATUS.hasOwnProperty(errorCode)) {
            errorCode = NT_STATUS[errorCode];
         }
         const ErrorCodeName = Object.keys(NT_STATUS).find((key) => NT_STATUS[key] === errorCode);
         if (ErrorCodeName) {
            this.logger.debug("ErrorCodeName: " + ErrorCodeName);
         } else {
            // treat all failure as not implemented? TODO to use specified types
            this.logger.debug("error treat as not implemented" + errorCode);
            errorCode = NT_STATUS.STATUS_NOT_IMPLEMENTED;
         }
         this.logger.trace("<--ioResponse error: " + errorCode);
         this.sendIoResponseWithoutPayload(ioRequest, errorCode);
      };

      ioRequest = this.protocolHelper.parse(streamReader, "RDP_DR_DEVICE_IOREQUEST", param);
      try {
         this.processIoRequest(ioRequest).then(onDone).catch(onError);
      } catch (e) {
         this.logger.error("IO error" + e);
         onError(e);
      }
      return true;
   };

   private handleUserLoggedon = () => {
      this.logger.trace("==>handleUserLoggedon");
      const ret = true;
      if (!this.userLoggedOn) {
         this.userLoggedOn = true;
         if (
            this.serverVersion === TDSR_TYPE.VERSION.TSDR_VERSION_V1 ||
            (this.serverVersion !== TDSR_TYPE.VERSION.TSDR_VERSION_UNKNOWN && this.pPolicy)
         ) {
            this.onUserReady();
         }
      }
      return ret;
   };

   public handleTSDRFromServer = (stream) => {
      const streamReader = ProtocolUtil.getReader(stream.params[0], false);
      const rdpdrHeader = this.protocolHelper.parse(streamReader, "RDPDR_HEADER");
      let ret = true;
      if (rdpdrHeader.component === TDSR_TYPE.TSDR_COMPONENT_CAPS) {
         /*
          * For backward compatibilty and potential race condition,
          * onUserReady will only be called when userLoggedOn, version
          * and policy are all received.
          */
         if (rdpdrHeader.packetId === TDSR_TYPE.TSDR_PACKID_VERSION_EXCHANGE) {
            streamReader.seek(0);
            ret = this._handleVersionExchange(streamReader);
            if (!!this.userLoggedOn && (this.pPolicy || this.serverVersion === TDSR_TYPE.VERSION.TSDR_VERSION_V1)) {
               this.onUserReady();
            }
         } else if (rdpdrHeader.packetId === TDSR_TYPE.TSDR_PACKID_AGENT_POLICY) {
            ret = this._handleTsdrPolicy(streamReader);
            if (!!this.userLoggedOn && this.serverVersion !== TDSR_TYPE.VERSION.TSDR_VERSION_UNKNOWN) {
               this.onUserReady();
            }
         }
      } else if (rdpdrHeader.component !== RDPDR_TYPE.RDPDR_CTYP_CORE) {
         this.logger.error("Received packet with component != CORE component:" + rdpdrHeader.component + "\n");
         return false;
      }
      /* Handle message */
      switch (rdpdrHeader.packetId) {
         case RDPDR_TYPE.PAKID_CORE_SERVER_ANNOUNCE: //DR_CORE_SERVER_ANNOUNCE_REQ
            // exchange tsdr version
            streamReader.seek(0);
            this.handleServerAnnounce(streamReader);
            break;
         case RDPDR_TYPE.PAKID_CORE_SERVER_CAPABILITY: //DR_CORE_CAPABILITY_REQ
            // exchange capability
            ret = this.handleServerCapability(streamReader);
            break;
         case RDPDR_TYPE.PAKID_CORE_DEVICE_REPLY:
            ret = this.handleDeviceReply();
            break;
         case RDPDR_TYPE.PAKID_CORE_USER_LOGGEDON:
            ret = this.handleUserLoggedon();
            break;
         case RDPDR_TYPE.PAKID_CORE_DEVICE_IOREQUEST:
            ret = this.handleIoRequest(streamReader);
            break;
         default:
            ret = true;
      }
      return ret;
   };

   public onDisconnect = () => {
      this.fileSystem.releaseAll();
   };
}
