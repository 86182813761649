/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { ChangeDetectorRef, Component } from "@angular/core";
import { Logger } from "@html-core";
import { AbstractModalComponent } from "../../common/commondialog/abstract-modal.component";
import { LaunchItemsCtrlService } from "../launchitems/launch-item-ctrl.service";
import { AutoForwardPolicyService } from "./auto-forward-usb-policy.service";
import { AutoFowardUSBService, USBRaw } from "./auto-usb-foward.service";
import { imageAsset } from "../../common/image-asset/image-asset";

@Component({
   selector: "setting-auto-usb-dialog",
   templateUrl: "./setting-auto-usb-dialog.component.html",
   styles: [
      `
         /deep/ .setting-auto-usb-window .modal-content {
            width: 504px;
            height: 634px;
            margin-left: -25px;
         }
      `
   ]
})
export class SettingAutoUSBDialogComponent extends AbstractModalComponent {
   public devices = new Array<USBRaw>();
   public autoUSBDesktops = new Array<string>();
   public selectedDesktop = null;
   public autoConnectAllOnStart: boolean;
   public autoConnectAllOnInsert: boolean;
   public imageAsset = imageAsset;
   public desktopPlaceholder: string;

   private _selectedDesktopPolicy: AutoUsbPolicyPerDesktop;
   public forceSettingsToAllDesktops: boolean = false;
   private _onDeviceAddedListenser;
   private _onDeviceRemovedListenser;
   public currentUsbSettingMap = {};
   private _desktopMap = new Map<string, AutoUsbPolicyPerDesktop>();
   private logger = new Logger(Logger.AUTO_USB);

   constructor(
      private autoFowardUSBService: AutoFowardUSBService,
      private policyService: AutoForwardPolicyService,
      private launchItemsCtrlService: LaunchItemsCtrlService,
      private changeDetectorRef: ChangeDetectorRef
   ) {
      super();
      this._init();
   }

   ngOnDestroy() {
      chrome.usb.onDeviceAdded.removeListener(this._updateUIOnDeviceInserted);
      chrome.usb.onDeviceRemoved.removeListener(this._updateUIOnDeviceRemoved);
   }

   private _init = async () => {
      this.devices = await this.getDevices();
      this._updateDesktops();
      this._desktopMap = this.autoFowardUSBService.getPolicy();
      this._checkGlobalPolicy();
      this.desktopPlaceholder = this.forceSettingsToAllDesktops ? "ALL_DESKTOPS_M" : "SELECT_DESKTOP_T";

      this._onDeviceAddedListenser = chrome.usb.onDeviceAdded.addListener(this._updateUIOnDeviceInserted);
      this._onDeviceRemovedListenser = chrome.usb.onDeviceRemoved.addListener(this._updateUIOnDeviceRemoved);
   };

   private _updateUIOnDeviceInserted = (device) => {
      const info = {} as USBRaw;
      info.productId = device.productId;
      info.vendorId = device.vendorId;
      info.productName = device.productName;
      info.autoConnectOnInsert = false;
      info.autoConnectOnStart = false;
      this.devices.push(info);
      this.logger.info(
         "New device is inserted, " + device.productName + ":" + device.vendorId + ":" + device.productId
      );
      if (this.selectedDesktop || this.forceSettingsToAllDesktops) {
         this._updateUI();
      }
      this.changeDetectorRef.detectChanges();
   };

   private _updateUIOnDeviceRemoved = (device) => {
      for (let i = 0; i < this.devices.length; i++) {
         this.policyService.isSameDevice(device, this.devices[i]);
         this.devices.splice(i, 1);
         break;
      }
      this.logger.info("A device is removed, " + device.productName + ":" + device.vendorId + ":" + device.productId);
      if (this.selectedDesktop) {
         this._updateUI();
      }
      this.changeDetectorRef.detectChanges();
   };

   public getDevices = (): Promise<Array<USBRaw>> => {
      return new Promise((resolve, reject) => {
         const res = new Array<USBRaw>();
         this.autoFowardUSBService
            .getDevicesFromAPI()
            .then((devices) => {
               devices.forEach((device) => {
                  const info = {} as USBRaw;
                  info.productId = String(device.raw.productId);
                  info.vendorId = String(device.raw.vendorId);
                  info.productName = device.raw.productName;
                  info.autoConnectOnInsert = false;
                  info.autoConnectOnStart = false;
                  res.push(info);
               });
               resolve(res);
            })
            .catch((e) => {
               reject(e);
            });
      });
   };

   private _updateDesktops = (): void => {
      const desktopsInfo = this.launchItemsCtrlService.getDesktops();
      desktopsInfo.forEach((desktop) => {
         this.autoUSBDesktops.push(desktop.name);
      });
   };

   public selectDesktop = async (desktopName: string) => {
      this.selectedDesktop = desktopName;
      this._selectedDesktopPolicy = this.getFromLocal(desktopName);
      this.logger.info("A desktop has been selected: " + desktopName);
      this._updateUI();
   };

   private getFromLocal = (desktopName) => {
      let res: AutoUsbPolicyPerDesktop;
      if (this._desktopMap.has(desktopName)) {
         res = this._desktopMap.get(desktopName);
      } else {
         res = {
            autoConnectAllOnStart: false,
            autoConnectAllOnInsert: false,
            devicePolicy: new Array<AutoUsbPolicyPerDevice>()
         };
      }
      this.logger.debug("Policy of selcted desktop is: " + JSON.stringify(res));
      return res;
   };

   private _updateUI = () => {
      const currentDesktopPolicy = this._selectedDesktopPolicy;
      const devicesPolicy = currentDesktopPolicy.devicePolicy;
      this.logger.info("Deploy policy to UI");

      for (let i = 0; i < this.devices.length; i++) {
         let found = false;
         for (let j = 0; j < devicesPolicy.length; j++) {
            const isSame = this.policyService.isSameDevice(this.devices[i], devicesPolicy[j]);
            if (isSame) {
               this.devices[i].autoConnectOnInsert = devicesPolicy[j].autoConnectOnInsert;
               this.devices[i].autoConnectOnStart = devicesPolicy[j].autoConnectOnStart;
               found = true;
            }
         }
         if (!found) {
            this.devices[i].autoConnectOnInsert = false;
            this.devices[i].autoConnectOnStart = false;
         }
      }
      this.autoConnectAllOnInsert = currentDesktopPolicy.autoConnectAllOnInsert;
      this.autoConnectAllOnStart = currentDesktopPolicy.autoConnectAllOnStart;
      if (this.autoConnectAllOnInsert) {
         this._updateOnConnectOptionForAllUsb("onInsertAll", this.autoConnectAllOnInsert);
      }
      if (this.autoConnectAllOnStart) {
         this._updateOnConnectOptionForAllUsb("onStartAll", this.autoConnectAllOnStart);
      }
   };

   public OkClicked = () => {
      this._checkApplyForAllDesktopOption();
      this.policyService.updatePolicyMap(this._desktopMap);
      this.policyService.updatePolicyToLocalStorage();
      this.autoFowardUSBService.updatePolicy();
      this.logger.debug("Settings are saved: " + JSON.stringify(this._desktopMap));
      this.closeModal();
   };

   public onStartForAll = (event) => {
      this.logger.debug("onStartForAll is clicked.");
      this._updateOnConnectOptionForAllUsb("onStartAll", event);
   };

   public onInsertForAll = (event) => {
      this.logger.debug("onInsertForAll is clicked.");
      this._updateOnConnectOptionForAllUsb("onInsertAll", event);
   };

   private _updateOnConnectOptionForAllUsb = (type: string, value: boolean) => {
      if (this.devices.length === 0) {
         this._updateDesktopMap(type, value);
         return;
      }
      this.devices.forEach((device) => {
         if (type === "onStartAll") {
            device.autoConnectOnStart = value;
         } else if (type === "onInsertAll") {
            device.autoConnectOnInsert = value;
         }
         this._updateDesktopMap(type, value, device);
      });
   };

   public onStartClick = (isEnabled, device) => {
      this.logger.debug("onStart is clicked: " + device.vendorId + ":" + device.productId);
      this._updateDesktopMap("onStart", isEnabled, device);
      if (!isEnabled) {
         this.autoConnectAllOnStart = false;
         const res = this.getFromLocal(this.selectedDesktop);
         res.autoConnectAllOnStart = false;
         this._desktopMap.set(this.selectedDesktop, res);
      }
   };

   public onInsertClick = (isEnabled, device) => {
      this.logger.debug("onInsert is clicked: " + device.vendorId + ":" + device.productId);
      this._updateDesktopMap("onInsert", isEnabled, device);
      if (!isEnabled) {
         this.autoConnectAllOnInsert = false;
         const res = this.getFromLocal(this.selectedDesktop);
         res.autoConnectAllOnInsert = false;
         this._desktopMap.set(this.selectedDesktop, res);
      }
   };

   private _updateDesktopMap = (type, value, device?) => {
      const res = this.getFromLocal(this.selectedDesktop);
      let found = false;

      if (type === "onStartAll") {
         res.autoConnectAllOnStart = value;
         for (let i = 0; i < res.devicePolicy.length; i++) {
            res.devicePolicy[i].autoConnectOnStart = value;
         }
      } else if (type === "onInsertAll") {
         res.autoConnectAllOnInsert = value;
         for (let i = 0; i < res.devicePolicy.length; i++) {
            res.devicePolicy[i].autoConnectOnInsert = value;
         }
      }
      if (device) {
         for (let i = 0; i < res.devicePolicy.length; i++) {
            const isSame = this.policyService.isSameDevice(res.devicePolicy[i], device);
            if (isSame) {
               if (type === "onStartAll") {
                  res.devicePolicy[i].autoConnectOnStart = value;
               }
               if (type === "onInsertAll") {
                  res.devicePolicy[i].autoConnectOnInsert = value;
               }

               if (type === "onInsert") {
                  res.devicePolicy[i].autoConnectOnInsert = value;
               } else if (type === "onStart") {
                  res.devicePolicy[i].autoConnectOnStart = value;
               }
               found = true;
               break;
            }
         }
         if (!found) {
            res.devicePolicy.push({
               vendorId: device.vendorId,
               productId: device.productId,
               productName: device.productName,
               autoConnectOnInsert: type === "onInsert" ? value : false,
               autoConnectOnStart: type === "onStart" ? value : false
            });
         }
      }
      this._desktopMap.set(this.selectedDesktop, res);
      return res;
   };

   public forceToAllOnChange = (event) => {
      const checkBox = event.target.parentNode.getElementsByTagName("input")[0];
      if (event.target.tagName === "LABEL") {
         checkBox.checked = !checkBox.checked;
      }
      this.forceSettingsToAllDesktops = checkBox.checked;
      this.logger.info("Apply to all desktop is clicked");
      if (this.forceSettingsToAllDesktops) {
         const globalPolicy = JSON.parse(JSON.stringify(this.getFromLocal(this.selectedDesktop)));
         this._desktopMap.set("HorizonAutoUsbAll", globalPolicy);
         this.selectedDesktop = "HorizonAutoUsbAll";
      } else {
         this._desktopMap.set("HorizonAutoUsbAll", null);
         this.selectedDesktop = null;
      }
      this._updateDesktopPlaceholder();
   };

   private _updateDesktopPlaceholder = (): void => {
      this.desktopPlaceholder = this.forceSettingsToAllDesktops ? "ALL_DESKTOPS_M" : "SELECT_DESKTOP_T";
      if (this.forceSettingsToAllDesktops) {
         const desktopOption: any = document.getElementById("auto-usb-desktop-selector");
         desktopOption.value = "";
      } else {
         this.autoConnectAllOnInsert = false;
         this.autoConnectAllOnStart = false;
      }
      this.changeDetectorRef.detectChanges();
   };

   public enableApply = (): boolean => {
      return this.devices.length > 0 && !!this.selectedDesktop;
   };

   private _checkApplyForAllDesktopOption = () => {
      let globalPolicy = {} as AutoUsbPolicyPerDesktop;
      if (this.forceSettingsToAllDesktops) {
         this.logger.info("apply for all desktop is enabled");
         globalPolicy = this.getFromLocal(this.selectedDesktop);
         this.logger.debug("Global policy is: " + JSON.stringify(globalPolicy));
      } else {
         this.logger.info("apply for all desktop is disabled");
      }
      this._desktopMap.set("HorizonAutoUsbAll", globalPolicy);
   };

   private _checkGlobalPolicy = () => {
      if (
         this._desktopMap.has("HorizonAutoUsbAll") &&
         this._desktopMap.get("HorizonAutoUsbAll") &&
         Object.keys(this._desktopMap.get("HorizonAutoUsbAll")).length > 0
      ) {
         this.selectedDesktop = "HorizonAutoUsbAll";
         const globalPolicy: AutoUsbPolicyPerDesktop = this._desktopMap.get("HorizonAutoUsbAll");
         this.logger.debug("There is HorizonAutoUsbAll policy");
         if (globalPolicy && Object.keys(globalPolicy).length > 0) {
            this.logger.info("There is a valid global policy");
            this.forceSettingsToAllDesktops = true;
            this._selectedDesktopPolicy = this._desktopMap.get("HorizonAutoUsbAll");
            this._updateUI();
         } else {
            this.logger.info("There is HorizonAutoUsbAll policy, but value is empty");
         }
      } else {
         this.logger.info("There is no HorizonAutoUsbAll policy");
      }
   };

   public isGrayOut = () => {
      return !this.selectedDesktop && !this.forceSettingsToAllDesktops;
   };
}
