/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input } from "@angular/core";
import { AbstractModalComponent } from "../abstract-modal.component";

@Component({
   selector: "confirm-dialog",
   templateUrl: "./confirm-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class ConfirmDialogComponent extends AbstractModalComponent {
   @Input() public title;
   @Input() public message;
   @Input() public showWarning;
   @Input() public isTransferringFile;
   @Input() public isLogOffDesktop;
   //NG-TODO: add function to verify if that session is printing files in later patch
   @Input() public isPrintingFile;
   @Input() public buttonLabelConfirm?;
   @Input() public buttonLabelCancel?;
}
