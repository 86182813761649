/**
 * *****************************************************
 * Copyright 2021 VMware, Inc.  All rights reserved.
 * ***
 *
 * @format
 */

import { Component, TemplateRef, OnInit } from "@angular/core";
import { NgbToastService } from "../service/ngb-toast.service";

@Component({
   selector: "app-toasts",
   templateUrl: "./toast-container.component.html",
   host: {
      class: "toast-container position-fixed top-0 end-0 p-3",
      style: "z-index: 1200"
   }
})
export class ToastComponent implements OnInit {
   constructor(public toastService: NgbToastService) {}
   multimonToastTimer;
   dismissCallback = null;

   isTemplate(toast) {
      return toast.textOrTpl instanceof TemplateRef;
   }

   ngOnInit(): void {}

   public executeCallbacks = (calback, toast) => {
      if (calback) {
         calback();
         calback = null;
      }
      this.toastService.remove(toast);
   };
   public toastHidden = (toast, dismissCallback) => {
      if (dismissCallback) {
         dismissCallback();
         dismissCallback = null;
      }
      this.toastService.remove(toast);
   };
}
