/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export enum LoginTypes {
   "Waiting" = "Waiting",
   "SecurIDPasscode" = "SecurIDPasscode",
   "WindowsPassword" = "WindowsPassword",
   "Disclaimer" = "Disclaimer",
   "Unauthenticated" = "Unauthenticated",
   "WindowsPasswordExpired" = "WindowsPasswordExpired",
   "SecurIDNextTokenCode" = "SecurIDNextTokenCode",
   "SecurIDPinChange" = "SecurIDPinChange",
   "SecurIDWait" = "SecurIDWait",
   "SAML" = "SAML",
   "UNKONWN" = "UNKONWN"
}

export type LoginTypesKey = keyof typeof LoginTypes;

// move definiation from component here in later patch
export type LoginContentData = any;

export type LoginData = {
   name: LoginTypesKey;
   content?: LoginContentData;
};
