/**
 * ******************************************************
 * Copyright (C) 2016-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * wmks-base-service.js -- wmksBaseService
 *
 * This hold the getEventPosition function, which currently not support retina,
 * later would add more logic and function in.
 */
import { Injectable } from "@angular/core";
import { CoordinatePoint, NormalizationService } from "./normalization-service";
import { clientUtil } from "@html-core";
import { DisplayCheckService } from "../desktop/common/display-check.service";

const MOUSE_BMASK = {
   MOUSE_BUTTON_RIGHT: 2,
   MOUSE_BUTTON_MIDDLE: 4
};

@Injectable()
export class WmksBaseService {
   private defaultPosition: CoordinatePoint;
   private defaultDisplay: DisplayBaseInfo;

   constructor(
      private normalizationService: NormalizationService,
      private displayCheckService: DisplayCheckService
   ) {
      this.defaultPosition = new CoordinatePoint();
      this.defaultPosition.x = 0;
      this.defaultPosition.y = 0;
      this.defaultDisplay = {} as DisplayBaseInfo;
      this.defaultDisplay.x = 0;
      this.defaultDisplay.y = 0;
      this.defaultDisplay.width = 0;
      this.defaultDisplay.height = 0;
      this.defaultDisplay.devicePixelRatio = window.devicePixelRatio || 1;
   }

   public getEventPosition = (event: MouseEvent, offset: CoordinatePoint): CoordinatePoint => {
      let docX: number = 0;
      let docY: number = 0;
      let normalizedPosition: CoordinatePoint;

      if (!offset || !event) {
         return this.defaultPosition;
      }
      if (event.pageX || event.pageY) {
         docX = event.pageX;
         docY = event.pageY;
      } else if (event.clientX || event.clientY) {
         docX = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
         docY = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      } else {
         return this.defaultPosition;
      }
      normalizedPosition = this.normalizationService.normalize({
         x: docX + screenX,
         y: docY + screenY
      });
      normalizedPosition.x += offset.x;
      normalizedPosition.y += offset.y;
      return normalizedPosition;
   };

   /**
    * Add this workaround check to avoid a bug happens by chance of
    * wrong screenX, where the offset is added with a extra number,
    * and the availLeft is correct when this bug happens without the
    * positive extra offset. so when screen.availLeft exist, use min
    * of both should fix it.
    *
    * On the spec, the screenX should never be smaller than the
    * screen.availLeft, please refer the definition in:
    * https://drafts.csswg.org/cssom-view/#dom-window-screenx
    * as "The screenX attribute must return the x-coordinate of the position
    * where the event occurred relative to the origin of the Web-exposed
    * screen area."
    * And after entering fullscreen, they should be equal to each other, if
    * the browser don't count the windows/Mac OS bar in.
    */
   public getScreenSetting = (): DisplayBaseInfo => {
      let offsetX, offsetY;

      if (!screen || screenX === undefined || screenY === undefined) {
         return this.defaultDisplay;
      }
      if (screen.availLeft !== undefined) {
         offsetX = Math.min(screenX, screen.availLeft);
      } else {
         offsetX = screenX;
      }
      if (screen.availTop !== undefined) {
         offsetY = Math.min(screenY, screen.availTop);
      } else {
         offsetY = screenY;
      }

      return {
         x: offsetX,
         y: offsetY,
         width: clientUtil.getScreenWidth(),
         height: this.displayCheckService.getScreenHeight(),
         devicePixelRatio: window.devicePixelRatio || 1
      } as DisplayBaseInfo;
   };

   public shouldPreventDefault = (event: any) => {
      // Only Chrome support multi monitor
      // Chrome support new keyboard
      // Here always return true. Bug 2220131
      //
      // For bug 2356163, based on fix for 2220131, return false
      // if key is Enter and element is dialog Button
      event = event.originalEvent || event;
      if (
         event &&
         (event.charCode === 13 || event.code === "Enter") &&
         event.path &&
         event.path[0] &&
         event.path[0].classList
      ) {
         for (let i = 0; i < event.path[0].classList.length; i++) {
            if (event.path[0].classList[i] === "modal-button-base") {
               return false;
            }
         }
      }
      return true;
   };

   /**
    * MouseEvent use button property to distinguish between different buttons, like
    * left button : 0, middle button : 1, right button: 2, backward button : 3, forward: 4...
    *
    * For VMWPointerEvent : https://opengrok.eng.vmware.com/source/xref/main.perforce.1666/bora/public/vnc.h#982
    * We just need use "1 << event.button" to convert
    *
    * But for VMWPointerEvent2 : https://opengrok.eng.vmware.com/source/xref/main.perforce.1666/bora/public/vnc.h#1039
    * The eventFlag of middle and right is opposite
    *    0x02 HID_MOUSE_BUTTON_RIGHT
    *    0x04 HID_MOUSE_BUTTON_MIDDLE
    *
    * So we need judge these two buttons separately.
    *
    */
   public getMouseBmask = (event: MouseEvent) => {
      switch (event.button) {
         case 1:
            return MOUSE_BMASK.MOUSE_BUTTON_MIDDLE;
         case 2:
            return MOUSE_BMASK.MOUSE_BUTTON_RIGHT;
         default:
            return 1 << event.button;
      }
   };
}
