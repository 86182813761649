
<form [formGroup]="unauthenticatedForm" id="unauthenticatedForm" name="unauthenticatedForm" (ngSubmit)="onSubmit($event)">
    <div class="ui-panel-content ">
      <div [hidden]="blankOutContent">
         <div class="alert alert-danger alert-unauthenticated">
            {{error}}
         </div>
         <div class="form-horizontal">
            <div id="unauthenticatedUsername" name="unauthenticatedUsername" role="combobox" class="ui-selectmenu-button ui-button ui-widget anonymous-select ui-selectmenu-button-open ui-corner-top"
               [autoFocus] (click)="toggleAnonymousList()" tabindex="0">
               <span class="ui-selectmenu-icon ui-icon"></span>
               <span class="ui-selectmenu-text">{{ selectedAnonyUsernameDisplay }}</span>
               <span class="ui-selectmenu-menu ui-front" [ngClass]="showAnonymousList ? 'ui-selectmenu-open ui-anonymous-list' : ''">
                  <ul id="unauthenticatedUsername-menu" role="listbox" class="ui-menu ui-corner-bottom ui-widget ui-widget-content overflow">
                     <li class="ui-menu-item" [formControl]="unauthenticatedUsernameControl" ngDefaultControl *ngFor="let unauthenticatedUsername of unauthenticatedUsernames" [ngValue]="unauthenticatedUsername.value" tabindex="0">
                        <div role="option" class="ui-menu-item-wrapper" (click)="selectAnonymousUser(unauthenticatedUsername)">{{ getDisplayName(unauthenticatedUsername) }}</div>
                     </li>
                  </ul>
               </span>
            </div>
         </div>
      </div>
      <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
    </div>
    <div class="ui-panel-footer">
      <button id="unauthenticatedLoginButton" class="btn btn-primary" [ngClass]="{'btnPrimaryWaiting':blankOutContent}" type="submit" [disabled]="!unauthenticatedForm.valid" [hidden]="blankOutContent">{{ 'dialog_btn_signin' | i18nv2}}</button>
      <button id="unauthenticatedCancelLoginBtn" class="btn btn-default" [ngClass]="{'btnDefaultWaiting':blankOutContent}" (click)="cancel()">{{'CANCEL' | i18nv2}}</button>
    </div>
</form>