/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { getBrowserCultureLang } from "@html-core";
import { HorizonClientInfo } from "../../../shared/common/service/client-info.service";
/* cSpell:disable */
export enum HostOS {
   WIN32 = "WIN32",
   WIN64 = "WIN64",
   WINMOBILE = "WINMOBILE",
   LINUX64 = "LINUX64",
   LINUX32 = "LINUX32",
   ANDROID = "ANDROID",
   IOS = "IOS",
   CHROMEOS = "CHROMEOS",
   MAC = "MAC",
   UNKNOWN = "UNKNOWN"
}

export function getHostOS() {
   const userAgent = navigator.userAgent.toLowerCase();
   let os: HostOS = HostOS.UNKNOWN;

   if (userAgent.indexOf("windows") > -1) {
      if (userAgent.indexOf("wow64") > -1 || userAgent.indexOf("win64") > -1) {
         os = HostOS.WIN64;
      } else if (userAgent.indexOf("iemobile") > -1 || userAgent.indexOf("windows phone") > -1) {
         os = HostOS.WINMOBILE;
      } else {
         os = HostOS.WIN32;
      }
   } else if (userAgent.indexOf("android") > -1) {
      os = HostOS.ANDROID;
   } else if (userAgent.indexOf("linux") > -1) {
      if (userAgent.indexOf("x86_64") > -1) {
         os = HostOS.LINUX64;
      } else {
         os = HostOS.LINUX32;
      }
   } else if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1) {
      os = HostOS.IOS;
   } else if (userAgent.indexOf("cros") > -1) {
      os = HostOS.CHROMEOS;
   } else if (userAgent.indexOf("macos") > -1 || userAgent.indexOf("macintosh") > -1) {
      os = HostOS.MAC;
   }
   return os;
}

let clientInfo: HorizonClientInfo = null as HorizonClientInfo;

export function getClientInfo(): HorizonClientInfo {
   if (!clientInfo) {
      clientInfo = {} as HorizonClientInfo;
      clientInfo.acceptLanguage = getBrowserCultureLang();
      clientInfo.clientVersion = __VDM_WEB_CLIENT_VERSION__ || "8.12.0";
      clientInfo.os = getHostOS().toString().toLowerCase();
   }
   return clientInfo;
}
