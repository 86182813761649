/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * The Device filtering library, which keep binary compatible with
 *
 * https://opengrok.eng.vmware.com/source/xref/main.perforce.1666/bora/apps/viewusb/framework/usb/lib/devFilter/
 *
 * @format
 */

import { StringUtils } from "@html-core";
import Logger from "../../../core/libs/logger";

// Dictates precedence PATH - down to remote user config
export enum FilterType {
   FLT_DEVPATH_EXCLUDE = 0,
   FLT_DEVPATH_INCLUDE,
   FLT_DEVID_EXCLUDE, // SUPPORT
   FLT_DEVID_INCLUDE, // SUPPORT
   FLT_DEVFAMILY_EXCLUDE, // SUPPORT
   FLT_DEVFAMILY_INCLUDE, // SUPPORT
   FLT_DEVALL_EXCLUDE, //SUPPORT
   FLT_DEVINTFNUM_EXCLUDE,
   FLT_DEVINTFGP_EXCLUDE,
   FLT_SPLT_DEVID_EXCLUDE,
   FLT_USEHID,
   FLT_USEHIDBOOT,
   FLT_USEKEYBOARDMOUSE,
   FLT_USESMARTCARD,
   FLT_USEAUDIOOUT,
   FLT_USEAUDIOIN,
   FLT_USEVIDEO,
   FLT_USENIC,
   FLT_BLOCKDEVSPLIT,
   FLT_ALLOWAUTODEVSPLIT,
   FLT_BLOCKBOOTDEVFILTER,
   FLT_BLOCKREMOTECONFIG,
   FLT_DEVID_FILTER,
   FLT_DISABLEBUFFERWITHOUTAUDIOLIST_FILTER,
   FLT_DEVID_HIDOPTINCLUDE,
   FLT_AUTO_DEVID_EXCLUDE,
   FLT_AUTO_DEVFAMILY_EXCLUDE,
   FLT_DEVID_V2_EXCLUDE, // SUPPORT
   FLT_DEVID_V2_INCLUDE, // SUPPORT
   FLT_ERROR,
   FLT_DEVID_EXCLUDE_LEGACY,
   FLT_DEVID_INCLUDE_LEGACY,
   FLT_DEVFAMILY_EXCLUDE_LEGACY,
   FLT_USESMARTCARD_LEGACY
}

export enum FilterSequencing {
   FLTSEQ_MERGE = 0,
   FLTSEQ_OVERRIDE,
   FLTSEQ_DEFAULT,
   FLTSEQ_NOTSET
}

export enum FilterResultType {
   OVERWRITE,
   MERGE
}

export interface FilterResult {
   type: FilterResultType;
   disabled: boolean;
}

// Typescript version of FilterDetails in devFilter/devFilter.h
class FilterDetails {
   public name: string;
   public value: string;

   constructor(name: string, value: string) {
      this.name = name;
      this.value = value;
   }
}

// Typescript version of DevFilter in devFilter/devFilter.h
class DevFilter {
   public mType: FilterType;
   public mDesc: Array<FilterDetails>;

   constructor() {
      this.mDesc = new Array<FilterDetails>();
   }

   public checkExcludeAll(): boolean {
      if (this.mType !== FilterType.FLT_DEVALL_EXCLUDE) {
         return false;
      }
      if (this.mDesc.length !== 1) {
         return false;
      }
      return this.mDesc[0].value === "true";
   }

   public checkDeviceId(vid: string, pid: string): boolean {
      if (this.mType !== FilterType.FLT_DEVID_INCLUDE && this.mType !== FilterType.FLT_DEVID_EXCLUDE) {
         return false;
      }
      if (this.mDesc.length !== 2) {
         return false;
      }

      if ("vid" === this.mDesc[0].name && vid === this.mDesc[0].value) {
         if ("pid" === this.mDesc[1].name && (pid === this.mDesc[1].value || "****" === this.mDesc[1].value)) {
            return true;
         }
      } else if ("vid" === this.mDesc[0].name && "****" === this.mDesc[0].value) {
         if ("pid" === this.mDesc[1].name && (pid === this.mDesc[1].value || "****" === this.mDesc[1].value)) {
            return true;
         }
      }
      return false;
   }

   public checkDeviceRel(vid: string, pid: string, rel: string): boolean {
      if (this.mType !== FilterType.FLT_DEVID_V2_INCLUDE && this.mType !== FilterType.FLT_DEVID_V2_EXCLUDE) {
         return false;
      }
      if (this.mDesc.length !== 3) {
         return false;
      }
      if (
         "vid" === this.mDesc[0].name &&
         vid === this.mDesc[0].value &&
         "pid" === this.mDesc[1].name &&
         pid === this.mDesc[1].value &&
         "rel" === this.mDesc[2].name &&
         rel === this.mDesc[2].value
      ) {
         return true;
      }
      return false;
   }

   public checkDeviceFamily(family: string): boolean {
      if (this.mType !== FilterType.FLT_DEVFAMILY_EXCLUDE && this.mType !== FilterType.FLT_DEVFAMILY_INCLUDE) {
         return false;
      }

      if (this.mDesc.length === 0) {
         return false;
      }

      if (this.mDesc[0].value === family) {
         return true;
      }
      return false;
   }

   private bin2string(array: any[]): string {
      let result = "";
      for (let i = 0; i < array.length; ++i) {
         result += String.fromCharCode(array[i]);
      }
      return result;
   }

   public unMarshall(packet) {
      this.mType = packet.readUint32();
      const noOfDesc = packet.readUint32();
      const noOfSplitRules = packet.readUint32();

      for (let i = 0; i < noOfDesc; i++) {
         const name: any[] = [];
         const value: any[] = [];
         let nameFound = false;
         while (packet.bytesRemaining() > 0) {
            const byte = packet.readUint8();
            if (nameFound === false) {
               if (byte === 58) {
                  //":"
                  nameFound = true;
               } else {
                  name.push(byte);
               }
            } else {
               if (byte === 44) {
                  //","
                  const filterName: string = this.bin2string(name);
                  const filterValue = this.bin2string(value);
                  this.mDesc.push(new FilterDetails(filterName, filterValue));
                  break;
               } else {
                  value.push(byte);
               }
            }
         }
      }
      //unMarshall for Split Rule
      for (let i = 0; i < noOfSplitRules; i++) {
         const split = new DevSplitRule();
         const result: any = split.unMarshall(packet);
         if (result.excludedInterfaces.length > 0) {
            result.excludedInterfaces.forEach((interfaceValue) => {
               this.mDesc.push(new FilterDetails("exintf", interfaceValue));
            });
         }
      }
      const separator = packet.readUint8();
      if (separator !== 59) {
         // ';'
         Logger.info("parse error in usb filter", Logger.USB);
      }
   }
}

class DevSplitRule {
   public mIndex: string;
   public mExclFamilies: Array<string> = [];
   public mExclIntfs: Array<string> = [];

   private _bin2string(array: any[]): string {
      let result = "";
      for (let i = 0; i < array.length; ++i) {
         result += String.fromCharCode(array[i]);
      }
      return result;
   }

   public unMarshall(packet) {
      const cpyLen = packet.readUint32();
      packet.readArray(cpyLen);
      const exclFamLen = packet.readUint32();
      const exclIntfLen = packet.readUint32();
      for (let i = 0; i < exclFamLen; i++) {
         const dataLen = packet.readUint32();
         packet.readArray(dataLen);
      }
      const excludedInterfaces = [];
      for (let i = 0; i < exclIntfLen; i++) {
         const dataLen = packet.readUint32();
         const packetData = packet.readArray(dataLen);
         if (packetData) {
            excludedInterfaces.push(this._bin2string(packetData));
         }
      }
      return {
         excludedInterfaces: excludedInterfaces
      };
   }
}

export class UsbFilter {
   private mListOfFilterSeq: Map<FilterType, FilterSequencing>;
   private mListOfFilters: Map<FilterType, DevFilter[]>;

   constructor() {
      this.mListOfFilterSeq = null;
      this.mListOfFilters = null;
   }

   public UnMarshallFilters(base64Filter: string) {
      if (base64Filter == null) {
         return;
      }

      this.mListOfFilterSeq = new Map<FilterType, FilterSequencing>();
      this.mListOfFilters = new Map<FilterType, DevFilter[]>();

      const packet = WMKS.Packet.createNewPacketLE();
      const stringFilter = atob(base64Filter);
      const byteFilter = StringUtils.stringToUint8Array(stringFilter, true);
      packet.writeArray(byteFilter);
      const filterSeqCount = packet.readUint32();
      for (let i = 0; i < filterSeqCount; i++) {
         const filterType: FilterType = packet.readUint32();
         const filterSeq: FilterSequencing = packet.readUint32();
         this.mListOfFilterSeq.set(filterType, filterSeq);
      }
      const filterCount = packet.readUint32();
      for (let i = 0; i < filterCount; i++) {
         const filter: DevFilter = new DevFilter();
         filter.unMarshall(packet);
         let filters: DevFilter[] = this.mListOfFilters.get(filter.mType);
         if (!filters) {
            filters = [] as DevFilter[];
         }
         filters.push(filter);
         this.mListOfFilters.set(filter.mType, filters);
      }
   }

   public getFilters = () => {
      return this.mListOfFilters;
   };

   private combineRules = (seq: FilterSequencing): FilterResultType => {
      if (seq === FilterSequencing.FLTSEQ_OVERRIDE) {
         return FilterResultType.OVERWRITE;
      }
      return FilterResultType.MERGE;
   };

   public checkDeviceId(vid: string, pid: string, filters: DevFilter[]) {
      for (const filter of filters) {
         if (filter.checkDeviceId(vid, pid)) {
            return true;
         }
      }
      return false;
   }

   public checkDeviceRel(vid: string, pid: string, rel: string, filters: DevFilter[]) {
      for (const filter of filters) {
         if (filter.checkDeviceRel(vid, pid, rel)) {
            return true;
         }
      }
      return false;
   }

   public checkDeviceFamily(family: any, filters: DevFilter[]) {
      for (const filter of filters) {
         if (filter.checkDeviceFamily(family)) {
            return true;
         }
      }
      return false;
   }

   public checkExcludeAll(filters: DevFilter[]): boolean {
      for (const filter of filters) {
         if (filter.checkExcludeAll()) {
            return true;
         }
      }
      return false;
   }

   public getDeviceExcludedInterfaces = (vid: number, pid: number): Array<number> => {
      const interfaces = new Array<number>();
      if (this.mListOfFilters && this.mListOfFilters.has(FilterType.FLT_USEHID)) {
         const filters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_USEHID);
         for (const filter of filters) {
            const rules = filter.mDesc;
            for (let i = 0; i < rules.length; i++) {
               const ruleName = rules[i].name;
               const ruleValue = parseInt(rules[i].value, 16);
               if ((ruleName === "vid" && ruleValue !== vid) || (ruleName === "pid" && ruleValue !== pid)) {
                  break;
               }
               if (ruleName == "exintf" && interfaces.indexOf(ruleValue) === -1) {
                  interfaces.push(ruleValue);
               }
            }
            Logger.info("Split rule of device has been found: " + vid + ":" + pid);
            Logger.info("Split device - excluded interface: " + interfaces);
         }
      }
      return interfaces;
   };

   public isSplitRuleDisabled = (vid: number, pid: number): boolean => {
      if (this.mListOfFilters && this.mListOfFilters.has(FilterType.FLT_SPLT_DEVID_EXCLUDE)) {
         const filters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_SPLT_DEVID_EXCLUDE);
         for (const filter of filters) {
            const rules = filter.mDesc;
            let vidInRule: number, pidInRule: number;
            for (let i = 0; i < rules.length; i++) {
               const ruleName = rules[i].name;
               if (ruleName === "vid") {
                  vidInRule = parseInt(rules[i].value, 16);
               } else if (ruleName === "pid") {
                  pidInRule = parseInt(rules[i].value, 16);
               }
            }
            if (vid === vidInRule && pid === pidInRule) {
               return true;
            }
         }
      }
      return false;
   };

   // Refer https://docs.vmware.com/en/VMware-Horizon-7/7.13/horizon-remote-desktop-features/GUID-0BA5D4E1-B71C-4E24-BEFA-B6381D0E0BEF.html
   public checkDeviceFiltered = (vid: string, pid: string, rel: string, family: string): FilterResult => {
      const result = {
         type: FilterResultType.MERGE,
         disabled: false
      } as FilterResult;

      if (this.mListOfFilters === null || this.mListOfFilterSeq === null) {
         return result;
      }

      // Horizon Client evaluates the filter policy settings in order of precedence,
      // taking into account the Horizon Client settings and the Horizon Agent settings
      // together with the modifier values that you apply to the Horizon Agent settings.
      // The following list shows the order of precedence, with item 1 having the highest precedence.

      //    Exclude Path (NOT SUPPORTED)
      //    Include Path (NOT SUPPORTED)
      //    Exclude Vid/Pid/Rel Device
      //    Include Vid/Pid Device
      //    Include Device Family
      //    Include Vid/Pid/Rel Device
      //    Exclude Vid/Pid Device
      //    Exclude Device Family
      //    Combined effective Exclude All Devices policy evaluated to exclude or include all USB devices

      // [1] All Devices policy
      if (this.mListOfFilters.has(FilterType.FLT_DEVALL_EXCLUDE)) {
         const filters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_DEVALL_EXCLUDE);
         result.disabled = this.checkExcludeAll(filters);
         result.type = this.combineRules(this.mListOfFilterSeq.get(FilterType.FLT_DEVALL_EXCLUDE));
         Logger.debug("FilterType.FLT_DEVALL_EXCLUDE rules = " + Logger.stringify(filters));
         Logger.info("FilterType.FLT_DEVALL_EXCLUDE result = " + result.disabled);
      }

      // [2] Exclude Device Family
      if (this.mListOfFilters.has(FilterType.FLT_DEVFAMILY_EXCLUDE)) {
         const filters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_DEVFAMILY_EXCLUDE);
         if (this.checkDeviceFamily(family, filters)) {
            result.disabled = true;
            result.type = this.combineRules(this.mListOfFilterSeq.get(FilterType.FLT_DEVFAMILY_EXCLUDE));
         }
         Logger.debug("FilterType.FLT_DEVFAMILY_EXCLUDE rules = " + Logger.stringify(filters));
         Logger.info("FilterType.FLT_DEVFAMILY_EXCLUDE result = " + result.disabled);
      }

      // [3] Exclude Vid/Pid Device
      if (this.mListOfFilters.has(FilterType.FLT_DEVID_EXCLUDE)) {
         const filters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_DEVID_EXCLUDE);
         if (this.checkDeviceId(vid, pid, filters)) {
            result.disabled = true;
            result.type = this.combineRules(this.mListOfFilterSeq.get(FilterType.FLT_DEVID_EXCLUDE));
         }
         Logger.debug("FilterType.FLT_DEVID_EXCLUDE rules = " + Logger.stringify(filters));
         Logger.info("FilterType.FLT_DEVID_EXCLUDE result = " + result.disabled);
      }

      // [4] Exclude Vid/Pid/Rel Device
      if (this.mListOfFilters.has(FilterType.FLT_DEVID_V2_INCLUDE)) {
         const relFilters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_DEVID_V2_INCLUDE);
         if (this.checkDeviceRel(vid, pid, rel, relFilters)) {
            result.disabled = false;
            result.type = this.combineRules(this.mListOfFilterSeq.get(FilterType.FLT_DEVID_V2_INCLUDE));
         }
         Logger.debug("FLT_DEVID_V2_INCLUDE rules = " + Logger.stringify(relFilters));
         Logger.info("LT_DEVID_V2_INCLUDE result = " + result.disabled);
      }

      // [5] Include Device Family
      if (this.mListOfFilters.has(FilterType.FLT_DEVFAMILY_INCLUDE)) {
         const filters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_DEVFAMILY_INCLUDE);
         if (this.checkDeviceFamily(family, filters)) {
            result.disabled = false;
         }
         result.type = this.combineRules(this.mListOfFilterSeq.get(FilterType.FLT_DEVFAMILY_INCLUDE));
         Logger.debug("FilterType.FLT_DEVFAMILY_INCLUDE rules = " + Logger.stringify(filters));
         Logger.info("FilterType.FLT_DEVFAMILY_INCLUDE result = " + result.disabled);
      }

      // [6] Include Vid/Pid Device
      if (this.mListOfFilters.has(FilterType.FLT_DEVID_INCLUDE)) {
         const filters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_DEVID_INCLUDE);
         if (this.checkDeviceId(vid, pid, filters)) {
            result.disabled = false;
         }
         result.type = this.combineRules(this.mListOfFilterSeq.get(FilterType.FLT_DEVID_INCLUDE));
         Logger.debug("FilterType.FLT_DEVID_INCLUDE rules = " + Logger.stringify(filters));
         Logger.info("FilterType.FLT_DEVID_INCLUDE result = " + result.disabled);
      }

      // [7] Exclude Vid/Pid/Rel Device
      if (this.mListOfFilters.has(FilterType.FLT_DEVID_V2_EXCLUDE)) {
         const relFilters: DevFilter[] = this.mListOfFilters.get(FilterType.FLT_DEVID_V2_EXCLUDE);
         if (this.checkDeviceRel(vid, pid, rel, relFilters)) {
            result.disabled = true;
            result.type = this.combineRules(this.mListOfFilterSeq.get(FilterType.FLT_DEVID_V2_EXCLUDE));
         }
         Logger.debug("FLT_DEVID_V2_EXCLUDE rules = " + Logger.stringify(relFilters));
         Logger.info("FLT_DEVID_V2_EXCLUDE result = " + result.disabled);
      }
      return result;
   };
}
