/**
 * ******************************************************
 * Copyright (C) 2018-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * debug-configuration-model.ts
 *
 * enable debug logs per module
 *
 */

import { Injectable } from "@angular/core";
import { LocalStorageService, Logger } from "@html-core";

export interface DubugModuleEntry {
   moduleName: string;
   isEnabled: boolean;
}

@Injectable({
   providedIn: "root"
})
export class DebugConfigurationModel {
   private static readonly SETTING_NAME = "DEBUG_CONFIG";
   static debugConfiguration: {
      isEnabled: boolean;
      debugModuleList: Array<DubugModuleEntry>;
   } = {
      isEnabled: false,
      debugModuleList: [
         {
            moduleName: Logger.Modules.RDPVCBridge,
            isEnabled: false
         },
         {
            moduleName: Logger.Modules.ChromeSDK,
            isEnabled: false
         },
         {
            moduleName: Logger.Modules.Unity,
            isEnabled: false
         }
      ]
   };

   constructor(private localStorageService: LocalStorageService) {
      this.init();
   }

   private _getKeyName = () => {
      return DebugConfigurationModel.SETTING_NAME;
   };

   private _readStorage = () => {
      let debugConfiguration;
      try {
         if (this.localStorageService.get(this._getKeyName())) {
            debugConfiguration = JSON.parse(this.localStorageService.get(this._getKeyName()));
         }
      } catch (e) {
         Logger.exception(e);
      }
      if (debugConfiguration) {
         DebugConfigurationModel.debugConfiguration = debugConfiguration;
      }
      return DebugConfigurationModel.debugConfiguration;
   };

   /**
    * Should catch exception if call this function
    */
   private _setStorage = () => {
      try {
         this.localStorageService.set(this._getKeyName(), JSON.stringify(DebugConfigurationModel.debugConfiguration));
      } catch (e) {
         Logger.exception(e);
      }
   };

   private save = () => {
      this._setStorage();
      Logger.setDebugConfiguration(DebugConfigurationModel.debugConfiguration);
      Logger.debug("Debug config is saved: " + JSON.stringify(DebugConfigurationModel.debugConfiguration));
   };

   // TODO: storage reading should be async, need to fix in another patch
   public isDebugConfigEnabled = (): boolean => {
      this._readStorage();
      return DebugConfigurationModel.debugConfiguration.isEnabled;
   };

   //will return the reference of DebugConfigurationModel.debugConfiguration.debugModuleList
   public getDebugConfigModuleList = (): Array<DubugModuleEntry> => {
      this._readStorage();
      return DebugConfigurationModel.debugConfiguration.debugModuleList;
   };

   public setDebugConfigEnabled = (isEnabled: boolean) => {
      DebugConfigurationModel.debugConfiguration.isEnabled = isEnabled;
      this.save();
   };

   //change will be made directly on the reference of debugModuleList
   public setDebugConfigModuleList = () => {
      this.save();
   };

   public init = () => {
      //init debugConfiguration if storage has one
      this._readStorage();
      Logger.setDebugConfiguration(DebugConfigurationModel.debugConfiguration);
   };
}
