/**
 * ******************************************************
 * Copyright (C) 2017-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { AB, BusEvent, DPIScaleToValue } from "@html-core";

import Logger from "../../../core/libs/logger";
import { VDP_CONSTS } from "../vdpservice";
import { commonSvcMsgSchema } from "./definitions/commonsvc.schema";
import { ProtocolUtil, ProtocolHelper } from "../../../shared/desktop/vdpservice/util/index";

export type DisplayTopologyBriefInfo = {
   isPrimary: boolean;
   monitorDPI: number;
   rect: {
      left: number;
      right: number;
      top: number;
      bottom: number;
   };
};

export const defaultBpp = 32;

// corresponding to RdeChannelDisplayInfo in commonSvcMsgSchema
export type DisplayInfo = {
   isPrimary: number;
   bpp: number;
   monitorDPI: number;
   rect: {
      left: number;
      right: number;
      top: number;
      bottom: number;
   };
   reserved1: number;
   reserved2: number;
   reserved3: number;
};

type DisplaysInfo = Array<DisplayInfo>;
// supported DPI_SYNC_MSG & DISPLAY_MSG
export enum CommonSvcCommandType {
   CLIENT_COMMON_PLUGIN_MSG,
   ENVIRONMENT_VAR_INFO_MSG,
   DPI_SYNC_MSG,
   TABLET_MODE_MSG,
   CERTSSO_UNLOCK_MSG,
   BATTERY_STATE_MSG,
   DISPLAY_MSG,
   FEATURE_ENABLEMENT_MSG
}

export enum DPISyncCommandType {
   DPI_SYNC_COMMAND_NONE,
   DPI_SYNC_COMMAND_VERSION,
   DPI_SYNC_COMMAND_DPI
}

export enum RdeChannelDPISyncServerVersionType {
   RDE_CHANNEL_DPI_SYNC_SERVER_DEFAULT = 1,
   RDE_CHANNEL_DPI_SYNC_NON_IDD_DRIVER,
   RDE_CHANNEL_DPI_SYNC_IDD_DRIVER
}

export enum RdeChannelDPISyncClientVersionType {
   RDE_CHANNEL_DPI_SYNC_CLIENT_DEFAULT = 1,
   RDE_CHANNEL_DPI_SYNC_CLIENT_DISPLAY_INFO
}

export enum RdeChannelDisplayMessageType {
   RDE_CHANNEL_DISPLAY_INFO_MSG
}

export interface ICommonSvcChannelCb {
   onRemoteDPI(wmksKey: string, remoteScaleDPI: number, isDisplayScaleDisabled: boolean, changed: boolean);
   getTargetDPI: () => number;
   getCurrentDisplayInfo: () => Promise<Array<DisplayTopologyBriefInfo>>;
}

// move to a separated file if used by another module
export class RequestWithTimeout<Type> {
   private timer = null;
   constructor(
      private readonly _resolve: (Type) => void,
      private readonly _reject: (string) => void,
      private onReadyRemoved: () => void,
      timeout: number,
      description: string = "request"
   ) {
      this.timer = setTimeout(() => {
         Logger.info(description + " timer timeout");
         this.timer = null;
         this.onReadyRemoved();
         this._reject("timeout");
      }, timeout);
   }
   resolve = (arg: Type) => {
      if (!this.timer) {
         Logger.error("can't resolve a resolved/rejected request");
         return;
      }
      clearTimeout(this.timer);
      this.timer = null;
      this.onReadyRemoved();
      this._resolve(arg);
   };
   reject = (e: any) => {
      if (!this.timer) {
         Logger.error("can't resolve a resolved/rejected request");
         return;
      }
      clearTimeout(this.timer);
      this.timer = null;
      this.onReadyRemoved();
      this._reject(e);
   };
}

export class RequestMatchManager<ResolveType, ItemType> {
   private lastAddedKey: string = null;
   public pendingRequests: Map<string, RequestWithTimeout<ResolveType>>;
   constructor(private keyHasher: (ItemType) => string) {
      this.lastAddedKey = null;
      this.pendingRequests = new Map<string, RequestWithTimeout<ResolveType>>();
   }
   public hasPendingRequestFor = (item: ItemType) => {
      const key = this.keyHasher(item);
      return this.pendingRequests.has(key);
   };

   public lastRequestSameAs = (item: ItemType) => {
      const key = this.keyHasher(item);
      return this.lastAddedKey === key;
   };

   public createTrackedRequest = (
      item: ItemType,
      resolve: (Type) => void,
      reject: (string) => void,
      timeout = 20 * 1000,
      description: string = "request"
   ) => {
      // monitorDPI can't be type of float
      // because in function of onItemResolved, the item's monitorDPI has been clip to integer
      // @ts-ignore
      const integerItem = JSON.parse(JSON.stringify(item));
      integerItem.map((v) => (v.monitorDPI = Math.floor(v.monitorDPI)));
      const key: string = this.keyHasher(integerItem);
      Logger.info("add request for " + key);
      this.pendingRequests.set(
         key,
         new RequestWithTimeout(
            resolve,
            reject,
            () => {
               Logger.info("remove request for " + key);
               this.pendingRequests.delete(key);
            },
            timeout,
            description
         )
      );
      this.lastAddedKey = key;
   };

   public onItemResolved = (item: ItemType, resolveValue: ResolveType) => {
      const key = this.keyHasher(item);
      if (!this.pendingRequests.has(key)) {
         Logger.error("failed to find the matched Item to resolve");
         return;
      }
      this.pendingRequests.get(key).resolve(resolveValue);
   };

   public rejectItemFor = (item: ItemType, reason: string) => {
      const key = this.keyHasher(item);
      const pendingRequest = this.pendingRequests.get(key);
      if (pendingRequest) {
         pendingRequest.reject(reason);
      }
      this.pendingRequests.delete(key);
   };
}

export class CommonSvcChannel {
   static readonly commonSvcObject: string = "CommonSvcObject";
   remoteScaleDPI: number;
   localDPI: number;
   lastAppliedDPI: number;
   vdpServiceMainChannel: any;
   wmksKey: string;
   wmksSession: any;
   pendingRequests = new RequestMatchManager<boolean, DisplaysInfo>((item: DisplaysInfo) => {
      // agent has a limitation: the primary monitor must be put in the first place
      // and sort will change item's order which will cause errors
      // so clone a new one to do the sort job
      const newObj = JSON.parse(JSON.stringify(item));
      const key = newObj
         .sort((a, b) => {
            return a.rect.left - b.rect.left;
         })
         .map((e) => {
            const stringifyBase = JSON.parse(JSON.stringify(e));
            stringifyBase.rect = JSON.stringify(stringifyBase.rect, Object.keys(stringifyBase.rect).sort());
            return JSON.stringify(stringifyBase, Object.keys(stringifyBase).sort());
         })
         .join("_");
      Logger.trace("hashing item to key: " + key);
      return key;
   });
   channelCB: ICommonSvcChannelCb = null;
   serverDPIsyncVersion: RdeChannelDPISyncServerVersionType =
      RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_SERVER_DEFAULT;
   clientDPIsyncVersion: RdeChannelDPISyncClientVersionType =
      RdeChannelDPISyncClientVersionType.RDE_CHANNEL_DPI_SYNC_CLIENT_DEFAULT;
   private onDisplayInfoEnabled: (boolean) => void = null;
   private displayScaleDisabled: boolean = false;
   private protocolHelper: ProtocolHelper;
   private logger: Logger;
   constructor(
      mksKey: string,
      mainChannel: any,
      wmksSession: any,
      defaultDPI: number,
      private enableSyncV2: boolean,
      cb: ICommonSvcChannelCb,
      private enforcePerSystemDPI: boolean
   ) {
      this.localDPI = defaultDPI;
      this.lastAppliedDPI = defaultDPI;
      this.wmksKey = mksKey;
      this.vdpServiceMainChannel = mainChannel;
      this.wmksSession = wmksSession;
      this.channelCB = cb;
      mainChannel.addMessageHandler(this);
      this.protocolHelper = ProtocolUtil.getHelper(commonSvcMsgSchema);
      this.logger = new Logger(Logger.DPI);
   }

   public getRemoteDPIScale = (): number => {
      if (!this.remoteScaleDPI) {
         return this.localDPI;
      }
      return this.remoteScaleDPI;
   };

   /**
    * handleRPCFromServer
    *
    * Helper function used to parse the RPC data that we
    * receive from a server.
    *
    * @param rpc      RPC object that was posted by VDPService.
    *
    * only handle displayInfo and DPI, not handle
    * RDE_CHANNEL_IME_MSG,
    * RDE_CHANNEL_BLOCK_SCREEN_CAPTURE_MSG
    */
   public handleRPCFromServer = (rpc: any) => {
      if (rpc.command === CommonSvcCommandType.DPI_SYNC_MSG) {
         this.processDPISync(rpc);
         return 0;
      } else if (rpc.command === CommonSvcCommandType.DISPLAY_MSG) {
         this.processDisplayInfo(rpc);
         return 0;
      } else {
         return 1;
      }
   };

   /**
    * responsed by UI, to trigger sending of client resolutions
    * where the update topology should only be send to Agent after invoking
    * sendDisplayInfo and get echoed response for IDD driver
    */
   public setOnDisplayInfoEnabled = (callback: (boolean) => void) => {
      this.onDisplayInfoEnabled = callback;
   };

   public sendDisplayInfo = (displayBriefInfos: Array<DisplayTopologyBriefInfo>): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         if (this.clientDPIsyncVersion === RdeChannelDPISyncClientVersionType.RDE_CHANNEL_DPI_SYNC_CLIENT_DEFAULT) {
            this.logger.debug("skip sending displayInfo since client works in DPI sync version 1");
            resolve(false);
            return;
         }
         const displaysInfo = this.getDisplayInfo(displayBriefInfos);
         this.logger.info("send displayInfo to Agent " + JSON.stringify(displaysInfo));
         if (this.pendingRequests.lastRequestSameAs(displaysInfo)) {
            this.logger.info("same display info request detected, skip the sending");
            resolve(true);
            return;
         }
         if (
            !this._sendDisplayInfo(
               displaysInfo,
               () => {
                  this.logger.info("display info had been send");
                  if (
                     this.serverDPIsyncVersion ===
                     RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_NON_IDD_DRIVER
                  ) {
                     resolve(true);
                  }
               },
               (e) => {
                  if (
                     this.serverDPIsyncVersion === RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_IDD_DRIVER
                  ) {
                     this.pendingRequests.rejectItemFor(displaysInfo, e);
                  }
               }
            )
         ) {
            reject("invalid display Info to send");
            return;
         }
         if (this.serverDPIsyncVersion === RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_IDD_DRIVER) {
            this.pendingRequests.createTrackedRequest(displaysInfo, resolve, reject);
         }
      });
   };

   private getDisplayInfo = (displayBriefInfos: Array<DisplayTopologyBriefInfo>): DisplaysInfo => {
      this.logger.debug("converting displayInfo from data" + JSON.stringify(displayBriefInfos));
      const displayInfoArray: DisplaysInfo = displayBriefInfos.map((briefInfo: DisplayTopologyBriefInfo) => {
         const info: DisplayInfo = {
            isPrimary: briefInfo.isPrimary ? 1 : 0,
            bpp: defaultBpp,
            monitorDPI: briefInfo.monitorDPI * AB.DPI_100_PERCENT,
            rect: briefInfo.rect,
            reserved1: 0,
            reserved2: 0,
            reserved3: 0
         };
         return info;
      });
      return displayInfoArray;
   };
   /**
    * The onDone onAbort is referening the sending done and abort
    * For IDD driver the returned promise would only be resolved if echo is get
    * otherwise rejected if timeout(30s)
    */

   /**
    * should be invoked by monitor control through eventBus.
    * updateTopology should be hold until response is get
    */

   private _sendDisplayInfo = (displayInfoArray: Array<DisplayInfo>, onDone = null, onAbort = null): boolean => {
      if (!displayInfoArray || !(displayInfoArray.length > 0)) {
         this.logger.error("invalid displayInfo to send");
         return false;
      }
      if (this.clientDPIsyncVersion !== RdeChannelDPISyncClientVersionType.RDE_CHANNEL_DPI_SYNC_CLIENT_DISPLAY_INFO) {
         this.logger.error("invalid client working version for dpi sync");
         return false;
      }
      const displayCommand = {
         commandType: RdeChannelDisplayMessageType.RDE_CHANNEL_DISPLAY_INFO_MSG,
         displaysInfo: {
            count: displayInfoArray.length,
            displayInfos: displayInfoArray
         }
      };
      const displayInfoUint8Array = this.protocolHelper.simplifiedStreamify("DisplayCommand", displayCommand, {});

      if (
         0 ===
         this.vdpServiceMainChannel.invoke({
            command: CommonSvcCommandType.DISPLAY_MSG,
            type: VDP_CONSTS.RPC_TYPE.REQUEST,
            params: [0, 0, displayInfoUint8Array],
            onDone: onDone,
            onAbort: onAbort,
            objName: CommonSvcChannel.commonSvcObject
         })
      ) {
         return false;
      }
      return true;
   };

   /**
    * send client dpi to server
    * @param dpi
    * @param {Function} [onDone]
    *    Called when the rpc has completed.
    * @param {Function} [onAbort]
    *    Called if the rpc has aborted.
    * @return {Boolean} success
    *    Returns true on success, false on failure
    */
   private sendClientVersionRPC = (clientVersion: RdeChannelDPISyncClientVersionType, onDone, onAbort) => {
      const sendVersionCommand = {
         commandType: DPISyncCommandType.DPI_SYNC_COMMAND_VERSION,
         targetVersion: clientVersion
      };

      const clientVersionUint8Array = this.protocolHelper.simplifiedStreamify(
         "DPISyncVersionCommand",
         sendVersionCommand,
         {}
      );

      if (
         0 ===
         this.vdpServiceMainChannel.invoke({
            command: CommonSvcCommandType.DPI_SYNC_MSG,
            type: VDP_CONSTS.RPC_TYPE.REQUEST,
            params: [0, 0, clientVersionUint8Array],
            onDone: onDone,
            onAbort: onAbort,
            objName: CommonSvcChannel.commonSvcObject
         })
      ) {
         return false;
      }
      return true;
   };

   /**
    * For RDE_CHANNEL_DPI_SYNC_VERSION_MSG, RDE_CHANNEL_DPI_SYNC_DPI_MSG
    * @param rpc
    */
   private processDPISync = (rpc) => {
      this.logger.debug("get DPI sync message");
      const packet = WMKS.Packet.createFromBufferLE(rpc.params[2]);
      if (packet != null) {
         const subCommandType = packet.readUint32();
         if (subCommandType === DPISyncCommandType.DPI_SYNC_COMMAND_VERSION) {
            const version = packet.readUint32();
            if (
               version > RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_IDD_DRIVER ||
               version < RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_SERVER_DEFAULT
            ) {
               this.logger.error("invalid server DPI sync version, fallback to default");
               this.serverDPIsyncVersion = RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_SERVER_DEFAULT;
            } else {
               this.serverDPIsyncVersion = version;
            }
            this.logger.info("Remote DPI version is: " + this.serverDPIsyncVersion);
            /**
             * use dynamic client version to improve readability
             * Always use per system DPI for remote app to avoid UI issue caused by
             * Window OS workaround when DPI each screen is different.
             */
            if (
               this.serverDPIsyncVersion !== RdeChannelDPISyncServerVersionType.RDE_CHANNEL_DPI_SYNC_SERVER_DEFAULT &&
               this.enableSyncV2 &&
               !this.enforcePerSystemDPI
            ) {
               //boradcast to request displayInfo
               this.clientDPIsyncVersion = RdeChannelDPISyncClientVersionType.RDE_CHANNEL_DPI_SYNC_CLIENT_DISPLAY_INFO;
               this.channelCB
                  .getCurrentDisplayInfo()
                  .then((displayInfo: Array<DisplayTopologyBriefInfo>) => {
                     this.logger.info("init display by " + JSON.stringify(displayInfo));
                     this.sendDisplayInfo(displayInfo).catch((e) => {
                        Logger.exception(e);
                     });
                  })
                  .catch((e) => {
                     Logger.exception(e);
                  });
            } else {
               this.clientDPIsyncVersion = RdeChannelDPISyncClientVersionType.RDE_CHANNEL_DPI_SYNC_CLIENT_DEFAULT;
            }
            if (!this.onDisplayInfoEnabled) {
               this.logger.error("no onDisplayInfoEnabled defined");
            } else {
               this.onDisplayInfoEnabled(
                  this.clientDPIsyncVersion ===
                     RdeChannelDPISyncClientVersionType.RDE_CHANNEL_DPI_SYNC_CLIENT_DISPLAY_INFO
               );
            }

            this.logger.info("Client DPI version is: " + this.clientDPIsyncVersion);
            this.sendClientVersionRPC(this.clientDPIsyncVersion, null, null);
         } else if (subCommandType === DPISyncCommandType.DPI_SYNC_COMMAND_DPI) {
            // For 0xffffffff, interpret as -1 and used as special value
            const remoteDPIValue = packet.readInt32();
            this.logger.info("Remote Desktop DPI : " + remoteDPIValue + ", for session " + this.wmksKey);

            // Always call without check, since promise can only be resolved or rejected once
            if (remoteDPIValue === -1) {
               this.logger.info("Agent request to disable Display scale");
               this.remoteScaleDPI = this.channelCB.getTargetDPI();
               this.displayScaleDisabled = true;
            } else {
               this.logger.info("Agent allow Display scale, remote Agent DPI is " + remoteDPIValue);
               this.remoteScaleDPI = remoteDPIValue / AB.DPI_100_PERCENT;
               this.displayScaleDisabled = false;
            }
            this.logger.info("use Remote Desktop DPI scale: " + this.remoteScaleDPI + ", for session " + this.wmksKey);
            this.channelCB.onRemoteDPI(
               this.wmksKey,
               this.remoteScaleDPI,
               this.displayScaleDisabled,
               this.remoteScaleDPI !== this.lastAppliedDPI
            );
            this.lastAppliedDPI = this.remoteScaleDPI;
         } else {
            this.logger.error("Invalid command type: " + subCommandType);
         }
      } else {
         this.logger.error("unexpected DPI sync message format");
      }
      this.logger.error(
         "dpi sync server version: " +
            this.serverDPIsyncVersion +
            ", selected client version: " +
            this.clientDPIsyncVersion
      );
   };

   public isDisplayScaleDisabled = () => {
      return this.displayScaleDisabled;
   };

   /**
    * DpiSyncClient::ProcessDisplayCommand
    * get echo from server as ACK
    *
    * Should trigger sending of updateTopology, which on native by
    * MKS::ProcessRdeCommonDisplayMsg => SetDisplayTopology(monitors);
    */
   private processDisplayInfo = (rpc) => {
      this.logger.info("get DisplayInfo from Agent");
      if (!rpc.params[2]) {
         this.logger.error("invalid DisplayInfo");
         return;
      }
      const displayCommand = this.protocolHelper.simplifiedParse(rpc.params[2], "DisplayCommand", {});
      if (displayCommand.commandType !== RdeChannelDisplayMessageType.RDE_CHANNEL_DISPLAY_INFO_MSG) {
         this.logger.error("unexpected RdeChannelDisplayMessageType " + displayCommand.subCommandType);
         return false;
      }

      const displayInfo: DisplaysInfo = displayCommand.displaysInfo.displayInfos;
      this.logger.info("DisplayInfo: " + JSON.stringify(displayInfo));
      this.pendingRequests.onItemResolved(displayInfo, true);
   };
}
