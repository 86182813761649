/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * resetDesktopCommand.js --
 *
 * reset desktop.
 *
 */

import { globalArray } from "../jscdkClient";
import ResetDesktopHandler from "../controllers/resetDesktopHandler";
import { MessageHandler } from "../controllers/messageHandler";

export default function ResetDesktopCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param resetDesktopAction[in]
 *        - its property 'method' has the value 'ResetDesktop'
 */
ResetDesktopCommand.prototype.execute = function (resetDesktopAction) {
   let handlerList,
      desktopId,
      resetDesktopObject,
      router = globalArray["router"];

   desktopId = resetDesktopAction.desktopId;
   resetDesktopObject = router.getHandler("reset-desktop", desktopId);

   if (!resetDesktopObject) {
      resetDesktopObject = new ResetDesktopHandler();
      globalArray[resetDesktopObject.messageName + resetDesktopObject.requestId] = resetDesktopObject;
      globalArray[resetDesktopObject.responseTag + resetDesktopObject.requestId] = resetDesktopObject;
      /**
       * update MessageHandler.prototype.requestIdKV
       * the format is {responseTag1+desktop-id1 : requestId1,
       * responseTag2+desktop-id2 : requestId2} such as key of
       * ResetDesktopHandler is "reset-desktop"+desktopId
       */
      MessageHandler.prototype.requestIdKV[resetDesktopObject.responseTag + desktopId] = resetDesktopObject.requestId;
   } else {
      resetDesktopObject.resetData();
   }

   if (desktopId) {
      resetDesktopObject.setRequestXML(desktopId);
      handlerList = resetDesktopObject.composeHandlerList();

      router.postMessage(handlerList);
   }
};
