<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit($event)" id="change-password">
   <div class="ui-panel-content">
      <div [hidden]="blankOutContent">
         <div *ngIf="!error" class="ui-login-hint-text ui-login-hint-text-change-password">
            {{'dialog_prompt_changepwd' | i18nv2}}</div>
         <div *ngIf="error" class="alert alert-danger alert-change-password">
            {{error}}
         </div>
         <div class="change-password-form form-horizontal">
            <label for="username">{{'dialog_placeholder_username' | i18nv2}}</label>
            <input type="text" id="username" class="form-control"
               placeholder="{{'dialog_placeholder_username' | i18nv2}}" readonly
               [formControl]="usernameControl">
            <label for="oldpassword">{{'dialog_placeholder_oldpwd' | i18nv2}}</label>
            <input type="text" id="oldpassword" (focus)="changeType('oldpassword')" class="form-control"
               placeholder="{{'dialog_placeholder_oldpwd' | i18nv2}}" [formControl]="oldPasswordControl"
               [autoFocus]>
            <button *ngIf="allowClear.oldPassword" class="ui-clear-btn ui-clear-oldpassword-btn" id="clearPasswordBtn"
               type=button (click)="clearOldPassword()" tabindex="-1">{{'clear_password_btn' | i18nv2}}</button>

            <label for="newpassword">{{'dialog_placeholder_newpwd' | i18nv2}}</label>
            <input type="text" id="newpassword" (focus)="changeType('newpassword')" class="form-control"
               placeholder="{{'dialog_placeholder_newpwd' | i18nv2}}" [formControl]="newPasswordControl">
            <button *ngIf="allowClear.newPassword" class="ui-clear-btn ui-clear-newpassword-btn" id="clearPasswordBtn"
               type=button (click)="clearNewPassword()" tabindex="-1">{{'clear_password_btn' | i18nv2}}</button>

            <div class="ui-text-left-align">
               <label for="newpassword-repeat"
                  *ngIf="changePasswordForm.hasError('notSame') && (newPasswordRepeatControl.touched||newPasswordRepeatControl.dirty)"
                  class="ui-password-dismatch-text ng-binding">{{'validation_equals' | i18nv2}}</label>
               <input type="text" id="newpassword-repeat" (focus)="changeType('newpassword-repeat')" class="form-control"
                  placeholder="{{'dialog_placeholder_newpwd2' | i18nv2}}" [formControl]="newPasswordRepeatControl">
               <button *ngIf="allowClear.newPasswordRepeat" class="ui-clear-btn ui-clear-newpasswordRepeat-btn"
                  id="clearPasswordBtn" type=button (click)="clearNewPasswordRepeat()"
                  tabindex="-1">{{'clear_password_btn' | i18nv2}}</button>
            </div>
         </div>
      </div>
      <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
   </div>
   <div class="ui-panel-footer ui-panel-footer-change-password">
      <button id="changePasswordSubmitBtn" class="btn btn-primary" [ngClass]="{'btnPrimaryWaiting':blankOutContent}"
         type="submit" [disabled]="!changePasswordForm.valid"
         [hidden]="blankOutContent">{{ 'dialog_btn_signin' | i18nv2}}</button>
      <button id="changePasswordCancelBtn" class="btn btn-default" [ngClass]="{'btnDefaultWaiting':blankOutContent}"
         (click)="cancel()">{{'CANCEL' | i18nv2}}</button>
   </div>
</form>