<form [formGroup]="windowsPasswordForm" (ngSubmit)="onSubmit($event)" (keydown)="onKeypress($event)">

   <div class="ui-panel-content ">
      <div *ngIf="!blankOutContent">
         <div class="file-association-warning-server" [hidden]="faServer===''">{{'file_association_server_notification' | i18nv2 : faServer}}</div>
         <div class="alert alert-danger" role="alert" aria-invalid="true">
            {{error}}
         </div>
         <div class="form-horizontal">
            <label for="username">{{'dialog_label_edit_username' | i18nv2}}</label>
            <input type="text" id="username" class="form-control"
               placeholder="{{'dialog_placeholder_username' | i18nv2}}" [readonly]="usernameReadOnly"
               [attr.disabled]="usernameReadOnly ? '' : null" [formControl]="usernameControl">
            <button [hidden]="!allowClearUsername" class="ui-clear-btn ui-clear-username-btn" id="clearUsernameBtn"
               type=button (click)="clearUsername()" tabindex="-1">{{'clear_username_btn' | i18nv2}} </button>
            <label for="password">{{'dialog_label_edit_password' | i18nv2}}</label>
            <input type="text" id="password" type="text" (focus)="changeType('password')" class="form-control"
               placeholder="{{'dialog_placeholder_password' | i18nv2}}" [formControl]="passwordControl">
            <button [hidden]="!allowClearPassword" class="ui-clear-btn ui-clear-password-btn" id="clearPasswordBtn"
               type=button (click)="clearPassword()" tabindex="-1">{{'clear_password_btn' | i18nv2}}</button>
            <label for="domain">{{'dialog_label_domain' | i18nv2}}</label>
            <span id="domain-button" *ngIf="!domainHidden" tabindex="0" [attr.disabled]="domainReadOnly || domainFrozen ? '' : null"
               role="combobox" (click)="toggleDomainList()" class="ui-selectmenu-button ui-button ui-widget domain-select"
               [ngClass]="showDomainList ? 'ui-selectmenu-button-open ui-corner-top' : 'ui-selectmenu-button-closed ui-corner-all'"
               (keydown)="openDomainList($event)">
               <span class="ui-selectmenu-icon ui-icon"></span>
               <span class="ui-selectmenu-text" [attr.disabled]="domainReadOnly || domainFrozen ? '' : null"
               >{{currentDomain}}</span>
               <span id="domain-list" class="ui-selectmenu-menu ui-front"
                  [ngClass]="showDomainList ? 'ui-selectmenu-open ui-domain-list' : ''">
                  <ul aria-labelledby="domain-button" id="domain-menu" role="listbox"
                     class="ui-menu ui-corner-bottom ui-widget ui-widget-content overflow"
                     style="width: 371px;">
                     <li class="ui-menu-item" *ngFor="let domain of domains" [ngValue]="domain">
                        <div role="option" class="ui-menu-item-wrapper" id="{{domain}}"
                           (click)="selectDomain(domain)" (keydown)="domainItemKeyEvent($event, domain)" tabindex="0">{{ domain }}</div>
                     </li>
                  </ul>
               </span>
            </span>
         </div>
      </div>
      <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
   </div>
   <div class="ui-panel-footer">
      <button id="loginButton" class="btn btn-primary" [ngClass]="{'btnPrimaryWaiting':blankOutContent}" type="submit"
         [hidden]="blankOutContent" [disabled]="!windowsPasswordForm.valid">{{'dialog_btn_signin' | i18nv2}}</button>
      <button id="cancelLoginBtn" class="btn btn-default" [ngClass]="{'btnDefaultWaiting':blankOutContent}"
         (click)="cancel()">{{'CANCEL' | i18nv2}}</button>
   </div>

</form>