/**
 * ******************************************************
 * Copyright (C) 2020-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable, Optional } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { DisplayCheckService } from "../../desktop/common/display-check.service";
import { ClientSettingModel } from "../model/client-setting-model";
import { PreDataSetModel } from "../model/pre-data-set-model";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { ConnectionServerModel } from "../model/connection-server-model";
import { UserGlobalPref } from "./user-global-pref";
import { clientUtil } from "@html-core";
import { ViewClientModel } from "../model/viewclient-model";
import { FeatureConfigs } from "../model/feature-configs";
import { DisplayService } from "../../desktop/common/display/display.service";
import { ConnectedMessageService } from "../../../chrome-client/base/service/connected-message.service";
import { Port } from "../../../chrome-client/base/model/rx-bridge-type";

export namespace DISPLAY {
   export enum DisplayOption {
      ALL = 0,
      SINGLE = 1,
      SELECTED = 2,
      SMALL_WINDOW = 3,
      LARGE_WINDOW = 4,
      CUSTOM_WINDOW = 5,
      FULLSCREEN = 6
   }
}

@Injectable({
   providedIn: "root"
})
export class MonitorInfoService {
   private static readonly SMALL_WINDOW_WIDTH: number = 640;
   private static readonly SMALL_WINDOW_HEIGHT: number = 480;
   private static readonly LARGE_WINDOW_WIDTH: number = 1904;
   private static readonly LARGE_WINDOW_HEIGHT: number = 978;

   private screenLayout: any;
   private customWindowWidth: number = null;
   private customWindowHeight: number = null;

   private _useWindowReplaceApi: boolean;

   private _selectedMonitorsJSONForDesktop = "";
   private _selectedMonitorsJSONForApp = "";
   private _isChromeClient;

   private _usedMonitorsCount: number = 0;

   public shouldEnterMultimonSessionKey = null;

   constructor(
      private displayCheckService: DisplayCheckService,
      private preDataSetModel: PreDataSetModel,
      private clientSettingModel: ClientSettingModel,
      private modalDialogService: ModalDialogService,
      private connectionServerModel: ConnectionServerModel,
      private userGlobalPref: UserGlobalPref,
      private _featureConfigs: FeatureConfigs,
      private _displayService: DisplayService,
      @Optional()
      private _connectedMessageService: ConnectedMessageService
   ) {
      this._isChromeClient = clientUtil.isChromeClient();
      this._useWindowReplaceApi =
         this._featureConfigs.getConfig("KillSwitch-WindowReplacementApi") && !this._isChromeClient;
      if (this._isChromeClient && !chrome.system.display.onDisplayChanged.hasListener(this._onExtendedScreenChanged)) {
         // init for first time
         this._onExtendedScreenChanged();
         chrome.system.display.onDisplayChanged.addListener(this._onExtendedScreenChanged);
      }
   }

   private _onExtendedScreenChanged = () => {
      Logger.info("Extended monitors changed.", Logger.DISPLAY);
      const option = this.clientSettingModel.getPrefNumberItem("displaySetting");
      // for remote desktop
      if (option === DISPLAY.DisplayOption.ALL || option === DISPLAY.DisplayOption.SELECTED) {
         let originSelectedMonitors = this.preDataSetModel.settingData["selectedMonitors"] || null;
         if (originSelectedMonitors !== null && originSelectedMonitors.length > 0) {
            originSelectedMonitors = JSON.parse(originSelectedMonitors);
         }
         this._computeMonitorAndDispatch(option, originSelectedMonitors, false, option === DISPLAY.DisplayOption.ALL);
      }
      // for remote app
      if (option !== DISPLAY.DisplayOption.ALL) {
         this._computeMonitorAndDispatch(DISPLAY.DisplayOption.ALL, null, true);
      }
   };

   private _computeMonitorAndDispatch = (option, selectedMonitors, isApplicationSession, sendForApp = false) => {
      this.getScreensInfo(option, selectedMonitors).then((content) => {
         let selectedMonitors = this.getSelectedMonitors(content, option);
         chrome.system.display.getDisplayLayout((layout) => {
            selectedMonitors = this.reorderMonitors(layout, selectedMonitors);
            const selectedMonitorsJSON = JSON.stringify(selectedMonitors);
            if (isApplicationSession || sendForApp) {
               if (this._selectedMonitorsJSONForApp === "") {
                  this._selectedMonitorsJSONForApp = selectedMonitorsJSON;
               } else if (this._selectedMonitorsJSONForApp != selectedMonitorsJSON) {
                  this._selectedMonitorsJSONForApp = selectedMonitorsJSON;
                  Logger.info("Selected monitors changed for APP, new value = " + selectedMonitorsJSON, Logger.DISPLAY);
                  this._connectedMessageService.sendMessageByType(Port.ChannelName.Settings, {
                     type: Port.SettingMsg.selectedMonitors,
                     data: {
                        selectedMonitors,
                        isApplicationSession: true
                     }
                  });
               }
            }
            if (!isApplicationSession) {
               if (this._selectedMonitorsJSONForDesktop === "") {
                  this._selectedMonitorsJSONForDesktop = selectedMonitorsJSON;
               } else if (this._selectedMonitorsJSONForDesktop != selectedMonitorsJSON) {
                  this._selectedMonitorsJSONForDesktop = selectedMonitorsJSON;
                  Logger.info(
                     "Selected monitors changed for DESKTOP, new value = " + selectedMonitorsJSON,
                     Logger.DISPLAY
                  );
                  this._connectedMessageService.sendMessageByType(Port.ChannelName.Settings, {
                     type: Port.SettingMsg.selectedMonitors,
                     data: {
                        selectedMonitors,
                        isApplicationSession: false,
                        shouldEnterMultimonSessionKey: this.shouldEnterMultimonSessionKey
                     }
                  });
               }
            }
         });
      });
   };

   public getScreensInfo = (option, selectedMonitors) => {
      return new Promise((resolve, reject) => {
         if (this._useWindowReplaceApi) {
            this._displayService
               .getAndInitScreenDetails()
               .then((screens) => {
                  const screenInfo = this._setDisplayInfoWebClient(screens);
                  const content = this.setSelectStatus(option, selectedMonitors, screenInfo);
                  resolve(content);
               })
               .catch((e) => {
                  reject(e);
               });
         } else {
            chrome.system.display.getInfo((screens) => {
               const screenInfo = this._setDisplayInfo(screens);
               const content = this.setSelectStatus(option, selectedMonitors, screenInfo);
               resolve(content);
            });
         }
      });
   };

   private _setDisplayInfoWebClient = (screens) => {
      const content = {};
      screens.screens.forEach((screen, key) => {
         content[key] = {
            settings: {
               x: screen.left,
               y: screen.top,
               width: screen.width,
               height: screen.height,
               modeWidth: screen.width,
               modeHeight: screen.height,
               devicePixelRatio: screen.devicePixelRatio,
               id: screen.id,
               label: screen.label
            },
            selected: screen === screens.currentScreen,
            isCurrentScreen: screen === screens.currentScreen
         };
      });
      Logger.debug("monitors are " + JSON.stringify(content), Logger.DISPLAY);
      return content;
   };

   private _setDisplayInfo = (screens) => {
      const content = {};
      screens.forEach((screen, key) => {
         // modes means dpi,width,height info when scale=100% that means
         // when you change screen scale modes don't change
         let modes = screen.modes,
            mode,
            modeWidth = 0,
            modeHeight = 0,
            // current real resolution that will be changed when scale changed.
            workArea,
            devicePixelRatio = 1;
         if (modes.length > 0) {
            // get selected mode
            mode = screen.modes.find((mode) => mode.isSelected);
            // only have DisplayMode deviceScaleFactor attr on ChromeOS
            devicePixelRatio = mode.deviceScaleFactor || 1;
            modeWidth = mode.width;
            modeHeight = mode.height;
         }
         // Use workArea instead of bounds here. Or
         // it will different will windows.screen value.
         workArea = screen.bounds;
         content[key] = {
            settings: {
               x: workArea.left,
               y: workArea.top,
               width: workArea.width, // used to check monitor adjacent
               height: workArea.height, // used to check monitor adjacent
               modeWidth, // used to check large monitor
               modeHeight, // used to check large monitor
               devicePixelRatio: devicePixelRatio,
               workArea, // used to get device size
               id: screen.id,
               label: ""
            },
            selected: false
         };
      });
      Logger.debug("monitors are " + JSON.stringify(content), Logger.DISPLAY);
      return content;
   };

   public setSelectStatus = (option, selectedMonitors, content, windowWidth?, windowHeight?) => {
      this._usedMonitorsCount = 0;
      for (const key in content) {
         if (content.hasOwnProperty(key)) {
            switch (option) {
               case DISPLAY.DisplayOption.ALL: {
                  content[key].selected = true;
                  content[key].fullscreen = false;
                  if (content[key].settings.workArea) {
                     // if previous value is small or large window, width and height will be small or large window's value
                     // when use all monitor, we need reset to origin window size.
                     content[key].settings.height = content[key].settings.workArea.height;
                     content[key].settings.width = content[key].settings.workArea.width;
                  }
                  this._usedMonitorsCount++;
                  break;
               }
               case DISPLAY.DisplayOption.SINGLE: {
                  content[key].selected = false;
                  content[key].fullscreen = false;
                  this._usedMonitorsCount = 1;
                  break;
               }
               case DISPLAY.DisplayOption.SMALL_WINDOW: {
                  content[key].selected = false;
                  content[key].fullscreen = false;
                  content[key].settings.height = MonitorInfoService.SMALL_WINDOW_HEIGHT;
                  content[key].settings.width = MonitorInfoService.SMALL_WINDOW_WIDTH;
                  this._usedMonitorsCount = 1;
                  break;
               }
               case DISPLAY.DisplayOption.LARGE_WINDOW: {
                  content[key].selected = false;
                  content[key].fullscreen = false;
                  this._usedMonitorsCount = 1;
                  break;
               }
               case DISPLAY.DisplayOption.CUSTOM_WINDOW: {
                  content[key].selected = false;
                  content[key].fullscreen = false;
                  if (windowHeight && windowWidth) {
                     content[key].settings.height = windowHeight;
                     content[key].settings.width = windowWidth;
                  }
                  this._usedMonitorsCount = 1;
                  break;
               }
               case DISPLAY.DisplayOption.FULLSCREEN: {
                  content[key].selected = false;
                  content[key].fullscreen = true;
                  this._usedMonitorsCount = 1;
                  break;
               }
               case DISPLAY.DisplayOption.SELECTED: {
                  if (selectedMonitors !== null) {
                     for (let i = 0; i < selectedMonitors.length; i++) {
                        if (this._isChromeClient) {
                           if (
                              selectedMonitors[i].settings.id === content[key].settings.id &&
                              selectedMonitors[i].selected
                           ) {
                              content[key].selected = true;
                              this._usedMonitorsCount++;
                           }
                        } else {
                           // on web client we use new api to get screen infos, which has no id to identify screens,
                           // instead, we can use x,y,width and height to identify screens
                           if (
                              this.displayCheckService.isSameMonitor(
                                 selectedMonitors[i].settings,
                                 content[key].settings
                              ) &&
                              selectedMonitors[i].selected
                           ) {
                              content[key].selected = true;
                              this._usedMonitorsCount++;
                           }
                        }
                     }
                  } else {
                     this._usedMonitorsCount = 1;
                     if (this._isChromeClient) {
                        content[key].selected = false;
                        content[key].settings.height = content[key].settings.workArea.height;
                        content[key].settings.width = content[key].settings.workArea.width;
                     }
                  }
                  break;
               }
               default: {
                  break;
               }
            }
         }
      }
      return content;
   };

   public useMultiMonitor = () => {
      const displaySetting = this.clientSettingModel.getPrefNumberItem("displaySetting");
      return displaySetting === DISPLAY.DisplayOption.ALL || displaySetting === DISPLAY.DisplayOption.SELECTED;
   };

   /**
    * detect adjacent rect
    */
   public hasAdjacentRect = (content) => {
      let k1,
         k2,
         rect1,
         rect2,
         hasAdjacent,
         selectedLength = 0;

      for (k1 in content) {
         if (content.hasOwnProperty(k1) && content[k1].selected) {
            selectedLength++;
            rect1 = content[k1].settings;
            hasAdjacent = false;
            for (k2 in content) {
               if (content.hasOwnProperty(k2) && k1 !== k2 && content[k2].selected) {
                  rect2 = content[k2].settings;
                  if (!!rect2 && this.displayCheckService.areRectanglesAdjacent(rect1, rect2)) {
                     hasAdjacent = true;
                     break;
                  }
               }
            }
            if (hasAdjacent) {
               return true;
            }
         }
      }

      if (selectedLength < 2) {
         return true;
      } else {
         return false;
      }
   };

   public setPolicyDisplayOption = (policyDisplayValue, singleDisplayOption?) => {
      let setOption;
      const adminCommonSettings = this.connectionServerModel.googleCommonAdminSettings;
      if (policyDisplayValue) {
         if (this.clientSettingModel.getPrefNumberItem("displaySetting") === DISPLAY.DisplayOption.SELECTED) {
            setOption = DISPLAY.DisplayOption.SELECTED;
         } else {
            setOption = DISPLAY.DisplayOption.ALL;
         }
      } else {
         if (singleDisplayOption) {
            if (
               singleDisplayOption === "singleMonitorSmallWindow" &&
               clientUtil.getScreenWidth() >= MonitorInfoService.SMALL_WINDOW_WIDTH &&
               window.screen.height >= MonitorInfoService.SMALL_WINDOW_HEIGHT
            ) {
               setOption = DISPLAY.DisplayOption.SMALL_WINDOW;
            } else if (
               singleDisplayOption === "singleMonitorLargeWindow" &&
               clientUtil.getScreenWidth() >= MonitorInfoService.LARGE_WINDOW_WIDTH &&
               window.screen.height >= MonitorInfoService.LARGE_WINDOW_HEIGHT
            ) {
               setOption = DISPLAY.DisplayOption.LARGE_WINDOW;
            } else if (singleDisplayOption === "singleMonitorFullscreen") {
               setOption = DISPLAY.DisplayOption.FULLSCREEN;
            } else if (singleDisplayOption === "singleMonitor") {
               setOption = DISPLAY.DisplayOption.SINGLE;
            } else if (
               !!adminCommonSettings &&
               adminCommonSettings.hasOwnProperty("display") &&
               adminCommonSettings["display"].hasOwnProperty("width") &&
               adminCommonSettings["display"].hasOwnProperty("height") &&
               adminCommonSettings["display"]["width"] <= clientUtil.getScreenWidth() &&
               adminCommonSettings["display"]["height"] <= window.screen.height
            ) {
               setOption = DISPLAY.DisplayOption.CUSTOM_WINDOW;
            } else if (
               !!adminCommonSettings &&
               adminCommonSettings.hasOwnProperty("display") &&
               typeof adminCommonSettings["display"] === "string" &&
               adminCommonSettings["display"].indexOf("width") !== -1 &&
               adminCommonSettings["display"].indexOf("height") !== -1
            ) {
               const customWindowString = adminCommonSettings["display"];
               const widthStartPos = customWindowString.indexOf(":") + 1;
               const widthEndPos = customWindowString.indexOf(",");
               const widthStr = customWindowString.substring(widthStartPos, widthEndPos);
               const widthNum = Number(widthStr);
               const heightStartPos = customWindowString.lastIndexOf(":") + 1;
               const heightEndPos = customWindowString.lastIndexOf("}");
               const heightStr = customWindowString.substring(heightStartPos, heightEndPos);
               const heightNum = Number(heightStr);
               if (widthNum <= clientUtil.getScreenWidth() && heightNum <= window.screen.height) {
                  setOption = DISPLAY.DisplayOption.CUSTOM_WINDOW;
               } else {
                  setOption = DISPLAY.DisplayOption.SINGLE;
               }
            } else {
               setOption = DISPLAY.DisplayOption.SINGLE;
            }
         } else {
            setOption = DISPLAY.DisplayOption.SINGLE;
         }
      }
      this.clientSettingModel.updateSetting("displaySetting", setOption.toString());
      this.clientSettingModel.saveSetting();
      Logger.info("Set display option value: " + setOption, Logger.DISPLAY);

      return setOption;
   };

   /**
    * there are two situations need to sync from admin settings:
    *
    * 1. admin setting has this key and the 'editable' attribute for this key is
    * false or do not exist
    *
    * 2. admin setting has this key, the 'editable' attribute for this key is true
    * but the value of this key do not changed by user before
    * @param key
    * @param isCommonSetting
    * verify if that specific setting need sync from google admin setting
    */
   private needSyncFromAdminSetting = (key: string, isCommonSetting: boolean) => {
      const adminSettings = this.clientSettingModel.getAdminSettings();
      const adminCommonSettings = this.connectionServerModel.googleCommonAdminSettings;
      if (isCommonSetting) {
         if (
            !!adminCommonSettings &&
            adminCommonSettings.hasOwnProperty(key) &&
            (!adminCommonSettings.hasOwnProperty("editable") || !adminCommonSettings["editable"][key])
         ) {
            this.clientSettingModel.updateSetting("isDisplayChanged", "false");
            this.clientSettingModel.saveSetting();
            return true;
         } else if (
            !!adminCommonSettings &&
            adminCommonSettings.hasOwnProperty(key) &&
            adminCommonSettings.hasOwnProperty("editable") &&
            adminCommonSettings["editable"].hasOwnProperty(key) &&
            adminCommonSettings["editable"][key] === true &&
            !this.userGlobalPref.getPrefBooleanItem("isDisplayChanged")
         ) {
            return true;
         } else {
            return false;
         }
      } else {
         if (
            !!adminSettings &&
            adminSettings.hasOwnProperty(key) &&
            (!adminSettings.hasOwnProperty("editable") || !adminSettings["editable"][key])
         ) {
            return true;
         } else if (
            !!adminSettings &&
            adminSettings.hasOwnProperty(key) &&
            adminSettings.hasOwnProperty("editable") &&
            adminSettings["editable"].hasOwnProperty(key) &&
            adminSettings["editable"][key] === true &&
            !this.userGlobalPref.getPrefBooleanItem("isDisplayChanged")
         ) {
            return true;
         } else {
            return false;
         }
      }
   };

   /**
    * The priority should be common display setting policy > per-broker enableMultiMonitor policy >
    * common enableMultiMonitor policy > local saved setting > default setting
    */
   public getDisplaySettingOption = () => {
      const adminSettings = this.clientSettingModel.getAdminSettings();
      const adminCommonSettings = this.connectionServerModel.googleCommonAdminSettings;
      if (this.needSyncFromAdminSetting("display", true)) {
         let brokerPolicyDisplayValue;
         if (adminCommonSettings["display"] === "multipleMonitors") {
            brokerPolicyDisplayValue = true;
            Logger.info(
               "Policy broker setting enablemultimonitor value: " + adminCommonSettings["display"],
               Logger.DISPLAY
            );
            return this.setPolicyDisplayOption(brokerPolicyDisplayValue);
         } else {
            brokerPolicyDisplayValue = false;
            Logger.info(
               "Policy broker setting enablemultimonitor value: " + adminCommonSettings["display"],
               Logger.DISPLAY
            );
            return this.setPolicyDisplayOption(brokerPolicyDisplayValue, adminCommonSettings["display"]);
         }
      } else if (
         this.needSyncFromAdminSetting("enableMultiMonitor", false) &&
         !adminCommonSettings.hasOwnProperty("display")
      ) {
         const brokerPolicyDisplayValue = adminSettings["enableMultiMonitor"];
         Logger.info("Policy broker setting enablemultimonitor value: " + brokerPolicyDisplayValue, Logger.DISPLAY);
         return this.setPolicyDisplayOption(brokerPolicyDisplayValue);
      } else {
         if (
            this.needSyncFromAdminSetting("enableMultiMonitor", true) &&
            !adminCommonSettings.hasOwnProperty("display") &&
            !adminSettings.hasOwnProperty("enableMultiMonitor")
         ) {
            const commonPolicyDisplayValue = adminCommonSettings["enableMultiMonitor"];
            Logger.info("Policy common setting enablemultimonitor value: " + commonPolicyDisplayValue, Logger.DISPLAY);
            return this.setPolicyDisplayOption(commonPolicyDisplayValue);
         } else {
            const displaySetting = this.clientSettingModel.getPrefNumberItem("displaySetting");
            switch (displaySetting) {
               case DISPLAY.DisplayOption.ALL:
               case DISPLAY.DisplayOption.FULLSCREEN:
               case DISPLAY.DisplayOption.SELECTED:
               case DISPLAY.DisplayOption.SINGLE:
                  Logger.info("Set display option value: " + displaySetting, Logger.DISPLAY);
                  return displaySetting;
               case DISPLAY.DisplayOption.CUSTOM_WINDOW:
               case DISPLAY.DisplayOption.LARGE_WINDOW:
               case DISPLAY.DisplayOption.SMALL_WINDOW:
                  // on webclient 3~5 not supported
                  if (this._useWindowReplaceApi) {
                     Logger.info("Set display option value: " + DISPLAY.DisplayOption.SINGLE, Logger.DISPLAY);
                     return DISPLAY.DisplayOption.SINGLE;
                  }
                  Logger.info("Set display option value: " + displaySetting, Logger.DISPLAY);
                  return displaySetting;
               default:
                  Logger.info("Set display option value: " + DISPLAY.DisplayOption.ALL, Logger.DISPLAY);
                  return DISPLAY.DisplayOption.ALL;
            }
         }
      }
   };

   public getSettingScreen = () => {
      if (
         this.preDataSetModel.settingData["selectedMonitors"] !== null &&
         this.preDataSetModel.settingData["selectedMonitors"] !== undefined
      ) {
         return JSON.parse(this.preDataSetModel.settingData["selectedMonitors"]);
      }
      return null;
   };

   public getSelectedMonitors = (content, option) => {
      let selectedMonitorsValue = [],
         key;

      for (key in content) {
         if (content.hasOwnProperty(key)) {
            if (content[key].selected) {
               selectedMonitorsValue.push(content[key]);
            }
         }
      }
      /**
       * If user choose "select" but doesn't select any monitor,
       * the setting is the same with "all".
       */
      if (option === DISPLAY.DisplayOption.SELECTED && selectedMonitorsValue.length === 0) {
         for (key in content) {
            if (content.hasOwnProperty(key)) {
               selectedMonitorsValue.push(content[key]);
            }
         }
      }

      return selectedMonitorsValue;
   };

   public reorderMonitors = (screenLayout, monitors) => {
      let temp;
      if (!!monitors && !!monitors[0] && !!monitors[0].settings) {
         monitors[0].parentId = monitors[0].settings.id;
      }

      if (screenLayout != null) {
         screenLayout.forEach((layout) => {
            for (let i = 0; i < monitors.length; i++) {
               if (layout.id === monitors[i].settings.id) {
                  // set parentid to the monitors
                  monitors[i].parentId = layout.parentId;
               }
            }
         });
      }

      for (let i1 = 0; i1 < monitors.length; i1++) {
         const parentId = monitors[i1].parentId;
         for (let i2 = 0; i2 < monitors.length; i2++) {
            // Set baseId to the screens according to parentid
            if (monitors[i2] && monitors[i2].settings && monitors[i2].settings.id === parentId) {
               monitors[i1].baseId = i2;
            }
         }
      }
      /**
       * Reorder the screens according to baseid
       * As the first monitor is always the primary monitor,
       * so start the loop from 1
       * */
      for (let i3 = 1; i3 < monitors.length; i3++) {
         const nextKey = i3 + 1;

         if (monitors[nextKey] && monitors[i3].baseId > monitors[nextKey].baseId) {
            temp = monitors[i3];
            monitors[i3] = monitors[nextKey];
            monitors[nextKey] = temp;
         }
      }

      return monitors;
   };

   public saveDisplaySetting = (option, selectedMonitors, customWindowWidth?, customWindowHeight?) => {
      // If user change display setting, don't get policy setting again.
      let adminDisplayOptionNum;
      let adminDisplayOption;
      const googleCommonAdminSettings = this.connectionServerModel.googleCommonAdminSettings;
      if (
         !this.userGlobalPref.getPrefBooleanItem("isDisplayChanged") &&
         !!googleCommonAdminSettings &&
         googleCommonAdminSettings.hasOwnProperty("display")
      ) {
         adminDisplayOption = googleCommonAdminSettings["display"];
         if (adminDisplayOption === "multipleMonitors") {
            adminDisplayOptionNum = 0;
         } else if (adminDisplayOption === "singleMonitor") {
            adminDisplayOptionNum = 1;
         } else if (adminDisplayOption === "singleMonitorSmallWindow") {
            adminDisplayOptionNum = 3;
         } else if (adminDisplayOption === "singleMonitorLargeWindow") {
            adminDisplayOptionNum = 4;
         } else if (adminDisplayOption === "singleMonitorFullscreen") {
            adminDisplayOptionNum = 6;
         } else if (adminDisplayOption.hasOwnProperty("width") && adminDisplayOption.hasOwnProperty("height")) {
            adminDisplayOptionNum = 5;
         }
         if (option !== adminDisplayOptionNum) {
            this.clientSettingModel.updateSetting("isDisplayChanged", "true");
            this.clientSettingModel.saveSetting();
         }
      }
      this.clientSettingModel.updateSetting("displaySetting", option.toString());
      this.clientSettingModel.saveSetting();
      if (typeof customWindowWidth === "number") {
         this.customWindowWidth = customWindowWidth;
         this.clientSettingModel.updateSetting("customWindowWidth", customWindowWidth.toString());
      }
      if (typeof customWindowHeight === "number") {
         this.customWindowHeight = customWindowHeight;
         this.clientSettingModel.updateSetting("customWindowHeight", customWindowHeight.toString());
      }
      Logger.info("Display setting option is : " + option, Logger.DISPLAY);
      if (this._isChromeClient) {
         try {
            chrome.system.display.getDisplayLayout((layout) => {
               selectedMonitors = this.reorderMonitors(layout, selectedMonitors);
               this.preDataSetModel.setDataValue("selectedMonitors", JSON.stringify(selectedMonitors));
               Logger.info("Display screen setting is : " + JSON.stringify(selectedMonitors), Logger.DISPLAY);
            });
         } catch (e) {
            Logger.info("Failed to use getDisplayLayout.");
         }
      } else {
         this.preDataSetModel.setDataValue("selectedMonitors", JSON.stringify(selectedMonitors));
      }
   };

   public saveAllDisplaySettingForApps = (allMonitors) => {
      try {
         chrome.system.display.getDisplayLayout((layout) => {
            allMonitors = this.reorderMonitors(layout, allMonitors);
            this.preDataSetModel.setDataValue("allValidMonitors", JSON.stringify(allMonitors));
            Logger.info("All Display screen setting is : " + JSON.stringify(allMonitors), Logger.DISPLAY);
         });
      } catch (e) {
         Logger.info("Failed to use getDisplayLayout.");
      }
   };

   public setSelectMonitors = (): Promise<void> => {
      return new Promise((resolve, reject) => {
         const settingScreen = this.getSettingScreen(),
            option = this.getDisplaySettingOption();
         this.getScreensInfo(option, settingScreen).then((content: any) => {
            let selectedMonitors = this.getSelectedMonitors(content, option);
            if (!this.hasAdjacentRect(selectedMonitors)) {
               this._showErrorMessage("MM_NONADJACENT_MONITOR");
               selectedMonitors = null;
            }

            if (selectedMonitors.length > 4) {
               this._showErrorMessage("MM_TOOMANY_MONITOR");
               selectedMonitors = null;
            }

            this.saveDisplaySetting(option, selectedMonitors, this.customWindowWidth, this.customWindowHeight);
            resolve();
         });
      });
   };

   public setAllMonitorsForApps = (): Promise<void> => {
      return new Promise((resolve, reject) => {
         const option = DISPLAY.DisplayOption.ALL;
         this.getScreensInfo(option, null).then((content: any) => {
            let allMonitors = this.getSelectedMonitors(content, option);
            if (!this.hasAdjacentRect(allMonitors)) {
               this._showErrorMessage("MM_NONADJACENT_MONITOR");
               allMonitors = null;
            }

            if (allMonitors.length > 4) {
               this._showErrorMessage("MM_TOOMANY_MONITOR");
               allMonitors = null;
            }

            this.saveAllDisplaySettingForApps(allMonitors);
            resolve();
         });
      });
   };

   private _showErrorMessage = (key) => {
      this.modalDialogService.showError({
         data: {
            titleKey: "ERROR",
            contentKey: key
         }
      });
   };
}
