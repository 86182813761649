/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import util from "../../jscdk/util";
import { Injectable, Injector } from "@angular/core";
import { BusEvent } from "../../../core/services/event";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { BaseViewService } from "../../common/service/base-view.service";
import { SessionDataService } from "../../common/service/session-data.service";
import { Ws1Service } from "../../common/service/ws1.service";
import { FullscreenService } from "../../utils/fullscreen-service";
import { WmksService } from "../common/wmks.service";
import { LogoutService } from "./logout.service";
import { LaunchUriService } from "../common/launch-uri.service";
import { XmlApiService } from "./xml-api.service";
import { clientUtil } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class HorizonLogoutService extends LogoutService {
   private _closeOnExit: boolean = false;

   constructor(
      private modalDialogService: ModalDialogService,
      private injector: Injector,
      private wmksService: WmksService,
      private xmlApi: XmlApiService,
      private sessionDataService: SessionDataService,
      private ws1Service: Ws1Service,
      private baseViewService: BaseViewService,
      private fullscreenService: FullscreenService,
      private launchUriService: LaunchUriService
   ) {
      super(injector);
      this._closeOnExit = !!this.launchUriService.urlParams.samlArt;
   }

   public logoutWithConfirm = (): void => {
      this.modalDialogService.showConfirm({
         data: {
            titleKey: "dialog_title_logout",
            contentKey: "dialog_confirm_logout"
         },
         callbacks: {
            confirm: this._logoutCallback
         }
      });
   };

   public logoutSilence = (): void => {
      this.wmksService.closeAllSessions();
      this.xmlApi
         .logout()
         .then(this.postLogoff)
         .catch(() => {
            this.postLogoff();
         });
   };

   private _logoutCallback = () => {
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));

      this.wmksService.quitMultimon();
      this.fullscreenService.exitFullscreen();
      this.logoutSilence();
   };

   /**
    *    postLogoff
    *
    * Page flow behavior after user logoff.
    *
    */
   public postLogoff = () => {
      if (clientUtil.isTitanClient()) {
         return;
      }
      if (this._closeOnExit) {
         // Horizon workspace will launch us into a separate tab, so we
         // want to close on exit in this case.

         // This won't work in any modern browser if the window
         // is not opened via script / a href.

         // Close the tab only work for IE browser. Move the close action
         // into the timeout to make sure the sessionData will be updated.
         // (SessionData will be updated in the callback of web socket)
         this.sessionDataService.clearRunningSession();
         window.open("", "_self", "");
         window.close();
         setTimeout(() => {
            //This timeout function will run in case window.close fails
            const ws1HostName = util.getWS1Hostname();
            if (ws1HostName) {
               this.ws1Service.redirectToWS1(ws1HostName, window);
            } else {
               this.baseViewService.gotoPortal();
            }
         }, 800);
      } else {
         this.baseViewService.gotoPortal();
      }
   };
}
