/**
 * ******************************************************
 * Copyright (C) 2017-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";

/**
 * abstract class for app image downloading.
 */

export interface ImageObjectURL {
   url: string;
   objURL: string;
}

export abstract class RemoteImageService {
   public logger = new Logger(Logger.IMAGE_CACHE);
   public objectURLs: Array<ImageObjectURL> = new Array<ImageObjectURL>();
   public abstract requestRemoteImageAndLoad(relativeImageUrl, element: HTMLElement): Promise<void>;

   protected searchForObjectURL = (imageUrl: string) => {
      for (let i = 0; i < this.objectURLs.length; i++) {
         const currentObjectURL = this.objectURLs[i];
         if (currentObjectURL.url === imageUrl) {
            return i;
         }
      }
      return -1;
   };

   protected createObjectURL = (imageUrl: string, blob: any) => {
      const searched = this.searchForObjectURL(imageUrl);
      if (searched !== -1) {
         return this.objectURLs[searched].objURL;
      }

      // Not found, create new one!
      const objURL = URL.createObjectURL(blob);
      this.objectURLs.push({
         url: imageUrl,
         objURL: objURL
      });
      return objURL;
   };
}
