/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

//Time map IANA link which contains the update of timezone location name
//Updated in 2020-04-23
//IANA official website reference link https://www.iana.org/time-zones
export const timezoneIANALink = {
   "Africa/Asmera": "Africa/Nairobi",
   "Africa/Timbuktu": "Africa/Abidjan",
   "America/Argentina/ComodRivadavia": "America/Argentina/Catamarca",
   "America/Atka": "America/Adak",
   "America/Buenos_Aires": "America/Argentina/Buenos_Aires",
   "America/Catamarca": "America/Argentina/Catamarca",
   "America/Coral_Harbour": "America/Atikokan",
   "America/Cordoba": "America/Argentina/Cordoba",
   "America/Ensenada": "America/Tijuana",
   "America/Fort_Wayne": "America/Indiana/Indianapolis",
   "America/Godthab": "America/Nuuk",
   "America/Indianapolis": "America/Indiana/Indianapolis",
   "America/Jujuy": "America/Argentina/Jujuy",
   "America/Knox_IN": "America/Indiana/Knox",
   "America/Louisville": "America/Kentucky/Louisville",
   "America/Mendoza": "America/Argentina/Mendoza",
   "America/Montreal": "America/Toronto",
   "America/Porto_Acre": "America/Rio_Branco",
   "America/Rosario": "America/Argentina/Cordoba",
   "America/Santa_Isabel": "America/Tijuana",
   "America/Shiprock": "America/Denver",
   "America/Virgin": "America/Port_of_Spain",
   "Antarctica/South_Pole": "Pacific/Auckland",
   "Asia/Ashkhabad": "Asia/Ashgabat",
   "Asia/Calcutta": "Asia/Kolkata",
   "Asia/Chongqing": "Asia/Shanghai",
   "Asia/Chungking": "Asia/Shanghai",
   "Asia/Dacca": "Asia/Dhaka",
   "Asia/Harbin": "Asia/Shanghai",
   "Asia/Kashgar": "Asia/Urumqi",
   "Asia/Katmandu": "Asia/Kathmandu",
   "Asia/Macao": "Asia/Macau",
   "Asia/Magadan": "Etc/GMT+11",
   "Asia/Rangoon": "Asia/Yangon",
   "Asia/Saigon": "Asia/Ho_Chi_Minh",
   "Asia/Tel_Aviv": "Asia/Jerusalem",
   "Asia/Thimbu": "Asia/Thimphu",
   "Asia/Ujung_Pandang": "Asia/Makassar",
   "Asia/Ulan_Bator": "Asia/Ulaanbaatar",
   "Atlantic/Faeroe": "Atlantic/Faroe",
   "Atlantic/Jan_Mayen": "Europe/Oslo",
   "Australia/ACT": "Australia/Sydney",
   "Australia/Canberra": "Australia/Sydney",
   "Australia/LHI": "Australia/Lord_Howe",
   "Australia/NSW": "Australia/Sydney",
   "Australia/North": "Australia/Darwin",
   "Australia/Queensland": "Australia/Brisbane",
   "Australia/South": "Australia/Adelaide",
   "Australia/Tasmania": "Australia/Hobart",
   "Australia/Victoria": "Australia/Melbourne",
   "Australia/West": "Australia/Perth",
   "Australia/Yancowinna": "Australia/Broken_Hill",
   "Brazil/Acre": "America/Rio_Branco",
   "Brazil/DeNoronha": "America/Noronha",
   "Brazil/East": "America/Sao_Paulo",
   "Brazil/West": "America/Manaus",
   "Canada/Atlantic": "America/Halifax",
   "Canada/Central": "America/Winnipeg",
   "Canada/East-Saskatchewan": "America/Regina",
   "Canada/Eastern": "America/Toronto",
   "Canada/Mountain": "America/Edmonton",
   "Canada/Newfoundland": "America/St_Johns",
   "Canada/Pacific": "America/Vancouver",
   "Canada/Saskatchewan": "America/Regina",
   "Canada/Yukon": "America/Whitehorse",
   "Chile/Continental": "America/Santiago",
   "Chile/EasterIsland": "Pacific/Easter",
   Cuba: "America/Havana",
   Egypt: "Africa/Cairo",
   Eire: "Europe/Dublin",
   "Etc/UCT": "Etc/UTC",
   "Europe/Belfast": "Europe/London",
   "Europe/Tiraspol": "Europe/Chisinau",
   GB: "Europe/London",
   "GB-Eire": "Europe/London",
   "GMT+0": "Etc/GMT",
   "GMT-0": "Etc/GMT",
   GMT0: "Etc/GMT",
   Greenwich: "Etc/GMT",
   Hongkong: "Asia/Hong_Kong",
   Iceland: "Atlantic/Reykjavik",
   Iran: "Asia/Tehran",
   Israel: "Asia/Jerusalem",
   Jamaica: "America/Jamaica",
   Japan: "Asia/Tokyo",
   Kwajalein: "Pacific/Kwajalein",
   Libya: "Africa/Tripoli",
   "Mexico/BajaNorte": "America/Tijuana",
   "Mexico/BajaSur": "America/Mazatlan",
   "Mexico/General": "America/Mexico_City",
   NZ: "Pacific/Auckland",
   "NZ-CHAT": "Pacific/Chatham",
   Navajo: "America/Denver",
   PRC: "Asia/Shanghai",
   "Pacific/Johnston": "Pacific/Honolulu",
   "Pacific/Ponape": "Pacific/Pohnpei",
   "Pacific/Samoa": "Pacific/Pago_Pago",
   "Pacific/Truk": "Pacific/Chuuk",
   "Pacific/Yap": "Pacific/Chuuk",
   Poland: "Europe/Warsaw",
   Portugal: "Europe/Lisbon",
   "Pacific/Tongatapu": "Tonga",
   ROC: "Asia/Taipei",
   ROK: "Asia/Seoul",
   Singapore: "Asia/Singapore",
   Turkey: "Europe/Istanbul",
   UCT: "Etc/UTC",
   "US/Alaska": "America/Anchorage",
   "US/Aleutian": "America/Adak",
   "US/Arizona": "America/Phoenix",
   "US/Central": "America/Chicago",
   "US/East-Indiana": "America/Indiana/Indianapolis",
   "US/Eastern": "America/New_York",
   "US/Hawaii": "Pacific/Honolulu",
   "US/Indiana-Starke": "America/Indiana/Knox",
   "US/Michigan": "America/Detroit",
   "US/Mountain": "America/Denver",
   "US/Pacific": "America/Los_Angeles",
   "US/Samoa": "Pacific/Pago_Pago",
   UTC: "Etc/UTC",
   Universal: "Etc/UTC",
   "W-SU": "Europe/Moscow",
   Zulu: "Etc/UTC"
};

export const timezoneMap = {
   "-12:00": "Etc/GMT-12",
   "-11:00": "Etc/GMT-11",
   "-10:00": "Pacific/Honolulu",
   "-09:30": "Pacific/Marquesas",
   "-09:00": "America/Anchorage",
   "-08:00": "America/Los_Angeles",
   "-07:00": "America/Denver",
   "-06:00": "America/Chicago",
   "-05:00": "America/New_York",
   "-04:00": "America/Asuncion",
   "-03:30": "America/St_Johns",
   "-03:00": "America/Sao_Paulo",
   "-02:00": "America/Noronha",
   "-01:00": "Atlantic/Azores",
   "-00:00": "Europe/London",
   "+01:00": "Europe/Budapest",
   "+02:00": "Europe/Kiev",
   "+03:00": "Europe/Moscow",
   "+03:30": "Asia/Tehran",
   "+04:00": "Asia/Dubai",
   "+04:30": "Asia/Kabul",
   "+05:00": "Asia/Tashkent",
   "+05:30": "Asia/Calcutta",
   "+05:45": "Asia/Katmandu",
   "+06:00": "Asia/Almaty",
   "+06:30": "Asia/Rangoon",
   "+07:00": "Asia/Bangkok",
   "+08:00": "Asia/Shanghai",
   "+08:30": "Asia/Pyongyang",
   "+08:45": "Australia/Eucla",
   "+09:00": "Asia/Tokyo",
   "+09:30": "Australia/Darwin",
   "+10:00": "Australia/Sydney",
   "+10:30": "Australia/Lord_Howe",
   "+11:00": "Etc/GMT+11",
   "+12:00": "Pacific/Fiji",
   "+12:45": "Pacific/Chatham",
   "+13:00": "Tonga",
   "+14:00": "Pacific/Honolulu"
};
