/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * file-transfer-util.service.ts
 */
import { Injectable } from "@angular/core";

import { MKSVCHAN_CONST } from "../channels/MKSVchan/mksvchan-consts";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { MksvchanService } from "../channels/mksvchan.service";
import { MKSVchan } from "../channels/mksvchan";

@Injectable({
   providedIn: "root"
})
export class FileTransferUtilService {
   constructor(
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private mksvchanService: MksvchanService
   ) {}
   public isFileTransferringForAllSession = () => {
      let mksVchanClient: MKSVchan.Client, uploadService, downloadService;
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [, wmksInstance] of wmksSessionMap) {
         mksVchanClient = this.mksvchanService.getClient(wmksInstance.key);
         if (mksVchanClient) {
            uploadService = mksVchanClient.uploadService;
            if (mksVchanClient.downloadServices) {
               downloadService = mksVchanClient.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT];
            }
         }
         if (
            (!!uploadService && uploadService.isTransferringFileWithNoDialog()) ||
            (!!downloadService && downloadService.isTransferringFileWithNoDialog())
         ) {
            return true;
         }
      }
      return false;
   };

   public isFileTransferringInSession = (wmksKey) => {
      let mksVchanClient: MKSVchan.Client = this.mksvchanService.getClient(wmksKey),
         uploadService,
         downloadService;

      if (mksVchanClient) {
         uploadService = mksVchanClient.uploadService;
         if (mksVchanClient.downloadServices) {
            downloadService = mksVchanClient.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT];
         }
      }
      return (
         (!!uploadService && uploadService.isTransferringFileWithNoDialog()) ||
         (!!downloadService && downloadService.isTransferringFileWithNoDialog())
      );
   };

   public isFileDownloadingInSession = (wmksKey) => {
      let mksVchanClient = this.mksvchanService.getClient(wmksKey),
         downloadService;

      if (!!mksVchanClient && !!mksVchanClient.downloadServices) {
         downloadService = mksVchanClient.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT];
      }
      return !!downloadService && downloadService.isTransferringFileWithNoDialog();
   };

   public isFileDownloadingInCurrentSession = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession || !currentSession.key) {
         return false;
      }
      return this.isFileDownloadingInSession(currentSession.key);
   };
}
