/**
 * *****************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Logger } from "@html-core";

export class HTML5FileSystemFileHandler {
   private name;
   private isDirectory: boolean = false;
   private isFile: boolean = true;
   private fullPath: string;
   private metaData;
   private parentHandler;
   private fileHandler;
   private fileEntry;
   private parentPath;
   public removeNodeInParent;
   public writer = {
      handler: null,
      addedInCache: false
   };

   constructor() {}

   public init = async (handler, directoryEntry) => {
      this.fileEntry = await handler.getFile();
      this.name = this.fileEntry.name;
      this.fullPath = "/" + this.name;
      this.parentHandler = directoryEntry.directoryHandler;
      this.fileHandler = await this.parentHandler.getFileHandle(this.name, { create: false });
      this.parentPath = directoryEntry.fullPath;
      this.metaData = {
         path: this.parentPath + "/" + this.fileEntry.name,
         name: this.fileEntry.name,
         size: this.fileEntry.size,
         type: this.fileEntry.type,
         webkitRelativePath: this.fileEntry.webkitRelativePath,
         lastModified: this.fileEntry.lastModified,
         modificationTime: this.fileEntry.lastModifiedDate
      };
   };

   public file = (FuncOnLoad, FuncOnError?) => {
      return new Promise((resolve, reject) => {
         if (FuncOnLoad) {
            FuncOnLoad(this.fileEntry);
            resolve("");
         }
      });
   };

   public createWriter = async (FuncOnLoad, FuncOnError?) => {
      const permission = await this.fileHandler.requestPermission({ mode: "readwrite" });
      if (permission === "granted") {
         if (!this.writer.handler) {
            this.writer.handler = await this.fileHandler.createWritable({ keepExistingData: true });
         }
         return new Promise(async (resolve, reject) => {
            if (FuncOnLoad) {
               FuncOnLoad(this.writer.handler);
               this.fileEntry = await this.fileHandler.getFile();
               if (this.fileEntry.size !== this.metaData.size) {
                  this.metaData.size = this.fileEntry.size;
               }
               resolve("");
            }
         });
      } else {
         Logger.info("CreateWriter: user denied permission.");
      }
   };

   public getMetadata = async (onLoad, FuncOnError) => {
      try {
         onLoad(this.metaData);
      } catch (e) {
         FuncOnError(e);
      }
   };

   public remove = (onSucceed, onError) => {
      try {
         this.parentHandler
            .removeEntry(this.name)
            .then(() => {
               this.removeNodeInParent(this.name);
               onSucceed();
            })
            .catch((e) => {
               onError(e);
            });
      } catch (e) {
         onError(e);
      }
   };

   public removeRecursively = (onSucceed, onError) => {
      try {
         this.parentHandler.removeEntry(this.name, { recursive: true }).then(() => {
            onSucceed();
         });
      } catch (e) {
         onError(e);
      }
   };

   public moveTo = async (targetFolderEntry, targetFileName, onSuccess, onError) => {
      const isRename =
         this.parentHandler.isSameEntry(targetFolderEntry.directoryHandler) &&
         this.parentHandler.name === targetFolderEntry.name;
      if (isRename) {
         Logger.debug("rename a file");
         this.renameFile(targetFileName)
            .then(() => {
               this.fullPath = this.fullPath.replace(this.name, targetFileName);
               this.metaData.path = this.metaData.path.replace(this.name, targetFileName);
               this.name = targetFileName;
               this.metaData.name = targetFileName;
               onSuccess(targetFolderEntry);
            })
            .catch((e) => {
               onError(e);
            });
      } else {
         const targetHandler = targetFolderEntry.directoryHandler;
         //Todo
         this.moveFile(targetHandler, targetFileName)
            .then(() => {
               onSuccess(targetFolderEntry);
            })
            .catch((e) => {
               onError(e);
            });
         Logger.debug("move node to another folder");
      }
   };

   private renameFile = (targetFileName) => {
      return new Promise((resolve, reject) => {
         this.fileHandler
            .move(targetFileName)
            .then(() => {
               resolve(true);
            })
            .catch((e) => {
               reject(e);
            });
      });
   };

   private moveFile = (target, name) => {
      return new Promise((resolve, reject) => {
         this.fileHandler
            .move(target, name)
            .then(() => {
               resolve(true);
            })
            .catch((e) => {
               reject(e);
            });
      });
   };
}
