/**
 * ******************************************************
 * Copyright (C) 2015-2020, 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview mediaCapture.ts -- MediaCapture
 * Class based on AudioCaptuer & VideoCapture to handle media capturing
 * depend on AudioCapture, VideoCapture, and SyncTimer
 *
 * the callback is used to pull the next video frame, while audio need no such
 *    an property since it push the data out.
 *
 * API:
 * No init API, after new or clear, the instance should be in the started
 *    status
 * We need to detect stopstream instead of dealing with that message is caused
 *    by the fact that the agent doesn't implement that message.
 *
 * open: get device
 * close: release device, Close the instance with all hardware resources
 *    released.
 * startStream: get device and ready to capture any data
 * stopCapturing: reset and return to the original status(started) after
 *    created
 * enable: enable the capturing of data of specified type
 * disable: disable the capturing of data of specified type
 */
import { Injectable } from "@angular/core";
import { AudioCapture } from "./audioCapture";
import { VideoCapture } from "./videoCapture";
import { SyncTimer } from "./synctimer";
import { PreferredRTAVDeviceService } from "../../common/service/preferred-RTAV-device.service";
import { Logger } from "@html-core";

@Injectable()
export class MediaCapture {
   private supportHTML5Audio;
   private MediaStream;
   private hasBlockedStartStream;
   private streamStarted;
   private isEnabled;
   private resourcesHold;
   private localStream;

   constructor(
      private audioCapture: AudioCapture,
      private videoCapture: VideoCapture,
      private syncTimer: SyncTimer,
      private preferredRTAVDeviceService: PreferredRTAVDeviceService
   ) {
      window.AudioContext =
         window.AudioContext ||
         //@ts-ignore
         window.webkitAudioContext ||
         //@ts-ignore
         window.mozAudioContext;
      //@ts-ignore
      navigator.getUserMedia =
         //@ts-ignore
         navigator.getUserMedia ||
         //@ts-ignore
         navigator.webkitGetUserMedia ||
         //@ts-ignore
         navigator.mozGetUserMedia ||
         //@ts-ignore
         navigator.msGetUserMedia ||
         navigator.mediaDevices.getUserMedia;
      //@ts-ignore
      this.supportHTML5Audio = !!window.AudioContext && !!navigator.getUserMedia;
      if (!this.supportHTML5Audio) {
         Logger.error("browser does not support HTML5 media in, creating media source fails", Logger.RTAV);
         return null;
      }
      Logger.debug("browser supports HTML5 media in", Logger.RTAV);
      /**
       * MediaStream is an experimental technology, so add this compatible codes
       * to provide the same API for the new and old browsers
       */
      //@ts-ignore
      this.MediaStream = window.MediaStream || window.webkitMediaStream;
      if (this.MediaStream !== undefined) {
         if (!this.MediaStream.prototype.hasOwnProperty("stop")) {
            this.MediaStream.prototype.stop = (stream) => {
               stream.getAudioTracks().forEach((track) => {
                  track.stop();
               });
               stream.getVideoTracks().forEach((track) => {
                  track.stop();
               });
            };
         }
      }
      this.hasBlockedStartStream = false;
      if (!this.audioCapture || !this.videoCapture || !this.syncTimer) {
         Logger.error("capture instance is not created, creating media source fails", Logger.RTAV);
         return null;
      }
      this.streamStarted = false;
      this.isEnabled = {
         audio: false,
         video: false
      };
      this.resourcesHold = {
         audio: false,
         video: false
      };
      this.localStream = {
         audio: null,
         video: null
      };
      Logger.debug("capture instance initialized", Logger.RTAV);
   }

   /**
    * Stop both video and audio if possible.
    * @private
    */
   private disableAll = () => {
      if (this.isEnabled.audio) {
         this.disable("audio");
      }
      if (this.isEnabled.video) {
         this.disable("video");
      }
   };

   /**
    * Disable the capturing of data of specified type
    * @param  {string} type This param is one of the 'audio' and 'video'.
    * @returns {Promise<boolean>} whether action succeeded
    */
   public disable = (type) => {
      return new Promise((resolve) => {
         if (!this.resourcesHold[type]) {
            Logger.debug("call disable when no device hold, video device might be missing", Logger.RTAV);
         }
         Logger.debug(
            "disable " + type + " when stream " + (this.streamStarted ? "have" : "haven't") + " started",
            Logger.RTAV
         );
         if (this.streamStarted) {
            if (type === "audio") {
               this.audioCapture.stop();
            } else if (type === "video") {
               this.videoCapture.stop();
            }
            this.close(type);
         } else {
            Logger.debug("Negative: no stream started when disable " + type, Logger.RTAV);
            resolve(false);
            return;
         }
         Logger.debug("media source working status had been changed to disable for " + type, Logger.RTAV);
         this.isEnabled[type] = false;
         /**
          * this code is to detect stopStream
          * The agent implement the stopstream this way, so client need to do work
          * accordingly. if detected, the source will stop working but that can be
          * recoveried by enable audio/video and startstream again.
          */
         if (!this.isEnabled.audio && !this.isEnabled.video) {
            this.streamStarted = false;
            Logger.debug("stop capture stream since neither audio nor video are used", Logger.RTAV);
            this.stopCapturing();
         }
         Logger.debug("media source disable done for " + type, Logger.RTAV);
         resolve(true);
      });
   };

   /**
    * Close the instance with all hardware resources released.
    * @param  {string} type The type name of device of the request
    */
   public close = (type) => {
      if (!this.resourcesHold[type]) {
         Logger.debug("no need to close " + type + " device", Logger.RTAV);
         this.localStream[type] = null;
         return;
      }
      if (!!this.localStream[type] && typeof this.localStream[type].stop === "function") {
         this.localStream[type].stop(this.localStream[type]);
         Logger.debug("rtav source is closed, " + type + " hardware get released", Logger.RTAV);
      } else {
         Logger.debug("rtav source is closed, but " + type + " hardware might fail to be released", Logger.RTAV);
      }
      this.localStream[type] = null;
      this.resourcesHold[type] = false;
      this.hasBlockedStartStream = false;
   };

   /**
    * Stop capture audio and video, and release the devices
    * after clear this instance should look like a new object
    */
   public stopCapturing = () => {
      // ensure stop
      if (this.streamStarted) {
         Logger.debug(
            "negative: there is at least one of stream capture session, so disable them before stopping capture",
            Logger.RTAV
         );
         this.disableAll();
      }
      // clear audio/video capture
      this.syncTimer.clear();
      if (this.audioCapture.getStatus() !== this.audioCapture.statusEnum["Uninited"]) {
         this.audioCapture.clear();
         Logger.debug("audio stream stopped", Logger.RTAV);
      }
      if (this.videoCapture.getStatus() !== this.videoCapture.statusEnum["Uninited"]) {
         this.videoCapture.clear();
         Logger.debug("video stream stopped", Logger.RTAV);
      }
      this.streamStarted = false;
      this.hasBlockedStartStream = false;
      this.isEnabled.audio = false;
      this.isEnabled.video = false;
      Logger.debug("capture stream stopped", Logger.RTAV);
   };

   /**
    * Async process to get control of audio & video devices
    * @param {string} type The device type name of open request
    * @param {function} resolve The callback with a bool as open success or not
    * @param {object} deviceMessageController This param is used to emit
    *    permission dialog related events
    */
   public open = (type, resolve, deviceMessageController) => {
      let deviceRequest = {
            audio: false,
            video: false
         },
         successCallback,
         errorCallback;
      successCallback = (stream) => {
         Logger.debug("obtain the rtav device for " + type, Logger.RTAV);
         this.localStream[type] = stream;
         this.resourcesHold[type] = true;
         resolve(true);
         deviceMessageController.onPermissionHandled(type);
      };
      errorCallback = (e) => {
         Logger.debug("negative: can't get the rtav device, so rtav will not working" + e, Logger.RTAV);
         resolve(false);
         deviceMessageController.onPermissionHandled(type);
      };
      this.preferredRTAVDeviceService.getPreferredDeviceId(type).then((preferredId) => {
         if (preferredId) {
            deviceRequest[type] = {
               deviceId: preferredId
            };
         } else {
            deviceRequest[type] = true;
         }
         Logger.debug("requesting device with request: " + JSON.stringify(deviceRequest), Logger.RTAV);
         if (!this.resourcesHold[type]) {
            //@ts-ignore
            navigator.getUserMedia(deviceRequest, successCallback, errorCallback);
            deviceMessageController.onPermissionAsked(type);
         }
      });
   };

   /**
    * Start capture audio and video
    * This function fit for the message startStream, and will ensure both the
    * audio and video source inited
    * @param  {function} audioCallback The callback function used to deal with
    *    captured audio
    * @param  {function} videoCallback The callback function used to deal with
    *    captured video
    */
   public startStream = (deviceParam, audioCallback, videoCallback) => {
      if (this.streamStarted) {
         Logger.debug('the media capture has already being "streamStarted" status, skip start', Logger.RTAV);
         return;
      }
      if (!this.isEnabled.audio && !this.isEnabled.video) {
         Logger.debug("previous hasBlockedStartStream is " + this.hasBlockedStartStream, Logger.RTAV);
         this.hasBlockedStartStream = true;
         Logger.error(
            "Negative: the media capture try to start with none of the video nor audio enabled, would check again when device obtained",
            Logger.RTAV
         );
         return;
      }

      if (!this.resourcesHold.audio && !this.resourcesHold.video) {
         Logger.debug(
            "no stream hold before trying to start capturing, please check whether video/audio device exist",
            Logger.RTAV
         );
         return;
      }

      Logger.debug("starting the source stream", Logger.RTAV);

      this.syncTimer.reset();
      this.audioCapture.init(deviceParam.audio, this.syncTimer, (sample) => {
         audioCallback(sample);
      });
      this.videoCapture.init(deviceParam.video, this.syncTimer, (sample) => {
         videoCallback(sample);
      });

      // we need to start start the audio/video capturing if that is enabled
      if (this.isEnabled.audio) {
         Logger.debug("start capturing audio data", Logger.RTAV);
         this.audioCapture.start(this.localStream["audio"]);
      }
      if (this.isEnabled.video) {
         Logger.debug("start capturing video data", Logger.RTAV);
         this.videoCapture.start(this.localStream["video"]);
      }
      this.streamStarted = true;
   };

   /**
    * Enable the capturing of data of specified type
    * @param  {string} type This param is one of the 'audio' and 'video'.
    * @param  {object} deviceMessageController This param is used to emit
    *    permission dialog related events
    * @returns {Promise<boolean>} whether action succeeded
    */
   public enable = (type, deviceMessageController) => {
      return new Promise((resolve) => {
         if (this.isEnabled[type]) {
            Logger.error("Negative: try to re-enable a media source of type " + type, Logger.RTAV);
            resolve(false);
            return;
         }

         this.open(
            type,
            (success) => {
               if (success) {
                  if (this.streamStarted) {
                     if (type === "audio") {
                        this.audioCapture.start(this.localStream[type]);
                     } else if (type === "video") {
                        this.videoCapture.start(this.localStream[type]);
                     }
                  }
                  Logger.debug("media source working status changed to enable for " + type, Logger.RTAV);
                  this.isEnabled[type] = true;
               }
               resolve(success);
            },
            deviceMessageController
         );
      });
   };

   /**
    * return whether the device of given type is active
    * @param  {string} type This param is one of the 'audio' and 'video'.
    */
   public isActive = (type) => {
      if (type === "audio") {
         return this.audioCapture.isActive();
      } else if (type === "video") {
         return this.videoCapture.isActive();
      }
      return false;
   };
}
