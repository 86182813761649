/**
 * ******************************************************
 * Copyright (C) 2018 - 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../../core/libs/logger";
import { MediaManager } from "../../media/media-manager";
import { MediaType } from "../../media/media-resource-manager";
import ResizeObserver from "resize-observer-polyfill";

import {
   CreateInstance,
   MediaTrkStop,
   NewVideoElement,
   NotifyAudio,
   OverlayInfo,
   RemoveVideo,
   UpdateDPI,
   UpdateOverlay,
   UpdateVideo,
   UpdateVolume,
   VideoItem,
   VMPoint,
   VMSize
} from "./model/html5MMR.media.models";
import { LocalStorageService } from "@html-core";
import { HTML5MMR_CONST } from "./html5MMR-consts";

/**
 * HTML5 MMR Media Service
 */
export namespace Html5MMR {
   @Injectable({
      providedIn: "root"
   })
   export class MediaService {
      private logger = new Logger(Logger.WEBRTCMEDIA);

      private dpi: number = 0;
      private guestOrigin: VMPoint = new VMPoint(0, 0);
      private supportObjectFit: boolean = false;
      private mVideos: Map<number, VideoItem> = new Map<number, VideoItem>();
      private resizeObserver: ResizeObserver;

      /**
       * Create an instance of HTML5 MMR Media Service
       *
       * @param  {MediaManager} mediaManager
       */
      constructor(
         private mediaManager: MediaManager,
         private localStorageService: LocalStorageService
      ) {
         this.logger.info("Html5MMR WebRTCMedia created");
         this.resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((each) => {
               if (each && each.target && each.contentRect) {
                  this.mediaManager.updateOverlaySize(
                     MediaType.video,
                     each.target.id,
                     new VMSize(each.contentRect.width, each.contentRect.height)
                  );
               }
            });
         });
      }

      /**
       * This is acting as destructor. It is responsible for cleaning up all media (video|audio) resources.
       */
      public clear = () => {
         this.mVideos.clear();
         this.mediaManager.clear();
      };

      /**
       * Handles play or pause the ringtone.
       *
       * @param  {NotifyAudio.Request} request request to handle play/pause the ringtone via media manager
       */
      public notifyAudio = (request: NotifyAudio.Request) => {
         const action = request.action;

         switch (action) {
            case NotifyAudio.Action.play:
               this.mediaManager.playMediaSource(MediaType.audio, request.audioId, request.src, request.loop);
               break;
            case NotifyAudio.Action.pause:
               this.mediaManager.pauseMedia(MediaType.audio, request.audioId);
               break;
         }
      };

      /**
       * Play Binar notify audio data
       *
       * @param {number} audioId, audio id to find audio element
       * @param {Uint8Array} audioData
       */
      public onBinaryNotifyAudio = (audioId: number, audioData: Uint8Array) => {
         this.mediaManager.onBinaryNotifyAudio(audioId, audioData);
      };

      /**
       * Update global volume and mute status
       *
       * @param  {UpdateVolumn.Request} request
       */
      public updateVolume = (request: UpdateVolume.Request) => {
         this.mediaManager.updateVolume(request.mute, request.volume);
      };

      /**
       * Update DPI and relavant parameters for each video element
       *
       * @param  {UpdateDPI.Request} request
       */
      public handleUpdateDPI = (request: UpdateDPI.Request) => {
         // TODO: handle update DPI
         this.logger.info(
            "OnUpdateDPI,  dpi: " +
               request.dpi +
               ", [b-x, b-y, g-x, g-y] = [" +
               request.boundingCX +
               ", " +
               request.boundingCY +
               " , " +
               request.guestOriginX +
               " , " +
               request.guestOriginY +
               "]"
         );
         // Todo: We might need DPI translation. Do nothing now.
         this.dpi = request.dpi;
         this.guestOrigin = new VMPoint(request.guestOriginX, request.guestOriginY);
         this.mVideos.forEach((video) => {
            const params = video.updateGuestOrigin(this.guestOrigin.x, this.guestOrigin.y);
            this.mediaManager.updateElement(MediaType.video, String(video.videoId), params);
         });
      };

      /**
       * Handler for updating a single video overlay
       *
       * @param  {UpdateOverlay.Request} request
       */
      public handleUpdateOverlay = (request: UpdateOverlay.Request) => {
         // TODO: handle update overlay
         this.logger.info(
            "[vid=" +
               request.videoId +
               "] OnUpdateOverlay visible=" +
               request.visible +
               ", [l, t, w, h] = [" +
               request.left +
               ", " +
               request.top +
               ", " +
               request.width +
               ", " +
               request.height +
               ", rgnCount = " +
               request.count +
               "]"
         );
         if (!this.mVideos.has(request.videoId)) {
            this.logger.error("onUpdateOverlay failed as video item cannot be found: " + request.videoId);
            return;
         }
         const video = this.mVideos.get(request.videoId);
         const params = video.updateOverlayInfo(new OverlayInfo(request));
         this._updateVideoItemsZIndex();
         this.mediaManager.updateElement(MediaType.video, String(video.videoId), params);
      };

      /**
       * Process/Cache media (initial) configuration parameters upon create instance
       *
       * @param  {CreateInstance.Request} request
       */
      public handleCreateInstance = (request: CreateInstance.Request) => {
         // TODO: handle create instance related parameters
         this.logger.debug("onCreateInstance: " + JSON.stringify(request));
         this.guestOrigin = new VMPoint(request.guestOriginX, request.guestOriginY);
         this.supportObjectFit = request.supportObjectFit;
      };

      /**
       * Create a new video element by given {NewVideoElement.Request}
       *
       * @param  {NewVideoElement.Request} request
       * @returns HTMLMediaElement
       */
      public createNewVideoElement = (instanceId: number, request: NewVideoElement.Request): HTMLMediaElement => {
         const videoId = String(request.videoId),
            videoItem = this.makeVideoItem(instanceId, request.videoId, this.supportObjectFit),
            mediaElement = this.mediaManager.createElement(MediaType.video, videoId, request) as HTMLVideoElement;

         this.mVideos.set(videoItem.videoId, videoItem);

         const statsOverlayEnabled =
            this.localStorageService.get(HTML5MMR_CONST.STATS_OVERLAY_LOCAL_STORAGE_KEY) === "true";
         if (statsOverlayEnabled) {
            mediaElement.ontimeupdate = () => {
               this.mediaManager.updateMediaStreamTrackStat(MediaType.video, mediaElement.id);
            };
            this.resizeObserver.observe(mediaElement);
         }

         return mediaElement;
      };

      /**
       * Create a new audio element by given audio id
       *
       * @param  {audioId} number
       * @returns HTMLMediaElement
       */
      public createNewAudioElement = (audioId: number) => {
         return this.mediaManager.createElement(MediaType.audio, String(audioId), {});
      };

      /*
       * Helper function that creates a new video item
       *
       * @param  {number} instanceId
       * @param  {number} videoId
       * @param  {boolean} supportObjectFit
       */
      private makeVideoItem = (instanceId: number, videoId: number, supportObjectFit: boolean) => {
         return new VideoItem(instanceId, videoId, this.guestOrigin, supportObjectFit);
      };

      /**
       * Add new media stream
       *
       * @param  {MediaStream} stream
       */
      public addStream = (stream: MediaStream) => {
         this.mediaManager.addStream(stream);
      };

      /**
       * Create a new HTMLMediaElement by media type, id and other parameters
       *
       * @param  {MediaType} type   given media type. Possible values: audio|video
       * @param  {string} id  given media html element id
       * @param  {any} params extra parameters specified to describe the media element init state
       *                      e.g. for video element, might need to specify its initial location and size
       * @returns HTMLMediaElement or undefined if element cannot be created
       */
      public createElement = (type, id, params) => {
         return this.mediaManager.createElement(type, id, params);
      };

      /**
       * Retrieve media element based on media type and given Id
       *
       * @param  {MediaType} type   given media type. Possible values: audio|video
       * @param  {string} id given media html element id
       * @returns HTMLMediaElement  or undefined if element cannot be created
       */
      public getElement = (type, id) => {
         return this.mediaManager.getElement(type, id);
      };

      /**
       * Update media stream by given stream id
       *
       * @param  {string} sId
       * @param  {MediaStream} stream
       */
      public updateStream = (sId: string, stream: MediaStream) => {
         this.mediaManager.updateStream(sId, stream);
      };

      /**
       * Update media stream track by given track id
       *
       * @param  {string} tId
       * @param  {MediaStreamTrack} track
       */
      public updateTrack = (tId: string, track: MediaStreamTrack) => {
         this.mediaManager.updateTrack(tId, track);
      };

      /**
       * Enable media stream track by given track id
       *
       * @param  {string} tId
       * @param  {boolean} enabled
       */
      public enableTrack = (tId: string, enabled: boolean) => {
         this.mediaManager.enableTrack(tId, enabled);
      };

      /**
       * Update source object by given stream id and element id
       *
       * @param  {MediaType} mediaType
       * @param  {string} streamId
       * @param  {string} elementId
       */
      public updateSrcObject = (mediaType: MediaType, streamId: string, elementId: string) => {
         this.mediaManager.updateSrcObject(mediaType, streamId, elementId);
      };

      /**
       * Update video element by {UpdateVideo.Request}
       *
       * @param  {UpdateVideo.Request} request
       */
      public updateVideoElement = (request: UpdateVideo.Request) => {
         const updateRequest = this._updateVideoRequest(request);
         this._updateVideoItemsZIndex();
         if (!updateRequest) {
            return;
         }

         this.logger.info(
            "[VideoItem] [x, y, w, h] = [" +
               updateRequest.left +
               ", " +
               updateRequest.top +
               ", " +
               updateRequest.width +
               ", " +
               updateRequest.height +
               "]"
         );

         this.mediaManager.updateElement(MediaType.video, String(updateRequest.videoId), updateRequest);
      };

      /**
       * Helper function that update size/position for given ${UpdateVideo.Request}
       *
       * @param  {UpdateVideo.Request} request
       * @returns {UpdateVideo.Request} or null if video element does not exist anymore
       */
      private _updateVideoRequest = (request: UpdateVideo.Request): UpdateVideo.Request => {
         const videoItem: VideoItem = this.mVideos.get(request.videoId);
         if (!videoItem) {
            this.logger.error(`video item ${request.videoId} does not exist!`);
            return null;
         } else if (!videoItem.overlayInfo) {
            this.logger.warning(
               `video item ${request.videoId} overlay info is missing. Use request parameters to update size/position.`
            );
            return request;
         }

         request.left = videoItem.overlayInfo.left;
         request.top = videoItem.overlayInfo.top;
         request.width = videoItem.overlayInfo.width;
         request.height = videoItem.overlayInfo.height;

         return request;
      };

      /**
       * Helper function that update zindex for each video item from cache
       * Note that based on MS teams Doc:
       */
      private _updateVideoItemsZIndex = () => {
         const sortedVideos: Array<VideoItem> = Array.from(this.mVideos.values()).sort(
            (video1: VideoItem, video2: VideoItem) => {
               return video1.area - video2.area;
            }
         );
         sortedVideos.forEach((video: VideoItem, index: number) => {
            this.mediaManager.updateElement(MediaType.video, String(video.videoId), {
               zIndex: Math.max(20 - index, 0)
            });
         });
      };

      /**
       * Delete video element by {RemoveVideo.Request}
       *
       * @param  {RemoveVideo.Request} request
       */
      public removeVideoElement = (request: RemoveVideo.Request) => {
         this.mVideos.delete(request.videoId);

         const videoElement = this.mediaManager.getElement(MediaType.video, String(request.videoId));
         if (videoElement) {
            videoElement.ontimeupdate = null;
            this.resizeObserver.unobserve(videoElement);
            this.mediaManager.deleteElement(MediaType.video, String(request.videoId));
         }
      };

      /**
       * Stop media track by given {MediaTrkStop.Request}
       *
       * @param  {MediaTrkStop.Request} request
       */
      public stopTrack = (request: MediaTrkStop.Request) => {
         this.mediaManager.stopTrack(request.trkid);
      };

      /**
       * Retrieve media stream by stream id
       *
       * @param  {string} sId
       * @returns MediaStream
       */
      public getStream = (sId: string): MediaStream => {
         return this.mediaManager.getStream(sId);
      };

      /**
       * Retrieve media track by track id
       *
       * @param  {string} sId
       * @returns MediaStreamTrack
       */
      public getTrack = (tId: string): MediaStreamTrack => {
         return this.mediaManager.getTrack(tId);
      };

      /**
       * Perform get user media. Wrapper of navigator.getUserMedia
       * https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
       *
       * @param  {MediaStreamConstraints} constraints
       * @returns Promise
       */
      public getUserMedia = (constraints: MediaStreamConstraints): Promise<MediaStream> => {
         return this.mediaManager.getUserMedia(constraints);
      };

      /**
       * Hide all video overlay element while active wmks session changed
       * @param  {toDisplay} boolean
       */
      public updateAllVideoElementsDisplay = (toDisplay) => {
         this.mVideos.forEach((video, videoId) => {
            const videoElement = this.mediaManager.getElement(MediaType.video, videoId.toString());
            videoElement.style.display = toDisplay ? "initial" : "none";
         });
      };
   }
}
