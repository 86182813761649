/**
 * *****************************************************
 * Copyright 2020-2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AjaxBusyOverlayComponent } from "./common/ajax-busy-overlay.service";
import { ClientCommonModule } from "../../shared/common/client-shared.common.module";
import { FormsModule } from "@angular/forms";
import { DesktopComponent } from "./desktop.component";
import { RemoteFeatureModule } from "../rx/rx.module";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { VerticalSliderDirective } from "./sidebar/vertical-slider.directive";
import { RunningItemsPipe } from "./sidebar/running-items.pipe";
import { RouterModule } from "@angular/router";
import { LoginModule } from "../login/login.module";
import { ReauthDialogService } from "./re-auth/reauth-dialog.service";
import { ReauthDialogComponent } from "./re-auth/reauth-dialog.component";
import { DeactivateGuardService } from "../../shared/view/deactive-guard.service";
import { SidebarGlobalComponent } from "./sidebar/sidebar-global-menu/sidebar-global-menu.component";
import { SidebarHeaderComponent } from "./sidebar/sidebar-header/sidebar-header.component";
import { IdleComponent } from "./idle/idle.component";
import { SidebarPerfTrackerComponent } from "./sidebar/sidebar-perf-tracker/sidebar-perf-tracker.component";
import { SidebarEntitledItems } from "./sidebar/sidebar-entitled-items/sidebar-entitled-items.component";

@NgModule({
   imports: [
      CommonModule,
      ClientCommonModule,
      FormsModule,
      RemoteFeatureModule,
      LoginModule,
      RouterModule.forChild([
         {
            path: "desktop",
            component: DesktopComponent,
            canDeactivate: [DeactivateGuardService]
         }
      ])
   ],
   declarations: [
      AjaxBusyOverlayComponent,
      ReauthDialogComponent,
      SidebarComponent,
      SidebarGlobalComponent,
      SidebarHeaderComponent,
      SidebarPerfTrackerComponent,
      IdleComponent,
      SidebarEntitledItems,
      VerticalSliderDirective,
      RunningItemsPipe,
      DesktopComponent
   ],
   providers: [ReauthDialogService],
   exports: [SidebarComponent, AjaxBusyOverlayComponent]
})
export class DesktopModule {}
