/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getDesktopsCommand.js --
 *
 * get desktop list.
 *
 */

import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import GetDesktopsHandler from "../controllers/getDesktopsHandler";

export default function GetDesktopsCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param getDesktopsAction[in]
 *        - its property 'method' has the value 'GetDesktops'
 */
GetDesktopsCommand.prototype.execute = function (getDesktopsAction) {
   let getDesktopsObject,
      handlerList,
      supportedProtocols,
      router = globalArray["router"];

   getDesktopsObject = globalArray["get-desktops"];
   if (!getDesktopsObject) {
      getDesktopsObject = new GetDesktopsHandler();
      globalArray[getDesktopsObject.messageName] = getDesktopsObject;
      globalArray[getDesktopsObject.responseTag] = getDesktopsObject;
   } else {
      getDesktopsObject.resetData();
   }

   if (!!getDesktopsObject && !!router) {
      supportedProtocols = globalArray.supportedProtocols;
      if (supportedProtocols) {
         getDesktopsObject.setRequestXML(supportedProtocols);
         handlerList = getDesktopsObject.composeHandlerList();
         router.postMessage(handlerList);
      } else {
         Logger.error("supported protocols is invalid.");
      }
   }
};
