/** @format */

import { settings } from "../rtav.settings";
import { RtavChannel } from "../rtavChannel";
import { AudioCallbackData, Callback, HeaderCallbackData, VideoCallbackData } from "./encoder.model";

export interface AudioDevice {
   deviceIndex: number;
   friendlyName: string;
   uniqueId: string;
   roles: number;
}

export interface VideoDeviceResolution {
   width: number;
   height: number;
}

export interface VideoDeviceCaps {
   resolutionCount: number;
   resolution: VideoDeviceResolution[];
}

export interface VideoDevice {
   deviceIndex: number;
   friendlyName: string;
   uniqueId: string;
   caps?: VideoDeviceCaps;
}

export type DeviceInfo<T extends AudioDevice | VideoDevice> = T & { deviceId: string };

export interface AudioDeviceRole {
   role: number;
   prefix: string;
   id: string;
}

export const audioDeviceDefaultRole: AudioDeviceRole = {
   role: 1,
   prefix: "Default - ",
   id: "default"
};
export const audioDeviceMultimediaRole: AudioDeviceRole = {
   role: 2,
   prefix: "Multimedia - ",
   id: "multimedia"
};
export const audioDeviceCommunicationsRole: AudioDeviceRole = {
   role: 4,
   prefix: "Communications - ",
   id: "communications"
};
export const audioDeviceRoles: AudioDeviceRole[] = [
   audioDeviceDefaultRole,
   audioDeviceMultimediaRole,
   audioDeviceCommunicationsRole
];

export type DeviceConfigCallback<T extends AudioDevice | VideoDevice> = (devices: T[]) => void;

export interface AVPermissionState {
   audio: PermissionState;
   video: PermissionState;
}

export const defaultAudioDevice: DeviceInfo<AudioDevice> = {
   deviceIndex: 0,
   friendlyName: "VMware Virtual Microphone",
   deviceId: "9235a7d0-c558-4edd-abce-9b548fdc6269",
   uniqueId: "9235a7d0-c558-4edd-abce-9b548fdc6269",
   roles: 7
};

export const defaultVideoDevice: DeviceInfo<VideoDevice> = {
   deviceIndex: 1,
   friendlyName: "VMware Virtual Webcam",
   deviceId: "8c50ba8e-d4cc-4025-a03e-feb556b6f23e",
   uniqueId: "8c50ba8e-d4cc-4025-a03e-feb556b6f23e",
   caps: {
      resolutionCount: 1,
      resolution: [{ width: settings.video.defResWidth, height: settings.video.defResHeight }]
   }
};

export interface DeviceConfigCallbackKV<T extends AudioDevice | VideoDevice> {
   channel: RtavChannel;
   callback: DeviceConfigCallback<T>;
}
export interface SessionCallbacks {
   audioDeviceConfigCallbackKV: DeviceConfigCallbackKV<AudioDevice>;
   audioHeaderCallback: Callback<HeaderCallbackData>;
   audioDataCallback: Callback<AudioCallbackData>;
   videoDeviceConfigCallbackKV: DeviceConfigCallbackKV<VideoDevice>;
   videoHeaderCallback: Callback<HeaderCallbackData>;
   videoDataCallback: Callback<VideoCallbackData>;
   emitDeviceStatusChanged: () => void;
}

export enum PreferredDeviceType {
   audio = "audio",
   video = "video",
   audioOut = "audioOut",
   videoResolution = "videoResolution"
}

export const preferredDeviceKeyPrefix = "preferredDeviceId_";
// prettier-ignore
export type PreferredDeviceKey = `preferredDeviceId_${PreferredDeviceType}`;

export function getPreferredDeviceKey(deviceType: PreferredDeviceType): PreferredDeviceKey {
   return (preferredDeviceKeyPrefix + deviceType) as unknown as PreferredDeviceKey;
}

export enum PreferredDeviceValue {
   default = "Default",
   all = "All"
}
export const resolutionNotSet = "not set";

export const preferredDeviceChangeEvent = "preferredDeviceChangeEvent";
export const devicePermissionChangeEvent = "devicePermissionChangeEvent";

export type UserAgent = "Firefox" | "Opera" | "Edge" | "Chrome" | "Safari" | "Unknown";

export const videoResolutionBuiltInList: VideoDeviceResolution[] = [
   { width: 1920, height: 1080 },
   { width: 1280, height: 720 },
   { width: 640, height: 480 },
   { width: 320, height: 240 }
];

export enum DefaultMicStrategy {
   sameAsOs = "SameAsOs",
   sameAsBrowser = "SameAsBrowser"
}

export const defaultMicStrategy = DefaultMicStrategy.sameAsBrowser;
