/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * ipv6-service.ts
 *
 * Service for ipv6 network
 */

export default function IPv6Service() {
   this.globalName = "ipv6-service";
   this.IPv6Disabled = false;
}

IPv6Service.prototype = {};

IPv6Service.constructor = IPv6Service;

IPv6Service.prototype.setIPv6Disabled = function (isIPv6Disabled) {
   this.IPv6Disabled = isIPv6Disabled;
};

IPv6Service.prototype.isIPv6Disabled = function () {
   return this.IPv6Disabled;
};
