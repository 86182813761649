/**
 * ******************************************************
 * Copyright (C) 2016-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import util from "../../jscdk/util";
import JSCDKBrokerError from "../../../shared/jscdk/model/jscdk-broker-error.consts";

import { BusEvent, clientUtil } from "@html-core";
import { ModalDialogService } from "../commondialog/dialog.service";
import {
   globalArray,
   UIEventToJSCDK,
   jscdkClientSetUIController,
   jscdkClientSetFeatureStatus,
   jscdkClientSetCryptoKeyDerivationService
} from "../../jscdk/jscdkClient";
import { RootModel } from "../model/root-model";
import { AjaxBusyService } from "../ajax-busy/ajax-busy.service";
import { TranslateService } from "@html-core";
import BrokerSessionTimeoutTimerController from "../../jscdk/timer/brokerSessionTimeoutTimerController";
import { CryptoKeyDerivationService } from "../service/crypto-key-derivation.service";
import { ErrorAction } from "../../jscdk/jscdk-interface";

export class JSCDKHandler {
   constructor(
      self,
      private _,
      private idleSessionService,
      private blastSessionsModel,
      private transitionDataModel,
      private jumpCacheService,
      private eventBusService,
      private ws1Service,
      private reconnectService,
      private baseViewService,
      private jscdkCommonInvoker,
      private utilService,
      private FeatureConfigs,
      private modalDialogService: ModalDialogService,
      private rootModel: RootModel,
      private ajaxBusyService: AjaxBusyService,
      private translate: TranslateService,
      private cryptoKeyDerivationService: CryptoKeyDerivationService
   ) {
      this.outterSelf = self;
      jscdkClientSetCryptoKeyDerivationService(this.cryptoKeyDerivationService);
   }
   private _callBackfunc = null;
   private _callBackParam = null;
   private outterSelf;
   public ajaxBusy = false;
   public notAuthenticatedErrorDialogId = null;
   public authenticationFailedDialogId = null;

   public invokeJSCDK = (action, doNotBlock?, doNotBusy?) => {
      const actionString = JSON.stringify(action);

      Logger.debug("UI request to JSCDK : " + util.censorMessage(actionString, "JSON"));

      // Set the ajaxBusy flag here before the JSCDK call is made to
      // prevent race conditions and to deal with the case where
      // action.async === false which means that the operation will
      // complete before UIEventToJSCDK() returns
      if (typeof doNotBlock !== "undefined" && !doNotBlock) {
         this.ajaxBusy = true;
      }
      this.outterSelf.data = null;

      // Change cursor to busy and disable buttons.
      if (this.outterSelf.currentController) {
         if (!doNotBusy) {
            this.ajaxBusyService.setAjaxBusy(true);
            this.outterSelf.currentController.connecting();
         }
      } else {
         Logger.error("currentController can't find");
      }
      UIEventToJSCDK(actionString);
   };

   public _setCallbackFunction = (callbackFunc, callbackParam?) => {
      if (typeof callbackFunc !== "function") {
         Logger.debug("Error: the callbackFunc is not a function in jscdkWrapper");
         return false;
      }
      this._callBackfunc = callbackFunc;

      if (callbackParam !== undefined) {
         this._callBackParam = callbackParam;
      } else {
         this._callBackParam = null;
      }
   };

   public invokeCallback = (response) => {
      const callback = this._callBackfunc;
      if (callback) {
         this._callBackfunc = null;
         this.ajaxBusy = false;
         callback(response);
      }
   };

   public invokeCallbackWithCallbackParam = (response) => {
      const callback = this._callBackfunc,
         param = this._callBackParam;
      if (callback) {
         this._callBackfunc = null;
         this._callBackParam = null;
         this.ajaxBusy = false;
         callback(response, param);
      }
   };

   public handleItemConnect = (actionData) => {
      const response = {
         success: true,
         blastURL: null,
         sessionLocked: false,
         error: null,
         sessionId: actionData.sessionId,
         enableUsb: false,
         usbTicket: "",
         successSessionInfo: this.blastSessionsModel.getFullSessionInfoWith(actionData),
         rdsLicense: null,
         redirectSetting: null,
         preferences: actionData.preferences,
         triggerItemInfo: actionData.triggerItemInfo,
         entitleId: actionData.entitleId
      };

      if (actionData.enableUsb === "true") {
         response.enableUsb = true;
         response.usbTicket = actionData.usbTicket;
      }
      if (actionData.redirectSetting) {
         response.redirectSetting = actionData.redirectSetting;
      }
      if (actionData.rdsLicense) {
         response.rdsLicense = actionData.rdsLicense;
      }
      /**
       * if need further info like id, desktop/application name,
       * isApplicationSession, we can change the blastURL into
       * sessionSwitchNeededInfo as an object, but those infos are
       * available when launching the item, so either way seems ok.
       * but the data for toolbar need to be changed accordingly,
       * and might need to update session storage if page jumping
       * is involved(I guess not).
       */
      if (!!actionData && !!actionData.url) {
         response.blastURL = actionData.url;
      } else {
         Logger.debug("Error: actionData.url do not exist when do not reuse blast session");
      }

      this.invokeCallbackWithCallbackParam(response);
   };

   public handleErrorInTitan = (actionData: ErrorAction) => {
      this.eventBusService.dispatch(new BusEvent.JSCDKErrorReportMsg(actionData));
   };

   public handleError = (actionData) => {
      if (
         actionData.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_NEED_FOREIGN_JUMP_ERROR &&
         actionData.errorDetails &&
         actionData.errorDetails.serverAddress
      ) {
         if (!clientUtil.isChromeClient()) {
            // history would not be removed for /desktop, so use 2 for Edge
            const uri = actionData.errorDetails.serverAddress + "/broker/certAccept.html?numPages=2";
            this.jumpCacheService.jumpFor("certAcceptReturnAddress", uri, actionData.errorDetails.itemDetails);
            return;
         }
      }
      if (
         actionData.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_AUTHENTICATED ||
         actionData.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_AUTHENTICATION_FAILED
      ) {
         this.eventBusService.dispatch(new BusEvent.ClearActiveClient());
         this.idleSessionService.onSessionEnded();
         this.reconnectService.clear();
         this.rootModel.set("isAlreadyLogin", false);
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
      }
      if (actionData.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_AUTHENTICATED) {
         /**
          * Only set overlay as false here since there are many imexplicitly
          * toggles, so we can't do it in commen, see bug 2167117 and 2166165.
          * While the toogle never get chance to be changed again since the
          * callback is not execute here, the struct change should be made,
          * but left it to be done later.
          */
         this.eventBusService.dispatch({
            type: "sessionExpired"
         });
         let brokerSessionTimeoutTimerController = globalArray["broker-session-timeout-timer"];
         if (!brokerSessionTimeoutTimerController) {
            brokerSessionTimeoutTimerController = new BrokerSessionTimeoutTimerController();
            globalArray["broker-session-timeout-timer"] = brokerSessionTimeoutTimerController;
         }
         brokerSessionTimeoutTimerController.stop();
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
         // Don't show more than one error at a time
         if (this.modalDialogService.isDialogOpen(this.notAuthenticatedErrorDialogId)) {
            return;
         }
         this.notAuthenticatedErrorDialogId = this.modalDialogService.showError({
            data: {
               titleKey: "ERROR",
               contentKey: "error_not_authenticated"
            },
            callbacks: {
               confirm: () => {
                  if (this.ws1Service.isWS1Mode()) {
                     if (clientUtil.isChromeClient()) {
                        chrome.app.window.getAll().forEach((window) => {
                           window.close();
                        });
                     } else {
                        window.close();
                     }
                  }
                  this.modalDialogService.closeDialogByTypes(this.modalDialogService.windowsClasses);
                  this.baseViewService.gotoPortal();
               }
            }
         });
         this.rootModel.set("sessionExpired", true);
         return;
      }
      if (actionData.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_AUTHENTICATION_FAILED) {
         this.eventBusService.dispatch(new BusEvent.AuthFailedMsg(actionData.authType, actionData.errorText, true));
         // Don't show more than one error at a time
         if (this.modalDialogService.isDialogOpen(this.authenticationFailedDialogId)) {
            return;
         }
         this.authenticationFailedDialogId = this.modalDialogService.showError({
            data: {
               title: this.translate._T("ERROR"),
               content: actionData.errorText
            },
            callbacks: {
               confirm: () => {
                  // To fix bug 3222405
                  if (this._callBackfunc === null && this.ws1Service.isWS1Mode() !== true) {
                     this.baseViewService.gotoPortal();
                  } else {
                     this.prepareParamForCallback(actionData);
                  }
               }
            }
         });
         return;
      }

      this.prepareParamForCallback(actionData);
   };

   private prepareParamForCallback = (actionData) => {
      const response = {
         success: false,
         sessionLocked: null,
         error: {
            // only pass those info to UI, could be changed if needed
            errorType: actionData.errorType,
            userMsg: actionData.errorText,
            errorMsg: actionData.errorMessage,
            errorDetails: actionData.errorDetails
         }
      };
      if (this._callBackParam) {
         this.invokeCallbackWithCallbackParam(response);
      } else {
         this.invokeCallback(response);
      }
   };

   public handleReauthResponse = (actionObj) => {
      //handle reAuth error response
      const actionData = actionObj.content;
      if (actionData.error) {
         Logger.debug("Get error message " + actionData.error + "from reAuth type: " + actionObj.name);
         const response = {
            success: false,
            sessionLocked: true,
            error: {
               errorMessage: actionData.error
            }
         };
         switch (actionObj.name) {
            case "SecurIDPasscode":
            case "SecurIDNextTokenCode":
            case "SecurIDPinChange":
               this.invokeCallback(response);
               break;
            default:
               break;
         }
      } else {
         Logger.debug("Get success response from reAuth type: " + actionObj.name);
      }
   };

   public handleSuccessResponse = () => {
      let response;
      response = {
         success: true,
         sessionLocked: false,
         error: null
      };

      this.invokeCallback(response);
   };

   public handlerReconnectResponse = (actionData) => {
      let response;
      response = {
         success: true,
         sessionLocked: false,
         successSessionInfo: this.blastSessionsModel.getFullSessionInfoWith(actionData),
         error: null,
         enableUsb: false,
         usbTicket: ""
      };
      if (actionData.enableUsb === "true") {
         response.enableUsb = true;
         response.usbTicket = actionData.usbTicket;
      }
      this.invokeCallbackWithCallbackParam(response);
   };

   public handleShareSessionResponse = () => {
      let response;
      response = {
         success: true,
         sessionLocked: false,
         error: null,
         shareSession: true
      };

      this.invokeCallback(response);
   };

   public handleLastUserActiveTime = (actionName) => {
      Logger.debug("JSCDK response to lastUserActiveTime : " + actionName);
      const lastUserActiveTime = this.idleSessionService.getLastUserActivityTime();
      this.jscdkCommonInvoker.sendLastUserActiveTimeToTimerCtrl(actionName, lastUserActiveTime);
   };

   public handleAuthenticationStatusReAuth = (actionObj) => {
      if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
         this.modalDialogService.closeDialogByType("reauth-window");
         this.utilService.authenticationStatusCheck.doAction(this.outterSelf.currentController);
      } else {
         Logger.debug("There's no reauth dialog");
         //bug 2865449 Since certauth has no reauth dialog,
         //need add this to deal with certauth reauth.
         if (actionObj.authType === "CertAuth") {
            this.utilService.authenticationStatusCheck.doAction(this.outterSelf.currentController);
         }
      }
      if (clientUtil.isChromeClient() && this.idleSessionService.needToReconnectApp()) {
         this.jscdkCommonInvoker.reconnectApplicationSessions([], "");
      }
   };

   public responseToJSCDKEvent = (action) => {
      const actionObj = $.parseJSON(action),
         actionName = actionObj.name,
         actionData = actionObj.content;

      if (!actionObj.ignoreData) {
         this.outterSelf.data = actionObj;
      }

      if (!actionData) {
         Logger.debug("JSCDK response to UI : " + util.censorMessage(action, "JSON"));
      } else {
         if (actionName === "GetLaunchItems") {
            Logger.debug("JSCDK response to UI : { name: " + actionName + ", content: ...");
         } else {
            Logger.debug(
               "JSCDK response to UI : { name: " +
                  actionName +
                  ", content: " +
                  util.censorMessage(JSON.stringify(actionData), "JSON")
            );
         }
      }
      this.outterSelf._show(actionObj);
   };

   //will be changed after JSCDK refactory
   public initJSCDK = (responseHandler, brokerUrl) => {
      this.ajaxBusy = false;
      const isRefreshPage =
         this.transitionDataModel.isApplicationSession === null || this.transitionDataModel.blastURL === null;
      if (isRefreshPage && brokerUrl !== "") {
         this.invokeJSCDK(
            {
               method: "InitRouter",
               brokerUrl: brokerUrl
            },
            true
         );
      }
      //init brokerSessionTimeoutTimerController
      let brokerSessionTimeoutTimerController = globalArray["broker-session-timeout-timer"];
      if (!brokerSessionTimeoutTimerController) {
         brokerSessionTimeoutTimerController = new BrokerSessionTimeoutTimerController();
         globalArray["broker-session-timeout-timer"] = brokerSessionTimeoutTimerController;
      }
      const targetTime = brokerSessionTimeoutTimerController.getTimerFromStorage(), //in ms
         currentTime = new Date().getTime(); //in ms
      if (targetTime) {
         Logger.info("get broker session target time from storage: " + targetTime);
         Logger.info("current time: " + currentTime);
         if (targetTime > currentTime) {
            const sessionTimeout = targetTime - currentTime; //in ms
            brokerSessionTimeoutTimerController.start(sessionTimeout);
         } else {
            Logger.info("session is already expired.");
         }
      }
      jscdkClientSetUIController(responseHandler);
      jscdkClientSetFeatureStatus({
         AzureConnectionRetry: this.FeatureConfigs.getConfig("KillSwitch-AzureConnectionRetry")
      });
   };
}
