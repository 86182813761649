/**
 * ******************************************************
 * Copyright (C) 2019-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Used for RX feature, mainly for seamless window
 * beta to reduce the refactor effort.
 */

export namespace Port {
   export enum ChannelName {
      Settings = "Settings",
      Session = "Session",
      VDPService = "VDPService",
      RTAV = "RTAV",
      CDR = "CDR",
      UrlRedirection = "UrlRedirection",
      USB = "UsbRedirection"
   }

   export enum MsgType {
      RTAV = "RTAV",
      Session = "Session",
      CDR = "CDR",
      VDPService = "VDPService",
      Settings = "Settings",
      UrlRedirection = "UrlRedirection"
   }

   export enum RTAVMsg {
      ChannelOpen = "ChannelOpen",
      ChannelClose = "ChannelClose",
      ChannelError = "ChannelError",
      ChannelMessage = "ChannelMessage",
      ChannelResponse = "ChannelResponse",
      DeviceStatus = "DeviceStatus"
   }

   export enum SessionMsg {
      onConnecting = "onConnecting",
      onConnected = "onConnected",
      onDisconnecting = "onDisconnecting",
      onDisconnected = "onDisconnected",
      onClosed = "onClosed",
      onReconnectToken = "onReconnectToken", //for network recovery and broker session reuse
      onSessionActive = "onSessionActive",
      requestSessionDisconnect = "requestSessionDisconnect",
      sessionMessage = "sessionMessage",
      sessionMessageHandled = "sessionMessageHandled",
      desktopSessionLogOut = "desktopSessionLogOut",
      desktopSessionDisconnect = "desktopSessionDisconnect",
      restoreApplicationSession = "restoreApplicationSession",
      restoreApp = "restoreApp"
   }

   export enum CDRMsg {
      requestFolderInfos = "requestFolderInfos", //session to parent
      AccessibleFolderInfos = "AccessibleFolderInfos", //parent to session
      updateFolderInfos = "updateFolderInfos",
      newFolderFromFA = "newFolderFromFA"
   }

   export enum USBMsg {
      desktopName = "desktopName",
      deviceOnInsert = "deviceOnInsert",
      DeviceChanged = "deviceChanged",
      getRedirectedDevices = "getRedirectedDevices",
      isDeviceRedirected = "isDeviceRedirected",
      onUsbConnected = "onUsbConnected",
      policyDeviceSync = "policyDeviceSync"
   }

   export enum SettingMsg {
      Resolution = "Resolution", //{width:int,height:int}
      enableHighResolution = "enableHighResolution", //boolean
      enableWebRTCRedirection = "enableWebRTCRedirection", //boolean
      enableScreenSharing = "enableScreenSharing", //boolean
      enableWindowsKey = "enableWindowsKey", //boolean
      enableWindowsDeleteKey = "enableWindowsDeleteKey",
      enableGeolocationSharing = "enableGeolocationSharing", //boolean
      donotShowGeolocationDialog = "donotShowGeolocationDialog", //boolean
      enableMediaStreamPermission = "enableMediaStreamPermission", //boolean
      donotShowMediaStreamPermissionDialog = "donotShowMediaStreamPermissionDialog", //boolean
      syncBackSettings = "syncBackSettings", //Array<SettingItem>
      enableBCR = "enableBCR",
      checkAnyInMultimon = "checkAnyInMultimon",
      enterMultimon = "enterMultimon",
      exitMultimon = "exitMultimon",
      selectedMonitors = "selectedMonitors",
      desktopFocused = "desktopFocused",
      multimonAlive = "multimonAlive",
      disableNetworkStateDisplay = "disableNetworkStateDisplay"
   }

   export enum UrlRedirectionMsg {
      RedirectToAgent = "RedirectToAgent"
   }
}
