/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * audio-resource-manager.ts
 * Audio Resource Manager
 */

import { Injectable } from "@angular/core";
import { AbstractMediaResourceManager } from "./abstract-media-resource-manager";
import { MediaType } from "./media-resource-manager";
import { MediaResourceUtil } from "./media-resource-util";
import { MediaStat } from "./model/media.models";

@Injectable({
   providedIn: "root"
})

/**
 * Resource Manager implementation for managing audio resources
 */
export class AudioResourceManager extends AbstractMediaResourceManager {
   /**
    * Construct an audio resource manager
    */
   constructor() {
      super(MediaType.audio);
   }

   /**
    * Create a new Audio HTMLMediaElement by given id and media parameters
    *
    * @param  {string} id given element id
    * @param  {any} params an JSON object that specify media element parameters
    */
   public createElement = (id: string, params: any): HTMLMediaElement | undefined => {
      const elem = MediaResourceUtil.createAudioElement(id);
      return elem;
   };

   /**
    * Update HTMLMediaElement by given id and media parameters
    *
    * @param  {string} id  given element id
    * @param  {any} params an JSON object that specify media element parameters
    */
   public updateElement = (id: string, params: any) => {
      // TODO: no-opt at the moment
   };

   /**
    * Retrieve audio elements
    *
    * @returns HTMLCollectionOf
    */
   public getElements = (): HTMLCollectionOf<HTMLMediaElement> => {
      return MediaResourceUtil.getAudioElements();
   };

   /**
    * Update srcObject property of the audio media element by given Id and MediaStream
    *
    * @param  {string} id given audio media element id
    * @param  {MediaStream} stream a stream of media content to be associated with given audio media element
    */
   public updateSourceObject = (id: string, stream: MediaStream) => {
      const elem: HTMLMediaElement = this.getElement(id);
      if (!elem) {
         this.logger.error("AudioResourceManager failed to update source object.");
         return null;
      }

      elem.srcObject = stream;
      // TODO-Note: potentially there could be an issue when playing the audio unmuted. Revisit the audio playing logic
      // and possibly add a click event listener and call .play() within the click event.
      elem.play();
   };

   /**
    * Wrapper to HTMLMediaElement.setSinkId() that sets the ID of the audio device to use for output
    * Reference: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
    *
    * @param  {string} id  given audio media html element id
    * @param  {string} deviceId  given audio device id
    * @returns Promise  return promise that resolves to undefined
    */
   public setSinkId = (id: string, deviceId: string): Promise<undefined> => {
      const elem: any = this.getElement(id);
      if (!elem) {
         this.logger.error("AudioResourceManager failed to set sink id.");
         return null;
      }

      return elem.setSinkId(deviceId);
   };

   public updateStats = (id: string, model: MediaStat) => {
      // NO-OPT for audio element for now
   };
}
