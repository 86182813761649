/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable, Optional } from "@angular/core";
import { Logger } from "../../../core/libs/logger";
import JSCDKBrokerError from "../../jscdk/model/jscdk-broker-error.consts";
import util from "../../jscdk/util";
import { CLIENT, clientUtil, BusEvent, LocalStorageService, EventBusService, TranslateService } from "@html-core";
import { VmwHorizonLaunchItemType, VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";
import { Ws1Service } from "../service/ws1.service";
import { ConnectionURIModel } from "../model/connection-uri-model";
import { ViewService } from "../../launcher/common/view-service";
import { UtilService } from "../../launcher/common/util-service";
import { SessionUtil } from "../service/session-util";
import { JumpCacheService } from "../service/jump-cache.service";
import { ReconnectService } from "../../desktop/common/reconnect.service";
import { ModalDialogService } from "../commondialog/dialog.service";
import { JscdkCommonInvoker } from "./jscdk-common-invoker";
import { SessionDataUpdateService } from "./session-data-update.service";
import { LoginModel } from "../../login/common/login-model";
import { RootModel } from "../../../shared/common/model/root-model";
import { AjaxBusyService } from "../ajax-busy/ajax-busy.service";
import { BrokerSessionStorageService } from "../../../core/services/storage/broker-session-storage.service";
import { UserGlobalPref } from "../service/user-global-pref";
import { SessionManagementCenterManager } from "../../../chrome-client/launcher/launchitems/session-service/session-management-center-manager.service";

@Injectable({
   providedIn: "root"
})
export class ErrorService {
   constructor(
      private ws1Service: Ws1Service,
      private connectionURIModel: ConnectionURIModel,
      private viewService: ViewService,
      private utilService: UtilService,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private userGlobalPref: UserGlobalPref,
      private sessionDataUpdateService: SessionDataUpdateService,
      private sessionUtil: SessionUtil,
      private jumpCacheService: JumpCacheService,
      private reconnectService: ReconnectService,
      private eventBusService: EventBusService,
      private modalDialogService: ModalDialogService,
      private loginModel: LoginModel,
      private rootModel: RootModel,
      private ajaxBusyService: AjaxBusyService,
      private brokerSessionStorageService: BrokerSessionStorageService,
      @Optional()
      private sessionManagementCenterManager: SessionManagementCenterManager
   ) {}

   /**
    * Switch pages.
    *
    * @param name [in] mapping name registered
    *                  in the controllers' dictionary
    * @param obj  [in] initialization parameter
    */
   private _errorDialogId: string = null;
   private _currentController: any = null;
   public showError = (data, controller) => {
      let errorType,
         userMsg,
         errorMsg,
         okCallback = null,
         COMANAGEMENT_ERROR = "failed launching desktop: No co-management availability for protocol BLAST",
         TUNNEL_ERROR =
            "failed launching desktop: com.vmware.vdi.sessionclientapi.TunnelManager$TunnelManagerException",
         AVAIlABILITY_ERROR = "failed launching connection:",
         PROTOCOL_ERROR = "Please try connecting via another protocol or logoff the existing session first",
         PROTOCOL_MISMATCH_ERROR_CODE = "AGENT_ERR_PROTOCOL_FAMILY_MISMATCH",
         SAML_AUTHENTICATOR_ERROR = "SAML Authenticator disabled";

      this._currentController = controller;
      if (!!data && !!data.content) {
         errorType = data.content.errorType;
         userMsg = data.content.errorText;
         errorMsg = data.content.errorMessage;
         // For web client when meet ws1 errors in ws1mode, close tab,
         if (this.ws1Service.isWS1Mode()) {
            if (clientUtil.isChromeClient()) {
               okCallback = clientUtil.closeAllWindows.bind(clientUtil);
            } else {
               okCallback = window.close;
            }
         }
         if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_ALREADY_AUTHENTICATED) {
            if (!!data.content.workspaceOneServerHostname && !this.ws1Service.isLaunchedFromWS1()) {
               // Invalid session, handle off to WS1
               this.ws1Service.redirectToWS1(data.content.workspaceOneServerHostname, window);
               return;
            }

            if (this.connectionURIModel.params) {
               // URI connection reuses the previous session.
               this.connectionURIModel.reuseSession = true;
            }
            // Already authenticated, navigate to the desktops or
            // launchitems page and ensure the timer is started
            if (util.brokerSupportApplication()) {
               this.jscdkCommonInvoker.startIdleTimer();
            }
            this.jscdkCommonInvoker.retrieveLaunchItems();
            return;
         }

         if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_AUTHENTICATION_FAILED) {
            this.eventBusService.dispatch(new BusEvent.AuthFailedMsg(data.content.authType, userMsg, true));
            // Check if invalid workspace one mode first
            if (data.content.workspaceOneServerHostname) {
               // Invalid session, handle off to WS1
               this.ws1Service.redirectToWS1(data.content.workspaceOneServerHostname, window);
               return;
            }

            if (this.loginModel.certAuth) {
               // Certificate authentication failed. Press OK, go back to
               // portal page.
               okCallback = this.getOKCallbackOnBrokerError(this.viewService.gotoPortal);
            } else {
               /**
                * Navigate back to the server select page when WS1 mode is disabled
                * but SAML is required, bug 2414266
                *
                * Need to Clear URI for this case, bug 2516193
                */
               if (clientUtil.isChromeClient() && !this.ws1Service.isWS1Mode()) {
                  okCallback = () => {
                     this.viewService.gotoPortal(true);
                  };
               } else {
                  // Press OK, close the error dialog and navigate back to
                  // the home page.
                  okCallback = this.getOKCallbackOnBrokerError(this.goServerLanding);
               }
            }

            // fix bug 2928704 & 2928726 Error dialog about SAML Authenticator
            // disabled/removed is always showing
            if (userMsg.indexOf(SAML_AUTHENTICATOR_ERROR) >= 0 && clientUtil.isChromeClient()) {
               okCallback = clientUtil.closeAllWindows.bind(clientUtil);
            }
         } else if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_TIMEOUT) {
            // Press OK, close the error dialog and navigate back to the
            // home page.
            okCallback = this.getOKCallbackOnBrokerError(this.goServerLanding);
            this.eventBusService.dispatch(
               new BusEvent.ConnectBrokerFailed({
                  errorMessage: "Request to Connection Server timeout"
               })
            );
         } else if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_ENTITLED) {
            /**
             * Not an entitled user to the item. need to update the UI.
             * Refresh the select page with the newest data
             */
            this.eventBusService.dispatch(
               new BusEvent.ItemLaunchFailed({
                  itemType:
                     data.content.itemType === "desktop"
                        ? <VmwHorizonLaunchItemType>"HorizonDesktop"
                        : <VmwHorizonLaunchItemType>"HorizonApp",
                  launchItemId: data.content.itemId,
                  errorMessage: errorMsg,
                  clientData: {
                     triggerItemInfo: data.content.triggerItemInfo
                  }
               })
            );
            okCallback = this.getOKCallbackOnBrokerError(() => {
               if (util.brokerSupportApplication()) {
                  this.jscdkCommonInvoker.startIdleTimer();
               }
               this.jscdkCommonInvoker.retrieveLaunchItems();
            });
         } else if (
            errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_DESKTOPS_ERROR ||
            errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_LAUNCH_ITEMS_ERROR ||
            errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_TUNNEL_ERROR
         ) {
            /**
             * Not able to show the select page. Needs to go back and
             * login with another username. Logout the broker.
             */

            this.eventBusService.dispatch(
               new BusEvent.ItemLaunchFailed({
                  itemType:
                     data.content.itemType === "desktop"
                        ? <VmwHorizonLaunchItemType>"HorizonDesktop"
                        : <VmwHorizonLaunchItemType>"HorizonApp",
                  launchItemId: data.content.itemId,
                  errorMessage: errorMsg,
                  clientData: {
                     triggerItemInfo: data.content.triggerItemInfo
                  }
               })
            );
            okCallback = this.getOKCallbackOnBrokerError(this.jscdkCommonInvoker.logout);
         } else if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_AUTHENTICATED) {
            this.brokerSessionStorageService.clear();
            this.localStorageService.clearSession();
            this.reconnectService.clear();
            this.sessionUtil.clearAll();
            if (this.utilService.supportLocalStorage()) {
               window.localStorage.removeItem(CLIENT.CLIENT_LOGIN_KEY);
            }
            userMsg = this.translate._T("error_not_authenticated");
            //TODO-SDK, Unauthenticated_Access is not a valid VmwHorizonClientAuthType
            this.eventBusService.dispatch(new BusEvent.AuthFailedMsg("Unauthenticated_Access", userMsg, true));
            // Press OK, close the error dialog and navigate back to the
            // home page.
            if (this.ws1Service.isWS1Mode()) {
               if (clientUtil.isChromeClient()) {
                  okCallback = clientUtil.closeAllWindows.bind(clientUtil);
               } else {
                  okCallback = window.close;
               }
               this.rootModel.set("sessionExpired", true);
            } else {
               this.modalDialogService.closeDialogByTypes(this.modalDialogService.windowsClasses);
               okCallback = this.getOKCallbackOnBrokerError(this.goServerLanding);
            }
         } else if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_DESKTOP_LAUNCH_ERROR) {
            this.eventBusService.dispatch(
               new BusEvent.ItemLaunchFailed({
                  itemType: <VmwHorizonClientAuthType>"HorizonDesktop",
                  launchItemId: data.content.itemId,
                  errorMessage: errorMsg,
                  clientData: {
                     triggerItemInfo: data.content.triggerItemInfo
                  }
               })
            );
            // fix bug 2859764 Existing remote agent should keep alive
            // after dismissing desktop unavailable error dialog in WS1 mode
            if (
               errorMsg.indexOf(AVAIlABILITY_ERROR) >= 0 &&
               this.ws1Service.isWS1Mode() &&
               clientUtil.isChromeClient()
            ) {
               okCallback = this.getOKCallbackOnSessionCheck();
            }
            // When tunnel connection hasn't been established
            // successfully, if Blast Secure Gateway is turned on, a 'No
            // co-management error' is returned; otherwise, a
            // 'TunnelManagerException' error is returned.
            if (errorMsg.indexOf(COMANAGEMENT_ERROR) >= 0 || errorMsg.indexOf(TUNNEL_ERROR) >= 0) {
               // Probably caused by the tunnel connection problem.
               userMsg = this.translate._T("error_no_comanagement");
               okCallback = this.getOKCallbackOnBrokerError(this.jscdkCommonInvoker.logout);
            }

            if (userMsg.indexOf(PROTOCOL_ERROR) >= 0 && this._currentController.currentDesktop) {
               okCallback = () => {
                  this.utilService.logOffConfirmation(this._currentController.currentDesktop);
               };
            }
         } else if (errorType === PROTOCOL_MISMATCH_ERROR_CODE && this._currentController.currentDesktop) {
            this.eventBusService.dispatch(
               new BusEvent.ConnectBrokerFailed({
                  errorMessage: errorMsg
               })
            );
            this.utilService.logOffConfirmation(this._currentController.currentDesktop);
            return;
         } else if (errorType === "JSCDK_ERROR_LOGOFF_POOL_FAILED") {
            // Desktop is disabled before user logs off
            okCallback = this.getOKCallbackOnBrokerError(this.goServerLanding);
         } else if (
            errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_CONNECT_TO_BROKER_ERROR ||
            errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_KEY_EXCHANGE_ERROR
         ) {
            // back to sever selector page when connection to broker fails
            this.eventBusService.dispatch(
               new BusEvent.ConnectBrokerFailed({
                  errorMessage: errorMsg
               })
            );
            okCallback = this.getOKCallbackOnBrokerError(this.viewService.gotoPortal);
            if (data.content.skipNetworkError) {
               return;
            }
         } else if (
            errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_NEED_FOREIGN_JUMP_ERROR &&
            data.content.errorDetails &&
            data.content.errorDetails.serverAddress
         ) {
            if (!clientUtil.isChromeClient()) {
               // this error is only for HTML Access
               const uri = data.content.errorDetails.serverAddress + "/broker/certAccept.html?numPages=2";
               this.jumpCacheService.jumpFor("certAcceptReturnAddress", uri, data.content.errorDetails.itemDetails);
               return;
            }
         } else if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_CONNECT_TO_FOREIGN_ERROR) {
            okCallback = () => {
               Logger.info("failed to connect to UAG");
            };
         } else if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_APPLICATION_LAUNCH_ERROR) {
            this.eventBusService.dispatch(
               new BusEvent.ItemLaunchFailed({
                  itemType: <VmwHorizonLaunchItemType>"HorizonApp",
                  launchItemId: data.content.itemId,
                  errorMessage: errorMsg,
                  clientData: {
                     triggerItemInfo: data.content.triggerItemInfo
                  }
               })
            );
            if (!okCallback) {
               okCallback = () => {
                  Logger.info("failed to launch application");
               };
            }
         } else if (errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_MAX_MULTI_SESSION_LIMIT_REACHED) {
            this.eventBusService.dispatch(new BusEvent.StopBlockLaunchingFromSDK("default error handling"));
            okCallback = () => {
               Logger.info("Reach session limited number.");
            };
         } else {
            this.eventBusService.dispatch(new BusEvent.StopBlockLaunchingFromSDK("default error handling"));
            okCallback = () => {
               this.viewService.gotoPortal();
            };
         }

         if (this.modalDialogService.isDialogOpen(this._errorDialogId)) {
            this.modalDialogService.close(this._errorDialogId);
         }

         // Show error dialog.
         this._errorDialogId = this.modalDialogService.showError({
            data: {
               title: this.translate._T("ERROR"),
               content: userMsg
            },
            callbacks: {
               confirm: () => {
                  if (typeof okCallback === "function") {
                     okCallback();
                  }
               }
            }
         });
         this.ajaxBusyService.setAjaxBusy(false);
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
         this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
      } else {
         // Refresh current page.
         //TODO-NG how to refresh page ?
         //$route.reload();
      }
   };

   public getOKCallbackOnBrokerError = (callbackFunc, callbackParams?: any) => {
      let isUagWithIdp = false;
      if (!clientUtil.isChromeClient()) {
         isUagWithIdp = localStorage.getItem("isUagWithIdp") === "true";
      }

      if (
         isUagWithIdp ||
         (clientUtil.isChromeClient() && !this.ws1Service.isWS1Mode() && this.ws1Service.isLaunchedFromWS1())
      ) {
         callbackFunc = this.viewService.gotoPortal;
      }
      return () => {
         this.utilService.runFunctionIfNotHWSOrF5(callbackFunc, callbackParams);
      };
   };

   /**
    * Go back to server landing page.
    * It will go to homeconnectctrl.
    * For both webclient and Chromeclient, homeconnectctrl is
    * used for connect to broker.
    * For webclient, there is a cloud btn.
    * For Chromeclient, there is no UI.
    */
   public goServerLanding = () => {
      this.userGlobalPref.clearPrefData();
      this.sessionDataUpdateService.handleEvent(this._currentController, "Brokers");
   };

   public getOKCallbackOnSessionCheck = () => {
      const callback = clientUtil.closeAllWindows;
      return () => {
         if (!this.sessionManagementCenterManager.hasConnectedSession()) {
            callback();
         }
      };
   };
}
