/**
 * *******************************************************
 * Copyright (C) 2012-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * messageHandler.js --
 *
 *      Implementation of the prototype of all message handlers.
 */

import $ from "jquery";
import Logger from "../../../core/libs/logger";
import util from "../util";
import JSCDKBrokerError from "../model/jscdk-broker-error.consts";
import { SubError } from "../jscdk-interface";

// all states of a message handler
export const StateEnum = {
   INIT: "init",
   PENDING: "pending",
   DONE: "done",
   FAIL: "fail"
};

MessageHandler.prototype = new MessageHandler(); // MessageHandler prototype
MessageHandler.constructor = MessageHandler; // constructor

export function MessageHandler() {
   this.messageName = ""; // name of this message’s handler, using XML
   // protocol’s message
   this.messageText = ""; // the text needed to send to the broker
   this.responseTag = ""; // indicate which response this handler should handle
   this.content = {}; // data generated (key-value) by current handler,
   // including response result, local info, etc.
   this.requestXML = ""; // XML document needs to send.
   this.state = StateEnum.INIT; // the state of the current handler, set to
   // INIT firstly
   this.dependencyList = []; // list of other handlers who current handler
   // depends on
   this.observerList = []; // list of handlers who depend on current handler
   this.subHandlerList = []; // one message handler may have some sub messages,
   // such as "do-submit-authentication"
   this.parentHandler = null; // when setting a handler's sub handler, we also
   // set it as the sub handler's parent handler
   this.composedHandlerList = []; // list of handlers who can be sent together
   // in a single message
   this.requestId = 0; // suffix of responseTag to distinguish mutiple
   // instances of a handler
   this.requestIdKV = {}; // key-value pairs, value is requestId, key can be
   // responseTag+desktopId or other
   this.xhrObject = {}; // XHR object for the http request
}

MessageHandler.prototype.setMessageName = function (messageName) {
   this.messageName = messageName;
};

MessageHandler.prototype.getMessageName = function () {
   return this.messageName;
};

MessageHandler.prototype.getMessageText = function () {
   return this.messageText;
};

MessageHandler.prototype.setRequestXML = function (requestXML) {
   this.requestXML = requestXML;
};

MessageHandler.prototype.getRequestXML = function () {
   return this.requestXML;
};

MessageHandler.prototype.setState = function (state) {
   let key;
   let handler;
   let parentHandler;

   if (state === StateEnum.DONE) {
      if (this.hasSubHandler()) {
         Logger.error(
            "Failed to set " + this.getMessageName() + "'s state to DONE because it has sub handlers to execute."
         );
         return;
      }
   }

   Logger.trace("Set " + this.getMessageName() + "'s state from " + this.state + " to " + state);
   this.state = state;

   if (this.state === StateEnum.DONE || this.state === StateEnum.FAIL) {
      parentHandler = this.parentHandler;
      if (parentHandler) {
         parentHandler.removeSubHandler(this);
         if (this.state === StateEnum.FAIL) {
            parentHandler.setState(StateEnum.FAIL);
         } else {
            parentHandler.onUpdated();
         }
      }

      if (this.observerList["state"]) {
         for (key in this.observerList["state"]) {
            if (this.observerList["state"].hasOwnProperty(key)) {
               handler = this.observerList["state"][key];
               if (this.state === StateEnum.FAIL) {
                  if (this.content["error"]) {
                     handler.appendContent(this.content["error"]);
                  }
               }
               handler.onUpdated();
            }
         }
      }
   }
};

MessageHandler.prototype.getState = function () {
   return this.state;
};

MessageHandler.prototype.getXhrObject = function () {
   return this.xhrObject;
};

MessageHandler.prototype.setXhrObject = function (xhrObject) {
   this.xhrObject = xhrObject;
};

/**
 * Reset the handler's state and content which can't be reused twice.
 *
 */

MessageHandler.prototype.resetData = function () {
   this.setState(StateEnum.INIT);
   this.content = {};
   this.subHandlerList = [];
   this.parentHandler = null;
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
MessageHandler.prototype.onUpdated = function () {
   let parsedResult;
   let i;
   let handler;
   let doneCnt;
   const error = {};
   const subError = {} as SubError;
   const msgError = {};
   let errorCode;
   let errorKey;
   let jscdkError;
   let key;
   if (this.getState() === StateEnum.INIT) {
      this.setState(StateEnum.PENDING);
   }
   // check parsed result in property "content"
   if (this.content["parsedResult"]) {
      parsedResult = this.content["parsedResult"];
      if (parsedResult["result"]) {
         if (parsedResult["result"] === "ok") {
            if (this.hasSubHandler()) {
               // trigger all sub handlers' executions.
               for (key in this.subHandlerList) {
                  if (this.subHandlerList.hasOwnProperty(key)) {
                     handler = this.subHandlerList[key];
                     handler.triggerExecution();
                  }
               }
            }

            // check all dependencies' status
            if (this.dependencyList["state"]) {
               doneCnt = 0;
               for (i = 0; i < this.dependencyList["state"].length; ++i) {
                  handler = this.dependencyList["state"][i];
                  Logger.debug(this.messageName + " depends on " + handler.messageName);
                  if (handler.getState() === StateEnum.DONE) {
                     Logger.debug(handler.messageName + "'s state is DONE.");
                     ++doneCnt;
                  }
                  if (handler.getState() === StateEnum.FAIL) {
                     Logger.debug(handler.messageName + "'s state is FAIL.");
                     this.setState(StateEnum.FAIL);
                     break;
                  }
               }
               if (doneCnt === this.dependencyList["state"].length) {
                  this.setState(StateEnum.DONE);
               }
            } else {
               this.setState(StateEnum.DONE);
            }
         } else if (parsedResult["result"] === "error") {
            /**
             * if result from broker is error, it should not be triggered by
             * any dependency; reset data to avoid entering this branch for
             * multiple times if error occurs
             */
            this.content = {};

            // fix bug: 2898590, handle ALREADY_AUTHENTICATED has key-parameters
            if (
               this.messageName === "get-configuration" &&
               parsedResult["error-code"] === JSCDKBrokerError.JSCDK_BROKER_ERROR_ALREADY_AUTHENTICATED &&
               parsedResult["keyParameters"]
            ) {
               this.appendContent({ keyParameters: parsedResult["keyParameters"] });
            }

            // handle general errors
            if (parsedResult["error-code"]) {
               errorCode = parsedResult["error-code"];
               errorKey = this.messageName;
               subError.errorCode = errorCode;
               if (parsedResult["error-message"]) {
                  subError.errorMessage = parsedResult["error-message"];
               }
               if (parsedResult["user-message"]) {
                  subError.userMessage = parsedResult["user-message"];
               }
               if (parsedResult["error-details"]) {
                  subError.errorDetails = parsedResult["error-details"];
               }
               if (parsedResult["workspaceOneServerHostname"]) {
                  subError.workspaceOneServerHostname = parsedResult["workspaceOneServerHostname"];
               }
               if (parsedResult["client-retry-timeout-seconds"]) {
                  subError.clientRetryTimeoutSeconds = parsedResult["client-retry-timeout-seconds"];
               }
               if (parsedResult["client-retry-interval-seconds"]) {
                  subError.clientRetryIntervalSeconds = parsedResult["client-retry-interval-seconds"];
               }
               msgError[errorKey] = subError;
               error["error"] = msgError;
               this.appendContent(error);
               this.setState(StateEnum.FAIL);
            }
         }
      } else {
         /**
          * if result from broker doesn't exist, it should not be triggered by
          * any dependency; reset data to avoid entering this branch for
          * multiple times if error occurs
          */
         this.content = {};

         Logger.error("no result in parsed result of " + this.getMessageName());
         msgError[this.getMessageName()] = JSCDKBrokerError.getError(
            JSCDKBrokerError.JSCDK_BROKER_ERROR_UNKNOWN
         ).toString();
         error["error"] = msgError;
         this.appendContent(error);
         this.setState(StateEnum.FAIL);
      }
   } else {
      Logger.debug("no parsed result for " + this.getMessageName() + " yet");
   }
   Logger.debug(this.getMessageName() + "'s state: " + this.getState());
};

/**
 * Broadcast here, such as doLogout, etc.
 *
 * @param msg [in] the message to be broadcast, such as "doLogout", etc.
 */
MessageHandler.prototype.onBroadcast = function (msg) {
   if (msg === "doLogout" || msg === "doCancelAuthentication") {
      Logger.debug(this.messageName + " reset data");
      // Reset data on logout or authentication cancellation.
      this.resetData();
   }
};

/**
 * add a new observer into observerList
 *
 * @param  inputHandler [in] the observer handler to be added
 * @param  attributeName [in] name of the attribute whose observerList is
 *    handled
 */
MessageHandler.prototype.addObserver = function (inputHandler, attributeName) {
   if (this.observerList[attributeName] !== null) {
      if (typeof this.observerList[attributeName] === "undefined") {
         this.observerList[attributeName] = [];
      }
      if (util.findHandlerInArray(inputHandler, this.observerList[attributeName]) === -1) {
         this.observerList[attributeName].push(inputHandler);
      } else {
         Logger.warning("Adding twice to the observer list for handler " + inputHandler.messageName);
      }
   }
};

/**
 * remove a specific observer from observerList
 *
 * @param  inputHandler [in] the observer handler to be removed
 * @param  attributeName [in] name of the attribute whose observerList is
 *    handled
 */
MessageHandler.prototype.removeObserver = function (inputHandler, attributeName) {
   const list = this.observerList[attributeName];
   if (list) {
      const index = list.indexOf(inputHandler);
      if (index > -1) {
         list.splice(index, 1);
      }
   }
};

/**
 * register the router and handlers here
 *
 * @param  inputHandler [in | out] the handler to be handled, update current
 *    handler's dependencyList and inputHandler's observerList
 * @param  attributeName [in] name of the attribute whose observerList is
 *    handled
 */
MessageHandler.prototype.registerHandler = function (inputHandler, attributeName) {
   // handle inputHandler's observer
   inputHandler.addObserver(this, attributeName);
   // handle its dependency
   if (!this.dependencyList[attributeName]) {
      this.dependencyList[attributeName] = [];
   }
   this.dependencyList[attributeName].push(inputHandler);
};

/**
 * unregister the router and handlers here
 *
 * @param  inputHandler [in | out] the handler to be handled, update current
 *    handler's dependencyList and inputHandler's observerList
 * @param  attributeName [in] name of the attribute whose observerList is
 *    handled
 */
MessageHandler.prototype.unregisterHandler = function (inputHandler, attributeName) {
   let index;
   let list;
   // handle inputHandler's observer
   inputHandler.removeObserver(this, attributeName);
   // handle its dependency
   list = this.dependencyList[attributeName];

   if (list) {
      index = list.indexOf(inputHandler);
      if (index > -1) {
         list.splice(index, 1);
      }
   }
};

/**
 * append relative content(key-value pairs) to the content of current handler
 *
 * @param content[in] key-value pair to be appended
 */
MessageHandler.prototype.appendContent = function (content) {
   let key;
   for (key in content) {
      if (content.hasOwnProperty(key)) {
         this.content[key] = content[key];
         if (this.messageName === "get-launch-items") {
            Logger.debug(this.messageName + " this.content[" + key + "]: ...");
         } else {
            Logger.debug(
               this.messageName +
                  " this.content[" +
                  key +
                  "]: " +
                  util.censorMessage(JSON.stringify(this.content[key]), "JSON")
            );
         }
      }
   }
};

/**
 * remove corresponding content(key-value pairs) from this.content of this
 * handler
 * @param key[in] key of the item to remove
 */
MessageHandler.prototype.removeFromContent = function (key) {
   if (this.content.hasOwnProperty(key)) {
      delete this.content[key];
   }
};

/**
 * compose list of message handlers who can be sent together in a single message
 *
 */
MessageHandler.prototype.composeHandlerList = function () {
   if (this.composedHandlerList.length === 0) {
      this.composedHandlerList.push(this);
   }
   return this.composedHandlerList;
};

/**
 * Cancel http request for the handler.
 *
 */

MessageHandler.prototype.cancelRequest = function () {
   if (!!this.xhrObject && $.isFunction(this.xhrObject.abort)) {
      this.xhrObject.abort();
   }
   this.resetData();
};

/**
 * Clear sub handler list.
 *
 */
MessageHandler.prototype.clearSubHandlers = function () {
   let subHandler, i;

   for (i = 0; i < this.subHandlerList.length; i++) {
      subHandler = this.subHandlerList[i];
      subHandler.resetData();
   }

   this.subHandlerList = [];
};

/*
 * Checks whether the current handler has any sub handler.
 *
 * @return true if it has, false otherwise.
 */
MessageHandler.prototype.hasSubHandler = function () {
   if (this.subHandlerList.length > 0) {
      return true;
   }

   return false;
};

/**
 * Append a new sub handler into subHandlerList
 *
 * @param  subHandler [in] the sub handler to be appended
 */
MessageHandler.prototype.appendSubHandler = function (subHandler) {
   let list = this.subHandlerList;

   if (!list) {
      this.subHandlerList = [];
      list = this.subHandlerList;
   }

   if (util.findHandlerInArray(subHandler, list) === -1) {
      list.push(subHandler);
      subHandler.parentHandler = this;
      Logger.trace(this.messageName + ": adding one sub handler: " + subHandler.messageName);
   } else {
      Logger.error("Adding " + subHandler.messageName + "twice to " + this.messageName + "'s sub handler list");
   }
};

/**
 * Remove a sub handler from subHandlerList
 *
 * @param  inputHandler [in] the sub handler to be removed
 */
MessageHandler.prototype.removeSubHandler = function (subHandler) {
   let index,
      list = this.subHandlerList;

   if (list) {
      index = list.indexOf(subHandler);
      if (index > -1) {
         list.splice(index, 1);
         subHandler.parentHandler = null;
      }
   }
};

/**
 * Trigger a handler to begin execution
 *
 */
MessageHandler.prototype.triggerExecution = function () {
   /**
    * Here we just provide an interface.
    * It should be overwitten by derived class when needed.
    */
   Logger.trace("Calling " + this.messageName + "'s triggerExecution function.");
};
