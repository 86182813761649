/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { CertService } from "../../../shared/base/cert.service";

/**
 * For html app, just jump to cert accept page.
 */
export class CertHTML5Service extends CertService {
   public tryToShowCertPage = (url) => {
      window.location.href = url;
   };
}
