/**
 * *************************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview rtavChannel.ts
 *
 * Class that contains functions and logic for using the rtav sub channel
 * @param {object} vvcSession The vvcSession object passed from wmks, with send and openChannel API.
 *                            Is null in seamless window mode.
 */

import { Injectable } from "@angular/core";
import { clientUtil, Logger } from "@html-core";
import { RTAV_H264_CODEC, RTAV_OPUS_CODEC, RTAV_V2_V0 } from "./rtav.constants";

@Injectable()
export class RtavChannel {
   private rtavChannel: any;
   private vvcSession;
   private onChannelCloseError;
   public send;
   public onopen;
   public onclose;
   public onerror;

   constructor() {}

   public init = (vvcSession) => {
      if (!vvcSession) {
         return;
      }
      this.vvcSession = vvcSession;
      this.rtavChannel = null;
   };

   public initialize = () => {
      let initialDataArr = null;
      Logger.debug(
         "vvcSession.enableRTAVH264Codec in rtavSession is " + this.vvcSession.enableRTAVH264Codec,
         Logger.RTAV
      );
      Logger.debug(
         "vvcSession.enableRTAVOpusCodec in rtavSession is " + this.vvcSession.enableRTAVOpusCodec,
         Logger.RTAV
      );
      let capability = 0x0000;
      if (this.vvcSession.enableRTAVH264Codec === true) {
         capability = capability | RTAV_H264_CODEC;
      }
      if (this.vvcSession.enableRTAVOpusCodec === true) {
         capability = capability | RTAV_OPUS_CODEC;
      }
      if (this.vvcSession.enableRTAVH264Codec === true && this.vvcSession.enableRTAVOpusCodec === true) {
         capability = capability | RTAV_V2_V0;
         Logger.info("client support rtav v2", Logger.RTAV);
      }
      initialDataArr = clientUtil.pushBytes(capability, 4, false);
      this.rtavChannel = this.vvcSession.openChannel("MultimediaRedirChannel", 10, null, null, initialDataArr);
      this.onChannelCloseError = () => {
         Logger.info("rtav channel is closed", Logger.RTAV);
         this.stop();
      };
      this.rtavChannel.onclose = this.onChannelCloseError;

      this.rtavChannel.onerror = this.onChannelCloseError;
      this.send = (networkBytes) => {
         if (!networkBytes) {
            return;
         }
         try {
            this.vvcSession.send(this.rtavChannel, networkBytes);
         } catch (e) {
            Logger.error("error when sending rtav message:" + e, Logger.RTAV);
         }
      };
   };

   public stop = () => {
      if (!this.rtavChannel) {
         return;
      }
      this.send = () => {
         Logger.debug("stop one package sending for rtav channel is closed", Logger.RTAV);
      };
      this.rtavChannel.onmessage = () => {
         Logger.debug("stop one package receiving for rtav channel is closed", Logger.RTAV);
      };
      this.rtavChannel = null;
   };

   public setChannelOnOpenListener = (onOpen) => {
      if (!this.rtavChannel) {
         return;
      }
      this.rtavChannel.onopen = onOpen;
   };

   public setReceivedListener = (onMessage) => {
      if (!this.rtavChannel) {
         return;
      }
      this.rtavChannel.onmessage = onMessage;
   };
}
