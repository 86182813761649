/* ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import $ from "jquery";
import * as CST from "@html-core";
import { Component, Input, ChangeDetectorRef, HostListener, Optional } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { Logger } from "../../../core/libs/logger";

import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { UtilService } from "../../launcher/common/util-service";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { BrokerSettingService } from "../../launcher/common/broker-setting.service";
import { ConnectionServerModel } from "../../../shared/common/model/connection-server-model";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { ReauthDialogService } from "../../desktop/re-auth/reauth-dialog.service";
import { SDKService } from "../../../chrome-client/SDK/sdk-service";
import { RootModel } from "../../common/model/root-model";
import { LoginService } from "./../login-root/login.service";
import { CommonSDKService } from "../../common/service/sdk.service";

/**
 * For type check only
 */
interface WindowPasswordData {
   content: {
      usernameReadOnly?: string;
      domainReadOnly?: string;
      username?: string;
      domains?: Array<string>;
      domainName?: string;
      error?: string;
   };
}

class DefaultSettings {
   domain: string;
   username: string;
   constructor(username?: string, domain?: string) {
      this.username = username || "";
      this.domain = domain || "";
   }
}

class Credential {
   constructor(
      public username: string,
      public password: string,
      public domain: string
   ) {}
}

/**
 * This implementation ease the effort for UT
 */
@Component({
   selector: "login-windows-password",
   templateUrl: "./windows-password.component.html"
})
export class LoginWindowsPasswordComponent extends BaseViewComponent implements BaseViewComponentInterface {
   @Input() formData;

   public defaultSettings?: DefaultSettings;
   public domainHidden?: boolean;

   public domains: Array<string> = new Array<string>();
   public error: string = "";
   public domainFrozen: boolean = false;
   public usernameReadOnly: boolean = false;
   public domainReadOnly: boolean = false;
   public cancelLoading: boolean = false;
   public allowClearUsername: boolean = false;
   public allowClearPassword: boolean = false;
   public domainInUsername: boolean = false;
   public showDomainList: boolean = false;
   public isReAuth: boolean = false;

   private defaultUsername: string;
   public _defaultDomain: string;
   public currentDomain: string;
   public usernameControl: UntypedFormControl;
   public passwordControl: UntypedFormControl;
   public windowsPasswordForm: UntypedFormGroup;
   public faServer: string = "";
   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private connectionServerModel: ConnectionServerModel,
      private utilService: UtilService,
      private brokerSettingService: BrokerSettingService,
      private modalDialogService: ModalDialogService,
      private viewClientModel: ViewClientModel,
      private reauthDialogService: ReauthDialogService,
      private loginService: LoginService,
      @Optional()
      private sdkService: CommonSDKService,
      private rootModel: RootModel,
      private eventBusService: CST.EventBusService
   ) {
      super(changeDetector, "WindowsPassword");
      this.loginService.winPasswordRedraw$.subscribe((data) => {
         this.onRefreshed(data);
      });
      this.domainHidden = false;

      this._initData();
      this.usernameControl = new UntypedFormControl(this.defaultUsername, [Validators.required]);
      this.passwordControl = new UntypedFormControl("");
      this.windowsPasswordForm = new UntypedFormGroup({
         username: this.usernameControl,
         password: this.passwordControl
      });
      this._readDefaultSetting();
      const faServer = this.rootModel.get("faServer");
      if (faServer && faServer !== "") {
         this.faServer = faServer;
         this.rootModel.set("faServer", "");
      }

      this.eventBusService.listen(CST.BusEvent.DoAuthSubmit.MSG_TYPE).subscribe(() => this._readSDKsecret());
   }

   ngAfterViewInit() {
      this._readSDKsecret();
   }

   public _readSDKsecret = () => {
      if (!this.sdkService) {
         Logger.trace("no SDK service found for auth");
         return;
      }
      if (!this.sdkService.hasAuthSecret()) {
         Logger.trace("no secrete found in SDK service for auth");
         return;
      }
      //This is for workaround introduced for bug 2771856
      const timer = 20;
      setTimeout(() => {
         const credential: Credential = this._getCredential();
         this.sdkService
            .getAuthSecret()
            .then((password) => {
               Logger.info("fetch and consume credential from SDK", Logger.SDK);
               credential.password = password;
               const isNotCancel = true;
               this.submitCredential(credential, isNotCancel);
            })
            .catch((e) => {
               Logger.exception(e);
               Logger.error("failed to read the secret from SDK for auth/re-auth", Logger.SDK);
            });
      }, timer);
   };

   public onRefreshed = (data?) => {
      super.onRefreshed();
      if (data) {
         this._initData(data);
      } else {
         this._initData();
      }
   };

   public renderData = () => {
      this.usernameControl.valueChanges.subscribe(this._onUsernameChanged);
      this.passwordControl.valueChanges.subscribe(this._onPasswordChanged);
   };

   private _forceDomain = (domain) => {
      if (this.domains.indexOf(domain) >= 0) {
         this.currentDomain = domain;
      }
      this.domainFrozen = true;
   };

   private _allowSelectDomain = () => {
      this.domainFrozen = false;
   };

   private _onUsernameChanged = (val) => {
      this.allowClearUsername = !!val && !this.usernameReadOnly;
      const domain = CST.UsernameUtil.getDomain(val);
      if (CST.UsernameUtil.isDomainLegal(domain)) {
         this._forceDomain(domain);
      } else {
         this._allowSelectDomain();
      }
      if (val.indexOf("\\") !== -1 || val.indexOf("@") !== -1) {
         this.domainReadOnly = true;
      } else {
         this.domainReadOnly = false;
      }
   };

   private _onPasswordChanged = (val) => {
      this.allowClearPassword = !!val;
   };

   public _readDefaultSetting = () => {
      this.brokerSettingService.getLastADConnectionInfo(this.connectionServerModel.host).then((setting) => {
         this.defaultSettings = new DefaultSettings(setting.username, setting.domain);
         this._updateDefault();
      });
   };

   public _updateDefault = () => {
      if (!this._defaultDomain && this.defaultSettings.domain) {
         const domain = this.defaultSettings.domain;
         if (this.domains.indexOf(domain) >= 0) {
            this.currentDomain = domain;
         }
      }
      if (!this.defaultUsername && this.defaultSettings.username) {
         const username = this.defaultSettings.username;
         this.usernameControl.setValue(username);
      }
   };

   public _initData = (redrawData?) => {
      let data;
      if (redrawData) {
         data = redrawData;
         // avoid double invocation without rewrite real auth flow
         this.onAuthScreen(this.componentName, data);
      } else {
         data = this.data as WindowPasswordData;
      }

      if (!data || !data.content) {
         Logger.error("skip init since no data");
         return;
      }

      let domain, i;

      // Initialize credential data binding model.
      this.defaultUsername = data.content.username;
      // Set username input editability.
      if (data.content.usernameReadOnly === "yes") {
         this.usernameReadOnly = true;
      } else {
         this.usernameReadOnly = false;
      }
      // Set available domains.
      this.domains = data.content.domains;
      this.domainHidden = !this.domains || !(this.domains.length > 0) || this.viewClientModel.clientHideDomainList;
      // Set domainName input editability.
      this.domainReadOnly = data.content.domainReadOnly === "yes";
      // Set current domain.
      if (!this.domainHidden) {
         if (data.content.domainName) {
            domain = data.content.domainName;
         }
         for (i = 0; i < this.domains.length; i++) {
            if (CST.ignoreCaseEquals(this.domains[i], domain)) {
               break;
            }
         }
         if (i >= this.domains.length) {
            i = 0;
         }

         this._defaultDomain = this.domains[i];
         this.currentDomain = this._defaultDomain;

         if (
            this.defaultUsername &&
            (this.defaultUsername.indexOf("\\") !== -1 || this.defaultUsername.indexOf("@") !== -1)
         ) {
            this.domainFrozen = true;
         } else {
            this.domainFrozen = false;
         }
      }
      // Set error message.
      if (data.content.error) {
         this.error = data.content.error;
      }

      this.allowClearUsername = !!this.defaultUsername && !this.usernameReadOnly;
      this.allowClearPassword = false;
      this.clearPassword();
      /**
       * for bug 2771856 (ReAuth) and VMWare SR #21282807012, need to update blankOutContent
       * at the end of the flow.
       */
      const timer = 10;
      setTimeout(() => {
         this.blankOutContent = false;
         this.changeDetector.detectChanges();
      }, timer);
      this._autoFocus();
   };

   private _isUsernameAndDomainLegal = (credential) => {
      /**
       * Basic username check,
       * 1. If username contains either @ or \
       * 2. If hide domain enabled,
       * 2.1 If the domain list contains single domain, no need to verify domain
       * 2.2 If the domain list contains multiple domains, need to verify domain
       * Note: we don't need to check #2 if user name is not editable by user which
       * could be re-authentication or 2FA under reproducing condition of bug 2351833.
       */
      if (
         !CST.UsernameUtil.isUsernameLegal(credential.username) ||
         (this.domainHidden &&
            this.domains.length > 1 &&
            !CST.UsernameUtil.domainInUsername(credential.username) &&
            !this.usernameReadOnly)
      ) {
         this.modalDialogService.showError({
            data: {
               titleKey: "ERROR",
               contentKey: "USERNAME_DOMAIN_ILLEGAL"
            }
         });
         return false;
      }

      // No more check for UPN or normal username
      if (CST.UsernameUtil.isUPN(credential.username) || !CST.UsernameUtil.domainInUsername(credential.username)) {
         return true;
      }

      /**
       * Begin to check domain\username now!
       * First check if domain is valid
       */
      const domain = CST.UsernameUtil.getDomain(credential.username);
      if (!CST.UsernameUtil.isDomainLegal(domain)) {
         this.modalDialogService.showError({
            data: {
               titleKey: "ERROR",
               contentKey: "USERNAME_DOMAIN_ILLEGAL"
            }
         });
         return false;
      }

      // If domain is not hidden, check if the input domain in domain list
      if (
         this.domains.length >= 1 &&
         !this.domainHidden &&
         this.domains.length > 1 &&
         !CST.UsernameUtil.isDomainInDomainList(domain, this.domains)
      ) {
         this.modalDialogService.showError({
            data: {
               titleKey: "ERROR",
               contentKey: "DOMAIN_NOT_IN_LIST"
            }
         });
         return false;
      }

      return true;
   };

   /**
    * Since we've moved the domain list out side select for vPAT,
    * use currentDomain to get selected value
    */
   private _getCredential = () => {
      const fromValue = this.windowsPasswordForm.value;
      return new Credential(fromValue.username, fromValue.password, this.currentDomain);
   };

   public onKeypress = (event) => {
      const credential: Credential = this._getCredential();
      if (
         credential.username === "" ||
         credential.username === null ||
         credential.password === "" ||
         credential.password === null
      ) {
         if (!!event && event.keyCode === 13 && event.target && event.target.id !== "cancelLoginBtn") {
            // Press enter key.
            event.preventDefault();
         }
      }
   };

   public onSubmit = (event) => {
      const credential: Credential = this._getCredential();
      const isNotCancel = !event.submitter || (event.submitter && event.submitter.id !== "cancelLoginBtn");
      this.submitCredential(credential, isNotCancel);
   };

   public submitCredential = (credential: Credential, isNotCancel: boolean) => {
      this.cancelLoading = false;
      let domain: string;

      setTimeout(() => {
         this.blankOutContent = true;
         this.clearPassword();
         this.changeDetector.detectChanges();
      });

      /**
       * move _isUsernameAndDomainLegal into validator
       * web API 'event.submitter' is not supported in IE and Safari
       * so add condition to verified if event.submitter is undefined here
       */
      if (isNotCancel && !this._isUsernameAndDomainLegal(credential)) {
         setTimeout(() => {
            this.blankOutContent = false;
         });
      } else if (!this.windowsPasswordForm.invalid && isNotCancel) {
         if (CST.UsernameUtil.domainInUsername(credential.username)) {
            /**
             * If username contains domain, store that one rather than
             * credential.domain
             *
             * connectionServerModel is used in storeUserInfo of
             * launch-items.component.ts
             */
            domain = CST.UsernameUtil.getDomain(credential.username);
         } else {
            if (credential.domain) {
               domain = credential.domain;
            } else {
               // when domain is hidden, use first domain as default domain
               domain = this.domains[0];
            }
         }

         //deal with reauth case
         if (this.usernameReadOnly && this.domainReadOnly) {
            this.isReAuth = true;
         }

         // Store username and domain
         this.connectionServerModel.username = credential.username;
         this.connectionServerModel.domain = domain;

         /**
          * only cache user and domain for normal AD auth.
          */
         if (!this.isReAuth) {
            this.brokerSettingService.setLastADConnectionInfo(
               this.connectionServerModel.host,
               this.connectionServerModel.username,
               this.connectionServerModel.domain
            );
         }

         if (this.isReAuth) {
            this.reauthDialogService.setReAuthCallBack();
         }

         this.jscdkCommonInvoker.submitWindowsPassword(credential.username, credential.password, domain, this.isReAuth);
      }
   };
   public cancel = () => {
      const credential: Credential = this._getCredential();
      // write in data that needed when back to login page
      this.cancelLoading = true;
      const backContent: any = {
         username: credential.username,
         domains: this.domains,
         domainName: credential.domain
      };
      if (this.usernameReadOnly === true) {
         backContent.usernameReadOnly = "yes";
      }
      if (this.domainReadOnly === true) {
         backContent.domainReadOnly = "yes";
      }
      this.data = {
         name: "WindowsPassword",
         content: backContent
      };

      this.eventBusService.dispatch(new CST.BusEvent.AuthenticationDeclined("cancel"));
      // cancel current request
      if (this.blankOutContent) {
         //cancel before auth result is back
         this.jscdkCommonInvoker.cancelCurrentRequest(this.data);
      } else {
         // cancel after auth result is back
         // if it's desktop mode reauth, cancel just close reauth dialog
         if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
            this.utilService.authenticationStatusCheck.clearAction("cancel reauth");
            this.modalDialogService.closeDialogByType("reauth-window");
            return;
         }
      }
      // Fix bug 3099961, cancel previous user launch/reset/logoff trial
      this.utilService.authenticationStatusCheck.clearAction("cancel reauth");
      if (this.domainReadOnly && this.usernameReadOnly) {
         //re-authentication
         // Logout the broker.
         this.jscdkCommonInvoker.logout(true);
      } else {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"Windows_Password");
      }
   };

   public clearUsername = () => {
      this.usernameControl.setValue("");
   };

   public clearPassword = () => {
      if (this.passwordControl) {
         this.passwordControl.setValue("");
      }
   };

   public selectDomain = (domain) => {
      this.currentDomain = domain;
   };

   public domainItemKeyEvent = (event, domain) => {
      if (!!event && event.keyCode === 9) {
         // when press tab key inside domain list block, move focus to next domain
         event.stopPropagation();
         event.preventDefault();
         const domainPos: number = this.domains.indexOf(domain);
         const domainListLen: number = this.domains.length;
         let nextDomainPos: number;
         if (domainListLen === domainPos + 1) {
            // current focus domain is last item in domain list
            nextDomainPos = 0;
         } else {
            nextDomainPos = domainPos + 1;
         }
         const nextDomain: string = this.domains[nextDomainPos];
         document.getElementById(nextDomain).focus();
      } else if (!!event && event.keyCode === 27) {
         // when press Esc key the domain list should be elapse
         event.stopPropagation();
         event.preventDefault();
         if (this.showDomainList === true) {
            this.showDomainList = false;
         }
      }
   };

   public toggleDomainList = () => {
      if (this.domainReadOnly || this.domainFrozen) {
         this.showDomainList = false;
         return;
      }
      this.showDomainList = !this.showDomainList;
      this.changeDetector.detectChanges();
   };

   public openDomainList = (evt) => {
      if (this.domainReadOnly || this.domainFrozen) {
         this.showDomainList = false;
         return;
      }
      if (evt.key === "Enter" || evt.key === "ArrowDown") {
         evt.preventDefault();
         this.showDomainList = !this.showDomainList;
      }
      if (!!evt && evt.keyCode === 27) {
         // when press Esc key the domain list should be elapse
         evt.stopPropagation();
         evt.preventDefault();
         if (this.showDomainList === true) {
            this.showDomainList = false;
         }
      }
   };

   @HostListener("document:mousedown", ["$event"]) handleMouseDown(event) {
      if (
         !event.srcElement.className.includes("ui-selectmenu-icon") &&
         !event.srcElement.className.includes("ui-menu") &&
         this.showDomainList
      ) {
         this.showDomainList = false;
      }
   }

   public _autoFocus = () => {
      setTimeout(() => {
         if (this.usernameControl.value === null) {
            $("#username").trigger("focus");
         } else {
            $("#password").trigger("focus");
         }
      });
   };
}
