/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class ScreenUtilService {
   constructor(private htmlRemoteSessionManager: HtmlRemoteSessionManager) {}
   public setCanvasVisibility = (wmksKey: string, isShow: boolean) => {
      if (clientUtil.isChromeClient() && clientUtil.isSeamlessMode()) {
         return;
      }

      const session = this.htmlRemoteSessionManager.getSessionById(wmksKey);
      if (!session) {
         console.warn("Tried to set unknown session: " + wmksKey);
         return;
      }

      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      session.setCanvasVisibility(isShow && currentSession.key === wmksKey);
   };

   public rescaleSession = (wmksKey: string) => {
      const session = this.htmlRemoteSessionManager.getSessionById(wmksKey);
      if (session) {
         session.wmks("rescale");
      }
   };
}
