/** @format */

import { Injectable } from "@angular/core";
import { EventBusService, Logger, clientUtil } from "@html-core";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { AVPermissionState, devicePermissionChangeEvent } from "./device-manager.model";

@Injectable({ providedIn: "root" })
export class DevicePermissionService {
   constructor(
      private modalDialogService: ModalDialogService,
      private eventBusService: EventBusService
   ) {}

   public async checkPermission(): Promise<AVPermissionState> {
      try {
         if (clientUtil.isChromeClient()) {
            // for Chrome client, we can always use the device no matter what state the permission is
            return { audio: "granted", video: "granted" };
         }
         // check permission before asking, this API might be different between browsers
         // @ts-ignore
         const audioResult = await navigator.permissions.query({ name: "microphone" });
         // @ts-ignore
         const videoResult = await navigator.permissions.query({ name: "camera" });
         // get the opposite because if it's not granted, we need true to open it
         return { audio: audioResult.state, video: videoResult.state };
      } catch (err) {
         Logger.error(err, Logger.RTAV);
         // this is more commonly supported than navigator.permissions.query
         // but it can't detect the "prompt" state
         const devices = await navigator.mediaDevices.enumerateDevices();
         const state = {
            audio: "granted" as PermissionState,
            video: "granted" as PermissionState
         };
         devices.forEach((device) => {
            if (device.label === "") {
               if (device.kind === "videoinput") {
                  state.video = "denied" as PermissionState;
               }
               if (device.kind === "audioinput") {
                  state.audio = "denied" as PermissionState;
               }
            }
         });
         return state;
      }
   }

   public async checkAndAskForPermission(useModal = false) {
      const permissions = await this.checkPermission();
      const hasAudioPermission = permissions.audio === "granted";
      const hasVideoPermission = permissions.video === "granted";
      // if any permission is missing, ask for it, then immediately turn off the device
      if (!hasAudioPermission || !hasVideoPermission) {
         if (useModal) {
            this.modalDialogService.showError({
               data: {
                  titleKey: "WARNING",
                  contentKey: "RTAV_ASK_PERMISSION"
               },
               callbacks: {
                  confirm: () => {
                     this.askPermission(hasAudioPermission, hasVideoPermission);
                  }
               }
            });
         } else {
            this.askPermission(hasAudioPermission, hasVideoPermission);
         }
      }
   }

   // this is used to check if the permission is granted, but there's no device in the list
   // this is for distinguishing between the user denying the permission and the user not having a device
   // because in device enumerator, we get an empty list for both situations
   // this relies on the behavior that
   // 1. when user have the devices but denied the permission, the API will return a device with an empty label
   // 2. when user doesn't have the devices and denied the permission, the API won't return any device
   public async noPermissionAndNoDevice(): Promise<{ audio: boolean; video: boolean; audioOut: boolean }> {
      const permissions = await this.checkPermission();
      const result = {
         audio: permissions.audio !== "granted",
         video: permissions.video !== "granted",
         audioOut: permissions.audio !== "granted"
      };
      const devices = await navigator.mediaDevices.enumerateDevices();
      devices.forEach((device) => {
         if (device.kind === "audioinput") {
            result.audio = false;
         }
         if (device.kind === "videoinput") {
            result.video = false;
         }
         if (device.kind === "audiooutput") {
            result.audioOut = false;
         }
      });
      return result;
   }

   private async askPermission(hasAudioPermission: boolean, hasVideoPermission: boolean) {
      if (hasAudioPermission && hasVideoPermission) {
         return;
      }
      try {
         const stream = await navigator.mediaDevices.getUserMedia({
            audio: !hasAudioPermission,
            video: !hasVideoPermission
         });
         stream?.getTracks()?.forEach((track) => {
            track?.stop();
         });
         this.eventBusService.dispatch({ type: devicePermissionChangeEvent });
      } catch (e) {
         // this means the user has manually denied the permission
         Logger.error(e, Logger.RTAV);
      }
   }
}
