/**
 * *******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { MessageHandler } from "./messageHandler";

/**
 * An abstract class with adapted MessageHandler function.
 * This class contains all the properties and methods of the MessageHandler function prototype.
 * All accesses to properties of this class will directly access the MessageHandler function's property of the same name.
 * All calls to methods of this class will directly call the MessageHandler function's method of the same name.
 */
export abstract class MessageHandlerV2 {
   private messageHandler = new MessageHandler();

   public get messageName(): string {
      return this.messageHandler.messageName;
   }
   public set messageName(messageName: string) {
      this.messageHandler.messageName = messageName;
   }

   public get messageText(): string {
      return this.messageHandler.messageText;
   }
   public set messageText(messageText: string) {
      this.messageHandler.messageText = messageText;
   }

   public get responseTag(): string {
      return this.messageHandler.responseTag;
   }
   public set responseTag(responseTag: string) {
      this.messageHandler.responseTag = responseTag;
   }

   public get content(): any {
      return this.messageHandler.content;
   }
   public set content(content: any) {
      this.messageHandler.content = content;
   }

   public get requestXML(): string {
      return this.messageHandler.requestXML;
   }
   public set requestXML(requestXML: string) {
      this.messageHandler.requestXML = requestXML;
   }

   public get state(): string {
      return this.messageHandler.state;
   }
   public set state(state: string) {
      this.messageHandler.state = state;
   }

   public get dependencyList(): any[] {
      return this.messageHandler.dependencyList;
   }
   public set dependencyList(dependencyList: any[]) {
      this.messageHandler.dependencyList = dependencyList;
   }

   public get observerList(): any[] {
      return this.messageHandler.observerList;
   }
   public set observerList(observerList: any[]) {
      this.messageHandler.observerList = observerList;
   }

   public get subHandlerList(): MessageHandlerV2[] {
      return this.messageHandler.subHandlerList;
   }
   public set subHandlerList(subHandlerList: MessageHandlerV2[]) {
      this.messageHandler.subHandlerList = subHandlerList;
   }

   public get parentHandler(): MessageHandlerV2 {
      return this.messageHandler.parentHandler;
   }
   public set parentHandler(parentHandler: MessageHandlerV2) {
      this.messageHandler.parentHandler = parentHandler;
   }

   public get composedHandlerList(): MessageHandlerV2[] {
      return this.messageHandler.composedHandlerList;
   }
   public set composedHandlerList(composedHandlerList: MessageHandlerV2[]) {
      this.messageHandler.composedHandlerList = composedHandlerList;
   }

   public get requestId(): number {
      return this.messageHandler.requestId;
   }
   public set requestId(requestId: number) {
      this.messageHandler.requestId = requestId;
   }

   public get requestIdKV(): any {
      return this.messageHandler.requestIdKV;
   }
   public set requestIdKV(requestIdKV: any) {
      this.messageHandler.requestIdKV = requestIdKV;
   }

   public get xhrObject(): any {
      return this.messageHandler.xhrObject;
   }
   public set xhrObject(xhrObject: any) {
      this.messageHandler.xhrObject = xhrObject;
   }

   public setMessageName(messageName: string) {
      MessageHandler.prototype.setMessageName.call(this, messageName);
   }

   public getMessageName(): string {
      return MessageHandler.prototype.getMessageName.call(this);
   }

   public getMessageText(): string {
      return MessageHandler.prototype.getMessageText.call(this);
   }

   public setRequestXML(requestXML?: string) {
      MessageHandler.prototype.setRequestXML.call(this, requestXML);
   }

   public getRequestXML(): string {
      return MessageHandler.prototype.getRequestXML.call(this);
   }

   public setState(state: string) {
      MessageHandler.prototype.setState.call(this, state);
   }

   public getState(): string {
      return MessageHandler.prototype.getState.call(this);
   }

   public getXhrObject(): any {
      return MessageHandler.prototype.getXhrObject.call(this);
   }

   public setXhrObject(xhrObject: any) {
      MessageHandler.prototype.setXhrObject.call(this, xhrObject);
   }

   public resetData() {
      MessageHandler.prototype.resetData.call(this);
   }

   public onUpdated() {
      MessageHandler.prototype.onUpdated.call(this);
   }

   public onBroadcast(msg: string) {
      MessageHandler.prototype.onBroadcast.call(this, msg);
   }

   public addObserver(inputHandler: MessageHandlerV2, attributeName: string) {
      MessageHandler.prototype.addObserver.call(this, inputHandler, attributeName);
   }

   public removeObserver(inputHandler: MessageHandlerV2, attributeName: string) {
      MessageHandler.prototype.removeObserver.call(this, inputHandler, attributeName);
   }

   public registerHandler(inputHandler: MessageHandlerV2, attributeName: string) {
      MessageHandler.prototype.registerHandler.call(this, inputHandler, attributeName);
   }

   public unregisterHandler(inputHandler: MessageHandlerV2, attributeName: string) {
      MessageHandler.prototype.unregisterHandler.call(this, inputHandler, attributeName);
   }

   public appendContent(content: any) {
      MessageHandler.prototype.appendContent.call(this, content);
   }

   public removeFromContent(key: string) {
      MessageHandler.prototype.removeFromContent.call(this, key);
   }

   public composeHandlerList(): MessageHandlerV2[] {
      return MessageHandler.prototype.composeHandlerList.call(this);
   }

   public cancelRequest() {
      MessageHandler.prototype.cancelRequest.call(this);
   }

   public clearSubHandlers() {
      MessageHandler.prototype.clearSubHandlers.call(this);
   }

   public hasSubHandler(): boolean {
      return MessageHandler.prototype.hasSubHandler.call(this);
   }

   public appendSubHandler(subHandler: MessageHandlerV2) {
      MessageHandler.prototype.appendSubHandler.call(this, subHandler);
   }

   public removeSubHandler(subHandler: MessageHandlerV2) {
      MessageHandler.prototype.removeSubHandler.call(this, subHandler);
   }

   public triggerExecution() {
      MessageHandler.prototype.triggerExecution.call(this);
   }

   public abstract parseResult(responseXML: XMLDocument): any;
}
