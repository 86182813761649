/**
 * ******************************************************
 * Copyright (C) 2016-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * file-transfer.service.ts --
 *
 * Module for read file and reassemble file service.
 *
 */

import Logger from "../../../core/libs/logger";
import { MKSVCHAN_CONST } from "../channels/MKSVchan/mksvchan-consts";
import { Injector } from "@angular/core";
import { FileTransferService } from "./file-transfer.service";
import { clientUtil } from "../../../core/libs/client-util";
import { UrlParameterService } from "../../../chrome-client/desktop/blast-common/url-parameter.service";

export class FTDownloadService extends FileTransferService {
   private mksClient;
   private downloadFileNum = 0;
   private downloadedFileNum = 0;
   private totalFileSize = 0;
   private policyAllowFileDownload: boolean = true;
   public unusedFileList = [];
   private type;
   constructor(mksClient: any, type: any, injector: Injector) {
      super(injector);
      this.type = type;
      this.mksClient = mksClient;
      if (clientUtil.isChromeClient()) {
         this.urlParameterService = injector.get(UrlParameterService);
      }
   }

   public downloadReady = () => {
      let enabled = false;
      if (this.type === MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT) {
         if (clientUtil.isChromeClient()) {
            this.policyAllowFileDownload = this.urlParameterService.policyAllowFileDownload;
            enabled = this.mksClient.FTUtil.config.downloadEnabled && this.policyAllowFileDownload;
         } else {
            enabled = this.mksClient.FTUtil.config.downloadEnabled;
         }
      }
      if (!enabled) {
         // If download not allowed, just return
         Logger.debug("File download is not allowed.", Logger.FILE_TRANSFER);
         return false;
      }

      if (!this.fileList || this.fileList.length < 1) {
         this.openErrorDialog("NoFileWarningDialog", this.translate._T("FT_NO_FILE_WARNING_M"));
         return false;
      }

      return true;
   };

   public verifyTotalSize = (newSize) => {
      this.totalFileSize = this.getTotalFileSizeForList(this.fileList);
      let size = this.totalFileSize + newSize,
         readableSize;
      if (size > MKSVCHAN_CONST.FILE_TRANSFER_MAX_TOTAL_SIZE) {
         readableSize = this.mksClient.FTUtil.getReadableSize(size);
         this.openErrorDialog(
            "TotalSizeTooLargeWarningDialog",
            this.translate._T("FT_TOTAL_SIZE_TOO_LARGE_WARNING_M", [readableSize])
         );
         return false;
      }

      return true;
   };

   private _hasSameValidFileInList = (file) => {
      for (let i = 0; i < this.fileList.length; i++) {
         if (
            this.fileList[i].fullPath === file.fullPath &&
            this.fileList[i].size === file.size &&
            !this.fileList[i].stopTransfer &&
            !this.fileList[i].transferError
         ) {
            return true;
         }
      }
      return false;
   };

   /**
    * abstract the duplicated files that already in the downloaded list.
    * @param  {array} fileList The array of file objects
    * @return {array} Return the duplicated file list
    */
   public abstractDuplicatedFiles = (fileList) => {
      const duplicatedFiles = [];
      if (!fileList.length || !this.fileList || !this.fileList.length) {
         return duplicatedFiles;
      }

      for (let i = 0; i < fileList.length; i++) {
         if (this._hasSameValidFileInList(fileList[i])) {
            duplicatedFiles.push(fileList.splice(i, 1)[0]);
            i--;
         }
      }
      return duplicatedFiles;
   };

   public beginDownload = (onTransferFinish) => {
      // Calculate how many file need to be download
      this.downloadFileNum = this._getDownloadFileNum();
      this.downloadedFileNum = this._getAlreadyDownloadedFileNum();
      this.totalFileSize = this.getTotalFileSizeForList(this.fileList);

      if (this.downloadFileNum === this.downloadedFileNum) {
         return;
      }

      if (this.downloadFileNum !== 0) {
         this.isTransferring = true;
         this._sendFileDownloadRequest();
      }
      if (typeof onTransferFinish === "function") {
         this.onTransferFinish = onTransferFinish;
      }
   };

   public getTotalFileSizeForList = (fileList) => {
      if (!fileList || fileList.length < 1) {
         return 0;
      }

      let size = 0;
      for (let i = 0; i < fileList.length; i++) {
         if (fileList[i].transferError !== true && fileList[i].stopTransfer !== true) {
            size += fileList[i].size;
         }
      }
      return size;
   };

   private _getDownloadFileNum = () => {
      if (!this.fileList || !this.fileList.length) {
         return 0;
      }

      let count = 0;
      // Count every valid download file, including downloaded ones
      for (let i = 0; i < this.fileList.length; i++) {
         if (this.fileList[i].stopTransfer !== true && this.fileList[i].transferError !== true) {
            count++;
         }
      }
      Logger.debug("There are: " + count + " files to download.", Logger.FILE_TRANSFER);
      return count;
   };

   private _getAlreadyDownloadedFileNum = () => {
      if (!this.fileList || !this.fileList.length) {
         return 0;
      }

      let count = 0;
      // Count every downloaded file
      for (let i = 0; i < this.fileList.length; i++) {
         if (this.fileList[i].progress === 100) {
            count++;
         }
      }
      Logger.debug("There are: " + count + " files already downloaded.", Logger.FILE_TRANSFER);
      return count;
   };

   private _stopFileDownload = (file, onDone?: any) => {
      if (!!file && !!file.fullPath && !!file.uuid) {
         this.mksClient.sendStopDownloadRequest(file.uuid + "|" + file.fullPath, onDone, () => {
            this.handleFileTransferFailure();
         });
      } else {
         this.handleFileTransferFailure();
      }
   };

   public stopFileDownload = (file) => {
      file.stopTransfer = true;
      this.downloadFileNum--;
      if (this.transferProgress === this.downloadFileNum) {
         this.isTransferring = false;
         this.downloadFileNum = 0;
         if (this.onTransferFinish) {
            this.onTransferFinish();
         }
      }
      this._stopFileDownload(file, function () {
         Logger.debug("Successfully send cancel download request.", Logger.FILE_TRANSFER);
      });
   };

   private _getPathAndUUIDFromIdentifier = function (chunkIdentifier) {
      if (!chunkIdentifier) {
         return null;
      }

      const index = chunkIdentifier.indexOf("|");
      if (index < 0 || index > chunkIdentifier.length) {
         Logger.error("Invalid identifier.", Logger.FILE_TRANSFER);
         return null;
      }

      return {
         uuid: chunkIdentifier.substr(0, index),
         fullPath: chunkIdentifier.substr(index + 1)
      };
   };

   private _sendFileDownloadRequest = () => {
      let enabled = false;

      if (this.type === MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT) {
         if (clientUtil.isChromeClient()) {
            enabled = this.mksClient.FTUtil.config.downloadEnabled && this.policyAllowFileDownload;
         } else {
            enabled = this.mksClient.FTUtil.config.downloadEnabled;
         }
      } else if (this.type === MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.PRINT) {
         enabled = this.mksClient.FTUtil.config.printDownloadEnabled;
      }

      if (!enabled) {
         return;
      }

      this.mksClient.sendFileTransferRequest(
         this.type,
         function () {
            Logger.info("File download request successfully sent", Logger.FILE_TRANSFER);
         },
         this.handleFileTransferFailure
      );
   };

   public searchReceiveFile = (chunkIdentifier) => {
      const info = this._getPathAndUUIDFromIdentifier(chunkIdentifier);

      if (info === null) {
         return null;
      }

      for (let i = 0; i < this.fileList.length; i++) {
         if (info.fullPath === this.fileList[i].fullPath && info.uuid === this.fileList[i].uuid) {
            return this.fileList[i];
         }
      }
      return null;
   };

   private _tryStopUnusedFileDownload = (chunkIdentifier) => {
      const info = this._getPathAndUUIDFromIdentifier(chunkIdentifier);

      if (info === null) {
         return false;
      }

      for (let i = 0; i < this.unusedFileList.length; i++) {
         if (info.fullPath === this.unusedFileList[i].fullPath && info.uuid === this.unusedFileList[i].uuid) {
            this._stopFileDownload({
               uuid: info.uuid,
               fullPath: info.fullPath
            });
            this.unusedFileList.splice(i, 1);
            return true;
         }
      }
      return false;
   };

   private _tryAddUnknownFileToList = function (chunkIdentifier, fileSize) {
      if (!chunkIdentifier || !fileSize) {
         return null;
      }

      let info = this._getPathAndUUIDFromIdentifier(chunkIdentifier),
         relPath = "";

      if (info === null) {
         return null;
      }

      // Find file name from file full path
      for (let i = info.fullPath.length - 1; i >= 0; i--) {
         // Take care for windows '\\' and linux '/'
         if (info.fullPath[i] === "\\" || info.fullPath[i] === "/") {
            relPath = info.fullPath.substr(i + 1);
            break;
         }
      }

      this.fileList.push({
         fullPath: info.fullPath,
         relPath: relPath,
         uuid: info.uuid,
         size: fileSize,
         readableSize: this.mksClient.FTUtil.getReadableSize(fileSize),
         fileType: MKSVCHAN_CONST.FILE_TRANSFER_FILE_TYPE.FILE,
         contentData: null,
         progress: 0,
         transferError: false,
         stopTransfer: false
      });
      return this.fileList[this.fileList.length - 1];
   };

   public reassembleChunks = (chunkNum, totalChunkNum, chunkIdentifier, chunkData, fileSize) => {
      if (
         typeof chunkNum !== "number" ||
         typeof totalChunkNum !== "number" ||
         typeof fileSize !== "number" ||
         chunkIdentifier == null ||
         chunkData == null
      ) {
         Logger.error("Cannot reassemble with wrong parameters!", Logger.FILE_TRANSFER);
         // TODO May need stop the download process
         this.isTransferring = false;
         return;
      }
      let uint8Array,
         self = this,
         file,
         stopUnusedFile;

      setTimeout(() => {
         file = self.searchReceiveFile(chunkIdentifier);

         // First search if the file chunk belongs to some file in
         // the file list
         if (file === null) {
            /**
             *  If the chunk doesn't belong to any file in file
             * list, and it is not the first chunk, do nothing
             * here.
             */
            if (chunkNum !== 0) {
               Logger.info("Cannot reassemble wrong file chunks!", Logger.FILE_TRANSFER);
               return;
            }

            /**
             * If it is the first chunk, try to find it belongs to
             * the unused file list. If so, ask server to stop
             * transferring.
             */
            stopUnusedFile = self._tryStopUnusedFileDownload(chunkIdentifier);

            // If find in unused file list, return.
            if (stopUnusedFile === true) {
               return;
            }

            /**
             * If not find in unused file list, it should be a
             * 'bug' file. See bug 1645572. We need to add it into
             * file list here.
             */
            file = self._tryAddUnknownFileToList(chunkIdentifier, fileSize);
            if (file === null) {
               return;
            }
         }
         uint8Array = new Uint8Array(chunkData);
         if (chunkNum === 0) {
            file.contentData = new Uint8Array(fileSize);
         }
         file.contentData.set(uint8Array, self.mksClient.FTUtil.config.chunkSize * chunkNum);
         file.progress = ((chunkNum + 1) / totalChunkNum) * 100;

         if (file.progress === 100) {
            self.transferProgress++;
         }

         if (self.transferProgress === self.downloadFileNum) {
            self.isTransferring = false;
            self.downloadFileNum = 0;
            if (self.onTransferFinish) {
               self.onTransferFinish();
            }
         }
      });
   };
}
