/**
 * ******************************************************
 * Copyright (C) 2014-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import * as CST from "@html-core";
import { AnonymousService } from "../service/anonymous.service";

@Injectable({
   providedIn: "root"
})
export class ConnectionURIModel {
   // URI parameters
   public params = null;
   // Whether the current URI connection is the same as the previous one.
   public reuseSession = false;

   constructor(
      private localStorageService: CST.LocalStorageService,
      private anonymousService: AnonymousService
   ) {}

   /**
    * This function detects whether it's the same user logins to the
    * broker.
    *
    * @param oldHost    Make function params same with Chrome client.
    *    oldHost is not used here for now.
    * @param callback   Use callback to deal with identical result.
    *                   It will make the function action the same with
    *    Chrome client.
    */
   public isIdenticalUser = (oldHost, callback) => {
      // If connectionURIModel.params is null, return.
      if (!this.params) {
         callback(true);
         return;
      }

      if (this._isAnonymousChanged()) {
         callback(false);
         return;
      }

      if (CST.clientUtil.isChromeClient() && this.isHWSession()) {
         // handle over the control to background page
         callback(true);
         return;
      }

      const userName = this._getUsername(),
         oldUserName = this.localStorageService.get(CST.COOKIE.USER_NAME),
         domainName = this.params.domainName,
         oldDomainName = this.localStorageService.get(CST.COOKIE.DOMAIN_NAME),
         tokenUsername = this.params.tokenUserName,
         oldTokenUsername = this.localStorageService.get(CST.COOKIE.TOKEN_USER_NAME),
         horizonId = this.params.horizonId,
         oldHorizonId = this.localStorageService.get(CST.COOKIE.HORIZON_ID);

      /**
       * NOTE: If userName contains domain, then that domain should
       * always be the same with domainName.
       * We have this check in 'connectToBroker' of view-service.js
       * If there's samlart in the url, return true or it will logout
       *
       * For WS1, diable the session reuse for WS1 launched session and
       * non-WS1 launched session.
       */
      if (
         (!!userName && !!oldUserName && !this.usernameEquals(userName, oldUserName)) ||
         !this.domainEquals(userName, domainName, oldUserName, oldDomainName) ||
         (!!tokenUsername && !!oldTokenUsername && tokenUsername !== oldTokenUsername) ||
         ((!!horizonId || !!oldHorizonId) && horizonId !== oldHorizonId)
      ) {
         callback(false);
      } else {
         callback(true);
      }
   };

   private _getUsername = (): string => {
      if (!this.params) {
         return null;
      }

      // Ignore userName if in anonymous mode
      if (this._isAnonymous()) {
         if (this.params.unauthenticatedAccessAccount) {
            return this.params.unauthenticatedAccessAccount;
         }
      }

      if (this.params.userName) {
         return this.params.userName;
      }

      if (this.params.user) {
         return this.params.user;
      }

      return null;
   };

   public getUserName = (): string => {
      return this._getUsername() || "";
   };
   public getUserDomain = (): string => {
      if (this.params && this.params.domainName) {
         return this.params.domainName;
      }
      return "";
   };

   /**
    * isHWSession
    *
    * Use the HWS session identifier to detect whether
    * the url is generated by a Workspace or not.
    */
   public isHWSession = (): boolean => {
      return !!this.params && !!this.params.horizonId;
   };

   /**
    * isF5Session
    *
    * Use the F5 session identifier to detect whether
    * the url is generated by a F5 APM server or not.
    */
   public isF5Session = (): boolean => {
      return !!this.params && !!this.params.mid;
   };

   public hasSaml = (): boolean => {
      return !!this.params && !!this.params.samlArt;
   };

   public isUrlLaunching = (): boolean => {
      return (
         !!this.params &&
         (!!this.params.desktopId ||
            !!this.params.desktopName ||
            !!this.params.applicationId ||
            !!this.params.applicationName ||
            !!this.params.itemId ||
            !!this.params.collabSessionId) &&
         (this.params.action === null || this.params.action === "start-session")
      );
   };

   public checkAnonymous = () => {
      if (
         !!this.params &&
         !!this.params.unauthenticatedAccessEnabled &&
         this.params.unauthenticatedAccessEnabled.toLowerCase() === "true"
      ) {
         this.anonymousService.setMode(true);
      } else {
         /**
          * Mark this session as anonymous.
          *
          * When user first login with anonymous account, there must be
          * unauthenticatedAccessEnabled=true in URI.
          *
          * But later if user reuses the session, it is possible that
          * unauthenticatedAccessEnabled=true doesn't exist in URI.
          *
          * That's to say, we can only use URI to check anonymous mode in
          * the first time.
          * otherwise, post the unauthenticatedAccess read from sessionStorage.
          */
         this.anonymousService.syncMode();
      }
   };
   private _isAnonymous = (): boolean => {
      return this.anonymousService.getMode();
   };

   /*
    * anonymousService.isAnonymousModeChanged() will hook the anonymous
    * change for chrome native client
    *
    * For HTML5 client, there are two cases
    *    => previous normal, current anonymous parameters => switch user
    *    => previous anonymous, current no anonymous parameters => continue anonymous
    *
    */
   private _isAnonymousChanged = (): boolean => {
      const anonParameter: boolean =
         !!this.params &&
         !!this.params.unauthenticatedAccessEnabled &&
         this.params.unauthenticatedAccessEnabled.toLowerCase() === "true";
      return (anonParameter && !this.anonymousService.getMode()) || this.anonymousService.isAnonymousModeChanged();
   };

   public getSpID = (): string => {
      if (!!this.params && !!this.params.spID) {
         return this.params.spID;
      }
      return "";
   };

   /**
    * Refer to this page:
    * https://confluence.eng.vmware.com/display/WOR/Horizon+Client+SP+Init+Flow+a.k.a+Fix+Access+Policy+support+in+Horizon
    *
    * Finally we have agreement with vIDM team that we just send the parameter
    * as it is now and they can send them back
    */
   public generateRelayInfo = (): string => {
      if (!this.params) {
         return "";
      }
      let relayInfo = "";

      for (const key in this.params) {
         if ((key === "path" || key === "action" || key === "mid" || key === "args") && !!this.params[key]) {
            relayInfo += key + "=" + encodeURIComponent(this.params[key]) + "&";
         }
      }
      // Cut the final '&'
      if (relayInfo.length >= 1) {
         relayInfo = relayInfo.substr(0, relayInfo.length - 1);
      }

      return relayInfo;
   };

   /**
    * getF5postFix
    *
    * return the mid=XXX string, currently we still not support ISO
    */
   public getF5postFix = (): string => {
      if (!this.isF5Session()) {
         return "";
      }
      return "?mid=" + this.params.mid;
   };

   public usernameEquals = (username1: string, username2: string) => {
      if (CST.UsernameUtil.domainInUsername(username1)) {
         username1 = CST.UsernameUtil.getUsername(username1);
      }
      if (CST.UsernameUtil.domainInUsername(username2)) {
         username2 = CST.UsernameUtil.getUsername(username2);
      }

      return CST.ignoreCaseEquals(username1, username2);
   };

   public domainEquals = (userName: string, domainName: string, oldUserName: string, oldDomainName: string) => {
      let domainName1 = domainName,
         domainName2 = oldDomainName;
      /**
       * It is possible there is no domainName param in URI, but the
       * username is UPN or domain\username.
       * So if username contains domain info, use it to compare with the
       * other one. If not, then use the domainName param.
       *
       * NOTE: we have domain check in uri-param-validator.js
       * The domain info in username and domainName param should always
       * be the same.
       */
      if (CST.UsernameUtil.domainInUsername(userName)) {
         domainName1 = CST.UsernameUtil.getDomain(userName);
      }
      if (CST.UsernameUtil.domainInUsername(oldUserName)) {
         domainName2 = CST.UsernameUtil.getDomain(oldUserName);
      }

      /**
       * Only compare domain when both of them exist.
       * Otherwise assume they are the same
       */
      if (!!domainName1 && !!domainName2) {
         return CST.ignoreCaseEquals(domainName1, domainName2);
      }
      return true;
   };

   // Clear URI data.
   public clear = (needReuseSession: boolean) => {
      this.params = null;
      if (needReuseSession === false) {
         this.reuseSession = false;
      }
   };
}
