/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * GetDesktopConnectionRedirectHandler.js--
 *
 *      inherited Class to handle <get-desktop-connection-redirect>
 */

import RedirectHandler from "./redirectHandler";
export default function GetDesktopConnectionRedirectHandler() {
   this.requestTag = "get-desktop-connection-redirect"; // the XML request tag name
   this.responseTag = "desktop-connection-redirect"; // the XML response tag name
}

// inherits RedirectHandler prototype
GetDesktopConnectionRedirectHandler.prototype = new RedirectHandler();

// constructor
GetDesktopConnectionRedirectHandler.constructor = GetDesktopConnectionRedirectHandler;
