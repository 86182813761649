/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input } from "@angular/core";
import { AbstractModalComponent } from "../abstract-modal.component";

@Component({
   selector: "error-dialog",
   templateUrl: "./error-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class ErrorDialogComponent extends AbstractModalComponent {
   public titanClient: boolean;
   public copied: boolean = false;
   private checkboxTime = 1500;
   @Input() public title;
   @Input() public errorMessage;
   @Input() public buttonLabel?;
   @Input() public showCopy;

   constructor() {
      super();
   }

   public confirmCopy = () => {
      if (navigator?.clipboard?.writeText) {
         navigator.clipboard.writeText(this.errorMessage);
      }
      this.copied = true;
      setTimeout(() => {
         this.copied = false;
      }, this.checkboxTime);
   };
}
