/**
 * ******************************************************
 * Copyright (C) 2018-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { AbstractModalComponent } from "../../common/commondialog/abstract-modal.component";
import { ShareFolderModel } from "../../common/model/share-folder-model";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { clientUtil, Logger } from "@html-core";
import { HtmlCDRService } from "../../../html5-client/desktop/cdr/html-cdr.service";
import { FileSystemService } from "../../desktop/cdr/cdr-lib/file-system-service";

@Component({
   selector: "setting-cdr-dialog",
   templateUrl: "./setting-cdr-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class SettingCDRDialogComponent extends AbstractModalComponent {
   private addFolders;
   private isChromeClient: boolean = clientUtil.isChromeClient();

   public allReady: boolean = true;
   public entries = [];
   public deleteFolders;

   constructor(
      private shareFolderModel: ShareFolderModel,
      private modalDialogService: ModalDialogService,
      private htmlCDRService: HtmlCDRService,
      private fileSystemService: FileSystemService
   ) {
      super();
      this.addFolders = [];
      this.deleteFolders = [];
      this.shareFolderModel.getDisplayInfo().then(async (displayInfo) => {
         for (let i = 0; i < displayInfo.length; i++) {
            if (!this.isChromeClient && displayInfo[i].htmlEntry) {
               await this.htmlCDRService.addDirectoryHandlers(displayInfo[i].htmlEntry.directoryHandler);
            }
            const info = displayInfo[i];
            let repeatEntry = false;
            setTimeout(() => {
               for (const entry of this.entries) {
                  if (info.path === entry.path) {
                     repeatEntry = true;
                  }
               }
               if (!repeatEntry) {
                  this.entries.push({
                     path: info.path,
                     valid: true,
                     entry: info.htmlEntry
                  });
               }
            });
         }
      });
   }

   public addShareFolderClicked = () => {
      this.fileSystemService
         .chooseEntry()
         .then((folderEntry) => {
            this.fileSystemService.getDisplayPath(folderEntry, async (path) => {
               const validate = await this.checkFolderValid(path, folderEntry);
               if (!validate) {
                  const findFolderInListToBeDeleted = this.deleteFolders.indexOf(path);
                  if (findFolderInListToBeDeleted > -1) {
                     this.deleteFolders.splice(findFolderInListToBeDeleted, 1);
                  }
                  this.addFolders.push({
                     entry: folderEntry,
                     path: path
                  });
                  setTimeout(() => {
                     this.entries.push({
                        path: path,
                        valid: true,
                        entry: folderEntry.directoryHandler
                     });
                  }, 0);
               }
            });
         })
         .catch((e) => {
            Logger.warning(e);
         });
   };

   private showError(msg) {
      this.modalDialogService.showError({
         data: {
            titleKey: "ERROR",
            contentKey: msg
         }
      });
   }

   private isChildOf = (child, parent) => {
      const parentTokens = parent.split("/");
      return parentTokens.every((t, i) => child.split("/")[i] === t);
   };

   private checkFolderValid = async (path, folderEntry) => {
      if (this.isChromeClient) {
         for (let i = 0; i < this.entries.length; i++) {
            if (this.entries[i].path === path) {
               this.showError("folder_already_in_the_list");
               return true;
            }
            if (this.isChildOf(path, this.entries[i].path)) {
               this.showError("parent_folder_already_in_the_list");
               return true;
            }

            if (this.isChildOf(this.entries[i].path, path)) {
               this.showError("sub_folder_already_in_the_list");
               return true;
            }
         }
         return false;
      } else {
         const res = await this.htmlCDRService.checkEntryExist(folderEntry);
         if (res === "duplicated") {
            this.showError("folder_already_in_the_list");
            return true;
         } else if (res === "child") {
            this.showError("sub_folder_already_in_the_list");
            return true;
         } else if (res === "parent") {
            this.showError("parent_folder_already_in_the_list");
            return true;
         } else {
            return false;
         }
      }
   };

   public cdrOkClicked = () => {
      for (let i = 0; i < this.addFolders.length; i++) {
         this.shareFolderModel.add(this.addFolders[i].path, this.addFolders[i].entry);
      }

      for (let i = 0; i < this.deleteFolders.length; i++) {
         this.shareFolderModel.remove(this.deleteFolders[i]);
      }

      let directoryHandlers;
      if (!this.isChromeClient) {
         directoryHandlers = this.htmlCDRService.getDirectoryHandlers();
      }
      this.shareFolderModel.save(directoryHandlers).then(() => {
         this.closeModal();
      });
   };

   public deleteFolder = async (id) => {
      for (let i = 0; i < this.entries.length; i++) {
         if (this.entries[i].path === id) {
            if (!this.isChromeClient && this.entries[i].entry) {
               await this.htmlCDRService.deleteDirectoryHandler(this.entries[i].entry.directoryHandler);
            }
            this.entries.splice(i, 1);
         }
      }
      this.deleteFolders.push(id);
   };
}
