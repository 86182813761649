<div class="timezonelist">
   <label for="timezoneList" class="hidecontentlabel">{{"timezone_select_list" | i18nv2}}</label>
   <select id="timezoneList" [disabled]="timeZoneStatus" [ngClass]="{disable:timeZoneStatus}" (change)="changeTimezone($event)">
      <option value="Etc/GMT-12">(UTC -12:00) {{"Etc_GMT_12" | i18nv2}}</option>
      <option value="Etc/GMT-11">(UTC -11:00) {{"Etc_GMT_11" | i18nv2}}</option>
      <option value="Pacific/Honolulu">(UTC -10:00) {{"Pacific_Honolulu" | i18nv2}}</option>
      <option value="America/Anchorage">(UTC -09:00) {{"America_Anchorage" | i18nv2}}</option>
      <option value="America/Tijuana">(UTC -08:00) {{"America_Tijuana" | i18nv2}}</option>
      <option value="America/Los_Angeles">(UTC -08:00) {{"America_Los_Angeles" | i18nv2}}</option>
      <option value="America/Chihuahua">(UTC -07:00) {{"America_Chihuahua" | i18nv2}}</option>
      <option value="America/Denver">(UTC -07:00) {{"America_Denver" | i18nv2}}</option>
      <option value="America/Phoenix">(UTC -07:00) {{"America_Phoenix" | i18nv2}}</option>
      <option value="America/Guatemala">(UTC -06:00) {{"America_Guatemala" | i18nv2}}</option>
      <option value="America/Chicago">(UTC -06:00) {{"America_Chicago" | i18nv2}}</option>
      <option value="America/Mexico_City">(UTC -06:00) {{"America_Mexico_City" | i18nv2}}</option>
      <option value="America/Regina">(UTC -06:00) {{"America_Regina" | i18nv2}}</option>
      <option value="America/Bogota">(UTC -05:00) {{"America_Bogota" | i18nv2}}</option>
      <option value="America/New_York">(UTC -05:00) {{"America_New_York" | i18nv2}}</option>
      <option value="America/Indiana/Indianapolis">(UTC -05:00) {{"America_Indiana_Indianapolis" | i18nv2}}</option>
      <option value="America/Caracas">(UTC -04:00) {{"America_Caracas" | i18nv2}}</option>
      <option value="America/Cuiaba">(UTC -04:00) {{"America_Cuiaba" | i18nv2}}</option>
      <option value="America/La_Paz">(UTC -04:00) {{"America_La_Paz" | i18nv2}}</option>
      <option value="America/Santiago">(UTC -04:00) {{"America_Santiago" | i18nv2}}</option>
      <option value="America/Asuncion">(UTC -04:00) {{"America_Asuncion" | i18nv2}}</option>
      <option value="America/Halifax">(UTC -04:00) {{"America_Halifax" | i18nv2}}</option>
      <option value="America/St_Johns">(UTC -03:30) {{"America_St_Johns" | i18nv2}}</option>
      <option value="America/Montevideo">(UTC -03:00) {{"America_Montevideo" | i18nv2}}</option>
      <option value="America/Buenos_Aires">(UTC -03:00) {{"America_Buenos_Aires" | i18nv2}}</option>
      <option value="America/Cayenne">(UTC -03:00) {{"America_Cayenne" | i18nv2}}</option>
      <option value="America/Godthab">(UTC -03:00) {{"America_Godthab" | i18nv2}}</option>
      <option value="America/Sao_Paulo">(UTC -03:00) {{"America_Sao_Paulo" | i18nv2}}</option>
      <option value="America/Noronha">(UTC -02:00) {{"Etc_GMT_2" | i18nv2}}</option>
      <option value="Atlantic/Azores">(UTC -01:00) {{"Atlantic_Azores" | i18nv2}}</option>
      <option value="Atlantic/Cape_Verde">(UTC -01:00) {{"Atlantic_Cape_Verde" | i18nv2}}</option>
      <option value="DMT">(UTC) {{"DMT" | i18nv2}}</option>
      <option value="Africa/Casablanca">(UTC +00:00) {{"Africa_Casablanca" | i18nv2}}</option>
      <option value="Europe/London">(UTC +00:00) {{"Europe_London" | i18nv2}}</option>
      <option value="Atlantic/Reykjavik">(UTC +00:00) {{"Atlantic_Reykjavik" | i18nv2}}</option>
      <option value="Europe/Berlin">(UTC +01:00) {{"Europe_Berlin" | i18nv2}}</option>
      <option value="Europe/Budapest">(UTC +01:00) {{"Europe_Budapest" | i18nv2}}</option>
      <option value="Europe/Paris">(UTC +01:00) {{"Europe_Paris" | i18nv2}}</option>
      <option value="Europe/Warsaw">(UTC +01:00) {{"Europe_Warsaw" | i18nv2}}</option>
      <option value="Africa/Lagos">(UTC +01:00) {{"Africa_Lagos" | i18nv2}}</option>
      <option value="Africa/Windhoek">(UTC +01:00) {{"Africa_Windhoek" | i18nv2}}</option>
      <option value="Asia/Amman">(UTC +02:00) {{"Asia_Amman" | i18nv2}}</option>
      <option value="Europe/Athens">(UTC +02:00) {{"Europe_Athens" | i18nv2}}</option>
      <option value="Asia/Beirut">(UTC +02:00) {{"Asia_Beirut" | i18nv2}}</option>
      <option value="Africa/Cairo">(UTC +02:00) {{"Africa_Cairo" | i18nv2}}</option>
      <option value="Asia/Damascus">(UTC +02:00) {{"Asia_Damascus" | i18nv2}}</option>
      <option value="Africa/Johannesburg">(UTC +02:00) {{"Africa_Johannesburg" | i18nv2}}</option>
      <option value="Europe/Kiev">(UTC +02:00) {{"Europe_Kiev" | i18nv2}}</option>
      <option value="Asia/Jerusalem">(UTC +02:00) {{"Asia_Jerusalem" | i18nv2}}</option>
      <option value="STAT">(UTC +03:00) {{"Europe_Minsk" | i18nv2}}</option>
      <option value="Europe/Istanbul">(UTC +03:00) {{"Europe_Istanbul" | i18nv2}}</option>
      <option value="Asia/Baghdad">(UTC +03:00) {{"Asia_Baghdad" | i18nv2}}</option>
      <option value="Asia/Riyadh">(UTC +03:00) {{"Asia_Riyadh" | i18nv2}}</option>
      <option value="Europe/Moscow">(UTC +03:00) {{"Europe_Moscow" | i18nv2}}</option>
      <option value="Africa/Nairobi">(UTC +03:00) {{"Africa_Nairobi" | i18nv2}}</option>
      <option value="Asia/Tehran">(UTC +03:30) {{"Asia_Tehran" | i18nv2}}</option>
      <option value="Asia/Dubai">(UTC +04:00) {{"Asia_Dubai" | i18nv2}}</option>
      <option value="Asia/Baku">(UTC +04:00) {{"Asia_Baku" | i18nv2}}</option>
      <option value="Indian/Mauritius">(UTC +04:00) {{"Indian_Mauritius" | i18nv2}}</option>
      <option value="Asia/Tbilisi">(UTC +04:00) {{"Asia_Tbilisi" | i18nv2}}</option>
      <option value="Asia/Yerevan">(UTC +04:00) {{"Asia_Yerevan" | i18nv2}}</option>
      <option value="Asia/Kabul">(UTC +04:30) {{"Asia_Kabul" | i18nv2}}</option>
      <option value="Asia/Yekaterinburg">(UTC +05:00) {{"Asia_Yekaterinburg" | i18nv2}}</option>
      <option value="Asia/Karachi">(UTC +05:00) {{"Asia_Karachi" | i18nv2}}</option>
      <option value="Asia/Tashkent">(UTC +05:00) {{"Asia_Tashkent" | i18nv2}}</option>
      <option value="Asia/Calcutta">(UTC +05:30) {{"Asia_Calcutta" | i18nv2}}</option>
      <option value="Asia/Colombo">(UTC +05:30) {{"Asia_Colombo" | i18nv2}}</option>
      <option value="Asia/Katmandu">(UTC +05:45) {{"Asia_Katmandu" | i18nv2}}</option>
      <option value="Asia/Almaty">(UTC +06:00) {{"Asia_Almaty" | i18nv2}}</option>
      <option value="Asia/Dhaka">(UTC +06:00) {{"Asia_Dhaka" | i18nv2}}</option>
      <option value="Asia/Novosibirsk">(UTC +06:00) {{"Asia_Novosibirsk" | i18nv2}}</option>
      <option value="Asia/Rangoon">(UTC +06:30) {{"Asia_Rangoon" | i18nv2}}</option>
      <option value="Asia/Bangkok">(UTC +07:00) {{"Asia_Bangkok" | i18nv2}}</option>
      <option value="Asia/Krasnoyarsk">(UTC +07:00) {{"Asia_Krasnoyarsk" | i18nv2}}</option>
      <option value="Asia/Shanghai">(UTC +08:00) {{"Asia_Shanghai" | i18nv2}}</option>
      <option value="Asia/Irkutsk">(UTC +08:00) {{"Asia_Irkutsk" | i18nv2}}</option>
      <option value="Asia/Singapore">(UTC +08:00) {{"Asia_Singapore" | i18nv2}}</option>
      <option value="Australia/Perth">(UTC +08:00) {{"Australia_Perth" | i18nv2}}</option>
      <option value="Asia/Taipei">(UTC +08:00) {{"Asia_Taipei" | i18nv2}}</option>
      <option value="Asia/Ulaanbaatar">(UTC +08:00) {{"Asia_Ulaanbaatar" | i18nv2}}</option>
      <option value="Asia/Tokyo">(UTC +09:00) {{"Asia_Tokyo" | i18nv2}}</option>
      <option value="Asia/Seoul">(UTC +09:00) {{"Asia_Seoul" | i18nv2}}</option>
      <option value="Asia/Yakutsk">(UTC +09:00) {{"Asia_Yakutsk" | i18nv2}}</option>
      <option value="Australia/Adelaide">(UTC +09:30) {{"Australia_Adelaide" | i18nv2}}</option>
      <option value="Australia/Darwin">(UTC +09:30) {{"Australia_Darwin" | i18nv2}}</option>
      <option value="Australia/Brisbane">(UTC +10:00) {{"Australia_Brisbane" | i18nv2}}</option>
      <option value="Australia/Sydney">(UTC +10:00) {{"Australia_Sydney" | i18nv2}}</option>
      <option value="Pacific/Port_Moresby">(UTC +10:00) {{"Pacific_Port_Moresby" | i18nv2}}</option>
      <option value="Australia/Hobart">(UTC +10:00) {{"Australia_Hobart" | i18nv2}}</option>
      <option value="Asia/Vladivostok">(UTC +10:00) {{"Asia_Vladivostok" | i18nv2}}</option>
      <option value="Australia/Lord_Howe">(UTC +11:00) {{"Australia_Lord_Howe" | i18nv2}}</option>
      <option value="Etc/GMT+11">(UTC +11:00) {{"Asia_Magadan" | i18nv2}}</option>
      <option value="PONT">(UTC +11:00) {{"Pacific_Guadalcanal" | i18nv2}}</option>
      <option value="Pacific/Auckland">(UTC +12:00) {{"Pacific_Auckland" | i18nv2}}</option>
      <option value="Etc/GMT+12">(UTC +12:00) {{"Etc_GMT_12" | i18nv2}}</option>
      <option value="Pacific/Fiji">(UTC +12:00) {{"Pacific_Fiji" | i18nv2}}</option>
      <option value="Asia/Kamchatka">(UTC +12:00) {{"Asia_Kamchatka" | i18nv2}}</option>
      <option value="Tonga">(UTC +13:00) {{"Pacific_Tongatapu" | i18nv2}}</option>
      <option value="locationFix" id="locationFix" [hidden]="true"></option>
   </select>
</div>