/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * mksvchan.js --
 *
 * Interface used to send and receive MKSVchan RPCs and updates.
 */

import WMKS from "WMKS";
import Logger from "../../../core/libs/logger";
import { StringUtils } from "@html-core";
import { VDP_CONSTS, IVDPServiceListener, VDPChannel, VDPService } from "../vdpservice";
import { MKSVCHAN_CONST } from "./MKSVchan/mksvchan-consts";
import { BlastWmks } from "../common/blast-wmks.service";

export namespace MKSVchan {
   export interface MksVchanCB {
      onReady(client: MKSVchan.Client): void;
      onDownloadFilesChange(client: MKSVchan.Client, downloadList: Array<Uint8Array>, error: number): void;
      onClipboardChanged(client: MKSVchan.Client, clipboard: any, error: number): void;
      onFileTransferConfigChange(error: number);
      onFileTransferError(error: number);
      onClipboardStateChanged(client: MKSVchan.Client);
   }

   export class Clipboard {
      public items: Array<Uint8Array>;
      public changed: boolean;
      constructor() {
         // Call this.clear to initialize the clipboard
         this.clear();
      }
      /**
       * Clear the data in the clipboard.
       */
      public clear = (): void => {
         this.items = new Array<Uint8Array>();
         this.changed = false;

         for (let i = MKSVCHAN_CONST.CP_FORMAT.MIN; i < MKSVCHAN_CONST.CP_FORMAT.MAX; ++i) {
            this.items[i] = null;
         }
      };

      /**
       * Return the total size of the data stored in the clipboard.
       *
       * @return {Number} size
       *    Size of all data in the clipboard.
       */
      private getTotalSize = (): number => {
         let size = 0;

         for (let i = 0; i < this.items.length; ++i) {
            size += this.items[i] ? this.items[i].byteLength : 0;
         }

         return size;
      };

      /**
       * Return the clipboard item for the specified format.
       *
       * @param  {MKSVCHAN_CONST.CP_FORMAT} format
       *    The item format.
       * @return {Uint8Array?} data
       *    The data in the clipboard, or null.
       */
      public getItem = (format: number): Uint8Array => {
         if (format < MKSVCHAN_CONST.CP_FORMAT.MIN || format > MKSVCHAN_CONST.CP_FORMAT.MAX) {
            Logger.error("Clipboard.getItem invalid format", Logger.CLIPBOARD);
            return null;
         }

         return this.items[format];
      };

      /**
       * Set the clipboard data for a specific format.
       *
       * Will automatically attempt to trim text data to fit, or attempt to
       * trim the clipboard's existing text data to fit non-text data.
       *
       * @param {MKSVCHAN_CONST.CP_FORMAT} format
       *    The format of the data to set.
       * @param {Uint8Array} data
       *    The actual data to set.
       * @return {Boolean} success
       *    Returns true on success, false on failure
       */
      public setItem = (format: number, data: Uint8Array): boolean => {
         let size, excess, text;

         if (format < MKSVCHAN_CONST.CP_FORMAT.MIN || format > MKSVCHAN_CONST.CP_FORMAT.MAX) {
            Logger.error("Clipboard.setItem invalid format", Logger.CLIPBOARD);
            return false;
         }

         if (data && !(data instanceof Uint8Array)) {
            Logger.error("Clipboard.setItem expects data to be a Uint8Array", Logger.CLIPBOARD);
            return false;
         }

         // Get size of current clipboard contents
         size = this.getTotalSize();

         // If we are replacing an item, subtract size of item we are replacing
         if (this.items[format]) {
            size -= this.items[format].byteLength;
         }

         // Add size of new clipboard contents
         if (data) {
            size += data.byteLength;
         }

         // Ensure we do not exceed the maximum clipboard data size
         if (size >= MKSVCHAN_CONST.MAX_BYTES_IMAGE_AND_RTF) {
            excess = size - MKSVCHAN_CONST.MAX_BYTES_IMAGE_AND_RTF;

            if (format === MKSVCHAN_CONST.CP_FORMAT.TEXT) {
               // Attempt to trim excess from the new text data
               if (excess < data.byteLength) {
                  Logger.debug(
                     "Clipboard.setItem has trimmed " + excess + " bytes from the new text data",
                     Logger.CLIPBOARD
                  );
                  data = data.subarray(0, data.byteLength - excess);
                  excess = 0;
               }
            } else if (this.items[MKSVCHAN_CONST.CP_FORMAT.TEXT]) {
               // Attempt to trim excess from existing text item to fit new data
               text = this.items[MKSVCHAN_CONST.CP_FORMAT.TEXT];

               if (excess < text.byteLength) {
                  Logger.debug(
                     "Clipboard.setItem has trimmed " + excess + " bytes from existing text data",
                     Logger.CLIPBOARD
                  );
                  this.items[MKSVCHAN_CONST.CP_FORMAT.TEXT] = text.subarray(0, text.byteLength - excess);
                  excess = 0;
               }
            }

            if (excess > 0) {
               Logger.debug(
                  "Clipboard.setItem will cause clipboard to exceed maximum size." +
                     " data size: " +
                     data.byteLength +
                     " clipboard size: " +
                     this.getTotalSize() +
                     " maximum size: " +
                     MKSVCHAN_CONST.MAX_BYTES_IMAGE_AND_RTF,
                  Logger.CLIPBOARD
               );
               return false;
            }
         }

         this.changed = true;
         this.items[format] = data;
         return true;
      };

      /**
       * Returns the clipboard's text value as a string.
       *
       * @param format: clipboard format, default value text
       */
      public getText = (format: number): string => {
         if (format < MKSVCHAN_CONST.CP_FORMAT.MIN || format > MKSVCHAN_CONST.CP_FORMAT.MAX) {
            Logger.error("Clipboard.getText invalid format", Logger.CLIPBOARD);
            return null;
         }
         let clipboardFormat = format || MKSVCHAN_CONST.CP_FORMAT.TEXT,
            text = this.getItem(clipboardFormat);

         if (text == null) {
            return null;
         }
         if (text.length > 0 && text[text.length - 1] === 0) {
            text = text.subarray(0, text.length - 1);
         }

         /**
          * Replace \n with \r\n if it is MKSVCHAN_CONST.CP_FORMAT.TEXT
          * See bug 1512956
          * mksvchan server will trim \r\n to \n. Client need to add it back.
          */
         let textStr = StringUtils.uint8ArrayToString(text);
         if (clipboardFormat === MKSVCHAN_CONST.CP_FORMAT.TEXT) {
            textStr = textStr.replace(/\r?\n/g, "\r\n");
         }
         return textStr;
      };

      /**
       * Sets the clipboard's text value.
       *
       * @param text: a string of text to set the clipboard to.
       * @param format: clipboard format, default value text
       */
      public setText = (text: string, format: number): void => {
         if (format < MKSVCHAN_CONST.CP_FORMAT.MIN || format > MKSVCHAN_CONST.CP_FORMAT.MAX) {
            Logger.error("Clipboard.setText invalid format", Logger.CLIPBOARD);
            return;
         }
         const clipboardFormat = format || MKSVCHAN_CONST.CP_FORMAT.TEXT;
         /**
          * Replace \r\n with \n if it is MKSVchan.CP_FORMAT.TEXT
          * to avoid more blank line when paste from client to agent
          * See bug 2522958
          */
         if (clipboardFormat === MKSVCHAN_CONST.CP_FORMAT.TEXT) {
            text = text.replace(/\r\n/g, "\n");
         }
         this.setItem(clipboardFormat, StringUtils.stringToUint8Array(text, true));
      };

      /**
       * Sets the clipboard's html value.
       *
       * @param html: a string of html to set the clipboard to.
       * @param format: clipboard format, default value html
       */
      public setHtml = (html: string, format: number): void => {
         if (format < MKSVCHAN_CONST.CP_FORMAT.MIN || format > MKSVCHAN_CONST.CP_FORMAT.MAX) {
            Logger.error("Clipboard.setHtml invalid format", Logger.CLIPBOARD);
            return;
         }
         const clipboardFormat = format || MKSVCHAN_CONST.CP_FORMAT.HTML_FORMAT;
         this.setItem(clipboardFormat, StringUtils.stringToUint8Array(html, true));
      };

      /**
       * Set the value of the changed flag.
       *
       * @parameter {Boolean} value
       *    New value of the changed flag.
       */
      public setChanged = (value: boolean): void => {
         this.changed = value;
      };

      /**
       * Gets the value of the changed flag.
       *
       * @return {Boolean} changed
       *    Value of the changed flag.
       */
      private getChanged = (value: boolean): boolean => {
         return this.changed;
      };
   }

   /**
    * Create a new file transfer util
    *
    * @classdesc
    *    File transfer util. Each MKSVchan client should keep its own one.
    *
    * @constructor
    * @return {MKSVchan.FTUtil}
    */
   export class FTUtil {
      public downloadList;
      public uploadList;
      public fileChunkQueue: any;
      public currentDownloadService: any;
      //TOGO-NG: the type should be FTDownloadService, will be updated later
      public config: any;

      constructor() {
         this.downloadList = {};
         this.downloadList[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT] = [];
         this.uploadList = [];
         this.fileChunkQueue = [];
         this.currentDownloadService = null;
         this.config = {
            // Second release version, set it to 1 as default
            serverVersion: 1,
            clientVersion: 1,
            // For file transfer download only
            downloadEnabled: 0,
            uploadEnabled: 0,
            // Default chunk size is 32KB
            chunkSize: 1024 * 32,
            // Largest chunk size for HTML Access
            maxChunkSize: 65535,
            // Largest chunk number for HTML Access
            maxChunkNum: 65535
         };
      }

      public parseDownloadListString = (fileList: string): Array<Uint8Array> => {
         if (!fileList) {
            Logger.info("parseFileListString: empty string found.", Logger.CLIPBOARD);
            return [];
         }

         let resList = [],
            fileListArray = fileList.split("\0"),
            fileNum = fileListArray.length / 5,
            fileInfoObj = {},
            fileType: number,
            fileSize: number;

         if (fileListArray.length % 5 !== 0) {
            Logger.error("parseFileListString: string format is not right.", Logger.CLIPBOARD);
            return [];
         }

         for (let i = 0; i < fileNum; i++) {
            fileType = parseInt(fileListArray[fileNum * 3 + i]);
            fileSize = parseInt(fileListArray[fileNum * 2 + i]);
            fileInfoObj = {
               fullPath: fileListArray[i],
               relPath: fileListArray[fileNum + i],
               uuid: fileListArray[fileNum * 4 + i],
               size: fileSize,
               readableSize: this.getReadableSize(fileListArray[fileNum * 2 + i]),
               fileType: fileType,
               contentData: null,
               progress: 0,
               transferError: fileType !== 0 || fileSize === 0 || fileSize > MKSVCHAN_CONST.FILE_TRANSFER_MAX_FILE_SIZE,
               stopTransfer: false
            };
            resList.push(fileInfoObj);
         }

         return resList;
      };

      public getReadableSize = (readableSize: string): string | number => {
         const size = parseInt(readableSize);
         if (typeof size !== "number") {
            Logger.error(size + "is not legal number", Logger.CLIPBOARD);
            return 0;
         }

         const kb = 1024,
            mb = 1024 * 1024,
            gb = 1024 * 1024 * 1024;

         if (size === 0) {
            return "0 byte";
         } else if (size < kb) {
            return size.toFixed(2) + "bytes";
         } else if (size >= kb && size < mb) {
            return (size / kb).toFixed(2) + "KB";
         } else if (size >= mb && size < gb) {
            return (size / mb).toFixed(2) + "MB";
         } else if (size >= gb) {
            return (size / gb).toFixed(2) + "GB";
         } else {
            Logger.info("getReadableSize: file is too large.", Logger.CLIPBOARD);
         }
      };

      public downloadServiceSwitcher = (
         services: Array<any>,
         //TODO_NG: the type of this is FTDownloadService, will change after it is updated,
         chunkNum: number,
         chunkFileIdentifier: string
      ) => {
         if (!services || services.length < 1 || !chunkFileIdentifier) {
            Logger.debug("Params illegal", Logger.CLIPBOARD);
            return null;
         }

         // No need to switch service if there is only one.
         if (services.length === 1) {
            return services[0];
         }

         // Only run service detection for the first packet.
         if (chunkNum > 0 && !!this.currentDownloadService) {
            return this.currentDownloadService;
         }

         let service = null;
         for (let i = 0; i < services.length; i++) {
            service = services[i];
            if (!!service.searchReceiveFile && !!service.searchReceiveFile(chunkFileIdentifier)) {
               this.currentDownloadService = service;
               return service;
            }
         }
         Logger.error("Cannot find appropriate download service for" + " the packet!", Logger.CLIPBOARD);
         return null;
      };

      public updateConfig = (serverVersion: number, policy: number, chunkSize: number) => {
         if (serverVersion !== this.config.clientVersion) {
            Logger.warning(
               "File transfer server version is: " +
                  serverVersion +
                  ". But the client version is: " +
                  this.config.clientVersion +
                  ". Only part of functionality can be used",
               Logger.CLIPBOARD
            );
            this.config.serverVersion = serverVersion;
         }

         if (policy < MKSVCHAN_CONST.FILE_TRANSFER_POLICY.MIN || policy > MKSVCHAN_CONST.FILE_TRANSFER_POLICY.MAX) {
            Logger.error("Wrong file transfer policy, use default setting.", Logger.CLIPBOARD);
            return;
         }

         if (chunkSize < 0 || chunkSize > this.config.maxChunkSize) {
            Logger.error("Wrong file transfer chunk size, use default setting.", Logger.CLIPBOARD);
            return;
         }

         // If parameter is right, set it to env
         this.config.chunkSize = chunkSize;
         this.config.uploadEnabled =
            policy === MKSVCHAN_CONST.FILE_TRANSFER_POLICY.BIDIRECTIONAL ||
            policy === MKSVCHAN_CONST.FILE_TRANSFER_POLICY.ONLY_TO_SERVER;
         this.config.downloadEnabled =
            policy === MKSVCHAN_CONST.FILE_TRANSFER_POLICY.BIDIRECTIONAL ||
            policy === MKSVCHAN_CONST.FILE_TRANSFER_POLICY.ONLY_TO_CLIENT;
      };

      // There is no other places to use this function, to be removed
      public searchFileFromList = (fileList, file) => {
         if (!fileList || !file || !file.fullPath) {
            return null;
         }

         let i;
         for (i = 0; i < fileList.length; i++) {
            if (fileList[i].fullPath === file.fullPath) {
               return fileList[i];
            }
         }
         return null;
      };

      public tryNormalizeUnicodeForMac = (string: string): string => {
         // Try to normalize filename for Mac, see bug 1655526 for detail
         if (window.navigator.platform.toLowerCase().indexOf("mac") >= 0) {
            try {
               string = string.normalize("NFC");
            } catch (e) {
               Logger.warning("Failed to normalize filename: " + e, Logger.CLIPBOARD);
            }
         }
         return string;
      };
   }

   export class Client {
      public vdpChannel;
      public vdpControlObject;
      public vdpDataObject;
      public key;

      public uploadService;
      public downloadServices;

      public ready: boolean;
      public copyEnabled: boolean;
      public pasteEnabled: boolean;
      private mksvchanCB: MksVchanCB = null;
      public session: BlastWmks = null;

      /**
       *Stores clipboard capabilities for this session. Default to enabled
       *since wmks only gives us an update if either is disabled.
       */
      private channelCallbacks: IVDPServiceListener;
      private vdpService;
      //The local copy of the remote clipboard.
      private clipboard: Clipboard;
      //The local copy of file transfer util.
      public FTUtil: FTUtil;
      //The locale of text in the clipboard.
      private locale: number;
      //The supported capabilities of MKSVchan.Client.
      private capabilities;

      constructor(session: BlastWmks) {
         this.session = session;
         this.vdpService = session.vdpService;
         /**
          * The locale of text in the clipboard.
          * @type {Number}
          */
         this.locale = 0;

         /**
          * The local copy of the remote clipboard.
          * @type {MKSVchan.Clipboard}
          */
         this.clipboard = new MKSVchan.Clipboard();

         /**
          * The local copy of file transfer util.
          * @type {MKSVchan.FTUtil}
          */
         this.FTUtil = new MKSVchan.FTUtil();

         /**
          * Called when the vdp channel becomes ready
          */
         this.ready = false;

         /**
          * Stores clipboard capabilities for this session. Default to enabled
          * since wmks only gives us an update if either is disabled.
          */
         this.copyEnabled = true;
         this.pasteEnabled = true;

         /**
          * The supported capabilities of MKSVchan.Client.
          * @type {Object}
          */
         this.capabilities = {
            version: MKSVCHAN_CONST.CAPABILITY_CLIPBOARD.NOTIFY_ON_CHANGE,
            notifyOnChangeEnabled: true,
            size: MKSVCHAN_CONST.MAX_KBYTES_IMAGE_AND_RTF
         };

         this.downloadServices = {};
         this.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT] = null;

         this.channelCallbacks = {
            onReady: (object) => {
               if (object.name === MKSVCHAN_CONST.CHANNEL_DATA_OBJECT) {
                  this.vdpDataObject = object;
                  Logger.debug("MKSVchan data channel is ready for traffic.", Logger.CLIPBOARD);
               } else if (object.name === MKSVCHAN_CONST.CHANNEL_CTRL_OBJECT) {
                  this.vdpControlObject = object;
                  Logger.debug("MKSVchan control channel is ready for traffic.", Logger.CLIPBOARD);
               }
               if (this.mksvchanCB) {
                  this.ready = true;
                  this.mksvchanCB.onReady(this);
               }
            },

            onDisconnect: (object) => {
               if (object) {
                  if (this.vdpControlObject && this.vdpControlObject.id === object.id) {
                     this.vdpControlObject = null;
                     Logger.debug("MKSVchan control channel was closed by " + "the remote desktop.", Logger.CLIPBOARD);
                  } else if (this.vdpDataObject && this.vdpDataObject.id === object.id) {
                     this.vdpDataObject = null;
                     Logger.debug("MKSVchan data channel was closed by " + "the remote desktop.", Logger.CLIPBOARD);
                  }
               } else {
                  // If object is not specified, everything disconnected!
                  this.vdpDataObject = null;
                  this.vdpControlObject = null;
                  Logger.debug(
                     "MKSVchan control and data channel was " + "closed by the remote desktop.",
                     Logger.CLIPBOARD
                  );
               }
            },

            onInvoke: (rpc) => {
               this.handleRPCFromServer(rpc);
            }
         };
      }

      /**
       * Initialize the MKSVchan client.
       *
       * Adds a listener on vdpService for channel created.
       */
      public initialize = () => {
         if (!this.vdpService) {
            Logger.error("mksVchan init failed: no vdpService specified", Logger.CLIPBOARD);
            return;
         }
         this.vdpService.addChannelCreatedListener((channel: VDPChannel) => {
            if (channel.name.indexOf(MKSVCHAN_CONST.CHANNEL_NAME_PREFIX) === 0) {
               if (this.vdpService.connectChannel(channel)) {
                  Logger.debug("Successfully accepted MKSVchan main channel", Logger.CLIPBOARD);
               } else {
                  Logger.error("Failed to open MKSVchan main channel", Logger.CLIPBOARD);
                  return;
               }

               this.vdpChannel = channel;
               channel.addCallback(this.channelCallbacks);
            }
         });
      };

      /**
       * Send request to receive remote clipboard.
       *
       * @param {Function} onDone
       *    Called when the request clipboard rpc has completed.
       * @param {Function} onAbort
       *    Called if the request clipboard rpc has aborted.
       * @return {Boolean} success
       *    Returns true on success, false on failure
       */
      public sendClipboardRequest = (onDone, onAbort) => {
         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_REQUEST, null, onDone, onAbort);
      };

      /**
       * Send request to receive remote files.
       *
       * @param {Integer} consumer
       *    Ft consumer
       * @param {Function} onDone
       *    Called when the request files rpc has completed.
       * @param {Function} onAbort
       *    Called if the request files rpc has aborted.
       */
      public sendFileTransferRequest = (consumer: string, onDone, onAbort) => {
         // Default request all the files from server
         const packet = WMKS.Packet.createNewPacketLE(),
            uint8Consumer = StringUtils.stringToUint8Array(consumer, true);

         packet.writeUint16(MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.SEND_FILES);

         if (this.FTUtil.config.serverVersion === 0) {
            // old ft request
            packet.writeUint16(0);
         } else if (this.FTUtil.config.serverVersion === 1) {
            // new ft request
            packet.writeUint16(uint8Consumer.length);
            packet.writeArray(uint8Consumer);
         }

         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_REQUEST, packet, onDone, onAbort);
      };

      /**
       * Send file chunk to MKSVchan server.
       */
      public sendFileChunk = (
         chunkNum: number,
         totalChunkNum: number,
         chunkSize: number,
         chunkIdentifier: string,
         chunkData: Array<Uint8Array>,
         fileSize: number,
         onDone,
         onAbort
      ) => {
         chunkIdentifier = this.FTUtil.tryNormalizeUnicodeForMac(chunkIdentifier);

         const packet = WMKS.Packet.createNewPacketLE(),
            uint8IdArray = StringUtils.stringToUint8Array(chunkIdentifier, true);
         packet.writeUint16(chunkNum);
         packet.writeUint16(totalChunkNum);
         packet.writeUint16(chunkSize);
         packet.writeUint16(uint8IdArray.length);
         packet.writeUint32(fileSize);
         packet.writeArray(uint8IdArray);
         packet.writeArray(chunkData);

         this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_DATA_FILE, packet, onDone, onAbort);
      };

      /**
       * Send text to remote clipboard.
       *
       * @param {String} text
       *    The text to upload to remote clipboard.
       * @param {Function} onDone
       *    Called when the send clipboard rpc has completed.
       * @param {Function} onAbort
       *    Called if the send clibpoard rpc has aborted.
       * @return {Boolean} success
       *    Returns true on success, false on failure
       */
      public sendClipboardText = (text: string, onDone, onAbort) => {
         let packet = WMKS.Packet.createNewPacketLE(),
            error = MKSVCHAN_CONST.CLIPBOARD_ERROR.NONE,
            utf8Text = StringUtils.stringToUint8Array(text, true),
            rpcDone;

         if (utf8Text.length > MKSVCHAN_CONST.MAX_BYTES_TEXT) {
            utf8Text = utf8Text.subarray(0, MKSVCHAN_CONST.MAX_BYTES_TEXT);
            utf8Text[MKSVCHAN_CONST.MAX_BYTES_TEXT - 1] = 0;
            error = MKSVCHAN_CONST.CLIPBOARD_ERROR.MAX_LIMIT_EXCEEDED;
         }

         rpcDone = function () {
            onDone(text, error);
         };

         packet.writeArray(utf8Text);
         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_DATA_TEXT, packet, rpcDone, onAbort);
      };

      /**
       * Upload a MKSVchan.Clipboard object.
       *
       * Equivalent to the format in bora/lib/dnd:
       *    uint32 max_formats
       *    for i = 1 -> max_formats
       *       uint8 exists
       *       uint32 size
       *       uint8 data[size]
       *    uint8 changed
       *
       * @param  {MKSVchan.Clipboard} clipboard
       *    The clipboard data to upload
       * @param  {Function} onDone
       *    Called when the send clipboard rpc has completed.
       * @param  {Function} onAbort
       *    Called if the send clipboard rpc has aborted.
       * @return {Boolean} success
       *    Returns true on success, false on failure
       */
      public sendClipboard = (clipboard, onDone, onAbort) => {
         let packet = WMKS.Packet.createNewPacketLE(),
            data: Uint8Array;

         packet.writeUint32(MKSVCHAN_CONST.CP_FORMAT.MAX);

         if (!((clipboard as any) instanceof MKSVchan.Clipboard)) {
            Logger.error("MKSVchan.sendClipboard expects a MKSVchan.Clipboard object", Logger.CLIPBOARD);
            return false;
         }

         if (clipboard.getTotalSize() > MKSVCHAN_CONST.MAX_BYTES_IMAGE_AND_RTF) {
            Logger.error(
               "MKSVchan.sendClipboard clipboard exceeds maximum size. " +
                  "Clipboard data must only be modified using the clipboard.setItem method",
               Logger.CLIPBOARD
            );
            return false;
         }

         for (let i = MKSVCHAN_CONST.CP_FORMAT.MIN; i < MKSVCHAN_CONST.CP_FORMAT.MAX; ++i) {
            data = clipboard.getItem(i);

            if (data) {
               Logger.debug("Sending clipboard data format: " + i + ", length: " + data.byteLength, Logger.CLIPBOARD);
               packet.writeUint8(1);
               packet.writeUint32(data.byteLength);
               packet.writeArray(data);
            } else {
               packet.writeUint8(0);
               packet.writeUint32(0);
            }
         }

         packet.writeUint8(clipboard.getChanged());
         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_DATA_CP, packet, onDone, onAbort);
      };

      public sendStopUploadRequest = (fileName: string, onDone, onAbort) => {
         fileName = this.FTUtil.tryNormalizeUnicodeForMac(fileName);

         // Default request all the files from server
         const packet = WMKS.Packet.createNewPacketLE(),
            uint8FileName = StringUtils.stringToUint8Array(fileName, true);
         packet.writeUint16(MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.CANCEL_RECEIVE_SPECIFIC_FILE);
         packet.writeUint16(uint8FileName.length);
         packet.writeArray(uint8FileName);

         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_REQUEST, packet, onDone, onAbort);
      };

      public sendStopDownloadRequest = (fileIdentifier: string, onDone, onAbort) => {
         // Default request all the files from server
         const packet = WMKS.Packet.createNewPacketLE(),
            uint8Id = StringUtils.stringToUint8Array(fileIdentifier, true);
         packet.writeUint16(MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.CANCEL_SEND_SPECIFIC_FILES);
         packet.writeUint16(uint8Id.length);
         packet.writeArray(uint8Id);

         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_REQUEST, packet, onDone, onAbort);
      };

      /**
       * Utility wrapper around vdpChannel invoke for sending RPCs.
       *
       * @param {PACKET_TYPE} command
       *    The command to invoke over rpc.
       * @param {WMKS.Packet} [packet]
       *    The packet of data to send with the command.
       * @param {Function} [onDone]
       *    Called when the rpc has completed.
       * @param {Function} [onAbort]
       *    Called if the rpc has aborted.
       * @return {Boolean} success
       *    Returns true on success, false on failure
       */
      public sendRPC = (command: number, packet: any, onDone, onAbort) => {
         if (!this.vdpChannel) {
            Logger.error("RPC send failed: vdpChannel not initialized", Logger.CLIPBOARD);
            onAbort();
            return;
         }
         return !!this.vdpChannel.invoke({
            object: this.vdpControlObject,
            command: command,
            type: VDP_CONSTS.RPC_TYPE.REQUEST,
            params: packet ? [packet.getData()] : [],
            onDone: onDone,
            onAbort: onAbort
         });
      };

      public addMksVchanObserver(cb: MksVchanCB) {
         this.mksvchanCB = cb;
      }

      public removeMksVchanObserver() {
         this.mksvchanCB = null;
      }
      /**
       * Send the capabilities of the client clipboard.
       *
       * @param {Number} capabilities
       *    The capabilities supported by the client clipboard.
       * @param {Function} onDone
       *    Called when the clipboard capabilities rpc has completed.
       * @param {Function} onAbort
       *    Called if the clipboard capabilities rpc has aborted.
       * @return {Boolean} success
       *    Returns true on success, false on failure
       */
      private sendClipboardCapabilities = (caps: number, onDone?: any, onAbort?: any) => {
         const packet = WMKS.Packet.createNewPacketLE();
         packet.writeUint32(caps);
         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_CAPABILITIES, packet, onDone, onAbort);
      };

      /**
       * Sends the locale of the client clipboard.
       *
       * @param {Number} locale
       *    The locale of client clipboard.
       * @param {Function} onDone
       *    Called when the clipboard locale rpc has completed.
       * @param {Function} onAbort
       *    Called if the clipboard locale rpc has aborted.
       * @return {Boolean} success
       *    Returns true on success, false on failure
       */
      private sendClipboardLocale = (locale, onDone?: any, onAbort?: any) => {
         const packet = WMKS.Packet.createNewPacketLE();
         packet.writeUint32(locale);
         return this.sendRPC(MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_LOCALE, packet, onDone, onAbort);
      };

      /**
       * Send final file transfer config to MKSVchan server.
       */
      private sendFileTransferConfig = (clientVersion: number, clientType: number, chunkSize: number) => {
         clientType = clientType << 4;

         const packet = WMKS.Packet.createNewPacketLE();
         packet.writeUint8(clientVersion);
         packet.writeUint8(clientType);
         packet.writeUint16(chunkSize);
         return this.sendRPC(
            MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_CONFIG,
            packet,
            function () {
               Logger.debug("File transfer caps sent successfully", Logger.CLIPBOARD);
            },
            function () {
               Logger.debug("File transfer caps cannot be sent.", Logger.CLIPBOARD);
            }
         );
      };

      /**
       * Handle the remote clipboard capabilities.
       *
       * As a client, we must reply with our capabilities and locale.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleClipboardCapabilities = (rpc) => {
         let packet = WMKS.Packet.createFromBufferLE(rpc.params[0]),
            caps = packet.readUint32(),
            version = caps & 0xffff,
            size = caps >> 16,
            notifyOnChangeEnabled = false,
            capabilities = null;

         // Negotiate minimum common version and size
         version = Math.min(version, this.capabilities.version);

         if (version >= MKSVCHAN_CONST.CAPABILITY_CLIPBOARD.IMAGE_AND_RTF) {
            size = Math.min(size, this.capabilities.size);
         } else {
            size = 0;
         }

         if (version >= MKSVCHAN_CONST.CAPABILITY_CLIPBOARD.NOTIFY_ON_CHANGE) {
            notifyOnChangeEnabled = true;
         } else {
            Logger.debug(
               "mksVchan version does not support clipboard auto notifications, copying will be broken",
               Logger.CLIPBOARD
            );
         }

         this.capabilities.version = version;
         this.capabilities.notifyOnChangeEnabled = notifyOnChangeEnabled;
         this.capabilities.size = size;

         capabilities = version | (size << 16);
         if (notifyOnChangeEnabled) {
            capabilities = capabilities | MKSVCHAN_CONST.ENABLE_NOTIFY_ON_CHANGE;
         }

         // Reply with our capabilities and locale
         this.sendClipboardCapabilities(capabilities);
         this.sendClipboardLocale(this.locale);
      };

      /**
       * Handle the remote clipboard state change message.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleClipboardState = (rpc) => {
         const packet = WMKS.Packet.createFromBufferLE(rpc.params[0]),
            data = packet.readUint32(),
            clipboardState = data & 0x3;
         if (clipboardState === 0) {
            this.copyEnabled = false;
            this.pasteEnabled = false;
         } else if (clipboardState === 1) {
            this.copyEnabled = true;
            this.pasteEnabled = true;
         } else if (clipboardState === 2) {
            this.copyEnabled = false;
            this.pasteEnabled = true;
         } else {
            this.copyEnabled = true;
            this.pasteEnabled = false;
         }
         if (this.mksvchanCB) {
            this.mksvchanCB.onClipboardStateChanged(this);
         }
      };

      /**
       * Handle the remote clipboard text data.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleClipboardDataText = (rpc) => {
         this.clipboard.setItem(MKSVCHAN_CONST.CP_FORMAT.TEXT, rpc.params[0]);

         if (this.mksvchanCB) {
            this.mksvchanCB.onClipboardChanged(this, this.clipboard, 0);
         }
      };

      /**
       * Handle the remote clipboard binary data.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleClipboardDataCP = (rpc) => {
         let packet = null,
            error = MKSVCHAN_CONST.CLIPBOARD_ERROR.NONE,
            formats = null,
            exists: number,
            size: number,
            data: Uint8Array;

         // If first parameter is a number, it is an error code.
         if (typeof rpc.params[0] === "number") {
            error = rpc.params[0];
         } else {
            if (rpc.params[1]) {
               error = rpc.params[1];
            }
            packet = WMKS.Packet.createFromBufferLE(rpc.params[0]);
            formats = packet.readUint32();

            // Limit to our known formats
            formats = Math.max(formats, MKSVCHAN_CONST.CP_FORMAT.MAX);

            // Read clipboard data for all format types
            for (let i = MKSVCHAN_CONST.CP_FORMAT.MIN; i < formats; ++i) {
               exists = packet.readUint8();
               size = packet.readUint32();
               data = packet.readArray(size);
               this.clipboard.setItem(i, data);
            }

            // If we have only 1 byte left, it's the changed flag
            if (packet.bytesRemaining() === 1) {
               this.clipboard.setChanged(!!packet.readUint8());
            } else {
               this.clipboard.setChanged(true);
            }
         }

         const fileListStr = this.clipboard.getText(MKSVCHAN_CONST.CP_FORMAT.FILELIST),
            fileList = this.FTUtil.parseDownloadListString(fileListStr);

         if (!!fileList && fileList.length > 0 && this.mksvchanCB) {
            // Update download list
            this.mksvchanCB.onDownloadFilesChange(this, fileList, error);
         }

         if (this.mksvchanCB) {
            this.mksvchanCB.onClipboardChanged(this, this.clipboard, error);
         }
      };

      /**
       * Handle the file chunk data.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleFileTransferData = (rpc) => {
         let packet = null,
            error = MKSVCHAN_CONST.CLIPBOARD_ERROR.NONE,
            chunkNum = null,
            totalChunkNum = null,
            chunkDataSize = null,
            chunkIdentifierSize = null,
            fileSize = null,
            chunkIdentifierData = null,
            chunkIdentifier,
            chunkData,
            downloadService = null,
            services = [];

         // If first parameter is a number, it is an error code.
         if (typeof rpc.params[0] === "number") {
            error = rpc.params[0];
         } else {
            if (rpc.params[1]) {
               error = rpc.params[1];
            }
            packet = WMKS.Packet.createFromBufferLE(rpc.params[0]);

            chunkNum = packet.readUint16();
            totalChunkNum = packet.readUint16();
            chunkDataSize = packet.readUint16();
            chunkIdentifierSize = packet.readUint16();
            fileSize = packet.readUint32();

            chunkIdentifierData = packet.readArray(chunkIdentifierSize);
            chunkIdentifier = StringUtils.uint8ArrayToString(chunkIdentifierData);
            chunkData = packet.readArray(chunkDataSize);

            if (this.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT]) {
               services.push(this.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT]);
            }

            downloadService = this.FTUtil.downloadServiceSwitcher(services, chunkNum, chunkIdentifier);
            /**
             * If cannot find download service, choose a default one by the
             * order:
             * 1. File transfer
             */
            if (!downloadService) {
               downloadService = this.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT];
            }

            // If still cannot find, throw error
            if (!downloadService) {
               throw "Cannot find download service!";
            }

            downloadService.reassembleChunks(chunkNum, totalChunkNum, chunkIdentifier, chunkData, fileSize, error);
         }
      };

      /**
       * Handle the file transfer request.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleFileTransferRequest = (rpc) => {
         let packet = null,
            error = MKSVCHAN_CONST.CLIPBOARD_ERROR.NONE,
            requestType = null;

         // If first parameter is a number, it is an error code.
         if (typeof rpc.params[0] === "number") {
            error = rpc.params[0];
         } else {
            if (rpc.params[1]) {
               error = rpc.params[1];
            }
            packet = WMKS.Packet.createFromBufferLE(rpc.params[0]);
            requestType = packet.readUint16();
            switch (requestType) {
               case MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.SEND_FILES:
               case MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.CANCEL_SEND_FILES:
               case MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.SEND_SPECIFIC_FILES:
               case MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.CANCEL_SEND_SPECIFIC_FILES:
               case MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.CANCEL_RECEIVE_SPECIFIC_FILE:
                  Logger.debug("Not support the requests yet.", Logger.CLIPBOARD);
                  break;
               case MKSVCHAN_CONST.FILE_TRANSFER_REQUEST.FILE_LIST: {
                  let consumer = packet.readUint8(),
                     fileListLength = null,
                     fileListData = null,
                     fileListStr = null,
                     fileList = null;

                  if (
                     consumer > MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.MAX ||
                     consumer < MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.MIN
                  ) {
                     Logger.debug("Invalid consumer type.", Logger.CLIPBOARD);
                     return;
                  }

                  // We don't need the last null terminate.
                  fileListLength = packet.readUint32() - 1;
                  fileListData = packet.readArray(fileListLength);
                  fileListStr = StringUtils.uint8ArrayToString(fileListData);
                  fileList = this.FTUtil.parseDownloadListString(fileListStr);

                  if (!fileList || fileList.length < 1) {
                     Logger.error("Cannot find download files from server msg", Logger.CLIPBOARD);
                     return;
                  }

                  switch (consumer) {
                     case MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT: {
                        // Treat it as normal file transfer case
                        if (this.mksvchanCB) {
                           // Update download list
                           this.mksvchanCB.onDownloadFilesChange(this, fileList, error);
                        }
                        break;
                     }
                     default:
                        Logger.error("Unsupported file transfer consumer", Logger.CLIPBOARD);
                  }
                  break;
               }
               default:
                  Logger.debug("Illegal request found.", Logger.CLIPBOARD);
            }
         }
      };

      /**
       * Handle the file transfer config change.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleFileTransferConfig = (rpc) => {
         let packet = null,
            error = MKSVCHAN_CONST.CLIPBOARD_ERROR.NONE,
            serverVersion = null,
            policy = null,
            chunkSize = null,
            clientVersionSentToServer = this.FTUtil.config.clientVersion;

         // If first parameter is a number, it is an error code.
         if (typeof rpc.params[0] === "number") {
            error = rpc.params[0];
         } else {
            if (rpc.params[1]) {
               error = rpc.params[1];
            }
            packet = WMKS.Packet.createFromBufferLE(rpc.params[0]);
            serverVersion = packet.readUint8();
            policy = packet.readUint8();
            chunkSize = packet.readUint16();

            Logger.debug("File transfer version from server: " + serverVersion, Logger.CLIPBOARD);
            Logger.debug("File transfer policy from server: " + policy, Logger.CLIPBOARD);
            Logger.debug("File transfer chunk size from server: " + chunkSize, Logger.CLIPBOARD);

            this.FTUtil.updateConfig(serverVersion, policy, chunkSize);
            if (serverVersion < this.FTUtil.config.clientVersion) {
               // Adjust client version to server's for backward compatibility
               clientVersionSentToServer = serverVersion;
            }
            this.sendFileTransferConfig(clientVersionSentToServer, MKSVCHAN_CONST.FILE_TRANSFER_CLIENT.WEB, chunkSize);
         }

         if (this.mksvchanCB) {
            this.mksvchanCB.onFileTransferConfigChange(error);
         }
      };

      /**
       * Handle the file transfer error from server.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleFileTransferError = (rpc) => {
         let packet = null,
            error = MKSVCHAN_CONST.CLIPBOARD_ERROR.NONE,
            ftError = null;

         // If first parameter is a number, it is an error code.
         if (typeof rpc.params[0] === "number") {
            error = rpc.params[0];
         } else {
            if (rpc.params[1]) {
               error = rpc.params[1];
            }
            packet = WMKS.Packet.createFromBufferLE(rpc.params[0]);
            ftError = packet.readUint8();

            Logger.debug("File transfer error from server: " + ftError, Logger.CLIPBOARD);
         }

         switch (ftError) {
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.NO_ENOUGH_DISK:
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.IS_TRANSFERRING:
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.UNKNOWN_ERROR:
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.FILE_PATH_ILLEGAL: {
               if (this.mksvchanCB) {
                  this.mksvchanCB.onFileTransferError(ftError);
               }
               break;
            }
            default:
               Logger.error("Unknown error number: " + ftError, Logger.CLIPBOARD);
         }
      };

      /**
       * Handle incoming RPCs and redirect them based on command.
       *
       * @param {Object} rpc
       *    The incoming RPC object.
       */
      private handleRPCFromServer = (rpc) => {
         switch (rpc.command) {
            case MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_DATA_TEXT:
               this.handleClipboardDataText(rpc);
               break;
            case MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_DATA_CP:
               this.handleClipboardDataCP(rpc);
               break;
            case MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_CAPABILITIES:
               this.handleClipboardCapabilities(rpc);
               break;
            case MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_DATA_FILE:
               this.handleFileTransferData(rpc);
               break;
            case MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_REQUEST:
               this.handleFileTransferRequest(rpc);
               break;
            case MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_CONFIG:
               this.handleFileTransferConfig(rpc);
               break;
            case MKSVCHAN_CONST.PACKET_TYPE.FILE_TRANSFER_ERROR:
               this.handleFileTransferError(rpc);
               break;
            case MKSVCHAN_CONST.PACKET_TYPE.CLIPBOARD_STATE:
               this.handleClipboardState(rpc);
               break;
            default:
               Logger.error("MKSVchan received unexpected command: " + rpc.command, Logger.CLIPBOARD);
         }
      };
   }
}
