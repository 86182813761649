/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * certAuthHandler.js --
 *
 *     handle certificate authentication
 */

import { MessageHandler } from "./messageHandler";
import util from "../util";
import { globalArray } from "../jscdkClient";

export default function CertAuthHandler() {
   let router;

   // member variables below
   this.messageName = "cert-auth";
   this.messageText = "cert-auth";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

CertAuthHandler.prototype = new MessageHandler(); // inherits MessageHandler
// prototype
CertAuthHandler.constructor = CertAuthHandler; // constructor

/**
 * Set the request XML for saml authentication.
 *
 * @param accept [in] string containing either "true" or "false".
 */

CertAuthHandler.prototype.setRequestXML = function (accept) {
   const nameText = util.createElement("name", "accept");
   const acceptText = util.createElement("values", util.createElement("value", accept));
   const paramText = util.createElement("param", nameText + acceptText);
   const paramsText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", "cert-auth");
   this.requestXML += paramsText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
