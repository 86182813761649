/**
 * ******************************************************
 * Copyright (C) 2016-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * file-transfer-upload.service.ts --
 *
 * Module for upload file service.
 *
 */
import Logger from "../../../core/libs/logger";
import { FileTransferService } from "./file-transfer.service";
import { Injector } from "@angular/core";
import { clientUtil } from "../../../core/libs/client-util";
import { UrlParameterService } from "../../../chrome-client/desktop/blast-common/url-parameter.service";

export class FTUploadService extends FileTransferService {
   private mksClient = null;
   // Store reference for mksvchan.ftutil.fileChunkQueue
   public fileChunkQueue = null;
   private NUMBER_OF_CHUNK_SEND_ONE_TIME = 10;
   private currentNumChunkSendOneTime = 0;
   private chunkSendNum = 0;
   private policyAllowFileUpload: boolean = true;

   constructor(mksClient: any, injector: Injector) {
      super(injector);
      this.mksClient = mksClient;
      if (clientUtil.isChromeClient()) {
         this.urlParameterService = injector.get(UrlParameterService);
      }
   }

   public uploadReady = () => {
      if (clientUtil.isChromeClient()) {
         this.policyAllowFileUpload = this.urlParameterService.policyAllowFileUpload;
         if (!this.mksClient.FTUtil.config.uploadEnabled || !this.policyAllowFileUpload) {
            // If upload not allowed, just return
            Logger.debug("File upload is not allowed.", Logger.FILE_TRANSFER);
            return false;
         }
      } else {
         if (!this.mksClient.FTUtil.config.uploadEnabled) {
            // If upload not allowed, just return
            Logger.debug("File upload is not allowed.", Logger.FILE_TRANSFER);
            return false;
         }
      }
      if (!this.fileList || this.fileList.length < 1) {
         this.openErrorDialog("NoFileWarningDialog", this.translate._T("FT_NO_FILE_WARNING_M"));
         return false;
      }

      return true;
   };

   private _queueFileChunks = (file) => {
      if (file.transferError === true || file.progress > 0 || file.stopTransfer === true || file.queued === true) {
         return;
      }

      let fileChunkNum, configChunkSize, currentChunkSize, i;

      if (this.mksClient == null) {
         return;
      }

      file.queued = true;
      configChunkSize = this.mksClient.FTUtil.config.chunkSize;
      currentChunkSize = configChunkSize;
      fileChunkNum =
         file.size % configChunkSize === 0
            ? Math.floor(file.size / configChunkSize)
            : Math.floor(file.size / configChunkSize + 1);

      for (i = 0; i < fileChunkNum; i++) {
         if (i === fileChunkNum - 1) {
            currentChunkSize = file.size - (fileChunkNum - 1) * configChunkSize;
         }
         this.fileChunkQueue.push({
            fileRef: file,
            chunkNum: i,
            totalChunkNum: fileChunkNum,
            currentChunkSize: currentChunkSize,
            chunkSize: configChunkSize
         });
      }
   };

   private _removeFileChunksFromQueue = (file) => {
      for (let i = this.fileChunkQueue.length - 1; i >= 0; i--) {
         if (file === this.fileChunkQueue[i].fileRef) {
            this.fileChunkQueue.splice(i, 1);
         }
      }
   };

   private _sendNextFileChunks = () => {
      if (this.fileChunkQueue.length === 0) {
         Logger.debug("File upload finished.", Logger.FILE_TRANSFER);
         this.isTransferring = false;
         return;
      }

      let onDone, onAbort;

      onDone = () => {
         this.chunkSendNum++;

         if (this.chunkSendNum === this.currentNumChunkSendOneTime) {
            this.chunkSendNum = 0;
            this._sendNextFileChunks();
         }
      };

      onAbort = () => {
         this.isTransferring = false;
         this.fileChunkQueue.splice(0, this.fileChunkQueue.length);
      };

      this.currentNumChunkSendOneTime = Math.min(this.NUMBER_OF_CHUNK_SEND_ONE_TIME, this.fileChunkQueue.length);

      let chunks = [],
         chunk;
      for (let i = 0; i < this.currentNumChunkSendOneTime; i++) {
         chunk = this.fileChunkQueue.shift();
         chunks.push(chunk);
      }
      this._readAndSendFileChunks(chunks, onDone, onAbort);
   };

   private _checkFileValid = function (fileList, onValidDone) {
      if (!fileList || typeof onValidDone !== "function") {
         Logger.error("Error file list or onValidDone function!", Logger.FILE_TRANSFER);
         return;
      }

      // Read each item for 1 byte to verify if it is a valid file
      const fileTester = (fileIndex) => {
         if (fileIndex > fileList.length - 1) {
            onValidDone();
            return;
         }

         let currentFile = fileList[fileIndex],
            reader,
            oneByteBlob;

         if (currentFile.transferError === true || currentFile.queued === true) {
            fileTester(fileIndex + 1);
            return;
         }

         if (
            currentFile.size === 0 ||
            currentFile.size > this.mksClient.FTUtil.config.maxChunkNum * this.mksClient.FTUtil.config.chunkSize
         ) {
            setTimeout(() => {
               currentFile.transferError = true;
               fileTester(fileIndex + 1);
            });
            return;
         }

         reader = new FileReader();
         oneByteBlob = currentFile.slice(0, 1);

         reader.onload = function (evt) {
            fileTester(fileIndex + 1);
         };

         reader.onerror = function () {
            setTimeout(() => {
               currentFile.transferError = true;
               fileTester(fileIndex + 1);
            });
         };
         reader.readAsArrayBuffer(oneByteBlob);
      };

      fileTester(0);
   };

   public beginUpload = () => {
      // Remember call ready before this function
      const onValidDone = () => {
         for (let i = 0; i < this.fileList.length; i++) {
            this._queueFileChunks(this.fileList[i]);
         }

         if (this.isTransferring === false) {
            this.isTransferring = true;
            this._sendNextFileChunks();
         }
      };

      this._checkFileValid(this.fileList, onValidDone);
   };

   public stopFileUpload = (file) => {
      file.stopTransfer = true;
      this._removeFileChunksFromQueue(file);

      // If already begin upload, tell mksvchan server to release file
      if (file.progress !== 0 && file.progress !== 100) {
         this.mksClient.sendStopUploadRequest(
            file.name,
            function () {
               Logger.debug("Successfully send cancel upload request.", Logger.FILE_TRANSFER);
            },
            () => {
               this.handleFileTransferFailure();
            }
         );
      }
   };

   /**
    * readAndSendFileChunks
    *
    * Upload file chunks to MKSVchan server.
    *
    * @params fileChunk: file chunk object
    * @params onDone: onDone event
    * @params onAbort: onAbort event
    */
   private _readAndSendFileChunks = (fileChunks, onDone, onAbort) => {
      if (!fileChunks || !Array.isArray(fileChunks)) {
         Logger.error("File chunks is not a array object!", Logger.FILE_TRANSFER);
         return;
      }

      if (fileChunks.length === 0) {
         return;
      }

      let fileReader = new FileReader(),
         chunkBlob,
         readerOnload,
         readerOnerror,
         fileChunk = fileChunks.shift();

      chunkBlob = fileChunk.fileRef.slice(
         fileChunk.chunkNum * fileChunk.chunkSize,
         fileChunk.chunkNum * fileChunk.chunkSize + fileChunk.currentChunkSize
      );

      readerOnload = (evt) => {
         const uint8Array = new Uint8Array(evt.target.result);

         this.mksClient.sendFileChunk(
            fileChunk.chunkNum,
            fileChunk.totalChunkNum,
            fileChunk.currentChunkSize,
            fileChunk.fileRef.name,
            uint8Array,
            fileChunk.fileRef.size,
            function () {
               setTimeout(() => {
                  onDone();
                  fileChunk.fileRef.progress = ((fileChunk.chunkNum + 1) / fileChunk.totalChunkNum) * 100;
               });
            },
            () => {
               if (onAbort) {
                  onAbort();
               }
               this.handleFileTransferFailure();
            }
         );

         this._readAndSendFileChunks(fileChunks, onDone, onAbort);
      };

      readerOnerror = () => {
         setTimeout(() => {
            fileChunk.fileRef.transferError = true;
            onAbort();
            this.stopFileUpload(fileChunk.fileRef);
         });
      };

      fileReader.onload = readerOnload;
      fileReader.onerror = readerOnerror;
      if (chunkBlob instanceof Blob || chunkBlob instanceof File) {
         fileReader.readAsArrayBuffer(chunkBlob);
      } else {
         Logger.error("chunkBlob is not a Blob or File Object!", Logger.FILE_TRANSFER);
      }
   };
}
