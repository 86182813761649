/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { IMessage } from "./abstract-message.service";
import { LAUNCH_PROTOCOL } from "../../../titan/services/titan-constant";
import { HttpErrorResponse } from "@angular/common/http";
import { BrokerErrorMsg } from "../../../titan/services/xml/xml-request-response";

export namespace TitanMsg {
   export class ProtocolRecoveryRequest implements IMessage {
      public static readonly MSG_TYPE = "ProtocolRecoveryRequest";
      public type: string = ProtocolRecoveryRequest.MSG_TYPE;
      public id: string;
      public isApplication: boolean;
      public brokerUrl: string;
      public uagUrl: string;
      public protocolToken: string;
      public tunnelToken: string;
      public dspecId: string;
      constructor() {}
   }

   export class ProtocolRecoveryResponse implements IMessage {
      public static readonly MSG_TYPE = "ProtocolRecoveryResponse";
      public type: string = ProtocolRecoveryResponse.MSG_TYPE;
      public id: string;
      public address: string;
      constructor() {}
   }

   export class TitanSessionExpiredMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanSessionExpiredMsg";
      public type: string = TitanSessionExpiredMsg.MSG_TYPE;
   }

   export class TitanSetProtocolMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanSetProtocolMsg";
      public type: string = TitanSetProtocolMsg.MSG_TYPE;
      public id: string;
      public protocol: LAUNCH_PROTOCOL;
      constructor(id: string, protocol: LAUNCH_PROTOCOL) {
         this.id = id;
         this.protocol = protocol;
      }
   }

   export class TitanQueryAppSessionMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanQueryAppSessionMsg";
      public refresh: boolean;
      public type: string = TitanQueryAppSessionMsg.MSG_TYPE;
      constructor(refresh: boolean) {
         this.refresh = refresh;
      }
   }

   export class TitanAppSessionUpdatedMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanAppSessionUpdatedMsg";
      public type: string = TitanAppSessionUpdatedMsg.MSG_TYPE;
      constructor(public emptySession: boolean) {}
   }

   export enum APP_SESSION_STATE {
      OK = "OK",
      FAILED = "FAILED",
      HEAD_ROOM_EXPIRED = "HEAD_ROOM_EXPIRED",
      SID_NOT_FOUND = "SID_NOT_FOUND",
      CONNECTION_SERVICE_ERROR = "CONNECTION_SERVICE_ERROR",
      FAIL_TO_GET_DSPEC = "FAIL_TO_GET_DSPEC",
      CONNECTED = "CONNECTED",
      NOT_SUPPORTED = "NOT_SUPPORTED"
   }

   export class TitanAppSessionReplyMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanAppSessionReplyMsg";
      public type: string = TitanAppSessionReplyMsg.MSG_TYPE;
      public status: APP_SESSION_STATE;
      public opt: BlastConnectInfo;
      public sessionId: string;
   }

   export class ResetAllApplicationMsg implements IMessage {
      public static readonly MSG_TYPE = "ResetAllApplicationMsg";
      public type: string = ResetAllApplicationMsg.MSG_TYPE;
      constructor(public callback: Function) {}
   }

   export enum TITAN_VM_OPS {
      LOGOFF,
      RESET,
      RESTART
   }

   export class TitanVMOpsMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanVMOpsMsg";
      public type: string = TitanVMOpsMsg.MSG_TYPE;
      public ops: TITAN_VM_OPS;
      public sessionId: string;
      constructor(id: string, ops: TITAN_VM_OPS) {
         this.ops = ops;
         this.sessionId = id;
      }
   }

   export class TitanSelectAccountMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanSelectAccountMsg";
      public type: string = TitanSelectAccountMsg.MSG_TYPE;
   }

   export class TitanRevokeOTAMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanRevokeOTAMsg";
      public type: string = TitanRevokeOTAMsg.MSG_TYPE;
      constructor(public ota: string) {}
   }

   export class TitanRestfulErrorReportMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanRestfulErrorReportMsg";
      public type: string = TitanRestfulErrorReportMsg.MSG_TYPE;
      public traceId: string;
      public resource: string;
      public errors: any;
      public status: number;

      constructor(id: string, resource: string, status: number, errors) {
         this.traceId = id;
         this.resource = resource;
         this.errors = errors;
         this.status = status;
      }
   }

   export class TitanClientRestrictionMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanClientRestrictionMsg";
      public type: string = TitanClientRestrictionMsg.MSG_TYPE;
   }

   export class TitanInterceptorError implements IMessage {
      public static readonly MSG_TYPE = "TitanInterceptorError";
      public type: string = TitanInterceptorError.MSG_TYPE;
      constructor(
         public api: string,
         public resp: HttpErrorResponse
      ) {}
   }

   export class TitanCommonErrorMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanCommonErrorMsg";
      public type: string = TitanCommonErrorMsg.MSG_TYPE;
      constructor(
         public title: string,
         public message: string
      ) {}
   }

   export class TitanHttpClientBrokerErrorMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanHttpClientBrokerErrorMsg";
      public type: string = TitanHttpClientBrokerErrorMsg.MSG_TYPE;
      constructor(
         public api: string,
         public error: BrokerErrorMsg,
         public isUag,
         public dspecId,
         public dspecToken
      ) {}
   }

   export class TitanHttpClientUnknownErrorMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanHttpClientUnknownErrorMsg";
      public type: string = TitanHttpClientUnknownErrorMsg.MSG_TYPE;
      constructor(
         public api: string,
         resp: HttpErrorResponse
      ) {}
   }

   export class TitanInternalServerErrorMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanInternalServerErrorMsg";
      public type: string = TitanInternalServerErrorMsg.MSG_TYPE;
      constructor(
         public api: string,
         public domain: string,
         public status: number,
         public detail: string
      ) {}
   }
   export class TitanRequestAccessTokenMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanRequestAccessTokenMsg";
      public type: string = TitanRequestAccessTokenMsg.MSG_TYPE;
      public isRefreshRequired: boolean = false;
      constructor(isRefreshRequired: boolean) {
         this.isRefreshRequired = isRefreshRequired;
      }
   }

   export enum TITAN_ON_RAMP {
      sessionKey = "on-ramp-key"
   }
}
