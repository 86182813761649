/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
/**
 * reconnectService
 *
 * This version of implementation is for DPM, and could be replaced,
 * once the WS1 changes the invoke workflow
 *
 * And we want to keep related data and logic all sealed inside this server,
 * to ease the effort to remove it in the future.
 *
 *
 *
 * safeStorageService had been removed, not bring it back since chrome app
 * would be discarded soon, use sessionStorage directly here.
 *
 *
 * We could move other code here when refactor, but it's not a goal for
 * this patch.
 */

import { Inject, Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { ViewClientModel } from "../../../shared/common/model/viewclient-model";
import { ConnectionURIModel } from "../../../shared/common/model/connection-uri-model";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { WindowToken } from "../../common/window.module";

@Injectable({
   providedIn: "root"
})
export class ReconnectService {
   private static readonly STORAGE_KEY = "desktopsFromWs1";
   private static MAX_SUSPEND_TIME = 2 * 60 * 1000; //2 mins

   private inited: boolean = false;
   private disabled: boolean = false;
   private beingDestroyed: boolean = false;
   private appendBeforeReconnect: boolean = false;

   private horizonId: string = null;
   private reconnectDesktopSessions: Function = null;
   private reconnectAppSessions: Function = null;

   constructor(
      private viewClientModel: ViewClientModel,
      private connectionURIModel: ConnectionURIModel,
      private remoteSessionEventService: RemoteSessionEventService
   ) {}
   // Chrome Client should not call init.
   public init = (initParam) => {
      if (!initParam.horizonId) {
         Logger.debug("skip init reconnectService for non-WS1 launches");
         return;
      }
      this.inited = true;
      this.appendBeforeReconnect = true;
      /**
       * For avoid client behavior change for F5, see details in bug 2579385
       */
      this.disabled = !!this.viewClientModel.disableReconnectForWS1 || this.connectionURIModel.isF5Session();
      this.horizonId = initParam.horizonId;
      this.reconnectDesktopSessions = initParam.reconnectDesktopSessions;
      this.reconnectAppSessions = initParam.reconnectAppSessions;
      window.addEventListener("beforeunload", () => {
         this.beingDestroyed = true;
      });
      this.remoteSessionEventService.addEventListener("sessionPropertyUpdate", this.onSessionPropertyUpdate);
   };

   public onSessionPropertyUpdate = (skip: boolean, info) => {
      this.sync(info);
   };

   public isIncludedIn = (targetItem, poolArray) => {
      return poolArray.some((item) => {
         const findTarget = item.key === targetItem.key;
         if (findTarget) {
            Logger.debug("find duplicated record for: " + item.key);
         }
         return findTarget;
      });
   };

   private merge = (newList) => {
      return new Promise((resolve, reject) => {
         if (!this.appendBeforeReconnect) {
            resolve(newList);
            return;
         }
         if (!newList || newList.length === 0) {
            resolve([]);
            return;
         }
         const currentTime = new Date().getTime();
         newList.forEach((item) => {
            item.time = currentTime;
         });
         this.getTargetSessions().then((previousWS1SessionInfo: any) => {
            if (!previousWS1SessionInfo) {
               resolve(newList);
               return;
            }
            previousWS1SessionInfo = JSON.parse(previousWS1SessionInfo);
            if (!previousWS1SessionInfo.desktopSessions || !(previousWS1SessionInfo.desktopSessions.length > 0)) {
               resolve(newList);
               return;
            }
            if (previousWS1SessionInfo.horizonId !== this.horizonId) {
               resolve(newList);
               return;
            }

            previousWS1SessionInfo.desktopSessions.forEach((item) => {
               if (currentTime - item.time <= ReconnectService.MAX_SUSPEND_TIME) {
                  if (!this.isIncludedIn(item, newList)) {
                     item.time = currentTime;
                     newList.push(item);
                  }
               }
            });
            resolve(newList);
         });
      });
   };

   public sync = (sessions) => {
      if (this.disabled) {
         Logger.info("don't sync for WS1 session since multi-session supported has been disabled");
         return;
      }
      if (!this.inited) {
         Logger.debug("skip session sync since server already init");
         return;
      }
      const desktopSessions = sessions.filter((session) => !session.isApplicationSession && session.isConnected);
      this.merge(desktopSessions).then((mergedSessions) => {
         setTimeout(() => {
            if (!this.beingDestroyed) {
               window.sessionStorage.setItem(
                  ReconnectService.STORAGE_KEY,
                  JSON.stringify({
                     horizonId: this.horizonId,
                     desktopSessions: mergedSessions
                  })
               );
            }
         }, 100);
      });
   };

   public getTargetSessions = () => {
      return new Promise((resolve, reject) => {
         setTimeout(() => {
            resolve(window.sessionStorage.getItem(ReconnectService.STORAGE_KEY));
         });
      });
   };

   public reconnectForHWS = async (excludeId, needReconnectApp) => {
      if (this.disabled) {
         Logger.info("don't reconnect for WS1 session since multi-session supported has been disabled");
         return;
      }
      if (!this.inited) {
         throw "Not a WS1 session";
      }

      let previousWS1SessionInfo: any = await this.getTargetSessions();
      this.appendBeforeReconnect = false;

      if (!previousWS1SessionInfo) {
         throw "There is no previous WS1 sessions to recovery";
      }
      previousWS1SessionInfo = JSON.parse(previousWS1SessionInfo);
      if (!previousWS1SessionInfo.desktopSessions) {
         throw "There is no previous WS1 sessions to recovery";
      }
      if (previousWS1SessionInfo.horizonId !== this.horizonId) {
         throw "detect WS1 session changed, not reconnect WS1 sessions";
      }

      const targetSessions = previousWS1SessionInfo.desktopSessions.filter((session) => session.key !== excludeId);

      await this.reconnectDesktopSessions(targetSessions);
      if (needReconnectApp) {
         await this.reconnectAppSessions();
      }
   };

   public clear = () => {
      if (window.sessionStorage) {
         window.sessionStorage.removeItem(ReconnectService.STORAGE_KEY);
      }
   };
}
