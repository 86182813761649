/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";
import { ClipBuffer } from "../ClipBuffer";
import { ReSampler } from "../reSampler";
import { SyncTimer } from "../synctimer";
import { AudioParams } from "./audio-capture.model";
import { AudioEncoderWorker } from "./audio-encoder";
import { AudioVideoSample } from "./encoder.model";

@Injectable({ providedIn: "root" })
export class AudioCaptureFactory {
   constructor(private syncTimer: SyncTimer) {}
   public newAudioCapture(deviceId: string) {
      return new AudioCapture(this.syncTimer, deviceId);
   }
}

export class AudioCapture {
   // compress + translate
   private bufferLen = 128;
   // the 20ms-frame size for 8KHz audio PCM data is 160, and now we use 48kHz as the default sampleRate
   // so the required length on the server side is 960
   private clipTarget = 960;
   // the length should be enough for buffering the data
   private clipBufferLen = 1024;
   private sampleRate: number;

   private started = false;
   private streamStarted = false;
   private reSampler: ReSampler;
   private clipBuffer: ClipBuffer;

   private audioWorkletNode: AudioWorkletNode;
   private mediaCtx: BaseAudioContext;
   private mediaStreamSource: MediaStreamAudioSourceNode;
   private audioContext: AudioContext;
   private localStream: MediaStream;

   private encoder: AudioEncoderWorker;
   private emitDeviceStatusChanged: () => void;

   // these classes are lightweight singletons
   constructor(
      private syncTimer: SyncTimer,
      private deviceId: string
   ) {}

   public getId() {
      return this.deviceId;
   }

   // throwable
   // the encoder is a more resource-intensive class, thus initialized only when the device is started
   public async start(encoder: AudioEncoderWorker, emitDeviceStatusChanged: () => void, audioParam: AudioParams) {
      if (this.started) {
         Logger.error("AudioCapture has already started, please stop first", Logger.RTAV);
         return;
      }
      this.encoder = encoder;
      this.emitDeviceStatusChanged = emitDeviceStatusChanged;

      this.sampleRate = audioParam.sampleRate ?? 0;
      if (this.sampleRate <= 0 || this.sampleRate > 48000) {
         Logger.debug("AudioCapture sample rate should be in 0 to 48k", Logger.RTAV);
         this.sampleRate = 48000;
      }
      switch (this.sampleRate) {
         case 48000:
            // the 20ms-frame size for 48KHz audio PCM data is 960
            this.clipTarget = 960;
            break;
         case 16000:
            // the 20ms-frame size for 16KHz audio PCM data is 320
            this.clipTarget = 320;
            break;
         case 8000:
            // the 20ms-frame size for 8KHz audio PCM data is 160
            this.clipTarget = 160;
      }
      Logger.debug("AudioCapture desired sample rate is " + this.sampleRate, Logger.RTAV);

      const constraints: MediaStreamConstraints = {
         audio: {
            deviceId: {
               exact: this.deviceId
            },
            sampleSize: audioParam.bitsPerSample,
            channelCount: audioParam.channels
         }
      };
      // open audio device and create context
      try {
         const stream = await navigator.mediaDevices.getUserMedia({
            audio: { ...(constraints.audio as MediaTrackConstraints), sampleRate: this.sampleRate }
         });
         this.localStream = stream;
         this.audioContext = new window.AudioContext({ sampleRate: this.sampleRate });
         this.mediaStreamSource = this.audioContext.createMediaStreamSource(this.localStream);
         this.mediaCtx = this.mediaStreamSource.context;
      } catch (err) {
         Logger.debug(
            "AudioCapture sample rate " +
               this.sampleRate +
               "is not supported by AudioContext: " +
               err +
               ", using preferred sample rate instead",
            Logger.RTAV
         );
         const stream = await navigator.mediaDevices.getUserMedia({
            audio: { ...(constraints.audio as MediaTrackConstraints), sampleRate: this.sampleRate }
         });
         this.localStream = stream;
         this.audioContext = new window.AudioContext();
         this.mediaStreamSource = this.audioContext.createMediaStreamSource(this.localStream);
         this.mediaCtx = this.mediaStreamSource.context;
         this.sampleRate = Math.min(this.sampleRate, this.mediaCtx.sampleRate);
      } finally {
         this.syncTimer.reset();
         this.started = true;
         this.emitDeviceStatusChanged();
         Logger.debug("AudioCapture final sample rate is " + this.mediaCtx.sampleRate, Logger.RTAV);
      }

      if (this.sampleRate !== this.mediaCtx.sampleRate) {
         this.reSampler = new ReSampler(this.mediaCtx.sampleRate, this.sampleRate, this.bufferLen);
      }
      this.clipBuffer = new ClipBuffer(this.clipTarget, this.clipBufferLen);
   }

   // throwable
   public async startStream() {
      if (this.streamStarted) {
         Logger.error("AudioCapture stream has already started, please stop stream first", Logger.RTAV);
         return;
      }
      if (!this.started) {
         throw new Error("AudioCapture hasn't started, startStream failed");
      }
      if (!this.localStream) {
         throw new Error("AudioCapture stream is not valid, startStream failed");
      }
      try {
         await this.mediaCtx.audioWorklet.addModule("./audioWorklet." + __BUILD_NUMBER__ + ".worker.js");
         this.audioWorkletNode = new AudioWorkletNode(this.mediaCtx, "audio-processor");

         this.audioWorkletNode.port.addEventListener("message", (event) => {
            if (!this.mediaCtx) {
               return;
            }
            const capturedData = event.data.buffer;
            if (this.sampleRate !== this.mediaCtx.sampleRate) {
               Logger.warning("AudioCapture audio resampler is used", Logger.RTAV);
               const resampledBuff = this.reSampler.process(capturedData);
               this.clipBuffer.add(resampledBuff, this.syncTimer.getTime());
            } else {
               this.clipBuffer.add(capturedData, this.syncTimer.getTime());
            }
            let clip: AudioVideoSample = this.clipBuffer.getClip();
            while (clip) {
               if (!this.encoder) {
                  break;
               }
               this.encoder.encode(clip);
               clip = this.clipBuffer.getClip();
            }
         });

         this.audioWorkletNode.port.start();
         this.mediaStreamSource.connect(this.audioWorkletNode);
         this.audioWorkletNode.connect(this.mediaCtx.destination);
         this.streamStarted = true;
      } catch (err) {
         Logger.error(err, Logger.RTAV);
      }
   }

   public stopStream() {
      if (!this.started || !this.streamStarted) {
         Logger.error("AudioCapture has not started, stopStream failed", Logger.RTAV);
         return;
      }
      this.streamStarted = false;
      this.mediaStreamSource?.disconnect(this.audioWorkletNode);
      this.audioWorkletNode?.disconnect(this.mediaCtx.destination);
      this.localStream?.getTracks()?.forEach((track) => {
         track.stop();
      });
   }

   public stop() {
      if (this.streamStarted) {
         this.stopStream();
      }
      this.audioWorkletNode = null;
      this.mediaCtx = null;
      this.mediaStreamSource = null;
      // Edge doesn't support the close method defined in HTML5 standard
      if (this.audioContext && typeof this.audioContext.close === "function") {
         this.audioContext.close();
      }
      this.audioContext = null;
      this.localStream = null;
      this.encoder?.clear();
      this.encoder = null;
      this.syncTimer?.clear();
      this.started = false;
      if (this.emitDeviceStatusChanged) {
         this.emitDeviceStatusChanged();
      }
   }

   public isActive() {
      return this.started;
   }
}
