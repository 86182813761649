/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * sidebar.js --
 *
 * Module to implement sidebar component for Blast UI.
 *
 */

import { AB } from "../../../shared/desktop/common/appblast-util.service";
import Logger from "../../../core/libs/logger";
import $ from "jquery";

import { BusEvent, EventBusService, clientUtil } from "@html-core";
import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { UnityService } from "../remoteapp";
import { RunningItemsModel } from "../common/runningitems-model";
import { ContextMenuManager } from "../context-menu/context-menu-manager";
import { WmksService } from "../common/wmks.service";
import { JscdkWrapper } from "../../common/jscdk/jscdk-wrapper";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { ScreenUtilService } from "../common/screen-util.service";
import { ConnectionRetryService } from "../../common/service/connection-retry.service";
import { RemoteSessionEventService, SessionMsg } from "../../common/remote-session/remote-session-event.service";
import { BlastWmks } from "../common/blast-wmks.service";
import { PanelEvent } from "./sidebar-constant";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { ClientSettingModel } from "../../common/model/client-setting-model";
import { ContextMenuUtilService } from "../../common/context-menu/context-menu-util.service";
import { panelNotification } from "../../../html5-client/desktop/clipboard/panel-notification.component";
import { LaunchUriService } from "../common/launch-uri.service";
import { H264FreezeFixService } from "./h264-freeze-fix.service";
import { getDesktopImage, imageAsset } from "../../common/image-asset/image-asset";
import { ViewClientModel } from "../../common/model/viewclient-model";

@Component({
   selector: "sidebar",
   templateUrl: "./sidebar.component.html"
})
export class SidebarComponent {
   @ViewChild(panelNotification, { static: true }) panel: panelNotification;
   // Control content panel visibility.
   public showPanel: boolean = false;
   public util = AB;
   // App (or desktop) for which a context menu is open
   public contextMenuItem = null;

   // Declares running items search query object.
   public queryText = {
      name: ""
   };

   // Flag to indicate whether or not the preferences should be persisted
   // when the sidebar is closed
   private arePreferDirty: boolean = false;

   private resizeHandler;

   private _searchElem = $(".search-input");
   private timeoutID;

   public exitMultimonDialog;

   public runningItems;
   private errorDialogId: string = null;
   public isTitanClient: boolean = clientUtil.isTitanClient();
   private prefPromise;
   public isDiffApp: boolean;
   public contextmenuName = "htmlaccess";
   public paneClass = ".content-panel-container";
   public isWebviewMode = false;
   public imageAsset = imageAsset;

   constructor(
      private unityService: UnityService,
      private runningItemsModel: RunningItemsModel,
      private contextMenuManager: ContextMenuManager,
      private wmksService: WmksService,
      private jscdkWrapper: JscdkWrapper,
      private remoteSessionEventService: RemoteSessionEventService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private screenUtilService: ScreenUtilService,
      private connectionRetryService: ConnectionRetryService,
      private eventBusService: EventBusService,
      private ref: ChangeDetectorRef,
      private modalDialogService: ModalDialogService,
      private clientSettingModel: ClientSettingModel,
      private contextMenuUtilService: ContextMenuUtilService,
      private launchUriService: LaunchUriService,
      private h264FreezeFixService: H264FreezeFixService,
      private viewClientModel: ViewClientModel
   ) {
      this.isWebviewMode =
         this.viewClientModel.enableExtendedAPI && this.launchUriService.urlParams.webviewMode === "true";
      this.runningItems = this.runningItemsModel.getRunningItems();

      /**
       *  when Ctrl + Shift + Alt/Option + ArrowUp combine key is enabled in web client,
       *  user press those combine key should change focus from remote desktop to sidebar
       */
      window.document.body.addEventListener(
         "keydown",
         (e) => {
            if (
               this.clientSettingModel.getBooleanItem("enableCtrlShiftAltUpKey") &&
               e.ctrlKey === true &&
               e.shiftKey === true &&
               e.altKey === true &&
               e.key === "ArrowUp"
            ) {
               e.preventDefault();
               e.stopPropagation();
               if (!this.showPanel) {
                  this.togglePanel();
               }
               setTimeout(() => {
                  $(".horizon-logo-image")[0].focus();
               }, 0);
               const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
               if (currentSession) {
                  currentSession.wmks(
                     "sendKeyCodes",
                     [17, 18, 16, 38], // Key codes
                     [0x1d, 0x38, 0x2a, 0x148], // VSCAN codes with hexdecimal
                     true
                  );
               }
            }
         },
         true
      );

      /**
       * For bug 2563555, use <get-launch-items> to update the item list, and check
       * authentication status instead of <get-authentication-status>.
       */
      this.runningItemsModel.addEventListener("pullItemsList", () => {
         this.jscdkWrapper.sendLaunchItemsXML();
      });

      this.runningItemsModel.addEventListener("valueChanged", (actionType, wmksKey) => {
         // hide the application context menu if the sidebar contents
         // have changed
         setTimeout(() => {
            contextMenuManager.closeMenu();
            if (actionType === "itemRemoved") {
               this.updateFocusItem(wmksKey);
            }
            if (actionType === "appFocusUpdate") {
               htmlRemoteSessionManager.activeSession(wmksKey);
            }
         });
      });

      this.runningItemsModel.addEventListener("noVisibleWindow", () => {
         this._toggleShowPanel(PanelEvent.SHOW);

         // If there is no running session, close ft panel
         if (!this.isRunningSessionExist()) {
            this.eventBusService.dispatch({
               type: "toggleFileTransferPanel",
               data: true
            });
         }
      });

      this.eventBusService.listen("UpdateAzureWaitingUI").subscribe((msg) => {
         if (this.timeoutID) {
            clearTimeout(this.timeoutID);
            this.timeoutID = null;
         }
         // Remove busy cursor.
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
         // ignore the case of dialog exist then enable connection retry
         // use jscdkWrapper instead of invoker since it's a wrapper with same API
         if (window.location.hash.indexOf("#/desktop") === 0) {
            this.connectionRetryService.showDialog(msg.data.waitMinutes, msg.data.type, this.jscdkWrapper);
         }
      });

      this.eventBusService.listen(BusEvent.NotificationMsg.MSG_TYPE).subscribe((msg: BusEvent.NotificationMsg) => {
         this.panel.showImgNotification(msg.notificationKey);
      });

      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTED_MSG, (session) => {
         // On current session connection, close the sidebar
         if (this.showPanel && this.htmlRemoteSessionManager.isCurrentSession(session.key)) {
            this._toggleShowPanel(PanelEvent.HIDE);
         }
      });

      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, (session: BlastWmks) => {
         if (!this.htmlRemoteSessionManager.isCurrentSessionApp()) {
            /*
             * In webmks, resize function needs canvas offsetWidth and
             * offsetHeight, but hidden element doesn't have these attribute
             * Only display ele could get this value, so here trigger resize
             * again when canvas is active. Bug 1980225
             */
            if (session) {
               this.screenUtilService.rescaleSession(session.key);
            }
         }
         this.wmksService.setCanvasParentOverflow();
         this.h264FreezeFixService.updateResolutionForH264();
      });

      this.remoteSessionEventService.addEventListener("sessionDisconnected", (session) => {
         if (!this.showPanel && this.htmlRemoteSessionManager.isCurrentSession(session.key)) {
            this._toggleShowPanel(PanelEvent.SHOW);
         }
      });

      this.remoteSessionEventService.addEventListener("sessionDisconnected", (session) => {
         if (this.htmlRemoteSessionManager.isCurrentSession(session.key)) {
            this.wmksService.destroyMultimon();
         }
      });

      $(".apps-panel").on("scroll", this.scrollHandler);
      // angular.element($(".apps-panel")).bind("scroll", this.scrollHandler);
   }

   ngAfterViewInit() {
      /**
       * Handler to be called when the window is resized.
       *
       * Changes the max-height of the apps panel to make sure it scrolls
       * properly.
       */
      this.resizeHandler = () => {
         let newMaxHeight = $(window).height() - $(".head-panel")[0].offsetHeight;

         if (newMaxHeight < 0) {
            newMaxHeight = 100;
         }

         $(".apps-panel").css("max-height", newMaxHeight);
         this._reviseAppPanelHeight();
      };
      this.resizeHandler();
      window.addEventListener("resize", this.resizeHandler);
   }

   ngAfterViewChecked() {
      $("#sidebar-toggler").toggleClass("sidebar-out", this.showPanel);
   }

   ngAfterDestroy() {
      window.removeEventListener("resize", this.resizeHandler);
      $(".apps-panel").unbind("scroll", this.scrollHandler);

      if (this.timeoutID) {
         clearTimeout(this.timeoutID);
         this.timeoutID = null;
      }
   }

   public isUsingWebcam(item: any): boolean {
      return this.wmksService.isUsingWebcam(item);
   }

   public isAskingWebcam(wmksKey: string): boolean {
      return this.wmksService.isAskingWebcam(wmksKey);
   }

   public isUsingMicrophone(item: any): boolean {
      return this.wmksService.isUsingMicrophone(item);
   }

   public isAskingMicrophone(wmksKey: string): boolean {
      return this.wmksService.isAskingMicrophone(wmksKey);
   }

   public showRunningItemTooltip = () => {
      $(".icon-and-name-container").tooltip();
   };

   public showContextMenuTooltip = () => {
      $(".running-app-context-menu").tooltip();
   };

   public togglePanel = (event: PanelEvent = PanelEvent.TOGGLE) => {
      this._toggleShowPanel(event);
   };

   public sidebarToggleKeydown = (event) => {
      /**
       * when focus on sidebar toggle, press Tab key will change focus to browser
       * then back to remote desktop
       */
      if (!!event && event.keyCode === 9) {
         $("#clipboard-input")[0].focus();
      } else if (!!event && event.keyCode === 13) {
         this._toggleShowPanel(event);
      }
   };

   public sliderMoveStart = () => {
      // this.$apply(() => {
      this.contextMenuManager.closeMenu();
      // });
   };

   /**
    * Given an app item, toggle the collapsed state of its instances
    * dropdown UI
    *
    * @param currentItem: the current item that requested the toggle
    */
   public toggleIsCollapsed = (currentItem) => {
      if (AB.isLoadingItem(currentItem.type) || AB.isApp(currentItem.type)) {
         currentItem.isCollapsed = !currentItem.isCollapsed;
      }
   };

   public activateItem = (currentItem) => {
      if (this.htmlRemoteSessionManager.isCurrentSession(currentItem.wmksKey)) {
         this._activateItem(currentItem);
         return;
      }
      this.wmksService.whenNotInMultimon(() => {
         this._activateItem(currentItem);
      });
   };

   /**
    * Handler to be called when item section is clicked.
    *
    * If we have only one unity window, we will activate this unity
    * Window. If there is more than one unity window we activate the
    * first one only if the app unless on of the windows has focus in
    * which case we bring that one to front.
    *
    * @TODO consider keeping track of the last window used for every app
    *    so we can bring that window to front when the app is activated
    *    via this method.
    *
    * For desktop item, do nothing in Q1 release.
    *
    * @param currentItem        the current item object which is clicked.
    */
   private _activateItem = (currentItem) => {
      let index, selectedItem, isSameActiveItem;

      if (this.htmlRemoteSessionManager.isCurrentSession(currentItem.wmksKey)) {
         isSameActiveItem = true;
      } else {
         Logger.info("The selected session has been activated");
         isSameActiveItem = false;
      }

      // The "Loading" item doesn't have an associated wmksKey
      if (!AB.isLoadingItem(currentItem.type)) {
         this.htmlRemoteSessionManager.activeSession(currentItem.wmksKey);
      }

      if (AB.isLoadingItem(currentItem.type) || AB.isApp(currentItem.type)) {
         // Only bring this app to front if it is not already in front.
         // Bring up the most recently used window if it is known,
         // otherwise bring up the first window in the instance list.
         if (currentItem.focusedItem === null) {
            if (currentItem.orderedInstances.length > 0) {
               index = currentItem.orderedInstances.length - 1;
               selectedItem = currentItem.orderedInstances[index];
            } else {
               selectedItem = currentItem.instances[0];
            }
         } else {
            selectedItem = currentItem.focusedItem;
         }

         // If we clicked the "Loading" item, set focus to the selected
         // instance
         if (AB.isLoadingItem(currentItem.type)) {
            this.htmlRemoteSessionManager.activeSession(selectedItem.wmksKey);
         }
         this.unityService.toggleWindow(selectedItem.wmksKey, selectedItem.windowId);
      }
   };

   public activateWindow = (runningItem) => {
      const needSwitchSession = this.htmlRemoteSessionManager.needSwitchSession(runningItem.wmksKey);
      if (needSwitchSession) {
         this.wmksService.whenNotInMultimon(() => {
            this._activateWindow(runningItem);
         });
      } else {
         this._activateWindow(runningItem);
      }
   };

   private _activateWindow = (runningItem) => {
      this.htmlRemoteSessionManager.activeSession(runningItem.wmksKey);
      this.unityService.toggleWindow(runningItem.wmksKey, runningItem.windowId);
   };

   public clearSearch = () => {
      this.queryText = {
         name: ""
      };
   };

   public contextMenuClicked = (horizonApp, event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      horizonApp.id = "isrunning-" + horizonApp.wmksKey;
      this.isDiffApp = this.contextMenuUtilService.isDiffApp(horizonApp);
      horizonApp.showContextMenu = !horizonApp.showContextMenu;
      if (horizonApp.isDesktop && horizonApp.name) {
         const contextMenuId = horizonApp.name + "-contextMenu";
         $("#" + contextMenuId).attr("aria-expanded", horizonApp.showContextMenu);
      }

      if (this.isDiffApp && this.contextMenuUtilService.existingAppWithContextMenu) {
         this.contextMenuUtilService.existingAppWithContextMenu.showContextMenu = false;
      }
      this.contextMenuUtilService.updateExistingContextAppId(horizonApp.id);
      this.contextMenuUtilService.updateExistingContext(horizonApp);
   };

   public onKeypress = (runningItem, event, type) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         if (type === "contextMenuClicked") {
            this.contextMenuClicked(runningItem, event);
         }
         if (type === "closeRunningApp") {
            this.closeRunningApp(runningItem);
         }
      }
   };

   public closeRunningApp = (currentItem) => {
      let i = 0,
         sortedInstances,
         instanceIds = [];

      if (!currentItem) {
         return;
      }

      Logger.info("Close current item " + currentItem.name);
      if (AB.isDesktop(currentItem.type)) {
         Logger.debug("this is a desktop");
      } else {
         // Create a shallow copy of instances and sort on window name
         sortedInstances = currentItem.instances.slice();
         sortedInstances.sort((a, b) => {
            return a.name.localeCompare(b.name);
         });

         // Close all instances in sorted order
         for (i = 0; i < sortedInstances.length; i++) {
            instanceIds.push(sortedInstances[i].windowId);
         }
         this.unityService.closeWindows(currentItem.wmksKey, instanceIds);
      }
      // ensure close button's tooltip disappear automatically after click it
      if ($(".ui-tooltip-content").length > 0) {
         $(".ui-tooltip-content").parent("div").remove();
      }
   };

   /*
    _toggleShowPanel
    *
    * Toggles whether the sidebar is shown. On hide, persist dirty prefer
    */
   private _toggleShowPanel = (event: PanelEvent) => {
      if (event === PanelEvent.SHOW) {
         if (this.showPanel) {
            return;
         }
         this.showPanel = true;
      } else if (event === PanelEvent.HIDE) {
         if (!this.showPanel) {
            return;
         }
         this.showPanel = false;
      } else {
         this.showPanel = !this.showPanel;
      }

      setTimeout(() => {
         const panelElement = $(".apps-panel");
         this.ref.markForCheck();
         this.contextMenuManager.closeMenu();

         if (panelElement && panelElement[0]) {
            panelElement[0].scrollTop = 0;
         }
         this.queryText = {
            name: ""
         };

         // If the prefs are dirty persist them
         if (!this.showPanel && this.arePreferDirty) {
            this.prefPromise = setTimeout(() => {
               this.eventBusService.dispatch({
                  type: "postPrefData"
               });
               this.arePreferDirty = false;
               this.prefPromise = null;
            });
         }

         if (WMKS.BROWSER.isTouchDevice()) {
            if (this.showPanel && this._searchElem.is(":focus")) {
               this._searchElem.blur();
            }
         }
      });
   };

   public preferDirty = (dirty: boolean) => {
      this.arePreferDirty = dirty;
   };

   // Bug 1823043 the element's height is calculated wrong.
   private _reviseAppPanelHeight = () => {
      // 98 is head-panel's height. Can't use $(".head-panel").height()
      // because the value is 0.

      const height = $("body").height() - 98;
      $(".apps-panel").css("max-height", height + "px");
   };

   /**
    * isRunningDesktopExist
    *
    * Check if there is running desktop session.
    *
    */
   public isRunningDesktopExist = (): boolean => {
      for (let i = 0; i < this.runningItems.length; i++) {
         if (AB.isDesktop(this.runningItems[i].type)) {
            return true;
         }
      }
      return false;
   };

   /**
    * isRunningSessionExist
    *
    * Check if there is running session.
    *
    */
   public isRunningSessionExist = (): boolean => {
      return this.runningItems.length > 0;
   };

   // Close selected item.
   public closeRunningInstance = (instance) => {
      this.unityService.closeWindow(instance.wmksKey, instance.windowId);
   };

   /**
    * Set focus to default(1st) item only when there is no app instance in the current session
    * and focused item can't be found anymore.
    */
   public updateFocusItem = (wmksKey) => {
      const runningItems = this.runningItemsModel.getRunningItems();
      for (let i = 0; i < runningItems.length; i++) {
         // runningItems[i].wmksKey === wmksKey is not needed, but keep it for readability
         if (runningItems[i].wmksKey === wmksKey || runningItems[i].isFocusedItem()) {
            return;
         }
      }
      // For Desktop session, when closing a desktop, it will automatically
      // change focuse to the first item in sidebar, so the same behavior
      // will be used in application session
      if (runningItems.length !== 0) {
         this.htmlRemoteSessionManager.activeSession(runningItems[0].wmksKey);
      }
   };

   // functions many times.
   /**
    * Any selected application is focused.
    *
    * return true if current application or desktop is focused.
    *
    * @param [in] app the application object.
    */
   public appHasFocus = (currentItem) => {
      return currentItem.isFocusedItem();
   };

   /**
    * Any selected unity window is focused.
    *
    * return true if the app of unity window and unity window itself are
    * both focused
    *
    * @param [in] app the application object.
    */
   public unityWindowHasFocused = (currentApp, currentItem) => {
      return currentApp.isFocusedItem() && currentItem.isFocused;
   };

   /*
    * Handler to be called when the apps panel is scrolled. Closes a context menu
    * if it is open. Needed because certain types of scrolling events do not
    * issue a mouse down event before hand (example: touch pad scrolling). If
    * the app-panel is scrolled then the menu will no longer line up with the
    * selected app. Instead of trying to move the menu we simply close it.
    */
   public scrollHandler = () => {
      // this.$apply(() => {
      this.contextMenuManager.closeMenu();
      // });
   };

   public searchClearKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.clearSearch();
      }
   };

   public activateItemKeydown = (event, currentItem) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.activateItem(currentItem);
      }
   };

   public toggleIsCollapsedKeydown = (event, currentItem) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.toggleIsCollapsed(currentItem);
      }
   };

   public activateWindowKeydown = (event, instance) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.activateWindow(instance);
      }
   };

   public closeRunningInstanceKeydown = (event, instance) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.closeRunningInstance(instance);
      }
   };

   public getDesktopIcon = (item: EntitlementItem) => {
      return getDesktopImage(item);
   };
}
