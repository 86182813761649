/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 * @format
 */

import Logger from "../../../core/libs/logger";
import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { clientUtil } from "@html-core";
import { Component, ChangeDetectorRef } from "@angular/core";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { UtilService } from "../../launcher/common/util-service";
import { ViewService } from "../../launcher/common/view-service";
import { LoginModel } from "../common/login-model";
import { AnonymousService } from "../../common/service/anonymous.service";
import { LoginData } from "../common/content-data";
import { AppViewService } from "../../common/service/app-view.service";
import { Subject } from "rxjs";
import { LoginWindowsPasswordComponent } from "../windows-password/windows-password.component";
import { WarningMessageService } from "../../../chrome-client/common/service/warning-message.service";
import { SecurIDPasscodeComponent } from "../securid-password/securid-passcode.component";
import { SecurIDNextTokenComponent } from "../securid-nexttokencode/securid-nexttokencode.component";
import { SecurIDPinChangeComponent } from "../securid-pinchange/securid-pinchange.component";
import { LoginSecurIDWaitComponent } from "../securid-wait/securid-wait.component";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { LoginService } from "./login.service";
import { ViewClientModel } from "../../common/model/viewclient-model";

@Component({
   selector: "login-root",
   templateUrl: "./login.component.html"
})
export class LoginComponent extends BaseViewComponent implements BaseViewComponentInterface {
   public enableDownloadInstaller = true;

   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private utilService: UtilService,
      private viewService: ViewService,
      private anonymousService: AnonymousService,
      private warningMessageService: WarningMessageService,
      private appViewService: AppViewService,
      private loginModel: LoginModel,
      private loginService: LoginService,
      private viewClientModel: ViewClientModel
   ) {
      super(changeDetector, "Start", { noBusyStatus: true });
      this.jscdkWrapper.currentController = this;
      this.loginService.loginRedraw$.subscribe((data) => {
         /* When the SAML is configured as Allowed and the SAML fails,
          * the windowspassword will be responsed. In this case, don't
          * re-enable the auth page, more details please see bug 2424458 */
         if (this.currentName === "WindowsPassword" && data.name === "SAML") {
            Logger.info("keep in window log in page even after getting SAML request");
            return;
         }
         this.reEnable(data);
         if (data.name === "WindowsPassword") {
            this.loginService.redraw(data);
            // LoginWindowsPasswordComponent.redraw(data);
         } else if (data.name === "SecurIDPasscode") {
            this.loginService.redraw(data);
            // SecurIDPasscodeComponent.redraw(data);
         } else if (data.name === "SecurIDNextTokenCode") {
            this.loginService.redraw(data);
            // SecurIDNextTokenComponent.redraw(data);
         } else if (data.name === "SecurIDPinChange") {
            this.loginService.redraw(data);
            // SecurIDPinChangeComponent.redraw(data);
         } else if (data.name === "SecurIDWait") {
            this.loginService.redraw(data);
            //LoginSecurIDWaitComponent.redraw(data);
         } else if (data.name === "WindowsPasswordExpired") {
            this.loginService.redraw(data);
            //ChangePasswordComponent.redraw(data);
         } else if (data.name === "Waiting") {
            if (this.viewService.logoutToLogin) {
               this.jscdkCommonInvoker.connectToBroker();
            }
         }
      });
      if (this.viewService.getSwitchForURI()) {
         this.viewService.setSwitchForURI(false);
         return;
      }
      this._connectToBroker();
   }
   private static readonly widgets = ["Waiting", "SecurIDPasscode", "WindowsPassword", "Disclaimer", "Unauthenticated"];
   // public static refreshSubject$ = new Subject<LoginData>();
   private currentName = "";
   // public static redraw = function(data: LoginData) {
   //    LoginComponent.refreshSubject$.next(data);
   // };
   public enable = function () {};

   public connecting = function () {};
   public tearDown = function () {};

   public appliedData: LoginData; //applied

   private _connectToBroker = () => {
      this.warningMessageService.init();
      // first time enter authentication
      if (!this.loginModel.isLoginInitialized) {
         Logger.info("set isLoginInitialized since client not initialized yet");
         this.loginModel.isLoginInitialized = true;
         Logger.info("doing compatible check and initing client");
         this._doCompatibilityCheckAndInitialize();
      } else {
         Logger.info("loginModel.isLoginInitialized is true");
         // idletimeout don't clear user's last action
         if (this.data && !!this.data.name && LoginComponent.widgets.indexOf(this.data.name) >= 0) {
            setTimeout(() => {
               this.reEnable(this.data);
            });
         } else {
            this.jscdkCommonInvoker.connectToBroker();
            Logger.info("connect to broker");
            //show login waiting
            this._showWaiting();
         }
      }
   };
   private _showWaiting = () => {
      this.appliedData = {
         name: "Waiting"
      };
   };

   public reEnable = (data: LoginData) => {
      this.data = data;
      this._enable();
      /**
       * when it's unauthenticated login and has 'unauthenticatedAccessAccount' argument
       * in URL, client will automatically login or show error dialog, do not need to set
       * ajaxbusy to false
       */
      if (
         !!data.name &&
         data.name === "Unauthenticated" &&
         !!data.content &&
         !!data.content.currentUnauthenticatedUsername
      ) {
         return;
      } else {
         this.ajaxBusyService.setAjaxBusy(false);
      }
   };

   private _enable = () => {
      if (this.data.name === "Unauthenticated") {
         this.anonymousService.setMode(true);
      } else {
         this.anonymousService.setMode(false);
      }
      this.currentName = this.data.name;
      //apply changes
      Logger.debug("rendering the login pages for sub component " + this.data.name);
      this.appliedData = this.data;
      this.changeDetector.detectChanges();
   };

   /*
    * doCompatibilityCheckAndInitialize
    *
    *    Verifies the version of the browser and check if cookie is enabled.
    *    If the browser is supported and cookie is enabled, launch
    *    web client. Otherwise, display the browser compatibility warning.
    *
    * @param data Locale and product data retrieved from the server.
    *             null if the request failed.
    */
   private _doCompatibilityCheckAndInitialize = () => {
      if (clientUtil.isChromeClient()) {
         // handle url later
         // cancel previous user launch/reset/logoff trial
         this.utilService.authenticationStatusCheck.clearAction("compatible check");

         this.jscdkCommonInvoker.connectToBroker();
         this._showWaiting();
         return;
      }
      let ok = this.utilService.isCompatibleBrowser();

      if (!this.utilService.isCookieEnabled()) {
         // User cannot login the connection server when the cookie is
         // disabled.
         ok = false;
      }

      // Enable browser support warning everytime instead of first use
      // only.
      if (ok) {
         if (
            !window.chromeClient.queryModel ||
            !window.chromeClient.queryModel.uri ||
            !window.chromeClient.queryModel.uri.url ||
            window.chromeClient.queryModel.uri.url.indexOf("?") === -1
         ) {
            // cancel previous user launch/reset/logoff trial
            this.utilService.authenticationStatusCheck.clearAction("compatible check");

            this.jscdkCommonInvoker.connectToBroker();
         } else {
            this.jscdkCommonInvoker.setURI(
               window.chromeClient.queryModel.uri.url,
               window.chromeClient.queryModel.uri.host
            );
            window.chromeClient.queryModel = null;
         }
         this._showWaiting();
      } else {
         // If use IE browser, display browser warnings.
         if (clientUtil.isIE()) {
            Logger.info("we do not support browser IE");
            this.appViewService.jumpToWarning();
         } else {
            // for other incompatible browsers, which are browsers in ARM devices
            if (
               !window.chromeClient?.queryModel ||
               !window.chromeClient.queryModel?.uri ||
               !window.chromeClient.queryModel.uri?.url ||
               window.chromeClient.queryModel.uri.url.indexOf("?") === -1
            ) {
               // if it's not URI launch, display browser warnings
               Logger.info("we do not support browser in Device with CPU ARM when it's not URI launch");
               this.appViewService.jumpToWarning();
            } else {
               // if it's URI launch, keep original behavior
               this.jscdkCommonInvoker.setURI(
                  window.chromeClient.queryModel.uri.url,
                  window.chromeClient.queryModel.uri.host
               );
               window.chromeClient.queryModel = null;
            }
            this._showWaiting();
         }
      }
   };

   public ngAfterViewInit(): void {
      this.enableDownloadInstaller = this.viewClientModel.enableDownloadInstaller;
   }
}
