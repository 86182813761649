/** @format */

import Logger from "../../../core/libs/logger";
import * as CST from "@html-core";
import { Component, Input } from "@angular/core";

@Component({
   selector: "phone-home",
   template: `<div class="phone-home">
      <div class="phone-home-link-area" id="phoneDownloadLinkArea">
         <a
            class="phone-home-link"
            id="phoneDownloadLink"
            href="{{ 'DOWNLOAD_LINK_HREF' | i18nv2 }}"
            target="_blank"
            rel="{{ linkRelValue }}"
            >{{ "DOWNLOAD_LINK_TEXT" | i18nv2 }}</a
         >
      </div>
      <div class="phone-home-link-area" id="phoneHelpLinkArea">
         <a
            class="phone-home-link"
            id="phoneHelpLink"
            href="{{ 'HELP_LINK_HREF' | i18nv2 }}"
            target="_blank"
            rel="{{ linkRelValue }}"
            >{{ "HELP_LINK_TEXT" | i18nv2 }}</a
         >
      </div>
   </div>`
})
export class PhoneHomeComponent {
   public linkRelValue = __REL_FOR_LINK__;
}
