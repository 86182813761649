/**
 * ******************************************************
 * Copyright (C) 2016-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * dialog.service.ts
 *
 * The unified dialog-service for desktop/launcher module.
 * It is a message router between component/service and modal-dialog.component
 */

import { Subject, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export namespace Modal {
   export const ABOUT_DIALOG = "aboutDialog";
   export const SETTING_DIALOG = "settingDialog";
   export const SETTING_MULTI_LAUNCH_DIALOG = "settingMultiLaunchDialog";
   export const SETTING_RTAV_DIALOG = "settingRtavDialog";
   export const SETTING_MULTI_MONITOR_DIALOG = "settingMultiMonitorDialog";
   export const SETTING_CDR_DIALOG = "settingCDRDialog";
   export const SETTING_AUTO_USB_DIALOG = "settingAutoUSBDialog";
   export const SETTING_FA_DIALOG = "settingFileAssociationDialog";
   export const SETTING_TRUSTED_APPS_DIALOG = "settingTrustedAppsDialog";
   export const DEBUG_CONFIG_DIALOG = "debugConfigurationDialog";
   export const CONFIRM_DIALOG = "confirmDialog";
   export const CANCEL_CONFIRM_DIALOG = "cancelConfirmDialog";
   export const CANCEL_CONFIRM_DIALOG_WITH_CHECKBOX = "cancelConfirmDialogWithCheckBox";
   export const CANCEL_CONFIRM_DIALOG_WITH_INPUTTEXT = "cancelConfirmDialogWithInputText";
   export const ERROR_DIALOG = "errorDialog";
   export const CLIP_FT_DIALOG = "clipFTDialog";
   export const PRE_SETTING_DIALOG = "preSettingDialog";
   export const REAUTH_DIALOG = "reauthDialog";
   export const GESTURE_HELP = "gestureHelpDialog";
   export const KEYBOARD_SETTING = "keyboardSettingDialog";
   // Compatible with NgbModalOptions
   export class ModalOption {
      public static DIALOG_INDEX: number = 0;
      public name: string;
      public id: string = (ModalOption.DIALOG_INDEX++).toString();

      /*
       * If true, the backdrop element will be created for a given modal.
       * Alternatively, specify 'static' for a backdrop which doesn't close the modal on click.
       */
      public backdrop?: boolean | "static" = true;

      /*
       * Callback right before the modal will be dismissed.
       * If this function returns:
       *    -  false
       *    -  a promise resolved with false
       *    -  a promise that is rejected
       * then the modal won't be dismissed.
       */
      public beforeDismiss?: () => boolean | Promise<boolean> = null;

      //If true, the modal will be closed when Escape key is pressed
      public keyboard?: boolean = true;

      //Scrollable modal content (false by default).
      public scrollable?: boolean = false;

      //A custom class to append to the modal window.
      public windowClass?: string = null;

      //If true, the modal will be centered vertically.
      public centered?: boolean = false;
   }

   export class AboutModalOption extends ModalOption {
      constructor() {
         super();
         this.name = ABOUT_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "about-window";
      }
   }

   export class ReauthModalOption extends ModalOption {
      constructor() {
         super();
         this.name = REAUTH_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = false;
         this.centered = true;
         this.windowClass = "reauth-window";
      }
   }

   export class PreSettingModalOption extends ModalOption {
      constructor() {
         super();
         this.name = PRE_SETTING_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "pre-setting-window";
      }
   }

   export class SettingModalOption extends ModalOption {
      public callback: () => {};
      constructor(callback?) {
         super();
         if (callback) {
            this.callback = callback;
         }
         this.name = SETTING_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-window";
      }
   }

   export class SettingMultiLaunchModalOption extends ModalOption {
      constructor() {
         super();
         this.name = SETTING_MULTI_LAUNCH_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-multi-launch-window";
      }
   }

   export class SettingMultiMonitorModalOption extends ModalOption {
      constructor() {
         super();
         this.name = SETTING_MULTI_MONITOR_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-multi-monitor-window";
      }
   }

   export class SettingRtavOption extends ModalOption {
      constructor() {
         super();
         this.name = SETTING_RTAV_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-rtav-window";
      }
   }

   export class SettingCDRModalOption extends ModalOption {
      constructor() {
         super();
         this.name = SETTING_CDR_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-cdr-window";
      }
   }

   export class SettingAutoUSBModalOption extends ModalOption {
      constructor() {
         super();
         this.name = SETTING_AUTO_USB_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = false;
         this.centered = true;
         this.windowClass = "setting-auto-usb-window";
      }
   }

   export class SettingFileAssociationModalOption extends ModalOption {
      constructor() {
         super();
         this.name = SETTING_FA_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-fa-window";
      }
   }

   export class SettingTrustedAppsModalOption extends ModalOption {
      constructor() {
         super();
         this.name = SETTING_TRUSTED_APPS_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-trusted-apps-window";
      }
   }

   export class DebugConfigurationModalOption extends ModalOption {
      constructor() {
         super();
         this.name = DEBUG_CONFIG_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "debug-config-window";
      }
   }

   export type dialogDataKey = {
      titleKey: string;
      contentKey: string;
      backdrop?: boolean | "static";
      contentSubstitutionsKey?: string;
      showWarning?: boolean;
      isLogOffDesktop?: boolean;
      isTransferringFile?: boolean;
      buttonLabelConfirmKey?: string;
      buttonLabelCancelKey?: string;
   };

   export type dialogData = {
      title: string;
      content: string;
      backdrop?: boolean | "static";
      contentSubstitutions?: string;
      showWarning?: boolean;
      isLogOffDesktop?: boolean;
      isTransferringFile?: boolean;
      buttonLabelConfirm?: string;
      buttonLabelCancel?: string;
      showCopy?: boolean;
   };

   export type dialogDataWithCheckBox = {
      titleKey: string;
      contentKey?: string;
      checkboxTitleKey: string;
      buttonLabelConfirmKey?: string;
      buttonLabelCancelKey?: string;
      isCheckBoxCheckedKey?: string;
      showCancelButton?: boolean;
   };

   export type dialogDataWithInputText = {
      title: string;
      content?: string;
      inputTextData?: string;
      buttonLabelConfirm?: string;
      buttonLabelCancel?: string;
   };

   export type dialogEventListener = {
      destroyAzureWaitingUIObserver: object;
      azureReconnectionErrorUIObserver: object;
      updateAzureWaitingUI: object;
   };

   export type dialogCallbacks = {
      confirm: () => void;
      cancel?: () => void;
   };

   export type dialogCallbacksWithCheckBox = {
      confirm: () => void;
      cancel?: () => void;
      setCheckBoxValue: (enabled: boolean) => void;
   };

   export type dialogCallbacksWithEditText = {
      confirm: (output: any) => void;
      cancel?: () => void;
   };

   export type IConfirmModalOption = {
      data: dialogDataKey | dialogData;
      callbacks: dialogCallbacks;
   };

   export type ICancelConfirmModalOptionWithCheckBox = {
      data: dialogDataKey | dialogDataWithCheckBox;
      callbacks: dialogCallbacksWithCheckBox;
   };

   export type ICancelConfirmModalOptionWithInputText = {
      data: dialogDataKey | dialogDataWithInputText;
      callbacks: dialogCallbacksWithEditText;
   };
   export class ConfirmModalOption extends ModalOption {
      public option: IConfirmModalOption;
      constructor(option: IConfirmModalOption, closeByEscape = true) {
         super();
         this.option = option;
         this.name = CONFIRM_DIALOG;
         this.keyboard = closeByEscape;
         this.backdrop = option.data.backdrop;
         this.scrollable = false;
         this.centered = true;
         this.windowClass = "confirm-window";
      }
   }

   export class CancelConfirmModalOption extends ModalOption {
      public option: IConfirmModalOption;
      constructor(option: IConfirmModalOption) {
         super();
         this.option = option;
         this.name = CANCEL_CONFIRM_DIALOG;
         this.keyboard = true;
         this.backdrop = "static";
         this.scrollable = false;
         this.centered = true;
         this.windowClass = "cancel-confirm-window";
      }
   }

   export class CancelConfirmModalOptionWithCheckBox extends ModalOption {
      public option: ICancelConfirmModalOptionWithCheckBox;
      constructor(option: ICancelConfirmModalOptionWithCheckBox) {
         super();
         this.option = option;
         this.name = CANCEL_CONFIRM_DIALOG_WITH_CHECKBOX;
         this.keyboard = true;
         this.backdrop = "static";
         this.scrollable = false;
         this.centered = true;
         this.windowClass = "cancel-confirm-window-with-CheckBox";
      }
   }
   export class CancelConfirmModalOptionWithInputText extends ModalOption {
      public option: ICancelConfirmModalOptionWithInputText;
      constructor(option: ICancelConfirmModalOptionWithInputText) {
         super();
         this.option = option;
         this.name = CANCEL_CONFIRM_DIALOG_WITH_INPUTTEXT;
         this.keyboard = true;
         this.backdrop = "static";
         this.scrollable = false;
         this.centered = true;
         this.windowClass = "cancel-confirm-window-with-InputText";
      }
   }

   export type IErrorModalOption = {
      data:
         | Omit<dialogDataKey, "showWarning" | "isTransferringFile" | "buttonLabelCancelKey">
         | Omit<dialogData, "showWarning" | "isTransferringFile" | "buttonLabelCancel">;
      callbacks?: Omit<dialogCallbacks, "cancel">;
      eventListeners?: dialogEventListener;
   };

   export class ErrorModalOption extends ModalOption {
      public option: IErrorModalOption;
      constructor(option: IErrorModalOption) {
         super();
         this.option = option;
         this.name = ERROR_DIALOG;
         this.keyboard = true;
         this.backdrop = "static";
         this.scrollable = false;
         this.centered = true;
         this.windowClass = "error-window";
      }
   }

   export type IClipFTHelpModalOption = {
      data: {
         title: string;
         msg: string;
         modKey: string;
      };
      callback: () => {};
   };

   export class ClipFTHelpModalOption extends ModalOption {
      public option: IClipFTHelpModalOption;
      constructor(option: IClipFTHelpModalOption) {
         super();
         this.option = option;
         this.name = CLIP_FT_DIALOG;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "clip-ft-window";
      }
   }

   export class GestureHelpModalOption extends ModalOption {
      public currentTouchMode: string;
      constructor(currentTouchMode: string) {
         super();
         this.currentTouchMode = currentTouchMode;
         this.name = GESTURE_HELP;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "gesture-help-window";
      }
   }

   export class KeyboardSettinggOption extends ModalOption {
      constructor() {
         super();
         this.name = KEYBOARD_SETTING;
         this.keyboard = true;
         this.backdrop = true;
         this.scrollable = true;
         this.centered = true;
         this.windowClass = "setting-keyboard-window";
      }
   }
}

@Injectable({
   providedIn: "root"
})
export class ModalDialogService {
   public dialogMap: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();
   public windowsClasses: string[] = [
      "setting-window",
      "setting-cdr-window",
      "setting-multi-monitor-window",
      "setting-multi-launch-window",
      "setting-keyboard-window",
      "about-window",
      "gesture-help-window",
      "setting-rtav-window"
   ];
   private dialogSubject$ = new Subject();

   constructor(private modalService: NgbModal) {}

   public subscribe(callback: (option: Modal.ModalOption) => void): Subscription {
      return this.dialogSubject$.subscribe(callback);
   }

   public showConfirm = (option: Modal.IConfirmModalOption, closeByEscape?: boolean) => {
      const confirmOption = new Modal.ConfirmModalOption(option, closeByEscape);
      this.dialogSubject$.next(confirmOption);
      return confirmOption.id;
   };

   public showCancelConfirm = (option: Modal.IConfirmModalOption) => {
      const cancelConfirmOption = new Modal.CancelConfirmModalOption(option);
      this.dialogSubject$.next(cancelConfirmOption);
      return cancelConfirmOption.id;
   };

   public showCancelConfirmWithCheckBox = (option: Modal.ICancelConfirmModalOptionWithCheckBox) => {
      const cancelConfirmOptionWithCheckBox = new Modal.CancelConfirmModalOptionWithCheckBox(option);
      this.dialogSubject$.next(cancelConfirmOptionWithCheckBox);
      return cancelConfirmOptionWithCheckBox.id;
   };

   public showCancelConfirmWithInputText = (option: Modal.ICancelConfirmModalOptionWithInputText) => {
      const cancelConfirmOptionWithInputText = new Modal.CancelConfirmModalOptionWithInputText(option);
      this.dialogSubject$.next(cancelConfirmOptionWithInputText);
      return cancelConfirmOptionWithInputText.id;
   };

   public showReAuth = () => {
      const reauthOption = new Modal.ReauthModalOption();
      this.dialogSubject$.next(reauthOption);
      return reauthOption.id;
   };

   public showAbout = () => {
      const aboutOption = new Modal.AboutModalOption();
      this.dialogSubject$.next(aboutOption);
      return aboutOption.id;
   };

   public showPreSetting = () => {
      const preSettingOption = new Modal.PreSettingModalOption();
      this.dialogSubject$.next(preSettingOption);
      return preSettingOption.id;
   };

   public showSetting = (callback?) => {
      const settingOption = new Modal.SettingModalOption(callback);
      this.dialogSubject$.next(settingOption);
      return settingOption.id;
   };

   public showSettingMultiLaunch = () => {
      const settingMultiLaunchOption = new Modal.SettingMultiLaunchModalOption();
      this.dialogSubject$.next(settingMultiLaunchOption);
      return settingMultiLaunchOption.id;
   };

   public showSettingMultiMonitor = () => {
      const settingMultiMonitorOption = new Modal.SettingMultiMonitorModalOption();
      this.dialogSubject$.next(settingMultiMonitorOption);
      return settingMultiMonitorOption.id;
   };

   public showSettingRtav = () => {
      const settingRtavOption = new Modal.SettingRtavOption();
      this.dialogSubject$.next(settingRtavOption);
      return settingRtavOption.id;
   };

   public showSettingCDR = () => {
      const settingCDROption = new Modal.SettingCDRModalOption();
      this.dialogSubject$.next(settingCDROption);
      return settingCDROption.id;
   };

   public showSettingAutoUSB = () => {
      const settingAutoUSBOption = new Modal.SettingAutoUSBModalOption();
      this.dialogSubject$.next(settingAutoUSBOption);
      return settingAutoUSBOption.id;
   };

   public showSettingFileAssociation = () => {
      const settingFAOption = new Modal.SettingFileAssociationModalOption();
      this.dialogSubject$.next(settingFAOption);
      return settingFAOption.id;
   };

   public showSettingTrustedApps = () => {
      const settingTrustedAppsOption = new Modal.SettingTrustedAppsModalOption();
      this.dialogSubject$.next(settingTrustedAppsOption);
      return settingTrustedAppsOption.id;
   };

   public showDebugConfiguration = () => {
      const debugConfigurationModalOption = new Modal.DebugConfigurationModalOption();
      this.dialogSubject$.next(debugConfigurationModalOption);
      return debugConfigurationModalOption.id;
   };

   public showError = (option: Modal.IErrorModalOption) => {
      const errorOption = new Modal.ErrorModalOption(option);
      this.dialogSubject$.next(errorOption);
      return errorOption.id;
   };

   public showClipFTHelp = (option) => {
      const helpOption = new Modal.ClipFTHelpModalOption(option);
      this.dialogSubject$.next(helpOption);
      return helpOption.id;
   };

   public showGestureHelp = (currentTouchMode: string) => {
      const gestureHelpOption = new Modal.GestureHelpModalOption(currentTouchMode);
      this.dialogSubject$.next(gestureHelpOption);
      return gestureHelpOption.id;
   };

   public showKeyboardSetting = () => {
      const keyboardSettingOption = new Modal.KeyboardSettinggOption();
      this.dialogSubject$.next(keyboardSettingOption);
      return keyboardSettingOption.id;
   };

   public hasDialogOpen = () => {
      return this.modalService.hasOpenModals();
   };

   public isDialogOpen = (id: string) => {
      return this.dialogMap.has(id);
   };

   public close = (id: string) => {
      const modalRef = this.dialogMap.get(id);
      if (modalRef) {
         modalRef.close();
      }
   };

   public addDialogCloseListener = (id, onClosed, onDismissed) => {
      const modalRef = this.dialogMap.get(id);
      modalRef.result.then(onClosed, onDismissed);
   };

   public checkDialogOpenByType = (windowClass: string) => {
      const res = false;
      for (const [key, value] of this.dialogMap) {
         if (
            value.componentInstance &&
            value.componentInstance.windowClass &&
            value.componentInstance.windowClass === windowClass
         ) {
            return true;
         }
      }
      return res;
   };

   public closeDialogByType = (windowClass: string) => {
      this.dialogMap.forEach((value, key) => {
         if (
            value.componentInstance &&
            value.componentInstance.windowClass &&
            value.componentInstance.windowClass === windowClass
         ) {
            value.close();
         }
      });
   };

   public closeDialogByTypes = (windowsClasses: string[]) => {
      this.dialogMap.forEach((value, key) => {
         if (
            value.componentInstance &&
            value.componentInstance.windowClass &&
            windowsClasses.includes(value.componentInstance.windowClass)
         ) {
            value.close();
         }
      });
   };

   public changeContent = (id: string, newText: string) => {
      const modalRef = this.dialogMap.get(id);
      if (modalRef) {
         if (modalRef.componentInstance.errorMessage) {
            modalRef.componentInstance.errorMessage = newText;
         } else if (modalRef.componentInstance.message) {
            modalRef.componentInstance.message = newText;
         }
      }
   };

   public changeTitle = (id: string, newText: string) => {
      const modalRef = this.dialogMap.get(id);
      if (modalRef && modalRef.componentInstance.title) {
         modalRef.componentInstance.title = newText;
      }
   };

   public changeBtnLabel = (id: string, newText: any) => {
      const modalRef = this.dialogMap.get(id);
      if (modalRef && modalRef.componentInstance.buttonLabel) {
         modalRef.componentInstance.buttonLabel = newText;
      }
   };
}
