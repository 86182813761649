/**
 * *****************************************************
 * Copyright 2020-2024 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { ClipboardController } from "./clipboard.component";
import { ClipboardPrimaryDesktopMonitorService } from "./common-clipboard-desktop-monitor.service";

@NgModule({
   imports: [],
   declarations: [ClipboardController],
   exports: [ClipboardController],
   providers: [ClipboardPrimaryDesktopMonitorService]
})
export class ChromeClipboardModule {}
