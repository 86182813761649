<div class="ui-panel-content ui-text-left-align panel-text">
   <div [hidden]="blankOutContent">
      <p class="ui-rsa-title" ><small>{{'dialog_wait_help1' | i18nv2}}</small></p>
      <p><small>{{'dialog_wait_help2' | i18nv2}}</small></p>
   <div>
   <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
</div>
<div class="ui-panel-footer">
   <button id="loginButton" class="btn btn-primary" [ngClass]="{btnPrimaryWaiting:blankOutContent}" (click)="onSubmit()" ng-disabled="disabled" [hidden]="blankOutContent">{{'dialog_wait_continue' | i18nv2}}</button>
   <button id="cancelLoginBtn" class="btn btn-default" (click)="cancel()" [ngClass]="{'btnDefaultWaiting':blankOutContent}">{{'CANCEL' | i18nv2}}</button>
</div>