/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class BrokerSettingService {
   /**
    * @public
    * @param {[type]} brokerAddress [description]
    * @param {[type]} username      [description]
    * @param {[type]} domain        [description]
    */
   public abstract setLastADConnectionInfo(brokerAddress, username, domain);
   public abstract getLastADConnectionInfo(brokerAddress);
}
