/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "./logger";

/**
 * workaround for loss session-id comparison, return known key without @domain part
 * see detail in 2291583 and 2294122.
 * refer /vdi/common/sessionclientapi/src/main/java/com/vmware/vdi/sessionclientapi/ApplicationSessionImp.java
 * @param  {object} map       map of all known keys, don't conatin "@domain" part
 * @param  {string} targetKey The target key, will be used to match, could contains "@domain" part
 * @return {string} Returns the modified key
 */

export function getPartialMatchedKey(map, targetKey) {
   if (!map || typeof targetKey !== "string") {
      return targetKey;
   }
   if (map.hasOwnProperty(targetKey)) {
      return targetKey;
   }
   const atPosition = targetKey.indexOf("@");
   const domainEndPosition = targetKey.indexOf("\\");
   const userDnStartPosition = targetKey.indexOf("(");
   if (atPosition === -1 || atPosition < domainEndPosition || atPosition > userDnStartPosition) {
      Logger.error("unmatched key doesn't contains @: " + targetKey);
      return targetKey;
   }

   if (domainEndPosition === -1 || userDnStartPosition === -1) {
      Logger.error("unmatched key looks invalid, doesn't \\ or (: " + targetKey);
      return targetKey;
   }
   const domainPart = targetKey.substring(0, domainEndPosition);
   // sessionMatchingTarget contains userDN, sessionGUID, serverDN
   const sessionMatchingTarget = targetKey.substring(userDnStartPosition);
   for (const key in map) {
      if (!map.hasOwnProperty(key) || typeof key !== "string") {
         continue;
      }

      // don't check the first part, since the user part of UPN and loggon name(per win2000) might be different
      const startWithSameDomain = key.indexOf(domainPart) === 0;
      const endWithTargetString = key.indexOf(sessionMatchingTarget) + sessionMatchingTarget.length === key.length;
      if (startWithSameDomain && endWithTargetString) {
         Logger.debug('treat key "' + targetKey + '" as "' + key + '"');
         return key;
      }
   }
   Logger.error("failed to find any existing session by partial match session-id: " + targetKey);
   return targetKey;
}

export function ignoreCaseEquals(str1: string, str2: string) {
   if (!str1 || !str2) {
      return false;
   }
   return str1.toLowerCase() === str2.toLowerCase();
}

/**
 * Return whether a monitor is abnormal large
 *
 * The max supported resolution is 3840*2160 as in the document:
 * https://www.vmware.com/pdf/horizon-view/horizon-client-windows-44-document.pdf
 * "With the VMware Blast display protocol or the PCoIP display protocol, a
 * remote desktop screen resolution of 4K (3840 x 2160) is supported."
 * Although it's for windows client, the limitation is on the agent side, so
 * commen for all Clients.
 */
export function isTooLargeMonitor(rect: any, factor) {
   const maxWidth = 3840,
      maxHeight = 2160;

   if (!rect || rect.devicePixelRatio === undefined) {
      return false;
   }

   const rectWidth = rect.modeWidth ? rect.modeWidth : rect.width;
   const rectHeight = rect.modeHeight ? rect.modeHeight : rect.height;

   return (
      Math.floor(rectWidth * factor * rect.devicePixelRatio) > maxWidth ||
      Math.floor(rectHeight * factor * rect.devicePixelRatio) > maxHeight
   );
}

export function setOnRampMode(mode: boolean) {
   localStorage.setItem("isOnRampMode", JSON.stringify(mode));
}

export function isOnRamp(): boolean {
   if (localStorage.getItem("isOnRampMode") === "true") {
      return true;
   } else {
      return false;
   }
}

export function convertToIPv6AddressIfNeeded(url: string) {
   const match = url.match(/wss:\/\/(\[?\w.+):\d+\/\S*\/\S*/);
   if (!(match && match.length >= 2)) {
      return url;
   }
   const host = match[1];
   if (host.includes(":") && !host.startsWith("[")) {
      url = url.replace(host, "[" + host + "]");
   }
   return url;
}

/**
 * Compute a random 128-bit string to use as the random token in the direct
 * connect case. Returns the result a string in the following format:
 * xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
 *
 * This UUID follows RFC 4122 type 4. 'x's map to random hex digits and 'y'
 * maps to 8, 9, a, or b.
 *
 * This code is based on BSG code for generating routing tokens.
 */
export function getUuid() {
   /**
    * Generate random numbers between 0 and 1 seeded using the current time.
    */
   const random = function () {
      const d = new Date();
      const seed = Math.abs(1000 * d.getSeconds() + d.getMilliseconds());
      const r = seed * Math.random();
      return r - Math.floor(r);
   };

   /**
    * Generate a version 4 UUID using the random() function to generate
    * each hex digit.
    */
   const token = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
      .replace(/[xy]/g, function (c) {
         const r = (random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;

         return v.toString(16);
      })
      .toUpperCase();

   return token;
}
