/**
 * *****************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import { ChromeClientFileSystem } from "./chrome-client-file-system";
import { Html5FileSystem } from "./html5-file-system";

export class FileSystemService {
   private fileSystem;
   constructor() {
      if (clientUtil.isChromeClient()) {
         this.fileSystem = new ChromeClientFileSystem();
      } else {
         this.fileSystem = new Html5FileSystem();
      }
   }

   public isRestorable = (id, callback) => {
      return this.fileSystem.isRestorable(id, callback);
   };

   public chooseEntry = () => {
      return this.fileSystem.chooseEntry();
   };

   public retainEntry = (entry): string => {
      return this.fileSystem.retainEntry(entry);
   };

   public getWritableEntry = (entry, callback?) => {
      return new Promise((resolve, reject) => {
         this.fileSystem
            .getWritableEntry(entry, callback)
            .then((entry) => {
               resolve(entry);
            })
            .catch(() => {
               reject();
            });
      });
   };
   public getDisplayPath = (rootEntry, callback) => {
      this.fileSystem.getDisplayPath(rootEntry, callback);
   };

   public addEntry = (entry) => {
      this.fileSystem.addEntry(entry);
   };

   public getDirectoryEntryFromStorage = (name) => {
      return new Promise((resolve, reject) => {
         this.fileSystem
            .getDirectoryEntryFromStorage(name)
            .then((res) => {
               resolve(res);
            })
            .catch(reject);
      });
   };

   public saveDirectoryEntryInStorage = (folderId, folderEntry) => {
      return new Promise((resolve, reject) => {
         this.fileSystem.saveDirectoryEntryInStorage(folderId, folderEntry).then(resolve).catch(reject);
      });
   };

   public addEntryInFileSystem = async (directoryEntry) => {
      await this.fileSystem.addEntryInFileSystem(directoryEntry);
   };
}
