/**
 * ******************************************************
 * Copyright (C) 2017-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Directive, HostListener } from "@angular/core";
import { EventBusService, BusEvent } from "@html-core";

@Directive({
   selector: "[setting-listener]"
})
export class settingListenerDirective {
   private tKeys: Array<number> = [83, 72, 79, 87, 77, 69, 84, 72, 69, 83, 69, 67, 82, 69, 84];
   private cKeys: Array<number> = [];

   @HostListener("document:keydown", ["$event"]) onKeyDown(e) {
      if (e.keyCode) {
         this.cKeys.push(e.keyCode);
         if (this.cKeys.length === this.tKeys.length) {
            if (
               this.cKeys.every((element, index): boolean => {
                  return element === this.tKeys[index];
               })
            ) {
               this.eventBusService.dispatch(new BusEvent.SettingOptionsChangedMsg());
            }
            this.cKeys.length = 0;
         }
      }
   }

   constructor(private eventBusService: EventBusService) {}
}
