/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * killAllApplicationSessionsCtrl.js--
 *
 *      middleware to reset all application sessions by
 *      sending multiple kill-session, will be deleted when
 *      JSCDK refactory by just using callback. It currently
 *      is used to solve the naming conflict and seperate the
 *      logic out.
 */

import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import KillSessionHandler from "./killSessionHandler";
import { ErrorObj } from "../jscdk-interface";

export default function KillAllApplicationSessionsCtrl() {
   // member variables below
   this.messageName = "kill-all-application-sessions";
   this.messageText = "kill-all-application-sessions";
   this.responseTag = "kill-all-application-sessions";
}

// inherits MessageHandler prototype
KillAllApplicationSessionsCtrl.prototype = new MessageHandler();
// constructor
KillAllApplicationSessionsCtrl.constructor = KillAllApplicationSessionsCtrl;

KillAllApplicationSessionsCtrl.prototype.dealWithResponses = function (dataList) {
   let i,
      data,
      killAllApplicationSessionsAction,
      router = globalArray["router"],
      errorObj,
      failIdList = [];

   if (!dataList || !Array.isArray(dataList) || dataList.length <= 0) {
      Logger.error("the response list is invalid");
      return;
   }
   // here assume all error are of the same type, so only store the last one
   for (i = 0; i < dataList.length; i++) {
      data = dataList[i];
      if (data.result === "fail") {
         failIdList.push(data.sessionId);
         errorObj = data.error;
      }
   }
   if (failIdList.length === 0) {
      // Kill application sessions succeeds
      killAllApplicationSessionsAction = {
         name: "KillAllApplicationSessions",
         result: "ok"
      };
      JSCDKSetUI(JSON.stringify(killAllApplicationSessionsAction));
   } else {
      // this is for reusing pushErrorToUser, will be brifer after jscdk
      // refactory, didn't rename error now
      errorObj.errorDetails = failIdList;
      this.content = {
         error: {
            test: errorObj
         }
      };
      router.pushErrorToUser(this);
   }
};

KillAllApplicationSessionsCtrl.prototype.getApplicationSessions = function () {
   let getLaunchItemsObject;
   getLaunchItemsObject = this.parentHandler;
   if (!getLaunchItemsObject) {
      getLaunchItemsObject = globalArray[this.messageName + "get-launch-items"];
   }
   if (!!getLaunchItemsObject && !!getLaunchItemsObject.content["parsedResult"]) {
      return getLaunchItemsObject.content["parsedResult"]["application-sessions"];
   }
   return null;
};

KillAllApplicationSessionsCtrl.prototype.getValidAndInvalidIdLists = function (applicationSessions) {
   let key,
      applicationSessionId,
      applicationSessionIdList = [],
      invalidOriginIdList = [];
   for (key in applicationSessions) {
      if (applicationSessions.hasOwnProperty(key)) {
         applicationSessionId = applicationSessions[key].id;
         // kill one of the application session.
         if (!!applicationSessionId && applicationSessionId.trim() !== "") {
            applicationSessionIdList.push(applicationSessionId);
         } else {
            invalidOriginIdList.push(applicationSessions[key]["origin-id"]);
         }
      }
   }
   return {
      valid: applicationSessionIdList,
      invalid: invalidOriginIdList
   };
};

KillAllApplicationSessionsCtrl.prototype.pushResetFailToUser = function () {
   let errorObj = {} as ErrorObj,
      router = globalArray["router"],
      errorMessage;

   errorObj.name = "JSCDK_ERROR_LOGOFF_POOL_FAILED";
   errorMessage = util._("JSCDK_ERROR_APP_SESSION_CANT_RESET");
   router.pushSelfDefinedError(errorObj, errorMessage);
   this.setState(StateEnum.FAIL);
};

/**
 * Triggered by the parent handler
 *
 */
KillAllApplicationSessionsCtrl.prototype.triggerExecution = function () {
   let validAndInvalidIdLists,
      applicationSessionIdList,
      targetApplicationSessionId,
      handlerList,
      router = globalArray["router"],
      killSessionObject,
      i,
      infoCombiner;

   MessageHandler.prototype.triggerExecution.apply(this);

   validAndInvalidIdLists = this.getValidAndInvalidIdLists(this.getApplicationSessions());

   if (validAndInvalidIdLists.invalid.length === 0) {
      applicationSessionIdList = validAndInvalidIdLists.valid;
      if (applicationSessionIdList.length > 0) {
         // sending kill sessions
         infoCombiner = new util.InfoCombiner(applicationSessionIdList.length, this.dealWithResponses.bind(this), null);
         for (i = 0; i < applicationSessionIdList.length; i++) {
            targetApplicationSessionId = applicationSessionIdList[i];
            killSessionObject = globalArray["kill-session" + targetApplicationSessionId];
            if (!killSessionObject) {
               killSessionObject = new KillSessionHandler();
               globalArray[killSessionObject.messageName + targetApplicationSessionId] = killSessionObject;
               globalArray[killSessionObject.responseTag + targetApplicationSessionId] = killSessionObject;
            } else {
               killSessionObject.resetData();
            }
            killSessionObject.setCallbackFunction(infoCombiner.onInfoReceived);
            killSessionObject.setRequestXML(targetApplicationSessionId);
            handlerList = killSessionObject.composeHandlerList();
            router.postMessage(handlerList);
         }
         this.setState(StateEnum.DONE);
      } else {
         /**
          * Get a non-empty app session list, return response as success,
          * since data in the UI layer might be out-dated, due to
          * "logoff when disconnect"
          */
         Logger.warning("There is no application session to be reset");
         // Kill application sessions succeeds
         const killAllApplicationSessionsAction = {
            name: "KillAllApplicationSessions",
            result: "ok",
            status: "no app to be reset"
         };
         JSCDKSetUI(JSON.stringify(killAllApplicationSessionsAction));
      }
   } else {
      //fail to get the list, report error
      Logger.error(
         "The application sessions with origin-id(in list)" +
            JSON.stringify(validAndInvalidIdLists.invalid) +
            " cannot be reset."
      );
      this.pushResetFailToUser();
   }
};
