/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import WMKS from "WMKS";
import Logger from "../../../core/libs/logger";

import { PrintFile, PrintRdService } from "./print-redirection.service";
import { NgbToastService } from "./../../common/service/ngb-toast.service";
import { Subscription } from "rxjs";
import { WmksService } from "../common/wmks.service";
import { TranslateService, EventBusService, BusEvent } from "@html-core";
import { printUtil } from "./print-util.service";
@Component({
   selector: "print-frame",
   templateUrl: "./print-frame.component.html"
})
export class PrintFrameComponent implements OnInit, OnDestroy, AfterViewInit {
   private printSubscription: Subscription;
   private isPrinting: boolean = false;
   private viewerReady: boolean = false;
   private printFilesList: PrintFile[] = [];
   private frame: any = null;
   private logger: Logger = new Logger(Logger.PRINTER_FRAME);
   defaultTemplate: boolean = true;
   firefoxTemplate: boolean = false;
   ieTemplate: boolean = false;

   ngOnInit() {
      this.printSubscription = this.printRdService.printSubject.subscribe((msg) => {
         this.handlePrintMsg(msg);
      });
      this.eventBusService
         .listen(BusEvent.PrintFileUseOldSolutionMsg.MSG_TYPE)
         .subscribe((msg: BusEvent.PrintFileUseOldSolutionMsg) => {
            const data = msg.file;
            this.download(data);
         });
   }

   ngOnDestroy() {
      if (this.printSubscription) {
         this.printSubscription.unsubscribe();
      }
   }

   ngAfterViewInit() {
      const self: PrintFrameComponent = this;
      self.frame = document.getElementById("printPDF");
      if (WMKS.BROWSER.isFirefox() || WMKS.BROWSER.isIE()) {
         window.addEventListener(
            "message",
            (event) => {
               if (event.origin !== location.origin) {
                  this.logger.error("origin check failed " + event.origin + " vs " + "location.origin");
                  return;
               }

               if (typeof event.data === "string") {
                  if (event.data === "ready") {
                     self.viewerReady = true;
                  } else if (event.data === "print done") {
                     self.postPrint();
                  }
               }
            },
            false
         );
      } else {
         self.frame.onload = () => {
            this.logger.debug("Enter frame onload");
            if (!self.isPrinting) {
               this.logger.debug("No executing print job!");
               return;
            }
            let time = 0;
            if (WMKS.BROWSER.isSafari()) {
               // Fix the safari blank preview issue
               time = 1000;
            }
            setTimeout(() => {
               self.frame.contentWindow.focus();
               this.logger.debug("Start to print");
               self.frame.contentWindow.print();
               $(self.frame.contentWindow).on("focus.print", () => {
                  $(self.frame.contentWindow).off("focus.print");
                  self.postPrint();
                  //get focus from iframe to window
                  window.focus();
               });
            }, time);
            /**
             * To fix bug 2681541, on Windows Chrome 87, can't get the
             * print dialog's focus leave event, so listen to document's click
             * event as a workaround, the limitation is that when print multi files
             * user need to click the desktop to print the next file.
             * */
            $(document).on("click", () => {
               $(document).off("click");
               self.postPrint();
            });
         };
      }
   }

   constructor(
      private wmksService: WmksService,
      private printRdService: PrintRdService,
      private toastService: NgbToastService,
      private translate: TranslateService,
      private eventBusService: EventBusService
   ) {
      if (WMKS.BROWSER.isFirefox()) {
         this.firefoxTemplate = true;
         this.defaultTemplate = false;
      } else if (WMKS.BROWSER.isIE()) {
         this.defaultTemplate = false;
         this.ieTemplate = true;
      } else {
         this.defaultTemplate = true;
         this.ieTemplate = false;
         this.firefoxTemplate = false;
      }
   }

   private handlePrintMsg = (msg) => {
      if (msg.type === PrintRdService.RECEIVED_PDF) {
         const pdf: PrintFile = msg.data;
         if (pdf.metaData && printUtil.isSupportChromeNewPrinter()) {
            this.eventBusService.dispatch(new BusEvent.PrintFileMsg(pdf));
         } else {
            this.download(pdf);
         }
      } else if (msg.type == PrintRdService.RECEIVED_NOTIFICATION) {
         this.showToast(1);
      } else {
         this.logger.info("unknown message : " + msg.type);
      }
   };

   private showToast = (plus) => {
      const waitingLength = this.printFilesList.length + plus;
      this.toastService.clearAll();
      if (waitingLength > 0) {
         this.toastService.info(
            this.translate._T("PRINT_START") + "...(1/" + waitingLength + ")",
            this.toastService.TOAST_TYPE.PRINT
         );
      }
   };

   private download = (fileData) => {
      this.printFilesList.push(fileData);
      this.logger.info("File is printing: " + this.isPrinting);
      if (!this.isPrinting) {
         this.printFile();
      }
   };

   private printFile = () => {
      if (this.printFilesList.length > 0) {
         this.wmksService.shadowAllHeartbeatForPrint();
         const firstFile = this.printFilesList[0];
         if (WMKS.BROWSER.isFirefox() || WMKS.BROWSER.isIE()) {
            if (this.viewerReady) {
               this.sendMsg(firstFile.pdfData);
            }
         } else {
            if (this.frame) {
               this.isPrinting = true;
               const fileNew = new Blob([firstFile.pdfData], {
                  type: "application/pdf"
               });
               this.frame.contentWindow.location.replace(window.URL.createObjectURL(fileNew));
            }
         }
         //Delete the file once it is printed.
         this.removePrintDoneFile(firstFile.wmksKey, firstFile.jobId);
         this.printFilesList.shift();
      } else {
         this.logger.info("NO files need to be printed");
      }
   };

   private sendMsg = (file) => {
      this.isPrinting = true;
      this.frame.contentWindow.postMessage(file, location.origin);
   };

   private removePrintDoneFile = (key: string, printJodId: number) => {
      this.printRdService.removePrintDoneFile(key, printJodId);
   };

   private printNextFile = () => {
      this.showToast(0);
      this.printFile();
   };

   private postPrint = () => {
      this.isPrinting = false;
      this.logger.debug("Printing done");
      this.wmksService.recoverAllHeartbeatForPrint();
      this.printNextFile();
   };
}
