/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getTunnelConnectionHandler.js --
 *
 *      Implementation of the message handler to get tunnel connection.
 */

import $ from "jquery";
import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";
import Router from "./router";

export default function GetTunnelConnectionHandler() {
   let router;

   // member variables
   this.messageName = "get-tunnel-connection";
   this.messageText = "get-tunnel-connection";
   this.responseTag = "tunnel-connection";

   this.subHandlerList = [];

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
GetTunnelConnectionHandler.prototype = new MessageHandler();
GetTunnelConnectionHandler.constructor = GetTunnelConnectionHandler;

/**
 * Set the request XML for getTunnelConnection.
 *
 * @param bypassTunnel [in] bypass tunnel or not(true/false).
 */

GetTunnelConnectionHandler.prototype.setRequestXML = function (bypassTunnel) {
   if (bypassTunnel === false) {
      // HTML Access should always bypass tunnel
      throw "HTML Access cannot use the tunnel connection!";
      // this.requestXML = "";
      // this.setSubHandler();
   } else {
      this.requestXML = util.createElement("bypass-tunnel", "true");
      if (util.brokerSupportApplication()) {
         this.requestXML += util.createElement("multi-connection-aware", "true");
      }
      this.clearSubHandlers();
   }
};
/**
 * parse tunnel information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */

GetTunnelConnectionHandler.prototype.parseResult = function (responseXML) {
   let brokerTag;
   let responses;
   let result;
   const tunnelConnectionResponse = {};
   let xmlDoc;

   xmlDoc = $(responseXML);
   brokerTag = $(xmlDoc.children()[0]);
   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(tunnelConnectionResponse, nameText, $(this).text());
      });
   }

   return tunnelConnectionResponse;
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */

GetTunnelConnectionHandler.prototype.onUpdated = function () {
   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   // Push error to UI
   Router.prototype.pushErrorToUser(this);
};

/**
 * Set its sub handler.
 * NOTE: this function is not used any more.
 */

// GetTunnelConnectionHandler.prototype.setSubHandler = function ()
// {
//    var setupTunnelConnObject = globalArray["setup-tunnel-connection"];
//    if (!setupTunnelConnObject) {
//       setupTunnelConnObject = new SetupTunnelConnectionHandler();
//       globalArray[setupTunnelConnObject.messageName] = setupTunnelConnObject;
//       globalArray[setupTunnelConnObject.responseTag] = setupTunnelConnObject;
//    } else {
//       setupTunnelConnObject.resetData();
//    }
//
//    this.appendSubHandler(setupTunnelConnObject);
// };
