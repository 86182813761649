/**
 * ******************************************************
 * Copyright (C) 2017-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../../core/libs/logger";
import { StreamReader } from "./stream-reader";
import { StreamWriter } from "./stream-writer";

/**
 * Help to load schema and apply to process of parsing and streaming.
 * Refer protocol-util.js for use examples.
 */

export class ProtocolHelper {
   private binaryLogEnabled = false;
   private logger = new Logger(Logger.CHAN_UTIL);
   private static defaultBaseTypes = {
      NONE: 0, //Default parsed value is 0 or null, used for key holder.
      CHAR: "ASCII", //"UINT8", for string
      WCHAR: "Unicode", //"UINT16", for string, UTF16_LE
      DATA: "DataStream", //ArrayBuffer
      DEVICE_TYPE: 4, //ULONG
      PADDING: 1, //"UINT8", for padding only
      BYTE: 1, //"UINT8", for data
      BOOLEAN: 1, //"UINT8", // uint8_t
      USHORT: 2,
      UINT16: 2,
      UINT32: 4, //"UINT32",
      INT8: "INT8",
      INT16: "INT16",
      INT32: "INT32",
      ULONG: 4,
      LONGLONG: 8, //should be struct if need to handle accurate value
      LARGE_INTEGER: "LARGE_INTEGER",
      ALIGN_TO: "ALIGN_TO" // used when a struct has fixed size, thus dynamic padding when payload is of different size.
   };
   private baseTypes;
   private structs;
   private macros;
   private arrayMatcher = new RegExp("\\w+\\[.{0,2}\\w+([/\\*]2){0,1}\\]");

   constructor(schema: any) {
      this.baseTypes = schema.baseTypes || ProtocolHelper.defaultBaseTypes;
      this.structs = schema.structs || {};
      this.macros = schema.macros || {};
   }
   /**
    * Used to avoid heave trace log.
    */
   private _binaryLogger = (logText) => {
      if (this.binaryLogEnabled) {
         this.logger.trace(logText);
      }
   };

   private _convertToCamel = (string) => {
      if (!(string.length > 0)) {
         return "";
      }
      return string[0].toLowerCase() + string.substr(1);
   };

   private _unsignedRightShift = (number, shift) => {
      return Math.floor(number / (1 << shift));
   };

   /**
    * @private
    */
   private _isBaseType = (dataType) => {
      return typeof dataType === "string" && this.baseTypes.hasOwnProperty(dataType);
   };

   /**
    * @private
    */
   private _isStruct = (dataType) => {
      return typeof dataType === "string" && this.structs.hasOwnProperty(dataType);
   };

   /**
    * @private
    */
   private _isVariance = (dataType) => {
      return dataType === "Variance";
   };

   /**
    * @private
    */
   private _isDataDefined = (dataType) => {
      return dataType === "DataDefinedClass";
   };

   /**
    * @private
    */
   private _isArray = (dataType) => {
      if (typeof dataType !== "string") {
         return false;
      }
      const matchedArray = this.arrayMatcher.exec(dataType);
      return !!matchedArray;
   };

   /**
    * @private
    */
   private _isUnion = (dataType) => {
      return Array.isArray(dataType);
   };

   /**
    * @private
    */
   private _isExplicitStruct = (dataType) => {
      return typeof dataType === "object" && !this._isUnion(dataType) && typeof dataType.forEach === "function";
   };

   /**
    * @private
    */
   private _getArrayInfo = (dataType, data) => {
      const matchedArray = this.arrayMatcher.exec(dataType);
      const splittedString = matchedArray[0].split(/\[|\]/);
      if (!splittedString || splittedString.length !== 3 || splittedString[2] !== "") {
         return;
      }
      const arrayType = splittedString[0];
      const arrayLengthString = splittedString[1];
      let arrayLength = Number(arrayLengthString);

      if (arrayLengthString === "UNLIMITED") {
         //@ts-ignore Evil but useful.
         arrayLength = arrayLengthString;
      } else if (!arrayLength) {
         if (arrayLengthString[0] !== ".") {
            return;
         }
         if (arrayLengthString[1] === ".") {
            const macroName = arrayLengthString.slice(2, arrayLengthString.length);
            if (!this.macros.hasOwnProperty(macroName) || typeof this.macros[macroName] !== "number") {
               return;
            }
            arrayLength = this.macros[macroName];
         } else {
            let lengthPropertyName = this._convertToCamel(arrayLengthString.slice(1, arrayLengthString.length));
            let shiftFactor = 0;
            if (lengthPropertyName.includes("/2")) {
               lengthPropertyName = lengthPropertyName.slice(0, lengthPropertyName.length - 2);
               shiftFactor = 1;
            }
            if (!data.hasOwnProperty(lengthPropertyName) || typeof data[lengthPropertyName] !== "number") {
               return;
            }
            arrayLength = this._unsignedRightShift(data[lengthPropertyName], shiftFactor);
         }
      }
      return {
         type: arrayType,
         length: arrayLength
      };
   };

   /**
    * @private
    */
   private _parseByStruct = (streamReader, struct, result, castConfig) => {
      if (!struct || typeof struct.forEach !== "function") {
         throw "invalid struct to parse";
      }
      streamReader.pushMark(struct);
      struct.forEach((dataType, propertyKey) => {
         const propertyName = this._convertToCamel(propertyKey);
         const logBase = "read " + JSON.stringify(dataType) + " for property " + propertyName;
         result[propertyName] = {}; //default as empty object, will be overwritten if is number
         const propertySlot = result[propertyName];
         if (this._isBaseType(dataType)) {
            result[propertyName] = streamReader.getBasic(this.baseTypes[dataType]);
            this._binaryLogger(
               logBase + " as baseTypes of " + this.baseTypes[dataType] + " bytes as" + result[propertyName]
            );
         } else if (this._isStruct(dataType)) {
            //should be another type
            const subStruct = this.structs[dataType];
            this._parseByStruct(streamReader, subStruct, propertySlot, castConfig); //subStruct is of type struct "json"
            this._binaryLogger(logBase + " as another sub structure " + subStruct);
         } else if (this._isVariance(dataType)) {
            throw logBase + " as Variance TODO";
         } else if (this._isArray(dataType)) {
            //array
            const arrayInfo = this._getArrayInfo(dataType, result);
            const arrayLength: any = arrayInfo.length;
            const arrayType = arrayInfo.type;
            if (arrayLength > 0 || (arrayLength === "UNLIMITED" && this.baseTypes[arrayType] === "DataStream")) {
               if (this.baseTypes[arrayType] === "ASCII") {
                  result[propertyName] = streamReader.getString(arrayLength);
               } else if (this.baseTypes[arrayType] === "Unicode") {
                  result[propertyName] = streamReader.getUnicode(arrayLength);
               } else if (this.baseTypes[arrayType] === "DataStream") {
                  result[propertyName] = streamReader.getStream(arrayLength);
               } else if (this.baseTypes[arrayType] === "ALIGN_TO") {
                  result[propertyName] = streamReader.getAlignTo(arrayLength);
               } else {
                  //default save as array
                  const propertyValue = [];
                  for (let i = 0; i < arrayLength; i++) {
                     if (this._isBaseType(arrayType)) {
                        this._binaryLogger(logBase + " as baseTypes of " + this.baseTypes[arrayType] + " bytes");
                        propertyValue[i] = streamReader.getBasic(this.baseTypes[arrayType]);
                     } else if (this._isStruct(arrayType)) {
                        //should be another type
                        const arrayStruct = this.structs[arrayType];
                        propertyValue[i] = {};
                        this._parseByStruct(streamReader, arrayStruct, propertyValue[i], castConfig);
                        this._binaryLogger(logBase + " as another sub structure in the array" + arrayStruct);
                     } else {
                        throw logBase + " failed, since the array data type is invalid" + dataType;
                     }
                  }
                  result[propertyName] = propertyValue;
               }
            } else if (arrayLength === 0) {
               this._binaryLogger(logBase + " success, as an empty string");
               result[propertyName] = "";
            } else {
               throw logBase + " failed, since the array length is invalid" + dataType;
            }
         } else if (this._isUnion(dataType)) {
            //array is used as union, so need to use castConfig to decide
            this._binaryLogger(logBase + " as union" + JSON.stringify(dataType));
            if (!castConfig.hasOwnProperty(propertyName)) {
               throw logBase + " as union fails, since castConfig is invalid";
            }
            let unionType = "";
            if (typeof castConfig[propertyName] === "string") {
               unionType = castConfig[propertyName];
            } else if (typeof castConfig[propertyName] === "function") {
               // Only support the result.
               unionType = castConfig[propertyName](result);
            }
            let hasMatchedType = false;
            if (unionType) {
               hasMatchedType = dataType.some((value, key) => {
                  if (value.type === unionType) {
                     this._binaryLogger(logBase + " as union success as " + unionType);
                     propertySlot.unionType = unionType;
                     let format;
                     if (typeof value.format === "string" && this._isStruct(value.format)) {
                        format = this.structs[value.format];
                     } else {
                        format = value.format;
                     }
                     this._parseByStruct(streamReader, format, propertySlot, castConfig); //subStruct is of type struct "json"
                     return true;
                  }
                  return false;
               });
            }
            if (!hasMatchedType) {
               throw logBase + " as union fails, since can't find the matched union type " + unionType;
            }
         } else if (this._isExplicitStruct(dataType)) {
            // Explicity structure
            this._binaryLogger(logBase + " as object of explicity structure: " + JSON.stringify(dataType));
            this._parseByStruct(streamReader, dataType, propertySlot, castConfig);
         } else {
            throw logBase + " failed, since the type is invalid: " + dataType;
         }
      });
      streamReader.popMark(struct);
      return result;
   };

   /**
    * @private
    */
   private _parseByName = (streamReader, type, result, castConfig?: any) => {
      if (!this._isStruct(type)) {
         throw "invalid name to parse " + type;
      }
      if (!result) {
         result = {};
      }
      const struct = this.structs[type];
      return this._parseByStruct(streamReader, struct, result, castConfig);
   };

   /**
    * From streamReader to object
    * @param  {[type]} streamReader [description]
    * @param  {[type]} type         [description]
    * @param  {[type]} castConfig   [description]
    * @return {[type]}              [description]
    */
   public parse = (streamReader, type, castConfig?: any) => {
      const result = {};
      return this._parseByName(streamReader, type, result, castConfig);
   };

   /**
    * @private
    */
   private _streamifyByStruct = (streamWriter, struct, data, castConfig?: any) => {
      if (!struct || typeof struct.forEach !== "function") {
         throw "invalid struct to streamify";
      }
      if (!data) {
         this.logger.warning("no data to streamify");
      }

      streamWriter.pushMark(struct);
      struct.forEach((dataType, propertyKey) => {
         const propertyName = this._convertToCamel(propertyKey);
         const logBase = "write " + JSON.stringify(dataType) + " for property " + propertyName;
         const propertyValue = data[propertyName];
         if (propertyValue === undefined) {
            throw "missing property data for key: " + propertyName;
         }
         if (this._isBaseType(dataType)) {
            this._binaryLogger(logBase + " as baseTypes of " + this.baseTypes[dataType] + " bytes as " + propertyValue);
            streamWriter.pushNumber(propertyValue, this.baseTypes[dataType]);
         } else if (this._isStruct(dataType)) {
            const subStruct = this.structs[dataType];
            this._streamifyByStruct(streamWriter, subStruct, propertyValue, castConfig);
            this._binaryLogger(logBase + " as another sub structure " + JSON.stringify(subStruct));
         } else if (this._isVariance(dataType)) {
            // where the length is not sure, so check castConfig for detail
            if (!castConfig || !castConfig.hasOwnProperty(propertyName)) {
               throw logBase + " as Variance fail, since find no key " + propertyName + " in the castConfig: ";
            }
            const varientTypes = castConfig[propertyName];
            this._binaryLogger(logBase + " as Variance:" + varientTypes);
            //TODO, currently only accept array of sub type names
            if (Array.isArray(varientTypes)) {
               for (let j = 0; j < varientTypes.length; j++) {
                  const varientType = varientTypes[j];
                  const varientName = varientType; //Don't use the caml for this case
                  if (!this._isStruct(varientType)) {
                     throw (
                        logBase +
                        " as Variance fail, since we only support array of subtype names for now, but one of sub type is:"
                     );
                  }
                  const varientStruct = this.structs[varientType];
                  if (!propertyValue.hasOwnProperty(varientName)) {
                     throw (
                        logBase +
                        " as Variance fail, since the proterty don't has corresponding data for " +
                        varientType
                     );
                  }
                  const varientData = propertyValue[varientName];
                  this._streamifyByStruct(
                     streamWriter,
                     varientStruct,
                     varientData,
                     castConfig /*TODO support nested castConfig*/
                  );
               }
            }
         } else if (this._isDataDefined(dataType)) {
            const classTypeKey = "classType";
            const classDataKey = "classData";
            if (!propertyValue.hasOwnProperty(classTypeKey)) {
               throw logBase + " as union fails, since data defined union type is missing";
            }
            //only support type name to indicate data-defined union
            const dataDefinedClassName = propertyValue[classTypeKey];
            const dataDefinedClassData = propertyValue[classDataKey];
            this._streamifyByName(streamWriter, dataDefinedClassName, dataDefinedClassData, castConfig);
         } else if (this._isArray(dataType)) {
            const arrayInfo = this._getArrayInfo(dataType, data);
            const arrayLength: any = arrayInfo.length;
            const arrayType = arrayInfo.type;
            if (!(arrayLength >= 0) && (arrayLength !== "UNLIMITED" || this.baseTypes[arrayType] !== "DataStream")) {
               throw logBase + " failed, since the array length is invalid" + dataType;
            }

            if (arrayLength === 0) {
               this._binaryLogger(logBase + " is skipped, since array is of length 0");
            } else if (typeof propertyValue === "string" && this.baseTypes[arrayType] === "ASCII") {
               streamWriter.pushString(propertyValue, arrayLength);
               this._binaryLogger(logBase + " as string " + propertyValue + " of length :" + arrayLength);
            } else if (typeof propertyValue === "string" && this.baseTypes[arrayType] === "Unicode") {
               streamWriter.pushUnicode(propertyValue, arrayLength);
               this._binaryLogger(logBase + " as unicode string " + propertyValue + " of length :" + arrayLength);
            } else if (propertyValue instanceof ArrayBuffer && this.baseTypes[arrayType] === "DataStream") {
               streamWriter.pushStream(propertyValue, arrayLength);
               this._binaryLogger(logBase + " as data stream " + propertyValue + " of length :" + arrayLength);
            } else if (this.baseTypes[arrayType] === "ALIGN_TO") {
               streamWriter.alignTo(arrayLength);
               this._binaryLogger(logBase + " force align to: " + arrayLength);
            } else {
               //default as number
               for (let i = 0; i < arrayLength; i++) {
                  const arrayData = propertyValue[i];
                  if (this._isBaseType(arrayType)) {
                     this._binaryLogger(logBase + " as baseTypes of " + this.baseTypes[arrayType] + " bytes");
                     streamWriter.pushNumber(arrayData, this.baseTypes[arrayType]);
                  } else if (this._isStruct(arrayType)) {
                     //should be another type
                     const arrayStruct = this.structs[arrayType];
                     this._streamifyByStruct(streamWriter, arrayStruct, arrayData, castConfig); //arrayStruct is of type struct "json"
                     this._binaryLogger(logBase + " as another sub structure in the array" + arrayStruct);
                  } else {
                     throw logBase + " failed, since the array data type is invalid" + dataType;
                  }
               }
            }
         } else if (this._isUnion(dataType)) {
            //array is used as union, so need to use castConfig to decide
            this._binaryLogger(logBase + " as union" + JSON.stringify(dataType));
            if (!castConfig.hasOwnProperty(propertyName)) {
               throw logBase + " as union fails, since castConfig is invalid";
            }
            let unionType = "";
            if (typeof castConfig[propertyName] === "string") {
               unionType = castConfig[propertyName];
            } else if (typeof castConfig[propertyName] === "function") {
               // Only support the property in the same layer.
               unionType = castConfig[propertyName](data);
            }
            let hasMatchedType = false;
            if (unionType) {
               hasMatchedType = dataType.some((value, key) => {
                  if (value.type === unionType) {
                     this._binaryLogger(logBase + " as union success as " + unionType);
                     this._streamifyByStruct(streamWriter, value.format, propertyValue, castConfig); //value.format is of type struct "map"
                     return true;
                  }
                  return false;
               });
            }
            if (!hasMatchedType) {
               throw logBase + " as union fails, since can't find the matched union type " + unionType;
            }
         } else if (this._isExplicitStruct(dataType)) {
            //explicity structure
            this._binaryLogger(logBase + " as object of explicity structure: " + JSON.stringify(dataType));
            this._streamifyByStruct(streamWriter, dataType, propertyValue, castConfig);
         } else {
            throw logBase + " failed, since the type is invalid: " + dataType;
         }
      });
      streamWriter.popMark(struct);
   };

   /**
    * @private
    */
   _streamifyByName = (streamWriter, type, data, castConfig) => {
      if (!this._isStruct(type)) {
         throw "invalid name to streamify" + type;
      }
      if (!data) {
         this.logger.debug("no data to streamify, skip");
         return;
      }
      const struct = this.structs[type];
      this._streamifyByStruct(streamWriter, struct, data, castConfig);
   };

   /**
    * From data into streamWriter
    * @param  {[type]} streamWriter The writer to accept stream
    * @param  {string} type         The struct name defined in the schema
    * @param  {object} data         The object containing all data
    * @param  {object} castConfig
    */
   public streamify = (streamWriter, type, data, castConfig) => {
      if (this._isExplicitStruct(type)) {
         this._streamifyByStruct(streamWriter, type, data, castConfig);
      } else if (this._isStruct(type)) {
         this._streamifyByName(streamWriter, type, data, castConfig);
      } else {
         throw "invalid type to streamify" + type;
      }
   };

   /**
    * Simplified API, better only use this when the struct is simple
    * @param  {TypedArray} arrayBuffer The data get from VVC
    * @param  {string} type            The struct name defined in the schema
    * @param  {object} option          The option, currently only spport cast option
    * @return {object}                 The object containing all data.
    */
   public simplifiedParse = (arrayBuffer, type, option) => {
      return this.parse(new StreamReader(arrayBuffer, false, true), type, option);
   };

   /**
    * Simplified API, better only use this when the struct is simple
    * @param  {string} type   The struct name defined in the schema
    * @param  {object} data   The object containing all data
    * @param  {object} option The option, currently only spport cast option
    * @return {Uint8Array}    The buffer containing all data in correct order.
    */
   public simplifiedStreamify = (type, data, option) => {
      const writer = new StreamWriter(false, true);
      this.streamify(writer, type, data, option);
      return writer.getStream();
   };

   /**
    * @return {object} The loaded schema
    */
   public getSchema = () => {
      return {
         structs: this.structs,
         macros: this.macros,
         baseTypes: this.baseTypes
      };
   };
}
