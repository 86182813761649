/**
 * ******************************************************
 * Copyright (C) 2017-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Service which is responsible for client mode switch and init/tear down main
 * service, wmks-service and entitlement-service.
 */

import { Injectable } from "@angular/core";
import { CLIENT_MODE, EventBusService, clientUtil } from "@html-core";
import { LaunchUriService } from "../../desktop/common/launch-uri.service";

//replace with real class later
type mainServiceType = any;
type wmksServiceType = any;
type jscdkWrapper = any;

@Injectable({
   providedIn: "root"
})
export class ClientModeService {
   private _launcherPromiseResolve;
   private _desktopPromiseResolve;
   public clientMode;

   constructor(
      private eventBusService: EventBusService,
      private launchUriService: LaunchUriService
   ) {}

   private _launcherPromise = new Promise((resolve, reject) => {
      this._launcherPromiseResolve = resolve;
   });

   private _desktopPromise = new Promise((resolve, reject) => {
      this._desktopPromiseResolve = resolve;
   });

   public setMainService = (mainService: mainServiceType) => {
      this._launcherPromiseResolve(mainService);
   };
   public setWmksServiceAndJSCDKWrapper = (wmksService: wmksServiceType, jscdkWrapper: jscdkWrapper) => {
      this._desktopPromiseResolve([wmksService, jscdkWrapper]);
   };

   public tearDownDesktopService = () => {
      return this._desktopPromise.then(([wmksService, jscdkWrapper]) => {
         jscdkWrapper.tearDown();
      });
   };

   public tearDownLauncherService = () => {
      return this._launcherPromise.then((mainService: mainServiceType) => {
         mainService.tearDown();
      });
   };

   public switchToDesktopMode = (): Promise<any> => {
      this.eventBusService.dispatch({
         type: "clientModeChanged",
         data: CLIENT_MODE.DESKTOP
      });
      this.clientMode = CLIENT_MODE.DESKTOP;

      return this._desktopPromise.then(([wmksService, jscdkWrapper]) => {
         wmksService.init();
         if (!clientUtil.isTitanClient()) {
            jscdkWrapper.init();
         }
      });
   };

   public switchToLauncherMode = (): Promise<any> => {
      this.eventBusService.dispatch({
         type: "clientModeChanged",
         data: CLIENT_MODE.LAUNCHER
      });
      this.clientMode = CLIENT_MODE.LAUNCHER;

      return this._launcherPromise.then((mainService: mainServiceType) => {
         mainService.init();
      });
   };
}
