/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class RebrandImage {
   public abstract getHTMLAccessLogo(): string;
}
