/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * handleURICommand.js --
 *
 * Store the Client URI.
 *
 */

import { globalArray } from "../jscdkClient";
import UrlHandler from "../controllers/urlHandler";
import util from "../util";

export default function HandleURICommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param handleURIAction[in]
 *        - handle (store/reset) the input parameters in an url-handler
 *    instance in JSCDK
 */
HandleURICommand.prototype.execute = function (handleURIAction) {
   let urlHandler = util.getObject(globalArray, "url-handler");
   if (!urlHandler) {
      urlHandler = new UrlHandler();
      globalArray[urlHandler.name] = urlHandler;
   }

   if (handleURIAction.operationType === "store") {
      urlHandler.handleUrlParams(handleURIAction);
   } else if (handleURIAction.operationType === "reset") {
      urlHandler.resetParams();
   } else if (handleURIAction.operationType === "setSAML" && !!handleURIAction.samlArt) {
      urlHandler.setSAML(handleURIAction.samlArt);
   } else if (handleURIAction.operationType === "resetSAML") {
      urlHandler.resetSAML();
   }
};
