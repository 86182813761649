/**
 * ******************************************************
 * Copyright (C) 2016-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { AB } from "./appblast-util.service";

@Injectable({
   providedIn: "root"
})
export class PanelService {
   private zIndex: number = 201;
   private panelList = [];

   /**
    * maxYValue
    *
    * returns the maximum Y value for the top of the clipboard window so
    * that all of the window will still be seen
    */
   private maxYValue = (element): number => {
      return Math.max(0, window.innerHeight - element[0].offsetHeight);
   };

   /**
    * maxXValue
    *
    * returns the maximum X value for the left side of the clipboard
    * window so that all of the window will still be seen or 0 if the
    * window is wider than the browser.
    */
   private maxXValue = (element): number => {
      return Math.max(0, window.innerWidth - element[0].offsetWidth);
   };

   /**
    * clampY
    *
    * Given a Y value that is to large or two small for the entire
    * clipboard window to be seen on the screen clamp so that the windows
    * would be seen. Or 0 if the window is taller than the browser.
    */
   private clampY = (y: number, element): number => {
      return AB.clamp(y, 0, this.maxYValue(element));
   };

   /**
    * clampX
    *
    * Given an X value that is to large or two small for the entire
    * clipboard window to be seen on the screen clamp so that the windows
    * would be seen
    */
   private clampX = (x: number, element): number => {
      return AB.clamp(x, 0, this.maxXValue(element));
   };

   constructor() {}

   /**
    * windowResizeHandler
    *
    * Callback invoked when the browser window is resized. If the window
    * would fall out view because of the resize this method will
    * reposition it so that it will still be visible
    */
   public windowResizeHandler = (scope, showPanel, element) => {
      if (!scope || !showPanel || !element) {
         return;
      }

      return () => {
         let x, y;

         // Only move the window if it is visible otherwise we will
         // calculate the wrong x and y
         if (!scope[showPanel]) {
            return;
         }

         /**
          *  The height or width of the panel can never be 0.
          *  If offsetWidth or offsetHeight return 0, the element is not
          *  rendered yet. We need to skip window re-location in this
          *  situation. Otherwise the panel could be placed at left-top
          *  of the window.
          */
         if (element[0].offsetHeight === 0 || element[0].offsetWidth === 0) {
            Logger.debug("Element is not ready, do nothing this time.");
            return;
         }

         y = this.clampY(element[0].offsetTop, element);
         x = this.clampX(element[0].offsetLeft, element);

         element.css({
            top: y + "px",
            left: x + "px"
         });
      };
   };

   public addPanelElement = (name, element) => {
      if (!name || !element) {
         Logger.debug("There is no element to add.");
         return;
      }

      this.panelList.push({
         name: name,
         element: element
      });
   };

   public getNewZIndex = () => {
      return this.zIndex++;
   };

   public onFocus = (name) => {
      for (let i = 0; i < this.panelList.length; i++) {
         if (this.panelList[i].name === name) {
            this.panelList[i].element.addClass("panel-front");
            this.panelList[i].element.removeClass("panel-behind");
            this.panelList[i].element.css("z-index", this.getNewZIndex());
         } else {
            this.panelList[i].element.addClass("panel-behind");
            this.panelList[i].element.removeClass("panel-front");
         }
      }
   };
}
