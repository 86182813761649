/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 * vc-transport-message.schema.js --
 *
 * schema used for vc session protocol in little endian
 * bora/apps/rde/rdpvcbridge/common/vcTransportMsg.h
 * @format
 */
export const commonSvcMsgSchema = {
   macros: {},
   structs: {
      DPISyncVersionCommand: new Map([
         ["CommandType", "INT32"],
         ["TargetVersion", "INT32"]
      ]),
      DisplayCommand: new Map([
         ["CommandType", "INT32"],
         ["DisplaysInfo", "RdeChannelDisplaysInfo"]
      ]),
      RdeChannelDisplaysInfo: new Map([
         ["Count", "UINT32"],
         ["DisplayInfos", "RdeChannelDisplayInfo[.Count]"]
      ]),
      RdeChannelDisplayInfo: new Map([
         ["Rect", "VMRect"],
         ["Bpp", "UINT32"],
         ["IsPrimary", "UINT32"],
         ["MonitorDPI", "UINT32"],
         ["Reserved1", "UINT32"],
         ["Reserved2", "UINT32"],
         ["Reserved3", "UINT32"]
      ]),
      VMRect: new Map([
         ["Left", "INT32"],
         ["Top", "INT32"],
         ["Right", "INT32"],
         ["Bottom", "INT32"]
      ])
   }
};
