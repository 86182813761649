<div *ngIf="firefoxTemplate">
   <iframe id='printPDF' style='height: 0;width: 0;border: none;' src="./pdfjs/web/viewer.html">
   </iframe>
</div>

<div *ngIf="defaultTemplate" style="height: 0;">
   <iframe id="printPDF" style="height: 0;width: 0;border: none;"></iframe>
</div>

<div *ngIf="ieTemplate">
   <iframe id='printPDF' style='border: none;' src="./pdfjs/web/viewer.html"></iframe>
</div>