/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { NgModule, Injector } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import "jquery-ui";

// All css import here. DO NOT change their order.
import "bootstrapCss";
import "jqueryuiCss";
import "webmksCss";

import "../shared/styles/style.css";
import "../shared/styles/appblast.css";
import "../shared/styles/clipboard.css";
import "../shared/styles/sidebar.css";
import "../shared/styles/modal-dialogs.css";

import { CoreModule } from "@html-core";
import { UtilsModule } from "../shared/utils/utils.module";
import { PrintRedirectionModule } from "../shared/desktop/printer-redirection/module";
import { HtmlaccessLauncherModule } from "./launcher/launcher.module";
import { ClientCommonModule } from "../shared/common/client-shared.common.module";
import { DesktopModule } from "../shared/desktop/desktop.module";
import { HTML5CommonModule } from "./common/html5-client.common.module";
import { SharedLauncherModule } from "../shared/launcher/launcher.module";
import { AppRootComponent } from "./app-root/app-root.component";
import { AppRoutingModule } from "./app-root/app-root.routing.module";
import { setAppInjector } from "../core/libs/app-injector";
import { MediaModule } from "../shared/desktop/media/media.module";

import { WebBaseModule } from "./base/base.module";
import { Html5DynamicModule } from "./html5-dynamic.module";
@NgModule({
   imports: [
      BrowserModule,
      AppRoutingModule,
      CoreModule,
      CommonModule,
      MediaModule,
      SharedLauncherModule,
      UtilsModule,
      PrintRedirectionModule,
      HtmlaccessLauncherModule,
      ClientCommonModule,
      DesktopModule,
      HTML5CommonModule,
      Html5DynamicModule,
      WebBaseModule
   ],
   bootstrap: [AppRootComponent],
   declarations: [AppRootComponent]
})
export class AppModule {
   constructor(injector: Injector) {
      setAppInjector(injector);
   }
}
