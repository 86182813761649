/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * doUnlockCommand.js --
 *
 *      Called to unlock the broker
 *
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import DoUnlockHandler from "../controllers/doUnlockHandler";
import { GetConfigurationHandler } from "../controllers/getConfigurationHandler";
import GetTunnelConnectionHandler from "../controllers/getTunnelConnectionHandler";
import { StateEnum } from "../controllers/messageHandler";

export default function DoUnlockCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param DoUnlockAction[in]
 *        - its property 'method' has the value 'DoUnlock'
 */
DoUnlockCommand.prototype.execute = function (DoUnlockAction) {
   let doUnlockObject,
      handlerList,
      router = globalArray["router"],
      getConfigurationObject = globalArray["get-configuration"],
      getTunnelConnObject = globalArray["get-tunnel-connection"];
   if (!util.brokerSupportApplication()) {
      return;
   }
   doUnlockObject = globalArray["do-unlock"];
   if (!doUnlockObject) {
      doUnlockObject = new DoUnlockHandler();
      globalArray[doUnlockObject.messageName] = doUnlockObject;
      globalArray[doUnlockObject.responseTag] = doUnlockObject;
   } else {
      doUnlockObject.resetData();
   }

   // Workaround to avoid bug 2173126 before combining JSCDK for desktop and launcher pages.
   if (!getConfigurationObject) {
      getConfigurationObject = new GetConfigurationHandler();
      globalArray[getConfigurationObject.messageName] = getConfigurationObject;
      globalArray[getConfigurationObject.responseTag] = getConfigurationObject;
      // treat as success
      getConfigurationObject.setState(StateEnum.DONE);
   }
   if (!getTunnelConnObject) {
      getTunnelConnObject = new GetTunnelConnectionHandler();
      globalArray[getTunnelConnObject.messageName] = getTunnelConnObject;
      globalArray[getTunnelConnObject.responseTag] = getTunnelConnObject;
      // treat as success
      getTunnelConnObject.setState(StateEnum.DONE);
   }

   if (!!doUnlockObject && !!router) {
      doUnlockObject.setRequestXML();
      handlerList = doUnlockObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};
