/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import "reflect-metadata";
import { enableProdMode } from "@angular/core";

import { platformBrowser } from "@angular/platform-browser";

import { AppModule } from "./html5-client/app.module";

enableProdMode();
platformBrowser().bootstrapModule(AppModule);
