/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable, Optional } from "@angular/core";
import { Subject } from "rxjs";
import { signal } from "../../../common/service/signal";
import { Html5MMRChan } from "./html5MMRChan";
import { RemoteSessionEventService } from "../../../common/remote-session/remote-session-event.service";
import { BlastWmks, BlastWmksSession } from "../../common/blast-wmks.service";
import { EventBusService, LocalStorageService, TranslateService, clientUtil } from "@html-core";
import { ClientSettingModel } from "../../../common/model/client-setting-model";
import { HTML5MMR as HTML5MMRSession } from "./model/html5MMR.session.models";
import { Html5MMR as HTML5E911 } from "./html5MMR-e911Mgr";
import { CommonSvcService } from "../commonSvc.service";
import { GeolocationService } from "../../../common/service/geolocation-service";
import Logger from "../../../../core/libs/logger";
import { NgbToastService } from "../../../common/service/ngb-toast.service";
import { HTML5MMR_CONST } from "./html5MMR-consts";
import { ConnectedMessageService } from "../../../../chrome-client/base/service/connected-message.service";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { MediastreamNotification } from "../../../common/service/mediastream-notification";
import { GoogleCommonSettings } from "../../../../chrome-client/launcher/server-connect/google-common-settings.service";
import { RootModel } from "../../../common/model/root-model";

/**
 * html5MMR.service.ts --
 *
 * Module for wrapping Html5MMRchan to angular factory service. H5mmrClientchan service is
 * a singleton service.
 *
 */

@Injectable({
   providedIn: "root"
})
export class Html5MMRService extends signal implements Html5MMRChan.Html5MMRChanCB {
   private logger = new Logger(Logger.HTML5MMR);
   private clientMap: Map<string, Html5MMRChan.Client> = null;

   html5Subject$ = new Subject();

   constructor(
      private remoteSessionEventService: RemoteSessionEventService,
      private commonSvcService: CommonSvcService,
      private clientSettingModel: ClientSettingModel,
      private localStorageService: LocalStorageService,
      private eventBusService: EventBusService,
      private geolocationService: GeolocationService,
      private e911Service: HTML5E911.E911Manager,
      private toastService: NgbToastService,
      @Optional()
      private connectedMessageService: ConnectedMessageService,
      private translate: TranslateService,
      private modalDialogService: ModalDialogService,
      private mediastreamNotification: MediastreamNotification,
      private googleCommonSettings: GoogleCommonSettings,
      private rootModel: RootModel
   ) {
      super();
      this.clientMap = new Map<string, Html5MMRChan.Client>();

      this.remoteSessionEventService.addEventListener("sessionConnecting", this.onSessionConnecting);
      this.remoteSessionEventService.addEventListener("activeSessionChanged", this.OnActiveSessionChanged);
      this.remoteSessionEventService.addEventListener("sessionDisconnected", this.onSessionDisconnected);
      this.remoteSessionEventService.addEventListener("sessionRemoved", this.onSessionRemoved);
      this.remoteSessionEventService.addEventListener("updateReloadingStatus", this.onSessionReloadingStatusUpdated);
   }

   private onSessionReloadingStatusUpdated = (status) => {
      this.logger.info(`mmrOnSessionReloadingStatusUpdated[${status}]`);
      if (!this.clientSettingModel.getBooleanItem("enableWebRTCRedirection")) {
         this.logger.info("mmrOnSessionReloadingStatusUpdated, WebRTC Redirection disabled. No toast.");
         return;
      }

      if (!status && !!this.localStorageService.getSession(HTML5MMR_CONST.MMR_RUNNNING_SESSION_FLAG)) {
         this.localStorageService.removeSession(HTML5MMR_CONST.MMR_RUNNNING_SESSION_FLAG);
         const warning = this.translate._T(
            clientUtil.isChromeClient() ? "WEBRTC_RELAUNCH_WARNING" : "MS_TEAMS_RELAUNCH_WARNING"
         );
         this.toastService.clearEarlyToast(this.toastService.TOAST_TYPE.PRINT);
         this.toastService.warning(warning, this.toastService.TOAST_TYPE.PRINT);
      }
   };

   private onSessionConnecting = (session: BlastWmksSession) => {
      this.logger.info(`mmrOnSessionConnecting[${session.key}][${session.name}]`);
      if (this.clientMap.has(session.key)) {
         this.clientMap.delete(session.key);
      }

      const defaultDPI = this.clientSettingModel.getTargetDPIScale(),
         sessionModel = new HTML5MMRSession.SessionModel(session, this.commonSvcService, defaultDPI);
      const client = new Html5MMRChan.Client(
         sessionModel,
         session.vdpService,
         session.isApplicationSession,
         this.clientSettingModel,
         this.localStorageService,
         this.eventBusService,
         this.geolocationService,
         this.e911Service,
         this.connectedMessageService,
         this.translate,
         this.modalDialogService,
         this.mediastreamNotification,
         this.googleCommonSettings,
         this.rootModel
      );

      client.addHtml5MMRClientchanObserver(this);
      this.clientMap.set(session.key, client);
      client.key = session.key;

      // TODO: Check if WebRTC is supported in current env. If not, return here.
      client.initialize();
      this.e911Service.init();
   };

   private OnActiveSessionChanged = (session: BlastWmksSession, isNewSession: boolean) => {
      if (session) {
         this.logger.info(`mmrOnSessionChanged[${session.key}][${session.name} isNewSession:${isNewSession}]`);
      }
      this.clientMap.forEach((client) => {
         client.OnActiveSessionChanged(session.key);
      });
   };

   private onSessionDisconnected = (session: BlastWmksSession) => {
      if (session) {
         this.logger.info(`mmrOnSessionDisconnected[${session.key}][${session.name}]`);
         this.cleanupSession(session);
      }
      this.maybeDestroyE911Service();
      this.maybeResetGeolocationService();
   };

   private onSessionRemoved = (session: BlastWmksSession) => {
      if (session) {
         this.logger.info(`mmrOnSessionRemoved[${session.key}][${session.name}]`);
         this.cleanupSession(session);
      }
      this.maybeDestroyE911Service();
      this.maybeResetGeolocationService();
   };

   private maybeDestroyE911Service = () => {
      if (this.clientMap.keys.length === 0) {
         this.e911Service.destroy();
      }
   };

   private maybeResetGeolocationService = () => {
      if (this.clientMap.keys.length === 0) {
         this.geolocationService.resetState();
      }
   };

   private cleanupSession = (session: BlastWmksSession) => {
      if (this.clientMap.has(session.key)) {
         const client = this.clientMap.get(session.key);
         client.destroy();
         this.clientMap.delete(session.key);
      }
   };

   Override;
   public onReady = (client: Html5MMRChan.Client): void => {
      this.html5Subject$.next({
         type: "Html5MMR",
         data: client.key
      });
   };
}
