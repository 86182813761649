<div class="ui-content-area login-bg">
   <div class="container" role="main">
      <div class="ui-center-panel" [ngClass]="{'desktop-reauth-change-password' : (!!appliedData && appliedData.name === 'WindowsPasswordExpired'),'nexttoken-auto-height' : (!!appliedData && appliedData.name === 'SecurIDNextTokenCode')}" >
         <advanced-set></advanced-set>
         <login-content [contentData]="appliedData"></login-content>
      </div>
      <phone-home></phone-home>
      <help-link></help-link>
      <privacy-policy></privacy-policy>
      <download-link *ngIf="enableDownloadInstaller"></download-link>
   </div>
   <div class="bottom-logo"></div>
</div>
