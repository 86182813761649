/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class UserInfoService {
   public abstract getUserName(): string;
   public abstract getUserPrincipalName(): string;
   public abstract getDomainName(): string;
   public abstract setDomainName(domain: string);
}
