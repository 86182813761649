/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * jscdk-feature-status.ts --
 *
 *      Model to store feature status,
 *      defaultly all to false.
 */
const FeatureStatusModel = function () {
   this.status = {
      AzureConnectionRetry: false
   };
   this.set = function (status) {
      for (const key in status) {
         if (this.status.hasOwnProperty(key)) {
            this.status[key] = status[key];
         }
      }
   };
   this.getStatus = function (name) {
      if (!name || !(name in this.status)) {
         return false;
      }
      return this.status[name];
   };
};

const model = new FeatureStatusModel();

export default model;
