/**
 * ******************************************************
 * Copyright (C) 2015-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * setUserGlobalPrefCommand.js --
 *    Implementation of the message handler to set user global setting.
 */

import $ from "jquery";
import Logger from "../../../core/libs/logger";
import { JSCDKSetUI } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function SetUserGlobalPrefHandler() {
   // member variables
   this.messageName = "set-user-global-preferences";
   this.messageText = "set-user-global-preferences";

   this.responseTag = "set-user-global-preferences";

   this.subHandlerList = [];
   this.composedHandlerList = [];
   this.favoriteIds = [];
}

// inherits MessageHandler prototype
SetUserGlobalPrefHandler.prototype = new MessageHandler();
SetUserGlobalPrefHandler.constructor = SetUserGlobalPrefHandler;

/**
 * Reset handler's state and content.
 *
 */
SetUserGlobalPrefHandler.prototype.resetData = function () {
   MessageHandler.prototype.resetData.apply(this);
};

/**
 * Set the request XML for global preferece.
 *
 * @param pref [in] preference json object
 */
SetUserGlobalPrefHandler.prototype.setRequestXML = function (pref) {
   let key,
      prefElements = "";

   for (key in pref) {
      if (pref.hasOwnProperty(key)) {
         prefElements += util.createElement("preference", pref[key], {
            name: key
         });
      }
   }

   this.requestXML = util.createElement("user-preferences", prefElements);
};

/**
 * parse user global preference information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
SetUserGlobalPrefHandler.prototype.parseResult = function (responseXML) {
   let responseBody = responseXML,
      brokerTag = util.getChildNode(responseBody, "broker", 0),
      result,
      errorCodeNode,
      errorMessageNode,
      resultElem,
      favoriteItems = {},
      prefNodes,
      resp = {},
      i,
      pref = {},
      value = "",
      len,
      name,
      key;

   if (!brokerTag) {
      Logger.error("response of SetUserGlobalPref error.");
      return null;
   }

   resultElem = util.getChildNode(brokerTag, this.responseTag, 0);
   //workaround for https://bugzilla.eng.vmware.com/show_bug.cgi?id=3155600
   if (util.getChildNode(resultElem, "result", 1)) {
      resultElem = util.getChildNode(resultElem, "result", 1);
   } else {
      resultElem = util.getChildNode(resultElem, "result", 0);
   }
   if (!resultElem || !resultElem.hasChildNodes()) {
      return null;
   }
   result = resultElem.childNodes[0].nodeValue;
   resp["result"] = result;
   if (result === "error") {
      errorCodeNode = util.getChildNode(responseBody, "error-code", 0);
      if (errorCodeNode) {
         resp["error-code"] = $(errorCodeNode).text();
         JSCDKSetUI(
            JSON.stringify({
               content: resp["error-code"],
               name: "SetPrefErrorMsg"
            })
         );
      }
      errorMessageNode = util.getChildNode(responseBody, "error-message", 0);
      if (errorMessageNode) {
         resp["error-message"] = $(errorMessageNode).text();
      }
   }
   return resp;
};

SetUserGlobalPrefHandler.prototype.onUpdated = function () {
   JSCDKSetUI(
      JSON.stringify({
         ignoreData: true,
         name: "SetUserGlobalPref"
      })
   );
};
