/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 *
 * fs-implementation.js
 *
 * implementation of FS layer API
 * Put it under shared for now, since we don't have the HTML Access version of this file.
 * decouple from entry-util since they are working on different level of FS, and this
 * will have very different implementations for HTML Access.
 */

export class FsImplementation {
   /**
    * @param  {object} directoryEntry Where to place the new entry
    * @param  {string} fileName       Entry name
    * @param  {object} options        Create options, not used for now
    * @return {object}                New entry promise
    */
   static createFile = (directoryEntry, fileName, options) => {
      return new Promise((resolve, reject) => {
         directoryEntry.getFile(
            fileName,
            {
               create: true,
               exclusive: false
            },
            function (fileEntry) {
               resolve(fileEntry);
            }
         );
      });
   };

   /**
    * @param  {object} directoryEntry Where to place the new entry
    * @param  {string} folderName     Entry name
    * @param  {object} options        Create options, not used for now
    * @return {object}                New entry promise
    */
   static createFolder = (directoryEntry, folderName, options) => {
      return new Promise((resolve, reject) => {
         directoryEntry.getDirectory(
            folderName,
            {
               create: true,
               exclusive: false
            },
            function (folderEntry) {
               resolve(folderEntry);
            }
         );
      });
   };
   /**
    * Create a empty file in the client machine
    *
    * @param  {object} directoryEntry Where to place the new entry
    * @param  {string} entryName      Entry name
    * @param  {object} options        Create options
    * @return {object}                New entry promise
    */
   static createEntry = (directoryEntry, entryName, options) => {
      if (options.isDir) {
         return FsImplementation.createFolder(directoryEntry, entryName, options);
      } else {
         return FsImplementation.createFile(directoryEntry, entryName, options);
      }
   };
}
