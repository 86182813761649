/**
 * *****************************************************
 * Copyright 2020 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { BrokerSettingService } from "../../shared/launcher/common/broker-setting.service";
import { BrokerSettingHTML5Service } from "./broker-setting-html5.service";
import { Html5LaunchService } from "./launchitems/launch.service";
import { LaunchService } from "../../shared/launcher/launchitems/session-launch.service";
import { HomeWarningComponent } from "./home-warning/home-warning.component";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { SharedCommonModule } from "../../shared/common/shared.common.module";
import { SharedLauncherCommonModule } from "../../shared/launcher/common/launcher.common.module";
import { DeactivateGuardService } from "../../shared/view/deactive-guard.service";
import { TabsModule } from "../../shared/common/across-tabs/tabs.module";
import { OnRampService } from "../../shared/common/service/on-ramp.service";
import { OnRampServiceHtml5 } from "../../shared/common/service/on-ramp-html5.service";
// claim warning page in html5 launcher module to reduce module number.
@NgModule({
   imports: [
      CommonModule,
      SharedCommonModule,
      SharedLauncherCommonModule,
      TabsModule,

      RouterModule.forChild([
         {
            path: "warning",
            component: HomeWarningComponent,
            canDeactivate: [DeactivateGuardService]
         }
      ])
   ],
   declarations: [HomeWarningComponent],
   providers: [
      OnRampServiceHtml5,
      {
         provide: OnRampService,
         useExisting: OnRampServiceHtml5
      },
      BrokerSettingHTML5Service,
      {
         provide: BrokerSettingService,
         useExisting: BrokerSettingHTML5Service
      },
      Html5LaunchService,
      {
         provide: LaunchService,
         useExisting: Html5LaunchService
      }
   ]
})
export class HtmlaccessLauncherModule {}
