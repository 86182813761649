/**
 * ***************************************************************************
 * Copyright 2016-2023 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

import $ from "jquery";
import { saveAs } from "fileSaver";
import * as CST from "@html-core";
import WMKS from "WMKS";
import { ElementRef, Component, EventEmitter, Output } from "@angular/core";
import { Logger } from "../../../core/libs/logger";
import { EventBusService, TranslateService } from "@html-core";
import { MKSVCHAN_CONST } from "../channels/MKSVchan/mksvchan-consts";
import { MKSVchan } from "../channels/mksvchan";
import { MksvchanService } from "../channels/mksvchan.service";
import { PanelService } from "../common/panel.service";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { TransferHelper } from "./file-transfer-helper.service";
import { SessionLifeCycleService } from "../../../chrome-client/desktop/blast-common/session-life-cycle.service";
import { Optional } from "@angular/core";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { UserInfoService } from "../../common/service/user-info.service";

type ftFile = any;
type uploadServiceType = any;
type downloadServiceType = any;

@Component({
   selector: "[file-change]",
   template: ` <ng-content></ng-content> `
})
export class FileChange {
   private element;
   @Output() fileChange = new EventEmitter();
   constructor(private _el: ElementRef) {
      this.element = $(this._el.nativeElement);
   }
   ngAfterViewInit() {
      this.element.on("change", (e) => {
         this.fileChange.emit(e);
      });
   }
}

@Component({
   selector: "[file-drop]",
   template: ` <ng-content></ng-content> `
})
export class FileDrop {
   private element;
   @Output() fileDrop = new EventEmitter();
   constructor(private _el: ElementRef) {
      this.element = $(this._el.nativeElement);
   }
   ngAfterViewInit() {
      this.element.on("dragover", (e) => {
         e.preventDefault();
         e.stopPropagation();
      });
      this.element.on("dragenter", (e) => {
         e.preventDefault();
         e.stopPropagation();
      });
      this.element.on("drop", (e) => {
         this.fileDrop.emit(e);
         e.preventDefault();
      });
   }
}

@Component({
   selector: "file-transfer-panel",
   templateUrl: "./file-transfer.component.html"
})
export class FileTransferPanelComponent {
   private element: JQuery;
   private windowResizeHandler;
   private userName: string;
   private unList: Array<ftFile>;
   private fileTransferInput: JQuery;
   // Used for focus/blur event
   private clipboardToServer: boolean = false;
   private clipboardToClient: boolean = false;
   private uploadService: uploadServiceType;
   private downloadService: downloadServiceType;
   private errorDialogId: string = null;

   public showFileTransferPanel: boolean = false;
   public focused: boolean = false;
   public helpDialogOpened: boolean = false;
   public showFileDownload: boolean = true;
   public showFileUpload: boolean = false;
   public downloadFiles = new Array<ftFile>();
   public uploadFiles = new Array<ftFile>();
   public uploadEnabled: boolean = true;
   public downloadEnabled: boolean = true;
   public modKey: boolean;
   public chromeClient: boolean;
   public PANEL_TAB = {
      DOWNLOAD: 0,
      UPLOAD: 1
   };

   constructor(
      private _el: ElementRef,
      private panelService: PanelService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private transferHelper: TransferHelper,
      private translate: TranslateService,
      private mksvchanService: MksvchanService,
      private eventBusService: EventBusService,
      private modalDialogService: ModalDialogService,
      @Optional()
      private sessionLifeCycleService: SessionLifeCycleService,
      private userInfoService: UserInfoService
   ) {
      this.chromeClient = CST.clientUtil.isChromeClient();
      this.modKey = WMKS.BROWSER.isMacOS() ? this.translate._T("CMD_KEY") : this.translate._T("CTRL_KEY");
      if (CST.clientUtil.isChromeClient()) {
         chrome.notifications.onButtonClicked.addListener(this.replyBtnClick);
      }

      this.userName = this.userInfoService.getUserName();
      // Listen to file transfer updates and sync panel with agent
      this.mksvchanService.addEventListener(
         "downloadFileListChanged",
         (fileList: Array<ftFile>, client: MKSVchan.Client, error: number) => {
            /**
             * TODO reference client here in case some bugs while
             * desktop switch. See bug 1755174, 1755201, 1756222.
             * These bugs have same root cause. Since it is not likely
             * happen in ft, let's fix it later.
             */
            setTimeout(() => {
               Logger.info("File download list from remote server is" + " synced to client.", Logger.FILE_TRANSFER);
               if (this.downloadEnabled === false || !fileList || fileList.length <= 0 || !this.downloadService) {
                  Logger.info("Download not enable or list is empty!", Logger.FILE_TRANSFER);
                  return;
               }

               // In web client, if panel not opened, do nothing
               if (!this.showFileTransferPanel && !CST.clientUtil.isChromeClient()) {
                  Logger.debug("File control panel is not opened" + " now.", Logger.FILE_TRANSFER);

                  for (let i = 0; i < fileList.length; i++) {
                     this.downloadService.unusedFileList.push(fileList[i]);
                  }
                  return;
               }

               if (!CST.clientUtil.isChromeClient()) {
                  // Skip all duplicated files
                  const duplicatedList = this.downloadService.abstractDuplicatedFiles(fileList);
                  if (!!duplicatedList && !!duplicatedList.length) {
                     Logger.info("Duplicated download files removed", Logger.FILE_TRANSFER);

                     for (let i = 0; i < duplicatedList.length; i++) {
                        this.downloadService.unusedFileList.push(duplicatedList[i]);
                     }
                     if (!fileList.length) {
                        return;
                     }
                  }
               }

               this.downloadService.verifyTotalSize(this.downloadService.getTotalFileSizeForList(fileList));

               if (error === MKSVCHAN_CONST.CLIPBOARD_ERROR.MAX_LIMIT_EXCEEDED) {
                  if (this.showFileTransferPanel) {
                     this.showNotification("FT_FILE_LIST_TRUNCATED_M");
                  }
               }

               // Store old and new file list
               this.transferHelper.updateDownloadFileList(fileList, client);

               // In web client if open panel or in chrome client,
               // begin download right now
               this.unList = [];
               for (let i = 0; i < fileList.length; i++) {
                  this.unList.push(fileList[i]);
               }
               this.beginDownload();
            });
         }
      );

      mksvchanService.addEventListener("fileTransferFailed", () => {
         if (this.showFileTransferPanel) {
            this.showNotification("FT_FAILED_M");
         }
      });

      mksvchanService.addEventListener("fileTransferBlockedByAudit", () => {
         if (this.showFileTransferPanel) {
            this.showNotification("ERROR_MSG_DISALLOWED_BY_AUDIT");
         }
      });

      mksvchanService.addEventListener("fileTransferError", (error: number) => {
         if (this.modalDialogService.isDialogOpen(this.errorDialogId)) {
            Logger.debug("Only open one dialog each time.", Logger.FILE_TRANSFER);
            this.modalDialogService.close(this.errorDialogId);
         }

         let errorMsg: string;
         switch (error) {
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.NO_ENOUGH_DISK:
               errorMsg = this.translate._T("FT_ERROR_NO_ENOUGH_DISK");
               for (let i = 0; i < this.uploadFiles.length; i++) {
                  if (
                     this.uploadFiles[i].progress !== 100 &&
                     this.uploadFiles[i].stopTransfer !== true &&
                     this.uploadFiles[i].transferError !== true
                  ) {
                     this.stopFileUpload(this.uploadFiles[i]);
                  }
               }
               break;
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.FILE_PATH_ILLEGAL:
               errorMsg = this.translate._T("FT_ERROR_FILE_PATH_ILLEGAL");
               for (let i = 0; i < this.uploadFiles.length; i++) {
                  if (
                     this.uploadFiles[i].progress !== 100 &&
                     this.uploadFiles[i].stopTransfer !== true &&
                     this.uploadFiles[i].transferError !== true
                  ) {
                     this.stopFileUpload(this.uploadFiles[i]);
                  }
               }
               break;
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.IS_TRANSFERRING:
               errorMsg = this.translate._T("FT_ERROR_SERVER_IS_TRANSFERRING_FILES");
               break;
            case MKSVCHAN_CONST.FILE_TRANSFER_ERROR.UNKNOWN_ERROR:
               errorMsg = this.translate._T("FT_ERROR_UNKNOWN_ERROR");
               break;
            default:
               Logger.error("Invalid error number: " + error + " received from server.", Logger.FILE_TRANSFER);
         }
         this.errorDialogId = this.modalDialogService.showError({
            data: {
               title: this.translate._T("FT_WARNING_T"),
               content: errorMsg,
               buttonLabelConfirm: this.translate._T("OK")
            }
         });
      });

      /**
       * Download is associated with copy from agent to client
       * (copyEnabled)
       */
      this.mksvchanService.addEventListener(
         "clipboardCapabilitiesChanged",
         (id: string, clipboardReady: boolean, copyEnabled: boolean, pasteEnabled: boolean) => {
            setTimeout(() => {
               this.clipboardToServer = pasteEnabled;
               this.clipboardToClient = copyEnabled;
               const mksClient = this.transferHelper.initTransferService();
               if (mksClient == null) {
                  return;
               }

               this.adjustFileTransferPolicy(mksClient);
            });
         }
      );

      this.transferHelper.addStatusListener(this.updateMKSvchanClient);
      this.eventBusService.listen("toggleFileTransferPanel").subscribe((msg) => {
         this.toggleFileTransferPanel(null, msg.data);
      });
   }

   ngAfterViewInit() {
      this.element = $(this._el.nativeElement.children[0]);
      this.fileTransferInput = $("#file-transfer-input-container");
      this.panelService.addPanelElement("ft", this.element);
      this.element
         .resizable({
            containment: "document",
            /**
             * The min height and min width are the same with the
             * style '.file-transfer-panel' in style.css
             * List them here to prevent bug 1788445. Otherwise
             * Edge could set the height much smaller
             */
            minHeight: 350,
            minWidth: 500
         })
         .draggable({
            containment: "window",
            handle: ".file-transfer-handle",
            start: (event, ui) => {
               this.element.css("z-index", this.panelService.getNewZIndex());
            }
         });

      this.windowResizeHandler = this.panelService.windowResizeHandler(this, "showFileTransferPanel", this.element);

      window.addEventListener("resize", this.windowResizeHandler);
      this.element.on("resize", this.resizeFileContent);
      this.resizeFileContent();
   }

   ngOnDestroy() {
      this.element.unbind("resize", this.resizeFileContent);
      window.removeEventListener("resize", this.windowResizeHandler);
   }

   /**
    * resizes the clipboard contents to fit within the
    * clipboard panel
    *
    * 39 is the header height and 19 is the footer height.
    */
   public resizeFileContent = () => {
      const newHeight = this.element.outerHeight() - 36 - 19;
      $(".file-content").css("height", newHeight);
      $(".file-content-list").css("height", newHeight - $(".file-area-header").outerHeight());
   };

   public switchFileTab = (type: number) => {
      switch (type) {
         case this.PANEL_TAB.DOWNLOAD:
            this.showFileDownload = true;
            this.showFileUpload = false;
            break;
         case this.PANEL_TAB.UPLOAD:
            this.showFileDownload = false;
            this.showFileUpload = true;
            break;
      }
   };

   public onKeypress = (event, type) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         this.switchFileTab(type);
      }
   };
   // shows/hides the file transfer panel
   public toggleFileTransferPanel = ($event, closePanel?: boolean) => {
      if (closePanel === true) {
         this.showFileTransferPanel = false;
      } else if (closePanel === false) {
         this.showFileTransferPanel = true;
      } else {
         this.showFileTransferPanel = !this.showFileTransferPanel;
      }

      if ($event) {
         $event.stopPropagation();
         $event.preventDefault();
      }

      if (this.showFileTransferPanel) {
         setTimeout(() => {
            this.windowResizeHandler();
            this.resizeFileContent();
            this.focusInput();
         });
      }
   };

   public focusInput = () => {
      if (this.fileTransferInput) {
         setTimeout(() => {
            this.fileTransferInput.select();
         });
      }
   };

   public ftPanelFocus = () => {
      if (this.showFileTransferPanel && !this.helpDialogOpened) {
         this.focused = true;
         this.panelService.onFocus("ft");
      }
   };

   public ftPanelBlur = () => {
      this.focused = false;
   };

   public openFileTransferHelp = () => {
      this.modalDialogService.showClipFTHelp({
         data: {
            title: "FILE_TRANSFER_HELP_DIALOG_T",
            msg: "FILE_TRANSFER_HELP_DIALOG_M",
            modKey: this.modKey
         },
         callback: () => {
            this.helpDialogOpened = false;
         }
      });
      this.helpDialogOpened = true;
   };

   public clearDownloadList = () => {
      if (this.downloadService.isTransferringFile()) {
         Logger.debug("Another task is in process.", Logger.FILE_TRANSFER);
         return;
      }

      for (let i = 0; i < this.downloadFiles.length; i++) {
         if (this.downloadFiles[i].contentData) {
            // Delete the file content first to trigger gc
            delete this.downloadFiles[i].contentData;
         }
      }
      // Be careful, this is referenced array
      this.downloadFiles.splice(0, this.downloadFiles.length);
   };

   public stopFileDownload = (file: ftFile) => {
      this.downloadService.stopFileDownload(file);
   };

   public removeDownloadFile = (file: ftFile) => {
      for (let i = 0; i < this.downloadFiles.length; i++) {
         if (file === this.downloadFiles[i]) {
            this.downloadFiles.splice(i, 1);
            break;
         }
      }
   };

   public clearUploadList = () => {
      if (this.uploadService.isTransferringFile()) {
         Logger.debug("Another task is in process.", Logger.FILE_TRANSFER);
         return;
      }

      this.uploadFiles.splice(0, this.uploadFiles.length);
      this.clearFileInput();
   };

   public stopFileUpload = (file: ftFile) => {
      this.uploadService.stopFileUpload(file);
      this.clearFileInput();
   };

   public uploadHandler = (event) => {
      if (!this.uploadEnabled) {
         return;
      }

      this.switchFileTab(this.PANEL_TAB.UPLOAD);

      let currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (CST.clientUtil.isChromeClient() && CST.clientUtil.isSeamlessMode()) {
         if (this.sessionLifeCycleService) {
            currentSession = this.sessionLifeCycleService.getCurrentSession();
         }
      }

      const mksClient = this.mksvchanService.getClient(currentSession.key);
      if (mksClient == null) {
         return;
      }

      const files = event.target.files || event.originalEvent.dataTransfer.files;
      if (CST.clientUtil.isChromeClient() && files.length === 0) {
         /**
          * In chrome client, if users drag some files with folders,
          * files length may be 0. We can't get other normal files data.
          * So show warning dialog here.
          */
         this.modalDialogService.showError({
            data: {
               titleKey: "FT_WARNING_T",
               contentKey: "FT_NO_FILE_WARNING_M",
               buttonLabelConfirmKey: "OK"
            }
         });
         return;
      }
      for (let i = 0; i < files.length; i++) {
         files[i].fullPath = files[i].name;
         files[i].relPath = files[i].name;
         files[i].readableSize = mksClient.FTUtil.getReadableSize(files[i].size);
         files[i].progress = 0;
         files[i].stopTransfer = false;
         // Is the file put into upload queue
         files[i].queued = false;

         if (this.checkFileNameLength(files[i].name) && this.checkIllegalCharater(files[i].name)) {
            files[i].transferError = false;
         } else {
            files[i].transferError = true;
         }

         this.uploadFiles.push(files[i]);
      }

      // this.$apply();
      this.beginUpload();
      $("#file-transfer-input-container").css("visibility", "hidden");
   };

   /**
    * Defined this function into window instead of scope to bypass the bug
    * 1793969, where if the $("#upload-file-input").click(); is in the
    * $timeout, no file selection window will be generated when using
    * multimon. And remove the $timeout alone will generate apply error,
    * since we call scope event-firing function inside scope event handing
    * funciton.
    */
   public chooseUploadFiles = () => {
      setTimeout(() => {
         $("#upload-file-input").click();
      });
   };

   public chromeChooseUploadFiles = () => {
      setTimeout(() => {
         $("#upload-file-input").click();
      });
   };

   public beginUpload = () => {
      this.uploadService.init(this.uploadFiles, () => {
         Logger.debug("Upload task finish.", Logger.FILE_TRANSFER);
      });

      if (!this.uploadService.uploadReady()) {
         return;
      }

      this.uploadService.beginUpload();
      this.clearFileInput();
   };

   public isChromeClient = () => {
      return CST.clientUtil.isChromeClient();
   };

   public preventScriptInjection = (str: string) => {
      if (str !== undefined) {
         return str.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      } else {
         return "";
      }
   };

   public showNotification = (downloadFile: ftFile) => {
      const fileName = this.preventScriptInjection(downloadFile.relPath);
      const opt: any = {
         type: "basic",
         title: "Download files",
         message: this.translate._T("CONFIRM_CHROME_FT1") + fileName + " " + this.translate._T("CONFIRM_CHROME_FT2"),
         buttons: [{ title: this.translate._T("OK") }, { title: this.translate._T("CANCEL") }],
         iconUrl: "icons-" + __BUILD_NUMBER__ + "/icon_settings.svg"
      };
      const callback = () => {
         Logger.info("Show notification", Logger.FILE_TRANSFER);
      };
      const notID = downloadFile.uuid;
      chrome.notifications.create(notID, opt, callback);

      setTimeout(() => {
         // clear the notification after 50s
         chrome.notifications.clear(notID, (wasCleared) => {
            Logger.info("Notification " + notID + " cleared: " + wasCleared, Logger.FILE_TRANSFER);
         });
      }, 50000);
   };

   public replyBtnClick = (notificationId: string, buttonIndex: number) => {
      //Write function to respond to user action.
      Logger.trace("replay btn click id = " + notificationId + " btnIndex = " + buttonIndex, Logger.FILE_TRANSFER);
      if (buttonIndex === 0) {
         // user press ok button
         const unList = this.unList;
         for (let i = 0; i < unList.length; i++) {
            if (unList[i].uuid === notificationId) {
               this.saveFile(this.unList[i]);
            }
         }
         this.saveFile();
      }
   };

   private saveFiles = () => {
      Logger.info("Download task finish.", Logger.FILE_TRANSFER);
      const downloadList = this.downloadFiles;
      const unList = this.unList;
      for (let i = 0; i < unList.length; i++) {
         for (let j = 0; j < downloadList.length; j++) {
            if (unList[i].fullPath === downloadList[j].fullPath && unList[i].uuid === downloadList[j].uuid) {
               this.showNotification(downloadList[j]);
            }
         }
      }
   };

   public beginDownload = () => {
      this.downloadService.init(this.downloadFiles, () => {
         Logger.debug("Download task finish.", Logger.FILE_TRANSFER);
      });

      if (!this.downloadService.downloadReady()) {
         return;
      }

      if (CST.clientUtil.isChromeClient()) {
         this.downloadService.beginDownload(this.saveFiles);
      } else {
         this.downloadService.beginDownload();
      }
   };

   /**
    * save file into the local machine, which will bring side effects when
    * using multimon so need to emit event for multimon module to handle.
    */
   public saveFile = (file?: ftFile) => {
      $(window).trigger("fileDownloaded");
      saveAs(new Blob([file.contentData.buffer]), file.relPath);
   };

   public clearFileInput = () => {
      const inputEle = $("#upload-file-input");
      /**
       * Special handle for IE
       * $('#upload-file-input').val('') will trigger new file input
       * change event in IE.
       * See here:
       * http://stackoverflow.com/questions/1043957/clearing-input
       * -type-file-using-jquery/1043969#1043969
       */
      if (WMKS.BROWSER.isIE() && WMKS.BROWSER.version.major <= 11) {
         //@ts-ignore
         inputEle.wrap("<form>").closest("form").get(0).reset();
         inputEle.unwrap();
      } else {
         inputEle.val("");
      }
   };

   public adjustFileTransferPolicy = (mksClient: MKSVchan.Client) => {
      /**
       * If copy/paste from server to client is disabled, file
       * download cannot be used
       */
      if (this.clipboardToClient === false) {
         mksClient.FTUtil.config.downloadEnabled = false;
         this.downloadEnabled = false;
      }

      if (!this.downloadEnabled && !this.uploadEnabled) {
         mksClient.session.fileTransfer = true;
         this.showFileTransferPanel = false;
         return;
      }

      mksClient.session.fileTransfer = false;
      if (!this.downloadEnabled && this.uploadEnabled) {
         this.switchFileTab(this.PANEL_TAB.UPLOAD);
      }
      if (this.downloadEnabled && !this.uploadEnabled) {
         this.switchFileTab(this.PANEL_TAB.DOWNLOAD);
      }
   };

   public updateMKSvchanClient = () => {
      setTimeout(() => {
         const mksClient = this.transferHelper.initTransferService();
         if (mksClient == null) {
            return;
         }
         this.uploadService = mksClient.uploadService;
         this.downloadService = mksClient.downloadServices[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT];
         this.downloadFiles = mksClient.FTUtil.downloadList[MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT];
         this.uploadFiles = mksClient.FTUtil.uploadList;
         this.uploadService.fileChunkQueue = mksClient.FTUtil.fileChunkQueue;

         this.downloadEnabled = !!mksClient.FTUtil.config.downloadEnabled && !WMKS.BROWSER.isSafari();
         this.uploadEnabled = !!mksClient.FTUtil.config.uploadEnabled;

         this.adjustFileTransferPolicy(mksClient);

         if (this.showFileTransferPanel) {
            this.showNotification("FT_SESSION_UPDATE_M");
         }
      });
   };

   public checkFileNameLength = (fileName: string) => {
      /**
       * Windows has the max 260 path length restriction
       * Mac has max 255 file name length restriction
       * Since we save uploaded file to C:/Users/userName/Documents/,
       * we need to calculate the length the whole path
       */
      const MAX_LENGTH = 250;

      if (!fileName || fileName === "" || fileName.length > MAX_LENGTH) {
         return false;
      }

      return 21 + fileName.length + this.userName.length <= MAX_LENGTH;
   };

   public checkIllegalCharater = (fileName: string) => {
      return (
         fileName.indexOf("\\") < 0 &&
         fileName.indexOf("/") < 0 &&
         fileName.indexOf(":") < 0 &&
         fileName.indexOf("*") < 0 &&
         fileName.indexOf("?") < 0 &&
         fileName.indexOf('"') < 0 &&
         fileName.indexOf("<") < 0 &&
         fileName.indexOf(">") < 0 &&
         fileName.indexOf("|") < 0
      );
   };

   public isSafari = () => {
      return WMKS.BROWSER.isSafari();
   };
}
