/**
 * ******************************************************
 * Copyright (C) 2022-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { EventBusService, LocalStorageService, Logger } from "@html-core";
import { ConnectionServerModel } from "../../common/model/connection-server-model";

@Injectable()
export class AutoForwardPolicyService {
   private static readonly AUTO_FORWARD_USB_KEY = "HorizonAutoUSB";
   private static readonly AUTO_FORWARD_USB_KEY_NEW = "HorizonAutoUSB_New";
   public isFromPolicy: boolean;
   private _googlePolicyMap = new Map();
   private _desktopPolicyMap = new Map<string, AutoUsbPolicyPerDesktop>();
   private _policyToStorage = {} as AutoUSBPolicyToStorage;
   public globalPolicy;
   private logger = new Logger(Logger.AUTO_USB);

   constructor(
      private connectionServerModel: ConnectionServerModel,
      private localStorageService: LocalStorageService,
      private eventBusService: EventBusService
   ) {}

   public getPolicy = () => {
      this._getDataFromLocalStorage();
      this._getSettingFromGooglePolicy(this.connectionServerModel.googleCommonAdminSettings);
      this._getEditableDetail(this.connectionServerModel.googleCommonAdminSettings);
      if (this.isFromPolicy) {
         this.logger.info("Use policy from Google admin");
         this._desktopPolicyMap = this.getGoogleSetting();
      } else {
         this.logger.info("Use policy from Local storage");
         this._desktopPolicyMap = this.generatePolicyMap();
      }
      return this._desktopPolicyMap;
   };

   public updatePolicyMap = (newMap) => {
      this._desktopPolicyMap = newMap;
   };

   public isSameDevice = (device, policyDevice): boolean => {
      return (
         String(device.productId) === String(policyDevice.productId) &&
         String(device.vendorId) === String(policyDevice.vendorId)
      );
   };

   public generatePolicyMap = () => {
      let res = {};
      if (this._policyToStorage.policy) {
         res = JSON.parse(this._policyToStorage.policy);
      }
      const policyMap = new Map<string, AutoUsbPolicyPerDesktop>();
      Object.keys(res).forEach((key) => {
         //@ts-ignore
         policyMap.set(key, res[key]);
      });
      return policyMap;
   };

   public updatePolicyToLocalStorage = () => {
      this.updateSettingToStorage(this._desktopPolicyMap, null);
      this.logger.debug("Policy has been updated to local Storage");
   };

   private _getSettingFromGooglePolicy = (commonSetting) => {
      const googlePolicyMap = new Map<string, AutoUsbPolicyPerDesktop>();
      if (
         commonSetting &&
         commonSetting.hasOwnProperty("autoForwardUSB") &&
         commonSetting.hasOwnProperty("autoForwardUSBPolicy")
      ) {
         const googlePolicy = commonSetting["autoForwardUSBPolicy"];
         if (googlePolicy && googlePolicy.length > 0) {
            this.logger.debug("Found auto forward usb policy from Google admin.");
            for (let i = 0; i < googlePolicy.length; i++) {
               const desktopName = googlePolicy[i].desktop;
               if (desktopName) {
                  const policy = this._convertGooglePolicy(googlePolicy[i]);
                  googlePolicyMap.set(desktopName, policy);
                  this.logger.debug("Google policy: Got auto usb policy from Google admin.");
               } else {
                  this.logger.warning("Google policy: desktopName isn't expected in autoForwardUSBPolicy");
               }
            }
         } else {
            this.logger.debug("There is no auto usb policy in Google admin.");
         }
      }
      this._googlePolicyMap = googlePolicyMap;
      this.logger.debug("policy map: " + JSON.stringify(this._googlePolicyMap));
   };

   private _getEditableDetail = (commonAdminSettings) => {
      if (!!commonAdminSettings && commonAdminSettings.hasOwnProperty("autoForwardUSB")) {
         this.logger.debug("Auto USB policy has autoForwardUSB key, use autoUsb policy in Google admin first");
         if (commonAdminSettings.hasOwnProperty("editable") && !commonAdminSettings["editable"]["autoForwardUSB"]) {
            this.logger.info("Auto USB policy is enabled but not editable");
            this.isFromPolicy = true;
         } else {
            this.logger.info("Auto USB policy is enabled and editable");
            if (this._policyToStorage.policy !== null) {
               this.isFromPolicy = false;
            } else {
               this.isFromPolicy = true;
            }
         }
      } else {
         this.logger.debug("Auto USB policy doesn't own autoForwardUSB key, use policy in local storage.");
         this.isFromPolicy = false;
      }
      this.eventBusService.dispatch({
         type: "autoUsbPolicyUpdate"
      });
   };

   private _getDataFromLocalStorage = () => {
      const policyV1 = this.localStorageService.get(AutoForwardPolicyService.AUTO_FORWARD_USB_KEY);
      if (policyV1) {
         //Save the v1 policy with the fomrat of v2 in storage
         const newPolicy = new Map<string, any>();
         newPolicy.set(this.connectionServerModel.host, {
            isEnabled: !!this.localStorageService.get("isAutoForwardUSBEnabled"),
            policy: JSON.parse(policyV1)
         });
         //@ts-ignore
         const data = JSON.stringify(Object.fromEntries(newPolicy));
         this.localStorageService.set(AutoForwardPolicyService.AUTO_FORWARD_USB_KEY_NEW, data);
         this.localStorageService.set(AutoForwardPolicyService.AUTO_FORWARD_USB_KEY, null);
      }

      const res = this.localStorageService.get(AutoForwardPolicyService.AUTO_FORWARD_USB_KEY_NEW);
      if (res) {
         const policyObj = JSON.parse(res);
         if (policyObj.hasOwnProperty(this.connectionServerModel.host)) {
            this._policyToStorage = policyObj[this.connectionServerModel.host];
         }
      } else {
         this._policyToStorage = {
            isEnabled: null,
            policy: null
         };
      }
      this.logger.debug("Got policy from Local storage");
   };

   private _convertGooglePolicy = (item) => {
      const devicePolicyLists = new Array<AutoUsbPolicyPerDevice>();
      const connectOnStartAll = !!item?.autoConnectAllOnStart;
      const connectOnInsertAll = !!item?.autoConnectAllOnInsert;
      item.policy?.forEach((policyInfo) => {
         const vendorId = policyInfo.device.split(":");
         const newPolicy: AutoUsbPolicyPerDevice = {
            productName: "",
            vendorId: String(parseInt(vendorId[0], 16)),
            productId: String(parseInt(vendorId[1], 16)),
            autoConnectOnInsert: !!policyInfo?.autoConnectOnInsert,
            autoConnectOnStart: !!policyInfo?.autoConnectOnStart
         };
         if (connectOnStartAll) {
            newPolicy.autoConnectOnStart = true;
         }
         if (connectOnInsertAll) {
            newPolicy.autoConnectOnInsert = true;
         }
         devicePolicyLists.push(newPolicy);
      });

      return {
         autoConnectAllOnStart: connectOnStartAll,
         autoConnectAllOnInsert: connectOnInsertAll,
         devicePolicy: devicePolicyLists
      };
   };

   public getGoogleSetting = () => {
      return this._googlePolicyMap;
   };

   public updateSettingToStorage = (setting?: any, isEnabled?: boolean) => {
      if (setting !== null) {
         //@ts-ignore
         this._policyToStorage.policy = JSON.stringify(Object.fromEntries(setting));
      }
      if (isEnabled !== null) {
         this._policyToStorage.isEnabled = isEnabled;
      }
      const policy = new Map<string, any>();
      policy.set(this.connectionServerModel.host, this._policyToStorage);
      //@ts-ignore
      const data = JSON.stringify(Object.fromEntries(policy));
      this.localStorageService.set(AutoForwardPolicyService.AUTO_FORWARD_USB_KEY_NEW, data);
   };

   public getKillSwitch = (): boolean => {
      const commonAdminSettings = this.connectionServerModel.googleCommonAdminSettings;
      let killSwitchStatus: boolean = false;
      if (!commonAdminSettings) {
         this.logger.debug("There is no Google admin, use killSwitch in localStorage");
         killSwitchStatus = this._policyToStorage.isEnabled;
      } else {
         if (commonAdminSettings.hasOwnProperty("autoForwardUSB")) {
            if (commonAdminSettings.hasOwnProperty("editable") && !commonAdminSettings["editable"]["autoForwardUSB"]) {
               //Common setting exists, autoForwardUSB is not editable
               //get from policy
               this.logger.debug("AutoForwardUSB killSwitch exists but is uneditable in Google admin");
               killSwitchStatus = commonAdminSettings["autoForwardUSB"];
            } else {
               //autoForwardUSB is editable, get from policy
               if (this._policyToStorage.isEnabled !== null) {
                  //if it's not changed yet, get it from policy
                  this.logger.debug(
                     "AutoForwardUSB killSwitch exists and is editable in Google admin but has been changed."
                  );
                  killSwitchStatus = this._policyToStorage.isEnabled;
               } else {
                  //if it's has been changed, get it from policy
                  this.logger.debug(
                     "AutoForwardUSB killSwitch exists and is editable in Google admin but hasn't been changed."
                  );
                  killSwitchStatus = commonAdminSettings["autoForwardUSB"];
               }
            }
         } else {
            //Common setting exists but doesn't contain autoUsb policy
            //Get from localStorage
            this.logger.debug("AutoForwardUSB killSwitch doesn't exist in google admin");
            killSwitchStatus = this._policyToStorage.isEnabled;
         }
      }
      return killSwitchStatus;
   };
}
