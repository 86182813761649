/**
 * ******************************************************
 * Copyright (C) 2015-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * setUserGlobalPrefCommand.js --
 *    set user global preference command
 */

import { globalArray } from "../jscdkClient";
import SetUserGlobalPrefHandler from "../controllers/setUserGlobalPrefHandler";
import Logger from "../../../core/libs/logger";

export default function SetUserGlobalPrefCommand() {}

SetUserGlobalPrefCommand.prototype.execute = function (setPrefAction) {
   let router = globalArray["router"],
      setPrefHandler = globalArray["set-user-global-preferences"];
   if (!router || !setPrefHandler) {
      Logger.error("No router or setPrefHandler instance");
   }

   if (!setPrefHandler) {
      setPrefHandler = new SetUserGlobalPrefHandler();
      globalArray[setPrefHandler.messageName] = setPrefHandler;
   }
   setPrefHandler.setRequestXML(setPrefAction.prefData);

   router.postMessage(setPrefHandler.composeHandlerList(), setPrefAction.async, setPrefAction.timeout);
};
