/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * GetUserGlobalPrefHandler.js --
 *
 *      Implementation of the message handler to get user global setting.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";

export default function GetUserGlobalPrefHandler() {
   // member variables
   this.messageName = "get-user-global-preferences";
   this.messageText = "get-user-global-preferences";
   this.responseTag = "user-global-preferences";

   this.notifyUI = true;

   this.subHandlerList = [];
   this.composedHandlerList = [];

   this.prefData = {
      favorites: [] //favorites is an array
   };
}

// inherits MessageHandler prototype
GetUserGlobalPrefHandler.prototype = new MessageHandler();
GetUserGlobalPrefHandler.constructor = GetUserGlobalPrefHandler;

/**
 * Reset handler's state and content.
 *
 */
GetUserGlobalPrefHandler.prototype.resetData = function () {
   MessageHandler.prototype.resetData.apply(this);
};

/**
 * set the request XML for getting user global preference
 */
GetUserGlobalPrefHandler.prototype.setRequestXML = function () {
   this.requestXML = util.createElement("get-user-global-preferences");
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetUserGlobalPrefHandler.prototype.onUpdated = function () {
   const launchitemsHandler = globalArray["get-launch-items"];

   MessageHandler.prototype.onUpdated.apply(this);

   if (this.state === StateEnum.DONE) {
      if (!!launchitemsHandler && !!launchitemsHandler.content["parsedResult"]) {
         this.markFavorite(launchitemsHandler.content.parsedResult.desktops);
         this.markFavorite(launchitemsHandler.content.parsedResult.applications);
      }
   }
   JSCDKSetUI(
      JSON.stringify({
         ignoreData: true,
         name: "GetUserGlobalPref"
      })
   );
};

GetUserGlobalPrefHandler.prototype.markFavorite = function (items) {
   let key;
   for (key in items) {
      if (items.hasOwnProperty(key)) {
         if (this.prefData.favorites.indexOf(items[key].id) >= 0) {
            items[key].favorite = true;
         } else {
            items[key].favorite = false;
         }
      }
   }
};

/**
 * parse user global preference information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
GetUserGlobalPrefHandler.prototype.parseResult = function (responseXML) {
   let responseBody = responseXML,
      brokerTag = util.getChildNode(responseBody, "broker", 0),
      result,
      errorCodeNode,
      errorMessageNode,
      responseElem,
      resultElem,
      favoriteItems = {},
      prefNodes,
      resp = {},
      i,
      pref = {},
      value = "",
      len,
      name,
      key;

   if (!brokerTag) {
      Logger.error("response of GetUserGlobalPref error.");
      return null;
   }
   this.prefData = {
      favorites: [] //favorites is an array
   };

   responseElem = util.getChildNode(brokerTag, this.responseTag, 0);
   resultElem = util.getChildNode(responseElem, "result", 0);
   if (!resultElem || !resultElem.hasChildNodes()) {
      return null;
   }
   result = resultElem.childNodes[0].nodeValue;
   resp["result"] = result;
   if (result === "ok") {
      // only search <preference> inside <user-global-preferences>, see details in bug 2589574.
      prefNodes = responseElem.getElementsByTagName("preference");
      for (i = 0, len = prefNodes.length; i < len; i++) {
         name = prefNodes[i].getAttribute("name");
         if (name) {
            value = $(prefNodes[i]).text();
            if (name.substring(0, "favorite".length) === "favorite") {
               pref = {
                  name: name,
                  id: value
               };
               // pref["name"] = name;
               // pref["id"] = value;
               favoriteItems[Object.keys(favoriteItems).length] = pref;

               this.prefData.favorites.push(value);
            } else {
               this.prefData[name] = value;
            }
         }
      }

      Logger.debug("favorite items: " + JSON.stringify(favoriteItems));
      resp["items"] = favoriteItems;
   } else if (result === "error") {
      errorCodeNode = util.getChildNode(responseBody, "error-code", 0);
      if (errorCodeNode) {
         resp["error-code"] = $(errorCodeNode).text();
      }
      errorMessageNode = util.getChildNode(responseBody, "error-message", 0);
      if (errorMessageNode) {
         resp["error-message"] = $(errorMessageNode).text();
      }
   }
   return resp;
};
