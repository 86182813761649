/**
 * ***************************************************************************
 * Copyright 2020 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { ShareFolderModel } from "../../common/model/share-folder-model";
import { CDRConfig } from "./cdr-config";
import Logger from "../../../core/libs/logger";
import { SharedFolder } from "./shared-folder";
import { FsObserver } from "./fs-observer";

@Injectable()
export class SharedFolderManager {
   private _sharedDrivers = {};
   private _inited = false;
   private _loadingPromise = null;
   constructor(
      private shareFolderModel: ShareFolderModel,
      private cdrConfig: CDRConfig,
      private fsObserver: FsObserver
   ) {}

   private _loadingFolders = async () => {
      Logger.debug("<cdr> loading share folders");
      const preloadPromises = [];
      const entries = await this.shareFolderModel.getValidEntries();

      if (!entries) {
         Logger.info("<cdr> No folder chosen for CDR");
         return;
      }
      for (let i = 0; i < entries.length; i++) {
         preloadPromises.push(this._addShareFolderByEntry(entries[i]));
      }
      await Promise.all(preloadPromises);
   };

   public afterInited = async () => {
      if (!this.cdrConfig.isEnabled() && !this.shareFolderModel.isContainsFA) {
         throw "CDR disabled";
      }
      if (!this._inited) {
         if (!this._loadingPromise) {
            this._loadingPromise = this._loadingFolders();
         }
         await this._loadingPromise;
         this._loadingPromise = null;
         this._inited = true;
      }
      Logger.trace("<cdr> shared folder has been confirmed as inited");
   };

   public getSharedDriver = (id) => {
      if (!this._sharedDrivers.hasOwnProperty(id)) {
         Logger.debug("<cdr> can't find the target shared folder");
         return null;
      }
      return this._sharedDrivers[id];
   };

   public forEach = (callback) => {
      let key;
      for (key in this._sharedDrivers) {
         if (this._sharedDrivers.hasOwnProperty(key)) {
            callback(this._sharedDrivers[key]);
         }
      }
   };

   public removeSharedFolderById = (id) => {
      return new Promise((resolve, reject) => {
         if (this._sharedDrivers.hasOwnProperty(id)) {
            this._sharedDrivers[id].clearForRemove();
            delete this._sharedDrivers[id];
            Logger.info("<cdr> shared folder with id " + id + " removed");
            // @ts-ignore
            resolve();
         } else {
            Logger.info("<cdr> shared folder with id " + id + " failed to be removed");
            reject();
         }
      });
   };

   public reset = () => {
      Logger.info("<cdr> shared folders cleared");
      this._sharedDrivers = {};
      this._inited = false;
      this._loadingPromise = null;
   };

   private _addShareFolderByEntry = (entry: any) => {
      Logger.trace("<cdr> adding new entry as shared folder " + entry.name);
      return new Promise((resolve, reject) => {
         const newSharedFolder = new SharedFolder(entry, resolve, this.fsObserver);
         this._sharedDrivers[newSharedFolder.id] = newSharedFolder;
         Logger.info("<cdr> loading new path as shared folder: " + entry.name);
      });
   };
}
