<div class="modal-title dialog-title">{{ 'SETTINGS' | i18nv2 }}</div>

<div class="modal-body dialog-content" setting-listener [ngClass]="{'titan-setting' : isTitanClient}">
    <div class="settings-container clearfix client-preference-container" *ngIf='showLaunchHorizonOption'>
      <horizon-preference></horizon-preference>
    </div>
    <div class="settings-separator" [hidden]="!isTitanClient"></div>
    <div class="settings-container" *ngIf="keyMappingOptionVisible">
        <button type="button" class="modal-button-base modal-button-blue modal-button-set" (click)="openKeyboardSetting()" title="{{'KEYBOARD_SETTING_SET' | i18nv2}}" id="keyboardSettingBtn">{{'KEYBOARD_SETTING_SET' | i18nv2}}</button>
        <span class="settings-label">{{'KEYBOARD_SETTING_T' | i18nv2}}</span>
        <span class="settings-text">{{'KEYBOARD_SETTING_M' | i18nv2}}</span>
    </div>
    <div class="settings-separator" *ngIf="keyMappingOptionVisible"></div>
    <div *ngFor="let switch of toggleSwitches">
        <div *ngIf='switch.isSupported' [ngClass]="{grayout:isSwitchDisabled(switch)}" [hidden]="!switch.isSupported || killSwitches[switch.id] === false">
            <div class="settings-container clearfix" >
                <div class="settings-toggle-container" [ngClass]="{grayoutForToggle:isToggleDisabled(switch)&&!isSwitchDisabled(switch)}">
                    <div class="toggle-opt" tabindex="0" (keydown)="onKeypress($event)" role="button" [attr.aria-pressed]="switch.isEnabled">
                        <input class="tgl tgl-light" id="{{switch.id}}" type="checkbox" [hidden]="true" [disabled]="isSwitchDisabled(switch) || isToggleDisabled(switch)" [(ngModel)]="switch.isEnabled" (ngModelChange)="switch.toggle()">
                        <label class="tgl-btn unselectable hidecontentlabel" for="{{switch.id}}">{{switch.label}}</label>
                    </div>
                </div>
                <span class="settings-label">{{switch.label}}</span>
                <span class="settings-text" [hidden]="!switch.text">{{switch.text}}</span>
                <div class="log_level" *ngIf="switch.id==='collect_log' && switch.isEnabled" (click)="changeLogLevel()">
                    <input type="radio" id="info" name="log-level" value="info">{{'log_level_info' | i18nv2}}
                    <input type="radio" id="debug" name="log-level" value="debug">{{'log_level_debug' | i18nv2}}
                    <input type="radio" id="trace" name="log-level" value="trace">{{'log_level_trace' | i18nv2}}
                </div>
                <span class="log-path-text settings-text" *ngIf="switch.id==='collect_log'" (click)="changeLogFile()">{{logPathInfo}}</span>
                <div *ngIf="switch.id==='timezone'" id="setTimezone" class="clearfix">
                    <div id="timezone-list-container">
                        <timezone-list [timeZoneStatus]="isAutoTimezoneEnabled" (selectedTimezone)="updateTimezone($event)"></timezone-list>
                    </div>
                </div>
                <div *ngIf="switch.id==='enableFolderSharing'" id="folder_sharing_set" class="clearfix">
                    <button type="button" class="modal-button-base modal-button-blue modal-button-reset" id="select_folder_sharing_btn" [ngClass]="{disable: (!isFolderSharingEnabled || !folderSharingEditable)}" (click)="showCDRDialog()" [disabled]="!isFolderSharingEnabled || !folderSharingEditable">{{'SELECT' | i18nv2}}</button>
                </div>
                <div *ngIf="switch.id==='auto_usb'" id="auto_usb_set" class="clearfix">
                  <button type="button" class="modal-button-base modal-button-blue modal-button-reset" id="auto_usb_btn" [ngClass]="{disable: !isAutoUSBEnabled}" (click)="showAutoUSBDialog()" [disabled]="!isAutoUSBEnabled || !isAutoUSBEditable">{{'SELECT' | i18nv2}}</button>
              </div>
                <div *ngIf="switch.id==='debugConfiguration'&&enableDebugConfiguration" id="debugConfiguration_set" class="clearfix">
                    <button type="button" class="modal-button-base modal-button-blue modal-button-reset" id="debugConfiguration_btn" [ngClass]="{disable: !enableDebugConfiguration}" (click)="showDebugConfigurationDialog()" [disabled]="!enableDebugConfiguration">{{'EDIT_DEBUG_MODULE_LIST' | i18nv2}}</button>
                </div>
            </div>
            <div class="settings-separator" [hidden]='!switch.isSupported || killSwitches[switch.id] === false'></div>
        </div>
    </div>
    <div class="audo-video-container" *ngIf='supportAudioDelayControl'>
        <div class="settings-container clearfix">
            <span class="settings-label">{{'AUDIO_DELAY_T' | i18nv2}}</span>
            <div id="audio-delay-options-container" class="option-container small-top-margin">
                <span class="label-text left-inline-text">{{'CHOOSE_AUDIO_DELAY_M' | i18nv2}}</span>
                <select id="audio-delay-options" name="audioDelayControlOptions" class="form-control form-select right-option setting-dropdown" (ngModelChange)="delayControlOptionChanged($event)" [(ngModel)]="selectedDelayControlOption">
                    <option *ngFor="let delayControlOption of delayControlOptions" value="{{delayControlOption}}">{{"AudioDelay_" + delayControlOption | i18nv2}}</option>
                </select>
            </div>
        </div>
        <div class="settings-separator"></div>
    </div>
      <div *ngIf="isRTAVSupported" id="rtavSettingBtn" class="audo-video-container">
         <div class="settings-container clearfix">
            <button type="button" class="modal-button-base modal-button-blue modal-button-set" (click)="openSettingsRtavConfig()" id="multiMonitorBtn">{{'MULTI_MONITOR_B' | i18nv2}}</button>
            <span class="settings-label">{{'CHOOSE_DEVICE_T' | i18nv2}}</span>
            <span class="settings-text">{{'CHOOSE_DEVICE_ADVANCED_M' | i18nv2}}</span>
         </div>
         <div class="settings-separator"></div>
      </div>
    <div class="settings-container">
        <button type="button" class="modal-button-base modal-button-blue modal-button-reset" [disabled]="isResetButtonDisabled()" [ngClass]="{disable:isResetButtonDisabled()}" (click)="resetApplications()" title="{{'RESET_APPS_T' | i18nv2}}">{{'RESET_APPS_T' | i18nv2}}</button>
        <span class="settings-label">{{'RESET_APPS_M' | i18nv2}}</span>
        <span class="settings-text warning">{{'UNSAVED_WORK_M' | i18nv2}}</span>
    </div>
    <div class="settings-separator" *ngIf="multiMonitorOptionVisible"></div>
    <div class="settings-container" *ngIf="multiMonitorOptionVisible">
        <button type="button" class="modal-button-base modal-button-blue modal-button-set" (click)="openSettingsMultiMonitorConfig()" id="multiMonitorBtn">{{'MULTI_MONITOR_B' | i18nv2}}</button>
        <span class="settings-label">{{'MULTI_MONITOR_T' | i18nv2}}</span>
        <span class="settings-text">{{'MULTI_MONITOR_M' | i18nv2}}</span>
    </div>
    <div class="settings-separator" [hidden]="!isSkipPortalSupported"></div>
    <div class="settings-container" [hidden]="!isSkipPortalSupported">
        <button type="button" class="modal-button-base modal-button-blue modal-button-reset" [disabled]="!isSkipPortalResetable()" [ngClass]="{disable:!isSkipPortalResetable()}" (click)="resetSkipPortal()" title="{{'restore_skip_portal_t' | i18nv2}}">{{'RESET_M' | i18nv2}}</button>
        <span class="settings-label">{{'restore_skip_portal_t' | i18nv2}}</span>
        <span class="settings-text warning">{{'restore_skip_portal_m1' | i18nv2}}</span>
    </div>
    <div class="settings-separator" *ngIf="multiLaunchOptionVisible"></div>
    <div class="settings-container" *ngIf="multiLaunchOptionVisible">
        <button type="button" class="modal-button-base modal-button-blue modal-button-set" (click)="openSettingsMultiLaunchConfig()" id="multiLaunchBtn" title="{{'MULTI_LAUNCH_B' | i18nv2}}">{{'MULTI_LAUNCH_B' | i18nv2}}</button>
        <span class="settings-label">{{'MULTI_LAUNCH_T' | i18nv2}}</span>
        <span class="settings-text">{{'MULTI_LAUNCH_M' | i18nv2}}</span>
    </div>
    <div class="settings-separator" *ngIf="sessionManagementCenterOptionVisible"></div>
    <div class="settings-container" *ngIf="sessionManagementCenterOptionVisible">
        <button type="button" class="modal-button-base modal-button-blue modal-button-set" (click)="openSessionManagementCenter()" title="{{'SESSION_MANAGEMENT_CENTER_B' | i18nv2}}" id="sessionManagementCenterBtn">{{'SESSION_MANAGEMENT_CENTER_B' | i18nv2}}</button>
        <span class="settings-label">{{'SESSION_MANAGEMENT_CENTER_T' | i18nv2}}</span>
        <span class="settings-text">{{'SESSION_MANAGEMENT_CENTER_M' | i18nv2}}</span>
    </div>
</div>

<div class="modal-footer dialog-button-row">
    <button type="button" id="closeSettingsBtn" class="modal-button-base modal-button-right modal-button-blue" (click)="closeSettingModal()" ngbAutofocus>
        {{ 'CLOSE' | i18nv2 }}
    </button>
</div>