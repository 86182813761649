/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { AbstractModalComponent } from "../commondialog/abstract-modal.component";

@Component({
   selector: "gesture-help",
   templateUrl: "./gesture-help.component.html"
})
export class GestureHelpComponent extends AbstractModalComponent {
   public allGestures = [];
   public currentTouchMode: string;

   constructor() {
      super();
      this.allGestures = [
         {
            category: "BASIC_TAPPING_GESTURES",
            gestures: [
               {
                  imgSelector: "gesture-click-tap",
                  name: "GESTURE_CLICK_NAME",
                  description: "GESTURE_CLICK_DESCRIPTION"
               },
               {
                  imgSelector: "gesture-right-click",
                  name: "GESTURE_SECONDARY_CLICK_NAME",
                  description: "GESTURE_SECONDARY_CLICK_DESCRIPTION"
               },
               {
                  imgSelector: "gesture-drag",
                  name: "GESTURE_DRAG_NAME",
                  description: "GESTURE_DRAG_DESCRIPTION"
               },
               {
                  imgSelector: "gesture-tapthree",
                  name: "GESTURE_KEYBOARD_NAME",
                  description: "GESTURE_KEYBOARD_DESCRIPTION"
               }
            ]
         },
         {
            category: "ADVANCED_TAPPING",
            gestures: [
               {
                  imgSelector: "gesture-accurate-drag",
                  name: "GESTURE_ACCURATE_DRAGGING_NAME",
                  description: "GESTURE_ACCURATE_DRAGGING_DESCRIPTION"
               },
               {
                  imgSelector: "gesture-scroll",
                  name: "GESTURE_SCROLL_NAME",
                  description: "GESTURE_SCROLLDESCRIPTION"
               }
            ]
         },
         {
            category: "TRACKPAD",
            gestures: [
               {
                  imgSelector: "gesture-touchpad",
                  name: "GESTURE_TRACKPAD_NAME",
                  description: "GESTURE_TRACKPAD_DESCRIPTION"
               }
            ]
         }
      ];
   }

   public isTrackPadMode = () => {
      return this.currentTouchMode === "TRACKPAD_MODE";
   };
}
