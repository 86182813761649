/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "../../../core/services/translate.service";

@Pipe({
   name: "languageLayoutSearch"
})
export class LanguageLayoutSearchPipe implements PipeTransform {
   constructor(private translate: TranslateService) {}
   transform(layouts, language, query) {
      const textContainsQuery = (text, query) => {
         if (query === "") {
            return true;
         }
         return text.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1;
      };
      const getSearchResult = (layout) => {
         if (
            textContainsQuery(this.translate._T(language), query) ||
            textContainsQuery(this.translate._T(layout), query)
         ) {
            return true;
         } else {
            return false;
         }
      };
      return layouts.filter(getSearchResult);
   }
}
