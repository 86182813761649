/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import $ from "jquery";
import { Component, AfterViewInit } from "@angular/core";
import { AbstractModalComponent } from "../commondialog/abstract-modal.component";
import { MultiSessionSingleUserService } from "../service/multisession-singleuser.service";
import { imageAsset } from "../image-asset/image-asset";

@Component({
   selector: "setting-multi-launch-dialog",
   templateUrl: "./setting-multi-launch-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class SettingMultiLaunchDialogComponent extends AbstractModalComponent implements AfterViewInit {
   public checkBoxIdProfix: string = "checkbox-multisession-";
   public settingProfix: string = "setting-multisession-";
   public configurableApplications;
   public enforcedApplications;
   private defaultOffIds;
   public disableApplyBtn;
   public imageAsset = imageAsset;

   constructor(private multiSessionSingleUserService: MultiSessionSingleUserService) {
      super();
      //Applications binding model.
      this.configurableApplications = this.multiSessionSingleUserService.getEditableStatusArray();
      this.enforcedApplications = this.multiSessionSingleUserService.getEnforcedArray();
      this.defaultOffIds = this.configurableApplications
         .filter((ele) => {
            return !ele.selected;
         })
         .map((ele) => {
            return this.checkBoxIdProfix + ele.id;
         });
      //set to check if the apply button disable
      this.disableApplyBtn = true;
   }

   ngAfterViewInit() {
      if (!this.defaultOffIds) {
         return;
      }
      this.defaultOffIds.forEach((id) => {
         const ele = document.getElementById(id);
         ele.removeAttribute("checked");
      });
      this.defaultOffIds = null;
   }

   private applyChange = () => {
      this.disableApplyBtn = true;
      this.multiSessionSingleUserService.setAppStatus(this.configurableApplications);
   };

   public multiLaunchOkClicked = () => {
      this.applyChange();
      this.closeModal();
   };

   public multiLaunchOkKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         this.multiLaunchOkClicked();
      }
   };

   public multiLaunchCancelKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         this.closeModal();
      }
   };

   public multiLaunchApplyKeydown = (event) => {
      if (this.disableApplyBtn === false && !!event && event.keyCode === 13) {
         this.multiLaunchApplyClicked();
      }
   };

   public checkboxClicked = (application) => {
      this.disableApplyBtn = false;
      application.selected = !application.selected;
   };

   public multiLaunchApplyClicked = () => {
      this.applyChange();
   };

   public keyboardHandler = (evt, application) => {
      if (!evt) {
         return;
      }

      switch (evt.keyCode) {
         case 13: {
            // Enter key
            application.selected = !application.selected;
            console.log(evt.currentTarget, evt.currentTarget.children[1]);
            const ele = evt.currentTarget.children[1];
            if (application.selected) {
               $(ele).attr("checked", "checked");
            } else {
               $(ele).removeAttr("checked");
            }

            break;
         }
         default:
      }
   };
}
