/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";

@Injectable({
   providedIn: "root"
})
export class JumpCacheService {
   public static readonly STORAGEKEY = "JC_IE";

   constructor(private localStorageService: LocalStorageService) {}

   public jumpFor = (
      historyName: string,
      uri: string,
      callbackParam: { string: string[] | string },
      maxWaitTime?: number
   ): void => {
      Logger.info("Pushing extra history entry");
      if (maxWaitTime === undefined) {
         maxWaitTime = 10 * 60 * 1000; //10 mins
      }
      const historyLength = history.length;
      history.pushState({ historyName: historyName }, "", window.location.href);
      // use history.length since due to that unlike normal static pages, the HTML Access
      // would clear the state when jumping back.
      this.localStorageService.set(
         JumpCacheService.STORAGEKEY,
         JSON.stringify({
            time: new Date().getTime(),
            callbackParam: callbackParam,
            historyName: historyName,
            maxWaitTime: maxWaitTime,
            historyLength: historyLength
         })
      );
      window.location.href = uri;
   };

   public removeCache = (): void => {
      this.localStorageService.remove(JumpCacheService.STORAGEKEY);
   };

   public readCachedDataFor = (targetName: string) => {
      return new Promise((resolve, reject) => {
         const currentHistoryLength = history.length;
         let data;
         try {
            data = JSON.parse(this.localStorageService.get(JumpCacheService.STORAGEKEY));
         } catch (e) {
            Logger.warning("no valid cache found for page jumping");
            reject();
            return;
         }
         if (!data) {
            Logger.debug("no cache found for page jumping");
            reject();
            return;
         }
         this.removeCache();
         // for other browsers like Chrome, if this is used in the future, +1 and +2 must be added also
         if (
            data.historyLength + 3 !== currentHistoryLength &&
            !(history.state && history.state.historyName === targetName)
         ) {
            Logger.error("mismatched history length: " + data.historyLength + ", " + currentHistoryLength);
            reject();
            return;
         }
         // skip checking with the history name in the history state
         if (data.historyName !== targetName) {
            Logger.error("mismatched history name: " + data.historyName + ", " + targetName);
            reject();
            return;
         }
         const waitTime = new Date().getTime() - data.time;
         const maxWaitTime = data.maxWaitTime;
         if (waitTime > maxWaitTime) {
            Logger.info("waiting for too long for cached jump" + targetName + ": " + waitTime + "," + maxWaitTime);
            reject();
            return;
         }

         Logger.info("found cache for " + targetName + JSON.stringify(data.callbackParam));
         resolve(data.callbackParam);
      });
   };
}
