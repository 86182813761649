/**
 * ******************************************************
 * Copyright (C) 2012-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * jscdkClientController.js --
 *
 * JSCDKClientController is the centralized request handling class,
 * that delegates requests to appropriate command classes.
 *
 * Singleton Class
 *
 */

import FrontController from "./frontController";
import SetViewURICommand from "../commands/setViewURICommand";
import ConnectToBrokerCommand from "../commands/connectToBrokerCommand";
import SubmitAuthInfoCommand from "../commands/submitAuthInfoCommand";
import ConnectToDesktopCommand from "../commands/connectToDesktopCommand";
import CancelAuthenticationCommand from "../commands/cancelAuthenticationCommand";
import LogoutBrokerCommand from "../commands/logoutBrokerCommand";
import ResetDesktopCommand from "../commands/resetDesktopCommand";
import RestartDesktopCommand from "../commands/restartDesktopCommand";
import KillSessionCommand from "../commands/killSessionCommand";
import RollbackDesktopCommand from "../commands/rollbackDesktopCommand";
import GetDesktopsCommand from "../commands/getDesktopsCommand";
import GetLaunchItemsCommand from "../commands/getLaunchItemsCommand";
import CancelCurrentRequestCommand from "../commands/cancelCurrentRequestCommand";
import HandleURICommand from "../commands/handleURICommand";
import GetTunnelConnectionCommand from "../commands/getTunnelConnectionCommand";
import ConnectToApplicationCommand from "../commands/connectToApplicationCommand";
import ReconnectApplicationSessionsCommand from "../commands/reconnectApplicationSessionsCommand";
import DisconnectSessionCommand from "../commands/disconnectSessionCommand";
import DoLockCommand from "../commands/doLockCommand";
import GetAuthenticationStatusCommand from "../commands/getAuthenticationStatusCommand";
import DoUnlockCommand from "../commands/doUnlockCommand";
import SetLastUserActivityCommand from "../commands/setLastUserActivityCommand";
import TimerCtrlCommand from "../commands/timerCtrlCommand";
import InitRouterCommand from "../commands/initRouterCommand";
import SetUserGlobalPrefCommand from "../commands/setUserGlobalPrefCommand";
import GetUserGlobalPrefCommand from "../commands/getUserGlobalPrefCommand";
import SetEnvironmentInfoCommand from "../commands/setEnvironmentInfoCommand";
import GetOnRampConfigCommand from "../commands/getOnRampConfigCommand";

const JSCDKClientController = (function () {
   /**
    * AuthenticationController derives from FrontController
    *
    * @returns {AuthenticationController}
    */
   function JSCDKFrontController() {
      this.addCommand("SetViewURI", SetViewURICommand);
      this.addCommand("ConnectToBroker", ConnectToBrokerCommand);
      this.addCommand("SubmitAuthInfo", SubmitAuthInfoCommand);
      this.addCommand("ConnectToDesktop", ConnectToDesktopCommand);
      this.addCommand("ConnectToApplication", ConnectToApplicationCommand);
      this.addCommand("ReconnectSession", ReconnectApplicationSessionsCommand);
      this.addCommand("CancelAuthentication", CancelAuthenticationCommand);
      this.addCommand("LogoutFromBroker", LogoutBrokerCommand);
      this.addCommand("ResetDesktop", ResetDesktopCommand);
      this.addCommand("RestartDesktop", RestartDesktopCommand);
      this.addCommand("KillSession", KillSessionCommand);
      this.addCommand("RollbackDesktop", RollbackDesktopCommand);
      this.addCommand("GetDesktops", GetDesktopsCommand);
      this.addCommand("GetLaunchItems", GetLaunchItemsCommand);
      this.addCommand("CancelCurrentRequest", CancelCurrentRequestCommand);
      this.addCommand("HandleURI", HandleURICommand);
      this.addCommand("GetTunnelConnection", GetTunnelConnectionCommand);
      this.addCommand("DisconnectSession", DisconnectSessionCommand);
      this.addCommand("DoLock", DoLockCommand);
      this.addCommand("GetAuthenticationStatus", GetAuthenticationStatusCommand);
      this.addCommand("SetLastUserActivity", SetLastUserActivityCommand);
      this.addCommand("DoUnlock", DoUnlockCommand);
      this.addCommand("TimerCtrl", TimerCtrlCommand);
      this.addCommand("InitRouter", InitRouterCommand);
      this.addCommand("SetUserGlobalPref", SetUserGlobalPrefCommand);
      this.addCommand("GetUserGlobalPref", GetUserGlobalPrefCommand);
      this.addCommand("SetEnvironmentInfo", SetEnvironmentInfoCommand);
      this.addCommand("GetOnRampConfig", GetOnRampConfigCommand);
   }

   JSCDKFrontController.prototype = new FrontController();
   JSCDKFrontController.constructor = JSCDKFrontController;

   /**
    * single instance of authenticate controller
    */
   let _instance;

   const JSCDKClientController_inner = {
      /**
       * @returns  A singleton instance of {AuthenticationController}
       */
      getInstance: function () {
         if (!_instance) {
            _instance = new JSCDKFrontController();
         }
         return _instance;
      },
      init: function () {
         console.log("Assigning values to parameters during project initialisation");
      }
   };
   window["JSCDKClientController"] = JSCDKClientController_inner;
   return JSCDKClientController_inner;
})();
export default JSCDKClientController;
