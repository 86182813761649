/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injector } from "@angular/core";
import { BusEvent, EventBusService } from "../../../core/services/event";
import { Modal, ModalDialogService } from "../../common/commondialog/dialog.service";
import { LAUNCH_CLIENT_TYPE, TranslateService } from "@html-core";
import { FileTransferUtilService } from "../filetransfer/file-transfer-util.service";
import { XMLPreference } from "../../common/service/prefdata";

export enum DESKTOP_OPS {
   RESET,
   RESTART,
   LOGOFF,
   CLOSE
}

export enum LAUNCH_TYPE {
   DESKTOP = "Desktop",
   APPLICATION = "Application"
}

export abstract class XmlApiService {
   public abstract handleResetDesktop(item: any): void;
   public abstract handleRestartDesktop(item: any): void;
   public abstract handleLogoffDesktop(item: any): void;
   public abstract handleCloseDesktop(item: any): void;
   public abstract getUserGlobalPref(): Promise<XMLPreference>;
   public abstract checkAuthStatus(): Promise<void>;
   public abstract launchItem(item: EntitlementItem, type: LAUNCH_TYPE): Promise<any>;
   public abstract launchNativeItem(item: EntitlementItem, type: LAUNCH_CLIENT_TYPE): void;
   public abstract init(): Promise<void>;
   public abstract reconnectApp(sessionKey: string): Promise<any>;
   public abstract logout(): Promise<void>;
   public abstract getFavorites(): Array<string>;
   public abstract getLaunchMethodForItem(item: EntitlementItem): LAUNCH_CLIENT_TYPE;
   public abstract hasApplicationSession(): Promise<any>;
   public abstract resetAllApplications(callback: Function, single: boolean);

   private fileTransferUtilService: FileTransferUtilService;
   public translate: TranslateService;
   public modalDialogService: ModalDialogService;
   public eventBuService: EventBusService;
   constructor(injector: Injector) {
      this.fileTransferUtilService = injector.get(FileTransferUtilService);
      this.translate = injector.get(TranslateService);
      this.modalDialogService = injector.get(ModalDialogService);
      this.eventBuService = injector.get(EventBusService);
   }

   /**
    * handleSessionOps
    *
    * Helper function to bring up a confirmation dialog before a session
    * operation is performed.
    *
    * @param title the title for the confirmation dialog
    * @param message the message body for the dialog
    * @param callback the callback to invoke if the user clicks on OK.
    */
   public handleSessionOps = (ops: DESKTOP_OPS, item: any) => {
      const opt: Modal.dialogData = this._generateModalOption(ops, item);
      this.modalDialogService.showConfirm({
         data: opt,
         callbacks: {
            confirm: () => {
               this.eventBuService.dispatch(new BusEvent.QuitMultiMonMsg());
               this._sessionOpsCallback(ops, item);
            }
         }
      });
   };

   private _generateModalOption = (ops: DESKTOP_OPS, item: any): Modal.dialogData => {
      const opt: Modal.dialogData = {} as Modal.dialogData;
      opt.showWarning = true;
      opt.isTransferringFile = this.fileTransferUtilService.isFileTransferringInSession(item.wmksKey);

      if (ops === DESKTOP_OPS.RESET) {
         opt.title = this.translate._T("RESET_DESKTOP_T");
         opt.content = this.translate._T("RESET_DESKTOP_M", item.name);
      } else if (ops === DESKTOP_OPS.RESTART) {
         opt.title = this.translate._T("RESTART_DESKTOP_T");
         opt.content = this.translate._T("RESTART_DESKTOP_M", item.name);
      } else if (ops === DESKTOP_OPS.LOGOFF) {
         opt.title = this.translate._T("LOGOFF_DESKTOP_T");
         opt.content = this.translate._T("LOGOFF_DESKTOP_M", item.name);
      } else if (ops === DESKTOP_OPS.CLOSE) {
         opt.title = this.translate._T("CLOSE_DESKTOP_T");
         opt.content = this.translate._T("CLOSE_DESKTOP_M", item.name);
      }
      return opt;
   };

   private _sessionOpsCallback = (ops: DESKTOP_OPS, item: any) => {
      if (ops === DESKTOP_OPS.RESET) {
         this.handleResetDesktop(item);
      } else if (ops === DESKTOP_OPS.RESTART) {
         this.handleRestartDesktop(item);
      } else if (ops === DESKTOP_OPS.LOGOFF) {
         this.handleLogoffDesktop(item);
      } else if (ops == DESKTOP_OPS.CLOSE) {
         this.handleCloseDesktop(item);
      }
   };
}
