/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { BusEvent, clientUtil, EventBusService } from "@html-core";
import {
   SessionMsg,
   RemoteSessionEventService
} from "../../../shared/common/remote-session/remote-session-event.service";
import { IdleSessionService } from "../../../shared/common/service/idle-session.service";
import { MultiMonitorChromeService } from "../../../shared/desktop/common/multi-monitor-chrome.service";
import { MultimonService } from "../../../shared/desktop/multimon/main-page/multimon.service";
import { CoordinateConverter } from "../../../shared/utils/coordinate-converter";
import { SessionSettingService } from "./session-setting.service";

/*
 *
 * Keep this service injected with the provider in the Common Module rather than with
 * the @Injectable() decorator.
 *
 * It is used in the shared codes with the @Optional() decorator.
 */
@Injectable()
export class SessionLifeCycleService {
   private _blastSession: any;
   constructor(
      private remoteSessionEventService: RemoteSessionEventService,
      private idleSessionService: IdleSessionService,
      private multiMonitorChromeService: MultiMonitorChromeService,
      private multimonService: MultimonService,
      private coordinateConverter: CoordinateConverter,
      private sessionSettingService: SessionSettingService,
      protected eventBusService: EventBusService
   ) {
      this.eventBusService
         .listen(BusEvent.DPIDataSyncSuccessEvent.MSG_TYPE)
         .subscribe((msg: BusEvent.DPIDataSyncSuccessEvent) => {
            this.updateWMKSDPI(msg.wmksKey, msg.remoteScaleDPI);
         });
   }

   public updateWMKSDPI = (key: string, dpi: number) => {
      if (key !== this._blastSession.key) {
         return;
      }
      this._blastSession.updateDPIInfo(dpi);
   };

   public addEventListener = (key, callback) => {
      this.remoteSessionEventService.addEventListener(key, callback);
   };

   public init = (blastSession, blastSessionController) => {
      this._blastSession = blastSession;

      this.multiMonitorChromeService.setWmksService(blastSessionController);

      blastSessionController.sessionOnConnecting = (wmksSession) => {
         this.remoteSessionEventService.emit(SessionMsg.SESSION_CONNECTING_MSG, wmksSession);
      };

      blastSessionController.checkSessionInMultimon = (key: string, callback: Function) => {
         this.multimonService.checkSessionInMultimon(key, callback);
      };

      blastSessionController.frameBufferInited = (agentDPI) => {
         Logger.info("frame buffer inited with DPI" + agentDPI);
         this.multiMonitorChromeService.prepareDisplaysForMultimon(this._blastSession.key, agentDPI);
      };

      blastSessionController.sessionOnConnected = (wmksSession) => {
         this.remoteSessionEventService.emit(SessionMsg.SESSION_CONNECTED_MSG, wmksSession);
         this._blastSession.setVisibility(true);
      };

      blastSessionController.sessionOnDisconnected = (wmksSession, code) => {
         this.remoteSessionEventService.emit(SessionMsg.SESSION_DISCONNECTED_MSG, wmksSession);
      };

      blastSessionController.sessionOnRemoved = (wmksSession) => {
         this.remoteSessionEventService.emit(
            SessionMsg.SESSION_REMOVED_MSG,
            wmksSession.key,
            wmksSession.isApplicationSession
         );
      };

      blastSessionController.updateReconnectToken = (wmksSession, reconnectToken) => {
         this.remoteSessionEventService.emit(
            SessionMsg.SESSION_TOKEN_UPDATED_MSG,
            wmksSession.key,
            wmksSession.isApplicationSession,
            reconnectToken
         );
      };

      blastSessionController.setCanvasVisibility = (wmksKey, isShow) => {
         this._blastSession.setCanvasVisibility(isShow);
      };

      blastSessionController.quitMultimon = () => {
         this.multimonService.cancelForMultimon();
         this.multimonService.switchToSingleMon(this._blastSession);
      };

      blastSessionController.addMonitor = (uiCallback, screenSetting) => {
         const currentSession = this._blastSession;
         const options = {
            // enableH264: isMP4Supported() && isMP4Enabled
            enableH264: false
         };

         const closeSidebar = function () {};
         this.multimonService.addMonitor(
            currentSession,
            currentSession.wmksContainer[0],
            uiCallback,
            closeSidebar,
            handleResize,
            options,
            screenSetting
         );
         $("#canvas-container").css("overflow", "visible");
      };

      blastSessionController.onExtendConnect = (port) => {
         if (port.name.indexOf("REMOTEAPP-MULTIMON-" + this._blastSession.key) === 0) {
            const id = port.name.split("__CMS_ON__")[1];
            this.multimonService.setPortForExtendMonitor(id, port);
         }
      };

      blastSessionController.onResolutionUpdated = (session, agentPixel) => {
         if (session !== this._blastSession) {
            Logger.error("inner error, the blast session mismatches");
         }
         // Used to bypass issues
         const targetPixel = clientUtil.getWindowPixels();
         const screenSize = clientUtil.getWindowResolution();
         Logger.info(
            "Agent Resolution: " + JSON.stringify(agentPixel) + "\nexpected resolution: " + JSON.stringify(targetPixel)
         );

         // Compatible with Agents that need resolution adjustment after connection
         if (Math.abs(targetPixel[0] - agentPixel.width) <= 1 || Math.abs(targetPixel[1] - agentPixel.height) <= 1) {
            Logger.info("resolution inited successfully");
            session.updateResolution(screenSize, false);
         } else {
            Logger.info("set resolution again, since resolution initialize failed");
            session.updateResolution(screenSize, true);
         }
      };
      blastSessionController.setLastUserActivityTime = () => {
         //don't need to implement this, since we are using chrome API for idle detection.
      };
      blastSessionController.updateTriedSSLVerify = async () => {
         // on of condition to show shelf icon since we should not show it when accepting cert
         return null;
      };
      blastSessionController.isSessionTimedOut = () => {
         return this.idleSessionService.isSessionTimedOut();
      };

      blastSessionController.getCurrentSession = () => {
         return this._blastSession;
      };

      blastSessionController.isCurrentSessionPerMonitorDPI = () => {
         return this._blastSession.isDisplayScaleDisabled();
      };

      blastSessionController.disconnectEventConnections = () => {
         this._blastSession.wmks("disconnectEvents");
         /**
          * Adding some extra lines since wmks is not symatrix so we can't remove
          * all the events by calling disconnectEvents, which seems can't be done
          * easily.
          */
         $(window).unbind("mousedown.wmks");
         $(window).unbind("mousewheel.wmks");
         $(window).unbind("mousemove.wmks");
         $(window).unbind("mouseup.wmks");
      };

      /**
       * handleResize
       *
       * We need to resize the remote desktop for onSize.
       */
      const handleResize = (e) => {
         const screenSize = clientUtil.getWindowResolution();
         if (this._blastSession !== this.multimonService.getMultimonSession()) {
            this._blastSession.updateResolution(screenSize, true);
         } else {
            Logger.debug("skip resize for session wmksKey: " + this._blastSession.key);
         }
         if (this._blastSession.isApplicationSession) {
            this.coordinateConverter.updateClientScreenInfo();
         }
      };

      blastSessionController.hasMultimonCapacity = () => {
         const currentSession = this._blastSession;
         if (!currentSession) {
            return false;
         }

         return currentSession.multimonServerEnabled;
      };

      blastSessionController.enterMultimon = () => {
         $(window).off("resize", handleResize);
         const currentSession = this._blastSession;
         if (currentSession) {
            Logger.info("enter multimon for session " + currentSession.key);
            blastSessionController.changeMonitorStatus(true);
            this.multimonService.enterMultimonMode();
         }
      };

      blastSessionController.changeMonitorStatus = (status) => {
         this.sessionSettingService.clientStatus.isInMultimon = status;
      };

      blastSessionController.recoveryEventConnections = () => {
         try {
            this._blastSession.wmks("connectEvents");
         } catch (e) {
            Logger.debug("fail to reconnect wmks events, please check the network");
         }
      };

      blastSessionController.preparingForMultimon = () => {
         Logger.info("preparing for entering multimon");
         this.multimonService.preparingForMultimon();
      };

      //one can add callback here if needed later.
      blastSessionController.showError = (wmksSession, title, message) => {
         this.remoteSessionEventService.emit(
            SessionMsg.SESSION_MODAL_MSG,
            wmksSession.key,
            wmksSession.isApplicationSession,
            {
               type: "Error",
               data: {
                  title: title,
                  errorMessage: message
               }
            }
         );
      };
   };

   public getCurrentSession = () => {
      return this._blastSession;
   };
}
