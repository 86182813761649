/**
 * *****************************************************
 * Copyright 2016-2020 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import $ from "jquery";
import Logger from "../../../core/libs/logger";
import { MKSVCHAN_CONST } from "../../../shared/desktop/channels/MKSVchan/mksvchan-consts";
import { MKSVchan } from "../../../shared/desktop/channels/mksvchan";
import { Component, ElementRef, AfterViewInit, ViewChild } from "@angular/core";
import { HtmlRemoteSessionManager } from "../../common/remote-session/html-remote-session-manager";
import { PanelService } from "../../../shared/desktop/common/panel.service";
import { MksvchanService } from "../../../shared/desktop/channels/mksvchan.service";
import { EventBusService, TranslateService } from "@html-core";
import { AB } from "../../../shared/desktop/common/appblast-util.service";
import { ViewClientModel } from "../../../shared/common/model/viewclient-model";
import { panelNotification } from "./panel-notification.component";
import { ModalDialogService } from "../../../shared/common/commondialog/dialog.service";

@Component({
   selector: "clipboard",
   templateUrl: "./clipboard.component.html"
})
export class ClipboardComponent implements AfterViewInit {
   @ViewChild(panelNotification, { static: true }) panel: panelNotification;
   private defaultText: string;
   private _globalClipboard = new MKSVchan.Clipboard();
   private savedClipboardHeight: number;
   private element: JQuery;

   public focused: boolean;
   public helpDialogOpened: boolean;
   public showClipboardPanel: boolean;
   public showClipboardText: boolean;
   public hasClipboardData: boolean;
   public clipboardReady: boolean;
   public copyEnabled: boolean;
   public pasteEnabled: boolean;
   public isMacOS: boolean;
   public windowResizeHandler: any;
   public clipboard = new Map<string, string>();
   public clipboardPolicy = new Map<string, string>();
   constructor(
      private translate: TranslateService,
      private viewClientModel: ViewClientModel,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private panelService: PanelService,
      private mksvchanService: MksvchanService,
      private eventBusService: EventBusService,
      private _el: ElementRef,
      private modalDialogService: ModalDialogService
   ) {
      this.element = $(this._el.nativeElement.children[0]);
      this.defaultText = "";
      this._globalClipboard = new MKSVchan.Clipboard();
      this.clipboard.set("text", this.defaultText);
      this.clipboardPolicy.set("text", "");
      this.showClipboardPanel = false;
      this.showClipboardText = true;
      this.hasClipboardData = false;
      this.clipboardReady = false;
      this.copyEnabled = true;
      this.pasteEnabled = true;
      this.isMacOS = this.viewClientModel.mIsMacOS;

      // Listen to server clipboard updates and sync panel with remote
      // clipboard data
      this.mksvchanService.addEventListener("clipboardChanged", this._handleClipboardChange);

      this.mksvchanService.addEventListener("clipboardCapabilitiesChanged", this._handleCapabilitiesChanged);

      this.mksvchanService.addEventListener("wmkscopy", (id) => {
         /**
          * handles the notification from the remote guest that clipboard has
          * changed, sends a request RPC for clipboard contents.
          */
         const session = this.htmlRemoteSessionManager.getSessionById(id);
         if (!session) {
            this.panel.showNotification("CLIPBOARD_FAILED_M");
            return;
         }
         const client = this.mksvchanService.getClient(session.key);
         if (!client) {
            this.panel.showNotification("CLIPBOARD_FAILED_M");
            return;
         }
         client.sendClipboardRequest(
            () => {
               // TODO
               console.info("Clipboard request successfully sent");
            },
            () => {
               this.panel.showNotification("CLIPBOARD_FAILED_M");
            }
         );
      });

      this.mksvchanService.addEventListener("clipboardReady", (id: string) => {
         /**
          * Called by a wmks session when its clipboard is ready. If the
          * session is the current session, sync the remote clipboard with the
          * local clipboard
          */
         const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
         if (!!currentSession && id === currentSession.key) {
            const mksVchanClient = this.mksvchanService.getClient(id);
            this._handleCapabilitiesChanged(
               id,
               mksVchanClient.ready,
               mksVchanClient.copyEnabled,
               mksVchanClient.pasteEnabled
            );
            this.pushClipboard();
         }
      });

      this.mksvchanService.addEventListener("clipboardPush", this.pushClipboard);

      this.eventBusService.listen("toggleClipboardPanel").subscribe(() => {
         this.toggleClipboardPanel();
      });
   }

   ngAfterViewInit() {
      this.element = $(this._el.nativeElement.children[0]);
      this.panelService.addPanelElement("clipboard", this.element);

      this.element
         .resizable({
            containment: "document"
         })
         .draggable({
            containment: "window",
            handle: ".clipboard-handle",
            start: (event, ui) => {
               this.element.css("z-index", this.panelService.getNewZIndex());
            }
         });

      this.windowResizeHandler = this.panelService.windowResizeHandler(this, "showClipboardPanel", this.element);

      window.addEventListener("resize", this.windowResizeHandler);
      this.element.bind("resize", this.resizeClipboardContents);
      this.resizeClipboardContents();
   }

   ngOnDestroy() {
      this.element.unbind("resize", this.resizeClipboardContents);
      window.removeEventListener("resize", this.windowResizeHandler);
   }

   // resizes the clipboard contents to fit within the clipboard
   // panel
   public resizeClipboardContents = () => {
      if (this.showClipboardText) {
         /**
          * clipboard contents sit under the label and fills the
          * remaining panel space. Its height is panel height minus
          * label height.
          *
          * 39 is the header height and 19 is the footer height.
          */
         const newHeight = this.element.outerHeight() - 36 - 19;
         $(".clipboard-content").css("height", newHeight);
      }
   };

   /*
    * minimizes/restores the clipboard panel. Needs to remember the panel
    * height on minimize and then set the height on restore.
    */
   public toggleClipboardText = () => {
      if (this.showClipboardText) {
         this.savedClipboardHeight = this.element.outerHeight();
         this.element.addClass("minimized");
      } else {
         this.element.css("height", this.savedClipboardHeight);
         this.element.removeClass("minimized");
         this.resizeClipboardContents();
      }
      this.showClipboardText = !this.showClipboardText;

      if (this.showClipboardText) {
         setTimeout(() => {
            $("#minimize").focus();
         });
      } else {
         setTimeout(() => {
            $("#restore").focus();
         });
      }
   };

   private setDefaultPolicyText = () => {
      let policyText = "";
      if (!this.clipboardReady) {
         policyText = this.translate._T("WARNING_COPY_PASTE_UNAVAILABLE_CONTENT");
      } else if (this.clipboardReady && this.copyEnabled && this.pasteEnabled) {
         policyText = this.translate._T("WARNING_COPY_PASTE_ENABLED_CONTENT");
      } else if (this.clipboardReady && this.copyEnabled && !this.pasteEnabled) {
         policyText = this.translate._T("WARNING_PASTE_DISABLED_CONTENT");
      } else if (this.clipboardReady && !this.copyEnabled && this.pasteEnabled) {
         policyText = this.translate._T("WARNING_COPY_DISABLED_CONTENT");
      } else if (this.clipboardReady && !this.copyEnabled && !this.pasteEnabled) {
         policyText = this.translate._T("WARNING_COPY_PASTE_DISABLED_CONTENT");
      }
      this.clipboardPolicy.set("text", policyText);
      $("#clipboard").attr("title", policyText);
   };

   // Redirects input to the clipboard's hidden input
   public focusInput = () => {
      const clipboardInput = $(document.getElementById("clipboardInput"));
      if (clipboardInput) {
         setTimeout(() => {
            clipboardInput.select();
         });
      }
   };

   // Clears clipboard contents
   public clipboardClear = () => {
      this.clipboard.set("text", this.defaultText);
      this._globalClipboard.clear();
      this.panel.showNotification("CLIPBOARD_CLEARED_M");
      this.hasClipboardData = false;
   };

   /*
    * Intercepts a copy event and replaces the copied text with the current
    * clipboard contents.
    */
   public clipboardCopy = (e: ClipboardEvent) => {
      Logger.info("Copy operation is captured.", Logger.CLIPBOARD);
      if (!this.hasClipboardData) {
         Logger.info("There is no data in the clipboard.", Logger.CLIPBOARD);
         return;
      }

      AB.setClipboardText(e, this.clipboard.get("text"));

      this.panel.showNotification("CLIPBOARD_COPIED_M");
      this.focusInput();
      e.preventDefault();
   };

   /*
    * Intercept a paste event to get paste data, then update clipboard and send
    * the appropriate call to update the server clipboard.
    */
   public clipboardPaste = (e: ClipboardEvent) => {
      Logger.info("Paste operation is captured and data is sent to remote server.", Logger.CLIPBOARD);
      const text = AB.getClipboardText(e);
      this.sendClipboardText(text);

      this.focusInput();
      e.preventDefault();
   };

   // Handle clipboard panel focus event
   public clipboardFocus = () => {
      if (this.showClipboardPanel && !this.helpDialogOpened) {
         this.focused = true;
         this.panelService.onFocus("clipboard");
         this.listenCopyEventForIE();
      }
   };

   public listenCopyEventForIE = () => {
      // This is for bug 2680770
      let ctrlPressed: boolean = false,
         cPressed: boolean = false;
      if (this.viewClientModel.mIsIE) {
         $("#clipboardInput").on("keydown", (e) => {
            if (e.key === "Control") {
               ctrlPressed = true;
            } else if (e.key === "c" || e.key === "C") {
               cPressed = true;
            }
            if (ctrlPressed && cPressed) {
               document.execCommand("copy");
            }
         });

         $("#clipboardInput").on("keyup", (e) => {
            if (e.key === "Control") {
               ctrlPressed = false;
            } else if (e.key === "c" || e.key === "C") {
               cPressed = false;
            }
         });
      }
   };

   // Handle clipboard panel blur event
   public clipboardBlur = () => {
      this.focused = false;
   };

   // shows/hides the clipboard panel
   public toggleClipboardPanel = ($event?: MouseEvent) => {
      this.showClipboardPanel = !this.showClipboardPanel;

      if ($event) {
         $event.stopPropagation();
         $event.preventDefault();
      }

      if (this.showClipboardPanel) {
         setTimeout(() => {
            this.windowResizeHandler();
            this.resizeClipboardContents();
            this.focusInput();
         });
      }
   };

   public openClipboardHelp = () => {
      const modKey = this.isMacOS ? this.translate._T("CMD_KEY") : this.translate._T("CTRL_KEY");

      this.modalDialogService.showClipFTHelp({
         data: {
            title: "CLIPBOARD_HELP_DIALOG_T",
            msg: "CLIPBOARD_HELP_DIALOG_M",
            modKey: modKey
         },
         callback: () => {
            this.helpDialogOpened = false;
         }
      });
      this.helpDialogOpened = true;
   };

   private _handleCapabilitiesChanged = (
      id: string,
      clipboardReady: boolean,
      copyEnabled: boolean,
      pasteEnabled: boolean
   ) => {
      /**
       * Notifies to update its clipboard capabilities. Note
       * that it always updates with the capabilities of currently active
       * session. If this session is not currently active
       * then the call is redundant, but harmless.
       */
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!!currentSession && id === currentSession.key) {
         setTimeout(() => {
            this.clipboardReady = clipboardReady;
            this.copyEnabled = copyEnabled;
            this.pasteEnabled = pasteEnabled;
            this.setDefaultPolicyText();
         });
      }
   };

   private _handleClipboardChange = (id: string, clipboard: MKSVchan.Clipboard, error: number) => {
      this._globalClipboard = clipboard;

      const data = new Map<string, any>();
      data.set("text", this._globalClipboard.getText(MKSVCHAN_CONST.CP_FORMAT.TEXT));

      /*
       * File transfer and clipboard share the same error code here
       * Can't detect whether to show notification on ft or clipboard panel
       * So trigger both of them, can't use dialog, or it will effect normal
       * user experience in the desktop.
       */
      if (error === MKSVCHAN_CONST.CLIPBOARD_ERROR.DISALLOWED_BY_AUDIT) {
         this.panel.showNotification("ERROR_MSG_DISALLOWED_BY_AUDIT");
         this.mksvchanService.emit("fileTransferBlockedByAudit");
         return;
      }

      if (data.get("text") != null) {
         this._handleClipboardDataChange(data, error);
      }
   };

   private _handleClipboardDataChange = (data: Map<string, any>, error: number) => {
      setTimeout(() => {
         Logger.info("Clipboard data in the remote server is synced to client.", Logger.CLIPBOARD);
         if (data === null) {
            this.panel.showNotification("CLIPBOARD_FAILED_M");
            return;
         }

         this.clipboard.set("text", data.get("text"));
         this.hasClipboardData = true;

         if (error === MKSVCHAN_CONST.CLIPBOARD_ERROR.MAX_LIMIT_EXCEEDED) {
            this.panel.showNotification("CLIPBOARD_TRUNCATED_M");
         } else {
            this.panel.showNotification("CLIPBOARD_SYNCED_M");
         }
      }, 1000);
   };

   /**
    * Pushes the local clipboard to the remote guest.
    */
   public pushClipboard = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession) {
         this.panel.showNotification("CLIPBOARD_FAILED_M");
         return;
      }
      const client = this.mksvchanService.getClient(currentSession.key);
      if (!client) {
         this.panel.showNotification("CLIPBOARD_FAILED_M");
         return;
      }
      client.sendClipboard(
         this._globalClipboard,
         () => {
            Logger.debug("Clipboard synced with focused session", Logger.CLIPBOARD);
         },
         () => {
            this.panel.showNotification("CLIPBOARD_FAILED_M");
         }
      );
   };

   /**
    * Sends a local clipboard text change to the remote guest.
    */
   public sendClipboardText = (text: string) => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession) {
         return;
      }

      const mksVchanClient = this.mksvchanService.getClient(currentSession.key);
      if (!mksVchanClient || !mksVchanClient.pasteEnabled) {
         return;
      }

      mksVchanClient.sendClipboardText(
         text,
         (text: string, error: number) => {
            const data = new Map<string, any>();
            data.set("text", text);
            Logger.debug("Clipboard update successfully sent", Logger.CLIPBOARD);
            this._globalClipboard.setText(text, MKSVCHAN_CONST.CP_FORMAT.TEXT);
            this._handleClipboardDataChange(data, error);
         },
         () => {
            this.panel.showNotification("CLIPBOARD_FAILED_M");
         }
      );
   };
}
