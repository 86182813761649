/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { RebrandImage } from "../../../shared/common/service/rebrand-image.service";
import { imageAsset } from "../../../shared/common/image-asset/image-asset";

@Injectable({
   providedIn: "root"
})
export class HorizonRebrandImageService extends RebrandImage {
   private imageAsset = imageAsset;
   constructor() {
      super();
   }

   public getHTMLAccessLogo(): string {
      return this.imageAsset.horizonLogo;
   }
}
