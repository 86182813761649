<form [formGroup]="securIDPinChangeForm" id="securIDPinChangeForm" name="securIDPinChangeForm" (ngSubmit)="onSubmit($event)">
   <div class="ui-panel-content">
      <div [hidden]="blankOutContent">
         <div *ngIf="error" class="alert alert-danger">
            {{error}}
         </div>
         <h3 *ngIf="!!prompt" class="ui-multi-line ui-text-left-align pin-change-prompt"><small>{{prompt}}</small></h3>

         <div class="form-horizontal">
            <label for="pin1">{{'dialog_placeholder_pin1' | i18nv2}}</label>
            <input id="pin1" name="pin1" type="{{pin1Type}}" class="form-control"
               placeholder="{{'dialog_placeholder_pin1' | i18nv2}}" [readonly]="pin1ReadOnly"
               [attr.disabled]="pin1ReadOnly ? '' : null" [formControl]="securIDPin1Control"
               [autoFocus]="!pin1ReadOnly">
            <button *ngIf="securIDPin1Control.value" class="ui-clear-btn ui-clear-pin1-btn" id="clearPin1Btn"
               [hidden]="pin1ReadOnly" (click)="clearPin1()" tabindex="-1">{{'clear_pin_code' | i18nv2}}</button>
            <div class="ui-text-left-align">
               <input id="pin2" name="pin2" type="text" (focus)="changeType('pin2')" class="form-control bottom-input"
                  placeholder="{{'dialog_placeholder_pin2' | i18nv2}}" [formControl]="securIDPin2Control"
                  [autoFocus]="!pin1ReadOnly">
               <button *ngIf="securIDPin1Control.value" class="ui-clear-btn ui-clear-pin2-btn" id="clearPin2Btn"
                  (click)="clearPin2()" tabindex="-1">{{'clear_pin_code' | i18nv2}}</button>
               <label for="pin2" class="ui-password-dismatch-text"
                  [hidden]="!securIDPinChangeForm.hasError('notSame') && (securIDPin2Control.touched||securIDPin2Control.dirty)">{{'validation_equals' | i18nv2}}</label>
            </div>
         </div>
      </div>
      <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
   </div>
   <div class="ui-panel-footer">
      <button id="loginButton" class="btn btn-primary" [ngClass]="{'btnPrimaryWaiting':blankOutContent}" type="submit"
         [disabled]="!securIDPinChangeForm.valid" [hidden]="blankOutContent">{{ 'dialog_btn_signin' | i18nv2}}</button>
      <button id="cancelLoginBtn" class="btn btn-default" [ngClass]="{'btnDefaultWaiting':blankOutContent}"
         (click)="cancel()">{{'CANCEL' | i18nv2}}</button>
   </div>
</form>