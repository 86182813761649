/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { ConnectionServerModel } from "../model/connection-server-model";
import { Injectable } from "@angular/core";
import { ImageObjectURL, RemoteImageService } from "./remote-image.service";
import { clientUtil, Logger } from "@html-core";

const INDEXED_DB_VERSION = 1;
@Injectable({
   providedIn: "root"
})
export class HorizonAppImageService extends RemoteImageService {
   private id: number = 0;
   constructor(private connectionServerModel: ConnectionServerModel) {
      super();
   }

   public requestRemoteImageAndLoad = (relativeImageUrl, element: HTMLElement): Promise<void> => {
      return new Promise((resolve, reject) => {
         if (!relativeImageUrl || !element) {
            // @ts-ignore
            resolve();
         }
         const imageUrl = "https://" + this.connectionServerModel.host + relativeImageUrl;
         const cacheIndex = this.searchForObjectURL(imageUrl);
         if (cacheIndex !== -1) {
            const obj: ImageObjectURL = this.objectURLs[cacheIndex];
            element.setAttribute("data-src", imageUrl);
            element.setAttribute("src", obj.objURL);
            this.logger.debug("cache hit " + imageUrl);
            resolve();
         } else {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", imageUrl);
            xhr.responseType = "blob";
            xhr.onload = () => {
               if (xhr.status !== 200) {
                  reject();
                  return;
               }
               const objURL = this.createObjectURL(imageUrl, xhr.response);
               element.setAttribute("data-src", imageUrl);
               element.setAttribute("src", objURL);
               if (clientUtil.isChromeClient()) {
                  clientUtil.blobToBase64(xhr.response).then((iconBase64Data) => {
                     this._updateImageToDb(element, iconBase64Data);
                  });
               }
               // @ts-ignore
               resolve();
            };
            xhr.onerror = () => {
               reject();
            };
            xhr.send();
         }
      });
   };

   private _updateImageToDb = (element, iconData) => {
      if (clientUtil.isChromeClient()) {
         //@ts-ignore
         const parentNodeId = element.parentNode.id;
         const key = parentNodeId.replace("launch-", this.connectionServerModel.host + "::");
         const appId = parentNodeId.replace("launch-", "");
         this._storeImageIntoIndexDB(key, appId, iconData);
      }
   };

   private _storeImageIntoIndexDB = (key, appId, imageBlob) => {
      //@ts-ignore
      const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
      if (indexedDB) {
         const request = indexedDB.open("HorizonFileAssociationImage");

         request.onerror = (event) => {
            //@ts-ignore
            Logger.error("Database error: " + event.target.errorCode);
         };
         request.onsuccess = (event) => {
            Logger.debug("Success to open indexDB.");
            const db = request.result;
            if (db) {
               if (db.objectStoreNames.contains("app-images")) {
                  const tx = db.transaction("app-images", "readwrite");
                  const store = tx.objectStore("app-images");
                  const index = store.index("key");
                  const readData = index.get(key);
                  readData.onsuccess = (event) => {
                     const result = event.target.result;
                     if (result) {
                        Logger.debug("Read image succeed. appID: " + appId + ", result: " + result);
                        if (result.imageBlob !== imageBlob) {
                           //replace the new one
                           result.imageBlob = imageBlob;
                           const writeData = store.put(result);
                           writeData.onsuccess = () => {
                              Logger.debug("Update image succeed. Key: " + key + ", imageBlob: " + imageBlob);
                           };
                           writeData.onerror = () => {
                              Logger.debug("Update image failed. Key: " + key);
                           };
                        }
                     } else {
                        Logger.debug("Read image filed. appID: " + appId + "doesn't exist.");
                        const writeData = store.add({
                           key: key,
                           appId: appId,
                           imageBlob: imageBlob,
                           version: INDEXED_DB_VERSION,
                           id: this.id
                        });
                        writeData.onsuccess = () => {
                           Logger.debug("Write image succeed. Key: " + key);
                        };
                        writeData.onerror = () => {
                           Logger.debug("Write image failed. Key: " + key);
                        };
                     }
                  };
                  readData.onerror = (event) => {
                     const result = event.target.result.name;
                     Logger.debug("Read image error. appID: " + appId + ", result: " + result);
                  };
                  Logger.debug("Success to add an image indexDB.");
                  this.id++;
               }
            }
         };

         request.onupgradeneeded = (event) => {
            // The database did not previously exist, so create object stores and indexes.
            Logger.debug("indexedDb onupgradeneeded is triggered");
            const db = request.result;
            if (db && !db.objectStoreNames.contains("app-images")) {
               const store = db.createObjectStore("app-images", { keyPath: "id" });
               Logger.debug("Success to create indexDB for images.");
               store.createIndex("key", "key", { unique: true });
               store.put({ key: key, appId: appId, imageBlob: imageBlob, version: INDEXED_DB_VERSION, id: this.id });
               Logger.info("success to upgrade indexDB.");
            } else {
               Logger.info("upgrade fails when update icon to indexedDB.");
            }
         };
      }
   };
}
