/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { BusEvent } from "@html-core";
import {
   ConnectingBrokerInfo,
   IdleLockedInfo,
   ProtocolSessionDisconnectedInfo,
   ProtocolSessionInfo
} from "../../../chrome-client/SDK/sdk-service";
import { BlastWmks } from "../../desktop/common/blast-wmks.service";

export abstract class CommonSDKService {
   public init: () => Promise<CommonSDKService>;
   public isHideClientEnabled: () => boolean;
   public boundEvents: (workingVersion?: number) => void;
   public onConnectingBroker: (event: ConnectingBrokerInfo) => Promise<void> = () => Promise.resolve();
   public onConnectBrokerFailed: (event: BusEvent.ConnectBrokerFailed) => void = () => Promise.resolve();
   public onDisclaimerDisplayed = (event: BusEvent.DisclaimerDisplayed) => {};
   public onAuthenticationDeclined: (event: BusEvent.AuthenticationDeclined) => void;
   public onAuthenticationFailed: (event: BusEvent.AuthFailedMsg) => void;
   public onItemLaunchFailed: (event: BusEvent.ItemLaunchFailed) => void;
   public onItemLaunchSucceeded: (event: BusEvent.ItemLaunchSucceeded) => void;
   public onIdleLocked: (event: IdleLockedInfo) => void = () => {};
   public onNewProtocolSessionCreated: (event: ProtocolSessionInfo) => void;
   public onProtocolSessionDisconnected: (event: ProtocolSessionDisconnectedInfo) => void;
   public onProtocolSessionConnected = (session: BlastWmks) => {};
   public getAuthSecret: () => Promise<string>;
   public hasAuthSecret: () => boolean;
   public setAuthSecret: (authSecret: string) => void;
   public getDisclaimerAccepted = (): boolean => null;
   public hasDisclaimerAccepted = () => false;
   public setDisclaimerAccepted = (accepted: boolean) => {};
}
