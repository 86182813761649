/**
 * *****************************************************
 * Copyright 2020-2021 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";

@NgModule({})
export class CoreModule {}

export * from "./services/event/event-bus.service";
export * from "./services/storage/local-storage.service";
export * from "./libs";
export * from "./services/event";
export * from "../../../SDK/src/lib/model/enum";
export * from "./services/translate.service";
export * from "./services/local-log.service";
