/**
 * ******************************************************
 * Copyright (C) 2014-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * context-menu-manager.js --
 *
 * Module for handling context menus. The module makes sure that only one
 * context menu is opened at a time and that clicking outside of the context
 * menu closes it. The ContextMenuManager exposes two methods. toggleMenu and
 * closeMenu detailed below.
 */
import { Injectable, ApplicationRef } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class ContextMenuManager {
   openedMenuId: string;
   closeCallback: Function;

   constructor(private ref: ApplicationRef) {
      this.openedMenuId = null;
      this.closeCallback = null;
   }

   detectChange = () => {
      this.ref.tick();
   };

   closeMenu = () => {
      const mainCanvas = $("#canvas-container");
      if (this.openedMenuId) {
         this.closeCallback();
         $(document).off("click", this.closeMenu);
         mainCanvas.off("touchstart", this.closeMenu);
         this.openedMenuId = null;
         this.closeCallback = null;
      }
   };

   /**
    * Opens a menu with a given id. The exact behavior depends on whether
    * another menu is opened and what its id is:
    *
    * - If no other menu is opened it opens the menu
    * - If the currently opened menu has the same id as menuId that menu
    *   is closed and no other action is taken.
    * - If the currently open menu has an id other than menuId that menu
    *   is closed and the new menu is opened.
    *
    * @param menuId an id for the menu to be opened
    * @param scope the scope under which operations to open and close the
    *    menu should take place
    * @param openCallback a callback to be used to open the menu
    * @param closeCallback a callback to be used to close the menu
    */
   toggleMenu = (menuId, scope, openCallback, closeCallback) => {
      const mainCanvas = $("#canvas-container");
      const currentMenuId: string = this.openedMenuId;
      this.closeMenu();

      if (currentMenuId === menuId) {
         return;
      }

      openCallback();
      this.openedMenuId = menuId;
      this.closeCallback = closeCallback;
      $(document).on("click", this.closeMenu);
      mainCanvas.on("touchstart", this.closeMenu);
   };
}
