/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Html5mmrRedirInstance } from "./Html5mmrRedirInstance";
import { HTML5MMR } from "./model/html5MMR.rpc.models";
import { Html5MMRChan } from "./html5MMRChan";
import { Logger } from "../../../../core/libs/logger";
import {
   HTML5MMR_CONST,
   JKEYS,
   WEB_COMMAND,
   WEBVIEW_CONST,
   BCR_CLIENT_ERROR,
   InjectedScriptMessageType,
   BCR_CONST
} from "./html5MMR-consts";
import { AB, TranslateService } from "@html-core";
import { BrowserRegion, UpdateDPI, UpdateVolume } from "./model/html5MMR.media.models";
import { BCRHelper, BCR_INJECTED_CODE, enhancedBcrContentScript } from "./bcrHelper";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { MediastreamNotification } from "../../../common/service/mediastream-notification";
import { GoogleCommonSettings } from "../../../../chrome-client/launcher/server-connect/google-common-settings.service";
import { GeolocationService } from "../../../common/service/geolocation-service";
import { ClientSettingModel } from "../../../common/model/client-setting-model";
import { RootModel } from "../../../common/model/root-model";
export class BCRWebViewInstance implements Html5mmrRedirInstance {
   private readonly WEBVIEW_DOM_ID: string;
   private readonly ERROR_FLAG_REGEX: RegExp = new RegExp("^(data:text/html)");
   private readonly URL_PROTOCAL: RegExp = new RegExp("^(http|https)://");
   private webview: HTMLWebViewElement;
   private mmrChannel: Html5MMRChan.Client;
   private instanceId: number;
   private previousURL: string;
   private translate: TranslateService;
   private partitionId: string;
   private muted: boolean;
   private bcrHelper: BCRHelper;
   private isEnhBcr: boolean;
   private currentURL: string;
   private isFullScreen: boolean = false;
   private browserRegion: BrowserRegion = new BrowserRegion();
   private modalDialogService: ModalDialogService;
   private mediastreamNotification: MediastreamNotification;
   private googleCommonSetting: GoogleCommonSettings;
   private geolocationService: GeolocationService;
   private serverDpi: number = 0;
   private webViewZoomFactor: number = 0;
   private rootModel: RootModel;
   private enhBCRPort: chrome.runtime.Port;
   private urlRedirectChain: Array<string>;

   public constructor(
      translate: TranslateService,
      mmrChannel: Html5MMRChan.Client,
      partitionId: string,
      instanceId: number,
      redirectedUrl: string,
      bcrHelper: BCRHelper,
      isEnhBcr: boolean,
      webTextPayload: any,
      modalDialogService: ModalDialogService,
      mediastreamNotification: MediastreamNotification,
      googleCommonSettings: GoogleCommonSettings,
      geolocationService: GeolocationService,
      rootModel: RootModel
   ) {
      this.translate = translate;
      this.mmrChannel = mmrChannel;
      this.partitionId = partitionId;
      this.instanceId = instanceId;
      this.currentURL = redirectedUrl;
      this.bcrHelper = bcrHelper;
      this.isEnhBcr = isEnhBcr;
      this.modalDialogService = modalDialogService;
      this.mediastreamNotification = mediastreamNotification;
      this.googleCommonSetting = googleCommonSettings;
      this.geolocationService = geolocationService;
      this.WEBVIEW_DOM_ID = `webview-${this.instanceId}`;
      this.previousURL = "";
      this.browserRegion.updateGuestOrigin(webTextPayload);
      this.rootModel = rootModel;
      this.urlRedirectChain = [];
      Logger.info("Inside constructor of BCRWebViewInstance" + this.getInstanceIdForLog(), Logger.BCR);
   }

   /**
    * Intialize the webview by inserting the webview element.
    * Navigate to the webpage if it's whitelisted.
    */
   public initAndNavigate = () => {
      Logger.info("Inside initAndNavigate " + this.getInstanceIdForLog(), Logger.BCR);
      this.webview = document.createElement("webview");
      Logger.info(`Webview with Instance ID - ${this.instanceId} created.`, Logger.BCR);
      this.addWebviewToDOM();
      this.subscribeToWebviewEvents();
      this.createWebviewContextMenu();
      this.addContentScripts();
      this.navigateToUrl(this.currentURL);
   };

   /**
    * Handle WebText request coming from server related to BrowserRedirection.
    * If request is not handled, log error.
    *
    * @param {number} cmd, {any} cmdObj
    */
   public handleWebText = (cmd: number, cmdObj: any): void => {
      let data;
      switch (cmd) {
         case WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_LOAD_REMOTE_URL:
            if (cmdObj.urlRedirectChain) {
               this.urlRedirectChain = Array.from(cmdObj[JKEYS.URL_REDIRECT_CHAIN]);
            }
            this.handleLoadRemoteURL(cmdObj[JKEYS.URL]);
            break;
         case WEB_COMMAND.UPDATE_TITLE:
            Logger.info("Server acknowledged updateTitle command.");
            break;
         case WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_SEND_MESSAGE:
            Logger.info("ENH BCR received a message from the far side with: " + cmdObj.payload.data, Logger.BCR);
            data = {
               type: BCR_CONST.ENH_BCR,
               instanceId: this.instanceId,
               commandId: InjectedScriptMessageType.ENH_BCR_RECEIVED_MESSAGE,
               payload: cmdObj.payload
            };
            this.postMessage(this.enhBCRPort, data);
            break;
         case WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_RESPONSE:
            Logger.info("ENH BCR received message response from far side: " + JSON.stringify(cmdObj.payload));
            data = {
               type: BCR_CONST.ENH_BCR,
               instanceId: this.instanceId,
               commandId: InjectedScriptMessageType.SEND_ENH_BCR_RESPONSE,
               payload: cmdObj.payload
            };
            this.postMessage(this.enhBCRPort, data);
            break;
         case WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_CHANGE_OVERLAY: {
            Logger.info(
               "ENH BCR received change overlay message. WebView is currently visible: " + this.isVisible(),
               Logger.BCR
            );
            const payload = cmdObj[JKEYS.PAYLOAD];
            //Set the webview visibility to what the extension is expecting. The overlay manager will handle any mistakes
            this.webview.style.visibility = payload[JKEYS.SHOW] ? "visible" : "hidden";
            Logger.info(
               "ENH BCR received change overlay message. WebView set visibility to: " +
                  this.webview.style.visibility +
                  " Send response to far side: " +
                  Boolean(payload[JKEYS.RESP_NEEDED]),
               Logger.BCR
            );
            const response = {
               type: InjectedScriptMessageType.REQUEST_DONE,
               ref: payload[JKEYS.REF],
               result: true,
               returnObj: true
            };
            //Send response to agent
            if (payload[JKEYS.RESP_NEEDED]) {
               cmdObj[JKEYS.PAYLOAD] = response;
               cmdObj[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_RESPONSE;
               cmdObj[JKEYS.COMMAND] = BCR_CONST.ENH_BCR_RESPONSE;
               const resultStr = JSON.stringify(cmdObj);
               this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_RESPONSE, resultStr);
            } else {
               //Send response to client
               const data = {
                  type: BCR_CONST.ENH_BCR,
                  instanceId: this.instanceId,
                  commandId: InjectedScriptMessageType.SEND_ENH_BCR_RESPONSE,
                  payload: response
               };
               this.postMessage(this.enhBCRPort, data);
            }
            break;
         }
         default:
            Logger.error(
               "bcrWebViewInstance received unexpected web command: " +
                  cmd +
                  ", " +
                  JSON.stringify(cmdObj) +
                  this.getInstanceIdForLog(),
               Logger.BCR
            );
            break;
      }
   };

   /**
    * Handle EnvUpdate request coming from server.
    * TODO : Need to handle volume of audio.
    *
    * @param {HTML5MMR.EnvFlag} flag, {any} cmdObj
    */
   public handleEnvUpdate = (flag: HTML5MMR.EnvFlag, cmdObj: any): void => {
      Logger.info(
         "bcrWebViewInstance received env update message" +
            flag +
            ", " +
            JSON.stringify(cmdObj) +
            this.getInstanceIdForLog(),
         Logger.BCR
      );
      switch (flag) {
         case HTML5MMR.EnvFlag.VOLUME:
            this.handleUpdateVolume(new UpdateVolume.Request(cmdObj));
            break;
         case HTML5MMR.EnvFlag.DPI:
            this.handleUpdateDPI(new UpdateDPI.Request(cmdObj));
            break;
         default:
            Logger.error(
               "bcrWebViewInstance recieved unexpected env update: " + flag + this.getInstanceIdForLog(),
               Logger.BCR
            );
            break;
      }
   };

   /**
    * Handle UpdateOverlay request coming from server.
    * Change the width, heigth and top left position of webview
    * based upon the coordinates been send by server.
    *
    * For Chrome App, the DPI of the machine at the start of application is of use.
    * input remoteDPIScaleFactor can be used for windows horizon client.
    *
    * Let us say dpi of client machine is 1.25 when app is started.
    * Now start BCR in a VDI.
    * Now change dpi of Client machine to 1.50
    * At this point server will send the coordinates w.r.t to DPI 1.25 not 1.50
    * So even though the param  remoteDPIScaleFactor is 1.50 it's of no use for us.
    * It may happen that, when Client was launched DPI of client machine was 1.25
    * But after that Client DPI is changed to 1.5 and BCR session is launched.
    * In that case well we have to use the DPI, when Client was launched i.e. 1.25
    *
    * @param {HTML5MMR.MMRUpdateOverlayRequest} flag, {number} remoteDPIScaleFactor
    */
   public handleUpdateOverlay = (cmdObj: HTML5MMR.MMRUpdateOverlayRequest, remoteDPIScaleFactor: number): void => {
      if (remoteDPIScaleFactor === 0) {
         Logger.error("remoteDPIScaleFactor for webview with" + this.getInstanceIdForLog() + " is 0, returning.");
         return;
      }

      //This will adjust the webview, when the Client DPI is changed dynamically.
      const clientDpi = ClientSettingModel.getDPIAtAppInit();
      Logger.info(
         "Inside handleUpdateOverlay for BCR" +
            ", visible=" +
            cmdObj.visible +
            ", [l, t, w, h] = [" +
            cmdObj.left +
            ", " +
            cmdObj.top +
            ", " +
            cmdObj.width +
            ", " +
            cmdObj.height +
            ", rgnCount = " +
            cmdObj.count +
            ", DPI =" +
            +clientDpi +
            "]" +
            this.getInstanceIdForLog(),
         Logger.BCR
      );

      //serverDpi is of no use when we are drying to realign the webview on top of browser.
      this.browserRegion.updateOverlay(cmdObj, clientDpi);
      this.realignWebview();
   };

   private realignWebview = (): void => {
      const overlayInfo = this.browserRegion.browserOverlayInfo;
      if (!overlayInfo) {
         Logger.error("overlayInfo is null" + this.getInstanceIdForLog(), Logger.BCR);
         return;
      }

      Logger.info(
         "Inside realignWebview for BCR " +
            "visible=" +
            overlayInfo.visible +
            ", [l, t, w, h] = [" +
            overlayInfo.left +
            ", " +
            overlayInfo.top +
            ", " +
            overlayInfo.width +
            ", " +
            overlayInfo.height +
            ", rgnCount = " +
            overlayInfo.count +
            ", dpi =" +
            overlayInfo.dpiScaleFactor +
            "]" +
            this.getInstanceIdForLog(),
         Logger.BCR
      );

      if (this.rootModel && this.rootModel.get("showChromeMenuBar")) {
         overlayInfo.top += HTML5MMR_CONST.TOP_HEIGHT_FOR_VIDEO_ADJUSTMENT;
      }

      this.webview.style.width = overlayInfo.width + "px";
      this.webview.style.height = overlayInfo.height + "px";
      this.webview.style.left = overlayInfo.left + "px";
      this.webview.style.top = overlayInfo.top + "px";
      this.webview.style.visibility = overlayInfo.visible ? "visible" : "hidden";
      this.webview.style.clipPath = `path('${overlayInfo.regionMask}')`;

      this.updateWebViewZoom(overlayInfo.dpiScaleFactor);
   };

   /**
    * Server dpi and Client dpi may be different.
    * Webview in the client, shows the content w.r.t zoom factor of
    * Client machine, which is similar to Chrome Browser in client.
    * So we need to adjust the zoom factor of webview to show content w.r.t client.
    *
    * For example if Client dpi = 1.25 & Server Dpi = 1.0
    * we have to set the zoom level to 1.0/1.25 = 0.8% in webview.
    *
    * @param { number } clientDpi
    */
   public updateWebViewZoom = (clientDpi: number) => {
      if (clientDpi > 0 && this.serverDpi > 0) {
         this.webViewZoomFactor = Number((this.serverDpi / clientDpi).toFixed(2));
         Logger.info(
            "The scale factor for BCR in [Server, Client, Adjusted] = [" +
               this.serverDpi +
               ", " +
               clientDpi +
               ", " +
               this.webViewZoomFactor +
               "]" +
               this.getInstanceIdForLog(),
            Logger.BCR
         );
         this.webview.getZoom(this.getZoomCalllback);
      }
   };

   /**
    * Inside the callback of getZoom, check current zoom.
    * If it's different then expected i.e. this.webViewZoomFactor call setZoom.
    * This will minimize calls to this.webview.setZoom when current and expected zoom are same.
    */
   private getZoomCalllback = (zoom: number) => {
      if (zoom != this.webViewZoomFactor) {
         Logger.info(
            "Changing Zoom of webview to=" + this.webViewZoomFactor + " From = " + zoom + this.getInstanceIdForLog(),
            Logger.BCR
         );
         this.webview.setZoom(this.webViewZoomFactor, this.setZoomCalllback);
      }
   };

   //Confirmation callback for setZoom call.
   private setZoomCalllback = () => {
      Logger.info(
         "Zoomfactor of webview set to " + this.webViewZoomFactor + " successfully" + this.getInstanceIdForLog(),
         Logger.BCR
      );
   };

   /**
    * Remove the webview from the page.
    * When the adjacent tab/page of browser in the server is closed
    * we need to remove the webview in client and destroy the instance.
    *
    * Persistent Cookie data is not deleted in order to keep users logged in to
    * accounts within a partition, even after a webview is closed.
    *
    * @param None
    */
   public clear = (): void => {
      Logger.info("Clear for BCRWebViewInstance gets called " + this.getInstanceIdForLog(), Logger.BCR);
      const clearDataType = {
         appcache: true,
         cache: true,
         cookies: true,
         fileSystems: true,
         indexedDB: true,
         localStorage: true,
         persistentCookies: false,
         sessionCookies: true,
         webSQL: true
      };

      if (this.isWebviewCreated()) {
         this.unsubscribeFromWebviewEvents();
         this.webview.clearData({ since: 0 }, clearDataType, () => {
            Logger.info(`Removing webview with ID: ${this.WEBVIEW_DOM_ID} from broswer.`, Logger.BCR);
         });
         if (this.isEnhBcr) {
            const data = {
               type: BCR_CONST.ENH_BCR,
               instanceId: this.instanceId,
               commandId: InjectedScriptMessageType.REMOVE_ENH_BCR_API_SCRIPT
            };
            this.postMessage(this.enhBCRPort, data);
         }
         const parent = document.querySelector(`#${WEBVIEW_CONST.WEBVIEW_OVERLAY_CONTAINER}`);
         parent.removeChild(this.webview);
         this.webview = null;
      } else {
         Logger.error(`Current webview with ID ${this.WEBVIEW_DOM_ID} is not found for removal.`, Logger.BCR);
      }
   };

   /**
    * Subscribe to the events of webview.
    *
    * @param None
    */
   private subscribeToWebviewEvents = () => {
      this.webview.addEventListener("loadstart", (e: WebView.Events.LoadStartEvent) => {
         this.handleLoadStart(e);
      });

      this.webview.addEventListener("loadstop", () => {
         this.handleLoadStop();
      });

      this.webview.addEventListener("loadredirect", (e) => {
         this.handleLoadRedirect(e);
      });

      this.webview.addEventListener("contentload", () => {
         this.handleContentLoad();
      });

      this.webview.addEventListener("newwindow", (e) => {
         this.handleNewWindowEvent(e);
      });

      this.webview.addEventListener("permissionrequest", (e) => {
         this.handlePermissionRequestEvent(e);
      });

      this.webview.addEventListener("exit", (e) => {
         this.handleWebViewExit(e);
      });

      this.webview.addEventListener("loadabort", (e) => {
         this.handleLoadAbort(e);
      });

      this.webview.addEventListener("dialog", (e) => {
         this.handleDialog(e);
      });

      this.webview.addEventListener("loadcommit", (e) => {
         this.handleLoadCommit(e);
      });
   };

   /**
    * unsubscribe from the events of webview before exiting.
    *
    * @param None
    */
   private unsubscribeFromWebviewEvents = () => {
      this.webview.removeEventListener("loadstart", this.handleLoadStart);
      this.webview.removeEventListener("loadstop", this.handleLoadStop);
      this.webview.removeEventListener("loadredirect", this.handleLoadRedirect);
      this.webview.removeEventListener("contentload", this.handleContentLoad);
      this.webview.removeEventListener("newwindow", this.handleNewWindowEvent);
      this.webview.removeEventListener("permissionrequest", this.handlePermissionRequestEvent);
      this.webview.removeEventListener("exit", this.handleWebViewExit);
      this.webview.removeEventListener("loadabort", this.handleLoadAbort);
      this.webview.removeEventListener("dialog", this.handleDialog);
      this.webview.removeEventListener("loadcommit", this.handleLoadCommit);
      Logger.info("Unsubscribed from all subscribed events of webview" + this.getInstanceIdForLog(), Logger.BCR);
   };

   private isWebviewCreated = () => {
      return (document.querySelector(`#${this.WEBVIEW_DOM_ID}`) as HTMLWebViewElement) !== null;
   };

   /**
    * Fired when webview started loading a page.
    * Send message to server only when isTopLevel = true
    *
    * @param {WebView.Events.LoadStartEve} : e
    */
   private handleLoadStart = (e: WebView.Events.LoadStartEvent) => {
      if (e.isTopLevel) {
         if (!this.isUrlWhiteListed(e.url)) {
            this.sendMsgUrlNotWhitelisted(e.url);
            return;
         }

         Logger.info("Got loadstart event for url : " + e.url + this.getInstanceIdForLog(), Logger.BCR);
         const msg = {};
         msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_LOADING_STATE;
         msg[JKEYS.LOADING] = true;
         msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
         this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_LOADING_STATE, JSON.stringify(msg));
      }
   };

   /**
    * Fired when all frame & subframe gets loaded in a page.
    * Exceptions - If a page creates new iframe after page is loaded, then one loadstop will get fired.
    *
    * @param null
    */
   private handleLoadStop = () => {
      if (this.webview.src === undefined || this.webview.src === null) {
         Logger.warning("Webview.src is empty" + this.getInstanceIdForLog(), Logger.BCR);
         return;
      }
      this.sendMessageUpdateAddr(this.webview.src);
   };

   /**
    * Fired when a Url is redirected to another url, while getting loaded in webview.
    * Cross check whether the redirected url is white listed or not.
    *
    * @param { WebView.Events.LoadRedirectEvent} e
    */
   private handleLoadRedirect = (e: WebView.Events.LoadRedirectEvent) => {
      if (!e.isTopLevel || !e.newUrl || e.oldUrl === e.newUrl) {
         return;
      }
      if (this.isEnhBcr && this.urlRedirectChain) {
         this.urlRedirectChain.push(e.newUrl);
      }
      Logger.info(
         "handleLoadRedirect gets called, url : " +
            e.oldUrl +
            " is redicted to : " +
            e.newUrl +
            this.getInstanceIdForLog(),
         Logger.BCR
      );
      if (!this.isUrlWhiteListed(e.newUrl)) {
         this.sendMsgUrlNotWhitelisted(e.newUrl);
      }
   };

   /**
    * Fired when document is loaded inside webview.
    * Send LOADING as false with command id 212 to server.
    * Once done send HTML5MMR_BRWREDIR_COMMAND_UPDATE_ADDR message to the server.
    *
    * @param null
    */
   private handleContentLoad = () => {
      Logger.info("Page load completed for url : " + this.webview.src + this.getInstanceIdForLog(), Logger.BCR);
      const msg = {};
      msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_LOADING_STATE;
      msg[JKEYS.LOADING] = false;
      msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
      this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_LOADING_STATE, JSON.stringify(msg));
   };

   /**
    * Fired when webview is trying to access camera/mic , requested by the website.
    * If user is accessing it for the first time,
    * show a one-time pop-up and ask permission, and allow or deny accordingly.
    * else access the the preference from prefdata.
    * Currently permission is granted for all URLs and it's not domain speciific.
    *
    * @param (WebView.Events.PermissionRequestEvent) e
    */
   private handleMediaStreamPermission = async (e: WebView.Events.PermissionRequestEvent) => {
      e.preventDefault();
      await this.mediastreamNotification
         .showMediaStreamNotificationIfNeeded()
         .then(() => {
            Logger.info("Promise for mediastream is resolved, camera and mic permission is granted.", Logger.BCR);
            e.request.allow();
         })
         .catch((error) => {
            Logger.info("Promise for mediastream is rejected, camera and mic permission is denied.", Logger.BCR);
            e.request.deny();
         });
   };

   /**
    * Fired when webview is trying to access geolocation , requested by the website.
    * If user is accessing it for the first time,
    * show a one-time pop-up and ask permission, and allow or deny accordingly.
    * else access the the preference from prefdata.
    * Currently permission is granted for all URLs and it's not domain speciific.
    *
    * @param (WebView.Events.PermissionRequestEvent) e
    */
   private handleGeolocationPermission = async (e: WebView.Events.PermissionRequestEvent) => {
      e.preventDefault();
      await this.geolocationService
         .maybeShowPermissionPrompt()
         .then(() => {
            e.request.allow();
            Logger.info("Geolocation Permission is granted.", Logger.BCR);
         })
         .catch((error) => {
            e.request.deny();
            Logger.info("Geolocation Permission is denied.", Logger.BCR);
         });
   };

   /**
    * Gets trigerred when user clicks on any hyperlinks in the page or a popup is triggered.
    * If hyperlink is to open new page, send HTML5MMR_BRWREDIR_COMMAND_UPDATE_ADDR message to the server.
    * This will open a page in the server, and server will send CREATEBRWREDIR_INSTANCE back to client.
    * Post that client will open the new page for redirection.
    * Similar to windows client, new page will not be in focus, and current page will remain active.
    * Saving file with BCR feature is not supported.
    *
    * If a popup message is triggered, open the popup in a new tab and add popup:true to the payload in the
    * message to server.
    *
    * If we are not handling the message, log the error.
    *
    * @param {WebView.Events.NewWindowEvent} e
    */
   private handleNewWindowEvent = (e: WebView.Events.NewWindowEvent): void => {
      Logger.info("newwindow event trigerred with param=" + JSON.stringify(e) + this.getInstanceIdForLog(), Logger.BCR);
      switch (e.windowOpenDisposition) {
         case "new_background_tab":
         case "new_foreground_tab":
            this.openNewInstance(e.targetUrl, false);
            break;
         case "new_window":
            this.openNewInstance(e.targetUrl, true);
            break;
         case "save_to_disk":
            Logger.error("Can't save file to disk, feature not supported" + this.getInstanceIdForLog(), Logger.BCR);
            break;
         case "new_popup":
            Logger.info("Opening popup in a new tab" + this.getInstanceIdForLog(), Logger.BCR);
            this.openNewInstance(e.targetUrl, false, true);
            break;
         default:
            Logger.info("Unhandled permission type" + e.windowOpenDisposition + this.getInstanceIdForLog(), Logger.BCR);
            break;
      }
   };

   /**
    * Gets trigerred when PermissionRequestEvent in raised by the page.
    * We will allow fullscreen, geolocation, filesystem
    * Download is not supported in windows client, so we will block it.
    * The main reason is it will download the file in client and not in server.
    *
    * TODO : Sharing entire screen, client + server. Need to share just server.
    * Issue : For Google maps geolocation permission event is not coming, for other urls it's coming.
    *
    * For Geolocation and media, show a pop-up for the first time and save user preference.
    * Use the same preferences from next requests.
    * User can change the prefernces from Settings.
    *
    * @param {WebView.Events.PermissionRequestEvent} e
    */
   private handlePermissionRequestEvent = async (e: WebView.Events.PermissionRequestEvent) => {
      Logger.info(
         "permissionrequest event trigerred with param=" +
            JSON.stringify(e) +
            " permission type=" +
            e.permission +
            this.getInstanceIdForLog(),
         Logger.BCR
      );
      switch (e.permission) {
         case "download":
            e.request.deny();
            Logger.error("Download is not supported" + this.getInstanceIdForLog(), Logger.BCR);
            this.handleContentLoad();
            break;
         case "pointerLock":
            e.request.allow();
            break;
         case "media":
            await this.handleMediaStreamPermission(e);
            break;
         case "geolocation":
            await this.handleGeolocationPermission(e);
            break;
         case "filesystem":
            e.request.allow();
            break;
         case "fullscreen":
            e.request.allow();
            break;
         default:
            //Deny for "loadplugin" & other items.
            Logger.info(
               "permission type: " + e.permission + " is not handled." + this.getInstanceIdForLog(),
               Logger.BCR
            );
            e.request.deny();
            break;
      }
   };

   /**
    * Send message to server to open a URL
    * if isNewWindow is true open new window, else open a new tab
    * if isPopup is true send appropriate message to server
    * @param {string} targetUrl
    * @param {isNewWindow} isNewWindow
    * @param {boolean} isPopup
    */
   private openNewInstance = (targetUrl: string, isNewWindow: boolean, isPopup: boolean = false) => {
      Logger.info(
         "openNewInstance called with url=" +
            targetUrl +
            ", isNewWindow=" +
            isNewWindow +
            ", isPopup=" +
            isPopup +
            this.getInstanceIdForLog(),
         Logger.BCR
      );
      const msg = {};
      msg[JKEYS.COMMAND_ID] = isNewWindow
         ? WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_NEW_WINDOW
         : WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_NEW_TAB;
      msg[JKEYS.URL] = targetUrl;
      msg[JKEYS.POPUP] = isPopup;
      msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
      this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_NEW_TAB, JSON.stringify(msg));
   };

   /**
    * Fired when webview is terminated bcaz of issue in webpage.
    *
    * @param {WebView.Events.ExitEvent} e
    */
   private handleWebViewExit = (e: WebView.Events.ExitEvent) => {
      Logger.info("Error in webview and it's closed, reason = " + e.reason + this.getInstanceIdForLog(), Logger.BCR);
      const msg = {};
      msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_LOADING_STATE;
      msg[JKEYS.LOADING] = true;
      msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
      this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_REMOVE_TAB, JSON.stringify(msg));
   };

   /**
    * When webview aborts load error flag and send to webview for rendering
    * Handle only if it's the top level page, ignore others.
    * If webview.src = <> is set, webview will send LoadAbort with ERR_ABORTED
    * so ignore that case. It's also ignored in windows client. This can also
    * gets called when download is aborted.
    *
    * Ignoring the case with error code -338. This is the ERR_INVALID_AUTH_CREDENTIALS
    * Which is sent to webview when an Auth credientials alert box is present. If this is not
    * caught the webview will abort before credientials can be inputted.
    *
    * @param {WebView.Events.LoadAbortEvent} e
    */
   private handleLoadAbort = (e: WebView.Events.LoadAbortEvent) => {
      if (
         !e.isTopLevel ||
         e.reason === "ERR_ABORTED" ||
         e.code === WEBVIEW_CONST.CRED_ERROR_CODE ||
         this.getIgnoreCertError(e.code)
      ) {
         return;
      }
      Logger.error(
         `Webview with instance id: ${this.instanceId}, aborted with url: ${e.url} , reason: ${e.reason}` +
            this.getInstanceIdForLog(),
         Logger.BCR
      );
      const html = BCR_INJECTED_CODE.ABORT_ERROR_FLAG(e.url, e.reason, e.code);
      this.webview.src = `data:text/html,${html}`;
      if (!this.isEnhBcr) {
         this.sendMessageUpdateTitle(this.translate._T("PAGE_FAILED_TO_LOAD"), e.url);
      }
   };

   private isErrorFlag = (url: string): boolean => {
      return this.ERROR_FLAG_REGEX.test(url);
   };

   /**
    * When webview recieves an alert box from a rendered website, this event
    * is fired. All the info from the alert box is contained in e
    *
    * Webview do not supports Alerts/Dialogs.
    * So we have subscribed to the message.
    * Identify the type of messageType, and call adjacent function.
    * e.preventDefault() will prevent default processing, post that we can
    * make a call to the callback.
    *
    * Used modalDialogService to show the Alerts om top of screen.
    * Issue is, it's modal and prevents user to click anywhere else in screen.
    * So we can not even open new pages on the browser.
    *
    * There is no way to find the caption of buttons from Event - e, so using
    * the default captions as Ok and Cancel.
    *
    * @param {WebView.Events.DialogEvent} e
    */
   private handleDialog = (e: WebView.Events.DialogEvent) => {
      Logger.info("Inside handleDialog, messageText=" + e.messageText + this.getInstanceIdForLog(), Logger.BCR);
      e.preventDefault();
      const currentUrl = new URL(this.webview.src);
      switch (e.messageType) {
         case "alert":
            this.handleAlertBox(e, currentUrl.hostname);
            break;
         case "confirm":
            this.handleConfirmBox(e, currentUrl.hostname);
            break;
         case "prompt":
            this.handlePromptBox(e, currentUrl.hostname);
            break;
         default:
            Logger.error(
               "handleDialog got unknwown messageType" + e.messageType + this.getInstanceIdForLog(),
               Logger.BCR
            );
      }
   };

   /**
    * Handle AlertBox type dialog
    *
    * AlertBox have text to be displayed in the dialog.
    * It has one Ok button, clicking which will close the dialog
    * e.dialog.ok will make a callback to the alert box we have overridden.
    *
    * @param {WebView.Events.DialogEvent} e
    *        {string} hostname - Title to be displayed for PromptBox.
    */
   private handleAlertBox = (e: WebView.Events.DialogEvent, hostname: string) => {
      Logger.info("handleAlertBox called" + this.getInstanceIdForLog(), Logger.BCR);
      const options = {
         data: {
            title: `JavaScript Alert - ${hostname}`,
            content: e.messageText
         },
         callbacks: {
            confirm: () => {
               Logger.info("Confirmed alert" + this.getInstanceIdForLog(), Logger.BCR);
               e.dialog.ok();
            }
         }
      };
      this.modalDialogService.showError(options);
   };

   /**
    * Handle ConfirmBox type of dialog.
    *
    * ConfirmBox have text to be displayed in the dialog.
    * It has one Ok button, and a Cancel button
    * Proper callback of event(e) need to be called based on the clicked button.
    *
    * @param {WebView.Events.DialogEvent} e
    *        {string} hostname - Title to be displayed for PromptBox.
    */
   private handleConfirmBox = (e: WebView.Events.DialogEvent, hostname: string) => {
      Logger.info("handleConfirmBox called" + this.getInstanceIdForLog(), Logger.BCR);
      const options = {
         data: {
            title: `JavaScript Confirm - ${hostname}`,
            content: e.messageText
         },
         callbacks: {
            confirm: () => {
               Logger.info("Confirmed alert" + this.getInstanceIdForLog(), Logger.BCR);
               e.dialog.ok();
            },
            cancel: () => {
               Logger.info("Canceled alert" + this.getInstanceIdForLog(), Logger.BCR);
               e.dialog.cancel();
            }
         }
      };
      this.modalDialogService.showCancelConfirm(options);
   };

   /**
    * Handle PromptBox type of dialog.
    * ConfirmBox have an editable field, which may contain default value.
    * + Ok and cancel button.
    * If user changes the value in editable field, same value should pass on to the callback.
    * Cancel callback, do not need the value of editable box inside edit control.
    *
    * defaultPromptText in event "e" contains the default value for edit text
    *
    * Added a new class showCancelConfirmWithInputText
    * Which can accept default value for editable text from user.
    * And when Ok is clicked will return back the data entered by user in edit field.
    * Sample site to test the feature : https://tinyurl.com/5d9duu67
    *
    * @param {WebView.Events.DialogEvent} e
    *        {string} hostname - Title to be displayed for PromptBox.
    */
   private handlePromptBox = (e: WebView.Events.DialogEvent, hostname: string) => {
      const defaultValue = (e as any).hasOwnProperty("defaultPromptText") ? e["defaultPromptText"] : "";
      Logger.info(
         "handlePromptBox called with default value = " + defaultValue + this.getInstanceIdForLog(),
         Logger.BCR
      );

      const options = {
         data: {
            title: `JavaScript Prompt - ${hostname}`,
            content: e.messageText,
            inputTextData: defaultValue
         },
         callbacks: {
            confirm: (output: any) => {
               Logger.info(
                  "Confirmed Prompt, callback param = " + JSON.stringify(output) + this.getInstanceIdForLog(),
                  Logger.BCR
               );
               e.dialog.ok(output && output.hasOwnProperty("INPUT_TEXT") ? output["INPUT_TEXT"] : "");
            },
            cancel: () => {
               Logger.info("Canceled Prompt" + this.getInstanceIdForLog(), Logger.BCR);
               e.dialog.cancel();
            }
         }
      };
      this.modalDialogService.showCancelConfirmWithInputText(options);
   };

   /**
    * Injected script will send message with isFullScreen to give a direct way to control fullscreen changes
    * @param isFullScreen
    */
   public handleFullScreenChange = (isFullScreen: boolean) => {
      Logger.info(
         "handleFullScreenChange called with fullscreen = " + isFullScreen + this.getInstanceIdForLog(),
         Logger.BCR
      );
      if (this.isFullScreen != isFullScreen) {
         this.isFullScreen = isFullScreen;
         const msg = {};
         msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_FULLSCREEN_MODE;
         msg[JKEYS.FULLSCREEN] = this.isFullScreen;
         msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
         this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_FULLSCREEN_MODE, JSON.stringify(msg));
      }
   };

   /**
    * Send messages to server, command id may very.
    * commmand will be HTML5MMR_CMD_SEND_WEBTEXT.
    * Call JSON.stringify() to get the string from JSON and pass as payload.
    * Log error if failed.
    *
    * @param {number} commandId, {string} url
    *
    */
   private sendRPCForBCR = (commandId: number, payload: string): boolean => {
      const result = this.mmrChannel.sendRPC(HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT, [
         this.instanceId,
         0,
         commandId,
         payload
      ]);

      if (result === false) {
         Logger.error("Unable to send commandId = " + commandId + " to server.", Logger.BCR);
      } else {
         Logger.info(
            "Sent message to server commandid=" + commandId + " with payload=" + payload + this.getInstanceIdForLog(),
            Logger.BCR
         );
      }
      return result;
   };

   private handleLoadCommit = (e: WebView.Events.LoadCommitEvent) => {
      if (e.isTopLevel) {
         this.updateBackForwardMenu();
      }
   };

   private updateBackForwardMenu = () => {
      this.webview.contextMenus.update(WEBVIEW_CONST.BCR_BACK, { enabled: this.webview.canGoBack() });
      this.webview.contextMenus.update(WEBVIEW_CONST.BCR_FORWARD, { enabled: this.webview.canGoForward() });
   };

   /**
    * Add title injection script to the webview. The script will be injected on all new navigations,
    * that match the allowed URL patterns.
    *
    * @param None
    */
   private addContentScripts = () => {
      const matches = this.bcrHelper.getUrlsPattern(this.isEnhBcr);
      this.webview.addContentScripts([
         {
            name: "bcrMessaging",
            matches: matches,
            js: { code: BCR_INJECTED_CODE.BCR_TITLE_CONTENT_SCRIPT(`${this.instanceId}`) },
            run_at: "document_start"
         }
      ]);
      if (this.isEnhBcr) {
         Logger.info("In enhanced BCR adding content script", Logger.BCR);
         this.webview.addContentScripts([
            {
               name: "enhancedBcrMessaging",
               matches: matches,
               js: { code: BCR_INJECTED_CODE.ENHANCED_BCR_CONTENT_SCRIPT(`${this.instanceId}`) },
               run_at: "document_start"
            }
         ]);
      }
   };

   /**
    * TODO : Disable default context menu items of webpage.
    *
    * @param None
    */
   private createWebviewContextMenu = () => {
      Logger.info("createWebviewContextMenu called" + this.getInstanceIdForLog(), Logger.BCR);
      this.createContextMenuItem(
         WEBVIEW_CONST.BCR_NEW_TAB,
         this.translate._T("BCR_OPEN_LINK_NEW_TAB"),
         [WEBVIEW_CONST.LINK],
         true,
         (e) => {
            Logger.info("Opening link in new tab" + this.getInstanceIdForLog(), Logger.BCR);
            this.openNewInstance(e.linkUrl, false);
         }
      );
      this.createContextMenuItem(
         WEBVIEW_CONST.BCR_NEW_WINDOW,
         this.translate._T("BCR_OPEN_LINK_NEW_WINDOW"),
         [WEBVIEW_CONST.LINK],
         true,
         (e) => {
            Logger.info("Opening link in new window" + this.getInstanceIdForLog(), Logger.BCR);
            this.openNewInstance(e.linkUrl, true);
         }
      );
      this.createContextMenuSeparator([WEBVIEW_CONST.LINK]);
      this.createContextMenuItem(
         WEBVIEW_CONST.BCR_BACK,
         this.translate._T("BCR_BACK"),
         [WEBVIEW_CONST.ALL],
         false,
         (e) => {
            this.webview.back((success) => {
               if (success) {
                  Logger.info("Webview went to previous page" + this.getInstanceIdForLog(), Logger.BCR);
               } else {
                  Logger.info("Webview can't go to previous page" + this.getInstanceIdForLog(), Logger.BCR);
               }
            });
         }
      );
      this.createContextMenuItem(
         WEBVIEW_CONST.BCR_FORWARD,
         this.translate._T("BCR_FORWARD"),
         [WEBVIEW_CONST.ALL],
         false,
         (e) => {
            this.webview.forward((success) => {
               if (success) {
                  Logger.info("Webview went to forward page" + this.getInstanceIdForLog(), Logger.BCR);
               } else {
                  Logger.info("Webview can't go to forward page" + this.getInstanceIdForLog(), Logger.BCR);
               }
            });
         }
      );
      this.createContextMenuSeparator([WEBVIEW_CONST.ALL]);
      this.createContextMenuItem(
         WEBVIEW_CONST.BCR_PRINT,
         this.translate._T("BCR_PRINT"),
         [WEBVIEW_CONST.ALL],
         false,
         (e) => {
            Logger.info("Print functionality is disabled" + this.getInstanceIdForLog(), Logger.BCR);
         }
      );
   };

   /**
    * Create a single context menu item for use within the webview context menu.
    * General framework that you pass the id, title and onclick function into.
    * The current context is set to all, if needed the context array can be passed in as
    * another parameter.
    *
    * @param id
    * @param title
    * @param enabled
    * @param onClickFunction
    */
   private createContextMenuItem = (
      id: string,
      title: string,
      contexts: Array<string>,
      enabled: boolean,
      onClickFunction: { (e: any): void }
   ) => {
      this.webview.contextMenus.create({
         id: id,
         title: title,
         contexts: contexts,
         enabled: enabled,
         onclick: function (e) {
            onClickFunction(e);
         }
      });
   };

   /**
    * Create a context menu separator
    *
    * @param None
    */
   private createContextMenuSeparator = (contexts: Array<string>) => {
      this.webview.contextMenus.create({
         type: "separator",
         contexts: contexts
      });
   };

   /**
    * If Url is whitelisted open it in the tab,
    * else send error message to server.
    *
    * @param url : The url to be opened in webview
    */
   private navigateToUrl = (url: string) => {
      if (this.isUrlWhiteListed(url)) {
         if (this.webview.src !== url) {
            Logger.info(url + " is whitelisted, opening it in webview" + this.getInstanceIdForLog(), Logger.BCR);
            this.webview.src = url;
         }
      } else {
         this.sendMsgUrlNotWhitelisted(url);
      }
   };

   /**
    * when a url is not white listed
    * Send message to server, HTML5MMR_BRWREDIR_COMMAND_CLIENT_ERROR
    * Server will show an error in the native browser saying
    * URL is not whitelisted.
    * @param url
    */
   private sendMsgUrlNotWhitelisted = (url: string) => {
      Logger.warning("URL: " + url + " is not whitelisted" + this.getInstanceIdForLog(), Logger.BCR);
      const msg = {};
      msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_CLIENT_ERROR;
      msg[JKEYS.URL] = url;
      msg[JKEYS.ERROR_CODE] = BCR_CLIENT_ERROR.URL_NOT_WHITELISTED;
      msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
      msg[JKEYS.MOVED_TO_ENHANCED] = !this.isEnhBcr && this.bcrHelper.isUrlEnhWhiteListed(url);
      this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_CLIENT_ERROR, JSON.stringify(msg));
   };

   /**
    * Send message to server, HTML5MMR_BRWREDIR_COMMAND_UPDATE_ADDR
    * Server will update the url in the address bar and BCR address bar.
    * @param url
    */
   private sendMessageUpdateAddr = (url: string) => {
      if (url === this.previousURL || this.isErrorFlag(url)) {
         return;
      }
      this.previousURL = url;
      const msg = {};
      msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_UPDATE_ADDR;
      msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
      msg[JKEYS.URL] = url;
      if (this.isEnhBcr) {
         msg[JKEYS.URL_REDIRECT_CHAIN] = this.urlRedirectChain;
      }

      Logger.info(`send url: ${url} to update address` + this.getInstanceIdForLog(), Logger.BCR);
      this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_BRWREDIR_COMMAND_UPDATE_ADDR, JSON.stringify(msg));
   };

   /**
    * Send message to server, UPDATE_TITLE.
    * Server will update the tab title in the browser.
    * Calls sendMessageUpdateAddr to ensure that the servers url
    * matches the current webviews url or else the title wont get updated
    * @param title
    */
   public sendMessageUpdateTitle = (title: string, url: string) => {
      this.sendMessageUpdateAddr(url);
      const msg = {};
      msg[JKEYS.COMMAND_ID] = WEB_COMMAND.UPDATE_TITLE;
      msg[JKEYS.TITLE] = title;
      msg[JKEYS.URL] = url;
      msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
      Logger.info(`Send title: ${title} to update tab info ` + this.getInstanceIdForLog(), Logger.BCR);
      this.sendRPCForBCR(WEB_COMMAND.UPDATE_TITLE, JSON.stringify(msg));
   };

   /**
    * Handle messages recieved from enhBCR content script.
    * Pass messages from enhBCR content script to the extension to handle
    * injected API function calls.
    * @param port
    * @param msg
    */
   public handleEnhBcrContentScriptMessage = (port: chrome.runtime.Port, msg: any) => {
      if (msg === null || msg.type === null || msg.payload === null) {
         Logger.error("Received a null message from enhBcr content script", Logger.BCR);
         return;
      }
      Logger.info("Received message from enhanced BCR content: " + String(msg.type), Logger.BCR);
      this.enhBCRPort = port;
      switch (msg.type) {
         case InjectedScriptMessageType.ENH_BCR_INITIALIZED: {
            const data = {
               type: BCR_CONST.ENH_BCR,
               instanceId: this.instanceId,
               commandId: InjectedScriptMessageType.INJECT_ENH_BCR_API_SCRIPT,
               script: this.bcrHelper.getEnhBcrInjectedScript()
            };
            this.postMessage(this.enhBCRPort, data);
            break;
         }
         case InjectedScriptMessageType.OVERLAY_VISIBILITY:
            Logger.info(
               "Enhanced BCR injected script called show/hide nearside and returned: " + JSON.stringify(msg.payload),
               Logger.BCR
            );
            msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_OVERLAY_VISIBILITY;
            msg[JKEYS.PAYLOAD] = msg.payload;
            msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
            this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_OVERLAY_VISIBILITY, JSON.stringify(msg));
            break;
         case InjectedScriptMessageType.SEND_MESSAGE:
            Logger.info(
               "Enhanced BCR injected script called sendMessageToOtherSide: " + JSON.stringify(msg.payload),
               Logger.BCR
            );
            msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_SEND_MESSAGE;
            msg[JKEYS.PAYLOAD] = msg.payload;
            msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
            this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_SEND_MESSAGE, JSON.stringify(msg));
            break;
         case InjectedScriptMessageType.ENH_RESPONSE:
            Logger.info(
               "Enhanced BCR injected script recieved message from other side and sending response: " +
                  JSON.stringify(msg.payload),
               Logger.BCR
            );
            msg[JKEYS.COMMAND_ID] = WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_RESPONSE;
            msg[JKEYS.PAYLOAD] = msg.payload;
            msg[JKEYS.FEATURE] = HTML5MMR_CONST.HTML5_FEATURE_TYPE.BROWSER_REDIRECTION_FEATURE;
            this.sendRPCForBCR(WEB_COMMAND.HTML5MMR_ENH_BRWREDIR_COMMAND_RESPONSE, JSON.stringify(msg));
            break;
         default:
            Logger.error(
               "bcrWebViewInstance received unexpected message from enhBcr content script " +
                  msg +
                  ", " +
                  this.getInstanceIdForLog(),
               Logger.BCR
            );
            break;
      }
   };

   /**
    * Adds the webview to the HTML DOM as a child element of Media-overlay div.
    * Pass in the initial url will get loaded in the webview.
    * set the initial audio if muted.
    *
    */
   private addWebviewToDOM = () => {
      Logger.info("addWebviewToDOM called" + this.getInstanceIdForLog(), Logger.BCR);
      this.webview.setAttribute("id", this.WEBVIEW_DOM_ID);
      this.webview.setAttribute("partition", this.partitionId);
      this.webview.focus();
      this.webview.style.position = "absolute";
      this.webview.style.zIndex = "20";
      this.webview.style.visibility = "hidden";
      //set zoom mode only for this webview.
      this.webview.setZoomMode("per-view");

      if (!document.querySelector("media-overlay")) {
         Logger.info("media-overlay element not present in the document, creating it now", Logger.BCR);
         document.createElement("media-overlay");
      }

      let webviewOverlayContainer = document.querySelector(`#${WEBVIEW_CONST.WEBVIEW_OVERLAY_CONTAINER}`);
      if (!webviewOverlayContainer) {
         webviewOverlayContainer = document.createElement("div");
         webviewOverlayContainer.setAttribute("id", WEBVIEW_CONST.WEBVIEW_OVERLAY_CONTAINER);
         document.querySelector("media-overlay").appendChild(webviewOverlayContainer);
      }
      webviewOverlayContainer.appendChild(this.webview);

      Logger.info("Added webview to the DOM" + this.getInstanceIdForLog(), Logger.BCR);

      if (this.muted !== undefined) {
         this.webview.setAudioMuted(this.muted);
      }
   };

   /**
    * Loads the remote url into the webview by checking if the link is valid.
    * Webview requires all urls to have http/https, so first checking if
    * the remote url has this, and adds it if not. the MDN URL api checks if the newly
    * created URL is valid. This is a temporary solution.
    *
    * @param {string} : remoteURL
    */
   private handleLoadRemoteURL = (remoteURL: string) => {
      Logger.info("Input url for handleLoadRemoteURL is " + remoteURL + this.getInstanceIdForLog(), Logger.BCR);

      try {
         const url = this.URL_PROTOCAL.test(remoteURL) ? new URL(remoteURL) : new URL("https://" + remoteURL);
         Logger.info(`Trying to open remote url: ${remoteURL} as: ${url.href}.`, Logger.BCR);
         this.navigateToUrl(url.href);
      } catch (e) {
         Logger.error(
            `${e.message}, Remote URL passed to webview ${remoteURL} is invalid` + this.getInstanceIdForLog(),
            Logger.BCR
         );
      }
   };

   /**
    * Handle the enviornment update for volume changes. Currently only mutes/unmutes the
    * webview. If the volume is initially muted before the webview gets created. Stores the
    * value in this.muted and sets it in addWebviewToDom
    *
    * TODO : change the specific volume of the webview
    * @param cmdObj
    */
   private handleUpdateVolume = (volume: UpdateVolume.Request) => {
      if (this.isWebviewCreated()) {
         this.webview.setAudioMuted(!!volume.mute);
         if (volume.mute) {
            Logger.info("Muted audio volume in webview" + this.getInstanceIdForLog(), Logger.BCR);
         }
      } else {
         this.muted = volume.mute;
      }
   };

   private handleUpdateDPI = (request: UpdateDPI.Request) => {
      Logger.info(
         "handleUpdateDPI for BCR " +
            "dpi=" +
            request.dpi +
            ", [b-x, b-y, g-x, g-y] = [" +
            request.boundingCX +
            ", " +
            request.boundingCY +
            " , " +
            request.guestOriginX +
            " , " +
            request.guestOriginY +
            "]" +
            this.getInstanceIdForLog(),
         Logger.BCR
      );

      //request.guestOriginX & request.guestOriginY are used only in case of multi monitor.
      //Note : dpi is dpi of server machine, (not the client)
      //dpi is coming as 96, 120 etc, we have to devide with 96 to get dpi factor.
      this.serverDpi = request.dpi > 0 ? request.dpi / AB.DPI_100_PERCENT : 1;
      this.browserRegion.updateGuestOrigin(request);

      //Hide the webview to stop flickering in the ui and wait for the overaly message to get trigerred.
      this.webview.style.visibility = "hidden";
      //We can not realign the webview in this message,
      //as the coordinates of browser are not available.
      //An updateoverlay message will get trigerred by server, which will adjust the coordinates.
   };

   private getInstanceIdForLog = (): string => {
      return "(instance id=" + this.instanceId + ").";
   };

   /**
    * Returns true if ignoreCertError is enabled. If ignoreCertError is disabled it will return true if the code is not a certificate error.
    * In summary: returns true if the error code should be ignored.
    * @param code
    */
   private getIgnoreCertError = (code: number) => {
      const ignoreCertError = this.isEnhBcr
         ? this.googleCommonSetting.isIgnoreCertErrorEnhBcrEnabled()
         : this.googleCommonSetting.isIgnoreCertErrorBcrEnabled();
      Logger.info(
         `Aborted with code: ${code}, which is a certification error: ${BCRHelper.certErrorList.has(code)}, ignoreCertError is: ${ignoreCertError}` +
            this.getInstanceIdForLog(),
         Logger.BCR
      );
      return ignoreCertError || !BCRHelper.certErrorList.has(code);
   };

   /**
    * Preventing a mix between enhBcr and bcr by checking both allowlists when in bcr mode.
    * When a user navigates or loads a URL that is in enhBcrAllowlist we will return false if this.isEnhBcr is false.
    * The error flag will produce a whitelisting error so we will always return true if the webview is in an error state.
    * @param url
    * @returns
    */
   private isUrlWhiteListed(url: string) {
      if (this.isErrorFlag(url)) {
         return true;
      }
      if (this.isEnhBcr) {
         return this.bcrHelper.isUrlEnhWhiteListed(url);
      } else {
         return this.bcrHelper.isUrlWhiteListed(url) && !this.bcrHelper.isUrlEnhWhiteListed(url);
      }
   }

   /**
    * Return if the visibility style element is set to "visible"
    * Does not check other forms of visibility, like if the webview is partial covered
    * @returns boolean
    */
   private isVisible() {
      const isVisible = this.webview.style.visibility === "visible";
      return isVisible;
   }

   /**
    * Send message to a webview content script via port
    * @param port
    * @param data
    */
   private postMessage(port: chrome.runtime.Port, data: any) {
      try {
         if (port) {
            port.postMessage(data);
         } else {
            Logger.error("Failed to post message as port is not initialized" + this.getInstanceIdForLog(), Logger.BCR);
         }
      } catch (e) {
         Logger.error("Failed to postMessage with error: " + e.message + this.getInstanceIdForLog(), Logger.BCR);
      }
   }
}
