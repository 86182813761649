<div class="modal-title dialog-title">{{ 'ABOUT_T' | i18nv2 }}</div>
<div class="modal-body dialog-content" fuzzy-free>
   <div class="about-dialog-content selectable">
      <div class="about-dialog-logo"></div>
      <div class="about-dialog-content-right">
            <span class="about-dialog-product-name-text">{{ 'COMPANY_NAME_T' | i18nv2 }}<sup>®</sup> {{ 'PRODUCT_NAME_T' | i18nv2 }}</span>
            <br>
            <span class="bold-text">{{ 'VERSION_NUMBER_M' | i18nv2 : versionNum }}</span>
            <br>
            <span class="bold-text">{{ 'BUILD_NUMBER_M' | i18nv2 : buildNum1 : buildNum2 }}</span>
            <br><br>
            {{ 'COPYRIGHT_BODY_M' | i18nv2 }}

            <a [href]="patentUrl" id="patentUrlLink" target="_blank" rel="linkRelValue" aria-describedby="new-window-1">{{patentUrl}}</a>

            <br><br>
            {{ 'COPYRIGHT_FOOT_M' | i18nv2}}

            <div *ngIf="showMore" >
               <br>
               <a [href]="eulaUrl" id="eulaUrlLink" target="_blank" rel="linkRelValue">{{ 'header_eula_link' | i18nv2 }}</a>
               <br>
               <a [href]="oslUrl" id="oslUrlLink" target="_blank" rel="linkRelValue">{{ 'header_osl_link' | i18nv2 }}</a>
               </div>
         </div>
      </div>
</div>
<div class="modal-footer dialog-button-row">
   <button type="button" id="aboutDialogHelpUrl" class="modal-button-base modal-button-right modal-button-blue" (click)="openHelpLink()" role="link" [attr.aria-describedby]= "'OPEN_IN_A_NEW_WINDOW' | i18nv2" >
      {{ 'HELP' | i18nv2 }}
   </button>
   <button type="button" id="aboutDialogCloseBtn" class="modal-button-base modal-button-right modal-button-blue" ngbAutofocus (click)="closeModal()">
      {{ 'OK' | i18nv2 }}
   </button>
</div>
<!-- New window warning messages -->
<div hidden>
   <span id="new-window-0">{{ 'link_new_window' | i18nv2 }}</span>
   <span id="new-window-1">{{ 'patent_link_new_window' | i18nv2 }}</span>
</div>