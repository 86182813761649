/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * changeWindowsPasswordHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler.
 */

import { MessageHandler } from "./messageHandler";
import util from "../util";
import { globalArray } from "../jscdkClient";

export default function ChangeWindowsPasswordHandler() {
   // member variables below
   this.messageName = "windows-password-expired";
   this.messageText = "windows-password-expired";

   // register dependencies here
   const router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
ChangeWindowsPasswordHandler.prototype = new MessageHandler();
// constructor
ChangeWindowsPasswordHandler.constructor = ChangeWindowsPasswordHandler;

/**
 * Set the request XML for windows-password-expired request.
 *
 * @param oldPassword [in] old password for the windows-password-expired
 *    request.
 * @param newPassword1 [in] new password1 for the windows-password-expired
 *    request.
 * @param newPassword2 [in] new password2 for the windows-password-expired
 *    request.
 */

ChangeWindowsPasswordHandler.prototype.setRequestXML = function (oldPassword, newPassword1, newPassword2) {
   let oldPasswordText, newPassword1Text, newPassword2Text, paramText;

   this.requestXML = "";

   oldPasswordText = util.createElement("name", "oldPassword");
   oldPasswordText += util.createElement("values", util.createElement("value", oldPassword));
   newPassword1Text = util.createElement("name", "newPassword1");
   newPassword1Text += util.createElement("values", util.createElement("value", newPassword1));
   newPassword2Text = util.createElement("name", "newPassword2");
   newPassword2Text += util.createElement("values", util.createElement("value", newPassword2));
   paramText = util.createElement("param", oldPasswordText);
   paramText += util.createElement("param", newPassword1Text);
   paramText += util.createElement("param", newPassword2Text);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", "windows-password-expired");
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
