/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";

type onHashChangeCallback = (current?: string, next?: string) => Observable<boolean> | Promise<boolean> | boolean;

export interface CanComponentDeactivate {
   canDeactivate?: onHashChangeCallback;
}

@Injectable({
   providedIn: "root"
})
export class DeactivateGuardService {
   private _onGeneralHashChanged;

   public canDeactivate(
      component: CanComponentDeactivate,
      currentRoute: ActivatedRouteSnapshot,
      currentState: RouterStateSnapshot,
      nextState: RouterStateSnapshot
   ) {
      const currentHash: string = currentState.url;
      const nextHash: string = nextState.url;
      if (typeof component.canDeactivate === "function") {
         return component.canDeactivate(currentHash, nextHash);
      } else if (typeof this._onGeneralHashChanged === "function") {
         return this._onGeneralHashChanged(currentHash, nextHash);
      } else {
         return true;
      }
   }

   public bindHashChangeHandler(callback: onHashChangeCallback) {
      this._onGeneralHashChanged = callback;
   }
}
