/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * sidebar-header.component.ts --
 *
 * Component implementing global context menu for sidebar
 *
 */
import { Component, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { EventBusService, LocalStorageService, COOKIE, BusEvent } from "@html-core";
import { Logger } from "../../../../core/libs/logger";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { ViewClientModel } from "../../../common/model/viewclient-model";
import { RemoteSessionEventService, SessionMsg } from "../../../common/remote-session/remote-session-event.service";
import { BlastWmks } from "../../common/blast-wmks.service";
import { ClipboardKillSwitchService } from "../../common/clipboard-killswitch.service";
import { WmksService } from "../../common/wmks.service";
import { ContextMenuManager } from "../../context-menu/context-menu-manager";
import { PanelEvent } from "../sidebar-constant";
import { TranslateService } from "@html-core";
import { imageAsset } from "../../../common/image-asset/image-asset";
import { RebrandImage } from "../../../common/service/rebrand-image.service";

@Component({
   selector: "sidebar-header",
   templateUrl: "./sidebar-header.component.html"
})
export class SidebarHeaderComponent implements AfterViewInit {
   public isWebPanelFreeClipboard: boolean;
   public enableClipboardNotification: boolean;
   public currentSession: BlastWmks = null;
   public showGlobalContextMenu: boolean = false;
   public isTrackPadMode: boolean = false;
   public imageAsset = imageAsset;
   @Output() panelEvent = new EventEmitter<PanelEvent>();

   constructor(
      private modalDialogService: ModalDialogService,
      private wmksService: WmksService,
      private clipboardKillSwitchService: ClipboardKillSwitchService,
      private eventBusService: EventBusService,
      private remoteSessionEventService: RemoteSessionEventService,
      private contextMenuManager: ContextMenuManager,
      private viewClientModel: ViewClientModel,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private rebrandImage: RebrandImage
   ) {
      this.isWebPanelFreeClipboard = this.clipboardKillSwitchService.getIsWebPanelFreeClipboard();
      this.enableClipboardNotification = clipboardKillSwitchService.enableClipboardNotification;
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, (session) => {
         this.currentSession = session;
      });
      this.isTrackPadMode = this.localStorageService.get(COOKIE.TOUCH_MODE) === "true";
      this.eventBusService.listen(BusEvent.SetTouchModeMsg.MSG_TYPE).subscribe((msg: BusEvent.SetTouchModeMsg) => {
         this.setTouchMode(msg.isTrackPadMode);
      });
   }

   public ngAfterViewInit() {
      // need to remove the hover property
      if (this.viewClientModel.mIsTouchDevice || this.viewClientModel.mIsVrDevice) {
         $("#cad").removeClass("icon-button-hover");
         $("#clipboard").removeClass("icon-button-hover");
         $("#contextMenu").removeClass("icon-button-hover");
      }

      jQuery(document).ready(function ($) {
         $("button").tooltip();
      });
      $("#keyboard").tooltip({
         items: "button",
         content: this.translate._T("TOGGLE_KEYBOARD")
      });
      $("#cad").tooltip({
         items: "button",
         content: this.translate._T("CTRL_ALT_DEL")
      });
      $("#contextMenu").tooltip({
         items: "button",
         content: this.translate._T("OPEN_MENU_T")
      });
      let FTBtnTooltip: string;
      if (!this.showFTPanel()) {
         FTBtnTooltip = this.translate._T("OPEN_FILE_TRANSFER");
      } else {
         FTBtnTooltip = this.translate._T("DISABLE_FT_PANEL");
      }
      $("#fileTransfer").tooltip({
         items: "button",
         content: FTBtnTooltip
      });
   }

   public aboutClicked = () => {
      this.modalDialogService.showAbout();
   };

   public isSupportedMobileDevice = () => {
      return this.viewClientModel.mIsTouchDevice || this.viewClientModel.mIsVrDevice;
   };

   public toggleKeyboard = () => {
      this.wmksService.launchKeyboard();
   };

   private setTouchMode = (isTrackPadMode: boolean) => {
      this.isTrackPadMode = isTrackPadMode;
      this.wmksService.changeTouchMode(this.isTrackPadMode);
      this.localStorageService.set(COOKIE.TOUCH_MODE, this.isTrackPadMode);
      if (this.isTrackPadMode) {
         this.eventBusService.dispatch(new BusEvent.NotificationMsg("TRACKPAD_MODE"));
      } else {
         this.eventBusService.dispatch(new BusEvent.NotificationMsg("NATIVETOUCH_MODE"));
      }
   };

   public changeTouchMode = () => {
      this.setTouchMode(!this.isTrackPadMode);
   };

   public openClipboardClicked = () => {
      // keep the button there to show clipboard status but not show the panel
      if (!this.isWebPanelFreeClipboard) {
         this.eventBusService.dispatch({
            type: "toggleClipboardPanel"
         });
      } else {
         if (this.clipboardKillSwitchService.enableClipboardNotification) {
            this.clipboardKillSwitchService.setIsClipboardNotification(false);
         } else {
            this.clipboardKillSwitchService.setIsClipboardNotification(true);
         }
         this.enableClipboardNotification = this.clipboardKillSwitchService.enableClipboardNotification;
      }
   };

   public openFileTransferPanel = () => {
      if (this.showFTPanel()) {
         this.eventBusService.dispatch({
            type: "toggleFileTransferPanel"
         });
      }
   };

   public hideCtrlAltDelete = (): boolean => {
      if (this.currentSession === null) {
         return true;
      }
      return this.currentSession.isApplicationSession;
   };

   public ctrlAltDeleteClicked = () => {
      Logger.info("Sending ctrl-alt-delete to active session.");
      if (this.currentSession && !this.currentSession.isApplicationSession) {
         this.wmksService.sendCtrlAltDel();
      }
   };

   public showFTPanel = (): boolean => {
      return !!this.currentSession && !this.currentSession.fileTransfer;
   };

   public globalContextMenuClicked = (event) => {
      this.contextMenuManager.toggleMenu(
         "globalContext",
         this,
         () => {
            this.showGlobalContextMenu = true;
            $("#contextMenu").attr("aria-expanded", "true");
         },
         () => {
            this.showGlobalContextMenu = false;
            $("#contextMenu").attr("aria-expanded", "false");
         }
      );
      event.stopPropagation();
   };

   public togglePanel = (event: PanelEvent) => {
      this.panelEvent.emit(event);
   };

   public getTenantHTMLAccessLogo = (): string => {
      return this.rebrandImage.getHTMLAccessLogo();
   };
}
