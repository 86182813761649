/**
 * ******************************************************
 * Copyright (C) 2017-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * A simple model, used only when page jump from launcher to desktop.
 */
import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class TransitionDataModel {
   public isApplicationSession: boolean;
   public isShadow: boolean;
   public sessionId: string;
   public originDesktop: {
      id: string;
      name: string;
   };
   public originApp: {
      appId: string;
      originId: string;
   };
   public blastURL: string;
   public enableUsb: boolean;
   public usbTicket: string;

   constructor() {
      this.isApplicationSession = null;
      this.isShadow = null;
      this.originDesktop = null;
      this.originApp = null;
      this.blastURL = null;
      this.enableUsb = false;
      this.usbTicket = "";
   }
}
