/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { MultiMonitor } from "./MultiMonitor";
import { Monitor } from "../../../../../../shared/desktop/multimon/common/monitor-message";

export class PrimaryScreen extends MultiMonitor {
   public canvasId = "primary-monitor";
   private _extendWindows = [];
   constructor(remoteappBorderService, coordinateConverter) {
      super();
      this._remoteappBorderService = remoteappBorderService;
      this._coordinateConverter = coordinateConverter;
      this._listen();
   }

   private _listen = () => {
      chrome.runtime.onConnect.addListener((port) => {
         if (port.name === this._getPortName()) {
            this._extendWindows.push(port);
            port.onMessage.addListener((msg) => {
               this._sendMessageToOtherScreen(msg, port);
               this._onMessage(msg);
            });
            port.onDisconnect.addListener(() => {
               const index = this._extendWindows.findIndex((v) => v == port);
               this._extendWindows.splice(index, 1);
            });
         }
      });
   };

   protected _onMessageMouseUp = (message: Monitor.BorderMouseUpMsg) => {
      const revertRect = this._coordinateConverter.revertRect(message.rect);
      this._remoteappBorderService.setTopWindowById(message.windowId);
      this.moveWindow(revertRect);
   };

   public moveWindow = (drawingRect) => {
      this._remoteappBorderService.moveWindowDirect(drawingRect);
   };

   protected _sendMessageToOtherScreen = (message: Monitor.MonitorMessage, exclude = null) => {
      this._extendWindows.forEach((port) => {
         if (exclude && exclude === port) return;
         port.postMessage(message);
      });
   };

   protected _toLocalPoint = (point) => {
      const base = this._coordinateConverter.revert({
         x: this._remoteappBorderService.multimonModel.baseX,
         y: this._remoteappBorderService.multimonModel.baseY
      });
      return {
         x: Math.round(point.x - screenX - base.x),
         y: Math.round(point.y - screenY - base.y)
      };
   };
}
