/**
 * ******************************************************
 * Copyright (C) 2016-2017, 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview rtavDialog.service.ts -- RTAVDialogService
 * Service to decouple the dialog implementation from UI layer
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";

@Injectable({ providedIn: "root" })
export class RtavDialogService {
   public uiDialogCallback;

   constructor() {}

   public setDialogFunction = (dialogFunction) => {
      this.uiDialogCallback = dialogFunction;
   };

   public showDialog = (info, okCallback, cancelCallback) => {
      if (typeof this.uiDialogCallback !== "function") {
         Logger.error("the uiDialogCallback doesn't exist", Logger.RTAV);
         return;
      }
      this.uiDialogCallback(info, okCallback, cancelCallback);
   };
}
