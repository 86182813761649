/**
 * ******************************************************
 * Copyright (C) 2014-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * unity-service.js --
 *
 * Module for wrapping unity to angular factory service. Unity Service is a
 * singleton service.
 *
 */

import { AB } from "../common/appblast-util.service";
import { EventBusService } from "@html-core";
import Logger from "../../../core/libs/logger";
import { Unity } from "./unity";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { UnityMessage, UnityPaused, UnityDestroyMsg } from "./unity-messages";
import { UnityHandler } from "./unity-handler";
import { Subject, Subscription } from "rxjs";
import { BlastWmks } from "../common/blast-wmks.service";

export abstract class UnityService {
   public static readonly UNITY_FEATURES = Unity.UnityFeatures.SHOW_FLOATING_LANGUAGE_BAR;
   public subject$: Subject<UnityMessage> = null;

   protected unityMgrMap: Map<string, Unity.BasicUnityMgrInterface> = null;
   constructor(
      protected remoteSessionEventService: RemoteSessionEventService,
      protected eventBusService: EventBusService
   ) {
      this.unityMgrMap = new Map<string, Unity.BasicUnityMgrInterface>();
      this.subject$ = new Subject<UnityMessage>();
   }

   public subscribe(callback: (msg: UnityMessage) => void): Subscription {
      Logger.error("subscribe callback for UnityService");
      return this.subject$.subscribe(callback);
   }

   private getMgr = (wmksKey: string): any => {
      return this.unityMgrMap.get(wmksKey);
   };

   public getUnityWindow = (wmksKey: string, windowId: string) => {
      return this.getMgr(wmksKey).windows[windowId];
   };

   /**
    * unityService.closeWindow
    *
    * Send closing window command to unityMgr.
    *
    * @param wmksKey  the wmksSession identifier.
    * @param windowId window to be closed.
    */
   public closeWindow = (wmksKey: string, windowId: string) => {
      this.closeWindows(wmksKey, [windowId]);
   };

   /**
    * unityService.closeWindows
    *
    * Send close commands to unityMgr for a list of windowIds. Windows
    * are closed in reverse order of input, so if the windows failed
    * to close, they are stacked on screen in the order of input
    * (window 1 on top of window 2, etc).
    *
    * @param wmksKey  the wmksSession identifier.
    * @param windowIds an array of windowIds to be closed.
    */
   public closeWindows = (wmksKey: string, windowIds: string[]) => {
      const unityMgr = this.getMgr(wmksKey);

      if (!unityMgr) {
         return;
      }

      // Bring windows to top first,  so they can open "save content"
      // dialogs
      unityMgr.setTopWindows(windowIds);
      // close in opposite order to setTopWindows
      for (let i = windowIds.length - 1; i >= 0; i--) {
         unityMgr.closeWindow(windowIds[i]);
      }
   };

   public moveResizeWindow = (wmksKey, windowId, x, y, width, height) => {
      const unityMgr = this.getMgr(wmksKey);
      if (!unityMgr) {
         return;
      }

      const unityWindow = unityMgr.windows[windowId];
      if (unityWindow) {
         unityMgr.moveResizeWindow(windowId, x, y, width, height);
      }
   };

   /**
    * unityService.toggleWindow
    *
    * Toggle unity window's status
    *
    * @param wmksKey the wmksSession identifier.
    * @param windowId windowId to be toggled.
    */
   public toggleWindow = (wmksKey: string, windowId: string) => {
      let unityMgr = this.getMgr(wmksKey),
         unityWindow;

      if (!unityMgr) {
         return;
      }

      unityWindow = unityMgr.windows[windowId];
      if (unityWindow) {
         if (unityWindow.attributes[Unity.UnityWindowAttribute.WINDOW_ATTR_MINIMIZED]) {
            Logger.info("Restoring minimized Unity window: " + windowId, Logger.UNITY);
            unityMgr.unminimize(windowId);
         } else {
            Logger.info("Activating Unity window: " + windowId, Logger.UNITY);
            unityMgr.setTopWindows([windowId]);
         }
      }
   };

   public minimizeWindow = (wmksKey: string, windowId: string) => {
      let unityMgr = this.getMgr(wmksKey),
         unityWindow;

      if (!unityMgr) {
         return;
      }

      unityWindow = unityMgr.windows[windowId];
      if (unityWindow) {
         if (!unityWindow.attributes[Unity.UnityWindowAttribute.WINDOW_ATTR_MINIMIZED]) {
            Logger.info("Minimize Unity window: " + windowId, Logger.UNITY);
            unityMgr.minimize(windowId);
         }
      }
   };

   /**
    * unityService.unminimizeWindow
    */
   public unminimizeWindow = (wmksKey: string, windowId: string) => {
      let unityMgr = this.getMgr(wmksKey),
         unityWindow;

      if (!unityMgr) {
         return;
      }

      unityWindow = unityMgr.windows[windowId];
      if (unityWindow) {
         if (unityWindow.attributes[Unity.UnityWindowAttribute.WINDOW_ATTR_MINIMIZED]) {
            Logger.info("Minimize Unity window: " + windowId, Logger.UNITY);
            unityMgr.unminimize(windowId);
         }
      }
   };

   public maximizeWindow = (wmksKey: string, windowId: string) => {
      if (this.getMgr(wmksKey)) {
         this.getMgr(wmksKey).maximize(windowId);
      }
   };

   public unmaximizeWindow = (wmksKey: string, windowId: string) => {
      if (this.getMgr(wmksKey)) {
         this.getMgr(wmksKey).unmaximize(windowId);
      }
   };

   public trayIconStartUpdate = (wmksKey: string) => {
      const unityMgr = this.getMgr(wmksKey);
      if (!unityMgr) {
         return;
      }
      unityMgr.trayIconStartUpdate(
         () => {
            Logger.info("TrayIcon.startUpdates has been sent.", Logger.UNITY);
         },
         (error) => {
            Logger.info("Failed to send TrayIcon.stratUpdate with error: " + error, Logger.UNITY);
         }
      );
   };

   public trayIconStopUpdate = (wmksKey: string) => {
      const unityMgr = this.getMgr(wmksKey);
      if (!unityMgr) {
         return;
      }
      unityMgr.trayIconStopUpdate(
         () => {
            Logger.info("TrayIcon.stopUpdates has been sent.", Logger.UNITY);
         },
         (error) => {
            Logger.info("Failed to send TrayIcon.stopUpdate with error: " + error, Logger.UNITY);
         }
      );
   };

   /**
    * unityService.getAppAttr
    *
    * Send get app name and icons command to unityMgr.
    * Once we get the information, we will fire appAttrChanged event
    * to its consumer.
    *
    * @param wmksKey    the wmksSession identifier.
    * @param windowPath window path for identifying application.
    * @param execPath   exec path for identifying application.
    * @param windowId   window id for the first unity window of this
    *    application.
    */
   public getAppAttr = (wmksKey, windowPath, execPath, windowId) => {
      const unityMgr = this.getMgr(wmksKey);
      if (!unityMgr) {
         return;
      }

      unityMgr.getExecInfoHash(
         execPath,
         (execInfoHash) => {
            Logger.info("ExecPath " + execPath + " has exec info hash: " + execInfoHash, Logger.UNITY);
         },
         (error) => {
            Logger.info(
               "Failed to retrieve exec info hash for ExecPath " + execPath + " with error: " + error,
               Logger.UNITY
            );
         }
      );

      this.trayIconStartUpdate(wmksKey);

      unityMgr.getBinaryInfo(
         windowPath,
         function (name, images) {
            let iconSrc = "",
               iconIndex = -1;
            Logger.info(
               "Window path " + windowPath + ' has name "' + name + '" and ' + images.length + " icons.",
               Logger.UNITY
            );
            if (images.length > 0) {
               iconIndex = AB.getAppIconIndex(images, 48);
               if (iconIndex !== -1) {
                  iconSrc = AB.bgraMapToPNG(images[iconIndex].bgra, images[iconIndex].width, images[iconIndex].height);
               }
            }

            if (iconSrc !== "") {
               if (unityMgr.ops) {
                  unityMgr.ops.onAppAttrChanged(windowPath, execPath, name, iconSrc);
                  unityMgr.ops.onVisibilityChanged(true);
               }
            } else {
               /*
                * On failure to retrieve or convert the icon to PNG, we use a
                * fallback RPC to try and retrieve it from the running instance.
                * The fallback is susceptible to a race condition if the server
                * application is changing icons when the RPC arrives, giving us
                * an outdated icon. However we should only need to send the RPC
                * rarely, and the race condition should be even rarer, so we can
                * reasonably ignore this case.
                *
                * PNG conversion will fail if canvas is not supported (IE9), so
                * the RPC will let us continue to support IE9.
                */
               this.getIconDataHelper(wmksKey, windowId, (data) => {
                  const iconSrc = data ? data : AB.ICONS.BIG_DEFAULT_ICON;
                  if (unityMgr.ops) {
                     unityMgr.ops.onAppAttrChanged(windowPath, execPath, name, iconSrc);
                     unityMgr.ops.onVisibilityChanged(true);
                  }
               });
            }
         },
         function (error) {
            Logger.info(
               "Failed to retrieve binary info for window path " + windowPath + " with error: " + error,
               Logger.UNITY
            );

            /**
             * Some special app will not be able to provide unity.get.binary.info,
             * but we still need to show the app, see bug 2227085.
             */
            if (unityMgr.ops) {
               unityMgr.ops.onVisibilityChanged(true);
            }
         }
      );
   };

   public isUnityRunning = (key: string) => {
      const unityMgr = this.getMgr(key);
      if (unityMgr) {
         return unityMgr.isOn && !unityMgr.paused;
      } else {
         return false;
      }
   };

   public activePerfTracker = (perfTrackerItem) => {
      const unityMgr = this.getMgr(perfTrackerItem.wmksKey);
      if (unityMgr) {
         unityMgr.sendEventToPerfTracker("activeWindow", perfTrackerItem);
      }
   };

   public leftSingleClickSystemTray = (item) => {
      const unityMgr = this.getMgr(item.wmksKey);
      if (unityMgr) {
         unityMgr.sendEventToPerfTracker("leftSingleClickSystemTray", item);
      }
   };

   public showPerfTrackerContextMenu = (perfTrackerItem) => {
      const unityMgr = this.getMgr(perfTrackerItem.wmksKey);
      if (unityMgr) {
         unityMgr.sendEventToPerfTracker("showContextMenu", perfTrackerItem);
      }
   };

   public updateEmptySession = (wmksKey, isAdd) => {
      const unityMgr: Unity.Mgr = this.getMgr(wmksKey);
      /* istanbul ignore if */
      if (!unityMgr) {
         return;
      }

      if (unityMgr.windowCount === 1 && isAdd) {
         // Unity window number is changed from 0 to 1. It is not empty.
         unityMgr.wmksSession.setEmpty(false);
      } else if (unityMgr.windowCount === 0 && !isAdd) {
         // Unity window number is changed from 1 to 0. It is empty now.
         unityMgr.wmksSession.setEmpty(true);
      }
   };

   /**
    * getIconDataHelper
    *
    * Send request of getting proper icon information to unityMgr.
    * We will try 32*32 at first and then fallback to 16*16.
    * And call onDone callback if callback is set by user.
    *
    * @param wmksKey     unique identifier of wmksSession.
    * @param windowId    window id of unity window.
    * @param onDone      callback called when requesting is finished.
    */
   public getIconDataHelper = (wmksKey, windowId, onDone) => {
      let iconSizeSets = [32, 16],
         iconSrc = "",
         iconSize,
         getIconData;

      const unityMgr = this.getMgr(wmksKey);
      if (!unityMgr) {
         return;
      }
      Logger.info("Window with id " + windowId + " has a new icon available.", Logger.UNITY);

      getIconData = () => {
         if (iconSizeSets.length > 0) {
            iconSize = iconSizeSets.shift();
            unityMgr.getIconData(
               windowId,
               iconSize,
               (bgra) => {
                  Logger.debug(
                     "Retrieved " + iconSize + " icon with length: " + bgra.length + " for window " + windowId,
                     Logger.UNITY
                  );
                  iconSrc = "data:image/png;base64," + window.btoa(String.fromCharCode.apply(null, bgra));
                  if (onDone) {
                     onDone(iconSrc);
                  }
               },
               (error) => {
                  Logger.debug(
                     "Failed to retrieve icon data for window " +
                        windowId +
                        " width " +
                        iconSize +
                        " with error: " +
                        error,
                     Logger.UNITY
                  );
                  getIconData();
               }
            );
         } else {
            if (onDone) {
               onDone(iconSrc);
            }
         }
      };

      getIconData();
   };

   /**
    * onWmksSessionConnecting
    *
    * If this is an app session, create an UnityMgr instance.
    * Otherwise, do nothing.
    *
    * @params wmksKey               the key to identify this unique
    *    wmksSession.
    * @params isApplicationSession  if this is an application session.
    * @params vdpService            vdpService associated with
    *    wmksSession.
    */
   protected _onWmksSessionConnecting = (session: BlastWmks) => {
      let unityMgr;

      if (!session.isApplicationSession) {
         return;
      }

      const handler = new UnityHandler(session.key, this.subject$, this);
      unityMgr = new Unity.Mgr(session, handler);
      handler.unityInst = unityMgr;
      this.unityMgrMap.set(session.key, unityMgr);
      unityMgr.ops.onVisibilityChanged(false);
   };

   /**
    * onWmksSessionConnected
    *
    * On WMKS connection, notify listeners if unity is not yet ready (off
    * or paused).
    *
    * @params wmksKey               the key to identify this unique
    *    wmksSession.
    */
   protected _onWmksSessionConnected = (session: BlastWmks) => {
      const unityMgr = this.getMgr(session.key);

      if (unityMgr && (!unityMgr.isOn || unityMgr.paused)) {
         this.subject$.next(new UnityPaused(session.key));
      }
   };

   /**
    * onWmksSessionDisconnected
    *
    * Cleanup the specific wmksSession.
    *
    * @params wmksKey the key to identify this unique wmksSession.
    */
   protected _onWmksSessionDisconnected = (session: BlastWmks) => {
      this._destroyUnityMgr(session.key);
   };

   /**
    * onWmksSessionRemoved
    *
    * Respond to a session removal by cleaning up the associated unityMgr.
    *
    * @params wmksKey: the key to identify this unique wmksSession
    */
   protected _onWmksSessionRemoved = (session: BlastWmks) => {
      this._destroyUnityMgr(session.key);
   };

   /**
    * destroyUnityMgr
    *
    * Reset the internal status of wmksService.
    *
    * @param wmksKey the wmksSession identifier.
    */
   private _destroyUnityMgr = (wmksKey) => {
      let unityMgr = this.getMgr(wmksKey);
      if (!unityMgr) {
         return;
      }
      unityMgr.ops = null;
      this.unityMgrMap.delete(wmksKey);
      unityMgr = null;
      this.subject$.next(new UnityDestroyMsg(wmksKey));
   };

   /**
    * Send the message for browse URL message.
    */
   public browseURL = (wmksKey: string, url: string, onDone?: Function, onAbort?: Function) => {
      const unityMgr: Unity.Mgr = this.getMgr(wmksKey);
      if (!unityMgr) {
         return;
      }

      unityMgr.browseURL(url, onDone, onAbort);
   };
}
