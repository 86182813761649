/**
 * *****************************************************
 * Copyright 2024 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { ParentTabService } from "./parent-tab.service";
import { ChildTabService } from "./child-tab.service";
import { TabUtilsService } from "./utils/tabUtil.service";
import { PostMessageListenerService } from "./utils/postmessage";

@NgModule({
   declarations: [],
   providers: [ParentTabService, ChildTabService, TabUtilsService, PostMessageListenerService]
})
export class TabsModule {}
