/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injector } from "@angular/core";
import { BlastWmks } from "../common/blast-wmks.service";
import { BlastOption } from "./blast-option";
import { isMKSSessionClosedNormally, webMksReconnectFailure } from "./wmks-error-handler";
import { clientUtil } from "@html-core";

export class Html5BlastSessionInstance extends BlastWmks {
   constructor(injector: Injector, opt: BlastOption) {
      super(injector, opt);
   }

   public onInitialize() {}

   public reconnectHandler = (code: number, closeReason: number) => {
      if (this.shouldTryCert()) {
         this.logger.info("showing cert page");
         this.tryAcceptSelfSignCert(code);
      } else if (this.isIdleTimeout()) {
         this.idleSessionService.onAppIdleTimeout(this.key);
         this.destroy();
      } else if (isMKSSessionClosedNormally(code, closeReason)) {
         if (closeReason === 30) {
            // VDPCONNECT_SERVER_SHADOW_SESSION_ENDED, code =1000. closeReason 30.
            this.logger.info("show shadow session disconnect error");
            this._openDisconnectedDialog(false, closeReason);
         } else {
            this.logger.info("show general disconnect error");
            this._openDisconnectedDialog(false);
         }
      } else if (webMksReconnectFailure(code)) {
         /*
          * We gave up trying to reconnect (3000).
          */
         this.logger.info("show general disconnect error");
         this._openDisconnectedDialog(false, code);
      } else if (this._reconnectMustFailure(code, closeReason)) {
         this.logger.info("show general disconnect error, since the device sleep for too long");
         this._openDisconnectedDialog(false);
      } else if (this.canReconnect(code, closeReason)) {
         this.logger.info("try one more reconnection");
         /*
          * To fix bug 2880137, after launch a desktop/application
          * Disconnected dialog will flash when refresh browser (only in Firefox)
          */
         let waitTime = 0;
         if (clientUtil.isFirefox() && code === 1001) {
            waitTime = 500;
         }
         setTimeout(() => {
            if (
               !this.modalDialogService.isDialogOpen(this._disconnectedDialogId) &&
               this._runningReconnectInBackGround !== true
            ) {
               this._openDisconnectedDialog(true);
            } else {
               this.logger.info("Don't need to pop up the disconnect dialog");
            }
         }, waitTime);
         this._startReconnection();
      } else {
         this.logger.info(
            "default disconnect case, might be caused by " + "cancel in reconnect or unknown disconnect case"
         );
         this._openDisconnectedDialog(false);
      }
   };
}
