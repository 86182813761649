/**
 * ******************************************************
 * Copyright (C) 2014-2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * signal.js --
 *
 * Make arbitrary objects follow the observer pattern.
 */

import Logger from "../../../core/libs/logger";

export class signal {
   private signals: Map<string, Function[]>;

   constructor() {
      this.signals = new Map<string, Function[]>();
   }

   public emit = (msg: string, ...args: any[]): void => {
      if (this.signals.has(msg)) {
         for (const handler of this.signals.get(msg)) {
            if (handler && typeof handler === "function") {
               handler(...args);
            }
         }
      }
   };

   public addEventListener = (msg: string, handler: Function): void => {
      if (this.signals.has(msg)) {
         if (!this.signals.get(msg).includes(handler)) {
            this.signals.get(msg).push(handler);
         }
      } else {
         Logger.info("signal message " + msg + " is not existed");
      }
   };

   public removeEventListener = (msg: string, handler: Function): void => {
      if (this.signals.has(msg)) {
         const handlers = this.signals.get(msg);
         if (handlers.includes(handler)) {
            const loc = handlers.indexOf(handler);
            handlers.slice(loc, 1);
         }
      }
   };

   public addSignal = (msg: string): void => {
      if (!this.signals.has(msg)) {
         this.signals.set(msg, []);
      } else {
         Logger.info("signal message " + msg + " already existed");
      }
   };

   // TODO: Remove this after migrating to Typescript. we should use extends.
   //  xxxx extends Signal
   static makeObservable = (object: any): void => {
      const agent = new signal();
      object.addEventListener = agent.addEventListener;
      object.removeEventListener = agent.removeEventListener;
      object.emit = agent.emit;
      object.addSignal = agent.addSignal;
   };
}
