/**
 * ******************************************************
 * Copyright (C) 2017-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { LargeInteger } from "./large-integer";
import { StructWithMarker } from "./struct-with-marker";
import Logger from "../../../../core/libs/logger";
/**
 * stream-reader.js --
 *
 * usage:
 * ------let streamReader = protocolUtil.getReader(typedArrayFromVVC);
 * ------let component = streamReader.getUint16();
 * ------let packetId = streamReader.getUint16();
 * ------let length = streamReader.getUint32();
 * ------let name = streamReader.getString(length);
 *
 * Don't support signed(negative) number for now.
 */
export class StreamReader extends StructWithMarker {
   private offset = 0;

   constructor(
      private data,
      private isBigEndian = false,
      allowStructAlign = false
   ) {
      super(allowStructAlign);
   }
   /**
    * Will loss data if number is too large for JS, use struct for that case.
    */
   public getBytes = (bytes, isSignedInteger = false) => {
      if (this.data.length < this.offset + bytes) {
         this.logger.trace("too less steam to read, default as 0");
         return 0;
      }
      let number = 0;
      if (this.isBigEndian) {
         for (let i = 0; i < bytes; i++) {
            number += this.data[this.offset + i] * (1 << ((bytes - 1 - i) * 8));
         }
      } else {
         for (let i = 0; i < bytes; i++) {
            number += this.data[this.offset + i] * (1 << (i * 8));
         }
      }
      this.shiftOffset(bytes);
      if (isSignedInteger) {
         let signedNumber;
         if (bytes === 4) {
            signedNumber = new Int32Array(new Uint32Array([number]).buffer)[0];
         } else if (bytes === 2) {
            signedNumber = new Int16Array(new Uint16Array([number]).buffer)[0];
         } else if (bytes === 1) {
            signedNumber = new Int8Array(new Uint8Array([number]).buffer)[0];
         } else {
            throw "not support reading int64";
         }
         return signedNumber;
      }
      return number;
   };

   public getUint8 = () => {
      return this.getBytes(1);
   };

   public getUint16 = () => {
      return this.getBytes(2);
   };

   public getUint32 = () => {
      return this.getBytes(4);
   };

   // JS number might loss some detail
   public getUint64 = () => {
      return this.getBytes(8);
   };

   // accurate way of reading Uint64
   public getLargeInteger = () => {
      return new LargeInteger().setByParts(this.getBytes(4), this.getBytes(4), this.isBigEndian);
   };

   public getRest = () => {
      const rest = this.data.subarray(this.offset, this.data.length);
      this.shiftOffset(this.data.length - this.offset);
      return rest;
   };

   public getBasic = (type) => {
      if (typeof type === "number") {
         return this.getBytes(type);
      } else if (type === "ASCII") {
         return String.fromCharCode(this.getBytes(1));
      } else if (type === "Unicode") {
         //UTF-16
         return String.fromCharCode(this.getBytes(2));
      } else if (type === "LARGE_INTEGER") {
         return this.getLargeInteger();
      } else if (type === "INT32") {
         return this.getBytes(4, true);
      } else if (type === "INT16") {
         return this.getBytes(2, true);
      } else if (type === "INT8") {
         return this.getBytes(1, true);
      } else {
         throw "unknown basic type: " + type;
      }
   };

   public getOffset = () => {
      return this.offset;
   };

   /**
    * seek the pointer to offset
    * @return {[type]} [description]
    */
   public seek = (position) => {
      if (!(position >= 0 && position < this.data.length)) {
         throw "Can't seek reader out of data range";
      }
      this.offset = position;
      this.randomAccessed = true;
   };

   public getStringByBytes = (stringBytes, charBytes) => {
      //let stringLength = stringBytes;
      const stringLength = stringBytes; // not do /charBytes, since it should be done in definition
      const chars = new Array(stringLength - 1);
      for (let i = 0; i < stringLength - 1; i++) {
         chars[i] = String.fromCharCode(this.getBytes(charBytes));
      }
      if (this.getBytes(charBytes) !== 0) {
         this.logger.warning("the end of string is not /0");
      }
      return chars.join("");
   };

   public getUnicode = (stringBytes) => {
      return this.getStringByBytes(stringBytes, 2);
   };

   public getString = (stringBytes) => {
      return this.getStringByBytes(stringBytes, 1);
   };

   public getStream = (length) => {
      if (length === "UNLIMITED" || length === undefined) {
         return this.getRest();
      }
      if (this.data.length < this.offset + length) {
         this.logger.info("too less stream data to read");
         return null;
      }
      const steamData = this.data.subarray(this.offset, this.offset + length);
      this.shiftOffset(length);
      return steamData;
   };
   private shiftOffset = (shift) => {
      this.offset += shift;
      if (this.randomAccessed) {
         return;
      }
      this.shiftOnCurrentMarker(shift);
   };

   public hasRemainStream = (): boolean => {
      if (this.data.length > this.offset) {
         Logger.debug("has remain Stream left for parsing of length " + (this.data.length - this.offset));
         return true;
      }
      Logger.trace("end of message reached");
      return false;
   };

   public getAlignTo = (size) => {
      if (!this.allowStructAlign) {
         Logger.error("doesn't support bit alignment with struct align disabled");
         return null;
      }
      if (this.randomAccessed) {
         Logger.error("doesn't support bit alignment with random access");
         return null;
      }
      const activeMarker = this.getActiveMarker();
      if (!activeMarker) {
         Logger.error("failed to find marker");
         return null;
      }
      if (size < activeMarker.innerOffset) {
         Logger.warning("overflow detected for data alignment");
         return null;
      }
      const drawUpSize = size - activeMarker.innerOffset;
      const segment = this.data.subarray(this.offset, this.offset + drawUpSize);
      this.shiftOffset(drawUpSize);
      return segment;
   };

   public getData = (): Uint8Array => {
      return new Uint8Array(this.data);
   };
}
