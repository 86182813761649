/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * submitAuthInfoCommand.js --
 *
 * Submit the user's credential and authenticate the user against the broker
 *    1. DoSubmitAuthentication
 *
 */

import { globalArray } from "../jscdkClient";
import Router from "../controllers/router";
import Logger from "../../../core/libs/logger";
import DoSubmitAuthenticationHandler from "../controllers/doSubmitAuthenticationHandler";
import GetTunnelConnectionHandler from "../controllers/getTunnelConnectionHandler";

export default function SubmitAuthInfoCommand() {}

/**
 * Send the get-configuration xml.
 */

SubmitAuthInfoCommand.prototype.resendGetConfiguration = function () {
   let handlerList,
      getConfigurationObject = globalArray["get-configuration"],
      router = globalArray["router"];
   if (getConfigurationObject) {
      getConfigurationObject.setRequestXML();
      handlerList = getConfigurationObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};

/*
 * submitAuthInfoCommand.js
 *
 * Invoked by JSCDKController
 *
 * @param submitAuthInfoAction[in]
 *        - its property 'method' has the value 'SubmitAuthInfo'
 */
SubmitAuthInfoCommand.prototype.execute = function (submitAuthInfoAction) {
   let handlerList,
      authInfoObject = globalArray["do-submit-authentication"],
      router = globalArray["router"],
      getTunnelConnObject = globalArray["get-tunnel-connection"],
      getConfigurationObject = globalArray["get-configuration"];

   if (!router) {
      Logger.info("router is null.");

      router = new Router();
      globalArray[router.name] = router;
   }

   if (!submitAuthInfoAction.type) {
      Logger.error("Authentication type is empty.");
      return;
   }

   //Send get-configuration xml before auth.
   if (getConfigurationObject.resendGetConfiguration) {
      Logger.info("Resend get-configuration.");
      this.resendGetConfiguration();
      getConfigurationObject.resendGetConfiguration = false;
      return;
   }

   // create message handler object
   if (!authInfoObject) {
      authInfoObject = new DoSubmitAuthenticationHandler();
      globalArray[authInfoObject.messageName] = authInfoObject;
      globalArray[authInfoObject.responseTag] = authInfoObject;
   } else {
      authInfoObject.resetData();
   }
   authInfoObject.setAuthAction(submitAuthInfoAction);
   authInfoObject.setReAuthFlag(submitAuthInfoAction.reAuth);
   authInfoObject.setAllowDataSharing(submitAuthInfoAction.allowDataSharing);
   authInfoObject.setDesktopReAuthFlag(submitAuthInfoAction.isDesktopReAuth);

   if (submitAuthInfoAction.reAuth) {
      if (!getTunnelConnObject) {
         Logger.debug("error: getTunnelConnObject do not exist when reAuth is true");
      }
   } else {
      if (!getTunnelConnObject) {
         getTunnelConnObject = new GetTunnelConnectionHandler();
         globalArray[getTunnelConnObject.messageName] = getTunnelConnObject;
         globalArray[getTunnelConnObject.responseTag] = getTunnelConnObject;
      } else {
         getTunnelConnObject.resetData();
      }
   }

   handlerList = authInfoObject.composeHandlerList();
   router.postMessage(handlerList);
   authInfoObject.clearCreds();
};
