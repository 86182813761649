<h3 class="modal-title dialog-title">{{ 'ADVANCED_SETTINGS' | i18nv2 }}</h3>
<div class="modal-body  dialog-content">
    <div *ngFor="let switch of toggleSwitches;">
        <div *ngIf='switch.isSupported' [hidden]="killSwitches[switch.id] === false" [ngClass]="{grayout:isSwitchDisabled(switch)}">
            <div class="settings-container clearfix" >
               <div *ngIf="switch.id!=='trusted_apps' && switch.id!=='file_association_default_app'" class="settings-toggle-container">
                  <div class="settings-toggle-container">
                     <div class="toggle-opt" tabindex="0" (keydown)="onKeypress($event)" role="button" [attr.aria-pressed]="switch.isEnabled">
                     <input class="tgl tgl-light" id="{{switch.id}}" type="checkbox" [hidden]="true" [(ngModel)]="switch.isEnabled" [disabled]="isSwitchDisabled(switch)" (ngModelChange)="switch.toggle()">
                     <label class="tgl-btn unselectable hidecontentlabel" for="{{switch.id}}">{{switch.label}}</label>
                     </div>
                  </div>
               </div>
               <div class="add-trusted-apps-container" *ngIf="switch.id==='trusted_apps'" id="{{switch.id}}">
                  <button type="button" class="modal-button-base modal-button-blue modal-button-reset" role="button" id="select_trusted_apps_btn" [ngClass]="{disable: switch.isSettingGrayOut}" (click)="showAddTrustedAppsDialog()" [disabled]="switch.isSettingGrayOut">{{'ADD_BUTTON' | i18nv2}}</button>
               </div>
               <div class="change-default-fa-app-container" *ngIf="switch.id==='file_association_default_app'" id="{{switch.id}}">
                  <button type="button" class="modal-button-base modal-button-blue modal-button-reset" role="button" id="change_default_apps_btn" [ngClass]="{disable: switch.isSettingGrayOut}" (click)="showDefaultAppDialog()" [disabled]="switch.isSettingGrayOut">{{'CHANGE_BUTTON' | i18nv2}}</button>
               </div>
               <span class="settings-label">{{switch.label}}</span>
               <span class="settings-text" [hidden]="!switch.text" [ngClass]="switch.id==='file_association_default_app'? 'extension-name':'null'">{{switch.text}}</span>
               <div class="log_level" *ngIf="switch.id==='collect_log' && switch.isEnabled" (click)="changeLogLevel()">
                  <input type="radio" id="info" name="log-level" value="info">{{'log_level_info' | i18nv2}}
                  <input type="radio" id="debug" name="log-level" value="debug">{{'log_level_debug' | i18nv2}}
                  <input type="radio" id="trace" name="log-level" value="trace">{{'log_level_trace' | i18nv2}}
              </div>
              <span class="log-path-text settings-text" *ngIf="switch.id==='collect_log'" (click)="changeLogFile()">{{logPathInfo}}</span>
               <div [ngClass]="switch.id==='trusted_apps'? 'trust-app-separator': 'settings-separator'" [hidden]='!switch.isSupported || killSwitches[switch.id] === false || isTheLastActiveItem(switch.id)'></div>
            </div>
      </div>
   </div>
</div>
<div class="modal-footer dialog-button-row">
   <button type="button" id="closeSettingsBtn" class="modal-button-base modal-button-right modal-button-blue" (click)="closeModal()" ngbAutofocus>
      {{ 'CLOSE' | i18nv2 }}
   </button>
</div>