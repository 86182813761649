/**
 * ******************************************************
 * Copyright (C) 2016-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { AfterViewInit, Component } from "@angular/core";
import { WmksService } from "./common/wmks.service";
import { JscdkWrapper } from "../common/jscdk/jscdk-wrapper";
import { ClientModeService } from "../common/service/client-mode.service";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { TitanLaunchedItem } from "../../titan/services/titan-constant";
import { BusEvent, EventBusService } from "../../core/services/event";
import { DesktopRouterParameter } from "./desktop-router.interface";
import { ENTITLE_TYPE } from "@html-core";

/**
 * desktop.component.ts --
 *
 * The center controller for desktop module.
 *
 */

@Component({
   selector: "html5-desktop",
   templateUrl: "./desktop.component.html"
})
export class DesktopComponent implements AfterViewInit {
   public item: DesktopRouterParameter = null;

   constructor(
      private wmksService: WmksService,
      private jscdkWrapper: JscdkWrapper,
      private clientModeService: ClientModeService,
      private router: Router,
      private eventBusService: EventBusService
   ) {
      // Change the TransitionDataModel to navigation.extras.state for HTML5 client.
      this.clientModeService.setWmksServiceAndJSCDKWrapper(this.wmksService, this.jscdkWrapper);
      this.getItemFromNavigationState();
      this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((e: NavigationStart) => {
         this.getItemFromNavigationState();
      });
      this.clientModeService.switchToDesktopMode();
   }

   public ngAfterViewInit() {
      if (this.item && this.item.type === "ENTITLEMENT") {
         this.eventBusService.dispatch(
            new BusEvent.PendingLaunchOps(
               this.item.entitlement.id,
               this.item.entitlement.isApp,
               this.item.entitlement.dspec
            )
         );
      } else if (this.item && this.item.type === "SESSION_RESUMPTION") {
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
         this.eventBusService.dispatch(new BusEvent.ReconnectAllSessionsMsg(false, null));
      }
      this.item = null;
   }

   private getItemFromNavigationState() {
      const navigation = this.router.getCurrentNavigation();
      if (navigation.extras.state && navigation.extras.state.item) {
         this.item = navigation.extras.state.item as DesktopRouterParameter;
      }
   }
}
