<li >
   <button (click)="connectToServer($event);">{{ 'dialog_connect_broker' | i18nv2 }}
   </button>
</li>
<br>
<li>
   <button (click)="editServer($event);">{{ 'dialog_edit_broker' | i18nv2 }}
   </button>
</li>
<br>
<li>
   <button (click)="removeServer($event);">{{ 'dialog_delete_server' | i18nv2 }}
   </button>
</li>