/** @format */

import { AnyARecord } from "dns";

/*********************************************************
 * Copyright (C) 2020-2022 VMware, Inc. All rights reserved.
 *********************************************************
 *
 * @format
 */
export namespace Monitor {
   export class MonitorMessage {
      public uid: string;
      public origin: string;
      constructor(public msgType: string) {}
   }

   export class InitMsg extends MonitorMessage {
      public static readonly TYPE = "init";
      constructor(
         public id: string,
         public translationMap: any
      ) {
         super(InitMsg.TYPE);
      }
   }

   export class UpdateMaxRegionMsg extends MonitorMessage {
      public static readonly TYPE = "updateUnmaxableRegions";
      constructor(public regions: string) {
         super(UpdateMaxRegionMsg.TYPE);
      }
   }

   export class HeartBeatMsg extends MonitorMessage {
      public static readonly TYPE = "monHeartbeat";
      constructor() {
         super(HeartBeatMsg.TYPE);
      }
   }

   export class StartDisplayMsg extends MonitorMessage {
      public static readonly TYPE = "startDisplay";
      constructor(
         public base: WMKPoint,
         public model: NormalizationParam,
         public factor: number
      ) {
         super(StartDisplayMsg.TYPE);
      }
   }

   export class DisplayMsg extends MonitorMessage {
      public static readonly TYPE = "display";
      constructor(
         public uid: string,
         public vncRects: any
      ) {
         super(DisplayMsg.TYPE);
      }
   }

   // the message paste in agent (copy from client) for chrome client
   export class PasteMsg extends MonitorMessage {
      public static readonly TYPE = "pasteMsg";
      public text: string;
      public html: string;
      constructor(content: { text: string; html: string }) {
         super(PasteMsg.TYPE);
         this.text = content.text;
         this.html = content.html;
      }
   }

   // the message copy from agent (paste in client) for chrome client
   export class CopyMsg extends MonitorMessage {
      public static readonly TYPE = "copyMsg";
      public text: string;
      public html: string;
      constructor(content: { text: string; html: string }) {
         super(CopyMsg.TYPE);
         this.text = content.text;
         this.html = content.html;
      }
   }

   export class ClipboardGPOSettingMsg extends MonitorMessage {
      public static readonly TYPE = "clipboardGPOSettingMsg";
      public copyEnabled: boolean;
      public pasteEnabled: boolean;
      constructor(content: { copyEnabled: boolean; pasteEnabled: boolean }) {
         super(ClipboardGPOSettingMsg.TYPE);
         this.copyEnabled = content.copyEnabled;
         this.pasteEnabled = content.pasteEnabled;
      }
   }

   export class ClipboardContentHashValueMsg extends MonitorMessage {
      public static readonly TYPE = "clipboardContentHashValueMsg";
      public clipboardContentHashValue: any;
      constructor(content: { clipboardContentHashValue: any }) {
         super(ClipboardContentHashValueMsg.TYPE);
         this.clipboardContentHashValue = content.clipboardContentHashValue;
      }
   }

   export class SetCursorMsg extends MonitorMessage {
      public static readonly TYPE = "setCursor";
      constructor(
         public url: any,
         public width: any,
         public height: any,
         public hotx: any,
         public hoty: any
      ) {
         super(SetCursorMsg.TYPE);
      }
   }

   export class ShowDisplay extends MonitorMessage {
      public static readonly TYPE = "showDisplay";
      constructor() {
         super(ShowDisplay.TYPE);
      }
   }

   export class HideDisplay extends MonitorMessage {
      public static readonly TYPE = "hideDisplay";
      constructor() {
         super(HideDisplay.TYPE);
      }
   }

   export class AdjustDisplay extends MonitorMessage {
      public static readonly TYPE = "adjustDisplay";
      constructor(
         public base: WMKPoint,
         public model: NormalizationParam,
         public factor: number
      ) {
         super(AdjustDisplay.TYPE);
      }
   }

   export class AdjustDisplayDone extends MonitorMessage {
      public static readonly TYPE = "adjustDisplayDone";
      constructor() {
         super(AdjustDisplayDone.TYPE);
      }
   }

   export class ForceCloseMsg extends MonitorMessage {
      public static readonly TYPE = "forcibleClose";
      constructor() {
         super(ForceCloseMsg.TYPE);
      }
   }

   // Extended -> Main

   export class ReadyMsg extends MonitorMessage {
      public static readonly TYPE = "ready";
      constructor(public uid: string) {
         super(ReadyMsg.TYPE);
      }
   }

   export class LogMsg extends MonitorMessage {
      public static readonly TYPE = "log";
      constructor(
         public logString: string,
         public logLevel: any
      ) {
         super(LogMsg.TYPE);
      }
   }

   export class CloseMsg extends MonitorMessage {
      public static readonly TYPE = "close";
      constructor() {
         super(CloseMsg.TYPE);
      }
   }

   export class InitDoneMsg extends MonitorMessage {
      public static readonly TYPE = "initDone";
      constructor() {
         super(InitDoneMsg.TYPE);
      }
   }

   export class SetUnMaxRegion extends MonitorMessage {
      public static readonly TYPE = "setUnmaxableRegion";
      constructor(public region: DisplayBaseInfo) {
         super(SetUnMaxRegion.TYPE);
      }
   }

   export class ReadyToDisplay extends MonitorMessage {
      public static readonly TYPE = "readyToDisplay";
      constructor(public region: DisplayBaseInfo) {
         super(ReadyToDisplay.TYPE);
      }
   }

   export class ConfirmedCloseMsg extends MonitorMessage {
      public static readonly TYPE = "confirmedClose";
      constructor() {
         super(ConfirmedCloseMsg.TYPE);
      }
   }

   export class RenderDoneMsg extends MonitorMessage {
      public static readonly TYPE = "renderingDone";
      constructor(public indices: any) {
         super(RenderDoneMsg.TYPE);
      }
   }

   //wmks event
   export class BlurMsg extends MonitorMessage {
      public static readonly TYPE = "onBlur";
      constructor() {
         super(BlurMsg.TYPE);
      }
   }

   export class MouseButtonMsg extends MonitorMessage {
      public static readonly TYPE = "mouseButton";
      constructor(
         public position,
         public isDownEvent,
         public bmask
      ) {
         super(MouseButtonMsg.TYPE);
      }
   }

   export class TouchScreenMsg extends MonitorMessage {
      public static readonly TYPE = "touchScreen";
      constructor(
         public event,
         public positions
      ) {
         super(TouchScreenMsg.TYPE);
      }
   }

   export class MouseWheelMsg extends MonitorMessage {
      public static readonly TYPE = "mouseWheel";
      constructor(
         public event,
         public position
      ) {
         super(MouseWheelMsg.TYPE);
      }
   }

   export class MouseMove extends MonitorMessage {
      public static readonly TYPE = "mouseMove";
      constructor(public position) {
         super(MouseMove.TYPE);
      }
   }

   export class KeyEvent extends MonitorMessage {
      public static readonly TYPE = "keyEvent";
      constructor(
         public type,
         public event
      ) {
         super(KeyEvent.TYPE);
      }
   }

   export class UnityMsg extends MonitorMessage {
      public static readonly TYPE = "unityEvent";
      constructor(public data) {
         super(UnityMsg.TYPE);
      }
   }

   export class BorderMoveMsg extends MonitorMessage {
      public static readonly TYPE = "borderMove";
      constructor(public rect) {
         super(BorderMoveMsg.TYPE);
      }
   }

   export class BorderMouseUpMsg extends MonitorMessage {
      public static readonly TYPE = "borderMouseUp";
      constructor(
         public rect,
         public windowId
      ) {
         super(BorderMouseUpMsg.TYPE);
      }
   }

   export class BorderHideMsg extends MonitorMessage {
      public static readonly TYPE = "borderHide";
      constructor() {
         super(BorderHideMsg.TYPE);
      }
   }

   export class EnterFullscreen extends MonitorMessage {
      public static readonly TYPE = "enterFullscreen";
      constructor() {
         super(EnterFullscreen.TYPE);
      }
   }

   export class OnFocus extends MonitorMessage {
      public static readonly TYPE = "onFocus";
      constructor() {
         super(OnFocus.TYPE);
      }
   }

   export class ClipboardCapabilitiesOKMsg extends MonitorMessage {
      public static readonly TYPE = "ClipboardCapabilitiesOKMsg";
      constructor() {
         super(ClipboardCapabilitiesOKMsg.TYPE);
      }
   }
}
