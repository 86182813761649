/**
 * ******************************************************
 * Copyright (C) 2012-2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * cancelCurrentReuqestCommand.js --
 *
 * Cancel the current AJAX request for JSCDK.
 *
 */

import $ from "jquery";
import Logger from "../../../core/libs/logger";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import util from "../util";
import ConnectionRetryController from "../timer/connectionRetryController";

export default function CancelCurrentRequestCommand() {}

/**
 * Invoked by JSCDKController
 *
 * Abort the current AJAX request in router.
 */
CancelCurrentRequestCommand.prototype.execute = function (cancelRequestAction) {
   let doneAction,
      router = globalArray["router"],
      puzzleHandler = util.getObject(globalArray, "unauthPuzzleHandler"),
      connectionRetryController = globalArray["connection-retry-controller"];

   if (!connectionRetryController) {
      connectionRetryController = new ConnectionRetryController();
      globalArray[connectionRetryController.globalName] = connectionRetryController;
   }
   const skipableConnectionRetry = connectionRetryController.getSkipableStatus();
   connectionRetryController.onReconnectionError("Aborted");
   if (skipableConnectionRetry) {
      return;
   }

   doneAction = cancelRequestAction.doneAction;
   if (!!router && !!router.recentXhrObject && $.isFunction(router.recentXhrObject.abort)) {
      router.recentXhrObject.abort();
      Logger.debug("Cancel current request complete.");
   } else {
      /*
       * If router or XHR object is null, it means the current AJAX request
       * hasn't started. Set the ajaxWasAbort flag to tell router not to
       * fire the request.
       *
       * Update Mar. 20th, 2018: it is also possible that client is doing
       * client puzzle at this moment (and also has no ajax request sent out).
       * In this case, we should not set 'ajaxWasAborted' to true.
       *
       */
      if (!!puzzleHandler && puzzleHandler.isWorkerBusy) {
         Logger.debug("AJAX request hasn't started while client is " + "solving puzzle.");
      } else {
         router.ajaxWasAborted = true;
         Logger.debug("AJAX request hasn't started yet.");
      }
   }

   /*
    * Set UI to user's expected page for both correct and error cases.
    */
   if (doneAction) {
      // If it is anonymous login, we should stop the worker as well
      if (doneAction.name === "Unauthenticated") {
         if (puzzleHandler) {
            puzzleHandler.tearDownWorker();
         }
      }

      JSCDKSetUI(JSON.stringify(doneAction));
      Logger.debug("Done action: " + JSON.stringify(doneAction));
   } else {
      Logger.error("Done action is not set.");
   }
};
