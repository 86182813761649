/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * winCredsHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function WinCredsHandler() {
   let router;

   // member variables below
   this.messageName = "windows-password";
   this.messageText = "windows-password";
   this.cachedDomainUsername = "";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
WinCredsHandler.prototype = new MessageHandler();
// constructor
WinCredsHandler.constructor = WinCredsHandler;

/**
 * Set the request XML for Windows password authentication.
 *
 * @param domain [in] domain for the windows password authentication.
 * @param username [in] username for the windows password authentication.
 * @param password [in] password for the windows password authentication.
 */

WinCredsHandler.prototype.setRequestXML = function (domain, username, password) {
   let usernameText, domainText, passwordText, paramText, backslash;

   /**
    * Three login username format is supported:
    * 1. username        (broker supported)
    * 2. username@domain (broker supported)
    * 3. domain\username (broker not supported)
    *
    * We need to parse username and domain from #3
    */

   if (this.isDomainUsername(username)) {
      this.cachedDomainUsername = username;
      backslash = username.indexOf("\\");
      domain = username.substr(0, backslash);
      username = username.substr(backslash + 1);
   } else {
      this.cachedDomainUsername = "";
   }

   usernameText = util.createElement("name", "username");
   usernameText += util.createElement("values", util.createElement("value", username.trim()));
   domainText = util.createElement("name", "domain");
   if (domain) {
      domainText += util.createElement("values", util.createElement("value", domain.trim()));
   }
   passwordText = util.createElement("name", "password");
   passwordText += util.createElement("values", util.createElement("value", password));
   paramText = util.createElement("param", usernameText);
   paramText += util.createElement("param", domainText);
   paramText += util.createElement("param", passwordText);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", "windows-password");
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};

WinCredsHandler.prototype.isDomainUsername = function (username) {
   if (!username) {
      return false;
   }
   return username.indexOf("\\") !== -1;
};
