/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { StringUtils } from "@html-core";
import { VDP_CONSTS } from "./vdp-constants";

/**
 * Class to encode/decode RPCs in External Data Representation.
 * Reference:
 * https://www.ietf.org/rfc/rfc1832.txt
 *
 * @constructor
 */

export class VDPXdrBuffer {
   /**
    * The read position of the encoded buffer.
    * @private
    * @type {Integer}
    */
   private readPosition: any;
   /**
    * The encoded buffer.
    * @type {Uint8Array}
    */
   private data: any;
   /**
    * The current length of the contents in the buffer.
    * @type {Integer}
    */
   private length: number;

   constructor() {
      (this.readPosition = 0), (this.data = null);
      this.length = 0;
   }

   /**
    * Initializes the encoder with the given size of the buffer with the default as
    * 512 bytes.
    *
    * @param {Integer} size -  Initial size of the Uint8Array buffer.
    */
   public initEncoder = (size?: number): void => {
      this.length = 0;
      this.data = new Uint8Array(size || 512);
   };

   /**
    * Initializes the decoder with the given encoded buffer.
    *
    * @param {Uint8Array} encodedArrayBuffer - The XDR encoded buffer format to
    *                                          be decoded.
    * @returns {Boolean} true if successful.
    */
   public initDecoder = (encodedArrayBuffer: Uint8Array): boolean => {
      if (!(encodedArrayBuffer instanceof Uint8Array)) {
         return false;
      }

      this.data = encodedArrayBuffer;
      this.length = encodedArrayBuffer.length;
      this.readPosition = 0;

      return true;
   };

   /**
    * Returns the internal buffer contents splicing the unused buffer.
    *
    * @returns {Uint8Array} - The buffer.
    */
   public getData = (): Uint8Array => {
      let data = null;

      if (this.data) {
         data = this.data.subarray(0, this.length);
      }

      return data;
   };

   /**
    * Get the read position of XDRBuffer.
    * @returns {Integer} the read position of the XDRBuffer.
    */
   public getReadPosition = (): number => {
      return this.readPosition;
   };

   /**
    * Generic function to write a variable to the buffer.  Uses the
    * variable's type to determine which write function to use.
    *
    * @param value - The value to be written.
    * @return {Boolean} - true if the write was successful, false otherwise.
    */
   public write = (value: any): boolean => {
      return this.writeVariant(value, true, false);
   };

   /**
    * Writes a string at the end of the buffer in XDR format.  The string is
    * converted from the native JavaScript UTF-16 to UTF-8 before putting it
    * on the buffer.
    *          0     1     2     3     4     5   ...
    *       +-----+-----+-----+-----+-----+-----+...+-----+-----+...+-----+
    *       |        length n       |byte0|byte1|...| n-1 |  0  |...|  0  |
    *       +-----+-----+-----+-----+-----+-----+...+-----+-----+...+-----+
    *       |<-------4 bytes------->|<------n bytes------>|<---r bytes--->|
    *                               |<----n+r (where (n+r) mod 4 = 0)---->|
    *
    * @param {String} value - The value to be written.
    */
   public writeString = (value: string): void => {
      const utf8Array = StringUtils.stringToUint8Array(value);

      /*
       * If the string wasn't empty but the conversion result is, that means
       * that it wasn't able to be converted.
       */
      if (utf8Array.length === 0 && value.length > 0) {
         Logger.error("XDRBuffer: Failed to convert string " + value + " to UTF-8.", Logger.VDP);
         return;
      }

      // Now write the resulting UTF-8 data.
      this.writeBlob(utf8Array);
   };

   /**
    * Writes a Uint8Array at the end of the buffer in XDR format.
    *          0     1     2     3     4     5   ...
    *       +-----+-----+-----+-----+-----+-----+...+-----+-----+...+-----+
    *       |        length n       |byte0|byte1|...| n-1 |  0  |...|  0  |
    *       +-----+-----+-----+-----+-----+-----+...+-----+-----+...+-----+
    *       |<-------4 bytes------->|<------n bytes------>|<---r bytes--->|
    *                               |<----n+r (where (n+r) mod 4 = 0)---->|
    *
    * @param {Uint8Array} value - The value to be written.
    */
   public writeBlob = (value: Uint8Array): void => {
      this.writeUint32(value.length);
      this.setUint8Array(value);
   };

   /**
    * Writes a 8-bit unsigned integer at the end of the buffer in XDR format.
    * XDR operates on a boundary of 4 bytes so the value is added at byte 3
    * with leading 0s.
    *            (MSB)                   (LSB)
    *       +-------+-------+-------+-------+
    *       |byte 0 |byte 1 |byte 2 |byte 3 |
    *       +-------+-------+-------+-------+
    *       <------------32 bits------------>
    *
    * @param {Integer} value - The value to be written.
    */
   public writeUint8 = (value: number): void => {
      this.ensureWrite(4);
      this.length += 3;
      this.data[this.length++] = value & 0xff;
   };

   /**
    * Writes a 32-bit unsigned integer at the end of the buffer
    * in XDR format.
    *
    *            (MSB)                   (LSB)
    *       +-------+-------+-------+-------+
    *       |byte 0 |byte 1 |byte 2 |byte 3 |
    *       +-------+-------+-------+-------+
    *       <------------32 bits------------>
    *
    * @param {Integer} value - The value to be written.
    */
   public writeUint32 = (value: number): void => {
      this.setInt32(this.length, value);
   };

   /**
    * Writes a 32-bit signed integer at the end of the buffer
    * in XDR format.
    *
    *            (MSB)                   (LSB)
    *       +-------+-------+-------+-------+
    *       |byte 0 |byte 1 |byte 2 |byte 3 |
    *       +-------+-------+-------+-------+
    *       <------------32 bits------------>
    *
    * @param {Integer} value - The value to be written.
    */
   public writeInt32 = (value: number): void => {
      this.setInt32(this.length, value);
   };

   /**
    * Writes a 32-bit integer at the specified position
    * of the buffer in XDR format.
    *
    * @param {Integer} position - The write position.
    * @param {Integer} value - The value to be written.
    */
   public setInt32 = (position: number, value: number): void => {
      const reqBytes = position - this.length + 4;

      if (reqBytes > 0) {
         this.ensureWrite(reqBytes);
         this.length += reqBytes;
      }

      this.data[position++] = (value >> 24) & 0xff;
      this.data[position++] = (value >> 16) & 0xff;
      this.data[position++] = (value >> 8) & 0xff;
      this.data[position++] = value & 0xff;
   };

   /**
    * Writes the variant type and the value at the end of the buffer in XDR format.
    *
    * @param {Number|String|Uint8Array} value - The value to be written.
    * @param {Boolean} signed - true if the value should be interpreted by the server
    *                           as a signed integer.
    * @param {Boolean} writeType - true (default) if the type header should be written
    * @returns {Boolean} true if the value was successfully written.
    */
   public writeVariant = (value: number | string | Uint8Array, signed?: boolean, writeType?: boolean): boolean => {
      let vt: any;

      if (writeType === undefined) {
         writeType = true;
      }

      if (typeof value === "string") {
         if (writeType) {
            this.writeUint32(VDP_CONSTS.VARIANT_TYPE.STRING);
         }
         this.writeString(value);
      } else if (typeof value === "number") {
         vt = VDP_CONSTS.VARIANT_TYPE.UNSIGNED_INTEGER;

         if (signed || false) {
            vt = VDP_CONSTS.VARIANT_TYPE.SIGNED_INTEGER;
         }

         if (writeType) {
            this.writeUint32(vt);
         }
         this.writeUint32(value);
      } else if (value instanceof Uint8Array) {
         if (writeType) {
            this.writeUint32(VDP_CONSTS.VARIANT_TYPE.BLOB);
         }
         this.writeBlob(value);
      } else {
         return false;
      }

      return true;
   };

   /**
    * Reads the encoded 8-bit unsigned integer from the current position
    * skipping 3 bytes.
    *
    * @returns {Integer} The value read or null if data was not available.
    */
   public readUint8 = (): number => {
      let param = null;

      if (this.ensureRead(4)) {
         this.readPosition += 3;
         param = this.data[this.readPosition++];
      }

      return param;
   };

   /**
    * Reads the 32-bit unsigned integer from the current position.
    *
    * @returns {Integer} The value read or null if data was not available.
    */
   public readUint32 = (): number => {
      let param = this.readInt32();

      if (param) {
         param = param >>> 0;
      }

      return param;
   };

   /**
    * Reads the 64-bit unsigned integer from the current position.
    *
    * @returns {array} The value read in array or null.
    */
   public readUint64 = (): any => {
      let param = null;

      if (this.ensureRead(8)) {
         param = new Uint8Array(8);
         param.set(this.data.slice(this.readPosition, this.readPosition + 8));
         this.readPosition += 8;
      }

      return param;
   };

   /**
    * Reads the 32-bit signed integer from the current position.
    *
    * @returns {Integer} The value read or null if data was not available.
    */
   public readInt32 = (): number => {
      let param = null;

      if (this.ensureRead(4)) {
         param =
            ((0xff & this.data[this.readPosition++]) << 24) |
            ((0xff & this.data[this.readPosition++]) << 16) |
            ((0xff & this.data[this.readPosition++]) << 8) |
            (0xff & this.data[this.readPosition++]);
      }

      return param;
   };

   /**
    * Reads a string from current position. Reads the length from the string and
    * then the actual string.
    *
    * @returns {String} The value read or null if data was not available.
    */
   public readString = (): string => {
      let stringData: any,
         string = null;

      stringData = this.readBlob();
      if (stringData) {
         if (stringData.length > 0) {
            string = StringUtils.uint8ArrayToString(stringData);
         } else {
            string = "";
         }
      }

      return string;
   };

   /**
    * Read the encoded blob {Uint8array} from the current position. Reads the length
    * from the buffer and then the actual Blob.
    *
    * @returns {Uint8Array} The value read or null if data was not available.
    */
   public readBlob = (): any => {
      let blob = null,
         len = this.readUint32();

      if (len && this.ensureRead(len)) {
         blob = this.data.subarray(this.readPosition, this.readPosition + len);
         this.readPosition += len;
         this.seekToNextBlock(false);
      }

      // Return an empty blob when the length read from the XDRBuffer is 0.
      if (len === 0) {
         return new Uint8Array(0);
      }

      return blob;
   };

   /**
    * Read the variant type and then the data from the current position. Reads the
    * type first and then data accordingly.
    *
    * @returns {Number|String|Blob} The value read or null if data was not available.
    */
   public readVariant = (): any => {
      let param = null,
         type = this.readUint32();

      switch (type) {
         case VDP_CONSTS.VARIANT_TYPE.STRING:
            param = this.readString();
            break;
         case VDP_CONSTS.VARIANT_TYPE.SIGNED_INTEGER:
            param = this.readInt32();
            break;
         case VDP_CONSTS.VARIANT_TYPE.UNSIGNED_INTEGER:
            param = this.readUint32();
            break;
         case VDP_CONSTS.VARIANT_TYPE.UNSIGNED_INTEGER_64:
            param = this.readUint64();
            break;
         case VDP_CONSTS.VARIANT_TYPE.BLOB:
            param = this.readBlob();
            break;
         case VDP_CONSTS.VARIANT_TYPE.CHAR:
            param = this.readUint8();
            if (param !== null) {
               param = String.fromCharCode(param);
            }
            break;
         default:
            Logger.error("Failed to read the variant due to invalid type: " + type, Logger.VDP);
      }

      return param;
   };

   /**
    * Skips the number of bytes. Note that all elements are initialized to
    * 0 by default.
    *
    * @param {Integer} pos - The number of bytes to skip.
    */
   public skipWriteBytes = (num: number): void => {
      this.ensureWrite(num);
      this.length += num;
   };

   /**
    * Seek the read position ensuring that it does not
    * go beyond the length.
    *
    * @param {Integer} pos - The position to seek to.
    * @returns {Boolean} True if successful.
    */
   public seekReadPosition = (pos: number): boolean => {
      if (pos >= 0 && pos < this.length) {
         this.readPosition = pos;
         return true;
      }

      return false;
   };

   /**
    * Ensure that the buffer is large enough to write the number of bytes.
    * The size is multiplied by 1.5 times.
    *
    * @param {Integer} size - Tbe number of bytes to ensure.
    */
   private ensureWrite = (size: number): void => {
      if (size > 0) {
         let buffer: any,
            reqLength = this.length + size,
            newLength = this.data.length;

         while (newLength < reqLength) {
            newLength = Math.floor(newLength * 1.5);
         }

         if (newLength > this.data.length) {
            buffer = new Uint8Array(newLength);
            buffer.set(this.data);
            this.data = buffer;
         }
      }
   };

   /**
    * Check if the number of bytes can be read from the current read
    * position.
    *
    * @param {Integer} length - Tbe number of bytes to be read.
    * @returns {Boolean} true if the buffer is large enough to read the length.
    */
   private ensureRead = (length: number): boolean => {
      return !!this.data && this.readPosition + length <= this.data.length;
   };

   /**
    * Append a Uint8Array with padding of 4 bytes according to XDR.
    *
    * @private
    * @param {Uint8Array} value - The value to be set.
    */
   private setUint8Array = (value: Uint8Array): void => {
      this.ensureWrite(value.length);
      if (value && value.length) {
         this.data.set(value, this.length);
      }

      this.length += value.length;
      this.seekToNextBlock(true);
   };

   /**
    * Helper function used to seek to the end of the 4-byte block.
    *
    * @private
    * @param {Boolean} seekWrite - true to seek write buffer,
    *                              false to seek read buffer.
    */
   private seekToNextBlock = (seekWrite: boolean): void => {
      if (seekWrite) {
         this.length = (this.length + 3) & ~0x3;
      } else {
         this.readPosition = (this.readPosition + 3) & ~0x3;
      }
   };
}
