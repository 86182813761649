/**
 * ******************************************************
 * Copyright (C) 2015-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview message-handler.service.ts -- messageHandlerService
 * This service offering the same API to decouple the different design for IE
 *    and non-IE browsers, it will inform the current tab about the launching
 *    info in one way or another with same API.
 *
 * The implement is using API of postMessage for non-IE or Edge browsers to
 *    provide better user exprience, while will use API of storage event for
 *    IE. For we have a different design using storage for IE with version
 *    below or equal to 11, since it suffers from some postMessage Bugs, please
 *    refer below link for details:
 *    http://stackoverflow.com/questions/16226924/is-cross-origin-postmessage-broken-in-ie10
 */
import { Injectable } from "@angular/core";
import { AB } from "./appblast-util.service";

@Injectable({
   providedIn: "root"
})
export class MessageHandlerService {
   private launchingFunc = null;
   private brokerAddress: string = "";
   private horizonId: string = "";
   private userName: string = "";
   private domainName: string = "";
   private inited: boolean = false;

   constructor() {}

   /**
    * check whether the data passed from the HWS is invalid
    * will treat the data for another HWS session as invalid,
    * since HWS should never use postMessage for that case.
    *
    * @param  {object} data
    * @return {boolean} This returns whether the action is acceptable
    * to the WebClient.
    */
   private invalidHWSData = (data): boolean => {
      if (!data.itemType || !data.itemId || !data.horizonId) {
         return true;
      }
      if (
         data.horizonId !== this.horizonId ||
         decodeURIComponent(data.userName) !== this.userName ||
         data.domainName !== this.domainName
      ) {
         // message is sent by a invalid horizon session
         return true;
      }
      return false;
   };

   /**
    * deal with the postMessage form the HWS
    * @param  {object} data The structure holds the action related
    *    information
    * @return {boolean} This returns whether the message is accepted
    */
   private dealWithHWSMessage = (data): boolean => {
      const itemType = data.itemType,
         itemId = decodeURIComponent(data.itemId);

      if (this.invalidHWSData(data)) {
         return false;
      }

      if (typeof this.launchingFunc === "function") {
         this.launchingFunc(itemType, itemId);
      }
      return true;
   };

   private receiveMessage = (event) => {
      let message, success;

      if (!event.data || typeof event.data !== "string") {
         return;
      }
      message = JSON.parse(event.data);
      if (message.type === "HWS") {
         success = this.dealWithHWSMessage(message.data);
         if (success) {
            event.source.postMessage(
               JSON.stringify({
                  type: "WebClient",
                  data: "Ack"
               }),
               "*"
            );
         } else {
            event.source.postMessage(
               JSON.stringify({
                  type: "WebClient",
                  data: "Nak"
               }),
               "*"
            );
         }
      }
   };

   private onStorageChange = (event) => {
      let message,
         success,
         eventKey = "HWS_BKA_" + this.brokerAddress;

      if (event.key === eventKey) {
         message = JSON.parse(event.newValue);
         if (message.origin !== "newTab") {
            return;
         }
         success = this.dealWithHWSMessage(message.data);
         if (success) {
            // return a success message.
            window.localStorage.setItem(
               eventKey,
               JSON.stringify({
                  origin: "oldTab", // this message is from a tab older than
                  // the reciever tab.
                  data: "Merged" // message indicate the request content is
                  // merged successfully into this tab old
                  // tab.
               })
            );
         }
      }
   };

   /**
    * Init function to set the current session info, if param are
    * correct, listener will also be registered.
    *
    * @param {object} localInfo The object contains properties of
    *    brokerAddress, horizonId, userName, and domainName
    */
   public init = (localInfo) => {
      if (this.inited || !localInfo) {
         return;
      }
      this.brokerAddress = localInfo.brokerAddress;
      this.horizonId = localInfo.horizonId;
      this.userName = localInfo.userName;
      this.domainName = localInfo.domainName;
      if (!!this.brokerAddress && !!this.horizonId && !!this.userName && !!this.domainName) {
         //only when info are fully given enable the listener
         if (!AB.supportPostMessage()) {
            if (window.localStorage) {
               window.addEventListener("storage", this.onStorageChange, false);
            }
         } else {
            window.addEventListener("message", this.receiveMessage, false);
         }
      }
      this.inited = true;
   };

   /**
    * Used to register in the launch callback, which will be called
    * when receive the specified request
    * @param  {function} launchCallback Could be the identical
    *    function used for sidebar launching
    */
   public registerLaunchCallback = (launchCallback) => {
      this.launchingFunc = launchCallback;
   };
}
