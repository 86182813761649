/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * disconnectSessionHandler.js --
 *
 *      Implementation of the message handler to disconnect session.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { StateEnum, MessageHandler } from "./messageHandler";
import Router from "./router";

export default function DisconnectSessionHandler() {
   let router;

   // member variables below
   this.messageName = "disconnect-session";
   this.messageText = "disconnect-session";
   this.responseTag = "disconnect-session";
   this.composedHandlerList = [];

   this.launchItemId = "";

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
}

// inherits MessageHandler prototype
DisconnectSessionHandler.prototype = new MessageHandler();
// constructor
DisconnectSessionHandler.constructor = DisconnectSessionHandler;

/**
 * parse information from the response XML of disconnect-session
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return
 *    null
 */
DisconnectSessionHandler.prototype.parseResult = function (responseXML) {
   let disconnectSessionResponse = {},
      xmlDoc = $(responseXML),
      brokerTag,
      responses,
      result;

   brokerTag = $(xmlDoc.children()[0]);
   if (!brokerTag) {
      Logger.error("response of disconnectSessionHandler error");
      return null;
   }

   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(disconnectSessionResponse, nameText, $(this).text());
      });
   }

   return disconnectSessionResponse;
};

/**
 * Set the request XML for disconnect-session
 * @param sessionId[in] session id to disconnect-session
 *
 */
DisconnectSessionHandler.prototype.setRequestXML = function (sessionId) {
   let sessionIdElem;
   sessionIdElem = util.createElement("session-id", sessionId);
   this.requestXML = sessionIdElem;
};

/**
 * callback when received notification from handlers in dependency list or
 * router
 *
 */
DisconnectSessionHandler.prototype.onUpdated = function () {
   let disconnectSessionAction;

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.state === StateEnum.DONE) {
      if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
         Logger.debug("disconnect-session succeeds.");

         // Disconnect session succeeds
         disconnectSessionAction = { name: "DisconnectSession", result: "ok" };
         JSCDKSetUI(JSON.stringify(disconnectSessionAction));
      }
   } else if (this.state === StateEnum.FAIL) {
      /*
       * Disconnect session failed.
       * The DisconnectionSessionfailed is needed to avoid bug 1374808.
       */
      Logger.debug("disconnect-session failed. Session ID:" + this.launchItemId);
      disconnectSessionAction = { name: "DisconnectSessionFailed", result: "failed" };
      JSCDKSetUI(JSON.stringify(disconnectSessionAction));
   } else {
      Router.prototype.pushErrorToUser(this);
   }
};

/**
 * Triggered by the parent handler
 *
 */
DisconnectSessionHandler.prototype.triggerExecution = function () {
   let getLaunchItemsObject,
      desktops,
      targetDesktop,
      sessionId,
      handlerList,
      launchItemId = this.launchItemId,
      router = globalArray["router"];

   MessageHandler.prototype.triggerExecution.apply(this);

   // Get session ID of the desktop whose id equals desktopId.
   getLaunchItemsObject = this.parentHandler;
   if (!getLaunchItemsObject) {
      getLaunchItemsObject = globalArray["get-launch-items" + launchItemId];
   }
   if (!!getLaunchItemsObject && !!getLaunchItemsObject.content["parsedResult"]) {
      desktops = getLaunchItemsObject.content["parsedResult"]["desktops"];
   }
   if (desktops) {
      targetDesktop = getLaunchItemsObject.findDesktop(launchItemId, desktops);
   }
   if (targetDesktop) {
      sessionId = targetDesktop["session-id"];
   } else {
      // While there is no desktop id associated with the session id,
      // Assuming the launchItemId is an application session id.
      sessionId = launchItemId;
   }

   // Disconnect the given session.
   if (!!sessionId && sessionId.trim() !== "") {
      this.setRequestXML(sessionId);
      handlerList = this.composeHandlerList();
      router.postMessage(handlerList);
   }
};
