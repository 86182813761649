/**
 * ******************************************************
 * Copyright (C) 2018-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Store device preference for RTAV, per chromebook deivce.
 *
 * Choose id for device identity since it's unique and consistent
 * and is not an optional property.
 * Clearable, same among users.
 */

import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { clientUtil } from "@html-core";
import {
   AudioDevice,
   DeviceInfo,
   getPreferredDeviceKey,
   PreferredDeviceKey,
   PreferredDeviceType,
   VideoDevice
} from "../../desktop/rtav/v2/device-manager.model";
import { DeviceEnumeratorService } from "../../desktop/rtav/v2/device-enumerator.service";

@Injectable({
   providedIn: "root"
})
export class PreferredRTAVDeviceService {
   public static readonly deviceTypeMap: { [K in PreferredDeviceType]?: MediaDeviceKind } = {
      audio: "audioinput",
      video: "videoinput",
      audioOut: "audiooutput"
   };
   private isChromeClient = clientUtil.isChromeClient();

   constructor(private deviceEnumeratorService: DeviceEnumeratorService) {}

   public readKey = (key: PreferredDeviceKey) => {
      if (!this.isChromeClient) {
         return Promise.resolve(window.localStorage.getItem(key));
      }
      return new Promise((resolve, reject) => {
         try {
            chrome.storage.local.get(key, (obj) => {
               if (chrome.runtime.lastError) {
                  Logger.error("chrome LastError = " + chrome.runtime.lastError);
                  resolve(undefined);
                  return;
               }
               if (!obj) {
                  resolve(undefined);
                  return;
               }
               resolve(obj[key]);
            });
         } catch (e) {
            Logger.exception(e);
            resolve(undefined);
         }
      });
   };

   // value is device's label
   private setKey = (key: PreferredDeviceKey, value: string) => {
      if (!this.isChromeClient) {
         return Promise.resolve(window.localStorage.setItem(key, value));
      }
      return new Promise((resolve, reject) => {
         try {
            const obj = {};
            obj[key] = value;
            chrome.storage.local.set(obj, () => {
               if (chrome.runtime.lastError) {
                  const error = chrome.runtime.lastError;
                  reject(error);
                  return;
               }
               // @ts-ignore
               resolve();
            });
         } catch (e) {
            reject(e);
         }
      });
   };
   private async getDeviceList() {
      await this.deviceEnumeratorService.updateDeviceList();
      return {
         audio: this.deviceEnumeratorService.getAudioInfoList(),
         video: this.deviceEnumeratorService.getVideoInfoList(),
         audioOut: this.deviceEnumeratorService.getAudioOutInfoList()
      };
   }

   /**
    * @return {Boolean} Whether current platform can support device choosing.
    */
   public isDeviceChoosingSupported = () => {
      if (this.isChromeClient) {
         return chrome?.storage?.local && chrome?.runtime && navigator?.mediaDevices?.enumerateDevices;
      } else {
         return true;
      }
   };

   /**
    * Don't use device name, but using id, so this function will return null
    * for existing device after user clear cookies.
    *
    * Don't use async to avoid a bug.
    *
    * @param  {string} type Of string "audio" or "video"
    * @return {string} Returns the deviceId, or return null if can't find the
    *    preferred device.
    */
   public getPreferredDeviceId = (type: PreferredDeviceType) => {
      return new Promise((resolve, reject) => {
         if (!this.isDeviceChoosingSupported()) {
            resolve(null);
            return;
         }
         this.readKey(getPreferredDeviceKey(type)).then((preferredDeviceId: string | undefined) => {
            if (preferredDeviceId) {
               navigator.mediaDevices.enumerateDevices().then((devices) => {
                  let deviceId = "";
                  devices.forEach((device) => {
                     if (
                        device.kind === PreferredRTAVDeviceService.deviceTypeMap[type] &&
                        (device.deviceId.toLowerCase() === preferredDeviceId.toLowerCase() ||
                           device.label === preferredDeviceId)
                     ) {
                        deviceId = device.deviceId;
                     }
                  });
                  if (deviceId) {
                     resolve(deviceId);
                  } else {
                     resolve(null);
                  }
               });
            } else {
               resolve(null);
            }
         });
      });
   };

   public setPreferredDeviceLabel = async (type: PreferredDeviceType, label: string) => {
      if (!this.isDeviceChoosingSupported()) {
         Logger.error("can't set setPreferredDeviceId " + "if device choosing is not supported");
         return;
      }
      await this.setKey(getPreferredDeviceKey(type), label);
   };

   /**
    * Return Info for displaying
    * Don't use async to avoid a bug
    */
   public getPreferredDeviceInfo(): Promise<{
      audio: DeviceInfo<AudioDevice>[];
      video: DeviceInfo<VideoDevice>[];
      audioOut: MediaDeviceInfo[];
      preferredLabels: {
         audio: string;
         video: string;
         audioOut: string;
         videoResolution: string;
      };
   }> {
      return new Promise((resolve, reject) => {
         if (!this.isDeviceChoosingSupported()) {
            resolve(null);
            return;
         }
         Promise.all([
            this.getDeviceList(),
            this.readKey(getPreferredDeviceKey(PreferredDeviceType.audio)),
            this.readKey(getPreferredDeviceKey(PreferredDeviceType.video)),
            this.readKey(getPreferredDeviceKey(PreferredDeviceType.audioOut)),
            this.readKey(getPreferredDeviceKey(PreferredDeviceType.videoResolution))
         ])
            .then((result: any) => {
               resolve({
                  audio: result[0].audio,
                  video: result[0].video,
                  //now we only have two options for preferred speakr: 'all' and 'default speaker'
                  audioOut: result[0].audioOut,
                  preferredLabels: {
                     audio: result[1],
                     video: result[2],
                     audioOut: result[3],
                     videoResolution: result[4]
                  }
               });
            })
            .catch((e) => {
               reject(e);
            });
      });
   }
}
