<div class="modal-title dialog-title">{{title}}</div>
<div class="modal-body dialog-content">
   <div class="session-ops-window-text">{{message}}</div>
</div>
<div class="modal-footer dialog-button-row">
   <button type="button" class="modal-button-base modal-button-right modal-button-grey" id="okDialogBtn" (click)="confirmModal()">
      {{ (buttonLabelConfirm) || ('OK' | i18nv2) }}
   </button>
   <button type="button" class="modal-button-base modal-button-right modal-button-blue ok-button" id="cancelDialogBtn" (click)="closeModal()" ngbAutofocus>
      {{ (buttonLabelCancel) || ('CANCEL' | i18nv2)}}
   </button>
</div>