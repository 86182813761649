/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import logoImg from "../../icons/logo.png";
import horizonLogoImg from "../../icons/horizon-logo.svg";
import searchIconImg from "../../icons/search_icon.svg";
import appIconInactiveImg from "../../icons/apps_icon_inactive.svg";
import appIcon2XImg from "../../icons/apps_icon@2x.png";
import favIconInactiveImg from "../../icons/favorite_icon_inactive.svg";
import favIconActiveImg from "../../icons/favorite_icon_active.svg";
import setIconInactiveImg from "../../icons/settings_icon_inactive.svg";
import helpIconInactiveImg from "../../icons/help_icon_inactive.svg";
import logoutIconInactiveImg from "../../icons/logout_icon_inactive.svg";
import deDesktopIconImg from "../../icons/default_desktop_icon.svg";
import deShadowIconImg from "../../icons/default_shadow_icon.svg";
import deAppIconImg from "../../icons/default_app_icon.svg";
import iclSidebarContextImg from "../../icons/icl_sidebar_context.svg";
import iclSendCadImg from "../../icons/icl_send_cad.svg";
import iclTransferImg from "../../icons/icl_transfer.svg";
import iclClipboardImg from "../../icons/icl_clipboard.svg";
import iclShowAllImg from "../../icons/icl_show_all.svg";
import iclShowAllOnImg from "../../icons/icl_show_all_on.svg";
import iclFavImg from "../../icons/icl_fav.svg";
import iclFavOnImg from "../../icons/icl_fav_on.svg";
import server2XImg from "../../icons/server@2x.png";
import addServerImg from "../../icons/add_server.png";
import iconContactcircleImg from "../../icons/icon-contactcircle.svg";
import window365Svg from "../../icons/windows365_icon.svg";
import { ENTITLE_TYPE, ICON_TYPE } from "@html-core";

export const imageAsset = {
   logo: logoImg,
   horizonLogo: horizonLogoImg,
   searchIcon: searchIconImg,
   appIconInactive: appIconInactiveImg,
   appIcon2X: appIcon2XImg,
   favIconInactive: favIconInactiveImg,
   favIconActive: favIconActiveImg,
   setIconInactive: setIconInactiveImg,
   helpIconInactive: helpIconInactiveImg,
   logoutIconInactive: logoutIconInactiveImg,
   deDesktopIcon: deDesktopIconImg,
   deShadowIcon: deShadowIconImg,
   deAppIcon: deAppIconImg,
   iclSidebarContext: iclSidebarContextImg,
   iclSendCad: iclSendCadImg,
   iclTransfer: iclTransferImg,
   iclClipboard: iclClipboardImg,
   iclShowAll: iclShowAllImg,
   iclShowAllOn: iclShowAllOnImg,
   iclFav: iclFavImg,
   iclFavOn: iclFavOnImg,
   server2X: server2XImg,
   addServer: addServerImg,
   iconContactcircle: iconContactcircleImg,
   window365Svg: window365Svg
};

export function getDesktopImage(entitlement: any): string {
   if (entitlement?.iconType === ICON_TYPE.W365) {
      return imageAsset.window365Svg;
   }
   return imageAsset.deDesktopIcon;
}
