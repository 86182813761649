/**
 * ******************************************************
 * Copyright (C) 2015-2021, 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { clientUtil } from "@html-core";

export interface XMLPreference {
   favorites?: Array<string>; // favorites is n array
   useMacOSXKeyMappings: string;
   timezoneSync: string;
   webAudioOutDeviceOption: string;
   enableWindowsKey: string;
   enableWindowsDeleteKey: string;
   enableFolderSharing: string;
   enableMultiMonitor: string;
   enableMP4: string;
   enableFitToViewer: string;
   enableHighResolution: string;
   enableClipboardPopupGuide: string;
   enableGeolocationSharing: string;
   donotShowGeolocationDialog: string;
   enableMediaStreamPermission: string;
   donotShowMediaStreamPermissionDialog: string;
   redirectSystemTray: string;
   titanLaunchType?: string;
   titanSingleLogout: string;
   titanAutoResumption?: string;
   enableWebRTCRedirection: string;
   enableWebRTCRedirectionWeb: string;
   enableScreenSharing: string;
   enableFileAssociation: string;
   enableCtrlShiftAltUpKey: string;
   enableVRMode: string;
   showNumOnTopbar: string;
   enableBCR: string;
   isDisplayChanged?: string;
   isDPISyncChanged?: string;
   isWinKeyChanged?: string;
   isWinDelKeyChanged?: string;
   isRedirSysTrayChanged?: string;
   isWebRTCRedirectionChanged?: string;
   isScreenSharingChanged?: string;
   isFolderSharingChanged?: string;
   isTimezoneChanged?: string;
   displaySetting?: string;
   customWindowWidth?: string;
   customWindowHeight?: string;
   keyMappingSetting?: string;
   enableKeyMapping?: string;
   autoForwardUSB?: string;
   isAutoFowardUSBChanged?: string;
   enableAdvancedCodec?: string;
}

export interface iChromeLaunchSetting {
   enableHighResolution: boolean;
   useMacOSXKeyMappings: boolean;
   enableWindowsKey: boolean;
   enableWindowsDeleteKey: boolean;
   enableFitToViewer: boolean;
   rxParameters: any;
   enableFolderSharing: boolean;
   enableMultiMonitor: boolean;
   enableMP4: boolean;
   enableGeolocationSharing: boolean;
   donotShowGeolocationDialog: boolean;
   enableMediaStreamPermission: boolean;
   donotShowMediaStreamPermissionDialog: boolean;
   useRTAVChannel: boolean;
   selectedMonitors: any;
   enableWebRTCRedirection: boolean;
   enableWebRTCRedirectionWeb: boolean;
   enableScreenSharing: boolean;
   enableBCR: boolean;
   showNumOnTopbar: boolean;
   enableKeyMapping: boolean;
   displaySetting: number;
   keyMappingSetting: any;
   policyAllowFileDownload: boolean;
   policyAllowFileUpload: boolean;
   enableNetworkIndicator: boolean;
   networkStateConfig: any;
   disableNetworkStateDisplay: boolean;
   enableRTAVH264Codec: boolean;
   enableRTAVOpusCodec: boolean;
   enableRTAVDTX: boolean;
   hardwareAccelerationOption: string;
   enableBlastCodec: boolean;
   enableAdvancedCodec: boolean;
   delayKeysWhenMoveInside: boolean;
}

@Injectable({
   providedIn: "root"
})
export class PrefData {
   public prefData: XMLPreference;

   constructor() {
      this.reset();
   }

   public reset = () => {
      this.prefData = {
         favorites: [],
         useMacOSXKeyMappings: "false",
         timezoneSync: "",
         enableWindowsKey: "false",
         enableWindowsDeleteKey: "false",
         enableFolderSharing: "false",
         enableMultiMonitor: "false",
         enableMP4: "false",
         enableFitToViewer: "false",
         enableHighResolution: "false",
         enableClipboardPopupGuide: "true",
         enableGeolocationSharing: "false",
         donotShowGeolocationDialog: "false",
         enableMediaStreamPermission: "false",
         donotShowMediaStreamPermissionDialog: "false",
         redirectSystemTray: "false",
         enableWebRTCRedirection: "true",
         enableWebRTCRedirectionWeb: "true",
         enableScreenSharing: "true",
         enableVRMode: "false",
         showNumOnTopbar: "false",
         enableBCR: "true",
         enableKeyMapping: "true",
         keyMappingSetting: "",
         autoForwardUSB: "false"
      } as XMLPreference;
   };

   /**
    * Checks in case there is a change in data which needs to be
    * pushed to prefData
    *
    * @param data
    * @returns {boolean}
    **/
   public equals = (data): boolean => {
      let ret: boolean = false;
      try {
         if (
            this.prefData.favorites.sort().toString() === data.favorites.sort().toString() &&
            this.prefData.useMacOSXKeyMappings === data.useMacOSXKeyMappings &&
            this.prefData.enableWindowsKey === data.enableWindowsKey &&
            this.prefData.enableWindowsDeleteKey === data.enableWindowsDeleteKey &&
            this.prefData.timezoneSync === data.timezoneSync &&
            this.prefData.enableFolderSharing === data.enableFolderSharing &&
            this.prefData.enableMultiMonitor === data.enableMultiMonitor &&
            this.prefData.enableMP4 === data.enableMP4 &&
            this.prefData.enableFitToViewer === data.enableFitToViewer &&
            this.prefData.enableHighResolution === data.enableHighResolution &&
            this.prefData.enableClipboardPopupGuide === data.enableClipboardPopupGuide &&
            this.prefData.enableGeolocationSharing === data.enableGeolocationSharing &&
            this.prefData.donotShowGeolocationDialog === data.donotShowGeolocationDialog &&
            this.prefData.enableWebRTCRedirection === data.enableWebRTCRedirection &&
            this.prefData.enableWebRTCRedirectionWeb === data.enableWebRTCRedirectionWeb &&
            this.prefData.enableScreenSharing === data.enableScreenSharing &&
            this.prefData.redirectSystemTray === data.redirectSystemTray &&
            this.prefData.enableVRMode === data.enableVRMode &&
            this.prefData.showNumOnTopbar === data.showNumOnTopbar &&
            this.prefData.enableBCR === data.enableBCR &&
            this.prefData.enableMediaStreamPermission === data.enableMediaStreamPermission &&
            this.prefData.donotShowMediaStreamPermissionDialog === data.donotShowMediaStreamPermissionDialog
         ) {
            ret = true;
         } else {
            ret = false;
         }
         if (clientUtil.isTitanClient()) {
            let titanItemEqual: boolean = false;
            if (this.prefData.titanLaunchType === data.titanLaunchType) {
               titanItemEqual = true;
            } else {
               titanItemEqual = false;
            }
            ret = ret && titanItemEqual;
         }
         return ret;
      } catch (e) {
         return false;
      }
   };

   public toJSON = (prefData: XMLPreference): object => {
      const pref = {};
      for (const key in prefData) {
         if (key === "enableKeyMapping") {
            continue;
         } else if (key === "keyMappingSetting" && prefData["enableKeyMapping"] === "false") {
            continue;
         } else if (key !== "favorites") {
            pref[key] = prefData[key];
         } else {
            for (let i = 0; i < prefData["favorites"].length; i++) {
               pref["favorite" + i] = prefData["favorites"][i];
            }
         }
      }
      return pref;
   };
}
