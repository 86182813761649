/**
 * *****************************************************
 * Copyright 2020 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedCommonModule } from "../../common/shared.common.module";
import { DownloadLinkComponent } from "../common/download-link.component";
import { HelpLinkComponent } from "../common/help-link.component";
import { PrivacyLinkComponent } from "../common/privacy-link.component";
import { HeaderComponent } from "../common/header/header.component";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
   imports: [CommonModule, SharedCommonModule, FormsModule, NgbModule],
   declarations: [DownloadLinkComponent, HelpLinkComponent, PrivacyLinkComponent, HeaderComponent],
   exports: [DownloadLinkComponent, HelpLinkComponent, PrivacyLinkComponent, HeaderComponent]
})
export class SharedLauncherCommonModule {}
