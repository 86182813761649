<ngb-toast
   *ngFor="let toast of toastService.toasts"
   [header]="toast.headertext"
   [class]="toast.classname"
   [autohide]="toast.autohide"
   [delay]="toast.delay || 5000"
   (hidden)="toastHidden(toast, toast.dismissCallback)"
   id="ngbToast"
>
   <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
   </ng-template>

   <ng-template #text>
      <div *ngIf="toast.textToast">{{ toast.textOrTpl }}</div>

      <div class='toastContentBox' *ngIf="toast.multiToast">
         <span class='toastContent'>{{'dialog_content_multimon' | i18nv2 }}</span>
         <button (click)="executeCallbacks(toast.callback, toast)"  type="button" id="multimonOkBtn" class="btn btn-primary toastButton">{{'OK' | i18nv2 }}</button>
      </div>

      <div class='toastContentBox' *ngIf="toast.usbToast">
         <span class='toastContent'>{{ toast.textOrTpl }}</span>
         <button (click)="executeCallbacks(toast.callback, toast)"  type="button" id="multimonOkBtn" class="btn btn-primary toastButton">{{'OK' | i18nv2 }}</button>
      </div>
   </ng-template>
</ngb-toast>