/**
 * ******************************************************
 * Copyright (C) 2018 - 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";

import { signal } from "../../common/service/signal";
import { MKSVchan } from "../../../shared/desktop/channels/mksvchan";
import { EventBusService } from "@html-core";
import { RemoteSessionEventService, SessionMsg } from "../../common/remote-session/remote-session-event.service";
import { BlastWmks } from "../common/blast-wmks.service";

/**
 * mksvchan.service.ts --
 *
 * Module for wrapping mksvchan to angular factory service. mksvchan service is
 * a singleton service.
 *
 */

@Injectable({
   providedIn: "root"
})
export class MksvchanService extends signal implements MKSVchan.MksVchanCB {
   private clientMap: Map<string, MKSVchan.Client> = null;

   constructor(
      private eventBusService: EventBusService,
      private remoteSessionEventService: RemoteSessionEventService
   ) {
      super();
      this.clientMap = new Map<string, MKSVchan.Client>();
      this.addSignal("fileTransferFailed");
      this.addSignal("fileTransferBlockedByAudit");
      this.addSignal("downloadFileListChanged");
      this.addSignal("fileTransferConfigChange");
      this.addSignal("fileTransferError");
      this.addSignal("printError");

      this.addSignal("clipboardCapabilitiesChanged");
      this.addSignal("clipboardChanged");
      this.addSignal("clipboardReady");
      this.addSignal("clipboardPush");
      this.addSignal("wmkscopy");

      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, this.OnActiveSessionChanged);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTING_MSG, this.onSessionConnecting);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_DISCONNECTED_MSG, this.onSessionRemoved);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_REMOVED_MSG, this.onSessionRemoved);

      this.eventBusService.listen("wmkscopy").subscribe((msg: any) => {
         this.emit("wmkscopy", msg.key);
      });
   }

   private onSessionConnecting = (session: BlastWmks) => {
      if (this.clientMap.has(session.key)) {
         this.clientMap.delete(session.key);
      }

      const client = new MKSVchan.Client(session);
      client.addMksVchanObserver(this);
      this.clientMap.set(session.key, client);
      client.key = session.key;

      client.initialize();
      this.updateCopyPasteCapabilityChange(session.key, true, true);
   };

   private OnActiveSessionChanged = (session: BlastWmks, isNewSession: boolean) => {
      if (session === null || !this.clientMap.has(session.key)) {
         return;
      }
      const mksVchanClient = this.clientMap.get(session.key);
      this.emit(
         "clipboardCapabilitiesChanged",
         session.key,
         mksVchanClient.ready,
         mksVchanClient.copyEnabled,
         mksVchanClient.pasteEnabled
      );
      if (!isNewSession) {
         this.emit("clipboardPush");
      }
   };

   private onSessionRemoved = (session: BlastWmks) => {
      if (!this.clientMap.has(session.key)) {
         return;
      }
      let client = this.clientMap.get(session.key);
      client.removeMksVchanObserver();
      this.clientMap.delete(session.key);
      client = null;
   };

   //Override
   public onReady = (client: MKSVchan.Client): void => {
      this.emit("clipboardReady", client.key);
   };

   public onDownloadFilesChange = (client: MKSVchan.Client, downloadList: Array<Uint8Array>, error: number) => {
      this.emit("downloadFileListChanged", downloadList, client, error);
   };

   public onClipboardChanged = (client: MKSVchan.Client, clipboard: any, error: number) => {
      this.emit("clipboardChanged", client.key, clipboard, error);
   };
   public onFileTransferConfigChange = (error: number) => {
      this.emit("fileTransferConfigChange", error);
   };
   public onFileTransferError = (error: number) => {
      this.emit("fileTransferError", error);
   };

   public onClipboardStateChanged = (client: MKSVchan.Client) => {
      const isFinalStateChanged = true;
      this.emit(
         "clipboardCapabilitiesChanged",
         client.key,
         client.ready,
         client.copyEnabled,
         client.pasteEnabled,
         isFinalStateChanged
      );
   };

   private updateCopyPasteCapabilityChange = (wmksKey: string, copyEnabled: boolean, pasteEnabled: boolean) => {
      if (!wmksKey) {
         return;
      }

      const client = this.clientMap.get(wmksKey);
      if (client) {
         client.copyEnabled = copyEnabled;
         client.pasteEnabled = pasteEnabled;
      }

      this.emit("clipboardCapabilitiesChanged", wmksKey, client ? client.ready : false, copyEnabled, pasteEnabled);
   };

   public getClient = (wmksKey: string) => {
      if (this.clientMap.has(wmksKey)) {
         return this.clientMap.get(wmksKey);
      }
      return null;
   };
}
