/**
 * ******************************************************
 * Copyright (C) 2014-2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getLaunchItems.js --
 *
 *      Implementation of the message handler to get launchItems.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import DoLogoutHandler from "./doLogoutHandler";
import KillSessionHandler from "./killSessionHandler";
import ResetDesktopHandler from "./resetDesktopHandler";
import RestartDesktopHandler from "./restartDesktopHandler";
import GetUserGlobalPrefHandler from "./getUserGlobalPrefHandler";
import { GetLaunchItemsAction } from "../jscdk-interface";

export default function GetLaunchItemsHandler(skipTunnelAndPref?, skipTimer?) {
   let router,
      getTunnelConnObject,
      getUserGlobalPref,
      urlHandler = null,
      urlParams;

   // member variables
   this.messageName = "get-launch-items";
   this.messageText = "get-launch-items";
   this.responseTag = "launch-items";

   // whether to notify UI on get-launchItems response.
   this.notifyUI = true;
   this.skipTimer = skipTimer || false;

   this.subHandlerList = [];
   this.observerList = [];
   this.collabSessionId = null;

   // get collaboration session id from url
   urlHandler = util.getObject(globalArray, "url-handler");
   if (urlHandler) {
      urlParams = urlHandler.params;
      if (urlParams.collabSessionId) {
         this.collabSessionId = urlParams.collabSessionId;
      }
   }

   if (!skipTunnelAndPref) {
      if (!globalArray["get-user-global-preferences"]) {
         getUserGlobalPref = new GetUserGlobalPrefHandler();
         globalArray["get-user-global-preferences"] = getUserGlobalPref;
      } else {
         getUserGlobalPref = globalArray["get-user-global-preferences"];
      }
      getUserGlobalPref.registerHandler(this, "state");

      this.composedHandlerList = [this, getUserGlobalPref];

      // register dependencies here
      getTunnelConnObject = util.getObject(globalArray, "get-tunnel-connection");
      if (getTunnelConnObject) {
         this.registerHandler(getTunnelConnObject, "state");
      } else {
         Logger.error("The get-tunnel-connection object is null!");
      }
   } else {
      this.composedHandlerList = [];
   }
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

GetLaunchItemsHandler.prototype = new MessageHandler(); // inherits
// MessageHandler
// prototype
GetLaunchItemsHandler.constructor = GetLaunchItemsHandler;

/**
 * Reset handler's state and content.
 *
 */
GetLaunchItemsHandler.prototype.resetData = function () {
   MessageHandler.prototype.resetData.apply(this);

   this.notifyUI = true;
};

/**
 * Set the request XML for getLaunchItems.
 *
 * @param supportedDesktopProtocols [in] array for supported protocols.
 * @param supportedAppTypes [in] array of supported app types.  Each app type
 *    is an object with .name and optionally .protocols.
 * @param notifyUI [in] whether to notify the UI on XML-API response.
 */
GetLaunchItemsHandler.prototype.setRequestXML = function (supportedDesktopProtocols, supportedAppTypes, notifyUI) {
   let nameElement,
      protocolElement,
      sessionElement,
      protocolsElement = "",
      collabSessionIdElement = "",
      requestedSessionsElement = "",
      shadowSessionContentElement = "",
      desktopsElement = "",
      typeElement,
      typeNameElement,
      typesElement = "",
      applicationElement = "",
      shadowSessionElement = "",
      launchItemsElement = "",
      protocols = "",
      launchItems = "",
      type = "",
      types = "",
      i,
      l,
      urlHandler = null;

   for (i = 0; i < supportedDesktopProtocols.length; i++) {
      nameElement = util.createElement("name", supportedDesktopProtocols[i]);
      protocolElement = util.createElement("protocol", nameElement);
      protocols += protocolElement;
   }
   protocolsElement = util.createElement("supported-protocols", protocols);
   desktopsElement = util.createElement("desktops", protocolsElement);
   launchItemsElement += desktopsElement;

   for (i = 0; i < supportedAppTypes.length; i++) {
      protocols = "";
      typeNameElement = util.createElement("name", supportedAppTypes[i]["name"]);
      for (l = 0; l < supportedAppTypes[i].protocols.length; l++) {
         nameElement = util.createElement("name", supportedAppTypes[i].protocols[l]);
         protocolElement = util.createElement("protocol", nameElement);
         protocols += protocolElement;
      }
      protocolsElement = util.createElement("supported-protocols", protocols);
      type = typeNameElement + protocolsElement;
      typeElement = util.createElement("type", type);
      types += typeElement;
   }
   typesElement = util.createElement("supported-types", types);
   applicationElement = util.createElement("applications", typesElement);
   launchItemsElement += applicationElement;

   for (i = 0; i < supportedDesktopProtocols.length; i++) {
      nameElement = util.createElement("name", supportedDesktopProtocols[i]);
      protocolElement = util.createElement("protocol", nameElement);
      protocols += protocolElement;
   }
   protocolsElement = util.createElement("supported-protocols", protocols);

   // Provided for CPA case where pod needs to ask other pods
   if (this.collabSessionId) {
      collabSessionIdElement = util.createElement("session-id", this.collabSessionId);
      requestedSessionsElement = util.createElement("requested-sessions", collabSessionIdElement);
   }
   shadowSessionContentElement = protocolsElement + requestedSessionsElement;
   shadowSessionElement = util.createElement("shadow-sessions", shadowSessionContentElement);

   launchItemsElement += shadowSessionElement;

   sessionElement = util.createElement("application-sessions");
   launchItemsElement += sessionElement;

   /**
    * Generate the <environment-info> section if it was specified to support:
    * https://jira-hzn.eng.vmware.com/browse/DPM-1211
    */
   const environmentInfo = globalArray["environment-information"];
   if (typeof environmentInfo === "object") {
      let infoElements = "";
      for (const key in environmentInfo) {
         if (environmentInfo.hasOwnProperty(key)) {
            infoElements += util.createElement("info", environmentInfo[key], { name: key });
         }
      }
      const environmentElement = util.createElement("environment-information", infoElements);
      launchItemsElement += environmentElement;
   }

   this.requestXML = launchItemsElement;

   if (typeof notifyUI === "boolean") {
      // Whether to notify UI on get-launchItems response.
      this.notifyUI = notifyUI;
   } else {
      // Always notify UI on get-launchItems response.
      this.notifyUI = true;
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetLaunchItemsHandler.prototype.onUpdated = function () {
   let getLaunchItemsAction = {} as GetLaunchItemsAction,
      actionContent = {} as GetLaunchItemsAction["content"],
      router,
      urlHandler,
      urlParams,
      idleTimerObject,
      idleTimeout,
      userActivityXMLSendingInterval;

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.state === StateEnum.DONE) {
      if (this.notifyUI) {
         // Compose refresh UI request.
         getLaunchItemsAction.name = "GetLaunchItems";
         if (globalArray) {
            router = globalArray["router"];
            if (router) {
               actionContent.brokerUrl = router.brokerUrl;
            }
         }
         if (this.content["parsedResult"]) {
            actionContent.warning = this.content.parsedResult["warning"];
            actionContent.desktops = this.content.parsedResult.desktops;
            actionContent.applications = this.content.parsedResult.applications;
            actionContent.applicationSessions = this.content.parsedResult["application-sessions"];
            actionContent.shadowSessions = this.content.parsedResult["shadow-sessions"];
         }
         // Handle the desktopId passed through URI.
         urlHandler = util.getObject(globalArray, "url-handler");
         if (urlHandler) {
            urlParams = urlHandler.params;
            if (urlParams.desktopId) {
               actionContent.desktopId = urlParams.desktopId;
               actionContent.action = urlParams.action;
               if (urlParams.desktopProtocol) {
                  actionContent.targetProtocol = urlParams.desktopProtocol.toUpperCase();
               }
            }
         }
         if (util.brokerSupportApplication() && !this.skipTimer) {
            //launch idle timer
            idleTimerObject = globalArray["idle-timeout-timer"];
            if (!idleTimerObject) {
               Logger.error("timer don't exist when displaying apps & desktops");
               return;
            }
            idleTimeout = idleTimerObject.getIdleTimeoutValue();
            userActivityXMLSendingInterval = idleTimerObject.getSendIntervalValue();
         } else {
            // for older broker, just set value to -1;
            idleTimeout = -1;
            userActivityXMLSendingInterval = -1;
         }
         getLaunchItemsAction.timerInfo = {
            idleTimeout: idleTimeout,
            userActivityXMLSendingInterval: userActivityXMLSendingInterval
         };

         getLaunchItemsAction.content = actionContent;
         JSCDKSetUI(JSON.stringify(getLaunchItemsAction));
      }
   }
   // if the error is special for this handler, alert
   Router.prototype.pushErrorToUser(this);
};

/**
 * parse launchItems information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
GetLaunchItemsHandler.prototype.parseResult = function (responseXML) {
   const responseBody = responseXML;
   const launchItemsResponse = {};
   let result;
   let resultElem;
   const desktops = {};
   let desktopsNodes;
   let desktopsLength;
   const applications = {};
   let applicationsNodes;
   let applicationsLength;
   const sessions = {};
   let applicationSessionsNodes;
   let applicationSessionsLength;
   const shadowSessions = {};
   let shadowSessionsNodes;
   let shadowSessionsLength;
   let errorCodeNode;
   let errorMessageNode;
   let warningMessageNode;
   let warningMessage = null;
   let i;
   const brokerTag = util.getChildNode(responseBody, "broker", 0);

   // Make sure brokerVersion will be set even without getAuthencationStatus
   globalArray["brokerVersion"] = $(brokerTag).attr("version");

   if (!brokerTag) {
      Logger.error("response of GetLaunchItems error.");
      return null;
   }
   resultElem = util.getChildNode(brokerTag, this.responseTag, 0);
   resultElem = util.getChildNode(resultElem, "result", 0);
   if (!resultElem || !resultElem.hasChildNodes()) {
      return null;
   }
   result = resultElem.childNodes[0].nodeValue;
   launchItemsResponse["result"] = result;
   if (result === "ok") {
      warningMessageNode = brokerTag.getElementsByTagName("warning");
      if (warningMessageNode.length > 0) {
         warningMessage = this.htmlDecode($(warningMessageNode).text());
         util.addItemForJson(launchItemsResponse, "warning", warningMessage);
      }
      desktopsNodes = brokerTag.getElementsByTagName("desktop");
      desktopsLength = desktopsNodes.length;
      for (i = 0; i < desktopsLength; i++) {
         const aDesktopInstance = {};

         // set desktop name
         this.setLaunchItemName(aDesktopInstance, desktopsNodes[i]);

         // set desktop id
         this.setLaunchItemId(aDesktopInstance, desktopsNodes[i]);

         // set response state
         this.setResponseState(aDesktopInstance, desktopsNodes[i]);

         // set sessionId
         this.setSessionId(aDesktopInstance, desktopsNodes[i]);

         // set inMaintenanceMode
         this.setInMaintenanceMode(aDesktopInstance, desktopsNodes[i]);

         // set protocol match
         this.setProtocolMatch(aDesktopInstance, desktopsNodes[i]);

         // set preferred protocol, defaultProtocol and supportedProtocol
         this.setProtocols(aDesktopInstance, desktopsNodes[i]);

         // set desktop user preferences
         this.setDesktopUserPrefs(aDesktopInstance, desktopsNodes[i]);

         // set canLogoff: whether the desktop can logoff
         this.setCanLogoff(aDesktopInstance, desktopsNodes[i]);

         // set resetAllowed
         this.setResetAllowed(aDesktopInstance, desktopsNodes[i]);

         // set resetAllowedOnSession
         this.setResetAllowedOnSession(aDesktopInstance, desktopsNodes[i]);

         // set canRollback: whether the desktop can rollback
         this.setCanRollback(aDesktopInstance, desktopsNodes[i]);

         util.addItemForJson(desktops, i, aDesktopInstance);
      }

      util.addItemForJson(launchItemsResponse, "desktops", desktops);

      applicationsNodes = brokerTag.getElementsByTagName("application");
      applicationsLength = applicationsNodes.length;
      for (i = 0; i < applicationsLength; i++) {
         const aApplicationInstance = {};

         // set desktop name
         this.setLaunchItemName(aApplicationInstance, applicationsNodes[i]);

         // set application id
         this.setLaunchItemId(aApplicationInstance, applicationsNodes[i]);

         // set registered files
         this.setLaunchItemRegisteredFile(aApplicationInstance, applicationsNodes[i]);

         // set application type
         this.setApplicationType(aApplicationInstance, applicationsNodes[i]);

         // set application origin id
         this.setLaunchItemOriginId(aApplicationInstance, applicationsNodes[i]);

         // set application version
         this.setApplicationVersion(aApplicationInstance, applicationsNodes[i]);

         // set application publisher
         this.setApplicationPublisher(aApplicationInstance, applicationsNodes[i]);

         // set launch-path
         this.setApplicationLaunchPath(aApplicationInstance, applicationsNodes[i]);

         // set protocol match
         this.setProtocolMatch(aApplicationInstance, applicationsNodes[i]);

         // set preferred protocol, defaultProtocol and supportedProtocol
         this.setProtocols(aApplicationInstance, applicationsNodes[i]);

         // set application icons
         this.setApplicationIcons(aApplicationInstance, applicationsNodes[i]);

         // set application multi session mode
         this.setApplicationMultiSessionMode(aApplicationInstance, applicationsNodes[i]);

         util.addItemForJson(applications, i, aApplicationInstance);
      }

      util.addItemForJson(launchItemsResponse, "applications", applications);

      applicationSessionsNodes = brokerTag.getElementsByTagName("application-session");
      applicationSessionsLength = applicationSessionsNodes.length;
      for (i = 0; i < applicationSessionsLength; i++) {
         const aApplicationSessionInstance = {};

         this.setLaunchItemId(aApplicationSessionInstance, applicationSessionsNodes[i]);

         // set application session origin id
         this.setLaunchItemOriginId(aApplicationSessionInstance, applicationSessionsNodes[i]);

         // set application session origin id
         this.setLaunchItemSessionId(aApplicationSessionInstance, applicationSessionsNodes[i]);

         // set application session state
         this.setResponseState(aApplicationSessionInstance, applicationSessionsNodes[i]);

         // set application sessin asset info
         this.setApplicationSessionRemoteAssetsType(aApplicationSessionInstance, applicationSessionsNodes[i]);

         // set protocol match
         this.setProtocolMatch(aApplicationSessionInstance, applicationSessionsNodes[i]);

         // set preferred protocol, defaultProtocol and supportedProtocol
         this.setProtocols(aApplicationSessionInstance, applicationSessionsNodes[i]);

         util.addItemForJson(sessions, i, aApplicationSessionInstance);
      }

      util.addItemForJson(launchItemsResponse, "application-sessions", sessions);

      shadowSessionsNodes = brokerTag.getElementsByTagName("shadow-session");
      shadowSessionsLength = shadowSessionsNodes.length;
      let isInShadowlist = false;
      for (i = 0; i < shadowSessionsLength; i++) {
         const aShadowSessionInstance = {};

         this.setLaunchItemId(aShadowSessionInstance, shadowSessionsNodes[i]);

         this.setLaunchItemName(aShadowSessionInstance, shadowSessionsNodes[i]);

         this.setProtocols(aShadowSessionInstance, shadowSessionsNodes[i]);

         this.setProtocolMatch(aShadowSessionInstance, shadowSessionsNodes[i]);

         this.setApplicationType(aShadowSessionInstance, shadowSessionsNodes[i]);

         util.addItemForJson(shadowSessions, i, aShadowSessionInstance);

         if (this.collabSessionId === aShadowSessionInstance["id"]) {
            isInShadowlist = true;
         }
      }

      if (isInShadowlist === false && !!this.collabSessionId) {
         Logger.info("shadow-sessions: " + this.collabSessionId + " is not in get-launch-items response.");
      }
      //Logger.debug("shadow-sessions: " + JSON.stringify(shadowSessions));
      util.addItemForJson(launchItemsResponse, "shadow-sessions", shadowSessions);
   } else if (result === "error") {
      errorCodeNode = util.getChildNode(responseBody, "error-code", 0);
      if (errorCodeNode) {
         util.addItemForJson(launchItemsResponse, "error-code", $(errorCodeNode).text());
      }
      errorMessageNode = util.getChildNode(responseBody, "error-message", 0);
      if (errorMessageNode) {
         util.addItemForJson(launchItemsResponse, "error-message", $(errorMessageNode).text());
      }
      const userMessageNode = util.getChildNode(responseBody, "user-message", 0);
      if (userMessageNode) {
         util.addItemForJson(launchItemsResponse, "user-message", $(userMessageNode).text());
      }
   }
   return launchItemsResponse;
};

/**
 * set protocol match or not
 *
 * @param aDesktopInstance[out] a launchItem instance to store corresponding
 *    info
 * @param launchItemNode[in] get information from this launchItem node
 *
 * Default using false for bug 2447664
 */
GetLaunchItemsHandler.prototype.setProtocolMatch = function (aLaunchItemInstance, launchItemNode) {
   const matchNode = util.getChildNode(launchItemNode, "protocol-match", 0),
      match = matchNode ? $(matchNode).text() : "false";
   // For bug 2450040, shadow session doesn't have 'protocol-match' value,
   // So use Blast protocol to do the check.
   if (matchNode === null) {
      let hasBlastProtocol = "false",
         protocolNode = null,
         protocolsNode = util.getChildNode(launchItemNode, "protocols", 0);
      if (protocolsNode) {
         protocolNode = protocolsNode.getElementsByTagName("protocol");
      }
      if (protocolNode) {
         for (let j = 0; j < protocolNode.length; j++) {
            const protocolName = $(util.getChildNode(protocolNode[j], "name", 0)).text();
            if (protocolName === "BLAST") {
               hasBlastProtocol = "true";
               break;
            }
         }
      }
      util.addItemForJson(aLaunchItemInstance, "protocol-match", hasBlastProtocol);
   } else {
      util.addItemForJson(aLaunchItemInstance, "protocol-match", match);
   }
};

/**
 * set preferredProtocol, defaultProtocol and supportedProtocol
 *
 * @param aLaunchItemInstance[out] a launchItem instance to store corresponding
 *    info
 * @param launchItemNode[in] get information from this launchItem node
 */
GetLaunchItemsHandler.prototype.setProtocols = function (aLaunchItemInstance, launchItemNode) {
   let userPreferencesNode;
   let preferenceNode;
   let j;
   let hasDefault = false;
   let protocolsNode;
   let protocolNode;
   let protocolName;
   const protocols = {};
   let preferenceNameAttr;
   let isDefaultNode;
   let supportPreferredProtocol = false;
   userPreferencesNode = util.getChildNode(launchItemNode, "user-preferences", 0);
   if (userPreferencesNode) {
      preferenceNode = userPreferencesNode.getElementsByTagName("preference");
   }
   if (preferenceNode) {
      for (j = 0; j < preferenceNode.length; j++) {
         preferenceNameAttr = preferenceNode[j].getAttribute("name");
         // set preferred protocol here
         if (preferenceNameAttr === "protocol") {
            util.addItemForJson(aLaunchItemInstance, "preferredProtocol", $(preferenceNode[j]).text());
         }
      }
   }
   protocolsNode = util.getChildNode(launchItemNode, "protocols", 0);
   if (protocolsNode) {
      protocolNode = protocolsNode.getElementsByTagName("protocol");
   }
   if (protocolNode) {
      for (j = 0; j < protocolNode.length; j++) {
         protocolName = $(util.getChildNode(protocolNode[j], "name", 0)).text();
         protocols[j] = protocolName;
         if (protocolName === aLaunchItemInstance["preferredProtocol"]) {
            supportPreferredProtocol = true;
         }
         isDefaultNode = util.getChildNode(protocolNode[j], "is-default", 0);
         if (!!isDefaultNode && $(isDefaultNode).text() === "true") {
            // set default protocol if "is-default" is not null
            hasDefault = true;
            util.addItemForJson(aLaunchItemInstance, "defaultProtocol", protocolName);
         }
      }
   }
   // if "is-default" is null, set first protocol as default protocol
   if (!hasDefault && !!protocolNode && protocolNode.length > 0) {
      aLaunchItemInstance["defaultProtocol"] = $(util.getChildNode(protocolNode[0], "name", 0)).text();
   }
   // if no preferred protocol in user preference, set default protocol as it
   if (!aLaunchItemInstance["preferredProtocol"] || !supportPreferredProtocol) {
      util.addItemForJson(aLaunchItemInstance, "preferredProtocol", aLaunchItemInstance["defaultProtocol"]);
   }
   // set supported protocols here
   util.addItemForJson(aLaunchItemInstance, "protocols", protocols);
};

/**
 * set desktop user preferences
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetLaunchItemsHandler.prototype.setDesktopUserPrefs = function (aDesktopInstance, desktopNode) {
   let preferenceNameAttr;
   let j;
   let userPreferencesNode;
   let preferenceNode;
   userPreferencesNode = util.getChildNode(desktopNode, "user-preferences", 0);
   if (userPreferencesNode) {
      preferenceNode = userPreferencesNode.getElementsByTagName("preference");
   }
   if (preferenceNode) {
      for (j = 0; j < preferenceNode.length; j++) {
         preferenceNameAttr = preferenceNode[j].getAttribute("name");
         if (preferenceNameAttr === "alwaysConnect") {
            // set alwaysConnect
            util.addItemForJson(aDesktopInstance, "alwaysConnect", $(preferenceNode[j]).text());
         }
         if (preferenceNameAttr === "screenSize") {
            // set screenSize
            util.addItemForJson(aDesktopInstance, "screenSize", $(preferenceNode[j]).text());
         }
      }
   }
};

/**
 * set launchItem id
 *
 * @param aDesktopInstance[out] a launchItem instance to store corresponding
 *    info
 * @param launchItemNode[in] get information from this launchItem node
 */
GetLaunchItemsHandler.prototype.setLaunchItemId = function (aLaunchItemInstance, launchItemNode) {
   const launchItemIdNode = util.getChildNode(launchItemNode, "id", 0),
      launchItemId = launchItemIdNode ? $(launchItemIdNode).text() : "";

   util.addItemForJson(aLaunchItemInstance, "id", launchItemId);
};

/**
 * set launchItem registered file
 *
 * @param aDesktopInstance[out] a launchItem instance to store corresponding
 *    info
 * @param launchItemNode[in] get information from this launchItem node
 */
GetLaunchItemsHandler.prototype.setLaunchItemRegisteredFile = function (aLaunchItemInstance, launchItemNode) {
   const launchItemIdNode = util.getChildNode(launchItemNode, "registered-file-types", 0);
   let launchItemFileExtensionsNode, nameNode, displayNameNode;
   let name, displayName;
   const files = {};

   if (launchItemIdNode) {
      for (let i = 0; i < launchItemIdNode.childElementCount; i++) {
         launchItemFileExtensionsNode = util.getChildNode(launchItemIdNode, "file-extension", i);
         if (launchItemFileExtensionsNode) {
            const file = {};
            nameNode = util.getChildNode(launchItemFileExtensionsNode, "name", 0);
            name = nameNode ? $(nameNode).text() : "";
            displayNameNode = util.getChildNode(launchItemFileExtensionsNode, "display-name", 0);
            displayName = displayNameNode ? $(displayNameNode).text() : "";
            util.addItemForJson(file, "name", name);
            util.addItemForJson(file, "displayName", displayName);
            files[i] = file;
         }
      }
      util.addItemForJson(aLaunchItemInstance, "registered-file-types", files);
   } else {
      Logger.debug("No registered-file-types info in XML");
   }
};

/**
 * set launchItem name
 *
 * @param aDesktopInstance[out] a launchItem instance to store corresponding
 *    info
 * @param launchItemNode[in] get information from this launchItem node
 */
GetLaunchItemsHandler.prototype.setLaunchItemName = function (aLaunchItemInstance, launchItemNode) {
   const launchItemNameNode = util.getDirectChildNode(launchItemNode, "name", 0),
      launchItemName = launchItemNameNode ? $(launchItemNameNode).text() : "";

   Logger.debug("launchItemName: " + launchItemName);
   util.addItemForJson(aLaunchItemInstance, "name", launchItemName);
};

/**
 * set canLogoff
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetLaunchItemsHandler.prototype.setCanLogoff = function (aDesktopInstance, desktopNode) {
   const inMaintenanceModeNode = util.getChildNode(desktopNode, "in-maintenance-mode", 0),
      inMaintenanceMode = inMaintenanceModeNode ? $(inMaintenanceModeNode).text() : "false",
      responseStateNode = util.getChildNode(desktopNode, "state", 0),
      responseState = responseStateNode ? $(responseStateNode).text() : "No Session",
      responseStateLower = responseState.toLowerCase();

   /*
    * We don't know what does desktop mean.
    * But we will get a desktop indeed.
    * Detail: https://bugzilla.eng.vmware.com/show_bug.cgi?id=2788016
    */
   if (
      inMaintenanceMode === "true" ||
      (responseStateLower !== "connected" && responseStateLower !== "desktop" && responseStateLower !== "disconnected")
   ) {
      /*
       * Logoff of a desktop from the desktop selector is not possible if it is
       * in maintenance mode, if it is checked out by another machine, or if
       * the desktop state is something other than "connected" or "disconnected".
       */
      util.addItemForJson(aDesktopInstance, "canLogoff", "false");
   } else {
      util.addItemForJson(aDesktopInstance, "canLogoff", "true");
   }
};

/**
 * set reset-allowed
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetLaunchItemsHandler.prototype.setResetAllowed = function (aDesktopInstance, desktopNode) {
   const resetAllowedNode = util.getChildNode(desktopNode, "reset-allowed", 0),
      resetAllowed = resetAllowedNode ? $(resetAllowedNode).text() : "false";

   util.addItemForJson(aDesktopInstance, "reset-allowed", resetAllowed);
};

/**
 * set reset-allowed-on-session
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetLaunchItemsHandler.prototype.setResetAllowedOnSession = function (aDesktopInstance, desktopNode) {
   const resetAllowedOnSessionNode = util.getChildNode(desktopNode, "reset-allowed-on-session", 0),
      resetAllowedOnSession = resetAllowedOnSessionNode ? $(resetAllowedOnSessionNode).text() : "false";

   util.addItemForJson(aDesktopInstance, "reset-allowed-on-session", resetAllowedOnSession);
};

/**
 * set in-maintenance-mode
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetLaunchItemsHandler.prototype.setInMaintenanceMode = function (aDesktopInstance, desktopNode) {
   const inMaintenanceModeNode = util.getChildNode(desktopNode, "in-maintenance-mode", 0),
      inMaintenanceMode = inMaintenanceModeNode ? $(inMaintenanceModeNode).text() : "false";

   util.addItemForJson(aDesktopInstance, "in-maintenance-mode", inMaintenanceMode);
};

/**
 * set response state
 *
 * @param aDesktopInstance[out] a launchItem instance to store corresponding
 *    info
 * @param launchItemNode[in] get information from this launchItem node
 */
GetLaunchItemsHandler.prototype.setResponseState = function (aDesktopInstance, launchItemNode) {
   const responseStateNode = util.getChildNode(launchItemNode, "state", 0),
      responseState = responseStateNode ? $(responseStateNode).text() : "No Session";

   util.addItemForJson(aDesktopInstance, "state", responseState);
};

/**
 * set session-id
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetLaunchItemsHandler.prototype.setSessionId = function (aDesktopInstance, desktopNode) {
   const sessionIdNode = util.getChildNode(desktopNode, "session-id", 0),
      sessionId = sessionIdNode ? $(sessionIdNode).text() : "";

   util.addItemForJson(aDesktopInstance, "session-id", sessionId);
};

/**
 * set canRollback
 *
 * @param aDesktopInstance[out] a desktop instance to store corresponding info
 * @param desktopNode[in] get information from this desktop node
 */
GetLaunchItemsHandler.prototype.setCanRollback = function (aDesktopInstance, desktopNode) {
   const offlineStateNode = util.getChildNode(desktopNode, "offline-state", 0),
      offlineState = offlineStateNode ? $(offlineStateNode).text() : "";

   if (offlineState === "checked out") {
      util.addItemForJson(aDesktopInstance, "canRollback", "true");
   } else {
      util.addItemForJson(aDesktopInstance, "canRollback", "false");
   }
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setApplicationType = function (aApplicationInstance, applicationNode) {
   const applicationTypeNode = util.getChildNode(applicationNode, "type", 0),
      applicationType = applicationTypeNode ? $(applicationTypeNode).text() : "";

   util.addItemForJson(aApplicationInstance, "type", applicationType);
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setLaunchItemOriginId = function (aLaunchItemInstance, launchItemNode) {
   const launchItemOriginIdNode = util.getChildNode(launchItemNode, "origin-id", 0),
      launchItemOriginId = launchItemOriginIdNode ? $(launchItemOriginIdNode).text() : "";

   util.addItemForJson(aLaunchItemInstance, "origin-id", launchItemOriginId);
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 * This value only exist with 7.7+ Broker and Agent
 */
GetLaunchItemsHandler.prototype.setLaunchItemSessionId = function (aLaunchItemInstance, launchItemNode) {
   const launchItemSessionIdNode = util.getChildNode(launchItemNode, "session-id", 0),
      launchItemSessionId = launchItemSessionIdNode ? $(launchItemSessionIdNode).text() : "";

   util.addItemForJson(aLaunchItemInstance, "session-id", launchItemSessionId);
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setApplicationVersion = function (aApplicationInstance, applicationNode) {
   const applicationVersionNode = util.getChildNode(applicationNode, "version", 0),
      applicationVersion = applicationVersionNode ? $(applicationVersionNode).text() : "";

   util.addItemForJson(aApplicationInstance, "version", applicationVersion);
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setApplicationPublisher = function (aApplicationInstance, applicationNode) {
   const applicationPublisherNode = util.getChildNode(applicationNode, "publisher", 0),
      applicationPublisher = applicationPublisherNode ? $(applicationPublisherNode).text() : "";

   util.addItemForJson(aApplicationInstance, "publisher", applicationPublisher);
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setApplicationLaunchPath = function (aApplicationInstance, applicationNode) {
   const applicationLaunchPathNode = util.getChildNode(applicationNode, "launch-path", 0),
      applicationLaunchPath = applicationLaunchPathNode ? $(applicationLaunchPathNode).text() : "";

   util.addItemForJson(aApplicationInstance, "launch-path", applicationLaunchPath);
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setApplicationIcons = function (aApplicationInstance, applicationNode) {
   let iconsNode;
   let iconNode;
   let iconPath;
   let iconWidth;
   let iconHeight;
   let icon;
   const icons = {};
   let j;

   iconsNode = util.getChildNode(applicationNode, "icons", 0);
   if (iconsNode) {
      iconNode = iconsNode.getElementsByTagName("icon");
   }
   if (iconNode) {
      for (j = 0; j < iconNode.length; j++) {
         icon = {};
         iconPath = $(util.getChildNode(iconNode[j], "path", 0)).text();
         iconWidth = $(util.getChildNode(iconNode[j], "width", 0)).text();
         iconHeight = $(util.getChildNode(iconNode[j], "height", 0)).text();
         util.addItemForJson(icon, "path", iconPath);
         util.addItemForJson(icon, "width", iconWidth);
         util.addItemForJson(icon, "height", iconHeight);
         icons[j] = icon;
      }
   }

   util.addItemForJson(aApplicationInstance, "icons", icons);
};

/**
 * set application type
 *
 * @param aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setApplicationSessionRemoteAssetsType = function (
   aApplicationSessionInstance,
   applicationSessionNode
) {
   const applicationSessionRemoteAssetNode = util.getChildNode(applicationSessionNode, "has-remotable-assets", 0),
      applicationSessionRemoteAsset = applicationSessionRemoteAssetNode
         ? $(applicationSessionRemoteAssetNode).text()
         : "false";

   util.addItemForJson(aApplicationSessionInstance, "has-remotable-assets", applicationSessionRemoteAsset);
};

/**
 * action to disconnect desktop
 *
 */
GetLaunchItemsHandler.prototype.disconnectDesktop = function () {
   let logoutBrokerObject;
   let router;
   let handlerList;

   logoutBrokerObject = util.getObject(globalArray, "do-logout");
   if (!logoutBrokerObject) {
      logoutBrokerObject = new DoLogoutHandler();
      globalArray[logoutBrokerObject.messageName] = logoutBrokerObject;
      globalArray[logoutBrokerObject.responseTag] = logoutBrokerObject;
   } else {
      logoutBrokerObject.resetData();
   }

   router = util.getObject(globalArray, "router");

   if (!!logoutBrokerObject && !!router) {
      handlerList = logoutBrokerObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};

/**
 * action to reset desktop
 *
 * @param desktopId [in] desktop's id used to reset desktop
 */
GetLaunchItemsHandler.prototype.resetDesktop = function (desktopId) {
   let resetDesktopObject;
   let router;
   let handlerList;

   router = util.getObject(globalArray, "router");
   if (!!router && !!desktopId) {
      /**
       * each reset-desktop object corresponds to a desktop-id
       * if the object already exists, reset data; otherwise create a new
       * instance firstly locate handler from
       * MessageHandler.prototype.requestIdKV
       */
      resetDesktopObject = router.getHandler("reset-desktop", desktopId);
      if (!resetDesktopObject) {
         resetDesktopObject = new ResetDesktopHandler();
         globalArray[resetDesktopObject.messageName + resetDesktopObject.requestId] = resetDesktopObject;
         globalArray[resetDesktopObject.responseTag + resetDesktopObject.requestId] = resetDesktopObject;
         /**
          * update MessageHandler.prototype.requestIdKV
          * the format is {responseTag1+desktop-id1 : requestId1,
          * responseTag2+desktop-id2 : requestId2} such as key of
          * ResetDesktopHandler is "reset-desktop"+desktopId
          */
         MessageHandler.prototype.requestIdKV[resetDesktopObject.responseTag + desktopId] =
            resetDesktopObject.requestId;
      } else {
         resetDesktopObject.resetData();
      }

      if (!!resetDesktopObject && !!router) {
         resetDesktopObject.setRequestXML(desktopId);
         handlerList = resetDesktopObject.composeHandlerList();
         router.postMessage(handlerList);
      }
   }
};

/**
 * action to restart desktop
 *
 * @param desktopId [in] desktop's id used to restart desktop
 */
GetLaunchItemsHandler.prototype.restartDesktop = function (desktopId) {
   let restartDesktopObject;
   let router;
   let handlerList;

   router = util.getObject(globalArray, "router");
   if (!!router && !!desktopId) {
      /**
       * each restart-desktop object corresponds to a desktop-id
       * if the object already exists, restart data; otherwise create a new
       * instance firstly locate handler from
       * MessageHandler.prototype.requestIdKV
       */
      restartDesktopObject = router.getHandler("restart-desktop", desktopId);
      if (!restartDesktopObject) {
         restartDesktopObject = new RestartDesktopHandler();
         globalArray[restartDesktopObject.messageName + restartDesktopObject.requestId] = restartDesktopObject;
         globalArray[restartDesktopObject.responseTag + restartDesktopObject.requestId] = restartDesktopObject;
         /**
          * update MessageHandler.prototype.requestIdKV
          * the format is {responseTag1+desktop-id1 : requestId1,
          * responseTag2+desktop-id2 : requestId2} such as key of
          * RestartDesktopHandler is "restart-desktop"+desktopId
          */
         MessageHandler.prototype.requestIdKV[restartDesktopObject.responseTag + desktopId] =
            restartDesktopObject.requestId;
      } else {
         restartDesktopObject.restartData();
      }

      if (!!restartDesktopObject && !!router) {
         restartDesktopObject.setRequestXML(desktopId);
         handlerList = restartDesktopObject.composeHandlerList();
         router.postMessage(handlerList);
      }
   }
};

/**
 * action to logoff desktop
 *
 * @param desktopId [in] desktop's id used to log off desktop
 * @param sessionId [in] desktop's sessionId to use
 */
GetLaunchItemsHandler.prototype.logoffDesktop = function (desktopId, sessionId) {
   let killSessionObject;
   let router;
   let handlerList;

   router = util.getObject(globalArray, "router");

   if (!!router && !!desktopId && !!sessionId && sessionId.trim() !== "") {
      /**
       * each kill-session object corresponds to a desktop-id
       * if the object already exists, reset data; otherwise create a new
       * instance firstly locate handler from
       * MessageHandler.prototype.requestIdKV
       */
      killSessionObject = router.getHandler("kill-session", desktopId);
      if (!killSessionObject) {
         killSessionObject = new KillSessionHandler();
         globalArray[killSessionObject.messageName + killSessionObject.requestId] = killSessionObject;
         globalArray[killSessionObject.responseTag + killSessionObject.requestId] = killSessionObject;
         /**
          * update MessageHandler.prototype.requestIdKV
          * the format is {responseTag1+desktop-id1 : requestId1,
          * responseTag2+desktop-id2 : requestId2} such as key of
          * KillSessionHandler is "kill-session"+desktopId
          */
         MessageHandler.prototype.requestIdKV[killSessionObject.responseTag + desktopId] = killSessionObject.requestId;
      } else {
         killSessionObject.resetData();
      }

      if (!!killSessionObject && !!router) {
         killSessionObject.setRequestXML(sessionId);
         handlerList = killSessionObject.composeHandlerList();
         router.postMessage(handlerList);
      }
   }
};

/**
 * find desktop by desktopId(desktop id or desktop name)
 *
 * @param desktopId [in] desktop id to use.
 * @param desktops [in] desktop list to be located.
 * @return target desktop whose id equals desktopId
 */
GetLaunchItemsHandler.prototype.findDesktop = function (desktopId, desktops) {
   let targetDesktop;
   let oneDesktop;

   if (desktopId) {
      for (oneDesktop in desktops) {
         /*
          * Use normalized (URL encoded and lower case) DN as the desktop
          * ID to follow Horizon's new rule.
          * Also, user may use the desktop as the desktop ID.
          */
         if (
            encodeURIComponent(desktops[oneDesktop].id.toLowerCase()) === encodeURIComponent(desktopId.toLowerCase()) ||
            desktops[oneDesktop].name.toLowerCase() === desktopId.toLowerCase()
         ) {
            targetDesktop = desktops[oneDesktop];
            break;
         }
      }
   }
   return targetDesktop;
};

/**
 * https://confluence.eng.vmware.com/display/HorizonArchitecture/
 * Allow+single+user+to+launch+multiple+instances+of+same+RDSH+App+on+multiple+clients
 *
 * Set application multi session mode status according to one of
 * "DISABLED"
 * "ENABLED_DEFAULT_OFF"
 * "ENABLED_DEFAULT_ON"
 * "ENABLED_ENFORCED"
 * ""
 *
 *
 * @param {object} aApplicationInstance[out] a application instance to store
 *    corresponding info
 * @param {object} applicationNode[in] get information from this application node
 */
GetLaunchItemsHandler.prototype.setApplicationMultiSessionMode = function (aApplicationInstance, applicationNode) {
   const applicationMultiSessionModeNode = util.getChildNode(applicationNode, "multi-session-mode", 0),
      applicationMultiSessionMode = applicationMultiSessionModeNode ? $(applicationMultiSessionModeNode).text() : "";
   util.addItemForJson(aApplicationInstance, "multi-session-mode", applicationMultiSessionMode);
};

GetLaunchItemsHandler.prototype.htmlDecode = function (input) {
   const output = new DOMParser().parseFromString(input, "text/html");
   return output.documentElement.textContent;
};
// add more functions here
