<header *ngIf="!option.hide" [hidden]="isChromeWS1Mode" class="navbar ui-navbar" [attr.role]="'banner' | i18nv2">
   <div class="container">
      <div class="navbar-header navbar-icon navbar-brand ui-brand" tabindex="-1" [attr.aria-label]= "'horizon_logo' | i18nv2" >
         <img class="ui-horizon-logo" [src]="this.imageAsset.horizonLogo">
      </div>
      <form>
         <div class="hide-font" role="alert" aria-invalid="true" *ngIf="search !== ''">
               {{'TOTAL' | i18nv2}} {{totalnum}} {{'available_results' | i18nv2}}
         </div>
         <div class="search-bar" [ngClass]="{ searchFocus: focus, searchBlur: blur }" [hidden]="!option.searchBar" [attr.role]="'Search' | i18nv2">
            <div class="search-content">
               <span class="search-icon" aria-hidden="true">
                  <img class="ui-search-icon" [src]="this.imageAsset.searchIcon" aria-hidden="true">
               </span>
               <label for="header-search">{{'Search' | i18nv2}}</label>
               <input id="header-search" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (ngModelChange)="onSearchChanged($event)" class="search-input" type="search" placeholder="{{'Search' | i18nv2}}" (focus)="focus=true;blur=false;" (blur)="blur=true;focus=false;" autofocus tabindex="0">
               <span *ngIf="search !== ''" (click)="clearSearch()" (keydown)="searchClearKeydown($event)" class="search-clear-icon" tabindex="0"></span>
            </div>
         </div>
      </form>
      <div class="navbar-header ui-navbar-links header-buttons" [attr.role]="'navigation' | i18nv2">
         <ul class="nav navbar-right">
            <li *ngIf="showPreference" class="all-choice first-item" tabindex="-1" (keydown)="enterKeypress($event, showAll)">
               <button id="showAllBtn" (click)="showAll()" [ngClass]="showAllStyle" title="{{'show_all' | i18nv2}}" tabindex="0">
                  <img *ngIf="showAllOn" class="ui-show-all-on-icon" [src]="this.imageAsset.appIconInactive">
                  <img *ngIf="!showAllOn" class="ui-show-all-off-icon" [src]="this.imageAsset.appIcon2X">
               </button>
            </li>
            <li *ngIf="showPreference" class="favorite-choice" tabindex="-1" (keydown)="enterKeypress($event, showFavorites)">
               <button id="showFavoritesBtn" (click)="showFavorites()" [ngClass]="showFavStyle" title="{{'show_fav' | i18nv2}}" tabindex="0">
                  <img *ngIf="!showFavOn" class="ui-show-fav-off-icon" [src]="this.imageAsset.favIconInactive">
                  <img *ngIf="showFavOn" class="ui-show-fav-on-icon" [src]="this.imageAsset.favIconActive">
               </button>
            </li>
            <li tabindex="-1" (keydown)="enterKeypress($event, showSetting)">
               <button id="settingsBtn" (click)="showSetting()"  title="{{'settings' | i18nv2}}" class="setting" tabindex="0">
                  <img class="ui-setting-icon"  [src]="this.imageAsset.setIconInactive">
               </button>
            </li>
            <li tabindex="-1" (keydown)="enterKeypress($event, showHelp)">
               <button id="helpBtn" (click)="showHelp()" title="{{'header_help_link' | i18nv2}}" class="help" tabindex="0">
                  <img class="ui-help-icon" [src]="this.imageAsset.helpIconInactive">
               </button>
            </li>
            <li tabindex="-1" [hidden]="!option.logout" (keydown)="enterKeypress($event, logout)" >
               <button id="logoutBtn" (click)="logout()" title="{{'dialog_title_logout' | i18nv2}}" class="logout" tabindex="0">
                  <img class="ui-logout-icon" [src]="this.imageAsset.logoutIconInactive">
               </button>
            </li>
         </ul>
      </div>
   </div>
</header>
