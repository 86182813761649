/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * ********************************************************
 *
 *    launch-uri.service.ts
 *
 * Angular service to parse launch parameters.
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import UrlHandler from "../../jscdk/controllers/urlHandler";
import { clientUtil } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class LaunchUriService {
   private urlHandler = new UrlHandler();
   public urlParams: UrlParams = {} as UrlParams;
   public searchParams: string = null;
   public hash: string = null;

   constructor() {
      this.setUrlParamsAndHash(location.search, location.hash);
   }

   public setUrlParamsAndHash = (uri: string, hash: string) => {
      if (clientUtil.isTitanClient()) {
         if (uri) {
            this.searchParams = decodeURIComponent(uri);
         }
      } else {
         this.searchParams = uri;
      }
      this.handleHashParams(uri, hash);

      this.urlHandler.handleUrlParams(this.searchParams);
      const exportAPI: HorizonClient = {
         urlParams: this.urlHandler.params,
         urlHandler: this.urlHandler
      };
      window["hzClient"] = exportAPI;
      this.urlParams = this.urlHandler.params;
   };

   public init() {
      Logger.info("LaunchUriService init");
   }

   public isDomainChange = (): boolean => {
      if (this.searchParams) {
         return this.searchParams.indexOf("prompt=select_account") !== -1;
      }
      return false;
   };

   private handleHashParams = (uri: string, hash: string) => {
      if (!clientUtil.isTitanClient()) {
         //only titan support hash parameters.
         return;
      }

      if (!uri && hash) {
         this.searchParams = "?" + decodeURIComponent(hash.split("?")[1]);
      }
      if (hash) {
         this.hash = hash.split("?")[0].replace("#/", "");
      }
   };
}
