/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { InjectionToken, NgModule } from "@angular/core";

export const WindowToken = new InjectionToken<Window>("Window");
export function windowProvider() {
   return window;
}

@NgModule({
   declarations: [],
   providers: [{ provide: WindowToken, useFactory: windowProvider }]
})
export class WindowModule {}
