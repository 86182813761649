/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { TitanTokenService } from "./titan-token.service";

import {
   AccessTokenPayload,
   AccessTokenRequest,
   ExternalAccessTokenRequest,
   TitanRefreshTokenRequest,
   AuthorizationUrlRequest,
   AuthorizationUrlPayload,
   ExternalAccessTokenPayload,
   TitanDSpecRequest,
   TitanDSpecPayload,
   TitanRefreshTokenPayload,
   TitanLogoutRequest,
   TitanLogoutPayload,
   TitanLaunchItemPayload,
   TitanGetAppSessionsPayload,
   TitanGetAppSessionsRequest,
   TitanSessionOperation,
   TitanSessionOpsRequest,
   TitanSessionOpsPayload,
   TitanSessionOpsStatusResponse,
   TitanUserProfileFavoritesRequest,
   TitanUserProfilePreferenceRequest,
   TitanUserProfileRequestV2,
   TitanUserProfileFavoritesResponse,
   TitanUserProfilePayloadV2,
   TitanUserProfilePreferenceResponse,
   TitanRdsLicenseRequestV2,
   TitanRdsLicenseResponse
} from "./restful/restful.module";

import { catchError, map } from "rxjs/operators";
import { TitanSessionSpecRequest } from "./restful/request/TitanSessionSpecRequest";
import { TitanSessionSpecPayload } from "./restful/response/TitanSessionSpecPayload";
import { TitanMsg } from "../../core/services/event";
import { TitanRevokeOtaRequest } from "./restful/request/TitanRevokeOtaRequest";
import { TitanErrorReportRequest } from "./restful/request/TitanErrorReportRequest";
import { PathService } from "./titan-path.interface";
import { MultiTenantService } from "./MultiTenantService.service";
import { EventBusService, BusEvent } from "@html-core";
import { UserInfoService } from "../../shared/common/service/user-info.service";
// leave here for local debugging.
//import launchJson from './mock/launch-item.json';
//import dspecJson from '../mock/titan-error.json';
//import rxJson from './mock/rx-profile.json';

@Injectable({
   providedIn: "root"
})
export class RestfulService {
   constructor(
      private httpClient: HttpClient,
      private userInfoService: UserInfoService,
      private titanTokenService: TitanTokenService,
      private multiTenantService: MultiTenantService,
      private pathService: PathService,
      private eventBusService: EventBusService
   ) {}

   private getParams(req: any) {
      let params = new HttpParams();
      Object.keys(req).forEach(function (key) {
         if (req[key] != null && req[key] !== "") {
            params = params.append(key, req[key]);
         }
      });
      return params;
   }

   public getAccessToken = (code: string, code_verifier?: string): Observable<AccessTokenPayload> => {
      const request: AccessTokenRequest = {
         code: code,
         redirectUri: this.multiTenantService.getOIDCRedirectUrl(),
         idpTenantDomain: this.userInfoService.getDomainName()
      };
      if (code_verifier) {
         request.codeVerifier = code_verifier;
      }
      return this.httpClient.post<AccessTokenPayload>(this.pathService.AUTH_ACCESS_TOKEN_PATH, request);
   };

   public getExternalAccessToken = (code: string): Observable<ExternalAccessTokenPayload> => {
      const request: ExternalAccessTokenRequest = {
         jwtToken: code,
         jwtSource: "CONNECTION_SERVER"
      };
      return this.httpClient.post<ExternalAccessTokenPayload>(
         this.pathService.AUTH_EXTERNAL_ACCESS_TOKEN_PATH,
         request
      );
   };

   public updateTokens = (obj: TitanRefreshTokenPayload) => {
      if (obj.access_token) {
         this.titanTokenService.setToken(obj);
      }
   };

   public refreshToken = (req: TitanRefreshTokenRequest): Observable<TitanRefreshTokenPayload> => {
      let params = new HttpParams();
      Object.keys(req).forEach(function (key) {
         if (key !== "refreshToken" && req[key] !== null && req[key] !== "") {
            params = params.append(key, encodeURIComponent(req[key]));
         }
      });
      return this.httpClient.post<TitanRefreshTokenPayload>(
         this.pathService.AUTH_REFRESH_TOKEN_PATH,
         req.refreshToken,
         {
            params
         }
      );
   };

   public revokeOTA = (ota: string): Observable<any> => {
      const req: TitanRevokeOtaRequest = {
         ota: ota
      };
      return this.httpClient.post<any>(this.pathService.AUTH_REVOKE_OTA, req, {
         observe: "response"
      });
   };

   public removeAll = () => {
      this.eventBusService.dispatch(new BusEvent.ClearRunningSession());
      this.titanTokenService.removeAll();
   };

   public getRedirectUrl = (req: AuthorizationUrlRequest): Observable<AuthorizationUrlPayload> => {
      const params = this.getParams(req);
      return this.httpClient.get<AuthorizationUrlPayload>(this.pathService.AUTH_AUTH_URL_PATH, { params });
   };

   public getLaunchItems = (): Observable<TitanLaunchItemPayload> => {
      return this.httpClient.get<TitanLaunchItemPayload>(this.pathService.PORTAL_LAUNCH_ITEMS);
      //return of(launchJson);
   };

   public getDSpec = (obj: TitanDSpecRequest): Observable<HttpResponse<TitanDSpecPayload>> => {
      return this.httpClient.post<TitanDSpecPayload>(this.pathService.PORTAL_GENERATE_DSPEC, obj, {
         observe: "response"
      });
   };

   public getSessionSpec = (obj: TitanSessionSpecRequest): Observable<HttpResponse<TitanSessionSpecPayload>> => {
      return this.httpClient.post<TitanSessionSpecPayload>(this.pathService.PORTAL_GENERATE_DSPEC, obj, {
         observe: "response"
      });
   };

   public postLogoutV2 = (callback) => {
      const req: TitanLogoutRequest = {
         idpTenantDomain: this.userInfoService.getDomainName(),
         postLogoutUri: this.multiTenantService.getOIDCPostLogoutUrl(),
         refreshToken: this.titanTokenService.getRefreshToken(),
         idToken: this.titanTokenService.getIdToken()
      } as TitanLogoutRequest;
      return new Promise((resolve, reject) => {
         this.httpClient.post<TitanLogoutPayload>(this.pathService.AUTH_AUTH_LOGOUT, req).subscribe({
            next: (res) => {
               if (callback) {
                  callback(res?.logoutUrl);
               }
               resolve(res?.logoutUrl);
            },
            error: () => {
               reject();
            }
         });
      });
   };

   public getLogout = (callback) => {
      const req = {
         id_token: this.titanTokenService.getIdToken(),
         idp_tenant_domain: this.userInfoService.getDomainName()
      };
      const params = this.getParams(req);
      return new Promise((resolve, reject) => {
         this.httpClient.get<TitanLogoutPayload>(this.pathService.AUTH_GET_LOGOUT, { params }).subscribe({
            next: (res: TitanLogoutPayload) => {
               if (callback) {
                  callback(res?.logoutUrl);
               }
               resolve(res?.logoutUrl);
            },
            error: () => {
               reject();
            }
         });
      });
   };

   public logout = (callback) => {
      // we need to move to v2 API for Titan appblast
      if (__CLIENT_TYPE__ === "titan") {
         return this.postLogoutV2(callback);
      }
      // we need to revert to get API for chrome native client.
      return this.getLogout(callback);
   };

   public getApplicationSessions = (): Observable<TitanGetAppSessionsPayload> => {
      const obj: TitanGetAppSessionsRequest = {};
      return this.httpClient.get<TitanGetAppSessionsPayload>(this.pathService.APP_SESSIONS);
   };

   public handleSessionOps = (sessionId: string, ops: TitanMsg.TITAN_VM_OPS): Observable<TitanSessionOperation> => {
      // For LOGOFF we use session-id, for restart/reset we use launchId/entitlementId
      const req: TitanSessionOpsRequest = {
         id: sessionId,
         actionType: "LOGOFF"
      };
      if (ops === TitanMsg.TITAN_VM_OPS.RESET) {
         req.actionType = "RESET";
      } else if (ops === TitanMsg.TITAN_VM_OPS.RESTART) {
         req.actionType = "RESTART";
      }
      return this.sendSessionOperation(req);
   };

   private sendSessionOperation = (req: TitanSessionOpsRequest): Observable<TitanSessionOperation> => {
      const opsResponse: TitanSessionOperation = {
         id: req.id,
         actionType: req.actionType
      } as TitanSessionOperation;
      return this.httpClient
         .post<TitanSessionOpsPayload>(this.pathService.USERS_ACTION, req, {
            observe: "response"
         })
         .pipe(
            map((httpResponse: HttpResponse<TitanSessionOpsPayload>) => {
               if (httpResponse.status === 201) {
                  opsResponse.status = "SUCCESS";
                  opsResponse.statusUrl = httpResponse.headers.get("location");
               } else if (httpResponse.status === 202) {
                  opsResponse.status = "PENDING";
                  opsResponse.statusUrl = httpResponse.headers.get("location");
               } else {
                  opsResponse.status = "FAILED";
               }
               return opsResponse;
            }),
            catchError((error) => {
               return throwError(() => error);
            })
         );
   };

   public querySessionOps = (req: TitanSessionOperation): Observable<TitanSessionOpsStatusResponse> => {
      const queryPath = `${this.pathService.USERS}/portal/v1/users/action/${req.actionType}/status/${req.id}`;
      const params = new HttpParams();
      params.append("action", encodeURIComponent(req.actionType));
      params.append("id", encodeURIComponent(req.id));
      return this.httpClient.get<TitanSessionOpsStatusResponse>(queryPath, { params });
   };

   public getRxTitanPreferenceV2 = (req: TitanUserProfileRequestV2): Observable<TitanUserProfilePayloadV2> => {
      return this.httpClient.post<TitanUserProfilePayloadV2>(this.pathService.RX_USER_PROFILE_V2, req);
   };

   public addRxTitanFavoriteItems = (
      req: TitanUserProfileFavoritesRequest
   ): Observable<TitanUserProfileFavoritesResponse> => {
      return this.httpClient.patch<TitanUserProfileFavoritesResponse>(
         this.pathService.RX_USER_PROFILE_V2_FAVORITES,
         req
      );
   };

   public deleteRxTitanFavoriteItems = (
      req: TitanUserProfileFavoritesRequest
   ): Observable<TitanUserProfileFavoritesResponse> => {
      // delete with body is supported in restful 1.1, but angular11 don't support it.
      // use options as a workaround.
      const options = {
         headers: new Headers({
            "Content-Type": "application/json"
         }),
         body: {}
      };
      options.body = req;
      //@ts-ignore
      return this.httpClient.delete<TitanUserProfileFavoritesResponse>(
         this.pathService.RX_USER_PROFILE_V2_FAVORITES,
         //@ts-ignore
         options
      );
   };

   public addRxTitanPreferences = (
      req: TitanUserProfilePreferenceRequest
   ): Observable<TitanUserProfilePreferenceResponse> => {
      return this.httpClient.put<TitanUserProfilePreferenceResponse>(
         this.pathService.RX_USER_PROFILE_V2_PREFERENCES,
         req
      );
   };

   public updateRxTitanRdsLicenseV2 = (req: TitanRdsLicenseRequestV2): Observable<TitanRdsLicenseResponse> => {
      return this.httpClient.put<TitanRdsLicenseResponse>(this.pathService.RX_V2_RDS_LICENSE, req);
   };

   public postTitanErrorReport = (req: TitanErrorReportRequest): Observable<any> => {
      return this.httpClient.post<any>(this.pathService.PORTAL_ERROR_REPORT, req);
   };
}
