<div>
   <button class="horizon-logo-image" title="{{'ABOUT_T' | i18nv2}}" (click)="aboutClicked()">
      <img class="sidebar-ui-horizon-logo" [src]="getTenantHTMLAccessLogo()">
   </button>
   <div class="head-panel-button-bar">
      <ul>
         <button id="keyboard" class="icon-button icon-keyboard-image" [hidden]="!isSupportedMobileDevice()"
            (click)="toggleKeyboard()" [attr.role]="'BUTTON' | i18nv2"
            name="{{'TOGGLE_KEYBOARD_BTN_NAME' | i18nv2}}" [attr.aria-label]="'TOGGLE_KEYBOARD_BTN_NAME' | i18nv2">
         </button>
         <button id="touchMode" class="icon-button" [ngClass]="this.isTrackPadMode ? 'icon-button-trackpad' : 'icon-button-nativetouch'" [hidden]="!isSupportedMobileDevice()"
            (click)="changeTouchMode()" [attr.role]="'BUTTON' | i18nv2"
            name="{{'CHANGE_TOUCH_MODE_BTN_NAME' | i18nv2}}" [attr.aria-label]="'CHANGE_TOUCH_MODE_BTN_NAME' | i18nv2"></button>
         <button id="cad" class="icon-button icon-cad-image icon-button-hover"
            [ngClass]="{'disable':hideCtrlAltDelete()}"
            (click)="ctrlAltDeleteClicked()" [attr.aria-label]="'CTRL_ALT_DEL_BTN_NAME' | i18nv2"
            [attr.role]="'BUTTON' | i18nv2"
            tabindex="0" name="{{'CTRL_ALT_DEL_BTN_NAME' | i18nv2}}" [attr.aria-disabled]="hideCtrlAltDelete() ? 'true' : 'false'">
            <img class="ui-cad-icon" [src]="this.imageAsset.iclSendCad">
         </button>
         <button id="fileTransfer" class="icon-button icon-file-transfer-image icon-button-hover"
            [hidden]="isSupportedMobileDevice()" [ngClass]="{'disable':!showFTPanel()}"
            (click)="openFileTransferPanel()" [attr.role]="'BUTTON' | i18nv2"
            [attr.aria-label]="'FILE_TRANSFER_BTN_NAME' | i18nv2"
            tabindex="0" name="{{'FILE_TRANSFER_BTN_NAME' | i18nv2}}" [attr.aria-disabled]="!showFTPanel() ? 'true' : 'false'">
            <img class="ui-fileTransfer-icon" [src]="this.imageAsset.iclTransfer">
         </button>
         <button id="clipboard" class="icon-button icon-clipboard-image icon-button-hover" (click)="openClipboardClicked()"
            [hidden]="isSupportedMobileDevice()" [attr.role]="'BUTTON' | i18nv2"
            [ngClass]="{'clipboardClickable': enableClipboardNotification && isWebPanelFreeClipboard}"
            [attr.aria-label]="'COPY_PASTE_BTN_NAME' | i18nv2"
            tabindex="0" name="{{'COPY_PASTE_BTN_NAME' | i18nv2}}" [attr.aria-disabled]="enableClipboardNotification && isWebPanelFreeClipboard ? 'false' : 'true'">
            <img class="ui-clipboard-icon" [src]="this.imageAsset.iclClipboard">
            <!--<span *ngIf="isWebPanelFreeClipboard" id="clipboard-tooltip"> </span>-->
         </button>
         <button id="contextMenu" class="icon-button icon-context-menu-image icon-button-hover" aria-expanded="false"
            (click)="globalContextMenuClicked($event)" [attr.role]="'BUTTON' | i18nv2"
            tabindex="0" name="{{'OPEN_MENU_BTN_NAME' | i18nv2}}" [attr.aria-label]="'OPEN_MENU_BTN_NAME' | i18nv2">
            <img class="ui-contextMenu-icon" [src]="this.imageAsset.iclSidebarContext">
         </button>
      </ul>
   </div>
</div>

<sidebar-global-menu [show]="showGlobalContextMenu" (panelEvent)="togglePanel($event)"></sidebar-global-menu>

