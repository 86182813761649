/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * usb-service.ts --
 *
 * Module for wrapping usb redirection to angular factory service. usb Service
 * is a singleton service.
 *
 */
import { RemoteSessionEventService, SessionMsg } from "../../common/remote-session/remote-session-event.service";
import { UsbChannel, UsbRedirState } from "./usb-channel";
import { UsbProtocol, USBControlMsg } from "./usb-protocol";
import { FeatureConfigs } from "../../common/model/feature-configs";
import { Subject, Subscription } from "rxjs";
import { RemoteUsbDevice } from "./usb-remote";
import { BlastWmks } from "../common/blast-wmks.service";
import { EventBusService, IMessage, Logger } from "@html-core";
import { RawImpl } from "./usblib";
import { USBNotificationService } from "./usb-notification.service";
import { NotificationType, usb } from "./usb.event";
import { USBDeviceProxyService } from "./usb-device-proxy.service";

export abstract class USBService {
   protected usbChannelMap: Map<string, UsbChannel>;
   protected usbEventSubject$ = new Subject<IMessage>();
   protected usbAPISupported: boolean = false;
   protected killSwitchOn: boolean = true;
   protected localDeviceMap: Map<number, RemoteUsbDevice>;
   protected logger = new Logger(Logger.USB);
   //used by UI
   public redirectedDevices: Array<RemoteUsbDevice>;

   public abstract getUserSelectedDevices(wmksKey: string);
   protected abstract _getUserSelectDevices();
   protected abstract _addRedirectedDevices(remoteDevice: RemoteUsbDevice);
   protected abstract _onRemoveRedirectedDevices(remoteDevice);
   protected abstract _getSplitDeviceInfo(usbChannel, selectDevice);
   protected abstract _checkGooglePolicy(vid, pid);

   constructor(
      protected remoteSessionEventService: RemoteSessionEventService,
      protected featureConfigs: FeatureConfigs,
      protected usbNotif: USBNotificationService,
      protected eventBusService: EventBusService,
      protected usbDeviceProxyService: USBDeviceProxyService
   ) {
      this.usbChannelMap = new Map<string, UsbChannel>();
      this.localDeviceMap = new Map<number, RemoteUsbDevice>();
      this.redirectedDevices = new Array<RemoteUsbDevice>();
   }

   public subscribe = (callback: (UsbMsg) => void): Subscription => {
      return this.usbEventSubject$.subscribe({
         next: callback
      });
   };

   public init = () => {
      this.usbNotif.init();
      this.remoteSessionEventService.addEventListener(
         SessionMsg.SESSION_CONNECTING_MSG,
         this.usbOnWmksSessionConnecting
      );
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTED_MSG, this.usbOnWmksSessionConnected);
      this.remoteSessionEventService.addEventListener(
         SessionMsg.SESSION_DISCONNECTED_MSG,
         this.usbOnWmksSessionDisconnected
      );
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_REMOVED_MSG, this.usbOnWmksSessionRemoved);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, this.usbOnWmksSessionChanged);
   };

   /*
    *---------------------------------------------------------------------------
    *
    * getSessionState --
    *
    * Get the USB Redirection state of current session.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public getSessionState = (wmksKey): UsbRedirState => {
      // [1] whether USB redirection over vvc is support in current session.
      const usbChannel = this.usbChannelMap.get(wmksKey);
      if (!usbChannel) {
         return UsbRedirState.DISABLED;
      }
      return usbChannel.state;
   };

   /*
    *---------------------------------------------------------------------------
    *
    * isUsbEnabled  --
    *
    * Whether USB redirection is supported in current session.
    * Return true if
    * 1. The current browser and OS support USB redirection.
    * 2. Google admin policy and broker configuration don't disable it.
    * 3. usbChannel is setup and state is available
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */

   public isUsbEnabled = (wmksKey: string): boolean => {
      if (this.usbAPISupported && this.killSwitchOn) {
         const usbChannel = this.usbChannelMap.get(wmksKey);
         if (usbChannel && usbChannel.state === UsbRedirState.AVAILABLE) {
            return true;
         }
         return false;
      }
      return false;
   };

   /*
    *---------------------------------------------------------------------------
    *
    * releaseSelectedDevices --
    *
    * Release one redirected USB device.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public releaseSelectedDevices = async (remoteDevice: RemoteUsbDevice) => {
      const usbDevice = this.localDeviceMap.get(remoteDevice.getId());
      let usbChannel = null;
      if (usbDevice) {
         await usbDevice.releaseDevice();
         usbChannel = this.usbChannelMap.get(usbDevice.wmksKey);
      }

      //this function maybe fail in the session has been disconnected.
      if (usbChannel && usbDevice) {
         this.sendUnplugInDevice(usbChannel, usbDevice, null, null);
      }

      this._removeRedirectedDevices(remoteDevice);
      this.localDeviceMap.delete(remoteDevice.getId());
      this.usbEventSubject$.next(new usb.DeviceChanged());
   };

   /*
    *---------------------------------------------------------------------------
    *
    * getRedirDevices --
    *
    * Return all the redirected USB devices for current session.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public getRedirDevices = (wmksKey) => {
      const devices: RemoteUsbDevice[] = [];
      for (const device of this.redirectedDevices) {
         if (device.wmksKey === wmksKey) {
            if (device.status === UsbProtocol.REDIRSTATUS.FAILED) {
               this.popUSBRedirectionErrorMsg(wmksKey, device);
               continue;
            }
            devices.push(device);
         }
      }
      return devices;
   };

   private popUSBRedirectionErrorMsg = (wmksKey: string, device: RemoteUsbDevice) => {
      this.usbEventSubject$.next(new usb.RedirectionNotSupported(wmksKey, device));
      this._releaseOneUSBDevice(device);
   };

   //@Override
   public onReady = (wmksKey: string): void => {
      const usbChannel: UsbChannel = this.usbChannelMap.get(wmksKey);
      if (!usbChannel) {
         this.logger.info("onReady - can't find channel for " + wmksKey);
         return;
      }
      this.logger.info("Wait 2s for usb status ready.");
      setTimeout(() => {
         this._sendIsUsbAvailableMessage(usbChannel);
      }, 2000);
   };

   //@Override
   public onDisconnect = (wmksKey: string): void => {};
   //@Override
   public onUrbMessage = (wmksKey: string, data: any): void => {
      const usbChannel: UsbChannel = this.usbChannelMap.get(wmksKey);
      if (!usbChannel) {
         this.logger.info("onUrbMessage - can't find channel for " + wmksKey);
         return;
      }
      this._handleChannelUrbMessage(usbChannel, data);
   };

   /*
    *---------------------------------------------------------------------------
    *
    * usbOnWmksSessionConnecting --
    *
    * Callback function for sessionConnecting signal in
    * RemoteSessionEventService.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public usbOnWmksSessionConnecting = (session: BlastWmks) => {
      if (session.isApplicationSession) {
         this.logger.info("Disable USB redirection for application.");
         return;
      }
      this.logger.info("usbOnWmksSessionConnecting " + session.name);
      if (this.usbAPISupported && this.killSwitchOn) {
         if (session.enableUsb) {
            this.usbDeviceProxyService.setSessionKey(session.key);
            const usbChannel = new UsbChannel(session.vdpService, session.key, this, session.usbTicket);
            this.usbChannelMap.set(session.key, usbChannel);
         } else {
            this.logger.info(session.name + " usb redirection is not supported on " + session.key);
         }
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * usbOnWmksSessionChanged --
    *
    * Callback function for activeSessionChanged signal in
    * RemoteSessionEventService.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public usbOnWmksSessionChanged = (session: BlastWmks, isNewSession) => {
      // event will be emit whether this session support USB redirection or not.
      if (session) {
         this.logger.info("usbOnWmksSessionChanged " + session.name);
         this.usbEventSubject$.next(new usb.SessionChanged(session.key, isNewSession));
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * usbOnWmksSessionConnected --
    *
    * Callback function for sessionConnected signal in
    * RemoteSessionEventService.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public usbOnWmksSessionConnected = (session) => {
      this.logger.info(session.name + " usbOnWmksSessionConnected ");
   };

   /*
    *---------------------------------------------------------------------------
    *
    * usbOnWmksSessionDisconnected --
    *
    * Callback function for sessionDisconnected signal in
    * RemoteSessionEventService.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public usbOnWmksSessionDisconnected = async (session) => {
      this.logger.info(session.name + " usbOnWmksSessionDisconnected ");
      if (this.usbChannelMap.get(session.key)) {
         await this._releaseUSBDevicesInCurrentSession(session.key);
      }
      setTimeout(() => {
         //This part is to hide usb panel under 2 situations
         if (!session.isDestroyed) {
            Logger.info("usb session is disconncted though network recovery");
            this.usbEventSubject$.next(new usb.SessionDisconnected());
         }
         if (this.usbChannelMap.size === 0) {
            Logger.info("All usb cannels are disconncected");
            this.usbEventSubject$.next(new usb.SessionDisconnected());
         }
         //Wait for 500ms for SessionRemoved message arrive
      }, 500);
   };

   /*
    *---------------------------------------------------------------------------
    *
    * usbOnWmksSessionRemoved --
    *
    * Callback function for sessionRemoved signal in RemoteSessionEventService.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public usbOnWmksSessionRemoved = async (session) => {
      this.logger.info(session.name + " usbOnWmksSessionRemoved ");
      if (this.usbChannelMap.get(session.key)) {
         await this._releaseUSBDevicesInCurrentSession(session.key);
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * sendUnplugInDevice --
    *
    * Send the USB msg "UnplugDevice" to the agent.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public sendUnplugInDevice = (usbChannel: UsbChannel, remoteDevice: RemoteUsbDevice, onDone, onError) => {
      const message: USBControlMsg = UsbProtocol.generateUsbRemoveDeviceMsg(remoteDevice.getId());
      usbChannel
         .sendPostMsgRPC(message)
         .then((reply) => {
            this.logger.debug("sendUnplugInDevice reply = " + reply);
            if (onDone) {
               onDone();
            }
         })
         .catch((reply) => {
            this.logger.debug("sendUnplugInDevice error = " + reply);
            if (onError) {
               onError();
            }
         });
   };

   private _sendIsUsbAvailableMessage = (usbChannel: UsbChannel) => {
      const message: USBControlMsg = UsbProtocol.generateUsbAvailableMsg(1, usbChannel.usbTicket);
      this.logger.info(usbChannel.wmksKey + " sendIsUsbAvailableMessage");
      // The reply may take 120 seconds at most if UEM is installed.
      usbChannel
         .sendAsyncMsgRPC(message)
         .then((reply) => {
            if (reply.includes("DenyReason")) {
               usbChannel.state = UsbRedirState.NOT_AVAILABLE;
               Logger.info(UsbProtocol.LOG + usbChannel.wmksKey + "'s USB redirection is disabled by policy");
            } else {
               usbChannel.state = UsbRedirState.AVAILABLE;
            }
            this.usbEventSubject$.next(new usb.SessionStatusUpdate());
            this.logger.info(usbChannel.wmksKey + " control channel reply = UsbAvailableDone " + reply);
            this._handleUsbFilter(usbChannel, reply);
         })
         .catch((reply) => {
            this.logger.info(usbChannel.wmksKey + " control channel reply = UsbAvailableAbort " + reply);
            usbChannel.state = UsbRedirState.NOT_AVAILABLE;
            this.usbEventSubject$.next(new usb.SessionStatusUpdate());
         });
   };

   private _handleUsbFilter(channel: UsbChannel, reply: string) {
      let usbFilter: string = null;
      const filterFound = reply.lastIndexOf(UsbProtocol.USB_FILTER_PREFIX);
      try {
         if (reply && filterFound !== -1) {
            usbFilter = reply.substr(
               reply.lastIndexOf(UsbProtocol.USB_FILTER_PREFIX) + UsbProtocol.USB_FILTER_PREFIX.length
            );
            if (usbFilter) {
               usbFilter = usbFilter.substr(0, usbFilter.indexOf(UsbProtocol.USB_FILTER_SUFFIX));
            }
         }

         if (usbFilter) {
            channel.usbFilter.UnMarshallFilters(usbFilter);
         }
      } catch (e) {
         this.logger.info("parse usb filter exception with" + e);
      }
   }

   /*
    *---------------------------------------------------------------------------
    *
    * sendPlugInDevice --
    *
    * Send the USB msg "PlugInDevice" to the agent.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   private _sendPlugInDevice(usbChannel: UsbChannel, remoteDevice: RemoteUsbDevice, onDone, onError) {
      //bora/apps/viewusb/framework/usb/clientd/dev.cc#512
      const message: USBControlMsg = UsbProtocol.generatePlugInDeviceMsg(
         4, //USB redirection version.
         remoteDevice.getId(),
         remoteDevice.getRawDeviceDescriptor(),
         remoteDevice.getRawConfigurationDescriptors(),
         remoteDevice.currentConfig.configurationValue,
         1,
         remoteDevice.getStringStore()
      );

      this.logger.info("sendPlugInDevice = " + JSON.stringify(message));

      usbChannel
         .sendAsyncMsgRPC(message)
         .then((reply) => {
            onDone();
            this.usbEventSubject$.next(new usb.DeviceChanged());
         })
         .catch((reply) => {
            onError();
            this.usbEventSubject$.next(new usb.DeviceChanged());
         });
   }

   /*
    *---------------------------------------------------------------------------
    *
    * _handleChannelUrbMessage --
    *
    * handle the URB request from agent.
    *
    * Dispatch the URB request from different agents to different devices.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   private _handleChannelUrbMessage(usbChannel: UsbChannel, data) {
      const packet = WMKS.Packet.createFromBufferLE(data);
      const vUrb: any = UsbProtocol.handleUrbMessage(packet);
      const vUrbPrefix: string = "<" + vUrb.itemId + ">";

      this.logger.debug(vUrbPrefix + "handleChannelUrbMessage URB = " + JSON.stringify(vUrb));
      this.logger.debug(vUrbPrefix + "packet reminding = " + packet.bytesRemaining());
      if (vUrb.status === false) {
         this.logger.debug(vUrbPrefix + "ignore unimplemented message");
         return;
      }

      const localDevice = this.localDeviceMap.get(vUrb.clientPlugNo);
      if (!localDevice) {
         this.logger.debug("can't find device with id " + vUrb.clientPlugNo);
         return;
      }
      localDevice.handleUrbMessage(vUrb, packet, (reply) => {
         if (reply == null) {
            this.logger.info(vUrbPrefix + "handleChannelUrbMessage return null");
            return;
         }

         this.logger.debug(vUrbPrefix + "reply length [" + reply.bytesRemaining() + "]");

         usbChannel.sendPostMsgFastRPC(
            reply,
            () => {},
            () => {
               this.logger.info(vUrbPrefix + "sendPostMsgFastRPC abort");
               this.usbEventSubject$.next(new usb.DeviceChanged());
            }
         );
      });
   }

   public onSelectConfiguration = () => {
      this.usbEventSubject$.next(new usb.DeviceChanged());
   };

   public onClaimError = (device: RemoteUsbDevice) => {
      this.usbEventSubject$.next(new usb.RedirectionNotSupported(device.wmksKey, device));
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _releaseUSBDevicesInCurrentSession --
    *
    * Helper function to release all the redirection USB devices in current
    * channel.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   private _releaseUSBDevicesInCurrentSession = (wmksKey): Promise<boolean> => {
      return new Promise((resolve) => {
         const redirectedDevicesForCurrentSession = [];
         for (const device of this.redirectedDevices) {
            if (device.wmksKey === wmksKey) {
               redirectedDevicesForCurrentSession.push(device);
            }
         }
         const promises = [];
         for (const device of redirectedDevicesForCurrentSession) {
            promises.push(this._releaseOneUSBDevice(device));
         }
         Promise.all(promises)
            .then(() => {
               this.usbChannelMap.delete(wmksKey);
               resolve(true);
            })
            .catch((e) => {
               Logger.debug("Error happens when releasing devices: " + e);
               resolve(false);
            });
      });
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _releaseOneUSBDevice --
    *
    * Helper function to destroy one USB device in current channel.
    * @param remoteDevice The usb device content of RemoteUsbDevice.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   protected _releaseOneUSBDevice = async (remoteDevice: RemoteUsbDevice) => {
      const usbDevice = this.localDeviceMap.get(remoteDevice.getId());
      let usbChannel = null;
      if (usbDevice) {
         await usbDevice.releaseDevice();
         usbChannel = this.usbChannelMap.get(usbDevice.wmksKey);
      }

      //this function maybe fail in the session has been disconnected.
      if (usbChannel && usbDevice) {
         this.sendUnplugInDevice(usbChannel, usbDevice, null, null);
      }

      this._removeRedirectedDevices(remoteDevice);
      this.localDeviceMap.delete(remoteDevice.getId());
      this.usbEventSubject$.next(new usb.DeviceChanged());
   };

   /*
    *---------------------------------------------------------------------------
    *
    * getUserSelectedDevices --
    *
    * call the platform API to pop up a dialog for device selection.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */

   protected getSelectedDevices = async (wmksKey) => {
      const usbChannel: UsbChannel = this.usbChannelMap.get(wmksKey);
      let selectDevice: RawImpl = null;
      if (!usbChannel) {
         this.logger.error("getUserSelectedDevices don't find a valid channel with " + wmksKey);
         return;
      }

      // Handle redirection failure.
      // Remove the device if unable to redirect after 10 seconds.
      setTimeout(() => {
         if (usbChannel.state === UsbRedirState.AVAILABLE) {
            this.usbEventSubject$.next(new usb.DeviceChanged());
         }
      }, 10000);

      try {
         selectDevice = await this._getUserSelectDevices();
      } catch (e) {
         return;
      }

      if (!selectDevice) {
         this.logger.info("_getUserSelectDevices return null device");
         return;
      }
      return selectDevice;
   };

   public initializeDevices = async (selectDevice: RawImpl, wmksKey: string) => {
      const usbChannel: UsbChannel = this.usbChannelMap.get(wmksKey);
      const splitDeviceInfo = this._getSplitDeviceInfo(usbChannel, selectDevice);
      const remoteDevice = new RemoteUsbDevice(selectDevice, wmksKey, splitDeviceInfo);
      this.logger.info("USB device -> " + remoteDevice.getProductName() + " selected");
      remoteDevice.usbEventHandler = this;
      remoteDevice
         .initialize()
         .then((ret) => {
            //Need to check policy after getting the device release number
            if (this._checkUsbBlockedByPolicy(usbChannel, remoteDevice)) {
               this.eventBusService.dispatch(
                  new usb.NotificationMsg(NotificationType.BLOCK_BY_POLICY, selectDevice.raw.productName)
               );
               return false;
            }
            if (ret) {
               this.logger.info("USB device ->  " + remoteDevice.getProductName() + " init success");
               return true;
            } else {
               this.logger.info("USB device ->  " + remoteDevice.getProductName() + " init failed");
               return false;
            }
         })
         .then(
            (ret) => {
               if (!ret) {
                  this.logger.info("USB device ->  " + remoteDevice.getProductName() + " not supported");
                  this.usbEventSubject$.next(new usb.RedirectionNotSupported(wmksKey, remoteDevice));
                  return;
               } else {
                  this.logger.info("USB device ->  " + remoteDevice.getProductName() + " supported");
               }
               this._addRedirectedDevices(remoteDevice);
               this.localDeviceMap.set(remoteDevice.getId(), remoteDevice);
               this._sendPlugInDevice(
                  usbChannel,
                  remoteDevice,
                  () => {
                     this.usbEventSubject$.next(new usb.DeviceChanged());
                  },
                  () => {
                     this.usbEventSubject$.next(new usb.DeviceChanged());
                  }
               );
            },
            () => {
               this.usbEventSubject$.next(new usb.DeviceChanged());
            }
         );
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _checkUsbBlockedByPolicy --
    *
    * When Google policy exists, always use USB policy in google Admin regardless
    * of Agent policy.
    * If device in both blockList and allowList, block list comes first.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */

   private _checkUsbBlockedByPolicy = (usbChannel: UsbChannel, device: RemoteUsbDevice): boolean => {
      const vid: number = device.localDevice.vendorId;
      const pid: number = device.localDevice.productId;
      const rel: string = device.getBcdDeviceReleaseNumber();
      //vid/pid in USB filter policy on agent are in hexadecimal
      const deviceResult = usbChannel.usbFilter.checkDeviceFiltered(
         vid.toString(16).padStart(4, "0"),
         pid.toString(16).padStart(4, "0"),
         rel,
         null
      );
      //vid/pid in USB Google policy are in decimal
      const googlePolicyResult = this._checkGooglePolicy(vid.toString(), pid.toString());
      if (googlePolicyResult !== null) {
         deviceResult.disabled = googlePolicyResult;
      }
      return deviceResult.disabled;
   };

   private _removeRedirectedDevices = (remoteDevice: RemoteUsbDevice) => {
      for (let i = 0; i < this.redirectedDevices.length; ++i) {
         if (this.redirectedDevices[i].getId() === remoteDevice.getId()) {
            this.redirectedDevices.splice(i, 1);
            break;
         }
      }
      this._onRemoveRedirectedDevices(remoteDevice);
   };
}
