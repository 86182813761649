
<div>
   <div class="ui-pattern-logo">
      <img [src]="this.imageAsset.logo" alt="{{logoAltText}}">
   </div>
   <div [ngSwitch]="loginName">
      <login-waiting *ngSwitchCase="'Waiting'" [formData]='formData'></login-waiting>
      <login-disclaimer *ngSwitchCase="'Disclaimer'" [formData]='formData'></login-disclaimer>
      <login-windows-password *ngSwitchCase="'WindowsPassword'" [formData]='formData'></login-windows-password>
      <login-securid-passcode *ngSwitchCase="'SecurIDPasscode'" [formData]='formData'></login-securid-passcode>
      <login-unauthenticated *ngSwitchCase="'Unauthenticated'" [formData]='formData'></login-unauthenticated>
      <login-securid-pin-change *ngSwitchCase="'SecurIDPinChange'" [formData]='formData' ></login-securid-pin-change>
      <login-change-password *ngSwitchCase="'WindowsPasswordExpired'" [formData]='formData'></login-change-password>
      <login-securid-next-token *ngSwitchCase="'SecurIDNextTokenCode'" [formData]='formData'></login-securid-next-token>
      <login-securid-wait *ngSwitchCase="'SecurIDWait'" [formData]='formData'></login-securid-wait>
   </div>
</div>