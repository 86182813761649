/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 *
 * url-parameter.service.ts
 *    Parse the launch Url parameter and store them
 */

import { Inject, Injectable } from "@angular/core";
import * as CST from "@html-core";
import { DISPLAY } from "../../../shared/common/service/monitor-info.service";
import { iChromeLaunchSetting } from "../../../shared/common/service/prefdata";
import { WindowToken } from "../../../shared/common/window.module";
import { defaultNetworkStateConfig } from "../../../shared/desktop/networkState/network-state.service";
import { ChromeSDKVchanSettings } from "../../SDK/sdk-service";

export interface GoogleAdminUsbDevice {
   vid: string;
   pid: string;
}

@Injectable({
   providedIn: "root"
})
export class UrlParameterService {
   private queryObject: any;
   public isApplicationSession: boolean;
   public isMultiSession: boolean;
   public isShadow: boolean;
   public sessionKey: string;
   public desktopName: string;
   public blastURL: string;
   public logLevel: string;
   public acceptedLanguage: string;
   public entitleId: string;
   public sessionId: string;
   public username: string;
   public launchSettings = {} as iChromeLaunchSetting;
   public connectOptions: any;
   public enableUsb: boolean = false;
   public usbTicket: string;
   public sdkOptions = {} as ChromeSDKVchanSettings;
   public fromFileAssociation: boolean;
   public browseURL: string;
   public showNumOnTopbar: boolean;
   public policyAllowFileDownload: boolean;
   public policyAllowFileUpload: boolean;
   public ws1Mode: boolean = false;
   public enableRTAVH264Codec: boolean;
   public enableRTAVOpusCodec: boolean;
   public enableRTAVDTX: boolean;
   public hardwareAccelerationOption: string;

   constructor(@Inject(WindowToken) private window: Window) {
      const search = window.location.search.substring(1);
      try {
         this.queryObject = JSON.parse(
            '{"' +
               decodeURI(search).replace("\\", "\\\\").replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
               '"}'
         );
      } catch (e) {
         this.queryObject = {};
      }

      this.isApplicationSession = this._getBool(this.queryObject.isApplicationSession);
      this.sessionKey = this._getString(this.queryObject.sessionKey);
      this.isShadow = this._getBool(this.queryObject.isShadow, false);
      this.desktopName = this._getString(this.queryObject.desktopName);
      this.blastURL = this._getString(this.queryObject.blastURL);
      this.logLevel = this._getString(this.queryObject.logLevel);
      this.isMultiSession = this._getBool(this.queryObject.isMultiSession, false);
      this.acceptedLanguage = this._getString(this.queryObject.acceptedLanguage, "en-US");
      this.fromFileAssociation = this._getBool(this.queryObject.fromFileAssociation, false);
      CST.clientUtil.updateFAStatus(this.fromFileAssociation);
      this.sdkOptions = JSON.parse(this._getString(this.queryObject.sdkOptions, "{}"));
      this.enableUsb = this._getBool(this.queryObject.enableUsb, false);
      this.usbTicket = this._getString(this.queryObject.usbTicket, "");
      this.entitleId = this._getString(this.queryObject.entitleId, "");
      this.sessionId = this._getString(this.queryObject.sessionId, "");
      this.username = this._getString(this.queryObject.username, "");
      const launchSettings = JSON.parse(this._getString(this.queryObject.launchSettings, "{}"));
      this.browseURL = this._getString(this.queryObject.browseURL); // browse URL for URL redirection
      this.policyAllowFileDownload = this._getBoolValue(launchSettings.policyAllowFileDownload, true);
      this.policyAllowFileUpload = this._getBoolValue(launchSettings.policyAllowFileUpload, true);
      this.enableRTAVH264Codec = this._getBoolValue(launchSettings.enableRTAVH264Codec, true);
      this.enableRTAVOpusCodec = this._getBoolValue(launchSettings.enableRTAVOpusCodec, true);
      this.enableRTAVDTX = this._getBoolValue(launchSettings.enableRTAVDTX, true);
      this.hardwareAccelerationOption = this._getString(launchSettings.hardwareAccelerationOption, "no-preference");
      this.ws1Mode = this._getBoolValue(launchSettings.ws1Mode, false);

      const defaultLaunchSettings: iChromeLaunchSetting = {
         enableHighResolution: false,
         useMacOSXKeyMappings: false,
         enableWindowsKey: false,
         enableWindowsDeleteKey: false,
         enableFitToViewer: false,
         rxParameters: {},
         enableFolderSharing: false,
         enableMultiMonitor: false,
         enableMP4: false,
         useRTAVChannel: false,
         enableGeolocationSharing: false,
         donotShowGeolocationDialog: false,
         enableMediaStreamPermission: false,
         donotShowMediaStreamPermissionDialog: false,
         selectedMonitors: {},
         enableWebRTCRedirection: true,
         enableWebRTCRedirectionWeb: true,
         enableScreenSharing: true,
         enableBCR: true,
         showNumOnTopbar: false,
         enableKeyMapping: true,
         keyMappingSetting: [],
         displaySetting: DISPLAY.DisplayOption.ALL,
         policyAllowFileDownload: true,
         policyAllowFileUpload: true,
         enableNetworkIndicator: true,
         networkStateConfig: defaultNetworkStateConfig,
         disableNetworkStateDisplay: false,
         enableRTAVH264Codec: true,
         enableRTAVOpusCodec: true,
         enableRTAVDTX: true,
         hardwareAccelerationOption: "no-preference",
         enableBlastCodec: false,
         enableAdvancedCodec: false,
         delayKeysWhenMoveInside: false
      };

      for (const key in defaultLaunchSettings) {
         if (launchSettings[key] !== undefined) {
            this.launchSettings[key] = launchSettings[key];
         } else {
            this.launchSettings[key] = defaultLaunchSettings[key];
         }
      }

      const connectOptionKeyMap = {
         enableHighResMode: "enableHighResolution",
         useMacOSXKeySettings: "useMacOSXKeyMappings",
         enableWindowsKey: "enableWindowsKey",
         enableWindowsDeleteKey: "enableWindowsDeleteKey",
         enableMP4: "enableMP4",
         enableFitToViewer: "enableFitToViewer",
         showNumOnTopbar: "showNumOnTopbar",
         enableKeyMapping: "enableKeyMapping",
         enableNetworkIndicator: "enableNetworkIndicator",
         networkStateConfig: "networkStateConfig",
         disableNetworkStateDisplay: "disableNetworkStateDisplay",
         enableRTAVH264Codec: "enableRTAVH264Codec",
         enableRTAVOpusCodec: "enableRTAVOpusCodec",
         enableRTAVDTX: "enableRTAVDTX",
         hardwareAccelerationOption: "hardwareAccelerationOption",
         enableBlastCodec: "enableBlastCodec",
         enableAdvancedCodec: "enableAdvancedCodec",
         delayKeysWhenMoveInside: "delayKeysWhenMoveInside"
      };

      this.connectOptions = {};
      for (const key in connectOptionKeyMap) {
         if (connectOptionKeyMap.hasOwnProperty(key)) {
            this.connectOptions[key] = this.launchSettings[connectOptionKeyMap[key]];
         }
      }
   }

   private _getBool = (value: string, defaultValue?: boolean) => {
      if (value === "true") {
         return true;
      }
      if (value === "false") {
         return false;
      }
      return defaultValue;
   };

   private _getBoolValue = (value: boolean, defaultValue?: boolean) => {
      if (value === true) {
         return true;
      }
      if (value === false) {
         return false;
      }
      return defaultValue;
   };

   private _getString = (value: string, defaultValue?: string) => {
      if (!value) {
         return defaultValue;
      }
      return decodeURIComponent(value);
   };
}
