/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * doUnlockHandler.js --
 *
 *      Implementation of the message handler to send doUnlock.
 */

import $ from "jquery";
import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { StateEnum, MessageHandler } from "./messageHandler";
import { HaveAuthTypesEnum } from "./getConfigurationHandler";
import { prepareForAlreadyAuthenticated } from "../timer/timer-util";

export default function DoUnlockHandler() {
   // member variables below
   this.messageName = "do-unlock";
   this.messageText = "do-unlock";
   this.responseTag = "unlock";

   // register dependencies here
   const router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
DoUnlockHandler.prototype = new MessageHandler();
DoUnlockHandler.constructor = DoUnlockHandler;

DoUnlockHandler.prototype.setRequestXML = function () {
   // handle SAML
   let samlNameElem,
      samlSecret,
      haveAuthTypesElem,
      authTypes,
      getConfigObject = globalArray["get-configuration"];

   this.requestXML = "";
   if (getConfigObject) {
      authTypes = getConfigObject.getAuthTypes();
      samlSecret = util.getObject(globalArray, "samlArt");
      // Without samlArt, don't send saml type in unlock to fix bug 2163135
      if (!!samlSecret && authTypes === HaveAuthTypesEnum.HAVEAUTHTYPE_SAML) {
         samlNameElem = util.createElement("name", "saml");
         haveAuthTypesElem = util.createElement("have-authentication-types", samlNameElem);
         this.requestXML = haveAuthTypesElem;
      }
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
DoUnlockHandler.prototype.onUpdated = function () {
   let parsedResult,
      getConfigurationObject = globalArray["get-configuration"],
      router = util.getObject(globalArray, "router");

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.state === StateEnum.DONE) {
      // prepare for already authentication case(locked is treated as already
      // authenticated)
      prepareForAlreadyAuthenticated();
      // handler response to continue re-authentication
      if (this.content["parsedResult"]) {
         parsedResult = this.content["parsedResult"];
         if (getConfigurationObject) {
            //let GetConfigurationHandler handles the authentication related
            // dealing.
            getConfigurationObject.handleParsedResult(parsedResult, true);
         } else {
            Logger.error("Wrong case, no get-configuration but has do-unlock");
         }
      }
   }
   // push error to UI
   router.pushErrorToUser(this);
};

/**
 * parse locale information from the response XML of GetConfiguration
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return
 *    null
 */
DoUnlockHandler.prototype.parseResult = function (responseXML) {
   let responseBody,
      brokerTag,
      responses,
      resultText,
      unlockResponse = {},
      authTag,
      authScreenTag;

   if (!responseXML || responseXML === "") {
      return null;
   }
   responseBody = $(responseXML);
   brokerTag = $(responseBody.children()[0]);
   if (!brokerTag) {
      Logger.error("response of getAuthenticationStatus error");
      return null;
   }
   responses = brokerTag.children(this.responseTag);
   resultText = responses.children("result").text();
   if (!!resultText && resultText.trim() !== "") {
      unlockResponse["result"] = resultText;
   } else {
      Logger.error("response of getAuthenticationStatus error, no valid result tag");
      return null;
   }
   if (resultText === "ok") {
      authTag = brokerTag.find("authentication");
      if (!authTag) {
         Logger.error("No authentication element in response of do-unlock");
         return null;
      }

      authScreenTag = authTag.children("screen");
      if (!authScreenTag) {
         Logger.error("No screen element in response of do-unlock");
         return null;
      }
      util.addItemForJson(unlockResponse, "screen", $(authScreenTag).children("name").text());

      $(authScreenTag)
         .children("params")
         .children("param")
         .each(function () {
            const child = $(this),
               nameText = child.find("name").text(),
               isReadOnly = child.find("readonly").length,
               valueList = [];

            if (isReadOnly !== 0) {
               util.addItemForJson(unlockResponse, nameText + "ReadOnly", "yes");
            } else {
               util.addItemForJson(unlockResponse, nameText + "ReadOnly", "no");
            }
            child.find("value").each(function () {
               valueList.push($(this).text());
            });
            util.addItemForJson(unlockResponse, nameText, valueList);
         });
   } else if (resultText === "error") {
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(unlockResponse, nameText, $(this).text());
      });
   }
   return unlockResponse;
};
