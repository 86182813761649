/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { TranslateService } from "@html-core";
import Logger from "../../../core/libs/logger";
import { BusEvent, EventBusService } from "../../../core/services/event";
import { ModalDialogService } from "../commondialog/dialog.service";
import { JscdkWrapper } from "../jscdk/jscdk-wrapper";

@Injectable({
   providedIn: "root"
})
export class ConnectionRetryService {
   private connectionRetryDialogId: string = null;
   private destroyAzureWaitingUIObserver = null;
   private azureReconnectionErrorUIObserver = null;
   private updateAzureWaitingUI = null;

   constructor(
      private translate: TranslateService,
      private eventBusService: EventBusService,
      private modalDialogService: ModalDialogService,
      private jscdkWrapper: JscdkWrapper
   ) {}

   public onTextChange = (newText) => {
      this.modalDialogService.changeContent(this.connectionRetryDialogId, newText);
   };

   public onUpdateWaitingUI = (waitMinutes, type) => {
      const text = this.translate._T(
         "azure_connection_retry_M",
         waitMinutes,
         this.translate._T(type),
         this.translate._T(type)
      );
      setTimeout(() => {
         this.onTextChange(text);
      });
   };

   public onReconnectionErrorUI = () => {
      const text = this.translate._T("azure_connection_failed_M");
      setTimeout(() => {
         if (this.modalDialogService.isDialogOpen(this.connectionRetryDialogId)) {
            this.onTextChange(text);
            this.modalDialogService.changeTitle(this.connectionRetryDialogId, this.translate._T("ERROR"));
            this.modalDialogService.changeBtnLabel(this.connectionRetryDialogId, undefined);
         }
      });
   };

   public errorCloseCallback = (skipAbort: boolean = false) => {
      if (this.destroyAzureWaitingUIObserver) {
         this.destroyAzureWaitingUIObserver.unsubscribe();
      }
      if (this.azureReconnectionErrorUIObserver) {
         this.azureReconnectionErrorUIObserver.unsubscribe();
      }
      if (this.updateAzureWaitingUI) {
         this.updateAzureWaitingUI.unsubscribe();
      }

      if (window.location.hash.indexOf("#/desktop") !== 0) {
         if (!skipAbort) {
            this.jscdkWrapper.abortRequest(null, true);
         }
      } else {
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
         // stop connection retry
         if (!skipAbort) {
            this.jscdkWrapper.abortRequest(null, true);
         }
      }

      if (this.modalDialogService.isDialogOpen(this.connectionRetryDialogId)) {
         this.modalDialogService.close(this.connectionRetryDialogId);
      }
   };

   public showDialog = (waitMinutes, type, jscdkInvoker) => {
      if (!this.modalDialogService.isDialogOpen(this.connectionRetryDialogId)) {
         this.updateAzureWaitingUI = this.eventBusService.listen("UpdateAzureWaitingUI").subscribe((msg) => {
            const data = msg.data;
            this.onUpdateWaitingUI(data.waitMinutes, data.type);
         });

         this.azureReconnectionErrorUIObserver = this.eventBusService
            .listen(BusEvent.HeadRoomTimeoutErrorMsg.MSG_TYPE)
            .subscribe(() => {
               this.onReconnectionErrorUI();
            });
         this.destroyAzureWaitingUIObserver = this.eventBusService.listen("DestroyAzureWaitingUI").subscribe(() => {
            this.errorCloseCallback(true);
         });
         this.connectionRetryDialogId = this.modalDialogService.showError({
            data: {
               title: this.translate._T("azure_connection_retry_T"),
               content: this.translate._T(
                  "azure_connection_retry_M",
                  waitMinutes,
                  this.translate._T(type),
                  this.translate._T(type)
               ),
               buttonLabelConfirm: this.translate._T("CANCEL")
            },
            callbacks: {
               confirm: this.errorCloseCallback
            }
         });
      } else {
         Logger.debug("reconnect dialog content changed");
      }
   };
}
