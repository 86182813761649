/**
 * ******************************************************
 * Copyright (C) 2019-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * foreign-cert.service.ts --
 *
 */

import Logger from "../../../core/libs/logger";
import { ForeignCertService } from "../../../shared/base/foreign-cert.service";
import { Injectable } from "@angular/core";

export class HTML5ForeignCertService extends ForeignCertService {
   private readonly popupParameters: string =
      "menubar=no, location=no, toolbar=no, " + "resizable=no, scrollbars=yes, height=600, width=600";

   private monitorWindowClose = (openedwindow, closeCallback, timeoutCallback) => {
      const closeCheckInterval = 1000;
      const maxWaitTime = 30000;
      const timeoutTimer = setTimeout(() => {
         clearInterval(timer);
         if (openedwindow && !openedwindow.closed) {
            openedwindow.close();
         }
         timeoutCallback();
      }, maxWaitTime);
      const timer = setInterval(() => {
         if (!openedwindow || openedwindow.closed) {
            clearInterval(timer);
            clearTimeout(timeoutTimer);
            closeCallback();
         }
      }, closeCheckInterval);
   };

   public displayCert = (uri: string) => {
      const name = uri;
      const ua = navigator.userAgent.toLowerCase();
      const isIE = ua.indexOf("msie") !== -1 || ua.indexOf("trident") !== -1 || ua.indexOf("edge") !== -1;
      return new Promise((resolve, reject) => {
         if (isIE) {
            Logger.info("need page jump for IE and Edge");
            reject("needPageJump");
            return;
         }
         const openedwindow = window.open(uri, name, this.popupParameters);
         if (!openedwindow) {
            Logger.warning("user blocks the pop up window");
            reject("popupBlocked");
            return;
         }
         this.monitorWindowClose(openedwindow, resolve, reject);
      });
   };
}
