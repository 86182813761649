/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * USB Redirection Message used inside USB only
 *
 * @format
 *
 */

import { IMessage } from "@html-core";
import { RemoteUsbDevice } from "./usb-remote";

export enum NotificationType {
   ALREADY_REDIRECT_MSG,
   NOT_SUPPORT_MSG,
   BLOCK_BY_POLICY
}

export namespace usb {
   export class NotificationMsg implements IMessage {
      public static readonly MSG_TYPE = "UsbNotificationMsg";
      public type: string = NotificationMsg.MSG_TYPE;
      public notifyMsg: NotificationType;
      public productName: string;
      constructor(msgType: NotificationType, productName: string) {
         this.productName = productName;
         this.notifyMsg = msgType;
      }
   }

   export class RedirectionNotSupported implements IMessage {
      public static readonly MSG_TYPE = "UsbRedirectionNotSupported";
      public type: string = RedirectionNotSupported.MSG_TYPE;
      public wmksKey: string;
      public device: RemoteUsbDevice;
      constructor(wmksKey: string, device: RemoteUsbDevice) {
         this.wmksKey = wmksKey;
         this.device = device;
      }
   }

   export class DeviceAlreadyRedirected implements IMessage {
      public static readonly MSG_TYPE = "UsbDeviceAlreadyRedirected";
      public type: string = DeviceAlreadyRedirected.MSG_TYPE;
      public device: RemoteUsbDevice;
      constructor(device: RemoteUsbDevice) {
         this.device = device;
      }
   }

   export class SessionChanged implements IMessage {
      public static readonly MSG_TYPE = "UsbSessionChanged";
      public type: string = SessionChanged.MSG_TYPE;
      public wmksKey: string;
      public isNewSession: boolean;
      constructor(wmksKey: string, isNewSession: boolean) {
         this.wmksKey = wmksKey;
         this.isNewSession = isNewSession;
      }
   }

   export class SessionRemoved implements IMessage {
      public static readonly MSG_TYPE = "UsbSessionRemoved";
      public type: string = SessionRemoved.MSG_TYPE;
   }

   export class SessionDisconnected implements IMessage {
      public static readonly MSG_TYPE = "UsbSessionDisconnected";
      public type: string = SessionDisconnected.MSG_TYPE;
   }

   export class DeviceChanged implements IMessage {
      public static readonly MSG_TYPE = "UsbDeviceChanged";
      public type: string = DeviceChanged.MSG_TYPE;
   }

   export class SessionStatusUpdate implements IMessage {
      public static readonly MSG_TYPE = "SessionStatusUpdate";
      public type: string = SessionStatusUpdate.MSG_TYPE;
   }
}
