/**
 * ********************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * ********************************************************
 *
 * @format
 */
import { Logger, clientUtil } from "@html-core";

class PrintUtil {
   private isSupportChromeNewPrinterOption = false;
   private isChromeSupportPrintingAPI = false;
   constructor() {
      this.isChromeSupportPrintingAPI = this.isSupportChromePrinterAPI();
   }

   private isSupportChromePrinterAPI = () => {
      if (clientUtil.isChromeClient()) {
         try {
            chrome.printing.getPrinters((printers) => {
               Logger.info("The client supports chrome.printing API", Logger.PRINTER_RD);
            });
            return true;
         } catch (e) {
            Logger.info("The client doesn't support chrome.printing API", Logger.PRINTER_RD);
            return false;
         }
      } else {
         Logger.info("Web client doesn't support chrome.printing API", Logger.PRINTER_RD);
         return false;
      }
   };

   public setSupportChromeNewPrinterVersion = (flag: boolean) => {
      this.isSupportChromeNewPrinterOption = flag;
   };

   public isSupportChromeNewPrinter = () => {
      return this.isSupportChromeNewPrinterOption && this.isChromeSupportPrintingAPI;
   };
}

export const printUtil = new PrintUtil();
