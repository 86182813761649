/**
 * ***************************************************************************
 * Copyright 2018-2020 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { CDRConfig } from "./cdr-config";
import { TsdrChannelService } from "./tsdr-channel.service";
import { SharedFolderManager } from "./shared-folder-manager";
import { FsObserver } from "./fs-observer";
import { clientUtil } from "@html-core";

/**
 *------------------------------------------------------------------------------
 *
 * cdr-service.js
 *
 * API layer of CDR.
 *
 * Only support one desktop using CDR, and implement no wmksAdapter for beta.
 * Always regenerate sharedFolders to avoid P2 bug VHCH-2343 for beta.
 *
 * don't use channel map, so we have to close previous CDR using session then
 * launch a new session to see CDR work on another desktop for beta.
 *
 *------------------------------------------------------------------------------
 */
@Injectable()
export class CDRService {
   private currentSessionKey = null;
   private tsdrChannel = null;
   private vdpServiceCache = null;
   private loggerService: Logger;
   constructor(
      private cdrConfig: CDRConfig,
      private tsdrChannelService: TsdrChannelService,
      private sharedFolderManager: SharedFolderManager,
      private fsObserver: FsObserver
   ) {
      this.loggerService = new Logger(Logger.CDR);
   }

   public onConnecting = (sessionKey, vdpService) => {
      this.vdpServiceCache = vdpService;
      if (this.currentSessionKey) {
         this.loggerService.info("CDR can only be enable on one desktop/app session for Beta version");
      } else if (this.cdrConfig.isEnabled() || clientUtil.isFASession()) {
         this.loggerService.info("enabling CDR for session " + sessionKey);
         this.resetModels();
         this.currentSessionKey = sessionKey;
         this.tsdrChannel = this.tsdrChannelService.getChannel(vdpService);
         this.loggerService.info("init tsdr channel");
      } else {
         this.loggerService.info("CDR is disabled for session " + sessionKey);
      }
   };

   public onDisconnected = (sessionKey) => {
      if (sessionKey === this.currentSessionKey && !!this.currentSessionKey && !!this.vdpServiceCache) {
         this.loggerService.info("CDR session closed");
         this.fsObserver.stopMonitor();
         this.vdpServiceCache.disconnectChannel(this.tsdrChannel);
         this.currentSessionKey = null;
         this.tsdrChannel = null;
         this.vdpServiceCache = null;
      } else {
         this.loggerService.info("CDR had not been enabled for disconnected session " + sessionKey);
      }
   };

   public isTsdrChannelDisconnected = () => {
      return !!this.tsdrChannel;
   };

   public resetModels = () => {
      this.loggerService.debug("CDR models reset");
      this.sharedFolderManager.reset();
   };

   public reset = () => {
      this.loggerService.debug("CDR service reset");
      if (this.currentSessionKey) {
         this.onDisconnected(this.currentSessionKey);
      }
      this.resetModels();
   };
}
