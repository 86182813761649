/**
 * ******************************************************
 * Copyright (C) 2017-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * vnc-decoder.js -- vncDecoder
 *
 * Service to get a VNC decoder
 * This file is needed since we want to fix bug 1797231 and unblock the release
 * of multimon feature, so the changes that should be done in 17Q2 after
 * completing the split of the vnc decoder from the wmks is move in 17Q1, thus
 * this file is incompleted and redundant with the one in the wmks.
 *
 * In another words, the one in wmks will work for single monitor as before,
 * while this one will work for multimon, and we plan to merger them later.
 *
 * And why this can fix the crash issue is for we pass the non-rendered VNC messages
 * around instead of the whole screen image, which save a lot of memory alloc
 * and free.
 */

import { Point, Size, ImageRect, RectStore } from "./base-types";
import { RenderCacheService } from "./render-cache.service";
import { NormalizationService } from "../../../utils/normalization-service";
import { Injectable } from "@angular/core";
import Logger from "../../../../core/libs/logger";
import { clientUtil } from "@html-core";
import { DisplayCheckService } from "../../common/display-check.service";

// basic rect
export class RGBColor {
   private R: number;
   private G: number;
   private B: number;
   /**
    * Don't assume integer input here.
    */
   private clipColor = (inputNumber: number): number => {
      return Math.min(Math.max(inputNumber, 0), 255);
   };

   constructor(colorArray: Array<number>) {
      if (colorArray.length < 3) {
         throw new Error("too less data for RGB color");
      }
      this.R = this.clipColor(colorArray[0]);
      this.G = this.clipColor(colorArray[1]);
      this.B = this.clipColor(colorArray[2]);
   }

   public getString = (): string => {
      return "rgb(" + this.R + "," + this.G + "," + this.B + ")";
   };
}

export enum EncodingTypes {
   encCopyRect = 0x01,
   encTightPNG = -260,
   encUpdateCache = 127 + 0x574d5600,
   //sub encodings
   subEncFill = 0x80
}

@Injectable({
   providedIn: "root"
})
export class VNCDecoder {
   private _canvas: HTMLCanvasElement;
   private _context: any;
   private _backgroundCanvasObject: JQuery<HTMLElement>;
   private _backgroundCanvas: HTMLCanvasElement;
   private _backgroundContext: any;
   private _screenBase: Point;

   private _left = null;
   private _top = null;

   constructor(
      private renderCacheService: RenderCacheService,
      private normalizationService: NormalizationService,
      private displayCheckService: DisplayCheckService
   ) {
      this._canvas = null;
      this._context = null;
      this._backgroundContext = null;
      this._backgroundCanvasObject = null;
      this._screenBase = new Point(0, 0);

      // for windowReplacementApi: left and top is received from main window by new api representing the coordinate of the current window in fullscreen mode,
      // bacause screenX , screenY , screen.availLeft and screen.availTop are not the right value of window in fullscreen due to they include topbar's size etc.
      const left = clientUtil.findURLParam("left");
      if (left !== null) {
         const top = clientUtil.findURLParam("top");
         this._left = left;
         this._top = top;
      }
   }

   /**
    * Chrome on some windows machine has bug, e.g. the screenX, screenY on the
    * problem machine return 3844, 1924 after using fullscreen, while the true
    * value on the system setting is 3840 and 1920
    * use Math.min(screen.availLeft, screeX) to bypass come this bug according
    * to spec:
    * https://drafts.csswg.org/cssom-view/#dom-window-screenx
    */
   public initScreen = (): void => {
      // if windowReplacementApi
      if (this._left !== null) {
         this._screenBase = this.normalizationService.normalize({
            x: this._left,
            y: this._top
         });
         return;
      }
      if (screen.availLeft !== undefined && screen.availTop !== undefined) {
         let y = Math.min(screenY, screen.availTop);
         const macNotchHeight = this.displayCheckService.getMacNotchHeight();
         if (macNotchHeight > 0) {
            y = macNotchHeight;
         }
         this._screenBase = this.normalizationService.normalize({
            x: Math.min(screenX, screen.availLeft),
            y
         });
      } else {
         this._screenBase = this.normalizationService.normalize({
            x: screenX,
            y: screenY
         });
      }
   };

   private _normalize = (p: Point, offset: Point): Point => {
      return {
         x: p.x - offset.x - this._screenBase.x,
         y: p.y - offset.y - this._screenBase.y
      };
   };

   private _copyRectBlit = (
      srcX: number,
      srcY: number,
      width: number,
      height: number,
      dstX: number,
      dstY: number
   ): void => {
      this._backgroundContext.drawImage(this._canvas, srcX, srcY, width, height, srcX, srcY, width, height);
      this._context.drawImage(this._backgroundCanvas, srcX, srcY, width, height, dstX, dstY, width, height);
   };

   private _fillRectWithColor = (x: number, y: number, width: number, height: number, color: RGBColor): void => {
      let newStyle,
         canvas2dCtx = this._context;
      newStyle = color.getString();
      canvas2dCtx.fillStyle = newStyle;
      canvas2dCtx.fillRect(x, y, width, height);
   };

   private _renderImage = (rect: ImageRect, offset: Point): void => {
      const p = this._normalize(
         {
            x: rect.x,
            y: rect.y
         },
         offset
      );
      try {
         this._context.drawImage(rect.image, p.x, p.y);
      } catch (e) {
         Logger.exception(e);
      }
   };

   public setCanvas = (canvas: HTMLCanvasElement) => {
      const size = new Size(canvas.width, canvas.height);
      this._canvas = canvas;
      this._context = this._canvas.getContext("2d");
      if (this._backgroundCanvasObject) {
         this._backgroundCanvasObject.remove();
      }
      this._backgroundCanvasObject = $("<canvas/>").css({
         position: "absolute"
      });
      this._backgroundCanvasObject.attr(size).css({
         width: size.width,
         height: size.height
      });
      this._backgroundCanvas = <HTMLCanvasElement>this._backgroundCanvasObject.get(0);
      this._backgroundContext = this._backgroundCanvas.getContext("2d");
      this.renderCacheService.setVncDecoder(this);
   };

   public onDPIChanged = (canvas: HTMLCanvasElement) => {
      const size: Size = {
         width: canvas.width,
         height: canvas.height
      };
      this._backgroundCanvasObject.attr(size);
   };

   /**
    * The function to render a rect, which include a special one as End of Frame
    *    indicate the end of async rendering is done.
    * @param  {object} rect The object including data
    * @param  {object} offset The screen offset information
    * @param  {function} onRenderDone The callback for finish rendering, accept no param
    */
   public render = (rect: ImageRect, offset: Point, onRenderDone: () => void) => {
      let pSrc: Point, p: Point;
      switch (rect.encoding) {
         case EncodingTypes.encCopyRect:
            pSrc = this._normalize(
               {
                  x: rect.srcX,
                  y: rect.srcY
               },
               offset
            );
            p = this._normalize(
               {
                  x: rect.x,
                  y: rect.y
               },
               offset
            );
            this._copyRectBlit(pSrc.x, pSrc.y, rect.width, rect.height, p.x, p.y);
            break;
         case EncodingTypes.encTightPNG:
            if (rect.subEncoding === EncodingTypes.subEncFill) {
               p = this._normalize(
                  {
                     x: rect.x,
                     y: rect.y
                  },
                  offset
               );
               this._fillRectWithColor(p.x, p.y, rect.width, rect.height, new RGBColor(rect.color));
            } else if (this.renderCacheService.decodingCache()) {
               this.renderCacheService.setImage(rect);
            } else {
               this._renderImage(rect, offset);
               this.releaseRectData(rect);
            }
            break;
         case EncodingTypes.encUpdateCache:
            this.renderCacheService.operateOnCache(rect, offset);
            break;
         default:
            Logger.error("unsupported encoding type found for multimon");
            break;
      }

      /**
       * similar timeout also exist in wmks to allow canvas to render, and
       * here it's mainly for GC
       */
      setTimeout(onRenderDone, 1);
   };

   public releaseRectData = (rect: RectStore) => {
      this.renderCacheService.releaseImage(rect);
   };
}
