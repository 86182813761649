/*********************************************************
 * Copyright (C) 2016-2020, 2023 VMware, Inc. All rights reserved.
 *********************************************************
 *
 * @format
 */

export enum PLATFORM_ARCH {
   ARM = "ARM",
   X86 = "X86",
   UNKNOWN = "UNKNOWN"
}

export enum PLATFORM_BITNESS {
   BIT32 = "32",
   BIT64 = "64",
   UNKNOWN = "UNKNOWN"
}

export interface PLATFORM_INFO {
   arch: PLATFORM_ARCH;
   bit: PLATFORM_BITNESS;
}

export class OsModel {
   public prefix: string = "download_rich_client_";
   public os: string = "unknown";
   public mIsWin32: boolean = false;
   public mIsWin64: boolean = false;
   public mIsWinMobile: boolean = false;
   public mIsLinux32: boolean = false;
   public mIsLinux64: boolean = false;
   public mIsMacOS: boolean = false;
   public mIsIOS: boolean = false;
   public mIsAndroid: boolean = false;
   public mIsChromeOS: boolean = false;
   public osNames: {
      win32: string;
      win64: string;
      winmobile: string;
      linux32: string;
      linux64: string;
      mac: string;
      android: string;
      ios: string;
      chromeos: string;
      unknown: string;
   };
   public osLiteral: string;

   constructor() {
      /*
       * Localization Map
       *   return the local key of the respective OS:
       *
       * win32 - os_win32
       * win64 - os_win64
       * winphone - os_winphone
       * linux32 - os_linux32
       * linux64 - os_linux64
       * mac - os_mac
       * android - os_android
       * ios - os_ios
       * chromeos - os_chromeos
       * unknown - os_unknown
       */
      this.osNames = {
         win32: "os_win32",
         win64: "os_win64",
         winmobile: "os_winmobile",
         linux32: "os_linux32",
         linux64: "os_linux64",
         mac: "os_mac",
         android: "os_android",
         ios: "os_ios",
         chromeos: "os_chromeos",
         unknown: "os_unknown"
      };
      this.osLiteral = "";
   }

   public getOsLiteral = (platform: string): string => {
      return this.prefix + (this.osNames[platform] || this.osNames.unknown);
   };

   public detectOS = (platform: string) => {
      this.os = platform || "unknown";

      switch (this.os) {
         case "win32":
            this.mIsWin32 = true;
            break;
         case "win64":
            this.mIsWin64 = true;
            break;
         case "winmobile":
            this.mIsWinMobile = true;
            break;
         case "linux32":
            this.mIsLinux32 = true;
            break;
         case "linux64":
            this.mIsLinux64 = true;
            break;
         case "mac":
            this.mIsMacOS = true;
            break;
         case "android":
            this.mIsAndroid = true;
            break;
         case "ios":
            this.mIsIOS = true;
            break;
         case "chromeos":
            this.mIsChromeOS = true;
            break;
         default:
            break;
      }
   };

   // Get platform architecture information with User-Agent Client Hints
   // https://wicg.github.io/ua-client-hints/#user-agent-platform-bitness
   public getArch = async () => {
      // default is X86_32
      const info: PLATFORM_INFO = {
         bit: PLATFORM_BITNESS.BIT32,
         arch: PLATFORM_ARCH.X86
      };
      //@ts-ignore
      if (typeof navigator?.userAgentData?.getHighEntropyValues === "function") {
         //@ts-ignore
         const arch = await navigator?.userAgentData.getHighEntropyValues(["architecture", "bitness"]);

         // platform architecture - The user agent's underlying CPU architecture (e.g., "ARM", or "x86")
         if (arch?.architecture === "arm") {
            info.arch = PLATFORM_ARCH.ARM;
         } else if (arch?.architecture === "x86") {
            info.arch = PLATFORM_ARCH.X86;
         } else {
            info.arch = PLATFORM_ARCH.UNKNOWN;
         }

         // platform bitness - The user agent's underlying CPU architecture bitness (e.g., "32" or "64")
         if (arch?.bitness === "64") {
            info.bit = PLATFORM_BITNESS.BIT64;
         } else if (arch?.bitness === "32") {
            info.bit = PLATFORM_BITNESS.BIT32;
         } else {
            info.bit = PLATFORM_BITNESS.UNKNOWN;
         }
      }
      return info;
   };
}
