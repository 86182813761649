/**
 * ******************************************************
 * Copyright (C) 2012-2019 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * connectToBrokerCommand.js --
 *
 * First stage of authentication:
 *    1. SetLocale
 *    2. GetConfiguration
 *
 */

import util from "../util";
import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import Router from "../controllers/router";
import SetLocaleHandler from "../controllers/setLocaleHandler";
import DoCancelAuthenticationHandler from "../controllers/doCancelAuthenticationHandler";
import {
   GetConfigurationHandler,
   GetConfigurationKeyParameters,
   HaveAuthTypesEnum
} from "../controllers/getConfigurationHandler";
import AddClientInfoHandler from "../controllers/addClientInfoHandler";
import CsrfTokenService from "../model/csrf-token.service";
import IPv6Service from "../model/ipv6.service";
import { ConnectBrokerAction } from "../../common/jscdk/jscdk-interface";

export default function ConnectToBrokerCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param connectBrokerAction[in]
 *        - its property 'method' has the value 'ConnectToBroker'
 */
ConnectToBrokerCommand.prototype.execute = function (connectBrokerAction: ConnectBrokerAction) {
   let handlerList,
      samlSecret,
      unauthenticated,
      urlHandler,
      oneProtocol,
      brokerUrl,
      clearBeforeConnect = false,
      setLocaleObject = globalArray["set-locale"],
      getConfigurationObject = globalArray["get-configuration"],
      submitAuthenticationObject = globalArray["do-submit-authentication"],
      addClientInfoObject = globalArray["add-client-info"],
      cancelAuthObject = globalArray["do-cancel-authentication"],
      csrfTokenService = globalArray["csrf-token-service"],
      ipv6Service = globalArray["ipv6-service"],
      router = globalArray["router"];

   //init GetConfigurationHandler to handle URL
   if (!getConfigurationObject) {
      getConfigurationObject = new GetConfigurationHandler();
      globalArray[getConfigurationObject.messageName] = getConfigurationObject;
      globalArray[getConfigurationObject.responseTag] = getConfigurationObject;
   } else {
      getConfigurationObject.resetData();
   }

   // add key-parameters
   getConfigurationObject.setKeyParameters({
      publicKey: connectBrokerAction.dhPublicKey,
      nonce: connectBrokerAction.nonce,
      identifier: connectBrokerAction.clientIdentifier,
      schemes: connectBrokerAction.encryptionSchemes
   } as GetConfigurationKeyParameters);

   urlHandler = util.getObject(globalArray, "url-handler");

   if (urlHandler) {
      samlSecret = urlHandler.params.samlArt;
      if (
         globalArray.hasOwnProperty("environment-information") &&
         globalArray["environment-information"].hasOwnProperty("Type") &&
         globalArray["environment-information"]["Type"] === "Chrome Native"
      ) {
         unauthenticated = "" + connectBrokerAction.unauthenticatedAccessEnabled;
      } else {
         unauthenticated = urlHandler.params.unauthenticatedAccessEnabled;
      }

      /*
       *
       * The implementation of XML in F5 APM don't support us to send
       * do-cancel-authentication before other xml message.
       *
       * We need to consider two cases
       *       APM + HTML5
       *       APM + WS1 + HTML5
       *
       * In both cases, the APM will set
       *    "F5_VdiUserClientChoicevmware_view=html"
       * in the cookies of the protal domain, so we use this Cookie to indicate
       * Whether the URL is redirected from F5 APM or not.
       */

      if (
         !!samlSecret &&
         samlSecret !== "F5FakeSamlArt" && //APM + HTML5
         !connectBrokerAction.F5Mode
      ) {
         // APM + WS1 + HTML5
         globalArray["samlArt"] = samlSecret;
         getConfigurationObject.setAuthTypes(HaveAuthTypesEnum.HAVEAUTHTYPE_SAML);
         // for fixing bug 2166197, send do-cancel-authentication first
         if (!cancelAuthObject) {
            cancelAuthObject = new DoCancelAuthenticationHandler();
            globalArray[cancelAuthObject.messageName] = cancelAuthObject;
            globalArray[cancelAuthObject.responseTag] = cancelAuthObject;
         } else {
            cancelAuthObject.resetData();
         }
         cancelAuthObject.setCallback(() => {
            getConfigurationObject.setRequestXML(true);
            handlerList = getConfigurationObject.composeHandlerList();
            router.postMessage(handlerList);
         });
         clearBeforeConnect = true;
      } else if (
         (!!samlSecret && samlSecret === "F5FakeSamlArt") || //APM + HTML5
         !!connectBrokerAction.F5Mode
      ) {
         // APM + WS1 + HTML5
         globalArray["samlArt"] = samlSecret;
         getConfigurationObject.setAuthTypes(HaveAuthTypesEnum.HAVEAUTHTYPE_SAML);
      } else if (!!unauthenticated && unauthenticated.toLowerCase() === "true") {
         getConfigurationObject.setAuthTypes(HaveAuthTypesEnum.HAVEAUTHTYPE_UNAUTH);
      } else {
         getConfigurationObject.setAuthTypes(HaveAuthTypesEnum.HAVEAUTHTYPE_DEFAULT);
      }
   } else {
      getConfigurationObject.setAuthTypes(HaveAuthTypesEnum.HAVEAUTHTYPE_DEFAULT);

      unauthenticated = connectBrokerAction.unauthenticatedAccessEnabled;
      if (unauthenticated) {
         getConfigurationObject.setAuthTypes(HaveAuthTypesEnum.HAVEAUTHTYPE_UNAUTH);
      }
   }

   if (!samlSecret && globalArray["cancelAuthBeforeConnect"]) {
      if (!cancelAuthObject) {
         cancelAuthObject = new DoCancelAuthenticationHandler();
         globalArray[cancelAuthObject.messageName] = cancelAuthObject;
         globalArray[cancelAuthObject.responseTag] = cancelAuthObject;
      }
      cancelAuthObject.setCallback(() => {
         getConfigurationObject.setRequestXML(true);
         handlerList = getConfigurationObject.composeHandlerList();
         router.postMessage(handlerList);
      });
      window.localStorage.setItem("partialAuthedWithSAML", null);
      clearBeforeConnect = true;
   }

   // read in data from connectBrokerAction
   // "add-client-info" work after broker>= 9.0
   if (connectBrokerAction.hasOwnProperty("clientInfo") && util.brokerSupportApplication()) {
      if (!connectBrokerAction["clientInfo"].hasOwnProperty("addClientInfo")) {
         addClientInfoObject = new AddClientInfoHandler();
         globalArray[addClientInfoObject.messageName] = addClientInfoObject;
         globalArray[addClientInfoObject.responseTag] = addClientInfoObject;
      } else {
         addClientInfoObject.resetData();
      }

      addClientInfoObject.clientInfo = connectBrokerAction.clientInfo;
   }

   if (!router) {
      Logger.info("router is null.");

      router = new Router();
      globalArray[router.name] = router;
   }

   if (connectBrokerAction.supportedProtocols) {
      for (oneProtocol in connectBrokerAction.supportedProtocols) {
         if (connectBrokerAction.supportedProtocols.hasOwnProperty(oneProtocol)) {
            oneProtocol = oneProtocol.toUpperCase();
         }
      }
   } else {
      Logger.error("No supported protocols configured.");
      return;
   }

   globalArray["brokerAddress"] = connectBrokerAction.address;
   globalArray.supportedProtocols = connectBrokerAction.supportedProtocols;

   // Configure broker post address, specify whether a timestamp is appended to
   // the URL.
   brokerUrl =
      "https://" +
      connectBrokerAction.address +
      "/broker/xml" +
      (connectBrokerAction.mid ? connectBrokerAction.mid : "");
   router.setBrokerUrl(brokerUrl);

   // if bypassTunnel exists, put bypassTunnel into globalArray
   if (connectBrokerAction["bypassTunnel"] !== undefined) {
      globalArray["bypassTunnel"] = connectBrokerAction["bypassTunnel"];
   } else {
      globalArray["bypassTunnel"] = true;
   }

   // reset submitAuthenticationObject for later usage when dealing with
   // <configuration>
   if (submitAuthenticationObject) {
      submitAuthenticationObject.resetData();
   }

   //set csrfCheck for csrfService
   if (!csrfTokenService) {
      csrfTokenService = new CsrfTokenService();
      globalArray[csrfTokenService.globalName] = csrfTokenService;
   }
   csrfTokenService.setCsrfEnforced(connectBrokerAction.csrfCheck);

   //set ipv6enabled for ipv6Service
   if (!ipv6Service) {
      ipv6Service = new IPv6Service();
      globalArray[ipv6Service.globalName] = ipv6Service;
   }
   ipv6Service.setIPv6Disabled(connectBrokerAction.disableIPv6);

   // send getconfiguration & setlocal to inform broker, but not prepare to
   // login
   if (!setLocaleObject) {
      setLocaleObject = new SetLocaleHandler();
      globalArray[setLocaleObject.messageName] = setLocaleObject;
      globalArray[setLocaleObject.responseTag] = setLocaleObject;
   } else {
      setLocaleObject.resetData();
   }

   if (clearBeforeConnect) {
      handlerList = cancelAuthObject.composeHandlerList();
      router.postMessage(handlerList);
   } else {
      getConfigurationObject.setRequestXML(true);
      handlerList = getConfigurationObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};
