/** @format */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PrintFrameComponent } from "./print-frame.component";
import { PrintRdService } from "./print-redirection.service";

@NgModule({
   imports: [CommonModule],
   declarations: [PrintFrameComponent],
   providers: [PrintRdService],
   exports: [PrintFrameComponent]
})
export class PrintRedirectionModule {}
