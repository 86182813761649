/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview video-capture.ts -- VideoCapture
 * Class to handle video capturing
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";
import { FrameRateController } from "../frameRateController";
import { SyncTimer } from "../synctimer";
import { VideoParams } from "./video-capture.model";
import { VideoEncoderWorker } from "./video-encoder";

@Injectable({ providedIn: "root" })
export class VideoCaptureFactory {
   constructor(
      private syncTimer: SyncTimer,
      private frameRateController: FrameRateController
   ) {}
   public newVideoCapture(deviceId: string) {
      return new VideoCapture(this.syncTimer, this.frameRateController, deviceId);
   }
}

export class VideoCapture {
   private started = false;
   private streamStarted = false;
   private sessionId: number;
   private sessionCount: number; // total amount of created sessions
   private ctx: OffscreenCanvasRenderingContext2D;
   private video: HTMLVideoElement;
   private canvas: OffscreenCanvas;
   private videoParam: VideoParams;
   private localStream: MediaStream;

   private encoder: VideoEncoderWorker;
   private emitDeviceStatusChanged: () => void;

   constructor(
      private syncTimer: SyncTimer,
      private frameRateController: FrameRateController,
      private deviceId: string
   ) {}

   public getId() {
      return this.deviceId;
   }

   // throwable
   public async start(encoder: VideoEncoderWorker, emitDeviceStatusChanged: () => void, videoParam: VideoParams) {
      if (this.started) {
         Logger.debug("VideoCapture has already started, please stop first", Logger.RTAV);
         return;
      }
      this.encoder = encoder;
      this.emitDeviceStatusChanged = emitDeviceStatusChanged;
      this.videoParam = videoParam;

      this.frameRateController.setFPS(videoParam.fps);
      this.video = document.createElement("video") as HTMLVideoElement;
      if (!this.video) {
         throw new Error("VideoCapture can't find the video element");
      }
      this.video.setAttribute("width", videoParam.width.toString());
      this.video.setAttribute("height", videoParam.height.toString());
      // mute the video to fix bug 1587389
      this.video.volume = 0;
      this.canvas = new OffscreenCanvas(videoParam.width, videoParam.height);
      this.ctx = this.canvas.getContext("2d", {
         willReadFrequently: true
      }) as OffscreenCanvasRenderingContext2D;
      if (
         !this.video ||
         !this.canvas ||
         !this.ctx ||
         videoParam.width !== this.canvas.width ||
         videoParam.height !== this.canvas.height
      ) {
         throw new Error("VideoCapture start failed");
      }
      this.sessionId = -1;
      this.sessionCount = 0;
      // open video device
      try {
         const constraints: MediaStreamConstraints = {
            video: {
               deviceId: {
                  exact: this.deviceId
               },
               width: { ideal: videoParam.width },
               height: { ideal: videoParam.height },
               frameRate: { ideal: videoParam.fps }
            }
         };
         const stream = await navigator.mediaDevices.getUserMedia(constraints);
         this.localStream = stream;
         this.syncTimer.reset();
         this.started = true;
         this.emitDeviceStatusChanged();
      } catch (err) {
         Logger.error("VideoCapture open device failed: " + err, Logger.RTAV);
         throw err;
      }
   }

   // throwable
   public startStream() {
      if (this.streamStarted) {
         Logger.error("VideoCapture stream has already started, please stop stream first", Logger.RTAV);
         return;
      }
      if (!this.localStream) {
         throw new Error("VideoCapture stream is not valid, startStream failed");
      }
      if (!this.started) {
         throw new Error("VideoCapture hasn't started, startStream failed");
      }
      if (this.sessionId >= 0) {
         throw new Error("VideoCapture is being used by another session, startStream failed");
      }

      /**
       * Switch to the new API srcObject if createObjectURL doesn't take stream anymore
       * See details in bug 2218657
       */
      this.video.srcObject = this.localStream;
      this.video.play();

      this.sessionId = this.sessionCount;
      this.sessionCount++;
      this.getNextFrame(this.sessionId);
      this.streamStarted = true;
   }

   public stopStream() {
      if (!this.started || !this.streamStarted) {
         Logger.error("VideoCapture has not started, stopStream failed", Logger.RTAV);
         return;
      }
      if (this.sessionId < 0) {
         Logger.debug("VideoCapture doesn't belong to a session, stopStream skipped", Logger.RTAV);
         return;
      }
      this.sessionId = -1;
      this.localStream?.getTracks()?.forEach((track) => {
         track.stop();
      });
      this.streamStarted = false;
   }

   public stop() {
      if (this.streamStarted) {
         this.stopStream();
      }
      if (this.video) {
         this.video.srcObject = null;
         this.video = null;
      }
      this.canvas = null;
      this.ctx = null;
      this.encoder?.clear();
      this.encoder = null;
      this.syncTimer?.clear();
      this.started = false;
      if (this.emitDeviceStatusChanged) {
         this.emitDeviceStatusChanged();
      }
   }

   public isActive() {
      return this.started;
   }

   private getNextFrame(sessionId: number) {
      if (!this.video) {
         return;
      }
      setTimeout(() => {
         if (!this.started) {
            return;
         }
         if (this.sessionId < 0 && this.sessionId !== sessionId) {
            return;
         }
         this.ctx?.drawImage(this.video, 0, 0, this.videoParam.width, this.videoParam.height);
         const imgData = this.ctx?.getImageData(0, 0, this.canvas.width, this.canvas.height);
         this.frameRateController.onFrameCaptured();
         this.encoder?.encode({
            data: imgData.data.buffer,
            timestamp: this.syncTimer.getTime(),
            others: {
               sessionId: sessionId,
               getNextFrame: this.getNextFrame.bind(this)
            }
         });
      }, this.frameRateController.getWaitTime());
   }
}
