<div class='base-panel file-transfer-panel' (click)="focusInput()" [hidden]="!showFileTransferPanel" *ngIf='!chromeClient'>
   <div file-drop (fileDrop)="uploadHandler($event)" [ngClass]="{'file-transfer-focused': focused, 'file-transfer-unfocused': !focused}">
      <div class="file-transfer-input-container">
         <label for="file-transfer-input-container">file-transfer-container</label>
         <input id="file-transfer-input-container" (focus)="ftPanelFocus()" (blur)="ftPanelBlur()"/>
      </div>
      <div class="file-transfer-header file-transfer-handle">
         <div class="file-transfer-title" >{{'FILE_TRANSFER_TITLE' | i18nv2}}</div>
         <div class="file-transfer-icon close" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? toggleFileTransferPanel($event) : null" (click)="toggleFileTransferPanel($event)" tabindex="0" title="{{'CLOSE_FT_T' | i18nv2}}" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]="'CLOSE_FT_BUTTON' | i18nv2"></div>
         <div class="file-transfer-icon help" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? openFileTransferHelp() : null" (click)="openFileTransferHelp()" tabindex="0" title="{{'FT_HELP_T' | i18nv2}}" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]="'HELP_FT_BUTTON' | i18nv2"></div>
      </div>
      <div class="file-content">
         <div class="file-area">
            <div class="file-area-header">
               <div *ngIf="downloadEnabled" tabindex="0" class="file-transfer-tab download-tab" (keydown)="onKeypress($event, PANEL_TAB.DOWNLOAD)" (click)="switchFileTab(PANEL_TAB.DOWNLOAD)" [ngClass]="{'active': showFileDownload}">{{'FILE_DOWNLOAD' | i18nv2}}</div>
               <div *ngIf="uploadEnabled" tabindex="0" class="file-transfer-tab upload-tab" (keydown)="onKeypress($event, PANEL_TAB.UPLOAD)" (click)="switchFileTab(PANEL_TAB.UPLOAD)" [ngClass]="{'active': showFileUpload}">{{'FILE_UPLOAD' | i18nv2}}</div>
            </div>
            <div *ngIf="downloadEnabled" [hidden]="!showFileDownload" class="unselectable file-list-container">
               <ul id="ft-download-list" class="file-content-list" [hidden]="!(downloadFiles.length !== 0)">
                  <li class="file-item" *ngFor="let file of downloadFiles">
                     <span class="file-name">{{file.relPath}}</span>
                     <span class="readable-file-size">{{'- ' + file.readableSize}}</span>
                     <span *ngIf="file.transferError  === false" [ngStyle]="{'width': file.progress + '%'}" class="transfer-progress" [ngClass]="{'transfer-done': file.progress  === 100}"></span>
                     <span *ngIf="file.transferError  === true" class="transfer-progress transfer-error"></span>
                     <span *ngIf="file.stopTransfer  === true" class="transfer-progress transfer-stop"><div class="file-transfer-file-icon stopped-transfer"></div></span>
                     <div *ngIf="file.transferError  === true" title="{{'FT_FAILED_HINT' | i18nv2}}" class="file-transfer-file-icon transfer-error"></div>
                     <div *ngIf="file.progress  === 100" (click)="saveFile(file)" class="file-transfer-file-icon save-file"></div>
                     <div *ngIf="file.transferError  === false && file.stopTransfer  === false && file.progress < 100" (click)="stopFileDownload(file)" class="file-transfer-file-icon cancel-upload"></div>
                  </li>
               </ul>
               <div id="ft-download-step-hint" class="hint-container file-transfer-handle" [hidden]="!(downloadFiles.length === 0 && downloadEnabled === true)">
                  <p class="hint-title">{{'FT_DOWNLOAD_HINT_TITLE' | i18nv2}}</p>
                  <div class="hint-step-container">
                     <p>{{'FT_DOWNLOAD_HINT1' | i18nv2}}</p>
                     <p>{{'FT_DOWNLOAD_HINT2' | i18nv2 : modKey}}</p>
                     <p>{{'FT_DOWNLOAD_HINT3_1' | i18nv2}}<span class="file-transfer-hint-icon save-file"></span>{{'FT_DOWNLOAD_HINT3_2' | i18nv2}}</p>
                  </div>
               </div>
               <div id="ft-download-disable-hint" class="hint-container file-transfer-handle" [hidden]="downloadEnabled === true">
                  <p class="hint-disable-title">{{'FT_DOWNLOAD_DISABLE_HINT_TITLE' | i18nv2}}</p>
               </div>
            </div>
            <div *ngIf="uploadEnabled" [hidden]="!showFileUpload" class="unselectable file-list-container">
               <ul id="ft-upload-list" class="file-content-list" [hidden]="!(uploadFiles.length !== 0)">
                  <li *ngFor="let file of uploadFiles" class="file-item">
                     <span class="file-name">{{file.relPath}}</span>
                     <span class="readable-file-size">{{'- ' + file.readableSize}}</span>
                     <span *ngIf="file.transferError === false" [ngStyle]="{'width': file.progress + '%'}" class="transfer-progress" [ngClass]="{'transfer-done': file.progress === 100}"></span>
                     <span *ngIf="file.transferError  === true" class="transfer-progress transfer-error"></span>
                     <span *ngIf="file.stopTransfer  === true" class="transfer-progress transfer-stop"><div class="file-transfer-file-icon stopped-transfer"></div></span>
                     <div *ngIf="file.transferError  === true" title="{{'FT_FAILED_HINT' | i18nv2}}" class="file-transfer-file-icon transfer-error"></div>
                     <div *ngIf="file.transferError  === false && file.stopTransfer  === false && file.progress < 100" (click)="stopFileUpload(file)" class="file-transfer-file-icon cancel-upload"></div>
                  </li>
                  <div class="btn-hint-container">
                     <button *ngIf="!isChromeClient()" [hidden]="uploadFiles.length === 0" (click)="chooseUploadFiles()" class="choose-files-button">{{'FT_CHOOSE_FILES_BTN2' | i18nv2}}</button>
                     <button *ngIf="isChromeClient()" (click)="chromeChooseUploadFiles()" [hidden]="uploadFiles.length === 0" class="choose-files-button">{{'FT_CHOOSE_FILES_BTN2' | i18nv2}}</button>
                    </div>
               </ul>
               <div id="ft-upload-step-hint" class="hint-container file-transfer-handle" [hidden]="!(uploadFiles.length  === 0 && uploadEnabled  === true)">
                  <p class="hint-title">{{'FT_UPLOAD_HINT_TITLE' | i18nv2}}</p>
                  <form>
                     <label for="upload-file-input">upload-file-input</label>
                     <input *ngIf="!isSafari()" type="file" multiple="multiple" id="upload-file-input" class="upload-file-input" file-change (fileChange)="uploadHandler($event)"/>
                     <input *ngIf="isSafari()" type="file" accept="audio/*,video/*,image/*,text/*,font/*,application/*,example/*,message/*,model/*,multipart/*,.iso,.xls,.webm,.bat,.ps1,.sh" multiple="multiple" id="upload-file-input" class="upload-file-input" file-change (fileChange)="uploadHandler($event)"/>
                  </form>
                  <div class="hint-step-container">
                     <p>{{'FT_UPLOAD_HINT1' | i18nv2}}</p>
                     <p>{{'FT_UPLOAD_HINT2' | i18nv2}}</p>
                     <p>{{'FT_UPLOAD_HINT3' | i18nv2}}</p>
                  </div>
                  <button *ngIf="!isChromeClient()" (click)="chooseUploadFiles()" class="choose-files-button">{{'FT_CHOOSE_FILES_BTN' | i18nv2}}</button>
                  <button *ngIf="isChromeClient()" (click)="chromeChooseUploadFiles()" class="choose-files-button">{{'FT_CHOOSE_FILES_BTN' | i18nv2}}</button>
                </div>
               <div id="ft-upload-disable-hint" class="hint-container file-transfer-handle" [hidden]="uploadEnabled  === true">
                  <p class="hint-disable-title">{{'FT_UPLOAD_DISABLE_HINT_TITLE' | i18nv2}}</p>
               </div>
            </div>
         </div>
      </div>
      <div class="file-transfer-footer file-transfer-handle">
         <span id="ft-clear-upload-list-btn" tabindex="0" class="file-transfer-clear" [hidden]="!showFileUpload" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? clearUploadList() : null" (click)="clearUploadList()" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]="'CLEAR_LIST_BUTTON' | i18nv2">{{'CLEAR_LIST' | i18nv2}}</span>
         <span id="ft-clear-download-list-btn" tabindex="0" class="file-transfer-clear" [hidden]="!showFileDownload" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? clearDownloadList() : null" (click)="clearDownloadList()" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]="'CLEAR_LIST_BUTTON' | i18nv2">{{'CLEAR_LIST' | i18nv2}}</span>
      </div>
   </div>

   <panel-notification></panel-notification>
</div>
