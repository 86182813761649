/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * abstract-media-resource-manager.ts
 */

import { Logger } from "../../../core/libs/logger";
import { MediaResourceInterface, MediaType } from "./media-resource-manager";
import { MediaResourceUtil } from "./media-resource-util";
import { MediaStat } from "./model/media.models";

export abstract class AbstractMediaResourceManager implements MediaResourceInterface {
   protected logger = new Logger(Logger.MEDIA);

   tracks: Map<string, MediaStreamTrack> = new Map<string, MediaStreamTrack>();

   /**
    * Construct an abstract MediaResourceManager by given media type
    *
    * @param  {MediaType} protectedtype given media type enum. Possible values are either MediaType.video or MediaType.audio
    */
   protected constructor(protected type: MediaType) {
      this.logger.info("Resource manager for " + type + " is created.");
   }

   /**
    * Retrieve all media elements
    */
   abstract getElements: () => HTMLCollectionOf<HTMLMediaElement>;

   /**
    * Update media stats for given media element by id
    *
    * @param  {string} id media element id
    * @param  {MediaStat} model  media stats model
    */
   abstract updateStats: (id: string, model: MediaStat) => void;

   /**
    * Remove/Clear all [MediaStreamTrack] and [HTMLMediaElement] from local cache
    */
   public clear = () => {
      Array.from(this.tracks.values()).forEach((each) => {
         this.removeTrack(each);
      });

      const elements = this.getElements();
      for (let i = 0; i < elements.length; i++) {
         const elem = elements[i];
         this.removeElement(elem.id);
      }
   };

   /**
    * Update global volume for media elements
    *
    * @param  {boolean} muted given mute status
    * @param  {number} volume given volume
    */
   public updateVolume = (muted: boolean, volume: number) => {
      const elements = this.getElements();
      for (let i = 0; i < elements.length; i++) {
         const elem = elements[i];
         elem.muted = muted;
         elem.volume = parseFloat(volume.toFixed(2));
      }
   };

   /**
    * Create a new HTMLMediaElement by given id and media parameters
    *
    * @param  {string} id given element id
    * @param  {any} params an JSON object that specify media element parameters
    */
   abstract createElement: (id: string, params: any) => HTMLMediaElement;

   /**
    * Update HTMLMediaElement by given id and media parameters
    *
    * @param  {string} id  given element id
    * @param  {any} params an JSON object that specify media element parameters
    */
   abstract updateElement: (id: string, params: any) => void;

   /**
    * Retrieve HTML media element by given id
    *
    * @param  {string} id given media element id
    * @returns HTMLMedialElement by given id or undefined if not found
    */
   public getElement = (id: string): HTMLMediaElement | undefined => {
      const elem: HTMLElement = MediaResourceUtil.getElement(id);
      if (elem instanceof HTMLMediaElement) {
         return elem as HTMLMediaElement;
      }
      return null;
   };

   /**
    * Remove HTML media element by given id
    *
    * @param  {string} id given media element id
    * @returns HTMLMedialElement that is removed by given id or undefined if not found
    */
   public removeElement = (id: string): HTMLMediaElement | undefined => {
      const elem: HTMLMediaElement = this.getElement(id);

      if (!elem) {
         this.logger.error("Media resource element cannot be removed for [" + this.type + "]");
         return null;
      }

      const parentElem: HTMLElement = elem.parentElement;

      elem.pause();
      elem.remove();

      if (parentElem) {
         parentElem.remove();
      }

      return elem;
   };

   /**
    * Update srcObject property of the media element by given Id and MediaStream
    *
    * @param  {string} id given media element id
    * @param  {MediaStream} stream a stream of media content to be associated with given media element
    */
   abstract updateSourceObject: (id: string, stream: MediaStream) => void;

   /**
    * Add new media stream track to media resource manager
    *
    * @param  {MediaStreamTrack} track
    */
   public addTrack = (track: MediaStreamTrack) => {
      if (track.kind != this.type) {
         this.logger.error("Cannot add track: " + track.id + " as it is not a " + this.type + " track");
         return;
      }
      this.tracks.set(track.id, track);
   };

   /**
    * Update MediaStreamTrack by given track id
    *
    * @param  {string} tId given track id
    * @param  {MediaStreamTrack} track media track to be updated
    */
   public updateTrack = (tId: string, track: MediaStreamTrack) => {
      this.tracks.set(tId, track);
   };

   /**
    * Retrieve media stream track by given track id
    *
    * @param  {string} id given media stream track id
    * @returns MediaStreamTrack by given track id or undefined if not found
    */
   public getTrack = (id: string): MediaStreamTrack | undefined => {
      const track: MediaStreamTrack = this.tracks.get(id);
      return track;
   };

   /**
    * Stop media stream track by given track id
    *
    * @param  {string} id given media stream track id
    */
   public stopTrack = (id: string) => {
      const track: MediaStreamTrack = this.getTrack(id);
      if (!track) {
         this.logger.error("Stop " + this.type + " track cannot be performed because " + id + " is not available");
         return;
      }
      track.stop();
   };

   /**
    * Remove media stream track
    *
    * @param  {MediaStreamTrack} track media stream track to be removed
    */
   public removeTrack = (track: MediaStreamTrack) => {
      if (track.kind != this.type) {
         this.logger.error("Cannot remove track: " + track.id + " as it is not a " + this.type + " track");
         return;
      }
      track.stop();
      this.tracks.delete(track.id);
   };

   /**
    * Enable/Disable media stream track by given id
    *
    * @param  {string} id given media track id
    * @param  {boolean} enabled boolean flag indicate if media track should be enabled/disabled
    */
   public enableTrack = (id: string, enabled: boolean) => {
      const track: MediaStreamTrack = this.getTrack(id);
      if (!track) {
         this.logger.error("Enable " + this.type + " track cannot be performed because " + id + " is not available");
         return;
      }
      track.enabled = enabled;
   };
}
