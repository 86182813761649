/**
 * ******************************************************
 * Copyright (C) 2012-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * setLocaleHandler.js --
 *
 *      Implementation of the message handler to set locale.
 */

import $ from "jquery";
import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function SetLocaleHandler() {
   // member variables below
   this.messageName = "set-locale";
   this.messageText = "set-locale";
   this.responseTag = "set-locale";
   this.properties = null; // properties in xml, key-value, such as locale:en_GB

   // register dependencies here
   const router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }

   this.requestXML = util.createElement("locale", util.l10N.getLocale());
}

// inherits MessageHandler prototype
SetLocaleHandler.prototype = new MessageHandler();
SetLocaleHandler.constructor = SetLocaleHandler;

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
SetLocaleHandler.prototype.onUpdated = function () {
   const router = util.getObject(globalArray, "router");
   MessageHandler.prototype.onUpdated.apply(this); // call parent class's onUpdated
   router.pushErrorToUser(this);
};

/**
 * parse locale information from the response XML of GetConfiguration
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
SetLocaleHandler.prototype.parseResult = function (responseXML) {
   if (!responseXML || responseXML === "") {
      return null;
   }
   const responseBody = $(responseXML);
   const ret = {};
   let brokerElem;
   let responseTagElem;
   let resultElem;
   let resultText;
   brokerElem = responseBody.find("broker");
   if (!brokerElem) {
      Logger.error("response of " + this.getMessageName() + " error");
      return null;
   }
   responseTagElem = brokerElem.find(this.responseTag);
   resultElem = responseTagElem.find("result");
   resultText = resultElem.text();
   if (!!resultText && resultText.trim() !== "") {
      ret["result"] = resultText;
   } else {
      return null;
   }
   if (resultText === "error") {
      ret["error-code"] = responseTagElem.find("error-code").text();
      ret["error-message"] = responseTagElem.find("error-message").text();
      ret["user-message"] = responseTagElem.find("user-message").text();
   }
   return ret;
};
