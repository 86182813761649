<div class="modal-title dialog-title">{{ 'MULTI_MONITOR_T' | i18nv2 }}</div>
<div class="modal-body dialog-content" fuzzy-free>
    <div class="multi-monitor-container settings-container settings-fix-height">
        <div id="display-setting-option" class="display-setting option-container small-top-margin">
            <span class="label-text">{{'MULTI_MONITOR_T' | i18nv2}}</span>
            <select id="display-list"  (change)="setDisplaySetting($event)" [(ngModel)]="displaySettingOption" [disabled]="displayListDisabled">
                <option value="0" [hidden]="enableAdvancedCodec">{{ 'USE_ALL_DISPLAYS' | i18nv2 }}</option>
                <option value="1">{{ 'USE_SINGLE_DISPLAY' | i18nv2 }}</option>
                <option value="2" [hidden]="enableAdvancedCodec">{{ 'USE_SELECTED_DISPLAYS' | i18nv2 }}</option>
                <ng-container *ngIf="chromeClient">
                    <option value="3">{{ 'USE_SMALL_WINDOW' | i18nv2 }}</option>
                    <option value="4">{{ 'USE_LARGE_WINDOW' | i18nv2 }}</option>
                    <option value="5">{{ 'USE_CUSTOM_WINDOW' | i18nv2 }}</option>
                </ng-container>
                <option value="6">{{ 'USE_FULLSCREEN' | i18nv2 }}</option>
            </select>
            <div class="advanced-codec-warn" [hidden]="!enableAdvancedCodec" title="{{ 'DISABLE_ADVANCED_CODEC_WARNING' | i18nv2 }}" >
         </div>
        </div>
        <div class="selected-display-area" [hidden]="!showSelectScreenPanel">
            <div class="display-arrange-title">{{ 'MULTI_DISPLAY_ARRANGEMENT' | i18nv2 }}</div>
            <div class="arrangement-area">
                <canvas id="topology-preview-canvas" width="380" height="220" class="multimon-preview" (click)="selectMonitor($event)"></canvas>
            </div>
        </div>
        <div class="custom-window-area" [hidden]="!showCustomWindowArea">
           <label for="width-area">{{ 'CUSTOM_WINDOW_WIDTH' | i18nv2 }}</label>
           <input id="width-area" type="number" [(ngModel)]="customWindowWidth" [disabled]="displayListDisabled" (keyup)="verifyCustomWidth()">
           <p class="warningInvalidVal" [hidden]="customWidthValid">{{ invalidWidthWarning }}</p>
           <br>
           <label for="height-area">{{ 'CUSTOM_WINDOW_HEIGHT' | i18nv2 }}</label>
           <input id="height-area" type="number" [(ngModel)]="customWindowHeight" [disabled]="displayListDisabled" (keyup)="verifyCustomHeight()">
           <p class="warningInvalidVal" [hidden]="customHeightValid">{{ invalidHeightWarning }}</p>
        </div>
    </div>
</div>
<div class="modal-footer dialog-button-row">
    <button id="multi-monitor-setting-ok" tabindex="0" class="modal-button-base modal-button-right" [ngClass]="OKBtnDisabled ? 'modal-button-grey' : 'modal-button-blue'" (click)="multiLaunchOkClicked()" [attr.disabled]="OKBtnDisabled">
        {{ 'OK' | i18nv2 }}
    </button>
    <button id="multi-monitor-setting-cancel" tabindex="0" class="modal-button-base modal-button-right modal-button-blue" (click)="closeModal()">
        {{'CANCEL' | i18nv2 }}
    </button>
</div>