/**
 * *******************************************************************
 * Copyright (C) 2014-2018 VMware, Inc. All rights reserved.
 * ********************************************************************
 *
 * @format
 */

/**
 * VDPService.js
 *
 *   Interface that lies between the View Virtual Channel(VVC) APIs and the Unity client
 *   plugin providing easy communication with the remote application using Remote
 *   Procedure Calls. Tbe server side VDPService written in C++ can be found at
 *   bora/apps/rde/vdpservice.
 *
 *   A root VDPService.Service instance needs to be created. Then listeners can be added
 *   which are called when a channel is opened by the remote application:
 *      .addChannelCreatedListener This listener is called when the server creates a new
 *                                 channel.
 *
 *   Please refer to VDPService.Service documentation.
 *
 *   A VDPChannel instance is a RPC wrapper to VVCChannel. The following listeners
 *   must be added:
 *      .onReady                 Called when the VDPChannel instance is ready to
 *                               send RPCs.
 *      .onDisconnect            Called when the VDPChannel is disconnected and
 *                               can no longer send RPCs.
 *      .onInvoke                Called when the server on the other end of the channel
 *                               called Invoke(), that is, when there is a new RPC message
 *                               received.
 *
 *   Please refer to VDPChannel documentation.
 *
 *   Side channels are disabled by client by default, but if Agent initialize one, we can
 *   handle Vchan sidechannel as well. And CDR feature should be the only feature working
 *   in this mode.
 */

import Logger from "../../../core/libs/logger";
import SideChannelManager from "./sidechannel-manager";
import { VDP_CONSTS } from "./vdp-constants";
import { VDPChannel } from "./vdp-channel";

/**
 * A root VDPService.Service instance needs to be created to handle channel
 * connections.
 *
 * @constructor
 * @param {VVC} vvc - VVC instance.
 * @param {VVCSession} vvcSession - VVCSession instance.
 */
export class VDPService {
   private vvcListener: any;
   private vdpChannels: VDPChannel[];
   private onChannelCreatedListeners: any[];
   private vvcSession: any;
   private sideChannelManager: any;

   constructor(vvc, vvcSession) {
      /**
       * The vvcListener object to listen to new channels
       * opened by the server.
       *
       * @type {VVCListener}
       * @private
       */
      this.vvcListener = null;

      /**
       * A list of VDPService channels indexed by VVC channel ID.
       *
       * @type {VDPChannel[]}
       * @private
       */
      this.vdpChannels = [];

      /**
       * A list of callbacks to be called when a channel is opened
       * by a remote application.
       */
      this.onChannelCreatedListeners = [];
      this.vvcSession = vvcSession;

      /**
       * Initialize the VVC transport listening for channels opened
       * by the server beginning with "RP" as done with the vdpService
       * native client.
       */
      this.vvcListener = vvc.createListener(vvcSession, "RP*");
      this.vvcListener.onpeeropen = (session: any, channel: any): void => {
         if (vvcSession !== session) {
            Logger.error("Unexpected session object.", Logger.VDP);
            return;
         }
         this.onPeerChannelOpen(channel);
      };

      // @ts-ignore TS2350: Only a void function can be called with the 'new' keyword.
      this.sideChannelManager = new SideChannelManager(vvcSession);
   }

   /**
    * Add a listener to be called when a channel is opened by
    * the remote application.
    *
    * @param {Function} callback
    *    This listener is called when the server creates a new channel and no
    *    other listeners have yet to accept the channel.
    *    Parameters:
    *       channel   The VDPChannel object of the newly created channel.
    *                 The plugin must call VDPService.Service.connectChannel to
    *                 accept the channel, the channel will be automatically rejected
    *                 if no listeners accept it, or manually rejected via
    *                 VDPService.Service.rejectChannel
    */
   public addChannelCreatedListener = (callback: any): void => {
      this.onChannelCreatedListeners.push(callback);
   };

   /**
    * Connect and acknowledge the new channel opened by the server.
    *
    * vdpservice.js automatically acknowledges the first "channel object" that is
    * created by the server if the channel object name if not specified through this
    * method. If the "channel object" name is passed with this method, vdpservice.js
    * then only acknowledges a channel object from the server with the desired name.

      * @param {VDPChannel} channel - The VDPChannel object to
      *                                       connect to.
      * @param {[String[]]} desiredObjectNames - The list of object names to accept.
      * @returns {Boolean} true if the channel was successfully acknowledged.
      */
   public connectChannel = (channel: VDPChannel, desiredObjectNames?: string[]): boolean => {
      if (!(channel instanceof VDPChannel)) {
         Logger.error("Invalid argument.", Logger.VDP);
         return false;
      }

      /*
       * The channel cannot be found in our list of existing
       * VDPChannel references.
       */
      if (this.vdpChannels[channel.id] !== channel) {
         Logger.error(
            "Channel with id " + channel.id + " cannot be found in the list of existing channels.",
            Logger.VDP
         );
         return false;
      }

      // The channel in the OPEN state to be connected.
      if (channel.state !== VDP_CONSTS.CHANNEL_STATE.OPEN) {
         Logger.error("Invalid channel state: " + channel.state, Logger.VDP);
         return false;
      }

      // Accept the channel.
      if (!this.vvcSession.acceptChannel(this.vvcSession.getChannel(channel.id))) {
         Logger.error("vvcSession.acceptChannel failed for" + " channel with id: " + channel.id, Logger.VDP);
         delete this.vdpChannels[channel.id];
         return false;
      }

      /*
       * The channel has been accepted by the plugin and is now NOT_READY until
       * one "channel object" is created by the server and acknowledged
       * automatically by VDPChannel implementation.
       */
      channel.state = VDP_CONSTS.CHANNEL_STATE.NOT_READY;
      channel.desiredObjects = desiredObjectNames || [];

      return true;
   };

   /**
    * Reject the newly opened channel by the server.
    *
    * @param {VDPChannel} channel - The VDPChannel object to reject.
    * @returns {boolean} true if the channel was rejected.
    */
   public rejectChannel = (channel: VDPChannel): boolean => {
      if (!(channel instanceof VDPChannel)) {
         Logger.error("Invalid argument.", Logger.VDP);
         return false;
      }

      /*
       * The channel cannot be found in our list of existing
       * VDPChannels.
       */
      if (this.vdpChannels[channel.id] !== channel) {
         Logger.error(
            "Channel with id " + channel.id + " cannot be found in the list of existing channels.",
            Logger.VDP
         );
         return false;
      }

      /*
       * The channel must be in the OPEN state.
       * A OPEN state denotes that the channel is neither accepted
       * or rejected by the plugin.
       */
      if (channel.state !== VDP_CONSTS.CHANNEL_STATE.OPEN) {
         Logger.error("Invalid channel state: " + channel.state, Logger.VDP);
         return false;
      }

      // Reject the channel.
      if (!this.vvcSession.rejectChannel(this.vvcSession.getChannel(channel.id))) {
         Logger.error("vvcSession.rejectChannel failed for" + " channel with id: " + channel.id, Logger.VDP);
         return false;
      }

      channel.state = VDP_CONSTS.CHANNEL_STATE.CLOSED;
      delete this.vdpChannels[channel.id];

      return true;
   };

   /**
    * Disconnect the channel.
    *
    * @param {VDPChannel} channel - The VDPChannel object to
    *                                       disconnect.
    * @returns {Boolean} Returns true if the channel close request was successfully
    *                    sent to VVC and the state is set to CLOSING.
    */
   public disconnectChannel = (channel: VDPChannel): boolean => {
      if (!(channel instanceof VDPChannel)) {
         Logger.error("Invalid argument.", Logger.VDP);
         return false;
      }

      /*
       * The channel cannot be found in our list of existing
       * VDPChannels.
       */
      if (this.vdpChannels[channel.id] !== channel) {
         Logger.error(
            "Channel with id " + channel.id + " cannot be found in the list of existing channels.",
            Logger.VDP
         );
         return false;
      }

      Logger.debug("Disconnecting channel with id:" + channel.id + " state:" + channel.state, Logger.VDP);

      // Call VVCChannel.close() if it has not been yet called.
      if (!(channel.state === VDP_CONSTS.CHANNEL_STATE.CLOSING || channel.state === VDP_CONSTS.CHANNEL_STATE.CLOSED)) {
         if (!this.vvcSession.getChannel(channel.id).close()) {
            Logger.error("vvcSession.disconnectChannel failed for channel with" + " id: " + channel.id, Logger.VDP);
            return false;
         }
         this.sideChannelManager.disconnectChannel(channel);

         channel.state = VDP_CONSTS.CHANNEL_STATE.CLOSING;
      }

      return true;
   };

   /**
    * Whether there is a VDPChannel object with the particular ID
    * in the list of vdpChannels.
    *
    * @param {Integer} channelID - The channel id.
    * @returns {Boolean} Whether the VDPChannel object with the particular
    *                    ID is present in the list of existing channels.
    */
   public isChannelPresent = (channelID: number): boolean => {
      return this.vdpChannels.hasOwnProperty(channelID);
   };

   /**
    * Called when the peer opens a vdp channel.
    *
    * @param {VVCChannel} channel - The VVCChannel object.
    * @private
    */
   private createVDPChannel = (channel: any): void => {
      let vdpChannel: VDPChannel, i: number;

      // An event listener to be called when the VVC channel closes.
      channel.onclose = () => {
         this.onChannelCloseError(channel);
      };

      // An event listener to called when there is a VVC channel error.
      channel.onerror = () => {
         this.onChannelCloseError(channel);
      };

      vdpChannel = new VDPChannel(channel, this.sideChannelManager);
      this.vdpChannels[channel.id] = vdpChannel;

      if (this.onChannelCreatedListeners.length) {
         // Find a listener to accept our newly created channel
         for (i = 0; i < this.onChannelCreatedListeners.length; ++i) {
            this.onChannelCreatedListeners[i](vdpChannel);

            if (vdpChannel.state !== VDP_CONSTS.CHANNEL_STATE.OPEN) {
               break;
            }
         }

         // If no one accepted or rejected the channel we need to reject it.
         if (vdpChannel.state === VDP_CONSTS.CHANNEL_STATE.OPEN) {
            this.rejectChannel(vdpChannel);
         }
      } else {
         Logger.warning("No onChannelCreated listeners have been " + "set by the consumer.", Logger.VDP);
      }
   };

   /**
    * Called when the peer opens a Vchan side channel.
    *
    * @param {VVCChannel} channel - The VVCChannel object.
    * @private
    */
   private createSideChannel = (channel: any): void => {
      this.sideChannelManager.onChannelConnect(channel);
   };

   /**
    * Called when the peer opens a channel.
    *
    * @param {VVCChannel} channel - The VVCChannel object.
    * @private
    */
   private onPeerChannelOpen = (channel: any): void => {
      if (channel.name.endsWith(VDP_CONSTS.VCHAN_SIDECHANNEL_SUFFIX)) {
         this.createSideChannel(channel);
      } else {
         this.createVDPChannel(channel);
      }
   };

   /**
    * Channel has been closed or there is a channel error.
    *
    * @param {VVCChannel} vvcChannel - The VVCChannel object.
    * @private
    */
   private onChannelCloseError = (vvcChannel: any): void => {
      let vdpChannel;

      vdpChannel = this.vdpChannels[vvcChannel.id];

      if (!vdpChannel) {
         Logger.error(
            "Channel with id " + vvcChannel.id + " cannot be found in the list of existing channels.",
            Logger.VDP
         );
         return;
      }

      /*
       * Invoke vdpChannel.onDisconnect if the channel is currently
       * in READY state.
       */
      if (vdpChannel.state === VDP_CONSTS.CHANNEL_STATE.READY) {
         if (vdpChannel.onDisconnect) {
            vdpChannel.onDisconnect();
         } else {
            Logger.warning("onDisconnect listener has not been" + " set by the consumer.", Logger.VDP);
         }
      }

      vdpChannel.state = VDP_CONSTS.CHANNEL_STATE.CLOSED;
      delete this.vdpChannels[vvcChannel.id];
   };
}
