/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * monitor-watcher.service.ts -- monitorWatcherService
 *
 * Use distributed design for both HTML Access and Chrome
 * Client, since it fit to HTML Access and also acceptable
 * on the Chrome Client.
 *
 * If we want to support adding monitor for chrome client
 * later, we could use central watcher, which require different
 * implementation than HTML Access.
 *
 * we can abstract a general watcher as base if needed.
 */

import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class MonitorWatcherService {
   private watcherTimer: any;
   private watchIntervalInMs: number;

   constructor() {
      this.watcherTimer = null;
      this.watchIntervalInMs = 3000;
   }

   /**
    * @private
    * use HTML5 API for both HTML Access and chrome client.
    * Could be a separate service.
    */
   private getMonitorSetting = () => {
      return {
         x: screen.availLeft,
         y: screen.availTop,
         width: screen.availWidth,
         height: screen.availHeight
      };
   };

   /**
    * @private
    * Only implement the unplug event for now
    */
   private watchChanges = (setting, callbacks) => {
      const currentSetting = this.getMonitorSetting();
      if (
         setting.x >= currentSetting.x + currentSetting.width ||
         currentSetting.x >= setting.x + setting.width ||
         setting.y >= currentSetting.y + currentSetting.height ||
         currentSetting.y >= setting.y + setting.height
      ) {
         if (typeof callbacks.onRemoved === "function") {
            callbacks.onRemoved();
         }
      }
   };

   /**
    * @private
    * @return {Boolean} Whether the watcher is enabled
    */
   private isWatching = () => {
      return !!this.watcherTimer;
   };

   /**
    * Only implement the onRemoved for now
    * @param  {object} callbacks The event hanlding callbacks
    */
   public startWatching = (callbacks) => {
      const setting = this.getMonitorSetting();
      // only one watch is enough for the need, use extra check for readbility
      if (this.isWatching()) {
         this.stopWatching();
      }

      this.watcherTimer = setInterval(() => {
         this.watchChanges(setting, callbacks);
      }, this.watchIntervalInMs);
   };

   public stopWatching = () => {
      if (!this.isWatching()) {
         return;
      }
      clearInterval(this.watcherTimer);
      this.watcherTimer = null;
   };
}
