/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * This file is to generate and reuse ClientID, currently, the
 * ClientID will be kept for 10 years, and can cross domains that
 * share a same root domain, so a user can access brokers in the
 * same root-domain with the identical clientID.
 *
 * But access through the static IP of broker or the hostName of
 * the static IP will use different Client ID, and will cost more
 * licenses when access RDSH desktop.
 *
 * And after the cookie is cleared by user, the previous client ID
 * will lost.
 *
 * The client ID is not cross browsers or machines but cross server.
 */

import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class ClientIdService {
   private static readonly ClIENT_UID_KEY = "CID";
   private clientUID: string = null;

   constructor(private localStorageService: LocalStorageService) {}

   /**
    * Generate a random number in [0,1), by using the current time.
    */
   private random = () => {
      const d = new Date(),
         seed = Math.abs(1000 * d.getSeconds() + d.getMilliseconds()),
         r = seed * (window.crypto.getRandomValues(new Uint8Array(1))[0] / 255);
      return r - Math.floor(r);
   };
   /* generate a new Client ID by random array
    *
    * the platform ID is generated as MS requested in MSDN.
    *
    * navigator.platform is supported by Chrome, FF, IE, Opera, Safari, and
    * Android, Chrome for Android, FF Mobile, IE Mobile, Opera Mobile, Safari
    * Mobile, using UA again is just to make code stronger for old browsers.
    *
    * and the String is matched with capital character reserved for reducing
    * the chance of mismatching.
    */
   private generateRandomArray = () => {
      let platformID = "04-00-00-00"; //default

      if (window.navigator.platform) {
         if (window.navigator.platform.indexOf("Win") === 0) {
            //'Win32','Windows'
            platformID = "02-00-00-00";
         }
         if (window.navigator.platform.indexOf("Mac") === 0) {
            // 'Mac68K', 'MacPPC', 'Macintosh', 'MacIntel'
            platformID = "06-00-00-00";
         }
      } else if (window.navigator.userAgent) {
         if (window.navigator.userAgent.indexOf("Windows") > -1) {
            //Windows NT 5.0, Windows 2000, Windows NT 5.1, Windows XP, Windows NT 5.2,
            // Windows 2003, Windows NT 6.0, Windows Vista, Windows NT 6.1, Windows 7,
            // Windows 8
            platformID = "02-00-00-00";
         }
         if (window.navigator.userAgent.indexOf("Mac") > -1) {
            platformID = "06-00-00-00";
         }
      }

      return (platformID + " xx-xx-xx-xx xx-xx-xx-xx xx-xx-xx-xx xx-xx-xx-xx")
         .replace(/x/g, () => {
            const r = (this.random() * 16) | 0;
            return r.toString(16);
         })
         .split(/ |-/);
   };

   private arrayToBase64 = (uidIntArray) => {
      let i = 0,
         arrayLength = uidIntArray.length,
         h1,
         h2,
         h3,
         number,
         c1,
         c2,
         c3,
         c4,
         toBase64Table = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
         charsArray = [],
         result,
         indexAc = 0,
         r;

      do {
         // pack three bytes into four characters in 64-base
         h1 = parseInt(uidIntArray[i++], 16); //undefine will return NaN
         h2 = parseInt(uidIntArray[i++], 16);
         h3 = parseInt(uidIntArray[i++], 16);

         number = (h1 << 16) | (h2 << 8) | h3; //NaN will be treated as 0

         c1 = (number >> 18) & 0x3f;
         c2 = (number >> 12) & 0x3f;
         c3 = (number >> 6) & 0x3f;
         c4 = number & 0x3f;

         // Convert decimal into base64, and append to array to avoid
         // realloc spaces too often.
         charsArray[indexAc++] = toBase64Table[c1] + toBase64Table[c2] + toBase64Table[c3] + toBase64Table[c4];
      } while (i < arrayLength);
      result = charsArray.join("");

      //replace the undefined pure-0 character(A)s part with '='s
      r = uidIntArray.length % 3;
      return (r ? result.slice(0, r - 3) : result) + "===".slice(r || 3);
   };

   public generateUID = () => {
      const uidIntArray = this.generateRandomArray();
      return this.arrayToBase64(uidIntArray);
   };

   private storeUID = () => {
      this.localStorageService.set(ClientIdService.ClIENT_UID_KEY, this.clientUID);
   };

   private initUID = () => {
      this.clientUID = this.localStorageService.get(ClientIdService.ClIENT_UID_KEY) || null;
   };

   public getClientID = (): string => {
      this.initUID();
      if (!this.clientUID) {
         this.clientUID = this.generateUID();
         this.storeUID();
         Logger.warning("Generating a new Client ID: " + this.clientUID);
      } else {
         Logger.info("Reuse previous Client ID: " + this.clientUID);
      }
      return this.clientUID;
   };
}
