/**
 * ******************************************************
 * Copyright (C) 2013 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { KeyMapping } from "../keyboard-setting/keyboard-setting.service";

export abstract class KeyMappingStorageService {
   public abstract isPrefLimitationExist();
   public abstract saveKeyMapping(keyMappingSetting: string);
   public abstract getKeyMapping();
   constructor() {}
}
