/**
 * *****************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Logger } from "@html-core";
import { HTML5FileSystemFileHandler } from "../../../../html5-client/desktop/cdr/html5-file-system-file-handler";
import { HorizonIndexedDBService } from "../../../../core/services/storage/horizon-indexed-db.service";
import { HTML5FileSystemDirectoryHandler } from "../../../../html5-client/desktop/cdr/html5-file-system-directory-handler";
import { HorizonFileSystem } from "./horizon-file-system";
import { CDRInfoInIndexedDB } from "./cdr-interface";

const INDEXED_DB_VERSION = 1;
export class Html5FileSystem extends HorizonFileSystem {
   constructor() {
      super();
   }

   public isRestorable = (info, callback) => {
      //Todo
      return new Promise((resolve, reject) => {
         resolve(info.entry);
      });
   };

   public chooseEntry = () => {
      return new Promise((resolve, reject) => {
         window
            //@ts-ignore
            .showDirectoryPicker({ mode: "readwrite" })
            .then(async (directoryHandler) => {
               Logger.info("user allow to edit the selected file in cdr file picker");
               const directoryEntry = new HTML5FileSystemDirectoryHandler(directoryHandler);
               const permission = await directoryHandler.requestPermission({ mode: "readwrite" });
               if (permission === "granted") {
                  Logger.debug("User accept the read-write permission");
                  resolve(directoryEntry);
               } else {
                  Logger.debug("User reject the Read-write permission");
                  reject();
               }
            })
            .catch((e) => {
               Logger.info("user refuse to edit the selected file in cdr file picker");
               reject();
            });
      });
   };

   public retainEntry = (entry): string => {
      return entry.folderId;
   };

   public getWritableEntry = (entry) => {
      return new Promise((resolve, reject) => {
         resolve(entry);
      });
   };
   public getDisplayPath = (rootEntry, callback) => {
      if (rootEntry) {
         const fullPath = "/" + rootEntry.name;
         callback(fullPath);
      }
   };

   public getDirectoryEntryFromStorage = (name) => {
      return new Promise((resolve, reject) => {
         HorizonIndexedDBService.get("HorizonFolderSharing", "folder-entries", name)
            .then(async (res: any) => {
               const info = [];
               if (res && res.folderEntry) {
                  for (let i = 0; i < res.folderEntry.length; i++) {
                     const directoryEntry = new HTML5FileSystemDirectoryHandler(res.folderEntry[i]);
                     info.push({
                        id: "HTMLCDR:" + res.folderEntry[i].name,
                        entry: directoryEntry,
                        path: "/" + res.folderEntry[i].name
                     });
                  }
                  resolve(info);
               } else {
                  resolve([]);
               }
            })
            .catch((e) => {
               resolve([]);
               Logger.debug(e);
            });
      });
   };

   public saveDirectoryEntryInStorage = (folderId, folderEntry) => {
      return new Promise((resolve, reject) => {
         const data: CDRInfoInIndexedDB = {
            folderId: folderId,
            folderEntry: folderEntry,
            version: INDEXED_DB_VERSION
         };
         HorizonIndexedDBService.set("HorizonFolderSharing", "folder-entries", folderId, data)
            .then((res) => {
               resolve(res);
            })
            .catch(() => {
               resolve(false);
            });
      });
   };
}
