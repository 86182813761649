/** @format */

/*********************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 *********************************************************
 *
 * @format
 */

export class Point {
   constructor(
      public x: number,
      public y: number
   ) {}
}

// basic rect
export class Rect {
   constructor(
      public x: number,
      public y: number,
      public width: number,
      public height: number
   ) {}
}

// no positive check for inner usage.
export class Size {
   constructor(
      public width: number,
      public height: number
   ) {}
}

export interface RectStore {
   data: ArrayBuffer;
   image: ImageBitmap;
}

// image rect for update
export class ImageRect {
   public subEncoding: number;
   public encoding: number;
   public srcX: number;
   public srcY: number;
   public opcode: number;
   public data: ArrayBuffer;
   public image: ImageBitmap;
   public dataLength: number;
   public slot: number;
   public x: number;
   public y: number;
   public width: number;
   public height: number;
   public color: Array<number>;
   public updateCacheEntries: number;
   constructor(rect?: any) {
      this.data = rect ? rect.data : null;
      this.image = rect ? rect.image : null;
      this.dataLength = rect ? rect.dataLength : 0;
      this.slot = rect ? rect.slot : 0;
      this.x = rect ? rect.x : 0;
      this.y = rect ? rect.y : 0;
      this.width = rect ? rect.width : 0;
      this.height = rect ? rect.height : 0;
      this.subEncoding = rect ? rect.subEncoding : "";
      this.encoding = rect ? rect.encoding : "";
      this.srcX = rect ? rect.srcX : 0;
      this.srcY = rect ? rect.srcY : 0;
      this.opcode = rect ? rect.opcode : 0;
      this.color = rect ? rect.color : [0, 0, 0];
      this.updateCacheEntries = 0;
   }
}
