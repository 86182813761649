/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import util from "../../jscdk/util";
import { Injectable, Optional } from "@angular/core";
import { ConnectionURIModel } from "../model/connection-uri-model";
import { JscdkCommonInvoker } from "./jscdk-common-invoker";
import { ViewService } from "../../launcher/common/view-service";
import { UtilService } from "../../launcher/common/util-service";
import { ConnectionServerModel } from "../model/connection-server-model";
import { BusEvent, EventBusService } from "../../../core/services/event";
import { ClientModeService } from "../service/client-mode.service";
import { ModalDialogService } from "../commondialog/dialog.service";
import { SDKService } from "../../../chrome-client/SDK/sdk-service";

@Injectable({
   providedIn: "root"
})
export class JscdkAuthService {
   constructor(
      private connectionURIModel: ConnectionURIModel,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private viewService: ViewService,
      private utilService: UtilService,
      private connectionServerModel: ConnectionServerModel,
      private eventBusService: EventBusService,
      private clientModeService: ClientModeService,
      private modalDialogService: ModalDialogService,
      @Optional()
      private sdkService: SDKService
   ) {}

   public handleAuthenticationStatusUnlocked = (jscdkWrapper) => {
      if (!this.utilService.authenticationStatusCheck.isLaunchAction() && jscdkWrapper.currentController) {
         jscdkWrapper.currentController.enable();
      }
      this.utilService.authenticationStatusCheck.doAction(jscdkWrapper.currentController);
   };

   public handleSessionUnlocked = () => {
      if (this.clientModeService.clientMode === "launcher") {
         if (this.connectionURIModel.params) {
            // URI connection reuses the previous session.
            this.connectionURIModel.reuseSession = true;
            // Clear saml after unlocked, fix bug 2594162
            this.connectionURIModel.params.samlArt = null;
         }
         // Already authenticated, navigate to the desktops or
         // launchitems page and ensure the timer is started
         if (util.brokerSupportApplication()) {
            this.jscdkCommonInvoker.startIdleTimer();
         }
         this.jscdkCommonInvoker.retrieveLaunchItems();
         return;
      }
   };

   public handleLockedNewTab = (jscdkHandler, actionObj) => {
      if (this.clientModeService.clientMode === "launcher") {
         this.jscdkCommonInvoker.startIdleTimer();
         this.connectionURIModel.isIdenticalUser(this.connectionServerModel.host, (isIdentical) => {
            if (!isIdentical) {
               if (this.connectionURIModel.isHWSession() || (this.sdkService && this.sdkService.isSDKSession())) {
                  // Logout previous broker session, but with uri info in jscdk and
                  // UI reserved.
                  this.connectionURIModel.reuseSession = false;
                  this.jscdkCommonInvoker.logout(false);
                  this.viewService.logoutToPortal = false;
                  if (this.connectionURIModel.hasSaml()) {
                     this.viewService.logoutToLogin = true;
                  }
               } else {
                  /**
                   * Current URI connection has different connection
                   * info from that of the session. Confirm with the user
                   * whether to reuse the present session.
                   */
                  this.modalDialogService.showConfirm({
                     data: {
                        titleKey: "dialog_title_logout",
                        contentKey: "dialog_confirm_logout"
                     },
                     callbacks: {
                        confirm: () => {
                           // Logout broker, but with uri info in jscdk and
                           // UI reversed.
                           this.connectionURIModel.reuseSession = false;
                           this.jscdkCommonInvoker.logout(false);
                           this.viewService.logoutToPortal = false;
                           this.viewService.logoutToLogin = true;
                           Logger.debug("ok to switch to new user in URL");
                        },
                        cancel: () => {
                           this.connectionURIModel.reuseSession = false;
                           this.jscdkCommonInvoker.sendDoUnlock();
                           Logger.debug("cancel to continue unlock session");
                        }
                     }
                  });
               }
            } else {
               if (this.connectionURIModel.params) {
                  this.connectionURIModel.reuseSession = true;
               }
               this.jscdkCommonInvoker.sendDoUnlock();
            }
         });
         return;
         // two cases for user act in the select page in silent mode
      } else {
         jscdkHandler.invokeCallback(actionObj);
      }
   };

   public handleWindowsPasswordExpired = (jscdkHandler, actionObj) => {
      if (this.clientModeService.clientMode === "desktop") {
         this.handleAuthenticationStatusLocked(actionObj);
         this.eventBusService.dispatch({
            type: "reAuthWindowsPasswordExpired",
            data: actionObj
         });
         this.eventBusService.dispatch({ type: "switchPage" });
      }
   };

   public handleWindowsPassword = (jscdkHandler, actionObj) => {
      if (this.clientModeService.clientMode === "desktop") {
         this.handleAuthenticationStatusLocked(actionObj);
         this.eventBusService.dispatch({
            type: "changeWindowsPassword",
            data: actionObj
         });
         this.eventBusService.dispatch({ type: "switchPage" });
      } else {
         // fix bug: VCART-1317.
         // Need to close the setting dialog when returning to the login page,
         // otherwise it will overwrite the login page.
         this.modalDialogService.closeDialogByType("setting-window");
      }
   };

   public authEventToUI = (actionObj) => {
      if (this.clientModeService.clientMode === "desktop") {
         //This scope is to pop up the re-auth dialog
         //and show different content according to actionObj.name
         this.handleAuthenticationStatusLocked(actionObj);
      }
      this.eventBusService.dispatch({ type: "switchPage" });
   };

   public handleAuthenticationStatusLocked = (actionData) => {
      if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
         return;
      }
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
      this.modalDialogService.showReAuth();
   };

   public sendDoUnlock = () => {
      this.jscdkCommonInvoker.sendDoUnlock();
   };
}
