/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * entitleditem-model.js --
 *
 * Module to provide entitled items information for Sidebar.
 *
 */

import { Subject } from "rxjs";
import util from "../../jscdk/util";
import { AB } from "./appblast-util.service";
import { Injectable } from "@angular/core";
import { CLIENT_TYPE, ENTITLE_TYPE } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class EntitledItemsModel {
   private entitledItems: Array<EntitlementItem> = null;
   public entitleChange$ = new Subject();

   constructor() {
      this.entitledItems = new Array<EntitlementItem>();
   }

   public setEntitledItems(items: Array<EntitlementItem>): void {
      this.entitledItems = items;
      this.entitleChange$.next(true);
   }

   public init = (data: any): void => {
      this.entitledItems = [];

      if (data && data.content) {
         const desktops = data.content.desktops;
         for (const desktopKey in desktops) {
            if (desktops.hasOwnProperty(desktopKey)) {
               const desktop = desktops[desktopKey];
               if (desktop["protocol-match"] === "false") {
                  continue;
               }
               const item: EntitlementItem = {} as EntitlementItem;
               item.id = desktop.id;
               item.favorite = desktop.favorite;
               item.canLogoff = desktop.canLogoff === "true";
               item.isShadow = false;
               item.name = desktop.name;
               item.clientType = CLIENT_TYPE.HORIZON;
               item.type = ENTITLE_TYPE.DESKTOP;
               item.resetable = desktop["reset-allowed-on-session"] === "true";
               item.restartable = desktop["reset-allowed-on-session"] === "true" && util.brokerSupportRestartDesktop();
               item.iconSrc = "";
               item.isDesktop = true;

               this.entitledItems.push(item);
            }
         }

         const shadowSessions = data.content.shadowSessions;
         for (const shadowSessionKey in shadowSessions) {
            if (shadowSessions.hasOwnProperty(shadowSessionKey)) {
               const shadow = shadowSessions[shadowSessionKey];

               if (shadow["protocol-match"] === "false" || shadow.type !== "desktop") {
                  continue;
               }
               const item: EntitlementItem = {} as EntitlementItem;
               item.id = shadow.id;
               item.favorite = shadow.favorite;
               item.canLogoff = shadow.canLogoff === "true";
               item.isShadow = true;
               item.name = shadow.name;
               item.clientType = CLIENT_TYPE.HORIZON;
               item.type = ENTITLE_TYPE.DESKTOP;
               item.resetable = shadow["reset-allowed-on-session"] === "true";
               item.restartable = shadow["reset-allowed-on-session"] === "true" && util.brokerSupportRestartDesktop();
               item.iconSrc = "";
               item.isDesktop = true;

               this.entitledItems.push(item);
            }
         }

         const applications = data.content.applications;
         for (const applicationKey in applications) {
            if (applications.hasOwnProperty(applicationKey)) {
               const application = applications[applicationKey];

               if (application["protocol-match"] === "false") {
                  continue;
               }
               const item: EntitlementItem = {} as EntitlementItem;
               item.id = application.id;
               item.originId = application["origin-id"];
               item.favorite = application.favorite;
               item.canLogoff = application.canLogoff === "true";
               item.isShadow = false;
               item.name = application.name;
               item.clientType = CLIENT_TYPE.HORIZON;
               item.type = ENTITLE_TYPE.APPLICATION;
               item.resetable = false;
               item.restartable = false;
               item.iconSrc = "";
               item.isDesktop = false;

               let iconIndex = AB.DEFAULT_ICON_INDEX;
               if (iconIndex === -1) {
                  const start = document.URL.indexOf("/portal");
                  const end = document.URL.indexOf("index");
                  const part = document.URL.substring(start, end);
                  item.iconSrc = part + AB.ICONS.BIG_DEFAULT_ICON;
               } else {
                  if (Object.keys(application.icons).length > 0) {
                     item.iconSrc = application.icons[iconIndex].path;
                  }
               }
               this.entitledItems.push(item);
            }
         }
         this.entitleChange$.next(true);
      }
   };

   public getEntitledItems = (): Array<EntitlementItem> => {
      return this.entitledItems;
   };

   public isItemExisting = (type: ENTITLE_TYPE, id: string): EntitlementItem => {
      for (const item of this.entitledItems) {
         if (item.type === type && item.id === id) {
            return item;
         }
      }
      return null;
   };

   public findEntitlement = (id: string, name: string, type: ENTITLE_TYPE): EntitlementItem => {
      let entitlement: EntitlementItem = null;
      for (const item of this.entitledItems) {
         if (type === ENTITLE_TYPE.UNKNOWN || item.type === type) {
            if (item.id === id || item.name === name || item.id === name || item.name === id) {
               entitlement = item;
               break;
            }
         }
      }
      return entitlement;
   };
}
