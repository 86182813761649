<div [hidden]="!showLaunchPanel" class="separator"></div>
<div [hidden]="!showLaunchPanel" class="available-title-panel">
   <h4 class="running-title">{{'AVAILABLE_T' | i18nv2}}</h4>
   <div *ngIf="showPreference" class="available-button-bar">
      <form>
         <li [attr.checked]="showOnlyFavorites" class="icon-button all-apps-button" [ngClass]="{'on': !showOnlyFavorites}" (click)="disableShowOnlyFavorites()" (keydown)="disableShowOnlyFavoritesKeydown($event)" title="{{'SHOW_ALL_T' | i18nv2}}" tabindex="0" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]="'SHOW_ALL_BUTTON' | i18nv2">
            <img *ngIf="!showOnlyFavorites" class="ui-showAll-icon-on" [src]="this.imageAsset.iclShowAllOn" aria-hidden="true">
            <img *ngIf="showOnlyFavorites" class="ui-showAll-icon" [src]="this.imageAsset.iclShowAll" aria-hidden="true">
         </li>
         <li [attr.checked]="!showOnlyFavorites"class="icon-button favorite-apps-button" [ngClass]="{'on': showOnlyFavorites}" (click)="enableShowOnlyFavorites()" (keydown)="enableShowOnlyFavoritesKeydown($event)" title="{{'SHOW_FAVORITES_T' | i18nv2}}" tabindex="0" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]="'SHOW_FAVORITES_BUTTON' | i18nv2">
            <img *ngIf="showOnlyFavorites" class="ui-showFav-icon-on" [src]="this.imageAsset.iclFavOn" aria-hidden="true">
            <img *ngIf="!showOnlyFavorites" class="ui-showFav-icon" [src]="this.imageAsset.iclFav" aria-hidden="true">
         </li>
      </form>
   </div>
</div>
<div [hidden]="!showLaunchPanel" class="available-content-panel">
   <div
      *ngFor="let entitledItem of (entitledItems | availableItemsFilter: query:showOnlyFavorites) | orderBy: 'type': 'name'">
      <ul class="running-app">
         <ul class="icon-and-name-container" (click)="launchItem(entitledItem)" (keydown)="launchItemKeydown($event, entitledItem)" (contextmenu)="toggleContextMenu(entitledItem, $event)"
            title="{{'LAUNCH_ITEM_T' | i18nv2 : entitledItem.name}}" id="{{'available-'+entitledItem.name}}" tabindex="0" (focus)="showAvailableItemTooltip()">
            <li class="running-app-border">
               <img alt="{{entitledItem.name}} icon" class="running-app-icon"
                  [src]="this.imageAsset.deAppIcon" [hidden]="!isApp(entitledItem)" icon-load url="{{entitledItem.iconSrc}}" />
               <img alt="{{entitledItem.name}} icon" class="running-app-icon"
                  [src]="getDesktopIcon(entitledItem)"
                  [hidden]="!(isDesktop(entitledItem) && !entitledItem.isShadow)" />
               <img alt="{{entitledItem.name}} icon" class="running-app-icon"
                  [src]="this.imageAsset.deShadowIcon"
                  [hidden]="!(isDesktop(entitledItem) && entitledItem.isShadow)" />
            </li>
            <li aria-hidden="true" class="available-app-name">{{entitledItem.name}}</li>
         </ul>
         <li *ngIf="showPreference && entitledItem.favorite" class="available-app-favorite-button on" id="{{entitledItem.id}}FavOn"
            title="{{'REMOVE_FAVORITE_T' | i18nv2 : entitledItem.name}}" (click)="toggleFavorite(entitledItem)" (keydown)="favoriteKeydown($event, entitledItem)" tabindex="0" (focus)="showFavoriteBtnTooltip()">
         </li>
         <li *ngIf="showPreference && !entitledItem.favorite" class="available-app-favorite-button off" id="{{entitledItem.id}}FavOff"
            title="{{'ADD_FAVORITE_T' | i18nv2 : entitledItem.name}}" (click)="toggleFavorite(entitledItem)" (keydown)="favoriteKeydown($event, entitledItem)" tabindex="0" (focus)="showFavoriteBtnTooltip()">
         </li>
         <li class="contextmenu-button" [hidden]="!isTitanClient && !isDesktop(entitledItem)">
            <button class="overflow-button app-item-action primary-text-color"
                  [attr.aria-label]="'apps.aria.label.context.menu.overflow' | i18nv2"
                  aria-haspopup="true"
                  aria-expanded="false"
                  (click)="toggleContextMenu(entitledItem, $event) "
                  (contextmenu)="toggleContextMenu(entitledItem, $event) "
                  (keydown)="toggleContextMenuKeydown($event, entitledItem) ">
            </button>
         </li>
         <share-contextmenu
               *ngIf="entitledItem.showContextMenu"
               class="content-menu"
               [app]="entitledItem"
               [item]="entitledItem"
               [paneClass]="paneClass"
               [contextmenuName]="contextmenuName"
               (protocolSelectEvent)="onUpdateSelectedProtocol($event)"
               (appLaunchEvent)="onLaunchHorizonAppFromContextMenu($event)"
               (connectEvent)="launchItem($event)">
         </share-contextmenu>
      </ul>
   </div>
   <div
      [hidden]="!(showOnlyFavorites && (entitledItems | availableItemsFilter: query:showOnlyFavorites).length === 0)">
      <div class="no-items-label">{{'NO_FAVORITES_T' | i18nv2}}</div>
   </div>
</div>