/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { BusEvent, EventBusService, Logger } from "@html-core";
import { CommonSDKService } from "../../shared/common/service/sdk.service";
import { ProtocolSessionDisconnectedInfo, ProtocolSessionInfo } from "../../chrome-client/SDK/sdk-service";
import { RemoteSessionEventService, SessionMsg } from "../../shared/common/remote-session/remote-session-event.service";
import { BlastWmks } from "../../shared/desktop/common/blast-wmks.service";
import { LaunchUriService } from "../../shared/desktop/common/launch-uri.service";
import { ViewClientModel } from "../../shared/common/model/viewclient-model";

@Injectable()
export class Html5SDKService extends CommonSDKService {
   private instanceId = 1;
   private serverId: string = null;
   private authSecret: string = null;
   private disclaimerAccepted: boolean = null;
   private logger = new Logger(Logger.SDK);

   constructor(
      private eventBusService: EventBusService,
      private remoteSessionEventService: RemoteSessionEventService,
      private launchUriService: LaunchUriService,
      private viewClientModel: ViewClientModel
   ) {
      super();
      this.eventBusService.listen(BusEvent.SessionUnlocked.MSG_TYPE).subscribe(() => {
         this.clearAuthSecret("for already authenticated or session unlock");
      });
      this.eventBusService.listen(BusEvent.AuthenticationDeclined.MSG_TYPE).subscribe(() => {
         this.clearAuthSecret("for authentication declined");
      });
      this.eventBusService.listen(BusEvent.DiscardAuthInfo.MSG_TYPE).subscribe(() => {
         this.clearAuthSecret("for client should not use it anymore");
      });
   }

   public init = async () => {
      if (this.viewClientModel.enableExtendedAPI && this.launchUriService.urlParams.authCallbacks === "true") {
         this.logger.info("init SDK module");
         this.boundEvents();
         return this;
      } else {
         return null;
      }
   };

   public isHideClientEnabled = () => false;

   public boundEvents = () => {
      this.logger.info("init SDK module");
      this.eventBusService.listen(BusEvent.DisclaimerDisplayed.MSG_TYPE).subscribe(this.onDisclaimerDisplayed);
      this.eventBusService.listen(BusEvent.AuthenticationDeclined.MSG_TYPE).subscribe(this.onAuthenticationDeclined);
      this.eventBusService.listen(BusEvent.AuthFailedMsg.MSG_TYPE).subscribe(this.onAuthenticationFailed);
      this.eventBusService.listen(BusEvent.ItemLaunchFailed.MSG_TYPE).subscribe(this.onItemLaunchFailed);
      this.eventBusService.listen(BusEvent.ItemLaunchSucceeded.MSG_TYPE).subscribe(this.onItemLaunchSucceeded);
      this.eventBusService.listen("newProtocolSessionCreated").subscribe(this.onNewProtocolSessionCreated);
      this.eventBusService.listen("protocolSessionDisconnected").subscribe(this.onProtocolSessionDisconnected);
      this.remoteSessionEventService.addEventListener(
         SessionMsg.SESSION_CONNECTED_MSG,
         this.onProtocolSessionConnected
      );
   };

   public onDisclaimerDisplayed = (event: BusEvent.DisclaimerDisplayed) => {
      this.getCallbacks().onDisclaimerDisplayed?.(this.serverId, event.data.disclaimerText, event.data.clientData);
   };

   public onAuthenticationDeclined = (event: BusEvent.AuthenticationDeclined) => {
      this.getCallbacks().onAuthenticationDeclined?.(this.serverId, event.data.authType, event.data.clientData);
   };

   public onAuthenticationFailed = (event: BusEvent.AuthFailedMsg) => {
      this.getCallbacks().onAuthenticationFailed?.(
         this.serverId,
         event.authType,
         event.errorMessage,
         event.retryAllowed
      );
   };

   public onItemLaunchFailed = (event: BusEvent.ItemLaunchFailed) => {
      this.getCallbacks().onItemLaunchFailed?.(
         this.serverId,
         event.data.itemType,
         event.data.launchItemId,
         event.data.errorMessage,
         event.data.clientData
      );
   };
   public onItemLaunchSucceeded = (event: BusEvent.ItemLaunchSucceeded) => {
      this.getCallbacks().onItemLaunchSucceeded?.(
         this.serverId,
         event.data.itemType,
         event.data.launchItemId,
         event.data.clientData
      );
   };

   public onNewProtocolSessionCreated = (event: ProtocolSessionInfo) => {
      const session = event.data.remoteSession;
      session.serverId = this.serverId;
      session.clientInstanceId = this.instanceId;
      this.getCallbacks().onNewProtocolSessionCreated?.(session, "Blast", event.data.clientData);
   };

   public onProtocolSessionDisconnected = (event: ProtocolSessionDisconnectedInfo) => {
      const session = event.data.remoteSession;
      session.serverId = this.serverId;
      session.clientInstanceId = this.instanceId;
      this.getCallbacks().onProtocolSessionDisconnected?.(
         session,
         event.data.connectionFailed,
         event.data.errorMessage,
         event.data.clientData
      );
   };

   public onProtocolSessionConnected = (session: BlastWmks) => {
      this.getCallbacks().onProtocolSessionConnected?.({
         name: session.name,
         id: session.entitleId,
         sessionId: session.key,
         protocol: "Blast",
         type: session.isApplicationSession ? "App" : "Desktop",
         allowedOperations: ["Disconnect"],
         serverId: this.serverId,
         clientInstanceId: this.instanceId
      });
   };

   public getAuthSecret = async () => {
      const rawSecret = this.authSecret;
      this.clearAuthSecret("for client already use it once");
      if (rawSecret) {
         return rawSecret;
      }
      throw "failed to read the secret";
   };

   public hasAuthSecret = () => !!this.authSecret;

   public setAuthSecret = (authSecret: string) => {
      this.authSecret = authSecret;
   };

   public clearAuthSecret = (reason: string) => {
      if (this.hasAuthSecret()) {
         this.authSecret = null;
         this.logger.info("SDK auth info cleared for " + reason);
      }
   };

   public getDisclaimerAccepted = () => {
      const accepted = this.disclaimerAccepted;
      this.disclaimerAccepted = null;
      return accepted;
   };

   public hasDisclaimerAccepted = () => this.disclaimerAccepted !== null;

   public setDisclaimerAccepted = (accepted: boolean) => {
      this.disclaimerAccepted = accepted;
   };

   private getCallbacks(): ExtendedCallbacksAPI_V1 {
      return window.hzClient.callbacks || {};
   }
}
