/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * printRd-service.js --
 *
 * Module for wrapping print redirection to angular factory service. printRd Service
 * is a singleton service.
 *
 */

import * as CST from "@html-core";
import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { PrintRdChannel, IPrintRdChannelCB } from "./print-redirection-channel";
import { PrintClientInfo, PrintRdProtocol } from "./print-redirection-protocol";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { BlastWmks } from "../common/blast-wmks.service";
import { PrePrintDataService } from "./pre-print-redirection.service";
import { UserInfoService } from "../../common/service/user-info.service";

export interface PrintFile {
   jobId: number;
   wmksKey: string;
   pdfData: any;
   metaData?: any;
}

@Injectable()
export class PrintRdService implements IPrintRdChannelCB {
   public static readonly RECEIVED_PDF = "printRdSessionReceivedPDF";
   public static readonly RECEIVED_NOTIFICATION = "printRdSessionReceivedNotification";
   printRdChannelMap: Map<string, PrintRdChannel>;
   printSubject = new Subject();
   clientInfo: PrintClientInfo = {} as PrintClientInfo;

   constructor(
      private remoteSessionEventService: RemoteSessionEventService,
      private userInfoService: UserInfoService,
      private prePrintDataService: PrePrintDataService
   ) {
      this.printRdChannelMap = new Map<string, PrintRdChannel>();
      const username = this.userInfoService.getUserName() || "";
      this.clientInfo.username = CST.UsernameUtil.getUsername(username);
      this.clientInfo.clientType = "Horizon Printer";
      if (CST.clientUtil.isChromeClient()) {
         this.clientInfo.version = "Chr" + __VDM_WEB_CLIENT_VERSION__;
      } else {
         this.clientInfo.version = "Web" + __VDM_WEB_CLIENT_VERSION__;
      }
      this.remoteSessionEventService.addEventListener("sessionConnecting", this.printRdOnWmksSessionConnecting);
   }

   private initChannelEventsListener = (wmksKey) => {
      if (!this.printRdChannelMap.has(wmksKey)) {
         Logger.error(PrintRdProtocol.LOGFX + "Can't find [" + wmksKey + "] in session map");
      }
   };

   private printRdOnWmksSessionConnecting = (session: BlastWmks) => {
      Logger.debug(PrintRdProtocol.LOGFX + "printRdOnWmksSessionConnecting");
      const printRdChannel = new PrintRdChannel(session.vdpService, session.key, this, this.prePrintDataService);
      this.printRdChannelMap.set(session.key, printRdChannel);
      this.initChannelEventsListener(session.key);
   };

   public onPrinterMessage = (key: string, data: Uint8Array): void => {
      if (this.printRdChannelMap.get(key)) {
         const printRdChannel = this.printRdChannelMap.get(key);
         this.handleChannelPrinterRdMessage(printRdChannel, data, key);
      } else {
         Logger.warning(PrintRdProtocol.LOGFX + "Receive message from invalid channel");
      }
   };

   private handleChannelPrinterRdMessage = (printRdChannel, data, wmksKey) => {
      const self: PrintRdService = this;
      const packet = WMKS.Packet.createFromBufferLE(data);
      const printMsg = PrintRdProtocol.handlePrinterRedirectionMessage(packet, wmksKey);

      if (printMsg.status === false) {
         return;
      }

      printRdChannel.handlePrinterRedirectionMessage(printMsg, packet, self.clientInfo, (reply) => {
         if (reply.isStreamData) {
            if (reply.isStreamDataReady) {
               const pdf: PrintFile = {} as PrintFile;
               pdf.wmksKey = wmksKey;
               pdf.pdfData = reply.finalPdfFile;
               pdf.jobId = reply.jobId;
               if (reply.metaData) {
                  pdf.metaData = reply.metaData;
               }
               self.printSubject.next({
                  type: "printRdSessionReceivedPDF",
                  data: pdf
               });
            }
         } else {
            if (reply.isStartFlag) {
               self.printSubject.next({
                  type: "printRdSessionReceivedNotification",
                  data: null
               });
            } else {
               Logger.trace("printRdChannel sendPostMsgFastRPC: " + JSON.stringify(reply), Logger.PRINTER_RD);
               printRdChannel.sendPostMsgFastRPC(
                  reply,
                  () => {
                     Logger.debug(PrintRdProtocol.LOGFX + "sendPostMsgFastRPC success");
                  },
                  () => {
                     Logger.debug(PrintRdProtocol.LOGFX + "sendPostMsgFastRPC abort");
                  }
               );
            }
         }
      });
   };

   public removePrintDoneFile(wmksKey: string, jobId: number) {
      if (this.printRdChannelMap.has(wmksKey)) {
         const printRdChannel = this.printRdChannelMap.get(wmksKey);
         printRdChannel.printFile.delete(jobId);
      }
   }
}
