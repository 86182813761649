/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 *
 * TODO-NG: during connecting desktop/app, start the timer
 */

import Logger from "../../../core/libs/logger";
import * as CST from "@html-core";
import $ from "jquery";
import util from "../../jscdk/util";

import { Component, ChangeDetectorRef, Optional } from "@angular/core";
import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { ConnectionServerModel } from "../../common/model/connection-server-model";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { UtilService } from "../common/util-service";
import { CredCleanService } from "../../common/service/credential-clean.service";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { LocalStorageService, EventBusService, clientUtil, BusEvent, TranslateService, isOnRamp } from "@html-core";
import { ShareFolderModel } from "../../common/model/share-folder-model";
import { IdleSessionService } from "../../common/service/idle-session.service";
import { ConnectionRetryService } from "../../common/service/connection-retry.service";
import { JscdkWrapper } from "../../common/jscdk/jscdk-wrapper";
import { ClientSettingModel } from "../../common/model/client-setting-model";
import { LaunchService } from "./session-launch.service";
import { Ws1Service } from "../../common/service/ws1.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { AnonymousService } from "../../common/service/anonymous.service";
import { Timezone } from "../../common/timezone/timezone.service";
import { ConnectionURIModel } from "../../common/model/connection-uri-model";
import { LaunchItemsCtrlService } from "../launchitems/launch-item-ctrl.service";
import { HeaderOption } from "../common/header/header.component";
import { AppViewService } from "../../../shared/common/service/app-view.service";
import { Subscription } from "rxjs";
import { AudioService } from "../../desktop/common/audio.service";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { globalArray } from "../../jscdk/jscdkClient";
import { ContextMenuUtilService } from "../../../shared/common/context-menu/context-menu-util.service";
import { imageAsset } from "../../common/image-asset/image-asset";
import { AutoForwardPolicyService } from "../auto-usb-setting/auto-forward-usb-policy.service";
import { OnRampService } from "../../../shared/common/service/on-ramp.service";
// move to new router in the next patch
@Component({
   selector: "launch-items",
   templateUrl: "./launch-items.component.html"
})
export class LaunchItemsComponent extends BaseViewComponent implements BaseViewComponentInterface {
   /**
    * private
    * ------------------------
    */
   // Timeout timer ID
   private _timeoutID = null;

   /**
    * public
    * ------------------------
    */

   /**
    * @event triggered from header
    */
   public search: string = "";
   /**
    * @event triggered from header
    * whether we should only show fav
    */
   public showOnlyFavorites: boolean = false;
   public isRampEnabled: boolean = false;

   public isChromeWS1Mode: boolean;
   public isUrlLaunching: boolean;
   public headerOptions: HeaderOption = {
      hide: true,
      logout: false,
      searchBar: false
   };
   public showPreference: boolean;
   public showLaunchPanel: boolean;

   /**
    * copy from service/model for UI
    */

   public desktops = [];
   /**
    * copy from service/model for UI
    */

   public applications = [];
   // Buffered app launching cmd
   public bufferedArgs = null;

   // Current click desktop.
   public currentDesktop = null;
   public switchUserDialog: string;
   public focusElement = null;
   public activeItem = null;

   private _isLaunchingItem: boolean;
   private _isSessionConnecting: boolean;
   private _launchTimeoutDialog: string = null;
   private _sessionConnectingSubscription: Subscription = null;

   public favTip = this.translate._T("mark_fav");
   public unfavTip = this.translate._T("unmark_fav");
   public showLine = true;
   public contextMenuItem = null;
   public contextmenuName = "htmlaccess";
   public paneClass = ".content-panel-container";
   public imageAsset = imageAsset;

   private _skipRendering = false;
   public isDiffApp: boolean;
   constructor(
      changeDetector: ChangeDetectorRef,
      private connectionServerModel: ConnectionServerModel,
      private viewClientModel: ViewClientModel,
      private utilService: UtilService,
      private translate: TranslateService,
      private connectionURIModel: ConnectionURIModel,
      private credCleanService: CredCleanService,
      private timezone: Timezone,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private localStorageService: LocalStorageService,
      private shareFolderModel: ShareFolderModel,
      private idleSessionService: IdleSessionService,
      private connectionRetryService: ConnectionRetryService,
      public jscdkWrapper: JscdkWrapper,
      private clientSettingModel: ClientSettingModel,
      private launchService: LaunchService,
      private ws1Service: Ws1Service,
      private eventBusService: EventBusService,
      private modalDialogService: ModalDialogService,
      private anonymousService: AnonymousService,
      private launchItemsCtrlService: LaunchItemsCtrlService,
      private appViewService: AppViewService,
      private audioService: AudioService,
      private remoteSessionEventService: RemoteSessionEventService,
      private contextMenuUtilService: ContextMenuUtilService,
      private onRampService: OnRampService,
      @Optional()
      private autoForwardPolicyService: AutoForwardPolicyService
   ) {
      super(changeDetector, "GetLaunchItems");
      if (clientUtil.isFAwithMainClientHide) {
         Logger.info("Hide main window for file-association session.");
         chrome.app.window.current().minimize();
      }

      // disable normal launching timer when Azure connect retry enabled.
      this.eventBusService.listen("UpdateAzureWaitingUI").subscribe((msg) => {
         this._clearTimer();
         if (window.location.hash.indexOf("#/desktop") !== 0) {
            this.connectionRetryService.showDialog(msg.data.waitMinutes, msg.data.type, jscdkCommonInvoker);
         }
      });
      this._sessionConnectingSubscription = this.eventBusService
         .listen(BusEvent.SessionConnectMsg.MSG_TYPE)
         .subscribe((msg: BusEvent.SessionConnectMsg) => {
            this._isSessionConnecting = msg.connecting;
         });
      this.eventBusService.dispatch(new BusEvent.LaunchInitMsg());
      this.eventBusService.listen("stopLoading").subscribe((msg) => {
         if (this.blankOutContent === true) {
            this.blankOutContent = false;
         }
      });
   }

   private scrollTop = (item) => {
      if (this.isHighlighted(item)) {
         // scroll 10px to the top.
         setTimeout(() => {
            $("html, body").animate(
               {
                  scrollTop: $(document.getElementById(item.id)).offset().top - 10
               },
               150
            );
         });
      }
   };

   public toggleContextMenu(horizonApp, isDesktop, event): void {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      this.isDiffApp = this.contextMenuUtilService.isDiffApp(horizonApp);
      horizonApp.showContextMenu = !horizonApp.showContextMenu;

      if (this.isDiffApp && this.contextMenuUtilService.existingAppWithContextMenu) {
         this.contextMenuUtilService.existingAppWithContextMenu.showContextMenu = false;
      }
      this.contextMenuUtilService.updateExistingContextAppId(horizonApp.id);
      this.contextMenuUtilService.updateExistingContext(horizonApp);
   }

   public onOnlyFavorites = (onlyFavorites: boolean) => {
      this.showOnlyFavorites = onlyFavorites;
   };
   public onSearchChanged = (searchText: string) => {
      this.search = searchText;
   };

   /**
    * avoid redenering again if setting dialog is being opened.
    * There should be a legacy race condition bug for years, not fix
    * since manual operation should never trigger it.
    */
   public onSettingDialog = (isOpened: boolean) => {
      this._skipRendering = isOpened;
      setTimeout(() => {
         this.blankOutContent = false;
         this.isUrlLaunching = false;
      });
   };

   // Store user info in the local storage.
   private _storeUserInfo = () => {
      let tokenUsername, username, domain, horizonId;

      // Current token user name
      tokenUsername = this.connectionServerModel.tokenUsername;
      if (tokenUsername) {
         this.localStorageService.set(CST.COOKIE.TOKEN_USER_NAME, tokenUsername);
      }

      //Current user name
      username = this.connectionServerModel.username;
      if (!username) {
         username = this.localStorageService.get(CST.COOKIE.USER_NAME);
         if (!username) {
            // If username is not available, use token username
            // instead.
            username = tokenUsername;
         }
         // Cache user name in the server model.
         this.connectionServerModel.username = username;
      } else {
         this.localStorageService.set(CST.COOKIE.USER_NAME, username);
      }

      //Current domain name
      domain = this.connectionServerModel.domain;
      if (!domain) {
         domain = this.localStorageService.get(CST.COOKIE.DOMAIN_NAME);
         this.connectionServerModel.domain = domain;
      } else {
         this.localStorageService.set(CST.COOKIE.DOMAIN_NAME, domain);
      }

      // HWS session identifier
      horizonId = this.connectionServerModel.horizonId;
      if (horizonId) {
         this.localStorageService.set(CST.COOKIE.HORIZON_ID, horizonId);
      } else {
         this.localStorageService.remove(CST.COOKIE.HORIZON_ID);
      }
   };

   // Initialize LaunchItemsCtrl controller.
   public renderData = (isRefreshing) => {
      if (clientUtil.isChromeClient() && window.chromeClient && this.ws1Service.isWS1Mode() && !window.isKioskSession) {
         Logger.info("hide main window under ws1 & non-kiosk mode");
         chrome.app.window.current().hide();
         window.chromeClient.isClientHidden = true;
      }
      // To fix bug 2700241, #preSettingsBtn jquery tooltip does not disappear automatically
      // in Firefox and IE. Remove it in launcher page.
      if ($(".ui-tooltip-content").length > 0) {
         $(".ui-tooltip-content").parent("div").remove();
      }
      this._setup();
      // for this component, not reloading would be more consistent
      if (isRefreshing) {
         return;
      }
      if (this._skipRendering) {
         return;
      }
      if (CST.clientUtil.isChromeClient()) {
         this.shareFolderModel.clearFolderInfo();
         this.autoForwardPolicyService.getPolicy();
      }

      this.anonymousService.subscribe((mode) => {
         this.showPreference = !mode;
      });

      this.launchItemsCtrlService.setItemController(this);

      // see detail at https://confluence.eng.vmware.com/display/HADD/Workspace+one+enhancement
      this.isChromeWS1Mode = this.ws1Service.isWS1Mode() && CST.clientUtil.isChromeClient();

      const data = this.jscdkWrapper.data;

      if (!!data && !!data.timerInfo) {
         this.idleSessionService.updateParams(data.timerInfo);
      } else {
         Logger.error("launchItemsResponse have no data for timer!");
      }
      if (CST.clientUtil.isChromeClient()) {
         // In chrome client, hide the app if it's ws1 mode.
         this.showLaunchPanel = !data.content.warning;
         this.isUrlLaunching = false;
      } else {
         // In web, see detail at https://confluence.eng.vmware.com/display/HADD/Workspace+one+enhancement
         this.showLaunchPanel = !this.ws1Service.isWS1Mode();
         this.isUrlLaunching = this.connectionURIModel.isUrlLaunching();
         if (this.isUrlLaunching) {
            Logger.info("set isUrlLaunching for launcher page");
         }
      }
      this.eventBusService.listen(BusEvent.ItemLaunchFailed.MSG_TYPE).subscribe(() => {
         this.isUrlLaunching = false;
      });
      this.remoteSessionEventService.addEventListener("sessionAdded", () => {
         this.isUrlLaunching = false;
      });
      // Event Listener for clicking OK btn on warning message dialog
      this.eventBusService.listen("warningMessageDismissed").subscribe((msg) => {
         if (clientUtil.isChromeClient()) {
            this.showLaunchPanel = true;
            this.headerOptions.hide = !this.showLaunchPanel;
         }
      });
      if (this.isChromeWS1Mode) {
         Logger.debug("Set reset saml listener for ChromeWS1Mode.");
         this.launchItemsCtrlService.registerResetSamlListener();
      }
      /**
       * Initialize view. Show header and show strip.
       * Hide the header if in WS1 mode
       */
      //$scope.initView(!this.showLaunchPanel);
      // Show logout button in none-HWS env.
      let showLogout = false;
      if (this.ws1Service.isWS1Mode() && !CST.clientUtil.isChromeClient()) {
         showLogout = false;
      } else {
         showLogout = true;
      }

      this.headerOptions = {
         logout: showLogout,
         searchBar: true,
         hide: !this.showLaunchPanel
      };

      this.showOnlyFavorites = false;
      // Set current controller.
      this.jscdkWrapper.currentController = this;

      // Render launchItems.
      this.launchItemsCtrlService.renderLaunchItems(this.jscdkWrapper.data);
      this.desktops = this.launchItemsCtrlService.getDesktops();
      this.applications = this.launchItemsCtrlService.getApplications();
      // Store user info.
      this._storeUserInfo();
      // Listen to up/down arrow keyup event.
      $(document).on("keydown", this.moveFocus);

      this.eventBusService.dispatch(new BusEvent.SearchFocusMsg());

      this.credCleanService.init();

      /**
       * If user enters launch items page, and there is no user switch dialog,
       * we can say they login broker session successfully.
       *
       * And at this moment we should add a flag to mark the session already
       * logged in. See app-center.component.ts for the log off part
       *
       * NOTE: don't put this code before
       * this.launchItemsCtrlService.renderLaunchItems.
       *
       * Because it should handle URI first
       * And if there is a switch user dialog, user might cancel the dialog and
       * decide not to switch session
       */
      if (!this.modalDialogService.isDialogOpen(this.switchUserDialog)) {
         /**
          * Don't use brokerSessionStorageService here because it may use
          * COOKIE to simulate localStorage
          */
         if (this.utilService.supportLocalStorage()) {
            window.localStorage.setItem(CST.CLIENT.CLIENT_LOGIN_KEY, "true");
         }
         this.connectionURIModel.checkAnonymous();
      }
      if (!clientUtil.isChromeClient()) {
         if (isOnRamp()) {
            this.isRampEnabled = true;
         }
      }
   };

   public launchDesktop = (desktop, event?) => {
      if (CST.clientUtil.isSeamlessMode()) {
         if (this.launchService.isDesktopLaunched(desktop.id)) {
            /**
             * Add triggerItemInfo for all launch, it's for sdk
             * for bug 3161107 VCART-483
             * targetActionInfo.desktopId is the name showed in launch panel
             */
            const triggerItemInfo = {
               type: "HorizonDesktop",
               id: desktop.id,
               triggerName: desktop.name
            };
            this.eventBusService.dispatch(
               new BusEvent.ItemLaunchFailed({
                  itemType: "HorizonDesktop",
                  launchItemId: desktop.id,
                  errorMessage: "Desktop is already launched.",
                  clientData: {
                     triggerItemInfo: triggerItemInfo
                  }
               })
            );
            this.handleRedirectUrl(desktop.id, true);
            return;
         }
      }

      if (this.blankOutContent) {
         Logger.warning("skip launching an desktop since current controller is busy");
         return;
      }

      this.eventBusService.dispatch(new BusEvent.WindowReplacementPermission());

      this.highlightItems(desktop, event);
      this._isLaunchingItem = true;
      this.audioService.resumeAudio();
      this.currentDesktop = desktop;
      this.viewClientModel.windowsTimezone = this.timezone.getWindowsTimezone();
      this.launchItemsCtrlService.launchDesktop(desktop);
   };

   private _logoffDesktop = (desktop) => {
      if (this.blankOutContent) {
         return;
      }
      this.launchItemsCtrlService.logoffDesktop(desktop);
   };

   private _resetDesktop = (desktop) => {
      if (!desktop.resetEnabled || this.blankOutContent) {
         return;
      }
      this.launchItemsCtrlService.resetDesktop(desktop);
   };

   private _restartDesktop = (desktop) => {
      if (!desktop.restartEnabled || this.blankOutContent) {
         return;
      }
      this.launchItemsCtrlService.restartDesktop(desktop);
   };

   public launchApplication = (application, event?, args?) => {
      if (this.blankOutContent) {
         Logger.warning("skip launching an app since current controller is busy");
         return;
      }
      if (event) {
         clientUtil.updateFAStatus(false);
      }
      this.highlightItems(application, event);
      this._isLaunchingItem = true;
      this.audioService.resumeAudio();
      this.launchItemsCtrlService.launchApplication(application, args);
   };

   private _launchApplicationSession = (applicationSession) => {
      if (this.blankOutContent) {
         Logger.warning("skip launching an app session since current controller is busy");
         return;
      }
      this._isLaunchingItem = true;
      this.audioService.resumeAudio();
      this.launchItemsCtrlService.launchApplicationSession(applicationSession);
   };
   public highlightItems = (item, event?) => {
      this.launchItemsCtrlService.highlightItems(item, event, this);
   };

   public isHighlighted = (item) => {
      return item === this.activeItem;
   };

   public makeFavorite = (item, event) => {
      if (
         !!event &&
         event.keyCode !== undefined &&
         event.keyCode !== null &&
         event.keyCode !== 13 &&
         event.keyCode !== 32
      ) {
         event.stopPropagation();
         return;
      }
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      item.favorite = !item.favorite;
      const action = item.favorite ? "FavOn" : "FavOff";
      this.clientSettingModel.updateSetting(action, item.id);
      this.clientSettingModel.saveSetting();
      if (item.favorite === true) {
         const favOnId = item.id + "Favon";
         setTimeout(() => {
            document.getElementById(favOnId).focus();
         }, 500);
      } else {
         const favOffId = item.id + "Favoff";
         setTimeout(() => {
            document.getElementById(favOffId).focus();
         }, 500);
      }
   };

   public showNoFavorites = (desktops, applications) => {
      let noDesktopFav = true,
         noAppFavorites = true,
         i;

      for (i = 0; i < desktops.length; i++) {
         if (desktops[i].favorite) {
            noDesktopFav = false;
            break;
         }
      }
      for (i = 0; i < applications.length; i++) {
         if (applications[i].favorite) {
            noAppFavorites = false;
            break;
         }
      }
      if ((noDesktopFav || noAppFavorites) && this.showOnlyFavorites) {
         this.showLine = false;
      } else {
         this.showLine = true;
      }

      return noDesktopFav && noAppFavorites && this.showOnlyFavorites;
   };

   public keyboardHandler = (evt, item, isDesktop) => {
      let callback, callbackParams;

      if (!evt) {
         return;
      }

      switch (evt.keyCode) {
         case 13: // Enter key
            callback = isDesktop ? this.launchDesktop : this.launchApplication;
            callbackParams = [item, evt.target];
            break;
         case 32: // Space key
            callback = this.makeFavorite;
            callbackParams = [item, evt];
            break;
         default:
      }

      if (typeof callback === "function") {
         // Invoke keyboard handler.
         callback.apply(this, callbackParams);
         // Prevent default behavior.
         evt.preventDefault();
      }
   };

   public moveFocusUpRow = (list, curr) => {
      let position, top, left, above, elem, i;

      if (!list || curr < 0 || curr >= list.length) {
         return -1;
      }

      // Get the top and left position of the focused element.
      position = this.utilService.position($(list[curr]));
      top = position.top;
      left = position.left;

      // Find backwards from the current position.
      for (i = curr - 1; i > -1; i--) {
         elem = $(list[i]);

         if (this.utilService.isHidden(elem)) {
            continue;
         }

         position = this.utilService.position(elem);

         if (!above && position.top !== top) {
            // Top position of the above row.
            above = position.top;
         }

         if (position.top === above && position.left <= left) {
            /*
             * Element is on the above row and
             * it is left aligned with focused element
             * or is the last element of the row.
             */
            return i;
         }
      }

      return -1;
   };

   public moveFocusDownRow = (list, curr) => {
      let i,
         position,
         top,
         left,
         bottom,
         elem,
         next = -1;

      if (!list || curr < 0 || curr >= list.length) {
         return -1;
      }

      // Get the top and left position of the focused element.
      position = this.utilService.position($(list[curr]));
      top = position.top;
      left = position.left;

      // Down arrow key is pressed.
      for (i = curr + 1; i < list.length; i++) {
         elem = $(list[i]);

         if (this.utilService.isHidden(elem)) {
            continue;
         }

         position = this.utilService.position(elem);

         if (!bottom && position.top !== top) {
            // Top position of the bottom row.
            bottom = position.top;
         }

         if (!!bottom && (position.top !== bottom || position.left > left)) {
            // Return when the bottom row is scanned or the left align
            // element is found.
            break;
         }

         if (position.top === bottom && position.left <= left) {
            /*
             * Element is on the bottom row and
             * it is left aligned with the focused element
             * or is the last element of the row.
             */
            next = i;
         }
      }

      return next;
   };

   public moveFocusLeft = (list, curr) => {
      let next = -1,
         elem,
         i;

      if (!list || curr < 0 || curr >= list.length) {
         return -1;
      }

      for (i = curr - 1; i > -1; i--) {
         elem = $(list[i]);

         if (this.utilService.isHidden(elem)) {
            continue;
         }

         // Previous element.
         next = i;
         break;
      }

      return next;
   };

   public moveFocusRight = (list, curr) => {
      let next = -1,
         elem,
         i;

      if (!list || curr < 0 || curr >= list.length) {
         return -1;
      }

      for (i = curr + 1; i < list.length; i++) {
         elem = $(list[i]);

         if (this.utilService.isHidden(elem)) {
            continue;
         }

         // Next element.
         next = i;
         break;
      }

      return next;
   };

   public moveFocus = (evt) => {
      let list, index, next, elem, i;

      if (this.modalDialogService.hasDialogOpen() || !evt || evt.keyCode < 37 || evt.keyCode > 40) {
         /**
          * Return if a dialog is popped up, or
          * key other than up/down/left/right arrow key is pressed.
          */
         return;
      }

      // Get desktop and application elements list.
      list = $("ul.ui-desktop-list li");

      // Find out the index of focused element in the list.
      index = Array.prototype.indexOf.call(list, this.focusElement);

      // If no element is focused, focus on the first one when down
      // arrow key is pressed.
      if (index === -1) {
         // Down arrow key is pressed.
         if (evt.keyCode === 40) {
            // Focus on the first visible element if down arrow key is
            // pressed.
            for (i = 0; i < list.length; i++) {
               elem = $(list[i]);

               if (this.utilService.isHidden(elem)) {
                  continue;
               }

               elem.focus();
               break;
            }

            // Prevent default behavior.
            evt.preventDefault();
         }

         return;
      }

      switch (evt.keyCode) {
         case 38:
            // Up arrow key is pressed.
            next = this.moveFocusUpRow(list, index);
            break;

         case 40:
            // Down arrow key is pressed.
            next = this.moveFocusDownRow(list, index);
            break;

         case 37:
            // Left arrow key is pressed.
            next = this.moveFocusLeft(list, index);
            break;

         case 39:
            // Right arrow key is pressed.
            next = this.moveFocusRight(list, index);
            break;

         default:
      }

      if (next > -1) {
         // Set focus
         $(list[next]).focus();
         // Prevent default behavior.
         evt.preventDefault();
      } else if (evt.keyCode === 38) {
         this.eventBusService.dispatch(new BusEvent.SearchFocusMsg());
         // Prevent default behavior.
         evt.preventDefault();
      }
   };

   public removeHighlight = () => {
      this.activeItem = null;
      this.focusElement = null;
   };

   // Clear timeout timer.
   private _clearTimer = () => {
      if (this._timeoutID !== null) {
         // Clear timer.
         Logger.debug("launch timer stopped for XML response reached");
         clearTimeout(this._timeoutID);
         this._timeoutID = null;
      }
   };

   /**
    * add a boolean flag for function onBlastWindowLaunched as a workaround for bug 2771035
    * to keep launcher page's loading status for launching the powered off desktop
    */
   public onBlastWindowLaunched = async (
      removeBusyCursor: boolean,
      pendingConnectionPromise: Promise<boolean> = null
   ) => {
      // Clear timeout timer.
      this._clearTimer();
      if (this._launchTimeoutDialog) {
         this.modalDialogService.close(this._launchTimeoutDialog);
         this._launchTimeoutDialog = null;
      }
      if (this._sessionConnectingSubscription) {
         this._sessionConnectingSubscription.unsubscribe();
         this._sessionConnectingSubscription = null;
      }
      if (removeBusyCursor) {
         this.removeBusyCursor();
      }
      if (pendingConnectionPromise) {
         try {
            const result = await pendingConnectionPromise;
            Logger.info("connectionPromise resolved " + (result ? "with wait" : "without wait"), Logger.SDK);
         } catch (e) {
            Logger.exception(e);
            Logger.warning("connectionPromise rejected", Logger.SDK);
         }
      }
      setTimeout(() => {
         this.eventBusService.dispatch(new BusEvent.StopBlockLaunchingFromSDK("ready to launch session"));
      });
   };

   // Override teardown method
   public tearDown = (name?: string) => {
      // Remove event listener.
      if (name === "Destroy" || name === "Logout" || name === "Brokers" || name === "ShowError") {
         Logger.info("jump related response received");
         $(document).off("keydown", this.moveFocus);
         this.onBlastWindowLaunched(false);
      } else {
         Logger.info("skip the tearDown for launch-items:" + name);
      }
   };

   private _setup = () => {
      // Listen to up/down arrow keyup event.
      $(document).on("keydown", this.moveFocus);
   };

   // Process URI action for application.
   public processURIAction4App = (application, action, args, isFA?) => {
      Logger.trace("enter processURIAction4App");
      if (CST.ignoreCaseEquals(action, "start-session") || !action) {
         // We need to fill in the mainService.currentController with
         // self or otherwise launching an application will fail if
         // we need to steal a session.
         this.jscdkWrapper.currentController = this;
         this.bufferedArgs = args;
         clientUtil.updateFAStatus(isFA);
         // Launch application.
         this.launchApplication(application, null, args);
      } else if (CST.ignoreCaseEquals(action, "browse")) {
         // Highlight the application.
         if (!this.ws1Service.isWS1Mode()) {
            this.highlightItems(application);
            this.scrollTop(application);
         } else {
            // Launch application.
            this.launchApplication(application, null, args);
         }
      }
   };

   // Process URI action for desktop.
   public processURIAction4Desktop = (desktop, action) => {
      Logger.trace("enter processURIAction4Desktop");
      if (CST.ignoreCaseEquals(action, "logoff")) {
         if (desktop.logoffEnabled) {
            // Logoff desktop.
            this._logoffDesktop(desktop);
         } else {
            // Failed to logoff the desktop.
            this.highlightItems(desktop);
            // Show error dialog.
            this.modalDialogService.showError({
               data: {
                  titleKey: "ERROR",
                  contentKey: "error_logoff_no_session",
                  contentSubstitutionsKey: desktop.name
               }
            });
         }
      } else if (CST.ignoreCaseEquals(action, "reset")) {
         if (desktop.resetEnabled) {
            // Reset desktop.
            this._resetDesktop(desktop);
         } else {
            // Failed to reset the desktop.
            this.highlightItems(desktop);
            this.modalDialogService.showError({
               data: {
                  titleKey: "ERROR",
                  contentKey: "error_reset_not_allowed",
                  contentSubstitutionsKey: desktop.name
               }
            });
         }
      } else if (CST.ignoreCaseEquals(action, "restart")) {
         if (desktop.restartEnabled) {
            // Restart desktop.
            this._restartDesktop(desktop);
         } else {
            // Failed to restart the desktop.
            this.highlightItems(desktop);
            this.modalDialogService.showError({
               data: {
                  titleKey: "ERROR",
                  contentKey: "error_restart_not_allowed",
                  contentSubstitutionsKey: desktop.name
               }
            });
         }
      } else if (CST.ignoreCaseEquals(action, "browse")) {
         // Highlight desktop.
         if (!this.ws1Service.isWS1Mode()) {
            this.highlightItems(desktop);
            this.scrollTop(desktop);
         } else {
            // Launch desktop.
            this.launchDesktop(desktop);
         }
      } else if (CST.ignoreCaseEquals(action, "start-session") || !action) {
         // Launch desktop.
         this.launchDesktop(desktop);
      }
   };

   // Process URI action for application session
   public processURIAction4AppSession = (applicationSession, action) => {
      Logger.trace("enter processURIAction4AppSession");
      if (CST.ignoreCaseEquals(action, "start-session") || !action) {
         // We only support start remote session for now
         this.jscdkWrapper.currentController = this;
         // Launch application Session.
         this._launchApplicationSession(applicationSession);
      }
   };

   public processLaunchItem = (item) => {
      if (item.isApplication) {
         this.launchApplication(item);
      } else {
         this.launchDesktop(item);
      }
   };

   private removeBusyCursor = () => {
      setTimeout(() => {
         this.blankOutContent = false;
      });
   };

   public connecting = () => {
      // skip show busy icon when WS1 mode enabled for Chrome Client
      if (this.isChromeWS1Mode) {
         this.removeBusyCursor();
         this.changeBusyCursor = (isBusy: boolean) => {
            this.removeBusyCursor();
            Logger.debug("always hide loading bar for Chrome Client in ws1 mode");
         };
      } else {
         super.connecting();
      }
      this._clearTimer();

      // Is launching a desktop or an application.
      if (this._isLaunchingItem) {
         this._isLaunchingItem = false;
         this._isSessionConnecting = true;
         Logger.debug("send XML for launching");
         /**
          * When 30 seconds expire, display a dialog
          * asking user whether to cancel the request.
          */
         this._timeoutID = setTimeout(() => {
            Logger.debug("already wait launch response for more than 30s");
            this.removeBusyCursor();
            this.isUrlLaunching = false;

            this._timeoutID = null;
            this._launchTimeoutDialog = this.modalDialogService.showConfirm({
               data: {
                  titleKey: "dialog_title_cancel_startsession",
                  contentKey: "dialog_confirm_cancel_startsession"
               },
               callbacks: {
                  confirm: () => {
                     this._launchTimeoutDialog = null;
                     // Press OK btn, display busy cursor.
                     super.connecting();
                  },
                  cancel: () => {
                     this._launchTimeoutDialog = null;
                     // To fix bug 2091563, if launch successfully, cancel can't click
                     if (this.appViewService.getCurrentHash().indexOf("/desktop") !== 0) {
                        let onDone = null;
                        const SAMLart = util.getUrlParam(window.location.search.toLowerCase(), "samlart");
                        if (SAMLart) {
                           onDone = {
                              name: "closeOnExit"
                           };
                        }
                        // Press cancel btn, cancel launching item request.
                        this.jscdkCommonInvoker.cancelCurrentRequest(onDone);
                        this.removeBusyCursor();
                        this.isUrlLaunching = false;
                     }
                  }
               }
            });
         }, 30 * 1000);
      }
   };

   public enable = () => {
      // Invoke parent's enable method.
      if (!this._isSessionConnecting) {
         //enable function is to dismiss the loading page
         //super.enable();
         setTimeout(() => {
            this.blankOutContent = false;
            this.isUrlLaunching = false;
         });
      }
      // Clear timer.
      this._clearTimer();
   };

   /**
    * Handle redirect URL in globalArray
    * @param itemId desktopId/appId
    * @param clearGlobalArray clear browseURL in globalArray after getting
    */
   private handleRedirectUrl(itemId, clearGlobalArray: boolean = false) {
      if (globalArray["browseURL"]) {
         const { id: sessionKey, url } = globalArray["browseURL"];

         if (sessionKey === itemId) {
            if (url) {
               this.eventBusService.dispatch(new BusEvent.RedirectUrlToAgentMsg({ sessionKey, url }));
            }

            if (clearGlobalArray) {
               globalArray["browseURL"] = null;
            }
         }
      }
   }

   public launchTitanInNewTab = () => {
      this.onRampService.getOnRampConfig();
   };
}
