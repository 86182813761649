/**
 * ******************************************************
 * Copyright (C) 2016-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * extended-monitor.service.ts -- extendedMonitorService
 *
 * provide data
 * extendedMonitorService
 */

import { Injectable } from "@angular/core";
import { TranslateService } from "@html-core";
import Logger from "../../../../core/libs/logger";
import { Monitor } from "../common/monitor-message";
@Injectable({
   providedIn: "root"
})
export class ExtendedMonitorService {
   private _blur = false;
   private _currentWindow = null;

   public statusMap = {
      opened: 0,
      inited: 1,
      readyToDisplay: 2,
      working: 3,
      closed: -1,
      confirmedQuit: -2
   };
   public wmksSession = null;
   public translationMap = null;

   constructor(private translate: TranslateService) {
      this.translationMap = this.getTranslateMap();
      // chromeclient && in primary screen
      if (window.location.href.indexOf("app-window.html") >= 0) {
         this._currentWindow = chrome.app.window.current();
      }
   }

   private getTranslateMap = () => {
      const keys = [
            "MM_WAIT_OTHER_MONITOR_READY",
            "MM_HINT_INIT",
            "MM_QUIT_MULTIMON_T",
            "MM_QUIT_MULTIMON_M",
            "OK",
            "CANCEL",
            "MM_EXTENDED_MONITOR",
            "LOADING",
            "FULLSCREEN",
            "dialog_content_multimon"
         ],
         translationMap = {};

      //user promise if translationService allow.
      for (let i = 0; i < keys.length; i++) {
         translationMap[keys[i]] = this.translate._T(keys[i]);
      }
      return translationMap;
   };

   public onMouseButton = (msg: Monitor.MouseButtonMsg) => {
      this._focus();
      this.wmksSession.wmksContainer.wmks("sendMouseButtonMessage", msg.position, msg.isDownEvent, msg.bmask);
      $(document).trigger("extendedmonitoruse");
   };

   public onMouseMove = (msg: Monitor.MouseMove) => {
      this._focus();
      if (msg.position) {
         this.wmksSession.wmksContainer.wmks("sendMouseMoveMessage", msg.position);
         $(document).trigger("extendedmonitoruse");
      }
   };

   public onTouchEvent = (msg: Monitor.TouchScreenMsg) => {
      this._focus();
      this.wmksSession.wmksContainer.wmks("onTouch", msg.event, msg.positions);
      $(document).trigger("extendedmonitoruse");
   };

   public onMouseWheel = (msg: Monitor.MouseWheelMsg) => {
      this._focus();
      this.wmksSession.wmksContainer.wmks("sendScrollMessageWithEvent", msg.event, msg.position);
      $(document).trigger("extendedmonitoruse");
   };

   public onKeyEvent = (msg: Monitor.KeyEvent) => {
      this._focus();
      this.wmksSession.wmksContainer.wmks("sendKeyMessage", {
         type: msg.type,
         event: msg.event
      });
      $(document).trigger("extendedmonitoruse");
   };

   private _focus = () => {
      if (this._blur && this._currentWindow) {
         this._currentWindow.focus();
         this._blur = false;
      }
   };

   public onBlur = () => {
      this._blur = true;
      Logger.debug("focus leave extended monitor", Logger.DISPLAY);
      this.wmksSession.wmksContainer.wmks("onBlur");
      $(document).trigger("extendedmonitoruse");
   };
}
