/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Logger } from "../../../core/libs";
import { BusEvent, EventBusService } from "../../../core/services/event";
import { SettingItem } from "../../../shared/common/model/client-setting-model";
import { iChromeLaunchSetting, XMLPreference } from "../../../shared/common/service/prefdata";
import { PreferenceInfo, UserGlobalPref } from "../../../shared/common/service/user-global-pref";

type SyncBackFunction = (settings: Array<SettingItem>) => void;

@Injectable({
   providedIn: "root"
})
export class ChromeShadowGlobalPref extends UserGlobalPref {
   private syncBackFunction: SyncBackFunction = null;
   private importedSetting: iChromeLaunchSetting = {} as iChromeLaunchSetting;

   constructor(private eventBusService: EventBusService) {
      super();
      this.init();
   }

   public init = () => {
      this.eventBusService
         .listen(BusEvent.SyncSessionSettingMsg.MSG_TYPE)
         .subscribe((msg: BusEvent.SyncSessionSettingMsg) => {
            this.syncPrefData(msg);
         });
   };

   public syncPrefData = (msg: BusEvent.SyncSessionSettingMsg) => {
      if (!!msg.items && msg.items.length > 0) {
         const changedSettingsItems: Array<SettingItem> = msg.items
            .filter((keyName: string) => {
               return this.importedSetting.hasOwnProperty(keyName);
            })
            .map((keyName: string) => {
               return <SettingItem>{
                  key: keyName,
                  value: this.importedSetting[keyName]
               };
            });
         if (typeof this.syncBackFunction === "function") {
            Logger.debug("saving back setting to main window:" + JSON.stringify(changedSettingsItems));
            this.syncBackFunction(changedSettingsItems);
         } else {
            Logger.error("syncBackFunction not set");
         }
      }
   };

   // import by JSON, used with object generated by getLaunchSettingJSON
   public importSettings = (settingsObject: iChromeLaunchSetting) => {
      Logger.error("importing settings" + JSON.stringify(settingsObject));
      this.importedSetting = settingsObject;
   };

   public setSyncBackFunction = (callback: SyncBackFunction) => {
      if (this.syncBackFunction) {
         Logger.warning("dup set of syncBackFunction, previous callback overwritten");
      }
      this.syncBackFunction = callback;
   };

   public clearPrefData() {
      throw Error("ChromeShadowGlobalPref doesn't support this action");
   }
   public toFlatFormat(changed?: boolean) {
      throw Error("ChromeShadowGlobalPref doesn't support this action");
   }
   public setPrefData(data: XMLPreference) {
      throw Error("ChromeShadowGlobalPref doesn't support this action");
   }
   public getPrefData(): XMLPreference {
      throw Error("ChromeShadowGlobalPref doesn't support this action");
   }
   public onDataSent() {
      throw Error("ChromeShadowGlobalPref doesn't support this action");
   }
   public getPrefStringItem(key: string): string {
      if (this.importedSetting.hasOwnProperty(key)) {
         return this.importedSetting[key];
      }
      Logger.error("missing setting for " + key);
      return "";
   }
   public getPrefBooleanItem(key: string): boolean {
      if (this.importedSetting.hasOwnProperty(key)) {
         return this.importedSetting[key] === true || this.importedSetting[key] === "true";
      }
      return false;
   }
   public getPrefNumberItem(key: string): number {
      let value: number = null;
      if (this.importedSetting.hasOwnProperty(key)) {
         value = Number(this.importedSetting[key]);
      } else {
         Logger.error("missing setting for " + key);
      }
      return value;
   }
   public updatePrefData(data: PreferenceInfo, newKey: boolean = false) {
      if (!this.importedSetting.hasOwnProperty(data.action)) {
         if (newKey) {
            this.importedSetting[data.action] = data.text;
            Logger.info("allow adding new setting key for " + data.action);
            return;
         }
         Logger.error("invalid setting key" + data.action);
      }
      this.importedSetting[data.action] = data.text;
   }
   public getAdminSettings() {
      throw Error("ChromeShadowGlobalPref doesn't support this action");
   }
}
