/**
 * ******************************************************
 * Copyright (C) 2018 - 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * session-util.js --
 * util for session related functions
 *
 * Mainly for feature:
 * https://confluence.eng.vmware.com/display/HorizonArchitecture/
 * Allow+single+user+to+launch+multiple+instances+of+same+RDSH+App+on+multiple+clients
 */

import Logger from "../../../core/libs/logger";
import { MultiSessionSingleUserService } from "./multisession-singleuser.service";
import { SingleSessionAppSessionService } from "./single-session.service";
import { ViewClientModel } from "../model/viewclient-model";
import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class SessionUtil {
   public static readonly multiSessionPrefix = "Multi-session-";
   private isFA: boolean = false;
   constructor(
      private multiSessionSingleUserService: MultiSessionSingleUserService,
      private singleSessionAppSessionService: SingleSessionAppSessionService,
      private viewClientModel: ViewClientModel
   ) {}

   /**
    * Wrapper for multiSessionSingleUserService.getAppStatus.
    */
   public isMultiSession = async (appId: string): Promise<boolean> => {
      const isMultiSessionMode: boolean = await this.multiSessionSingleUserService.getAppStatus(appId);
      return isMultiSessionMode;
   };

   /**
    * Currently use appId and sessionId instead of originId, since GE has been enabled.
    * For single session still uses originId for now to reduce change size.
    *
    * In order for the class injected with SessionUtil to also call the getSessionKey method, it is set to static.
    */
   public static getSessionKey = (isMultiSession: boolean, originId: string, sessionId: string): string => {
      if (isMultiSession) {
         if (!sessionId) {
            Logger.error(
               "sessionId is missing for a multi-session app session, please use latest Broker + Agent build or GA build for testing"
            );
            return SessionUtil.multiSessionPrefix + originId;
         }
         return SessionUtil.multiSessionPrefix + sessionId;
      }
      if (!sessionId) {
         Logger.info("sessionId is missing, use originId as key: " + originId);
         return originId;
      }
      return sessionId;
   };

   public getIdsFromSessionKey = (key: string) => {
      const defaultResult = {
         sessionId: undefined,
         originId: key,
         isMultiSession: false
      };
      const temp: Array<string> = key.split(SessionUtil.multiSessionPrefix);
      if (temp.length === 1) {
         return defaultResult;
      }
      if (temp.length !== 2) {
         Logger.error("bad session Key format: " + key);
         return defaultResult;
      }
      return {
         originId: undefined,
         sessionId: temp[1],
         isMultiSession: true
      };
   };

   public updateReconnectToken = (sessionKey, reconnectToken) => {
      const sessionIdInfo = this.getIdsFromSessionKey(sessionKey);
      if (sessionIdInfo.isMultiSession && !this.viewClientModel.isAnonymousMode) {
         this.multiSessionSingleUserService.updateReconnectToken(sessionIdInfo.sessionId, reconnectToken);
      } else {
         this.singleSessionAppSessionService.updateReconnectToken(
            sessionIdInfo.originId,
            sessionIdInfo.sessionId,
            reconnectToken
         );
      }
   };

   public onSessionConnected = (isMultiSession: boolean, key: string, wmksSession) => {
      if (isMultiSession && !this.viewClientModel.isAnonymousMode) {
         this.multiSessionSingleUserService.onConnectMultiSession({
            keyInfo: this.getIdsFromSessionKey(key),
            wmksSession: wmksSession
         });
      } else {
         this.singleSessionAppSessionService.onConnectSingleSession({
            keyInfo: this.getIdsFromSessionKey(key),
            wmksSession: wmksSession
         });
      }
   };

   public onSessionRemoved = (isMultiSession: boolean, key: string) => {
      const keyInfo = this.getIdsFromSessionKey(key);
      if (isMultiSession === undefined) {
         isMultiSession = keyInfo.isMultiSession;
      }
      if (isMultiSession && !this.viewClientModel.isAnonymousMode) {
         this.multiSessionSingleUserService.onSessionRemoved({
            keyInfo: keyInfo
         });
      } else {
         this.singleSessionAppSessionService.onSessionRemoved({
            keyInfo: keyInfo
         });
      }
   };

   public onSessionRemovedForTimeout = (isMultiSession: boolean, sessionKey: string) => {
      if (isMultiSession && !this.viewClientModel.isAnonymousMode) {
         this.multiSessionSingleUserService.onSessionRemovedForTimeout(sessionKey);
      } else {
         this.singleSessionAppSessionService.onSessionRemovedForTimeout(sessionKey);
      }
   };

   /**
    * Cache launching data into client for HTML Access, Since the client might
    * be reloaded and reuses the broker session.
    */
   public cacheLaunchData = async (launchData) => {
      if (!launchData || !launchData.id || !launchData.originId) {
         Logger.error("invalid ids to cache the launch data");
         return;
      }
      const isMultiSession = await this.isMultiSession(launchData.id);
      if (isMultiSession && !this.viewClientModel.isAnonymousMode) {
         Logger.info("caching session info of reconnect Multi-session app session");
         this.multiSessionSingleUserService.cacheMultiSessionLaunchData(launchData);
      } else {
         Logger.info("caching session info of reconnect Single-session app session");
         this.singleSessionAppSessionService.cacheSingleSessionLaunchData(launchData);
      }
   };

   // webpack has bug, so use Promise instead of async await
   public fetchLaunchData = (appId: string, originId: string, sessionId: string) => {
      return new Promise((resolve, reject) => {
         if (!appId || !originId) {
            Logger.error("invalid ids to fetch the launch data");
            reject();
            return;
         }
         this.isMultiSession(appId)
            .then((isMultiSession) => {
               let cachedData;
               if (isMultiSession && !this.viewClientModel.isAnonymousMode) {
                  cachedData = this.multiSessionSingleUserService.fetchMultiSessionLaunchData(sessionId);
               } else {
                  cachedData = this.singleSessionAppSessionService.fetchSingleSessionLaunchData(originId, sessionId);
               }
               if (!cachedData) {
                  throw "Cached launch data not find";
               }
               resolve(cachedData);
            })
            .catch(reject);
      });
   };

   /**
    * Change the original id checking to sessionKey checking
    * webpack has bug, so use Promise instead of async await
    */
   public sessionConnected = (appId: string, originId: string, sessionId: string): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         if (!appId || !originId) {
            Logger.error("invalid ids to check session connection status");
            resolve(false);
            return;
         }
         this.isMultiSession(appId).then((isMultiSession) => {
            let isSessionConnected;
            if (isMultiSession && !this.viewClientModel.isAnonymousMode) {
               isSessionConnected = this.multiSessionSingleUserService.hasConnectedSession(sessionId);
            } else {
               isSessionConnected = this.singleSessionAppSessionService.hasConnectedSession(originId, sessionId);
            }
            resolve(isSessionConnected);
         });
      });
   };

   /**
    * When user reset apps or logout brokers, all session should be cleared.
    */
   public clearAll = () => {
      Logger.info("clear all app session data in the client");
      this.multiSessionSingleUserService.clearAll();
      this.singleSessionAppSessionService.clearAll();
   };
}
