/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * 1.improved behavior:
 * 1.1 simplified workflow for entering multimon
 * 1.3 multimon setting would be honered whenever switching session, or launching a new session
 *
 *
 * 2.fixable limitations which would not be done in Q2:
 * 2.1 if one desktop session get disconnected, the rest of desktop session would be displayed in single monitor session
 * 2.2 switch session could avoid extra user action
 *
 * 3. reasonable limitation
 * 3.1 app can't be used for multimon mode, since we would implement seamless window soon
 * 3.2
 */

import Logger from "../../../core/libs/logger";
import WMKS from "WMKS";
import * as CST from "@html-core";
import { Injectable, Inject, Optional } from "@angular/core";
import { DisplayCheckService } from "./display-check.service";
import { RemoteSessionEventService, SessionMsg } from "../../common/remote-session/remote-session-event.service";
import { FullscreenService } from "../../utils/fullscreen-service";
import { ClientSettingModel } from "../../common/model/client-setting-model";
import { NormalizationService } from "../../utils/normalization-service";
import { NgbToastService } from "./../../common/service/ngb-toast.service";
import { BlastWmks } from "./blast-wmks.service";
import { BusEvent, TranslateService, clientUtil } from "@html-core";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { DisplayService } from "./display/display.service";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { FeatureConfigs } from "../../common/model/feature-configs";
import { DISPLAY } from "../../common/service/monitor-info.service";

@Injectable({
   providedIn: "root"
})
export class MultipleDisplayService {
   public static readonly multimonHeartbeatInterval = 1; // set to -1 as never for debugging

   private _enabled: boolean = true;
   // whether already in multimon
   private _multimonEntered = false;
   // which session is the target session of using multimon(preparing or working)
   private _applySession: BlastWmks = null;
   private _wmksService: any = null;
   private _permissionToast = null;
   private _fullscreenDialogId: string = null;

   private _hasNonAdjacentMonitors: boolean = false;
   private _hasTooLargeMonitor: boolean = false;

   private _useWindowReplacementApi: boolean;
   private _enterFullscreenIfOneMonitor: boolean;
   private _quitFullscreenBtnClicked: boolean = false;
   private _windowOpenIsBlocked: boolean = false;

   constructor(
      @Optional()
      private displayService: DisplayService,
      private remoteSessionEventService: RemoteSessionEventService,
      private fullscreenService: FullscreenService,
      private clientSettingModel: ClientSettingModel,
      private normalizationService: NormalizationService,
      private eventBusService: CST.EventBusService,
      private toastService: NgbToastService,
      private translate: TranslateService,
      private modalDialogService: ModalDialogService,
      private displayCheckService: DisplayCheckService,
      private _featureConfigs: FeatureConfigs
   ) {
      this._useWindowReplacementApi =
         this._featureConfigs.getConfig("KillSwitch-WindowReplacementApi") && !CST.clientUtil.isChromeClient();
      this._enterFullscreenIfOneMonitor = this._featureConfigs.getConfig("KillSwitch-EnterFullscreenIfOneMonitor");

      if (this._useWindowReplacementApi) {
         this.eventBusService.listen(BusEvent.WindowOpenIsBlocked.MSG_TYPE).subscribe(() => {
            if (!this._windowOpenIsBlocked) {
               this._windowOpenIsBlocked = true;
               this.eventBusService.dispatch(new BusEvent.QuitMultiMonMsg());
               this.modalDialogService.showError({
                  data: {
                     titleKey: "WARNING",
                     contentKey: "MUST_GRANT_WINDOW_OPEN"
                  }
               });
            }
         });

         this.eventBusService.listen(BusEvent.FullscreenBtnClicked.MSG_TYPE).subscribe(() => {
            this._quitFullscreenBtnClicked = false;
         });

         this.eventBusService.listen(BusEvent.QuitFullscreenBtnClicked.MSG_TYPE).subscribe(() => {
            this._quitFullscreenBtnClicked = true;
         });
      }

      this.eventBusService.listen(BusEvent.ScreenChanged.MSG_TYPE).subscribe((wmksSession) => {
         if (this.fullscreenService.isInFullscreen()) {
            this.fullscreenService.exitFullscreen();
         }
         this._doGrabExtendedMonitor(wmksSession, false);
      });

      this._init();
   }

   public isSupportedPlatform = () => {
      return (
         !CST.clientUtil.isChromeClient() &&
         this.displayService.isSupportedPlatform &&
         !WMKS.BROWSER.isMobileTouchDevice()
      );
   };
   /**
    * Client side initialed termination of using multimon
    */
   public quitMultimon = () => {
      this._wmksService.quitMultimon();
   };

   /**
    * @param  {wmksService} service Injectable Angular service wmksService
    */
   public setWmksService = (service) => {
      this._wmksService = service;
   };

   /**
    * Constructor core, should be called at end of this file to init the only instance for service.
    */
   private _init = () => {
      if (this.isSupportedPlatform()) {
         this.displayService.init(
            "./extended-monitor.html?v=" +
               __BUILD_NUMBER__ +
               "&heartbeatInterval=1" +
               MultipleDisplayService.multimonHeartbeatInterval
         );
         this.displayService.addEventListener("displayDisconnected", this.resetStatus);
      }

      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, this._onActiveSession);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTING_MSG, this._onSessionConnected);
      this.eventBusService
         .listen(CST.BusEvent.UpdateMultiMonCapacityMsg.MSG_TYPE)
         .subscribe((msg: CST.BusEvent.UpdateMultiMonCapacityMsg) => {
            this._updateMultiCapability(msg.enable, msg.webmks);
         });
   };

   private _onUpdateSession = (wmksSession: BlastWmks) => {
      setTimeout(() => {
         this._onActiveSession(wmksSession);
      }, 0);
   };

   private _sessionReadyForMultimon = (wmksSession: BlastWmks) => {
      if (!this._wmksService || !this._enabled || !wmksSession) {
         return false;
      }
      const isActiveSession = wmksSession.isActive,
         isSupportedBrowser = this.isSupportedPlatform(),
         isDesktop = !wmksSession.isApplicationSession,
         agentSupportMultimon = wmksSession.multimonServerEnabled,
         isNotShadowSession = !wmksSession.isShadow;
      Logger.debug(
         "checking session status for multimon, " +
            JSON.stringify({
               isActiveSession: isActiveSession,
               isSupportedBrowser: isSupportedBrowser,
               isDesktop: isDesktop,
               agentSupportMultimon: agentSupportMultimon,
               isNotShadowSession: isNotShadowSession
            }),
         Logger.DISPLAY
      );

      return isActiveSession && isSupportedBrowser && isDesktop && agentSupportMultimon && isNotShadowSession;
   };

   private _updateMultiCapability = (hasServerCap, wmksSession: BlastWmks) => {
      if (hasServerCap) {
         this._onUpdateSession(wmksSession);
      }
   };

   /**
    * Reset display status, would be ready to enter multimon.
    */
   private resetStatus = () => {
      this._applySession = null;
      this._multimonEntered = false;
      this._hasNonAdjacentMonitors = false;
      this._hasTooLargeMonitor = false;
      if (this._permissionToast) {
         this.toastService.remove(this._permissionToast);
         this._permissionToast = null;
      }
      if (this.modalDialogService.isDialogOpen(this._fullscreenDialogId)) {
         this.modalDialogService.close(this._fullscreenDialogId);
      }
      this.displayService.close();
   };

   /**
    * Currently apply the same old logic to quit first and then enter.
    * TODO: need to switch to single monitor for the previous session,
    * and hook wmks and display to the new session.
    * - unhook event and send resolution to previous session.
    * - hook event and sent topology to current session.(normalize server reinit needed)
    *
    * Optimize for workflow to be single enter, but current design
    * is more robust and easy.
    *
    * @param  {wmksSession} wmksSession
    */
   private _hookToAnotherSession = (wmksSession: BlastWmks) => {
      if (this._applySession && !this._multimonEntered) {
         Logger.info(
            "skip entering multimon for " +
               wmksSession.key +
               ", since the previous session " +
               this._applySession.key +
               " is still preparing for using it",
            Logger.DISPLAY
         );
         return;
      }
      this.quitMultimon();
      this._applySession = wmksSession;
      this._grapExtendedMonitorInTimeout(wmksSession, true);
   };

   private _requestEnterFullscreen = () => {
      if (
         !this.fullscreenService.isInFullscreen() &&
         !this._quitFullscreenBtnClicked &&
         !this.modalDialogService.isDialogOpen(this._fullscreenDialogId)
      ) {
         this._fullscreenDialogId = this.modalDialogService.showCancelConfirm({
            data: {
               titleKey: "FULLSCREEN",
               contentKey: "dialog_content_multimon"
            },
            callbacks: {
               confirm: () => {
                  this.fullscreenService.bindFullScreen();
                  this.fullscreenService.enterFullscreen(document.documentElement);
               },
               cancel: () => {
                  this._quitFullscreenBtnClicked = true;
                  Logger.info("cancel to enter fullscreen", Logger.DISPLAY);
               }
            }
         });
      }
   };

   private _grapExtendedMonitorInTimeout = (wmksSession, isHook?) => {
      if (this._useWindowReplacementApi) {
         Logger.info("_useWindowReplacementApi is true", Logger.DISPLAY);

         const displaySetting = this.clientSettingModel.getPrefNumberItem("displaySetting");

         if (displaySetting === DISPLAY.DisplayOption.SINGLE) {
            Logger.info("displaySetting is SINGLE", Logger.DISPLAY);
            return;
         }

         // use only one monitor in fullscreen mode
         if (displaySetting === DISPLAY.DisplayOption.FULLSCREEN) {
            this._requestEnterFullscreen();
            Logger.info("displaySetting is FULLSCREEN", Logger.DISPLAY);
            return;
         }

         if (
            this.displayService.windowReplacementPermissionStatus !== "granted" &&
            this.displayService.getHasExtraDisplay()
         ) {
            if (this.displayService.windowReplacementPermissionStatus === "denied") {
               // Notice user to grant permission manually
               this.modalDialogService.showError({
                  data: {
                     titleKey: "WARNING",
                     contentKey: "MUST_GRANT_WINDOW_REPLACEMENT_PERMISSION"
                  }
               });
               Logger.info("window placement permission is denied", Logger.DISPLAY);
            } else {
               // request permission
               this.modalDialogService.showError({
                  data: {
                     titleKey: "WARNING",
                     contentKey: "MUST_GIVE_WINDOW_REPLACEMENT_PERMISSION"
                  },
                  callbacks: {
                     confirm: () => {
                        this.displayService.checkPermissionAndExtraDisplay(wmksSession);
                     }
                  }
               });
               Logger.info("window placement permission is prompt", Logger.DISPLAY);
               // got permission, switch to multi monitor
               this.eventBusService
                  .listen(BusEvent.GainWindowReplacementPermission.MSG_TYPE)
                  .subscribe(() => this._doGrabExtendedMonitor(wmksSession, isHook));
            }
            return;
         }
         this.displayService.checkPermissionAndExtraDisplay(wmksSession);
      }
      this._doGrabExtendedMonitor(wmksSession, isHook);
   };

   private _doGrabExtendedMonitor = (wmksSession, isHook) => {
      const pendingTime = 3000;
      // need to wait a while under some conditions.
      setTimeout(
         () => {
            this._grapExtendedMonitor(wmksSession);
         },
         this._useWindowReplacementApi || isHook ? pendingTime : 0
      );
   };

   /**
    * @return {Boolean} Returns whether current client can use this API to enable Multimon.
    */
   private isMultiMonitorEnabled = (): boolean => {
      // for windowReplacementApi, ignore enableMultiMonitor, because use can select any monitors to use
      return (
         this.isSupportedPlatform() &&
         (this._useWindowReplacementApi ? true : this.clientSettingModel.getBooleanItem("enableMultiMonitor"))
      );
   };

   /**
    * Interface for activeSessionChanged
    * @param  {wmksSession}  wmksSession
    */
   private _onActiveSession = (wmksSession: BlastWmks) => {
      if (!this._enabled) {
         return;
      }
      if (!wmksSession) {
         Logger.error("missing session in onActiveSession", Logger.DISPLAY);
         return;
      }
      if (!wmksSession.isActive) {
         Logger.trace("skip checking multimon for session update of inactive sessions", Logger.DISPLAY);
         return;
      }
      if (this._applySession && this._applySession.isDestroyed) {
         Logger.info("reset status for dead session" + this._applySession.key, Logger.DISPLAY);
         this.resetStatus();
      }
      if (wmksSession === this._applySession) {
         Logger.debug("skip duplicated active on same session" + wmksSession.key, Logger.DISPLAY);
         return;
      }
      if (!this.isMultiMonitorEnabled()) {
         if (this._applySession) {
            Logger.info("only quit multimon for previous session" + this._applySession.key, Logger.DISPLAY);
            this.quitMultimon();
         }
         return;
      }
      const currentSessionReadyToMultimon = this._sessionReadyForMultimon(wmksSession);
      if (this._applySession) {
         if (!currentSessionReadyToMultimon) {
            Logger.info("quit multimon for previous session" + this._applySession.key, Logger.DISPLAY);
            this.quitMultimon();
         } else {
            Logger.info("switch session for multimon" + this._applySession.key, Logger.DISPLAY);
            //keep display service unchanged if switching to another desktop session.
            this._hookToAnotherSession(wmksSession);
         }
         return;
      }
      if (currentSessionReadyToMultimon && !this._windowOpenIsBlocked) {
         this._grapExtendedMonitorInTimeout(wmksSession);
      }
   };

   /**
    * We use the dialog to show display message since user is selection
    * screens so that the remote session can't handle for mulitmon
    */
   private _showErrorMessage = (contentKey, options?: any) => {
      this.resetStatus();
      this.modalDialogService.showError({
         data: {
            titleKey: "ERROR",
            contentKey: contentKey
         }
      });
   };

   /**
    * Enter multimon by applying DPI and switch mode
    */
   public enterMultimon = (doubleCheckSession?: boolean) => {
      if (this._multimonEntered) {
         Logger.info("can't enter multimon if already in it", Logger.DISPLAY);
         return;
      }
      const currentSession = this._wmksService.getCurrentSession();
      if (this._applySession !== currentSession) {
         Logger.info(
            "detect current session changed from " +
               (this._applySession ? this._applySession.key : null) +
               " to " +
               currentSession.key,
            Logger.DISPLAY
         );
         this._applySession = currentSession;
         setTimeout(() => {
            this.enterMultimon(true);
         }, 1000);
         return;
      }

      if (doubleCheckSession) {
         if (!this._sessionReadyForMultimon(currentSession)) {
            this.resetStatus();
            return;
         }
      }

      const agentDPI = this._wmksService.getCurrentSessionDPI();
      const enableHighResolution = this.clientSettingModel.getBooleanItem("enableHighResolution");
      const displayScaleDisabled = this._wmksService.isCurrentSessionPerMonitorDPI();

      this.normalizationService.setAgentDPI(agentDPI);
      this.normalizationService.setDPISync(!enableHighResolution, displayScaleDisabled);

      this._multimonEntered = true;
      this._wmksService.enterMultimon();
   };

   /**
    * not apply the conflict monitor check here, since it's abnormal for user to
    * move browser into the selected screen manually while entering multimon.
    */
   private _updateScreen = (content) => {
      this._hasNonAdjacentMonitors = this._hasNonAdjacentRect(content);
      this._hasTooLargeMonitor = this._checkTooLargeMonitor(content);
   };

   /**
    * Detect whether has abnormal large monitor caused by agent status
    */
   private _checkTooLargeMonitor = (content) => {
      const agentScale = this._wmksService.getCurrentSessionDPI();

      if (this._wmksService.isCurrentSessionPerMonitorDPI()) {
         return false;
      }
      if (agentScale === 1) {
         return false;
      }
      const deviceFactor = !this.clientSettingModel.getBooleanItem("enableHighResolution") ? devicePixelRatio : 1;
      const factor = agentScale / deviceFactor;

      for (const key in content) {
         if (content.hasOwnProperty(key)) {
            if (content[key].settings.macNotchHeight && content[key].settings.macNotchHeight > 0) {
               return false;
            }
            if (CST.isTooLargeMonitor(content[key].settings, factor)) {
               return true;
            }
         }
      }
      return false;
   };

   private _hasNonAdjacentRect = (content) => {
      let k1, k2, rect1, rect2, hasAdjacent;

      for (k1 in content) {
         if (content.hasOwnProperty(k1)) {
            rect1 = content[k1].settings;
            hasAdjacent = false;
            for (k2 in content) {
               if (content.hasOwnProperty(k2) && k1 !== k2) {
                  rect2 = content[k2].settings;
                  if (!!rect2 && this.displayCheckService.areRectanglesAdjacent(rect1, rect2)) {
                     hasAdjacent = true;
                     break;
                  }
               }
            }
            if (!hasAdjacent) {
               return true;
            }
         }
      }
      return false;
   };

   /**
    * After grabbing the extended monitor, enter fullscreen and ready to enter multimon
    */
   private _prepareMultimon = () => {
      Logger.info("now entering multimon mode", Logger.DISPLAY);
      if (this._multimonEntered) {
         Logger.info("can't enter multimon if already in it", Logger.DISPLAY);
         return;
      }
      const uiCallback = (type, data) => {
         switch (type) {
            case "onMonitorChanged":
               Logger.info("onMonitorChanged" + JSON.stringify(data), Logger.DISPLAY);
               this._updateScreen(data.settings);
               if (data.allReady) {
                  if (this.modalDialogService.isDialogOpen(this._fullscreenDialogId) || this._multimonEntered) {
                     return;
                  }
                  this._fullscreenDialogId = this.modalDialogService.showConfirm({
                     data: {
                        titleKey: "FULLSCREEN",
                        contentKey: "dialog_content_multimon"
                     },
                     callbacks: {
                        confirm: () => {
                           if (this._hasNonAdjacentMonitors) {
                              Logger.info("stop entering multimon due to select non adjacent monitors", Logger.DISPLAY);
                              this._showErrorMessage("MM_NONADJACENT_MONITOR");
                           } else if (this._hasTooLargeMonitor) {
                              Logger.info(
                                 "stop entering multimon due to select too large monitors after display scaling",
                                 Logger.DISPLAY
                              );
                              this._showErrorMessage("MM_HAS_TOO_LARGE_MONITOR");
                           } else {
                              this.enterMultimon();
                              // for windowReplaceApi: send "enterFullscreen" message to extended monitors at this moment that user clicked a button to trigger enter fullscreen.
                              // for PresentationApi: do nothing
                              Logger.info("Send enter fullscreen message to extended monitors", Logger.DISPLAY);
                              this.displayService.setExtendedMonitorEnterFullscreen();
                           }
                        },
                        cancel: () => {
                           Logger.info("cancel to enter fullscreen", Logger.DISPLAY);
                           this.displayService.close();
                           this.resetStatus();
                        }
                     }
                  });
               }
               break;
            case "onMultiMonitor":
               this._wmksService.disconnectEventConnections();
               Logger.debug("switch mks for multimon", Logger.DISPLAY);
               break;
            case "onSingleMonitor":
               // Unbind the key and mouse event first, or sometimes it will bind multi times
               this._wmksService.disconnectEventConnections();
               this._wmksService.recoveryEventConnections();
               Logger.debug("switch back mks for quiting multimon", Logger.DISPLAY);
               break;
            case "onQuitMultimon":
               Logger.info("quitting Multimon", Logger.DISPLAY);
               this.eventBusService.dispatch(new BusEvent.MultiMonitorsMsg(false));
               this.resetStatus();
               break;
            case "onEnterMultimon":
               Logger.info("entering Multimon", Logger.DISPLAY);
               this.eventBusService.dispatch(new BusEvent.MultiMonitorsMsg(true));
               break;
            case "onInvalidTopology":
               Logger.debug("topology becomes invalid, due to quit multiple monitors", Logger.DISPLAY);
               break;
            default:
               Logger.error("unknown message from multimon", Logger.DISPLAY);
         }
      };
      let screens;
      if (this._useWindowReplacementApi) {
         screens = this.displayService.getExtendedScreens();
         if (screens.length >= 1 && this.displayCheckService.isWindowInMaximum()) {
            // wait a little while for exit fullscreen mode when switching desktop
            setTimeout(() => {
               if (this.displayCheckService.isWindowInMaximum()) {
                  this._showErrorMessage("MM_MAXIMAS_BORWSER_ERROR");
               } else {
                  this._wmksService.preparingForMultimon();
                  this._addExtendedMonitors(screens, uiCallback);
               }
            }, 2000);
            return;
         }
      }
      if (this._useWindowReplacementApi) {
         this._wmksService.preparingForMultimon();
         this._addExtendedMonitors(screens, uiCallback);
      } else {
         this._wmksService.preparingForMultimon();
         this._wmksService.addMonitor(uiCallback, () => {});
         this.displayService.addEventListener("displayConnected", () => {
            Logger.info("display connected from multi-display", Logger.DISPLAY);
         });
      }
   };

   private _addExtendedMonitors = (screens, uiCallback) => {
      Logger.info("Add extended monitors: " + JSON.stringify(screens), Logger.DISPLAY);
      for (let i = 0; i < screens.length; i++) {
         this._wmksService.addMonitor(uiCallback, () => {}, screens[i]);
      }
   };

   /**
    * To take over control of extended monitor
    * Possible optimization: do this when launching instead of connected
    * @param  {[type]} wmksSession [description]
    * @return {[type]}             [description]
    */
   private _grapExtendedMonitor = (wmksSession) => {
      if (!wmksSession) {
         Logger.error("missing wmksSession when try to grab extended monitor", Logger.DISPLAY);
         return;
      }
      this._applySession = wmksSession;
      if (this.displayService.getDisplayReady()) {
         Logger.info("ready", Logger.DISPLAY);
      } else if (!this.displayService.getHasExtraDisplay()) {
         if (this._useWindowReplacementApi) {
            const displaySetting = this.clientSettingModel.getPrefNumberItem("displaySetting");
            // when a new user login for the first time, displaySetting is null
            if (
               this._enterFullscreenIfOneMonitor &&
               (displaySetting === DISPLAY.DisplayOption.ALL || displaySetting === null)
            ) {
               this._requestEnterFullscreen();
               Logger.info("displaySetting is ALL and has only one monitor, so enter fullscreen", Logger.DISPLAY);
            }
         }
         Logger.warning("don't have extra monitor, can't enter multimon mode", Logger.DISPLAY);
      } else {
         if (this._useWindowReplacementApi) {
            this._prepareMultimon();
         } else {
            if (this._permissionToast) {
               Logger.warning(
                  "already have a permission asking toaster, " + "skip show another one for " + wmksSession.key,
                  Logger.DISPLAY
               );
               return;
            }
            if (this.fullscreenService.isInFullscreen()) {
               Logger.warning(
                  "already in fullscreen mode, skip showing toaster for " + wmksSession.key,
                  Logger.DISPLAY
               );
               this.resetStatus();
               return;
            }
            this._permissionToast = this.toastService.infoWithCallBack(
               "MULTIMON",
               this.toastService.TOAST_TYPE.MULTIMON,
               () => {
                  this.toastCallback(this._permissionToast, wmksSession);
               },
               null,
               () => {
                  this.dismissToastCallback(this._permissionToast);
               },
               null
            );
         }
      }
   };

   public toastCallback = (toast, wmksSession) => {
      this._permissionToast = toast;
      if (this._permissionToast) {
         this.toastService.remove(this._permissionToast);
         this._permissionToast = null;
      }
      // check here again to avoid user wasting time on doomed-fail actions
      if (this._sessionReadyForMultimon(wmksSession)) {
         this._prepareMultimon();
      } else {
         this.resetStatus();
      }
   };
   public dismissToastCallback = (toast) => {
      Logger.info("cancel to enter multimon", Logger.DISPLAY);
      this._permissionToast = toast;
      if (this._permissionToast) {
         this.toastService.remove(this._permissionToast);
         this._permissionToast = null;
      }
      this.resetStatus();
   };

   /**
    * Interface for sessionConnecting
    * @param  {string}  key
    * @param  {boolean} isApplicationSession
    * @param  {VDPService}  vdpService
    * @param  {object}  mainChannel
    * @param  {wmksSession}  wmksSession
    */
   private _onSessionConnected = (wmksSession: BlastWmks) => {
      this._onUpdateSession(wmksSession);
   };

   /**
    * Interface for sessionDisconnecting
    * @param  {string}  key
    * @param  {boolean} isApplicationSession
    * @param  {wmksSession}  wmksSession
    */
   public onSessionDisconnected = (wmksSession: BlastWmks) => {
      if (this._applySession === wmksSession) {
         this.quitMultimon();
      }
   };
}
