/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * unity-service.js --
 *
 * Module for wrapping unity to angular factory service. Unity Service is a
 * singleton service.
 *
 */

import { Injectable, Optional } from "@angular/core";
import { AB } from "../common/appblast-util.service";
import { EventBusService, BusEvent, clientUtil } from "@html-core";
import Logger from "../../../core/libs/logger";
import { Unity } from "./unity";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { UnityHandler } from "./unity-handler";
import { Subject, Subscription } from "rxjs";
import { BlastWmks } from "../common/blast-wmks.service";
import { UnityService } from "./unity.service";
import { UnityMonitorConnectorPrimary } from "./unity-monitor-connect-primary";
@Injectable()
export class UnityPrimaryWindowService extends UnityService {
   constructor(
      remoteSessionEventService: RemoteSessionEventService,
      eventBusService: EventBusService,
      @Optional()
      private unityMonitorConnectorPrimary: UnityMonitorConnectorPrimary
   ) {
      super(remoteSessionEventService, eventBusService);

      this.remoteSessionEventService.addEventListener("sessionConnecting", this._onWmksSessionConnecting);
      this.remoteSessionEventService.addEventListener("sessionConnected", this._onWmksSessionConnected);
      this.remoteSessionEventService.addEventListener("sessionDisconnected", this._onWmksSessionDisconnected);
      this.remoteSessionEventService.addEventListener("sessionRemoved", this._onWmksSessionRemoved);
   }

   protected _onWmksSessionConnecting = (session: BlastWmks) => {
      if (!session.isApplicationSession) {
         return;
      }

      const handler = new UnityHandler(session.key, this.subject$, this);
      const unityMgr = new Unity.Mgr(session, handler);
      handler.unityInst = unityMgr;
      this.unityMgrMap.set(session.key, unityMgr);
      handler.onVisibilityChanged(false);

      if (clientUtil.isChromeClient()) {
         // multimon option is always true for apps
         // TODO: check monitor number here.
         Logger.info("initing bridge for app multimon");
         this.unityMonitorConnectorPrimary.init(this, handler, unityMgr, session.key);
      }
   };
}
