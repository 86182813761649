/**
 * ******************************************************
 * Copyright (C) 2016-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * extended-monitor-util.js -- messageService extendedMonitorModel
 * extendedMonitorTranslationService
 *
 * "multimonModule" is for multimon codes
 * Define those services and model that shared among controllers to decouple.
 */

import { clientUtil } from "@html-core";
import { Injectable } from "@angular/core";
import { Monitor } from "../common/monitor-message";
import Logger from "../../../../core/libs/logger";

@Injectable({
   providedIn: "root"
})
export class MessageService {
   private messageMap = {};
   private extendedMonitorModel = null;
   private origin;
   private connection: any = null;
   private isChromeClient = clientUtil.isChromeClient();
   private onInitDone;

   private _port = null;

   public uid = "";

   constructor() {
      if (this.isChromeClient) {
         this.origin = "chrome-extension://" + window.location.hostname;

         const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
         const sessionKey = urlParams.get("sessionKey");
         const id = urlParams.get("v");
         this._port = chrome.runtime.connect({ name: "REMOTEAPP-MULTIMON-" + sessionKey + "__CMS_ON__" + id });
         if (!this._port) {
            Logger.error("Error to connected to primary screen !");
         }
      } else {
         this.origin = "https://" + window.location.hostname;
      }
      this.init();
   }

   private init = () => {
      Logger.info("init messageService");
      if (this.isChromeClient) {
         this._port.onMessage.addListener(this.onConnectedMessage);
         this._port.onDisconnect.addListener(() => {
            chrome.app.window.current().close();
         });

         chrome.app.window.current().onClosed.addListener(() => {
            Logger.info("close window", Logger.DISPLAY);
            this.onClose();
         });
      } else {
         window.addEventListener("message", this.onMessage, false);
         window.addEventListener("beforeunload", this.onClose);
         if (
            //@ts-ignore
            navigator.presentation &&
            //@ts-ignore
            navigator.presentation.receiver &&
            //@ts-ignore
            navigator.presentation.receiver.connectionList
         ) {
            const addConnection = (connection) => {
               connection.onmessage = (e) => {
                  try {
                     const message = {
                        origin:
                           e.currentTarget.url.split("://")[0] +
                           "://" +
                           e.currentTarget.url.split("https://")[1].split("/")[0].split(":")[0],
                        data: JSON.parse(e.data)
                     };
                     this.onMessage(message);
                  } catch (e) {
                     Logger.error("exception found when handling message from extended monitor", Logger.DISPLAY);
                  }
               };
               this.connection = connection;
               Logger.info("connection established", Logger.DISPLAY);
            };
            //@ts-ignore
            navigator.presentation.receiver.connectionList.then((list) => {
               list.connections.map((connection) => {
                  addConnection(connection);
               });
               list.onconnectionavailable = (evt) => {
                  addConnection(evt.connection);
               };
            });
         }
      }
   };

   public onConnectedMessage = (data) => {
      let handlers, i;
      if (this.messageMap.hasOwnProperty(data.msgType)) {
         handlers = this.messageMap[data.msgType];
         if (!handlers.length) {
            Logger.info("no listener for message type" + data.msgType);
         }
         for (i = 0; i < handlers.length; i++) {
            Logger.trace("dispatching message to handler " + i);
            handlers[i](data);
         }
      } else {
         Logger.info("not responsed message type" + data.msgType);
      }
   };

   public onMessage = (event) => {
      let data,
         i,
         handlers,
         eventOrigin = event.origin || event.originalEvent?.origin;
      if (this.isChromeClient) {
         data = event;
      } else {
         data = event.data;
      }
      Logger.trace("extended monitor: <=[" + JSON.stringify(data), Logger.DISPLAY);

      if (eventOrigin !== this.origin) {
         Logger.info("mismatched event origin " + eventOrigin + " vs " + this.origin, Logger.DISPLAY);
         return;
      }
      if (!this.extendedMonitorModel) {
         Logger.error("invalid extendedMonitorModel", Logger.DISPLAY);
         return;
      }
      if (data.uid !== this.uid && data.uid !== this.extendedMonitorModel.uid) {
         Logger.info(
            "mismatched message :" + data.msgType + " => uid " + data.uid + " vs " + this.extendedMonitorModel.uid,
            Logger.DISPLAY
         );
         return;
      }
      if (this.messageMap.hasOwnProperty(data.msgType)) {
         handlers = this.messageMap[data.msgType];
         if (!handlers.length) {
            Logger.info("no listener for message type" + data.msgType);
         }
         for (i = 0; i < handlers.length; i++) {
            Logger.trace("dispatching message to handler " + i);
            handlers[i](data);
         }
      } else {
         Logger.info("not responsed message type" + data.msgType);
      }
   };

   /**
    * make handler response to a type of message
    * @param {string} type The target message type
    * @param {function} handler The callback for the target message
    */
   public responseTo = (type, handler) => {
      if (!this.messageMap[type]) {
         this.messageMap[type] = [];
      }
      Logger.info("response to " + type);
      if (this.messageMap[type].includes(handler)) {
         Logger.debug("skip dup registration for type " + type);
         return;
      }
      this.messageMap[type].push(handler);
   };

   /**
    * send message
    * @param {object} message should contains the "type" property
    */
   public sendMessage = (message: Monitor.MonitorMessage): boolean => {
      message.uid = this.uid ? this.uid : this.extendedMonitorModel.uid;

      Logger.trace("extended monitor: => [" + JSON.stringify(message), Logger.DISPLAY);
      if (this.isChromeClient) {
         this._port.postMessage(message);
      } else {
         if (window.parent && window.parent !== window) {
            window.parent.postMessage(message, origin);
         } else if (window.opener) {
            window.opener.postMessage(message, origin);
         } else if (this.connection) {
            this.connection.send(JSON.stringify(message));
         } else {
            Logger.error("no connection for now, discard message :" + message, Logger.DISPLAY);
            return false;
         }
      }
      return true;
   };

   /**
    * cancel calling back the handler for the target type
    * @param {string} type The target message type
    * @param {function} handler Optimal The callback for the target message,
    *     if absent, will delete all handlers bound to the message
    */
   public clearResponse = (type, handler?: any) => {
      let handlerIndex;

      if (!this.messageMap[type]) {
         return;
      }
      if (!handler) {
         delete this.messageMap[type];
      } else {
         handlerIndex = this.messageMap[type].indexOf(handler);
         if (handlerIndex === -1) {
            return;
         }
         this.messageMap[type].splice(handlerIndex, 1);
         if (this.messageMap[type].length === 0) {
            delete this.messageMap[type];
         }
      }
   };

   /**
    * @param {object} model The extendedMonitorModel object
    */
   public setExtendedMonitorModel = (model) => {
      this.extendedMonitorModel = model;
      if (this.onInitDone) {
         this.onInitDone(true);
      }
   };

   public stop = () => {
      Logger.info("message service stopped");
      if (!this.isChromeClient) {
         window.removeEventListener("message", this.onMessage);
         window.removeEventListener("beforeunload", this.onClose);
      } else {
         this._port.disconnect();
         chrome.app.window.current().onClosed.removeListener(this.onClose);
      }
   };

   public onClose = () => {
      this.sendMessage(new Monitor.CloseMsg());
   };

   // return Promise<boolean> indicate whether was pending on initing when called
   public onReady = () => {
      if (this.extendedMonitorModel && this.extendedMonitorModel.uid) {
         return new Promise((resolve, reject) => {
            resolve(false);
         });
      }
      return new Promise((resolve, reject) => {
         this.onInitDone = resolve;
      });
   };
}
