<div [ngClass]="{desktopBackground:CLIENT_MODE === 'desktop'}">
   <div>
   <router-outlet></router-outlet>
   </div>

   <div>

      <div *ngIf="CLIENT_MODE === 'desktop'" id="main" class="canvas-view-port">
         <!-- canvas and video are used for RTAV -->
         <canvas width="320" id="canvas" height="240" style="display: none;"></canvas>
         <video id="video" width="0" height="0" autoplay style="display: none;"></video>
         <!-- Media Overlay container -->
         <!-- TODO-NG need some extra work for handling multi-session for HTML web client -->
         <media-overlay></media-overlay>
         <!-- Main application canvas -->
         <div id="canvas-container" class="web-container"></div>
      </div>

      <!-- Dialog holder contains resources that are not visible by default -->
      <div *ngIf="CLIENT_MODE === 'desktop'" id="dialog-holder" class="ng-hide">
         <!-- About, gesture guide dialogs are dynamically added here -->
         <!-- Load jQuery icons and touch icons upfront for iPad. -->
         <div id="preLoadImage" class="ui-widget-header">
            <div class="ui-icon jquery-ui-image"></div>
            <div class="ui-touch-feedback-icon"></div>
         </div>
      </div>

      <!-- Angular container -->
      <div *ngIf="CLIENT_MODE === 'desktop'">
         <div id="busy-overlay-container">
            <ajax-busy-overlay></ajax-busy-overlay>
         </div>
      </div>

      <!-- views -->
      <!-- TODO-NG can we remove this -->
      <div *ngIf="CLIENT_MODE !== null" ng-view class="ui-page ui-body {{transitionState}}"></div>
      <custom-modal-dialog></custom-modal-dialog>
   </div>
</div>