/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";

let proto = null;
try {
   proto = require("./rtav.proto");
} catch (e) {
   Logger.error(e, Logger.RTAV);
}

@Injectable({
   providedIn: "root"
})
export class ProtobufService {
   readonly proto: any = proto;

   constructor() {}

   public serializeVideoDeviceResolution(data): Uint8Array {
      const message = proto.RTAV.VideoDeviceResolution.create(data);
      return proto.RTAV.VideoDeviceResolution.encode(message).finish();
   }

   public deserializeVideoDeviceResolution(data: Uint8Array) {
      return proto.RTAV.VideoDeviceResolution.decode(data);
   }

   public serializeAdminPolicy(data): Uint8Array {
      const message = proto.RTAV.AdminPolicy.create(data);
      return proto.RTAV.AdminPolicy.encode(message).finish();
   }

   public deserializeAdminPolicy(data: Uint8Array) {
      return proto.RTAV.AdminPolicy.decode(data);
   }

   public serializeClientConfig(data): Uint8Array {
      const message = proto.RTAV.ClientConfig.create(data);
      return proto.RTAV.ClientConfig.encode(message).finish();
   }

   public deserializeClientConfig(data: Uint8Array) {
      return proto.RTAV.ClientConfig.decode(data);
   }

   public serializeAudioDeviceList(data): Uint8Array {
      var audioDeviceListJson = {};
      audioDeviceListJson["count"] = data.length;
      audioDeviceListJson["devices"] = data;
      const audioDeviceList = proto.RTAV.AudioDeviceList.fromObject(audioDeviceListJson);
      return proto.RTAV.AudioDeviceList.encode(audioDeviceList).finish();
   }

   public serializeVideoDeviceList(data): Uint8Array {
      var videoDeviceListJson = {};
      videoDeviceListJson["count"] = data.length;
      videoDeviceListJson["devices"] = data;
      const videoDeviceList = proto.RTAV.VideoDeviceList.fromObject(videoDeviceListJson);
      return proto.RTAV.VideoDeviceList.encode(videoDeviceList).finish();
   }

   public deserializeAudioDevicePref(data: Uint8Array) {
      return proto.RTAV.AudioDevicePrefs.decode(data);
   }

   public deserializeVideoDevicePref(data: Uint8Array) {
      return proto.RTAV.VideoDevicePrefs.decode(data);
   }

   public serializeAudioDevicePref(audioDevicePref) {
      return proto.RTAV.AudioDevicePrefs.encode(audioDevicePref).finish();
   }

   public serializeVideoDevicePref(videoDevicePref) {
      return proto.RTAV.VideoDevicePrefs.encode(videoDevicePref).finish();
   }
}

export const protobufService = new ProtobufService();
