/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * GetUserGlobalPrefCommand.js --
 *
 *      get user global preference.
 *
 */

import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import GetUserGlobalPrefHandler from "../controllers/getUserGlobalPrefHandler";

export default function GetUserGlobalPrefCommand() {}

GetUserGlobalPrefCommand.prototype.execute = function () {
   let getGlbUserPrefObj,
      handlerList,
      router = globalArray["router"];

   getGlbUserPrefObj = globalArray["get-user-global-preference"];
   if (!getGlbUserPrefObj) {
      getGlbUserPrefObj = new GetUserGlobalPrefHandler();
      globalArray[getGlbUserPrefObj.messageName] = getGlbUserPrefObj;
      globalArray[getGlbUserPrefObj.responseTag] = getGlbUserPrefObj;
   } else {
      getGlbUserPrefObj.resetData();
   }

   if (!!getGlbUserPrefObj && !!router) {
      getGlbUserPrefObj.setRequestXML();
      handlerList = getGlbUserPrefObj.composeHandlerList();
      router.postMessage(handlerList);
   } else {
      Logger.error("supported protocols is invalid.");
      return false;
   }
};
