/**
 * ******************************************************
 * Copyright (C) 2018-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 *
 * entry-util.js
 *
 * Utils of File Node layer API
 * Put it under shared for now, since we don't have the HTML Access version of this file.
 */
import { fsConsts } from "./index";
import Logger from "../../../core/libs/logger";
import { FileSystemService } from "./cdr-lib/file-system-service";

export class EntryUtil {
   /**
    * @param  {object} entry
    * @return {object} The metadata promise
    */
   public static getMetaData = (entry) => {
      return new Promise((resolve, reject) => {
         if (!entry) {
            Logger.error("can't get metadata for a not exist entry");
            reject();
            return;
         }
         entry.getMetadata(resolve, reject);
      });
   };

   /**
    * Gather the information from entry
    * Don't use async to avoid webpack bug
    *
    * @param  {object} entry
    * @return {object} The info object promise
    */
   public static getInfo = (entry) => {
      return new Promise((resolve, reject) => {
         if (!entry) {
            Logger.error("can't abstract the info obj for a not exist entry");
            resolve(null);
         }

         EntryUtil.getMetaData(entry)
            .then((metaData: any) => {
               //remove the root folder name
               const fullPath = entry.fullPath.split(fsConsts.localSeparator).slice(2).join(fsConsts.localSeparator);
               const folderPath =
                  "\\" + fullPath.split(fsConsts.localSeparator).slice(0, -1).join(fsConsts.remoteSeparator);
               const name = entry.name;
               const isDirectory = entry.isDirectory;
               const size = metaData.size;
               const modificationTime = metaData.modificationTime;
               resolve({
                  folderPath: folderPath,
                  name: name,
                  isDirectory: isDirectory,
                  type: "CROS",
                  size: isDirectory ? fsConsts.folderSize : size,
                  modificationTime: modificationTime,
                  fileEntry: entry
               });
            })
            .catch(reject);
      });
   };

   /**
    * @param  {object} dirEntry The target folder
    * @param  {function} process Callback will be called with each content in the target folder
    * @return {object} Promise resolved with all the processed results
    */
   public static eachInDir = async (dirEntry, process) => {
      if (!dirEntry.isDirectory) {
         throw "can't use eachInDir for non-dir";
      }
      const dirReader = dirEntry.createReader();
      const entriesPromises = [];
      // Call the reader.readEntries() until no more results are returned.
      const readEntries = () => {
         return new Promise((resolve, reject) => {
            dirReader.readEntries(
               function (results) {
                  if (results.length) {
                     results.forEach(function (item) {
                        entriesPromises.push(process(item));
                     });
                     readEntries().then(resolve);
                  } else {
                     // @ts-ignore
                     resolve();
                  }
               },
               () => {}
            );
         });
      };
      await readEntries();
      return await Promise.all(entriesPromises);
   };

   /**
    * Unlike eachInDir this stops if any matched item is found.
    *
    * @param  {object} dirEntry The target folder
    * @param  {function} process Callback will be called with each content in the target folder
    * @return {object} Promise resolved with first matched one
    */
   public static firstInDir = (dirEntry, condition) => {
      return new Promise((resolve, reject) => {
         if (!dirEntry.isDirectory) {
            reject();
            return;
         }
         const dirReader = dirEntry.createReader();
         // Call the reader.readEntries() until no more results are returned.
         let result = null;
         const conditionFunc = function (element, index, array) {
            const matched = condition(element, index, array);
            if (matched) {
               result = element;
            }
            return matched;
         };
         const readEntries = function () {
            dirReader.readEntries(
               function (results) {
                  if (!results.length) {
                     // no more to check, so fail
                     reject();
                     return;
                  } else {
                     if (!results.some(conditionFunc)) {
                        readEntries();
                     } else {
                        resolve(result);
                        return;
                     }
                  }
               },
               () => {}
            );
         };
         readEntries(); //Start reading dirs.
      });
   };

   /**
    * Move file node to another place
    *
    * @param  {object} entry            source File Node
    * @param  {object} folderEntry      Target Folder
    * @param  {object} name             Target File name
    * @param  {boolean} replaceIfExists
    * @return {[type]}                  New item promise
    */
   public static move = (entry, folderEntry, name, replaceIfExists) => {
      return new Promise((resolve, reject) => {
         if (!entry || !folderEntry) {
            Logger.error("<cdr> Invalid entry to proform move");
            reject();
            return;
         }
         entry.moveTo(
            folderEntry,
            name,
            (newEntry) => {
               Logger.trace("<cdr> Succeed moving the new node");
               resolve(newEntry);
            },
            (e) => {
               Logger.trace("<cdr> Moving the new node failed");
               reject(e);
            }
         );
      });
   };

   /**
    * Get the entry for writing, will be rejected if it's read-only
    * Should involve UI when support on HTML Access, but not for now.
    *
    * @param  {object} entry            source File Node
    * @param  {object} folderEntry      Target Folder
    * @param  {object} name             Target File name
    * @param  {boolean} replaceIfExists
    * @return {[type]}                  New item promise
    */
   public static getWritableEntry = (entry) => {
      return new Promise((resolve, reject) => {
         if (!entry) {
            Logger.error("<cdr> can't find writable entry for non existing entry");
            reject();
            return;
         }

         const fileSystem = new FileSystemService();
         fileSystem
            .getWritableEntry(entry)
            .then((writableEntry) => {
               resolve(writableEntry);
            })
            .catch((e) => {
               Logger.error(e);
               reject(e);
            });
      });
   };
}
