/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { clientUtil } from "@html-core";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { ClientSettingModel } from "../model/client-setting-model";
import { defaultKeyboardLayouts, keyMappingForSpecificLanguage, keyMappingsForLayout } from "./keyboard-layout";

export interface KeyMapping {
   platform: string;
   isDefault: boolean;
   isOn: boolean;
   fromShotcut: string;
   toShortcut: string;
   originShortcut: string;
   mappingShortcut: string;
}

export const defaultKeyMappingSetting: KeyMapping[] = [
   {
      platform: "Windows",
      isDefault: true,
      isOn: true,
      fromShotcut: "Win+Control",
      toShortcut: "Win",
      originShortcut: "MetaLeft,ControlLeft",
      mappingShortcut: "MetaLeft"
   },
   {
      platform: "MacOS",
      isDefault: true,
      isOn: true,
      fromShotcut: "⌘+Control",
      toShortcut: "Win",
      originShortcut: "MetaLeft,ControlLeft",
      mappingShortcut: "MetaLeft"
   },
   {
      platform: "MacOS",
      isDefault: true,
      isOn: true,
      fromShotcut: "⌘+C",
      toShortcut: "Control+C",
      originShortcut: "MetaLeft,KeyC",
      mappingShortcut: "ControlLeft,KeyC"
   },
   {
      platform: "MacOS",
      isDefault: true,
      isOn: true,
      fromShotcut: "⌘+A",
      toShortcut: "Control+A",
      originShortcut: "MetaLeft,KeyA",
      mappingShortcut: "ControlLeft,KeyA"
   },
   {
      platform: "MacOS",
      isDefault: true,
      isOn: true,
      fromShotcut: "⌘+X",
      toShortcut: "Control+X",
      originShortcut: "MetaLeft,KeyX",
      mappingShortcut: "ControlLeft,KeyX"
   },
   {
      platform: "MacOS",
      isDefault: true,
      isOn: true,
      fromShotcut: "⌘+V",
      toShortcut: "Control+V",
      originShortcut: "MetaLeft,KeyV",
      mappingShortcut: "ControlLeft,KeyV"
   },
   {
      platform: "ChromeOS",
      isDefault: true,
      isOn: true,
      fromShotcut: "Search+Control",
      toShortcut: "Win",
      originShortcut: "MetaLeft,ControlLeft",
      mappingShortcut: "MetaLeft"
   },
   {
      platform: "ChromeOS",
      isDefault: true,
      isOn: true,
      fromShotcut: "Alt+Backspace",
      toShortcut: "Delete",
      originShortcut: "AltLeft,Delete",
      mappingShortcut: "Delete"
   },
   {
      platform: "ChromeOS",
      isDefault: true,
      isOn: false,
      fromShotcut: "Alt+Backspace",
      toShortcut: "Control+Z",
      originShortcut: "AltLeft,Delete",
      mappingShortcut: "ControlLeft,KeyZ"
   },
   {
      platform: "Other",
      isDefault: true,
      isOn: true,
      fromShotcut: "Meta+Control",
      toShortcut: "Win",
      originShortcut: "MetaLeft,ControlLeft",
      mappingShortcut: "MetaLeft"
   }
];

@Injectable({
   providedIn: "root"
})
export class KeyboardSettingService {
   private keyboardLayouts = {};
   private currentLanguage: string = "";
   private currentLayout: string = "";
   private isSpecificLanguageEnabled: boolean = false;
   private keyMappingForSpecificLanguage = keyMappingForSpecificLanguage;
   constructor(
      private clientSettingModel: ClientSettingModel,
      private localStorageService: LocalStorageService
   ) {
      this.keyboardLayouts = defaultKeyboardLayouts;
      this.getValueFromStorage("currentLanguage", "ENGLISH_UNITED_STATES");
      this.getValueFromStorage("currentLayout", "US_KEYBOARD");
      this.getValueFromStorage("isSpecificLanguageEnabled", false);
   }

   public getKeyboardLayouts = (): any => {
      return this.keyboardLayouts;
   };

   public getCurrentLanguage = (): string => {
      return this.currentLanguage;
   };

   public getCurrentLayout = (): string => {
      return this.currentLayout;
   };

   public getIsSpecificLanguageEnabled = (): boolean => {
      return this.isSpecificLanguageEnabled;
   };

   public getKeyMappingSetting = (): any => {
      return JSON.parse(this.clientSettingModel.getStringItem("keyMappingSetting"));
   };

   public updateIsSpecificLanguageEnabled = (isSpecificLanguageEnabled: boolean): void => {
      this.isSpecificLanguageEnabled = isSpecificLanguageEnabled;
      this.localStorageService.set("isSpecificLanguageEnabled", JSON.stringify(isSpecificLanguageEnabled));
   };

   public updateCurrentLanguage = (currentLanguage: string): void => {
      this.currentLanguage = currentLanguage;
      this.localStorageService.set("currentLanguage", JSON.stringify(currentLanguage));
   };

   public updatecurrentLayout = (currentLayout: string): void => {
      this.currentLayout = currentLayout;
      this.localStorageService.set("currentLayout", JSON.stringify(currentLayout));
   };

   public getLanguageSpecificKeyMapping = (language: string, layout: string): any[] => {
      let keyMapping = [];
      if (language in keyMappingsForLayout) {
         if (layout in keyMappingsForLayout[language]) {
            const keyMappings = keyMappingsForLayout[language][layout];
            keyMappings.forEach((item) => {
               keyMapping = keyMapping.concat(JSON.parse(JSON.stringify(this.keyMappingForSpecificLanguage[item])));
            });
         }
      }
      return keyMapping;
   };

   public getFinalKeyMappingSetting = (keyMappingSetting: KeyMapping[]) => {
      let finalKeyMappingSetting = keyMappingSetting;
      const languageSpecificKeyMapping = this.getLanguageSpecificKeyMapping(this.currentLanguage, this.currentLayout);
      if (this.isSpecificLanguageEnabled) {
         const extraKeyMappingSetting = languageSpecificKeyMapping.map((keyMapping) => {
            keyMapping.isDefault = true;
            keyMapping.isOn = true;
            keyMapping.platform = keyMapping.hasOwnProperty("platform") ? keyMapping.platform : "MacOS";
            return keyMapping;
         });
         finalKeyMappingSetting = finalKeyMappingSetting.concat(extraKeyMappingSetting);
      }
      return finalKeyMappingSetting;
   };

   public getOriginModifierKeys = (): string[] => {
      return clientUtil.isMacOS()
         ? ["Shift", "Control", "Option", "⌘"]
         : clientUtil.isChromeOS()
           ? ["Shift", "Control", "Alt", "Search"]
           : ["Shift", "Control", "Alt", "Win"];
   };

   public getCurrentPlatform = (): string => {
      if (clientUtil.isChromeOS()) {
         return "ChromeOS";
      } else if (clientUtil.isMacOS()) {
         return "MacOS";
      } else if (clientUtil.isWindows()) {
         return "Windows";
      } else {
         return "Other";
      }
   };

   public sortModifierKey = (keysArray: string[]): string[] => {
      const result = [];
      const index = [];
      index[0] = keysArray.includes("MetaLeft")
         ? keysArray.indexOf("MetaLeft")
         : keysArray.includes("⌘")
           ? keysArray.indexOf("⌘")
           : keysArray.includes("Search")
             ? keysArray.indexOf("Search")
             : keysArray.indexOf("Win");
      index[1] = keysArray.includes("ShiftLeft") ? keysArray.indexOf("ShiftLeft") : keysArray.indexOf("Shift");
      index[2] = keysArray.includes("ControlLeft") ? keysArray.indexOf("ControlLeft") : keysArray.indexOf("Control");
      index[3] = keysArray.includes("AltLeft")
         ? keysArray.indexOf("AltLeft")
         : keysArray.includes("Alt")
           ? keysArray.indexOf("Alt")
           : keysArray.indexOf("Option");

      for (let i = 0; i < index.length; i++) {
         if (index[i] !== -1) {
            result.push(keysArray[index[i]]);
         }
      }
      return result;
   };

   public formatModifierKey = (code: string, isMappingShortcut: boolean): string => {
      switch (code) {
         case "ControlLeft":
         case "ControlRight":
            if (isMappingShortcut) {
               return "Ctrl";
            }
            return clientUtil.isMacOS() ? "Control" : "Ctrl";
         case "AltLeft":
         case "AltRight":
            if (isMappingShortcut) {
               return "Alt";
            }
            return clientUtil.isMacOS() ? "Option" : "Alt";
         case "MetaLeft":
         case "MetaRight":
            if (isMappingShortcut) {
               return "Win";
            }
            return clientUtil.isMacOS() ? "⌘" : clientUtil.isChromeOS() ? "Search" : "Win";
         case "ShiftLeft":
         case "ShiftRight":
            return "Shift";
         default:
            return "";
      }
   };

   public formatModifierKeysArray = (modifierKeysArray: string[], isMappingShortcut: boolean): string[] => {
      const afterFormating = [];
      for (let i = 0; i < modifierKeysArray.length; i++) {
         afterFormating.push(this.formatModifierKey(modifierKeysArray[i], isMappingShortcut));
      }
      return afterFormating;
   };

   public formatNonModifierKey = (key: string): string => {
      let afterFormating = "";
      if (this.isModifierKey(key)) {
         afterFormating = "";
      } else if (key.length === 1) {
         afterFormating = key.toUpperCase();
      } else {
         afterFormating = key;
      }
      return afterFormating;
   };

   public isModifierKey = (code: string): boolean => {
      if (
         code.includes("Shift") ||
         code.includes("Control") ||
         code.includes("Alt") ||
         code.includes("Meta") ||
         code.includes("OS")
      ) {
         return true;
      } else {
         return false;
      }
   };

   public saveKeyMappingSetting = (keyMappingSetting: KeyMapping[]) => {
      this.clientSettingModel.updateSetting("keyMappingSetting", JSON.stringify(keyMappingSetting));
      this.clientSettingModel.saveSetting();
   };

   public getValueFromStorage = (key: string, defaultValue: any) => {
      if (this.localStorageService.get(key)) {
         this[key] = JSON.parse(this.localStorageService.get(key));
      } else {
         this[key] = defaultValue;
         this.localStorageService.set(key, JSON.stringify(defaultValue));
      }
   };

   public getLanguageSpecificKeyMappingForCurrentPlatform = () => {
      const languageSpecificKeyMappingSetting = this.getFinalKeyMappingSetting([] as KeyMapping[]);
      return languageSpecificKeyMappingSetting.filter((keyMapping) => {
         return keyMapping.platform === this.getCurrentPlatform();
      });
   };
}
