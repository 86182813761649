/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input } from "@angular/core";
import { AbstractModalComponentWithInputText } from "../abstract-modal.component.with-input-text";

@Component({
   selector: "cancel-confirm-dialog-with-input-text",
   templateUrl: "./cancel-confirm-dialog-with-input-text.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class CancelConfirmDialogWithInputTextComponent extends AbstractModalComponentWithInputText {
   @Input() public title;
   @Input() public message;
   @Input() public buttonLabelConfirm?;
   @Input() public buttonLabelCancel?;
}
