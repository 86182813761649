/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * timerCtrlCommand.js --
 *
 *      API to timer for webClient
 *
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import Logger from "../../../core/libs/logger";
import Router from "../controllers/router";
import IdleTimeoutTimerController from "../timer/idleTimeoutTimerController";

export default function TimerCtrlCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param timerCtrlAction[in]
 *        - its property 'method' has the value 'TimerCtrl'
 */
TimerCtrlCommand.prototype.execute = function (timerCtrlAction) {
   let idleTimerObject = globalArray["idle-timeout-timer"],
      idleTimeout,
      userActivityInterval,
      lastUserActiveTime,
      actionType = timerCtrlAction.type,
      initType = timerCtrlAction.initType,
      router;

   if (timerCtrlAction.bypassApplicationVersionCheck === "true") {
      if (!router) {
         Logger.info("router is inited.");
         router = new Router();
         globalArray[router.name] = router;
      } else {
         Logger.error("router has already inited.");
      }
      router.setBrokerUrl(timerCtrlAction.brokerUrl);
   }
   if (!util.brokerSupportApplication()) {
      return;
   }
   if (actionType === "InitTimer") {
      idleTimeout = timerCtrlAction.idleTimeout;
      userActivityInterval = timerCtrlAction.userActivityInterval;
      if (!idleTimerObject) {
         idleTimerObject = new IdleTimeoutTimerController();
         idleTimerObject.init(userActivityInterval, idleTimeout, initType);
         globalArray[idleTimerObject.globalName] = idleTimerObject;
         idleTimerObject.start();
      } else {
         Logger.debug("warning: re-init timer for Web Client reused-session unlocked");
         idleTimerObject.ensureStopped();
         idleTimerObject.reset(initType);
         idleTimerObject.start();
      }
   } else {
      if (!idleTimerObject) {
         Logger.error("timer do not exist when JSCDK receive a non-init request");
         return;
      }
      lastUserActiveTime = timerCtrlAction.lastUserActiveTime;
      if (actionType === "stopTimer") {
         idleTimerObject.stop();
      } else if (actionType === "sendLastUserActiveTime") {
         idleTimerObject.setLastUserActiveTime(lastUserActiveTime, true, false); //only sendXML
      } else if (actionType === "syncWithTimer4AboutToTimeout" || actionType === "syncWithTimer4Timeout") {
         idleTimerObject.setUITimeForCompare(lastUserActiveTime);
         idleTimerObject.requestBrokerTimeForCompareFor(actionType);
      } else if (actionType === "showAboutToTimeoutDialogCallback") {
         idleTimerObject.setLastUserActiveTime(lastUserActiveTime, true, true); //refresh
         // timer
         // and
         // sendXML
      } else {
         Logger.error("TimerCtrlCommand received a undefined request");
      }
   }
};
