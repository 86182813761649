/**
 * ******************************************************
 * Copyright (C) 2018-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * multi-monitor-chrome.service.ts --
 *
 * Only used in ChromeClient, but keep it in the wrong folder to reduce
 * change.
 *
 *
 * could reuse more logics to simplify logic and remove the added events.
 */
import Logger from "../../../core/libs/logger";
import { Injectable, Optional } from "@angular/core";
import { NormalizationService } from "../../utils/normalization-service";
import { FeatureConfigs } from "../../common/model/feature-configs";
import { ClientSettingModel } from "../../common/model/client-setting-model";
import { FullscreenService } from "../../utils/fullscreen-service";
import { isTooLargeMonitor } from "@html-core";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { RemoteappBorderService } from "../../../chrome-client/desktop/remoteapp/app-util/remoteapp-border.service";
import { BusEvent, EventBusService } from "../../../core/services/event";
import { ConnectedMessageService } from "../../../chrome-client/base/service/connected-message.service";
import { Port } from "../../../chrome-client/base/model/rx-bridge-type";
import { DISPLAY, MonitorInfoService } from "../../common/service/monitor-info.service";
import { MonitorManageService } from "../multimon/main-page/monitor-manage.service";
import { clientUtil } from "../../../core/libs";

@Injectable()
export class MultiMonitorChromeService {
   private hasTooLargeMonitor = false;
   private errorDialogId: string = null;
   private killSwitchOn: boolean = true;
   private wmksService = null;
   private agentDPI: number = null;
   private _hasTooLargeMonitor: boolean = false;
   private _hasAdjacentMonitor: boolean = false;
   // whether already in multimon
   private _multimonEntered = false;
   private clientSetting: any = {};
   private _sessionId;
   private _isApplication: boolean;
   private _focusEventBinded: boolean = false;
   private _multimonAliveInterval = null;
   private _multimonAliveIntervalTime = 1000;
   private _lastReopenTime = 0;

   constructor(
      private normalizationService: NormalizationService,
      private featureConfigs: FeatureConfigs,
      private clientSettingModel: ClientSettingModel,
      private fullscreenService: FullscreenService,
      private modalDialogService: ModalDialogService,
      private eventBusService: EventBusService,
      private connectedMessageService: ConnectedMessageService,
      @Optional()
      private _remoteappBorderService: RemoteappBorderService,
      private _monitorManageService: MonitorManageService,
      private _monitorInfoService: MonitorInfoService
   ) {
      if (FeatureConfigs) {
         this.featureConfigs.registerListener("KillSwitch-MultiMonitor", this.setKillSwitch);
      }
   }
   public setWmksService = (service) => {
      this.wmksService = service;
   };

   /**
    * Detect whether has abnormal large monitor caused by agent status
    */
   private _checkTooLargeMonitor = (content) => {
      const agentScale = this.agentDPI;
      const deviceFactor = !this.clientSettingModel.getBooleanItem("enableHighResolution") ? devicePixelRatio : 1;
      const factor = agentScale / deviceFactor;
      if (this.wmksService.isCurrentSessionPerMonitorDPI()) {
         return false;
      }
      Logger.info("agentScale is :" + agentScale, Logger.DISPLAY);
      for (const key in content) {
         if (content.hasOwnProperty(key)) {
            if (isTooLargeMonitor(content[key].settings, factor)) {
               return true;
            }
         }
      }
      return false;
   };

   public isMultimonEnabled = () => {
      this.hasTooLargeMonitor = this._checkTooLargeMonitor(this.clientSetting);
      if (this.hasTooLargeMonitor) {
         Logger.info("skip entering multimon for large monitors", Logger.DISPLAY);
         if (!this._isApplication) {
            this._showErrorMessage("MM_HAS_TOO_LARGE_MONITOR");
         }
      } else {
         if (this._isApplication) {
            this._checkEnterMultiMode();
         } else {
            // check if any desktop is running in multi moniotr mode.
            Logger.info("Send checkAnyInMultimon", Logger.DISPLAY);
            this._sendCheckAnyInMultimon();
         }
      }
   };
   /**
    * not apply the conflict monitor check here, since it's abnormal for user to
    * move browser into the selected screen manually while entering multimon.
    */
   private _updateScreen = (content) => {
      this._hasTooLargeMonitor = this._checkTooLargeMonitor(content);
      this._hasAdjacentMonitor = this._monitorInfoService.hasAdjacentRect(this.clientSetting);
   };

   public _checkEnterMultiMode = () => {
      Logger.info("now entering multimon mode", Logger.DISPLAY);
      if (this._multimonEntered) {
         Logger.info("can't enter multimon if already in it", Logger.DISPLAY);
         return;
      }
      const uiCallback = (type, data) => {
         switch (type) {
            case "onMonitorChanged":
               Logger.info("onMonitorChanged" + JSON.stringify(data), Logger.DISPLAY);
               this._updateScreen(data.settings);
               if (data.allReady) {
                  if (this._multimonEntered) {
                     return;
                  }
                  if (this._hasTooLargeMonitor) {
                     Logger.info(
                        "stop entering multimon due to select" + "too large monitors after display scaling",
                        Logger.DISPLAY
                     );
                     this._showErrorMessage("MM_HAS_TOO_LARGE_MONITOR");
                  } else if (!this._hasAdjacentMonitor) {
                     Logger.info(
                        "stop entering multimon due to not adjacent monitors after display scaling",
                        Logger.DISPLAY
                     );
                     this._showErrorMessage("MM_NONADJACENT_MONITOR");
                  } else {
                     this.enterMultimon();
                  }
               }
               break;
            case "onMultiMonitor":
               this.wmksService.disconnectEventConnections();
               break;
            case "onSingleMonitor":
               // Unbind the key and mouse event first, or sometimes it will bind multi times
               this.wmksService.disconnectEventConnections();
               this.wmksService.recoveryEventConnections();
               this._multimonEntered = false;
               break;
            case "onQuitMultimon":
               this.wmksService.changeMonitorStatus(false);
               this._multimonEntered = false;
               break;
            case "onEnterMultimon":
               Logger.info("UI is changed for entering Multimon", Logger.DISPLAY);
               break;
            case "onInvalidTopology":
               Logger.info("skip entering multimon for invalid monitor topology", Logger.DISPLAY);
               this._showErrorMessage("MM_INVALID_DISPLAY_TOPOLOGY");
               break;
            default:
               Logger.error("unknown message from multimon", Logger.DISPLAY);
         }
      };

      if (this._isApplication) {
         this._remoteappBorderService && this._remoteappBorderService.enterMultimonMode();
      } else {
         // notice other desktops that this desktop has been in mutlimon mode.
         this.connectedMessageService.sendMessage(this._sessionId, Port.ChannelName.Settings, {
            type: Port.SettingMsg.enterMultimon
         });
         this.fullscreenService.enterFullscreen();
      }
      this.wmksService.preparingForMultimon();

      if (!chrome.runtime.onConnect.hasListener(this.wmksService.onExtendConnect)) {
         chrome.runtime.onConnect.addListener(this.wmksService.onExtendConnect);
      }

      for (let i = 0; i < this.clientSetting.length; i++) {
         const setting = this.clientSetting[i].settings;
         // skip main window
         if (clientUtil.isInCurrentMonitor(setting.x, setting.y, setting.width, setting.height)) {
            continue;
         }
         setting.isForApplication = this._isApplication;
         this.wmksService.addMonitor(uiCallback, setting);
      }
   };

   /**
    * Enter multimon by applying DPI and switch mode
    */
   public enterMultimon = () => {
      if (this._multimonEntered) {
         Logger.info("can't enter multimon if already in it", Logger.DISPLAY);
         return;
      }
      const enableHighResolution = this.clientSettingModel.getBooleanItem("enableHighResolution");
      const displayScaleDisabled = this.wmksService.isCurrentSessionPerMonitorDPI();

      this.normalizationService.setAgentDPI(this.agentDPI);
      this.normalizationService.setDPISync(!enableHighResolution, displayScaleDisabled);
      this._multimonEntered = true;

      // send keepalive message
      this._multimonAliveInterval = setInterval(() => {
         this.connectedMessageService.sendMessage(this._sessionId, Port.ChannelName.Settings, {
            type: Port.SettingMsg.multimonAlive
         });
      }, this._multimonAliveIntervalTime);

      setTimeout(() => {
         this.wmksService.enterMultimon();
      }, 5000);
   };

   private _chkMonitor = () => {
      this.clientSetting = this.clientSettingModel.getStringItem("selectedMonitors");
      if (!this.clientSetting || (this.clientSetting && this.clientSetting.length < 2)) {
         const displaySetting = this.clientSettingModel.getPrefNumberItem("displaySetting");
         if (displaySetting === DISPLAY.DisplayOption.ALL && !this._isApplication) {
            this.fullscreenService.enterFullscreen();
         }
         Logger.info("Only one monitor is seleted, don't enter multi monitor mode.", Logger.DISPLAY);
         return;
      }
      Logger.info("Prepare Displays For Multimon " + this._sessionId + " with DPI " + this.agentDPI, Logger.DISPLAY);

      // get multimonitor capacity directly or can't get the value again
      if (!this.wmksService.hasMultimonCapacity()) {
         Logger.info("not enter multimon for Agent Cap of Multimon is not true", Logger.DISPLAY);
         return;
      } else {
         Logger.info("enter multimon for Chrome Client for session " + this._sessionId, Logger.DISPLAY);
         Logger.info("Monitor Setting is: " + JSON.stringify(this.clientSetting), Logger.DISPLAY);
         this.isMultimonEnabled();
      }
   };

   private _sendCheckAnyInMultimon = () => {
      this.connectedMessageService.sendMessage(this._sessionId, Port.ChannelName.Settings, {
         type: Port.SettingMsg.checkAnyInMultimon
      });
   };

   public prepareDisplaysForMultimon = (sessionId, agentDPI, isActive?: any) => {
      this._sessionId = sessionId;
      this.agentDPI = agentDPI;

      const currentSession = this.wmksService.getCurrentSession();
      this._isApplication = currentSession.isApplicationSession;

      if (!this._focusEventBinded && !this._isApplication) {
         // track which desktop is focused, the focused desktop will enter multimon when extended monitors change
         window.addEventListener("focus", () => {
            this._focusEventBinded = true;
            this.connectedMessageService.sendMessage(this._sessionId, Port.ChannelName.Settings, {
               type: Port.SettingMsg.desktopFocused
            });
         });
      }

      // listen message to check whether can enter multi monitor mode
      this.eventBusService.listen("checkAnyInMultimon").subscribe((msg) => {
         Logger.info("Receive checkAnyInMultimon message, value is " + msg.data);
         // no desktop in multimon
         if (!msg.data) {
            this._checkEnterMultiMode();
         }
      });

      // listen extended monitors' change
      this.eventBusService.listen("selectedMonitors").subscribe((msg) => {
         if (msg.data.isApplicationSession === this._isApplication) {
            Logger.info("Receive new selected monitors", Logger.DISPLAY);
            this.clientSettingModel.updateSetting("selectedMonitors", msg.data.selectedMonitors);
            if (msg.data.isApplicationSession) {
               this._multimonEntered ? this.reOpenAndDoSth() : this._chkMonitor();
            } else if (
               msg.data.shouldEnterMultimonSessionKey === null ||
               msg.data.shouldEnterMultimonSessionKey === this._sessionId
            ) {
               // already in multimon mode, reenter
               if (this._multimonEntered) {
                  this.reOpenAndDoSth();
               } else {
                  if (!this.clientSetting || this.clientSetting.length < 2) {
                     this._chkMonitor();
                  }
               }
            }
         }
      });
      this._chkMonitor();
   };

   public reOpenAndDoSth = (doSth?: Function) => {
      const now = Date.now();
      const waitTime = 3000;
      // add a time to avoid calling this function many times in 3s (like add and remove monitor quickly)
      if (now - this._lastReopenTime > waitTime) {
         this._lastReopenTime = now;
         // exit multi without dialog
         this._monitorManageService.switchToSingleMonitorWithoutQuitDialog();
         if (typeof doSth === "function") {
            doSth();
         }
         // enter multimon mode again
         setTimeout(this._chkMonitor, waitTime);
      }
   };

   // used in topbar.component.ts to check whether show enter multimon button
   public canEnterMultimon = () => {
      this.clientSetting = this.clientSettingModel.getStringItem("selectedMonitors");
      if (!this.clientSetting || (this.clientSetting && this.clientSetting.length < 2)) {
         return false;
      }
      if (this.wmksService && !this.wmksService.hasMultimonCapacity()) {
         return false;
      }
      return true;
   };

   private _resetStatus = () => {
      this.wmksService.quitMultimon();
      this.fullscreenService.exitFullscreen();
   };

   private _showErrorMessage = (key) => {
      this._resetStatus();
      if (this.modalDialogService.isDialogOpen(this.errorDialogId)) {
         this.modalDialogService.close(this.errorDialogId);
      }

      this.errorDialogId = this.modalDialogService.showError({
         data: {
            titleKey: "WARNING",
            contentKey: key
         }
      });
   };

   /**
    * @param {Boolean}
    */
   private setKillSwitch = (switchOn) => {
      this.killSwitchOn = switchOn;
   };

   public quitMultimon = () => {
      this._resetStatus();
   };
}
