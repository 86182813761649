/**
 * ******************************************************
 * Copyright (C) 2014-2021, 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { BaseViewComponent, BaseViewComponentInterface } from "../../../shared/view/base-view.component";
import { clientUtil, PLATFORM_ARCH, TranslateService } from "@html-core";
import { Component, ChangeDetectorRef } from "@angular/core";
import { UtilService } from "../../../shared/launcher/common/util-service";
import { ViewClientModel } from "../../../shared/common/model/viewclient-model";
import { ViewService } from "../../../shared/launcher/common/view-service";
import { imageAsset } from "../../../shared/common/image-asset/image-asset";

@Component({
   selector: "home-warning",
   templateUrl: "./home-warning.component.html"
})
export class HomeWarningComponent extends BaseViewComponent implements BaseViewComponentInterface {
   public helpLinkAddress: string;
   public nativeInstallerLink: string;
   public showCookieWarning: boolean;
   public continueAllowed: boolean;
   public isMobileBrowser: boolean;
   public logoAltText: string;
   public imageAsset = imageAsset;
   public onProcessing: boolean = false;
   public isWinARM: boolean = false;

   constructor(
      changeDetector: ChangeDetectorRef,
      private utilService: UtilService,
      private viewClientModel: ViewClientModel,
      private viewService: ViewService,
      private translate: TranslateService
   ) {
      super(changeDetector, "Warning");
      this.logoAltText = this.translate._T("horizon_logo");
      if (this.viewClientModel.rebrandName && !clientUtil.isChromeClient()) {
         this.logoAltText = this.viewClientModel.rebrandName + " " + this.translate._T("LOGO");
      }
   }
   renderData = () => {
      const browserCompatible = this.utilService.isCompatibleBrowser(),
         cookieEnabled = this.utilService.isCookieEnabled(),
         canvasSupported = this.utilService.hasCanvasAndContext();

      const HTMLAccessArchType = this.utilService.HTMLAccessArch;

      if (HTMLAccessArchType === PLATFORM_ARCH.ARM && this.viewClientModel.osModel.mIsWin64) {
         this.isWinARM = true;
      }

      this.helpLinkAddress = this.utilService.getLinkByType("helpPages");
      // Name of the controller.

      // The browser is compatible but the cookie is disabled, show
      // cookie warning msg.
      this.showCookieWarning = browserCompatible && canvasSupported && !cookieEnabled;
      // Allow to use web client if cookie is enabled and canvas is
      // supported.
      this.continueAllowed = cookieEnabled && canvasSupported;
      // Display mobile layout on Android and Windows Phone.
      this.isMobileBrowser = this.viewClientModel.mIsAndroid || this.viewClientModel.osModel.mIsWinMobile;
      // Native client installer URL
      this.nativeInstallerLink = this.viewClientModel.nativeInstallerLink;
   };

   public cancel = () => {
      window.location.href = this.viewClientModel.nativeInstallerLink;
   };

   public goNext = () => {
      this.onProcessing = true;
      this.viewService.switchView({
         action: "Brokers",
         controller: this,
         data: null
      });
   };
}
