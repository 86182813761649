/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * GetApplicationSessionConnectionRedirectHandler.js--
 *
 *      inherited Class to handle <get-application-session-connection-redirect>
 */

import RedirectHandler from "./redirectHandler";

export default function GetApplicationSessionConnectionRedirectHandler() {
   this.requestTag = "get-application-session-connection-redirect"; // the XML request tag name
   this.responseTag = "application-session-connection-redirect"; // the XML response tag name
}

// inherits RedirectHandler prototype
GetApplicationSessionConnectionRedirectHandler.prototype = new RedirectHandler();

// constructor
GetApplicationSessionConnectionRedirectHandler.constructor = GetApplicationSessionConnectionRedirectHandler;
