/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { RemoteUsbDevice } from "../../usb-remote";

export abstract class PathosisDevice {
   protected remoteUsb: RemoteUsbDevice = null;
   constructor(usb: RemoteUsbDevice) {
      this.remoteUsb = usb;
   }
   public abstract getDeviceDescriptor(): ArrayBuffer;
   public abstract handleUrbMessage(vUrb, packet, callback): boolean;
}
