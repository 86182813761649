/**
 * *****************************************************
 * Copyright 2021 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";

@NgModule({
   declarations: [],
   providers: []
})
export class WebBaseModule {}
