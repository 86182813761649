/**
 * ******************************************************
 * Copyright (C) 2016-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export class MultiSiteService {
   /**
    * In normal case, this function will redirect client to the new server.
    * However, if the params are illegal, the function will return, and
    * client continues the previous action.
    *
    * @param redirectProperties
    */
   public static redirectAndStartSession = (redirectProperties) => {
      const serverAddress = redirectProperties["server-address"],
         desktopName = redirectProperties["desktop-name"],
         SAMLart = redirectProperties["saml-art"],
         desktopProtocol = redirectProperties["desktop-protocol"];

      if (!serverAddress || !desktopName || !SAMLart || desktopProtocol.toLowerCase() !== "blast") {
         // Shared module doesn't have logger
         throw "Redirect properties are not working!";
      }

      /**
       * DaaS desktop URL format:
       * https://ip or domain/appblast/webclient/index.html
       * https://ip or domain/appblast/desktop/index.html
       *
       * We don't get desktop ID in this case, so we use desktop name.
       */
      window.document.location.href =
         "https://" +
         serverAddress +
         "/appblast/webclient/index.html?" +
         "desktopName=" +
         encodeURIComponent(desktopName) +
         "&SAMLart=" +
         encodeURIComponent(SAMLart) +
         "&action=start-session";
   };
}
