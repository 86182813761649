/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 *------------------------------------------------------------------------------
 *
 * tsdr-channel.service.ts
 *
 * used to maintain the channel for tsdr client
 *
 *------------------------------------------------------------------------------
 */
import { Injectable, Inject } from "@angular/core";
import { IVDPServiceListener, VDPChannel, VDPService } from "../vdpservice";
import Logger from "../../../core/libs/logger";
import { RdpdrChannelManager } from "./rdpdr-channel-manager";

@Injectable()
class tsdrChannel implements IVDPServiceListener {
   static readonly tsdrPrefix: string = "RPC#tsdr#";
   static readonly objectNames = ["tsdrControlObj", "tsdrDataObj"];

   private loggerService = new Logger(Logger.CDR);

   tsdrDataObj: any = null;
   rdpdrManager: any = null;
   vdpChannel: VDPChannel = null;
   tsdrChannelService: any = null;
   msgHandlers: any[] = [];

   constructor(vdpService: VDPService, @Inject("tsdrChannelService") tsdrChannelService: any) {
      this.tsdrChannelService = tsdrChannelService;
      vdpService.addChannelCreatedListener((channel: VDPChannel) => {
         if (channel.name.indexOf(tsdrChannel.tsdrPrefix) === 0) {
            this.loggerService.debug("connect channel to " + channel.name);
            const ret = vdpService.connectChannel(channel, tsdrChannel.objectNames);
            if (ret) {
               this.loggerService.debug("Successfully accepted tsdr channel");
            } else {
               this.loggerService.error("Failed to open tsdr channel");
               return;
            }
            this.vdpChannel = channel;
            channel.addCallback(this);
         }
      });
   }

   public onReady = (object?: { id: number; name: string }): void => {
      this.loggerService.info("tsdr channel is ready for traffic " + object.name);
      if (object.name === tsdrChannel.objectNames[0]) {
         this.loggerService.trace("tsdr data channel is ready for traffic.");
      } else if (object.name === tsdrChannel.objectNames[1]) {
         this.tsdrDataObj = object;
         this.loggerService.trace("tsdr control channel is ready for traffic.");
         const rdpdrManager = this.tsdrChannelService.getRdpdrManager(this.vdpChannel, this.tsdrDataObj);
         this.addMessageHandler(rdpdrManager);
      }
   };

   public onDisconnect = (obj) => {
      this.loggerService.debug("one of the object is disconnected, disconnect manager");
      if (this.rdpdrManager) {
         this.rdpdrManager.onDisconnect();
      }
      this.vdpChannel = null;
   };

   public onInvoke = (tsdr) => {
      this.loggerService.trace("get a tsdr message" + tsdr);
      this.handleTSDRFromServer(tsdr);
   };

   private addMessageHandler = (handler) => {
      this.msgHandlers.push(handler);
   };

   public invoke = (param) => {
      this.loggerService.trace("sending tsdr message" + param);
      return this.vdpChannel.invoke(param);
   };

   private handleTSDRFromServer = (tsdr) => {
      let i, ret;

      for (i = 0; i < this.msgHandlers.length; i++) {
         ret = this.msgHandlers[i].handleTSDRFromServer(tsdr);
         if (ret) {
            // means the message has been consumed
            break;
         }
      }
   };
}

@Injectable()
export class TsdrChannelService {
   constructor(private rdpdrChannelManager: RdpdrChannelManager) {}

   public getRdpdrManager = (tsdrChannel: VDPChannel, dataObj: any) => {
      this.rdpdrChannelManager.init(tsdrChannel, dataObj);
      return this.rdpdrChannelManager;
   };

   public getChannel = (vdpService: VDPService) => {
      return new tsdrChannel(vdpService, this);
   };
}
