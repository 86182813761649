/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 *
 * TODO-NG: during connecting desktop/app, start the timer
 */

import Logger from "../../../core/libs/logger";
import * as CST from "@html-core";
import { Injectable } from "@angular/core";
import { Ws1Service } from "../../common/service/ws1.service";
import { BusEvent, clientUtil, TranslateService } from "@html-core";
import { JumpCacheService } from "../../common/service/jump-cache.service";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { UtilService } from "../common/util-service";
import { EventBusService } from "../../../core/core.module";
import { VmwHorizonLaunchItemType } from "../../../../../SDK/src/lib/model/enum";
import { globalArray } from "../../jscdk/jscdkClient";
import { ConnectionURIModel } from "../../common/model/connection-uri-model";
import { IdleSessionService } from "../../common/service/idle-session.service";

export interface IItemController {
   processURIAction4Desktop(desktop, action);
   processURIAction4App(application, action, args, isFA?);
   processURIAction4AppSession(applicationSession, action);
   processLaunchItem(item);
   switchUserDialog: string;
}

@Injectable()
export class OperationService {
   private _itemCtrl: IItemController = null;
   constructor(
      private utilService: UtilService,
      private jumpCacheService: JumpCacheService,
      private modalDialogService: ModalDialogService,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private translate: TranslateService,
      private ws1Service: Ws1Service,
      private eventBusService: EventBusService,
      private connectionURIModel: ConnectionURIModel,
      private idleSessionService: IdleSessionService
   ) {}

   public checkForLaunching(desktops, applications, applicationSessions, targetActionInfo, shouldClearPreviousAction) {
      if (this.connectionURIModel.isHWSession() && this.idleSessionService.isSessionTimedOut()) {
         this.utilService.authenticationStatusCheck.clearAction("ws1 launch after idleSessionTimeout");
      }
      if (desktops.length > 0 || applications.length > 0) {
         // Handle URI.
         const nextStepFlags = this._handleURI(
            desktops,
            applications,
            applicationSessions,
            targetActionInfo,
            shouldClearPreviousAction
         );
         if (nextStepFlags.canContinuePreviousAction) {
            // continue action.
            const previousAction = this.utilService.authenticationStatusCheck.popAction(this._itemCtrl);
            Logger.info("continue previous action: " + JSON.stringify(previousAction));
            if (previousAction && previousAction.item && previousAction.action) {
               this._handlePreviousAction(desktops, applications, applicationSessions, previousAction);
               this.jumpCacheService.removeCache();
            } else {
               //auto reconnect for self-signed cert on IE, when no URL invoked.
               this.jumpCacheService
                  .readCachedDataFor("certAcceptReturnAddress")
                  .then((cachedData) => {
                     const item = cachedData;
                     this._itemCtrl.processLaunchItem(item);
                  })
                  .catch(() => {});
            }
         } else if (nextStepFlags.shouldClearPreviousAction) {
            this.utilService.authenticationStatusCheck.clearAction("shouldClearPreviousAction");
            this.jumpCacheService.removeCache();
         }
      } else {
         const popErrorMessage = this.translate._T("error_none_desktops");
         this.modalDialogService.showError({
            data: {
               title: this.translate._T("ERROR"),
               content: popErrorMessage
            },
            callbacks: {
               confirm: () => {
                  this.utilService.runFunctionIfNotHWSOrF5(() => {
                     this.jscdkCommonInvoker.logout();
                  });
               }
            }
         });
         this.utilService.authenticationStatusCheck.clearAction("no entitlement");
      }
   }

   /**
    * URI handler
    * return {canContinuePreviousAction: bool,
    * shouldClearPreviousAction: bool}. and if first flag is true, the
    * second one should not be defined, i.e.
    * {canContinuePreviousAction: true}
    */
   private _handleURI = (desktops, applications, applicationSessions, targetActionInfo, shouldClearPreviousAction) => {
      Logger.info(
         "check with URI with shouldClearPreviousAction=" +
            shouldClearPreviousAction +
            ", and action of:" +
            JSON.stringify(targetActionInfo)
      );
      let i,
         length,
         searchedItems = false,
         triggerItemInfo = {};

      if (shouldClearPreviousAction) {
         return {
            canContinuePreviousAction: false,
            shouldClearPreviousAction: true
         };
      }

      /**
       * NOTE:
       * This is for vIDM integration case. See bug 1770485
       *
       * vIDM uses the wrong URI to launch application, e.g.
       * https://pod2-vcs1.blr.trcint.com/portal/webclient/index.html?
       * applicationId=cn%3Dwordpad,ou%3Dapplications,dc%3Dvdi,dc%3Dvmware,
       * dc%3Dint&SAMLart=AAQAAA/Hs7t/WV3jvQAZ6XcIqPsJs0EeMl%2BMImpRKY8RlqOW
       * %2Bhg%2BGCOOGWg%3D&user=idmadmin&domainName=blr.trcint.com&
       * desktopName=WordPad&horizonId=184a3bbe-aa23-49ce-8372-857c0abb44ae
       * #/launchitems
       *
       * This URI contains applicationId and desktopName at the same time.
       * In our early code, desktopName is not supported. So the launch can
       * work. However, we introduce desktopName as a new parameter in 16q4,
       * which cause vIDM cannot launch application.
       *
       * As vIDM already releases at this time (2016-11-24), we have to
       * provide a workaround for them.
       *
       * Above all, we still first search the desktop list and then
       * the application list.
       * if none can be found in both list, we will then prompt a dialog.
       *
       * If we don't even search the list (while there is no desktop or
       * application info), we will still use the old code to return.
       */
      if (
         !!targetActionInfo &&
         (!!targetActionInfo.desktopId ||
            !!targetActionInfo.desktopName ||
            !!targetActionInfo.collabSessionId ||
            !!targetActionInfo.itemId) &&
         !!desktops
      ) {
         /**
          * Ignore itemId for searching HTML access for now.
          * keep consistent with previous logic, although it's inaccurate
          */
         if (!clientUtil.isChromeClient()) {
            searchedItems =
               !!targetActionInfo.desktopId || !!targetActionInfo.desktopName || !!targetActionInfo.collabSessionId;
         } else {
            searchedItems = true;
            /**
             * Add triggerItemInfo for all launch, it's for sdk
             * for bug 3161107 VCART-483
             * targetActionInfo.desktopId is the name showed in launch panel
             */
            triggerItemInfo = {
               type: "HorizonDesktop",
               id: undefined,
               triggerName: targetActionInfo.desktopId
            };
         }

         for (i = 0, length = desktops.length; i < length; i++) {
            const desktop = desktops[i];
            if (
               CST.ignoreCaseEquals(desktop.id, targetActionInfo.desktopId) ||
               CST.ignoreCaseEquals(desktop.name, targetActionInfo.desktopId) ||
               CST.ignoreCaseEquals(desktop.name, targetActionInfo.desktopName) ||
               CST.ignoreCaseEquals(desktop.id, targetActionInfo.collabSessionId) ||
               (clientUtil.isChromeClient() &&
                  (CST.ignoreCaseEquals(desktop.id, targetActionInfo.itemId) ||
                     CST.ignoreCaseEquals(desktop.name, targetActionInfo.itemId)))
            ) {
               this.handleUrlRedirection(desktop, targetActionInfo, false);
               // Process URI action.
               this._itemCtrl.processURIAction4Desktop(desktop, targetActionInfo.action);
               // delete desktopId to avoid further usage, can't clear
               // all the params for bug 1450429.
               targetActionInfo.desktopId = null;
               // help to fix a logic defect introduced when songlil
               // added the desktopName support, or the desktopName
               // might get reused later if launch is canceled
               targetActionInfo.desktopName = null;

               targetActionInfo.collabSessionId = null;
               targetActionInfo.itemId = null;
               return {
                  canContinuePreviousAction: false,
                  shouldClearPreviousAction: false
               };
            }
         }
      }

      if (
         !!targetActionInfo &&
         (!!targetActionInfo.applicationId || !!targetActionInfo.applicationName || !!targetActionInfo.itemId) &&
         !!applications
      ) {
         /**
          * Ignore itemId for searching HTML access for now.
          * keep consistent with previous logic, although it's inaccurate
          */
         if (!clientUtil.isChromeClient()) {
            searchedItems = !!targetActionInfo.applicationId || !!targetActionInfo.applicationName;
         } else {
            searchedItems = true;
            /**
             * Add triggerItemInfo for all launch, it's for sdk
             * for bug 3161107 VCART-483
             * targetActionInfo.applicationId is the name showed in launch panel
             */
            triggerItemInfo = {
               type: "HorizonAPP",
               id: targetActionInfo.applicationId,
               triggerName: targetActionInfo.applicationId
            };
         }

         for (i = 0, length = applications.length; i < length; i++) {
            const application = applications[i];
            if (
               CST.ignoreCaseEquals(application.id, targetActionInfo.applicationId) ||
               CST.ignoreCaseEquals(application.name, targetActionInfo.applicationId) ||
               CST.ignoreCaseEquals(application.name, targetActionInfo.applicationName) ||
               (clientUtil.isChromeClient() &&
                  (CST.ignoreCaseEquals(application.id, targetActionInfo.itemId) ||
                     CST.ignoreCaseEquals(application.name, targetActionInfo.itemId)))
            ) {
               this.handleUrlRedirection(application, targetActionInfo, true);
               this._itemCtrl.processURIAction4App(
                  application,
                  targetActionInfo.action,
                  targetActionInfo.args,
                  targetActionInfo.isFA
               );
               // delete applicationId, applicationName and args to
               // avoid further usage.
               targetActionInfo.applicationId = null;
               targetActionInfo.itemId = null;
               targetActionInfo.applicationName = null;
               targetActionInfo.args = null;
               return {
                  canContinuePreviousAction: false,
                  shouldClearPreviousAction: false
               };
            }
         }
      }

      if (!!targetActionInfo && !!targetActionInfo.itemId && !!applicationSessions && clientUtil.isChromeClient()) {
         /**
          * Ignore itemId for searching HTML access for now.
          * allow chrome client to accept itemIf to connect app session.
          */
         searchedItems = true;

         for (i = 0, length = applicationSessions.length; i < length; i++) {
            const applicationSession = applicationSessions[i];
            if (
               clientUtil.isChromeClient() &&
               (CST.ignoreCaseEquals(applicationSession.id, targetActionInfo.itemId) ||
                  CST.ignoreCaseEquals(applicationSession.sessionId, targetActionInfo.itemId))
            ) {
               this._itemCtrl.processURIAction4AppSession(applicationSession, targetActionInfo.action);
               // delete itemId to avoid later use
               targetActionInfo.itemId = null;
               return {
                  canContinuePreviousAction: false,
                  shouldClearPreviousAction: false
               };
            }
         }
      }

      if (searchedItems) {
         Logger.trace(
            "failed to match target to existing items:" +
               JSON.stringify({ targetActionInfo, desktops, applications, applicationSessions }),
            Logger.UI
         );
         const type = !!targetActionInfo && !!targetActionInfo.desktopId;
         this.eventBusService.dispatch(
            new BusEvent.ItemLaunchFailed({
               itemType: type ? <VmwHorizonLaunchItemType>"HorizonDesktop" : <VmwHorizonLaunchItemType>"HorizonApp",
               launchItemId: type ? targetActionInfo.desktopId : targetActionInfo.applicationId,
               errorMessage: this.translate._T("error_item_not_found"),
               clientData: {
                  triggerItemInfo: triggerItemInfo
               }
            })
         );

         // Show error dialog.
         this.modalDialogService.showError({
            data: {
               titleKey: "ERROR",
               contentKey: "error_item_not_found",
               contentSubstitutionsKey:
                  targetActionInfo.desktopId ||
                  targetActionInfo.desktopName ||
                  targetActionInfo.applicationId ||
                  targetActionInfo.applicationName ||
                  targetActionInfo.collabSessionId
            },
            callbacks: {
               confirm: () => {
                  let callBack = null;
                  if (this.ws1Service.isWS1Mode() && clientUtil.isChromeClient()) {
                     callBack = chrome.app.window.getAll().forEach((window) => {
                        window.close();
                     });
                  }

                  // To fix bug 3074566, ignore the error dialog when meet not found error. don't logout and close the tab
                  if (
                     this.connectionURIModel.isHWSession() &&
                     !this.ws1Service.isWS1Mode() &&
                     !clientUtil.isChromeClient()
                  ) {
                     return;
                  } else {
                     this.utilService.runFunctionIfNotHWSOrF5(null);
                  }
               }
            }
         });
         return {
            canContinuePreviousAction: false,
            shouldClearPreviousAction: true
         };
      }

      return {
         canContinuePreviousAction: true
      };
   };

   /**
    * add this function cause the desktop object for display is not
    * the same before and after the reauth. And whether the action can
    * be processed now is also unknown, so check the validation of
    * action again, even some times ago it's valid.
    *
    * should be old broker compatible cause the applications will be
    * empty, and this function should never be involved.
    *
    * the itemType is just in case application and desktop have same
    * id, could be added in the action string, but I choose not to for
    * easy to be understanded.
    */
   private _handlePreviousAction = (desktops, applications, applicationSessions, previousAction) => {
      let previousTargetItem = previousAction.item,
         action = previousAction.action,
         itemType = previousAction.itemType,
         args,
         targetItemId,
         i,
         length,
         desktop,
         application;

      if (!previousTargetItem || !action || !itemType) {
         return;
      }
      if (itemType === "application") {
         previousTargetItem = previousTargetItem.application;
         args = previousTargetItem.args;
      }

      targetItemId = previousTargetItem.id;
      if (!targetItemId) {
         Logger.debug("error: cann't find the id in previousAction Object");
         return;
      }

      //search desktops
      if (!!desktops && itemType === "desktop") {
         for (i = 0, length = desktops.length; i < length; i++) {
            desktop = desktops[i];
            if (CST.ignoreCaseEquals(desktop.id, targetItemId)) {
               this._itemCtrl.processURIAction4Desktop(desktop, action);
               return;
            }
         }
      }
      //search applications
      if (!!applications && itemType === "application") {
         for (i = 0, length = applications.length; i < length; i++) {
            application = applications[i];
            if (CST.ignoreCaseEquals(application.id, targetItemId)) {
               this._itemCtrl.processURIAction4App(application, action, args);
               return;
            }
         }
      }
      //search application sessions
      if (!!applicationSessions && itemType === "applicationSession") {
         for (i = 0, length = applicationSessions.length; i < length; i++) {
            const applicationSession = applicationSessions[i];
            if (CST.ignoreCaseEquals(applicationSession.id, targetItemId)) {
               this._itemCtrl.processURIAction4AppSession(applicationSession, action);
               return;
            }
         }
      }
      // not found target
      Logger.warning("can't find the target of previous action, please check with system admin");
   };

   public setItemController = (item: IItemController) => {
      this._itemCtrl = item;
   };

   /**
    * Handle launch with URL redirection
    */
   private handleUrlRedirection = (item, urlParams, isApplication: boolean) => {
      if (
         clientUtil.isChromeClient() &&
         urlParams?.url &&
         (!urlParams.launchType || urlParams.launchType === "UrlRedirection")
      ) {
         if (isApplication) {
            urlParams.args = [urlParams.url];
         } else {
            globalArray["browseURL"] = {
               id: item.id,
               url: urlParams.url
            };
         }
      }
   };
}
