/**
 * ******************************************************
 * Copyright (C) 2017-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * commonSvcService.js --
 *
 * The service to communicate with server in main channel
 *
 * Server code: bfg-main
 *    depot/bora/bfg-main/apps/rde/rdeSvc/server/commonSvc.cpp
 *    depot/bora/bfg-main/apps/rde/rdeSvc/server/commonSvc.h
 *    depot/bora/bfg-main/apps/rde/rdeSvc/shared/commonSvc_defines.h
 *
 * Once the connection established, server pushes the DPI message version.
 * After that, the communication protocol would follow
 *   https://wiki.eng.vmware.com/EUCBU/CART/DpiSync#Relationship_between_DPI_Sync_feature_and_.22Enable_display_scaling.22_feature
 *
 *
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { ICommonSvcChannelCb, CommonSvcChannel, DisplayTopologyBriefInfo } from "./commonSvcChannel";
import { clientUtil, EventBusService, BusEvent } from "@html-core";
import { ClientSettingModel } from "../../common/model/client-setting-model";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { BlastWmks } from "../common/blast-wmks.service";

@Injectable({
   providedIn: "root"
})
export class CommonSvcService implements ICommonSvcChannelCb {
   static readonly LOGFX: string = "[commonSvcService] ";

   private inited: boolean;
   comSvcMgrMap: Map<string, CommonSvcChannel>;

   constructor(
      private clientSettingModel: ClientSettingModel,
      private remoteSessionEventService: RemoteSessionEventService,
      private eventBusService: EventBusService
   ) {
      this.comSvcMgrMap = new Map<string, CommonSvcChannel>();
      // this.addSignal("DPIDataUpdateCursor");
   }

   public tearDown = () => {
      this.remoteSessionEventService.removeEventListener("sessionConnecting", this.onWmksSessionConnecting);

      this.remoteSessionEventService.removeEventListener("sessionDisconnected", this.onWmksSessionDisconnected);
      this.remoteSessionEventService.removeEventListener("sessionRemoved", this.onWmksSessionRemoved);
   };

   public getTargetDPI = (): number => {
      return this.clientSettingModel.getTargetDPIScale();
   };

   public getCurrentDisplayInfo = (): Promise<Array<DisplayTopologyBriefInfo>> => {
      return new Promise((resolve, reject) => {
         const screenPixels = clientUtil.getWindowPixels();
         const windowPixelWidth = screenPixels[0];
         let windowPixelHeight = screenPixels[1];

         // for bug: VCART-2286
         if (clientUtil.isChromeClient() && !chrome.app.window.current().isFullscreen()) {
            const isApp = chrome.app.window.current().contentWindow.document.title === "Remote Apps";
            // We need to set canvas's height 30px less than window's size because we draw a 30px height topbar
            if (!isApp) {
               windowPixelHeight = Math.round(windowPixelHeight - 30 * devicePixelRatio);
            }
         }

         resolve([
            {
               isPrimary: true,
               monitorDPI: this.getTargetDPI(),
               rect: {
                  left: 0,
                  top: 0,
                  right: windowPixelWidth,
                  bottom: windowPixelHeight
               }
            }
         ]);
      });
   };

   public onRemoteDPI = (
      wmksKey: string,
      remoteScaleDPI: number,
      isDisplayScaleDisabled: boolean,
      changed: boolean
   ) => {
      if (!this.comSvcMgrMap.has(wmksKey)) {
         return;
      }
      const channel: CommonSvcChannel = this.comSvcMgrMap.get(wmksKey);
      Logger.info(
         "use Agent DPI " + remoteScaleDPI + ", isDisplayScaleDisabled = " + isDisplayScaleDisabled,
         Logger.DPI
      );
      channel.wmksSession.setRemoteDPI(remoteScaleDPI, isDisplayScaleDisabled);
      channel.wmksSession.wmks("option", "agentDPI", remoteScaleDPI);
      channel.wmksSession.checkSessionInMultimon();
      if (changed) {
         this.eventBusService.dispatch(new BusEvent.DPIDataChangeEvent(wmksKey, remoteScaleDPI, channel.wmksSession));
      }
      // for fix bug 3146154
      this.eventBusService.dispatch(new BusEvent.DPIDataSyncSuccessEvent(wmksKey, remoteScaleDPI));
   };

   public sendDisplayInfo = async (wmksKey: string, displayInfo: Array<DisplayTopologyBriefInfo>): Promise<boolean> => {
      if (!wmksKey || !this.comSvcMgrMap.has(wmksKey)) {
         return false;
      }
      return await this.comSvcMgrMap.get(wmksKey).sendDisplayInfo(displayInfo);
   };

   public isDisplayScaleDisabled = (wmksKey: string) => {
      if (!wmksKey || !this.comSvcMgrMap.has(wmksKey)) {
         return false;
      }
      return this.comSvcMgrMap.get(wmksKey).isDisplayScaleDisabled();
   };

   public getRemoteDPI = (wmksKey) => {
      if (!this.comSvcMgrMap.has(wmksKey)) {
         return this.clientSettingModel.getTargetDPIScale();
      }
      return this.comSvcMgrMap.get(wmksKey).getRemoteDPIScale();
   };

   private destroyComSvcMgr = (wmksKey) => {
      if (!this.comSvcMgrMap.has(wmksKey)) {
         return;
      }
      let mgr = this.comSvcMgrMap.get(wmksKey);
      this.comSvcMgrMap.delete(wmksKey);
      mgr = null;
   };

   private onWmksSessionConnecting = (session: BlastWmks) => {
      const defaultDPI = this.clientSettingModel.getTargetDPIScale();
      // Align with other Native client for bypassing bug 2857170
      const enforcePerSystemDPI = session.isApplicationSession && clientUtil.isChromeClient();
      const mgr = new CommonSvcChannel(
         session.key,
         session.mainChannel,
         session,
         defaultDPI,
         true,
         this,
         enforcePerSystemDPI
      );
      this.comSvcMgrMap.set(session.key, mgr);
   };

   private onWmksSessionDisconnected = (session: BlastWmks) => {
      this.destroyComSvcMgr(session.key);
   };

   private onWmksSessionRemoved = (session: BlastWmks) => {
      this.destroyComSvcMgr(session.key);
   };

   public init = () => {
      if (this.inited) {
         return;
      }
      this.inited = true;

      Logger.debug(CommonSvcService.LOGFX + "init commonSvc events");
      this.remoteSessionEventService.addEventListener("sessionConnecting", this.onWmksSessionConnecting);

      this.remoteSessionEventService.addEventListener("sessionDisconnected", this.onWmksSessionDisconnected);

      this.remoteSessionEventService.addEventListener("sessionRemoved", this.onWmksSessionRemoved);
   };
}
