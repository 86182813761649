/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";

import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { APP_BASE_HREF } from "@angular/common";

const routes: Routes = [];
const routeOption: ExtraOptions = {
   useHash: true,
   initialNavigation: "disabled"
};

@NgModule({
   imports: [RouterModule.forRoot(routes, routeOption)],
   exports: [RouterModule],
   providers: [{ provide: APP_BASE_HREF, useValue: "/" }]
})
export class AppRoutingModule {}
