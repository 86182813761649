/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * logoutBrokerBrokerCommand.js --
 *
 * Logout from broker.
 *
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import Logger from "../../../core/libs/logger";
import DoLogoutHandler from "../controllers/doLogoutHandler";
import SetUserGlobalPrefHandler from "../controllers/setUserGlobalPrefHandler";
import Router from "../controllers/router";
import BrokerSessionTimeoutTimerController from "../timer/brokerSessionTimeoutTimerController";

export default function LogoutBrokerCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param logoutBrokerAction[in]
 *        - its property 'method' has the value 'LogoutFromBroker'
 */
LogoutBrokerCommand.prototype.execute = function (logoutBrokerAction) {
   let handlerList,
      logoutBrokerObject = globalArray["do-logout"],
      router = globalArray["router"],
      idleTimerObject,
      setPrefHandler = globalArray["set-user-global-preferences"],
      brokerSessionTimeoutTimerController = globalArray["broker-session-timeout-timer"];

   const checkExist = function (handler) {
      return handler !== undefined;
   };

   if (!router) {
      Logger.info("router is null.");

      router = new Router();
      globalArray[router.name] = router;
   }

   // this code is added here instead of in onUpdate() is for avoid timer
   // triggered after broker just log off and returning response
   if (util.brokerSupportApplication()) {
      idleTimerObject = globalArray["idle-timeout-timer"];
      if (!idleTimerObject) {
         Logger.debug("timer don't exist when log out");
      } else {
         idleTimerObject.stop();
      }
   }

   if (logoutBrokerAction.prefData) {
      if (!setPrefHandler) {
         setPrefHandler = new SetUserGlobalPrefHandler();
         globalArray[setPrefHandler.messageName] = setPrefHandler;
      }
      setPrefHandler.setRequestXML(logoutBrokerAction.prefData);
   }

   if (!logoutBrokerObject) {
      logoutBrokerObject = new DoLogoutHandler();
      globalArray[logoutBrokerObject.messageName] = logoutBrokerObject;
      globalArray[logoutBrokerObject.responseTag] = logoutBrokerObject;
   } else {
      logoutBrokerObject.resetData();
   }

   if (logoutBrokerAction.needClearURI !== false) {
      //before sending response to UI, this flag will be cleared automaticly,
      // no need to clear it
      logoutBrokerObject.setNeedClearURIFlag();
   }

   //clear timer in storage before logout
   if (!brokerSessionTimeoutTimerController) {
      brokerSessionTimeoutTimerController = new BrokerSessionTimeoutTimerController();
      globalArray["broker-session-timeout-timer"] = brokerSessionTimeoutTimerController;
   }
   brokerSessionTimeoutTimerController.stop();

   handlerList = logoutBrokerObject.composeHandlerList();

   handlerList = handlerList.filter(checkExist);

   router.postMessage(
      handlerList,
      logoutBrokerAction.async,
      logoutBrokerAction.timeout,
      undefined,
      logoutBrokerAction.sendFetch
   );
};
