/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getAuthenticationStatusCommand.js --
 *
 *      Called to get authentication status and also for idle time in the
 * broker
 *      cases that this will be called:
 *          1. timer want to know the last user active time on all tab of
 * browser through the broker
 *          2. unlock and client launched by Horizon all need send this first
 * to decide what to do next.
 *
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import GetAuthenticationStatusHandler from "../controllers/getAuthenticationStatusHandler";

export default function GetAuthenticationStatusCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param GetAuthenticationStatusAction[in]
 *        - its property 'method' has the value 'GetAuthenticationStatus'
 */
GetAuthenticationStatusCommand.prototype.execute = function (GetAuthenticationStatusAction) {
   let getAuthenticationStatusObject,
      handlerList,
      router = globalArray["router"];

   // version check is here because GetAuthenticationStatus can not be the
   // first send XML
   if (!util.brokerSupportApplication()) {
      return;
   }
   getAuthenticationStatusObject = globalArray["get-authentication-status"];
   if (!getAuthenticationStatusObject) {
      getAuthenticationStatusObject = new GetAuthenticationStatusHandler();
      globalArray[getAuthenticationStatusObject.messageName] = getAuthenticationStatusObject;
      globalArray[getAuthenticationStatusObject.responseTag] = getAuthenticationStatusObject;
   } else {
      getAuthenticationStatusObject.resetData();
   }

   if (!!getAuthenticationStatusObject && !!router) {
      getAuthenticationStatusObject.setRequestXML(false);
      if (GetAuthenticationStatusAction.workForSilentMode) {
         getAuthenticationStatusObject.setWorkForSilentModeFlag();
      }
      handlerList = getAuthenticationStatusObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};
