/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { JscdkWrapper } from "../../common/jscdk/jscdk-wrapper";
import { AbstractModalComponent } from "../../common/commondialog/abstract-modal.component";
import { LoginTypesKey } from "../../login/common/content-data";
import { LoginService } from "../../login/login-root/login.service";

const ReAuthKeys: Array<LoginTypesKey> = [
   "WindowsPassword",
   "SecurIDPasscode",
   "SecurIDNextTokenCode",
   "SecurIDPinChange",
   "SecurIDWait",
   "WindowsPasswordExpired"
];
@Component({
   selector: "reauth-dialog",
   templateUrl: "./reauth-dialog.component.html"
})
export class ReauthDialogComponent extends AbstractModalComponent {
   public contentData;

   constructor(
      jscdkWrapper: JscdkWrapper,
      private loginService: LoginService
   ) {
      super();
      this.loginService.reauthDialogRedraw$.subscribe((data) => {
         this.contentData = data;
      });
      if (ReAuthKeys.includes(jscdkWrapper.data.name)) {
         this.contentData = jscdkWrapper.data;
      }
   }
}
