/**
 * ******************************************************
 * Copyright (C) 2016-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";
import { Monitor } from "../../multimon/common/monitor-message";

@Injectable({
   providedIn: "root"
})
export class MultimonMessageQueueService {
   private allReady = false;
   private allReadyPendingPromise = null;
   private onAllReadyCallback = null;
   private forEachExtenalMonitor = null;
   private unityWindowTracker = null;
   public setUnityWindowTracker(o) {
      this.unityWindowTracker = o;
   }
   // simplified for current chrome client arch
   // return promose<boolean> indicate whether multimon was not ready
   public afterAllMonitorReady = (sessionKey) => {
      if (!this.allReadyPendingPromise) {
         this.allReadyPendingPromise = new Promise((resolve) => {
            if (this.allReady) {
               Logger.info("no message was blocked");
               resolve(false);
               return;
            }
            this.onAllReadyCallback = resolve;
         });
      }
      if (!this.allReady) {
         Logger.info("message would need to be cached in queue");
      }
      return this.allReadyPendingPromise;
   };

   public reset = () => {
      this.allReady = false;
      this.allReadyPendingPromise = null;
   };

   public onAllMonitorReady = (forEachExtenalMonitorFunc) => {
      if (this.allReady) {
         Logger.error("can't init MultimonMessageQueueService multiple times ");
         return;
      }
      if (this.allReadyPendingPromise && this.onAllReadyCallback) {
         Logger.info("monitor queue unleashed");
         this.onAllReadyCallback(true);
      }
      this.allReady = true;
      this.forEachExtenalMonitor = forEachExtenalMonitorFunc;

      if (this.unityWindowTracker) {
         this.unityWindowTracker.syncUnityToExtendedMonitors();
      }

      Logger.info("no longer blocking message by monitor queue");
   };

   public boardcastUnityMessage = (message) => {
      if (!this.allReady) {
         Logger.error("can't boardcast when not ready");
         return;
      }
      this.forEachExtenalMonitor((key, monitor) => {
         //TODO: filter based on message type here
         Logger.dump("send message to " + key + JSON.stringify(message), Logger.UNITY, Logger.Modules.Unity);
         monitor.sendUnityMessage(message);
      });
   };
}
