/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import $ from "jquery";
import * as CST from "@html-core";
import {
   KillItemAction,
   ReconnectApplicationSessionsAction,
   InitTimerAction,
   LogoffDesktopAction,
   ResetAllApplicationSessionAction,
   ResetApplicationSessionAction,
   DesktopAction,
   LogoutAction,
   ViewURIAction,
   ResetURIAction,
   ConnectBrokerAction,
   SelectApplicationAction,
   SelectDesktopAction,
   SetSAMLartAction
} from "./jscdk-interface";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { ViewClientModel } from "../model/viewclient-model";
import { ConnectionURIModel } from "../model/connection-uri-model";
import { DPIService } from "../service/dpi.service";
import { BusEvent, clientUtil, LocalStorageService } from "@html-core";
import { ConnectionServerModel } from "../model/connection-server-model";
import { IdleSessionService } from "../service/idle-session.service";
import { PreDataSetModel } from "../model/pre-data-set-model";
import { MultiSessionSingleUserService } from "../service/multisession-singleuser.service";
import { Timezone } from "../timezone/timezone.service";
import { CredCleanService } from "../service/credential-clean.service";
import { ReconnectService } from "../../desktop/common/reconnect.service";
import { ClientModeService } from "../service/client-mode.service";
import { EventBusService } from "../../../core/services/event/event-bus.service";
import { UserGlobalPref } from "../service/user-global-pref";
import { CryptoKeyDerivationService } from "../service/crypto-key-derivation.service";
import { SessionUtil } from "../service/session-util";
import { ClientIdService } from "../../common/model/clientid-model";
import { RdsLicenseInfoService } from "../service/rds-license-info.service";
import { globalArray, JSCDKSetUI } from "../../jscdk/jscdkClient";
import { BrokerSessionStorageService } from "../../../core/services/storage/broker-session-storage.service";
import { Ws1Service } from "../service/ws1.service";
import { SessionDataService } from "../../common/service/session-data.service";
@Injectable({
   providedIn: "root"
})
export class JscdkCommonInvoker {
   applicationSessionArray = [];
   mainService = null;
   jscdkHandler = null;

   constructor(
      private viewClientModel: ViewClientModel,
      private connectionURIModel: ConnectionURIModel,
      private userGlobalPref: UserGlobalPref,
      private dpiService: DPIService,
      private localStorageService: LocalStorageService,
      private connectionServerModel: ConnectionServerModel,
      private idleSessionService: IdleSessionService,
      private preDataSetModel: PreDataSetModel,
      private multiSessionSingleUserService: MultiSessionSingleUserService,
      private timezone: Timezone,
      private credCleanService: CredCleanService,
      private reconnectService: ReconnectService,
      private clientModeService: ClientModeService,
      private eventBusService: EventBusService,
      private cryptoKeyDerivationService: CryptoKeyDerivationService,
      private clientIdService: ClientIdService,
      private rdsLicenseInfoService: RdsLicenseInfoService,
      private brokerSessionStorageService: BrokerSessionStorageService,
      private ws1Service: Ws1Service,
      private sessionDataService: SessionDataService
   ) {}

   public _invokeJSCDK = (action, doNotBlock?: any, doNotBusy?: any) => {
      action = this._addAllowDataSharingSetting(action);
      this.mainService.invokeJSCDK(action, doNotBlock, doNotBusy);
   };

   public resetItem = (itemId, isDesktop, isMultiSession?: any) => {
      // when reset multi session application, itemId should not includes "Multi-session-" and we should only disconnect multisession app
      if (isMultiSession !== true) {
         const killItemAction = {} as KillItemAction;
         killItemAction.method = "KillSession";
         killItemAction.itemInfo = {
            id: itemId,
            isDesktop: isDesktop,
            isMultiSession: !!isMultiSession
         };
         this.jscdkHandler.invokeJSCDK(killItemAction);
      } else {
         // The session key should includes prefix "Multi-session-"
         if (itemId && !itemId.startsWith(SessionUtil.multiSessionPrefix)) {
            itemId = SessionUtil.multiSessionPrefix + itemId;
         }
         // when reset multi session application, we should only disconnect multisession app
         this.eventBusService.dispatch(new BusEvent.DisconnectApp(itemId));
      }
   };

   public getUserGlobalPref = () => {
      this.jscdkHandler.invokeJSCDK({
         method: "GetUserGlobalPref"
      });
   };

   public reconnectApplicationSessions = (targetSessionIds, excludedSessionId) => {
      const reconnectApplicationSessionsAction = {} as ReconnectApplicationSessionsAction;
      reconnectApplicationSessionsAction.method = "ReconnectSession";
      reconnectApplicationSessionsAction.targetSessionIds = targetSessionIds;
      reconnectApplicationSessionsAction.disconnectAll = false;
      reconnectApplicationSessionsAction.excludedSessionId = excludedSessionId;

      this._getEnvironmentInfo(false).then((envInfo: any) => {
         reconnectApplicationSessionsAction.environmentInfo = envInfo;
         if (this.rdsLicenseInfoService.isRdsLicenseEnabled()) {
            reconnectApplicationSessionsAction.rdsLicenseInfo = this.getRdsLicenseInfo();
         }
         this.jscdkHandler.invokeJSCDK(reconnectApplicationSessionsAction);
      });
   };

   public setEnvironmentInfo = (environmentInfo) => {
      const setEnvironmentInfoAction = {
         method: "SetEnvironmentInfo",
         data: environmentInfo
      };
      this._invokeJSCDK(setEnvironmentInfoAction);
   };

   public getOnRampConfig = () => {
      const getOnRampConfigAction = {
         method: "GetOnRampConfig"
      };
      this._invokeJSCDK(getOnRampConfigAction);
   };

   public sendLaunchItemsXML = () => {
      const getLaunchItemsAction = {
         method: "GetLaunchItems",
         skipTunnelAndPref: true,
         skipTimer: true
      };
      this._invokeJSCDK(getLaunchItemsAction);
   };

   public _addAllowDataSharingSetting = (action) => {
      // many types of SubmitAuthInfo, use a function
      // to add the config
      if (action.method === "SubmitAuthInfo" || action.method === "ConnectToBroker") {
         if (this.preDataSetModel.settingData["dataSharingAllowed"] !== undefined) {
            action.allowDataSharing = this.preDataSetModel.settingData["dataSharingAllowed"];
         } else {
            action.allowDataSharing = true;
         }
         Logger.info("User set allow data sharing to : " + action.allowDataSharing);
      }
      return action;
   };

   /**
    * send last user active time of this local tab to timer-controller
    * in JSCDK Invoke it receiving action from JSCDK in main-service/
    * show()
    */
   public sendLastUserActiveTimeToTimerCtrl = (actionTrigger, lastUserActiveTime) => {
      if (typeof lastUserActiveTime !== "number") {
         Logger.warning("lastUserActiveTime is NOT a valid number!");
         return;
      }

      let sendLastUserActiveTimeAction,
         actionType = null;

      switch (actionTrigger) {
         case "RequestLastUserActiveTime":
            actionType = "sendLastUserActiveTime";
            break;
         case "AboutToTimeout":
            actionType = "syncWithTimer4AboutToTimeout";
            break;
         case "Timeout":
            actionType = "syncWithTimer4Timeout";
            break;
         case "showAboutToTimeoutDialogCallback":
            actionType = "showAboutToTimeoutDialogCallback";
            break;
         default:
            Logger.warning("unknown actionTrigger in" + " sendLastUserActiveTimeToTimerCtrl()" + actionTrigger);
            return;
      }

      // send UI detected last user active time as default value
      sendLastUserActiveTimeAction = {
         method: "TimerCtrl",
         type: actionType,
         lastUserActiveTime: lastUserActiveTime
      };
      this.mainService.invokeTimerCtrl(sendLastUserActiveTimeAction);
   };

   public startIdleTimer = (type?: string, parameters?: any) => {
      // reset last activity time, since the trigger of renew is treated as user action
      this.idleSessionService.setLastUserActivityTime();

      const initTimerAction = {
         method: "TimerCtrl",
         type: "InitTimer",
         idleTimeout: this.idleSessionService.idleTimeout,
         userActivityInterval: this.idleSessionService.userActivityInterval
      } as InitTimerAction;
      if (type === "Desktop") {
         initTimerAction.initType = "Desktop";
         initTimerAction.bypassApplicationVersionCheck = "true";
         initTimerAction.brokerUrl = parameters ? parameters.brokerUrl : "";
      } else {
         initTimerAction.initType = "Portal";
         if (parameters && parameters.onlyEnableIntervalTimer) {
            initTimerAction.idleTimeout = -1;
         }
      }
      this.mainService.invokeTimerCtrl(initTimerAction);
   };

   /**
    * send do-unlock command
    * Invoke it after receiving dolock success massage and triggered by
    * user click ok in get-my-things-back dialog
    */
   public sendDoUnlock = () => {
      const sendDoUnlockAction = {
         method: "DoUnlock"
      };
      this._invokeJSCDK(sendDoUnlockAction);
   };

   /**
    * Logoff desktop
    *
    * @param desktopId Desktop ID
    */
   public logoffDesktop = (desktopId?: any) => {
      const logoffDesktopAction = {} as LogoffDesktopAction;
      logoffDesktopAction.method = "KillSession";
      logoffDesktopAction.itemInfo = {
         id: desktopId,
         isDesktop: true
      };
      this._invokeJSCDK(logoffDesktopAction);
   };

   /**
    * Reset all application sessions
    * will send <kill-session> to the broker to kill the specified
    * application session
    *
    * will be changed when refactory JSCDK to merge handler & XMLs
    *
    * the orignal idea is
    */
   public resetAllApplicationSessions = () => {
      const resetAllApplicationSessionAction = {} as ResetAllApplicationSessionAction;
      resetAllApplicationSessionAction.method = "KillSession";
      resetAllApplicationSessionAction.killAllApplicationSessions = true;
      this._invokeJSCDK(resetAllApplicationSessionAction);
   };

   /**
    * Reset a application session
    * will send <kill-session> to the broker to kill the specified
    * application session
    */
   public resetSession = (id, isDesktop, isMultiSession) => {
      const resetApplicationSessionAction = {} as ResetApplicationSessionAction;
      resetApplicationSessionAction.method = "KillSession";
      resetApplicationSessionAction.itemInfo = {
         id: id,
         isDesktop: isDesktop,
         isMultiSession: !!isMultiSession
      };
      this._invokeJSCDK(resetApplicationSessionAction);
   };

   /**
    * Reset desktop
    *
    * @param desktopId Desktop ID
    */
   public reset = (desktopId) => {
      const resetDesktopAction = {} as DesktopAction;
      resetDesktopAction.method = "ResetDesktop";
      resetDesktopAction.desktopId = desktopId;
      this._invokeJSCDK(resetDesktopAction);
   };

   /**
    * Restart desktop
    *
    * @param desktopId Desktop ID
    */
   public restart = (desktopId) => {
      const restartDesktopAction = {} as DesktopAction;
      restartDesktopAction.method = "RestartDesktop";
      restartDesktopAction.desktopId = desktopId;
      this._invokeJSCDK(restartDesktopAction);
   };

   /**
    * Cancel the current JSCDK request, if one exists. This function is
    * used to cancel things like authentication requests.
    *
    * @param doneAction[in] action to request after cancellation.
    */

   public cancelCurrentRequest = (doneAction: any, doNotBlock?: boolean) => {
      this.jscdkHandler.invokeJSCDK(
         {
            method: "CancelCurrentRequest",
            doneAction: doneAction
         },
         doNotBlock
      );
   };

   public cancelAuthentication = (
      authType: VmwHorizonClientAuthType = <VmwHorizonClientAuthType>"Unknown",
      sendFetch: boolean = false
   ) => {
      this.eventBusService.dispatch(
         new BusEvent.AuthenticationDeclined({
            authType: authType
         })
      );
      this._invokeJSCDK({
         method: "CancelAuthentication",
         sendFetch: sendFetch
      });

      this.eventBusService.dispatch(new CST.BusEvent.ClearActiveClient());
   };

   // stop the idle timeout related timers in the jscdk
   public stopIdleTimer = () => {
      const blockValue = this.clientModeService.clientMode === "desktop" ? true : false;
      const stopTimerAction = {
         method: "TimerCtrl",
         type: "stopTimer"
      };
      this.mainService.invokeTimerCtrl(stopTimerAction, blockValue);
   };

   public _clearSessionCookies = () => {
      this.localStorageService.remove(CST.COOKIE.USER_NAME);
      this.localStorageService.remove(CST.COOKIE.TOKEN_USER_NAME);
      this.localStorageService.remove(CST.COOKIE.DOMAIN_NAME);
      this.localStorageService.remove(CST.COOKIE.IDLE_TIMEOUT);
      this.localStorageService.remove(CST.COOKIE.SEND_TIME_INTERVAL);
      this.localStorageService.remove(CST.COOKIE.HORIZON_ID);
   };

   // Logout the connection server.
   public logout = (needClearURI?: any) => {
      const logoutAction = {
         method: "LogoutFromBroker",
         prefData: this.userGlobalPref.toFlatFormat(true)
      } as LogoutAction;

      this.eventBusService.dispatch(new CST.BusEvent.ClearActiveClient());
      this.eventBusService.dispatch(
         new CST.BusEvent.PolicyTriggeredQuit("enableLogoutTriggeredQuit", this.connectionServerModel.host)
      );

      this._clearSessionCookies();
      // Reset server model.
      this.connectionServerModel.reset();
      // Clear any WS1 desktop sessions
      this.reconnectService.clear();
      // clear brokerSessionStorage for previous broker session exist no more
      this.brokerSessionStorageService.clear();
      this.localStorageService.clearSession();
      this.sessionDataService.clearRunningSession();
      //Reset geolocation popup appreared once state
      this.eventBusService.dispatch(new CST.BusEvent.StopGeolocationService());
      // Clear URI model for normal logout.
      if (needClearURI === false) {
         logoutAction.needClearURI = false;
      } else {
         this.connectionURIModel.clear(false);
         //need to close the tab when logged out or when try to do
         // that?
      }
      this._invokeJSCDK(logoutAction);
   };

   public setURI = (queryString, brokerURL) => {
      this.credCleanService.waitForCredentialClean(() => {
         this._setURI(queryString, brokerURL);
      });
   };

   public _setURI = (queryString, brokerURL) => {
      const viewURIAction = {
         method: "SetViewURI",
         queryString: queryString,
         brokerURL: brokerURL
      } as ViewURIAction;
      this._invokeJSCDK(viewURIAction);
   };

   public clearURI = () => {
      const resetURIAction = {
         method: "HandleURI",
         operationType: "reset"
      } as ResetURIAction;

      this._invokeJSCDK(resetURIAction, true, true);
   };

   public setSAMLart = (samlArt) => {
      const setSAMLartAction = {
         method: "HandleURI",
         operationType: "setSAML",
         samlArt: samlArt
      } as SetSAMLartAction;

      this._invokeJSCDK(setSAMLartAction, true, true);
   };

   public resetSAMLart = () => {
      const setSAMLartAction = {
         method: "HandleURI",
         operationType: "resetSAML",
         samlArt: null
      } as SetSAMLartAction;

      this._invokeJSCDK(setSAMLartAction, true, true);
   };

   /**
    * Retrieve launchitems of the current session.
    * Invoke it when ALREADY_AUTHENTICATED.
    */
   public retrieveLaunchItems = () => {
      const getLaunchItemsAction = {
         method: "GetLaunchItems"
      };
      this._invokeJSCDK(getLaunchItemsAction);
   };

   /**
    * this method should be moved to a service for better unit testing
    * there's another connectToBroker method in util-service.  Should
    * consider to combine both of the method
    */
   public connectToBroker = () => {
      let connectBrokerAction = {} as ConnectBrokerAction,
         clientCommonInfo,
         clientStats,
         addClientCommonInfo,
         addClientStats,
         userAgent = navigator.userAgent,
         isHandheldDevice = !!userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(IEMobile)|(webOS)/i),
         matches,
         vmwareBrowserMatches,
         browserName = "unknown",
         browserVersion,
         browserArch = navigator.platform;

      if (browserArch.indexOf("Win") !== -1) {
         browserArch =
            navigator.userAgent.indexOf("WOW64") !== -1 || navigator.userAgent.indexOf("Win64") !== -1
               ? "Win64"
               : "Win32";
      }

      // Connect To the Broker
      connectBrokerAction.method = "ConnectToBroker";
      connectBrokerAction.address = this.connectionServerModel.host;
      connectBrokerAction.supportedProtocols = this.connectionServerModel.supportedProtocols;
      connectBrokerAction.unauthenticatedAccessEnabled = this.preDataSetModel.settingData["anonymousLogin"];
      connectBrokerAction.csrfCheck = this.viewClientModel.csrfCheck;
      connectBrokerAction.disableIPv6 = this.viewClientModel.disableIPv6;

      //ignore ISO for now
      if (this.connectionURIModel.isF5Session()) {
         connectBrokerAction.mid = this.connectionURIModel.getF5postFix();
      }

      if (userAgent.toLowerCase().match(/(iphone|ipod|ipad)/i)) {
         // Apple iOS6 introduced a new xml post bug where the browser
         // would cache response if the request data did not change.
         // Hack to make sure we always request a new url, by adding a
         // timestamp whenever get an iOS browser
         connectBrokerAction.urlParam = "?_ab=" + $.now();
      }

      // Match pattern like 'Chrome/26.0.1410.64' to retrieve browser
      // name and version.
      matches = userAgent.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);
      vmwareBrowserMatches = userAgent.match(/(airwatch browser)\s*(v\.?\d+(\.\d+)*)/i);
      if (vmwareBrowserMatches) {
         browserName = vmwareBrowserMatches[1];
         browserVersion = vmwareBrowserMatches[2].replace("v", "");
      } else {
         if (matches) {
            browserName = matches[1];
            browserVersion = matches[2];
         }
      }

      // For Microsoft Edge, the useragent is like:
      // "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36
      // (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36
      // Edge/12.0". Match pattern Edge, because there's a "chrome"
      // string in it.
      if (userAgent.toLowerCase().indexOf("edge") !== -1) {
         matches = userAgent.match(/(edge)\/?\s*(\.?\d+(\.\d+)*)/i);
         if (matches) {
            browserName = matches[1];
            browserVersion = matches[2];
         }
      } else if (userAgent.toLowerCase().indexOf("edg") !== -1) {
         //For Chromium Edge, edg will contains in useragent instead of edge
         matches = userAgent.match(/(edg)\/?\s*(\.?\d+(\.\d+)*)/i);
         if (matches) {
            browserName = "edge";
            browserVersion = matches[2];
         }
      }

      if (browserName === "Safari") {
         // For Safari, the version is in 'Version/6.0.3'.
         matches = userAgent.match(/version\/([.\d]+)/i);
         if (matches) {
            browserVersion = matches[1];
         }
      } else if (browserName === "Trident") {
         // For IE 11, the version is in 'rv:11.0'.
         browserName = "Internet Explorer";
         matches = userAgent.match(/rv[:\s]([\w.]+)/i);
         if (matches) {
            browserVersion = matches[1];
         }
      }
      // For Chromebook, detect browser-arch from userAgent text.
      matches = userAgent.match(/cros\s*[a-z0-9_.]*/i);
      if (matches) {
         browserArch = matches[0];
      }

      // Client common info
      clientCommonInfo = {
         // 'VMware'
         "client-vendor": this.viewClientModel.vendor,
         // 'VMware Horizon HTML Access'
         "client-product": this.viewClientModel.product,
         // View client version
         "client-version": this.viewClientModel.version + "-" + this.viewClientModel.buildNum,
         // 'Chrome Apps'
         "client-arch": this.viewClientModel.arch,
         //'client-build for blast portal'
         "client-build":
            this.viewClientModel.product.replace(/ Chrome/, "").replace(/ /g, "-") + "-" + this.viewClientModel.platform
      };
      // Client platform specific info
      clientStats = {
         // Browser info
         "browser-user-agent": userAgent,
         // Browser core
         "browser-core": browserName,
         // Browser version
         "browser-version": browserVersion,
         // System info
         "browser-arch": browserArch,
         // Is mobile device
         "browser-is-handheld": isHandheldDevice ? "true" : "false"
      };

      addClientCommonInfo = {
         "entity-type": "CLIENT",
         "data-type": "configuration"
      };

      addClientStats = {
         // the same with windows client
         "entity-id": "singleton"
      };

      connectBrokerAction.clientInfo = {
         clientCommonInfo: clientCommonInfo,
         clientStats: clientStats,
         addClientCommonInfo: addClientCommonInfo,
         addClientStats: addClientStats
      };

      // F5 info is only for launcher invoker
      if (this.clientModeService.clientMode === "launcher") {
         let F5Mode = this.localStorageService.get(CST.COOKIE.F5_MODE) === "true";
         if (!F5Mode) {
            if (location.pathname.search("f5vdifwd/vmview") !== -1) {
               F5Mode = true;
            }
         }
         if (F5Mode) {
            connectBrokerAction.F5Mode = F5Mode;
         }
      }

      // add key-parameters
      connectBrokerAction.dhPublicKey = this.cryptoKeyDerivationService.getDHClientPublicKey();
      connectBrokerAction.nonce = this.cryptoKeyDerivationService.getNonce();
      connectBrokerAction.clientIdentifier = this.cryptoKeyDerivationService.getClientIdentifier();
      /**
       * For server side, it will firstly cut off original 16 bytes auth tag to 12 bytes then send to client,
       * as for client side, when it use crypto-browserify API to execute decipher.final() function, inside
       * that function, will firstly calculate decipher original auth tag, which is 16 bytes, then do xor test
       * for that original auth tag and the auth server send to client, since the length os these two auth tag
       * is different, decipher.final() will result in a error, and thus, in this release, for web client and
       * chrome native client, we only use SCHEME-AES1, which do not need auth tag
       *
       * we will still find other libraries can allow the shorter auth tag length and ask nodeJS community for
       * help
       */
      connectBrokerAction.encryptionSchemes = ["SCHEME-AES1"];

      this.viewClientModel.clientInfoSubject.subscribe(() => {
         /**
          * sync model into jscdk to init environment info for get-launch-item to
          * support: https://jira-hzn.eng.vmware.com/browse/DPM-1211
          * decouple from current _getEnvironmentInfo since they are different,
          * and under current code structure better to be separated.
          */
         const environmentInfo = {};

         if (this.viewClientModel.ipAddress) {
            environmentInfo["IP_Address"] = this.viewClientModel.ipAddress;
            environmentInfo["Machine_Name"] = this.viewClientModel.machineName;
         }
         if (this.viewClientModel.clientID) {
            environmentInfo["Client_ID"] = this.viewClientModel.clientID;
         }
         environmentInfo["Client_Version"] = this.viewClientModel.version + "-" + this.viewClientModel.buildNum;
         environmentInfo["Type"] = CST.CLIENT.viewclientType;

         if (this.viewClientModel.AssetID) {
            environmentInfo["Asset_ID"] = this.viewClientModel.AssetID;
         }
         if (this.viewClientModel.SerialNum) {
            environmentInfo["Serial_Number"] = this.viewClientModel.SerialNum;
         }
         // Start support Rdpvcbridge for Chrome Client from version 8.4
         // Todo double confirm titan support this
         environmentInfo["RDPVCBridge.Supported"] = clientUtil.isChromeClient() ? "true" : "false";

         // fix SR 2980519, put chromebook asset ID into Machine_Name instead of machine name
         if (this.viewClientModel.useAssetIdReplaceMachineName && this.viewClientModel.AssetID) {
            environmentInfo["Machine_Name"] = this.viewClientModel.AssetID;
            Logger.info("put chromebook asset ID into Machine_Name");
         }

         this.setEnvironmentInfo(environmentInfo);

         if (this.viewClientModel.browserName === "Saf") {
            setTimeout(() => {
               this._invokeJSCDK(connectBrokerAction);
            }, 200);
         } else {
            this._invokeJSCDK(connectBrokerAction);
         }
      });
   };

   // doForceCloseLoading： Used to control whether the loading state can be forcibly closed
   public checkAuthenticationStatus = (doForceCloseLoading?: any) => {
      let offlineSSOCacheTimerController = globalArray["offline-sso-cache-timer"],
         ssoDiscarded = true;
      if (offlineSSOCacheTimerController) {
         const targetTime = offlineSSOCacheTimerController.getTimerFromStorage(),
            currentTime = new Date().getTime();
         if (targetTime) {
            Logger.debug("Get offline sso cache time from storage: " + targetTime);
            if (targetTime >= currentTime) {
               Logger.debug("Offline SSO is still valid");
               ssoDiscarded = false;
            } else {
               Logger.debug("Offline SSO has been discarded");
               offlineSSOCacheTimerController.stop();
            }
         }
      }
      // If it's launched from WS1, always send <get-auth-status> to check sso status
      if (this.ws1Service.isLaunchedFromWS1() || ssoDiscarded || this.idleSessionService.isSessionTimedOut()) {
         Logger.debug("SSO is timeout and check auth status, or it's chromeclient ws1 launch");
         const authStatusCheckAction = {
            method: "GetAuthenticationStatus",
            workForSilentMode: true
         };
         if (doForceCloseLoading) {
            this._invokeJSCDK(authStatusCheckAction, true, true);
         } else {
            this._invokeJSCDK(authStatusCheckAction);
         }
      } else {
         const action = {
            name: "AuthenticationStatusUnlocked"
         };
         const actionString = JSON.stringify(action);
         JSCDKSetUI(actionString);
      }
   };

   public launchApplication = (appInfo) => {
      const application: EntitlementItem = appInfo.application,
         args = appInfo.args,
         selectApplicationAction = {} as SelectApplicationAction,
         originId = application["origin-id"] || application.originId;

      selectApplicationAction.disableCombine = false;
      if (clientUtil.isTitanClient()) {
         selectApplicationAction.disableCombine = true;
      }
      // connect to application
      selectApplicationAction.method = "ConnectToApplication";
      // cache information to used in the callback
      selectApplicationAction.callbackParams = { originId };
      /*
       * launch application for the specific protocol
       * currently, only BLAST is allowed.
       */
      selectApplicationAction.protocol = this.connectionServerModel.connectionProtocol;
      selectApplicationAction.applicationId = application.id;
      // The application data may be from LaunchItemsCtrlService or EntitledItemsModel
      selectApplicationAction.originId = originId;

      if (CST.clientUtil.isChromeClient()) {
         selectApplicationAction.maximized = false;
      } else {
         selectApplicationAction.maximized = true;
      }

      selectApplicationAction.preferences = {
         applicationName: application.name,
         resolution: {
            x: 0,
            y: 0,
            w: $(window).width(),
            h: $(window).height()
         }
      };

      if (args) {
         selectApplicationAction.launchParam = {
            args: args
         };
      }

      // Reverse connection only supported in titan now.
      if (clientUtil.isTitanClient()) {
         const entitle: TitanEntitlementItem = appInfo as TitanEntitlementItem;
         selectApplicationAction.reverseToken = entitle.reverseToken;
      }
      selectApplicationAction.prefData = this.userGlobalPref.toFlatFormat();
      if (this.rdsLicenseInfoService.isRdsLicenseEnabled()) {
         selectApplicationAction.rdsLicenseInfo = this.getRdsLicenseInfo();
      }

      this._getEnvironmentInfo(false).then((envInfo: any) => {
         selectApplicationAction.environmentInfo = envInfo;
         this.multiSessionSingleUserService.getAppStatus(application.id).then((useMultiSessionTag) => {
            selectApplicationAction.multiSession = useMultiSessionTag;
            this._invokeJSCDK(selectApplicationAction);
         });
      });
   };

   public launchDesktop = (desktop: EntitlementItem) => {
      const ratio = 1,
         selectDesktopAction = {} as SelectDesktopAction;

      selectDesktopAction.disableCombine = false;
      if (clientUtil.isTitanClient()) {
         selectDesktopAction.disableCombine = true;
      }
      selectDesktopAction.method = "ConnectToDesktop";
      /* launch desktop for the specific protocol
       * currently, only BLAST is allowed.
       */
      selectDesktopAction.protocol = this.connectionServerModel.connectionProtocol;
      selectDesktopAction.desktopId = desktop.id;
      selectDesktopAction.isShadow = desktop.isShadow;
      selectDesktopAction.preferences = {
         desktopName: desktop.name,
         resolution: {
            x: 0,
            y: 0,
            w: $(window).width() * ratio,
            h: $(window).height() * ratio
         }
      };

      // Reverse connection only supported in titan now.
      if (clientUtil.isTitanClient()) {
         const entitle: TitanEntitlementItem = desktop as TitanEntitlementItem;
         selectDesktopAction.reverseToken = entitle.reverseToken;
      }
      selectDesktopAction.prefData = this.userGlobalPref.toFlatFormat();
      if (this.rdsLicenseInfoService.isRdsLicenseEnabled()) {
         selectDesktopAction.rdsLicenseInfo = this.getRdsLicenseInfo();
      }

      if (!selectDesktopAction.isShadow) {
         this._getEnvironmentInfo(true).then((info: any) => {
            selectDesktopAction.environmentInfo = info;
            this._invokeJSCDK(selectDesktopAction);
         });
      } else {
         this._invokeJSCDK(selectDesktopAction);
      }
   };

   public acceptDisclaimer = () => {
      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "Disclaimer"
      };

      this._invokeJSCDK(submitAuthInfoAction);
   };

   /**
    * use reAuth to only fix bug 2096064, better fix need
    * change tunnel connection logic to be same with
    * libcdk or it's still bug risky for other partial
    * auth methods.
    */
   public changePassword = (oldPassword, newPassword1, newPassword2, reAuth, isDesktopReAuth?) => {
      // Encrypt XML-API protection data
      oldPassword = this.cryptoKeyDerivationService.encryptIfXmlApiDataProtected(oldPassword);
      newPassword1 = this.cryptoKeyDerivationService.encryptIfXmlApiDataProtected(newPassword1);
      newPassword2 = this.cryptoKeyDerivationService.encryptIfXmlApiDataProtected(newPassword2);

      const isDesktopReAuthValue = !!isDesktopReAuth;
      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "WindowsPasswordExpired",
         isDesktopReAuth: isDesktopReAuthValue,
         oldPassword: oldPassword,
         newPassword1: newPassword1,
         newPassword2: newPassword2,
         reAuth: !!reAuth
      };
      this._invokeJSCDK(submitAuthInfoAction);
   };

   public submitUnauthenticated = (username) => {
      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "Unauthenticated",
         username: username
      };

      this._invokeJSCDK(submitAuthInfoAction);
   };

   public submitWindowsPassword = (username, password, domain, reAuth) => {
      // Encrypt XML-API protection data
      password = this.cryptoKeyDerivationService.encryptIfXmlApiDataProtected(password);
      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "WindowsPassword",
         username: username,
         secret: password,
         domain: domain,
         reAuth: reAuth,
         isDesktopReAuth: reAuth
      };
      this._invokeJSCDK(submitAuthInfoAction);
   };

   public submitSecurIDNextTokenCode = (passcode, reAuth) => {
      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "SecurIDNextTokenCode",
         secret: passcode,
         reAuth: reAuth
      };
      this._invokeJSCDK(submitAuthInfoAction);
   };

   public submitSecurIDPasscode = (username, passcode, reAuth) => {
      // Encrypt XML-API protection data
      passcode = this.cryptoKeyDerivationService.encryptIfXmlApiDataProtected(passcode);

      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "SecurIDPasscode",
         username: username,
         secret: passcode,
         reAuth: reAuth
      };
      this._invokeJSCDK(submitAuthInfoAction);
   };

   public submitSecurIDPinChange = (pin1, pin2, reAuth) => {
      // Encrypt XML-API protection data
      pin1 = this.cryptoKeyDerivationService.encryptIfXmlApiDataProtected(pin1);
      pin2 = this.cryptoKeyDerivationService.encryptIfXmlApiDataProtected(pin2);

      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "SecurIDPinChange",
         pin1: pin1,
         pin2: pin2,
         reAuth: reAuth
      };
      this._invokeJSCDK(submitAuthInfoAction);
   };

   public submitSecurIDWait = (reAuth) => {
      const submitAuthInfoAction = {
         method: "SubmitAuthInfo",
         type: "SecurIDWait",
         reAuth: reAuth
      };
      this._invokeJSCDK(submitAuthInfoAction);
   };

   public sendDoLock = () => {
      const doLockAction = {
         method: "DoLock"
      };

      this._invokeJSCDK(doLockAction, true, true);
   };

   public getRdsLicenseInfo = () => {
      const rdsLicenseInfo = {};
      rdsLicenseInfo["client-id"] = this.clientIdService.getClientID();
      rdsLicenseInfo["client-store"] = "ALWAYS";
      rdsLicenseInfo["license-data"] = this.rdsLicenseInfoService.getRdsLicense() || "";
      return rdsLicenseInfo;
   };

   public _getEnvironmentInfo = (isDesktop) => {
      return new Promise((resolve, reject) => {
         const environmentInfo = {};

         if (this.viewClientModel.ipAddress) {
            environmentInfo["IP_Address"] = this.viewClientModel.ipAddress;
            environmentInfo["Machine_Name"] = this.viewClientModel.machineName;
         }
         if (this.viewClientModel.clientID) {
            environmentInfo["Client_ID"] = this.viewClientModel.clientID;
         }
         if (this.viewClientModel.version) {
            environmentInfo["Client_Version"] = this.viewClientModel.version + "-" + this.viewClientModel.buildNum;
         }
         // Start support Rdpvcbridge for Chrome Client from version 8.4
         environmentInfo["RDPVCBridge.Supported"] = clientUtil.isChromeClient() ? "true" : "false";

         environmentInfo["TZID"] = this.timezone.getWindowsTimezone();
         environmentInfo["Type"] = CST.CLIENT.viewclientType;

         environmentInfo["Displays.SystemDpi"] = this.dpiService.getDPI();

         if (this.viewClientModel.AssetID) {
            environmentInfo["Asset_ID"] = this.viewClientModel.AssetID;
         }
         if (this.viewClientModel.SerialNum) {
            environmentInfo["Serial_Number"] = this.viewClientModel.SerialNum;
         }

         /**
          * bug#1854211 Resolution with assumption of Agent DPI has been sync
          * should be sent. For lagecy Agent and Agents with DPI sync disable, the user
          * Experience might be bad in the first 15s, but it's acceptable.
          * See details in bug.
          *
          * bug#2122876 Displays.Number is requested by Agent, and unlike Native
          * Clients, HTML Access and chrome Client will always open desktop in one
          * display firstly, so only one display is set for launching desktop.
          *
          */
         environmentInfo["Displays.Number"] = "1";
         const pixels = CST.clientUtil.getWindowPixels();
         environmentInfo["Displays.Topology"] = "{" + pixels[0] + "," + pixels[1] + ",0,0,32,0}";

         // fix SR 2980519, put chromebook asset ID into Machine_Name instead of machine name
         if (this.viewClientModel.useAssetIdReplaceMachineName && this.viewClientModel.AssetID) {
            environmentInfo["Machine_Name"] = this.viewClientModel.AssetID;
            Logger.info("put chromebook asset ID into Machine_Name");
         }

         resolve(environmentInfo);
      });
   };

   public getEnvironmentInfo = (isDesktop: boolean): Promise<any> => {
      return this._getEnvironmentInfo(isDesktop);
   };
}
