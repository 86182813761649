/**
 * **************************************************************
 * Copyright (C) 2015-2017, 2023-2024 VMware, Inc. All rights reserved.
 * ***************************************************************
 *
 * @format
 */

/**
 * @fileoverview rtavProtocol.service.ts -- rtavProtocolService
 * Service implement RTAV feature for WebClient, depend on MediaCapture, and MediaEncoder
 */

import { Injectable } from "@angular/core";
import { settings } from "./rtav.settings";
import { AVPluginState, RtavTypes, StreamHeaderLength, CodecTypes } from "./rtav.constants";
import { Logger } from "@html-core";

@Injectable()
export class RTAVProtocolService {
   private streamlizersMap: any;
   private util = {
      convertU32: (n) => {
         return [(n & 0xff000000) >>> 24, (n & 0x00ff0000) >>> 8, (n & 0x0000ff00) << 8, (n & 0x000000ff) << 24].reduce(
            (a, b) => {
               return a + b;
            }
         );
      }
   };

   constructor() {
      this.streamlizersMap = {
         sendConfig: (config) => {
            let configArray = [
                  8 * 4,
                  AVPluginState.STOn,
                  config.versionNum,
                  config.isEnabled,
                  config.vdoRes.width,
                  config.vdoRes.height,
                  config.vdoFPS,
                  config.reserved
               ],
               networkArray = this.getNetWork(configArray);

            return this.getRtavControlPacket32("PMsgSetConfig", networkArray);
         },

         sendStartAudioAck: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStart_A_Ack", networkArray);
         },

         sendStartAudioNak: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStart_A_Ack_Err", networkArray);
         },
         sendStopAudioAck: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStop_A_Ack", networkArray);
         },

         sendStopAudioNak: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStop_A_Ack_Err", networkArray);
         },

         sendStartVideoAck: (localConfig) => {
            return this.getRtavControlPacket32(
               "PMsgStart_V_Ack",
               this.getNetWork([localConfig.codecPref]).concat(this.getDevPrefsNet(localConfig))
            );
         },

         sendStartVideoNak: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStart_V_Ack_Err", networkArray);
         },

         sendStopVideoAck: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStop_V_Ack", networkArray);
         },

         sendStopVideoNak: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStop_V_Ack_Err", networkArray);
         },

         sendStream: (messageData) => {
            let streamData, streamHeader;

            if (!messageData || !messageData.streamData) {
               Logger.error("trying to send a invalid stream", Logger.RTAV);
               return null;
            }
            streamData = messageData.streamData;
            streamHeader = new Uint32Array(streamData.buffer, 0, 4);

            streamHeader[0] = RtavTypes["PMsgBinData"];
            streamHeader[1] = streamData.length + StreamHeaderLength;
            streamHeader[2] = messageData.timeStamp;
            streamHeader[3] = messageData.dataCount;

            return new Uint8Array(streamData.buffer);
         },

         sendClientSettings: (localConfig) => {
            let codecPref = localConfig.codecPref;
            return this.getRtavControlPacket32(
               "PMsgSetCliSettings",
               this.getNetWork([codecPref]).concat(this.getDevPrefsNet(localConfig))
            );
         },

         sendStopStream: () => {
            let networkArray = [];
            return this.getRtavControlPacket32("PMsgStopStream", networkArray);
         }
      };
   }

   private getMessageStruct(message) {
      let headerLength = 8,
         header = new Uint32Array(message.data.slice(0, headerLength));
      if (header[1] !== message.data.byteLength) {
         return {
            type: "undefined"
         };
      }
      return {
         type: header[0],
         dataLength: header[1] - headerLength,
         data: message.data.slice(8)
      };
   }

   private parseStruct(data, formats) {
      let i,
         j,
         k,
         format,
         result = {},
         array;
      for (i = 0, j = 0, k = 0; i < formats.length; i++) {
         format = formats[i];
         if (typeof format === "object") {
            array = [];
            for (j = 0; j < format.length; j++, k++) {
               array[j] = this.util.convertU32(data[k]);
            }
            result[format.name] = array;
         } else {
            result[format] = this.util.convertU32(data[k]);
            k++;
         }
      }
      return result;
   }

   private parseAgentGPOSettings(rtavMessage) {
      let configArray = new Uint32Array(rtavMessage.data),
         format = [
            "dataLength",
            "status",
            "versionNum",
            "isEnabled",
            "vdoMaxFPS",
            "vdoMaxResHeight",
            "vdoMaxResWidth",
            "vdoDefResHeight",
            "vdoDefResWidth",
            {
               length: 20,
               name: "reserved"
            }
         ],
         agentGPOSettings;
      agentGPOSettings = this.parseStruct(configArray, format);
      if (agentGPOSettings.dataLength !== rtavMessage.dataLength) {
         return null;
      }
      Logger.debug(
         "parsed agent GPO settings vdoDefResWidth is: " +
            agentGPOSettings.vdoDefResWidth +
            ", vdoDefResHeight is: " +
            agentGPOSettings.vdoDefResHeight +
            ", vdoMaxResWidth is: " +
            agentGPOSettings.vdoMaxResWidth +
            ", vdoMaxResHeight is: " +
            agentGPOSettings.vdoMaxResHeight +
            ", vdoMaxFPS is: " +
            agentGPOSettings.vdoMaxFPS,
         Logger.RTAV
      );
      return agentGPOSettings;
   }

   private parseAVDevPrefs(rtavMessage) {
      let aVDevPrefsArray = new Uint32Array(rtavMessage.data),
         format = [
            "codecPrefs",
            "audFrameUnitLenMS",
            "audFrameUnitCount",
            "queueLen",
            "bitsPerSample",
            "channels",
            "inputStreams",
            "framesPerPacket",
            "samplesPerSec",
            "cliSamplesPerSec",
            { length: 19, name: "reserved1" },
            "fWidth",
            "fHeight",
            "frameRate",
            "tPauseMS",
            "queueLen",
            { length: 20, name: "reserved2" }
         ],
         aVDevPrefs;
      aVDevPrefs = this.parseStruct(aVDevPrefsArray, format);
      Logger.debug(
         "parsed agent codecPref is: " +
            aVDevPrefs.codecPrefs +
            ", bitsPerSample is: " +
            aVDevPrefs.bitsPerSample +
            ", channels is: " +
            aVDevPrefs.channels +
            ", width is: " +
            aVDevPrefs.fWidth +
            ", height is: " +
            aVDevPrefs.fHeight +
            ", samplesPerSec is: " +
            aVDevPrefs.samplesPerSec +
            ", framerate is: " +
            aVDevPrefs.frameRate,
         Logger.RTAV
      );
      return aVDevPrefs;
   }

   private getNetWork(src) {
      let i,
         dst = [];

      for (i = 0; i < src.length; i++) {
         dst[i] = this.util.convertU32(src[i]);
      }
      return dst;
   }

   private uint32ToUint8(array32) {
      let i,
         length8 = array32.length * 4,
         buff8 = new Uint8Array(new Uint32Array(array32).buffer),
         array8 = new Array(length8);

      for (i = 0; i < length8; i++) {
         array8[i] = buff8[i];
      }
      return array8;
   }

   private getRtavControlPacket32(type, dataArray) {
      let data,
         networkBytes,
         headerLength = 8,
         dataLength = dataArray.length * 4 + headerLength,
         typeCode = RtavTypes[type];

      if (typeof typeCode !== "number") {
         return;
      }
      Logger.debug(`sending code: ${typeCode}, type: ${type}`, Logger.RTAV);
      data = [typeCode, dataLength].concat(dataArray);
      networkBytes = new Uint8Array(new Uint32Array(data).buffer);
      return networkBytes;
   }

   private extendZeros(length) {
      let i,
         result = new Array(length);
      for (i = 0; i < length; i++) {
         result[i] = 0;
      }
      return result;
   }

   private getDevPrefsNet(localConfig) {
      let audioSettings = localConfig.audio,
         videoSettings = localConfig.video,
         audioPrefs,
         videoPrefs,
         audioInDevPrefsNet,
         videoCamDevPrefsNet;

      audioPrefs = {
         audFrameUnitLenMS: 20, // cAInUnitLenMS_Def = 20
         audFrameUnitCount: 50, // cAInCacheUnitCount_Def = 50
         queueLen: 10, // cAInQueueLen_Def = 10, but here set as 10
         bitsPerSample: audioSettings.bitsPerSample,
         channels: audioSettings.channels,
         inputStreams: 1,
         framesPerPacket: 1,
         samplesPerSec: audioSettings.sampleRate
      };
      videoPrefs = {
         fWidth: videoSettings.width,
         fHeight: videoSettings.height,
         frameRate: videoSettings.fps,
         tPauseMS: 0,
         queueLen: 10 // cWCamQueueLen_Def = 10
      };
      audioInDevPrefsNet = this.getNetWork(
         [
            audioPrefs.audFrameUnitLenMS,
            audioPrefs.audFrameUnitCount,
            audioPrefs.queueLen,
            audioPrefs.bitsPerSample,
            audioPrefs.channels,
            audioPrefs.inputStreams,
            audioPrefs.framesPerPacket,
            audioPrefs.samplesPerSec
         ].concat(this.extendZeros(20))
      );
      videoCamDevPrefsNet = this.getNetWork(
         [videoPrefs.fWidth, videoPrefs.fHeight, videoPrefs.frameRate, videoPrefs.tPauseMS, videoPrefs.queueLen].concat(
            this.extendZeros(20)
         )
      );

      return audioInDevPrefsNet.concat(videoCamDevPrefsNet);
   }

   /**
    * use to parse rtav protocol message into object.
    * @param  {object} message The object contains binary message
    * @return {object} This will returns the parsed Object of input message
    */
   public parseRecievedMessage(message) {
      let agentGPOSettings,
         aVDevPrefs,
         rtavMessage = this.getMessageStruct(message),
         parsedMessage = {
            type: rtavMessage.type,
            data: null
         },
         testAudioContext;

      switch (rtavMessage.type) {
         case RtavTypes["PMsgGetConfig"]:
            agentGPOSettings = this.parseAgentGPOSettings(rtavMessage);
            parsedMessage.data = agentGPOSettings;
            break;
         case RtavTypes["PMsgGetCliSettings"]:
            Logger.debug("received agent message PMsgGetCliSettings", Logger.RTAV);
            aVDevPrefs = this.parseAVDevPrefs(rtavMessage);
            parsedMessage.data = aVDevPrefs;
            settings.audio.sampleRate = aVDevPrefs.samplesPerSec;
            settings.audio.channels = aVDevPrefs.channels;
            settings.audio.bitsPerSample = aVDevPrefs.bitsPerSample;

            testAudioContext = new window.AudioContext();
            if (testAudioContext.sampleRate < settings.audio.sampleRate) {
               Logger.debug(
                  "AudioContext preferred sample rate " +
                     testAudioContext.sampleRate +
                     " smaller than agent expected " +
                     settings.audio.sampleRate,
                  Logger.RTAV
               );
               settings.audio.sampleRate = testAudioContext.sampleRate >= 16000 ? 16000 : 8000;
            }
            testAudioContext.close();
            if (
               settings.audio.sampleRate === 48000 &&
               (parsedMessage.data.codecPrefs === CodecTypes["CodecNone"] ||
                  parsedMessage.data.codecPrefs === CodecTypes["CodecVmwTheoraSpeex"] ||
                  parsedMessage.data.codecPrefs === CodecTypes["CodecVmwH264Speex"])
            ) {
               settings.audio.sampleRate = 16000;
            }
            break;
         case RtavTypes["PMsgStart_A"]:
            break;
         case RtavTypes["PMsgStop_A"]:
            break;
         case RtavTypes["PMsgStart_V"]:
            Logger.debug("received agent message PMsgStart_V", Logger.RTAV);
            aVDevPrefs = this.parseAVDevPrefs(rtavMessage);
            parsedMessage.data = aVDevPrefs;
            settings.audio.sampleRate = aVDevPrefs.samplesPerSec;
            settings.audio.channels = aVDevPrefs.channels;
            settings.audio.bitsPerSample = aVDevPrefs.bitsPerSample;

            testAudioContext = new window.AudioContext();
            if (testAudioContext.sampleRate < settings.audio.sampleRate) {
               Logger.debug(
                  "AudioContext preferred sample rate " +
                     testAudioContext.sampleRate +
                     " smaller than agent expected " +
                     settings.audio.sampleRate,
                  Logger.RTAV
               );
               settings.audio.sampleRate = testAudioContext.sampleRate >= 16000 ? 16000 : 8000;
            }
            testAudioContext.close();
            if (
               settings.audio.sampleRate === 48000 &&
               (parsedMessage.data.codecPrefs === CodecTypes["CodecNone"] ||
                  parsedMessage.data.codecPrefs === CodecTypes["CodecVmwTheoraSpeex"] ||
                  parsedMessage.data.codecPrefs === CodecTypes["CodecVmwH264Speex"])
            ) {
               settings.audio.sampleRate = 16000;
            }
            break;
         case RtavTypes["PMsgStop_V"]:
         case RtavTypes["PMsgStartStream"]:
         case RtavTypes["PMsgStopStream"]:
            break;
         default:
            Logger.error("unknown msg", Logger.RTAV);
            Logger.error(rtavMessage.toString(), Logger.RTAV);
            return null;
      }
      Logger.debug("receive rtav message:" + JSON.stringify(parsedMessage), Logger.RTAV);
      return parsedMessage;
   }

   /**
    * use to get rtav protocol message object into binary stream.
    * @param {string} type The message type
    * @param {object} data Optional The data that will be carried by the message
    * @return {object} This will returns the binary message constructed from the inputs
    */
   public getSendingBinary(type, data?) {
      let getStream = this.streamlizersMap[type];
      if (typeof getStream !== "function") {
         Logger.error("unknown type to be streamlized", Logger.RTAV);
         return null;
      }
      return getStream(data);
   }
}
