/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { UtilService } from "../../launcher/common/util-service";
import { Subject } from "rxjs";
import { LoginService } from "./../login-root/login.service";

interface SecurIDWaitData {
   content: {
      reAuth?: boolean;
   };
}

@Component({
   selector: "login-securid-wait",
   templateUrl: "./securid-wait.component.html"
})
export class LoginSecurIDWaitComponent extends BaseViewComponent implements BaseViewComponentInterface {
   @Input() formData;
   public cancelLoading: boolean;
   private _reAuth: boolean;
   // private static refreshSubject$ = new Subject<any>();
   // public static redraw = function(data: any) {
   //    LoginSecurIDWaitComponent.refreshSubject$.next(data);
   // };

   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private utilService: UtilService,
      private loginService: LoginService
   ) {
      super(changeDetector, "SecurIDWait");
      // LoginSecurIDWaitComponent.refreshSubject$.subscribe(() => {
      //    this.onRefreshed();
      // });
      this.loginService.securIDWaitRedraw$.subscribe((data) => {
         this.onRefreshed();
      });
      this.cancelLoading = false;
   }

   public onRefreshed = () => {
      super.onRefreshed();
      this.renderData();
   };

   public renderData = () => {
      const data = this.data as SecurIDWaitData;
      this.onAuthScreen(this.componentName, data);
      this.cancelLoading = false;
      this._reAuth = !!data.content.reAuth;
   };

   public onSubmit = () => {
      this.cancelLoading = false;
      this.jscdkCommonInvoker.submitSecurIDWait(this._reAuth);
   };

   public cancel = () => {
      this.cancelLoading = true;
      this.utilService.runFunctionIfNotHWSOrF5(() => {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"SecurID_Wait");
      });
   };
}
