<div class="modal-title dialog-title">{{title}}</div>
<div class="modal-body dialog-content">
   <div class="session-ops-window-text">{{message}}</div>
</div>

<div class="dialog-edit-box">
   <input type="text" value = {{inputTextData}} #inputTextBox (blur)="updateText(inputTextBox.value)" size="30">
</div>

<div class="modal-footer dialog-button-row">
   <button type="button" class="modal-button-base modal-button-blue ok-button" id="okDialogBtn" (click)="confirmModalWithInputText()">
      {{ (buttonLabelConfirm) || ('OK' | i18nv2) }}
   </button>
   <button type="button" class="modal-button-base modal-button-grey" id="cancelDialogBtn" (click)="cancelModalWithInputText()" >
      {{ (buttonLabelCancel) || ('CANCEL' | i18nv2) }}
   </button>
</div>