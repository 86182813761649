/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { XMLPreference } from "./prefdata";

export interface PreferenceInfo {
   action: string;
   text: string;
}

export abstract class UserGlobalPref {
   public abstract clearPrefData();
   public abstract toFlatFormat(changed?: boolean);
   public abstract setPrefData(data: XMLPreference);
   public abstract getPrefData(): XMLPreference;
   public abstract onDataSent();
   public abstract getPrefStringItem(key: string): string;
   public abstract getPrefBooleanItem(value: string): boolean;
   public abstract getPrefNumberItem(key: string): number;
   public abstract updatePrefData(data: PreferenceInfo, newKey: boolean);
   public abstract getAdminSettings();
}
