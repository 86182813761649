/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Monitor } from "../../../../../../shared/desktop/multimon/common/monitor-message";
import { CoordinateConverter } from "../../../../../../shared/utils/coordinate-converter";
import { BorderUtil } from "../../border-util";
import { RemoteappBorderService } from "../../../app-util/remoteapp-border.service";
import { clientUtil } from "@html-core";

export abstract class MultiMonitor {
   protected _remoteappBorderService: RemoteappBorderService = null;
   protected _coordinateConverter: CoordinateConverter = null;
   protected _isFocused = false;
   protected _sessionKey = "";
   protected _portNamePrefix = "REMOTEAPP-BORDER";

   protected abstract _sendMessageToOtherScreen(message);
   protected abstract _toLocalPoint(point);
   protected _currentWindow = chrome.app.window.current();

   constructor() {
      const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
      this._sessionKey = urlParams.get("sessionKey");
   }

   protected _getPortName = () => {
      return this._portNamePrefix + "_" + this._sessionKey;
   };

   protected _onMessage = (msg) => {
      if (msg.msgType === Monitor.BorderMoveMsg.TYPE) this._onMessageBorderMove(msg);
      else if (msg.msgType === Monitor.BorderHideMsg.TYPE) this._onMessageBorderHide();
      else if (msg.msgType === Monitor.BorderMouseUpMsg.TYPE) this._onMessageMouseUp(msg);
   };

   // @override when in PrimaryScreen
   protected _onMessageMouseUp = (message: Monitor.BorderMouseUpMsg) => {};

   public toLocalCoordinate = (rect) => {
      return this._toLocalCoordinate(rect);
   };

   public normalizeBorderRect = (rect) => {
      return this.normalizeRect(rect);
   };

   // @rect: {left:xx,top:xx,width:xx,height:xx}
   public normalizeRect = (rect) => {
      const leftTop = this._toLocalPoint({
         x: rect.left,
         y: rect.top
      });
      const rightBottom = this._toLocalPoint({
         x: rect.left + rect.width,
         y: rect.top + rect.height
      });
      return {
         left: leftTop.x,
         top: leftTop.y,
         width: rightBottom.x - leftTop.x,
         height: rightBottom.y - leftTop.y
      };
   };

   public fitForHighResolution = (rect) => {
      return rect;
   };

   public updateDrawingRect = (drawingRect) => {
      // move outside
      const localRect = this._toLocalCoordinate(drawingRect);
      if (
         localRect.left < 0 ||
         localRect.right > clientUtil.getScreenWidth() ||
         localRect.bottom > screen.height ||
         localRect.top < 0
      ) {
         const rect = this._coordinateConverter.normalizePoint(drawingRect);
         this._sendMessageToOtherScreen(new Monitor.BorderMoveMsg(rect));
      }
   };

   public getDetectionTarget = () => {
      const rebased = this._remoteappBorderService.topWindow.relativeTitleBarArea;
      const scaleLeftTop = this._coordinateConverter.scale({
         x: rebased.left,
         y: rebased.top
      });
      const scaleRightBottom = this._coordinateConverter.scale({
         x: rebased.right,
         y: rebased.bottom
      });
      const offset = this._toLocalPoint({
         x: this._remoteappBorderService.topWindow.clientSingleRect.left,
         y: this._remoteappBorderService.topWindow.clientSingleRect.top
      });
      return {
         left: Math.round(scaleLeftTop.x + offset.x),
         top: Math.round(scaleLeftTop.y + offset.y),
         right: Math.round(scaleRightBottom.x + offset.x),
         bottom: Math.round(scaleRightBottom.y + offset.y)
      };
   };

   public hideVisibleShape = () => {
      // reset the border focused status
      this._isFocused = false;
      this._remoteappBorderService.overlayCtlService.hideVisibleShape();
      this._sendMessageToOtherScreen(new Monitor.BorderHideMsg());
   };

   public setVisibleBorder = (drawingRect, localDrawingRect = null) => {
      const borderShape = BorderUtil.getBorderShape(drawingRect, true);
      if (localDrawingRect === null) {
         localDrawingRect = this._toLocalCoordinate(drawingRect);
      }
      this._remoteappBorderService.overlayCtlService.setVisibleShape(borderShape, localDrawingRect);
   };

   protected _onMessageBorderMove = (message: Monitor.BorderMoveMsg) => {
      // convert to local global
      const revertRect = this._coordinateConverter.revertRect(message.rect);
      // to local coordinate
      const localRect = this._toLocalCoordinate(revertRect);
      // the border in other screen is moving into current screen
      if (
         this._insideCurrentScreen(localRect.left, localRect.top) ||
         this._insideCurrentScreen(localRect.right, localRect.top) ||
         this._insideCurrentScreen(localRect.left, localRect.bottom) ||
         this._insideCurrentScreen(localRect.right, localRect.bottom)
      ) {
         // this if can make sure that only call "focus()" once during the process of moving
         if (!this._isFocused) {
            this._isFocused = true;
            this._currentWindow.focus();
         }
         this.setVisibleBorder(revertRect, localRect);
      }
   };

   protected _onMessageBorderHide = () => {
      this._isFocused = false;
      this._remoteappBorderService.overlayCtlService.hideVisibleShape();
   };

   protected _toLocalCoordinate = (rect) => {
      const p1 = this._toLocalPoint({
         x: rect.left,
         y: rect.top
      });
      const p2 = this._toLocalPoint({
         x: rect.right,
         y: rect.bottom
      });
      return {
         left: p1.x,
         top: p1.y,
         right: p2.x,
         bottom: p2.y
      };
   };

   private _insideCurrentScreen = (x, y) => {
      return x > 0 && x < clientUtil.getScreenWidth() && y > 0 && y < screen.height;
   };
}
