/**
 * ******************************************************
 * Copyright (C) 2015-2017, 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview resourceManager.ts -- ResourceManager
 * Service to handle resources(mainly audio-in and video-in devices) management
 *
 * currently we only allow devices to be used by one of the wmks session, and before it
 * releases the resources, other sesion can not get the resouce.
 */

import { Injectable } from "@angular/core";
import { MediaCapture } from "./mediaCapture";
import { MediaEncoder } from "./mediaEncoder";
import { EventBusService } from "@html-core";
import { Logger } from "@html-core";

@Injectable()
export class ResourceManager {
   private activeSessionID: string = null;
   private resourcePool = null;
   private deviceStatusChangeCallback;

   constructor(
      private mediaCapture: MediaCapture,
      private mediaEncoder: MediaEncoder,
      private eventBusService: EventBusService
   ) {
      this.activeSessionID = null;
      this.eventBusService.listen("rtavDeviceStatusChanged").subscribe(() => {
         this.emitDeviceStatusChanged();
      });
   }

   public initResourcesPool = (onDone) => {
      if (!this.resourcePool) {
         this.mediaEncoder.initInstance();
         this.resourcePool = {
            src: this.mediaCapture,
            mediaEnc: this.mediaEncoder
         };
      }
      onDone(this.resourcePool);
   };

   /**
    * Used to check whether there is any usable devices
    * currently we only allow device to be used by one desktop at a time.
    * @return {Boolean} This returns whether there is any usable devices
    */
   public hasUsableResources = () => {
      return this.activeSessionID === null;
   };

   /**
    * Used to check whether a session have usable devices, and if not we should not
    * process the RTAV function on that session.
    * currently we only have one session can work, so the condition is very simple.
    * @param {string}  sessionID The ID of the session that will be checked.
    * @return {Boolean} This returns whether the device has already been occupied
    */
   public hasOccupiedResources = (sessionID) => {
      return this.activeSessionID === sessionID;
   };

   /**
    * Used to occupy device for specified session.
    * Should only be call this API when hasOccupiedResources returns false, and hasUsableResources returns true.
    * @param {string} sessionID The ID of session that wants to occupy the devices
    * @param {function} onDone The callback to pass resources out, null means fail to occupy
    */
   public occupyResources = (sessionID, onDone) => {
      if (this.hasOccupiedResources(sessionID)) {
         Logger.error('session "' + sessionID + '" has already occupy a device', Logger.RTAV);
         onDone(null);
         return;
      }
      if (!this.hasUsableResources()) {
         Logger.error('there is no rest device for session "' + sessionID + '"', Logger.RTAV);
         onDone(null);
         return;
      }
      this.activeSessionID = sessionID;

      this.emitDeviceStatusChanged();

      this.initResourcesPool(onDone);
   };

   /**
    * Used to release device for specified session.
    * Should only be call this API when hasOccupiedResources returns true.
    * @param {string} sessionID The ID of session that wants to release the devices
    * @param {bool} forcibly Whether bypass the owner ship check to release owner's devices
    * @param {function} onDone The callback to pass resources out, null means fail to occupy
    */
   public releaseResources = (sessionID, forcibly?) => {
      if (!forcibly && !this.hasOccupiedResources(sessionID)) {
         Logger.error('session "' + sessionID + "\" hasn't occupied a device", Logger.RTAV);
         return;
      }
      this.activeSessionID = null;
   };

   /**
    * Set the callback function to detect the device status changes
    * @param {function} callback The callback function passed from the UI layer
    */
   public onDeviceStatusChanged = (callback) => {
      this.deviceStatusChangeCallback = callback;
   };

   /**
    * Trigger DeviceStatusChanged function from outside
    */
   public emitDeviceStatusChanged = () => {
      if (typeof this.deviceStatusChangeCallback === "function") {
         this.deviceStatusChangeCallback();
      }
   };

   /**
    * @return {string} This returns the current device using id, if not in use, return null
    */
   public getWorkingSessionId = () => {
      return this.activeSessionID;
   };

   /**
    * Let a new session steal device from current using session
    * @param  {string} sessionID The new session ID
    * @param  {function} onDone  The callback function once the device hand over is finished
    */
   public stealSession = (sessionID, onDone) => {
      if (!sessionID || typeof onDone !== "function") {
         return;
      }
      this.eventBusService.dispatch({
         type: "rtavUserStopSession",
         data: this.activeSessionID
      });
      this.occupyResources(sessionID, onDone);
   };

   /**
    * Close the devices for RTAV, later usage must require another premission
    * @param  {string} sessionID The ID of session from which this function is called
    * since we close device when not using them, there is no need to close them manully anymore
    */
   public closeDevices = (sessionID) => {
      if (!sessionID || !this.hasOccupiedResources(sessionID)) {
         return;
      }
      this.eventBusService.dispatch({
         type: "rtavUserStopSession",
         data: sessionID
      });
   };
}
