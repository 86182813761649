/**
 * ******************************************************
 * Copyright (C) 2015-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview rtav-service.js -- RTAVService
 * Service implement RTAV feature for WebClient
 *
 * It was using RTAV namespace.
 * Now the structure looks weird after refactory...
 */
import { Injectable } from "@angular/core";
import { RtavDialogService } from "./rtavDialog.service";
import { ResourceManager } from "./resourceManager";
import { ResourceManagerV2 } from "./v2/resourceManagerV2";
import { WmksAdapter } from "./wmksAdapter";
import { SessionMsg, RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { BlastWmks } from "../common/blast-wmks.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { clientUtil } from "../../../core/libs";
import { Logger } from "@html-core";

export type DeviceStatus = {
   activeSessionId: string;
   deviceInUsed: {
      audio: boolean;
      video: boolean;
   };
};

@Injectable()
export class RTAVService {
   constructor(
      private remoteSessionEventService: RemoteSessionEventService,
      private modalDialogService: ModalDialogService,
      private rtavDialogService: RtavDialogService,
      private wmksAdapter: WmksAdapter,
      private resourceManager: ResourceManager,
      private resourceManagerV2: ResourceManagerV2
   ) {
      if (!clientUtil.isChromeClient()) {
         this.setDialogHandler(this._confirmRTAVSessionSwitch);
      }
   }

   public init(rtavInitArgs?) {
      Logger.debug("constructing RTAV service", Logger.RTAV);
      if (!!rtavInitArgs) {
         Logger.debug("video codec enable H264 is " + rtavInitArgs.enableRTAVH264Codec, Logger.RTAV);
         Logger.debug("audio codec enable Opus is " + rtavInitArgs.enableRTAVOpusCodec, Logger.RTAV);
         Logger.debug("RTAV DTX mode is: " + rtavInitArgs.enableRTAVDTX, Logger.RTAV);
         Logger.debug(
            "video hardware acceleration option value get from admin is " + rtavInitArgs.hardwareAccelerationOption,
            Logger.RTAV
         );
      }
      if (!clientUtil.isChromeClient()) {
         if (this.remoteSessionEventService) {
            this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTING_MSG, (session: BlastWmks) => {
               if (!!rtavInitArgs) {
                  this.onConnecting(session.key, session.vdpService.vvcSession, rtavInitArgs);
               } else {
                  this.onConnecting(session.key, session.vdpService.vvcSession);
               }
            });
            this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTED_MSG, (session: BlastWmks) => {
               this.onConnected(session.key);
            });
            this.remoteSessionEventService.addEventListener(
               SessionMsg.SESSION_DISCONNECTED_MSG,
               (session: BlastWmks) => {
                  this.onDisconnected(session.key);
               }
            );
         } else {
            Logger.debug("skip since remoteSessionEventService absent when creating RTAV service", Logger.RTAV);
         }
      }
   }

   public onConnecting(key, vvcSession, rtavInitArgs?) {
      Logger.debug("rtav service onConnecting", Logger.RTAV);
      if (!!rtavInitArgs) {
         Logger.debug(
            "when rtavService onConnecting rtavInitArgs.enableRTAVH264Codec value is " +
               rtavInitArgs.enableRTAVH264Codec,
            Logger.RTAV
         );
         Logger.debug(
            "when rtavService onConnecting rtavInitArgs.enableRTAVOpusCodec value is " +
               rtavInitArgs.enableRTAVOpusCodec,
            Logger.RTAV
         );
         Logger.debug(
            "when rtavService onConnecting rtavInitArgs.enableRTAVDTX value is " + rtavInitArgs.enableRTAVDTX,
            Logger.RTAV
         );
         Logger.debug(
            "when rtavService onConnecting rtavInitArgs.hardwareAccelerationOption value is " +
               rtavInitArgs.hardwareAccelerationOption,
            Logger.RTAV
         );
         vvcSession.enableRTAVH264Codec = rtavInitArgs.enableRTAVH264Codec;
         vvcSession.enableRTAVOpusCodec = rtavInitArgs.enableRTAVOpusCodec;
         vvcSession.enableRTAVDTX = rtavInitArgs.enableRTAVDTX;
         vvcSession.hardwareAccelerationOption = rtavInitArgs.hardwareAccelerationOption;
      }
      return this.wmksAdapter.addSession(key, vvcSession);
   }

   public onConnected(key: string) {
      return this.wmksAdapter.activeSession(key);
   }

   public onDisconnected(key: string) {
      return this.wmksAdapter.removeSession(key);
   }

   public onDeviceStatusChanged(callback) {
      return this.resourceManager.onDeviceStatusChanged(callback);
   }

   public emitDeviceStatusChanged = () => {
      return this.resourceManager.emitDeviceStatusChanged();
   };

   public hasOccupiedResources = (sessionID) => {
      return (
         this.resourceManager.hasOccupiedResources(sessionID) || this.resourceManagerV2.hasOccupiedResources(sessionID)
      );
   };

   public occupyResources = (sessionID, onDone) => {
      this.resourceManager.occupyResources(sessionID, onDone);
   };

   public getWorkingSessionId = () => {
      return this.resourceManager.getWorkingSessionId();
   };

   public isUsingDevices = (sessionId, type?) => {
      if (!!type) {
         return this.wmksAdapter.isUsingDevices(sessionId, type);
      } else {
         return this.wmksAdapter.isUsingDevices(sessionId);
      }
   };

   public isAskingPermission = (sessionId, type) => {
      return this.wmksAdapter.isAskingPermission(sessionId, type);
   };

   public setDialogHandler(callback) {
      return this.rtavDialogService.setDialogFunction(callback);
   }

   public hasSession(key) {
      return this.wmksAdapter.hasSession(key);
   }

   private _confirmRTAVSessionSwitch = (info, okCallback, cancelCallback) => {
      if (this.modalDialogService) {
         this.modalDialogService.showError({
            data: {
               titleKey: "RTAV_DEVICE_CONFLICT_T",
               contentKey: "RTAV_DEVICE_CONFLICT_M",
               buttonLabelConfirmKey: "OK"
            }
         });
         // call the cancelCallback here for we only need to show a error
         // dialog, and then we could send response to the agent.
         cancelCallback();
      }
   };
}
