/**
 * ******************************************************
 * Copyright (C) 2020-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { VDP_CONSTS } from "./vdp-constants";

export interface IUserRPC {
   command: number | string;
   type: any;
   params: number | Uint8Array[];
   toCompress: boolean;
   object?: {
      id: number;
      name: string;
   };
   objID?: number;
   objName: string;
   onDone: IRPC;
   onAbort: IRPC;
}

export interface IRPC {
   command: number | string;
   action: number;
   contexts: number[];
   params: (number | string | Uint8Array)[];
   returnCode: number;
   returnParams: (number | string | Uint8Array)[];
   abort: number;
   userCancelled: number;
   abortCode: number;
   type: string;
}
