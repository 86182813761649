/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { MediaType } from "../../../media/media-resource-manager";
import { HTML5MMR_CONST } from "../html5MMR-consts";
import { HTML5MMR } from "./html5MMR.rpc.models";

/**
 * NotifyAudio Namespace
 */
export namespace NotifyAudio {
   /**
    * NotifyAudio.Action enumeration that represents either play or pause media action
    */
   export enum Action {
      play = "play",
      pause = "pause"
   }

   export class Request {
      public action: NotifyAudio.Action;
      public audioId: string;
      public src: string;
      public loop: boolean;
      public blob: Uint8Array;

      constructor(data: any) {
         this.action = data.action === "play" ? Action.play : Action.pause;
         this.audioId = data.audid;
         this.src = data.src; /// TODO: should use audio binary data as UInt8Array
         this.loop = data.loop;
      }
   }
}

/**
 * Update volume request
 */
export namespace UpdateVolume {
   export class Request {
      public mute: boolean;
      public volume: number;

      constructor(data: any) {
         if (data === null || data.mute === null || data.volume === null) {
            throw new Error("Invalid update volume request data: " + JSON.stringify(data));
         }
         this.mute = data.mute;
         this.volume = data.volume;
      }
   }
}

export namespace NewVideoElement {
   export class Request {
      public videoId: number;
      public left: number;
      public right: number;
      public top: number;
      public bottom: number;
      public width: number;
      public height: number;
      public id: number;
      public peer: number;
      public commandId: number;

      constructor(data: any) {
         this.videoId = data.videoId;
         this.left = data.left;
         this.right = data.right;
         this.top = data.top;
         this.bottom = data.bottom;
         this.width = data.width;
         this.height = data.height;
         this.id = data.id;
         this.peer = data.peer;
         this.commandId = data.commandId;
      }
   }
}

export namespace TrackEnabled {
   export class Request {
      public trackId: string;
      public enabled: boolean;
      public commandId: number;
      public id: number;
      public peer: number;

      constructor(data: any) {
         this.trackId = data.trackId;
         this.enabled = data.enabled;
         this.commandId = data.commandId;
         this.id = data.id;
         this.peer = data.peer;
      }
   }
}

export namespace MediaStreamClone {
   export class StreamClone {
      public sid: string;
      public cid: string;
      constructor(sid: string, cid: string) {
         this.sid = sid;
         this.cid = cid;
      }
   }
   export class TrackClone {
      public tid: string;
      public cid: string;
      constructor(tid: string, cid: string) {
         this.tid = tid;
         this.cid = cid;
      }
   }
   export class Request {
      public streamClone: StreamClone;
      public trackClones: TrackClone[];
      public commandId: number;
      public id: number;

      constructor(data: any) {
         this.streamClone = new StreamClone(data.sid, data.cid);
         const tClones: TrackClone[] = data.trackClones;
         this.trackClones = tClones.map((each) => new TrackClone(each.tid, each.cid));
         this.commandId = data.commandId;
         this.id = data.id;
      }
   }
}

export namespace UpdateSrcObject {
   export class Request {
      public commandId: number;
      public mediaStreamId: string;
      public videoId: number;
      public audioId: number;
      public type: MediaType;
      public id: number;
      public peer: number;

      constructor(data: any) {
         this.commandId = data.commandId;
         this.mediaStreamId = data.mediaStreamId;
         this.videoId = data.videoId;
         this.audioId = data.audioId;
         this.type = data.type;
         this.id = data.id;
         this.peer = data.peer;
      }
   }
}

export namespace UpdateVideo {
   export class Body {
      public left: number;
      public right: number;
      public bottom: number;
      public top: number;
      public width: number;
      public height: number;
      constructor(body: any) {
         this.left = body.left;
         this.right = body.right;
         this.top = body.top;
         this.bottom = body.bottom;
         this.width = body.width;
         this.height = body.height;
      }
   }
   export class Request {
      public videoId: number;
      public body: Body;
      public left: number;
      public right: number;
      public bottom: number;
      public top: number;
      public width: number;
      public height: number;
      public objectFit: string;
      public commandId: number;
      public id: number;
      public peer: number;
      constructor(data: any) {
         this.videoId = data.videoId;
         this.left = data.left;
         this.right = data.right;
         this.top = data.top;
         this.bottom = data.bottom;
         this.width = data.width;
         this.height = data.height;
         this.objectFit = data.objectFit;
         this.commandId = data.commandId;
         this.id = data.id;
         this.peer = data.peer;
         if (data && data.body) {
            this.body = new Body(data.body);
         }
      }
   }
}

export namespace RemoveVideo {
   export class Request {
      public videoId: number;
      public commandId: number;
      public id: number;
      public peer: number;
      constructor(data: any) {
         this.videoId = data.videoId;
         this.commandId = data.commandId;
         this.id = data.id;
         this.peer = data.peer;
      }
   }
}

export namespace MediaTrkStop {
   export class Request {
      public trkid: string;
      public commandId: number;
      public id: number;
      public peer: number;
      constructor(data: any) {
         this.trkid = data.trkid;
         this.commandId = data.commandId;
         this.id = data.id;
         this.peer = data.peer;
      }
   }
}

export namespace UpdateDPI {
   export class Request {
      public boundingCX: number;
      public boundingCY: number;
      public guestOriginX: number;
      public guestOriginY: number;
      public dpi: number;

      constructor(data: any) {
         this.dpi = data.hasOwnProperty("updatedDpi") ? data["updatedDpi"] : data["dpi"];
         this.boundingCX = data["bounding_cx"];
         this.boundingCY = data["bounding_cy"];
         this.guestOriginX = data["guestorigin-x"];
         this.guestOriginY = data["guestorigin-y"];
      }
   }
}

export namespace UpdateOverlay {
   export class Request {
      public videoId: number;
      public enabled: boolean;
      public visible: boolean;
      public colorKey: string;
      public top: number;
      public left: number;
      public width: number;
      public height: number;
      public count: number;
      public rgnData: Uint8Array;
      public rgnDataSize: number;
      public dpiScaleFactor: number;

      constructor(data: any, dpiScaleFactor: number) {
         this.videoId = data.videoId;
         this.enabled = data.enabled;
         this.visible = data.visible;
         this.colorKey = data.colorKey;
         this.top = data.top;
         this.left = data.left;
         this.width = data.width;
         this.height = data.height;
         this.count = data.count;
         this.rgnData = data.rgnData;
         this.rgnDataSize = data.rgnDataSize;
         this.dpiScaleFactor = dpiScaleFactor;
      }

      public updateRect = (rect: VMRect) => {
         this.left = rect.left;
         this.top = rect.top;
         this.width = rect.width;
         this.height = rect.height;
      };
   }
}

export namespace CreateInstance {
   export class Request {
      public colorKey: string;
      public guestOriginX: number;
      public guestOriginY: number;
      public supportObjectFit: boolean;
      constructor(data: any) {
         this.colorKey = data.colorKey;
         this.guestOriginX = data["guestorigin-x"];
         this.guestOriginY = data["guestorigin-y"];
         this.supportObjectFit = data["supportObjectFit"] === 1;
      }
   }
}

export class VMPoint {
   public x: number;
   public y: number;

   constructor(x: number, y: number) {
      this.x = x;
      this.y = y;
   }
}

export class VMSize {
   public width: number;
   public height: number;

   constructor(width: number, height: number) {
      this.width = width;
      this.height = height;
   }

   public equal = (other: VMSize): boolean => {
      return other && other.width === this.width && other.height === this.height;
   };
}

export class VMRect {
   public left: number;
   public top: number;
   public width: number;
   public height: number;

   constructor(left: number, top: number, width: number, height: number) {
      this.left = left;
      this.top = top;
      this.width = width;
      this.height = height;
   }

   /**
    * Build a new VMRect by given binary data and offset
    *
    * @param  {Uint8Array} data  region binary data as {Uint8Array}
    * @param  {number} offset    position start to read
    * @returns VMRect
    */
   public static build = (data: Uint8Array, offset: number): VMRect => {
      const left = read32Bits(data, offset),
         top = read32Bits(data, offset + HTML5MMR_CONST.BINARY_DATA_LEN),
         right = read32Bits(data, offset + HTML5MMR_CONST.BINARY_DATA_LEN * 2),
         bottom = read32Bits(data, offset + HTML5MMR_CONST.BINARY_DATA_LEN * 3);
      return new VMRect(left, top, right - left, bottom - top);
   };
}

export class OverlayRegion {
   private dwSize: number;
   private iType: number;
   public rectCount: number;
   private rectDataLength: number;
   private rectBound: VMRect;
   public rects: Array<VMRect> = new Array<VMRect>();

   constructor(rgnData: Uint8Array, rgnDataLength: number) {
      if (rgnData != null && rgnData != undefined) {
         let currOffset: number = 0;
         // parse through region data
         this.dwSize = read32Bits(rgnData, currOffset);
         this.iType = read32Bits(rgnData, (currOffset += HTML5MMR_CONST.BINARY_DATA_LEN));
         this.rectCount = read32Bits(rgnData, (currOffset += HTML5MMR_CONST.BINARY_DATA_LEN));
         this.rectDataLength = read32Bits(rgnData, (currOffset += HTML5MMR_CONST.BINARY_DATA_LEN));

         // parse rectangle bound
         this.rectBound = VMRect.build(rgnData, (currOffset += HTML5MMR_CONST.BINARY_DATA_LEN));

         // parse rectangles
         this.rects = new Array<VMRect>(this.rectCount);
         for (let i = 0; i < this.rects.length; i++) {
            this.rects[i] = VMRect.build(rgnData, (currOffset += HTML5MMR_CONST.BINARY_DATA_LEN * 4));
         }
      }
   }
}

export class OverlayInfo {
   public videoId: number;
   public enabled: boolean;
   public visible: boolean;
   public colorKey: string;
   public top: number;
   public left: number;
   public width: number;
   public height: number;
   public count: number;
   public region: OverlayRegion;
   public dpiScaleFactor: number;
   private originalData: any;

   constructor(data: any) {
      this.dpiScaleFactor = data.dpiScaleFactor;
      this.videoId = data.videoId;
      this.enabled = data.enabled;
      this.visible = data.visible;
      this.colorKey = data.colorKey;
      this.top = data.top / this.dpiScaleFactor;
      this.left = data.left / this.dpiScaleFactor;
      this.width = data.width / this.dpiScaleFactor;
      this.height = data.height / this.dpiScaleFactor;
      this.count = data.count;
      this.originalData = data;
      this.region = new OverlayRegion(data.rgnData, data.rgnDataSize);
   }

   public get area(): number {
      return this.width * this.height;
   }

   public get regionRects(): Array<VMRect> {
      return this.region.rects;
   }

   public updateRegionRects = (rects: Array<VMRect>) => {
      this.region.rects = rects;
   };

   public get regionMask(): string {
      const rects = this.region.rects;
      let clipPath = "";
      // TODO: More complex rendering logic is needed here to deal with multi monitor situations
      for (const rect of rects) {
         // Ignore any rects that are out of bounds
         if (this.isRectOutOfBounds(rect)) {
            continue;
         }
         const rleft = rect.left / this.dpiScaleFactor;
         const rtop = rect.top / this.dpiScaleFactor;
         const rheight = rect.height / this.dpiScaleFactor;
         const rwidth = rect.width / this.dpiScaleFactor;
         const x1 = rleft;
         const y1 = rtop;
         const x2 = rleft;
         const y2 = rtop + rheight;
         const x3 = rleft + rwidth;
         const y3 = y2;
         const x4 = x3;
         const y4 = y1;
         const path = `M ${x1} ${y1} L ${x2} ${y2} L ${x3} ${y3} L ${x4} ${y4} Z`;
         clipPath = clipPath.concat(path);
      }
      return clipPath;
   }

   /**
    * Checks if any rectangle is out of bounds of the original region area.
    * Currently being used to stop 1 pixel width rects from being displayed on the boundaries.
    * @param rect
    * @returns
    */
   private isRectOutOfBounds = (rect: VMRect) => {
      if (
         rect.left >= this.originalData.width ||
         rect.top < 0 ||
         rect.top >= this.originalData.height ||
         rect.left < 0
      ) {
         return true;
      }
      return false;
   };
}

export class VideoParameters {
   public left: number;
   public right: number;
   public top: number;
   public bottom: number;
   public width: number;
   public height: number;
   public regionMask: string;
   public objectFit: string;
   constructor(data: any) {
      if (data == null) {
         return;
      }
      this.left = data.left;
      this.right = data.right;
      this.top = data.top;
      this.bottom = data.bottom;
      this.width = data.width;
      this.height = data.height;
      this.regionMask = data.regionMask;
      this.objectFit = data.objectFit;
   }
}

export class VideoItem {
   public instanceId: number;
   public videoId: number;
   public guestOrigin: VMPoint;
   public supportObjectFit: boolean;
   public objectFit: string;
   public overlayInfo: OverlayInfo;
   constructor(instanceId: number, videoId: number, guestOrigin: VMPoint, supportObjectFit: boolean) {
      this.instanceId = instanceId;
      this.videoId = videoId;
      this.guestOrigin = guestOrigin;
      this.supportObjectFit = supportObjectFit;
   }

   public get area(): number {
      if (!this.overlayInfo) {
         return 0;
      }
      return this.overlayInfo.area;
   }

   public updateOverlayInfo = (info: OverlayInfo): VideoParameters => {
      this.overlayInfo = info;
      return this.updateOverlay();
   };

   public updateGuestOrigin = (x: number, y: number): VideoParameters => {
      this.guestOrigin = new VMPoint(x, y);
      return this.updateOverlay();
   };

   public updateObjectFit = (objectFit: string) => {
      this.objectFit = objectFit;
   };

   public updateOverlay = (): VideoParameters => {
      if (this.overlayInfo && this.overlayInfo.enabled) {
         const screenPos: VMPoint = new VMPoint(this.overlayInfo.left, this.overlayInfo.top);
         return new VideoParameters({
            left: screenPos.x,
            top: screenPos.y,
            width: this.overlayInfo.width,
            height: this.overlayInfo.height,
            right: screenPos.x + this.overlayInfo.width,
            bottom: screenPos.y + this.overlayInfo.height,
            regionMask: this.overlayInfo.regionMask,
            objectFit: this.objectFit
         });
      }
      return null;
   };
}

export class BrowserRegion {
   private guestOrigin: VMPoint = new VMPoint(0, 0);
   private overlayInfo: OverlayInfo = null;

   private isValidInfo = (): boolean => {
      return this.overlayInfo && this.overlayInfo.enabled;
   };
   public get browserOverlayInfo(): OverlayInfo {
      return this.isValidInfo() ? this.overlayInfo : null;
   }

   public updateOverlay = (cmdObj: HTML5MMR.MMRUpdateOverlayRequest, remoteDPIScaleFactor: number) => {
      const request = new UpdateOverlay.Request(cmdObj, remoteDPIScaleFactor);
      this.overlayInfo = new OverlayInfo(request);
   };

   public updateGuestOrigin = (payload: any) => {
      if (payload && payload.hasOwnProperty("guestorigin-x") && payload.hasOwnProperty("guestorigin-y")) {
         this.guestOrigin.x = payload["guestorigin-x"];
         this.guestOrigin.y = payload["guestorigin-y"];
      }
      this.updateOverlayInfo();
   };

   private updateOverlayInfo = () => {
      if (!this.isValidInfo()) {
         return;
      }
      this.overlayInfo.left = this.guestOrigin.x;
      this.overlayInfo.top = this.guestOrigin.y;
   };
}

/**
 * Read 4 bytes of an Uint8Array with given position
 *
 * @param  {Uint8Array} data  Uint8 data array
 * @param  {number} offset position start to read
 *
 * @return {number} the integer number that these 4 bytes represnt
 */
function read32Bits(data: Uint8Array, offset: number): number {
   let res = null;
   res =
      (0xff & data[0 + offset]) |
      ((0xff & data[1 + offset]) << 8) |
      ((0xff & data[2 + offset]) << 16) |
      (0xff & (data[3 + offset] << 24));

   return res;
}
