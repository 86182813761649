/**
 * ******************************************************
 * Copyright (C) 2017-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Input, ElementRef, AfterViewInit, Directive } from "@angular/core";
import { RemoteImageService } from "../service/remote-image.service";

@Directive({
   selector: "[icon-load]"
})
export class iconLoadDirective implements AfterViewInit {
   @Input("url") relativeImageUrl: string;
   @Input("alter") replaceIcon?: string = "true";
   constructor(
      private remoteImageService: RemoteImageService,
      private _el: ElementRef
   ) {}

   private canLazyLoad() {
      return window && "IntersectionObserver" in window;
   }

   private LazyLoadingImage() {
      const obs = new IntersectionObserver((entries) => {
         entries.forEach(({ isIntersecting }) => {
            if (isIntersecting) {
               this.loadImage();
               obs.unobserve(this._el.nativeElement);
            }
         });
      });
      obs.observe(this._el.nativeElement);
   }

   private loadImage = () => {
      this.remoteImageService.requestRemoteImageAndLoad(this.relativeImageUrl, this._el.nativeElement).catch((e) => {
         // Use default Icon when fail to fetch icon from backend.
         if (this.replaceIcon !== "false") {
            $(this._el.nativeElement).addClass("replace-icon");
         }
      });
   };

   ngAfterViewInit() {
      if (!this.relativeImageUrl) {
         // Use default Icon when there is no icon url for application.
         if (this.replaceIcon !== "false") {
            $(this._el.nativeElement).addClass("replace-icon");
         }
         return;
      }

      if (this.canLazyLoad()) {
         this.LazyLoadingImage();
      } else {
         this.loadImage();
      }
   }
}
