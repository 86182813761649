/**
 * ***************************************************************************
 * Copyright 2018 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

/**
 *------------------------------------------------------------------------------
 *
 * feature-config.js
 *
 * Config service introduced for CDR, since it have too many logs.
 *
 * using this we can filter logs of specified feature and reduce the debug effort
 *
 *------------------------------------------------------------------------------
 */

export class PrefixLogger {
   public static readonly LOGGER_NAMES = ["trace", "debug", "info", "warning", "error"];
   constructor(
      private logger: any,
      prefix: string
   ) {
      for (let i = 0; i < PrefixLogger.LOGGER_NAMES.length; i++) {
         const loggerName = PrefixLogger.LOGGER_NAMES[i];
         this[loggerName] = function (text) {
            logger[loggerName](prefix + " feature log: " + text);
         };
      }
   }
}

export function FeatureLogger(prefix: string) {
   return function (logger) {
      return new PrefixLogger(logger, prefix);
   };
}
