/**
 * *****************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";
import { EventBusService } from "../../../core/services/event";

@Injectable()
export class FileHandlerCacheManagerService {
   public fileHandlers = [];
   constructor(private eventBusService: EventBusService) {}

   public addFileHandlerToCacheList = async (fileEntry, handler) => {
      const isDuplicated = await this.isDuplicatedHandler(fileEntry);
      if (!isDuplicated) {
         if (this.fileHandlers.length === 0) {
            this.eventBusService.dispatch({
               type: "fileIsWriting",
               data: true
            });
         }
         this.fileHandlers.push({
            entry: fileEntry,
            writeHandler: handler
         });
      }
   };

   private isDuplicatedHandler = async (fileEntry) => {
      for (let i = 0; i < this.fileHandlers.length; i++) {
         const isSame = await this.fileHandlers[i].entry.fileHandler.resolve(fileEntry.fileHandler);
         if (isSame) {
            return true;
         }
      }
      return false;
   };

   public releaseAllWriteHandlers = () => {
      for (let i = this.fileHandlers.length - 1; i >= 0; i--) {
         this.fileHandlers[i].writeHandler.close();
         this.fileHandlers.splice(i, 1);
      }
   };

   public releaseWriteHandler = (fileEntry) => {
      for (let i = this.fileHandlers.length - 1; i >= 0; i--) {
         if (this.fileHandlers[i].entry === fileEntry) {
            if (this.fileHandlers[i].writeHandler) {
               Logger.debug("Release write handler for file " + fileEntry.name);
               this.fileHandlers[i].writeHandler.close();
               fileEntry.writer.addedInCache = false;
               fileEntry.writer.handler = null;
               this.fileHandlers.splice(i, 1);
            } else {
               Logger.debug("Write handler is empty" + fileEntry.name);
            }
            break;
         }
      }
   };
}
