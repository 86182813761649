/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import JSCDKBrokerError from "../../jscdk/model/jscdk-broker-error.consts";
import { JscdkWrapper } from "../../common/jscdk/jscdk-wrapper";
import { EventBusService } from "../../../core/services/event";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { BaseViewService } from "../../common/service/base-view.service";
import { TranslateService } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class ReauthDialogService {
   constructor(
      private jscdkWrapper: JscdkWrapper,
      private eventBusService: EventBusService,
      private modalDialogService: ModalDialogService,
      private baseViewService: BaseViewService,
      private translate: TranslateService
   ) {}

   private loginCallback = (response, onDone) => {
      if (typeof response === "object" && !response.success) {
         if (response.error && response.error.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_AUTHENTICATION_FAILED) {
            this.baseViewService.gotoPortal();
         } else if (
            response.error &&
            response.error.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_ALREADY_AUTHENTICATED
         ) {
            // already authenticated close dialog
            if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
               this.modalDialogService.closeDialogByType("reauth-window");
            }
            //launch app
            if (onDone) {
               onDone();
            }
         } else if (response.error && response.error.errorMessage === "Access Denied") {
            this.eventBusService.dispatch({
               type: "reauthError",
               data: response.error
            });
         }
      } else {
         // treat all other cases as success
         if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
            this.modalDialogService.closeDialogByType("reauth-window");
         }
         //launch app
         if (onDone) {
            onDone();
         }
      }
   };

   public setReAuthCallBack = () => {
      this.jscdkWrapper.setCallBackForReAuth(this.loginCallback);
   };
}
