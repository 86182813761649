/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export type BlastOption = {
   wmksService: any;
   key: string;
   isApp: boolean;
   isShadow: boolean;
   name: string;
   reconnectToken: string;
   triedSSLVerify: boolean;
   isMultiSession: boolean;
   enableUsb: boolean;
   usbTicket: string;
   redirectSetting?: ProtocolRedirectSettings;
   brokerUrl?: string;
   sessionId?: string;
   dspecId?: string;
   smartCardDummyService?: any;
   htmlCdrService?: any;
   isWindows365?: boolean;
};

export enum PROTOCOL_RECOVERY_STATE {
   UNKNOWN,
   PENDING,
   DONE
}
