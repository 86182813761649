/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class RdsLicenseInfoService {
   public abstract getRdsLicense(): string;
   public abstract isRdsLicenseEnabled(): boolean;
}
