/**
 * ******************************************************
 * Copyright (C) 2015-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * blastsessions-model.ts --
 *
 * Model to wrap the singleSessionAppSessionService to provide blast application
 * sessions information for reconnection.
 *
 */

import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { SingleSessionAppSessionService } from "../service/single-session.service";

@Injectable({
   providedIn: "root"
})
export class BlastSessionsModel {
   constructor(private singleSessionAppSessionService: SingleSessionAppSessionService) {}

   //private function:
   /**
    * isNotValidString
    * isInvalidBlastSession
    * hasBlastInfoIn
    * getFormatConvertedInfo
    * hasSessionInfoInStorage
    */

   private isNotValidString = (data) => {
      return typeof data !== "string" || data.trim() === "";
   };

   private isInvalidBlastSession = (sessionInfo) => {
      if (
         !sessionInfo ||
         this.isNotValidString(sessionInfo.applicationSessionId) ||
         sessionInfo.protocolIsBlast === false ||
         typeof sessionInfo.needNewConnectionWithBlast !== "boolean" ||
         !sessionInfo.content ||
         this.isNotValidString(sessionInfo.content.originId)
      ) {
         return true;
      }

      return false;
   };

   private hasBlastInfoIn = function (sessionInfo) {
      return sessionInfo.needNewConnectionWithBlast;
   };

   //public function:
   /**
    * Designed for read out and fill the lacking data of the
    * sessionInfoList for app reconnection.
    * side effect: also write data.
    * implement like this to increase efficiency and provide a simple API.
    */
   public getFullSessionInfoWith = (sessionInfoList) => {
      const validSessionInfoList = [];

      for (let i = 0; i < sessionInfoList.length; i++) {
         const sessionInfo = sessionInfoList[i];
         if (this.isInvalidBlastSession(sessionInfo)) {
            Logger.error("invalid session " + JSON.stringify(sessionInfo));
            return [];
         }

         let sessionData = sessionInfo.content;
         const originId = sessionData.originId;
         const sessionId = sessionData.sessionId;
         if (this.hasBlastInfoIn(sessionInfo)) {
            sessionData = sessionInfo.content;
            this.singleSessionAppSessionService.cacheSingleSessionLaunchData(sessionData);
         } else {
            sessionData = this.singleSessionAppSessionService.fetchSingleSessionLaunchData(originId, sessionId);
         }
         if (sessionData) {
            sessionData.blastUrl = sessionData.url;
            validSessionInfoList[i] = sessionData;
         } else {
            validSessionInfoList[i] = {};
         }
      }
      return validSessionInfoList;
   };
}
