/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

//
/**
 * Returns the culture language code name from the browser, e.g. "de-DE"
 *
 * https://github.com/ngx-translate/core/blob/3a4c7ee9e56a86f72a42dd3590122b0db7667779/projects
 * /ngx-translate/core/src/lib/translate.service.ts#L532
 *
 */

export function getBrowserCultureLang(): string | undefined {
   if (typeof window === "undefined" || typeof window.navigator === "undefined") {
      return "en-US";
   }

   let browserCultureLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
   browserCultureLang =
      browserCultureLang ||
      window.navigator.language ||
      //@ts-ignore
      window.navigator.browserLanguage ||
      //@ts-ignore
      window.navigator.userLanguage;

   return browserCultureLang || "en-US";
}
