/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { CoreModule } from "@html-core";
import { UtilsModule } from "../../shared/utils/utils.module";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { SharedCommonModule } from "./shared.common.module";
import { CommonModule } from "@angular/common";

@NgModule({
   imports: [CommonModule, SharedCommonModule, UtilsModule, CoreModule],
   declarations: [],
   schemas: [NO_ERRORS_SCHEMA],
   exports: [SharedCommonModule, UtilsModule, CoreModule]
})
export class ClientCommonModule {}
