/**
 * ******************************************************
 * Copyright (C) 2014-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * unity.js --
 *
 * Interface used to send and receive Unity RPCs and updates.
 * The full Unity documentation can be found in bora/public/unityCommon.h and
 * the RPC constants referenced in the code are described there.
 *
 * Consumers of this interface should set the following optional listeners in
 * the object after it is instantiated.
 */

import { StringUtils } from "@html-core";
import Logger from "../../../core/libs/logger";
import { VDP_CONSTS, VDPXdrBuffer } from "../vdpservice";
import { RDEChannel } from "../channels/rde-main-channel";
import { BlastWmks } from "../common/blast-wmks.service";

export namespace Unity {
   export interface UnityOps {
      /*
       * Called when Unity window is added.
       *    windowId         ID of window to add.
       *    windowPath       String tying the window ID to an executable.
       *    execPath         String uniquely identifying just the executable.
       */
      onAdd(windowId: string, winPath: string, execPath: string);

      /*
       * Called when Unity window is removed.
       *    windowId         ID of window to remove.
       */
      onRemove(windowId: string);

      /*
       * Called when Unity window title changes.
       *    windowId          ID of window to update.
       *    windowTitle       New window title.
       */
      onTitleChanged(windowId: string, windowTitle: string);

      /*
       * Called when Unity window attribute changes.
       *    windowId          ID of window to update.
       *    type              Attribute type.
       *    value             Attribute value (boolean).
       */
      onAttrChanged(windowId: string, type: UnityWindowAttribute, value: boolean);

      /*
       * Called when Unity window type changes.
       *    windowId         ID of window whose type changed.
       *    type             New window type.
       */
      onTypeChanged(windowId: string, type: UnityWindowType);

      /*
       * Called when Unity window are moved.
       *    windowId         ID of window whose type changed.
       *    rect             New position.
       */
      onWindowMoved(windowId: string, rect: any);

      /*
       * Called when Unity title bar are are changed.
       *    windowId         ID of window whose type changed.
       *    rect             New position.
       */
      onTitleBarAreaChanged(windowId: string, windowPositionInfo: any);

      onPrimaryWindowUpdate(windowId: string, primary: string);

      onSecondaryWindowUpdate(windowId: string, secondaryWindows: any[]);

      onTrayIconChanged(icons: any);

      onRegionUpdate(windowId: string, regions: any[]);

      onZorderUpdate(components: any);
      /*
       * Called when Unity window icon changes.
       *    windowId         ID of window whose icon changed.
       */
      onIconChanged(windowId: string);

      /*
       * Called when the remote desktop signals a change in whether or not it is ready for Unity.
       *    ready            true if ready, false if not ready.
       */
      onReadyChanged(ready: boolean, isOn?: boolean, paused?: boolean);

      /*
       * Called when the remote desktops signals a change in whether Unity is on or off.
       *    active           true if Unity is on, false if it is off.
       */
      onActiveChanged(active: boolean);

      /*
       * Called when the remote desktop signals their capabilities.
       *     caps             Array containing capabilities that the server advertised.
       */
      onCapsChanged(caps: any[]);

      /*
       * Called after a Unity update (RPC_PUSH_UPDATE_CMD) has been completely parsed
       * and one or more listeners called.
       */
      onUpdateComplete();
      onVisibilityChanged(show: boolean);
      onAppAttrChanged(windowPath: string, execPath: string, name: string, iconSrc: any);

      /**
       * Called when Unity window URL redirection.
       */
      onUrlRedirection(url: string);
   }
   /**
    * These constants represent the various capabilities that we could receive
    * from the server.  Note: they are *not* bit masks.
    *
    * Refer to bora/public/remoteUnity.h for more info.
    */
   export enum UnitySvcCapType {
      CAP_UNITY = 1,
      CAP_STATUS = 2,
      CAP_WORK_AREA = 3,
      CAP_MULTIMON = 4,
      CAP_TASKBAR = 5,
      CAP_WINDOW_CONTENTS = 6,
      CAP_SHELL_ACTION_RUN = 7,
      CAP_SET_FOCUSED_WINDOW = 8,
      CAP_MOUSE_BUTTON_SWAPPING = 9,
      CAP_SET_APP_ENTITLEMENT_MAP = 10,
      CAP_TRAY_ICONS = 11
   }

   export enum UnityWindowType {
      /**
       * These constants represent possible window types for Unity windows.
       * Some may be deprecated, so refer to bora/public/unityCommon.h for more
       * info.
       */
      WINDOW_TYPE_NONE = -1,
      WINDOW_TYPE_NORMAL = 0,
      WINDOW_TYPE_PANEL = 1,
      WINDOW_TYPE_DIALOG = 2,
      WINDOW_TYPE_MENU = 3,
      WINDOW_TYPE_TOOLTIP = 4,
      WINDOW_TYPE_SPLASH = 5,
      WINDOW_TYPE_TOOLBAR = 6,
      WINDOW_TYPE_DOCK = 7,
      WINDOW_TYPE_DESKTOP = 8,
      WINDOW_TYPE_COMBOBOX = 9,
      WINDOW_TYPE_WIDGET = 10,
      WINDOW_TYPE_METRO_OBSOLETE = 11,
      WINDOW_TYPE_START_SCREEN = 12,
      WINDOW_TYPE_SLIDE_IN_PANEL = 13,
      WINDOW_TYPE_TASKBAR = 14,
      WINDOW_TYPE_METRO_FULLSCREEN_APP = 15,
      WINDOW_TYPE_METRO_DOCKED_APP = 16,
      MAX_WINDOW_TYPES = 17 // Final, sentinel attribute entry.
   }

   /**
    * These constants represent the features bitmask which can be provided
    * to Unity.Mgr.On().
    *
    * Refer to bora/public/unityCommon.h for more info.
    */
   export enum UnityFeatures {
      ADD_HIDDEN_WINDOWS_TO_TRACKER = 1,
      INTERLOCK_MINIMIZE_OPERATION = 2,
      SEND_WINDOW_CONTENTS = 4,
      DISABLE_COMPOSITING_IN_GUEST = 8,
      DISABLE_MOUSE_BUTTON_SWAPPING = 16,
      SHOW_FLOATING_LANGUAGE_BAR = 32
   }

   /**
    * These constants represent possible window attributes for Unity windows.
    * Some may be deprecated, so refer to bora/public/unityCommon.h for more
    * info.
    */
   export enum UnityWindowAttribute {
      WINDOW_ATTR_BORDERLESS = 0,
      WINDOW_ATTR_MINIMIZABLE = 1,
      WINDOW_ATTR_MAXIMIZABLE = 2,
      WINDOW_ATTR_MAXIMIZED = 3,
      WINDOW_ATTR_CLOSABLE = 5,
      WINDOW_ATTR_HAS_TITLEBAR = 6,
      WINDOW_ATTR_VISIBLE = 7,
      WINDOW_ATTR_CHILD_WINDOW = 8,
      WINDOW_ATTR_HAS_TASKBAR_BTN = 9,
      WINDOW_ATTR_MOVABLE = 10,
      WINDOW_ATTR_RESIZABLE = 11,
      WINDOW_ATTR_ALWAYS_ABOVE = 12,
      WINDOW_ATTR_ALWAYS_BELOW = 13,
      WINDOW_ATTR_DISABLED = 14,
      WINDOW_ATTR_NOACTIVATE = 15,
      WINDOW_ATTR_SYSMENU = 16,
      WINDOW_ATTR_TOOLWINDOW = 17,
      WINDOW_ATTR_APPWINDOW = 18,
      WINDOW_ATTR_FULLSCREENABLE = 19,
      WINDOW_ATTR_FULLSCREENED = 20,
      WINDOW_ATTR_ATTN_WANTED = 21,
      WINDOW_ATTR_SHADEABLE = 22,
      WINDOW_ATTR_SHADED = 23,
      WINDOW_ATTR_STICKABLE = 24,
      WINDOW_ATTR_STICKY = 25,
      WINDOW_ATTR_MODAL = 26,
      WINDOW_ATTR_MINIMIZED = 27,
      WINDOW_ATTR_FOCUSED = 28,
      WINDOW_ATTR_TRANSPARENT = 29,
      MAX_ATTRIBUTES = 30 // Final, sentinel attribute entry.
   }

   /**
    * Various constants defining the RPCs that we currently use.
    *
    * Some may be deprecated, so refer to bora/public/unityCommon.h for more
    * info.
    */
   const RPC_ENTER = "unity.enter";
   const RPC_GET_UPDATE = "unity.get.update";
   const RPC_GET_WINDOW_PATH = "unity.get.window.path";
   const RPC_GET_BINARY_INFO = "unity.get.binary.info";
   const RPC_WINDOW_SETTOP = "unity.window.settop";
   const RPC_WINDOW_CLOSE = "unity.window.close";
   const RPC_WINDOW_MOVE_RESIZE = "unity.window.move_resize";
   const RPC_GET_ICON_DATA = "unity.get.icon.data";
   const RPC_EXIT = "unity.exit";
   const RPC_GET_UPDATE_FULL = "unity.get.update.full";
   const RPC_GET_UPDATE_INCREMENTAL = "unity.get.update.incremental";
   const RPC_WINDOW_UNMINIMIZE = "unity.window.restore";
   const RPC_WINDOW_MINIMIZE = "unity.window.minimize";
   const RPC_WIDNOW_MAXIMIZE = "unity.window.maximize";
   const RPC_WINDOW_UNMAXIMIZE = "unity.window.unmaximize";
   const RPC_SET_OPTIONS = "unity.set.options";
   const RPC_GET_EXEC_INFO_HASH = "ghi.guest.getExecInfoHash";
   const RPC_TRAYICON_START_UPDATE = "ghi.guest.trayIcon.startUpdates";
   const RPC_TRAYICON_STOP_UPDATE = "ghi.guest.trayIcon.stopUpates";
   const RPC_TRAYICON_SEND_EVENT = "ghi.guest.trayIcon.sendEvent";
   const RPC_TRAYICON_UPDATE = "ghi.guest.trayIcon.update";
   const RPC_SHELL_ACTION = "ghi.guest.shell.action";
   const BROWSE_URL_ACTION = "x-vmware-action:///browse";

   // Guest-to-Host RPCs that we may receive.
   const RPC_PUSH_UPDATE_CMD = "tools.unity.push.update";
   const RPC_ACTIVE = "unity.active";
   const RPC_NOTIFY_CLIENT = "unity.notify.client";

   // VDPService command IDs.
   const SERVER_UNITY_INFO_MSG = 0;
   const SERVER_UNITY_PLUGIN_MSG = 1;
   const CLIENT_UNITY_PLUGIN_MSG = 2;
   const SERVER_UNITY_URL_REDIRECT_MSG = 3;

   //UnityTrayBotifictaion Option
   const GHI_TRAY_ICON_EVENT_INVALID = 0;
   const GHI_TRAY_ICON_EVENT_LBUTTONDBCLICK = 1;
   const GHI_TRAY_ICON_EVENT_RIGHT_CLICK = 2;
   const GHI_TRAY_ICON_EVENT_LEFT_CLICK = 3;

   // Version enums used when XDR-encoding some Unity message parameters.
   const OPTIONS_V1 = 1;
   const EXEC_INFO_HASH_V1 = 1;
   const ACTIVE_V1 = 1;
   const GHI_TRAY_ICON_V1 = 1;

   // Constants used by getIconData().
   const ICON_TYPE_MAIN = 0;
   const DEFAULT_ICON_CHUNK_SIZE = 32 * 1024; // 32KB

   export interface BasicUnityMgrInterface {
      unminimize: (windowId) => void;
      minimize: (windowId) => void;
      maximize: (windowId) => void;
      unmaximize: (windowId) => void;
      moveResizeWindow: (wmksKey, windowId, x, y, width, height) => void;
   }
   /**
    * Unity.Mgr
    *
    * Constructor used to create a new instance of the Unity.Mgr object.
    *
    */
   export class Mgr implements BasicUnityMgrInterface {
      //The various channel names and object names we use.
      public static readonly UNITY_SVC_OBJECT = "UnitySvcObject";
      public isOn = false;
      public paused = false;
      public windowCount = 0;
      public wmksSession: BlastWmks;
      /*
       * The Unity.Mgr owner can retrieve information about windows directly using the
       * .windows object by using the windowId as the key.  A window object has the
       * following attributes:
       *    .attributes: Object with attribute name as the key and a boolean as the value.
       *    .title: Title of the window.
       *    .type: Window type.
       *    .windowId: Window ID.
       *    .windowPath: Window path in remote desktop.
       *    .execPath: Executable path in remote desktop.
       */
      public windows = {};

      private vdpServiceMainChannel: RDEChannel;
      /*
       * VDPService provides no built-in functionality to correlated RPC
       * responses previous requests.  As a result, we track in-flight requests with a
       * sequence number that the server treats as a cookie and returns back with the
       * response.  seqNo is a sequentially increasing number used as the cookie and
       * requests is the map of a cookie to optional callback functions.
       */
      private requests = {};
      private seqNo = 1;
      private pendingIconRequests = {};
      private pendingRegionUpdate = {
         windowId: 0,
         regionCount: 0,
         rects: []
      };
      public ops: UnityOps = null;

      constructor(session: BlastWmks, ops: UnityOps) {
         this.wmksSession = session;
         this.vdpServiceMainChannel = session.mainChannel;
         this.ops = ops;
         if (this.vdpServiceMainChannel) {
            this.vdpServiceMainChannel.addMessageHandler(this);
         }
      }

      /**
       * Unity.Mgr.on
       *
       * Requests that the remote desktop enters Unity mode with the specified
       * feature mask.
       *
       * @param features  Bitmask of features.
       */
      public on = (features) => {
         features = features || 0;

         /*
          * Sends RPC_SET_OPTIONS, then RPC_ENTER, then RPC_GET_UPDATE_FULL.
          * Doesn't wait for each RPC to complete before sending the next.
          */
         if (
            this._sendRPC(true, null, null, RPC_SET_OPTIONS, OPTIONS_V1, 1, features) &&
            this._sendRPC(false, null, null, RPC_ENTER)
         ) {
            this.requestUpdate(true);

            /*
             * Note: cui::UnityMgr has a much more complicated state process including an
             * "on pending" state that times out after 20 seconds.  I don't believe that it
             * is necessary for View and often times does more harm than good for us.
             */
            this.isOn = true;
            this.paused = false;
         }
      };

      /**
       * Unity.Mgr.off
       *
       * Requests that the remote desktop exits Unity mode.
       */
      public off = () => {
         let windowId,
            removedWindow = false;

         // Clear this data even if sending the RPC failed.
         this.paused = false;
         this.isOn = false;
         for (windowId in this.windows) {
            if (this.windows.hasOwnProperty(windowId)) {
               if (this._removeWindow(windowId)) {
                  removedWindow = true;
               }
            }
         }

         // We faked window remove updates so we need to fake
         // onUpdateComplete() as well.
         if (removedWindow && !!this.ops) {
            this.ops.onUpdateComplete();
         }

         // Send RPC_EXIT.
         this._sendRPC(false, null, null, RPC_EXIT);
      };

      /**
       * Unity.Mgr.requestUpdate
       *
       * Requests either a full or incremental Unity update.
       *
       * @param fullUpdate Set to true if the update should be full, false
       *    otherwise.
       */
      public requestUpdate = (fullUpdate) => {
         if (fullUpdate) {
            this._sendRPC(false, null, null, RPC_GET_UPDATE_FULL);
         } else {
            this._sendRPC(false, null, null, RPC_GET_UPDATE_INCREMENTAL);
         }
      };

      /**
       * Unity.Mgr.getBinaryInfo
       *
       * Requests the binary information for the specified Unity window path.
       *
       * @param windowPath The window path to retrieve the binary information
       *    for.
       * @param onDone   A callback function triggered upon RPC success.  Takes
       *    parameters: name       Name of the application. icons      Array of
       *    icon objects.  Each icon has:
       *                            .width  Width of the icon
       *                            .height Height of the icon
       *                            .bgra   Array with icon BGRA information
       * @param onAbort  A callback function triggered upon RPC failure.  Takes
       *    parameters: error      Error string sent by the remote desktop.
       */
      public getBinaryInfo = (windowPath, onDone, onAbort) => {
         // Send Unity.RPC_GET_BINARY_INFO.
         if (!!windowPath && !!onDone) {
            this._sendRPC(false, onDone, onAbort, RPC_GET_BINARY_INFO, windowPath);
         }
      };

      /**
       * Unity.Mgr.setTopWindows
       *
       * Requests that the remote desktop raise a group of Unity windows to the
       * top of the window stacking order.
       *
       * @param windowIds An array of Unity window IDs.
       */
      public setTopWindows = (windowIds) => {
         // Send Unity.RPC_WINDOW_SETTOP.
         if (windowIds instanceof Array && windowIds.length > 0) {
            this._sendRPC(false, null, null, RPC_WINDOW_SETTOP, windowIds);
         }
      };

      /**
       * Unity.Mgr.closeWindow
       *
       * Requests that the remote desktop close the specified window.
       *
       * @param windowId ID of window to close.
       */
      public closeWindow = (windowId) => {
         // Send Unity.RPC_WINDOW_CLOSE.
         if (windowId) {
            this._sendRPC(false, null, null, RPC_WINDOW_CLOSE, windowId);
         }
      };

      public moveResizeWindow = (windowId, x, y, width, height) => {
         // Send Unity.RPC_WINDOW_MOVE_RESIZE.
         if (windowId) {
            this._sendRPC(false, null, null, RPC_WINDOW_MOVE_RESIZE, windowId, x, y, width, height);
         }
      };

      /**
       * Unity.Mgr.getIconData
       *
       * Requests an icon for the specified window.
       *
       * @param windowId ID of window to request icon data for.
       * @param size     Size of icon (e.g. 16, 32, 48).
       * @param onDone   A callback function triggered upon RPC success.  Takes
       *    parameters: bgra        Array with icon BGRA information.
       * @param onAbort  A callback function triggered upon RPC failure.  Takes
       *    parameters: error      Error string sent by the remote desktop.
       * @param chunkSize Optional chunk size that the caller can pass in.
       *    Defaults to Unity.DEFAULT_ICON_CHUNK_SIZE.
       */
      public getIconData = (windowId, size, onDone, onAbort, iconChunkSize = DEFAULT_ICON_CHUNK_SIZE) => {
         // Send Unity.RPC_GET_ICON_DATA.
         if (!!windowId && size > 0 && !!onDone && iconChunkSize > 0) {
            this._getIconDataChunk(windowId, size, onDone, onAbort, iconChunkSize);
         }
      };

      /**
       * Unity.Mgr.unminimize
       *
       * Requests that the specified window be unminimized.
       *
       * @param windowId ID of window to unminimize.
       */
      public unminimize = (windowId) => {
         // Send RPC_WINDOW_UNMINIMIZE.
         if (windowId) {
            this._sendRPC(false, null, null, RPC_WINDOW_UNMINIMIZE, windowId);
         }
      };

      public minimize = (windowId) => {
         // Send RPC_WINDOW_MINIMIZE.
         if (windowId) {
            this._sendRPC(false, null, null, RPC_WINDOW_MINIMIZE, windowId);
         }
      };

      public maximize = (windowId) => {
         // Send RPC_WIDNOW_MAXIMIZE.
         if (windowId) {
            this._sendRPC(false, null, null, RPC_WIDNOW_MAXIMIZE, windowId);
         }
      };

      public unmaximize = (windowId) => {
         // Send RPC_WINDOW_UNMAXIMIZE.
         if (windowId) {
            this._sendRPC(false, null, null, RPC_WINDOW_UNMAXIMIZE, windowId);
         }
      };

      /**
       * Unity.Mgr.getExecInfoHash
       *
       * Requests a hash of the executable info used to save binary information
       * to an internal hash.
       *
       * @param execPath     Executable path received by onAdd listener.
       * @param onDone   A callback function triggered upon RPC success.  Takes
       *    parameters: execInfoHash Executable info hash.
       * @param onAbort  A callback function triggered upon RPC failure.  Takes
       *    parameters: error      Error string sent by the remote desktop.
       */
      public getExecInfoHash = (execPath, onDone, onAbort) => {
         // Send Unity.RPC_GET_EXEC_INFO_HASH.
         if (!!execPath && !!onDone) {
            this._sendRPC(true, onDone, onAbort, RPC_GET_EXEC_INFO_HASH, EXEC_INFO_HASH_V1, 1, execPath);
         }
      };

      /**
       * Unity.Mgr.pause
       *
       * Requests that Unity be paused.  Usually called by the UI as a result
       * of receiving the Unity "not ready" signal.
       *
       * This function doesn't send any RPCs, but does clear any internal Unity
       * window state the object maintains and sets a variable to prevent
       * dispatching most Unity updates.
       */
      public pause = () => {
         let windowId,
            removedWindow = false;

         this.paused = true;

         for (windowId in this.windows) {
            if (this.windows.hasOwnProperty(windowId)) {
               if (this._removeWindow(windowId)) {
                  removedWindow = true;
               }
            }
         }

         // We faked window remove updates so we need to fake
         // onUpdateComplete() as well.
         if (removedWindow && !!this.ops) {
            this.ops.onUpdateComplete();
         }
      };

      /**
       * Unity.Mgr.unpause
       *
       * Requests that Unity be unpaused.  Usually called by the UI as a result
       * of receiving the Unity "ready" signal after we were previously paused.
       *
       * This function resumes all Unity updates and requests a full Unity
       * update.
       */
      public unpause = () => {
         /*
          * Set a variable to start dispatching Unity updates.
          * Also call self.requestUpdate(true).
          */
         this.paused = false;
         this.requestUpdate(true);
      };

      /**
       * Unity.Mgr.getPendingIconRequestCount
       *
       * Returns the number of in-flight icon requests.  Used by the unit tests
       * to confirm that the map was cleaned up properly.
       *
       * @return     Total icon request count.
       */
      public getPendingIconRequestCount = () => {
         let request,
            count = 0;

         for (request in this.pendingIconRequests) {
            if (this.pendingIconRequests.hasOwnProperty(request)) {
               count++;
            }
         }

         return count;
      };

      /**
       * Unity.Mgr.getPendingRPCCount
       *
       * Returns the number of in-flight RPCs.
       *
       * @return     Total RPC count.
       */
      public getPendingRPCCount = () => {
         let rpc,
            count = 0;

         for (rpc in this.requests) {
            if (this.requests.hasOwnProperty(rpc)) {
               count++;
            }
         }

         return count;
      };

      /**
       * Unity.Mgr.trayIconStartUpdate
       *
       * Requests the avaliable tray icons and wait for the update response.
       */
      public trayIconStartUpdate = (onDone, onAbort) => {
         // Send Unity.RPC_TRAYICON_START_UPDATE
         if (onDone) {
            this._sendRPC(false, onDone, onAbort, RPC_TRAYICON_START_UPDATE);
         }
      };

      /**
       * Unity.Mgr.trayIconStopUpdate
       *
       * Wait for the stop response.
       */
      public trayIconStopUpdate = (onDone, onAbort) => {
         // Send Unity.RPC_TRAYICON_STOP_UPDATE
         if (onDone) {
            this._sendRPC(false, onDone, onAbort, RPC_TRAYICON_STOP_UPDATE);
         }
      };

      public sendEventToPerfTracker = (action, perfTrackerItem) => {
         switch (action) {
            case "activeWindow":
               /**
                * Send ghi.guest.trayIcon.sendEvent
                * With parameter:
                * Version: GHI_TRAY_ICON_EVENT_V1
                * iconId: (string)identifier of icon
                * event: (uint32) event identifier
                *        GHI_TRAY_ICON_EVENT_INVALID: 0,
                *        GHI_TRAY_ICON_EVENT_LBUTTONDBCLICK: 1,
                *        GHI_TRAY_ICON_EVENT_RIGHT_CLICK: 2,
                *        GHI_TRAY_ICON_EVENT_LEFT_CLICK: 3,
                * x: (uint32)(not used) guest x co-ordinate of the event
                * y: (uint32)(not used) guest y co-ordinate of the event
                */
               this._sendRPC(
                  true,
                  null,
                  null,
                  RPC_TRAYICON_SEND_EVENT,
                  GHI_TRAY_ICON_V1,
                  1,
                  perfTrackerItem.iconId,
                  GHI_TRAY_ICON_EVENT_LBUTTONDBCLICK,
                  0,
                  0
               );
               break;
            case "showContextMenu":
               Logger.trace(
                  "send " +
                     RPC_TRAYICON_SEND_EVENT +
                     " to RPC with posiotion x1:" +
                     perfTrackerItem.position.x +
                     " y1:" +
                     perfTrackerItem.position.y,
                  Logger.UNITY
               );
               this._sendRPC(
                  true,
                  null,
                  null,
                  RPC_TRAYICON_SEND_EVENT,
                  GHI_TRAY_ICON_V1,
                  1,
                  perfTrackerItem.iconId,
                  GHI_TRAY_ICON_EVENT_RIGHT_CLICK,
                  perfTrackerItem.position.x,
                  perfTrackerItem.position.y
               );
               break;
            case "leftSingleClickSystemTray":
               Logger.trace(
                  "send Left Single Click" +
                     RPC_TRAYICON_SEND_EVENT +
                     " to RPC with posiotion x1:" +
                     perfTrackerItem.position.x +
                     " y1:" +
                     perfTrackerItem.position.y,
                  Logger.UNITY
               );
               this._sendRPC(
                  true,
                  null,
                  null,
                  RPC_TRAYICON_SEND_EVENT,
                  GHI_TRAY_ICON_V1,
                  1,
                  perfTrackerItem.iconId,
                  GHI_TRAY_ICON_EVENT_LEFT_CLICK,
                  perfTrackerItem.position.x,
                  perfTrackerItem.position.y
               );
               break;
         }
      };

      /**
       * getIconDataChunk
       *
       * Helper function to request an icon for the specified window at a
       * specified offset.
       *
       * @param windowId ID of window to request icon data for.
       * @param size     Size of icon (e.g. 16, 32, 48).
       * @param onDone   A callback function triggered upon RPC success.  Takes
       *    parameters: bgra        Array with icon BGRA information.
       * @param onAbort  A callback function triggered upon RPC failure.  Takes
       *    parameters: error      Error string sent by the remote desktop.
       * @param chunkSize        Icon chunk size.
       * @param iconPosition     Optional index to start at.  Defaults to 0.
       * @param previousCookie   Optional previous cookie used in a different
       *    RPC request for the same icon.  Should be sent if iconPosition > 0.
       */
      public _getIconDataChunk = (
         windowId,
         size,
         onDone,
         onAbort,
         iconChunkSize,
         iconPosition?: any,
         previousCookie?: any
      ) => {
         let newCookie,
            pendingRequest = null;

         iconPosition = iconPosition || 0;
         newCookie = this.seqNo.toString();

         if (newCookie) {
            if (previousCookie) {
               pendingRequest = this.pendingIconRequests[previousCookie];
               delete this.pendingIconRequests[previousCookie];
            }
            if (!pendingRequest) {
               pendingRequest = {};
               pendingRequest.iconData = null;
            }

            pendingRequest.cookie = newCookie;
            pendingRequest.iconChunkSize = iconChunkSize;
            pendingRequest.windowId = windowId;
            pendingRequest.size = size;
            pendingRequest.onDone = onDone;
            pendingRequest.onAbort = onAbort;
            pendingRequest.iconPosition = iconPosition;
            this.pendingIconRequests[newCookie] = pendingRequest;
         }

         this._sendRPC(
            false,
            onDone,
            onAbort,
            RPC_GET_ICON_DATA,
            windowId,
            ICON_TYPE_MAIN,
            size,
            iconPosition,
            iconChunkSize
         );
      };

      /**
       * Send the message for browse URL message.
       */
      public browseURL = (url: string, onDone?: Function, onAbort?: Function) => {
         // Send ghi.guest.shell.action
         this._sendRPC(false, onDone, onAbort, RPC_SHELL_ACTION, BROWSE_URL_ACTION, 1, url);
      };

      /**
       * concatUint8Array
       *
       *  Concatenation of Uint8Array
       *
       * @param first      Uint8Array
       * @param second     Uint8Array
       * @return           An Uint8Array if successfully, null otherwise.
       */
      public concatUint8Array = (first, second, nullTerminated) => {
         let result = null;
         const padding = nullTerminated ? 1 : 0;
         if (first instanceof Uint8Array && second instanceof Uint8Array) {
            result = new Uint8Array(first.length + second.length + padding);
            result.set(first);
            result.set(second, first.length);
            result.fill(0, result.length - padding);
         }
         return result;
      };

      /**
       * sendRPC
       *
       * Function that owns the process of sending an RPC.
       *
       * @param xdrEncodeArgs  true if the arguments after the RPC string
       *    should be xdr-encoded if using vdpService.
       * @param onDone     Optional callback function triggered upon RPC
       *    success. Parameters depend on the RPC specifics.
       * @param onAbort    Optional callback function triggered upon RPC
       *    failure. Takes parameters: error      Error string sent by the
       *    remote desktop.
       * @param name       Name of the RPC
       * @param <params>   Optional extra parameters to be appended to string.
       * @return           true if successfully sent the RPC, false otherwise.
       */
      private _sendRPC = (xdrEncodeArgs, onDone, onAbort, name, ...args: any[]) => {
         let rpcString = name,
            i = 0,
            cookie = this.seqNo.toString(),
            sendBuffer;

         if (!this.vdpServiceMainChannel) {
            Logger.error("Failed to send Unity RPC. vdpService channel is closed.", Logger.UNITY);
            return false;
         }

         if (xdrEncodeArgs) {
            const xdrBuffer = new VDPXdrBuffer();
            xdrBuffer.initEncoder();
            for (let i = 0; i < args.length; i++) {
               xdrBuffer.write(args[i]);
            }
            sendBuffer = this.concatUint8Array(
               StringUtils.stringToUint8Array(rpcString + " ", false),
               xdrBuffer.getData(),
               true
            );
         } else {
            // Add all extra parameters to the RPC string.
            for (let i = 0; i < args.length; i++) {
               rpcString += this._serializeRPCParameter(args[i]);
            }
            sendBuffer = StringUtils.stringToUint8Array(rpcString, true);
         }

         /*
          * If neither onDone and onAbort are provided, that means that this request will
          * not result in a response being sent by the server.  For those RPCs, we should
          * not create an entry in requests, because it would never be cleaned up.
          */
         if (!!onDone && !!onAbort) {
            this.requests[cookie] = { onDone: onDone, onAbort: onAbort };
         }

         /*
          * Note: The RdeServer expects the message string to be null-terminated,
          * which StringUtils.stringToUint8Array does here.
          */
         if (
            0 ===
            this.vdpServiceMainChannel.invoke({
               command: CLIENT_UNITY_PLUGIN_MSG,
               type: VDP_CONSTS.RPC_TYPE.POST,
               params: [0, 0, sendBuffer, cookie, 0],
               objName: Mgr.UNITY_SVC_OBJECT
            })
         ) {
            Logger.error("Failed to send Unity RPC.", Logger.UNITY);
            delete this.requests[cookie]; // For if onDone callback was
            // provided.
            delete this.pendingIconRequests[cookie]; // For if it's an icon request.
            return false;
         }

         this.seqNo++;
         return true;
      };

      /**
       * serializeRPCParameter
       *
       * Helper function used to recursively add parameters to an RPC string.
       * Expands any arrays that it encounters.
       *
       * @param parameter    Parameter to add.
       * @return             Serialized RPC params string with leading space if
       *    > 0 params.
       */
      private _serializeRPCParameter = (parameter) => {
         let serializedString = "",
            i = 0;

         if (parameter instanceof Array) {
            for (i = 0; i < parameter.length; i++) {
               serializedString += this._serializeRPCParameter(parameter[i]);
            }
         } else {
            serializedString += " " + parameter;
         }

         return serializedString;
      };

      /**
       * handleRPCFromServer
       *
       * Helper function used to parse the RPC data that we receive from a
       * server.
       *
       * @param rpc      RPC object that was posted by VDPService.
       */
      public handleRPCFromServer = (rpc) => {
         let rpcName,
            cookie,
            messageContents,
            status,
            capabilities,
            capsCount,
            timestamp,
            invalidRPCCommand = false,
            lastTokenIndex,
            tokenIndex,
            spaceCharCode = 32;

         switch (rpc.command) {
            case SERVER_UNITY_INFO_MSG:
               if (rpc.type === VDP_CONSTS.RPC_TYPE.REQUEST && rpc.params.length >= 6) {
                  Logger.trace("Received Unity server info: " + rpc.params, Logger.UNITY);

                  capsCount = rpc.params[3];
                  capabilities = rpc.params[4];

                  if (capabilities) {
                     this._parseUnityCapabilities(capsCount, capabilities);
                  }
               } else {
                  invalidRPCCommand = true;
               }
               break;
            case SERVER_UNITY_PLUGIN_MSG:
               /*
                * The parameters sent by the server are in the format:
                *    rpc.params[0]: ?
                *    rpc.params[1]: ?
                *    rpc.params[2]: Uint8Array with string data of the format
                *                   <RPC name> <optional_RPC_parameters>
                *    rpc.params[3]: String containing cookie ('' means no cookie).
                *    rpc.params[4]: Integer containing status (0 means success).
                *    rpc.params[5]: ?
                */
               if (
                  rpc.type === VDP_CONSTS.RPC_TYPE.POST &&
                  rpc.params.length === 6 &&
                  rpc.params[2] instanceof Uint8Array
               ) {
                  /*
                   * Note: some messages (e.g. RPC_GET_BINARY_INFO) contain binary
                   * data that causes StringUtils.uint8ArrayToString to fail.
                   */
                  tokenIndex = StringUtils.uint8ArrayIndexOf(rpc.params[2], spaceCharCode);
                  if (tokenIndex === -1) {
                     Logger.error("unity.js: Failed to find first string in response.", Logger.UNITY);
                     return;
                  }

                  rpcName = StringUtils.uint8ArrayToString(rpc.params[2].subarray(0, tokenIndex));
                  if (rpcName.indexOf("time:") === 0) {
                     // This first string is a timestamp and the next string is
                     // the RPC name.
                     timestamp = Date.parse(rpcName.slice(5));

                     lastTokenIndex = StringUtils.uint8ArrayIndexOf(rpc.params[2], spaceCharCode, tokenIndex + 1);
                     if (lastTokenIndex === -1) {
                        Logger.error("unity.js: Failed to find second string in response.", Logger.UNITY);
                        return;
                     }

                     rpcName = StringUtils.uint8ArrayToString(rpc.params[2].subarray(tokenIndex + 1, lastTokenIndex));

                     if (timestamp) {
                        Logger.trace(
                           "Receiving Unity message " + rpcName + " took " + (Date.now() - timestamp) + "ms.",
                           Logger.UNITY
                        );
                     }
                  } else {
                     lastTokenIndex = tokenIndex;
                  }

                  if (rpcName.length === 0) {
                     Logger.error("unity.js: Failed to parse RPC name from server.", Logger.UNITY);
                     return;
                  }

                  messageContents = rpc.params[2].subarray(lastTokenIndex + 1);

                  cookie = rpc.params[3];
                  status = rpc.params[4];

                  this._parseUnityMessage(cookie, status, rpcName, messageContents);
               } else {
                  invalidRPCCommand = true;
               }
               break;
            case SERVER_UNITY_URL_REDIRECT_MSG: // Handle the message of URL content redirection
               if (rpc.type === VDP_CONSTS.RPC_TYPE.REQUEST && rpc.params.length >= 3) {
                  Logger.info("Received url redirection for : " + JSON.stringify(rpc.params), Logger.UNITY);

                  let url = <string>rpc.params[2];
                  if (url[0] === '"' && url[url.length - 1] === '"') {
                     url = url.substring(1, url.length - 1);
                  }

                  if (url && this.ops) {
                     this.ops.onUrlRedirection(url);
                  }
               } else {
                  invalidRPCCommand = true;
               }
               break;
            default:
               // not consume this unknown message and allow other module to consume it, like DPI sync module
               return 1;
         }

         if (invalidRPCCommand) {
            Logger.error(
               "Unity message " +
                  rpc.command +
                  " with type " +
                  rpc.type +
                  " was in an unexpected format: " +
                  rpc.params,
               Logger.UNITY
            );
         }
         return 0;
      };

      /**
       * parseUnityMessage
       *
       * Helper function used to parse a Unity message that is received by
       * vdpService.
       *
       * @param cookie        String containing the request cookie.
       * @param status        Status integer (literal) sent by server.  0 means
       *    success.
       * @param messageName   Name of the message sent.  Usually corresponds to
       *    an RPC.
       * @param messageContents  The message-specific contents in a Uint8Array.
       */
      private _parseUnityMessage = (cookie, status, messageName, messageContents) => {
         let success = !status,
            rpcContentsInvalid = false,
            callbacks,
            onDone,
            onAbort;

         if (!!cookie && cookie.length > 0) {
            callbacks = this.requests[cookie];
            if (callbacks) {
               onDone = callbacks.onDone;
               onAbort = callbacks.onAbort;
               delete this.requests[cookie];
            }
         }

         if (!success) {
            if (messageName === RPC_GET_ICON_DATA) {
               // Icon request failed, so delete the request.
               delete this.pendingIconRequests[cookie];
            }

            /*
             * Note: Don't log messageContents because it may contain null characters,
             * which causes the unit test framework to fail.
             */
            Logger.error("Unity RPC " + messageName + " failed (status=" + status + ").", Logger.UNITY);
            if (onAbort) {
               onAbort(StringUtils.uint8ArrayToString(messageContents));
            }
            return;
         }

         switch (messageName) {
            case RPC_GET_BINARY_INFO:
               rpcContentsInvalid = !this._handleGetBinaryInfoMessage(messageContents, onDone);
               break;
            case RPC_GET_ICON_DATA:
               rpcContentsInvalid = !this._handleGetIconDataMessage(messageContents, onDone, cookie);
               break;
            case RPC_GET_EXEC_INFO_HASH:
               rpcContentsInvalid = !this._handleGetExecInfoHashMessage(messageContents, onDone);
               break;
            case RPC_TRAYICON_UPDATE:
               rpcContentsInvalid = !this._handleSystemTrayIconMessage(messageContents);
               break;
            case RPC_PUSH_UPDATE_CMD:
               rpcContentsInvalid = !this._handleUnityUpdateMessage(messageContents);
               break;
            case RPC_ACTIVE:
               rpcContentsInvalid = !this._handleUnityActiveMessage(messageContents);
               break;
            case RPC_NOTIFY_CLIENT:
               rpcContentsInvalid = !this._handleNotifyReadyMessage(messageContents);
               break;
         }

         if (rpcContentsInvalid) {
            if (messageName === RPC_GET_ICON_DATA) {
               // Icon request received an invalid response, so delete the
               // request.
               delete this.pendingIconRequests[cookie];
            }

            Logger.error("Unexpectedly unable to dispatch response for RPC " + messageName, Logger.UNITY);

            if (onAbort) {
               onAbort("Unexpected failure");
            }
         }
      };

      /**
       * parseUnityCapabilities
       *
       * Helper function used to parse a capabilities string sent by the server.
       * If successful, also triggers the .onCapsChanged listener.
       *
       * @param count        Number of capabilities sent.
       * @param capabilities String of form "1=1;2=1;4=1;5=1;".
       */
      private _parseUnityCapabilities = (count, capabilities) => {
         let capsArray = capabilities.split(";"),
            capArray,
            serverCaps = [],
            i = 0;

         if (capsArray instanceof Array && capsArray.length > 0) {
            /*
             * The last entry may be empty due to a trailing semicolon.
             * Remove it so we can accurately check the count.
             */
            if (capsArray[capsArray.length - 1].length === 0) {
               capsArray.pop();
            }

            if (count > capsArray.length) {
               /*
                * This isn't a fatal error but may be indicative of a
                * parsing failure.
                */
               Logger.error(
                  "Unity capabilities count " +
                     count +
                     " is unexpectedly larger than actual count " +
                     capsArray.length +
                     ". Caps: " +
                     capabilities,
                  Logger.UNITY
               );
               return;
            }

            for (i = 0; i < count; i++) {
               // Each individual capability is of the form 'type=1'.
               capArray = capsArray[i].split("=");
               if (!!capArray && capArray instanceof Array && capArray.length === 2) {
                  if (capArray[1] === "1") {
                     serverCaps.push(parseInt(capArray[0], 10));
                  }
               } else {
                  Logger.error(
                     "Unable to parse Unity capability entry " + i + " with contents: " + capabilities[i],
                     Logger.UNITY
                  );
                  return;
               }
            }

            Logger.trace("Received Unity capabilities: " + serverCaps, Logger.UNITY);

            if (this.ops) {
               this.ops.onCapsChanged(serverCaps);
            }
         }
      };

      /**
       * parseUnityUpdate
       *
       * Helper function used to parse a single Unity update.
       *
       * @param update        Unity update string.
       * @return              true if a listener was triggered, false otherwise.
       */
      private _parseUnityUpdate = (update) => {
         let updateComponents,
            type,
            windowId,
            shouldCheckExists,
            shouldExist,
            window,
            exists,
            updateContentsInvalid = false,
            unHandledMessage = false,
            i,
            temp1,
            temp2;

         if (update.trim().indexOf("title") === 0) {
            /*
             * The window title may have spaces so we must separate it into exactly
             * three components: the update name, the window ID, and the title.
             */
            updateComponents = this._splitStringComponents(update, 3);
         } else {
            updateComponents = update.split(" ");
         }

         Logger.debug("unity update = " + JSON.stringify(update), Logger.UNITY);
         if (!(updateComponents instanceof Array) || updateComponents.length < 2) {
            Logger.error('Received invalid Unity update: "' + update + '"', Logger.UNITY);
            return false;
         }

         type = updateComponents[0];
         windowId = updateComponents[1];
         shouldExist = type !== "add";
         window = this.windows[windowId];
         exists = !!window;

         // A few update types don't have the Unity window ID first, so don't
         // check.
         shouldCheckExists = type !== "rect" && type !== "zorder" && type !== "activedesktop" && type !== "region";

         Logger.debug(
            'Received Unity update "' + type + '" for windowId: ' + windowId + exists
               ? " existing windowId "
               : "nonexistent windowId " + "with parameters " + updateComponents.slice(1, -1),
            Logger.UNITY
         );

         if (shouldCheckExists && shouldExist !== exists) {
            return false;
         }
         /*
          * Map to the UnityUpdateType in unityWindowTracker.h
          * The format of the message can be found in UnityUpdateCallbackFn
          * of bora/apps/rde/unityPlugin/unityTclo.cc
          */
         switch (type) {
            // UNITY_UPDATE_ADD_WINDOW
            case "add":
               if (updateComponents.length >= 4) {
                  /*
                   * Initialize the window object.  We may eventually move this code
                   * into its own constructor.
                   */
                  window = {
                     attributes: {},
                     title: "",
                     type: UnityWindowType.WINDOW_TYPE_NONE,
                     windowId: windowId
                  };

                  // Initialize all attributes.
                  for (i = UnityWindowAttribute.WINDOW_ATTR_BORDERLESS; i < UnityWindowAttribute.MAX_ATTRIBUTES; i++) {
                     window.attributes[i] = false;
                  }

                  // The rest of the components, including windowPath and
                  // execPath.
                  for (i = 2; i < updateComponents.length; i++) {
                     temp1 = updateComponents[i].indexOf("=");
                     temp2 = updateComponents[i].substr(0, temp1);

                     if (temp2.length > 0) {
                        window[temp2] = updateComponents[i].substr(temp1 + 1);
                     }
                  }

                  if (!window.windowPath || window.windowPath.length === 0) {
                     Logger.error("Window id " + windowId + " did not have a " + "window path.", Logger.UNITY);
                  }
                  if (!window.execPath || window.execPath.length === 0) {
                     Logger.trace(
                        "Window id " + windowId + " did not have an " + "exec path. Using window path instead.",
                        Logger.UNITY
                     );
                     window.execPath = window.windowPath;
                  }

                  this.windows[windowId] = window;
                  this.windowCount++;

                  if (this.ops) {
                     this.ops.onAdd(windowId, window.windowPath, window.execPath);
                     return true;
                  }
               } else {
                  Logger.error(
                     "Expected at least 4 components for Unity add " +
                        "update, but received " +
                        updateComponents.length,
                     Logger.UNITY
                  );
                  updateContentsInvalid = true;
               }
               break;
            // UNITY_UPDATE_REMOVE_WINDOW
            case "remove":
               if (this._removeWindow(windowId)) {
                  return true;
               }
               break;
            // UNITY_UPDATE_MOVE_WINDOW
            case "move":
               // We are not currently respecting this update type.
               if (updateComponents.length >= 6) {
                  const newRect = {
                     x1: parseInt(updateComponents[2]),
                     y1: parseInt(updateComponents[3]),
                     x2: parseInt(updateComponents[4]),
                     y2: parseInt(updateComponents[5])
                  };
                  if (this.ops) {
                     this.ops.onWindowMoved(windowId, newRect);
                     return true;
                  }
               } else {
                  updateContentsInvalid = true;
               }
               break;
            // UNITY_UPDATE_CHANGE_WINDOW_TITLE
            case "title":
               if (updateComponents.length >= 3) {
                  window.title = updateComponents[2];

                  if (this.ops) {
                     this.ops.onTitleChanged(windowId, window.title);
                     return true;
                  }
               } else {
                  updateContentsInvalid = true;
               }
               break;
            // UNITY_UPDATE_CHANGE_ZORDER
            case "zorder":
               if (this.ops) {
                  this.ops.onZorderUpdate(updateComponents);
                  return true;
               }
               break;
            // UNITY_UPDATE_CHANGE_WINDOW_REGION
            //"region %u %drect %d %d %d %drect %d %d %d %d"
            case "region":
               {
                  let regionCount = 0;

                  regionCount = parseInt(updateComponents[2]);
                  this.pendingRegionUpdate.regionCount = regionCount;
                  this.pendingRegionUpdate.windowId = windowId;
                  if (regionCount === 0) {
                     if (this.ops) {
                        this.ops.onRegionUpdate(
                           this.pendingRegionUpdate.windowId.toString(),
                           this.pendingRegionUpdate.rects
                        );
                        return true;
                     }
                  }
                  Logger.info("region update for " + windowId + " regionCount = " + regionCount, Logger.UNITY);
               }
               break;
            case "rect":
               {
                  const rect = {
                     x1: parseInt(updateComponents[1]),
                     y1: parseInt(updateComponents[2]),
                     x2: parseInt(updateComponents[3]),
                     y2: parseInt(updateComponents[4])
                  };
                  this.pendingRegionUpdate.rects.push(rect);
                  if (this.pendingRegionUpdate.regionCount === this.pendingRegionUpdate.rects.length) {
                     if (this.ops) {
                        this.ops.onRegionUpdate(
                           this.pendingRegionUpdate.windowId.toString(),
                           this.pendingRegionUpdate.rects
                        );
                     }
                     this.pendingRegionUpdate.windowId = 0;
                     this.pendingRegionUpdate.rects = [];
                     this.pendingRegionUpdate.regionCount = 0;
                     return true;
                  }
               }
               break;
            // UNITY_UPDATE_CHANGE_WINDOW_STATE
            case "state":
               unHandledMessage = true;
               break;
            // UNITY_UPDATE_CHANGE_WINDOW_ATTRIBUTE
            case "attr":
               if (updateComponents.length >= 4) {
                  temp1 = parseInt(updateComponents[2], 10);
                  temp2 = updateComponents[3] === "1";

                  if (
                     temp1 >= UnityWindowAttribute.WINDOW_ATTR_BORDERLESS &&
                     temp1 < UnityWindowAttribute.MAX_ATTRIBUTES
                  ) {
                     window.attributes[temp1] = temp2;

                     if (this.ops) {
                        this.ops.onAttrChanged(windowId, temp1, temp2);
                        return true;
                     }
                  }
               } else {
                  updateContentsInvalid = true;
               }
               break;
            // UNITY_UPDATE_CHANGE_WINDOW_TYPE
            case "type":
               if (updateComponents.length >= 3) {
                  temp1 = parseInt(updateComponents[2], 10);

                  if (temp1 >= UnityWindowType.WINDOW_TYPE_NORMAL && temp1 < UnityWindowType.MAX_WINDOW_TYPES) {
                     window.type = temp1;

                     if (this.ops) {
                        this.ops.onTypeChanged(windowId, window.type);
                        return true;
                     }
                  } else {
                     updateContentsInvalid = true;
                  }
               }
               break;
            // UNITY_UPDATE_CHANGE_WINDOW_ICON
            case "icon":
               // Note: The icon type is in updateComponents[2] but we don't
               // need it.
               if (this.ops) {
                  this.ops.onIconChanged(windowId);
                  return true;
               }
               break;
            // UNITY_UPDATE_CHANGE_WINDOW_DESKTOP
            case "desktop":
               unHandledMessage = true;
               break;
            // UNITY_UPDATE_CHANGE_ACTIVE_DESKTOP
            case "activedesktop":
               unHandledMessage = true;
               break;
            // UNITY_UPDATE_CHANGE_TITLEBAR_AREA
            // This unity message is only for Mac client
            case "titlebararea":
               if (this.ops.onTitleBarAreaChanged) {
                  const windowPositionInfo = updateComponents[2].split(" ");
                  this.ops.onTitleBarAreaChanged(windowId, windowPositionInfo);
               }
               break;
            // UNITY_UPDATE_CHANGE_PRIMARY_WINDOW
            case "primarywindow":
               if (this.ops) {
                  this.ops.onPrimaryWindowUpdate(updateComponents[1], updateComponents[2]);
               }
               break;
            // UNITY_UPDATE_CHANGE_SECONDARY_WINDOWS
            case "secondarywindows": {
               const secondaryWindows = [];
               for (let i = 0; i < updateComponents[2]; i++) {
                  secondaryWindows.push(updateComponents[3 + i]);
               }
               if (this.ops) {
                  this.ops.onSecondaryWindowUpdate(windowId, secondaryWindows);
               }
               break;
            }
            default:
               unHandledMessage = true;
               Logger.trace("Received unknown Unity update? " + update, Logger.UNITY);
               break;
         }

         if (updateContentsInvalid) {
            Logger.error("Contents were invalid for Unity update of type: " + type, Logger.UNITY);
         }
         if (unHandledMessage) {
            Logger.error("Received unknown Unity update message " + type, Logger.UNITY);
         }
         return false;
      };

      /**
       * splitStringComponents
       *
       * Helper function used to split a string into a certain number of
       * components at the space character.  The last word will contain the
       * rest of the string, possibly including spaces.
       *
       * @param string  String to split.
       * @param count   Number of components to split it into.
       * @return        If successful, Array of string.  Otherwise null.
       */
      private _splitStringComponents = (string, count) => {
         let components = null,
            i = 0,
            regex = "";

         if (!!string && string.match instanceof Function) {
            // Add regex components for all words other than the last one.
            for (i = 0; i < count - 1; i++) {
               regex += "(\\S+)\\s+";
            }
            // The last word should include all of the rest of the string.
            regex += "(.*)";

            components = new RegExp(regex).exec(string);

            if (components instanceof Array) {
               // The first index has the full update, which we don't want.
               components.shift();
            }
         }

         return components;
      };

      /**
       * removeWindow
       *
       * Helper function used to remove a window from the internal tracker
       * and call a listener to notify the consumer of the window removal.
       *
       * @param windowId   Window ID to remove.
       * @return           true if a listener was triggered, false otherwise.
       */
      private _removeWindow = (windowId) => {
         if (windowId) {
            delete this.windows[windowId];
            this.windowCount--;

            if (this.ops) {
               this.ops.onRemove(windowId);
               return true;
            }
         }
         return false;
      };

      /**
       * handleGetBinaryInfoMessage
       *
       * Helper function used to parse and handle the response to
       * RPC_GET_BINARY_INFO.
       *
       * @param messageContents  The message-specific contents in a Uint8Array.
       * @param onDone           Done slot that caller passed into
       *    getBinaryInfo().
       * @return                 true if successfully parsed the message, false
       *    otherwise.
       */
      private _handleGetBinaryInfoMessage = (messageContents, onDone) => {
         let tokenObject,
            name,
            images = [],
            imageCount,
            image,
            imageBGRASize,
            i;

         tokenObject = StringUtils.uint8ArrayTokenize(messageContents, 0);
         name = StringUtils.uint8ArrayToString(tokenObject.result);
         if (name.length === 0) {
            Logger.error("Unable to parse application name from " + "getBinaryInfo response.", Logger.UNITY);
            return false;
         }

         tokenObject = StringUtils.uint8ArrayTokenize(messageContents, 0, tokenObject.index + 1);
         if (tokenObject.result.length === 0) {
            Logger.error(
               'Unable to parse image count for application "' + name + '" from getBinaryInfo response.',
               Logger.UNITY
            );
            return false;
         }

         imageCount = parseInt(StringUtils.uint8ArrayToString(tokenObject.result), 10);

         for (i = 0; i < imageCount; i++) {
            image = {};

            // The first null-separated string is the image width.
            tokenObject = StringUtils.uint8ArrayTokenize(messageContents, 0, tokenObject.index + 1);
            image.width = parseInt(StringUtils.uint8ArrayToString(tokenObject.result), 10);

            // Next is the image height.
            tokenObject = StringUtils.uint8ArrayTokenize(messageContents, 0, tokenObject.index + 1);
            image.height = parseInt(StringUtils.uint8ArrayToString(tokenObject.result), 10);

            /*
             * Next is the image size, which tells us how many image bytes are after
             * the next null.
             */
            tokenObject = StringUtils.uint8ArrayTokenize(messageContents, 0, tokenObject.index + 1);
            imageBGRASize = parseInt(StringUtils.uint8ArrayToString(tokenObject.result), 10);

            // Next is the image data.
            image.bgra = messageContents.subarray(tokenObject.index + 1, tokenObject.index + imageBGRASize + 1);
            tokenObject.index += imageBGRASize + 1; // Skip the following null
            // byte too.

            // Now do some sanity checking
            if (image.width <= 0 || image.height <= 0 || imageBGRASize <= 0 || imageBGRASize !== image.bgra.length) {
               Logger.error(
                  "Failed to parse image index " +
                     i +
                     ' for application "' +
                     name +
                     '", image=' +
                     JSON.stringify(image) +
                     ", image.bgra.length=" +
                     image.bgra.length +
                     ", imageBGRASize=" +
                     imageBGRASize,
                  Logger.UNITY
               );
            } else {
               images.push(image);
            }
         }

         if (onDone) {
            onDone(name, images);
         }

         return true;
      };

      /**
       * handleGetIconDataMessage
       *
       * Helper function used to parse and handle the response to
       * RPC_GET_ICON_DATA.
       *
       * @param messageContents  The message-specific contents in a Uint8Array.
       * @param onDone           Done slot that caller passed into
       *    getIconData().
       * @param cookie           cookie that was used for last
       *    RPC_GET_ICON_DATA message.
       * @return                 true if successfully parsed the message, false
       *    otherwise.
       */
      private _handleGetIconDataMessage = (messageContents, onDone, cookie) => {
         let iconRequest,
            tokenObject,
            fullIconLength,
            returnedIconLength,
            iconChunk,
            spaceCharCode = 32;

         iconRequest = this.pendingIconRequests[cookie];
         if (!iconRequest) {
            // We need callback info because that's where we store icon chunks.
            Logger.error(
               "Unexpectedly unable to find request info for " + " getIconData response with cookie " + cookie,
               Logger.UNITY
            );
            return false;
         }

         // The first token returned is the full image length.
         tokenObject = StringUtils.uint8ArrayTokenize(messageContents, spaceCharCode);
         if (tokenObject.result.length === 0) {
            Logger.error("Unable parse full image length from " + "getIconData response.", Logger.UNITY);
            return false;
         }

         fullIconLength = parseInt(StringUtils.uint8ArrayToString(tokenObject.result), 10);

         tokenObject = StringUtils.uint8ArrayTokenize(messageContents, spaceCharCode, tokenObject.index + 1);
         if (tokenObject.result.length === 0) {
            Logger.error("Unable to parse returned image length from " + "getIconData response.", Logger.UNITY);
            return false;
         }

         returnedIconLength = parseInt(StringUtils.uint8ArrayToString(tokenObject.result), 10);

         if (fullIconLength <= 0 || returnedIconLength <= 0) {
            Logger.error(
               "getIconData response had bad icon lengths: " + fullIconLength + ", " + returnedIconLength,
               Logger.UNITY
            );
            return false;
         }

         if (iconRequest.iconPosition + returnedIconLength > fullIconLength) {
            Logger.error(
               "getIconData response overflowed end of buffer. Expected " +
                  "end to be " +
                  fullIconLength +
                  " but received end of " +
                  (iconRequest.iconPosition + returnedIconLength),
               Logger.UNITY
            );
            return false;
         }

         tokenObject.index++;
         iconChunk = messageContents.subarray(tokenObject.index, tokenObject.index + returnedIconLength);

         if (iconChunk.length !== returnedIconLength) {
            Logger.error(
               "getIconData response had bad icon data. " +
                  "Expected " +
                  returnedIconLength +
                  " bytes but " +
                  "only received " +
                  iconChunk.length +
                  " bytes.",
               Logger.UNITY
            );
            return false;
         }

         if (returnedIconLength === fullIconLength && !iconRequest.iconData) {
            // Avoid an extra copy by just using the array directly.
            iconRequest.iconData = iconChunk;
         } else {
            if (!iconRequest.iconData) {
               // There is more image data to come, so allocate the full length.
               iconRequest.iconData = new Uint8Array(fullIconLength);
            }
            iconRequest.iconData.set(iconChunk, iconRequest.iconPosition);
         }

         iconRequest.iconPosition += iconChunk.length;

         if (iconRequest.iconPosition < fullIconLength) {
            Logger.trace(
               "Received " +
                  iconRequest.iconPosition +
                  " of " +
                  fullIconLength +
                  " icon bytes for cookie " +
                  cookie +
                  ", so sending another RPC.",
               Logger.UNITY
            );

            this._getIconDataChunk(
               iconRequest.windowId,
               iconRequest.size,
               iconRequest.onDone,
               iconRequest.onAbort,
               iconRequest.iconChunkSize,
               iconRequest.iconPosition,
               cookie
            );
         } else {
            Logger.trace(
               "Received full " + iconRequest.iconPosition + " bytes for cookie " + cookie + ", so all done."
            );

            delete this.pendingIconRequests[cookie];
            if (onDone) {
               onDone(iconRequest.iconData);
            }
         }

         return true;
      };

      /**
       * handleGetExecInfoHashMessage
       *
       * Helper function used to parse and handle the response to
       * RPC_GET_EXEC_INFO_HASH.
       *
       * @param messageContents  The message-specific contents in a Uint8Array.
       * @param onDone           Done slot that caller passed into
       *    getExecInfoHash().
       * @return                 true if successfully parsed the message, false
       *    otherwise.
       */
      private _handleGetExecInfoHashMessage = (messageContents, onDone) => {
         let xdrBuffer, xdrObjectVersion, xdrObjectMoreData, xdrObjectData;

         /*
          * We need to XDR decode the response, which we read in the following order:
          *   uint32 with struct version, 4-byte-aligned bool explaining whether
          *   there is more data (should be true), then string with exec info hash.
          */
         xdrBuffer = new VDPXdrBuffer();
         xdrBuffer.initDecoder(messageContents);
         xdrObjectVersion = xdrBuffer.readUint32();
         xdrObjectMoreData = xdrBuffer.readUint32() !== 0;

         // Note: We treat an empty exec info hash as success for now.
         if (xdrObjectVersion === EXEC_INFO_HASH_V1 && xdrObjectMoreData) {
            xdrObjectData = xdrBuffer.readString();

            Logger.trace("Received exec info hash: " + xdrObjectData, Logger.UNITY);
            if (onDone) {
               onDone(xdrObjectData);
            }

            return true;
         } else {
            Logger.error(
               "Received unexpected exec info hash response. version=" +
                  xdrObjectVersion +
                  ", moreData=" +
                  xdrObjectMoreData,
               Logger.UNITY
            );
            return false;
         }
      };

      private _handleSystemTrayIconMessage = (messageContents) => {
         let xdrBuffer, xdrObjectVersion, xdrObjectMoreData, systemTrayIconData;
         /*
          * We need to XDR decode the response, which we read in the following order:
          *   uint32 with struct version, 4-byte-aligned bool explaining whether
          *   there is more data (should be true), then string with data info is as below:
          *   IconID : String
          *   TrayIcpnOp: Unit32:
          *      GHI_TRAY_ICON_OP_ADD = 1,
          *      GHI_TRAY_ICON_OP_MODIFY,
          *      GHI_TRAY_ICON_OP_DELETE
          *   Flags: Unkit32
          *      GHI_TRAY_ICON_FLAG_PNGDATA      = 1, /* The pngData is valid.
          *      GHI_TRAY_ICON_FLAG_TOOLTIP      = 2, /* The tooltip is valid.
          *      GHI_TRAY_ICON_FLAG_BLACKLISTKEY = 4  /* The blacklistKey is valid.
          *   Width: Unit32
          *   Height: Unit32
          *   PngData: Blob(len, data)
          *   Tooltip: String
          *   BlackList: String
          */
         xdrBuffer = new VDPXdrBuffer();
         xdrBuffer.initDecoder(messageContents);
         xdrObjectVersion = xdrBuffer.readUint32();
         xdrObjectMoreData = xdrBuffer.readUint32() !== 0;

         if (xdrObjectVersion === GHI_TRAY_ICON_V1 && xdrObjectMoreData) {
            systemTrayIconData = {
               wmksKey: null,
               isFoucused: false,
               iconId: xdrBuffer.readString(),
               iconOp: xdrBuffer.readUint32(),
               iconFlags: xdrBuffer.readUint32(),
               iconWidth: xdrBuffer.readUint32(),
               iconHeight: xdrBuffer.readUint32(),
               iconSrc: xdrBuffer.readBlob(),
               iconTooltip: xdrBuffer.readString(),
               iconBlackListKey: xdrBuffer.readString()
            };

            Logger.trace("Received PerfTracker update info: " + systemTrayIconData, Logger.UNITY);
            if (this.ops) {
               this.ops.onTrayIconChanged(systemTrayIconData);
            }
            return true;
         } else {
            Logger.error(
               "Received unexpected PerfTracker update info response. version=" +
                  xdrObjectVersion +
                  ", moreData=" +
                  xdrObjectMoreData,
               Logger.UNITY
            );
            return false;
         }
      };

      /**
       * handleUnityUpdateMessage
       *
       * Helper function used to parse and handle a Unity update message.
       *
       * @param messageContents  The message-specific contents in a Uint8Array.
       * @return                 true if successfully parsed the message, false
       *    otherwise.
       */
      private _handleUnityUpdateMessage = (messageContents) => {
         let messageComponents,
            i,
            listenerCalled = false;

         if (!this.paused) {
            messageComponents = StringUtils.uint8ArrayToString(messageContents).split("\x00");
            if (messageComponents instanceof Array) {
               for (i = 0; i < messageComponents.length; i++) {
                  if (messageComponents[i].length !== 0) {
                     if (this._parseUnityUpdate(messageComponents[i])) {
                        listenerCalled = true;
                     }
                  }
               }

               if (messageComponents.length > 0) {
                  Logger.trace(
                     "Finished parsing current Unity update. One or " +
                        "more listeners " +
                        (listenerCalled ? "were" : "were not") +
                        " called.",
                     Logger.UNITY
                  );
                  if (listenerCalled && !!this.ops) {
                     this.ops.onUpdateComplete();
                  }
               }
            } else {
               return false;
            }
         }

         return true;
      };

      /**
       * handleUnityActiveMessage
       *
       * Helper function used to parse and handle a Unity active message.
       *
       * @param messageContents  The message-specific contents in a Uint8Array.
       * @return                 true if successfully parsed the message, false
       *    otherwise.
       */
      private _handleUnityActiveMessage = (messageContents) => {
         let xdrBuffer, xdrActiveVersion, xdrActiveData;

         xdrBuffer = new VDPXdrBuffer();
         xdrBuffer.initDecoder(messageContents);
         xdrActiveVersion = xdrBuffer.readUint32();
         xdrActiveData = xdrBuffer.readUint32();

         if (xdrActiveVersion === ACTIVE_V1) {
            if (this.ops) {
               this.ops.onActiveChanged(xdrActiveData);
            }
            return true;
         } else {
            return false;
         }
      };

      /**
       * handleNotifyReadyMessage
       *
       * Helper function used to parse and handle a Unity ready message.
       *
       * @param messageContents  The message-specific contents in a Uint8Array.
       * @return                 true if successfully parsed the message, false
       *    otherwise.
       */
      private _handleNotifyReadyMessage = (messageContents) => {
         const messageComponents = StringUtils.uint8ArrayToString(messageContents).split("\x00");

         if (messageComponents instanceof Array && messageComponents.length >= 1) {
            if (this.ops) {
               this.ops.onReadyChanged(messageComponents[0] === "ready");
            }
            return true;
         } else {
            return false;
         }
      };
   }
}
