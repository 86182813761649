/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export const COOKIE = {
   VALUE_DELIMITER: "/",
   USER_NAME: "UID",
   TOKEN_USER_NAME: "TID",
   DOMAIN_NAME: "DID",
   IDLE_TIMEOUT: "ITO",
   SEND_TIME_INTERVAL: "STI",
   HORIZON_ID: "HID",
   F5_MODE: "F5_VdiUserClientChoicevmware_view",
   TOUCH_MODE: "Touch_Mode"
};

export const AB = {
   minScreenWidth: 320,
   minScreenHeight: 240,
   DPI_100_PERCENT: 96,
   DEFAULT_ICON_INDEX: 0
};

export const CLIENT = {
   viewclientType: __CLIENT_TYPE__,
   cartName: __VDM_CART_RELEASE_NAME__, //e.g. CART19FQ4
   clientVersion: __VDM_WEB_CLIENT_VERSION__, //e.g. 4.10.0
   clientShortVersion: __VDM_WEB_CLIENT_SHORT_VERSION__, //e.g. 4.10
   marketVersion: __MARKET_VERSION__, //e.g. 2006 (format: YYMM)
   ANONYMOUS_MODE_KEY: "ANONYMOUS_MODE",
   SP_ID_FOR_WS1_KEY: "SP_ID_FOR_WS1",
   CLIENT_LOGIN_KEY: "HTML_ACCESS_LOGIN"
};

export const CLIENT_MODE = {
   LAUNCHER: "launcher",
   DESKTOP: "desktop"
};

export const ICON_TYPE = {
   DEFAULT: "default",
   W365: "W365"
};

export enum CLIENT_TYPE {
   TITAN = "titan",
   HORIZON = "horizon"
}

export enum ENTITLE_TYPE {
   DESKTOP,
   APPLICATION,
   UNKNOWN
}

export enum LAUNCH_CLIENT_TYPE {
   UNDEFINED = "UNDEFINED",
   HORIZON_CLIENT = "HORIZON_CLIENT",
   BROWSER = "BROWSER",
   HORIZON_CHROME_NATIVE = "HORIZON_CHROME_NATIVE"
}
