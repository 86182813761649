<div class="ui-disclaimer-panel">
   <!-- TODO-NG: Add back pending Elements-->
   <div class="ui-panel-content">
     <pre [hidden]="blankOutContent" class="ui-disclaimer-text pre-scrollable" tabindex="0">{{disclaimerText}}</pre>
     <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
   </div>
   <div class="ui-panel-footer">
       <button id="acceptDisclaimerBtn" type="submit" class="btn btn-primary" [ngClass]="{'btnPrimaryWaiting':blankOutContent}" [hidden]="blankOutContent" (click)="accept()" [autoFocus]>{{ 'dialog_wait_accept' | i18nv2 }}</button>
       <button id="cancelDisclaimerBtn" class="btn btn-default" [ngClass]="{'btnDefaultWaiting':blankOutContent}" (click)="cancel()">{{ 'CANCEL' | i18nv2 }}</button>
   </div>
</div>