/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { signal } from "../../../common/service/signal";
import { Monitor } from "../../multimon/common/monitor-message";

export abstract class DisplayService extends signal {
   public isSupportedPlatform: boolean;
   public hasExtraDisplay: boolean;
   public displayReady: boolean;
   public windowReplacementPermissionStatus: string;

   public setExtendedMonitorEnterFullscreen(): void {}
   public getAndInitScreenDetails(): any {}
   public checkPermissionAndExtraDisplay(session): any {}
   public getExtendedScreens() {}
   public getPrimaryScreen() {}
   public setSelectedMonitor(displayOption: number, selectedMonitor: any): void {}
   public getHasExtraDisplay = () => {
      return this.hasExtraDisplay;
   };
   public getDisplayReady = () => {
      return this.displayReady;
   };
   public isCurrentScreen = (pos) => {
      return false;
   };

   public abstract close();
   public abstract init(url: string);
   public abstract sendToDisplay(message: Monitor.MonitorMessage, window?);
   public abstract entendDisplay(extendedWindow?);
}
