/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injector, Injectable } from "@angular/core";
import { LAUNCH_CLIENT_TYPE, Logger } from "@html-core";
import { BusEvent, EventBusService } from "../../../core/services/event";
import { JscdkWrapper } from "../../../shared/common/jscdk/jscdk-wrapper";
import { XMLPreference } from "../../../shared/common/service/prefdata";
import { UserGlobalPref } from "../../../shared/common/service/user-global-pref";
import { LAUNCH_TYPE, XmlApiService } from "../../../shared/desktop/sidebar/xml-api.service";
import { UtilService } from "../../../shared/launcher/common/util-service";

@Injectable({
   providedIn: "root"
})
export class HorizonXmlApiService extends XmlApiService {
   private logger = new Logger(Logger.JSCDK);
   constructor(
      private injector: Injector,
      private eventBusService: EventBusService,
      private jscdkWrapper: JscdkWrapper,
      private utilService: UtilService,
      private userGlobalPref: UserGlobalPref
   ) {
      super(injector);
   }

   public init = (): Promise<void> => {
      return new Promise((resolve, reject) => {
         this.jscdkWrapper.addInitDoneCallback(() => {
            resolve();
         });
      });
   };

   public handleResetDesktop = (item: any): void => {
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
      this.jscdkWrapper.resetDesktop(item.wmksKey, (response) => {
         this._sessionOpResultCallback(item, response);
      });
   };

   public handleRestartDesktop = (item: any): void => {
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
      this.jscdkWrapper.restartDesktop(item.wmksKey, (response) => {
         this._sessionOpResultCallback(item, response);
      });
   };

   public handleLogoffDesktop = (item: any): void => {
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
      this.jscdkWrapper.killItem(item.wmksKey || item.id, this.jscdkWrapper.itemTypeEnum.desktop, (response) => {
         this._sessionOpResultCallback(item, response);
      });
   };

   public handleCloseDesktop = (item: any): void => {
      this.eventBusService.dispatch(new BusEvent.SessionCloseMsg(item.wmksKey));
   };

   public getUserGlobalPref = (): Promise<XMLPreference> => {
      return this.jscdkWrapper.getUserGlobalPref();
   };

   public checkAuthStatus = (): Promise<void> => {
      return new Promise((resolve, reject) => {
         this.utilService.authenticationStatusCheck.callBackWhenUnlocked(() => {
            resolve();
         });
      });
   };

   public launchItem = (item: EntitlementItem, type: LAUNCH_TYPE): Promise<any> => {
      return new Promise((resolve, reject) => {
         this.jscdkWrapper.launch(item.id, type, (response) => {
            resolve(response);
         });
      });
   };

   public reconnectApp = (sessionKey: string): Promise<any> => {
      return new Promise((resolve, reject) => {
         this.jscdkWrapper.reconnectApplicationSessions(
            (response) => {
               resolve(response);
            },
            null,
            [],
            sessionKey
         );
      });
   };

   public logout = (): Promise<void> => {
      return new Promise((resolve, reject) => {
         this.jscdkWrapper.logout(() => {
            resolve();
         });
      });
   };

   public getFavorites = (): Array<string> => {
      const prefer: XMLPreference = this.userGlobalPref.getPrefData();
      if (prefer) {
         return prefer.favorites;
      } else {
         return null;
      }
   };

   /**
    * _sessionOpResultCallback
    *
    * Callback to handle results from a session operation call. Pops up an
    * error dialog if the call fails.
    *
    * @param item the item on which the call was performed.
    * @param response the response to the session operation call.
    */
   private _sessionOpResultCallback = (item, response) => {
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
      if (!response.success) {
         this._showErrorMessage(null, { rawMessage: response.error.userMsg });
      }
   };

   /**
    * In Q1, we use the dialog to show 2 display message since user
    *    is doing valid operations but the result is not a valid horizon
    *    multimon setting.
    */
   private _showErrorMessage = (contentKey, options) => {
      options = options || {};
      if (options.rawMessage) {
         this.modalDialogService.showError({
            data: {
               title: this.translate._T("ERROR"),
               content: options.rawMessage
            }
         });
      } else {
         this.modalDialogService.showError({
            data: {
               titleKey: "ERROR",
               contentKey: contentKey,
               contentSubstitutionsKey: options.massageParam
            }
         });
      }
   };

   public launchNativeItem = (item: any, type: any) => {
      this.logger.warning("launchNativeItem NOT IMPLEMENTED");
   };

   public getLaunchMethodForItem = (item: EntitlementItem): LAUNCH_CLIENT_TYPE => {
      return LAUNCH_CLIENT_TYPE.BROWSER;
   };

   public hasApplicationSession = (): Promise<any> => {
      return this.jscdkWrapper.hasApplicationSession();
   };

   public resetAllApplications = (callback: Function, single: boolean) => {
      this.jscdkWrapper.resetAllApplications(callback, single);
   };
}
