/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { IMessage } from "./abstract-message.service";

abstract class EventBusServiceMessage {
   public SWITCH_PAGE_MSG: string = "switch_page_msg";
   public REAUTH_PASSWORD_EXPIRED_MSG: string = "reAuthWindowsPasswordExpired";
   public CHANGE_WINDOW_PASSWORD_MSG: string = "changeWindowsPassword";
   public GLOBAL_PREFS_UPDATE_MSG: string = "userGlobalPreferRead";
   public GET_LAUNCH_ITEMS_MSG: string = "GetLaunchItems";
   public DESTROY_AZURE_WAITING_UI: string = "DestroyAzureWaitingUI";
}

@Injectable({
   providedIn: "root"
})
export class EventBusService extends EventBusServiceMessage {
   private eventBus$: Subject<IMessage>;

   constructor() {
      super();
      this.eventBus$ = new Subject<IMessage>();
   }

   public dispatch = (data: IMessage) => {
      this.eventBus$.next(data);
   };

   public listen = (key: string): Observable<IMessage> => {
      return this.eventBus$.pipe(filter((event: IMessage) => event.type === key));
   };
}
