/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { HTML5MMR_CONST, JKEYS, WEB_COMMAND } from "../html5MMR-consts";
export namespace HTML5MMR {
   export class GeolocationCoordinates {
      latitude: number;
      longitude: number;
      constructor(lat: number, long: number) {
         this.latitude = lat;
         this.longitude = long;
      }
   }

   export class E911Info {
      geolocation: HTML5MMR.GeolocationCoordinates;

      constructor(location: GeolocationPosition) {
         this.updateGeolocation(location);
      }

      private updateGeolocation(location: GeolocationPosition) {
         if (location === null) {
            return;
         }
         const coords = location.coords;
         if (coords) {
            this.geolocation = new HTML5MMR.GeolocationCoordinates(coords.latitude, coords.longitude);
         }
      }

      public get responseObj(): any {
         const res: any = {};
         res[JKEYS.EVT] = HTML5MMR_CONST.VDIE911INFOCHANGED;
         if (this.geolocation && this.geolocation.latitude && this.geolocation.longitude) {
            res.latitude = this.geolocation.latitude;
            res.longitude = this.geolocation.longitude;
         }
         return res;
      }
   }
}
