/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { BorderUtil } from "../border-util";
import { RemoteappBorderService } from "../../app-util/remoteapp-border.service";

export class SingleMonitor {
   public canvasId = "mainCanvas";
   private _remoteappBorderService: RemoteappBorderService = null;
   constructor(remoteappBorderService) {
      this._remoteappBorderService = remoteappBorderService;
   }
   public listen = () => {};
   public hideVisibleShape = () => {
      this._remoteappBorderService.overlayCtlService.hideVisibleShape();
   };
   public setVisibleBorder = (drawingRect) => {
      const borderShape = BorderUtil.getBorderShape(drawingRect, true);
      this._remoteappBorderService.overlayCtlService.setVisibleShape(borderShape, drawingRect);
   };
   public moveWindow = (drawingRect) => {
      drawingRect = this.revertRect(drawingRect);
      this._remoteappBorderService.moveWindowDirect(drawingRect);
   };
   public getDetectionTarget = () => {
      const rebased = this._remoteappBorderService.topWindow.relativeTitleBarArea;
      const rect = this.normalizeRect(this._remoteappBorderService.topWindow.clientSingleRect);
      return {
         left: Math.round(rebased.left + rect.left),
         top: Math.round(rebased.top + rect.top),
         right: Math.round(rebased.right + rect.left),
         bottom: Math.round(rebased.bottom + rect.top)
      };
   };

   public updateDrawingRect = (drawingRect) => {};

   public toLocalCoordinate = (rect) => {
      return this.normalizeRect(rect);
   };

   public normalizeBorderRect = (rect) => {
      return rect;
   };

   public normalizeRect = (rect) => {
      return rect;
   };

   public revertRect = (rect) => {
      return rect;
   };
}
