/**
 * ******************************************************
 * Copyright (C) 2020-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { clientUtil } from "../../../core/libs";

@Injectable({
   providedIn: "root"
})
export class DisplayCheckService {
   // workaround for bug 3117746
   private _screenHeight = screen.height;
   private _inMultimonMode = false;
   private _macNotchMonitor = [
      { width: 1168, height: 755, notchHeight: 27 },
      { width: 1312, height: 848, notchHeight: 29 },
      { width: 1496, height: 967, notchHeight: 33 },
      { width: 1728, height: 1117, notchHeight: 37 },
      { width: 2056, height: 1329, notchHeight: 43 }
   ];
   private _currentMonitorDPI = window.devicePixelRatio;

   // this dpi is not affected by browser zoom in/out
   public setCurrentMonitorDPI = (dpi) => {
      this._currentMonitorDPI = dpi;
   };

   public getMacNotchHeight = () => {
      if (!clientUtil.isMacOS()) {
         return 0;
      }
      if (this._currentMonitorDPI !== 2) {
         return 0;
      }
      const notchMonitorIndex = this._macNotchMonitor.findIndex(
         (s) => s.width === clientUtil.getScreenWidth() && s.height === clientUtil.getScreenHeight()
      );
      if (-1 === notchMonitorIndex) {
         return 0;
      } else {
         return this._macNotchMonitor[notchMonitorIndex].notchHeight;
      }
   };

   public getScreenHeightWithoutNotchHeight = () => {
      return this.getScreenHeight() - this.getMacNotchHeight();
   };

   public syncScreenHeight = () => {
      this._inMultimonMode = true;
      this._screenHeight = screen.height;
   };

   public getScreenHeight = () => {
      if (clientUtil.isChromeClient()) {
         return screen.height;
      }
      return this._inMultimonMode ? this._screenHeight : screen.height;
   };

   public quitMultimonMode = () => {
      this._inMultimonMode = false;
   };

   /**
    * Helper function to check whether non-trivial rectangles has adjacent
    * Edge. It's written in the less effective but more readable way
    *
    * Since In Q1 we have other functions to implement use a tricky tolerentY
    *    to cope with the problem that browser can't return real monitor
    *    resolution since the up and down system bar area is not allow to
    *    be used by browser, it will make the condition losser than it should
    *    be, but the condition-difference is very trivial and demage
    *    acceptable, and this is disucssed with Jack and get agreement for Q1.
    *    And we should change this after Q1 by adjust workflow if PM is ok
    *    with the drawback of that "good" design which will make the screen
    *    blink once before seeing error message.
    *
    * To fix the issue when task bar in the left side or right side, add
    *  reRectanglesAdjacentTastBarLeftRight function. use a tricky tolerentX.
    *
    * @param  {object} rectA
    * @param  {object} rectB
    * @return {boolean}
    */
   public areRectanglesAdjacent = (rectA, rectB) => {
      const tolerentX = 100,
         tolerentY = 100,
         minAx = rectA.x,
         minAy = rectA.y,
         maxAx = rectA.x + rectA.width,
         maxAy = rectA.y + rectA.height,
         minBx = rectB.x,
         minBy = rectB.y,
         maxBx = rectB.x + rectB.width,
         maxBy = rectB.y + rectB.height,
         minAxInBx = this._InRange(minAx, minBx, maxBx),
         maxAxInBx = this._InRange(maxAx, minBx, maxBx),
         minAyInBy = this._InRange(minAy, minBy, maxBy),
         maxAyInBy = this._InRange(maxAy, minBy, maxBy),
         minBxInAx = this._InRange(minBx, minAx, maxAx),
         maxBxInAx = this._InRange(maxBx, minAx, maxAx),
         minByInAy = this._InRange(minBy, minAy, maxAy),
         maxByInAy = this._InRange(maxBy, minAy, maxAy),
         someAxInBx = minAxInBx || maxAxInBx,
         someAyInBy = minAyInBy || maxAyInBy,
         someBxInAx = minBxInAx || maxBxInAx,
         someByInAy = minByInAy || maxByInAy,
         axEqualBx = minAx === minBx && maxAx === maxBx,
         ayEqualBy = minAy === minBy && maxAy === maxBy,
         xShareRange = someAxInBx || someBxInAx || axEqualBx,
         yShareRange = someAyInBy || someByInAy || ayEqualBy,
         aAboveBTastBarLeftRight = minAy === maxBy,
         aBelowBTastBarLeftRight = maxAy === minBy,
         aRightOfBTastBarLeftRight = maxBx <= minAx && minAx < maxBx + tolerentX,
         aLeftOfBTastBarLeftRight = minBx - tolerentX < maxAx && maxAx <= minBx,
         aLeftOfBTastBarTopBottom = maxAx === minBx,
         aRightOfBTastBarTopBottom = minAx === maxBx,
         aBelowBTastBarTopBottom = maxBy <= minAy && minAy < maxBy + tolerentY,
         aAboveBTastBarTopBottom = minBy - tolerentY < maxAy && maxAy <= minBy,
         reRectanglesAdjacentTastBarLeftRight =
            (aLeftOfBTastBarLeftRight && yShareRange) ||
            (aRightOfBTastBarLeftRight && yShareRange) ||
            (aAboveBTastBarLeftRight && xShareRange) ||
            (aBelowBTastBarLeftRight && xShareRange),
         reRectanglesAdjacentTastBarTopBottom =
            (aLeftOfBTastBarTopBottom && yShareRange) ||
            (aRightOfBTastBarTopBottom && yShareRange) ||
            (aAboveBTastBarTopBottom && xShareRange) ||
            (aBelowBTastBarTopBottom && xShareRange);

      Logger.debug("isReRectanglesAdjacentTastBarTopBottom: " + reRectanglesAdjacentTastBarTopBottom, Logger.DISPLAY);
      Logger.debug("isReRectanglesAdjacentTastBarLeftRight: " + reRectanglesAdjacentTastBarLeftRight, Logger.DISPLAY);
      return reRectanglesAdjacentTastBarLeftRight || reRectanglesAdjacentTastBarTopBottom;
   };

   /**
    * Helper function to check whether value in (min, max)
    * @param {number} value
    * @param {number} min
    * @param {number} max
    * @return {boolean}
    */
   private _InRange = (value, min, max) => {
      return min < value && value < max;
   };

   /**
    * Helper function to check whether non-trivial rectangles has non-zero
    *    shared area
    * It's written in the less effective but more readable way
    * @param  {object} rectA
    * @param  {object} rectB
    * @return {boolean}
    */
   public rectanglesShareArea = (rectA, rectB) => {
      const minAx = rectA.x,
         minAy = rectA.y,
         maxAx = rectA.x + rectA.width,
         maxAy = rectA.y + rectA.height,
         minBx = rectB.x,
         minBy = rectB.y,
         maxBx = rectB.x + rectB.width,
         maxBy = rectB.y + rectB.height,
         aLeftOfB = maxAx <= minBx,
         aRightOfB = minAx >= maxBx,
         aAboveB = minAy >= maxBy,
         aBelowB = maxAy <= minBy;

      return !(aLeftOfB || aRightOfB || aAboveB || aBelowB) || this._rectanglesCoincide(rectA, rectB);
   };

   /**
    * Helper function to check whether rectangles coincide
    * @param  {object} rectA
    * @param  {object} rectB
    * @return {boolean}
    */
   private _rectanglesCoincide = (rectA, rectB) => {
      return rectA.x === rectB.x && rectA.y === rectB.y && rectA.width === rectB.width && rectA.height === rectB.height;
   };

   /**
    * Helper function to check whether targetKey is conflict with others,
    *    and always return false for key "0"
    * @param {string} targetKey The key we check for
    * @param {object} content Contains all the items
    * @return {boolean} This will return result
    */
   public hasConflict = (targetKey, content) => {
      let key,
         targetRect = content[targetKey].settings,
         comparedRect;

      if (targetKey === "0") {
         return false;
      }
      for (key in content) {
         if (content.hasOwnProperty(key) && targetKey !== key) {
            comparedRect = content[key].settings;
            if (this.rectanglesShareArea(targetRect, comparedRect)) {
               return true;
            }
         }
      }
      return false;
   };

   public isSameMonitor = (monitor1, monitor2) => {
      let checkLabel = true;
      const x1 = monitor1.x !== undefined ? monitor1.x : monitor1.left;
      const y1 = monitor1.y !== undefined ? monitor1.y : monitor1.top;
      const x2 = monitor2.x !== undefined ? monitor2.x : monitor2.left;
      const y2 = monitor2.y !== undefined ? monitor2.y : monitor2.top;

      if (monitor1.label && monitor2.label) {
         checkLabel = monitor1.label === monitor2.label;
      }

      return (
         checkLabel &&
         x1 === x2 &&
         y1 === y2 &&
         monitor1.width === monitor2.width &&
         monitor1.height === monitor2.height
      );
   };

   public isWindowInMaximum = () => {
      // extra condition for fixing issue when place browser on extended monitor
      return (
         screen.availWidth === screen.width &&
         screen.availHeight === screen.height &&
         screenX === screen.availLeft &&
         screenY === screen.availTop
      );
   };
}
