/**
 * *************************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview rtavSettingManagerV2.ts
 *
 * Class that contains functions and logic for managing and formatting RTAV configurable settings
 *
 */

import { Injectable } from "@angular/core";
import { settings } from "./rtav.settings";
import { Platforms, AVPluginState, Versionnum, CodecTypes } from "./rtav.constants";
import { PreferredRTAVDeviceService } from "../../common/service/preferred-RTAV-device.service";
import { PreferredDeviceType, VideoDeviceResolution, getPreferredDeviceKey } from "./v2/device-manager.model";
import { DeviceManagerService } from "./v2/device-manager.service";
import { Logger } from "@html-core";

let proto = null;
try {
   proto = require("./v2/rtav.proto");
} catch (e) {
   Logger.error(e, Logger.RTAV);
}

const RTAV_AUDIO_DISABLED = 0x0001;
const RTAV_VIDEO_DISABLED = 0x0002;
const RTAV_AUDIO_VIDEO_DISABLED = RTAV_AUDIO_DISABLED | RTAV_VIDEO_DISABLED;

@Injectable()
export class RtavSettingManagerV2 {
   private adminPolicy;
   private localConfig;
   private isEnabled;
   private currState;

   constructor(
      private preferredDeviceService: PreferredRTAVDeviceService,
      private deviceManagerService: DeviceManagerService
   ) {}

   private get_v2_version() {
      return (
         ((Versionnum.v2_x & 0x00ff) << 24) | ((Versionnum.v2_y & 0x00ff) << 16) | ((Versionnum.v2_z & 0xffff) << 0)
      );
   }

   // setting the local config with admin policy and local settings we will provide user UI for that.
   public async setLocalConfig(adminPolicy) {
      let localWidth, localHeight, localMaxFPS;

      let clientDefaultResWidth = 0;
      let clientDefaultResHeight = 0;
      const userSelectedResolution = await this.preferredDeviceService.readKey(
         getPreferredDeviceKey(PreferredDeviceType.videoResolution)
      );
      if (userSelectedResolution) {
         try {
            const videoDeviceResolution = JSON.parse(userSelectedResolution as string) as VideoDeviceResolution;
            clientDefaultResWidth = videoDeviceResolution.width;
            clientDefaultResHeight = videoDeviceResolution.height;
         } catch (e) {
            // parse failed, not set or wrong value, move on with the agent settings
         }
      }
      // decide localWidth
      if (adminPolicy.vdoMaxResWidth === 0) {
         if (clientDefaultResWidth !== 0) {
            localWidth = clientDefaultResWidth;
         } else if (adminPolicy.vdoDefResWidth !== 0) {
            localWidth = adminPolicy.vdoDefResWidth;
         } else {
            localWidth = settings.video.defResWidth;
         }
      } else {
         if (clientDefaultResWidth !== 0 && clientDefaultResWidth <= adminPolicy.vdoMaxResWidth) {
            localWidth = clientDefaultResWidth;
         } else if (adminPolicy.vdoDefResWidth !== 0 && adminPolicy.vdoDefResWidth < adminPolicy.vdoMaxResWidth) {
            localWidth = adminPolicy.vdoDefResWidth;
         } else {
            localWidth = adminPolicy.vdoMaxResWidth;
         }
      }
      // decide localHeight
      if (adminPolicy.vdoMaxResHeight === 0) {
         if (clientDefaultResHeight !== 0) {
            localHeight = clientDefaultResHeight;
         } else if (adminPolicy.vdoDefResHeight !== 0) {
            localHeight = adminPolicy.vdoDefResHeight;
         } else {
            localHeight = settings.video.defResHeight;
         }
      } else {
         if (clientDefaultResHeight !== 0 && clientDefaultResHeight <= adminPolicy.vdoMaxResHeight) {
            localHeight = clientDefaultResHeight;
         } else if (adminPolicy.vdoDefResHeight !== 0 && adminPolicy.vdoDefResHeight < adminPolicy.vdoMaxResHeight) {
            localHeight = adminPolicy.vdoDefResHeight;
         } else localHeight = adminPolicy.vdoMaxResHeight;
      }

      // decide localMaxFPS
      if (adminPolicy.vdoMaxFPS !== 0) {
         if (settings.video.defMaxFPS !== 0) {
            localMaxFPS = Math.min(settings.video.defMaxFPS, adminPolicy.vdoMaxFPS);
         } else {
            localMaxFPS = adminPolicy.vdoMaxFPS;
         }
      } else {
         localMaxFPS = settings.video.defMaxFPS;
      }

      // round the resolution since cpmpression lib suggest so.
      if (localWidth % 16 !== 0) {
         localWidth = Math.floor(localWidth / 16) * 16;
      }
      if (localHeight % 16 !== 0) {
         localHeight = Math.floor(localHeight / 16) * 16;
      }
      if (adminPolicy.disabledComponent === RTAV_AUDIO_VIDEO_DISABLED) {
         this.isEnabled = false;
         this.currState = AVPluginState.STOff;
      } else {
         this.isEnabled = true;
         this.currState = AVPluginState.STOn;
      }

      const vdoCodec = proto.RTAV.VdoCodec.create({ count: 1, codec: [CodecTypes.CodecVmwOpus] });
      const adoCodec = proto.RTAV.AdoCodec.create({ count: 1, codec: [CodecTypes.CodecVmwH264] });
      this.localConfig = {
         versionNum: this.get_v2_version(),
         currState: this.currState,
         isEnabled: this.isEnabled,
         vdoFPS: localMaxFPS,
         vdoResHeight: localHeight,
         vdoResWidth: localWidth,
         platform: Platforms.WEB,
         vdoWanEnhancementPhase: adminPolicy.vdoWanEnhancementPhase,
         vdoCodec: vdoCodec,
         adoCodec: adoCodec
      };
      this.deviceManagerService.setAgentMaxResolution({
         width: adminPolicy.vdoMaxResWidth,
         height: adminPolicy.vdoMaxResHeight
      });
      this.deviceManagerService.setClientResolution({ width: localWidth, height: localHeight });
   }

   public async setAdminPolicy(policy) {
      this.adminPolicy = policy;
      await this.setLocalConfig(this.adminPolicy);
   }

   public getLocalConfig() {
      return this.localConfig;
   }

   public getAudioDeviceSetting() {
      return settings.audio;
   }

   public getVideoDeviceSetting() {
      return {
         width: this.localConfig.vdoResWidth,
         height: this.localConfig.vdoResHeight,
         fps: this.localConfig.vdoFPS
      };
   }
}
