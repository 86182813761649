/**
 * ******************************************************
 * Copyright (C) 2018-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { AbstractModalComponent } from "../../common/commondialog/abstract-modal.component";
import { DebugConfigurationModel, DubugModuleEntry } from "../../common/model/debug-configuration-model";

@Component({
   selector: "debug-configuration-dialog",
   templateUrl: "./debug-configuration-dialog.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class DebugConfigurationDialogComponent extends AbstractModalComponent {
   public debugModuleList: Array<DubugModuleEntry> = [];

   constructor(private debugConfigurationModel: DebugConfigurationModel) {
      super();
      this.debugModuleList = this.debugConfigurationModel.getDebugConfigModuleList();
   }

   public debugConfigOkClicked = () => {
      this.debugConfigurationModel.setDebugConfigModuleList();
      this.closeModal();
   };

   public onKeypress = (evt) => {
      if (!!evt && evt.keyCode === 32) {
         // Space key is pressed.
         if (evt.target.children && evt.target.children[0]) {
            $(evt.target.children[0]).trigger("click");
         }
         // Prevent default behavior.
         evt.preventDefault();
      }
   };
}
