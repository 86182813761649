/**
 * ******************************************************
 * Copyright (C) 2012-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getDesktopConnectionHandler.js --
 * Implementation of the message handler to get desktop connection.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import SetUserGlobalPrefHandler from "./setUserGlobalPrefHandler";
import ProtocolRedirectionController from "./redirect/protocol-redirection-controller";
import { ShowDesktopAction } from "../jscdk-interface";
import { CryptoKeyDerivationService } from "../../common/service/crypto-key-derivation.service";
import { getUuid } from "@html-core";

const BlastType = {
   CURRENT: "current",
   NEW: "new"
};

export default function GetDesktopConnectionHandler() {
   let getDesktopsObject, getTunnelConnObject, router, setPrefObject;

   // member variables
   this.messageName = "get-desktop-connection";
   this.messageText = "get-desktop-connection";
   this.responseTag = "desktop-connection";
   this.redirectMsg = false;
   // Use current blastType by default.
   this.blastType = BlastType.CURRENT;
   this.entitleId = null;
   this.itemId = null;
   this.preferences = {};

   // register dependencies here
   getDesktopsObject = util.getObject(globalArray, "get-desktops");
   if (getDesktopsObject) {
      this.registerHandler(getDesktopsObject, "state");
   }
   getTunnelConnObject = util.getObject(globalArray, "get-tunnel-connection");
   if (getTunnelConnObject) {
      this.registerHandler(getTunnelConnObject, "state");
   }
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }

   // maybe not good to invoke the SetUserGlobalPrefHandler every time, would
   // be changed when re-factorying
   setPrefObject = util.getObject(globalArray, "set-user-global-preferences");
   if (!setPrefObject) {
      setPrefObject = new SetUserGlobalPrefHandler();
      globalArray["set-user-global-preferences"] = setPrefObject;
   }
   this.composedHandlerList = [this, setPrefObject];
}

//inherits MessageHandler prototype
GetDesktopConnectionHandler.prototype = new MessageHandler();

GetDesktopConnectionHandler.constructor = GetDesktopConnectionHandler;

GetDesktopConnectionHandler.prototype.combineWithPerf = function () {
   const setPrefObject = util.getObject(globalArray, "set-user-global-preferences");
   this.composedHandlerList = [this, setPrefObject];
};
GetDesktopConnectionHandler.prototype.sendAlong = function () {
   this.composedHandlerList = [];
};

/**
 * Set the request XML for getDesktopConnection.
 *
 * @param desktopId [in] desktop id to connect
 * @param protocol [in] which protocol to use
 * @param environmentInfo [in] optional object containing client info
 *    key/value pairs
 */

GetDesktopConnectionHandler.prototype.setRequestXML = function (
   desktopId,
   protocol,
   environmentInfo,
   isShadow,
   reverseToken,
   rdsLicenseInfo
) {
   let isWS1Mode = globalArray["ws1Mode"],
      urlHandler,
      desktopIdElement = util.createElement("desktop-id", desktopId),
      nameElement = util.createElement("name", protocol),
      protocolElement = util.createElement("protocol", nameElement),
      reverseConnectElement = null,
      environmentElement = "",
      infoElements = "",
      samlElement = "",
      key,
      rdsLicenseElement = "",
      rdsLicenseInfoElements = "";

   if (isShadow) {
      desktopIdElement = util.createElement("shadow-session-id", desktopId);
   }

   if (isWS1Mode) {
      urlHandler = util.getObject(globalArray, "url-handler");
      if (!!urlHandler && !!urlHandler.params && !!urlHandler.params.samlArt) {
         samlElement = util.createElement("saml-artifact", urlHandler.params.samlArt);
      }
   }

   // Generate the <environment-info> section if it was specified.
   if (typeof environmentInfo === "object") {
      for (key in environmentInfo) {
         if (environmentInfo.hasOwnProperty(key)) {
            infoElements += util.createElement("info", environmentInfo[key], { name: key });
         }
      }
      environmentElement = util.createElement("environment-information", infoElements);
   }
   if (reverseToken) {
      reverseConnectElement = util.createElement("reverse-connect-token", reverseToken);
   }
   // Generate the <rds-license> section if it was specified.
   if (rdsLicenseInfo) {
      for (key in rdsLicenseInfo) {
         if (rdsLicenseInfo.hasOwnProperty(key)) {
            rdsLicenseInfoElements += util.createElement(key, rdsLicenseInfo[key]);
         }
      }
      rdsLicenseElement = util.createElement("rds-license", rdsLicenseInfoElements);
   }

   this.entitleId = desktopId;
   this.itemId = desktopId;
   this.cachedRequestInfo = {
      isApplication: false,
      id: desktopId,
      "origin-id": null,
      isShadow: isShadow,
      name: ""
   };
   this.requestXML = desktopIdElement + samlElement + protocolElement + environmentElement;
   if (reverseConnectElement) {
      this.requestXML += reverseConnectElement;
   }
   if (rdsLicenseElement) {
      this.requestXML += rdsLicenseElement;
   }
};

/**
 * Set the preferences for getDesktopConnection.
 *
 * @param desktopPreferences [in] the desktop preferences JSON.
 */

GetDesktopConnectionHandler.prototype.setPreferences = function (desktopPreferences) {
   this.preferences = desktopPreferences;
   Logger.debug("Desktop preferences is " + JSON.stringify(this.preferences));
   this.cachedRequestInfo.name = desktopPreferences.desktopName;
};

GetDesktopConnectionHandler.prototype.onUpdated = function () {
   this.decryptResult();
   this.redirectMsg = false;
   if (
      this.content["parsedResult"] &&
      this.content["parsedResult"]["result"] === "ok" &&
      this.content["parsedResult"]["redirect-settings"]
   ) {
      let protocolRedirectionController = util.getObject(globalArray, "protocol-redirect-controller");
      if (!protocolRedirectionController) {
         protocolRedirectionController = new ProtocolRedirectionController();
         globalArray["protocol-redirect-controller"] = protocolRedirectionController;
      }
      protocolRedirectionController
         .redirect("Desktop", this.content["parsedResult"], this.cachedRequestInfo)
         .then((fullResponse) => {
            this.content["parsedResult"] = fullResponse;
            this.redirectMsg = true;
            this._onUpdated();
         })
         .catch((errorObject) => {
            this.redirectMsg = true;
            this._onUpdated();
         });
   } else {
      this._onUpdated();
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetDesktopConnectionHandler.prototype._onUpdated = function () {
   let parsedResult,
      protocolName,
      token,
      launchArgs,
      address,
      port,
      userMessage,
      showDesktopAction = {} as ShowDesktopAction,
      content = {} as ShowDesktopAction["content"],
      redirectProperties,
      router,
      id,
      sessionId,
      userName,
      domainName,
      enableUsb,
      usbTicket,
      isShadow = false,
      storeRedirectInfo = false,
      rdsLicense,
      connectionRetryController = util.getObject(globalArray, "connection-retry-controller");

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's

   /**
    * Add triggerItemInfo for all launch
    * for bug 3161107 VCART-483
    */
   this.triggerItemInfo = {
      type: "HorizonDesktop",
      id: this.cachedRequestInfo.id,
      triggerName: this.preferences.desktopName
   };
   content.triggerItemInfo = this.triggerItemInfo;

   if (this.state === StateEnum.DONE) {
      if (this.content["parsedResult"]) {
         parsedResult = this.content["parsedResult"];
         /**
          * We need to try to find if redirection properties exist for DaaS.
          * If so, there will be no other properties.
          */
         redirectProperties = parsedResult["redirect-properties"];

         if (
            !!redirectProperties &&
            redirectProperties["server-address"] &&
            redirectProperties["desktop-name"] &&
            redirectProperties["saml-art"] &&
            redirectProperties["desktop-protocol"] &&
            redirectProperties["desktop-protocol"].toLowerCase() === "blast"
         ) {
            // Do the redirection
            showDesktopAction.name = "ShowAppBlastDesktop";
            content.redirectProperties = redirectProperties;
            showDesktopAction.content = content;
         } else {
            // No redirection
            protocolName = parsedResult["protocol"];
            if (protocolName.toLowerCase() === "blast") {
               id = parsedResult["id"];
               if (!id) {
                  id = parsedResult["shadow-session-id"];
                  isShadow = id ? true : false;
               }
               // only for titan
               sessionId = parsedResult["desktop-session-id"];
               userName = parsedResult["user-name"];
               domainName = parsedResult["domain-name"];
               address = parsedResult["address"];
               port = parsedResult["port"];
               token = parsedResult["token"];
               if (token === "") {
                  return;
               }
               enableUsb = parsedResult["enable-usb"];
               usbTicket = parsedResult["framework-channel-ticket"];
               storeRedirectInfo = parsedResult["protocol-redirect-recovery"] || true;

               try {
                  launchArgs = JSON.parse(token);
               } catch (err) {
                  Logger.error("Parse blast token has been error: " + err);
               }

               if (!launchArgs) {
                  userMessage = util._("JSCDK_TOKEN_ERROR_JSON_FORMAT");
                  Logger.error("Blast token is not a valid JSON.");
               } else if (!launchArgs.hasOwnProperty("a")) {
                  userMessage = util._("JSCDK_TOKEN_ERROR_JSON_PROPERTY");
                  Logger.error("Blast token has no 'a' property.");
               }
               if (userMessage) {
                  // push error to UI
                  Router.prototype.pushSelfDefinedError(this, userMessage);
               }
               if (parsedResult["rds-license"]) {
                  rdsLicense = parsedResult["rds-license"];
               }
               showDesktopAction.name = "ShowAppBlastDesktop";
               content.id = id;
               content.sessionId = sessionId;
               content.entitleId = this.entitleId;
               content.userName = userName;
               content.domainName = domainName;
               content.enableUsb = enableUsb;
               content.usbTicket = usbTicket;
               content.url = this.createAppBlastLaunchAction(address, port, launchArgs);
               content.preferences = this.preferences;
               content.preferences.blastType = this.blastType;
               content.isApplicationSession = false;
               content.isShadow = isShadow;
               content.rdsLicense = rdsLicense;
               if (storeRedirectInfo) {
                  content.redirectSetting = {} as ProtocolRedirectSettings;
                  if (parsedResult["redirect-settings"]) {
                     content.redirectSetting.url = parsedResult["redirect-settings"]["url"];
                     content.redirectSetting.protocolToken =
                        parsedResult["redirect-settings"]["protocol-redirect-token"];
                     content.redirectSetting.tunnelToken = parsedResult["redirect-settings"]["tunnel-redirect-token"];
                  }
               }
               showDesktopAction.content = content;
            } else {
               // PCoIP or RDP just tells the UI about all of the parameters.
               showDesktopAction.name = "LaunchDesktop";
               showDesktopAction.content = parsedResult;
            }
            router = globalArray["router"];
            // Common code for appblast or others.
            if (router) {
               showDesktopAction.content.brokerUrl = encodeURIComponent(router.brokerUrl);
            }
         }

         /**
          * parsed results of getDesktopConnection are of no use after this
          * function; reset data before UI refresh to avoid it triggered by
          * dependencies for multiple times reset data should be before
          * JSCDKSetUI to avoid unpredictable error during SetUI
          */
         this.content = {};

         connectionRetryController.onReconnectionDone("Reconnected");
         JSCDKSetUI(JSON.stringify(showDesktopAction));
      }
   }
   // if the error is special for this handler, alert
   Router.prototype.pushErrorToUser(this);
};

/**
 * parse desktop connection information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
GetDesktopConnectionHandler.prototype.parseResult = function (responseXML) {
   const self = this;
   let brokerTag;
   let responses;
   let result;
   let tokenText;
   const desktopConnectionResponse = {};
   let xmlDoc;

   xmlDoc = $(responseXML);
   brokerTag = $(xmlDoc.children()[0]);
   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XMpL structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         if (nameText === "redirect-properties") {
            desktopConnectionResponse["redirect-properties"] = self.parseRedirectProperties($(this).children());
         } else if (nameText === "redirect-settings") {
            desktopConnectionResponse["redirect-settings"] = util.parseXML($(this).children());
         } else {
            util.addItemForJson(desktopConnectionResponse, nameText, $(this).text());
         }
      });

      // handle token and default values here
      tokenText = desktopConnectionResponse["protocol-settings"];
      if (tokenText) {
         util.addItemForJson(desktopConnectionResponse, "token", tokenText);
      }
   }
   return desktopConnectionResponse;
};

/**
 * Create the action to launch Blast desktop.
 *
 * @param host[in] the host for Blast.
 * @param port[in] the port for Blast.
 * @param args[in] the launch arguments for Blast.
 * @return launchUrl as a string for the desktop connection.
 */

GetDesktopConnectionHandler.prototype.createAppBlastLaunchAction = function (host, port, args) {
   /*
    * Construct appblast desktop url.
    * https://view.corp.com/r/r-token-value/?vauth=auth-token-value (BSG)
    * OR
    * https://view.corp.com/d/d-token-value/?vauth=auth-token-value (direct)
    */
   let r;
   if (args.r) {
      r = "/r/" + args.r;
   } else {
      r = "/d/" + getUuid();
   }

   const launchUrl = ["https://", host, ":", port, encodeURI(r), "/?vauth=", encodeURIComponent(args.a)].join("");
   Logger.trace("Blast url generated");
   return launchUrl;
};

/**
 * Parse the redirect properties from broker response
 *
 * @param properties
 * @returns object contains all the redirect properties
 */
GetDesktopConnectionHandler.prototype.parseRedirectProperties = function (properties) {
   if (!properties || !properties.length) {
      return {};
   }

   let i,
      property,
      attrName,
      attrValue,
      res = {};

   for (i = 0; i < properties.length; i++) {
      property = properties[i];
      attrName = property.getAttribute("name");
      attrValue = property.textContent;
      if (attrName) {
         res[attrName] = attrValue;
      }
   }
   return res;
};

/**
 * Decrypt the XML-API protection data.
 */
GetDesktopConnectionHandler.prototype.decryptResult = function () {
   const parsedResult = this.content["parsedResult"];
   const cryptoKeyDerivationService = util.getObject(
      globalArray,
      "cryptoKeyDerivationService"
   ) as CryptoKeyDerivationService;

   if (!cryptoKeyDerivationService) {
      Logger.error("The CryptoKeyDerivationService missing.");
      return;
   }

   if (parsedResult && cryptoKeyDerivationService.isXmlApiDataProtected()) {
      // Since 3.0, but only used by VMware client types
      if (parsedResult["framework-channel-ticket"]) {
         parsedResult["framework-channel-ticket"] = cryptoKeyDerivationService.AESDecryptData(
            parsedResult["framework-channel-ticket"]
         );
      }
      if (parsedResult["password"]) {
         parsedResult["password"] = cryptoKeyDerivationService.AESDecryptData(parsedResult["password"]);
      }
      // Since 3.1, used for protocol specific negotiation, such as PCoIP token
      if (parsedResult["protocol-settings"]) {
         parsedResult["protocol-settings"] = cryptoKeyDerivationService.AESDecryptData(
            parsedResult["protocol-settings"]
         );
      }
      // The field "token" and the field "protocol-settings" are the same value in "parseResult" function.
      if (parsedResult["token"]) {
         parsedResult["token"] = cryptoKeyDerivationService.AESDecryptData(parsedResult["token"]);
      }
      // Since 15.0, settings for protocol and tunnel connection redirection
      if (parsedResult["redirect-settings"]) {
         const redirectSettings = parsedResult["redirect-settings"];
         if (redirectSettings["protocol-redirect-token"]) {
            redirectSettings["protocol-redirect-token"] = cryptoKeyDerivationService.AESDecryptData(
               redirectSettings["protocol-redirect-token"]
            );
         }
         if (redirectSettings["tunnel-redirect-token"]) {
            redirectSettings["tunnel-redirect-token"] = cryptoKeyDerivationService.AESDecryptData(
               redirectSettings["tunnel-redirect-token"]
            );
         }
      }
   }
};
