/**
 * *************************************************************
 * Copyright (C) 2016-2020, 2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview device-message-manager.ts -- DeviceMessageManager
 * Class contains the logic for device related messages, mainly works
 * as a status converter since we need to mock response to avoid pending
 * agent response for too long when agent ask whether has devices.
 * For bug 2629754, queue all events at client side while pending on
 * device permission asking and also encoder init and to enforce messages
 * are processed by order.
 *
 * Class that contains functions and logic for using the rtav sub channel
 * @type {function}
 */

import { Injectable } from "@angular/core";
import { ResourceManager } from "./resourceManager";
import { MessageWaitManager } from "./messageWaitManager";
import { Logger } from "@html-core";

@Injectable()
export class DeviceMessageManager {
   public localMessageController;
   public allowedActions;
   public permissionDialogPending;
   public unprocessedActionQueue;
   public processingAction;
   public enableDeviceActions;
   constructor(
      private resourceManager: ResourceManager,
      private messageWaitManager: MessageWaitManager
   ) {}

   public init = (controller) => {
      this.unprocessedActionQueue = [];
      this.processingAction = null;
      // for UI only
      this.permissionDialogPending = {
         audio: false,
         video: false
      };
      this.allowedActions = ["PMsgStop_A", "PMsgStart_A", "PMsgStop_V", "PMsgStart_V", "PMsgStartStream"];
      // used for permission status check
      this.enableDeviceActions = {
         audio: "PMsgStart_A",
         video: "PMsgStart_V"
      };

      this.localMessageController = controller;
      this.localMessageController.deviceMessageManager = this;
   };

   private isProcessingDeviceEnable = (type) => {
      return this.processingAction && this.processingAction.messageType === this.enableDeviceActions[type];
   };

   private hasEnableActionInQueue = (type) => {
      return this.unprocessedActionQueue.some((action) => {
         return this.enableDeviceActions[type] === action.messageType;
      });
   };

   private hasUnfinishedTask = () => {
      return this.unprocessedActionQueue.length > 0 || this.processingAction;
   };

   private pushUnprocessedAction = (action) => {
      this.unprocessedActionQueue.push(action);
      Logger.debug("unprocessed action queue updated to: " + JSON.stringify(this.unprocessedActionQueue), Logger.RTAV);
   };

   /**
    * Add no cancellation in queue, since client had remove the parallel
    * processing with mocked response.
    */
   private popActionFromQueue = () => {
      if (this.unprocessedActionQueue.length > 0) {
         let nextAction = this.unprocessedActionQueue.splice(0, 1)[0];
         Logger.debug("pop action: " + JSON.stringify(nextAction), Logger.RTAV);
         return nextAction;
      }
      return null;
   };

   private executeNextAction = (self) => {
      this.processingAction = null;
      let nextAction = this.popActionFromQueue();
      if (nextAction) {
         this.processMessage(self, nextAction.messageType, nextAction.callback, nextAction.param);
      }
   };

   private processMessage = (self, messageType, callback, param) => {
      if (!this.localMessageController) {
         Logger.error("uninited controller", Logger.RTAV);
         return;
      }
      this.processingAction = {
         messageType: messageType,
         callback: callback,
         param: param
      };
      Logger.debug("push device message into wait queue: " + messageType, Logger.RTAV);
      this.messageWaitManager.process(messageType, () => {
         switch (messageType) {
            case "PMsgStart_A":
               this.localMessageController.enableAudioIn((success) => {
                  if (success) {
                     Logger.debug("audio device successfully enabled", Logger.RTAV);
                  } else {
                     Logger.debug("audio device fail to be enabled", Logger.RTAV);
                  }
                  callback(success);
                  this.executeNextAction(self);
               }, self);
               break;
            case "PMsgStop_A":
               this.localMessageController.disableAudioIn((success) => {
                  if (success) {
                     Logger.debug("audio device successfully disabled", Logger.RTAV);
                  } else {
                     Logger.debug("audio device fail to be disabled", Logger.RTAV);
                  }
                  callback(success);
                  this.executeNextAction(self);
               });
               break;
            case "PMsgStart_V":
               this.localMessageController.enableVideoIn((success) => {
                  if (success) {
                     Logger.debug("video device successfully enabled", Logger.RTAV);
                  } else {
                     Logger.debug("video device fail to be enabled", Logger.RTAV);
                  }
                  callback(success);
                  this.executeNextAction(self);
               }, self);
               break;
            case "PMsgStop_V":
               this.localMessageController.disableVideoIn((success) => {
                  if (success) {
                     Logger.debug("video device successfully disabled", Logger.RTAV);
                  } else {
                     Logger.debug("video device fail to be disabled", Logger.RTAV);
                  }
                  callback(success);
                  this.executeNextAction(self);
               });
               break;
            case "PMsgStartStream":
               this.localMessageController.startMediaIn(param, callback);
               setTimeout(() => {
                  this.executeNextAction(self);
               }, 1000);
               break;
            default:
               Logger.error("unexpected message type in messageWaitManager.process", Logger.RTAV);
               this.executeNextAction(self);
               break;
         }
      });
   };

   private processAction = (self, messageType, callback, param) => {
      if (this.hasUnfinishedTask()) {
         Logger.warning("has unprocessed message push into the queue for later execution", Logger.RTAV);
         this.pushUnprocessedAction({
            messageType: messageType,
            callback: callback,
            param: param
         });
         Logger.debug(
            "message added: " + messageType + ", processing: " + this.processingAction.messageType,
            Logger.RTAV
         );
      } else {
         this.processMessage(self, messageType, callback, param);
      }
   };

   /**
    * should be called when get device related message
    * @param {string} messageType Message name
    * @param {function} callback Optimal The callback should be called with
    *     bool indicate whether success
    */
   public onMessage = (messageType, callback, param?) => {
      if (typeof callback !== "function") {
         Logger.error("inner error: there is no callback for " + messageType, Logger.RTAV);
         return;
      }
      if (!this.allowedActions.includes(messageType)) {
         Logger.error("DeviceMessageManager shouldn't be used to process message " + messageType, Logger.RTAV);
         callback(false);
         return;
      }
      this.localMessageController.processWhenHaveResources((canProcess) => {
         if (canProcess) {
            this.processAction(this, messageType, callback, param);
         } else {
            callback(false);
            Logger.debug("do nothing for " + messageType + " since session is not allow to be processed", Logger.RTAV);
         }
      });
   };

   /**
    * function should be called when permission is handled
    * @param {string} type Of audio or video indicate which permission is handled
    */
   public onPermissionHandled = (type) => {
      Logger.trace("permission dialog is closed", Logger.RTAV);
      if (!this.permissionDialogPending[type]) {
         Logger.error("the inner logic is wrong, the permission dialog seems get disappear", Logger.RTAV);
         return;
      }
      this.permissionDialogPending[type] = false;
   };

   /**
    * function should be called when permission is asked
    * @param {string} type Of audio or video indicate which permission is asked
    */
   public onPermissionAsked = (type) => {
      if (this.permissionDialogPending[type]) {
         Logger.error("the inner logic is wrong, the permission dialog seems get duplicated", Logger.RTAV);
         return;
      }
      this.permissionDialogPending[type] = true;
      this.resourceManager.emitDeviceStatusChanged();
      Logger.trace("permission dialog pop out", Logger.RTAV);
   };

   /**
    * function to known whether permission is being asked for specified type
    * @param {string} type Of audio or video indicate which permission is asked
    * @return {boolean} This returns whether permission is being asked for
    *     specified type
    */
   public isPermissionPending = (type?) => {
      if (this.isProcessingDeviceEnable(type) || this.hasEnableActionInQueue(type)) {
         return true;
      }
      if (type !== undefined) {
         return this.permissionDialogPending[type];
      }
      return this.permissionDialogPending["audio"] || this.permissionDialogPending["video"];
   };
}
