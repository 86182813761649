/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { IStorage } from "./storage.interface";

export class ChromeStorage implements IStorage {
   caches: Map<string, any>;

   constructor() {
      this.caches = new Map<string, any>();

      chrome.storage.local.get(null, (obj) => {
         if (!chrome.runtime.lastError) {
            for (const [key, value] of Object.entries(obj)) {
               this.caches.set(key, value);
            }
         }
      });

      //@ts-ignore
      chrome.storage.local.onChanged.addListener((changes) => {
         for (const key in changes) {
            this.caches.set(key, changes[key].newValue);
         }
      });

      //@ts-ignore
      chrome.storage.local.onChanged.removeListener((changes) => {
         for (const key in changes) {
            this.caches.delete(key);
         }
      });
   }

   public getItem = (key: string): any => {
      if (this.caches.has(key)) {
         return this.caches.get(key);
      } else {
         return null;
      }
   };

   public setItem(key: string, data: any): void {
      this.caches.set(key, data);
      const obj = {};
      obj[key] = data;
      chrome.storage.local.set(obj, null);
   }

   public removeItem(key: string): void {
      if (this.caches.has(key)) {
         this.caches.delete(key);
      }
      chrome.storage.local.remove(key, null);
   }
}
