<div id="sidebar" class="sidebar-panel unselectable" [hidden]="!showPanel || isWebviewMode" [ngClass]="{'titan-sidebar': isTitanClient}" [attr.role]="'sidebar' | i18nv2">
   <div class="head-panel">
      <sidebar-header (panelEvent)="togglePanel($event)"></sidebar-header>
      <div class="search-panel" [attr.role]="'Search' | i18nv2">
         <div class="search-icon">
            <img class="ui-search-icon" [src]="this.imageAsset.searchIcon">
         </div>
         <label for="search-input">{{'SEARCH_PH_M' | i18nv2}}</label>
         <input id="search-input" class="search-input" [(ngModel)]="queryText.name" type="search" placeholder="{{'SEARCH_PH_M' | i18nv2}}"/>
         <span *ngIf="queryText.name !== ''" (click)="clearSearch()" (keydown)="searchClearKeydown($event)" class="search-clear-icon" tabindex="0"  ></span>
      </div>
      <div class="separator"></div>
   </div>
   <div class="apps-panel content-panel-container">
      <div class="running-title-panel">
         <h4 class="running-title">{{'RUNNING_T' | i18nv2}}</h4>
         <sidebar-perf-tracker></sidebar-perf-tracker>
      </div>
      <div [hidden]="!(!runningItems || runningItems.length === 0)" class="no-items-label">{{'NO_RUNNING_ITEMS_T' | i18nv2}}</div>
      <div class="running-content-panel">
         <div *ngFor="let runningItem of (runningItems | runningItemsFilter : queryText.name)">
            <ul class="running-app">
               <ul class="icon-and-name-container" (click)="activateItem(runningItem)" (keydown)="activateItemKeydown($event, runningItem)" title="{{'BRING_ITEM_TO_FRONT_T' | i18nv2 : runningItem.name}}" tabindex="0" (focus)="showRunningItemTooltip()" (contextmenu)="contextMenuClicked(runningItem, $event)">
                  <li aria-hidden="true" [ngClass]="{'running-app-border': !appHasFocus(runningItem), 'focused-app-border': appHasFocus(runningItem)}">
                     <img alt="{{runningItem.name}} icon" [ngClass]="{'running-app-icon': !appHasFocus(runningItem), 'focused-app-icon': appHasFocus(runningItem), 'nonready' : !util.isConnected(runningItem.state)}" [src]="runningItem.iconSrc" [hidden]="util.isDesktop(runningItem.type)"/>
                     <img alt="{{runningItem.name}} icon" [ngClass]="{'running-app-icon': !appHasFocus(runningItem), 'focused-app-icon': appHasFocus(runningItem), 'nonready' : !util.isConnected(runningItem.state)}"  [src]="getDesktopIcon(runningItem)" [hidden]="!(util.isDesktop(runningItem.type) && !runningItem.isShadow)"/>
                     <img alt="{{runningItem.name}} icon" [ngClass]="{'running-app-icon': !appHasFocus(runningItem), 'focused-app-icon': appHasFocus(runningItem), 'nonready' : !util.isConnected(runningItem.state)}"  [src]="this.imageAsset.deShadowIcon" [hidden]="!(util.isDesktop(runningItem.type) && runningItem.isShadow)"/>
                     <div class="running-item-overlay loading" [hidden]="!util.isConnecting(runningItem.state)"></div>
                     <div class="running-item-overlay attention" [hidden]="!util.isDisconnected(runningItem.state)"></div>
                  </li>
                  <li aria-hidden="true" [ngClass]="{'focused-app-name': appHasFocus(runningItem), 'running-app-name': true}">
                     {{runningItem.name}}
                  </li>
               </ul>
               <li class="running-app-window-count-container" [hidden]="!(!util.isDesktop(runningItem.type) && runningItem.instances.length > 0 && (!queryText || queryText.name ===''))">
                  <div class="running-app-window-count" (click)="toggleIsCollapsed(runningItem)" (keydown)="toggleIsCollapsedKeydown($event, runningItem)">
                     <div *ngIf="runningItem.isCollapsed && runningItem.instances.length > 1" title="{{'CLICK_EXPAND_T' | i18nv2}}">{{runningItem.instances.length}}</div>
                     <div *ngIf="runningItem.isCollapsed && runningItem.instances.length === 1" class="icon-up-arrow-image vertical-flip" title="{{'CLICK_EXPAND_T' | i18nv2}}"></div>
                     <div *ngIf="!runningItem.isCollapsed" class="icon-up-arrow-image" title="{{'CLICK_COLLAPSE_T' | i18nv2}}"></div>
                  </div>
               </li>
               <li [hidden]="!util.isDesktop(runningItem.type)" class="icon-button icon-context-menu-image running-app-context-menu" (click)="contextMenuClicked(runningItem, $event)" (keydown)="onKeypress(runningItem, $event, 'contextMenuClicked')" [attr.aria-label]="'OPEN_MENU_BTN_NAME' | i18nv2" [attr.role]="'BUTTON' | i18nv2" aria-expanded="false" title="{{'OPEN_MENU_T' | i18nv2}}" id="{{runningItem.name}}-contextMenu" tabindex="0" (focus)="showContextMenuTooltip()">
                  <img class="ui-contextMenu-icon-two" [src]="this.imageAsset.iclSidebarContext"  aria-hidden="true">
               </li>
               <share-contextmenu *ngIf="runningItem.showContextMenu" class="content-menu"
                     [contextmenuName]="contextmenuName" [item]="runningItem"
                     [paneClass]="paneClass" >
               </share-contextmenu>
               <li [hidden]="!util.isApp(runningItem.type)" class="icon-button icon-close-app-image running-app-context-menu" (click)="closeRunningApp(runningItem)" (keydown)="onKeypress(runningItem, $event, 'closeRunningApp')" title="{{'CLOSE' | i18nv2}}" tabindex="0" (focus)="showContextMenuTooltip()"></li>
               <li class="running-session-rtav-containner">
                  <div id="rtav-using-microphone-icon" [hidden]="!isUsingMicrophone(runningItem)" class="running-session-using-rtav microphone" [ngClass]="{'blocked': isAskingMicrophone(runningItem.wmksKey)}" title="{{(isAskingMicrophone(runningItem.wmksKey) === true? 'ASKING_MICROPHONE' : 'USING_MICROPHONE') | i18nv2}}" tabindex="0"></div>
                  <div id="rtav-using-webcam-icon" [hidden]="!isUsingWebcam(runningItem)" class="running-session-using-rtav webcam" [ngClass]="{'blocked': isAskingWebcam(runningItem.wmksKey)}" title="{{(isAskingWebcam(runningItem.wmksKey) === true? 'ASKING_WEBCAM' : 'USING_WEBCAM') | i18nv2}}" tabindex="0"></div>
               </li>
            </ul>
            <ul class="running-window-list" [hidden]="!(!runningItem.isCollapsed || (queryText && queryText.name !== ''))">
               <li *ngFor="let instance of (runningItem.queryInstances | orderBy:'name')" class="running-window">
                  <ul>
                     <ul class="icon-and-name-container" (click)="activateWindow(instance)" (keydown)="activateWindowKeydown($event, instance)" title="{{'BRING_ITEM_TO_FRONT_T' | i18nv2 : instance.name}}" tabindex="0">
                        <li aria-hidden="true" [ngClass]="{'running-window-border': !unityWindowHasFocused(runningItem, instance), 'focused-window-border': unityWindowHasFocused(runningItem, instance)}">
                           <img alt="{{instance.name}} icon" [ngClass]="{'running-window-icon': !unityWindowHasFocused(runningItem, instance), 'focused-window-icon': unityWindowHasFocused(runningItem, instance), 'nonready': !util.isConnected(instance.state)}" [src]="instance.iconSrc"/>
                           <div class="running-window-overlay loading" [hidden]="!util.isConnecting(runningItem.state)"></div>
                           <div class="running-window-overlay attention" [hidden]="!util.isDisconnected(runningItem.state)"></div>
                        </li>
                        <li aria-hidden="true" [ngClass]="{'focused-window-name': unityWindowHasFocused(runningItem, instance), 'running-window-name': true}">{{instance.name}}</li>
                     </ul>
                     <li class="icon-button icon-close-image icon-close-running-window" (click)="closeRunningInstance(instance)" (keydown)="closeRunningInstanceKeydown($event, instance)" title="{{'CLOSE_WINDOW_T' | i18nv2}}" [hidden]="util.isLoadingItem(runningItem.type)" tabindex="0"></li>
                  </ul>
               </li>
            </ul>
         </div>
      </div>
      <sidebar-entitled-items [query]='queryText.name' (panelEvent)="togglePanel($event)" (preferEvent)="preferDirty($event)"></sidebar-entitled-items>
   </div>
</div>
<vertical-slider id="sidebar-toggler"
                 class="panel-toggle-tab pointer-cursor sidebar-tab-image"
                 name="sidebar-toggler"
                 (onClick)="togglePanel()"
                 (keydown)="sidebarToggleKeydown($event)"
                 (onMoved)="sliderMoveStart()"
                 element-drag-styles="pointer-cursor resize-cursor"
                 body-drag-styles="resize-cursor"
                 [attr.name]="'sidebar_toggle' | i18nv2" [attr.aria-label]="'sidebar_toggle' | i18nv2" tabindex="0" [attr.aria-pressed]="showPanel" role="button"
                 [hidden]="isWebviewMode"
></vertical-slider>

<panel-notification class="imgNotification"></panel-notification>
