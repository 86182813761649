/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Helper to handle large integer since JS can only represent 2^53
 * accurately, so we need to use struct to store UInt64.
 *
 * Can be extended by nested call to support any number using ArrayBuffer,
 * but don't implement now.
 *
 * Currently only implement the little endian version since it's used in Agent side.
 * don't support div since we don't have that need so far.
 *
 * Refactor is possible to handle over the calculation to BigInt or
 * other libs, but it's not necessary.
 */
export class LargeInteger {
   /**
    * Don't use the const since current loader would pollute the root namespace
    */
   private static safeMax: number = Number.MAX_SAFE_INTEGER || 9007199254740991; //ES6 standard
   private static invalidNumberError: string = "This number can't be represented by Uint64 accurately";
   private static cannotRepresentedAsIntegerInJS: string =
      "This number can't be represented as a JS integer number accurately";
   private static UnsupportedEndian: string = "Don't support big endian for now";
   private static pow16: number = Math.pow(2, 16);
   private static pow32: number = Math.pow(2, 32);
   private static pow48: number = Math.pow(2, 48);
   private static pow64: number = Math.pow(2, 64);

   private powAry: number;
   private powMax: number;
   public isBigEndian: boolean;
   public lowPart: number;
   public highPart: number;

   // allow no param where data are undefined
   constructor(ary?: number, max?: number) {
      this.powAry = ary || LargeInteger.pow32;
      this.powMax = max || LargeInteger.pow64;
   }

   public setByParts = (firstPart: number, secondPart: number, isBigEndian: boolean): LargeInteger => {
      if (isBigEndian) {
         throw new Error(LargeInteger.UnsupportedEndian);
      }
      if (firstPart < 0 || secondPart < 0) {
         throw new Error(LargeInteger.invalidNumberError);
      }
      if (Math.round(firstPart) !== firstPart || Math.round(secondPart) !== secondPart) {
         throw new Error(LargeInteger.invalidNumberError);
      }
      if (firstPart >= this.powAry || secondPart >= this.powAry) {
         throw new Error(LargeInteger.invalidNumberError);
      }
      this.isBigEndian = false;
      this.lowPart = firstPart;
      this.highPart = secondPart;
      return this;
   };

   private copy = (number: LargeInteger, isBigEndian?: boolean): void => {
      if (isBigEndian || number.isBigEndian) {
         throw new Error(LargeInteger.UnsupportedEndian);
      }
      this.isBigEndian = number.isBigEndian;
      this.highPart = number.highPart;
      this.lowPart = number.lowPart;
   };

   private from = (number: number, isBigEndian?: boolean): void => {
      if (isBigEndian) {
         throw new Error(LargeInteger.UnsupportedEndian);
      }
      if (number < 0 || number >= LargeInteger.safeMax || Math.round(number) !== number) {
         throw new Error(LargeInteger.invalidNumberError);
      }
      this.isBigEndian = false;
      this.lowPart = number % this.powAry;
      this.highPart = Math.floor(number / this.powAry);
   };

   public set = (number: LargeInteger | number, isBigEndian?: boolean): LargeInteger => {
      if (number instanceof LargeInteger) {
         this.copy(number, isBigEndian);
      } else {
         this.from(number, isBigEndian);
      }
      return this;
   };

   public add = (number: LargeInteger | number): LargeInteger => {
      number = new LargeInteger().set(number);
      this.lowPart += number.lowPart;
      this.highPart += number.highPart;
      if (this.lowPart >= this.powAry) {
         const carry = Math.floor(this.lowPart / this.powAry); //should always be 1
         this.lowPart = this.lowPart % this.powAry;
         this.highPart += carry;
      }
      if (this.highPart >= this.powAry) {
         throw new Error(LargeInteger.invalidNumberError);
      }
      return this;
   };

   // throw error when result is negative
   public minus = (number: LargeInteger | number): LargeInteger => {
      number = new LargeInteger().set(number);
      this.lowPart -= number.lowPart;
      this.highPart -= number.highPart;
      if (this.lowPart < 0) {
         this.highPart--;
         this.lowPart += this.powAry;
      }
      if (this.highPart < 0) {
         throw new Error(LargeInteger.invalidNumberError);
      }
      return this;
   };

   public handleCarry = (array: Array<number>, offset: number, carry: number, max: number): void => {
      if (carry === 0) {
         return;
      }
      if (offset >= array.length) {
         throw new Error(LargeInteger.invalidNumberError);
      }
      const result = array[offset] + carry;
      array[offset] = result % max;
      this.handleCarry(array, offset + 1, Math.floor(result / max), max);
   };

   /**
    * Only support Uint64 for now
    */
   public mul = (number: LargeInteger | number): LargeInteger => {
      number = new LargeInteger().set(number);
      const uint16A: Array<number> = this.getUint16();
      const uint16B: Array<number> = number.getUint16();
      const result: Array<number> = [0, 0, 0, 0];
      for (let i: number = 0; i < uint16A.length; i++) {
         if (!uint16A[i]) {
            continue;
         }
         for (let j: number = 0; j < uint16B.length; j++) {
            if (!uint16B[j]) {
               continue;
            }
            if (Math.pow(2, (i + j) * 16) > this.powMax) {
               throw new Error(LargeInteger.invalidNumberError);
            }
            const mulResult = uint16A[i] * uint16B[j];
            const adding = mulResult % LargeInteger.pow16;
            let carry = Math.floor(mulResult / LargeInteger.pow16);

            result[i + j] += adding;
            if (result[i + j] >= LargeInteger.pow16) {
               result[i + j] = result[i + j] - LargeInteger.pow16;
               carry++;
            }
            this.handleCarry(result, i + j + 1, carry, LargeInteger.pow16);
         }
      }
      this.lowPart = result[0] + result[1] * LargeInteger.pow16;
      this.highPart = result[2] + result[3] * LargeInteger.pow16;
      return this;
   };

   /**
    * Only implement little endian for now.
    * @return {Array} little endian array of Uint16
    */
   public getUint16 = (): Array<number> => {
      return [
         this.lowPart % LargeInteger.pow16,
         Math.floor(this.lowPart / LargeInteger.pow16) % LargeInteger.pow16,
         this.highPart % LargeInteger.pow16,
         Math.floor(this.highPart / LargeInteger.pow16) % LargeInteger.pow16
      ];
   };

   // API for later supporting endian.
   public getLowPart = (): number => {
      return this.lowPart;
   };

   // API for later supporting endian.
   public getHighPart = (): number => {
      return this.highPart;
   };

   // always return a accurate number, if not, the inaccurate error will be thrown.
   public getValue = (): number => {
      const result = this.highPart * this.powAry + this.lowPart;
      if (result > LargeInteger.safeMax) {
         throw new Error(LargeInteger.cannotRepresentedAsIntegerInJS);
      }
      return result;
   };
}
