/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import { FeatureConfigs } from "../../common/model/feature-configs";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class ClipboardKillSwitchService {
   private killSwitchOnForPanelFreeClipboard: boolean;
   public enableClipboardNotification: boolean;
   constructor(
      private featureConfigs: FeatureConfigs,
      private viewClientModel: ViewClientModel
   ) {
      this.enableClipboardNotification = true;
      this.killSwitchOnForPanelFreeClipboard = true;
   }

   private setKillSwitchForPanelFreeClipboard = (switchOn: boolean) => {
      this.killSwitchOnForPanelFreeClipboard = switchOn;
   };

   public getIsWebPanelFreeClipboard = (): boolean => {
      let isWebPanelFreeClipboard = false;
      if (!clientUtil.isChromeClient()) {
         this.featureConfigs.registerListener("KillSwitch-PanelFreeClipboard", this.setKillSwitchForPanelFreeClipboard);

         const isBrowserSupportPanelFree =
            !this.viewClientModel.mIsWebKit &&
            !this.viewClientModel.mIsTouchDevice &&
            !this.viewClientModel.mIsIE &&
            !this.viewClientModel.mIsSamsungTV;

         if (this.killSwitchOnForPanelFreeClipboard && isBrowserSupportPanelFree) {
            isWebPanelFreeClipboard = true;
         }
      }
      return isWebPanelFreeClipboard;
   };

   private getIsClipboardNotification = (): boolean => {
      return this.enableClipboardNotification;
   };

   public setIsClipboardNotification = (value: boolean) => {
      this.enableClipboardNotification = value;
   };
}
