/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { EventEmitter, Injector } from "@angular/core";
import { EventBusService, Logger } from "@html-core";
import { fromEvent, Observable, Subscription, timer } from "rxjs";
import { debounceTime, delay, retryWhen, startWith, switchMap, tap } from "rxjs/operators";
import { NetworkMonitorOptions } from "./network-monitor.option";
import { ConnectionState } from "./connection-state";
import { defaults } from "lodash";
import { NetWorkMonitorType } from "./network-monitor-type";
import { PathService } from "../../../../titan/services/titan-path.interface";

/**
 * Instance of this interface is used to report current connection status.
 */

export class NetworkMonitorService {
   private static DEFAULT_OPTIONS: NetworkMonitorOptions = {
      enableHeartbeat: true,
      heartbeatUrl: "",
      heartbeatInterval: 30000,
      heartbeatRetryInterval: 1000,
      requestMethod: "head",
      networkMonitorType: NetWorkMonitorType.GLOBAL_MONITOR
   };

   private stateChangeEventEmitter = new EventEmitter<ConnectionState>();
   private currentState: ConnectionState = {
      hasInternetAccess: true,
      hasNetworkConnection: window.navigator.onLine,
      networkMonitorType: NetWorkMonitorType.GLOBAL_MONITOR
   };
   private offlineSubscription: Subscription;
   private onlineSubscription: Subscription;
   private httpSubscription: Subscription;
   private serviceOptions: NetworkMonitorOptions;

   private http: HttpClient;
   private eventBusService: EventBusService;
   private pathService: PathService;

   constructor(injector: Injector) {
      this.serviceOptions = defaults({}, NetworkMonitorService.DEFAULT_OPTIONS);
      this.http = injector.get(HttpClient);
      this.eventBusService = injector.get(EventBusService);
      this.checkNetworkState();
      this.pathService = injector.get(PathService);
      this.serviceOptions.heartbeatUrl = this.pathService.heartBeatURI;
   }

   public start = () => {
      this.checkInternetState();
   };

   public stop = () => {
      try {
         this.offlineSubscription.unsubscribe();
         this.onlineSubscription.unsubscribe();
         this.httpSubscription.unsubscribe();
      } catch (e) {
         Logger.error(e);
      }
   };

   public getNetworkMonitorType = (): NetWorkMonitorType => {
      return this.currentState.networkMonitorType;
   };
   /**
    * Monitor Network & Internet connection status by subscribing to this observer.
    * If you set "reportCurrentState" to "false" then function will not report current status
    * of the connections when initially subscribed.
    * @param reportCurrentState Report current state when initial subscription. Default is "true"
    */
   public monitor(reportCurrentState = true): Observable<ConnectionState> {
      return reportCurrentState
         ? this.stateChangeEventEmitter.pipe(debounceTime(800), startWith(this.currentState))
         : this.stateChangeEventEmitter.pipe(debounceTime(800));
   }

   private checkNetworkState() {
      this.onlineSubscription = fromEvent(window, "online").subscribe(() => {
         this.currentState.hasNetworkConnection = true;
         this.checkInternetState();
         this.emitEvent();
      });

      this.offlineSubscription = fromEvent(window, "offline").subscribe(() => {
         this.currentState.hasNetworkConnection = false;
         this.checkInternetState();
         this.emitEvent();
      });
   }

   public updateOptions(options: Partial<NetworkMonitorOptions>) {
      this.currentState.networkMonitorType = options.networkMonitorType;
      this.serviceOptions = defaults({}, options, this.serviceOptions);
      this.checkInternetState();
   }

   private checkInternetState() {
      if (this.httpSubscription) {
         this.httpSubscription.unsubscribe();
      }

      if (this.serviceOptions.enableHeartbeat) {
         this.httpSubscription = timer(0, this.serviceOptions.heartbeatInterval)
            .pipe(
               switchMap(() =>
                  this.http[this.serviceOptions.requestMethod](this.serviceOptions.heartbeatUrl, {
                     responseType: "text"
                  })
               ),
               retryWhen((errors) =>
                  errors.pipe(
                     // log error message
                     tap((val) => {
                        console.error("Http error:", val);
                        this.currentState.hasInternetAccess = false;
                        this.emitEvent();
                     }),
                     // restart after 5 seconds
                     delay(this.serviceOptions.heartbeatRetryInterval)
                  )
               )
            )
            .subscribe(
               (result: HttpResponse<any>) => {
                  this.currentState.hasInternetAccess = true;
                  this.currentState.heatBeatStatus = result.status;
                  this.emitEvent();
               },
               (error: HttpErrorResponse) => {
                  this.currentState.hasInternetAccess = false;
                  this.currentState.heatBeatStatus = error.status;
               }
            );
      } else {
         this.currentState.hasInternetAccess = false;
         this.emitEvent();
      }
   }
   private emitEvent() {
      this.stateChangeEventEmitter.emit(this.currentState);
   }
}
