/**
 * ******************************************************
 * Copyright (C) 2015-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { clientUtil } from "@html-core";
import Logger from "../../../core/libs/logger";
import { EventBusService } from "../../../core/services/event";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { PreferredRTAVDeviceService } from "../../common/service/preferred-RTAV-device.service";
import { DeviceEnumeratorService, GuidGenerator } from "../rtav/v2/device-enumerator.service";
import { PreferredDeviceType, PreferredDeviceValue, getPreferredDeviceKey } from "../rtav/v2/device-manager.model";
import { AudioPlayControl } from "./audio-play.control";
import { LocalStorageService } from "@html-core";
import { FreeQueue } from "./free-queue.js";

/*
 * Multiple audio playback-related definitions
 */
export const MULTI_AUDIO_OUT_MAX_DEVS: number = 8;
export const MULTI_AUDIO_OUT_MAX_DEV_NAME_LEN: number = 128;
export const MULTI_AUDIO_MAX_UNIQUE_ID_LEN: number = 128;

export enum AudioOutOption {
   Default = 0, // default speaker device
   All = 1, // all speaker devices
   Custom = 2 // selected speaker device
}

export interface AudioOutputDeviceUniqueId {
   deviceId: string;
   deviceName: string;
   uniqueId: string; //uniqueId should be a GUID with format {XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX}
   flags: number; //The flags field should be either 0 or 0x7 for chrome client.
}

export interface DirectSoundCallbackContext {
   audioDevices: Array<AudioOutputDeviceUniqueId>;
   deviceCount: number;
}

/**
 * audio.service.ts --
 *
 * Factory service for playing audio.
 *
 */
@Injectable({
   providedIn: "root"
})
export class AudioService {
   private audioInstanceNumber = 0;
   private static readonly MAX_WAIT_FOR_CLICK = 10 * 1000; //ms
   private audioResumeDialogId = null;
   private defaultAudioOutDevLabel = null;
   public audioEnabled = false;
   public audioUseOpus = false;
   public audioUseAac = false;
   public audioUseWebAudioAPI = false;
   public globalAudioContext: AudioContext = null;
   public customAudioOutDevice: AudioOutputDeviceUniqueId = {
      deviceId: "",
      deviceName: "",
      uniqueId: "",
      flags: 0
   };
   public audioOutOption: number;
   private _audioOutDevicesUniqueIdInfo: DirectSoundCallbackContext = {
      audioDevices: [],
      deviceCount: 0
   };
   public audioEle;
   public mediaStreamAudioDestinationNode;
   public isRefreshPage = false;
   public audioResumed = false;

   // ensure the audioDevices doesn't contain falsy value, and the deviceCount equals to the length of the array
   public get audioOutDevicesUniqueIdInfo() {
      if (!Array.isArray(this._audioOutDevicesUniqueIdInfo.audioDevices)) {
         this._audioOutDevicesUniqueIdInfo.audioDevices = [];
         this._audioOutDevicesUniqueIdInfo.deviceCount = 0;
      } else {
         // remove invalid values
         this._audioOutDevicesUniqueIdInfo.audioDevices = this._audioOutDevicesUniqueIdInfo.audioDevices.filter(
            (device) => device
         );
         this._audioOutDevicesUniqueIdInfo.deviceCount = this._audioOutDevicesUniqueIdInfo.audioDevices.length;
      }
      return structuredClone(this._audioOutDevicesUniqueIdInfo);
   }

   constructor(
      private modalDialogService: ModalDialogService,
      private preferredRTAVDeviceService: PreferredRTAVDeviceService,
      private eventBusService: EventBusService,
      private deviceEnumeratorService: DeviceEnumeratorService
   ) {
      this.initDevicesByPreferred();
      if (!clientUtil.isChromeClient() && !WMKS.BROWSER.isSafari() && !WMKS.BROWSER.isFirefox()) {
         this.eventBusService.listen("reFreshStatus").subscribe((msg) => {
            if (msg.data === true) {
               this.isRefreshPage = true;
            }
         });
      }
      this.init();
   }

   public async initDevicesByPreferred() {
      const preferredLabel = await this.preferredRTAVDeviceService.readKey(
         getPreferredDeviceKey(PreferredDeviceType.audioOut)
      );
      if (preferredLabel === PreferredDeviceValue.default || preferredLabel === "default") {
         this.initAudioOutUniqueIdInfo();
      } else if (preferredLabel === PreferredDeviceValue.all || preferredLabel === undefined) {
         this.getAudioOutUniqueIdInfo();
      } else {
         this.getSpecificAudioOutUniqueIdInfo(preferredLabel);
      }
   }

   public getSpecificAudioOutUniqueIdInfo = (label) => {
      const guidGenerator = new GuidGenerator();
      this._audioOutDevicesUniqueIdInfo.audioDevices = new Array(1);
      this._audioOutDevicesUniqueIdInfo.deviceCount = 1;
      const devices = this.deviceEnumeratorService.getAudioOutInfoList();
      devices.forEach((device) => {
         const [guid] = guidGenerator.genGuid(device.label, "a", 0);
         if (device.deviceId === "default") {
            this.defaultAudioOutDevLabel = device.label;
            if (this.defaultAudioOutDevLabel === label) {
               this._audioOutDevicesUniqueIdInfo.audioDevices[0] = {
                  deviceId: device.deviceId,
                  deviceName: device.label,
                  uniqueId: `{${guid}}`,
                  flags: 7
               };
            }
            return;
         }
         /**
          * device info example:
          * deviceId: "0a613b391a6fff669a914f6dc69f24bc8d06358186aaf1d8292bb5d440347c10"
          * groupId: "31843cb0c09a7f68a1047dd3be0a4593cd3f837af36e17e4726f1e15d5e07d43"
          * kind: "audiooutput"
          * label: "Default - Internal Speakers (Built-in)"
          */
         if (device.label === label) {
            this._audioOutDevicesUniqueIdInfo.audioDevices[0] = {
               deviceId: device.deviceId,
               deviceName: device.label,
               uniqueId: `{${guid}}`,
               flags: 7
            };
         }
      });
   };

   public getAudioOutUniqueIdInfo = () => {
      this._audioOutDevicesUniqueIdInfo.audioDevices = [];
      const defaultAudioOutDevice = this.deviceEnumeratorService.getDefaultAudioOutInfo();
      if (defaultAudioOutDevice) {
         this.defaultAudioOutDevLabel = defaultAudioOutDevice.label;
      }
      const audioOutDevicesList = this.deviceEnumeratorService.getAudioOutInfoList();
      const guidGenerator = new GuidGenerator();
      audioOutDevicesList.forEach((device) => {
         const [guid] = guidGenerator.genGuid(device.label, "a", 0);
         if (defaultAudioOutDevice && device.label === defaultAudioOutDevice.label) {
            this._audioOutDevicesUniqueIdInfo.audioDevices.push({
               deviceId: device.deviceId,
               deviceName: device.label,
               uniqueId: `{${guid}}`,
               flags: 7
            });
         } else {
            this._audioOutDevicesUniqueIdInfo.audioDevices.push({
               deviceId: device.deviceId,
               deviceName: device.label,
               uniqueId: `{${guid}}`,
               flags: 0
            });
         }
      });
      this._audioOutDevicesUniqueIdInfo.deviceCount = this._audioOutDevicesUniqueIdInfo.audioDevices.length;
   };

   public initAudioOutUniqueIdInfo = () => {
      this._audioOutDevicesUniqueIdInfo.audioDevices = [];
      this._audioOutDevicesUniqueIdInfo.deviceCount = 0;
   };

   public resumeAudio = () => {
      if (this.globalAudioContext && this.globalAudioContext.state === "suspended") {
         this.globalAudioContext.resume();
      }
   };

   public getAudioServiceInstance = (logId: string, sessionKey: string) => {
      this.audioInstanceNumber += 1;
      return new AudioFactory(this.globalAudioContext, this, logId, sessionKey);
   };
   /**
    * init
    *
    * Test browser API compatibility and set proper global variables.
    *
    */
   private init = () => {
      let test;
      try {
         test = new Audio();
         //@ts-ignore
         window.URL = window.URL || window.webkitURL;
         /**
          * All of available IE/Edge version can't work well, see bug 2078667
          * Mac+firefox also has issue, see bug 2471450.
          */
         this.audioUseOpus = test.canPlayType('audio/ogg; codecs="opus"') === "probably" && !WMKS.BROWSER.isIE();
         this.audioUseAac = test.canPlayType('audio/mp4; codecs="mp4a.40.2"') === "probably";
         this.audioEnabled = true;
         Logger.info("HTML5 Audio is available, Opus = " + this.audioUseOpus + " AAC: " + this.audioUseAac);
      } catch (e2) {
         Logger.info("HTML5 Audio is unavailable.");
      }

      try {
         /**
          * The Web Audio API will be included in the Chrome autoplay policy with M70 (October 2018).
          * Editor’s Draft, 31 August 2018
          * https://webaudio.github.io/web-audio-api/#dfn-allowed-to-start
          * M70:
          * A first canary around July 20, 2018,
          * first beta release around September 13, 2018
          * and a stable release around October 16, 2018
          */
         window.AudioContext =
            //@ts-ignore
            window.AudioContext || window.webkitAudioContext;
         this.globalAudioContext = new AudioContext({
            latencyHint: "interactive",
            sampleRate: 48000
         });
         this.globalAudioContext.audioWorklet.addModule("./audio-output-worklet." + __BUILD_NUMBER__ + ".worker.js");

         /*
          * This is necessary due to:
          * https://developer.mozilla.org/en-US/docs/Web_Audio_API/Porting_webkitAudioContext_code_to_standards_based_AudioContext
          */
         if (typeof window.AudioContext.prototype.createGain === "undefined") {
            window.AudioContext.prototype.createGain =
               //@ts-ignore
               window.AudioContext.prototype.createGainNode;
         }

         this.audioUseWebAudioAPI = true;
         Logger.info("Web Audio API support is available.");
      } catch (e) {
         this.globalAudioContext = null;
         Logger.info("Web Audio API support is unavailable.");
      }
   };

   /**
    * when page reloaded, user need to click to resume audio, if didn't
    * click for more than 10s, there will pop up a resume dialog, click
    * ok to resume audio.
    */
   public onPageReloaded = () => {
      if (this.globalAudioContext && this.globalAudioContext.state === "suspended") {
         let errorDialogTimer, showErrorDialog, clickBeforeShowingDialog;

         showErrorDialog = () => {
            if (this.audioInstanceNumber === 0) {
               errorDialogTimer = setTimeout(showErrorDialog, AudioService.MAX_WAIT_FOR_CLICK);
               Logger.debug("no active session is running, reset audio resumption timer", Logger.AUDIO);
               return;
            }
            errorDialogTimer = null;
            document.body.removeEventListener("click", clickBeforeShowingDialog);
            if (this.globalAudioContext.state !== "suspended") {
               Logger.debug(
                  "global audio context state is already change to not suspended and thus return",
                  Logger.AUDIO
               );
               return;
            }
            setTimeout(() => {
               if (!this.modalDialogService.isDialogOpen(this.audioResumeDialogId)) {
                  this.audioResumeDialogId = this.modalDialogService.showError({
                     data: {
                        titleKey: "WARNING",
                        contentKey: "CHROME_AUDIO_RESUME"
                     },
                     callbacks: {
                        confirm: () => {
                           this.globalAudioContext.resume().then(() => {
                              Logger.debug("audoservice audioResumed ", Logger.AUDIO);
                              this.audioResumed = true;
                           });
                        }
                     }
                  });
               } else {
                  Logger.debug("There already have audio resume dialog", Logger.AUDIO);
               }
            });
         };

         clickBeforeShowingDialog = () => {
            Logger.debug("User have trigger click event before showing audio resume dialog", Logger.AUDIO);
            document.body.removeEventListener("click", clickBeforeShowingDialog);
            if (errorDialogTimer) {
               clearTimeout(errorDialogTimer);
               errorDialogTimer = null;
               this.globalAudioContext.resume().then(() => {
                  Logger.debug("audoservice audioResumed ", Logger.AUDIO);
                  this.audioResumed = true;
               });
            }
         };

         errorDialogTimer = setTimeout(showErrorDialog, AudioService.MAX_WAIT_FOR_CLICK);
         document.body.addEventListener("click", clickBeforeShowingDialog);
      } else {
         if (!this.globalAudioContext) {
            Logger.debug("globalAudioContext not exist when page reloaded", Logger.AUDIO);
         } else if (this.globalAudioContext.state !== "suspended") {
            Logger.debug("globalAudioContext state is not suspended when page reloaded", Logger.AUDIO);
            Logger.debug("globalAudioContext state is " + this.globalAudioContext.state, Logger.AUDIO);
         }
      }
   };
}

export interface audioMixerInfo {
   volumesInfo: Array<number>;
   mute: boolean;
}

export interface ClientAudioData {
   audioMixerInfo: audioMixerInfo;
}

export interface ClientAudio {
   audioInfo: ClientAudioData;
   audioOutputDeviceCount: number;
}

export class AudioFactory {
   private static readonly MAX_DEVICE_ID: number = 8;
   private static readonly MAX_CHANNEL_NUMBER: number = 2;
   private static readonly MSG_TYPE_CHANNEL_VOLUME_MUTE = 0;
   private static readonly MSG_TYPE_CHANNEL_VOLUME_CHANGE = 1;
   private audioNextTime: number = 0;
   private volumeInfo: Array<number> = [];
   private isMuted: boolean = false;
   private lastUpdateChannelId = 0;
   private numChannels: number = 0;
   private audioWorklet: any = null;
   private audioQueue: any = null;
   private audioQueueBuffer: any = null;
   private audioPlayControl: AudioPlayControl;
   private audioInfo: Array<ClientAudioData> = [
      { audioMixerInfo: { volumesInfo: [], mute: false } },
      { audioMixerInfo: { volumesInfo: [], mute: false } },
      { audioMixerInfo: { volumesInfo: [], mute: false } },
      { audioMixerInfo: { volumesInfo: [], mute: false } },
      { audioMixerInfo: { volumesInfo: [], mute: false } },
      { audioMixerInfo: { volumesInfo: [], mute: false } },
      { audioMixerInfo: { volumesInfo: [], mute: false } },
      { audioMixerInfo: { volumesInfo: [], mute: false } }
   ];

   constructor(
      private audioContext: AudioContext,
      private service: AudioService,
      private logId: string,
      private sessionKey: string
   ) {
      this.audioPlayControl = new AudioPlayControl(this.logId, sessionKey, service);
      for (let i = 0; i < AudioFactory.MAX_CHANNEL_NUMBER; i++) {
         this.volumeInfo.push(1.0);
      }
      this.audioInfo.length = AudioFactory.MAX_DEVICE_ID;
      for (let m = 0; m < AudioFactory.MAX_DEVICE_ID; m++) {
         this.audioInfo[m]["audioMixerInfo"]["volumesInfo"] = this.volumeInfo;
         this.audioInfo[m]["audioMixerInfo"]["mute"] = false;
      }
   }

   /**
    * AudioFactory.isAudioEnabled
    *
    * Return whether audio is enabled on the browser.
    *
    */
   public isAudioEnabled = () => {
      return this.service.audioEnabled;
   };

   /**
    * AudioFactory.updateAudioMixer
    *
    * Update the audio mixer for each channel.
    *
    */
   public updateAudioMixer = (audioMixerInfo) => {
      const audioMixerMultiInfo = {
         audioDeviceId: 0,
         channelId: audioMixerInfo.channelId,
         msgType: audioMixerInfo.msgType,
         data: audioMixerInfo.data,
         flags: audioMixerInfo.flags
      };
      this.updateAudioMixerMulti(audioMixerMultiInfo);
   };

   public updateAudioMixerMulti = (audioMixerMultiInfo) => {
      let volumeNumber = 0;
      let db;
      if (audioMixerMultiInfo.msgType === AudioFactory.MSG_TYPE_CHANNEL_VOLUME_CHANGE) {
         /*
          * Convert from volume level ticks to dB.
          */
         db = audioMixerMultiInfo.data / 0x10000;

         /*
          * Solve for the amplitude of the dB.
          * dB = 10 log_10(A^2) = 20 log_20(A); A = 10^(dB / 20).
          */
         volumeNumber = Math.pow(10.0, db / 20);

         /*
          * Allow volume over 1.0 for https://jira.eng.vmware.com/browse/VCART-954
          * https://developer.mozilla.org/en-US/docs/Web/API/GainNode
          */
         this.volumeInfo[audioMixerMultiInfo.channelId] = volumeNumber;
         this.audioInfo[audioMixerMultiInfo.audioDeviceId].audioMixerInfo.volumesInfo[audioMixerMultiInfo.channelId] =
            volumeNumber;
         this.lastUpdateChannelId = audioMixerMultiInfo.channelId;
      } else if (audioMixerMultiInfo.msgType === AudioFactory.MSG_TYPE_CHANNEL_VOLUME_MUTE) {
         // Audio muted update.
         this.isMuted = !!audioMixerMultiInfo.data;
         this.audioInfo[audioMixerMultiInfo.audioDeviceId].audioMixerInfo.mute = !!audioMixerMultiInfo.data;
      } else {
         return;
      }
   };

   /**
    * AudioServiceFactory.canAudioUseOpus
    *
    * Return whether audio Opus is supported on the browser.
    *
    */
   public canAudioUseOpus = () => {
      return this.service.audioUseOpus;
   };

   /**
    * AudioServiceFactory.canAudioUseAac
    *
    * Return whether audio Aac is supported on the browser.
    *
    */
   public canAudioUseAac = () => {
      return this.service.audioUseAac;
   };

   /**
    * AudioServiceFactory.initializeAudioForTouch
    *
    * Initialize variables for touch device.
    *
    */
   public initializeAudioForTouch = () => {
      let silentTone;
      let gainNode;

      if (!this.service.audioUseWebAudioAPI) {
         // We only need to do this for the Web Audio API.
         return true;
      }

      Logger.trace("Initializing audio for touch.");

      try {
         silentTone = this.audioContext.createOscillator();
         gainNode = this.audioContext.createGain();

         gainNode.gain.value = 0;
         gainNode.connect(this.audioContext.destination);

         silentTone.type = "sine";
         silentTone.frequency.value = 200;
         silentTone.connect(gainNode);
         silentTone.noteOn(0);
         silentTone.stop(0);
      } catch (e) {
         Logger.info("Fail to Initialize audio for touch.");
      }

      /*
       * Ironically, audio should now be unmuted.
       * Explanation: http://stackoverflow.com/questions/12517000/no-sound-on-ios-6-web-audio-api
       */
      return true;
   };

   /**
    * AudioServiceFactory.playAudio
    *
    * Play a clip of audio information.
    *
    * @params audioInfo audio byte streams.
    */
   public playAudio = (audioInfo) => {
      Logger.trace("Received " + audioInfo.data.length + " audio bytes.");

      if (audioInfo.hasOwnProperty("rawPcm")) {
         if (this.numChannels != audioInfo.numChannels) {
            if (this.audioWorklet) {
               this.audioWorklet.disconnect();
               this.audioWorklet = null;
            }

            if (this.audioQueue) {
               this.audioQueue = null;
            }

            if (this.audioQueueBuffer) {
               this.audioQueueBuffer = null;
            }

            this.numChannels = audioInfo.numChannels;
            this.audioQueueBuffer = FreeQueue.createSharedBuffer(this.getAudioQueueMaxSamples(), audioInfo.numChannels);
            this.audioQueue = new FreeQueue(this.audioQueueBuffer);
            this.audioWorklet = new AudioWorkletNode(this.audioContext, "audio-output-worklet", {
               numberOfOutputs: 1,
               outputChannelCount: [audioInfo.numChannels],
               processorOptions: {
                  outputQueue: this.audioQueue
               }
            });
            this.audioWorklet.connect(this.audioContext.destination);
         }

         // Unfortunately we must de-interleave data for audioQueue
         const numSamples = audioInfo.length / audioInfo.numChannels / (audioInfo.containerSize / 8);
         const channels = [new Float32Array(numSamples), new Float32Array(numSamples)];
         let samplePosition = 0;
         for (let j = 0; j < numSamples; ++j) {
            for (let i = 0; i < audioInfo.numChannels; ++i) {
               channels[i][j] = audioInfo.data[samplePosition];
               samplePosition += 1;
            }
         }

         this.audioQueue.push(channels, numSamples);
         return;
      }
      const buffer = new ArrayBuffer(audioInfo.data.length);
      const bufferView = new Uint8Array(buffer);

      Logger.trace("Received " + audioInfo.data.length + " audio bytes.");

      for (let i = 0; i < audioInfo.data.length; i++) {
         bufferView[i] = audioInfo.data[i];
      }

      if (this.service.audioOutDevicesUniqueIdInfo.deviceCount === 0 && this.audioContext) {
         // Web Audio API
         this.playAudioWithWebAudioAPI(audioInfo, buffer);
      } else {
         // HTML5 audio object
         this.playAudioWithAudioObject(audioInfo, buffer);
      }
   };

   /**
    *
    * getAudioQueueSamples
    *
    * Returns the current number of samples in this.audioQueue
    */
   public getAudioQueueSamples = () => {
      if (this.audioQueue) {
         return this.audioQueue.getAvailableRead();
      } else {
         return 0;
      }
   };

   /**
    *
    * getAudioQueueMaxSamples
    *
    * Returns the maximum number of samples that can be in this.audioQueue
    */
   public getAudioQueueMaxSamples = () => {
      return 48000 / 5; // 0.2s max queue size
   };

   /**
    *
    * AudioServiceFactory.createBufferSource
    *
    * This is to work around Web Audio API portability issues across
    * browsers. See:
    * https://developer.mozilla.org/en-US/docs/Web_Audio_API/Porting_webkitAudioContext_code_to_standards_based_AudioContext
    *
    */
   private createBufferSource = (): AudioBufferSourceNode => {
      const source: AudioBufferSourceNode = this.audioContext.createBufferSource();
      if (typeof source.start === "undefined") {
         //@ts-ignore
         source.start = source.noteOn;
      }
      if (typeof source.stop === "undefined") {
         //@ts-ignore
         source.stop = source.noteOff;
      }
      return source;
   };

   /**
    *
    * playAudioWithWebAudioAPI
    *
    * Play audio using the Web Audio API.
    *
    * @params audioInfo audio byte streams.
    * @params buffer    buffer source.
    */
   private playAudioWithWebAudioAPI = (audioInfo, buffer: ArrayBuffer) => {
      const self = this;

      if (self.isMuted) {
         return;
      }

      self.audioContext.decodeAudioData(
         buffer,
         (audioBuffer: AudioBuffer) => {
            const source: AudioBufferSourceNode = self.createBufferSource();
            self.audioPlayControl.onNewData(
               self.audioContext,
               audioBuffer,
               audioInfo,
               source,
               self.isMuted,
               self.volumeInfo
            );
         },
         function () {
            Logger.trace("Error decoding audio data.");
         }
      );
   };

   /**
    *
    * playAudioWithAudioObject
    *
    * Play audio using an HTML5 audio object.
    *
    * @params audioInfo audio byte streams.
    * @params buffer    buffer source.
    */
   private playAudioWithAudioObject = (audioInfo, buffer: ArrayBuffer) => {
      const self = this;
      const audio = new Audio();
      const audioType =
         (audioInfo.flags & WMKS.VNCDecoder.prototype.audioflagFmtMask) >> WMKS.VNCDecoder.prototype.audioflagFmtShift;
      const mimeType = audioType === WMKS.VNCDecoder.prototype.audioFlagFmtClipsOpus ? "audio/ogg" : "audio/mp4";
      const blob = new Blob([buffer], { type: mimeType });

      const playClip = function () {
         const curTime = new Date().getTime();
         // audioInfo.containerSize is really our duration!
         const duration = audioInfo.containerSize;
         // audioInfo.sampleRate is really our overlap!
         const overlap = audioInfo.sampleRate;
         const offset = self.audioNextTime - curTime;
         const delay = Math.max(offset, 0);

         self.audioNextTime = curTime + delay + duration - overlap;

         if (Logger.LOG_LEVEL === Logger.LEVEL_TRACE) {
            Logger.trace("Playing audio (HTML5 Audio object)");
            Logger.trace(
               "Offset: " +
                  offset +
                  "ms" +
                  ", delay: " +
                  delay +
                  "ms" +
                  ", current time: " +
                  curTime +
                  ", duration: " +
                  duration +
                  "ms" +
                  ", overlap: " +
                  overlap +
                  "ms"
            );
         }
         setTimeout(function () {
            audio.muted = self.isMuted;
            if (!self.isMuted) {
               const audioVolume = self.volumeInfo[self.lastUpdateChannelId];
               /**
                * Add 1.0 limitation for changes of https://jira.eng.vmware.com/browse/VCART-954
                * Otherwise there will be error:
                * "Uncaught DOMException: Failed to set the 'volume' property on 'HTMLMediaElement':
                *    The volume provided (5) is outside the range [0, 1]."
                * We don't implement the volume over 1.0 using PCM data modification for below reasons:
                * 1) it's not common for model browser to run into this case, since it's a set of old API.
                * 2) we are using browser to decode the audio and don't have the access to the PCM data for now,
                * Which make the PCM modification take a lot of efforts.
                */
               audio.volume = audioVolume > 1.0 ? 1.0 : audioVolume;
            }
            audio.play();
            if (overlap) {
               /*
                * The audio object doesn't really support our
                * crossfade trick so start playing after any
                * overlap.
                */
               try {
                  /*
                   * This can throw an exception if the metadata
                   * hasn't been loaded yet.
                   */
                  audio.currentTime = overlap / 1000.0;
               } catch (e) {
                  Logger.warning("Error setting currentTime!");
               }
            }
            setTimeout(function () {
               window.URL.revokeObjectURL(audio.src);
            }, duration + 20);
         }, delay);
      };

      audio.src = window.URL.createObjectURL(blob);
      if (typeof audio.setSinkId === "function") {
         let audioOutDeviceId = 0;
         if (!audioInfo.hasOwnProperty("audioDeviceId")) {
            audioOutDeviceId = 0;
         } else {
            audioOutDeviceId = audioInfo.audioDeviceId;
         }
         audio.setSinkId(this.service.audioOutDevicesUniqueIdInfo.audioDevices[audioOutDeviceId].deviceId);
      }
      audio.addEventListener("loadeddata", playClip, false);
   };
}
