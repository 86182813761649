/**
 * ***************************************************************************
 * Copyright 2013-2021 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

/**
 * appblast-util.js
 *
 *    Initialize a top level namespace for Appblast. This file should be loaded
 *    before others can initialize.
 *
 *    This contains the following:
 *       Utility and helper functions that are not appblast specific.
 *
 *    NOTE: Namespace should be in upper case.
 */

import WMKS from "WMKS";
import BIG_DEFAULT_ICON_URI from "../../icons/default_app_icon.svg";
import SMALL_DEFAULT_ICON_URI from "../../icons/default_app_icon.svg";
import LOADING_ICON_URI from "../../icons/spinner2x.gif";
import ATTENTION_ICON_URI from "../../icons/attention2x.png";
import { Logger } from "@html-core";
import { RTAVService } from "../rtav/rtav.service";
import { DeviceStatus } from "../rtav/rtav.service";

export class AB {
   public static readonly DEFAULT_ICON_INDEX = 0;
   public static readonly BlastWMKS = {
      BSG_TIMEOUT_MS: 2 * 60 * 1000 - 1, // Just under 2 mins.
      INIT_BACKOFF_DELAY_MS: 1000,
      REQUEST_DISCONNECT_TIMEOUT: 5000,
      MAP_META_TO_CTRL_FOR_KEYS: [65, 67, 86, 88], // Map CMD to CTRL when used
      // with A, C, V and X
      MAP_META_TO_CTRL_FOR_VSCANS: [0x1e, 0x2e, 0x2f, 0x2d],
      IGNORE_RAW_KEY_CODES: [20], // CAPS: VK_CAPITAL = 20
      WIN_KEY_CODES: [91, 92] // WIN: VK_LWIN = 91, VK_RWIN = 92
   };

   /*
    * Defines disconnection codes for all of our expected disconnection cases.
    * The codes 3000-3999 are reserved for client use, so we can define our own
    * error codes.
    */
   public static readonly DISCONNECT_REASON = {
      VDPCONNECT_SERVER_SHADOW_SESSION_ENDED: 30,
      RECONNECT_FAILED: 3000,
      HEARTBEAT_TIMEOUT: 3001,
      DISCONNECT_REQUEST_TIMEOUT: 3002,
      IDLE_TIMEOUT: 3003
   };

   /*
    * Defines the different types of running/available items, in order of priority
    * on the sidebar display. These numbers will be used to sort the lists of running
    * and available items for display, with 0 displayed first, then 1, and such
    */
   public static readonly ITEMS_TYPE = {
      LOADING_ITEM: 0, // placeholder item that holds all connecting sessions
      LOADING_APP: 1, // placeholder for apps that have not received app data
      NEED_ATTENTION: 2, // session placeholders for unity paused sessions
      DESKTOP: 3,
      APP: 4
   };

   /*
    * Defines the different running item states. States are only assigned on actual
    * running items as opposed to placeholder items (LOADING_ITEM, NEED_ATTENTION)
    * to signal the UI to display them differently on the sidebar.
    */
   public static readonly ITEMS_STATE = {
      CONNECTED: 0,
      CONNECTING: 1,
      DISCONNECTED: 2
   };

   public static readonly ICONS = {
      BIG_DEFAULT_ICON: BIG_DEFAULT_ICON_URI,
      SMALL_DEFAULT_ICON: SMALL_DEFAULT_ICON_URI,
      LOADING_ICON: LOADING_ICON_URI,
      ATTENTION_ICON: ATTENTION_ICON_URI
   };

   public static readonly CANVAS_PARENT_ID: string = "#canvas-container";
   public static conversionCanvas: HTMLCanvasElement = null;

   public static ABrtavService;
   constructor(private rtavService: RTAVService) {
      AB.ABrtavService = this.rtavService;
   }

   public static isLoadingItem(type: number): boolean {
      return type === AB.ITEMS_TYPE.LOADING_ITEM;
   }

   public static isLoadingApp(type: number): boolean {
      return type === AB.ITEMS_TYPE.LOADING_APP;
   }

   public static isDesktop(type: number): boolean {
      return type === AB.ITEMS_TYPE.DESKTOP;
   }

   public static isIPhone(): boolean {
      return window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1;
   }

   public static isApp(type: number): boolean {
      return type === AB.ITEMS_TYPE.LOADING_APP || type === AB.ITEMS_TYPE.APP;
   }

   public static isConnected(state: number): boolean {
      return state === AB.ITEMS_STATE.CONNECTED;
   }

   public static isConnecting(state: number): boolean {
      return state === AB.ITEMS_STATE.CONNECTING;
   }

   public static isDisconnected(state: number): boolean {
      return state === AB.ITEMS_STATE.DISCONNECTED;
   }

   public static isUsingWebcam(item: any): boolean {
      return (
         this.isDesktop(item.type) &&
         AB.ABrtavService.hasOccupiedResources(item.wmksKey) &&
         AB.ABrtavService.isUsingDevices(item.wmksKey, "video")
      );
   }

   public static isAskingWebcam(wmksKey: string): boolean {
      return AB.ABrtavService.isAskingPermission(wmksKey, "video");
   }

   public static isUsingMicrophone(item: any): boolean {
      return (
         this.isDesktop(item.type) &&
         AB.ABrtavService.hasOccupiedResources(item.wmksKey) &&
         AB.ABrtavService.isUsingDevices(item.wmksKey, "audio")
      );
   }

   public static getRTAVDeviceStatus(): DeviceStatus {
      const result: DeviceStatus = {
         activeSessionId: null,
         deviceInUsed: {
            audio: false,
            video: false
         }
      };
      const activeRTAVSessionId = AB.ABrtavService.getWorkingSessionId();
      if (activeRTAVSessionId) {
         result.activeSessionId = activeRTAVSessionId;
         result.deviceInUsed.audio = AB.ABrtavService.isUsingDevices(activeRTAVSessionId, "audio");
         result.deviceInUsed.video = AB.ABrtavService.isUsingDevices(activeRTAVSessionId, "video");
      }
      Logger.info("RTAV status:" + JSON.stringify(result));
      return result;
   }

   public static isAskingMicrophone(wmksKey: string): boolean {
      return AB.ABrtavService.isAskingPermission(wmksKey, "audio");
   }

   /*
    * bgraMapToPNG
    *
    *    Converts a bgra bitmap (Uint8ClampedArray) to a PNG format
    *
    *    returns a PNG formatted data string for web (data:image/png;base64...)
    */
   public static bgraMapToPNG(bgra: [], width: number, height: number): string {
      let canvas: HTMLCanvasElement = null;
      let context: CanvasRenderingContext2D = null;
      let imageData: ImageData = null;

      try {
         // Use a canvas to convert the bitmap image format to png
         if (!AB.conversionCanvas) {
            AB.conversionCanvas = document.createElement("canvas");
            if (!AB.conversionCanvas.getContext) {
               AB.conversionCanvas = null;
               return "";
            }
         }

         canvas = AB.conversionCanvas;
         canvas.width = width;
         canvas.height = height;
         context = canvas.getContext("2d");
         imageData = context.createImageData(width, height);

         // Individually write each pixel to canvas data
         for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++) {
               // format is bottom-to-top, convert to top-to-bottom
               const bgraIndex = 4 * (x + width * y);
               const imageIndex = 4 * (x + width * (height - 1 - y));
               // convert bgra pixel to rgba
               imageData.data[imageIndex] = bgra[bgraIndex + 2];
               imageData.data[imageIndex + 1] = bgra[bgraIndex + 1];
               imageData.data[imageIndex + 2] = bgra[bgraIndex];
               imageData.data[imageIndex + 3] = bgra[bgraIndex + 3];
            }
         }
         context.putImageData(imageData, 0, 0);
         // extracts image data in 'data:image/png;base64' format
         return canvas.toDataURL("image/png");
      } catch (e) {
         return "";
      }
   }

   /*
    * getClipboardText
    *
    *    Gets text data from the user's clipboard.
    *    e - a copy or paste event from the browser, is required for non-IE
    *    browsers because they store clipboard data on the event
    *
    * @param windowObject the window object to use, if undefined the global window
    *                     object will be used. The normal use case is for this to be
    *                     undefined, but we use the parameter for unit tests where we
    *                     do not want to manipulate the actual window object.
    */
   public static getClipboardText(e: any, windowObject?: any): string {
      if (!windowObject) {
         windowObject = window;
      }
      if (e && e.originalEvent && e.originalEvent.clipboardData) {
         // For non-IE browsers triggered by jquery
         return e.originalEvent.clipboardData.getData("text/plain");
      } else if (e && e.clipboardData) {
         // For non-IE browsers triggered by angular/window event
         return e.clipboardData.getData("text/plain");
      } else if (windowObject.clipboardData) {
         // For IE
         return windowObject.clipboardData.getData("text");
      }
      return "";
   }

   /*
    * getClipboardHtml
    *
    *    Gets html data from the user's clipboard.
    *    e - a copy or paste event from the browser, is required for non-IE
    *    browsers because they store clipboard data on the event
    *
    * @param windowObject the window object to use, if undefined the global window
    *                     object will be used. The normal use case is for this to be
    *                     undefined, but we use the parameter for unit tests where we
    *                     do not want to manipulate the actual window object.
    */
   public static getClipboardHtml(e: any, windowObject?: any): string {
      if (!windowObject) {
         windowObject = window;
      }

      if (e && e.originalEvent && e.originalEvent.clipboardData) {
         // For non-IE browsers triggered by jquery
         return e.originalEvent.clipboardData.getData("text/html");
      } else if (e && e.clipboardData) {
         // For non-IE browsers triggered by angular/window event
         return e.clipboardData.getData("text/html");
      } else if (windowObject.clipboardData) {
         // For IE
         return windowObject.clipboardData.getData("text");
      }
      return "";
   }

   /*
    * setClipboardText
    *
    *    Sets text data on the user's clipboard.
    *    e - a copy or paste event from the browser, is required for non-IE
    *    browsers because they store clipboard data on the event
    *    text - the text to be pasted to clipboard
    *
    * @param windowObject the window object to use, if undefined the global window
    *    object will be used. The normal use case is for this to be
    *    undefined, but we use the parameter for unit tests where we
    *    do not want to manipulate the actual window object.
    */

   public static setClipboardText(e: any, text: string, windowObject?: any): void {
      /* istanbul ignore if */
      if (!windowObject) {
         windowObject = window;
      }

      if (e && e.originalEvent && e.originalEvent.clipboardData) {
         // For non-IE browsers
         e.originalEvent.clipboardData.setData("text/plain", text);
      } else if (e && e.clipboardData) {
         e.clipboardData.setData("text/plain", text);
      } else if (windowObject.clipboardData) {
         // For IE
         windowObject.clipboardData.setData("text", text);
      }
   }

   /*
    * setClipboardHtml
    *
    *    Sets html data on the user's clipboard.
    *    e - a copy or paste event from the browser, is required for non-IE
    *    browsers because they store clipboard data on the event
    *    text - the text to be pasted to clipboard
    *
    * @param windowObject the window object to use, if undefined the global window
    *    object will be used. The normal use case is for this to be
    *    undefined, but we use the parameter for unit tests where we
    *    do not want to manipulate the actual window object.
    */
   public static setClipboardHtml(e: any, html: string, windowObject?: any): void {
      /* istanbul ignore if */
      if (!windowObject) {
         windowObject = window;
      }

      if (e && e.originalEvent && e.originalEvent.clipboardData) {
         // For non-IE browsers
         e.originalEvent.clipboardData.setData("text/html", html);
      } else if (e && e.clipboardData) {
         e.clipboardData.setData("text/html", html);
      }
      // For IE there's only two parameters "url" and 'text'for
      // setdata according to the docs, don't accept text/html as a format.
   }

   /*
    * getAppIconIndex
    *
    *    return a proper icon index from an icon set.
    *    If we have target size icon, we will use it.
    *    Otherwise, use the next larger icon.
    *    Otherwise, use the next smaller icon.
    *    Otherwise, we return -1 to index there is no icon found.
    *
    * param icons a set of icon candidate.
    * param targetIconSize icon size we are looking for.
    */
   public static getAppIconIndex(icons: any, targetIconSize: number): number {
      let iconIndex: any = -1;
      let currentSelectedIconSize: number = 0;
      let currentIconSize: number = 0;
      for (const i in icons) {
         if (icons.hasOwnProperty(i)) {
            currentIconSize = parseInt(icons[i].width, 10);
            if (currentIconSize === targetIconSize) {
               currentSelectedIconSize = currentIconSize;
               iconIndex = i;
               break;
            } else if (currentIconSize > targetIconSize) {
               // Find the closest larger icon.
               if (currentSelectedIconSize < targetIconSize || currentIconSize < currentSelectedIconSize) {
                  currentSelectedIconSize = currentIconSize;
                  iconIndex = i;
               }
            } else {
               // Find the closest smaller icon
               if (currentIconSize > currentSelectedIconSize && currentSelectedIconSize < targetIconSize) {
                  currentSelectedIconSize = currentIconSize;
                  iconIndex = i;
               }
            }
         }
      }
      return iconIndex;
   }

   /**
    * getTouchDeviceDesiredResolution
    *
    * orientationchange is fired after window.orientation
    * property has changed, but before the orientation is reflected in the UI.
    * Inspecting dimensions of elements (e.g. window.innerWidth or window.innerHeight)
    * gives the dimensions of the elements in the pre-orientation change state.
    *
    * There is no way to capture the end of the orientation change event because
    * handling of the orientation change varies from browser to browser. Drawing
    * a balance between the most reliable and the fastest way to detect the end
    * of orientation change requires racing interval and timeout.
    *
    */
   public static getTouchDeviceDesiredResolution(callback: any, windowObject?: any) {
      let noChangeCountToEnd = 100,
         noEndTimeout = 1000,
         interval,
         timeout,
         end,
         lastClientWidth,
         lastClientHeight,
         noChangeCount;

      /* istanbul ignore if */
      if (!windowObject) {
         windowObject = window;
      }

      end = function () {
         clearInterval(interval);
         clearTimeout(timeout);

         interval = null;
         timeout = null;
         // "orientationchangeend"
         callback([lastClientWidth, lastClientHeight]);
      };

      interval = setInterval(function () {
         if (
            windowObject.document.documentElement.clientWidth === lastClientWidth &&
            windowObject.document.documentElement.clientHeight === lastClientHeight
         ) {
            noChangeCount++;

            if (noChangeCount === noChangeCountToEnd) {
               end();
            }
         } else {
            lastClientWidth = windowObject.document.documentElement.clientWidth;
            lastClientHeight = windowObject.document.documentElement.clientHeight;
            noChangeCount = 0;
         }
      });
      timeout = setTimeout(function () {
         // The timeout happened first.
         end();
      }, noEndTimeout);
   }

   /*
    * isMP4Supported
    *
    * Test if media source object is supported on this browser.
    * For EA, we only support Chrome browser for now.
    *
    *
    * @param windowObject the window object to use, if undefined the global window
    *                     object will be used. The normal use case is for this to be
    *                     undefined, but we use the parameter for unit tests where we
    *                     do not want to manipulate the actual window object.
    *
    * @param wmks the wmks Object to use, if undefined the global wmks object will be
    *             used. This is for unit testing purposes to avoid modifying a global
    *             object.
    *
    */
   public static isMP4Supported(windowObject?: any, wmks?: any): boolean {
      let isMediaSourceSupported = true,
         testObject = null;

      if (!windowObject) {
         windowObject = window;
      }

      if (!wmks) {
         wmks = WMKS;
      }

      // For MP4 feature, we only support Chrome browser 45 and above.
      if (!wmks.BROWSER.isChrome() || wmks.BROWSER.version.major < 45 || WMKS.BROWSER.isAndroid()) {
         return false;
      }

      try {
         windowObject.MediaSource = windowObject.MediaSource || windowObject.WebKitMediaSource;
         testObject = new windowObject.MediaSource();
         testObject = null;
      } catch (e) {
         isMediaSourceSupported = false;
      }
      return isMediaSourceSupported;
   }

   /*
    * isRTAVSupported
    *
    * We donn't support RTAV on IE and Safari
    *
    * @param wmks the wmks Object to use, if undefined the global wmks object will be
    *             used. This is for unit testing purposes to avoid modifying a global
    *             object.
    */
   public static isRTAVSupported(wmks?: any): boolean {
      if (!wmks) {
         wmks = WMKS;
      }
      // For RTAV feature, we don't support IE and Safari.
      return !((wmks.BROWSER.isIE() && wmks.BROWSER.version.major <= 11) || wmks.BROWSER.isSafari());
   }

   /*
    * clamp
    *
    *    Given three parameters value, minValue, maxValue returns:
    *       minValue if value < minValue
    *       maxValue if min > maxValue
    *       otherwise returns value
    *    In the case where minValue > maxValue the behavior is undefined
    */
   public static clamp(value: number, minValue: number, maxValue: number): number {
      return Math.min(maxValue, Math.max(value, minValue));
   }

   /*
    * itemNameContainsQuery
    *
    *    Predicate that returns where an item's name property matches a query
    *    string. Returns true if query === "" or if the item name contains query.
    *    This does a case insensitive comparison.
    */
   public static itemNameContainsQuery(item: any, query: string): boolean {
      if (query === "") {
         return true;
      }

      // Use indexOf along with toLocaleLowerCase() to see if the search string
      // is in the item
      return item.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1;
   }

   /*
    * itemSortHelper
    *
    *    Help function is used in Array.sort(). We always put desktop item on the
    *    top of list. If it is the same type, follow the alphabet order.
    */
   public static itemSortHelper(item1: any, item2: any): number {
      if (item1.type === item2.type) {
         // If it is the same type, sort the item by alphabet order.
         if (item1.name === item2.name) {
            return 0;
         } else {
            return item1.name > item2.name ? 1 : -1;
         }
      } else {
         return item1.type > item2.type ? 1 : -1;
      }
   }

   /**
    * @return {Boolean} This returns whether current browser is IE with version equal
    *    or lower than 11 which doesn't postMessage well.
    */
   public static supportPostMessage(): boolean {
      const ua = window.navigator.userAgent.toLowerCase();
      const isIE = ua.indexOf("msie") > -1 || ua.indexOf("trident") > -1;
      const isEdge = ua.indexOf("edge") > -1;
      return !isIE || isEdge;
   }

   public static isTouchDevice(): boolean {
      const isIOSSafari = WMKS.BROWSER.isSafari() && WMKS.BROWSER.isIOS(),
         isAndroidChrome = WMKS.BROWSER.isChrome() && WMKS.BROWSER.isAndroid();
      if (isIOSSafari || isAndroidChrome) {
         return true;
      }
      return false;
   }
}
