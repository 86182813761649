/**
 * ******************************************************
 * Copyright (C) 2019 - 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import $ from "jquery";
import { Injectable } from "@angular/core";

type voidCall = () => void;

/*
 *
 * Keep this service injected with the provider in the Common Module rather than with
 * the @Injectable() decorator.
 *
 * It is used in the shared codes with the @Optional() decorator.
 */

export class IdleTimeoutUtil {
   public static readonly hintTime = 30;
   private static readonly checkingInterval = 5;
   private handleTimeoutWarning;
   private handleTimeout;

   public inited: boolean;
   public checkingTimer;
   constructor() {
      this.inited = false;
   }
   /**
    * same as in JSCDK, user will see a about to time out hint
    * 30s before timeout,
    */

   private getIdleStatusHandler = (callback, description, hasAlreadyTimeout) => {
      const idleStatusMap = {
         idle: "idle",
         active: "active",
         locked: "idle"
      };
      let lastStatus = "active";
      return (status) => {
         if (!idleStatusMap.hasOwnProperty(status)) {
            Logger.error("invalid idle status");
            return;
         }
         const newStatus = idleStatusMap[status];
         Logger.trace(
            "idle timer for: " + description + ", lastStatus:" + lastStatus + ", currentStatus: " + newStatus
         );
         // sync status before invoking callback
         const idleJustHappens: boolean = newStatus === "idle" && lastStatus === "active";

         lastStatus = newStatus;
         if (idleJustHappens) {
            /**
             * below is workaround and might causes bug, need heavy testing on it for complex cases.
             */
            if (hasAlreadyTimeout()) {
               Logger.debug("already in locked status, skip idletimeout related event firing: " + description);
               return;
            }
            Logger.info("idletimeout related event fired by chrome OS: " + description);
            callback();
         }
      };
   };

   public init = (warningCallback: voidCall, timeoutCallback: voidCall, isSessionTimedOut: voidCall) => {
      this.handleTimeoutWarning = this.getIdleStatusHandler(warningCallback, "about to time out", isSessionTimedOut);
      this.handleTimeout = this.getIdleStatusHandler(timeoutCallback, "already time out", isSessionTimedOut);
      this.inited = true;
   };

   public stopTimers = () => {
      if (this.checkingTimer) {
         clearInterval(this.checkingTimer);
      }
   };

   public startTimers = (timeout: number) => {
      if (!this.inited) {
         Logger.error("chromeidleTimeoutService not inited before try to start timers");
         return;
      }
      if (timeout <= 0) {
         Logger.info("idle timeout is disabled, not enable it for chrome client");
         return;
      }
      this.stopTimers();

      this.checkingTimer = setInterval(() => {
         chrome.idle.queryState(timeout, this.handleTimeout);
         if (timeout - IdleTimeoutUtil.hintTime >= IdleTimeoutUtil.hintTime) {
            // not show the hint if it will pop out too quickly.
            chrome.idle.queryState(timeout - IdleTimeoutUtil.hintTime, this.handleTimeoutWarning);
         }
      }, IdleTimeoutUtil.checkingInterval * 1000);
   };
}
