/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { Html5MMRService } from "../channels/html5MMR-client/html5MMR.service";
import { MediaManager } from "./media-manager";

@Component({
   selector: "media-overlay",
   templateUrl: "./media-overlay.component.html"
})

/**
 * Media overlay component that manages <media-overlay> tag and its
 * subcomponents related to video/audio resources
 */
export class MediaOverlayComponent implements OnDestroy, AfterViewInit {
   private logger = new Logger(Logger.MEDIA);

   /**
    * Construct Media Overlay component
    * @param  {MediaManager} mediaManager injected media manager instance
    * @param  {Html5MMRService} mmrService injected HTML5 MMR Service instance
    */
   constructor(
      private mediaManager: MediaManager,
      private mmrService: Html5MMRService
   ) {
      this.logger.info("MediaOverlayComponent created");
   }

   ngAfterViewInit() {
      this.logger.info("MediaOverlayComponent after view init");
   }

   ngOnDestroy() {
      this.logger.info("MediaOverlayComponent on view destroy");
   }
}
