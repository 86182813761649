/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export const MKSVCHAN_CONST = {
   // Some constants copied from bora/apps/rde/mksvchan
   PACKET_TYPE: {
      CLIPBOARD_DATA_TEXT: 0,
      CLIPBOARD_REQUEST: 1,
      CLIPBOARD_DATA_CP: 2,
      CLIPBOARD_CAPABILITIES: 3,
      CLIPBOARD_LOCALE: 4,
      FILE_TRANSFER_REQUEST: 5,
      FILE_TRANSFER_DATA_FILE: 6,
      FILE_TRANSFER_CONFIG: 7,
      FILE_TRANSFER_ERROR: 8,
      CLIPBOARD_PASTE_NOTIFICATION: 9, //used for clipboard audit
      CLIPBOARD_STATE: 10
   },

   CAPABILITY_CLIPBOARD: {
      TEXT: 0,
      IMAGE_AND_RTF: 1,
      NOTIFY_ON_CHANGE: 2
   },

   CLIPBOARD_ERROR: {
      NONE: 0,
      MAX_LIMIT_EXCEEDED: 1,
      DISALLOWED_BY_AUDIT: 2
   },

   FILE_TRANSFER_FILE_TYPE: {
      FILE: 0,
      DIRECTORY: 1,
      SYMLINK: 2
   },

   FILE_TRANSFER_POLICY: {
      MIN: 0,
      DISABLED: 0,
      DEFAULT: 0,
      BIDIRECTIONAL: 1,
      ONLY_TO_SERVER: 2,
      ONLY_TO_CLIENT: 3,
      MAX: 3
   },

   FILE_TRANSFER_ERROR: {
      NO_ENOUGH_DISK: 0,
      IS_TRANSFERRING: 1,
      UNKNOWN_ERROR: 2,
      PRINT_TIMEOUT: 3,
      FILE_PATH_ILLEGAL: 4
   },

   FILE_TRANSFER_REQUEST: {
      SEND_FILES: 0,
      CANCEL_SEND_FILES: 1,
      SEND_SPECIFIC_FILES: 2,
      CANCEL_SEND_SPECIFIC_FILES: 3,
      CANCEL_RECEIVE_SPECIFIC_FILE: 4,
      // New added file list format
      FILE_LIST: 5,
      PRINT_CONFIG: 6
   },

   FILE_TRANSFER_CLIENT: {
      WINDOWS: 0,
      MAC: 1,
      LINUX: 2,
      WEB: 3,
      ANDROID: 4,
      IOS: 5
   },

   FILE_TRANSFER_CONSUMER: {
      FT: 0,
      PRINT: 1,
      DEFAULT: 0,
      MIN: 0,
      MAX: 1
   },

   FILE_TRANSFER_MAX_FILE_SIZE: 1024 * 1024 * 500,
   FILE_TRANSFER_MAX_TOTAL_SIZE: 1024 * 1024 * 1000,

   /**
    * The capability bit that enables automatic mksVchan updates on clipboard
    * change. When this capability is enabled, we will listen to mksVchan's
    * automatic updates, rather than the old updates from Blast, which will be
    * disabled by agent.
    */
   ENABLE_NOTIFY_ON_CHANGE: 1 << 8,

   /*
    * The current limit for MAX_BYTES_TEXT comes from the mksVchanPlugin, see
    * MKSVchanPlugin_GetCapsMaxClipboardBytes. The limit is 1mb (minus header)
    * since the new clipboard functionality was added.
    */
   MAX_BYTES_TEXT: 1024 * 1024 - 100,
   MAX_BYTES_IMAGE_AND_RTF: 1024 * 1024,
   MAX_KBYTES_IMAGE_AND_RTF: 1024,

   CHANNEL_NAME_PREFIX: "RPC#MKSVchan#",
   CHANNEL_DATA_OBJECT: "MKSVchanDataObj",
   CHANNEL_CTRL_OBJECT: "MKSVchanControlObj",

   /**
    * List of data formats that the clipboard supports
    * Copied from bora/lib/public/dnd.h
    * NOTE: View does not support all of these
    * @enum {Number}
    */
   CP_FORMAT: {
      UNKNOWN: 0,
      MIN: 1,
      TEXT: 1,
      FILELIST: 2,
      RTF: 3,
      FILELIST_URI: 4,
      FILECONTENTS: 5,
      IMG_PNG: 6,
      FILEATTRIBUTES: 7,
      BIFF12: 8,
      ART_GVML_CLIPFORMAT: 9,
      HTML_FORMAT: 10,
      MAX: 11
   }
};
