/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
   selector: "timezone-list",
   templateUrl: "./timezone-list.component.html"
})
export class TimezoneListComponent {
   @Input() timeZoneStatus;
   @Output() selectedTimezone = new EventEmitter();
   constructor() {}

   public changeTimezone = (change) => {
      this.selectedTimezone.emit(change.target.value);
   };
}
