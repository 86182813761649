/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { BusEvent, clientUtil, EventBusService } from "@html-core";
import { MultiSiteService } from "../../common/service/multisite.service";
import { Injectable, Optional } from "@angular/core";
import { SessionUtil } from "../service/session-util";
import { LaunchService } from "../../launcher/launchitems/session-launch.service";
import { ClientModeService } from "../service/client-mode.service";
import { AjaxBusyService } from "../ajax-busy/ajax-busy.service";

@Injectable({
   providedIn: "root"
})
export class SessionManagementService {
   private currentController = null;

   constructor(
      private sessionUtil: SessionUtil,
      @Optional()
      private launchService: LaunchService,
      private clientModeService: ClientModeService,
      private ajaxBusyService: AjaxBusyService,
      private eventBusService: EventBusService
   ) {}

   public disconnectSessionFailed = (controller, jscdkHandler) => {
      this.currentController = controller;
      if (this.clientModeService.clientMode === "launcher") {
         if (this.currentController.componentName === "GetLaunchItems" && !clientUtil.isSeamlessMode()) {
            this.ajaxBusyService.setAjaxBusy(false);
            this.currentController.launchApplication(
               this.currentController.currentApplication,
               this.currentController.focusElement,
               this.currentController.bufferedArgs
            );
            this.currentController.bufferedArgs = null;
         } else {
            Logger.debug("Not in the launchitems page.");
         }
         return;
      } else {
         Logger.error("disconnect is not expected to be invoked");
         jscdkHandler.handleSuccessResponse();
      }
   };

   public cacheBlastApplication = (actionData) => {
      this.sessionUtil.cacheLaunchData(actionData);
   };

   public showAppBlastSession = (actionData, controller, jscdkHandler, jscdkWrapper) => {
      this.currentController = controller;
      if (this.clientModeService.clientMode === "launcher" && !clientUtil.isTitanClient()) {
         this.launchService.onLaunchDataReady(
            {
               controller: this.currentController,
               launchData: actionData.content
            },
            jscdkWrapper
         );
      } else {
         // Multi-site redirection only works for desktop now
         if (!!actionData.content.redirectProperties && actionData.name === "ShowAppBlastDesktop") {
            // Multi-site redirection
            try {
               MultiSiteService.redirectAndStartSession(actionData.content.redirectProperties);
               return;
            } catch (e) {
               Logger.debug("Error: fail to redirect due to: " + e);
            }
         }
         jscdkHandler.handleItemConnect(actionData.content);
      }
      if (actionData.content && actionData.content.rdsLicense) {
         this.eventBusService.dispatch(new BusEvent.RdsLicenseMsg(actionData.content.rdsLicense));
      }
   };

   public applicationAlreadyConnected = (actionData, jscdkHandler) => {
      const appId = actionData.content.id,
         originId = actionData.content.callbackParams.originId,
         sessionId = actionData.content.sessionId;
      this.ajaxBusyService.setAjaxBusy(false);
      this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
      this.sessionUtil.sessionConnected(appId, originId, sessionId).then((isSessionConnected) => {
         if (isSessionConnected) {
            if (this.clientModeService.clientMode === "launcher") {
               Logger.debug("new launched app reuses the existing session, should be in the seamless mode");
               this.sessionUtil.isMultiSession(appId).then((isMultimon) => {
                  const SessionKey = SessionUtil.getSessionKey(isMultimon, originId, sessionId);
                  this.launchService.onNewAppReuseSession(SessionKey);
               });
            } else {
               jscdkHandler.handleShareSessionResponse();
            }
            setTimeout(() => {
               this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
            });
            this.eventBusService.dispatch(
               new BusEvent.StopBlockLaunchingFromSDK("target app session hosted on existing session")
            );
         } else {
            this.sessionUtil
               .fetchLaunchData(appId, originId, sessionId)
               .then((launchData) => {
                  if (!launchData) {
                     Logger.error("recovery empty session data when it's status is connected!");
                     return;
                  }
                  Logger.info("using cached session info to reconnect app session");
                  actionData.content = launchData;
                  actionData.name = "ShowAppBlastApplication";
                  jscdkHandler.responseToJSCDKEvent(JSON.stringify(actionData));
                  // not unblock further launch from SDK/URL here, since the connection is not finished yet.
               })
               .catch((e) => {
                  Logger.error("failed to recovery the session data when it's status is connected!" + e);
                  /**
                   * only unblock UI for bug 2510806, where session-id is missing and origin-id is not accurate.
                   * If we want to make client more robust in the same setup, more codes are needed.
                   */
                  jscdkHandler.handleShareSessionResponse();
                  this.eventBusService.dispatch(
                     new BusEvent.StopBlockLaunchingFromSDK(
                        "target app session hosted on existing session but not recoverable"
                     )
                  );
               });
         }
      });
   };

   public onKillAllApplicationSessionsDone = (controller, jscdkHandler) => {
      // this function is for kill session on launcher page
      if (controller) {
         this.currentController = controller;
         this.currentController.enable();
      }
      jscdkHandler.handleSuccessResponse();
   };

   public onKillSessionsDone = (jscdkHandler) => {
      // this function is for kill session on launcher page
      this.currentController.enable();
      jscdkHandler.handleSuccessResponse();
   };

   public reconnectAllApplications = (actionData, controller, jscdkHandler, jscdkWrapper) => {
      if (actionData.length > 0) {
         for (let i = 0; i < actionData.length; i++) {
            this.cacheBlastApplication(actionData[i].content);
            const showApplicationObj = {
               content: actionData[i].content,
               name: "ShowAppBlastApplication"
            };
            this.showAppBlastSession(showApplicationObj, controller, jscdkHandler, jscdkWrapper);
         }
      }
   };
}
