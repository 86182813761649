/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * securIDWaitHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler for securIDWait
 *      authentication.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function SecurIDWaitHandler() {
   let router;
   // member variables below
   this.messageName = "securid-wait";
   this.messageText = "securid-wait";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
SecurIDWaitHandler.prototype = new MessageHandler();
// constructor
SecurIDWaitHandler.constructor = SecurIDWaitHandler;

/**
 * Set the request XML for securIDWait authentication.
 *
 */

SecurIDWaitHandler.prototype.setRequestXML = function () {
   let paramText;

   paramText = util.createElement("params", "");
   this.requestXML = util.createElement("name", this.messageName);
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
