/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * securIDPasscodeHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler for SecurIDPasscode
 *      authentication.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function SecurIDPasscodeHandler() {
   let router;
   // member variables below
   this.messageName = "securid-passcode";
   this.messageText = "securid-passcode";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
SecurIDPasscodeHandler.prototype = new MessageHandler();
// constructor
SecurIDPasscodeHandler.constructor = SecurIDPasscodeHandler;

/**
 * Set the request XML for Windows passcode authentication.
 *
 * @param username [in] username for the securid authentication.
 * @param passcode [in] passcode for the securid authentication.
 */

SecurIDPasscodeHandler.prototype.setRequestXML = function (username, passcode) {
   let usernameText;
   let passcodeText;
   let paramText;
   usernameText = util.createElement("name", "username");
   usernameText += util.createElement("values", util.createElement("value", username));
   passcodeText = util.createElement("name", "passcode");
   passcodeText += util.createElement("values", util.createElement("value", passcode));
   paramText = util.createElement("param", usernameText);
   paramText += util.createElement("param", passcodeText);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", this.messageName);
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
