/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { Logger } from "../../../core/libs/logger";

@Injectable({
   providedIn: "root"
})
export class AjaxBusyService {
   private isAjaxBusy: boolean;
   // ueses 1 events only for output to avoid fire same status multiple times.
   private ajaxBusySubject$: BehaviorSubject<boolean>;

   constructor() {
      this.isAjaxBusy = false;
      this.ajaxBusySubject$ = new BehaviorSubject<boolean>(this.isAjaxBusy);
   }

   public setAjaxBusy = (isBusy: boolean) => {
      if (isBusy !== this.isAjaxBusy) {
         this.isAjaxBusy = isBusy;
         Logger.debug("ajaxBusy status changed to: " + isBusy);
         this.ajaxBusySubject$.next(this.isAjaxBusy);
      }
   };

   public getAjaxBusy = (): boolean => {
      return this.isAjaxBusy;
   };

   public subscribe = (callback: (boolean) => void): Subscription => {
      return this.ajaxBusySubject$.subscribe({
         next: callback
      });
   };
}
