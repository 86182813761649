/**
 * ******************************************************
 * Copyright (C) 2015-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import util from "../../jscdk/util";
import $ from "jquery";
import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { ConnectionServerModel } from "../../common/model/connection-server-model";
import { UtilService } from "../common/util-service";
import { TransitionDataModel } from "../../common/model/transition-data-model";
import { MultiSessionSingleUserService } from "../../common/service/multisession-singleuser.service";
import { SessionManagementCenterManager } from "../../../chrome-client/launcher/launchitems/session-service/session-management-center-manager.service";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { ConnectionURIModel } from "../../common/model/connection-uri-model";
import { Optional } from "@angular/core";
import { AjaxBusyService } from "../../common/ajax-busy/ajax-busy.service";
import { OperationService, IItemController } from "./operation.service";
import { ViewService } from "../common/view-service";
import { SDKService } from "../../../chrome-client/SDK/sdk-service";
import { EventBusService, BusEvent } from "@html-core";

// refactor in the later patch
@Injectable()
export class LaunchItemsCtrlService {
   constructor(
      private connectionServerModel: ConnectionServerModel,
      private utilService: UtilService,
      private connectionURIModel: ConnectionURIModel,
      private transitionDataModel: TransitionDataModel,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private multisessionSingleUserService: MultiSessionSingleUserService,
      @Optional()
      private sessionManagementCenterManager: SessionManagementCenterManager,
      private modalDialogService: ModalDialogService,
      private ajaxBusyService: AjaxBusyService,
      private operationService: OperationService,
      private viewService: ViewService,
      @Optional()
      private sdkService: SDKService,
      private eventBusService: EventBusService
   ) {}
   private _itemCtrl: IItemController;
   /**
    * _storeLaunchingItemInfo
    *
    * store the current launching item info. If item is desktop,
    * desktopId and desktopName is required. originApp is clear to
    * null. If item is application, vice versa.
    */
   public _storeLaunchingItemInfo = (desktopInfo, originApp) => {
      this.transitionDataModel.originDesktop = desktopInfo;
      this.transitionDataModel.originApp = originApp;
   };

   private _launchDesktop = (desktop) => {
      if (desktop) {
         // Store current desktop to persistent storage
         this._storeLaunchingItemInfo(
            {
               id: desktop.id,
               name: desktop.name
            },
            {
               appId: "",
               originId: ""
            }
         );
         this.jscdkCommonInvoker.launchDesktop(desktop);
      }
   };

   private _logoffDesktop = (desktop) => {
      let logoffCallback;

      logoffCallback = (desktop) => {
         // Disable logoff button.
         desktop.logoffEnabled = false;
         // Logoff desktop.
         this.jscdkCommonInvoker.logoffDesktop(desktop.id);
         /*
          * if logoffDesktop is called by URI action, client need to
          * set ajaxBusy to false after logoff desktop to show all
          * launcher items
          */
         if (window.location.hash === "#/launchitems") {
            this.ajaxBusyService.setAjaxBusy(false);
         }
      };

      this.modalDialogService.showConfirm({
         data: {
            titleKey: "LOGOFF_DESKTOP_T",
            contentKey: "dialog_confirm_logoff"
         },
         callbacks: {
            confirm: () => {
               logoffCallback(desktop);
            }
         }
      });
   };

   private _resetDesktop = (desktop) => {
      let resetCallback;

      resetCallback = (desktop) => {
         // Logoff desktop.
         this.jscdkCommonInvoker.reset(desktop.id);
      };

      this.modalDialogService.showConfirm({
         data: {
            titleKey: "dialog_title_reset",
            contentKey: "dialog_confirm_reset"
         },
         callbacks: {
            confirm: () => {
               resetCallback(desktop);
            }
         }
      });
   };

   private _restartDesktop = (desktop) => {
      let restartCallback;

      restartCallback = (desktop) => {
         this.jscdkCommonInvoker.restart(desktop.id);
      };

      this.modalDialogService.showConfirm({
         data: {
            titleKey: "dialog_title_restart",
            contentKey: "dialog_confirm_restart"
         },
         callbacks: {
            confirm: () => {
               restartCallback(desktop);
            }
         }
      });
   };

   private _launchApplication = (appInfo) => {
      /*jshint validthis: true*/
      const application = appInfo.application;

      if (!application) {
         Logger.error("Can't get the application data.");
         return;
      }

      if (application) {
         // Store current appSession to persistent storage
         this._storeLaunchingItemInfo(
            {
               id: "",
               name: ""
            },
            {
               appId: application.id,
               originId: application["origin-id"]
            }
         );
         this.jscdkCommonInvoker.launchApplication(appInfo);
      }
   };

   private _launchApplicationSession = (appSessionInfo) => {
      /*jshint validthis: true*/
      const appSession = appSessionInfo.applicationSession;

      if (!appSession) {
         Logger.error("Can't get the appSession data.");
         return;
      }

      const sessionIdKey = "id";
      if (appSession && appSession[sessionIdKey]) {
         Logger.info("reconnecting app session " + appSession.id);
         this.jscdkCommonInvoker.reconnectApplicationSessions([appSession[sessionIdKey]], "");
      }
   };

   public _desktopsMap = {};
   public _desktops = [];
   public _applications = [];
   private _resetSAMLartListener = null;

   public registerResetSamlListener = () => {
      if (!this._resetSAMLartListener) {
         Logger.debug("Set reset saml listener for launchItem.");
         this._resetSAMLartListener = this.eventBusService.listen(BusEvent.DiscardAuthInfo.MSG_TYPE).subscribe(() => {
            this.jscdkCommonInvoker.resetSAMLart();
            this._resetSAMLartListener.unsubscribe();
            this._resetSAMLartListener = null;
            Logger.debug("Clear reset saml listener for launchItem.");
         });
      }
   };

   private getOperations = () => {
      return {
         launchItem: (targetInfo, channelOptions) => {
            if (!targetInfo) {
               Logger.error("missing targetInfo when launch items");
               return;
            }
            Logger.debug("launch items with targetInfo of " + JSON.stringify(targetInfo));
            if (targetInfo.SAMLart) {
               this.jscdkCommonInvoker.setSAMLart(targetInfo.SAMLart);
               this.registerResetSamlListener();
            } else if (channelOptions && channelOptions.authSecret) {
               // set encoded text to server to decode
               this.sdkService.setAuthSecret(channelOptions.authSecret);
            }
            this.sessionManagementCenterManager.clearIdleTimeoutDialog();
            this.operationService.checkForLaunching(
               this._desktops,
               this._applications,
               this.jscdkCommonInvoker["applicationSessionArray"],
               targetInfo,
               false
            );
         }
      };
   };

   private setActiveClient(
      identityId: string,
      serverAddress: string,
      operations: any,
      clientWindow: any,
      type: string,
      isReusable: boolean,
      xmlServerURL: string,
      userName: string,
      userDomain: string
   ) {
      if (!!window.chromeClient && typeof window.chromeClient.setActiveClient === "function") {
         if (clientWindow && serverAddress && xmlServerURL) {
            window.chromeClient.setActiveClient({
               identityId: identityId,
               serverAddress: serverAddress,
               xmlServerURL: xmlServerURL,
               isReusable: isReusable,
               operations: operations,
               client: clientWindow,
               userName: userName,
               userDomain: userDomain,
               type: type
            });
            Logger.info("reusable client registered for " + type);
         } else {
            Logger.info("failed to register reusable client for " + type);
         }
      } else {
         Logger.info("setActiveClient is not a function.");
      }
   }

   // public functions
   // Render launch items list.
   public renderLaunchItems = (data) => {
      let desktops,
         desktop,
         desktopKey,
         shadowSessions,
         shadowSession,
         shadowSessionKey,
         applications,
         application,
         applicationKey,
         applicationSessions,
         applicationSessionKey;
      this.jscdkCommonInvoker["applicationSessionArray"] = [];

      this._desktops = [];
      this._applications = [];
      this._desktopsMap = {};

      // this implementation can't support quick calls, would need buffer for action to allow each done.
      try {
         let isClientReusable = false;
         let identityId = undefined;
         let operations = {};
         let launchType = "default";
         if (this.connectionURIModel.params && this.connectionURIModel.params.horizonId) {
            identityId = this.connectionURIModel.params.horizonId;
            if (identityId) {
               operations = this.getOperations();
               isClientReusable = true;
               launchType = "WS1";
            }
         } else if (this.sdkService && this.sdkService.serverId) {
            const serverId = this.sdkService.serverId;
            if (serverId) {
               operations = this.getOperations();
               isClientReusable = true;
               launchType = "SDK";
               identityId = serverId;
            }
         } else {
            operations = this.getOperations();
            isClientReusable = true;
         }
         const clientWindow = chrome.app.window.current();
         const serverAddress = this.connectionServerModel.host;

         this.setActiveClient(
            identityId,
            serverAddress,
            operations,
            clientWindow,
            launchType,
            isClientReusable,
            this.connectionServerModel.getXMLServerURL(),
            this.connectionURIModel.getUserName(),
            this.connectionURIModel.getUserDomain()
         );
      } catch (e) {
         Logger.info("failed to register reusable client");
      }

      if (!!data && !!data.content) {
         desktops = data.content.desktops;
         for (desktopKey in desktops) {
            if (desktops.hasOwnProperty(desktopKey)) {
               desktop = desktops[desktopKey];

               if (desktop["protocol-match"] === "false") {
                  // Don't show this desktop because its display
                  // protocol is not allowed.
                  continue;
               }

               $.extend(desktop, {
                  isShadow: false,
                  resetEnabled: false,
                  restartEnabled: false,
                  logoffEnabled: false,
                  highlight: false,
                  isDesktop: true
               });

               if (desktop.canLogoff === "true" && desktop.state !== "") {
                  desktop.logoffEnabled = true;
               }

               if (desktop["reset-allowed"] === "true" || desktop["reset-allowed-on-session"] === "true") {
                  desktop.resetEnabled = true;
               }

               if (
                  (desktop["reset-allowed"] === "true" || desktop["reset-allowed-on-session"] === "true") &&
                  util.brokerSupportRestartDesktop()
               ) {
                  desktop.restartEnabled = true;
               }

               this._desktops.push(desktop);
               this._desktopsMap[desktop.id] = desktop;
            }
         }

         shadowSessions = data.content.shadowSessions;
         for (shadowSessionKey in shadowSessions) {
            if (shadowSessions.hasOwnProperty(shadowSessionKey)) {
               shadowSession = shadowSessions[shadowSessionKey];
               // We treat all shadow sessions as desktop sessions.
               if (shadowSession["protocol-match"] === "false") {
                  continue;
               }

               $.extend(shadowSession, {
                  isShadow: true,
                  resetEnabled: false,
                  restartEnabled: false,
                  logoffEnabled: false,
                  highlight: false
               });

               this._desktops.push(shadowSession);
            }
         }

         applications = data.content.applications;

         this.multisessionSingleUserService.setApps(applications);
         for (applicationKey in applications) {
            if (applications.hasOwnProperty(applicationKey)) {
               application = applications[applicationKey];

               if (application["protocol-match"] === "false") {
                  // Don't show this application because its
                  // display protocol is not allowed.
                  continue;
               }

               $.extend(application, {
                  resetEnabled: false,
                  logoffEnabled: false,
                  highlight: false
               });
               application.icon = application.icons[this.utilService.getLargestIcon(application.icons)];
               this._applications.push(application);
            }
         }
         /**
          * Store the application-sessions data
          * If we want to support more action, or on HTML Access,
          * extra sync logic is needed here
          *
          * Also we could merge the 2 arrays in later patch.
          */
         applicationSessions = data.content.applicationSessions;
         for (applicationSessionKey in applicationSessions) {
            if (applicationSessions.hasOwnProperty(applicationSessionKey)) {
               const applicationSession = applicationSessions[applicationSessionKey];
               this.jscdkCommonInvoker["applicationSessionArray"].push(applicationSession);
            }
         }
         const targetActionInfo = this.connectionURIModel.params;
         const shouldClearPreviousAction = this.needToClearAction();
         // keep using the array in jscdkCommonInvoker to avoid duplicated array.
         this.operationService.checkForLaunching(
            this._desktops,
            this._applications,
            this.jscdkCommonInvoker["applicationSessionArray"],
            targetActionInfo,
            shouldClearPreviousAction
         );
      }

      if (this.sessionManagementCenterManager) {
         this.sessionManagementCenterManager.setDesktopPropertyGetter((itemId) => {
            const desktop = this._desktopsMap[itemId];
            return desktop;
         });
      }
   };

   public needToClearAction = () => {
      /** TODO: this async design will cause bug.
       *  The shouldClearPreviousAction will not be set true as
       * expected. To solve this bug, the whole function will be
       * redesigned.
       */
      if (this.connectionURIModel.reuseSession) {
         let shouldClearPreviousAction = false;
         this.connectionURIModel.isIdenticalUser(this.connectionServerModel.host, (isIdentical) => {
            if (!isIdentical) {
               /**
                * Current URI connection has different connection
                * info from that of the session. Confirm with the
                * user whether to reuse the present session.
                */
               this.connectionURIModel.reuseSession = false;
               shouldClearPreviousAction = true;
               /*
                * If it's a HWS session, logout connection server immediately.
                * Otherwise, confirm with the user whether to logout or not.
                */
               if (this.connectionURIModel.isHWSession()) {
                  // Logout desktop, but with uri info in jscdk and
                  // UI reserved.
                  this.jscdkCommonInvoker.logout(false);
                  this.viewService.logoutToPortal = false;
               } else {
                  // always overwrite id under current interface of dialogService.
                  this._itemCtrl.switchUserDialog = this.modalDialogService.showConfirm({
                     data: {
                        titleKey: "dialog_title_logout",
                        contentKey: "dialog_confirm_logout"
                     },
                     callbacks: {
                        confirm: () => {
                           // Logout desktop, but with uri info in
                           // jscdk and UI reserved.
                           this.jscdkCommonInvoker.logout(false);
                           this.viewService.logoutToPortal = false;
                        }
                     }
                  });
               }
            } else {
               // if is identical user, update connecting info accordingly
               if (this.connectionURIModel.isHWSession()) {
                  /**
                   * only for HWS, should not move this line out of
                   * the "if" condition, or the normal URL will
                   * have a corner regression
                   */
                  this.connectionServerModel.loadFromURIModel(this.connectionURIModel);
               }
            }
         });
         if (shouldClearPreviousAction) {
            return true;
         }
      } else {
         /**
          * When first time launched by vIDM(workspace),
          * connectionServerModel should be updated as well. Otherwise
          * nothing could be stored in cookie. When next time launched by
          * vIDM with a different user, client wouldn't be able to realize
          * it is a different session. See bug 1766419
          *
          */
         if (this.connectionURIModel.isHWSession()) {
            this.connectionServerModel.loadFromURIModel(this.connectionURIModel);
         }
      }
      return false;
   };

   public launchDesktop = (desktop) => {
      if (!util.brokerSupportApplication()) {
         this._launchDesktop(desktop);
      } else {
         this.utilService.authenticationStatusCheck.callBackWhenUnlocked(
            this._launchDesktop,
            desktop,
            "start-session",
            "desktop"
         );
      }
   };

   public logoffDesktop = (desktop) => {
      if (!util.brokerSupportApplication()) {
         this._logoffDesktop(desktop);
      } else {
         this.utilService.authenticationStatusCheck.callBackWhenUnlocked(
            this._logoffDesktop,
            desktop,
            "logoff",
            "desktop",
            true
         );
      }
   };

   public resetDesktop = (desktop) => {
      if (!util.brokerSupportApplication()) {
         this._resetDesktop(desktop);
      } else {
         this.utilService.authenticationStatusCheck.callBackWhenUnlocked(
            this._resetDesktop,
            desktop,
            "reset",
            "desktop"
         );
      }
   };

   public restartDesktop = (desktop) => {
      this.utilService.authenticationStatusCheck.callBackWhenUnlocked(
         this._restartDesktop,
         desktop,
         "restart",
         "desktop"
      );
   };

   public highlightItems = (item, event = null, ctrl = null) => {
      if (ctrl) {
         ctrl.activeItem = item;
      }
      if (event) {
         ctrl.focusElement = event.target;
      }
   };

   public launchApplication = (application, args) => {
      if (!util.brokerSupportApplication()) {
         Logger.error("application is not supported by the broker");
      } else {
         this.utilService.authenticationStatusCheck.callBackWhenUnlocked(
            this._launchApplication,
            {
               application: application,
               args: args
            },
            "start-session",
            "application"
         );
      }
   };

   public launchApplicationSession = (applicationSession) => {
      if (!util.brokerSupportApplication()) {
         Logger.error("application is not supported by the broker");
      } else {
         this.utilService.authenticationStatusCheck.callBackWhenUnlocked(
            this._launchApplicationSession,
            {
               applicationSession: applicationSession
            },
            "start-session",
            "applicationSession"
         );
      }
   };

   public setItemController = (itemCtrl: IItemController) => {
      this._itemCtrl = itemCtrl;
      this.operationService.setItemController(itemCtrl);
   };

   public getDesktops = () => {
      return this._desktops;
   };

   public getApplications = () => {
      return this._applications;
   };
}
