/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { Component, Input, ChangeDetectorRef, Optional } from "@angular/core";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { UtilService } from "../../launcher/common/util-service";
import { BusEvent, clientUtil, EventBusService, Logger, VmwHorizonClientAuthType } from "@html-core";
import { Ws1Service } from "../../common/service/ws1.service";

import { RootModel } from "../../common/model/root-model";
import { CommonSDKService } from "../../common/service/sdk.service";
import { Subscription } from "rxjs";

/**
 * TODO-NG: add event for accepting disclaimer, who should be used
 * to invoke JSCDK and .
 * Could also be helpful when refactor
 */
interface disclaimerData {
   content: {
      label: string;
   };
}
@Component({
   selector: "login-disclaimer",
   templateUrl: "./disclaimer.component.html"
})
export class LoginDisclaimerComponent extends BaseViewComponent implements BaseViewComponentInterface {
   @Input() fromData;
   public disclaimerText: string;
   public cancelLoading: boolean;
   private requestDisclaimerSubscription: Subscription = null;

   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private utilService: UtilService,
      private ws1Service: Ws1Service,
      private rootModel: RootModel,
      @Optional()
      private sdkService: CommonSDKService,
      private eventBusService: EventBusService
   ) {
      super(changeDetector, "Disclaimer");
      this.cancelLoading = false;
      if (clientUtil.isChromeClient()) {
         const waitTimer = setInterval(() => {
            clearInterval(waitTimer);
            if (this.ws1Service?.isWS1Mode() === true || this.sdkService?.isHideClientEnabled() === true) {
               clientUtil.showClientForWS1orSDK("Accepting disclaimer.");
            }
         }, 1000);
      }

      this.eventBusService.listen(BusEvent.DoDisclaimerSubmit.MSG_TYPE).subscribe(() => {
         this._readSDKDisclaimerExecutor();
         this.requestDisclaimerSubscription?.unsubscribe();
         this.requestDisclaimerSubscription = null;
      });
   }

   ngAfterViewInit() {
      this._readSDKDisclaimerExecutor();
   }

   public _readSDKDisclaimerExecutor = () => {
      if (!this.sdkService) {
         Logger.trace("no SDK service found for auth");
         return;
      }

      if (!this.sdkService.hasDisclaimerAccepted()) {
         Logger.trace("no disclaimer accepted found in SDK service for auth");
         return;
      }

      setTimeout(() => {
         if (this.sdkService.getDisclaimerAccepted()) {
            Logger.info("accept disclaimer from SDK", Logger.SDK);
            this.accept();
         } else {
            Logger.info("reject disclaimer from SDK", Logger.SDK);
            this.cancel();
         }
      }, 20);
   };

   public renderData = () => {
      const disclaimerData: disclaimerData = this.data;
      this.onAuthScreen(this.componentName, disclaimerData);
      const message = disclaimerData.content.label;
      // Normalize pre-login message that each newline starts with
      // '\n'.
      this.disclaimerText = message.replace(/(\r\n|\r|\n)/g, "\n");

      this.eventBusService.dispatch(new BusEvent.DisclaimerDisplayed({ disclaimerText: this.disclaimerText }));
      if (!this.requestDisclaimerSubscription) {
         this.requestDisclaimerSubscription = this.eventBusService
            .listen(BusEvent.RequestDisclaimer.MSG_TYPE)
            .subscribe(() => {
               this.eventBusService.dispatch(new BusEvent.DisclaimerDisplayed({ disclaimerText: this.disclaimerText }));
            });
      }
   };

   public accept = () => {
      this.cancelLoading = false;
      this.jscdkCommonInvoker.acceptDisclaimer();
      if (this.ws1Service?.isWS1Mode() === true || this.sdkService?.isHideClientEnabled() === true) {
         clientUtil.hideClientForWS1orSDK("Accepting disclaimer.");
      }
   };

   public cancel = () => {
      this.cancelLoading = true;
      this.rootModel.set("partialAuthedWithSAML", false);
      this.utilService.runFunctionIfNotHWSOrF5(() => {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"Disclaimer");
      });
   };
}
