/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * jscdk-error.ts --
 *
 * A base class defining JSCDK errors,
 * each error is defined by an error codes and the associated error string.
 *
 */

export default function JSCDKError(errorCode, errorMessage) {
   this.code = errorCode;
   this.message = errorMessage;
}

JSCDKError.prototype.getErrorCode = function () {
   return this.code;
};

JSCDKError.prototype.getErrorMessage = function () {
   return this.message;
};

JSCDKError.prototype.toString = function () {
   return "Error Code: " + this.code + ", Error Message: " + this.message;
};
