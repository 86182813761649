/**
 * ******************************************************
 * Copyright (C) 2018-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * trusted-apps-model.js
 *
 * get/set trusted apps list from/to storage
 *
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";

export interface TrustedAppEntry {
   address: string;
   accepted: boolean;
}

@Injectable({
   providedIn: "root"
})
export class TrustedAppsModel {
   private trustedAppInfo: Array<TrustedAppEntry>;
   private static readonly SETTING_NAME = "TRUST_LIST";

   constructor() {
      this.trustedAppInfo = new Array<TrustedAppEntry>();
   }

   private _getKeyName = () => {
      return TrustedAppsModel.SETTING_NAME;
   };

   private _readStorage = (key: string) => {
      return new Promise((resolve, reject) => {
         try {
            chrome.storage.local.get(key, (obj) => {
               if (chrome.runtime.lastError) {
                  Logger.error("chrome.runtime.lastError = " + chrome.runtime.lastError);
                  resolve(undefined);
                  return;
               }
               if (!obj) {
                  resolve(undefined);
                  return;
               }
               if (!obj[key]) {
                  resolve(undefined);
               } else {
                  resolve(obj[key]);
               }
            });
         } catch (e) {
            Logger.exception(e);
            resolve(undefined);
         }
      });
   };

   /**
    * Should catch exception if call this function
    */
   private _setInfo = (data) => {
      return new Promise((resolve, reject) => {
         try {
            const obj = {};
            obj[this._getKeyName()] = data;
            chrome.storage.local.set(obj, () => {
               if (chrome.runtime.lastError) {
                  const error = chrome.runtime.lastError;
                  reject(error);
                  return;
               }
               // @ts-ignore
               resolve();
            });
         } catch (e) {
            reject(e);
         }
      });
   };

   private _loadTrustedAppsInfo = (infos) => {
      infos.forEach((info) => {
         this.trustedAppInfo.push(info);
      });
   };

   public save = async () => {
      try {
         await this._setInfo(this.trustedAppInfo);
      } catch (e) {
         Logger.exception(e);
      }
   };

   public get = async () => {
      const trustedApps = await this._readStorage(this._getKeyName());
      if (trustedApps) {
         this._loadTrustedAppsInfo(trustedApps);
      }
      return this.trustedAppInfo;
   };

   public set = (trustedApps: Array<TrustedAppEntry>) => {
      this.setEmpty();
      for (const entry of trustedApps) {
         this.trustedAppInfo.push(entry);
      }
   };

   public setEmpty = () => {
      this.trustedAppInfo = new Array<TrustedAppEntry>();
   };
}
