/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { TabUtilsService } from "./tabUtil.service";
import { WarningTextEnum, PostMessageEventNamesEnum } from "./tabEnum";
import { Injectable } from "@angular/core";
@Injectable()
export class PostMessageListenerService {
   public newlyTabOpened;
   constructor(private tabUtils: TabUtilsService) {}

   private _onLoad = (data: string) => {
      const tabInfo = data.split(PostMessageEventNamesEnum.LOADED)[1];
      if (tabInfo) {
         try {
            const parsedTabInfo = JSON.parse(tabInfo);
            if (parsedTabInfo.id) {
               const tabs = this.tabUtils.getAll();
               if (tabs.length) {
                  this.newlyTabOpened = tabs[tabs.length - 1];
                  this.newlyTabOpened.id = parsedTabInfo.id;
                  this.newlyTabOpened.name = parsedTabInfo.name || parsedTabInfo.windowName;
               }
            }
         } catch (e) {
            throw new Error(WarningTextEnum.INVALID_JSON);
         }
      }

      if (this.newlyTabOpened) {
         try {
            const dataToSend =
               PostMessageEventNamesEnum.HANDSHAKE_WITH_PARENT +
               JSON.stringify({
                  id: this.newlyTabOpened.id,
                  name: this.newlyTabOpened.name || this.newlyTabOpened.windowName,
                  parentName: window.name
               });
            //@ts-ignore
            this.tabUtils.sendMessage(this.newlyTabOpened, dataToSend);
         } catch (e) {
            throw new Error(WarningTextEnum.INVALID_JSON);
         }
      }
   };

   private _onCustomMessage = (data: string, type: string) => {
      const tabInfo = data.split(type)[1];
      const eventData = {
         tabInfo,
         type
      };

      const event = new CustomEvent("onCustomChildMessage", { detail: eventData });
      window.dispatchEvent(event);
   };

   private _onBeforeUnload = (data: string) => {
      const tabInfo = data.split(PostMessageEventNamesEnum.ON_BEFORE_UNLOAD)[1];
      let parsedTabInfo;
      try {
         parsedTabInfo = JSON.parse(tabInfo);
      } catch (e) {
         throw new Error(WarningTextEnum.INVALID_JSON);
      }

      const tabs = this.tabUtils.getAll();
      this.newlyTabOpened = this.tabUtils.searchByKeyName(tabs, "id", parsedTabInfo.id) || this.newlyTabOpened;
      const event = new CustomEvent("onChildUnload", { detail: parsedTabInfo });

      window.dispatchEvent(event);
   };

   public onNewTab = (message: MessageEvent) => {
      const data = message.data;
      if (!data || typeof data !== "string" || !this.tabUtils.tabs.length) {
         return false;
      }
      if (this.tabUtils.config.origin && this.tabUtils.config.origin !== message.origin) {
         return false;
      }

      if (data.indexOf(PostMessageEventNamesEnum.LOADED) > -1) {
         this._onLoad(data);
      } else if (data.indexOf(PostMessageEventNamesEnum.CUSTOM) > -1) {
         this._onCustomMessage(data, PostMessageEventNamesEnum.CUSTOM);
      } else if (data.indexOf(PostMessageEventNamesEnum.HANDSHAKE) > -1) {
         this._onCustomMessage(data, PostMessageEventNamesEnum.HANDSHAKE);
      } else if (data.indexOf(PostMessageEventNamesEnum.ON_BEFORE_UNLOAD) > -1) {
         this._onBeforeUnload(data);
      }
   };
}
