<div class='base-panel clipboard-panel' (click)="focusInput()" [hidden]="!showClipboardPanel">

   <div [ngClass]="{'clipboard-focused': focused, 'clipboard-unfocused': !focused}">
      <div class="clipboard-input-container">
         <label for="clipboard-input">clipboard-input</label>
         <input id="clipboardInput" (copy)="clipboardCopy($event)" (paste)="clipboardPaste($event)" (focus)="clipboardFocus()" (blur)="clipboardBlur()"/>
      </div>
      <div class="clipboard-header clipboard-handle">
         <div class="clipboard-label unselectable">{{'COPY_PASTE' | i18nv2}}</div>
         <div class="clipboard-icon close" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? toggleClipboardPanel($event) : null" (click)="toggleClipboardPanel($event)" title="{{'CLOSE_CLIPBOARD_T' | i18nv2}}" tabindex="0" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]= "'CLOSE_PANEL_BUTTON' | i18nv2"></div>
         <div id="minimize" class="clipboard-icon minimize" *ngIf="showClipboardText" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? toggleClipboardText() : null" (click)="toggleClipboardText()" title="{{'MINIMIZE_CLIPBOARD_T' | i18nv2}}" tabindex="0" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]= "'MINIMIZE_PANEL_BUTTON' | i18nv2"></div>
         <div id="restore" class="clipboard-icon restore" *ngIf="!showClipboardText" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? toggleClipboardText() : null" (click)="toggleClipboardText()" title="{{'RESTORE_CLIPBOARD_T' | i18nv2}}" tabindex="0" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]= "'RESTORE_PANEL_BUTTON' | i18nv2"></div>
         <div class="clipboard-icon help" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? openClipboardHelp() : null" (click)="openClipboardHelp()" title="{{'CLIPBOARD_HELP_T' | i18nv2}}" tabindex="0" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]= "'HELP_BUTTON' | i18nv2"></div>
      </div>
      <div class='clipboard-content' (focus)="focusInput()" [hidden]="!showClipboardText">
         <div class="clipboard-area clipboard-handle unselectable" [ngClass]="{'inactive': !hasClipboardData, 'focused': hasClipboardData && focused}" [hidden]="!showClipboardText">
            <span id="clipboard-text-element" *ngIf="hasClipboardData" class="text-display">{{clipboard.get("text")}}</span>
            <span id="clipboard-policy-text-element" *ngIf="!hasClipboardData" class="text-display">{{clipboardPolicy.get("text")}}</span>
         </div>
      </div>
      <div class="clipboard-footer clipboard-handle">
         <span class="clipboard-clear" (keypress)="[32, 13].indexOf($event.keyCode) > -1 ? clipboardClear() : null" (click)="clipboardClear()" [hidden]="!(hasClipboardData && showClipboardText)" tabindex="0" title="{{'CLEAR_TEXT_BUTTON' | i18nv2}}" [attr.role]="'BUTTON' | i18nv2" [attr.aria-label]= "'CLEAR_TEXT_BUTTON' | i18nv2">{{'CLEAR_CLIPBOARD_T' | i18nv2}}</span>
      </div>
   </div>

   <panel-notification></panel-notification>
</div>
