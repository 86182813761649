/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { ClientSettingModel } from "../model/client-setting-model";
import Logger from "../../../core/libs/logger";
import { ModalDialogService } from "../commondialog/dialog.service";

@Injectable()
export class GeolocationNotification {
   constructor(
      private clientSettingModel: ClientSettingModel,
      private modalDialogService: ModalDialogService
   ) {}

   public showGeolocationPermissionPopup = (): Promise<void> => {
      return new Promise((resolve, reject) => {
         const option = {
            data: {
               titleKey: "GEOPERMISSION_POPUP_TITLE",
               contentKey: "GEOPERMISSION_SUB_MENU",
               buttonLabelConfirmKey: "GEOPERMISSION_POPUP_ALLOW",
               buttonLabelCancelKey: "GEOPERMISSION_POPUP_DENY",
               checkboxTitleKey: "DO_NOT_SHOW_THIS_DIALOG_AGAIN",
               isCheckBoxCheckedKey: "true"
            },
            callbacks: {
               confirm: () => {
                  this.setGeolocationSharingEnabled(true);
                  Logger.info("Geolocation access allowed from confimation popup.", Logger.GEOLOCATION);
                  resolve();
               },
               cancel: () => {
                  this.setGeolocationSharingEnabled(false);
                  Logger.info("Geolocation access denied from confimation popup.", Logger.GEOLOCATION);
                  reject();
               },
               setCheckBoxValue: (enabled: boolean) => {
                  this.setDonotShowGeolocationDialog(enabled);
                  Logger.info(
                     "Setting do not show this dialog again for geolocation to " + enabled,
                     Logger.GEOLOCATION
                  );
               }
            }
         };
         Logger.info("Show geo location permission pop-up", Logger.GEOLOCATION);
         this.modalDialogService.showCancelConfirmWithCheckBox(option);
      });
   };

   public setDonotShowGeolocationDialog = (enabled: boolean): void => {
      this.clientSettingModel.updateSetting("donotShowGeolocationDialog", enabled ? "true" : "false", true);
      this.clientSettingModel.syncSetting(["donotShowGeolocationDialog"]);
   };

   public setGeolocationSharingEnabled = (enabled: boolean): void => {
      this.clientSettingModel.updateSetting("enableGeolocationSharing", enabled ? "true" : "false", true);
      this.clientSettingModel.syncSetting(["enableGeolocationSharing"]);
      this.clientSettingModel.saveSetting();
   };
}
