/**
 * *****************************************************
 * Copyright 2022-2023 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import * as CST from "@html-core";
import { EventBusService, Logger } from "@html-core";
import { CDRService } from "../../../shared/desktop/cdr/cdr-service";
import { ConnectionServerModel } from "../../../shared/common/model/connection-server-model";
import { FileSystemService } from "../../../shared/desktop/cdr/cdr-lib/file-system-service";
import { FileHandlerCacheManagerService } from "../../../shared/desktop/cdr/file-handler-cache-manager.service";
import { HtmlRemoteSessionManager } from "../../common/remote-session/html-remote-session-manager";
import { UserInfoService } from "../../../shared/common/service/user-info.service";

@Injectable()
export class HtmlCDRService {
   private directoryHandleList = [];
   private permissionRequestList = [];
   private reload = null;
   private currentCanvas;

   constructor(
      private cdrService: CDRService,
      private connectionServerModel: ConnectionServerModel,
      private userInfoService: UserInfoService,
      private fileSystemService: FileSystemService,
      private fileHandlerCacheManagerService: FileHandlerCacheManagerService,
      private eventBusService: EventBusService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager
   ) {
      this.reload = (event) => {
         if (this.fileHandlerCacheManagerService.fileHandlers.length > 0) {
            this.fileHandlerCacheManagerService.releaseAllWriteHandlers();
            window.removeEventListener("beforeunload", this.reload);
            event.preventDefault();
         } else {
            return null;
         }
      };
      this.eventBusService.listen("fileIsWriting").subscribe(() => {
         window.addEventListener("beforeunload", this.reload);
      });
   }

   public onWmksSessionConnecting = (wmksKey, vdpService) => {
      Logger.info("start CDR for HTML5 CDR Folder");
      this.cdrService.onConnecting(wmksKey, vdpService);
   };

   private _verifyPermission = async () => {
      if (this.directoryHandleList.length === 0) {
         await this.restoreEntryFromDB();
      }
      for (let i = 0; i < this.directoryHandleList.length; i++) {
         const permission = await this.directoryHandleList[i].queryPermission({ mode: "readwrite" });
         if (permission === "prompt") {
            this.permissionRequestList.push(this.directoryHandleList[i]);
         }
      }
      for (let i = 0; i < this.permissionRequestList.length; i++) {
         try {
            const permission = await this.permissionRequestList[i].requestPermission({ mode: "readwrite" });
            Logger.info("Folder's permission is " + permission);
         } catch (e) {
            Logger.exception(e);
         }
      }
      this.permissionRequestList = [];
   };

   public onWmksSessionDisconnected = (wmksKey) => {
      this.cdrService.onDisconnected(wmksKey);
      if (this.cdrService.isTsdrChannelDisconnected()) {
         this.fileHandlerCacheManagerService.releaseAllWriteHandlers();
         window.removeEventListener("beforeunload", this.reload);
      }
   };

   public getDirectoryHandlers = () => {
      return this.directoryHandleList;
   };

   public addDirectoryHandlers = async (handler) => {
      const isDuplicated = await this.isDuplicated(handler);
      if (!isDuplicated) {
         this.directoryHandleList.push(handler);
      }
   };

   public deleteDirectoryHandler = async (folder) => {
      if (!folder) {
         return;
      }
      for (let i = 0; i < this.directoryHandleList.length; i++) {
         const isSame = await this.directoryHandleList[i].resolve(folder);
         if (isSame) {
            this.directoryHandleList.splice(i, 1);
            break;
         }
      }
   };

   private isDuplicated = async (handler) => {
      for (let i = 0; i < this.directoryHandleList.length; i++) {
         const isSame = await this.directoryHandleList[i].isSameEntry(handler);
         if (isSame) {
            return true;
         }
      }
      return false;
   };

   public restoreEntries = async (entry) => {
      const handler = entry.directoryHandler;
      if (handler) {
         this.directoryHandleList.push(handler);
      }
   };

   private getFolderNameByUser = () => {
      let username = this.connectionServerModel.username;
      if (!username) {
         // Read user name from cookie
         username = this.userInfoService.getUserName();
         if (!username) {
            // If username is not available, use token username instead.
            username = this.connectionServerModel.tokenUsername;
         }
         // Cache user name in the server model.
         this.connectionServerModel.username = username;
      }
      username = CST.UsernameUtil.getUsername(username);
      Logger.info("username name: " + username);
      return username + "sharefolder";
   };

   public restoreEntryFromDB = async () => {
      const name = this.getFolderNameByUser();
      const entries = await this.fileSystemService.getDirectoryEntryFromStorage(name);
      //@ts-ignore
      entries.forEach((entry) => {
         this.directoryHandleList.push(entry.entry.directoryHandler);
      });
   };

   public checkEntryExist = async (entry) => {
      const handler = entry.directoryHandler;
      if (handler.kind === "directory") {
         for (let i = 0; i < this.directoryHandleList.length; i++) {
            const isChild = await this.directoryHandleList[i].resolve(handler);
            if (isChild) {
               if (isChild.length > 0) {
                  Logger.info("Entry is a child entry of " + this.directoryHandleList[i].name);
                  return "child";
               } else if (isChild.length === 0) {
                  Logger.info("A duplicated entry of existed one");
                  return "duplicated";
               }
            } else {
               Logger.debug("Not a child entry of existed one");
               const isParent = await handler.resolve(this.directoryHandleList[i]);
               if (isParent && isParent.length > 0) {
                  Logger.info("Entry is a parent entry of " + this.directoryHandleList[i].name);
                  return "parent";
               } else {
                  Logger.debug("Not a parent entry of existed one");
               }
            }
         }
         Logger.info("This entry didn't existed yet.");
         this.directoryHandleList.push(handler);
         return false;
      }
   };
}
