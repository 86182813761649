<!-- @format -->

<div class="modal-title dialog-title">{{ "CHOOSE_DEVICE_ADVANCED_T" | i18nv2 }}</div>
<div class="modal-body dialog-content" fuzzy-free>
   <div class="rtav-settings-box">
      <div *ngIf="isChromeClient" class="rtav-msg">
         {{ "CHOOSE_DEVICE_TAKE_EFFECT_NEXT_CONNECTION_M" | i18nv2 }}
      </div>
      <div id="setting-rtav-audio-in">
         <div *ngIf="!hasAudioPermission" class="rtav-permission-msg">
            {{ "CHOOSE_DEVICE_AUDIO_PERMISSION_M" | i18nv2 }}
         </div>
         <div class="rtav-device-label-list">
            <span class="rtav-label">{{ "CHOOSE_DEVICE_AUDIO_M" | i18nv2 }}</span>
            <select
               id="setting-rtav-audio-in-select"
               [ngModel]="audioSelection"
               (change)="audioSelectChange($event)"
               role="listbox"
               class="rtav-device"
               [disabled]="!hasAudioPermission || !audioList.length"
            >
               <option *ngIf="!audioList.length" value="{{ noDeviceMessage }}">{{ noDeviceMessage }}</option>
               <option *ngFor="let device of audioList" value="{{ device.value }}">
                  {{ device.name }}
               </option>
            </select>
         </div>
      </div>
      <div id="setting-rtav-audio-out" *ngIf="supportAudioOutAndResolution" class="rtav-device-label-list">
         <span class="rtav-label">{{ "CHOOSE_DEVICE_AUDIO_OUT_M" | i18nv2 }}</span>
         <select
            id="setting-rtav-audio-out-select"
            [ngModel]="audioOutSelection"
            (change)="audioOutSelectChange($event)"
            role="listbox"
            class="rtav-device"
            [disabled]="!audioOutList.length"
         >
            <option *ngIf="!audioOutList.length" value="{{ noDeviceMessage }}">{{ noDeviceMessage }}</option>
            <option *ngFor="let device of audioOutList" value="{{ device.value }}">
               {{ device.name }}
            </option>
         </select>
      </div>
      <div id="setting-rtav-video-in">
         <div *ngIf="!hasVideoPermission" class="rtav-permission-msg">
            {{ "CHOOSE_DEVICE_VIDEO_PERMISSION_M" | i18nv2 }}
         </div>
         <div class="rtav-device-label-list">
            <span class="rtav-label">{{ "CHOOSE_DEVICE_VIDEO_M" | i18nv2 }}</span>
            <select
               id="setting-rtav-video-in-select"
               [ngModel]="videoSelection"
               (change)="videoSelectChange($event)"
               role="listbox"
               class="rtav-device"
               [disabled]="!hasVideoPermission || !videoList.length"
            >
               <option *ngIf="!videoList.length" value="{{ noDeviceMessage }}">{{ noDeviceMessage }}</option>
               <option *ngFor="let device of videoList" value="{{ device.value }}">
                  {{ device.name }}
               </option>
            </select>
         </div>
      </div>
      <div
         id="setting-rtav-video-resolution"
         *ngIf="supportAudioOutAndResolution && supportV2Features"
         class="rtav-device-label-list"
      >
         <span class="rtav-label">{{ "CHOOSE_DEVICE_VIDEO_RESOLUTION_M" | i18nv2 }}</span>
         <select
            id="setting-rtav-video-resolution-select"
            [ngModel]="videoResolutionSelection"
            (change)="videoResolutionSelectChange($event)"
            role="listbox"
            class="rtav-device"
            [disabled]="!hasVideoPermission || !videoResolutionList.length"
         >
            <option *ngFor="let res of videoResolutionList" value="{{ res.value }}">
               {{ res.name }}
            </option>
         </select>
      </div>
   </div>
   <div class="modal-footer dialog-button-row">
      <button tabindex="0" class="modal-button-base modal-button-right modal-button-blue" (click)="okClicked()">
         {{ "OK" | i18nv2 }}
      </button>
      <button tabindex="0" class="modal-button-base modal-button-right modal-button-blue" (click)="closeModal()">
         {{ "CANCEL" | i18nv2 }}
      </button>
   </div>
</div>
