/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { UsbProtocol } from "../../usb-protocol";
import { RemoteUsbDevice } from "../../usb-remote";
import { PathosisDevice } from "./pathosis-device";

/*
 *    -- ThermalPrinter --
 *
 * NOT follow standard USB function spec in chrome native client
 * 1. Return nothing for GET_DESCRIPTOR_FROM_DEVICE control transfer;
 * 2. reset pipe can't take effort. (Reset device as a workaround)
 *
 */

export class ThermalPrinter extends PathosisDevice {
   private deviceDescriptor: ArrayBuffer = null;
   private deviceDescriptorRaw = [0x12, 0x1, 0, 2, 0, 0, 0, 0x40, 0x90, 0x1d, 0xf4, 0x20, 2, 0, 1, 2, 3, 1];
   constructor(remoteUsb: RemoteUsbDevice) {
      super(remoteUsb);
      this.deviceDescriptor = new Uint8Array(this.deviceDescriptorRaw).buffer;
   }

   public getDeviceDescriptor = (): ArrayBuffer => {
      return this.deviceDescriptor;
   };
   public handleUrbMessage = (vUrb, packet, callback): boolean => {
      if (vUrb.itemType === UsbProtocol.VHUBITEM.Urb) {
         switch (vUrb.UrbHeader.function) {
            case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_DEVICE:
               if (vUrb.cntlDesc.DescriptorType === 1) {
                  this.handleGetDeviceDesp(vUrb, packet, callback);
                  return true;
               }
               break;
            case UsbProtocol.URB_FUNCTION.SYNC_RESET_PIPE_AND_CLEAR_STALL: {
               this.remoteUsb.localDevice.reset();
               const reply = UsbProtocol.constructResetStallReply(vUrb);
               callback(reply);
               return true;
            }
            default:
               return false;
         }
      }
      return false;
   };

   private handleGetDeviceDesp(vUrb, packet, callback) {
      const rawData = new Uint8Array(this.deviceDescriptorRaw);
      const reply = UsbProtocol.constructGetDescriptorReply(vUrb, rawData, packet);
      callback(reply);
   }
}
