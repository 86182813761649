/**
 * ******************************************************
 * Copyright (C) 2023-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { EventBusService, BusEvent, Logger, setOnRampMode, isOnRamp } from "@html-core";
import { JscdkCommonInvoker } from "../jscdk/jscdk-common-invoker";
import { ParentTabService } from "../across-tabs/parent-tab.service";
import { TabUtilsService } from "../across-tabs/utils/tabUtil.service";
import { PostMessageListenerService } from "../across-tabs/utils/postmessage";
import { PostMessageEventNamesEnum, MESSAGETYPE } from "../across-tabs/utils/tabEnum";
@Injectable()
export class OnRampServiceHtml5 {
   public i = 0;
   public onRampUrl: string = "";
   public openTabUrl: string = "";
   public accessToken: string = "";
   public parent;
   public isRequireToken: boolean = false;

   public method = {
      onHandshakeCallback: (data) => {
         Logger.info("onHandshakeCallback" + JSON.stringify(data), Logger.ON_RAMP);
         data.type = data.type || "open";

         this.handleMessage(data);
      },
      onChildDisconnect: (data) => {
         Logger.info("onChildDisconnect" + JSON.stringify(data), Logger.ON_RAMP);
         data.type = "close";
         this.handleMessage(data);
      }
   };

   constructor(
      private eventBusService: EventBusService,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private tabUtilsService: TabUtilsService,
      private postMessageListenerService: PostMessageListenerService
   ) {
      this.init();
      this.eventBusService.listen("logOutSuccess").subscribe(() => {
         const data = {
            id: this.postMessageListenerService.newlyTabOpened.id,
            type: MESSAGETYPE.LOG_OUT
         };
         this.sendMessage(data);
         this.reset();
      });

      this.eventBusService.listen("sessionExpired").subscribe(() => {
         const data = {
            id: this.postMessageListenerService.newlyTabOpened.id,
            type: MESSAGETYPE.SESSION_EXPIRED
         };
         this.sendMessage(data);
         this.reset();
      });
   }

   public init = () => {
      this.eventBusService.listen("SetRampConfig").subscribe((msg: BusEvent.SetRampConfig) => {
         if (msg.content?.url && msg.content?.accessToken) {
            Logger.info("Get the response from setRampConfig", Logger.ON_RAMP);
            if (!this.isRequireToken) {
               this.setConfiguration(msg);
               this.checkToCreateTab(msg);
            } else {
               const dataToSend = {
                  id: this.postMessageListenerService.newlyTabOpened.id,
                  accessToken: msg.content.accessToken,
                  type: MESSAGETYPE.PUSH_TOKEN
               };
               this.sendMessage(dataToSend);
               this.isRequireToken = false;
               Logger.info("send token to child tab and set isRequireToken to false", Logger.ON_RAMP);
            }
         }
      });
      this.initParent();
   };

   public getOpenTab = () => {
      if (this.parent.getOpenedTabs() && this.parent.getOpenedTabs()[0] && this.parent.getOpenedTabs()[0].ref) {
         return this.parent.getOpenedTabs()[0].ref;
      } else {
         return null;
      }
   };

   public handleMessage = (data) => {
      if (data.type === "open") {
         const dataToSend = {
            id: this.postMessageListenerService.newlyTabOpened.id,
            accessToken: this.accessToken,
            type: MESSAGETYPE.PUSH_TOKEN
         };
         Logger.info("send token to child tab", Logger.ON_RAMP);
         this.sendMessage(dataToSend);
      } else if (data.type === MESSAGETYPE.REQUIRE_TOKEN) {
         this.isRequireToken = true;
         Logger.info("require token from child tab", Logger.ON_RAMP);
         this.getOnRampConfig();
      }
   };

   public sendMessage = (data) => {
      const dataToSend = PostMessageEventNamesEnum.PARENT_COMMUNICATED + JSON.stringify(data);
      Logger.debug("parent sendMessage" + dataToSend, Logger.ON_RAMP);
      this.parent.broadCastAll(dataToSend);
   };

   public initParent = () => {
      this.parent = new ParentTabService(this.tabUtilsService, this.postMessageListenerService);
      this.parent.init({
         onHandshakeCallback: this.method.onHandshakeCallback,
         onChildDisconnect: this.method.onChildDisconnect,
         heartBeatInterval: 500
      });
   };

   public setConfiguration = (msg) => {
      this.accessToken = msg.content?.accessToken;
      this.onRampUrl = msg.content?.url;
      this.tabUtilsService.config.origin = this.onRampUrl;
      this.openTabUrl = this.onRampUrl + "/appblast/webclient/index.html?isOnRamp=true";
      Logger.info("Tab tab url is" + this.openTabUrl, Logger.ON_RAMP);
   };

   public checkToCreateTab = (msg) => {
      if (!msg?.content?.url) {
         return;
      }
      let popUpWindow = this.getOpenTab();
      //Check whether it's the same configuration with before
      if (msg.content.url !== this.onRampUrl && this.onRampUrl != "") {
         if (popUpWindow !== null) {
            Logger.info("Close the tab as it's not the same on ramp url", Logger.ON_RAMP);
            popUpWindow.close();
         }
         Logger.info("Not the same on ramp url", Logger.ON_RAMP);
         popUpWindow = null;
      }
      if (popUpWindow !== null) {
         popUpWindow.focus();
      } else {
         this.openNewTab();
      }
   };

   public getOnRampConfig = () => {
      this.jscdkCommonInvoker.getOnRampConfig();
   };

   public openNewTab = () => {
      this.parent.openNewTab({
         url: this.openTabUrl,
         windowName: "Child - " + ++this.i,
         windowFeatures: ""
      });
   };

   public reset = () => {
      setOnRampMode(false);
   };
}
