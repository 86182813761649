/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";

export class FullScreenHelper {
   public static isFullscreen = () => {
      if (clientUtil.isChromeClient()) {
         return chrome.app.window.current().isFullscreen();
      } else {
         // prettier-ignore
         return !(
            (
               // @ts-ignore
               (window.document.fullScreenElement !== undefined &&
                  // @ts-ignore
                  window.document.fullScreenElement === null) ||
               // @ts-ignore
               (window.document.msFullscreenElement !== undefined &&
                  // @ts-ignore
                  window.document.msFullscreenElement === null) ||
               // @ts-ignore
               (window.document.mozFullScreen !== undefined &&
                  // @ts-ignore
                  !window.document.mozFullScreen) ||
               // @ts-ignore
               (window.document.webkitIsFullScreen !== undefined &&
                  // @ts-ignore
                  !window.document.webkitIsFullScreen)
            )
         );
      }
   };

   /**
    * Will execute the callback when either waitTime reached or condition is
    *    satisfied
    * @param  {function} condition The condition function, which once return
    *    true, will trigger the execution of callback
    * @param  {function} callback The callback function will be processed
    *    sooner
    *    or later.
    * @param  {number} waitMaxTime The max wait time in ms
    */
   public static waitCondition = (condition, callback, waitMaxTime?: any) => {
      let waitTime = waitMaxTime || 3000, //ms
         checkInterval = 100, //ms
         checkFunction,
         waitTimer;

      checkFunction = function () {
         waitTime -= checkInterval;
         if (condition() || waitTime <= 0) {
            callback();
            clearInterval(waitTimer);
         }
      };

      if (condition()) {
         callback();
      } else {
         waitTimer = setInterval(checkFunction, checkInterval);
      }
   };
}
