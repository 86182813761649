/**
 * ******************************************************
 * Copyright (C) 2017-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 *  multimon-rendering.service.ts -- multimonRenderingService
 *  The API service of multimon to pass into the wmks.
 * @format
 */

import { Injectable } from "@angular/core";
import { MonitorManageService } from "./monitor-manage.service";

@Injectable({
   providedIn: "root"
})
export class MultimonRenderingService {
   public onRectData;

   constructor(private monitorManageService: MonitorManageService) {}

   public reset() {
      this.monitorManageService.reset();
   }

   public onInit = (type, param, onSuccess, onError) => {
      this.monitorManageService.h264Stream.init(param.streamId, onSuccess, onError);
   };
   public onData = (type, param, onSuccess, onError) => {
      if (type === "mp4") {
         this.monitorManageService.h264Stream.appendData(param.streamId, param.data, onSuccess, onError);
      } else {
         this.onRectData = this.monitorManageService.rectStreamAppendData(param, onSuccess, onError);
      }
   };
}
