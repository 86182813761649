/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import JSCDKClientController from "./jscdkClientController";
import Logger from "../../../core/libs/logger";
/**
 * UnauthPuzzleHandler.js --
 */

export default function UnauthPuzzleHandler() {
   this.puzzleWorker = null;
   this.isWorkerBusy = false;
}

function getCalCurrentTime() {
   const curDate = new Date();
   return curDate.getTime();
}

UnauthPuzzleHandler.prototype.doCalculation = function (puzzleData) {
   if (this.isWorkerBusy) {
      Logger.warning("Calculation worker is busy and cannot handle more puzzle");
      return;
   }

   if (!this.puzzleWorker) {
      Logger.debug("Creating new calculation worker now");
      this.tsStart = getCalCurrentTime();
      this.puzzleWorker = new Worker("./unauthworker." + __BUILD_NUMBER__ + ".js");
      this.puzzleWorker.onmessage = (event) => {
         this.onWorkerMsg(event);
      };
   }

   this.isWorkerBusy = true;
   this.puzzleWorker.postMessage(puzzleData);
};

UnauthPuzzleHandler.prototype.onWorkerMsg = function (event) {
   this.tsEnd = getCalCurrentTime();
   const duringTime = this.tsEnd - this.tsStart;
   Logger.info("The duration (in milliseconds) to solve puzzles: " + duringTime);
   this.isWorkerBusy = false;
   this.submitSolutions(event.data);
};

UnauthPuzzleHandler.prototype.submitSolutions = function (solutions) {
   const submitAuthInfoAction = {
      method: "SubmitAuthInfo",
      type: "UnauthenticatedSolution",
      solutionList: solutions
   };
   JSCDKClientController.getInstance().execute(submitAuthInfoAction);
};

UnauthPuzzleHandler.prototype.tearDownWorker = function () {
   if (!this.puzzleWorker) {
      return;
   }

   if (this.isWorkerBusy) {
      Logger.warning("Worker is still busy, this is probably not illegal tear" + " down operation");
   }

   this.puzzleWorker.terminate();
   this.puzzleWorker = null;
   this.isWorkerBusy = false;
};
