/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import WMKS from "WMKS";
import { StringUtils } from "@html-core";
import Logger from "../../../core/libs/logger";
import { UsbProtocol, USBControlMsg } from "./usb-protocol";

import { IRPC, IVDPServiceListener, VDPChannel, VDPService, VDP_CONSTS } from "../../../shared/desktop/vdpservice";
import { UsbFilter } from "./usb-filter";

export enum UsbRedirState {
   DISABLED,
   PENDING,
   NOT_AVAILABLE,
   AVAILABLE
}

export interface USBChannelEventCb {
   onReady(wmksKey: string): void;
   onUrbMessage(wmksKey: string, data: any);
   onDisconnect(wmksKey: string): void;
}

export class UsbChannel implements IVDPServiceListener {
   private static readonly REFIX: string = "RPC#UsbRedirection#";
   private static readonly CONTROL_OBJ_NAME: string = "UsbRedirControlObj";
   private static readonly DATA_OBJ_NAME: string = "UsbRedirDataObj";
   private static deviceIndex = 0;

   private logger: Logger = null;
   private eventCb: USBChannelEventCb = null;
   public deviceId: number = 0;
   public usbTicket: string = "";
   private deviceStr: string;
   public usbFilter: UsbFilter = null;

   private vdpService: VDPService = null;

   private usbDataObject = null;
   private usbControlObject = null;
   private mainChannel = null;

   public wmksKey: string = "";
   public state: UsbRedirState = UsbRedirState.PENDING;

   constructor(vdpService: VDPService, wmksKey: string, cb: USBChannelEventCb, ticket: string) {
      this.vdpService = vdpService;
      this.wmksKey = wmksKey;
      this.eventCb = cb;
      this.usbTicket = ticket;
      this.deviceId = UsbChannel.deviceIndex++;
      this.logger = new Logger(Logger.USB, this.deviceId.toString());
      this.deviceStr = this._generateDeviceId();
      this.usbFilter = new UsbFilter();
      this.initialize();
   }

   // @Override
   public onInvoke = (rpc: IRPC): void => {
      this.handleRPCFromServer(rpc);
   };

   // @Override
   public onReady = (object?: { id: number; name: string }): void => {
      if (object.name === UsbChannel.DATA_OBJ_NAME) {
         this.usbDataObject = object;
         // only trigger the callback when both channels are set up.
         if (this.eventCb && this.eventCb.onReady) {
            this.eventCb.onReady(this.wmksKey);
         }
      } else if (object.name === UsbChannel.CONTROL_OBJ_NAME) {
         this.usbControlObject = object;
      }
   };

   // @Override
   public onDisconnect = (object?: { id: number; name: string }): void => {
      if (object) {
         if (this.usbControlObject && this.usbControlObject.id === object.id) {
            this.usbControlObject = null;
         } else if (this.usbDataObject && this.usbDataObject.id === object.id) {
            this.usbDataObject = null;
         }
      } else {
         this.usbDataObject = null;
         this.usbControlObject = null;
      }

      //Trigger when one of the channel is disconnected.
      if (this.eventCb && this.eventCb.onDisconnect) {
         this.eventCb.onDisconnect(this.wmksKey);
      }
   };

   public sendPostMsgFastRPC = (packet, onDone, onAbort) => {
      if (!this.mainChannel) {
         this.logger.error("RPC send failed: UsbChannel not initialized");
         onAbort();
         return;
      }

      return !!this.mainChannel.invoke({
         object: this.usbDataObject,
         command: UsbProtocol.PACKET_TYPE.USBREDIRECTION_POSTMSGFAST,
         type: VDP_CONSTS.RPC_TYPE.REQUEST,
         params: packet ? [packet.getData()] : [],
         onDone: onDone,
         onAbort: onAbort
      });
   };

   public sendAsyncMsgRPC = (message: USBControlMsg): Promise<string> => {
      return new Promise((resolve, reject) => {
         if (!this.mainChannel) {
            this.logger.error("RPC send failed: UsbChannel not initialized");
            reject("");
            return;
         }
         let packet = WMKS.Packet.createNewPacketLE(),
            utf8Text = StringUtils.stringToUint8Array(message.data, true);
         packet.writeArray(utf8Text);
         this.mainChannel.invoke({
            object: this.usbDataObject,
            command: UsbProtocol.PACKET_TYPE.USBREDIRECTION_SENDASYNCMSG,
            type: VDP_CONSTS.RPC_TYPE.REQUEST,
            params: [message.type, this.deviceStr, packet.getData()],
            onDone: (rpc) => {
               let msgData: Uint8Array = null;
               let msgString = "";
               if (typeof rpc.returnParams[0] === "string") {
                  packet = WMKS.Packet.createFromBufferLE(rpc.returnParams[1]);
                  msgData = packet.readArray(rpc.returnParams[1].length);
                  msgString = StringUtils.uint8ArrayToString(msgData);
               }
               resolve(msgString);
            },
            onAbort: (error) => {
               reject(error);
            }
         });
      });
   };

   public sendPostMsgRPC = (message: USBControlMsg) => {
      return new Promise((resolve, reject) => {
         if (!this.mainChannel) {
            this.logger.error("RPC send failed: UsbChannel not initialized");
            reject();
            return;
         }

         const packet = WMKS.Packet.createNewPacketLE(),
            utf8Text = StringUtils.stringToUint8Array(message.data, true);
         packet.writeArray(utf8Text);
         this.mainChannel.invoke({
            object: this.usbDataObject,
            command: UsbProtocol.PACKET_TYPE.USBREDIRECTION_POSTMSG,
            type: VDP_CONSTS.RPC_TYPE.REQUEST,
            params: [message.type, packet.getData()],
            onDone: () => {
               resolve("Done");
            },
            onAbort: (error) => {
               reject(error);
            }
         });
      });
   };

   public handleRPCFromServer = (rpc: IRPC) => {
      //util.dump_rpc(rpc);
      switch (rpc.command) {
         case UsbProtocol.PACKET_TYPE.USBREDIRECTION_SENDASYNCMSG:
            break;
         case UsbProtocol.PACKET_TYPE.USBREDIRECTION_POSTMSG:
            break;
         case UsbProtocol.PACKET_TYPE.USBREDIRECTION_POSTMSGFAST:
            if (this.eventCb && this.eventCb.onUrbMessage) {
               this.eventCb.onUrbMessage(this.wmksKey, rpc.params[3]);
            }
            break;
         default:
            this.logger.error(UsbProtocol.LOG + "MKSVchan received unexpected command: " + rpc.command);
      }
   };

   private initialize = () => {
      if (!this.vdpService) {
         this.logger.error("UsbChannel Init failed: no vdpService specified");
         return;
      }
      this.vdpService.addChannelCreatedListener((channel: VDPChannel) => {
         if (channel.name.indexOf(UsbChannel.REFIX) === 0) {
            if (this.vdpService.connectChannel(channel)) {
               this.logger.info("Successfully accepted USB main channel for " + this.wmksKey);
            } else {
               this.logger.error("Failed to open USB main channel for " + this.wmksKey);
               return;
            }

            channel.enableArbitrarySideChannel(true);
            this.mainChannel = channel;
            channel.addCallback(this);
         }
      });
   };

   private _generateDeviceId = (): string => {
      return (
         "00000000-0001-0000-0000-00000000" +
         this.deviceId.toLocaleString(undefined, {
            minimumIntegerDigits: 12,
            useGrouping: false
         })
      );
   };
}
