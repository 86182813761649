/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * For now only use very small factor to speed up without lowpass filter,
 * Since it can resolve customer's issue, avoid the complex change to avoid choppy or complex control for 2 buffers and worker
 * check details on SR 2709340
 * @format
 */

import { clientUtil } from "@html-core";
import Logger from "../../../core/libs/logger";
import { Subject, Subscription } from "rxjs";

export enum AudioDelayOption {
   disabled = "disabled",
   enabled = "enabled",
   auto = "auto"
}

type RTAVActiveChanged = {
   isActive: boolean;
   sessionKey: string;
};
export class AudioPlayControl {
   private startTimeStamp: number; //: double
   private audioNextTime: number; //lastScheduled play time
   private playedTime: number; //schedule one instead real one
   private speedFactor: number;
   private cleared: boolean;
   private maxWaitTime: number;
   // used for detect the variation of delay
   private foundInitialDelay: number; // found initial decode and network delay
   private maxRelativePlayDelay: number; // being a large negative mean get improved
   private maxRelativePackageDelay: number; // being a large negative mean get improved
   private currentRelativePlayDelay: number;
   private currentRelativePackageDelay: number;

   private idealPlayTime: number; //schedule one instead real one
   private currentWaitTime: number;
   private activeStatus: AudioDelayOption;
   private isRTAVActive: boolean;
   // Design in HTML Access compatible way

   private static onRTAVActiveChanged$ = new Subject<RTAVActiveChanged>();
   public audioService;

   // for debugging with trace log
   private loggerTimer;
   private logger: Logger;
   constructor(logId: string, sessionKey: string, audioService) {
      this.audioNextTime = -1;
      this.startTimeStamp = -1;
      this.playedTime = 0; // modified actual play time
      this.idealPlayTime = 0; // original time
      this.speedFactor = 1.0;
      this.cleared = true;
      this.foundInitialDelay = 0;
      this.maxRelativePlayDelay = 0;
      this.maxRelativePackageDelay = 0;
      this.currentRelativePlayDelay = 0;
      this.currentRelativePackageDelay = 0;
      this.maxWaitTime = 0;
      this.currentWaitTime = 0;
      this.loggerTimer = window.setInterval(this.printStatus, 5000);
      this.logger = new Logger(Logger.AUDIO + "(" + logId + ")");
      AudioPlayControl.getDelayControlOption().then((delayControlOption) => {
         this.activeStatus = delayControlOption;
      });
      this.isRTAVActive = false;
      AudioPlayControl.onRTAVActiveChanged$.subscribe((rtavActiveChanged: RTAVActiveChanged) => {
         this.isRTAVActive = rtavActiveChanged.sessionKey === sessionKey && !!rtavActiveChanged.isActive;
         this.logger.info("rtavActive Changed to " + this.isRTAVActive + " in audio delay control for " + sessionKey);
      });
      this.audioService = audioService;
   }

   public static setRTAVActive = (isActive: boolean, sessionKey: string) => {
      const rtavActiveChanged: RTAVActiveChanged = {
         isActive: isActive,
         sessionKey: sessionKey
      };
      AudioPlayControl.onRTAVActiveChanged$.next(rtavActiveChanged);
   };

   private static DelayControlOptionKey = "AudioDelayControlOption";
   public static setDelayControlOption = (delayControlOption: AudioDelayOption) => {
      if (!clientUtil.isChromeClient()) {
         return;
      }
      const content = {};
      content[AudioPlayControl.DelayControlOptionKey] = delayControlOption;
      chrome.storage.local.set(content);
   };
   public static getDelayControlOption = (): Promise<AudioDelayOption> => {
      return new Promise((resolve, reject) => {
         if (!clientUtil.isChromeClient()) {
            resolve(AudioDelayOption.disabled);
         } else {
            const key = AudioPlayControl.DelayControlOptionKey;
            chrome.storage.local.get(key, (value) => {
               if (value && value[key]) {
                  if (value[key] === AudioDelayOption.disabled || value[key] === AudioDelayOption.enabled) {
                     Logger.info("Audio Delay status is: " + value[key], Logger.AUDIO);
                     resolve(value[key]);
                     return;
                  }
               }
               Logger.info("Audio Delay status is: " + AudioDelayOption.auto, Logger.AUDIO);
               resolve(AudioDelayOption.auto);
            });
         }
      });
   };
   private printStatus = () => {
      this.logger.trace(
         "delay control enabled: " +
            this.isDelayControlActive() +
            ", current speedFactor = " +
            this.speedFactor +
            ", max found maxWaitTime = " +
            this.maxWaitTime +
            ", current waitTime = " +
            this.currentWaitTime +
            ", current foundInitialDelay = " +
            this.foundInitialDelay +
            ", max found maxRelativePackageDelay = " +
            (this.maxRelativePackageDelay + this.foundInitialDelay) +
            ", current currentRelativePackageDelay = " +
            (this.currentRelativePackageDelay + this.foundInitialDelay) +
            ", max found maxRelativePlayDelay = " +
            (this.maxRelativePlayDelay + this.foundInitialDelay) +
            ", current currentRelativePlayDelay = " +
            (this.currentRelativePlayDelay + this.foundInitialDelay)
      );
   };
   /**
    * Used to calculate real statistics for debugging, while control is based on relative parameters
    */
   private updateStatistics = (playableTime, delay, duration, audioInfo, curTime) => {
      this.maxWaitTime = Math.max(this.maxWaitTime, delay);
      this.currentWaitTime = delay;

      const nextTimeStamp = this.getTimeStamp(audioInfo);
      this.currentRelativePackageDelay = this.startTimeStamp + curTime - nextTimeStamp;

      let packageDelay = this.currentRelativePackageDelay + this.foundInitialDelay;

      if (packageDelay < 0) {
         //current delay - min delay
         this.foundInitialDelay -= packageDelay;
         packageDelay = 0;
      }
      this.maxRelativePackageDelay = Math.max(this.maxRelativePackageDelay, packageDelay - this.foundInitialDelay);

      let currentPlayDelay = delay + packageDelay;
      currentPlayDelay = Math.max(0, currentPlayDelay);

      this.currentRelativePlayDelay = currentPlayDelay - this.foundInitialDelay;
      this.maxRelativePlayDelay = Math.max(this.maxRelativePlayDelay, this.currentRelativePlayDelay);

      return currentPlayDelay;
   };
   // introduce tolerance to avoid jump around
   private setSpeedFactor = (factor, delay, toleranceDown = -1, largerFactor = 1) => {
      if (this.speedFactor > factor) {
         if (delay > toleranceDown) {
            factor = largerFactor;
         }
      }
      if (Math.abs(factor - this.speedFactor) > 0.001) {
         this.logger.info("audio speed change from " + this.speedFactor + " to " + factor + " with delay " + delay);
         this.speedFactor = factor;
      }
   };

   private isDelayControlActive = (): boolean => {
      return (
         this.activeStatus === AudioDelayOption.enabled ||
         (this.activeStatus === AudioDelayOption.auto && this.isRTAVActive)
      );
   };
   // use simple 4 stage
   private updateSpeedFactor = (delay) => {
      if (!this.isDelayControlActive()) {
         this.speedFactor = 1;
         return;
      }
      // instead of using currentPlayDelay, use the simpler algorithm of PlayableTime-currentTime to avoid accumulated error
      if (delay > 0.9) {
         this.setSpeedFactor(1.075, delay);
      } else if (delay > 0.6) {
         this.setSpeedFactor(1.05, delay, 0.85, 1.075);
      } else if (delay > 0.4) {
         this.setSpeedFactor(1.03, delay, 0.55, 1.05);
      } else {
         this.setSpeedFactor(1, delay, 0.35, 1.03);
      }
   };

   private int32Touint32(n) {
      if (n < 0) {
         return 4294967296 + n;
      }
      return n;
   }
   private getTimeStamp(audioInfo) {
      return (
         this.int32Touint32(audioInfo.audioTimestampHi) * (4294967296 / 1000000) +
         this.int32Touint32(audioInfo.audioTimestampLo) / 1000000
      );
   }
   public onNewData = (
      audioContext: AudioContext,
      audioBuffer: AudioBuffer,
      audioInfo,
      source: AudioBufferSourceNode,
      isMuted: boolean,
      volumeInfo: Array<number>
   ) => {
      const curTime = audioContext.currentTime;
      if (this.cleared) {
         this.audioNextTime = curTime;
         this.startTimeStamp = this.getTimeStamp(audioInfo) - curTime;
         this.playedTime = 0;
         this.speedFactor = 1.0;
         this.cleared = false;
         this.idealPlayTime = 0;
      }
      let audioOutDeviceId;
      const offset = this.audioNextTime - curTime;
      const delay = Math.max(offset, 0);
      const startTime = curTime + delay;
      const gainNodeArray: GainNode[] = [];
      let duration = audioInfo.containerSize / 1000.0;
      const durationDiff = audioBuffer.duration - duration;
      const overlap = audioInfo.sampleRate / 1000.0;
      const numberOfChannels = audioInfo.numChannels;
      if (!audioInfo.hasOwnProperty("audioDeviceId")) {
         audioOutDeviceId = 0;
      } else {
         audioOutDeviceId = audioInfo.audioDeviceId;
      }

      /*
       * "audioInfo.containerSize" and "audioInfo.sampleRate"
       * is uint32, which drop those digits after the decimal. so it
       * will always be slightly larger or slightly smaller than the
       * original PCM duration inside audioBuffer.
       *
       * So, we always choose the same value between audioBuffer.duration
       * and "audioInfo.containerSize",
       */
      if (durationDiff < 0.0) {
         duration = audioBuffer.duration;
      }
      const audioDuration = audioBuffer.duration;
      const lastAudioNextTime = this.audioNextTime;

      const playDelay = this.updateStatistics(lastAudioNextTime, delay, audioDuration, audioInfo, curTime);
      //when the time is over hardClipTime seconds, client would ignore the package, we can't make this value too large
      const hardClipTime = 1.3;

      // Fix Bug 3078694If the user refresh page and there's no user gesture, don't run the buffer
      if (this.audioService.isRefreshPage && !this.audioService.audioResumed) {
         //return without add up to buffer.
         this.logger.info(
            "There's no user gesture to enable audio, discard audio data, " +
               +delay +
               "s, current play delay: " +
               playDelay +
               "s"
         );
         return;
      }
      if (
         this.isDelayControlActive() &&
         (delay > hardClipTime || (playDelay > 1.7 && delay > 1) || (playDelay > 2.5 && delay > 0.5))
      ) {
         //return without add up to buffer.
         this.logger.info(
            "audio buffer overflow, discard audio data, " +
               "please check the network delay, current queue delay: " +
               delay +
               "s, current play delay: " +
               playDelay +
               "s"
         );
         return;
      }
      this.updateSpeedFactor(delay);

      //create two gains for volume change.
      for (let i = 0; i < numberOfChannels; i++) {
         gainNodeArray.push(audioContext.createGain());
      }

      const playingTime = (duration - overlap) / this.speedFactor;

      source.buffer = audioBuffer;

      if (numberOfChannels === 1) {
         source.connect(gainNodeArray[0]);
         gainNodeArray[0].connect(audioContext.destination);
      } else if (numberOfChannels === 2) {
         let channelSplitter = null;
         let channelMerger = null;
         channelSplitter = audioContext.createChannelSplitter(2);
         channelMerger = audioContext.createChannelMerger(2);
         source.connect(channelSplitter);
         for (let i = 0; i < numberOfChannels; i++) {
            channelSplitter.connect(gainNodeArray[i], i);
            gainNodeArray[i].connect(channelMerger, 0, i);
         }
         channelMerger.connect(audioContext.destination);
      }
      this.audioNextTime = startTime + playingTime;
      this.playedTime += playingTime;

      for (let i = 0; i < numberOfChannels; i++) {
         gainNodeArray[i].gain.setValueAtTime(!isMuted ? volumeInfo[i] : 0, startTime);
      }

      source.playbackRate.value = this.speedFactor;
      source.start(startTime, overlap, duration - overlap);
   };
}
