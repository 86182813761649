/**
 * ******************************************************
 * Copyright (C) 2019-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * use this to sync setting changes to window in real time
 */

// prepare to accept connections from the window for this session.

import { Injectable } from "@angular/core";
import { clientUtil } from "@html-core";
import Logger from "../../../../core/libs/logger";
import { Port } from "../../../base/model/rx-bridge-type";
import { ConnectedMessageService } from "../../../base/service/connected-message.service";
import { ClientSettingModel, SettingItem } from "../../../../shared/common/model/client-setting-model";
import { MonitorInfoService } from "../../../../shared/common/service/monitor-info.service";
import { AutoFowardUSBService } from "../../../../shared/launcher/auto-usb-setting/auto-usb-foward.service";

/*
 *
 * Keep this service injected with the provider in the Common Module rather than with
 * the @Injectable() decorator.
 *
 * It is used in the shared codes with the @Optional() decorator.
 */

export type OnSessionSettingSyncBackFunc = (sessionKey: string, content: Array<SettingItem>) => void;
@Injectable()
export class RemoteSettingManager {
   private onSessionSettingSyncBackFunc: OnSessionSettingSyncBackFunc;
   public checkAnyInMultimon: boolean = false;

   private _multimonDesktopSessionKey = null;
   private _multimonDesktopAliveTimeout = null;
   // https://bugzilla.eng.vmware.com/show_bug.cgi?id=3100119
   private _multimonDesktopAliveTimeoutTime = 20000;
   private _latestAliveMessageTime;

   constructor(
      private connectedMessageService: ConnectedMessageService,
      private clientSettingModel: ClientSettingModel,
      private monitorInfoService: MonitorInfoService,
      private autoFowardUSBService: AutoFowardUSBService
   ) {
      this.clientSettingModel.bindRemoteSettingManager(this);
   }

   public registerNewSession = (sessionKey) => {
      if (!clientUtil.isSeamlessMode()) {
         Logger.error("remoteSettingManager should only be used for seamless window mode for now");
         return;
      }
      Logger.info("registering new sessionKey for setting sync to: " + sessionKey);
      this.connectedMessageService.addAcceptedId(sessionKey);
      this.connectedMessageService.onMessage(sessionKey, Port.ChannelName.Settings, (message) => {
         if (!message) {
            Logger.error("empty message" + message);
            return;
         }

         switch (message.type) {
            case Port.SettingMsg.enterMultimon:
               Logger.info("Receive enterMultimon message", Logger.DISPLAY);
               this._multimonDesktopSessionKey = sessionKey;
               this.clientSettingModel.anyDesktopInMultimonMode = true;
               this.connectedMessageService.sendMessageByType(Port.ChannelName.Settings, {
                  type: Port.SettingMsg.enterMultimon
               });
               return;
            case Port.SettingMsg.exitMultimon:
               Logger.info("Receive exitMultimon message", Logger.DISPLAY);
               this._multimonDesktopSessionKey = null;
               clearInterval(this._multimonDesktopAliveTimeout);
               this._multimonDesktopAliveTimeout = null;
               this.clientSettingModel.anyDesktopInMultimonMode = false;
               this.connectedMessageService.sendMessageByType(Port.ChannelName.Settings, {
                  type: Port.SettingMsg.exitMultimon
               });
               return;
            case Port.SettingMsg.desktopFocused:
               Logger.info("Receive desktopFocused message", Logger.DISPLAY);
               if (this._multimonDesktopSessionKey === null) {
                  this.monitorInfoService.shouldEnterMultimonSessionKey = sessionKey;
               }
               this.autoFowardUSBService.updateFocusSession(sessionKey);
               return;
            case Port.SettingMsg.checkAnyInMultimon:
               Logger.info("Receive checkAnyInMultimon message", Logger.DISPLAY);
               // no desktop in multimon mode
               if (this._multimonDesktopSessionKey === null) {
                  this._multimonDesktopSessionKey = sessionKey;
                  if (this._multimonDesktopAliveTimeout !== null) {
                     clearInterval(this._multimonDesktopAliveTimeout);
                  }
                  // reset check multimon desktop status if no keepalived message received
                  this._multimonDesktopAliveTimeout = setInterval(() => {
                     if (Date.now() - this._latestAliveMessageTime > this._multimonDesktopAliveTimeoutTime) {
                        this._multimonDesktopSessionKey = null;
                        clearInterval(this._multimonDesktopAliveTimeout);
                        this._multimonDesktopAliveTimeout = null;
                        this.monitorInfoService.shouldEnterMultimonSessionKey = null;
                     }
                  }, this._multimonDesktopAliveTimeoutTime);
                  this.monitorInfoService.shouldEnterMultimonSessionKey = sessionKey;
                  Logger.info("Send checkAnyInMultimon message", Logger.DISPLAY);
                  this.connectedMessageService.sendMessage(sessionKey, Port.ChannelName.Settings, {
                     type: Port.SettingMsg.checkAnyInMultimon,
                     data: false
                  });
               }
               return;
            case Port.SettingMsg.multimonAlive:
               this._latestAliveMessageTime = Date.now();
               return;
         }

         if (message.type !== Port.SettingMsg.syncBackSettings) {
            Logger.error("unacceptable message type" + message.type);
            return;
         }
         const content = <Array<SettingItem>>message.content;
         Logger.trace("get settings sync back on " + sessionKey + " of :\n" + JSON.stringify(content));
         if (!Array.isArray(content)) {
            Logger.error("invalid sync back format");
            return;
         }
         if (typeof this.onSessionSettingSyncBackFunc === "function") {
            const validSettings = content.filter((item: SettingItem) => {
               /*
                * use run time check instead of type script type to detect misuse
                * currently, only sync back string is supported
                */
               if (typeof item.key !== "string" || typeof item.value !== "string") {
                  Logger.error("invalid setting sync format" + JSON.stringify(item));
                  return false;
               }
               return true;
            });
            this.onSessionSettingSyncBackFunc(sessionKey, validSettings);
         }
      });
   };

   // Triggered from launch service, bounded with chrome API for bound changes
   public onResize = (sessionKey, width, height) => {
      this.connectedMessageService.sendMessage(sessionKey, Port.ChannelName.Settings, {
         type: Port.SettingMsg.Resolution,
         content: {
            width: width,
            height: height
         }
      });
   };
   public syncSettingToSession = (key: string, newValue: string) => {
      Logger.info("sync " + key + " setting status to all sessions: " + newValue);
      // Send to all sessions here by connectedMessageService
      this.connectedMessageService.sendMessageByType(Port.ChannelName.Settings, {
         type: key,
         content: newValue
      });
   };
   public onSessionSettingSyncBack = (callback: OnSessionSettingSyncBackFunc) => {
      if (this.onSessionSettingSyncBackFunc) {
         Logger.error("Not support multiple listener for now");
      }
      this.onSessionSettingSyncBackFunc = callback;
   };
}
