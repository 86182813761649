/**
 * ******************************************************
 * Copyright (C) 2014-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * util.js --
 *
 *      common functions used in jscdk
 *      may replace the current utils.js in the future
 */

import Logger from "../../core/libs/logger";
import $ from "jquery";
import { globalArray } from "./jscdkClient";

/*global XMLSerializer, chrome, document */

const util = {
   /**
    * check whether broker version support application and idle timeout
    *
    * @return bool satisfied
    */
   brokerSupportApplication: function () {
      if (
         !!globalArray["brokerVersion"] &&
         !!globalArray["applicationAPIVersion"] &&
         parseFloat(globalArray["brokerVersion"]) >= parseFloat(globalArray["applicationAPIVersion"])
      ) {
         return true;
      }
      return false;
   },

   /**
    * check whether broker version support URL content redirection
    *
    * @return bool satisfied
    */
   brokerSupportUrlContentRedirection: function () {
      return parseFloat(globalArray["brokerVersion"]) >= 11;
   },

   /**
    * check whether broker version support application and idle timeout
    *
    * @return bool satisfied
    */
   brokerSupportAzureReconnect: function () {
      if (
         !!globalArray["brokerVersion"] &&
         !!globalArray["azureReconnectAPIVersion"] &&
         parseFloat(globalArray["brokerVersion"]) >= parseFloat(globalArray["azureReconnectAPIVersion"])
      ) {
         return true;
      }
      return false;
   },

   /**
    * check whether broker version support restart desktop.
    * this was supported from broker 13.0
    * @return bool satisfied
    */
   brokerSupportRestartDesktop: function () {
      if (!!globalArray["brokerVersion"] && parseFloat(globalArray["brokerVersion"]) > 12) {
         return true;
      }
      return false;
   },

   /**
    * find the first element in the given list that contains the given property
    *
    * @param propertyName[in]: of type "string"
    *   the compare will be done for this property for each element
    * @param targetValue[in]: of type "any class that support ==="
    *   the property value of the searching target
    * @param list[in]: of type "Array/Object"
    *   the list that search alone with.
    */
   findFirstItemWithPropertyInList: function (propertyName, targetValue, list) {
      let key;
      for (key in list) {
         if (list.hasOwnProperty(key) && !!list[key] && list[key][propertyName] === targetValue) {
            return list[key];
         }
      }
      return null;
   },

   /**
    * create a new xml element
    *
    * We don't use the document object because the "param" node can't be add to
    * the document object correctly in Javascript.
    * @param name[in]  the name of the XML node
    * @param content[in]  the content of the XML node, can be nested XML
    * @param attrArray[in]  key-value pairs for xml attributes
    * @return a new element
    */
   createElement: function (name: string, content?: string, attrArray?: any) {
      // Length of parameters defined in function below
      const defParamLength = util.createElement.length;
      // Length of actual parameters below
      const actParamLength = arguments.length;
      let xmlText = "";
      let attrText = "";
      let attrKey;
      let attrValue;

      if (defParamLength === actParamLength) {
         for (attrKey in attrArray) {
            if (attrArray.hasOwnProperty(attrKey)) {
               attrValue = attrArray[attrKey];
               attrText += " " + attrKey + "='" + attrValue + "'";
            }
         }
      }
      if (typeof content === "undefined" || content === "") {
         xmlText = "<" + name + attrText + "/>";
      } else {
         if (util.validateXML(content) === true) {
            xmlText = "<" + name + attrText + ">" + content + "</" + name + ">";
         } else {
            // if content is pure text, escape content
            xmlText = "<" + name + attrText + ">" + util.escapeElement(content) + "</" + name + ">";
         }
      }

      return xmlText;
   },

   /**
    * compose messages for handlerList using jQuery
    *
    * @param handlerList[in] list including handlers whose messages can be sent
    *    together
    * @param brokerVersion[in]  broker version used in xml
    * @return the xml message composed
    */
   createXML: function (handlerList, brokerVersion) {
      let composedMessage = ""; // the composed message to be returned
      let handlerKey;
      let handler;
      let content;
      composedMessage += "<?xml version='1.0' encoding='UTF-8'?><broker version='" + brokerVersion + "'>";
      for (handlerKey in handlerList) {
         if (handlerList.hasOwnProperty(handlerKey)) {
            handler = handlerList[handlerKey];
            content = handler.requestXML;
            if (typeof content === "undefined" || content === "") {
               composedMessage += "<" + handler.messageText + "/>";
            } else {
               composedMessage += "<" + handler.messageText + ">";
               composedMessage += content;
               composedMessage += "</" + handler.messageText + ">";
            }
         }
      }
      composedMessage += "</broker>";
      return composedMessage;
   },

   /**
    * Find whether one handler is in the target array using handler's message
    * name as the key.
    *
    * @param handler[in] the handler to find.
    * @param targetArray[in] the target array to find.
    * @return -1 if not found, else the position of the handler in the target
    *         array.
    */
   findHandlerInArray: function (handler, targetArray) {
      let index = 0;
      for (index = 0; index < targetArray.length; index++) {
         if (targetArray[index].messageName === handler.messageName) {
            return index;
         }
      }
      return -1;
   },

   /**
    * Convert XML document to string
    *
    * @param xmlDoc[in] the XML document needs to be converted.
    * @return the converted string.
    */
   XMLToString: function (xmlDoc) {
      let xmlString = "",
         serializer;

      try {
         if (typeof XMLSerializer === "function") {
            serializer = new XMLSerializer();
            xmlString = serializer.serializeToString(xmlDoc);
         }
      } catch (error) {
         // For IE browsers which don't support XMLSerializer.
         xmlString = xmlDoc.xml || "";
      }

      return xmlString;
   },

   /**
    * get object from array named "name"
    *
    * @param array[in] the array to look for
    * @param name[in] the object's name
    * @return the object named "name"
    */
   getObject: function (array, name) {
      if (!array) {
         return null;
      } else {
         return array[name];
      }
   },

   /**
    * get child element node named "name",
    set idx to 0 if only 2 parameters exist
    *
    * @param parentNode[in] whose child to be got
    * @param name[in] child's name
    * @param idx[in] child's index
    * @return the available child node
    */
   getChildNode: function (parentNode, name, idx) {
      let childNode;
      // Length of parameters defined in function below
      const defParamLength = util.getChildNode.length;
      // Length of actual parameters below
      const actParamLength = arguments.length;
      if (defParamLength === actParamLength) {
         if (!parentNode) {
            return null;
         } else {
            childNode = parentNode.getElementsByTagName(name);
            if (!childNode) {
               return null;
            } else {
               if (childNode.length <= idx) {
                  return null;
               } else {
                  return childNode[idx];
               }
            }
         }
      } else if (defParamLength - 1 === actParamLength) {
         // only 2 parameter, set idx to 0 by default
         return util.getChildNode(parentNode, name, 0);
      }
   },

   /**
    * get child element node in the top layer named "name",
    set idx to 0 if only 2 parameters exist
    *
    * @param parentNode[in] whose child to be got
    * @param name[in] child's name
    * @param idx[in] child's index
    * @return the available child node in the first layer
    */
   getDirectChildNode: function (parentNode, name, idx) {
      let childNode;
      // Length of parameters defined in function below
      const defParamLength = util.getDirectChildNode.length;
      // Length of actual parameters below
      const actParamLength = arguments.length;
      if (defParamLength === actParamLength) {
         if (!parentNode) {
            return null;
         } else {
            const targetElements = [];
            $(parentNode.childNodes).each((i, element) => {
               if (element.tagName === name || element.localName === name) {
                  targetElements.push(element);
               }
            });
            if (targetElements.length > idx) {
               return targetElements[idx];
            } else {
               Logger.error("Failed to find direct child for name " + name + " at index: " + idx);
               return null;
            }
         }
      } else if (defParamLength - 1 === actParamLength) {
         // only 2 parameter, set idx to 0 by default
         return util.getDirectChildNode(parentNode, name, 0);
      }
   },
   /**
    * add new key-value item for jsonObject
    *
    * @param jsonObject[in] the json object to be handled
    * @param key[in] new item's key to be added
    * @param value[in] new item's value to be added
    * @return the status of this operation, true/false
    */
   addItemForJson: function (jsonObject, key, value) {
      if (!jsonObject) {
         return false;
      } else {
         jsonObject[key] = value;
         return true;
      }
   },

   /**
    * get the value of param named paramName from url
    *
    * @param query[in] the url to be handled
    * @param paramName[in] param name in url to be used
    * @return param value of param named paramName in url
    */
   getUrlParam: function (query, paramName) {
      let param, paramKey, paramValue, i;
      let ret = null;
      const params = query.slice(query.indexOf("?") + 1).split("&");
      for (i = 0; i < params.length; i++) {
         param = params[i].split("=");
         paramKey = param[0];
         paramValue = param[1];
         if (paramKey === paramName) {
            ret = paramValue;
         }
      }
      return ret;
   },

   /**
    * get decoded value of param named paramName from url
    *
    * @param query[in] the url to be handled
    * @param paramName[in] param name in url to be used
    * @return decoded value of param named paramName in url
    */
   getDecodedUrlParam: function (query, paramName) {
      let ret = null;
      const urlParam = util.getUrlParam(query, paramName);
      if (urlParam) {
         ret = decodeURIComponent(urlParam);
      }
      return ret;
   },

   /**
    * escape illegal characters in XML element string, such as '&', '<'
    * @param text[in] XML element string containing illegal characters
    * @return escaped XML element string
    */

   escapeElement: function (text) {
      // Characters like "<" and "&" are illegal in XML elements.
      // see
      // https://en.wikipedia.org/wiki/List_of_XML_and_HTML_character_entity_references
      if (text && typeof text === "string") {
         return text.replace(/["&'<>]/g, function (c) {
            if (c === '"') {
               return "&quot;";
            } else if (c === "'") {
               return "&apos;";
            } else if (c === "<") {
               return "&lt;";
            } else if (c === ">") {
               return "&gt;";
            } else if (c === "&") {
               return "&amp;";
            } else {
               return "&#" + c.charCodeAt(0) + ";";
            }
         });
      }
      return text;
   },

   /**
    * unescape characters in XML element string, such as '&amp;', '&lt;'
    * @param text[in] XML element string to unescape
    * @return unescaped XML element string
    */

   unescapeElement: function (text) {
      // Characters like "<" and "&" are illegal in XML elements.
      // refer to: http://www.w3schools.com/xml/xml_cdata.asp
      if (text) {
         text = text.replace(/&quot;/g, '"');
         text = text.replace(/&apos;/g, "'");
         text = text.replace(/&lt;/g, "<");
         text = text.replace(/&gt;/g, ">");
         text = text.replace(/&amp;/g, "&");
      }
      return text;
   },

   /**
    * validate xmlString whether it is pure text without xml elements
    * @param xmlString[in] xmlString to be validated
    * @return true/false, whether xmlString is pure text without xml elements
    */

   validateXML: function (xmlString) {
      let xmlDoc;
      let isValid;
      let len;

      try {
         /**
          * add a root element "<validate></validate>" to avoid XML validation
          * error caused by multiple XML elements (this case is valid) in
          * xmlString
          */
         xmlDoc = $.parseXML("<validate>" + xmlString + "</validate>");
         len = $(xmlDoc).find("validate").children().length;
         if (len === 0) {
            /**
             * "len === 0" indicates that xmlString is a text string,
             * without XML elements in it.
             */
            isValid = false;
         } else {
            /**
             * xmlString has one or multiple XML elements but it is valid.
             */
            isValid = true;
         }
      } catch (e) {
         /**
          * "validate error" indicates that xmlString has special characters to
          * escape
          */
         isValid = false;
         Logger.error("validate XML error: " + e);
      }
      return isValid;
   },

   /**
    * Parses a URL into its components
    *
    * @param url[in] URL to parse
    * @return object containing information about the URL
    */
   parseURL: function (url) {
      const a = document.createElement("a");

      try {
         /*
          * IE10 (possibly among others) has problems parsing a link if there is no protocol
          * attached to it.
          */
         if (url.indexOf("://") !== -1) {
            a.href = url;
         } else {
            a.href = "https://" + url;
         }
         return {
            source: url,
            protocol: a.protocol.replace(":", ""),
            host: a.hostname,
            port: a.port,
            query: a.search,
            params: (function () {
               let ret = {},
                  seg = a.search.replace(/^\?/, "").split("&"),
                  len = seg.length,
                  i = 0,
                  s;
               for (i = 0; i < len; i++) {
                  if (!seg[i]) {
                     continue;
                  }
                  s = seg[i].split("=");
                  ret[s[0]] = s[1];
               }
               return ret;
            })(),
            file: (a.pathname.match(/\/([^/?#]+)$/i) || ["", ""])[1],
            hash: a.hash.replace("#", ""),
            path: a.pathname.replace(/^([^/])/, "/$1"),
            relative: (a.href.match(/tps?:\/\/[^/]+(.+)/) || ["", ""])[1],
            segments: a.pathname.replace(/^\//, "").split("/")
         };
      } catch (e) {
         /*
          * Browsers may throw an exception if the URL is not valid.  Return an object
          * with the expected members all empty so the applications don't have to check
          * whether a member exists before using it.
          */
         return {
            source: url,
            protocol: "",
            host: "",
            port: "",
            query: "",
            params: {},
            file: "",
            hash: "",
            path: "",
            relative: "",
            segments: ""
         };
      }
   },

   /**
    * Check whether the code is running under Chrome Extension environment.
    * If chrome.extension APIs are defined, it is running in Chrome Extension
    * environment.
    *
    * @return true if it is in Chrome Extension environment, else false.
    */
   isChromeClient: function () {
      let isChromeClient;
      try {
         // Edge has chrome, but doesn't has chrome.management
         isChromeClient = !!chrome && !!chrome.management;
      } catch (e) {
         // Safari/Firefox/IE is throwing error here
         isChromeClient = false;
      }
      return isChromeClient;
   },

   /**
    * Replace the secret field's value such as password before logging it.
    *
    * @param message [in] message needs to be censored.
    * @param type    [in] message type: XML or JSON, default type is XML.
    * @return the censored string, or "CENSORING FAILED" when translation fails.
    */
   censorMessage: function (message, type?) {
      let censoredMessage = message,
         secretParams = [
            "framework-channel-ticket",
            "newPassword1",
            "newPassword2",
            "oldPassword",
            "passcode",
            "password",
            "pin1",
            "pin2",
            "smartCardPIN",
            "secret",
            "tokencode",
            "url"
         ],
         partHidenParams = ["token", "framework-channel-certificate-thumbprint", "csrf-token"],
         messageType = type || "XML",
         xmlDoc,
         xml,
         secretNode,
         secretKey,
         obj,
         i;

      try {
         if (messageType.toLowerCase() === "xml") {
            xmlDoc = $.parseXML(censoredMessage);
            xml = $(xmlDoc);
            secretNode = xml.find("param");
            secretNode = Array.prototype.slice.call(secretNode);
            for (secretKey in secretNode) {
               // Skip non-properties to avoid bugs like 1732807
               if (!secretNode.hasOwnProperty(secretKey)) {
                  continue;
               }
               if ($.inArray($(secretNode[secretKey]).children("name").text(), secretParams) !== -1) {
                  $(secretNode[secretKey]).find("value").text("[REDACTED]");
               }
            }
            censoredMessage = util.XMLToString(xmlDoc);
         } else {
            obj = $.parseJSON(censoredMessage);
            for (i = 0; i < partHidenParams.length; i++) {
               secretKey = partHidenParams[i];
               if (obj.hasOwnProperty(secretKey)) {
                  const originalValue = obj[secretKey];
                  const cleanedString = originalValue.trim().replace(/\{#-ASKS=\d+:\d+\}/g, "");
                  obj[secretKey] = cleanedString.slice(0, 3) + "******" + cleanedString.slice(-3);
               }
            }
            for (i = 0; i < secretParams.length; i++) {
               secretKey = secretParams[i];
               if (obj.hasOwnProperty(secretKey)) {
                  obj[secretKey] = "[REDACTED]";
               }
            }
            censoredMessage = JSON.stringify(obj);
         }

         return censoredMessage;
      } catch (e) {
         // Parse document fail
         return "CENSORING FAILED";
      }
   },

   /**
    * Serializes information about an error into a string.  I used to just
    * stringify the entire error object but sometimes it would have circular
    * references which would throw its own exception.  The next best thing
    * is to just log the top-level items in the error object.
    *
    * @param e[in] error object to serialize.
    * @return string containing error serialization.
    */
   serializeError: function (e) {
      let key,
         detail = "";
      for (key in e) {
         if (e.hasOwnProperty(key)) {
            detail += "\r\n   " + key + ": " + e[key];
         }
      }
      return detail;
   },

   getSelfDefinedErrorObjectBy: function (xmlHandler, errorMessage, errorDetails?) {
      const ErrorObj: any = {};
      let errorKey;

      errorKey = xmlHandler.name || xmlHandler.messageName || "self-defined error";
      Logger.error("error from " + errorKey + ": " + errorMessage);

      ErrorObj.errorType = errorKey;
      ErrorObj.errorText = errorMessage;
      if (errorDetails) {
         ErrorObj.errorDetails = errorDetails;
      }
      return ErrorObj;
   },

   /**
    * Deals with some of the L10N features like using display text based on
    * user's locale.
    */
   l10N: {
      // Mapping of supported locales to the corresponding JSON file.
      supportedLocale: {
         en: "en",
         es: "es",
         de: "de",
         fr: "fr",
         ja: "ja",
         ko: "ko",
         zh: "zh-CN",
         "en-us": "en",
         "es-es": "es",
         "de-de": "de",
         "fr-fr": "fr",
         "ja-jp": "ja",
         "ko-kr": "ko",
         "zh-cn": "zh-CN",
         "zh-hans": "zh-CN",
         "zh-hant": "zh-TW",
         "zh-tw": "zh-TW",
         "zh-hk": "en",
         "zh-mo": "en",
         "zh-hans-hk": "en",
         "zh-hant-hk": "en",
         "zh-hans-mo": "en",
         "zh-hant-mo": "en"
      },

      // Locale to be used during translation. Default is English.
      locale: "en",

      // JSON object containing the translated text.
      translatedTable: null,

      // Get Locale info
      getLocale: function () {
         if (!this.translatedTable) {
            // Set locale and load JSON translation table.
            this.setLocaleAndLoad();
         }

         return this.locale;
      },

      // Set the locale and load the corresponding JSON translation table.
      setLocaleAndLoad: function (acceptLanguage?: any) {
         this.translatedTable = window.translateTable || null;
      },

      translate: function (msgid, args) {
         let translatedStr;

         /**
          * If the translated msgid exists, return the translated string, else
          * return the original string.
          */
         if (!!this.translatedTable && !!this.translatedTable[msgid]) {
            translatedStr = this.translatedTable[msgid];
         } else {
            translatedStr = msgid;
         }

         return translatedStr.replace(/\{(\d+)\}/g, function (match, mNum, offset, fullStr) {
            let retStr;
            const intNum = parseInt(mNum, 10);
            // For escaped case, remove the duplicated "{" and "}".
            if (fullStr.charAt(offset - 1) === "{" && fullStr.charAt(offset + match.length) === "}") {
               return mNum;
            }
            if (args[intNum + 1]) {
               retStr = args[intNum + 1];
            } else {
               retStr = match;
            }

            return retStr;
         });
      },

      /**
       * For a given list of desired locales, determine which language out of
       * our supported languages we should display.
       */
      getBestLocale: function (acceptLanguage) {
         let matchedLocale = null,
            matchedRank = 0,
            accepted,
            i,
            kv,
            parts,
            thisRank,
            localeAndRank;

         if (!acceptLanguage) {
            return null;
         }

         /**
          * For each of the languages in the acceptLanguage param, see
          * if getSupportedLocale likes it. If so, set the currently
          * preferred language and its rank ('q=' value.) Do this for
          * all languages.
          */
         accepted = acceptLanguage.split(",");
         for (i = 0; i < accepted.length; i++) {
            if (accepted[i].indexOf("*") >= 0) {
               // Ignore the wildcard.
               continue;
            }

            /*
             * There may be a 'q=' section with a rank for this language.
             * Set rank value to 1 by default.
             */
            thisRank = 1;
            parts = $.trim(accepted[i]).split(";", 2);
            if (parts.length === 2) {
               kv = parts[1].split("=", 2);
               if (kv[0] === "q" && kv.length === 2) {
                  thisRank = parseFloat(kv[1]);
               }
            } else if (parts.length < 1) {
               continue;
            }

            /**
             * If this locale entry is supported, compare its rank to the
             * highest matched rank thus far.
             */
            localeAndRank = this.getSupportedLocale(parts[0], thisRank);
            if (localeAndRank) {
               if (localeAndRank[1] > matchedRank) {
                  matchedLocale = localeAndRank[0];
                  matchedRank = localeAndRank[1];
               }
            }
         }

         return matchedLocale;
      },

      /**
       * For a given locale, determine the best supported localization
       * fit for that language using the supportedLocale dictionary.
       */
      getSupportedLocale: function (locale, returnRank) {
         let langCode, selectedLocale, specifiers;

         // Convert to lower-case.
         locale = locale.toLowerCase();

         if (this.supportedLocale.hasOwnProperty(locale)) {
            // The full locale is specifically supported.
            selectedLocale = this.supportedLocale[locale];
         } else {
            specifiers = locale.split("-");
            if (specifiers.length > 2) {
               // Locale string like 'zh-hant' or 'zh-hant'.
               langCode = specifiers[0] + "-" + specifiers[1];
               if (this.supportedLocale.hasOwnProperty(langCode)) {
                  // The language is zh-hans or zh-hant
                  selectedLocale = this.supportedLocale[langCode];
               }
            }
            if (!selectedLocale && specifiers.length > 0) {
               // Locale string like 'en-us' or 'zh-tw'.
               langCode = specifiers[0];
               if (this.supportedLocale.hasOwnProperty(langCode)) {
                  /**
                   * The language is supported, but not in the desired locale,
                   * the request is generic (xx instead of xx-YY)
                   */
                  selectedLocale = this.supportedLocale[langCode];
               }
            }
         }

         if (selectedLocale) {
            return [selectedLocale, returnRank];
         }

         return null;
      }
   },

   /**
    * Get the translated strings for user's current language with printable
    * style. The {number} tags are replaced by the order of the arguments
    * followed. The number should start from 0 and continuously. The arguments
    * followed are printed in the number orders to support different orders for
    * the same sentence in different languages. Use {{number}} to print
    * {number} in the string.
    *
    * A tutorial for English strings:
    *     Input                          Output
    * _("Test text.")                "Test text."
    * _("{0} text.", "Test")         "Test text."
    * _("{1} {0}.", "text", "Test")  "Test text."
    * _("Test text {{0}}", "fail")   "Test text {0}"
    * _("Test text {0}")             "Test text {0}"
    * @param msgid[in] key for the translated string.
    * @param arguments...[in] variable arguments for printf style strings, use
    *    {0}, {1}, etc as the format parameters.
    * @return the translated string if found, else the original string.
    */

   _: function (msgid) {
      const args = arguments;

      /**
       * Load the resources when the _ function first called.
       */
      if (!util.l10N.translatedTable) {
         // Load translation table according to the desired locale.
         util.l10N.setLocaleAndLoad();
      }

      return util.l10N.translate(msgid, args);
   },

   /**
    * workflow control class for info merging.
    *
    * @param waitNumber: of int
    *    if the input info number is greater or equal to this number,
    *    the merging will be preformed
    * @param mergingFunc: of function
    */
   InfoCombiner: function (waitNumber, mergingFunc, callbackParam) {
      // private:
      let mergingThreshold = waitNumber,
         mergingFunction = mergingFunc,
         mergingCallbackParam = callbackParam,
         containerList = [];

      // privileged:
      this.onInfoReceived = function (infoObj) {
         containerList.push(infoObj);
         if (containerList.length >= mergingThreshold) {
            mergingFunction(containerList, mergingCallbackParam);
            containerList = [];
         }
      };
   },

   isWS1Mode: function () {
      return globalArray.ws1Mode;
   },

   getWS1Hostname: function () {
      return globalArray.workspaceOneServerHostname;
   },

   parseXML: function (XMLArray) {
      const xml = {};
      XMLArray.each(function (index, element) {
         const nameText = element.localName || element.baseName;
         if ($(element).children().length === 0) {
            xml[nameText] = $(element).text();
         } else {
            xml[nameText] = util.parseXML($(element).children());
         }
      });
      return xml;
   }
};

export default util;
