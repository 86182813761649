/**
 * ******************************************************
 * Copyright (C) 2018 - 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * multisession-singleuser.service.ts --
 *
 * Service to store model and provide API for feature:
 * https://confluence.eng.vmware.com/display/HorizonArchitecture/
 * Allow+single+user+to+launch+multiple+instances+of+same+RDSH+App+on+multiple+clients
 *
 * We can move the appstatus into item model after merging the item model
 * of desktop and launcher page.
 * But for now, we use this server to avoid 2 sets of duplicated codes.
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { AB } from "../../desktop/common/appblast-util.service";
import { FeatureConfigs } from "../model/feature-configs";
import { LocalStorageService } from "@html-core";
import { ConnectionServerModel } from "../model/connection-server-model";
import { MultiSessionSingleUserSessionService } from "./multisession-singleuser-session.service";

@Injectable({
   providedIn: "root"
})
export class MultiSessionSingleUserService {
   private static readonly CONFIG_KEY: string = "MultiSessionSingleUserConfig";
   private appSettingMap = {};
   private perfMap = null;
   private initPromise = null;
   private isEnabled = false;

   constructor(
      private featureConfigs: FeatureConfigs,
      private localStorageService: LocalStorageService,
      private connectionServerModel: ConnectionServerModel,
      private multiSessionSingleUserSessionService: MultiSessionSingleUserSessionService
   ) {
      this.featureConfigs.registerListener("KillSwitch-MultiSessionSingleUser", (enabled) => {
         this.isEnabled = enabled;
      });
   }

   public setApps = (appsMap) => {
      const appSettingMap = {};
      if (!this.isEnabled) {
         Logger.debug("The multiSessionSingleUserService has not been disabled");
         return;
      }
      Logger.info("App model set for multi session single user");

      for (const applicationKey in appsMap) {
         if (appsMap.hasOwnProperty(applicationKey)) {
            const application = appsMap[applicationKey];
            const appSettings: any = {};
            switch (application["multi-session-mode"]) {
               case "":
               case "DISABLED":
                  appSettings.defaultEnabled = false;
                  appSettings.editable = false;
                  break;
               case "ENABLED_DEFAULT_OFF":
                  appSettings.defaultEnabled = false;
                  appSettings.editable = true;
                  break;
               case "ENABLED_DEFAULT_ON":
                  appSettings.defaultEnabled = true;
                  appSettings.editable = true;
                  break;
               case "ENABLED_ENFORCED":
                  appSettings.defaultEnabled = true;
                  appSettings.editable = false;
                  break;
            }
            appSettings.application = application;
            this.appSettingMap[application.id] = appSettings;
         }
      }
      if (!this.isInited()) {
         this.initPromise = new Promise((resolve, reject) => {
            this.loadPerf()
               .then(() => {
                  Logger.info("perf loaded for multi session single user");
                  this.initPromise = null;
                  // @ts-ignore
                  resolve();
               })
               .catch((e) => {
                  Logger.error("perf loaded failed for multi session single user");
                  this.initPromise = null;
                  reject(e);
               });
         });
      }
   };

   /**
    * Used when launching app to decide whether to carry tag.
    * @param  {string} appId The target App id.
    * @return {boolean}      Whether <multi-session> tag should be carried in request
    *
    * There is a bug in the webpack so that async await doesn't work
    */
   public getAppStatus = (appId): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         let useMultiSessionTag = false;
         if (!this.isEnabled) {
            Logger.debug("The multiSessionSingleUserService has not been disabled");
            resolve(useMultiSessionTag);
            return;
         }

         this.initedDone().then(() => {
            if (!this.isInited()) {
               Logger.error("The multiSessionSingleUserService has not been inited with data");
               resolve(useMultiSessionTag);
               return;
            }

            if (!this.appSettingMap.hasOwnProperty(appId)) {
               Logger.error("failed to find the multi-session for single-user " + "setting for app with id " + appId);
               resolve(useMultiSessionTag);
               return;
            }
            if (this.perfMap.hasOwnProperty(appId)) {
               useMultiSessionTag = this.perfMap[appId];
            } else {
               useMultiSessionTag = this.appSettingMap[appId].defaultEnabled;
            }
            resolve(useMultiSessionTag);
            return;
         });
      });
   };

   /**
    * @return {Array} Return the array for displaying editable checkboxs, each of
    * {
    *    id: @string,
    *    selected: @boolean,
    *    initialValue: @boolean
    * }
    */
   public getEditableStatusArray = () => {
      const editableStatusMap = [];

      if (!this.isEnabled) {
         Logger.debug("The multiSessionSingleUserService has not been disabled");
         return editableStatusMap;
      }

      if (!this.isInited()) {
         Logger.error("The multiSessionSingleUserService has not been inited with data");
         return editableStatusMap;
      }

      for (const appId in this.appSettingMap) {
         if (this.appSettingMap.hasOwnProperty(appId) && this.appSettingMap[appId].editable) {
            let enabled;
            if (this.perfMap.hasOwnProperty(appId)) {
               enabled = this.perfMap[appId];
            } else {
               enabled = this.appSettingMap[appId].defaultEnabled;
            }
            editableStatusMap.push({
               id: appId,
               selected: enabled,
               initialValue: enabled,
               iconSrc: this.getIconURL(this.appSettingMap[appId].application),
               name: this.appSettingMap[appId].application.name
            });
         }
      }
      return editableStatusMap;
   };

   public getEnforcedArray = () => {
      const enforcedMap = [];
      if (!this.isEnabled) {
         Logger.debug("The multiSessionSingleUserService has not been disabled");
         return enforcedMap;
      }

      if (!this.isInited()) {
         Logger.error("The multiSessionSingleUserService has not been inited with data");
         return enforcedMap;
      }

      for (const appId in this.appSettingMap) {
         if (
            this.appSettingMap.hasOwnProperty(appId) &&
            !this.appSettingMap[appId].editable &&
            this.appSettingMap[appId].defaultEnabled
         ) {
            enforcedMap.push({
               id: appId,
               iconSrc: this.getIconURL(this.appSettingMap[appId].application),
               name: this.appSettingMap[appId].application.name
            });
         }
      }
      return enforcedMap;
   };

   /**
    * Since we use Apply UX design, status change will be in a map.
    * @param {array} status array of
    * {
    *    id: @string,
    *    selected: @boolean,
    *    initialValue: @boolean
    * }
    * No check for the key validation, since it's not a open API.
    */
   public setAppStatus = (selectedStatusArray) => {
      if (!this.isEnabled) {
         Logger.error("Failed to set, the multiSessionSingleUserService has not been disabled");
         return;
      }
      selectedStatusArray.forEach((selectedStatus) => {
         if (selectedStatus.initialValue !== selectedStatus.selected) {
            if (
               !this.appSettingMap.hasOwnProperty(selectedStatus.id) ||
               !this.appSettingMap[selectedStatus.id].editable
            ) {
               Logger.warning("trying to set value for not editable keys");
               return;
            }
            this.perfMap[selectedStatus.id] = selectedStatus.selected;
         }
      });
      this.localStorageService.set(
         this.getStorageKey(MultiSessionSingleUserService.CONFIG_KEY),
         JSON.stringify(this.perfMap)
      );
   };

   public hasConfigDisplayableApp = async () => {
      await this.initedDone();
      return this.getEditableStatusArray().length > 0 || this.getEnforcedArray().length > 0;
   };

   /**
    * for UT
    */
   public getAppSettingMap = () => {
      return this.appSettingMap;
   };

   public setEnable = (enabled) => {
      this.isEnabled = enabled;
   };

   public resolvePerf = (perf) => {
      this.initPromise = null;
      this.setPerf(perf);
   };

   public resetAllMultiSessions = () => {
      if (!this.isEnabled) {
         return;
      }
      this.multiSessionSingleUserSessionService.resetAllMultiSessions();
   };

   public cacheMultiSessionLaunchData = (launchData) => {
      if (!this.isEnabled) {
         return;
      }
      this.multiSessionSingleUserSessionService.addConnectionInfo(launchData);
   };

   /**
    * Fetch launch info for mocking XML response
    * @param  {string} sessionId The sessionId for target application in the Multi-session mode
    * @return {object}          The mocked XML response object
    */
   public fetchMultiSessionLaunchData = (sessionId) => {
      if (!this.isEnabled) {
         return null;
      }
      return this.multiSessionSingleUserSessionService.getConnectionInfo(sessionId);
   };

   /**
    * @return {Boolean} Whether there is any Multi-session app sessions related
    *    to this broker session.
    *    currently if they are not connected, although the button is clickable,
    *    no XML will be sent to reset those sessions.
    */
   public hasResettableSessions = () => {
      if (!this.isEnabled) {
         return false;
      }
      return this.multiSessionSingleUserSessionService.hasResettableSessions();
   };

   /**
    * Invoked when enough data are gethered to establish a connection for a new Multi-session app session
    */
   public onConnectMultiSession = (session) => {
      if (!this.isEnabled) {
         return;
      }
      this.multiSessionSingleUserSessionService.onSessionConnected(session);
   };

   public onSessionRemoved = (session) => {
      if (!this.isEnabled) {
         return;
      }
      this.multiSessionSingleUserSessionService.onSessionRemoved(session);
   };

   public onSessionRemovedForTimeout = (sessionKey) => {
      if (!this.isEnabled) {
         return;
      }
      this.multiSessionSingleUserSessionService.onSessionRemovedForTimeout(sessionKey);
   };

   /**
    * @param  {string}  sessionId sessionId of the Multi-session app session
    * @return {Boolean}          Whether has connected multi-session app session with same sessionId
    */
   public hasConnectedSession = (sessionId) => {
      if (!this.isEnabled) {
         return;
      }
      return this.multiSessionSingleUserSessionService.hasConnectedSession(sessionId);
   };

   /**
    * Use this function only for Multi-session sessions
    * @param  {string} sessionId   sessionId of the Multi-session app session
    * @param  {string} reconnectToken The token for the session
    */
   public updateReconnectToken = (sessionId, reconnectToken) => {
      this.multiSessionSingleUserSessionService.updateReconnectToken(sessionId, reconnectToken);
   };

   public clearAll = () => {
      this.multiSessionSingleUserSessionService.clearAll();
   };

   private isInited = () => {
      return !this.initPromise && this.appSettingMap && this.perfMap;
   };

   private getStorageKey = (key: string): string => {
      // If username is not available, use token username
      // instead.
      const hostAddress = this.connectionServerModel.host;
      const userName = this.connectionServerModel.username || this.connectionServerModel.tokenUsername;

      return hostAddress + "-USER-" + userName + "-KEY-" + key;
   };

   private setPerf = (prefMapJSON: string) => {
      if (prefMapJSON) {
         this.perfMap = JSON.parse(prefMapJSON);
         Logger.info("perf restored for multi session single user: " + prefMapJSON);
      } else {
         Logger.info("there is no perf stored for multi session single user");
         this.perfMap = {};
      }
      this.clearOutDatedPerf();
   };

   private initedDone = async () => {
      if (this.initPromise) {
         await this.initPromise;
      }
   };

   private clearOutDatedPerf = () => {
      if (!this.isInited()) {
         return;
      }
      for (const appId in this.perfMap) {
         if (this.perfMap.hasOwnProperty(appId)) {
            if (!this.appSettingMap.hasOwnProperty(appId) || !this.appSettingMap[appId].editable) {
               delete this.perfMap[appId];
            }
         }
      }
   };

   /**
    * This setting is per Broker and Per user and Per client,
    * and the user/borker map is handled outside this service.
    * @param {string} prefMap The JSON string of app id and user options.
    * option will be saved once changed and applied.
    */
   private loadPerf = async () => {
      if (!this.isEnabled) {
         return;
      }
      const prefMapJSON = await this.localStorageService.get(
         this.getStorageKey(MultiSessionSingleUserService.CONFIG_KEY)
      );
      this.setPerf(prefMapJSON);
   };

   private getIconURL = (application) => {
      let iconSrcStr = AB.ICONS.SMALL_DEFAULT_ICON;
      let iconIndex = AB.DEFAULT_ICON_INDEX;
      if (iconIndex !== -1) {
         iconSrcStr = application.icons[iconIndex].path;
      }
      return iconSrcStr;
   };
}
