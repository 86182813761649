/**
 * *****************************************************
 * Copyright 2020-2023 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { core } from "../../libs/coreUtil";
import { IStorage, IStorageService } from "./storage.interface";
import { ChromeStorage } from "./chrome-storage.service";
import { Logger } from "@html-core";
export class LocalStorage implements IStorage {
   public getItem(inKey: string): any {
      return localStorage[inKey];
   }
   public setItem(inKey: string, value: any): void {
      localStorage[inKey] = value;
   }
   public removeItem(inKey: string): void {
      delete localStorage[inKey];
   }
}

class SessionStorage implements IStorage {
   public getItem(inKey: string): any {
      return sessionStorage[inKey];
   }
   public setItem(inKey: string, value: any): void {
      sessionStorage[inKey] = value;
   }
   public removeItem(inKey: string) {
      delete sessionStorage[inKey];
   }
   public clear() {
      sessionStorage.clear();
   }
}

@Injectable({
   providedIn: "root"
})
export class LocalStorageService extends IStorageService {
   storage: IStorage = null;
   sessionStorage: SessionStorage = null;

   constructor() {
      super();
      this.sessionStorage = new SessionStorage();
      try {
         if (core.isChromeClient()) {
            this.storage = new ChromeStorage();
            return;
         }
      } catch (e) {
         Logger.error(e);
      }
      this.storage = new LocalStorage();
   }

   public set(key: string, value: any) {
      this.storage.setItem(key, value);
   }

   public get(key: string): any {
      return this.storage.getItem(key);
   }

   public remove(key: string) {
      this.storage.removeItem(key);
   }

   public setSession(key: string, value: any) {
      this.sessionStorage.setItem(key, value);
   }

   public getSession(key: string): string {
      return this.sessionStorage.getItem(key);
   }

   public removeSession(key: string) {
      this.sessionStorage.removeItem(key);
   }

   public clearSession() {
      this.sessionStorage.clear();
   }
}
