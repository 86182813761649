/**
 * ******************************************************
 * Copyright (C) 2018-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, OnDestroy } from "@angular/core";
import { AbstractModalComponent } from "../../../common/commondialog/abstract-modal.component";
import { ConnectionServerModel } from "../../../common/model/connection-server-model";
import { FeatureConfigs } from "../../../common/model/feature-configs";
import { PreDataSetModel } from "../../../common/model/pre-data-set-model";
import { clientUtil, TranslateService } from "@html-core";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import Logger from "../../../../core/libs/logger";
import { LocalStorageService, LocalLogService } from "@html-core";
import { ViewClientModel } from "../../../common/model/viewclient-model";

@Component({
   selector: "pre-setting-dialog",
   templateUrl: "./pre-setting-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class PreSettingDialogComponent extends AbstractModalComponent implements OnDestroy {
   private adminSettings;
   private onCollectDataKillSwitch;
   private isAnonymousLoginEnabled;
   private isDataSharingEnabled;
   private isManagedByPolicy = true;
   private isCollectLogEnabled;
   private isCollectLogSupport;
   private logPath;

   public logPathInfo;
   public toggleSwitches;
   public killSwitches;
   public fileExtensionList;
   public defaultAppMap = {};
   public filteredAppsInfo;

   constructor(
      private connectionServerModel: ConnectionServerModel,
      private featureConfigs: FeatureConfigs,
      private preDataSetModel: PreDataSetModel,
      private modalDialogService: ModalDialogService,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private localLogService: LocalLogService,
      private viewClientModel: ViewClientModel
   ) {
      super();
      this.adminSettings = this.connectionServerModel.googleCommonAdminSettings || {};
      this.setUp();
   }

   ngOnDestroy() {
      this.onDestroy();
   }

   private isSettingEditable = (id) => {
      if (!!this.adminSettings && this.adminSettings[id] !== undefined) {
         if (!this.adminSettings["editable"]) {
            // if that setting has been set by google admin, but google admin has not set key "editale"
            // use default behavior for that setting, which is client cannot edit that setting
            return false;
         } else {
            if (this.adminSettings["editable"][id] === undefined || this.adminSettings["editable"][id] === false) {
               // if that setting has been set by google admin, but google admin key "editale" has not that
               // setting or set false for that setting, then client cannot edit that setting
               return false;
            } else {
               // if google admin set "editable" for true for that setting, client can edit that setting
               return true;
            }
         }
      } else {
         // if google admin has not set or google admin not set that setting, client behavior is the same as without google
         // admin setting, client can set that setting.
         return true;
      }
   };

   public isSettingGrayOut = (id) => {
      return !this.isSettingEditable(id);
   };

   public isSwitchDisabled = (switchItem) => {
      let result = false;
      switch (switchItem.id) {
         case "anonymous_login": {
            result = this.isSettingGrayOut("enableAnonymousLogin");
            break;
         }
         case "data_sharing": {
            result = this.isSettingGrayOut("allowDataSharing");
            break;
         }
         default: {
            break;
         }
      }
      return result;
   };

   private onDestroy = () => {
      this.unbindKillSwitchse();
   };

   /**
    * kill switch can take affect in real time.
    */
   private _bindKillSwitch = (switchName, key, onChange?) => {
      const handle = (killSwitchOn) => {
         setTimeout(() => {
            this.killSwitches[key] = killSwitchOn;
            if (typeof onChange === "function") {
               onChange();
            }
         });
      };
      this.featureConfigs.registerListener("KillSwitch-" + switchName, handle);
      return handle;
   };

   /**
    * kill switch can take affect in real time.
    */
   private _unbindKillSwitch = (switchName, handle) => {
      this.featureConfigs.unregisterListener("KillSwitch-" + switchName, handle);
   };

   /**
    * kill switch can take affect in real time.
    * can close sub dialog if adding close to dialog Service.
    */
   private bindKillSwitches = () => {
      this.killSwitches = {};
      this.onCollectDataKillSwitch = this._bindKillSwitch("AllowDataSharing", "data_sharing");
   };

   /**
    * kill switch can take affect in real time.
    */
   private unbindKillSwitchse = () => {
      this._unbindKillSwitch("AllowDataSharing", this.onCollectDataKillSwitch);
      this.onCollectDataKillSwitch = undefined;
   };

   public isTheLastActiveItem = (id) => {
      let lastItem;
      for (const item of this.toggleSwitches) {
         if (item.isSupported) {
            lastItem = item;
         }
      }
      return lastItem.id === id;
   };

   private getTrustPolicy = (): Promise<any> => {
      return new Promise((resolve, reject) => {
         if (typeof window.chromeClient.getTrustPolicy !== "function") {
            reject();
         } else {
            window.chromeClient
               .getTrustPolicy()
               .then((trustedPolicy) => {
                  resolve(trustedPolicy);
               })
               .catch(reject);
         }
      });
   };

   private setUp = async () => {
      this.bindKillSwitches();
      this.updateStatus();
      this.updateCollectLogStatus();
      this.updateLogLevel();
      try {
         const trustPolicy = await this.getTrustPolicy();
         this.isManagedByPolicy = !!trustPolicy;
         Logger.info("read trusted model for policy, policy exist=" + this.isManagedByPolicy);
         if (this.isManagedByPolicy) {
            Logger.debug("trust policy: " + JSON.stringify(trustPolicy));
         }
      } catch (error) {
         this.isManagedByPolicy = true;
         Logger.error("Failed to get trusted model, lock the setting");
      }

      this.toggleSwitches = [
         {
            id: "data_sharing",
            label: this.translate._T("DATA_SHARING_T"),
            text: this.translate._T("WARN_DATA_SHARING"),
            isSupported: true,
            isEnabled: this.isDataSharingEnabled,
            toggle: this.toggleDataSharing
         },
         {
            id: "anonymous_login",
            label: this.translate._T("ANONYMOUS_LOGIN_T"),
            isSupported: clientUtil.isChromeClient(),
            isEnabled: this.isAnonymousLoginEnabled,
            toggle: this.toggleAnonymousLogin
         },
         {
            id: "collect_log",
            label: this.translate._T("collect_log_t"),
            text: "",
            isSupported: clientUtil.isChromeClient(),
            isEnabled: this.isCollectLogEnabled,
            toggle: this.toggleCollectLog
         },
         {
            id: "trusted_apps",
            label: this.translate._T("TRUSTED_APPS"),
            text: this.isSettingGrayOut("addTrustedApps") ? this.translate._T("TRUSTED_APPS_DETAIL") : "",
            isSupported: clientUtil.isChromeClient(),
            isSettingGrayOut: this.isManagedByPolicy,
            hiddenBtn: true
         },
         {
            id: "file_association_default_app",
            label: this.translate._T("CHANGE_DEFAULT_FILE_ASSOCIATION_APP_TITLE"),
            isSupported: clientUtil.isChromeClient(),
            hiddenBtn: true
         }
      ];
   };

   private updateStatus = () => {
      // For SR 2994646: Put the fetching from adminSettings together with the fetching from preDataSetModel
      [
         {
            adminName: "allowDataSharing",
            modelName: "dataSharingAllowed",
            propName: "isDataSharingEnabled"
         },
         {
            adminName: "enableAnonymousLogin",
            modelName: "anonymousLogin",
            propName: "isAnonymousLoginEnabled"
         }
      ].forEach(({ adminName, modelName, propName }) => {
         // Only editable settings can take value from the preDataSetModel
         if (this.isSettingEditable(adminName)) {
            this[propName] = this.preDataSetModel.settingData[modelName];
         }
         // Non-editable settings and settings with no value in preDataSetModel are taken from adminSettings
         if (this[propName] === undefined) {
            this[propName] = this.adminSettings[adminName];
            this.preDataSetModel.setDataValue(modelName, this.isDataSharingEnabled);
         }
      });
   };

   private updateCollectLogStatus = () => {
      if (clientUtil.isChromeClient()) {
         this.isCollectLogSupport = true;
         this.isCollectLogEnabled = this.localStorageService.get("isCollectLogEnabled") === "true";
         const path = this.localStorageService.get("chromeClientLogPath");
         if (path) {
            this.updateElementForLog(path, "path");
         }
      }
   };

   private toggleDataSharing = () => {
      this.isDataSharingEnabled = !this.isDataSharingEnabled;
      this.preDataSetModel.setDataValue("dataSharingAllowed", this.isDataSharingEnabled);
   };

   private toggleAnonymousLogin = () => {
      this.isAnonymousLoginEnabled = !this.isAnonymousLoginEnabled;
      this.preDataSetModel.setDataValue("anonymousLogin", this.isAnonymousLoginEnabled);
   };

   private toggleCollectLog = () => {
      this.isCollectLogEnabled = !this.isCollectLogEnabled;
      this.preDataSetModel.setDataValue("isCollectLogEnabled", this.isCollectLogEnabled);
      if (this.isCollectLogEnabled) {
         //turn on
         this.localStorageService.set("isCollectLogEnabled", "true");
         this.localLogService
            .startLogging()
            .then((path) => {
               this.localStorageService.set("chromeClientLogPath", path);
               if (path === undefined) {
                  //if cancel the select file, path will return undefined
                  this.isCollectLogEnabled = false;
                  this.localStorageService.set("isCollectLogEnabled", "false");
               }
               this.updateElementForLog(path, "path");
            })
            .catch((e) => {
               Logger.exception(e);
               setTimeout(() => {
                  this.isCollectLogEnabled = false;
               });
            });
         this.updateLogLevel();
      } else {
         // turn off
         this.localStorageService.set("isCollectLogEnabled", "false");
         this.localStorageService.set("chromeClientLogPath", null);
         this.localStorageService.set("logLevel", Logger.LEVEL_INFO);
         this.updateElementForLog(null, "path");
         this.localLogService.endLogging();
      }
   };

   private updateElementForLog = (value, type) => {
      setTimeout(() => {
         if (type === "path") {
            if (value) {
               this.logPath = value;
               this.logPathInfo = this.translate._T("collect_log_m1") + value + this.translate._T("collect_log_m2");
            } else {
               this.logPath = "";
               this.logPathInfo = "";
            }
         } else if (type === "loglevel") {
            const logLevelButtons: any = document.getElementsByName("log-level");
            if (value >= 0 && value <= 3) {
               for (let i = 0; i < logLevelButtons.length; i++) {
                  switch (logLevelButtons[i].value) {
                     case "info":
                        logLevelButtons[i].checked = value === Logger.LEVEL_INFO;
                        break;
                     case "debug":
                        logLevelButtons[i].checked = value === Logger.LEVEL_DEBUG;
                        break;
                     case "trace":
                        logLevelButtons[i].checked = value === Logger.LEVEL_TRACE;
                        break;
                  }
               }
            }
         }
      });
   };

   private updateLogLevel = () => {
      if (clientUtil.isChromeClient()) {
         let logLevel: number;
         if (this.localStorageService.get("logLevel") === null) {
            logLevel = Logger.LEVEL_INFO;
         } else {
            logLevel = Number(this.localStorageService.get("logLevel"));
         }
         setTimeout(() => {
            this.updateElementForLog(logLevel, "loglevel");
            Logger.setLogLevel(logLevel);
            this.viewClientModel.logLevel = logLevel;
         }, 10);
      }
   };

   public changeLogLevel = () => {
      const logLevelButton: any = document.getElementsByName("log-level");
      let logLevel = Logger.LEVEL_INFO;
      for (let i = 0; i < logLevelButton.length; i++) {
         if (logLevelButton[i].checked) {
            if (logLevelButton[i].value === "info") {
               logLevel = Logger.LEVEL_INFO;
            } else if (logLevelButton[i].value === "debug") {
               logLevel = Logger.LEVEL_DEBUG;
            } else if (logLevelButton[i].value === "trace") {
               logLevel = Logger.LEVEL_TRACE;
            }
            Logger.info("Change log level to " + logLevel);
            Logger.setLogLevel(logLevel);
            this.localStorageService.set("logLevel", logLevel);
            break;
         }
      }
   };

   public changeLogFile = () => {
      this.localLogService.startLogging("changeFile").then((path) => {
         if (path) {
            chrome.storage.local.set({ chromeClientLogPath: path });
            this.updateElementForLog(path, "path");
         }
      });
   };

   public showAddTrustedAppsDialog = () => {
      this.modalDialogService.showSettingTrustedApps();
   };

   public showDefaultAppDialog = () => {
      this.modalDialogService.showSettingFileAssociation();
   };

   public onKeypress = (evt) => {
      if (!!evt && (evt.keyCode === 32 || evt.keyCode === 13)) {
         // Space key or enter key is pressed.
         if (evt.target.children && evt.target.children[0]) {
            $(evt.target.children[0]).trigger("click");
         }
         // Prevent default behavior.
         evt.preventDefault();
      }
   };
}
