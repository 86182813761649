/**
 * *********************************************************
 * Copyright (C) 2017-2024 VMware, Inc. All rights reserved.
 * *********************************************************
 *
 * @format
 */

import { getBrowserCultureLang } from "@html-core";

/*
 *  Example of clientInfo.jsp for horizon 8
 * {
 *    "clientVersion":"8.0.0",
 *    "disableCEIP":"false",
 *    "disableReconnectForWS1":"false",
 *    "logLevel":"1",
 *    "rebrandName":"\"my little broker\"",
 *    "rebrandHelpUrl":"",
 *    "rebrandGotoForumUrl":"",
 *    "contextPath":"/portal",
 *    "feature":{},
 *    "os":"mac",
 *    "installerLink":"https://www.vmware.com/go/viewclients#mac"}
 *
 */

export class HorizonClientInfo {
   os: string = null;
   logLevel: number = 2;
   feature = {};
   ipAddress: string = null;
   contextPath: string = null;
   rebrandName: string = null;
   rebrandHelpUrl: string = null;
   clientVersion: string = null;
   disableCEIP: boolean = false;
   acceptLanguage: string = null;
   installerLink: string = null;
   rebrandGotoForumUrl: string = null;
   disableReconnectForWS1: boolean = false;
   csrfCheck: boolean = false;
   disableIPv6: boolean = false;
   useWindowReplacementApi: boolean = true;
   enterFullscreenIfOneMonitor: boolean = true;
   vrCheck: boolean = false;
   enableKeyMapping: boolean = true;
   enableNetworkIndicator: boolean = true;
   networkStateTcpRttMSLow: number = 2;
   networkStateTcpRttMSHigh: number = 400;
   networkStateQualityScoreTcpThresholdGood: number = 85;
   networkStateQualityScoreTcpThresholdPoor: number = 40;
   networkStateRttCheckPeriodMs: number = 15000;
   enableRTAVH264Codec: boolean = true;
   enableRTAVOpusCodec: boolean = true;
   enableRTAVDTX: boolean = true;
   enableExtendedAPI: boolean = false;
   hardwareAccelerationOption: string = "no-preference";
   enableDownloadInstaller: boolean = true;
   enableBlastCodec: boolean = false;
}

export abstract class ClientInfoService {
   protected clientInfo: HorizonClientInfo = null;
   public abstract getConfigInfo(): Promise<HorizonClientInfo>;

   public getClientInfo = (): HorizonClientInfo => {
      return this.clientInfo;
   };

   protected generateClientInfo(data): HorizonClientInfo {
      const info = new HorizonClientInfo();

      if (typeof data.logLevel === "string" && !isNaN(Number(data.logLevel))) {
         info.logLevel = Number(data.logLevel);
      }

      if (typeof data.disableReconnectForWS1 === "string") {
         info.disableReconnectForWS1 = data.disableReconnectForWS1 === "true";
      }

      if (typeof data.disableCEIP === "string") {
         info.disableCEIP = data.disableCEIP === "true";
      }

      if (typeof data.logLevel === "number" && !isNaN(data.logLevel)) {
         info.logLevel = data.logLevel;
      }

      if (typeof data.csrfCheck === "string") {
         info.csrfCheck = data.csrfCheck === "true";
      }

      if (typeof data.disableIPv6 === "string") {
         info.disableIPv6 = data.disableIPv6 === "true";
      }

      if (typeof data.useWindowReplacementApi === "string") {
         info.useWindowReplacementApi = data.useWindowReplacementApi === "true";
      }

      if (typeof data.enterFullscreenIfOneMonitor === "string") {
         info.enterFullscreenIfOneMonitor = data.enterFullscreenIfOneMonitor === "true";
      }

      if (typeof data.vrCheck === "string") {
         info.vrCheck = data.vrCheck === "true";
      } else if (data.vrCheck === undefined) {
         info.vrCheck = undefined;
      }

      if (typeof data.enableKeyMapping === "string") {
         info.enableKeyMapping = data.enableKeyMapping === "true";
      } else if (data.enableKeyMapping === undefined) {
         info.enableKeyMapping = true;
      }

      if (typeof data.enableNetworkIndicator === "string") {
         info.enableNetworkIndicator = data.enableNetworkIndicator === "true";
      } else if (data.enableNetworkIndicator === undefined) {
         info.enableNetworkIndicator = true;
      }

      if (typeof data.networkStateTcpRttMSLow === "string" && !isNaN(Number(data.networkStateTcpRttMSLow))) {
         info.networkStateTcpRttMSLow = Number(data.networkStateTcpRttMSLow);
      }

      if (typeof data.networkStateTcpRttMSHigh === "string" && !isNaN(Number(data.networkStateTcpRttMSHigh))) {
         info.networkStateTcpRttMSHigh = Number(data.networkStateTcpRttMSHigh);
      }

      if (
         typeof data.networkStateQualityScoreTcpThresholdGood === "string" &&
         !isNaN(Number(data.networkStateQualityScoreTcpThresholdGood))
      ) {
         info.networkStateQualityScoreTcpThresholdGood = Number(data.networkStateQualityScoreTcpThresholdGood);
      }

      if (
         typeof data.networkStateQualityScoreTcpThresholdPoor === "string" &&
         !isNaN(Number(data.networkStateQualityScoreTcpThresholdPoor))
      ) {
         info.networkStateQualityScoreTcpThresholdPoor = Number(data.networkStateQualityScoreTcpThresholdPoor);
      }

      if (
         typeof data.networkStateRttCheckPeriodMs === "string" &&
         !Number.isNaN(Number(data.networkStateRttCheckPeriodMs))
      ) {
         info.networkStateRttCheckPeriodMs = Number(data.networkStateRttCheckPeriodMs);
      }

      if (typeof data.enableRTAVH264Codec === "string") {
         info.enableRTAVH264Codec = data.enableRTAVH264Codec === "true";
      } else if (data.enableRTAVH264Codec === undefined) {
         info.enableRTAVH264Codec = true;
      }

      if (typeof data.enableRTAVOpusCodec === "string") {
         info.enableRTAVOpusCodec = data.enableRTAVOpusCodec === "true";
      } else if (data.enableRTAVOpusCodec === undefined) {
         info.enableRTAVOpusCodec = true;
      }

      if (typeof data.enableRTAVDTX === "string") {
         info.enableRTAVDTX = data.enableRTAVDTX === "true";
      } else if (data.enableRTAVDTX === undefined) {
         info.enableRTAVDTX = true;
      }

      if (typeof data.enableExtendedAPI === "string") {
         info.enableExtendedAPI = data.enableExtendedAPI === "true";
      } else if (data.enableExtendedAPI === undefined) {
         info.enableExtendedAPI = false;
      }

      if (typeof data.enableDownloadInstaller === "string") {
         info.enableDownloadInstaller = data.enableDownloadInstaller === "true";
      } else if (data.enableDownloadInstaller === undefined) {
         info.enableDownloadInstaller = true;
      }

      if (
         data.hardwareAccelerationOption === "prefer-hardware" ||
         data.hardwareAccelerationOption === "prefer-software"
      ) {
         info.hardwareAccelerationOption = data.hardwareAccelerationOption;
      } else {
         info.hardwareAccelerationOption = "no-preference";
      }

      if (typeof data.enableBlastCodec === "string") {
         info.enableBlastCodec = data.enableBlastCodec === "true";
      } else if (data.enableBlastCodec === undefined) {
         info.enableBlastCodec = false;
      }

      info.os = data.os ? data.os : null;
      info.feature = data.feature;
      info.ipAddress = data.ipAddress ? data.ipAddress : null;
      info.contextPath = data.contextPath ? data.contextPath : null;
      info.rebrandName = data.rebrandName ? data.rebrandName : null;
      info.rebrandHelpUrl = data.rebrandHelpUrl ? data.rebrandHelpUrl : null;
      info.acceptLanguage = getBrowserCultureLang();
      info.installerLink = data.installerLink ? data.installerLink : null;
      info.rebrandGotoForumUrl = data.rebrandGotoForumUrl ? data.rebrandGotoForumUrl : null;
      return info;
   }
}
