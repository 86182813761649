/**
 * ******************************************************
 * Copyright (C) 2015-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * wmks-service.js --
 *
 * Module for managing multiple webmks adapter instances. Wmks Service is a
 * singleton service.
 *
 */

import Logger from "../../../core/libs/logger";
import { AB } from "./appblast-util.service";
import * as CST from "@html-core";
import { MultimonService } from "../multimon/main-page/multimon.service";
import { ClientSettingModel } from "../../common/model/client-setting-model";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { IdleSessionService } from "../../common/service/idle-session.service";
import { FeatureConfigs } from "../../common/model/feature-configs";
import { SessionUtil } from "../../common/service/session-util";
import { MultipleDisplayService } from "./multi-display.service";
import { Injector, Injectable, Type } from "@angular/core";
import { SessionMonitorService } from "./session-monitor.service";
import { BusEvent, EventBusService, clientUtil } from "@html-core";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { RTAVService } from "../../../shared/desktop/rtav/rtav.service";
import { XMLPreference } from "../../common/service/prefdata";
import { BlastOption } from "../wmks/blast-option";
import { Html5BlastSessionInstance } from "../wmks/html5-blast-session.instance";
import { TitanBlastSessionInstance } from "../wmks/titan-blast-session.instance";
import { BlastWmks } from "./blast-wmks.service";
import { smartCardDummyService } from "../smart-card/smart-card-dummy.service";
import { HtmlCDRService } from "../../../html5-client/desktop/cdr/html-cdr.service";
import { MonitorInfoService } from "../../common/service/monitor-info.service";
import { KeyboardSettingService, KeyMapping } from "../../common/keyboard-setting/keyboard-setting.service";
import { NetworkStateService } from "../networkState/network-state.service";
import { PreDataSetModel } from "../../common/model/pre-data-set-model";
@Injectable({
   providedIn: "root"
})
export class WmksService {
   private _isFolderSharingEnabled;
   private _isHighResModeEnabled;
   private _isMP4Enabled: boolean = false;
   private _areMacOSXKeyMappingsEnabled: boolean = false;
   private _multipleMonitor: boolean = false;
   private _isWindowsKeyEnabled: boolean = false;
   private _isWindowsDeleteKeyEnabled: boolean = false;
   private _isFitToViewerEnabled;
   private sessionMonitor: SessionMonitorService;
   private inited: boolean = false;
   private _enableRTAVH264Codec: boolean = true;
   private _enableRTAVOpusCodec: boolean = true;
   private _enableRTAVDTX: boolean = true;
   private _hardwareAccelerationOption: string = "no-preference";
   private _beforeunload: boolean = false;
   private _keyMappingSetting: KeyMapping[] = [];

   public get beforeunload() {
      return this._beforeunload;
   }

   constructor(
      private multimonService: MultimonService,
      private clientSettingModel: ClientSettingModel,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private remoteSessionEventService: RemoteSessionEventService,
      private idleSessionService: IdleSessionService,
      private featureConfigs: FeatureConfigs,
      private sessionUtil: SessionUtil,
      private multipleDisplayService: MultipleDisplayService,
      private eventBusService: EventBusService,
      private injector: Injector,
      private viewClientModel: ViewClientModel,
      private rtavService: RTAVService,
      private smartCardDummyService: smartCardDummyService,
      private htmlCdrService: HtmlCDRService,
      private monitorInfoService: MonitorInfoService,
      private keyboardSettingService: KeyboardSettingService,
      private networkStateService: NetworkStateService,
      private preDataSetModel: PreDataSetModel
   ) {
      if (CST.clientUtil.isChromeClient()) {
         Logger.debug("skip construct WmksService for chrome Client");
         return;
      }
      this._isFolderSharingEnabled = this.clientSettingModel.getBooleanItem("enableFolderSharing");
      this._isHighResModeEnabled = this.clientSettingModel.getBooleanItem("enableHighResolution");
      this._isFitToViewerEnabled = this.clientSettingModel.getBooleanItem("enableFitToViewer");
      this.sessionMonitor = new SessionMonitorService(this.injector, this);
      this.multipleDisplayService.setWmksService(this);
      this.eventBusService.listen(BusEvent.QuitMultiMonMsg.MSG_TYPE).subscribe(() => {
         this.quitMultimon();
      });
      this.eventBusService
         .listen(CST.BusEvent.DPIDataSyncSuccessEvent.MSG_TYPE)
         .subscribe((msg: CST.BusEvent.DPIDataSyncSuccessEvent) => {
            this.updateWMKSDPI(msg.wmksKey, msg.remoteScaleDPI);
         });
      const rtavInitArgs = {
         enableRTAVH264Codec: false,
         enableRTAVOpusCodec: false,
         enableRTAVDTX: false,
         hardwareAccelerationOption: ""
      };
      if ("VideoEncoder" in window) {
         // when the platform support WebCodecs API
         rtavInitArgs.enableRTAVH264Codec = this.viewClientModel.enableRTAVH264Codec;
         rtavInitArgs.hardwareAccelerationOption = this.viewClientModel.hardwareAccelerationOption;
         if (CST.clientUtil.isPlatformSupportOpus()) {
            rtavInitArgs.enableRTAVOpusCodec = this.viewClientModel.enableRTAVOpusCodec;
            rtavInitArgs.enableRTAVDTX = this.viewClientModel.enableRTAVDTX;
         }
      }
      this.rtavService.init(rtavInitArgs);
   }

   /*
    *init
    *
    * Init all the events we are listening to from postMessage
    * and browser native events.
    */
   public init = () => {
      if (this.inited === true) {
         return;
      } else {
         this.inited = true;
      }

      Logger.debug("wmksService is waiting for listeners ready.", Logger.WMKS);

      //TODO-Titan add a event to handle refresh cases.
      setTimeout(() => {
         this.sessionMonitor.init();
      }, 200);

      let shouldPassKeyboard: Function = null;

      this.eventBusService.listen("sessionExpired").subscribe(() => {
         this.closeAllSessions();
      });

      this.eventBusService.listen(BusEvent.CloseSessionsMsg.MSG_TYPE).subscribe(() => {
         this.closeAllSessions();
      });

      this.eventBusService.listen(BusEvent.DisconnectApp.MSG_TYPE).subscribe((msg: BusEvent.DisconnectApp) => {
         this.closeSession(msg.data);
      });

      this.eventBusService.listen(BusEvent.DisconnectAllMultiSessionApp.MSG_TYPE).subscribe(() => {
         this.closeAllMultiSessionApplications();
      });

      this.idleSessionService.addEventListener("idleSessionTimeout", this.requestDisconnectApplications);
      /*
       * Create a check on a DOM element to determine whether the element
       * should pass keyboard events to the canvas rather than consume it.
       * The behaviour is browser specific.
       */
      if (WMKS.BROWSER.isIE() && WMKS.BROWSER.version.major < 12) {
         shouldPassKeyboard = function (elem) {
            /*
             * In old IE, elements like divs are focusable and we check
             * isContentEditable to filter out elements like inputs from the
             * list of elements that should pass keyboard events.
             *
             * Windows Edge will behave like the other browsers
             */
            return !elem.isContentEditable && elem.nodeName !== "CANVAS";
         };
      } else {
         shouldPassKeyboard = function (elem) {
            /*
             * In non IE browsers, most elements aren't focusable and the
             * keyboard event gets targeted directly at the body unless it is
             * an input, etc.
             */
            return elem.nodeName === "BODY";
         };
      }

      if (WMKS.BROWSER.isSafari()) {
         window.document.addEventListener(
            "visibilitychange",
            () => {
               if (window.document.hidden) {
                  // Shadow heartbeat for Safari
                  this._shadowAllHeartbeatWhenSafariHidden();
               } else {
                  // Recover heartbeat for Safari
                  this._recoverAllHeartbeatWhenSafariVisible();
               }
            },
            false
         );
      }

      /*
       * We currently catch any key events not consumed by an input, canvas,
       * etc and redirects the input to the canvas. This ensures that we
       * don't lose any keystrokes while being focused on a non-interactable
       * part of the sidebar, since there's no UI indication that the sidebar
       * is focused.
       */
      $(window).bind("keypress keydown keyup", (e) => {
         const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
         if (currentSession && shouldPassKeyboard(e.target)) {
            const canvas = currentSession.wmksContainer.find("canvas");
            if (canvas.length > 0) {
               e.target = canvas[0];
               canvas.trigger(e);
            }
         }
      });

      $(window).bind("beforeunload", () => {
         this._beforeunload = true;
      });

      // Listen to onSize to adjust window size.
      if (WMKS.BROWSER.isTouchDevice() && this.featureConfigs.getConfig("KillSwitch-Touch")) {
         $(window).on("orientationchange", this._handleResize);
         $(window).on("touchstart", this._handleTouchStart);
         //change the main div to position fixed, bug 1583810
         $("#main").css({ position: "fixed" });
      }
      // add resize for touch device to solve bug 1916087
      // screen need to resize if open soft keyboard on touch device
      $(window).on("resize", this._handleResize);
   };

   public setPreference = (prefData: XMLPreference): void => {
      this._isHighResModeEnabled = prefData.enableHighResolution === "true";
      this._isFolderSharingEnabled = prefData.enableFolderSharing === "true";
      this._areMacOSXKeyMappingsEnabled = WMKS.BROWSER.isMacOS() && prefData.useMacOSXKeyMappings !== "false";
      this._isWindowsKeyEnabled = prefData.enableWindowsKey === "true";
      this._isWindowsDeleteKeyEnabled = prefData.enableWindowsDeleteKey === "true";
      this._isMP4Enabled = prefData.enableMP4 === "true";
      this._multipleMonitor = prefData.enableMultiMonitor === "true";
      this._isFitToViewerEnabled = prefData.enableFitToViewer === "true";
      if (prefData.keyMappingSetting) {
         try {
            this._keyMappingSetting = JSON.parse(prefData.keyMappingSetting);
         } catch (e) {
            Logger.debug("fail to parse key mapping data", Logger.WMKS);
         }
      }
   };

   /**
    * For vcart-1271, we always initially disable "display scale" for all cases
    * and move the fixed resolution setting into initialization phase.
    * And resolution is requested to be large enough, so set resolution again
    * if the intial setting failed, since Linux Agent don't support the intial
    * resolution setting.
    */

   public onResolutionUpdated = (session, agentPixel) => {
      // Used to bypass issues
      const targetPixel = CST.clientUtil.getWindowPixels();
      // Diff Android and iOS
      if (WMKS.BROWSER.isAndroid()) {
         this.handleTouchResizeWithoutMutimon(session, agentPixel, targetPixel);
      } else {
         const screenSize = CST.clientUtil.getWindowResolution();
         this._onResolutionUpdatedCommon(session, agentPixel, targetPixel, screenSize);
      }
   };

   /**
    * hasMultimonCapacity
    *
    * Return whether the current session supporting multimon
    */
   public hasMultimonCapacity = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession) {
         return false;
      }

      return currentSession.multimonServerEnabled;
   };

   public getCurrentSession = () => {
      return this.htmlRemoteSessionManager.getCurrentSession();
   };

   /**
    * addMonitor
    */
   public addMonitor = (uiCallback, closeSidebar, screenSetting) => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (
         currentSession &&
         currentSession.hasOwnProperty("wmksContainer") &&
         currentSession.wmksContainer.length >= 1
      ) {
         Logger.info("add a monitor for session " + currentSession.key, Logger.WMKS);
         const useMultiMonitor = this.monitorInfoService.useMultiMonitor();
         const options = {
            enableH264: AB.isMP4Supported() && this._isMP4Enabled && !useMultiMonitor
         };
         this.multimonService.addMonitor(
            currentSession,
            currentSession.wmksContainer[0],
            uiCallback,
            closeSidebar,
            this._handleResize,
            options,
            screenSetting
         );
         $(AB.CANVAS_PARENT_ID).css("overflow", "visible");
      }
   };

   /**
    * quitMultimon
    */
   public quitMultimon = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         Logger.info("add a monitor for session " + currentSession.key, Logger.WMKS);
         this.multimonService.cancelForMultimon();
         this.multimonService.switchToSingleMon(currentSession);
         $(AB.CANVAS_PARENT_ID).css("overflow", "hidden");
      }
   };

   /**
    * destroyMultimon
    */
   public destroyMultimon = () => {
      this.multimonService.destroyMultimon();
   };

   public preparingForMultimon = () => {
      this.multimonService.preparingForMultimon();
   };

   /**
    * Will processing only when the current session is not in the multimon
    *     mode
    * @param  {function} processingFunction The processing function
    */
   public whenNotInMultimon = (processingFunction) => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         this.multimonService.whenNotInMultimon(processingFunction);
      } else {
         processingFunction();
      }
   };

   public enterMultimon = () => {
      //To fix bug 2163927, to remove the listener of resize, and add it back on quit
      $(window).off("resize", this._handleResize);
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         Logger.info("enter multimon for session " + currentSession.key, Logger.WMKS);
         $("#sidebar-fullscreen-button").hide();
         this.multimonService.enterMultimonMode();
      }
   };

   public recoveryEventConnections = () => {
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [key, wmksInstance] of wmksSessionMap) {
         try {
            wmksInstance.wmks("connectEvents");
         } catch (e) {
            Logger.debug("fail to reconnect wmks events, please check the network", Logger.WMKS);
         }
      }
   };

   public disconnectEventConnections = () => {
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [key, wmksInstance] of wmksSessionMap) {
         wmksInstance.wmks("disconnectEvents");
      }
      /**
       * Adding some extra lines since wmks is not symatrix so we can't remove
       * all the events by calling disconnectEvents, which seems can't be done
       * easily.
       */
      $(window).unbind("mousedown.wmks");
      $(window).unbind("mousewheel.wmks");
      $(window).unbind("mousemove.wmks");
      $(window).unbind("mouseup.wmks");
   };

   /**
    * requestDisconnect
    *
    * Attempt a graceful disconnect from the server. If the server is
    * already disconnected, triggers a page reflow.
    */
   public closeSession = (id: string) => {
      if (id) {
         const session = this.htmlRemoteSessionManager.getSessionById(id);
         if (session) {
            session.requestDisconnect();
         }
      }
   };

   public closeAllSessions = () => {
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [key, wmksInstance] of wmksSessionMap) {
         wmksInstance.requestDisconnect();
      }
   };

   /**
    * Close all multi-session applications
    */
   public closeAllMultiSessionApplications = () => {
      this.htmlRemoteSessionManager.getAllSessions().forEach((ins) => {
         if (ins.isMultiSession) {
            ins.requestDisconnect();
         }
      });
   };
   /**
    * sendCtrlAltDel
    *
    * Send Ctrl-Alt-Del to the guest.
    * Send Ctrl-Alt-Ins to since the Ctrl-Alt-Del maybe disabled by agent policy
    */
   public sendCtrlAltDel = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         currentSession.wmks(
            "sendKeyCodes",
            [17, 18, 45], // Key codes
            [0x1d, 0x38, 0x152],
            true
         ); // VSCAN codes
      }
   };

   /**
    * sendEsc
    *
    * Send Esc to the guest.
    */
   public sendEsc = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         currentSession.wmks(
            "sendKeyCodes",
            [27], // Key code
            [0x01]
         ); // VSCAN code
      }
   };

   /**
    * toggleKeyBoard
    *
    */
   public launchKeyboard = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         currentSession.wmks("toggleKeyboard");
      }
   };

   /**
    * change the touch mode between TRACKPAD_MODE and NATIVETOUCH_MODE
    *
    */
   public changeTouchMode = (isTrackPadMode: boolean) => {
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [key, wmksInstance] of wmksSessionMap) {
         wmksInstance.wmks("changeTouchMode", isTrackPadMode);
      }
   };

   public updateKeyMappingSetting = (keyMappingSetting) => {
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [key, wmksInstance] of wmksSessionMap) {
         wmksInstance.wmks("updateKeyMappingSetting", keyMappingSetting);
      }
   };

   public updateNetworkStateDisplay = (disableNetworkStateDisplay) => {
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [key, wmksInstance] of wmksSessionMap) {
         wmksInstance.option.disableNetworkStateDisplay = disableNetworkStateDisplay;
      }
   };

   /**
    * toggleHighResMode
    *
    * Function to toggle high resolution mode to a new value on all
    * wmksSessions.
    *
    * @return the new value for high resolution mode
    */
   public toggleHighResMode = () => {
      if (this._isHighResModeSupported()) {
         this._isHighResModeEnabled = !this._isHighResModeEnabled;
         const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
         const targetDPI = this._isHighResModeEnabled ? 1 : window.devicePixelRatio;
         for (const [key, wmksInstance] of wmksSessionMap) {
            // To Fix bug 3030371
            wmksInstance.sendDisplayInfo(targetDPI).then((res) => {
               wmksInstance.wmks("option", "useNativePixels", this._isHighResModeEnabled);
            });
         }
      }
      return this._isHighResModeEnabled;
   };

   /**
    * setCanvasParentOverflow
    *
    * set Canvas-container's overflow attribute
    *
    * To fix bug 2009266.
    * When shadow cursor move to the bottom of the screen, there
    * will pop up unnecessary scroll bar.
    *
    * So only set the overflow attribute to visible when
    * current session is shadow session and user set
    * isFitToViewerEnabled false.
    *
    * This is because only when not in fitToViewer mode,
    * there will be the situation that the wmks container is
    * bigger than canvas parent. Other cases will be the same
    * size and overflow:hidden is enough.
    *
    * Change the overflow attr in three cases:
    * 1. User change the value in the setting dialog.
    * 2. Change activate session.
    */
   public setCanvasParentOverflow = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!this._isFitToViewerEnabled && currentSession && currentSession.isShadow) {
         $(AB.CANVAS_PARENT_ID).css("overflow", "visible");
      } else {
         if (this.featureConfigs.getConfig("KillSwitch-WindowReplacementApi")) {
            return;
         }
         $(AB.CANVAS_PARENT_ID).css("overflow", "hidden");
      }
   };

   /**
    * toggleFitToViewer
    *
    * Set option isFitToViewer.
    */
   public toggleFitToViewer = () => {
      this._isFitToViewerEnabled = !this._isFitToViewerEnabled;
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [key, wmksInstance] of wmksSessionMap) {
         wmksInstance.wmks("option", "isFitToViewer", this._isFitToViewerEnabled);
         wmksInstance.wmks("rescale");
      }
      this.setCanvasParentOverflow();
      return this._isFitToViewerEnabled;
   };

   /**
    * toggleMP4
    *
    * Function to toggle MP4 to a new value on all
    * wmksSessions.
    *
    * @return the new value for MP4
    */
   public toggleMP4 = () => {
      if (AB.isMP4Supported()) {
         this._isMP4Enabled = !this._isMP4Enabled;
      }
      return this._isMP4Enabled;
   };

   public toggleMultipleMonitor = (status: boolean) => {
      this._multipleMonitor = status;
   };

   /**
    * toggleUseMacOSXKeyMappings
    *
    * Function to toggle key mappings for this session.
    *
    * @return the new value for high resolution mode
    */
   public toggleUseMacOSXKeyMappings = () => {
      if (WMKS.BROWSER.isMacOS()) {
         this._areMacOSXKeyMappingsEnabled = !this._areMacOSXKeyMappingsEnabled;
         const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
         wmksSessionMap.forEach((session, key) => {
            session.wmks("option", "mapMetaToCtrlForKeys", this._areMacOSXKeyMappingsEnabled);
            session.wmks(
               "option",
               "mapMetaToCtrlForVScans",
               this._areMacOSXKeyMappingsEnabled ? AB.BlastWMKS.MAP_META_TO_CTRL_FOR_VSCANS : []
            );
         });
      }
      return this._areMacOSXKeyMappingsEnabled;
   };

   /**
    * toggleWindowsKey
    *
    * Function to toggle Windows key simulation functionality.
    *
    * @return true or false
    */
   public toggleWindowsKey = () => {
      this._isWindowsKeyEnabled = !this._isWindowsKeyEnabled;
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [, wmksInstance] of wmksSessionMap) {
         wmksInstance.enableWindowsKey(this._isWindowsKeyEnabled);
      }
      return this._isWindowsKeyEnabled;
   };

   public toggleWindowsDeleteKey = () => {
      this._isWindowsDeleteKeyEnabled = !this._isWindowsDeleteKeyEnabled;
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [, wmksInstance] of wmksSessionMap) {
         wmksInstance.enableWindowsDeleteKey(this._isWindowsDeleteKeyEnabled);
      }
      return this._isWindowsDeleteKeyEnabled;
   };

   /**
    * prepareForSessionReset
    *
    * Informs the wmksService that an application reset will be performed.
    * The service sets the requestedDisconnect flag to true for all of its
    * application sessions to prevent them from displaying a disconnected
    * dialog
    *
    * multi-session reset support has also been implemented, so no disconnection
    * will be informed
    */
   public prepareForSessionReset = (resetWmksKey?) => {
      if (resetWmksKey) {
         const session = this.htmlRemoteSessionManager.getSessionById(resetWmksKey);
         session.requestedDisconnect = true;
         Logger.info("Setting requestedDisconnect to true for session: " + resetWmksKey, Logger.WMKS);
      } else {
         const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
         for (const [key, wmksInstance] of wmksSessionMap) {
            if (wmksInstance.isApplicationSession) {
               wmksInstance.requestedDisconnect = true;
               Logger.info("Setting requestedDisconnect to true for session: " + resetWmksKey, Logger.WMKS);
            }
         }
      }
   };

   /**
    * isCurrentSessionShadowSession
    *
    * Utility function to check if current wmks session is a shadow session
    */
   public isCurrentSessionShadowSession = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      return currentSession && currentSession.isShadow;
   };

   /**
    * isCurrentSessionShadowSession
    *
    * Utility function to check if current wmks session is a shadow session
    */
   public isCurrentSessionPerMonitorDPI = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession) {
         return false;
      }
      return currentSession.isDisplayScaleDisabled();
   };

   /**
    * getCurrentSessionDPI
    *
    * Utility function to getCurrentSessionDPI
    */
   public getCurrentSessionDPI = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession) {
         return 1.0;
      }
      return currentSession.getRemoteDPI();
   };

   /**
    * sessionOnConnecting
    *
    * Handle the onConnecting delegate request from wmksSession.
    */
   public sessionOnConnecting = (wmksSession) => {
      this.remoteSessionEventService.emit("sessionConnecting", wmksSession);
   };

   /**
    * sessionOnConnected
    *
    * Handle the onConnected delegate request from wmksSession.
    */
   public sessionOnConnected = (wmksSession) => {
      setTimeout(
         () => {
            this.remoteSessionEventService.emit("sessionConnected", wmksSession);
            this.htmlRemoteSessionManager.updateRunningSessions(true);
         },
         0,
         false
      );
   };

   /**
    * sessionOnDisconnected
    *
    * Handle the onDisconnected delegate request from wmksSession.
    */
   public sessionOnDisconnected = (wmksSession) => {
      const wasCurrentSession = this.htmlRemoteSessionManager.isCurrentSession(wmksSession.key);
      setTimeout(
         () => {
            this.remoteSessionEventService.emit("sessionDisconnected", wmksSession);
         },
         0,
         false
      );
      this.htmlRemoteSessionManager.updateRunningSessions(true);
   };

   public updateReconnectToken = (wmksSession, reconnectToken) => {
      Logger.debug("Updating token for session: " + wmksSession.key, Logger.WMKS);
      this.htmlRemoteSessionManager.updateRunningSessions();
      this.sessionUtil.updateReconnectToken(wmksSession.key, reconnectToken);
   };

   public updateTriedSSLVerify = async () => {
      await this.htmlRemoteSessionManager.updateRunningSessions();
   };

   /**
    * sessionOnRemoved
    *
    * Handle the onRemoved delegate request from wmksSession.
    */
   public sessionOnRemoved = (wmksSession) => {
      this.multipleDisplayService.onSessionDisconnected(wmksSession);
      this.htmlRemoteSessionManager.removeSession(wmksSession.key);
      this.htmlRemoteSessionManager.updateRunningSessions();
   };

   public shadowAllHeartbeatForPrint = () => {
      // Only work for Firefox and Safari
      if (!WMKS.BROWSER.isFirefox() && !WMKS.BROWSER.isSafari()) {
         return;
      }

      Logger.debug("Stop heartbeat for print.", Logger.WMKS);
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [, wmksInstance] of wmksSessionMap) {
         wmksInstance.shadowHeartbeat();
      }
   };

   public recoverAllHeartbeatForPrint = () => {
      if (!WMKS.BROWSER.isFirefox() && !WMKS.BROWSER.isSafari()) {
         // Only work for Firefox and Safari
         return;
      }

      Logger.debug("Recover heartbeat for print.", Logger.WMKS);
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [, wmksInstance] of wmksSessionMap) {
         wmksInstance.recoverHeartbeat();
      }
   };

   public setLastUserActivityTime = () => {
      this.idleSessionService.setLastUserActivityTime();
   };

   public isSessionTimedOut = () => {
      return this.idleSessionService.isSessionTimedOut();
   };

   public checkSessionInMultimon = (key: string, callback: Function) => {
      this.multimonService.checkSessionInMultimon(key, callback);
   };

   public isUsingWebcam = (item: any): boolean => {
      return (
         this.rtavService.hasOccupiedResources(item.wmksKey) && this.rtavService.isUsingDevices(item.wmksKey, "video")
      );
   };

   public isAskingWebcam = (wmksKey: string): boolean => {
      return this.rtavService.isAskingPermission(wmksKey, "video");
   };

   public isUsingMicrophone(item: any): boolean {
      return (
         this.rtavService.hasOccupiedResources(item.wmksKey) && this.rtavService.isUsingDevices(item.wmksKey, "audio")
      );
   }

   public isAskingMicrophone(wmksKey: string): boolean {
      return this.rtavService.isAskingPermission(wmksKey, "audio");
   }

   /**
    * handleTouchStart
    *
    * We need to initialize the audio context for onTouchStart.
    */
   private _handleTouchStart = (e) => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         currentSession.audioService.initializeAudioForTouch();
      }
   };

   /**
    * handleResize
    *
    * We need to resize the remote desktop for onSize.
    */
   private _handleResize = (e) => {
      if (WMKS.BROWSER.isAndroid()) {
         this.handleTouchResize();
      } else {
         const screenSize = CST.clientUtil.getWindowResolution();

         // Send a rescale request to resize based on the parent container
         // size.
         const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
         if (wmksSessionMap?.size > 0) {
            for (const [key, wmksInstance] of wmksSessionMap) {
               if (wmksInstance !== this.multimonService.getMultimonSession()) {
                  wmksInstance.updateResolution(screenSize, true);
               } else {
                  Logger.debug("skip resize for session wmksKey: " + key, Logger.WMKS);
               }
            }
         } else {
            Logger.debug("wmksSessionMap is empty");
         }
      }
   };

   /**
    * handleTouchResize
    *
    * We need to resize the remote desktop for onSize.
    */
   private handleTouchResize = (e?: any) => {
      const callback = (screenSize) => {
         // Send a rescale request to resize based on the parent container
         // size.
         const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
         if (wmksSessionMap?.size > 0) {
            for (const [key, wmksInstance] of wmksSessionMap) {
               if (wmksInstance !== this.multimonService.getMultimonSession()) {
                  wmksInstance.updateResolution(screenSize, true);
               } else {
                  Logger.debug("skip resize for session wmksKey: " + key, Logger.WMKS);
               }
            }
         } else {
            Logger.debug("wmksSessionMap is empty");
         }
      };
      AB.getTouchDeviceDesiredResolution(callback);
   };

   /**
    * iPhone not supported
    */
   private _isHighResModeSupported = () => {
      return WMKS.UTIL.isHighResolutionSupported() && !AB.isIPhone();
   };

   // The connectToBlast will replace the _connectToServer in later patch.
   public connectToBlast = (blastInfo: BlastConnectInfo) => {
      const isCdrEnabled = this.clientSettingModel.getBooleanItem("enableFolderSharing");
      const serviceLoadingLog =
         `<cdr>${isCdrEnabled ? "Enable" : "Disable"} ` +
         "loading smartCardDummyService and htmlCdrService since cdr is" +
         ` ${isCdrEnabled ? "enable" : "disable"}`;
      Logger.debug(serviceLoadingLog);
      const opt: BlastOption = {
         wmksService: this,
         key: blastInfo.key,
         isApp: blastInfo.isApplicationSession,
         isShadow: blastInfo.isShadow,
         name: blastInfo.name,
         reconnectToken: blastInfo.reconnectToken,
         triedSSLVerify: blastInfo.triedSSLVerify,
         isMultiSession: blastInfo.isMultiSession,
         enableUsb: blastInfo.enableUsb,
         usbTicket: blastInfo.usbTicket,
         redirectSetting: blastInfo.redirectSetting || null,
         brokerUrl: blastInfo.brokerUrl || null,
         sessionId: blastInfo.sessionId || null,
         dspecId: blastInfo.dspecId || null,
         smartCardDummyService: isCdrEnabled ? this.smartCardDummyService : null,
         htmlCdrService: isCdrEnabled ? this.htmlCdrService : null,
         isWindows365: blastInfo.isWindows365
      };
      let wmksSession: BlastWmks = null;
      if (clientUtil.isTitanClient()) {
         wmksSession = this.newBlastWmks(TitanBlastSessionInstance, opt);
      } else {
         wmksSession = this.newBlastWmks(Html5BlastSessionInstance, opt);
      }
      const webSocketUrl = blastInfo.targetUrl.replace("http", "ws");
      const useMultiMonitor = this.monitorInfoService.useMultiMonitor();
      wmksSession.initialize(webSocketUrl, {
         enableHighResMode: this._isHighResModeEnabled,
         useMacOSXKeySettings: WMKS.BROWSER.isMacOS() && this._areMacOSXKeyMappingsEnabled,
         enableWindowsKey: this._isWindowsKeyEnabled,
         enableWindowsDeleteKey: this._isWindowsDeleteKeyEnabled,
         enableFitToViewer: this._isFitToViewerEnabled,
         enableMP4: AB.isMP4Supported() && this._isMP4Enabled && !useMultiMonitor,
         enableKeyMapping: this.clientSettingModel.getBooleanItem("enableKeyMapping"),
         enableNetworkIndicator: this.networkStateService.getOptions().enableNetworkIndicator,
         networkStateConfig: this.networkStateService.getOptions().networkStateConfig,
         disableNetworkStateDisplay: this.preDataSetModel.settingData["disableNetworkStateDisplay"],
         enableRTAVH264Codec: this.viewClientModel.enableRTAVH264Codec,
         enableRTAVOpusCodec: this.viewClientModel.enableRTAVOpusCodec,
         enableRTAVDTX: this.viewClientModel.enableRTAVDTX,
         hardwareAccelerationOption: this.viewClientModel.hardwareAccelerationOption,
         enableBlastCodec: this.viewClientModel.enableBlastCodec,
         enableAdvancedCodec: this.clientSettingModel.getBooleanItem("enableAdvancedCodec") && !useMultiMonitor
      });

      if (this._isMP4Enabled && useMultiMonitor) {
         Logger.info("force to disable H.264 codec when multiple monitors mode enabled");
      }

      if (blastInfo.isApplicationSession) {
         this.sessionUtil.onSessionConnected(blastInfo.isMultiSession, blastInfo.key, wmksSession);
      }
      if (this.clientSettingModel.getBooleanItem("enableKeyMapping")) {
         wmksSession.wmks(
            "updateKeyMappingSetting",
            this.keyboardSettingService.getFinalKeyMappingSetting(this._keyMappingSetting)
         );
      }
      this.htmlRemoteSessionManager.addSession(blastInfo.key, wmksSession);
   };

   private newBlastWmks(BlastWmksType: Type<BlastWmks>, opt: BlastOption) {
      return new BlastWmksType(this.injector, opt);
   }

   private _shadowAllHeartbeatWhenSafariHidden = () => {
      if (!WMKS.BROWSER.isSafari()) {
         // Only work for Safari
         return;
      }

      Logger.debug("Stop heartbeat when Safari is hidden.", Logger.WMKS);
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [, wmksInstance] of wmksSessionMap) {
         wmksInstance.shadowHeartbeat();
      }
   };

   private _recoverAllHeartbeatWhenSafariVisible = () => {
      if (!WMKS.BROWSER.isSafari()) {
         // Only work for Safari
         return;
      }

      Logger.debug("Recover heartbeat when Safari is visible.", Logger.WMKS);
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const [, wmksInstance] of wmksSessionMap) {
         wmksInstance.recoverHeartbeat();
      }
   };

   /**
    * requestDisconnectApplications
    * disconnect all application sessions
    */
   private requestDisconnectApplications = () => {
      const wmksSessionMap = this.htmlRemoteSessionManager.getAllSessions();
      for (const session of wmksSessionMap.values()) {
         if (session.isApplicationSession) {
            session.requestDisconnect();
         }
      }
   };

   private updateWMKSDPI = (key: string, dpi: number) => {
      const session = this.htmlRemoteSessionManager.getSessionById(key);
      if (session) {
         session.updateDPIInfo(dpi);
      }
   };

   /**
    * handleTouchResize
    *
    * We need to resize the remote desktop for onSize when wmkscontainer init.
    */
   private handleTouchResizeWithoutMutimon = (session, agentPixel, targetPixel) => {
      const callback = (screenSize) => {
         this._onResolutionUpdatedCommon(session, agentPixel, targetPixel, screenSize);
      };
      AB.getTouchDeviceDesiredResolution(callback);
   };

   private _onResolutionUpdatedCommon = (session, agentPixel, targetPixel, screenSize) => {
      // Used to bypass issues
      Logger.info(
         "Agent Resolution: " + JSON.stringify(agentPixel) + "\nexpected resolution: " + JSON.stringify(targetPixel),
         Logger.WMKS
      );

      // Compatible with Agents that need resolution adjustment after connection
      if (Math.abs(targetPixel[0] - agentPixel.width) <= 1 || Math.abs(targetPixel[1] - agentPixel.height) <= 1) {
         Logger.info("resolution inited successfully", Logger.WMKS);
         session.updateResolution(screenSize, false);
      } else {
         Logger.info("set resolution again, since resolution initialize failed", Logger.WMKS);
         session.updateResolution(screenSize, true);
      }
   };
}
