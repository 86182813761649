/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { KeyMappingStorageService } from "./keymapping-storage.service";
import { ClientInfoService } from "./client-info.service";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";

@Injectable()
export class HorizonKMStorageService extends KeyMappingStorageService {
   constructor(
      private clientInfoService: ClientInfoService,
      private localStorageService: LocalStorageService
   ) {
      super();
   }
   public isPrefLimitationExist() {
      return (
         this.clientInfoService.getClientInfo() !== null &&
         this.clientInfoService.getClientInfo().contextPath === "/appblast"
      );
   }
   public saveKeyMapping(keyMappingSetting: string) {
      this.localStorageService.set("keyMappingSetting", keyMappingSetting);
   }
   public getKeyMapping() {
      return this.localStorageService.get("keyMappingSetting");
   }
}
