/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
/**
 *
 * blast-wmks-service.js
 *
 * Blast WMKS client implementation for use with NGP.
 * AB and WMKS are pre-requisite namespaces.
 *
 */

import { AB } from "./appblast-util.service";
import Logger from "../../../core/libs/logger";
import * as CST from "@html-core";
import { RDEChannel } from "../channels/rde-main-channel";
import { VDPService } from "../vdpservice";
import { Injector } from "@angular/core";
import { AudioService } from "./audio.service";
import { EventBusService, BusEvent, TranslateService, convertToIPv6AddressIfNeeded } from "@html-core";
import { WmksContainer, WmksOption } from "./wmks-container";
import { FeatureConfigs } from "../../common/model/feature-configs";
import { MultimonRenderingService } from "../multimon/main-page/multimon-rendering.service";
import { AjaxBusyService } from "../../common/ajax-busy/ajax-busy.service";
import { SessionUtil } from "../../common/service/session-util";
import { CommonSvcService } from "../channels/commonSvc.service";
import { IdleSessionService } from "../../common/service/idle-session.service";
import { CertService } from "../../base/cert.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { DisplayTopologyBriefInfo } from "../../../shared/desktop/channels/commonSvcChannel";
import {
   BlastCloseEvent,
   isMKSSessionClosedNormally,
   VDPConnectionResult,
   webMksReconnectFailure
} from "../wmks/wmks-error-handler";
import { BlastOption } from "../wmks/blast-option";
import { Subject } from "rxjs";
import { HtmlCDRService } from "../../../html5-client/desktop/cdr/html-cdr.service";
import { smartCardDummyService } from "../smart-card/smart-card-dummy.service";
import { defaultNetworkStateConfig, NetworkStateService } from "../networkState/network-state.service";
import { default as wasmBlast } from "wasmBlast";

export interface BlastWmksSession {
   key: string;
   name: string;
   vdpService: any;
   isApplicationSession: boolean;
}

export abstract class BlastWmks extends WmksContainer implements BlastWmksSession {
   // External access
   public triedSSLVerify: boolean;
   public isMultiSession: boolean;
   public key: string;
   public name: string;
   public isShadow: boolean;
   public isDestroyed: boolean = false;
   public multimonServerEnabled: boolean = false;
   public wmksContainer: any = null;
   public requestedDisconnect: boolean = false;
   public isApplicationSession: boolean = false;
   public reconnectToken: string = null;
   public isEmpty: boolean = true;
   public audioService: any = null;
   public audioServiceT: any = null;
   public url: string = null;
   public mainChannel: any = null;
   public vdpService: any = null;
   public vvc: any = null;
   public vvcSession: any = null;
   public entitleId: string = null;
   public enableUsb: boolean = false;
   public usbTicket: string = "";
   public fileTransfer: boolean = false;
   public redirectSetting: ProtocolRedirectSettings = null;
   public brokerUrl: string = "";
   public sessionId: string = "";
   public dspecId: string = "";
   // common short ID cross different component and service
   public logId: string = null;
   // event to remove subscription of current session.
   public destroy$ = new Subject();
   public isWindows365: boolean;

   protected settings: any = null;

   // Internal parameters
   protected _disconnectedDialogId: string = null;
   private _dialogCloseReason: string = null;
   private _disconnectedDialogOption = null;

   private _heartbeatTimeout: any = null;
   private _reconnectTimeout: any = null;
   private _backoffTimeout: any = null;
   private _disconnectRequestTimeout: any = null;
   private _backoffDelayMs: number = AB.BlastWMKS.INIT_BACKOFF_DELAY_MS;
   private _heartbeatEnabled: boolean = false;
   private _heartbeatInterval: number = 20;
   private _dpiDataSyncSuccess: boolean = false;
   private _initializedResolution = null;
   private _isWmksActive: boolean = false;
   private _resolutionInitialized: boolean = false;
   private _H264ResolutionAdjustCounter: number = 2;
   protected _runningReconnectInBackGround: boolean = false;

   // Angular Services
   protected _wmksService: any = null;
   protected wasWmksConnectedOnce: boolean = false;
   protected eventBusService: EventBusService = null;
   private featureConfigs: FeatureConfigs = null;
   private multimonRenderingService: MultimonRenderingService = null;
   protected ajaxBusyService: AjaxBusyService = null;
   protected translate: TranslateService = null;
   protected modalDialogService: ModalDialogService = null;
   private sessionUtil: SessionUtil = null;
   private commonSvcService: CommonSvcService = null;
   protected idleSessionService: IdleSessionService = null;
   private localStorageService: CST.LocalStorageService = null;
   private htmlCdrService: HtmlCDRService = null;
   private smartCardDummyService: smartCardDummyService = null;
   private networkStateService: NetworkStateService = null;
   private injector: Injector = null;

   public abstract onInitialize();
   public abstract reconnectHandler(code: number, closeReason: number);

   constructor(injector: Injector, opt: BlastOption) {
      super();
      this.name = typeof opt.name === "string" ? opt.name : "";
      this.key = opt.key;
      if (this.name !== "") {
         this.logId = this.name;
      } else {
         this.logId = WmksContainer.canvasId.toString();
      }
      this.logger = new Logger(Logger.WMKS, this.logId);
      this.injector = injector;
      this.triedSSLVerify = opt?.triedSSLVerify || false;
      this.isMultiSession = opt.isMultiSession;
      this.isApplicationSession = opt?.isApp || false;
      this.reconnectToken = opt.reconnectToken;
      this.enableUsb = opt.enableUsb;
      this.usbTicket = opt.usbTicket;
      this.redirectSetting = opt.redirectSetting || null;
      this.brokerUrl = opt.brokerUrl || null;
      this.sessionId = opt.sessionId || null;
      this.dspecId = opt.dspecId || null;
      this.isWindows365 = opt.isWindows365 || false;

      this._wmksService = opt.wmksService;
      this.isShadow = opt.isShadow;

      this.eventBusService = injector.get(EventBusService);
      this.featureConfigs = injector.get(FeatureConfigs);
      this.multimonRenderingService = injector.get(MultimonRenderingService);
      this.ajaxBusyService = injector.get(AjaxBusyService);
      this.translate = injector.get(TranslateService);
      this.sessionUtil = injector.get(SessionUtil);
      this.commonSvcService = injector.get(CommonSvcService);
      this.idleSessionService = injector.get(IdleSessionService);
      this.modalDialogService = injector.get(ModalDialogService);
      this.localStorageService = injector.get(CST.LocalStorageService);
      this.audioService = injector.get(AudioService).getAudioServiceInstance(this.logId, this.key);
      this.audioServiceT = injector.get(AudioService);
      this.htmlCdrService = opt.htmlCdrService;
      this.smartCardDummyService = opt.smartCardDummyService;
      this.networkStateService = injector.get(NetworkStateService);
   }

   private _checkEnableWidowKey = (settings: any) => {
      if (this.isApplicationSession) {
         return false;
      }
      return settings?.enableWindowsKey || false;
   };

   private _checkIgnoredRawKeyCodes = (settings: any): number[] => {
      const winKeyEnabled: boolean = this._checkEnableWidowKey(settings);
      if (winKeyEnabled) {
         return AB.BlastWMKS.IGNORE_RAW_KEY_CODES;
      }
      // If Windows key simulation is disabled, don't process WIN code.
      return AB.BlastWMKS.IGNORE_RAW_KEY_CODES.concat(AB.BlastWMKS.WIN_KEY_CODES);
   };

   public initialize = (url, settings) => {
      this.url = convertToIPv6AddressIfNeeded(url);
      this.settings = settings;

      this.option.fitToParent = this.isShadow;
      this.option.fitGuest = !this.isShadow;
      this.option.isFitToViewer = settings?.enableFitToViewer || false;
      this.option.isShadow = this.isShadow;
      this.option.enableOpusAudioClips = this.audioService.isAudioEnabled() && this.audioService.canAudioUseOpus();
      this.option.enableAacAudioClips = this.audioService.isAudioEnabled() && this.audioService.canAudioUseAac();
      this.option.enableMP4 = settings?.enableMP4 || false;
      this.option.displayScaleInitialOff = !this.option.enableMP4;
      this.option.enableWindowsKey = this._checkEnableWidowKey(settings);
      this.option.ignoredRawKeyCodes = this._checkIgnoredRawKeyCodes(settings);
      this.option.useNativePixels = settings?.enableHighResMode || false;
      this.option.enableKeyMapping = settings?.enableKeyMapping === false ? false : true;
      if (CST.clientUtil.isChromeClient()) {
         this.option.isTrackPadMode = false;
         this.option.logger = this.getLoggerWrapper();
         this.featureConfigs.setConfig("KillSwitch-WasmBlast", settings?.enableBlastCodec === true ? true : false);
      } else {
         this.option.disableVscanKeyboard = CST.clientUtil.isVrDevice;
         this.option.isTrackPadMode = this.localStorageService.get(CST.COOKIE.TOUCH_MODE) === "true";
         if (CST.clientUtil.isIOS() || CST.clientUtil.isAndroid()) {
            if (this.option.isTrackPadMode) {
               this.eventBusService.dispatch(new BusEvent.NotificationMsg("TRACKPAD_MODE"));
            } else {
               this.eventBusService.dispatch(new BusEvent.NotificationMsg("NATIVETOUCH_MODE"));
            }
         }
      }
      if (settings?.useMacOSXKeySettings) {
         this.option.mapMetaToCtrlForKeys = AB.BlastWMKS.MAP_META_TO_CTRL_FOR_KEYS;
         this.option.mapMetaToCtrlForVScans = AB.BlastWMKS.MAP_META_TO_CTRL_FOR_VSCANS;
      }
      this.option.enableWindowsDeleteKey = settings?.enableWindowsDeleteKey;
      this.option.enableRTAVH264Codec = settings?.enableRTAVH264Codec;
      this.option.enableRTAVOpusCodec = settings?.enableRTAVOpusCodec;
      this.option.enableRTAVDTX = settings?.enableRTAVDTX;
      this.option.hardwareAccelerationOption = settings?.hardwareAccelerationOption;
      this.option.cursorHandler = this.cursorHandler;
      this.option.networkStateHandler = this.networkStateHandler;
      this.option.enableNetworkIndicator = settings?.enableNetworkIndicator === false ? false : true;
      this.option.networkStateConfig = settings?.networkStateConfig || defaultNetworkStateConfig;
      this.option.disableNetworkStateDisplay = settings?.disableNetworkStateDisplay || false;

      if (this.featureConfigs.getConfig("KillSwitch-WasmBlast")) {
         this.logger.debug("[BlastCodec] Blast Codec is already enabled by kill switch");
         if (!window.hasOwnProperty("WebAssembly")) {
            this.logger.warning("[BlastCodec] Cannot enable WASM blast client, browser does not support WebAssembly");
         } else if (!window.hasOwnProperty("SharedArrayBuffer")) {
            this.logger.warning(
               "[BlastCodec] Cannot enable WASM blast client, browser does not support SharedArrayBuffer"
            );
         } else if (!window.hasOwnProperty("AudioDecoder")) {
            this.logger.warning("[BlastCodec] Cannot enable WASM blast client, browser does not support AudioDecoder");
         } else if (!window.hasOwnProperty("VideoDecoder")) {
            this.logger.warning("[BlastCodec] Cannot enable WASM blast client, browser does not support VideoDecoder");
         } else if (!(settings?.enableAdvancedCodec === true ? true : false)) {
            this.logger.debug(
               "[BlastCodec] WASM blast client is disabled from setting or currently in multi-screen mode."
            );
         } else {
            this.logger.debug("[BlastCodec] WASM blast client is enabled");
            try {
               this.option.wasmBlast = wasmBlast({});
               const self = this;
               this.option.getAudioQueueSamples = () => {
                  return self.audioService.getAudioQueueSamples();
               };
               this.option.maxAudioQueueSamples = this.audioService.getAudioQueueMaxSamples();
            } catch (e) {
               this.logger.debug("[BlastCodec] wasmBlast init failed");
               this.option.wasmBlast = null;
            }
         }
      } else {
         this.logger.debug("[BlastCodec] Blast Codec is disabled by kill switch");
         this.option.wasmBlast = null;
      }

      (this.option.multimonRenderer = this.multimonRenderingService), (this.option.disableTouch = false);
      this.onInitialize();

      if (this._backoffTimeout) {
         clearTimeout(this._backoffTimeout);
         this._backoffTimeout = null;
      }

      this._initWmksContainer();

      let targetUrl = this.url;
      if (this.reconnectToken) {
         targetUrl += targetUrl.indexOf("?") === -1 ? "?" : "&";
         targetUrl += "session=" + encodeURIComponent(this.reconnectToken);
      }
      this.commonSvcService.init();
      // Initiate the remote desktop connection.
      this.wmksContainer.wmks("connect", targetUrl);
      if (!CST.clientUtil.isChromeClient()) {
         this.wmksContainer.wmks("setLoglevel", Logger.LOG_LEVEL);
      }

      return true;
   };

   /**
    * requestDisconnect
    *
    * Attempt a graceful disconnect from the server. If the server is
    * already disconnected, triggers a page reflow. If the request takes
    * too long to complete or fails, force the disconnect after a delay.
    */
   public requestDisconnect = () => {
      this.requestedDisconnect = true;

      if (this._isWmksActive) {
         this.wmks("disconnect");
         if (!this._disconnectRequestTimeout) {
            this._disconnectRequestTimeout = setTimeout(() => {
               this._disconnectRequestTimeout = null;
               if (this._isWmksActive) {
                  this.logger.warning("disconnect request timed out");
                  this.OnWmksDisconnected(
                     BlastCloseEvent.DISCONNECT_REQUEST_TIMEOUT,
                     VDPConnectionResult.VDPCONNECT_TIMEOUT
                  );
               }
            }, AB.BlastWMKS.REQUEST_DISCONNECT_TIMEOUT);
         }
      } else {
         this.destroy();
      }
   };

   /**
    * onConnected
    *
    * Called upon successful connection.
    */
   public OnWmksConnected(): void {
      // Note that we're connected with wmks.
      this.logger.info(this.key + " Remote connection successful - wmks.");

      this.wasWmksConnectedOnce = true;
      this.ajaxBusyService.setAjaxBusy(false);
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
      this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
      // Clear previous state if any, and start the heartbeats.
      this._resetStateAndInitHeartbeat();

      // Now send the messages from the queue if any.
      this.sendQueueMessages();

      // Show the canvas if we are the active session.
      if (this.isActive && !this.wmksContainer.hideLoading) {
         this.wmksContainer.show();
      }
      this._wmksService.sessionOnConnected(this);

      if (this.smartCardDummyService) {
         this.smartCardDummyService.onConnected(this.key);
      }
   }
   /**
    * onConnecting
    *
    * Called while the MKS is connecting.  This currently is only used to
    * create an instance of Unity.Mgr and log what it receives. This will
    * also create and initialize an mksVchan client for the session.
    */

   public OnWmksConnecting(vvc: any, vvcSession: any): void {
      this._isWmksActive = true;
      if (!vvc) {
         this.logger.warning("The agent version this session " + this.key + " is connected to is deprecated.");
         return;
      }
      this.vvc = vvc;
      this.vvcSession = vvcSession;

      this.vdpService = new VDPService(vvc, vvcSession);
      this.mainChannel = new RDEChannel(this.vdpService);

      this.logger.info("Connecting " + (this.isApplicationSession ? "application" : "desktop") + " session - wmks.");
      this._wmksService.sessionOnConnecting(this);
      if (this.htmlCdrService && this.smartCardDummyService) {
         this.smartCardDummyService.onConnecting(this.key, vvcSession);
         this.htmlCdrService.onWmksSessionConnecting(this.key, this.vdpService);
      }
   }

   public OnWmksAudioOutputDevicesUpdated = async () => {
      await this.audioServiceT.initDevicesByPreferred();
      Logger.info(
         "send update audio out devices list request on mks: " +
            JSON.stringify(this.audioServiceT.audioOutDevicesUniqueIdInfo.audioDevices)
      );
      Logger.info(
         "send update audio out devices number request on mks: " +
            this.audioServiceT.audioOutDevicesUniqueIdInfo.deviceCount
      );
      this.wmksContainer.wmks(
         "sendAudioOutDevicesRequest",
         this.audioServiceT.audioOutDevicesUniqueIdInfo.deviceCount,
         this.audioServiceT.audioOutDevicesUniqueIdInfo.audioDevices
      );
   };

   public OnWmksTopologyUpdated = (topologyBriefInfo: Array<DisplayTopologyBriefInfo>) => {
      const topologySettings = topologyBriefInfo.map((item) => {
         return {
            requestedWidth: item.rect.right - item.rect.left,
            requestedHeight: item.rect.bottom - item.rect.top,
            left: item.rect.left,
            top: item.rect.top
         };
      });
      Logger.debug("send display info on common svc");
      this.commonSvcService
         .sendDisplayInfo(this.key, topologyBriefInfo)
         .then(() => {
            Logger.info("send updateTopology on mks: " + JSON.stringify(topologySettings));
            this.wmksContainer.wmks("updateTopology", topologySettings, true);
         })
         .catch((e) => {
            Logger.error("detect exception when sending display info");
            Logger.exception(e);
            Logger.info("send updateTopology on mks: " + JSON.stringify(topologySettings));
            this.wmksContainer.wmks("updateTopology", topologySettings, true);
         });
   };

   public OnWmksResolutionChanged = (res: Resolution): void => {
      this.logger.debug("Agent Topology changed to : " + JSON.stringify(res));
      /*
       *  If there are any resize issues, we can rely on the return
       *  value and verify against the requested desktop size.
       */
      this._initializedResolution = res;
      if (!this._resolutionInitialized) {
         this._resolutionInitialized = true;
         this._wmksService.onResolutionUpdated(this, res);
         this.eventBusService.dispatch({
            type: "resolutionInited"
         });
      } else if (this.option.enableMP4 && this._H264ResolutionAdjustCounter > 0) {
         /**
          * for bug 2549886, where the previous design in fix for 2259823
          * was disabled for H264 mode by fix for 2291672.
          * Since the default setting for H264 break the control flow, so that
          * extra resolution check is needed in case race condition happens.
          * We should move this workaround and workaround for 2291672,
          * after 2291672 had been fixed by Agent.
          */
         this._H264ResolutionAdjustCounter--;
         this.logger.debug("apply extra client resolution update for H264 mode");
         this._wmksService.onResolutionUpdated(this, res, true);
      }
   };

   public OnWmksHeartbeatCapacity = (enable: boolean): void => {
      this._heartbeatEnabled = enable;

      this.logger.info(this.key + " heartbeat timed out enabled: " + enable);
      /*
       * Initialize the heartbeat as soon as we know the server and client
       * support it, server cap message is treated as a heart beat.
       */
      if (this._heartbeatEnabled) {
         this.OnWmksHeartbeat(this._heartbeatInterval);
      }
   };

   public OnWmksHeartbeat = (interval: number) => {
      if (this._heartbeatTimeout !== null) {
         clearTimeout(this._heartbeatTimeout);
      }
      if (!this._heartbeatEnabled) {
         return;
      }
      this._heartbeatInterval = interval;

      this._heartbeatTimeout = setTimeout(
         () => {
            if (!this._heartbeatEnabled) {
               this.logger.info("Timed out waiting for a heartbeat, but skip disconnection");
               return;
            }
            this.logger.warning("timed out waiting for a heartbeat.");
            this.OnWmksDisconnected(BlastCloseEvent.HEARTBEAT_TIMEOUT, VDPConnectionResult.VDPCONNECT_FAILURE);
            /*
             * "interval" is in seconds --
             * 3 intervals without a heartbeat means we're dead.
             * Where network delay of 2 interval(40s) would cause blast disconnection.
             */
         },
         interval * 1000 * 3
      );
   };

   public OnWmksAudio = (audioInfo: any): void => {
      this.audioService.playAudio(audioInfo);
   };

   public OnWmksAudioMixer = (audioMixerInfo: any): void => {
      this.audioService.updateAudioMixer(audioMixerInfo);
   };

   public OnWmksAudioMixerMulti = (audioMixerMultiInfo: any): void => {
      this.audioService.updateAudioMixerMulti(audioMixerMultiInfo);
   };

   public OnWmksReconnectToken = (token: string): void => {
      this.reconnectToken = token;
      this._wmksService.updateReconnectToken(this, this.reconnectToken);
   };

   public OnWmksUpdateMultiMonCapacityUI = (enable: boolean): void => {
      this.logger.info("update-multimon-capacity-ui = " + enable);
      this.multimonServerEnabled = enable;
      this.eventBusService.dispatch(new BusEvent.UpdateMultiMonCapacityMsg(enable, this));
   };

   public OnWmksUserActivity = (): void => {
      if (!CST.clientUtil.isSeamlessMode()) {
         this._wmksService.setLastUserActivityTime();
      }
   };
   public OnWmksFramebufferInited = (): void => {
      this.eventBusService.dispatch(new BusEvent.FrameBufferInited());
      if (this._resolutionInitialized) {
         this._wmksService.onResolutionUpdated(this, this._initializedResolution);
      }
      if (this._wmksService.frameBufferInited) {
         const frameBufferInitedTime = new Date().getTime();
         const interval = setInterval(() => {
            if (this._dpiDataSyncSuccess === true) {
               this.logger.info("dpiDataSyncSuccess is ready, call frameBufferInited");
               this._wmksService.frameBufferInited(this.commonSvcService.getRemoteDPI(this.key));
               clearInterval(interval);
            } else {
               const currentTime = new Date().getTime();
               if (currentTime > frameBufferInitedTime + 1000 * 20) {
                  this.logger.info("dpiDataSyncSuccess is not ready, time is longer than 20s, call frameBufferInited");
                  this._wmksService.frameBufferInited(this.commonSvcService.getRemoteDPI(this.key));
                  clearInterval(interval);
               }
            }
         }, 500);
      }
   };

   /**
    * shadowHeartbeat
    *
    *    This is special handle for bug 1731596
    *
    *    Stop heartbeat watching when print since the print dialog in
    *    Firefox and Safari can block JS code, which prevent client
    * receiving heart beat from server. At last client will disconnect
    * from server itself. Therefore we have to stop heartbeat watching in
    * this case
    */
   public shadowHeartbeat = () => {
      if (this._heartbeatTimeout !== null) {
         clearTimeout(this._heartbeatTimeout);
      }
   };

   /**
    * recoverHeartbeat
    *
    *    This is special handle for bug 1731596
    *    Recover heartbeat watching after print finish
    */
   public recoverHeartbeat = () => {
      this.OnWmksHeartbeat(this._heartbeatInterval);
   };

   public setVisibility = (isShow) => {
      this._setVisibility(isShow);
      if (isShow && this._disconnectedDialogOption !== null) {
         this._openDisconnectedDialog(
            this._disconnectedDialogOption.reconnecting,
            this._disconnectedDialogOption.reason
         );
         this._disconnectedDialogOption = null;
      }
      return true;
   };

   /**
    * cleanup
    *
    * Clean up the selected timeout task. Default is to
    * clean up all the tasks.
    *
    * @params isIgnoreReconnect if we ignore reconnect timeout
    */
   private _cleanup = (isIgnoreReconnect) => {
      // Remove all the timeout tasks for it is useless in the shutdown
      // process.
      if (this._heartbeatTimeout) {
         clearTimeout(this._heartbeatTimeout);
         this._heartbeatTimeout = null;
      }

      if (this._reconnectTimeout && !isIgnoreReconnect) {
         clearTimeout(this._reconnectTimeout);
         this._reconnectTimeout = null;
      }

      if (this._backoffTimeout) {
         clearTimeout(this._backoffTimeout);
         this._backoffTimeout = null;
      }

      if (this._disconnectRequestTimeout) {
         clearTimeout(this._disconnectRequestTimeout);
         this._disconnectRequestTimeout = null;
      }
   };

   /**
    * destroy
    *
    * Destroys this session after performing the necessary cleanup actions
    */
   protected destroy = () => {
      if (!this.isDestroyed) {
         this.destroyWmks();
         this.isActive = false;
         this._cleanup(false);
         this.reconnectToken = null;
         this.destroy$.next("");
         this._wmksService.sessionOnRemoved(this);
         this.sessionUtil.onSessionRemoved(this.isMultiSession, this.key);
         this.isDestroyed = true;
      }
   };

   protected isIdleTimeout = () => {
      return this.isApplicationSession && this._wmksService.isSessionTimedOut();
   };

   public enableWindowsKey = (enabled: boolean) => {
      if (!this.isApplicationSession) {
         this._enableWindowsKey(enabled);
      }
   };

   public enableWindowsDeleteKey = (enabled: boolean) => {
      this._enableWindowsDeleteKey(enabled);
   };

   public checkSessionInMultimon = () => {
      this._wmksService.checkSessionInMultimon(this.key, () => {
         this.updateResolution(CST.clientUtil.getWindowResolution(), true);
         if (WMKS.BROWSER.isAndroid()) {
            this.handleTouchResize();
         } else {
            this.updateResolution(CST.clientUtil.getWindowResolution(), true);
         }
      });
   };

   /**
    * handleTouchResize
    *
    * We need to resize the remote desktop for onSize when check session.
    */
   private handleTouchResize = () => {
      const callback = (screenSize) => {
         this.updateResolution(screenSize, true);
      };
      AB.getTouchDeviceDesiredResolution(callback);
   };
   /**
    * closeDisconnectedDialog
    *
    * Close the disconnected dialog.
    */
   protected closeDisconnectedDialog = (value) => {
      if (this._disconnectedDialogId && this.modalDialogService.isDialogOpen(this._disconnectedDialogId)) {
         try {
            this.modalDialogService.close(this._disconnectedDialogId);
            this._disconnectedDialogId = null;
            this._dialogCloseReason = value;
            this.logger.debug("Closing disconnected dialog with value" + value);
         } catch (e) {
            this.logger.error("Closing dialog " + this._disconnectedDialogId + " fails");
            this.logger.exception(e);
         }
      }
   };

   /*
    * If the machine hasn't been active for mins,
    * Pop up disconnect dialog directly as the connection
    * can't reconnect again. bug#2274379
    */
   protected _reconnectMustFailure = (code, closeReason) => {
      return CST.clientUtil.isSystemSleepTooLongToReconnect(this._heartbeatInterval);
   };
   protected canReconnect = (code, closeReason) => {
      return (
         !isMKSSessionClosedNormally(code, closeReason) &&
         !webMksReconnectFailure(code) &&
         !this.isIdleTimeout() &&
         !this._reconnectMustFailure(code, closeReason)
      );
   };
   protected shouldTryCert = (code?: number, closeReason?: number) => {
      return (
         !this.triedSSLVerify &&
         !this.wasWmksConnectedOnce &&
         this.url &&
         (this.canReconnect(code, closeReason) || webMksReconnectFailure(code))
      );
   };

   protected _startReconnection = () => {
      if (!this._reconnectTimeout) {
         this._reconnectTimeout = setTimeout(() => {
            this.logger.warning("Give up on reconnection. sending code: 3000");
            this._reconnectTimeout = null;
            this.OnWmksDisconnected(BlastCloseEvent.RECONNECT_FAILED, VDPConnectionResult.VDPCONNECT_TIMEOUT);
            /*
             * Give it two minutes before we give up --
             * The BSG will revoke our route after exactly 2 minutes.
             */
         }, AB.BlastWMKS.BSG_TIMEOUT_MS);
      }

      // Exponentially backoff our reconnect attempts with a max of
      // 20s.
      this._backoffDelayMs = Math.min(2 * this._backoffDelayMs, 20000);
      this.logger.info("Attempt reconnect in (ms): " + this._backoffDelayMs);

      if (this._backoffDelayMs) {
         this.logger.debug("Unexpected backoff timeout does exist.");
      }

      this._backoffTimeout = setTimeout(() => {
         this.logger.debug("Attempting to reconnect to desktop...");
         // Use storing url and setting params to reconnect.
         this.initialize(this.url, this.settings);
      }, this._backoffDelayMs);
   };

   protected reconnectSession = () => {
      if (this._backoffTimeout) {
         clearTimeout(this._backoffTimeout);
         this._backoffTimeout = null;
      }
      this.initialize(this.url, this.settings);
   };

   protected tryAcceptSelfSignCert = (code) => {
      this.logger.info("check for showing cert for remote session");
      /* matches things of the form:
       * wss://<host>:<port>/<path>/<auth token>
       * We strip out the auth token and replace wss with https
       */
      const match = this.url.match(/wss:\/\/(\[?\w.+:\d+\/\S*\/)\S*/);
      if (!(match && match.length >= 2)) {
         this.logger.error("unable to generate the cert page link from url");
         return;
      }
      let numPages = 1;

      /**
       * The Windows 10 Edge browser breaks our un-trust SSL
       * workflow because its un-trust cert warning clobbers
       * the history entry for the client page. This means that
       * moving back one page takes us back to certAccept.html,
       * and moving back 2 pages takes us back to the launcher
       * page.
       *
       * The fix is to use history.pushState() to add a new
       * entry for the client page. This entry is just a
       * duplicate of the URL of the client page. This entry
       * gets clobbered by Edge, but having the extra entry
       * means that the original history entry for the client
       * page is not touched by edge, so moving back two pages
       * takes us back to the client page.
       * Push 3 extra history for graceful fail when websocket error
       * found, which is not caused by certification error.
       * Below should also work for Chromium based Edge.
       */
      if (WMKS.BROWSER.isIE()) {
         this.logger.info("Pushing extra history entry on Edge");
         history.pushState({ state: "certAcceptReturnAddress" }, "", window.location.href);
         /**
          * use below workaround fix 2581812 while avoid regression
          * when have either cert error and cert warning
          *
          * Keep the jump to reduce regression risk for unknown
          * error types that need manual accept.
          *
          * Use one extra record should be responding to numPage = 2
          */
         if (code !== 1015) {
            history.pushState({ state: "certAcceptReturnAddress2" }, "", window.location.href);
         }
         /**
          * After Angular upgrade, jump of 2 is enough.
          * So remove the workaround related to numPages of 3.
          */
         numPages = 2;
      }

      const redirectUrl = "https://" + match[1] + "certAccept.html?numPages=" + numPages;

      // If we have not tried to verify the cert of the agent
      // then do so.
      this.triedSSLVerify = true;
      this._wmksService.updateTriedSSLVerify().then(() => {
         this.logger.info("show cert for remote session");
         this.closeDisconnectedDialog("old");
         this.injector.get(CertService).tryToShowCertPage(redirectUrl);
      });
   };

   /**
    * Function called when close disconnected dialog
    * there are 4 kinds of close reason:
    * 1. 'backRun': when reconnecting is true and user click button YES
    *    for disconnected dialog
    * 2. 'old': close last opened disconnected dialog before open a new
    *    disconnected dialog or when we have not tried to verify the cert
    *    of the agent
    * 3. 'connected':  when we resetStateAndInitHeartbeat, Upon successful
    *    connection we have to clear some old state if this is from a previous
    *    reconnect attempt.
    */
   private _closeDialogCallback = (closeReasonArg?: string) => {
      let closeReason: string;
      if (closeReasonArg) {
         closeReason = closeReasonArg;
      } else {
         closeReason = this._dialogCloseReason;
      }
      this.logger.info("The reason for closing disconnected dialog: " + closeReason);
      setTimeout(() => {
         //wait for 5s for all the sessions pop up dialog.
         CST.clientUtil.clearSystemSleepTime();
      }, 5000);
      if (closeReason === "backRun") {
         this._disconnectedDialogOption = null;
         return;
      }
      this._runningReconnectInBackGround = false;
      if (closeReason !== "old") {
         this._disconnectedDialogOption = null;

         // Destroy everything unless the dialog is closed by a
         // connection
         if (closeReason !== "connected") {
            this.destroy();
         }
      }
   };

   /**
    * openDisconnectedDialog
    *
    * Callback to displays the dialog indicating the desktop has been
    * disconnected. Or to bring up the SSL CertAccept page for the agent
    * we are trying to connect to if triedSSLVerify is false.
    *
    * @params reconnecting are we trying to reconnect
    * @params reason: a optional DISCONNECT_REASON code
    */
   protected _openDisconnectedDialog = (reconnecting, reason?: any) => {
      let dialogText;

      this.closeDisconnectedDialog("old");
      dialogText = this.translate._T("DESKTOP_DISCONNECTED_M");

      if (reconnecting) {
         dialogText += " " + this.translate._T("ATTEMPTING_RECONNECT_M");
      } else {
         switch (reason) {
            case BlastCloseEvent.RECONNECT_FAILED:
               dialogText += " " + this.translate._T("RECONNECT_FAIL_M");
               break;
            case AB.DISCONNECT_REASON.VDPCONNECT_SERVER_SHADOW_SESSION_ENDED:
               dialogText = this.translate._T("COLLABORATION_SESSION_ENDED");
               break;
            default:
            // unrecognized reason, no need to add anything
         }
         this._runningReconnectInBackGround = false;
         // destroy unless we plan to reconnect
         this.destroy();
      }

      if (reconnecting) {
         /*
          ** Here we should use openConfirm ,but it can't be closed
          ** by close() method, so that we can't get closePromise.
          ** We use open instead of openConfirm in order we could
          ** close it by codes as before.
          ** It helps us resolve the promise that was returned.
          */
         this._disconnectedDialogId = this.modalDialogService.showConfirm({
            data: {
               title: this.translate._T("DESKTOP_DISCONNECTED_T"),
               content: dialogText,
               backdrop: "static",
               buttonLabelConfirm: this.translate._T("YES"),
               buttonLabelCancel: this.translate._T("NO")
            },
            callbacks: {
               confirm: () => {
                  /*
                   ** Button Yes, put the reconnection to background and
                   ** try to reconnect
                   */
                  this._runningReconnectInBackGround = true;
                  this._closeDialogCallback("backRun");
               },
               cancel: this._closeDialogCallback
            }
         });
      } else {
         this._disconnectedDialogId = this.modalDialogService.showError({
            data: {
               title: this.translate._T("DESKTOP_DISCONNECTED_T"),
               content: dialogText,
               buttonLabelConfirm: this.translate._T("CLOSE")
            },
            callbacks: {
               confirm: this._closeDialogCallback
            }
         });
      }
      this.logger.info("Dialog is created for " + this.key + " with disconnectedDialog");
   };

   /**
    * setEmpty
    *
    * Change the application session is empty or not.
    * @params isEmpty if there is any unity window active on this session.
    */
   public setEmpty = (isEmpty) => {
      if (!this.isApplicationSession) {
         this.logger.error("We can not set a desktop session " + this.key + " to empty.");
         return;
      }
      this.isEmpty = isEmpty;
   };

   /**
    * resetStateAndInitHeartbeat
    *
    * Upon successful connection we have to clear some old state if this
    * is from a previous reconnect attempt. Remove old dialogs, reset
    * timeouts, initialize heartbeats, etc. For more details see PR:
    * 1200406
    */
   private _resetStateAndInitHeartbeat = () => {
      // Close the reconnecting dialog now that we're
      // connected/reconnected.
      this.logger.info("is connected and close all the disconnected dialog");
      this._runningReconnectInBackGround = false;
      this.closeDisconnectedDialog("connected");
      this._disconnectedDialogOption = null;

      // Clear any reconnect timeouts if set.
      if (this._reconnectTimeout !== null) {
         clearTimeout(this._reconnectTimeout);
         this._reconnectTimeout = null;
      }
      // Reset the backoffDelayMs used for reconnect.
      this._backoffDelayMs = AB.BlastWMKS.INIT_BACKOFF_DELAY_MS;

      /*
       * Initialize the heartbeat as soon as we have a successful connection.
       * A successful connection is a valid heartbeat from the server.
       */
      this.OnWmksHeartbeat(this._heartbeatInterval);
   };

   /**
    * OnWmksDisconnected
    *
    * Called upon disconnection with the WebSocket close code.
    * We may come through here multiple times when trying to reconnect.
    */
   public OnWmksDisconnected(code: number, closeReason: number) {
      this.ajaxBusyService.setAjaxBusy(false);
      this.eventBusService.dispatch(new BusEvent.SessionConnectMsg(false));
      // Fix for 1589336 When refreshing page, return directly.
      // Mainly for Firefox, but the code does not do harm to Chrome or Safari.
      if (this._wmksService.beforeunload === true && CST.clientUtil.isFirefox()) {
         return;
      }

      if (this.mainChannel) {
         delete this.mainChannel;
      }
      this.logger.warning("disconnected: " + code);

      // make sure we only issue the disconnection event once.
      if (this._isWmksActive) {
         this._wmksService.sessionOnDisconnected(this, code);
      }
      this._isWmksActive = false;

      if (this.smartCardDummyService && this.htmlCdrService) {
         this.smartCardDummyService.onDisconnected(this.key);
         this.htmlCdrService.onWmksSessionDisconnected(this.key);
      }

      if (this.requestedDisconnect || (this.isApplicationSession && this.isEmpty && this.wasWmksConnectedOnce)) {
         this.destroy();
         return;
      } else {
         this._cleanup(true);
         this.destroyWmks();
      }
      this.reconnectHandler(code, closeReason);
   }

   public updateDPIInfo = (scaleDPI) => {
      this.logger.info(`dpi upgrade to ${scaleDPI}`);
      this.cursorHandler();
      this._dpiDataSyncSuccess = true;
   };

   public isForeground = () => {
      return this._wmksService.getCurrentSession()?.key === this.key;
   };

   public sendCursor = (src, width, height, hotx, hoty) => {
      this.eventBusService.dispatch(new BusEvent.CursorChanged(src, width, height, hotx, hoty));
   };

   public sendDisplayInfo = (targetDPI) => {
      return new Promise((resolve, reject) => {
         this.commonSvcService
            .getCurrentDisplayInfo()
            .then((displayInfo: Array<DisplayTopologyBriefInfo>) => {
               this.logger.info("init display by " + JSON.stringify(displayInfo));
               this.commonSvcService
                  .sendDisplayInfo(this.key, displayInfo)
                  .then((needSendAgentDPI) => {
                     this.logger.info("needSendAgentDPI is " + needSendAgentDPI + ", targetDPI is " + targetDPI);
                     if (needSendAgentDPI && targetDPI) {
                        this.wmks("option", "agentDPI", targetDPI);
                     }
                     resolve(true);
                  })
                  .catch((e) => {
                     Logger.exception(e);
                     reject(e);
                  });
            })
            .catch((e) => {
               Logger.exception(e);
               reject(e);
            });
      });
   };

   public networkStateHandler = () => {
      if (!this.option.disableNetworkStateDisplay) {
         this.networkStateService.networkStateHandler(this);
      }
   };

   public getLoggerWrapper = () => {
      const loggerWrapper: any = Object.assign({}, this.logger);
      loggerWrapper.warn = this.logger.warning;
      loggerWrapper.log = Logger.log;
      return loggerWrapper;
   };
}
