/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injector } from "@angular/core";
import { BusEvent, EventBusService } from "../../../core/services/event";

export abstract class LogoutService {
   public eventBusService: EventBusService = null;
   private isInit: boolean = false;
   constructor(injector: Injector) {
      this.eventBusService = injector.get(EventBusService);
   }

   public init() {
      if (this.eventBusService && !this.isInit) {
         this.isInit = true;
         this.eventBusService.listen(BusEvent.LogOutMsg.MSG_TYPE).subscribe((msg: BusEvent.LogOutMsg) => {
            if (msg.silence) {
               this.logoutSilence();
            } else {
               this.logoutWithConfirm();
            }
         });
      }
   }
   public abstract logoutSilence(): void;
   public abstract logoutWithConfirm(): void;
   public abstract postLogoff(): void;
}
