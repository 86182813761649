/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class ClipboardDesktopService {
   public static readonly delayTime = 300;
   public abstract onMonitorReady(): void;
   public abstract shouldSkipPaste(timeOnFoucsCurrentWindow: number): boolean;
   public abstract blockKeyEventOnFoucs(timeOnFoucsCurrentWindow: number, updateKey: Function): void;
   public getMainWindow = () => {
      let windows = chrome.app.window.getAll();
      for (let i = 0; i < windows.length; i++) {
         if (windows[i].id === "HTMLAccessChromeWindow") {
            return windows[i];
         }
      }
      return null;
   };
}
