/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Diffie Hellman Key generation for encryption/decryption
 *
 * The service generates the DH key pair at the client side
 *
 * The DH secret key is generated on receiving the server DH public key. SHA384
 * hash is generated using a 4 byte counter, the generated secret key,
 * negotiated algorithm, nonce, client identifier and server identifier. The
 * first 16 bytes is used as the HMAC key, based on the algorithm negotiated
 * either a 256 bit AES client/server key (AES2) or two 128 bit client/server key
 * (AES1) will be generated.
 *
 * The utility also provides mechanism for encryption/decryption using AES 128
 * CFB mode (AES1) or AES 256 GCM mode (AES2)  based on the negotiated algorithm.
 *
 */

import { Buffer } from "buffer/";
import * as crypto from "crypto-browserify";
import { Inject, Injectable } from "@angular/core";
import { CLIENT, clientUtil, LocalStorageService } from "@html-core";
import { ViewClientModel } from "../model/viewclient-model";
import CRC32 from "crc-32";
import { Logger } from "@html-core";
import * as bigInt from "big-integer";
import { ModalDialogService } from "../commondialog/dialog.service";
import { TranslateService } from "@html-core";
import { Ws1Service } from "./ws1.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Optional } from "@angular/core";
import { WindowToken } from "../window.module";
import { EventBusService } from "../../../core/services/event/event-bus.service";

@Injectable({
   providedIn: "root"
})
export class CryptoKeyDerivationService {
   public static readonly PROTECTION_STATUS_STORAGE_KEY = "XML-API-DATA-PROTECTION";
   public static readonly DATA_PROTECTION_SCHEME = "data-protection-scheme";
   public static readonly DATA_PROTECTION_CLIENT_KEY = "data-protection-client-key";
   public static readonly DATA_PROTECTION_SERVER_KEY = "data-protection-server-key";
   public static readonly DATA_PROTECTION_ORIGIN_TAG = "data-protection-origin-tag";
   public static readonly DATA_PROTECTION_PROOF = "data-protection-proof";
   public static readonly DATA_PROTECTION_CRC = "data-protection-crc";
   public static readonly PRIME: string =
      "90066455B5CFC38F9CAA4A48B4281F292C260FEEF01FD61037E56258A7795A1C7AD46076982CE6BB956936C6AB4DCFE05E6784586940CA544B9B2140E1EB523F009D20A7E7880E4E5BFA690F1B9004A27811CD9904AF70420EEFD6EA11EF7DA129F58835FF56B89FAA637BC9AC2EFAAB903402229F491D8D3485261CD068699B6BA58A1DDBBEF6DB51E8FE34E8A78E542D7BA351C21EA8D8F1D29F5D5D15939487E27F4416B0CA632C59EFD1B1EB66511A5A0FBF615B766C5862D0BD8A3FE7A0E0DA0FB2FE1FCB19E8F9996A8EA0FCCDE538175238FC8B0EE6F29AF7F642773EBE8CD5402415A01451A840476B2FCEB0E388D30D4B376C37FE401C2A2C2F941DAD179C540C1C8CE030D460C4D983BE9AB0B20F69144C1AE13F9383EA1C08504FB0BF321503EFE43488310DD8DC77EC5B8349B8BFE97C2C560EA878DE87C11E3D597F1FEA742D73EEC7F37BE43949EF1A0D15C3F3E3FC0A8335617055AC91328EC22B50FC15B941D3D1624CD88BC25F3E941FDDC6200689581BFEC416B4B2CB73";
   public static readonly GENERATOR: string =
      "5E5CBA992E0A680D885EB903AEA78E4A45A469103D448EDE3B7ACCC54D521E37F84A4BDD5B06B0970CC2D2BBB715F7B82846F9A0C393914C792E6A923E2117AB805276A975AADB5261D91673EA9AAFFEECBFA6183DFCB5D3B7332AA19275AFA1F8EC0B60FB6F66CC23AE4870791D5982AAD1AA9485FD8F4A60126FEB2CF05DB8A7F0F09B3397F3937F2E90B9E5B9C9B6EFEF642BC48351C46FB171B9BFA9EF17A961CE96C7E7A7CC3D3D03DFAD1078BA21DA425198F07D2481622BCE45969D9C4D6063D72AB7A0F08B2F49A7CC6AF335E08C4720E31476B67299E231F8BD90B39AC3AE3BE0C6B6CACEF8289A2E2873D58E51E029CAFBD55E6841489AB66B5B4B9BA6E2F784660896AFF387D92844CCB8B69475496DE19DA2E58259B090489AC8E62363CDF82CFD8EF2A427ABCD65750B506F56DDE3B988567A88126B914D7828E2B63A6D7ED0747EC59E0E0A23CE7D8A74C1D2C2A7AFB6A29799620F00E11C33787F7DED3B30E1A22D09F1FBDA1ABBBFBF25CAE05A13F812E34563F99410E73B";
   public static readonly Q: string = "CFA0478A54717B08CE64805B76E5B14249A77A4838469DF7F7DC987EFCCFB11D";
   public static readonly ALGO_SCHEME_1: string = "SCHEME-AES1";
   public static readonly ALGO_SCHEME_2: string = "SCHEME-AES2";
   public static readonly ALGO_SCHEME_1_PREAMBLE: string = "{#-ASKS=1:1}";
   public static readonly ALGO_SCHEME_2_PREAMBLE: string = "{#-ASKS=2:1}";
   public static readonly HMAC_SHA256: string = "sha256";
   public static readonly HMAC_SHA224: string = "sha224";
   public static readonly HMAC_IDENTIFIER: string = "KC_1_V";
   public static readonly HASH_ALGORITHM = "sha384";
   /**
    * The secrect key length needs to be 384 as the key material
    * which we generate also has to be 384 in size so that we can
    * generate the HMAC and the client and server AES keys.
    */
   public static readonly SECRECT_KEY_LEN: number = 384;
   private dh;
   private COUNTER = new Uint8Array(4);
   private crc;
   private deCryptErrorDialog: string = null;
   public clientPublicKey;
   public serverPublicKey;
   // the scheme used to encrypt/decrypt
   public scheme: string;
   // in client side, client key used to encrypt data
   public clientKey;
   // in client side, server key used to decrypt data
   public serverKey;
   public keyedMACProof;
   public originTag;
   private xmlApiDataProtected: boolean;

   constructor(
      @Optional()
      private route: ActivatedRoute,
      @Optional()
      private router: Router,
      private viewClientModel: ViewClientModel,
      private localStorageService: LocalStorageService,
      private modalDialogService: ModalDialogService,
      private translate: TranslateService,
      private ws1Service: Ws1Service,
      private eventBusService: EventBusService,
      @Inject(WindowToken) private window: Window
   ) {
      // Big-endian 4-byte unsigned integer counter initialized as 0x00000000
      // The counter is incremented by 1 to generate the final hash
      // More info : https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-56Cr2.pdf (page 14)
      this.COUNTER[3] = 0x1;
      this.dh = crypto.createDiffieHellman(
         CryptoKeyDerivationService.PRIME,
         "hex",
         CryptoKeyDerivationService.GENERATOR,
         "hex"
      );
   }

   public base64encode = (str: string, input_encoding?: BufferEncoding): string => {
      return Buffer.from(str, input_encoding).toString("base64");
   };

   public base64decode = (base64Str: string, output_encoding?: BufferEncoding): string => {
      return Buffer.from(base64Str, "base64").toString(output_encoding);
   };

   public getDHClientPublicKey = (encoding: BufferEncoding = "base64"): string => {
      return this.dh.generateKeys().toString(encoding);
   };

   public getNonce = (size: number = 16, encoding: BufferEncoding = "base64"): string => {
      return crypto.randomBytes(size).toString(encoding);
   };

   public getClientIdentifier = (): string => {
      const clientType = CLIENT.viewclientType;
      const clientLocationID = this.viewClientModel.AssetID || this.viewClientModel.clientID;
      const clientIdentifier = clientType.concat(clientLocationID);
      return this.base64encode(clientIdentifier);
   };

   public getProof() {
      this.keyedMACProof = this.localStorageService.get(CryptoKeyDerivationService.DATA_PROTECTION_PROOF);
      return this.keyedMACProof;
   }

   public computeDHSecretKey = (serverPublicKey: string) => {
      const serverPublicKeyBuffer = Buffer.from(serverPublicKey, "base64");
      const DHSecretKeyArr = this.dh.computeSecret(serverPublicKeyBuffer);
      let DHSecretKeyOriginLen = DHSecretKeyArr.length;
      const prependSecretArr = new Uint8Array(CryptoKeyDerivationService.SECRECT_KEY_LEN);
      DHSecretKeyOriginLen = DHSecretKeyArr.length;
      //if the DH secret key is less than 384 then prepend it with zeros
      if (DHSecretKeyOriginLen < CryptoKeyDerivationService.SECRECT_KEY_LEN) {
         const prependLen = CryptoKeyDerivationService.SECRECT_KEY_LEN - DHSecretKeyOriginLen;
         for (let m = 0; m < prependLen; m++) {
            prependSecretArr[m] = 0;
         }
         for (let n = prependLen; n < CryptoKeyDerivationService.SECRECT_KEY_LEN; n++) {
            prependSecretArr[n] = DHSecretKeyArr[n - prependLen];
         }
         return btoa(String.fromCharCode.apply(null, prependSecretArr));
      }
      return DHSecretKeyArr.toString("base64");
   };

   private concatuint8Arrays = (arrayOfuint8Arrays: Array<Uint8Array>): Uint8Array => {
      // sum of individual array lengths
      const totalLength = arrayOfuint8Arrays.reduce((acc, value) => acc + value.length, 0);

      if (!arrayOfuint8Arrays.length) return null;

      const result = new Uint8Array(totalLength);

      // for each array - copy it over result
      // next array is copied right after the previous one
      let length = 0;
      for (const array of arrayOfuint8Arrays) {
         result.set(array, length);
         length += array.length;
      }

      return result;
   };

   /**
    * Returns whether the Diffie-Hellman server public key is valid or not.
    *
    * Per RFC 2631 and NIST SP800-56A, the following algorithm is used to
    * validate Diffie-Hellman public keys:
    * 1. Verify that y lies within the interval (2,prime-2). If it does not,
    *    the key is invalid.
    * 2. Compute y^q mod p. If the result == 1, the key is valid.
    *    Otherwise the key is invalid.
    */
   public validateKeyStrength = (publicKey: string) => {
      let keyStrengthRes;
      const publicKeyBytes = Buffer.from(publicKey, "base64");
      const publicKeyHexStr = publicKeyBytes.toString("hex");
      const publicKeybn = bigInt(publicKeyHexStr, 16);

      const QArr = Buffer.from(CryptoKeyDerivationService.Q, "hex");
      const QbInt = bigInt(CryptoKeyDerivationService.Q, 16);
      const leftOpenbn = bigInt(2);
      const primeArr = Buffer.from(CryptoKeyDerivationService.PRIME, "hex");
      const primebInt = bigInt(CryptoKeyDerivationService.PRIME, 16);
      const rightOpenbn = primebInt.minus(2);

      // check first condition describe in function comment
      if (publicKeybn.minus(leftOpenbn).greater(0) && publicKeybn.minus(rightOpenbn).lesser(0)) {
         keyStrengthRes = true;
      } else {
         keyStrengthRes = false;
      }

      // check second condition describe in function comment
      if (publicKeybn.modPow(QbInt, primebInt).equals(1)) {
         keyStrengthRes = true;
      } else {
         keyStrengthRes = false;
      }
      return keyStrengthRes;
   };

   public generateFinalKeyAndProof = (
      DHSecretKey: string,
      scheme: string,
      nonce: string,
      clientIdentifier: string,
      serverIdentifier: string
   ) => {
      const finalKeyByteArr = this.concatuint8Arrays([
         this.COUNTER,
         Buffer.from(DHSecretKey, "base64"),
         Buffer.from(scheme, "utf8"),
         Buffer.from(nonce, "base64"),
         Buffer.from(clientIdentifier, "base64"),
         Buffer.from(serverIdentifier, "base64")
      ]);
      const hash = crypto.createHash(CryptoKeyDerivationService.HASH_ALGORITHM);
      const hashKeyMaterial = Buffer.from(hash.update(finalKeyByteArr).digest("base64"), "base64");
      let HMAC_ALGO, HMACKey;
      if (scheme === CryptoKeyDerivationService.ALGO_SCHEME_2) {
         this.scheme = scheme;
         HMAC_ALGO = CryptoKeyDerivationService.HMAC_SHA256;
         HMACKey = hashKeyMaterial.slice(0, 16);
         this.clientKey = hashKeyMaterial.slice(16, 48);
         this.serverKey = hashKeyMaterial.slice(16, 48);
      } else if (scheme === CryptoKeyDerivationService.ALGO_SCHEME_1) {
         this.scheme = scheme;
         HMAC_ALGO = CryptoKeyDerivationService.HMAC_SHA224;
         HMACKey = hashKeyMaterial.slice(0, 16);
         this.clientKey = hashKeyMaterial.slice(16, 32);
         this.serverKey = hashKeyMaterial.slice(32, 48);
      }
      const keyedMAC = this.concatuint8Arrays([
         Buffer.from(CryptoKeyDerivationService.HMAC_IDENTIFIER, "utf8"),
         Buffer.from(serverIdentifier, "base64"),
         Buffer.from(clientIdentifier, "base64"),
         Buffer.from(nonce, "base64")
      ]);
      const hmac = crypto.createHmac(HMAC_ALGO, HMACKey);
      this.keyedMACProof = hmac.update(keyedMAC).digest("base64");
      this.localStorageService.set(CryptoKeyDerivationService.DATA_PROTECTION_PROOF, this.keyedMACProof);
      Logger.info("we generate the proof as base64 string: " + this.keyedMACProof);
   };

   public validateProof = (serverProof) => {
      this.keyedMACProof = this.localStorageService.get(CryptoKeyDerivationService.DATA_PROTECTION_PROOF);
      this.toggleXmlApiDataProtectionStatus(this.keyedMACProof === serverProof);
      if (this.keyedMACProof === serverProof) {
         Logger.info(
            "client proof is the same as server proof, store scheme, client key and server key in local storage"
         );
         this.localStorageService.set(CryptoKeyDerivationService.DATA_PROTECTION_SCHEME, this.scheme);
         this.localStorageService.set(
            CryptoKeyDerivationService.DATA_PROTECTION_CLIENT_KEY,
            this.clientKey.toString("base64")
         );
         this.localStorageService.set(
            CryptoKeyDerivationService.DATA_PROTECTION_SERVER_KEY,
            this.serverKey.toString("base64")
         );
         return true;
      } else {
         return false;
      }
   };

   public showKeyNegotiateError = () => {
      this.modalDialogService.showError({
         data: {
            title: this.translate._T("ERROR"),
            content: this.translate._T("Key_Negotiate_errorMsg")
         },
         callbacks: {
            confirm: () => {
               Logger.info("jump back to landing page", Logger.ROUTE);
               if (!clientUtil.isChromeClient()) {
                  if (this.viewClientModel.contextPath === null) {
                     this.viewClientModel.contextPath = "";
                  }
                  setTimeout(() => {
                     let landingAddress = this.window.location.protocol + "//" + this.window.location.host;
                     landingAddress += this.viewClientModel.contextPath;
                     this.window.location.href = landingAddress;
                  });
               } else {
                  this.router.navigate(["/serverselect"], { relativeTo: this.route });
                  if (this.ws1Service.isWS1Mode()) {
                     clientUtil.closeAllWindows();
                  }
               }
            }
         }
      });
   };

   public showAESDecryptError = () => {
      if (this.deCryptErrorDialog && this.modalDialogService.isDialogOpen(this.deCryptErrorDialog)) {
         return;
      }
      this.deCryptErrorDialog = this.modalDialogService.showError({
         data: {
            title: this.translate._T("AES_Decrypt_Error_Title"),
            content: this.translate._T("AES_Decrypt_Error_Message")
         },
         callbacks: {
            confirm: () => {
               Logger.info("stop loading, jump back to desktop and application select page", Logger.ROUTE);
            }
         }
      });
   };

   /* this function return data as [CRC32][Padding Data Length][Padding Data][Data] */
   public addCRC32Padding = (originData: string) => {
      // pick a random int inside [0, 7] to set as padding data length
      const paddingDataArrayLen = Math.floor(Math.random() * 8);
      // get the area to contain paddingDataArray's Length as uint8Array format, the length of it is 1
      const paddingDataLengthArr = new Uint8Array(1);
      paddingDataLengthArr[0] = parseInt(paddingDataArrayLen.toString(), 8);
      // get the content of paddingDataArray as uint8Array format, the length of it is paddingDataArrayLen
      const paddingDataArr = new Uint8Array(paddingDataArrayLen).fill(0);
      // get the content of originDataArr as uint8Array format
      const originDataArr = Buffer.from(originData);
      const crcData = this.concatuint8Arrays([paddingDataLengthArr, paddingDataArr, originDataArr]);
      // get the content of crc32 as uint8Array format, the length of it is 4
      const origincrc = CRC32.buf(crcData);
      const origincrcArr = new Int32Array(1);
      origincrcArr[0] = origincrc;
      this.crc = new Uint8Array(origincrcArr.buffer);
      // return the padded data with uint8array format
      return this.concatuint8Arrays([this.crc, paddingDataLengthArr, paddingDataArr, originDataArr]);
   };

   public checkCRC32 = (originData: string, paddingDataLen: number) => {
      // get the area to contain paddingDataArray's Length as uint8Array format, the length of it is 1
      const paddingDataLengthArr = new Uint8Array(1);
      paddingDataLengthArr[0] = parseInt(paddingDataLen.toString(), 8);
      // get the content of paddingDataArray as uint8Array format, the length of it is paddingDataArrayLen
      const paddingDataArr = new Uint8Array(paddingDataLen).fill(0);
      // get the content of originDataArr as uint8Array format
      const originDataArr = Buffer.from(originData);
      const crcData = this.concatuint8Arrays([paddingDataLengthArr, paddingDataArr, originDataArr]);
      // get the content of crc32 as uint8Array format, the length of it is 4
      const origincrc = CRC32.buf(crcData);
      const origincrcArr = new Int32Array(1);
      origincrcArr[0] = origincrc;
      this.crc = new Uint8Array(origincrcArr.buffer);
      return this.crc;
   };

   public AESEncryptData = (originData: string) => {
      let encryptedData;
      // clientkey is the key used for encrypt for client side
      const clientKeyArr = Buffer.from(
         this.localStorageService.get(CryptoKeyDerivationService.DATA_PROTECTION_CLIENT_KEY),
         "base64"
      );
      const crcPaddingData = this.addCRC32Padding(originData);
      // aad in encrypt process is client identifier
      const aad = Buffer.from(this.getClientIdentifier(), "base64");
      if (
         this.localStorageService.get(CryptoKeyDerivationService.DATA_PROTECTION_SCHEME) ===
         CryptoKeyDerivationService.ALGO_SCHEME_1
      ) {
         const iv = crypto.randomBytes(16);
         const cipher = crypto.createCipheriv("aes-128-cfb8", clientKeyArr, iv);
         const cipherText = cipher.update(crcPaddingData);
         const cipherFinalText = this.concatuint8Arrays([cipherText, cipher.final()]);
         const encryptedDataArr = this.concatuint8Arrays([iv, cipherFinalText]);
         encryptedData =
            CryptoKeyDerivationService.ALGO_SCHEME_1_PREAMBLE + btoa(String.fromCharCode.apply(null, encryptedDataArr));
      } else if (
         this.localStorageService.get(CryptoKeyDerivationService.DATA_PROTECTION_SCHEME) ===
         CryptoKeyDerivationService.ALGO_SCHEME_2
      ) {
         const iv = crypto.randomBytes(12);
         const cipher = crypto.createCipheriv("aes-256-gcm", clientKeyArr, iv, { authTagLength: 12 });
         cipher.setAAD(aad);
         const cipherText = cipher.update(crcPaddingData);
         const cipherFinalText = this.concatuint8Arrays([cipherText, cipher.final()]);
         this.originTag = cipher.getAuthTag();
         this.localStorageService.set(
            CryptoKeyDerivationService.DATA_PROTECTION_ORIGIN_TAG,
            this.originTag.toString("base64")
         );
         const tag = cipher.getAuthTag().slice(0, 12);
         const encryptedDataArr = this.concatuint8Arrays([iv, cipherFinalText, tag]);
         encryptedData =
            CryptoKeyDerivationService.ALGO_SCHEME_2_PREAMBLE + btoa(String.fromCharCode.apply(null, encryptedDataArr));
      }
      return encryptedData;
   };

   public AESDecryptData = (decryptData: string) => {
      let preambleLength;
      decryptData = decryptData.trim();
      if (decryptData.indexOf(CryptoKeyDerivationService.ALGO_SCHEME_1_PREAMBLE) === 0) {
         preambleLength = CryptoKeyDerivationService.ALGO_SCHEME_1_PREAMBLE.length;
         decryptData = decryptData.slice(preambleLength, decryptData.length);
      } else if (decryptData.indexOf(CryptoKeyDerivationService.ALGO_SCHEME_2_PREAMBLE) === 0) {
         preambleLength = CryptoKeyDerivationService.ALGO_SCHEME_2_PREAMBLE.length;
         decryptData = decryptData.slice(preambleLength, decryptData.length);
      } else {
         return decryptData;
      }
      let decryptedData, paddingDataArr, decryptCRC, calculateCrc;
      const decryptDataArr = Buffer.from(decryptData, "base64");
      // servekey is the key used for decrypt for client side
      const serverKeyArr = Buffer.from(
         this.localStorageService.get(CryptoKeyDerivationService.DATA_PROTECTION_SERVER_KEY),
         "base64"
      );
      const aad = Buffer.from(this.getClientIdentifier(), "base64");
      const scheme = this.localStorageService.get(CryptoKeyDerivationService.DATA_PROTECTION_SCHEME);

      if (scheme === CryptoKeyDerivationService.ALGO_SCHEME_1) {
         const iv = decryptDataArr.slice(0, 16);
         const cipherText = decryptDataArr.slice(16, decryptDataArr.length);
         const decipher = crypto.createDecipheriv("aes-128-cfb8", serverKeyArr, iv);
         const plainText = decipher.update(cipherText);
         const finalPlainText = this.concatuint8Arrays([plainText, decipher.final()]);
         decryptCRC = finalPlainText.slice(0, 4);
         const paddingLengthArr = finalPlainText.slice(4, 5);
         const paddingDataLength = paddingLengthArr[0];
         paddingDataArr = finalPlainText.slice(5, 5 + paddingDataLength);
         const originData = finalPlainText.slice(5 + paddingDataLength, finalPlainText.length);
         decryptedData = String.fromCharCode.apply(null, originData);
         calculateCrc = this.checkCRC32(decryptedData, paddingDataLength);
      } else if (scheme === CryptoKeyDerivationService.ALGO_SCHEME_2) {
         const iv = decryptDataArr.slice(0, 12);
         const cipherText = decryptDataArr.slice(12, decryptDataArr.length - 12);
         const tag = decryptDataArr.slice(decryptDataArr.length - 12, decryptDataArr.length);
         const decipher = crypto.createDecipheriv("aes-256-gcm", serverKeyArr, iv, { authTagLength: 12 });
         decipher.setAAD(aad);
         const plainText = decipher.update(cipherText);
         decipher.setAuthTag(tag);
         const finalPlainText = this.concatuint8Arrays([plainText, decipher.final()]);
         decryptCRC = finalPlainText.slice(0, 4);
         const paddingLengthArr = finalPlainText.slice(4, 5);
         const paddingDataLength = paddingLengthArr[0];
         paddingDataArr = finalPlainText.slice(5, 5 + paddingDataLength);
         const originData = finalPlainText.slice(5 + paddingDataLength, finalPlainText.length);
         decryptedData = String.fromCharCode.apply(null, originData);
         calculateCrc = this.checkCRC32(decryptedData, paddingDataLength);
      }
      // verified that padding data is all 0
      for (const paddingData of paddingDataArr) {
         if (paddingData !== 0) {
            this.eventBusService.dispatch({
               type: "stopLoading"
            });
            Logger.error(`Decrypt data failed using ${scheme}, because the padding data isn't all 0`);
            return decryptedData;
         }
      }
      // verified CRC32 is correct
      for (let i = 0; i < 4; i++) {
         if (decryptCRC[i] !== calculateCrc[i]) {
            this.eventBusService.dispatch({
               type: "stopLoading"
            });
            Logger.error(`Decrypt data failed using ${scheme}, because verified CRC32 wrong`);
            return decryptedData;
         }
      }
      return decryptedData;
   };

   public getXmlApiDataProtectionStatus = () => {
      return this.xmlApiDataProtected;
   };

   /**
    * Enable/Disabled XML-API data protection.
    */
   public toggleXmlApiDataProtectionStatus = (enabled: boolean) => {
      this.xmlApiDataProtected = enabled;
      this.localStorageService.set(
         CryptoKeyDerivationService.PROTECTION_STATUS_STORAGE_KEY,
         this.xmlApiDataProtected ? "true" : "false"
      );
   };

   /**
    * The XML-API Data is Protected or not.
    */
   public isXmlApiDataProtected = (): boolean => {
      if (this.xmlApiDataProtected == null) {
         this.xmlApiDataProtected =
            this.localStorageService.get(CryptoKeyDerivationService.PROTECTION_STATUS_STORAGE_KEY) === "true";
      }
      return this.xmlApiDataProtected;
   };

   /**
    * Encrypt the data if the XML-API Data is Protected, otherwise return the original data.
    */
   public encryptIfXmlApiDataProtected = (data: string): string => {
      if (data.length > 0 && this.isXmlApiDataProtected()) {
         return this.AESEncryptData(data);
      }
      return data;
   };

   /**
    * Decrypt the data if the XML-API Data is Protected, otherwise return the original data.
    */
   public decryptIfXmlApiDataProtected = (data: string): string => {
      if (this.isXmlApiDataProtected()) {
         return this.AESDecryptData(data);
      }
      return data;
   };
}
