/**
 * *****************************************************
 * Copyright 2020 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { CDRService } from "./cdr-service";
import { FileSystem } from "./file-system";
import { CDRConfig } from "./cdr-config";
import { SharedFolderManager } from "./shared-folder-manager";
import { RdpdrChannelManager } from "./rdpdr-channel-manager";
import { TsdrChannelService } from "./tsdr-channel.service";
import { FsObserver } from "./fs-observer";
import { HtmlCDRService } from "../../../html5-client/desktop/cdr/html-cdr.service";
import { FileSystemService } from "./cdr-lib/file-system-service";
import { smartCardDummyService } from "../smart-card/smart-card-dummy.service";
import { FileHandlerCacheManagerService } from "./file-handler-cache-manager.service";

@NgModule({
   declarations: [],
   providers: [
      CDRConfig,
      FsObserver,
      SharedFolderManager,
      CDRService,
      FileSystem,
      RdpdrChannelManager,
      TsdrChannelService,
      HtmlCDRService,
      FileSystemService,
      smartCardDummyService,
      FileHandlerCacheManagerService
   ]
})
export class CDRModule {}
