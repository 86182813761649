/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Unity } from "./unity";
import { EventBusService, BusEvent, Logger } from "@html-core";

export namespace UnityEventExchange {
   export enum MessageType {
      onWindowAdded = "onWindowAdded",
      onWindowRemoved = "onWindowRemoved",
      onWindowMoved = "onWindowMoved",
      onVisibilityChanged = "onVisibilityChanged",
      onUpdateComplete = "onUpdateComplete",
      onReadyChanged = "onReadyChanged",
      onRegionUpdate = "onRegionUpdate",
      onTitleBarAreaChanged = "onTitleBarAreaChanged",
      onWindowAttrChanged = "onWindowAttrChanged",
      onZorderUpdated = "onZorderUpdated",
      onWindowSync = "onWindowSync"
   }
   export type Message = {
      type: MessageType;
      payload: any;
   };
   export type ReadyChangedMsg = {
      ready: boolean;
      isOn: boolean;
      paused: boolean;
   };
   export type UpdateCompleteMsg = {};
   export type VisibilityChangedMsg = {
      show: boolean;
   };
   export type RegionUpdateMsg = {
      windowId: string;
      regions: any[];
   };
   export type TitleBarAreaUpdateMsg = {
      windowId: string;
      windowPositionInfo: any;
   };
   export type WindowMovedMsg = {
      windowId: string;
      rect: any[];
   };
   export type WindowAddedMsg = {
      windowId: string;
      windowPath: string;
      execPath: string;
   };
   export type WindowRemovedMsg = {
      windowId: string;
   };
   export type WindowAttrChangedMsg = {
      windowId: string;
      type: Unity.UnityWindowAttribute;
      value: boolean;
   };
   export type ZorderUpdatedMsg = {
      windowId: string;
      components: any;
   };
}

export namespace UnityActionExchange {
   export enum MessageType {
      unminimize = "unminimize",
      minimize = "minimize",
      maximize = "maximize",
      unmaximize = "unmaximize",
      moveResizeWindow = "moveResizeWindow"
   }
   export type WindowIdPayload = {
      windowId: number;
   };
   export type WindowMovePayload = {
      wmksKey: string;
      windowId: number;
      x: number;
      y: number;
      width: number;
      height: number;
   };
   export type MessagePayload = WindowIdPayload | WindowMovePayload;

   export type Message = {
      type: MessageType;
      payload: MessagePayload;
   };
   export const createMessage = (messageType: MessageType, payload: MessagePayload) => {
      return <Message>{
         type: messageType,
         payload: payload
      };
   };
}

//TODO: remove not used types
import {
   UnityMessage,
   WindowAddMsg,
   WindowRemoveMsg,
   WindowTitleChangeMsg,
   WindowAttrChangeMsg,
   WindowTypeChangeMsg,
   WindowMoveMsg,
   WindowTitleBarAreaMsg,
   WindowPrimaryMsg,
   WindowSecondaryMsg,
   SystemTrayMsg,
   WindowRegionMsg,
   ZorderUpdateMsg,
   WindowIconChangeMsg,
   UnityAttrChangedMsg,
   UpdateCompleteMsg,
   UnityVisibilityChanged,
   UnityReady,
   UnityPaused,
   UnityNotReady,
   UnitySubject,
   WindowSyncMsg
} from "./unity-messages";
export namespace ExtendedWindowUnity {
   type HandleWithWindowId = (number) => void;
   export class Mgr implements Unity.BasicUnityMgrInterface {
      constructor(
         private sessionId: string,
         private eventBusService: EventBusService
      ) {}
      private generateHandleWithWindowId = (type: UnityActionExchange.MessageType): HandleWithWindowId => {
         return (windowId: number) => {
            Logger.info("extended monitor get unity request " + type + " for window id " + windowId);
            const message = UnityActionExchange.createMessage(type, { windowId });
            this.eventBusService.dispatch(new BusEvent.UnityClientRequest(message));
         };
      };

      public unminimize = this.generateHandleWithWindowId(UnityActionExchange.MessageType.unminimize);
      public minimize = this.generateHandleWithWindowId(UnityActionExchange.MessageType.minimize);
      public maximize = this.generateHandleWithWindowId(UnityActionExchange.MessageType.maximize);
      public unmaximize = this.generateHandleWithWindowId(UnityActionExchange.MessageType.unmaximize);
      public moveResizeWindow = (wmksKey, windowId, x, y, width, height) => {
         const message = UnityActionExchange.createMessage(UnityActionExchange.MessageType.maximize, {
            wmksKey,
            windowId,
            x,
            y,
            width,
            height
         });
         Logger.info(
            "extended monitor get unity request " +
               UnityActionExchange.MessageType.maximize +
               " for window id " +
               windowId
         );
         this.eventBusService.dispatch(new BusEvent.UnityClientRequest(message));
      };
   }

   export class Handler implements Unity.UnityOps {
      private logger: Logger;
      private subject$: UnitySubject;
      constructor(
         private wmksKey: string,
         subject: UnitySubject
      ) {
         Logger.debug("construct handler for unity");
         this.subject$ = subject;
         this.logger = new Logger(Logger.UNITY, wmksKey, Logger.Modules.Unity);
      }
      onAdd(windowId: string, winPath: string, execPath: string) {
         this.logger.trace("Window: " + windowId + " is added for " + winPath);
         this.logger.dump("onWindowAdded" + JSON.stringify({ windowId, winPath, execPath }));
         this.subject$.next(new WindowAddMsg(this.wmksKey, windowId, winPath, execPath));
      }

      /*
       * Called when Unity window is removed.
       *    windowId         ID of window to remove.
       */
      onRemove(windowId: string) {
         this.logger.trace("Window: " + windowId + " is removed");
         this.logger.dump("onWindowRemoved" + JSON.stringify({ windowId }));
         this.subject$.next(new WindowRemoveMsg(this.wmksKey, windowId));
      }

      /*
       * Called when Unity window title changes.
       *    windowId          ID of window to update.
       *    windowTitle       New window title.
       */
      onTitleChanged(windowId: string, windowTitle: string) {}

      /*
       * Called when Unity window attribute changes.
       *    windowId          ID of window to update.
       *    type              Attribute type.
       *    value             Attribute value (boolean).
       */
      onAttrChanged(windowId: string, type: Unity.UnityWindowAttribute, value: boolean) {
         this.subject$.next(new WindowAttrChangeMsg(this.wmksKey, windowId, type, value));
      }

      /*
       * Called when Unity window type changes.
       *    windowId         ID of window whose type changed.
       *    type             New window type.
       */
      onTypeChanged(windowId: string, type: Unity.UnityWindowType) {}

      /*
       * Called when Unity window are moved.
       *    windowId         ID of window whose type changed.
       *    rect             New position.
       */
      onWindowMoved(windowId: string, rect: any) {
         this.logger.trace("Window: " + windowId + " is moving");
         this.logger.dump("onWindowMoved" + JSON.stringify({ windowId, rect }));
         this.subject$.next(new WindowMoveMsg(this.wmksKey, windowId, rect));
      }

      /*
       * Called when Unity title bar are are changed.
       *    windowId         ID of window whose type changed.
       *    rect             New position.
       */
      onTitleBarAreaChanged(windowId: string, windowPositionInfo: any) {
         this.logger.dump("onTitleBarAreaChanged" + JSON.stringify({ windowId, windowPositionInfo }));
         this.subject$.next(new WindowTitleBarAreaMsg(this.wmksKey, windowId, windowPositionInfo));
      }

      onPrimaryWindowUpdate(windowId: string, primary: string) {}

      onSecondaryWindowUpdate(windowId: string, secondaryWindows: any[]) {}

      onTrayIconChanged(icons: any) {}

      onRegionUpdate(windowId: string, regions: any[]) {
         this.logger.dump("onRegionUpdate" + JSON.stringify({ windowId, regions }));
         this.subject$.next(new WindowRegionMsg(this.wmksKey, windowId, regions));
      }

      onZorderUpdate(components: any) {
         this.subject$.next(new ZorderUpdateMsg(this.wmksKey, components));
      }

      onWindowSync(wmksKey, windows, zOrders) {
         this.subject$.next(new WindowSyncMsg(wmksKey, windows, zOrders));
      }
      /*
       * Called when Unity window icon changes.
       *    windowId         ID of window whose icon changed.
       */
      onIconChanged(windowId: string) {}

      /*
       * Called when the remote desktop signals a change in whether or not it is ready for Unity.
       *    ready            true if ready, false if not ready.
       */
      onReadyChanged(ready: boolean, isOn: boolean, paused: boolean) {
         this.logger.dump("onReadyChanged" + JSON.stringify({ ready, isOn, paused }));
         this.logger.info("Server is " + (ready ? "ready" : "not ready") + " for unity.");
         if (ready) {
            if (isOn) {
               if (paused) {
                  this.logger.info("UnPause Unity now.");
                  this.subject$.next(new UnityReady(this.wmksKey));
               } else {
                  this.logger.info('Ignoring duplicate "Unity ready" notification.');
               }
            } else {
               this.logger.info("Entering Unity now.");
               this.subject$.next(new UnityReady(this.wmksKey));
            }
         } else {
            if (isOn) {
               if (paused) {
                  this.logger.info('Ignoring duplicate "Unity not ready" notification.');
               } else {
                  this.logger.info("Pausing Unity now.");
                  this.subject$.next(new UnityPaused(this.wmksKey));
               }
            } else {
               /*
                * chrome native Clients need this notification
                * but the HTML5 client don't need it.
                */
               this.subject$.next(new UnityNotReady(this.wmksKey));
               this.logger.info(' "Unity not ready" before entering Unity mode.');
               // Unity not ready, show login desktop directly.
               this.onVisibilityChanged(true);
            }
         }
      }

      /*
       * Called when the remote desktops signals a change in whether Unity is on or off.
       *    active           true if Unity is on, false if it is off.
       */
      onActiveChanged(active: boolean) {}

      /*
       * Called when the remote desktop signals their capabilities.
       *     caps             Array containing capabilities that the server advertised.
       */
      onCapsChanged(caps: any[]) {}

      /*
       * Called after a Unity update (RPC_PUSH_UPDATE_CMD) has been completely parsed
       * and one or more listeners called.
       */
      onUpdateComplete() {
         this.logger.dump("onUpdateComplete");
         this.subject$.next(new UpdateCompleteMsg(this.wmksKey));
      }
      onVisibilityChanged(show: boolean) {
         this.logger.dump("onVisibilityChanged: " + show);
         this.subject$.next(new UnityVisibilityChanged(this.wmksKey, show));
      }
      onAppAttrChanged(windowPath: string, execPath: string, name: string, iconSrc: any) {}
      onUrlRedirection(url: string) {}
   }
}
