/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getOnRampConfigCommand.js --
 *
 *
 */

import { globalArray } from "../jscdkClient";
import GetOnRampConfigHandler from "../controllers/getOnRampConfigHandler";

export default function GetOnRampConfigCommand() {}

/**
 * Invoked by JSCDKController
 *
 */

GetOnRampConfigCommand.prototype.execute = function () {
   let getOnRampConfigObject = globalArray["get-on-ramp-config"];

   getOnRampConfigObject = new GetOnRampConfigHandler();
   globalArray[getOnRampConfigObject.messageName] = getOnRampConfigObject;
   globalArray[getOnRampConfigObject.responseTag] = getOnRampConfigObject;

   if (globalArray["router"]) {
      getOnRampConfigObject.setRequestXML();
      const handlerList = getOnRampConfigObject.composeHandlerList();
      globalArray["router"].postMessage(handlerList);
   }
};
