/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * feature-configs.js
 *
 * Use this service when want to enable dynamic feature config.
 * see UT for usage details.
 */

import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class FeatureConfigs {
   private listenerMap: Map<string, Function[]>;
   private valueCache: Map<string, boolean>;

   constructor() {
      this.listenerMap = new Map<string, Function[]>();
      this.valueCache = new Map<string, boolean>();
   }

   public registerListener = (key: string, callback: Function) => {
      if (!this.listenerMap.has(key)) {
         this.listenerMap.set(key, []);
      }
      if (this.listenerMap.get(key).indexOf(callback) === -1) {
         this.listenerMap.get(key).push(callback);
      }
      if (this.valueCache.has(key)) {
         callback(this.valueCache.get(key));
      }
   };

   public unregisterListener = (key: string, callback: Function) => {
      if (this.listenerMap.has(key)) {
         const listeners = this.listenerMap.get(key);
         const index = listeners.indexOf(callback);
         if (index !== -1) {
            listeners.splice(index, 1);
         }
      }
   };

   public setConfig = (key: string, value: boolean) => {
      this.valueCache.set(key, value);
      if (this.listenerMap.has(key)) {
         const listeners = this.listenerMap.get(key);
         listeners.forEach((callback) => {
            callback(value);
         });
      }
   };

   public getConfig = (key: string) => {
      if (this.valueCache.has(key)) {
         return this.valueCache.get(key);
      }
      return undefined;
   };

   public clear = (): void => {
      this.listenerMap.clear();
      this.valueCache.clear();
   };
}
