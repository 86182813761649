/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { signal } from "../common/service/signal";

export abstract class CertService extends signal {
   public abstract tryToShowCertPage(url: string);
   public setXHRTimeoutValue() {}
}
