/** @format */

import { Injectable } from "@angular/core";
import { EventBusService, Logger } from "@html-core";
import { Subscription } from "rxjs";
import { PreferredRTAVDeviceService } from "../../../common/service/preferred-RTAV-device.service";
import { RtavChannel } from "../rtavChannel";
import { AudioParams } from "./audio-capture.model";
import { AudioEncoderWorkerFactory } from "./audio-encoder";
import { DeviceEnumeratorService } from "./device-enumerator.service";
import {
   AudioDevice,
   DeviceConfigCallback,
   DeviceConfigCallbackKV,
   DeviceInfo,
   PreferredDeviceType,
   PreferredDeviceValue,
   VideoDevice,
   VideoDeviceResolution,
   audioDeviceDefaultRole,
   defaultAudioDevice,
   defaultVideoDevice,
   devicePermissionChangeEvent,
   getPreferredDeviceKey,
   preferredDeviceChangeEvent,
   videoResolutionBuiltInList
} from "./device-manager.model";
import { AudioCallbackData, Callback, HeaderCallbackData, VideoCallbackData } from "./encoder.model";
import { VideoParams } from "./video-capture.model";
import { VideoEncoderWorkerFactory } from "./video-encoder";

@Injectable({ providedIn: "root" })
export class DeviceManagerService {
   private callbackSet = false;
   private isListening = false;
   private lastDeviceUpdatePromise: Promise<void> = Promise.resolve();
   private hasDeviceChangeListener = false;
   private deviceChangeDebounceTimeout = null;
   private permissionChangeSubscription: Subscription = null;
   private preferredChangeSubscription: Subscription = null;

   private readonly audioDeviceConfigCallbackMap: Map<RtavChannel, DeviceConfigCallback<AudioDevice>> = new Map();
   private audioHeaderCallback: Callback<HeaderCallbackData>;
   private audioDataCallback: Callback<AudioCallbackData>;
   private readonly videoDeviceConfigCallbackMap: Map<RtavChannel, DeviceConfigCallback<VideoDevice>> = new Map();
   private videoHeaderCallback: Callback<HeaderCallbackData>;
   private videoDataCallback: Callback<VideoCallbackData>;
   private emitDeviceStatusChanged: () => void;

   // clientResolution is guaranteed to be equal or lower than agentMaxResolution
   private clientResolution: VideoDeviceResolution = null;
   private agentMaxResolution: VideoDeviceResolution = null;

   constructor(
      private readonly deviceEnumeratorService: DeviceEnumeratorService,
      private readonly audioEncoderWorkerFactory: AudioEncoderWorkerFactory,
      private readonly videoEncoderWorkerFactory: VideoEncoderWorkerFactory,
      private readonly preferredDeviceService: PreferredRTAVDeviceService,
      private readonly eventBusService: EventBusService
   ) {}

   // throwable
   public setCallbacks(
      audioDeviceConfigCallbackKV: DeviceConfigCallbackKV<AudioDevice>,
      audioHeaderCallback: Callback<HeaderCallbackData>,
      audioDataCallback: Callback<AudioCallbackData>,
      videoDeviceConfigCallbackKV: DeviceConfigCallbackKV<VideoDevice>,
      videoHeaderCallback: Callback<HeaderCallbackData>,
      videoDataCallback: Callback<VideoCallbackData>,
      emitDeviceStatusChanged: () => void
   ) {
      if (
         !audioDeviceConfigCallbackKV ||
         !audioHeaderCallback ||
         !audioDataCallback ||
         !videoDeviceConfigCallbackKV ||
         !videoHeaderCallback ||
         !videoDataCallback ||
         !emitDeviceStatusChanged
      ) {
         throw new Error("DeviceManager failed to set callbacks, some of them are invalid");
      }
      this.audioDeviceConfigCallbackMap.set(audioDeviceConfigCallbackKV.channel, audioDeviceConfigCallbackKV.callback);
      this.audioHeaderCallback = audioHeaderCallback;
      this.audioDataCallback = audioDataCallback;
      this.videoDeviceConfigCallbackMap.set(videoDeviceConfigCallbackKV.channel, videoDeviceConfigCallbackKV.callback);
      this.videoHeaderCallback = videoHeaderCallback;
      this.videoDataCallback = videoDataCallback;
      this.emitDeviceStatusChanged = emitDeviceStatusChanged;
      this.callbackSet = true;
   }

   public async setClientResolution(resolution: VideoDeviceResolution) {
      if (!resolution) {
         return;
      }
      this.clientResolution = resolution;
      await this.sendVideoDeviceList();
   }

   public async setAgentMaxResolution(resolution: VideoDeviceResolution) {
      if (!resolution) {
         return;
      }
      this.agentMaxResolution = resolution;
      await this.sendVideoDeviceList();
   }

   // throwable
   // it's okay to call startListening before asking for permission, it will simply return a list with empty device names
   public async startListening() {
      if (!this.callbackSet) {
         throw new Error("DeviceManager must have callbacks before startListening");
      }
      this.isListening = true;
      // listen to device change
      if (!this.hasDeviceChangeListener) {
         if (navigator.mediaDevices) {
            navigator.mediaDevices.addEventListener("devicechange", (event) => {
               if (this.deviceChangeDebounceTimeout !== null) {
                  clearTimeout(this.deviceChangeDebounceTimeout);
               }
               this.deviceChangeDebounceTimeout = setTimeout(async () => {
                  Logger.info("DeviceManager update device list because device change", Logger.RTAV);
                  await this.updateAndSendDeviceList();
               }, 1000);
            });
            this.hasDeviceChangeListener = true;
         } else {
            Logger.error(`DeviceManager mediaDevices not supported`, Logger.RTAV);
         }
      }

      // listen to permission change
      if (!this.permissionChangeSubscription) {
         this.permissionChangeSubscription = this.eventBusService.listen(devicePermissionChangeEvent).subscribe(() => {
            Logger.info("DeviceManager update device list because device permission change", Logger.RTAV);
            this.updateAndSendDeviceList();
         });
      }
      // listen to preferred device change
      if (!this.preferredChangeSubscription) {
         this.preferredChangeSubscription = this.eventBusService.listen(preferredDeviceChangeEvent).subscribe(() => {
            Logger.info("DeviceManager update device list because preferred device change", Logger.RTAV);
            this.updateAndSendDeviceList();
         });
      }
      Logger.info("DeviceManager update device list because startListening", Logger.RTAV);
      await this.updateAndSendDeviceList();
   }

   // startAudio corresponds to PMsgStart_A message
   // throwable
   public async startAudio(deviceIndex: number, param: AudioParams) {
      this.checkIsListening();
      const audioCapture = this.deviceEnumeratorService.getAudioDevice(deviceIndex);
      const encoder = this.audioEncoderWorkerFactory.newAudioEncoderWorker(
         deviceIndex,
         this.audioHeaderCallback,
         this.audioDataCallback,
         param
      );
      await audioCapture.start(encoder, this.emitDeviceStatusChanged, param);
   }

   // streamAudio corresponds to PMsgStartStream_A
   // throwable
   public async startStreamAudio(deviceIndex: number) {
      this.checkIsListening();
      const audioCapture = this.deviceEnumeratorService.getAudioDevice(deviceIndex);
      await audioCapture.startStream();
   }

   // throwable
   public async stopStreamAudio(deviceIndex: number) {
      this.checkIsListening();
      const audioCapture = this.deviceEnumeratorService.getAudioDevice(deviceIndex);
      audioCapture.stopStream();
   }

   // throwable
   public async stopAudio(deviceIndex: number) {
      this.checkIsListening();
      const audioCapture = this.deviceEnumeratorService.getAudioDevice(deviceIndex);
      audioCapture.stop();
   }

   // throwable
   public stopAllAudio(except: string[] = []) {
      try {
         this.checkIsListening();
         this.deviceEnumeratorService.getAllAudioDevices(except)?.forEach((device) => {
            device?.stop();
         });
      } catch (e) {
         Logger.error(e);
      }
   }

   // throwable
   public isAudioActive() {
      this.checkIsListening();
      return this.deviceEnumeratorService.isAudioActive();
   }

   // throwable
   public async startVideo(deviceIndex: number, param: VideoParams) {
      this.checkIsListening();
      const videoCapture = this.deviceEnumeratorService.getVideoDevice(deviceIndex);
      const encoder = this.videoEncoderWorkerFactory.newVideoEncoderWorker(
         deviceIndex,
         this.videoHeaderCallback,
         this.videoDataCallback,
         param
      );
      await videoCapture.start(encoder, this.emitDeviceStatusChanged, param);
   }

   // throwable
   public startStreamVideo(deviceIndex: number) {
      this.checkIsListening();
      const videoCapture = this.deviceEnumeratorService.getVideoDevice(deviceIndex);
      videoCapture.startStream();
   }

   // throwable
   public async stopStreamVideo(deviceIndex: number) {
      this.checkIsListening();
      const videoCapture = this.deviceEnumeratorService.getVideoDevice(deviceIndex);
      videoCapture.stopStream();
   }

   // throwable
   public async stopVideo(deviceIndex: number) {
      this.checkIsListening();
      const videoCapture = this.deviceEnumeratorService.getVideoDevice(deviceIndex);
      videoCapture.stop();
   }

   public stopAllVideo(except: string[] = []) {
      try {
         this.checkIsListening();
         this.deviceEnumeratorService.getAllVideoDevices(except)?.forEach((device) => {
            device?.stop();
         });
      } catch (e) {
         Logger.error(e);
      }
   }

   // throwable
   public isVideoActive() {
      this.checkIsListening();
      return this.deviceEnumeratorService.isVideoActive();
   }

   // this makes sure that only one updateDeviceList runs at a time
   private async addDeviceChangeUpdateToQueue() {
      // create a queuePromise to wait for the updateDeviceList to finish
      let resolveQueuePromise = (value = undefined) => {};
      const queuePromise = new Promise((resolve) => {
         resolveQueuePromise = resolve;
      });
      this.lastDeviceUpdatePromise = this.lastDeviceUpdatePromise.finally(async () => {
         try {
            await this.deviceEnumeratorService.updateDeviceList();
         } catch (e) {
            Logger.error(e, Logger.RTAV);
         } finally {
            resolveQueuePromise();
         }
      });
      return queuePromise;
   }

   // get currently available devices and send the list using callback
   private async updateAndSendDeviceList() {
      await this.addDeviceChangeUpdateToQueue();
      if (this.callbackSet) {
         await this.sendAudioDeviceList();
         await this.sendVideoDeviceList();
      }
   }

   private async sendAudioDeviceList() {
      const audioDevices = this.deviceEnumeratorService.getAudioInfoList();
      let sendList: DeviceInfo<AudioDevice>[];
      if (!audioDevices || audioDevices.length <= 0) {
         sendList = [defaultAudioDevice];
      } else {
         let preferredLabel = await this.preferredDeviceService.readKey(
            getPreferredDeviceKey(PreferredDeviceType.audio)
         );
         // might be null or undefined
         if (!preferredLabel) {
            preferredLabel = PreferredDeviceValue.all;
         }
         // filter the preferred devices
         sendList = audioDevices.filter((device) => {
            if (preferredLabel === PreferredDeviceValue.all) {
               return true;
            }
            // use role to detect default device
            if (preferredLabel === PreferredDeviceValue.default && (device.roles & audioDeviceDefaultRole.role) > 0) {
               return true;
            }
            // use includes for better flexibility
            if (device.deviceId === preferredLabel || device.friendlyName.includes(preferredLabel as string)) {
               return true;
            }
            return false;
         });
         // if no filtered devices, fallback to all
         if (sendList.length <= 0) {
            sendList = audioDevices;
         }
      }
      this.sendDeviceList(this.audioDeviceConfigCallbackMap, sendList);
   }

   private async sendVideoDeviceList() {
      if (!this.clientResolution || !this.agentMaxResolution) {
         Logger.warning(
            `DeviceManager can't send video device list: ${
               !this.clientResolution ? "client resolution not set" : "agent max resolution not set"
            }`,
            Logger.RTAV
         );
         return;
      }
      const videoDevices = this.deviceEnumeratorService.getVideoInfoList();
      let sendList: DeviceInfo<VideoDevice>[];
      if (!videoDevices || videoDevices.length <= 0) {
         sendList = [defaultVideoDevice];
      } else {
         let preferredLabel = await this.preferredDeviceService.readKey(
            getPreferredDeviceKey(PreferredDeviceType.video)
         );
         // might be null or undefined
         if (!preferredLabel) {
            preferredLabel = PreferredDeviceValue.all;
         }
         // filter the preferred devices
         sendList = videoDevices.filter((device) => {
            // video shouldn't have default
            if (preferredLabel === PreferredDeviceValue.all || preferredLabel === PreferredDeviceValue.default) {
               return true;
            }
            if (device.deviceId === preferredLabel || device.friendlyName.includes(preferredLabel as string)) {
               return true;
            }
            return false;
         });
         // if no filtered devices, fallback to all
         if (sendList.length <= 0) {
            sendList = videoDevices;
         }
         // to make the default resolution take effect, put it in front of the resolution array
         const resolutionSorted = videoResolutionBuiltInList
            .filter((res) => {
               return res.width !== this.clientResolution.width || res.height !== this.clientResolution.height;
            })
            // remove resolutions that are too high for the agent
            .filter((res) => {
               if (this.agentMaxResolution.width === 0 || this.agentMaxResolution.height === 0) return true;
               return res.width <= this.agentMaxResolution.width && res.height <= this.agentMaxResolution.height;
            });
         resolutionSorted.unshift(this.clientResolution);
         // use the resolution from admin policy
         sendList.forEach((device) => {
            device.caps = {
               resolutionCount: resolutionSorted.length,
               resolution: resolutionSorted
            };
         });
      }
      this.sendDeviceList(this.videoDeviceConfigCallbackMap, sendList);
   }

   // send the audio/video device list to the agent and clear the closed channels
   private sendDeviceList<T extends AudioDevice | VideoDevice>(
      configCallbackMap: Map<RtavChannel, DeviceConfigCallback<T>>,
      sendList: DeviceInfo<T>[]
   ) {
      // stop unsent devices
      const exceptIdList = sendList.map((device) => device.deviceId);
      this.stopAllAudio(exceptIdList);
      this.stopAllVideo(exceptIdList);
      Array.from(configCallbackMap.entries()).forEach(([channel, callback]) => {
         // @ts-ignore
         if (channel && !channel.rtavChannel) {
            // clear closed channel
            configCallbackMap.delete(channel);
         } else {
            // convert from DeviceInfo<T> to T
            const listWithoutId = sendList.map(({ deviceId, ...rest }) => ({ ...rest })) as unknown as T[];
            callback(listWithoutId);
         }
      });
   }

   // throwable
   // ensure that some methods only run after startListening
   private checkIsListening() {
      if (!this.callbackSet || !this.isListening) {
         throw new Error("DeviceManager must startListening first");
      }
   }
}
