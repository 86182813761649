/**
 * ******************************************************
 * Copyright (C) 2016 - 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * idle-session.service.ts --
 *
 * This file performs the manage work to init a timerContrller in JSCDK, and
 * is also the middle layer between blast page and JSCDK, thus it will provide
 * an API to let JSCDK send last-user-active time request through post-massage
 * and also handle the response and told back to JSCDK.
 *
 * NOTE: the file is almost copied from slim client
 *
 * For HTML Access, JSCDK contains all the logic for timer and sync between tabs,
 *    so only user active time need to be provided by UI layer, and timeout event
 *    get responsed in UI.
 * For Chrome Client, there is no easy way to obtain the active time itself, so
 *    we choose to switch to chromeidleTimeoutService which uses the chrome.idle API
 *    where the chrome.idle and JSCDK are doing the same.
 */

import $ from "jquery";
import Logger from "../../../core/libs/logger";
import util from "../../jscdk/util";
import { signal } from "../../common/service/signal";
import * as CST from "../../../core/libs";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { IdleTimeoutUtil } from "../../../chrome-client/base/service/idle-timeout-util.service";
import { Injectable } from "@angular/core";
import { EventBusService } from "../../../core/services/event/event-bus.service";

type jscdkWrapperData = any;
type voidCall = () => void;

@Injectable({
   providedIn: "root"
})
export class IdleSessionService extends signal {
   // Track user activity.
   private _lastUserActivityTime: number;
   private reconnectAppWhenUnlocked: boolean;
   private doLockCallback: voidCall;
   private refreshCallback: voidCall;
   private doUnlockCallback: voidCall;
   private _isSuppressed: boolean = false;

   // broker idle timeout setting in milliseconds

   public idleTimeout;
   public userActivityInterval;
   public hasAppLaunched: boolean;
   public userAllowReconnect: boolean;
   public sessionTimedOut: boolean;
   constructor(
      private localStorageService: LocalStorageService,
      private idleTimeoutUtil: IdleTimeoutUtil,
      private eventBusService: EventBusService
   ) {
      super();
      this.addSignal("idleSessionWarning");
      // broker session event
      this.addSignal("idleSessionTimeout");
      // blast event
      this.addSignal("appIdleTimeout");
      this.addSignal("idleSessionUnlockDone");

      this._lastUserActivityTime = $.now();
      this.idleTimeout = null;
      this.userActivityInterval = null;
      this.hasAppLaunched = false;
      this.userAllowReconnect = false;
      this.sessionTimedOut = false;
   }

   private _bindUserActivities = () => {
      // Listen to all the events for tracking user activity.
      $(document).bind(
         "mousemove.useractivity mousedown.useractivity mouseup.useractivity",
         this.setLastUserActivityTime
      );
      $(document).bind("keydown.useractivity keyup.useractivity", this.setLastUserActivityTime);
      $(document).bind(
         "touchstart.useractivity touchmove.useractivity touchend.useractivity",
         this.setLastUserActivityTime
      );
      $(document).bind("orientationchange.useractivity", this.setLastUserActivityTime);
      $(document).bind("extendedmonitoruse.useractivity", this.setLastUserActivityTime);
      $(document).bind("primarymonitoruse.useractivity", this.setLastUserActivityTime);
   };

   private _unbindUserActivities = () => {
      $(document).unbind(
         "mousemove.useractivity mousedown.useractivity mouseup.useractivity",
         this.setLastUserActivityTime
      );
      $(document).unbind("keydown.useractivity keyup.useractivity", this.setLastUserActivityTime);
      $(document).unbind(
         "touchstart.useractivity touchmove.useractivity touchend.useractivity",
         this.setLastUserActivityTime
      );
      $(document).unbind("orientationchange.useractivity", this.setLastUserActivityTime);
      $(document).unbind("extendedmonitoruse.useractivity", this.setLastUserActivityTime);
      $(document).unbind("primarymonitoruse.useractivity", this.setLastUserActivityTime);
   };

   private _initParams = () => {
      // Current idleTimeout value
      if (!this.idleTimeout) {
         this.idleTimeout = Number(this.localStorageService.get(CST.COOKIE.IDLE_TIMEOUT));
         if (!this.idleTimeout) {
            Logger.debug("idleTimeout don't exist in the cookie");
         }
      } else {
         Logger.debug("idleTimeout already exist");
      }
      // Current user-activity-interval value
      if (!this.userActivityInterval) {
         // Read user-activity-interval value from cookie
         this.userActivityInterval = Number(this.localStorageService.get(CST.COOKIE.SEND_TIME_INTERVAL));
         if (!this.userActivityInterval) {
            Logger.debug("userActivityInterval don't exist in the cookie");
         }
      } else {
         Logger.debug("userActivityInterval already exist");
      }
   };

   // public:
   public updateParams = (timerInfo: jscdkWrapperData) => {
      if (!timerInfo) {
         return;
      }

      if (timerInfo.idleTimeout) {
         this.idleTimeout = timerInfo.idleTimeout;
      }
      if (timerInfo.userActivityXMLSendingInterval) {
         this.userActivityInterval = timerInfo.userActivityXMLSendingInterval;
      }

      // Current idleTimeout value
      if (!this.idleTimeout) {
         Logger.error("idleTimeout don't exist yet");
      } else {
         this.localStorageService.set(CST.COOKIE.IDLE_TIMEOUT, this.idleTimeout);
      }
      // Current user-activity-interval value
      if (!this.userActivityInterval) {
         Logger.error("idleTimeout don't exist in connectionServerModel");
      } else {
         this.localStorageService.set(CST.COOKIE.SEND_TIME_INTERVAL, this.userActivityInterval);
      }

      if (this.idleTimeoutUtil) {
         this.idleTimeoutUtil.startTimers(this.idleTimeout);
      }
      this.onSessionRenew();
   };

   public init = (refreshCallback: voidCall, doLockCallback: voidCall, doUnlockCallback: voidCall) => {
      if (!util.brokerSupportApplication()) {
         Logger.info("Broker doesn't support idle session timeout");
         return;
      }
      this.doLockCallback = doLockCallback;
      this.refreshCallback = refreshCallback;
      this.doUnlockCallback = doUnlockCallback;

      this._initParams();
      this._bindUserActivities();
      if (CST.clientUtil.isChromeClient()) {
         this.idleTimeoutUtil.init(this.onIdleWarning, this.onIdleLocking, this.isSessionTimedOut);
      }
   };

   public onIdleLocking = () => {
      if (!this.doLockCallback) {
         return;
      }
      this.eventBusService.dispatch({
         type: "idleTimeout",
         data: {
            hasAppDisconnection: this.hasAppLaunched
         }
      });
      Logger.info("Session is locking");
      this.emit("idleSessionTimeout", this.doLockCallback);
      this.sessionTimedOut = true;
   };
   public onIdleLocked = () => {
      if (!this.doUnlockCallback) {
         return;
      }
      Logger.info("Session Locked since idle for too long");
      this.sessionTimedOut = true;
      this.emit("idleSessionTimeout", this.doUnlockCallback);
   };

   public onIdleWarning = () => {
      if (!this.refreshCallback) {
         return;
      }
      Logger.info("Session is about to be locked, since idle for too long");
      this.emit("idleSessionWarning", this.refreshCallback);
   };
   public onSessionUnlockDone = () => {
      this.emit("idleSessionUnlockDone");
      this.sessionTimedOut = false;
      this.reconnectAppWhenUnlocked = this.hasAppLaunched && this.userAllowReconnect;
      this.hasAppLaunched = false;
      this.userAllowReconnect = false;
   };

   public onSessionRenew = () => {
      this.tearDown(true);
   };

   public onSessionEnded = () => {
      this.tearDown();
   };

   public setLockedStatus = (isSessionLocked: boolean) => {
      this.sessionTimedOut = isSessionLocked;
   };
   /**
    * setLastUserActivityTime
    *
    * Record the activity time when user is using the web client.
    */
   public setLastUserActivityTime = () => {
      this._lastUserActivityTime = $.now();
   };

   // triggered by baseViewService.isBackFromDesktopToServerSelect
   public tearDown = (isRenew?: boolean) => {
      this.sessionTimedOut = false;
      if (!isRenew) {
         this._unbindUserActivities();
         if (this.idleTimeoutUtil) {
            this.idleTimeoutUtil.stopTimers();
         }
         this.hasAppLaunched = false;
         this.userAllowReconnect = false;
         this.reconnectAppWhenUnlocked = false;
      }
   };

   public onAppIdleTimeout = (sessionKey: string) => {
      Logger.info("app session disconnected due to idle timeout: " + sessionKey);
      if (!this.doUnlockCallback) {
         return;
      }
      this.emit("appIdleTimeout", this.doUnlockCallback);
   };

   /**
    * Since for now this will be called with true when launching app.
    * the needToReconnectApp might return true after resetting apps from broker admin.
    * but then, there is no app to reconnect since clinet will check against the
    * app session list, so it's ok.
    *
    * And the needToReconnectApp will only return true if user click reconnect button
    * which also make reconnection mis-trigger very unlikely to cause issue, so keep
    * the code here for now.
    */
   public setHasAppLaunched = (value: boolean) => {
      this.hasAppLaunched = value;
   };

   public onUserReconnectApps = () => {
      this.userAllowReconnect = true;
   };

   public getLastUserActivityTime = () => {
      if (CST.clientUtil.isChromeClient()) {
         // don't update time to JSCDK, use chrome OS API to implement directly
         this.setLastUserActivityTime();
      }
      return this._lastUserActivityTime;
   };

   public isSessionTimedOut = () => {
      return this.sessionTimedOut;
   };

   /**
    * @return {boolean} This returns whether has app session window when idle timeout happens
    */
   public needToReconnectApp = () => {
      return this.reconnectAppWhenUnlocked;
   };

   public suppressMsg = (on: boolean) => {
      this._isSuppressed = on;
   };
}
