/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

import { EventExchangeBase } from "./event-exchange.base";
import { EventExchangeLongLived } from "./event-exchange.long-lived";
import { EventExchangeOneTime } from "./event-exchange.one-time";

export enum EventExchangeType {
   OneTime,
   LongLived
}
export function getEventExchange(
   allowedPeerAddresss: Array<string>,
   type: EventExchangeType,
   getTrustOriginList?: (string) => Promise<Array<string>>,
   isTrustSyncMessage?: (SDKRawEvent) => boolean
): EventExchangeBase {
   if (type === EventExchangeType.OneTime) {
      return new EventExchangeOneTime(
         allowedPeerAddresss,
         getTrustOriginList,
         isTrustSyncMessage
      );
   } else if (type === EventExchangeType.LongLived) {
      return new EventExchangeLongLived(
         allowedPeerAddresss,
         getTrustOriginList,
         isTrustSyncMessage
      );
   }

}

export {
   EventExchangeBase as EventExchange,
   SDKReceivedRawRequest
} from "./event-exchange.base";
