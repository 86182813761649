/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * file-util.js
 *
 * non-arch fs utils.
 */
import { fsConsts } from "./index";
import { LargeInteger } from "../../../shared/desktop/vdpservice/util/large-integer";

export class FSUtil {
   /**
    * Convert to the Windows timestamp.
    * (time + base) * multiply
    */
   public static timeToFileTime(time) {
      return new LargeInteger().set(time).add(fsConsts.timeBase).mul(fsConsts.timeMultiply);
   }

   /**
    * get folder path from the full with volume name removed.
    */
   public static getWinFolderPath(path) {
      const folderPath = "\\" + path.split("/").slice(2, -1).join("\\");
      return folderPath;
   }

   /**
    * @return {number} Return a number that is not related to serial ID,
    *    and is not required to be unique.
    */
   public static getSid() {
      return 0x00564d57;
   }

   public static getPathWithoutLastSeparator(path) {
      if (
         (!!path && path.length > 0 && path[path.length - 1] === fsConsts.localSeparator) ||
         path[path.length - 1] === fsConsts.remoteSeparator
      ) {
         return path.substring(0, path.length - 1);
      }
      return path;
   }

   public static getPathNames(path) {
      return path.split("\\").splice(1);
   }

   public static async processThrough(array, i, process, iterator) {
      const next = await process(iterator, array[i]);
      i++;
      if (array.length === i) {
         return next;
      }
      return await this.processThrough(array, i, process, next);
   }

   /**
    * Split the file path into folder path and file name
    *
    * @param  {[type]} filepath [description]
    * @return {[type]}          [description]
    */
   public static getFolderAndFileName(filepath) {
      const pathChain = filepath.split("\\");
      const fileName = pathChain[pathChain.length - 1];
      const folderPath = pathChain.splice(0, pathChain.length - 1).join("\\");
      return {
         fileName: fileName,
         folderPath: folderPath
      };
   }

   private static _stringifyByCondition(value, map, condition) {
      let flagString = "";
      for (const key in map) {
         if (condition(value, map[key])) {
            if (flagString !== "") {
               flagString += ",";
            }
            flagString += key;
         }
      }
      return flagString;
   }

   public static stringifyFlags(value, map) {
      return FSUtil._stringifyByCondition(value, map, (a, b) => {
         return !!(a & b);
      });
   }

   public static stringifyTypes(value, map) {
      return FSUtil._stringifyByCondition(value, map, (a, b) => {
         return a === b;
      });
   }
}
