/**
 * *****************************************************
 * Copyright 2023 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { IStorageService } from "../../core/services/storage/storage.interface";
export abstract class TitanTokenStorage extends IStorageService {}
