/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { clientUtil, LAUNCH_CLIENT_TYPE } from "@html-core";
import { ClientSettingModel } from "../model/client-setting-model";
import { ViewClientModel } from "../model/viewclient-model";

@Component({
   selector: "horizon-preference",
   templateUrl: "./horizon-preference.component.html",
   styleUrls: ["./horizon-preference.component.less"]
})
export class HorizonPreferenceComponent {
   public isChromeOS: boolean;
   public checkIconSrc: string;
   public titanClient: boolean;
   public preferredClient: string;

   constructor(
      private viewClientModel: ViewClientModel,
      private clientSettingModel: ClientSettingModel
   ) {
      this.isChromeOS = this.viewClientModel.osModel.mIsChromeOS;
      this.titanClient = clientUtil.isTitanClient();
      const type = this.clientSettingModel.getStringItem("titanLaunchType");
      if (type) {
         this.preferredClient = type as string;
      } else {
         this.preferredClient = LAUNCH_CLIENT_TYPE.UNDEFINED;
      }
   }

   public setPreferredViewClient = (client?: string): void => {
      if (client) {
         this.preferredClient = client as LAUNCH_CLIENT_TYPE;
      }
      this.clientSettingModel.updateSetting("titanLaunchType", this.preferredClient);
   };
}
