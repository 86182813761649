/**
 * ******************************************************
 * Copyright (C) 2015-2020, 2023-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * rtavSessionController.ts --
 *
 * Class contains the logic for each rtav action, and how to control classes to complete
 * a target action like "start streaming" or "enable audio".
 *
 * Class contains the main logic of rtav session, which will be used
 * by the RTAV session
 *
 */

import { Injectable } from "@angular/core";
import { ResourceManager } from "./resourceManager";
import { RtavDialogService } from "./rtavDialog.service";
import { CodecTypes } from "./rtav.constants";
import { EventBusService } from "../../../core/services/event";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { Logger } from "@html-core";

@Injectable()
export class RtavSessionController {
   private sessionId;
   private hardwareAccelerationOption;
   private src;
   private mediaEnc;
   private sendingFunc;
   private enableRTAVDTX;
   private metaCacheA;
   private metaCacheV;
   private dataChannel = {
      audioEnable: false,
      videoEnable: false,
      // this flag is for fixing bug 1647044, where the device is missing but we should treat the enable as success
      videoMissing: false
   };
   private hasResources;
   private deviceManager;
   /**
    * need to bind the sending function into a map with wmksId as key if we want to support seamless window,
    * that could be done using a new object named sessionManager, and I will not implement that for now.
    */
   private initDoneCallback;
   private audioDataCallback;
   private videoDataCallback;
   public isUsingDevices: boolean;

   constructor(
      private resourceManager: ResourceManager,
      private dialogService: RtavDialogService,
      private modalDialogService: ModalDialogService,
      private eventBusService: EventBusService
   ) {}

   public init = (sessionId, hardwareAccelerationOption) => {
      this.hasResources = false;
      this.sessionId = sessionId;
      this.hardwareAccelerationOption = hardwareAccelerationOption;
   };

   /**
    * reset for a new stream session
    * @param  {[type]} sendingFunction [description]
    * @return {[type]}                 [description]
    */
   private resetProperties = (sendingFunction) => {
      this.sendingFunc = sendingFunction;
      this.metaCacheA = null;
      this.metaCacheV = null;
   };

   private initEncoder = (deviceParam) => {
      let headerCallback = (libHeader, isFackInit) => {
         this.initDoneCallback(libHeader, deviceParam, isFackInit);
      };
      if (!this.mediaEnc.isInited()) {
         Logger.debug("init encoders", Logger.RTAV);
         this.mediaEnc.init(
            deviceParam.audio,
            deviceParam.video,
            deviceParam.codecPref,
            this.hardwareAccelerationOption,
            this.enableRTAVDTX,
            headerCallback,
            this.audioDataCallback,
            this.videoDataCallback
         );
      } else {
         Logger.debug("encoders has already been inited, so fetch the header again", Logger.RTAV);
         this.mediaEnc.fetchCachedHeader();
      }
   };

   private setResources = (resources, onDone) => {
      if (!resources) {
         Logger.debug("find no available audio-in or video-in devices, so RTAV function is not usable", Logger.RTAV);
         onDone(false);
      } else {
         this.src = resources.src;
         this.mediaEnc = resources.mediaEnc;
         if (this.deviceManager) {
            this.mediaEnc.setDeviceManager(this.deviceManager);
         }
         Logger.debug("session " + this.sessionId + " successfully get RTAV devices control rights", Logger.RTAV);
         onDone(true);
      }
   };

   private initResources = (onDone) => {
      let stolenSessionId, confirmDeviceSteal, cancelDeviceSteal;

      if (!this.resourceManager.hasOccupiedResources(this.sessionId)) {
         if (this.resourceManager.hasUsableResources()) {
            this.resourceManager.occupyResources(this.sessionId, (resources) => {
               this.setResources(resources, onDone);
            });
         } else {
            if (this.dialogService.showDialog) {
               confirmDeviceSteal = () => {
                  this.resourceManager.stealSession(this.sessionId, (resources) => {
                     Logger.debug(
                        "session " + this.sessionId + " trying to steal the devices from session " + stolenSessionId,
                        Logger.RTAV
                     );
                     this.setResources(resources, onDone);
                  });
               };

               cancelDeviceSteal = () => {
                  Logger.debug(
                     "session " + this.sessionId + " failed to occupy devices, so RTAV function is not usable",
                     Logger.RTAV
                  );
                  onDone(false);
               };

               stolenSessionId = this.resourceManager.getWorkingSessionId();
               // skip confirmation if devices are not used
               let showConflitDeviceDialog = false;
               this.eventBusService.listen("rtavShowConflictDialog").subscribe((msg) => {
                  showConflitDeviceDialog = msg.data;
               });
               this.eventBusService.dispatch({
                  type: "rtavIsUsingDevices",
                  data: stolenSessionId
               });
               if (showConflitDeviceDialog === false) {
                  confirmDeviceSteal();
                  return;
               } else if (this.modalDialogService.hasDialogOpen()) {
                  this.dialogService.showDialog(
                     {
                        stolenSessionId: stolenSessionId
                     },
                     confirmDeviceSteal,
                     cancelDeviceSteal
                  );
               }
            } else {
               Logger.debug(
                  "session " + this.sessionId + " failed to occupy devices, so RTAV function is not usable",
                  Logger.RTAV
               );
               onDone(false);
            }
         }
      } else {
         onDone(true);
      }
   };

   /**
    * release all local resources
    */
   private releaseResources = () => {
      // clear local refer
      this.src = null;
      this.mediaEnc = null;
      // release
      if (this.resourceManager.hasOccupiedResources(this.sessionId)) {
         this.resourceManager.releaseResources(this.sessionId);
      }
      // reset flag
      this.hasResources = false;
      return true;
   };

   /**
    * close the hold resources, then the resources are not usable anymore
    */
   private closeResources = () => {
      // release
      if (this.resourceManager.hasOccupiedResources(this.sessionId) && !!this.src) {
         this.src.close();
      }
   };

   public initialize = () => {
      // inited header callback
      this.initDoneCallback = (libHeader, deviceParam, isFackInit) => {
         Logger.trace("header generated: ", Logger.RTAV);
         if (deviceParam.codecPref !== CodecTypes["CodecVmwH264Opus"]) {
            if (!libHeader || !libHeader.buffer) {
               Logger.error("fail to get header, RTAV will stop working", Logger.RTAV);
               return;
            }
            Logger.trace(new Uint8Array(libHeader.buffer).toString(), Logger.RTAV);
            this.sendingFunc({
               streamData: libHeader,
               dataCount: 1,
               timeStamp: 0
            });
         }
         if (!isFackInit && this.src) {
            Logger.debug("start streaming with encoder inited", Logger.RTAV);
            this.src.startStream(
               deviceParam,
               (data) => {
                  this.mediaEnc.encodeAudio(data);
               },
               (data) => {
                  this.mediaEnc.encodeVideo(data);
               }
            );
         }
      };
      // audio encoded callback, no others needed to be default so far
      this.audioDataCallback = (result, timeStamp) => {
         let metaCount = 1;
         if (!this.dataChannel.audioEnable) {
            return;
         }
         if (result) {
            if (this.metaCacheA !== null) {
               this.sendingFunc({
                  streamData: result,
                  dataCount: this.metaCacheA + metaCount,
                  timeStamp: timeStamp
               });
               this.metaCacheA = null;
            } else {
               this.sendingFunc({
                  streamData: result,
                  dataCount: metaCount,
                  timeStamp: timeStamp
               });
            }
         } else {
            if (this.metaCacheA !== null) {
               this.metaCacheA += metaCount;
            } else {
               this.metaCacheA = metaCount;
            }
         }
      };
      // video encoded callback
      this.videoDataCallback = (result, timeStamp, others) => {
         let metaCount = 1,
            onDoneCallback,
            callbackParam;

         if (!this.dataChannel.videoEnable) {
            return;
         }
         if (result) {
            if (this.metaCacheV !== null) {
               this.sendingFunc({
                  streamData: result,
                  dataCount: this.metaCacheV + metaCount,
                  timeStamp: timeStamp
               });
               this.metaCacheV = null;
            } else {
               this.sendingFunc({
                  streamData: result,
                  dataCount: metaCount,
                  timeStamp: timeStamp
               });
            }
         } else {
            if (this.metaCacheV !== null) {
               this.metaCacheV += metaCount;
            } else {
               this.metaCacheV = metaCount;
            }
         }
         if (others) {
            onDoneCallback = others.callback;
            callbackParam = others.callbackParam;
            if (typeof onDoneCallback === "function") {
               onDoneCallback(callbackParam);
            }
         }
      };
   };

   /**
    * The API for start a rtav streaming
    * @param  {object} encoderParam The params that will be used to config the source and encoder which generating the stream data.
    * @param  {function} sendingFunction The callback that will be used to send the stream data.
    * @return {boolean} This returns whether we can start a new rtav stream task.
    */
   public startMediaIn = (encoderParam, sendingFunction) => {
      this.resetProperties(sendingFunction);
      if (!this.hasResources) {
         Logger.debug("no resource to start", Logger.RTAV);
         return false;
      }
      Logger.debug("start mediaIn", Logger.RTAV);
      this.initEncoder(encoderParam);
      return true;
   };

   /**
    * The API for stop a rtav streaming
    */
   public stopMediaIn = () => {
      if (!this.hasResources) {
         return;
      }
      Logger.debug("stop mediaIn", Logger.RTAV);
      if (this.dataChannel.audioEnable) {
         this.disableAudioIn(() => {
            Logger.debug("audio stopped", Logger.RTAV);
         });
      }
      if (this.dataChannel.videoEnable) {
         this.disableVideoIn(() => {
            Logger.debug("video stopped", Logger.RTAV);
         });
      }
   };

   public setAudioDTXMode = (isAudioDTXEnabled) => {
      this.enableRTAVDTX = isAudioDTXEnabled;
   };

   /**
    * The API for enable audio in
    * we need to wait the sources inited which is a async process, then see whether this task can be processed successfullly
    * @param {function} callback will return whether enable success
    * @param {object} deviceMessageController This param is used to emit premission dialog related events
    */
   public enableAudioIn = (callback, deviceMessageController) => {
      let whenHasResources = (canProcess) => {
         if (!canProcess) {
            Logger.debug("enable audio can't be done since current session has no resources", Logger.RTAV);
            callback(false);
            return;
         }
         if (!this.src) {
            Logger.error("no src, inner error found", Logger.RTAV);
            callback(false);
            return;
         }
         Logger.debug("enable audio for current session", Logger.RTAV);
         this.src.enable("audio", deviceMessageController).then((enableSuccess) => {
            Logger.debug("audio enable result: " + enableSuccess, Logger.RTAV);
            this.dataChannel.audioEnable = enableSuccess;
            if (enableSuccess) {
               this.mediaEnc.enable("audio");
            }
            callback(enableSuccess);
            this.resourceManager.emitDeviceStatusChanged();
         });
      };
      this.processWhenHaveResources(whenHasResources);
   };

   /**
    * The API for disable audio in
    * we need to wait the sources inited which is a async process, then see whether this task can be processed successfullly
    * @return {boolean} This returns whether the disable action success, but treat to disable a released encoder as success
    */
   public disableAudioIn = (callback) => {
      Logger.debug("disabling audio for current session", Logger.RTAV);
      if (!this.hasResources) {
         Logger.debug("disable success for the encoder and src has already been released", Logger.RTAV);
         callback(true);
      }
      if (!this.src) {
         Logger.error("no src, inner error found", Logger.RTAV);
         callback(true);
      }
      if (!this.dataChannel.audioEnable) {
         Logger.debug("audio has already being disabled, but still disable again", Logger.RTAV);
      }
      this.dataChannel.audioEnable = false;

      Logger.debug("disabling audio", Logger.RTAV);
      Promise.all([this.src.disable("audio"), this.mediaEnc.disable("audio")]).then((results) => {
         let success = results[0] && results[1];
         if (!success) {
            Logger.error(
               "disable audio failed, device disable: " + results[0] + "encoder disable: " + results[1],
               Logger.RTAV
            );
         } else {
            Logger.debug("disable audio device and encoder done", Logger.RTAV);
         }
         callback(success);
         this.resourceManager.emitDeviceStatusChanged();
      });
   };

   /**
    * The API for enable video in
    * we need to wait the sources inited which is a async process, then see whether this task can be processed successfullly
    * @param {function} callback will return whether enable success
    * @param {object} deviceMessageController This param is used to emit premission dialog related events
    */
   public enableVideoIn = (callback, deviceMessageController) => {
      let whenHasResources = (canProcess) => {
         if (!canProcess) {
            Logger.debug("enable video can't be done since current session has no resources", Logger.RTAV);
            callback(false);
            return;
         }
         if (!this.src) {
            Logger.error("no src, inner error found", Logger.RTAV);
            callback(false);
            return;
         }
         Logger.debug("enable video for current session", Logger.RTAV);
         this.src.enable("video", deviceMessageController).then((success) => {
            // check bug 1647044 for detail
            let enableSuccess = true;
            let missingDevice = !success;
            Logger.debug(
               "video enable result: " + enableSuccess + " , with device missing status: " + missingDevice,
               Logger.RTAV
            );
            this.dataChannel.videoEnable = enableSuccess;
            this.dataChannel.videoMissing = !!missingDevice;
            if (enableSuccess) {
               this.mediaEnc.enable("video");
            }
            callback(enableSuccess);
            this.resourceManager.emitDeviceStatusChanged();
         });
      };
      this.processWhenHaveResources(whenHasResources);
   };

   /**
    * The API for disable video in
    * we need to wait the sources inited which is a async process, then see whether this task can be processed successfullly
    * @return {boolean} This returns whether the disable action success, but treat to disable a released encoder as success
    */
   public disableVideoIn = (callback) => {
      if (!this.hasResources) {
         Logger.debug("disable success for the encoder and src has already been released", Logger.RTAV);
         callback(true);
      }
      if (!this.dataChannel.videoEnable) {
         Logger.debug("video has already being disabled, so no need to disable again", Logger.RTAV);
         callback(true);
      }
      if (!this.src) {
         Logger.error("no src, inner error found", Logger.RTAV);
         callback(true);
      }

      Logger.debug("disabling video for current session", Logger.RTAV);
      Promise.all([this.src.disable("video"), this.mediaEnc.disable("video")]).then((results) => {
         let success = results[0] && results[1];
         if (!success) {
            Logger.error(
               "disable video failed, device disable: " + results[0] + "encoder disable: " + results[1],
               Logger.RTAV
            );
         } else {
            Logger.debug("disable video device and encoder done", Logger.RTAV);
         }
         callback(success);
         this.resourceManager.emitDeviceStatusChanged();
      });
      this.dataChannel.videoEnable = false;
      this.dataChannel.videoMissing = false;
   };

   /**
    * The API for user level of stop of RTAV
    * should be called when the rtav is turned off by user for a specified session
    * @return {boolean} This returns whether the userStop action success
    */
   public userStop = () => {
      Logger.debug("user stop WebCam/Mic redirection", Logger.RTAV);
      this.stopMediaIn();
      this.releaseResources();
   };

   /**
    * The API for session level of stop of RTAV
    * should be called only when the hosted session is disconnected
    * @return {boolean} This returns whether the stop action success
    */
   public stop = () => {
      Logger.debug("stop WebCam/Mic redirection", Logger.RTAV);
      this.stopMediaIn();
      this.closeResources();
      this.releaseResources();
   };

   /**
    * @return {Boolean} This returns whether the controller is send stream in any kind
    */
   public isSendingStream = (type) => {
      if (!this.hasResources) {
         return false;
      }
      if (!this.src) {
         return false;
      }
      if (type === "video") {
         return (
            (this.dataChannel.videoEnable && !this.dataChannel.videoMissing && this.src.isActive(type)) ||
            this.deviceManager.isPermissionPending(type)
         );
      } else if (type === "audio") {
         return (
            (this.dataChannel.audioEnable && this.src.isActive(type)) || this.deviceManager.isPermissionPending(type)
         );
      }
      return (
         (this.dataChannel.videoEnable && !this.dataChannel.videoMissing && this.src.isActive("video")) ||
         (this.dataChannel.audioEnable && this.src.isActive("audio")) ||
         this.deviceManager.isPermissionPending()
      );
   };

   /**
    * Try to get the resource, and when do, call processFunc, and call callback(true)
    * if not, call callback(false)
    * @param {function} processFunc The function that will be called when the resources are ready
    */
   public processWhenHaveResources = (processFunc) => {
      // the true false indicates whether the processFunc can process
      if (!this.hasResources) {
         this.initResources((success) => {
            if (success) {
               Logger.trace("get resources for operation", Logger.RTAV);
               this.hasResources = success;
               processFunc(true);
            } else {
               Logger.trace("don't get resources for operation", Logger.RTAV);
               processFunc(false);
            }
         });
      } else {
         processFunc(true);
      }
   };

   /**
    * set messageManager into encoder so that we can check the agent-client
    * mis-match status to avoid bug 1750132.
    * Never clear massageManager since it's const and persistent
    * @param {object} manager A DeviceMessageManager instance
    */
   public setDeviceManager = (deviceManager) => {
      this.deviceManager = deviceManager;
      if (this.mediaEnc) {
         this.mediaEnc.setDeviceManager(deviceManager);
      }
   };

   /**
    * keep the encoder release to avoid accumulated error
    */
   public onPermissionHandled = () => {
      if (!this.mediaEnc || !this.mediaEnc.isInited()) {
         return;
      }
      if (this.mediaEnc.notInUse()) {
         Logger.warning("no encoder is enabled after device permission handled", Logger.RTAV);
         this.mediaEnc.clear();
      }
   };
}
