<div class="modal-title dialog-title">{{title}}</div>
<div class="modal-body dialog-content">
   <div class="session-ops-window-text">{{message}}</div>
</div>

<div class = "dialog-check-box">
   <input type="checkbox" id="checkboxForDialog" [checked]="isCheckBoxChecked" (change)="isCheckBoxChecked = !isCheckBoxChecked">
   <label for="checkboxForDialog" class = "dialog-check-box-label">
      {{checkboxTitle || ('DO_NOT_SHOW_THIS_DIALOG_AGAIN' | i18nv2) }}
   </label>
</div>

<div class="modal-footer dialog-button-row {{showCancelButton ? '' : 'rightMargin'}}">
   <button type="button" class="modal-button-base modal-button-blue ok-button" id="okDialogBtn" (click)="confirmModalWithCheckBox()">
      {{ (buttonLabelConfirm) || ('OK' | i18nv2) }}
   </button>
   <button *ngIf="showCancelButton" type="button" class="modal-button-base modal-button-grey" id="cancelDialogBtn" (click)="closeModalWithCheckBox()" >
      {{ (buttonLabelCancel) || ('CANCEL' | i18nv2) }}
   </button>
</div>