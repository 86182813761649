<div [hidden]="!globalContextMenu" class="menu global-context">
   <div class="bubble">
      <div class="triangle"></div>
      <div class="triangle-border"></div>
      <div class="menu-content">
         <div class="menu-item inactive" [attr.role]="'BUTTON' | i18nv2" title="{{userInfo}}" id="sidebar-user-info" tabindex="0">
            <div class="menu-item-icon icon-user-image"></div>
            <div class="menu-item-title unselectable">{{userName}}</div>
         </div>
         <div class="separator"></div>
         <div class="menu-item" [attr.role]="'BUTTON' | i18nv2" (click)="settingsClicked()" (keydown)="settingsKeydown($event)" id="sidebar-settings-button" tabindex="0">
            <div class="menu-item-icon icon-settings-image"></div>
            <div class="menu-item-title">{{'SETTINGS' | i18nv2}}</div>
         </div>
         <div *ngIf="isShowFullscreenBtn()" class="menu-item" [attr.role]="'BUTTON' | i18nv2" (click)="fullscreenClicked()" (keydown)="fullscreenKeydown($event)" id="sidebar-fullscreen-button" tabindex="0">
               <div class="menu-item-icon icon-fullscreen-image"></div>
               <div class="menu-item-title">{{'FULLSCREEN' | i18nv2}}</div>
         </div>
         <div *ngIf="checkFullscreenBtnClicked && !multimonStarted" class="menu-item" [attr.role]="'BUTTON' | i18nv2" (click)="quitFullscreenClicked()" (keydown)="quitFullscreenKeydown($event)" id="sidebar-quit-fullscreen-button" title="{{'QUIT_FULLSCREEN' | i18nv2}}" tabindex="0">
               <div class="menu-item-icon icon-quit-fullscreen-image"></div>
               <div class="menu-item-title">{{'QUIT_FULLSCREEN' | i18nv2}}</div>
         </div>
         <div class="usb-button menu-item" *ngIf="isUSBEnabled" [attr.role]="'BUTTON' | i18nv2" (click)="usbClicked()" (keydown)="usbKeydown($event)" id="sidebar-usb-button" tabindex="0">
            <div class="menu-item-icon icon-usb-image"></div>
            <div class="menu-item-title">{{'USB' | i18nv2}}</div>
         </div>
         <div class="menu-item" [attr.role]="'BUTTON' | i18nv2" (click)="aboutClicked()" id="sidebar-about-button" (keydown)="aboutKeydown($event)" tabindex="0">
            <div class="menu-item-icon icon-about-image"></div>
            <div class="menu-item-title">{{'ABOUT' | i18nv2}}</div>
         </div>
         <div class="menu-item" *ngIf="isSupportedMobileDevice()" [attr.role]="'BUTTON' | i18nv2" (click)="gestureHelpClicked()" id="sidebar-about-button" (keydown)="gestureHelpKeydown($event)" tabindex="0">
            <div class="menu-item-icon icon-gesture-help-image"></div>
            <div class="menu-item-title">{{'GESTURE_HELP' | i18nv2}}</div>
         </div>
         <div class="menu-item" [attr.role]="'BUTTON' | i18nv2" (click)="logoutClicked()" id="sidebar-logout-button" (keydown)="logoutKeydown($event)" tabindex="0">
            <div class="menu-item-icon icon-logoff-desktop-image"></div>
            <div class="menu-item-title">{{'LOGOUT_T' | i18nv2}}</div>
         </div>
      </div>
   </div>
</div>