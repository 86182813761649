/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * ReSampler for converting data in high sample rate to lower.
 *
 * @format
 * @param {number} originalRate The input sample rate
 * @param {number} targetRate   The target sample rate
 * @param {number} inputSize    The input data length
 */

import { Logger } from "@html-core";

export class ReSampler {
   private sampleIndexInc: number;
   private buffSize: number;
   private buff: number[];
   private originalRate: number;
   private targetRate: number;
   private inputSize: number;

   private startIndex = 0;
   private tailIndex = 0;
   private sampleStartIndex = 0;
   private length = 0;

   constructor(originalRate: number, targetRate: number, inputSize: number) {
      if (originalRate < targetRate) {
         const err = "resample can only have less samples";
         Logger.error(err, Logger.RTAV);
         throw err;
      }
      this.sampleIndexInc = originalRate / targetRate;
      // Math.ceil(this.sampleIndexInc) * 2 is large enough extra space for
      // the non-processed and being processed data
      this.buffSize = inputSize + Math.ceil(this.sampleIndexInc) * 2;
      this.buff = new Array(this.buffSize);
      this.originalRate = originalRate;
      this.targetRate = targetRate;
      this.inputSize = inputSize;
      this.length = 0;
      this.reset();
   }

   /**
    * Input data and would return the resampled array
    * @param  {Array} data The array of length inputSize and with sample
    *    rate originalRate
    * @return {Array|null} This returns the array of data with sample rate
    *    targetRate
    */
   public process(data) {
      let resampled;

      if (data.length !== this.inputSize) {
         return null;
      }
      if (!this.push(data)) {
         return null;
      }
      resampled = this.getResampledArray();
      if (!resampled) {
         return null;
      }
      return resampled;
   }

   public reset() {
      this.startIndex = 0;
      this.tailIndex = 0;
      this.sampleStartIndex = 0;
   }

   /**
    * Insert data in the inner buffer
    */
   private push(data) {
      if (data.length > this.buffSize - this.length) {
         return false;
      }
      for (let i = 0, k = this.tailIndex; i < data.length; i++, k = (k + 1) % this.buffSize) {
         this.buff[k] = data[i];
      }
      this.tailIndex = (this.tailIndex + data.length) % this.buffSize;
      return true;
   }

   /**
    * @return {float} return the resampled value
    */
   private getResampledData(startIndex, endIndex) {
      let i, is, ie, ws, we, sum;

      // Start index of included data segment(included)
      is = Math.ceil(startIndex);
      // End index of included data segment(not included)
      ie = Math.floor(endIndex);
      // The persentage of sample that should be count in before the
      // included data segment
      ws = is - startIndex;
      // The persentage of sample that should be count in after the included
      // data segment
      we = endIndex - ie;
      // Count in the data before the included data segment
      sum = ws * this.buff[Math.floor(startIndex)];
      // Count in the data after the included data segment
      if (we > 0) {
         sum += we * this.buff[ie];
      }
      // Count in the included data segment
      if (ie < is) {
         ie += this.buffSize;
         for (i = is; i < ie; i++) {
            sum += this.buff[i % this.buffSize];
         }
      } else {
         for (i = is; i < ie; i++) {
            sum += this.buff[i];
         }
      }
      return sum / this.sampleIndexInc;
   }

   /**
    * @param  {number} index
    * @return {boolean} This returns whether the index is in the valid range
    */
   private isInRange(index: number) {
      if (this.startIndex < this.tailIndex) {
         return index <= this.tailIndex && index >= this.startIndex;
      }
      return index >= this.startIndex || index <= this.tailIndex;
   }

   /**
    * Using reverting check to tell when both input index are valid, whether
    * the range in between is also valid
    * @param  {number}  sampleIndexStart The valid start index of the range
    * @param  {number}  sampleIndexEnd The valid end index of the range
    * @return {Boolean} This returns whether the range in between valid
    *    index contains invalid data
    */
   private isInCorrectOrder(sampleIndexStart: number, sampleIndexEnd: number) {
      if (this.startIndex < this.tailIndex) {
         // with normal order of valid data, sample should also be normal
         // order
         return sampleIndexStart < sampleIndexEnd;
      }
      // with revert order of valid data, sample can't across the bind
      return sampleIndexStart > this.tailIndex || sampleIndexEnd < this.startIndex;
   }

   /**
    * Using isInCorrectOrder and isInRange to check whether the sub-range
    * are valid or not.
    * @return {boolean} This returns whether we can access the elements in
    *    the range.
    */
   private validSubRange(sampleIndexStart, sampleIndexEnd) {
      return (
         this.isInRange(sampleIndexStart) &&
         this.isInRange(sampleIndexEnd) &&
         this.isInCorrectOrder(sampleIndexStart, sampleIndexEnd)
      );
   }

   private deleteUsed() {
      this.startIndex = Math.floor(this.sampleStartIndex);
   }

   /**
    * @return {Array} return the resampled array
    */
   private getResampledArray() {
      let sampleIndexStart = this.sampleStartIndex,
         sampleIndexEnd = (sampleIndexStart + this.sampleIndexInc) % this.buffSize,
         i = 0,
         result = [];

      // while needed data are in the buff.
      while (this.validSubRange(sampleIndexStart, sampleIndexEnd)) {
         // get each resampled data
         result[i] = this.getResampledData(sampleIndexStart, sampleIndexEnd);
         // shift indexes accordingly after get the resampled data
         i++;
         sampleIndexStart = sampleIndexEnd;
         sampleIndexEnd = (sampleIndexStart + this.sampleIndexInc) % this.buffSize;
      }
      this.sampleStartIndex = sampleIndexStart;
      this.deleteUsed();
      return result;
   }
}
