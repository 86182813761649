/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

import {EventHelperBase, SDKEvent, SDKRawEvent, RequestBundle} from "./event-helper.base";
// format validation check could be added here, packed binary format for efficiency
// Doable in the future: move below to schema
// messageType: uid char[32]: 31 char with /0
// requestId: id char[64]: 64 char without /0
// isRequest: uchar: boolean
// payloadLength: uchar[31]
// dataTypeLength: uchar
// payloadType: char[dataTypeLength]: max 254 string for payload schemaName with /0
// payload: arrarBuffer[data-LengthLength]: max 2^31 = 2GB

export class EventHelperArrayBuffer extends EventHelperBase{
   constructor(private schema: any){
      super();
   }

   public getRequest = (messageType: string, data: any, dataType: string): RequestBundle => {
      return null;
   };
   public parseResponse = (rawResponse: SDKRawEvent): SDKEvent => {
      return null;
   };
   public getResponse = (messageType: string, data: any, requestId: string, dataType: string): SDKRawEvent => {
      return null;
   };
   public parseRequest = (rawRequest: SDKRawEvent): SDKEvent => {
      return null;
   };

}