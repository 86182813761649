/**
 * *****************************************************
 * Copyright 2020-2023 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { LoginModule } from "../login/login.module";
import { RouterModule } from "@angular/router";

import { CommonModule } from "@angular/common";
import { ClientCommonModule } from "../../shared/common/client-shared.common.module";
import { SharedLauncherCommonModule } from "./common/launcher.common.module";
import { LaunchItemsComponent } from "./launchitems/launch-items.component";
import { OperationService } from "./launchitems/operation.service";
import { LaunchItemsCtrlService } from "./launchitems/launch-item-ctrl.service";
import { DeactivateGuardService } from "../../shared/view/deactive-guard.service";

@NgModule({
   imports: [
      CommonModule,
      ClientCommonModule,
      SharedLauncherCommonModule,
      LoginModule,
      // if a module has components related to router, define here
      RouterModule.forChild([
         {
            path: "launchitems",
            component: LaunchItemsComponent,
            canDeactivate: [DeactivateGuardService]
         }
      ])
   ],
   declarations: [LaunchItemsComponent],
   providers: [LaunchItemsCtrlService, OperationService]
})
export class SharedLauncherModule {}
