/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Subject } from "rxjs";
import { Unity } from "./unity";
export interface UnityMessage {
   msgType: string;
}

export class WindowAddMsg implements UnityMessage {
   public static readonly TYPE = "windowAdded";
   public msgType: string = WindowAddMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public windowPath: string,
      public execPath: string
   ) {}
}

export class WindowRemoveMsg implements UnityMessage {
   public static readonly TYPE = "windowRemoved";
   public msgType: string = WindowRemoveMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string
   ) {}
}

export class WindowTitleChangeMsg implements UnityMessage {
   public static readonly TYPE = "titleChanged";
   public msgType: string = WindowTitleChangeMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public windowTitle: string
   ) {}
}

export class WindowAttrChangeMsg implements UnityMessage {
   public static readonly TYPE = "attrChanged";
   public msgType: string = WindowAttrChangeMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public type: Unity.UnityWindowAttribute,
      public value: boolean
   ) {}
}

export class WindowTypeChangeMsg implements UnityMessage {
   public static readonly TYPE = "typeChanged";
   public msgType: string = WindowTypeChangeMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public type: Unity.UnityWindowType
   ) {}
}

export class WindowMoveMsg implements UnityMessage {
   public static readonly TYPE = "windowMoved";
   public msgType: string = WindowMoveMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public rect: any
   ) {}
}

export class WindowTitleBarAreaMsg implements UnityMessage {
   public static readonly TYPE = "titlebBarAreaChanged";
   public msgType: string = WindowTitleBarAreaMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public windowPositionInfo: any
   ) {}
}

export class WindowPrimaryMsg implements UnityMessage {
   public static readonly TYPE = "primaryWindowUpdate";
   public msgType: string = WindowPrimaryMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public primaryWindowId: any
   ) {}
}

export class WindowSecondaryMsg implements UnityMessage {
   public static readonly TYPE = "secondaryWindowUpdate";
   public msgType: string = WindowSecondaryMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public secondaryWindows: any[]
   ) {}
}

export class WindowIconChangeMsg implements UnityMessage {
   public static readonly TYPE = "iconChanged";
   public msgType: string = WindowIconChangeMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public iconSrc: string
   ) {}
}

export class SystemTrayMsg implements UnityMessage {
   public static readonly TYPE = "trayIconUpdate";
   public msgType: string = SystemTrayMsg.TYPE;
   constructor(
      public wmksKey: string,
      public icons: any[]
   ) {}
}

export class WindowRegionMsg implements UnityMessage {
   public static readonly TYPE = "regionUpdate";
   public msgType: string = WindowRegionMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowId: string,
      public regions: any[]
   ) {}
}

export class ZorderUpdateMsg implements UnityMessage {
   public static readonly TYPE = "zorderUpdate";
   public msgType: string = ZorderUpdateMsg.TYPE;
   constructor(
      public wmksKey: string,
      public components: any[]
   ) {}
}

export class UpdateCompleteMsg implements UnityMessage {
   public static readonly TYPE = "unityUpdateComplete";
   public msgType: string = UpdateCompleteMsg.TYPE;
   constructor(public wmksKey: string) {}
}

export class UnityReady implements UnityMessage {
   public static readonly TYPE = "unityReady";
   public msgType: string = UnityReady.TYPE;
   constructor(public wmksKey: string) {}
}
export class UnityReadyChanged implements UnityMessage {
   public static readonly TYPE = "unityReadyChanged";
   public msgType: string = UnityReadyChanged.TYPE;
   constructor(
      public wmksKey: string,
      public data: {
         ready: boolean;
         isOn: boolean;
         paused: boolean;
      }
   ) {}
}

export class UnityPaused implements UnityMessage {
   public static readonly TYPE = "unityPaused";
   public msgType: string = UnityPaused.TYPE;
   constructor(public wmksKey: string) {}
}

export class UnityNotReady implements UnityMessage {
   public static readonly TYPE = "unityNotReady";
   public msgType: string = UnityNotReady.TYPE;
   constructor(public wmksKey: string) {}
}

export class UnityVisibilityChanged implements UnityMessage {
   public static readonly TYPE = "visibleChanged";
   public msgType: string = UnityVisibilityChanged.TYPE;
   constructor(
      public wmksKey: string,
      public isShow: boolean
   ) {}
}

export class UnityDestroyMsg implements UnityMessage {
   public static readonly TYPE = "unityDestroyed";
   public msgType: string = UnityDestroyMsg.TYPE;
   constructor(public wmksKey: string) {}
}

export class UnityAttrChangedMsg implements UnityMessage {
   public static readonly TYPE = "appAttrChanged";
   public msgType: string = UnityAttrChangedMsg.TYPE;
   constructor(
      public wmksKey: string,
      public windowPath: string,
      public execPath: string,
      public name: string,
      public iconSrc: any
   ) {}
}

export class UnityUrlRedirectionMsg implements UnityMessage {
   public static readonly TYPE = "urlRedirection";
   public msgType: string = UnityUrlRedirectionMsg.TYPE;
   constructor(public url: string) {}
}

export class WindowSyncMsg implements UnityMessage {
   public static readonly TYPE = "windowSync";
   public msgType: string = WindowSyncMsg.TYPE;
   constructor(
      public wmksKey,
      public windows,
      public zOrders
   ) {}
}

export type UnitySubject = Subject<UnityMessage>;
