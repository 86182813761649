/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { clientUtil } from "@html-core";
import { AbstractModalComponent } from "../commondialog/abstract-modal.component";
import Logger from "../../../core/libs/logger";
import { WmksService } from "../../desktop/common/wmks.service";
import { ModalDialogService } from "../commondialog/dialog.service";
import { defaultKeyMappingSetting, KeyboardSettingService, KeyMapping } from "./keyboard-setting.service";

@Component({
   selector: "keyboard-setting",
   templateUrl: "./keyboard-setting.component.html",
   styles: [
      `
         /deep/ .setting-keyboard-window.modal.show .modal-dialog {
            max-width: 576px;
            width: 576px;
         }
      `
   ]
})
export class KeyBoardSettingComponent extends AbstractModalComponent {
   public modalType = {
      PRIMARY_MODAL: {
         type: "primaryModal",
         title: "KEYBOARD_SETTING_T"
      },
      KEYBOARD_LAYOUT_MODAL: {
         type: "keyBoardModal",
         title: "LANGUAGE_SETTING_T"
      },
      KEY_MAPPING_MODAL: {
         type: "keyMappingModal",
         title: "KEY_MAPPING_SETTING_T"
      },
      ADD_NEW_KEY_MAPPING_MODAL: {
         type: "addNewKeyMappingModal",
         title: "KEY_MAPPING_SETTING_T"
      }
   };

   public iskeyMappingSubModalOpened = false;
   public keyMappingSetting: KeyMapping[];
   public originModifierKeys = [];
   public mappingModifierKeys = ["Shift", "Control", "Alt", "Win"];
   public currentKeyMapping = {} as KeyMapping;
   public pendingOriginKeys = [];
   public pendingMappingKeys = [];
   public pendingOriginNonModifierKey = "";
   public pendingMappingNonModifierKey = "";
   public formatOriginNonModifierKey = "";
   public formatMappingNonModifierKey = "";
   public fromModifierKeys = [];
   public toModifierKeys = [];
   public isValidKeyMapping = false;
   public duplicateKeyMapping = [];
   public fromShotcut = "";
   public toShortcut = "";
   public warningMessage = "";
   public isEdited = false;
   public defaultKeyMappingSetting: KeyMapping[] = defaultKeyMappingSetting;
   public currentPlatform = "";
   public isSpecificLanguageEnabled = false;
   public currentModal = this.modalType.PRIMARY_MODAL;
   public keyboardLayouts = {};
   public currentLanguage = "";
   public currentLayout = "";
   public isPriKeyboardLayoutModal = true;
   public currentEditedLanguage = "";
   public languageSpecificKeyMapping = [];
   public currentLayoutForSpecificLanguage = "";
   public languages = [];
   public tempCurrentLanguage = "";
   public tempCurrentLayout = "";
   public isLatestFinalKeyMappingSetting = false;
   public finalKeyMappingSetting: KeyMapping[];
   public languageSpecificKeyMappingForCurrentPlatform: KeyMapping[];
   public searchContent = "";
   public duplicateIndex = [];
   public currentEditKeyMapping = {};
   public tempKeyMappingSetting: KeyMapping[];

   constructor(
      private keyboardSettingService: KeyboardSettingService,
      private modalDialogService: ModalDialogService,
      private wmksService: WmksService
   ) {
      super();
      this.keyMappingSetting = this.validityCheck(this.keyboardSettingService.getKeyMappingSetting());
      this.tempKeyMappingSetting = JSON.parse(JSON.stringify(this.keyMappingSetting));
      this.originModifierKeys = this.keyboardSettingService.getOriginModifierKeys();
      this.currentPlatform = this.keyboardSettingService.getCurrentPlatform();
      this.keyboardLayouts = this.keyboardSettingService.getKeyboardLayouts();
      this.languages = Object.keys(this.keyboardLayouts);
      this.currentLanguage = this.keyboardSettingService.getCurrentLanguage();
      this.currentLayout = this.keyboardSettingService.getCurrentLayout();
      this.isSpecificLanguageEnabled = this.keyboardSettingService.getIsSpecificLanguageEnabled();
      this.languageSpecificKeyMapping = this.keyboardSettingService.getLanguageSpecificKeyMapping(
         this.currentLanguage,
         this.currentLayout
      );
      this.languageSpecificKeyMappingForCurrentPlatform =
         this.keyboardSettingService.getLanguageSpecificKeyMappingForCurrentPlatform();
   }

   public deleteKeyMapping = (currentKeyMapping) => {
      this.tempKeyMappingSetting = this.tempKeyMappingSetting.filter((keyMapping) => {
         return (
            keyMapping.originShortcut !== currentKeyMapping.originShortcut ||
            keyMapping.mappingShortcut !== currentKeyMapping.mappingShortcut
         );
      });
      this.currentKeyMapping = {} as KeyMapping;
      this.isLatestFinalKeyMappingSetting = false;
      this.updateDuplicateIndex();
   };

   public openSubModal = () => {
      this.clear();
      this.isEdited = false;
      this.currentModal = this.modalType.ADD_NEW_KEY_MAPPING_MODAL;
   };

   public openLanguageSetting = () => {
      this.currentModal = this.modalType.KEYBOARD_LAYOUT_MODAL;
      this.tempCurrentLanguage = this.currentLanguage;
      this.tempCurrentLayout = this.currentLayout;
      this.searchContent = "";
   };

   public cancelUpdateLanguage = () => {
      this.currentModal = this.modalType.PRIMARY_MODAL;
      this.currentLanguage = this.tempCurrentLanguage;
      this.currentLayout = this.tempCurrentLayout;
   };

   public restoreToDefault = () => {
      /* Due to the HCS issue https://jira-euc.eng.vmware.com/jira/browse/ESC-36809,
         we need change the logic that restoring default just resets the keyMappingSetting corresponding to the OS
         to resets all keyMappingSetting due to not every users have MacOS
      */
      this.tempKeyMappingSetting = JSON.parse(JSON.stringify(this.defaultKeyMappingSetting));
      this.updateDuplicateIndex();
   };

   public chooseOriginModifierKeys = (event, isOrigin: boolean) => {
      let tempKey,
         formatKey = event.target.textContent;

      switch (event.target.textContent) {
         case "Shift":
            tempKey = "ShiftLeft";
            break;
         case "Control":
            tempKey = "ControlLeft";
            break;
         case "Alt":
         case "Option":
            tempKey = "AltLeft";
            break;
         case "⌘":
         case "Search":
         case "Win":
            tempKey = "MetaLeft";
            break;
         default:
            Logger.error("Choose a invalid key: " + formatKey);
            return "";
      }
      if (event.target.classList.contains("modifierKey-isChosen")) {
         event.target.style.backgroundColor = "";
         event.target.classList.remove("modifierKey-isChosen");
         if (isOrigin) {
            this.pendingOriginKeys = this.pendingOriginKeys.filter((originKey) => {
               return originKey !== tempKey;
            });
            this.fromModifierKeys = this.fromModifierKeys.filter((formatOriginKey) => {
               return formatOriginKey !== formatKey;
            });
         } else {
            this.pendingMappingKeys = this.pendingMappingKeys.filter((mappingKey) => {
               return mappingKey !== tempKey;
            });
            this.toModifierKeys = this.toModifierKeys.filter((formatMappingkey) => {
               return formatMappingkey !== formatKey;
            });
         }
      } else {
         event.target.style.backgroundColor = "#61bbe1";
         event.target.classList.add("modifierKey-isChosen");
         if (isOrigin) {
            this.pendingOriginKeys.push(tempKey);
            this.fromModifierKeys.push(formatKey);
         } else {
            this.pendingMappingKeys.push(tempKey);
            this.toModifierKeys.push(formatKey);
         }
      }
      this.pendingOriginKeys = this.keyboardSettingService.sortModifierKey(this.pendingOriginKeys);
      this.pendingMappingKeys = this.keyboardSettingService.sortModifierKey(this.pendingMappingKeys);
      this.fromModifierKeys = this.keyboardSettingService.sortModifierKey(this.fromModifierKeys);
      this.toModifierKeys = this.keyboardSettingService.sortModifierKey(this.toModifierKeys);
      this.isValidKeyMapping = this.checkRules();
      this.displayPendingKeys();
   };

   public enterNonModifierKey = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const self = this,
         code = event.code,
         key = this.keyboardSettingService.formatNonModifierKey(event.key);
      if (code)
         if (key) {
            if (event.target.classList.contains("origin-NonModifier-Key-input")) {
               this.pendingOriginNonModifierKey = code;
               setTimeout(() => {
                  self.formatOriginNonModifierKey = key;
               }, 20);
            } else {
               this.pendingMappingNonModifierKey = code;
               setTimeout(() => {
                  self.formatMappingNonModifierKey = key;
               }, 20);
            }
         }
      this.isValidKeyMapping = this.checkRules();
      setTimeout(() => {
         this.displayPendingKeys();
      }, 20);
   };

   public addKeyMapping = () => {
      if (this.pendingOriginNonModifierKey) {
         this.pendingOriginKeys.push(this.pendingOriginNonModifierKey);
      }
      if (this.pendingMappingNonModifierKey) {
         this.pendingMappingKeys.push(this.pendingMappingNonModifierKey);
      }
      const originShortcut = this.pendingOriginKeys.join(",");
      const mappingShortcut = this.pendingMappingKeys.join(",");

      this.tempKeyMappingSetting.push({
         platform: this.currentPlatform,
         isDefault: false,
         isOn: true,
         fromShotcut: this.fromShotcut,
         toShortcut: this.toShortcut,
         originShortcut: originShortcut,
         mappingShortcut: mappingShortcut
      });
      this.currentModal = this.modalType.KEY_MAPPING_MODAL;
      this.iskeyMappingSubModalOpened = false;
      this.isLatestFinalKeyMappingSetting = false;
      this.fromShotcut = "";
      this.toShortcut = "";
      this.updateDuplicateIndex();
   };

   public updateKeyMappingSetting = () => {
      this.keyMappingSetting = JSON.parse(JSON.stringify(this.tempKeyMappingSetting));
      this.keyboardSettingService.saveKeyMappingSetting(this.keyMappingSetting);
      if (clientUtil.isChromeClient()) {
         this.modalDialogService.showError({
            data: {
               titleKey: "RESTART_SESSION_T",
               contentKey: "RESTART_SESSION_M",
               buttonLabelConfirmKey: "OK"
            }
         });
      } else {
         this.wmksService.updateKeyMappingSetting(
            this.keyboardSettingService.getFinalKeyMappingSetting(this.keyMappingSetting)
         );
      }
   };

   public updateIsSpecificLanguageEnabled = () => {
      this.isLatestFinalKeyMappingSetting = false;
      this.isSpecificLanguageEnabled = !this.isSpecificLanguageEnabled;
      this.keyboardSettingService.updateIsSpecificLanguageEnabled(this.isSpecificLanguageEnabled);
      this.languageSpecificKeyMappingForCurrentPlatform =
         this.keyboardSettingService.getLanguageSpecificKeyMappingForCurrentPlatform();
      this.updateDuplicateIndex();
   };

   public displayPendingKeys = () => {
      this.fromShotcut =
         this.fromModifierKeys.length === 0
            ? this.formatOriginNonModifierKey
            : this.fromModifierKeys.join("+") +
              (this.formatOriginNonModifierKey ? "+" + this.formatOriginNonModifierKey : "");
      this.toShortcut =
         this.toModifierKeys.length === 0
            ? this.formatMappingNonModifierKey
            : this.toModifierKeys.join("+") +
              (this.formatMappingNonModifierKey ? "+" + this.formatMappingNonModifierKey : "");
   };

   public clear = () => {
      this.pendingOriginKeys = [];
      this.pendingMappingKeys = [];
      this.pendingOriginNonModifierKey = "";
      this.pendingMappingNonModifierKey = "";
      this.formatOriginNonModifierKey = "";
      this.formatMappingNonModifierKey = "";
      this.fromModifierKeys = [];
      this.toModifierKeys = [];
      this.isValidKeyMapping = false;
      this.fromShotcut = "";
      this.toShortcut = "";
      this.warningMessage = "";
      Array.from(document.getElementsByClassName("modifierKey-isChosen")).forEach((element) => {
         //@ts-ignore
         element.style.backgroundColor = "";
         element.classList.remove("modifierKey-isChosen");
      });
   };

   public cancel = () => {
      this.closeModal();
   };

   public closeSubModal = () => {
      this.isEdited = false;
      this.iskeyMappingSubModalOpened = false;
      this.currentModal = this.modalType.KEY_MAPPING_MODAL;
   };

   public checkRules = () => {
      const tempPendingOriginKeys = this.pendingOriginKeys.slice();
      const tempPendingMappingKeys = this.pendingMappingKeys.slice();
      const tempKeyMappingSetting = JSON.parse(JSON.stringify(this.tempKeyMappingSetting));
      if (this.pendingOriginNonModifierKey) {
         tempPendingOriginKeys.push(this.pendingOriginNonModifierKey);
      }
      if (this.pendingMappingNonModifierKey) {
         tempPendingMappingKeys.push(this.pendingMappingNonModifierKey);
      }
      // rule-1 Origin shortcut or mapping shortcut is empty.
      if (!tempPendingOriginKeys.length || !tempPendingMappingKeys.length) {
         this.warningMessage = "WRONG_MESSAGE_NO_ORIGIN_OR_MAPPING";
         return false;
      }
      // rule-2 Origin mapping equals to the mapping shortcut.
      if (tempPendingOriginKeys.sort().join(",") === tempPendingMappingKeys.sort().join(",")) {
         this.warningMessage = "WRONG_MESSAGE_ORIGIN_EQUAL_MAPPING";
         return false;
      }
      // rule-3 Origin and mapping shortcut have modifier keys only, but count of keys in one of them is not 1.
      if (
         !this.pendingOriginNonModifierKey &&
         !this.pendingMappingNonModifierKey &&
         (tempPendingOriginKeys.length !== 1 || tempPendingMappingKeys.length !== 1)
      ) {
         this.warningMessage = "WRONG_MESSAGE_NO_NONMODIFIER";
         return false;
      }
      // rule-4 Mapping shortcut has modifier keys only and origin shortcut has the selected key or input key.
      if (
         !this.pendingMappingNonModifierKey &&
         !(tempPendingOriginKeys.length === 1 && !this.pendingOriginNonModifierKey)
      ) {
         this.warningMessage = "WRONG_MESSAGE_MAPPING_MODIFIER_ONLY";
         return false;
      }
      // rule-5 Duplicate mapping
      for (let i = 0; i < tempKeyMappingSetting.length; i++) {
         if (
            tempKeyMappingSetting[i].originShortcut.split(",").sort().join(",") + tempKeyMappingSetting[i].platform ===
               tempPendingOriginKeys.sort().join(",") + this.currentPlatform &&
            tempKeyMappingSetting[i].mappingShortcut.split(",").sort().join(",") + tempKeyMappingSetting[i].platform ===
               tempPendingMappingKeys.sort().join(",") + this.currentPlatform
         ) {
            this.warningMessage = "WRONG_MESSAGE_DUPLICATE";
            return false;
         }
      }
      //rule-6 Combo with any of 3 LED keys (capLock, numLock and ScrollLock) is not allowed.
      if (
         this.pendingOriginNonModifierKey.includes("NumLock") ||
         this.pendingOriginNonModifierKey.includes("CapsLock") ||
         this.pendingOriginNonModifierKey.includes("ScrollLock")
      ) {
         this.warningMessage = "WRONG_MESSAGE_LED_KEYS";
         return false;
      }
      //rule-7 User can't add origin shortcut like Shift + char (like Shift + A)
      if (
         this.pendingOriginNonModifierKey.includes("Key") &&
         tempPendingOriginKeys.length === 2 &&
         (tempPendingOriginKeys.includes("ShiftLeft") || tempPendingOriginKeys.includes("ShiftRight"))
      ) {
         this.warningMessage = "WRONG_MESSAGE_SHIFT_PLUS_CHAR";
         return false;
      }
      this.warningMessage = "";
      return true;
   };

   public isDuplicate = (keyMapping: KeyMapping) => {
      if (!this.isLatestFinalKeyMappingSetting) {
         this.finalKeyMappingSetting = this.keyboardSettingService.getFinalKeyMappingSetting(
            this.tempKeyMappingSetting
         );
         this.isLatestFinalKeyMappingSetting = true;
      }
      const tempKeyMappingSetting = this.finalKeyMappingSetting.slice();
      const currentKeyMappingStr = keyMapping.platform + true + keyMapping.originShortcut;
      const originShutcuts = [];
      let count = 0;
      for (let i = 0; i < tempKeyMappingSetting.length; i++) {
         originShutcuts.push(
            tempKeyMappingSetting[i].platform + tempKeyMappingSetting[i].isOn + tempKeyMappingSetting[i].originShortcut
         );
      }
      for (let i = 0; i < originShutcuts.length; i++) {
         if (originShutcuts[i].includes(currentKeyMappingStr) || currentKeyMappingStr.includes(originShutcuts[i])) {
            count = count + 1;
         }
      }
      return count > 1;
   };

   public updateDuplicateIndex = () => {
      this.duplicateIndex = [];
      this.finalKeyMappingSetting = this.keyboardSettingService.getFinalKeyMappingSetting(this.tempKeyMappingSetting);
      for (let i = 0; i < this.finalKeyMappingSetting.length; i++) {
         if (this.isDuplicate(this.finalKeyMappingSetting[i])) {
            this.duplicateIndex.push(i);
         }
      }
   };

   public editKeyMapping = (keyMapping) => {
      this.currentEditKeyMapping = keyMapping;
      if (keyMapping.isDefault) {
         return;
      }
      const self = this;
      this.clear();
      const originShortcut = keyMapping.originShortcut.split(",");
      const mappingShortcut = keyMapping.mappingShortcut.split(",");
      const fromShotcut = keyMapping.fromShotcut.split("+");
      const toShortcut = keyMapping.toShortcut.split("+");
      if (!this.keyboardSettingService.isModifierKey(originShortcut[originShortcut.length - 1])) {
         this.pendingOriginNonModifierKey = originShortcut[originShortcut.length - 1];
         this.pendingOriginKeys = originShortcut.slice(0, originShortcut.length - 1);
         this.fromModifierKeys = fromShotcut.slice(0, fromShotcut.length - 1);
         this.formatOriginNonModifierKey = fromShotcut[fromShotcut.length - 1];
      } else {
         this.pendingOriginKeys = originShortcut;
         this.fromModifierKeys = fromShotcut;
      }

      if (!this.keyboardSettingService.isModifierKey(mappingShortcut[mappingShortcut.length - 1])) {
         this.pendingMappingNonModifierKey = mappingShortcut[mappingShortcut.length - 1];
         this.pendingMappingKeys = mappingShortcut.slice(0, mappingShortcut.length - 1);
         this.toModifierKeys = toShortcut.slice(0, toShortcut.length - 1);
         this.formatMappingNonModifierKey = toShortcut[toShortcut.length - 1];
      } else {
         this.pendingMappingKeys = mappingShortcut;
         this.toModifierKeys = toShortcut;
      }
      this.currentModal = this.modalType.ADD_NEW_KEY_MAPPING_MODAL;
      this.iskeyMappingSubModalOpened = true;
      this.isEdited = true;

      this.displayPendingKeys();
      setTimeout(() => {
         self.toggleModifierButton(true);
         self.toggleModifierButton(false);
      }, 20);
   };

   public toggleModifierButton = (isMapping: boolean) => {
      let buttonKeys = [],
         chooseKeys = [],
         prefix = "",
         ele;
      if (isMapping) {
         buttonKeys = this.mappingModifierKeys.slice();
         chooseKeys = this.toModifierKeys.slice();
         prefix = "mapping_";
      } else {
         buttonKeys = this.originModifierKeys.slice();
         chooseKeys = this.fromModifierKeys.slice();
         prefix = "origin_";
      }
      for (let i = 0; i < buttonKeys.length; i++) {
         if (chooseKeys.includes(buttonKeys[i])) {
            ele = document.getElementsByClassName(prefix + buttonKeys[i]);
            ele[0].style.backgroundColor = "#61bbe1";
            ele[0].classList.add("modifierKey-isChosen");
         }
      }
   };

   public updateCurrentKeyMapping = () => {
      this.deleteKeyMapping(this.currentEditKeyMapping);
      this.addKeyMapping();
      this.isEdited = false;
      this.isLatestFinalKeyMappingSetting = false;
      this.updateDuplicateIndex();
   };

   public updateKeyboardLayout = () => {
      this.currentModal = this.modalType.PRIMARY_MODAL;
      this.languageSpecificKeyMapping = this.keyboardSettingService.getLanguageSpecificKeyMapping(
         this.currentLanguage,
         this.currentLayout
      );
      this.keyboardSettingService.updateCurrentLanguage(this.currentLanguage);
      this.keyboardSettingService.updatecurrentLayout(this.currentLayout);
      this.languageSpecificKeyMappingForCurrentPlatform =
         this.keyboardSettingService.getLanguageSpecificKeyMappingForCurrentPlatform();
      this.updateKeyMappingSetting();
   };

   public isShowLanguageSpecificKeyMappings = () => {
      return this.isSpecificLanguageEnabled && this.languageSpecificKeyMappingForCurrentPlatform.length > 0;
   };

   public toggleKeyMapping = (keyMapping) => {
      keyMapping.isOn = !keyMapping.isOn;
      this.updateDuplicateIndex();
   };

   public openKeyMapping = () => {
      this.tempKeyMappingSetting = JSON.parse(JSON.stringify(this.keyMappingSetting));
      this.isLatestFinalKeyMappingSetting = false;
      this.currentModal = this.modalType.KEY_MAPPING_MODAL;
      this.updateDuplicateIndex();
   };

   public cancelKeyMappingOperation = () => {
      this.clear();
      this.currentModal = this.modalType.PRIMARY_MODAL;
      this.tempKeyMappingSetting = JSON.parse(JSON.stringify(this.keyMappingSetting));
   };

   public validityCheck = (arry4Checking) => {
      const isKeyMapping = function (item: any): item is KeyMapping {
         return (
            typeof item === "object" &&
            "platform" in item &&
            "isDefault" in item &&
            "isOn" in item &&
            "fromShotcut" in item &&
            "toShortcut" in item &&
            "originShortcut" in item &&
            "mappingShortcut" in item
         );
      };
      if (Array.isArray(arry4Checking) && arry4Checking.every(isKeyMapping)) {
         return arry4Checking;
      } else {
         Logger.error(
            "The format of keyMappingSetting is wrong and restore it to default automatically: " +
               JSON.stringify(arry4Checking)
         );
         this.restoreToDefault();
         this.updateKeyMappingSetting();
         return JSON.parse(JSON.stringify(this.defaultKeyMappingSetting));
      }
   };
}
