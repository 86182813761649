/**
 * ******************************************************
 * Copyright (C) 2015-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { EventBusService, BusEvent } from "@html-core";

@Component({
   selector: "ajax-busy-overlay",
   template: `
      <div [hidden]="!showAjaxBusyOverlay">
         <div class="ajax-busy-overlay">
            <div class="ajax-busy-icon"><div></div></div>
         </div>
      </div>
   `
})
export class AjaxBusyOverlayComponent implements OnInit, OnDestroy {
   public showAjaxBusyOverlay: boolean = false;
   ngOnInit() {
      this.eventBusService.listen(BusEvent.AjaxBusyMsg.MSG_TYPE).subscribe((msg: BusEvent.AjaxBusyMsg) => {
         this.showAjaxBusyOverlay = msg.busy;
      });
   }
   ngOnDestroy() {
      //We don't need to unsubscribe the ajaxBusy$
   }

   constructor(private eventBusService: EventBusService) {}
}
