/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * FIFO clipper buffer
 *
 * @format
 * @param {number} segmentLength [description]
 * @param {number} bufferSize      [description]
 */

interface Timestamp {
   value: number;
   index: number;
}

export class ClipBuffer {
   private buff: Uint16Array = null;
   private startIndex = 0;
   private tailIndex = 0;
   private length = 0;
   private lastTimeStamp: Timestamp = {
      value: 0,
      index: 0
   };
   private newTimeStamp: Timestamp = {
      value: 0,
      index: 0
   };
   private segmentLength: number;
   private bufferSize: number;

   constructor(segmentLength: number, bufferSize: number) {
      this.buff = new Uint16Array(bufferSize);
      this.startIndex = 0;
      this.tailIndex = 0;
      this.length = 0;
      this.lastTimeStamp = {
         value: 0,
         index: 0
      };
      this.newTimeStamp = {
         value: 0,
         index: 0
      };
      this.segmentLength = segmentLength;
      this.bufferSize = bufferSize;
   }

   public reset = () => {
      this.startIndex = 0;
      this.tailIndex = 0;
      this.length = 0;
      this.lastTimeStamp = {
         value: 0,
         index: 0
      };
      this.newTimeStamp = {
         value: 0,
         index: 0
      };
   };

   /**
    * Get a object with ArrayBuffer as data and number as timeStamp if possible
    * @return {object|null}
    */
   public getClip() {
      if (this.length < this.segmentLength) {
         return null;
      }
      // shift out the data
      const resultLength = this.segmentLength;
      const result = new Uint16Array(resultLength);
      for (let i = 0, k = this.startIndex; i < resultLength; i++, k = (k + 1) % this.bufferSize) {
         result[i] = this.buff[k];
      }
      this.length -= result.length;
      this.startIndex = (this.startIndex + result.length) % this.bufferSize;
      // calculate the corresponding timeStamp with linear assumption, which
      // should be accurate enough for the current use case
      const lastIndex = this.lastTimeStamp.index;
      const sampleExtenedIndex = this.getExtendedIndex(this.startIndex, lastIndex);
      const newExtenedIndex = this.getExtendedIndex(this.newTimeStamp.index, lastIndex);
      // approximate incremental time from the 2nd last newTimeStamp, and that
      // is using linear assumption with 2nd last and last newTimeStamp to get.
      const addup =
         ((this.newTimeStamp.value - this.lastTimeStamp.value) / (newExtenedIndex - lastIndex)) *
         (sampleExtenedIndex - lastIndex);
      // add the incremental time to 2nd last newTimeStamp, and get the result
      const timeStamp = Math.round(this.lastTimeStamp.value + addup);
      return {
         data: result.buffer,
         timestamp: timeStamp
      };
   }

   /**
    * Add data to the ClipBuffer with floating number converted to short
    * @param {Array} data
    * @param {number} timeStamp
    */
   public add(data: Float32Array, timeStamp: number) {
      if (!data || data.length > this.bufferSize - this.length) {
         return;
      }
      this.lastTimeStamp.value = this.newTimeStamp.value;
      this.lastTimeStamp.index = this.newTimeStamp.index;
      for (let i = 0, k = this.tailIndex; i < data.length; i++, k = (k + 1) % this.bufferSize) {
         this.buff[k] = this.convertToShort(data[i]);
      }
      this.length += data.length;
      this.tailIndex = (this.tailIndex + data.length) % this.bufferSize;
      this.newTimeStamp.value = timeStamp;
      this.newTimeStamp.index = this.tailIndex;
   }

   /**
    * A simple float to short(int16) converting function
    * @param  {number} floatNum A number in the range [-1,1]
    * @return {number} This returns a short number in the range [-32767, 32767]
    */
   private convertToShort(floatNum: number) {
      const maxShortValue = 32767;
      return Math.round(maxShortValue * floatNum);
   }

   /**
    * get the extended index to easy the calculation of accurate timestamp
    * @param  {number} srcIndex The original index, which might get extended if
    *    too small
    * @param  {number} minIndex The lower bound of the index
    * @return {number} This returns the extended index which is larger or equal
    *    to minIndex
    */
   private getExtendedIndex(srcIndex, minIndex) {
      if (srcIndex < minIndex) {
         return srcIndex + this.bufferSize;
      } else {
         return srcIndex;
      }
   }
}
