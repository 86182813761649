/**
 * *****************************************************
 * Copyright 2018 - 2021 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import $ from "jquery";
import Logger from "../../../core/libs/logger";
import WMKS from "WMKS";
import TextParser from "../../../shared/desktop/common/text-parser.service";
import { MKSVCHAN_CONST } from "../../../shared/desktop/channels/MKSVchan/mksvchan-consts";
import { MKSVchan } from "../../../shared/desktop/channels/mksvchan";
import { AB } from "../../../shared/desktop/common/appblast-util.service";
import { HtmlRemoteSessionManager } from "../../common/remote-session/html-remote-session-manager";
import { MksvchanService } from "../../../shared/desktop/channels/mksvchan.service";
import { ClipboardKillSwitchService } from "../../../shared/desktop/common/clipboard-killswitch.service";
import { asyncClipboard } from "../../../shared/desktop/common/async-clipboard.service";
import { NgbToastService } from "./../../../shared/common/service/ngb-toast.service";
import { AfterViewInit, Component, ViewChild, TemplateRef, ElementRef } from "@angular/core";
import { TranslateService, BusEvent, EventBusService } from "@html-core";
import { DisplayService } from "../../../shared/desktop/common/display/display.service";

@Component({
   selector: "panel-free-clipboard",
   templateUrl: "./panel-free-clipboard.component.html"
})
export class panelFreeClipboardComponent implements AfterViewInit {
   @ViewChild("clipboardComponent")
   clipboardComponent: TemplateRef<any>;

   private copyInside: boolean;
   private pasteFirstTime: boolean;
   private clipboard: {
      text: string;
      html: string;
   };
   private hasClipboardData: boolean;
   private clipboardReady: boolean;
   private copyEnabled: boolean;
   private pasteEnabled: boolean;
   private showClipboardDialog: boolean;
   private isMacOS: boolean;
   private pushClipboardFinish: boolean;
   private activeModifiers: number[];
   private _globalClipboard: any;
   private readonly defaultText = "";
   private readonly defaultHtml = "";
   private element: any;
   public inputElement: any;
   public clipboardTooltip: any;
   public clipboardBtn: any;
   public lateKeysArray: Function[] = [];
   public isHandlingKeys = false;

   constructor(
      private translate: TranslateService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private mksvchanService: MksvchanService,
      private toastService: NgbToastService,
      private clipboardKillSwitchService: ClipboardKillSwitchService,
      private asyncClipboard: asyncClipboard,
      private _el: ElementRef,
      private displayService: DisplayService,
      private eventBusService: EventBusService
   ) {
      this.copyInside = false;
      this.pasteFirstTime = true;
      this._globalClipboard = new MKSVchan.Clipboard();
      this.clipboard = { text: this.defaultText, html: this.defaultHtml };
      this.hasClipboardData = false;
      this.clipboardReady = false;
      this.copyEnabled = true;
      this.pasteEnabled = true;
      this.showClipboardDialog = true;
      this.activeModifiers = [];

      // Listen to server clipboard updates and sync panel with remote
      this.asyncClipboard.setSyncFunction(this.sendClipboardText, this.sendClipboardHTML);
      // clipboard data
      this.mksvchanService.addEventListener("clipboardChanged", this.handleClipboardChange);

      this.mksvchanService.addEventListener("clipboardCapabilitiesChanged", this.handleCapabilitiesChanged);

      this.mksvchanService.addEventListener("wmkscopy", (id) => {
         /**
          * handles the notification from the remote guest that clipboard has
          * changed, sends a request RPC for clipboard contents.
          */
         const session = this.htmlRemoteSessionManager.getSessionById(id);
         if (!session) {
            Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
            return;
         }
         const client = this.mksvchanService.getClient(session.key);
         if (!client) {
            Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
            return;
         }
         client.sendClipboardRequest(
            () => {
               Logger.info("Clipboard request successfully sent", Logger.CLIPBOARD);
            },
            () => {
               Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
            }
         );
      });

      this.mksvchanService.addEventListener("clipboardReady", (id) => {
         /**
          * Called by a wmks session when its clipboard is ready. If the
          * session is the current session, sync the remote clipboard with the
          * local clipboard
          */
         const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
         if (!!currentSession && id === currentSession.key) {
            const mksVchanClient = this.mksvchanService.getClient(id);
            this.handleCapabilitiesChanged(
               id,
               mksVchanClient.ready,
               mksVchanClient.copyEnabled,
               mksVchanClient.pasteEnabled
            );
            this.pushClipboard();
         }
      });

      this.mksvchanService.addEventListener("clipboardPush", this.pushClipboard);

      this.eventBusService.listen(BusEvent.ClipboardClientContentMsg.MSG_TYPE).subscribe(this._onClipboardMsg);
   }

   ngAfterViewInit() {
      this.element = $(this._el.nativeElement);
      this.inputElement = this.element.find("#clipboard-input");
      this.clipboardTooltip = $("#clipboard-tooltip");
      this.clipboardBtn = $("#clipboard");
      $(window).bind("focus", this.focusInvisibleTextarea);
      $(window).bind("click", this.focusInvisibleTextarea);
      $(window).bind("blur", this.blurInvisibleTextarea);
      const self = this;
      //This is for SR ESC-39655, `this` in this.inputElement.on() is wrong

      this.inputElement.on({
         paste: (e) => {
            Logger.info("Paste event trigger.", Logger.CLIPBOARD);
            if (self.pasteFirstTime) {
               self.clipboardPaste(e);
            } else {
               Logger.info("Don't send paste again", Logger.CLIPBOARD);
            }
         },
         copy: (e) => {
            Logger.info("Copy event trigger.", Logger.CLIPBOARD);
            self.copyInside = true;
            if (!self.copyEnabled) {
               return;
            }
            self.clipboardCopy(e);
         },
         keydown: (e) => {
            self.syncModifiers(e);
         },
         keyup: (e) => {
            self.syncModifiers(e);
         },
         keypress: (e) => {
            //keypress and keydown take priority over paste event in Firefox & Safari
            if (WMKS.BROWSER.isSafari() || WMKS.BROWSER.isFirefox()) {
               Logger.debug("KeyPress event", Logger.CLIPBOARD);
            } else {
               self.syncModifiers(e);
            }
         }
      });
   }

   private _onClipboardMsg = (msg: BusEvent.ClipboardClientContentMsg) => {
      // Clear the local clipboard before set data
      this._globalClipboard.clear();
      if (msg.text !== undefined) {
         this._globalClipboard.setText(msg.text, MKSVCHAN_CONST.CP_FORMAT.TEXT);
      }
      if (msg.html !== undefined) {
         const cfHtml = TextParser.htmlToCFHtml(msg.html);
         if (cfHtml !== null && cfHtml !== "") {
            this._globalClipboard.setHtml(cfHtml, MKSVCHAN_CONST.CP_FORMAT.HTML_FORMAT);
         }
      }

      this.pushClipboard();
   };

   public isClipboardPopupToastEnabled = (): any => {
      return this.clipboardKillSwitchService.enableClipboardNotification;
   };

   public sendClipboardHTML = (html) => {
      const cfHtml = TextParser.htmlToCFHtml(html);
      if (cfHtml !== null) {
         this.setClipboardHtml(cfHtml);
      }
      this.pushClipboard();
   };
   /*
    * Intercepts a copy event and replaces the copied text with the current
    * clipboard contents.
    */
   public clipboardCopy = (e): void => {
      Logger.debug("Copy operation is captured.", Logger.CLIPBOARD);
      if (!this.hasClipboardData) {
         Logger.info("There is no data in the clipboard.", Logger.CLIPBOARD);
         return;
      }

      AB.setClipboardText(e, this.clipboard.text);

      if (this.clipboard.html) {
         const html = TextParser.CFHtmlToHtml(this.clipboard.html);
         if (html !== null && e !== undefined) {
            Logger.debug("agent clipboard has html", Logger.CLIPBOARD);
            AB.setClipboardHtml(e, html);
         }
      }

      Logger.debug(this.translate._T("CLIPBOARD_COPIED_M"), Logger.CLIPBOARD);
      if (e) {
         e.preventDefault();
      }
   };

   /**
    * setClipboardText
    *
    * Set a local clipboard text change to the global clipboard.
    *
    * @params text: A string that we will update the remote clipboard with
    */
   public setClipboardText = (text: string): void => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();

      if (!currentSession) {
         return;
      }

      const client = this.mksvchanService.getClient(currentSession.key);
      if (!client || !client.pasteEnabled) {
         Logger.warning(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }

      this._globalClipboard.setText(text, MKSVCHAN_CONST.CP_FORMAT.TEXT);
   };

   /**
    * setClipboardHtml
    *
    * Set a local clipboard html change to the global clipboard.
    *
    * @params html: A string that we will update the remote clipboard with
    */
   public setClipboardHtml = (html: string): void => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();

      if (!currentSession) {
         return;
      }

      const client = this.mksvchanService.getClient(currentSession.key);
      if (!client || !client.pasteEnabled) {
         Logger.warning(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }

      this._globalClipboard.setHtml(html, MKSVCHAN_CONST.CP_FORMAT.HTML_FORMAT);
   };

   /*
    * Intercept a paste event to get paste data, then update clipboard and send
    * the appropriate call to update the server clipboard.
    */
   public clipboardPaste = (e): void => {
      if (!this.pasteEnabled || this.copyInside) {
         return;
      }
      Logger.info("Send paste", Logger.CLIPBOARD);
      let text, html;

      Logger.debug("Paste operation is captured and data is sent to remote server.", Logger.CLIPBOARD);
      text = AB.getClipboardText(e);
      html = AB.getClipboardHtml(e);

      // Clear the local clipboard before set data
      this._globalClipboard.clear();
      this.setClipboardText(text);
      this.sendClipboardHTML(html);
      e.preventDefault();
   };

   public setDefaultPolicyText = () => {
      let policyText = "";
      if (!this.clipboardReady) {
         policyText = this.translate._T("WARNING_COPY_PASTE_UNAVAILABLE_CONTENT");
      } else if (this.clipboardReady && this.copyEnabled && this.pasteEnabled) {
         policyText = this.translate._T("WARNING_COPY_PASTE_ENABLED_CONTENT");
      } else if (this.clipboardReady && this.copyEnabled && !this.pasteEnabled) {
         policyText = this.translate._T("WARNING_PASTE_DISABLED_CONTENT");
      } else if (this.clipboardReady && !this.copyEnabled && this.pasteEnabled) {
         policyText = this.translate._T("WARNING_COPY_DISABLED_CONTENT");
      } else if (this.clipboardReady && !this.copyEnabled && !this.pasteEnabled) {
         policyText = this.translate._T("WARNING_COPY_PASTE_DISABLED_CONTENT");
      }
      if ($("#clipboard").tooltip !== undefined) {
         $("#clipboard").tooltip({
            items: "button",
            content: policyText
         });
      }
   };

   public handleCapabilitiesChanged = (
      id,
      clipboardReady,
      copyEnabled,
      pasteEnabled,
      isFinalStatus?: boolean
   ): void => {
      /**
       * Notifies to update its clipboard capabilities. Note
       * that it always updates with the capabilities of currently active
       * session. If this session is not currently active
       * then the call is redundant, but harmless.
       */
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!!currentSession && id === currentSession.key) {
         setTimeout(() => {
            const oldStat = this.clipboardReady;

            this.clipboardReady = clipboardReady;
            this.copyEnabled = copyEnabled;
            this.pasteEnabled = pasteEnabled;
            /**
             * handleCapabilitiesChanged will be called multi times, but the correct
             * status is updated by client.onClipboardStateChanged, other status
             * are wrong.
             */
            if (isFinalStatus) {
               Logger.debug(
                  "handleCapabilitiesChanged isFinalStatus:" +
                     isFinalStatus +
                     ", copyEnabled: " +
                     copyEnabled +
                     ", pasteEnabled: " +
                     pasteEnabled,
                  Logger.CLIPBOARD
               );
               this.asyncClipboard.onSessionCapUpdate(id, copyEnabled, pasteEnabled, clipboardReady);

               if (pasteEnabled && clipboardReady) {
                  this.eventBusService.dispatch(new BusEvent.ReadyForClipboardSyncMsg());
               }
            }
            this.setDefaultPolicyText();
            /*
             * Sync local clipboard to remote once clipboard is ready
             */
            if (oldStat !== true && this.clipboardReady === true) {
               this.focusInvisibleTextarea(null);
            }
         });
      }
   };

   public handleClipboardChange = (id, clipboard, error): void => {
      this._globalClipboard = clipboard;
      this.copyInside = true;
      const data: any = {};

      data.text = this._globalClipboard.getText(MKSVCHAN_CONST.CP_FORMAT.TEXT);
      data.html = this._globalClipboard.getText(MKSVCHAN_CONST.CP_FORMAT.HTML_FORMAT);

      /*
       * File transfer and clipboard share the same error code here
       * Can't detect whether to show notification on ft or clipboard panel
       * So trigger both of them, can't use dialog, or it will effect normal
       * user experience in the desktop.
       */
      if (error === MKSVCHAN_CONST.CLIPBOARD_ERROR.DISALLOWED_BY_AUDIT) {
         Logger.info(this.translate._T("ERROR_MSG_DISALLOWED_BY_AUDIT"), Logger.CLIPBOARD);
         this.mksvchanService.emit("fileTransferBlockedByAudit");
         return;
      }

      if (data.text != null || data.html != null) {
         this._handleClipboardDataChange(data, error);

         if (this.isClipboardPopupToastEnabled() && this.copyEnabled) {
            this.asyncClipboard
               .setClipboard(data)
               .then(() => {
                  // for now only plain text and image can be set on Chrome
                  Logger.debug("successfully set client clipboard", Logger.CLIPBOARD);
               })
               .catch((e) => {
                  Logger.debug(e);
                  if (e.toString() === "NotAllowedError: Document is not focused.") {
                     // @ts-ignore
                     if (this.displayService.setExtendedMonitorClipboardChanged) {
                        // @ts-ignore
                        this.displayService.setExtendedMonitorClipboardChanged(data.text, data.html);
                     }
                     Logger.debug(
                        "copy and paste is not supported in extend monitor for HTML Access",
                        Logger.CLIPBOARD
                     );
                  } else {
                     Logger.debug("fallback to show toast to set client clipboard", Logger.CLIPBOARD);
                     this.toastService.clearEarlyToast(this.toastService.TOAST_TYPE.CLIPBOARD);
                     //Confirm the copy to your local clipboard
                     this.toastService.infoWithCallBack(
                        this.clipboardComponent,
                        this.toastService.TOAST_TYPE.CLIPBOARD,
                        this.executeCopy
                     );
                  }
               });
         }
      }
   };

   public confirmClipboardToast = () => {
      this.executeCopy();
      this.toastService.clearEarlyToast(this.toastService.TOAST_TYPE.CLIPBOARD);
   };

   private _handleClipboardDataChange = (data, error): void => {
      setTimeout(() => {
         Logger.info("Clipboard data in the remote server is synced to client.", Logger.CLIPBOARD);
         if (data === null) {
            Logger.info(this.translate._T("CLIPBOARD_FAILED_M"));
            return;
         }

         if (data.text === null) {
            // if only have html no text, text will be string null so make it ''.
            this.clipboard.text = "";
         } else {
            this.clipboard.text = data.text;
         }

         this.clipboard.html = data.html;
         this.hasClipboardData = true;

         if (error === MKSVCHAN_CONST.CLIPBOARD_ERROR.MAX_LIMIT_EXCEEDED) {
            Logger.info(this.translate._T("CLIPBOARD_TRUNCATED_M"), Logger.CLIPBOARD);
         } else {
            Logger.info(this.translate._T("CLIPBOARD_SYNCED_M"), Logger.CLIPBOARD);
         }
      });
   };

   /**
    * Pushes the local clipboard to the remote guest.
    */
   public pushClipboard = (): void => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession) {
         Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }
      const client = this.mksvchanService.getClient(currentSession.key);
      if (!client) {
         Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }
      this.pushClipboardFinish = false;
      client.sendClipboard(
         this._globalClipboard,
         () => {
            this.pushClipboardFinish = true;
            Logger.debug("Clipboard synced with focused session", Logger.CLIPBOARD);
         },
         () => {
            Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         }
      );
   };

   /**
    * Sends a local clipboard text change to the remote guest.
    */
   public sendClipboardText = (text): void => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (!currentSession) {
         return;
      }
      const mksVchanClient = this.mksvchanService.getClient(currentSession.key);
      if (!mksVchanClient || !mksVchanClient.pasteEnabled) {
         return;
      }
      mksVchanClient.sendClipboardText(
         text,
         (text, error) => {
            const data: any = {};
            data.text = text;
            Logger.debug("Clipboard update successfully sent", Logger.CLIPBOARD);
            this._globalClipboard.setText(text, MKSVCHAN_CONST.CP_FORMAT.TEXT);
            this._handleClipboardDataChange(data, error);
         },
         () => {
            Logger.warning(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         }
      );
   };

   public executeCopy = () => {
      try {
         // To fix bug 2232902
         this.inputElement.focus(this.hideShadowCursor);
         this.inputElement.select();
         Logger.debug("clipboard-input area display: " + this.inputElement.css("display"), Logger.CLIPBOARD);
         document.execCommand("copy");
      } catch (a) {
         Logger.info(a);
      }
   };

   public focusInvisibleTextarea = (e) => {
      if (e && e.target && e.target.tagName !== "CANVAS" && e.target.tagName !== "VIDEO") {
         return;
      }

      if (!this.clipboardReady) {
         Logger.debug("Clipboard is not ready.", Logger.CLIPBOARD);
         return;
      }
      this.inputElement.focus(this.hideShadowCursor);
      this.inputElement.select();
   };

   public blurInvisibleTextarea = (): void => {
      this.copyInside = false;
      this.pasteFirstTime = true;
      this.showShadowCursor();
   };

   public hideShadowCursor = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession) {
         currentSession.wmks("hideShadowCursor");
      }
   };

   public showShadowCursor = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      // show the shadow cursor when not in multi monitor
      if (currentSession && $("#primary-monitor").length === 0) {
         currentSession.wmks("showShadowCursor");
      }
   };

   private sendKeyEvents = (e, type) => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (type === "keyup") {
         type = "KeyUp";
      }
      if (type === "keydown") {
         type = "KeyDown";
      }
      if (type === "keypress") {
         type = "KeyPress";
      }
      if (currentSession) {
         currentSession.wmks("sendKeyMessage", {
            type: type,
            event: e
         });
      }
   };

   public sendLateKey = (func: Function) => {
      this.lateKeysArray.push(func);
      this.runNextKey();
   };

   private runNextKey = () => {
      if (this.isHandlingKeys) {
         return;
      }

      const promises = this.lateKeysArray.map((item: Function) => {
         this.isHandlingKeys = true;
         return new Promise((resolve, reject) => {
            let intervalTime = 10,
               callCount = 0;

            const intervalID = setInterval(() => {
               callCount++;
               if (callCount > 10) {
                  resolve(item);
                  Logger.debug("the delay is longer than 100ms", Logger.CLIPBOARD);
                  clearInterval(intervalID);
                  // Reset the pushClipboardFinish value
                  this.pushClipboardFinish = false;
               } else {
                  if (this.pushClipboardFinish) {
                     resolve(item);
                     Logger.debug("clear interval", Logger.CLIPBOARD);
                     clearInterval(intervalID);
                     // Reset the pushClipboardFinish value
                     this.pushClipboardFinish = false;
                  }
               }
            }, intervalTime);
         });
      });

      Promise.all(promises).then((values) => {
         values.forEach((func: Function) => {
            func();
            this.lateKeysArray.shift();
         });
         this.isHandlingKeys = false;

         if (this.lateKeysArray.length > 0) {
            this.runNextKey();
         }
      });
   };

   private syncModifiers = (e) => {
      let thisMod, thisVal, i, idx;
      // 17: ctrl, 91: meta chrome, 224: meta firefox
      let Modifiers = [17, 91];
      if (WMKS.BROWSER.isFirefox()) {
         Modifiers = [17, 224];
      }
      const values = [e.ctrlKey, e.metaKey];

      // Send paste directly without wait clipboard data sync after first time.
      if (!this.pasteFirstTime) {
         e.preventDefault();
         this.sendKeyEvents(e, e.type);
         return;
      }
      // Mac command in Firefox is 224

      // keycode 86 => v, keycode 67 =>c
      // when user press ctrl + c in the remote desktop, set pasteFirstTime to false to fix SR 2966565
      if (
         this.activeModifiers.length > 0 &&
         Modifiers.indexOf(this.activeModifiers[0]) > -1 &&
         (e.keyCode === 86 || e.keyCode === 67)
      ) {
         const sendV = () => {
            this.sendKeyEvents(e, e.type);
            this.pasteFirstTime = false;
         };
         this.sendLateKey(sendV);
         return;
      }

      // Don't need to wait for timeout for other keys. 91 : cmd, 17 :ctrl, 86: v

      if (e.keyCode !== 91 && e.keyCode !== 17 && e.keyCode !== 224 && e.keyCode !== 86) {
         e.preventDefault();
         this.sendKeyEvents(e, e.type);
         return;
      }

      for (i = 0; i < Modifiers.length; i++) {
         thisMod = Modifiers[i];
         thisVal = values[i];

         idx = this.activeModifiers.indexOf(thisMod);
         if (thisVal && idx === -1) {
            this.activeModifiers.push(thisMod);
            e.preventDefault();
            this.sendKeyEvents(e, "keydown");
            return;
         } else if (!thisVal && idx !== -1) {
            const sendCtrl = () => {
               this.activeModifiers.splice(idx, 1);
               e.preventDefault();
               this.sendKeyEvents(e, "keyup");
            };
            this.sendLateKey(sendCtrl);
            return;
         }
      }
      e.preventDefault();
      this.sendKeyEvents(e, e.type);
   };

   ngOnDestroy = () => {
      $(window).unbind("focus", this.focusInvisibleTextarea);
      $(window).unbind("click", this.focusInvisibleTextarea);
      $(window).unbind("blur", this.blurInvisibleTextarea);
   };
}
