/**
 * ******************************************************
 * Copyright (C) 2017-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { LargeInteger } from "./large-integer";
import { StructWithMarker } from "./struct-with-marker";
import Logger from "../../../../core/libs/logger";
/**
 * stream-writer.js --
 *
 * less efficient, optimize later if needed.
 *
 * usage:
 * ------let streamWriter = protocolUtil.getWriter();
 * ------streamWriter.pushUint16(RDPDR_TYPE.RDPDR_CTYP_CORE);
 * ------streamWriter.pushUint16(RDPDR_TYPE.PAKID_CORE_CLIENTID_CONFIRM);
 * ------streamWriter.pushUint16(versionMajor);
 * ------streamWriter.pushUint16(versionMinor);
 * ------streamWriter.pushUint32(clientId);
 * ------console.log(streamWriter.getPrintableBuffer());
 * ------sendRPC(streamWriter, onDone, onAbort);
 */

export class StreamWriter extends StructWithMarker {
   /**
    * Could be slow, if so, optimize later.
    */
   private temp = [];
   constructor(
      private isBigEndian: boolean = false,
      allowStructAlign: boolean = false
   ) {
      super(allowStructAlign);
   }

   /**
    * Will loss data if number is too large for JS, use struct for that case.
    */
   public pushBytes = (number, bytes, isSignInteger = false) => {
      if (bytes === 0) {
         return;
      } else if (typeof number === "boolean") {
         this.logger.trace("convert boolean into number");
         number = Number(number);
      } else if (typeof number !== "number") {
         throw "can't push a non-number value without string type";
      } else if (number < 0) {
         if (!isSignInteger) {
            throw "should not be used with negative number";
         }
         let number1;
         if (bytes <= 2) {
            number1 = (number + 1) << (8 * bytes);
         } else if (bytes === 4) {
            number1 = new Uint32Array(new Int32Array([number]).buffer)[0];
         } else {
            throw "not support push int64";
         }
         number = number1;
      }
      const numberArray = new Array(bytes);
      if (this.isBigEndian) {
         for (let i = 0; i < bytes; i++) {
            numberArray[bytes - 1 - i] = number & 0xff;
            number >>>= 8;
         }
      } else {
         for (let i = 0; i < bytes; i++) {
            numberArray[i] = number & 0xff;
            number >>>= 8;
         }
      }
      this.pushData(numberArray);
   };

   public pushInt8 = (number) => {
      this.pushBytes(number, 1, true);
   };

   public pushInt16 = (number) => {
      this.pushBytes(number, 2, true);
   };

   public pushInt32 = (number) => {
      this.pushBytes(number, 4, true);
   };

   public pushUint8 = (number) => {
      this.pushBytes(number, 1);
   };

   public pushUint16 = (number) => {
      this.pushBytes(number, 2);
   };

   public pushUint32 = (number) => {
      this.pushBytes(number, 4);
   };

   public pushUint64 = (number) => {
      this.pushBytes(number, 8);
   };

   public pushLargeInteger = (number) => {
      if (number instanceof LargeInteger) {
         if (this.isBigEndian) {
            throw "Don't support big endian with largeInteger yet";
         } else {
            this.pushUint32(number.getLowPart());
            this.pushUint32(number.getHighPart());
         }
      } else {
         this.pushUint64(number);
      }
   };

   public getStream = () => {
      return new Uint8Array(this.temp);
   };

   public getArray = () => {
      return this.temp;
   };

   public hasData = () => {
      return this.temp.length > 0;
   };

   public clear = () => {
      this.temp = [];
      this.resetMarker();
   };

   public pushNumber = (value, type) => {
      // special process for large integer since JS language has inaccurate issue
      if (type === "LARGE_INTEGER") {
         this.pushLargeInteger(value);
      } else if (type === "INT8") {
         this.pushBytes(value, 1, true);
      } else if (type === "INT16") {
         this.pushBytes(value, 2, true);
      } else if (type === "INT32") {
         this.pushBytes(value, 4, true);
      } else {
         this.pushBytes(value, type);
      }
   };

   /**
    * @private
    */
   public pushStringByFunc = (string, length, pushFunction) => {
      if (string.length > length - 1) {
         this.logger.warning("the alloc length is less than the input string length");
      } else if (string.length < length - 1) {
         this.logger.trace("padding is added when pushing string");
      }
      for (let i = 0; i < Math.min(string.length, length - 1); i++) {
         const charCode = string.charCodeAt(i);
         pushFunction(charCode);
      }
      // add padding
      for (let i = string.length; i < length - 1; i++) {
         pushFunction(0);
      }
      pushFunction(0); //add end as /0
   };

   //UTF-16
   public pushUnicode = (string, length) => {
      this.pushStringByFunc(string, length, this.pushUint16);
   };

   //ascii code, allow padding
   public pushString = (string, length) => {
      this.pushStringByFunc(string, length, this.pushUint8);
   };

   public getPrintableBuffer = () => {
      const hexNumbers = [];
      this.temp.forEach((number) => {
         hexNumbers.push("0x" + number.toString(16));
      });
      return hexNumbers;
   };

   /**
    * @param  {ArrayBuffer} data
    * @param  {number} length
    */
   public pushStream = (data, length) => {
      let streamArray;
      if (!(data instanceof ArrayBuffer)) {
         this.logger.error("can only push ArrayBuffer as stream");
         return;
      }
      if (length === "UNLIMITED" || length === undefined) {
         streamArray = Array.from(new Uint8Array(data));
      } else {
         if (length <= data.byteLength) {
            streamArray = Array.from(new Uint8Array(data, 0, length));
         } else {
            const tmp = new Uint8Array(length);
            tmp.set(new Uint8Array(data));
            streamArray = Array.from(tmp);
         }
      }
      this.pushData(streamArray);
   };

   /**
    * @param {StreamWriter} anotherWriter
    */
   public concat = (anotherWriter) => {
      if (anotherWriter.hasData()) {
         this.pushData(anotherWriter.getArray());
      }
   };

   public getOffset = () => {
      return this.getArray().length;
   };

   public pushData = (data) => {
      this.temp = this.temp.concat(data);
      this.shiftOnCurrentMarker(data.length);
   };

   public alignTo = (size) => {
      if (!this.allowStructAlign) {
         Logger.error("doesn't support bit alignment with struct align disabled");
         return null;
      }
      const activeMarker = this.getActiveMarker();
      if (!activeMarker) {
         Logger.error("failed to find marker, skip alignment");
         return;
      }
      if (size < activeMarker.innerOffset) {
         Logger.warning("overflow detected, skip alignment");
         return;
      }
      const drawUpSize = size - activeMarker.innerOffset;
      const padding = new Array(drawUpSize).fill(0);
      this.pushData(padding);
   };
}
