/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Used at the source part for RX feature, mainly for seamless window
 * beta to reduce the refactor effort.
 *
 * onVDPChannelConnected return a channel with below properties:
 * onReady onDisconnect onInvoke portType.
 *
 * openChannel, open a vvc sub channel, with below properties:
 * onopen, onclose, onerror, send
 *
 *
 * presend itself as VDP channel or vvc channel
 *
 * RTAV: vvc channel, no need to check platfrom since this code
 * only runs on Chrome book for seamless window.
 * this.onSessionConnecting/onSessionConnected/onSessionDisconnected should be called
 * when the remote session status has been changed.
 */

// prepare to accept connections from the window for this session.
import { Inject, Injectable, Optional } from "@angular/core";
import { Port } from "../../../base/model/rx-bridge-type";
import { ConnectedMessageService } from "../../../base/service/connected-message.service";
import { ShareFolderModel } from "../../../../shared/common/model/share-folder-model";
import { RTAVService, DeviceStatus } from "../../../../shared/desktop/rtav/rtav.service";
import { AB } from "../../../../shared/desktop/common/appblast-util.service";
import { Subject, Subscription } from "rxjs";
import { clientUtil, EventBusService, Logger } from "@html-core";
import { GeolocationNotification } from "../../../../shared/common/service/geolocation-notification";
import { BusEvent } from "../../../../core/services/event/common-event.message";
import { WindowToken } from "../../../../shared/common/window.module";
import { AutoFowardUSBService } from "../../../../shared/launcher/auto-usb-setting/auto-usb-foward.service";
import { ConnectionServerModel } from "../../../../shared/common/model/connection-server-model";
import { GoogleCommonSettings } from "../../server-connect/google-common-settings.service";

@Injectable()
export class RXBridgeSource {
   private onRTAVDeviceStatusChanged$: Subject<DeviceStatus>;
   private rtavStatusSubscriptions: Map<string, Subscription>;
   private rtavChannels: Map<string, any> = new Map();
   constructor(
      @Inject(WindowToken) private window: Window,
      private connectedMessageService: ConnectedMessageService,
      private shareFolderModel: ShareFolderModel,
      private eventBusService: EventBusService,
      private autoForwardUsbService: AutoFowardUSBService,
      private connectionServerModel: ConnectionServerModel,
      private googleCommonSetting: GoogleCommonSettings,
      private rtavService: RTAVService,
      @Optional()
      private geolocationNotification: GeolocationNotification
   ) {
      // workaround needed to avoid regression before avoiding other services in root services
      if (!clientUtil.isChromeClient() || this.window.location.href.indexOf("index.html") === -1) {
         Logger.debug("skip constructing RXBridgeSource");
         return;
      }
      Logger.debug("constructing RXBridgeSource");
      this.onRTAVDeviceStatusChanged$ = new Subject<DeviceStatus>();
      this.rtavService.init();
      const ABInstance = new AB(this.rtavService);
      this.rtavService.onDeviceStatusChanged(() => {
         const deviceStatus = AB.getRTAVDeviceStatus();
         this.onRTAVDeviceStatusChanged$.next(deviceStatus);
      });
      this.rtavStatusSubscriptions = new Map<string, Subscription>();
   }

   private initRTAV = (sessionKey) => {
      const id = sessionKey,
         portType = Port.ChannelName.RTAV;

      this.connectedMessageService.onPeerOpen(id, portType, () => {
         Logger.info("peerOpened :" + id + portType);
         const rtavChannel: any = {
            send: (msg) => {
               const messageArray = Array.from(msg);
               Logger.trace("sending reponse: " + id + portType + JSON.stringify(messageArray));
               this.connectedMessageService.sendMessage(id, portType, {
                  type: Port.RTAVMsg.ChannelResponse,
                  content: messageArray
               });
            }
         };
         this.rtavChannels.set(id, rtavChannel);
         Logger.debug("resigter rtav listener for :" + id + portType);
         this.connectedMessageService.onMessage(id, portType, this._rtavOnMessageHandler);

         const remoteVVCSession = {
            openChannel: () => {
               return rtavChannel;
            },
            send: (channel, bytes) => {
               channel.send(bytes);
            }
         };

         const subscription = this.getRTAVStatusHandler(sessionKey, (status) => {
            this.connectedMessageService.sendMessage(id, portType, {
               type: Port.RTAVMsg.DeviceStatus,
               content: status
            });
            Logger.info("RTAV device status for " + id + " :" + JSON.stringify(status));
         });
         if (subscription) {
            this.rtavStatusSubscriptions.set(sessionKey, subscription);
         }
         this.rtavService.onConnecting(sessionKey, remoteVVCSession);
      });
   };

   private _rtavOnMessageHandler = (msg) => {
      const rtavChannel = this.rtavChannels.get(msg.wmksKey);
      if (msg.type === Port.RTAVMsg.ChannelOpen) {
         if (typeof rtavChannel.onopen === "function") {
            rtavChannel.onopen(msg);
         } else {
            Logger.warning("onopen is not defined");
         }
      } else if (msg.type === Port.RTAVMsg.ChannelClose) {
         if (typeof rtavChannel.onclose === "function") {
            rtavChannel.onclose(msg);
         } else {
            Logger.warning("onclose is not defined");
         }
      } else if (msg.type === Port.RTAVMsg.ChannelError) {
         if (typeof rtavChannel.onerror === "function") {
            rtavChannel.onerror(msg);
         } else {
            Logger.warning("onerror is not defined");
         }
      } else if (msg.type === Port.RTAVMsg.ChannelMessage) {
         if (typeof rtavChannel.onmessage === "function") {
            msg.content.data = new Uint8Array(msg.content.data).buffer;
            rtavChannel.onmessage(msg.content);
         } else {
            Logger.warning("onmessage is not defined");
         }
      } else {
         Logger.error("unknown rtav received by rxBridgeSource" + msg);
      }
   };

   // for non-kiosk mode
   private getRTAVStatusHandler = (sessionKey: string, sendFunction) => {
      if (!this.window.chromeClient?.useRTAVChannel) {
         Logger.error("RTAV device status change is detected for kiosk mode");
         return null;
      }
      return this.onRTAVDeviceStatusChanged$.subscribe((deviceStatus) => {
         const status = {
            audio: false,
            video: false
         };
         if (!!deviceStatus && !!deviceStatus.activeSessionId && deviceStatus.activeSessionId === sessionKey) {
            status.audio = deviceStatus.deviceInUsed.audio;
            status.video = deviceStatus.deviceInUsed.video;
         }
         sendFunction(status);
      });
   };

   private initUsbRxBridgeChannel = (sessionKey) => {
      const id = sessionKey,
         portType = Port.ChannelName.USB;
      this.connectedMessageService.onPeerOpen(id, portType, () => {
         Logger.info("peerOpened : id = " + id + " port = " + portType);
         Logger.debug("register usb redirection listener for : " + id + "(" + portType + ")");
      });
      this.autoForwardUsbService.updateFocusSession(sessionKey);
      this.connectedMessageService.onMessage(sessionKey, portType, this._usbOnMessageHandler);
   };

   private _usbOnMessageHandler = (msg) => {
      let wmksKey: string = "";
      if (msg && msg.content && msg.content.wmksKey) {
         wmksKey = msg.content.wmksKey;
      }
      switch (msg.type) {
         case Port.USBMsg.DeviceChanged: {
            const isRedirected = msg.content.status;
            const device = msg.content.device;
            Logger.info("usb device changed: " + isRedirected);
            this.autoForwardUsbService.updateRedirectedMap(wmksKey, device, isRedirected);
            break;
         }
         case Port.USBMsg.isDeviceRedirected: {
            const res = this.autoForwardUsbService.isDeviceRedirected(msg.content.device);
            this.connectedMessageService.sendMessage(wmksKey, Port.ChannelName.USB, {
               type: Port.USBMsg.isDeviceRedirected,
               content: {
                  device: msg.content.device,
                  isRedirected: res
               }
            });
            break;
         }
         case Port.USBMsg.onUsbConnected: {
            //Sync auto-redirected device
            const name = msg.content.name;
            const isAutoUsbEnabled = this.autoForwardUsbService.getKillSwitch();
            if (isAutoUsbEnabled) {
               this.autoForwardUsbService.updatePolicy();
               this.autoForwardUsbService.getAutoConnectDeviceList(name).then((devicesList) => {
                  this.connectedMessageService.sendMessage(wmksKey, Port.ChannelName.USB, {
                     type: Port.USBMsg.getRedirectedDevices,
                     content: {
                        devicesList: devicesList
                     }
                  });
               });
            }
            //Sync device google policy usb list
            let deviceRulePolicy = {
               allowedList: new Array<any>(),
               blockedList: new Array<any>()
            };
            let splitRulePolicy = {
               allowedSplitDevices: new Array<any>(),
               blockedSplitDevices: new Array<any>()
            };
            deviceRulePolicy = this._getUsbRuleFromPolicy();
            splitRulePolicy = this.googleCommonSetting.getSplitUsbSetting();
            this.connectedMessageService.sendMessage(wmksKey, Port.ChannelName.USB, {
               type: Port.USBMsg.policyDeviceSync,
               content: {
                  allowedDevices: deviceRulePolicy.allowedList,
                  blockedDevices: deviceRulePolicy.blockedList,
                  allowedSplitDevices: splitRulePolicy.allowedSplitDevices,
                  blockedSplitDevices: splitRulePolicy.blockedSplitDevices
               }
            });
            break;
         }
         default:
            Logger.info("unsupported usb msg: " + msg.type);
            break;
      }
   };

   private _getUsbRuleFromPolicy = () => {
      const host = this.connectionServerModel.host;
      const goolgeServerPolicy = this.connectionServerModel.googleAdminSettings;
      let allowedList = new Array<any>(),
         blockedList = new Array<any>();
      if (goolgeServerPolicy) {
         for (let i = 0; i < goolgeServerPolicy.length; i++) {
            if (goolgeServerPolicy[i].address === host && !!goolgeServerPolicy[i].settings) {
               if (goolgeServerPolicy[i].settings.usbAllowList) {
                  allowedList = goolgeServerPolicy[i].settings.usbAllowList;
               }
               if (goolgeServerPolicy[i].settings.usbBlockList) {
                  blockedList = goolgeServerPolicy[i].settings.usbBlockList;
               }
               break;
            }
         }
      }
      return {
         allowedList: allowedList,
         blockedList: blockedList
      };
   };

   private initCDR = (sessionKey) => {
      const id = sessionKey,
         portType = Port.ChannelName.CDR;
      this.connectedMessageService.onPeerOpen(id, portType, () => {
         Logger.info("peerOpened : id = " + id + " port = " + portType);
         Logger.debug("register cdr listener for : " + id + "(" + portType + ")");
         this.connectedMessageService.onMessage(id, portType, this._cdrOnMessageHandler);
         this.eventBusService.listen("newFileInfoFromFA").subscribe((msg) => {
            this.connectedMessageService.sendMessage(id, portType, {
               type: Port.CDRMsg.newFolderFromFA
            });
         });
      });
   };

   private _cdrOnMessageHandler = (msg) => {
      switch (msg.type) {
         case Port.CDRMsg.requestFolderInfos: {
            const id = msg.wmksKey;
            Logger.info("start CDR for " + id);
            this.shareFolderModel.export().then((folderInfo) => {
               this.connectedMessageService.sendMessage(id, Port.ChannelName.CDR, {
                  type: Port.CDRMsg.AccessibleFolderInfos,
                  content: folderInfo
               });
               Logger.debug("syncing cdr folder info, " + JSON.stringify(folderInfo));
            });
            break;
         }
         case Port.CDRMsg.updateFolderInfos:
            Logger.info("restoring info, " + JSON.stringify(msg.content));
            this.shareFolderModel.restore(msg.content);
            break;
         default:
            Logger.error("Unknown cdr meesage received by rxBridgeSource" + msg);
      }
   };

   public onSessionConnecting = (sessionKey) => {
      Logger.debug("blast session connecting: " + sessionKey);
      if (this.window.chromeClient?.useRTAVChannel) {
         this.initRTAV(sessionKey);
      }
      this.initCDR(sessionKey);
      this.initUsbRxBridgeChannel(sessionKey);
      if (this.geolocationNotification) {
         this.showGeoLocationPopup(sessionKey);
      }
   };

   public onSessionConnected = (sessionKey) => {
      Logger.debug("blast session connected: " + sessionKey);
      Logger.info("start RTAV for: " + sessionKey);
      if (this.window.chromeClient?.useRTAVChannel) {
         this.rtavService.onConnected(sessionKey);
      }
   };

   //TODO, need also bind to window close event
   public onSessionDisconnected = (sessionKey) => {
      Logger.debug("blast session disconnected: " + sessionKey);
      Logger.info("stop RTAV for: " + sessionKey);
      if (!!this.window.chromeClient?.useRTAVChannel && this.rtavService.hasSession(sessionKey)) {
         this.rtavService.onDisconnected(sessionKey);
      }
      const subscription = this.rtavStatusSubscriptions.get(sessionKey);
      if (subscription) {
         subscription.unsubscribe();
      }
      this.rtavStatusSubscriptions.delete(sessionKey);
      this.autoForwardUsbService.updateRedirectedMapOnSessionClosed(sessionKey);
   };

   public onMessage = (sessionKey, portType, callback) => {
      Logger.trace("blast session message: " + sessionKey + portType);
      if (!(portType in Port.MsgType)) {
         Logger.error("invalid rxBridge portType" + portType);
         return;
      }
      this.connectedMessageService.onMessage(sessionKey, portType, callback);
   };

   public invoke = (sessionKey, portType, params) => {
      if (!(portType in Port.MsgType)) {
         Logger.error("invalid rxBridge portType" + portType);
         return;
      }
      this.connectedMessageService.sendMessage(sessionKey, portType, params);
   };

   private showGeoLocationPopup = (sessionKey: string) => {
      //Show geo notification dialog on top of the main window, for remote app session.
      this.connectedMessageService.onMessage(sessionKey, "geoPermissionPopup", this._geoLocationOnMessageHandler);
   };

   private _geoLocationOnMessageHandler = (msg) => {
      if (msg.type === "showGeoPermissionPopup") {
         const wmksKey = msg.wmksKey;
         Logger.info(
            "showGeoPermissionPopup message came to session-management, show the geo pop-up",
            Logger.GEOLOCATION
         );
         if (chrome.app.window.current() !== null) {
            Logger.info("Set focus to main window, so that geo notification pop-up will come on top of it.");
            chrome.app.window.current().focus();
         }
         this.geolocationNotification
            .showGeolocationPermissionPopup()
            .then(() => {
               Logger.info(
                  "Geo permission granted from geo pop-up, send geoPermissionAllowed message",
                  Logger.GEOLOCATION
               );
               this.connectedMessageService.sendMessage(wmksKey, "geoPermissionPopup", {
                  type: "geoPermissionAllowed",
                  content: {
                     sessionKey: wmksKey
                  }
               });
               setTimeout(() => {
                  Logger.info(
                     "Sending the event FocusOnRemoteApp to restore the minimised remoteapp.",
                     Logger.GEOLOCATION
                  );
                  this.eventBusService.dispatch(new BusEvent.FocusOnRemoteApp(wmksKey));
               }, 1000);
            })
            .catch((e) => {
               setTimeout(() => {
                  Logger.info(
                     "Sending the event FocusOnRemoteApp to restore the minimised remoteapp.",
                     Logger.GEOLOCATION
                  );
                  this.eventBusService.dispatch(new BusEvent.FocusOnRemoteApp(wmksKey));
               }, 1000);
               Logger.info("Geo permission denied from geo pop-up", Logger.GEOLOCATION);
            });
      }
   };
}
