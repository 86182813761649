/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.RTAV = (function() {
    
        /**
         * Namespace RTAV.
         * @exports RTAV
         * @namespace
         */
        var RTAV = {};
    
        RTAV.VideoDeviceResolution = (function() {
    
            /**
             * Properties of a VideoDeviceResolution.
             * @memberof RTAV
             * @interface IVideoDeviceResolution
             * @property {number|null} [width] VideoDeviceResolution width
             * @property {number|null} [height] VideoDeviceResolution height
             */
    
            /**
             * Constructs a new VideoDeviceResolution.
             * @memberof RTAV
             * @classdesc Represents a VideoDeviceResolution.
             * @implements IVideoDeviceResolution
             * @constructor
             * @param {RTAV.IVideoDeviceResolution=} [properties] Properties to set
             */
            function VideoDeviceResolution(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VideoDeviceResolution width.
             * @member {number} width
             * @memberof RTAV.VideoDeviceResolution
             * @instance
             */
            VideoDeviceResolution.prototype.width = 0;
    
            /**
             * VideoDeviceResolution height.
             * @member {number} height
             * @memberof RTAV.VideoDeviceResolution
             * @instance
             */
            VideoDeviceResolution.prototype.height = 0;
    
            /**
             * Creates a new VideoDeviceResolution instance using the specified properties.
             * @function create
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {RTAV.IVideoDeviceResolution=} [properties] Properties to set
             * @returns {RTAV.VideoDeviceResolution} VideoDeviceResolution instance
             */
            VideoDeviceResolution.create = function create(properties) {
                return new VideoDeviceResolution(properties);
            };
    
            /**
             * Encodes the specified VideoDeviceResolution message. Does not implicitly {@link RTAV.VideoDeviceResolution.verify|verify} messages.
             * @function encode
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {RTAV.IVideoDeviceResolution} message VideoDeviceResolution message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDeviceResolution.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.width);
                if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.height);
                return writer;
            };
    
            /**
             * Encodes the specified VideoDeviceResolution message, length delimited. Does not implicitly {@link RTAV.VideoDeviceResolution.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {RTAV.IVideoDeviceResolution} message VideoDeviceResolution message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDeviceResolution.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VideoDeviceResolution message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.VideoDeviceResolution} VideoDeviceResolution
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDeviceResolution.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.VideoDeviceResolution();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.width = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.height = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VideoDeviceResolution message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.VideoDeviceResolution} VideoDeviceResolution
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDeviceResolution.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VideoDeviceResolution message.
             * @function verify
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VideoDeviceResolution.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.width != null && message.hasOwnProperty("width"))
                    if (!$util.isInteger(message.width))
                        return "width: integer expected";
                if (message.height != null && message.hasOwnProperty("height"))
                    if (!$util.isInteger(message.height))
                        return "height: integer expected";
                return null;
            };
    
            /**
             * Creates a VideoDeviceResolution message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.VideoDeviceResolution} VideoDeviceResolution
             */
            VideoDeviceResolution.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.VideoDeviceResolution)
                    return object;
                var message = new $root.RTAV.VideoDeviceResolution();
                if (object.width != null)
                    message.width = object.width >>> 0;
                if (object.height != null)
                    message.height = object.height >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a VideoDeviceResolution message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {RTAV.VideoDeviceResolution} message VideoDeviceResolution
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VideoDeviceResolution.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.width = 0;
                    object.height = 0;
                }
                if (message.width != null && message.hasOwnProperty("width"))
                    object.width = message.width;
                if (message.height != null && message.hasOwnProperty("height"))
                    object.height = message.height;
                return object;
            };
    
            /**
             * Converts this VideoDeviceResolution to JSON.
             * @function toJSON
             * @memberof RTAV.VideoDeviceResolution
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VideoDeviceResolution.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VideoDeviceResolution
             * @function getTypeUrl
             * @memberof RTAV.VideoDeviceResolution
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VideoDeviceResolution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.VideoDeviceResolution";
            };
    
            return VideoDeviceResolution;
        })();
    
        RTAV.VideoDeviceCaps = (function() {
    
            /**
             * Properties of a VideoDeviceCaps.
             * @memberof RTAV
             * @interface IVideoDeviceCaps
             * @property {number|null} [resolutionCount] VideoDeviceCaps resolutionCount
             * @property {Array.<RTAV.IVideoDeviceResolution>|null} [resolution] VideoDeviceCaps resolution
             */
    
            /**
             * Constructs a new VideoDeviceCaps.
             * @memberof RTAV
             * @classdesc Represents a VideoDeviceCaps.
             * @implements IVideoDeviceCaps
             * @constructor
             * @param {RTAV.IVideoDeviceCaps=} [properties] Properties to set
             */
            function VideoDeviceCaps(properties) {
                this.resolution = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VideoDeviceCaps resolutionCount.
             * @member {number} resolutionCount
             * @memberof RTAV.VideoDeviceCaps
             * @instance
             */
            VideoDeviceCaps.prototype.resolutionCount = 0;
    
            /**
             * VideoDeviceCaps resolution.
             * @member {Array.<RTAV.IVideoDeviceResolution>} resolution
             * @memberof RTAV.VideoDeviceCaps
             * @instance
             */
            VideoDeviceCaps.prototype.resolution = $util.emptyArray;
    
            /**
             * Creates a new VideoDeviceCaps instance using the specified properties.
             * @function create
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {RTAV.IVideoDeviceCaps=} [properties] Properties to set
             * @returns {RTAV.VideoDeviceCaps} VideoDeviceCaps instance
             */
            VideoDeviceCaps.create = function create(properties) {
                return new VideoDeviceCaps(properties);
            };
    
            /**
             * Encodes the specified VideoDeviceCaps message. Does not implicitly {@link RTAV.VideoDeviceCaps.verify|verify} messages.
             * @function encode
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {RTAV.IVideoDeviceCaps} message VideoDeviceCaps message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDeviceCaps.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.resolutionCount != null && Object.hasOwnProperty.call(message, "resolutionCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.resolutionCount);
                if (message.resolution != null && message.resolution.length)
                    for (var i = 0; i < message.resolution.length; ++i)
                        $root.RTAV.VideoDeviceResolution.encode(message.resolution[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified VideoDeviceCaps message, length delimited. Does not implicitly {@link RTAV.VideoDeviceCaps.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {RTAV.IVideoDeviceCaps} message VideoDeviceCaps message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDeviceCaps.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VideoDeviceCaps message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.VideoDeviceCaps} VideoDeviceCaps
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDeviceCaps.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.VideoDeviceCaps();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.resolutionCount = reader.uint32();
                            break;
                        }
                    case 2: {
                            if (!(message.resolution && message.resolution.length))
                                message.resolution = [];
                            message.resolution.push($root.RTAV.VideoDeviceResolution.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VideoDeviceCaps message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.VideoDeviceCaps} VideoDeviceCaps
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDeviceCaps.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VideoDeviceCaps message.
             * @function verify
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VideoDeviceCaps.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.resolutionCount != null && message.hasOwnProperty("resolutionCount"))
                    if (!$util.isInteger(message.resolutionCount))
                        return "resolutionCount: integer expected";
                if (message.resolution != null && message.hasOwnProperty("resolution")) {
                    if (!Array.isArray(message.resolution))
                        return "resolution: array expected";
                    for (var i = 0; i < message.resolution.length; ++i) {
                        var error = $root.RTAV.VideoDeviceResolution.verify(message.resolution[i]);
                        if (error)
                            return "resolution." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a VideoDeviceCaps message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.VideoDeviceCaps} VideoDeviceCaps
             */
            VideoDeviceCaps.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.VideoDeviceCaps)
                    return object;
                var message = new $root.RTAV.VideoDeviceCaps();
                if (object.resolutionCount != null)
                    message.resolutionCount = object.resolutionCount >>> 0;
                if (object.resolution) {
                    if (!Array.isArray(object.resolution))
                        throw TypeError(".RTAV.VideoDeviceCaps.resolution: array expected");
                    message.resolution = [];
                    for (var i = 0; i < object.resolution.length; ++i) {
                        if (typeof object.resolution[i] !== "object")
                            throw TypeError(".RTAV.VideoDeviceCaps.resolution: object expected");
                        message.resolution[i] = $root.RTAV.VideoDeviceResolution.fromObject(object.resolution[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a VideoDeviceCaps message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {RTAV.VideoDeviceCaps} message VideoDeviceCaps
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VideoDeviceCaps.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.resolution = [];
                if (options.defaults)
                    object.resolutionCount = 0;
                if (message.resolutionCount != null && message.hasOwnProperty("resolutionCount"))
                    object.resolutionCount = message.resolutionCount;
                if (message.resolution && message.resolution.length) {
                    object.resolution = [];
                    for (var j = 0; j < message.resolution.length; ++j)
                        object.resolution[j] = $root.RTAV.VideoDeviceResolution.toObject(message.resolution[j], options);
                }
                return object;
            };
    
            /**
             * Converts this VideoDeviceCaps to JSON.
             * @function toJSON
             * @memberof RTAV.VideoDeviceCaps
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VideoDeviceCaps.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VideoDeviceCaps
             * @function getTypeUrl
             * @memberof RTAV.VideoDeviceCaps
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VideoDeviceCaps.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.VideoDeviceCaps";
            };
    
            return VideoDeviceCaps;
        })();
    
        RTAV.VideoDevice = (function() {
    
            /**
             * Properties of a VideoDevice.
             * @memberof RTAV
             * @interface IVideoDevice
             * @property {number|null} [deviceIndex] VideoDevice deviceIndex
             * @property {string|null} [friendlyName] VideoDevice friendlyName
             * @property {string|null} [uniqueId] VideoDevice uniqueId
             * @property {RTAV.IVideoDeviceCaps|null} [caps] VideoDevice caps
             */
    
            /**
             * Constructs a new VideoDevice.
             * @memberof RTAV
             * @classdesc Represents a VideoDevice.
             * @implements IVideoDevice
             * @constructor
             * @param {RTAV.IVideoDevice=} [properties] Properties to set
             */
            function VideoDevice(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VideoDevice deviceIndex.
             * @member {number} deviceIndex
             * @memberof RTAV.VideoDevice
             * @instance
             */
            VideoDevice.prototype.deviceIndex = 0;
    
            /**
             * VideoDevice friendlyName.
             * @member {string} friendlyName
             * @memberof RTAV.VideoDevice
             * @instance
             */
            VideoDevice.prototype.friendlyName = "";
    
            /**
             * VideoDevice uniqueId.
             * @member {string} uniqueId
             * @memberof RTAV.VideoDevice
             * @instance
             */
            VideoDevice.prototype.uniqueId = "";
    
            /**
             * VideoDevice caps.
             * @member {RTAV.IVideoDeviceCaps|null|undefined} caps
             * @memberof RTAV.VideoDevice
             * @instance
             */
            VideoDevice.prototype.caps = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * VideoDevice _caps.
             * @member {"caps"|undefined} _caps
             * @memberof RTAV.VideoDevice
             * @instance
             */
            Object.defineProperty(VideoDevice.prototype, "_caps", {
                get: $util.oneOfGetter($oneOfFields = ["caps"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new VideoDevice instance using the specified properties.
             * @function create
             * @memberof RTAV.VideoDevice
             * @static
             * @param {RTAV.IVideoDevice=} [properties] Properties to set
             * @returns {RTAV.VideoDevice} VideoDevice instance
             */
            VideoDevice.create = function create(properties) {
                return new VideoDevice(properties);
            };
    
            /**
             * Encodes the specified VideoDevice message. Does not implicitly {@link RTAV.VideoDevice.verify|verify} messages.
             * @function encode
             * @memberof RTAV.VideoDevice
             * @static
             * @param {RTAV.IVideoDevice} message VideoDevice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDevice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deviceIndex != null && Object.hasOwnProperty.call(message, "deviceIndex"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.deviceIndex);
                if (message.friendlyName != null && Object.hasOwnProperty.call(message, "friendlyName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.friendlyName);
                if (message.uniqueId != null && Object.hasOwnProperty.call(message, "uniqueId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.uniqueId);
                if (message.caps != null && Object.hasOwnProperty.call(message, "caps"))
                    $root.RTAV.VideoDeviceCaps.encode(message.caps, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified VideoDevice message, length delimited. Does not implicitly {@link RTAV.VideoDevice.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.VideoDevice
             * @static
             * @param {RTAV.IVideoDevice} message VideoDevice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDevice.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VideoDevice message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.VideoDevice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.VideoDevice} VideoDevice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDevice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.VideoDevice();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deviceIndex = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.friendlyName = reader.string();
                            break;
                        }
                    case 3: {
                            message.uniqueId = reader.string();
                            break;
                        }
                    case 4: {
                            message.caps = $root.RTAV.VideoDeviceCaps.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VideoDevice message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.VideoDevice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.VideoDevice} VideoDevice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDevice.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VideoDevice message.
             * @function verify
             * @memberof RTAV.VideoDevice
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VideoDevice.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.deviceIndex != null && message.hasOwnProperty("deviceIndex"))
                    if (!$util.isInteger(message.deviceIndex))
                        return "deviceIndex: integer expected";
                if (message.friendlyName != null && message.hasOwnProperty("friendlyName"))
                    if (!$util.isString(message.friendlyName))
                        return "friendlyName: string expected";
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    if (!$util.isString(message.uniqueId))
                        return "uniqueId: string expected";
                if (message.caps != null && message.hasOwnProperty("caps")) {
                    properties._caps = 1;
                    {
                        var error = $root.RTAV.VideoDeviceCaps.verify(message.caps);
                        if (error)
                            return "caps." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a VideoDevice message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.VideoDevice
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.VideoDevice} VideoDevice
             */
            VideoDevice.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.VideoDevice)
                    return object;
                var message = new $root.RTAV.VideoDevice();
                if (object.deviceIndex != null)
                    message.deviceIndex = object.deviceIndex >>> 0;
                if (object.friendlyName != null)
                    message.friendlyName = String(object.friendlyName);
                if (object.uniqueId != null)
                    message.uniqueId = String(object.uniqueId);
                if (object.caps != null) {
                    if (typeof object.caps !== "object")
                        throw TypeError(".RTAV.VideoDevice.caps: object expected");
                    message.caps = $root.RTAV.VideoDeviceCaps.fromObject(object.caps);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a VideoDevice message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.VideoDevice
             * @static
             * @param {RTAV.VideoDevice} message VideoDevice
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VideoDevice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.deviceIndex = 0;
                    object.friendlyName = "";
                    object.uniqueId = "";
                }
                if (message.deviceIndex != null && message.hasOwnProperty("deviceIndex"))
                    object.deviceIndex = message.deviceIndex;
                if (message.friendlyName != null && message.hasOwnProperty("friendlyName"))
                    object.friendlyName = message.friendlyName;
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    object.uniqueId = message.uniqueId;
                if (message.caps != null && message.hasOwnProperty("caps")) {
                    object.caps = $root.RTAV.VideoDeviceCaps.toObject(message.caps, options);
                    if (options.oneofs)
                        object._caps = "caps";
                }
                return object;
            };
    
            /**
             * Converts this VideoDevice to JSON.
             * @function toJSON
             * @memberof RTAV.VideoDevice
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VideoDevice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VideoDevice
             * @function getTypeUrl
             * @memberof RTAV.VideoDevice
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VideoDevice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.VideoDevice";
            };
    
            return VideoDevice;
        })();
    
        RTAV.VideoDeviceList = (function() {
    
            /**
             * Properties of a VideoDeviceList.
             * @memberof RTAV
             * @interface IVideoDeviceList
             * @property {number|null} [count] VideoDeviceList count
             * @property {Array.<RTAV.IVideoDevice>|null} [devices] VideoDeviceList devices
             */
    
            /**
             * Constructs a new VideoDeviceList.
             * @memberof RTAV
             * @classdesc Represents a VideoDeviceList.
             * @implements IVideoDeviceList
             * @constructor
             * @param {RTAV.IVideoDeviceList=} [properties] Properties to set
             */
            function VideoDeviceList(properties) {
                this.devices = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VideoDeviceList count.
             * @member {number} count
             * @memberof RTAV.VideoDeviceList
             * @instance
             */
            VideoDeviceList.prototype.count = 0;
    
            /**
             * VideoDeviceList devices.
             * @member {Array.<RTAV.IVideoDevice>} devices
             * @memberof RTAV.VideoDeviceList
             * @instance
             */
            VideoDeviceList.prototype.devices = $util.emptyArray;
    
            /**
             * Creates a new VideoDeviceList instance using the specified properties.
             * @function create
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {RTAV.IVideoDeviceList=} [properties] Properties to set
             * @returns {RTAV.VideoDeviceList} VideoDeviceList instance
             */
            VideoDeviceList.create = function create(properties) {
                return new VideoDeviceList(properties);
            };
    
            /**
             * Encodes the specified VideoDeviceList message. Does not implicitly {@link RTAV.VideoDeviceList.verify|verify} messages.
             * @function encode
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {RTAV.IVideoDeviceList} message VideoDeviceList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDeviceList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.count);
                if (message.devices != null && message.devices.length)
                    for (var i = 0; i < message.devices.length; ++i)
                        $root.RTAV.VideoDevice.encode(message.devices[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified VideoDeviceList message, length delimited. Does not implicitly {@link RTAV.VideoDeviceList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {RTAV.IVideoDeviceList} message VideoDeviceList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDeviceList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VideoDeviceList message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.VideoDeviceList} VideoDeviceList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDeviceList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.VideoDeviceList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint32();
                            break;
                        }
                    case 2: {
                            if (!(message.devices && message.devices.length))
                                message.devices = [];
                            message.devices.push($root.RTAV.VideoDevice.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VideoDeviceList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.VideoDeviceList} VideoDeviceList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDeviceList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VideoDeviceList message.
             * @function verify
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VideoDeviceList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.devices != null && message.hasOwnProperty("devices")) {
                    if (!Array.isArray(message.devices))
                        return "devices: array expected";
                    for (var i = 0; i < message.devices.length; ++i) {
                        var error = $root.RTAV.VideoDevice.verify(message.devices[i]);
                        if (error)
                            return "devices." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a VideoDeviceList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.VideoDeviceList} VideoDeviceList
             */
            VideoDeviceList.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.VideoDeviceList)
                    return object;
                var message = new $root.RTAV.VideoDeviceList();
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.devices) {
                    if (!Array.isArray(object.devices))
                        throw TypeError(".RTAV.VideoDeviceList.devices: array expected");
                    message.devices = [];
                    for (var i = 0; i < object.devices.length; ++i) {
                        if (typeof object.devices[i] !== "object")
                            throw TypeError(".RTAV.VideoDeviceList.devices: object expected");
                        message.devices[i] = $root.RTAV.VideoDevice.fromObject(object.devices[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a VideoDeviceList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {RTAV.VideoDeviceList} message VideoDeviceList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VideoDeviceList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.devices = [];
                if (options.defaults)
                    object.count = 0;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.devices && message.devices.length) {
                    object.devices = [];
                    for (var j = 0; j < message.devices.length; ++j)
                        object.devices[j] = $root.RTAV.VideoDevice.toObject(message.devices[j], options);
                }
                return object;
            };
    
            /**
             * Converts this VideoDeviceList to JSON.
             * @function toJSON
             * @memberof RTAV.VideoDeviceList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VideoDeviceList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VideoDeviceList
             * @function getTypeUrl
             * @memberof RTAV.VideoDeviceList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VideoDeviceList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.VideoDeviceList";
            };
    
            return VideoDeviceList;
        })();
    
        RTAV.AudioDevice = (function() {
    
            /**
             * Properties of an AudioDevice.
             * @memberof RTAV
             * @interface IAudioDevice
             * @property {number|null} [deviceIndex] AudioDevice deviceIndex
             * @property {string|null} [friendlyName] AudioDevice friendlyName
             * @property {string|null} [uniqueId] AudioDevice uniqueId
             * @property {number|null} [roles] AudioDevice roles
             */
    
            /**
             * Constructs a new AudioDevice.
             * @memberof RTAV
             * @classdesc Represents an AudioDevice.
             * @implements IAudioDevice
             * @constructor
             * @param {RTAV.IAudioDevice=} [properties] Properties to set
             */
            function AudioDevice(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudioDevice deviceIndex.
             * @member {number} deviceIndex
             * @memberof RTAV.AudioDevice
             * @instance
             */
            AudioDevice.prototype.deviceIndex = 0;
    
            /**
             * AudioDevice friendlyName.
             * @member {string} friendlyName
             * @memberof RTAV.AudioDevice
             * @instance
             */
            AudioDevice.prototype.friendlyName = "";
    
            /**
             * AudioDevice uniqueId.
             * @member {string} uniqueId
             * @memberof RTAV.AudioDevice
             * @instance
             */
            AudioDevice.prototype.uniqueId = "";
    
            /**
             * AudioDevice roles.
             * @member {number} roles
             * @memberof RTAV.AudioDevice
             * @instance
             */
            AudioDevice.prototype.roles = 0;
    
            /**
             * Creates a new AudioDevice instance using the specified properties.
             * @function create
             * @memberof RTAV.AudioDevice
             * @static
             * @param {RTAV.IAudioDevice=} [properties] Properties to set
             * @returns {RTAV.AudioDevice} AudioDevice instance
             */
            AudioDevice.create = function create(properties) {
                return new AudioDevice(properties);
            };
    
            /**
             * Encodes the specified AudioDevice message. Does not implicitly {@link RTAV.AudioDevice.verify|verify} messages.
             * @function encode
             * @memberof RTAV.AudioDevice
             * @static
             * @param {RTAV.IAudioDevice} message AudioDevice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDevice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deviceIndex != null && Object.hasOwnProperty.call(message, "deviceIndex"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.deviceIndex);
                if (message.friendlyName != null && Object.hasOwnProperty.call(message, "friendlyName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.friendlyName);
                if (message.uniqueId != null && Object.hasOwnProperty.call(message, "uniqueId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.uniqueId);
                if (message.roles != null && Object.hasOwnProperty.call(message, "roles"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.roles);
                return writer;
            };
    
            /**
             * Encodes the specified AudioDevice message, length delimited. Does not implicitly {@link RTAV.AudioDevice.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.AudioDevice
             * @static
             * @param {RTAV.IAudioDevice} message AudioDevice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDevice.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudioDevice message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.AudioDevice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.AudioDevice} AudioDevice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDevice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.AudioDevice();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deviceIndex = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.friendlyName = reader.string();
                            break;
                        }
                    case 3: {
                            message.uniqueId = reader.string();
                            break;
                        }
                    case 4: {
                            message.roles = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudioDevice message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.AudioDevice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.AudioDevice} AudioDevice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDevice.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudioDevice message.
             * @function verify
             * @memberof RTAV.AudioDevice
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudioDevice.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deviceIndex != null && message.hasOwnProperty("deviceIndex"))
                    if (!$util.isInteger(message.deviceIndex))
                        return "deviceIndex: integer expected";
                if (message.friendlyName != null && message.hasOwnProperty("friendlyName"))
                    if (!$util.isString(message.friendlyName))
                        return "friendlyName: string expected";
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    if (!$util.isString(message.uniqueId))
                        return "uniqueId: string expected";
                if (message.roles != null && message.hasOwnProperty("roles"))
                    if (!$util.isInteger(message.roles))
                        return "roles: integer expected";
                return null;
            };
    
            /**
             * Creates an AudioDevice message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.AudioDevice
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.AudioDevice} AudioDevice
             */
            AudioDevice.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.AudioDevice)
                    return object;
                var message = new $root.RTAV.AudioDevice();
                if (object.deviceIndex != null)
                    message.deviceIndex = object.deviceIndex >>> 0;
                if (object.friendlyName != null)
                    message.friendlyName = String(object.friendlyName);
                if (object.uniqueId != null)
                    message.uniqueId = String(object.uniqueId);
                if (object.roles != null)
                    message.roles = object.roles >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an AudioDevice message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.AudioDevice
             * @static
             * @param {RTAV.AudioDevice} message AudioDevice
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioDevice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.deviceIndex = 0;
                    object.friendlyName = "";
                    object.uniqueId = "";
                    object.roles = 0;
                }
                if (message.deviceIndex != null && message.hasOwnProperty("deviceIndex"))
                    object.deviceIndex = message.deviceIndex;
                if (message.friendlyName != null && message.hasOwnProperty("friendlyName"))
                    object.friendlyName = message.friendlyName;
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    object.uniqueId = message.uniqueId;
                if (message.roles != null && message.hasOwnProperty("roles"))
                    object.roles = message.roles;
                return object;
            };
    
            /**
             * Converts this AudioDevice to JSON.
             * @function toJSON
             * @memberof RTAV.AudioDevice
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioDevice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudioDevice
             * @function getTypeUrl
             * @memberof RTAV.AudioDevice
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioDevice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.AudioDevice";
            };
    
            return AudioDevice;
        })();
    
        RTAV.AudioDeviceList = (function() {
    
            /**
             * Properties of an AudioDeviceList.
             * @memberof RTAV
             * @interface IAudioDeviceList
             * @property {number|null} [count] AudioDeviceList count
             * @property {Array.<RTAV.IAudioDevice>|null} [devices] AudioDeviceList devices
             */
    
            /**
             * Constructs a new AudioDeviceList.
             * @memberof RTAV
             * @classdesc Represents an AudioDeviceList.
             * @implements IAudioDeviceList
             * @constructor
             * @param {RTAV.IAudioDeviceList=} [properties] Properties to set
             */
            function AudioDeviceList(properties) {
                this.devices = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudioDeviceList count.
             * @member {number} count
             * @memberof RTAV.AudioDeviceList
             * @instance
             */
            AudioDeviceList.prototype.count = 0;
    
            /**
             * AudioDeviceList devices.
             * @member {Array.<RTAV.IAudioDevice>} devices
             * @memberof RTAV.AudioDeviceList
             * @instance
             */
            AudioDeviceList.prototype.devices = $util.emptyArray;
    
            /**
             * Creates a new AudioDeviceList instance using the specified properties.
             * @function create
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {RTAV.IAudioDeviceList=} [properties] Properties to set
             * @returns {RTAV.AudioDeviceList} AudioDeviceList instance
             */
            AudioDeviceList.create = function create(properties) {
                return new AudioDeviceList(properties);
            };
    
            /**
             * Encodes the specified AudioDeviceList message. Does not implicitly {@link RTAV.AudioDeviceList.verify|verify} messages.
             * @function encode
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {RTAV.IAudioDeviceList} message AudioDeviceList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDeviceList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.count);
                if (message.devices != null && message.devices.length)
                    for (var i = 0; i < message.devices.length; ++i)
                        $root.RTAV.AudioDevice.encode(message.devices[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AudioDeviceList message, length delimited. Does not implicitly {@link RTAV.AudioDeviceList.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {RTAV.IAudioDeviceList} message AudioDeviceList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDeviceList.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudioDeviceList message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.AudioDeviceList} AudioDeviceList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDeviceList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.AudioDeviceList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint32();
                            break;
                        }
                    case 2: {
                            if (!(message.devices && message.devices.length))
                                message.devices = [];
                            message.devices.push($root.RTAV.AudioDevice.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudioDeviceList message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.AudioDeviceList} AudioDeviceList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDeviceList.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudioDeviceList message.
             * @function verify
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudioDeviceList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.devices != null && message.hasOwnProperty("devices")) {
                    if (!Array.isArray(message.devices))
                        return "devices: array expected";
                    for (var i = 0; i < message.devices.length; ++i) {
                        var error = $root.RTAV.AudioDevice.verify(message.devices[i]);
                        if (error)
                            return "devices." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates an AudioDeviceList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.AudioDeviceList} AudioDeviceList
             */
            AudioDeviceList.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.AudioDeviceList)
                    return object;
                var message = new $root.RTAV.AudioDeviceList();
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.devices) {
                    if (!Array.isArray(object.devices))
                        throw TypeError(".RTAV.AudioDeviceList.devices: array expected");
                    message.devices = [];
                    for (var i = 0; i < object.devices.length; ++i) {
                        if (typeof object.devices[i] !== "object")
                            throw TypeError(".RTAV.AudioDeviceList.devices: object expected");
                        message.devices[i] = $root.RTAV.AudioDevice.fromObject(object.devices[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AudioDeviceList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {RTAV.AudioDeviceList} message AudioDeviceList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioDeviceList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.devices = [];
                if (options.defaults)
                    object.count = 0;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.devices && message.devices.length) {
                    object.devices = [];
                    for (var j = 0; j < message.devices.length; ++j)
                        object.devices[j] = $root.RTAV.AudioDevice.toObject(message.devices[j], options);
                }
                return object;
            };
    
            /**
             * Converts this AudioDeviceList to JSON.
             * @function toJSON
             * @memberof RTAV.AudioDeviceList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioDeviceList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudioDeviceList
             * @function getTypeUrl
             * @memberof RTAV.AudioDeviceList
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioDeviceList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.AudioDeviceList";
            };
    
            return AudioDeviceList;
        })();
    
        RTAV.AudioDevicePrefs = (function() {
    
            /**
             * Properties of an AudioDevicePrefs.
             * @memberof RTAV
             * @interface IAudioDevicePrefs
             * @property {number|null} [codec] AudioDevicePrefs codec
             * @property {number|null} [frameUnitLenMS] AudioDevicePrefs frameUnitLenMS
             * @property {number|null} [frameUnitCount] AudioDevicePrefs frameUnitCount
             * @property {number|null} [queueLen] AudioDevicePrefs queueLen
             * @property {number|null} [bitsPerSample] AudioDevicePrefs bitsPerSample
             * @property {number|null} [channels] AudioDevicePrefs channels
             * @property {number|null} [inputStreams] AudioDevicePrefs inputStreams
             * @property {number|null} [framesPerPacket] AudioDevicePrefs framesPerPacket
             * @property {number|null} [agentSamplesRate] AudioDevicePrefs agentSamplesRate
             * @property {number|null} [clientSamplesRate] AudioDevicePrefs clientSamplesRate
             */
    
            /**
             * Constructs a new AudioDevicePrefs.
             * @memberof RTAV
             * @classdesc Represents an AudioDevicePrefs.
             * @implements IAudioDevicePrefs
             * @constructor
             * @param {RTAV.IAudioDevicePrefs=} [properties] Properties to set
             */
            function AudioDevicePrefs(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AudioDevicePrefs codec.
             * @member {number} codec
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.codec = 0;
    
            /**
             * AudioDevicePrefs frameUnitLenMS.
             * @member {number} frameUnitLenMS
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.frameUnitLenMS = 0;
    
            /**
             * AudioDevicePrefs frameUnitCount.
             * @member {number} frameUnitCount
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.frameUnitCount = 0;
    
            /**
             * AudioDevicePrefs queueLen.
             * @member {number} queueLen
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.queueLen = 0;
    
            /**
             * AudioDevicePrefs bitsPerSample.
             * @member {number} bitsPerSample
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.bitsPerSample = 0;
    
            /**
             * AudioDevicePrefs channels.
             * @member {number} channels
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.channels = 0;
    
            /**
             * AudioDevicePrefs inputStreams.
             * @member {number} inputStreams
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.inputStreams = 0;
    
            /**
             * AudioDevicePrefs framesPerPacket.
             * @member {number} framesPerPacket
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.framesPerPacket = 0;
    
            /**
             * AudioDevicePrefs agentSamplesRate.
             * @member {number} agentSamplesRate
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.agentSamplesRate = 0;
    
            /**
             * AudioDevicePrefs clientSamplesRate.
             * @member {number} clientSamplesRate
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             */
            AudioDevicePrefs.prototype.clientSamplesRate = 0;
    
            /**
             * Creates a new AudioDevicePrefs instance using the specified properties.
             * @function create
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {RTAV.IAudioDevicePrefs=} [properties] Properties to set
             * @returns {RTAV.AudioDevicePrefs} AudioDevicePrefs instance
             */
            AudioDevicePrefs.create = function create(properties) {
                return new AudioDevicePrefs(properties);
            };
    
            /**
             * Encodes the specified AudioDevicePrefs message. Does not implicitly {@link RTAV.AudioDevicePrefs.verify|verify} messages.
             * @function encode
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {RTAV.IAudioDevicePrefs} message AudioDevicePrefs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDevicePrefs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.codec != null && Object.hasOwnProperty.call(message, "codec"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.codec);
                if (message.frameUnitLenMS != null && Object.hasOwnProperty.call(message, "frameUnitLenMS"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.frameUnitLenMS);
                if (message.frameUnitCount != null && Object.hasOwnProperty.call(message, "frameUnitCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.frameUnitCount);
                if (message.queueLen != null && Object.hasOwnProperty.call(message, "queueLen"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.queueLen);
                if (message.bitsPerSample != null && Object.hasOwnProperty.call(message, "bitsPerSample"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.bitsPerSample);
                if (message.channels != null && Object.hasOwnProperty.call(message, "channels"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.channels);
                if (message.inputStreams != null && Object.hasOwnProperty.call(message, "inputStreams"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.inputStreams);
                if (message.framesPerPacket != null && Object.hasOwnProperty.call(message, "framesPerPacket"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.framesPerPacket);
                if (message.agentSamplesRate != null && Object.hasOwnProperty.call(message, "agentSamplesRate"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.agentSamplesRate);
                if (message.clientSamplesRate != null && Object.hasOwnProperty.call(message, "clientSamplesRate"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.clientSamplesRate);
                return writer;
            };
    
            /**
             * Encodes the specified AudioDevicePrefs message, length delimited. Does not implicitly {@link RTAV.AudioDevicePrefs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {RTAV.IAudioDevicePrefs} message AudioDevicePrefs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AudioDevicePrefs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AudioDevicePrefs message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.AudioDevicePrefs} AudioDevicePrefs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDevicePrefs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.AudioDevicePrefs();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.codec = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.frameUnitLenMS = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.frameUnitCount = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.queueLen = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.bitsPerSample = reader.uint32();
                            break;
                        }
                    case 6: {
                            message.channels = reader.uint32();
                            break;
                        }
                    case 7: {
                            message.inputStreams = reader.uint32();
                            break;
                        }
                    case 8: {
                            message.framesPerPacket = reader.uint32();
                            break;
                        }
                    case 9: {
                            message.agentSamplesRate = reader.uint32();
                            break;
                        }
                    case 10: {
                            message.clientSamplesRate = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AudioDevicePrefs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.AudioDevicePrefs} AudioDevicePrefs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AudioDevicePrefs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AudioDevicePrefs message.
             * @function verify
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AudioDevicePrefs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.codec != null && message.hasOwnProperty("codec"))
                    if (!$util.isInteger(message.codec))
                        return "codec: integer expected";
                if (message.frameUnitLenMS != null && message.hasOwnProperty("frameUnitLenMS"))
                    if (!$util.isInteger(message.frameUnitLenMS))
                        return "frameUnitLenMS: integer expected";
                if (message.frameUnitCount != null && message.hasOwnProperty("frameUnitCount"))
                    if (!$util.isInteger(message.frameUnitCount))
                        return "frameUnitCount: integer expected";
                if (message.queueLen != null && message.hasOwnProperty("queueLen"))
                    if (!$util.isInteger(message.queueLen))
                        return "queueLen: integer expected";
                if (message.bitsPerSample != null && message.hasOwnProperty("bitsPerSample"))
                    if (!$util.isInteger(message.bitsPerSample))
                        return "bitsPerSample: integer expected";
                if (message.channels != null && message.hasOwnProperty("channels"))
                    if (!$util.isInteger(message.channels))
                        return "channels: integer expected";
                if (message.inputStreams != null && message.hasOwnProperty("inputStreams"))
                    if (!$util.isInteger(message.inputStreams))
                        return "inputStreams: integer expected";
                if (message.framesPerPacket != null && message.hasOwnProperty("framesPerPacket"))
                    if (!$util.isInteger(message.framesPerPacket))
                        return "framesPerPacket: integer expected";
                if (message.agentSamplesRate != null && message.hasOwnProperty("agentSamplesRate"))
                    if (!$util.isInteger(message.agentSamplesRate))
                        return "agentSamplesRate: integer expected";
                if (message.clientSamplesRate != null && message.hasOwnProperty("clientSamplesRate"))
                    if (!$util.isInteger(message.clientSamplesRate))
                        return "clientSamplesRate: integer expected";
                return null;
            };
    
            /**
             * Creates an AudioDevicePrefs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.AudioDevicePrefs} AudioDevicePrefs
             */
            AudioDevicePrefs.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.AudioDevicePrefs)
                    return object;
                var message = new $root.RTAV.AudioDevicePrefs();
                if (object.codec != null)
                    message.codec = object.codec >>> 0;
                if (object.frameUnitLenMS != null)
                    message.frameUnitLenMS = object.frameUnitLenMS >>> 0;
                if (object.frameUnitCount != null)
                    message.frameUnitCount = object.frameUnitCount >>> 0;
                if (object.queueLen != null)
                    message.queueLen = object.queueLen >>> 0;
                if (object.bitsPerSample != null)
                    message.bitsPerSample = object.bitsPerSample >>> 0;
                if (object.channels != null)
                    message.channels = object.channels >>> 0;
                if (object.inputStreams != null)
                    message.inputStreams = object.inputStreams >>> 0;
                if (object.framesPerPacket != null)
                    message.framesPerPacket = object.framesPerPacket >>> 0;
                if (object.agentSamplesRate != null)
                    message.agentSamplesRate = object.agentSamplesRate >>> 0;
                if (object.clientSamplesRate != null)
                    message.clientSamplesRate = object.clientSamplesRate >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from an AudioDevicePrefs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {RTAV.AudioDevicePrefs} message AudioDevicePrefs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AudioDevicePrefs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.codec = 0;
                    object.frameUnitLenMS = 0;
                    object.frameUnitCount = 0;
                    object.queueLen = 0;
                    object.bitsPerSample = 0;
                    object.channels = 0;
                    object.inputStreams = 0;
                    object.framesPerPacket = 0;
                    object.agentSamplesRate = 0;
                    object.clientSamplesRate = 0;
                }
                if (message.codec != null && message.hasOwnProperty("codec"))
                    object.codec = message.codec;
                if (message.frameUnitLenMS != null && message.hasOwnProperty("frameUnitLenMS"))
                    object.frameUnitLenMS = message.frameUnitLenMS;
                if (message.frameUnitCount != null && message.hasOwnProperty("frameUnitCount"))
                    object.frameUnitCount = message.frameUnitCount;
                if (message.queueLen != null && message.hasOwnProperty("queueLen"))
                    object.queueLen = message.queueLen;
                if (message.bitsPerSample != null && message.hasOwnProperty("bitsPerSample"))
                    object.bitsPerSample = message.bitsPerSample;
                if (message.channels != null && message.hasOwnProperty("channels"))
                    object.channels = message.channels;
                if (message.inputStreams != null && message.hasOwnProperty("inputStreams"))
                    object.inputStreams = message.inputStreams;
                if (message.framesPerPacket != null && message.hasOwnProperty("framesPerPacket"))
                    object.framesPerPacket = message.framesPerPacket;
                if (message.agentSamplesRate != null && message.hasOwnProperty("agentSamplesRate"))
                    object.agentSamplesRate = message.agentSamplesRate;
                if (message.clientSamplesRate != null && message.hasOwnProperty("clientSamplesRate"))
                    object.clientSamplesRate = message.clientSamplesRate;
                return object;
            };
    
            /**
             * Converts this AudioDevicePrefs to JSON.
             * @function toJSON
             * @memberof RTAV.AudioDevicePrefs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AudioDevicePrefs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AudioDevicePrefs
             * @function getTypeUrl
             * @memberof RTAV.AudioDevicePrefs
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AudioDevicePrefs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.AudioDevicePrefs";
            };
    
            return AudioDevicePrefs;
        })();
    
        RTAV.VideoDevicePrefs = (function() {
    
            /**
             * Properties of a VideoDevicePrefs.
             * @memberof RTAV
             * @interface IVideoDevicePrefs
             * @property {number|null} [codec] VideoDevicePrefs codec
             * @property {number|null} [frameWidth] VideoDevicePrefs frameWidth
             * @property {number|null} [frameHeight] VideoDevicePrefs frameHeight
             * @property {number|null} [frameRate] VideoDevicePrefs frameRate
             * @property {number|null} [queueLen] VideoDevicePrefs queueLen
             */
    
            /**
             * Constructs a new VideoDevicePrefs.
             * @memberof RTAV
             * @classdesc Represents a VideoDevicePrefs.
             * @implements IVideoDevicePrefs
             * @constructor
             * @param {RTAV.IVideoDevicePrefs=} [properties] Properties to set
             */
            function VideoDevicePrefs(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VideoDevicePrefs codec.
             * @member {number} codec
             * @memberof RTAV.VideoDevicePrefs
             * @instance
             */
            VideoDevicePrefs.prototype.codec = 0;
    
            /**
             * VideoDevicePrefs frameWidth.
             * @member {number} frameWidth
             * @memberof RTAV.VideoDevicePrefs
             * @instance
             */
            VideoDevicePrefs.prototype.frameWidth = 0;
    
            /**
             * VideoDevicePrefs frameHeight.
             * @member {number} frameHeight
             * @memberof RTAV.VideoDevicePrefs
             * @instance
             */
            VideoDevicePrefs.prototype.frameHeight = 0;
    
            /**
             * VideoDevicePrefs frameRate.
             * @member {number} frameRate
             * @memberof RTAV.VideoDevicePrefs
             * @instance
             */
            VideoDevicePrefs.prototype.frameRate = 0;
    
            /**
             * VideoDevicePrefs queueLen.
             * @member {number} queueLen
             * @memberof RTAV.VideoDevicePrefs
             * @instance
             */
            VideoDevicePrefs.prototype.queueLen = 0;
    
            /**
             * Creates a new VideoDevicePrefs instance using the specified properties.
             * @function create
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {RTAV.IVideoDevicePrefs=} [properties] Properties to set
             * @returns {RTAV.VideoDevicePrefs} VideoDevicePrefs instance
             */
            VideoDevicePrefs.create = function create(properties) {
                return new VideoDevicePrefs(properties);
            };
    
            /**
             * Encodes the specified VideoDevicePrefs message. Does not implicitly {@link RTAV.VideoDevicePrefs.verify|verify} messages.
             * @function encode
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {RTAV.IVideoDevicePrefs} message VideoDevicePrefs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDevicePrefs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.codec != null && Object.hasOwnProperty.call(message, "codec"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.codec);
                if (message.frameWidth != null && Object.hasOwnProperty.call(message, "frameWidth"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.frameWidth);
                if (message.frameHeight != null && Object.hasOwnProperty.call(message, "frameHeight"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.frameHeight);
                if (message.frameRate != null && Object.hasOwnProperty.call(message, "frameRate"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.frameRate);
                if (message.queueLen != null && Object.hasOwnProperty.call(message, "queueLen"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.queueLen);
                return writer;
            };
    
            /**
             * Encodes the specified VideoDevicePrefs message, length delimited. Does not implicitly {@link RTAV.VideoDevicePrefs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {RTAV.IVideoDevicePrefs} message VideoDevicePrefs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VideoDevicePrefs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VideoDevicePrefs message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.VideoDevicePrefs} VideoDevicePrefs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDevicePrefs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.VideoDevicePrefs();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.codec = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.frameWidth = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.frameHeight = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.frameRate = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.queueLen = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VideoDevicePrefs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.VideoDevicePrefs} VideoDevicePrefs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VideoDevicePrefs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VideoDevicePrefs message.
             * @function verify
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VideoDevicePrefs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.codec != null && message.hasOwnProperty("codec"))
                    if (!$util.isInteger(message.codec))
                        return "codec: integer expected";
                if (message.frameWidth != null && message.hasOwnProperty("frameWidth"))
                    if (!$util.isInteger(message.frameWidth))
                        return "frameWidth: integer expected";
                if (message.frameHeight != null && message.hasOwnProperty("frameHeight"))
                    if (!$util.isInteger(message.frameHeight))
                        return "frameHeight: integer expected";
                if (message.frameRate != null && message.hasOwnProperty("frameRate"))
                    if (!$util.isInteger(message.frameRate))
                        return "frameRate: integer expected";
                if (message.queueLen != null && message.hasOwnProperty("queueLen"))
                    if (!$util.isInteger(message.queueLen))
                        return "queueLen: integer expected";
                return null;
            };
    
            /**
             * Creates a VideoDevicePrefs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.VideoDevicePrefs} VideoDevicePrefs
             */
            VideoDevicePrefs.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.VideoDevicePrefs)
                    return object;
                var message = new $root.RTAV.VideoDevicePrefs();
                if (object.codec != null)
                    message.codec = object.codec >>> 0;
                if (object.frameWidth != null)
                    message.frameWidth = object.frameWidth >>> 0;
                if (object.frameHeight != null)
                    message.frameHeight = object.frameHeight >>> 0;
                if (object.frameRate != null)
                    message.frameRate = object.frameRate >>> 0;
                if (object.queueLen != null)
                    message.queueLen = object.queueLen >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a VideoDevicePrefs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {RTAV.VideoDevicePrefs} message VideoDevicePrefs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VideoDevicePrefs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.codec = 0;
                    object.frameWidth = 0;
                    object.frameHeight = 0;
                    object.frameRate = 0;
                    object.queueLen = 0;
                }
                if (message.codec != null && message.hasOwnProperty("codec"))
                    object.codec = message.codec;
                if (message.frameWidth != null && message.hasOwnProperty("frameWidth"))
                    object.frameWidth = message.frameWidth;
                if (message.frameHeight != null && message.hasOwnProperty("frameHeight"))
                    object.frameHeight = message.frameHeight;
                if (message.frameRate != null && message.hasOwnProperty("frameRate"))
                    object.frameRate = message.frameRate;
                if (message.queueLen != null && message.hasOwnProperty("queueLen"))
                    object.queueLen = message.queueLen;
                return object;
            };
    
            /**
             * Converts this VideoDevicePrefs to JSON.
             * @function toJSON
             * @memberof RTAV.VideoDevicePrefs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VideoDevicePrefs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VideoDevicePrefs
             * @function getTypeUrl
             * @memberof RTAV.VideoDevicePrefs
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VideoDevicePrefs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.VideoDevicePrefs";
            };
    
            return VideoDevicePrefs;
        })();
    
        RTAV.VdoCodec = (function() {
    
            /**
             * Properties of a VdoCodec.
             * @memberof RTAV
             * @interface IVdoCodec
             * @property {number|null} [count] VdoCodec count
             * @property {Array.<number>|null} [codec] VdoCodec codec
             */
    
            /**
             * Constructs a new VdoCodec.
             * @memberof RTAV
             * @classdesc Represents a VdoCodec.
             * @implements IVdoCodec
             * @constructor
             * @param {RTAV.IVdoCodec=} [properties] Properties to set
             */
            function VdoCodec(properties) {
                this.codec = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * VdoCodec count.
             * @member {number} count
             * @memberof RTAV.VdoCodec
             * @instance
             */
            VdoCodec.prototype.count = 0;
    
            /**
             * VdoCodec codec.
             * @member {Array.<number>} codec
             * @memberof RTAV.VdoCodec
             * @instance
             */
            VdoCodec.prototype.codec = $util.emptyArray;
    
            /**
             * Creates a new VdoCodec instance using the specified properties.
             * @function create
             * @memberof RTAV.VdoCodec
             * @static
             * @param {RTAV.IVdoCodec=} [properties] Properties to set
             * @returns {RTAV.VdoCodec} VdoCodec instance
             */
            VdoCodec.create = function create(properties) {
                return new VdoCodec(properties);
            };
    
            /**
             * Encodes the specified VdoCodec message. Does not implicitly {@link RTAV.VdoCodec.verify|verify} messages.
             * @function encode
             * @memberof RTAV.VdoCodec
             * @static
             * @param {RTAV.IVdoCodec} message VdoCodec message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VdoCodec.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.count);
                if (message.codec != null && message.codec.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.codec.length; ++i)
                        writer.uint32(message.codec[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified VdoCodec message, length delimited. Does not implicitly {@link RTAV.VdoCodec.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.VdoCodec
             * @static
             * @param {RTAV.IVdoCodec} message VdoCodec message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VdoCodec.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a VdoCodec message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.VdoCodec
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.VdoCodec} VdoCodec
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VdoCodec.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.VdoCodec();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint32();
                            break;
                        }
                    case 2: {
                            if (!(message.codec && message.codec.length))
                                message.codec = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.codec.push(reader.uint32());
                            } else
                                message.codec.push(reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a VdoCodec message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.VdoCodec
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.VdoCodec} VdoCodec
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VdoCodec.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a VdoCodec message.
             * @function verify
             * @memberof RTAV.VdoCodec
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VdoCodec.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.codec != null && message.hasOwnProperty("codec")) {
                    if (!Array.isArray(message.codec))
                        return "codec: array expected";
                    for (var i = 0; i < message.codec.length; ++i)
                        if (!$util.isInteger(message.codec[i]))
                            return "codec: integer[] expected";
                }
                return null;
            };
    
            /**
             * Creates a VdoCodec message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.VdoCodec
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.VdoCodec} VdoCodec
             */
            VdoCodec.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.VdoCodec)
                    return object;
                var message = new $root.RTAV.VdoCodec();
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.codec) {
                    if (!Array.isArray(object.codec))
                        throw TypeError(".RTAV.VdoCodec.codec: array expected");
                    message.codec = [];
                    for (var i = 0; i < object.codec.length; ++i)
                        message.codec[i] = object.codec[i] >>> 0;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a VdoCodec message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.VdoCodec
             * @static
             * @param {RTAV.VdoCodec} message VdoCodec
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VdoCodec.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.codec = [];
                if (options.defaults)
                    object.count = 0;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.codec && message.codec.length) {
                    object.codec = [];
                    for (var j = 0; j < message.codec.length; ++j)
                        object.codec[j] = message.codec[j];
                }
                return object;
            };
    
            /**
             * Converts this VdoCodec to JSON.
             * @function toJSON
             * @memberof RTAV.VdoCodec
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VdoCodec.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for VdoCodec
             * @function getTypeUrl
             * @memberof RTAV.VdoCodec
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VdoCodec.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.VdoCodec";
            };
    
            return VdoCodec;
        })();
    
        RTAV.AdoCodec = (function() {
    
            /**
             * Properties of an AdoCodec.
             * @memberof RTAV
             * @interface IAdoCodec
             * @property {number|null} [count] AdoCodec count
             * @property {Array.<number>|null} [codec] AdoCodec codec
             */
    
            /**
             * Constructs a new AdoCodec.
             * @memberof RTAV
             * @classdesc Represents an AdoCodec.
             * @implements IAdoCodec
             * @constructor
             * @param {RTAV.IAdoCodec=} [properties] Properties to set
             */
            function AdoCodec(properties) {
                this.codec = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AdoCodec count.
             * @member {number} count
             * @memberof RTAV.AdoCodec
             * @instance
             */
            AdoCodec.prototype.count = 0;
    
            /**
             * AdoCodec codec.
             * @member {Array.<number>} codec
             * @memberof RTAV.AdoCodec
             * @instance
             */
            AdoCodec.prototype.codec = $util.emptyArray;
    
            /**
             * Creates a new AdoCodec instance using the specified properties.
             * @function create
             * @memberof RTAV.AdoCodec
             * @static
             * @param {RTAV.IAdoCodec=} [properties] Properties to set
             * @returns {RTAV.AdoCodec} AdoCodec instance
             */
            AdoCodec.create = function create(properties) {
                return new AdoCodec(properties);
            };
    
            /**
             * Encodes the specified AdoCodec message. Does not implicitly {@link RTAV.AdoCodec.verify|verify} messages.
             * @function encode
             * @memberof RTAV.AdoCodec
             * @static
             * @param {RTAV.IAdoCodec} message AdoCodec message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdoCodec.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.count);
                if (message.codec != null && message.codec.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.codec.length; ++i)
                        writer.uint32(message.codec[i]);
                    writer.ldelim();
                }
                return writer;
            };
    
            /**
             * Encodes the specified AdoCodec message, length delimited. Does not implicitly {@link RTAV.AdoCodec.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.AdoCodec
             * @static
             * @param {RTAV.IAdoCodec} message AdoCodec message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdoCodec.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdoCodec message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.AdoCodec
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.AdoCodec} AdoCodec
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdoCodec.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.AdoCodec();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint32();
                            break;
                        }
                    case 2: {
                            if (!(message.codec && message.codec.length))
                                message.codec = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.codec.push(reader.uint32());
                            } else
                                message.codec.push(reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdoCodec message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.AdoCodec
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.AdoCodec} AdoCodec
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdoCodec.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdoCodec message.
             * @function verify
             * @memberof RTAV.AdoCodec
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdoCodec.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.codec != null && message.hasOwnProperty("codec")) {
                    if (!Array.isArray(message.codec))
                        return "codec: array expected";
                    for (var i = 0; i < message.codec.length; ++i)
                        if (!$util.isInteger(message.codec[i]))
                            return "codec: integer[] expected";
                }
                return null;
            };
    
            /**
             * Creates an AdoCodec message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.AdoCodec
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.AdoCodec} AdoCodec
             */
            AdoCodec.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.AdoCodec)
                    return object;
                var message = new $root.RTAV.AdoCodec();
                if (object.count != null)
                    message.count = object.count >>> 0;
                if (object.codec) {
                    if (!Array.isArray(object.codec))
                        throw TypeError(".RTAV.AdoCodec.codec: array expected");
                    message.codec = [];
                    for (var i = 0; i < object.codec.length; ++i)
                        message.codec[i] = object.codec[i] >>> 0;
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AdoCodec message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.AdoCodec
             * @static
             * @param {RTAV.AdoCodec} message AdoCodec
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdoCodec.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.codec = [];
                if (options.defaults)
                    object.count = 0;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.codec && message.codec.length) {
                    object.codec = [];
                    for (var j = 0; j < message.codec.length; ++j)
                        object.codec[j] = message.codec[j];
                }
                return object;
            };
    
            /**
             * Converts this AdoCodec to JSON.
             * @function toJSON
             * @memberof RTAV.AdoCodec
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdoCodec.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AdoCodec
             * @function getTypeUrl
             * @memberof RTAV.AdoCodec
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AdoCodec.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.AdoCodec";
            };
    
            return AdoCodec;
        })();
    
        RTAV.AdminPolicy = (function() {
    
            /**
             * Properties of an AdminPolicy.
             * @memberof RTAV
             * @interface IAdminPolicy
             * @property {number|null} [versionNum] AdminPolicy versionNum
             * @property {number|null} [currState] AdminPolicy currState
             * @property {number|null} [disabledComponent] AdminPolicy disabledComponent
             * @property {number|null} [vdoMaxFPS] AdminPolicy vdoMaxFPS
             * @property {number|null} [vdoMaxResHeight] AdminPolicy vdoMaxResHeight
             * @property {number|null} [vdoMaxResWidth] AdminPolicy vdoMaxResWidth
             * @property {number|null} [vdoDefResHeight] AdminPolicy vdoDefResHeight
             * @property {number|null} [vdoDefResWidth] AdminPolicy vdoDefResWidth
             * @property {number|null} [vdoWanEnhancementPhase] AdminPolicy vdoWanEnhancementPhase
             * @property {RTAV.IVdoCodec|null} [vdoCodec] AdminPolicy vdoCodec
             * @property {RTAV.IAdoCodec|null} [adoCodec] AdminPolicy adoCodec
             */
    
            /**
             * Constructs a new AdminPolicy.
             * @memberof RTAV
             * @classdesc Represents an AdminPolicy.
             * @implements IAdminPolicy
             * @constructor
             * @param {RTAV.IAdminPolicy=} [properties] Properties to set
             */
            function AdminPolicy(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * AdminPolicy versionNum.
             * @member {number} versionNum
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.versionNum = 0;
    
            /**
             * AdminPolicy currState.
             * @member {number} currState
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.currState = 0;
    
            /**
             * AdminPolicy disabledComponent.
             * @member {number} disabledComponent
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.disabledComponent = 0;
    
            /**
             * AdminPolicy vdoMaxFPS.
             * @member {number} vdoMaxFPS
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.vdoMaxFPS = 0;
    
            /**
             * AdminPolicy vdoMaxResHeight.
             * @member {number} vdoMaxResHeight
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.vdoMaxResHeight = 0;
    
            /**
             * AdminPolicy vdoMaxResWidth.
             * @member {number} vdoMaxResWidth
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.vdoMaxResWidth = 0;
    
            /**
             * AdminPolicy vdoDefResHeight.
             * @member {number} vdoDefResHeight
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.vdoDefResHeight = 0;
    
            /**
             * AdminPolicy vdoDefResWidth.
             * @member {number} vdoDefResWidth
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.vdoDefResWidth = 0;
    
            /**
             * AdminPolicy vdoWanEnhancementPhase.
             * @member {number} vdoWanEnhancementPhase
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.vdoWanEnhancementPhase = 0;
    
            /**
             * AdminPolicy vdoCodec.
             * @member {RTAV.IVdoCodec|null|undefined} vdoCodec
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.vdoCodec = null;
    
            /**
             * AdminPolicy adoCodec.
             * @member {RTAV.IAdoCodec|null|undefined} adoCodec
             * @memberof RTAV.AdminPolicy
             * @instance
             */
            AdminPolicy.prototype.adoCodec = null;
    
            /**
             * Creates a new AdminPolicy instance using the specified properties.
             * @function create
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {RTAV.IAdminPolicy=} [properties] Properties to set
             * @returns {RTAV.AdminPolicy} AdminPolicy instance
             */
            AdminPolicy.create = function create(properties) {
                return new AdminPolicy(properties);
            };
    
            /**
             * Encodes the specified AdminPolicy message. Does not implicitly {@link RTAV.AdminPolicy.verify|verify} messages.
             * @function encode
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {RTAV.IAdminPolicy} message AdminPolicy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminPolicy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.versionNum != null && Object.hasOwnProperty.call(message, "versionNum"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.versionNum);
                if (message.currState != null && Object.hasOwnProperty.call(message, "currState"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.currState);
                if (message.disabledComponent != null && Object.hasOwnProperty.call(message, "disabledComponent"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.disabledComponent);
                if (message.vdoMaxFPS != null && Object.hasOwnProperty.call(message, "vdoMaxFPS"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.vdoMaxFPS);
                if (message.vdoMaxResHeight != null && Object.hasOwnProperty.call(message, "vdoMaxResHeight"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.vdoMaxResHeight);
                if (message.vdoMaxResWidth != null && Object.hasOwnProperty.call(message, "vdoMaxResWidth"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.vdoMaxResWidth);
                if (message.vdoDefResHeight != null && Object.hasOwnProperty.call(message, "vdoDefResHeight"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.vdoDefResHeight);
                if (message.vdoDefResWidth != null && Object.hasOwnProperty.call(message, "vdoDefResWidth"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.vdoDefResWidth);
                if (message.vdoWanEnhancementPhase != null && Object.hasOwnProperty.call(message, "vdoWanEnhancementPhase"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.vdoWanEnhancementPhase);
                if (message.vdoCodec != null && Object.hasOwnProperty.call(message, "vdoCodec"))
                    $root.RTAV.VdoCodec.encode(message.vdoCodec, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.adoCodec != null && Object.hasOwnProperty.call(message, "adoCodec"))
                    $root.RTAV.AdoCodec.encode(message.adoCodec, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified AdminPolicy message, length delimited. Does not implicitly {@link RTAV.AdminPolicy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {RTAV.IAdminPolicy} message AdminPolicy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminPolicy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AdminPolicy message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.AdminPolicy} AdminPolicy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminPolicy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.AdminPolicy();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.versionNum = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.currState = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.disabledComponent = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.vdoMaxFPS = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.vdoMaxResHeight = reader.uint32();
                            break;
                        }
                    case 6: {
                            message.vdoMaxResWidth = reader.uint32();
                            break;
                        }
                    case 7: {
                            message.vdoDefResHeight = reader.uint32();
                            break;
                        }
                    case 8: {
                            message.vdoDefResWidth = reader.uint32();
                            break;
                        }
                    case 9: {
                            message.vdoWanEnhancementPhase = reader.uint32();
                            break;
                        }
                    case 10: {
                            message.vdoCodec = $root.RTAV.VdoCodec.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.adoCodec = $root.RTAV.AdoCodec.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AdminPolicy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.AdminPolicy} AdminPolicy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminPolicy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AdminPolicy message.
             * @function verify
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminPolicy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.versionNum != null && message.hasOwnProperty("versionNum"))
                    if (!$util.isInteger(message.versionNum))
                        return "versionNum: integer expected";
                if (message.currState != null && message.hasOwnProperty("currState"))
                    if (!$util.isInteger(message.currState))
                        return "currState: integer expected";
                if (message.disabledComponent != null && message.hasOwnProperty("disabledComponent"))
                    if (!$util.isInteger(message.disabledComponent))
                        return "disabledComponent: integer expected";
                if (message.vdoMaxFPS != null && message.hasOwnProperty("vdoMaxFPS"))
                    if (!$util.isInteger(message.vdoMaxFPS))
                        return "vdoMaxFPS: integer expected";
                if (message.vdoMaxResHeight != null && message.hasOwnProperty("vdoMaxResHeight"))
                    if (!$util.isInteger(message.vdoMaxResHeight))
                        return "vdoMaxResHeight: integer expected";
                if (message.vdoMaxResWidth != null && message.hasOwnProperty("vdoMaxResWidth"))
                    if (!$util.isInteger(message.vdoMaxResWidth))
                        return "vdoMaxResWidth: integer expected";
                if (message.vdoDefResHeight != null && message.hasOwnProperty("vdoDefResHeight"))
                    if (!$util.isInteger(message.vdoDefResHeight))
                        return "vdoDefResHeight: integer expected";
                if (message.vdoDefResWidth != null && message.hasOwnProperty("vdoDefResWidth"))
                    if (!$util.isInteger(message.vdoDefResWidth))
                        return "vdoDefResWidth: integer expected";
                if (message.vdoWanEnhancementPhase != null && message.hasOwnProperty("vdoWanEnhancementPhase"))
                    if (!$util.isInteger(message.vdoWanEnhancementPhase))
                        return "vdoWanEnhancementPhase: integer expected";
                if (message.vdoCodec != null && message.hasOwnProperty("vdoCodec")) {
                    var error = $root.RTAV.VdoCodec.verify(message.vdoCodec);
                    if (error)
                        return "vdoCodec." + error;
                }
                if (message.adoCodec != null && message.hasOwnProperty("adoCodec")) {
                    var error = $root.RTAV.AdoCodec.verify(message.adoCodec);
                    if (error)
                        return "adoCodec." + error;
                }
                return null;
            };
    
            /**
             * Creates an AdminPolicy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.AdminPolicy} AdminPolicy
             */
            AdminPolicy.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.AdminPolicy)
                    return object;
                var message = new $root.RTAV.AdminPolicy();
                if (object.versionNum != null)
                    message.versionNum = object.versionNum >>> 0;
                if (object.currState != null)
                    message.currState = object.currState >>> 0;
                if (object.disabledComponent != null)
                    message.disabledComponent = object.disabledComponent >>> 0;
                if (object.vdoMaxFPS != null)
                    message.vdoMaxFPS = object.vdoMaxFPS >>> 0;
                if (object.vdoMaxResHeight != null)
                    message.vdoMaxResHeight = object.vdoMaxResHeight >>> 0;
                if (object.vdoMaxResWidth != null)
                    message.vdoMaxResWidth = object.vdoMaxResWidth >>> 0;
                if (object.vdoDefResHeight != null)
                    message.vdoDefResHeight = object.vdoDefResHeight >>> 0;
                if (object.vdoDefResWidth != null)
                    message.vdoDefResWidth = object.vdoDefResWidth >>> 0;
                if (object.vdoWanEnhancementPhase != null)
                    message.vdoWanEnhancementPhase = object.vdoWanEnhancementPhase >>> 0;
                if (object.vdoCodec != null) {
                    if (typeof object.vdoCodec !== "object")
                        throw TypeError(".RTAV.AdminPolicy.vdoCodec: object expected");
                    message.vdoCodec = $root.RTAV.VdoCodec.fromObject(object.vdoCodec);
                }
                if (object.adoCodec != null) {
                    if (typeof object.adoCodec !== "object")
                        throw TypeError(".RTAV.AdminPolicy.adoCodec: object expected");
                    message.adoCodec = $root.RTAV.AdoCodec.fromObject(object.adoCodec);
                }
                return message;
            };
    
            /**
             * Creates a plain object from an AdminPolicy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {RTAV.AdminPolicy} message AdminPolicy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminPolicy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.versionNum = 0;
                    object.currState = 0;
                    object.disabledComponent = 0;
                    object.vdoMaxFPS = 0;
                    object.vdoMaxResHeight = 0;
                    object.vdoMaxResWidth = 0;
                    object.vdoDefResHeight = 0;
                    object.vdoDefResWidth = 0;
                    object.vdoWanEnhancementPhase = 0;
                    object.vdoCodec = null;
                    object.adoCodec = null;
                }
                if (message.versionNum != null && message.hasOwnProperty("versionNum"))
                    object.versionNum = message.versionNum;
                if (message.currState != null && message.hasOwnProperty("currState"))
                    object.currState = message.currState;
                if (message.disabledComponent != null && message.hasOwnProperty("disabledComponent"))
                    object.disabledComponent = message.disabledComponent;
                if (message.vdoMaxFPS != null && message.hasOwnProperty("vdoMaxFPS"))
                    object.vdoMaxFPS = message.vdoMaxFPS;
                if (message.vdoMaxResHeight != null && message.hasOwnProperty("vdoMaxResHeight"))
                    object.vdoMaxResHeight = message.vdoMaxResHeight;
                if (message.vdoMaxResWidth != null && message.hasOwnProperty("vdoMaxResWidth"))
                    object.vdoMaxResWidth = message.vdoMaxResWidth;
                if (message.vdoDefResHeight != null && message.hasOwnProperty("vdoDefResHeight"))
                    object.vdoDefResHeight = message.vdoDefResHeight;
                if (message.vdoDefResWidth != null && message.hasOwnProperty("vdoDefResWidth"))
                    object.vdoDefResWidth = message.vdoDefResWidth;
                if (message.vdoWanEnhancementPhase != null && message.hasOwnProperty("vdoWanEnhancementPhase"))
                    object.vdoWanEnhancementPhase = message.vdoWanEnhancementPhase;
                if (message.vdoCodec != null && message.hasOwnProperty("vdoCodec"))
                    object.vdoCodec = $root.RTAV.VdoCodec.toObject(message.vdoCodec, options);
                if (message.adoCodec != null && message.hasOwnProperty("adoCodec"))
                    object.adoCodec = $root.RTAV.AdoCodec.toObject(message.adoCodec, options);
                return object;
            };
    
            /**
             * Converts this AdminPolicy to JSON.
             * @function toJSON
             * @memberof RTAV.AdminPolicy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminPolicy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for AdminPolicy
             * @function getTypeUrl
             * @memberof RTAV.AdminPolicy
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AdminPolicy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.AdminPolicy";
            };
    
            return AdminPolicy;
        })();
    
        RTAV.ClientConfig = (function() {
    
            /**
             * Properties of a ClientConfig.
             * @memberof RTAV
             * @interface IClientConfig
             * @property {number|null} [versionNum] ClientConfig versionNum
             * @property {number|null} [currState] ClientConfig currState
             * @property {number|null} [isEnabled] ClientConfig isEnabled
             * @property {number|null} [vdoFPS] ClientConfig vdoFPS
             * @property {number|null} [vdoResHeight] ClientConfig vdoResHeight
             * @property {number|null} [vdoResWidth] ClientConfig vdoResWidth
             * @property {number|null} [platform] ClientConfig platform
             * @property {number|null} [vdoWanEnhancementPhase] ClientConfig vdoWanEnhancementPhase
             * @property {RTAV.IVdoCodec|null} [vdoCodec] ClientConfig vdoCodec
             * @property {RTAV.IAdoCodec|null} [adoCodec] ClientConfig adoCodec
             */
    
            /**
             * Constructs a new ClientConfig.
             * @memberof RTAV
             * @classdesc Represents a ClientConfig.
             * @implements IClientConfig
             * @constructor
             * @param {RTAV.IClientConfig=} [properties] Properties to set
             */
            function ClientConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ClientConfig versionNum.
             * @member {number} versionNum
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.versionNum = 0;
    
            /**
             * ClientConfig currState.
             * @member {number} currState
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.currState = 0;
    
            /**
             * ClientConfig isEnabled.
             * @member {number} isEnabled
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.isEnabled = 0;
    
            /**
             * ClientConfig vdoFPS.
             * @member {number} vdoFPS
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.vdoFPS = 0;
    
            /**
             * ClientConfig vdoResHeight.
             * @member {number} vdoResHeight
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.vdoResHeight = 0;
    
            /**
             * ClientConfig vdoResWidth.
             * @member {number} vdoResWidth
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.vdoResWidth = 0;
    
            /**
             * ClientConfig platform.
             * @member {number} platform
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.platform = 0;
    
            /**
             * ClientConfig vdoWanEnhancementPhase.
             * @member {number} vdoWanEnhancementPhase
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.vdoWanEnhancementPhase = 0;
    
            /**
             * ClientConfig vdoCodec.
             * @member {RTAV.IVdoCodec|null|undefined} vdoCodec
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.vdoCodec = null;
    
            /**
             * ClientConfig adoCodec.
             * @member {RTAV.IAdoCodec|null|undefined} adoCodec
             * @memberof RTAV.ClientConfig
             * @instance
             */
            ClientConfig.prototype.adoCodec = null;
    
            /**
             * Creates a new ClientConfig instance using the specified properties.
             * @function create
             * @memberof RTAV.ClientConfig
             * @static
             * @param {RTAV.IClientConfig=} [properties] Properties to set
             * @returns {RTAV.ClientConfig} ClientConfig instance
             */
            ClientConfig.create = function create(properties) {
                return new ClientConfig(properties);
            };
    
            /**
             * Encodes the specified ClientConfig message. Does not implicitly {@link RTAV.ClientConfig.verify|verify} messages.
             * @function encode
             * @memberof RTAV.ClientConfig
             * @static
             * @param {RTAV.IClientConfig} message ClientConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.versionNum != null && Object.hasOwnProperty.call(message, "versionNum"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.versionNum);
                if (message.currState != null && Object.hasOwnProperty.call(message, "currState"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.currState);
                if (message.isEnabled != null && Object.hasOwnProperty.call(message, "isEnabled"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.isEnabled);
                if (message.vdoFPS != null && Object.hasOwnProperty.call(message, "vdoFPS"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.vdoFPS);
                if (message.vdoResHeight != null && Object.hasOwnProperty.call(message, "vdoResHeight"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.vdoResHeight);
                if (message.vdoResWidth != null && Object.hasOwnProperty.call(message, "vdoResWidth"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.vdoResWidth);
                if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.platform);
                if (message.vdoWanEnhancementPhase != null && Object.hasOwnProperty.call(message, "vdoWanEnhancementPhase"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.vdoWanEnhancementPhase);
                if (message.vdoCodec != null && Object.hasOwnProperty.call(message, "vdoCodec"))
                    $root.RTAV.VdoCodec.encode(message.vdoCodec, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.adoCodec != null && Object.hasOwnProperty.call(message, "adoCodec"))
                    $root.RTAV.AdoCodec.encode(message.adoCodec, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified ClientConfig message, length delimited. Does not implicitly {@link RTAV.ClientConfig.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RTAV.ClientConfig
             * @static
             * @param {RTAV.IClientConfig} message ClientConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientConfig.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ClientConfig message from the specified reader or buffer.
             * @function decode
             * @memberof RTAV.ClientConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RTAV.ClientConfig} ClientConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RTAV.ClientConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.versionNum = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.currState = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.isEnabled = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.vdoFPS = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.vdoResHeight = reader.uint32();
                            break;
                        }
                    case 6: {
                            message.vdoResWidth = reader.uint32();
                            break;
                        }
                    case 7: {
                            message.platform = reader.uint32();
                            break;
                        }
                    case 8: {
                            message.vdoWanEnhancementPhase = reader.uint32();
                            break;
                        }
                    case 9: {
                            message.vdoCodec = $root.RTAV.VdoCodec.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.adoCodec = $root.RTAV.AdoCodec.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ClientConfig message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RTAV.ClientConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RTAV.ClientConfig} ClientConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientConfig.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ClientConfig message.
             * @function verify
             * @memberof RTAV.ClientConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.versionNum != null && message.hasOwnProperty("versionNum"))
                    if (!$util.isInteger(message.versionNum))
                        return "versionNum: integer expected";
                if (message.currState != null && message.hasOwnProperty("currState"))
                    if (!$util.isInteger(message.currState))
                        return "currState: integer expected";
                if (message.isEnabled != null && message.hasOwnProperty("isEnabled"))
                    if (!$util.isInteger(message.isEnabled))
                        return "isEnabled: integer expected";
                if (message.vdoFPS != null && message.hasOwnProperty("vdoFPS"))
                    if (!$util.isInteger(message.vdoFPS))
                        return "vdoFPS: integer expected";
                if (message.vdoResHeight != null && message.hasOwnProperty("vdoResHeight"))
                    if (!$util.isInteger(message.vdoResHeight))
                        return "vdoResHeight: integer expected";
                if (message.vdoResWidth != null && message.hasOwnProperty("vdoResWidth"))
                    if (!$util.isInteger(message.vdoResWidth))
                        return "vdoResWidth: integer expected";
                if (message.platform != null && message.hasOwnProperty("platform"))
                    if (!$util.isInteger(message.platform))
                        return "platform: integer expected";
                if (message.vdoWanEnhancementPhase != null && message.hasOwnProperty("vdoWanEnhancementPhase"))
                    if (!$util.isInteger(message.vdoWanEnhancementPhase))
                        return "vdoWanEnhancementPhase: integer expected";
                if (message.vdoCodec != null && message.hasOwnProperty("vdoCodec")) {
                    var error = $root.RTAV.VdoCodec.verify(message.vdoCodec);
                    if (error)
                        return "vdoCodec." + error;
                }
                if (message.adoCodec != null && message.hasOwnProperty("adoCodec")) {
                    var error = $root.RTAV.AdoCodec.verify(message.adoCodec);
                    if (error)
                        return "adoCodec." + error;
                }
                return null;
            };
    
            /**
             * Creates a ClientConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RTAV.ClientConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RTAV.ClientConfig} ClientConfig
             */
            ClientConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.RTAV.ClientConfig)
                    return object;
                var message = new $root.RTAV.ClientConfig();
                if (object.versionNum != null)
                    message.versionNum = object.versionNum >>> 0;
                if (object.currState != null)
                    message.currState = object.currState >>> 0;
                if (object.isEnabled != null)
                    message.isEnabled = object.isEnabled >>> 0;
                if (object.vdoFPS != null)
                    message.vdoFPS = object.vdoFPS >>> 0;
                if (object.vdoResHeight != null)
                    message.vdoResHeight = object.vdoResHeight >>> 0;
                if (object.vdoResWidth != null)
                    message.vdoResWidth = object.vdoResWidth >>> 0;
                if (object.platform != null)
                    message.platform = object.platform >>> 0;
                if (object.vdoWanEnhancementPhase != null)
                    message.vdoWanEnhancementPhase = object.vdoWanEnhancementPhase >>> 0;
                if (object.vdoCodec != null) {
                    if (typeof object.vdoCodec !== "object")
                        throw TypeError(".RTAV.ClientConfig.vdoCodec: object expected");
                    message.vdoCodec = $root.RTAV.VdoCodec.fromObject(object.vdoCodec);
                }
                if (object.adoCodec != null) {
                    if (typeof object.adoCodec !== "object")
                        throw TypeError(".RTAV.ClientConfig.adoCodec: object expected");
                    message.adoCodec = $root.RTAV.AdoCodec.fromObject(object.adoCodec);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ClientConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RTAV.ClientConfig
             * @static
             * @param {RTAV.ClientConfig} message ClientConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.versionNum = 0;
                    object.currState = 0;
                    object.isEnabled = 0;
                    object.vdoFPS = 0;
                    object.vdoResHeight = 0;
                    object.vdoResWidth = 0;
                    object.platform = 0;
                    object.vdoWanEnhancementPhase = 0;
                    object.vdoCodec = null;
                    object.adoCodec = null;
                }
                if (message.versionNum != null && message.hasOwnProperty("versionNum"))
                    object.versionNum = message.versionNum;
                if (message.currState != null && message.hasOwnProperty("currState"))
                    object.currState = message.currState;
                if (message.isEnabled != null && message.hasOwnProperty("isEnabled"))
                    object.isEnabled = message.isEnabled;
                if (message.vdoFPS != null && message.hasOwnProperty("vdoFPS"))
                    object.vdoFPS = message.vdoFPS;
                if (message.vdoResHeight != null && message.hasOwnProperty("vdoResHeight"))
                    object.vdoResHeight = message.vdoResHeight;
                if (message.vdoResWidth != null && message.hasOwnProperty("vdoResWidth"))
                    object.vdoResWidth = message.vdoResWidth;
                if (message.platform != null && message.hasOwnProperty("platform"))
                    object.platform = message.platform;
                if (message.vdoWanEnhancementPhase != null && message.hasOwnProperty("vdoWanEnhancementPhase"))
                    object.vdoWanEnhancementPhase = message.vdoWanEnhancementPhase;
                if (message.vdoCodec != null && message.hasOwnProperty("vdoCodec"))
                    object.vdoCodec = $root.RTAV.VdoCodec.toObject(message.vdoCodec, options);
                if (message.adoCodec != null && message.hasOwnProperty("adoCodec"))
                    object.adoCodec = $root.RTAV.AdoCodec.toObject(message.adoCodec, options);
                return object;
            };
    
            /**
             * Converts this ClientConfig to JSON.
             * @function toJSON
             * @memberof RTAV.ClientConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ClientConfig
             * @function getTypeUrl
             * @memberof RTAV.ClientConfig
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ClientConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/RTAV.ClientConfig";
            };
    
            return ClientConfig;
        })();
    
        return RTAV;
    })();

    return $root;
});
