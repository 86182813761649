/**
 * ******************************************************
 * Copyright (C) 2015-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { LocalStorageService } from "./local-storage.service";

/**
 * brokerSessionStorage-service.js --
 *
 * utility serive to provide API for maintain a storage live as long as the
 * broker session and shared among tabs.
 */

@Injectable({
   providedIn: "root"
})
export class BrokerSessionStorageService {
   private _storage: any;
   private readonly _keyHeader: string = "BSS_"; //'broker session storage'

   constructor(private localStorageService: LocalStorageService) {
      try {
         /**
          * Try local storage first.
          * We don't have good way to mock local storage right now.
          * So there is no UT for code below.
          */
         this._storage = window.localStorage;
         this._storage.setItem("testKey123", "foobar");
         this._storage.removeItem("testKey123");
      } catch (err) {
         /**
          * Use non-persistent instead of cookie after ng-migration.
          */
         this._storage = {
            setItem: (key: string, value: any) => {
               try {
                  this.localStorageService.setSession(key, JSON.stringify(value));
               } catch (e) {
                  Logger.exception(e);
               }
            },
            getItem: (key: string) => {
               try {
                  const value = this.localStorageService.getSession(key);
                  if (value) {
                     return JSON.parse(value);
                  } else {
                     return null;
                  }
               } catch (e) {
                  Logger.exception(e);
                  return null;
               }
            },
            clear: () => {
               try {
                  this.localStorageService.clearSession();
               } catch (e) {
                  Logger.exception(e);
               }
            },
            nonPersistent: true
         };
      }
   }

   private _getFullKey = (key) => {
      return this._keyHeader + key;
   };

   private _isManagedKey = (itemKey) => {
      // compatible with very short input
      return itemKey.substring(0, this._keyHeader.length) === this._keyHeader;
   };

   public read = (key) => {
      let fullKey, content;

      fullKey = this._getFullKey(key);
      content = this._storage.getItem(fullKey);
      /**
       * If the storage here is referred to storageService.localStorage,
       * then it is actually stored in cookie. And it is possible the
       * return value is ''. See the getItem function in
       * storage-service.js.
       *
       * However JSON.parse('') always throw error. So we should return
       * null in this case
       */
      try {
         return JSON.parse(content);
      } catch (e) {
         return null;
      }
   };

   public write = (key, value) => {
      let fullKey,
         content = JSON.stringify(value);

      fullKey = this._getFullKey(key);
      this._storage.setItem(fullKey, content);
   };

   public exist = (key) => {
      const value = this.read(key);
      return value !== null;
   };

   public clear = () => {
      if (this._storage.nonPersistent) {
         this._storage.clear();
      } else {
         for (let i = 0; i < this._storage.length; i++) {
            const itemkey = this._storage.key(i);
            if (this._isManagedKey(itemkey)) {
               this._storage.removeItem(itemkey);
            }
         }
      }
   };
}
