/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * use this to manage the session management center
 */

import Logger from "../../../../core/libs/logger";
import util from "../../../../shared/jscdk/util";
import { Injectable } from "@angular/core";
import { ENTITLE_TYPE } from "@html-core";
import { ConnectedMessageService } from "../../../base/service/connected-message.service";
import { JscdkCommonInvoker } from "../../../../shared/common/jscdk/jscdk-common-invoker";
import { UtilService } from "../../../../shared/launcher/common/util-service";
import { IdleSessionService } from "../../../../shared/common/service/idle-session.service";
import { SessionUtil } from "../../../../shared/common/service/session-util";
import { ModalDialogService } from "../../../../shared/common/commondialog/dialog.service";
import { EventBusService } from "../../../../core/services/event/event-bus.service";
import { Ws1Service } from "../../../../shared/common/service/ws1.service";
import { VmwHorizonClientProtocol, VmwHorizonClientSessionType } from "../../../../../../SDK/src/lib/model/enum";
import { BusEvent } from "../../../../core/services/event";
import { Port } from "../../../base/model/rx-bridge-type";
import { clientUtil, TitanMsg, TranslateService } from "@html-core";
import { RestfulService } from "../../../../titan/services/restful.service";
import { EntitledItemsModel } from "../../../../shared/desktop/common/entitleditems-model";

@Injectable()
export class SessionManagementCenterManager {
   private jscdkWrapper = null;
   // Stores the idle timeout warning dialog, if open
   private idleTimeoutWarningDialogId: string = null;
   // Stores the idle timeout dialog, if open
   private idleTimeoutDialogId: string = null;
   // stores all launched desktop sessions
   private desktopSessionList = [];
   // stores all launched application sessions and each session's apps
   private applicationSessionList = [];
   // stores each session's operations
   private sessionOperationsList = {};
   private remoteSessionManager = null;
   private desktopPropertyGetter = null;
   private hasSession: boolean = false;
   public _desktopsMap = {};
   private errorDialogId = "";

   constructor(
      private connectedMessageService: ConnectedMessageService,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private utilService: UtilService,
      private idleSessionService: IdleSessionService,
      private sessionUtil: SessionUtil,
      private modalDialogService: ModalDialogService,
      private ws1Service: Ws1Service,
      private eventBusService: EventBusService,
      private restfulService: RestfulService,
      private entitledItemsModel: EntitledItemsModel,
      private translate: TranslateService
   ) {
      if (clientUtil.isTitanClient()) {
         this.entitledItemsModel.entitleChange$.subscribe(() => {
            this.updateItem();
         });
      }
      connectedMessageService.initConnection("sessionMessage", "KILL_SESSION_ERROR");
   }

   public updateItem = () => {
      this._desktopsMap = {};
      const entitledItems = this.entitledItemsModel.getEntitledItems();
      for (const item of entitledItems) {
         if (item.type === ENTITLE_TYPE.DESKTOP) {
            this._desktopsMap[item.id] = item;
         }
      }
      this.setDesktopPropertyGetter((itemId) => {
         const desktop = this._desktopsMap[itemId];
         return desktop;
      });
   };

   private _logoffDesktop = (content) => {
      if (clientUtil.isTitanClient()) {
         this.restfulService.handleSessionOps(content.titanSessionId, TitanMsg.TITAN_VM_OPS.LOGOFF).subscribe((msg) => {
            Logger.info("log off desktop");
         });
      } else {
         this.jscdkWrapper.killItem(content.sessionId, "Desktop", function () {});
      }
   };

   private _resetDesktop = (sessionId) => {
      this.jscdkWrapper.resetDesktop(sessionId, function () {});
   };

   private _restartDesktop = (sessionId) => {
      if (clientUtil.isTitanClient()) {
         this.restfulService.handleSessionOps(sessionId, TitanMsg.TITAN_VM_OPS.RESTART).subscribe((msg) => {
            Logger.info("restart desktop");
         });
      } else {
         this.jscdkWrapper.restartDesktop(sessionId, function () {});
      }
   };

   private _terminateAppSession = (sessionId, isMultiSession) => {
      if (!isMultiSession) {
         if (clientUtil.isTitanClient()) {
            this.restfulService.handleSessionOps(sessionId, TitanMsg.TITAN_VM_OPS.LOGOFF).subscribe((msg) => {
               Logger.info("log off application");
            });
         } else {
            this.jscdkWrapper.killItem(sessionId, "Application", function () {});
         }
      } else {
         this.jscdkCommonInvoker.resetItem(sessionId, false, isMultiSession);
      }
   };

   private _disconnectSession = (sessionId) => {
      this.connectedMessageService.sendMessage(sessionId, "Session", {
         type: "requestSessionDisconnect"
      });
   };

   private _disconnectAllApplications = () => {
      this.sessionUtil.clearAll();
      for (let i = 0; i < this.applicationSessionList.length; i++) {
         this._disconnectSession(this.applicationSessionList[i].key);
      }
   };

   /**
    * Disconnect all multi-session applications
    */
   private _disconnectAllMultiSessionApplications = () => {
      this.applicationSessionList.forEach(({ key, isMultiSession }) => {
         if (isMultiSession) {
            this._disconnectSession(key);
         }
      });
   };

   private _disconnectAllDesktops = () => {
      for (let i = 0; i < this.desktopSessionList.length; i++) {
         this._disconnectSession(this.desktopSessionList[i].key);
      }
   };

   private _disconnectAllSessions = () => {
      this._disconnectAllApplications();
      this._disconnectAllDesktops();
   };

   public init = (remoteSessionManager) => {
      this.connectedMessageService.addAcceptedId("sessionManagementCenter");

      this.eventBusService.listen("FocusOnRemoteApp").subscribe((msg1: BusEvent.FocusOnRemoteApp) => {
         if (this.remoteSessionManager) {
            Logger.info(
               "Got FocusOnRemoteApp message calling remoteSessionManager.focusOn with SessionKey = " + msg1.sessionKey,
               Logger.GEOLOCATION
            );
            this.remoteSessionManager.focusOn(msg1.sessionKey);
         }
      });

      this.eventBusService.listen("disconnectSession").subscribe((msg) => {
         if (!util.brokerSupportApplication()) {
            this._disconnectSession(msg.data.sessionId);
         } else {
            this.utilService.authenticationStatusCheck.callBackWhenUnlocked(
               this._disconnectSession,
               msg.data.sessionId,
               "disconnect",
               "desktop"
            );
         }
      });

      this.eventBusService.listen(BusEvent.DisconnectApp.MSG_TYPE).subscribe((msg: BusEvent.DisconnectApp) => {
         this._disconnectSession(msg.data);
      });

      this.eventBusService.listen(BusEvent.DisconnectAllMultiSessionApp.MSG_TYPE).subscribe(() => {
         this._disconnectAllMultiSessionApplications();
      });

      this.eventBusService.listen("sessionExpired").subscribe(() => {
         this._disconnectAllSessions();
      });

      this.connectedMessageService.onMessage("sessionMessage", "KILL_SESSION_ERROR", (msg) => {
         if (msg.type === "killSessionError" && !this.modalDialogService.isDialogOpen(this.errorDialogId)) {
            this.errorDialogId = this.modalDialogService.showError({
               data: {
                  title: this.translate._T("ERROR"),
                  content: msg.content.errorText
               }
            });
         }
      });

      this.connectedMessageService.onMessage("sessionManagementCenter", "sessionManagementCenter", (msg) => {
         Logger.debug("session management center window accept operation message: " + msg.type);
         if (this.jscdkWrapper) {
            if (msg.type === "logoffSession") {
               this._logoffDesktop(msg.content);
            } else if (msg.type === "resetSession") {
               this._resetDesktop(msg.content.sessionId);
            } else if (msg.type === "restartSession") {
               this._restartDesktop(msg.content.sessionId);
            } else if (msg.type === "terminateAppSession") {
               this._terminateAppSession(msg.content.sessionId, msg.content.isMultiSession);
            } else if (msg.type === "disconnectSession") {
               this._disconnectSession(msg.content.sessionId);
            } else if (msg.type === "restoreApplicationSession") {
               // if this session is not focus, focus on it .
               this.remoteSessionManager.focusOn(msg.content.sessionId);
               this.connectedMessageService.sendMessage(msg.content.sessionId, "Session", {
                  type: "restoreApplicationSession",
                  content: {
                     sessionId: msg.content.sessionId,
                     windowIds: msg.content.windowIds
                  }
               });
            } else if (msg.type === "restoreApp") {
               this.remoteSessionManager.focusOn(msg.content.sessionId);
               this.connectedMessageService.sendMessage(msg.content.sessionId, "Session", {
                  type: "restoreApp",
                  content: {
                     sessionId: msg.content.sessionId,
                     windowIds: msg.content.windowIds
                  }
               });
            } else if (msg.type === "reloadUI") {
               this.connectedMessageService.sendMessage("sessionManagementCenter", "sessionManagementCenter", {
                  type: "renderData",
                  content: {
                     desktopSessionList: this.desktopSessionList,
                     applicationSessionList: this.applicationSessionList,
                     sessionOperationsList: this.sessionOperationsList
                  }
               });
            }
         }
      });

      this.remoteSessionManager = remoteSessionManager;

      this.connectedMessageService.onPeerOpen("sessionManagementCenter", "sessionManagementCenter", () => {
         const sessionManagementCenterWindow = chrome.app.window.get("sessionManagementCenter");
         sessionManagementCenterWindow.onClosed.addListener(() => {
            this.connectedMessageService.resetConnection("sessionManagementCenter", "sessionManagementCenter");
         });
         this.connectedMessageService.sendMessage("sessionManagementCenter", "sessionManagementCenter", {
            type: "renderData",
            content: {
               desktopSessionList: this.desktopSessionList,
               applicationSessionList: this.applicationSessionList,
               sessionOperationsList: this.sessionOperationsList
            }
         });
      });

      this.remoteSessionManager.onSessionListAdded((sessionKey, sessionInfo) => {
         if (typeof this.desktopPropertyGetter !== "function") {
            Logger.error("desktopPropertyGetter is not defined");
            return;
         }
         let operations = [];
         if (sessionInfo.isDesktopSession) {
            // stores that desktop session info
            let needChange = true;
            for (let i = 0; i < this.desktopSessionList.length; i++) {
               if (this.desktopSessionList[i].key === sessionKey) {
                  needChange = false;
                  break;
               }
            }
            if (needChange) {
               const session: any = {};
               session.key = sessionKey;
               session.entitleId = sessionInfo.entitleId;
               session.name = sessionInfo.desktopDisplayName;
               session.isDesktopSession = true;
               session.sessionId = sessionInfo.triggerItemInfo.sessionId;
               this.desktopSessionList.push(session);
            }
            // store that desktop session operations
            let desktop = this.desktopPropertyGetter(sessionKey);

            // For CPA or DaaS, the entitleId can be difference with sessionKey
            if (!desktop && sessionInfo.entitleId) {
               desktop = this.desktopPropertyGetter(sessionInfo.entitleId);
            }

            operations = ["Disconnect"];

            /*
             * The correct method is we need to send a get-launch-item again to
             * query whether the desktop can be logoff after sending a
             * get-desktop-connection request.
             *
             * But our session management only trace alive session. So just add
             * the option since a live session should be logged off.
             */
            operations.push("Log off");

            if (desktop && (desktop.resetEnabled || desktop.resetable)) {
               operations.push("Reset");
            }
            if (desktop && (desktop.restartEnabled || desktop.restartable)) {
               operations.push("Restart");
            }
            this.sessionOperationsList[sessionKey] = operations;

            this.eventBusService.dispatch({
               type: "newProtocolSessionCreated",
               data: {
                  remoteSession: {
                     name: sessionInfo.desktopDisplayName,
                     id: sessionInfo.entitleId,
                     sessionId: sessionKey,
                     type: <VmwHorizonClientSessionType>"Desktop",
                     allowedOperations: operations,
                     protocol: <VmwHorizonClientProtocol>"Blast"
                  },
                  clientData: {
                     triggerItemInfo: sessionInfo.triggerItemInfo
                  }
               }
            });
         } else {
            // stores that application session info
            let needChange = true;
            for (let i = 0; i < this.applicationSessionList.length; i++) {
               if (this.applicationSessionList[i].key === sessionKey) {
                  needChange = false;
                  break;
               }
            }
            if (needChange) {
               const session: any = {};
               session.key = sessionKey;
               session.windowIds = [];
               session.windowTitles = [];
               session.isDesktopSession = false;
               session.isMultiSession = sessionInfo.isMultiSession;
               this.applicationSessionList.push(session);
            }
            // store that desktop session operations
            operations = ["Terminate", "Disconnect"];
            this.sessionOperationsList[sessionKey] = operations;

            this.eventBusService.dispatch({
               type: "newProtocolSessionCreated",
               data: {
                  remoteSession: {
                     name: "",
                     id: "",
                     sessionId: sessionKey,
                     type: <VmwHorizonClientSessionType>"App",
                     allowedOperations: operations,
                     protocol: <VmwHorizonClientProtocol>"Blast"
                  },
                  clientData: {
                     triggerItemInfo: sessionInfo.triggerItemInfo
                  }
               }
            });
         }

         if (!this.hasSession && (this.applicationSessionList.length > 0 || this.desktopSessionList.length > 0)) {
            this.eventBusService.dispatch({
               type: "firstSessionAdded"
            });
            this.hasSession = true;
            Logger.info("First session is launched.");
         }

         this.connectedMessageService.initConnection(sessionKey, Port.ChannelName.UrlRedirection);

         this.connectedMessageService.sendMessage("sessionManagementCenter", "sessionManagementCenter", {
            type: "renderDataUpdate",
            content: {
               desktopSessionList: this.desktopSessionList,
               applicationSessionList: this.applicationSessionList,
               sessionOperationsList: this.sessionOperationsList
            }
         });

         this.connectedMessageService.onMessage(sessionKey, "sessionManagementCenterAppWindow", (msg) => {
            if (msg.type === "addAppWindow") {
               let hasSession = false;
               for (let i = 0; i < this.applicationSessionList.length; i++) {
                  if (this.applicationSessionList[i].key === msg.content.sessionKey) {
                     hasSession = true;
                     const windowIds = this.applicationSessionList[i].windowIds;
                     const windowTitles = this.applicationSessionList[i].windowTitles;
                     if (windowIds.indexOf(msg.content.windowId) === -1) {
                        // if msg's window id is not exist, we should add windowId and windowTitle separately to windowIds and windowTitles
                        // Window Id is the only symbol for a window.
                        windowIds.push(msg.content.windowId);
                        windowTitles.push(msg.content.windowTitle);
                        this.applicationSessionList[i].windowIds = windowIds;
                        this.applicationSessionList[i].windowTitles = windowTitles;
                     } else if (windowTitles.indexOf(msg.content.windowTitle) === -1) {
                        // if msg's window id exist, but window title is new, we need to update that window's windowTitle in windowTitles
                        const pos = windowIds.indexOf(msg.content.windowId);
                        windowTitles.splice(pos, 1, msg.content.windowTitle);
                        this.applicationSessionList[i].windowTitles = windowTitles;
                     }
                     break;
                  }
               }
               if (!hasSession) {
                  const session: any = {};
                  session.key = msg.content.sessionKey;
                  session.windowIds = [msg.content.windowId];
                  session.windowTitles = [msg.content.windowTitle];
                  session.isDesktopSession = false;
                  this.applicationSessionList.push(session);
                  this.sessionOperationsList[msg.content.sessionKey] = ["Terminate"];
               }
            } else if (msg.type === "removeAppWindow") {
               for (let i = 0; i < this.applicationSessionList.length; i++) {
                  if (this.applicationSessionList[i].key === msg.content.sessionKey) {
                     const windowIds = this.applicationSessionList[i].windowIds;
                     const windowTitles = this.applicationSessionList[i].windowTitles;
                     const pos = windowIds.indexOf(msg.content.windowId);
                     if (pos !== -1) {
                        windowIds.splice(pos, 1);
                        windowTitles.splice(pos, 1);
                        this.applicationSessionList[i].windowIds = windowIds;
                        this.applicationSessionList[i].windowTitles = windowTitles;
                     }
                  }
               }
            }
            this.connectedMessageService.sendMessage("sessionManagementCenter", "sessionManagementCenter", {
               type: "renderDataUpdate",
               content: {
                  desktopSessionList: this.desktopSessionList,
                  applicationSessionList: this.applicationSessionList,
                  sessionOperationsList: this.sessionOperationsList
               }
            });
         });
      });

      this.remoteSessionManager.onSessionListRemoved((sessionKey, sessionInfo) => {
         if (sessionInfo.isDesktopSession) {
            // delete that desktop session
            for (let i = 0; i < this.desktopSessionList.length; i++) {
               if (this.desktopSessionList[i].key === sessionKey) {
                  this.desktopSessionList.splice(i, 1);
               }
            }

            this.eventBusService.dispatch({
               type: "protocolSessionDisconnected",
               data: {
                  remoteSession: {
                     name: sessionInfo.desktopDisplayName,
                     id: sessionInfo.entitleId,
                     sessionId: sessionKey,
                     type: <VmwHorizonClientSessionType>"Desktop",
                     allowedOperations: this.sessionOperationsList[sessionKey],
                     protocol: <VmwHorizonClientProtocol>"Blast"
                  },
                  connectionFailed: false,
                  errorMessage: "Desktop disconnected."
               }
            });
            delete this.sessionOperationsList[sessionKey];
         } else {
            // delete that application session
            for (let i = 0; i < this.applicationSessionList.length; i++) {
               if (this.applicationSessionList[i].key === sessionKey) {
                  this.applicationSessionList.splice(i, 1);
               }
            }

            this.eventBusService.dispatch({
               type: "protocolSessionDisconnected",
               data: {
                  remoteSession: {
                     name: "",
                     id: "",
                     sessionId: sessionKey,
                     type: <VmwHorizonClientSessionType>"App",
                     allowedOperations: this.sessionOperationsList[sessionKey],
                     protocol: <VmwHorizonClientProtocol>"Blast"
                  },
                  connectionFailed: false,
                  errorMessage: "Application disconnected."
               }
            });

            delete this.sessionOperationsList[sessionKey];
         }

         if (this.applicationSessionList.length === 0 && this.desktopSessionList.length === 0) {
            this.eventBusService.dispatch({
               type: "lastSessionRemoved"
            });
            this.hasSession = false;
            Logger.info("Last session is removed.");
         }

         this.connectedMessageService.releaseConnection(sessionKey, Port.ChannelName.UrlRedirection);

         this.connectedMessageService.sendMessage("sessionManagementCenter", "sessionManagementCenter", {
            type: "renderDataUpdate",
            content: {
               desktopSessionList: this.desktopSessionList,
               applicationSessionList: this.applicationSessionList,
               sessionOperationsList: this.sessionOperationsList
            }
         });
      });

      this.idleSessionService.addEventListener("idleSessionTimeout", (doLockCallback) => {
         if (this.modalDialogService.isDialogOpen(this.idleTimeoutWarningDialogId)) {
            this.modalDialogService.close(this.idleTimeoutWarningDialogId);
         }
         doLockCallback();
         if (!remoteSessionManager.hasApplicationSession()) {
            return;
         } else {
            this._disconnectAllApplications();
         }
         if (!this.modalDialogService.isDialogOpen(this.idleTimeoutDialogId)) {
            this.idleTimeoutDialogId = this.modalDialogService.showError({
               data: {
                  titleKey: "dialog_title_locked",
                  contentKey: "dialog_confirm_locked"
               },
               callbacks: {
                  confirm: () => {
                     if (this.ws1Service.isWS1Mode()) {
                        if (!remoteSessionManager.hasDesktopSession()) {
                           chrome.app.window.getAll().forEach((window) => {
                              window.close();
                           });
                        }
                     }
                  }
               }
            });
         }
      });

      this.idleSessionService.addEventListener("idleSessionWarning", (refreshCallback) => {
         // only when there exist application session, idleSessionWarning
         // dialogue will pop up like the behavior in windows native client
         if (!remoteSessionManager.hasApplicationSession()) {
            return;
         }
         // Don't show more than one warning at a time
         if (this.modalDialogService.isDialogOpen(this.idleTimeoutWarningDialogId)) {
            return;
         }
         this.idleTimeoutWarningDialogId = this.modalDialogService.showError({
            data: {
               titleKey: "dialog_title_about_to_timeout",
               contentKey: "dialog_confirm_about_to_timeout",
               buttonLabelConfirmKey: "OK"
            },
            callbacks: {
               confirm: () => {
                  refreshCallback();
               }
            }
         });
      });
   };

   public setJscdkWrapper = (jscdkWrapper) => {
      this.jscdkWrapper = jscdkWrapper;
   };

   public setDesktopPropertyGetter = (callback) => {
      this.desktopPropertyGetter = callback;
   };

   public clearIdleTimeoutDialog = () => {
      if (this.modalDialogService.isDialogOpen(this.idleTimeoutDialogId)) {
         this.modalDialogService.close(this.idleTimeoutDialogId);
      }
   };

   public hasConnectedSession = () => {
      return this.hasSession;
   };
}
