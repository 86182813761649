/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

 // general design, not specific to Chrome API
export type SDKEvent = {
   messageType: string, //which module/window should be processing this
   requestId: string, //uid for each request,
   isRequest: boolean,
   //eventName: any,
   data: any//payload
};

export type StructSchema = string;//change to ID to optimize

export type SDKRawEvent = any;
export type RequestBundle = {
   request: SDKRawEvent,
   requestId: string
}

export abstract class EventHelperBase {
   private static nextId: number = 0;
   protected _getUid = ():string => {
      // below implementation can ease debug and avoid duplicate, and uid is not used for security check.
      EventHelperBase.nextId %= 65536;
      return (new Date()).getTime() + "-" + EventHelperBase.nextId++;
   };
   public _getRequestPackage = (messageType: string, data: any): SDKEvent => {
      const request:SDKEvent = {
         messageType: messageType,
         requestId: this._getUid(),
         isRequest: true,
         data: data
      };
      return request;
   };
   protected _getResponsePackage = (messageType: string, data: any, requestId: string): SDKEvent => {
      const response:SDKEvent = {
         messageType: messageType,
         requestId: requestId,
         isRequest: false,
         data: data
      };
      return response;
   };
   public abstract getRequest: (messageType: string, data: any, dataType?: string) => RequestBundle;
   public abstract parseResponse: (rawResponse: SDKRawEvent) => SDKEvent;
   public abstract getResponse: (messageType: string, data: any, requestId: string, dataType?: string) => SDKRawEvent;
   public abstract parseRequest: (rawResponse: SDKRawEvent) => SDKEvent;
}