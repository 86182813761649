/**
 * ******************************************************
 * Copyright (C) 2016-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * username-util.js
 *
 * Service for username related code.
 *
 * Three login username formats here:
 * 1. username        (broker supported)
 * 2. username@domain (broker supported)
 * 3. domain\username (broker not supported)
 *
 */

export class UsernameUtil {
   public static isDomainUsername = (username) => {
      if (!username) {
         return false;
      }
      return username.indexOf("\\") !== -1 && username.indexOf("@") === -1;
   };

   public static isUPN = (username) => {
      if (!username) {
         return false;
      }
      return username.indexOf("@") !== -1 && username.indexOf("\\") === -1;
   };

   public static domainInUsername = (username) => {
      if (!username) {
         return false;
      }

      return UsernameUtil.isUPN(username) || UsernameUtil.isDomainUsername(username);
   };

   public static getUsernameFromDomainUsername = (username) => {
      if (!UsernameUtil.isDomainUsername(username)) {
         throw "Not an illegal domain username!";
      }

      const backslash = username.indexOf("\\");
      return username.substr(backslash + 1);
   };

   public static getDomainFromDomainUsername = (username) => {
      if (!UsernameUtil.isDomainUsername(username)) {
         throw "Not an illegal domain username!";
      }

      const backslash = username.indexOf("\\");
      return username.substr(0, backslash);
   };

   public static getUsernameFromUPN = (username) => {
      if (!UsernameUtil.isUPN(username)) {
         throw "Not an illegal UPN!";
      }

      const at = username.indexOf("@");
      return username.substr(0, at);
   };

   public static getDomainFromUPN = (username) => {
      if (!UsernameUtil.isUPN(username)) {
         throw "Not an illegal UPN!";
      }

      const at = username.indexOf("@");
      return username.substr(at + 1);
   };

   public static getUsername = (username) => {
      if (!UsernameUtil.domainInUsername(username)) {
         return username;
      }

      if (UsernameUtil.isUPN(username)) {
         return UsernameUtil.getUsernameFromUPN(username);
      } else if (UsernameUtil.isDomainUsername(username)) {
         return UsernameUtil.getUsernameFromDomainUsername(username);
      }

      throw "Cannot get legal username!";
   };

   public static getDomain = (username) => {
      if (!UsernameUtil.domainInUsername(username)) {
         return "";
      }

      if (UsernameUtil.isUPN(username)) {
         return UsernameUtil.getDomainFromUPN(username);
      } else if (UsernameUtil.isDomainUsername(username)) {
         return UsernameUtil.getDomainFromDomainUsername(username);
      }

      throw "Cannot get legal domain!";
   };

   public static isUsernameLegal = (username) => {
      return !(username.indexOf("\\") !== -1 && username.indexOf("@") !== -1);
   };

   public static isDomainLegal = (domain) => {
      // Only check if domain is empty now
      return !!domain;
   };

   public static isDomainInDomainList = (domain, domains) => {
      if (!domain || !domains || domains.length <= 0) {
         throw "Domain or domain list cannot be null!";
      }

      for (let i = 0; i < domains.length; i++) {
         if (domain.toLowerCase() === domains[i].toLowerCase()) {
            return true;
         }
      }
      return false;
   };
}
