/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import "../styles/style.css";
import "../styles/clipboard.css";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedCommonModule } from "../common/shared.common.module";
import { RemoteFeatureComponent } from "./remote-feature.component";
import { ClipboardModule } from "../../html5-client/desktop/clipboard/clipboard.module";
import { FTModule } from "../desktop/filetransfer/module";
import { PrintRedirectionModule } from "../desktop/printer-redirection/module";
import { FormsModule } from "@angular/forms";
import { ChromeClipboardModule } from "../../chrome-client/desktop/clipboard/clipboard.module";
import { UsbRedirectionModule } from "../desktop/usb/usb.module";
import { ToastComponent } from "../common/toast-container/toast-container.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RTAVModule } from "../desktop/rtav/rtav.module";

@NgModule({
   imports: [
      SharedCommonModule,
      CommonModule,
      ClipboardModule,
      FTModule,
      PrintRedirectionModule,
      FormsModule,
      UsbRedirectionModule,
      ChromeClipboardModule,
      NgbModule,
      RTAVModule
   ],
   declarations: [RemoteFeatureComponent, ToastComponent],
   exports: [RemoteFeatureComponent]
})
export class RemoteFeatureModule {}
