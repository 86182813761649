<div class="modal-title dialog-title">{{ 'TRUSTED_APPS' | i18nv2 }}</div>

<div class="modal-body dialog-content" fuzzy-free>
    <div class="modal-dialog-container">
        <div class="modal-dialog-setting">
            <div class="modal-dialog-list">
               <div *ngFor="let entry of defaultTrustedApps">
                  <div class="app-item .clearfix">
                      <div class="app-id" title="{{entry}}">{{entry}}
                          <label class="tgl-btn unselectable hidecontentlabel" for="{{entry}}" ></label>
                      </div>
                  </div>
                </div>
                <div *ngFor="let entry of trustedApps">
                    <div class="app-item .clearfix">
                        <div class="app-id" title="{{entry.address}}">{{entry.address}}</div>
                        <div class="modal-dialog-toggle-opt" tabindex="0" (keyup)="onKeypress($event)">
                            <input class="tgl tgl-light" id="{{entry.address}}" type="checkbox" [hidden]="true" [(ngModel)]="entry.accepted" [disabled]="false">
                            <label class="tgl-btn unselectable hidecontentlabel" for="{{entry.address}}" ></label>
                            <div class="delete-folder" (click)="deleteTrustedApp(entry.address)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-modal-dialog-container">
                <div class="add-trusted-app">
                    <input id="app-id-input" [(ngModel)]="inputAppID" class="app-input" (keyup.enter)="addTrustedAppsClicked()" type="text" placeholder="{{'ADD_TRUSTED_APPS' | i18nv2}}">
                </div>
                <div id="trusted-app-setting-add" class="modal-button-base modal-button-blue" [ngClass]="{'disable' : !allReady}" (click)="addTrustedAppsClicked()">
                    {{ 'ADD_BUTTON' | i18nv2 }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer dialog-button-row">
    <div id="trusted-app-setting-ok" class="modal-button-base modal-button-right modal-button-blue no-disable-hover" [ngClass]="{'disable' :!allReady}" (click)="trustedAppsOkClicked()">
        {{ 'OK' | i18nv2 }}
    </div>
    <div id="trusted-app-setting-cancel" class="modal-button-base modal-button-right modal-button-blue" (click)="closeModal()">
        {{ 'CANCEL' | i18nv2 }}
    </div>
</div>