/**
 * ******************************************************
 * Copyright (C) 2014-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * reconnectApplicationSessionsCommand.js --
 *
 * Called to setup and retrieve the parameters for application sessions
 * connection. The returned parameters should be used to launch appropriate
 * connection with Blast(default)
 *
 * will send getLaunchItem first, and later send
 * getApplicationSessionConnection for target sessions
 *
 * API struct "selectApplicationSessionAction" could be like:
 * {
 *    method                    : "ReconnectApplicationSessions",
 *    environmentInfo           : {%client info%},
 *    protocol                  : 'BLAST',
 *    disconnectAll             : false,
 *    targetSessionIds          : ["id1","id2"]
 * }
 *
 * due to lacking of UT and JSCDK is about to be refactor, I will not split
 * functions, instead I just add comments.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import Logger from "../../../core/libs/logger";
import Router from "../controllers/router";
import ReconnectAllApplicationSessionsCtrl from "../controllers/reconnectApplicationSessionsCtrl";
import GetLaunchItemsHandler from "../controllers/getLaunchItemsHandler";
import { PresetInfo } from "../jscdk-interface";

export default function ReconnectApplicationSessionsCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param selectApplicationSessionAction[in]
 *        - its property 'method' has the value 'ReconnectApplicationSessions'
 */
ReconnectApplicationSessionsCommand.prototype.execute = function (selectApplicationSessionAction) {
   let reconnectCtrl,
      supportedProtocols,
      getLaunchItemsObject,
      //please do not worry about the naming, it will be deleted after JSCDK
      // refactor
      presetInfo = {} as PresetInfo,
      getLaunchItemsHeader = "pre-reconnect-all-application-sessions",
      supportedAppProtocols,
      handlerList,
      router = globalArray["router"];

   if (!util.brokerSupportApplication()) {
      Logger.debug("error: broker do not support reconnect all application sessions!");
      return;
   }

   if (!router) {
      Logger.info("router is null.");

      router = new Router();
      globalArray[router.name] = router;
   }

   // config reconnectApplicationSessions Object
   supportedProtocols = globalArray.supportedProtocols || ["BLAST"];
   presetInfo.environmentInfo = selectApplicationSessionAction.environmentInfo;
   presetInfo.protocol = selectApplicationSessionAction.protocol || "BLAST";
   presetInfo.disconnectAll = selectApplicationSessionAction.disconnectAll;
   presetInfo.rdsLicenseInfo = selectApplicationSessionAction.rdsLicenseInfo;

   reconnectCtrl = globalArray["reconnect-application-sessions"];
   if (!reconnectCtrl) {
      reconnectCtrl = new ReconnectAllApplicationSessionsCtrl();
      globalArray[reconnectCtrl.messageName] = reconnectCtrl;
      globalArray[reconnectCtrl.responseTag] = reconnectCtrl;
   }
   reconnectCtrl.targetSessionIds = selectApplicationSessionAction.targetSessionIds;
   // excludedSessionId is an id indicating the corresponding session
   // should not to be reconnected, could be origin-id or session-id
   reconnectCtrl.excludedSessionId = selectApplicationSessionAction.excludedSessionId;
   reconnectCtrl.presetInfo = presetInfo;

   // config getLaunchItems Object
   getLaunchItemsObject = globalArray[getLaunchItemsHeader + "get-launch-items"];
   if (!getLaunchItemsObject) {
      getLaunchItemsObject = new GetLaunchItemsHandler(true, true);
      globalArray[getLaunchItemsHeader + getLaunchItemsObject.messageName] = getLaunchItemsObject;
      globalArray[getLaunchItemsHeader + getLaunchItemsObject.responseTag] = getLaunchItemsObject;
   } else {
      getLaunchItemsObject.resetData();
   }

   getLaunchItemsObject.appendSubHandler(reconnectCtrl);

   if (!!getLaunchItemsObject && !!router) {
      // start getLaunchItem sending
      supportedAppProtocols = [
         {
            name: "remote",
            protocols: supportedProtocols
         }
      ];
      getLaunchItemsObject.setRequestXML(supportedProtocols, supportedAppProtocols, false);
      handlerList = getLaunchItemsObject.composeHandlerList();
      router.postMessage(handlerList);
   } else {
      Logger.error("the jscdk is in wired status");
   }
};
