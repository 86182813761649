/**
 * ******************************************************
 * Copyright (C) 2012-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * urlHandler.js --
 *
 *      Implementation of the handler to deal with url
 */

import $ from "jquery";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import { ParseUriInfo, PushErrorToUI } from "../jscdk-interface";
import { clientUtil } from "../../../core/libs";

// the list for action names.
const ActionEnum = {
   STARTSESSION: "start-session",
   BROWSE: "browse",
   LOGOFF: "logoff",
   RESET: "reset"
};
const UrlHandlerError = {
   URI_ERROR_INVALID_URL_PARAMETER: "INVALID_URL_PARAMETER"
};

export default function UrlHandler() {
   // member variables below
   this.name = "url-handler";

   this.params = {
      samlArt: null,
      accessToken: null,
      user: null,
      userName: null,
      tokenUserName: null,
      domainName: null,
      host: null,
      port: null,
      desktopId: null,
      desktopName: null,
      desktopProtocol: null,
      collabSessionId: null,
      applicationId: null,
      applicationName: null,
      itemId: null,
      schemeName: null,
      path: null,
      action: null,
      horizonId: null,
      mid: null,
      args: null,
      unauthenticatedAccessEnabled: null,
      unauthenticatedAccessAccount: null,
      spID: null,
      broker: null,
      isFA: null,
      url: null, // The URL will be visited after the desktop/app starts
      webviewMode: null,
      ota: null,
      entitlementId: null,
      authCallbacks: null
   } as UrlParams;
}

UrlHandler.constructor = UrlHandler;

/**
 * handle url params in url
 *
 * @param url [in] url to be handled, such as window.location.search
 *
 */
UrlHandler.prototype.handleUrlParams = function (url) {
   const parsedUriInfo = {} as ParseUriInfo;
   try {
      if (typeof url === "object") {
         this.fillParams(url);
         if (this.isErrorInParameters()) {
            Logger.error("Url has invalid param");
            return;
         }

         Logger.debug("Url has valid param");

         parsedUriInfo.name = "ParsedUriInfo";
         parsedUriInfo.content = this.params;
         JSCDKSetUI(JSON.stringify(parsedUriInfo));
      } else if (typeof url === "string") {
         this.loadParams(url);

         parsedUriInfo.name = "Brokers";
         parsedUriInfo.params = this.params;
         JSCDKSetUI(JSON.stringify(parsedUriInfo));
      }
   } catch (error) {
      Logger.error("Url params are invalid: " + error);

      parsedUriInfo.name = "Brokers";
      JSCDKSetUI(JSON.stringify(parsedUriInfo));
   }
};

/**
 * validate params in url and fill params
 *
 * @param url [in] url to be handled, such as window.location.search
 *
 */
UrlHandler.prototype.fillParams = function (url) {
   let urlKey, paramKey;

   for (urlKey in url) {
      if (url.hasOwnProperty(urlKey)) {
         for (paramKey in this.params) {
            if (this.params.hasOwnProperty(paramKey)) {
               if (paramKey.toLowerCase() === urlKey.toLowerCase()) {
                  this.params[paramKey] = url[urlKey];
                  break;
               }
            }
         }
      }
   }

   if (this.params.samlArt) {
      this.params.samlArt = decodeURIComponent(this.params.samlArt);
   }

   if (this.params.userName) {
      this.params.userName = decodeURIComponent(this.params.userName);
   } else if (this.params.user) {
      this.params.userName = decodeURIComponent(this.params.user);
   }

   if (this.params.host) {
      this.params.host = decodeURIComponent(this.params.host);
   }

   // the vcsId is not decoded, and will be returned and used directly

   /* The desktop id is indicated in the path param.
    * The path param starts with "/", so it needs to be removed to get the desktop id.
    */
   if (!!this.params.path && this.params.path.length > 1) {
      this.params.desktopId = decodeURIComponent(this.params.path.substring(1));
   }

   // At present RDP is the only supported protocol for Metro client.
   if (!this.params.desktopProtocol) {
      this.params.desktopProtocol = "RDP";
   }
};
/**
 * This is done according to
 * https://wiki.eng.vmware.com/VDM/ClientBrokerXml#Request_23 and
 * https://wiki.eng.vmware.com/EUCBU/RX/SupportRDSHApplicationParameters
 * @sideeffects before this function, this.params.args is the escaped command
 *    line string, after, this.params.args will be an array of split command
 *    lines according to XML spec
 */
UrlHandler.prototype.formatArgs = function () {
   let arg;
   if (this.params.args) {
      // use whole input string as the first and only param as spec requires
      arg = this.params.args;
      this.params.args = [arg];
   } else if (clientUtil.isChromeClient() && this.params.url) {
      // support application browsing URL for URL redirection
      this.params.args = [this.params.url];
   }
};

UrlHandler.prototype.loadParams = function (url) {
   let a, key, value;

   a = $("<a>", {
      href: url
   })[0];
   this.params.host = a.hostname;
   this.params.port = a.port;
   this.params.schemeName = a.protocol;

   // Load mandatory params.
   for (key in this.params) {
      if (this.params.hasOwnProperty(key)) {
         value = this.parseParam(a.search, key);
         if (value) {
            this.params[key] = value;
         }
      }
   }

   // BLAST is the default protocol.
   if (!this.params.desktopProtocol) {
      this.params.desktopProtocol = "BLAST";
   }

   // BLAST is the default protocol.
   if (!this.params.userName && !!this.params.user) {
      this.params.userName = this.params.user;
   }
   this.formatArgs();
};

/**
 * parse param value from url, param name is considered case-insensitiv
 *   param value is considered case-sensitive
 *
 * @param url [in] url to be handled, such as window.location.search
 * @param name [in] name of param key
 * @return param value
 *
 */
UrlHandler.prototype.parseParam = function (url, name) {
   if (__CLIENT_TYPE__ === "titan") {
      return this.parseParamV2(url, name);
   }
   return this.parseParamV1(url, name);
};

UrlHandler.prototype.parseParamV1 = function (url, name) {
   // use 'i' here to ignore case of param name
   // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent
   // decodeURIComponent() cannot be used directly to parse query parameters from a URL. It needs a bit of preparation.
   url = url.replace(/\+/g, " ");
   return decodeURIComponent(
      (new RegExp(".*([?|&]" + name + "=" + "([^&;]+?)(&|#|;|$))", "i").exec(url) || [null, null, ""])[2]
   );
};

UrlHandler.prototype.parseParamV2 = function (url, name) {
   return decodeURIComponent(
      (new RegExp(".*([?|&]" + name + "=" + "([^&;]+?)(&|#|;|$))", "i").exec(url) || [null, null, ""])[2]
   );
};

/**
 * refresh data
 *
 */
UrlHandler.prototype.refresh = function () {
   this.params = null;
};

/**
 * reset all params.
 */
UrlHandler.prototype.resetParams = function () {
   let key;
   for (key in this.params) {
      if (this.params.hasOwnProperty(key)) {
         this.params[key] = null;
      }
   }
};

/**
 * Check whether there is an error in parameters.
 *
 * @return true if there is an error, otherwise false.
 */
UrlHandler.prototype.isErrorInParameters = function () {
   const pushErrorToUI = {} as PushErrorToUI;

   pushErrorToUI.content = {} as PushErrorToUI["content"];

   if (!this.params.action || this.params.action.toLowerCase() === ActionEnum.BROWSE) {
      return false;
   }

   // For other actions like "start-session" there should be a desktopId.
   if (!this.params.desktopId) {
      // push error to UI
      pushErrorToUI.name = "ShowError";
      pushErrorToUI.content.errorType = UrlHandlerError.URI_ERROR_INVALID_URL_PARAMETER;
      pushErrorToUI.content.errorText = util._("JSCDK_ERROR_URL_INVALID");
      JSCDKSetUI(JSON.stringify(pushErrorToUI));
      return true;
   }
   return false;
};

/**
 * set samlart
 */
UrlHandler.prototype.setSAML = function (samlArt) {
   if (!this.params) {
      this.params = {};
   }
   this.params["samlArt"] = samlArt;
   globalArray["samlArt"] = samlArt;
};

UrlHandler.prototype.resetSAML = function () {
   if (this.params) {
      this.params["samlArt"] = null;
   }
   globalArray["samlArt"] = null;
   Logger.debug("Saml is cleared in url handler.");
};
