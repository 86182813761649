<li (click)="launchItem($event)" [hidden]="!showConnect" id="menu-item-launch-btn">
   <button>
      <div class="menu-item-icon icon-connect-image"></div> {{'CONNECT_M' | i18nv2}}
   </button>
</li>
<li (click)="logOffDesktop($event)" [hidden]="!showLogOffDesktop" id="menu-item-logoff-btn">
   <button>
      <div class="menu-item-icon icon-logoff-desktop-image"></div> {{'LOGOFF_T' | i18nv2}}
   </button>
</li>
<li (click)="resetDesktop($event)" [hidden]="!showResetDesktop" id="menu-item-reset-btn">
   <button>
      <div class="menu-item-icon icon-reset-image"></div> {{'RESET_M' | i18nv2}}
   </button>
</li>
<li (click)="restartDesktop($event)" [hidden]="!showRestartDesktop" id="menu-item-restart-btn">
   <button>
      <div class="menu-item-icon icon-restart-image"></div> {{'RESTART_M' | i18nv2}}
   </button>
</li>
<li (click)="closeRunningItem($event)" [hidden]="!showClose" id="menu-item-close-btn">
   <button>
      <div class="menu-item-icon icon-close-desktop-image"></div> {{'CLOSE' | i18nv2}}
   </button>
</li>