/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../../../core/libs/logger";
import { HTML5MMR_CONST } from "../html5MMR-consts";
export namespace HTML5MMR {
   export enum RequestType {
      WEBTEXT = "WEBTEXT",
      WEBBINARY = "WEBBINARY",
      UPDATEENV = "UPDATEENV",
      UPDATEOVERLAY = "UPDATEOVERLAY",
      VERSIONINFO = "VERSIONINFO",
      DISCONNECT = "DISCONNECT",
      UNKNOWN = "UNKNOWN"
   }

   export enum EnvFlag {
      DPI = "DPI",
      VOLUME = "VOLUME",
      CLIENT_TOPOLOGY = "CLIENT_TOPOLOGY",
      UNKNOWN = "UNKNOWN"
   }

   /**
    * This model represents a single RPC request
    */
   export abstract class MMRRequest {
      public action: number;
      public command: number;
      public params: Array<any>;
      public returnCode: number;
      public type: string;

      private rpc: any;

      constructor(rpc: any) {
         this.action = rpc.action;
         this.command = rpc.command;
         this.params = rpc.params;
         this.returnCode = rpc.returnCode;
         this.type = rpc.type;
         this.rpc = rpc;
      }

      /**
       * Determine what kind of MMR Request it is based on given RPC object
       *
       * @param  {any} rpc given RPC object
       * @returns {HTML5MMR.RequestType}
       */
      public static kind(rpc: any): RequestType {
         if (rpc === null || !rpc.command) {
            return RequestType.UNKNOWN;
         }
         const command: number = rpc.command;
         switch (command) {
            case HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT:
               return RequestType.WEBTEXT;
            case HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBBINARY:
               return RequestType.WEBBINARY;
            case HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_UPDATE_ENV:
               return RequestType.UPDATEENV;
            case HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_UPDATE_OVERLAY:
               return RequestType.UPDATEOVERLAY;
            case HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_EXCHANGE_VERSIONINFO:
               return RequestType.VERSIONINFO;
            case HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_DISCONNECT:
               return RequestType.DISCONNECT;
            default:
               return RequestType.UNKNOWN;
         }
      }

      /**
       * Instance Id is always the 1st parameter
       */
      public get instanceId(): number {
         const instId = this.params[0];
         if (instId === null) {
            Logger.error("Unknown instance id");
            return null;
         }
         return instId;
      }

      /**
       * Web Command enum is always the 3rd parameter
       */
      public get webCommand(): number {
         const webCmd = this.params[2];
         if (webCmd === null) {
            Logger.error("Unknown web command");
            return null;
         }
         return webCmd;
      }

      /**
       * Obtain JSON string representation of this MMR Request
       *
       * @returns string
       */
      public get JSONString(): string {
         return JSON.stringify(this.rpc);
      }

      /**
       * Obtain JSON string representation of this MMR request's params attribute
       *
       * @returns string
       */
      public get paramsJSONString(): string {
         return JSON.stringify(this.params);
      }

      /**
       * Abstract function that determines if this request is valid
       *
       * @returns boolean
       */
      public abstract get isValid(): boolean;
   }

   export class MMRWebTextRequest extends MMRRequest {
      /**
       * Determine if this {MMRWebTextRequest} is valid based on its parameters
       *
       * @returns boolean
       */
      public get isValid(): boolean {
         return this.instanceId !== null && this.webCommand !== null && this.webTextPayload !== null;
      }

      /**
       * Retrieve WebText Parameters object
       *
       * @returns any
       */
      public get webTextPayload(): any {
         Logger.debug("HTML5 MMR WebCommandObj: " + JSON.stringify(this.params));
         const webCmdObjText: string = this.params[3];
         if (webCmdObjText === null) {
            Logger.error("Unknown web command object text. " + JSON.stringify(this.params));
            return null;
         }
         try {
            return JSON.parse(webCmdObjText);
         } catch (err) {
            Logger.error(err);
            return null;
         }
      }

      /**
       * Retrieve WebText Parameters as JSON string
       *
       * @returns string
       */
      public get webTextPayloadString(): string {
         return this.params[3];
      }
   }

   export class MMRUpdateEnvRequest extends MMRWebTextRequest {
      /**
       * Environment Flag
       *
       * @returns EnvFlag
       */
      public get envFlag(): EnvFlag {
         const payload = this.webTextPayload;
         if (payload === null || payload.envFlag === null) {
            Logger.error("Unknown env flag");
            return null;
         }
         const flag: number = payload.envFlag;
         switch (flag) {
            case HTML5MMR_CONST.MMR_ENV.HTML5MMR_ENV_DPI:
               return EnvFlag.DPI;
            case HTML5MMR_CONST.MMR_ENV.HTML5MMR_ENV_VOLUME:
               return EnvFlag.VOLUME;
            default:
               return EnvFlag.UNKNOWN;
         }
      }

      /**
       * Determine if this {MMRUpdateEnvRequest} is valid based on its parameters
       *
       * @returns boolean
       */
      public get isValid(): boolean {
         return (
            this.instanceId !== null &&
            this.webCommand !== null &&
            this.webTextPayload !== null &&
            this.envFlag !== null
         );
      }
   }

   export class MMRUpdateOverlayRequest extends MMRRequest {
      /**
       * Determine if this {MMRUpdateOverlayRequest} is valid based on its parameters
       *
       * @returns boolean
       */
      public get isValid(): boolean {
         return this.instanceId !== null;
      }

      public get videoId(): number {
         const vId = this.params[1];
         if (vId === null) {
            Logger.error("Unknown video id");
            return null;
         }
         return vId;
      }

      public get enabled(): boolean {
         return this.params[2] === 1; // 1bit either 0 or 1
      }

      public get visible(): boolean {
         return this.params[3] === 1; // 1bit either 0 or 1
      }

      public get colorKey(): number {
         return this.params[4]; // 32bit unsigned int
      }

      public get left(): number {
         const posLeft = this.params[5]; // 32bit int
         if (posLeft === null) {
            Logger.error("Unknown position left");
            return null;
         }
         return posLeft;
      }

      public get top(): number {
         const posTop = this.params[6]; // 32bit int
         if (posTop === null) {
            Logger.error("Unknown position top");
            return null;
         }
         return posTop;
      }

      public get width(): number {
         const sizeWidth = this.params[7]; // 32bit int
         if (sizeWidth === null) {
            Logger.error("Unknown size width");
            return null;
         }
         return sizeWidth;
      }

      public get height(): number {
         const sizeHeight = this.params[8]; // 32bit int
         if (sizeHeight === null) {
            Logger.error("Unknown size height");
            return null;
         }
         return sizeHeight;
      }

      public get count(): number {
         const countData = this.params[9]; // 32bit int
         if (countData === null) {
            Logger.error("Unknown count");
            return null;
         }
         return countData;
      }

      public get rgnDataSize(): number {
         const regionDataSize = this.params[10]; // 32bit int
         if (regionDataSize === null) {
            Logger.error("Unknown region data size");
            return null;
         }
         return regionDataSize;
      }

      public get rgnData(): Uint8Array {
         const regionDataArray = this.params[11]; // Uint8Array
         if (regionDataArray === null) {
            Logger.error("Unknown region data array");
            return null;
         }
         return regionDataArray;
      }
   }

   export class MMRWebBinaryRequest extends MMRRequest {
      /**
       * Determine if this {MMRWebBinaryRequest} is valid based on its parameters
       *
       * @returns boolean
       */
      public get isValid(): boolean {
         return this.instanceId !== null && this.webCommand !== null;
      }

      /**
       * Retrieve stream status enum
       *
       * @returns number
       */
      public get streamEnum(): number {
         const sEnum = this.params[3];
         if (sEnum === null) {
            Logger.error("Unknown stream enum");
            return null;
         }
         return sEnum;
      }

      /**
       * Retrieve binary data length
       *
       * @returns number
       */
      public get binaryDataLength(): number {
         const length = this.params[4];
         if (length === null) {
            Logger.error("Unknown binary data length");
            return null;
         }
         return length;
      }

      /**
       * Retrieve binary data as byte array
       *
       * @returns Uint8Array
       */
      public get binaryData(): Uint8Array {
         const data = this.params[5];
         if (data === null) {
            Logger.error("Unknown binary data");
            return null;
         }
         return data;
      }
   }
   export class MMRVersionInfoRequest extends MMRRequest {
      /**
       * Determine if this {MMRWebBinaryRequest} is valid based on its parameters
       *
       * @returns boolean
       */
      public get isValid(): boolean {
         return this.command !== null && this.webCommand !== null;
      }
      public data(index: number): number {
         const data = this.params[index];
         if (data === null) {
            Logger.error("Parameter missing in the packet for VersionInfo");
            return null;
         }
         return data;
      }
      public get majorVersion(): number {
         return this.data(0);
      }
      public get minorVersion(): number {
         return this.data(1);
      }
      public get capability(): number {
         return this.data(2);
      }
   }

   export class MMRDisconnectRequest extends MMRRequest {
      /**
       * Determine if this {MMRWebBinaryRequest} is valid based on its parameters
       *
       * @returns boolean
       */
      public get isValid(): boolean {
         return this.instanceId !== null && this.webCommand !== null;
      }
   }
}
