<div class="modal-title dialog-title">{{ 'GESTURE_HELP' | i18nv2 }}</div>
<div class="modal-body dialog-content" fuzzy-free>
   <div class="trackpadTouchMode-content" [hidden]="!isTrackPadMode()">
      <div *ngFor="let subGestures of allGestures">
         <div class="gesture-category">{{subGestures.category | i18nv2}}</div>
         <div *ngFor="let gesture of subGestures.gestures">
            <div class="gestures-container clearfix">
               <div class="gesture-img" [ngClass]="gesture.imgSelector"></div>
               <div class="gesture-content">
                  <div class="gesture-name"> {{gesture.name | i18nv2}}</div>
                  <div class="gesture-description"> {{gesture.description | i18nv2}}</div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="nativeTouchMode-content" [hidden]="isTrackPadMode()">
      <div class="gesture-category gesture-category-nativeTouchMode">
         <span>{{"GESTURE_NATIVETOUCH_DESCRIPTION_FIRST" | i18nv2}}</span>
         <br><br>
         <span> {{"GESTURE_NATIVETOUCH_DESCRIPTION_SECOND" | i18nv2}}</span>
      </div>
      <div class="gestures-container clearfix">
         <div class="gesture-img gesture-tapthree"></div>
         <div class="gesture-content">
            <div class="gesture-name"> {{"GESTURE_KEYBOARD_NAME" | i18nv2}}</div>
            <div class="gesture-description"> {{"GESTURE_KEYBOARD_DESCRIPTION" | i18nv2}}</div>
         </div>
      </div>
   </div>
</div>
<div class="modal-footer dialog-button-row">
   <button type="button" ngbAutofocus id="closeHelpBtn" class="modal-button-base modal-button-right modal-button-blue"
      (click)="closeModal()" [attr.aria-describedby]="'CLOSE_GESTURE_HELP' | i18nv2">
      {{ 'OK' | i18nv2 }}
   </button>
</div>