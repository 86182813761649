/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getApplicationSessionConnectionHandler.js --
 *
 *      Implementation of the message handler to get application session
 * connection.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import ProtocolRedirectionController from "./redirect/protocol-redirection-controller";
import { ResponseObj } from "../jscdk-interface";
import { ShowApplicationAction } from "../jscdk-interface";
import { CryptoKeyDerivationService } from "../../common/service/crypto-key-derivation.service";
import { getUuid } from "@html-core";

const BlastType = {
   CURRENT: "current",
   NEW: "new"
};

export default function GetApplicationSessionConnectionHandler() {
   // member variables
   this.messageName = "get-application-session-connection";
   this.messageText = "get-application-session-connection";
   this.responseTag = "application-session-connection";

   this.composedHandlerList = [];

   // Use current blastType by default.
   this.blastType = BlastType.CURRENT;
   this.callbackFunction = null;
   this.targetAppSessionId = null;
   this.originId = null;
   this.redirectMsg = false;

   this.preferences = {};

   // register dependencies here
   let getLaunchItemsObject;
   let getTunnelConnObject;
   let router;
   getLaunchItemsObject = util.getObject(globalArray, "get-launch-items");
   if (getLaunchItemsObject) {
      this.registerHandler(getLaunchItemsObject, "state");
   }
   getTunnelConnObject = util.getObject(globalArray, "get-tunnel-connection");
   if (getTunnelConnObject) {
      this.registerHandler(getTunnelConnObject, "state");
   }
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

GetApplicationSessionConnectionHandler.prototype = new MessageHandler(); // inherits
// MessageHandler
// prototype
GetApplicationSessionConnectionHandler.constructor = GetApplicationSessionConnectionHandler;

/**
 * using a bool to switch to call back API, which will be formal one after JSCDK
 * refactory
 */
GetApplicationSessionConnectionHandler.prototype.setCallbackFunction = function (callbackFunction) {
   this.callbackFunction = callbackFunction;
};

GetApplicationSessionConnectionHandler.prototype.setOriginId = function (originId) {
   this.originId = originId;
};

/**
 * Set the request XML for getApplicationSessionConnection.
 *
 * @param applicationSessionId [in] applicationSession id to connect
 * @param protocol [in] which protocol to use
 * @param environmentInfo [in] optional object containing client info key/value
 *    pairs
 */

GetApplicationSessionConnectionHandler.prototype.setRequestXML = function (
   applicationSessionId,
   protocol,
   environmentInfo,
   disconnectAll,
   rdsLicenseInfo
) {
   let applicationSessionIdElement = util.createElement("application-session-id", applicationSessionId),
      nameElement = util.createElement("name", protocol),
      protocolElement = util.createElement("protocol", nameElement),
      disconnectAllElement = "",
      environmentElement = "",
      infoElements = "",
      key,
      attrArray,
      rdsLicenseElement = "",
      rdsLicenseInfoElements = "";

   if (disconnectAll === false) {
      disconnectAllElement = util.createElement("disconnect-all-sessions", "false");
   } else {
      disconnectAllElement = util.createElement("disconnect-all-sessions", "true");
   }

   this.targetAppSessionId = applicationSessionId;

   // Generate the <environment-info> section if it was specified.
   if (typeof environmentInfo === "object") {
      for (key in environmentInfo) {
         if (environmentInfo.hasOwnProperty(key)) {
            infoElements += util.createElement("info", environmentInfo[key], {
               name: key
            });
         }
      }
      environmentElement = util.createElement("environment-information", infoElements);
   }
   // Generate the <rds-license> section if it was specified.
   if (rdsLicenseInfo) {
      for (key in rdsLicenseInfo) {
         if (rdsLicenseInfo.hasOwnProperty(key)) {
            rdsLicenseInfoElements += util.createElement(key, rdsLicenseInfo[key]);
         }
      }
      rdsLicenseElement = util.createElement("rds-license", rdsLicenseInfoElements);
   }

   this.requestXML = applicationSessionIdElement + disconnectAllElement + protocolElement + environmentElement;
   if (rdsLicenseElement) {
      this.requestXML += rdsLicenseElement;
   }
};

GetApplicationSessionConnectionHandler.prototype.getConvertedResponse = function (responseAction) {
   const responseObj = {} as ResponseObj;

   responseObj.success = true;
   responseObj.applicationSessionId = this.targetAppSessionId;
   switch (responseAction.name) {
      case "ShowAppBlastApplicationSession":
         responseObj.protocolIsBlast = true;
         responseObj.needNewConnectionWithBlast = true;
         responseObj.content = responseAction.content;
         responseObj.content.originId = this.originId;
         // compatible with connection response
         responseObj.content.callbackParams = {
            callbackParams: this.originId
         };
         break;
      case "LaunchApplicationSession":
         responseObj.protocolIsBlast = false;
         responseObj.content = responseAction.content;
         break;
      case "ApplicationSessionAlreadyConnected":
         responseObj.protocolIsBlast = true;
         responseObj.needNewConnectionWithBlast = false;
         responseObj.content = responseAction.content;
         responseObj.content.originId = this.originId;
         break;
   }
   return responseObj;
};

GetApplicationSessionConnectionHandler.prototype.hasError = function () {
   return !!this.content["error"];
};

GetApplicationSessionConnectionHandler.prototype.getErrorResponseBy = function (errorObj, sessionId) {
   return {
      success: false,
      error: errorObj,
      applicationSessionId: this.targetAppSessionId,
      originId: this.originId,
      sessionId: sessionId || ""
   };
};

GetApplicationSessionConnectionHandler.prototype.handleSelfDefinedError = function (userMessage, sessionId) {
   let errorObj, errorResponse;

   if (typeof this.callbackFunction === "function") {
      errorObj = util.getSelfDefinedErrorObjectBy(this, userMessage);
      errorResponse = this.getErrorResponseBy(errorObj, sessionId);
      this.callbackFunction(errorObj);
      this.callbackFunction = null;
   } else {
      Router.prototype.pushSelfDefinedError(this, userMessage);
   }
};

GetApplicationSessionConnectionHandler.prototype.handleSuccess = function (responseAction) {
   let convertedResult;

   if (typeof this.callbackFunction === "function") {
      convertedResult = this.getConvertedResponse(responseAction);
      this.callbackFunction(convertedResult);
      this.callbackFunction = null;
   } else {
      JSCDKSetUI(JSON.stringify(responseAction));
   }
};

GetApplicationSessionConnectionHandler.prototype.handleError = function () {
   let errorObj,
      errorResponse,
      key = this.messageName;

   if (typeof this.callbackFunction === "function") {
      errorObj = this.content["error"][key];
      errorResponse = this.getErrorResponseBy(errorObj);
      this.callbackFunction(errorResponse);
      this.callbackFunction = null;
   } else {
      Router.prototype.pushErrorToUser(this);
   }
};

GetApplicationSessionConnectionHandler.prototype.onUpdated = function () {
   this.decryptResult();
   this.redirectMsg = false;

   if (
      this.content["parsedResult"] &&
      this.content["parsedResult"]["result"] === "ok" &&
      this.content["parsedResult"]["redirect-settings"]
   ) {
      let protocolRedirectionController = util.getObject(globalArray, "protocol-redirect-controller");
      if (!protocolRedirectionController) {
         protocolRedirectionController = new ProtocolRedirectionController();
         globalArray["protocol-redirect-controller"] = protocolRedirectionController;
      }
      protocolRedirectionController
         .redirect("ApplicationSession", this.content["parsedResult"])
         .then((fullResponse) => {
            this.content["parsedResult"] = fullResponse;
            this.redirectMsg = true;
            this._onUpdated();
         })
         .catch((errorObject) => {
            Logger.info(errorObject);
            this.redirectMsg = true;
            this._onUpdated();
         });
   } else {
      this._onUpdated();
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetApplicationSessionConnectionHandler.prototype._onUpdated = function () {
   let parsedResult,
      needConnectionText,
      protocolName,
      token,
      launchArgs,
      address,
      port,
      userMessage,
      showApplicationSessionAction: ShowApplicationAction = {} as ShowApplicationAction,
      applicationSessionResolution = {},
      content = {} as ShowApplicationAction["content"],
      router,
      id,
      sessionId,
      userName,
      domainName,
      enableUsb,
      usbTicket,
      enableMmr,
      rdsLicense,
      protocolRedirectionController = util.getObject(globalArray, "protocol-redirect-controller");

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   /**
    * Add triggerItemInfo for all launch
    * for bug 3161107 VCART-483
    */
   this.triggerItemInfo = {
      type: "HorizonAppSession",
      id: this.targetAppSessionId,
      triggerName: this.targetAppSessionId
   };
   content.triggerItemInfo = this.triggerItemInfo;

   if (this.state === StateEnum.DONE) {
      if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
         parsedResult = this.content["parsedResult"];
         needConnectionText = parsedResult["new-connection-needed"];

         if (needConnectionText === "true") {
            protocolName = parsedResult["protocol"];
            if (protocolName.toLowerCase() === "blast") {
               id = parsedResult["id"];
               sessionId = parsedResult["session-id"];
               userName = parsedResult["user-name"];
               domainName = parsedResult["domain-name"];
               address = parsedResult["address"];
               port = parsedResult["port"];
               token = parsedResult["token"];
               if (token === "") {
                  return;
               }
               if (token) {
                  launchArgs = JSON.parse(token);
                  if (!launchArgs) {
                     userMessage = util._("JSCDK_TOKEN_ERROR_JSON_FORMAT");
                     Logger.error("Blast token is not a valid JSON.");
                  } else if (!launchArgs["a"]) {
                     userMessage = util._("JSCDK_TOKEN_ERROR_JSON_PROPERTY");
                     Logger.error("Blast token has no 'a' property.");
                  }
                  if (userMessage) {
                     // push error to UI
                     this.handleSelfDefinedError(userMessage, sessionId);
                     return;
                  }
               } else {
                  //throw an error
                  userMessage = util._("JSCDK_ERROR_BLAST_TOKEN_ERROR");
                  Logger.error("Blast token does not exist.");
                  this.handleSelfDefinedError(userMessage);
                  return;
               }

               enableUsb = parsedResult["enable-usb"];
               usbTicket = parsedResult["framework-channel-ticket"];
               enableMmr = parsedResult["enable-mmr"];
               if (parsedResult["rds-license"]) {
                  rdsLicense = parsedResult["rds-license"];
               }

               showApplicationSessionAction.name = "ShowAppBlastApplicationSession";
               content.id = id;
               content.sessionId = sessionId;
               content.userName = userName;
               content.domainName = domainName;
               content.url = this.createAppBlastLaunchApplicationSessionAction(address, port, launchArgs);
               content.enableUsb = enableUsb;
               content.enableMmr = enableMmr;
               content.usbTicket = usbTicket;
               content.preferences = this.preferences;
               content.preferences.blastType = this.blastType;
               content.isApplicationSession = true;
               content.rdsLicense = rdsLicense;
               showApplicationSessionAction.content = content;
            } else {
               // PCoIP or RDP just tells the UI about all of the parameters.
               showApplicationSessionAction.name = "LaunchApplicationSession";
               showApplicationSessionAction.content = parsedResult;
            }
         } else {
            // no need to connect to a application that is currently connected
            showApplicationSessionAction.name = "ApplicationSessionAlreadyConnected";
            // the UI side for this
            // action
            id = parsedResult["id"];
            sessionId = parsedResult["session-id"];
            content.id = id;
            content.sessionId = sessionId;
            showApplicationSessionAction.content = content;
            //can throw an exception here instead if needed
         }
         router = globalArray["router"];
         // Common code for appblast or others.
         if (router) {
            showApplicationSessionAction.content.brokerUrl = encodeURIComponent(router.brokerUrl);
         }
         /**
          * parsed results of getApplicationSessionConnection are of no use
          * after this function; reset data before UI refresh to avoid it
          * triggered by dependencies for multiple times reset data should be
          * before JSCDKSetUI to avoid unpredictable error during SetUI
          */
         this.content = {};

         this.handleSuccess(showApplicationSessionAction);
      }
   }
   // if the error is special for this handler, alert

   if (this.hasError()) {
      this.handleError();
   }
};

/**
 * parse applicationSession connection information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */

GetApplicationSessionConnectionHandler.prototype.parseResult = function (responseXML) {
   let brokerTag;
   let responses;
   let result;
   let tokenText;
   const applicationSessionConnectionResponse = {};
   let xmlDoc;
   let listeners;
   let listenersNode;
   let listenerNode;
   let listener;
   let agentResponseNode;
   let agentResponse;
   let errorCode;
   let protocol;
   let protocolObj;
   let i;

   xmlDoc = $(responseXML);
   brokerTag = $(xmlDoc.children()[0]);
   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases
       */
      //make no check for the new-connection-needed property existence with
      // result set "ok", the absent of this property will cause
      // response.name = "ApplicationAlreadyConnected" in onUpdated(), works
      // well if <id> is at least provided
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         if (nameText === "redirect-settings") {
            applicationSessionConnectionResponse["redirect-settings"] = util.parseXML($(this).children());
         } else {
            util.addItemForJson(applicationSessionConnectionResponse, nameText, $(this).text());
         }
      });

      listenersNode = util.getChildNode($(responses)[0], "additional-listeners", 0);
      if (listenersNode) {
         listenerNode = listenersNode.getElementsByTagName("additional-listener");
      }
      if (listenerNode) {
         listeners = [];
         for (i = 0; i < listenerNode.length; i++) {
            listener = {};
            util.addItemForJson(listener, "name", listenerNode[i].getAttribute("name"));
            util.addItemForJson(listener, "value", $(listenerNode[i]).text());
            listeners[i] = listener;
         }

         util.addItemForJson(applicationSessionConnectionResponse, "additional-listeners", listeners);
      }
      // handle token and default values here
      tokenText = applicationSessionConnectionResponse["protocol-settings"];
      if (tokenText) {
         util.addItemForJson(applicationSessionConnectionResponse, "token", tokenText);
      }
      return applicationSessionConnectionResponse;
   } else if (result === "error") {
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(applicationSessionConnectionResponse, nameText, $(this).text());
      });

      agentResponseNode = util.getChildNode($(responses)[0], "agent-response", 0);
      if (agentResponseNode) {
         agentResponse = {};
         errorCode = util.getChildNode(agentResponseNode, "error-code", 0);
         util.addItemForJson(agentResponse, "error-code", $(errorCode).text());

         protocol = util.getChildNode(agentResponseNode, "protocol", 0);
         if (protocol) {
            protocolObj = {};
            util.addItemForJson(protocolObj, "name", $(util.getChildNode(protocol, "name", 0)).text());
            util.addItemForJson(protocolObj, "error-code", $(util.getChildNode(protocol, "error-code", 0)).text());
            util.addItemForJson(agentResponse, "protocol", protocolObj);
         }

         util.addItemForJson(applicationSessionConnectionResponse, "agent-response", agentResponse);
      }

      return applicationSessionConnectionResponse;
   }
   return applicationSessionConnectionResponse;
};

/**
 * Create the action to launch Blast applicationSession.
 *
 * @param host[in] the host for Blast.
 * @param port[in] the port for Blast.
 * @param args[in] the launch arguments for Blast.
 * @return launchUrl as a string for the applicationSession connection.
 */

GetApplicationSessionConnectionHandler.prototype.createAppBlastLaunchApplicationSessionAction = function (
   host,
   port,
   args
) {
   /*
    * Construct appblast applicationSession url.
    * https://view.corp.com/r/r-token-value/?vauth=auth-token-value (BSG)
    * OR
    * https://view.corp.com/d/d-token-value/?vauth=auth-token-value (direct)
    */
   let r, launchUrl;

   if (args.r) {
      r = "/r/" + args.r;
   } else {
      r = "/d/" + getUuid();
   }
   launchUrl = ["https://", host, ":", port, encodeURI(r), "/?vauth=", encodeURIComponent(args.a)].join("");
   Logger.trace("Blast url generated");
   return launchUrl;
};

/**
 * Decrypt the XML-API protection data.
 */
GetApplicationSessionConnectionHandler.prototype.decryptResult = function () {
   const cryptoKeyDerivationService = util.getObject(
      globalArray,
      "cryptoKeyDerivationService"
   ) as CryptoKeyDerivationService;
   const parsedResult = this.content["parsedResult"];

   if (!cryptoKeyDerivationService) {
      Logger.error("The CryptoKeyDerivationService missing.");
      return;
   }

   if (parsedResult && cryptoKeyDerivationService.isXmlApiDataProtected()) {
      // Only used by VMware client types
      if (parsedResult["framework-channel-ticket"]) {
         parsedResult["framework-channel-ticket"] = cryptoKeyDerivationService.AESDecryptData(
            parsedResult["framework-channel-ticket"]
         );
      }
      if (parsedResult["password"]) {
         parsedResult["password"] = cryptoKeyDerivationService.AESDecryptData(parsedResult["password"]);
      }
      // Optional: used for protocol specific negotiation, such as the PCoIP token.
      if (parsedResult["protocol-settings"]) {
         parsedResult["protocol-settings"] = cryptoKeyDerivationService.AESDecryptData(
            parsedResult["protocol-settings"]
         );
      }
      // The field "token" and the field "protocol-settings" are the same value in "parseResult" function.
      if (parsedResult["token"]) {
         parsedResult["token"] = cryptoKeyDerivationService.AESDecryptData(parsedResult["token"]);
      }
      // Since 15.0, settings for protocol and tunnel connection redirection
      if (parsedResult["redirect-settings"]) {
         const redirectSettings = parsedResult["redirect-settings"];
         if (redirectSettings["protocol-redirect-token"]) {
            redirectSettings["protocol-redirect-token"] = cryptoKeyDerivationService.AESDecryptData(
               redirectSettings["protocol-redirect-token"]
            );
         }
         if (redirectSettings["tunnel-redirect-token"]) {
            redirectSettings["tunnel-redirect-token"] = cryptoKeyDerivationService.AESDecryptData(
               redirectSettings["tunnel-redirect-token"]
            );
         }
      }
   }
};
