<li>
   <button class="contextmenu-launch-nativeclient" [attr.aria-label]="'launchFromViewClient' | i18nv2"
      (click)="launchHorizonApp($event, app, 'HORIZON_CLIENT')" title="{{ 'launchFromViewClient' | i18nv2 }}">
      {{ 'launchFromViewClient' | i18nv2 }}
   </button>
</li>
<li *ngIf="isChromeOS">
   <button class="contextmenu-launch-chromeclient" [attr.aria-label]="'launchFromViewChromeClient' | i18nv2"
      (click)="launchHorizonApp($event, app, 'HORIZON_CHROME_NATIVE')"
      title="{{ 'launchFromViewChromeClient' | i18nv2 }}">
      {{ 'launchFromViewChromeClient' | i18nv2 }}
   </button>
</li>
<li>
   <button class="contextmenu-launch-browser" [attr.aria-label]="'launchFromBrowser' | i18nv2"
      (click)="launchHorizonApp($event, app, 'BROWSER')" title="{{ 'launchFromBrowser' | i18nv2 }}">
      {{ 'launchFromBrowser' | i18nv2 }}
   </button>
</li>
<li *ngIf="isSupportBlast">
   <button class="contextmenu-launch-blast" [ngClass]="{'boldFont': selectedProtocol === 'BLAST'}"
      [attr.aria-label]="'launchFromBlast' | i18nv2" (click)="setLaunchProtocol($event, app, 'BLAST')"
      title="VMware Blast">
      VMware Blast
   </button>
</li>
<li *ngIf="isSupportPCoIP">
   <button class="contextmenu-launch-pcoip" [ngClass]="{'boldFont': selectedProtocol === 'PCOIP'}"
      [attr.aria-label]="'launchFromPCoIP' | i18nv2" (click)="setLaunchProtocol($event, app, 'PCOIP')" title="PCOIP">
      PCOIP
   </button>
</li>
<li *ngIf="isSupportRDP">
   <button class="contextmenu-launch-rdp" [ngClass]="{'boldFont': selectedProtocol === 'RDP'}"
      [attr.aria-label]="'launchFromRDP' | i18nv2" (click)="setLaunchProtocol($event, app, 'RDP')" title="RDP">
      RDP
   </button>
</li>