/**
 * ******************************************************
 * Copyright (C) 2014-2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * namedPromise.js --
 *
 * named Promise using observer pattern
 *
 *
 * Allow resolve or reject only once.
 * Don't support unbind and bind to retrigger.
 * Don't support calls in chain in serious, like object.then().then()
 * Support object.then().catch().resolve();
 */

import Logger from "../../../core/libs/logger";

const namedPromise = (function () {
   /**
    * dispatch
    *
    * Returns a function which proxies for a call on the named method
    * on an object found in the given dispatch table. The first
    * parameter of the returned function is a 'name', which is used to
    * index into the dispatch table. All other parameters are passed
    * to the underlying method.
    *
    * @param self          To return self for calls like object.then().catch()
    * @param table         The dispatch table, a map from string -> object
    * @param method        The name of the method to invoke on the found object.
    * @param errNoInstance Error to throw if instance name isn't found
    * @param errNoMethod   Error to throw if instance method isn't found.
    */

   function dispatch(self, table, method, errNoInstance, errNoMethod?: any) {
      return function (name) {
         // strip off the first argument, which is used as the key.
         const args = Array.prototype.slice.call(arguments, 1);

         if (table.hasOwnProperty(name)) {
            /* istanbul ignore else */
            if (table[name] !== undefined) {
               table[name][method](...args);
            } else {
               throw /* istanbul ignore next */ errNoMethod || "MethodNotFound";
            }
         } else {
            Logger.info(name + "is not key in " + JSON.stringify(table));
            throw /* istanbul ignore next */ errNoInstance || "InstanceNotFound";
         }
         return self;
      };
   }

   return {
      /**
       * createPromise
       *
       * Returns a new 'namedPromise' object, which represents an observable
       * event on an object.
       */
      createPromise: function (name) {
         let resolveHandlers = [];
         let rejectHandlers = [];
         let pending = true;
         let finishParams = null;
         let success = false;

         return {
            /*
             * resolve
             *
             * Calls all registered listeners. All arguments passed
             * to resolve() are passed to each listener.
             */
            resolve: function () {
               if (!pending) {
                  Logger.debug("skip, since resolving a non-pending namedPromise with name: " + name);
                  return;
               }
               Logger.debug("resolve namedPromise with name: " + name);
               finishParams = arguments;
               pending = false;
               success = true;
               for (let i = 0; i < resolveHandlers.length; i++) {
                  resolveHandlers[i].apply(null, arguments);
               }
            },

            /*
             * reject
             *
             * Calls all registered listeners. All arguments passed
             * to reject() are passed to each listener.
             */
            reject: function () {
               if (!pending) {
                  Logger.debug("skip, since rejecting a non-pending namedPromise with name: " + name);
                  return;
               }
               Logger.debug("reject named promise with name: " + name);
               finishParams = arguments;
               pending = false;
               success = false;
               for (let i = 0; i < rejectHandlers.length; i++) {
                  rejectHandlers[i].apply(null, arguments);
               }
            },

            /*
             * then
             *
             * Register a new namedPromise handler to the namedPromise. If the
             * function is already registered, an error is raised.
             *
             * @param handler A namedPromise handler callback
             */
            then: function (handler) {
               if (resolveHandlers.indexOf(handler) === -1) {
                  if (!!finishParams && success) {
                     handler(...finishParams);
                     return;
                  } else {
                     resolveHandlers.push(handler);
                  }
               } else {
                  throw "Duplicate success listener";
               }
            },

            unbindThen: function (handler) {
               if (finishParams) {
                  return;
               }
               const loc = resolveHandlers.indexOf(handler);
               if (loc !== -1) {
                  resolveHandlers.splice(loc, 1);
               } else {
                  Logger.warning("There is no success listener named as: " + handler.name);
               }
            },
            /*
             * catch
             *
             * Register a new namedPromise handler to the namedPromise. If the
             * function is already registered, an error is raised.
             *
             * @param handler A namedPromise handler callback
             */
            catch: function (handler) {
               if (rejectHandlers.indexOf(handler) === -1) {
                  if (!!finishParams && !success) {
                     handler(...finishParams);
                     return;
                  } else {
                     rejectHandlers.push(handler);
                  }
               } else {
                  throw "Duplicate error listener";
               }
            },

            unbindCatch: function (handler) {
               if (finishParams) {
                  return;
               }
               const loc = rejectHandlers.indexOf(handler);
               if (loc !== -1) {
                  rejectHandlers.splice(loc, 1);
               } else {
                  Logger.warning("There is no error listener named as: " + handler.name);
               }
            },

            reset: function () {
               resolveHandlers = [];
               rejectHandlers = [];
               pending = true;
               finishParams = null;
               success = false;
            }
         };
      },

      /**
       * makeObservable
       *
       * Makes an arbitrary object conform to the 'observable' pattern by
       * implementing 'addEventListener', 'emit', and 'addPromise'
       * methods.
       *
       * @param object The object to be made observable.
       */
      makeObservable: function (object) {
         const promises = {};

         /**
          * first parameter is name, and rest is similar to Promise
          * @type {object} object The object where the named promises will be bound to.
          */
         object.then = dispatch(object, promises, "then", "InvalidPromiseName");
         object.unbindThen = dispatch(object, promises, "unbindThen", "InvalidPromiseName");
         object.catch = dispatch(object, promises, "catch", "InvalidPromiseName");
         object.unbindCatch = dispatch(object, promises, "unbindCatch", "InvalidPromiseName");
         object.resolve = dispatch(object, promises, "resolve", "InvalidPromiseName");
         object.reject = dispatch(object, promises, "reject", "InvalidPromiseName");
         object.resetStatus = dispatch(object, promises, "reset", "InvalidPromiseName");

         /**
          * addPromise
          *
          * Register a new namedPromise. After resolved or rejected,
          * handlers may be connected to the named promise
          * via 'addEventListener'. If the named promise has already been
          * registered, an error is thrown.
          *
          * @param name The name of the target promise that will be created.
          */
         object.addPromise = function (name) {
            if (promises.hasOwnProperty(name)) {
               throw "DuplicatePromiseName";
            }

            promises[name] = namedPromise.createPromise(name);
         };
      }
   };
})();

export default namedPromise;
