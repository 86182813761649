/**
 * ******************************************************
 * Copyright (C) 2015-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable, Inject } from "@angular/core";
import { CLIENT } from "@html-core";
import { UtilService } from "./util-service";
import { ConnectionURIModel } from "../../common/model/connection-uri-model";
import { BaseViewService } from "../../common/service/base-view.service";
import { Ws1Service } from "../../common/service/ws1.service";
import { JscdkCommonInvoker } from "../../../shared/common/jscdk/jscdk-common-invoker";
import { AppViewService } from "../../common/service/app-view.service";

@Injectable({
   providedIn: "root"
})
export class ViewService {
   /**
    * It's terrible to use a flag, however this is the only way doing it at the
    * time For logout behavior, normally when a user logs out, it goes to
    * portal.  However, if user A already logged in, user B uses URI to launch
    * a desktop in the same browser instance.  We need B to confirm if logs out
    * user A.  In this case, it goes to login page, not portal.  The root cause
    * of adding this flag is, as of now, when sending logout command to broker,
    * main service gets the response.
    * (the truth is whatever it is sending or who ever is sending, main service
    * gets the response).  The 'show' method of main service is invoked with
    * 'logout' passed.  However, main service does not know which case it is
    * (normal logout or another user is acting this behavior). That's why a
    * flag is required when user logs out to indicate which page is goes to. We
    * need to let the caller of jscdk to get the response instead of main
    * service.
    */
   public logoutToPortal: boolean = true;
   public logoutToLogin: boolean = false;
   public isForFA: boolean = false;

   /**
    * Add special indicator but share same parent component for:
    *
    *  WindowsPasswordExpired: '/changepassword',
    *  SecurIDNextTokenCode: '/securidnexttoken',
    *  SecurIDPinChange: '/securidpinchange',
    *  SecurIDWait: '/securidwait',
    */
   private map = {
      Start: "/home",
      GetLaunchItems: "/launchitems",
      Warning: "/warning"
   };

   constructor(
      private utilService: UtilService,
      private connectionURIModel: ConnectionURIModel,
      private baseViewService: BaseViewService,
      private ws1Service: Ws1Service,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private appViewService: AppViewService
   ) {}

   public Logout = () => {
      if (this.utilService.supportLocalStorage()) {
         window.localStorage.removeItem(CLIENT.CLIENT_LOGIN_KEY);
      }
      const logoutToPortal = this.logoutToPortal;
      if (logoutToPortal && !this.utilService.isVADC()) {
         this.gotoPortal(); // default behavior
      } else {
         this.logoutToPortal = true; // reset the stupid flag
         setTimeout(() => {
            this.utilService.authenticationStatusCheck.clearAction("log out");
            this.baseViewService.changeLocation("/home");
         });
      }
   };

   public gotoPortal = (clearURI?: any) => {
      this.baseViewService.gotoPortal(clearURI);
   };

   public connectToBroker = (data) => {
      if (!!data && !!data.params) {
         // Store URI parameters.
         this.connectionURIModel.params = data.params;
      }

      // If spID exist in URI, update it to local storage
      const spID = this.connectionURIModel.getSpID();
      if (spID) {
         this.ws1Service.storeSpId(spID);
      }

      // cancel previous user launch/reset/logoff trial
      this.utilService.authenticationStatusCheck.clearAction("connect to broker");

      this.jscdkCommonInvoker.connectToBroker();
   };

   public switchView = (param) => {
      if (param.action === "Portal") {
         this.gotoPortal(true);
      } else if (param.action === "Logout") {
         this.Logout();
      } else if (param.action === "Brokers") {
         this.connectToBroker(param.data);
      } else {
         const targetPath = this.map[param.action];
         if (!!param.action && this.map.hasOwnProperty(param.action)) {
            setTimeout(() => {
               this.baseViewService.locationChange = true;
               this.appViewService.changeLocation(targetPath);
            });
         } else {
            // The previous implementation is risky, change during refactor
            if (param.controller) {
               param.controller.enable(param.action, param.data);
            }
         }
      }
   };

   public goBlastPage = () => {
      this.baseViewService.changeLocation("/desktop");
   };

   public getSwitchForURI() {
      return this.baseViewService.switchForURI;
   }

   public setSwitchForURI(value: boolean) {
      this.baseViewService.switchForURI = value;
   }
}
