/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/
import { SDKResponseInterface } from "./event-manager";
import { filter, map } from "rxjs/operators";
import { Observable, Subject, Subscription } from "rxjs";
import { EventManager, ResponseCallbackData } from "./event-manager";
import {
   EventExchange,
   getEventExchange,
   EventExchangeType,
   SDKReceivedRawRequest
} from "./event-exchange/event-exchange";
import {
   getEventHelper,
   EventHelper,
   SDKEvent,
   EventHelperType,
   StructSchema,
   RequestBundle
} from "./event-helper/event-helper";
import { Logger } from "./logger";

// should only be resolved once and only once at each processor for each message
type SDKResposer = Promise<SDKResponseInterface>;
type SDKRawEvent = string | ArrayBuffer;

type SDKRequestInterface = {
   sdkRequest: SDKEvent;
   responser: SDKResposer;
};

export type SDKReceivedRequest = {
   request: SDKEvent;
   sendResponse: (SDKRawEvent, StructSchema?) => void;
   peerAddress: string;
};

export enum SDKRequestIds {
   null = 0,
   // request to background
   connectItem,
   shutDownClient,
   setClientVisibility,
   // request to client
   disconnectProtocolSession = 0x01000001,
   logoffProtocolSession,
   resetProtocolSession,
   restartProtocolSession,
   // request to blast window(for vchan/seamless window operations)
   // from 0x02000001

   // events
   // from background
   clientLaunched = 0x10000001,
   clientClosed,
   clientLaunchFailed,
   // from client
   connectingBroker = 0x11000001,
   connectBrokerFailed,
   authenticationDeclined,
   authenticationFailed,
   itemLaunchFailed,
   itemLaunchSucceeded,
   idleTimeout,
   newProtocolSessionCreated,
   protocolSessionDisconnected,
   // from blast/vchan 0x12000001,
   SeamlessWindowsModeChanged = 0x12000001,
   SeamlessWindowAdded,
   SeamlessWindowRemoved,
   VchanConnected,
   VchanDisconnected,
   VchanToRemoteData,
   VchanFromRemoteData,
}
export enum SDKRequestProcessers {
   versionExchange = "versionExchange", //max 1
   chromeBackground = "ChromeBackground", //max 1
   chromeClient = "ChromeClient-", //max 255
   blast = "Blast-" // max 255*1000
}

export class SDKEventService {
   protected version: number;
   private eventExchangeService: EventExchange;
   private eventManagers: EventManager;
   private eventHelper: EventHelper;

   // only when allowSenderList is null, getDefaultTrustList and onUntrustRequest would be used.
   constructor(
      messageType: EventExchangeType,
      allowSenderList: Array<string>,
      getTrustOriginList?: (string) => Promise<Array<string>>,
      isTrustSyncMessage?: (SDKRawEvent) => boolean,
   ) {
      this.eventExchangeService = getEventExchange(
         allowSenderList,
         messageType,
         getTrustOriginList,
         isTrustSyncMessage
      );
      this.eventManagers = new EventManager();
      this.eventHelper = getEventHelper(EventHelperType.PlainText);
   }

   public disconnectPort= (portId: string, reason: string) => {
      this.eventExchangeService.disconnectPort(portId, reason);
   }
   // sender call this to send message, returned promise would be resolved to the SDKResponse
   // : SDKResponse
   public sendMessage = (
      peerAddress: string,
      messageType: string,
      data: any,
      callbackParameter: any,
      dataType?: StructSchema
   ): Promise<ResponseCallbackData> => {
      return new Promise((resolve, reject) => {
         try {
            const requestBundle: RequestBundle = this.eventHelper.getRequest(
               messageType,
               data,
               dataType
            );
            const request: SDKRawEvent = requestBundle.request;
            this.eventManagers.onSendingRequest(
               peerAddress,
               requestBundle.requestId,
               callbackParameter,
               resolve,
               reject
            );
            Logger.trace("sending request to peer" + peerAddress + "message type: " + messageType + ", type:" + dataType);
            let portId: string = undefined;
            if (messageType.startsWith(SDKRequestProcessers.blast)) {
               portId = messageType.split(SDKRequestProcessers.blast)[1];
            } else if (messageType.startsWith(SDKRequestProcessers.chromeClient)) {
               portId = messageType.split(SDKRequestProcessers.chromeClient)[1];
            }
            this.eventExchangeService
               .sendMessage(peerAddress, request, portId)
               .then((rawResponse) => {
                  if (!rawResponse) {
                     Logger.error("no response");
                     reject();
                     return;
                  }
                  const response: SDKEvent = this.eventHelper.parseResponse(
                     rawResponse as SDKRawEvent
                  );
                  if(response.messageType === SDKRequestProcessers.versionExchange) {
                     Logger.trace(
                        "get version exchange response from peer" + peerAddress + " content: " + JSON.stringify(response)
                     );
                  } else {
                     const dataType = (response.data) ? response.data.name : "missing";
                     Logger.trace(
                        "get response from peer" + peerAddress + ", message type: " + response.messageType + " with data type " + dataType
                     );
                  }
                  this.eventManagers.onReceivingResponse(peerAddress, response);
               }, reject)
               .catch(reject);
         } catch (e) {
            Logger.exception(e);
            reject(e);
         }
      });
   };


   public addListener = (
         peerAddress: string,
         messageType: string,
         processFunction: (SDKReceivedRequest)=>void
   ):Subscription => {
      return this.listen(peerAddress, messageType).subscribe((message: SDKReceivedRequest)=>{
         try {
            processFunction(message);
         } catch(e) {
            Logger.exception(e);
            try {
               message.sendResponse(null);
            } catch(e2) {
               Logger.error("connection lost, failed to send failure response");
            }
         }
      });
   }

   /**
    * Receiver call this to receive message, returned each event would corresponding to a emit of SDKRequestListener
    * And the responser inside SDKRequestListener should be fired once and only once for returning the response
    * The Event
    */
   private listen = (
      peerAddress?: string,
      messageType?: string
   ): Observable<SDKReceivedRequest> => {
      let messageEvent: Subject<SDKReceivedRawRequest>;
      return this.eventExchangeService.message$.pipe(
         map((rawRequest: SDKReceivedRawRequest) => {
            const requestContent = this.eventHelper.parseRequest(
               rawRequest.request
            );
            if (!requestContent.messageType || !requestContent.requestId) {
               Logger.error("invalid message received");
               return;
            }
            const request: SDKReceivedRequest = {
               request: requestContent,
               sendResponse: (
                  response: SDKRawEvent,
                  dataType?: StructSchema
               ) => {
                  const responseEvent = this.eventHelper.getResponse(
                     requestContent.messageType,
                     response,
                     requestContent.requestId,
                     dataType
                  );
                  rawRequest.sendResponse(responseEvent);
               },
               peerAddress: rawRequest.peerAddress
            };
            if (requestContent.messageType === SDKRequestProcessers.versionExchange) {
               Logger.info("get version exchange request from peer " + peerAddress);
            } else {
               const dataType = requestContent.data ? requestContent.data.name : "missing";
               Logger.trace("get request from peer " + peerAddress + ", messageType: " + requestContent.messageType + ", data type: " + dataType);
            }
            return request;
         }),
         filter((event: SDKReceivedRequest) => {
            const messageTypeMatch =
               !messageType || event.request.messageType === messageType;
            const messageTypePartialMatch = messageType.endsWith("-") && !!event.request.messageType &&
               event.request.messageType.startsWith(messageType);
            const peerMatch = !peerAddress || peerAddress === event.peerAddress;
            return event.request.isRequest && (messageTypeMatch || messageTypePartialMatch) && peerMatch;
         })
      );
   };

   public reset = () => {
      // Could response with error if needed here for pending request in both direction
      this.eventExchangeService.reset();
   }
}
