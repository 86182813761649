/**
 * ******************************************************
 * Copyright (C) 2016-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * monitor-manage.service.ts -- monitorManageService
 *
 * service to control all monitors for multimon.
 * service to bound events to update the model
 *
 * entermultimon, will always call addMonitor, PrimaryMonitor.enable, bound
 * events
 */
// step: switchToMultiMonitor, addMonitor

import $ from "jquery";
import { Injectable, Optional } from "@angular/core";
import { MultimonModel } from "./multimon-model";
import { ExtendedMonitorService } from "./extended-monitor.service";
import { NormalizationService } from "../../../utils/normalization-service";
import { VNCDecoder, EncodingTypes } from "../common/vnc-decoder";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import Logger from "../../../../core/libs/logger";
import { Subscription } from "rxjs";
import { clientUtil } from "../../../../core/libs";
import { MultimonMessageQueueService } from "./multimon-queue-message.service";
import { RemoteappBorderService } from "../../../../chrome-client/desktop/remoteapp/app-util/remoteapp-border.service";
import { ConnectedMessageService } from "../../../../chrome-client/base/service/connected-message.service";
import { Port } from "../../../../chrome-client/base/model/rx-bridge-type";
import { FeatureConfigs } from "../../../common/model/feature-configs";
import { BusEvent, EventBusService } from "../../../../core/services/event";
import { Event, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { DisplayCheckService } from "../../common/display-check.service";
import { DisplayService } from "../../common/display/display.service";

enum UpdateCacheType {
   updateCacheOpInit = 0,
   updateCacheOpBegin = 1,
   updateCacheOpEnd = 2,
   updateCacheOpReplay = 3
}

@Injectable({
   providedIn: "root"
})
export class MonitorManageService {
   private canvasBuffer = null;
   private canvasContainer = null;
   private quitConfirmDialogId: string = null;
   private renderingList = {
      0: {}
   };
   private renderingCallback = {};
   private renderingIndex = 0;
   private monitorBounds = null;
   private caches = [];
   private _cacheInited = false;
   private _isDisplaying = false;
   private _isH264Enabled = false;
   private _activeBackground = null;
   private sessionContainer = null;

   private onSingleMonitor = null;
   private onEnterMultimon = null;
   private onMultiMonitor = null;
   private onQuitMultimon = null;
   private onTopologyChanged = null;
   private onMonitorChanged = null;
   private handleResize = null;
   private _locationChangeSubscription: Subscription = null;
   private monitorInfo = {};
   public showQuitMultimonDialog = true;
   private _useWindowReplaceApi;
   private _runningInMultimonMode: boolean = false;

   private _cursorInfo = {
      needSend: false,
      src: "",
      width: "",
      height: "",
      hotx: "",
      hoty: ""
   };

   constructor(
      private multimonModel: MultimonModel,
      private extendedMonitorService: ExtendedMonitorService,
      private normalizationService: NormalizationService,
      private vncDecoder: VNCDecoder,
      private modalDialogService: ModalDialogService,
      private multimonMessageQueueService: MultimonMessageQueueService,
      private _featureConfigs: FeatureConfigs,
      private eventBusService: EventBusService,
      private _displayCheckService: DisplayCheckService,
      @Optional()
      private connectedMessageService: ConnectedMessageService,
      @Optional()
      private remoteappBorderService: RemoteappBorderService,
      @Optional()
      private displayService: DisplayService,
      private router: Router
   ) {
      this._useWindowReplaceApi = this._featureConfigs.getConfig("KillSwitch-WindowReplacementApi");
      this.eventBusService.listen(BusEvent.CursorChanged.MSG_TYPE).subscribe((msg) => {
         // @ts-ignore
         if (this._cursorInfo.src !== msg.src) {
            // @ts-ignore
            this._cursorInfo.src = msg.src;
            // @ts-ignore
            this._cursorInfo.width = msg.width;
            // @ts-ignore
            this._cursorInfo.height = msg.height;
            // @ts-ignore
            this._cursorInfo.hotx = msg.hotx;
            // @ts-ignore
            this._cursorInfo.hoty = msg.hoty;
            this._cursorInfo.needSend = true;
         }
      });
   }

   private cursorObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutationRecord) => {
         setTimeout(() => {
            $(window).trigger("CursorChanged");
         });
      });
   });

   private setMonitorBounds = () => {
      const baseX = this.multimonModel.baseX,
         baseY = this.multimonModel.baseY;

      this.monitorBounds = {};
      this.multimonModel.forEachSettings((key, setting) => {
         this.monitorBounds[key] = {
            x: baseX + setting.x,
            y: baseY + setting.y,
            width: setting.width,
            height: setting.height
         };
      });
   };

   private clearRenderStatus = () => {
      let key;
      for (key in this.renderingList) {
         if (this.renderingList.hasOwnProperty(key) && JSON.stringify(this.renderingList[key]) !== "{}") {
            this.renderingCallback[key].onDone();
         }
      }
      this.renderingList = {
         0: {}
      };
      this.renderingCallback = {};
      this.renderingIndex = 0;
   };

   /**
    * Uses the overall decoding delay, which might bring down the fps, but
    *    should be more stable
    * @param  {number} monitorIndex The index of monitor
    * @param  {number} frameIndex   The index of rendered frame
    * @param  {number} decodeStart  The start time of rendering
    */
   private onRenderingDone = (monitorIndex, frameIndex) => {
      if (!this.renderingList[frameIndex] || !this.renderingCallback[frameIndex]) {
         Logger.debug("can't finish rendering twice for index: " + frameIndex, Logger.DISPLAY);
         return;
      }
      delete this.renderingList[frameIndex][monitorIndex];
      if (JSON.stringify(this.renderingList[frameIndex]) === "{}") {
         this.renderingCallback[frameIndex].onDone();
         delete this.renderingList[frameIndex];
         delete this.renderingCallback[frameIndex];
      }
   };

   private onCursorChanged = () => {
      if (this._cursorInfo.needSend) {
         // cursor for primary monitor
         // dispatch to all monitors.
         for (const key in this.multimonModel.monitors) {
            if (this.multimonModel.monitors.hasOwnProperty(key)) {
               // for extended monitor
               if (typeof this.multimonModel.monitors[key].primaryCanvas === "undefined") {
                  this.multimonModel.monitors[key].onCursorChanged(this._cursorInfo);
               } else {
                  // for primary monitor
                  const cursorAddress = $(this._activeBackground).css("cursor");
                  this.multimonModel.monitors[key].onCursorChanged(cursorAddress);
               }
            }
         }
         this._cursorInfo.needSend = false;
      }
   };

   private isOutsideBound(x, y, width, height, bound) {
      return x < bound.x || x + width > bound.x + bound.width || y < bound.y || y + height > bound.y + bound.height;
   }

   /**
    * Return whether a package should be dispatch to monitor with key as index
    * dispatching EOF to all monitors to ensure all monitors are responding
    * and able to deal with comming frames
    *
    * extra logic for frame buffer cache
    */
   private shouldDispatch = (rect, key) => {
      const rectWidth = rect.width || (!!rect.image && rect.image.width),
         rectHeight = rect.height || (!!rect.image && rect.image.height);

      // dispatch message to all monitors when init cache
      if (rect.encoding === EncodingTypes.encUpdateCache && rect.opcode === UpdateCacheType["updateCacheOpInit"]) {
         return true;
      }
      if (!this.monitorBounds || !this.monitorBounds.hasOwnProperty(key)) {
         Logger.error("wrong status in the monitorBounds", Logger.DISPLAY);
         return false;
      }
      if (this.isOutsideBound(rect.x, rect.y, rectWidth, rectHeight, this.monitorBounds[key])) {
         return false;
      }
      if (
         !!rect.srcX &&
         !!rect.srcY &&
         this.isOutsideBound(rect.srcX, rect.srcY, rectWidth, rectHeight, this.monitorBounds[key])
      ) {
         Logger.debug("cross monitor move detected", Logger.DISPLAY);
         return false;
      }
      return true;
   };

   private getCanvasContainer() {
      return document.getElementById("canvas-container");
   }

   private initImageBuffer = () => {
      if (!this.canvasContainer) {
         this.canvasContainer = this.getCanvasContainer();
      }
      const height = this._displayCheckService.getScreenHeightWithoutNotchHeight();
      this.canvasContainer.style.height = height + "px";
      this.canvasContainer.style.width = screen.width + "px";
      this.canvasContainer.style.overflow = "hidden";

      this.sessionContainer = this.multimonModel.sessionContainer;
      this.sessionContainer.style.display = "none";
      const backgroundCanvas: HTMLCanvasElement = $(this.sessionContainer).children(
         "#mainCanvas"
      )[0] as HTMLCanvasElement;
      const backgroundVideo = $(this.sessionContainer).children("video")[0];
      /**
       * Don't use this._isH264Enabled for now, since current wmks design allows
       * inconsistent between settings and real working mode, but still keep the
       * mode info for feature usage.
       */
      if (backgroundVideo) {
         this._activeBackground = backgroundVideo;
      } else {
         this._activeBackground = backgroundCanvas;
      }
      this.canvasBuffer = backgroundCanvas.getContext("2d");
      $(window).off("CursorChanged");
      $(window).on("CursorChanged", this.onCursorChanged);
   };

   private clearImageBuffer() {
      if (!this.canvasContainer) {
         this.canvasContainer = this.getCanvasContainer();
      }
      this.canvasContainer.style.height = "100%";
      this.canvasContainer.style.width = "100%";
      if (this._activeBackground) {
         this._activeBackground.style.display = "none";
         const switchDelay = 2000;
         setTimeout(() => {
            if (this._activeBackground) {
               this._activeBackground.style.display = "";
               this._activeBackground = null;
            }
         }, switchDelay);
      }
      if (this.sessionContainer) {
         this.sessionContainer.style.display = "";
         this.sessionContainer = null;
      }
      this.canvasBuffer = null;
      $(window).off("CursorChanged", this.onCursorChanged);
   }

   /**
    * This release will always fire onSingleMonitor and onQuitMultimon at the
    * same time, but later will be different, user will be allow to adjust the
    * displayers
    * @param  {object} wmksSession     The wmks session object
    * @param  {object} sessionContainer The DOM that contains the blast session
    * @param  {function} onQuitMultimon The callback when quit multimon mode, but still in the config phase
    * @param  {function} onEnterMultimon The callback when leave config phase and enter multimon
    * @param  {function} onSingleMonitor The callback when switch back to single monitor mode
    */
   public switchToMultiMonitor = (
      wmksSession,
      sessionContainer,
      onQuitMultimon,
      onEnterMultimon,
      onSingleMonitor,
      onMultiMonitor,
      onTopologyChanged,
      onMonitorChanged,
      onHandleResize,
      options,
      screenSetting
   ) => {
      this.multimonModel.init(wmksSession, sessionContainer);
      this.extendedMonitorService.wmksSession = wmksSession;
      this.onSingleMonitor = onSingleMonitor;
      this.onEnterMultimon = onEnterMultimon;
      this.onMultiMonitor = onMultiMonitor;
      this.onQuitMultimon = onQuitMultimon;
      this.onTopologyChanged = onTopologyChanged;
      this.onMonitorChanged = onMonitorChanged;
      this.handleResize = onHandleResize;
      this.multimonModel.primaryMonitor.init(this.onRegionUpdated);
      // enable quit multimon dialog
      this.showQuitMultimonDialog = true;
      // pop out first detector
      this.addMonitor(screenSetting);
      if (!clientUtil.isChromeClient()) {
         window.addEventListener("beforeunload", this.switchToSingleMonitor);
         this._locationChangeSubscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
               this.onLocationChanged(null, event.urlAfterRedirects, event.url);
            }
         });
      }
      this.renderingList = {
         0: {}
      };
      this.renderingIndex = 0;
      this.caches = [];
      if (options) {
         this._isH264Enabled = options.enableH264;
      }
   };

   public onLocationChanged = (event, next, current) => {
      if (current.indexOf("#/desktop") !== -1 && next.indexOf("#/desktop") === -1) {
         this.switchToSingleMonitor();
      }
   };

   public switchToSingleMonitorWithoutQuitDialog = () => {
      // disable quit multimon dialog, this value will be reset to true before entering multimon each time.
      this.showQuitMultimonDialog = false;
      this.switchToSingleMonitor();
   };

   public switchToSingleMonitor = () => {
      let key;

      //close all extended
      for (key in this.multimonModel.monitors) {
         if (
            this.multimonModel.monitors.hasOwnProperty(key) &&
            typeof this.multimonModel.monitors[key].close === "function"
         ) {
            this.multimonModel.monitors[key].removeEventListeners();
            this.multimonModel.monitors[key].close();
         }
      }
      //then quitMultimonMode should be called
      this.quitMultimonMode();
      if ($("#sidebar-fullscreen-button")) {
         $("#sidebar-fullscreen-button").show();
      }

      if (clientUtil.isChromeClient()) {
         // only for remoteapp
         if (window.location.href.indexOf("app-window.html") >= 0) {
            this.remoteappBorderService.enterSinglemonMode();
         } else {
            this.connectedMessageService.sendMessageByType(Port.ChannelName.Settings, {
               type: Port.SettingMsg.exitMultimon
            });
         }
      }

      window.removeEventListener("beforeunload", this.switchToSingleMonitor);
      if (this._locationChangeSubscription) {
         this._locationChangeSubscription.unsubscribe();
         this._locationChangeSubscription = null;
      }
      if (this.modalDialogService.isDialogOpen(this.quitConfirmDialogId)) {
         this.modalDialogService.close(this.quitConfirmDialogId);
      }
   };

   private startSpyCursor() {
      this.cursorObserver.observe(this._activeBackground, {
         attributes: true,
         attributeFilter: ["style"]
      });
   }
   private stopSpyCursor() {
      this.cursorObserver.disconnect();
   }

   private quitMultimonMode() {
      // To fix bug 2163927, to remove the listener of resize when enter
      // multi monitor mode, and add it back on quit
      $(window).on("resize", this.handleResize);
      this.multimonModel.usingMultimon = false;
      this._cacheInited = false;
      this.monitorBounds = null;
      Logger.info("quit multimon mode", Logger.DISPLAY);
      this.multimonMessageQueueService.reset();
      this._runningInMultimonMode = false;
      this.stopSpyCursor();
      this.multimonModel.primaryMonitor.disable();
      this.clearImageBuffer();
      this.clearRenderStatus();
      this._displayCheckService.quitMultimonMode();
      this.multimonModel.clear();
      this.monitorInfo = {};
      // not for remoteapp
      if (window.location.href.indexOf("app-window.html") === -1) {
         this.normalizationService.clear();
      }
      this.onQuitMultimon();
      this.onSingleMonitor();
   }

   /**
    * @return {[type]} [description]
    */
   public addMonitor = (screenSetting) => {
      Logger.debug("add one monitor", Logger.DISPLAY);
      this.multimonModel.addMonitor(this.onStatusChanged, this.onRegionUpdated, screenSetting);
   };

   private keepInMultimon = () => {
      this.multimonModel.usingMultimon = true;
      this.multimonModel.updateSettings();
      if (window.location.href.indexOf("app-window.html") === -1) {
         this.multimonModel.primaryMonitor.enterFullscreen();
      }
      this.onEnterMultimon();
   };

   private updateTopology = () => {
      this.clearRenderStatus();
      this.normalizationService.calculate();
      this.multimonModel.monitorSettings = this.normalizationService.getNormalizedSettings();
      this.multimonModel.updateSettings();

      const screenBaseUnderNotchMonitor = this.normalizationService.getScreenBaseUnderNotchMonitor();
      Logger.info(
         "(updateTopologY) screenBaseUnderNotchMonitor = " + JSON.stringify(screenBaseUnderNotchMonitor),
         Logger.NOTCH_MONITOR
      );

      const primaryMonitorScreenBase = {
         x: this.multimonModel.baseX,
         y: this.multimonModel.baseY
      };

      const primaryMonitorIsUnderNotchScreenBase = screenBaseUnderNotchMonitor.get("0");
      if (primaryMonitorIsUnderNotchScreenBase) {
         primaryMonitorScreenBase.y -= primaryMonitorIsUnderNotchScreenBase;
      }

      const screenModels = this.normalizationService.getNormalizationModel();
      let sizeFactor = this.normalizationService.getSizeFactor("0");
      this.multimonModel.primaryMonitor.adjustDisplay(primaryMonitorScreenBase, screenModels.get("0"), sizeFactor);
      this.multimonModel.primaryMonitor.adjustDisplayDone();

      let extendedUnderNotchMonitorScreenBase;
      for (const key in this.multimonModel.monitors) {
         if (this.multimonModel.monitors.hasOwnProperty(key) && key !== "0") {
            const extendedMonitorScreenBase = {
               x: this.multimonModel.baseX,
               y: this.multimonModel.baseY
            };
            if ((extendedUnderNotchMonitorScreenBase = screenBaseUnderNotchMonitor.get(key))) {
               extendedMonitorScreenBase.y -= extendedUnderNotchMonitorScreenBase;
            }
            sizeFactor = this.normalizationService.getSizeFactor(key);
            this.multimonModel.monitors[key].adjustDisplay(
               extendedMonitorScreenBase,
               screenModels.get(key),
               sizeFactor
            );
            this.multimonModel.monitors[key].adjustDisplayDone();
         }
      }
      this.onTopologyChanged();
      this.setMonitorBounds();
   };

   /**
    * Can also be used to support screen rotation, resolution change,
    *    display add/remove
    * @param  {object} newDisplayinfo
    */
   public onDisplayInfoChanged = (newDisplayinfo) => {
      if (!newDisplayinfo || !newDisplayinfo.type) {
         Logger.error("invalid display change info format", Logger.DISPLAY);
         return;
      }

      switch (newDisplayinfo.type) {
         case "AgentDPI": {
            const newAgentDPI = newDisplayinfo.value;
            this.normalizationService.setAgentDPI(newAgentDPI);
            break;
         }
         default:
            Logger.error("unknown display change info type: " + newDisplayinfo.type, Logger.DISPLAY);
            return;
      }

      this.updateTopology();
   };

   /**
    * Don't allow to close dialog by esc since this dialog can be triggered
    *    by it.
    */
   public onPrimaryMonitorQuit = (cancelAsDefault) => {
      if (this.modalDialogService.isDialogOpen(this.quitConfirmDialogId)) {
         return;
      }
      if (!this.showQuitMultimonDialog) {
         return;
      }
      setTimeout(() => {
         const option = {
            data: {
               titleKey: "MM_QUIT_MULTIMON_T",
               contentKey: "MM_QUIT_MULTIMON_M",
               buttonLabelConfirmKey: "YES",
               buttonLabelCancelKey: "CANCEL"
            },
            callbacks: {
               confirm: () => {
                  this.switchToSingleMonitor();
                  if (!this.modalDialogService.isDialogOpen(this.quitConfirmDialogId)) {
                     return;
                  }
               },
               cancel: () => {
                  this.keepInMultimon();
                  if (!this.modalDialogService.isDialogOpen(this.quitConfirmDialogId)) {
                     return;
                  }
               }
            }
         };
         if (!this.modalDialogService.isDialogOpen(this.quitConfirmDialogId)) {
            if (cancelAsDefault) {
               this.quitConfirmDialogId = this.modalDialogService.showCancelConfirm(option);
            } else {
               this.quitConfirmDialogId = this.modalDialogService.showConfirm(option, false);
            }
         }
      });
   };

   private _getPrimaryScreen = (): DisplayBaseInfo => {
      if (this._useWindowReplaceApi) {
         const notchHeight = this._displayCheckService.getMacNotchHeight();
         let primaryMonitorSetting;
         primaryMonitorSetting = this.displayService.getPrimaryScreen();
         primaryMonitorSetting.macNotchHeight = notchHeight;
         this.multimonModel.primaryMonitor.setNotchHeight(notchHeight * primaryMonitorSetting.devicePixelRatio);
         return primaryMonitorSetting;
      }
      return this.multimonModel.primaryMonitor.getSetting();
   };

   /**
    * @private
    * @return {[type]} [description]
    */
   public enterMultimonMode = () => {
      this.multimonModel.usingMultimon = true;
      Logger.info("enter multimon mode", Logger.DISPLAY);
      this.multimonModel.primaryMonitor.enable(
         this.multimonModel.wmksSession,
         () => {
            // To avoid enter multimon when it's already in multimon mode.
            // (This happens when enter fullscreen mode when click cancel button on "Exit multimon dialog")
            if (this._runningInMultimonMode) {
               return;
            }

            const primaryMonitorSetting = this._getPrimaryScreen();

            this.multimonModel.monitorSettings.set("0", primaryMonitorSetting);
            this.normalizationService.setRawSetting("0", primaryMonitorSetting);

            this.normalizationService.calculate();
            this.multimonModel.monitorSettings = this.normalizationService.getNormalizedSettings();
            this.multimonModel.updateSettings();

            const primaryMonitorScreenBase = {
               x: this.multimonModel.baseX,
               y: this.multimonModel.baseY
            };

            const screenBaseUnderNotchMonitor = this.normalizationService.getScreenBaseUnderNotchMonitor();
            const primaryMonitorIsUnderNotchScreenBase = screenBaseUnderNotchMonitor.get("0");
            if (primaryMonitorIsUnderNotchScreenBase) {
               primaryMonitorScreenBase.y -= primaryMonitorIsUnderNotchScreenBase;
            }

            const screenModels = this.normalizationService.getNormalizationModel();
            let sizeFactor = this.normalizationService.getSizeFactor("0");
            this.multimonModel.primaryMonitor.setScreenBase(primaryMonitorScreenBase);
            this.multimonModel.primaryMonitor.setScreenModel(screenModels.get("0"));
            this.multimonModel.primaryMonitor.onDPISettingChanged(sizeFactor);
            this.multimonModel.primaryMonitor.initScreen();
            this.onTopologyChanged();
            this.setMonitorBounds();
            this._isDisplaying = true;
            setTimeout(() => {
               // maybe already quit multimon before clicking enter-fullscreen-button for invalid topology
               if (this.multimonModel.usingMultimon) {
                  this.onMultiMonitor();
                  this.initImageBuffer();
                  this.startSpyCursor();
                  this._runningInMultimonMode = true;
                  this.multimonModel.forEachExtenalMonitor((key, monitor) => {
                     const extendedMonitorScreenBase = {
                        x: this.multimonModel.baseX,
                        y: this.multimonModel.baseY
                     };
                     const extendedUnderNotchMonitorScreenBase = screenBaseUnderNotchMonitor.get(key);
                     if (extendedUnderNotchMonitorScreenBase) {
                        extendedMonitorScreenBase.y -= extendedUnderNotchMonitorScreenBase;
                     }
                     sizeFactor = this.normalizationService.getSizeFactor(key);
                     const screenModel = screenModels.get(key);
                     Logger.info("start display for monitor " + key);
                     Logger.trace(
                        "display param " + JSON.stringify({ extendedMonitorScreenBase, screenModel, sizeFactor })
                     );
                     monitor.startDisplay(extendedMonitorScreenBase, this.onRenderingDone, screenModel, sizeFactor);
                  });
                  this.multimonMessageQueueService.onAllMonitorReady(this.multimonModel.forEachExtenalMonitor);
               }
            });
         },
         this.onPrimaryMonitorQuit,
         this.onRenderingDone,
         this.switchToSingleMonitor
      );
      this.onEnterMultimon();
   };

   public readyToEnterMultimon = () => {
      let key,
         isNotEmpty = false;

      for (key in this.multimonModel.monitors) {
         if (this.multimonModel.monitors.hasOwnProperty(key) && key !== "0") {
            isNotEmpty = true;
            if (
               this.multimonModel.monitors[key].status !== this.extendedMonitorService.statusMap["readyToDisplay"] &&
               this.multimonModel.monitors[key].status !== this.extendedMonitorService.statusMap["working"]
            ) {
               return false;
            }
         }
      }
      return isNotEmpty;
   };

   public getMonitorInfo = (id) => {
      if (!clientUtil.isChromeClient() && !this._useWindowReplaceApi) {
         let key,
            monitorInfo = {};
         for (key in this.multimonModel.monitors) {
            if (this.multimonModel.monitors.hasOwnProperty(key)) {
               monitorInfo[key] = {
                  settings: this.multimonModel.monitorSettings.get(key),
                  ready:
                     this.multimonModel.monitors[key].status === this.extendedMonitorService.statusMap["readyToDisplay"]
               };
            }
         }
         return monitorInfo;
      } else {
         if (this.multimonModel.monitors.hasOwnProperty(id)) {
            this.monitorInfo[id] = {
               settings: this.multimonModel.monitorSettings.get(id),
               ready: this.multimonModel.monitors[id].status === this.extendedMonitorService.statusMap["readyToDisplay"]
            };
         }
         return this.monitorInfo;
      }
   };

   public onStatusChanged = (id, status) => {
      switch (status) {
         case this.extendedMonitorService.statusMap["inited"]:
            this.multimonModel.monitors[id].updateUnmaxizableRegions(this.multimonModel.monitorSettings);
            break;
         case this.extendedMonitorService.statusMap["readyToDisplay"]:
            this.onMonitorChanged(this.getMonitorInfo(id), this.readyToEnterMultimon());
            break;
         case this.extendedMonitorService.statusMap["closed"]:
            this.onRegionUpdated(id, null);
            this.multimonModel.updateSettings();
            if (this.multimonModel.monitorCount === 1) {
               this.switchToSingleMonitor();
            } else {
               if (this._useWindowReplaceApi) {
                  // with 2 more monitors, close extended monitor will trigger errors if too fast
                  setTimeout(() => {
                     if (this.multimonModel.monitorCount > 1) {
                        this.onTopologyChanged();
                     }
                  }, 1000);
               } else {
                  this.onTopologyChanged();
               }
            }
            break;
         case this.extendedMonitorService.statusMap["confirmedQuit"]:
            this.switchToSingleMonitor();
            break;
      }
   };

   public onRegionUpdated = (id: string, value: DisplayBaseInfo, stillUpdating?: any) => {
      let monitorCount = 0,
         key,
         monitorSettings = this.multimonModel.monitorSettings;

      if (!value) {
         monitorSettings.delete(id);
         delete this.multimonModel.monitors[id];
         this.normalizationService.removeRawSetting(id);
      } else {
         //update/add
         monitorSettings.set(id, value);
         this.normalizationService.setRawSetting(id, value);
      }
      for (key in this.multimonModel.monitors) {
         if (this.multimonModel.monitors.hasOwnProperty(key)) {
            monitorCount++;
            if (key !== id) {
               this.multimonModel.monitors[key].updateUnmaxizableRegions(monitorSettings);
            }
         }
      }
      this.multimonModel.monitorCount = monitorCount;
      this.onMonitorChanged(this.getMonitorInfo(id), this.readyToEnterMultimon(), stillUpdating);
   };

   private renderingNext = () => {
      this.renderingIndex++;
      if (this.renderingIndex === Number.MAX_SAFE_INTEGER) {
         this.renderingIndex = 0;
      }
   };

   private _hideDisplay = () => {
      if (this._isDisplaying) {
         Logger.info("hide client displays");
         Object.entries(this.multimonModel.monitors).forEach(([key, item]) => {
            //@ts-ignore
            item.hideDisplay();
         });
         this._isDisplaying = false;
      }
   };

   /**
    * During prepare of rendering, we better hide the screen
    */
   private _showDisplay = () => {
      const prepareTimeEstimation = 500;
      if (!this._isDisplaying) {
         setTimeout(() => {
            Object.entries(this.multimonModel.monitors).forEach(([key, item]) => {
               //@ts-ignore
               item.showDisplay();
            });
         }, prepareTimeEstimation);
         this._isDisplaying = true;
      }
   };

   public rectStreamAppendData = (data, onDone, onError) => {
      let rect = data.data,
         key = 0,
         monitorSettings = this.multimonModel.monitorSettings,
         dispatchCount = 0,
         i;

      if (rect.encoding === EncodingTypes.encUpdateCache) {
         if (rect.opcode === UpdateCacheType["updateCacheOpInit"]) {
            this._cacheInited = true;
            this._showDisplay();
         }
         if (!this._cacheInited) {
            Logger.trace("receive a cache rect during switching", Logger.DISPLAY);
            this._hideDisplay();
            onDone();
            return;
         }
      }
      rect.frameId = data.frameId;
      this.renderingList[this.renderingIndex] = {};
      this.renderingCallback[this.renderingIndex] = {
         onDone: onDone,
         onError: onError
      };

      if (rect.opcode === UpdateCacheType["updateCacheOpBegin"]) {
         if (this.caches.length !== 0) {
            Logger.debug("cache begin from a wrong place", Logger.DISPLAY);
            onError();
         }
         this.caches[0] = {
            rect: rect,
            renderingIndex: this.renderingIndex
         };
         this.renderingNext();
         return;
      } else if (this.caches.length > 0 && rect.opcode !== UpdateCacheType["updateCacheOpEnd"]) {
         this.caches.push({
            rect: rect,
            renderingIndex: this.renderingIndex
         });
         this.renderingNext();
         return;
      }
      // dispatch to all valid monitors.
      for (const key in this.multimonModel.monitors) {
         if (this.multimonModel.monitors.hasOwnProperty(key)) {
            if (this.shouldDispatch(rect, key)) {
               this.renderingList[this.renderingIndex][key] = true;
               if (rect.opcode === UpdateCacheType["updateCacheOpEnd"]) {
                  if (this.caches.length === 0) {
                     Logger.debug("cache end to a wrong place", Logger.DISPLAY);
                     onError();
                  }
                  for (i = 0; i < this.caches.length; i++) {
                     this.multimonModel.monitors[key].onDisplayChanged(
                        this.caches[i].rect,
                        this.caches[i].renderingIndex
                     );
                  }
                  this.caches = [];
               }
               this.multimonModel.monitors[key].onDisplayChanged(rect, this.renderingIndex);
               dispatchCount++;
            }
         }
      }
      if (rect.opcode !== UpdateCacheType["updateCacheOpEnd"] && !rect.skipRelease) {
         this.vncDecoder.releaseRectData(rect);
      }
      if (dispatchCount === 0) {
         Logger.debug("A rectangle is not dispatched to any monitor!", Logger.DISPLAY);
         onError();
         delete this.renderingList[this.renderingIndex];
         delete this.renderingCallback[this.renderingIndex];
      }
      this.renderingNext();
   };

   public h264Stream = {
      init: function (key, onDecodeComplete, onDecodeMP4Error) {
         // do nothing right now.
      },
      appendData: function (key, data, onDecodeComplete, onDecodeMP4Error) {
         // do nothing right now.
      }
   };

   public reset() {
      // do nothing right now.
   }
}
