/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * html5MMR-sessionMgr.ts --
 *
 * Manage Html5 MMR session and handle events between client and server.
 */
import WMKS from "WMKS";
import { BusEvent, clientUtil, EventBusService, LocalStorageService, TranslateService } from "@html-core";
import Logger from "../../../../core/libs/logger";
import { HTML5MMR_CONST, JKEYS, WEB_COMMAND } from "./html5MMR-consts";
import { Html5MMRDeviceMgr } from "./htmlMMR-deviceMgr";
import { Html5MMR } from "./html5MMR.media.manager";
import { AudioResourceManager } from "../../media/audio-resource-manager";
import { MediaManager } from "../../media/media-manager";
import { VideoResourceManager } from "../../media/video-resource-manager";
import { HTML5MMR } from "./model/html5MMR.rpc.models";
import { HTML5MMR as HTML5MMRSession } from "./model/html5MMR.session.models";
import { Html5MMRChan } from "./html5MMRChan";
import { ClientSettingModel } from "../../../common/model/client-setting-model";
import { webRTCAdapter } from "../../webrtc/webrtc-adapter";
import { Html5mmrRedirInstance } from "./Html5mmrRedirInstance";
import { WebRTCInstance } from "./webRTCInstance";
import { BCRWebViewInstance } from "./bcrWebViewInstance";
import { BCRHelper } from "./bcrHelper";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { MediastreamNotification } from "../../../common/service/mediastream-notification";
import { GoogleCommonSettings } from "../../../../chrome-client/launcher/server-connect/google-common-settings.service";
import { GeolocationService } from "../../../common/service/geolocation-service";
import { RootModel } from "../../../common/model/root-model";

export class Html5MMRSessionMgr {
   private logger = new Logger(Logger.HTML5MMR);
   private bcrHelper = new BCRHelper();
   private mmrChannel: Html5MMRChan.Client;
   private deviceMgr: Html5MMRDeviceMgr;
   private mediaService: Html5MMR.MediaService;
   private redirInstances: Map<number, Html5mmrRedirInstance> = new Map<number, Html5mmrRedirInstance>();

   private remoteScaleDPI: number;
   private eventBusService: EventBusService;
   private clientSettingModel: ClientSettingModel;
   private translate: TranslateService;
   private modalDialogService: ModalDialogService;
   private mediastreamNotification: MediastreamNotification;
   private googleCommonSettings: GoogleCommonSettings;
   private geolocationService: GeolocationService;
   private rootModel: RootModel;
   private wmksSessionKey: string;
   constructor(
      sessionModel: HTML5MMRSession.SessionModel,
      clientSettingModel: ClientSettingModel,
      localStorageService: LocalStorageService,
      eventBusService: EventBusService,
      translate: TranslateService,
      modalDialogService: ModalDialogService,
      mediastreamNotification: MediastreamNotification,
      googleCommonSetting: GoogleCommonSettings,
      geolocationService: GeolocationService,
      rootModel: RootModel
   ) {
      this.eventBusService = eventBusService;
      this.clientSettingModel = clientSettingModel;
      this.deviceMgr = Html5MMRDeviceMgr.getInstance();
      this.translate = translate;
      this.modalDialogService = modalDialogService;
      this.mediastreamNotification = mediastreamNotification;
      this.googleCommonSettings = googleCommonSetting;
      this.rootModel = rootModel;
      this.mediaService = new Html5MMR.MediaService(
         new MediaManager(new AudioResourceManager(), new VideoResourceManager(localStorageService, rootModel)),
         localStorageService
      );
      this.remoteScaleDPI = sessionModel.remoteDPI;
      this.geolocationService = geolocationService;
      this.wmksSessionKey = sessionModel.key || "";
      this.eventBusService
         .listen(BusEvent.DPIDataChangeEvent.MSG_TYPE)
         .subscribe((msg: BusEvent.DPIDataChangeEvent) => {
            if (sessionModel.key !== msg.wmksKey) {
               return;
            }
            this.remoteScaleDPI = msg.remoteScaleDPI;
         });
   }

   public init = (MMRChannel: Html5MMRChan.Client) => {
      this.mmrChannel = MMRChannel;
      if (clientUtil.isChromeClient()) {
         this.createOnConnectListener();
      }
   };

   private createOnConnectListener = () => {
      if (chrome && chrome.runtime && chrome.runtime.onConnect) {
         chrome.runtime.onConnect.addListener(this.bcrInjectedScriptConnected);
      }
   };

   /**
    * Listens for port with name BCR which will send webpage title for webview.
    * For webview with a valid instance ID send title to the server.
    * @param port
    */
   private bcrInjectedScriptConnected = (port: chrome.runtime.Port) => {
      if (port.name === "BCR") {
         port.onMessage.addListener((msg) => {
            try {
               const webViewInstance = this.redirInstances.get(Number(msg.tabInstanceId)) as BCRWebViewInstance;
               switch (msg.type) {
                  case "tabTitle":
                     webViewInstance.sendMessageUpdateTitle(msg.title, msg.url);
                     break;
                  case "fullScreen":
                     webViewInstance.handleFullScreenChange(Boolean(msg.fullScreen));
                     break;
                  default:
                     Logger.error(
                        "Recieved unknown message from BCR content script with type: " + msg.type,
                        Logger.BCR
                     );
               }
            } catch (e) {
               Logger.error("Error with attempting to send webview title to server" + e, Logger.BCR);
            }
         });
      } else if (port.name === "ENH_BCR") {
         //make these values constants
         port.onMessage.addListener((msg) => {
            try {
               (this.redirInstances.get(Number(msg.instanceId)) as BCRWebViewInstance).handleEnhBcrContentScriptMessage(
                  port,
                  msg
               );
            } catch (e) {
               Logger.error("Error with attempting to send enhanced BCR message to webview" + e, Logger.BCR);
            }
         });
      }
   };

   /**
    * This function is called immediately when resources to be cleaned up
    */
   public clear = () => {
      this.mediaService.clear();
      this.redirInstances.forEach((instance) => {
         instance.clear();
      });
   };

   /**
    * Handle CREATEBRWREDIR_INSTANCE command from server.
    * Show onetime camera/mic permission dialog to seek user permission,
    * irrespective of the fact, website is going to access camera/mic or not.
    * It's not possible to handle the same in webview permission, as it do not
    * accept async functions.
    *
    * @param {HTML5MMR.RPCRequest} cmd
    *    The incoming RPC request object.
    * @returns {boolean}
    */
   public createBrowserRedirInstance = async (cmd: HTML5MMR.MMRWebTextRequest, partitionId: string) => {
      if (!cmd.isValid) {
         this.logger.error(
            "Create browser redir instance command cannot be handled because it is invalid: " + cmd.JSONString
         );
         return false;
      }

      Logger.info("Html5MMR SessionMgr handle create browser redir instance cmd: " + cmd.paramsJSONString, Logger.BCR);

      const instanceId = cmd.instanceId;
      const instance = this.redirInstances.get(instanceId);
      //Ensure instanceId does not exist in the map
      if (instance !== undefined && instance !== null) {
         //This condition should not meet
         this.logger.error("The instance id " + instanceId + " already exist, some serious error with id");
         return false;
      }

      const cmdObj = cmd.webTextPayload;
      cmdObj[JKEYS.COMMAND_ID] = WEB_COMMAND.CREATEINSTANCE_RESULT;
      cmdObj[JKEYS.COMMAND] = HTML5MMR_CONST.CREATE_INSTANCE_DONE;
      cmdObj[JKEYS.ALLOW] = "true";
      cmdObj[JKEYS.BROWSER_REDIR_CLIENT_VERSION] = HTML5MMR_CONST.BROWSERREDIR_VERSION;
      cmdObj[JKEYS.CLIENT_CAPABILITY] = HTML5MMR_CONST.CLIENT_CAPABILITY_DEFAULT;
      cmdObj[JKEYS.PLAYERHTML_VERSION] = HTML5MMR_CONST.DEFAULT_PLAYERHTML_VERSION;
      cmdObj[JKEYS.CLIENT_SCRIPT] = "";
      cmdObj[JKEYS.ENH_BCR_SUPPORTED] = "" + this.googleCommonSettings.isEnhBrowserRedirEnabled();

      const resultStr = JSON.stringify(cmdObj);
      Logger.info("Html5MMR SessionMgr sending create instance result for browser redir : " + resultStr, Logger.BCR);

      this.mmrChannel.sendRPC(HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT, [
         instanceId,
         0,
         WEB_COMMAND.CREATEINSTANCE_RESULT,
         resultStr
      ]);

      const redirectedUrl = cmdObj.hasOwnProperty(JKEYS.URL) ? cmdObj[JKEYS.URL] : null;
      const isEnhBcr =
         cmdObj.hasOwnProperty(JKEYS.ENHANCED) && this.googleCommonSettings.isEnhBrowserRedirEnabled()
            ? cmdObj[JKEYS.ENHANCED]
            : false;
      if (isEnhBcr) {
         this.bcrHelper.enhancedBcrInjectedScript = cmdObj.hasOwnProperty(JKEYS.ENH_BCR_SCRIPT)
            ? cmdObj[JKEYS.ENH_BCR_SCRIPT]
            : "";
      }
      if (redirectedUrl) {
         Logger.info(`Starting browser redirection with URL: ${redirectedUrl}`, Logger.BCR);
         const bcrwebviewInstance = new BCRWebViewInstance(
            this.translate,
            this.mmrChannel,
            partitionId,
            instanceId,
            redirectedUrl,
            this.bcrHelper,
            isEnhBcr,
            cmd.webTextPayload,
            this.modalDialogService,
            this.mediastreamNotification,
            this.googleCommonSettings,
            this.geolocationService,
            this.rootModel
         );
         bcrwebviewInstance.initAndNavigate();
         this.redirInstances.set(instanceId, bcrwebviewInstance);
      } else {
         Logger.error(`URL sent by the server is empty`, Logger.BCR);
      }
   };

   /**
    * Handle create WebRTC instance command from server.
    *
    * @param {HTML5MMR.RPCRequest} cmd
    * @param {boolean} isApplicationSession
    *    The incoming RPC request object.
    * @returns {boolean}
    */
   public createWebRTCInstance = (cmd: HTML5MMR.MMRWebTextRequest, isApplicationSession: boolean): boolean => {
      if (!cmd.isValid) {
         this.logger.error("Create instance command cannot be handled because it is invalid: " + cmd.JSONString);
         return false;
      }

      this.logger.debug("Html5MMR SessionMgr handle create instance cmd: " + cmd.paramsJSONString);

      const instanceId = cmd.instanceId;
      const cmdObj = cmd.webTextPayload;

      const isAllow = !isApplicationSession;

      cmdObj[JKEYS.AGC] = "true";
      if (!isAllow) {
         cmdObj[JKEYS.ALLOW] = "false";
      } else {
         cmdObj[JKEYS.ALLOW] = "true";
         cmdObj[JKEYS.BROWSER] = this.getBrowserName();
         cmdObj[JKEYS.CLIENT_OS_VERSION] = this.getBrowserName();
         cmdObj[JKEYS.BROWSER_VER] = WMKS.BROWSER.version.major;
         cmdObj[JKEYS.CLIENT_SCRIPT] = "";
         cmdObj[JKEYS.CMD] = HTML5MMR_CONST.CREATE_INSTANCE;
         cmdObj[JKEYS.COMMAND] = HTML5MMR_CONST.CREATE_INSTANCE_DONE;
         cmdObj[JKEYS.COMMAND_ID] = WEB_COMMAND.CREATEINSTANCE_RESULT;
         cmdObj[JKEYS.FEATURE_SUPPORTED] = this.supportedFeatures;
         cmdObj[JKEYS.MAX_WIDTH] = HTML5MMR_CONST.VIDEO_MAXIMUM_WIDTH;
         cmdObj[JKEYS.WEBRTC_REDIR_CLIENT_VERSION] = HTML5MMR_CONST.WEBRTCREDIR_VERSION;
         cmdObj[JKEYS.ENABLE_DATACHANNEL] = 1;
         // cmdObj[JKEYS.LOG_TO_CONSOLE] = 1;   // Enable this line to debug Teams client on remote VM

         // set api capabilities
         cmdObj[JKEYS.API_CAPABILITIES] = cmdObj[JKEYS.API_CAPABILITIES] || {};
         cmdObj[JKEYS.API_CAPABILITIES][JKEYS.GET_STAT] = HTML5MMR_CONST.GET_STAT_CAPABILITY.GETSTAT_CAPABILITY_2; // Prefer to send whole getStat report
         cmdObj[JKEYS.API_CAPABILITIES][JKEYS.SENDER] = 1;
         cmdObj[JKEYS.API_CAPABILITIES][JKEYS.SET_SINKID] = 1;
         cmdObj[JKEYS.API_CAPABILITIES][JKEYS.JPEG_COMPRESS] = 1;
         cmdObj[JKEYS.API_CAPABILITIES][JKEYS.GET_SCREENS] = 12;
         cmdObj[JKEYS.API_CAPABILITIES][JKEYS.CONNECTION_STATE] = 1;
         cmdObj[JKEYS.API_CAPABILITIES][JKEYS.DATACHANNEL] = 1;
         cmdObj[JKEYS.RTP_CAPABILITIES] = webRTCAdapter.getRtpCapabilities();
         // TODO: Add more items in cmdObj if they are needed in the feature.
      }

      const resultStr = JSON.stringify(cmdObj);
      this.logger.info("Html5MMR SessionMgr sending create instance result: " + resultStr);

      this.mmrChannel.sendRPC(HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT, [
         instanceId,
         0,
         WEB_COMMAND.CREATEINSTANCE_RESULT,
         resultStr
      ]);

      if (!isAllow) {
         return false;
      }
      this.deviceMgr.getVideoCaps();

      const instance = new WebRTCInstance(instanceId, this.mediaService, this.eventBusService, this.mmrChannel, cmdObj);

      this.redirInstances.set(instanceId, instance);
      return true;
   };

   /**
    * Handle destroy WebRTC instance command from server.
    *
    * @param  {HTML5MMR.RPCRequest} cmd The incoming RPC request object.
    * @returns {boolean}
    */
   public destroyWebRTCInstance = (cmd: HTML5MMR.MMRWebTextRequest): boolean => {
      if (!cmd.isValid) {
         this.logger.error("Failed to handle destory webrtc instance as command is not valid. " + cmd.JSONString);
         return false;
      }
      this.destroyRedirInstance(cmd.instanceId);
   };

   public destroyRedirInstance = (instanceId: number): boolean => {
      this.logger.info("Html5MMR SessionMgr destroyinstance for Id = " + instanceId);

      const instance = this.redirInstances.get(instanceId);
      if (!instance) {
         this.logger.error("Failed to handle destory instance command as instance does not exist. " + instanceId);
         return false;
      }
      instance.clear();
      return this.redirInstances.delete(instanceId);
   };

   /**
    * Retrieve WebRTC player instance by given instance id
    *
    * @param  {number} instanceId given WebRTC player instance id
    * @returns WebRTCInstance return WebRTCInstance by given instance id
    */
   public getRedirInstance = (instanceId: number): Html5mmrRedirInstance => {
      return this.redirInstances.get(instanceId);
   };

   /**
    * Handle HTML5MMR_CMD_SEND_WEBTEXT for each target WebRTCInstance
    *
    * @param  {HTML5MMR.RPCRequest} cmd   given RPC request
    */
   public handleWebText = (cmd: HTML5MMR.MMRWebTextRequest) => {
      const instanceId: number = cmd.instanceId,
         webCommand: number = cmd.webCommand,
         webTextPayload: any = cmd.webTextPayload;
      const instance: Html5mmrRedirInstance = this.getRedirInstance(instanceId);
      if (!instance) {
         this.logger.error(
            "Unexpected instance " + instanceId + " webCommand: " + webCommand + " text: " + cmd.webTextPayloadString
         );
         return;
      }
      instance.handleWebText(webCommand, webTextPayload);
   };

   /**
    * Handle Environment parameters update when HTML5MMR_CMD_UPDATE_ENV is received
    *
    * @param  {HTML5MMR.MMRUpdateEnvRequest} cmd
    */
   public handleEnvUpdate = (cmd: HTML5MMR.MMRUpdateEnvRequest) => {
      const instanceId: number = cmd.instanceId,
         flag: HTML5MMR.EnvFlag = cmd.envFlag;
      const instance: Html5mmrRedirInstance = this.getRedirInstance(instanceId);
      if (!instance) {
         this.logger.error(
            "Unexpected instance " + instanceId + " env flag: " + flag + " text: " + cmd.webTextPayloadString
         );
         return;
      }
      instance.handleEnvUpdate(flag, cmd.webTextPayload);
   };

   /**
    * Handle default command(1) of binary data from server.
    *
    * @param {HTML5MMR.RPCRequest} binaryRequest
    *    The incoming RPC request object.
    * @returns {boolean}
    */
   // Now we only take care the binary data for notify audio. Will expand later.
   public handleDefaultBinary = (binaryRequest: HTML5MMR.MMRWebBinaryRequest) => {
      this.logger.debug(
         // TODO: remove this line after POC
         "Html5MMR SessionMgr handle default binary cmd: " + binaryRequest.JSONString
      );
      const instanceId = binaryRequest.instanceId,
         dataLength = binaryRequest.binaryDataLength,
         data = binaryRequest.binaryData, // Uint8Array
         streamEnum = binaryRequest.streamEnum;
      const instance: Html5mmrRedirInstance = this.getRedirInstance(instanceId);
      if (!instance) {
         this.logger.error("Unexpected instance " + instanceId + " binary data: " + binaryRequest.paramsJSONString);
         return;
      }
      instance.handleBinaryData(data, dataLength, streamEnum);
   };

   /**
    * Handle video overlay update
    *
    * @param  {HTML5MMR.MMRUpdateOverlayRequest} overlayUpdateRequest
    */
   public handleOverlayUpdate = (overlayUpdateRequest: HTML5MMR.MMRUpdateOverlayRequest) => {
      const instanceId = overlayUpdateRequest.instanceId;

      const instance: Html5mmrRedirInstance = this.getRedirInstance(instanceId);
      if (!instance) {
         this.logger.error(
            "Unexpected instance " + instanceId + " overlay update data: " + overlayUpdateRequest.paramsJSONString
         );
         return;
      }

      const isHighResolution = this.clientSettingModel.getBooleanItem("enableHighResolution");
      let dpiScaleFactor = this.remoteScaleDPI;
      if (isHighResolution) {
         dpiScaleFactor = dpiScaleFactor * window.devicePixelRatio;
      }

      this.logger.info(
         `handle overlay update [highres, remotedpi, localdpr] = [${isHighResolution}, ${this.remoteScaleDPI}, ${window.devicePixelRatio}]`
      );
      instance.handleUpdateOverlay(overlayUpdateRequest, dpiScaleFactor);
   };

   /**
    * Set preferred speaker for current session.
    *
    * @param {HTML5MMR.MMRWebTextRequest} cmd
    *    The parsed incoming RPC request parameters
    * @returns {boolean}
    */
   public setSinkId = (cmd: HTML5MMR.MMRWebTextRequest) => {
      const instanceId = cmd.instanceId;
      const webTextPayload = cmd.webTextPayload;

      const respObj = {};
      respObj[JKEYS.EVT] = HTML5MMR_CONST.SETSINKID;
      respObj[JKEYS.ID] = webTextPayload[JKEYS.ID];
      const audioDevice = this.deviceMgr.getDevice(webTextPayload.deviceId);

      if (audioDevice) {
         respObj[JKEYS.PEER] = webTextPayload[JKEYS.PEER];
         const instance: Html5mmrRedirInstance = this.getRedirInstance(instanceId);
         if (instance) {
            instance.setSinkId(audioDevice, webTextPayload[JKEYS.AUDIO_ID]);
         }
      } else {
         // Referred from: https://opengrok.eng.vmware.com/source/xref/main.perforce.1666/bora/apps/rde/html5mmr/web/webrtc/webRTCRedir.js#2496
         respObj[JKEYS.ERROR] = {
            code: HTML5MMR_CONST.RTCErrorType.UNSUPPORTED_OPERATION,
            name: "UNSUPPORTED_OPERATION",
            message: ""
         };
      }
      this.mmrChannel.sendRPC(HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT, [
         instanceId,
         0,
         WEB_COMMAND.SET_SINKID,
         JSON.stringify(respObj)
      ]);
   };

   /**
    * Check if WebRTC redirect is enabled.
    * @returns {array} string array of supported features
    */
   private get supportedFeatures(): Array<string> {
      const featureKeyVideo = "video",
         featureKeyScreenshare = "screenshare",
         featureKeyMultiMonScreenshare = "multimonitorscreenshare",
         features = [];

      if (this.isPlatformSupported) {
         features.push(featureKeyVideo);

         if (this.isScreenShareEnabled) {
            features.push(featureKeyScreenshare, featureKeyMultiMonScreenshare);
         }
      }
      return features;
   }

   /**
    * Determine if WebRTC Redirection is enabled by policy
    *
    * @returns {boolean} true if WebRTC Redirection is enabled
    */
   private get isWebRTCRedirectionEnabled(): boolean {
      const webRTCRedirectionKey = "enableWebRTCRedirection";
      return this.clientSettingModel.getBooleanItem(webRTCRedirectionKey);
   }

   /**
    * Determine if screenshare capability is enabled by policy
    *
    * @returns {boolean} true if screenshare is enabled
    */
   private get isScreenShareEnabled(): boolean {
      const screenSharePolicyKey = "enableScreenSharing";
      return this.clientSettingModel.getBooleanItem(screenSharePolicyKey);
   }

   /**
    * Determine if current platform supports MMR
    *
    * @returns {boolean} true if current platform supports HTML5 MMR features
    */
   private get isPlatformSupported(): boolean {
      // TODO: check more browser version to determin supported features
      return clientUtil.isChromeClient() || clientUtil.isChromium();
   }

   /**
    * Temporary solution to Browser name.
    * @returns {string} string of browser name
    */
   private getBrowserName = () => {
      if (clientUtil.isChromeClient()) {
         return "ChromeOS";
      } else if (clientUtil.isChromium()) {
         return "Chrome";
      } else if (clientUtil.isFirefox()) {
         return "Firefox";
      }
   };

   /**
    * Pass whitelist & navWhitelist received from server to bcrHelper.
    * For each VDI session there is one instance of bcrHelper.
    * Call bcrHelper.parseUrls(), after setting the patterns so that
    * bcrHelper will parse the pattern and extract all patterns been whitelisted.
    */
   public setWhitelist = (whitelist: string, navWhitelist: string, enhWhitelist: string) => {
      Logger.info("Setting whiltelist and navWhitelist for BCR." + Logger.BCR);
      this.bcrHelper.whitelist = whitelist ? whitelist : "";
      this.bcrHelper.navWhitelist = navWhitelist ? navWhitelist : "";
      this.bcrHelper.enhWhitelist = enhWhitelist ? enhWhitelist : "";
      this.bcrHelper.parseUrls();
   };

   /**
    * Handle active wmks session changed event
    *
    * @param  {wmksSessionKey} string
    */
   public OnActiveSessionChanged = (wmksSessionKey: string) => {
      if (wmksSessionKey === this.wmksSessionKey) {
         // Show video overlays for this session.
         this.mediaService.updateAllVideoElementsDisplay(true);
      } else {
         // Hide video overlays for this session.
         this.mediaService.updateAllVideoElementsDisplay(false);
      }
   };
}
