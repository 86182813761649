/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * border-util.js --
 *
 * util functions
 */

export class BorderUtil {
   public static readonly DEFAULT_EDGE_WIDTH = 8; //borderDefaultEdgeWidth = 8;
   public static readonly borderProperties = [
      {
         name: "leftEdge",
         verticalMovable: null,
         horizontalMovable: "left"
      },
      {
         name: "rightEdge",
         verticalMovable: null,
         horizontalMovable: "right"
      },
      {
         name: "topEdge",
         verticalMovable: "top",
         horizontalMovable: null
      },
      {
         name: "bottomEdge",
         verticalMovable: "bottom",
         horizontalMovable: null
      },
      {
         name: "leftTop",
         verticalMovable: "top",
         horizontalMovable: "left"
      },
      {
         name: "rightTop",
         verticalMovable: "top",
         horizontalMovable: "right"
      },
      {
         name: "leftBottom",
         verticalMovable: "bottom",
         horizontalMovable: "left"
      },
      {
         name: "rightBottom",
         verticalMovable: "bottom",
         horizontalMovable: "right"
      }
   ];

   /**
    * has side-effect, the shape will be modified
    */
   private static _completeShapeData = (shape, borderRect, borderEdgeWidth?: any) => {
      const result: any = {};
      const margin = borderEdgeWidth || BorderUtil.DEFAULT_EDGE_WIDTH;
      if (shape.left !== undefined) {
         shape.right = shape.left + margin;
      }
      if (shape.right !== undefined) {
         shape.left = shape.right - margin;
      }
      if (shape.top !== undefined) {
         shape.bottom = shape.top + margin;
      }
      if (shape.bottom !== undefined) {
         shape.top = shape.bottom - margin;
      }

      // assign
      if (shape.left !== undefined) {
         result.left = shape.left;
         result.width = margin;
      } else {
         result.left = borderRect.left + margin;
         result.width = borderRect.right - borderRect.left - 2 * margin;
      }
      if (shape.top !== undefined) {
         result.top = shape.top;
         result.height = margin;
      } else {
         result.top = borderRect.top + margin;
         result.height = borderRect.bottom - borderRect.top - 2 * margin;
      }

      //adjustment
      //TODO: clip into screen Rect
      result.width = Math.max(0, result.width);
      result.height = Math.max(0, result.height);

      return result;
   };

   public static getBorderShape = (borderRect: any, noExtraProperties?: any, borderEdgeWidth?: any) => {
      const borderShapeRects = [];
      BorderUtil.borderProperties.forEach((borderRectProperty: any) => {
         let borderShape: any = {};
         if (borderRectProperty.verticalMovable) {
            const alignedEdge = borderRectProperty.verticalMovable;
            borderShape[alignedEdge] = borderRect[alignedEdge];
         }
         if (borderRectProperty.horizontalMovable) {
            const alignedEdge = borderRectProperty.horizontalMovable;
            borderShape[alignedEdge] = borderRect[alignedEdge];
         }
         borderShape = BorderUtil._completeShapeData(borderShape, borderRect, borderEdgeWidth);
         if (!noExtraProperties) {
            borderShape.name = borderRectProperty.name;
            borderShape.verticalMovable = borderRectProperty.verticalMovable;
            borderShape.horizontalMovable = borderRectProperty.horizontalMovable;
         }
         borderShapeRects.push(borderShape);
      });
      return borderShapeRects;
   };
}
