/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { BusEvent, clientUtil, EventBusService } from "@html-core";
import { Injectable } from "@angular/core";
import { PreDataSetModel } from "../model/pre-data-set-model";
import { UserGlobalPref } from "../service/user-global-pref";
import { ConnectionServerModel } from "./connection-server-model";
import { NetworkStateService } from "../../desktop/networkState/network-state.service";

export type SettingItem = { key: string; value: string };

//will replace this with real class in future
type displayInfoType = any;
type brokerConfigType = any;
type remoteSettingManagerType = any;

@Injectable({
   providedIn: "root"
})
export class ClientSettingModel {
   public anyDesktopInMultimonMode: boolean = false;
   public static readonly minialWindowSize = {
      width: 800,
      height: 600
   };
   public static readonly smallWindowSize = {
      width: 1024,
      height: 768
   };
   public static readonly mediumWindowSize = {
      width: 1440,
      height: 900
   };
   public static readonly maxWindowSize = {
      width: 3840,
      height: 2560
   };
   private isImportMode: boolean;
   private remoteSettingManager;
   private static dpiAtAppInit: number = 0;
   constructor(
      private userGlobalPref: UserGlobalPref,
      private preDataSetModel: PreDataSetModel,
      private eventBusService: EventBusService,
      private connectionServerModel: ConnectionServerModel,
      private networkStateService: NetworkStateService
   ) {
      ClientSettingModel.dpiAtAppInit = Number(this.getTargetDPIScale().toFixed(2));
      Logger.info("Dpi for BCR during App init = " + ClientSettingModel.dpiAtAppInit, Logger.BCR);
   }

   // not RX will be inited using other channel, should be inited from XML&storage, and changed by setting dialog
   public getLaunchSetting = (allValidMonitors = false): any => {
      const adminCommonSettings = this.connectionServerModel.googleCommonAdminSettings;
      let policyAllowFileDownload = true;
      let policyAllowFileUpload = true;
      let enableRTAVH264Codec = true;
      let enableRTAVOpusCodec = true;
      let enableRTAVDTX = true;
      let enableBlastCodec = false;
      let delayKeysWhenMoveInside = false;
      let hardwareAccelerationOption = "no-preference";
      if (
         !!adminCommonSettings &&
         adminCommonSettings.hasOwnProperty("allowFileDownload") &&
         adminCommonSettings["allowFileDownload"] === false
      ) {
         policyAllowFileDownload = false;
      }
      if (
         !!adminCommonSettings &&
         adminCommonSettings.hasOwnProperty("allowFileUpload") &&
         adminCommonSettings["allowFileUpload"] === false
      ) {
         policyAllowFileUpload = false;
      }
      if (
         !!adminCommonSettings &&
         adminCommonSettings.hasOwnProperty("enableRTAVH264Codec") &&
         adminCommonSettings["enableRTAVH264Codec"] === false
      ) {
         enableRTAVH264Codec = false;
      }
      if (
         !!adminCommonSettings &&
         adminCommonSettings.hasOwnProperty("enableRTAVOpusCodec") &&
         adminCommonSettings["enableRTAVOpusCodec"] === false
      ) {
         enableRTAVOpusCodec = false;
      }
      if (
         !!adminCommonSettings &&
         adminCommonSettings.hasOwnProperty("enableRTAVDTX") &&
         adminCommonSettings["enableRTAVDTX"] === false
      ) {
         enableRTAVDTX = false;
      }
      if (
         !!adminCommonSettings &&
         adminCommonSettings.hasOwnProperty("hardwareAccelerationOption") &&
         (adminCommonSettings["hardwareAccelerationOption"] === "prefer-hardware" ||
            adminCommonSettings["hardwareAccelerationOption"] === "prefer-software")
      ) {
         hardwareAccelerationOption = adminCommonSettings["hardwareAccelerationOption"];
      }
      if (
         !!adminCommonSettings &&
         adminCommonSettings.hasOwnProperty("enableBlastCodec") &&
         adminCommonSettings["enableBlastCodec"] === true
      ) {
         enableBlastCodec = true;
      }

      if (
         adminCommonSettings?.hasOwnProperty("delayKeysWhenMoveInside") &&
         adminCommonSettings["delayKeysWhenMoveInside"]
      ) {
         delayKeysWhenMoveInside = adminCommonSettings["delayKeysWhenMoveInside"];
      }
      return {
         enableHighResolution: this.userGlobalPref.getPrefBooleanItem("enableHighResolution"),
         useMacOSXKeyMappings: this.userGlobalPref.getPrefBooleanItem("useMacOSXKeyMappings"),
         enableWindowsKey: this.userGlobalPref.getPrefBooleanItem("enableWindowsKey"),
         enableWindowsDeleteKey: this.userGlobalPref.getPrefBooleanItem("enableWindowsDeleteKey"),
         enableFitToViewer: this.userGlobalPref.getPrefBooleanItem("enableFitToViewer"),
         enableMP4: this.userGlobalPref.getPrefBooleanItem("enableMP4"),
         rxParameters: {},
         enableFolderSharing: this.userGlobalPref.getPrefBooleanItem("enableFolderSharing"),
         enableMultiMonitor: this.userGlobalPref.getPrefBooleanItem("enableMultiMonitor"),
         enableGeolocationSharing: this.userGlobalPref.getPrefBooleanItem("enableGeolocationSharing"),
         donotShowGeolocationDialog: this.userGlobalPref.getPrefBooleanItem("donotShowGeolocationDialog"),
         redirectSystemTray: this.userGlobalPref.getPrefBooleanItem("redirectSystemTray"),
         useRTAVChannel: !!window.chromeClient?.useRTAVChannel,
         selectedMonitors: JSON.parse(
            allValidMonitors
               ? this.preDataSetModel.settingData["allValidMonitors"]
               : this.preDataSetModel.settingData["selectedMonitors"]
         ),
         titanLaunchType: this.userGlobalPref.getPrefStringItem("titanLaunchType"),
         titanSingleLogout: this.userGlobalPref.getPrefBooleanItem("titanSingleLogout"),
         titanAutoResumption: this.userGlobalPref.getPrefBooleanItem("titanAutoResumption"),
         enableWebRTCRedirection: this.userGlobalPref.getPrefBooleanItem("enableWebRTCRedirection"),
         enableScreenSharing: this.userGlobalPref.getPrefBooleanItem("enableScreenSharing"),
         enableBCR: this.userGlobalPref.getPrefBooleanItem("enableBCR"),
         enableMediaStreamPermission: this.userGlobalPref.getPrefBooleanItem("enableMediaStreamPermission"),
         donotShowMediaStreamPermissionDialog: this.userGlobalPref.getPrefBooleanItem(
            "donotShowMediaStreamPermissionDialog"
         ),
         showNumOnTopbar: this.userGlobalPref.getPrefBooleanItem("showNumOnTopbar"),
         enableKeyMapping: this.userGlobalPref.getPrefBooleanItem("enableKeyMapping"),
         displaySetting: this.userGlobalPref.getPrefStringItem("displaySetting"),
         keyMappingSetting: this.userGlobalPref.getPrefStringItem("keyMappingSetting"),
         autoForwardUSB: this.userGlobalPref.getPrefBooleanItem("autoForwardUSB"),
         enableNetworkIndicator: this.networkStateService.getOptions().enableNetworkIndicator,
         networkStateConfig: this.networkStateService.getOptions().networkStateConfig,
         disableNetworkStateDisplay: this.preDataSetModel.settingData["disableNetworkStateDisplay"],
         policyAllowFileDownload: policyAllowFileDownload,
         policyAllowFileUpload: policyAllowFileUpload,
         enableRTAVH264Codec: enableRTAVH264Codec,
         enableRTAVOpusCodec: enableRTAVOpusCodec,
         enableRTAVDTX: enableRTAVDTX,
         hardwareAccelerationOption: hardwareAccelerationOption,
         enableBlastCodec: enableBlastCodec,
         enableAdvancedCodec: this.userGlobalPref.getPrefBooleanItem("enableAdvancedCodec"),
         delayKeysWhenMoveInside: delayKeysWhenMoveInside
      };
   };

   public getLaunchSettingJSON = () => {
      return encodeURIComponent(JSON.stringify(this.getLaunchSetting()));
   };

   public getLaunchSettingForAppJSON = () => {
      return encodeURIComponent(JSON.stringify(this.getLaunchSetting(true)));
   };

   private getCurrentDisplay = (displays: displayInfoType): displayInfoType => {
      const minIntersectionRatio = 0.1;
      let maxIntersectionRatio = minIntersectionRatio;
      let maxIntersectionDisplay = displays[0];
      const currentWindowRect = chrome.app.window.current().innerBounds;
      for (let i = 0; i < displays.length; i++) {
         const displayRect = displays[i].bounds;
         const maxLeft = Math.max(currentWindowRect.left, displayRect.left);
         const maxTop = Math.max(currentWindowRect.top, displayRect.top);
         const minRight = Math.min(
            currentWindowRect.left + currentWindowRect.width,
            displayRect.left + displayRect.width
         );
         const minDown = Math.min(
            currentWindowRect.top + currentWindowRect.height,
            displayRect.top + displayRect.height
         );
         const intersectionArea = Math.max(0, minRight - maxLeft) * Math.max(0, minDown - maxTop);
         const windowArea = currentWindowRect.width * currentWindowRect.height;
         // if current window has minIntersectionRatio in one of the monitor, consider the window could be in that monitor
         if (intersectionArea / windowArea > maxIntersectionRatio) {
            maxIntersectionRatio = intersectionArea / windowArea;
            maxIntersectionDisplay = displays[i];
         }
      }
      return maxIntersectionDisplay;
   };

   private getPrimaryDisplay = (displays: Array<displayInfoType>): displayInfoType => {
      for (let i = 0; i < displays.length; i++) {
         if (displays[i].isPrimary) {
            return displays[i];
         }
      }
      return null;
   };

   // for Chrome Client only
   public getLaunchWindowParams = (isApplication: boolean): Promise<any> => {
      return new Promise((resolve, reject) => {
         if (!clientUtil.isChromeClient()) {
            reject();
         }
         chrome.system.display.getInfo((displays: Array<displayInfoType>) => {
            if (!isApplication) {
               const currentMonitor = this.getCurrentDisplay(displays);
               if (!currentMonitor) {
                  reject();
               } else {
                  const displayBound = currentMonitor.workArea || currentMonitor.bounds,
                     setting = JSON.parse(this.preDataSetModel.settingData["selectedMonitors"]);
                  if (setting !== null && setting[0] && setting[0].settings) {
                     displayBound.left = setting[0].settings.x;
                     displayBound.top = setting[0].settings.y;
                  }
                  resolve({
                     outerBounds: displayBound,
                     resizable: true,
                     minWidth: ClientSettingModel.minialWindowSize.width,
                     minHeight: ClientSettingModel.minialWindowSize.height,
                     frameStyle: "chrome",
                     showInShelf: true,
                     isDesktopSession: true
                  });
               }
            } else {
               const primaryScreen = this.getPrimaryDisplay(displays);
               if (!primaryScreen) {
                  reject();
               } else {
                  const displayBound = primaryScreen.bounds;
                  resolve({
                     outerBounds: displayBound,
                     resizable: true,
                     minWidth: ClientSettingModel.minialWindowSize.width,
                     minHeight: ClientSettingModel.minialWindowSize.height,
                     frameStyle: "none",
                     showInShelf: false,
                     isDesktopSession: false
                  });
               }
            }
         });
      });
   };

   public getPrefNumberItem = (key: string): number => {
      const value: number = this.userGlobalPref.getPrefNumberItem(key);
      return isNaN(value) ? null : value;
   };

   public getBooleanItem = (key: string): boolean => {
      return this.userGlobalPref.getPrefBooleanItem(key);
   };

   public getBCRBooleanItem = (key: string): boolean => {
      return this.userGlobalPref.getPrefBooleanItem(key);
   };

   public getStringItem = (key: string): string => {
      return this.userGlobalPref.getPrefStringItem(key);
   };

   public getTargetDPIScale = (): number => {
      const highResolutionEnabled = this.getBooleanItem("enableHighResolution");
      return highResolutionEnabled ? 1.0 : window.devicePixelRatio || 1.0;
   };

   public bindRemoteSettingManager = (remoteSettingManager: remoteSettingManagerType) => {
      this.remoteSettingManager = remoteSettingManager;
      this.remoteSettingManager.onSessionSettingSyncBack((sessionKey: string, settings: Array<SettingItem>) => {
         Logger.info("receive setting change from session " + sessionKey + " as " + JSON.stringify(settings));
         if (settings) {
            settings.forEach((setting) => this.updateSetting(setting.key, setting.value));
            this.saveSetting();
         }
      });
   };

   /**
    * @param allowNewKey would only matter when isImportMode is true
    * when set as true, updateSetting accepts any new setting key
    * Ideally, the allowNewKey should never be set as True by ensure the
    * imported setting contains all needed keys
    */
   public updateSetting = (key: string, value: string, allowNewKey: boolean = false) => {
      this.userGlobalPref.updatePrefData(
         {
            action: key,
            text: value
         },
         allowNewKey
      );
      if (this.remoteSettingManager) {
         Logger.debug("syncing setting to session" + key);
         this.remoteSettingManager.syncSettingToSession(key, value);
      }
   };

   public syncSetting = (enforceSyncKeyList?: Array<string>) => {
      this.eventBusService.dispatch(new BusEvent.SyncSessionSettingMsg(enforceSyncKeyList));
   };

   public syncSettingToSession = (key: string, value: string) => {
      if (this.remoteSettingManager) {
         Logger.debug("syncing setting to session" + key);
         this.remoteSettingManager.syncSettingToSession(key, value);
      }
   };

   public saveSetting = () => {
      this.eventBusService.dispatch({
         type: "postPrefData"
      });
   };

   public getAdminSettings = (): brokerConfigType => {
      return this.userGlobalPref.getAdminSettings();
   };

   public static getDPIAtAppInit = (): number => {
      return ClientSettingModel.dpiAtAppInit;
   };

   public getKeyMapSupBooleanItem = (key: string): boolean => {
      let result: boolean = false;
      switch (key) {
         case "isWinKeySupported":
            result = !clientUtil.isIOS() && !clientUtil.isAndroid() && !this.getBooleanItem("enableKeyMapping");
            break;
         case "areMacOSXKeyMappingsSupported":
            result = clientUtil.isMacOS() && !this.getBooleanItem("enableKeyMapping");
            break;
         case "isWinDeleteKeySupported":
            result = clientUtil.isChromeOS() && !this.getBooleanItem("enableKeyMapping");
            break;
         default:
            Logger.error("Unsupported preference type: " + key);
      }
      return result;
   };
}
