/**
 * ******************************************************
 * Copyright (C) 2015-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { globalArray } from "../../jscdk/jscdkClient";
import Logger from "../../../core/libs/logger";
import { clientUtil, EventBusService } from "@html-core";
import { PrefData, XMLPreference } from "./prefdata";
import { ConnectionServerModel } from "../model/connection-server-model";
import { Injectable } from "@angular/core";
import { timezoneMap } from "../timezone/timezone-history.consts";
import { PreferenceInfo, UserGlobalPref } from "./user-global-pref";
import { PreDataSetModel } from "../model/pre-data-set-model";
import { ViewClientModel } from "../model/viewclient-model";
import { BusEvent } from "../../../core/services/event";

type googleAdminList = any;

@Injectable({
   providedIn: "root"
})
export class HorizonUserGlobalPref extends UserGlobalPref {
   private initialized: boolean;
   private dirty: boolean;
   private commandName: string = "SetUserGlobalPref";

   constructor(
      protected prefData: PrefData,
      protected connectionServerModel: ConnectionServerModel,
      protected eventBusService: EventBusService,
      protected preDataSetModel: PreDataSetModel,
      protected viewClientModel: ViewClientModel
   ) {
      super();
      this.initialized = false;
      this.dirty = false;

      if (clientUtil.isChromeClient()) {
         this.syncAdminSetting();
         this.syncAdminCommonSetting();
      }
   }

   public getPrefDataInJson() {
      return this.prefData.toJSON(this.prefData.prefData);
   }

   public postPrefData(syncPost?: boolean) {
      if (this.readyToSend()) {
         const uiController = globalArray["uiController"];
         if (uiController) {
            const action: any = {
               method: this.commandName,
               prefData: this.getPrefDataInJson()
            };
            if (syncPost) {
               action.async = false;
               action.timeout = 2000;
            }
            // Don't need to show loading icon when set pref data
            uiController.invokeJSCDK(action, true, true);
         }
      }
   }

   public clearPrefData = () => {
      this.prefData.reset();
      this.dirty = false;
   };

   public onDataSent = () => {
      this.dirty = false;
   };

   public readyToSend = (): boolean => {
      return this.initialized && this.dirty;
   };

   public getAdminSettings = () => {
      let googleAdminSettings: Array<googleAdminList> = this.connectionServerModel.googleAdminSettings,
         serverAdminSetting = null;
      if (!clientUtil.isChromeClient()) {
         return null;
      }
      if (googleAdminSettings) {
         for (let i = 0; i < googleAdminSettings.length; i++) {
            if (googleAdminSettings[i].address.replace("https://", "") === this.connectionServerModel.host) {
               serverAdminSetting = googleAdminSettings[i].settings;
            }
         }
      }
      return serverAdminSetting;
   };

   public getCommonAdminSettings = () => {
      const googleCommonAdminSettings = this.connectionServerModel.googleCommonAdminSettings;
      if (!clientUtil.isChromeClient()) {
         return null;
      }
      return googleCommonAdminSettings;
   };

   private syncAdminSetting = () => {
      const adminSettings: Array<googleAdminList> = this.getAdminSettings();
      if (adminSettings !== null) {
         for (const key in adminSettings) {
            if (this.prefData.prefData.hasOwnProperty(key)) {
               if ("timezoneSync" === key) {
                  let timezone: string = "";
                  const timezoneOffset: string = adminSettings[key].timezone;
                  if (!adminSettings[key].isSync && timezoneMap.hasOwnProperty(timezoneOffset)) {
                     timezone = timezoneMap[timezoneOffset];
                  }
                  this.prefData.prefData[key] = adminSettings[key].isSync ? "" : timezone;
               } else {
                  this.prefData.prefData[key] = adminSettings[key] ? "true" : "false";
               }
               Logger.info("Admin set " + key + " to " + adminSettings[key]);
            }
         }
      } else {
         Logger.info("Admin setting is not set.");
      }
   };

   private syncAdminCommonSetting = () => {
      const adminCommonSettings = this.getCommonAdminSettings();
      if (adminCommonSettings !== null) {
         for (const key in adminCommonSettings) {
            if ("enableH264" === key) {
               this.prefData.prefData["enableMP4"] = adminCommonSettings[key] ? "true" : "false";
            }
            if (this.prefData.prefData.hasOwnProperty(key)) {
               if ("redirectSystemTray" === key || "enableWebRTCRedirection" === key || "enableScreenSharing" === key) {
                  this.prefData.prefData[key] = adminCommonSettings[key] ? "true" : "false";
               }
               Logger.info("Admin common settings set " + key + " to " + adminCommonSettings[key]);
            }
         }
      } else {
         Logger.info("Admin common setting is not set.");
      }
   };

   public setPrefData(data: XMLPreference) {
      JSON.parse(JSON.stringify(data));
      Logger.info("Received data: " + JSON.stringify(data));
      const adminSettings = this.getAdminSettings();
      Logger.info("Received adminSettings: " + JSON.stringify(adminSettings));
      const adminCommonSettings = this.getCommonAdminSettings();
      Logger.info("Received adminCommonSettings: " + JSON.stringify(adminCommonSettings));
      const supportEscapeToSidebar = !clientUtil.isChromeClient();
      this.prefData.prefData.isDisplayChanged = data.hasOwnProperty("isDisplayChanged")
         ? data.isDisplayChanged
         : "false";
      this.prefData.prefData.isTimezoneChanged = data.hasOwnProperty("isTimezoneChanged")
         ? data.isTimezoneChanged
         : "false";
      this.prefData.prefData.isDPISyncChanged = data.hasOwnProperty("isDPISyncChanged")
         ? data.isDPISyncChanged
         : "false";
      this.prefData.prefData.isWinKeyChanged = data.hasOwnProperty("isWinKeyChanged") ? data.isWinKeyChanged : "false";
      this.prefData.prefData.isWinDelKeyChanged = data.hasOwnProperty("isWinDelKeyChanged")
         ? data.isWinDelKeyChanged
         : "false";
      this.prefData.prefData.isRedirSysTrayChanged = data.hasOwnProperty("isRedirSysTrayChanged")
         ? data.isRedirSysTrayChanged
         : "false";
      this.prefData.prefData.isWebRTCRedirectionChanged = data.hasOwnProperty("isWebRTCRedirectionChanged")
         ? data.isWebRTCRedirectionChanged
         : "false";
      this.prefData.prefData.isScreenSharingChanged = data.hasOwnProperty("isScreenSharingChanged")
         ? data.isScreenSharingChanged
         : "false";
      this.prefData.prefData.isAutoFowardUSBChanged = data.hasOwnProperty("isAutoFowardUSBChanged")
         ? data.isAutoFowardUSBChanged
         : "false";
      this.prefData.prefData.isFolderSharingChanged = data.hasOwnProperty("isFolderSharingChanged")
         ? data.isFolderSharingChanged
         : "false";
      this.prefData.prefData.displaySetting = data.hasOwnProperty("displaySetting") ? data.displaySetting : undefined;
      this.prefData.prefData.customWindowWidth = data.hasOwnProperty("customWindowWidth")
         ? data.customWindowWidth
         : null;
      this.prefData.prefData.customWindowHeight = data.hasOwnProperty("customWindowHeight")
         ? data.customWindowHeight
         : null;
      this.prefData.prefData.favorites = data.favorites;
      this.prefData.prefData.enableFolderSharing =
         typeof data.enableFolderSharing === "undefined" ? "true" : data.enableFolderSharing;
      this.prefData.prefData.enableFileAssociation =
         data.enableFileAssociation === "undefined" ? "true" : data.enableFileAssociation;

      this.prefData.prefData.enableWindowsKey = data.enableWindowsKey;
      this.prefData.prefData.enableWindowsDeleteKey = data.enableWindowsDeleteKey;
      if (!supportEscapeToSidebar) {
         this.prefData.prefData.enableCtrlShiftAltUpKey = "false";
      } else {
         this.prefData.prefData.enableCtrlShiftAltUpKey = data.enableCtrlShiftAltUpKey;
      }
      this.prefData.prefData.enableMultiMonitor = data.enableMultiMonitor;
      this.prefData.prefData.enableMP4 = data.hasOwnProperty("enableMP4") ? data.enableMP4 : "false";
      this.prefData.prefData.enableClipboardPopupGuide =
         typeof data.enableClipboardPopupGuide === "undefined" ? "true" : data.enableClipboardPopupGuide;
      this.prefData.prefData.useMacOSXKeyMappings = data.useMacOSXKeyMappings;
      this.prefData.prefData.timezoneSync = data.timezoneSync;
      this.prefData.prefData.webAudioOutDeviceOption = data.webAudioOutDeviceOption;
      // fitToViewer default value is true
      this.prefData.prefData.enableFitToViewer =
         typeof data.enableFitToViewer === "undefined" ? "true" : data.enableFitToViewer;
      this.prefData.prefData.enableGeolocationSharing =
         typeof data.enableGeolocationSharing === "undefined" ? "false" : data.enableGeolocationSharing;
      this.prefData.prefData.donotShowGeolocationDialog =
         typeof data.donotShowGeolocationDialog === "undefined" ? "false" : data.donotShowGeolocationDialog;
      this.prefData.prefData.enableMediaStreamPermission =
         typeof data.enableMediaStreamPermission === "undefined" ? "false" : data.enableMediaStreamPermission;
      this.prefData.prefData.donotShowMediaStreamPermissionDialog =
         typeof data.donotShowMediaStreamPermissionDialog === "undefined"
            ? "false"
            : data.donotShowMediaStreamPermissionDialog;
      this.prefData.prefData.enableBCR = typeof data.enableBCR === "undefined" ? "true" : data.enableBCR;
      this.prefData.prefData.redirectSystemTray =
         typeof data.redirectSystemTray === "undefined" ? "false" : data.redirectSystemTray;
      // VR mode switch only show when VR mode is enabled, so default is true
      this.prefData.prefData.enableVRMode = typeof data.enableVRMode === "undefined" ? "true" : data.enableVRMode;
      this.prefData.prefData.showNumOnTopbar =
         typeof data.showNumOnTopbar === "undefined" ? "true" : data.showNumOnTopbar;
      this.prefData.prefData.autoForwardUSB =
         typeof data.autoForwardUSB === "undefined" ? "false" : data.autoForwardUSB;
      if (clientUtil.isTitanClient()) {
         this.prefData.prefData.titanLaunchType = data.titanLaunchType;
      }

      // webrtc redireion and screensharing from comming settings
      this.prefData.prefData.enableWebRTCRedirection =
         typeof data.enableWebRTCRedirection === "undefined" ? "true" : data.enableWebRTCRedirection;

      this.prefData.prefData.enableScreenSharing =
         typeof data.enableScreenSharing === "undefined" ? "true" : data.enableScreenSharing;

      if (!this.isGoogleCommonAdminSettingEditable("enableWebRTCRedirection")) {
         this.prefData.prefData.isWebRTCRedirectionChanged = "false";
         if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("enableWebRTCRedirection")) {
            this.prefData.prefData.enableWebRTCRedirection = adminCommonSettings["enableWebRTCRedirection"]
               ? "true"
               : "false";
         }
      }
      if (
         this.isGoogleCommonAdminSettingEditable("enableWebRTCRedirection") &&
         this.prefData.prefData.isWebRTCRedirectionChanged === "false"
      ) {
         if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("enableWebRTCRedirection")) {
            this.prefData.prefData.enableWebRTCRedirection = adminCommonSettings["enableWebRTCRedirection"]
               ? "true"
               : "false";
         }
      }
      if (!this.isGoogleCommonAdminSettingEditable("enableScreenSharing")) {
         this.prefData.prefData.isScreenSharingChanged = "false";
         if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("enableScreenSharing")) {
            this.prefData.prefData.enableScreenSharing = adminCommonSettings["enableScreenSharing"] ? "true" : "false";
         }
      }
      if (
         this.isGoogleCommonAdminSettingEditable("enableScreenSharing") &&
         this.prefData.prefData.isScreenSharingChanged === "false"
      ) {
         if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("enableScreenSharing")) {
            this.prefData.prefData.enableWebRTCRedirection = adminCommonSettings["enableScreenSharing"]
               ? "true"
               : "false";
         }
      }

      if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("autoForwardUSB")) {
         this.prefData.prefData.autoForwardUSB = adminCommonSettings["autoForwardUSB"] ? "true" : "false";
      }

      if (clientUtil.isIOS() || clientUtil.isAndroid() || clientUtil.isTitanClient()) {
         this.prefData.prefData.enableKeyMapping = "false";
      } else if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("enableKeyMapping")) {
         this.prefData.prefData.enableKeyMapping = adminCommonSettings["enableKeyMapping"] ? "true" : "false";
      } else {
         this.prefData.prefData.enableKeyMapping = this.viewClientModel.enableKeyMapping ? "true" : "false";
      }

      this.prefData.prefData.enableAdvancedCodec =
         typeof data.enableAdvancedCodec === "undefined" ? "false" : data.enableAdvancedCodec;

      /**
       * Refer to getDPI function in dpi.service.
       * When the value is 'true', it doesn't do dpi sync actually...
       */
      this.prefData.prefData.enableHighResolution =
         typeof data.enableHighResolution === "undefined" ? "false" : data.enableHighResolution;

      if (adminCommonSettings) {
         if (adminCommonSettings.hasOwnProperty("enableHighResolution")) {
            this.prefData.prefData.enableHighResolution = adminCommonSettings["enableHighResolution"].toString();
         }
         if (adminCommonSettings.hasOwnProperty("editable")) {
            if (
               adminCommonSettings.hasOwnProperty("enableHighResolution") &&
               adminCommonSettings["editable"].hasOwnProperty("enableHighResolution") &&
               adminCommonSettings["editable"]["enableHighResolution"] === true &&
               this.getPrefBooleanItem("isDPISyncChanged") === true
            ) {
               /**
                * Refer to getDPI function in dpi.service.
                * When the value is 'true', it doesn't do dpi sync actually...
                */
               this.prefData.prefData.enableHighResolution =
                  typeof data.enableHighResolution === "undefined" ? "false" : data.enableHighResolution;
            }
         }
      }

      if (adminSettings) {
         if (adminSettings.hasOwnProperty("enableHighResolution")) {
            this.prefData.prefData.enableHighResolution = adminSettings["enableHighResolution"].toString();
         }
         if (adminSettings.hasOwnProperty("enableWindowsKey")) {
            this.prefData.prefData.enableWindowsKey = adminSettings["enableWindowsKey"].toString();
         }
         if (adminSettings.hasOwnProperty("enableWindowsDeleteKey")) {
            this.prefData.prefData.enableWindowsDeleteKey = adminSettings["enableWindowsDeleteKey"].toString();
         }
         if (adminSettings.hasOwnProperty("timezoneSync")) {
            this.prefData.prefData.timezoneSync = adminSettings["timezoneSync"]["timezone"];
         }
         if (adminSettings.hasOwnProperty("enableWebRTCRedirection")) {
            this.prefData.prefData.enableWebRTCRedirection = adminSettings["enableWebRTCRedirection"].toString();
         }
         if (adminSettings.hasOwnProperty("enableScreenSharing")) {
            this.prefData.prefData.enableScreenSharing = adminSettings["enableScreenSharing"].toString();
         }
         if (adminSettings.hasOwnProperty("enableFolderSharing")) {
            this.prefData.prefData.enableFolderSharing = adminSettings["enableFolderSharing"].toString();
         }
         if (adminSettings.hasOwnProperty("enableH264")) {
            this.prefData.prefData.enableMP4 = adminSettings["enableH264"].toString();
         }

         if (
            !adminSettings.hasOwnProperty("editable") ||
            !adminSettings["editable"].hasOwnProperty("enableHighResolution") ||
            adminSettings["editable"]["enableHighResolution"] === false
         ) {
            this.prefData.prefData.isDPISyncChanged = "false";
         }
         if (
            !adminSettings.hasOwnProperty("editable") ||
            !adminSettings["editable"].hasOwnProperty("enableWindowsKey") ||
            adminSettings["editable"]["enableWindowsKey"] === false
         ) {
            this.prefData.prefData.isWinKeyChanged = "false";
         }
         if (
            !adminSettings.hasOwnProperty("editable") ||
            !adminSettings["editable"].hasOwnProperty("enableWindowsDeleteKey") ||
            adminSettings["editable"]["enableWindowsDeleteKey"] === false
         ) {
            this.prefData.prefData.isWinDelKeyChanged = "false";
         }
         if (
            !adminSettings.hasOwnProperty("editable") ||
            !adminSettings["editable"].hasOwnProperty("enableWebRTCRedirection") ||
            adminSettings["editable"]["enableWebRTCRedirection"] === false
         ) {
            this.prefData.prefData.isWebRTCRedirectionChanged = "false";
         }
         if (
            !adminSettings.hasOwnProperty("editable") ||
            !adminSettings["editable"].hasOwnProperty("enableScreenSharing") ||
            adminSettings["editable"]["enableScreenSharing"] === false
         ) {
            this.prefData.prefData.isScreenSharingChanged = "false";
         }
         if (
            !adminSettings.hasOwnProperty("editable") ||
            !adminSettings["editable"].hasOwnProperty("enableFolderSharing") ||
            adminSettings["editable"]["enableFolderSharing"] === false
         ) {
            this.prefData.prefData.isFolderSharingChanged = "false";
         }

         if (adminSettings.hasOwnProperty("editable")) {
            if (
               adminSettings.hasOwnProperty("timezoneSync") &&
               adminSettings["editable"].hasOwnProperty("timezoneSync") &&
               adminSettings["editable"]["timezoneSync"] === true &&
               this.getPrefBooleanItem("isTimezoneChanged") === true
            ) {
               this.prefData.prefData.timezoneSync = data.timezoneSync;
            }
            if (
               adminSettings.hasOwnProperty("enableHighResolution") &&
               adminSettings["editable"].hasOwnProperty("enableHighResolution") &&
               adminSettings["editable"]["enableHighResolution"] === true &&
               this.getPrefBooleanItem("isDPISyncChanged") === true
            ) {
               /**
                * Refer to getDPI function in dpi.service.
                * When the value is 'true', it doesn't do dpi sync actually...
                */
               this.prefData.prefData.enableHighResolution =
                  typeof data.enableHighResolution === "undefined" ? "false" : data.enableHighResolution;
            }
            if (
               adminSettings.hasOwnProperty("enableWindowsKey") &&
               adminSettings["editable"].hasOwnProperty("enableWindowsKey") &&
               adminSettings["editable"]["enableWindowsKey"] === true &&
               this.getPrefBooleanItem("isWinKeyChanged") === true
            ) {
               this.prefData.prefData.enableWindowsKey = data.enableWindowsKey;
            }

            if (
               adminSettings.hasOwnProperty("enableWindowsDeleteKey") &&
               adminSettings["editable"].hasOwnProperty("enableWindowsDeleteKey") &&
               adminSettings["editable"]["enableWindowsDeleteKey"] === true &&
               this.getPrefBooleanItem("isWinDelKeyChanged") === true
            ) {
               this.prefData.prefData.enableWindowsDeleteKey = data.enableWindowsDeleteKey;
            }

            if (
               adminSettings.hasOwnProperty("enableWebRTCRedirection") &&
               adminSettings["editable"].hasOwnProperty("enableWebRTCRedirection") &&
               adminSettings["editable"]["enableWebRTCRedirection"] === true &&
               this.getPrefBooleanItem("isWebRTCRedirectionChanged") === true
            ) {
               this.prefData.prefData.enableWebRTCRedirection =
                  typeof data.enableWebRTCRedirection === "undefined" ? "false" : data.enableWebRTCRedirection;
            }

            if (
               adminSettings.hasOwnProperty("enableScreenSharing") &&
               adminSettings["editable"].hasOwnProperty("enableScreenSharing") &&
               adminSettings["editable"]["enableScreenSharing"] === true &&
               this.getPrefBooleanItem("isScreenSharingChanged") === true
            ) {
               this.prefData.prefData.enableScreenSharing =
                  typeof data.enableScreenSharing === "undefined" ? "false" : data.enableScreenSharing;
            }

            if (
               adminSettings.hasOwnProperty("enableFolderSharing") &&
               adminSettings["editable"].hasOwnProperty("enableFolderSharing") &&
               adminSettings["editable"]["enableFolderSharing"] === true &&
               this.getPrefBooleanItem("isFolderSharingChanged") === true
            ) {
               this.prefData.prefData.enableFolderSharing =
                  typeof data.enableScreenSharing === "undefined" ? "false" : data.enableFolderSharing;
            }
         }
      }
      if (!this.isGoogleCommonAdminSettingEditable("enableH264")) {
         if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("enableH264")) {
            this.prefData.prefData.enableMP4 = adminCommonSettings["enableMP4"] ? "true" : "false";
         }
      }
      if (
         !this.isGoogleCommonAdminSettingEditable("redirectSystemTray") ||
         !this.getPrefBooleanItem("isRedirSysTrayChanged")
      ) {
         if (!this.isGoogleCommonAdminSettingEditable("redirectSystemTray")) {
            this.prefData.prefData.isRedirSysTrayChanged = "false";
         }
         if (!!adminCommonSettings && adminCommonSettings.hasOwnProperty("redirectSystemTray")) {
            this.prefData.prefData.redirectSystemTray = adminCommonSettings["redirectSystemTray"] ? "true" : "false";
         }
      }
      if (this.getPrefBooleanItem("enableKeyMapping")) {
         this.prefData.prefData.keyMappingSetting =
            data.hasOwnProperty("keyMappingSetting") && data.keyMappingSetting
               ? data.keyMappingSetting
               : this.initKeyMappingSetting(
                    this.prefData.prefData.enableWindowsKey === "true",
                    this.prefData.prefData.useMacOSXKeyMappings !== "false",
                    this.prefData.prefData.enableWindowsDeleteKey === "true"
                 );
      }
      this.initialized = true;

      Logger.info("Preference data set: " + JSON.stringify(this.prefData.prefData));
   }

   private isGoogleCommonAdminSettingEditable = (id) => {
      const setting = this.getCommonAdminSettings();
      if (!!setting && setting[id] !== undefined) {
         if (!setting["editable"]) {
            // if that setting has been set by google admin, but google admin has not set key "editale"
            // use default behavior for that setting, which is client cannot edit that setting
            return false;
         } else {
            if (setting["editable"][id] === undefined || setting["editable"][id] === false) {
               // if that setting has been set by google admin, but google admin key "editale" has not that
               // setting or set false for that setting, then client cannot edit that setting
               return false;
            } else {
               // if google admin set "editable" for true for that setting, client can edit that setting
               return true;
            }
         }
      } else {
         // if google admin has not set or google admin not set that setting, client behavior is the same as without google
         // admin setting, client can set that setting.
         return true;
      }
   };

   public getPrefData = (): XMLPreference => {
      return this.prefData.prefData;
   };

   /**
    https://wiki.eng.vmware.com/VDM/ClientBrokerXml#GetUserGlobalPreferences
    data: {name, text, action}
    */
   public updatePrefData = (data: PreferenceInfo, newKey: boolean = false) => {
      switch (data.action) {
         case "FavOff": {
            const favArray: Array<string> = this.prefData.prefData.favorites;
            favArray.forEach((value: string, index: number) => {
               if (value === data.text) {
                  favArray.splice(index, 1);
               }
            });
            break;
         }
         case "FavOn":
            this.prefData.prefData.favorites.push(data.text);
            break;
         case "useMacOSXKeyMappings":
         case "enableWindowsKey":
         case "enableWindowsDeleteKey":
         case "enableCtrlShiftAltUpKey":
         case "enableMP4":
         case "enableClipboardPopupGuide":
         case "timezoneSync":
         case "enableFitToViewer":
         case "enableMultiMonitor":
         case "enableFolderSharing":
         case "enableFileAssociation":
         case "enableHighResolution":
         case "enableGeolocationSharing":
         case "donotShowGeolocationDialog":
         case "enableMediaStreamPermission":
         case "donotShowMediaStreamPermissionDialog":
         case "redirectSystemTray":
         case "enableScreenSharing":
         case "titanLaunchType":
         case "enableVRMode":
         case "showNumOnTopbar":
         case "enableBCR":
         case "isDisplayChanged":
         case "isDPISyncChanged":
         case "isWinKeyChanged":
         case "isWinDelKeyChanged":
         case "isRedirSysTrayChanged":
         case "isWebRTCRedirectionChanged":
         case "isScreenSharingChanged":
         case "isFolderSharingChanged":
         case "isTimezoneChanged":
         case "displaySetting":
         case "customWindowWidth":
         case "customWindowHeight":
         case "keyMappingSetting":
         case "webAudioOutDeviceOption":
         case "autoForwardUSB":
         case "enableWebRTCRedirection":
         case "enableAdvancedCodec":
            this.prefData.prefData[data.action] = data.text;
            break;
         default:
            Logger.info("Action '" + data.action + "' is not supported yet.");
      }

      this.dirty = true;

      if (data.action === "displaySetting") {
         this.eventBusService.dispatch(new BusEvent.DisplaySettingChanged());
      }

      Logger.info("Preference data updated: " + JSON.stringify(this.prefData.prefData));
   };

   public toFlatFormat = (changed?: boolean) => {
      if (changed) {
         if (this.dirty) {
            return this.getPrefDataInJson();
         } else {
            return null;
         }
      }
      return this.getPrefDataInJson();
   };

   public getPrefNumberItem = (value: string): number => {
      let result: number;
      switch (value) {
         case "displaySetting":
            result = Number(this.prefData.prefData.displaySetting);
            break;
         case "customWindowWidth":
            result = Number(this.prefData.prefData.customWindowWidth);
            break;
         case "customWindowHeight":
            result = Number(this.prefData.prefData.customWindowHeight);
            break;
         default:
            Logger.error("Unsupported preference type: " + value);
      }
      Logger.info("Get preference data: " + value + " Result is " + result);
      return result;
   };

   public getPrefBooleanItem = (value: string): boolean => {
      let result: boolean = false;
      switch (value) {
         case "enableHighResolution":
            result = this.prefData.prefData.enableHighResolution === "true";
            break;
         case "useMacOSXKeyMappings":
            result = this.prefData.prefData.useMacOSXKeyMappings !== "false";
            break;
         case "enableWindowsKey":
            result = this.prefData.prefData.enableWindowsKey === "true";
            break;
         case "enableWebRTCRedirection":
            result = this.prefData.prefData.enableWebRTCRedirection === "true";
            break;
         case "enableWindowsDeleteKey":
            result = this.prefData.prefData.enableWindowsDeleteKey === "true";
            break;
         case "enableCtrlShiftAltUpKey":
            result = this.prefData.prefData.enableCtrlShiftAltUpKey === "true";
            break;
         case "enableScreenSharing":
            result = this.prefData.prefData.enableScreenSharing === "true";
            break;
         case "enableMP4":
            result = this.prefData.prefData.enableMP4 === "true";
            break;
         case "enableFolderSharing":
            result = this.prefData.prefData.enableFolderSharing === "true";
            break;
         case "enableFileAssociation":
            result = this.prefData.prefData.enableFileAssociation !== "false";
            break;
         case "enableMultiMonitor":
            result = this.prefData.prefData.enableMultiMonitor === "true";
            break;
         case "enableFitToViewer":
            result = this.prefData.prefData.enableFitToViewer === "true";
            break;
         case "enableClipboardPopupGuide":
            result = this.prefData.prefData.enableClipboardPopupGuide === "true";
            break;
         case "enableGeolocationSharing":
            result = this.prefData.prefData.enableGeolocationSharing === "true";
            break;
         case "donotShowGeolocationDialog":
            result = this.prefData.prefData.donotShowGeolocationDialog === "true";
            break;
         case "enableMediaStreamPermission":
            result = this.prefData.prefData.enableMediaStreamPermission === "true";
            break;
         case "donotShowMediaStreamPermissionDialog":
            result = this.prefData.prefData.donotShowMediaStreamPermissionDialog === "true";
            break;
         case "redirectSystemTray":
            result = this.prefData.prefData.redirectSystemTray === "true";
            break;
         case "enableVRMode":
            result = this.prefData.prefData.enableVRMode !== "false";
            break;
         case "showNumOnTopbar":
            result = this.prefData.prefData.showNumOnTopbar === "true";
            break;
         case "enableBCR":
            result = this.prefData.prefData.enableBCR === "true";
            break;
         case "enableKeyMapping":
            result = this.prefData.prefData.enableKeyMapping === "true";
            break;
         case "autoForwardUSB":
            result = this.prefData.prefData.autoForwardUSB === "true";
            break;
         case "enableAdvancedCodec":
            result = this.prefData.prefData.enableAdvancedCodec === "true";
            break;
         case "isDisplayChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isDisplayChanged") &&
               this.prefData.prefData.isDisplayChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isTimezoneChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isTimezoneChanged") &&
               this.prefData.prefData.isTimezoneChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isDPISyncChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isDPISyncChanged") &&
               this.prefData.prefData.isDPISyncChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isWinKeyChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isWinKeyChanged") &&
               this.prefData.prefData.isWinKeyChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isWinDelKeyChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isWinDelKeyChanged") &&
               this.prefData.prefData.isWinDelKeyChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isRedirSysTrayChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isRedirSysTrayChanged") &&
               this.prefData.prefData.isRedirSysTrayChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isWebRTCRedirectionChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isWebRTCRedirectionChanged") &&
               this.prefData.prefData.isWebRTCRedirectionChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isScreenSharingChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isScreenSharingChanged") &&
               this.prefData.prefData.isScreenSharingChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isAutoFowardUSBChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isAutoFowardUSBChanged") &&
               this.prefData.prefData.isAutoFowardUSBChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         case "isFolderSharingChanged":
            if (
               this.prefData.prefData.hasOwnProperty("isFolderSharingChanged") &&
               this.prefData.prefData.isFolderSharingChanged === "true"
            ) {
               result = true;
            } else {
               result = false;
            }
            break;
         default:
            Logger.error("Unsupported preference type: " + value);
      }
      Logger.info("Get preference data: " + value + " Result is " + result);
      return result;
   };

   public getPrefStringItem = (value: string): string => {
      let result: string = "";
      switch (value) {
         case "timezoneSync":
            result = this.prefData.prefData.timezoneSync;
            break;
         case "selectedMonitors":
            result = this.preDataSetModel.getDataValue("selectedMonitors");
            break;
         case "displaySetting":
            result = this.prefData.prefData.displaySetting;
            break;
         case "titanLaunchType":
            result = this.prefData.prefData.titanLaunchType;
            break;
         case "keyMappingSetting":
            result = this.prefData.prefData.keyMappingSetting;
            break;
         case "webAudioOutDeviceOption":
            result = this.prefData.prefData.webAudioOutDeviceOption;
            break;
         default:
            Logger.error("Unsupported preference type: " + value);
      }
      Logger.info("Get preference data: " + value + " Result is " + result);
      return result;
   };

   public initKeyMappingSetting = (
      enableWindowsKey: boolean,
      useMacOSXKeyMappings: boolean,
      enableWindowsDeleteKey: boolean
   ): string => {
      const KeyMappingSetting = [
         {
            platform: "Windows",
            isDefault: true,
            isOn: enableWindowsKey,
            fromShotcut: "Win+Control",
            toShortcut: "Win",
            originShortcut: "MetaLeft,ControlLeft",
            mappingShortcut: "MetaLeft"
         },
         {
            platform: "MacOS",
            isDefault: true,
            isOn: enableWindowsKey,
            fromShotcut: "⌘+Control",
            toShortcut: "Win",
            originShortcut: "MetaLeft,ControlLeft",
            mappingShortcut: "MetaLeft"
         },
         {
            platform: "MacOS",
            isDefault: true,
            isOn: useMacOSXKeyMappings,
            fromShotcut: "⌘+A",
            toShortcut: "Control+A",
            originShortcut: "MetaLeft,KeyA",
            mappingShortcut: "ControlLeft,KeyA"
         },
         {
            platform: "MacOS",
            isDefault: true,
            isOn: useMacOSXKeyMappings,
            fromShotcut: "⌘+C",
            toShortcut: "Control+C",
            originShortcut: "MetaLeft,KeyC",
            mappingShortcut: "ControlLeft,KeyC"
         },
         {
            platform: "MacOS",
            isDefault: true,
            isOn: useMacOSXKeyMappings,
            fromShotcut: "⌘+X",
            toShortcut: "Control+X",
            originShortcut: "MetaLeft,KeyX",
            mappingShortcut: "ControlLeft,KeyX"
         },
         {
            platform: "MacOS",
            isDefault: true,
            isOn: useMacOSXKeyMappings,
            fromShotcut: "⌘+V",
            toShortcut: "Control+V",
            originShortcut: "MetaLeft,KeyV",
            mappingShortcut: "ControlLeft,KeyV"
         },
         {
            platform: "ChromeOS",
            isDefault: true,
            isOn: enableWindowsKey,
            fromShotcut: "Search+Control",
            toShortcut: "Win",
            originShortcut: "ControlLeft,MetaLeft",
            mappingShortcut: "MetaLeft"
         },
         {
            platform: "ChromeOS",
            isDefault: true,
            isOn: enableWindowsDeleteKey,
            fromShotcut: "Alt+Backspace",
            toShortcut: "Delete",
            originShortcut: "AltLeft,Delete",
            mappingShortcut: "Delete"
         },
         {
            platform: "ChromeOS",
            isDefault: true,
            isOn: !enableWindowsDeleteKey,
            fromShotcut: "Alt+Backspace",
            toShortcut: "Control+Z",
            originShortcut: "AltLeft,Delete",
            mappingShortcut: "ControlLeft,KeyZ"
         },
         {
            platform: "Other",
            isDefault: true,
            isOn: enableWindowsKey,
            fromShotcut: "Meta+Control",
            toShortcut: "Win",
            originShortcut: "MetaLeft,ControlLeft",
            mappingShortcut: "MetaLeft"
         }
      ];
      return JSON.stringify(KeyMappingSetting);
   };
}
