/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export enum VDPConnectionResult {
   VDPCONNECT_INVALID = -1, // Invalid, Unset
   VDPCONNECT_SUCCESS = 0, // Successful connect/disconnect
   VDPCONNECT_FAILURE, // Failed to connect or disconnected due to a generic failure
   VDPCONNECT_TIMEOUT, // Failed to connect or disconnected due to a timeout
   VDPCONNECT_REJECTED, // Connection rejected
   VDPCONNECT_NETWORK_FAILURE, // Failed to connect or disconnected due to a network failure
   VDPCONNECT_CONNECTION_LOST, // Disconnect due to lost connection to server
   VDPCONNECT_SERVER_DISCONNECTED, // Server initiated generic disconnect
   VDPCONNECT_SERVER_ERROR, // Server initiated disconnect due to a generic server error
   VDPCONNECT_DISPLAY_NOT_ENOUGH_MEM, // Client's memory is not sufficient for the required display configuration
   VDPCONNECT_RESULT_UNSPECIFIED, // We dont know the result. We could be disconnected and yet not aware about the reason.
   VDPCONNECT_SERVER_DISCONNECTED_EXPIRED, // Server initiated disconnect. Session expired due to timeout.
   VDPCONNECT_SERVER_DISCONNECTED_MANUAL_LOGOUT, // User initiated disconnect or logout.
   VDPCONNECT_SERVER_DISCONNECTED_ADMIN_MANUAL, // Server initiated disconnect. Admin manually disconnected the session from admin ui.
   VDPCONNECT_SERVER_DISCONNECTED_RECONNECT, // Server initiated disconnect as a precursor to pending reconnect.
   VDPCONNECT_SERVER_SVGA_DRIVER_INCOMPATIBLE, // Server failed to attach to svgadevtap due to an incompatible version of svga drivers.
   VDPCONNECT_TERA_DISCONNECT_HOST_DRIVER_MANUAL_USER_DISCONNECT, // Tera 1 host card user disconnect.
   VDPCONNECT_TERA_DISCONNECT_HOST_DRIVER_INCOMPATIBLE_DRIVER_VERSION, // Tera 1 host card driver version mismatch.
   VDPCONNECT_ENCRYPTION_MISMATCH, // Mismatch in client/server encryption algorithms.
   VDPCONNECT_SERVER_DISCONNECTED_AES_DISABLED_LOCALLY, // The connection to the remote computer has been closed. If you are connecting through a Security Server, please ensure that AES encryption is enabled on the client.
   VDPCONNECT_CERTIFICATE_REJECTED_INVALID, // Certificate being invalid.
   VDPCONNECT_CERTIFICATE_REJECTED_LEGACY, // Certificate being the legacy PCoIP cert (no security level would connect).
   VDPCONNECT_SECURITY_FAILED_AT_FULL, // Weak certificate (no-security/warn-but-allow level would connect).
   VDPCONNECT_HOSTNAME_NOT_RESOLVABLE, // Connection failed due to the server FQDN not being resolvable.

   VDPCONNECT_USER_INIT_DISCONNECT, // User initiated disconnect from the client.
   VDPCONNECT_USER_INIT_DISCONNECTANDLOGOFF, // User initiated 'disconnect and log off' from the client.
   VDPCONNECT_USER_INIT_RESET, // User initiated desktop reset from the client.

   VDPCONNECT_CLIENT_SHOULD_DISCONNECT, // Flag for client that it should disconnect

   VDPCONNECT_GENERIC_LOGOUT, // Generic session logout (regardless of the initiator)

   /*
    * Generic application restart/shutdown when the cause is not known.
    * For example, on some systems, it doesn't tell us reliably that a system
    * restart/shutdown is happening that cause this app restart/shutdown.
    * All the application knows is that it was asked to exit (or be killed).
    * This case will cover such situation.
    */
   VDPCONNECT_GENERIC_APP_SHUTDOWN_RESTART,

   VDPCONNECT_NETWORK_FAILURE_WITH_CONTINUITY, // A network failure but in a scenario where network continuity enabled
   VDPCONNECT_SERVER_SHADOW_SESSION_ENDED, // The shadow session is no longer available.

   VDPCONNECT_MAX = 100 // Add any new error before this. Marker for our connection state reason boundary value.
}

const GracefulClose: Array<VDPConnectionResult> = [];

export enum BlastCloseEvent {
   NORMAL_CLOSURE = 1000, //The connection successfully completed the purpose for which it was created.
   GOING_AWAY = 1001, //The endpoint is going away, either because of a server failure or because the browser is navigating away from the page that opened the connection.
   PROTOCOL_ERROR = 1002,
   UNSUPPORTED_DATA = 1003, //The connection is being terminated because the endpoint received data of a type it cannot accept.
   NO_STATUS_RECEIVED = 1005, //Indicates that no status code was provided even though one was expected.
   ABNORMAL_CLOSURE = 1006, //Indicates that a connection was closed abnormally (that is, with no close frame being sent) when a status code is expected.
   SERVICE_RESTART = 1012,
   TRY_AGAIN_LATER = 1013,
   BAD_GATEWAY = 1014,
   TLS_HAND_SHAKE = 1015,
   RECONNECT_FAILED = 3000,
   HEARTBEAT_TIMEOUT = 3001,
   DISCONNECT_REQUEST_TIMEOUT = 3002,
   IDLE_TIMEOUT = 3003
}

export function isMKSSessionClosedNormally(code: BlastCloseEvent, reason: VDPConnectionResult): boolean {
   if (code && (code === BlastCloseEvent.NORMAL_CLOSURE || code === BlastCloseEvent.NO_STATUS_RECEIVED)) {
      return true;
   }

   // We don't implement VDPConnectionResult in webmks layer, the reason is pass to horizon client from the blast protocol
   // So, treat all the
   if (
      reason &&
      reason >= VDPConnectionResult.VDPCONNECT_SERVER_DISCONNECTED &&
      reason <= VDPConnectionResult.VDPCONNECT_SERVER_SHADOW_SESSION_ENDED
   ) {
      return true;
   }
   return false;
}

export function webMksReconnectFailure(code: number): boolean {
   return code === BlastCloseEvent.RECONNECT_FAILED;
}

export function blastSessionErrorMessage(code: number, reason: number): string {
   switch (reason) {
      case VDPConnectionResult.VDPCONNECT_SERVER_DISCONNECTED:
         return "VDPCONNECT_SERVER_DISCONNECTED";
      case VDPConnectionResult.VDPCONNECT_SERVER_ERROR:
         return "VDPCONNECT_SERVER_ERROR";
      case VDPConnectionResult.VDPCONNECT_SERVER_DISCONNECTED_EXPIRED:
         return "VDPCONNECT_SERVER_DISCONNECTED_EXPIRED";
      case VDPConnectionResult.VDPCONNECT_SERVER_DISCONNECTED_MANUAL_LOGOUT:
         return "VDPCONNECT_SERVER_DISCONNECTED_MANUAL_LOGOUT";
      case VDPConnectionResult.VDPCONNECT_SERVER_DISCONNECTED_ADMIN_MANUAL:
         return "VDPCONNECT_SERVER_DISCONNECTED_ADMIN_MANUAL";
      case VDPConnectionResult.VDPCONNECT_SERVER_DISCONNECTED_RECONNECT:
         return "VDPCONNECT_SERVER_DISCONNECTED_RECONNECT";
      case VDPConnectionResult.VDPCONNECT_SERVER_SVGA_DRIVER_INCOMPATIBLE:
         return "VDPCONNECT_SERVER_SVGA_DRIVER_INCOMPATIBLE";
      case VDPConnectionResult.VDPCONNECT_GENERIC_LOGOUT:
         return "VDPCONNECT_GENERIC_LOGOUT";
   }
   switch (code) {
      case BlastCloseEvent.RECONNECT_FAILED:
         return "RECONNECT_FAIL_M";
      case BlastCloseEvent.HEARTBEAT_TIMEOUT:
         return "VDPCONNECT_CONNECTION_LOST";
      case BlastCloseEvent.DISCONNECT_REQUEST_TIMEOUT:
         return "VDPCONNECT_TIMEOUT";
      default:
         return "VDPCONNECT_FAILURE";
   }
}
