/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { MediaType } from "./media-resource-manager";

/**
 * Utility class that provides helper functions which creates/remove/update media overlay related HTML elements
 */
export class MediaResourceUtil {
   public static readonly MEDIA_OVERLAY_CONTAINER = "media-overlay-container";

   public static readonly AUDIO_OVERLAY_CONTAINER = "audio-overlay-container";

   public static readonly VIDEO_OVERLAY_CONTAINER = "video-overlay-container";

   public static readonly AUDIO_ELEMENT_PREFIX = "Html5MMRAudio_";

   public static readonly VIDEO_ELEMENT_PREFIX = "Html5MMRVideo_";

   /**
    * Get media container html element
    *
    * @returns <media-overlay-container> element
    */
   public static getMediaContainer = () => {
      return document.getElementById(MediaResourceUtil.MEDIA_OVERLAY_CONTAINER);
   };

   /**
    * Get audio overlay html element
    *
    * @returns <audio-overlay-container> element
    */
   public static getAudioOverlayContainer = () => {
      return document.getElementById(MediaResourceUtil.AUDIO_OVERLAY_CONTAINER);
   };

   /**
    * Get video overlay html element
    *
    * @returns <video-overlay-container> element
    */
   public static getVideoOverlayContainer = () => {
      return document.getElementById(MediaResourceUtil.VIDEO_OVERLAY_CONTAINER);
   };

   /**
    * Helper function to create media html element by given id and media type
    *
    * @param  {string} id given elemment id
    * @param  {MediaType} type enum value can be either 'video' or 'audio'
    */
   private static _createMediaElement = (id: string, type: MediaType) => {
      const elem = document.createElement(type);
      elem.id = id;
      return elem;
   };

   /**
    * Retrieve html element by id
    *
    * @param  {string} id given html id
    */
   public static getElement = (id: string) => {
      return document.getElementById(id);
   };

   /**
    * Retrieve audio media elements
    *
    * @returns HTMLCollectionOf
    */
   public static getAudioElements = (): HTMLCollectionOf<HTMLAudioElement> => {
      const audioRoot = MediaResourceUtil.getAudioOverlayContainer(),
         audioElements = audioRoot.getElementsByTagName(MediaType.audio);
      return audioElements;
   };

   /**
    * Retrieve video media elements
    *
    * @returns HTMLCollectionOf
    */
   public static getVideoElements = (): HTMLCollectionOf<HTMLVideoElement> => {
      const videoRoot = MediaResourceUtil.getVideoOverlayContainer(),
         videoElements = videoRoot.getElementsByTagName(MediaType.video);
      return videoElements;
   };

   /**
    * Create audio html element by element id
    *
    * @param  {string} id given audio element id
    * @returns HTMLMediaElement  audio media element
    */
   public static createAudioElement = (id: string): HTMLMediaElement => {
      const elem = MediaResourceUtil._createMediaElement(id, MediaType.audio) as HTMLMediaElement,
         parent = MediaResourceUtil.getAudioOverlayContainer();

      MediaResourceUtil._createMediaWrapperElement(id, elem, parent);
      return elem;
   };

   /**
    * Create video html element by element id
    *
    * @param  {string} id given video element id
    * @returns HTMLMediaElement  video media element
    */
   public static createVideoElement = (id: string): HTMLMediaElement => {
      const elem = MediaResourceUtil._createMediaElement(id, MediaType.video) as HTMLMediaElement,
         parent = MediaResourceUtil.getVideoOverlayContainer();

      MediaResourceUtil._createMediaWrapperElement(id, elem, parent);
      return elem;
   };

   /**
    * Helper function that creates media wrapper element that contains both overlay and possibly other sub-component (e.g. stats overlay)
    *
    * @param  {string} id given media element id
    * @param  {HTMLMediaElement} elem  media element
    * @param  {HTMLElement} parent  parent container to append the overlay wrapper as child element
    */
   private static _createMediaWrapperElement = (id: string, elem: HTMLMediaElement, parent: HTMLElement) => {
      const overlayWrapper = document.createElement(`${id}_wrapper`);
      overlayWrapper.appendChild(elem);
      parent.appendChild(overlayWrapper);
   };

   /**
    * Convert MediaStream object to JSON object
    *
    * @param  {MediaStream} mediaStream given media stream object
    * @returns JSON object represents MediaStream
    */
   public static mediaStreamToJSON = (mediaStream: MediaStream) => {
      return {
         id: mediaStream.id,
         active: mediaStream.active,
         track: mediaStream.getTracks().map((track) => {
            return {
               id: track.id,
               kind: track.kind,
               label: track.label,
               enabled: track.enabled,
               muted: track.muted,
               readyState: track.readyState,
               /// TODO: Need to determine if we should handle remote and readonly properties from mediastream
               /// track.remote and track.readonly are deprecated
               settings: track.getSettings()
            };
         })
      };
   };
}
