<div class="modal-title dialog-title">{{ 'DEBUG_MODULE_LIST' | i18nv2 }}</div>

<div class="modal-body dialog-content" fuzzy-free>
    <div class="modal-dialog-container">
        <div class="modal-dialog-setting">
            <div class="modal-dialog-list">
                <div *ngFor="let entry of debugModuleList">
                    <div class="folder-item .clearfix">
                        <div class="folder-name" title="{{entry.moduleName}}">{{entry.moduleName}}</div>
                        <div class="modal-dialog-toggle-opt" tabindex="0" (keyup)="onKeypress($event)">
                            <input class="tgl tgl-light" id="{{entry.moduleName}}" type="checkbox" [hidden]="true" [(ngModel)]="entry.isEnabled" [disabled]="false">
                            <label class="tgl-btn unselectable hidecontentlabel" for="{{entry.moduleName}}" ></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer dialog-button-row">
    <div id="debug-config-ok" class="modal-button-base modal-button-right modal-button-blue no-disable-hover" (click)="debugConfigOkClicked()">
        {{ 'OK' | i18nv2 }}
    </div>
</div>