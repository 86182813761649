/**
 * ******************************************************
 * Copyright (C) 2016-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable, Inject } from "@angular/core";
import { clientUtil } from "@html-core";
import { ConnectionURIModel } from "../model/connection-uri-model";
import { ViewClientModel } from "../model/viewclient-model";
import { UtilService } from "../../launcher/common/util-service";
import { Ws1Service } from "./ws1.service";
import { CredCleanService } from "./credential-clean.service";
import { AppViewService } from "../../common/service/app-view.service";
import { WindowToken } from "../window.module";
import Logger from "../../../core/libs/logger";

@Injectable({
   providedIn: "root"
})
export class BaseViewService {
   public locationChange: boolean = false;
   public switchForURI = false;
   constructor(
      @Inject(WindowToken) private window: Window,
      private connectionURIModel: ConnectionURIModel,
      private viewClientModel: ViewClientModel,
      private utilService: UtilService,
      private ws1Service: Ws1Service,
      private credCleanService: CredCleanService,
      private appViewService: AppViewService
   ) {}

   public changeLocation = (value) => {
      Logger.info("Has changeLocation");
      setTimeout(() => {
         this.locationChange = true;
         if (value === "/home" || value === "/") {
            Logger.debug("jump to login pages", Logger.ROUTE);
            this.appViewService.jumpToLogin(null);
         } else if (value === "/desktop") {
            Logger.debug("jump to desktop page", Logger.ROUTE);
            this.appViewService.jumpToDesktop();
         } else if (value === "/launchitems") {
            Logger.debug("jump to launchitems page", Logger.ROUTE);
            this.appViewService.jumpToLaunchItems();
         } else {
            Logger.debug("jump to other page", Logger.ROUTE);
            this.appViewService.changeLocation(value);
         }
      });
   };

   public changeLocationWithSearch = (value, parameters) => {
      setTimeout(() => {
         this.locationChange = true;
         this.appViewService.changeLocationWithQuery(value, parameters);
      });
   };

   // use clearURI to minimal the change scope for fixing bug 2516193
   public gotoPortal = (clearURI?: any) => {
      Logger.info("jump back to landing page", Logger.ROUTE);
      if (clientUtil.isChromeClient()) {
         this.utilService.authenticationStatusCheck.clearAction("go to Portal");
         if (clearURI) {
            this.connectionURIModel.clear(false);
         }
         clientUtil.closeAllSessionWindows();
         this.changeLocation("/serverselect");
         if (this.ws1Service.isWS1Mode()) {
            clientUtil.closeAllWindows();
         }
      } else {
         if (this.ws1Service.isWS1Mode() || this.connectionURIModel.isF5Session()) {
            this.window.open("", "_self", "");
            this.window.close();
            //Bug 3053634 The tab should be closed after canceling disclaimer launching from WS1 Access
            //Bug 3040522 The client should not login after canceling Disclaimer from WS1 Access
            //https://reviewboard.eng.vmware.com/r/1947367/diff/3/
         }
         if (!this.viewClientModel.contextPath) {
            this.viewClientModel.contextPath = "";
         }
         setTimeout(() => {
            let landingAddress = this.window.location.protocol + "//" + this.window.location.host;
            landingAddress += this.viewClientModel.contextPath;
            this.window.location.href = landingAddress;
         });
      }
   };

   public goBackToLauncherFromDesktop = () => {
      this.credCleanService.setFlagBeforePageJumping();
      setTimeout(() => {
         this.window.location.href = "./index.html";
      });
   };

   public isBackInLauncher = (nextHash, currentHash) => {
      return currentHash === "/launchitems" && nextHash !== "/desktop";
   };

   public isBackFromDesktop = (nextHash, currentHash) => {
      /**
       * If the next hash is not #/desktop, we will treat it as back
       * button click
       */
      return currentHash === "/desktop" && nextHash !== "/desktop";
   };

   public isJumpingToDesktop = (nextHash, currentHash) => {
      return currentHash === "/launchitems" && nextHash === "/desktop";
   };

   // in launcher page, in the url tab, delete launch items and refresh page,
   public isRefreshingLauncher = (next, current) => {
      return current.indexOf("/launchitems") > 0 && next.indexOf("/desktop") <= 0;
   };
}
