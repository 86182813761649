/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * on extended monitor
 *
 * TODO: rename, refactor, and relocate
 * @format
 */

import { Injectable } from "@angular/core";
import { EventBusService, Logger, BusEvent } from "@html-core";
import { ExtendedWindowUnity } from "./unity-exchange";
import { MultimonMessageQueueService } from "../multimon/main-page/multimon-queue-message.service";
import { Monitor } from "../multimon/common/monitor-message";
import { UnityEventExchange } from "./unity-exchange";
import { UnityService } from "./unity.service";
import { Unity } from "./unity";
import { UnityHandler } from "./unity-handler";

import {
   UnityMessage,
   WindowAddMsg,
   WindowRemoveMsg,
   WindowTitleChangeMsg,
   WindowAttrChangeMsg,
   WindowTypeChangeMsg,
   WindowMoveMsg,
   WindowTitleBarAreaMsg,
   WindowPrimaryMsg,
   WindowSecondaryMsg,
   SystemTrayMsg,
   WindowRegionMsg,
   ZorderUpdateMsg,
   WindowIconChangeMsg,
   UnityAttrChangedMsg,
   UpdateCompleteMsg,
   UnityVisibilityChanged,
   UnityReady,
   UnityReadyChanged,
   UnityPaused,
   UnityNotReady,
   UnityDestroyMsg,
   UnitySubject
} from "./unity-messages";

@Injectable()
export class UnityMonitorConnectorPrimary {
   private sessionKey: string;
   private logger: Logger;
   constructor(
      private MultimonMessageQueueService: MultimonMessageQueueService,
      private eventBusService: EventBusService
   ) {
      this.logger = new Logger(Logger.UNITY, null, Logger.Modules.Unity);
   }
   public init = (unityService: UnityService, handler: UnityHandler, mgr: Unity.Mgr, sessionKey: string) => {
      this.sessionKey = sessionKey;
      this.logger.info("UnityMonitorConnector inited on primary monitor");
      unityService.subject$.subscribe(this.handleUnityMessage);
   };
   private boardCastToMonitor = (type: UnityEventExchange.MessageType, payload: any) => {
      this.logger.info("dispatching unity message to queue" + type);
      this.logger.dump(JSON.stringify(payload));
      const message: UnityEventExchange.Message = {
         type: type,
         payload: payload
      };
      this.MultimonMessageQueueService.afterAllMonitorReady(this.sessionKey).then(() => {
         this.logger.dump("sending unity message " + JSON.stringify(message));
         this.MultimonMessageQueueService.boardcastUnityMessage(message);
      });
   };

   public handleUnityMessage = (raw: UnityMessage) => {
      this.logger.debug("Unity connector received : " + raw.msgType);
      this.logger.dump(JSON.stringify(raw));
      switch (raw.msgType) {
         case WindowAddMsg.TYPE:
            {
               const msg = raw as WindowAddMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onWindowAdded, {
                  windowPath: msg.windowPath,
                  windowId: msg.windowId,
                  execPath: msg.execPath
               });
            }
            break;
         case WindowRemoveMsg.TYPE:
            {
               const msg = raw as WindowRemoveMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onWindowRemoved, {
                  windowId: msg.windowId
               });
            }
            break;
         case WindowMoveMsg.TYPE:
            {
               const msg = raw as WindowMoveMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onWindowMoved, {
                  windowId: msg.windowId,
                  rect: msg.rect
               });
            }
            break;
         case WindowRegionMsg.TYPE:
            {
               const msg = raw as WindowRegionMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onRegionUpdate, {
                  windowId: msg.windowId,
                  regions: msg.regions
               });
            }
            break;
         case UnityReadyChanged.TYPE:
            {
               const msg = raw as UnityReadyChanged;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onReadyChanged, msg.data);
            }
            break;
         case UpdateCompleteMsg.TYPE:
            {
               const msg = raw as UpdateCompleteMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onUpdateComplete, {});
            }
            break;
         case WindowTitleBarAreaMsg.TYPE:
            {
               const msg = raw as WindowTitleBarAreaMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onTitleBarAreaChanged, {
                  windowId: msg.windowId,
                  windowPositionInfo: msg.windowPositionInfo
               });
            }
            break;
         case WindowAttrChangeMsg.TYPE:
            {
               const msg = raw as WindowAttrChangeMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onWindowAttrChanged, {
                  windowId: msg.windowId,
                  type: msg.type,
                  value: msg.value
               });
            }
            break;
         case ZorderUpdateMsg.TYPE:
            {
               const msg = raw as ZorderUpdateMsg;
               this.boardCastToMonitor(UnityEventExchange.MessageType.onZorderUpdated, {
                  components: msg.components
               });
            }
            break;
         case UnityVisibilityChanged.TYPE: {
            const msg = raw as UnityVisibilityChanged;
            this.boardCastToMonitor(UnityEventExchange.MessageType.onVisibilityChanged, { isShow: msg.isShow });
         }
      }
   };
}
