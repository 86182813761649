/**
 * *****************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Logger } from "@html-core";
import { HorizonFileSystem } from "./horizon-file-system";

export class ChromeClientFileSystem extends HorizonFileSystem {
   constructor() {
      super();
   }

   public isRestorable = (info) => {
      const id = info.id;
      return new Promise((resolve, reject) => {
         chrome.fileSystem.isRestorable(id, function (isRestorable) {
            if (!isRestorable) {
               Logger.debug("invalid share folder id can't be added: " + id);
               resolve(null);
               return;
            }
            chrome.fileSystem.restoreEntry(id, (entry) => {
               if (!entry) {
                  Logger.error("id can't be used to restore folder: " + id);
                  resolve(null);
                  return;
               }
               resolve(entry);
            });
         });
      });
   };

   public chooseEntry = () => {
      return new Promise((resolve, reject) => {
         chrome.fileSystem.chooseEntry(
            {
               type: "openDirectory"
            },
            function (theEntry) {
               if (!theEntry || !theEntry.isDirectory) {
                  reject();
               }
               resolve(theEntry);
            }
         );
      });
   };

   public retainEntry = (entry): string => {
      return chrome.fileSystem.retainEntry(entry);
   };

   public getWritableEntry = (entry) => {
      return new Promise((resolve, reject) => {
         chrome.fileSystem.getWritableEntry(entry, (writableEntry) => {
            resolve(writableEntry);
         });
      });
   };

   public getDisplayPath = (rootEntry, callback) => {
      chrome.fileSystem.getDisplayPath(rootEntry, callback);
   };

   public getDirectoryEntryFromStorage = (name) => {
      return new Promise((resolve, reject) => {
         chrome.storage.local.get((obj) => {
            if (chrome.runtime.lastError) {
               Logger.error("chrome.runtime.lastError = " + chrome.runtime.lastError);
               resolve([]);
               return;
            }
            if (!obj) {
               resolve([]);
            } else {
               let ids = [];
               if (obj.hasOwnProperty(name.toLocaleLowerCase()) && obj[name.toLocaleLowerCase()]) {
                  ids = ids.concat(Array.from(obj[name.toLocaleLowerCase()]));
               }
               if (name !== name.toLocaleLowerCase()) {
                  if (obj.hasOwnProperty(name) && obj[name]) {
                     ids = ids.concat(Array.from(obj[name]));
                  }
               }
               ids = Array.from(new Set(ids));
               resolve(ids);
            }
         });
      });
   };

   public saveDirectoryEntryInStorage = (name, data) => {
      return new Promise((resolve, reject) => {
         const obj = {};
         obj[name] = data;
         chrome.storage.local.set(obj, () => {
            if (chrome.runtime.lastError) {
               const error = chrome.runtime.lastError;
               reject(error);
               return;
            }
            resolve("");
         });
      });
   };
}
