/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * entry-util
 *
 * This is pure newly written by referring existing FS designs, so there should be no patent issues.
 * Will add more functions when support on HTML Access.
 *
 * Implement a simplified Master file table in NTFS, but includes unchangeable attributes for Linux
 * This is pure newly  written by referring existing FS design, so there should be no patent issues
 * take space always be 512n
 * Don't support hard link, since it will bring difficulties in the future, and we don't has this need for now.
 * Don't support low level functions like fragmentation in the first version, since chromeOS don't have this need
 * Don't support linked list for the same reason
 * Don't implement director attributes with 32bits records for JS is high level language
 * Don't support w/r/x permission in the first version
 * Remove the operators related codes since we only need to support local folder redirection not include cloud driver
 *    for now
 * Adding ADHoc updating, so that client change can be sync to Agent also.
 */

import { IOOptimizer } from "./io-optimizor";
import Logger from "../../../core/libs/logger";
import { FSUtil } from "./fs-util";
import { EntryUtil } from "./entry-util";

export class FileNode {
   public ioOptimizer: IOOptimizer = null;
   public fileEntry: any = null;
   public isReadOnly: boolean = false;
   public name;
   public time: any = null;
   public rootId = null;
   public parentFolder: any = null;
   public isDirectory;
   public isDeletePending: boolean = false;
   // Hard link number
   public linkNumber = 1;
   public size;
   public contains: any = [];
   // For reading content
   public containReadCache: any = [];
   public containReadIndex = 0;
   public type;
   public cacheService;

   constructor(parent, name, isDirectory, size, fileEntry, rootId, type, modificationTime) {
      Logger.trace("create file node for " + name);
      if (!type) {
         Logger.error("type not exist");
         return;
      }

      let initTime;
      try {
         if (modificationTime) {
            initTime = FSUtil.timeToFileTime(new Date(modificationTime).getTime());
         } else {
            initTime = FSUtil.timeToFileTime(Date.now());
         }
      } catch (e) {
         Logger.error("Error when parse modified time" + e);
         initTime = FSUtil.timeToFileTime(Date.now());
      }
      this.time = {
         creationTime: initTime,
         lastAccessTime: initTime,
         changeTime: initTime,
         lastWriteTime: initTime
      };
      this.ioOptimizer = isDirectory ? null : new IOOptimizer();
      this.name = name;
      this.isDirectory = isDirectory;
      this.size = size;
      this.type = type;
      this.fileEntry = fileEntry;

      if (!parent) {
         this.rootId = rootId;
         this.parentFolder = null;
      } else {
         if (!parent.isDirectory) {
            Logger.error("<cdr> Fatal error: try to add a node into a non-folder position");
            return;
         }
         this.parentFolder = parent;
         this.rootId = parent.rootId;
      }

      if (parent) {
         parent.addNode(this);
      }
   }

   public addNode = (node) => {
      this.contains.push(node);
      Logger.trace("<cdr> " + node.name + " read as contents of " + this.name);
   };

   public addEntry = async (entry) => {
      const info: any = await EntryUtil.getInfo(entry);
      if (this.isContainingNodeOf(info)) {
         Logger.warning("<cdr> Adding a duplicated node" + JSON.stringify(info));
         return;
      }
      Logger.trace("<cdr> create a new file node " + info.name);
      return new FileNode(
         this,
         info.name,
         info.isDirectory,
         info.size,
         info.fileEntry,
         null,
         info.type,
         info.modificationTime
      );
   };

   public isContainingNodeOf = (info: FileNode) => {
      if (this.contains.length === 0) {
         return false;
      }
      return this.contains.some((node: FileNode) => {
         return !!node && node.name === info.name && node.isDirectory === info.isDirectory;
      });
   };

   //generate sub nodes in contains
   public loadingPromise: any;
   public noPendingLoading = async () => {
      if (this.loadingPromise) {
         await this.loadingPromise;
      }
   };

   private _loadContains = async () => {
      Logger.debug("<cdr> loading contains for " + this.name);
      if (!this.isDirectory) {
         Logger.error("<cdr> can't load content on a file");
         throw "STATUS_NO_SUCH_FILE";
      }
      const entry = await this.getFileEntry();
      if (!entry || !entry.isDirectory) {
         Logger.error("<cdr> entry not found when load dir content" + this.name);
         throw "STATUS_NO_SUCH_FILE";
      }
      this.contains = [];
      await EntryUtil.eachInDir(entry, this.addEntry.bind(this));
   };

   public loadContains = async () => {
      if (this.loadingPromise) {
         Logger.warning("<cdr> try to reload a loading folder " + this.name);
         return this.loadingPromise;
      }
      try {
         this.loadingPromise = this._loadContains();
         await this.loadingPromise;
      } catch (e) {
         Logger.error(e);
      }
      this.loadingPromise = null;
   };

   public getSubNode = async (name) => {
      if (this.contains.length === 0) {
         await this.loadContains();
      } else if (this.contains[0].fileEntry.isFA) {
         await this.loadContains();
      }
      let result = null;
      await this.noPendingLoading();
      this.contains.some((entry: FileNode) => {
         if (entry.name === name) {
            result = entry;
            return true;
         }
         return false;
      });
      if (!result) {
         throw "STATUS_NO_SUCH_FILE";
      }
      return result;
   };

   public removeNode = (node: FileNode) => {
      const index = this.contains.indexOf(node);
      if (index > -1) {
         this.contains.splice(index, 1);
         Logger.trace("<cdr> remove a node in " + this.name + " of name " + node.name);
      } else {
         Logger.warning("<cdr> can't find the original file Node in removeNode, file conflict detected");
         throw "STATUS_NO_SUCH_FILE";
      }
   };

   public rewinddir = async () => {
      await this.loadContains();
      this.containReadCache = this.contains;
      this.containReadIndex = 0;
   };

   public readdir = () => {
      if (this.contains !== this.containReadCache) {
         Logger.error("<cdr> operation conflict detected");
         return null;
      }
      if (this.containReadIndex >= this.containReadCache.length) {
         return null;
      }
      const result = this.containReadCache[this.containReadIndex];
      this.containReadIndex++;
      return result;
   };

   public active = () => {
      return;
   };

   public disactive = async () => {
      return;
   };

   /**
    * @param  {number}   offset
    * @param  {number}   length Max read length, real lenght must be smaller than or equal to this length
    */
   public pRead = async (offset, length, param) => {
      const entry = await this.getFileEntry();

      let data;
      try {
         data = await this.ioOptimizer.read(entry, offset, length, param.requestId);
      } catch (e) {
         if (String(e).indexOf("permission problems") > -1) {
            return "notReady";
         }
      }
      this.time.lastAccessTime = FSUtil.timeToFileTime(Date.now());
      return {
         targetOffset: offset,
         targetlength: length,
         readData: data,
         length: data.byteLength
      };
   };

   public updateByWrite = () => {
      return new Promise((resolve, reject) => {
         this.time.changeTime = FSUtil.timeToFileTime(Date.now());
         this.time.lastWriteTime = FSUtil.timeToFileTime(Date.now());
         this.getFileEntry()
            .then((entry) => {
               EntryUtil.getMetaData(entry)
                  .then((data: any) => {
                     this.size = data.size;
                     // @ts-ignore
                     resolve();
                  })
                  .catch(reject);
            })
            .catch(reject);
      });
   };

   /**
    * @param  {ArrayBuffer} buffer The data buffer
    * @param  {number} offset      Can be larger than EOF
    * @param  {number} length      The data length
    * @return {object}             Written info
    */
   public pWrite = async (buffer, offset, length) => {
      const entry = await this.getFileEntry();
      const realWriteLength = await this.ioOptimizer.write(entry, buffer, offset, length, this.cacheService);
      await this.updateByWrite();
      return {
         length: realWriteLength,
         padding: [0]
      };
   };

   public truncate = async (length) => {
      if (this.isDirectory) {
         throw "STATUS_NO_SUCH_FILE";
      }
      const truncatedLength = await this.ioOptimizer.truncate(await this.getFileEntry(), length, this.cacheService);
      await this.updateByWrite();
      return {
         length: truncatedLength,
         padding: [0]
      };
   };

   public isEmptyDirectory = () => {
      return !this.contains.length;
   };

   public onNodeRemoved = () => {
      if (this.isDirectory) {
         while (!!this.contains && this.contains.length > 0) {
            Logger.error("<cdr> Agent don't delete the contents before deleting the folder!");
            this.contains[0].onNodeRemoved();
         }
      }
      this.parentFolder.removeNode(this);
   };

   public setDeletePending = (deletePending) => {
      this.isDeletePending = deletePending;
      //use queue for this later
      //flush all IO related.(-r)
      if (deletePending === true) {
         return new Promise((resolve, reject) => {
            let removeFunctionName;
            if (this.isDirectory) {
               removeFunctionName = "removeRecursively";
            } else {
               removeFunctionName = "remove";
            }
            this.getFileEntry().then((entry) => {
               this.getWritableEntry().then((writableEntry: any) => {
                  if (!writableEntry || typeof writableEntry[removeFunctionName] !== "function") {
                     Logger.error("<cdr> fail to get valid file entry to remove for " + this.getPath());
                     reject();
                  }
                  writableEntry[removeFunctionName](
                     () => {
                        Logger.trace("<cdr> successfully removed " + writableEntry.fullPath);
                        try {
                           this.onNodeRemoved();
                        } catch (e) {
                           Logger.debug("<cdr> remove node skip, should be caused by cancel copying");
                        }
                        // @ts-ignore
                        resolve();
                     },
                     (e) => {
                        Logger.error("<cdr> fail to remove " + writableEntry.fullPath + e);
                        reject();
                     }
                  );
                  this.time.changeTime = FSUtil.timeToFileTime(Date.now());
                  this.time.lastWriteTime = FSUtil.timeToFileTime(Date.now());
               });
            });
         });
      } else {
         throw "<cdr> not support setting delete pending flag to false!";
      }
   };

   public clearContainedEntries = () => {
      if (this.isDirectory) {
         for (let i = 0, len = this.contains.length; i < len; i++) {
            this.contains[i].clearContainedEntries();
         }
      }
      this.fileEntry = null;
   };

   public move = async (folderNode, name, replaceIfExists) => {
      if (!name || !folderNode) {
         throw "<cdr> invalid target file path to be moved to";
      }
      if (!(name.length > 0)) {
         throw "<cdr> invalid target file name";
      }
      if (!folderNode.isDirectory) {
         throw "<cdr> invalid target folder";
      }
      if (this.name === name && this.parentFolder === folderNode) {
         Logger.warning("<cdr> no need to move, since it's already there, treat as successed");
         return;
      }
      const entry = await this.getFileEntry();
      const folderEntry = await folderNode.getFileEntry();
      const newEntry = await EntryUtil.move(entry, folderEntry, name, replaceIfExists); //fs implementation
      this.fileEntry = newEntry;
      this.name = name;
      this.parentFolder = folderNode;
      folderNode.addNode(this);
      //io optimizor
      // update entries of contents
      this.clearContainedEntries();
   };

   public getPath = () => {
      if (!this.parentFolder) {
         return this.name;
      }
      return this.parentFolder.getPath() + "\\" + this.name;
   };

   public getWritableEntry = () => {
      return new Promise((resolve, reject) => {
         this.getFileEntry()
            .then((entry) => {
               EntryUtil.getWritableEntry(entry).then(resolve);
            })
            .catch(reject);
      });
   };

   public getFileEntry = async (): Promise<FileEntry> => {
      let fileEntry: FileEntry = null;
      if (!this.parentFolder) {
         if (!this.fileEntry) {
            Logger.error("<cdr> Fatal error: the root node is missing");
            throw "<cdr> fatal error: the root node is missing";
         }
         Logger.trace("Find root file entry");
         fileEntry = this.fileEntry;
      }
      if (this.fileEntry) {
         Logger.trace("<cdr> find cached file entry");
         fileEntry = this.fileEntry;
      } else {
         Logger.trace("<cdr> getting file entry");
         const parentEntry = await this.parentFolder.getFileEntry();
         this.fileEntry = await EntryUtil.firstInDir(parentEntry, (entry, index, array) => {
            const folderPath = FSUtil.getWinFolderPath(entry.fullPath);
            const name = entry.name;
            const entryPath = folderPath !== "\\" ? folderPath + "\\" + name : "\\" + name;
            return this.getPath() === entryPath;
         });
         fileEntry = this.fileEntry;
      }
      return fileEntry;
   };
}
