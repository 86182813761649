/**
 * ********************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * ********************************************************
 *
 * @format
 */

import Logger from "./logger";

function TrueFunc() {
   return true;
}

function FalseFunc() {
   return false;
}

class ClientUtil {
   private lastSystemActiveTime: number;
   private sleepTime: number;
   private sleepEndTime: number;
   private isFA: boolean;
   public isFAwithMainClientHide: boolean;
   public chromeVersion: number;

   public isIE: Function;
   public isOpera: Function;
   public isWebkit: Function;
   public isChromium: Function;
   public isChromeVersionAfter114: Function;
   public isSafari: Function;
   public isBB: Function;
   public isGecko: Function;
   public isFirefox: Function;
   public isIOS: Function;
   public isAndroid: Function;
   public isIEMobile: Function;
   public hasTouchInput: Function;
   public isChromeClient: Function;
   public isChromeOS: Function;
   public isChromeOSVersionAfter114: Function;
   public isWindows: Function;
   public isLinux: Function;
   public isMacOS: Function;
   public isVrDevice: boolean;
   public isPlatformSupportOpus: Function;
   private isTitanHostMode: boolean = false;

   constructor() {
      this.lastSystemActiveTime = 0;
      const ua = navigator.userAgent.toLowerCase();
      this.isIE =
         ua.indexOf("msie") !== -1 || ua.indexOf("trident") !== -1 || ua.indexOf("edge") !== -1 ? TrueFunc : FalseFunc;
      // Since Opera 30, Opera's UA is changed to "OPR".
      this.isOpera = ua.indexOf("opera/") !== -1 || ua.indexOf("opr/") !== -1 ? TrueFunc : FalseFunc;
      this.isWebkit =
         this.isChromium =
         this.isChromeVersionAfter114 =
         this.isPlatformSupportOpus =
         this.isSafari =
         this.isBB =
            FalseFunc;
      // Check for webkit engine.
      if (!this.isIE() && !this.isOpera() && ua.indexOf("applewebkit") !== -1) {
         this.isWebkit = TrueFunc;
         // Webkit engine is used by chrome, safari and blackberry browsers.
         if (ua.indexOf("chrome") !== -1) {
            this.isChromium = TrueFunc;
            const versionStartPos = ua.indexOf("chrome/");
            const versionEndPos = ua.indexOf("safari/") - 1;
            const detailVersion = ua.slice(versionStartPos, versionEndPos);
            const simpleVersionStartPos = 7;
            const simpleVersionEndPos = detailVersion.indexOf(".");
            const simpleVersion = detailVersion.slice(simpleVersionStartPos, simpleVersionEndPos);
            if (Number(simpleVersion) >= 114) {
               /**
                * for web client, only when browser chrome version larger than 114, we could use
                * Opus codec for WebCodec API
                */
               this.isChromeVersionAfter114 = TrueFunc;
               this.isPlatformSupportOpus = TrueFunc;
            }
         } else if (ua.indexOf("bb") !== -1) {
            // Detect if its a BlackBerry browser or higher on OS BB10+
            this.isBB = TrueFunc;
         } else if (ua.indexOf("safari") !== -1) {
            this.isSafari = TrueFunc;
         }
      }
      // See: https://developer.mozilla.org/en/Gecko_user_agent_string_reference
      // Also, Webkit/IE11 say they're 'like Gecko', so we get a false positive here.
      this.isGecko = !this.isWebkit() && !this.isIE() && ua.indexOf("gecko") !== -1 ? TrueFunc : FalseFunc;

      this.isFirefox = ua.indexOf("firefox") !== -1 || ua.indexOf("iceweasel") !== -1 ? TrueFunc : FalseFunc;

      // Detect specific mobile devices. These are *not* guaranteed to also set
      // isLowBandwidth. Some however do when presenting over WiFi, etc.
      this.isIOS =
         ua.indexOf("iphone") !== -1 || ua.indexOf("ipod") !== -1 || ua.indexOf("ipad") !== -1 ? TrueFunc : FalseFunc;

      /* typically also sets isLinux */
      this.isAndroid = ua.indexOf("android") !== -1 ? TrueFunc : FalseFunc;

      // Detect IE mobile versions.
      this.isIEMobile = ua.indexOf("IEMobile") !== -1 ? TrueFunc : FalseFunc;

      // Flag indicating that touch feature exists. (Ex: includes Win8 touch laptops)
      try {
         this.hasTouchInput = "ontouchstart" in window || navigator.maxTouchPoints ? TrueFunc : FalseFunc;
      } catch (e) {
         Logger.warning(e);
         this.hasTouchInput = FalseFunc;
      }

      try {
         // Edge has chrome, but doesn't has chrome.management
         this.isChromeClient = !!chrome && !!chrome.management ? TrueFunc : FalseFunc;
      } catch (e) {
         // Safari/Firefox/IE is throwing error here
         this.isChromeClient = FalseFunc;
      }

      // PC OS detection.
      this.isChromeOS = ua.indexOf("cros") !== -1 ? TrueFunc : FalseFunc;
      if (ua.indexOf("cros") !== -1) {
         const versionStartPos = ua.indexOf("chrome/");
         const versionEndPos = ua.indexOf("safari/") - 1;
         const detailVersion = ua.slice(versionStartPos, versionEndPos);
         const simpleVersionStartPos = 7;
         const simpleVersionEndPos = detailVersion.indexOf(".");
         const simpleVersion = detailVersion.slice(simpleVersionStartPos, simpleVersionEndPos);
         if (Number(simpleVersion) >= 114) {
            /**
             * for chrome client, only when chromeOS version larger than 114, we could use
             * Opus codec for WebCodec API
             */
            this.isChromeOSVersionAfter114 = TrueFunc;
            this.isPlatformSupportOpus = TrueFunc;
         } else {
            /**
             * for chrome client, when chromeOS version not larger than or equal to 114, we could
             * not use Opus codec for WebCodec API bug in google
             */
            this.isChromeOSVersionAfter114 = FalseFunc;
            this.isPlatformSupportOpus = FalseFunc;
         }
      }
      this.isWindows = ua.indexOf("windows") !== -1 ? TrueFunc : FalseFunc;
      this.isLinux = ua.indexOf("linux") !== -1 ? TrueFunc : FalseFunc;
      this.isMacOS =
         ua.indexOf("mac os") !== -1 || ua.indexOf("macos") !== -1 || ua.indexOf("macintosh") > -1
            ? TrueFunc
            : FalseFunc;
   }

   public init = () => {
      this.lastSystemActiveTime = 0;
      const ua = navigator.userAgent.toLowerCase();
      this.isIE =
         ua.indexOf("msie") !== -1 || ua.indexOf("trident") !== -1 || ua.indexOf("edge") !== -1 ? TrueFunc : FalseFunc;
      // Since Opera 30, Opera's UA is changed to "OPR".
      this.isOpera = ua.indexOf("opera/") !== -1 || ua.indexOf("opr/") !== -1 ? TrueFunc : FalseFunc;
      this.isWebkit =
         this.isChromium =
         this.isChromeVersionAfter114 =
         this.isPlatformSupportOpus =
         this.isSafari =
         this.isBB =
            FalseFunc;
      // Check for webkit engine.
      if (!this.isIE() && !this.isOpera() && ua.indexOf("applewebkit") !== -1) {
         this.isWebkit = TrueFunc;
         // Webkit engine is used by chrome, safari and blackberry browsers.
         if (ua.indexOf("chrome") !== -1) {
            this.isChromium = TrueFunc;
            const versionStartPos = ua.indexOf("chrome/");
            const versionEndPos = ua.indexOf("safari/") - 1;
            const detailVersion = ua.slice(versionStartPos, versionEndPos);
            const simpleVersionStartPos = 7;
            const simpleVersionEndPos = detailVersion.indexOf(".");
            const simpleVersion = detailVersion.slice(simpleVersionStartPos, simpleVersionEndPos);
            if (Number(simpleVersion) >= 114) {
               /**
                * for web client, only when browser chrome version larger than 114, we could use
                * Opus codec for WebCodec API
                */
               this.isChromeVersionAfter114 = TrueFunc;
               this.isPlatformSupportOpus = TrueFunc;
            }
         } else if (ua.indexOf("bb") !== -1) {
            // Detect if its a BlackBerry browser or higher on OS BB10+
            this.isBB = TrueFunc;
         } else if (ua.indexOf("safari") !== -1) {
            this.isSafari = TrueFunc;
         }
      }
      // See: https://developer.mozilla.org/en/Gecko_user_agent_string_reference
      // Also, Webkit/IE11 say they're 'like Gecko', so we get a false positive here.
      this.isGecko = !this.isWebkit() && !this.isIE() && ua.indexOf("gecko") !== -1 ? TrueFunc : FalseFunc;

      this.isFirefox = ua.indexOf("firefox") !== -1 || ua.indexOf("iceweasel") !== -1 ? TrueFunc : FalseFunc;

      // Detect specific mobile devices. These are *not* guaranteed to also set
      // isLowBandwidth. Some however do when presenting over WiFi, etc.
      this.isIOS =
         ua.indexOf("iphone") !== -1 || ua.indexOf("ipod") !== -1 || ua.indexOf("ipad") !== -1 ? TrueFunc : FalseFunc;

      /* typically also sets isLinux */
      this.isAndroid = ua.indexOf("android") !== -1 ? TrueFunc : FalseFunc;

      // Detect IE mobile versions.
      this.isIEMobile = ua.indexOf("IEMobile") !== -1 ? TrueFunc : FalseFunc;

      // Flag indicating that touch feature exists. (Ex: includes Win8 touch laptops)
      this.hasTouchInput = "ontouchstart" in window || navigator.maxTouchPoints ? TrueFunc : FalseFunc;

      try {
         // Edge has chrome, but doesn't has chrome.management
         this.isChromeClient = !!chrome && !!chrome.management ? TrueFunc : FalseFunc;
      } catch (e) {
         // Safari/Firefox/IE is throwing error here
         this.isChromeClient = FalseFunc;
      }

      // PC OS detection.
      this.isChromeOS = ua.indexOf("cros") !== -1 ? TrueFunc : FalseFunc;
      if (ua.indexOf("cros") !== -1) {
         const versionStartPos = ua.indexOf("chrome/");
         const versionEndPos = ua.indexOf("safari/") - 1;
         const detailVersion = ua.slice(versionStartPos, versionEndPos);
         const simpleVersionStartPos = 7;
         const simpleVersionEndPos = detailVersion.indexOf(".");
         const simpleVersion = detailVersion.slice(simpleVersionStartPos, simpleVersionEndPos);
         if (Number(simpleVersion) >= 114) {
            /**
             * for chrome client, only when chromeOS version larger than 114, we could use
             * Opus codec for WebCodec API
             */
            this.isChromeOSVersionAfter114 = TrueFunc;
            this.isPlatformSupportOpus = TrueFunc;
         } else {
            /**
             * for chrome client, when chromeOS version not larger than or equal to 114, we could
             * not use Opus codec for WebCodec API bug in google
             */
            this.isChromeOSVersionAfter114 = FalseFunc;
            this.isPlatformSupportOpus = FalseFunc;
         }
      }
      this.isWindows = ua.indexOf("windows") !== -1 ? TrueFunc : FalseFunc;
      this.isLinux = ua.indexOf("linux") !== -1 ? TrueFunc : FalseFunc;
      this.isMacOS =
         ua.indexOf("mac os") !== -1 || ua.indexOf("macos") !== -1 || ua.indexOf("macintosh") > -1
            ? TrueFunc
            : FalseFunc;
   };

   public clipResolution = (resolution: number[]): number[] => {
      let w = Math.floor(resolution[0]),
         h = Math.floor(resolution[1]);

      // We need to round width to even for RDSH, see PR:1172572
      if (w % 2) {
         w = w - 1;
      }

      // We need to round height to even for H.264
      if (h % 2) {
         h = h - 1;
      }

      return [w, h];
   };

   public updateFAStatus = (status) => {
      this.isFA = status === true || status === "true";
   };

   public isFASession = () => {
      return this.isFA;
   };

   public setSystemActiveTime = () => {
      this.lastSystemActiveTime = new Date().getTime();
      setInterval(() => {
         const currentTime = new Date().getTime();
         if (currentTime > this.lastSystemActiveTime + 1000 * 2) {
            // Just wake up
            this.sleepTime = currentTime - this.lastSystemActiveTime;
            this.sleepEndTime = currentTime;
            Logger.debug("Set sleep time to:" + this.sleepTime);
         }
         this.lastSystemActiveTime = currentTime;
      }, 1000 * 1);
   };

   public isSystemSleepTooLongToReconnect = (interval) => {
      const currentTime = new Date().getTime();
      const sleepTime = this.sleepTime ? this.sleepTime : 0;
      const sleepEndTime = this.sleepEndTime ? this.sleepEndTime : currentTime;
      const reconnectTime = sleepTime + currentTime - sleepEndTime;
      Logger.debug("Reconnect and sleep time:" + reconnectTime);
      // 2mins for reconnection time, 2 interval for agent heartbeat check
      if (reconnectTime > interval * 1000 * 2 + 2 * 60 * 1000) {
         Logger.debug("System sleep for a long time");
         return true;
      } else {
         Logger.debug("System doesn't sleep for a long time");
         return false;
      }
   };

   public clearSystemSleepTime = () => {
      this.sleepTime = 0;
   };

   public isSeamlessMode = () => {
      return this.isChromeClient();
   };

   /**
    * This function is to close all the windows including the main window
    */
   public closeAllWindows = () => {
      if (this.isChromeClient()) {
         const windows = chrome.app.window.getAll();
         // this first appwindow is current window, close
         // other windows first
         for (let i = windows.length - 1; i >= 0; i--) {
            Logger.debug("close all windows id:" + windows[i].id);
            windows[i].close();
         }
      }
   };

   /**
    * This function is to close all the windows except the main window
    */
   public closeAllSessionWindows = () => {
      if (this.isChromeClient()) {
         const windows = chrome.app.window.getAll();
         for (let i = windows.length - 1; i >= 0; i--) {
            if (windows[i].id !== "HTMLAccessChromeWindow") {
               windows[i].close();
            }
         }
      }
   };

   /**
    * This function computes the desired resolution for the desktop for all
    * device and desktop browsers.
    * @param  {object} windowObject The window object to use, if undefined the
    *    global window object will be used. The normal use case is for this
    *    to be undefined, but we use the parameter for unit tests where we
    *    do not want to manipulate the actual window object.
    * @return {Array} [width, height]
    */
   public getWindowResolution = (windowObject?: any) => {
      let w, h;

      if (!windowObject) {
         windowObject = window;
      }

      if (this.isIOS() || this.isAndroid() || this.isChromeOS()) {
         /*
            For more information please refer 1572136
            For iOS 9, we need to use document.documentElement.clientWidth/clientHeight
         */
         w = windowObject.document.documentElement.clientWidth;
         h = windowObject.document.documentElement.clientHeight;
      } else {
         /*
          * In desktop mode on a desktop, resize the desktop to fit the browser
          * window, not the size of the whole local screen.
          */
         w = windowObject.innerWidth;
         h = windowObject.innerHeight;
      }
      return [w, h];
   };

   /**
    * This function computes the desired resolution in pixel.
    * @param  {object} windowObject For UT
    * @return {Array} [width, height]
    */
   public getWindowPixels = (windowObject?: any) => {
      const resolution = this.getWindowResolution(windowObject);
      const pixelsInRow = devicePixelRatio * resolution[0];
      // workaround for bug: 3060908
      const pixelsInColumn = devicePixelRatio * Math.floor(resolution[1] / 2) * 2;
      return this.clipResolution([pixelsInRow, pixelsInColumn]);
   };

   /**
    * return null if not find uri parameter
    */
   public findURLParam = (key) => {
      key = key.toLowerCase();
      const searchString = window.location.search.toLowerCase();
      if (!searchString) {
         Logger.debug("no search string");
         return null;
      }
      if (searchString.split("?").length <= 1) {
         Logger.debug("no valid search string:" + searchString);
      }
      const urlPairs = searchString.split("?")[1].split("&");
      if (urlPairs.length <= 0) {
         // redundant check
         Logger.debug("no valid search string:" + searchString);
         return null;
      }
      const matched = urlPairs.find((pair) => {
         return pair.includes(key + "=");
      });
      if (matched && matched.split("=").length === 2) {
         return matched.split("=")[1];
      } else {
         Logger.debug(`no value found for ${key} in URL`);
         return null;
      }
   };

   public blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.onloadend = () => {
            resolve(reader.result);
         };
         reader.readAsDataURL(blob);
      });
   };

   public base64toBlob = (b64Data, contentType) => {
      //This function is to create a Blob from a base64 string
      contentType = contentType || "";
      const sliceSize = 512;
      // The performance can be improved a little by processing the byteCharacters in smaller slices, rather than all at once.
      // 512 bytes seems to be a good slice size.

      if (b64Data.split(",")[0] !== "data:image/png;base64") {
         Logger.debug("the format of base64 data is not correct.", Logger.UNITY);
         return;
      } else {
         b64Data = b64Data.split(",")[1];
      }

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);
         const byteNumbers = new Array(slice.length);
         for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
         }
         const byteArray = new Uint8Array(byteNumbers);
         byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      Logger.debug("Base64 data is successfully transferred to blob", Logger.UNITY);
      return blob;
   };

   public getScreenWidth = () => {
      return this.adjustScreenSizeFor4kMonitor(screen.width, [3840]);
   };

   public getScreenHeight = () => {
      return this.adjustScreenSizeFor4kMonitor(screen.height, [2160, 2560]);
   };

   public adjustScreenSizeFor4kMonitor = (originValue, maxValue: Array<number> = []) => {
      const resolution = originValue * devicePixelRatio;
      const tolerantDPI = Math.ceil(devicePixelRatio);

      for (let i = 0; i < maxValue.length; i++) {
         // normal 4k monitor
         if (resolution === maxValue[i]) {
            return originValue;
         }

         // some 4k monitor may get wrong width/height value (google'bug)
         if (resolution <= maxValue[i] + tolerantDPI + 1e-6 && resolution >= maxValue[i] - tolerantDPI - 1e-6) {
            const expectValue = Math.round(maxValue[i] / devicePixelRatio);
            Logger.error(
               `Screen size error, origin size value=${originValue}, dpi=${devicePixelRatio}, change value to ${expectValue}`,
               Logger.DISPLAY
            );
            return expectValue;
         }
      }

      // not 4k monitor.
      return originValue;
   };

   public showClientForWS1orSDK = (reason: string) => {
      if (typeof window?.chromeClient?.showClient === "function") {
         window.chromeClient.showClient();
         Logger.debug("In ws1 or SDK mode, show client for reason: " + reason);
      }
   };

   public hideClientForWS1orSDK = (reason: string) => {
      if (typeof window?.chromeClient?.hideClient === "function") {
         window.chromeClient.hideClient();
         Logger.debug("In ws1 or SDK mode, hide client for reason: " + reason);
      }
   };

   public isInCurrentMonitor = (x, y, width, height) => {
      const isSame =
         x === screen.availLeft && y === screen.availTop && width === screen.width && height === screen.height;
      if (isSame) {
         return true;
      } else {
         // check if inside monitor (considing menubar, window'size is smaller than screen's size)
         // right-bottom point
         const rightPos = x + width;
         const bottomPos = y + height;
         // current monitor's right-bottom point
         const curMonitorRightPos = screen.availLeft + screen.availWidth;
         const curMonitorBottomPos = screen.availTop + screen.availHeight;
         return (
            // left-top-point
            screen.availLeft >= x &&
            screen.availLeft <= rightPos &&
            screen.availTop >= y &&
            screen.availTop <= bottomPos &&
            // right-bottom-point
            curMonitorRightPos >= x &&
            curMonitorRightPos <= rightPos &&
            curMonitorBottomPos >= y &&
            curMonitorBottomPos <= bottomPos &&
            screen.width <= width &&
            screen.height <= height
         );
      }
   };

   public pushBytes = (number, bytes, isBigEndian) => {
      if (bytes === 0) {
         return;
      } else if (typeof number === "boolean") {
         number = Number(number);
      } else if (typeof number !== "number") {
         throw "can't push a non-number value without string type";
      } else if (number < 0) {
         throw "should not be used with negative number";
      }
      const numberArray = new Uint8Array(bytes);
      let i;
      if (isBigEndian) {
         for (i = 0; i < bytes; i++) {
            numberArray[bytes - 1 - i] = number & 0xff;
            number >>>= 8;
         }
      } else {
         for (i = 0; i < bytes; i++) {
            numberArray[i] = number & 0xff;
            number >>>= 8;
         }
      }
      return numberArray;
   };

   public generateLocalFeatureKey = (host: string, userName: string, featureName: string): string => {
      return host.toLocaleLowerCase() + "-" + userName.toLocaleLowerCase() + "-" + featureName;
   };

   // Never call setTitanClient directly. use ChromeBrokerModeService
   public setTitanClient = (mode: boolean) => {
      this.isTitanHostMode = mode;
   };

   public isTitanClient = (): boolean => {
      if (__CLIENT_TYPE__ === "titan") {
         return true;
      } else if (__CLIENT_TYPE__ === "html5") {
         return false;
      }
      return this.isTitanHostMode;
   };

   public recordChromeClientInfo = () => {
      Logger.info("###################################################");
      Logger.info(
         chrome.runtime.getManifest().description +
            " [" +
            chrome.runtime.getManifest().version +
            "] starts @" +
            Date().toString()
      );
      Logger.info("Browser Version:\t" + navigator.appVersion);
      Logger.info("Browser platform:\t" + navigator.platform);
      Logger.info("Browser language:\t" + navigator.language);
      Logger.info("Browser cookie:\t" + navigator.cookieEnabled);
      Logger.info("Browser onLine:\t" + navigator.onLine);
   };

   public isMultiMonitorMode = (): boolean => {
      let windowList = chrome.app.window.getAll();
      for (let i = 0; i < windowList.length; i++) {
         if (windowList[i].id.indexOf("extended_monitor") > -1) {
            return true;
         }
      }
      return false;
   };
}

export const clientUtil = new ClientUtil();
