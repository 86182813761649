/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { HTML5MMR as MMR } from "./model/html5MMR.e911.models";
import { BusEvent, EventBusService, Logger } from "@html-core";
import { GeolocationService } from "../../../common/service/geolocation-service";
import { Subscription } from "rxjs";
import { Injectable } from "@angular/core";

export namespace Html5MMR {
   @Injectable({
      providedIn: "root"
   })
   export class E911Manager {
      logger = new Logger(Logger.WEBRTC);

      private geolocationService: GeolocationService;
      private eventBusService: EventBusService;
      private locationWatchId: number = null;
      private geoInfoListener: Subscription = null;
      private isStarted = false;

      constructor(geoLocationService: GeolocationService, eventBusService: EventBusService) {
         this.geolocationService = geoLocationService;
         this.eventBusService = eventBusService;
      }

      public init() {
         if (this.geoInfoListener === null) {
            this.geoInfoListener = this.eventBusService.listen(BusEvent.GeolocationInfo.MSG_TYPE).subscribe(() => {
               this.notifyE911InfoUpdate();
            });
         }
      }

      public destroy() {
         this.clear();
         if (this.geoInfoListener !== null) {
            this.geoInfoListener.unsubscribe();
            this.geoInfoListener = null;
         }
      }

      public start() {
         if (this.isStarted) {
            this.logger.warning("E911 Mgr start, e911 Mgr already started, ignore this call.");
            return;
         }
         this.isStarted = true;
         this.geolocationService
            .shouldBeAbleToSubscribe()
            .then(() => {
               if (this.locationWatchId === null) {
                  this.locationWatchId = Date.now() + Math.floor(Math.random() * 30) + 1;
                  this.geolocationService.subscribe(this.locationWatchId);
               }
            })
            .catch((e) => {
               this.logger.error(`Should not be able to subscribe to location service ${JSON.stringify(e)}`);
            });
      }

      public clear() {
         if (this.locationWatchId !== null) {
            this.geolocationService.unsubscribe(this.locationWatchId);
            this.locationWatchId = null;
         }
         this.isStarted = false;
      }

      public notifyE911InfoUpdate(): Promise<MMR.E911Info> {
         return this.getE911Info().then((info) => {
            this.eventBusService.dispatch(new BusEvent.E911InfoMsg(info));
            return info;
         });
      }

      public getE911Info(): Promise<MMR.E911Info> {
         return new Promise((resolve, reject) => {
            const lastKnownPos = this.geolocationService.getLastGeolocationPosition();
            const info = new MMR.E911Info(lastKnownPos);
            resolve(info);
         });
      }
   }
}
