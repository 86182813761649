/**
 * ******************************************************
 * Copyright (C) 2015-2017, 2023-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * @fileoverview audioCapture.ts -- AudioCapture
 * Class to handle audio capturing
 *
 * Support but not recommend to change the default sample rate.
 */

import { Injectable } from "@angular/core";
import { ClipBuffer } from "./ClipBuffer";
import { ReSampler } from "./reSampler";
import { EventBusService } from "@html-core";
import { Logger } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class AudioCapture {
   // compress + translate
   private bufferLen = 128;
   // the 20ms-frame size for 8KHz audio PCM data is 160, and now we use 48kHz as the default sampleRate
   // so the required length on the server side is 960
   private clipTarget = 960;
   // the length should be enough for buffering the data
   private clipBufferLen = 1024;
   // browser API's sample rate, which is fixed
   private machineSampleRate = 48000;

   public statusEnum = {
      Uninited: "Uninited",
      Inited: "Inited",
      Working: "Working"
   };

   private inited: boolean = false;
   private paused: boolean;
   private sampleRate: number;
   private resampler: ReSampler;
   private callback;

   private audioWorkletNode: AudioWorkletNode;
   private mediaCtx: BaseAudioContext;
   private mediaStreamSource: MediaStreamAudioSourceNode;
   private audioContext: AudioContext;
   private losingDevice;
   private deviceUnpluged;
   private localStream;
   private lastValidTime: number;
   private freezeCount: number;
   private syncTimer;
   private clipBuffer: ClipBuffer;

   constructor(private eventBusService: EventBusService) {}

   /**
    * Get what status this audio src is in, currently only UnInited is detected,
    * but keep the API as it for future extension and debugging
    * @return {string} This returns one of the AudioCapture.statusEnum of
    *    'UnInited', 'Inited', 'Working'
    */
   public getStatus = () => {
      let status;
      if (!this.inited) {
         status = this.statusEnum["Uninited"];
      } else {
         if (this.paused) {
            status = this.statusEnum["Inited"];
         } else {
            status = this.statusEnum["Working"];
         }
      }
      return status;
   };

   /**
    * Init capturing audio by binding events to the passed in stream, and for each
    * data segment, call the callback with it. Similar to mAudInput.Open for
    * native clients, use prefs and timer, but here also pass in stream and
    * callback which is done differently for native clients. and the caller should
    * be similar to VCamServer::InitAudioInDev, to reset sync timer if needed. It
    * looks like "open" but the real open didn't happen here, it's actually init
    * the opened source, we do not open it here to avoid annoying hint dialog
    * which is forced by some browsers like firefox.
    *
    * @param  {object} syncTimer The timer object user to sync audio and video
    * @param  {function} callback The function for dealing with each frame
    */
   public init = (audioParam, syncTimer, callback) => {
      let sampleRate = audioParam.sampleRate;

      if (this.inited) {
         Logger.error("this audio capture has already been inited, init fail", Logger.RTAV);
         return;
      }
      if (typeof callback !== "function") {
         Logger.error("this audio capture callback is not a function, init fail", Logger.RTAV);
         return;
      }

      this.syncTimer = syncTimer;

      if (!sampleRate || sampleRate <= 0) {
         Logger.debug("AudioCapture sample rate should be in 0 to 48k", Logger.RTAV);
         sampleRate = 48000;
      }

      if (sampleRate === 48000) {
         //the 20ms frame size for 48KHz audio PCM data is 960
         this.clipTarget = 960;
      } else if (sampleRate === 16000) {
         // the 20ms frame size for 16KHz audio PCM data is 320
         this.clipTarget = 320;
      } else if (sampleRate === 8000) {
         // the 20ms frame size for 8KHz audio PCM data is 160
         this.clipTarget = 160;
      }

      this.sampleRate = sampleRate;
      Logger.debug("AudioCapture sample rate is " + sampleRate, Logger.RTAV);

      Logger.debug("audio capture get sampleRate value is " + sampleRate, Logger.RTAV);

      if (!!this.resampler) {
         delete this.resampler;
      }
      this.resampler = new ReSampler(this.machineSampleRate, sampleRate, this.bufferLen);
      this.clipBuffer = new ClipBuffer(this.clipTarget, this.clipBufferLen);

      this.callback = callback;
      this.paused = true;
      this.inited = true;
   };

   /**
    * Clear the status and release audio capture related resources which make this
    * srouce to start a new srouce for another stream session(start_A and stop_A).
    * But be aware of that, the stream object which will hold the devices will not
    * be release in this level, but in the mediacapture.clear(). and that should
    * only happen when the wmks session don't want RTAV anymore, like when the
    * wmks session is closed.
    */
   public clear = () => {
      if (!this.inited) {
         Logger.error("this audio capture is not inited, clear fail", Logger.RTAV);
         return;
      }
      if (!this.paused) {
         Logger.trace("negative: the audio capture is not stopped yet, stop it before clear it.", Logger.RTAV);
         this.stop();
      }
      this.audioWorkletNode = null;
      this.mediaCtx = null;
      this.mediaStreamSource = null;
      // Edge seems not support close method which is defined in html5 standard
      // for now, so we need the check
      if (!!this.audioContext && typeof this.audioContext.close === "function") {
         this.audioContext.close();
      }
      this.audioContext = null;
      this.clipBuffer.reset();
      this.resampler.reset();
      this.inited = false;
      this.paused = true;
      this.losingDevice = false;
      this.deviceUnpluged = false;
      this.localStream = null;
   };

   /**
    * Start capturing data with inited param
    * @param  {object} stream The stream object obtained by the getUserMedia
    */
   public start = async (stream) => {
      if (!stream) {
         Logger.error("the audio stream is not valid, start fail", Logger.RTAV);
         return;
      }
      if (!this.inited) {
         Logger.error("the audio capture is not being inited, start fail", Logger.RTAV);
         return;
      }
      if (!this.paused) {
         Logger.error("find existing audio capturing session, start fail", Logger.RTAV);
         return;
      }

      this.lastValidTime = -1;
      this.freezeCount = 0;
      this.losingDevice = false;
      this.deviceUnpluged = false;

      try {
         this.audioContext = new window.AudioContext({ sampleRate: this.sampleRate });
         this.localStream = stream;
         this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
         this.mediaCtx = this.mediaStreamSource.context;
      } catch (e) {
         Logger.debug(
            "AudioCapture sample rate " +
               this.sampleRate +
               "is not supported by AudioContext: " +
               e +
               ", using preferred sample rate instead",
            Logger.RTAV
         );
         this.localStream = stream;
         this.audioContext = new window.AudioContext();
         this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
         this.mediaCtx = this.mediaStreamSource.context;
         this.sampleRate = Math.min(this.sampleRate, this.mediaCtx.sampleRate);
      } finally {
         Logger.debug("AudioCapture get machineSampleRate value is " + this.mediaCtx.sampleRate, Logger.RTAV);
      }

      this.machineSampleRate = this.mediaCtx.sampleRate;
      if (this.sampleRate !== this.mediaCtx.sampleRate) {
         Logger.debug(
            "AudioCapture machine sample rate is not " +
               this.sampleRate +
               ", recreating resampler for real sample rate " +
               this.mediaCtx.sampleRate,
            Logger.RTAV
         );
         if (!!this.resampler) {
            delete this.resampler;
         }
         this.resampler = new ReSampler(this.mediaCtx.sampleRate, this.sampleRate, this.bufferLen);
      } else {
         Logger.debug("AudioCapture using machine sample rate " + this.sampleRate, Logger.RTAV);
      }

      try {
         await this.mediaCtx.audioWorklet.addModule("./audioWorklet." + __BUILD_NUMBER__ + ".worker.js");
         this.audioWorkletNode = new AudioWorkletNode(this.mediaCtx, "audio-processor");
         this.audioWorkletNode.port.addEventListener("message", (event) => {
            let clip, capturedData, resampledBuff;
            if (this.paused) {
               return;
            }
            this.updateActiveStatus();
            // Buffer has length specified in _useStream
            capturedData = event.data.buffer;
            if (this.sampleRate !== this.machineSampleRate) {
               resampledBuff = this.resampler.process(capturedData);
               this.clipBuffer.add(resampledBuff, this.syncTimer.getTime());
            } else {
               this.clipBuffer.add(capturedData, this.syncTimer.getTime());
            }
            // Add the samples immediately to the Clip, with correct format(float32
            // to uint16)
            clip = this.clipBuffer.getClip();
            while (clip) {
               // The callback should compress/encode the clip, and send the compressed
               // data in package on VVC.
               this.callback(clip);
               clip = this.clipBuffer.getClip();
            }
         });
         this.audioWorkletNode.port.start();
         this.mediaStreamSource.connect(this.audioWorkletNode);
         this.audioWorkletNode.connect(this.mediaCtx.destination);
         this.paused = false;
      } catch (err) {
         console.error(err);
      }
   };

   /**
    * Stop capturing data, and enter waiting status.
    */
   public stop = () => {
      if (!this.inited) {
         Logger.error("the audio capture is not being inited, stop fail", Logger.RTAV);
         return;
      }
      if (this.paused) {
         Logger.error("find no audio capturing session, stop fail", Logger.RTAV);
         return;
      }
      this.mediaStreamSource.disconnect(this.audioWorkletNode);
      this.audioWorkletNode.disconnect(this.mediaCtx.destination);
      //since its in the single thread, set the pause flag here is early enough
      this.paused = true;
      this.losingDevice = false;
      this.deviceUnpluged = false;
   };

   /**
    * This returns whether the device can provide valid data
    */
   public isActive = () => {
      return !this.losingDevice && !this.deviceUnpluged;
   };

   /**
    * Update the device active status
    */
   public updateActiveStatus = () => {
      let freezeTolerance = this.machineSampleRate / this.bufferLen;

      if (this.localStream.currentTime) {
         if (this.localStream.currentTime === this.lastValidTime) {
            this.freezeCount = this.freezeCount + 1;
            if (!this.losingDevice && this.freezeCount > freezeTolerance) {
               this.losingDevice = true;
               Logger.trace("negative: seems the audio device becomes invalid, please check", Logger.RTAV);
               this.eventBusService.dispatch({ type: "rtavDeviceStatusChanged" });
            }
         } else {
            if (this.localStream.currentTime > 0) {
               this.lastValidTime = this.localStream.currentTime;
            }
            this.freezeCount = 0;
            if (this.losingDevice) {
               this.losingDevice = false;
               Logger.trace(
                  "negative: seems the audio device becomes valid again, which means it might unstable",
                  Logger.RTAV
               );
               this.eventBusService.dispatch({ type: "rtavDeviceStatusChanged" });
            }
         }
      }

      if (!!this.localStream && typeof this.localStream.active === "boolean") {
         if (!this.localStream.active && !this.deviceUnpluged) {
            this.deviceUnpluged = true;
            Logger.trace("negative: seems the audio device is unplugged", Logger.RTAV);
            this.eventBusService.dispatch({ type: "rtavDeviceStatusChanged" });
         }
      }
   };
}
