/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * H5mmrClientChan.js --
 *
 * Interface used to send and receive Html5 MMR RPCs and updates.
 */
import { BusEvent, clientUtil, EventBusService, LocalStorageService, TranslateService } from "@html-core";
import Logger from "../../../../core/libs/logger";
import { ClientSettingModel } from "../../../common/model/client-setting-model";
import { VDP_CONSTS, IVDPServiceListener, VDPChannel, VDPService } from "../../vdpservice";
import { BCR_CONST, HTML5MMR_CONST, JKEYS, WEB_COMMAND } from "./html5MMR-consts";
import { Html5MMRSessionMgr } from "./html5MMR-sessionMgr";
import { HTML5MMR as HTML5MMRSession } from "./model/html5MMR.session.models";
import { Html5MMR as HTML5E911 } from "./html5MMR-e911Mgr";
import { Html5MMRDeviceMgr } from "./htmlMMR-deviceMgr";
import { HTML5MMR } from "./model/html5MMR.rpc.models";
import { GeolocationService } from "../../../common/service/geolocation-service";
import { ConnectedMessageService } from "../../../../chrome-client/base/service/connected-message.service";
import { Optional } from "@angular/core";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { MediastreamNotification } from "../../../common/service/mediastream-notification";
import { GoogleCommonSettings } from "../../../../chrome-client/launcher/server-connect/google-common-settings.service";
import { RootModel } from "../../../common/model/root-model";

export namespace Html5MMRChan {
   export interface Html5MMRChanCB {
      onReady(client: Html5MMRChan.Client): void;
   }

   export class Client {
      static readonly objectNames = [
         HTML5MMR_CONST.HTML5_MMR_MAIN_OBJECT,
         HTML5MMR_CONST.HTML5_MMR_SIDE_CHANNEL_OBJECT,
         HTML5MMR_CONST.HTML5_GPS_REDIR_OBJECT
      ];

      public vdpChannel;
      public vdpMainObject;
      public vdpSideChannelObject;
      public vdpGpsChannelObject;
      public key;

      public ready: boolean;

      private html5MMRChanCB: Html5MMRChanCB = null;
      private sessionMgr: Html5MMRSessionMgr;

      private channelCallbacks: IVDPServiceListener;
      private vdpService: VDPService;
      private deviceMgr: Html5MMRDeviceMgr;
      private logger = new Logger(Logger.HTML5MMR);
      private subscribedToGeoService: boolean = false;
      private eventBusService: EventBusService;
      private geolocationService: GeolocationService = null;
      private e911Mgr: HTML5E911.E911Manager;
      private localStorageService: LocalStorageService;
      private clientSettingModel: ClientSettingModel;
      private googleCommonSettings: GoogleCommonSettings;
      // private rootModel: RootModel;

      private uniqueId: number;
      private isApplicationSession: boolean;
      private sessionKey: string;
      private connectedMessageService: ConnectedMessageService = null;

      constructor(
         sessionModel: HTML5MMRSession.SessionModel,
         vdpService: VDPService,
         isApplicationSession: boolean,
         clientSettingModel: ClientSettingModel,
         localStorageService: LocalStorageService,
         eventBusService: EventBusService,
         geolocationService: GeolocationService,
         e911Service: HTML5E911.E911Manager,
         @Optional()
         connectedMessageService: ConnectedMessageService,
         translate: TranslateService,
         modalDialogService: ModalDialogService,
         mediastreamNotification: MediastreamNotification,
         googleCommonSettings: GoogleCommonSettings,
         rootModel: RootModel
      ) {
         this.vdpService = vdpService;
         this.clientSettingModel = clientSettingModel;
         this.sessionMgr = new Html5MMRSessionMgr(
            sessionModel,
            clientSettingModel,
            localStorageService,
            eventBusService,
            translate,
            modalDialogService,
            mediastreamNotification,
            googleCommonSettings,
            geolocationService,
            rootModel
         );
         this.deviceMgr = Html5MMRDeviceMgr.getInstance();
         this.eventBusService = eventBusService;
         this.geolocationService = geolocationService;
         this.e911Mgr = e911Service;
         this.isApplicationSession = isApplicationSession;
         this.localStorageService = localStorageService;
         this.sessionKey = sessionModel.key;
         this.googleCommonSettings = googleCommonSettings;
         if (connectedMessageService) {
            this.connectedMessageService = connectedMessageService;
            this.connectedMessageService.onMessage(this.sessionKey, "geoPermissionPopup", (msg) => {
               if (msg.type === "geoPermissionAllowed") {
                  Logger.info("geoPermissionAllowed message received, Start sharing geolocation.", Logger.GEOLOCATION);
                  const dummyJson = {
                     action: 0,
                     command: 0,
                     params: [0, 0, 0, "{}"],
                     type: "none"
                  };
                  this.startGpsUpdate(dummyJson as HTML5MMR.MMRWebTextRequest);
               }
            });
         }
         /**
          * Called when the vdp channel becomes ready
          */
         this.ready = false;
         //Get an unique id as nanosecs for this object
         this.uniqueId = new Date().getTime();
         this.channelCallbacks = {
            onReady: (object) => {
               if (object.name === HTML5MMR_CONST.HTML5_MMR_MAIN_OBJECT) {
                  this.vdpMainObject = object;
                  this.logger.debug("Html5MMR main channel is ready for traffic.");
                  if (this.html5MMRChanCB) {
                     this.ready = true;
                     this.html5MMRChanCB.onReady(this);
                  }
               } else if (object.name === HTML5MMR_CONST.HTML5_MMR_SIDE_CHANNEL_OBJECT) {
                  this.vdpSideChannelObject = object;
                  this.logger.debug("Html5MMR side channel is ready for traffic.");
               } else if (object.name === HTML5MMR_CONST.HTML5_GPS_REDIR_OBJECT) {
                  this.vdpGpsChannelObject = object;
                  Logger.debug("Html5MMR GPS channel is ready for traffic.", Logger.GEOLOCATION);
               }
            },

            onDisconnect: (object) => {
               if (object) {
                  if (this.vdpMainObject && this.vdpMainObject.id === object.id) {
                     this.vdpMainObject = null;
                     this.logger.debug("Html5MMR main channel was closed by the remote desktop.");
                  } else if (this.vdpSideChannelObject && this.vdpSideChannelObject.id === object.id) {
                     this.vdpSideChannelObject = null;
                     this.sessionMgr.clear();
                     this.logger.debug("Html5MMR side channel was closed by the remote desktop.");
                  } else if (this.vdpGpsChannelObject && this.vdpGpsChannelObject.id === object.id) {
                     if (this.subscribedToGeoService !== false) {
                        this.geolocationService.unsubscribe(this.uniqueId);
                        this.subscribedToGeoService = false;
                     }
                     this.vdpGpsChannelObject = null;
                     this.logger.debug("Html5MMR GPS channel was closed by the remote desktop.");
                  }
               } else {
                  // If object is not specified, everything disconnected!
                  if (this.subscribedToGeoService !== false) {
                     this.geolocationService.unsubscribe(this.uniqueId);
                     this.subscribedToGeoService = false;
                  }
                  this.vdpMainObject = null;
                  this.vdpSideChannelObject = null;
                  this.sessionMgr.clear();
                  this.e911Mgr.clear();
                  this.localStorageService.removeSession(HTML5MMR_CONST.MMR_RUNNNING_SESSION_FLAG);
                  this.vdpGpsChannelObject = null;
                  this.logger.error("Html5MMR main, Gps and side channels were closed by the remote desktop.");
               }
            },

            onInvoke: (rpc) => {
               this.handleRPCFromServer(rpc);
            }
         };
      }

      /**
       * Initialize the  H5mmrClientchan client.
       *
       * Adds a listener on vdpService for channel created.
       */
      public initialize = () => {
         if (!this.vdpService) {
            this.logger.error("H5MMRChan init failed: no vdpService specified");
            return;
         }
         this.vdpService.addChannelCreatedListener((channel: VDPChannel) => {
            if (channel.name.indexOf(HTML5MMR_CONST.CHANNEL_NAME_PREFIX) === 0) {
               channel.enableSideChannel(true);
               channel.enableSnappy(true);
               const ret = this.vdpService.connectChannel(channel, Client.objectNames);
               if (ret) {
                  this.logger.debug("Successfully accepted Html5MMR main channel");
               } else {
                  this.logger.error("Failed to open Html5MMR main channel");
                  return;
               }

               this.vdpChannel = channel;
               channel.addCallback(this.channelCallbacks);
            }
         });

         this.sessionMgr.init(this);
         this.deviceMgr.init(this);
      };

      public destroy = () => {
         if (this.sessionMgr !== null) {
            this.sessionMgr.clear();
         }
      };

      /**
       * Utility wrapper around vdpChannel invoke for sending RPCs.
       *
       * @param {PACKET_TYPE} command
       *    The command to invoke over rpc.
       * @param {array} params
       *    The packet of data to send with the command.
       */
      public sendRPC = (
         command: number,
         params: any,
         _onDone = this._onDone,
         _onAbort = this._onAbort,
         channelObject = this.vdpMainObject
      ) => {
         if (!this.vdpChannel) {
            this.logger.error("RPC send failed: vdpChannel not initialized.");
            return;
         }

         if (!channelObject) {
            this.logger.error("RPC send failed for message id=" + command + ", as channelObject is not initialized.");
            return;
         }

         let needCompression = false;
         if (params instanceof Array) {
            const cmd = params[2];
            // Refer to: https://opengrok.eng.vmware.com/source/xref/main.perforce.1666/bora/apps/rde/html5mmr/client/mainChannel.cpp#894
            if (
               cmd === WEB_COMMAND.GET_STATS ||
               cmd === WEB_COMMAND.GET_STATS_2 ||
               cmd === WEB_COMMAND.EVENT_ONRECEIVERS
            ) {
               needCompression = true;
            }
         }

         return !!this.vdpChannel.invoke({
            object: channelObject,
            command: command,
            type: VDP_CONSTS.RPC_TYPE.REQUEST,
            params: params || [],
            toCompress: needCompression,
            onDone: _onDone,
            onAbort: _onAbort
         });
      };

      private _onDone = () => {
         this.logger.trace("send rpc done.");
      };

      private _onAbort = () => {
         this.logger.error("send rpc aborted.");
      };

      public addHtml5MMRClientchanObserver(cb: Html5MMRChanCB) {
         this.html5MMRChanCB = cb;
      }

      public removeHtml5MMRObserver() {
         this.html5MMRChanCB = null;
      }

      /**
       * Handle WEBBINARY event.
       *
       * @param {HTML5MMR.RPCRequest} rpc
       *    The incoming RPC request object.
       */
      private handleHTML5MMRDataBinary = (commandRequest: HTML5MMR.MMRWebBinaryRequest) => {
         if (!commandRequest.isValid) {
            this.logger.error("H5MMRChan received incorrect rpc text info:" + commandRequest.JSONString);
            return;
         }
         const webCommnad = commandRequest.webCommand;
         this.logger.debug("WEBBINARY command received: " + webCommnad);
         switch (webCommnad) {
            case WEB_COMMAND.DEFAULT:
               this.sessionMgr.handleDefaultBinary(commandRequest);
               break;
            default:
               this.logger.error("H5MMRChan received unexpected WEBBINARY web command: " + webCommnad);
         }
      };

      /**
       * Handle UPDATEENV event.
       *
       * @param  {HTML5MMR.MMRUpdateEnvRequest} commandRequest
       */
      private handleHTML5MMRUpdateEnv = (commandRequest: HTML5MMR.MMRUpdateEnvRequest) => {
         if (!commandRequest.isValid) {
            this.logger.error("H5MMRChan received incorrect update env request:" + commandRequest.JSONString);
            return;
         }
         this.logger.debug("UPDATEENV command received: " + commandRequest.envFlag);
         this.sessionMgr.handleEnvUpdate(commandRequest);
      };

      /**
       * Handle UPDATEOVERLAY event.
       *
       * @param  {HTML5MMR.MMRUpdateOverlayRequest} commandRequest
       */
      private handleHTML5MMRUpdateOverlay = (commandRequest: HTML5MMR.MMRUpdateOverlayRequest) => {
         if (!commandRequest.isValid) {
            this.logger.error("H5MMRChan received incorrect update overlay request:" + commandRequest.JSONString);
            return;
         }
         this.logger.debug("UPDATEOVERLAY command received: " + commandRequest.paramsJSONString);
         this.sessionMgr.handleOverlayUpdate(commandRequest);
      };

      /**
       * Handle FeaturesSync request from server.
       *
       * @param {string} featuresString
       *    The JSON format feature string from server side
       */
      private handleFeaturesSync = (commandRequest: HTML5MMR.MMRWebTextRequest) => {
         this.logger.info("H5MMRChan received features sync from server:" + commandRequest.webTextPayloadString);

         // TODO: Check root and features node
         const root = commandRequest.webTextPayload;
         const features = root.features;
         const resp = {};

         resp[JKEYS.FEATURES] = {};
         const resFeature = resp[JKEYS.FEATURES];
         for (const key in features) {
            const featureObj = features[key];
            resFeature[key] = {};

            if (key === JKEYS.BROWSER_REDIRECT) {
               const resultBcrEnabled = this.isBrowserRedirEnabled(
                  featureObj[JKEYS.MIN_CLIENT_VERSION],
                  featureObj[JKEYS.STATUS]
               );
               resFeature[key][JKEYS.STATUS] = resultBcrEnabled;
               resFeature[key][JKEYS.VERSION] = HTML5MMR_CONST.BROWSERREDIR_VERSION;
               if (resultBcrEnabled === HTML5MMR_CONST.ENABLED) {
                  this.sessionMgr.setWhitelist(
                     root[key][BCR_CONST.WHITELIST],
                     root[key][BCR_CONST.NAVWHITELIST],
                     root[key][BCR_CONST.ENHWHITELIST]
                  );
               }
            } else if (key === JKEYS.HTML5MMR) {
               resFeature[key][JKEYS.STATUS] = this.isHTML5MMREnabled(
                  featureObj[JKEYS.MIN_CLIENT_VERSION],
                  featureObj[JKEYS.STATUS]
               );
               resFeature[key][JKEYS.VERSION] = HTML5MMR_CONST.HTML5MMR_VERSION;
            } else if (key === JKEYS.WEBRTC_REDIRECT) {
               resFeature[key][JKEYS.STATUS] = this.isWebRTCRedirEnabled(
                  featureObj[JKEYS.MIN_CLIENT_VERSION],
                  featureObj[JKEYS.STATUS],
                  featureObj[JKEYS.FORCED]
               );
               resFeature[key][JKEYS.VERSION] = HTML5MMR_CONST.WEBRTCREDIR_VERSION;
            } else {
               resFeature[key][JKEYS.STATUS] = HTML5MMR_CONST.UNAVAILABLE;
               resFeature[key][JKEYS.VERSION] = JKEYS.VERSION;
            }
         }
         resp[JKEYS.VERSION] = HTML5MMR_CONST.FEATURES_SYNC_MSG_VERSION;

         this.logger.info("H5MMRChan sending out features sync to server:" + JSON.stringify(resp));

         return this.sendRPC(HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT, [
            0,
            0,
            WEB_COMMAND.FEATURES_SYNC_RESULT,
            JSON.stringify(resp)
         ]);
      };

      /**
       * Handle HTML5MMR_CMD_EXCHANGE_VERSIONINFO event.
       *
       * @param  {HTML5MMR.MMRUpdateOverlayRequest} commandRequest
       *
       * Verify the packet
       * It's a 'POST' message, overwrite the return code and returnParams in the packet.
       * Server will send a HTML5MMR_GPS_START_UPDATES after receiveing this packet.
       */
      private handleHTML5MMRVersionInfo = (
         commandRequest: any //OUT
      ) => {
         const inputRequest: HTML5MMR.MMRVersionInfoRequest = new HTML5MMR.MMRVersionInfoRequest(commandRequest);
         if (!inputRequest.isValid) {
            this.logger.info("Invalid packet received for HTML5MMR_CMD_EXCHANGE_VERSIONINFO");
            return;
         }

         this.logger.info(
            "HTML5MMR_CMD_EXCHANGE_VERSIONINFO command received" +
               " majorVersion=" +
               inputRequest.majorVersion +
               " minorVersion=" +
               inputRequest.minorVersion +
               " capability=" +
               inputRequest.capability
         );

         commandRequest.returnCode = HTML5MMR_CONST.GPSREDIR_CLIENT_RETURNCODE;
         commandRequest.returnParams = [
            HTML5MMR_CONST.GPSREDIR_CLIENT_VERSION,
            HTML5MMR_CONST.GPSREDIR_CLIENT_MINOR_VERSION,
            HTML5MMR_CONST.GPSREDIR_CLIENT_CAPABILITY
         ];
      };

      public processGpsUpdate = (commandRequest: HTML5MMR.MMRWebTextRequest) => {
         Logger.info("processGpsUpdate get called.", Logger.GEOLOCATION);
         if (this.isApplicationSession && clientUtil.isChromeClient()) {
            this.geolocationService
               .showGeoPromptForRemoteAppInChromeBook(this.sessionKey)
               .then(() => {
                  //When there is not need to show geo prompt & geo sharing is enabled, start gpsupdate
                  Logger.info("Start GPS update for remote app.", Logger.GEOLOCATION);
                  this.startGpsUpdate(commandRequest);
               })
               .catch((err) => {
                  this.logger.error(err);
               });
         } else {
            this.geolocationService
               .maybeShowPermissionPrompt()
               .then(() => {
                  this.startGpsUpdate(commandRequest);
               })
               .catch((err) => {
                  this.logger.error(err);
               });
         }
      };

      /**
       * start watching Geo Location
       *
       * @param  {HTML5MMR.MMRUpdateOverlayRequest} commandRequest
       *
       * Subscribe to geolocationService.subscribe event
       * which will call sendGPSInfo with updated geo position.
       *
       * Max there is only one instance of watchposition API, and all subscribers will
       * receive sendGPSInfo callback event, from the Observable geolocationService.
       * Corner Case : If client1 subscibed to watchposition API it will get trigerred
       * and say client2 subscribes after some time, it will not get those events
       * So keep lastposition shared by watchposition API in buffer.
       */
      public startGpsUpdate = (commandRequest: HTML5MMR.MMRWebTextRequest) => {
         const root = commandRequest.webTextPayload;
         Logger.info("Input json for startGpsUpdate is : " + JSON.stringify(root), Logger.GEOLOCATION);

         //If location available from prev call, share it to Agent
         if (this.geolocationService.getLastGeolocationPosition() !== null) {
            this.sendGPSInfo(this.geolocationService.getLastGeolocationPosition());
         }
         if (this.subscribedToGeoService === false) {
            this.geolocationService.subscribe(this.uniqueId);
            this.subscribedToGeoService = true;
            this.eventBusService.listen("GeolocationInfo").subscribe((msg) => {
               this.sendGPSInfo(msg.data);
            });
         }
      };

      /**
       * Callback of the API watchPosition
       * Send GPS location to server by triggering HTML5MMR_CMD_SEND_WEBTEXT message
       *
       * @param {GeolocationPosition} geoPosition
       *
       */
      public sendGPSInfo = (geoPosition: GeolocationPosition) => {
         if (
            geoPosition.coords === undefined ||
            geoPosition.coords.latitude === undefined ||
            geoPosition.coords.longitude === undefined
         ) {
            //If unable to access latitude or longitude than other params are not of much use.
            Logger.error("watchPosition Api failed to get the position.", Logger.GEOLOCATION);
            return;
         }

         const resp = {};
         resp["latitude"] = geoPosition.coords.latitude;
         resp["longitude"] = geoPosition.coords.longitude;

         if (geoPosition.coords.accuracy != undefined) {
            resp["accuracy"] = geoPosition.coords.accuracy;
         }
         if (geoPosition.coords.altitude != undefined) {
            resp["altitude"] = geoPosition.coords.altitude;
         }
         if (geoPosition.coords.altitudeAccuracy != undefined) {
            resp["altitudeAccuracy"] = geoPosition.coords.altitudeAccuracy;
         }
         Logger.info(
            "Geo position " + JSON.stringify(resp) + " is sent to Agent " + this.uniqueId + " .",
            Logger.GEOLOCATION
         );
         this.sendRPC(
            HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT,
            [0, 0, WEB_COMMAND.HTML5MMR_GPS_SEND_GEO_INFO, JSON.stringify(resp)],
            this._onDone,
            this._onAbort,
            this.vdpGpsChannelObject
         );
      };

      /**
       * Handle HTML5MMR_GPS_STOP_UPDATES event.
       * Note : Not been used currently, reserved for future use
       * @param  {HTML5MMR.MMRWebTextRequest} commandRequest
       *
       * Stop watching GeoLocation.
       */
      private stopGpsUpdate = (commandRequest: HTML5MMR.MMRWebTextRequest) => {
         Logger.info(
            "Input param for stopGpsUpdate is " + commandRequest.webTextPayload.paramsJSONString,
            Logger.GEOLOCATION
         );
      };

      /**
       * Get the latest geolocation info from geolocation service
       *
       * @return  {GeolocationPosition}
       */
      public getLastGeolocationInfo = () => {
         return this.geolocationService.getLastGeolocationPosition();
      };

      /**
       * Handle active wmks session changed event
       *
       * @param  {wmksSessionKey} string
       */
      public OnActiveSessionChanged = (wmksSessionKey: string) => {
         this.sessionMgr.OnActiveSessionChanged(wmksSessionKey);
      };

      /**
       * Check if browser redirect is enabled.
       *
       * @param {string} minVersion
       * @param {string} status
       * @returns {string} "enabled"/"disabled"
       */
      private isBrowserRedirEnabled = (minVersion, status): string => {
         const result =
            status === HTML5MMR_CONST.ENABLED &&
            !this.isApplicationSession &&
            clientUtil.isChromeClient() &&
            this.clientSettingModel.getBooleanItem("enableBCR") &&
            this.googleCommonSettings.isBrowserRedirEnabled()
               ? HTML5MMR_CONST.ENABLED
               : HTML5MMR_CONST.DISABLED;
         Logger.info(`BCR is ${result}`, Logger.BCR);
         return result;
      };

      /**
       * Check if HTML5MMR is enabled.
       *
       * @param {string} minVersion
       * @param {string} status
       * @returns {string} "enabled"/"disabled"
       */
      private isHTML5MMREnabled = (minVersion, status) => {
         // TODO: check minVersion and status to dicide return value
         return HTML5MMR_CONST.DISABLED;
      };

      /**
       * Check if WebRTC redirect is enabled.
       *
       * @param {string} minVersion
       * @param {string} status
       * @param {string} forced
       * @returns {string} "enabled"/"disabled"
       */
      private isWebRTCRedirEnabled = (minVersion, status, forced) => {
         // Agent side does not support or local env does not support
         if (
            status !== HTML5MMR_CONST.ENABLED ||
            this.isApplicationSession ||
            (!clientUtil.isChromeClient() && !clientUtil.isChromium())
         ) {
            return HTML5MMR_CONST.DISABLED;
         }

         // Agent side force enable WebRTC optimization
         if (forced === HTML5MMR_CONST.TRUE) {
            return HTML5MMR_CONST.ENABLED;
         }

         // Decided by Chrome admin policy and client side settings
         return this.clientSettingModel.getBooleanItem("enableWebRTCRedirection")
            ? HTML5MMR_CONST.ENABLED
            : HTML5MMR_CONST.DISABLED;
      };

      /**
       * Handle WEBTEXT Request
       *
       * @param  {HTML5MMR.RPCRequest} commandRequest given PRC request object
       */
      private handleHTML5MMRDataText = (commandRequest: HTML5MMR.MMRWebTextRequest) => {
         if (!commandRequest.isValid) {
            this.logger.error("H5MMRChan received incorrect web command:" + commandRequest.JSONString);
            return;
         }
         const webCommand: number = commandRequest.webCommand;
         this.logger.debug("WEBTEXT command received: " + webCommand);
         switch (webCommand) {
            case WEB_COMMAND.FEATURES_SYNC:
               this.handleFeaturesSync(commandRequest);
               break;
            case WEB_COMMAND.CREATEBRWREDIR_INSTANCE:
               this.handleCreateBrowserRedirInstance(commandRequest);
               break;
            case WEB_COMMAND.CREATEWEBRTC_INSTANCE:
               this.handleCreateInstance(commandRequest);
               break;
            case WEB_COMMAND.DESTROYINSTANCE:
               this.sessionMgr.destroyWebRTCInstance(commandRequest);
               break;
            case WEB_COMMAND.ENUM_DEVICE:
               this.deviceMgr.handleEnumDevice(commandRequest);
               break;
            case WEB_COMMAND.SET_SINKID:
               this.sessionMgr.setSinkId(commandRequest);
               break;
            case WEB_COMMAND.HTML5MMR_GPS_START_UPDATES:
               this.processGpsUpdate(commandRequest);
               break;
            case WEB_COMMAND.HTML5MMR_GPS_STOP_UPDATES:
               this.stopGpsUpdate(commandRequest);
               break;
            default:
               this.sessionMgr.handleWebText(commandRequest);
               break;
         }
      };

      /**
       * Handle DISCONNECT Request
       *
       * @param  {HTML5MMR.MMRDisconnectRequest} commandRequest given PRC request object
       */
      private handleDisconnect = (commandRequest: HTML5MMR.MMRDisconnectRequest) => {
         this.logger.info("H5MMRChan received disconnect command." + commandRequest.JSONString);
         this.sessionMgr.destroyRedirInstance(commandRequest.instanceId);
      };

      /**
       * Handle CREATE Instance Request
       * This function is called whenever new instance of a tab is created.
       * Create a unique partition id to be used to have self contained partition
       * cookies within different browsers and VDIs.
       *
       * @param  {HTML5MMR.MMRWebTextRequest} commandRequest
       */
      private handleCreateBrowserRedirInstance = (commandRequest: HTML5MMR.MMRWebTextRequest) => {
         this.logger.debug("H5MMRChan received handleCreateBrowserRedirInstance command.");
         let partitionId = "persist:";
         const browser = commandRequest.webTextPayload[JKEYS.BROWSER];
         partitionId += !browser || browser === "" ? "Chrome" : browser;
         partitionId += `-${this.uniqueId}`;
         this.sessionMgr.createBrowserRedirInstance(commandRequest, partitionId);
      };

      /**
       * Handle CREATEBRWREDIR_INSTANCE request
       * This function is called whenever new instance of a browser or tab is created.
       *
       * @param  {HTML5MMR.MMRWebTextRequest} commandRequest
       */
      private handleCreateInstance = (commandRequest: HTML5MMR.MMRWebTextRequest) => {
         //If webRTC is disabled from Client side, do not call e911Mgr.
         if (this.sessionMgr.createWebRTCInstance(commandRequest, this.isApplicationSession)) {
            const webRTCRedirGPO = commandRequest.webTextPayload.webRTCRedirGPO;
            const supportE911 = webRTCRedirGPO.hasOwnProperty(JKEYS.SUPPORT_E911)
               ? webRTCRedirGPO[JKEYS.SUPPORT_E911] == "1"
               : true;
            if (supportE911) {
               this.e911Mgr.start();
            } else {
               Logger.info("supportE911 turned off from GPO policy", Logger.WEBRTC);
            }
            this.localStorageService.setSession(HTML5MMR_CONST.MMR_RUNNNING_SESSION_FLAG, true);
         }
      };

      /**
       * Handle incoming RPCs and redirect them based on command.
       *
       * @param  {any} rpc The incoming RPC object
       */
      private handleRPCFromServer = (rpc: any) => {
         const cmdType: HTML5MMR.RequestType = HTML5MMR.MMRRequest.kind(rpc);
         switch (cmdType) {
            case HTML5MMR.RequestType.WEBTEXT:
               this.handleHTML5MMRDataText(new HTML5MMR.MMRWebTextRequest(rpc));
               break;
            case HTML5MMR.RequestType.WEBBINARY:
               this.handleHTML5MMRDataBinary(new HTML5MMR.MMRWebBinaryRequest(rpc));
               break;
            case HTML5MMR.RequestType.UPDATEENV:
               this.handleHTML5MMRUpdateEnv(new HTML5MMR.MMRUpdateEnvRequest(rpc));
               break;
            case HTML5MMR.RequestType.UPDATEOVERLAY:
               this.handleHTML5MMRUpdateOverlay(new HTML5MMR.MMRUpdateOverlayRequest(rpc));
               break;
            case HTML5MMR.RequestType.VERSIONINFO:
               this.handleHTML5MMRVersionInfo(rpc);
               break;
            case HTML5MMR.RequestType.DISCONNECT:
               this.handleDisconnect(new HTML5MMR.MMRDisconnectRequest(rpc));
               break;
            default:
               this.logger.error(
                  "H5MMRChan received unexpected command: " + rpc.command + " req: " + JSON.stringify(rpc)
               );
               break;
         }
      };
   }
}
