/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "../../../core/libs";
import Logger from "../../../core/libs/logger";
import { UsbProtocol } from "./usb-protocol";
import { UsbStringStore } from "./usb-string";
import { util } from "./usb-util";
import { HorizonUsbDevice, RawImpl, HorizonUsb } from "./usblib";
import { PathosisDevice } from "./usblib/pathosis/pathosis-device";
import { PathosisDeviceFilter } from "./usblib/pathosis/pathosis-device-filter";
import { SplitDevice } from "./usblib/splitDevice/split-device";
import { SplitDeviceFilter } from "./usblib/splitDevice/split-device-filter";

export interface UsbEventHandler {
   onSelectConfiguration();
   onClaimError(usb: RemoteUsbDevice);
}

export class RemoteUsbDevice {
   private static DEVICE_INDEX: number = 0;
   private logger: Logger = null;
   private deviceHandle: number = 0;

   private rawDeviceDesc: ArrayBuffer = null;
   private rawConfigurationDesc = {};
   private bcdDeviceReleaseNumber: number = 0;
   private pathosis: PathosisDevice = null;
   private splitDevice: SplitDevice = null;
   private excludedInterfaces = new Array<number>();

   public wmksKey: string = "";
   public localDevice: HorizonUsbDevice = null;
   public status = UsbProtocol.REDIRSTATUS.INIT;
   public currentConfig: USBConfiguration = null;
   public deviceClaimed: boolean = false;
   public usbEventHandler: UsbEventHandler = null;

   public usbStringStore: UsbStringStore = null;

   constructor(device: RawImpl, wmksKey: string, splitDeviceInfo: Array<number>) {
      this.deviceHandle = RemoteUsbDevice.DEVICE_INDEX++;
      this.wmksKey = wmksKey;
      this.usbEventHandler = null;
      this.localDevice = HorizonUsb.createHorizonUsbDevice(device);
      this.usbStringStore = new UsbStringStore();
      this.logger = new Logger(Logger.USB, this.localDevice.deviceId.toString());
      this.pathosis = PathosisDeviceFilter.check(this.localDevice.vendorId, this.localDevice.productId, this);
      if (clientUtil.isChromeClient()) {
         this.excludedInterfaces = splitDeviceInfo;
         this.splitDevice = SplitDeviceFilter.check(splitDeviceInfo, this);
      }
   }

   /*
    *---------------------------------------------------------------------------
    *
    * initialize --
    *
    * Open a usb device and try to get the device and configuration descriptors.
    *
    *
    * Results:
    *      True for success, false for others.
    *
    *
    *---------------------------------------------------------------------------
    */
   public initialize = async (): Promise<boolean> => {
      try {
         await this.localDevice.open();
      } catch (e) {
         this.logger.error(" failed to open device with " + e);
         this.status = UsbProtocol.REDIRSTATUS.FAILED;
         return false;
      }
      this.logger.info("opened successfully");

      try {
         this.currentConfig = await this.localDevice.getConfiguration();
         if (this.splitDevice) {
            this.currentConfig = this.splitDevice.reWriteInterfaceConfiguration(this.currentConfig);
         }
      } catch (e) {
         this.logger.error("failed to getConfiguration " + e);
         this.status = UsbProtocol.REDIRSTATUS.FAILED;
         return false;
      }

      this.logger.info(
         "current Configuration #" +
            this.currentConfig.configurationValue +
            " - " +
            this.currentConfig.configurationName
      );

      const ret: boolean = await this._getRawDesc();
      if (!ret) {
         this.status = UsbProtocol.REDIRSTATUS.FAILED;
         this.logger.error("failed to _getRawDesc");
         return false;
      }
      return true;
   };

   /**
    * UsbDevice.LocalDevice.getRawDeviceDescriptor
    *
    *       Get the raw device descriptor from USB Device.
    *       ref: http://esd.cs.ucr.edu/webres/usb11.pdf
    *            $9.3 USB Device Requests
    *
    * Results:
    *       Returns true if it is composite else false.
    *       ref: http://esd.cs.ucr.edu/webres/usb11.pdf
    *            $Table 9-7. Standard Device Descriptor
    */
   public getRawDeviceDescriptor = (): ArrayBuffer => {
      if (this.pathosis && this.pathosis.getDeviceDescriptor()) {
         return this.pathosis.getDeviceDescriptor();
      }
      return this.rawDeviceDesc;
   };

   public getRawConfigurationDescriptors = () => {
      return this.rawConfigurationDesc;
   };

   public getCurrentConfigurationValue = () => {
      return this.currentConfig.configurationValue;
   };

   public releaseDevice = async () => {
      this.logger.info("release device");
      if (this.deviceClaimed) {
         for (const inter of this.currentConfig.interfaces) {
            if (this.splitDevice && this.splitDevice.excludedInterfaces.indexOf(inter.interfaceNumber) > -1) {
               this.logger.info(
                  "Don't need to release interface" +
                     inter.interfaceNumber +
                     "as it wasn't claimed since it's excluded interface"
               );
               continue;
            }
            try {
               await this.localDevice.releaseInterface(inter.interfaceNumber);
               this.logger.info(" release interface" + inter.interfaceNumber + "successfully.");
            } catch (e) {
               this.logger.error("failed to release interface#" + inter.interfaceNumber + e);
            }
         }
      }
      this.deviceClaimed = false;
   };

   /*
    *---------------------------------------------------------------------------
    *
    * claimDevice --
    *
    * Claims an interface on a USB device. Before data can be transferred to an
    * interface or associated endpoints the interface must be claimed.
    *
    * Make sure to call this function after selectConfiguration.
    *
    * Results:
    *      Promise<boolean>.
    *
    * Side effects:
    * This function will failure if a device can't be redirection.
    *
    *---------------------------------------------------------------------------
    */

   public claimDevice = async (): Promise<boolean> => {
      if (this.deviceClaimed) {
         this.logger.info("already claimed");
         return true;
      } else {
         this.logger.info("claiming device");
      }

      /*
       *    Generally, we should redirect the USB device with current
       *    configuration. (default configuration)
       *
       *    But the native client always redirect the first configuration to
       *    to remote agent even if it is a device with multiple configurations.
       *
       *    So, do we need to follow native client ?
       *    I would like to redirect USB device with the default configuration.
       */
      const claimResult = new Map<number, boolean>();
      let claimed: boolean = true;
      for (const inter of this.currentConfig.interfaces) {
         if (this.excludedInterfaces.indexOf(inter.interfaceNumber) > -1) {
            this.logger.info(
               "skip claim interface#" + inter.interfaceNumber + " is an excldued interface in split rule."
            );
            continue;
         }
         /*
          * chrome browser - w3c usb implementation workaround.
          * https://wicg.github.io/webusb/
          * The alternate attribute SHALL be set to the USBAlternateInterface that
          * is currently selected for this interface, which by default SHALL
          * be the one with bAlternateSetting equal to 0.
          *
          * But this value is always zero in chrome browser.
          * (before/after select configuration)
          */
         let alternate: USBAlternateInterface = inter.alternate;
         if (alternate === null || inter.alternate.alternateSetting !== 0) {
            for (const alter of inter.alternates) {
               if (alter.alternateSetting === 0) {
                  alternate = alter;
                  break;
               }
            }
         }
         if (alternate === null || alternate.alternateSetting !== 0) {
            this.logger.info("skip claim interface#" + inter.interfaceNumber + " since alternateSetting = 0");
            continue;
         }
         if (alternate.endpoints.length === 0) {
            this.logger.info("skip claim interface#" + inter.interfaceNumber + " since endpoints = 0");
            continue;
         }

         try {
            await this.localDevice.claimInterface(inter.interfaceNumber);
            this.logger.info("claim interface #" + inter.interfaceNumber + "successfully");
            claimResult[inter.interfaceNumber] = true;
         } catch (e) {
            this.logger.error("claim interface #" + inter.interfaceNumber + "failed" + e);
            claimResult[inter.interfaceNumber] = false;
            claimed = false;
         }
      }

      if (claimed) {
         this.logger.info("all the active interface(s) claimed successfully");
      } else {
         //release claimed device when failed
         for (const [key, value] of claimResult) {
            if (value === true) {
               try {
                  await this.localDevice.releaseInterface(key);
                  this.logger.info("release interface #" + key);
               } catch (e) {
                  this.logger.info("failed to release interface #" + e);
               }
            }
         }
         return false;
      }
      this.deviceClaimed = true;
      return true;
   };

   public handleUrbMessage = async (vUrb, packet, callback) => {
      const vid: string = "<" + vUrb.itemId + "> ";

      if (this.pathosis) {
         if (this.pathosis.handleUrbMessage(vUrb, packet, callback)) {
            this.logger.debug(vid + " pathosis.handleUrbMessage" + JSON.stringify(vUrb));
            return;
         }
      }

      if (this.splitDevice) {
         if (await this.splitDevice.handleUrbMessage(vUrb, packet, callback, this.localDevice)) {
            this.logger.debug(vid + " splitDevice.handleUrbMessage" + JSON.stringify(vUrb));
            return;
         }
      }

      if (vUrb.itemType === UsbProtocol.VHUBITEM.Urb) {
         switch (vUrb.UrbHeader.function) {
            case UsbProtocol.URB_FUNCTION.BULK_OR_INTERRUPT_TRANSFER:
               return this._handleBulkOrInterruptTransfer(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.CONTROL_TRANSFER:
               return this._handleControlTransfer(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_DEVICE:
            case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_INTERFACE:
               return this._handleGetDescriptor(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.SELECT_CONFIGURATION:
               return this._handleSelectConfiguration(vUrb, packet, callback);
            //case UsbProtocol.URB_FUNCTION.SELECT_INTERFACE:
            //break;
            case UsbProtocol.URB_FUNCTION.CLASS_INTERFACE:
            case UsbProtocol.URB_FUNCTION.VENDOR_DEVICE:
               return this._handleCntlVendorClassTransfer(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_DEVICE:
               return this._handleGetStatusFromDevice(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.GET_MS_FEATURE_DESCRIPTOR:
               return this._handleGetMsFeatureDescriptor(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.ABORT_PIPE:
            case UsbProtocol.URB_FUNCTION.TAKE_FRAME_LENGTH_CONTROL:
            case UsbProtocol.URB_FUNCTION.RELEASE_FRAME_LENGTH_CONTROL:
            case UsbProtocol.URB_FUNCTION.GET_FRAME_LENGTH:
            case UsbProtocol.URB_FUNCTION.SET_FRAME_LENGTH:
               return this._handlePipeRequest(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.SYNC_RESET_PIPE_AND_CLEAR_STALL:
               return this._handleResetPipe(vUrb, packet, callback);
            default:
               this.logger.error(vid + +"function type (" + vUrb.UrbHeader.function + ") not implemented");
               break;
         }
      } else if (vUrb.itemType === UsbProtocol.VHUBITEM.Ioctl) {
         return this._handleIoctl(vUrb, packet, callback);
      } else if (vUrb.itemType === UsbProtocol.VHUBITEM.CancelNotification) {
         this.logger.debug(vid + "UsbProtocol.VHUBITEM.CancelNotification" + JSON.stringify(vUrb));
         return;
      } else {
         this.logger.error(vid + "invalid VHUBITEM item");
         this.status = UsbProtocol.REDIRSTATUS.FAILED;
      }

      const reply = UsbProtocol.processItemFailedResponse(vUrb);
      callback(reply);
   };

   public getId = (): number => {
      return this.localDevice.deviceId;
   };

   public getProductName = (): string => {
      return this.localDevice.getProductName();
   };

   public getRaw = () => {
      return this.localDevice.getRaw();
   };

   public getBcdDeviceReleaseNumber = () => {
      return this.bcdDeviceReleaseNumber.toString(16).padStart(4, "0");
   };

   private getLanguageId = async (): Promise<boolean> => {
      const setup: USBControlTransferParameters = {} as USBControlTransferParameters;
      let result: USBInTransferResult = null;
      //get string Descriptor
      setup.index = 0;
      setup.recipient = "device";
      setup.request = 0x06;
      setup.requestType = "standard";
      setup.value = (0x3 << 8) | 0x00;
      try {
         result = await this.localDevice.controlTransferIn(setup, 0xff);
      } catch (e) {
         this.logger.error("failed to get string descriptor" + e);
         return false;
      }
      const stringDesc: ArrayBuffer = result.data.buffer;
      this.usbStringStore.addStringDesc(0, 0, stringDesc);

      const deviceDescView = new DataView(this.getRawDeviceDescriptor());
      //bcdDevice is the release number of usb device, which is a 16 bytes value with little endian
      this.bcdDeviceReleaseNumber = (deviceDescView.getUint8(13) << 8) | deviceDescView.getUint8(12);
      const iSerialNumber = deviceDescView.getUint8(16);
      //get iSerialNumber
      setup.index = 0x409;
      setup.recipient = "device";
      setup.requestType = "standard";
      setup.request = 0x06;
      setup.value = (0x3 << 8) | iSerialNumber;
      try {
         result = await this.localDevice.controlTransferIn(setup, 0xff);
      } catch (e) {
         this.logger.error("failed to get string descriptor" + e);
         return false;
      }

      const serialDesc: ArrayBuffer = result.data.buffer;
      this.usbStringStore.addStringDesc(iSerialNumber, 0x409, serialDesc);

      //get iProduct
      setup.index = 0x409;
      const iProduct = deviceDescView.getUint8(15);
      setup.recipient = "device";
      setup.requestType = "standard";
      setup.request = 0x06;
      setup.value = (0x3 << 8) | iProduct;
      try {
         result = await this.localDevice.controlTransferIn(setup, 0xff);
      } catch (e) {
         this.logger.error("failed to get string descriptor" + e);
         return false;
      }
      const productDesc: ArrayBuffer = result.data.buffer;
      this.usbStringStore.addStringDesc(iProduct, 0x409, productDesc);
   };

   public getStringStore = (): string => {
      return this.usbStringStore.toBlobBytes();
   };

   private _getRawDesc = async (): Promise<boolean> => {
      let setup: USBControlTransferParameters = {} as USBControlTransferParameters;
      let result: USBInTransferResult = null;
      let numConfigs: number;

      if (this.pathosis && this.pathosis.getDeviceDescriptor()) {
         this.rawDeviceDesc = this.pathosis.getDeviceDescriptor();
         const dv = new DataView(this.rawDeviceDesc);
         numConfigs = dv.getUint8(17);
         util.dumpRawDesc("DEVICE_DESCRIPTOR", dv);
      } else {
         //get device Descriptor
         setup.index = 0;
         setup.recipient = "device";
         setup.request = 0x06; // GET_DESCRIPTOR
         setup.requestType = "standard"; // device descriptor
         setup.value = 0x100;
         try {
            result = await this.localDevice.controlTransferIn(setup, 64);
         } catch (e) {
            this.logger.error("failed to get device descriptor " + e);
            return false;
         }
         this.rawDeviceDesc = result.data.buffer;
         numConfigs = result.data.getUint8(17);
         util.dumpRawDesc("DEVICE_DESCRIPTOR", result.data);
      }

      //get configuration Descriptor
      for (let i = 0; i < numConfigs; i++) {
         setup = {} as USBControlTransferParameters;
         setup.index = 0;
         setup.recipient = "device";
         setup.request = 0x06;
         setup.requestType = "standard";
         setup.value = (0x2 << 8) | i;
         try {
            result = await this.localDevice.controlTransferIn(setup, 1000);
         } catch (e) {
            this.logger.error("failed to get configuration descriptor" + e);
            return false;
         }
         if (this.splitDevice) {
            result = this.splitDevice.rewriteConfiguration(result);
         }

         const interfaces: number = result.data.getInt8(4);
         const configValue: number = result.data.getInt8(5);
         this.logger.info(interfaces + " interface(s) in config #" + configValue);
         this.rawConfigurationDesc[configValue] = result.data.buffer;
         util.dumpRawDesc("Configuration Desc", result.data);
      }
      await this.getLanguageId();
      return true;
   };

   private _handleIoctl = (vUrb, packet, callback) => {
      const reply = UsbProtocol.constructIoctlReply(vUrb, packet);
      callback(reply);
   };

   private _handleGetMsFeatureDescriptor = (vUrb, packet, callback) => {
      const data = packet.readArray(packet.bytesRemaining());
      const reply = UsbProtocol.constructGetMsFeatureDescriptorReply(vUrb, data);
      callback(reply);
   };

   private _handlePipeRequest = (vUrb, packet, callback) => {
      const data = packet.readArray(packet.bytesRemaining());
      const reply = UsbProtocol.constructPipeRequestReply(vUrb, data);
      callback(reply);
   };

   private _configureAndClaimDevice = async (agentValue: number) => {
      this.logger.info("remote usb select configuration #" + agentValue);
      this.logger.info("current configuration #" + this.currentConfig.configurationValue);
      if (agentValue) {
         this.logger.info("agent select configuration not match with the default one");
         try {
            await this.releaseDevice();
            await this.localDevice.selectConfiguration(agentValue);
         } catch (error) {
            this.logger.warning("failed to select configuration with " + error);
            if (this.currentConfig.configurationValue !== agentValue) {
               this.logger.info("configuration value is incorrect: " + agentValue);
               return false;
            } else {
               this.logger.info("configuration value is correct, continue");
            }
         }
         this.logger.info("select configuration #" + agentValue);
         try {
            this.currentConfig = await this.localDevice.getConfiguration();
            if (this.splitDevice) {
               this.currentConfig = this.splitDevice.reWriteInterfaceConfiguration(this.currentConfig);
            }
         } catch (e) {
            return false;
         }
         this.logger.info(
            "current Configuration #" +
               this.currentConfig.configurationValue +
               " - " +
               this.currentConfig.configurationName
         );
         await this._getRawDesc();
         const ret = await this.claimDevice();
         return ret;
      }
   };

   private _handleSelectConfiguration = async (vUrb, packet, callback) => {
      const agentConfig = packet.readArray(packet.bytesRemaining());
      const agentValue = agentConfig[5];
      this._configureAndClaimDevice(agentValue).then((ret) => {
         if (ret) {
            this.status = UsbProtocol.REDIRSTATUS.CONNECTED;
            const raw = new Uint8Array(this.rawConfigurationDesc[this.currentConfig.configurationValue]);
            const reply = UsbProtocol.constructSelectConfigurationReply(
               vUrb,
               raw,
               this.currentConfig.interfaces,
               this.deviceHandle
            );
            util.dumpRawDesc("URB_FUNCTION.SELECT_CONFIGURATION", new DataView(raw.buffer));
            util.dumpRawDesc("URB_FUNCTION.SELECT_CONFIGURATION", new DataView(reply._buffer.buffer));
            callback(reply);
            if (this.usbEventHandler) {
               this.usbEventHandler.onSelectConfiguration();
            }
            return;
         } else {
            this.logger.error("failed to claim device #" + agentValue);
            const reply = UsbProtocol.processItemFailedResponse(vUrb);
            this.status = UsbProtocol.REDIRSTATUS.CLAIM_ERROR;
            callback(reply);
            if (this.usbEventHandler) {
               this.usbEventHandler.onClaimError(this);
            }
            return;
         }
      });
   };

   private _handleCntlVendorClassTransfer = async (vUrb, packet, callback) => {
      if (this.deviceClaimed == null) {
         this.logger.error("device is not opened or claimed");
         return null;
      }
      const setup: USBControlTransferParameters = {} as USBControlTransferParameters;
      setup.request = vUrb.CntlVendorClass.Request;
      setup.value = vUrb.CntlVendorClass.Value;
      setup.index = vUrb.CntlVendorClass.Index;

      switch (vUrb.UrbHeader.function) {
         case UsbProtocol.URB_FUNCTION.CLASS_INTERFACE:
            setup.recipient = "interface";
            setup.requestType = "class";
            break;
         case UsbProtocol.URB_FUNCTION.CLASS_DEVICE:
            setup.recipient = "device";
            setup.requestType = "class";
            break;
         case UsbProtocol.URB_FUNCTION.CLASS_ENDPOINT:
            setup.recipient = "endpoint";
            setup.requestType = "class";
            break;
         case UsbProtocol.URB_FUNCTION.VENDOR_DEVICE:
            setup.recipient = "device";
            setup.requestType = "vendor";
            break;
         case UsbProtocol.URB_FUNCTION.VENDOR_INTERFACE:
            setup.recipient = "interface";
            setup.requestType = "vendor";
            break;
         case UsbProtocol.URB_FUNCTION.VENDOR_ENDPOINT:
            setup.recipient = "endpoint";
            setup.requestType = "vendor";
            break;
         default:
            this.logger.error(vUrb.UrbHeader.function + " is not supported in _handleCntlVendorClassTransfer");
            return null;
      }

      if (setup.request === 10 && this.localDevice.vendorId === 0x056a) {
         // It seems the wacom device will enter stall state if you send
         // SET_IDLE command to it with chrome.usb.api, here is a workaround
         // for it.
         this.logger.debug("ClassInterfaceTransfer ignore set idle urb for wacom device");
         return;
      }

      if (vUrb.CntlVendorClass.TransferFlags & 0x1) {
         let result: USBInTransferResult = null;
         const length = vUrb.CntlVendorClass.TransferBufferLength;
         try {
            result = await this.localDevice.controlTransferIn(setup, length);
            if (result.status === "ok") {
               this.logger.trace("URB_FUNCTION_CLASS_INTERFACE IN success");
               util.dumpRawDesc("URB_FUNCTION_CLASS_INTERFACE", result.data);
               const reply = UsbProtocol.constructGetClassInterfaceReply(vUrb, new Uint8Array(result.data.buffer), 0);
               callback(reply);
            } else {
               this.logger.error("URB_FUNCTION_CLASS_INTERFACE failed");
            }
         } catch (e) {
            this.logger.info("controlTransferIn - failed with exception " + e);
         }
      } else {
         let result: USBOutTransferResult = null;
         const data_length = packet.bytesRemaining();
         const toUsb = new ArrayBuffer(data_length);
         const dataView = new Uint8Array(toUsb, 0, packet.bytesRemaining());
         const data = packet.readArray(packet.bytesRemaining());
         if (data) {
            for (let i = 0; i < data.length; i++) {
               dataView[i] = data[i];
            }
         }
         try {
            result = await this.localDevice.controlTransferOut(setup, toUsb);
            if (result && result.status === "ok") {
               this.logger.trace("URB_FUNCTION_CLASS_INTERFACE OUT success");
               const reply = UsbProtocol.constructGetClassInterfaceReply(vUrb, null, result.bytesWritten);
               callback(reply);
            } else {
               this.logger.error("URB_FUNCTION_CLASS_INTERFACE failed");
            }
         } catch (e) {
            this.logger.info("controlTransferOut - failed with exception " + e);
         }
      }
   };

   private _handleBulkOrInterruptTransfer = async (vUrb, packet, callback) => {
      let rawData: Uint8Array = null;
      let endpoint: USBEndpoint = null;

      for (const inter of this.currentConfig.interfaces) {
         /*
          * chrome browser bug - w3c usb implementation workaround.
          * https://wicg.github.io/webusb/
          * The alternate attribute SHALL be set to the USBAlternateInterface that
          * is currently selected for this interface, which by default SHALL
          * be the one with bAlternateSetting equal to 0.
          *
          * But this value is always zero in chrome browser.
          * (before/after select configuration)
          */
         let alternate: USBAlternateInterface = inter.alternate;
         if (alternate === null) {
            for (const alter of inter.alternates) {
               if (alter.alternateSetting === 0) {
                  alternate = alter;
               }
            }
         }
         for (const ep of alternate.endpoints) {
            let address = ep.endpointNumber;
            /*
             * w3c and chrome.usb.* differences.
             * W3C standard.
             * https://wicg.github.io/webusb/
             * Each endpoint within a particular device configuration SHALL have a unique
             * combination of endpointNumber and direction. The endpointNumber MUST equal
             * the 4 least significant bits of the bEndpointAddress field of the endpoint
             * descriptor defining the endpoint.
             *
             * chrome.usb.* API
             * https://developer.chrome.com/apps/usb#type-EndpointDescriptor
             *
             */
            if (ep.direction === "in") {
               address = 0x80 | ep.endpointNumber;
            }
            if (address === vUrb.bulkOrInterruptTransfer.PipeHandle) {
               endpoint = ep;
               break;
            }
         }
      }

      if (endpoint === null) {
         this.logger.error("BulkOrInterruptTransfer endpoint don't find " + vUrb.bulkOrInterruptTransfer.PipeHandle);
         return false;
      }

      if (!(endpoint.type === "bulk" || endpoint.type === "interrupt")) {
         this.logger.error("BulkOrInterruptTransfer invalid endpoint type : " + endpoint.type);
         return null;
      }

      if (
         endpoint.direction === "in" &&
         (vUrb.urb.transferBuffer2Dir === UsbProtocol.VHUBITEM_TRANSFERDIRECTION.In ||
            vUrb.urb.transferBuffer2Dir === UsbProtocol.VHUBITEM_TRANSFERDIRECTION.InOut)
      ) {
         this.logger.trace("BulkOrInterruptTransfer endpoint direction : " + endpoint.direction);
      } else if (
         endpoint.direction === "out" &&
         (vUrb.urb.transferBuffer2Dir === UsbProtocol.VHUBITEM_TRANSFERDIRECTION.Out ||
            vUrb.urb.transferBuffer2Dir === UsbProtocol.VHUBITEM_TRANSFERDIRECTION.InOut)
      ) {
         this.logger.trace("BulkOrInterruptTransfer endpoint direction : " + endpoint.direction);
      } else {
         this.logger.error(
            "BulkOrInterruptTransfer Transfer direction mismatch : " +
               endpoint.direction +
               " vs " +
               vUrb.urb.transferBuffer2Dir
         );
         return null;
      }

      if (this.deviceClaimed === false) {
         this.logger.error("BulkOrInterruptTransfer - device not claimed");
         return;
      }
      const toUsb: ArrayBuffer = null;
      if (endpoint.direction === "in") {
         let result: USBInTransferResult = null;
         const length = vUrb.bulkOrInterruptTransfer.TransferBufferLength;
         try {
            result = await this.localDevice.transferIn(endpoint.type, endpoint.endpointNumber, length);
            if (result && result.status !== "ok") {
               this.logger.info("BulkOrInterruptTransfer in - failed " + result.status);
            } else {
               if (result.data) {
                  rawData = new Uint8Array(result.data.buffer);
               }
               const reply = UsbProtocol.constructBulkOrInterruptTransferReply(vUrb, rawData, 0);
               callback(reply);
            }
         } catch (e) {
            this.logger.info("BulkOrInterruptTransfer in - failed with exception " + e);
         }
      } else {
         let result: USBOutTransferResult = null;
         const data = packet.readArray(packet.bytesRemaining());
         const toUsb = new ArrayBuffer(data.length);
         const dataView = new Uint8Array(toUsb);
         for (let i = 0; i < data.length; i++) {
            dataView[i] = data[i];
         }
         try {
            result = await this.localDevice.transferOut(endpoint.type, endpoint.endpointNumber, toUsb);
            if (result && result.status !== "ok") {
               this.logger.info("BulkOrInterruptTransfer out - failed " + result.status);
            } else {
               const reply = UsbProtocol.constructBulkOrInterruptTransferReply(vUrb, rawData, result.bytesWritten);
               callback(reply);
            }
         } catch (e) {
            this.logger.info("BulkOrInterruptTransfer out - failed with exception " + e);
         }
      }
   };

   private _handleControlTransfer = async (vUrb, packet, callback) => {
      const replyData: Uint8Array = null;
      const setup: USBControlTransferParameters = {} as USBControlTransferParameters;
      if (this.deviceClaimed == null) {
         this.logger.error("usb device has not been opened");
         return;
      }

      //Refer from usb2.0 $9.3 Table 9-2
      const reqDir = (vUrb.cntlTransfer.bmRequestType >> 7) & 0x1;
      const reqRecipient = vUrb.cntlTransfer.bmRequestType & 0x3;
      const reqType = (vUrb.cntlTransfer.bmRequestType >> 5) & 0x3;

      this.logger.trace("ControlTransfer reqRecipient = " + reqRecipient + " reqType " + reqType);

      setup.index = vUrb.cntlTransfer.wIndex;
      setup.recipient = UsbProtocol.USBRECIPIENT[reqRecipient] as USBRecipient;
      setup.request = vUrb.cntlTransfer.bRequest;
      setup.requestType = UsbProtocol.USBTYPE[reqType] as USBRequestType;
      setup.value = vUrb.cntlTransfer.wValue;

      let result: USBInTransferResult = null;
      if (reqDir) {
         const length: number = vUrb.cntlTransfer.wLength;
         try {
            result = await this.localDevice.controlTransferIn(setup, length);
            if (result && result.status !== "ok") {
               this.logger.info("controlTransferIn - failed " + result.status);
            } else {
               const replyData = new Uint8Array(result.data.buffer);
               const reply = UsbProtocol.constructControlTransferReply(vUrb, replyData, 0);
               callback(reply);
            }
         } catch (e) {
            this.logger.info("controlTransferIn - failed with exception " + e);
         }
      } else {
         // SET_CONFIGURATION
         if (setup.request === 9 && vUrb.cntlTransfer.bmRequestType === 0) {
            this._configureAndClaimDevice(setup.value)
               .then((ret) => {
                  if (ret) {
                     this.status = UsbProtocol.REDIRSTATUS.CONNECTED;
                     const reply = UsbProtocol.constructControlTransferReply(vUrb, replyData, 0);
                     callback(reply);
                     if (this.usbEventHandler) {
                        this.usbEventHandler.onSelectConfiguration();
                     }
                     return;
                  } else {
                     this.logger.error("_configureAndClaimDevice failed");
                  }
               })
               .catch((e) => {
                  this.logger.error("_configureAndClaimDevice exception" + e);
                  if (this.usbEventHandler) {
                     this.usbEventHandler.onClaimError(this);
                  }
               });
         } else {
            let result: USBOutTransferResult = null;
            const data_length = packet.bytesRemaining();
            let toUsb = null;
            toUsb = new ArrayBuffer(data_length);
            const dataView = new Uint8Array(toUsb, 0, packet.bytesRemaining());
            const data = packet.readArray(packet.bytesRemaining());
            if (data) {
               for (let i = 0; i < data.length; i++) {
                  dataView[i] = data[i];
               }
            }
            try {
               result = await this.localDevice.controlTransferOut(setup, toUsb);
               if (result && result.status !== "ok") {
                  this.logger.info("controlTransferOut - failed " + result.status);
               } else {
                  const reply = UsbProtocol.constructControlTransferReply(vUrb, replyData, result.bytesWritten);
                  callback(reply);
               }
            } catch (e) {
               this.logger.info("controlTransferOut - failed with exception " + e);
            }
         }
      }
   };

   private _handleGetStatusFromDevice = async (vUrb, packet, callback) => {
      let result: USBInTransferResult = null;
      const setup: USBControlTransferParameters = {} as USBControlTransferParameters;

      setup.recipient = "device";
      let indexId = 0;
      if (vUrb.UrbHeader.function === UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_DEVICE) {
         setup.recipient = "device";
         indexId = vUrb.getStatus.index;
      } else if (vUrb.UrbHeader.function === UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_INTERFACE) {
         setup.recipient = "interface";
      } else if (vUrb.UrbHeader.function === UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_ENDPOINT) {
         setup.recipient = "endpoint";
      } else {
         setup.recipient = "other";
      }
      setup.index = indexId;
      setup.request = 0;
      setup.requestType = "standard";
      setup.value = 0;

      try {
         result = await this.localDevice.controlTransferIn(setup, vUrb.urb.transferBuffer2Length);
      } catch (e) {
         this.logger.error("handleGetStatus failed with exception " + e);
         return;
      }

      if (result.status === "ok") {
         const rawData = new Uint8Array(result.data.buffer);
         const reply = UsbProtocol.constructGetStatusFromDeviceReply(vUrb, rawData);
         callback(reply);
      } else {
         this.logger.error("handleGetStatus failed " + result.status);
      }
   };

   private _handleGetDescriptor = async (vUrb, packet, callback) => {
      let result: USBInTransferResult = null;
      const setup: USBControlTransferParameters = {} as USBControlTransferParameters;

      if (!this.deviceClaimed) {
         this.logger.info("usb device not been opened/claimed");
      }

      setup.index = vUrb.cntlDesc.LanguageId;
      setup.recipient = "device";
      setup.request = 0x6;
      setup.requestType = "standard";
      setup.value = (vUrb.cntlDesc.DescriptorType << 8) | vUrb.cntlDesc.Index;

      if (vUrb.UrbHeader.function === UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_INTERFACE) {
         setup.recipient = "interface";
      }

      try {
         result = await this.localDevice.controlTransferIn(setup, vUrb.urb.transferBuffer2Length);
      } catch (e) {
         this.logger.error("_handleGetDescriptor failed with exception " + e);
         return;
      }

      if (result && result.status === "ok") {
         util.dumpRawDesc("Descriptor", result.data);
         this.logger.debug("Descriptor type = " + vUrb.cntlDesc.DescriptorType);
         const rawData = new Uint8Array(result.data.buffer);
         const reply = UsbProtocol.constructGetDescriptorReply(vUrb, rawData, packet);
         callback(reply);
      } else {
         this.logger.error("_handleGetDescriptor failed " + result.status);
      }
   };

   private _handleResetPipe = async (vUrb, packet, callback) => {
      const result: USBInTransferResult = null;
      const setup: USBControlTransferParameters = {} as USBControlTransferParameters;

      if (!this.deviceClaimed) {
         this.logger.info("usb device not been opened/claimed");
      }

      setup.index = vUrb.resetPipe.PipeHandle;
      setup.recipient = "endpoint";
      setup.request = 0x01;
      setup.requestType = "standard";
      setup.value = 0x00;

      try {
         const result = await this.localDevice.controlTransferOut(setup, new ArrayBuffer(0));
      } catch (e) {
         this.logger.error("handleResetPipe failed with exception " + e);
      }

      if (result && result.status !== "ok") {
         this.logger.error("handleGetStatus failed " + result.status);
      }
      const reply = UsbProtocol.constructResetStallReply(vUrb);
      callback(reply);
   };
}
