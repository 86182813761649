/**
 * ******************************************************
 * Copyright (C) 2018-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/*
 *    This file contains copies of Windows USB definitions taken from
 *    Windows Vista DDK.
 *
 *    Port from:
 *    bora/apps/viewusb/framework/usb/include/winusb.h
 */

import WMKS from "WMKS";
import Logger from "../../../core/libs/logger";

/*
 * This is the maximum URB size we'll submit to the backend. Small TDs
 * are aggregated up to this maximum size, TDs larger than this size
 * are split. It must be 16k on Linux due to a kernel limitation, but
 * 32k is much more reasonable on windows as it avoids splitting 20k
 * EHCI packets.
 *
 * The limits here for Linux are both set according to kernel limitations.
 * The windows limits are higher for improved performance- particularly
 * the isochronous batch size is much larger to support high-bandwidth
 * isochronous traffic at the full data rate.
 *
 * We set the batch size for the remoteUSB backend to always be to that Linux
 * supports to prevent crashes when a windows controller sends packets larger
 * than what a client running on Linux can handle.
 *
 * NB: The largest URBs we've seen in practice are 0x8008 bytes, generated
 *     by the Apple iPhone's Windows drivers. Note that the BATCH_SIZE doesn't
 *     have to be larger than the biggest guest URB, it's just a performance
 *     enhancement if this is the case.
 *
 */

export const VUSB_BATCH_SIZE = 0x40000;

export interface USBControlMsg {
   type: string;
   data: string;
}

export const UsbProtocol = {
   LOG: "<usb> ",

   USBDIRECTION: ["out", "in"],
   USBTYPE: ["standard", "class", "vendor", "reserved"],
   USBRECIPIENT: ["device", "interface", "endpoint", "other"],

   USB_FILTER_PREFIX: '<ITEM name="FilterBlob" type="BINARY">',
   USB_FILTER_SUFFIX: "</ITEM>",
   VHUBITEM: {
      Urb: 0,
      Ioctl: 1,
      CancelNotification: 2
   },

   REDIRSTATUS: {
      INIT: "REDIRECT_STATUS_INIT",
      CONNECTING: "REDIRECT_STATUS_CONNECTING",
      CONNECTED: "REDIRECT_STATUS_CONNECTED",
      FAILED: "REDIRECT_STATUS_FAILED",
      CLAIM_ERROR: "REDIRECT_STATUS_CLAIM_ERROR"
   },

   //Transfer buffer direction
   VHUBITEM_TRANSFERDIRECTION: {
      Neither: 0,
      In: 1,
      Out: 2,
      InOut: 3
   },

   VHUBITEM_FAILED: 0xffffffff,

   OPERATION: {
      USB_DEVICE_UNPLUGGED_TEXT: "UnplugDevice",
      USB_DEVICE_PLUGIN_TEXT: "PlugInDevice",
      RUSB_ISUSBAVAILABLE_TEXT: "IsUsbAvailable"
   },

   PACKET_TYPE: {
      USBREDIRECTION_POSTMSG: 0,
      USBREDIRECTION_POSTMSGFAST: 1,
      USBREDIRECTION_SENDASYNCMSG: 2
   },

   URB_FUNCTION: {
      SELECT_CONFIGURATION: 0x0000,
      SELECT_INTERFACE: 0x0001,
      ABORT_PIPE: 0x0002,
      TAKE_FRAME_LENGTH_CONTROL: 0x0003,
      RELEASE_FRAME_LENGTH_CONTROL: 0x0004,
      GET_FRAME_LENGTH: 0x0005,
      SET_FRAME_LENGTH: 0x0006,
      GET_CURRENT_FRAME_NUMBER: 0x0007,
      CONTROL_TRANSFER: 0x0008,
      BULK_OR_INTERRUPT_TRANSFER: 0x0009,
      ISOCH_TRANSFER: 0x000a,
      GET_DESCRIPTOR_FROM_DEVICE: 0x000b,
      SET_DESCRIPTOR_TO_DEVICE: 0x000c,
      SET_FEATURE_TO_DEVICE: 0x000d,
      SET_FEATURE_TO_INTERFACE: 0x000e,
      SET_FEATURE_TO_ENDPOINT: 0x000f,
      CLEAR_FEATURE_TO_DEVICE: 0x0010,

      CLEAR_FEATURE_TO_INTERFACE: 0x0011,
      CLEAR_FEATURE_TO_ENDPOINT: 0x0012,
      GET_STATUS_FROM_DEVICE: 0x0013,
      GET_STATUS_FROM_INTERFACE: 0x0014,
      GET_STATUS_FROM_ENDPOINT: 0x0015,
      RESERVED_0X0016: 0x0016,
      VENDOR_DEVICE: 0x0017,
      VENDOR_INTERFACE: 0x0018,
      VENDOR_ENDPOINT: 0x0019,
      CLASS_DEVICE: 0x001a,
      CLASS_INTERFACE: 0x001b,
      CLASS_ENDPOINT: 0x001c,
      RESERVE_0X001D: 0x001d,

      // previously URB_FUNCTION_RESET_PIPE
      SYNC_RESET_PIPE_AND_CLEAR_STALL: 0x001e,
      CLASS_OTHER: 0x001f,
      VENDOR_OTHER: 0x0020,
      GET_STATUS_FROM_OTHER: 0x0021,
      CLEAR_FEATURE_TO_OTHER: 0x0022,
      SET_FEATURE_TO_OTHER: 0x0023,
      GET_DESCRIPTOR_FROM_ENDPOINT: 0x0024,
      SET_DESCRIPTOR_TO_ENDPOINT: 0x0025,
      GET_CONFIGURATION: 0x0026,
      GET_INTERFACE: 0x0027,
      GET_DESCRIPTOR_FROM_INTERFACE: 0x0028,
      SET_DESCRIPTOR_TO_INTERFACE: 0x0029,

      GET_MS_FEATURE_DESCRIPTOR: 0x002a,
      SYNC_RESET_PIPE: 0x0030,
      SYNC_CLEAR_STALL: 0x0031,

      CONTROL_TRANSFER_EX: 0x0032,
      SET_PIPE_IO_POLICY: 0x0033,
      GET_PIPE_IO_POLICY: 0x0034,
      // Reserve 0x002B-0x002F
      RESERVE_0X002B: 0x002b,
      RESERVE_0X002C: 0x002c,
      RESERVE_0X002D: 0x002d,
      RESERVE_0X002E: 0x002e,
      RESERVE_0X002F: 0x002f
   },

   DESCRIPTOR: {
      USB_DEVICE_DESCRIPTOR_TYPE: 0x01,
      USB_CONFIGURATION_DESCRIPTOR_TYPE: 0x02,
      USB_STRING_DESCRIPTOR_TYPE: 0x03,
      USB_INTERFACE_DESCRIPTOR_TYPE: 0x04,
      USB_ENDPOINT_DESCRIPTOR_TYPE: 0x05,
      USB_HID_DESCRIPTOR_TYPE: 0x21,
      USB_HID_REPORT_TYPE: 0x22
   },

   USB_HID_COMMAND_TYPE: {
      GET_REPORT: 0x01,
      SET_REPORT: 0x09,
      SET_IDLE: 0x0a
   },

   STATUS: {
      SUCESS: 0x0,
      UNSUCCESSFUL: 0xc0000001,
      INVALID_PARAMETER: 0xc000000d,
      INVALID_DEVICE_REQUEST: 0xc0000010,
      INSUFFICIENT_RESOURCES: 0xc000009a,
      CANCELLED: 0xc0000120
   },

   VhubItemFailed: 0xffffffff,

   getUrbFunctionType: function (func) {
      switch (func) {
         case UsbProtocol.URB_FUNCTION.SELECT_CONFIGURATION:
            return "URB_FUNCTION_SELECT_CONFIGURATION";
         case UsbProtocol.URB_FUNCTION.SELECT_INTERFACE:
            return "URB_FUNCTION_SELECT_INTERFACE";
         case UsbProtocol.URB_FUNCTION.ABORT_PIPE:
            return "URB_FUNCTION_ABORT_PIPE";
         case UsbProtocol.URB_FUNCTION.TAKE_FRAME_LENGTH_CONTROL:
            return "URB_FUNCTION_TAKE_FRAME_LENGTH_CONTROL";
         case UsbProtocol.URB_FUNCTION.RELEASE_FRAME_LENGTH_CONTROL:
            return "URB_FUNCTION_RELEASE_FRAME_LENGTH_CONTROL";
         case UsbProtocol.URB_FUNCTION.GET_FRAME_LENGTH:
            return "URB_FUNCTION_GET_FRAME_LENGTH";
         case UsbProtocol.URB_FUNCTION.SET_FRAME_LENGTH:
            return "URB_FUNCTION_SET_FRAME_LENGTH";
         case UsbProtocol.URB_FUNCTION.GET_CURRENT_FRAME_NUMBER:
            return "URB_FUNCTION_GET_CURRENT_FRAME_NUMBER";
         case UsbProtocol.URB_FUNCTION.CONTROL_TRANSFER:
            return "URB_FUNCTION_CONTROL_TRANSFER";
         case UsbProtocol.URB_FUNCTION.BULK_OR_INTERRUPT_TRANSFER:
            return "URB_FUNCTION_BULK_OR_INTERRUPT_TRANSFER";
         case UsbProtocol.URB_FUNCTION.ISOCH_TRANSFER:
            return "URB_FUNCTION_ISOCH_TRANSFER";
         case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_DEVICE:
            return "URB_FUNCTION_GET_DESCRIPTOR_FROM_DEVICE";
         case UsbProtocol.URB_FUNCTION.SET_DESCRIPTOR_TO_DEVICE:
            return "URB_FUNCTION_SET_DESCRIPTOR_TO_DEVICE";
         case UsbProtocol.URB_FUNCTION.SET_FEATURE_TO_DEVICE:
            return "URB_FUNCTION_SET_FEATURE_TO_DEVICE";
         case UsbProtocol.URB_FUNCTION.SET_FEATURE_TO_INTERFACE:
            return "URB_FUNCTION_SET_FEATURE_TO_INTERFACE";
         case UsbProtocol.URB_FUNCTION.SET_FEATURE_TO_ENDPOINT:
            return "URB_FUNCTION_SET_FEATURE_TO_ENDPOINT";
         case UsbProtocol.URB_FUNCTION.CLEAR_FEATURE_TO_DEVICE:
            return "URB_FUNCTION_CLEAR_FEATURE_TO_DEVICE";
         case UsbProtocol.URB_FUNCTION.CLEAR_FEATURE_TO_INTERFACE:
            return "URB_FUNCTION_CLEAR_FEATURE_TO_INTERFACE";
         case UsbProtocol.URB_FUNCTION.CLEAR_FEATURE_TO_ENDPOINT:
            return "URB_FUNCTION_CLEAR_FEATURE_TO_ENDPOINT";
         case UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_DEVICE:
            return "URB_FUNCTION_GET_STATUS_FROM_DEVICE";
         case UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_INTERFACE:
            return "URB_FUNCTION_GET_STATUS_FROM_INTERFACE";
         case UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_ENDPOINT:
            return "URB_FUNCTION_GET_STATUS_FROM_ENDPOINT";
         case UsbProtocol.URB_FUNCTION.VENDOR_DEVICE:
            return "URB_FUNCTION_VENDOR_DEVICE";
         case UsbProtocol.URB_FUNCTION.VENDOR_INTERFACE:
            return "URB_FUNCTION_VENDOR_INTERFACE";
         case UsbProtocol.URB_FUNCTION.VENDOR_ENDPOINT:
            return "URB_FUNCTION_VENDOR_ENDPOINT";
         case UsbProtocol.URB_FUNCTION.CLASS_DEVICE:
            return "URB_FUNCTION_CLASS_DEVICE";
         case UsbProtocol.URB_FUNCTION.CLASS_INTERFACE:
            return "URB_FUNCTION_CLASS_INTERFACE";
         case UsbProtocol.URB_FUNCTION.CLASS_ENDPOINT:
            return "URB_FUNCTION_CLASS_ENDPOINT";
         case UsbProtocol.URB_FUNCTION.RESERVE_0X001D:
            return "URB_FUNCTION_RESERVE_0X001D";
         case UsbProtocol.URB_FUNCTION.SYNC_RESET_PIPE_AND_CLEAR_STALL:
            return "URB_FUNCTION_SYNC_RESET_PIPE_AND_CLEAR_STALL";
         case UsbProtocol.URB_FUNCTION.CLASS_OTHER:
            return "URB_FUNCTION_CLASS_OTHER";
         case UsbProtocol.URB_FUNCTION.VENDOR_OTHER:
            return "URB_FUNCTION_VENDOR_OTHER";
         case UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_OTHER:
            return "URB_FUNCTION_GET_STATUS_FROM_OTHER";
         case UsbProtocol.URB_FUNCTION.CLEAR_FEATURE_TO_OTHER:
            return "URB_FUNCTION_CLEAR_FEATURE_TO_OTHER";
         case UsbProtocol.URB_FUNCTION.SET_FEATURE_TO_OTHER:
            return "URB_FUNCTION_SET_FEATURE_TO_OTHER";
         case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_ENDPOINT:
            return "URB_FUNCTION_GET_DESCRIPTOR_FROM_ENDPOINT";
         case UsbProtocol.URB_FUNCTION.SET_DESCRIPTOR_TO_ENDPOINT:
            return "URB_FUNCTION_SET_DESCRIPTOR_TO_ENDPOINT";
         case UsbProtocol.URB_FUNCTION.GET_CONFIGURATION:
            return "URB_FUNCTION_GET_CONFIGURATION";
         case UsbProtocol.URB_FUNCTION.GET_INTERFACE:
            return "URB_FUNCTION_GET_INTERFACE";
         case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_INTERFACE:
            return "URB_FUNCTION_GET_DESCRIPTOR_FROM_INTERFACE";
         case UsbProtocol.URB_FUNCTION.SET_DESCRIPTOR_TO_INTERFACE:
            return "URB_FUNCTION_SET_DESCRIPTOR_TO_INTERFACE";
         case UsbProtocol.URB_FUNCTION.GET_MS_FEATURE_DESCRIPTOR:
            return "URB_FUNCTION_GET_MS_FEATURE_DESCRIPTOR";
         case UsbProtocol.URB_FUNCTION.SYNC_RESET_PIPE:
            return "URB_FUNCTION_SYNC_RESET_PIPE";
         case UsbProtocol.URB_FUNCTION.SYNC_CLEAR_STALL:
            return "URB_FUNCTION_SYNC_CLEAR_STALL";
         default:
            return "unsupported message(" + func + ")";
      }
   },

   _arrayBufferToBase64: function (buffer) {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
         binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
   },

   generateUsbRemoveDeviceMsg: function (pluginNo): USBControlMsg {
      const request: USBControlMsg = {} as USBControlMsg;
      request.type = UsbProtocol.OPERATION.USB_DEVICE_UNPLUGGED_TEXT;
      request.data = '<PROPERTYBAG><ITEM name="PLUGNO">' + pluginNo + "</ITEM>" + "</PROPERTYBAG>";
      return request;
   },

   generateUsbAvailableMsg: function (session, ticket): USBControlMsg {
      const request: USBControlMsg = {} as USBControlMsg;
      request.type = UsbProtocol.OPERATION.RUSB_ISUSBAVAILABLE_TEXT;
      request.data =
         '<PROPERTYBAG><ITEM name="SessionId">' +
         session +
         '</ITEM><ITEM name="channelTicket">' +
         ticket +
         "</ITEM></PROPERTYBAG>";
      return request;
   },

   generatePlugInDeviceMsg: function (
      version,
      plugno,
      deviceDesc,
      deviceConfigs,
      currentConfigValue,
      fastQueue,
      stringStore
   ): USBControlMsg {
      const request: USBControlMsg = {} as USBControlMsg;
      const configIndex = Object.keys(deviceConfigs).sort();
      let defaultIndex = currentConfigValue;
      if (configIndex && configIndex[0]) {
         defaultIndex = configIndex[0];
      }
      request.type = UsbProtocol.OPERATION.USB_DEVICE_PLUGIN_TEXT;
      request.data =
         "<PROPERTYBAG>" +
         '<ITEM name="VERSION">' +
         version +
         "</ITEM>" +
         '<ITEM name="PLUGNO">' +
         plugno +
         "</ITEM>" +
         '<ITEM name="DEVICEDESC" type="BINARY">' +
         this._arrayBufferToBase64(deviceDesc) +
         "</ITEM>" +
         '<ITEM name="DEVICECONFIG" type="BINARY">' +
         this._arrayBufferToBase64(deviceConfigs[defaultIndex]) +
         "</ITEM>" +
         '<ITEM name="STRINGSTORE" type="BINARY">' +
         stringStore +
         "</ITEM>" +
         '<ITEM name="ADDITIONALDEVICECONFIG" type="BAG">';

      Object.keys(deviceConfigs).forEach((key) => {
         if (key !== defaultIndex.toString()) {
            request.data += '<ITEM name="DEVICECONFIG" type="BAG">';
            request.data += '<ITEM name="DEVICECONFIGINDEX">' + key + "</ITEM>";
            request.data +=
               '<ITEM name="DEVICECONFIG" type="BINARY">' + this._arrayBufferToBase64(deviceConfigs[key]) + "</ITEM>";
            request.data += "</ITEM>";
         }
      });
      request.data +=
         "</ITEM>" +
         '<ITEM name="PARTIALDEVICE">false</ITEM>' +
         '<ITEM name="FASTQUEUE">' +
         fastQueue +
         "</ITEM>" +
         "</PROPERTYBAG>";
      return request;
   },

   processItemFailedResponse: function (vUrb) {
      const packet = WMKS.Packet.createNewPacketLE();
      packet.writeUint32(vUrb.itemId);
      packet.writeUint32(UsbProtocol.VHUBITEM_FAILED);
      packet.writeUint32(0);
      return packet;
   },

   __parseVhubItem: function (vUrb, packet) {
      vUrb.itemType = packet.readUint32();
      vUrb.itemId = packet.readUint32();
      vUrb.plugNo = packet.readUint32();
      vUrb.clientPlugNo = packet.readUint32();
   },

   __parseUrbCommonHeader: function (vUrb, packet) {
      vUrb.urb = {};

      vUrb.urb.transferBuffer1Dir = packet.readUint32();
      vUrb.urb.transferBuffer1Length = packet.readUint32();
      vUrb.urb.transferBuffer2Dir = packet.readUint32();
      vUrb.urb.transferBuffer2Length = packet.readUint32();
      vUrb.urb.transferBufferLength = packet.readUint32();
      vUrb.urb.dropResponse = packet.readUint32();

      if (vUrb.urb.transferBufferLength !== packet.bytesRemaining()) {
         Logger.error(
            UsbProtocol.LOG +
               "Malformed URB request, buffer length mismatch, " +
               "transferBufferLength: " +
               packet.bytesRemaining() +
               "VhubItem.transferBufferLength: " +
               vUrb.urb.transferBufferLength
         );
         return false;
      }

      if (vUrb.urb.transferBuffer1Dir !== UsbProtocol.VHUBITEM_TRANSFERDIRECTION.InOut) {
         Logger.error(
            UsbProtocol.LOG +
               "Malformed URB request, invalid transfer buffer 1 " +
               "direction, VhubItem.transferBuffer1Dir: " +
               vUrb.urb.transferBuffer1Dir
         );
         return false;
      }

      if (
         ![
            UsbProtocol.VHUBITEM_TRANSFERDIRECTION.Neither,
            UsbProtocol.VHUBITEM_TRANSFERDIRECTION.In,
            UsbProtocol.VHUBITEM_TRANSFERDIRECTION.Out,
            UsbProtocol.VHUBITEM_TRANSFERDIRECTION.InOut
         ].includes(vUrb.urb.transferBuffer2Dir)
      ) {
         Logger.error(
            UsbProtocol.LOG +
               "Malformed URB request, invalid transfer buffer 2 direction " +
               "VhubItem.transferBuffer2Dir: " +
               vUrb.urb.transferBuffer2Dir
         );
         return false;
      }

      let urbTransferBufferLength = vUrb.urb.transferBuffer1Length;
      if (
         vUrb.urb.transferBuffer2Dir === UsbProtocol.VHUBITEM_TRANSFERDIRECTION.Out ||
         vUrb.urb.transferBuffer2Dir === UsbProtocol.VHUBITEM_TRANSFERDIRECTION.InOut
      ) {
         urbTransferBufferLength += vUrb.urb.transferBuffer2Length;
      }

      if (
         urbTransferBufferLength !== packet.bytesRemaining() ||
         urbTransferBufferLength !== vUrb.urb.transferBufferLength
      ) {
         Logger.error(
            UsbProtocol.LOG +
               "Malformed URB request, buffer length mismatch," +
               " transferBufferLength :" +
               packet.bytesRemaining() +
               " urbTransferBufferLength :" +
               urbTransferBufferLength +
               " VhubItem.transferBufferLength: " +
               vUrb.urb.transferBufferLength +
               " VhubItem.transferBuffer1Length: " +
               vUrb.urb.transferBuffer1Length +
               " VhubItem.transferBuffer2Dir: " +
               vUrb.urb.transferBuffer2Dir +
               " VhubItem.transferBuffer2Length: " +
               vUrb.urb.transferBuffer2Length
         );
         return false;
      }
      return true;
   },

   __parseUrbHeader: function (vUrb, packet) {
      vUrb.UrbHeader = {};

      vUrb.UrbHeader.length = packet.readUint16();
      vUrb.UrbHeader.function = packet.readUint16();
      vUrb.UrbHeader.status = packet.readUint32();
      vUrb.UrbHeader.deviceHandle = packet.readUint32();
      vUrb.UrbHeader.usbdFlag = packet.readUint32();

      // Logger.debug(UsbProtocol.LOG
      //    + "vUrb.UrbHeader.length : " + vUrb.UrbHeader.length
      //    + " vUrb.UrbHeader.function: " + vUrb.UrbHeader.function
      //    + " vUrb.UrbHeader.status : " + vUrb.UrbHeader.status
      //    + " vUrb.UrbHeader.deviceHandle : " + vUrb.UrbHeader.deviceHandle
      //    + " vUrb.UrbHeader.usbdFlag : "
      //    + parseInt(vUrb.UrbHeader.usbdFlag,16));
   },

   __handleBulkOrInterruptTransfer: function (vUrb, packet) {
      vUrb.bulkOrInterruptTransfer = {};

      vUrb.bulkOrInterruptTransfer.PipeHandle = packet.readUint32();
      vUrb.bulkOrInterruptTransfer.TransferFlags = packet.readUint32();
      vUrb.bulkOrInterruptTransfer.TransferBufferLength = packet.readUint32();
      vUrb.bulkOrInterruptTransfer.TransferBuffer = packet.readUint32();
      vUrb.bulkOrInterruptTransfer.TransferBufferMDL = packet.readUint32();
      vUrb.bulkOrInterruptTransfer.UrbLink = packet.readUint32();
      vUrb.bulkOrInterruptTransfer.hca = packet.readArray(32);
   },

   __handleGetDescriptorTransfer: function (vUrb, packet) {
      vUrb.cntlDesc = {};

      vUrb.cntlDesc.reserved = packet.readUint32();
      vUrb.cntlDesc.reserved0 = packet.readUint32();
      vUrb.cntlDesc.transferBufferLength = packet.readUint32();
      vUrb.cntlDesc.TransferBuffer = packet.readUint32();
      vUrb.cntlDesc.TransferBufferMDL = packet.readUint32();
      vUrb.cntlDesc.UrbLink = packet.readUint32();
      vUrb.cntlDesc.hca = packet.readArray(32);
      vUrb.cntlDesc.reserved1 = packet.readUint16();
      vUrb.cntlDesc.Index = packet.readUint8();
      vUrb.cntlDesc.DescriptorType = packet.readUint8();
      vUrb.cntlDesc.LanguageId = packet.readUint16();
      vUrb.cntlDesc.reserved2 = packet.readUint16();
   },

   __handleControlTransfer: function (vUrb, packet) {
      vUrb.cntlTransfer = {};

      vUrb.cntlTransfer.PipeHandle = packet.readUint32();
      vUrb.cntlTransfer.TransferFlags = packet.readUint32();
      vUrb.cntlTransfer.TransferBufferLength = packet.readUint32();
      vUrb.cntlTransfer.TransferBuffer = packet.readUint32();
      vUrb.cntlTransfer.TransferBufferMDL = packet.readUint32();
      vUrb.cntlTransfer.UrbLink = packet.readUint32();
      vUrb.cntlTransfer.hca = packet.readArray(32);
      // 8 byte for SetupPacket[8]
      vUrb.cntlTransfer.bmRequestType = packet.readUint8();
      vUrb.cntlTransfer.bRequest = packet.readUint8();
      vUrb.cntlTransfer.wValue = packet.readUint16();
      vUrb.cntlTransfer.wIndex = packet.readUint16();
      vUrb.cntlTransfer.wLength = packet.readUint16();
   },

   __handleSelectConfiguration: function (vUrb, packet) {
      vUrb.selectConfig = {};

      vUrb.selectConfig.ConfigurationDescriptor = packet.readUint32();
      vUrb.selectConfig.ConfigurationHandle = packet.readUint32();

      vUrb.selectConfig.intef = [];
      const i = 0;
      while (packet.bytesRemaining() > vUrb.urb.transferBuffer2Length) {
         //USBD_INTERFACE_INFORMATION
         vUrb.selectConfig.intef[i] = {};
         vUrb.selectConfig.intef[i].Length = packet.readUint16();
         vUrb.selectConfig.intef[i].InterfaceNumber = packet.readUint8();
         vUrb.selectConfig.intef[i].AlternateSetting = packet.readUint8();

         //These fields are filled in by USBD
         vUrb.selectConfig.intef[i].Class = packet.readUint8();
         vUrb.selectConfig.intef[i].Subclass = packet.readUint8();
         vUrb.selectConfig.intef[i].Protocol = packet.readUint8();
         vUrb.selectConfig.intef[i].Reserved = packet.readUint8();
         vUrb.selectConfig.intef[i].InterfaceHandle = packet.readUint32();
         vUrb.selectConfig.intef[i].NumberOfPipes = packet.readUint32();

         //USBD_PIPE_INFORMATION
         vUrb.selectConfig.intef[i].pipe = [];
         for (let j = 0; j < vUrb.selectConfig.intef[i].NumberOfPipes; j++) {
            vUrb.selectConfig.intef[i].pipe[j] = {};
            const pipe = vUrb.selectConfig.intef[i].pipe[j];
            pipe.MaximumPacketSize = packet.readUint16();
            pipe.EndpointAddress = packet.readUint8();
            pipe.Interval = packet.readUint8();
            pipe.PipeType = packet.readUint8();
            packet.readUint8();
            packet.readUint16();
            pipe.PipeHandle = packet.readUint32();
            pipe.MaximumTransferSize = packet.readUint32();
            pipe.PipeFlags = packet.readUint32();
         }
      }
   },

   __handleCntlVendorClass: function (vUrb, packet) {
      vUrb.CntlVendorClass = {};

      vUrb.CntlVendorClass.Reserved = packet.readUint32();
      vUrb.CntlVendorClass.TransferFlags = packet.readUint32();
      vUrb.CntlVendorClass.TransferBufferLength = packet.readUint32();
      vUrb.CntlVendorClass.TransferBuffer = packet.readUint32();
      vUrb.CntlVendorClass.TransferBufferMDL = packet.readUint32();
      vUrb.CntlVendorClass.UrbLink = packet.readUint32();
      vUrb.CntlVendorClass.hca = packet.readArray(32);
      vUrb.CntlVendorClass.RequestTypeReservedBits = packet.readUint8();
      vUrb.CntlVendorClass.Request = packet.readUint8();
      vUrb.CntlVendorClass.Value = packet.readUint16();
      vUrb.CntlVendorClass.Index = packet.readUint16();
      vUrb.CntlVendorClass.Reserved1 = packet.readUint16();
   },

   __handleGetStatusFromDevice: function (vUrb, packet) {
      vUrb.getStatus = {};

      vUrb.getStatus.Reserved = packet.readUint32();
      vUrb.getStatus.Reserved0 = packet.readUint32();
      vUrb.getStatus.TransferBufferLength = packet.readUint32();
      vUrb.getStatus.TransferBuffer = packet.readUint32();
      vUrb.getStatus.TransferBufferMDL = packet.readUint32();
      vUrb.getStatus.UrbLink = packet.readUint32();
      vUrb.getStatus.hca = packet.readArray(32);
      vUrb.getStatus.Reserved1 = packet.readUint32();
      vUrb.getStatus.index = packet.readUint16();
      vUrb.getStatus.Reserved2 = packet.readUint16();
   },

   __handleGetMsFeatureDescriptor: function (vUrb, packet) {
      vUrb.msFeatureDesc = {};
      vUrb.msFeatureDesc.Reserved = packet.readUint32();
      vUrb.msFeatureDesc.Reserved0 = packet.readUint32();
      vUrb.msFeatureDesc.TransferBufferLength = packet.readUint32();
      vUrb.msFeatureDesc.TransferBuffer = packet.readUint32();
      vUrb.msFeatureDesc.TransferBufferMDL = packet.readUint32();
      vUrb.msFeatureDesc.UrbLink = packet.readUint32();
      vUrb.msFeatureDesc.hca = packet.readArray(32);
      vUrb.msFeatureDesc.recipient = packet.readUint8();
      vUrb.msFeatureDesc.Reserved2 = packet.readUint8();
      vUrb.msFeatureDesc.interfaceNumber = packet.readUint8();
      vUrb.msFeatureDesc.msPageIndex = packet.readUint8();
      vUrb.msFeatureDesc.msFeatureDescIndex = packet.readUint16();
      vUrb.msFeatureDesc.Reserved3 = packet.readUint16();
   },

   __handlePipeRequest: function (vUrb, packet) {
      vUrb.pipeRequest = {};
      vUrb.pipeRequest.PipeHandle = packet.readUint32();
      vUrb.pipeRequest.reserved = packet.readUint32();
   },

   __handleSyncResetPipeAndClearStall: function (vUrb, packet) {
      vUrb.resetPipe = {};
      vUrb.resetPipe.PipeHandle = packet.readUint32();
      vUrb.resetPipe.Reserved = packet.readUint32();
   },

   __processUrb: function (vUrb, packet) {
      //common urb request header
      if (!this.__parseUrbCommonHeader(vUrb, packet)) {
         vUrb.status = false;
         Logger.error(UsbProtocol.LOG + "__parseUrbCommonHeader failed");
         return;
      }

      //windows common urb header
      this.__parseUrbHeader(vUrb, packet);
      Logger.info(
         UsbProtocol.LOG +
            " UrbMessage function type -- (" +
            vUrb.UrbHeader.function +
            ") " +
            this.getUrbFunctionType(vUrb.UrbHeader.function)
      );
      //urb transication
      switch (vUrb.UrbHeader.function) {
         case UsbProtocol.URB_FUNCTION.BULK_OR_INTERRUPT_TRANSFER:
            this.__handleBulkOrInterruptTransfer(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_DEVICE:
         case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_INTERFACE:
            this.__handleGetDescriptorTransfer(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.SELECT_CONFIGURATION:
            this.__handleSelectConfiguration(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.CLASS_INTERFACE:
         case UsbProtocol.URB_FUNCTION.CLASS_DEVICE:
         case UsbProtocol.URB_FUNCTION.CLASS_ENDPOINT:
         case UsbProtocol.URB_FUNCTION.VENDOR_DEVICE:
         case UsbProtocol.URB_FUNCTION.VENDOR_INTERFACE:
         case UsbProtocol.URB_FUNCTION.VENDOR_ENDPOINT:
            this.__handleCntlVendorClass(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.CONTROL_TRANSFER:
            this.__handleControlTransfer(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.GET_STATUS_FROM_DEVICE:
            this.__handleGetStatusFromDevice(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.GET_MS_FEATURE_DESCRIPTOR:
            this.__handleGetMsFeatureDescriptor(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.ABORT_PIPE:
         case UsbProtocol.URB_FUNCTION.TAKE_FRAME_LENGTH_CONTROL:
         case UsbProtocol.URB_FUNCTION.RELEASE_FRAME_LENGTH_CONTROL:
         case UsbProtocol.URB_FUNCTION.GET_FRAME_LENGTH:
         case UsbProtocol.URB_FUNCTION.SET_FRAME_LENGTH:
            this.__handlePipeRequest(vUrb, packet);
            break;
         case UsbProtocol.URB_FUNCTION.SYNC_RESET_PIPE_AND_CLEAR_STALL:
            this.__handleSyncResetPipeAndClearStall(vUrb, packet);
            break;
         default:
            Logger.error(UsbProtocol.LOG + "--------------------------------");
            Logger.error(UsbProtocol.LOG + "|");
            Logger.error(
               UsbProtocol.LOG + "=> URBMessage function type (" + vUrb.UrbHeader.function + ") not implemented"
            );
            Logger.error(UsbProtocol.LOG + "|");
            Logger.error(UsbProtocol.LOG + "--------------------------------");
            vUrb.status = false;
            return;
      }
   },

   __processCancelUrb: function (vUrb, packet) {
      //read the padding and print it.
      const padding = packet.readArray(packet.bytesRemaining());
      Logger.debug(UsbProtocol.LOG + "__processCancelUrb pandding" + padding);
   },

   handleUrbMessage: function (packet) {
      const vUrb: any = {};
      vUrb.status = true;
      this.__parseVhubItem(vUrb, packet);
      if (vUrb.itemType === UsbProtocol.VHUBITEM.Urb) {
         this.__processUrb(vUrb, packet);
      } else if (vUrb.itemType === UsbProtocol.VHUBITEM.Ioctl) {
         vUrb.Ioctl = {};
         vUrb.Ioctl.ioctl = packet.readUint32();
      } else if (vUrb.itemType === UsbProtocol.VHUBITEM.CancelNotification) {
         vUrb.CancelNotification = {};
         vUrb.CancelNotification.cancelItemId = packet.readUint32();
         this.__processCancelUrb(vUrb, packet);
      } else {
         vUrb.status = false;
         Logger.error(UsbProtocol.LOG + "-----------------------------------");
         Logger.error(UsbProtocol.LOG + "|");
         Logger.error(UsbProtocol.LOG + "invalid VHUBITEM item");
         Logger.error(UsbProtocol.LOG + "|");
         Logger.error(UsbProtocol.LOG + "-----------------------------------");
      }
      return vUrb;
   },

   constructIoctlReply: function (vUrb, packet) {
      const reply = WMKS.Packet.createNewPacketLE();
      let result = 0;
      reply.writeUint32(vUrb.itemId);
      reply.writeUint32(0);

      if (vUrb.Ioctl.ioctl === 0x220013) {
         // USB_GET_PORT_STATUS
         result = 3;
      } else if (vUrb.Ioctl.ioctl === 0x220007) {
         // USB_RESET_PORT
      } else if (vUrb.Ioctl.ioctl === 0x220017) {
         // USB_ENABLE_PORT
      } else {
         Logger.error(UsbProtocol.LOG + "Invalid ioctrol" + vUrb.Ioctl.ioctl.toString(16));
      }
      if (result !== 0) {
         reply.writeUint32(4);
         reply.writeUint32(result);
      } else {
         reply.writeUint32(0);
      }
      return reply;
   },

   _constructCommonUrbReply: function (vUrb, data: Uint8Array, reply, status = 0) {
      reply.writeUint32(vUrb.itemId);
      reply.writeUint32(status);
      if (data) {
         reply.writeUint32(vUrb.urb.transferBuffer1Length + data.length);
      } else {
         reply.writeUint32(vUrb.urb.transferBuffer1Length);
      }
      reply.writeUint16(vUrb.UrbHeader.length);
      reply.writeUint16(vUrb.UrbHeader.function);
      reply.writeUint32(0); //line 477 of winusb.h
      reply.writeUint32(vUrb.UrbHeader.deviceHandle);
      reply.writeUint32(vUrb.UrbHeader.usbdFlag);
   },

   constructBulkOrInterruptTransferReply: function (vUrb, data: Uint8Array, written) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, data, reply);

      reply.writeUint32(vUrb.bulkOrInterruptTransfer.PipeHandle);
      reply.writeUint32(vUrb.bulkOrInterruptTransfer.TransferFlags);
      if (data) {
         reply.writeUint32(data.length);
      } else {
         reply.writeUint32(written);
      }
      reply.writeUint32(vUrb.bulkOrInterruptTransfer.TransferBuffer);
      reply.writeUint32(vUrb.bulkOrInterruptTransfer.TransferBufferMDL);
      reply.writeUint32(vUrb.bulkOrInterruptTransfer.UrbLink);
      reply.writeArray(vUrb.bulkOrInterruptTransfer.hca);
      if (data) {
         reply.writeArray(data);
      }
      return reply;
   },

   constructSelectConfigurationReply: function (vUrb, data: Uint8Array, interfaces: USBInterface[], usbHandle) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, data, reply);

      //desc
      reply.writeUint32(vUrb.selectConfig.ConfigurationDescriptor);
      reply.writeUint32(usbHandle); //test

      //USBD_INTERFACE_INFORMATION
      for (let i = 0; i < interfaces.length; i++) {
         const inter: USBInterface = interfaces[i];
         /*
          * chrome browser - w3c usb implementation workaround.
          * https://wicg.github.io/webusb/
          * The alternate attribute SHALL be set to the USBAlternateInterface that
          * is currently selected for this interface, which by default SHALL
          * be the one with bAlternateSetting equal to 0.
          *
          * But this value is always zero in chrome browser.
          * (before/after select configuration)
          */
         let alternate: USBAlternateInterface = inter.alternate;
         if (alternate === null || alternate.alternateSetting !== 0) {
            for (const alter of inter.alternates) {
               if (alter.alternateSetting === 0) {
                  alternate = alter;
                  break;
               }
            }
         }
         if (alternate === null || alternate.alternateSetting !== 0) {
            continue;
         }
         reply.writeUint16(16 + alternate.endpoints.length * 20);
         reply.writeUint8(inter.interfaceNumber);
         reply.writeUint8(alternate.alternateSetting);
         reply.writeUint8(alternate.interfaceClass);
         reply.writeUint8(alternate.interfaceSubclass);
         reply.writeUint8(alternate.interfaceProtocol);
         reply.writeUint8(vUrb.selectConfig.Reserved);
         reply.writeUint32(i); //test
         reply.writeUint32(alternate.endpoints.length);

         for (let j = 0; j < alternate.endpoints.length; j++) {
            reply.writeUint16(alternate.endpoints[j].packetSize);
            /*
             * w3c and chrome.usb.* differences.
             * W3C standard.
             * https://wicg.github.io/webusb/
             * Each endpoint within a particular device configuration SHALL have a unique
             * combination of endpointNumber and direction. The endpointNumber MUST equal
             * the 4 least significant bits of the bEndpointAddress field of the endpoint
             * descriptor defining the endpoint.
             *
             * chrome.usb.* API
             * https://developer.chrome.com/apps/usb#type-EndpointDescriptor
             *
             */
            if (alternate.endpoints[j].direction === "in") {
               reply.writeUint8(alternate.endpoints[j].endpointNumber | 0x80);
            } else {
               reply.writeUint8(alternate.endpoints[j].endpointNumber);
            }

            //pollingInterval default to zero
            reply.writeUint8(0);
            if (alternate.endpoints[j].type === "interrupt") {
               reply.writeUint8(3);
            } else if (alternate.endpoints[j].type === "isochronous") {
               reply.writeUint8(1);
            } else if (alternate.endpoints[j].type === "bulk") {
               reply.writeUint8(2);
            } else {
               reply.writeUint8(0);
            }

            reply.writeUint8(0); //padding
            reply.writeUint16(0); //padding
            /*
             * w3c and chrome.usb.* differences.
             * W3C standard.
             * https://wicg.github.io/webusb/
             * Each endpoint within a particular device configuration SHALL have a unique
             * combination of endpointNumber and direction. The endpointNumber MUST equal
             * the 4 least significant bits of the bEndpointAddress field of the endpoint
             * descriptor defining the endpoint.
             *
             * chrome.usb.* API
             * https://developer.chrome.com/apps/usb#type-EndpointDescriptor
             *
             */
            if (alternate.endpoints[j].direction === "in") {
               reply.writeUint32(alternate.endpoints[j].endpointNumber | 0x80); // endpoint address.
            } else {
               reply.writeUint32(alternate.endpoints[j].endpointNumber);
            }
            reply.writeUint32(VUSB_BATCH_SIZE);
            reply.writeUint32(0);
         }
      }

      reply.writeArray(data);
      return reply;
   },

   constructControlTransferReply: function (vUrb, data: Uint8Array, written: number) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, data, reply);

      //control desc
      reply.writeUint32(vUrb.cntlTransfer.PipeHandle);
      reply.writeUint32(vUrb.cntlTransfer.TransferFlags);
      if (data) {
         reply.writeUint32(data.length);
      } else {
         reply.writeUint32(written);
      }

      reply.writeUint32(vUrb.cntlTransfer.TransferBuffer);
      reply.writeUint32(vUrb.cntlTransfer.TransferBufferMDL);
      reply.writeUint32(vUrb.cntlTransfer.UrbLink);
      reply.writeArray(vUrb.cntlTransfer.hca);
      reply.writeUint8(vUrb.cntlTransfer.bmRequestType);
      reply.writeUint8(vUrb.cntlTransfer.bRequest);
      reply.writeUint16(vUrb.cntlTransfer.wValue);
      reply.writeUint16(vUrb.cntlTransfer.wIndex);
      reply.writeUint16(vUrb.cntlTransfer.wLength);
      reply.writeArray(data);
      return reply;
   },

   constructGetStatusFromDeviceReply: function (vUrb, data: Uint8Array) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, data, reply);

      reply.writeUint32(vUrb.getStatus.Reserved);
      reply.writeUint32(vUrb.getStatus.Reserved0);
      reply.writeUint32(data.length);
      reply.writeUint32(vUrb.getStatus.TransferBuffer);
      reply.writeUint32(vUrb.getStatus.TransferBufferMDL);
      reply.writeUint32(vUrb.getStatus.UrbLink);
      reply.writeArray(vUrb.getStatus.hca);
      reply.writeUint32(vUrb.getStatus.Reserved1);
      reply.writeUint16(vUrb.getStatus.index);
      reply.writeUint16(vUrb.getStatus.Reserved2);
      reply.writeArray(data);
      return reply;
   },

   constructGetDescriptorReply: function (vUrb, data: Uint8Array, packet) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, data, reply);

      reply.writeUint32(vUrb.cntlDesc.reserved);
      reply.writeUint32(vUrb.cntlDesc.reserved0);
      reply.writeUint32(data.length);
      reply.writeUint32(vUrb.cntlDesc.TransferBuffer);
      reply.writeUint32(vUrb.cntlDesc.TransferBufferMDL);
      reply.writeUint32(vUrb.cntlDesc.UrbLink);
      reply.writeArray(vUrb.cntlDesc.hca);
      reply.writeUint16(0);
      reply.writeUint8(vUrb.cntlDesc.Index);
      reply.writeUint8(vUrb.cntlDesc.DescriptorType);
      reply.writeUint16(vUrb.cntlDesc.LanguageId);
      reply.writeUint16(vUrb.cntlDesc.reserved2);
      reply.writeArray(data);
      return reply;
   },

   constructGetMsFeatureDescriptorReply: function (vUrb, data) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, data, reply, 0xc0000000);

      reply.writeUint32(vUrb.msFeatureDesc.Reserved);
      reply.writeUint32(vUrb.msFeatureDesc.Reserved0);
      reply.writeUint32(vUrb.msFeatureDesc.TransferBufferLength);
      reply.writeUint32(vUrb.msFeatureDesc.TransferBuffer);
      reply.writeUint32(vUrb.msFeatureDesc.TransferBufferMDL);
      reply.writeUint32(vUrb.msFeatureDesc.UrbLink);
      reply.writeArray(vUrb.msFeatureDesc.hca);
      reply.writeUint8(vUrb.msFeatureDesc.recipient);
      reply.writeUint8(vUrb.msFeatureDesc.Reserved2);
      reply.writeUint8(vUrb.msFeatureDesc.interfaceNumber);
      reply.writeUint8(vUrb.msFeatureDesc.msPageIndex);

      reply.writeUint16(vUrb.msFeatureDesc.msFeatureDescIndex);
      reply.writeUint16(vUrb.msFeatureDesc.Reserved3);
      reply.writeArray(data);
      return reply;
   },

   constructPipeRequestReply: function (vUrb, data) {
      const reply = WMKS.Packet.createNewPacketLE();
      if (vUrb.UrbHeader.function === UsbProtocol.URB_FUNCTION.ABORT_PIPE) {
         this._constructCommonUrbReply(vUrb, data, reply);
      } else {
         this._constructCommonUrbReply(vUrb, data, reply, 0xc0000000);
      }
      reply.writeUint32(vUrb.pipeRequest.PipeHandle);
      reply.writeUint32(vUrb.pipeRequest.reserved);
      return reply;
   },

   constructGetClassInterfaceReply: function (vUrb, data: Uint8Array, written: number) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, data, reply);

      //control desc
      reply.writeUint32(vUrb.CntlVendorClass.Reserved);
      reply.writeUint32(vUrb.CntlVendorClass.TransferFlags);
      if (data !== null) {
         reply.writeUint32(data.length);
      } else {
         reply.writeUint32(written);
      }
      reply.writeUint32(vUrb.CntlVendorClass.TransferBuffer);
      reply.writeUint32(vUrb.CntlVendorClass.TransferBufferMDL);
      reply.writeUint32(vUrb.CntlVendorClass.UrbLink);
      reply.writeArray(vUrb.CntlVendorClass.hca);
      reply.writeUint8(vUrb.CntlVendorClass.RequestTypeReservedBits);
      reply.writeUint8(vUrb.CntlVendorClass.Request);
      reply.writeUint16(vUrb.CntlVendorClass.Value);
      reply.writeUint16(vUrb.CntlVendorClass.Index);
      reply.writeUint16(vUrb.CntlVendorClass.Reserved1);
      if (data !== null) {
         reply.writeArray(data);
      }
      return reply;
   },

   constructResetStallReply: function (vUrb) {
      const reply = WMKS.Packet.createNewPacketLE();
      this._constructCommonUrbReply(vUrb, null, reply);
      reply.writeUint32(vUrb.resetPipe.PipeHandle);
      reply.writeUint32(vUrb.resetPipe.Reserved);
      return reply;
   }
};
