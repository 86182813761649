/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Logger } from "@html-core";
/**
 * string-utils.js
 *
 * Utility functions for converting between JavaScript strings and
 * uint8Arrray objects.
 **/

export class StringUtils {
   /**
    * uint8ArrayToString
    *
    * Helper function used to convert a Uint8Array containing UTF-8 data to a string.
    *
    * @param array   Array to convert.
    * @return        Converted string.
    */
   public static uint8ArrayToString = (array: Uint8Array): string => {
      let str = "",
         CHUNK_SZ = 0x8000,
         c = [],
         i;
      try {
         /*
          * The "apply" method can only handle arrays up to a certain size, so we
          * split the array into large chunks and process them chunk by chunk.
          * The subarray method will safely return the remainder of the array if the
          * upper bound is higher than the array bound.
          */
         for (i = 0; i < array.length; i += CHUNK_SZ) {
            c.push(String.fromCharCode.apply(null, array.subarray(i, i + CHUNK_SZ)));
         }
         str = decodeURIComponent(escape(c.join("")));
      } catch (e) {
         Logger.error(e);
      }
      return str;
   };

   /**
    * stringToUint8Array
    *
    * Helper function used to convert a string to a Uint8Array.  Note that it is not
    * explicitly null-terminated, so consumers who are using this function to create
    * data to send over the network may need to append '\x00' if the server side is
    * expecting null-terminated string.
    *
    * This will not work for unmatched surrogate halves, but it is good enough for
    * us to use for now.
    *
    * @param string        String to convert.
    * @param nullTerminate Whether to null-terminate the string (unspecified means false).
    * @return              Converted array in UTF-8 form.
    */
   public static stringToUint8Array = (str: string, nullTerminate: boolean = false): Uint8Array => {
      let i = 0,
         utf8String,
         array;

      // Convert from UTF-16 to UTF-8.
      try {
         utf8String = unescape(encodeURIComponent(str));
      } catch (e) {
         return new Uint8Array(0);
      }

      // Copy the UTF-8 data to the Uint8Array.
      array = new Uint8Array(utf8String.length + (nullTerminate ? 1 : 0));
      for (i = 0; i < utf8String.length; i++) {
         array[i] = utf8String.charCodeAt(i);
      }

      // null-terminate if necessary.
      if (nullTerminate) {
         array[array.length] = 0;
      }
      return array;
   };

   /**
    * uint8ArrayIndexOf
    *
    * Helper function that does the equivalent of Array indexOf(), but for Uint8Array.
    *
    * @param array         Array to search
    * @param element       Element to search for
    * @param start         Optional start index to search (default is 0).
    * @return              First index of element if found, -1 otherwise.
    */
   public static uint8ArrayIndexOf = (array: any, element: number, start: number = 0) => {
      if (element >= 256 || start === -1) {
         return -1;
      }

      for (let i = start; i < array.length; i++) {
         if (array[i] === element) {
            return i;
         }
      }

      return -1;
   };

   /**
    * uint8ArrayTokenize
    *
    * Helper function that retrieves the subarray of a Uint8Array that begins at the
    * specified starting index and ends at first occurrence of the specified array
    * element (exclusive of the index where the element occurs).  If the element isn't
    * found, the rest of the array is returned.
    *
    * @param array         Array to search
    * @param element       Element to search for
    * @param start         Optional start index to search (default is 0).
    * @return              Object containing:
    *                       .index: First index of element if found, -1 otherwise.
    *                       .result: Subarray as mentioned in function description.
    */
   public static uint8ArrayTokenize = (array: any, element: number, start: number = 0) => {
      const index = StringUtils.uint8ArrayIndexOf(array, element, start);
      const resultArray = array.subarray(start, index);
      return { index: index, result: resultArray };
   };

   public static uInt8ArrayToUInt32(arr, isBigEndiann) {
      let res = 0;
      if (isBigEndiann) {
         for (let i = 0; i < arr.length; i++) {
            res += arr[i] * 256 ** i;
         }
      } else {
         for (let i = arr.length - 1; i >= 0; i--) {
            res += arr[i] * 256 ** i;
         }
      }
      return res;
   }
}
