/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 * @hidden
 * @format
 **/
export type hidden = 1;

export type VmwHorizonClientAPIVersion = 2;

export type VmwHorizonClientTypes = "ChromeNative";

export type VmwHorizonClientVChanAPIVersion = 2;

export type VmwHorizonClientEventsVersion = 2;

export type VmwHorizonLaunchItemType =
   | "HorizonDesktop"
   | "HorizonApp"
   //XenApp |
   //SaaSApp |
   | "HorizonAppSession" // Disconnected app session
   | "DesktopShadowSession"
   | "AppShadowSession";

/*
 * subset of set coresponding to
  Unknown = 0,
  Disclaimer,
  SecurID_Passcode,
  SecurID_NextTokencode,
  SecurID_PIN_Change,
  SecurID_Wait,
  Windows_Password,
  Windows_Password_Expired,
  Certificate,
  SAML,
  GSSAPI,
  Unauthenticated_Access
 */
export type VmwHorizonClientAuthType = "Not_Allowed" | "Disclaimer" | "SAML" | "Windows_Password"; //used for indicate authentication error for now

// bitmask, should be used to parse the supported protocols from the "protocol number" for entitlement items.
// when launching items, should only use one protocol.
export type VmwHorizonClientProtocol = "Default" | "Blast"; // Chrome client doesn't support "RDP" or "PCoIP"

export type VmwHorizonClientSessionType = "App" | "Desktop";

export type VmwHorizonClientDisplayType = "Default" | "WindowSmall" | "WindowLarge" | "Fullscreen" | "Multimonitor";

export type VmwHorizonServerType = "Horizon";
//WorkspaceOne

export type VmwHorizonClientSeamlessWindowType =
   | "None"
   | "Normal"
   | "Panel"
   | "Dialog"
   | "Menu"
   | "Tooltip"
   | "Splash"
   | "Toolbar"
   | "Dock"
   | "Desktop"
   | "Combobox"
   | "Widget"
   | "StartScreen"
   | "SlideInPanel"
   | "Taskbar"
   | "Metro"
   | "ShellSystemDialog";

export type VmwHorizonClientAllowedOperations = "Disconnect" | "Log off" | "Reset" | "Restart" | "Terminate";

export type VmwHorizonClientRemoteSessionOperation = "Disconnect";

export type VmwHorizonClientLogLevel = "None" | "Error" | "Warning" | "Info" | "Debug" | "Trace";
