/**
 * ******************************************************
 * Copyright (C) 2014-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { AsyncSubject } from "rxjs";
import { Injectable } from "@angular/core";

import Logger from "../../../core/libs/logger";
import util from "../../jscdk/util";
import { OsModel } from "@html-core";
import { clientUtil } from "@html-core";
import { BaseViewClientModel } from "./baseviewclient-model";
import { ClientIdService } from "./clientid-model";
import { HorizonClientInfo } from "../service/client-info.service";
import { PreDataSetModel } from "./pre-data-set-model";
import { PolicyModel } from "../../../chrome-client/launcher/server-connect/policy-model";
import { FeatureConfigs } from "./feature-configs";

/**
 * Most of data in viewClientModel can be read directly, except 'client info'.
 * For both chrome and html client, client info is init async.
 * As a result, it should return promise when other module tries to get client
 * info from viewClientModel.
 */
@Injectable({
   providedIn: "root"
})
export class ViewClientModel extends BaseViewClientModel {
   public buildNum: string = __BUILD_NUMBER__;
   public enableDownloadInstaller: boolean = true;
   public nativeInstallerLink: string = "https://www.vmware.com/go/viewclients";
   public platform: string = "Unknown";
   public mIsIEMobile: boolean = false;

   public mIsIE: boolean = false;
   public mIsEdge: boolean = false;
   public mIsChrome: boolean = false;
   public mIsWebKit: boolean = false;
   public mIsOpera: boolean = false;
   public mIsGecko: boolean = false;
   public mIsSamsungTV: boolean = false;
   public mIsSafari: boolean = false;
   public mIsIPhone: boolean = false;
   public mIsIOSSafari: boolean = false;
   public mIsAirWatchBrowser: boolean = false;
   public mIsTouchDevice: boolean = false;
   public mIsMacOS: boolean = false;
   public mIsAndroid: boolean = false;
   public mIsIOS: boolean = false;
   public mIsVrDevice: boolean = false;

   public browserName: string;
   public mVersionIE: any = 0;
   public mVersionWebKit: number;
   public mVersionOpera: number;
   public mVersionChrome: number;
   public mVersionGecko: number;

   public disableReconnectForWS1: boolean = false;
   public disableCEIP: boolean = false;
   public contextPath: string = null;

   public clientInfoSubject = new AsyncSubject();

   public enableCredentialCleanupForHTMLAccess: boolean = false;
   public clientHideServerInformation: boolean = false;
   public clientHideDomainList: boolean = false;
   public isAnonymousMode: boolean = false;

   public osModel: OsModel = null;
   public machineName: string = null;
   public osLiteral: string;
   public csrfCheck: boolean = false;
   public disableIPv6: boolean = false;
   public enableKeyMapping: boolean = true;
   public useWindowReplacementApi: boolean = true;
   public enterFullscreenIfOneMonitor: boolean = true;
   public AssetID: string = null;
   public SerialNum: string = null;
   public useAssetIdReplaceMachineName = false;
   public cdrEntry;
   public ipAddress: string = null;
   public instanceId: string = null;
   public enableNetworkIndicator: boolean = true;
   public networkStateTcpRttMSLow: number;
   public networkStateTcpRttMSHigh: number;
   public networkStateQualityScoreTcpThresholdGood: number;
   public networkStateQualityScoreTcpThresholdPoor: number;
   public networkStateRttCheckPeriodMs: number;
   public enableRTAVH264Codec: boolean = true;
   public enableRTAVOpusCodec: boolean = true;
   public enableRTAVDTX: boolean = true;
   public enableExtendedAPI: boolean = false;
   public hardwareAccelerationOption: string = "no-preference";
   public enableBlastCodec: boolean = false;

   constructor(
      private clientIdService: ClientIdService,
      private preDataSetModel: PreDataSetModel,
      private policyModel: PolicyModel,
      private featureConfigs: FeatureConfigs
   ) {
      super(clientUtil.isChromeClient());
      this.clientID = this.clientIdService.getClientID();
      this.osModel = new OsModel();
      this.instanceId = this.clientIdService.generateUID();
   }

   public initClientInfo = async (info: HorizonClientInfo) => {
      // Do NOT change the init order here
      this.initOSInfo(info);
      this.initBrowserInfo();
      this.initBrowserName();
      this.initPlatform();
      this.initRebrandInfo(info);
      this.enableRTAVH264Codec = info.enableRTAVH264Codec;
      this.enableRTAVOpusCodec = info.enableRTAVOpusCodec;
      this.enableRTAVDTX = info.enableRTAVDTX;
      this.enableExtendedAPI = info.enableExtendedAPI;
      this.hardwareAccelerationOption = info.hardwareAccelerationOption;
      Logger.setLogLevel(info.logLevel);
      Logger.debug(
         "[RTAV]: video hardware acceleration option value set to " +
            this.hardwareAccelerationOption +
            "in viewClientModel"
      );
      if (clientUtil.isChromeClient()) {
         this.initPolicy();
         this.enableRTAVH264Codec = await this._getEnableRTAVH264CodecFromPolicy();
         this.enableRTAVOpusCodec = await this._getEnableRTAVOpusCodecFromPolicy();
         this.enableRTAVDTX = await this._getEnableRTAVDTXFromPolicy();
         this.hardwareAccelerationOption = await this._getHardwareAccelerationOptionFromPolicy();
         Logger.debug(
            "[RTAV]: video hardware acceleration option value set to " +
               this.hardwareAccelerationOption +
               "in chrome client viewClientModel"
         );
         this.enableBlastCodec = await this._getEnableBlastCodec();
         Logger.debug(
            "[BlastCodec]: Blast Codec Kill Switch set to " + this.enableBlastCodec + "in chrome client viewClientModel"
         );
         this.ipAddress = await this._getClientIP();
      } else {
         this.enableBlastCodec = info.enableBlastCodec && (this.mIsChrome || this.mIsEdge);
      }
      this.logLevel = info.logLevel;
      this.disableReconnectForWS1 = info.disableReconnectForWS1;
      this.disableCEIP = info.disableCEIP;
      this.acceptLanguage = info.acceptLanguage;
      this.contextPath = info.contextPath;
      this.csrfCheck = info.csrfCheck;
      this.disableIPv6 = info.disableIPv6;
      this.enableKeyMapping = info.enableKeyMapping;
      this.useWindowReplacementApi = !this.mIsAirWatchBrowser && info.useWindowReplacementApi;
      this.enterFullscreenIfOneMonitor = info.enterFullscreenIfOneMonitor;
      this.enableNetworkIndicator = info.enableNetworkIndicator;
      this.networkStateTcpRttMSLow = info.networkStateTcpRttMSLow;
      this.networkStateTcpRttMSHigh = info.networkStateTcpRttMSHigh;
      this.networkStateQualityScoreTcpThresholdGood = info.networkStateQualityScoreTcpThresholdGood;
      this.networkStateQualityScoreTcpThresholdPoor = info.networkStateQualityScoreTcpThresholdPoor;
      this.networkStateRttCheckPeriodMs = info.networkStateRttCheckPeriodMs;
      this.featureConfigs.setConfig("KillSwitch-WasmBlast", this.enableBlastCodec);
      if (info.vrCheck !== undefined) {
         //Only when the flag is set, use the flag value, only take effect on Linux
         //If there is no flag value, use the API result
         Logger.debug("There is kill-switch for VR from server: " + info.vrCheck);
         // In case to impact the experience on Windows/Mac/Chromebook, only includes Linux and Android
         this.mIsVrDevice = info.vrCheck && (this.platform === "Linux" || this.platform === "Android");
      } else {
         Logger.debug("No kill-switch for VR from server.");
      }
      clientUtil.isVrDevice = this.mIsVrDevice;

      /*
       * User is able to customize native installers download links
       * in the configuration file.
       *
       * If no download links are customized in the configuration file,
       * use the default download link: https://www.vmware.com/go/viewclients
       */
      this.nativeInstallerLink = info.installerLink;
      this.enableDownloadInstaller = info.enableDownloadInstaller;

      util.l10N.setLocaleAndLoad(this.acceptLanguage);
      if (this.disableCEIP) {
         this.preDataSetModel.setDataValue("dataSharingAllowed", false);
      }

      this.clientInfoSubject.next(true);
      this.clientInfoSubject.complete();

      Logger.debug("HTML5 Running Env = \n" + this._LoggedInfo());
   };

   private _LoggedInfo = () => {
      // Only add necessary message without privacy
      const message = {
         buildNum: this.buildNum,
         ua: window.navigator.userAgent.toLowerCase(),
         vs: window.navigator.appVersion.toString(),
         acceptLanguage: this.acceptLanguage,
         rebrandEnable: this.rebrandEnable
      };
      return JSON.stringify(message);
   };

   private _getEnableRTAVH264CodecFromPolicy = (): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         this.policyModel.getModel().then((model) => {
            if (!model) {
               resolve(true);
            } else {
               if (
                  model.getCommonAdminSettings()?.enableRTAVH264Codec === false ||
                  model.getCommonAdminSettings()?.enableRTAVH264Codec === true
               ) {
                  resolve(model.getCommonAdminSettings().enableRTAVH264Codec);
               } else {
                  resolve(true);
               }
            }
         });
      });
   };

   private _getEnableRTAVOpusCodecFromPolicy = (): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         this.policyModel.getModel().then((model) => {
            if (!model) {
               resolve(true);
            } else {
               if (
                  model.getCommonAdminSettings()?.enableRTAVOpusCodec === false ||
                  model.getCommonAdminSettings()?.enableRTAVOpusCodec === true
               ) {
                  resolve(model.getCommonAdminSettings().enableRTAVOpusCodec);
               } else {
                  resolve(true);
               }
            }
         });
      });
   };

   private _getEnableRTAVDTXFromPolicy = (): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         this.policyModel.getModel().then((model) => {
            if (!model) {
               resolve(true);
            } else {
               if (
                  model.getCommonAdminSettings()?.enableRTAVDTX === false ||
                  model.getCommonAdminSettings()?.enableRTAVDTX === true
               ) {
                  resolve(model.getCommonAdminSettings().enableRTAVDTX);
               } else {
                  resolve(true);
               }
            }
         });
      });
   };

   private _getHardwareAccelerationOptionFromPolicy = (): Promise<string> => {
      return new Promise((resolve, reject) => {
         this.policyModel.getModel().then((model) => {
            if (!model) {
               resolve("no-preference");
            } else {
               if (
                  model.getCommonAdminSettings()?.hardwareAccelerationOption === "prefer-hardware" ||
                  model.getCommonAdminSettings()?.hardwareAccelerationOption === "prefer-software"
               ) {
                  resolve(model.getCommonAdminSettings().hardwareAccelerationOption);
               } else {
                  resolve("no-preference");
               }
            }
         });
      });
   };

   private _getEnableBlastCodec = (): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         this.policyModel.getModel().then((model) => {
            if (!model) {
               resolve(false);
            } else {
               if (
                  model.getCommonAdminSettings()?.enableBlastCodec === false ||
                  model.getCommonAdminSettings()?.enableBlastCodec === true
               ) {
                  resolve(model.getCommonAdminSettings().enableBlastCodec);
               } else {
                  resolve(false);
               }
            }
         });
      });
   };

   private _getClientIP = (): Promise<string> => {
      return new Promise((resolve, reject) => {
         // @ts-ignore error TS2551
         // Property 'socket' does not exist on type 'typeof chrome'.
         chrome.socket.getNetworkList((interfaces) => {
            let ipAddress: string = null;
            for (let i = 0; i < interfaces.length; i++) {
               if (interfaces[i].address.split(".").length === 4) {
                  //only pick IPv4
                  ipAddress = interfaces[i].address;
                  break;
               }
            }
            if (ipAddress) {
               resolve(ipAddress);
            } else {
               reject();
            }
         });
      });
   };

   public onFailReadingClientInfo = () => {
      /**
       * Since VADC don't have info.jsp, and BSG is only allow access using
       * specified address for info.jsp, it's possible of failing to get the
       * info.jsp. When that happens, the client will continue int work
       * as before.
       * Don't forget the init work here!
       */

      this.initBrowserInfo();
      this.initBrowserName();
      this.initPlatform();

      this.clientInfoSubject.next(true);
      this.clientInfoSubject.complete();
   };

   public initChromeDeviceInfo = (chromeDeviceInfo) => {
      if (chromeDeviceInfo.AssetID) {
         this.AssetID = chromeDeviceInfo.AssetID;
      }
      if (chromeDeviceInfo.SerialNum) {
         this.SerialNum = chromeDeviceInfo.SerialNum;
      }
   };

   private initBrowserName = () => {
      if (this.osModel.mIsAndroid) {
         this.browserName = "An";
      } else if (this.mIsIEMobile) {
         this.browserName = "IEM";
      } else if (this.osModel.mIsIOS) {
         this.browserName = "IOS";
      } else if (this.mIsEdge) {
         this.browserName = "Edge";
      } else if (this.mIsIE) {
         // mobile browsers should not enter here or after
         this.browserName = "IE";
      } else if (this.osModel.mIsChromeOS) {
         this.browserName = "CRX";
      } else if (this.mIsChrome) {
         this.browserName = "Chr";
      } else if (this.mIsWebKit) {
         this.browserName = "Saf";
      } else if (this.mIsOpera) {
         this.browserName = "Op";
      } else if (this.mIsGecko) {
         // webkit will not enter here
         this.browserName = "FF";
      } else if (this.mIsSamsungTV) {
         this.browserName = "STV";
      } else {
         this.browserName = "UNK";
      }
   };

   private initBrowserInfo = () => {
      // Detect browser version.
      // the browser and version detection copied from original code,
      // but it's better that we can have a browser service to do this job
      const ua = window.navigator.userAgent.toLowerCase();
      const vs = window.navigator.appVersion.toString();

      this.mIsIE = ua.indexOf("msie") > -1;
      this.mVersionIE = this.mIsIE ? parseInt(vs.slice(vs.indexOf("MSIE ") + "MSIE ".length).split(";")[0], 10) : null;

      /*
       * The user-agent string for Internet Explorer 11 looks like:
       * 'mozilla/5.0 (windows nt 6.1; wow64; trident/7.0; rv:11.0) like gecko'.
       * It has removed 'MSIE' tokexn. Detect 'trident' instead.
       */
      if (!this.mIsIE) {
         this.mIsIE = ua.indexOf("trident") > -1;
         this.mVersionIE = this.mIsIE ? /rv[:\s]([\w.]+)/.exec(vs) : null;
         this.mVersionIE = this.mVersionIE ? this.mVersionIE[1] : null;
      }

      /*
       * The user-agent string for Internet Explorer 12/Edge looks like:
       * 'Mozilla/5.0 (Windows NT 10.0) AppleWebKit/537.36 (KHTML, like Gecko)
       * Chrome/39.0.2171.71 Safari/537.36 Edge/12.0'.
       * Detect 'Edge' here.
       */
      if (!this.mIsIE) {
         this.mIsIE = ua.indexOf("edge") > -1;
         //Old edge contains edge in userAgent,
         //while Chromium Edge contains edg in userAgent
         this.mIsEdge = ua.indexOf("edg") > -1;
         let edgeVersion = null;
         if (ua.indexOf("edge") > -1) {
            edgeVersion = parseFloat(ua.slice(ua.indexOf("edge")).split("/")[1].split(" ")[0]);
         } else if (ua.indexOf("edg") > -1) {
            edgeVersion = parseFloat(ua.slice(ua.indexOf("edg")).split("/")[1].split(" ")[0]);
         } else {
            edgeVersion = null;
         }
         this.mVersionIE = edgeVersion;
      }

      // clientModel version is * 100 (e.g. '533' instead of 5.3.3)
      this.mIsWebKit = ua.indexOf("applewebkit") > -1 && ua.indexOf("chrome") === -1;
      this.mIsSafari = this.mIsWebKit && ua.indexOf("safari") !== -1;
      this.mVersionWebKit = this.mIsWebKit
         ? parseInt(ua.slice(ua.indexOf("applewebkit/") + "applewebkit/".length), 10)
         : null;

      this.mIsOpera = ua.indexOf("opera/") > -1;
      this.mVersionOpera = this.mIsOpera ? parseInt(ua.slice(ua.indexOf("Opera/") + "Opera/".length), 10) : null;

      if (!this.mIsIE && !this.mIsEdge && !this.mIsOpera && ua.indexOf("applewebkit") !== -1) {
         let chromeIndex;
         if (ua.indexOf("chrome") > -1) {
            chromeIndex = ua.indexOf("chrome");
            this.mIsChrome = true;
         } else if (ua.indexOf("crios") > -1) {
            chromeIndex = ua.indexOf("crios");
            this.mIsChrome = true;
         }
         this.mVersionChrome = this.mIsChrome ? parseFloat(ua.slice(chromeIndex).split("/")[1].split(" ")[0]) : null;
      } else {
         this.mIsChrome = false;
      }

      // See:
      // https://developer.mozilla.org/en/Gecko_user_agent_string_reference
      // Also, Webkit says it's 'like Gecko', so we get a false positive
      // here.
      this.mIsGecko = !this.mIsWebKit && ua.indexOf("gecko") > -1;
      this.mVersionGecko = this.mIsGecko ? parseFloat(ua.slice(ua.indexOf("rv:") + "rv:".length)) : null;

      // Detect IE9+ mobile versions.
      this.mIsIEMobile = ua.indexOf("IEMobile") > -1 && this.mVersionIE > 8;

      this.mIsIPhone = ua.indexOf("iphone") > -1;

      if (this.osModel.mIsIOS) {
         // even chrome on iOS has 'safari' in user agent,
         // but chrome uses 'crios'.
         this.mIsIOSSafari = ua.indexOf("safari") > -1 && ua.indexOf("crios") === -1 && ua.indexOf("airwatch") === -1;
      } else {
         this.mIsIOSSafari = false;
      }

      this.mIsSamsungTV = ua.indexOf("tizen") > -1 && ua.indexOf("smart-tv") > -1 && ua.indexOf("samsungbrowser") > -1;

      this.mIsAirWatchBrowser = ua.indexOf("airwatch") > -1;

      this.mIsTouchDevice = this.osModel.mIsIOS || this.osModel.mIsAndroid || this.mIsSamsungTV;
      this.mIsMacOS = this.osModel.mIsMacOS;
      this.mIsAndroid = this.osModel.mIsAndroid;
      this.mIsIOS = this.osModel.mIsIOS;
      //@ts-ignore
      this.mIsVrDevice = !!(ua.indexOf("mobile vr") > -1 || (window.navigator.xr && !this.mIsChrome && !this.mIsEdge));
   };

   private initPlatform = () => {
      //'client-build for blast portal'
      if (!!this.osModel.mIsWin32 || !!this.osModel.mIsWin64 || !!this.osModel.mIsWinMobile) {
         this.platform = "Windows";
      } else if (!!this.osModel.mIsLinux32 || !!this.osModel.mIsLinux64) {
         if (this.mIsSamsungTV) {
            this.platform = "SamsungTV";
         } else {
            this.platform = "Linux";
         }
      } else if (this.osModel.mIsIOS) {
         this.platform = "iOS";
      } else if (this.osModel.mIsMacOS) {
         this.platform = "Mac";
      } else if (this.osModel.mIsChromeOS) {
         this.platform = "ChromeBook";
      } else if (this.osModel.mIsAndroid) {
         this.platform = "Android";
      }
      this.machineName = this.browserName + ":" + this.platform;
   };

   private initOSInfo = (data: HorizonClientInfo) => {
      this.osModel.detectOS(data.os);
      this.osLiteral = this.osModel.getOsLiteral(data.os);
   };

   private initRebrandInfo = (data: HorizonClientInfo) => {
      if (data.rebrandName) {
         this.rebrandName = data.rebrandName;
         this.rebrandEnable = true;
      } else {
         this.rebrandEnable = false;
      }

      if (data.rebrandHelpUrl) {
         this.rebrandHelpUrl = data.rebrandHelpUrl;
         // Add https:// to fix bug 2177688
         if (this.rebrandHelpUrl.indexOf("://") === -1) {
            this.rebrandHelpUrl = "https://" + this.rebrandHelpUrl;
         }
      }

      // below link seems not used for now.
      if (data.rebrandGotoForumUrl) {
         this.rebrandGotoForumUrl = data.rebrandGotoForumUrl;
      }
   };

   private initPolicy() {
      this.policyModel.getModel().then((model) => {
         if (model?.getCommonAdminSettings()?.useAssetIdReplaceMachineName) {
            this.useAssetIdReplaceMachineName = true;
            Logger.info("Enable useAssetIdReplaceMachineName configuration.");
         }
      });
   }
}
