/**
 * ******************************************************
 * Copyright (C) 2016-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * file-transfer.service.ts --
 *
 * Base class for upload & download service.
 *
 */

import { Subscription } from "rxjs";
import { EventEmitter, Injector, Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { TranslateService } from "@html-core";
import { UrlParameterService } from "../../../chrome-client/desktop/blast-common/url-parameter.service";
import { clientUtil } from "../../../core/libs/client-util";

@Injectable()
export class FileTransferService {
   public static readonly FILE_TRANSFER_FAILED = "fileTransferFailed";
   private event: EventEmitter<string> = new EventEmitter<string>();
   protected fileList = [];
   protected isTransferring = false;
   protected onTransferFinish = null;
   protected transferProgress = 0;
   protected errorDialogId: string = null;

   protected translate: TranslateService = null;
   protected modalDialogService: ModalDialogService;
   protected urlParameterService: UrlParameterService;

   constructor(injector: Injector) {
      this.translate = injector.get(TranslateService);
      this.modalDialogService = injector.get(ModalDialogService);
      if (clientUtil.isChromeClient()) {
         this.urlParameterService = injector.get(UrlParameterService);
      }
   }

   public openErrorDialog = (errorDgName: string, errorMsg: string) => {
      if (this.modalDialogService.isDialogOpen(this.errorDialogId)) {
         Logger.debug("Only open one dialog each time.", Logger.FILE_TRANSFER);
         this.modalDialogService.close(this.errorDialogId);
      }
      this.errorDialogId = this.modalDialogService.showError({
         data: {
            title: this.translate._T("FT_WARNING_T"),
            content: errorMsg,
            buttonLabelConfirm: this.translate._T("OK")
         }
      });
   };

   public init = (fileList, onTransferFinish: Function) => {
      if (!fileList) {
         Logger.error("Cannot init file transfer service due to param error!", Logger.FILE_TRANSFER);
         return;
      }

      this.transferProgress = 0;
      for (let i = 0; i < fileList.length; i++) {
         if (fileList[i].progress === 100) {
            this.transferProgress++;
         }
      }
      this.fileList = fileList;
      this.onTransferFinish = onTransferFinish;
   };

   public isTransferringFile = () => {
      if (this.isTransferring) {
         this.openErrorDialog("BeingTransferWarningDialog", this.translate._T("FT_BEING_TRANSFER_WARNING_M"));
         return true;
      }
      return false;
   };

   public isTransferringFileWithNoDialog = () => {
      return this.isTransferring;
   };

   public handleFileTransferFailure = () => {
      this.event.emit(FileTransferService.FILE_TRANSFER_FAILED);
   };

   public subscribe = (callback: (msg: string) => void): Subscription => {
      return this.event.subscribe(callback);
   };
}
