<div class="auto-usb-title-container modal-title dialog-title">
   <div id="auto-usb-title">{{ 'AUTO_FORWARD_USB' | i18nv2 }}</div>
</div>

<div class="modal-body dialog-content auto-usb-body" fuzzy-free>
    <div class="usb-container">
        <div class="usb-auto-setting">
            <div class="auto-usb-desktops">
                <div class="auto-usb-desktop-title">
                  <span class="label-text">{{'DESKTOPS' | i18nv2}}</span>
                </div>
                <div class="search-icon">
                  <img class="auto-usb-search-desktop-icon" [src]="this.imageAsset.searchIcon">
                  <div class="auto-usb-placeholder" [hidden]="selectedDesktop && !forceSettingsToAllDesktops">{{desktopPlaceholder | i18nv2}}</div>
                </div>
                <select required id="auto-usb-desktop-selector" name="autoUSBDesktops" [disabled]="forceSettingsToAllDesktops" class="form-control form-select right-option setting-dropdown" (ngModelChange)="selectDesktop($event)" [(ngModel)]="selectedDesktop">
                    <option *ngFor="let desktop of autoUSBDesktops" value="{{desktop}}">{{desktop}}</option>
                </select>
            </div>

            <div id="auto-foward-all" class="auto-foward-all">
                  <div class="auto-foward-option settings-toggle-container" [ngClass]="{usbGrayoutForToggle: isGrayOut()}">
                     <div class="auto-usb-toogle toggle-opt" tabindex="0" role="button">
                        <input class="tgl tgl-light" id="on-start-all" value="start-up" [hidden]="true" type="checkbox" [disabled]="isGrayOut()" [(ngModel)]="autoConnectAllOnStart" (ngModelChange)="onStartForAll($event)">
                        <label id="start-up-label" class="tgl-btn unselectable hidecontentlabel" for="on-start-all"></label>
                     </div>
                  </div>
                 <span class="auto-usb-settings-label">{{'AUTO_USB_ALL_ON_CONNECT' | i18nv2}}</span>

               <div class="auto-usb-settings-separator"></div>
               <div class="auto-foward-option settings-toggle-container" [ngClass]="{usbGrayoutForToggle: isGrayOut()}">
                  <div class="auto-usb-toogle toggle-opt" tabindex="0" role="button">
                     <input class="tgl tgl-light" id="on-insert-all" value="insert" [hidden]="true" type="checkbox" [disabled]="!selectedDesktop && !forceSettingsToAllDesktops" [(ngModel)]="autoConnectAllOnInsert" (ngModelChange)="onInsertForAll($event)">
                     <label id="insert-label" class="tgl-btn unselectable hidecontentlabel" for="on-insert-all"></label>
                  </div>
               </div>
               <span class="auto-usb-settings-label">{{'AUTO_USB_ALL_ON_INSERT' | i18nv2}}</span>
              <div class="auto-usb-settings-separator"></div>
           </div>

            <div class="usb-auto-list" [ngClass]="{autoHideUsb:isGrayOut()}">
               <div class="auto-usb-device-title">
                  <div class="option-title device-title">{{'DEVICE_NAME_T' | i18nv2}}</div>
                  <div class="option-title input-title">{{'ON_START_OPTION_T' | i18nv2}}</div>
                  <div class="option-title-1">{{'ON_INSERT_OPTION_T' | i18nv2}}</div>
               </div>
               <div class="usb-option-container">
                  <div *ngFor="let device of devices; let i = index" class="usb-option-list">
                     <div id="{{device.vendorId}}-{{device.productId}}" class="option-title auto-usb-name" title="{{device.productName}}">{{device.productName}}</div>
                     <div class="auto-usb-option-container">
                        <input class="auto-usb-option-radio on-startup-foward-option" type="checkbox" value="start-up" (ngModelChange)="onStartClick($event, device)" [(ngModel)]="device.autoConnectOnStart">
                        <input class="auto-usb-option-radio on-insert-foward-option" type="checkbox" value="insert" (ngModelChange)="onInsertClick($event,device)" [(ngModel)]="device.autoConnectOnInsert">
                     </div>
                  </div>
               </div>
            </div>
        </div>
    </div>
</div>

<div class="usb-modal-footer usb-dialog-button">
   <div id="usb-all-desktop" (click)="forceToAllOnChange($event)">
      <input type="checkbox" id="force-to-all-desktop" value="usb-all-desktop" [(ngModel)]="forceSettingsToAllDesktops">
      <label id="apply-all-label" for="usb-all-desktop">{{'APPLY_TO_ALL_DESKTOP_M' |i18nv2}}</label>
   </div>
   <div class="auto-usb-button-container">
      <div id="auto-usb-setting-cancel" class="modal-button-base usb-modal-button-right modal-button-blue" (click)="closeModal()">
         {{ 'CANCEL' | i18nv2 }}
     </div>
      <div id="auto-usb-setting-ok" class="modal-button-base usb-modal-button-right no-disable-hover modal-button-blue" (click)="OkClicked()">
         {{ 'APPLY' | i18nv2 }}
     </div>
   </div>
</div>