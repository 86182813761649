/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * share-folder-model.js
 *
 * per device per user model for share folders.
 *
 * init itself from storage with logged in user name
 * can add/remove folder and sync back to storage, and reject invalid adding(repeat, nested)
 * can check folder status
 *
 * This class suffer from race condition in theory, so UI reference must use await
 * and disable controls.
 *
 */

import { Injectable, Optional } from "@angular/core";
import * as CST from "@html-core";
import { ConnectionServerModel } from "./connection-server-model";
import { CDRConfig } from "../../desktop/cdr/cdr-config";
import { clientUtil, Logger } from "@html-core";
import { FileSystemService } from "../../desktop/cdr/cdr-lib/file-system-service";
import { ModalDialogService } from "../commondialog/dialog.service";
import { UserInfoService } from "../service/user-info.service";

interface SharedFolderInfo {
   id: string;
   path: string;
}

@Injectable({
   providedIn: "root"
})
export class ShareFolderModel {
   private folderInfo = [];
   private folderForFA;
   public isContainsFA;
   private folderInfoForCDRAndFA = [];
   public static readonly FAPath = "~/(VMFR)HorizonChromeFA";
   public static readonly FAMockedId = "fileAssociationMockedId";
   private savedData;
   public html5Entry;
   private isChromeClient: boolean = clientUtil.isChromeClient();
   private permssionDialogId;

   constructor(
      private connectionServerModel: ConnectionServerModel,
      private userInfoService: UserInfoService,
      private fileSystemService: FileSystemService,
      private modalDialogService: ModalDialogService,
      @Optional()
      private cdrConfig: CDRConfig
   ) {}

   private _getKeyName = () => {
      let username = this.connectionServerModel.username;
      if (!username) {
         // Read user name from cookie
         username = this.userInfoService.getUserName();
         if (!username) {
            // If username is not available, use token username
            // instead.
            username = this.connectionServerModel.tokenUsername;
         }
         // Cache user name in the server model.
         this.connectionServerModel.username = username;
      }
      username = CST.UsernameUtil.getUsername(username);
      Logger.debug("username name: " + username);
      return username + "sharefolder";
   };

   private _loadInfo = (name: string): Promise<Array<SharedFolderInfo>> => {
      return new Promise((resolve) => {
         try {
            this.fileSystemService
               .getDirectoryEntryFromStorage(name)
               .then((res: any) => {
                  resolve(res);
               })
               .catch((e) => {
                  Logger.debug("getDirectoryEntryFromStorage return error with " + name);
                  Logger.exception(e);
                  resolve([]);
               });
         } catch (e) {
            Logger.exception(e);
            Logger.debug("getDirectoryEntryFromStorage failed with " + name);
            resolve([]);
         }
      });
   };

   /**
    * Should catch exception if call this function
    */
   private _setInfoToStorage = async (data): Promise<void> => {
      //Don't save FA info in local storage
      const cdrData = [];
      data.forEach((info) => {
         if (info.path !== ShareFolderModel.FAPath) {
            cdrData.push(info);
         }
      });
      const saveFolderPromise = (name: string): Promise<any> => {
         return new Promise((resolve, reject) => {
            try {
               this.fileSystemService
                  .saveDirectoryEntryInStorage(name, cdrData)
                  .then((res) => {
                     resolve(res);
                  })
                  .catch((error) => {
                     reject(error);
                  });
            } catch (e) {
               reject(e);
            }
         });
      };
      await saveFolderPromise(this._getKeyName());
      //This is a workaround for bug 2943449
      if (this._getKeyName() !== this._getKeyName().toLowerCase()) {
         await saveFolderPromise(this._getKeyName().toLowerCase());
      }
   };

   private _updateStatus = async () => {
      if (!this.isChromeClient) {
         await this._requestPermssion();
      }
      await Promise.all(this.folderInfo.map((info, i) => this._updateEntryStatus(info.entry, i)));
   };

   private _updateEntryStatus = (entry, index): Promise<void> => {
      return new Promise((resolve, reject) => {
         if (!entry) {
            resolve();
         }
         entry.getMetadata(
            (metaData) => {
               if (!metaData) {
                  delete this.folderInfo[index].entry;
               }
               resolve();
            },
            () => {
               delete this.folderInfo[index].entry;
               resolve();
            }
         );
      });
   };

   private _requestPermssion = (): Promise<void> => {
      return new Promise(async (resolve, reject) => {
         const requestList = [];
         for (let i = 0; i < this.folderInfo.length; i++) {
            const handler = this.folderInfo[i].entry.directoryHandler;
            const permission = await handler.queryPermission({ mode: "readwrite" });
            if (permission !== "granted") {
               requestList.push(handler);
            }
         }
         if (requestList.length > 0 && !this.modalDialogService.isDialogOpen(this.permssionDialogId)) {
            this.permssionDialogId = this.modalDialogService.showConfirm({
               data: {
                  titleKey: "FOLDER_SHARING",
                  contentKey: "FOLDER_SHARING_M"
               },
               callbacks: {
                  confirm: async () => {
                     for (let i = 0; i < requestList.length; i++) {
                        const permission = await requestList[i].requestPermission({ mode: "readwrite" });
                        Logger.info("Request Permssion Result: " + permission);
                     }
                     resolve();
                  }
               }
            });
            const onClosed = () => {
               Logger.debug("User close the dialog");
            };
            const onDismissed = () => {
               Logger.info("User reject the permssion by dismissing the dialog");
               resolve();
            };
            this.modalDialogService.addDialogCloseListener(this.permssionDialogId, onClosed, onDismissed);
         } else {
            resolve();
         }
      });
   };

   private _getValidEntries = () => {
      const entries = [];
      this.folderInfo.forEach((info, index) => {
         if (info.entry) {
            if (info.entry.hasOwnProperty("canceledByUser")) {
               if (!info.entry.canceledByUser) {
                  entries.push(info.entry);
               }
            } else {
               entries.push(info.entry);
            }
         }
      });
      return entries;
   };

   private _loadEntry = (info): Promise<any> => {
      const path = info.path;
      const id = info.id;
      return new Promise((resolve, reject) => {
         if (id === ShareFolderModel.FAMockedId) {
            if (this.folderForFA) {
               const entry = this.folderForFA.entry;
               resolve(entry);
               return;
            } else {
               resolve(null);
               return;
            }
         }
         if (!id) {
            resolve(null);
            return;
         }
         this.fileSystemService.isRestorable(info, null).then(async (entry) => {
            resolve(entry);
         });
      });
   };

   private _setFolderInfo = async (info) => {
      const index = this.folderInfo.push({
         path: info.path
      });
      this.folderInfo[index - 1].entry = await this._loadEntry(info);
      this.folderInfo[index - 1].id = info.id;
   };

   private _isFileDuplicated = (folderList, path) => {
      let isDuplicated = false;
      if (folderList) {
         for (let i = 0; i < folderList.length; i++) {
            if (folderList[i].path === path) {
               isDuplicated = true;
               break;
            }
         }
      }
      return isDuplicated;
   };

   private _checkFAFolderExist = (folderList) => {
      const res = this._isFileDuplicated(folderList, ShareFolderModel.FAPath);
      return res;
   };

   private _checkCDRFolderExist = (folderList) => {
      //need to check CDR info when no CDR is in folderList
      const isFAExist = this._checkFAFolderExist(folderList);
      if (folderList) {
         if (folderList.length === 0) {
            Logger.debug("CDR info isn't in folderList, need to load CDR Info.");
            return true;
         } else if (folderList.length === 1 && isFAExist) {
            Logger.debug("CDR info isn't in folderList, need to load CDR Info.");
            return true;
         } else if (folderList.length > 0 && folderList[0].entry) {
            return true;
         } else {
            Logger.debug("CDR info is in folderList, don't need to load CDR Info.");
            return false;
         }
      } else {
         Logger.debug("CDR info isn't in folderList, need to load CDR Info.");
         return true;
      }
   };

   private _loadFolderInfo = (infos) => {
      const loadPromises = [];
      this.folderInfo = [];
      if (this.folderForFA && !this._checkFAFolderExist(infos)) {
         this.folderInfo.push(this.folderForFA);
      }
      infos.forEach((info, index) => {
         loadPromises.push(this._setFolderInfo(info));
      });
      return Promise.all(loadPromises);
   };

   private _init = async () => {
      if (this.cdrConfig.isEnabled()) {
         Logger.debug("CDR is enabled, need to load both CDR and FA info.");
         if (this.isChromeClient) {
            this.folderInfo = this.folderInfoForCDRAndFA;
         }
         const needToLoadCDRInfo = this._checkCDRFolderExist(this.folderInfo);
         if (needToLoadCDRInfo) {
            const ids: Array<SharedFolderInfo> = await this._loadInfo(this._getKeyName());
            if (ids.length === 0) {
               if (this.folderInfo === null) {
                  this.folderInfo = [];
               } else {
                  Logger.debug("folderInfo only contains FA folder");
               }
            } else {
               await this._loadFolderInfo(ids);
            }
         }
      } else {
         Logger.debug("CDR is disabled, only need to load FA info.");
         this.folderInfoForCDRAndFA = this.folderInfo;
         this.folderInfo = [this.folderForFA];
      }
   };

   private _getFolderIds = () => {
      const ids = [];
      this.folderInfo.forEach((info, index) => {
         let id;
         if (info.path === ShareFolderModel.FAPath) {
            id = ShareFolderModel.FAMockedId;
         } else {
            id = info.entry ? this.fileSystemService.retainEntry(info.entry) : "";
         }
         ids.push({
            path: info.path,
            id: id
         });
      });
      return ids;
   };

   public clearFolderInfo = () => {
      this.removeCDREntry();
   };

   private _getDisplayInfo = () => {
      const infos = [];
      this.folderInfo.forEach((info, index) => {
         if (info.path !== ShareFolderModel.FAPath) {
            infos.push({
               path: info.path,
               valid: !!info.entry,
               htmlEntry: info.entry
            });
         }
      });
      return infos;
   };

   public save = async (directoryHandlers) => {
      let folderIds = this._getFolderIds();
      if (directoryHandlers) {
         folderIds = directoryHandlers;
      }
      await this._setInfoToStorage(folderIds);
   };

   public getDisplayInfo = async () => {
      await this._init();
      await this._updateStatus();
      return this._getDisplayInfo();
   };

   public getValidEntries = async () => {
      await this._init();
      await this._updateStatus();
      return this._getValidEntries();
   };

   public add(path, entry) {
      const isDuplicated = this._isFileDuplicated(this.folderInfo, path);
      if (!isDuplicated) {
         this.folderInfo.push({
            entry: entry,
            path: path
         });
         this.folderInfoForCDRAndFA = this.folderInfo;
      }
   }

   public updateFolderInfo = (info) => {
      this.folderInfo.forEach((item) => {
         if (item.id === info.id) {
            item.entry = info.entry;
         }
      });
   };

   public remove(path) {
      return this.folderInfo.some((info, index) => {
         if (info.path === path) {
            this.folderInfo.splice(index, 1);
            this.folderInfoForCDRAndFA = this.folderInfo;
            return true;
         }
         return false;
      });
   }

   // use array of token to init this model, without save to storage
   public async import(infos) {
      await this._loadFolderInfo(infos);
      await this._updateStatus();
   }

   // export folders into array of token
   public async export() {
      await this._init();
      if (this.folderInfo) {
         await this._updateStatus();
         const folderIds = this._getFolderIds();
         await this._setInfoToStorage(folderIds);
         return folderIds;
      } else {
         return null;
      }
   }

   // use array of token to init this model, and save back to storage
   public async restore(infos) {
      await this._loadFolderInfo(infos);
      await this._updateStatus();
      const folderIds = this._getFolderIds();
      await this._setInfoToStorage(folderIds);
   }

   public async setEmpty() {
      this.folderInfo = [];
   }

   public addFAFolder = async (path, entry) => {
      if (!this.folderInfo) {
         this.folderInfo = [];
      }
      this.isContainsFA = true;
      this.add(path, entry);
      this.folderForFA = {
         path: path,
         entry: entry
      };
      const folderIds = this._getFolderIds();
      return folderIds;
   };

   public removeFAEntry = () => {
      this.folderInfo.forEach((folder, index) => {
         if (folder.path === ShareFolderModel.FAPath) {
            this.folderInfo.splice(index, 1);
         }
      });
   };

   private removeCDREntry = () => {
      if (this.folderInfo) {
         for (let i = this.folderInfo.length - 1; i >= 0; i--) {
            if (this.folderInfo[i].path !== ShareFolderModel.FAPath) {
               this.folderInfo.splice(i, 1);
            }
         }
      }
   };
}
