/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import * as CST from "@html-core";
import { UserInfoService } from "./user-info.service";

@Injectable()
export class HorizonUserInfoService extends UserInfoService {
   constructor(private localStorageService: CST.LocalStorageService) {
      super();
   }
   public getUserName = (): string => {
      return CST.UsernameUtil.getUsername(this.localStorageService.get(CST.COOKIE.USER_NAME) || "");
   };
   public getUserPrincipalName = (): string => {
      if (this.getDomainName()) {
         return this.getUserName() + "@" + this.getDomainName();
      }
      return this.getUserName();
   };

   public getDomainName = (): string => {
      return this.localStorageService.get(CST.COOKIE.DOMAIN_NAME) || "";
   };

   // This is only used for chrome native client to store idpTenantDomain for HCS v2.
   public setDomainName(domain: string) {
      this.localStorageService.set(CST.COOKIE.DOMAIN_NAME, domain);
   }
}
