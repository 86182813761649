/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import WMKS from "WMKS";
import Logger from "../../../core/libs/logger";
import { printUtil } from "./print-util.service";
export interface PrinterPDUHeader {
   type: number;
   dataSize: number;
}

export interface PrinterTransTaskHdr {
   taskType: number;
}

export interface PrinterTransTaskData {
   setting: number;
}

export interface PrinterTransStreamHdr {
   streamFlag: number;
   streamType: number;
   printerId: number;
   jobId: number;
   dataSize: number;
   metaData?: any;
}

export class PrintMessage {
   status: boolean;
   pduHeader: PrinterPDUHeader = {} as PrinterPDUHeader;
   transferTaskHeader: PrinterTransTaskHdr = {} as PrinterTransTaskHdr;
   tramsferTaskData: PrinterTransTaskData = {} as PrinterTransTaskData;
   transferStreamHdr: PrinterTransStreamHdr = {} as PrinterTransStreamHdr;
   transferStreamData: any;
}

export interface PrintClientInfo {
   username: string;
   clientType: string;
   version: string;
}

export class PrintRdProtocol {
   public static readonly LOGFX = "<PrintRd> ";
   static readonly PDU_TYPE = {
      PDU_TYPE_TASK: 0,
      PDU_TYPE_QUERY: 1,
      PDU_TYPE_STREAM: 2,
      PDU_TYPE_INFO: 3,
      PDU_TYPE_EVENT: 4
   };

   static readonly TASK_TYPE = {
      TASK_TYPE_EXCHANGE_PLATFORM: 0,
      TASK_TYPE_EXCHANGE_VERSION: 1,
      TASK_TYPE_START_MONITOR: 2,
      TASK_TYPE_STOP_MONITOR: 3,
      TASK_TYPE_ADD_PRINTER: 4,
      TASK_TYPE_REMOVE_PRINTER: 5,
      TASK_TYPE_SET_DEFAULT_PRINTER: 6,
      TASK_TYPE_GET_CLIENT_INFO: 7,
      TASK_TYPE_SET_CLIENT_INFO: 8,
      TASK_TYPE_STOP_PRINTER_SERVER: 9,
      TASK_TYPE_GET_GPO: 10,
      TASK_TYPE_START_MSPDFPRINT: 11
   };

   static readonly STREAM_FLAG = {
      STREAM_FLAG_QUIT: 0,
      STREAM_FLAG_START: 1,
      STREAM_FLAG_DATA: 2,
      STREAM_FLAG_PAUSE: 3,
      STREAM_FLAG_END: 4
   };

   static readonly PRINT_REDIR_PLATFORM = {
      PRINT_REDIR_PLATFORM_DEFAULT: 0,
      PRINT_REDIR_PLATFORM_WINDOWS: 1,
      PRINT_REDIR_PLATFORM_LINUX: 2,
      PRINT_REDIR_PLATFORM_MACOS: 3,
      PRINT_REDIR_PLATFORM_WEB: 4,
      PRINT_REDIR_PLATFORM_MAX: 5,
      PRINT_REDIR_PLATFORM_WEB2: 6
   };

   private static parsePrinterPDUHeader(msg: PrintMessage, packet: any) {
      msg.pduHeader.type = packet.readUint32();
      msg.pduHeader.dataSize = packet.readUint32();
   }

   private static parsePrinterTransTaskHdr(msg: PrintMessage, packet: any) {
      msg.transferTaskHeader.taskType = packet.readUint32();
   }

   private static parsePrinterTransTaskData(msg: PrintMessage, packet: any) {
      msg.tramsferTaskData.setting = packet.readUint32();
   }

   private static parsePrinterTransStreamHdr(msg: PrintMessage, packet: any) {
      msg.transferStreamHdr.streamFlag = packet.readUint32();
      msg.transferStreamHdr.streamType = packet.readUint32();
      msg.transferStreamHdr.printerId = packet.readUint32();
      msg.transferStreamHdr.jobId = packet.readUint32();
      msg.transferStreamHdr.dataSize = packet.readUint32();
      if (
         msg.transferStreamHdr.streamFlag === PrintRdProtocol.STREAM_FLAG.STREAM_FLAG_START &&
         printUtil.isSupportChromeNewPrinter()
      ) {
         const data = packet.readArray(msg.transferStreamHdr.dataSize);
         msg.transferStreamHdr.metaData = data;
      }
   }

   private static parsePrinterTransStreamData(msg: PrintMessage, packet: any) {
      msg.transferStreamData = packet.readArray(msg.transferStreamHdr.dataSize);
   }

   private static processPrinter(msg: PrintMessage, packet: any) {
      PrintRdProtocol.parsePrinterPDUHeader(msg, packet);

      switch (msg.pduHeader.type) {
         case PrintRdProtocol.PDU_TYPE.PDU_TYPE_TASK:
            PrintRdProtocol.parsePrinterTransTaskHdr(msg, packet);
            PrintRdProtocol.parsePrinterTransTaskData(msg, packet);
            break;
         case PrintRdProtocol.PDU_TYPE.PDU_TYPE_STREAM:
            PrintRdProtocol.parsePrinterTransStreamHdr(msg, packet);
            PrintRdProtocol.parsePrinterTransStreamData(msg, packet);
            break;
         default:
            msg.status = false;
            Logger.warning(PrintRdProtocol.LOGFX + "Unsupported PrinterPDU message");
            return;
      }
   }

   private static dumpPrintMsg(msg: PrintMessage, key: string) {
      const pdu: PrinterPDUHeader = msg.pduHeader;
      Logger.debug(PrintRdProtocol.LOGFX + "-- [" + key + "]");

      if (pdu) {
         Logger.debug(PrintRdProtocol.LOGFX + "msg.pdu [" + pdu.type + " " + pdu.dataSize + "]");
      }
      if (pdu.type === PrintRdProtocol.PDU_TYPE.PDU_TYPE_TASK) {
         const taskHdr: PrinterTransTaskHdr = msg.transferTaskHeader;
         const taskData: PrinterTransTaskData = msg.tramsferTaskData;
         Logger.debug(PrintRdProtocol.LOGFX + "msg.task.hdr [" + taskHdr.taskType + " " + taskData.setting + "]");
      } else if (pdu.type === PrintRdProtocol.PDU_TYPE.PDU_TYPE_STREAM) {
         const sHdr: PrinterTransStreamHdr = msg.transferStreamHdr;
         Logger.debug(
            PrintRdProtocol.LOGFX +
               "msg.stream [" +
               sHdr.streamFlag +
               " " +
               sHdr.streamType +
               " " +
               sHdr.printerId +
               " " +
               sHdr.jobId +
               " " +
               sHdr.dataSize +
               "]"
         );
      }
   }

   private static stringToUint8Array(str: string, len: number) {
      const res = new Uint8Array(len);
      for (let i = 0, j = 0; i < str.length && j < len; i++, j += 2) {
         res[j] = str.charCodeAt(i);
         res[j + 1] = 0;
      }
      return res;
   }

   public static handlePrinterRedirectionMessage(packet: any, key: string) {
      const printMsg: PrintMessage = new PrintMessage();
      printMsg.status = true;
      PrintRdProtocol.processPrinter(printMsg, packet);
      PrintRdProtocol.dumpPrintMsg(printMsg, key);
      return printMsg;
   }

   public static constructExchangeVersionReply() {
      const reply = this.createReplyAndSetTaskType(36);
      reply.writeUint32(PrintRdProtocol.TASK_TYPE.TASK_TYPE_EXCHANGE_VERSION);
      if (printUtil.isSupportChromeNewPrinter()) {
         // Use this version for chrome new solution
         reply.writeUint32(0x03020000);
      } else {
         reply.writeUint32(0x02010002);
      }
      return reply;
   }

   public static constructExchangePlatformReply() {
      const reply = this.createReplyAndSetTaskType(36);
      reply.writeUint32(PrintRdProtocol.TASK_TYPE.TASK_TYPE_EXCHANGE_PLATFORM);
      if (printUtil.isSupportChromeNewPrinter()) {
         reply.writeUint32(PrintRdProtocol.PRINT_REDIR_PLATFORM.PRINT_REDIR_PLATFORM_WEB2);
      } else {
         reply.writeUint32(PrintRdProtocol.PRINT_REDIR_PLATFORM.PRINT_REDIR_PLATFORM_WEB);
      }
      return reply;
   }

   public static constructExchangeClientInfoReply(clientInfo: PrintClientInfo, clientinfoMessage?: Uint8Array) {
      let reply;
      if (printUtil.isSupportChromeNewPrinter()) {
         const length = 4 + clientinfoMessage.length; // size of Transtask is 4 bytes plus
         reply = this.createReplyAndSetTaskType(length);
         reply.writeUint32(PrintRdProtocol.TASK_TYPE.TASK_TYPE_SET_CLIENT_INFO);
         reply.writeArray(clientinfoMessage);
      } else {
         reply = this.createReplyAndSetTaskType(132);
         reply.writeUint32(PrintRdProtocol.TASK_TYPE.TASK_TYPE_SET_CLIENT_INFO);
         reply.writeArray(this.stringToUint8Array(clientInfo.username, 64));
         reply.writeArray(this.stringToUint8Array(clientInfo.clientType, 32));
         reply.writeArray(this.stringToUint8Array(clientInfo.version, 32));
      }
      return reply;
   }

   public static constructExchangePrinterListReply(printerList, type) {
      const replyLength = 4 + printerList.length; // size of Transtask is 4 bytes plus
      const reply = this.createReplyAndSetTaskType(replyLength);
      if (type === "add") {
         reply.writeUint32(PrintRdProtocol.TASK_TYPE.TASK_TYPE_ADD_PRINTER);
      } else {
         reply.writeUint32(PrintRdProtocol.TASK_TYPE.TASK_TYPE_REMOVE_PRINTER);
      }
      reply.writeArray(printerList);
      return reply;
   }

   public static createReplyAndSetTaskType(length) {
      const reply = WMKS.Packet.createNewPacketLE();
      reply.writeUint32(PrintRdProtocol.PDU_TYPE.PDU_TYPE_TASK);
      reply.writeUint32(length);
      return reply;
   }
}
