/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * ******************************************************
 *
 * @format
 */

/**
 * offlineSSOCacheTimerController.ts --
 *
 * timer and logic for discard sso timeout.
 *
 */

import Logger from "../../../core/libs/logger";
import { Timer, timerTypeEnum } from "./timer";

export default function OfflineSSOCacheTimerController() {
   Logger.debug("OfflineSSOCacheTimerController created");
   this.globalName = "offline-sso-cache-timer";
   this.timeoutTimer = new Timer(timerTypeEnum.timeout);
   this.STORAGE_KEY = "SSO-TIMER";
   this.ssoTimeout = -1;
}

OfflineSSOCacheTimerController.prototype = {};
OfflineSSOCacheTimerController.constructor = OfflineSSOCacheTimerController;

(function () {
   function offlineSSOCacheTimeoutEvent() {}

   function storeTimerInStorage() {
      const targetTime = this.timeoutTimer.getTargetTime();
      window.sessionStorage.setItem(this.STORAGE_KEY, targetTime);
      Logger.debug("Offline sso cache timer is stored:" + targetTime);
   }

   function removeTimerFromStorage() {
      window.sessionStorage.removeItem(this.STORAGE_KEY);
      Logger.debug("Offline sso cache timer is removed");
   }

   function initCore() {
      if (this.ssoTimeout <= 0) {
         this.timeoutTimer.setDisable();
         this.ssoTimeout = -1;
      } else {
         this.timeoutTimer.setEnable();
      }
      this.timeoutTimer.init(offlineSSOCacheTimeoutEvent, this.ssoTimeout);
   }

   OfflineSSOCacheTimerController.prototype.start = function (timeout: number) {
      Logger.debug("Init offline sso cache timer controller with timeout: " + timeout);
      this.ssoTimeout = timeout;
      initCore.call(this);
      this.timeoutTimer.start();
      storeTimerInStorage.apply(this);
   };

   OfflineSSOCacheTimerController.prototype.stop = function () {
      this.timeoutTimer.discard();
      removeTimerFromStorage.apply(this);
      Logger.debug("Offline SSO cache timer is discarded because timeout reached");
   };

   OfflineSSOCacheTimerController.prototype.getTimerFromStorage = function () {
      const targetTime = window.sessionStorage.getItem(this.STORAGE_KEY);
      Logger.debug("Offline SSO cache time is: " + targetTime);
      return parseInt(targetTime, 10);
   };
})();
