/**
 * ******************************************************
 * Copyright (C) 2016-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input, Optional, ViewChild } from "@angular/core";
import { clientUtil } from "@html-core";
import { ClipboardKillSwitchService } from "../desktop/common/clipboard-killswitch.service";
import { FileTransferPanelComponent } from "../desktop/filetransfer/file-transfer.component";

@Component({
   selector: "remote-feature",
   templateUrl: "./remote-feature.component.html"
})
export class RemoteFeatureComponent {
   public isWebPanelFreeClipboard: boolean;
   public chromeclient: boolean;
   @Input() isApplicationOnChromeclient;
   public session;
   @ViewChild(FileTransferPanelComponent, { static: true }) fileTransfer: FileTransferPanelComponent;
   constructor(private clipboardKillSwitchService: ClipboardKillSwitchService) {
      this.isWebPanelFreeClipboard = this.clipboardKillSwitchService.getIsWebPanelFreeClipboard();
      this.chromeclient = clientUtil.isChromeClient();
   }
}
