/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * disclaimerHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler for accepting
 *      disclaimer.
 */

import $ from "jquery";
import { MessageHandler } from "./messageHandler";
import util from "../util";
import { globalArray } from "../jscdkClient";

export default function DisclaimerHandler() {
   // member variables below
   this.messageName = "disclaimer";
   this.messageText = "disclaimer";

   // register dependencies here
   const router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
DisclaimerHandler.prototype = new MessageHandler();
// constructor
DisclaimerHandler.constructor = DisclaimerHandler;

/**
 * Set the request XML for accepting disclaimer authentication.
 */

DisclaimerHandler.prototype.setRequestXML = function () {
   let paramText,
      acceptText = util.createElement("name", "accept");
   acceptText += util.createElement("values", util.createElement("value", "true"));
   paramText = util.createElement("param", acceptText);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", "disclaimer");
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};

/**
 * parse the response of winCredsHandler
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return
 *    null
 */

DisclaimerHandler.prototype.parseResult = function (responseXML) {
   const authResponse = {};
   util.addItemForJson(authResponse, "screen", $(responseXML).children("name").text());
   $(responseXML)
      .children("params")
      .children("param")
      .each(function () {
         const child = $(this);
         const nameText = child.find("name").text();
         const valueList = [];
         child.find("value").each(function () {
            valueList.push($(this).text());
         });
         util.addItemForJson(authResponse, nameText, valueList);
      });

   return authResponse;
};
