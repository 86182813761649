/**
 * ******************************************************
 * Copyright (C) 2021，2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * sidebar-global-menu.component.ts --
 *
 * Component implementing global context menu for sidebar
 *
 */

import { Component, Input, Output, EventEmitter } from "@angular/core";
import * as CST from "@html-core";
import { BusEvent, EventBusService } from "@html-core";
import { HtmlRemoteSessionManager } from "../../../../html5-client/common/remote-session/html-remote-session-manager";
import Logger from "../../../../core/libs/logger";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { ViewClientModel } from "../../../common/model/viewclient-model";
import { FullscreenService } from "../../../utils/fullscreen-service";
import { WmksService } from "../../common/wmks.service";
import { MultimonService } from "../../multimon/main-page/multimon.service";
import { HorizonLogoutService } from "../horizon-logout.service";
import { PanelEvent } from "../sidebar-constant";
import { Html5UsbService } from "../../usb/usb-service/html5-usb.service";
import { UserInfoService } from "../../../common/service/user-info.service";
@Component({
   selector: "sidebar-global-menu",
   templateUrl: "./sidebar-global-menu.component.html"
})
export class SidebarGlobalComponent {
   public userName: string;
   public userInfo: string;
   public globalContextMenu: boolean = false;
   public checkFullscreenBtnClicked: boolean = false;
   public multimonStarted: boolean = false;
   public multimonOptionVisible: boolean = false;
   public isUsbSupported: boolean = true;
   public isUSBEnabled: boolean = false;
   public currentTouchMode: string = "NATIVETOUCH_MODE";

   @Output() panelEvent = new EventEmitter<PanelEvent>();

   constructor(
      private modalDialogService: ModalDialogService,
      private fullscreenService: FullscreenService,
      private wmksService: WmksService,
      private multimonService: MultimonService,
      private eventBusService: EventBusService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private viewClientModel: ViewClientModel,
      private logoutService: HorizonLogoutService,
      private usbService: Html5UsbService,
      private userInfoService: UserInfoService
   ) {
      this.userName = this.userInfoService.getUserName();
      this.userInfo = this.userInfoService.getUserPrincipalName();

      fullscreenService.fullScreen$.subscribe((fullscreen: boolean) => {
         this.checkFullscreenBtnClicked = fullscreen;
         if (!fullscreen && !this._isInMultiMonitor()) {
            fullscreenService.unbindFullScreen();
         }
      });

      this.eventBusService.listen(BusEvent.MultiMonitorsMsg.MSG_TYPE).subscribe((msg: BusEvent.MultiMonitorsMsg) => {
         this.multimonStarted = msg.enter;
      });

      this.eventBusService
         .listen(BusEvent.UsbSessionChangedMsg.MSG_TYPE)
         .subscribe((msg: BusEvent.UsbSessionChangedMsg) => {
            this.isUsbSupported = msg.enabled;
         });

      this.eventBusService.listen(BusEvent.NotificationMsg.MSG_TYPE).subscribe((msg: BusEvent.NotificationMsg) => {
         this.currentTouchMode = msg.notificationKey;
      });

      this.logoutService.init();
   }
   @Input("show") set GlobalMenu(enable: boolean) {
      this.globalContextMenu = enable;
      this.updateMultimonMenuVisibility();
      this.updateUsbVisibility();
   }

   get GlobalMenu(): boolean {
      return this.globalContextMenu;
   }

   private _isInMultiMonitor = (): boolean => {
      return this.multimonService.isInMultiMonitorStatus();
   };

   public settingsClicked = () => {
      this.modalDialogService.showSetting();
   };

   public isSupportedMobileDevice = () => {
      return this.viewClientModel.mIsTouchDevice || this.viewClientModel.mIsVrDevice;
   };

   public isShowFullscreenBtn = (): boolean => {
      return (
         !this.checkFullscreenBtnClicked &&
         !this.multimonStarted &&
         !this.viewClientModel.mIsSafari &&
         !this.viewClientModel.mIsAndroid
      );
   };

   /**
    *  Handler for the fullscreen button.
    *
    *  @param [in] e the jquery click event
    */
   public fullscreenClicked = () => {
      this.fullscreenService.bindFullScreen();
      this.fullscreenService.enterFullscreen(document.body);
      this.panelEvent.emit(PanelEvent.HIDE);
      this.eventBusService.dispatch(new BusEvent.FullscreenBtnClicked());
   };

   /**
    *  Handler for the fullscreen button.
    *
    *  @param [in] e the jquery click event
    */
   public quitFullscreenClicked = () => {
      this.fullscreenService.exitFullscreen();
      this.panelEvent.emit(PanelEvent.HIDE);
      this.eventBusService.dispatch(new BusEvent.QuitFullscreenBtnClicked());
   };

   public aboutClicked = () => {
      this.modalDialogService.showAbout();
   };

   public showKeyMapping = () => {
      this.modalDialogService.showKeyboardSetting();
   };

   public gestureHelpClicked = () => {
      this.modalDialogService.showGestureHelp(this.currentTouchMode);
   };

   public logoutClicked = () => {
      this.eventBusService.dispatch(new BusEvent.LogOutMsg(false));
   };

   public usbClicked = () => {
      if (this.isUSBEnabled) {
         this.eventBusService.dispatch(new BusEvent.ToggleUsbMsg());
      }
   };

   public updateUsbVisibility = () => {
      const currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (currentSession && currentSession.key) {
         this.isUSBEnabled = this.usbService.isUsbEnabled(currentSession.key);
      }
      if (currentSession === null) {
         this.isUSBEnabled = false;
      }
   };

   public settingsKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.settingsClicked();
      }
   };

   public fullscreenKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.fullscreenClicked();
      }
   };

   public quitFullscreenKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.quitFullscreenClicked();
      }
   };

   public usbKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.usbClicked();
      }
   };

   public aboutKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.aboutClicked();
      }
   };

   public gestureHelpKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.gestureHelpClicked();
      }
   };

   public logoutKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.logoutClicked();
      }
   };

   public keyMappingKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         // Press enter key.
         event.preventDefault();
         event.stopPropagation();
         this.showKeyMapping();
      }
   };

   /**
    * for Q1 only suuport chrome and firefox to reduce dev efforts
    *    and not support touch device and application sessions to reduce
    *    the testing and bug fixing efforts
    *
    *    set minial chrome version to 55 since it fix a DPI setting bug
    */
   private updateMultimonMenuVisibility = () => {
      const isSupportedBrowser =
            WMKS.BROWSER.isChrome() && WMKS.BROWSER.version.major >= 55 && !CST.clientUtil.isChromeClient(),
         isSupportedDevice = !WMKS.BROWSER.isMobileTouchDevice(),
         currentSessionIsDesktop = this.htmlRemoteSessionManager.isCurrentSessionDesktop(),
         agentSupportMultimon = this.wmksService.hasMultimonCapacity(),
         isNotShadowSession = !this.wmksService.isCurrentSessionShadowSession();

      Logger.info(
         "update updateMultimonMenuVisibility, " +
            JSON.stringify({
               isSupportedBrowser: isSupportedBrowser,
               isSupportedDevice: isSupportedDevice,
               isDesktop: currentSessionIsDesktop,
               agentSupportMultimon: agentSupportMultimon,
               isNotShadowSession: isNotShadowSession
            })
      );

      this.multimonOptionVisible =
         isSupportedBrowser &&
         isSupportedDevice &&
         currentSessionIsDesktop &&
         agentSupportMultimon &&
         isNotShadowSession;
   };
}
