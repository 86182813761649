/**
 * ******************************************************
 * Copyright (C) 2017-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { StreamReader } from "./stream-reader";
import { StreamWriter } from "./stream-writer";
import { ProtocolHelper } from "./protocol-helper";
import Logger from "../../../../core/libs/logger";

/**
 * Service provide instances to parse messages, might be used for other features
 * later, but place it inside CDR/TSDR folder for now.
 *
 * It's mainly used to ease the pain when handling huge structures.
 *
 * There will be exceptions thrown out, so please use catch to handle them.
 *
 * We could only provide API of higher level like, which is more readable than
 * the current APIs, but that will limit the flexibility. So if others will use
 * this also, a higher level API without details can be provided also.
 *
 * Large integer could use libs, but have no such a need for now.
 * If really need to use the accurate value, import the structure of LARGE_INTEGER
 *
 * p.s. The log level of debug is used for logging for developing, and will be
 * changed before the release, after that, for other features want to see the
 * detailed log, please set log level to trace explicitly(using param or config
 * ), and better disable other features when debugging).
 */

/**
 * Simple API
 * when schema is well defined, we can use the simplified APIs.
 * This is a little bit of less efficient, so use the other APIs when invoked heavily.
 *
 * 1. Create helper
 * ------let tsdrHelper = protocolUtil.getHelper(tsdrSchema);
 *
 *
 * 2. Parse stream into nested JS object
 * ------let parsedJsObject = helper.simplifiedParse(typedArrayFromVVC, "STRUCT_NAME", castConfig);
 * where castConfig is optional to support unions and various length structs.
 *
 *
 * 3. Streamify nested JS object into stream
 * ------dataStream = helper.simplifiedStreamify("STRUCT_NAME", jsObject, castConfig);
 * ------channel.send(dataStream);
 * Where castConfig is optional to support unions and various length structure.
 */

/**
 * Flexible API
 * If want to reduce the schema complexity or reuse instances, we can use in the
 * below way
 *
 *
 * 2. Parse
 * Below is a normal way to decide what to read step by step.
 * ------let streamReader = protocolUtil.getReader(typedArrayFromVVC);
 * ------let header = protocolHelper.parse(streamReader, "RDPDR_HEADER");
 * ------switch(header.component){
 * ------  case "RDPDR_TYPE.PAKID_CORE_DEVICE_IOREQUEST":
 * ------    let ioRequest = protocolHelper.parse(streamReader, "RDP_DR_DEVICE_IOREQUEST", castConfig);
 * ------    processIO(ioRequest);
 * ------    break;
 * ------  ...
 * ------}
 * ioRequest could be one of many formats, which is dynamically decided by the
 * message type and the value-type map, so not only nested structure and nested
 * unions are supported, but also various length structures can be supported.
 * p.s. not using C's union style but introduce "unionType" variable for better
 * readability, and also C codes also not use union but use cast in the similar
 * way.
 *
 * And the parsed result looks like:
 * {
 *    "deviceId": 65535,
 *    "fileId": 0,
 *    "completionId": 0,
 *    "majorFunction": 0,
 *    "minorFunction": 0,
 *    "parameters": {
 *       "unionType": "Create",//this is a added variable.
 *       "desiredAccess": 1179785,
 *       "allocationSize": 0,
 *       "fileAttributes": 0,
 *       "sharedAccess": 7,
 *       "createDisposition": 1,
 *       "createOptions": 96,
 *       "pathLength": 26,
 *       "path": "\\Desktop.ini"
 *    }
 * }
 *
 * Which corresponding to C structs, but different with union refer:
 * C: pIoRequest->Parameters.Create.SharedAccess
 * JS: ioReqeuset.parameters.sharedAccess
 * While for JS, ioReqeuset.parameters.unionType is "Create", this value will be
 * overwritten by data number of same name if exist, and is mainly aimed for
 * providing better readability and compacter object structure.
 *
 * We can also use streamReader directly to parse data when writing PoC
 * ------let streamReader = protocolUtil.getReader(typedArrayFromVVC);
 * ------let component = streamReader.getUint16();
 * ------let packetId = streamReader.getUint16();
 *
 *
 * 3. Write
 * ------let clientName ={
 * ------   header: {
 * ------      component: RDPDR_TYPE.RDPDR_CTYP_CORE,
 * ------      packetId: RDPDR_TYPE.PAKID_CORE_CLIENT_NAME
 * ------   },
 * ------   unicodeFlag: 0x00000001,
 * ------   codePage: 0,
 * ------   computerNameLen: 20,
 * ------   computerName: "Eden's PC"
 * ------};
 * ------let streamWriter = protocolUtil.getWriter();
 * ------protocolHelper.streamify(streamWriter, "DR_CORE_CLIENT_NAME_REQ", clientName);
 * ------sendRPC(streamWriter, onDone, onAbort);//use callback for example
 * Where one need to implement sendRPC by writer.hasData(), writer.getStream(),
 * and channel.send(), etc.
 *
 * For PoC, streamWriter API could be used also like:
 * ------let streamWriter = protocolUtil.getWriter();
 * ------streamWriter.pushUint16(1);
 * ------streamWriter.pushUint32(2);
 * ------streamWriter.pushString("test");
 * ------sendRPC(streamWriter, onDone, onAbort);//use callback for example
 *
 * For more complex examples with union, please CDR codes.
 */
export class ProtocolUtil {
   public static getReader = (data, isBigEndian: boolean = false, allowStructAlign = false): StreamReader => {
      return new StreamReader(data, isBigEndian, allowStructAlign);
   };

   public static getWriter = (isBigEndian: boolean = false, allowStructAlign = false): StreamWriter => {
      return new StreamWriter(isBigEndian, allowStructAlign);
   };

   /**
    * Function to get a helper, which can recognize all baseType, union,
    * struct defined in the schema, and also provide lower API to handle
    * structs and unions not defined in the schema.
    * But recommend to use the schema to decouple the protocol data format
    * from logic.
    * @param  {object} schema [description]
    * @return {object}        The helper instances with schema loaded
    */
   public static getHelper = (schema): ProtocolHelper => {
      return new ProtocolHelper(schema);
   };

   /**
    * Function to help change endian
    * @param  {number} n The number we want to swap
    * @param  {number} bytes  how many bytes the number have.
    * @return {number}        The swaped number
    */
   public static bswap = (n, bytes) => {
      if (bytes === 4) {
         return [(n & 0xff000000) >>> 24, (n & 0x00ff0000) >>> 8, (n & 0x0000ff00) << 8, (n & 0x000000ff) << 24].reduce(
            function (a, b) {
               return a + b;
            }
         );
      } else if (bytes === 2) {
         return [(n & 0xff00) >>> 8, (n & 0x00ff) << 8].reduce(function (a, b) {
            return a + b;
         });
      } else if (bytes === 1) {
         return n;
      } else {
         Logger.warning("ProtocolUtil " + " don't support bswap on this type of number");
         return 0;
      }
   };

   /**
    * @param  {Array} The array will be modified
    * @param  {number} offset
    * @param  {number} bytes
    * @return {Array} Same as array
    */
   public static arraySwap = (array, offset: number = 0, bytes?: any) => {
      if (bytes === undefined) {
         bytes = array.length;
      }

      for (let i = 0; i < bytes / 2; i++) {
         const tmp = array[offset + i];
         array[offset + i] = array[offset + bytes - 1 - i];
         array[offset + bytes - 1 - i] = tmp;
      }
      return array;
   };

   /**
    * convert string into UFT-8 format and represented in normal string
    * @param  {string} text  The source string
    * @param  {number} maxSize The max size of returned string, clip if text too long
    * @param  {number} revertEndian Optional Default as false
    * @return {string}         [description]
    */
   public static encodeUTF8 = (text, maxSize, revertEndian) => {
      if (!text || typeof text !== "string") {
         Logger.warning("ProtocolUtil " + "input text must be of type string");
         return;
      }
      const concateByEndian = (newString, previousString) => {
         if (revertEndian) {
            return newString + previousString;
         } else {
            return previousString + newString;
         }
      };
      let encodedString = "";
      text.split("").some((charactor) => {
         const escapedCharactor = encodeURI(charactor);
         let encodedCharactor;
         if (escapedCharactor[0] !== "%") {
            encodedCharactor = escapedCharactor[0];
         } else {
            encodedCharactor = "";
            for (let i = 0; i < escapedCharactor.length; i += 3) {
               if (escapedCharactor[i] !== "%") {
                  Logger.error("ProtocolUtil inner error when encode utf-8");
                  return true;
               }
               encodedCharactor = concateByEndian(
                  encodedCharactor,
                  String.fromCharCode(Number("0x" + escapedCharactor[i + 1] + escapedCharactor[i + 2]))
               );
            }
         }
         // clip
         if (maxSize > 0 && encodedString.length + encodedCharactor.length > maxSize) {
            return true;
         }
         encodedString += encodedCharactor;
         return false;
      });
      return encodedString;
   };
}
