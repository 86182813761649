/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { TitanEnvironment } from "./environment.interface";

export const environment: TitanEnvironment = {
   production: true,
   commonPostLogoutUri: `${location.origin}/appblast/webclient/index.html`,
   vanityPostLogoutUri: `${location.origin}/appblast/oauth/post_logout`,
   authUrl: `${location.origin}/auth`,
   portalUrl: `${location.origin}/portal`,
   vanityRedirectUri: `${location.origin}/oauth/callback`,
   commonRedirectUri: `${location.origin}/appblast/webclient/index.html`,
   titanVersion: "0.2.0",
   rxUrl: `${location.origin}/rx-service`,
   authEndpoint: `${location.origin}/appblast/endpoint`,
   heartBeatURI: `${location.origin}/appblast/endpoint/favicon.ico`
};
