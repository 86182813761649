/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { ConnectionServerModel } from "./model/connection-server-model";
import { TimezoneListComponent } from "./timezone/timezone-list.component";
import { AdvancedSettingComponent } from "../launcher/common/advanced-setting/advanced-setting.component";
import { iconLoadDirective } from "./directive/icon-load.directive";
import { ScrollableDialogDirective } from "./directive/scrollable-dialog.directive";
import { AboutDialogComponent } from "./aboutdialog/about-dialog.component";
import { CustomModalDialogComponent } from "./commondialog/custom-modal-dialog/custom-modal-dialog.component";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "./commondialog/confirm-dialog/confirm-dialog.component";
import { CancelConfirmDialogComponent } from "./commondialog/cancel-confirm-dialog/cancel-confirm-dialog.component";
import { CancelConfirmDialogWithCheckBoxComponent } from "./commondialog/cancel-confirm-dialog-with-checkbox/cancel-confirm-dialog-with-checkbox.component";
import { CancelConfirmDialogWithInputTextComponent } from "./commondialog/cancel-confirm-dialog-with-input-text/cancel-confirm-dialog-with-input-text.component";
import { panelNotification } from "../../html5-client/desktop/clipboard/panel-notification.component";
import { ErrorDialogComponent } from "./commondialog/error-dialog/error-dialog.component";
import { ClipFTHelpDialogComponent } from "../desktop/common/clipboard-filetransfer-help-dialog/clipboard-filetransfer-help-dialog.component";
import { FormsModule } from "@angular/forms";
import { FuzzyFreeDirective } from "./directive/scrollable-dialog-fuzzy-free.directive";
import { AutoFocusDirective, loadingImageAnimationComponent } from "../launcher/common/common-directive";
import { SettingDialogComponent } from "./setting-dialog/setting-dialog.component";
import { SettingMultiLaunchDialogComponent } from "./multi-launch-setting/setting-multi-launch-dialog.component";
import { SettingMultiMonitorDialogComponent } from "./multi-monitor-setting/setting-multi-monitor-dialog.component";
import { SettingRtavDialogComponent } from "./rtav-setting/setting-rtav-dialog.component";
import { SettingCDRDialogComponent } from "../launcher/cdr-setting/setting-cdr-dialog.component";
import { SettingAutoUSBDialogComponent } from "../launcher/auto-usb-setting/setting-auto-usb-dialog.component";
import { SettingTrustedAppsDialogComponent } from "../launcher/trusted-apps-setting/setting-trusted-apps-dialog.component";
import { DebugConfigurationDialogComponent } from "../launcher/debug-configuration-setting/debug-configuration-dialog.component";
import { PreSettingDialogComponent } from "../launcher/common/pre-setting/pre-setting-dialog.component";
import { AvailableItemsPipe } from "./filters/available-items.pipe";
import { SortPipe } from "./filters/sort.pipe";
import { IdleTimeoutUtil } from "../../chrome-client/base/service/idle-timeout-util.service";
import { I18nv2Pipe } from "./filters/i18n-v2.pipe";
import { CommonModule } from "@angular/common";
import { settingListenerDirective } from "./directive/setting-listener.directive";
import { HorizonPreferenceComponent } from "./horizon-preference/horizon-preference.component";
import { FileAssociationDefaultAppComponent } from "../../chrome-client/file-association/file-association-default-app/file-association-default-app-dialog.component";
import { WindowModule } from "./window.module";
import { ShareContextMenuComponent } from "./context-menu/contextmenu-root/contextmenu.component";
import { TitanContextMenuComponent } from "./context-menu/titan-context-menu/context-menu.component";
import { WebContextMenuComponent } from "./context-menu/web-context-menu/context-menu.component";
import { ChromeContextmenuComponent } from "./context-menu/chrome-context-menu/context-menu.component";
import { GestureHelpComponent } from "./gesture-help/gesture-help.component";
import { KeyBoardSettingComponent } from "./keyboard-setting/keyboard-setting.component";
import { LanguageLayoutSearchPipe } from "./filters/language-layout-search.pipe";

@NgModule({
   imports: [CommonModule, NgbModule, FormsModule, WindowModule],
   declarations: [
      AboutDialogComponent,
      TimezoneListComponent,
      I18nv2Pipe,
      AdvancedSettingComponent,
      CustomModalDialogComponent,
      iconLoadDirective,
      settingListenerDirective,
      AutoFocusDirective,
      ScrollableDialogDirective,
      ConfirmDialogComponent,
      CancelConfirmDialogComponent,
      CancelConfirmDialogWithCheckBoxComponent,
      CancelConfirmDialogWithInputTextComponent,
      panelNotification,
      ErrorDialogComponent,
      ClipFTHelpDialogComponent,
      SettingDialogComponent,
      SettingMultiLaunchDialogComponent,
      SettingMultiMonitorDialogComponent,
      SettingRtavDialogComponent,
      SettingCDRDialogComponent,
      SettingAutoUSBDialogComponent,
      SettingTrustedAppsDialogComponent,
      FileAssociationDefaultAppComponent,
      DebugConfigurationDialogComponent,
      FuzzyFreeDirective,
      PreSettingDialogComponent,
      loadingImageAnimationComponent,
      AvailableItemsPipe,
      SortPipe,
      HorizonPreferenceComponent,
      ShareContextMenuComponent,
      TitanContextMenuComponent,
      WebContextMenuComponent,
      ChromeContextmenuComponent,
      GestureHelpComponent,
      KeyBoardSettingComponent,
      LanguageLayoutSearchPipe
   ],
   schemas: [NO_ERRORS_SCHEMA],
   providers: [ConnectionServerModel, NgbActiveModal, IdleTimeoutUtil],
   exports: [
      I18nv2Pipe,
      panelNotification,
      iconLoadDirective,
      settingListenerDirective,
      AutoFocusDirective,
      CustomModalDialogComponent,
      AdvancedSettingComponent,
      AvailableItemsPipe,
      SortPipe,
      loadingImageAnimationComponent,
      HorizonPreferenceComponent,
      ShareContextMenuComponent,
      TitanContextMenuComponent,
      WebContextMenuComponent,
      ChromeContextmenuComponent,
      LanguageLayoutSearchPipe
   ]
})
export class SharedCommonModule {}
