/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * resetDesktopHandler.js --
 *
 *      Implementation of the message handler to reset desktop.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";

export default function ResetDesktopHandler() {
   let router;

   // member variables below
   this.messageName = "reset-desktop";
   this.messageText = "reset-desktop";
   this.responseTag = "reset-desktop";
   this.composedHandlerList = [];
   // requestId will increase when a new instance is created
   // use prototype requestId to share in all instances
   ResetDesktopHandler.prototype.requestId += 1;

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
}

// inherits MessageHandler prototype
ResetDesktopHandler.prototype = new MessageHandler();
// constructor
ResetDesktopHandler.constructor = ResetDesktopHandler;

/**
 * parse information from the response XML of reset-desktop
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
ResetDesktopHandler.prototype.parseResult = function (responseXML) {
   const responseBody = responseXML;
   let brokerTag;
   let responses;
   let result;
   let tokenText;
   const resetDesktopResponse = {};
   let xmlDoc;

   xmlDoc = $(responseXML);
   brokerTag = $(xmlDoc.children()[0]);
   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(resetDesktopResponse, nameText, $(this).text());
      });
   }
   return resetDesktopResponse;
};

/**
 * Set the request XML for reset-desktop
 * @param desktopId[in] desktop id to reset
 *
 */
ResetDesktopHandler.prototype.setRequestXML = function (desktopId) {
   let desktopIdElem;
   desktopIdElem = util.createElement("desktop-id", desktopId);
   this.requestXML = desktopIdElem;
};

/**
 * callback when received notification from handlers in dependency list or
 * router
 *
 */
ResetDesktopHandler.prototype.onUpdated = function () {
   let resetDesktopAction;

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (this.state === StateEnum.DONE) {
      if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
         Logger.debug("Reset desktop succeeds.");

         // Kill session succeeds
         resetDesktopAction = { name: "ResetDesktop", result: "ok" };
         JSCDKSetUI(JSON.stringify(resetDesktopAction));
      }
   }

   // push error if it exists
   Router.prototype.pushErrorToUser(this);
};
