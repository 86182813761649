/**
 * ******************************************************
 * Copyright (C) 2016-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * app-center.component.ts --
 *
 * The center controller for whole angular application.
 *
 * It is responsible for initialization of both clients
 *
 */

import { CLIENT, clientUtil } from "@html-core";
import { FullscreenService } from "./utils/fullscreen-service";
import { BaseViewService } from "./common/service/base-view.service";
import { ClientModeService } from "./common/service/client-mode.service";
import { ConnectionURIModel } from "./common/model/connection-uri-model";
import { EventBusService } from "../core/services/event/event-bus.service";
import { ViewClientModel } from "./common/model/viewclient-model";
import { ModalDialogService } from "./common/commondialog/dialog.service";
import { Localize } from "./common/service/localize.service";
import { KillSwitchService } from "./common/service/kill-switch.service";
import { PreDataSetModel } from "./common/model/pre-data-set-model";
import { ConnectionServerModel } from "./common/model/connection-server-model";
import { SessionDataService } from "./common/service/session-data.service";
import { AppViewService } from "./common/service/app-view.service";
import { ClientInfoService } from "./common/service/client-info.service";
import { RootModel } from "../shared/common/model/root-model";
import Logger from "../core/libs/logger";
import { DeactivateGuardService } from "./view/deactive-guard.service";
import { DebugConfigurationModel } from "./common/model/debug-configuration-model";

export abstract class BaseIndexComponent {
   public showChromeMenuBar: boolean;
   public linkRelValue: string;
   public transitionState: string;
   public CLIENT_MODE;
   public needToDisplayLogError: boolean;
   public _isLowerThanIE11;
   public _isFocusedWindow: boolean;

   constructor(
      public fullscreenService: FullscreenService,
      public baseViewService: BaseViewService,
      public clientModeService: ClientModeService,
      private connectionURIModel: ConnectionURIModel,
      public debugConfigurationModel: DebugConfigurationModel,
      public eventBusService: EventBusService,
      public viewClientModel: ViewClientModel,
      public modalDialogService: ModalDialogService,
      public localize: Localize,
      public killSwitchService: KillSwitchService,
      public preDataSetModel: PreDataSetModel,
      public connectionServerModel: ConnectionServerModel,
      public sessionDataService: SessionDataService,
      public appViewService: AppViewService,
      public clientInfoService: ClientInfoService,
      public rootModel: RootModel,
      private deactivateGuardService?: DeactivateGuardService
   ) {
      this._init();
   }

   public _init = () => {
      this.linkRelValue = __REL_FOR_LINK__;

      this.rootModel.set("isAlreadyLogin", false);

      this.debugConfigurationModel.init();

      /**
       *  Used to postpone the initialization for controllers
       *  Check code '$scope.CLIENT_MODE !== null' in index.html
       */
      this.CLIENT_MODE = null;
      this.eventBusService.listen("clientModeChanged").subscribe((msg) => {
         this.CLIENT_MODE = msg.data;
      });

      this._bindEvents();
   };

   // still use central jump control to avoid massive change
   public onLocationChangeStart = (current, next) => {
      let canJump = true;
      if (this.baseViewService.isBackInLauncher(next, current) && !this.baseViewService.locationChange) {
         if (this.appViewService.allowJumpBack) {
            this.appViewService.allowJumpBack = false;
            Logger.info("expected backward jump for error handling", Logger.ROUTE);
         } else {
            Logger.info("disable the manual hash change for launcher", Logger.ROUTE);
            canJump = false;
         }
      } else if (this.baseViewService.isBackFromDesktop(next, current)) {
         Logger.info("expected backward from desktop page");
         setTimeout(() => {
            /**
             * NOTE: using connectionURIModel.isHWSession here is based on that
             * we don't remove params in URI
             */
            if (!this.connectionURIModel.isHWSession() && !clientUtil.isTitanClient()) {
               /**
                *  NOTE: Do not try to maintain the dependencies between
                *  launcher and desktop module now. We just need to go back to
                *  launcher with a refresh action.
                */
               this.baseViewService.goBackToLauncherFromDesktop();
            }
         });
         canJump = false;
      } else if (this.baseViewService.isJumpingToDesktop(next, current)) {
         this.clientModeService.tearDownLauncherService().then(() => {});
      }

      this.baseViewService.locationChange = false;

      // it will just run routeChangeStart not Success.
      if (this.baseViewService.isRefreshingLauncher(next, current)) {
         this.transitionState = "showPage";
      }
      return canJump;
   };

   public onRouteChangeSuccess = () => {
      this.transitionState = "showPage";
   };

   public onRouteChangeStart = () => {
      this.transitionState = "hidePage";
   };

   private _bindEvents = () => {
      if (this.deactivateGuardService) {
         this.deactivateGuardService.bindHashChangeHandler(this.onLocationChangeStart);
      }
      // Listen to any location change event for whole application
      this.appViewService.bindRouteEvents(this.onRouteChangeSuccess, this.onRouteChangeStart);

      this._isLowerThanIE11 = () => {
         return this.viewClientModel.mIsIE && this.viewClientModel.mVersionIE <= 11;
      };
      /**
       * We're going to detect log off event!
       * constants.CLIENT_LOGIN_KEY is deleted in 'view-service.js',
       * and 'jscdk-wrapper.js'
       */
      this._isFocusedWindow = true;
      window.addEventListener("storage", (e: any) => {
         const event: any = e.originalEvent | e;
         if (event.key !== CLIENT.CLIENT_LOGIN_KEY) {
            return;
         }

         /**
          * NOTE: in IE11, the event will also be fired in the focused window.
          * As a result, we need to ignore it.
          */
         if (this._isFocusedWindow && this._isLowerThanIE11()) {
            return;
         }
         if (
            !!event.oldValue &&
            event.oldValue === "true" &&
            (event.newValue === "null" || event.newValue === null || event.newValue === "")
         ) {
            // Once user logs off broker, return any other tabs to portal page
            let launchPageURL;
            if (!window.location.origin) {
               launchPageURL =
                  window.location.protocol +
                  "//" +
                  window.location.hostname +
                  (window.location.port ? ":" + window.location.port : "");
            } else {
               launchPageURL = window.location.origin;
            }

            window.location.href = launchPageURL;
         }
      });
   };
}
