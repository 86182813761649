/**
 * ******************************************************
 * Copyright (C) 2019-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";

/**
 * Based on https://developer.chrome.com/apps/messaging#connect
 * https://developer.chrome.com/extensions/runtime#type-Port
 *
 * suitable for consistent communication, like for multimon for RTAV, CDR.
 *
 * since this chrome.runtime.connect API is working only within the extension, there
 * is no security concern so far.
 *
 * Currently provide no API to unbind callback.
 */

export type PortCallback = (message: any, port: chrome.runtime.Port) => void;

class PortChannel {
   id: string = null;
   type: string = null;
   name: string = null;
   unregisteredCallbacks: Array<PortCallback> = new Array<PortCallback>();
   cachedCallbacks: Array<PortCallback> = new Array<PortCallback>();
   port: chrome.runtime.Port = null;
   description: string = null;
}

/*
 *
 * Keep this service injected with the provider in the Common Module rather than with
 * the @Injectable() decorator.
 *
 * It is used in the shared codes with the @Optional() decorator.
 */

export class ConnectedMessageService {
   private _idFilter: Array<string> = null;
   private _portMap: Map<string, Map<string, PortChannel>> = null;
   private _onPeerOpens: Map<string, Function> = null;
   private _cachedOnPeerOpens: Map<string, Function> = null;

   constructor() {
      this._idFilter = new Array<string>();
      this._portMap = new Map<string, Map<string, PortChannel>>();
      this._onPeerOpens = new Map<string, Function>();
      this._cachedOnPeerOpens = new Map<string, Function>();
      this._initMessage();
   }

   private _initMessage() {
      if (chrome && chrome.runtime.onConnect) {
         chrome.runtime.onConnect.addListener((port) => {
            const info = this._splitKey(port.name);
            if (!(this._idFilter.indexOf(info.id) >= 0)) {
               Logger.error("inner error, invalid session key for the channel named as " + port.name);
               return;
            }
            const entry = this._setPort(info.id, info.type, port, "acceptedChannel");
            if (this._onPeerOpens.has(entry.name)) {
               const opPeerOpen = this._onPeerOpens.get(entry.name);
               this._onPeerOpens.delete(entry.name);
               opPeerOpen();
            }
         });
      }
   }
   private _getName = (id: string, type: string): string => {
      return type + "_ON_" + id;
   };

   private _splitKey = (targetKey: string) => {
      const infoArray = targetKey.split("_ON_");
      return {
         id: infoArray[1],
         type: infoArray[0]
      };
   };

   private _getEntry = (id: string, type: string): PortChannel => {
      if (!this._portMap.has(id)) {
         this._portMap.set(id, new Map<string, PortChannel>());
      }

      const inst = this._portMap.get(id);
      if (!inst.has(type)) {
         const channel = new PortChannel();
         channel.id = id;
         channel.type = type;
         channel.name = this._getName(id, type);
         inst.set(type, channel);
      }
      return this._portMap.get(id).get(type);
   };

   private _setPort = (id: string, type: string, port: chrome.runtime.Port, description: string) => {
      const entry = this._getEntry(id, type);
      if (entry.port) {
         Logger.warning("duplicated port for " + type + ", " + id);
      }
      entry.description = description || "";
      entry.port = port;
      for (let i = 0; i < entry.unregisteredCallbacks.length; i++) {
         port.onMessage.addListener(entry.unregisteredCallbacks[i]);
      }
      entry.unregisteredCallbacks = [];
      return entry;
   };

   public onMessage = (id: string, type: string, callback: PortCallback) => {
      const entry = this._getEntry(id, type);
      // when we disconnect connection, we should use cachedCallbacks to reset the unregisteredCallbacks.
      if (entry.cachedCallbacks.indexOf(callback) === -1) {
         entry.cachedCallbacks.push(callback);
      }
      if (!entry.port) {
         entry.unregisteredCallbacks.push(callback);
      } else {
         entry.port.onMessage.addListener(callback);
      }
   };

   public onPeerOpen = (id: string, type: string, callback: Function) => {
      const entry = this._getEntry(id, type);
      if (!this._cachedOnPeerOpens.has(entry.name)) {
         this._cachedOnPeerOpens.set(entry.name, callback);
      }
      if (!entry.port) {
         this._onPeerOpens.set(entry.name, callback);
      } else {
         callback();
      }
   };

   public addAcceptedId = (id: string) => {
      if (this._idFilter.indexOf(id) === -1) {
         this._idFilter.push(id);
      }
   };

   public initConnection = (id: string, type: string, description?: string) => {
      if (!id || !type) {
         Logger.error("invalid id or type" + id + ", " + type);
      }
      const name = this._getName(id, type);
      const port = chrome.runtime.connect({
         name: name
      });
      this._setPort(id, type, port, "initedChannel");
   };

   public resetConnection = (id: string, type: string) => {
      if (!this._portMap.has(id)) {
         return;
      }

      const entry = this._portMap.get(id);
      if (type) {
         if (entry.has(type)) {
            const channel = entry.get(type);
            if (channel.port) {
               channel.port.disconnect();
               channel.port = null;
               // when port set to null, all unregistered callbacks is cleared, we need to reassign
               channel.unregisteredCallbacks = channel.cachedCallbacks;
               const peerName = this._getName(type, id);
               if (!this._onPeerOpens.has(peerName)) {
                  this._onPeerOpens.set(peerName, this._cachedOnPeerOpens.get(peerName));
               }
            }
         } else {
            for (const [key, channel] of this._portMap.get(id)) {
               if (channel.port) {
                  channel.port.disconnect();
                  channel.port = null;
                  channel.unregisteredCallbacks = channel.cachedCallbacks;
                  const peerName = this._getName(key, id);
                  if (!this._onPeerOpens.has(peerName)) {
                     this._onPeerOpens.set(peerName, this._cachedOnPeerOpens.get(peerName));
                  }
               }
            }
         }
      }
   };

   public releaseConnection = (id: string, type?: string) => {
      if (!this._portMap.has(id)) {
         return;
      }

      if (type) {
         const entry = this._portMap.get(id);
         if (entry.has(type)) {
            const channel = entry.get(type);
            if (channel.port) {
               channel.port.disconnect();
               channel.port = null;
            }
            entry.delete(type);
         }
      } else {
         for (const [key, channel] of this._portMap.get(id)) {
            if (channel.port) {
               channel.port.disconnect();
               channel.port = null;
            }
            this._portMap.get(id).delete(key);
         }
      }

      if (this._portMap.get(id).size === 0) {
         this._portMap.delete(id);
      }

      if (!this._portMap.has(id)) {
         const targetIndex = this._idFilter.indexOf(id);
         if (targetIndex >= 0) {
            this._idFilter.splice(targetIndex, 1);
         }
      }
   };

   public sendMessage = (id: string, type: string, content: any) => {
      if (!this._portMap.has(id) || !this._portMap.get(id).has(type)) {
         if (type !== "Logger") {
            Logger.error("There is no registered channel: " + this._getName(id, type));
            return;
         }
      }
      const channel = this._portMap.get(id).get(type);
      if (!channel.port) {
         if (type !== "Logger") {
            Logger.error("There is no opened port for registered channel: " + this._getName(id, type));
         }
         return;
      }

      try {
         channel.port.postMessage(content);
      } catch {
         Logger.debug("Failed to send message with error " + chrome.runtime.lastError);
      }
   };

   public sendMessageByType = (type: string, content: any) => {
      for (const inst of this._portMap.values()) {
         for (const channel of inst.values()) {
            if (channel.port && channel.type === type) {
               channel.port.postMessage(content);
            }
         }
      }
   };
}
