/**
 * ******************************************************
 * Copyright (C) 2016-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { ClientSettingModel } from "../model/client-setting-model";
import { Injectable } from "@angular/core";
import { ViewClientModel } from "../model/viewclient-model";
import { timezoneIANALink, timezoneMap } from "./timezone-history.consts";

@Injectable({
   providedIn: "root"
})
export class Timezone {
   public timezoneNotInMap: boolean = false;
   public timeZoneMap = timezoneMap;

   constructor(private clientSettingModel: ClientSettingModel) {}
   /*
    * https://wiki.eng.vmware.com/VDM/ClientBrokerXml#
    * TimeOffset_GMT : -07:00
    * TZID : Europe/London
    * Windows_Timezone: GMT Standard Time
    */

   private isDaylightSaving = () => {
      //@ts-ignore
      Date.prototype.stdTimezoneOffset = function () {
         const jan = new Date(this.getFullYear(), 0, 1);
         const jul = new Date(this.getFullYear(), 6, 1);
         return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
      };

      //@ts-ignore
      Date.prototype.dst = function () {
         return this.getTimezoneOffset() < this.stdTimezoneOffset();
      };

      const today = new Date();
      //@ts-ignore
      return !!today.dst();
   };

   private getTimezoneOffsetData = (): string => {
      let offset: number, r: string, h: string;
      //@ts-ignore
      offset = new window.Date().getTimezoneOffset();

      if (this.isDaylightSaving()) {
         offset = offset + 60;
      }

      r = offset < 0 ? "+" : "-";
      offset = offset < 0 ? 0 - offset : offset;

      const temp: Array<string> = String(offset / 60).split(".");
      h = temp[0].length > 1 ? temp[0] : "0" + temp[0];
      r += h;
      r += ":";

      r += temp.length > 1 ? "30" : "00";

      /**
       * As we want to keep remote desktop time the same with local,
       * so  don't consider daylight saving now. Bug 1730455
       */
      Logger.info("Timezone offset: " + r);
      return r;
   };

   public getWindowsTimezone = (timezoneOffset?: string): string => {
      let windowsTimezone: string;
      if (timezoneOffset && timezoneOffset !== "") {
         windowsTimezone = this.timeZoneMap[timezoneOffset];
         Logger.info("Auto get Timezone data: " + windowsTimezone);
         return windowsTimezone;
      }
      if (
         this.clientSettingModel.getStringItem("timezoneSync") === "" ||
         this.clientSettingModel.getStringItem("timezoneSync") === undefined
      ) {
         windowsTimezone = this.windowsTimezoneHandler();
         Logger.info("Auto get Timezone data: " + windowsTimezone);
         return windowsTimezone;
      } else {
         Logger.info("Prefer timezone user preference: " + this.clientSettingModel.getStringItem("timezoneSync"));
         return this.clientSettingModel.getStringItem("timezoneSync").toString();
      }
   };

   private windowsTimezoneHandler = (): string => {
      let windowsTimezone: string;
      const isAutoTimezoneEnabled = this.clientSettingModel.getStringItem("timezoneSync") === "";
      if (!this.isIntlApiSupported() || !isAutoTimezoneEnabled) {
         //Using timezone offset to get timezone
         const timezoneOffset = this.getTimezoneOffsetData();
         windowsTimezone = this.timeZoneMap[timezoneOffset];
      } else {
         //Using new API to directly get timezone
         windowsTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
         this.timezoneNotInMap = false;
         if (!this.timeZoneMap.hasOwnProperty(windowsTimezone)) {
            this.timezoneNotInMap = true;
            windowsTimezone = this.searchTimezoneHistory(windowsTimezone);
         }
      }
      return windowsTimezone;
   };

   private isIntlApiSupported = (): boolean => {
      const apiStatus = Intl.DateTimeFormat().resolvedOptions();
      const res: boolean =
         apiStatus !== void undefined && apiStatus.timeZone !== void undefined && apiStatus.timeZone !== "";
      Logger.info("Intl support status: " + res);
      return res;
   };

   private searchTimezoneHistory = (location): string => {
      let res = location;
      if (timezoneIANALink.hasOwnProperty(location)) {
         Logger.debug("Timezone has an update in history, updated.");
         res = timezoneIANALink[location];
      }
      return res;
   };

   public getTimeZoneInfo = () => {
      return {
         location: Intl.DateTimeFormat().resolvedOptions().timeZone,
         timezoneOffset: this.getTimezoneOffsetData()
      };
   };
}
