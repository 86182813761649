/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * This file is in TS, thus must be compile by webpack/tsc.
 * Currently only used for Chrome Client SDK, but compatible to XML protection.
 * This is only protect sensitive data like AD password from being send in plain text, since the IPC
 * API is not ensured to be protected against message listening by other apps(native).
 * Also this would reduce the chance for memory dump/log dump expose password in plain text.
 *
 * The man in middle attack need to be prevent by other modules, for SDK, it's by OS's IPC API.
 * while Identity is ensured by app-id on Google Web Store, which is protected by signature.
 *
 * for now, only support RSA_OAEP for now for SDK
 * If SDK need to be used FIPS env, more work is needed
 * The security component is built-in by ChromeOS, and private key not exportable
 *
 * Skip the nounce verification for RSA for SDK.
 *
 * the maximum length of a password supported by Active Directory is 256 characters
 * Use modulusLength = 2096 of max-data length of 470 to avoid using dataChunks
 *
 * avoid implement saveKeyForChromeOS to saving jwt keys for Chrome SDK, but allow
 * expose public key in format of spki, and pass it to partner app to do the encoding.
 *
 * @format
 */

import { Logger } from "./logger";

export enum CryptoAlgorithmType {
   None = "None",
   RSA_OAEP = "RSA_OAEP"
   //AES_GCM_256_DH = "AES_GCM_256_DH"//only a place holder for XML protection AES_MODE_2
}

export class SafeLongNumber {
   private data: ArrayBuffer;
   constructor(data: ArrayBuffer) {
      this.data = data;
   }
   public static from = (data: Array<number>): SafeLongNumber => {
      const dataArrayBuffer = new Uint8Array(data).buffer;
      return new SafeLongNumber(dataArrayBuffer);
   };
   public getArrayBuffer = () => {
      return this.data;
   };
   public getArray = () => {
      return Array.from(new Uint8Array(this.data));
   };
}
/*
 * not supporting other browser for now, please add API
 * compatible before used on other browsers
 * TODO: onDHPublicKey, GenerateDHKeyPaire, and generateNounce verifyNounceResponse for now.
 */
class CryptoService {
   private encryptoKey = null; //cyptoKey
   private decryptoKey = null; //cyptoKey
   private algoType: CryptoAlgorithmType;
   private readonly supported: boolean = !!self.crypto && !!self.crypto.subtle && !!self.crypto.subtle.exportKey;

   // since only used on Chrome Client SDk, implement the simpliest logic
   public getSupportedAlgoTypes = (): Array<CryptoAlgorithmType> => {
      if (!this.supported) {
         Logger.info("no support crypto");
         return [CryptoAlgorithmType.None];
      } else {
         Logger.info("support RSA");
         return [CryptoAlgorithmType.RSA_OAEP];
      }
   };

   public getFinalSupportedAlgoType = (peerSupportedList: Array<CryptoAlgorithmType>): CryptoAlgorithmType => {
      if (!this.supported || !Array.isArray(peerSupportedList)) {
         Logger.info("no support crypto");
         return CryptoAlgorithmType.None;
      }
      if (peerSupportedList.includes(CryptoAlgorithmType.RSA_OAEP)) {
         Logger.info("using RSA");
         return CryptoAlgorithmType.RSA_OAEP;
      }
      Logger.info("no support crypto");
      return CryptoAlgorithmType.None;
   };

   public getAlgorithm = (): CryptoAlgorithmType => {
      if (!this.supported) {
         Logger.info("no support crypto");
         return CryptoAlgorithmType.None;
      }
      return this.algoType;
   };
   public setAlgorithm = (algoType: CryptoAlgorithmType) => {
      if (!this.supported) {
         return;
      }
      Logger.info("using crypto Algorithm" + algoType);
      if (!this.supported) {
         return;
      }
      this.algoType = algoType;
   };

   public importEncryptoKey = (publicKeyNumber: SafeLongNumber): Promise<void> => {
      return new Promise((resolve, reject) => {
         Logger.info("importing public key");
         if (!this.supported) {
            reject("Crypto not supported ");
            return;
         }
         const publicKeyArrayBuffer = publicKeyNumber.getArrayBuffer();

         self.crypto.subtle
            .importKey(
               "spki", //public only
               publicKeyArrayBuffer,
               {
                  //these are the algorithm options
                  name: "RSA-OAEP",
                  hash: { name: "SHA-256" } //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
               },
               false, //whether the key is extractable (i.e. can be used in exportKey)
               ["encrypt"] //"encrypt" or "wrapKey" for public key import or
               //"decrypt" or "unwrapKey" for private key imports
            )
            .then((publicKey) => {
               // we get a publicKey object here
               this.encryptoKey = publicKey;
               resolve();
            })
            .catch((err) => {
               Logger.exception(err);
               reject();
            });
      });
   };

   // return numberArray instead of ArrayBuffer to compatible with Chrome OS api.
   // only work for RSA, throw error for sysmatric algorithms
   public getPublicKey = (): Promise<SafeLongNumber> => {
      Logger.info("returning public key");
      return new Promise((resolve, reject) => {
         if (!this.supported) {
            reject("no support crypto");
            return;
         }
         if (!this.encryptoKey) {
            reject("No encryptoKey found");
            return;
         }
         self.crypto.subtle
            .exportKey(
               "spki", //public only
               this.encryptoKey
            )
            .then((keydata) => {
               resolve(new SafeLongNumber(keydata));
            })
            .catch(reject);
      });
   };

   public encrypto = (data: SafeLongNumber): Promise<SafeLongNumber> => {
      return new Promise((resolve, reject) => {
         if (!this.supported) {
            reject("Crypto not supported");
            return;
         }
         const rawData = data.getArrayBuffer();

         if (!this.encryptoKey) {
            reject("No encryptoKey found");
            return;
         }
         self.crypto.subtle
            .encrypt(
               {
                  name: "RSA-OAEP"
               },
               this.encryptoKey,
               rawData
            )
            .then((encrypted) => {
               resolve(new SafeLongNumber(new Uint8Array(encrypted)));
            })
            .catch(reject);
      });
   };

   // below is for SDK, and can't be reused for XML protection, since mismatch with server accepted format
   public encryptString = async (rawString: string): Promise<string> => {
      if (!this.supported) {
         throw "Crypto not supported";
      }
      const rawData = rawString.split("").map((char) => {
         return char.charCodeAt(0);
      });
      const dataNumber = SafeLongNumber.from(rawData);
      const encryptoedData = await this.encrypto(dataNumber);
      return btoa(JSON.stringify(encryptoedData.getArray()));
   };

   // below is for SDK, and not needed for XML protection
   public decryptString = async (rawString: string): Promise<string> => {
      if (!this.supported) {
         throw "Crypto not supported";
      }
      let rawData;
      try {
         const rawArray = JSON.parse(atob(rawString));
         rawData = SafeLongNumber.from(rawArray);
      } catch (e) {
         Logger.exception(e);
         throw "failed to dedecryptString due to string parse failed";
      }
      const decryptedData = await this.decrypto(rawData);
      if (!decryptedData) {
         throw "failed to decrypt valid data";
      }
      const decryptedDataArray = decryptedData.getArray();
      if (!decryptedDataArray || !decryptedDataArray.length) {
         throw "failed to decrypt valid data";
      }
      return decryptedDataArray
         .map((charCode) => {
            return String.fromCharCode(charCode);
         })
         .join("");
   };
   public decrypto = (data: SafeLongNumber): Promise<SafeLongNumber> => {
      return new Promise((resolve, reject) => {
         if (!this.supported) {
            reject("Crypto not supported");
            return;
         }
         if (!this.decryptoKey) {
            reject("No decryptoKey found");
            return;
         }
         const cryptedData = data.getArrayBuffer();
         self.crypto.subtle
            .decrypt(
               {
                  name: "RSA-OAEP"
               },
               this.decryptoKey,
               cryptedData
            )
            .then((decrypted) => {
               resolve(new SafeLongNumber(decrypted));
            })
            .catch(reject);
      });
   };

   // only support RSA for now, implement DH when needed.
   public generateKey = (): Promise<void> => {
      return new Promise((resolve, reject) => {
         if (!this.supported) {
            reject();
            return;
         }
         // 2048 should be enough for long password within length 214.
         self.crypto.subtle
            .generateKey(
               {
                  name: "RSA-OAEP",
                  modulusLength: 4096, //a 4096-bit RSA key using OAEP padding can encrypt up to (4096/8) – 42 = 470 bytes.
                  publicExponent: new Uint8Array([0x01, 0x00, 0x01]), // The most common exponent is 0x10001
                  hash: { name: "SHA-256" } //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
               },
               true, // allow to exportKey
               ["encrypt", "decrypt"] //must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
            )
            .then((key) => {
               //returns a keypair object
               this.decryptoKey = key.privateKey;
               this.encryptoKey = key.publicKey;
               Logger.info("Key generated");
               resolve();
            })
            .catch((err) => {
               reject(err);
            });
      });
   };

   // used for Chrome Client SDK, instead of passing by storage.
   public getDecryptoKeyObject = () => {
      return this.decryptoKey;
   };
   public setDecryptoKeyObject = (decryptoKey) => {
      this.decryptoKey = decryptoKey;
   };
}
export const cryptoService: CryptoService = new CryptoService();
