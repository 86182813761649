/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, AfterViewInit, Optional } from "@angular/core";
import { AbstractModalComponent } from "../commondialog/abstract-modal.component";
import Logger from "../../../core/libs/logger";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { DisplayCheckService } from "../../desktop/common/display-check.service";
import { MonitorInfoService } from "../service/monitor-info.service";
import { DISPLAY } from "../service/monitor-info.service";
import { TranslateService, clientUtil } from "@html-core";
import { ClientSettingModel } from "../model/client-setting-model";
import { ConnectionServerModel } from "../model/connection-server-model";
import { PreDataSetModel } from "../model/pre-data-set-model";
import { DisplayService } from "../../desktop/common/display/display.service";
import { FeatureConfigs } from "../model/feature-configs";

@Component({
   selector: "setting-multi-monitor-dialog",
   templateUrl: "./setting-multi-monitor-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
         .selected-display-area {
            margin-top: 20px;
         }
         .label-text {
            float: left;
            text-align: left;
            margin-right: 20px;
         }
      `
   ]
})
export class SettingMultiMonitorDialogComponent extends AbstractModalComponent implements AfterViewInit {
   private errorDialogId: string = null;
   private hasWindowPlacementPermission: boolean = false;
   // record value before change, used to restore old value
   private _lastDisplaySettingOption: number;
   public adminSettings;
   public adminCommonSettings;
   public hasConflictedDetector;
   public verticalMonitorNumber;
   public maxMonitorNumber;
   public displayDefaultSettingOption;
   public displaySettingOption: number;
   public showSelectScreenPanel;
   public previewSettings;
   public canvasArea;
   public selectedMonitors;

   public displayListDisabled: boolean = false;
   public showCustomWindowArea: boolean = false;
   public customWindowWidth: number;
   public customWindowHeight: number;
   public customWidthValid: boolean = true;
   public customHeightValid: boolean = true;
   public windowWidth: number = window.innerWidth;
   public windowHeight: number = window.innerHeight;
   public invalidWidthWarning: string;
   public invalidHeightWarning: string;
   public OKBtnDisabled: boolean = null;
   public chromeClient: boolean = false;
   public enableAdvancedCodec: boolean = false;

   constructor(
      private modalDialogService: ModalDialogService,
      private displayCheckService: DisplayCheckService,
      private monitorInfoService: MonitorInfoService,
      private translate: TranslateService,
      private clientSettingModel: ClientSettingModel,
      private connectionServerModel: ConnectionServerModel,
      private preDataSetModel: PreDataSetModel,
      private featureConfigs: FeatureConfigs,
      @Optional()
      private _displayService: DisplayService
   ) {
      super();
      this.hasConflictedDetector = false;
      this.displayDefaultSettingOption = DISPLAY.DisplayOption.ALL;
      this.verticalMonitorNumber = 0;
      this.maxMonitorNumber = 4;
      this.invalidWidthWarning = this.translate._T("INVALID_CUSTOM_WINDOW_WIDTH_WARNING", this.windowWidth);
      this.invalidHeightWarning = this.translate._T("INVALID_CUSTOM_WINDOW_HEIGHT_WARNING", this.windowHeight);
      this.adminSettings = this.clientSettingModel.getAdminSettings();
      this.adminCommonSettings = this.connectionServerModel.googleCommonAdminSettings;
      this.displayListDisabled = this._initDisplayList();
      this.chromeClient = clientUtil.isChromeClient();
      this.enableAdvancedCodec =
         this.featureConfigs.getConfig("KillSwitch-WasmBlast") &&
         this.clientSettingModel.getBooleanItem("enableAdvancedCodec");
      this._init();
   }

   ngAfterViewInit() {
      this.canvasArea = document.getElementById("topology-preview-canvas") as HTMLCanvasElement;
      if (
         this.chromeClient ||
         this.displaySettingOption === DISPLAY.DisplayOption.ALL ||
         this.displaySettingOption === DISPLAY.DisplayOption.SELECTED
      ) {
         this.initPreview();
      }
   }

   ngOnDestroy() {
      if (this.chromeClient && chrome.system.display.onDisplayChanged.hasListener(this.initPreview)) {
         chrome.system.display.onDisplayChanged.removeListener(this.initPreview);
      }
   }

   private _init = () => {
      this._getAllSettings();
      this.setSelectPanelVisibility();
      this.setCustomWindowAreaVisibility();
      if (
         !!this.adminCommonSettings &&
         this.adminCommonSettings.hasOwnProperty("display") &&
         this.adminCommonSettings["display"].hasOwnProperty("width") &&
         this.adminCommonSettings["display"].hasOwnProperty("height") &&
         this.adminCommonSettings["display"]["width"] <= clientUtil.getScreenWidth() &&
         this.adminCommonSettings["display"]["height"] <= window.screen.height
      ) {
         this.customWindowWidth = this.adminCommonSettings["display"]["width"];
         this.customWindowHeight = this.adminCommonSettings["display"]["height"];
      }
      if (
         !!this.adminCommonSettings &&
         this.adminCommonSettings.hasOwnProperty("display") &&
         typeof this.adminCommonSettings["display"] === "string" &&
         this.adminCommonSettings["display"].indexOf("width") !== -1 &&
         this.adminCommonSettings["display"].indexOf("height") !== -1
      ) {
         const customWindowString = this.adminCommonSettings["display"];
         const widthStartPos = customWindowString.indexOf(":") + 1;
         const widthEndPos = customWindowString.indexOf(",");
         const widthStr = customWindowString.substring(widthStartPos, widthEndPos);
         const widthNum = Number(widthStr);
         const heightStartPos = customWindowString.lastIndexOf(":") + 1;
         const heightEndPos = customWindowString.lastIndexOf("}");
         const heightStr = customWindowString.substring(heightStartPos, heightEndPos);
         const heightNum = Number(heightStr);
         if (widthNum <= clientUtil.getScreenWidth() && heightNum <= window.screen.height) {
            this.customWindowWidth = widthNum;
            this.customWindowHeight = heightNum;
         }
      }
      if (typeof this.clientSettingModel.getPrefNumberItem("customWindowWidth") === "number") {
         this.customWindowWidth = this.clientSettingModel.getPrefNumberItem("customWindowWidth");
      }
      if (typeof this.clientSettingModel.getPrefNumberItem("customWindowHeight") === "number") {
         this.customWindowHeight = this.clientSettingModel.getPrefNumberItem("customWindowHeight");
      }
      if (this.chromeClient && !chrome.system.display.onDisplayChanged.hasListener(this.initPreview)) {
         //Listen to Chrome system display change event
         chrome.system.display.onDisplayChanged.addListener(this.initPreview);
      }
   };

   private _initDisplayList = () => {
      /**
       * "enableMultiMonitor" is used for old policy, "display" is used for new policy
       * the priority is "display" in common setting "editable" > "enableMultiMonitor"
       * in broker setting "editable" > "enableMultiMonitor" in common setting "editable"
       **/
      if (!!this.adminCommonSettings && this.adminCommonSettings.hasOwnProperty("display")) {
         if (!this.adminCommonSettings.hasOwnProperty("editable")) {
            return true;
         } else {
            if (
               this.adminCommonSettings["editable"]["display"] === undefined ||
               this.adminCommonSettings["editable"]["display"] === false
            ) {
               return true;
            } else if (this.adminCommonSettings["editable"]["display"] === true) {
               return false;
            }
         }
      } else if (!!this.adminSettings && this.adminSettings.hasOwnProperty("enableMultiMonitor")) {
         if (!this.adminSettings.hasOwnProperty("editable")) {
            return true;
         } else {
            if (
               this.adminSettings["editable"]["enableMultiMonitor"] === undefined ||
               this.adminSettings["editable"]["enableMultiMonitor"] === false
            ) {
               return true;
            } else if (this.adminSettings["editable"]["enableMultiMonitor"] === true) {
               return false;
            }
         }
      } else if (!!this.adminCommonSettings && this.adminCommonSettings.hasOwnProperty("enableMultiMonitor")) {
         if (!this.adminCommonSettings.hasOwnProperty("editable")) {
            return true;
         } else {
            if (
               this.adminCommonSettings["editable"]["enableMultiMonitor"] === undefined ||
               this.adminCommonSettings["editable"]["enableMultiMonitor"] === false
            ) {
               return true;
            } else if (this.adminCommonSettings["editable"]["enableMultiMonitor"] === true) {
               return false;
            }
         }
      } else {
         return false;
      }
   };

   private _getAllSettings = () => {
      this.displaySettingOption = Number(this.monitorInfoService.getDisplaySettingOption());
      this._lastDisplaySettingOption = isNaN(this.displaySettingOption)
         ? this.displaySettingOption
         : DISPLAY.DisplayOption.SINGLE;
      this.selectedMonitors = this.monitorInfoService.getSettingScreen();
   };

   public verifyCustomWidth = () => {
      const width = Number($("#width-area").val());
      if (width > 0 && width <= window.innerWidth && width % 1 === 0) {
         this.customWidthValid = true;
         this.OKBtnDisabled = null;
      } else {
         this.customWidthValid = false;
         this.OKBtnDisabled = true;
      }
   };

   public verifyCustomHeight = () => {
      const height = Number($("#height-area").val());
      if (height > 0 && height <= window.innerHeight && height % 1 === 0) {
         this.customHeightValid = true;
         this.OKBtnDisabled = null;
      } else {
         this.customHeightValid = false;
         this.OKBtnDisabled = true;
      }
   };

   public setSelectPanelVisibility = () => {
      if (this.displaySettingOption === DISPLAY.DisplayOption.SELECTED) {
         this.showSelectScreenPanel = true;
      } else {
         this.showSelectScreenPanel = false;
      }
   };

   public setCustomWindowAreaVisibility = () => {
      if (this.displaySettingOption === DISPLAY.DisplayOption.CUSTOM_WINDOW) {
         this.showCustomWindowArea = true;
      } else {
         this.showCustomWindowArea = false;
      }
   };

   public setDisplaySetting = (change) => {
      this.displaySettingOption = Number(change.target.value);
      if (!this.chromeClient && !this.hasWindowPlacementPermission) {
         if (
            this.displaySettingOption === DISPLAY.DisplayOption.ALL ||
            this.displaySettingOption === DISPLAY.DisplayOption.SELECTED
         ) {
            this.initPreview();
            return;
         }
      }
      this._updateSelectPanelVisibility(this.displaySettingOption);
   };

   private _updateSelectedMonitor = (option) => {
      this.selectedMonitors = null;
      // Only when using multi monitors, this.previewSettings can be defined
      if (this.previewSettings) {
         this.previewSettings.content = this.monitorInfoService.setSelectStatus(
            option,
            this.selectedMonitors,
            this.previewSettings.content
         );
      }
      if (option === DISPLAY.DisplayOption.SELECTED) {
         this.updatePreviewSettings(this.previewSettings.content, this.canvasArea.width, this.canvasArea.height);
         this.updatePreview();
      }
   };

   public saveDisplaySetting = () => {
      if (this.displaySettingOption === DISPLAY.DisplayOption.CUSTOM_WINDOW) {
         this.customWindowWidth = Number((<HTMLInputElement>document.getElementById("width-area")).value);
         this.customWindowHeight = Number((<HTMLInputElement>document.getElementById("height-area")).value);
         this.previewSettings.content = this.monitorInfoService.setSelectStatus(
            this.displaySettingOption,
            this.selectedMonitors,
            this.previewSettings.content,
            this.customWindowWidth,
            this.customWindowHeight
         );
      }

      let selectedMonitors = [];
      // if window-placement permision is denied, there is no previewSettings
      if (this.previewSettings) {
         selectedMonitors = this.monitorInfoService.getSelectedMonitors(
            this.previewSettings.content,
            this.displaySettingOption
         );
      }

      if (!this.monitorInfoService.hasAdjacentRect(selectedMonitors)) {
         this._showErrorMessage("MM_NONADJACENT_MONITOR");
         return;
      }

      if (this._displayService) {
         this._displayService.setSelectedMonitor(this.displaySettingOption, selectedMonitors);
      }

      this.monitorInfoService.saveDisplaySetting(
         this.displaySettingOption,
         selectedMonitors,
         this.customWindowWidth,
         this.customWindowHeight
      );

      this.closeModal();
   };

   /**
    * Helper function to derive the settings suitable to be previewed
    * @param {object} content The content to be previewed
    * @return {object} This returns a object containing all adjusted rect
    *    and requested canvas size
    */
   public updatePreviewSettings = (content, maxWidth, maxHeight) => {
      let key,
         baseOffset,
         currentRect,
         scale,
         visibleScaleFactor,
         canvasSize,
         setting,
         overallWidth,
         overallHeight,
         scaleFactor = 0.85,
         rects = {},
         overallRect = null;

      for (key in content) {
         if (content.hasOwnProperty(key)) {
            setting = content[key].settings;
            currentRect = {
               minX: setting.x,
               minY: setting.y,
               maxX: setting.x + setting.width,
               maxY: setting.y + setting.height
            };
            if (!overallRect) {
               overallRect = currentRect;
            } else {
               overallRect.minX = Math.min(overallRect.minX, currentRect.minX);
               overallRect.minY = Math.min(overallRect.minY, currentRect.minY);
               overallRect.maxX = Math.max(overallRect.maxX, currentRect.maxX);
               overallRect.maxY = Math.max(overallRect.maxY, currentRect.maxY);
            }
         }
      }

      overallWidth = overallRect.maxX - overallRect.minX;
      overallHeight = overallRect.maxY - overallRect.minY;
      scale = Math.min(maxWidth / overallWidth, maxHeight / overallHeight);
      visibleScaleFactor = scale * scaleFactor;
      canvasSize = {
         width: Math.floor(scale * overallWidth),
         height: Math.floor(scale * overallHeight)
      };
      baseOffset = {
         x: (1 - scaleFactor) * 0.5 * overallWidth * scale,
         y: (1 - scaleFactor) * 0.5 * overallHeight * scale
      };

      for (key in content) {
         if (content.hasOwnProperty(key)) {
            setting = content[key].settings;
            rects[key] = {
               x: Math.round((setting.x - overallRect.minX) * visibleScaleFactor + baseOffset.x),
               y: Math.round((setting.y - overallRect.minY) * visibleScaleFactor + baseOffset.y),
               width: Math.round(setting.width * visibleScaleFactor),
               height: Math.round(setting.height * visibleScaleFactor)
            };
            rects[key].conflicted = this.displayCheckService.hasConflict(key, content);
         }
      }

      this.previewSettings = {
         content: content,
         rects: rects,
         canvasOffset: {
            x: Math.floor(maxWidth - canvasSize.width) / 2,
            y: Math.floor(maxHeight - canvasSize.height) / 2
         }
      };
   };

   /**
    * display the monitor status and position as rect for user to preview
    * @param {object} content The previewed content, it contains all
    */
   public updatePreview = () => {
      let key,
         offset,
         rects,
         rect,
         previewContext,
         rectNumber = 0,
         previewCanvas = this.canvasArea;

      if (!previewCanvas || typeof previewCanvas.getContext !== "function") {
         return;
      }
      previewContext = previewCanvas.getContext("2d");
      rects = this.previewSettings.rects;

      offset = this.previewSettings.canvasOffset;
      previewContext.beginPath();
      previewContext.rect(0, 0, previewCanvas.width, previewCanvas.height);
      previewContext.fillStyle = "#cccccc";
      previewContext.fill();
      this.hasConflictedDetector = false;
      this.verticalMonitorNumber = 0;
      for (key in rects) {
         if (rects.hasOwnProperty(key)) {
            rect = rects[key];
            rectNumber++;
            if (rect.height > rect.width) {
               this.verticalMonitorNumber++;
            }
            //TODO: need PM's input for UX details
            if (!rect.conflicted) {
               previewContext.beginPath();
               previewContext.rect(offset.x + rect.x, offset.y + rect.y, rect.width, rect.height);
               const isCurrentScreen = this._displayService.isCurrentScreen({
                  left: this.previewSettings.content[key].settings.x,
                  top: this.previewSettings.content[key].settings.y
               });
               if (this.previewSettings.content[key].selected || isCurrentScreen) {
                  previewContext.fillStyle = "#77FF77";
               } else {
                  previewContext.fillStyle = "#777777";
               }
               previewContext.fill();
               previewContext.lineWidth = 2;
               previewContext.strokeStyle = "black";
               previewContext.stroke();
            } else {
               this.hasConflictedDetector = true;
            }
         }
      }
   };

   private _showErrorMessage = (key) => {
      if (this.modalDialogService.isDialogOpen(this.errorDialogId)) {
         this.modalDialogService.close(this.errorDialogId);
      }

      this.errorDialogId = this.modalDialogService.showError({
         data: {
            titleKey: "WARNING",
            contentKey: key
         }
      });
   };

   public initPreview = () => {
      this.monitorInfoService
         .getScreensInfo(this.displaySettingOption, this.selectedMonitors)
         .then((content: any) => {
            if (!this.monitorInfoService.hasAdjacentRect(content)) {
               this._showErrorMessage("MM_NONADJACENT_MONITOR");
            }
            this.updatePreviewSettings(content, this.canvasArea.width, this.canvasArea.height);
            this.updatePreview();
            if (!this.chromeClient) {
               this._updateSelectPanelVisibility(this.displaySettingOption);
               this.hasWindowPlacementPermission = true;
            }
         })
         .catch((e) => {
            if (this._displayService.windowReplacementPermissionStatus === "denied") {
               this.modalDialogService.showError({
                  data: {
                     titleKey: "ERROR",
                     contentKey: "MUST_GRANT_WINDOW_REPLACEMENT_PERMISSION"
                  },
                  callbacks: {
                     confirm: () => {
                        if (
                           this.displaySettingOption === DISPLAY.DisplayOption.ALL ||
                           this.displaySettingOption === DISPLAY.DisplayOption.SELECTED
                        ) {
                           this.displaySettingOption = DISPLAY.DisplayOption.SINGLE;
                        }
                     }
                  }
               });
            }
         });
   };

   private _highlightSelectScreen = (selectStatus, id) => {
      const highlightTime = 3000;
      if (selectStatus) {
         try {
            //Use overscanCalibrationStart to highlight the selected screen
            chrome.system.display.overscanCalibrationStart(id);
            setTimeout(() => {
               chrome.system.display.overscanCalibrationComplete(id);
            }, highlightTime);
         } catch (e) {
            Logger.info("Failed to use overscanCalibrationStart.");
         }
      }
   };

   public selectMonitor = (event) => {
      let key,
         offset,
         rects,
         rect,
         content,
         selectX = event.offsetX,
         selectY = event.offsetY;
      rects = this.previewSettings.rects;
      offset = this.previewSettings.canvasOffset;
      content = this.previewSettings.content;
      for (key in rects) {
         if (rects.hasOwnProperty(key)) {
            rect = rects[key];
            if (
               selectX >= offset.x + rect.x &&
               selectX <= offset.x + rect.x + rect.width &&
               selectY >= offset.y + rect.y &&
               selectY <= offset.y + rect.y + rect.height
            ) {
               const selectStatus = !this.previewSettings.content[key].selected;
               // only when on webclient and windowReplacementApi is enable, there is aisCurrentScreen key exists.
               // So this code only for windowReplacementApi on webclient
               const isCurrentScreen = this._displayService.isCurrentScreen({
                  left: this.previewSettings.content[key].settings.x,
                  top: this.previewSettings.content[key].settings.y
               });
               if (isCurrentScreen) {
                  if (!selectStatus) {
                     this._showErrorMessage("MM_CURRENT_MONITOR_MUSTBE_SELECTED");
                  }
                  return;
               }
               content[key].selected = selectStatus;
               if (this.monitorInfoService.hasAdjacentRect(content)) {
                  Logger.debug("Select adjacent monitors");
                  this.previewSettings.content[key].selected = selectStatus;
                  const id = this.previewSettings.content[key].settings.id;
                  this._highlightSelectScreen(selectStatus, id);
               } else {
                  content[key].selected = !selectStatus;
                  Logger.info("stop entering multimon due to select non adjacent monitors");
                  this._showErrorMessage("MM_NONADJACENT_MONITOR");
               }
               break;
            }
         }
      }
      this.updatePreview();
   };

   public multiLaunchOkClicked = () => {
      this.saveDisplaySetting();
      if (this.chromeClient) {
         chrome.system.display.onDisplayChanged.removeListener(() => {});
      }
   };

   private _updateSelectPanelVisibility = (displaySettingOption) => {
      this._lastDisplaySettingOption = displaySettingOption;
      this.setSelectPanelVisibility();
      this.setCustomWindowAreaVisibility();
      Logger.debug("Display setting value is : " + displaySettingOption);
      this._updateSelectedMonitor(displaySettingOption);
   };
}
