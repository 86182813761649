/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import { JKEYS } from "../channels/html5MMR-client/html5MMR-consts";

/**
 *  WebRTC Service adapter that handles standard webrtc API interfaces
 */
class WebRTCAdapter {
   constructor() {}

   public getUserMedia = async (constraints: any) => {
      if (clientUtil.isSafari()) {
         return top.navigator.mediaDevices.getUserMedia(constraints);
      } else {
         // for Chrome, Firefox and Edge
         return navigator.mediaDevices.getUserMedia(constraints);
      }
   };

   public getDisplayMedia = async (constraints: any) => {
      //@ts-ignore
      return navigator.mediaDevices.getDisplayMedia(constraints);
   };

   public enumerateDevices = async () => {
      if (clientUtil.isSafari()) {
         return top.navigator.mediaDevices.enumerateDevices();
      } else {
         // for Chrome, Firefox and Edge
         return navigator.mediaDevices.enumerateDevices();
      }
   };

   /**
    * Get RTCRtpReceiver and RTCRtpSender audio&video capabilites in current envFlag
    * Including codec set, headerExtensions
    *
    * @returns{object}
    */
   public getRtpCapabilities = () => {
      const result = {};

      // RTCRtpReceiver
      result[JKEYS.RECEIVER] = {};
      result[JKEYS.RECEIVER][JKEYS.AUDIO] = RTCRtpReceiver.getCapabilities(JKEYS.AUDIO);
      result[JKEYS.RECEIVER][JKEYS.VIDEO] = RTCRtpReceiver.getCapabilities(JKEYS.VIDEO);

      // RTCRtpSender
      result[JKEYS.SENDER] = {};
      result[JKEYS.SENDER][JKEYS.AUDIO] = RTCRtpSender.getCapabilities(JKEYS.AUDIO);
      result[JKEYS.SENDER][JKEYS.VIDEO] = RTCRtpSender.getCapabilities(JKEYS.VIDEO);

      return result;
   };
}

export const webRTCAdapter = new WebRTCAdapter();
