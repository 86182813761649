/**
 * ******************************************************
 * Copyright (C) 2020-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { IMessage } from "./abstract-message.service";
import { HTML5MMR as MMR } from "../../../shared/desktop/channels/html5MMR-client/model/html5MMR.e911.models";
import { ErrorAction } from "../../../shared/jscdk/jscdk-interface";

export namespace BusEvent {
   export class UpdateSettingDialogStatus implements IMessage {
      public static readonly MSG_TYPE = "UpdateSettingDialog";
      public type: string;
      public status: boolean;
      constructor(status: boolean) {
         this.type = UpdateSettingDialogStatus.MSG_TYPE;
         this.status = status;
      }
   }

   export class DPIDataChangeEvent implements IMessage {
      public static readonly MSG_TYPE = "DPIDataChange";
      public type: string;
      public wmksKey: string;
      public remoteScaleDPI: number;
      public session: any;

      constructor(key: string, dpi: number, session: any) {
         this.type = DPIDataChangeEvent.MSG_TYPE;
         this.wmksKey = key;
         this.remoteScaleDPI = dpi;
         this.session = session;
      }
   }

   export class DPIDataSyncSuccessEvent implements IMessage {
      public static readonly MSG_TYPE = "DPIDataSyncSuccess";
      public type: string;
      public wmksKey: string;
      public remoteScaleDPI: number;

      constructor(key: string, dpi: number) {
         this.type = DPIDataSyncSuccessEvent.MSG_TYPE;
         this.wmksKey = key;
         this.remoteScaleDPI = dpi;
      }
   }

   export class CertAuth implements IMessage {
      public static readonly MSG_TYPE = "certAuth";
      public type: string;
      public enable: boolean;

      constructor(enable: boolean) {
         this.type = CertAuth.MSG_TYPE;
         this.enable = enable;
      }
   }
   export class RdsLicenseMsg implements IMessage {
      public static readonly MSG_TYPE = "rdsLicense";
      public license: string;
      public type: string = RdsLicenseMsg.MSG_TYPE;

      constructor(license: string) {
         this.license = license;
      }
   }
   export class LaunchInitMsg implements IMessage {
      public static readonly MSG_TYPE = "launchInit";
      public type: string = LaunchInitMsg.MSG_TYPE;
   }

   export class ResumeAudio implements IMessage {
      public static readonly MSG_TYPE = "resumeAudio";
      public type: string = ResumeAudio.MSG_TYPE;
   }

   export class FileAssociationLoadingMsg implements IMessage {
      public static readonly MSG_TYPE = "loadingFileAssociation";
      public type: string = FileAssociationLoadingMsg.MSG_TYPE;
      public serverAddress: string;
      public show: boolean;
      constructor(show: boolean, serverAddress: string) {
         this.show = show;
         this.serverAddress = serverAddress;
      }
   }

   export class UpdateMultiMonCapacityMsg implements IMessage {
      public static readonly MSG_TYPE = "updatemultimoncapacityui";
      public type: string = UpdateMultiMonCapacityMsg.MSG_TYPE;
      public enable: boolean;
      public webmks: any;
      constructor(enable: boolean, webmks: any) {
         this.enable = enable;
         this.webmks = webmks;
      }
   }

   export class MultiMonitorsMsg implements IMessage {
      public static readonly MSG_TYPE = "multipleMonitors";
      public type: string = MultiMonitorsMsg.MSG_TYPE;
      public enter: boolean;
      constructor(enter: boolean) {
         this.enter = enter;
      }
   }

   export class FileTransferMsg implements IMessage {
      public static readonly MSG_TYPE = "disableFTPanel";
      public type: string = FileTransferMsg.MSG_TYPE;
      public data: boolean;
      constructor(data?: boolean) {
         this.data = data;
      }
   }

   export class UsbSessionChangedMsg implements IMessage {
      public static readonly MSG_TYPE = "usbSessionChangedMsg";
      public type: string = UsbSessionChangedMsg.MSG_TYPE;
      public enabled: boolean;
      constructor(enable: boolean) {
         this.enabled = enable;
      }
   }

   export class ToggleUsbMsg implements IMessage {
      public static readonly MSG_TYPE = "ToggleUsbMsg";
      public type: string = ToggleUsbMsg.MSG_TYPE;
   }

   export class SettingOptionsChangedMsg implements IMessage {
      public static readonly MSG_TYPE = "SettingOptionsChangedMsg";
      public type: string = SettingOptionsChangedMsg.MSG_TYPE;
   }

   export class ShowCertMsg implements IMessage {
      public static readonly MSG_TYPE = "ShowCertMsg";
      public type: string = ShowCertMsg.MSG_TYPE;
      public show: boolean;
      constructor(show: boolean) {
         this.show = show;
      }
   }

   export class ShowWs1WebViewMsg implements IMessage {
      public static readonly MSG_TYPE = "ShowWs1WebView";
      public type: string = ShowWs1WebViewMsg.MSG_TYPE;
      public show: boolean;
      public url: string;
      public serverType: string;
      constructor(content: { show: boolean; url: string; serverType: string }) {
         this.show = content.show;
         this.url = content.url;
         this.serverType = content.serverType;
      }
   }

   export class Ws1WebViewUrlMsg implements IMessage {
      public static readonly MSG_TYPE = "Ws1WebViewUrlMsg";
      public type: string = Ws1WebViewUrlMsg.MSG_TYPE;
      public url: string;
      constructor(content: { url: string }) {
         this.url = content.url;
      }
   }

   export class AjaxBusyMsg implements IMessage {
      public static readonly MSG_TYPE = "AjaxBusyMsg";
      public type: string = AjaxBusyMsg.MSG_TYPE;
      public busy: boolean;
      constructor(busy: boolean) {
         this.busy = busy;
      }
   }

   export class PanelEventMsg implements IMessage {
      public static readonly MSG_TYPE = "PanelEventMsg";
      public type: string = PanelEventMsg.MSG_TYPE;
      public isHide: boolean;
      constructor(isHide: boolean) {
         this.isHide = isHide;
      }
   }

   export class SessionCloseMsg implements IMessage {
      public static readonly MSG_TYPE = "SessionCloseMsg";
      public type: string = SessionCloseMsg.MSG_TYPE;
      public wmksKey: string;
      constructor(wmksKey: string) {
         this.wmksKey = wmksKey;
      }
   }

   export class SystemTrayEnabledMsg implements IMessage {
      public static readonly MSG_TYPE = "SystemTrayEnabledMsg";
      public type: string = SystemTrayEnabledMsg.MSG_TYPE;
      public isEnabled: string | boolean;
      constructor(isEnabled: string | boolean) {
         this.isEnabled = isEnabled;
      }
   }

   export class SearchFocusMsg implements IMessage {
      public static readonly MSG_TYPE = "SearchFocusMsg";
      public type: string = SearchFocusMsg.MSG_TYPE;
   }

   export class SessionConnectMsg implements IMessage {
      public static readonly MSG_TYPE = "SessionConnectMsg";
      public type: string = SessionConnectMsg.MSG_TYPE;
      public connecting: boolean;
      constructor(connecting: boolean) {
         this.connecting = connecting;
      }
   }

   export class SetTitanModeMsg implements IMessage {
      public static readonly MSG_TYPE = "SetTitanModeMsg";
      public type: string = SetTitanModeMsg.MSG_TYPE;
      public isNewAdd: boolean;
      constructor(isNewAdd: boolean) {
         this.isNewAdd = isNewAdd;
      }
   }

   export class TitanAuthDoneMsg implements IMessage {
      public static readonly MSG_TYPE = "TitanAuthDoneMsg";
      public type: string = TitanAuthDoneMsg.MSG_TYPE;
      public isDone: boolean;
      public isGoToPortal: boolean;
      constructor(isDone: boolean, isGoToPortal?: boolean) {
         this.isDone = isDone;
         this.isGoToPortal = isGoToPortal;
      }
   }

   export class ClipboardClientContentMsg implements IMessage {
      public static readonly MSG_TYPE = "ClipboardClientContentMsg";
      public type: string = ClipboardClientContentMsg.MSG_TYPE;
      public text: string;
      public html: string;
      constructor(content: { text: string; html: string }) {
         this.text = content.text;
         this.html = content.html;
      }
   }

   export class ClipboardAgentContentMsg implements IMessage {
      public static readonly MSG_TYPE = "ClipboardAgentContentMsg";
      public type: string = ClipboardAgentContentMsg.MSG_TYPE;
      public text: string;
      public html: string;
      constructor(content: { text: string; html: string }) {
         this.text = content.text;
         this.html = content.html;
      }
   }

   export class ClipboardGPOMsg implements IMessage {
      public static readonly MSG_TYPE = "ClipboardGPOMsg";
      public type: string = ClipboardGPOMsg.MSG_TYPE;
      public copyEnabled: boolean;
      public pasteEnabled: boolean;
      constructor(content: { copyEnabled: boolean; pasteEnabled: boolean }) {
         this.copyEnabled = content.copyEnabled;
         this.pasteEnabled = content.pasteEnabled;
      }
   }

   export class ClipboardContentHashValue implements IMessage {
      public static readonly MSG_TYPE = "ClipboardContentHashValue";
      public type: string = ClipboardContentHashValue.MSG_TYPE;
      public clipboardContentHashValue: any;
      constructor(content: { clipboardContentHashValue: any }) {
         this.clipboardContentHashValue = content.clipboardContentHashValue;
      }
   }

   export class WebClipboardAgentContentMsg implements IMessage {
      public static readonly MSG_TYPE = "WebClipboardAgentContentMsg";
      public type: string = WebClipboardAgentContentMsg.MSG_TYPE;
      public text: string;
      public html: string;
      constructor(content: { text: string; html: string }) {
         this.text = content.text;
         this.html = content.html;
      }
   }

   export class ClearActiveClient implements IMessage {
      public static readonly MSG_TYPE = "ClearActiveClient";
      public type: string;
      constructor() {
         this.type = ClearActiveClient.MSG_TYPE;
      }
   }

   // TODO: add types and move to seperat file
   export class StopBlockLaunchingFromSDK implements IMessage {
      public static readonly MSG_TYPE = "StopBlockLaunchingFromSDK";
      public type: string;
      public reason: string;
      constructor(reason?: string) {
         this.type = StopBlockLaunchingFromSDK.MSG_TYPE;
         this.reason = reason || "unknown reason";
      }
   }

   export class AuthFailedMsg implements IMessage {
      public static readonly MSG_TYPE = "AuthFailedMsg";
      public type: string = AuthFailedMsg.MSG_TYPE;
      public authType: string;
      public errorMessage: string;
      public retryAllowed: boolean;
      constructor(authType: string, errorMessage: string, retryAllowed: boolean) {
         this.authType = authType;
         this.errorMessage = errorMessage;
         this.retryAllowed = retryAllowed;
      }
   }

   export class ItemLaunchFailed implements IMessage {
      public static readonly MSG_TYPE = "ItemLaunchFailed";
      public type: string = ItemLaunchFailed.MSG_TYPE;
      public data: any;
      constructor(data) {
         this.data = data;
      }
   }

   export class ItemLaunchSucceeded implements IMessage {
      public static readonly MSG_TYPE = "ItemLaunchSucceeded";
      public type: string = ItemLaunchSucceeded.MSG_TYPE;
      public data: any;
      constructor(data) {
         this.data = data;
      }
   }

   export class ConnectBrokerFailed implements IMessage {
      public static readonly MSG_TYPE = "ConnectBrokerFailed";
      public type: string = ConnectBrokerFailed.MSG_TYPE;
      public data: any;
      constructor(data) {
         this.data = data;
      }
   }

   export class DisclaimerDisplayed implements IMessage {
      public static readonly MSG_TYPE = "DisclaimerDisplayed";
      public type: string = DisclaimerDisplayed.MSG_TYPE;
      constructor(public data: { disclaimerText: string; clientData?: any }) {}
   }

   // no sensitive data should passed in this message
   export class AuthenticationNext implements IMessage {
      public static readonly MSG_TYPE = "AuthenticationNext";
      public type: string = AuthenticationNext.MSG_TYPE;
      public data: any;
      public authType: string;
      constructor(authType, data) {
         this.authType = authType;
         this.data = data;
      }
   }

   export class AuthenticationDeclined implements IMessage {
      public static readonly MSG_TYPE = "AuthenticationDeclined";
      public type: string = AuthenticationDeclined.MSG_TYPE;
      public data: any;
      constructor(data) {
         this.data = data;
      }
   }

   export class ReconnectAllSessionsMsg implements IMessage {
      public static readonly MSG_TYPE = "ReconnectAllSessionsMsg";
      public type: string = ReconnectAllSessionsMsg.MSG_TYPE;
      constructor(
         public isMultiSession: boolean,
         public skipped: string
      ) {}
   }

   export class QuitMultiMonMsg implements IMessage {
      public static readonly MSG_TYPE = "QuitMultiMonMsg";
      public type: string = QuitMultiMonMsg.MSG_TYPE;
   }

   export class WindowOpenIsBlocked implements IMessage {
      public static readonly MSG_TYPE = "WindowOpenIsBlocked";
      public type: string = WindowOpenIsBlocked.MSG_TYPE;
   }

   export class TitanCloseClient implements IMessage {
      public static readonly MSG_TYPE = "TitanCloseClient";
      public type: string = TitanCloseClient.MSG_TYPE;
   }

   export class LogOutMsg implements IMessage {
      public static readonly MSG_TYPE = "LogOutMsg";
      public type: string = LogOutMsg.MSG_TYPE;
      public silence: boolean;
      constructor(silence: boolean) {
         this.silence = silence;
      }
   }

   export class PendingLaunchOps implements IMessage {
      public static readonly MSG_TYPE = "PendingLaunchOps";
      public type: string = PendingLaunchOps.MSG_TYPE;
      public id: string;
      public isApp: boolean;
      public spec: DSpecToken;
      constructor(id: string, isApp: boolean, spec: DSpecToken) {
         this.id = id;
         this.isApp = isApp;
         this.spec = spec;
      }
   }

   export class PendingResumptionOps implements IMessage {
      public static readonly MSG_TYPE = "PendingResumptionOps";
      public type: string = PendingResumptionOps.MSG_TYPE;
      public sid: string;
      constructor(sid: string) {
         this.sid = sid;
      }
   }

   export class GeolocationInfo implements IMessage {
      public static readonly MSG_TYPE = "GeolocationInfo";
      public type: string = GeolocationInfo.MSG_TYPE;
      public data: GeolocationPosition;
      constructor(data: GeolocationPosition) {
         this.data = data;
      }
   }

   export class StopGeolocationService implements IMessage {
      public static readonly MSG_TYPE = "StopGeolocationService";
      public type: string = GeolocationInfo.MSG_TYPE;
      constructor() {}
   }

   export class E911InfoMsg implements IMessage {
      public static readonly MSG_TYPE = "E911Info";
      public type: string = E911InfoMsg.MSG_TYPE;
      public data: MMR.E911Info;
      constructor(data: MMR.E911Info) {
         this.data = data;
      }
   }

   export class RefreshEntitlementMsg implements IMessage {
      public static readonly MSG_TYPE = "RefreshEntitlementMsg";
      public type: string = RefreshEntitlementMsg.MSG_TYPE;
   }

   export class UnityClientRequest implements IMessage {
      public static readonly MSG_TYPE = "UnityClientRequest";
      public type: string;
      public data: any;
      constructor(data: any) {
         this.type = UnityClientRequest.MSG_TYPE;
         this.data = data;
      }
   }

   export class RestoreAppRequest implements IMessage {
      public static readonly MSG_TYPE = "RestoreAppRequest";
      public type: string;
      public wmksKey: string;
      public windowsIds: Array<string>;
      constructor(wmksKey: string, windowsIds: Array<string>) {
         this.type = RestoreAppRequest.MSG_TYPE;
         this.wmksKey = wmksKey;
         this.windowsIds = windowsIds;
      }
   }

   export class UnityUpdates implements IMessage {
      public static readonly MSG_TYPE = "UnityUpdates";
      public type: string;
      public data: any;
      constructor(data: any) {
         this.type = UnityUpdates.MSG_TYPE;
         this.data = data;
      }
   }

   export class ReadyToDisplay implements IMessage {
      public static readonly MSG_TYPE = "ReadyToDisplay";
      public type: string = ReadyToDisplay.MSG_TYPE;
      constructor(public sessionKey: string) {}
   }

   export class MultiMonStatusUpdated implements IMessage {
      public static readonly MSG_TYPE = "MultiMonStatusUpdated";
      public type: string = MultiMonStatusUpdated.MSG_TYPE;
      constructor(public offset: any) {}
   }

   export class GlobalPreferenceUpdated implements IMessage {
      public static readonly MSG_TYPE = "GlobalPreferenceUpdated";
      public type: string = GlobalPreferenceUpdated.MSG_TYPE;
   }

   export class OnUnityReady implements IMessage {
      public static readonly MSG_TYPE = "OnUnityReady";
      public type: string = OnUnityReady.MSG_TYPE;
      public wmksKey: string;
      constructor(wmksKey: string) {
         this.wmksKey = wmksKey;
      }
   }
   export class OnUnityPause implements IMessage {
      public static readonly MSG_TYPE = "OnUnityPause";
      public type: string = OnUnityPause.MSG_TYPE;
      public wmksKey: string;
      constructor(wmksKey: string) {
         this.wmksKey = wmksKey;
      }
   }
   export class FocusOnRemoteApp implements IMessage {
      public static readonly MSG_TYPE = "FocusOnRemoteApp";
      public type: string = FocusOnRemoteApp.MSG_TYPE;
      public sessionKey: string;
      constructor(sessionkey: string) {
         this.sessionKey = sessionkey;
      }
   }

   /**
    * The message of disconnect multi-session application
    */
   export class DisconnectApp implements IMessage {
      public static readonly MSG_TYPE = "DisconnectApp";
      public type: string = DisconnectApp.MSG_TYPE;
      public data: string;
      constructor(sessionKey: string) {
         this.data = sessionKey;
      }
   }

   /**
    * The message of disconnect all multi-session application
    */
   export class DisconnectAllMultiSessionApp implements IMessage {
      public static readonly MSG_TYPE = "DisconnectAllMultiSessionApp";
      public type: string = DisconnectAllMultiSessionApp.MSG_TYPE;
   }

   export class DiscardAuthInfo implements IMessage {
      public static readonly MSG_TYPE = "DiscardAuthInfo";
      public type: string = DiscardAuthInfo.MSG_TYPE;
   }

   export class ChromeSelfCertAccepted implements IMessage {
      public static readonly MSG_TYPE = "ChromeSelfCertAccepted";
      public type: string = ChromeSelfCertAccepted.MSG_TYPE;
      constructor(
         public url: string,
         public param: string
      ) {}
   }

   /**
    * The message of update client rule for URL redirection
    */
   export class UpdateUrlRedirectionClientRuleMsg implements IMessage {
      public static readonly MSG_TYPE = "UpdateUrlRedirectionClientRule";
      public type: string = UpdateUrlRedirectionClientRuleMsg.MSG_TYPE;
      constructor(
         public data,
         public source: string
      ) {}
   }

   /**
    * The message of redirect URL from client to agent
    */
   export class RedirectUrlToAgentMsg implements IMessage {
      public static readonly MSG_TYPE = "RedirectUrlToAgent";
      public type: string = RedirectUrlToAgentMsg.MSG_TYPE;
      constructor(
         public data: {
            url: string;
            sessionKey: string;
         }
      ) {}
   }

   export class NotificationMsg implements IMessage {
      public static readonly MSG_TYPE = "Notification";
      public type: string = NotificationMsg.MSG_TYPE;
      public notificationKey: string;
      constructor(notificationKey: string) {
         this.notificationKey = notificationKey;
      }
   }

   export class WindowReplacementPermission implements IMessage {
      public static readonly MSG_TYPE = "WindowReplacementPermission";
      public type: string = WindowReplacementPermission.MSG_TYPE;
   }

   export class GainWindowReplacementPermission implements IMessage {
      public static readonly MSG_TYPE = "GainWindowReplacementPermission";
      public type: string = GainWindowReplacementPermission.MSG_TYPE;
   }

   export class ReInitServerSelectComponent implements IMessage {
      public static readonly MSG_TYPE = "ReInitServerSelectComponent";
      public type: string = ReInitServerSelectComponent.MSG_TYPE;
   }

   export class UpdateFavoritesMsg implements IMessage {
      public static readonly MSG_TYPE = "UpdateFavoritesMsg";
      public type: string = UpdateFavoritesMsg.MSG_TYPE;
   }

   export class FullscreenBtnClicked implements IMessage {
      public static readonly MSG_TYPE = "FullscreenBtnClicked";
      public type: string = FullscreenBtnClicked.MSG_TYPE;
   }

   export class QuitFullscreenBtnClicked implements IMessage {
      public static readonly MSG_TYPE = "QuitFullscreenBtnClicked";
      public type: string = QuitFullscreenBtnClicked.MSG_TYPE;
   }

   export class ScreenChanged implements IMessage {
      public static readonly MSG_TYPE = "ScreenChanged";
      public type: string = ScreenChanged.MSG_TYPE;
      public wmksSession;
      constructor(wmksSession) {
         this.wmksSession = wmksSession;
      }
   }

   export class OnPrimaryScreenAPIWorkaroundApplied implements IMessage {
      public static readonly MSG_TYPE = "OnPrimaryScreenAPIWorkaroundApplied";
      public type: string = OnPrimaryScreenAPIWorkaroundApplied.MSG_TYPE;
      public screenInfo: DisplayBaseInfo;
      constructor(screenInfo: DisplayBaseInfo) {
         this.screenInfo = screenInfo;
      }
   }

   /**
    * to change the touch mode
    */
   export class SetTouchModeMsg implements IMessage {
      public static readonly MSG_TYPE = "SetTouchMode";
      public type: string = SetTouchModeMsg.MSG_TYPE;
      public isTrackPadMode: boolean;
      constructor(isTrackPadMode: boolean) {
         this.isTrackPadMode = isTrackPadMode;
      }
   }

   export class DisplaySettingChanged implements IMessage {
      public static readonly MSG_TYPE = "DisplaySettingChanged";
      public type: string = DisplaySettingChanged.MSG_TYPE;
   }

   export class CursorChanged implements IMessage {
      public static readonly MSG_TYPE = "CursorChanged";
      public type: string = CursorChanged.MSG_TYPE;
      public constructor(
         public src: any,
         public width: any,
         public height: any,
         public hotx: any,
         public hoty: any
      ) {}
   }

   export class AppSessionUpdatedMsg implements IMessage {
      public static readonly MSG_TYPE = "AppSessionUpdatedMsg";
      public type: string = AppSessionUpdatedMsg.MSG_TYPE;
   }

   export class URIActionMsg implements IMessage {
      public static readonly MSG_TYPE = "URIActionMsg";
      public type: string = URIActionMsg.MSG_TYPE;
      public action: string;
      public entitlement: EntitlementItem;
      constructor(action: string, item: EntitlementItem) {
         this.action = action;
         this.entitlement = item;
      }
   }

   export class LaunchRequestCanceledMsg implements IMessage {
      public static readonly MSG_TYPE = "LaunchRequestCanceledMsg";
      public type: string = LaunchRequestCanceledMsg.MSG_TYPE;
      constructor() {}
   }

   export class JSCDKErrorReportMsg implements IMessage {
      public static readonly MSG_TYPE = "JSCDKErrorReportMsg";
      public type: string = JSCDKErrorReportMsg.MSG_TYPE;
      constructor(public action: ErrorAction) {}
   }

   export class BrokerXmlReportMsg implements IMessage {
      public static readonly MSG_TYPE = "BrokerXmlReportMsg";
      public type: string = BrokerXmlReportMsg.MSG_TYPE;
      constructor(
         public api: string,
         public code: string,
         public message: string,
         public uag: boolean,
         public status: number,
         public dspecId: string = null,
         public dspecToken: string = null
      ) {}
   }

   export class BlastReportErrorMsg implements IMessage {
      public static readonly MSG_TYPE = "BlastReportErrorMsg";
      public type: string = BlastReportErrorMsg.MSG_TYPE;
      constructor(
         public url: string,
         public reason: string
      ) {}
   }

   export class SetPrefErrorMsg implements IMessage {
      public static readonly MSG_TYPE = "SetPrefErrorMsg";
      public type: string = SetPrefErrorMsg.MSG_TYPE;
      constructor(public errorCodeNode: string) {}
   }
   export class cancelAuthenticationMsg implements IMessage {
      public static readonly MSG_TYPE = "cancelAuthenticationMsg";
      public type: string = cancelAuthenticationMsg.MSG_TYPE;
   }

   export class SyncSessionSettingMsg implements IMessage {
      public static readonly MSG_TYPE = "SyncSessionSettingMsg";
      public type: string = SyncSessionSettingMsg.MSG_TYPE;
      constructor(public items: Array<string>) {}
   }

   export class ConnectItemMsg implements IMessage {
      public static readonly MSG_TYPE = "ConnectItemMsg";
      public item: TitanEntitlementItem;
      public type: string = ConnectItemMsg.MSG_TYPE;
      constructor(item: TitanEntitlementItem) {
         this.item = item;
      }
   }

   export class CloseSessionsMsg implements IMessage {
      public static readonly MSG_TYPE = "CloseSessionsMsg";
      public type: string = CloseSessionsMsg.MSG_TYPE;
   }

   export class FrameBufferInited implements IMessage {
      public static readonly MSG_TYPE = "FrameBufferInited";
      public type: string = FrameBufferInited.MSG_TYPE;
   }

   export class PrintFileMsg implements IMessage {
      public static readonly MSG_TYPE = "PrintFileMsg";
      public type: string = PrintFileMsg.MSG_TYPE;
      public file;
      constructor(file) {
         this.file = file;
      }
   }

   export class PrintFileUseOldSolutionMsg implements IMessage {
      public static readonly MSG_TYPE = "PrintFileUseOldSolutionMsg";
      public type: string = PrintFileUseOldSolutionMsg.MSG_TYPE;
      public file;
      constructor(file) {
         this.file = file;
      }
   }

   export class PolicyTriggeredQuit implements IMessage {
      public static readonly MSG_TYPE = "PolicyTriggeredQuit";
      public type: string = PolicyTriggeredQuit.MSG_TYPE;
      constructor(
         public policy: "enableLogoutTriggeredQuit" | "enableDisconnectionTriggeredQuit",
         public host: string
      ) {}
   }

   export class TitanLogOutSuccess implements IMessage {
      public static readonly MSG_TYPE = "TitanLogOutSuccess";
      public type: string = TitanLogOutSuccess.MSG_TYPE;
   }

   export class HeadRoomTimeoutErrorMsg implements IMessage {
      public static readonly MSG_TYPE = "HeadRoomTimeoutErrorMsg";
      public type: string = HeadRoomTimeoutErrorMsg.MSG_TYPE;
      constructor(public action: any) {}
   }

   export class ClientRestrictionWarningMsg implements IMessage {
      public static readonly MSG_TYPE = "ClientRestrictionWarningMsg";
      public type: string = ClientRestrictionWarningMsg.MSG_TYPE;
      constructor(public msg: any) {}
   }

   export class ClientRestrictionBlockMsg implements IMessage {
      public static readonly MSG_TYPE = "ClientRestrictionBlockMsg";
      public type: string = ClientRestrictionBlockMsg.MSG_TYPE;
      constructor(public msg: any) {}
   }

   export class ClearRunningSession implements IMessage {
      public static readonly MSG_TYPE = "ClearRunningSession";
      public type: string = ClearRunningSession.MSG_TYPE;
   }

   export class ReadyForClipboardSyncMsg implements IMessage {
      public static readonly MSG_TYPE = "ReadyForClipboardSyncMsg";
      public type: string = ReadyForClipboardSyncMsg.MSG_TYPE;
   }

   export class RequestDisclaimer implements IMessage {
      public static readonly MSG_TYPE = "RequestDisclaimer";
      public type: string = RequestDisclaimer.MSG_TYPE;
   }

   export class DoDisclaimerSubmit implements IMessage {
      public static readonly MSG_TYPE = "DoDisclaimerSubmit";
      public type: string = DoDisclaimerSubmit.MSG_TYPE;
   }

   export class DoAuthSubmit implements IMessage {
      public static readonly MSG_TYPE = "DoAuthSubmit";
      public type: string = DoAuthSubmit.MSG_TYPE;
   }

   export class SessionUnlocked implements IMessage {
      public static readonly MSG_TYPE = "SessionUnlocked";
      public type: string = SessionUnlocked.MSG_TYPE;
   }
   export class SetRampConfig implements IMessage {
      public static readonly MSG_TYPE = "SetRampConfig";
      public type: string = SetRampConfig.MSG_TYPE;
      public content;
      constructor(content) {
         this.content = content;
      }
   }

   export class UpdateOnRampTokenMsg implements IMessage {
      public static readonly MSG_TYPE = "UpdateOnRampTokenMsg";
      public type: string = UpdateOnRampTokenMsg.MSG_TYPE;
      public token;
      constructor(token) {
         this.token = token;
      }
   }
}
