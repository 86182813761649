/**
 * ******************************************************
 * Copyright (C) 2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * tsdr-consts.js --
 *
 * defined types for TSDR protocol
 * Could be splited if needed later
 */
export const RDPDR_POLICY = {
   TSDR_RDPDR_HEADER_SIZE: 4,
   TSDR_POLICY_HEADER_SIZE: 8,
   TSDR_GLOBAL_POLICY_SIZE: 8,
   TSDR_FOLDER_POLICY_SIZE: 8,

   TSDR_DEVID_AC_SHIFT: 24,
   TSDR_DEVID_AC_MASK: 0xffffff,

   TSDR_FOLDER_PERMISSION: {
      TSDR_PERM_UNKNOWN: 0, // Cannot be determined
      TSDR_PERM_OPEN_READ_ONLY: 1, // cannot create/open for write
      TSDR_PERM_READ_ONLY: 2, // cannot write data
      TSDR_PERM_OPEN_WRITE_ONLY: 3, // cannot create/open for read
      TSDR_PERM_WRITE_ONLY: 4, // cannot read data
      TSDR_PERM_NORMAL: 5 // normal permission
   }
};

// Enough types for now
export const NT_STATUS = {
   STATUS_SUCCESS: 0x00000000,
   STATUS_NOTIFY_ENUM_DIR: 0x0000010c,
   STATUS_DEVICE_BUSY: 0x80000011,
   STATUS_NO_MORE_FILES: 0x80000006,
   STATUS_UNSUCCESSFUL: 0xc0000001,
   STATUS_NOT_IMPLEMENTED: 0xc0000002,
   STATUS_INVALID_HANDLE: 0xc0000008,
   STATUS_INVALID_PARAMETER: 0xc000000d,
   STATUS_NO_SUCH_FILE: 0xc000000f,
   STATUS_END_OF_FILE: 0xc0000011,
   STATUS_NO_MEMORY: 0xc0000017,
   STATUS_ACCESS_DENIED: 0xc0000022,
   STATUS_BUFFER_TOO_SMALL: 0xc0000023,
   STATUS_OBJECT_NAME_NOT_FOUND: 0xc0000034,
   STATUS_OBJECT_NAME_COLLISION: 0xc0000035,
   STATUS_LOCK_NOT_GRANTED: 0xc0000055,
   STATUS_DISK_FULL: 0xc000007f,
   STATUS_FILE_IS_A_DIRECTORY: 0xc00000ba,
   STATUS_NOT_A_DIRECTORY: 0xc0000103,
   STATUS_CANCELLED: 0xc0000120,
   STATUS_DEVICE_REMOVED: 0xc00002b6,
   STATUS_DIRECTORY_NOT_EMPTY: 0xc0000101
};

export const RDPDR_TYPE = {
   RDPDR_CTYP_CORE: 0x4472,
   RDPDR_CTYP_PRN: 0x5052,

   /* PacketId [MS-RPDEFS 2.2.1.1] */
   PAKID_CORE_SERVER_ANNOUNCE: 0x496e,
   PAKID_CORE_CLIENTID_CONFIRM: 0x4343,
   PAKID_CORE_CLIENT_NAME: 0x434e,
   PAKID_CORE_DEVICELIST_ANNOUNCE: 0x4441,
   PAKID_CORE_DEVICE_REPLY: 0x6472,
   PAKID_CORE_DEVICE_IOREQUEST: 0x4952,
   PAKID_CORE_DEVICE_IOCOMPLETION: 0x4943,
   PAKID_CORE_SERVER_CAPABILITY: 0x5350,
   PAKID_CORE_CLIENT_CAPABILITY: 0x4350,
   PAKID_CORE_DEVICELIST_REMOVE: 0x444d,
   PAKID_PRN_CACHE_DATA: 0x5043,
   PAKID_CORE_USER_LOGGEDON: 0x554c,
   PAKID_PRN_USING_XPS: 0x5543,

   RDPDR_DEVICE_REMOVE_PDUS: 0x00000001,
   RDPDR_CLIENT_DISPLAY_NAME_PDU: 0x00000002,
   RDPDR_USER_LOGGEDON_PDU: 0x00000004,

   IRP_MJ_CREATE: 0,
   IRP_MJ_CLOSE: 2,
   IRP_MJ_READ: 3,
   IRP_MJ_WRITE: 4,

   IRP_MJ_DEVICE_CONTROL: 0x00000000e,
   IRP_MJ_QUERY_VOLUME_INFORMATION: 0xa,
   IRP_MJ_SET_VOLUME_INFORMATION: 0xb,
   IRP_MJ_QUERY_INFORMATION: 0x5,
   IRP_MJ_SET_INFORMATION: 0x6,
   IRP_MJ_DIRECTORY_CONTROL: 0xc,
   IRP_MJ_LOCK_CONTROL: 0x11,

   IRP_MN_QUERY_DIRECTORY: 0x1,
   IRP_MN_NOTIFY_CHANGE_DIRECTORY: 2,

   //[MS-SMB2 2.2.13.1.1]
   FILE_ACCESS_DELETE: 0x10000,
   FILE_ACCESS_GENERIC_ALL: 0x10000000,
   FILE_ACCESS_GENERIC_WRITE: 0x40000000,

   RDP_LOWIO_OP_SHAREDLOCK: 0x00000002,
   RDP_LOWIO_OP_EXCLUSIVELOCK: 0x00000003,
   RDP_LOWIO_OP_UNLOCK: 0x00000004,
   RDP_LOWIO_OP_UNLOCK_MULTIPLE: 0x00000005,

   CAP_TYPES: {
      CAP_GENERAL_TYPE: 1,
      CAP_PRINTER_TYPE: 2,
      CAP_PORT_TYPE: 3,
      CAP_DRIVE_TYPE: 4,
      CAP_SMARTCARD_TYPE: 5
   },

   GENERAL_CAPABILITY_VERSION: {
      GENERAL_CAPABILITY_VERSION_01: 1,
      GENERAL_CAPABILITY_VERSION_02: 2
   },

   FILE_INFORMATION_CLASS: {
      FileDirectoryInformation: 1,
      FileFullDirectoryInformation: 2,
      FileBothDirectoryInformation: 3,
      FileBasicInformation: 4,
      FileStandardInformation: 5,
      FileInternalInformation: 6,
      FileEaInformation: 7,
      FileAccessInformation: 8,
      FileNameInformation: 9,
      FileRenameInformation: 10,
      FileLinkInformation: 11,
      FileNamesInformation: 12,
      FileDispositionInformation: 13,
      FilePositionInformation: 14,
      FileFullEaInformation: 15,
      FileModeInformation: 16,
      FileAlignmentInformation: 17,
      FileAllInformation: 18,
      FileAllocationInformation: 19,
      FileEndOfFileInformation: 20,
      FileAlternateNameInformation: 21,
      FileStreamInformation: 22,
      FilePipeInformation: 23,
      FilePipeLocalInformation: 24,
      FilePipeRemoteInformation: 25,
      FileMailslotQueryInformation: 26,
      FileMailslotSetInformation: 27,
      FileCompressionInformation: 28,
      FileObjectIdInformation: 29,
      FileCompletionInformation: 30,
      FileMoveClusterInformation: 31,
      FileQuotaInformation: 32,
      FileReparsePointInformation: 33,
      FileNetworkOpenInformation: 34,
      FileAttributeTagInformation: 35,
      FileTrackingInformation: 36,
      FileIdBothDirectoryInformation: 37,
      FileIdFullDirectoryInformation: 38,
      FileValidDataLengthInformation: 39,
      FileShortNameInformation: 40,
      FileIoCompletionNotificationInformation: 41,
      FileIoStatusBlockRangeInformation: 42,
      FileIoPriorityHintInformation: 43,
      FileSfioReserveInformation: 44,
      FileSfioVolumeInformation: 45,
      FileHardLinkInformation: 46,
      FileProcessIdsUsingFileInformation: 47,
      FileNormalizedNameInformation: 48,
      FileNetworkPhysicalNameInformation: 49,
      FileIdGlobalTxDirectoryInformation: 50,
      FileIsRemoteDeviceInformation: 51,
      FileAttributeCacheInformation: 52,
      FileNumaNodeInformation: 53,
      FileStandardLinkInformation: 54,
      FileRemoteProtocolInformation: 55,
      FileMaximumInformation: 56
   },

   /* PAKID_CORE_DEVICELIST_ANNOUNCE */
   RDPDR_DTYP_SMARTCARD: 0x00000020,

   /* The Preferred Dosname length in DEVICE_ANNOUNCE packet. */
   PREFERRED_DOS_NAME_LENGTH: 8,

   /*
    * DeviceData length. Derived from RDPDR_DRIVEDEVICE's
    * szRedirectorLocalName size.
    */
   DEVICE_DATA_LOCAL_NAME_LENGTH: 250,
   DEVICE_TYPE: 8, //defined in RdpdrChannel.cpp

   /*
    *  Allow SmartCard client to have 65K Smartcard readers.  The remainder
    *  of the DWORD will be reserved for FR (and other future purposes).
    */
   SMARTCARD_MAX_DEVICE_ID: 0xffff,

   /* RDP file system device [MS-RDPEFS 2.2.1.3] */
   RDPDR_DTYPE_FILESYSTEM: 0x8
};

export const TDSR_TYPE = {
   /*
    * TSDR Version Exchange Component/PacketId defines.
    */
   TSDR_COMPONENT_CAPS: 0xfffe,
   TSDR_PACKID_VERSION_EXCHANGE: 0x0001,
   TSDR_PACKID_AGENT_POLICY: 0x0002,

   TSDR_FOLDER_ENUM_BUF_SIZE: 4096,

   /*
    * Bitmask for peer capbilities
    */
   TSDR_CAPS_MUL_ENTRIES: 0x1,

   //(sizeof(TSDR_CAPS_VERSION_EXCHANGE_REQ) - sizeof(USHORT))
   MIN_TSDR_CAPS_VERSION_EXCHANGE_REQ_SIZE: 6,

   /*
    * enum TsdrVersion
    *
    *   Represents the version of the TSDR endpoint.
    *
    *   TSDR_VERSION_UNKNOWN = Unknown version.
    *   TSDR_VERSION_V1 = Initial release.
    *   TSDR_VERSION_V2 = Read/Write only CDR support.
    *
    *   XXX: When adding a version, mention the new features/capabilities here.
    */
   VERSION: {
      TSDR_VERSION_UNKNOWN: 0,
      TSDR_VERSION_V1: 1,
      TSDR_VERSION_V2: 2, // Start to support agent policy
      TSDR_VERSION_V3: 3 // Start to support cache
   }
};
