/**
 * *****************************************************
 * Copyright 2024 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { WmksAdapter } from "./wmksAdapter";
import { RtavChannel } from "./rtavChannel";
import { RtavStatus } from "./rtavStatus";
import { RTAVSession } from "./rtavSession";
import { AudioCapture } from "./audioCapture";
import { SyncTimer } from "./synctimer";
import { FrameRateController } from "./frameRateController";
import { VideoCapture } from "./videoCapture";
import { RtavDialogService } from "./rtavDialog.service";
import { MessageWaitManager } from "./messageWaitManager";
import { RTAVProtocolService } from "./rtavProtocol.service";
import { RTAVProtocolServiceV2 } from "./v2/rtavProtocolV2.service";
import { RtavSettingManager } from "./rtavSettingManager";
import { RtavSettingManagerV2 } from "./rtavSettingManagerV2";
import { RTAVService } from "./rtav.service";
import { ResourceManager } from "./resourceManager";
import { ResourceManagerV2 } from "./v2/resourceManagerV2";
import { MediaCapture } from "./mediaCapture";
import { MediaEncoder } from "./mediaEncoder";
import { DeviceMessageManager } from "./deviceMessageManager";
import { DeviceMessageManagerV2 } from "./v2/deviceMessageV2Manager";
import { DeviceManagerService } from "./v2/device-manager.service";
import { RtavSessionController } from "./rtavSessionController";
import { RtavSessionControllerV2 } from "./v2/rtavSessionV2Controller";

@NgModule({
   imports: [],
   declarations: [],
   providers: [
      AudioCapture,
      SyncTimer,
      FrameRateController,
      VideoCapture,
      RtavDialogService,
      MessageWaitManager,
      RTAVProtocolService,
      RTAVProtocolServiceV2,
      RtavSettingManager,
      RtavSettingManagerV2,
      WmksAdapter,
      RtavChannel,
      RtavStatus,
      RTAVSession,
      RTAVService,
      ResourceManager,
      ResourceManagerV2,
      MediaCapture,
      MediaEncoder,
      DeviceMessageManager,
      DeviceMessageManagerV2,
      DeviceManagerService,
      RtavSessionController,
      RtavSessionControllerV2
   ],
   exports: []
})
export class RTAVModule {}
