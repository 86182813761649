/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * file-system.js
 *
 * Implement the FS as if it's a FAT32 FS, the missing data will be
 * mocked data are:
 * - device name as Horizon
 * - volume size as 32G
 * - create date as last modified date
 *
 * use setFSImplementation to inject fsImplementation for now
 */

import { fsConsts, FILE_TYPES, DEVICE_TYPES, FS_INFORMATION_CLASS } from "./index";
import { Injectable } from "@angular/core";
import { FsImplementation } from "./fs-implementation";
import { FSUtil } from "./fs-util";
import Logger from "../../../core/libs/logger";
import { SharedFolderManager } from "./shared-folder-manager";
import { FileHandlerCacheManagerService } from "./file-handler-cache-manager.service";

@Injectable()
export class FileSystem {
   private fileMap = {};
   private logger = new Logger(Logger.CDR);
   private fileIdCounter = 1;

   constructor(
      private sharedFolderManager: SharedFolderManager,
      private cacheService: FileHandlerCacheManagerService
   ) {}

   /**
    * Use current time for missing date values
    * @return {[type]} [description]
    */
   private _getCurrentTime = () => {
      return FSUtil.timeToFileTime(Date.now());
   };

   /**
    * [attrToRdp description]
    * @param  {string}  name            The file name, for cross system FS convert
    * @param  {Boolean} isDeletePending
    * @param  {Boolean} isDirectory
    * @param  {Boolean} isReadOnly
    * @return {number}                  attr value
    */
   private _attrToRdp = (name, isDeletePending, isDirectory, isReadOnly) => {
      return (
         (isDirectory ? FILE_TYPES.ATTRIBUTE_BITS.FILE_ATTRIBUTE_DIRECTORY : 0) |
         (name[0] === "." ? FILE_TYPES.ATTRIBUTE_BITS.FILE_ATTRIBUTE_HIDDEN : 0) |
         (isDeletePending ? FILE_TYPES.ATTRIBUTE_BITS.FILE_ATTRIBUTE_TEMPORARY : 0) |
         (isReadOnly ? FILE_TYPES.ATTRIBUTE_BITS.FILE_ATTRIBUTE_READONLY : 0)
      );
   };

   /**
    * @param  {strng} path
    * @return {array} Return root as default
    */
   private _getNameChain = (path) => {
      if (!path) {
         return ["*"];
      }
      let nameChain = path.split("\\");
      if (!nameChain[0]) {
         nameChain = nameChain.splice(1, nameChain.length - 1);
      }
      if (!nameChain) {
         return ["*"];
      }
      return nameChain;
   };

   private _matchPath = (fileNode, targetRegString) => {
      const targetNameChain = this._getNameChain(targetRegString);
      const filePathChain = this._getNameChain(fileNode.getPath());
      //skip path reg check with file path, since we've already used fildId to find the dir
      // get fileReg
      const fileMatcher = targetNameChain[targetNameChain.length - 1];
      const allowedChar = "[" + fsConsts.charRegExp + "a-zA-Z0-9_.\\-\\\\(\\\\)\\s]";
      //only support*
      const fileName = fileNode.name;
      if (fileMatcher.includes("*")) {
         const reg = new RegExp(
            fileMatcher
               .replace(".", "\\.")
               .split("*")
               .join(allowedChar + "*(?!\\*)")
         );
         const matched = reg.exec(fileName);
         if (!matched) {
            return false;
         }
         // use full match
         return matched[0].length === fileName.length;
      } else {
         return fileMatcher === fileName;
      }
   };

   /**
    * Always return this volume info for now.
    * @param  {number} deviceId
    * @return {object} Volume info
    */
   private _getDeviceFS = (deviceId) => {
      const deviceInfo: any = fsConsts.volumeInfo;
      deviceInfo.sid = FSUtil.getSid();
      return deviceInfo;
   };

   private _alreadyRedirected = (path) => {
      let found = false;
      for (const fileId in this.fileMap) {
         if (
            this.fileMap.hasOwnProperty(fileId) &&
            !!this.fileMap[fileId] &&
            this.fileMap[fileId].getPath() === path &&
            !this.fileMap[fileId].deleted
         ) {
            found = true;
         }
      }
      return found;
   };

   private _createRedirectedNode = (node) => {
      const fileId = this.fileIdCounter;
      this.fileMap[fileId] = node; //TODO might be able to be optimized here
      this.fileIdCounter++;
      return fileId;
   };

   private _getRedirectedNode = (fileId) => {
      if (!this.fileMap.hasOwnProperty(fileId)) {
         return null;
      }
      return this.fileMap[fileId];
   };

   private _fileExist = async (entryPath, sharedDriver) => {
      try {
         return !!(await sharedDriver.findNodeByPath(entryPath));
      } catch (e) {
         this.logger.trace("target position has no exsting file" + e);
         return false;
      }
   };

   private _createNewEntry = async (entryPath, sharedDriver, isDir) => {
      if (await this._fileExist(entryPath, sharedDriver)) {
         this.logger.debug("create file fail, since the file already exist");
         throw "STATUS_OBJECT_NAME_COLLISION";
      }
      const pathInfo = FSUtil.getFolderAndFileName(entryPath);
      const folderNode = await sharedDriver.findFolder(pathInfo.folderPath);
      const folderEntry = await folderNode.getFileEntry();
      const newEntry = await FsImplementation.createEntry(folderEntry, pathInfo.fileName, {
         isDir: isDir
      });
      await folderNode.addEntry(newEntry);

      const node = await sharedDriver.findNodeByPath(entryPath);
      const fileId = this._createRedirectedNode(node);
      return fileId;
   };

   public noDevice = () => {
      //return !sharedFolderManager.hasSharedDriver();
   };

   public getDeviceName = (deviceId) => {
      //return sharedFolderManager.getSharedDriver(deviceId).deviceName;
   };

   public releaseAll = () => {
      const deviceId = "TODO";
      for (const fileId in this.fileMap) {
         this.redirectedCloseFile(deviceId, fileId);
      }
   };

   public redirectedCloseFile = async (deviceId, fileId) => {
      if (!this.fileMap.hasOwnProperty(fileId)) {
         throw "can't remove a non-exist file with Id: " + fileId;
      }
      const pathString = this.fileMap[fileId].getPath();
      const fileEntry = await this.fileMap[fileId].getFileEntry();
      this.cacheService.releaseWriteHandler(fileEntry);
      await this.fileMap[fileId].disactive();
      //delete fileMap[fileId];
      this.fileMap[fileId].deleted = true;
      this.logger.trace("remove a redirected node at ./" + pathString);
   };

   //https://msdn.microsoft.com/en-us/library/bb432380%28v=vs.85%29.aspx
   //https://opengrok.eng.vmware.com/source/xref/bfg-main.perforce.1666/bora/apps/rde/tsdr/client/posix/fileSystemImpl.cpp#113
   public redirectedCreateFile = async (
      deviceId,
      desiredAccess,
      pathString,
      allocationSize,
      fileAttributes,
      sharedAccess,
      createDisposition,
      createOptions,
      readOnlyCDR
   ) => {
      //return fileId
      const sharedDriver = this.sharedFolderManager.getSharedDriver(deviceId);
      if (!sharedDriver) {
         this.logger.error("Can't find the driver with id: " + deviceId);
         throw "STATUS_OBJECT_NAME_NOT_FOUND";
      }

      this.logger.trace(
         [
            "createDisposition: ",
            FSUtil.stringifyTypes(createDisposition, FILE_TYPES.OPERATION_REQUEST),
            ", createOptions: ",
            FSUtil.stringifyFlags(createOptions, FILE_TYPES.WINTERNL),
            ", access: ",
            FSUtil.stringifyFlags(desiredAccess, FILE_TYPES.ACCESS)
         ].join("")
      );
      const redirectExistFile = async (clear) => {
         if (this._alreadyRedirected(pathString) && !clear) {
            this.logger.warning("re-redirecting a redirected node");
         }
         this.logger.trace("create a redirected stream node at " + pathString);
         const node = await sharedDriver.findNodeByPath(pathString);
         if (clear) {
            node.truncate(0);
         }
         const fileId = this._createRedirectedNode(node);
         return fileId;
      };

      let information = 0;
      let fileId = 0;

      const isDir = !!(createOptions & FILE_TYPES.WINTERNL.FILE_DIRECTORY_FILE);
      const pathExist = await this._fileExist(pathString, sharedDriver);

      if (pathExist) {
         this.logger.trace("Creating a file or directory which already exists.");
         if (isDir && !(await sharedDriver.findNodeByPath(pathString)).isDirectory) {
            throw "STATUS_NOT_A_DIRECTORY";
         }
      }
      this.logger.debug("opening file" + pathString + " with param " + createDisposition + " " + createOptions);
      //http://pubs.opengroup.org/onlinepubs/7908799/xsh/open.html
      //https://www.thinkage.ca/english/gcos/expl/c/lib/open.html
      switch (createDisposition) {
         case FILE_TYPES.OPERATION_REQUEST.FILE_SUPERSEDE:
         case FILE_TYPES.OPERATION_REQUEST.FILE_OVERWRITE:
            // if file exist, clear it and open for write
            this.logger.warning("need to trunc file when open it");
            information = FILE_TYPES.OPERATION_RESPONSE.FILE_SUPERSEDED;

            fileId = await redirectExistFile(true);
            return {
               fileId: fileId,
               information: information
            };
         case FILE_TYPES.OPERATION_REQUEST.FILE_CREATE:
            // fail if file exist, otherwise, create a new file
            if (pathExist) {
               throw "STATUS_OBJECT_NAME_COLLISION";
            }
            information = FILE_TYPES.OPERATION_RESPONSE.FILE_SUPERSEDED;
            fileId = await this._createNewEntry(pathString, sharedDriver, isDir);
            this.logger.trace("create file successfully: " + fileId);
            return {
               fileId: fileId,
               information: information
            };
         case FILE_TYPES.OPERATION_REQUEST.FILE_OPEN_IF:
            // create if not exist
            information = FILE_TYPES.OPERATION_RESPONSE.FILE_OPENED;
            if (pathExist) {
               fileId = await redirectExistFile(false);
            } else {
               fileId = await this._createNewEntry(pathString, sharedDriver, isDir);
            }
            return {
               fileId: fileId,
               information: information
            };
         case FILE_TYPES.OPERATION_REQUEST.FILE_OVERWRITE_IF:
            information = FILE_TYPES.OPERATION_RESPONSE.FILE_OVERWRITTEN;
            if (pathExist) {
               fileId = await redirectExistFile(false);
            } else {
               fileId = await this._createNewEntry(pathString, sharedDriver, isDir);
            }
            return {
               fileId: fileId,
               information: information
            };
         case FILE_TYPES.OPERATION_REQUEST.FILE_OPEN: {
            //open existing file
            information = FILE_TYPES.OPERATION_RESPONSE.FILE_SUPERSEDED;
            const node = await sharedDriver.findNodeByPath(pathString);
            fileId = await redirectExistFile(false);
            return {
               fileId: fileId,
               information: information
            };
         }
      }
      throw "Unknown create error";
   };

   //FileSystemImpl::RedirectedQueryDirectoryFile
   public redirectedQueryDirectorFile = async (deviceId, fileId, initialQuery, path, fileInformationClass) => {
      const fileNode = this._getRedirectedNode(fileId);

      if (!fileNode) {
         this.logger.info("can't find folder for content: " + deviceId + "_" + fileId);
         throw "STATUS_NO_SUCH_FILE";
      }
      if (!fileNode.isDirectory) {
         this.logger.info("can't request dir content on a file");
         throw "STATUS_UNSUCCESSFUL";
      }

      //According to https://msdn.microsoft.com/en-us/library/windows/hardware/ff567047(v=vs.85).aspx
      if (initialQuery) {
         this.logger.trace("query contents of folder " + fileNode.name + " at " + deviceId + "_" + fileId);
         await fileNode.rewinddir();
      }
      let matchedSubNode = null;
      do {
         const subNode = fileNode.readdir();
         if (!subNode) {
            this.logger.trace("find no more files");
            //throw STATUS_NO_MORE_FILES without data
            throw "STATUS_NO_MORE_FILES";
         }
         if (this._matchPath(subNode, path)) {
            matchedSubNode = subNode;
            this.logger.trace(
               "return one dir content" +
                  matchedSubNode.name +
                  " in the queried folder " +
                  fileNode.name +
                  " of " +
                  fileId
            );
            break;
         } else {
            this.logger.trace(
               "find a reg exp unmatched file" +
                  subNode.name +
                  " in the queried folder " +
                  fileNode.name +
                  " of " +
                  fileId +
                  " with filter " +
                  path
            );
         }
      } while (1); // eslint-disable-line
      const result: any = {};
      let packType = "";
      let dataSize = 0;
      let fixedSize = 0;
      switch (fileInformationClass) {
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileDirectoryInformation:
            fixedSize = 64;

            result.nextEntryOffset = 0;
            result.fileIndex = 0;
            result.creationTime = matchedSubNode.time.creationTime;
            result.lastAccessTime = matchedSubNode.time.lastAccessTime;
            result.lastWriteTime = matchedSubNode.time.lastWriteTime;
            result.changeTime = matchedSubNode.time.changeTime;
            result.endOfFile = matchedSubNode.size;
            result.allocationSize = matchedSubNode.size;
            result.fileAttributes = this._attrToRdp(
               matchedSubNode.name,
               matchedSubNode.isDeletePending,
               matchedSubNode.isDirectory,
               matchedSubNode.isReadOnly
            );
            result.fileNameLength = matchedSubNode.name.length * 2 + 2;
            result.fileName = matchedSubNode.name;
            packType = "FILE_DIRECTORY_INFORMATION_PACKED";
            dataSize = fixedSize + result.fileNameLength;
            break;
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileFullDirectoryInformation:
            fixedSize = 68;

            result.nextEntryOffset = 0;
            result.fileIndex = 0;
            result.creationTime = matchedSubNode.time.creationTime;
            result.lastAccessTime = matchedSubNode.time.lastAccessTime;
            result.lastWriteTime = matchedSubNode.time.lastWriteTime;
            result.changeTime = matchedSubNode.time.changeTime;
            result.endOfFile = matchedSubNode.size;
            result.allocationSize = matchedSubNode.size;
            result.fileAttributes = this._attrToRdp(
               matchedSubNode.name,
               matchedSubNode.isDeletePending,
               matchedSubNode.isDirectory,
               matchedSubNode.isReadOnly
            );
            result.fileNameLength = matchedSubNode.name.length * 2 + 2;
            result.eaSize = 0;
            result.fileName = matchedSubNode.name;
            packType = "FILE_FULL_DIR_INFORMATION_PACKED";
            dataSize = fixedSize + result.fileNameLength;
            break;
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileNamesInformation:
            fixedSize = 12;
            result.nextEntryOffset = 0;
            result.fileIndex = 0;
            result.fileNameLength = (matchedSubNode.name.length + 1) * 2;
            result.fileName = matchedSubNode.name;
            packType = "FILE_NAMES_INFORMATION_PACKED";
            dataSize = fixedSize + result.fileNameLength;
            break;
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileBothDirectoryInformation:
            fixedSize = 93;
            result.nextEntryOffset = 0;
            result.fileIndex = 0;
            result.creationTime = matchedSubNode.time.creationTime;
            result.lastAccessTime = matchedSubNode.time.lastAccessTime;
            result.lastWriteTime = matchedSubNode.time.lastWriteTime;
            result.changeTime = matchedSubNode.time.changeTime;
            result.endOfFile = matchedSubNode.size;
            result.allocationSize = matchedSubNode.size;
            result.fileAttributes = this._attrToRdp(
               matchedSubNode.name,
               matchedSubNode.isDeletePending,
               matchedSubNode.isDirectory,
               matchedSubNode.isReadOnly
            );
            result.fileNameLength = matchedSubNode.name.length * 2 + 2;
            result.eaSize = 0;
            result.shortNameLength = 0; //don't implement this
            result.shortName = new Array(12).fill(0);
            result.fileName = matchedSubNode.name;
            packType = "FILE_BOTH_DIR_INFORMATION_PACKED";
            dataSize = fixedSize + result.fileNameLength;
            break;
         default:
            throw "Not implemented QueryDirectorFile type:" + fileInformationClass;
      }

      return {
         length: dataSize,
         fileInformation: {
            classType: packType,
            classData: result
         }
      };
   };

   public redirectedQueryInformationFile = (deviceId, fileId, fileInformationClass) => {
      const fnode = this._getRedirectedNode(fileId);

      if (!fnode) {
         this.logger.debug("can't find file for query info by id " + fileId);
         throw "STATUS_NO_SUCH_FILE";
      }
      switch (
         fileInformationClass //4
      ) {
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileBasicInformation: {
            this.logger.trace("Retrieving basic information.");
            const fileBasicInformation = {
               creationTime: fnode.time.creationTime,
               lastAccessTime: fnode.time.lastAccessTime,
               lastWriteTime: fnode.time.lastWriteTime,
               changeTime: fnode.time.changeTime,
               fileAttributes: this._attrToRdp(fnode.name, fnode.isDeletePending, fnode.isDirectory, fnode.isReadOnly)
            };
            return {
               length: 36,
               fileInformation: {
                  classType: "FILE_BASIC_INFORMATION",
                  classData: fileBasicInformation
               }
            };
         }
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileStandardInformation: {
            this.logger.trace("Retrieving standard information.");
            const fileStandardInformation = {
               allocationSize: fnode.size,
               endOfFile: fnode.size,
               numberOfLinks: fnode.linkNumber,
               deletePending: fnode.isDeletePending,
               directory: fnode.isDirectory
            };
            return {
               length: 22, //C: pack(8) sizeof (FILE_STANDARD_INFORMATION) - sizeof (USHORT);
               fileInformation: {
                  classType: "FILE_STANDARD_INFORMATION",
                  classData: fileStandardInformation
               }
            };
         }
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileAttributeTagInformation: {
            const fileAttributeTagInformation = {
               fileAttributes: this._attrToRdp(fnode.name, fnode.isDeletePending, fnode.isDirectory, fnode.isReadOnly),
               reparseTag: 0
            };
            return {
               length: 8,
               fileInformation: {
                  classType: "FILE_ATTRIBUTE_TAG_INFORMATION",
                  classData: fileAttributeTagInformation
               }
            };
         }
         default:
            this.logger.error("invalid fileInformationClass to retrive: " + fileInformationClass);
      }
   };

   //https://msdn.microsoft.com/en-us/library/cc241374.aspx
   public redirectedSetInformationFile = async (deviceId, fileId, fileInformationClass, length, information) => {
      const fnode = this._getRedirectedNode(fileId);
      fnode.cacheService = this.cacheService;
      const sharedDriver = this.sharedFolderManager.getSharedDriver(deviceId);
      if (!sharedDriver || !fnode) {
         this.logger.debug("can't find file for query info by id " + deviceId + "-" + fileId);
         throw "STATUS_NO_SUCH_FILE";
      }
      this.logger.debug(
         "set information for" +
            deviceId +
            "_" +
            fileId +
            " of type " +
            fileInformationClass +
            " of data " +
            JSON.stringify(information)
      );
      switch (fileInformationClass) {
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileBasicInformation:
            break;
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileEndOfFileInformation:
            fnode.truncate(information.endOfFile.getValue());
            break;
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileAllocationInformation:
            fnode.truncate(information.allocationSize.getValue());
            break;
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileDispositionInformation: {
            if (fnode.isDirectory && !fnode.isEmptyDirectory()) {
               throw "STATUS_DIRECTORY_NOT_EMPTY";
            }
            const deletePending = true;
            if (!fnode.isDirectory && information.deleteFile !== undefined) {
               await fnode.setDeletePending(information.deleteFile);
            } else if (length === 0 || information.deleteFile === undefined) {
               await fnode.setDeletePending(true);
            }
            break;
         }
         case FILE_TYPES.FILE_INFORMATION_CLASS.FileRenameInformation: {
            const pathInfo = FSUtil.getFolderAndFileName(information.fileName);
            const targetFolderNode = await sharedDriver.findFolder(pathInfo.folderPath);
            this.logger.trace("move file to folder" + targetFolderNode + " with name " + pathInfo.fileName);
            if (!information.replaceIfExists && (await this._fileExist(information.fileName, sharedDriver))) {
               throw "STATUS_OBJECT_NAME_COLLISION";
            }
            await fnode.move(targetFolderNode, pathInfo.fileName, information.replaceIfExists);
            break;
         }
      }
      /**
       * always return the same value as passed in instead of real length
       */
      return {
         length: length,
         padding: [0]
      };
   };

   private _wait = (time) => {
      return new Promise((resolve) => {
         setTimeout(resolve, time);
      });
   };

   public redirectedReadFile = async (deviceId, fileId, length, offset, param) => {
      this.logger.debug("read for " + length + " bytes from " + offset + " in " + deviceId + "-" + fileId);
      const fileNode = this._getRedirectedNode(fileId);
      // isDirectory must be false
      if (!fileNode) {
         throw "STATUS_NO_SUCH_FILE";
      }
      if (fileNode.isDirectory || !!fileNode.file) {
         throw "STATUS_UNSUCCESSFUL";
      }
      let readResult = await fileNode.pRead(offset, length, param);
      if (readResult === "notReady") {
         //This is for bug 1979186, read when read permission is not ready
         await this._wait(2000);
         this.logger.debug("Wait for 2s for read permission");
         readResult = await fileNode.pRead(offset, length, param);
         if (readResult === "notReady") {
            this.logger.debug("Read fails after 2s' wait");
            throw "STATUS_UNSUCCESSFUL";
         }
      }
      if (!readResult || readResult === "notReady") {
         throw "STATUS_UNSUCCESSFUL";
      }

      if (readResult.length === 0) {
         throw "STATUS_END_OF_FILE";
      }
      readResult.param = param;
      return readResult;
   };

   //similiar to https://msdn.microsoft.com/en-us/library/windows/hardware/ff567121
   public redirectedWriteFile = async (deviceId, fileId, buffer, length, offset) => {
      this.logger.debug("write for " + length + "bytes from" + offset + "in " + deviceId + "-" + fileId);
      const fileNode = this._getRedirectedNode(fileId);
      if (!fileNode) {
         this.logger.error("find no node");
         throw "STATUS_NO_SUCH_FILE";
      }
      fileNode.cacheService = this.cacheService;
      // isDirectory must be false
      if (fileNode.isDirectory || !!fileNode.file) {
         this.logger.error("the found node is not valid file");
         throw "STATUS_UNSUCCESSFUL";
      }
      const writeResult = await fileNode.pWrite(buffer, offset, length);
      // actual write length
      if (!writeResult) {
         throw "STATUS_UNSUCCESSFUL";
      }
      return writeResult;
   };

   public redirectedQueryVolumeInformationFile = (deviceId, fileId, fsInformationClass) => {
      this.logger.debug("query volem info for " + deviceId + "-" + fileId + " with type " + fsInformationClass);
      const volumLabel = "VMWARE";
      const diskType = "FAT32";
      const fsInfo = this._getDeviceFS(deviceId); //return statvfs
      let volumeResponse = null;
      switch (fsInformationClass) {
         case FS_INFORMATION_CLASS.FileFsVolumeInformation: {
            this.logger.trace("Retrieving FileFsVolumeInformation.");
            const labelLength = volumLabel.length * 2 + 2;
            const fixedSize = 17; //from Agent definition
            const volumeInfo = {
               volumeLabelLength: labelLength,
               volumeCreationTime: 0, //getCurrentTime(),
               volumeSerialNumber: fsInfo.sid,
               supportsObjects: 0,
               volumeLabel: volumLabel
            };
            volumeResponse = {
               length: fixedSize + labelLength,
               volumeInformation: {
                  classType: "FILE_FS_VOLUME_INFORMATION_PACKED",
                  classData: volumeInfo
               }
            };
            break;
         }
         case FS_INFORMATION_CLASS.FileFsSizeInformation: {
            this.logger.trace("Retrieving FileFsSizeInformation.");
            const volumeInfo = {
               totalAllocationUnits: fsInfo.blocks,
               availableAllocationUnits: fsInfo.bavail,
               sectorsPerAllocationUnit: 1,
               bytesPerSector: fsInfo.bsize
            };
            volumeResponse = {
               length: 24, //size of FILE_FS_SIZE_INFORMATION
               volumeInformation: {
                  classType: "FILE_FS_SIZE_INFORMATION",
                  classData: volumeInfo
               }
            };
            break;
         }
         case FS_INFORMATION_CLASS.FileFsAttributeInformation: {
            this.logger.trace("Retrieving FileFsAttributeInformation.");
            const fsNameLength = diskType.length * 2 + 2;
            const fixedSize = 12; //from Agent definition
            const fsAttributes =
               FILE_TYPES.FILE_ATTRIBUTES.FILE_CASE_SENSITIVE_SEARCH |
               FILE_TYPES.FILE_ATTRIBUTES.FILE_CASE_PRESERVED_NAMES |
               FILE_TYPES.FILE_ATTRIBUTES.FILE_UNICODE_ON_DISK;
            const volumeInfo = {
               fileSystemAttributes: fsAttributes,
               maximumComponentNameLength: fsInfo.namemax,
               fileSystemNameLength: fsNameLength,
               fileSystemName: diskType
            };
            volumeResponse = {
               length: fixedSize + fsNameLength,
               volumeInformation: {
                  classType: "FILE_FS_ATTRIBUTE_INFORMATION",
                  classData: volumeInfo
               }
            };
            break;
         }
         case FS_INFORMATION_CLASS.FileFsFullSizeInformation: {
            this.logger.trace("Retrieving FileFsFullSizeInformation.");
            const volumeInfo = {
               totalAllocationUnits: fsInfo.blocks,
               callerAvailableAllocationUnits: fsInfo.bavail,
               actualAvailableAllocationUnits: fsInfo.bfree,
               sectorsPerAllocationUnit: 1,
               bytesPerSector: fsInfo.bsize
            };
            volumeResponse = {
               length: 32,
               volumeInformation: {
                  classType: "FILE_FS_FULL_SIZE_INFORMATION",
                  classData: volumeInfo
               }
            };
            break;
         }
         case FS_INFORMATION_CLASS.FileFsDeviceInformation: {
            this.logger.trace("Retrieving FileFsDeviceInformation.");
            const volumeInfo = {
               deviceType: DEVICE_TYPES.FILE_DEVICE_DISK,
               characteristics: 0
            };
            volumeResponse = {
               length: 32,
               volumeInformation: {
                  classType: "FILE_FS_DEVICE_INFORMATION",
                  classData: volumeInfo
               }
            };
            break;
         }
         default:
            throw "unknown fsInformationClass: " + fsInformationClass;
      }
      this.logger.debug("volume info:" + JSON.stringify(volumeResponse));
      return volumeResponse;
   };

   public redirectedDeviceIoControlFile = (deviceId, fileId, ioControlCode, inputBuffer, outputBufferLength) => {
      throw "DeviceIoControlFile not implemented";
   };
}
