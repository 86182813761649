/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

// Chrome Client only

import { Subject } from "rxjs";
import { SDKRawEvent } from "../event-helper/event-helper";
import { EventExchangeBase, SDKReceivedRawRequest } from "./event-exchange.base";
import { Logger } from "../logger";

export class EventExchangeLongLived extends EventExchangeBase {
   private ports: Map<string, any> = new Map();
   private callbacks: Map<string, Function> = new Map();
   constructor(
      allowedPeerAddresss: Array<string>,
      getTrustOriginList: (string) => Promise<Array<string>>,
      isTrustSyncMessage?: (SDKRawEvent) => boolean
   ) {
      super(allowedPeerAddresss, getTrustOriginList, isTrustSyncMessage);
      if (chrome?.runtime?.onConnectExternal) {
         chrome.runtime.onConnectExternal.addListener((port) => {
            const sender = port.sender;
            const senderOrigin = this.getOrigin(sender.url);
            if (!senderOrigin) {
               return false;
            }
            //this.ports.set(sender.id, port);
            Logger.debug(`Port ${port.name} is opened by sender: ${sender.url}  origin: ${senderOrigin}`);
            return this.addListenerForPort(port);
         });
      }
   }

   public disconnectPort = (portId: string, reason?: string) => {
      const port = this.ports.get(portId);
      if (port) {
         Logger.debug(`Disconnet port ${port.name}, portId : ${portId} since ${reason}`);
         port.disconnect();
         this.ports.delete(portId);

      }
   };

   public sendMessage = (peerAddress: string, data: SDKRawEvent, portId: string = undefined): Promise<SDKRawEvent> => {
      let port: any;
      const key = portId ? portId : peerAddress;
      return new Promise((resolve, reject) => {
         try {
            if (!this.ports.has(key)) {
               const name = "port_" + Math.random().toString();
               port = chrome.runtime.connect(peerAddress, { name: name });
               // The port's onDisconnect event is fired if the extension/app does not exist or receive connection.
               port.onDisconnect.addListener((port) => {
                  Logger.debug(`Port disconnected since receiving end does not exist.`);
                  this.ports.delete(key);
                  reject();
               });
               this.ports.set(key, port);
               Logger.debug(`Open port ${port.name} by sdk. Connected with app ${peerAddress}, portId ${portId} `);
               this.addListenerForPort(port);
            } else {
               port = this.ports.get(key);
               Logger.debug(`Use existed port ${port.name}`);
            }

            const msgId = Math.random().toString(36);
            this.callbacks.set(msgId, (response: SDKRawEvent) => {
               resolve(response);
            });

            port.postMessage({ msgId: msgId, message: data });
            Logger.trace(
               `Sent data ${JSON.stringify({ msgId: msgId, message: data })} from port ${JSON.stringify(port)}`
            );
         } catch (e) {
            this.ports.delete(key);
            Logger.debug(`Failed to open port with app ${peerAddress}. Error in chrome.runtime.connect`);
            reject(e);
         }
      });
   };

   private addListenerForPort = (port: any): boolean => {
      port.onMessage.addListener((data, port) => {
         const message = data.message;
         const msgId = data.msgId;
         Logger.trace(`Receive data ${JSON.stringify(data)} from port ${JSON.stringify(port)}`);
         if (data.isResponse) {
            const callback = this.callbacks.get(msgId);
            if (typeof callback === "function") {
               callback(message);
               Logger.debug(`Message ${msgId} is resolved.`);
            }
            this.callbacks.delete(msgId);
         } else {
            const msgSenderOrigin = this.getOrigin(port.sender.url);

            if (!msgSenderOrigin) {
               return false;
            }
            this.getAllowedPeerAddress(msgSenderOrigin, message).then((matchedPeerAddress) => {
               if (!matchedPeerAddress) {
                  Logger.warning("ignore message from " + msgSenderOrigin);
                  return false;
               }

               // double check sender in code again
               const request: SDKReceivedRawRequest = {
                  request: message as SDKRawEvent,
                  sendResponse: (response: SDKRawEvent) => {
                     port.postMessage({ isResponse: true, msgId: msgId, message: response });
                  },
                  peerAddress: matchedPeerAddress
               };
               this.message$.next(request);
            });
         }
      });
      return true;
   };
}
