/**
 * ******************************************************
 * Copyright (C) 2012-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getConfigurationHandler.js --
 *
 *      Implementation of the message handler to get configuration.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import { clientUtil } from "@html-core";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import JSCDKBrokerError from "../model/jscdk-broker-error.consts";
import GetAuthenticationStatusHandler from "./getAuthenticationStatusHandler";
import JSCDKClientController from "./jscdkClientController";
import UnauthPuzzleHandler from "./unauthPuzzleHandler";
import { AuthenticationAction, SubmitAuthInfoAction } from "../jscdk-interface";
import Ws1ModeService from "../model/ws1-mode.service";
import IPv6Service from "../model/ipv6.service";
import { CryptoKeyDerivationService } from "../../common/service/crypto-key-derivation.service";

// all auth types here
export const HaveAuthTypesEnum = {
   HAVEAUTHTYPE_DEFAULT: "",
   HAVEAUTHTYPE_SAML: "saml",
   HAVEAUTHTYPE_UNAUTH: "unauthenticated"
};

const CLIENT_PUZZLE = "clientpuzzle";
const TITAN_CLIENT = "TitanClient";

const PasscodeAuthLabel = {
   PASSCODE_AUTH_LABEL_RSA_SECURID: "RSA SecurID"
};

export function GetConfigurationHandler() {
   // member variables below
   this.messageName = "get-configuration";
   this.messageText = "get-configuration";
   this.responseTag = "configuration";
   this.authTypes = HaveAuthTypesEnum.HAVEAUTHTYPE_DEFAULT;

   // register dependencies below, only involve setlocale when first sending
   let setLocaleObject;
   let router;
   setLocaleObject = util.getObject(globalArray, "set-locale");
   if (setLocaleObject) {
      this.registerHandler(setLocaleObject, "state");
   }
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
   this.ignoreResponseFlag = false;
}

// inherits MessageHandler prototype
GetConfigurationHandler.prototype = new MessageHandler();
// constructor
GetConfigurationHandler.constructor = GetConfigurationHandler;

/**
 * Set the request XML for Windows password authentication.
 *
 */
GetConfigurationHandler.prototype.setRequestXML = function (ignoreResponse) {
   // handle SAML
   let samlNameElem,
      unauthNameElem,
      haveAuthTypesElem,
      contentElem = "",
      keyParameters = "",
      supportedFeatures,
      authTypes,
      keyParametersObj: GetConfigurationKeyParameters;
   authTypes = this.getAuthTypes();
   keyParametersObj = this.getKeyParameters();

   if (authTypes === HaveAuthTypesEnum.HAVEAUTHTYPE_SAML) {
      samlNameElem = util.createElement("name", "saml");
      haveAuthTypesElem = util.createElement("have-authentication-types", samlNameElem);
      contentElem = haveAuthTypesElem;
   } else if (authTypes === HaveAuthTypesEnum.HAVEAUTHTYPE_UNAUTH) {
      unauthNameElem = util.createElement("name", "unauthenticated");
      haveAuthTypesElem = util.createElement("have-authentication-types", unauthNameElem);
      contentElem = haveAuthTypesElem;
   }

   supportedFeatures = util.createElement("feature", "lastUserActivity");
   supportedFeatures += util.createElement("feature", "reauthentication");
   supportedFeatures += util.createElement("feature", "nameResolution");
   supportedFeatures += util.createElement("feature", "redirection");
   supportedFeatures += util.createElement("feature", "workspaceOneMode");
   supportedFeatures += util.createElement("feature", "shadowSessions");
   supportedFeatures += util.createElement("feature", "clientPuzzle");
   if (clientUtil.isChromeClient()) {
      supportedFeatures += util.createElement("feature", "titan");
   }
   supportedFeatures += util.createElement("feature", "multiSessionApplicationLaunch");
   supportedFeatures += util.createElement("feature", "protocolRedirection");
   if (!clientUtil.isChromeClient()) {
      supportedFeatures += util.createElement("feature", "csrfProtection");
   }
   let ipv6Service = globalArray["ipv6-service"];
   if (!ipv6Service) {
      ipv6Service = new IPv6Service();
      globalArray[ipv6Service.globalName] = ipv6Service;
   }
   if (!ipv6Service.isIPv6Disabled()) {
      supportedFeatures += util.createElement("feature", "ipv6");
   }
   if (!clientUtil.isChromeClient()) {
      supportedFeatures += util.createElement("feature", "on-ramp");
   }
   contentElem += util.createElement("supported-features", supportedFeatures);

   // add key-parameters
   if (keyParametersObj && keyParametersObj.schemes && keyParametersObj.schemes.length) {
      keyParameters += util.createElement("public-key", keyParametersObj.publicKey);
      keyParameters += util.createElement("nonce", keyParametersObj.nonce);
      keyParameters += util.createElement("identifier", keyParametersObj.identifier);

      let param = util.createElement("name", "schemes");
      const values = keyParametersObj.schemes.map((scheme) => util.createElement("value", scheme)).join("");
      param += util.createElement("values", values);

      const params = util.createElement("param", param);
      keyParameters += util.createElement("params", params);

      contentElem += util.createElement("key-parameters", keyParameters);
   }

   this.requestXML = contentElem;
   if (ignoreResponse === true) {
      this.ignoreResponseFlag = true;
   } else {
      this.ignoreResponseFlag = false;
   }
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
GetConfigurationHandler.prototype.onUpdated = function () {
   let errorObj,
      errorCode,
      router = util.getObject(globalArray, "router"),
      sendGetAuthenticationStatus = function () {
         let getAuthenticationStatusObject = globalArray["get-authentication-status"],
            handlerList;
         if (!getAuthenticationStatusObject) {
            getAuthenticationStatusObject = new GetAuthenticationStatusHandler();
            globalArray[getAuthenticationStatusObject.messageName] = getAuthenticationStatusObject;
            globalArray[getAuthenticationStatusObject.responseTag] = getAuthenticationStatusObject;
         } else {
            getAuthenticationStatusObject.resetData();
         }

         if (!!getAuthenticationStatusObject && !!router) {
            getAuthenticationStatusObject.setRequestXML(false);
            handlerList = getAuthenticationStatusObject.composeHandlerList();
            router.postMessage(handlerList);
         }
      };

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   const alreadyAuthenticated =
      this.content["error"] &&
      this.content["error"][this.messageName] &&
      this.content["error"][this.messageName]["errorCode"] ===
         JSCDKBrokerError.JSCDK_BROKER_ERROR_ALREADY_AUTHENTICATED;
   if (!alreadyAuthenticated && this.content["parsedResult"]) {
      // fix bug: 2898590, handle this.content["parsedResult"] is null
      this.handleXmlApiDataProtection(this.content["parsedResult"]["keyParameters"]);
   } else if (this.content["keyParameters"]) {
      // fix bug: 2898590, handle ALREADY_AUTHENTICATED has key-parameters for UAG
      this.handleXmlApiDataProtection(this.content["keyParameters"]);
   }

   if (this.ignoreResponseFlag === false) {
      if (this.state === StateEnum.DONE) {
         this.handleParsedResult(this.content["parsedResult"]);
      }
      // if the error is special for this handler, alert
      router.pushErrorToUser(this);
   } else {
      /**
       * Process the success response, since already_auth is returned as an
       * error.
       */
      if (this.state === StateEnum.DONE) {
         this.handleParsedResult(this.content["parsedResult"]);
         return;
      }
      // treat already authed error as success
      errorObj = this.content["error"];
      if (!!errorObj && !!errorObj[this.messageName]) {
         errorCode = errorObj[this.messageName]["errorCode"];
         if (alreadyAuthenticated) {
            //treat as success to set state to done. cause further step info is
            // already in error message
            this.setState(StateEnum.DONE);
         }

         /**
          * If in workspace one mode, push error to UI layer directly.
          * No need to get authentication status in this case.
          */
         if (errorObj[this.messageName]["workspaceOneServerHostname"]) {
            router.pushErrorToUser(this);
            return;
         }
         if (alreadyAuthenticated) {
            /**
             * Try send get-authentication-status XML, and use its response to
             * decide next action
             */
            sendGetAuthenticationStatus();
            return;
         }
      }

      // if the error is special for this handler, alert
      router.pushErrorToUser(this);
   }
};

/**
 * compose list of message handlers who can be sent together in a single message
 * @return handler list whose messages can be sent together in a single message
 */
GetConfigurationHandler.prototype.composeHandlerList = function () {
   let setLocaleObject,
      composedHandlerList = this.composedHandlerList;
   if (composedHandlerList.length > 0) {
      return composedHandlerList;
   }
   if (globalArray) {
      setLocaleObject = globalArray["set-locale"];
      if (setLocaleObject) {
         composedHandlerList.push(setLocaleObject);
      }
   }
   composedHandlerList.push(this);
   return composedHandlerList;
};

/**
 * parse configuration information from the response XML of GetConfiguration
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return null
 */
GetConfigurationHandler.prototype.parseResult = function (responseXML) {
   const responseBody = $(responseXML);
   const authentication = {};
   let brokerElem;
   let responseTagElem;
   let resultElem;
   let resultText;
   let authElem;
   let authScreenElem;
   let clientConfigElem;
   let keyParametersElem;
   const clientConfiguration = {};
   let workspaceElem;
   let screenNameText;
   let errorCodeElem;
   let errorMessageElem;
   let userMessageElem;
   if (!responseXML || responseXML === "") {
      return null;
   }
   brokerElem = responseBody.find("broker");
   if (!brokerElem || !brokerElem.length) {
      Logger.error("response of GetConfiguration error");
      return null;
   }
   responseTagElem = brokerElem.find(this.responseTag);
   resultElem = responseTagElem.find("result");
   resultText = resultElem.text();
   if (!!resultText && resultText.trim() !== "") {
      authentication["result"] = resultText;
   } else {
      return null;
   }

   clientConfigElem = brokerElem.find("client-configuration");
   if (clientConfigElem) {
      $(clientConfigElem)
         .children("params")
         .children("param")
         .each(function () {
            const child = $(this);
            const nameText = child.find("name").text();
            const valueList = [];
            child.find("value").each(function () {
               valueList.push($(this).text());
            });
            util.addItemForJson(clientConfiguration, nameText, valueList);
         });
   }
   if (clientConfiguration && clientConfiguration["titan-host-name"]) {
      // Once broker returns Titan host server name, enable Titan mode
      globalArray["titanMode"] = true;
      if (clientConfiguration["titan-host-name"][0]) {
         Logger.info("Titan server host is: " + clientConfiguration["titan-host-name"][0]);
         util.addItemForJson(authentication, "TitanMode", clientConfiguration["titan-host-name"][0]);
      }
      return authentication;
   } else {
      globalArray["titanMode"] = false;
   }

   if (resultText === "ok") {
      authElem = brokerElem.find("authentication");
      if (!authElem || !authElem.length) {
         Logger.error("No authentication element in response of getConfiguration");
         return null;
      }

      authScreenElem = authElem.children("screen");
      if (!authScreenElem || !authScreenElem.length) {
         Logger.error("No screen element in response of getConfiguration");
         return null;
      }
      screenNameText = $(authScreenElem).children("name").text();
      util.addItemForJson(authentication, "screen", $(authScreenElem).children("name").text());
      $(authScreenElem)
         .children("params")
         .children("param")
         .each(function () {
            const child = $(this);
            const nameText = child.find("name").text();
            const valueList = [];
            child.find("value").each(function () {
               // Special handle for unauthenticated login
               if (screenNameText === "unauthenticated" && nameText === "username") {
                  valueList.push({
                     value: $(this).text(),
                     default: !!$(this).attr("default")
                  });
               } else if (screenNameText === CLIENT_PUZZLE && nameText === "puzzles") {
                  valueList.push({
                     value: $(this).text(),
                     hash: $(this).attr("hash")
                  });
               } else {
                  valueList.push($(this).text());
               }
            });
            util.addItemForJson(authentication, nameText, valueList);
         });

      // Parse client-configuration section
      clientConfigElem = brokerElem.find("client-configuration");
      if (clientConfigElem) {
         $(clientConfigElem)
            .children("params")
            .children("param")
            .each(function () {
               const child = $(this);
               const nameText = child.find("name").text();
               const valueList = [];
               child.find("value").each(function () {
                  valueList.push($(this).text());
               });
               util.addItemForJson(clientConfiguration, nameText, valueList);
            });
         util.addItemForJson(authentication, "clientConfiguration", clientConfiguration);
      }
   } else if (resultText === "error") {
      const configElem = brokerElem.find("configuration");
      errorCodeElem = $(configElem).find("error-code");
      authentication["error-code"] = errorCodeElem.text();
      errorMessageElem = $(configElem).find("error-message");
      authentication["error-message"] = errorMessageElem.text();
      userMessageElem = $(configElem).find("user-message");
      authentication["user-message"] = userMessageElem.text();
   }

   // Parse key-parameters section
   keyParametersElem = brokerElem.find("key-parameters");
   if (keyParametersElem.length > 0) {
      authentication["keyParameters"] = {
         publicKey: keyParametersElem.find("public-key").text(),
         proof: keyParametersElem.find("proof").text(),
         identifier: keyParametersElem.find("identifier").text(),
         scheme: keyParametersElem.find("scheme").text()
      } as GetConfigurationAuthKeyParameters;
   } else {
      Logger.warning("No key materials are provided, OLD server is used");
   }

   // Parse workspaceOneServerHostname
   workspaceElem = brokerElem.find("workspace-one-server-hostname");
   if (!!workspaceElem && !!workspaceElem.text()) {
      util.addItemForJson(authentication, "workspaceOneServerHostname", workspaceElem.text());

      // Once broker returns ws1 server name, enable ws1 mode
      globalArray["ws1Mode"] = true;
      globalArray["workspaceOneServerHostname"] = workspaceElem.text();
   } else {
      globalArray["ws1Mode"] = false;
      delete globalArray["workspaceOneServerHostname"];
   }
   let ws1ModeService = globalArray["ws1-mode-service"];
   if (!ws1ModeService) {
      ws1ModeService = new Ws1ModeService();
      globalArray[ws1ModeService.globalName] = ws1ModeService;
   }
   ws1ModeService.updateWs1Mode(globalArray["ws1Mode"]);
   return authentication;
};

GetConfigurationHandler.prototype.setAuthTypes = function (authTypes) {
   this.authTypes = authTypes;
};

GetConfigurationHandler.prototype.getAuthTypes = function () {
   return this.authTypes;
};

/**
 * handle parsed xml response to show different authentication dialogs.
 *
 * @param parsedResult [in] the result of parsed response.
 */

GetConfigurationHandler.prototype.handleParsedResult = function (parsedResult, isReauth) {
   let urlHandler = util.getObject(globalArray, "url-handler"),
      urlParams,
      samlSecret,
      submitAuthInfoAction = {} as SubmitAuthInfoAction,
      label = PasscodeAuthLabel.PASSCODE_AUTH_LABEL_RSA_SECURID,
      authenticationAction = {} as AuthenticationAction,
      actionContent = {} as AuthenticationAction["content"],
      router = util.getObject(globalArray, "router"),
      authType,
      authChallenge,
      usernameLabel,
      passcodeLabel;

   if (parsedResult["screen"] === "windows-password") {
      authenticationAction.name = "WindowsPassword";
      if (parsedResult["domain"]) {
         actionContent.domains = parsedResult["domain"].sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
         });
      }

      if (urlHandler) {
         urlParams = urlHandler.params;
         if (urlParams.userName) {
            actionContent.username = urlParams.userName;
         }
         if (urlParams.domainName) {
            actionContent.domainName = urlParams.domainName;
         }
      }
   } else if (parsedResult["screen"] === "windows-password-expired") {
      authenticationAction.name = "WindowsPasswordExpired";
   } else if (parsedResult["screen"] === "securid-passcode") {
      if (urlHandler) {
         urlParams = urlHandler.params;
         if (urlParams.tokenUserName) {
            actionContent.username = urlParams.tokenUserName;
         } else if (urlHandler.params.userName) {
            actionContent.username = urlParams.userName;
         }
      }
      authenticationAction.name = "SecurIDPasscode";
      if (parsedResult["auth-label"]) {
         label = parsedResult["auth-label"][0];
      }
      if (parsedResult["auth-type"]) {
         authType = parsedResult["auth-type"][0];
      }
      if (parsedResult["auth-username-label"]) {
         usernameLabel = parsedResult["auth-username-label"][0];
      }
      if (parsedResult["auth-passcode-label"]) {
         passcodeLabel = parsedResult["auth-passcode-label"][0];
      }

      actionContent.label = label;
      actionContent.authType = authType;
      actionContent.usernameLabel = usernameLabel;
      actionContent.passcodeLabel = passcodeLabel;
   } else if (parsedResult["screen"] === "securid-nexttokencode") {
      if (parsedResult["auth-label"]) {
         label = parsedResult["auth-label"][0];
      }
      if (parsedResult["auth-type"]) {
         authType = parsedResult["auth-type"][0];
      }
      if (parsedResult["auth-challenge-string"]) {
         authChallenge = parsedResult["auth-challenge-string"][0];
      }
      authenticationAction.name = "SecurIDNextTokenCode";
      actionContent.label = label;
      actionContent.authType = authType;
      actionContent.authChallenge = authChallenge;
   } else if (parsedResult["screen"] === "securid-pinchange") {
      authenticationAction.name = "SecurIDPinChange";
      if (parsedResult["message"] && parsedResult["message"][0]) {
         actionContent.message = parsedResult["message"][0];
      }
      /**
       * "user-selectable" should be used to decide whether the mode,
       * which is redundant information, see XML document for details
       */
      if (parsedResult["user-selectable"] && parsedResult["user-selectable"][0]) {
         actionContent["user-selectable"] = parsedResult["user-selectable"][0];
      }

      if (parsedResult["pin1"] && parsedResult["pin1"][0]) {
         actionContent["pin1"] = parsedResult["pin1"][0];
         actionContent["pin1ReadOnly"] = parsedResult.pin1ReadOnly;
      }
   } else if (parsedResult["screen"] === "securid-wait") {
      authenticationAction.name = "SecurIDWait";
   } else if (parsedResult["screen"] === "saml") {
      authenticationAction.name = "SAML";
      samlSecret = util.getObject(globalArray, "samlArt");
      submitAuthInfoAction.method = "SubmitAuthInfo";
      submitAuthInfoAction.type = "SAML";
      submitAuthInfoAction.secret = samlSecret;
      submitAuthInfoAction.reAuth = isReauth;
      delete globalArray["samlArt"];
      if (submitAuthInfoAction.reAuth && parsedResult["result"] === "partial") {
         //To fix the loop of submission of saml auth when failed, see bug 2507055
         const doUnlockAction = { method: "DoUnlock" };
         JSCDKClientController.getInstance().execute(doUnlockAction);
      } else {
         // SAML submit authentication information directly
         JSCDKClientController.getInstance().execute(submitAuthInfoAction);
      }
   } else if (parsedResult["screen"] === "cert-auth") {
      /**
       * If we have been asked to confirm "cert-auth", that means that the
       * user already performed it as part of the SSL handshake when
       * initially connecting to the server.  As a result, it is best to
       * always accept it.
       */
      authenticationAction.name = "CertAuth";
      submitAuthInfoAction.method = "SubmitAuthInfo";
      submitAuthInfoAction.type = "CertAuth";
      submitAuthInfoAction.accept = "true";
      submitAuthInfoAction.reAuth = isReauth;
      JSCDKClientController.getInstance().execute(submitAuthInfoAction);
   } else if (parsedResult["screen"] === "disclaimer") {
      authenticationAction.name = "Disclaimer";
      actionContent.label = parsedResult["text"][0];
   } else if (parsedResult["screen"] === "unauthenticated") {
      authenticationAction.name = "Unauthenticated";
      actionContent.usernames = parsedResult["username"];
      if (urlHandler) {
         urlParams = urlHandler.params;
         if (urlParams.unauthenticatedAccessAccount) {
            actionContent.currentUnauthenticatedUsername = urlParams.unauthenticatedAccessAccount;
         }
      }
   } else if (parsedResult["screen"] === CLIENT_PUZZLE) {
      authenticationAction.name = CLIENT_PUZZLE;
      actionContent.puzzles = parsedResult["puzzles"];
      if (!!parsedResult["algorithm"] && !!parsedResult["algorithm"][0]) {
         actionContent.algorithm = parsedResult["algorithm"][0];
      }
      if (!!parsedResult["type"] && !!parsedResult["type"][0]) {
         actionContent.type = parsedResult["type"][0];
      }

      if (!!parsedResult["domain"] && !!parsedResult["domain"][0]) {
         actionContent.domain = parsedResult["domain"][0];
      }
   }

   // Don't set the username when use unauthenticated
   if (authenticationAction.name !== "Unauthenticated" && !!parsedResult["username"] && !!parsedResult["username"][0]) {
      actionContent.username = parsedResult["username"][0];
      actionContent.usernameReadOnly = parsedResult.usernameReadOnly;
   }
   if (!!parsedResult["domain"] && !!parsedResult["domain"][0]) {
      actionContent.domain = parsedResult["domain"][0];
      actionContent.domainReadOnly = parsedResult.domainReadOnly;
   }

   if (parsedResult["error"] && parsedResult["error"][0]) {
      actionContent.error = parsedResult["error"][0];
   }

   if (parsedResult["clientConfiguration"]) {
      actionContent.clientConfig = {
         enableCredentialCleanupForHTMLAccess: null,
         clientHideServerInformation: null,
         clientHideDomainList: null
      };
      const clientConfiguration = parsedResult["clientConfiguration"];

      if (clientConfiguration["enableCredentialCleanupForHTMLAccess"]) {
         actionContent.clientConfig["enableCredentialCleanupForHTMLAccess"] =
            clientConfiguration["enableCredentialCleanupForHTMLAccess"][0] === "true";
      }
      if (clientConfiguration["clientHideServerInformation"]) {
         actionContent.clientConfig["clientHideServerInformation"] =
            clientConfiguration["clientHideServerInformation"][0] === "true";
      }
      if (clientConfiguration["clientHideDomainList"]) {
         actionContent.clientConfig["clientHideDomainList"] = clientConfiguration["clientHideDomainList"][0] === "true";
      }
   }

   if (parsedResult["workspaceOneServerHostname"]) {
      actionContent.workspaceOneServerHostname = parsedResult["workspaceOneServerHostname"];
   }

   actionContent.brokerUrl = encodeURIComponent(router.brokerUrl);

   if (isReauth) {
      actionContent.reAuth = true;
   }

   if (parsedResult["TitanMode"]) {
      authenticationAction.name = TITAN_CLIENT;
      actionContent.titanServerHostName = parsedResult["TitanMode"];
      authenticationAction.content = actionContent;
      JSCDKSetUI(JSON.stringify(authenticationAction));
      return;
   }

   authenticationAction.content = actionContent;

   /**
    * If there is some puzzle to be resolved for anonymous login, no need
    * to invoke ui
    */
   if (authenticationAction.name === CLIENT_PUZZLE && authenticationAction.content.puzzles) {
      let puzzleHandler = util.getObject(globalArray, "unauthPuzzleHandler");
      if (!puzzleHandler) {
         puzzleHandler = new UnauthPuzzleHandler();
         globalArray["unauthPuzzleHandler"] = puzzleHandler;
      }
      puzzleHandler.doCalculation({
         puzzles: authenticationAction.content.puzzles,
         domain: authenticationAction.content.domain,
         algorithm: authenticationAction.content.algorithm,
         type: authenticationAction.content.type
      });
      return;
   }

   JSCDKSetUI(JSON.stringify(authenticationAction));
};

GetConfigurationHandler.prototype.getKeyParameters = function (): GetConfigurationKeyParameters {
   return this.keyParameters;
};

GetConfigurationHandler.prototype.setKeyParameters = function (keyParameters: GetConfigurationKeyParameters) {
   this.keyParameters = keyParameters;
};

/**
 * Validate authentication and storage encryption/decryption keys
 */
GetConfigurationHandler.prototype.handleXmlApiDataProtection = function (
   keyParameters?: GetConfigurationAuthKeyParameters
) {
   const cryptoKeyDerivationService = util.getObject(
      globalArray,
      "cryptoKeyDerivationService"
   ) as CryptoKeyDerivationService;
   let keyStrengthRes;

   if (cryptoKeyDerivationService) {
      // Validate authentication and storage encryption/decryption keys
      if (keyParameters) {
         // When result is error, parsedResult may be undefined
         const { identifier: clientIdentifier, nonce } = this.getKeyParameters() as GetConfigurationKeyParameters;
         const { identifier: serverIdentifier, publicKey, proof, scheme } = keyParameters;

         if (proof === cryptoKeyDerivationService.getProof()) {
            // SR 2901552, fix ALREADY_AUTHENTICATED return the old key-parameter for UAG
            Logger.info(
               "The server proof is the same as the locally stored proof, so there is no need to recompute and revalidate."
            );
            return;
         }

         if (!publicKey) {
            Logger.error("Server public key is null, cannot be parsed");
            cryptoKeyDerivationService.toggleXmlApiDataProtectionStatus(false);
            cryptoKeyDerivationService.showKeyNegotiateError();
            return;
         }
         /**
          * We need to check the server's key-parameters to decide the negotiation result,
          * Treat as fatal error and report the error to user once any check fails.
          */
         keyStrengthRes = cryptoKeyDerivationService.validateKeyStrength(publicKey);
         if (keyStrengthRes === false) {
            Logger.error("Server public key couldn't be validated");
            cryptoKeyDerivationService.toggleXmlApiDataProtectionStatus(false);
            cryptoKeyDerivationService.showKeyNegotiateError();
            return;
         }
         // now web client and chrome client only support scheme: SCHEME-AES1
         if (scheme !== "SCHEME-AES1") {
            Logger.error("Server selected a scheme which was not supported by client");
            cryptoKeyDerivationService.toggleXmlApiDataProtectionStatus(false);
            cryptoKeyDerivationService.showKeyNegotiateError();
            return;
         }
         const secretKey = cryptoKeyDerivationService.computeDHSecretKey(publicKey);
         cryptoKeyDerivationService.generateFinalKeyAndProof(
            secretKey,
            scheme,
            nonce,
            clientIdentifier,
            serverIdentifier
         );
         const proofValidateRes = cryptoKeyDerivationService.validateProof(proof);
         if (proofValidateRes === false) {
            Logger.error("Fail to generate the final keys");
            cryptoKeyDerivationService.toggleXmlApiDataProtectionStatus(false);
            cryptoKeyDerivationService.showKeyNegotiateError();
            return;
         }
         Logger.info("Key materials from server are all valid and final keys are generated.");
      } else {
         cryptoKeyDerivationService.toggleXmlApiDataProtectionStatus(false); // Disable XML-API data protection
      }
   } else {
      Logger.error("The CryptoKeyDerivationService missing.");
   }
};

/**
 * key-parameters in the request XML
 */
export interface GetConfigurationKeyParameters {
   publicKey: string;
   nonce: string;
   identifier: string;
   schemes: string[];
}

/**
 * key-parameters in the response XML
 */
export interface GetConfigurationAuthKeyParameters {
   publicKey: string;
   proof: string;
   identifier: string;
   scheme: string;
}
