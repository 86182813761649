/**
 * ***************************************************************************
 * Copyright 2018-2020 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

/**
 *------------------------------------------------------------------------------
 *
 * fs-observer.js
 *
 * Implementation for feature observeDirectory, used to monitor folder remove, or
 * renamed, don't support create for now.
 * Only support folder for now.
 *
 * Only support one observer for now, which means all event firing share the same
 * kill-switch.
 *
 *------------------------------------------------------------------------------
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
// use type any for loggerService for now

type Entry = any;
type Timer = number | null;

class ObservedEntry {
   constructor(
      public entry: Entry,
      public onRemoved: (Entry, any) => void,
      public param: any
   ) {}
}

export class FsObserver {
   private static readonly MONITOR_INTERVAL: number = 5000;
   private logger;
   private observedList: Array<ObservedEntry>;
   private monitorTimer: Timer;
   constructor() {
      this.observedList = [];
      this.monitorTimer = null;
      this.logger = new Logger(Logger.CDR);
   }
   /**
    * We invoke the same callback only when the folder
    * can't be used anymore, no matter it's removed or
    * renamed.
    * @param  {object} entry
    * @param  {function} onRemoved
    * @param  {object} param parameter will be passed as 2nd param in the onRemoved
    */
   public observeDirectory = (entry: Entry, onRemoved: () => void, param: any): void => {
      this.logger.trace("spy on folder " + entry.name);
      this.observedList.push(new ObservedEntry(entry, onRemoved, param));
   };

   private removeChecker = (checker: ObservedEntry): void => {
      for (let i: number = 0; i < this.observedList.length; i++) {
         if (this.observedList[i] === checker) {
            this.observedList.splice(i, 1);
            break;
         }
      }
   };

   private checkEntryStatus = (checker: ObservedEntry): void => {
      const onFail: () => void = () => {
         this.logger.warning("redirected folder rejected: " + checker.entry.name);
         checker.onRemoved(checker.entry, checker.param);
         this.removeChecker(checker);
      };
      checker.entry.getMetadata((metaData) => {
         if (!metaData) {
            onFail();
         }
      }, onFail);
   };

   private checkStatus = (): void => {
      for (let i = 0; i < this.observedList.length; i++) {
         this.checkEntryStatus(this.observedList[i]);
      }
   };

   public startMonitor = (): void => {
      this.logger.debug("start monitor folders");
      if (!this.monitorTimer) {
         this.monitorTimer = Number(setInterval(this.checkStatus, FsObserver.MONITOR_INTERVAL));
      }
   };

   public stopMonitor = (): void => {
      this.logger.debug("stop monitor folders");
      if (this.monitorTimer) {
         clearInterval(this.monitorTimer);
         this.monitorTimer = null;
      }
   };

   public clear = () => {
      this.stopMonitor();
      this.observedList = [];
   };
}
