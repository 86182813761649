<form id="securIDNextTokenForm" name="securIDNextTokenForm" [formGroup]="securIDNextTokenForm" (ngSubmit)="onSubmit($event)">
   <div class="ui-panel-content next-token">
      <div [hidden]="blankOutContent">
         <div class="ui-login-hint-text ui-multi-line-margin ui-multi-line ui-text-left-align selectable">{{challengeString}}</div>
         <div class="form-horizontal">
            <label for="passcode">{{'dialog_placeholder_nexttoken' | i18nv2}}</label>
            <input id="passcode" type="text" (focus)="changeType('passcode')" class="form-control" placeholder="{{'dialog_placeholder_passcode' | i18nv2}}" [formControl]="passcodeControl" [autoFocus]>
            <button *ngIf="passcodeControl.value" class="ui-clear-btn ui-clear-next-token-btn" id="clearPasswordBtn" type=button (click)="clearNextTokenCode()" tabindex="-1">{{'clear_next_token_code' | i18nv2}}</button>
         </div>
      </div>
      <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
   </div>
   <div class="ui-panel-footer ui-panel-footer-change-password">
      <button id="loginButton" class="btn btn-primary" [ngClass]="{'btnPrimaryWaiting':blankOutContent}" type="submit" [disabled]="!securIDNextTokenForm.valid" [hidden]="blankOutContent">{{ 'dialog_wait_continue' | i18nv2}}</button>
      <button id="cancelLoginBtn" class="btn btn-default" [ngClass]="{'btnDefaultWaiting':blankOutContent}" (click)="cancel()">{{'CANCEL' | i18nv2}}</button>
   </div>
</form>