/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * rollbackDesktopCommand.js --
 *
 * rollback desktop.
 *
 */

import { globalArray } from "../jscdkClient";
import RollbackCheckoutDesktopHandler from "../controllers/rollbackCheckoutDesktopHandler";
import { MessageHandler } from "../controllers/messageHandler";

export default function RollbackDesktopCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param rollbackDesktopAction[in]
 *        - its property 'method' has the value 'RollbackDesktop'
 */
RollbackDesktopCommand.prototype.execute = function (rollbackDesktopAction) {
   let handlerList,
      desktopId,
      rollbackDesktopObject,
      router = globalArray["router"];

   desktopId = rollbackDesktopAction.desktopId;

   if (!!router && !!desktopId) {
      rollbackDesktopObject = router.getHandler("rollback-checkout-desktop", desktopId);
      if (!rollbackDesktopObject) {
         rollbackDesktopObject = new RollbackCheckoutDesktopHandler();
         globalArray[rollbackDesktopObject.messageName + rollbackDesktopObject.requestId] = rollbackDesktopObject;
         globalArray[rollbackDesktopObject.responseTag + rollbackDesktopObject.requestId] = rollbackDesktopObject;
         /**
          * update MessageHandler.prototype.requestIdKV
          * the format is {responseTag1+desktop-id1 : requestId1,
          * responseTag2+desktop-id2 : requestId2} such as key of
          * RollbackCheckoutDesktopHandler is
          * "rollback-checkout-desktop"+desktopId
          */
         MessageHandler.prototype.requestIdKV[rollbackDesktopObject.responseTag + desktopId] =
            rollbackDesktopObject.requestId;
      } else {
         rollbackDesktopObject.resetData();
      }

      if (rollbackDesktopObject) {
         rollbackDesktopObject.setRequestXML(desktopId);
         handlerList = rollbackDesktopObject.composeHandlerList();
         router.postMessage(handlerList);
      }
   }
};
