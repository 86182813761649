/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Logger, clientUtil } from "@html-core";
import { ClipboardDesktopService } from "../../../shared/common/service/clipboard-desktop-monitor-service";

/**
 * This service is to monitor the focus and blure on main monitor and extended monitor,
 * so that we can handle clipboard event correctly for text, html and image
 */

@Injectable({
   providedIn: "root"
})
export class ClipboardPrimaryDesktopMonitorService extends ClipboardDesktopService {
   private mainWindow;
   constructor() {
      super();
   }

   public onMonitorReady = (): void => {
      this.mainWindow = this.getMainWindow();
      if (this.mainWindow) {
         this.mainWindow.contentWindow.timeOnBlurOtherWindow = 0;
      }
      let currentWindow = chrome.app.window.current();
      currentWindow.contentWindow.addEventListener("blur", this._onBlur);
   };

   private _onBlur = (): void => {
      let currentWindow = chrome.app.window.current();
      Logger.debug("window got blurred: " + currentWindow.id);
      if (this.mainWindow) {
         this.mainWindow.contentWindow.timeOnBlurOtherWindow = new Date().getTime();
      } else {
         this.mainWindow = {
            contentWindow: {
               timeOnBlurOtherWindow: 0
            }
         };
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * shouldSkipPaste
    *
    * This is for Chrome client under multi-monitor mode
    * We only skip paste in the 2 situations:
    * 1. when move is inside Chrome client from one monitor to another
    * 2. when both text and html are empty
    *
    *---------------------------------------------------------------------------
    */

   public shouldSkipPaste = (timeOnFoucsCurrentWindow: number): boolean => {
      if (clientUtil.isMultiMonitorMode()) {
         let skipClipboard = false;
         if (
            timeOnFoucsCurrentWindow - this.mainWindow.contentWindow.timeOnBlurOtherWindow <
            ClipboardDesktopService.delayTime
         ) {
            Logger.debug("moves are inside chrome client's monitors");
            skipClipboard = true;
         }
         return skipClipboard;
      } else {
         return false;
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * blockKeyEventOnFoucs
    *
    * This function is for clipboard SR ESC-45468 in multi-monitor,
    * it block keys for 1s when move is from outside the client to client-side, it
    * ensure clipboard's content in the clipboard is successfully updated on
    * the agent side, so that paste can get the updated contents.
    *
    *---------------------------------------------------------------------------
    */

   public blockKeyEventOnFoucs = (timeOnFoucsCurrentWindow: number, updateKey: Function): void => {
      if (
         timeOnFoucsCurrentWindow - this.mainWindow.contentWindow.timeOnBlurOtherWindow <
         ClipboardDesktopService.delayTime
      ) {
         Logger.debug("move is inside Chrome client between monitors, don't block keys");
         return;
      }
      Logger.debug("move is from outside the client to client-side, block keys for 300ms");
      updateKey(true);
      setTimeout(() => {
         updateKey(false);
      }, ClipboardDesktopService.delayTime);
   };
}
