<div class="base-panel usb-panel usb-handle {'usb-panel-full':redirectedDevices.length > 0}" [hidden]="!showUsbPanel" [ngClass]="isUsbListEmpty() ? 'collapseUsbPanel' : 'expandUsbPanel'">
   <div class="usb-header ui-draggable-handle usb-handle">
      <div class="icon-usb-info" [hidden]="!isUsbListEmpty()"></div>
      <div class="usb-title">{{'USB_DEVICE_REDIRECTION' | i18nv2}}</div>
      <div class="usb-icon close" (click)="toggleUsbPanel()" title="{{'USB_CLOSE_PANEL' | i18nv2}}"></div>
   </div>
   <div class="usb-content usb-handle">
      <div class="usb-content-text text-display" [hidden]="!showUsbBtn || redirectedDevices.length !== 0">
         {{'USB_CLICK' | i18nv2}}</div>
      <div class="usb-content-text text-display" [hidden]="!showUsbBtn || !(redirectedDevices.length > 0)">{{'USB_DEVICES' | i18nv2}}
      </div>
      <div class="usb-content-text text-display" [hidden]="showUsbBtn">{{placeholder}}
      </div>
      <div class="usb-device-list usb-handle" [hidden]="!(redirectedDevices.length > 0)">
         <div class="table-header">
            <div class="table-header-device item">{{'DEVICE' | i18nv2}}</div>
            <div class="table-header-status item">{{'STATUS' | i18nv2}}</div>
            <div class="table-header-button item"></div>
         </div>
         <div class="table-content" *ngFor="let x of redirectedDevices; index as i">
            <div class="usb-product-name item" title="{{x.getProductName()}}">{{x.getProductName()}}</div>
            <div class="usb-product-status item">{{x.status | i18nv2}}</div>
            <div class="usb-release item">
               <button (click)="releaseDevice(i)" id="usb-release-button">
                  {{'RELEASE' | i18nv2}}
               </button>
            </div>
         </div>
      </div>
      <div class="usb-footer ui-draggable-handle usb-handle">
         <button id="add-device-button" class="modal-button-base modal-button-left modal-button-blue usb-add-button"
            (click)="findDevice()" [hidden]="!showUsbBtn">
            <div class="usb-add-device-btn-text">{{'ADD_DEVICE' | i18nv2}}</div>
         </button>
      </div>
      <div class="usb-footer-placeholder"></div>
   </div>
</div>