/**
 * ***************************************************************************
 * Copyright 2021 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

/**
 * Video Resource Manager
 */

import { Injectable } from "@angular/core";
import { LocalStorageService } from "@html-core";
import { HTML5MMR_CONST } from "../channels/html5MMR-client/html5MMR-consts";
import { AbstractMediaResourceManager } from "./abstract-media-resource-manager";
import { MediaType } from "./media-resource-manager";
import { MediaResourceUtil } from "./media-resource-util";
import { MediaStat } from "./model/media.models";
import { RootModel } from "../../common/model/root-model";

@Injectable({
   providedIn: "root"
})

/**
 * Resource Manager implementation for managing video resources
 */
export class VideoResourceManager extends AbstractMediaResourceManager {
   /**
    * Construct a video resource manager
    */
   constructor(
      private localStorageService: LocalStorageService,
      private rootModel: RootModel
   ) {
      super(MediaType.video);
   }

   /**
    * Create a new Video HTMLMediaElement by given id and media parameters
    *
    * @param  {string} id given element id
    * @param  {any} params an JSON object that specify media element parameters
    */
   public createElement = (id: string, params: any = {}): HTMLMediaElement | undefined => {
      const videoElem: HTMLMediaElement = MediaResourceUtil.createVideoElement(id),
         wrapperElem: HTMLElement = videoElem.parentElement,
         initPosition: string = "absolute",
         initZIndex: number = 10;

      this.updateElement(id, params);

      // Init style attributes for media wrapper element
      Object.assign(wrapperElem.style, {
         position: initPosition,
         zIndex: initZIndex
      });

      // Init style attributes for the video element
      // Note this will ensure child video element has same position and size as its wrapper element
      Object.assign(videoElem.style, {
         position: initPosition,
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         minWidth: "100%",
         minHeight: "100%",
         width: "0px",
         height: "0px",
         zIndex: initZIndex,
         overflow: "hidden"
      });

      this.updateObjectFit(videoElem, params);

      const statsOverlayEnabled =
         this.localStorageService.get(HTML5MMR_CONST.STATS_OVERLAY_LOCAL_STORAGE_KEY) === "true";
      if (statsOverlayEnabled) {
         const statsElement: HTMLTextAreaElement = document.createElement("textarea");
         statsElement.id = `${id}_stats`;
         wrapperElem.appendChild(statsElement);

         Object.assign(statsElement.style, {
            position: initPosition,
            resize: "auto",
            minHeight: "80px",
            background: "red",
            color: "white",
            opacity: 0.6,
            zIndex: 100
         });
      }

      return videoElem;
   };

   /**
    * Update media stats by media element id and corresponding {MediaStat} model
    *
    * @param  {string} id
    * @param  {MediaStat} model
    */
   public updateStats = (id: string, model: MediaStat) => {
      const statsElement: HTMLTextAreaElement = document.getElementById(`${id}_stats`) as HTMLTextAreaElement;
      if (!statsElement) {
         return;
      }
      model.id = id.replace(MediaResourceUtil.VIDEO_ELEMENT_PREFIX, "");
      statsElement.textContent = model.description;
   };

   /**
    * Retrieve video elements
    *
    * @returns HTMLCollectionOf
    */
   public getElements = (): HTMLCollectionOf<HTMLMediaElement> => {
      return MediaResourceUtil.getVideoElements();
   };

   /**
    * Update given video element with given location parameters
    *
    * @param  {string} id  given video element id
    * @param  {any} params location parameters (e.g. left, top, width, height ... etc)
    * @returns HTMLMediaElement updated video media element
    */
   public updateElement = (id: string, params: any = {}): HTMLMediaElement | undefined => {
      const child: HTMLMediaElement = this.getElement(id);
      if (!child || !params) {
         this.logger.error("VideoResourceManager update element cannot be performed because element is missing: " + id);
         return null;
      }

      const elem: HTMLElement = child.parentElement;

      if (this.rootModel && this.rootModel.get("showChromeMenuBar")) {
         params.top += HTML5MMR_CONST.TOP_HEIGHT_FOR_VIDEO_ADJUSTMENT;
         params.bottom = HTML5MMR_CONST.TOP_HEIGHT_FOR_VIDEO_ADJUSTMENT;
      }

      this.updateElementLocation(elem, params);
      this.updateElementSize(elem, params);
      this.updateObjectFit(elem, params);
      this.updateObjectFit(child, params);
      this.updateClipPath(elem, params);
      this.updateZIndex(elem, params);
      this.disableContextMenu(elem);
      this.disablePointerEvent(elem);

      return child;
   };

   /**
    * Disable context menu for given video element
    *
    * @param  {HTMLElement} elem  given video element to be disabled
    * @returns HTMLMediaElement
    */
   private disableContextMenu = (elem: HTMLElement): HTMLElement => {
      elem.oncontextmenu = () => {
         return false;
      };
      return elem;
   };

   /**
    * Update zindex of each video element
    *
    * @param  {HTMLMediaElement} elem
    * @param  {any} params
    * @returns HTMLMediaElement
    */
   private updateZIndex = (elem: HTMLElement, params: any): HTMLElement => {
      if (params.zIndex !== undefined) {
         Object.assign(elem.style, {
            zIndex: params.zIndex
         });
      }
      return elem;
   };

   /**
    * Disable pointer event for all video tags.
    * This is to discard events and make them propagate to the main canvas underneath.
    * @param elem given video media element
    * @returns HTMLMediaElement the element has pointerEvents set to 'none'
    */
   private disablePointerEvent = (elem: HTMLElement): HTMLElement => {
      Object.assign(elem.style, {
         pointerEvents: "none"
      });
      return elem;
   };

   /**
    * Helper function that updates video element location/position based on left/right/top/bottom parameters
    *
    * @param  {HTMLMediaElement} elem
    * @param  {any} params
    */
   private updateElementLocation = (elem: HTMLElement, params: any): HTMLElement => {
      if (params.left !== undefined) {
         Object.assign(elem.style, {
            left: params.left + "px"
         });
      }

      if (params.top !== undefined) {
         Object.assign(elem.style, {
            top: params.top + "px"
         });
      }

      if (params.bottom !== undefined) {
         Object.assign(elem.style, {
            bottom: params.bottom + "px"
         });
      }

      if (params.right !== undefined) {
         Object.assign(elem.style, {
            right: params.right + "px"
         });
      }

      return elem;
   };

   /**
    * Helper function that updates video element size based on width height parameters
    *
    * @param  {HTMLMediaElement} elem
    * @param  {any} params
    */
   private updateElementSize = (elem: HTMLElement, params: any): HTMLElement => {
      if (params.height !== undefined) {
         Object.assign(elem.style, {
            height: params.height + "px"
         });
      }

      if (params.width !== undefined) {
         Object.assign(elem.style, {
            width: params.width + "px"
         });
      }

      return elem;
   };

   /**
    * Helper function that updates video element's clip path
    * Reference link: https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path
    *
    * @param  {HTMLElement} elem
    * @param  {any} params
    */
   private updateClipPath = (elem: HTMLElement, params: any): HTMLElement => {
      if (params.regionMask !== undefined) {
         Object.assign(elem.style, {
            clipPath: `path('${params.regionMask}')`
         });
      }

      return elem;
   };

   /**
    * Helper function that updates video element's objectFit style sheet parameter:
    * Reference link: https://www.w3schools.com/css/css3_object-fit.asp
    *
    * @param  {HTMLMediaElement} elem
    * @param  {any} params
    */
   private updateObjectFit = (elem: HTMLElement, params: any): HTMLElement => {
      if (params.objectFit !== undefined) {
         Object.assign(elem.style, {
            objectFit: params.objectFit || "cover"
         });
      }

      return elem;
   };

   /**
    * Update srcObject property of the video media element by given Id and MediaStream
    *
    * @param  {string} id given video media element id
    * @param  {MediaStream} stream a stream of media content to be associated with given video media element
    */
   public updateSourceObject = (id: string, stream: MediaStream) => {
      const elem: HTMLMediaElement = this.getElement(id);
      if (!elem) {
         this.logger.error("VideoResourceManager failed to update source object.");
         return;
      }

      /// TODO: validate/process stream
      // try {
      //    elem.src = window.URL.createObjectURL(stream);
      // } catch (e) {
      //    elem.srcObject = stream;
      // }

      elem.srcObject = stream;
      if (!stream) {
         elem.pause();
      } else {
         elem.play();
      }
   };
}
