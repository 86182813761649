/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * unity-service.js --
 *
 * Module for wrapping unity to angular factory service. Unity Service is a
 * singleton service.
 *
 */

import { Injectable } from "@angular/core";
import { AB } from "../common/appblast-util.service";
import { EventBusService, BusEvent } from "@html-core";
import Logger from "../../../core/libs/logger";
import { ExtendedWindowUnity } from "./unity-exchange";
import { RemoteSessionEventService } from "../../common/remote-session/remote-session-event.service";
import { Subject, Subscription } from "rxjs";
import { BlastWmks } from "../common/blast-wmks.service";
import { UnityService } from "./unity.service";
import { UnityMonitorConnector } from "./unity-monitor-connector.service";
@Injectable()
export class UnityExtendedMonitorService extends UnityService {
   constructor(
      remoteSessionEventService: RemoteSessionEventService,
      eventBusService: EventBusService,
      private unityMonitorConnector: UnityMonitorConnector
   ) {
      super(remoteSessionEventService, eventBusService);
      Logger.info("construct UnityExtendedMonitorService");
   }

   protected _onWmksSessionConnecting = (session: any) => {
      const handler = new ExtendedWindowUnity.Handler(session.key, this.subject$);
      const unityMgr = new ExtendedWindowUnity.Mgr(session.key, this.eventBusService);
      this.unityMonitorConnector.init(handler, unityMgr); //primary monitor to extended monitor
      this.unityMgrMap.set(session.key, unityMgr);
      handler.onVisibilityChanged(false);
   };

   public init = () => {
      Logger.info("response to ReadyToDisplay in UnityExtendedMonitorService");
      this.eventBusService.listen(BusEvent.ReadyToDisplay.MSG_TYPE).subscribe((msg: BusEvent.ReadyToDisplay) => {
         this.connectUnity(msg.sessionKey);
      });
   };

   public connectUnity(sessionKey: string) {
      Logger.info("connect Unity for this extended monitor");
      const session = {
         key: sessionKey
      };
      Logger.info("Unity server inited for Extended monitor");
      this._onWmksSessionConnecting(session);
   }
}
