/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Component, OnDestroy, OnInit, Optional } from "@angular/core";
import { AbstractModalComponent } from "../commondialog/abstract-modal.component";
import { ClientModeService } from "../service/client-mode.service";
import { CLIENT_MODE, EventBusService, clientUtil, BusEvent, TranslateService } from "@html-core";
import { Ws1Service } from "../service/ws1.service";
import { ClientSettingModel } from "../model/client-setting-model";
import { ViewClientModel } from "../model/viewclient-model";
import { DebugConfigurationModel } from "../model/debug-configuration-model";
import { FeatureConfigs } from "../model/feature-configs";
import Logger from "../../../core/libs/logger";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { MultiSessionSingleUserService } from "../service/multisession-singleuser.service";
import { UtilService } from "../../launcher/common/util-service";
import { Timezone } from "../timezone/timezone.service";
import { LocalLogService } from "@html-core";
import { ModalDialogService } from "../commondialog/dialog.service";
import { WmksService } from "../../desktop/common/wmks.service";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { ChangeDetectorRef } from "@angular/core";
import { AudioPlayControl, AudioDelayOption } from "../../desktop/common/audio-play.control";
import { HTML5MMR_CONST } from "../../desktop/channels/html5MMR-client/html5MMR-consts";
import { ConnectionServerModel } from "../model/connection-server-model";
import { XmlApiService } from "../../desktop/sidebar/xml-api.service";
import { PreDataSetModel } from "../model/pre-data-set-model";
import { UserGlobalPref } from "../service/user-global-pref";
import { GoogleCommonSettings } from "../../../chrome-client/launcher/server-connect/google-common-settings.service";
import { AjaxBusyService } from "../ajax-busy/ajax-busy.service";
import { DISPLAY } from "../service/monitor-info.service";
import { NetworkStateService } from "../../desktop/networkState/network-state.service";
import { AutoFowardUSBService } from "../../launcher/auto-usb-setting/auto-usb-foward.service";
import { MultipleDisplayService } from "../../desktop/common/multi-display.service";

const isChromeClient = clientUtil.isChromeClient();

@Component({
   selector: "setting-dialog",
   templateUrl: "./setting-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
         /deep/ .setting-window .modal-content {
            height: calc(100% - 50px);
         }
      `
   ]
})
export class SettingDialogComponent extends AbstractModalComponent implements OnInit, OnDestroy {
   private isLauncher: boolean = null;
   private isWaiting: boolean = null;
   private adminSettings;
   private adminCommonSettings;
   private enableHighResolution;
   private enableVideoStatsOverlay;
   private areMacOSXKeyMappingsEnabled;
   private isWindowsKeyEnabled;
   private isWindowsDeleteKeyEnabled;
   private isCtrlShiftAltUpKeyEnabled;
   private isMP4Enabled;
   private isMultiMonitorEnabled;
   private isMultiMonitorDpiSyncEnabled;
   private isMultiMonitorSupported;
   private isFitToViewerEnabled;
   private isSystemTrayEnabled;
   private isUagWithIdp;
   private isCollectLogEnabledinStorage;
   private isCustomizedTimezoneChanged;
   private mIsTouchDevice;
   private isCollectLogSupport;
   private isCollectLogEnabled;
   private isVRModeEnabled;
   private isNumEnabled;
   private onMultiMonitorKillSwitch;
   private onCdrKillSwitch;
   private logPath;
   private multiSessionAppExist;
   private hasSingleSessionAppSession;
   private resetButtonEnabled;
   private isGeolocationShared;
   private donotShowGeolocationDialog;
   private isMediaStreamPermissionGranted;
   private donotShowMediaStreamPermissionDialog;
   private isWebRTCRedirectionEnabled;
   private isScreenShareEnabled;
   private isBCREnabled;
   private initialHRStatus: boolean;
   private displaySetting: number;
   private _useWindowReplaceApi;

   public enableDebugConfiguration;
   public isAutoTimezoneEnabled;
   public isFolderSharingEnabled;
   public isFileAssociationEnabled;
   public toggleSwitches;
   public killSwitches;
   public logPathInfo;
   public multiMonitorOptionVisible;
   public multiLaunchOptionVisible;
   public sessionManagementCenterOptionVisible;
   public keyMappingOptionVisible;
   public isSkipPortalSupported;
   public deviceInfo;
   public selectedTimezone;
   public supportAudioDelayControl;
   public selectedDelayControlOption: AudioDelayOption;
   public delayControlOptions: Array<AudioDelayOption>;
   public isTitanClient: boolean = clientUtil.isTitanClient();
   public isTitanSingleLogoutEnabled: boolean;
   public isTitanAutoResumptionEnabled: boolean;
   public isTitanAutoSignOut: boolean;
   public isAutoUSBEnabled: boolean = false;
   public showLaunchHorizonOption: boolean = false;
   public folderSharingEditable: boolean = true;
   public isAutoUSBEditable: boolean = true;
   public disableNetworkStateDisplay: boolean = false;
   public isRTAVSupported = true;
   public enableAdvancedCodec: boolean = false;

   constructor(
      private clientModeService: ClientModeService,
      private ws1Service: Ws1Service,
      private eventBusService: EventBusService,
      private clientSettingModel: ClientSettingModel,
      private viewClientModel: ViewClientModel,
      private debugConfigurationModel: DebugConfigurationModel,
      private featureConfigs: FeatureConfigs,
      private translate: TranslateService,
      private multipleDisplayService: MultipleDisplayService,
      private localStorageService: LocalStorageService,
      private multiSessionSingleUserService: MultiSessionSingleUserService,
      private xmlApiService: XmlApiService,
      private utilService: UtilService,
      private timezone: Timezone,
      private localLogService: LocalLogService,
      private modalDialogService: ModalDialogService,
      private wmksService: WmksService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private changeDetector: ChangeDetectorRef,
      private connectionServerModel: ConnectionServerModel,
      private preDataSetModel: PreDataSetModel,
      private userGlobalPref: UserGlobalPref,
      private googleCommonSettings: GoogleCommonSettings,
      private ajaxBusyService: AjaxBusyService,
      private networkStateService: NetworkStateService,
      @Optional()
      private autoForwardUSBService: AutoFowardUSBService
   ) {
      super();

      this._useWindowReplaceApi = this.featureConfigs.getConfig("KillSwitch-WindowReplacementApi");

      this.supportAudioDelayControl = isChromeClient;
      this.delayControlOptions = [AudioDelayOption.auto, AudioDelayOption.disabled, AudioDelayOption.enabled];
      if (this.clientModeService.clientMode === CLIENT_MODE.LAUNCHER) {
         this.isLauncher = true;
      } else if (this.clientModeService.clientMode === CLIENT_MODE.DESKTOP) {
         this.isLauncher = false;
      }

      if (!clientUtil.isTitanClient()) {
         this.updateResetButtonStatus();
         this.isWaiting = true;
         this.eventBusService.listen("GetLaunchItems").subscribe(() => {
            this.isWaiting = false;
         });
      } else {
         if (this.viewClientModel.mIsAndroid || this.viewClientModel.mIsIOS) {
            this.showLaunchHorizonOption = true;
         }
         this.eventBusService.dispatch(new BusEvent.RefreshEntitlementMsg());
         this.eventBusService.listen(BusEvent.AppSessionUpdatedMsg.MSG_TYPE).subscribe(() => {
            this.updateResetButtonStatus();
         });
      }

      this.eventBusService.listen(BusEvent.DisplaySettingChanged.MSG_TYPE).subscribe(() => {
         this.displaySetting = this.clientSettingModel.getPrefNumberItem("displaySetting");
      });

      this.setUp();

      this.isSkipPortalSupported = !isChromeClient;

      /**
       * clarify if it's need to add multi-launch row in setting dialog
       * if there is no app in ENALBE_DEFAULT_OFF or ENABLED_DEFAULT_ON, the
       * multi-launch row will not show
       *
       * This option will also be hidden during perf loading, but this should be quick
       */
      this.multiSessionSingleUserService.hasConfigDisplayableApp().then((hasApps) => {
         setTimeout(() => {
            this.multiLaunchOptionVisible = hasApps;
         });
      });

      this.sessionManagementCenterOptionVisible = isChromeClient;
      this.keyMappingOptionVisible = this.clientSettingModel.getBooleanItem("enableKeyMapping");
      if (this.isLauncher) {
         /**
          * Allow reset apps when multi-session exist
          */
         setTimeout(() => {
            this.multiSessionAppExist = this.multiSessionSingleUserService.hasResettableSessions();
         });
      } else {
         /**
          * clarify if it's need to add multi-launch row in setting dialog
          * if there is no app in ENALBE_DEFAULT_OFF or ENABLED_DEFAULT_ON, the
          * multi-launch row will not show
          *
          * This option will also be hidden during perf loading, but this should be quick
          */
         this.multiSessionSingleUserService.hasConfigDisplayableApp().then((hasApps) => {
            setTimeout(() => {
               this.multiLaunchOptionVisible = hasApps;
            });
         });
      }

      setTimeout(() => {
         this.ajaxBusyService.setAjaxBusy(false);
      });

      if (!clientUtil.isChromium() && !clientUtil.isFirefox()) {
         this.isRTAVSupported = false;
      }
   }

   ngOnInit() {
      /**
       * set the timezone default value in the select menu when
       * dialog is shown. This will make sure select menu has been
       * included. The dialog display time is a later than running(),
       * watch on the dialog to show default value.
       */
      this.showDefaultTimezone();
      this.showAudioDelayControl();
      this.updateLogLevel();
      this.updateCloseStatus();
   }

   ngOnDestroy() {
      this.clientSettingModel.saveSetting();
      this.onDestroy();
   }

   public updateResetButtonStatus = () => {
      this.xmlApiService.hasApplicationSession().then((applicationSessions) => {
         if (!!applicationSessions && applicationSessions.length > 0) {
            this.hasSingleSessionAppSession = !!applicationSessions && applicationSessions.length > 0;
         }
         this.resetButtonEnabled =
            this.hasSingleSessionAppSession || this.multiSessionSingleUserService.hasResettableSessions();
      });
   };

   public updateCloseStatus = () => {
      this.eventBusService.listen(BusEvent.UpdateSettingDialogStatus.MSG_TYPE).subscribe(() => {
         this.closeSettingModal();
      });
   };
   public updateTimezone = (timezone) => {
      this.selectedTimezone = timezone;
   };

   // Save for timezone syncing.
   private saveTimezone = () => {
      if (this.isAutoTimezoneEnabled || !this.isCustomizedTimezoneChanged) {
         return;
      }
      if (!this.isAutoTimezoneEnabled) {
         if (this.selectedTimezone === undefined) {
            this.selectedTimezone = this.timezone.getWindowsTimezone();
         }
         this.clientSettingModel.updateSetting("timezoneSync", this.selectedTimezone);
         this.clientSettingModel.saveSetting();
      }
   };

   private onDestroy = () => {
      this.unbindKillSwitchse();
      // save timezone setting when controller destroy
      this.saveTimezone();
   };

   /**
    * kill switch can take affect in real time.
    */
   private _bindKillSwitch = (switchName, key, onChange?) => {
      const handle = (killSwitchOn) => {
         setTimeout(() => {
            this.killSwitches[key] = killSwitchOn;
            if (typeof onChange === "function") {
               onChange();
            }
         });
      };
      this.featureConfigs.registerListener("KillSwitch-" + switchName, handle);
      return handle;
   };

   /**
    * kill switch can take affect in real time.
    */
   private _unbindKillSwitch = (switchName, handle) => {
      this.featureConfigs.unregisterListener("KillSwitch-" + switchName, handle);
   };

   /**
    * kill switch can take affect in real time.
    * can close sub dialog if adding close to dialog Service.
    */
   private bindKillSwitches = () => {
      this.killSwitches = {};
      this.onMultiMonitorKillSwitch = this._bindKillSwitch("MultiMonitor", "multi_monitor");
      this.onCdrKillSwitch = this._bindKillSwitch("CDR", "folder_sharing");
   };

   /**
    * kill switch can take affect in real time.
    */
   private unbindKillSwitchse = () => {
      this._unbindKillSwitch("CDR", this.onCdrKillSwitch);
      this.onCdrKillSwitch = undefined;
      this._unbindKillSwitch("MultiMonitor", this.onMultiMonitorKillSwitch);
      this.onMultiMonitorKillSwitch = undefined;
   };

   private updateMultimonMenuVisibility = () => {
      this.isMultiMonitorSupported = this.multipleDisplayService.isSupportedPlatform() && !this._useWindowReplaceApi;
      this.multiMonitorOptionVisible = isChromeClient || this._useWindowReplaceApi;
   };

   private isSettingEditable = (id) => {
      let setting;
      if (id === "redirectSystemTray" || id === "autoForwardUSB") {
         setting = this.adminCommonSettings;
      } else if (id === "enableWebRTCRedirection" || id === "enableScreenSharing" || id === "enableHighResolution") {
         // For "enableWebRTCRedirection" and "enableScreenSharing", let per site setting overwrite common setting
         setting = this.adminCommonSettings;
         if (this.adminSettings && this.adminSettings[id] !== undefined) {
            setting = this.adminSettings;
         }
      } else {
         setting = this.adminSettings;
      }
      let ret = true;
      if (!!setting && setting[id] !== undefined) {
         if (!setting["editable"]) {
            // if that setting has been set by google admin, but google admin has not set key "editale"
            // use default behavior for that setting, which is client cannot edit that setting
            ret = false;
         } else {
            if (setting["editable"][id] === undefined || setting["editable"][id] === false) {
               // if that setting has been set by google admin, but google admin key "editale" has not that
               // setting or set false for that setting, then client cannot edit that setting
               ret = false;
            } else {
               // if google admin set "editable" for true for that setting, client can edit that setting
               ret = true;
            }
         }
      } else {
         // if google admin has not set or google admin not set that setting, client behavior is the same as without google
         // admin setting, client can set that setting.
         ret = true;
      }

      if (id === "enableFolderSharing") {
         this.folderSharingEditable = ret;
      } else if (id === "autoForwardUSB") {
         this.isAutoUSBEditable = ret;
      } else if (id === "titanAutoSignOut") {
         if (!!this.adminCommonSettings && this.adminCommonSettings.hasOwnProperty("forcePrivateMode")) {
            ret = !this.adminCommonSettings["forcePrivateMode"];
         } else {
            ret = true;
         }
      } else if (id === "enableBCR") {
         ret = this.googleCommonSettings.isBrowserRedirEnabled();
      }
      return ret;
   };

   private isSettingGrayOut = (id) => {
      if (id === "timezoneSync") {
         // specific way to deal with "set time zone automaticaaly" column in setting diaog
         if (!this.isSettingEditable(id)) {
            if (this.adminSettings[id].isSync === true) {
               return true;
            } else {
               if (this.timezone.timeZoneMap.hasOwnProperty(this.adminSettings[id].timezone)) {
                  return true;
               } else {
                  return false;
               }
            }
         } else {
            return false;
         }
      } else if (id === "mp4") {
         return this._willUseMultiMonitor() || this.enableAdvancedCodec;
      } else if (id === "advancedCodec") {
         return this._willUseMultiMonitor();
      } else {
         return !this.isSettingEditable(id);
      }
   };

   private isToggleGrayOut = (id) => {
      if ((id === "timezoneSync" || id === "enableFolderSharing") && !this.isSettingEditable(id)) {
         return true;
      } else {
         return false;
      }
   };

   private _getTogglePosById = (id) => {
      if (!id || !this.toggleSwitches) {
         Logger.error("The id is invalid, should be ann item in setting list");
         return -1;
      }

      for (let i = 0; i < this.toggleSwitches.length; i++) {
         if (this.toggleSwitches[i].id === id) {
            Logger.info("Find " + id + " in setting list.");
            return i;
         }
      }
      Logger.error("Didn't find " + id + " in setting list.");
      return -1;
   };

   private updateElementForLog = (value, type) => {
      setTimeout(() => {
         if (type === "path") {
            if (value) {
               this.logPath = value;
               this.logPathInfo = this.translate._T("collect_log_m1") + value + this.translate._T("collect_log_m2");
            } else {
               this.logPath = "";
               this.logPathInfo = "";
            }
         } else if (type === "loglevel") {
            const logLevelButtons: any = document.getElementsByName("log-level");
            if (value >= 0 && value <= 3) {
               for (let i = 0; i < logLevelButtons.length; i++) {
                  switch (logLevelButtons[i].value) {
                     case "info":
                        logLevelButtons[i].checked = value === Logger.LEVEL_INFO;
                        break;
                     case "debug":
                        logLevelButtons[i].checked = value === Logger.LEVEL_DEBUG;
                        break;
                     case "trace":
                        logLevelButtons[i].checked = value === Logger.LEVEL_TRACE;
                        break;
                  }
               }
            }
         }
      });
   };

   private updateCollectLogStatus = () => {
      if (isChromeClient) {
         this.isCollectLogSupport = true;
         const result = this.localStorageService.get("isCollectLogEnabled") === "true";
         const path = this.localStorageService.get("chromeClientLogPath");
         setTimeout(() => {
            const togglePos = this._getTogglePosById("collect_log");
            this.isCollectLogEnabled = result;
            this.toggleSwitches[togglePos].isEnabled = result;
            if (path) {
               this.updateElementForLog(path, "path");
            }
         }, 10);
      }
   };

   private updateLogLevel = () => {
      if (isChromeClient) {
         let logLevel: number;
         if (this.localStorageService.get("logLevel") === null) {
            logLevel = Logger.LEVEL_INFO;
         } else {
            logLevel = Number(this.localStorageService.get("logLevel"));
         }
         setTimeout(() => {
            this.updateElementForLog(logLevel, "loglevel");
            Logger.setLogLevel(logLevel);
            this.viewClientModel.logLevel = logLevel;
         }, 10);
      }
   };

   /**
    * same logic exist in the setting-window-controller.js
    * @return {boolean}
    */
   private supportDPISync = () => {
      if (isChromeClient) {
         /**
          * Always Show High Resolution option for Chrome Client instead of checking DPI of primary monitor
          * or current running monitor, to give consistent user experience to user for both Single and multimon monitor mode.
          *
          * The implementation is not identical to Android client, but similar to Window's client to always show the option.(ARC++ always not show this option)
          */
         return true;
      }
      return (
         window.devicePixelRatio &&
         window.devicePixelRatio > 1 &&
         window.navigator.userAgent.toLowerCase().indexOf("iphone") === -1
      );
   };

   private windowsKeySimulator = () => {
      if (this.viewClientModel.osModel.mIsMacOS) {
         return this.translate._T("cmd_key");
      } else if (this.viewClientModel.osModel.mIsChromeOS) {
         return this.translate._T("Search");
      } else {
         return this.translate._T("win_key");
      }
   };

   private _willUseMultiMonitor = () => {
      if (this.displaySetting === undefined) {
         this.displaySetting = this.clientSettingModel.getPrefNumberItem("displaySetting");
      }
      const returnStatus =
         this.displaySetting === DISPLAY.DisplayOption.ALL || this.displaySetting === DISPLAY.DisplayOption.SELECTED;
      if (this.isMP4Enabled && isChromeClient && returnStatus && this.controlMP4Supported()) {
         const togglePos = this._getTogglePosById("mp4");
         this.toggleSwitches[togglePos].toggle();
         this.toggleSwitches[togglePos].isEnabled = this.isMP4Enabled;
      }

      return returnStatus;
   };

   private setUp = () => {
      // OSX key mappings setting is available only on Mac OS.
      const areMacOSXKeyMappingsSupported = this.clientSettingModel.getKeyMapSupBooleanItem(
         "areMacOSXKeyMappingsSupported"
      );
      const isWinKeySupported = this.clientSettingModel.getKeyMapSupBooleanItem("isWinKeySupported");
      const isWinDeleteKeySupported = this.clientSettingModel.getKeyMapSupBooleanItem("isWinDeleteKeySupported");
      const isMP4Supported = this.isMP4Supported();

      this.adminSettings = this.clientSettingModel.getAdminSettings();
      this.adminCommonSettings = this.connectionServerModel.googleCommonAdminSettings;
      this.bindKillSwitches();
      this.enableHighResolution = this.clientSettingModel.getBooleanItem("enableHighResolution");
      this.initialHRStatus = this.enableHighResolution;
      this.enableVideoStatsOverlay =
         this.localStorageService.get(HTML5MMR_CONST.STATS_OVERLAY_LOCAL_STORAGE_KEY) === "true";
      this.enableDebugConfiguration = this.debugConfigurationModel.isDebugConfigEnabled();
      this.areMacOSXKeyMappingsEnabled = this.clientSettingModel.getBooleanItem("useMacOSXKeyMappings");
      // Windows key setting.
      this.isWindowsKeyEnabled = this.clientSettingModel.getBooleanItem("enableWindowsKey");
      this.isWindowsDeleteKeyEnabled = this.clientSettingModel.getBooleanItem("enableWindowsDeleteKey");
      // if it's webclient, enable key: Ctrl + Shift + Alt/Option + Arrow up to change focus from remote desktop to sidebar first
      this.isCtrlShiftAltUpKeyEnabled = this.clientSettingModel.getBooleanItem("enableCtrlShiftAltUpKey");
      // MP4 setting.
      this.isMP4Enabled = this.clientSettingModel.getBooleanItem("enableMP4");
      this.isFolderSharingEnabled = this.clientSettingModel.getBooleanItem("enableFolderSharing");
      this.isFileAssociationEnabled = this.clientSettingModel.getBooleanItem("enableFileAssociation");
      this.isMultiMonitorEnabled = this.clientSettingModel.getBooleanItem("enableMultiMonitor");
      this.isFitToViewerEnabled = this.clientSettingModel.getBooleanItem("enableFitToViewer");
      this.isSystemTrayEnabled = this.clientSettingModel.getBooleanItem("redirectSystemTray");
      if (clientUtil.isTitanClient()) {
         this.isTitanSingleLogoutEnabled = this.clientSettingModel.getBooleanItem("titanSingleLogout");
         this.isTitanAutoResumptionEnabled = this.clientSettingModel.getBooleanItem("titanAutoResumption");
         this.isTitanAutoSignOut = this.clientSettingModel.getBooleanItem("titanAutoSignOut");
      }
      this.updateMultimonMenuVisibility();
      this.updateCollectLogStatus();
      try {
         //To verify if localStorage is supported
         localStorage.setItem("test", "test");
         localStorage.removeItem("test");
         this.isUagWithIdp = localStorage.getItem("isUagWithIdp") === "true";
      } catch (exception) {
         Logger.debug("LocalStorage is not supported");
      }

      this.isAutoTimezoneEnabled = this.clientSettingModel.getStringItem("timezoneSync") === "";

      this.isCollectLogEnabledinStorage = false;
      this.isCustomizedTimezoneChanged = false;
      this.isGeolocationShared = this.clientSettingModel.getBooleanItem("enableGeolocationSharing");
      this.donotShowGeolocationDialog = this.clientSettingModel.getBooleanItem("donotShowGeolocationDialog");
      this.isWebRTCRedirectionEnabled = this.clientSettingModel.getBooleanItem("enableWebRTCRedirection");
      this.isScreenShareEnabled = this.clientSettingModel.getBooleanItem("enableScreenSharing");

      if (!isChromeClient && this.viewClientModel.mIsVrDevice) {
         this.isVRModeEnabled = this.clientSettingModel.getBooleanItem("enableVRMode");
      }
      if (isChromeClient) {
         this.isBCREnabled = this.googleCommonSettings.isBrowserRedirEnabled()
            ? this.clientSettingModel.getBooleanItem("enableBCR")
            : false;
         this.isMediaStreamPermissionGranted = this.clientSettingModel.getBooleanItem("enableMediaStreamPermission");
         this.donotShowMediaStreamPermissionDialog = this.clientSettingModel.getBooleanItem(
            "donotShowMediaStreamPermissionDialog"
         );
         this.isNumEnabled = this.clientSettingModel.getBooleanItem("showNumOnTopbar");
         this.isAutoUSBEnabled = this.autoForwardUSBService.getKillSwitch();
      }
      this.disableNetworkStateDisplay = this.preDataSetModel.settingData["disableNetworkStateDisplay"];
      this.enableAdvancedCodec =
         this.featureConfigs.getConfig("KillSwitch-WasmBlast") &&
         this.clientSettingModel.getBooleanItem("enableAdvancedCodec");
      this.toggleSwitches = [
         {
            id: "debugConfiguration",
            label: this.translate._T("DEBUG_CONFIG_M"),
            text: "",
            isSupported: false,
            isEnabled: this.enableDebugConfiguration,
            toggle: this.toggleDebugConfiguration
         },
         {
            id: "overlayStats",
            label: this.translate._T(clientUtil.isChromeClient() ? "STATS_OVERLAY_M" : "MS_TEAMS_STATS_OVERLAY_M"),
            text: "",
            isSupported: false,
            isEnabled: this.enableVideoStatsOverlay,
            toggle: this.toggleVideoStatsOverlay
         },
         {
            id: "dpi_sync",
            label: this.translate._T("DPI_SYNC_M"),
            text: "",
            isSupported: this.supportDPISync(),
            isEnabled: this.enableHighResolution,
            toggle: this.toggleDPISync,
            isWaitingStatusChanged: false
         },
         {
            id: "osxKeys",
            label: this.translate._T("use_osx_key_mappings"),
            text: "",
            isSupported: areMacOSXKeyMappingsSupported,
            isEnabled: this.areMacOSXKeyMappingsEnabled,
            toggle: this.toggleUseMacOSXKeyMappings
         },
         {
            id: "winKey",
            label: this.translate._T("enable_windows_key_t"),
            text: this.translate._T("enable_windows_key_m", this.windowsKeySimulator()),
            isSupported: isWinKeySupported,
            isEnabled: this.isWindowsKeyEnabled,
            toggle: this.toggleWindowsKey
         },
         {
            id: "winDeleteKey",
            label: this.translate._T("enable_windows_delete_key_t"),
            text: "",
            isSupported: isWinDeleteKeySupported,
            isEnabled: this.isWindowsDeleteKeyEnabled,
            toggle: this.toggleWindowsDeleteKey
         },
         {
            id: "ctrlShiftAltUpKey",
            label: this.translate._T("enable_ctrl_shift_alt_up_key_t"),
            text: this.translate._T("enable_ctrl_shift_alt_up_key_m"),
            isSupported: !isChromeClient,
            isEnabled: this.isCtrlShiftAltUpKeyEnabled,
            toggle: this.toggleCtrlShiftAltUpKey
         },
         {
            id: "advancedCodec",
            label: this.translate._T("ADVANCED_LABEL"),
            text: this.translate._T("ADVANCED_CODEC_TITLE"),
            isSupported: this.featureConfigs.getConfig("KillSwitch-WasmBlast"),
            isEnabled: this.enableAdvancedCodec,
            toggle: this.toggleAdvancedCodec
         },
         {
            id: "mp4",
            label: this.translate._T("allow_mp4_key_t"),
            text: this.translate._T("ONLY_VALID_WHEN_SINGLE"),
            isSupported: isMP4Supported && this.controlMP4Supported(),
            isEnabled: this.isMP4Enabled,
            toggle: this.toggleMP4
         },
         {
            id: "geolocationPermission",
            label: this.translate._T("GEOPERMISSION_MENU"),
            text: this.translate._T("GEOPERMISSION_SUB_MENU"),
            isSupported: true,
            isEnabled: this.isGeolocationShared,
            toggle: this.toggleGeolocationShared
         },
         {
            id: "donotShowGeolocationDialog",
            label: this.translate._T("GEOPERMISSION_DO_NOT_SHOW_DIALOG"),
            text: "",
            isSupported: true,
            isEnabled: this.donotShowGeolocationDialog,
            toggle: this.toggleDonotShowGeolocationDialog
         },
         {
            id: "enableBCR",
            label: this.translate._T("BROWSER_REDIRECTION"),
            text: "",
            isSupported: isChromeClient,
            isEnabled: this.isBCREnabled,
            toggle: this.toggleEnableBCR
         },
         {
            id: "enableMediaStreamPermission",
            label: this.translate._T("MEDIASTREAM_MENU"),
            text: this.translate._T("MEDIASTREAM_SUB_MENU"),
            isSupported: isChromeClient && this.googleCommonSettings.isBCRMediaStreamEnabled(),
            isEnabled: this.isMediaStreamPermissionGranted,
            toggle: this.toggleMediaStreamShared
         },
         {
            id: "donotShowMediaStreamPermissionDialog",
            label: this.translate._T("MEDIASTREAM_DO_NOT_SHOW_DIALOG"),
            text: "",
            isSupported: isChromeClient && this.googleCommonSettings.isBCRMediaStreamEnabled(),
            isEnabled: this.donotShowMediaStreamPermissionDialog,
            toggle: this.toggleDonotShowMediaStreamDialog
         },
         {
            id: "enableWebRTCRedirection",
            label: this.translate._T(clientUtil.isChromeClient() ? "ENABLE_WEBRTC_REDIRECTION_M" : "ENABLE_MS_TEAMS_M"),
            text: this.translate._T(
               clientUtil.isChromeClient() ? "ENABLE_WEBRTC_REDIRECTION_LABEL_M" : "ENABLE_MS_TEAMS_LABEL_M"
            ),
            isSupported: true,
            isEnabled: this.isWebRTCRedirectionEnabled,
            toggle: this.toggleEnableWebRTCRedirection
         },
         {
            id: "enableScreenSharing",
            label: this.translate._T(
               clientUtil.isChromeClient() ? "ENABLE_SCREEN_SHARE_M" : "ENABLE_MS_TEAMS_SCREEN_SHARE_M"
            ),
            text: this.translate._T(
               clientUtil.isChromeClient() ? "ENABLE_SCREEN_SHARE_LABEL_M" : "ENABLE_MS_TEAMS_SCREEN_SHARE_LABEL_M"
            ),
            isSupported: this.isWebRTCRedirectionEnabled,
            isEnabled: this.isScreenShareEnabled,
            toggle: this.toggleEnableScreenShare
         },
         {
            id: "system_tray",
            label: this.translate._T("SYSTEMTRAY_T"),
            text: "",
            isSupported: isChromeClient && !this.ws1Service.isWS1Mode(),
            isEnabled: this.isSystemTrayEnabled,
            toggle: this.toggleSystemTray
         },
         {
            id: "fit-to-viewer",
            label: this.translate._T("FITTOVIEWER_T"),
            text: "",
            isSupported: !isChromeClient && !this.isLauncher,
            isEnabled: this.isFitToViewerEnabled,
            toggle: this.toggleFitToViewer
         },
         {
            id: "enableFolderSharing",
            label: this.translate._T("ENABLE_FOLDER_SHARING"),
            text: "",
            //@ts-ignore
            isSupported: !!window.showDirectoryPicker,
            isEnabled: this.isFolderSharingEnabled,
            toggle: this.toggleFolderSharing
         },
         {
            id: "file_association",
            label: this.translate._T("ENABLE_FILE_ASSOCIATION"),
            text: "",
            isSupported: isChromeClient,
            isEnabled: this.isFileAssociationEnabled,
            toggle: this.toggleFileAssociation
         },
         {
            id: "timezone",
            label: this.translate._T("allow_timezone_key_t"),
            text: "",
            isSupported: !this.mIsTouchDevice,
            isEnabled: this.isAutoTimezoneEnabled,
            toggle: this.toggleTimezone
         },
         {
            id: "collect_log",
            label: this.translate._T("collect_log_t"),
            text: "",
            isSupported: this.isCollectLogSupport,
            isEnabled: this.isCollectLogEnabled,
            toggle: this.toggleCollectLog
         },
         {
            id: "vr_mode",
            label: this.translate._T("vr_mode_t"),
            text: "",
            isSupported: this.viewClientModel.mIsVrDevice && !isChromeClient,
            isEnabled: this.isVRModeEnabled,
            toggle: this.toggleVRMode
         },
         {
            id: "num_mode",
            label: this.translate._T("num_mode_t"),
            text: "",
            isSupported: isChromeClient,
            isEnabled: this.isNumEnabled,
            toggle: this.toggleNumButton
         },
         {
            id: "auto_usb",
            label: this.translate._T("auto_usb_t"),
            text: "",
            isSupported: isChromeClient,
            isEnabled: this.isAutoUSBEnabled,
            toggle: this.toggleAutoUSB
         },
         {
            id: "network_state",
            label: this.translate._T("disable_network_state_display"),
            text: "",
            isSupported: this.networkStateService.getOptions().enableNetworkIndicator,
            isEnabled: this.disableNetworkStateDisplay,
            toggle: this.toggleNetworkStatsDisplay
         }
      ];

      // use PresentationApi for multimon
      if (!this._useWindowReplaceApi) {
         this.toggleSwitches.unshift({
            id: "multi_monitor",
            label: this.translate._T("USE_MULTI_MONITOR"),
            text: "",
            isSupported: this.isMultiMonitorSupported,
            isEnabled: this.isMultiMonitorEnabled,
            toggle: this.toggleMultiMonitor
         });
      }

      if (clientUtil.isTitanClient()) {
         this.toggleSwitches.unshift({
            id: "titanAutoResumption",
            label: this.translate._T("TITAN_APP_RESUMPTION"),
            text: "",
            isSupported: clientUtil.isTitanClient() && !clientUtil.isChromeClient(),
            isEnabled: this.isTitanAutoResumptionEnabled,
            toggle: this.toggleTitanAutoResumption
         });

         this.toggleSwitches.unshift({
            id: "titanSingleLogout",
            label: this.translate._T("TITAN_SLO_T"),
            text: this.translate._T("TITAN_SLO_SUB_T"),
            isSupported: clientUtil.isTitanClient() && !clientUtil.isChromeClient(),
            isEnabled: this.isTitanSingleLogoutEnabled,
            toggle: this.toggleTitanSLO
         });

         this.toggleSwitches.unshift({
            id: "titanAutoSignOut",
            label: this.translate._T("TITAN_ASO_T"),
            text: this.translate._T("TITAN_ASO_M"),
            isSupported: clientUtil.isTitanClient() && clientUtil.isChromeClient(),
            isEnabled: this.isTitanAutoSignOut,
            toggle: this.toggleTitanASO
         });
      }
      this.eventBusService.listen(BusEvent.SettingOptionsChangedMsg.MSG_TYPE).subscribe(() => {
         let togglePos = this._getTogglePosById("overlayStats");
         this.toggleSwitches[togglePos].isSupported = true;
         togglePos = this._getTogglePosById("debugConfiguration");
         this.toggleSwitches[togglePos].isSupported = true;
      });
   };

   public controlMP4Supported = () => {
      this.adminCommonSettings = this.connectionServerModel.googleCommonAdminSettings;

      if (!!this.adminCommonSettings && this.adminCommonSettings["enableH264"] !== undefined) {
         if (!this.adminCommonSettings["editable"]) {
            return true;
         } else {
            if (
               this.adminCommonSettings["editable"]["enableH264"] === undefined ||
               this.adminCommonSettings["editable"]["enableH264"] === false
            ) {
               return false;
            }
            return true;
         }
      } else {
         return true;
      }
   };

   public isMP4Supported = (windowObject?: any) => {
      let isMediaSourceSupported = true,
         testObject = null;
      // For MP4 feature, we only support Chrome browser 45 and above.
      if (
         !clientUtil.isChromium() ||
         this.viewClientModel.mVersionChrome <= 44 ||
         clientUtil.isAndroid() ||
         this.viewClientModel.mIsSamsungTV
      ) {
         return false;
      }

      if (!windowObject) {
         windowObject = window;
      }

      try {
         windowObject.MediaSource = windowObject.MediaSource || windowObject.WebKitMediaSource;
         testObject = new windowObject.MediaSource();
         testObject = null;
      } catch (e) {
         isMediaSourceSupported = false;
      }
      return isMediaSourceSupported;
   };

   public toggleAutoUSB = () => {
      this.isAutoUSBEnabled = !this.isAutoUSBEnabled;
      //Save the changed status locally
      this.autoForwardUSBService.updateKillSwitchStatusToStorage(this.isAutoUSBEnabled);
   };

   public toggleNetworkStatsDisplay = () => {
      this.disableNetworkStateDisplay = !this.disableNetworkStateDisplay;
      this.preDataSetModel.setDataValue("disableNetworkStateDisplay", this.disableNetworkStateDisplay);
      this.wmksService.updateNetworkStateDisplay(this.disableNetworkStateDisplay);
      this.clientSettingModel.syncSettingToSession(
         "disableNetworkStateDisplay",
         this.disableNetworkStateDisplay ? "true" : "false"
      );
   };

   public showAutoUSBDialog = () => {
      this.modalDialogService.showSettingAutoUSB();
   };

   public toggleNumButton = () => {
      this.isNumEnabled = !this.isNumEnabled;
      this.clientSettingModel.updateSetting("showNumOnTopbar", this.isNumEnabled ? "true" : "false");
      //send to parameter
   };

   public toggleVRMode = () => {
      this.isVRModeEnabled = !this.isVRModeEnabled;
      this.clientSettingModel.updateSetting("enableVRMode", this.isVRModeEnabled ? "true" : "false");
      Logger.info("Change VR mode as " + this.isVRModeEnabled);
      const enable = this.translate._T("ENABLE");
      const disable = this.translate._T("DISABLE");
      this.modalDialogService.showError({
         data: {
            titleKey: "VR_WARNING_T",
            contentKey: this.translate._T("VR_WARNING_M", this.isVRModeEnabled ? enable : disable)
         }
      });
   };

   public isSkipPortalResetable = () => {
      if (isChromeClient) {
         return false;
      }
      return (
         (!this.isUagWithIdp && localStorage.getItem("skipPageOnHTML") === "true") ||
         (this.isUagWithIdp && localStorage.getItem("skipPageOnIDP") === "true")
      );
   };

   public resetSkipPortal = () => {
      if (this.isUagWithIdp && localStorage.getItem("skipPageToClient") === "html5") {
         //reset skip portal when it is UAG with IDP
         localStorage.setItem("skipPageOnIDP", "false");
         localStorage.setItem("skipPageToClient", "false");
      } else if (!this.isUagWithIdp && localStorage.getItem("skipPageOnHTML") === "true") {
         //reset skip portal when it is on normal broker
         localStorage.setItem("skipPageOnHTML", "false");
      }
   };

   private toggleCollectLog = () => {
      const togglePos = this._getTogglePosById("collect_log");
      this.isCollectLogEnabled = !this.isCollectLogEnabled;
      if (this.isCollectLogEnabled) {
         //turn on
         this.localStorageService.set("isCollectLogEnabled", "true");
         this.localLogService
            .startLogging()
            .then((path) => {
               this.localStorageService.set("chromeClientLogPath", path);
               if (path === undefined) {
                  //if cancel the select file, path will return undefined
                  this.isCollectLogEnabled = false;
                  this.localStorageService.set("isCollectLogEnabled", "false");
                  this.toggleSwitches[togglePos].isEnabled = this.isCollectLogEnabled;
               }
               this.updateElementForLog(path, "path");
            })
            .catch((e) => {
               Logger.exception(e);
               setTimeout(() => {
                  this.isCollectLogEnabled = false;
               });
            });
         this.updateLogLevel();
      } else {
         // turn off
         this.localStorageService.set("isCollectLogEnabled", "false");
         this.localStorageService.set("chromeClientLogPath", null);
         this.localStorageService.set("logLevel", Logger.LEVEL_INFO);
         this.updateElementForLog(null, "path");
         this.localLogService.endLogging();
      }
   };

   public changeLogFile = () => {
      this.localLogService.startLogging("changeFile").then((path) => {
         if (path) {
            chrome.storage.local.set({ chromeClientLogPath: path });
            this.updateElementForLog(path, "path");
         }
      });
   };

   public changeLogLevel = () => {
      const logLevelButton: any = document.getElementsByName("log-level");
      let logLevel = Logger.LEVEL_INFO;
      for (let i = 0; i < logLevelButton.length; i++) {
         if (logLevelButton[i].checked) {
            if (logLevelButton[i].value === "info") {
               logLevel = Logger.LEVEL_INFO;
            } else if (logLevelButton[i].value === "debug") {
               logLevel = Logger.LEVEL_DEBUG;
            } else if (logLevelButton[i].value === "trace") {
               logLevel = Logger.LEVEL_TRACE;
            }
            Logger.info("Change log level to " + logLevel);
            Logger.setLogLevel(logLevel);
            this.localStorageService.set("logLevel", logLevel);
            break;
         }
      }
   };

   private showDefaultTimezone = () => {
      setTimeout(() => {
         let clientTimezone = this.timezone.getWindowsTimezone(),
            timezoneInfo = this.timezone.getTimeZoneInfo(),
            timezoneValueList = [],
            timezoneOptionMap = document.getElementById("timezoneList").children;

         //timezoneOptionMap is a HTMLCollection which is not iteration in ES6
         //Transfer it to <any> to avoid typescript error
         for (const item of <any>timezoneOptionMap) {
            if (item.value) {
               timezoneValueList.push(item.value);
            }
         }
         if (this.isAutoTimezoneEnabled && timezoneValueList.indexOf(clientTimezone) === -1) {
            $("#locationFix").html("(UTC " + timezoneInfo.timezoneOffset + ") " + timezoneInfo.location);
            clientTimezone = "locationFix";
         }
         $("#timezoneList")
            .val(clientTimezone)
            .change(() => {
               this.isCustomizedTimezoneChanged = true;
            });
      });
   };

   private showAudioDelayControl = async () => {
      try {
         this.selectedDelayControlOption = await AudioPlayControl.getDelayControlOption();
      } catch (e) {
         Logger.exception(e);
         setTimeout(() => {
            this.selectedDelayControlOption = AudioDelayOption.disabled;
         });
      }
   };

   // Reset application.
   public resetApplications = () => {
      Logger.info("Reset all applications");
      this.modalDialogService.showConfirm({
         data: {
            titleKey: "RESET_APPS_T",
            contentKey: "RESET_APPS_M",
            showWarning: true
         },
         callbacks: {
            confirm: () => {
               const callback = () => {
                  setTimeout(() => {
                     this.wmksService.prepareForSessionReset();
                     if (this.hasSingleSessionAppSession) {
                        this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
                     }
                     this.xmlApiService.resetAllApplications(
                        this._resetResultCallback,
                        this.hasSingleSessionAppSession
                     );
                  });
               };
               if (clientUtil.isTitanClient()) {
                  callback();
               } else {
                  this.utilService.authenticationStatusCheck.callBackWhenUnlocked(callback);
               }
            }
         }
      });
   };

   /**
    * _resetResultCallback
    *
    * Callback to handle the response from attempting to reset all
    * apps. Brings up an error dialog if necessary.
    *
    * @param response the response from the server
    */
   private _resetResultCallback = (response) => {
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
      if (!response.success) {
         // tell the wmksService that our attempt to reset sessions
         // failed for the sessions in response.error.errorDetails
         this.htmlRemoteSessionManager.sessionResetDone(response.error.errorDetails);
         this.modalDialogService.showError({
            data: {
               title: this.translate._T("ERROR"),
               content: response.error.userMsg
            }
         });
      }
   };

   private toggleVideoStatsOverlay = () => {
      this.enableVideoStatsOverlay = !this.enableVideoStatsOverlay;
      this.localStorageService.set(
         HTML5MMR_CONST.STATS_OVERLAY_LOCAL_STORAGE_KEY,
         new Boolean(this.enableVideoStatsOverlay).toString()
      );
   };

   private toggleDebugConfiguration = () => {
      this.enableDebugConfiguration = !this.enableDebugConfiguration;
      this.debugConfigurationModel.setDebugConfigEnabled(this.enableDebugConfiguration);
   };

   private waitForResponseToToggle = (swithItem) => {
      let resolutionInited = false;
      if (swithItem) {
         swithItem.isWaitingStatusChanged = true;
         this.eventBusService.listen("resolutionInited").subscribe(() => {
            swithItem.isWaitingStatusChanged = false;
            this.changeDetector.detectChanges();
            //$scope.$apply();
            resolutionInited = true;
         });
         setTimeout(() => {
            //If the resolution changeg fails in 1s, enable the toggle
            if (!resolutionInited && swithItem.isWaitingStatusChanged) {
               swithItem.isWaitingStatusChanged = false;
               this.changeDetector.detectChanges();
               //$scope.$apply();
            }
         }, 1000);
      }
   };

   public isSwitchDisabled = (switchItem) => {
      let result = false;
      switch (switchItem.id) {
         case "multi_monitor":
            result = this.isSettingGrayOut("enableMultiMonitor");
            break;
         case "dpi_sync":
            result = this.isSettingGrayOut("enableHighResolution");
            break;
         case "winKey":
            result = this.isSettingGrayOut("enableWindowsKey");
            break;
         case "winDeleteKey":
            result = this.isSettingGrayOut("enableWindowsDeleteKey");
            break;
         case "timezone":
            result = this.isSettingGrayOut("timezoneSync");
            break;
         case "enableGeolocationSharing":
            result = this.isSettingGrayOut("enableGeolocationSharing");
            break;
         case "donotShowGeolocationDialog":
            result = this.isSettingGrayOut("donotShowGeolocationDialog");
            break;
         case "enableMediaStreamPermission":
            result = this.isSettingGrayOut("enableMediaStreamPermission");
            break;
         case "donotShowMediaStreamPermissionDialog":
            result = this.isSettingGrayOut("donotShowMediaStreamPermissionDialog");
            break;
         case "enableWebRTCRedirection":
            result = this.isSettingGrayOut("enableWebRTCRedirection");
            break;
         case "enableScreenSharing":
            result = this.isSettingGrayOut("enableScreenSharing");
            break;
         case "system_tray":
            result = this.isSettingGrayOut("redirectSystemTray");
            break;
         case "titanSingleLogout":
            result = this.isSettingGrayOut("titanSingleLogout");
            break;
         case "titanAutoResumption":
            result = this.isSettingGrayOut("titanAutoResumption");
            break;
         case "titanAutoSignOut":
            result = this.isSettingGrayOut("titanAutoSignOut");
            break;
         case "enableBCR":
            result = this.isSettingGrayOut("enableBCR");
            break;
         case "mp4":
            result = this.isSettingGrayOut("mp4");
            break;
         case "auto_usb":
            result = this.isSettingGrayOut("autoForwardUSB");
            break;
         case "advancedCodec":
            result = this.isSettingGrayOut("advancedCodec");
            break;
         default:
            break;
      }
      return result;
   };

   public isToggleDisabled = (switchItem) => {
      let result = false;
      switch (switchItem.id) {
         case "timezone":
            result = this.isToggleGrayOut("timezoneSync");
            break;
         case "enableFolderSharing":
            result = this.isToggleGrayOut("enableFolderSharing");
            break;
      }
      return result;
   };

   private toggleDPISync = () => {
      const togglePos = this._getTogglePosById("dpi_sync");
      if (togglePos === -1) {
         return;
      }
      const _width = window.screen.width,
         _height = window.screen.height,
         _dpi = window.devicePixelRatio;
      if (
         _dpi >= 2 &&
         _dpi * _width >= 3840 &&
         _dpi * _height >= 1080 &&
         clientUtil.isChromeOS() &&
         this.enableHighResolution === false
      ) {
         // This judgment is used to avoid chromebooks with 4K screens,
         // which open HR on and cause remoteApp not to work properly
         const option = {
            data: {
               titleKey: "MM_CHANGE_HIGH_RESOLUTION_T",
               contentKey: "MM_CHANGE_HIGH_RESOLUTION_E",
               showWarning: true
            },
            callbacks: {
               confirm: () => {
                  this.toggleSwitches[togglePos].isEnabled = this.enableHighResolution;
               },
               cancel: () => {
                  this.toggleSwitches[togglePos].isEnabled = this.enableHighResolution;
               }
            }
         };
         this.modalDialogService.showConfirm(option, false);
      } else if (this.isLauncher) {
         // check if any desktop is running in multimon right now
         if (this.clientSettingModel.anyDesktopInMultimonMode) {
            const option = {
               data: {
                  titleKey: "MM_CHANGE_HIGH_RESOLUTION_T",
                  contentKey: "MM_CHANGE_HIGH_RESOLUTION_M",
                  buttonLabelConfirmKey: "YES",
                  buttonLabelCancelKey: "CANCEL"
               },
               callbacks: {
                  confirm: this._toggleDPISync,
                  cancel: () => {
                     this.toggleSwitches[togglePos].isEnabled = this.enableHighResolution;
                  }
               }
            };
            this.modalDialogService.showConfirm(option, false);
         } else {
            this._toggleDPISync();
         }
      } else {
         this.wmksService.whenNotInMultimon(() => {
            this._toggleDPISync();
            this.waitForResponseToToggle(this.toggleSwitches[togglePos]);
            setTimeout(() => {
               this.toggleSwitches[togglePos].isEnabled =
                  this.clientSettingModel.getBooleanItem("enableHighResolution");
            });
         });
         setTimeout(() => {
            this.toggleSwitches[togglePos].isEnabled = this.clientSettingModel.getBooleanItem("enableHighResolution");
         });
      }
   };

   private _toggleDPISync = () => {
      if (this.isSettingGrayOut("enableHighResolution")) {
         return;
      }
      this.enableHighResolution = !this.enableHighResolution;
      this.updateAdminSettingFeatureChangedStatus("enableHighResolution");
      this.updateAdminCommonSettingFeatureChangedStatus("enableHighResolution");
      this.clientSettingModel.updateSetting("enableHighResolution", this.enableHighResolution ? "true" : "false");
   };

   private toggleMultiMonitor = () => {
      if (this.isSettingGrayOut("enableMultiMonitor")) {
         return;
      }
      this.isMultiMonitorEnabled = !this.isMultiMonitorEnabled;
      this.wmksService.toggleMultipleMonitor(this.isMultiMonitorEnabled);
      this.clientSettingModel.updateSetting("enableMultiMonitor", this.isMultiMonitorEnabled ? "true" : "false");
   };

   // Method for toggling OSX key mappings.
   private toggleUseMacOSXKeyMappings = () => {
      if (this.isLauncher) {
         this.areMacOSXKeyMappingsEnabled = !this.areMacOSXKeyMappingsEnabled;
      } else {
         this.areMacOSXKeyMappingsEnabled = this.wmksService.toggleUseMacOSXKeyMappings();
      }
      this.clientSettingModel.updateSetting(
         "useMacOSXKeyMappings",
         this.areMacOSXKeyMappingsEnabled ? "true" : "false"
      );
   };

   // Method for toggling Windows key simulation functionality.
   private toggleWindowsKey = () => {
      if (this.isSettingGrayOut("enableWindowsKey")) {
         return;
      }
      if (this.isLauncher) {
         this.isWindowsKeyEnabled = !this.isWindowsKeyEnabled;
      } else {
         this.isWindowsKeyEnabled = this.wmksService.toggleWindowsKey();
      }
      this.clientSettingModel.updateSetting("enableWindowsKey", this.isWindowsKeyEnabled ? "true" : "false");
      this.updateAdminSettingFeatureChangedStatus("enableWindowsKey");
   };

   private toggleWindowsDeleteKey = () => {
      if (this.isSettingGrayOut("enableWindowsDeleteKey")) {
         return;
      }
      if (this.isLauncher) {
         this.isWindowsDeleteKeyEnabled = !this.isWindowsDeleteKeyEnabled;
      } else {
         this.isWindowsDeleteKeyEnabled = this.wmksService.toggleWindowsDeleteKey();
      }
      this.clientSettingModel.updateSetting(
         "enableWindowsDeleteKey",
         this.isWindowsDeleteKeyEnabled ? "true" : "false"
      );
      this.updateAdminSettingFeatureChangedStatus("enableWindowsDeleteKey");
   };

   private toggleCtrlShiftAltUpKey = () => {
      this.isCtrlShiftAltUpKeyEnabled = !this.isCtrlShiftAltUpKeyEnabled;
      this.clientSettingModel.updateSetting(
         "enableCtrlShiftAltUpKey",
         this.isCtrlShiftAltUpKeyEnabled ? "true" : "false"
      );
   };

   private toggleFitToViewer = () => {
      if (!this.isLauncher) {
         this.isFitToViewerEnabled = !this.isFitToViewerEnabled;

         this.clientSettingModel.updateSetting("enableFitToViewer", this.isFitToViewerEnabled ? "true" : "false");
         this.wmksService.toggleFitToViewer();
      }
   };

   private toggleSystemTray = () => {
      if (this.isSettingGrayOut("redirectSystemTray")) {
         return;
      }
      this.isSystemTrayEnabled = !this.isSystemTrayEnabled;
      this.clientSettingModel.updateSetting("redirectSystemTray", this.isSystemTrayEnabled ? "true" : "false");
      this.updateAdminCommonSettingFeatureChangedStatus("redirectSystemTray");
      this.eventBusService.dispatch(new BusEvent.SystemTrayEnabledMsg(this.isSystemTrayEnabled));
   };

   private toggleTitanSLO = () => {
      if (this.isSettingGrayOut("titanSingleLogout")) {
         return;
      }
      this.isTitanSingleLogoutEnabled = !this.isTitanSingleLogoutEnabled;
      this.clientSettingModel.updateSetting("titanSingleLogout", this.isTitanSingleLogoutEnabled ? "true" : "false");
   };

   private toggleTitanASO = () => {
      if (this.isSettingGrayOut("titanAutoSignOut")) {
         return;
      }
      this.isTitanAutoSignOut = !this.isTitanAutoSignOut;
      this.clientSettingModel.updateSetting("titanAutoSignOut", this.isTitanAutoSignOut ? "true" : "false");
   };

   private toggleTitanAutoResumption = () => {
      if (this.isSettingGrayOut("titanAutoResumption")) {
         return;
      }
      this.isTitanAutoResumptionEnabled = !this.isTitanAutoResumptionEnabled;
      this.clientSettingModel.updateSetting(
         "titanAutoResumption",
         this.isTitanAutoResumptionEnabled ? "true" : "false"
      );
   };

   // Method for toggling folder sharing.
   private toggleFolderSharing = () => {
      this.isFolderSharingEnabled = !this.isFolderSharingEnabled;
      this.clientSettingModel.updateSetting("enableFolderSharing", this.isFolderSharingEnabled ? "true" : "false");
   };

   public toggleFileAssociation = () => {
      this.isFileAssociationEnabled = !this.isFileAssociationEnabled;
      this.clientSettingModel.updateSetting("enableFileAssociation", this.isFileAssociationEnabled ? "true" : "false");
      this._updateDisabledHostForFA();
   };

   private _updateDisabledHostForFA = () => {
      const data = this.localStorageService.get("fileAssociationDisabledHost");
      let disableHostList = [];
      if (data) {
         try {
            disableHostList = JSON.parse(data);
         } catch {
            Logger.error("Read disabledHostList from local storage fails");
         }
      }
      const index = disableHostList.indexOf(this.connectionServerModel.host);
      if (this.isFileAssociationEnabled) {
         if (index !== -1) {
            disableHostList.splice(index, 1);
         }
      } else {
         if (index === -1) {
            disableHostList.push(this.connectionServerModel.host);
         }
      }
      this.localStorageService.set("fileAssociationDisabledHost", JSON.stringify(disableHostList));
   };

   // Method for toggling MP4 functionality.
   private toggleMP4 = () => {
      if (this.isLauncher) {
         this._toggleMP4();
      } else {
         const togglePos = this._getTogglePosById("mp4");
         if (togglePos === -1) {
            return;
         }
         this.wmksService.whenNotInMultimon(() => {
            this._toggleMP4();
            setTimeout(() => {
               this.toggleSwitches[togglePos].isEnabled = this.isMP4Enabled;
            });
         });
         setTimeout(() => {
            this.toggleSwitches[togglePos].isEnabled = this.isMP4Enabled;
         });
      }
   };

   private _toggleMP4 = () => {
      if (this.isLauncher) {
         this.isMP4Enabled = !this.isMP4Enabled;
      } else {
         this.isMP4Enabled = this.wmksService.toggleMP4();
      }
      this.clientSettingModel.updateSetting("enableMP4", this.isMP4Enabled ? "true" : "false");
   };

   // Method for set timezone automatically functionality.
   private toggleTimezone = () => {
      if (this.isSettingGrayOut("timezoneSync")) {
         return;
      }
      this.isAutoTimezoneEnabled = !this.isAutoTimezoneEnabled;
      if (this.isAutoTimezoneEnabled) {
         // is enable save , need a parameter.
         this.clientSettingModel.updateSetting("timezoneSync", "");
      } else {
         this.isCustomizedTimezoneChanged = true;
      }
      this.showDefaultTimezone();
      this.updateAdminSettingFeatureChangedStatus("timezoneSync");
   };

   private toggleEnableWebRTCRedirection = () => {
      this.isWebRTCRedirectionEnabled = !this.isWebRTCRedirectionEnabled;
      this.clientSettingModel.updateSetting(
         "enableWebRTCRedirection",
         this.isWebRTCRedirectionEnabled ? "true" : "false"
      );
      const togglePos = this._getTogglePosById("enableScreenSharing");
      if (togglePos >= 0 && !!this.toggleSwitches[togglePos]) {
         this.toggleSwitches[togglePos].isSupported = this.isWebRTCRedirectionEnabled;
      }
      this.updateAdminSettingFeatureChangedStatus("enableWebRTCRedirection");
      this.updateAdminCommonSettingFeatureChangedStatus("enableWebRTCRedirection");
      this.clientSettingModel.saveSetting();
   };

   private toggleEnableScreenShare = () => {
      this.isScreenShareEnabled = !this.isScreenShareEnabled;
      this.clientSettingModel.updateSetting("enableScreenSharing", this.isScreenShareEnabled ? "true" : "false");
      this.updateAdminSettingFeatureChangedStatus("enableScreenSharing");
      this.updateAdminCommonSettingFeatureChangedStatus("enableScreenSharing");
      this.clientSettingModel.saveSetting();
   };

   private toggleGeolocationShared = () => {
      this.isGeolocationShared = !this.isGeolocationShared;
      this.clientSettingModel.updateSetting("enableGeolocationSharing", this.isGeolocationShared ? "true" : "false");
      this.clientSettingModel.saveSetting();
   };

   private toggleDonotShowGeolocationDialog = () => {
      this.donotShowGeolocationDialog = !this.donotShowGeolocationDialog;
      this.clientSettingModel.updateSetting(
         "donotShowGeolocationDialog",
         this.donotShowGeolocationDialog ? "true" : "false"
      );
      this.clientSettingModel.saveSetting();
   };

   private toggleMediaStreamShared = () => {
      this.isMediaStreamPermissionGranted = !this.isMediaStreamPermissionGranted;
      this.clientSettingModel.updateSetting(
         "enableMediaStreamPermission",
         this.isMediaStreamPermissionGranted ? "true" : "false"
      );
      this.clientSettingModel.saveSetting();
   };

   private toggleDonotShowMediaStreamDialog = () => {
      this.donotShowMediaStreamPermissionDialog = !this.donotShowMediaStreamPermissionDialog;
      this.clientSettingModel.updateSetting(
         "donotShowMediaStreamPermissionDialog",
         this.donotShowMediaStreamPermissionDialog ? "true" : "false"
      );
      this.clientSettingModel.saveSetting();
   };

   private toggleEnableBCR = () => {
      this.isBCREnabled = !this.isBCREnabled;
      this.clientSettingModel.updateSetting("enableBCR", this.isBCREnabled ? "true" : "false");
      this.clientSettingModel.saveSetting();
   };

   public onKeypress = (evt) => {
      if (!!evt && evt.keyCode === 32) {
         // Space key is pressed.
         if (evt.target.children && evt.target.children[0]) {
            $(evt.target.children[0]).trigger("click");
         }
         // Prevent default behavior.
         evt.preventDefault();
      }
   };

   public showCDRDialog = () => {
      this.modalDialogService.showSettingCDR();
   };

   public showDebugConfigurationDialog = () => {
      this.modalDialogService.showDebugConfiguration();
   };

   public openSettingsMultiLaunchConfig = () => {
      this.modalDialogService.showSettingMultiLaunch();
   };

   public openSettingsMultiMonitorConfig = () => {
      this.modalDialogService.showSettingMultiMonitor();
   };

   public openSettingsRtavConfig = () => {
      this.modalDialogService.showSettingRtav();
   };

   public openKeyboardSetting = () => {
      this.modalDialogService.showKeyboardSetting();
   };

   public openSessionManagementCenter = () => {
      const screenWidth = screen.availWidth;
      const screenHeight = screen.availHeight;
      const width = 800;
      const height = 600;
      chrome.app.window.create(
         "./webclient/session-management-center.html",
         {
            id: "sessionManagementCenter",
            outerBounds: {
               width: width,
               height: height,
               left: Math.round((screenWidth - width) / 2),
               top: Math.round((screenHeight - height) / 2)
            }
         },
         (app) => {
            if (app) {
               app.contentWindow.isKioskSession = window.isKioskSession;
               if (app.contentWindow.isKioskSession) {
                  Logger.debug("Close setting panel when session management center opened in kiosk session.");
                  this.modalDialogService.closeDialogByType("setting-window");
               }
            } else {
               console.log("failed to create window with error " + chrome.runtime.lastError);
            }
         }
      );
   };

   public isResetButtonDisabled = () => {
      return this.resetButtonEnabled === false || this.isWaiting === true;
   };

   public delayControlOptionChanged = (newValue) => {
      Logger.debug("audio delay changed to" + newValue + " from " + this.selectedDelayControlOption);
      AudioPlayControl.setDelayControlOption(newValue);
   };

   public closeSettingModal = () => {
      // defaultHRStuts： Used to determine whether the changed result is the same as the initial value.
      if (this.initialHRStatus !== this.enableHighResolution) {
         this.wmksService.toggleHighResMode();
         this.initialHRStatus = this.enableHighResolution;
      }
      this.closeModal();
   };

   private getFeatureChangedValue = (featureName: string): string => {
      let changeFeature: string = "";
      switch (featureName) {
         case "enableWindowsKey":
            changeFeature = "isWinKeyChanged";
            break;
         case "timezoneSync":
            changeFeature = "isTimezoneChanged";
            break;
         case "enableScreenSharing":
            changeFeature = "isScreenSharingChanged";
            break;
         case "enableFolderSharing":
            changeFeature = "isFolderSharingChanged";
            break;
         case "enableWebRTCRedirection":
            changeFeature = "isWebRTCRedirectionChanged";
            break;
         case "enableHighResolution":
            changeFeature = "isDPISyncChanged";
            break;
         case "enableWindowsDeleteKey":
            changeFeature = "isWinDelKeyChanged";
            break;
         case "redirectSystemTray":
            changeFeature = "isRedirSysTrayChanged";
            break;
         default:
            Logger.info(featureName + "is not in supported editable list");
      }
      return changeFeature;
   };

   private updateAdminCommonSettingFeatureChangedStatus = (featureName: string) => {
      const changeFeature: string = this.getFeatureChangedValue(featureName);
      if (
         !!this.adminCommonSettings &&
         this.adminCommonSettings.hasOwnProperty(featureName) &&
         this.adminCommonSettings.hasOwnProperty("editable") &&
         !!this.adminCommonSettings["editable"][featureName] &&
         this.adminCommonSettings["editable"][featureName] === true &&
         !this.userGlobalPref.getPrefBooleanItem(changeFeature)
      ) {
         this.clientSettingModel.updateSetting(changeFeature, "true");
         Logger.info("update changed status for" + featureName + " in admin common setting");
      } else {
         Logger.info(featureName + " is not editable in admin common setting");
      }
   };

   private updateAdminSettingFeatureChangedStatus = (featureName: string) => {
      const changeFeature: string = this.getFeatureChangedValue(featureName);
      if (
         !!this.adminSettings &&
         this.adminSettings.hasOwnProperty(featureName) &&
         this.adminSettings.hasOwnProperty("editable") &&
         !!this.adminSettings["editable"][featureName] &&
         this.adminSettings["editable"][featureName] === true &&
         !this.userGlobalPref.getPrefBooleanItem(changeFeature)
      ) {
         this.clientSettingModel.updateSetting(changeFeature, "true");
         Logger.info("update changed status for" + featureName + " in admin setting");
      } else {
         Logger.info(featureName + " is not editable in admin setting");
      }
   };

   private toggleAdvancedCodec = () => {
      this.enableAdvancedCodec = !this.enableAdvancedCodec;
      this.clientSettingModel.updateSetting("enableAdvancedCodec", this.enableAdvancedCodec ? "true" : "false");
      if (clientUtil.isChromeClient()) {
         this.modalDialogService.showError({
            data: {
               titleKey: "RESTART_SESSION_T",
               contentKey: "RESTART_SESSION_M",
               buttonLabelConfirmKey: "OK"
            }
         });
      } else if (this.htmlRemoteSessionManager.getAllSessions().size) {
         this.modalDialogService.showError({
            data: {
               titleKey: "REFRESH_PAGE_T",
               contentKey: "REFRESH_PAGE_M",
               buttonLabelConfirmKey: "OK"
            }
         });
      }
   };
}
