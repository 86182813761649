/*********************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 *********************************************************
 *
 * @format
 */

import { Localize } from "../../common/service/localize.service";
import { Component, ElementRef, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { clientUtil, TranslateService } from "@html-core";

@Component({
   selector: "download-link",
   template: '<a class="home-link" id="downloadLink" href="" target="_blank" rel="{{linkRelValue}}"></a>'
})
export class DownloadLinkComponent implements AfterViewInit {
   public linkRelValue: string;
   public nativeInstallerLink: string;
   private element;
   constructor(
      private translate: TranslateService,
      private viewClientModel: ViewClientModel,
      private localize: Localize,
      private _el: ElementRef,
      private changeDetector: ChangeDetectorRef
   ) {}

   ngAfterViewInit() {
      this.element = $(this._el.nativeElement.children[0]);
      this.localize.localeReady$.subscribe((ready) => {
         setTimeout(() => {
            if (ready) {
               this.showLink();
            }
         });
      });

      this.linkRelValue = __REL_FOR_LINK__;
      this.changeDetector.detectChanges();
   }

   private showLink = () => {
      if (clientUtil.isChromeClient()) {
         return;
      }
      //Rebrand the installer URL
      const name = this.translate._T(this.viewClientModel.osLiteral);
      this.element.html(name);
      this.element.attr("href", this.viewClientModel.nativeInstallerLink);
      // For unit testing
      this.nativeInstallerLink = this.viewClientModel.nativeInstallerLink;
   };
}
