/**
 * ******************************************************
 * Copyright (C) 2016-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";
import { ExtendedMonitorFactory } from "./extended-monitor";
import { PrimaryMonitorService } from "./primary-monitor.service";

/**
 * multimon-model.js -- multimonModel
 *
 * module to hold all multimon related informations, mainly for sync them before
 * working
 */

@Injectable({
   providedIn: "root"
})
export class MultimonModel {
   private idCounter: number = 0;
   public monitors = {};
   public primaryMonitor = null;
   public monitorCount = 0;
   public monitorSettings = new Map<string, DisplayBaseInfo>();
   public usingMultimon: boolean = false;
   public wmksSession = null;
   public sessionContainer = null;
   public baseX: number = 0;
   public baseY: number = 0;

   constructor(
      private extendedMonitorFactory: ExtendedMonitorFactory,
      private primaryMonitorService: PrimaryMonitorService
   ) {}

   public init = (wmksSession, sessionContainer) => {
      this.primaryMonitor = this.primaryMonitorService;
      this.idCounter = 1;
      this.monitors = {};
      this.monitors[0] = this.primaryMonitor;
      this.monitorCount = 1;
      this.usingMultimon = false;
      this.wmksSession = wmksSession;
      this.sessionContainer = sessionContainer;
   };

   public clear = () => {
      this.idCounter = 0;
      this.monitors = {};
      this.monitorCount = 0;
      this.monitorSettings.clear();
      this.usingMultimon = false;
      this.wmksSession = null;
      this.sessionContainer = null;
   };

   public addMonitor = (onStatusChanged, onRegionUpdated, screenSetting) => {
      const id = this.idCounter.toString();
      const extendedMonitor = this.extendedMonitorFactory.createExtendedMonitor();

      extendedMonitor.init(id, this.monitorSettings, onStatusChanged, onRegionUpdated, screenSetting);

      this.monitors[id] = extendedMonitor;
      this.monitorCount++;
      this.idCounter++;
   };

   /**
    * Set the baseX and baseY based on the current settings, which are both
    * defined as -min(.) corresponding to the agent buffer settings.
    *
    * p.s. the agent buffer setting will not contains the positive offset
    * also, so change max(0, -min(.)) to -min(.), and default as 0
    */
   public updateSettings = () => {
      let minX = NaN,
         minY = NaN;

      this.forEachSettings((key, setting) => {
         if (isNaN(minX) && isNaN(minY)) {
            minX = setting.x;
            minY = setting.y;
         } else {
            minX = Math.min(minX, setting.x);
            minY = Math.min(minY, setting.y);
         }
      });
      if (isNaN(minX)) {
         minX = 0;
      }
      if (isNaN(minY)) {
         minY = 0;
      }
      this.baseX = -minX;
      this.baseY = -minY;
   };

   /**
    * forEachSettings
    * @param  {function} processFunction The function will be called for
    *    each key setting pair
    */
   public forEachSettings = (processFunction) => {
      for (const [key, val] of this.monitorSettings) {
         if (this.monitorSettings.has(key) && typeof val === "object") {
            processFunction(key, val);
         }
      }
   };

   public forEachExtenalMonitor = (callback) => {
      for (const key in this.monitors) {
         if (this.monitors.hasOwnProperty(key) && key !== "0") {
            Logger.trace("for external monitor " + key);
            callback(key, this.monitors[key]);
         }
      }
   };
}
