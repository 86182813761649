/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * disconnectSessionCommand.js --
 *
 * logoff session.
 *
 */

import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import DisconnectSessionHandler from "../controllers/doDisconnectSessionHandler";
import GetLaunchItemsHandler from "../controllers/getLaunchItemsHandler";

export default function DisconnectSessionCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param disconnectSessionAction[in]
 *        - its property 'method' has the value 'DisconnectSession'
 */
DisconnectSessionCommand.prototype.execute = function (disconnectSessionAction) {
   let handlerList,
      launchItemId,
      disconnectSessionObject,
      getLaunchItemsObject,
      supportedProtocols,
      supportedAppProtocols,
      type,
      router = globalArray["router"];

   launchItemId = disconnectSessionAction.launchItemId;

   // Get DisconnectSessionHandler
   disconnectSessionObject = globalArray["disconnect-session" + launchItemId];
   if (!disconnectSessionObject) {
      disconnectSessionObject = new DisconnectSessionHandler();
      globalArray[disconnectSessionObject.messageName + launchItemId] = disconnectSessionObject;
      globalArray[disconnectSessionObject.responseTag + launchItemId] = disconnectSessionObject;
   } else {
      disconnectSessionObject.resetData();
   }
   // The targeted session ID.
   disconnectSessionObject.launchItemId = launchItemId;

   getLaunchItemsObject = globalArray["get-launch-items" + launchItemId];
   if (!getLaunchItemsObject) {
      getLaunchItemsObject = new GetLaunchItemsHandler(true, true);
      globalArray[getLaunchItemsObject.messageName + launchItemId] = getLaunchItemsObject;
      globalArray[getLaunchItemsObject.responseTag + launchItemId] = getLaunchItemsObject;
   } else {
      getLaunchItemsObject.resetData();
   }

   getLaunchItemsObject.appendSubHandler(disconnectSessionObject);

   if (!!getLaunchItemsObject && !!router) {
      supportedProtocols = globalArray.supportedProtocols || ["BLAST"];
      type = {};
      type.name = "remote";
      type.protocols = supportedProtocols;
      supportedAppProtocols = [];
      supportedAppProtocols[0] = type;
      if (supportedProtocols) {
         getLaunchItemsObject.setRequestXML(supportedProtocols, supportedAppProtocols, false);
         handlerList = getLaunchItemsObject.composeHandlerList();
         router.postMessage(handlerList);
      } else {
         Logger.error("supported protocols is invalid.");
      }
   }
};
