/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { IMAGE_ATTRIBUTES, RebrandingItemTO, REBRAND_IMAGE_MAP } from "./Rebrand";
import { BRAND_URL_TYPE, TenantProperty } from "./TenantProperty";
import { environment } from "../environments/environment";
import { PathService } from "./titan-path.interface";

@Injectable({
   providedIn: "root"
})
export class MultiTenantService {
   private brandURL: string = "";
   private rebrandEnabled: boolean = false;
   private tenantDomain: string = "";
   private preLoginMessage: string = "";
   private brandType: BRAND_URL_TYPE = BRAND_URL_TYPE.UNKNOWN;
   private globalDomain: string = "";
   private tenantProperty: TenantProperty = {} as TenantProperty;
   private rebrandItems: RebrandingItemTO[] = [];

   constructor(private pathService: PathService) {
      this.init();
   }

   public init = () => {
      //@ts-ignore
      const tenantString: string = window.tenantProperty;
      try {
         if (tenantString) {
            const jsonString = atob(tenantString);
            this.tenantProperty = JSON.parse(jsonString);
            if (this.tenantProperty.brandURL) {
               this.brandURL = this.tenantProperty.brandURL;
            }
            if (this.tenantProperty.idpTenantDomain) {
               this.tenantDomain = this.tenantProperty.idpTenantDomain;
            }
            if (!this.tenantProperty.rebrandInfo || this.tenantProperty.rebrandInfo.length !== 0) {
               this.rebrandEnabled = true;
               this.rebrandItems = this.tenantProperty.rebrandInfo;
            }
            if (this.tenantProperty.preLoginMessage) {
               this.preLoginMessage = decodeURIComponent(escape(this.tenantProperty.preLoginMessage));
            }
            if (this.tenantProperty.brandType) {
               this.brandType = this.setBrandURLType(this.tenantProperty.brandType);
            }
            if (this.tenantProperty.globalDomain) {
               this.globalDomain = this.tenantProperty.globalDomain;
            }
         }
      } catch (e) {
         console.log("fail to parse window.rebrand with error" + JSON.stringify(e));
      }
   };

   public setBrandURLType = (type: string): BRAND_URL_TYPE => {
      if (type === "CUSTOM_DEFINED_URL") {
         return BRAND_URL_TYPE.CUSTOM_DEFINED_URL;
      } else if (type === "VANITY_URL") {
         return BRAND_URL_TYPE.VANITY_URL;
      }
      return BRAND_URL_TYPE.UNKNOWN;
   };

   public getBrandURLType = (): BRAND_URL_TYPE => {
      return this.brandType;
   };

   public getGlobalDomain = (): string => {
      return this.globalDomain;
   };

   public getBrandURL = (): string => {
      return this.brandURL;
   };

   public getIDPDomain = (): string => {
      return this.tenantDomain;
   };

   public getPreLoginMsg = (): string => {
      return this.preLoginMessage;
   };

   public getOIDCRedirectUrl = (): string => {
      if (this.brandType === BRAND_URL_TYPE.VANITY_URL) {
         return environment.vanityRedirectUri.replace(this.brandURL + ".", "");
      } else if (this.brandType === BRAND_URL_TYPE.CUSTOM_DEFINED_URL) {
         return this.generateRedirectUrl();
      } else {
         return this.pathService.commonRedirectUri;
      }
   };

   public getOIDCPostLogoutUrl = (): string => {
      if (this.brandType === BRAND_URL_TYPE.VANITY_URL) {
         return environment.vanityPostLogoutUri.replace(this.brandURL + ".", "");
      } else if (this.brandType === BRAND_URL_TYPE.CUSTOM_DEFINED_URL) {
         return this.generatePostLogoutUrl();
      }
      return this.pathService.getPostLogoutUrl();
   };

   public getRebrandImage = (key: IMAGE_ATTRIBUTES): string => {
      const imagePath = this.findFromRebrandItem(key);
      if (!imagePath) {
         const defaultPath = REBRAND_IMAGE_MAP.get(key);
         if (defaultPath) {
            return defaultPath;
         }
      }
      return imagePath;
   };

   private findFromRebrandItem = (key: string): string => {
      if (!this.rebrandItems || this.rebrandItems.length === 0) {
         return "";
      }
      for (const item of this.rebrandItems) {
         if (item.attribute === key) {
            return item.value;
         }
      }
      return "";
   };

   private generateRedirectUrl = () => {
      return "https://" + this.globalDomain + "/appblast/oauth/callback";
   };

   generatePostLogoutUrl = () => {
      return "https://" + this.globalDomain + "/appblast/oauth/post_logout";
   };
}
