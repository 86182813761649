/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { HorizonUsbDevice, TransferType } from "./horizon-usb-device";

export class WebUsbDevice extends HorizonUsbDevice {
   private raw: USBDevice;

   constructor(device: USBDevice, id: number) {
      super();
      this.raw = device;
      this.productId = device.productId;
      this.vendorId = device.vendorId;
      this.deviceId = id;
   }

   //@Override
   public open = (): Promise<any> => {
      return this.raw.open();
   };

   //@Override
   public close = (): Promise<any> => {
      return this.raw.close();
   };

   //@Override
   public selectConfiguration = (configurationValue: number): Promise<any> => {
      return this.raw.selectConfiguration(configurationValue);
   };

   //@Override
   public claimInterface = (interfaceNumber: number): Promise<any> => {
      return this.raw.claimInterface(interfaceNumber);
   };

   //@Override
   public releaseInterface = (interfaceNumber: number): Promise<any> => {
      return this.raw.releaseInterface(interfaceNumber);
   };

   //@Override
   public selectAlternateInterface = (interfaceNumber: number, alternateSetting: number): Promise<any> => {
      return this.raw.selectAlternateInterface(interfaceNumber, alternateSetting);
   };

   //@Override
   public controlTransferIn = (setup: USBControlTransferParameters, length: number): Promise<USBInTransferResult> => {
      return this.raw.controlTransferIn(setup, length);
   };

   //@Override
   public controlTransferOut = (
      setup: USBControlTransferParameters,
      data?: BufferSource
   ): Promise<USBOutTransferResult> => {
      return this.raw.controlTransferOut(setup, data);
   };

   //@Override
   public transferIn = (type: TransferType, endpointNumber: number, length: number): Promise<USBInTransferResult> => {
      return this.raw.transferIn(endpointNumber, length);
   };

   //@Override
   public transferOut = (
      type: TransferType,
      endpointNumber: number,
      data: BufferSource
   ): Promise<USBOutTransferResult> => {
      return this.raw.transferOut(endpointNumber, data);
   };

   //@Override
   public reset = (): Promise<any> => {
      return this.raw.reset();
   };

   //@Override
   public getConfiguration = (): Promise<USBConfiguration> => {
      return new Promise((resolve) => {
         if (this.raw.configuration) {
            resolve(this.raw.configuration);
         } else {
            resolve(this.raw.configurations[0]);
         }
      });
   };

   //@Override
   public getRaw = (): any => {
      return this.raw;
   };

   //@Override
   public getProductName = (): string => {
      return this.raw.productName;
   };

   //@Override
   public getSerialNumber = (): string => {
      return this.raw.serialNumber;
   };

   //@Override
   public getManufacturerName = (): string => {
      return this.raw.manufacturerName;
   };
}
