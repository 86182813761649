/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export interface RebrandingItemTO {
   attribute: string;
   value: string;
   type: string;
}

export enum IMAGE_ATTRIBUTES {
   horizonLogoImage = "horizonLogoImage"
}

export const REBRAND_IMAGE_MAP: Map<string, string> = new Map<string, string>([
   [IMAGE_ATTRIBUTES.horizonLogoImage, ""]
]);
