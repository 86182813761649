/**
 * ******************************************************
 * Copyright (C) 2012-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * rollbackCheckoutDesktopHandler.js --
 *
 *      Implementation of the message handler to rollback desktop which is
 * checked out
 */

import $ from "jquery";
import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import GetDesktopsHandler from "./getDesktopsHandler";
import GetLaunchItemsHandler from "./getLaunchItemsHandler";

export default function RollbackCheckoutDesktopHandler() {
   let router;

   // member variables below
   this.messageName = "rollback-checkout-desktop";
   this.messageText = "rollback-checkout-desktop";
   this.responseTag = "rollback-checkout-desktop";
   /**
    * requestId will increase when a new instance is created
    * use prototype requestId to share in all instances
    */
   RollbackCheckoutDesktopHandler.prototype.requestId += 1;

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
}

// inherits MessageHandler prototype
RollbackCheckoutDesktopHandler.prototype = new MessageHandler();
// constructor
RollbackCheckoutDesktopHandler.constructor = RollbackCheckoutDesktopHandler;

/**
 * parse information from the response XML of rollbackCheckoutDesktop
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
RollbackCheckoutDesktopHandler.prototype.parseResult = function (responseXML) {
   let rollbackCheckoutDesktopResponse = {},
      xmlDoc = $(responseXML),
      brokerTag,
      responses,
      result;

   brokerTag = $(xmlDoc.children()[0]);
   if (!brokerTag) {
      Logger.error("response of rollbackCheckoutDesktopHandler error");
      return null;
   }

   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   util.addItemForJson(rollbackCheckoutDesktopResponse, "result", result);
   return rollbackCheckoutDesktopResponse;
};

/**
 * Set the request XML for rollbackCheckoutDesktop
 * @param desktopId[in] desktop id to undo a previous checkout
 *
 */
RollbackCheckoutDesktopHandler.prototype.setRequestXML = function (desktopId) {
   let desktopIdElem;
   desktopIdElem = util.createElement("desktop-id", desktopId);
   this.requestXML = desktopIdElem;
};

RollbackCheckoutDesktopHandler.prototype.onUpdated = function () {
   const router = util.getObject(globalArray, "router");
   let logoutFromBrokerAction;
   let getDesktopsObject;
   let getLaunchItemsObject;
   let supportedProtocols;
   let supportedAppProtocols;
   let handlerList;
   let type;

   MessageHandler.prototype.onUpdated.apply(this);

   if (this.state === StateEnum.DONE) {
      if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
         Logger.debug("rollbackCheckoutDesktop result is OK.");
         if (!util.brokerSupportApplication()) {
            //send getDesktops request again and refresh desktop list in UI
            getDesktopsObject = globalArray["get-desktops"];
            if (!getDesktopsObject) {
               getDesktopsObject = new GetDesktopsHandler();
               globalArray[getDesktopsObject.messageName] = getDesktopsObject;
               globalArray[getDesktopsObject.responseTag] = getDesktopsObject;
            } else {
               getDesktopsObject.resetData();
            }

            if (!!getDesktopsObject && !!router) {
               supportedProtocols = globalArray.supportedProtocols;
               if (supportedProtocols) {
                  getDesktopsObject.setRequestXML(supportedProtocols);
                  handlerList = getDesktopsObject.composeHandlerList();
                  router.postMessage(handlerList);
               } else {
                  Logger.error("supported protocols is invalid.");
               }
            }
         } else {
            getLaunchItemsObject = globalArray["get-launch-items"];
            if (!getLaunchItemsObject) {
               getLaunchItemsObject = new GetLaunchItemsHandler();
               globalArray[getLaunchItemsObject.messageName] = getLaunchItemsObject;
               globalArray[getLaunchItemsObject.responseTag] = getLaunchItemsObject;
            } else {
               getLaunchItemsObject.resetData();
            }

            if (!!getLaunchItemsObject && !!router) {
               supportedProtocols = globalArray.supportedProtocols;
               type = {};
               type.name = "remote";
               type.protocols = supportedProtocols;
               supportedAppProtocols = [];
               supportedAppProtocols[0] = type;
               if (supportedProtocols) {
                  getLaunchItemsObject.setRequestXML(supportedProtocols, supportedAppProtocols);
                  handlerList = getLaunchItemsObject.composeHandlerList();
                  router.postMessage(handlerList);
               } else {
                  Logger.error("supported protocols is invalid.");
               }
            }
         }
      } else {
         Logger.error("rollbackCheckoutDesktop failed.");
      }
   }

   Router.prototype.pushErrorToUser(this);
};
