/**
 * ******************************************************
 * Copyright (C) 2012-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * jscdkClient.js --
 *
 *      Interfaces between UI and JSCDK.
 */

import $ from "jquery";
import { CryptoKeyDerivationService } from "../common/service/crypto-key-derivation.service";
import jscdkFeatureStatus from "./model/jscdk-feature-status";
import util from "./util";

export const globalArray = {
   // set 6.0 as the API version of supporting setClientInfo
   clientInfoAPIVersion: "6.0",

   // set 9.0 as the API version of supporting getLaunchItems and idle timeout
   applicationAPIVersion: "9.0",
   applicationParamVersion: "12.0",
   azureReconnectAPIVersion: "11.0",
   /* set >=6.0  to support clientInfo,
    *     >=7.0  to support SAML,
    *     >=9.0  to further support applications and idle timeout,
    *     >=10.0 to support disconnect session,
    *     >=11.0 to support azure reconnection, since Azure XML version is 11.0
    *     >=12.0 to support RDSH application launching params,
    *     >=13.0 to support restart desktop,
    *     >=15.0 to support on ramp,
    * this value will be rewrite according to response of XML
    */
   brokerVersion: "15.0",
   // default Protocol to connect to remote session
   supportedProtocols: ["BLAST"],
   // whether broker is set to ws1 mode
   ws1Mode: undefined,
   workspaceOneServerHostname: "",
   csrfToken: undefined,
   titanMode: undefined
};

/**
 * Set the UI controller to JSCDK library to let UI response to JSCDK's
 * events.
 *
 * @param controller [in] the main UI controller.
 */
export function jscdkClientSetUIController(controller) {
   // if (!globalArray["uiController"]) {
   globalArray["uiController"] = controller;
   //    Logger.debug("UI Controller is set to JSCDK.");
   // } else {
   //    Logger.warning("UI Controller has already been set.");
   // }
}

/**
 * Interface from UI to JSCDK.
 *
 * @param action [in] a JSON string describes the action content.
 */
export function UIEventToJSCDK(action) {
   const actionArray = $.parseJSON(action);
   window["JSCDKClientController"].getInstance().execute(actionArray);
}

/**
 * Interface from JSCDK to UI.
 *
 * @param action [in] a JSON string describes the action content.
 */
export function JSCDKSetUI(action) {
   const mainUIController = util.getObject(globalArray, "uiController");
   if (mainUIController) {
      mainUIController.responseToJSCDKEvent(action);
   }
}

export function jscdkClientSetCertFunction(showCertFunction) {
   globalArray["show-cert-function"] = showCertFunction;
}

/**
 * Set JSCDK features
 * @param {object} status
 */
export function jscdkClientSetFeatureStatus(status) {
   if (!status) {
      return;
   }
   jscdkFeatureStatus.set(status);
}

export function jscdkClientSetStoreFunction(readFunction, writeFunction, removeFunction) {
   globalArray["read-function"] = readFunction;
   globalArray["write-function"] = writeFunction;
   globalArray["remove-function"] = removeFunction;
}

/**
 * Set CryptoKeyDerivationService to globalArray
 * @param cryptoKeyDerivationService
 */
export function jscdkClientSetCryptoKeyDerivationService(cryptoKeyDerivationService: CryptoKeyDerivationService) {
   globalArray["cryptoKeyDerivationService"] = cryptoKeyDerivationService;
}
