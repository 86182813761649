/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * GetOnRampConfigHandler.js --
 *
 *      Implementation of the message handler to Get On Ramp Config.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";

export default function GetOnRampConfigHandler() {
   let router;

   // member variables below
   this.messageName = "get-on-ramp-config";
   this.messageText = "get-on-ramp-config";
   this.responseTag = "on-ramp-config";
   this.composedHandlerList = [];

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
}

// inherits MessageHandler prototype
GetOnRampConfigHandler.prototype = new MessageHandler();
// constructor
GetOnRampConfigHandler.constructor = GetOnRampConfigHandler;

/**
 * parse information from the response XML of GetOnRampConfig
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
GetOnRampConfigHandler.prototype.parseResult = function (responseXML) {
   let brokerTag;
   let responses;
   let result;
   const GetOnRampConfigResponse = {};
   let xmlDoc;

   xmlDoc = $(responseXML);
   brokerTag = $(xmlDoc.children()[0]);
   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(GetOnRampConfigResponse, nameText, $(this).text());
      });
      const rampConfig = responses.children("on-ramp-config");
      rampConfig.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(GetOnRampConfigResponse, nameText, $(this).text());
      });
   }
   return GetOnRampConfigResponse;
};

/**
 * Set the request XML for get-on-ramp-config
 *
 */
GetOnRampConfigHandler.prototype.setRequestXML = function () {
   this.requestXML = util.createElement("access-token");
};

/**
 * callback when received notification from handlers in dependency list or
 * router
 *
 */
GetOnRampConfigHandler.prototype.onUpdated = function () {
   let GetOnRampConfigAction;

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's

   if (this.state === StateEnum.DONE) {
      if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
         Logger.debug("Get on ramp config succeeds.");
         const url = this.content["parsedResult"]["url"],
            accessToken = this.content["parsedResult"]["access-token"];

         GetOnRampConfigAction = {
            name: "GetOnRampConfig",
            content: {
               url: url,
               accessToken: accessToken
            }
         };
         JSCDKSetUI(JSON.stringify(GetOnRampConfigAction));
      }
   } else {
      Logger.debug("Get on ramp config failed.");
   }

   // push error if it exists
   Router.prototype.pushErrorToUser(this);
};
