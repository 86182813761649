/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * warning-message.service.ts
 *
 * Service for warning message of Restriction setting
 */
import { Injectable } from "@angular/core";
import { TranslateService } from "@html-core";
import Logger from "../../../core/libs/logger";
import { EventBusService } from "../../../core/services/event";
import { ModalDialogService } from "../../../shared/common/commondialog/dialog.service";

@Injectable({
   providedIn: "root"
})
export class WarningMessageService {
   private _inited = false;
   private _allowVersionWarningDisplay: boolean = false;

   constructor(
      private eventBusService: EventBusService,
      private modelDialog: ModalDialogService,
      private translate: TranslateService
   ) {}
   public init = () => {
      if (this._inited) {
         return;
      }
      this._enableWarningMsg();
      this.eventBusService.listen("logOutSuccess").subscribe(() => {
         this._enableWarningMsg();
      });

      this.eventBusService.listen("sessionExpired").subscribe(() => {
         this._enableWarningMsg();
      });

      this.eventBusService.listen("GetLaunchItems").subscribe((msg) => {
         if (msg.data.chrome && msg.data.warning) {
            this._showWarningMsg(msg.data.warning);
         }
      });
      this._inited = true;
   };

   private _enableWarningMsg = () => {
      this._allowVersionWarningDisplay = true;
   };

   private disableWarningMsg = () => {
      this._allowVersionWarningDisplay = false;
   };

   private _showWarningMsg = (warningMsg) => {
      if (this._allowVersionWarningDisplay) {
         this.modelDialog.showError({
            data: {
               title: this.translate._T("WARNING"),
               content: warningMsg
            },
            callbacks: {
               confirm: () => {
                  this.eventBusService.dispatch({
                     type: "warningMessageDismissed"
                  });
               }
            }
         });
         this.disableWarningMsg();
      }
   };
}
