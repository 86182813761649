/** @format */

import { Injectable } from "@angular/core";
import { Logger, clientUtil, getUuid } from "@html-core";
import { AudioCapture, AudioCaptureFactory } from "./audio-capture";
import {
   AudioDevice,
   DefaultMicStrategy,
   DeviceInfo,
   VideoDevice,
   audioDeviceCommunicationsRole,
   audioDeviceDefaultRole,
   audioDeviceMultimediaRole,
   audioDeviceRoles,
   defaultMicStrategy
} from "./device-manager.model";
import { VideoCapture, VideoCaptureFactory } from "./video-capture";

@Injectable({ providedIn: "root" })
export class DeviceEnumeratorService {
   private readonly audioInfoMap: Map<string, DeviceInfo<AudioDevice>> = new Map(); // key: deviceId
   private readonly videoInfoMap: Map<string, DeviceInfo<VideoDevice>> = new Map();
   private readonly audioOutMap: Map<string, MediaDeviceInfo> = new Map();
   private readonly audioDeviceMap: Map<number, AudioCapture> = new Map(); // key: deviceIndex
   private readonly videoDeviceMap: Map<number, VideoCapture> = new Map();
   private deviceIndex = 2; // 0 and 1 are reserved

   constructor(
      private readonly audioCaptureFactory: AudioCaptureFactory,
      private readonly videoCaptureFactory: VideoCaptureFactory
   ) {
      this.updateDeviceList();
   }

   public getAudioDevice(index: number) {
      const device = this.audioDeviceMap.get(index);
      if (!device) {
         throw new Error("DeviceEnumerator cannot find device with the specified index");
      }
      return device;
   }

   public getVideoDevice(index: number) {
      const device = this.videoDeviceMap.get(index);
      if (!device) {
         throw new Error("DeviceEnumerator cannot find device with the specified index");
      }
      return device;
   }

   public getAllAudioDevices(except: string[] = []) {
      return Array.from(this.audioDeviceMap.values()).filter((device) => !except.includes(device.getId()));
   }

   public getAllVideoDevices(except: string[] = []) {
      return Array.from(this.videoDeviceMap.values()).filter((device) => !except.includes(device.getId()));
   }

   public getAudioInfoList() {
      return Array.from(this.audioInfoMap.values());
   }

   public getVideoInfoList() {
      return Array.from(this.videoInfoMap.values());
   }

   public getAudioOutInfoList() {
      if (clientUtil.isChromium()) {
         // remove devices that has prefix
         return Array.from(this.audioOutMap.values()).filter((device) => {
            for (const role of audioDeviceRoles) {
               if (device.label.startsWith(role.prefix)) {
                  return false;
               }
            }
            return true;
         });
      } else {
         return [];
      }
   }

   public getDefaultAudioInfo() {
      const filtered = Array.from(this.audioInfoMap.values()).filter((device) => {
         return (device.roles & audioDeviceDefaultRole.role) > 0;
      });
      if (filtered.length) {
         return filtered[0];
      } else {
         return null;
      }
   }

   public getDefaultAudioOutInfo() {
      if (clientUtil.isChromium()) {
         const filtered = Array.from(this.audioOutMap.values())
            .filter((device) => {
               return device.deviceId === audioDeviceDefaultRole.id;
            })
            // remove the prefix from the label
            .map((device) => {
               if (device.label.startsWith(audioDeviceDefaultRole.prefix)) {
                  return { ...device, label: device.label.slice(audioDeviceDefaultRole.prefix.length) };
               } else {
                  return device;
               }
            });
         if (filtered.length) {
            return filtered[0];
         } else {
            return null;
         }
      } else {
         return null;
      }
   }

   public isAudioActive() {
      for (const audioCapture of this.audioDeviceMap.values()) {
         if (audioCapture && audioCapture.isActive()) {
            return true;
         }
      }
      return false;
   }

   public isVideoActive() {
      for (const videoCapture of this.videoDeviceMap.values()) {
         if (videoCapture && videoCapture.isActive()) {
            return true;
         }
      }
      return false;
   }

   public async updateDeviceList() {
      Logger.info("DeviceEnumeratorService updateDeviceList", Logger.RTAV);
      try {
         const devices = await navigator.mediaDevices.enumerateDevices();
         // this set records currently available devices, so we can remove redundant devices from two maps
         const videoDeviceSet = new Set<string>(); // device.deviceId
         // because audio devices also need to be assigned with a role, we use a map here for both purposes:
         // 1. keep track of the role; 2. keep track of current available devices
         const audioDeviceRoleMap = new Map<string, number>(); // key: device.label
         // bug fix: the deviceId is different on chrome client even in the same session, so we also need to maintain an id set for audio
         const audioDeviceSet = new Set<string>(); // key: device.deviceId
         const guidGenerator = new GuidGenerator();
         // for audio out, simply reset the map
         this.audioOutMap.clear();
         // if the default microphone strategy is "sameAsBrowser", we will use the first microphone as the default device
         let isFirstAudio = true;

         for (const device of devices) {
            if (device.label === "") {
               // no permission
               continue;
            }
            if (device.kind === "audioinput") {
               audioDeviceSet.add(device.deviceId);
               const [role, labelNoPrefix] = this.getRoleAndLabel(device.deviceId, device.label, isFirstAudio);
               isFirstAudio = false;
               audioDeviceRoleMap.set(labelNoPrefix, (audioDeviceRoleMap.get(labelNoPrefix) ?? 0) + role);
               /* if the device id is a role created by Chrome (default, communications, multimedia)
               only set the role to the map without creating a device info
               this makes sure that there's only one device info for each physical device */
               let idIsARole = false;
               for (const role of audioDeviceRoles) {
                  if (device.deviceId === role.id) {
                     idIsARole = true;
                     break;
                  }
               }
               if (!idIsARole && !this.audioInfoMap.has(device.deviceId)) {
                  const deviceIndex = this.genDeviceIndex();
                  const [guid, labelNoCollision] = guidGenerator.genGuid(labelNoPrefix, "a", deviceIndex);
                  if (labelNoCollision !== labelNoPrefix) {
                     // collision happened, add the resolved name to the map
                     // set the role to 0 because if the collided name is A
                     // only one of the two A can have a role, and that role should have already been set
                     audioDeviceRoleMap.set(labelNoCollision, 0);
                  }
                  // normal device without any role
                  this.audioInfoMap.set(device.deviceId, {
                     uniqueId: `{${guid}}`,
                     friendlyName: labelNoCollision,
                     deviceIndex: deviceIndex,
                     roles: role,
                     deviceId: device.deviceId
                  });
               }
            }
            if (device.kind === "videoinput") {
               videoDeviceSet.add(device.deviceId);
               if (!this.videoInfoMap.has(device.deviceId)) {
                  const deviceIndex = this.genDeviceIndex();
                  const [guid, labelNoCollision] = guidGenerator.genGuid(device.label, "v", deviceIndex);
                  this.videoInfoMap.set(device.deviceId, {
                     uniqueId: `{${guid}}`,
                     friendlyName: labelNoCollision,
                     deviceIndex: deviceIndex,
                     deviceId: device.deviceId
                     // resolution is set in DeviceManagerService
                     // use the same resolution got from admin policy
                     // this can save us from turning camera on and off just for getting the resolution
                  });
               }
            }
            if (device.kind === "audiooutput") {
               this.audioOutMap.set(device.deviceId, device);
            }
         }
         // maybe split below to a separate method to improve the performance
         this.videoInfoMap.forEach((value, key, map) => {
            // clear unplugged devices
            if (!videoDeviceSet.has(key)) {
               const videoCapture = this.videoDeviceMap.get(value.deviceIndex);
               videoCapture?.stop();
               map.delete(key);
            } else {
               // initialize new devices
               if (!this.videoDeviceMap.has(value.deviceIndex)) {
                  this.videoDeviceMap.set(value.deviceIndex, this.videoCaptureFactory.newVideoCapture(key));
               }
            }
         });
         let hasCommunicationRole = false;
         let hasMultimediaRole = false;
         let defaultDevice = null;
         this.audioInfoMap.forEach((value, key, map) => {
            // clear unplugged devices and update new audio device roles
            if (!audioDeviceRoleMap.has(value.friendlyName) || !audioDeviceSet.has(key)) {
               const audioCapture = this.audioDeviceMap.get(value.deviceIndex);
               audioCapture?.stop();
               map.delete(key);
            } else {
               value.roles = audioDeviceRoleMap.get(value.friendlyName);
               // initialize new devices
               if (!this.audioDeviceMap.has(value.deviceIndex)) {
                  this.audioDeviceMap.set(value.deviceIndex, this.audioCaptureFactory.newAudioCapture(key));
               }
               // check the default device, the default communication device, and the default multimedia device
               if ((value.roles & audioDeviceDefaultRole.role) > 0) {
                  defaultDevice = value;
               }
               if ((value.roles & audioDeviceCommunicationsRole.role) > 0) {
                  hasCommunicationRole = true;
               }
               if ((value.roles & audioDeviceMultimediaRole.role) > 0) {
                  hasMultimediaRole = true;
               }
            }
         });
         // if there is no default communication device or default multimedia device, set the default device as these as well
         if (defaultDevice && !hasCommunicationRole) {
            defaultDevice.roles |= audioDeviceCommunicationsRole.role;
         }
         if (defaultDevice && !hasMultimediaRole) {
            defaultDevice.roles |= audioDeviceMultimediaRole.role;
         }
      } catch (err) {
         Logger.error("DeviceEnumertor update device listt error: " + err, Logger.RTAV);
      }
   }

   // getRoleAndLabel returns the role number and the sliced label
   private getRoleAndLabel(id: string, label: string, isFirstAudio: boolean): [number, string] {
      if (isFirstAudio && !clientUtil.isChromium()) {
         // for Firefox and Safari, set the first audio device as the default device, the default communications device, and the default multimedia device
         return [
            audioDeviceDefaultRole.role | audioDeviceCommunicationsRole.role | audioDeviceMultimediaRole.role,
            label
         ];
      }
      // if the default microphone strategy is "sameAsBrowser"
      // we will use the first microphone as both the default device, the default communications device, and the default multimedia device
      if (defaultMicStrategy === DefaultMicStrategy.sameAsBrowser) {
         if (isFirstAudio) {
            for (const role of audioDeviceRoles) {
               if (label.startsWith(role.prefix)) {
                  label = label.slice(role.prefix.length);
               }
            }
            return [
               audioDeviceDefaultRole.role | audioDeviceCommunicationsRole.role | audioDeviceMultimediaRole.role,
               label
            ];
         }
         for (const role of audioDeviceRoles) {
            // ignore the roles of the OS
            if (id === role.id) {
               return [0, label.slice(role.prefix.length)];
            }
         }
      }
      // @ts-ignore
      if (defaultMicStrategy === DefaultMicStrategy.sameAsOs) {
         for (const role of audioDeviceRoles) {
            if (id === role.id) {
               return [role.role, label.slice(role.prefix.length)];
            }
         }
      }
      return [0, label];
   }

   private genDeviceIndex() {
      this.deviceIndex++;
      return this.deviceIndex;
   }
}

export class GuidGenerator {
   private guidSet = new Set<string>();

   // genGuid is a synchronous method, which might have a higher possibility of collision compared with the async one
   // the deviceIndex is only used when collision happens
   public genGuid(label: string, deviceType: "a" | "v" | "", deviceIndex: number): [string, string] {
      // bug fix: some devices will create a microphone and a camera with the same name, so the deviceType is used to distinguish them
      const restructuredArr: string[] = [];
      if (deviceType !== "") {
         restructuredArr.push(deviceType);
      }
      // reconstruct the label to reduce the collision rate
      for (let i = 0, j = label.length - 1; i <= j; i++, j--) {
         if (label[i] !== " ") restructuredArr.push(label[i]);
         if (label[j] !== " ") restructuredArr.push(label[j]);
      }
      const restructured = restructuredArr.join("");
      const hexDigits = "0123456789abcdef";

      let rIndex = 0;

      function nextHexPos() {
         if (rIndex >= restructured.length) rIndex = 0;
         const nextChar = restructured[rIndex++];
         return nextChar.charCodeAt(0) % hexDigits.length;
      }

      const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
         const r = nextHexPos();
         const v = c === "x" ? r : (r & 0x3) | 0x8;
         return v.toString(16);
      });

      if (this.guidSet.has(guid)) {
         // label collision happened, use device index as a suffix
         if (deviceIndex !== 0) {
            return this.genGuid(label + deviceIndex.toString(), "", 0);
         }
         // nothing works, return a random guid
         const randomGuid = getUuid();
         this.guidSet.add(randomGuid);
         return [randomGuid, label];
      }
      this.guidSet.add(guid);

      return [guid, label];
   }
}
