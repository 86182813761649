/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * brokerSessionTimeoutTimerController.js --
 *
 * timer and logic for broker session timeout.
 *
 */

import { globalArray, JSCDKSetUI, UIEventToJSCDK } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { Timer, timerTypeEnum } from "./timer";
import { PushErrorToUI } from "../jscdk-interface";
import JSCDKBrokerError from "../model/jscdk-broker-error.consts";
import { LogoutAction } from "../../common/jscdk/jscdk-interface";

export default function BrokerSessionTimeoutTimerController() {
   Logger.debug("BrokerSessionTimeoutTimerController created");
   this.globalName = "broker-session-timeout-timer";
   this.sessionTimeout = -1;
   this.timeoutTimer = new Timer(timerTypeEnum.timeout);
   this.STORAGE_KEY = "SESSION-TIMER";
}

// only store functions
BrokerSessionTimeoutTimerController.prototype = {};
BrokerSessionTimeoutTimerController.constructor = BrokerSessionTimeoutTimerController;

(function () {
   function brokerSessionTimeoutEvent() {
      const showErrorAction = {} as PushErrorToUI;

      Logger.debug("broker Session Timeout event reached");
      showErrorAction.name = "ShowError";
      showErrorAction.content = {
         errorType: JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_AUTHENTICATED,
         errorText: ""
      };
      JSCDKSetUI(JSON.stringify(showErrorAction));
      const logoutAction = {
         method: "LogoutFromBroker",
         prefData: null
      } as LogoutAction;
      UIEventToJSCDK(JSON.stringify(logoutAction));
   }

   function storeTimerInStorage() {
      const writeLocalStorage = globalArray["write-function"],
         targetTime = this.timeoutTimer.getTargetTime(); // in ms
      if (typeof writeLocalStorage === "function" && !!targetTime) {
         writeLocalStorage(this.STORAGE_KEY, targetTime); // in ms
         Logger.debug("broker session timer is stored:" + targetTime);
      }
   }

   function removeTimerFromStorage() {
      const removeLocalStorage = globalArray["remove-function"];
      if (typeof removeLocalStorage === "function") {
         removeLocalStorage(this.STORAGE_KEY);
         Logger.debug("broker session timer is removed");
      }
   }

   function initCore() {
      const sessionTimeout = this.sessionTimeout;

      if (sessionTimeout <= 0) {
         this.timeoutTimer.setDisable();
         this.sessionTimeout = -1; // -1 means invalid
      } else {
         this.timeoutTimer.setEnable();
      }
      this.timeoutTimer.init(
         brokerSessionTimeoutEvent,
         this.sessionTimeout // in ms
      );
   }

   BrokerSessionTimeoutTimerController.prototype.start = function (sessionTimeout) {
      Logger.debug("init broker session timer controller: sessionTimeout:" + sessionTimeout);
      this.sessionTimeout = sessionTimeout; // in ms
      initCore.call(this);
      this.timeoutTimer.start();
      if (!util.isChromeClient()) {
         //no need to store timer in storage for chromeclient
         storeTimerInStorage.apply(this);
      }
   };

   BrokerSessionTimeoutTimerController.prototype.stop = function () {
      this.timeoutTimer.discard();
      removeTimerFromStorage.apply(this);
      Logger.debug("----broker session timer is discarded");
   };

   BrokerSessionTimeoutTimerController.prototype.getTimerFromStorage = function () {
      let readLocalStorage = globalArray["read-function"],
         targetTime;
      if (typeof readLocalStorage === "function") {
         targetTime = readLocalStorage(this.STORAGE_KEY); // in ms
         Logger.debug("broker session timer is read out:" + targetTime);
      }
      return parseInt(targetTime, 10);
   };

   BrokerSessionTimeoutTimerController.prototype.getsessionTimeoutValue = function () {
      Logger.debug("getsessionTimeoutValue return:" + this.sessionTimeout);
      return this.sessionTimeout;
   };
})();
