/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BusEvent, EventBusService, Logger } from "@html-core";
import { ViewClientModel } from "../../../shared/common/model/viewclient-model";
import { LaunchUriService } from "../../../shared/desktop/common/launch-uri.service";
import { HtmlRemoteSessionManager } from "../remote-session/html-remote-session-manager";
import { CommonSDKService } from "../../../shared/common/service/sdk.service";

const KEY_CODES = {
   Digit1: 49,
   Digit2: 50,
   Digit3: 51,
   Digit4: 52,
   Digit5: 53,
   Digit6: 54,
   Digit7: 55,
   Digit8: 56,
   Digit9: 57,
   Digit0: 48,
   KeyA: 65,
   KeyB: 66,
   KeyC: 67,
   KeyD: 68,
   KeyE: 69,
   KeyF: 70,
   KeyG: 71,
   KeyH: 72,
   KeyI: 73,
   KeyJ: 74,
   KeyK: 75,
   KeyL: 76,
   KeyM: 77,
   KeyN: 78,
   KeyO: 79,
   KeyP: 80,
   KeyQ: 81,
   KeyR: 82,
   KeyS: 83,
   KeyT: 84,
   KeyU: 85,
   KeyV: 86,
   KeyW: 87,
   KeyX: 88,
   KeyY: 89,
   KeyZ: 90,
   Comma: 188,
   Period: 190,
   Semicolon: 186,
   Quote: 222,
   BracketLeft: 219,
   BracketRight: 221,
   Backslash: 220,
   Minus: 189,
   Equal: 187,
   IntlRo: 193,
   IntlYen: 255,
   AltLeft: 18,
   AltRight: 18,
   CapsLock: 20,
   ControlLeft: 17,
   ControlRight: 17,
   OSLeft: 91,
   OSRight: 92,
   ShiftLeft: 16,
   ShiftRight: 16,
   ContextMenu: 93,
   Enter: 13,
   Space: 32,
   Tab: 9,
   Delete: 46,
   End: 35,
   Home: 36,
   Insert: 45,
   PageDown: 34,
   PageUp: 33,
   ArrowDown: 40,
   ArrowLeft: 37,
   ArrowRight: 39,
   ArrowUp: 38,
   Escape: 27,
   PrintScreen: 44,
   ScrollLock: 145,
   Pause: 19,
   F1: 112,
   F2: 113,
   F3: 114,
   F4: 115,
   F5: 116,
   F6: 117,
   F7: 118,
   F8: 119,
   F9: 120,
   F10: 121,
   F11: 122,
   F12: 123,
   F13: 124,
   F14: 125,
   F15: 126,
   F16: 127,
   F17: 128,
   F18: 129,
   F19: 130,
   F20: 131,
   F21: 132,
   F22: 133,
   F23: 134,
   F24: 135,
   NumLock: 144,
   Numpad0: 96,
   Numpad1: 97,
   Numpad2: 98,
   Numpad3: 99,
   Numpad4: 100,
   Numpad5: 101,
   Numpad6: 102,
   Numpad7: 103,
   Numpad8: 104,
   Numpad9: 105,
   NumpadAdd: 107,
   NumpadComma: 194,
   NumpadDecimal: 110,
   NumpadDivide: 111,
   NumpadEnter: 13,
   NumpadEqual: 12,
   NumpadMultiply: 106,
   NumpadSubtract: 109
};

@Injectable({
   providedIn: "root"
})
export class ExtendedApiService {
   private logger = new Logger(Logger.SDK);
   private maximumAPIVersion = 1;
   private workingVersion = 1;
   private callbacks: ExtendedCallbacksAPI_V1;

   constructor(
      private router: Router,
      private eventBusService: EventBusService,
      private viewClientModel: ViewClientModel,
      private launchUriService: LaunchUriService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private sdkService: CommonSDKService
   ) {
      // The purpose of defining `callbacks` as a Proxy is to allow special behavior when certain hooks are set.
      //  When customers set the `onDisclaimerDisplayed` hook, a `RequestDisclaimer` message is broadcasted.
      //  If the `LoginDisclaimerComponent` has finished rendering by this time, it will listen for this message.
      this.callbacks = new Proxy(
         {},
         {
            set: (target, p, newValue) => {
               this.logger.info(`Set the ${p.toString()} hook into callbacks`);
               if (p === "onDisclaimerDisplayed") {
                  setTimeout(() => this.eventBusService.dispatch(new BusEvent.RequestDisclaimer()));
               }
               target[p] = newValue;
               return true;
            }
         }
      );
   }

   public init() {
      if (this.viewClientModel.enableExtendedAPI) {
         this.logger.info(`Set the getAPI function into window.hzClient`);
         window.hzClient.getAPI = (param) => this.getAPI(param);

         this.initWebviewMode(window.hzClient);
         this.initAuthCallbacks(window.hzClient);
      }
   }

   private getAPI(param?: { clientType?: "HTMLAccess"; desiredVersion?: number }) {
      const oldVersion = this.workingVersion;
      this.negotiateVersion(param?.desiredVersion);
      if (oldVersion !== this.workingVersion) {
         this.logger.info("The workingVersion has changed, remake the API in window.hzClient");
         this.initWebviewMode(window.hzClient);
         this.initAuthCallbacks(window.hzClient);
      }

      const api: ExtendedAPI_V1 = {
         workingVersion: this.workingVersion,
         maximumAPIVersion: this.maximumAPIVersion
      };
      this.initWebviewMode(api);
      this.initAuthCallbacks(api);
      return api;
   }

   private negotiateVersion(desiredVersion?: number) {
      if (desiredVersion === undefined || desiredVersion === null) {
         this.logger.info("No desiredVersion, workingVersion is still " + this.workingVersion);
         return;
      } else if (typeof desiredVersion !== "number" || isNaN(desiredVersion)) {
         throw this.printErr(`The value '${desiredVersion}' of desiredVersion is not of the 'number' type`);
      }

      const version = Math.min(desiredVersion, this.maximumAPIVersion);
      if (version >= 1 && Number.isInteger(version)) {
         this.workingVersion = version;
      } else {
         throw this.printErr(
            `Unsupported desiredVersion: ${desiredVersion}, it must be an integer greater than or equal to 1`
         );
      }
   }

   private initWebviewMode(target: HorizonClient | ExtendedCoreAPI_V1) {
      if (this.launchUriService.urlParams.webviewMode === "true") {
         this.logger.info(
            "Initialize webviewMode API of " + (target === window.hzClient ? "window.hzClient" : "the API object")
         );
         target.sendKeyCodes = (codes) => this.sendKeyCodes(codes);
         target.sendKeyCodesStr = (codes) => this.sendKeyCodes(codes.split(","));
         target.sendKeyEvent = (event) => this.sendKeyEvent(event);
         target.sendKeyEventArgs = (type, code, ctrlKey = false, shiftKey = false, altKey = false, metaKey = false) => {
            this.sendKeyEvent(new KeyboardEvent(type, { code, ctrlKey, shiftKey, altKey, metaKey }));
         };
         target.sendScrollMessage = (x, y, dx, dy) => this.sendScrollMessage(x, y, dx, dy);
         target.setTouchMode = (touchMode) => this.setTouchMode(touchMode);
         target.disconnect = (sessionId: string) => this.disconnect(sessionId);
      }
   }

   private checkInsideDesktopRoutingPage() {
      if (this.router.url !== "/desktop") {
         throw this.printErr("Outside #desktop routing page");
      }
   }

   private getCurrentSession() {
      const session = this.htmlRemoteSessionManager.getCurrentSession();
      if (session) {
         return session;
      } else {
         throw this.printErr("There is currently no available session");
      }
   }

   private sendKeyCodes(codes: string[]) {
      this.checkInsideDesktopRoutingPage();

      if (!Array.isArray(codes)) {
         throw this.printErr("Invalid argument codes, must be an array of string.");
      }

      if (codes.length) {
         const vScanCodes = codes.map((code) => {
            const vScanCode = WMKS.keyboardUtils.fromKeyCodeToVScanCode[code];
            if (typeof vScanCode !== "number" || isNaN(vScanCode)) {
               throw this.printErr(`Invalid keyboard code "${code}"`);
            }
            return vScanCode;
         });

         const keyCodes = codes.map((code) => {
            const keyCode = KEY_CODES[code];
            if (typeof keyCode !== "number" || isNaN(keyCode)) {
               throw this.printErr(`Invalid keyboard code "${code}"`);
            }
            return keyCode;
         });

         // The first argument of the sendKeyCodes method is the KeyboardEvent.keyCode array, it has been deprecated in wmks/widgetProto.js
         this.getCurrentSession().wmks("sendKeyCodes", keyCodes, vScanCodes, true);
      } else {
         throw this.printErr("The argument codes is empty");
      }
   }

   private sendKeyEvent(originalEvent: KeyboardEvent) {
      this.checkInsideDesktopRoutingPage();

      // check invalid type and convert the event type to the message type
      let type;
      switch (originalEvent.type) {
         case "keyup":
            type = "KeyUp";
            break;
         case "keydown":
            type = "KeyDown";
            break;
         case "keypress":
            type = "KeyPress";
            break;
         default:
            throw this.printErr("Invalid keyboard event type: " + originalEvent.type);
      }

      // The sendKeyMessage method uses jQuery events, so needs to be encapsulated with jQuery.Event.
      const event = jQuery.Event(originalEvent.type, { originalEvent });
      this.getCurrentSession().wmks("sendKeyMessage", { type, event });
   }

   private sendScrollMessage(x: number, y: number, dx: number, dy: number) {
      this.checkInsideDesktopRoutingPage();
      this.getCurrentSession().wmks("sendScrollMessage", { x, y }, dx, dy);
   }

   private setTouchMode(touchMode: string) {
      this.checkInsideDesktopRoutingPage();

      if (!this.viewClientModel.mIsTouchDevice && !this.viewClientModel.mIsVrDevice) {
         throw "Not a touch device";
      }

      if (touchMode !== "TRACKPAD_MODE" && touchMode !== "NATIVETOUCH_MODE") {
         throw "Invalid touch mode " + touchMode;
      }

      this.eventBusService.dispatch(new BusEvent.SetTouchModeMsg(touchMode === "TRACKPAD_MODE"));
   }

   private disconnect(sessionId: string) {
      this.checkInsideDesktopRoutingPage();
      const session = this.htmlRemoteSessionManager.getSessionById(sessionId);
      if (session) {
         session.requestDisconnect();
      } else {
         throw this.printErr(`The '${sessionId}' session cannot be found in running items`);
      }
   }

   private printErr(err: string) {
      this.logger.error(err);
      return err;
   }

   private initAuthCallbacks(target: HorizonClient | ExtendedCoreAPI_V1) {
      if (this.launchUriService.urlParams.authCallbacks === "true") {
         this.logger.info(
            "Initialize authCallbacks API of " + (target === window.hzClient ? "window.hzClient" : "the API object")
         );
         target.callbacks = this.callbacks;
         target.doAuth = (authInfo) => this.doAuth(authInfo);
      }
   }

   private doAuth(authInfo: { isDisclaimerAccepted?: boolean; password?: string }) {
      if (authInfo.hasOwnProperty("isDisclaimerAccepted")) {
         this.logger.info((authInfo.isDisclaimerAccepted ? "Accept" : "Decline") + " the disclaimer");
         this.sdkService.setDisclaimerAccepted(authInfo.isDisclaimerAccepted);
         this.eventBusService.dispatch(new BusEvent.DoDisclaimerSubmit());
      }
      if (authInfo.hasOwnProperty("password")) {
         this.logger.info("Login with password");
         this.sdkService.setAuthSecret(authInfo.password);
         this.eventBusService.dispatch(new BusEvent.DoAuthSubmit());
      }
   }
}
