/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * session-data.service.ts
 *
 * session data format:
 *
 * {
 *    clientConfigs: [
 *       {
 *          name: 'test1',
            value: ['123', '456']
 *       },
 *       {
 *          name: 'test2',
            value: ['123', '456']
 *       }
 *    ],
 *    brokerUrl: "https://localhost/broker/xml",
 *    runningSessions: [
 *       {
 *          itemKey: 'cn=win10_local,ou=applications,dc=vdi,dc=vmware,dc=int',
 *          url:
 * 'wss://10.112.118.9:8443/r/C9CF7AD2-857A-4504-A032-BD305AF76B17/?vauth=%2BdoT7MJmGwb5vDQWQ%2F4qKbVD',
 * name: 'test', isActive: true, isApplicationSession: false, isShadow: false,
 * reconnectToken: 'L5reSnuL8l8GgnpksecQRs1z8Q1thYEr'
 *       },
 *       {
 *          itemKey: 'cn=win10_local,ou=applications,dc=vdi,dc=vmware,dc=int',
 *          url:
 * 'wss://10.112.118.9:8443/r/C9CF7AD2-857A-4504-A032-BD305AF76B17/?vauth=%2BdoT7MJmGwb5vDQWQ%2F4qKbVD',
 * name: 'test', isActive: true, isApplicationSession: false, isShadow: true,
 * reconnectToken: 'L5reSnuL8l8GgnpksecQRs1z8Q1thYEr'
 *       }
 *    ]
 * }
 *
 * clientConfigs is very the same with the XML API:
 *    https://wiki.eng.vmware.com/VDM/ClientBrokerXml#Response_.28Windows_password_authentication_is_required.29
 *
 * Unlike connectionURIModel reflect JSCDK accepted URI params,
 * clientUtilService.findURLParam reflects the URI parameter directly,
 * and can be used for client int without race condition.
 *
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { clientUtil, LocalStorageService } from "@html-core";
import { ConnectionServerModel } from "../model/connection-server-model";
import { ViewClientModel } from "../model/viewclient-model";
import { RemoteSessionEventService } from "../remote-session/remote-session-event.service";
import { MultiSessionSingleUserSessionService } from "./multisession-singleuser-session.service";
import { EventBusService, BusEvent } from "@html-core";

const RESPONSE = {
   OK: "ok",
   FAIL: "fail",
   SESSION_CHANGE: "sessionChanged"
};
const LOCAL_STORAGE_KEY = "LOCAL_SESSION_DATA_V1";

interface SessionClientConfig {
   enableCredentialCleanupForHTMLAccess: boolean;
   clientHideServerInformation: boolean;
   clientHideDomainList: boolean;
}

@Injectable({
   providedIn: "root"
})
export class SessionDataService {
   public useLocalStorage: boolean = false;
   private sessionData = {
      clientConfigs: {} as SessionClientConfig,
      runningSessions: [],
      brokerUrl: "",
      horizonId: "",
      runningMultiSessions: []
   };

   constructor(
      private localStorageService: LocalStorageService,
      private connectionServerModel: ConnectionServerModel,
      private viewClientModel: ViewClientModel,
      private remoteSessionEventService: RemoteSessionEventService,
      private multiSessionSingleUserSessionService: MultiSessionSingleUserSessionService,
      private eventBusService: EventBusService
   ) {
      this.init();
   }

   public init = () => {
      if (clientUtil.isTitanClient()) {
         /*
          * Use this as a workaround for HV-65325.
          *
          * We shouldn’t store connection information in localStorage.
          * However, we have made a compromise here to support app session reuse across multiple HTTP sessions.
          * The reason for doing so is that the data model used by Titan’s inventory service traces user sessions using the clientId,
          * and thus cannot differentiate between different HTTP sessions of the same user in different time domains.
          *
          * This becomes a blocker when integrating with WS1 Hub in the cases of HV-65325.
          * Here is a workaround before titan's inventory service changes the data model in the backend.
          *
          */
         /**
          * Here use sessionstorage in web client:
          * https://confluence.eng.vmware.com/display/HCS/DD+-+Broker+to+consume+clientSessionID+to+increase+resiliency
          */
         this.useLocalStorage = false;
      } else {
         this.useLocalStorage = location.pathname.search("f5vdifwd/vmview") === -1;
      }
      this.remoteSessionEventService.addEventListener("sessionPropertyUpdate", this.onSessionPropertyUpdate);

      this.eventBusService.listen(BusEvent.ClearRunningSession.MSG_TYPE).subscribe(() => {
         this.clearRunningSession();
      });
   };

   private onSessionPropertyUpdate = (skip: boolean, info) => {
      if (!skip) {
         this.updateLocalAndRemoteRunningSession(info);
      }
   };

   private getSessionDataFromStorage = () => {
      let data = null;

      if (this.useLocalStorage) {
         data = this.localStorageService.get(LOCAL_STORAGE_KEY);

         // If fail to read LOCAL_STORAGE_KEY from localStorage, try sessionData.
         // When launching HTML5 client from F5 and refresh in desktop page,
         // the related sessionStorage flag will be missed.
         if (!data) {
            data = this.localStorageService.getSession(LOCAL_STORAGE_KEY);
            if (data) {
               this.useLocalStorage = false;
            }
         }
      } else {
         data = this.localStorageService.getSession(LOCAL_STORAGE_KEY);
      }
      if (data) {
         try {
            this.sessionData = JSON.parse(data);
         } catch (e) {
            Logger.error("failed to parse session data in json format : " + data);
         }
      }

      // need to correct the URI under current implementation, see details in bug 2580196
      const mid = clientUtil.findURLParam("mid");
      if (this.sessionData.brokerUrl !== "" && mid) {
         this.sessionData.brokerUrl = "https://" + this.connectionServerModel.host + "/broker/xml?mid=" + mid;
      }
      return this.sessionData;
   };

   private updateSessionDataToStorage = () => {
      if (this.useLocalStorage) {
         this.localStorageService.set(LOCAL_STORAGE_KEY, JSON.stringify(this.sessionData));
      } else {
         this.localStorageService.setSession(LOCAL_STORAGE_KEY, JSON.stringify(this.sessionData));
      }
   };

   public getSessionData = () => {
      this.updateLocalClientConfig();
      if (clientUtil.isChromeClient()) {
         return this.sessionData;
      } else {
         return this.getSessionDataFromStorage();
      }
   };

   private updateSessionData = () => {
      this.checkSessionData();

      if (!clientUtil.isChromeClient()) {
         this.updateSessionDataToStorage();
      }
   };

   private checkSessionData = () => {
      this.checkClientConfig();
      this.checkRunningSession();
      this.checkMisc();
   };

   private checkClientConfig = () => {
      if (!this.sessionData.clientConfigs) {
         this.sessionData.clientConfigs = {} as SessionClientConfig;
         return;
      }
   };

   private updateLocalClientConfig = () => {
      if (!this.sessionData.clientConfigs) {
         return;
      }
      Logger.debug("Updating client config to: " + JSON.stringify(this.sessionData.clientConfigs));
      const clientConfigs: SessionClientConfig = this.sessionData.clientConfigs;

      this.viewClientModel.enableCredentialCleanupForHTMLAccess = clientConfigs.enableCredentialCleanupForHTMLAccess;
      this.viewClientModel.clientHideDomainList = clientConfigs.clientHideDomainList;
      this.viewClientModel.clientHideServerInformation = clientConfigs.clientHideServerInformation;
   };

   /*
    * The clientConfigs are contained in the response of get-configuration.
    * https://confluence.eng.vmware.com/display/HorizonArchitecture/GetConfiguration
    *
    * currently, we only support
    *    enableCredentialCleanupForHTMLAccess: null,
    *    clientHideServerInformation: null,
    *    clientHideDomainList: null
    *
    */
   public updateLocalAndRemoteClientConfig = (clientConfigs) => {
      if (!clientConfigs) {
         return;
      }

      /**
       * Store the value after authentication, otherwise they could be
       * deleted.
       */
      this.sessionData.clientConfigs = {} as SessionClientConfig;
      this.sessionData.clientConfigs = clientConfigs;

      this.viewClientModel.enableCredentialCleanupForHTMLAccess = clientConfigs.enableCredentialCleanupForHTMLAccess;
      this.viewClientModel.clientHideDomainList = clientConfigs.clientHideDomainList;
      this.viewClientModel.clientHideServerInformation = clientConfigs.clientHideServerInformation;

      this.updateSessionData();
   };

   public getRunningSessions = () => {
      if (!this.sessionData.runningSessions) {
         return [];
      }
      return this.sessionData.runningSessions;
   };

   public getMultiRunningSessions = () => {
      if (!this.sessionData.runningMultiSessions) {
         return [];
      }
      return this.sessionData.runningMultiSessions;
   };

   public updateLocalAndRemoteRunningSession = (runningSessions) => {
      if (!runningSessions) {
         return;
      }

      this.sessionData.runningSessions = runningSessions;
      this.updateSessionData();
   };

   private checkRunningSession = () => {
      if (!this.sessionData.runningSessions) {
         this.sessionData.runningSessions = [];
         return;
      }

      this.sessionData.runningSessions.forEach((runningSession) => {
         if (!runningSession.key) {
            Logger.debug("sessionData.runningSessions is not well format");
         }
      });
   };

   public updateBrokerUrl = (brokerUrl) => {
      if (!brokerUrl) {
         return;
      }

      this.sessionData.brokerUrl = brokerUrl;
      this.sessionData.runningMultiSessions = [];
      const runningSessions = this.sessionData.runningSessions;
      const multiSessions = this.multiSessionSingleUserSessionService.multiSessions;
      Object.values(multiSessions).forEach(({ launchData }) => {
         const runningSession = runningSessions.find((runningSession) => runningSession.key === launchData.key);
         if (runningSession) {
            this.sessionData.runningMultiSessions.push(runningSession);
         }
      });
      this.sessionData.runningSessions = [];
      this.updateSessionData();
   };

   private checkMisc = () => {
      if (!this.sessionData.brokerUrl) {
         this.sessionData.brokerUrl = "";
      }
   };

   public clearRunningSession = () => {
      this.sessionData = {
         clientConfigs: {} as SessionClientConfig,
         runningSessions: [],
         runningMultiSessions: [],
         horizonId: "",
         brokerUrl: ""
      };
      this.updateSessionData();
   };

   /**
    * Extra logic needed if want to support multi-tabs.
    */
   public detectBaseConflict = (sessionData) => {
      if (!this.useLocalStorage) {
         return false;
      }

      const storedDataString = this.localStorageService.get(LOCAL_STORAGE_KEY);
      if (!storedDataString) {
         return true;
      }

      const storedData = JSON.parse(storedDataString);
      if (!storedData || !storedData.runningSessions) {
         return true;
      }

      const previousSessionData = storedData.runningSessions;
      if (previousSessionData.length !== sessionData.length) {
         Logger.debug("session number conflict detected");
         return true;
      }

      const keyCount = {};
      for (let i = 0; i < previousSessionData.length; i++) {
         const previousKey = previousSessionData[i].key;
         if (!keyCount.hasOwnProperty(previousKey)) {
            keyCount[previousKey] = 0;
         }
         keyCount[previousKey]++;
      }

      for (let i = 0; i < sessionData.length; i++) {
         const currentKey = sessionData[i].key;
         keyCount[currentKey]--;
      }
      for (const key in keyCount) {
         if (keyCount.hasOwnProperty) {
            if (keyCount[key] !== 0) {
               if (keyCount[key] > 0) {
                  Logger.debug("found session added conflict");
               } else if (keyCount[key] < 0) {
                  Logger.debug("found session removed conflict");
               } else {
                  Logger.error("inner error for sessionDataService.detectBaseConflict");
               }
               return true;
            }
         }
      }
      return false;
   };

   public updateHorizonId(horizonId: string) {
      this.sessionData.horizonId = horizonId;
      this.updateSessionData();
   }

   public getHorizonId() {
      if (!this.sessionData.horizonId) {
         return "";
      }
      return this.sessionData.horizonId;
   }
}
