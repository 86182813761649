/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Component } from "@angular/core";
import { clientUtil } from "@html-core";
import { ClientSettingModel } from "../../../common/model/client-setting-model";
import { RemoteSessionEventService, SessionMsg } from "../../../common/remote-session/remote-session-event.service";
import { NormalizationService } from "../../../utils/normalization-service";
import { BlastWmks } from "../../common/blast-wmks.service";
import { RunningItemsModel } from "../../common/runningitems-model";
import { WmksService } from "../../common/wmks.service";

@Component({
   selector: "sidebar-perf-tracker",
   templateUrl: "./sidebar-perf-tracker.component.html"
})
export class SidebarPerfTrackerComponent {
   public perfTrackerItem = null;
   constructor(
      private runningItemsModel: RunningItemsModel,
      private remoteSessionEventService: RemoteSessionEventService,
      private clientSettingModel: ClientSettingModel,
      private normalizationService: NormalizationService,
      private wmksService: WmksService
   ) {
      this.runningItemsModel.addEventListener("trayUpdate", (key) => {
         this.perfTrackerItem = this.runningItemsModel.getPerfTrackerItem(key);
      });
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, (session: BlastWmks) => {
         if (session && session.isApplicationSession) {
            this.perfTrackerItem = this.runningItemsModel.getPerfTrackerItem(session.key);
         } else {
            this.perfTrackerItem = null;
         }
      });
   }

   public showPerfContextMenu = (currentItem, event) => {
      event.preventDefault();
      const screenInfo = {
         x: 0,
         y: 0,
         height: 0,
         width: 0,
         devicePixelRatio: window.devicePixelRatio
      };
      currentItem.position = this.getContextMenuPosition(screenInfo);
      this.runningItemsModel.showPerfContextMenu(currentItem);
   };

   public getContextMenuPosition = (screenInfo) => {
      let model, DPIEnable;
      const contextMenuPosition = { x: 280, y: 125 };

      /**
       * This service is used to fix VHCH-2398, since multimon and
       * perf-tracker-redirection will not be used at the same time normally
       * only for HTML Access for now.
       *
       * For Chrome Client, multimon would always be silently enabled for apps.
       * Thus below executing below block would causes unexpected error related to
       * race condition.
       *
       * Add extra safe gard to highlight the restriction and avoid later regression
       * event through it's only used for HTML Access for now.
       */

      if (!clientUtil.isChromeClient()) {
         DPIEnable = !this.clientSettingModel.getBooleanItem("enableHighResolution");
         this.normalizationService.setDPISync(DPIEnable);
         this.normalizationService.setAgentDPI(this.wmksService.getCurrentSessionDPI());
         this.normalizationService.setRawSetting("0", screenInfo);
         this.normalizationService.calculate();
      }
      model = this.normalizationService.getNormalizationModel().get("0");
      return this.normalizationService.normalize(contextMenuPosition, model);
   };

   public activatePerfTracker = (currentItem) => {
      this.runningItemsModel.activePerfTracker(currentItem);
   };
}
