/**
 * ******************************************************
 * Copyright (C) 2016-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * fullscreen-service.js -- fullscreenService
 *
 * "sharedUtilModule" will shared among modules.
 * functions like this and logger should also be added in this place
 * to share among all modules like for launcher, appblast, mulitmon,
 * rtav, etc...
 *
 * Service to control the fullscreen related logic, only support one
 * listener for event, and extendable to the many version.
 */

import { Subject } from "rxjs";
import Logger from "../../core/libs/logger";
import { clientUtil } from "@html-core";
import { FullScreenHelper } from "./full-screen-helper";

export abstract class FullscreenService {
   public static readonly WAIT_TIMEOUT = 500;
   public fullScreen$ = new Subject();

   constructor() {}

   private isSafari = () => {
      const ua = window.navigator.userAgent.toLowerCase();
      return ua.indexOf("applewebkit") > -1;
   };

   public onFullscreenChanged = (event?: any) => {
      Logger.info("full screen changed");
      if (FullScreenHelper.isFullscreen()) {
         Logger.debug("current is fullscreen");
         if (typeof this.onEnterFullscreen !== "function") {
            Logger.error("FullscreenService: onEnterFullscreen not found");
            return;
         }

         if (this.isSafari() || WMKS.BROWSER.isIE()) {
            setTimeout(this.onEnterFullscreen, FullscreenService.WAIT_TIMEOUT);
         } else {
            FullScreenHelper.waitCondition(
               () => {
                  /**
                   * Use tolerant to avoid delay before entering fullscreen on some
                   * machines since chrome has bug on some machines to support screenX
                   * https://drafts.csswg.org/cssom-view/#dom-window-screenx
                   */
                  const tolerant = 10;
                  return screenX <= screen.availLeft + tolerant && screenY <= screen.availTop + tolerant;
               },
               () => {
                  setTimeout(this.onEnterFullscreen, FullscreenService.WAIT_TIMEOUT);
               }
            );
         }
      } else {
         Logger.info("exit full screen");
         if (typeof this.onExitFullscreen !== "function") {
            Logger.error("FullscreenService: onExitFullscreen not found");
            return;
         }
         /**
          * Specify the wait time here to avoid a chrome bug on "screen", which
          * in corner cases violates the spec:
          * https://developer.mozilla.org/en-US/docs/Web/API/Screen/availLeft
          */

         FullScreenHelper.waitCondition(
            () => {
               return screenX >= screen.availLeft && screenY >= screen.availTop;
            },
            this.onExitFullscreen,
            FullscreenService.WAIT_TIMEOUT
         );
      }
   };

   public isInFullscreen = (): boolean => {
      return FullScreenHelper.isFullscreen();
   };
   protected abstract onEnterFullscreen(): void;
   protected abstract onExitFullscreen(): void;

   public abstract enterFullscreen(elem?: any): void;
   public abstract exitFullscreen(): void;
   public abstract bindFullScreen(): void;
   public abstract unbindFullScreen(): void;
   public abstract bindListenersForChromeBook(enterFunc: any, exitFunc: any): void;
}
