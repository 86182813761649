/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * shared-folder.js
 *
 * Act like a Driver, has it's own properties(block reference)
 * can convert flat file system into FS like NTFS
 * Only implement the CROS codes for now with cache disabled.
 *
 * Cross platform logic will be abstracted when enable CDR on HTML Access.
 * For now although it's placed under common, only chrome needed part is done.
 */

import { fsConsts, RDPDR_TYPE } from "./index";
import { FsObserver } from "./fs-observer";
import Logger from "../../../core/libs/logger";
import { FileNode } from "./file-node";
import { FSUtil } from "./fs-util";
import { ChromeClientFileSystem } from "./cdr-lib/chrome-client-file-system";
import { Html5FileSystem } from "./cdr-lib/html5-file-system";

export class SharedFolder {
   public static shareIdCounter: number = RDPDR_TYPE.SMARTCARD_MAX_DEVICE_ID;
   /**
    * The id of share folder, same implementation as mobile clients.
    * @type {number}
    */
   public id: number;
   private _onRemovedCallback = null;
   private driverRoot: FileNode;
   private rootPath;
   private deviceName;
   private fileSystem;

   constructor(rootEntry, onDone, fsObserver: FsObserver) {
      SharedFolder.shareIdCounter += 1;
      this.id = SharedFolder.shareIdCounter;
      if (!rootEntry) {
         Logger.error("<cdr> rootEntry is invalid when init sharedFolder: " + rootEntry);
         throw "<cdr> empty root entry";
      }
      Logger.debug("<cdr> find sharable device " + rootEntry.name);
      const nodeType = "CROS";
      this.driverRoot = new FileNode(
         null,
         "",
         true,
         fsConsts.folderSize,
         rootEntry,
         SharedFolder.shareIdCounter,
         nodeType,
         null
      );
      if (window.chrome.fileSystem) {
         this.fileSystem = new ChromeClientFileSystem();
      } else {
         this.fileSystem = new Html5FileSystem();
      }

      /**
       * For better user experience, read exact folder name using API
       * @param  {[type]} rootEntry [description]
       * @param  {[type]} (path     [description]
       * @return {[type]}           [description]
       */
      if (rootEntry.isFA) {
         this.rootPath = rootEntry.fullPath;
         this.deviceName = rootEntry.name;
         Logger.trace("<cdr> FA shared folder is created for " + this.rootPath + " with id " + this.id);
         onDone();
      } else {
         this.fileSystem.getDisplayPath(rootEntry, (path) => {
            this.rootPath = path;
            this.deviceName = rootEntry.fullPath.split(fsConsts.localSeparator)[1];
            Logger.trace("<cdr> shared folder is created for " + this.rootPath + " with id " + this.id);
            onDone();
         });
      }

      /**
       * watch root folder renamed or removed, not recursively
       */
      fsObserver.observeDirectory(rootEntry, this.onRemoved.bind(this), this.id);
   }

   public onRemoved = (entry, param) => {
      Logger.debug("<cdr> shared folder removed " + entry.name);
      if (!this._onRemovedCallback) {
         Logger.warning("<cdr> setOnRemoved not called before monitor started");
         return;
      }
      this._onRemovedCallback(entry, param);
   };

   public setOnRemoved = (callback) => {
      this._onRemovedCallback = callback;
   };

   public clearForRemove = () => {
      Logger.debug("<cdr> shared folder clear " + this.deviceName);
      this.driverRoot = null;
   };

   /**
    * @param  {string} nodePath The path string
    * @return {object} Return the node by the input path, return null if not found
    */
   public findNodeByPath = async (nodePath) => {
      Logger.trace("<cdr> getting node for " + nodePath);
      nodePath = FSUtil.getPathWithoutLastSeparator(nodePath);
      if (!nodePath) {
         Logger.trace("return root entry");
         return this.driverRoot;
      }
      const pathNames = FSUtil.getPathNames(nodePath);
      return await FSUtil.processThrough(
         pathNames,
         0,
         async function (node, value) {
            return await node.getSubNode(value);
         },
         this.driverRoot
      );
   };

   /**
    * @param  {string} folderPath The folder path
    * @return {object}  Return the folder node by the input path, return null
    *    if not found, support root folder also
    */
   public findFolder = async (folderPath) => {
      const node = await this.findNodeByPath(folderPath);
      if (!node.isDirectory) {
         Logger.error("<cdr> target path is not folder");
         throw "STATUS_NO_SUCH_FILE";
      }
      return node;
   };
}
