/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { EventEmitter, Output, Directive } from "@angular/core";

@Directive()
export class AbstractModalComponent {
   public static readonly BTN_OK = "confirm";
   public static readonly BTN_CANCEl = "cancel";
   public static readonly CLOSE_REASON = {
      ClickBackDrop: 0,
      ExitByESC: 1
   };
   @Output()
   public eventEntry: EventEmitter<any> = new EventEmitter();
   public closeModal = () => {
      this.eventEntry.emit(AbstractModalComponent.BTN_CANCEl);
   };
   public confirmModal = () => {
      this.eventEntry.emit(AbstractModalComponent.BTN_OK);
   };
}
