/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * singlesession-app-session-service.js --
 *
 * Service to manage the single-sessions app sessions, since the server
 * has changed the behavior for originId of GE apps and non-GE apps.
 *
 * Since the new introduced session-id is accurate, also change the logic for
 * single session app session launch workflow.
 *
 * Designed to compatible to seamless window reconnection, etc.
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { getPartialMatchedKey } from "@html-core";
import { BrokerSessionStorageService } from "../../../core/services/storage/broker-session-storage.service";

@Injectable({
   providedIn: "root"
})
export class SingleSessionAppSessionService {
   private static readonly STORAGE_KEY = "Single-session-Info";
   private sessions = {
      cached: {}, // client cached sessions, used to regain connection info
      brokerAvailable: {}, // available sessions returned by broker,
      connected: {} // equal to cached if not reloading client(HTML Access)
   };

   constructor(private brokerSessionStorageService: BrokerSessionStorageService) {
      this.loadSessionCachedFromStorage();
   }

   /**
    * Store sessions.cached into storage.
    */
   private syncChangeToStorage = () => {
      this.brokerSessionStorageService.write(SingleSessionAppSessionService.STORAGE_KEY, this.sessions.cached);
   };

   /**
    * Read data from storage, to init sessions.cached.
    */
   private loadSessionCachedFromStorage = () => {
      if (this.brokerSessionStorageService.exist(SingleSessionAppSessionService.STORAGE_KEY)) {
         this.sessions.cached = this.brokerSessionStorageService.read(SingleSessionAppSessionService.STORAGE_KEY);
      }
   };

   private getSessionKey = (originId: string, sessionId: string) => {
      let sessionKey = null;
      if (!sessionId) {
         Logger.trace("missing sessionId, should be caused by connecting to old Broker");
         sessionKey = originId;
      } else {
         sessionKey = sessionId;
      }
      return sessionKey;
   };

   /**
    * called when <get-application-connection> response contains URI for single-session
    * @param {object} session has properties of sessionId, url
    */
   public cacheSingleSessionLaunchData = (connectionInfo) => {
      // load to enable status sync between tabs
      this.loadSessionCachedFromStorage();
      if (!connectionInfo || !connectionInfo.originId || !connectionInfo.url) {
         Logger.error("invalid single session param to be added");
         return;
      }

      const launchData = JSON.parse(JSON.stringify(connectionInfo));
      launchData.url = launchData.url.split("&session=")[0];
      const sessionKey = this.getSessionKey(launchData.originId, launchData.sessionId);
      Logger.info("single session is managed: " + sessionKey);
      this.sessions.cached[sessionKey] = {
         launchData: launchData,
         reconnectToken: null
      };
      this.syncChangeToStorage();
   };

   public fetchSingleSessionLaunchData = (originId: string, sessionId: string) => {
      let sessionKey = this.getSessionKey(originId, sessionId);
      // load to enable status sync between tabs
      this.loadSessionCachedFromStorage();
      // see detail in bug 2291583
      sessionKey = getPartialMatchedKey(this.sessions.cached, sessionKey);
      if (this.sessions.cached.hasOwnProperty(sessionKey)) {
         const connectionInfo = JSON.parse(JSON.stringify(this.sessions.cached[sessionKey].launchData));
         if (this.sessions.cached[sessionKey].reconnectToken) {
            connectionInfo.url += "&session=" + this.sessions.cached[sessionKey].reconnectToken;
            this.sessions.cached[sessionKey].reconnectToken = null;
            this.syncChangeToStorage();
         }
         return connectionInfo;
      }
      return null;
   };

   public onConnectSingleSession = (session) => {
      const sessionKey = this.getSessionKey(session.keyInfo.originId, session.keyInfo.sessionId);
      this.sessions.connected[sessionKey] = session;
   };

   public onSessionRemoved = (session) => {
      const sessionKey = this.getSessionKey(session.keyInfo.originId, session.keyInfo.sessionId);
      if (this.sessions.connected.hasOwnProperty(sessionKey)) {
         delete this.sessions.connected[sessionKey];
      }
   };

   public onSessionRemovedForTimeout = (sessionKey) => {
      if (this.sessions.connected.hasOwnProperty(sessionKey)) {
         delete this.sessions.connected[sessionKey];
      }
   };

   public hasConnectedSession = (originId, sessionId?: any) => {
      let sessionKey = this.getSessionKey(originId, sessionId);
      // see detail in bug 2291583
      sessionKey = getPartialMatchedKey(this.sessions.connected, sessionKey);
      return this.sessions.connected.hasOwnProperty(sessionKey);
   };

   public updateReconnectToken = (originId, sessionId, reconnectToken) => {
      const sessionKey = this.getSessionKey(originId, sessionId);
      if (!this.sessions.cached.hasOwnProperty(sessionKey)) {
         Logger.error("reconnectToken is set for not tracked single session: " + sessionKey);
         return;
      }
      if (!this.hasConnectedSession(sessionKey)) {
         Logger.warning("reconnectToken is set for a not connected session");
      }
      this.sessions.cached[sessionKey].reconnectToken = reconnectToken;
      this.syncChangeToStorage();
   };

   public clearAll = () => {
      this.sessions = {
         cached: {},
         brokerAvailable: {},
         connected: {}
      };
   };
}
