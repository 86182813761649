/**
 * *************************************************************
 * Copyright (C) 2016-2020, 2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview device-message-manager.ts -- DeviceMessageManager
 * Class contains the logic for device related messages, mainly works
 * as a status converter since we need to mock response to avoid pending
 * agent response for too long when agent ask whether has devices.
 * For bug 2629754, queue all events at client side while pending on
 * device permission asking and also encoder init and to enforce messages
 * are processed by order.
 *
 * Class that contains functions and logic for using the rtav sub channel
 * @type {function}
 */

import { Injectable } from "@angular/core";
import { MessageWaitManager } from "../messageWaitManager";
import { Logger } from "@html-core";

@Injectable()
export class DeviceMessageManagerV2 {
   private allowedActions;
   public localMessageControllerV2;

   constructor(private messageWaitManager: MessageWaitManager) {}

   public init(controller) {
      this.allowedActions = [
         "PMsgStop_A",
         "PMsgStart_A",
         "PMsgStop_V",
         "PMsgStart_V",
         "PMsgStartStream_A",
         "PMsgStartStream_V"
      ];

      this.localMessageControllerV2 = controller;
      this.localMessageControllerV2.deviceMessageManager = this;
   }

   public processMessage(messageType, deviceIndex, callback, param) {
      if (!this.localMessageControllerV2) {
         Logger.error("uninited controller", Logger.RTAV);
         return;
      }
      Logger.debug("push device message into wait queue: " + messageType, Logger.RTAV);
      this.messageWaitManager.process(messageType, () => {
         Logger.debug("processing device message: " + messageType + " deviceIndex is " + deviceIndex, Logger.RTAV);
         switch (messageType) {
            case "PMsgStart_A":
               Logger.debug("PMsgStart_A in devicemessagemanagerv2", Logger.RTAV);
               this.localMessageControllerV2.enableAudioIn(deviceIndex, (success) => {
                  if (success) {
                     Logger.debug("audio device successfully enabled", Logger.RTAV);
                  } else {
                     Logger.debug("audio device fail to be enabled", Logger.RTAV);
                  }
                  callback(success);
               });
               break;
            case "PMsgStart_V":
               Logger.debug("PMsgStart_V in devicemessagemanagerv2", Logger.RTAV);
               this.localMessageControllerV2.enableVideoIn(deviceIndex, (success) => {
                  if (success) {
                     Logger.debug("video device successfully enabled", Logger.RTAV);
                  } else {
                     Logger.debug("video device fail to be enabled", Logger.RTAV);
                  }
                  callback(success);
               });
               break;
            case "PMsgStop_A":
               this.localMessageControllerV2.stopAudioIn(deviceIndex, (success) => {
                  if (success) {
                     Logger.debug("audio device successfully disabled", Logger.RTAV);
                  } else {
                     Logger.debug("audio device fail to be disabled", Logger.RTAV);
                  }
                  callback(success);
               });
               break;
            case "PMsgStop_V":
               this.localMessageControllerV2.stopVideoIn(deviceIndex, (success) => {
                  if (success) {
                     Logger.debug("Video device successfully disabled", Logger.RTAV);
                  } else {
                     Logger.debug("video device fail to be disabled", Logger.RTAV);
                  }
                  callback(success);
               });
               break;
            case "PMsgStartStream_A":
               this.localMessageControllerV2.startAudioIn(deviceIndex);
               break;
            case "PMsgStartStream_V":
               this.localMessageControllerV2.startVideoIn(deviceIndex);
               break;
            default:
               Logger.error("unexpected message type in messageWaitManager.process", Logger.RTAV);
               break;
         }
      });
   }

   public onMessage(messageType, deviceIndex, callback, param?) {
      if (typeof callback !== "function") {
         Logger.error("inner error: there is no callback for " + messageType, Logger.RTAV);
         return;
      }
      if (!this.allowedActions.includes(messageType)) {
         Logger.error("DeviceMessageManager shouldn't be used to process message " + messageType, Logger.RTAV);
         callback(false);
         return;
      }
      this.localMessageControllerV2.processWhenHaveResources((canProcess) => {
         if (canProcess) {
            this.processMessage(messageType, deviceIndex, callback, param);
         } else {
            callback(false);
            Logger.debug("do nothing for " + messageType + " since session is not allow to be processed", Logger.RTAV);
         }
      });
   }
}
