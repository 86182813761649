/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ViewClientModel } from "../../../../shared/common/model/viewclient-model";
@Component({
   selector: "titan-contextmenu",
   templateUrl: "./context-menu.component.html",
   styleUrls: ["../contextmenu-root/context-menu-content.component.less"]
})
export class TitanContextMenuComponent implements OnInit {
   @Input() app: TitanEntitlementItem;
   @Input() paneClass: string;
   @Output() protocolSelectEvent = new EventEmitter();
   @Output() appLaunchEvent = new EventEmitter();
   public selectedProtocol: string;
   public isResetAllowed: boolean;
   public isChromeOS: boolean;
   public isClientInLaunchClients: boolean;
   public isBrowserInLaunchClients: boolean;
   public isSupportBlast: boolean;
   public isSupportPCoIP: boolean;
   public isSupportRDP: boolean;
   public sWindowsFromJade: boolean;

   constructor(private viewClientModel: ViewClientModel) {}

   ngOnInit(): void {
      this.isChromeOS = this.viewClientModel.osModel.mIsChromeOS;
      this.setItemProtocol();
   }

   private setItemProtocol(): void {
      for (let i = 0; i < this.app.protocols.length; i++) {
         if (this.app.protocols[i].name === "BLAST") {
            this.isSupportBlast = true;
         }
         if (this.app.protocols[i].name === "PCOIP") {
            this.isSupportPCoIP = true;
         }
         if (this.app.protocols[i].name === "RDP") {
            this.isSupportRDP = true;
         }
      }
      this.selectedProtocol = this.app.selectedProtocol;
   }

   public launchHorizonApp($event: any, item: TitanEntitlementItem, client: string): void {
      $event.stopPropagation();
      const obj = {
         item: item,
         client: client
      };
      this.appLaunchEvent.emit(obj);
   }

   public setLaunchProtocol($event: any, item: TitanEntitlementItem, protocol: string): void {
      $event.stopPropagation();
      const obj = {
         item: item,
         protocol: protocol
      };
      this.protocolSelectEvent.emit(obj);
   }
}
