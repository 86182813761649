/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
export const AVPluginState = {
   STChannelClose: 0,
   STChannelOpen: 1,
   STOff: 2,
   STOn: 3,
   STPending_AIn: 4,
   STEnabled_AIn: 5,
   STEnabled_V: 6,
   STEnabled_VAIn: 7,
   STError: 8
};
export const RtavTypes = {
   PMsgGetConfig: 4,
   PMsgSetConfig: 5,
   PMsgGetCliSettings: 6,
   PMsgSetCliSettings: 7,
   PMsgStart_A: 8,
   PMsgStart_A_Ack: 9,
   PMsgStart_A_Ack_Err: 10,
   PMsgStop_A: 11,
   PMsgStop_A_Ack: 12,
   PMsgStop_A_Ack_Err: 13,
   PMsgStart_V: 14,
   PMsgStart_V_Ack: 15,
   PMsgStart_V_Ack_Err: 16,
   PMsgStop_V: 17,
   PMsgStop_V_Ack: 18,
   PMsgStop_V_Ack_Err: 19,
   PMsgStartStream: 21,
   PMsgStopStream: 22,
   PMsgBinData: 24,
   PMsgBinData_A: 33,
   PMsgBinData_V: 34,
   PMsgAudioDevices: 40,
   PMsgVideoDevices: 41,
   PMsgStartStream_A: 42,
   PMsgStartStream_V: 43
};
export enum CodecTypes {
   CodecNone = 0,
   CodecSpeex = 1,
   CodecTheora = 2,
   CodecTheoraSpeex = 3,
   CodecVmwSpeex = 4,
   CodecVmwTheora = 5,
   CodecVmwTheoraSpeex = 6,
   CodecVmwH264 = 7,
   CodecVmwH264Speex = 8, // For H264 and speex
   CodecVmwOpus = 9, // Opus codec for audio will be done by 24FQ3
   CodecVmwH264Opus = 10 // For H264 and Opus, which will be done by 24FQ3
}
export const Platforms = {
   WINDOWS: 0,
   LINUX: 1,
   MACOS: 2,
   ANDROID: 3,
   IOS: 4,
   WEB: 5
};
export const Versionnum = {
   v2_x: 2,
   v2_y: 0,
   v2_z: 1
};
export const RTAV_H264_OPUS_CODEC = 0x0028;
export const RTAV_H264_CODEC = 0x0008;
export const RTAV_OPUS_CODEC = 0x0020;
export const RTAV_V2_V0 = 0x8000;
export const StreamHeaderLengthV2 = 20;
export const StreamHeaderLength = 16;
export const RTAV_AUDIO_DISABLED = 0x0001;
export const RTAV_VIDEO_DISABLED = 0x0002;
