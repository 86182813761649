/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { AfterViewInit, Component, ElementRef, OnDestroy, Optional } from "@angular/core";
import { TranslateService } from "@html-core";
import $ from "jquery";
import { BusEvent, EventBusService, IMessage } from "../../../core/services/event";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { PanelService } from "../common/panel.service";
import { SessionLifeCycleService } from "../../../chrome-client/desktop/blast-common/session-life-cycle.service";
import { UsbRedirState } from "./usb-channel";
import { RemoteUsbDevice } from "./usb-remote";
import { USBService } from "./usb-service";
import { NotificationType, usb } from "./usb.event";
import { RemoteSessionEventService, SessionMsg } from "../../common/remote-session/remote-session-event.service";

@Component({
   selector: "usb-redirection",
   templateUrl: "./usb.component.html",
   styleUrls: []
})
export class UsbComponent implements AfterViewInit, OnDestroy {
   private element: JQuery;
   public windowResizeHandler: any;
   public redirectedDevices: RemoteUsbDevice[] = [];
   public showUsbBtn: boolean = false;
   public showUsbPanel = false;
   public currentState: UsbRedirState = UsbRedirState.DISABLED;
   public placeholder: string;

   constructor(
      private panelService: PanelService,
      @Optional()
      private sessionLifeCycleService: SessionLifeCycleService,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private eventBusService: EventBusService,
      private usbService: USBService,
      private translate: TranslateService,
      private remoteSessionEventService: RemoteSessionEventService,
      private _el: ElementRef
   ) {
      this.element = $(this._el.nativeElement.children[0]);
      this.addUsbEventHandle();
      this.placeholder = this.translate._T("USB_REDIRECT_NOT_SUPPORT");
   }

   ngAfterViewInit() {
      this.element = $(this._el.nativeElement.children[0]);
      this.panelService.addPanelElement("usb", this.element);

      this.element
         .resizable({
            containment: "document"
         })
         .draggable({
            containment: "window",
            handle: ".usb-handle",
            start: (event, ui) => {
               this.element.css("z-index", this.panelService.getNewZIndex());
            }
         });

      this.windowResizeHandler = this.panelService.windowResizeHandler(this, "showUsbPanel", this.element);

      window.addEventListener("resize", this.windowResizeHandler);

      this.eventBusService.listen(BusEvent.ToggleUsbMsg.MSG_TYPE).subscribe(() => {
         this.toggleUsbPanel();
      });
   }

   ngOnDestroy() {
      window.removeEventListener("resize", this.windowResizeHandler);
   }

   public isUsbListEmpty = () => {
      return this.redirectedDevices.length === 0;
   };

   public findDevice = () => {
      let currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (this.sessionLifeCycleService) {
         currentSession = this.sessionLifeCycleService.getCurrentSession();
      }
      this.usbService.getUserSelectedDevices(currentSession.key);
   };

   public releaseDevice = (index) => {
      const device = this.redirectedDevices[index];
      this.usbService.releaseSelectedDevices(device);
   };

   // shows or hides the usb panel
   public toggleUsbPanel = ($event?: any) => {
      this.showUsbPanel = !this.showUsbPanel;
      if (this.showUsbPanel) {
         setTimeout(() => {
            this.getCurrentSessionState();
            this.windowResizeHandler();
            this.panelService.onFocus("usb");
         });
      }
      if ($event) {
         $event.stopPropagation();
         $event.preventDefault();
      }
   };

   public getCurrentSessionState = () => {
      let currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (this.sessionLifeCycleService) {
         currentSession = this.sessionLifeCycleService.getCurrentSession();
      }
      if (currentSession && currentSession.key) {
         this.currentState = this.usbService.getSessionState(currentSession.key);
         this.showUsbBtn = this.currentState === UsbRedirState.AVAILABLE;
         if (this.currentState === UsbRedirState.NOT_AVAILABLE) {
            this.placeholder = this.translate._T("USB_REDIRECT_UNAVAILABLE");
         } else if (this.currentState === UsbRedirState.PENDING) {
            this.placeholder = this.translate._T("USB_REDIRECT_PENDING");
         } else if (this.currentState === UsbRedirState.DISABLED) {
            this.placeholder = this.translate._T("USB_REDIRECT_NOT_SUPPORT");
         }
      }
   };

   private addUsbEventHandle = () => {
      this.usbService.subscribe((msg: IMessage) => {
         let currentSession = this.htmlRemoteSessionManager.getCurrentSession();
         if (this.sessionLifeCycleService) {
            currentSession = this.sessionLifeCycleService.getCurrentSession();
         }
         if (msg.type === usb.SessionChanged.MSG_TYPE) {
            const usbMsg: usb.SessionChanged = msg as usb.SessionChanged;
            if (!usbMsg.isNewSession) {
               if (!!currentSession && usbMsg.wmksKey === currentSession.key) {
                  this.redirectedDevices = [];
                  this.currentState = this.usbService.getSessionState(usbMsg.wmksKey);

                  setTimeout(() => {
                     this.redirectedDevices = this.usbService.getRedirDevices(currentSession.key);
                     this.showUsbBtn = this.currentState === UsbRedirState.AVAILABLE;
                  });
               }
            } else {
               if (!!currentSession && usbMsg.wmksKey === currentSession.key) {
                  this.getCurrentSessionState();
               }
            }
         } else if (msg.type === usb.SessionDisconnected.MSG_TYPE) {
            if (this.showUsbPanel) {
               this.toggleUsbPanel();
            }
         } else if (msg.type === usb.DeviceChanged.MSG_TYPE) {
            if (currentSession) {
               setTimeout(() => {
                  this.redirectedDevices = this.usbService.getRedirDevices(currentSession.key);
               });
            }
         } else if (msg.type === usb.RedirectionNotSupported.MSG_TYPE) {
            const usbMsg: usb.RedirectionNotSupported = msg as usb.RedirectionNotSupported;
            this.eventBusService.dispatch(
               new usb.NotificationMsg(NotificationType.NOT_SUPPORT_MSG, usbMsg.device.getProductName())
            );
         } else if (msg.type === usb.DeviceAlreadyRedirected.MSG_TYPE) {
            const usbMsg: usb.DeviceAlreadyRedirected = msg as usb.DeviceAlreadyRedirected;
            this.eventBusService.dispatch(
               new usb.NotificationMsg(NotificationType.ALREADY_REDIRECT_MSG, usbMsg.device.getProductName())
            );
         } else if (msg.type === usb.SessionStatusUpdate.MSG_TYPE) {
            this.redirectedDevices = this.usbService.getRedirDevices(currentSession.key);
            this.getCurrentSessionState();
         }
      });
   };
}
