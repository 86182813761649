/**
 * ******************************************************
 * Copyright (C) 2017-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import * as CST from "@html-core";
import { Component, Input, ChangeDetectorRef, AfterViewInit, HostListener } from "@angular/core";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { ConnectionURIModel } from "../../common/model/connection-uri-model";
import { ViewService } from "../../launcher/common/view-service";
import { ConnectionServerModel } from "../../../shared/common/model/connection-server-model";
import { AnonymousService } from "../../../shared/common/service/anonymous.service";
import { TranslateService } from "@html-core";

interface UnauthenticatedData {
   content: {
      usernames?: Array<UnauthenticatedUsername>;
      error: string;
      currentUnauthenticatedUsername?: UnauthenticatedUsername;
   };
}

type UnauthenticatedUsername = {
   default?: boolean;
   value: string;
};

@Component({
   selector: "login-unauthenticated",
   templateUrl: "./unauthenticated.component.html"
})
export class LoginUnauthenticatedComponent
   extends BaseViewComponent
   implements BaseViewComponentInterface, AfterViewInit
{
   @Input() formData;
   public cancelLoading: boolean;
   public selectedAnonyUsername: string;
   public selectedAnonyUsernameDisplay: string;
   public selectedAnonyUsernameObj: UnauthenticatedUsername;
   public error: string;
   public unauthenticatedUsernames: Array<UnauthenticatedUsername>;
   public unauthenticatedUsernameControl: UntypedFormControl;
   public unauthenticatedForm: UntypedFormGroup;
   public showAnonymousList: boolean = false;

   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private translationService: TranslateService,
      private modalDialogService: ModalDialogService,
      private connectionURIModel: ConnectionURIModel,
      private viewService: ViewService,
      private connectionServerModel: ConnectionServerModel,
      private anonymousService: AnonymousService
   ) {
      super(changeDetector, "Unauthenticated");
      this.cancelLoading = false;
      this.unauthenticatedUsernameControl = new UntypedFormControl(this.selectedAnonyUsername, [Validators.required]);
      this.unauthenticatedForm = new UntypedFormGroup({
         unauthenticatedUsername: this.unauthenticatedUsernameControl
      });
   }

   public ngAfterViewInit() {
      this._initData();
   }

   public getDisplayName = (user: UnauthenticatedUsername): string => {
      if (user.default) {
         // Append ' (default)' to default username
         return user.value + this.translationService._T("DEFAULT_FOR_ANONYMOUS");
      } else {
         return user.value;
      }
   };

   private _getDefaultUsername = (): string => {
      const usernames: Array<UnauthenticatedUsername> = this.unauthenticatedUsernames;
      for (let i = 0; i < usernames.length; i++) {
         if (!!usernames[i].default && !!usernames[i].default === true) {
            return usernames[i].value;
         }
      }
      // If not find the default username, just use the first one
      return usernames[0].value;
   };

   private _getDefaultUsernameObj = (): UnauthenticatedUsername => {
      const usernames: Array<UnauthenticatedUsername> = this.unauthenticatedUsernames;
      for (let i = 0; i < usernames.length; i++) {
         if (usernames[i].default) {
            return usernames[i];
         }
      }
      // If not find the default username, just use the first one
      return usernames[0];
   };

   public _initData = () => {
      const data = this.data as UnauthenticatedData;
      this.onAuthScreen(this.componentName, data);
      this.anonymousService.setMode(true);
      this.unauthenticatedUsernames = data.content.usernames;
      this.selectedAnonyUsername = this._getDefaultUsername();
      this.selectedAnonyUsernameObj = this._getDefaultUsernameObj();
      this.selectedAnonyUsernameDisplay = this.getDisplayName(this.selectedAnonyUsernameObj);

      if (data.content.currentUnauthenticatedUsername) {
         this.selectedAnonyUsername = data.content.currentUnauthenticatedUsername.value;
      }
      if (data.content.error) {
         this.error = data.content.error;
      } else {
         this.error = "";
      }
      this.cancelLoading = false;

      if (this.selectedAnonyUsername) {
         this.unauthenticatedUsernameControl.setValue(this.selectedAnonyUsername);
      }

      this._tryAutoConnect();
   };

   private _verifyUsername = (username: string): UnauthenticatedUsername => {
      const usernames: Array<UnauthenticatedUsername> = this.unauthenticatedUsernames;
      for (let i = 0; i < usernames.length; i++) {
         if (CST.ignoreCaseEquals(username, usernames[i].value)) {
            return usernames[i];
         }
      }
      return null;
   };

   private _loginWithUsername = (username) => {
      this.selectedAnonyUsername = username;
      this.connectionServerModel.username = username;
      this.jscdkCommonInvoker.submitUnauthenticated(username);
   };

   private _tryAutoConnect = () => {
      // If unauthenticatedAccessAccount exists in URL, use it
      if (!!this.connectionURIModel.params && !!this.connectionURIModel.params.unauthenticatedAccessAccount) {
         const usernameObj = this._verifyUsername(this.connectionURIModel.params.unauthenticatedAccessAccount);

         if (usernameObj !== null) {
            this.unauthenticatedUsernameControl.setValue(usernameObj.value);
            this._loginWithUsername(usernameObj.value);
         } else {
            this.modalDialogService.showError({
               data: {
                  titleKey: "ERROR",
                  contentKey: "ANONYMOUS_USERNAME_NOT_FOUND",
                  contentSubstitutionsKey: this.connectionURIModel.params.unauthenticatedAccessAccount
               },
               callbacks: {
                  confirm: () => {
                     this.viewService.gotoPortal();
                  }
               }
            });
         }
         return;
      }

      /**
       * If no unauthenticatedAccessAccount exists but there is only
       * one username, use it
       */
      if (this.unauthenticatedUsernames.length === 1) {
         setTimeout(() => {
            this._loginWithUsername(this.unauthenticatedUsernames[0].value);
         }, 300);
      }
   };

   public onSubmit = (event) => {
      this.cancelLoading = false;
      /**
       * web API 'event.submitter' is not supported in IE and Safari
       * so add condition to verified if event.submitter is undefined here
       */
      if (!event.submitter || (event.submitter && event.submitter.id !== "unauthenticatedCancelLoginBtn")) {
         this._loginWithUsername(this.unauthenticatedUsernameControl.value);
      }
   };

   public cancel = () => {
      this.cancelLoading = true;

      if (this.blankOutContent) {
         this.jscdkCommonInvoker.cancelCurrentRequest({
            name: "Unauthenticated",
            content: {
               usernames: this.unauthenticatedUsernames,
               currentUnauthenticatedUsername: this.unauthenticatedUsernameControl.value
            }
         });
         this.blankOutContent = false;
      } else {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"Unauthenticated_Access");
      }
      if (this.selectedAnonyUsername) {
         this.unauthenticatedUsernameControl.setValue(this.selectedAnonyUsername);
      }
   };

   public toggleAnonymousList = () => {
      this.showAnonymousList = !this.showAnonymousList;
   };

   public selectAnonymousUser = (anonymousUsername) => {
      this.selectedAnonyUsernameDisplay = this.getDisplayName(anonymousUsername);
      this.unauthenticatedUsernameControl.setValue(anonymousUsername.value);
   };

   @HostListener("document:mousedown", ["$event"]) handleMouseDown(event) {
      if (
         !event.srcElement.className.includes("ui-selectmenu-icon") &&
         !event.srcElement.className.includes("ui-menu") &&
         this.showAnonymousList
      ) {
         this.showAnonymousList = false;
      }
   }
}
