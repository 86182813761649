/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";

@Injectable()
export class SyncTimer {
   private inited = false;
   private startTime = 0;
   // track how many devices are using the sync timer
   private referenceCount = 0;

   constructor() {}

   /**
    * @return {number} return current system time
    */
   public getNow() {
      return new Date().getTime();
   }

   public isInited() {
      return this.inited;
   }

   /**
    * reset the timer to init status
    */
   public reset() {
      this.referenceCount++;
      if (this.inited) {
         return;
      }
      this.startTime = this.getNow();
      this.inited = true;
   }

   public clear() {
      this.referenceCount--;
      if (this.referenceCount > 0) {
         // other devices are still using
         return;
      }
      /* Previously, we used the referenceCount to ensure that
      the sync timer was only cleared when there's no device using it.
      However, there are some rare cases that cause issues.
      Even if we don't clear the sync timer, it is almost impossible for the timestamp
      to overflow (takes about 1192 hours for one session), so we just disable the clear operation */
      // this.startTime = 0;
      // this.inited = false;
   }

   /**
    * @return {number} This returns the time from the timer-init moment
    */
   public getTime() {
      if (!this.inited) {
         return 0;
      }
      let now = this.getNow();
      return now - this.startTime;
   }
}
