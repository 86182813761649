/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input } from "@angular/core";
import { AbstractModalComponent } from "../../../common/commondialog/abstract-modal.component";

@Component({
   selector: "clip-ft-help-dialog",
   templateUrl: "./clipboard-filetransfer-help-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class ClipFTHelpDialogComponent extends AbstractModalComponent {
   @Input() public title;
   @Input() public msg;
   @Input() public modKey;
}
