/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable } from "@angular/core";
import { PrePrintDataService } from "./pre-print-redirection.service";

@Injectable({
   providedIn: "root"
})
export class HTMLPrePrintDataService extends PrePrintDataService {
   constructor() {
      super();
   }

   public checkPrinterList = async (callback) => {};
   public getExchangePrinterList = async (callback) => {};
   public constructExchangeClientInfoReply = () => {};
}
