/**
 * *****************************************************
 * Copyright 2021 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { clientUtil, LocalStorageService, Logger } from "@html-core";
import { ConnectionServerModel } from "../../shared/common/model/connection-server-model";
import { ChromeServerService } from "../launcher/server-connect/chrome-server.service";

//TODO: avoid claim in root @yanx
@Injectable({
   providedIn: "root"
})
export class FileExtensionService {
   private fileExtensionsMap = {};
   public imageBlobList = new Map<string, string>();
   constructor(
      private localStorageService: LocalStorageService,
      private connectionServerModel: ConnectionServerModel,
      private chromeServerService: ChromeServerService
   ) {}

   /*
    *---------------------------------------------------------------------------
    *
    * filterInfo  --
    *
    * Filter the info valuable to file association from
    * the response of <get-launch-item> XML
    *
    * Results:
    *      None
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */

   public filterInfo = async (appsInfo) => {
      if (clientUtil.isChromeClient()) {
         let host = this.connectionServerModel.host;
         const originServer = await this.chromeServerService.getOriginServer(host);
         if (originServer) {
            this.removeAssociatedApps(host);
            host = originServer;
         }
         if (!this.fileExtensionsMap[host]) {
            this.fileExtensionsMap[host] = {};
         }

         for (const key in appsInfo) {
            //need to combine extensions for different users
            this.fileExtensionsMap[host][key] = {
               name: appsInfo[key].name,
               id: appsInfo[key].id,
               extensions: appsInfo[key]["registered-file-types"],
               brokerURL: host,
               icon: this._getIconFromIconList(appsInfo[key].icons)
            };
         }

         this._orderFileInfoByExtensionName(host);
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _getIconFromIconList  --
    *
    * The icon on FA menu will show as 10px
    * So get size with 16 comes with high priority
    *
    * Results:
    *      string
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */

   private _getIconFromIconList = (icons) => {
      for (const key in icons) {
         if (icons[key].height === "16") {
            return icons[key];
         } else if (icons[key].height === "24") {
            return icons[key];
         } else if (icons[key].height === "32") {
            return icons[key];
         } else if (icons[key].height === "48") {
            return icons[key];
         } else if (icons[key].height === "256") {
            return icons[key];
         } else {
            return null;
         }
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _orderFileInfoByExtensionName  --
    *
    * Order the extension info, make the extension name as the primary key,
    * store the map in local storage
    *
    * Results:
    * The result should be as the following example
    * {
    *    "brokerA": {
    *       ".txt" : {
    *          {
    *             id: "",
    *            {
    *                displayName: "NotePad",
    *                brokerURL: "x.x.x.x",
    *                icon: "",
    *                appId: ""
    *           }
    *          }, ...
    *       },
    *      ".doc": {...}
    *    },
    *    brokerB: {...}
    * }
    * Side effects:
    *      None.
    *---------------------------------------------------------------------------
    */

   private _orderFileInfoByExtensionName = (registeredHost?: string) => {
      let host = this.connectionServerModel.host;
      if (registeredHost && host !== registeredHost) {
         host = registeredHost;
      }

      if (!this.fileExtensionsMap[host] || Object.keys(this.fileExtensionsMap[host]).length === 0) {
         Logger.warning("The file extensions info is empty in fileExtensionsMap for host: " + host);
         return;
      }
      const orderedFileExtensionsMap = {};

      for (const key in this.fileExtensionsMap[host]) {
         const extensionInfo = this.fileExtensionsMap[host][key];
         const id = host + "::" + extensionInfo.id;
         const extensions = extensionInfo.extensions;

         for (const extensionKey in extensions) {
            const extensionName = extensions[extensionKey].name;
            if (!orderedFileExtensionsMap.hasOwnProperty(extensionName)) {
               orderedFileExtensionsMap[extensionName] = {};
               orderedFileExtensionsMap[extensionName]["default"] = "";
            }
            orderedFileExtensionsMap[extensionName][id] = {
               displayName: extensionInfo.name,
               brokerURL: host,
               icon: extensionInfo.icon,
               appId: extensionInfo.id
            };
         }
      }
      this.updateToStorage(orderedFileExtensionsMap);
   };

   public updateDefaultApp = (extensionName, id) => {
      const defaultAppDataString = this.localStorageService.get("fileAssociationHorizon");
      let data;
      if (!defaultAppDataString) {
         data = {};
      } else {
         data = JSON.parse(defaultAppDataString);
      }
      if (data[extensionName] && data[extensionName].hasOwnProperty("default")) {
         data[extensionName].default = id;
      }
      this.localStorageService.set("fileAssociationHorizon", JSON.stringify(data));
   };

   private updateToStorage = (orderedFileExtensionsMap) => {
      const existingDataString = this.localStorageService.get("fileAssociationHorizon");
      let dataObj;
      if (!existingDataString) {
         dataObj = {};
      } else {
         dataObj = JSON.parse(existingDataString);
      }
      //Merge the response info and the one in storage

      for (const key in orderedFileExtensionsMap) {
         if (orderedFileExtensionsMap[key]) {
            for (const id in orderedFileExtensionsMap[key]) {
               if (!dataObj[key]) {
                  dataObj[key] = {};
               }
               dataObj[key][id] = orderedFileExtensionsMap[key][id];
            }
         }
      }
      const dataString = JSON.stringify(dataObj);
      this.localStorageService.set("fileAssociationHorizon", dataString);
      Logger.info("Extension map has been updated in storage");
   };

   public removeAssociatedApps = (brokerURL) => {
      //Remove in this.fileExtensionsMap
      if (this.fileExtensionsMap && this.fileExtensionsMap.hasOwnProperty(brokerURL)) {
         delete this.fileExtensionsMap[brokerURL];
      }
      //Remove in storage
      const existingDataString = this.localStorageService.get("fileAssociationHorizon");
      if (!existingDataString) {
         Logger.info("No fa data, don't need to remove for server delete.");
         return;
      } else {
         const data = JSON.parse(existingDataString);
         for (const extensionName in data) {
            Logger.debug("Remove for server delete: " + brokerURL + ", extensionName: " + extensionName);
            if (data[extensionName] && data[extensionName]) {
               for (const id in data[extensionName]) {
                  if (id === "default") {
                     continue;
                  }
                  if (id && data[extensionName][id] && data[extensionName][id].brokerURL === brokerURL) {
                     delete data[extensionName][id];
                     if (data[extensionName].default === id) {
                        data[extensionName].default = "";
                     }
                  }
               }
               if (Object.keys(data[extensionName]).length === 1 && data[extensionName].hasOwnProperty("default")) {
                  delete data[extensionName];
               }
            }
         }
         Logger.info("Remove for server delete: " + brokerURL);
         this.localStorageService.set("fileAssociationHorizon", JSON.stringify(data));
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * getImageFromDb  --
    *
    * Get the icon of app in indexedDb.
    *
    * Results: Array
    * The result imageBlobList should be as the following example
    * {
    *    "cn=NotePad1..." : "Blob1",
    *    "cn=NotePad2..." : "Blob2",
    *     ...
    * }
    *
    *---------------------------------------------------------------------------
    */

   public getImageFromDb = () => {
      return new Promise<Map<string, string>>((resolve, reject) => {
         //@ts-ignore
         const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
         if (indexedDB) {
            const request = indexedDB.open("HorizonFileAssociationImage");

            request.onerror = (event) => {
               reject();
               Logger.error("Get image from indexDB: Database error: " + event.target.errorCode);
            };

            request.onsuccess = (event) => {
               Logger.debug("success to open indexDB.");
               const db = request.result;
               if (db && db.objectStoreNames.contains("app-images")) {
                  const tx = db.transaction("app-images", "readonly");
                  const store = tx.objectStore("app-images");
                  const imageIndex = store.index("key");
                  const openCursor = imageIndex.openCursor();
                  openCursor.onsuccess = (event) => {
                     const cursor = event.target.result;
                     if (cursor) {
                        if (cursor.key && cursor.key !== "") {
                           try {
                              Logger.debug("Get image from indexDB: " + cursor.key + ": " + cursor.value.imageBlob);
                              const blob = clientUtil.base64toBlob(cursor.value.imageBlob, "image/png");
                              this.imageBlobList.set(cursor.key, URL.createObjectURL(blob));
                           } catch (e) {
                              Logger.warning("Read image blob form index fails.");
                              Logger.exception(e);
                           }
                        }
                        cursor.continue();
                     } else {
                        resolve(this.imageBlobList);
                        Logger.info("Get image from indexDB ends.");
                     }
                  };
                  openCursor.onerror = (event) => {
                     reject();
                     Logger.error("Open cursor fails: " + event.target.errorCode);
                  };
               } else {
                  reject();
                  Logger.info("app-images doesn't exist in indexedDB.");
               }
            };

            request.onupgradeneeded = (event) => {
               Logger.info("Get image from indexDB: Success to upgrade indexDB.");
            };
         } else {
            reject();
            Logger.error("indexedDB is not supported.");
         }
      });
   };
}
