/**
 * ******************************************************
 * Copyright (C) 2019-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { LoginData } from "../common/content-data";
@Injectable({
   providedIn: "root"
})
export class LoginService {
   public loginRedraw$ = new Subject<any>();
   public winPasswordRedraw$ = new Subject<any>();
   public securIDPasscodeRedraw$ = new Subject<any>();
   public securIDNextTokenCode$ = new Subject<any>();
   public securIDPinChange$ = new Subject<any>();
   public securIDWaitRedraw$ = new Subject<any>();
   public changPasswordRedraw$ = new Subject<any>();
   public reauthDialogRedraw$ = new Subject<any>();
   constructor() {}
   public redraw = function (data: LoginData, name?: string) {
      if (name === "loginCom") {
         this.loginRedraw$.next(data);
      } else if (name === "ReauthDialog") {
         this.reauthDialogRedraw$.next(data);
      } else {
         switch (data.name) {
            case "WindowsPassword":
               this.winPasswordRedraw$.next(data);
               break;
            // LoginWindowsPasswordComponent.redraw(data);
            case "SecurIDPasscode":
               this.securIDPasscodeRedraw$.next(data);
               break;
            // SecurIDPasscodeComponent.redraw(data);
            case "SecurIDNextTokenCode":
               this.securIDNextTokenCode$.next(data);
               break;
            // SecurIDNextTokenComponent.redraw(data);
            case "SecurIDPinChange":
               this.securIDPinChange$.next(data);
               break;
            // SecurIDPinChangeComponent.redraw(data);
            case "SecurIDWait":
               this.securIDWaitRedraw$.next(data);
               break;
            // LoginSecurIDWaitComponent.redraw(data);
            case "WindowsPasswordExpired":
               this.changPasswordRedraw$.next(data);
               break;
            // ChangePasswordComponent.redraw(data);
         }
      }
   };
}
