/**
 * ******************************************************
 * Copyright (C) 2015-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import $ from "jquery";
import { HostListener } from "@angular/core";
import { ConnectionServerModel } from "../../../common/model/connection-server-model";
import { UtilService } from "../util-service";
import { IdleSessionService } from "../../../common/service/idle-session.service";
import { BusEvent, EventBusService } from "../../../../core/services/event";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { Component, Input, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { AnonymousService } from "../../../common/service/anonymous.service";
import { clientUtil } from "@html-core";
import { imageAsset } from "../../../common/image-asset/image-asset";

export type HeaderOption = {
   hide: boolean;
   searchBar: boolean;
   logout: boolean;
};
@HostListener("window:scroll", ["$event"])
@Component({
   selector: "launcher-header",
   templateUrl: "./header.component.html"
})
export class HeaderComponent implements AfterViewInit {
   public search: string;

   private settingDialogId;
   private closeAboutDialog;
   public host: string;
   public showAllStyle: string;
   public showAllOn: boolean;
   public showFavStyle: string;
   public showFavOn: boolean;
   public imageAsset = imageAsset;
   public linkRelValue: string;
   @Input() isChromeWS1Mode: boolean;
   @Input() option: HeaderOption;
   public focus: boolean;
   public blur: boolean;
   public showPreference: boolean;
   public totalnum: number;
   private tooltipTop: number;

   isSettingDialogDisplay: boolean;

   //output
   // is favorite
   @Output() targetViewChange = new EventEmitter<boolean>();
   @Output() searchChange = new EventEmitter<string>();
   @Output() settingDialogStatus = new EventEmitter<boolean>();
   public onlyFavorites: boolean;

   constructor(
      private connectionServerModel: ConnectionServerModel,
      private utilService: UtilService,
      private idleSessionService: IdleSessionService,
      private eventBusService: EventBusService,
      private modalDialogService: ModalDialogService,
      private anonymousService: AnonymousService
   ) {
      this.anonymousService.subscribe((mode) => {
         this.showPreference = !mode;
      });
      // Portal URL.
      this.host = "https://" + this.connectionServerModel.host;
      this.showAllStyle = "show-all-on";
      this.showAllOn = true;
      this.showFavStyle = "show-fav-off";
      this.showFavOn = false;
      this._setOnlyFavorites(false);
      // Bind close dialog method.
      $("#closeAboutBtn").on("click", this.closeAboutDialog);
      // Focus on search input on receiving searchFocus event.
      this.eventBusService.listen(BusEvent.SearchFocusMsg.MSG_TYPE).subscribe(() => {
         $("#header-search").focus();
      });

      // I think we need this listener to close setting dialog when time out
      this.idleSessionService.addEventListener("idleSessionTimeout", () => {
         if (this.settingDialogId && this.modalDialogService.isDialogOpen(this.settingDialogId)) {
            this.modalDialogService.close(this.settingDialogId);
         }
      });

      this.linkRelValue = __REL_FOR_LINK__;
      this.search = "";
   }

   ngAfterViewInit() {
      jQuery(document).ready(function ($) {
         $(".navbar-right button").tooltip({
            close: function () {
               $(".ui-helper-hidden-accessible > *:not(:last)").remove();
            }
         });
      });
      window.addEventListener("scroll", this.scroll, true);
      if (clientUtil.isAndroid() || clientUtil.isIOS()) {
         this.toolTipSolution();
      }
   }

   scroll = (evt): void => {
      if ($(".ui-tooltip")[0]) {
         if (this.tooltipTop === undefined) {
            this.tooltipTop = $(".ui-tooltip").position().top;
         }

         if (evt.srcElement.scrollTop === 0) {
            $(".ui-tooltip").css("top", this.tooltipTop);
         } else {
            $(".ui-tooltip").css("top", this.tooltipTop - evt.srcElement.scrollTop);
         }
      }
   };

   private toolTipSolution = () => {
      //This if for bug 2977390
      $(".navbar-right button").on("touchstart", (eve) => {
         console.log(eve.target.parentElement);
         const className = eve.target.parentElement.className;
         if (className.indexOf("show-all") > -1) {
            $("#showAllBtn").tooltip({
               disabled: false
            });
            $("#showFavoritesBtn").tooltip({
               disabled: true
            });
         } else if (className.indexOf("show-fav") > -1) {
            $("#showAllBtn").tooltip({
               disabled: true
            });
            $("#showFavoritesBtn").tooltip({
               disabled: false
            });
         } else {
            $("#showAllBtn").tooltip({
               disabled: true
            });
            $("#showFavoritesBtn").tooltip({
               disabled: true
            });
         }
      });
   };

   // Logout function.
   public logout = () => {
      this.utilService.showLogoutDialog();
   };

   private _setOnlyFavorites = (onlyFavorites) => {
      this.targetViewChange.emit(onlyFavorites);
      this.onlyFavorites = onlyFavorites;
   };

   public onSearchChanged = (searchText) => {
      this.searchChange.emit(searchText);
      if (searchText !== "") {
         setTimeout(() => {
            this.totalnum = $(".ui-desktop-corner").length;
         }, 2500);
      }
   };

   public showAll = () => {
      this._setOnlyFavorites(false);
      this.showAllStyle = "show-all-on";
      this.showAllOn = true;
      this.showFavStyle = "show-fav-off";
      this.showFavOn = false;
   };

   public showAllFocus = () => {
      if (this.onlyFavorites === false) {
         this.showAllStyle = "show-all-on-focus";
         this.showAllOn = true;
      } else {
         this.showAllStyle = "show-all-off-focus";
         this.showAllOn = false;
      }
   };

   public showAllBlur = () => {
      if (this.onlyFavorites === false) {
         this.showAllStyle = "show-all-on";
         this.showAllOn = true;
      } else {
         this.showAllStyle = "show-all-off";
         this.showAllOn = false;
      }
   };

   public showFavorites = () => {
      this._setOnlyFavorites(true);
      this.showAllStyle = "show-all-off";
      this.showAllOn = false;
      this.showFavStyle = "show-fav-on";
      this.showFavOn = true;
   };

   public showFavoritesFocus = () => {
      if (this.onlyFavorites === true) {
         this.showFavStyle = "show-fav-on-focus";
         this.showFavOn = true;
      } else {
         this.showFavStyle = "show-fav-off-focus";
         this.showFavOn = false;
      }
   };

   public showFavoritesBlur = () => {
      if (this.onlyFavorites === true) {
         this.showFavStyle = "show-fav-on";
         this.showFavOn = true;
      } else {
         this.showFavStyle = "show-fav-off";
         this.showFavOn = false;
      }
   };

   public showSetting = () => {
      this.settingDialogId = this.modalDialogService.showSetting(() => {
         this.settingDialogStatus.emit(false);
      });
      this.settingDialogStatus.emit(true);
   };

   public clearSearch = () => {
      this.search = "";
      this.searchChange.emit(this.search);
   };

   public showHelp = () => {
      this.modalDialogService.showAbout();
   };

   /**
    * When dialog is closed, the focus could go back to header
    * element. And since keydown is listened on button, this would
    * causes no issue.
    * We would response to key holdings, when browser fires multiple down events.
    */
   public enterKeypress = (evt, callback) => {
      if (!!evt && evt.keyCode === 13 && typeof callback === "function") {
         // Enter key is pressed.
         callback();
         // Prevent default behavior.
         evt.preventDefault();
      }
   };

   public searchClearKeydown = (event) => {
      if (!!event && event.keyCode === 13) {
         event.preventDefault();
         event.stopPropagation();
         this.clearSearch();
      }
   };
}
