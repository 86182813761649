<div class="dialog-title">{{(title) || ('ERROR' | i18nv2)}}</div>
<div class="dialog-content selectable">
   <div class="session-ops-window-text">{{errorMessage}}</div>
</div>
<div class="dialog-button-row">
   <button class="modal-button-base modal-button-right modal-button-blue" (click)="confirmModal()" id="closeDialogBtn"
      [autoFocus]>
      {{(buttonLabel) || ('OK' | i18nv2)}}
   </button>
   <button *ngIf="titanClient && showCopy" class="modal-button-base modal-button-right modal-button-grey" (click)="confirmCopy()"
      id="copyDialogBtn">
      <div class="copy-icon" *ngIf="!copied"></div>
      <div class="copy-text" *ngIf="!copied">
         {{('COPY' | i18nv2)}}
      </div>
      <div class="copy-check-icon" *ngIf="copied"></div>
   </button>
</div>