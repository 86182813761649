/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable } from "@angular/core";
import { TranslateService } from "@html-core";
import { Localize } from "../../../shared/common/service/localize.service";

@Injectable()
export class HTML5TranslateService extends TranslateService {
   constructor(private localize: Localize) {
      super();
   }

   public _T = (...args: any[]) => {
      return this.localize.translate(...args);
   };
}
