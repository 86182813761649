/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * vertical-slider.js --
 *
 * Module to implement a UI element that can be slid up and down the
 * entire document. The element is packaged as a directive named
 * verticalSlider.
 * The directive is responsible for allowing the use to move its contents by
 * dragging them. The directive also accepts allows the developer to configure
 * what action will be taken when the user clicks on the element but does not
 * move it.
 *
 * The actual visuals of the element are controlled via HTML and CSS.
 *
 * The directive expects the following attributes to be defined:
 *
 *   on-click = an expression that will be evaluated when the element is
 * clicked on on-move = an expression that will be evaluated when the element
 * is moved element-drag-styles = a list of css classes that will be toggled on
 * the element when it is dragged. body-drag-styles = a list of css classes
 * that will be toggled on the HTML body when the element is dragged.
 *
 * Sample use:
 *
 *     <vertical-slider class="panel-toggle-tab pointer-cursor"
 *               on-click="$emit('togglePanel')"
 *               on-move="$emit('sliderMoveStart')"
 *               element-drag-styles="pointer-cursor resize-cursor"
 *               body-drag-styles="resize-cursor">
 *        <img src="img/tab.png"/>
 *     </vertical-slider>
 *
 * Limitations:
 *    - The element can only be moved vertically and it moves the entire height
 * of the document body.
 *    - Double clicking is not supported.
 *
 */

import { Component, ElementRef, EventEmitter, Output, HostListener } from "@angular/core";
import { AB } from "../common/appblast-util.service";
import { ViewClientModel } from "../../common/model/viewclient-model";

@Component({
   selector: "vertical-slider",
   template: `<ng-content></ng-content>`
})
export class VerticalSliderDirective {
   private element;
   private y;
   private moving: boolean = false;
   private moved: boolean = false;
   private startY: number = 0;
   private body;
   private documentListener: boolean;

   @Output() onClick = new EventEmitter();
   @Output() onMoved = new EventEmitter();

   @HostListener("mousedown", ["$event"]) handleMouseDown(event) {
      // Prevent default dragging of selected content
      event.preventDefault();
      this.startY = event.pageY - this.y;
      this.moving = true;
      this.moved = false;

      this.onMoved.emit();
      this.documentListener = true;

      $(this.element).toggleClass(this.element.getAttribute("element-drag-styles"));
      this.body.toggleClass(this.element.getAttribute("body-drag-styles"));
   }
   @HostListener("document:mouseup", ["$event"]) handleMouseUp(event) {
      if (this.documentListener) {
         this.sidebarMouseUp(event);
      }
   }
   @HostListener("document:mousemove", ["$event"]) handleMouseMove(event) {
      if (this.documentListener) {
         this.sidebarMouseMove(event);
      }
   }

   @HostListener("touchstart", ["$event"]) handleTouchEvent(event) {
      // Prevent default dragging of selected content
      event.preventDefault();
      this.startY = event.touches[0].pageY - this.y;
      this.moving = true;
      this.moved = false;

      this.onMoved.emit();
      this.documentListener = true;

      $(this.element).toggleClass(this.element.getAttribute("element-drag-styles"));
      this.body.toggleClass(this.element.getAttribute("body-drag-styles"));
   }
   @HostListener("document:touchmove", ["$event"]) handleDocumentTouchMove(event) {
      if (this.documentListener) {
         this.sidebarMouseMove(event);
      }
   }
   @HostListener("document:touchend", ["$event"]) handleDocumentTouchEnd(event) {
      if (this.documentListener) {
         this.sidebarMouseUp(event);
      }
   }

   constructor(
      private viewClientModel: ViewClientModel,
      private _el: ElementRef
   ) {
      this.element = this._el.nativeElement;
      this.body = $("body");
   }

   ngAfterViewInit() {
      this.y = this.clamp(this.maxYValue() / 2);
      $(this.element).css({
         top: this.y + "px"
      });
      window.addEventListener("resize", this.resizeHandler);
   }

   ngAfterDestroy() {
      window.removeEventListener("resize", this.resizeHandler);
      if (this.moving) {
         this.documentListener = false;
      }
   }

   private maxYValue = () => {
      return window.innerHeight - this.element.offsetHeight;
   };

   private clamp = (y) => {
      return AB.clamp(y, 0, this.maxYValue());
   };

   public resizeHandler = () => {
      this.y = this.clamp(this.y);
      $(this.element).css({
         top: this.y + "px"
      });
   };

   public sidebarMouseMove = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const oldY = this.y;
      if (this.viewClientModel.mIsAndroid) {
         this.y = event.changedTouches[0].pageY - this.startY;
      } else {
         this.y = event.pageY - this.startY;
      }

      this.y = this.clamp(this.y);

      if (this.y !== oldY) {
         this.moved = true;
         $(this.element).css({
            top: this.y + "px"
         });
      }
   };

   public sidebarMouseUp = (event) => {
      if (!this.moved) {
         this.onClick.emit();
      }
      this.documentListener = false;
      $(this.element).toggleClass(this.element.getAttribute("element-drag-styles"));
      this.body.toggleClass(this.element.getAttribute("body-drag-styles"));
      this.moving = false;
   };
}
