/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { HorizonUsb, RawImpl, USB_TYPE } from "../../desktop/usb/usblib";
import { EventBusService, Logger } from "@html-core";
import { Injectable, Optional } from "@angular/core";
import { AutoForwardPolicyService } from "./auto-forward-usb-policy.service";
import { LaunchItemsCtrlService } from "../launchitems/launch-item-ctrl.service";
import { Port } from "../../../chrome-client/base/model/rx-bridge-type";
import { ConnectedMessageService } from "../../../chrome-client/base/service/connected-message.service";
import { NotificationType, usb } from "../../desktop/usb/usb.event";

export interface USBRaw {
   productName: string;
   vendorId: string;
   productId: string;
   autoConnectOnStart?: boolean;
   autoConnectOnInsert?: boolean;
}
@Injectable()
export class AutoFowardUSBService {
   public devicePolicy: Array<any>;
   private _onStartUpDevicesMap = new Map<string, Array<any>>();
   private _onInsertedDevicesMap = new Map<string, Array<any>>();
   private redirectedDeviceMap = new Map<string, string>();
   private _policyMap = new Map<string, any>();
   private _focusedSession: string;
   private _focusedDesktopName: string;
   private logger = new Logger(Logger.AUTO_USB);
   private _lastInsertedDevice;
   private _lastTimeStamp;

   constructor(
      private policyService: AutoForwardPolicyService,
      private connectedMessageService: ConnectedMessageService,
      private eventBusService: EventBusService,
      @Optional()
      private launchItemsCtrlService: LaunchItemsCtrlService
   ) {
      if (chrome.app.window.current().id === "HTMLAccessChromeWindow") {
         HorizonUsb.onConnect(USB_TYPE.CHROME_USB, this._onDeviceConnectCallBack);
      }
   }

   private _onDeviceConnectCallBack = (device) => {
      if (!this.getKillSwitch()) {
         return;
      }
      if (
         this._lastInsertedDevice &&
         this._lastInsertedDevice.raw.vendorId === device.raw.vendorId &&
         this._lastInsertedDevice.raw.productId === device.raw.productId
      ) {
         //This is a workaround for chrome.usb.api issue,
         //it returns duplicated device when inserted on some devices.
         //So we only handle one device if duplicated devices arrives in 1s.
         const currentTime = new Date().getTime();
         if (currentTime - this._lastTimeStamp < 1000) {
            this._lastInsertedDevice = null;
            this._lastTimeStamp = 0;
            this.logger.debug("Duplicated device got from api in 1s");
            return;
         }
      } else {
         this._lastTimeStamp = new Date().getTime();
         this._lastInsertedDevice = device;
      }

      const isDeviceRedirected = this.isDeviceRedirected(device);
      if (!isDeviceRedirected) {
         let onInsertList = [];
         if (this._isGlobalPolicyExist()) {
            if (this._onInsertedDevicesMap.has("HorizonAutoUsbAll")) {
               onInsertList = this._onInsertedDevicesMap.get("HorizonAutoUsbAll");
            }
         } else {
            if (this._onInsertedDevicesMap.has(this._focusedDesktopName)) {
               onInsertList = this._onInsertedDevicesMap.get(this._focusedDesktopName);
            }
         }

         if (onInsertList[0] === "All" || this._isDeviceInList(onInsertList, device)) {
            this.connectedMessageService.sendMessage(this._focusedSession, Port.ChannelName.USB, {
               type: Port.USBMsg.deviceOnInsert,
               content: {
                  vid: device.raw.vendorId,
                  pid: device.raw.productId
               }
            });
         }
      }
   };

   public updatePolicy = () => {
      const isFromPolicy = this.policyService.isFromPolicy;
      if (isFromPolicy) {
         this._policyMap = this.policyService.getGoogleSetting();
      } else {
         this._policyMap = this.policyService.generatePolicyMap();
      }
      this._updateOnInsertList();
      this._updateOnStartUpList();
   };

   public getPolicy = () => {
      this.updatePolicy();
      return this._policyMap;
   };

   private _getDesktopPolicy = (desktopName) => {
      const globalSetting = this._getGlobalPolicy();
      let desktopPolicy;
      if (globalSetting) {
         this.logger.debug("Global policy is enabled");
         desktopPolicy = globalSetting;
         this.logger.debug("Global policy is: " + JSON.stringify(desktopPolicy));
      } else if (this._policyMap.has(desktopName)) {
         this.logger.debug("Global policy is disabled");
         desktopPolicy = this._policyMap.get(desktopName);
         this.logger.debug("desktop policy is: " + JSON.stringify(desktopPolicy));
      }
      return desktopPolicy;
   };

   private _updateOnInsertList = (): void => {
      const desktopNames = Array.from(this._policyMap.keys());
      desktopNames.forEach((desktopName: string) => {
         let onInsertDeviceList = [];
         const desktopPolicy = this._getDesktopPolicy(desktopName);
         if (desktopPolicy) {
            const devicePolicyList = desktopPolicy.devicePolicy;
            if (devicePolicyList) {
               if (desktopPolicy.hasOwnProperty("autoConnectAllOnInsert") && desktopPolicy.autoConnectAllOnInsert) {
                  this.logger.debug("OnStart for all devices is enabled, add all api devices in onStart list");
                  onInsertDeviceList = ["All"];
               } else {
                  for (let i = 0; i < devicePolicyList.length; i++) {
                     if (devicePolicyList[i].autoConnectOnInsert) {
                        onInsertDeviceList.push(devicePolicyList[i]);
                     }
                  }
               }
            }
            this._onInsertedDevicesMap.set(desktopName, onInsertDeviceList);
         }
      });
   };

   private _updateOnStartUpList = (): void => {
      const desktopNames = Array.from(this._policyMap.keys());
      desktopNames.forEach((desktopName: string) => {
         let onStartUpDeviceList = [];
         const desktopPolicy = this._getDesktopPolicy(desktopName);
         if (desktopPolicy) {
            const devicePolicyList = desktopPolicy.devicePolicy;
            if (devicePolicyList) {
               if (desktopPolicy.hasOwnProperty("autoConnectAllOnStart") && desktopPolicy.autoConnectAllOnStart) {
                  this.logger.debug("OnStart for all devices is enabled, add all api devices in onStart list");
                  onStartUpDeviceList = ["All"];
               } else {
                  for (let i = 0; i < devicePolicyList.length; i++) {
                     if (devicePolicyList[i].autoConnectOnStart) {
                        onStartUpDeviceList.push(devicePolicyList[i]);
                     }
                  }
               }
            }
            this._onStartUpDevicesMap.set(desktopName, onStartUpDeviceList);
         }
      });
   };

   private _createFilterList = (apiDevice, targetDevice, filteredList): boolean => {
      if (!this.isDeviceRedirected(apiDevice)) {
         this.logger.debug(
            "Device hasn't been redirected to other desktop, " + apiDevice.raw.vendorId + ":" + apiDevice.raw.productId
         );
         if (!targetDevice) {
            filteredList.push(apiDevice);
            return true;
         }

         if (
            targetDevice &&
            targetDevice.vendorId === String(apiDevice.raw.vendorId) &&
            targetDevice.productId === String(apiDevice.raw.productId)
         ) {
            this.logger.debug(
               "Add api device in onStartList: " + apiDevice.raw.vendorId + ":" + apiDevice.raw.productId
            );
            filteredList.push(apiDevice);
            return true;
         }
      } else {
         this.logger.debug("Device is redirected, " + apiDevice.raw.vendorId + ":" + apiDevice.raw.productId);
         this.eventBusService.dispatch(
            new usb.NotificationMsg(NotificationType.ALREADY_REDIRECT_MSG, apiDevice.raw.productName)
         );
      }
      return false;
   };

   public getAutoConnectDeviceList = (desktopName) => {
      return new Promise((reslove, reject) => {
         let deviceList = [];
         if (this._isGlobalPolicyExist()) {
            if (this._onStartUpDevicesMap.has("HorizonAutoUsbAll")) {
               deviceList = this._onStartUpDevicesMap.get("HorizonAutoUsbAll");
            }
         } else {
            if (this._onStartUpDevicesMap.has(desktopName)) {
               deviceList = this._onStartUpDevicesMap.get(desktopName);
            }
         }

         if (deviceList && deviceList.length > 0) {
            const filteredList = [];

            this.getDevicesFromAPI().then((apiDevices) => {
               apiDevices.forEach((apiDevice) => {
                  if (deviceList[0] === "All") {
                     this._createFilterList(apiDevice, null, filteredList);
                  } else {
                     for (let i = 0; i < deviceList.length; i++) {
                        if (this._createFilterList(apiDevice, deviceList[i], filteredList)) {
                           break;
                        }
                     }
                  }
               });
               reslove(filteredList);
            });
         } else {
            reslove([]);
         }
      });
   };

   private _isGlobalPolicyExist = (): boolean => {
      return (
         this._policyMap &&
         this._policyMap.has("HorizonAutoUsbAll") &&
         this._policyMap.get("HorizonAutoUsbAll") &&
         Object.keys(this._policyMap.get("HorizonAutoUsbAll")).length > 0
      );
   };

   private _getGlobalPolicy = () => {
      if (this._isGlobalPolicyExist()) {
         const globalPolicy: AutoUsbPolicyPerDesktop = this._policyMap.get("HorizonAutoUsbAll");
         if (globalPolicy && Object.keys(globalPolicy).length > 0) {
            return this._policyMap.get("HorizonAutoUsbAll");
         }
      }
      return false;
   };

   public getDevicesFromAPI = (): Promise<Array<RawImpl>> => {
      return new Promise((resolve, reject) => {
         HorizonUsb.getDevices(USB_TYPE.CHROME_USB)
            .then((devices) => {
               this.logger.info("Successfully get devices from HorizonUsb.getDevices");
               resolve(devices);
            })
            .catch((e) => {
               this.logger.warning("HorizonUsb.getDevices API failed");
               this.logger.exception(e);
            });
      });
   };

   public updateFocusSession = (wmksKey) => {
      this.logger.debug("Update focused session: " + wmksKey);
      this._focusedSession = wmksKey;
      const desktops = this.launchItemsCtrlService.getDesktops();
      for (let i = 0; i < desktops.length; i++) {
         if (desktops[i].id === wmksKey) {
            this.logger.debug("Get session key: " + desktops[i].id);
            this._focusedDesktopName = desktops[i].name;
            break;
         }
      }
   };

   public updateRedirectedMap = (wmksKey, device, isRedirected) => {
      const deviceKey = device.deviceId + ":" + device.vendorId + ":" + device.productId;
      if (isRedirected) {
         this.redirectedDeviceMap.set(deviceKey, wmksKey);
         this.logger.debug("Added in redirected list: " + deviceKey);
      } else {
         if (this.redirectedDeviceMap.has(deviceKey)) {
            this.redirectedDeviceMap.delete(deviceKey);
         }
         this.logger.debug("Removed in redirected list: " + deviceKey);
      }
   };

   public updateRedirectedMapOnSessionClosed = (wmksKey) => {
      this.redirectedDeviceMap.forEach((value, key) => {
         if (value === wmksKey) {
            this.redirectedDeviceMap.delete(key);
         }
      });
      this.logger.debug("All redirected device has been update when session is closed: " + wmksKey);
   };

   public isDeviceRedirected = (device): boolean => {
      const deviceKey = device.raw.vendorId + ":" + device.raw.productId;
      const res = this.redirectedDeviceMap.get(deviceKey);
      if (res) {
         this.logger.info(deviceKey + "is redirected to : " + res);
      } else {
         this.logger.info(deviceKey + "is not redirected");
      }
      return !!res;
   };

   private _isDeviceInList = (deviceList, device): boolean => {
      for (let i = 0; i < deviceList.length; i++) {
         if (
            deviceList[i].vendorId === String(device.raw.vendorId) &&
            deviceList[i].productId === String(device.raw.productId)
         ) {
            return true;
         }
      }
      return false;
   };

   public getKillSwitch = (): boolean => {
      return this.policyService.getKillSwitch();
   };

   public updateKillSwitchStatusToStorage = (status) => {
      this.policyService.updateSettingToStorage(null, status);
   };
}
