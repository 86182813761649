/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * doLockCommand.js --
 *
 *      Called to lock the broker. Should be triggered by
 *      idle timeout if the user disactive for a long time.
 *
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import DoLockHandler from "../controllers/doLockHandler";

export default function DoLockCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param DoLockAction[in]
 *        - its property 'method' has the value 'DoLock'
 */
DoLockCommand.prototype.execute = function (DoLockAction) {
   let doLockObject,
      handlerList,
      router = globalArray["router"];
   if (!util.brokerSupportApplication()) {
      return;
   }
   doLockObject = globalArray["do-lock"];
   if (!doLockObject) {
      doLockObject = new DoLockHandler();
      globalArray[doLockObject.messageName] = doLockObject;
      globalArray[doLockObject.responseTag] = doLockObject;
   } else {
      doLockObject.resetData();
   }

   if (!!doLockObject && !!router) {
      doLockObject.setRequestXML();
      handlerList = doLockObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};
