/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { ClientSettingModel } from "../model/client-setting-model";
import Logger from "../../../core/libs/logger";
import { ModalDialogService } from "../commondialog/dialog.service";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { GoogleCommonSettings } from "../../../chrome-client/launcher/server-connect/google-common-settings.service";

@Injectable()
export class MediastreamNotification {
   private localStorageService: LocalStorageService = null;

   //At the start of dialog checkbox is always checked.
   private checkboxChecked: boolean = true;
   constructor(
      private clientSettingModel: ClientSettingModel,
      private modalDialogService: ModalDialogService,
      private googleCommonSettings: GoogleCommonSettings
   ) {
      this.localStorageService = new LocalStorageService();
   }

   /**
    * Abstract function to show media sream dialog.
    * Dialog will be shown max once per session,
    * In case do not show the dialog again is selected, it will not appear again.
    * Dialog can reappear by making apropriate selection from the Settings menu.
    **/
   public showMediaStreamNotificationIfNeeded = async () => {
      if (this.shouldShowMediaStreamNotification() && this.googleCommonSettings.isBCRMediaStreamEnabled()) {
         await this.showNotification();
      }
      return this.isMediaStreamPermissionEnabled() ? Promise.resolve() : Promise.reject();
   };

   /**
    * Seeking permission from user before accessing the media stream like camera or mic is a legal requirement.
    * showNotification will show a one time dialog before BCR starts and ask user permission to allow/deny media access.
    * Below function is a synchronous function, i.e. execution will be blocked untill user makes a selection.
    *
    * @param null
    * @return Promise
    */
   private showNotification = async (): Promise<void> => {
      return await new Promise((resolve, reject) => {
         const option = {
            data: {
               titleKey: "MEDIASTREAM_PERMISSION_POPUP_TITLE",
               buttonLabelConfirmKey: "GEOPERMISSION_POPUP_ALLOW",
               buttonLabelCancelKey: "GEOPERMISSION_POPUP_DENY",
               checkboxTitleKey: "DO_NOT_SHOW_THIS_DIALOG_AGAIN",
               isCheckBoxCheckedKey: "true"
            },
            callbacks: {
               confirm: () => {
                  this.setEnableMediaStreamPermission(true);
                  Logger.info("Camera & mic permission access allowed from confimation popup.", Logger.BCR);
                  return resolve();
               },
               cancel: () => {
                  this.setEnableMediaStreamPermission(false);
                  Logger.info("Camera & mic permission access denied from confimation popup.", Logger.BCR);
                  return reject();
               },
               setCheckBoxValue: (enabled: boolean) => {
                  this.checkboxChecked = enabled;
               }
            }
         };
         Logger.info("Show media stream permission pop-up", Logger.BCR);
         this.setMediastreamDialogAppearedOnce(true);
         this.modalDialogService.showCancelConfirmWithCheckBox(option);
      });
   };

   private setDonotShowMediaStreamPermissionDialog = (): void => {
      const status = this.checkboxChecked ? "true" : "false";
      Logger.info("Setting do not show this dialog again for camera & mic access to " + status, Logger.BCR);
      this.clientSettingModel.updateSetting("donotShowMediaStreamPermissionDialog", status, true);
      this.clientSettingModel.syncSetting(["donotShowMediaStreamPermissionDialog"]);
   };

   private setEnableMediaStreamPermission = (enabled: boolean): void => {
      this.clientSettingModel.updateSetting("enableMediaStreamPermission", enabled ? "true" : "false", true);
      this.clientSettingModel.syncSetting(["enableMediaStreamPermission"]);
      this.setDonotShowMediaStreamPermissionDialog();
   };

   private shouldShowMediaStreamNotification = (): boolean => {
      return (
         !this.clientSettingModel.getBooleanItem("donotShowMediaStreamPermissionDialog") &&
         !this.getMediastreamDialogAppearedOnce()
      );
   };

   public isMediaStreamPermissionEnabled = (): boolean => {
      return (
         this.googleCommonSettings.isBCRMediaStreamEnabled() &&
         this.clientSettingModel.getBooleanItem("enableMediaStreamPermission")
      );
   };

   private setMediastreamDialogAppearedOnce = (appeared: boolean): void => {
      const status = appeared ? "true" : "false";
      Logger.info("Setting mediastreamDialogAppearedOnce flag to: " + status, Logger.BCR);
      this.localStorageService.set("mediastreamDialogAppearedOnce", status);
   };

   private getMediastreamDialogAppearedOnce = (): boolean => {
      return this.localStorageService.get("mediastreamDialogAppearedOnce") === "true";
   };
}
