/**
 * ******************************************************
 * Copyright (C) 2016-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { globalArray } from "../../jscdk/jscdkClient";
import Logger from "../../../core/libs/logger";
import { clientUtil, LocalStorageService } from "@html-core";
import { ViewClientModel } from "../model/viewclient-model";
import { ConnectionServerModel } from "../model/connection-server-model";
import { ReconnectService } from "../../desktop/common/reconnect.service";
import { Injectable } from "@angular/core";
import { UserGlobalPref } from "./user-global-pref";

@Injectable({
   providedIn: "root"
})
export class CredCleanService {
   private _isPageJump: boolean;
   private static readonly CLEAN_TIME_KEY = "CC_TIME";
   constructor(
      private userGlobalPref: UserGlobalPref,
      private viewClientModel: ViewClientModel,
      private connectionServerModel: ConnectionServerModel,
      private reconnectService: ReconnectService,
      private localStorageService: LocalStorageService
   ) {
      this._isPageJump = false;
   }

   public init = () => {
      if (!this.viewClientModel.enableCredentialCleanupForHTMLAccess || clientUtil.isChromeClient()) {
         Logger.debug("Credential cleanup is not enabled");
         return;
      }
      Logger.debug("Credential cleanup is enabled");

      this._isPageJump = false;
      window.addEventListener("beforeunload", () => {
         this._actionForPageTearDown();
      });
      window.addEventListener("unload", () => {
         if (this.viewClientModel.mIsIOSSafari || this.viewClientModel.mIsAirWatchBrowser) {
            this._actionForPageTearDown();
         }
      });
   };

   private _actionForPageTearDown = () => {
      if (!this._isPageJump) {
         this._sendLogoutAction();
      }
   };

   private _sendLogoutAction = () => {
      const uiController = globalArray["uiController"];
      const sendFetch = !!window.fetch;
      if (uiController) {
         uiController.invokeJSCDK({
            method: "LogoutFromBroker",
            prefData: this.userGlobalPref.toFlatFormat(),
            async: false,
            timeout: 2000,
            sendFetch: sendFetch
         });
         window.localStorage.setItem(CredCleanService.CLEAN_TIME_KEY, new Date().getTime().toString());
         // Clear session cookies.
         this.localStorageService.clearSession();
         // Reset server model.
         this.connectionServerModel.reset();
         // Clear WS1 desktop sessions
         this.reconnectService.clear();
      }
   };

   public setFlagBeforePageJumping = () => {
      // This function can be removed after launcher/desktop page merged
      this._isPageJump = true;
   };

   /**
    * Each time before initing client check whether need to wait for a
    * while for credential clean up to be finished with large chance.
    * see details in bug 2572799
    */
   public waitForCredentialClean = (callback: Function) => {
      if (!window.localStorage) {
         callback();
         return;
      }
      const minialWaitTime: number = 3 * 1000;
      const cleanTime: string = window.localStorage.getItem(CredCleanService.CLEAN_TIME_KEY);
      let cleanTimeNum: number = 0;
      if (!cleanTime) {
         cleanTimeNum = 0;
      } else {
         cleanTimeNum = Number(cleanTime);
      }
      const currentTime = new Date().getTime();
      if (currentTime > cleanTimeNum + minialWaitTime) {
         callback();
      } else {
         const waitTime: number = cleanTimeNum + minialWaitTime - currentTime;
         Logger.info("detect recent credential clean up, wait for " + waitTime + "ms");
         setTimeout(callback, waitTime);
      }
   };
}
