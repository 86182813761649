/**
 * ******************************************************
 * Copyright (C) 2012-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * killSessionHandler.js --
 *
 *      Implementation of the message handler to kill session.
 *      current used type is only of Desktop And All Application
 *      sessions, the "ApplicationSession"(not recommended) or
 *      "Application" is the preserved API for later function
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";
import Router from "./router";
import { ErrorObj } from "../jscdk-interface";
import { getPartialMatchedKey } from "@html-core";

export default function KillSessionHandler() {
   let router;

   // member variables below
   this.messageName = "kill-session";
   this.messageText = "kill-session";
   this.responseTag = "kill-session";
   this.composedHandlerList = [];

   this.itemId = "";
   this.itemType = "";
   this.callbackFunction = null;

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
}

// inherits MessageHandler prototype
KillSessionHandler.prototype = new MessageHandler();
// constructor
KillSessionHandler.constructor = KillSessionHandler;

KillSessionHandler.prototype.setCallbackFunction = function (callbackFunction) {
   this.callbackFunction = callbackFunction;
};

/**
 * parse information from the response XML of kill-session
 *
 * @param responseXML[in] response of XMLHttpRequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
KillSessionHandler.prototype.parseResult = function (responseXML) {
   let killSessionResponse = {},
      xmlDoc = $(responseXML),
      brokerTag,
      responses,
      result;

   brokerTag = $(xmlDoc.children()[0]);
   if (!brokerTag) {
      Logger.error("response of killSessionHandler error");
      return null;
   }

   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   if (result === "ok" || result === "error") {
      /*
       * Add the "ok" and "error" response to the response JSON struct,
       * because for the "ok" and "error" result, the response XML structure
       * are the same, the following code can work in both cases.
       */
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(killSessionResponse, nameText, $(this).text());
      });
   }

   return killSessionResponse;
};

/**
 * Currently we use id for single session and session-id for multi-session
 * and session-id don't include the BLAST protocol part
 */
KillSessionHandler.prototype.isForMultiSession = function () {
   const isMultiSessionId = !this.sessionId.includes("BLAST");
   return isMultiSessionId;
};

/**
 * Set the request XML for kill-session
 * @param sessionId[in] session id to kill-session
 *
 */
KillSessionHandler.prototype.setRequestXML = function (sessionId) {
   let sessionIdElem;
   sessionIdElem = util.createElement("session-id", sessionId);
   this.requestXML = sessionIdElem;
   this.sessionId = sessionId;
};

/**
 * callback when received notification from handlers in dependency list or
 * router
 *
 */
KillSessionHandler.prototype.onUpdated = function () {
   let killSessionAction,
      callbackFunction = this.callbackFunction,
      key = this.messageName;

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's
   // onUpdated

   if (typeof callbackFunction !== "function") {
      if (this.isForMultiSession()) {
         // treat as kill session succeeds
         Logger.info("out-dated multi-session detected, it should has been destroyed by server");
         // Kill session succeeds
         killSessionAction = {
            name: "KillSession",
            result: "ok"
         };
         JSCDKSetUI(JSON.stringify(killSessionAction));
      } else {
         if (this.state === StateEnum.DONE) {
            if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
               Logger.debug("kill-session succeeds.");

               // Kill session succeeds
               killSessionAction = {
                  name: "KillSession",
                  result: "ok"
               };
               JSCDKSetUI(JSON.stringify(killSessionAction));
            }
         }

         // push error if it exists
         Router.prototype.pushErrorToUser(this);
      }
   } else {
      if (this.state === StateEnum.DONE) {
         if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
            Logger.debug("kill-session succeeds.");

            // Kill session succeeds
            callbackFunction({
               result: "success"
            });
         }
      }
      if (this.content["error"]) {
         if (this.isForMultiSession()) {
            // treat as kill session succeeds
            Logger.info("out-dated multi-session detected, it should has been destroyed by server");
            callbackFunction({
               result: "success"
            });
         } else {
            callbackFunction({
               result: "fail",
               error: this.content["error"][key],
               sessionId: this.sessionId
            });
         }
      }
      callbackFunction = null;
   }
};

/**
 * Triggered by the parent handler
 *
 */
KillSessionHandler.prototype.triggerExecution = function () {
   let getItemsObject,
      desktops,
      applications,
      applicationSessions,
      targetDesktop,
      targetApplication,
      targetApplicationSession,
      sessionId,
      handlerList,
      itemId = this.itemInfo.id,
      launchItemTypeIsDesktop = this.itemInfo.isDesktop,
      router = globalArray["router"],
      errorMessage,
      errorObj = {} as ErrorObj;

   MessageHandler.prototype.triggerExecution.apply(this);

   // Get session ID of the desktop whose id equals itemId.
   getItemsObject = this.parentHandler;
   if (!getItemsObject) {
      if (!util.brokerSupportApplication()) {
         getItemsObject = globalArray["get-desktops" + itemId];
      } else {
         getItemsObject = globalArray["get-launch-items" + itemId];
      }
   }
   if (!!getItemsObject && !!getItemsObject.content["parsedResult"]) {
      desktops = getItemsObject.content["parsedResult"]["desktops"];
      applications = getItemsObject.content["parsedResult"]["applications"];
      applicationSessions = getItemsObject.content["parsedResult"]["application-sessions"];
   }
   if (launchItemTypeIsDesktop && !!desktops) {
      // using this function, for URI launching might use desktop name or its id
      targetDesktop = getItemsObject.findDesktop(itemId, desktops);
      if (targetDesktop) {
         sessionId = targetDesktop["session-id"];
      }
   }
   if (!launchItemTypeIsDesktop && !!applications && !!applicationSessions) {
      let key;
      const sessionIdMap = {};
      for (key in applicationSessions) {
         if (
            applicationSessions.hasOwnProperty(key) &&
            !!applicationSessions[key] &&
            !!applicationSessions[key]["session-id"]
         ) {
            sessionIdMap[applicationSessions[key]["session-id"]] = "";
         }
      }
      const targetItemId = getPartialMatchedKey(sessionIdMap, itemId);
      targetApplicationSession = util.findFirstItemWithPropertyInList("session-id", targetItemId, applicationSessions);
      if (targetApplicationSession) {
         sessionId = targetApplicationSession.id;
      } else if (util.findFirstItemWithPropertyInList("origin-id", itemId, applicationSessions)) {
         targetApplicationSession = util.findFirstItemWithPropertyInList("origin-id", itemId, applicationSessions);
         sessionId = targetApplicationSession.id;
      } else {
         targetApplication = util.findFirstItemWithPropertyInList("id", itemId, applications);
         if (targetApplication) {
            targetApplicationSession = util.findFirstItemWithPropertyInList(
               "origin-id",
               targetApplication["origin-id"],
               applicationSessions
            );
            if (targetApplicationSession) {
               sessionId = targetApplicationSession.id;
            }
         }
      }
   }

   // kill the session.
   if (!!sessionId && sessionId.trim() !== "") {
      this.setRequestXML(sessionId);
      handlerList = this.composeHandlerList();
      router.postMessage(handlerList);
   } else {
      errorObj.name = "JSCDK_ERROR_LOGOFF_POOL_FAILED";
      if (launchItemTypeIsDesktop) {
         errorMessage = util._("JSCDK_ERROR_DESKTOP_CANT_LOGOFF");
      } else {
         errorMessage = util._("JSCDK_ERROR_APP_CANT_RESET");
      }
      Router.prototype.pushSelfDefinedError(errorObj, errorMessage);
   }
};
