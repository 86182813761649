/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Define how registered server model looks like.
 * It is kinda redundant for dynamic language such as JS. But it makes code
 * clearer.
 */

import { Injectable } from "@angular/core";

class Model {
   public address: string = null;
   public description: string = null;
   public autoConnect: boolean = false;
   public secondaryServers: string[] = null;
   public updateTime: string = null;
   public isTitan: boolean = false;
   constructor(
      address: string,
      autoConnect: boolean,
      description?: string,
      secondaryServers?: string[],
      updateTime?: string,
      isTitan?: boolean
   ) {
      this.address = address;
      this.description = description;
      /**
       * If autoConnect is not true or false (including string), set it
       * to false as default.
       */
      this.autoConnect = autoConnect === true || autoConnect === false ? autoConnect : false;
      this.secondaryServers = !secondaryServers ? [] : secondaryServers;
      this.updateTime = updateTime === undefined ? "" : updateTime;
      this.isTitan = isTitan === true || isTitan === false ? isTitan : false;
   }

   public equals = (server: Model): boolean => {
      return this.sameEntry(server) && server.description === this.description;
   };

   public sameEntry = (server: Model): boolean => {
      if (!server || !server.address) {
         throw "Not a valid server to compare!";
      }
      return server.address === this.address;
   };
}

@Injectable({
   providedIn: "root"
})
export class ChromeServerModel {
   public getModel = (
      address: string,
      autoConnect: boolean,
      description: string,
      secondaryServers: string[],
      updateTime: string,
      isTitan: boolean
   ) => {
      return new Model(address, autoConnect, description, secondaryServers, updateTime, isTitan);
   };
}
