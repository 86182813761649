/**
 * ***************************************************************************
 * Copyright 2018-2022 VMware, Inc.  All rights reserved.
 * ***************************************************************************
 *
 * @format
 */

/**
 *------------------------------------------------------------------------------
 *
 * cdr-config.js
 *
 * Config service for CDR, include client capacity model and feature version and
 * enablement.
 *
 * Most of the them are using the same value of native clients, and is hardcoded
 * since they should be enough.
 *
 * Only expose enable CDR for now, and might expose more config later.
 *
 *------------------------------------------------------------------------------
 */

import { Injectable } from "@angular/core";
import { TDSR_TYPE, RDPDR_TYPE } from "./index";
import { FeatureConfigs } from "../../common/model/feature-configs";
import { ClientSettingModel } from "../../common/model/client-setting-model";
import { Logger } from "@html-core";

@Injectable()
export class CDRConfig {
   public static readonly minorVersion = 0x0c;
   public static readonly tsdrVersion = TDSR_TYPE.VERSION.TSDR_VERSION_V1;
   public static readonly tsdrCaps = 0;

   private enableCDR = false;
   private killSwitchOn = true;
   constructor(
      private featureConfigs: FeatureConfigs,
      private clientSettingModel: ClientSettingModel
   ) {
      this.featureConfigs.registerListener("KillSwitch-CDR", this.setKillSwitch);
   }

   public getGeneralCapability = () => {
      return {
         header: {
            capabilityType: RDPDR_TYPE.CAP_TYPES.CAP_GENERAL_TYPE,
            capabilityLength: 0x2c,
            version: RDPDR_TYPE.GENERAL_CAPABILITY_VERSION.GENERAL_CAPABILITY_VERSION_02
         },
         osType: 0x00000002,
         osVersion: 0x00060001,
         protocolMajorVersion: 1,
         protocolMinorVersion: 0x0c,
         ioCode1: 0xffff,
         ioCode2: 0,
         extendedPDU:
            RDPDR_TYPE.RDPDR_DEVICE_REMOVE_PDUS |
            RDPDR_TYPE.RDPDR_CLIENT_DISPLAY_NAME_PDU |
            RDPDR_TYPE.RDPDR_USER_LOGGEDON_PDU,
         extraFlags1: 1, //ENABLE_ASYNCIO = 1
         extraFlags2: 0,
         specialTypeDeviceCap: 0
      };
   };

   /**
    * place holder
    */
   public getPrinterCapability = () => {
      return {
         header: {
            capabilityType: RDPDR_TYPE.CAP_TYPES.CAP_PRINTER_TYPE,
            capabilityLength: 0x08,
            version: 1
         }
      };
   };

   /**
    * place holder
    */
   public getPortCapability = () => {
      return {
         header: {
            capabilityType: RDPDR_TYPE.CAP_TYPES.CAP_PORT_TYPE,
            capabilityLength: 0x08,
            version: 1
         }
      };
   };

   /**
    * place holder, but version need to be 2
    */
   public getDriveCapability = () => {
      return {
         header: {
            capabilityType: RDPDR_TYPE.CAP_TYPES.CAP_DRIVE_TYPE,
            capabilityLength: 0x08,
            /* CAP_DRIVE_TYPE enabled with v2 to
             * support device names longer than 8 bytes.
             */
            version: 2
         }
      };
   };

   /**
    * place holder
    */
   public getSmartcardCapability = () => {
      return {
         header: {
            capabilityType: RDPDR_TYPE.CAP_TYPES.CAP_SMARTCARD_TYPE,
            capabilityLength: 0x08,
            version: 1
         }
      };
   };

   /**
    * @param {Boolean} Whether we enable/disable CDR by kill-switch
    */
   public setKillSwitch = (switchOn) => {
      this.killSwitchOn = switchOn;
   };

   /**
    * enable CDR only for Chrome client for now.
    * @return {Boolean} Whether the CDR is enabled.
    */
   public isEnabled = () => {
      this.enableCDR = this.clientSettingModel.getBooleanItem("enableFolderSharing");
      Logger.info("CDR kilsswitch: " + this.killSwitchOn + "enableCDR: " + this.enableCDR);
      return this.killSwitchOn && this.enableCDR;
   };

   /**
    * @return {object} The capacity object.
    */
   public getCapability = () => {
      return {
         header: {
            component: RDPDR_TYPE.RDPDR_CTYP_CORE,
            packetId: RDPDR_TYPE.PAKID_CORE_CLIENT_CAPABILITY
         },
         numCapabilities: 0x0005,
         padding: 0x0000,
         capabilityMessage: {
            GENERAL_CAPS_SET: this.getGeneralCapability(),
            PRINTER_CAPS_SET: this.getPrinterCapability(),
            PORT_CAPS_SET: this.getPortCapability(),
            DRIVE_CAPS_SET: this.getDriveCapability(),
            SMARTCARD_CAPS_SET: this.getSmartcardCapability()
         }
      };
   };

   public getMinorVersion = () => {
      return CDRConfig.minorVersion;
   };

   public getTsdrInfo = () => {
      return {
         version: CDRConfig.tsdrVersion,
         caps: CDRConfig.tsdrCaps
      };
   };
}
