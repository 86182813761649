/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * initRouterCommand.js --
 *
 * init jscdkRouter with broker url
 *
 * designed to limit the JSCDK talk to one broker at a time
 * shall be the constructor of JSCDK after re-factory.
 */

import { globalArray } from "../jscdkClient";
import Router from "../controllers/router";
import Logger from "../../../core/libs/logger";

export default function InitRouterCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param initRouterAction[in]
 *        - its property 'method' has the value 'InitRouter'
 */
InitRouterCommand.prototype.execute = function (initRouterAction) {
   let jscdkRouter = globalArray["router"];
   if (!jscdkRouter) {
      Logger.info("jscdkRouter is inited.");
      jscdkRouter = new Router();
      globalArray[jscdkRouter.name] = jscdkRouter;
   } else {
      Logger.info("jscdkRouter has already been inited.");
   }
   jscdkRouter.setBrokerUrl(initRouterAction.brokerUrl);
};
