/**
 * ******************************************************
 * Copyright (C) 2017-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * BitBuffer.js -- BitBuffer
 *
 */

export class BitBuffer {
   private _buf: any;
   private _size: number;
   private _readCount: number;
   private _thisByte: number;
   private _thisByteBits: number;

   public overflow: boolean;

   constructor(buffer: any, size: number) {
      this._buf = buffer;
      this._size = size;
      this._readCount = 0;
      this.overflow = false;
      this._thisByte = 0;
      this._thisByteBits = 0;
   }

   public readBits0 = (val: number, nr: number): number => {
      const mask = ~(0xff >> nr); /* ones in the lower 'nr' bits */
      val <<= nr; /* move output value up to make space */
      val |= (this._thisByte & mask) >> (8 - nr);
      this._thisByte <<= nr;
      this._thisByte &= 0xff;
      this._thisByteBits -= nr;

      return val;
   };

   public readBits = (nr: number): number => {
      const origNr = nr;
      let val = 0;

      if (this.overflow) {
         return 0;
      }

      while (nr > this._thisByteBits) {
         nr -= this._thisByteBits;
         val = this.readBits0(val, this._thisByteBits);

         if (this._readCount < this._size) {
            this._thisByte = this._buf[this._readCount++];
            this._thisByteBits = 8;
         } else {
            this._thisByte = 0;
            this._thisByteBits = 0;
            if (nr > 0) {
               this.overflow = true;
               return 0;
            }
         }
      }

      val = this.readBits0(val, nr);
      return val;
   };

   public readEliasGamma = (): number => {
      let l = 0;
      let value;
      let bit;

      while (!this.overflow && (bit = this.readBits(1)) === 0) {
         l++;
      }

      value = 1 << l;

      if (l) {
         value |= this.readBits(l);
      }

      return value;
   };
}
