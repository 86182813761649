/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import Logger from "../../../../core/libs/logger";
import { ChromeUsbDevice } from "./chrome-usb-device";
import { HorizonUsbDevice } from "./horizon-usb-device";
import { WebUsbDevice } from "./web-usb-device";

export enum USB_TYPE {
   CHROME_USB,
   WEB_USB
}
export class RawImpl {
   //raw device handle for different implementations.
   public raw: any;
   constructor(device) {
      this.raw = device;
   }
}

declare type USBDeviceCB = (device: RawImpl) => void;

export class HorizonUsb {
   private static opaqueId = 1000;

   /*
    *---------------------------------------------------------------------------
    *
    * onconnect --
    *
    * Event generated when a device is added to the system.
    *
    * (chrome.usb.*)
    * Events are only broadcast to apps and extensions that have permission to
    * access the device. Permission may have been granted at install time, when the
    * user accepted an optional permission (see permissions.request), or through
    * getUserSelectedDevices.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public static onConnect(type: USB_TYPE, cb: USBDeviceCB) {
      if (!cb) {
         return;
      }

      if (type === USB_TYPE.CHROME_USB) {
         if (chrome && chrome.usb && chrome.usb.onDeviceAdded) {
            chrome.usb.onDeviceAdded.addListener((device: chrome.usb.Device) => {
               cb(new RawImpl(device));
            });
         }
      } else {
         window.navigator.usb.onconnect = (ev: USBConnectionEvent) => {
            if (ev.device) {
               cb(new RawImpl(ev.device));
            }
         };
      }
   }

   /*
    *---------------------------------------------------------------------------
    *
    * onDisconnect --
    *
    * Event generated when a device is removed from the system.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public static onDisconnect(type: USB_TYPE, cb: USBDeviceCB) {
      if (!cb) {
         return;
      }

      if (type === USB_TYPE.CHROME_USB) {
         if (chrome && chrome.usb && chrome.usb.onDeviceRemoved) {
            chrome.usb.onDeviceRemoved.addListener((device: chrome.usb.Device) => {
               cb(new RawImpl(device));
            });
         }
      } else {
         window.navigator.usb.ondisconnect = (ev: USBConnectionEvent) => {
            if (ev.device) {
               cb(new RawImpl(ev.device));
            }
         };
      }
   }

   /*
    *---------------------------------------------------------------------------
    *
    * getDevices --
    *
    * The getDevices() method, when invoked, MUST return a new Promise and run the
    * following steps in parallel:
    * 1. Enumerate all devices attached to the system. Let this result be
    *    enumerationResult.
    * 2. Let devices be a new empty Array.
    * 3. For each device in enumerationResult:
    *
    * Results:
    *      Promise<Array<RawImpl>>.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public static getDevices(type: USB_TYPE): Promise<RawImpl[]> {
      const usbDevices = new Array<RawImpl>();
      if (type === USB_TYPE.CHROME_USB) {
         return new Promise<Array<RawImpl>>((resolve, reject) => {
            if (chrome && chrome.usb && chrome.usb.onDeviceRemoved) {
               chrome.usb.getDevices({}, (devices) => {
                  if (!devices) {
                     reject();
                  } else {
                     for (const raw of devices) {
                        usbDevices.push(new RawImpl(raw));
                     }
                     resolve(usbDevices);
                  }
               });
            } else {
               Logger.error("getDevices failed with " + chrome.runtime.lastError);
               reject();
            }
         });
      } else {
         Logger.error("getDevices - NOT IMPLEMENTED USB_TYPE", Logger.USB);
      }
   }

   /*
    *---------------------------------------------------------------------------
    *
    * requestDevice --
    *
    * Presents a device picker to the user and returns the Devices selected.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    * If the user cancels the picker devices will be empty. A user gesture is required
    * for the dialog to display. Without a user gesture, the callback will run as
    * though the user cancelled.
    *
    *---------------------------------------------------------------------------
    */
   public static requestDevice(type: USB_TYPE, option: USBDeviceRequestOptions): Promise<RawImpl[]> {
      const selected = new Array<RawImpl>();
      if (type === USB_TYPE.CHROME_USB) {
         if (chrome && chrome.usb && chrome.usb.getUserSelectedDevices) {
            const opt = {
               multiple: false,
               filters: option.filters
            };
            return new Promise<Array<RawImpl>>((resolve, reject) => {
               chrome.usb.getUserSelectedDevices(opt, (devices: chrome.usb.Device[]) => {
                  if (chrome.runtime.lastError !== undefined) {
                     Logger.error("requestDevice failed with " + chrome.runtime.lastError, Logger.USB);
                     reject(chrome.runtime.lastError);
                  } else if (!devices || devices.length === 0) {
                     Logger.error("requestDevice - no device selected", Logger.USB);
                     reject("no device selected");
                  } else {
                     for (const device of devices) {
                        selected.push(new RawImpl(device));
                     }
                     resolve(selected);
                  }
               });
            });
         }
      } else {
         return new Promise<Array<RawImpl>>((resolve, reject) => {
            window.navigator.usb
               .requestDevice(option)
               .then((device: USBDevice) => {
                  selected.push(new RawImpl(device));
                  resolve(selected);
               })
               .catch((err) => {
                  Logger.error("w3c requestDevice failed with " + err);
                  reject(err);
               });
         });
      }
   }

   /*
    *---------------------------------------------------------------------------
    *
    * onDisconnect --
    *
    * Create a HorizonUsb which is a wrapper for chrome.usb.* API and W3C API.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public static createHorizonUsbDevice = (device: RawImpl): HorizonUsbDevice => {
      if (clientUtil.isChromeClient()) {
         return new ChromeUsbDevice(device.raw, HorizonUsb.opaqueId++);
      } else {
         return new WebUsbDevice(device.raw, HorizonUsb.opaqueId++);
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * onDisconnect --
    *
    * Check whether chrome.usb.* API and W3C API is supported in current platform
    * or browsers.
    *
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   public static isUsbAPISupported = (type: USB_TYPE): boolean => {
      if (type === USB_TYPE.CHROME_USB) {
         return !!chrome && !!chrome.usb && !!chrome.usb.getUserSelectedDevices;
      } else {
         return !!window.navigator && !!window.navigator.usb && !!window.navigator.usb.requestDevice;
      }
   };
}
