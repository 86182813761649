/** @format */

import { Callbacks } from "jquery";
/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 */
import { Injectable, TemplateRef } from "@angular/core";
import { TranslateService } from "@html-core";

class NgbToastOption {
   public toastConfig = {
      classname: "toastBg text-light",
      delay: 5000,
      autohide: true,
      headertext: ""
   };
   constructor() {}
}

@Injectable({
   providedIn: "root"
})
export class NgbToastService {
   public readonly TOAST_TYPE = {
      DEFAULT: 0,
      PREDEFINED: 1,
      FT: 2,
      PRINT: 3,
      CLIPBOARD: 4,
      CHROMEFT: 5,
      MULTIMON: 6,
      USB: 7,
      NETWORK: 8
   };
   static readonly LEVEL = {
      INFO: "info",
      SUCCESS: "success",
      WARNING: "WARNING",
      ERROR: "ERROR"
   };

   toasts: any[] = [];

   private defaultOption: NgbToastOption;
   private multimonToastTimer = null;
   private _toastArray = [];

   constructor(private translate: TranslateService) {
      this.defaultOption = new NgbToastOption();
   }
   // Push new Toasts to array with content and options
   show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
      this.toasts.push({ textOrTpl, ...options });
   }

   private showToast = (
      level,
      text,
      type,
      callback: any = null,
      arg: any = null,
      dismissCallback?: any,
      dismissArg?: any
   ) => {
      if (!text) {
         return;
      }

      if (
         level !== NgbToastService.LEVEL.INFO &&
         level !== NgbToastService.LEVEL.ERROR &&
         level !== NgbToastService.LEVEL.WARNING &&
         level !== NgbToastService.LEVEL.SUCCESS
      ) {
         throw "Error level type is used";
      }

      if (!type) {
         type = this.TOAST_TYPE.DEFAULT;
      }
      const defaultConfig: any = this.defaultOption.toastConfig;
      defaultConfig.headertext = this.translate._T(level);
      defaultConfig.multiToast = false;
      defaultConfig.usbToast = false;
      switch (type) {
         case this.TOAST_TYPE.DEFAULT:
         case this.TOAST_TYPE.PREDEFINED:
         case this.TOAST_TYPE.CHROMEFT:
         case this.TOAST_TYPE.FT:
         case this.TOAST_TYPE.PRINT:
         case this.TOAST_TYPE.NETWORK: {
            defaultConfig.textToast = true;
            break;
         }
         case this.TOAST_TYPE.CLIPBOARD: {
            defaultConfig.callback = callback;
            break;
         }
         case this.TOAST_TYPE.MULTIMON: {
            defaultConfig.multiToast = true;
            defaultConfig.callback = callback;
            defaultConfig.dismissCallback = dismissCallback;
            break;
         }
         case this.TOAST_TYPE.USB: {
            defaultConfig.classname = "bg-info toast-bottom-right";
            defaultConfig.usbToast = true;
            defaultConfig.callback = callback;
            break;
         }
         default: {
            throw "Error msg type is used.";
         }
      }
      this.show(text, defaultConfig);
      this._toastArray.push({
         toast: defaultConfig,
         type: type
      });
      return defaultConfig;
   };

   public success = (text, type) => {
      this.showToast(NgbToastService.LEVEL.SUCCESS, text, type);
   };

   public warning = (text, type) => {
      this.showToast(NgbToastService.LEVEL.WARNING, text, type);
   };

   public error = (text, type) => {
      this.showToast(NgbToastService.LEVEL.ERROR, text, type);
   };

   public info = (text, type) => {
      this.showToast(NgbToastService.LEVEL.INFO, text, type);
   };

   public infoWithCallBack = (text, type, callback?, arg?, dismissCallback?, dismissArg?) => {
      return this.showToast(NgbToastService.LEVEL.INFO, text, type, callback, arg, dismissCallback, dismissArg);
   };

   public clearEarlyToast = (type) => {
      if (this._toastArray.length < 1) {
         return;
      }

      if (!type) {
         type = this.TOAST_TYPE.DEFAULT;
      }

      let i = 0;
      while (i < this._toastArray.length) {
         if (this._toastArray[i].type === type) {
            this.remove(this.toasts[i]);
            this._toastArray.splice(i, 1);
            continue;
         }
         i++;
      }
   };

   clearAll = () => {
      for (const toast of this.toasts) {
         this.remove(toast);
      }
   };

   // Callback method to remove Toast DOM element from view
   remove(toast) {
      this.toasts = this.toasts.filter((t) => t !== toast);
   }
}
