/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

//should support highlighting both app and desktop, added to html later, then
// filename should also be changed
import { AfterContentInit, Component, Directive, ElementRef, Input } from "@angular/core";

//[autoFocus] //will focus
//[autoFocus]="true" //will focus
//[autoFocus]="false" //will not focus
@Directive({
   selector: "[autoFocus]"
})
export class AutoFocusDirective implements AfterContentInit {
   private focus: boolean = true;

   public constructor(private el: ElementRef) {}
   public ngAfterContentInit() {
      if (this.focus) {
         setTimeout(() => {
            this.el.nativeElement.focus();
         });
      }
   }

   @Input()
   set autoFocus(condition: boolean) {
      this.focus = condition !== false;
   }
}

/* TODO-NG: add back later to avoid browser default behavior
export function unstorablePasswordDirective($timeout) {
   return {
      link: function(scope, elem, attrs) {
         $timeout(function() {
            $(elem[0]).prop('type', 'password');
         }, 200);
      }
   };
}*/

@Component({
   selector: "loading-image-animation",
   templateUrl: "./loading-image-animation.html"
})
export class loadingImageAnimationComponent {
   @Input() showLoading: boolean;
}
