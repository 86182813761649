/***
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 * Simplified Logger function for SDK, remove the jquery
 * Dependency
 *
 * @format
 */

enum LogLevel {
   None = -2,
   Dump,
   Trace,
   Debug,
   Info,
   Warning,
   Error
}
const defaultLogPrefix = "HC-SDK";

export class Logger {
   private static _pad = (value, maxLen) => {
      const string = value.toString(),
         negative = string.indexOf("-") === 0,
         length = negative ? string.length - 1 : string.length;
      return length < maxLen ? Logger._pad(negative ? "-0" + string.slice(1) : "0" + string, maxLen) : string;
   };
   private static levelName = ["none", "dump", "verb", "dbg", "info", "warn", "err"];

   public static LOG_LEVEL = 1;

   private prefix: string;
   constructor(private name: string) {
      this.prefix = name;
   }

   public dump = (args: string) => {
      Logger.dump(args, this.prefix);
   };
   public trace = (args: string) => {
      Logger.trace(args, this.prefix);
   };
   public debug = (args: string) => {
      Logger.debug(args, this.prefix);
   };
   public info = (args: string) => {
      Logger.info(args, this.prefix);
   };
   public warning = (args: string) => {
      Logger.warning(args, this.prefix);
   };
   public error = (args: string) => {
      Logger.error(args, this.prefix);
   };

   public exception = (exception: Error) => {
      Logger.exception(exception, this.prefix);
   };

   public static dump(args: string, prefix: string = defaultLogPrefix) {
      Logger.log(args, LogLevel.Dump, prefix);
   }
   public static trace(args: string, prefix: string = defaultLogPrefix) {
      Logger.log(args, LogLevel.Trace, prefix);
   }

   public static debug(args: string, prefix: string = defaultLogPrefix) {
      Logger.log(args, LogLevel.Debug, prefix);
   }

   public static info(args: string, prefix: string = defaultLogPrefix) {
      Logger.log(args, LogLevel.Info, prefix);
   }

   public static warning(args: string, prefix: string = defaultLogPrefix) {
      Logger.log(args, LogLevel.Warning, prefix);
   }

   public static error(args: string, prefix: string = defaultLogPrefix) {
      Logger.log(args, LogLevel.Error, prefix);
   }

   public static exception(exception: Error, prefix: string = defaultLogPrefix) {
      if (!exception || !(exception instanceof Error)) {
         Logger.error(String(exception), prefix);
      } else {
         Logger.error(exception.stack || exception.message, prefix);
      }
   }

   public static addRawLog(logString) {
      if (!!self.console && typeof self.console.log === "function") {
         self.console.log(logString);
      }
   }

   public static log(args: string, level: number = LogLevel.Debug, prefix: string = null): void {
      let logTime, logString;

      if (level >= Logger.LOG_LEVEL) {
         if (args) {
            logTime = new Date();
            logString =
               "[" +
               logTime.getFullYear() +
               "-" +
               Logger._pad(logTime.getMonth() + 1, 2) +
               "-" +
               Logger._pad(logTime.getDate(), 2) +
               "T" +
               Logger._pad(logTime.getHours(), 2) +
               ":" +
               Logger._pad(logTime.getMinutes(), 2) +
               ":" +
               Logger._pad(logTime.getSeconds(), 2) +
               "." +
               Logger._pad(logTime.getMilliseconds(), 3) +
               "][" +
               Logger.levelName[level + 2] +
               "]";

            if (prefix) {
               logString += " <" + prefix + ">";
            }

            logString += ": " + args;
         }
         if (!!self.console && typeof self.console.log === "function") {
            self.console.log(logString);
         }
      }
   }

   /**
    * Set the log level.
    *
    * @param logLevel [in] the log level of the application.
    */
   public static setLogLevel(logLevel: string) {
      Logger.LOG_LEVEL = LogLevel[logLevel];
   }

   public static getLogLevel() {
      return Logger.LOG_LEVEL;
   }
}
