/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injector } from "@angular/core";
import { Logger } from "./logger";
/**
 * Allows for retrieving singletons using `AppInjector.get(ServiceX)` (whereas
 * `ReflectiveInjector.resolveAndCreate(ServiceX)` would create a new instance
 * of the service).
 */
export let AppInjector: Injector;

/**
 * Helper to set the exported {@link AppInjector}, needed as ES6 modules export
 * immutable bindings for which trying to make changes after using `import {AppInjector}`
 * would throw: "TS2539: Cannot assign to 'AppInjector' because it is not a variable".
 */
export function setAppInjector(injector: Injector) {
   if (AppInjector) {
      // Should not happen
      Logger.error("Programming error: AppInjector was already set");
   } else {
      AppInjector = injector;
   }
}
