/**
 * ******************************************************
 * Copyright (C) 2013 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { HorizonUserGlobalPref } from "./horizon-user-pref.service";
import { PrefData, XMLPreference } from "./prefdata";
import { ConnectionServerModel } from "../model/connection-server-model";
import { EventBusService } from "@html-core";
import { PreDataSetModel } from "../model/pre-data-set-model";
import { ViewClientModel } from "../model/viewclient-model";
import { Subscription } from "rxjs";
import { KeyMappingStorageService } from "./keymapping-storage.service";

@Injectable({
   providedIn: "root"
})
export class HorizonUserPrefAdaptor extends HorizonUserGlobalPref {
   protected postPrefDataSub$: Subscription;
   constructor(
      prefData: PrefData,
      connectionServerModel: ConnectionServerModel,
      eventBusService: EventBusService,
      preDataSetModel: PreDataSetModel,
      viewClientModel: ViewClientModel,
      private keyMapLocalService: KeyMappingStorageService
   ) {
      super(prefData, connectionServerModel, eventBusService, preDataSetModel, viewClientModel);
      this.postPrefDataSub$ = this.eventBusService.listen("postPrefData").subscribe(() => {
         this.filterAndPostPrefData();
      });
   }

   private filterAndPostPrefData = () => {
      super.postPrefData();
      if (this.keyMapLocalService.isPrefLimitationExist()) {
         const keyMappingSetting = this.prefData.prefData.keyMappingSetting;
         this.keyMapLocalService.saveKeyMapping(keyMappingSetting);
      }
   };

   public getPrefDataInJson = () => {
      if (this.keyMapLocalService.isPrefLimitationExist()) {
         return this.filteredPrefData();
      }
      return super.getPrefDataInJson();
   };

   public filteredPrefData = () => {
      const prefDataCopy = JSON.parse(JSON.stringify(this.prefData.prefData));
      if (prefDataCopy.hasOwnProperty("keyMappingSetting")) {
         delete prefDataCopy.keyMappingSetting;
      }
      return this.prefData.toJSON(prefDataCopy);
   };

   public setPrefData = (data: XMLPreference) => {
      if (this.keyMapLocalService.isPrefLimitationExist()) {
         const keyMappingSettingPref = {
            keyMappingSetting: this.keyMapLocalService.getKeyMapping()
         };
         const mergedPref = { ...data, ...keyMappingSettingPref };
         super.setPrefData(mergedPref);
      } else {
         super.setPrefData(data);
      }
   };
}
