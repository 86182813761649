/**
 * ******************************************************
 * Copyright (C) 2016-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * extended-monitor.ts -- ExtendedMonitorFactory
 *
 * Class to control the extended monitor.
 * contained by the model
 *
 * mainly maintain the inner status and response to message and inner data for communication
 *
 * user Logger for now later will change when the new logService is ready
 */

import Logger from "../../../../core/libs/logger";
import { Injectable, Optional } from "@angular/core";
import { BusEvent, clientUtil, EventBusService } from "@html-core";
import { ExtendedMonitorService } from "./extended-monitor.service";
import { VNCDecoder } from "../common/vnc-decoder";
import { DisplayService } from "../../common/display/display.service";
import { Monitor } from "../common/monitor-message";
import { asyncClipboard } from "../../common/async-clipboard.service";
import { FeatureConfigs } from "../../../common/model/feature-configs";

@Injectable({
   providedIn: "root"
})
export class ExtendedMonitorFactory {
   constructor(
      private extendedMonitorService: ExtendedMonitorService,
      private vncDecoder: VNCDecoder,
      private eventBusService: EventBusService,
      @Optional()
      private displayService: DisplayService,
      private asyncClipboard: asyncClipboard,
      private featureConfigs: FeatureConfigs
   ) {}
   public createExtendedMonitor = () => {
      Logger.info("open a extended monitor", Logger.DISPLAY);
      return new ExtendedMonitorClass(
         this.extendedMonitorService,
         this.vncDecoder,
         this.eventBusService,
         this.displayService,
         this.asyncClipboard,
         this.featureConfigs
      );
   };
}

class ExtendedMonitorClass {
   private inited = false;
   private canRender = false;
   private heartbeatTimer = null;
   private isChromeClient = false;
   private origin: string = "";
   private uid: string = "";
   private status = null;
   private isForApplication = false;
   // The extended monitor id
   public id: string = "";
   // The regions that don't allow maximize
   private unmaximizableRegions: Map<string, DisplayBaseInfo> = null;
   // The callback for monitor status change (id , status)
   private onRegionUpdated = null;
   //The callback for regions changes (id ,data(could be null))
   private onStatusChanged = null;
   private extendedWindow = null;

   private vncRects = [];
   private currentFrameId = 0;
   private frameWaitTimer = null;
   private frameWaitTime = 0;
   private onRenderingDone = null;
   private screenSetting = null;

   private _window;
   private _port = null;

   private onDisplayDisconnected = () => {
      this.onRegionUpdated(this.id, null);
      this.onStatusChanged(this.id, this.extendedMonitorService.statusMap["closed"]);
   };
   private onDisplayMessage = (e) => {
      try {
         const message = {
            origin:
               e.currentTarget.url.split("://")[0] +
               "://" +
               e.currentTarget.url.split("https://")[1].split("/")[0].split(":")[0],
            data: JSON.parse(e.data)
         };
         this.onPostMessage(message);
      } catch (e) {
         Logger.error("exception found when handing message from extended window", Logger.DISPLAY);
      }
   };
   public onDisplayConnected = () => {
      this.inited = true;
      Logger.info("display connected from extend-monitor", Logger.DISPLAY);

      this.heartbeatTimer = setInterval(() => {
         if (this._window && this._window.closed) {
            this.close();
            return;
         }
         this.sendMessage(new Monitor.HeartBeatMsg());
      }, 1000);

      this.displayService.addEventListener("displayMessage", this.onDisplayMessage);
   };
   public clearHeartBeat = () => {
      if (this.heartbeatTimer) {
         clearInterval(this.heartbeatTimer);
         this.heartbeatTimer = null;
      }
   };

   constructor(
      private extendedMonitorService: ExtendedMonitorService,
      private vncDecoder: VNCDecoder,
      private eventBusService: EventBusService,
      private displayService: DisplayService,
      private asyncClipboard: asyncClipboard,
      private featureConfigs: FeatureConfigs
   ) {
      this.isChromeClient = clientUtil.isChromeClient();
      if (!this.isChromeClient) {
         this.origin = "https://" + window.location.hostname;
         this.eventBusService.listen(BusEvent.ReadyForClipboardSyncMsg.MSG_TYPE).subscribe(() => {
            this.sendMessage(new Monitor.ClipboardCapabilitiesOKMsg());
         });
      } else {
         this.origin = "chrome-extension://" + window.location.hostname;
      }
      Logger.debug("ExtendedMonitor instance created", Logger.DISPLAY);
   }

   private sendMessage = (message: Monitor.MonitorMessage, noRetry: boolean = false): boolean => {
      if (!this.inited) {
         const retryInterval = 1000; //ms
         if (noRetry) {
            Logger.error("failed to send message to un-inited extended monitor, stop retry and fail the sending");
            return false;
         }
         Logger.info("failed to send message to un-inited extended monitor, try again in " + retryInterval + "ms");
         setTimeout(() => {
            return this.sendMessage(message, true);
         }, retryInterval);
      }
      if (this.uid) {
         message.uid = this.uid;
      } else {
         message.uid = "";
      }

      Logger.trace("multi monitor: => [" + JSON.stringify(message), Logger.DISPLAY);

      if (!this.isChromeClient) {
         return this.displayService.sendToDisplay(message, this._window);
      } else {
         if (!this._port) {
            Logger.error("No port for extend monitor to send message.");
            return false;
         }
         this._port.postMessage(message);
         return true;
      }
   };
   private setStatus = (status) => {
      this.status = status;
      this.onStatusChanged(this.id, status);
   };

   private initPage = (data: Monitor.ReadyMsg) => {
      this.uid = data.uid;
      const ret = this.sendMessage(new Monitor.InitMsg(this.id, this.extendedMonitorService.translationMap));
      if (ret) {
         Logger.info("request to init extended monitor");
      } else {
         Logger.error("failed to request to init extended monitor");
      }
   };

   private sendUnmaximizableRegion = () => {
      this.sendMessage(new Monitor.UpdateMaxRegionMsg(JSON.stringify(Array.from(this.unmaximizableRegions))));
   };

   public setPort = (port) => {
      this._port = port;
      this._port.onMessage.addListener(this.onPostMessage);
      this._port.onDisconnect.addListener(() => {
         this._port = null;
      });
   };

   public onPostMessage = (event) => {
      let responseData: Monitor.MonitorMessage;
      if (this.isChromeClient) {
         responseData = event;
      } else {
         responseData = event.data;
         const origin = event.origin || event.originalEvent?.origin;
         if (origin !== this.origin) {
            return;
         }
         if (this.uid !== "" && responseData.uid !== this.uid) {
            return;
         }
      }

      Logger.trace("multi monitor: <= [" + JSON.stringify(responseData), Logger.DISPLAY);

      switch (responseData.msgType) {
         case Monitor.ClipboardContentHashValueMsg.TYPE:
            this.eventBusService.dispatch(
               new BusEvent.ClipboardContentHashValue({
                  clipboardContentHashValue: responseData["clipboardContentHashValue"]
               })
            );
            break;
         case Monitor.PasteMsg.TYPE:
            this.eventBusService.dispatch(
               new BusEvent.ClipboardClientContentMsg({ text: responseData["text"], html: responseData["html"] })
            );
            break;
         case Monitor.ReadyMsg.TYPE:
            Logger.info("start to init extended monitor");
            this.initPage(responseData as Monitor.ReadyMsg);
            break;
         case Monitor.InitDoneMsg.TYPE:
            Logger.info("initDone received");
            this.setStatus(this.extendedMonitorService.statusMap["inited"]);
            break;
         case Monitor.ReadyToDisplay.TYPE:
            {
               Logger.info("monitor starting to work");
               const msg = responseData as Monitor.ReadyToDisplay;
               // for windowReplacementApi, extended monitor's info is already stored in this.screenSetting, so use this value directly
               // can't use msg.region from extended monitor due to the wrong coordinate value (not the value of window in fullscree for including topbar's size etc)
               if (this.featureConfigs.getConfig("KillSwitch-WindowReplacementApi") && this.screenSetting) {
                  this.onRegionUpdated(this.id, {
                     x: this.screenSetting.left,
                     y: this.screenSetting.top,
                     width: this.screenSetting.width,
                     height: this.screenSetting.height,
                     devicePixelRatio: this.screenSetting.devicePixelRatio,
                     baseKey: this.screenSetting.baseKey,
                     macNotchHeight: msg.region.macNotchHeight
                  });
               } else {
                  this.onRegionUpdated(this.id, msg.region);
               }
               this.setStatus(this.extendedMonitorService.statusMap["readyToDisplay"]);
            }
            break;
         case Monitor.CloseMsg.TYPE:
            this.onRegionUpdated(this.id, null);
            this.onStatusChanged(this.id, this.extendedMonitorService.statusMap["closed"]);
            break;
         case Monitor.ConfirmedCloseMsg.TYPE:
            this.onStatusChanged(this.id, this.extendedMonitorService.statusMap["confirmedQuit"]);
            break;
         case Monitor.BlurMsg.TYPE:
            this.extendedMonitorService.onBlur();
            break;
         case Monitor.KeyEvent.TYPE:
            {
               const msg = responseData as Monitor.KeyEvent;
               if (this.isChromeClient) {
                  let type = msg.event.type;

                  if (type === "keydown") {
                     type = "KeyDown";
                  } else if (type === "keypress") {
                     type = "KeyPress";
                  } else if (type === "keyup") {
                     type = "KeyUp";
                  }
                  msg.type = type;
               }
               this.extendedMonitorService.onKeyEvent(msg);
            }
            break;
         case "mouseButton":
            this.extendedMonitorService.onMouseButton(responseData as Monitor.MouseButtonMsg);
            break;
         case "mouseWheel":
            this.extendedMonitorService.onMouseWheel(responseData as Monitor.MouseWheelMsg);
            break;
         case "mouseMove":
            this.extendedMonitorService.onMouseMove(responseData as Monitor.MouseMove);
            break;
         case "touchScreen":
            this.extendedMonitorService.onTouchEvent(responseData as Monitor.TouchScreenMsg);
            break;
         case Monitor.RenderDoneMsg.TYPE:
            {
               const msg = responseData as Monitor.RenderDoneMsg;
               msg.indices.forEach((item) => {
                  this.onRenderingDone(this.id, item);
               });
            }
            break;
         case Monitor.OnFocus.TYPE:
            // since asyncClipboard.ts is only used for web client, here we should add condition
            // to ensure it's not chrome client before use the function in asyncClipboard.
            if (!this.isChromeClient) {
               this.asyncClipboard.onFocus(null);
            }
            break;
         case Monitor.LogMsg.TYPE: {
            let logInfo: any = responseData;
            let logString = "<Extended Monitor> " + logInfo.logString;
            if (logInfo.logLevel === 0) {
               Logger.trace(logString);
            } else if (logInfo.logLevel === 1) {
               Logger.debug(logString);
            } else if (logInfo.logLevel === 2) {
               Logger.info(logString);
            } else if (logInfo.logLevel === 3) {
               Logger.warning(logString);
            } else if (logInfo.logLevel === 4) {
               Logger.error(logString);
            }
            break;
         }
         default:
            Logger.debug("unknown message type" + responseData, Logger.DISPLAY);
      }
   };
   /**
    * init this instance
    * @param  {[type]} id              The extended monitor id
    * @param  {[type]} unmaximizableRegions The regions that don't allow maximize
    * @param  {[type]} onStatusChanged The callback for monitor status change (id , status)
    * @param  {[type]} onRegionUpdated The callback for regions changes (id ,data(could be null))
    * @param  {object} screenSetting @optional {x, y, isApplication}
    */
   public init = (id, unmaximizableRegions, onStatusChanged, onRegionUpdated, screenSetting) => {
      Logger.debug("ExtendedMonitor instance init", Logger.DISPLAY);
      this.id = id;
      this.unmaximizableRegions = unmaximizableRegions;
      this.onRegionUpdated = onRegionUpdated;
      this.onStatusChanged = onStatusChanged;
      this.status = this.extendedMonitorService.statusMap["opened"];
      this.uid = "";
      this.isForApplication = !!screenSetting && !!screenSetting.isForApplication;
      this.screenSetting = screenSetting;
      if (!this.isChromeClient) {
         Logger.info("occupying extended monitor for HTML Access", Logger.DISPLAY);
         this.openPresentationWindow();
      } else if (this.extendedWindow === null) {
         Logger.info("occupying extended monitor for Chrome Client", Logger.DISPLAY);
         this.openChromeClientExtendedWindow(this.isForApplication);
      } else {
         Logger.error("skip init an inited extend monitor", Logger.DISPLAY);
      }
      this.eventBusService
         .listen(BusEvent.ClipboardAgentContentMsg.MSG_TYPE)
         .subscribe((msg: BusEvent.ClipboardAgentContentMsg) => {
            this.sendMessage(new Monitor.CopyMsg({ text: msg.text, html: msg.html }));
         });
      this.eventBusService.listen(BusEvent.ClipboardGPOMsg.MSG_TYPE).subscribe((msg: BusEvent.ClipboardGPOMsg) => {
         this.sendMessage(
            new Monitor.ClipboardGPOSettingMsg({ copyEnabled: msg.copyEnabled, pasteEnabled: msg.pasteEnabled })
         );
      });
      this.eventBusService
         .listen(BusEvent.ClipboardContentHashValue.MSG_TYPE)
         .subscribe((msg: BusEvent.ClipboardContentHashValue) => {
            this.sendMessage(
               new Monitor.ClipboardContentHashValueMsg({ clipboardContentHashValue: msg.clipboardContentHashValue })
            );
         });
   };

   private openPresentationWindow = () => {
      Logger.info("open new display", Logger.DISPLAY);
      this._window = this.displayService.entendDisplay(this);
      // window.open is blocked by browser
      // eslint-disable-next-line
      if (this._window === null || typeof this._window === undefined) {
         this.eventBusService.dispatch(new BusEvent.WindowOpenIsBlocked());
         return;
      }
      this.displayService.addEventListener("displayConnected", this.onDisplayConnected);
      // on id check since there is at most one extend monitor for now
      this.displayService.addEventListener("displayDisconnected", this.onDisplayDisconnected);
      this.displayService.addEventListener("displayDisconnected", this.clearHeartBeat);
   };

   private openChromeClientExtendedWindow = (isApplication: boolean) => {
      // chrome app will maintain the windows fullscreen status, so add
      // time stamp here to fix this issue.
      const setting = this.screenSetting,
         randomString = Date.parse(new Date().toString()) + Math.random();
      Logger.info("open extended monitor for chrome client to x=" + setting.x + ", y=" + setting.y, Logger.DISPLAY);
      let pagePath;
      let style;
      if (isApplication) {
         pagePath = "./webclient/app-extended-monitor.html?v=";
         style = "none";
      } else {
         pagePath = "./webclient/extended-monitor.html?v=";
         style = "chrome";
      }
      pagePath += this.id + "&" + __BUILD_NUMBER__ + "&" + randomString;
      const primaryUrlParams = new URLSearchParams(window.location.href.split("?")[1]);
      const primarySessionKey = encodeURIComponent(primaryUrlParams.get("sessionKey"));
      pagePath += "&sessionKey=" + primarySessionKey;
      chrome.app.window.create(
         pagePath,
         {
            id: "extended_monitor" + this.id + "&" + randomString,
            outerBounds: {
               // On chrome os, if the new create window size is bigger than then screen,
               // it will fail to open in the new screen, so set the init size small.
               left: setting.x,
               top: setting.y,
               width: 100,
               height: 100
            },
            frame: style
         },
         (appWindow) => {
            /*
            Reset the extended window's position，
            if the extended window was opened once, but
            the position is different, the next one will
            be wrong. System's limitation.
            */
            appWindow.outerBounds.setPosition(setting.x, setting.y);
            this.extendedWindow = appWindow;
            appWindow.contentWindow.addEventListener("load", () => {
               this.inited = true;
            });
         }
      );
   };

   public removeEventListeners = () => {
      this.displayService.removeEventListener("displayDisconnected", this.onDisplayDisconnected);
      this.displayService.removeEventListener("displayMessage", this.onDisplayMessage);
      this.displayService.removeEventListener("displayConnected", this.onDisplayConnected);
   };

   /**
    * This function should be called when other monitor detector found any changes
    * It will set regions to the all used regions except itself
    * @param  {object} allUsedRegions The object contains the region
    */
   public updateUnmaxizableRegions = (allUsedRegions: Map<string, DisplayBaseInfo>) => {
      this.unmaximizableRegions = new Map(allUsedRegions);
      delete this.unmaximizableRegions[this.id];
      this.sendUnmaximizableRegion();
   };

   /**
    * should be called when user want to use all monitors
    * @param  {object} screenBase
    * @param  {object} screenModel
    * @param  {function} onRenderingDone
    */
   public startDisplay = (
      screenBase: WMKPoint,
      onRenderingDone,
      screenModel: NormalizationParam,
      sizeFactor: number
   ) => {
      if (this.status !== this.extendedMonitorService.statusMap["readyToDisplay"]) {
         Logger.debug("skip startDisplay since not ready on" + this.id, Logger.DISPLAY);
         return;
      }
      Logger.info("start display");
      Logger.debug("display parameters " + JSON.stringify({ screenBase, screenModel, sizeFactor }));
      this.onRenderingDone = onRenderingDone;
      this.sendMessage(new Monitor.StartDisplayMsg(screenBase, screenModel, sizeFactor));
      this.status = this.extendedMonitorService.statusMap["working"];
      this.canRender = true;
      this.vncRects = [];
      this.currentFrameId = 0;
      this.frameWaitTimer = null;
      this.frameWaitTime = 50;
   };

   private appendChange = (data) => {
      const frameId = data.rect.frameId;
      if (this.currentFrameId !== frameId) {
         this.sendDisplayChange();
         this.currentFrameId = frameId;
      }
      this.vncRects.push(data);
      if (!this.frameWaitTimer) {
         this.frameWaitTimer = setTimeout(this.sendDisplayChange, this.frameWaitTime);
      }
   };

   private sendDisplayChange = () => {
      if (this.vncRects.length > 0) {
         const message = new Monitor.DisplayMsg(this.uid, this.vncRects);
         if (this.extendedWindow) {
            if (this.isChromeClient) {
               this.sendMessage(message);
            } else {
               message.origin = this.origin;
               this.extendedWindow.postMessage(message);
            }
         } else {
            this.displayService.sendToDisplay(message, this._window);
         }

         this.vncRects.forEach((item) => {
            this.vncDecoder.releaseRectData(item);
         });
         this.vncRects = [];
      }
      if (this.frameWaitTimer) {
         clearTimeout(this.frameWaitTimer);
         this.frameWaitTimer = null;
      }
   };

   /**
    * [onDisplayChanged description]
    * @param  {[type]} regionData [description]
    * @return {[type]}            [description]
    */
   public onDisplayChanged = (rect, renderingIndex) => {
      if (this.status !== this.extendedMonitorService.statusMap["working"]) {
         Logger.debug("skip display since not working on " + this.id, Logger.DISPLAY);
         return;
      }
      if (!this.canRender) {
         Logger.trace("skip display since extended monitor is busy on " + this.id, Logger.DISPLAY);
         return;
      }

      this.appendChange({
         rect: rect,
         renderingIndex: renderingIndex
      });
      rect.skipRelease = true;
   };

   /**
    * [onCursorChanged description]
    * @param  {[type]} cursorAddress [description]
    * @return {[type]}               [description]
    */
   public onCursorChanged = (cursorAddress) => {
      if (this.status !== this.extendedMonitorService.statusMap["working"]) {
         Logger.debug("skip changeCursor since not working on " + this.id, Logger.DISPLAY);
         return;
      }
      this.sendMessage(
         new Monitor.SetCursorMsg(
            cursorAddress.src,
            cursorAddress.width,
            cursorAddress.height,
            cursorAddress.hotx,
            cursorAddress.hoty
         )
      );
   };

   // double confirm to close extended window, since chrome has bug sometime
   public close = () => {
      this.canRender = false;
      this.status = this.extendedMonitorService.statusMap["opened"];
      if (!this.isChromeClient && this.extendedWindow && this.extendedWindow.removeEventListener) {
         this.extendedWindow.removeEventListener("message", this.onPostMessage, false);
      } else if (this.extendedWindow && this.extendedWindow.onClosed) {
         this.extendedWindow.onClosed.removeListener(this.onPostMessage);
      } else {
         this.displayService.close();
      }
      setTimeout(() => {
         if (this.heartbeatTimer) {
            clearInterval(this.heartbeatTimer);
            this.heartbeatTimer = null;
         }
         this.sendMessage(new Monitor.ForceCloseMsg());
         this.extendedWindow = null;
         this.inited = false;
      });
   };

   public showDisplay = () => {
      this.sendMessage(new Monitor.ShowDisplay());
   };

   public hideDisplay = () => {
      this.sendMessage(new Monitor.HideDisplay());
   };

   public adjustDisplay = (screenBase: WMKPoint, screenModel: NormalizationParam, sizeFactor: number) => {
      this.sendMessage(new Monitor.AdjustDisplay(screenBase, screenModel, sizeFactor));
   };

   public adjustDisplayDone = () => {
      this.sendMessage(new Monitor.AdjustDisplayDone());
   };

   public sendUnityMessage = (data) => {
      this.sendMessage(new Monitor.UnityMsg(data));
   };

   public sendBorderMessage = (message) => {
      this.sendMessage(message);
   };
}
