/** @format */

export interface Header {
   buffer: ArrayBuffer;
   length: number;
}

export interface HeaderCallbackData {
   deviceIndex: number;
   header: Header;
}

export interface AudioCallbackData {
   deviceIndex: number;
   data: ArrayBuffer;
   timestamp: number;
   dataCount: number;
}

interface VideoEnvDataOthers {
   sessionId: number;
   getNextFrame: (sessionId: number) => void;
}
export type VideoCallbackData = { others: VideoEnvDataOthers } & AudioCallbackData;

export type Callback<T extends HeaderCallbackData | AudioCallbackData | VideoCallbackData> = (data: T) => void;

export interface AudioVideoSample {
   data: ArrayBufferLike;
   timestamp: number;
   others?: VideoEnvDataOthers;
}

export interface EnvData {
   metaData: number; // this attribute is for V0 mode
   timestamp: number; // this attribute is for v2 mode
   others?: VideoEnvDataOthers; // this attribute for both V0 & V2 mode
}

export class LimitedSet {
   private buff: Array<EnvData>;
   private occupied: Array<boolean>;

   constructor(capacity: number) {
      this.buff = Array(capacity);
      this.occupied = Array(capacity).fill(false);
   }

   public insert(item: EnvData) {
      for (let i = 0; i < this.occupied.length; i++) {
         if (!this.occupied[i]) {
            this.buff[i] = item;
            this.occupied[i] = true;
            return i;
         }
      }
      return -1;
   }

   public fetch(id: number) {
      this.occupied[id] = false;
      return this.buff[id];
   }
}

export function arrayCopy(src: ArrayBuffer, srcPtr: number, dst: ArrayBuffer, dstPtr: number, length: number) {
   const srcArray = new Uint8Array(src, srcPtr, length);
   const dstArray = new Uint8Array(dst, dstPtr, length);
   dstArray.set(srcArray);
}
