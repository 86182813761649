/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * sidechannel.js --
 *
 *    Sidechannel, currently only support type "Vchan"
 *    Capacity is hard coded as MINIRPC, NO_TCP, INVOKE.
 *    No compression or crypto is supported.
 */

import Logger from "../../../core/libs/logger";
import sidechannelSchema from "./sidechannel-schema";
import { ProtocolUtil } from "./util/protocol-util";

const SideChannel = function (channel) {
   const self = this;

   const pendingMaxTime = 30000; //max lifetime for not hooked sidechannel

   const CAP = {
      INVOKE: 1 << 0,
      HMAC: 1 << 2,
      NIMIRPC: 1 << 3,
      NO_TCP: 1 << 4
   };

   const COM_SUPPORT_MASK = 0x3f << 10;
   const CRYPTO_SUPPORT_MASK = 0x3 << 22;
   const ACTION_SHIFT = 24;

   /**
    * @private
    */
   this.capacity = CAP.INVOKE | CAP.NIMIRPC | CAP.NO_TCP;

   /**
    * @private
    */
   this.protocolHelper = ProtocolUtil.getHelper(sidechannelSchema);

   /**
    * @private
    * @type {Boolean}
    */
   this.opened = false;

   /**
    * @private
    * @type {Boolean}
    */
   this.ready = false;

   /**
    * @private
    * @type {function}
    */
   this.onReady = null;

   /**
    * @type {VDPService.Channel}
    */
   this.mainChannel = null;

   this.name = channel.name;
   this.id = channel.id;

   channel.onopen = function () {
      self.opened = true;
   };

   /**
    * Handler for messages
    * Will handle init and on invoke
    *
    * @private
    * @param  {object} The message event
    */
   this.onMessage = function (e) {
      if (!self.mainChannel) {
         Logger.error("main channel not found", Logger.VDP);
         return;
      }
      if (self.name.includes("html5mmr")) {
         // Html5 MMR does not use miniRPC schema, forward to main channel to parse it.
         self.mainChannel.onmessage(e);
         return;
      }
      if (!self.ready) {
         // skip check here
         if (!self.onReady) {
            Logger.error("onReady is not defined for the side channel", Logger.VDP);
            if (self.opened) {
               /**
                * Normally it should never go into this case, but use this to prevent misuse.
                * Clean up will be done in the side channel manager and onClose.
                */
               try {
                  channel.close();
               } catch (e) {
                  self.onClose();
                  Logger.exception(e, Logger.VDP);
               }
            }
            return;
         }
         self.onReady();
         return;
      }
      const reader = ProtocolUtil.getReader(new Uint8Array(e.data), true);
      const castConfig = {
         hmac: function (parsed) {
            if (!!parsed && parsed.capacity & CAP.HMAC) {
               return "Enable";
            } else {
               return "Disable";
            }
         }
      };
      const userRpc = self.protocolHelper.parse(reader, "RPC", castConfig);
      const header = userRpc.header;
      // always ignore hmac here
      if (header.format & self.COM_SUPPORT_MASK) {
         Logger.error("we don't support compress message", Logger.VDP);
      } else if (header.format & self.CRYPTO_SUPPORT_MASK) {
         Logger.error("we don't support crypto message", Logger.VDP);
      } else if (!(header.format & CAP.NIMIRPC)) {
         Logger.error("minirpc is disabled by Agent", Logger.VDP);
      } else {
         if (self.mainChannel.handleRPCInvoke) {
            const rpc = {
               params: [userRpc.payload],
               contexts: [header.objectId, header.requestId],
               action: header.format >> ACTION_SHIFT,
               command: header.command
            };
            self.mainChannel.handleRPCInvoke(rpc);
         }
      }
   };

   this.setAsPending = function (time) {
      if (self.pendingTimer) {
         Logger.error("can't set pending timer for a pending channel again", Logger.VDP);
         return;
      }
      self.pendingTimer = setTimeout(function () {
         Logger.info("side channel " + self.name + " has been pending too long, close it.", Logger.VDP);
         channel.close();
         self.clearPendingTimer();
      }, pendingMaxTime);
   };

   this.clearPendingTimer = function () {
      if (self.pendingTimer) {
         clearTimeout(self.pendingTimer);
         delete self.pendingTimer;
      }
   };

   /**
    * Called when channel is hooked
    *
    * @param  {object} info
    */
   this.hook = function (info) {
      self.ready = false;
      self.clearPendingTimer();
      self.mainChannel = info.mainChannel;
      if (self.mainChannel.secret) {
         if (self.opened) {
            self.setSecret(self.mainChannel.secret);
         } else {
            channel.onopen = function () {
               self.opened = true;
               self.setSecret(self.mainChannel.secret);
            };
         }
      }
      channel.onmessage = self.onMessage;
      self.onReady = function () {
         self.ready = true;
         info.resolve(self);
      };
   };

   /*
    * Invoke onDisconnect if the sideChannel.ready is true.
    */
   this.onClose = function () {
      Logger.info("side channel " + self.name + " disconnected", Logger.VDP);
      if (self.ready) {
         if (!!self.mainChannel && !!self.mainChannel.onDisconnect) {
            self.mainChannel.onDisconnect(self.name);
         } else {
            Logger.warning("onDisconnect listener has not been" + " set by the consumer.", Logger.VDP);
         }
      }
      self.clearPendingTimer();
      self.ready = false;
      self.opened = false;
   };

   /**
    * Send secret to Agent
    * @param  {secret} Optional Should contains id
    */
   this.sendSecret = function (secret) {
      if (!self.opened || self.ready) {
         Logger.error("Wrong status when try to send secret", Logger.VDP);
         return;
      }
      if (!secret) {
         if (!self.mainChannel || !self.mainChannel.secret) {
            Logger.error("Can't get secret from mainchannel when" + " try to send secret", Logger.VDP);
            return;
         }
         secret = self.mainChannel.secret;
      }
      channel.send(ProtocolUtil.arraySwap(new Uint8Array(secret.id)));
   };

   /**
    * Set secret for this sidechannel, currently only involve sending since
    * only limited format is supported.
    * @param  {secret} Should contains id
    */
   this.setSecret = function (secret) {
      self.sendSecret(secret);
   };

   /**
    * Invoke RPC to the Agent
    * @param  {object} rpc The standard rpc object
    */
   this.invoke = function (rpc) {
      if (!self.ready) {
         Logger.error("Can't invoke on " + self.name + " when not ready", Logger.VDP);
         return;
      }
      if (!rpc || !rpc.contexts || !rpc.params || !(rpc.params[0] instanceof Uint8Array)) {
         Logger.error("Can't invoke on " + self.name + " with invalid rpc", Logger.VDP);
         return;
      }
      const headerSize = 24;
      const format = (rpc.action << ACTION_SHIFT) | CAP.NIMIRPC;
      const writer = ProtocolUtil.getWriter(true);
      const dataObj = {
         header: {
            length: headerSize + rpc.params[0].length,
            capacity: self.capacity,
            format: format,
            command: rpc.command,
            objectId: rpc.contexts[0],
            requestId: rpc.contexts[1],
            hmac: {
               data: null
            }
         },
         payload: rpc.params[0].buffer
      };
      self.protocolHelper.streamify(writer, "RPC", dataObj, {
         hmac: "Disable"
      });
      channel.send(writer.getStream());
   };
};

export default SideChannel;
