/**
 * **************************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * ***************************************************************
 *
 * @format
 */

/**
 * @fileoverview rtavprotocolservicev2.js -- rtavProtocolServiceV2
 * Service implement RTAVV2 feature for WebClient
 */

import { Injectable } from "@angular/core";
import { protobufService } from "./protobuf.service";
import { RtavTypes } from "../rtav.constants";
import { Logger } from "@html-core";

@Injectable()
export class RTAVProtocolServiceV2 {
   private streamlizersMap;

   constructor() {
      this.streamlizersMap = {
         sendConfig: (deviceIndex, config) => {
            let serializedMsg = protobufService.serializeClientConfig(config);
            return this.getRtavV2ControlPacket32("PMsgSetConfig", 0, serializedMsg);
         },
         audioDeviceList: (deviceIndex, audioDeviceList) => {
            let serializedMsg = protobufService.serializeAudioDeviceList(audioDeviceList);
            return this.getRtavV2ControlPacket32("PMsgAudioDevices", 0, serializedMsg);
         },
         videoDeviceList: (deviceIndex, videoDeviceList) => {
            let serializedMsg = protobufService.serializeVideoDeviceList(videoDeviceList);
            return this.getRtavV2ControlPacket32("PMsgVideoDevices", 0, serializedMsg);
         },
         sendStartAudioAck: (deviceIndex, audioDevicePrefs) => {
            let serializedMsg = protobufService.serializeAudioDevicePref(audioDevicePrefs);
            return this.getRtavV2ControlPacket32("PMsgStart_A_Ack", deviceIndex, serializedMsg);
         },
         sendStartAudioNak: (deviceIndex) => {
            let networkArray = [];
            return this.getRtavV2ControlPacket32("PMsgStart_A_Ack_Err", deviceIndex, networkArray);
         },
         sendStartVideoAck: (deviceIndex, audioDevicePrefs) => {
            let serializedMsg = protobufService.serializeVideoDevicePref(audioDevicePrefs);
            return this.getRtavV2ControlPacket32("PMsgStart_V_Ack", deviceIndex, serializedMsg);
         },
         sendStartVideoNak: (deviceIndex) => {
            let networkArray = [];
            return this.getRtavV2ControlPacket32("PMsgStart_V_Ack_Err", deviceIndex, networkArray);
         },
         sendStopAudioAck: (deviceIndex) => {
            let networkArray = [];
            return this.getRtavV2ControlPacket32("PMsgStop_A_Ack", deviceIndex, networkArray);
         },
         sendStopAudioNak: (deviceIndex) => {
            let networkArray = [];
            return this.getRtavV2ControlPacket32("PMsgStop_A_Ack_Err", deviceIndex, networkArray);
         },
         sendStopVideoAck: (deviceIndex) => {
            let networkArray = [];
            return this.getRtavV2ControlPacket32("PMsgStop_V_Ack", deviceIndex, networkArray);
         },
         sendStopVideoNak: (deviceIndex) => {
            let networkArray = [];
            return this.getRtavV2ControlPacket32("PMsgStop_V_Ack_Err", deviceIndex, networkArray);
         },
         // TODO refactor
         sendStreamAudio: (deviceIndex, messageData) => {
            let streamData, streamHeader;
            if (!messageData) {
               Logger.error("trying to send a invalid audio stream", Logger.RTAV);
               return null;
            }
            streamData = messageData.data;
            streamHeader = new Uint32Array(streamData, 0, 5);
            streamHeader[0] = RtavTypes["PMsgBinData_A"];
            streamHeader[1] = deviceIndex;
            streamHeader[2] = streamData.byteLength;
            streamHeader[3] = messageData.timestamp;
            streamHeader[4] = messageData.dataCount;

            return new Uint8Array(streamData);
         },
         sendStreamVideo: (deviceIndex, messageData) => {
            let streamData, streamHeader;
            if (!messageData) {
               Logger.error("trying to send a invalid video stream", Logger.RTAV);
               return null;
            }
            streamData = messageData.data;
            streamHeader = new Uint32Array(streamData, 0, 5);
            streamHeader[0] = RtavTypes["PMsgBinData_V"];
            streamHeader[1] = deviceIndex;
            streamHeader[2] = streamData.byteLength;
            streamHeader[3] = messageData.timestamp;
            streamHeader[4] = messageData.dataCount;

            return new Uint8Array(streamData);
         }
      };
   }

   private getRtavV2ControlPacket32(type, deviceIndex, dataArray) {
      let dataHead,
         networkBytes,
         headerLength = 12,
         dataLength = dataArray.length + headerLength,
         typeCode = RtavTypes[type];
      if (typeof typeCode !== "number") {
         return;
      }
      Logger.debug(`sending code: ${typeCode}, type: ${type}`, Logger.RTAV);
      dataHead = [typeCode, deviceIndex, dataLength];
      let dataHeadUInit8 = new Uint8Array(new Uint32Array(dataHead).buffer);
      let dataArrayUInt8 = new Uint8Array(dataArray);
      let networkLength = dataHeadUInit8.length + dataArrayUInt8.length;
      networkBytes = new Uint8Array(networkLength);
      networkBytes.set(dataHeadUInit8);
      networkBytes.set(dataArrayUInt8, dataHeadUInit8.length);
      return networkBytes;
   }

   private getMessageStruct(message) {
      let headerLength = 12,
         header = new Uint32Array(message.data.slice(0, headerLength));
      if (header[2] !== message.data.byteLength) {
         return {
            type: "undefined"
         };
      }
      return {
         type: header[0],
         index: header[1],
         dataLength: header[2] - headerLength,
         data: message.data.slice(12)
      };
   }

   /**
    * use to get rtav protocol message object into binary stream.
    * @param {string} type The message type
    * @param {number} index device index
    * @param {object} data Optional The data that will be carried by the message
    * @return {object} This will returns the binary message constructed from the inputs
    */
   public getSendingBinary(type, index, data?) {
      let getStream = this.streamlizersMap[type];
      if (typeof getStream !== "function") {
         Logger.error("unknown type to be streamlized", Logger.RTAV);
         return null;
      }
      if (!!data) {
         return getStream(index, data);
      } else {
         return getStream(index);
      }
   }

   /**
    * use to parse rtav protocol message into object.
    * @param  {object} message The object contains binary message
    * @return {object} This will returns the parsed Object of input message
    */
   public parseReceivedMessageV2(message) {
      let adminPolicyArray,
         adminPolicy,
         rtavMessage = this.getMessageStruct(message),
         parsedMessage = {
            type: rtavMessage.type,
            index: rtavMessage.index,
            data: null
         };

      switch (rtavMessage.type) {
         case RtavTypes["PMsgGetConfig"]:
            adminPolicyArray = new Uint8Array(rtavMessage.data);
            adminPolicy = protobufService.deserializeAdminPolicy(adminPolicyArray);
            parsedMessage.data = adminPolicy;
            break;
         case RtavTypes["PMsgStart_A"]:
            let audioDevicePrefsArray = new Uint8Array(rtavMessage.data);
            let audioDevicePrefs = protobufService.deserializeAudioDevicePref(audioDevicePrefsArray);
            parsedMessage.data = audioDevicePrefs;
            break;
         case RtavTypes["PMsgStop_A"]:
            break;
         case RtavTypes["PMsgStart_V"]:
            let videoDevicePrefsArray = new Uint8Array(rtavMessage.data);
            let videoDevicePrefs = protobufService.deserializeVideoDevicePref(videoDevicePrefsArray);
            parsedMessage.data = videoDevicePrefs;
            break;
         case RtavTypes["PMsgStop_V"]:
         case RtavTypes["PMsgStartStream_A"]:
         case RtavTypes["PMsgStartStream_V"]:
            break;
         default:
            Logger.error("unknown msg", Logger.RTAV);
            Logger.error(rtavMessage.toString(), Logger.RTAV);
            return null;
      }
      Logger.debug("receive rtav v2 message:" + JSON.stringify(parsedMessage), Logger.RTAV);
      return parsedMessage;
   }
}
