/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../../core/libs/logger";
import { MediaType } from "../../media/media-resource-manager";
import { HTML5MMR_CONST, JKEYS, WEB_COMMAND } from "./html5MMR-consts";
import { Html5MMR } from "./html5MMR.media.manager";
import { Html5MMRChan } from "./html5MMRChan";
import {
   NewVideoElement,
   NotifyAudio,
   UpdateVolume,
   MediaStreamClone,
   TrackEnabled,
   UpdateSrcObject,
   UpdateVideo,
   RemoveVideo,
   MediaTrkStop,
   UpdateOverlay,
   UpdateDPI,
   CreateInstance
} from "./model/html5MMR.media.models";
import { HTML5MMR } from "./model/html5MMR.rpc.models";
import { webRTCAdapter } from "../../webrtc/webrtc-adapter";
import { VMPeerConnection } from "./vmPeerConnection";
import { Html5mmrRedirInstance } from "./Html5mmrRedirInstance";

import { HTML5MMR as HTML5E911Model } from "./model/html5MMR.e911.models";
import { Subscription } from "rxjs";
import { BusEvent, EventBusService } from "@html-core";

type CMDHandlingFunction = (cmdObj: Object) => void;

/**
 * WebRTC Player Instance Class implementation
 */
export class WebRTCInstance implements Html5mmrRedirInstance {
   private logger = new Logger(Logger.WEBRTC);

   private mediaService: Html5MMR.MediaService;
   private mmrChannel: Html5MMRChan.Client;
   private instanceId: number;

   private e911UpdateListener: Subscription = null;
   private eventBusService: EventBusService;

   // binary audio notify data
   private audioNotifyHeaderPos; // bytes of header already read
   private audioNotifyDataPos; // bytes of data already read
   private audioNotifyHeader: Uint8Array;
   private audioNotifyData: Uint8Array;
   private audioNotifyDataLen;

   private peerConnections: Array<any>; // indexed by the peer id
   private mediaIdMap: Map<string, string>; // map of: local stream/track id => remote stream/track id. Help us to modify sdp

   private BWEMap: Map<number, number>; // map of peerId => sender's band width estimation value

   /**
    * Construct a new WebRTCInstance with given instance id
    *
    * @param  {number} instanceId   given instance identifier
    * @param  {Html5MMR.MediaService} mediaService given media service
    * @param  {Html5MMRChan.Client} mmrChannel  given MMR Channel used to send response to server
    */
   constructor(
      instanceId: number,
      mediaService: Html5MMR.MediaService,
      eventBusService: EventBusService,
      mmrChannel: Html5MMRChan.Client,
      cmdObj: any
   ) {
      this.instanceId = instanceId;
      this.mediaService = mediaService;
      this.mmrChannel = mmrChannel;

      this.eventBusService = eventBusService;
      this.startE911Listener();

      this.audioNotifyHeaderPos = 0;
      this.audioNotifyDataPos = 0;
      this.audioNotifyDataLen = 0;
      this.audioNotifyHeader = new Uint8Array(HTML5MMR_CONST.AUD_NOTIFY_HEADER_LEN);

      this.peerConnections = [];
      this.mediaIdMap = new Map<string, string>();
      this.BWEMap = new Map<number, number>();

      const createInstanceRequest: CreateInstance.Request = new CreateInstance.Request(cmdObj);
      this.mediaService.handleCreateInstance(createInstanceRequest);

      // TODO: Consider to take care multiple session case
      // User might connect to multiple VMs, devices might have confliction among them.
      if (navigator.mediaDevices) {
         this.logger.info("WebRTCInstance starts listening to device change event.");
         navigator.mediaDevices.addEventListener("devicechange", this.onDeviceChanged.bind(this));
      }

      this.logger.info("WebRTCInstance created: " + instanceId);
   }

   private startE911Listener = () => {
      if (this.e911UpdateListener === null) {
         this.logger.info(`[${this.instanceId}] E911 Start listener`);

         const LastGeolocationPosition = this.mmrChannel.getLastGeolocationInfo();

         // geolocation info was save before subscription, and event won't be triggered again.
         if (LastGeolocationPosition) {
            const geoInfo: any = {};
            geoInfo[JKEYS.EVT] = HTML5MMR_CONST.VDIE911INFOCHANGED;
            if (
               LastGeolocationPosition.coords &&
               LastGeolocationPosition.coords.latitude &&
               LastGeolocationPosition.coords.longitude
            ) {
               geoInfo.latitude = LastGeolocationPosition.coords.latitude;
               geoInfo.longitude = LastGeolocationPosition.coords.longitude;
            }
            this.logger.info(`[${this.instanceId}] E911 message needs to be updated ************`); // don't print out geo loation privacy data
            return this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVENT_ON_E911_INFO_CHANGE, geoInfo);
         }

         this.e911UpdateListener = this.eventBusService.listen(BusEvent.E911InfoMsg.MSG_TYPE).subscribe((msg) => {
            const e911info: HTML5E911Model.E911Info = msg.data;
            this.logger.info(`[${this.instanceId}] E911 message needs to be updated ************`); // don't print out geo loation privacy data
            return this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVENT_ON_E911_INFO_CHANGE, e911info.responseObj);
         });
      }
   };

   private stopE911Listener = () => {
      if (this.e911UpdateListener !== null) {
         this.logger.info(`[${this.instanceId}] E911 Stop listener`);
         this.e911UpdateListener.unsubscribe();
      }
   };

   private appendLog = (cmdHandingFunction: CMDHandlingFunction, hanlderName: string): CMDHandlingFunction => {
      return (cmdObj) => {
         const peerId = cmdObj[JKEYS.PEER];
         this.logger.info("WebRTCInstance => handle " + hanlderName + " for peer: " + peerId);
         cmdHandingFunction(cmdObj);
         this.logger.info("WebRTCInstance => handle " + hanlderName + " done for peer: " + peerId);
      };
   };

   /**
    * Handle web text command for each webrtc instance
    *
    * @param  {number} cmd given command id number defined in html5MMR-const
    * @param  {any} cmdObj given command json object
    */
   public handleWebText = (cmd: number, cmdObj: any) => {
      if (cmd !== 51 && cmd !== 68) {
         this.logger.info(`WebRTCInstance [${this.instanceId}]: received web command: ` + JSON.stringify(cmdObj));
      }

      switch (cmd) {
         case WEB_COMMAND.NOTIFY_AUDIO:
            this.mediaService.notifyAudio(new NotifyAudio.Request(cmdObj));
            break;
         case WEB_COMMAND.ADD_VIDEO:
            this.handleAddvideo(new NewVideoElement.Request(cmdObj));
            break;
         case WEB_COMMAND.NEW_AUDIO_ELEMENT:
            this.handleNewAudioElement(cmdObj);
            break;
         case WEB_COMMAND.TRACK_ENABLED:
            this.handleTrackEnabled(new TrackEnabled.Request(cmdObj));
            break;
         case WEB_COMMAND.GETUSERMEDIASHIM:
            this.handleGetUserMedia(cmdObj);
            break;
         case WEB_COMMAND.GETDISPLAYMEDIASHIM:
            this.handleGetDisplayMedia(cmdObj);
            break;
         case WEB_COMMAND.MEDIA_STREM_CLONE:
            this.handleMediaStreamClone(new MediaStreamClone.Request(cmdObj));
            break;
         case WEB_COMMAND.UPDATE_SRCOBJECT:
            this.handleUpdateSrcObject(new UpdateSrcObject.Request(cmdObj));
            break;
         case WEB_COMMAND.UPDATE_VIDEO:
         case WEB_COMMAND.UPDATE_OBJECTFIT:
            this.handleUpdateVideo(new UpdateVideo.Request(cmdObj));
            break;
         case WEB_COMMAND.REMOVE_VIDEO:
            this.handleRemoveVideo(new RemoveVideo.Request(cmdObj));
            break;
         case WEB_COMMAND.MEDIATRK_STOP:
            this.handleMediaTrkStop(new MediaTrkStop.Request(cmdObj));
            break;
         case WEB_COMMAND.CREATE_PEERCONNECTION:
            this.handleCreatePeerConnection(cmdObj);
            break;
         case WEB_COMMAND.ADD_STREAM:
            this.handleAddStream(cmdObj);
            break;
         case WEB_COMMAND.REMOVE_STREAM:
            this.handleRemoveStream(cmdObj);
            break;
         case WEB_COMMAND.CREATE_OFFER:
            this.handleCreateOffer(cmdObj);
            break;
         case WEB_COMMAND.CREATE_ANSWER:
            this.handleCreateAnswer(cmdObj);
            break;
         case WEB_COMMAND.SET_LOCALDESCRIPTION:
            this.handleSetLocalDescription(cmdObj);
            break;
         case WEB_COMMAND.SET_REMOTEDESCRIPTION:
            this.handleSetRemoteDescription(cmdObj);
            break;
         case WEB_COMMAND.CLOSE:
            this.handleClosePeerConnection(cmdObj);
            break;
         case WEB_COMMAND.GET_STATS:
            this.handleGetStatsDiff(cmdObj);
            break;
         case WEB_COMMAND.GET_STATS_2:
            this.handleGetStats(cmdObj);
            break;
         case WEB_COMMAND.GET_RECEIVERS:
            this.handleGetReceivers(cmdObj);
            break;
         case WEB_COMMAND.APPLY_CONSTRAINTS:
            this.handleApplyConstraints(cmdObj);
            break;
         case WEB_COMMAND.ADD_ICECANDICATE:
            this.handleAddIceCandidate(cmdObj);
            break;
         case WEB_COMMAND.ADD_TRACK:
            this.handleAddTrack(cmdObj);
            break;
         case WEB_COMMAND.REMOVE_TRACK:
            this.handleRemoveTrack(cmdObj);
            break;
         case WEB_COMMAND.CREATE_MEDIASTREAM:
            this.handleCreateMediaStream(cmdObj);
            break;
         case WEB_COMMAND.WEBRTC_TRANSCEIVER_CMD:
            this.handleTransceiverCmd(cmdObj);
            break;
         case WEB_COMMAND.SENDER_CMDS:
            this.handleSenderCmd(cmdObj);
            break;
         case WEB_COMMAND.CONNECTED_STATE_CHANGE:
            this.handleConnectedStateChanged(cmdObj);
            break;
         case WEB_COMMAND.INSERT_DTMF:
            this.handleInsertDTMF(cmdObj);
            break;
         case WEB_COMMAND.WEBRTC_DATACHANNEL_CMD:
            this.handleDataChannelCommand(cmdObj);
            break;
         default:
            Logger.error(
               "WebRTCInstance received unexpected web command: " + cmd + ", " + JSON.stringify(cmdObj),
               Logger.HTML5MMR
            );
            break;
      }
   };

   /**
    * Handle mediaStreamClone WEBTEXT command
    *
    * @param  {MediaStreamClone.Request} cmdObj
    */
   private handleMediaStreamClone = (cmdObj: MediaStreamClone.Request) => {
      const sid: string = cmdObj.streamClone.sid,
         cid: string = cmdObj.streamClone.cid,
         localStream: MediaStream = this.mediaService.getStream(sid);

      if (!localStream) {
         this.logger.error("WebRTCInstance => handle mediaStreamClone, local stream was freed.");
         return;
      }

      const cloneStream = new MediaStream();
      cmdObj.trackClones.forEach((trackPair) => {
         if (trackPair.tid === null || trackPair.cid === null) {
            this.logger.error("Invalid track to be cloned.");
            return;
         }

         const localTrack = this.mediaService.getTrack(trackPair.tid);
         let cloneTrack = this.mediaService.getTrack(trackPair.cid);
         if (cloneTrack) {
            this.logger.info(`Track: ${trackPair.cid} already cloned.`);
            return;
         }
         if (!localTrack) {
            this.logger.info(`Local track: ${trackPair.tid} was freed.`);
            return;
         }
         cloneTrack = localTrack.clone();
         cloneStream.addTrack(cloneTrack);

         this.mediaService.updateTrack(trackPair.cid, cloneTrack);
         this.updateMediaIdMap(cloneTrack.id, trackPair.cid);
      });

      if (cloneStream.getTracks().length) {
         this.mediaService.updateStream(cid, cloneStream);
         this.updateMediaIdMap(cloneStream.id, cid);
      }
   };

   /**
    * Handle add video command(32) from server.
    *
    * @param {object} cmdObj
    *    The incoming command object.
    */
   private handleAddvideo = (cmdObj: NewVideoElement.Request) => {
      this.logger.debug("Html5MMR WebRTCInstance handle new video element cmd: " + JSON.stringify(cmdObj));
      const newVideoElem: HTMLVideoElement = this.mediaService.createNewVideoElement(
         this.instanceId,
         cmdObj
      ) as HTMLVideoElement;
      if (newVideoElem === null) {
         this.logger.error(`Cannot add new video element ${cmdObj.videoId}`);
         return;
      }
      newVideoElem.onloadedmetadata = () => {
         const response: any = {};
         response[JKEYS.EVT] = HTML5MMR_CONST.LOADEDMETADATA;
         response[JKEYS.ID] = -1;
         response[JKEYS.VIDEOID] = cmdObj.videoId;
         response[JKEYS.VIDEO_WIDTH] = newVideoElem.videoWidth;
         response[JKEYS.VIDEO_HEIGHT] = newVideoElem.videoHeight;

         this.logger.info(
            `onloadedmetadata video[${cmdObj.videoId}] [w,h] = [${newVideoElem.videoWidth}, ${newVideoElem.videoHeight}]`
         );
         this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVT, response);
      };
   };

   /**
    * Handler of removeVideoElement WEBTEXT command
    *
    * @param  {RemoveVideo.Request} cmdObj
    */
   private handleRemoveVideo = (cmdObj: RemoveVideo.Request) => {
      this.logger.debug("Html5MMR WebRTCInstance handle remove video element cmd: " + JSON.stringify(cmdObj));
      this.mediaService.removeVideoElement(cmdObj);
   };

   /**
    * Handler of updateVideoElement WEBTEXT command
    *
    * @param  {UpdateVideo.Request} cmdObj
    */
   private handleUpdateVideo = (cmdObj: UpdateVideo.Request) => {
      this.logger.debug("Html5MMR WebRTCInstance handle update video cmd: " + JSON.stringify(cmdObj));
      this.mediaService.updateVideoElement(cmdObj);
   };

   /**
    * Handler of updateSrcObject WEBTEXT command
    *
    * @param  {UpdateSrcObject.Request} cmdObj
    */
   private handleUpdateSrcObject = (cmdObj: UpdateSrcObject.Request) => {
      this.logger.debug("Html5MMR WebRTCInstance handle update src object cmd: " + JSON.stringify(cmdObj));
      const mediaStreamId: string = cmdObj.mediaStreamId,
         mediaType: MediaType = cmdObj.type;
      let elemId: string = "";
      if (mediaType === MediaType.video) {
         elemId = cmdObj.videoId + "";
      } else if (mediaType === MediaType.audio) {
         elemId = cmdObj.audioId + "";
      }
      this.mediaService.updateSrcObject(mediaType, mediaStreamId, elemId);
   };

   /**
    * Handler of trackEnabled WEBTEXT command
    *
    * @param  {TrackEnabled.Request} cmdObj
    */
   private handleTrackEnabled = (cmdObj: TrackEnabled.Request) => {
      this.logger.debug("Html5MMR WebRTCInstance handle track enabled cmd: " + JSON.stringify(cmdObj));
      this.mediaService.enableTrack(cmdObj.trackId, cmdObj.enabled);
   };

   /**
    * Handler of mediaTrkStop WEBTEXT command
    *
    * @param  {MediaTrkStop.Request} cmdObj
    */
   private handleMediaTrkStop = (cmdObj: MediaTrkStop.Request) => {
      this.logger.debug("Html5MMR WebRTCInstance handle media track stop cmd: " + JSON.stringify(cmdObj));
      this.mediaService.stopTrack(cmdObj);
   };

   /**
    * Handle update environment command for each webrtc instance
    *
    * @param  {HTML5MMR.EnvFlag} flag given environment flag
    * @param  {any} cmdObj given command json object
    */
   public handleEnvUpdate = (flag: HTML5MMR.EnvFlag, cmdObj: any) => {
      switch (flag) {
         case HTML5MMR.EnvFlag.VOLUME:
            this.mediaService.updateVolume(new UpdateVolume.Request(cmdObj));
            break;
         case HTML5MMR.EnvFlag.DPI:
            this.mediaService.handleUpdateDPI(new UpdateDPI.Request(cmdObj));
            break;
         default:
            this.logger.error("WebRTCInstance received unexpected env update: " + flag + ", " + JSON.stringify(cmdObj));
            break;
      }
   };

   /**
    * Handle update video overlay
    *
    * @param  {HTML5MMR.MMRUpdateOverlayRequest} cmdObj
    * @param  {number} remoteDPIScaleFactor
    */
   public handleUpdateOverlay = (cmdObj: HTML5MMR.MMRUpdateOverlayRequest, remoteDPIScaleFactor: number) => {
      this.mediaService.handleUpdateOverlay(new UpdateOverlay.Request(cmdObj, remoteDPIScaleFactor));
   };

   /**
    * Handle binary data
    *
    * @param  {Uint8Array} data     stream of bytes from server
    * @param  {number} dataLength   data length of given binary data
    * @param  {number} streamEnum   flag of binary stream status
    */
   public handleBinaryData = (data: Uint8Array, dataLength: number, streamEnum: number) => {
      if (dataLength > 0) {
         const res = this.consumeBinaryData(data, dataLength);
         if (!res) {
            this.logger.error("WebRTCInstance => consume binary data failed.");
            this.resetBinaryData();
            return;
         }
      }

      if (streamEnum === HTML5MMR_CONST.BINARYSTREAM_END) {
         if (this.audioNotifyDataPos >= this.audioNotifyDataLen) {
            const audioId = this.read32Bits(this.audioNotifyHeader, HTML5MMR_CONST.AUD_NOTIFY_ID_OFFSET);
            this.mediaService.onBinaryNotifyAudio(audioId, this.audioNotifyData);
         } else {
            this.logger.error(
               "Only received " +
                  this.audioNotifyDataPos +
                  " bytes of " +
                  this.audioNotifyDataLen +
                  " bytes, discard this data."
            );
         }
         this.resetBinaryData();
      } else if (streamEnum !== HTML5MMR_CONST.BINARYSTREAM_READREADY) {
         this.logger.error("Received unexpected binary stream status: " + streamEnum);
         this.resetBinaryData();
      }
   };

   /**
    * Handle getUserMediaShim command(54) from server.
    *
    * @param {object} rpc
    *    The incoming RPC object.
    * @returns {boolean}
    */
   private handleGetUserMedia = (cmdObj: any) => {
      this.logger.debug("Html5MMR WebRTCInstance handle getUserMediaShim cmd: " + JSON.stringify(cmdObj));

      const constraints = this.getConstraints(cmdObj.constraints);
      webRTCAdapter
         .getUserMedia(constraints)
         .then((mediaStream) => {
            if (mediaStream) {
               this.mediaService.addStream(mediaStream);
               const respObj = this.getStreamDetails(mediaStream, "vdi");
               if (!respObj) {
                  this.sendErrorEvent(
                     WEB_COMMAND.GETUSERMEDIASHIM,
                     cmdObj[JKEYS.ID],
                     -1,
                     HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR
                  );
                  return;
               }
               respObj[JKEYS.EVT] = HTML5MMR_CONST.GETUSERMEDIA;
               respObj[JKEYS.ID] = cmdObj[JKEYS.ID];

               this.sendRTCResponse(WEB_COMMAND.GETUSERMEDIASHIM, respObj);
            } else {
               this.sendErrorEvent(
                  WEB_COMMAND.GETUSERMEDIASHIM,
                  cmdObj[JKEYS.ID],
                  -1,
                  HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR
               );
            }
         })
         .catch((error) => {
            this.logger.error(
               "Html5MMR WebRTCInstance failed to get media stream with: " + error.name + ", " + error.massage
            );
            this.sendRTCError(WEB_COMMAND.GETUSERMEDIASHIM, cmdObj[JKEYS.ID], -1, cmdObj[JKEYS.CMD], error);
            return;
         });
   };

   /**
    * Handle getDisplayMediaShim command(63) from server.
    *
    * @param {object} rpc
    *    The incoming RPC object.
    * @returns {boolean}
    */
   private handleGetDisplayMedia = (cmdObj: any) => {
      // From Chrome 109 for some unknown reason, current horizon tab is not shown in 'content to share' tab option
      // To workaround this, give the default "include" value to the option
      cmdObj.constraints["selfBrowserSurface"] = "include";
      this.logger.debug("Html5MMR WebRTCInstance handle getDisplayMediaShim cmd: " + JSON.stringify(cmdObj));

      webRTCAdapter
         .getDisplayMedia(cmdObj.constraints)
         .then((mediaStream) => {
            if (mediaStream) {
               this.mediaService.addStream(mediaStream);
               const videoTracks = mediaStream.getVideoTracks();
               if (videoTracks) {
                  videoTracks[0].onended = () => {
                     this.handleStopShare();
                  };
               }
               const respObj = this.getStreamDetails(mediaStream, "desktop");
               if (!respObj) {
                  this.sendErrorEvent(
                     WEB_COMMAND.GETDISPLAYMEDIASHIM,
                     cmdObj[JKEYS.ID],
                     -1,
                     HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR
                  );
                  return;
               }
               respObj[JKEYS.EVT] = HTML5MMR_CONST.GETDISPLAYMEDIASHIM;
               respObj[JKEYS.ID] = cmdObj[JKEYS.ID];

               this.sendRTCResponse(WEB_COMMAND.GETDISPLAYMEDIASHIM, respObj);
            } else {
               this.sendErrorEvent(
                  WEB_COMMAND.GETDISPLAYMEDIASHIM,
                  cmdObj[JKEYS.ID],
                  -1,
                  HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR
               );
            }
         })
         .catch((error) => {
            this.logger.error(
               "Html5MMR WebRTCInstance failed to get display media stream with: " + error.name + ", " + error.massage
            );
            this.sendRTCError(WEB_COMMAND.GETDISPLAYMEDIASHIM, cmdObj[JKEYS.ID], -1, cmdObj[JKEYS.CMD], error);
            return;
         });
   };

   /**
    * Send back RTC related error message
    *
    * @param  {number} evtId, the related event/command id
    * @param  {number} reqId, the related request id
    * @param  {number} peerId, the related peer connection id
    * @param  {string} evtName, the related event name
    * @param  {object} error, object contains error details
    */
   private sendRTCError = (evtId, reqId, peerId, evtName, error) => {
      const errEvt = {};
      errEvt[JKEYS.ID] = reqId;
      errEvt[JKEYS.PEER] = peerId || -1;
      errEvt[JKEYS.EVT] = evtName;

      errEvt[JKEYS.ERROR] = {};
      errEvt[JKEYS.ERROR][JKEYS.NAME] = error.name || "";
      errEvt[JKEYS.ERROR][JKEYS.MESSAGE] = error.message || "";
      if (error.code) {
         errEvt[JKEYS.ERROR][JKEYS.CODE] = error.code;
      }

      this.sendRTCResponse(evtId, errEvt);
   };

   /**
    * Put local media stream details into an object. This part of code will be shared with
    * getUserMedia and getDisplayMedia
    *
    * @param {MediaStream} mediaStream
    * @param {string} contentHintPrefix, to differentiate getUserMedia and getDisplayMedia
    */
   private getStreamDetails = (mediaStream, contentHintPrefix) => {
      const respObj = {};
      respObj[JKEYS.STREAM] = {};
      respObj[JKEYS.STREAM][JKEYS.ACTIVE] = mediaStream.active;
      respObj[JKEYS.STREAM][JKEYS.ID] = mediaStream.id;

      const tracks = mediaStream.getTracks();
      if (!tracks || tracks.length < 1) {
         return null;
      }
      respObj[JKEYS.STREAM][JKEYS.TRACK] = [];
      tracks.forEach((track) => {
         const trackInfo = {};
         const trackSettings = track.getSettings();
         trackInfo[JKEYS.CONTENT_HINT] =
            track.kind === "video" ? contentHintPrefix + "Video" : contentHintPrefix + "Audio";
         trackInfo[JKEYS.ENABLED] = track.enabled;
         trackInfo[JKEYS.ID] = track.id;
         trackInfo[JKEYS.ISOLATED] = false;
         trackInfo[JKEYS.KIND] = track.kind;
         trackInfo[JKEYS.LABEL] = track.label;
         trackInfo[JKEYS.MUTED] = track.muted;
         trackInfo[JKEYS.READ_ONLY] = true;
         trackInfo[JKEYS.READY_STATE] = track.readyState;
         trackInfo[JKEYS.REMOTE] = false;

         trackInfo[JKEYS.SETTINGS] = {};
         trackInfo[JKEYS.SETTINGS][JKEYS.DEVICE_ID] = trackSettings.deviceId;
         if (track.kind === "video") {
            trackInfo[JKEYS.SETTINGS][JKEYS.ASPECT_RATIO] = trackSettings.aspectRatio;
            trackInfo[JKEYS.SETTINGS][JKEYS.FRAME_RATE] = trackSettings.frameRate;
            trackInfo[JKEYS.SETTINGS][JKEYS.HEIGHT] = trackSettings.height;
            trackInfo[JKEYS.SETTINGS][JKEYS.WIDTH] = trackSettings.width;
         }

         respObj[JKEYS.STREAM][JKEYS.TRACK].push(trackInfo);
      });
      return respObj;
   };

   /**
    * Handle the case that user clicks 'Stop Share' from browser (not in our app)
    */
   private handleStopShare = () => {
      // This is the temoproray solution to let Teams stop screen sharing.
      const eventData = {};
      eventData[JKEYS.EVT] = HTML5MMR_CONST.VDISCREENTOPOLOGYCHANGED;
      this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVT, eventData);
   };

   /**
    * Parse params in incoming getUserMedia request and generate an appropriate constraints object
    * @returns {object} the result constraints
    */
   private getConstraints = (cons) => {
      const resConstraints = {};
      if (!cons) {
         this.logger.error("Html5MMR SessionMgr failed to get constraits with empty cons.");
         return null;
      }
      if (cons.video) {
         resConstraints["video"] = {};
         if (cons.video.mandatory) {
            const mandatory = cons.video.mandatory; // just for a short name
            if (mandatory.sourceId) {
               resConstraints["video"]["deviceId"] = mandatory.sourceId;
            }
            if (mandatory.minWidth || mandatory.maxWidth) {
               resConstraints["video"]["width"] = {};
               if (mandatory.minWidth) {
                  resConstraints["video"]["width"]["min"] = mandatory.minWidth;
               }
               if (mandatory.maxWidth) {
                  resConstraints["video"]["width"]["max"] = mandatory.maxWidth;
               }
            }

            if (mandatory.minHeight || mandatory.maxHeight) {
               resConstraints["video"]["height"] = {};
               if (mandatory.minHeight) {
                  resConstraints["video"]["height"]["min"] = mandatory.minHeight;
               }
               if (mandatory.maxHeight) {
                  resConstraints["video"]["height"]["max"] = mandatory.maxHeight;
               }
            }

            if (mandatory.minFrameRate || mandatory.maxFrameRate) {
               resConstraints["video"]["frameRate"] = {
                  ideal: HTML5MMR_CONST.DEFAULT_CONSTRAITS.VIDEO.frameRate
               };
               if (mandatory.minFrameRate) {
                  resConstraints["video"]["frameRate"]["min"] = mandatory.minFrameRate;
               }
               if (mandatory.maxFrameRate) {
                  resConstraints["video"]["frameRate"]["max"] = mandatory.maxFrameRate;
               }
            }
         } else {
            resConstraints["video"] = HTML5MMR_CONST.DEFAULT_CONSTRAITS.VIDEO;
         }
      }
      if (cons.audio) {
         if (cons.audio.deviceId) {
            resConstraints["audio"] = {
               deviceId: cons.audio.deviceId
            };
         } else {
            resConstraints["audio"] = true;
         }
      }
      return resConstraints;
   };

   /**
    * Handle binary data
    *
    * @param  {Uint8Array} data  stream of bytes from server
    * @param  {number} length length of given binary data
    *
    * @return {boolean} sucess or failure
    */
   private consumeBinaryData = (data, length) => {
      if (length <= 0) {
         return false;
      }
      let readPos = 0;
      let left = length; // unread data length
      // consume header
      if (this.audioNotifyHeaderPos < HTML5MMR_CONST.AUD_NOTIFY_HEADER_LEN) {
         const need = HTML5MMR_CONST.AUD_NOTIFY_HEADER_LEN - this.audioNotifyHeaderPos;
         if (need > left) {
            // copy all data to header, return
            this.audioNotifyHeader.set(data.subarray(0, left), this.audioNotifyHeaderPos);
            this.audioNotifyHeaderPos += left;
            return true;
         }

         this.audioNotifyHeader.set(data.subarray(0, need), this.audioNotifyHeaderPos);
         this.audioNotifyHeaderPos = HTML5MMR_CONST.AUD_NOTIFY_HEADER_LEN;
         left -= need;
         readPos += need;

         this.audioNotifyData = null;
         const header = this.read32Bits(this.audioNotifyHeader, 0);
         if (header === HTML5MMR_CONST.AUD_NOTIFY_SIGNATURE) {
            this.audioNotifyDataLen =
               this.read32Bits(this.audioNotifyHeader, HTML5MMR_CONST.AUD_NOTIFY_SIGNATURE_LEN) -
               HTML5MMR_CONST.AUD_NOTIFY_HEADER_LEN;
            this.audioNotifyData = new Uint8Array(this.audioNotifyDataLen);
         } else {
            this.logger.error("Unsupported audio notify data header: " + header);
            this.resetBinaryData();
         }
      }
      // consume data
      const dataNeeded = this.audioNotifyDataLen - this.audioNotifyDataPos;
      const toCopy = Math.min(dataNeeded, left);
      this.audioNotifyData.set(data.subarray(readPos, toCopy), this.audioNotifyDataPos);
      this.audioNotifyDataPos += toCopy;

      return true;
   };

   /**
    * Clear up binary data buffer and position marks
    *
    */
   private resetBinaryData = () => {
      this.audioNotifyHeaderPos = 0;
      this.audioNotifyDataPos = 0;
      this.audioNotifyDataLen = 0;
      this.audioNotifyHeader = null;
      this.audioNotifyData = null;

      this.audioNotifyHeader = new Uint8Array(HTML5MMR_CONST.AUD_NOTIFY_HEADER_LEN);
   };

   /**
    * Read 4 bytes of an Uint8Array with given position
    *
    * @param  {Uint8Array} data  Uint8 data array
    * @param  {number} offset position start to read
    *
    * @return {number} the integer number that these 4 bytes represnt
    */
   private read32Bits = (data, offset) => {
      let res = null;
      res =
         ((0xff & data[0 + offset]) << 24) |
         ((0xff & data[1 + offset]) << 16) |
         ((0xff & data[2 + offset]) << 8) |
         (0xff & data[3 + offset]);

      return res;
   };

   /**
    * Set preferred speaker for specified audio id.
    *
    * @param  {object} audioDevice  the audio device
    * @param  {number} audioId  the related audio id
    */
   public setSinkId = (audioDevice, audioId) => {
      this.logger.info(
         "WebRTCInstance => handle setSinkId for deviceId: " + audioDevice.deviceId + ", audioId: " + audioId
      );
      const audioElement: any = this.mediaService.createNewAudioElement(audioId);
      audioElement.setSinkId(audioDevice.deviceId);
   };

   /**
    * Create a RTCPeerConnection with given configuration.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleCreatePeerConnection = (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      this.logger.info("WebRTCInstance => handle create peerconnection for peer: " + peerId);
      if (!cmdObj[JKEYS.ARG1]) {
         this.logger.warning("Create peerconnection without given configuration.");
      }
      const configurations = cmdObj[JKEYS.ARG1];
      const options = cmdObj[JKEYS.ARG2];

      if (this.peerConnections[peerId]) {
         this.peerConnections[peerId].close();
         this.peerConnections[peerId] = null;
      }

      this.peerConnections[peerId] = new VMPeerConnection(peerId, this, configurations, options);
      if (!this.peerConnections[peerId]) {
         this.logger.error("WebRTCInstance => handle create peerconnection failed for peer: " + peerId);
         this.sendErrorEvent(
            WEB_COMMAND.CREATE_PEERCONNECTION,
            cmdObj[JKEYS.ID],
            -1,
            HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR
         );
         return;
      }

      this.peerConnections[peerId].init();
      this.logger.info("WebRTCInstance => handle create peerconnection done for peer: " + peerId);
   };

   /**
    * Handle createOffer command with given options. And then send back to agent.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleCreateOffer = (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      const options = cmdObj[JKEYS.OPTIONS];
      this.logger.info(
         "WebRTCInstance => Create offer for peer: " + peerId + ", with given options: " + JSON.stringify(options)
      );
      const pc = this.peerConnections[peerId];
      if (!pc) {
         this.logger.error("WebRTCInstance => Handle create offer failed, could not find peerconnection: " + peerId);
         return;
      }

      pc.createOffer(options) // use promise chain format to capture DOMException
         .then((localOffer) => {
            localOffer.sdp = this.replaceClientStreamId(localOffer.sdp);
            const respObj = {
               evt: "createOffer",
               id: cmdObj[JKEYS.ID],
               peer: peerId,
               desc: localOffer,
               details: pc.getPCDetails()
            };
            this.sendRTCResponse(WEB_COMMAND.CREATE_OFFER, respObj);
            this.logger.info("WebRTCInstance => Handle create offer done for peer: " + peerId);
         })
         .catch((error) => {
            this.logger.error(
               "WebRTCInstance => Handle create offer failed with given options: " + JSON.stringify(options)
            );
            const event = {};
            event[JKEYS.ID] = cmdObj[JKEYS.ID];
            event[JKEYS.PEER] = peerId;
            event[JKEYS.EVT] = "createOffer";
            event[JKEYS.ERROR] = {};
            event[JKEYS.ERROR][JKEYS.CODE] = error.code;
            event[JKEYS.ERROR][JKEYS.NAME] = error.name;
            event[JKEYS.ERROR][JKEYS.MESSAGE] = error.name;
            event[JKEYS.DETAILS] = pc.getPCDetailsEx();
            if (pc.isUnifiedPlan) {
               event[JKEYS.TRANSCEIVERS] = pc.getTransceiversInfo(true);
            }

            this.sendRTCResponse(WEB_COMMAND.CREATE_OFFER, event);
         });
   };

   /**
    * Handle createAnswer command. And then send back to agent.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleCreateAnswer = (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      this.logger.info("WebRTCInstance => Handle create answer for peer: " + peerId);
      const pc = this.peerConnections[peerId];
      if (!pc) {
         this.logger.error("WebRTCInstance => Handle create answer failed, could not find peerconnection: " + peerId);
         return;
      }

      pc.createAnswer() // use promise chain format to capture DOMException
         .then((answer) => {
            answer.sdp = this.replaceClientStreamId(answer.sdp);
            const respObj = {
               evt: "createAnswer",
               id: cmdObj[JKEYS.ID],
               peer: peerId,
               desc: answer
            };
            this.sendRTCResponse(WEB_COMMAND.CREATE_ANSWER, respObj);
            this.logger.info("WebRTCInstance => Handle create answer done for peer: " + peerId);
         })
         .catch((error) => {
            this.logger.error("WebRTCInstance => Handle create answer failed with error: " + JSON.stringify(error));
            const event = {};
            event[JKEYS.ID] = cmdObj[JKEYS.ID];
            event[JKEYS.PEER] = peerId;
            event[JKEYS.EVT] = "createAnswer";
            event[JKEYS.ERROR] = {};
            event[JKEYS.ERROR][JKEYS.CODE] = error.code;
            event[JKEYS.ERROR][JKEYS.NAME] = error.name;
            event[JKEYS.ERROR][JKEYS.MESSAGE] = error.name;
            event[JKEYS.DETAILS] = pc.getPCDetailsEx();
            if (pc.isUnifiedPlan) {
               event[JKEYS.TRANSCEIVERS] = pc.getTransceiversInfo(true);
            }

            this.sendRTCResponse(WEB_COMMAND.CREATE_ANSWER, event);
         });
   };

   /**
    * Handle setLocalDescription command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleSetLocalDescription = async (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      this.logger.info("WebRTCInstance => handle setLocalDescription for peer: " + peerId);
      const pc = this.peerConnections[peerId];
      const desc = cmdObj[JKEYS.DESC];
      desc.sdp = this.replaceServerStreamId(desc.sdp);
      if (pc) {
         try {
            await pc.setLocalDescription(desc);
            const respObj = {
               evt: "setLocalDescription",
               id: cmdObj[JKEYS.ID],
               peer: peerId
            };
            if (pc.isUnifiedPlan) {
               const removeTransceivers = pc.UpdateTransceivers();
               respObj[JKEYS.TRANSCEIVERS] = pc.getTransceiversInfo(true);
               if (removeTransceivers.length) {
                  respObj[JKEYS.TRANSCEIVERS_REMOVED] = removeTransceivers;
               }
            }
            this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVT, respObj);
            this.logger.info("WebRTCInstance => handle setLocalDescription done for peer: " + peerId);
         } catch (error) {
            this.logger.error("WebRTCInstance => handle setLocalDescription failed with given sdp: " + desc.sdp);
         }
      }
   };

   /**
    * Handle setRemoteDescription command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleSetRemoteDescription = async (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      this.logger.info("WebRTCInstance => handle setRemoteDescription for peer: " + peerId);
      const pc = this.peerConnections[peerId];
      const desc = cmdObj[JKEYS.DESC];
      desc.sdp = this.replaceServerStreamId(desc.sdp);
      this.parseBWE(desc.sdp, peerId);

      if (pc) {
         try {
            await pc.setRemoteDescription(desc);
            const respObj = {
               evt: "setRemoteDescription",
               id: cmdObj[JKEYS.ID],
               peer: peerId
            };
            if (pc.isUnifiedPlan) {
               const removeTransceivers = pc.UpdateTransceivers();
               respObj[JKEYS.TRANSCEIVERS] = pc.getTransceiversInfo(true);
               if (removeTransceivers.length) {
                  respObj[JKEYS.TRANSCEIVERS_REMOVED] = removeTransceivers;
               }
            }
            this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVT, respObj);
            this.logger.info("WebRTCInstance => handle setRemoteDescription done for peer: " + peerId);
         } catch (error) {
            this.logger.error("WebRTCInstance => SetRemoteDescription failed with given sdp: " + desc.sdp);
         }
      }
   };

   /**
    * Handle add stream to peerconnection command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleAddStream = this.appendLog((cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      const streamId = cmdObj[JKEYS.SID];
      this.logger.info("WebRTCInstance => handle addStream for peer: " + peerId);
      if (this.peerConnections[peerId]) {
         this.peerConnections[peerId].addStream(this.mediaService.getStream(streamId), streamId);
      }
   }, "add stream");

   /**
    * Handle remove stream to peerconnection command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleRemoveStream = this.appendLog((cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      const streamId = cmdObj[JKEYS.SID];
      this.logger.info("WebRTCInstance => handle removeStream for peer: " + peerId);

      if (this.peerConnections[peerId]) {
         this.peerConnections[peerId].removeStream(this.mediaService.getStream(streamId), streamId);
      }
   }, "remove stream");

   /**
    * Handle close peerconnection command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleClosePeerConnection = this.appendLog((cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];

      if (this.peerConnections[peerId]) {
         this.peerConnections[peerId].close();
         this.peerConnections[peerId] = null;
      }
   }, "close peer");

   /**
    * Handle create new audio element command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleNewAudioElement = (cmdObj) => {
      const audioId = cmdObj[JKEYS.AUDIO_ID];
      this.logger.info("WebRTCInstance => handle create audio element for audio: " + audioId);
      this.mediaService.createNewAudioElement(audioId);
      this.logger.info("WebRTCInstance => handle create audio element done for audio: " + audioId);
   };

   /**
    * Handle getStat2(68) command.
    * cmdObj.capability will indicate the WebRTC version
    * And will apply different hanlder. Because the report format
    * are totally different.
    *
    * @param  {object} cmdObj given command json object
    *
    */
   private handleGetStats = async (cmdObj) => {
      if (cmdObj[JKEYS.CAPABILITY]) {
         this.handleGetStatsV1(cmdObj);
      } else {
         this.handleGetStatsV0(cmdObj);
      }
   };

   private handleGetStatsV0 = async (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];

      if (this.peerConnections[peerId]) {
         this.peerConnections[peerId].getStatsV0(
            this.onGetStatsV0Success.bind(this, cmdObj[JKEYS.ID], peerId),
            this.onGetStatsV0Failure.bind(this, peerId)
         );
      }
   };

   private onGetStatsV0Success = (reqId, peerId, response) => {
      const reports = response.result();

      if (reports.length > 0) {
         const dataArray = [];
         reports.forEach((report) => {
            const standardReport = {
               id: report.id,
               timestamp: report.timestamp,
               type: report.type
            };
            report.names().forEach((name) => {
               standardReport[name] = report.stat(name);
            });
            if (
               standardReport.id.indexOf("_recv") > 0 &&
               standardReport["googTrackId"] &&
               standardReport[JKEYS.MEDIA_TYPE] === "video"
            ) {
               const track = this.mediaService.getTrack(standardReport["googTrackId"]);
               if (track) {
                  // add customized value to video track
                  track["_" + JKEYS.FRAME_RATE] = standardReport["googFrameRateReceived"];
                  track["_" + JKEYS.HEIGHT] = standardReport["googFrameHeightReceived"];
                  track["_" + JKEYS.WIDTH] = standardReport["googFrameWidthReceived"];
               }
            }
            dataArray.push(standardReport);
         });

         const respObj = {
            evt: "getStats",
            id: reqId,
            peer: peerId,
            stats: dataArray
         };

         this.sendRTCResponse(WEB_COMMAND.GET_STATS_2, respObj);
      }
   };

   private onGetStatsV0Failure = (peerId) => {
      // We don't need to send back failure Msg to Shim
      this.logger.error("WebRTCInstance failed to getStat with peerId: " + peerId);
   };

   private handleGetStatsV1 = async (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];

      if (this.peerConnections[peerId]) {
         try {
            const reports = await this.peerConnections[peerId].getStats();
            const reportCount = reports.size || 0;

            if (reportCount > 0) {
               const dataArray = [];
               reports.forEach((report) => {
                  dataArray.push(report);
               });

               const respObj = {
                  evt: "getStats",
                  id: cmdObj[JKEYS.ID],
                  peer: peerId,
                  stats: dataArray
               };
               this.sendRTCResponse(WEB_COMMAND.GET_STATS_2, respObj);
            }
         } catch (error) {
            this.logger.error(
               "WebRTCInstance failed to getStat with peerId: " + peerId + ", error: " + JSON.stringify(error)
            );
            return;
         }
      }
   };

   /**
    * Handle getStat(51) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleGetStatsDiff = (cmdObj) => {
      // TODO: support getStat diff for backward Compatibility
   };

   /**
    * Handle getReceivers(67) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleGetReceivers = (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      if (peerId >= 0 && this.peerConnections[peerId]) {
         this.peerConnections[peerId].startTrackReceivers();
      } else {
         this.logger.error("WebRTCInstance could not find local peer connection with peerId: " + peerId);
      }
   };

   /**
    * Handle applyConstraints(70) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleApplyConstraints = (cmdObj) => {
      const trackId = cmdObj[JKEYS.TRKID];
      if (!trackId) {
         this.logger.error("WebRTCInstance => handle apply constraints gets invalid trackId : " + trackId);
      } else {
         this.logger.info("WebRTCInstance => handle apply constraints for track: " + trackId);
      }

      const constraints = cmdObj[JKEYS.CONSTRAINTS];
      const respObj = {};
      if (!trackId || !constraints) {
         respObj[JKEYS.ERROR] = {};
         respObj[JKEYS.MESSAGE] = HTML5MMR_CONST.INVALID_INPUT;
      } else {
         try {
            const track = this.mediaService.getTrack(trackId);
            track.applyConstraints(constraints);
            respObj[JKEYS.SETTINGS] = {};
            respObj[JKEYS.SETTINGS][JKEYS.WIDTH] = constraints.width;
            respObj[JKEYS.SETTINGS][JKEYS.HEIGHT] = constraints.height;
         } catch (error) {
            respObj[JKEYS.ERROR] = {};
            respObj[JKEYS.MESSAGE] = HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR;
         }
      }
      respObj[JKEYS.ID] = cmdObj[JKEYS.ID];
      respObj[JKEYS.EVT] = cmdObj[JKEYS.CMD];

      this.sendRTCResponse(WEB_COMMAND.APPLY_CONSTRAINTS, respObj);
   };

   /**
    * Handle addIceCandidate(48) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleAddIceCandidate = (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      if (!peerId || !this.peerConnections[peerId]) {
         this.logger.error(
            "WebRTCInstance => handle addIceCandidate could not find peer connection with peerId : " + peerId
         );
         this.sendErrorEvent(
            WEB_COMMAND.ADD_ICECANDICATE,
            cmdObj[JKEYS.ID],
            peerId,
            HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR
         );
         return;
      }
      if (this.peerConnections[peerId].isUnifiedPlan) {
         this.handleAddIceCandidateV1(cmdObj);
      } else {
         this.handleAddIceCandidateV0(cmdObj);
      }
   };

   // For pre WebRTC 1.0
   private handleAddIceCandidateV0 = (cmdObj) => {
      // peerId and this.peerConnections[peerId] should be checked before
      const peerId = cmdObj[JKEYS.PEER];
      const candidate = cmdObj[JKEYS.CANDIDATE];
      candidate[JKEYS.SDP_MID] = this.replaceServerStreamId(candidate[JKEYS.SDP_MID]);
      this.peerConnections[peerId].addIceCandidateV0(
         candidate,
         this.onAddIceCandidateSuccess.bind(this, cmdObj[JKEYS.ID], peerId),
         this.onAddIceCandidateFailure.bind(this, cmdObj[JKEYS.ID], peerId)
      );
   };

   private onAddIceCandidateSuccess = (reqId, peerId) => {
      const respObj = {};
      respObj[JKEYS.ID] = reqId;
      respObj[JKEYS.PEER] = peerId;
      respObj[JKEYS.EVT] = HTML5MMR_CONST.ICE_CANDIDATE;
      this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVT, respObj);
   };

   private onAddIceCandidateFailure = (reqId, peerId) => {
      this.sendErrorEvent(WEB_COMMAND.ADD_ICECANDICATE, reqId, peerId, HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR);
   };

   // For WebRTC 1.0
   private handleAddIceCandidateV1 = (cmdObj) => {
      // peerId and this.peerConnections[peerId] should be checked before
      const peerId = cmdObj[JKEYS.PEER];
      const candidate = cmdObj[JKEYS.CANDIDATE];
      candidate[JKEYS.SDP_MID] = this.replaceServerStreamId(candidate[JKEYS.SDP_MID]);
      this.peerConnections[peerId]
         .addIceCandidateV1(candidate)
         .then(() => {
            const respObj = {};
            respObj[JKEYS.ID] = cmdObj[JKEYS.ID];
            respObj[JKEYS.PEER] = peerId;
            respObj[JKEYS.EVT] = HTML5MMR_CONST.ICE_CANDIDATE;
            this.sendRTCResponse(WEB_COMMAND.HTML5MMR_WEBRTC_EVT, respObj);
         })
         .catch((error) => {
            this.sendErrorEvent(
               WEB_COMMAND.ADD_ICECANDICATE,
               cmdObj[JKEYS.ID],
               peerId,
               HTML5MMR_CONST.WEBRTC_STR_INTERNAL_ERROR
            );
         });
   };

   /**
    * Handle addTrack(74) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleAddTrack = (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      const trackId = cmdObj[JKEYS.TID];
      const streamId = cmdObj[JKEYS.SID];
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;

      if (!cmdObj[JKEYS.ID] || !peerId || !trackId || !streamId || !this.peerConnections[peerId]) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid json command.");
         this.logger.error("WebRTCInstance => handle addTrack got invalid parameters.");
         return;
      }
      this.logger.info("WebRTCInstance => handle addTrack for peer: " + peerId);
      const stream = this.mediaService.getStream(streamId);
      const track = this.mediaService.getTrack(trackId);
      if (!stream || !track) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "could not find stream or track with given id.");
         this.logger.error("WebRTCInstance => handle addTrack invalid stream/track id.");
         return;
      }

      this.peerConnections[peerId].addTrack(cmdObj, track, stream);
   };

   /**
    * Handle removeTrack(75) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleRemoveTrack = (cmdObj) => {
      const peerId = cmdObj[JKEYS.PEER];
      const senderId = cmdObj[JKEYS.SENDER_ID];
      if (!peerId || !senderId || !this.peerConnections[peerId]) {
         this.logger.error("WebRTCInstance => handle handle removeTrack got invalid parameters.");
         return;
      }
      this.logger.info("WebRTCInstance => handle removeTrack for peer: " + peerId);
      this.peerConnections[peerId].removeTrack(senderId);
   };

   /**
    * Handle createMediaStream(77) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleCreateMediaStream = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle createMediaStream command.");
      const streamId = cmdObj[JKEYS.SID];
      const tracksInfo = cmdObj[JKEYS.TRACKS];
      if (!streamId || !tracksInfo || !Array.isArray(tracksInfo)) {
         this.logger.error("WebRTCInstance => handle createMediaStream got invalid parameters.");
         this.sendErrorEvent(WEB_COMMAND.CREATE_MEDIASTREAM, cmdObj[JKEYS.ID], -1, HTML5MMR_CONST.INVALID_INPUT);
         return;
      }
      const newMediaStream = new MediaStream();
      tracksInfo.forEach((trackInfo) => {
         const track = this.mediaService.getTrack(trackInfo[JKEYS.ID]);
         track && newMediaStream.addTrack(track);
      });

      this.mediaService.updateStream(streamId, newMediaStream);
      this.updateMediaIdMap(newMediaStream.id, streamId);
      this.sendSuccessResult(cmdObj);
   };

   /**
    * Handle transceiver related command(300).
    *
    * @param  {object} cmdObj given command json object
    */
   private handleTransceiverCmd = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle transceiver cmd.");
      const transceiverCmd = cmdObj[JKEYS.TRANSCEIVER_CMD];
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;
      if (!cmdObj[JKEYS.ID] || !transceiverCmd) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "missing request id or transceiverCmd");
      }

      switch (transceiverCmd) {
         case HTML5MMR_CONST.TRANSCEIVER_CMD.TRANSCEIVER_ADD_TRANSCEIVER:
            this.handleAddTransceiver(cmdObj);
            break;
         case HTML5MMR_CONST.TRANSCEIVER_CMD.TRANSCEIVER_SET_CODECPREFERENCE:
            this.setTransceiverCodecPreference(cmdObj);
            break;
         case HTML5MMR_CONST.TRANSCEIVER_CMD.TRANSCEIVER_STOP:
            this.handleStopTransceiver(cmdObj);
            break;
         case HTML5MMR_CONST.TRANSCEIVER_CMD.TRANSCEIVER_SET_DIRECTION:
            this.handleTranceiverSetDirection(cmdObj);
            break;
         default:
            this.logger.error("WebRTCInstance => handle transceiver cmd: unexpected cmd id.");
            this.sendRTCErrorResult(cmdObj, invalidParams, "unknown transceiverCmd");
      }
   };

   /**
    * Handle add transceiver(1) command from transceiver command(300).
    *
    * @param  {object} cmdObj given command json object
    */
   private handleAddTransceiver = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle add transceiver cmd.");
      const peerId = cmdObj[JKEYS.PEER];
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const trackOrKind = cmdObj[JKEYS.TRACK_OR_KIND];
      const init = cmdObj[JKEYS.INIT];
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;

      if (!peerId || !transceiverId || !trackOrKind || !init || !this.peerConnections[peerId]) {
         this.logger.error("WebRTCInstance => invalid parameters in json.");
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid json command");
         return;
      }

      this.peerConnections[peerId].addTranscevier(cmdObj);
   };

   /**
    * Handle set transceiver codec preference(2) command from transceiver command(300).
    *
    * @param  {object} cmdObj given command json object
    */
   private setTransceiverCodecPreference = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle set transceiver codec preference cmd.");
      const peerId = cmdObj[JKEYS.PEER];
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const codecs = cmdObj[JKEYS.CODECS];
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;
      if (!peerId || !transceiverId || !codecs || !this.peerConnections[peerId] || !Array.isArray(codecs)) {
         this.logger.error("WebRTCInstance => invalid parameters in json.");
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid json command");
         return;
      }

      if (this.peerConnections[peerId].setTransceiverCodecPreference(transceiverId, codecs)) {
         this.sendSuccessResult(cmdObj);
      } else {
         const internalError = HTML5MMR_CONST.RTCErrorType.INTERNAL_ERROR;
         this.sendRTCErrorResult(cmdObj, internalError, "set transceiver codec preference failed");
      }
   };

   /**
    * Handle stop transceiver(3) command from transceiver command(300).
    *
    * @param  {object} cmdObj given command json object
    */
   private handleStopTransceiver = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle stop transceiver cmd.");
      const peerId = cmdObj[JKEYS.PEER];
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;
      if (!peerId || !transceiverId || !this.peerConnections[peerId]) {
         this.logger.error("WebRTCInstance => invalid parameters in json.");
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid json command");
         return;
      }
      if (this.peerConnections[peerId].stopTransceiver(transceiverId)) {
         this.sendSuccessResult(cmdObj);
      } else {
         const internalError = HTML5MMR_CONST.RTCErrorType.INTERNAL_ERROR;
         this.sendRTCErrorResult(cmdObj, internalError, "stop transceiver failed");
      }
   };

   /**
    * Handle transceiver set direction(4) command from transceiver command(300).
    *
    * @param  {object} cmdObj given command json object
    */
   private handleTranceiverSetDirection = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle transceiver set direction cmd.");
      const peerId = cmdObj[JKEYS.PEER];
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const direction = cmdObj[JKEYS.VALUE];
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;

      if (!peerId || !transceiverId || !direction || !this.peerConnections[peerId]) {
         this.logger.error("WebRTCInstance => invalid parameters in json.");
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid json command");
         return;
      }
      if (HTML5MMR_CONST.TRANSCEIVER_DIRECTIONS.indexOf(direction) < 0) {
         this.logger.error("WebRTCInstance => invalid transceiver direction.");
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid transceiver direction");
         return;
      }
      if (this.peerConnections[peerId].setTransceiverDirection(transceiverId, direction)) {
         this.sendSuccessResult(cmdObj);
      } else {
         const internalError = HTML5MMR_CONST.RTCErrorType.INTERNAL_ERROR;
         this.sendRTCErrorResult(cmdObj, internalError, "set transceiver direction failed");
      }
   };

   /**
    * Handle sender related command(76).
    *
    * @param  {object} cmdObj given command json object
    */
   private handleSenderCmd = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle sender cmd.");
      const senderCmd = cmdObj[JKEYS.SENDER_CMD];
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;
      if (!cmdObj[JKEYS.ID] || !senderCmd) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "missing requet id or senderCmd");
         return;
      }

      switch (senderCmd) {
         case HTML5MMR_CONST.SENDER_CMD.SENDERCMD_SET_PARAMETERS:
            this.handleSetSenderParameters(cmdObj);
            break;
         case HTML5MMR_CONST.SENDER_CMD.SENDERCMD_REPLACE_TRACK:
            this.handleSenderReplaceTrack(cmdObj);
            break;
         default:
            this.logger.error("WebRTCInstance => handle senderCmd: unexpected cmd id.");
            this.sendRTCErrorResult(cmdObj, invalidParams, "unknown senderCmd");
      }
   };

   /**
    * Handle connected state changed command(78).
    *
    * @param  {object} cmdObj given command json object
    */
   private handleConnectedStateChanged = (cmdObj) => {
      // we only log this event for now
      const state = cmdObj[JKEYS.CONNECTIONSTATE];
      if (state) {
         this.logger.info("WebRTCInstance => connected state changed to: " + state);
      } else {
         this.logger.error("WebRTCInstance => could not read connection state from event.");
      }
   };

   /**
    * Handle set sendParameters(1) command from senderCMD(76) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleSetSenderParameters = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle set sender parameter cmd.");
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;
      const peerId = cmdObj[JKEYS.PEER];
      if (!peerId || !this.peerConnections[peerId]) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid peer id");
         return;
      }
      const senderId = cmdObj[JKEYS.SENDER_ID];
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      if (!senderId || !transceiverId) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid senderId or transceiverId");
      }
      const parameters = cmdObj[JKEYS.PARAMETERS];
      if (!parameters) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid sender parameters");
         return;
      }
      this.peerConnections[peerId].setSenderParameters(cmdObj);
   };

   /**
    * Handle replace sender track(2) command from senderCMD(76) command.
    *
    * @param  {object} cmdObj given command json object
    */
   private handleSenderReplaceTrack = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle replace sender track cmd.");
      const invalidParams = HTML5MMR_CONST.RTCErrorType.INVALID_PARAMETER;
      const peerId = cmdObj[JKEYS.PEER];
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const newTrackId = cmdObj[JKEYS.NEW_TRACK_ID];
      if (!peerId || !this.peerConnections[peerId] || !transceiverId) {
         this.sendRTCErrorResult(cmdObj, invalidParams, "invalid json command");
         return;
      }
      let newTrack = null;
      if (newTrackId) {
         newTrack = this.mediaService.getTrack(newTrackId);
         if (!newTrack) {
            this.sendRTCErrorResult(cmdObj, invalidParams, "couldn not find mediatrack with given id.");
            return;
         }
      }

      this.peerConnections[peerId].replaceSenderTrack(cmdObj, newTrack);
   };

   /**
    * Handle insert DTMF (66) command.
    *
    * @param  {object} cmdObj given command json object
    *
    * @returns {boolean}
    */
   private handleInsertDTMF = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle insert DTMF cmd.");
      const peerId = cmdObj[JKEYS.PEER];
      if (!peerId || !this.peerConnections[peerId]) {
         this.logger.error("WebRTCInstance => handle insert DTMF, invalid peerId.");
         return false; // just return false, no need to send back to agent
      }
      const trackId = cmdObj[JKEYS.TID];
      const tones = cmdObj[JKEYS.TONES];
      const duration = cmdObj[JKEYS.DURATION];
      const gap = cmdObj[JKEYS.GAP];

      if (!trackId || !tones || !duration || !gap) {
         this.logger.error("WebRTCInstance => handle insert DTMF, invalid json cmd.");
         return false;
      }

      // These 2 values could be null in pre-webrtc 1.0
      const transceiverId = cmdObj[JKEYS.TRANSCEIVER_ID];
      const senderId = cmdObj[JKEYS.SENDER_ID];

      this.peerConnections[peerId].insertDTMF(transceiverId, senderId, trackId, tones, duration, gap);
      return true;
   };

   /**
    * Handle RTCDataChannel related command(301).
    *
    * @param  {object} cmdObj given command json object
    *
    * @returns {boolean}
    */
   private handleDataChannelCommand = (cmdObj) => {
      this.logger.info("WebRTCInstance => handle Datachannel cmd.");
      const peerId = cmdObj[JKEYS.PEER];
      if (!peerId || !this.peerConnections[peerId]) {
         this.logger.error("WebRTCInstance => handle Datachannel, invalid peerId.");
         return false; // just return false, no need to send back to agent
      }
      return this.peerConnections[peerId].handleDataChannelCommand(cmdObj);
   };

   /**
    * Replace local media id with remote media id
    *
    * @param  {string} localString, the string contains local mediaId
    * @returns {string} localString, the string replace with remote mediaId
    */
   public replaceClientStreamId = (localString: string) => {
      if (typeof localString !== "string") {
         this.logger.error("replaceClientStreamId failed: input is an invalid string");
         return;
      }
      this.mediaIdMap.forEach((remoteCloneId, localMediaId) => {
         localString = localString.replace(new RegExp(localMediaId, "g"), remoteCloneId);
      });
      return localString;
   };

   /**
    * Replace remote media id with local media id
    *
    * @param  {string} remoteString, the string contains remote mediaId
    * @returns {string} remoteString, the string replace with local mediaId
    */
   public replaceServerStreamId = (remoteString: string) => {
      if (typeof remoteString !== "string") {
         this.logger.error("replaceServerStreamId failed: input is an invalid string");
         return;
      }
      this.mediaIdMap.forEach((remoteCloneId, localMediaId) => {
         remoteString = remoteString.replace(new RegExp(remoteCloneId, "g"), localMediaId);
      });
      return remoteString;
   };

   private updateMediaIdMap = (localMediaId, remoteCloneId) => {
      this.mediaIdMap.forEach((value, key) => {
         if (value === remoteCloneId) {
            this.mediaIdMap.delete(key);
         }
      });
      this.mediaIdMap.set(localMediaId, remoteCloneId);
   };

   public updateStream = (streamId: string, stream: MediaStream) => {
      this.mediaService.updateStream(streamId, stream);
   };

   public updateTrack = (trackId: string, track: MediaStreamTrack) => {
      this.mediaService.updateTrack(trackId, track);
   };

   /**
    * Update device list when user plugs in new device or pulls out current device.
    *
    */
   public onDeviceChanged = () => {
      this.logger.info("WebRTCInstance detected device change.");
      const respObj = {};
      respObj[JKEYS.EVT] = HTML5MMR_CONST.DEVICECHANGE;
      this.sendRTCResponse(WEB_COMMAND.EVENT_ONDEVICECHANGED, respObj);
   };

   /**
    * Parse band width estimation value from sdp in remote description
    *
    * @param {string} sdp
    * @param {number} peerId
    *    */
   private parseBWE = (sdp: string, peerId: number) => {
      const vPosition = sdp.indexOf("m=video");
      if (vPosition >= 0) {
         const tmpString = sdp.substring(vPosition);
         const bLineRegExp = new RegExp("b=AS:[0-9]*");
         const result = tmpString.match(bLineRegExp);
         if (result && result.length) {
            const bline = result[0];
            const cPosition = bline.indexOf(":");
            if (cPosition >= 0) {
               const value = parseInt(bline.substring(cPosition + 1));
               this.BWEMap.set(peerId, value);
               this.peerConnections[peerId].updateBWE(value);
            }
         }
      }
   };

   /**
    * Helper function that is responsible for sending WebRTC Response upon handling of WEBTEXT command
    *
    * @param  {number} resCmdId  response WEBTEXT command id
    * @param  {any} response  response JSON payload. This will be converted to JSON string as part of MMR response payload.
    */
   public sendRTCResponse = (resCmdId: number, response: any) => {
      const resString: string = JSON.stringify(response);
      this.logger.debug(
         "Html5MMR WebRTC Instance sending back response. CmdId: " + resCmdId + " response: " + resString
      );

      this.mmrChannel.sendRPC(HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT, [
         this.instanceId,
         0,
         resCmdId,
         resString
      ]);
   };

   /**
    * Send general OK event which does not need complicated webTextPayload,
    * But only requetId, peerId, command name and command id
    *
    * @param  {object} event, the original command event object
    * @param  {object} value, the additional value needed in payload
    * @param  {string} valueName, the value name in result needed to be sent
    */
   // TODO: simplify handlers which could use this function
   public sendSuccessResult = (event, value = {}, valueName = "") => {
      let evt = {}; // result object to send out
      if (valueName) {
         evt[valueName] = value[valueName];
      } else {
         evt = value;
      }
      evt[JKEYS.ID] = event[JKEYS.ID];
      evt[JKEYS.PEER] = event[JKEYS.PEER] || -1; // some events don't refer to peerId
      evt[JKEYS.EVT] = event[JKEYS.CMD];
      if (event[JKEYS.HINT]) {
         // option value
         evt[JKEYS.HINT] = event[JKEYS.HINT];
      }
      this.sendRTCResponse(event[JKEYS.COMMAND_ID], evt);
   };

   /**
    * Send back RTC related error message
    *
    * @param  {object} event, the original command event object
    * @param  {number} errorType, the pre-defined error type number
    * @param  {string} errorMsg, the additioal error message
    */
   // TODO: simplify handlers which could use this function
   public sendRTCErrorResult = (event, errorType, errorMsg) => {
      const errorObj = {};
      errorObj[JKEYS.NAME] = HTML5MMR_CONST.RTCErrorTypeNames[errorType];
      errorObj[JKEYS.CODE] = errorType;
      errorObj[JKEYS.MESSAGE] = errorMsg;

      this.sendRTCError(event[JKEYS.COMMAND_ID], event[JKEYS.ID], event[JKEYS.PEER], event[JKEYS.CMD], errorObj);
   };

   public sendErrorEvent = (evtId: number, reqId: number, peerId: number, errMsg) => {
      const event = {};
      event[JKEYS.ID] = reqId;
      event[JKEYS.PEER] = peerId;
      event[JKEYS.EVT] = "Error";
      event[JKEYS.ERROR] = errMsg;

      this.sendRTCResponse(evtId, event);
   };

   public clear = () => {
      if (navigator.mediaDevices) {
         navigator.mediaDevices.removeEventListener("devicechange", this.onDeviceChanged.bind(this));
      }
      this.audioNotifyHeaderPos = 0;
      this.audioNotifyDataPos = 0;
      this.audioNotifyDataLen = 0;
      this.audioNotifyHeader = new Uint8Array(HTML5MMR_CONST.AUD_NOTIFY_HEADER_LEN);
      this.mediaIdMap.clear();
      this.BWEMap.clear();

      for (let i = this.peerConnections.length - 1; i >= 0; i--) {
         const pc = this.peerConnections.pop();
         pc && pc.close();
      }

      this.stopE911Listener();

      this.logger.info("WebRTCInstance cleared: " + this.instanceId);
   };
}
