/**
 * ******************************************************
 * Copyright (C) 2016-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { ElementRef, AfterViewInit, DoCheck, Directive } from "@angular/core";

@Directive({
   selector: "[fuzzy-free]"
})
export class FuzzyFreeDirective implements AfterViewInit, DoCheck {
   /**
    * This directive is for bug 1512510
    * Some font is fuzzy in Chrome because of Chrome's bug
    * It happens in odd pixel height.
    */
   constructor(private _el: ElementRef) {}

   ngAfterViewInit() {}

   ngDoCheck() {
      if (this._el.nativeElement.offsetHeight % 2 === 0) {
         const newOffsetHeight = this._el.nativeElement.offsetHeight + 1;
         this._el.nativeElement.style.height = newOffsetHeight + "px";
      }
   }
}
