/**
 * ******************************************************
 * Copyright (C) 2018-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { AbstractModalComponent } from "../../common/commondialog/abstract-modal.component";
import { TrustedAppsModel, TrustedAppEntry } from "../../common/model/trusted-apps-model";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { Logger } from "../../../core/libs/logger";
@Component({
   selector: "setting-trusted-apps-dialog",
   templateUrl: "./setting-trusted-apps-dialog.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class SettingTrustedAppsDialogComponent extends AbstractModalComponent {
   public allReady: boolean = true;
   public trustedApps: Array<TrustedAppEntry> = [];
   public inputAppID: string;
   public defaultTrustedApps: Array<string> = [];

   constructor(
      private trustedAppsModel: TrustedAppsModel,
      private modalDialogService: ModalDialogService
   ) {
      super();
      //@ts-ignore
      const defaultTrustedAppsString: string = String(__VMWARE_DEFAULT_TRUSTED__).replaceAll(" ", "");
      this.defaultTrustedApps = defaultTrustedAppsString ? defaultTrustedAppsString.split(",") : [];
      Logger.debug("trust panel opened, default trusted 3rd party apps:" + JSON.stringify(this.defaultTrustedApps));

      this.trustedAppsModel.get().then((trustedApps) => {
         for (let i = 0; i < trustedApps.length; i++) {
            const app = trustedApps[i];
            let repeatEntry = false;
            setTimeout(() => {
               for (const entry of this.trustedApps) {
                  if (app.address === entry.address) {
                     repeatEntry = true;
                  }
               }
               // remove the default and invalid entry
               if (!repeatEntry && this.checkTrustedAppValid(app.address)) {
                  this.trustedApps.push({
                     address: app.address,
                     accepted: app.accepted
                  });
               }
            });
         }
         setTimeout(() => {
            Logger.debug("read user trust: " + JSON.stringify(this.trustedApps));
         });
      });
   }

   private showError(msg) {
      this.modalDialogService.showError({
         data: {
            titleKey: "ERROR",
            contentKey: msg
         }
      });
   }

   private checkTrustedAppValid = (address) => {
      const regexp = /^(chrome-extension:\/\/)?[A-Za-z]+$/;

      if (!address) {
         return false;
      }
      if (!address.match(regexp)) {
         this.showError("invalid_chrome_app_id");
         return false;
      }
      // don't support websites to consume SDK for now.
      if (address.indexOf("https://") >= 0 || address.indexOf("http://") >= 0 || address.indexOf(".") >= 0) {
         this.showError("invalid_chrome_app_id");
         return false;
      }
      if (this.defaultTrustedApps.includes(address)) {
         this.showError("use_policy_to_overwrite");
         return false;
      }
      for (let i = 0; i < this.trustedApps.length; i++) {
         if (this.trustedApps[i].address === address) {
            this.showError("app_already_in_the_list");
            return false;
         }
      }
      return true;
   };

   public trustedAppsOkClicked = () => {
      this.trustedAppsModel.set(this.trustedApps);
      Logger.debug("save user trust: " + JSON.stringify(this.trustedApps));
      this.trustedAppsModel.save().then(() => {
         this.closeModal();
      });
   };

   public addTrustedAppsClicked = () => {
      const chromeAppHeader = "chrome-extension://";
      this.inputAppID = this.inputAppID.trim();
      if (!this.inputAppID.startsWith(chromeAppHeader)) {
         this.inputAppID = chromeAppHeader + this.inputAppID;
      }
      if (this.checkTrustedAppValid(this.inputAppID)) {
         this.trustedApps.push({
            address: this.inputAppID,
            accepted: true
         });
         Logger.info("add trust app setting for origin" + this.inputAppID);
      } else {
         Logger.error("invalid input for trusted app id");
      }
      this.inputAppID = "";
   };

   public deleteTrustedApp = (address) => {
      for (let i = this.trustedApps.length - 1; i >= 0; i--) {
         if (this.trustedApps[i].address === address) {
            Logger.info("remove trust app setting for origin" + address);
            this.trustedApps.splice(i, 1);
         }
      }
   };

   public onKeypress = (evt) => {
      if (!!evt && evt.keyCode === 32) {
         // Space key is pressed.
         if (evt.target.children && evt.target.children[0]) {
            $(evt.target.children[0]).trigger("click");
         }
         // Prevent default behavior.
         evt.preventDefault();
      }
   };
}
