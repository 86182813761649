/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * setEnvironmentInfoCommand.js --
 *
 *      This API is added to minimize the change to support:
 *      https://confluence.eng.vmware.com/pages/viewpage.action?pageId=233382996
 *      Where whenever a request is sent, environment info is needed.
 *      Since the environment content never changes and we have too many APIs to
 *      indirectly sending this XML as the first step, use this method to minimize
 *      the changes in both JSCDK, and HTML Access.
 *
 *      p.s. After JSCDK refactory, it should be in a model and shared among
 *      more XMLs with filters.
 */

import { globalArray } from "../jscdkClient";

export default function SetEnvironmentInfoCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param SetEnvironmentInfoAction[in]
 *        - its property 'method' has the value 'SetEnvironmentInfo'
 */
SetEnvironmentInfoCommand.prototype.execute = function (SetEnvironmentInfoAction) {
   globalArray["environment-information"] = SetEnvironmentInfoAction.data;
};
