/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "orderBy",
   pure: false
})
export class SortPipe implements PipeTransform {
   transform(array: any, key1: string, key2?: string) {
      if (!Array.isArray(array)) {
         return;
      }
      array.sort((item1: any, item2: any) => {
         if (item1[key1] === item2[key1]) {
            if (!key2 || item1[key2] === item2[key2]) {
               return 0;
            } else {
               return item1[key2] > item2[key2] ? 1 : -1;
            }
         } else {
            return item1[key1] > item2[key1] ? 1 : -1;
         }
      });
      return array;
   }
}
