/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { PipeTransform, Pipe } from "@angular/core";
import { AB } from "../common/appblast-util.service";

@Pipe({
   name: "runningItemsFilter",
   pure: false
})
export class RunningItemsPipe implements PipeTransform {
   constructor() {}
   transform(runningItems, query) {
      // Filter for running items given a search query string.
      const instanceQueryFilter = (item) => {
         return AB.itemNameContainsQuery(item, query);
      };

      const getRunningItemFilter = (runningItem) => {
         if (AB.isLoadingItem(runningItem.type) || AB.isLoadingApp(runningItem.type)) {
            if (query === "") {
               /*
                * The special "Loading" item holds reconnecting sessions, and
                * should only be displayed if and we are not searching for anything.
                */
               runningItem.queryInstances = runningItem.instances;
               return true;
            }
         } else if (AB.itemNameContainsQuery(runningItem, query)) {
            // If parent app matches, assume all child instances are relevant
            runningItem.queryInstances = runningItem.instances;
            return true;
         } else {
            // If any child instances match query, both parent app and all
            // matching child apps must be shown.
            const matchedInstances = runningItem.instances.filter(instanceQueryFilter);
            if (matchedInstances.length > 0) {
               runningItem.queryInstances = matchedInstances;
               return true;
            }
         }
      };
      const output = runningItems.filter(getRunningItemFilter);
      output.sort(AB.itemSortHelper);
      return output;
   }
}
