/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import "../../shared/styles/style.css";

import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";

import { ClientCommonModule } from "../common/client-shared.common.module";
import { SharedLauncherCommonModule } from "../launcher/common/launcher.common.module";

import { LoginComponent } from "./login-root/login.component";
import { LoginContentComponent } from "./login-content/login-content.component";
import { LoginWindowsPasswordComponent } from "./windows-password/windows-password.component";
import { LoginUnauthenticatedComponent } from "./unauthenticated/unauthenticated.component";
import { SecurIDPasscodeComponent } from "./securid-password/securid-passcode.component";
import { LoginWaitingComponent } from "./login-wait/login-waiting.component";
import { LoginDisclaimerComponent } from "./disclaimer/disclaimer.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { SecurIDNextTokenComponent } from "./securid-nexttokencode/securid-nexttokencode.component";
import { LoginSecurIDWaitComponent } from "./securid-wait/securid-wait.component";
import { SecurIDPinChangeComponent } from "./securid-pinchange/securid-pinchange.component";
import { loadingAreaComponent } from "./common/loading-area-directive";
import { PhoneHomeComponent } from "./phone-home/phone-home.component";
import { DeactivateGuardService } from "../../shared/view/deactive-guard.service";

@NgModule({
   imports: [
      CommonModule,
      ReactiveFormsModule,
      ClientCommonModule,
      SharedLauncherCommonModule,
      RouterModule.forChild([
         {
            path: "home",
            component: LoginComponent,
            canDeactivate: [DeactivateGuardService]
         }
      ])
   ],
   schemas: [NO_ERRORS_SCHEMA],

   declarations: [
      LoginComponent,
      LoginContentComponent,
      LoginWindowsPasswordComponent,
      SecurIDPasscodeComponent,
      SecurIDPinChangeComponent,
      SecurIDNextTokenComponent,
      ChangePasswordComponent,
      LoginDisclaimerComponent,
      LoginSecurIDWaitComponent,
      LoginWaitingComponent,
      PhoneHomeComponent,
      LoginUnauthenticatedComponent,
      loadingAreaComponent
   ],
   exports: [LoginContentComponent]
})
export class LoginModule {}
