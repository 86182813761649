/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * multisession-singleuser-session.service.ts --
 *
 * Service to manage the multi-sessions app sessions, mainly used for HTML Access
 * https://confluence.eng.vmware.com/display/HorizonArchitecture/
 * Allow+single+user+to+launch+multiple+instances+of+same+RDSH+App+on+multiple+clients
 *
 * We use brokerSessionStorage to store the multi-session info, since those info will
 * be invalid if broker session is changed.
 *
 * This is used to handle reset, and launch, for bug 2205291 and 2211891
 *
 * The logic to bypass issue for launching should be removed once broker returns
 * the Multisession with <session-id> in the <application-sessions> of XML
 * <launch-items>, but this should not be added in the near future.
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import { BusEvent, EventBusService, getPartialMatchedKey } from "@html-core";
import { BrokerSessionStorageService } from "../../../core/services/storage/broker-session-storage.service";
import { SessionUtil } from "./session-util";

@Injectable({
   providedIn: "root"
})
export class MultiSessionSingleUserSessionService {
   private static readonly STORAGE_KEY: string = "Multi-session-Info";
   public multiSessions = {};
   private connectionSessions = {};

   constructor(
      private brokerSessionStorageService: BrokerSessionStorageService,
      private eventBusService: EventBusService
   ) {
      this.loadMultiSessionsFromStorage();
   }

   /**
    * called when <get-application-connection> response contains URI for multi-session
    * @param {object} session has properties of originId, sessionId, url
    */
   public addConnectionInfo = (connectionInfo) => {
      // load to enable status sync between tabs
      this.loadMultiSessionsFromStorage();
      if (!connectionInfo || !connectionInfo.sessionId || !connectionInfo.url) {
         Logger.error("invalid multi session param to be added");
         return;
      }
      const launchData = JSON.parse(JSON.stringify(connectionInfo));
      launchData.url = launchData.url.split("&session=")[0];
      // The runningSessions of SessionDataService only have the sessionKey instead of the sessionID,
      // so the launchData also adds the sessionKey.
      launchData.key = SessionUtil.getSessionKey(true, launchData.originId, launchData.sessionId);

      if (!launchData.sessionId) {
         Logger.error(
            "missing sessionId, please update Broker to latest build or GA build, or reset will not affect Multi-session apps"
         );
      }
      Logger.info("multi session is managed: " + launchData.sessionId);
      this.multiSessions[launchData.sessionId] = {
         launchData: launchData,
         reconnectToken: null
      };
      this.syncChangeToStorage();
   };

   /**
    * API to reset All Multi-session app sessions related to the current broker session
    * Since <application-session> will not return multi-session app sessions.
    */
   public resetAllMultiSessions = () => {
      // load to enable status sync between tabs
      this.loadMultiSessionsFromStorage();
      this.eventBusService.dispatch(new BusEvent.DisconnectAllMultiSessionApp());
      this.multiSessions = {};
      this.connectionSessions = {};
      this.syncChangeToStorage();
   };

   /**
    * no matter connected or not, this function counts the
    * Multi-session app sessions related to the current broker session
    * @return {Boolean} whether current broker session has multisession to reset
    */
   public hasResettableSessions = () => {
      // load to enable status sync between tabs
      this.loadMultiSessionsFromStorage();
      for (const key in this.multiSessions) {
         if (this.multiSessions.hasOwnProperty(key)) {
            return true;
         }
      }
      return false;
   };

   public getConnectionInfo = (sessionId) => {
      // load to enable status sync between tabs
      this.loadMultiSessionsFromStorage();
      // see detail in bug 2291583
      sessionId = getPartialMatchedKey(this.multiSessions, sessionId);
      if (this.multiSessions.hasOwnProperty(sessionId)) {
         const connectionInfo = JSON.parse(JSON.stringify(this.multiSessions[sessionId].launchData));
         if (this.multiSessions[sessionId].reconnectToken) {
            connectionInfo.url += "&session=" + this.multiSessions[sessionId].reconnectToken;
            this.multiSessions[sessionId].reconnectToken = null;
            this.syncChangeToStorage();
         }
         return connectionInfo;
      }
      return null;
   };

   public onSessionConnected = (session) => {
      this.connectionSessions[session.keyInfo.sessionId] = session;
   };

   public onSessionRemoved = (session) => {
      if (this.connectionSessions.hasOwnProperty(session.keyInfo.sessionId)) {
         delete this.connectionSessions[session.keyInfo.sessionId];
      }
   };

   public onSessionRemovedForTimeout = (sessionKey) => {
      if (this.connectionSessions.hasOwnProperty(sessionKey)) {
         delete this.connectionSessions[sessionKey];
      }
   };

   hasConnectedSession = (sessionId) => {
      // see detail in bug 2291583
      sessionId = getPartialMatchedKey(this.connectionSessions, sessionId);
      return this.connectionSessions.hasOwnProperty(sessionId);
   };

   public updateReconnectToken = (sessionId, reconnectToken) => {
      if (!this.multiSessions.hasOwnProperty(sessionId)) {
         Logger.error("reconnectToken is set for not tracked Multi-session: " + sessionId);
         return;
      }
      if (!this.hasConnectedSession(sessionId)) {
         Logger.warning("reconnectToken is set for a not connected session");
      }
      this.multiSessions[sessionId].reconnectToken = reconnectToken;
      this.syncChangeToStorage();
   };

   public clearAll = () => {
      this.multiSessions = {};
      this.connectionSessions = {};
   };

   private syncChangeToStorage = () => {
      this.brokerSessionStorageService.write(MultiSessionSingleUserSessionService.STORAGE_KEY, this.multiSessions);
   };

   private loadMultiSessionsFromStorage = () => {
      if (this.brokerSessionStorageService.exist(MultiSessionSingleUserSessionService.STORAGE_KEY)) {
         this.multiSessions = this.brokerSessionStorageService.read(MultiSessionSingleUserSessionService.STORAGE_KEY);
      }
   };
}
