/*********************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 *********************************************************
 *
 * @format
 */

import { Component, ElementRef, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { TranslateService } from "@html-core";
import { Localize } from "../../common/service/localize.service";

@Component({
   selector: "privacy-policy",
   template:
      '<a class="home-link" id="privacyPolicyLink" ' +
      'href="http://www.vmware.com/help/privacy.html" target="_blank" rel="{{linkRelValue}}"></a>'
})
export class PrivacyLinkComponent implements AfterViewInit {
   public linkRelValue: string;
   private element;
   constructor(
      private translate: TranslateService,
      private localize: Localize,
      private _el: ElementRef,
      private changeDetector: ChangeDetectorRef
   ) {}

   ngAfterViewInit() {
      this.element = $(this._el.nativeElement.children[0]);
      this.localize.localeReady$.subscribe((ready) => {
         setTimeout(() => {
            if (ready) {
               const name = this.translate._T("privacy_policy");
               this.element.html(name);
            }
         });
      });

      this.linkRelValue = __REL_FOR_LINK__;
      this.changeDetector.detectChanges();
   }
}
