/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { clientUtil } from "@html-core";
import { Pipe, PipeTransform } from "@angular/core";
import { Localize } from "../service/localize.service";

@Pipe({
   name: "i18nv2"
})
export class I18nv2Pipe implements PipeTransform {
   constructor(private localize: Localize) {}
   transform(...param: any[]): string {
      if (clientUtil.isChromeClient()) {
         try {
            /* For DELL Chromebook, it will has error
             * when chrome.i18n.getMessage(undefined)
             * use try catch to avoid this error, use
             * original way to translate text instead
             * of chrome API, detail is in VHCH-3330
             */
            if (arguments.length === 1) {
               return chrome.i18n.getMessage(arguments[0]);
            } else {
               const params = [];
               for (let i = 1; i < arguments.length; i++) {
                  params[i - 1] = arguments[i];
               }
               return chrome.i18n.getMessage(arguments[0], params as chrome.i18n.StringSubstitutions);
            }
         } catch (e) {
            return this.localize.translate(...param);
         }
      } else {
         return this.localize.translate(...param);
      }
   }
}
