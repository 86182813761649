/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * runningitem-model.js --
 *
 * Module to provide running items information for Sidebar.
 *
 */
import { AB } from "./appblast-util.service";
import { Injectable } from "@angular/core";
import { signal } from "../../common/service/signal";
import { Unity } from "../remoteapp/unity";
import Logger from "../../../core/libs/logger";
import { ScreenUtilService } from "./screen-util.service";
import { UnityService, UnitySubscription } from "../remoteapp";
import { RemoteSessionEventService, SessionMsg } from "../../common/remote-session/remote-session-event.service";
import { Ws1Service } from "../../common/service/ws1.service";
import { BlastWmks } from "./blast-wmks.service";
import { RootModel } from "../../../shared/common/model/root-model";
import { TranslateService } from "@html-core";
import { EntitledItemsModel } from "../../../shared/desktop/common/entitleditems-model";
@Injectable({
   providedIn: "root"
})
export class RunningItemsModel extends UnitySubscription {
   public static readonly PERF_TRACKER_TOOL_TIP = "vmware horizon performance tracker";
   public static readonly PERF_TRACKER_BLACK_LIST_KEY = "vmware.horizon.performancetracker";
   public runningItems = [];

   private signalService: any = {};
   /*
    * Fast query table for visible window, associate windowId with itemObj
    * in application instance array.
    */
   public runningVisibleItemsMap = {};
   // Fast query table for apps, associate windowPath+execPath with
   // application array.
   public runningApplicationMap = {};
   // Fast query table for all available unity Windows from server.
   public runningItemsMap = {};
   // Fast Query table for all running desktops.
   public runningDesktopMap = {};
   public addEventListener: Function;

   // Special "Loading" item that contains placeholders for loading
   // sessions
   private loadingSessionsItem = null;
   private _refreshingPage = false;
   private _currentFocusWmksKey = null;
   private _systemTrayItems = {};
   private _perfTrackerItems = {};
   private _unityReadyTimer = null;
   private _checkVisibleWindowTimer = null;
   public loadingSessionsMap = {};
   public entitledItems: Array<EntitlementItem> = null;
   private closeClientTimer;
   constructor(
      private screenUtilService: ScreenUtilService,
      private unityService: UnityService,
      private remoteSessionEventService: RemoteSessionEventService,
      private ws1Service: Ws1Service,
      private translate: TranslateService,
      private rootModel: RootModel,
      private entitledItemsModel: EntitledItemsModel
   ) {
      super();
      signal.makeObservable(this.signalService);
      this.addEventListener = this.signalService.addEventListener;
      this.init();
   }

   /**
    * generateApplicationId
    *
    * Generate a unique id based on parameters.
    * @param wmksKey     the key to identify this unique wmksSession.
    * @param windowPath  window path for this application.
    * @param exePath     exec path for this application.
    * return unique application id.
    */
   private _generateApplicationId = (wmksKey, windowPath, execPath) => {
      return wmksKey + windowPath + execPath;
   };

   /**
    * generateRunningItemId
    *
    * Generate a unique id based on parameters.
    * @param wmksKey     the key to identify this unique wmksSession.
    * @param windowId    window id for this application.
    * return unique running item id.
    */
   private _generateRunningItemId = (wmksKey, windowId) => {
      return wmksKey + windowId;
   };

   /**
    * getApplicationItems
    *
    * Get an application item instance by windowPath and execPath.
    * If the application doesn't exist and createNew is true, then create
    * a new application and request an attr update from the server.
    *
    * @param wmksKey     the key to identify this unique wmksSession.
    * @param windowPath  window path for this application.
    * @param exePath     exec path for this application.
    * @param windowId    windowId for the unity window calling this
    *    function
    * @param createNew   flags whether we should create a running
    *    application if no application is found
    *
    * return application item if found, else, returns null.
    */
   private _getApplicationItems = (wmksKey, windowPath, execPath, windowId, createNew) => {
      let id = this._generateApplicationId(wmksKey, windowPath, execPath),
         newApplication;
      const self = this;
      if (!this.runningApplicationMap[id]) {
         if (!createNew) {
            return null;
         }
         newApplication = {
            wmksKey: wmksKey,
            windowPath: windowPath,
            execPath: execPath,
            iconSrc: AB.ICONS.BIG_DEFAULT_ICON,
            name: this.translate._T("LOADING_APP"),
            focusedItem: null,
            isCollapsed: true,
            type: AB.ITEMS_TYPE.LOADING_APP,
            state: AB.ITEMS_STATE.CONNECTED,
            instances: [],
            orderedInstances: [], // instances ordered from LRU to MRU
            isFocusedItem: function () {
               return this.wmksKey === self._currentFocusWmksKey && !!this.focusedItem;
            }
         };
         this.runningApplicationMap[id] = newApplication;
         this.runningItems.push(newApplication);
         // self.emit(
         //    "appWindowChange",
         //    "add",
         //    wmksKey,
         //    windowPath,
         //    execPath,
         //    windowId
         // );
         // This is the first window and we need to retrieve application
         // name and icon.
         this.unityService.getAppAttr(wmksKey, windowPath, execPath, windowId);
      }
      return this.runningApplicationMap[id];
   };

   /**
    * removeApplicationItems
    *
    * Remove an application item instance by windowPath and execPath.
    *
    * @param wmksKey     the key to identify this unique wmksSession.
    * @param windowPath  window path for this application.
    * @param exePath     exec path for this application.
    * return true if removing this application.
    */
   private _removeApplicationItems = (wmksKey, windowPath, execPath) => {
      let id = this._generateApplicationId(wmksKey, windowPath, execPath),
         currentApp,
         appIndex;

      if (this.runningApplicationMap[id]) {
         currentApp = this.runningApplicationMap[id];
         appIndex = this.runningItems.indexOf(currentApp);
         if (appIndex !== -1) {
            this.runningItems.splice(appIndex, 1);
            // self.emit(
            //    "appWindowChange",
            //    "remove",
            //    wmksKey,
            //    windowPath,
            //    execPath
            // );
         }

         delete this.runningApplicationMap[id];
         return true;
      }
      return false;
   };

   /**
    * updateApplicationAttribute
    *
    * Update an application item attributes by windowPath and execPath.
    * It will fire a value changed event.
    *
    * @param wmksKey     the key to identify this unique wmksSession.
    * @param windowPath  window path for this application.
    * @param exePath     exec path for this application.
    * @param params      params value to be updated.
    * return true if value is updated.
    */
   private _updateApplicationAttribute = (wmksKey, windowPath, execPath, params) => {
      let id = this._generateApplicationId(wmksKey, windowPath, execPath),
         currentApp;

      if (this.runningApplicationMap[id]) {
         currentApp = this.runningApplicationMap[id];
         if (typeof params.iconSrc === "string") {
            currentApp.iconSrc = params.iconSrc;
         }
         if (typeof params.windowTitle === "string") {
            currentApp.name = params.windowTitle;
         }
         currentApp.type = AB.ITEMS_TYPE.APP;
         this.signalService.emit("valueChanged");
         return true;
      }
      return false;
   };

   /**
    * updateApplicationFocusedItem
    *
    * Update an focused item property in its parent application item.
    * It will also reset all others application focused item to null.
    * For we only allow one focused item even if there are multiple farms.
    *
    * @param focusedItem current focused item.
    */
   private _updateApplicationFocusedItem = (focusedItem) => {
      let currentApp = this._getApplicationItems(
            focusedItem.wmksKey,
            focusedItem.windowPath,
            focusedItem.execPath,
            focusedItem.windowId,
            false
         ),
         applicationIdKey = null,
         index = -1;

      if (currentApp) {
         currentApp.focusedItem = focusedItem;

         // Move the current item to the end of the ordered instance list
         index = currentApp.orderedInstances.indexOf(focusedItem);
         if (index !== -1) {
            currentApp.orderedInstances.splice(index, 1);
         }
         currentApp.orderedInstances.push(focusedItem);

         /*
          * Reset other focused unity Window in same farmer to false.
          */
         for (applicationIdKey in this.runningApplicationMap) {
            if (
               this.runningApplicationMap.hasOwnProperty(applicationIdKey) &&
               this.runningApplicationMap[applicationIdKey] !== currentApp &&
               this.runningApplicationMap[applicationIdKey].wmksKey === currentApp.wmksKey &&
               this.runningApplicationMap[applicationIdKey].focusedItem
            ) {
               this.runningApplicationMap[applicationIdKey].focusedItem.isFocused = false;
               this.runningApplicationMap[applicationIdKey].focusedItem = null;
            }
         }
         if (!this._refreshingPage) {
            this.signalService.emit("valueChanged", "appFocusUpdate", currentApp.wmksKey);
         }
         return true;
      } else {
         Logger.warning("updateApplicationFocusedItem: application not found");
         return false;
      }
   };

   /**
    * onWindowAdded
    *
    * Add an window item instance by windowId to items map.
    * All the new window is located in items map and it is
    * not visible.
    *
    * @param wmksKey     the key to identify this unique wmksSession.
    * @param windowId    window id for this application.
    * @param windowPath  window path for this application.
    * @param exePath     exec path for this application.
    * return true if a running item is added.
    */
   public onWindowAdded = (wmksKey: string, windowId: string, windowPath: string, execPath: string) => {
      let id = this._generateRunningItemId(wmksKey, windowId),
         newItem,
         applicationItem;
      if (!this.runningVisibleItemsMap[id] && !this.runningItemsMap[id]) {
         newItem = {
            wmksKey: wmksKey,
            windowId: windowId,
            windowPath: windowPath,
            execPath: execPath,
            iconSrc: AB.ICONS.SMALL_DEFAULT_ICON,
            isFocused: false,
            name: "",
            /*
             * Todo :Remove all the following three attributes after we
             * have a kind of unity-window-ready notification in unity.js.
             * We should use cached data in unity.js in the end.
             */
            windowType: undefined,
            isTransparent: true,
            isToolWindow: true,
            // Remove this attribute and use cached data in unity.js
            isMinimized: undefined
         };
         this.runningItemsMap[id] = newItem;
         if (this.closeClientTimer) {
            Logger.info("New window is added, closeClientTimer cancaled: sessionExpired");
            clearTimeout(this.closeClientTimer);
            this.closeClientTimer = null;
         }
         return true;
      } else {
         return false;
      }
   };

   /*
    * updateRunningItemVisibility
    *
    * Checks a running item's visibility. If the item is visible and in the
    * runningItemsMap, move it to runningVisibleItemsMap. If the item is not
    * visible and in runningVisibleItemsMap, move it to runningItemsMap. This
    * method should be called on a window whenever one of its parameters that
    * may effect visibility is changed.
    *
    * NOTE: If an item's visibility is changed, this method will emit valueChanged
    *
    * @params: item - the running item to check visibility on
    *
    * @return: Returns true if the item visibility has changed (the item has been
    * moved to a different running item map based on visibility)
    */
   private _updateRunningItemVisibility = (item) => {
      let id = this._generateRunningItemId(item.wmksKey, item.windowId),
         applicationItem,
         removed;

      if (this.runningItemsMap[id] && this._isRunningItemVisibleOnUI(item)) {
         applicationItem = this._getApplicationItems(item.wmksKey, item.windowPath, item.execPath, item.windowId, true);
         this.runningVisibleItemsMap[id] = item;
         delete this.runningItemsMap[id];
         applicationItem.instances.push(item);
         if (item.isFocused === true) {
            this._updateApplicationFocusedItem(item);
         }
         this.signalService.emit("valueChanged");
         return true;
      } else if (this.runningVisibleItemsMap[id] && !this._isRunningItemVisibleOnUI(item)) {
         removed = this.onWindowRemoved(item.wmksKey, item.windowId);
         if (removed) {
            this.runningItemsMap[id] = item;
         }
         return removed;
      } else {
         return false;
      }
   };

   /*
    * setCanvasVisibility
    *
    * Set a running item's visibility.
    *
    * @params: wmksKey - the key to identify this unique wmksSession.
    *
    * @isShow: set visibility to show or hide
    */
   private _setCanvasVisibility = (wmksKey, isShow) => {
      this.screenUtilService.setCanvasVisibility(wmksKey, isShow);
   };

   /**
    * onWindowRemoved
    *
    * remove an window item instance by windowId.
    * if this running item is the last instance of its application,
    * application item would be removed, too.
    * It will fire a value changed event if it is visible.
    *
    * @param wmksKey     the key to identify this unique wmksSession.
    * @param windowId    window id for this application.
    * return true if a running item is removed.
    */
   public onWindowRemoved = (wmksKey, windowId) => {
      let id = this._generateRunningItemId(wmksKey, windowId),
         currentItem,
         currentApp,
         itemIndex,
         orderedItemIndex;

      currentItem = this.runningItemsMap[id];
      /*
       * It is in the running item maps. It is not visible on the UI yet.
       * We only need to remove it from this temporary cache.
       */
      if (currentItem) {
         delete this.runningItemsMap[id];
         currentItem = null;
         return true;
      }

      currentItem = this.runningVisibleItemsMap[id];
      if (currentItem) {
         currentApp = this._getApplicationItems(
            currentItem.wmksKey,
            currentItem.windowPath,
            currentItem.execPath,
            windowId,
            false
         );
         if (!currentApp) {
            Logger.warning("removeRunningItem: application not found");
            return false;
         }
         itemIndex = currentApp.instances.indexOf(currentItem);
         if (itemIndex !== -1) {
            currentApp.instances.splice(itemIndex, 1);
         }

         orderedItemIndex = currentApp.orderedInstances.indexOf(currentItem);
         if (orderedItemIndex !== -1) {
            currentApp.orderedInstances.splice(orderedItemIndex, 1);
         }

         if (currentApp.focusedItem === currentItem) {
            currentApp.focusedItem = null;
         }

         if (currentApp.instances.length === 0) {
            this._removeApplicationItems(currentItem.wmksKey, currentApp.windowPath, currentApp.execPath);
         } else if (currentApp.instances.length === 1) {
            currentApp.isCollapsed = true;
         }

         delete this.runningVisibleItemsMap[id];
         this.signalService.emit("valueChanged", "itemRemoved", currentItem.wmksKey);
         currentItem = null;

         this._checkVisibleWindows();
         return true;
      } else {
         return false;
      }
   };

   /**
    * updateRunningItem
    *
    * Update an running item attributes by windowId.
    * It will fire a value changed event if the item is visible.
    *
    * @param wmksKey   the key to identify this unique wmksSession.
    * @param windowId  window id for this running item.
    * @param params    params value to be updated.
    * return true if value is updated.
    */
   private _updateRunningItem = (wmksKey, windowId, params) => {
      let id = this._generateRunningItemId(wmksKey, windowId),
         currentItem,
         visibleChanged = false,
         isFromVisibleMap = true;

      currentItem = this.runningVisibleItemsMap[id];
      if (!currentItem) {
         currentItem = this.runningItemsMap[id];
         isFromVisibleMap = false;
      }

      if (currentItem) {
         if (typeof params.windowTitle === "string") {
            currentItem.name = params.windowTitle;
         }

         if (typeof params.iconSrc === "string") {
            currentItem.iconSrc = params.iconSrc;
         }

         if (typeof params.windowTitle === "string" || typeof params.iconSrc === "string") {
            visibleChanged = this._updateRunningItemVisibility(currentItem);
         }

         /*
          * Notify UI only if item is visible and visibility has not changed.
          * Note that if visibility changes, updateRunningItemVisibility will
          * handle the valueChanged signal.
          */
         if (isFromVisibleMap && !visibleChanged) {
            this.signalService.emit("valueChanged");
         }
         return true;
      } else {
         return false;
      }
   };

   private _generatePerfItemId = (wmksKey, perfIconId) => {
      return wmksKey + perfIconId;
   };

   /**
    * onSystemTray
    *
    * Update an systemtray list
    * It will filter performance Tracker based on the tooltips and blackListKey
    * Only if both of tooltips and blackList are performance tracker,
    * it will be added in the perfTracker list.
    *
    * @param wmksKey   the key to identify this unique wmksSession.
    * @param systemTrayIcon
    * return true if value is updated.
    */
   public onSystemTray = (wmksKey, systemTrayIcon) => {
      const id = this._generatePerfItemId(wmksKey, systemTrayIcon.iconId);

      switch (systemTrayIcon.iconOp) {
         case 1:
            this._systemTrayItems[id] = systemTrayIcon;
            break;
         case 2:
            //    GHI_TRAY_ICON_FLAG_PNGDATA      = 1, / The pngData is valid. /
            //    GHI_TRAY_ICON_FLAG_TOOLTIP      = 2, / The tooltip is valid. /
            //    GHI_TRAY_ICON_FLAG_BLACKLISTKEY = 4  / The blacklistKey is valid. /
            if ((systemTrayIcon.iconFlags & 1) === 1) {
               this._systemTrayItems[id].iconSrc = systemTrayIcon.iconSrc;
            }
            if (((systemTrayIcon.iconFlags >> 1) & 1) === 1) {
               this._systemTrayItems[id].iconTooltip = systemTrayIcon.iconTooltip;
            }
            if (((systemTrayIcon.iconFlags >> 2) & 1) === 1) {
               this._systemTrayItems[id].iconBlackListKey = systemTrayIcon.iconBlackListKey;
            }
            break;
         case 3:
            delete this._systemTrayItems[id];
            delete this._perfTrackerItems[wmksKey];
            this._updateTrayIcon(wmksKey);
            return;
      }

      //Only when tooltip and blackList are all performance tracker,
      //The icon is performance tracker, the values of them are:
      //@tooltip: VMware Horizon Performance Tracker
      //@BlackListKey: VMware.Horizon.PerformanceTracker.0
      if (
         this._systemTrayItems[id].iconTooltip &&
         this._systemTrayItems[id].iconTooltip.toLowerCase().match(RunningItemsModel.PERF_TRACKER_TOOL_TIP) &&
         this._systemTrayItems[id].iconBlackListKey &&
         this._systemTrayItems[id].iconBlackListKey.toLowerCase().match(RunningItemsModel.PERF_TRACKER_BLACK_LIST_KEY)
      ) {
         this._perfTrackerItems[wmksKey] = this._systemTrayItems[id];
         this._updateTrayIcon(wmksKey);
      }
   };

   private _updateTrayIcon = (wmksKey) => {
      this.signalService.emit("trayUpdate", wmksKey);
   };

   public getPerfTrackerItem = (key) => {
      if (this._perfTrackerItems.hasOwnProperty(key)) {
         return this._perfTrackerItems[key];
      }
      return null;
   };

   /**
    * onAttrChanged
    *
    * Update an running item attributes by windowId.
    * It will fire a value changed event if instance value is changed
    * and this item is visible.
    *
    * @param wmksKey   the key to identify this unique wmksSession.
    * @param windowId  window id of unity window.
    * @param type      type of value.
    * @param value     value of this attribute.
    * return true if value is updated.
    */
   public onAttrChanged = (wmksKey, windowId, type, value) => {
      let id = this._generateRunningItemId(wmksKey, windowId),
         currentItem,
         currentApp = null,
         isChanged = false,
         applicationIdKey = null,
         isFromVisibleMap = true;

      currentItem = this.runningVisibleItemsMap[id];
      if (!currentItem) {
         currentItem = this.runningItemsMap[id];
         isFromVisibleMap = false;
      }

      if (currentItem) {
         if (type === Unity.UnityWindowAttribute.WINDOW_ATTR_FOCUSED && currentItem.isFocused !== value) {
            currentItem.isFocused = value;
            if (value === true) {
               this._updateApplicationFocusedItem(currentItem);
            } else {
               currentApp = this._getApplicationItems(
                  currentItem.wmksKey,
                  currentItem.windowPath,
                  currentItem.execPath,
                  currentItem.windowId,
                  false
               );
               if (currentApp && currentApp.focusedItem === currentItem) {
                  currentApp.focusedItem = null;
               }
            }
            this.signalService.emit("valueChanged");
            isChanged = true;
         } else if (
            type === Unity.UnityWindowAttribute.WINDOW_ATTR_TRANSPARENT ||
            type === Unity.UnityWindowAttribute.WINDOW_ATTR_TOOLWINDOW
         ) {
            if (type === Unity.UnityWindowAttribute.WINDOW_ATTR_TRANSPARENT) {
               currentItem.isTransparent = value;
            } else if (type === Unity.UnityWindowAttribute.WINDOW_ATTR_TOOLWINDOW) {
               currentItem.isToolWindow = value;
            }
            isChanged = this._updateRunningItemVisibility(currentItem);
         } else if (type === Unity.UnityWindowAttribute.WINDOW_ATTR_MINIMIZED) {
            currentItem.isMinimized = value;
            if (value === true) {
               this._checkVisibleWindows();
               isChanged = false;
            }
         }
      }
      return isChanged;
   };

   /**
    * onTypeChanged
    *
    * Update an running item type by windowId.
    * The item would become visible if all the conditions are meet.
    * It will fire a value changed event.
    *
    * @param wmksKey   the key to identify this unique wmksSession.
    * @param windowId  window id for this running item.
    * @param type      window type.
    * return true if value is updated.
    */
   public onTypeChanged = (wmksKey, windowId, type) => {
      let id = this._generateRunningItemId(wmksKey, windowId),
         currentItem,
         isFromVisibleMap = false;

      currentItem = this.runningItemsMap[id];
      if (!currentItem) {
         currentItem = this.runningVisibleItemsMap[id];
         isFromVisibleMap = true;
      }

      if (currentItem) {
         currentItem.windowType = type;
         return this._updateRunningItemVisibility(currentItem);
      } else {
         return false;
      }
   };

   /**
    * isRunningItemVisibleOnUI
    *
    *
    * @param runningItem running item
    *
    * Return true if running item is visible on UI.
    */
   private _isRunningItemVisibleOnUI = (runningItem) => {
      if (!runningItem.name && !runningItem.iconSrc) {
         return false;
      }
      return (
         runningItem.windowType === Unity.UnityWindowType.WINDOW_TYPE_NORMAL &&
         runningItem.isTransparent === false &&
         runningItem.isToolWindow === false
      );
   };

   /**
    * prepare to close the client when there is no usable remote resources
    * Would skip the right away client close if session expired happens.
    * Where client would be closed after clicking on OK to align with Other clients.
    */
   private _closeClientAfterAllDisconnect = () => {
      /**
       * For bug 2563555
       * Use below XML request to confirm whether the last session close is caused by
       * Broker Session Expire or not, currently only affect HTML Access client.
       */
      this.signalService.emit("pullItemsList");
      /**
       * use the 3 seconds to allow use to notice the error dialog
       * if it exist for bug 2563291 and 2563302
       * To fix bug 2864209, Set the time to 60s according to comment in:
       * https://bugzilla.eng.vmware.com/show_bug.cgi?id=2733572#c16
       */
      if (!this.closeClientTimer) {
         Logger.info(
            "Detect no running app within Blast session, wait for new app for 60s, otherwise will close Blast session"
         );
         this.closeClientTimer = setTimeout(() => {
            /**
             * use sessionExpired to align with Native Client for bug 2563555
             */
            if (!this.rootModel.get("sessionExpired")) {
               window.close();
            }
         }, 60 * 1000);
      } else {
         Logger.info("closeClientTimer existed");
      }
   };

   /**
    * checkVisibleWindows
    *
    * If unity is running and there is no visible running item on the
    * current active session, we should popup sidebar if sidebar is
    * collapsed. And emit "noVisibleWindow" to notify the sidebar to open
    * if collapsed We don't care about the item which is not visible on
    * the sidebar.
    *
    */
   private _checkVisibleWindows = (ignoreUnity?: any) => {
      /*
       * For bug 2733572, it is common for a window to show up temporarily
       * on user login and then be hidden. Client is listening to the windows
       * remove event and trigger to check running items visibility, when unity
       * windows is removed, no running items are in the map. It will cause the
       * client close. Here increase the time to 3s to wait the later unity window.
       */
      const checkVisibleWindowTime = 3000;
      /*
       * If unity has just become ready, we set a timer that blocks visible
       * window checks. We need to wait a bit for us to be notified of all
       * the windows on a pre-existing session, or we may open the sidebar
       * when no windows are visible, but the server has not yet sent us the
       * data on visible items on the desktop.
       */
      if (this._unityReadyTimer) {
         return;
      }

      // Do not open sidebar if unity is paused, we are likely in UAC mode
      if (
         (typeof ignoreUnity === "undefined" || !ignoreUnity) &&
         !this.unityService.isUnityRunning(this._currentFocusWmksKey)
      ) {
         return;
      }

      if (this._checkVisibleWindowTimer) {
         clearTimeout(this._checkVisibleWindowTimer);
         this._checkVisibleWindowTimer = null;
      }
      this._checkVisibleWindowTimer = setTimeout(
         () => {
            let runningItemKey;

            this._checkVisibleWindowTimer = null;
            for (runningItemKey in this.runningVisibleItemsMap) {
               if (
                  this.runningVisibleItemsMap.hasOwnProperty(runningItemKey) &&
                  this.runningVisibleItemsMap[runningItemKey].wmksKey === this._currentFocusWmksKey &&
                  !this.runningVisibleItemsMap[runningItemKey].isMinimized
               ) {
                  return;
               }
            }
            this.signalService.emit("noVisibleWindow");
            if (!this._hasOpenedSession()) {
               Logger.info("Last visible app closed");
               if (this.ws1Service.isWS1Mode()) {
                  this._closeClientAfterAllDisconnect();
               }
            }
         },
         checkVisibleWindowTime,
         false
      );
   };

   /**
    * removeAllRunningItemsPerSession
    *
    * Remove all the running items from the specified farm.
    * It will fire a value changed event if instance value is changed.
    *
    * @param wmksKey   the key to identify this unique wmksSession.
    */
   private _removeAllRunningItemsPerSession = (wmksKey) => {
      let runningItemKey,
         removed = false;
      for (runningItemKey in this.runningItemsMap) {
         if (
            this.runningItemsMap.hasOwnProperty(runningItemKey) &&
            this.runningItemsMap[runningItemKey].wmksKey === wmksKey
         ) {
            if (this.onWindowRemoved(wmksKey, this.runningItemsMap[runningItemKey].windowId)) {
               removed = true;
            }
         }
      }

      for (runningItemKey in this.runningVisibleItemsMap) {
         if (
            this.runningVisibleItemsMap.hasOwnProperty(runningItemKey) &&
            this.runningVisibleItemsMap[runningItemKey].wmksKey === wmksKey
         ) {
            if (this.onWindowRemoved(wmksKey, this.runningVisibleItemsMap[runningItemKey].windowId)) {
               removed = true;
            }
         }
      }
      return removed;
   };

   /**
    * addDesktopItem
    *
    * Handle the notification from wmksSevice about the added desktop.
    * Add current desktop item into running section and set the current
    * desktop to be focused. And notify UI if value is changed.
    *
    * @params desktopId unique desktopId.
    * @params isApplicationSession if it is an application session.
    * @params isShadowSession if it is a shadow session.
    * @params desktopName current desktop name
    * Return true if a desktop item is added successfully.
    */
   public addDesktopItem = (session) => {
      let newDesktopItem, item;
      if (session.isApplicationSession) {
         return false;
      }

      if (!this.runningDesktopMap[session.key]) {
         this.entitledItems = this.entitledItemsModel.getEntitledItems();
         for (let i = 0; i < this.entitledItems.length; i++) {
            if (
               this.entitledItems[i] &&
               this.entitledItems[i].id === session.key &&
               this.entitledItems[i].name === session.name
            ) {
               item = this.entitledItems[i];
               item.isRunning = true;
               break;
            }
         }
         newDesktopItem = {
            wmksKey: session.key,
            name: session.name,
            type: AB.ITEMS_TYPE.DESKTOP,
            state: AB.ITEMS_STATE.CONNECTED,
            isShadow: session.isShadow,
            instances: [],
            isCollapsed: true,
            isDesktop: true,
            isFocusedItem: () => {
               return session.key === this._currentFocusWmksKey;
            },
            sessionId: session.sessionId
         };
         if (item) {
            newDesktopItem.resetable = item.resetable;
            newDesktopItem.restartable = item.restartable;
            newDesktopItem.canLogoff = item.canLogoff;
            newDesktopItem.iconType = item.iconType;
         }
         this.runningItems.push(newDesktopItem);
         this.runningDesktopMap[session.key] = newDesktopItem;
         this.signalService.emit("valueChanged");
         return true;
      } else {
         return false;
      }
   };

   /**
    * onUnityReady
    *
    * Called when a unity session becomes ready. First, search for and
    * remove any session placeholder entries in runningItems. Then, start
    * a timer that blocks checkVisibleWindows in its duration, then calls
    * checkVisibleWindows on completion. This lets us wait a short
    * duration for unity to reload the windows on the session before we
    * do a visible windows check. See bug #1398864
    *
    * @params wmksKey: the key of the wmks session that has become ready
    */
   public onUnityReady = (wmksKey) => {
      this.onDestroy(wmksKey);

      if (this._unityReadyTimer) {
         return;
      }
      this._unityReadyTimer = setTimeout(
         () => {
            this._unityReadyTimer = null;
            this._checkVisibleWindows();
         },
         1000,
         false
      );
   };

   /**
    * changeFocusedSession
    *
    * Handle the notification from wmksSevice about the changed active
    * session. set the current focused session key and notify UI.
    *
    * @params wmksKey unique identifier for session.
    */
   private _changeFocusedSession = (session) => {
      if (!session) {
         return;
      }
      const wmksKey = session.key;
      if (this._currentFocusWmksKey !== wmksKey) {
         this._currentFocusWmksKey = wmksKey;
         this.signalService.emit("valueChanged");
      }

      // If we are currently focused on a session loading placeholder,
      // unfocus it
      if (this.loadingSessionsItem.focusedItem) {
         this.loadingSessionsItem.focusedItem.isFocused = false;
         this.loadingSessionsItem.focusedItem = null;
      }
      // If focused session has a loading placeholder, set focus on the
      // placeholder
      if (this.loadingSessionsMap[wmksKey]) {
         this.loadingSessionsItem.focusedItem = this.loadingSessionsMap[wmksKey];
         this.loadingSessionsItem.focusedItem.isFocused = true;
      }
   };

   /**
    * onSessionRemoved
    *
    * Responds to a session removed event, will remove any placeholders
    * and desktop items associated with the given session.
    *
    * @params wmksKey: the key of the calling session
    */
   private onSessionRemoved = (session: BlastWmks) => {
      let removed = false;

      this._removeLoadingPlaceholder(session.key);

      if (session.isApplicationSession) {
         removed = this._removeAllRunningItemsPerSession(session.key);
      } else {
         removed = this.removeDesktopItem(session.key);
      }

      if (session.isApplicationSession) {
         if (this._systemTrayItems.hasOwnProperty(session.key)) {
            delete this._systemTrayItems[session.key];
         }
         if (this._perfTrackerItems.hasOwnProperty(session.key)) {
            delete this._perfTrackerItems[session.key];
         }
      }

      // If we removed a session, do a visible windows check
      if (removed) {
         this._checkVisibleWindows(true);
      }

      if (!this._hasOpenedSession()) {
         Logger.info("Last visible session closed");
         if (this.ws1Service.isWS1Mode()) {
            this._closeClientAfterAllDisconnect();
         }
      }
   };

   /**
    * removeDesktopItem
    *
    * Handle the notification from wmksSevice about the removed desktop.
    * Remove specified desktop and notify UI if value is changed.
    *
    * @params desktopId unique desktopId.
    */
   private removeDesktopItem = (desktopId) => {
      let desktopItemIndex = -1,
         currentDesktopItem = this.runningDesktopMap[desktopId];
      if (currentDesktopItem) {
         desktopItemIndex = this.runningItems.indexOf(currentDesktopItem);
         if (desktopItemIndex !== -1) {
            this.entitledItems = this.entitledItemsModel.getEntitledItems();
            for (let i = 0; i < this.entitledItems.length; i++) {
               if (
                  this.entitledItems[i] &&
                  this.entitledItems[i].id === currentDesktopItem.wmksKey &&
                  this.entitledItems[i].name === currentDesktopItem.name
               ) {
                  const item = this.entitledItems[i];
                  item.isRunning = false;
                  break;
               }
            }
            this.runningItems.splice(desktopItemIndex, 1);
         }
         delete this.runningDesktopMap[desktopId];
         this.signalService.emit("valueChanged");
         return true;
      } else {
         return false;
      }
   };

   /**
    * addLoadingPlaceholder
    *
    * Creates a placeholder for a loading session, to be displayed as an
    * instance under the special "Loading" item. If the "Loading" item is
    * not displayed, add it to the list of displayed items.
    *
    * @param wmksKey: The key of the calling wmks session
    */
   private _addLoadingPlaceholder = (wmksKey) => {
      const newSessionInstance = {
         wmksKey: wmksKey,
         name: this.translate._T("LOADING_SESSION"),
         iconSrc: AB.ICONS.LOADING_ICON,
         isFocused: false
      };

      /*
       * if we had no placeholders and we just added one, add the loading item to
       * the runningItems list
       */
      if (this.loadingSessionsItem.instances.length === 0) {
         this.runningItems.push(this.loadingSessionsItem);
      }
      this.loadingSessionsItem.instances.push(newSessionInstance);
      this.loadingSessionsMap[wmksKey] = newSessionInstance;

      // If session placeholder is focused, set focus on UI
      if (this._currentFocusWmksKey === wmksKey) {
         this.loadingSessionsItem.focusedItem = newSessionInstance;
         this.loadingSessionsItem.focusedItem.isFocused = true;
      }
   };

   /**
    * removeLoadingPlaceholder
    *
    * Removes a loading items placeholder instance for a given session.
    * If the
    * "Loading" item has no instances, remove it from the sidebar.
    *
    * @param wmksKey: The key of the calling wmks session
    */
   private _removeLoadingPlaceholder = (wmksKey) => {
      /*
       * If session leaves the connecting state, remove any session
       * placeholder entries that exist.
       */
      const currentSessionInstance = this.loadingSessionsMap[wmksKey];
      let index = this.loadingSessionsItem.instances.indexOf(currentSessionInstance);

      if (!currentSessionInstance) {
         return;
      }

      if (this.loadingSessionsItem.focusedItem === currentSessionInstance) {
         this.loadingSessionsItem.focusedItem = null;
      }

      if (index !== -1) {
         this.loadingSessionsItem.instances.splice(index, 1);
      }

      /*
       * if the last placeholder has been removed, remove the loading
       * item from runningItems
       */
      if (this.loadingSessionsItem.instances.length === 0) {
         index = this.runningItems.indexOf(this.loadingSessionsItem);
         if (index !== -1) {
            this.runningItems.splice(index, 1);
         }
      }

      delete this.loadingSessionsMap[wmksKey];
      this.signalService.emit("valueChanged");
   };

   /**
    * onUnityPause
    *
    * Adds an "Attention Required" item for a paused session to the
    * sidebar.
    *
    * @param wmksKey: The key of the calling wmks session
    */
   public onUnityPause = (wmksKey) => {
      let newSessionItem;

      if (!this.runningItemsMap[wmksKey]) {
         newSessionItem = {
            wmksKey: wmksKey,
            name: this.translate._T("ATTENTION_REQUIRED"),
            iconSrc: AB.ICONS.ATTENTION_ICON,
            type: AB.ITEMS_TYPE.NEED_ATTENTION,
            instances: [],
            isCollapsed: true,
            isFocusedItem: () => {
               return wmksKey === this._currentFocusWmksKey;
            }
         };
         this.runningItems.push(newSessionItem);
         this.runningItemsMap[wmksKey] = newSessionItem;

         this.signalService.emit("valueChanged");
      } else {
         Logger.warning("addAttentionRequired: attempted to add placeholder that already existed");
      }
   };

   /**
    * onDestroy
    *
    * Removes the "Attention Required" item for a given paused session to
    * the sidebar.
    *
    * @param wmksKey: The key of the calling wmks session
    */

   public onDestroy = (wmksKey) => {
      let sessionItem, index;
      if (this.runningItemsMap[wmksKey]) {
         sessionItem = this.runningItemsMap[wmksKey];
         index = this.runningItems.indexOf(sessionItem);
         if (index !== -1) {
            this.runningItems.splice(index, 1);
         }
         delete this.runningItemsMap[wmksKey];
         this.signalService.emit("valueChanged");
      } else {
         Logger.warning("onDestroy: attempt to remove placeholder that did not exist");
      }
   };

   /**
    * Not take connectin sessions into consideration since this should only
    *    be called when session removed or app closed.
    * @return {Boolean} Returns whether has any existing sessions.
    */
   private _hasOpenedSession = () => {
      for (const key in this.runningVisibleItemsMap) {
         if (this.runningVisibleItemsMap.hasOwnProperty(key) && this.runningVisibleItemsMap[key]) {
            return true;
         }
      }
      for (const key in this.runningDesktopMap) {
         if (this.runningDesktopMap.hasOwnProperty(key)) {
            return true;
         }
      }
      return false;
   };

   /**
    * changeRunningItemStatus
    *
    * Change item status based on session status.
    *
    * @params wmksKey unique session id.
    * @params isApplicationSession isDesktop or application.
    * @params state: the item state to change to
    * Return true if status is changed.
    */
   private _changeRunningItemStatus = (wmksKey, isApplicationSession, state) => {
      let isChanged = false,
         runningApplicationKey,
         currentItem,
         hasApps = false;

      if (!isApplicationSession) {
         currentItem = this.runningDesktopMap[wmksKey];
         if (currentItem && currentItem.state !== state) {
            currentItem.state = state;
            isChanged = true;
         }
      } else {
         for (runningApplicationKey in this.runningApplicationMap) {
            if (
               this.runningApplicationMap.hasOwnProperty(runningApplicationKey) &&
               this.runningApplicationMap[runningApplicationKey].wmksKey === wmksKey
            ) {
               hasApps = true;
               if (this.runningApplicationMap[runningApplicationKey].state !== state) {
                  isChanged = true;
                  this.runningApplicationMap[runningApplicationKey].state = state;
               }
            }
         }

         /*
          * On session connecting, if that session has no associated apps we
          * create a placeholder instance under the "Loading" item.
          */
         if (!hasApps && state === AB.ITEMS_STATE.CONNECTING && !this.loadingSessionsMap[wmksKey]) {
            this._addLoadingPlaceholder(wmksKey);
            isChanged = true;
         } else if (state !== AB.ITEMS_STATE.CONNECTING && this.loadingSessionsMap[wmksKey]) {
            this._removeLoadingPlaceholder(wmksKey);
            isChanged = true;
         }
      }
      if (isChanged) {
         this.signalService.emit("valueChanged");
      }
      return isChanged;
   };

   /**
    * init
    *
    * Listen to all the events.
    */
   public init = () => {
      // initialize the "Loading" item to hold loading session
      // placeholders
      this.loadingSessionsItem = {
         name: this.translate._T("LOADING"),
         iconSrc: AB.ICONS.LOADING_ICON,
         type: AB.ITEMS_TYPE.LOADING_ITEM,
         focusedItem: null,
         instances: [],
         isCollapsed: true,
         isFocusedItem: () => {
            return !!this.loadingSessionsMap[this._currentFocusWmksKey];
         }
      };

      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_ADDED_MSG, this.addDesktopItem);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_REMOVED_MSG, this.onSessionRemoved);
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTING_MSG, (session) => {
         this._changeRunningItemStatus(session.key, session.isApplicationSession, AB.ITEMS_STATE.CONNECTING);
      });
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CONNECTED_MSG, (session) => {
         this._changeRunningItemStatus(session.key, session.isApplicationSession, AB.ITEMS_STATE.CONNECTED);
      });
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_DISCONNECTED_MSG, (session) => {
         this._changeRunningItemStatus(session.key, session.isApplicationSession, AB.ITEMS_STATE.DISCONNECTED);
      });
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, this._changeFocusedSession);

      this.remoteSessionEventService.addEventListener("updateReloadingStatus", (status) => {
         this._refreshingPage = status;
      });
      this.signalService.addSignal("valueChanged");
      this.signalService.addSignal("noVisibleWindow");
      this.signalService.addSignal("trayUpdate");
      this.signalService.addSignal("appWindowChange");
      this.signalService.addSignal("pullItemsList");
      this.unityService.subject$.subscribe(this.handleUnityMessage);
   };

   public onVisibilityChanged = (wmksKey, show) => {
      this._setCanvasVisibility(wmksKey, show);
   };
   public onTitleChanged = (wmksKey, windowId, windowTitle) => {
      this._updateRunningItem(wmksKey, windowId, { windowTitle: windowTitle });
   };

   public onIconChanged = (wmksKey, windowId, iconSrc) => {
      this._updateRunningItem(wmksKey, windowId, { iconSrc: iconSrc });
   };

   public onAppAttrChanged(wmksKey, windowPath, execPath, name, iconSrc) {
      this._updateApplicationAttribute(wmksKey, windowPath, execPath, {
         windowTitle: name,
         iconSrc: iconSrc
      });
   }

   /**
    * runningItemsModel.getRunningItems
    *
    * Return a array of running application and desktop.
    */
   public getRunningItems = () => {
      return this.runningItems;
   };

   public getSystemTrayItems = () => {
      return this._systemTrayItems;
   };
   public activePerfTracker = (perfTrackerItem) => {
      //send signal
      this.unityService.activePerfTracker(perfTrackerItem);
   };

   public showPerfContextMenu = (perfTrackerItem) => {
      this.unityService.showPerfTrackerContextMenu(perfTrackerItem);
   };

   /**
    * runningItemsModel.reset
    *
    * Reset all the internal status.
    */
   public reset = () => {
      // We want to empty the array but preserve the reference
      this.runningItems.length = 0;
      this.runningItemsMap = {};
      this.runningVisibleItemsMap = {};
      this.runningApplicationMap = {};
      this.runningDesktopMap = {};
      this._systemTrayItems = {};
      this._perfTrackerItems = {};
      if (this._unityReadyTimer) {
         clearTimeout(this._unityReadyTimer);
         this._unityReadyTimer = null;
      }
      if (this._checkVisibleWindowTimer) {
         clearTimeout(this._checkVisibleWindowTimer);
         this._checkVisibleWindowTimer = null;
      }

      this.signalService.emit("valueChanged");
   };
}
