/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * html5-usb.service.ts --
 *
 * Module for wrapping usb redirection to angular factory service. usb Service
 * is a singleton service.
 *
 */
import { Injectable } from "@angular/core";
import { RemoteSessionEventService } from "../../../common/remote-session/remote-session-event.service";
import { UsbChannel, USBChannelEventCb } from "../usb-channel";
import { FeatureConfigs } from "../../../common/model/feature-configs";
import { RemoteUsbDevice, UsbEventHandler } from "../usb-remote";
import { HorizonUsb, RawImpl, USB_TYPE } from "../usblib";
import { usb } from "../usb.event";
import { EventBusService } from "../../../../core/services/event";
import { USBNotificationService } from "../usb-notification.service";
import { USBDeviceProxyService } from "../usb-device-proxy.service";
import { USBService } from "../usb-service";

@Injectable({
   providedIn: "root"
})
export class Html5UsbService extends USBService implements USBChannelEventCb, UsbEventHandler {
   constructor(
      protected remoteSessionEventService: RemoteSessionEventService,
      protected featureConfigs: FeatureConfigs,
      protected usbNotif: USBNotificationService,
      protected eventBusService: EventBusService,
      protected usbDeviceProxyService: USBDeviceProxyService
   ) {
      super(remoteSessionEventService, featureConfigs, usbNotif, eventBusService, usbDeviceProxyService);
      this._checkUsbPolicy();
      if (this.killSwitchOn && this.usbAPISupported) {
         this.init();
         // Release Device when unPlugin Devices.
         HorizonUsb.onDisconnect(USB_TYPE.WEB_USB, (usb: RawImpl) => {
            for (const device of this.redirectedDevices) {
               if (device.localDevice.getRaw() === usb.raw) {
                  this._releaseOneUSBDevice(device);
                  break;
               }
            }
         });
      }
   }

   private _checkUsbPolicy = () => {
      // default on
      this.killSwitchOn = true;
      this.usbAPISupported = HorizonUsb.isUsbAPISupported(USB_TYPE.WEB_USB);
      this.featureConfigs.registerListener("KillSwitch-USBRedirection", (on) => {
         this.killSwitchOn = on;
      });

      this.featureConfigs.setConfig("KillSwitch-USBRedirection", true);
      this.killSwitchOn = this.featureConfigs.getConfig("KillSwitch-USBRedirection");
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _getUserSelectDevices --
    *
    * call the platform API to pop up a dialog for device selection.
    *
    * Results:
    *      None.
    *
    * Side effects:
    *      None.
    *
    *---------------------------------------------------------------------------
    */
   protected _getUserSelectDevices = async () => {
      const option: USBDeviceRequestOptions = {} as USBDeviceRequestOptions;
      option.filters = [];
      let selectedDevices: Array<RawImpl> = new Array<RawImpl>();
      try {
         selectedDevices = await HorizonUsb.requestDevice(USB_TYPE.WEB_USB, option);
      } catch (e) {
         this.logger.info("web failed to requestDevice from system with exception " + e);
         return null;
      }

      for (const device of this.redirectedDevices) {
         // don't support multiple device selection.
         if (selectedDevices.length > 0 && selectedDevices[0].raw === device.getRaw()) {
            this.logger.info("already redirected devices " + device.getId());
            this.usbEventSubject$.next(new usb.DeviceAlreadyRedirected(device));
            return null;
         }
      }
      return selectedDevices[0];
   };

   public getUserSelectedDevices = async (wmksKey) => {
      const selectDevice = await this.getSelectedDevices(wmksKey);
      if (selectDevice) {
         this.initializeDevices(selectDevice, wmksKey);
      }
   };

   protected _addRedirectedDevices = (remoteDevice: RemoteUsbDevice) => {
      this.redirectedDevices.push(remoteDevice);
   };

   protected _onRemoveRedirectedDevices = (remoteDevice) => {
      this.logger.debug("<auto-usb> Update removed device for web client");
   };

   protected _getSplitDeviceInfo = (usbChannel, selectDevice) => {
      this.logger.debug("Web client doesn'yt support split device redirection");
      return new Array<number>();
   };

   protected _checkGooglePolicy = (vid, pid) => {
      return null;
   };
}
