/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EventBusService, BusEvent } from "@html-core";
import { Logger } from "../../../../core/libs/logger";
import { AB } from "../../../../shared/desktop/common/appblast-util.service";
import { DESKTOP_OPS, XmlApiService } from "../../../../shared/desktop/sidebar/xml-api.service";
import { UnityService } from "../../../../shared/desktop/remoteapp";
import { WmksService } from "../../../../shared/desktop/common/wmks.service";
import { LaunchItemsCtrlService } from "../../../launcher/launchitems/launch-item-ctrl.service";

@Component({
   selector: "web-contextmenu",
   templateUrl: "./context-menu.component.html",
   styleUrls: ["../contextmenu-root/context-menu-content.component.less"]
})
export class WebContextMenuComponent {
   private _selectedItem: any;
   private _isRunningItem: boolean;
   private _itemName: string;
   public showLogOffDesktop: boolean;
   public showResetDesktop: boolean;
   public showRestartDesktop: boolean;
   public showConnect: boolean;
   public showClose: boolean;
   @Output() connectEvent: EventEmitter<any> = new EventEmitter();

   constructor(
      private xmlApiService: XmlApiService,
      private unityService: UnityService,
      private wmksService: WmksService,
      private eventBusService: EventBusService,
      private launchItemsCtrlService: LaunchItemsCtrlService
   ) {
      this.eventBusService.listen(BusEvent.SessionCloseMsg.MSG_TYPE).subscribe((msg: BusEvent.SessionCloseMsg) => {
         const key = msg.wmksKey;
         this.wmksService.closeSession(key);
      });
   }

   @Input("item") set selectedItem(item) {
      this._selectedItem = item;
      if (item) {
         this._itemName = item.name;
         this._isRunningItem = !!item.wmksKey;
      }
      this._updateContextMenuItems(item);
   }

   get selectedItem() {
      return this._selectedItem;
   }

   private _updateContextMenuItems = (item) => {
      const isDesktop = item.isDesktop;
      /*
      item.logoffEnabled  for launchItem
      item.canLogoff === true for entitlementItem and runningItem
      item.isRunning for sync runningItem' status to entitlementItem, only for desktops now
      this._isRunningItem for runningItem
      */
      this.showLogOffDesktop =
         !!isDesktop &&
         !item.isShadow &&
         (item.logoffEnabled || item.canLogoff === true || item.isRunning || this._isRunningItem);
      this.showResetDesktop = !!isDesktop && (item.resetEnabled || item.resetable);
      this.showRestartDesktop = !!isDesktop && (item.restartEnabled || item.restartable);
      this.showClose = this._isRunningItem;
      this.showConnect = !this._isRunningItem;
   };

   public resetDesktop = (event) => {
      Logger.debug(`Reset ${this._itemName}  from contextmenu.`);
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      if (!this._isRunningItem) {
         this.launchItemsCtrlService.resetDesktop(this._selectedItem);
      } else {
         this.wmksService.prepareForSessionReset(this._selectedItem.wmksKey);
         this.xmlApiService.handleSessionOps(DESKTOP_OPS.RESET, this._selectedItem);
      }
      this._selectedItem.showContextMenu = false;
   };

   public logOffDesktop = (event) => {
      Logger.debug(`Logoff ${this._selectedItem}  from contextmenu.`);
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      if (!this._isRunningItem) {
         this.launchItemsCtrlService.logoffDesktop(this._selectedItem);
      } else {
         this.xmlApiService.handleSessionOps(DESKTOP_OPS.LOGOFF, this._selectedItem);
      }
      this._selectedItem.showContextMenu = false;
   };

   public restartDesktop = (event) => {
      Logger.debug(`Restart ${this._itemName}  from contextmenu.`);
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      if (!this._isRunningItem) {
         this.launchItemsCtrlService.restartDesktop(this._selectedItem);
      } else {
         this.wmksService.prepareForSessionReset(this._selectedItem.wmksKey);
         this.xmlApiService.handleSessionOps(DESKTOP_OPS.RESTART, this._selectedItem);
      }
      this._selectedItem.showContextMenu = false;
   };

   public launchItem = (event) => {
      Logger.debug(`Launch ${this._itemName}  from contextmenu.`);
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      this.connectEvent.emit(this._selectedItem);
      this._selectedItem.showContextMenu = false;
   };

   public closeRunningItem = (event) => {
      Logger.debug(`Close ${this._itemName}  from contextmenu.`);
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      if (!this._selectedItem) {
         return;
      }
      if (AB.isDesktop(this._selectedItem.type)) {
         this.xmlApiService.handleSessionOps(DESKTOP_OPS.CLOSE, this._selectedItem);
      } else {
         const instanceIds = [];
         const sortedInstances = this._selectedItem.instances.slice();
         sortedInstances.sort((a, b) => {
            return a.name.localeCompare(b.name);
         });

         // Close all instances in sorted order
         for (let i = 0; i < sortedInstances.length; i++) {
            instanceIds.push(sortedInstances[i].windowId);
         }
         this.unityService.closeWindows(this._selectedItem.wmksKey, instanceIds);
      }
      this._selectedItem.showContextMenu = false;
   };
}
