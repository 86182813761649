/**
 * *****************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Logger } from "@html-core";
import { HTML5FileSystemFileHandler } from "./html5-file-system-file-handler";

export class HTML5FileSystemDirectoryHandler {
   private static folderIndexId = 0;
   public directoryHandler;
   public removeNodeInParent;
   public folderId = "HTMLCDR";
   private fullPath: string;
   private isDirectory: boolean = true;
   private isFile: boolean = false;
   private isFA: boolean = false;
   private name: string;
   private fileEntry = [];
   private parentHandler;

   constructor(directoryHandler) {
      this.isDirectory = true;
      this.name = directoryHandler.name;
      this.fullPath = "/" + directoryHandler.name;
      this.directoryHandler = directoryHandler ? directoryHandler : null;
      this.folderId = "HTMLCDR:" + this.name + HTML5FileSystemDirectoryHandler.folderIndexId++;
   }

   public addNodeToFolder = (entry) => {
      if (!entry) {
         Logger.warning("File entry is empty.");
         return;
      }
      if (entry.isDirectory) {
         entry.parentHandler = this.directoryHandler;
      }
      entry.removeNodeInParent = this.removeNode;
      if (!this._isNodeDuplicated(entry)) {
         this.fileEntry.push(entry);
         Logger.debug("<cdr> new file is added: " + entry.name);
      }
   };

   private _isNodeDuplicated = (entry) => {
      try {
         for (let i = 0; i < this.fileEntry.length; i++) {
            if (this.fileEntry[i].name === entry.name && this.fileEntry[i].isDirectory === entry.isDirectory) {
               return true;
            }
         }
         return false;
      } catch (e) {
         return true;
      }
   };

   private addEntryInFileSystem = async (directoryHandler) => {
      const rootEntry = this;
      try {
         for await (const [key, handler] of directoryHandler.entries()) {
            if (handler.kind === "file") {
               const fileEntry = new HTML5FileSystemFileHandler();
               await fileEntry.init(handler, rootEntry);
               rootEntry.addNodeToFolder(fileEntry);
            } else if (handler.kind === "directory") {
               const subDirectoryEntry = new HTML5FileSystemDirectoryHandler(handler);
               rootEntry.addNodeToFolder(subDirectoryEntry);
            }
         }
      } catch (e) {
         Logger.error("Add cdr entries to file system failed.");
         Logger.error(e);
      }
   };

   public removeNode = (name) => {
      for (let i = 0; i < this.fileEntry.length; i++) {
         if (name === this.fileEntry[i].name) {
            Logger.debug("remove node in file system " + name);
            this.fileEntry.splice(i, 1);
            break;
         }
      }
   };

   public renameNode = (name, newName) => {
      for (let i = 0; i < this.fileEntry.length; i++) {
         if (name === this.fileEntry[i].name) {
            Logger.debug("rename node name from " + name + " to " + newName);
            this.fileEntry.splice(i, 1);
            break;
         }
      }
   };

   private removeEntry = async (entry) => {
      let handler;
      if (!entry.isDirectory) {
         handler = entry.fileHandler;
      } else {
         handler = entry.directoryHandler;
      }
      for (let i = 0; i < this.fileEntry.length; i++) {
         let entryHandler;
         if (this.fileEntry[i].isDirectory) {
            entryHandler = this.fileEntry[i].directoryHandler;
         } else {
            entryHandler = this.fileEntry[i].fileHandler;
         }
         const isSame = await entryHandler.isSameEntry(handler);
         if (isSame) {
            Logger.debug("remove folder entry in file system: " + entry.name);
            this.fileEntry.splice(i, 1);
            break;
         }
      }
   };

   public removeFileEntry = (name) => {
      for (let i = 0; i < this.fileEntry.length; i++) {
         if (this.fileEntry[i].name === name) {
            Logger.debug("remove file entry in file system: " + name);
            this.fileEntry.splice(i, 1);
            break;
         }
      }
   };

   public createWriter = (FuncOnWrite, FuncOnError?): void => {
      try {
         Logger.debug("<cdr> spy FolderEntry for HTML CDR CreateWriter");
         FuncOnWrite();
      } catch (e) {
         if (FuncOnError) {
            FuncOnError();
         }
      }
   };

   public file = (FuncOnLoad, FuncOnError?): void => {
      try {
         Logger.debug("<cdr> spy FileFunc for HTML CDR FileFunc");
         FuncOnLoad();
      } catch (e) {
         if (FuncOnError) {
            FuncOnError();
         }
      }
   };

   private _updateFileNode = async () => {
      const updated = [];
      const length = this.fileEntry.length;
      for await (const [key, handler] of this.directoryHandler.entries()) {
         if (handler.kind == "file") {
            let found = false;
            for (let i = 0; i < this.fileEntry.length; i++) {
               if (!this.fileEntry[i].isDirectory) {
                  const isSame = await this.fileEntry[i].fileHandler.isSameEntry(handler);
                  if (isSame) {
                     Logger.trace("updated content for " + this.fileEntry[i].name);
                     const fileEntry = await handler.getFile();
                     this.fileEntry[i].metaData.size = fileEntry.size;
                     this.fileEntry[i].fileEntry = fileEntry;
                     found = true;
                     updated[i] = true;
                     break;
                  }
               }
            }
            //If no file is found , should add a new Node in current directory node,
            //but this code will made redirection crush,
            if (!found) {
               Logger.trace("New added file node: " + handler.name);
               const newNodeEntry = new HTML5FileSystemFileHandler();
               await newNodeEntry.init(handler, this);
               this.addNodeToFolder(newNodeEntry);
            }
         } else {
            let found = false;
            for (let i = 0; i < this.fileEntry.length; i++) {
               if (this.fileEntry[i].isDirectory) {
                  const isSame = await this.fileEntry[i].directoryHandler.isSameEntry(handler);
                  if (isSame) {
                     Logger.trace("updated content for " + this.fileEntry[i].name);
                     found = true;
                     updated[i] = true;
                     break;
                  } else {
                     Logger.trace("New added directory node");
                  }
               }
            }
            //If no folder is found , should add a new Node in current directory node,
            //but this code will made redirection crush,
            if (!found) {
               Logger.trace("New added directory node: " + handler.name);
               const newNodeEntry = new HTML5FileSystemDirectoryHandler(handler);
               this.addNodeToFolder(newNodeEntry);
            }
         }
      }
      for (let i = length - 1; i >= 0; i--) {
         if (!updated[i]) {
            Logger.trace("Delete Node");
            if (this.fileEntry[i]) {
               await this.removeEntry(this.fileEntry[i]);
            }
         }
      }
   };

   public getMetadata = async (onLoad, FuncOnError) => {
      //Todo:
      //currently folder size will not impact read/write, but needs update
      Logger.trace("Update node in directory" + this.name);
      //Sync client content each time on getMetadata
      try {
         const permission = await this.directoryHandler.queryPermission({ mode: "readwrite" });
         Logger.debug("Query permission result: " + permission + " for folder: " + this.name);

         if (permission === "granted") {
            if (this.fileEntry.length === 0) {
               await this.addEntryInFileSystem(this.directoryHandler);
            } else {
               this._updateFileNode();
            }
            const mockedMetadata = {
               size: 0,
               modificationTime: new Date()
            };
            onLoad(mockedMetadata);
            Logger.info("Folder content loaded: " + this.name);
         }
      } catch (e) {
         Logger.exception(e);
         FuncOnError();
      }
   };

   public createReader = () => {
      Logger.debug("<cdr> spy CreateReader for HTML CDR FileFunc");
      const mockedRes = [];
      for (let i = 0; i < this.fileEntry.length; i++) {
         mockedRes.push(this.fileEntry[i]);
      }
      let count = 0;
      return {
         readEntries: (onLoad, onError) => {
            try {
               if (count > 0 && mockedRes.length > 0) {
                  mockedRes.shift();
               }
               count++;
               onLoad(mockedRes);
            } catch (e) {
               onError();
            }
         }
      };
   };

   public getFile = async (fileName, options, onSucceed) => {
      if (this.directoryHandler) {
         try {
            const fileHandler = await this.directoryHandler.getFileHandle(fileName, { create: true });
            const newEntry = new HTML5FileSystemFileHandler();
            await newEntry.init(fileHandler, this);
            this.addNodeToFolder(newEntry);
            onSucceed(newEntry);
         } catch (e) {
            Logger.debug("Get file failed");
            Logger.error(e);
         }
      }
   };

   public getDirectory = async (folderName, options, onSucceed) => {
      if (this.directoryHandler) {
         const newDirectoryHandler = await this.directoryHandler.getDirectoryHandle(folderName, { create: true });
         const newFolderEntry = new HTML5FileSystemDirectoryHandler(newDirectoryHandler);
         this.addNodeToFolder(newFolderEntry);
         onSucceed(newFolderEntry);
      } else {
         Logger.info("getDirectory fail: " + folderName);
      }
   };

   public removeRecursively = async (onSucceed, onError) => {
      try {
         const queryPermission = await this.directoryHandler.requestPermission({ mode: "readwrite" });
         if (queryPermission == "granted") {
            if (this.parentHandler) {
               this.parentHandler
                  .removeEntry(this.name)
                  .then(() => {
                     this.removeNodeInParent(this.name);
                     onSucceed();
                  })
                  .catch((e) => {
                     Logger.error(e);
                     onError(e);
                  });
            }
         } else {
            onError("Delete is prevented by user");
         }
      } catch (e) {
         Logger.exception(e);
         onError(e);
      }
   };

   public moveTo = (targetFolderEntry, targetFileName, onSuccess, onError) => {
      this.renameFile(targetFileName)
         .then(() => {
            this.fullPath.replace(this.name, targetFileName);
            //Update folder path
            this.name = targetFileName;
            onSuccess(targetFolderEntry);
         })
         .catch((e) => {
            onError(e);
         });
   };

   private renameFile = (targetFileName) => {
      return new Promise(async (resolve, reject) => {
         const queryPermission = await this.directoryHandler.requestPermission({ mode: "readwrite" });
         if (queryPermission === "granted") {
            try {
               //Currently directory handler API rename and move is not supported for directory handler
               //More details about this API defect please see below, Google is working on the solution
               //https://bugs.chromium.org/p/chromium/issues/detail?id=1250534
               this.directoryHandler
                  .move(targetFileName)
                  .then(() => {
                     resolve(true);
                  })
                  .catch((e) => {
                     reject(e);
                  });
            } catch (e) {
               reject(e);
            }
         } else {
            Logger.debug("renameFile: user denied permission.");
            reject();
         }
      });
   };
}
