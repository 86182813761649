/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { VDP_CONSTS } from "./vdp-constants";
import { VDPXdrBuffer } from "./vdp-xdr-buffer";
import { IUserRPC, IRPC } from "./vdp-service-rpc";
import SnappyJS from "snappyjs";

/** Listeners:
 *    .onReady
 *       Called for each new channel object connected to the VDPChannel.
 *       The object is now ready to send and receive RPCs.
 *
 *   .onDisconnect
 *       Called for each channel object which is disconnected from the channel.
 *       The object is disconnected and can no longer send or receive RPCs.
 *
 *   .onInvoke
 *       Called when the server on the other end of the channel called Invoke(),
 *       that is, when there is a new RPC message received.
 *          @param rpc          This parameter holds the data given by the server.
 *                              This should be altered to hold the return values,
 *                              and the RPC will be returned to the server when this
 *                              method returns.
 *                              The following properties must be processed:
 *               .command       {Integer} or {String}. The command code represents the
 *                              remote method that the object is meant to represent.
 *               .type          Whether the RPC is a REQUEST or a POST RPC.
 *               .params[]      {Integer} or {String} or {Uint8Array} The data given
 *                              by the server.
 */
export interface IVDPServiceListener {
   onInvoke(rpc: IRPC): void;
   onReady(object?: { id: number; name: string }): void;
   onDisconnect(object?: { id: number; name: string }): void;
}

/**
 * VDPChannel is a RPC wrapper over VVCChannel. Its created when a channel is
 * opened by the server. A channel is a communication path between the remote
 * application and the local plugin. The consumers will use the VDPChannel
 * object to communicate with the server using RPCs.
 * @constructor
 *
 * @param (VVChannel) vvcChannel - The VVC channel object that will be using to send
 *                                   and receive data.
 *
 */
export class VDPChannel {
   /**
    * The id of the channel.
    * @type {Integer}
    */
   public id: number;
   /**
    * The name of the channel.
    * @type {String}
    */
   public name: string;
   public secret: any;

   private vvcChannel: any = null;
   /**
    * The next request ID for the RPC.
    * @type {Integer}
    * @private
    */
   private requestID: number;
   /**
    * List of requests which are waiting for a return context
    * from the server.
    * @type {{onDone, onAbort}[]} Array of {onDone, onAbort} indexed by
    *                              requestIDs.
    * @private
    */
   // private pendingRequests: {IonDone, IonAbort}[];
   private pendingRequests: any[];

   /**
    * The initial state of the channel is PENDING, that is,
    * the channel is open from the server side but it
    * needs to be accepted by a plugin.
    *
    * @type {VDP_CONSTS.CHANNEL_STATE}
    */
   public state: number;
   /**
    * The list of all open objects on the channel.
    * @type {{id, name}[]}
    */
   private openObjects: { id: number; name: string }[];
   /**
    * The list of object names to accept from the server.
    * @type {String[]}
    */
   public desiredObjects: string[];
   private sideChannelEnabled: any;
   private arbitrarySideChannel: boolean;
   private snappyEnabled: any;
   //sideChannelEnabled type
   private sideChannel: any;
   private sideChannelManager: any;

   //callbacks of channel listener
   public onInvoke = null;
   public onReady = null;
   public onDisconnect = null;

   constructor(vvcChannel: any, sideChannelManager: any) {
      //will change the type later
      this.vvcChannel = vvcChannel;
      this.sideChannelManager = sideChannelManager;
      this.requestID = VDP_CONSTS.START_REQUEST_ID;
      this.pendingRequests = [];
      this.sideChannelEnabled = false;
      this.arbitrarySideChannel = false;
      this.snappyEnabled = false;
      this.id = vvcChannel.id;
      this.name = vvcChannel.name;
      this.state = VDP_CONSTS.CHANNEL_STATE.OPEN;
      this.openObjects = [];
      this.desiredObjects = [];
      // An event listener to be called when a RPC is received from the server.
      this.vvcChannel.onmessage = this.onmessage;
   }

   public addCallback = (callbacks: IVDPServiceListener) => {
      this.onInvoke = callbacks.onInvoke;
      this.onReady = callbacks.onReady;
      this.onDisconnect = callbacks.onDisconnect;
   };

   /**
    * Returns to disable side channel in the main channel.
    *
    * @param {Boolean} request - The request.
    *
    */
   public enableSideChannel = (request: boolean): void => {
      this.sideChannelEnabled = request;
   };

   public enableArbitrarySideChannel = (request: boolean): void => {
      this.arbitrarySideChannel = request;
   };

   /**
    * API to enable/disable snappy feature for this channel.
    * @param {boolean} request - The request value
    *
    */
   public enableSnappy = (request: boolean): void => {
      this.snappyEnabled = request;
   };

   /**
    * Returns whether the requestID is pending.
    *
    * @param {Integer} requestID - The RPC requestID.
    * @returns {Boolean} whether the request ID is pending
    */
   public isRequestPending = (requestID: number): boolean => {
      return this.pendingRequests.hasOwnProperty(requestID);
   };

   public handleRPCInvoke = (rpc: IRPC): void => {
      const objID = rpc.contexts[0];
      if (this.findOpenObjectByID(objID)) {
         this.handleOnInvoke(rpc);
      } else {
         Logger.error("Invalid server object ID: " + objID, Logger.VDP);
      }
   };

   /**
    * Callback invoked by VVC when a new message is received.
    *
    * @param data {Uint8Array} - The data received from VVC.
    * @private
    */
   private onVVCMessage = (data: Uint8Array): void => {
      /*
       * This channel data is received from the server. We decode the
       * buffer into a plain object using XDR decoder.
       */
      let userRPC = this.decode(data),
         objID;

      if (!userRPC) {
         Logger.error("Failed to decode the message received from the server.", Logger.VDP);
         return;
      }

      /*
       * The server channel object ID in the contexts array will be 0 when a
       * channel command is received. Handle the channel commands sent from the
       * server.
       */
      objID = userRPC.contexts[0];

      if (objID === 0) {
         switch (userRPC.command) {
            case VDP_CONSTS.CHANNEL_COMMANDS.CREATE_OBJECT:
               this.handleCreateObject(userRPC);
               break;
            case VDP_CONSTS.CHANNEL_COMMANDS.DESTROY_OBJECT:
               this.handleDestroyObject(userRPC);
               break;
            case VDP_CONSTS.CHANNEL_COMMANDS.SEND_VCHAN_SIDECHANNAME:
               Logger.debug("create sidechannel", Logger.VDP);
               this.handleSendSideChannel(userRPC);
               break;
            case VDP_CONSTS.CHANNEL_COMMANDS.EXCHANGE_SECRET:
               Logger.debug("exchanging secret side channel", Logger.VDP);
               this.handleExchangeSecret(userRPC);
               break;
            default:
               Logger.error("Invalid channel command received" + "from the server: " + userRPC.command, Logger.VDP);
         }
      } else {
         this.handleRPCInvoke(userRPC);
      }
   };

   public onmessage = (event: any): void => {
      this.onVVCMessage(new Uint8Array(event.data));
   };

   /*
    * Initiates a RPC between the plugin and the server object on the other end of
    * the channel.
    *
    * The object can be specified with any of userRPC.object, .objID or .objName.
    * If no object is specified the first open object will be used.
    *
    * @param userRPC       {Object} An object of the following format:
    *            .command  {Integer} or {String} The remote code that the rpc
    *                      represents.
    *            .type     {VDP_CONSTS.RPC_TYPE}  Whether the RPC is a REQUEST or a
    *                      POST RPC. Default is REQUEST.
    *            .params[] {Integer} or {String} or {Uint8Array}[]  Data to be sent
    *                      to the server.
    *            .onDone   {Function} Called when the VDPChannel.invoke call
    *                      has returned from the server.
    *            .onAbort  {Function} Called when the Invoke call fails due to a
    *                      VDPService error.
    *            .object   {{id,name}?} Object that this RPC request is for.
    *            .objID    {Integer?} Object ID that this RPC request is for.
    *            .objName  {String?} Object Name that this RPC request is for.
    *
    * Description of the two callback functions.
    *
    *    onDone                 Called when the VDPChannel.invoke call has
    *                           returned
    *                           from the server. Takes parameter:
    *        rpc                This will hold all of the return codes and values
    *                           given by the peer.
    *                           The following are the properties of the rpc object
    *                           that may be processed:
    *            .command       {Integer} or {String}. The command code represents
    *                           the remote method that the context is meant to
    *                           represent.
    *            .returnCode    {Integer} The return code set by the server.
    *            .returnParams  {Integer} or {String} or {Uint8Array}[] The return
    *                           params appended by the server.
    *
    *    onAbort                Called when the Invoke call fails due to a VDPService
    *                           error. Takes parameter:
    *        rpc                The following are the properties may be processed:
    *           .userCancelled  {Boolean} 0.
    *           .abortCode      {Integer} The abort reason.
    *
    * @returns {Integer} A non-zero requestID if successful.
    */
   public invoke = (userRPC: IUserRPC): number => {
      let sendBuffer,
         object = null,
         rpc: any = {},
         i: number;

      // Check whether userRPC is an object literal.
      if (!userRPC || userRPC.constructor !== Object) {
         Logger.error("Invalid argument.", Logger.VDP);
         return 0;
      }

      if (this.state !== VDP_CONSTS.CHANNEL_STATE.READY) {
         Logger.error(
            "The channel must be in READY(" +
               VDP_CONSTS.CHANNEL_STATE.READY +
               ") state. Current " +
               "state of the channel: " +
               this.state,
            Logger.VDP
         );
         return 0;
      }

      /*
       * Check the validity of the command. VDPService accepts number or
       * string commands.
       */
      if (!(typeof userRPC.command === "number" || typeof userRPC.command === "string")) {
         Logger.error("Invalid command type: " + userRPC.command, Logger.VDP);
         return 0;
      }

      /*
       * We receive the RPC params[] from the user.
       * TODO: need to verify whether RPCs with no parameters are allowed.
       */
      if (!(userRPC.params instanceof Array)) {
         Logger.error("Invalid argument property rpc.params: " + userRPC.params, Logger.VDP);
         return 0;
      }

      /*
       * Check if userRPC contains the right data types. If not, return
       * failure.
       */
      for (i = 0; i < userRPC.params.length; i++) {
         if (
            !(
               typeof userRPC.params[i] === "number" ||
               typeof userRPC.params[i] === "string" ||
               userRPC.params[i] instanceof Uint8Array
            )
         ) {
            Logger.error("Invalid param: " + userRPC.params[i] + " at index: " + i, Logger.VDP);
            return 0;
         }
      }

      // Set the command and the params to the internal RPC object.
      rpc.command = userRPC.command;
      rpc.params = userRPC.params;

      // The default RPC type is REQUEST.
      if (userRPC.type === VDP_CONSTS.RPC_TYPE.POST) {
         rpc.action = VDP_CONSTS.RPC_ACTION.POST;
      } else {
         rpc.action = VDP_CONSTS.RPC_ACTION.REQUEST;
      }

      /*
       * Add tbe server channel object ID and the request ID to the list
       * of contexts to be sent.
       */
      if (userRPC.object) {
         // Ensure object exists by searching by id
         object = this.findOpenObjectByID(userRPC.object.id);
      } else if (userRPC.objID) {
         object = this.findOpenObjectByID(userRPC.objID);
      } else if (userRPC.objName) {
         object = this.findOpenObjectByName(userRPC.objName);
      } else if (this.openObjects.length > 0) {
         // Just default to first open object
         object = this.openObjects[0];
      }

      if (!object) {
         Logger.error(
            "Could not find matching channel object to invoke on " +
               "id: " +
               userRPC.objID +
               ", name: " +
               userRPC.objName,
            Logger.VDP
         );
         return 0;
      }

      rpc.contexts = [object.id, this.requestID];

      if (this.sideChannel) {
         if (!this.sideChannel.invoke(rpc)) {
            return 0;
         }
      } else {
         // Encode the user RPC in XDR format.
         sendBuffer = this.encode(rpc);
         if (userRPC.toCompress) {
            sendBuffer = this.compressSendBuffer(sendBuffer);
         }

         // Send the buffer over the virtual channel.
         if (!this.vvcChannel.send(sendBuffer)) {
            return 0;
         }
      }

      // Save onDone and onAbort callbacks for REQUEST RPCs.
      if (rpc.action !== VDP_CONSTS.RPC_ACTION.POST) {
         this.pendingRequests[this.requestID] = {
            onDone: userRPC.onDone,
            onAbort: userRPC.onAbort
         };
      }

      return this.requestID++;
   };

   /**
    * Used by VDPService Tests. Retrieves the private encode function
    * to simulate RPCs in the tests.
    */
   public getEncodeFunction = (): any => {
      return this.encode;
   };

   /**
    * Used by VDPService Tests. Retrieves the private decode function
    * to simulate RPCs in the tests.
    */
   public getDecodeFunction = (): any => {
      return this.decode;
   };

   /**
    * Encodes the rpc object in XDR format. Uses XDRBuffer to do its operations.
    *
    * @private
    * @param rpc                 {Object} The object holds all of the information
    *                            of a remote call for the server. It has the
    *                            following properties some of which are optional
    *                            depending on the RPC action.
    *            .command        {Integer} or {String} The remote code that the rpc
    *                            represents.
    *            .action         {VDP_CONSTS.RPC_ACTION} The action to be performed on
    *                            the RPC.
    *            .contexts[]     {Number[]}The channel object ID and the request ID
    *                            of the RPC.
    *            .params[]       {Integer} or {String} or {Uint8Array}[] Array of
    *                            heterogeneous types. Contains data to be sent to
    *                            the peer.
    *            .returnCode     {Integer} The return code to be sent to the server.
    *            .returnParams[] {Integer} or {String} or {Uint8Array}[] Array of
    *                            heterogeneous types.
    *                            Contains data to be sent as return value of a RPC
    *                            to the peer.
    *            .abort          {Integer} Set to 1 if the RPC should be aborted.
    *                            The peer will get an onAbort callback.
    *            .userCancelled  {Integer} 0.
    *            .abortCode      {Integer} The abort code of the RPC failure sent
    *                            to the peer.
    *
    * @returns {Uint8Array} the encoded buffer.
    */
   private encode = (rpc: IRPC): Uint8Array => {
      let i: number,
         xdr = new VDPXdrBuffer();

      // Initialize the encoder.
      xdr.initEncoder();
      xdr.skipWriteBytes(VDP_CONSTS.RPC_HEADER_SIZE);

      // Write the data format as DATA_FORMAT_RPC.
      xdr.writeUint32(VDP_CONSTS.RPC_DATA_FORMAT);

      // Write the action required for the RPC by the peer.
      xdr.writeUint8(rpc.action);

      // Write the number of contexts of the RPC.
      xdr.writeUint8(rpc.contexts.length);

      /**
       * Write the contexts of the RPC.
       * contexts[0] - The server channel object ID where the RPC will be sent.
       * contexts[1] - The request ID.
       */
      for (i = 0; i < rpc.contexts.length; i++) {
         xdr.writeVariant(rpc.contexts[i]);
      }

      // Write the command of the RPC.
      xdr.writeVariant(rpc.command);

      // Write the number of params of the RPC.
      xdr.writeUint8(rpc.params.length);

      // Write the params of the RPC.
      for (i = 0; i < rpc.params.length; i++) {
         // This version does not support named params. Write an empty string.
         xdr.writeVariant("");

         // Write the param.
         xdr.writeVariant(rpc.params[i]);
      }

      /*
       * The following needs to encoded only when the RPC
       * is of type RESPONSE. RESPONSE RPCs send the
       * plugin data to the server.
       */
      if (rpc.action === VDP_CONSTS.RPC_ACTION.RESPONSE) {
         // Write the return code.
         xdr.writeUint32(rpc.returnCode);

         // Write the number of return params of the RPC.
         xdr.writeUint8(rpc.returnParams.length);

         // Write the return params from the RPC.
         for (i = 0; i < rpc.returnParams.length; i++) {
            /**
             * This version does not support named return params. Write an empty
             * string.
             */
            xdr.writeVariant("");

            // Write the param.
            xdr.writeVariant(rpc.returnParams[i]);
         }

         // Write the abort value of the RPC.
         xdr.writeUint8(rpc.abort);

         // Set the userCancelled value of the RPC.
         xdr.writeUint8(rpc.userCancelled);

         // Set the abortCode value from the RPC.
         xdr.writeUint32(rpc.abortCode);
      } else {
         xdr.skipWriteBytes(VDP_CONSTS.MINIMUM_RETURN_SECTION_LENGTH);
      }

      /*
       * Append the 24-byte wireheader at the beginning of the buffer:
       * | length | capability | unused | unused | unused | unused |
       */
      xdr.setInt32(0, xdr.getData().length);
      let cap = VDP_CONSTS.CAPABILITY;
      if (this.sideChannelEnabled) {
         cap &= VDP_CONSTS.CAP_ENABLE_SIDE_CHANNEL;
      }
      if (this.snappyEnabled) {
         cap |= VDP_CONSTS.CAP_ENABLE_SNAPPY;
      }
      xdr.setInt32(4, cap);

      /*
       * Refer review#1326609
       *   Set this byte to 0x30 will make the RPCManager to support arbitary
       *   sidechannel decision from peer.
       *
       *   Another requirement is that the main channel must send at least
       *   one message to the agent side before the side channel handshake.
       */
      if (this.arbitrarySideChannel) {
         xdr.setInt32(12, 0x30);
      }

      return xdr.getData();
   };

   /**
    * Converts the encoded data received from the server into an rpc object with
    * the below properties.
    *
    * @private
    * @param {Uint8Array} data - The encoded data buffer.
    *
    * @returns {Object}  The object holds all of the information of a remote call
    *                     for the plugin. It has the following properties:
    *            .command        {Integer} or {String} The remote code that the rpc
    *                            represents.
    *            .action         {VDP_CONSTS.RPC_ACTION} The action to be performed on
    *                            the RPC.
    *            .contexts[]     {Number[]}The channel object ID and the request ID
    *                            of the RPC.
    *            .params[]       {Integer} or {String} or {Uint8Array}[] Array of
    *                            heterogeneous types. Contains data to be sent to the
    *                            peer.
    *            .returnCode     {Integer} The return code to be sent to the server.
    *            .returnParams[] {Integer} or {String} or {Uint8Array}[] Array of
    *                            heterogeneous types.
    *                            Contains data to be sent as return value of a RPC to
    *                            the peer.
    *            .abort          {Integer} Set to 1 if the RPC should be aborted.
    *                            The peer will get an onAbort callback.
    *            .userCancelled  {Integer} 0.
    *            .abortCode      {Integer} The abort code of the RPC failure sent to
    *                            the peer.
    */
   decode = function (data: Uint8Array): IRPC {
      let rpc: any = {},
         numOfContexts,
         numOfParams,
         numOfReturnParams,
         i: number,
         param,
         xdr = new VDPXdrBuffer();

      if (!xdr.initDecoder(data)) {
         Logger.error("Initializing the decoder with the given data failed.", Logger.VDP);
         return null;
      }

      // Check whether the length matches the data length.
      if (xdr.readUint32() !== data.length) {
         Logger.error("The length of the buffer does not match the header.", Logger.VDP);
         return null;
      }

      // Move the position pointer ahead to the first data byte.
      if (!xdr.seekReadPosition(VDP_CONSTS.RPC_HEADER_SIZE)) {
         Logger.error("Cannot set the read position to the first data byte.", Logger.VDP);
         return null;
      }

      // Check the data format matches DATA_FORMAT_RPC.
      if (xdr.readUint32() !== VDP_CONSTS.RPC_DATA_FORMAT) {
         return null;
      }

      // Read the RPC action field to allow the RPC to be processed accordingly.
      rpc.action = xdr.readUint8();
      if (rpc.action === null) {
         Logger.error("Failed to read the action property of rpc.", Logger.VDP);
         return null;
      }

      // Populate the rpc.type based on the RPC_ACTION.
      if (rpc.action === VDP_CONSTS.RPC_ACTION.POST) {
         rpc.type = VDP_CONSTS.RPC_TYPE.POST;
      } else if (rpc.action === VDP_CONSTS.RPC_ACTION.REQUEST) {
         rpc.type = VDP_CONSTS.RPC_TYPE.REQUEST;
      } else if (rpc.action !== VDP_CONSTS.RPC_ACTION.RESPONSE) {
         Logger.error("Invalid action property of rpc: " + rpc.action, Logger.VDP);
         return null;
      }

      // Read the number of contexts of the RPC.
      numOfContexts = xdr.readUint8();
      if (numOfContexts === null) {
         Logger.error("Failed to read the number of contexts field of rpc.", Logger.VDP);
         return null;
      }

      /**
       * Reads the contexts of the RPC.
       * contexts[0] - The server channel object ID.
       * contexts[1] - The request ID.
       */
      rpc.contexts = [];
      for (i = 0; i < numOfContexts; ++i) {
         param = xdr.readVariant();
         if (param === null) {
            Logger.error("Failed to read the context at index: " + i, Logger.VDP);
            return null;
         }
         rpc.contexts.push(param);
      }

      // Read the command.
      rpc.command = xdr.readVariant();
      if (!(typeof rpc.command === "number" || typeof rpc.command === "string")) {
         Logger.error("Invalid command type: " + rpc.command, Logger.VDP);
         return null;
      }

      // Read the number of parameters field.
      numOfParams = xdr.readUint8();
      if (numOfParams === null) {
         Logger.error("Failed to read the number of params field of rpc.", Logger.VDP);
         return null;
      }

      // Read the params field.
      rpc.params = [];
      for (i = 0; i < numOfParams; ++i) {
         if (xdr.readVariant() === null) {
            Logger.error("Failed to read the named param at index: " + i, Logger.VDP);
            return null;
         }

         param = xdr.readVariant();
         if (param === null) {
            Logger.error("Failed to read the param value at index: " + i, Logger.VDP);
            return null;
         }
         rpc.params.push(param);
      }

      // Read the return code.
      rpc.returnCode = xdr.readUint32();
      if (rpc.returnCode === null) {
         Logger.error("Failed to read the return code of rpc.", Logger.VDP);
         return null;
      }

      // Read the number of return parameters field.
      numOfReturnParams = xdr.readUint8();
      if (numOfReturnParams === null) {
         Logger.error("Failed to read the number of return params field" + " of rpc.", Logger.VDP);
         return null;
      }

      // Read the return params.
      rpc.returnParams = [];
      for (i = 0; i < numOfReturnParams; ++i) {
         if (xdr.readVariant() === null) {
            Logger.error("Failed to read the return named parameter" + " field at index: " + i, Logger.VDP);
            return null;
         }

         param = xdr.readVariant();
         if (param === null) {
            Logger.error("Failed to read the return value at index: " + i, Logger.VDP);
            return null;
         }
         rpc.returnParams.push(param);
      }

      // Read whether the RPC has been aborted.
      rpc.abort = xdr.readUint8();
      if (rpc.abort === null) {
         Logger.error("Failed to read the abort value", Logger.VDP);
         return null;
      }

      // Read the userCancelled field.
      rpc.userCancelled = xdr.readUint8();
      if (rpc.userCancelled === null) {
         Logger.error("Failed to read the userCancelled value.", Logger.VDP);
         return null;
      }

      // Read the abortReason value from the buffer.
      rpc.abortCode = xdr.readUint32();
      if (rpc.abortCode === null) {
         Logger.error("Failed to read abortCode value.", Logger.VDP);
         return null;
      }

      return rpc;
   };

   /**
    * handle sidechannel
    */
   private handleSendSideChannel = (rpc: IRPC): void => {
      if (rpc.params.length !== 1 || typeof rpc.params[0] !== "string") {
         Logger.error("Invalid parameters: " + rpc.params, Logger.VDP);
         return;
      }

      const name = rpc.params[0];
      Logger.debug("side channel name: " + name, Logger.VDP);

      this.sideChannelManager.registerSideChannel(name, this).then((channel) => {
         this.sideChannel = channel;
      });
   };

   /**
    * Don't support crypto
    */
   private handleExchangeSecret = (rpc: IRPC): void => {
      if (
         rpc.params.length < 2 ||
         //@ts-ignore
         (typeof rpc.params[0] != "number" && rpc.params[0].length !== 8) ||
         //@ts-ignore
         (typeof rpc.params[1] != "number" && rpc.params[1].length !== 8)
      ) {
         Logger.error("Invalid parameters: " + rpc.params, Logger.VDP);
         return;
      }

      this.secret = {
         id: rpc.params[0],
         hmac: rpc.params[1]
      };

      if (this.sideChannel) {
         this.sideChannel.setSecret(this.secret);
      }
   };

   /**
    * Handles the RPC received from the server.
    *
    * @private
    * @param rpc {Object} - The rpc object described in VDPChannel.decode.
    */
   private handleOnInvoke = (rpc: IRPC): void | number => {
      let buffer, reqID, request;

      switch (rpc.action) {
         case VDP_CONSTS.RPC_ACTION.REQUEST:
            /*
             * Inform the plugin about a new incoming RPC from the server by calling
             * onInvoke. The plugin reads the RPC data from the object passed with
             * onInvoke and adds the return data to this same object which is sent
             * back to the server.
             */
            if (this.onInvoke) {
               this.onInvoke(rpc);
            } else {
               Logger.error("onInvoke Listener has not been set" + " by the plugin.", Logger.VDP);
            }

            /*
             * Send the return parameters appended to the rpc object to
             * the server.
             */
            rpc.action = VDP_CONSTS.RPC_ACTION.RESPONSE;

            // Clear the server params in this RESPONSE message.
            rpc.params = [];

            if (this.sideChannel) {
               if (!this.sideChannel.invoke(rpc)) {
                  return 0;
               }
            } else {
               buffer = this.encode(rpc);

               // Send the buffer over the virtual channel.
               if (!this.vvcChannel.send(buffer)) {
                  Logger.error(
                     "Unable to send the response buffer of the RPC with " + "request ID = " + rpc.contexts[1],
                     Logger.VDP
                  );
               }
            }

            break;

         case VDP_CONSTS.RPC_ACTION.POST:
            /*
             * Inform the plugin about a new incoming RPC from the server. We do no
             * process any return parameters of the onInvoke call as no response needs
             * to be sent to the server.
             */
            if (this.onInvoke) {
               this.onInvoke(rpc);
            } else {
               Logger.error(
                  "Unable to hand over the RPC to the " +
                     "consumer as VDPChannel.onInvoke listener " +
                     "has not been set.",
                  Logger.VDP
               );
            }

            break;

         case VDP_CONSTS.RPC_ACTION.RESPONSE:
            // Map the request ID of the RPC with the existing pending requests.
            reqID = rpc.contexts[1];
            request = this.pendingRequests[reqID];

            if (!request) {
               Logger.error(
                  "Unable to find the request ID of " + "this RESPONSE RPC in the list of pending requests.",
                  Logger.VDP
               );
               return;
            }

            // Invoke the return callbacks saved while sending the RPC.
            if (rpc.abort) {
               if (request.onAbort) {
                  request.onAbort(rpc);
               } else {
                  Logger.error(
                     "Unable to hand over the RPC to " + "the consumer as " + "onAbort listener has not been saved.",
                     Logger.VDP
                  );
               }
            } else {
               if (request.onDone) {
                  request.onDone(rpc);
               } else {
                  Logger.error(
                     "Unable to hand over the RPC to the " + "consumer as onDone listener has not been saved.",
                     Logger.VDP
                  );
               }
            }

            delete this.pendingRequests[reqID];
            break;
      }
   };

   /**
    * Handles the creation of a "channel object" by the server. A channel object is
    * required by the server for communicating with RPCs. This method simply
    * acknowledges the first "channel object" that is created by the server if the
    * channel object name if not specified using VDPService.Service.connectChannel().
    * If the "channel object" name is passed with connectChannel, vdpservice.js only
    * only acknowledges the channel object with the desired name.
    *
    * Note that javascript version of vdpService for simplification does not give the
    * ability to the plugins to create "channel objects".
    *
    * @private
    * @param rpc {Object} - The rpc object described in VDPChannel.decode.
    */
   private handleCreateObject = (rpc: IRPC): any => {
      let name, objID, sendBuffer, vdpRPC, object;

      /*
       * The channel objects will have two parameters, server's channel object name
       * and object ID.
       */
      if (rpc.params.length !== 2 || typeof rpc.params[0] !== "string" || typeof rpc.params[1] !== "number") {
         Logger.error("Invalid parameters: " + rpc.params, Logger.VDP);
         return;
      }

      name = rpc.params[0];
      objID = rpc.params[1];

      /*
       * If desiredObjects has been specified, then we must ensure that this
       * new channel object's name is in the desired object list.
       */
      if (this.desiredObjects && this.desiredObjects.length) {
         if (this.desiredObjects.indexOf(name) === -1) {
            Logger.info(
               "Ignoring channel object " +
                  name +
                  ", because" +
                  " it doesn't match desired channel objects " +
                  this.desiredObjects,
               Logger.VDP
            );
            return null;
         }
      }

      vdpRPC = {
         command: VDP_CONSTS.CHANNEL_COMMANDS.CREATE_OBJECT,
         action: VDP_CONSTS.RPC_ACTION.POST,
         params: [name, objID],
         contexts: [0, 0]
      };

      sendBuffer = this.encode(vdpRPC);

      // Send the buffer over the virtual channel.
      if (!this.vvcChannel.send(sendBuffer)) {
         Logger.error("Unable to send the CREATE_OBJECT acknowledgement.", Logger.VDP);
         return;
      }

      /*
       * VDPChannel goes into a READY state when a "Channel
       * Object" is created by the server. The channel is ready to send RPCs.
       * Fire the onReady callback.
       */
      this.state = VDP_CONSTS.CHANNEL_STATE.READY;

      // Add the object to our open objects list
      object = { id: objID, name: name };
      this.openObjects.push(object);

      Logger.info(
         "vdpService: Acknowledged object ID " + objID + ", name " + name + ", for channel " + this.name,
         Logger.VDP
      );

      // Fire the onReady callback to the listener.
      if (this.onReady) {
         this.onReady(object);
      } else {
         Logger.error("onReady Listener has not been set by the plugin.", Logger.VDP);
      }
   };

   /**
    * Handles the destruction of a "channel object".
    *
    * @private
    * @param rpc {Object} - The rpc object described in VDPChannel.decode.
    */
   private handleDestroyObject = (rpc: IRPC): any => {
      let name, objID, sendBuffer, vdpRPC, index, object;

      /*
       * The channel objects will have two parameters,
       * channel object name and ID.
       */
      if (rpc.params.length !== 2 || typeof rpc.params[0] !== "string" || typeof rpc.params[1] !== "number") {
         Logger.error("Invalid params length:" + rpc.params.length, Logger.VDP);
         return;
      }

      name = rpc.params[0];
      objID = rpc.params[1];

      // Find and remove the object from the list of open objects
      index = this.openObjects.indexOf(this.findOpenObjectByID(objID));

      if (index === -1) {
         Logger.error("Could not find open object with id:" + objID, Logger.VDP);
         return null;
      }

      object = this.openObjects.splice(index, 1)[0];

      /*
       * If there is now no server channel object to communicate with, the
       * channel goes into NOT_READY state.
       */
      if (this.openObjects.length === 0) {
         this.state = VDP_CONSTS.CHANNEL_STATE.NOT_READY;
      }

      /**
       * Send the VDP_CONSTS.CHANNEL_COMMANDS.DESTROY_OBJECT channel command
       * to the server.
       */
      vdpRPC = {
         command: VDP_CONSTS.CHANNEL_COMMANDS.DESTROY_OBJECT,
         action: VDP_CONSTS.RPC_ACTION.POST,
         params: [name, objID],
         contexts: [0, 0]
      };

      sendBuffer = this.encode(vdpRPC);

      /**
       * Send the buffer over the virtual channel. We trigger the channel
       * state change even when we fail to send the DESTROY_OBJECT packet to
       * the server.
       */
      if (!this.vvcChannel.send(sendBuffer)) {
         Logger.error("Failed to send the DESTROY_OBJECT acknowledgement.", Logger.VDP);
      }

      // Fire the onDisconnect callback to the listener.
      if (this.onDisconnect) {
         this.onDisconnect(object);
      } else {
         Logger.warning("onDisconnect Listener has not been set " + "by the plugin.", Logger.VDP);
      }
   };

   /**
    * Find an open channel object by id
    *
    * @private
    * @param {Number} id
    *    The object id to search for
    * @return {{id, name}?}
    *    The open object, or null when no object is found with id
    */
   private findOpenObjectByID = (id: number): any => {
      let i: number;

      for (i = 0; i < this.openObjects.length; ++i) {
         if (this.openObjects[i].id === id) {
            return this.openObjects[i];
         }
      }
      return null;
   };

   /**
    * Find an open channel object by name
    *
    * @private
    * @param {String} name
    *    The object name to search for
    * @return {{id, name}?}
    *    The open object, or null when no object is found with name
    */
   private findOpenObjectByName = (name: string): any => {
      let i: number;

      for (i = 0; i < this.openObjects.length; ++i) {
         if (this.openObjects[i].name === name) {
            return this.openObjects[i];
         }
      }

      return null;
   };

   /**
    * Compress the payload of buffer that will be sent out with Snappy
    *
    * @private
    * @param {Uint8Array} originalBuffer
    *    The original buffer with header
    * @return {Uint8Array}
    *    The compressed buffer with header
    */
   private compressSendBuffer = (originalBuffer: Uint8Array) => {
      // Skip the wireheader to payload part
      const payLoad = originalBuffer.subarray(VDP_CONSTS.RPC_HEADER_SIZE, originalBuffer.length);
      const rawDataLength = payLoad.length;

      const compressedData = SnappyJS.compress(payLoad);
      // Another 4 byte for save raw data length
      const newLength = VDP_CONSTS.RPC_HEADER_SIZE + 4 + compressedData.length;
      const xdr = new VDPXdrBuffer();

      // Initialize the encoder.
      xdr.initEncoder(newLength);
      // set new length to wireheader
      xdr.setInt32(0, newLength);
      // set cap
      let cap = VDP_CONSTS.CAPABILITY;
      if (this.sideChannelEnabled) {
         cap &= VDP_CONSTS.CAP_ENABLE_SIDE_CHANNEL;
      }
      if (this.snappyEnabled) {
         cap |= VDP_CONSTS.CAP_ENABLE_SNAPPY;
      }
      xdr.setInt32(4, cap);
      // set data format
      let dataFmt = 0;
      dataFmt |= VDP_CONSTS.VDP_RPC_COMP_SNAPPY;
      xdr.setInt32(8, dataFmt);
      if (this.arbitrarySideChannel) {
         xdr.setInt32(12, 0x30);
      }
      // set raw data length right after header
      xdr.setInt32(VDP_CONSTS.RPC_HEADER_SIZE, rawDataLength);

      const newBuffer = new Uint8Array(newLength);
      // copy header
      newBuffer.set(xdr.getData(), 0);
      // copy new payload
      newBuffer.set(compressedData, VDP_CONSTS.RPC_HEADER_SIZE + 4);

      return newBuffer;
   };
}
