/**
 * ******************************************************
 * Copyright (C) 2016-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { AB } from "@html-core";
import { ClientSettingModel } from "../model/client-setting-model";
import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class DPIService {
   constructor(private clientSettingModel: ClientSettingModel) {}

   public getDPI = (): string => {
      const dpiScale = this.clientSettingModel.getTargetDPIScale();
      const dpi = (AB.DPI_100_PERCENT * dpiScale).toString();
      Logger.info("[dpiService] Target display DPI is " + dpi);
      return dpi;
   };
}
