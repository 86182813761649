/**
 * *****************************************************
 * Copyright 2020 - 2023 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */
import { AB } from "./appblast-util.service";
import * as CST from "@html-core";
import { clientUtil, Logger } from "@html-core";
import { NetworkstateConfig } from "../networkState/network-state.service";

//move to the index.d.ts of wmks
export interface WmksOption {
   useVNCHandshake: boolean;
   fitToParent: boolean;
   fitGuest: boolean;
   isFitToViewer: boolean;
   isShadow: boolean;
   useUnicodeKeyboardInput: boolean;
   enableOpusAudioClips: boolean;
   enableAacAudioClips: boolean;
   enableVMWSessionClose: boolean;
   enableSubRectangleCache: boolean;
   enableMP4: boolean;
   enableVMWAudioMixer: boolean;
   sendProperMouseWheelDeltas: boolean;
   ignoredRawKeyCodes: number[];
   useNativePixels: boolean;
   displayScaleInitialOff: boolean;
   enableWindowsKey: boolean;
   enableWindowsDeleteKey: boolean;
   disableTouch: boolean;
   disableVscanKeyboard: boolean;
   retryConnectionInterval: number;
   mapMetaToCtrlForKeys: number[];
   mapMetaToCtrlForVScans: number[];
   resolutionHandler: Function;
   cursorHandler: Function;
   multimonRenderer: any;
   allowMobileKeyboardInput: boolean;
   allowMobileTrackpad: boolean;
   allowMobileExtendedKeypad: boolean;
   isTrackPadMode: boolean;
   enableKeyMapping: boolean;
   networkStateHandler: Function;
   enableNetworkIndicator: boolean;
   networkStateConfig: NetworkstateConfig;
   disableNetworkStateDisplay: boolean;
   logger: any;
   enableRTAVH264Codec: boolean;
   enableRTAVOpusCodec: boolean;
   enableRTAVDTX: boolean;
   hardwareAccelerationOption: string;
   wasmBlast: any;
   getAudioQueueSamples: Function;
   maxAudioQueueSamples: number;
   boraLogLevel: string;
   vncDecodeLogLevel: number;
}

export abstract class WmksContainer {
   public static canvasId = 0;

   private _cursorCanvas: HTMLCanvasElement = null;
   private _remoteDPI: number = 1.0;
   private _isDisplayScaleDisabled = true;
   private _cursorCursorModel = null;
   private _wmksOfflineMessageQueue: any = [];
   private _closeReason: number = -1;

   private _cursorResizeInfo = {
      postResize: () => {},
      src: "",
      width: 0,
      height: 0,
      hotx: 0,
      hoty: 0
   };

   public isActive: boolean = false;
   public wmksContainer: any;
   public isWmksConnected: boolean = false;
   public multiAudioOutEnable: boolean;
   public option: WmksOption = {
      useVNCHandshake: false,
      useUnicodeKeyboardInput: true,
      enableVMWSessionClose: true,
      enableSubRectangleCache: true,
      enableVMWAudioMixer: true,
      sendProperMouseWheelDeltas: true,
      disableVscanKeyboard: false,
      allowMobileExtendedKeypad: true,
      allowMobileKeyboardInput: true,
      allowMobileTrackpad: true,
      resolutionHandler: null,
      retryConnectionInterval: -1,
      mapMetaToCtrlForKeys: [],
      mapMetaToCtrlForVScans: [],
      enableKeyMapping: true,
      wasmBlast: null,
      getAudioQueueSamples: null,
      maxAudioQueueSamples: 0,
      boraLogLevel: "info",
      vncDecodeLogLevel: 0
   } as WmksOption;
   public logger: Logger;

   constructor() {}

   /*
    * Setup our wmks widget.
    *
    * 1. Set the preferred screen size to wmks container, so it autofits
    *    upon connection.
    * 2. Blast requests the desired resolution, hence:
    *    if it is a primary session, we set fitToParent = false, fitToGuest = true.
    *    if it is a shadow session, we will set fitToGuest = false.
    * 3. Browser sends unicode and raw keyboard inputs, set flag.
    * 4. Do not process CAPS LOCK keys (send as non-unicode keys).
    *    If Windows key simulation is disabled, don't process WIN code.
    *    VK_LWIN = 91, VK_RWIN = 92, CAPS: VK_CAPITAL = 20 are non-unicode keys.
    *    NOTE: Unicode 91, 92 are [ and \ respectively. (PR 885884)
    */
   protected _initWmksContainer = () => {
      this.option.resolutionHandler = this.resolutionHandler;
      this.wmksContainer = $("<div/>", {
         id: "wmksContainer" + WmksContainer.canvasId
      })
         .appendTo($(AB.CANVAS_PARENT_ID))
         .css({
            position: "absolute",
            display: "none",
            border: 0
         });
      WmksContainer.canvasId += 1;

      this.wmksContainer.wmks(this.option);
      if (WMKS.BROWSER.isTouchDevice() && !this.option.disableTouch) {
         this.wmksContainer.css({
            overflow: "hidden"
         });
      }
      // Register our callbacks.
      this.wmksContainer
         .bind("wmksconnected", () => {
            this.isWmksConnected = true;
            this.OnWmksConnected();
         })
         .bind("wmksconnecting", (e, info) => {
            this.OnWmksConnecting(info.vvc, info.vvcSession);
         })
         .bind("wmksbeforedisconnected", (e, closeReason) => {
            this._closeReason = closeReason;
         })
         .bind("wmksdisconnected", (e, info) => {
            this.isWmksConnected = false;
            this.OnWmksDisconnected(info.code, this._closeReason);
            this._closeReason = -1;
         })
         .bind("wmksmultiaudioout", (e, useVMWDynamicMultiAudioUniqueId) => {
            if (useVMWDynamicMultiAudioUniqueId === true) {
               this.multiAudioOutEnable = true;
               this.OnWmksAudioOutputDevicesUpdated();
            }
         })
         .bind("wmksresolutionchanged", (e, size) => {
            this.OnWmksResolutionChanged(size);
         })
         .bind("wmkserror", function (e, err) {
            Logger.error("[wmks] Error: " + err);
         })
         .bind("wmksheartbeatcapacity", (e, status) => {
            this.OnWmksHeartbeatCapacity(status);
         })
         .bind("wmksheartbeat", (e, interval) => {
            this.OnWmksHeartbeat(interval);
         })
         .bind("wmkscopy", (e, data) => {
            Logger.info("[wmks] copy");
         })
         .bind("wmkstoggle", (e, itemName, toggleState) => {
            Logger.info("[wmks] toggle " + itemName + " state = " + toggleState);
         })
         .bind("wmksaudio", (e, audioInfo) => {
            this.OnWmksAudio(audioInfo);
         })
         .bind("wmksaudiomixer", (e, audioMixerInfo) => {
            this.OnWmksAudioMixer(audioMixerInfo);
         })
         .bind("wmksaudiomixermulti", (e, audioMixerMultiInfo) => {
            this.OnWmksAudioMixerMulti(audioMixerMultiInfo);
         })
         .bind("wmksreconnecttoken", (e, token) => {
            this.OnWmksReconnectToken(token);
         })
         .bind("wmksupdatemultimoncapacityui", (e, enable) => {
            this.OnWmksUpdateMultiMonCapacityUI(enable);
         })
         .bind("wmksuseractivity", this.OnWmksUserActivity)
         .bind("wmksframebufferinited", this.OnWmksFramebufferInited);
   };

   /**
    * Set a single canvas dimension, either width or height. Given a
    * desired value, we adjust the value until we get a valid setting for
    * that canvas dimension. Returns the final dimension value after
    * adjustments.
    *
    * @params isWidth: flags whether we are setting width or height
    * @params desiredValue: initial desired value for this dimension
    */
   private _setDimension = (isWidth, desiredValue) => {
      let value,
         minReached = false,
         diff,
         minimum = isWidth ? CST.AB.minScreenWidth : CST.AB.minScreenHeight,
         dimensionName = isWidth ? "width" : "height";

      if (!isWidth && clientUtil.isChromeClient() && !chrome.app.window.current().isFullscreen()) {
         const isApp = chrome.app.window.current().contentWindow.document.title === "Remote Apps";
         // We need to set canvas's height 30px less than window's size because we draw a 30px height topbar
         if (!isApp) {
            desiredValue = desiredValue - 30;
         }
      }

      if (desiredValue < minimum) {
         desiredValue = minimum;
         minReached = true;
      }

      value = desiredValue;
      // call wmksContainer.width() or wmksContainer.height()
      this.wmksContainer[dimensionName](value);

      /**
       * When client DPI is more than 100%, sometimes, the container
       * height would be a float value.  For example, if we set the
       * height to be 720, and browser would make it 720.0000323.
       * In this case, it does not really trigger a scrollbar, and
       * now necessarily to decrease the value.  See VHCH-879
       */
      diff = parseInt(this.wmksContainer[dimensionName]()) / this.wmksContainer[dimensionName]();

      if (!(diff > 0.99 && diff < 1.001)) {
         /*
          * If the browser is zoomed, the dimensions sometimes will not off by 1
          * because of rounding errors. If this happens, decrease the dimensions
          * until we get a value that doesn't get altered.
          *
          * We adjust downwards first to avoid having a scrollbar show up, but
          * if we hit the minimum we will adjust up instead.
          */

         while (this.wmksContainer[dimensionName]() !== value) {
            if (!minReached) {
               value -= 2;
               if (value < minimum) {
                  value = desiredValue + 2;
                  minReached = true;
               }
            } else {
               value += 2;
            }
            this.wmksContainer[dimensionName](value);
         }
      }

      return value;
   };

   /**
    * updateResolution
    *
    * This function applies the new screen size to the wmks container,
    * and
    * may make adjustments if necessary. If specified we will also make a
    * wmks request to rescale the canvas containing the desktop.
    *
    * @params screenSize: an Array [x,y] containing the new width and
    *    height
    * @params rescale: whether to send wmks rescale
    */
   public updateResolution = (screenSize, rescale) => {
      const reCalculateHeight = Math.floor(screenSize[1] / 2) * 2;

      this.logger.info(" Client screen size changed to (" + screenSize[0] + ", " + screenSize[1] + ")");
      const w = this._setDimension(true, screenSize[0]);
      const h = this._setDimension(false, reCalculateHeight);
      this.logger.info("Request agent resolution change to (" + w + ", " + h + ")");

      if (rescale) {
         try {
            this.wmksContainer.wmks("rescale");
            // To Fix bug 3030371
            this.sendDisplayInfo();
         } catch (e) {
            Logger.info(e);
         }
      }
   };

   /**
    * enableWindowsKey
    *
    * Enable/disable Windows key simulation function.
    *
    * @param enabled true means enable, false means disable
    */
   public enableWindowsKey = (enabled) => {
      // If Windows key simulation is disabled, don't process WIN code.
      const ignoredRawKeyCodes = enabled
         ? AB.BlastWMKS.IGNORE_RAW_KEY_CODES
         : AB.BlastWMKS.IGNORE_RAW_KEY_CODES.concat(AB.BlastWMKS.WIN_KEY_CODES);
      this.wmksContainer.wmks({
         ignoredRawKeyCodes: ignoredRawKeyCodes,
         enableWindowsKey: enabled
      });
   };

   /**
    * enableWindowsDeleteKey
    *
    * Enable/disable Windows delete key simulation function.
    *
    * @param enabled true means enable, false means disable
    */
   public enableWindowsDeleteKey = (enabled) => {
      this.wmksContainer.wmks({
         enableWindowsDeleteKey: enabled
      });
   };
   /**
    * setCanvasVisibility
    *
    * Show or hide the active canvas.
    *
    * @params isShow if to show the canvas.
    */
   public setCanvasVisibility = (isShow) => {
      if (this.wmksContainer) {
         if (isShow) {
            this.wmksContainer.hideLoading = false;
            // As there will be many times toggle to show, but one time to hide.
            // so check the status first.
            if (this.wmksContainer.css("display") === "block") {
               return;
            } else {
               this.wmksContainer.show();
            }
         } else {
            this.wmksContainer.hide();
            this.wmksContainer.hideLoading = true;
         }
      }
   };

   public cursorHandler = (src?: any, width?: any, height?: any, postResize?: any, hotx?: any, hoty?: any) => {
      if (src === undefined && this._cursorCursorModel === null) {
         return;
      }
      if (src !== undefined) {
         this._cursorCursorModel = {
            src: src,
            width: width,
            height: height,
            postResize: postResize,
            hotx: hotx,
            hoty: hoty
         };
      } else if (this._cursorCursorModel !== null) {
         src = this._cursorCursorModel.src;
         width = this._cursorCursorModel.width;
         height = this._cursorCursorModel.height;
         postResize = this._cursorCursorModel.postResize;
         hotx = this._cursorCursorModel.hotx;
         hoty = this._cursorCursorModel.hoty;
      }

      // @ts-ignore
      if (this.sendCursor) {
         // @ts-ignore
         this.sendCursor(src, width, height, hotx, hoty);
      }

      this._cursorResizeInfo.src = src;
      this._cursorResizeInfo.postResize = postResize;
      this._cursorResizeInfo.width = width;
      this._cursorResizeInfo.height = height;
      this._cursorResizeInfo.hotx = hotx;
      this._cursorResizeInfo.hoty = hoty;

      this._resizeCursor();
   };

   private _resizeCursor = () => {
      const ratio = this._remoteDPI;

      if (ratio !== 0 && ratio !== 1.0) {
         new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
               resolve(img);
            };
            // Bypass the cross domain check, see details in:
            // https://stackoverflow.com/questions/25753754/
            // canvas-todataurl-security-error-the-operation-is-insecure
            img.crossOrigin = "anonymous";
            img.src = this._cursorResizeInfo.src;
         })
            .then((img) => {
               let ctx;
               if (!this._cursorCanvas) {
                  this._cursorCanvas = document.createElement("canvas");
               }
               this._cursorCanvas.width = Math.round(this._cursorResizeInfo.width / ratio);
               this._cursorCanvas.height = Math.round(
                  (this._cursorCanvas.width * this._cursorResizeInfo.height) / this._cursorResizeInfo.width
               );
               ctx = this._cursorCanvas.getContext("2d");
               ctx.drawImage(img, 0, 0, this._cursorCanvas.width, this._cursorCanvas.height);
               return this._cursorCanvas.toDataURL("image/x-icon");
            })
            .then((url) => {
               let hx, hy;
               // need to scale hot spot as well
               if (this._cursorResizeInfo.hotx) {
                  hx = Math.round(this._cursorResizeInfo.hotx / ratio);
               }
               if (this._cursorResizeInfo.hoty) {
                  hy = Math.round(this._cursorResizeInfo.hoty / ratio);
               }
               //@ts-ignore
               this._cursorResizeInfo.postResize(url, hx, hy);
            });
      } else {
         //@ts-ignore
         this._cursorResizeInfo.postResize(this._cursorResizeInfo.src);
      }
   };

   /**
    * For this wmks session, set the remote DPI
    * @param remoteDPI
    */
   public setRemoteDPI = (remoteDPI: number, isDisplayScaleDisabled: boolean) => {
      if (remoteDPI !== this._remoteDPI) {
         this._remoteDPI = remoteDPI;
         this._resizeCursor();
         this._isDisplayScaleDisabled = isDisplayScaleDisabled;
      }
   };

   public getRemoteDPI = (): number => {
      return this._remoteDPI;
   };

   public isDisplayScaleDisabled = (): boolean => {
      return this._isDisplayScaleDisabled;
   };
   /**
    * resolution handler is called in
    * WMKS.widgetProto.updateFitGuestSize to adjust the resolution
    *
    * @param width
    * @param height
    * @returns {[*,*]}
    */
   protected resolutionHandler = (width, height) => {
      let w = parseInt(width),
         h = parseInt(height);

      // We need to round width to even for RDSH, see PR:1172572
      if (w % 2) {
         w = w - 1;
      }

      // We need to round height to even for H.264
      if (this.option.enableMP4 && h % 2) {
         h = h - 1;
      }

      return [w, h];
   };

   /**
    * destroyWmks
    *
    * Destroys this session's wmks instance and remove the canvas
    */
   protected destroyWmks = function () {
      if (this.wmksContainer) {
         this.wmksContainer.unbind("wmksdisconnected");
         // here's a issue when update to jquery 1.9.1 call wmks prior to
         // initialization
         this.wmksContainer.wmks().wmks("destroy");
         this.wmksContainer.remove();
      }
   };

   /**
    * enableWindowsKey
    *
    * Enable/disable Windows key simulation function.
    *
    * @param enabled true means enable, false means disable
    */
   protected _enableWindowsKey = (enabled) => {
      let ignoredRawKeyCodes;

      // If Windows key simulation is disabled, don't process WIN code.
      ignoredRawKeyCodes = enabled
         ? AB.BlastWMKS.IGNORE_RAW_KEY_CODES
         : AB.BlastWMKS.IGNORE_RAW_KEY_CODES.concat(AB.BlastWMKS.WIN_KEY_CODES);
      this.wmksContainer.wmks({
         ignoredRawKeyCodes: ignoredRawKeyCodes,
         enableWindowsKey: enabled
      });
   };

   /**
    * enableWindowsDeleteKey
    *
    * Enable/disable Windows delete key simulation function.
    *
    * @param enabled true means enable, false means disable
    */
   protected _enableWindowsDeleteKey = (enabled) => {
      this.wmksContainer.wmks({
         enableWindowsDeleteKey: enabled
      });
   };

   /**
    * setVisibility
    *
    * Show or hide the canvas itself. Returns true if a change was made.
    *
    * @params isShow if to show the canvas.
    */
   protected _setVisibility = (isShow) => {
      if (this.isActive === isShow) {
         return false;
      }

      this.isActive = isShow;

      if (isShow) {
         if (this.wmksContainer) {
            this.wmksContainer.show();
         }
      } else {
         if (this.wmksContainer) {
            this.wmksContainer.hide();
         }
      }
      return true;
   };

   /*
    * wmks
    *
    *Invoke WMKS widget APIs.
    */
   public wmks = (...args) => {
      const widget = this.wmksContainer;

      if (widget && this.isWmksConnected) {
         // Send to wmks if its connected.
         widget.wmks(...args);
         return;
      }
      // Queue these requests for sending upon connection.
      this._wmksOfflineMessageQueue.push(args);
   };

   /**
    * sendQueueMessages
    *
    * Loop through the list of messages in the queue and send them.
    */
   public sendQueueMessages = () => {
      let i,
         message,
         length = this._wmksOfflineMessageQueue.length;

      // send all messages, messages may be re-added to list on failure
      for (i = 0; i < length; i++) {
         message = this._wmksOfflineMessageQueue.shift();
         this.wmks(...message);
      }
   };

   public abstract OnWmksConnected(): void;
   public abstract OnWmksConnecting(vvc: any, vvcSession: any): void;
   public abstract OnWmksDisconnected(code: number, reason: number): void;
   public abstract OnWmksResolutionChanged(res: Resolution): void;
   //public abstract OnWmksError(error: number);
   public abstract OnWmksHeartbeatCapacity(enable: boolean): void;
   public abstract OnWmksHeartbeat(interval: number);
   //public abstract OnWmksCopy(): void;
   //public abstract OnWmksToggle(toggle: boolean);
   public abstract OnWmksAudio(audioInfo: any): void;
   public abstract OnWmksAudioMixer(audioMixerInfo: any): void;
   public abstract OnWmksAudioMixerMulti(audioMixerMultiInfo: any): void;
   public abstract OnWmksReconnectToken(token: string): void;
   public abstract OnWmksUpdateMultiMonCapacityUI(enable: boolean): void;
   public abstract OnWmksUserActivity(): void;
   public abstract OnWmksFramebufferInited(): void;
   public abstract OnWmksAudioOutputDevicesUpdated(): void;
   public abstract sendDisplayInfo(targetDPI?): void;
}
