/**
 * ******************************************************
 * Copyright (C) 2019-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { CLIENT, LocalStorageService, EventBusService, BusEvent, Logger } from "@html-core";
import { Injectable } from "@angular/core";
import { SessionDataService } from "../service/session-data.service";
import { AnonymousService } from "../service/anonymous.service";
import { UtilService } from "../../launcher/common/util-service";
import { ViewService } from "../../launcher/common/view-service";
import { BrokerSessionStorageService } from "../../../core/services/storage/broker-session-storage.service";

@Injectable({
   providedIn: "root"
})
export class SessionDataUpdateService {
   private currentController = null;
   private name = null;
   private data = null;

   constructor(
      private sessionDataService: SessionDataService,
      private localStorageService: LocalStorageService,
      private anonymousService: AnonymousService,
      private utilService: UtilService,
      private viewService: ViewService,
      private eventBus: EventBusService,
      private brokerSessionStorageService: BrokerSessionStorageService
   ) {}
   public handleEvent = (controller, actionName, actionObj?: any) => {
      this.name = actionName;
      this.data = actionObj;
      this.currentController = controller;
      this._resetForNewAuthenticating(this.name);
      this._resetCertAuth();
      this._switchView(this.name);
   };

   private _resetCertAuth = () => {
      this.eventBus.dispatch(new BusEvent.CertAuth(false));
   };

   private _resetForNewAuthenticating = (name) => {
      if (this._isNewAuthenticating(this.name, this.data)) {
         // clear brokerSessionStorage for previous broker session exist no
         // more
         this.brokerSessionStorageService.clear();
         this.anonymousService.setMode(false);
         this.localStorageService.clearSession();

         if (this.utilService.supportLocalStorage()) {
            window.localStorage.removeItem(CLIENT.CLIENT_LOGIN_KEY);
         }
      }
   };

   /**
    * return whether the input UI change request is an authentication one
    *
    * 'WindowsPasswordExpired', 'Disclaimer', 'SecurIDNextTokenCode',
    * 'SecurIDPinChange', 'SecurIDWait' must happen with either
    * 'SecurIDPasscode' or 'WindowsPassword', so do not do the clear work
    *
    * the HWS case is not supported yet.
    */
   private _isNewAuthenticating = (name, data) => {
      let i,
         authenticationNameList = ["SecurIDPasscode"];
      if (name === "WindowsPassword" && !(data.usernameReadOnly === "yes" && data.domainReadOnly === "yes")) {
         return true;
      }
      for (i = 0; i < authenticationNameList.length; i++) {
         if (name === authenticationNameList[i]) {
            return true;
         }
      }
      return false;
   };

   /**
    * Check if the current controller is of mapping name.
    *
    * @param currentController [in] view controller instance
    * @param name       [in] mapping name registered
    *                        in the controllers' dictionary
    * @returns true if matched; false if not matched
    */
   private _isCurrentController = (name) => {
      if (!this.currentController) {
         return false;
      }
      return this.currentController.componentName === name;
   };

   private _switchView = (name) => {
      if (this._isCurrentController(name)) {
         // Enable current page.
         this.currentController.enable(name, this.data);
         // Refresh current page.
         this.currentController.init();
      } else if (
         name === "RequestLastUserActiveTime" ||
         name === "AboutToTimeout" ||
         name === "syncWithTimer4AboutToTimeout" ||
         name === "syncWithTimer4Timeout" ||
         name === "showAboutToTimeoutDialogCallback" ||
         name === "Timeout"
      ) {
         Logger.debug("change no path for idle timeout related message: " + name);
      } else {
         if (this.currentController) {
            // Tear down the current page.
            this.currentController.tearDown(name);
         }
         this.viewService.switchView({
            action: name,
            controller: this.currentController,
            data: this.data
         });
      }
   };
}
