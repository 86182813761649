/**
 * ******************************************************
 * Copyright (C) 2016-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * file-transfer-helper.service.ts --
 *
 * Common helper for both ft and print service.
 *
 */

import Logger from "../../../core/libs/logger";
import { Injectable, Optional } from "@angular/core";
import { clientUtil } from "@html-core";
import { MKSVCHAN_CONST } from "../channels/MKSVchan/mksvchan-consts";
import { FTDownloadService } from "./file-transfer-download.service";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { RemoteSessionEventService, SessionMsg } from "../../common/remote-session/remote-session-event.service";
import { MksvchanService } from "../channels/mksvchan.service";
import { Injector } from "@angular/core";
import { FTUploadService } from "./file-transfer-upload.service";
import { SessionLifeCycleService } from "../../../chrome-client/desktop/blast-common/session-life-cycle.service";

@Injectable({
   providedIn: "root"
})
export class TransferHelper {
   private consumer = MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT;
   constructor(
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private remoteSessionEventService: RemoteSessionEventService,
      private mksvchanService: MksvchanService,
      @Optional()
      private sessionLifeCycleService: SessionLifeCycleService,
      private injector: Injector
   ) {}

   public updateDownloadFileList = (fileList, client) => {
      /**
       * $scope.downloadFiles is referred to
       * mksClient.FTUtil.downloadList[FT/PRINT]. We need to use the
       * later one, in case some bugs while desktop switch.
       * See bug 1755174, 1755201, 1756222.
       */
      if (!client || !client.FTUtil) {
         Logger.error("Cannot find FTUtil for this desktop!", Logger.FILE_TRANSFER);
         return;
      }

      let isAdded,
         downloadList = client.FTUtil.downloadList[this.consumer];
      for (let i = 0; i < fileList.length; i++) {
         isAdded = false;
         for (let j = 0; j < downloadList.length; j++) {
            if (fileList[i].fullPath === downloadList[j].fullPath && fileList[i].uuid === downloadList[j].uuid) {
               isAdded = true;
               break;
            }
         }

         if (isAdded === false) {
            downloadList.push(fileList[i]);
         }
      }
   };

   /**
    * Used to get current mks client with transfer service.
    * @param consumer
    * @returns {*}
    */
   public initTransferService = () => {
      let currentSession = this.htmlRemoteSessionManager.getCurrentSession();
      if (this.sessionLifeCycleService) {
         currentSession = this.sessionLifeCycleService.getCurrentSession();
      }

      if (currentSession === null) {
         return;
      }

      const mksClient = this.mksvchanService.getClient(currentSession.key);
      if (mksClient == null) {
         return null;
      }

      // Only file transfer has upload service now
      if (!mksClient.uploadService && this.consumer === MKSVCHAN_CONST.FILE_TRANSFER_CONSUMER.FT) {
         mksClient.uploadService = new FTUploadService(mksClient, this.injector);
      }
      if (!mksClient.downloadServices[this.consumer]) {
         mksClient.downloadServices[this.consumer] = new FTDownloadService(mksClient, this.consumer, this.injector);
      }

      return mksClient;
   };

   public addStatusListener = (func) => {
      if (typeof func !== "function") {
         Logger.info("Invalid parameter", Logger.FILE_TRANSFER);
         return;
      }

      this.mksvchanService.addEventListener("fileTransferConfigChange", function (error) {
         func();
      });

      // Listen to session change
      this.remoteSessionEventService.addEventListener(SessionMsg.SESSION_CHANGED_MSG, func);
      // Listen to new session
      this.remoteSessionEventService.addEventListener("sessionConnected", func);
   };
}
