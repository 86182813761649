/**
 * ******************************************************
 * Copyright (C) 2019-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * redirectGateway.js --
 *
 *      Implementation of the network layer to send XML to a
 *         foreign UAG, with self-signed certification support
 *      TODO: support cancel to allow UI to abort the request
 *         if it takes too long
 */

import $ from "jquery";
import { globalArray } from "../../jscdkClient";
import util from "../../util";
import Logger from "../../../../core/libs/logger";
import { clientUtil } from "@html-core";
/**
 * Set the request URL of the broker and specify
 * whether timestamp is needed to appended to each request.
 *
 * Appending a timestamp to the URL is to fix a iOS6 Ajax cache problem.
 *
 * @param brokerUrl [in] The URL address of the broker
 */
export default function RedirectGateway(uagURL) {
   this.uagURL = uagURL;
   this.brokerUrlWithTimestamp = !!navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/i);
   this.pendingRequests = [];
   this.firstConnected = true;
   this.hadSuccess = false;
}
RedirectGateway.prototype.send = function (content) {
   this.firstConnected = true;
   return this._send(content);
};
RedirectGateway.prototype.getFullXML = function (content) {
   let brokerVersion = "";
   if (globalArray) {
      brokerVersion = globalArray["brokerVersion"];
   }
   return "<?xml version='1.0' encoding='UTF-8'?><broker version='" + brokerVersion + "'>" + content + "</broker>";
};
RedirectGateway.prototype.parseXMLResponse = function (responseXML) {
   const xmlDoc = $(responseXML);
   const brokerTag = $(xmlDoc.children()[0]);
   const responses = brokerTag.children()[0];
   const result = $(responses).children("result").text();
   if (result === "ok" || result === "error") {
      const responseTag = responses.localName || responses.baseName;
      const responseObj = {};
      responseObj[responseTag] = util.parseXML($(responses).children());
      return responseObj;
   }
};

RedirectGateway.prototype._send = function (content) {
   // assume no F5 is involved under the CPA and Cloud Broker deployment
   let serverURL = this.brokerUrlWithTimestamp ? this.uagURL + "?_ab=" + $.now() : this.uagURL;
   serverURL += "/broker/xml";
   const cacheThis = this;
   const fullXMLContent = this.getFullXML(content);
   return new Promise((resolve, reject) => {
      const settings = {
         type: "POST",
         processData: false,
         url: serverURL,
         data: fullXMLContent,
         cache: false,
         success: function (response, textStatus, jscdkXHR) {
            cacheThis.firstConnected = false;
            cacheThis.hadSuccess = true;
            if (textStatus === "success") {
               Logger.debug("jscdkPost with 5 parameters success");
               const responseObject = cacheThis.parseXMLResponse(response);
               resolve({
                  uag: cacheThis,
                  responseObject: responseObject,
                  textStatus: textStatus,
                  jscdkXHR: jscdkXHR
               });
            } else if (textStatus === "error") {
               const errorMsg = "receive response of XmlHttpRequest error";
               Logger.debug(errorMsg);
               reject(errorMsg);
            }
         },
         error: function (jscdkXHR, textStatus, errorThrown) {
            if (textStatus !== "timeout") {
               cacheThis.tryResend(content).then(resolve).catch(reject);
            } else {
               const errorMsg = "timeout for redirect request, network issue detected";
               Logger.info(errorMsg);
               reject(errorMsg);
            }
         },
         async: true,
         timeout: 50000
      };
      this.checkAndAllowCrossOrigin(settings);
      const ajaxRequest = $.ajax(settings);
      this.pendingRequests.push(ajaxRequest);
      Logger.trace(util.censorMessage(content));
   });
};

RedirectGateway.prototype.getURL = function () {
   return this.uagURL;
};

/**
 * checkAndAllowCrossOrigin --
 * entitlements like Windows365 VMs will run in the zero edge infrastructure mode
 * and hence UAGs cannot be deployed in the customer's subscription. So we introduce
 * UAG-as-a-service to provide reverse connection for such kinds of deployment.
 *
 * The UAG-as-a-service are deployed globally for all customers. To provide better
 * scalability for the UAG-as-a-service. We need to enable cross-origin cookie for
 * UAG-as-a-service.
 *
 * Refer DPM-11012 and DPM-11820 for the background.
 */
RedirectGateway.prototype.checkAndAllowCrossOrigin = function (request) {
   let gwUrl: string = "";
   let dspec: DSpecToken = {} as DSpecToken;
   if (clientUtil.isTitanClient()) {
      const rawToken: string = sessionStorage.getItem("dspec_token");
      if (rawToken) {
         try {
            dspec = JSON.parse(rawToken);
            gwUrl = dspec.gwUrl;
         } catch {
            Logger.info("failed to parse stored dspec token");
         }
      }
      if (gwUrl != null && this.uagURL.includes(gwUrl)) {
         request.xhrFields = {
            withCredentials: true
         };
      }
   }
};

RedirectGateway.prototype.tryResend = async function (content) {
   return new Promise((resolve, reject) => {
      const showCertPage = globalArray["show-cert-function"];
      if (typeof showCertPage !== "function") {
         Logger.error("show-cert-function is not defined");
         reject("inner error");
         return;
      }
      if (!this.hadSuccess && this.firstConnected) {
         Logger.info("try to accept self-signed certification for UAG:" + this.uagURL);
         //await the pop out window async function, passed from get-XXX-connection from UI
         this.firstConnected = false;
         showCertPage(this.uagURL + "/broker/certAccept.html")
            .then(() => {
               this._send(content).then(resolve).catch(reject);
            })
            .catch((e) => {
               Logger.exception(e);
               if (e === "needPageJump") {
                  reject("needPageJump");
                  return;
               } else {
                  reject("popupBlocked");
                  return;
               }
            });
      } else {
         reject("failed to connect to UAG");
         return;
      }
   });
};
