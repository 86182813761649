/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input } from "@angular/core";

@Component({
   selector: "login-waiting",
   templateUrl: "./login-waiting.component.html"
})
// don't inherit for logging
export class LoginWaitingComponent {
   @Input() fromData;
}
