/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * sidechannel-manager.js --
 *
 *    Manager for sidechannels, currently only suport Vchan
 *    side channels.
 *    Don't support client-side-initialized sidechannel.
 *
 *    - use disconnectChannel when main channel is disconnected
 *    - use registerSideChannel when have channel name to set up
 *    - use onChannelConnect when Agent want to setup a vchan
 *      sidechannel.
 */

import Logger from "../../../core/libs/logger";
import SideChannel from "./sidechannel";
const SidechannelManager = function (vvcSession) {
   const self = this;
   const sideChannels = {};
   const registeredList = [];
   const searchForRegistration = function () {
      const matchedInfos = [];
      for (const key in sideChannels) {
         if (!sideChannels.hasOwnProperty(key)) {
            continue;
         }
         const sideChannel = sideChannels[key];
         if (sideChannel.ready) {
            continue;
         }
         registeredList.forEach((registrationInfo, index) => {
            if (!!sideChannel.name && sideChannel.name.endsWith(registrationInfo.name)) {
               matchedInfos.push({
                  info: registrationInfo,
                  channel: sideChannel,
                  index: index
               });
            }
         });
      }
      if (matchedInfos.length === 0) {
         Logger.trace("Can't find registration", Logger.VDP);
      } else if (matchedInfos.length > 1) {
         Logger.error(
            "Find more than 2 matched side channels, fail to hook," + "found pair number: " + matchedInfos.length,
            Logger.VDP
         );
      } else {
         const info = matchedInfos[0].info;
         const channel = matchedInfos[0].channel;
         const matchedIndex = matchedInfos[0].index;
         Logger.info("side channel created for " + info.name + " by " + info.mainChannel.name, Logger.VDP);
         registeredList.splice(matchedIndex, 1);
         channel.hook(info);
         return true;
      }
      return false;
   };

   const rejectChannel = function (channel) {
      if (!vvcSession.rejectChannel(vvcSession.getChannel(channel.id))) {
         Logger.error("vvcSession.rejectChannel failed for" + " side channel with id: " + channel.id, Logger.VDP);
         delete sideChannels[channel.id];
         return false;
      }
      return true;
   };

   const acceptChannel = function (channel) {
      // Accept the channel.
      if (!vvcSession.acceptChannel(vvcSession.getChannel(channel.id))) {
         Logger.error("vvcSession.acceptChannel failed for" + " side channel with id: " + channel.id, Logger.VDP);
         return false;
      }
      return true;
   };

   const onChannelCloseError = function (channel) {
      const sideChannel = sideChannels[channel.id];

      if (!sideChannel) {
         Logger.error(
            "Channel with id " + channel.id + " cannot be found in the list of existing side channels.",
            Logger.VDP
         );
         return;
      }

      sideChannel.onClose();
      delete sideChannels[channel.id];
   };

   return {
      /**
       * Process request for Agent channel establish.
       * @param {object} channel Of type VVCchannel
       */
      onChannelConnect: function (channel) {
         Logger.info("side channel " + name + " waiting for connection", Logger.VDP);
         // An event listener to be called when the VVC channel closes.
         channel.onclose = function () {
            onChannelCloseError(channel);
         };

         // An event listener to called when there is a VVC channel error.
         channel.onerror = function () {
            onChannelCloseError(channel);
         };

         const sideChannel = new SideChannel(channel);
         sideChannels[channel.id] = sideChannel;
         const found = searchForRegistration();
         if (!found) {
            Logger.debug(
               "can't find the matched registration for side channel " + sideChannel.name + ", treat as pending",
               Logger.VDP
            );
            acceptChannel(channel);
            sideChannel.setAsPending();
         } else {
            acceptChannel(channel);
         }
      },

      /**
       * @param  {string} name
       * @param  {object} mainChannel Of type VDPService.Channel
       * @return {object} Returns Promise that will be resolved with channel
       *    when it is connected and ready.
       */
      registerSideChannel: function (name, mainChannel) {
         Logger.info("side channel " + name + " is required by " + mainChannel.name, Logger.VDP);
         return new Promise((resolve, reject) => {
            const registerInfo = {
               name: name,
               mainChannel: mainChannel,
               resolve: resolve,
               reject: reject
            };
            registeredList.push(registerInfo);
            searchForRegistration();
         });
      },

      /**
       * Called when mainchannel is disconnected.
       * @param {object} mainChannel Of type VDPService.Channel
       */
      disconnectChannel: function (mainChannel) {
         for (const key in sideChannels) {
            if (sideChannels.hasOwnProperty(key)) {
               if (sideChannels[key].mainChannel.id === mainChannel.id) {
                  delete sideChannels[key];
               }
            }
         }
      },

      getSidechannels: function () {
         return sideChannels;
      },

      getRegisterdList: function () {
         return registeredList;
      }
   };
};

export default SidechannelManager;
