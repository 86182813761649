/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";
import { ReauthDialogService } from "../../../shared/desktop/re-auth/reauth-dialog.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { Subject } from "rxjs";
import { TranslateService } from "@html-core";
import { LoginService } from "./../login-root/login.service";

interface SecurIDNextTokenCodeData {
   content: {
      label: string;
      authChallenge: string;
      reAuth?: boolean;
   };
}
class SecurIDNextTokenCodeCredential {
   constructor(
      public passcode: string,
      public reAuth: boolean
   ) {}
}

@Component({
   selector: "login-securid-next-token",
   templateUrl: "./securid-nexttokencode.component.html"
})
export class SecurIDNextTokenComponent extends BaseViewComponent implements BaseViewComponentInterface {
   @Input() fromData;
   public cancelLoading: boolean = false;
   public title: string;
   public challengeString: string;
   private _reAuth: boolean;

   public passcodeControl: UntypedFormControl;
   public securIDNextTokenForm: UntypedFormGroup;
   // private static refreshSubject$ = new Subject<any>();
   // public static redraw = function(data: any) {
   //    SecurIDNextTokenComponent.refreshSubject$.next(data);
   // };

   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private reauthDialogService: ReauthDialogService,
      private translationService: TranslateService,
      private modalDialogService: ModalDialogService,
      private loginService: LoginService
   ) {
      super(changeDetector, "SecurIDNextTokenCode");
      // SecurIDNextTokenComponent.refreshSubject$.subscribe(() => {
      //    this.onRefreshed();
      // });
      this.loginService.securIDNextTokenCode$.subscribe((data) => {
         this.onRefreshed();
      });

      this.passcodeControl = new UntypedFormControl("", [Validators.required]);
      this.securIDNextTokenForm = new UntypedFormGroup({
         passcode: this.passcodeControl
      });
   }

   public onRefreshed = () => {
      super.onRefreshed();
      this.renderData();
   };

   public renderData = () => {
      const data = this.data as SecurIDNextTokenCodeData;
      this.onAuthScreen(this.componentName, data);
      if (data.content.label) {
         this.title = data.content.label;
      } else {
         this.title = this.translationService._T("dialog_title_login");
      }
      if (data.content.authChallenge) {
         this.challengeString = data.content.authChallenge;
      } else {
         this.challengeString = this.translationService._T("dialog_challenge_nexttoken");
      }
      this._reAuth = !!data.content.reAuth;

      this.cancelLoading = false;
      setTimeout(() => {
         this.blankOutContent = false;
      });
   };

   private _getCredential = (): SecurIDNextTokenCodeCredential => {
      const fromValue = this.securIDNextTokenForm.value;
      return new SecurIDNextTokenCodeCredential(fromValue.passcode, this._reAuth);
   };
   public onSubmit = (event) => {
      this.cancelLoading = false;
      /**
       * web API 'event.submitter' is not supported in IE and Safari
       * so add condition to verified if event.submitter is undefined here
       */
      if (
         !this.securIDNextTokenForm.invalid &&
         !this.blankOutContent &&
         (!event.submitter || (event.submitter && event.submitter.id !== "cancelLoginBtn"))
      ) {
         const credential = this._getCredential();
         if (credential.reAuth) {
            this.reauthDialogService.setReAuthCallBack();
         }
         this.jscdkCommonInvoker.submitSecurIDNextTokenCode(credential.passcode, credential.reAuth);
      }
      setTimeout(() => {
         this.blankOutContent = true;
         this.clearNextTokenCode();
      });
   };

   public cancel = () => {
      this.cancelLoading = true;
      this.data = {
         name: "SecurIDNextTokenCode",
         content: {
            label: this.title,
            authChallenge: this.challengeString
         }
      };
      if (this.blankOutContent) {
         this.jscdkCommonInvoker.cancelCurrentRequest(this.data);
         setTimeout(() => {
            this.blankOutContent = false;
            this.onRefreshed();
            // SecurIDNextTokenComponent.redraw(this.data);
         });
      } else {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"SecurID_NextTokencode");
         if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
            this.modalDialogService.closeDialogByType("reauth-window");
         }
      }
   };

   public clearNextTokenCode = () => {
      this.passcodeControl.setValue("");
   };
}
