/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Router, ActivatedRoute, RoutesRecognized, ActivationStart, ActivationEnd } from "@angular/router";
import { Injectable, NgZone } from "@angular/core";
import { Subscription } from "rxjs";
import { pairwise } from "rxjs/operators";
import Logger from "../../../core/libs/logger";
import { ClientModeService } from "../../common/service/client-mode.service";
import { filter } from "rxjs/operators";
import { clientUtil, LocalStorageService, VmwHorizonClientAuthType } from "@html-core";
import { Optional } from "@angular/core";
import { JscdkCommonInvoker } from "../jscdk/jscdk-common-invoker";
import { RootModel } from "../model/root-model";
import { globalArray } from "../../jscdk/jscdkClient";
import { LoginService } from "../../login/login-root/login.service";

const LocationEventMap = {
   NavigationStart: RoutesRecognized,
   ActivationStart: ActivationStart,
   ActivationEnd: ActivationEnd
};

@Injectable({
   providedIn: "root"
})
export class AppViewService {
   private _currentURL: string = "";
   private _authOnSAMLCanceled: boolean = false;
   private _cancelAuthentication = () => {
      if (this.rootModel.get("partialAuthedWithSAML")) {
         const sendFetch = !!window.fetch;
         if (!this._authOnSAMLCanceled) {
            Logger.info("sending cancel auth due to authOnSAMLCanceled");
            this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"Unknown", sendFetch);
            if (clientUtil.isIE()) {
               this.localStorageService.set("partialAuthedWithSAML", location.hostname);
            }
            this._authOnSAMLCanceled = true;
         }
      }
   };
   public allowJumpBack: boolean = false;
   constructor(
      @Optional()
      private route: ActivatedRoute,
      @Optional()
      private router: Router,
      private clientModeService: ClientModeService,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private rootModel: RootModel,
      private localStorageService: LocalStorageService,
      private loginService: LoginService,
      private ngZone: NgZone
   ) {
      this.onPageUnloaded();
   }

   private onPageUnloaded = () => {
      window.addEventListener("beforeunload", this._cancelAuthentication);
   };

   public isAuthCanceledOnSAML = (): boolean => {
      return this._authOnSAMLCanceled;
   };

   //Todo-ng, to be removed, if directly remove will cause login page can't load correctly
   public hadBeenSwitchedToAngular = (data) => {
      const migratedPages = [
         "Waiting",
         "SecurIDPasscode",
         "WindowsPassword",
         "Unauthenticated",
         "WindowsPasswordExpired",
         "SecurIDNextTokenCode",
         "SecurIDPinChange",
         "Disclaimer",
         "SecurIDWait",
         "SAML",
         "ShowAppBlastDesktop",
         "ShowAppBlastApplication",
         "GetLaunchItems"
      ];
      return !!data && !!data.name && migratedPages.includes(data.name);
   };

   private _forDesktopLaunching = (data) => {
      return data.name === "ShowAppBlastDesktop" || data.name === "ShowAppBlastApplication";
   };
   private _forShowItems = (data) => {
      return data.name === "GetLaunchItems";
   };

   private _forReAuthOnDesktop = (data) => {
      return data.content.reAuth && this.clientModeService.clientMode === "desktop";
   };

   public handleResponse = (data) => {
      if (this._forDesktopLaunching(data)) {
         // not jump due to wmks init is out of router.
         Logger.debug("switch to desktop", Logger.ROUTE);
      } else if (this._forShowItems(data)) {
         Logger.debug("get session information", Logger.ROUTE);
         this.jumpToLaunchItems();
      } else if (this._forReAuthOnDesktop(data)) {
         if (data.content.error) {
            Logger.debug("Update error message on reauth dialog", Logger.ROUTE);
            switch (data.name) {
               case "WindowsPassword":
                  this.loginService.redraw(data);
                  break;
               case "SecurIDPasscode":
                  this.loginService.redraw(data);
                  break;
               case "SecurIDNextTokenCode":
                  this.loginService.redraw(data);
                  break;
               case "SecurIDPinChange":
                  this.loginService.redraw(data);
                  break;
            }
         } else {
            Logger.debug("switch component on reauth dialog", Logger.ROUTE);
            this.loginService.redraw(data, "ReauthDialog");
            // ReauthDialogComponent.redraw(data);
         }
      } else {
         Logger.debug("switch to login pages", Logger.ROUTE);
         this.jumpToLogin(data);
      }
   };

   //HTML Access only
   public jumpToWarning = () => {
      this.changeLocation("/warning");
      Logger.debug("display /warning", Logger.ROUTE);
   };

   public jumpToLaunchItems = () => {
      this.changeLocation("/launchitems");
      Logger.debug("display /launchitems", Logger.ROUTE);
   };

   public jumpToDesktop = () => {
      this.changeLocation("/desktop");
      Logger.debug("display /desktop", Logger.ROUTE);

      setTimeout(() => {
         this.clientModeService.switchToDesktopMode();
      });
   };

   // TODO: remove after removing JSCDK wrapper.
   public jumpToLogin = (data) => {
      if (this.router.url === "/launchitems") {
         this.allowJumpBack = true;
      }

      if (data) {
         setTimeout(() => {
            Logger.debug("display /home for type " + data.name, Logger.ROUTE);
            this.changeLocation("/home");
            this.loginService.redraw(data, "loginCom");
            // LoginComponent.redraw(data);
         });
      } else {
         if (clientUtil.isIE() && this.localStorageService.get("partialAuthedWithSAML") === location.hostname) {
            globalArray["cancelAuthBeforeConnect"] = true;
         }
         this.changeLocation("/home");
         this.loginService.redraw({ name: "Waiting", content: null }, "loginCom");
         // LoginComponent.redraw({ name: "Waiting", content: null });
         if (!clientUtil.isChromeClient()) {
            if (!window.chromeClient) {
               window.chromeClient = {};
            }
            if (!window.chromeClient.queryModel) {
               window.chromeClient.queryModel = {
                  uri: {
                     url: location.href,
                     host: location.hostname
                  }
               };
            }
         }
         Logger.debug("display /home without data", Logger.ROUTE);
      }
   };

   public jumpToAddServer = () => {
      this.changeLocation("/addserver");
      Logger.debug("display /addserver", Logger.ROUTE);
   };

   // TODO-NG: double check the event when moving the location and router
   public bindRouteEvents = (onRouteChangeSuccess, onRouteChangeStart): void => {
      if (this.route) {
         this.route.url.subscribe((url) => {
            if (url.length > 0 && !url[0] && !url[0].parameters) {
               this._currentURL = url[0].parameters.path;
               Logger.info("client route changed to " + this._currentURL, Logger.ROUTE);
            }
         });
      }
      // the route change result are captured for log only
      this._getSubScribe(RoutesRecognized, (oldHash, newHash) => {
         Logger.info("client navigating from " + oldHash + " to " + newHash, Logger.ROUTE);
      });
      this._getSubScribe(ActivationStart, onRouteChangeStart);
      this._getSubScribe(ActivationEnd, onRouteChangeSuccess);
   };

   public getSubScribe = (eventName: string, callback: any): Subscription => {
      if (!LocationEventMap.hasOwnProperty(eventName)) {
         return null;
      }
      return this._getSubScribe(LocationEventMap[eventName], callback);
   };
   private _getSubScribe = (eventType: any, callback: any): Subscription => {
      if (eventType === RoutesRecognized) {
         return this.router.events
            .pipe(
               filter((evt: any) => evt instanceof RoutesRecognized),
               pairwise()
            )
            .subscribe((events: RoutesRecognized[]) => {
               callback(this.route, events[0].urlAfterRedirects, events[1].urlAfterRedirects);
            });
      } else {
         return this.router.events.pipe(filter((event) => event instanceof eventType)).subscribe(callback);
      }
   };

   public changeLocation = (path: string) => {
      this.ngZone.run(() => {
         Logger.info("change Location to other page");
         // this.router.navigate([path], { relativeTo: this.route });
         if (clientUtil.isChromeClient()) {
            // Chrome client will have compile error if skipLocationChange is false
            this.router.navigateByUrl(path, { skipLocationChange: true });
         } else {
            this.router.navigateByUrl(path, { skipLocationChange: false });
         }
      });
   };

   public changeLocationWithQuery = (path: string, queryParameter: any) => {
      this.router.navigate([path], { queryParams: queryParameter });
      Logger.info("change location to " + path + " with param " + JSON.stringify(queryParameter), Logger.ROUTE);
   };

   public keepInCurrentPage = () => {
      const currentRoute = this.router.routerState;
      this.router.navigateByUrl(currentRoute.snapshot.url, {
         skipLocationChange: true
      });
   };

   public getCurrentHash = () => {
      if (!this.router) {
         return "";
      }
      return this.router.url;
   };
}
