/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * UnauthSolutionHandler.js --
 *
 *      Sub handler to control the calculation worker.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function UnauthSolutionHandler() {
   let router;

   // member variables below
   this.messageName = "UnauthenticatedSolution";
   this.messageText = "UnauthenticatedSolution";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
UnauthSolutionHandler.prototype = new MessageHandler();
// constructor
UnauthSolutionHandler.constructor = UnauthSolutionHandler;

/**
 * Set the request XML for unauthenticated puzzle.
 *
 * @param solutionList [in] the result work out from client side.
 */

UnauthSolutionHandler.prototype.setRequestXML = function (solutionList) {
   let solutionText,
      solutionValueText = "",
      paramText;

   for (let i = 0; i < solutionList.length; i++) {
      const solutionValue = solutionList[i];
      solutionValueText += util.createElement("value", solutionValue + "");
   }

   solutionText = util.createElement("name", "solution");
   solutionText += util.createElement("values", solutionValueText);
   paramText = util.createElement("param", solutionText);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", "clientpuzzle");
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
