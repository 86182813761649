/**
 * ******************************************************
 * Copyright (C) 2015-2021, 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import Logger from "../../../core/libs/logger";
import util from "../../jscdk/util";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { PLATFORM_ARCH, clientUtil } from "@html-core";
import { ConnectionURIModel } from "../../common/model/connection-uri-model";
import { Localize } from "../../common/service/localize.service";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { Ws1Service } from "../../common/service/ws1.service";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";

export class callBackWrapper {
   private invoker = null;
   private callbackFunc: Function = null;
   private callbackFuncs: Array<Function> = [];
   private callbackParams: any = null;
   private callbackParamsArr: Array<any> = [];
   private action = null;
   private itemType = null;
   private loadingStatus = null;

   constructor(jscdkCommonInvoker) {
      this.invoker = jscdkCommonInvoker;
   }

   public callBackWhenUnlocked = (
      callbackFunction,
      callbackParameters?: any,
      actionName?: any,
      targetItemType?: any,
      forceClose?: any
   ) => {
      this.callbackFunc = callbackFunction;
      this.callbackFuncs.push(callbackFunction);
      this.callbackParams = callbackParameters;
      this.callbackParamsArr.push(this.callbackParams);
      this.action = actionName;
      this.itemType = targetItemType;
      this.loadingStatus = forceClose;
      Logger.info("holding " + actionName + " for unlock", Logger.IDLETIMEOUT);
      this.invoker.checkAuthenticationStatus(this.loadingStatus);
   };

   public clearAction = (reason?: string) => {
      Logger.info("clear holding " + this.action + " for unlock with reason:" + reason, Logger.IDLETIMEOUT);
      this.callbackFunc = null;
      this.callbackFuncs = [];
      this.callbackParams = null;
      this.callbackParamsArr = [];
      this.action = null;
      this.itemType = null;
   };

   public doAction = (scope) => {
      Logger.info("processing hold action " + this.action + " for unlock", Logger.IDLETIMEOUT);
      for (let i = 0; i < this.callbackFuncs.length; i++) {
         if (typeof this.callbackFuncs[i] === "function") {
            this.callbackFunc = this.callbackFuncs[i];
            this.callbackFunc.call(scope, this.callbackParamsArr[i]);
         }
      }
      this.clearAction("done the action");
   };

   public popAction = (scope) => {
      const result = {
         item: this.callbackParams,
         action: this.action,
         itemType: this.itemType
      };
      /**
       * launch item without valid check, act same as always stay in
       * select page. by adding this will provide a better animation
       * effect after finishing re-auth.
       */
      if (this.action === "start-session" || (this.action === "reset-all" && this.itemType === "application")) {
         this.doAction(scope);
         return null;
      }
      if (typeof this.callbackFunc === "function") {
         this.clearAction("action pop");
      }
      return result;
   };

   isLaunchAction = () => {
      return this.action === "start-session";
   };
}

@Injectable({
   providedIn: "root"
})
export class UtilService {
   public HTMLAccessArch: PLATFORM_ARCH;
   constructor(
      private connectionURIModel: ConnectionURIModel,
      private localize: Localize,
      private viewClientModel: ViewClientModel,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private ws1Service: Ws1Service,
      private modalDialogService: ModalDialogService
   ) {
      this.detectArch();
   }

   public detectArch = async () => {
      this.HTMLAccessArch = (await this.viewClientModel.osModel.getArch()).arch;
   };

   public logOffConfirmation = (desktop) => {
      if (clientUtil.isChromeClient()) {
         this.modalDialogService.showConfirm({
            data: {
               titleKey: "dialog_title_logoff_retry",
               contentKey: "dialog_confirm_logoff_retry_chromeclient"
            },
            callbacks: {
               confirm: () => {
                  this.jscdkCommonInvoker.logoffDesktop(desktop.id);
               }
            }
         });
      } else {
         this.modalDialogService.showConfirm({
            data: {
               titleKey: "dialog_title_logoff_retry",
               contentKey: "dialog_confirm_logoff_retry"
            },
            callbacks: {
               confirm: () => {
                  this.jscdkCommonInvoker.logoffDesktop(desktop.id);
               }
            }
         });
      }
   };

   // Rebrand page title
   public rebrand = (rebrandName) => {
      window.document.title = rebrandName;
   };

   // Detect whether the browser supports sessionStorage.
   public isSessionStorageAllowed = () => {
      try {
         window.sessionStorage.setItem("closeOnExit", "false");
         window.sessionStorage.removeItem("closeOnExit");
         return true;
      } catch (e) {
         Logger.error("This browser does not support sessionStorage. " + e, Logger.IDLETIMEOUT);
         return false;
      }
   };

   // Detect if it's a WebKit browser.
   public isWebKitBrowser = () => {
      return /AppleWebKit/.test(navigator.userAgent);
   };

   // Get link by its type
   public getLinkByType = (type) => {
      const locale = this.localize.getLocale(),
         map = this.viewClientModel.linkModel[type];

      if (map.hasOwnProperty(locale)) {
         return map[locale];
      }

      return map.en;
   };

   public showLogoutDialog = () => {
      this.modalDialogService.showConfirm({
         data: {
            titleKey: "dialog_title_logout",
            contentKey: "dialog_confirm_logout"
         },
         callbacks: {
            confirm: () => {
               this.jscdkCommonInvoker.logout(true);
            }
         }
      });
   };

   // Get query string from model
   public getQueryStringFromModel = (model) => {
      let queryString = "https://" + model.host + "?",
         keys;

      for (keys in model) {
         if (model.hasOwnProperty(keys) && keys !== "host") {
            queryString += keys + "=" + model[keys] + "&";
         }
      }
      return queryString.substring(0, queryString.length - 1);
   };

   // Get largest icon from icons
   public getLargestIcon = (icons) => {
      let iconsLength = Object.keys(icons).length,
         i,
         largeFlag = 0;

      for (i = 0; i < iconsLength; i++) {
         if (parseInt(icons[i].height, 10) > parseInt(icons[largeFlag].height, 10)) {
            largeFlag = i;
         }
      }
      return largeFlag;
   };

   /* in silent mode, when user want to do action in the selector page,
    * the authentication status must be checked before allow user do it
    *
    * this handler can only record one request at a time, but ajaxBusy
    * will force user send most one request at a time.
    */
   public authenticationStatusCheck = new callBackWrapper(this.jscdkCommonInvoker);

   /**
    * hasCanvasAndContext
    *
    *    Tests if the browser supports the use of <canvas> elements
    * properly with the ability to retrieve its draw context.
    *
    * @return Boolean. true if the browser supports HTML5 canvas; false
    *    otherwise.
    */
   public hasCanvasAndContext = () => {
      try {
         let canvas = window.document.createElement("canvas");
         const result = !!canvas.getContext; // convert to Boolean, invert
         // again.
         canvas = null; // was never added to DOM, don't need to remove
         return result;
      } catch (e) {
         return false;
      }
   };

   /**
    * runFunctionIfNotHWSOrF5
    *
    * Handle callback function according to runtime
    * If in HWSession/ F5 instead of normal session, window should be
    * closed directly, or run the callback function
    */
   public runFunctionIfNotHWSOrF5 = (callbackFunc?: any, callbackParams?) => {
      if (this.connectionURIModel.isHWSession() || this.connectionURIModel.isF5Session()) {
         if (clientUtil.isChromeClient()) {
            const self = this;
            if (typeof callbackFunc === "function") {
               callbackFunc(callbackParams);
            } else {
               Logger.info("Param callbackFunc is not a function.", Logger.IDLETIMEOUT);
            }
            return;
         } else {
            //Bug 3053634 The tab should be closed after canceling disclaimer launching from WS1 Access
            //cancelAuthentication will trigger gotoPortal, close window or jump to portal then
            this.jscdkCommonInvoker.cancelAuthentication();
            return;
         }
      }

      const self = this;
      if (typeof callbackFunc === "function") {
         callbackFunc(callbackParams);
      } else {
         Logger.info("Param callbackFunc is not a function.", Logger.IDLETIMEOUT);
      }
   };

   /**
    * isCompatibleBrowser
    *
    *    Verifies the version of the browser and check if it's supported.
    *
    * @return Boolean. true if the browser is supported; false otherwise.
    */
   public isCompatibleBrowser = () => {
      // Add Android support first, don't support IE any more 2022.5.19
      if (
         this.viewClientModel.osModel.mIsWinMobile ||
         this.viewClientModel.mIsIE ||
         (this.HTMLAccessArch === PLATFORM_ARCH.ARM && this.viewClientModel.osModel.mIsWin64)
      ) {
         if (this.HTMLAccessArch === PLATFORM_ARCH.ARM && this.viewClientModel.osModel.mIsWin64) {
            Logger.debug(
               "web client is not suggest to be used because the client OS is Windows and client machine CPU is ARM type"
            );
         }
         return false;
      } else if (this.viewClientModel.osModel.mIsAndroid) {
         return (
            (this.viewClientModel.mIsChrome &&
               this.viewClientModel.mVersionChrome >= this.viewClientModel.MIN_VERSION.Chrome) ||
            this.viewClientModel.mIsAirWatchBrowser
         );
      } else if (this.viewClientModel.osModel.mIsIOS) {
         return (
            this.viewClientModel.mIsIOSSafari ||
            this.viewClientModel.mIsAirWatchBrowser ||
            this.viewClientModel.mIsChrome ||
            this.viewClientModel.mIsGecko
         );
      } else {
         return (
            this.viewClientModel.mIsVrDevice ||
            this.viewClientModel.mIsEdge ||
            (this.viewClientModel.mIsChrome &&
               this.viewClientModel.mVersionChrome >= this.viewClientModel.MIN_VERSION.Chrome) ||
            (this.viewClientModel.mIsGecko &&
               this.viewClientModel.mVersionGecko >= this.viewClientModel.MIN_VERSION.Gecko) ||
            (this.viewClientModel.mIsWebKit &&
               this.viewClientModel.mVersionWebKit >= this.viewClientModel.MIN_VERSION.WebKit)
         );
      }
   };

   /**
    * @return This returns whether the host browser is supporting
    *    postMessage API well
    */
   public supportPostMessage = () => {
      return !(this.viewClientModel.mIsIE && this.viewClientModel.mVersionIE <= 11);
   };

   /**
    * position
    *
    *    Returns the position of the element.
    *
    * @param jQuery element
    * @return position of the element.
    */
   public position = (elem) => {
      return elem.position();
   };

   /**
    * isHidden
    *
    *    Check whether an element is hidden.
    *
    * @param jQuery element.
    * @return it is hidden or not.
    */
   public isHidden = (elem) => {
      return elem.is(":hidden");
   };

   public isVADC = () => {
      return util.getUrlParam(window.location.search.toLowerCase(), "vadc") !== null;
   };

   public supportLocalStorage = () => {
      if (clientUtil.isChromeClient()) {
         return false;
      }

      try {
         window.localStorage.setItem("testKey", "foobar");
         window.localStorage.removeItem("testKey");
         return true;
      } catch (err) {
         return false;
      }
   };

   public isCookieEnabled = () => {
      const cookieEnabled = window.navigator.cookieEnabled;
      return !!cookieEnabled;
   };
}
