<div class="modal-title dialog-title">{{ 'FOLDER_SHARING' | i18nv2 }}</div>

<div class="modal-body dialog-content" fuzzy-free>
    <div class="cdr-container">
        <div class="cdr-file-setting">
            <div class="cdr-file-list">
                <div *ngFor="let entry of entries">
                    <div class="folder-item" [ngClass]="{'invalid' : !entry.valid}">
                        <div class="folder-name" title="{{entry.path}}">{{entry.path}}</div>
                        <div class="delete-folder" (click)="deleteFolder(entry.path)"></div>
                    </div>
                </div>
            </div>
            <div class="add-share-folder">
                <div class="text-intro">
                    {{ 'ADD_A_SHARE_FOLDER' | i18nv2 }}
                </div>

                <div id="cdr-setting-add" class="modal-button-base modal-button-right modal-button-blue" [ngClass]="{'disable' : !allReady}" (click)="addShareFolderClicked()">
                    {{ 'ADD_BUTTON' | i18nv2 }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer dialog-button-row">
    <div id="cdr-setting-ok" class="modal-button-base modal-button-right modal-button-blue no-disable-hover" [ngClass]="{'disable' :!allReady}" (click)="cdrOkClicked()">
        {{ 'OK' | i18nv2 }}
    </div>
    <div id="cdr-setting-cancel" class="modal-button-base modal-button-right modal-button-blue" (click)="closeModal()">
        {{ 'CANCEL' | i18nv2 }}
    </div>
</div>