/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export const defaultKeyboardLayouts = {
   ENGLISH_UNITED_STATES: {
      layout: ["US_KEYBOARD"]
   },

   CHINESE_SIMPLIFIED: {
      layout: ["US_KEYBOARD"]
   },
   CHINESE_TRADITIONAL: {
      layout: ["US_KEYBOARD"]
   },
   JAPANESE: { layout: ["JAPANESE_KEYBOARD", "US_KEYBOARD"] },
   KOREAN: { layout: ["KOREAN_KEYBOARD", "US_KEYBOARD"] },
   FRENCH_FRANCE: {
      layout: ["FRENCH_KEYBOARD", "US_KEYBOARD"]
   },
   GERMAN_GERMANY: { layout: ["GERMAN_KEYBOARD", "US_KEYBOARD"] },
   SPANISH_SPAIN: { layout: ["SPANISH_KEYBOARD", "US_KEYBOARD"] }
};

export const keyMappingForSpecificLanguage = {
   US_KEYBOARD: [
      {
         fromShotcut: "Num Pad Equals(=)",
         toShortcut: "=",
         originShortcut: "NumpadEqual",
         mappingShortcut: "Equal"
      }
   ],
   GERMAN_KEYBOARD: [
      {
         fromShotcut: "Option+5",
         toShortcut: "Right Alt (AltGr)+8",
         originShortcut: "AltLeft,Digit5",
         mappingShortcut: "ControlLeft,AltRight,Digit8"
      },
      {
         fromShotcut: "Option+6",
         toShortcut: "Right Alt (AltGr)+9",
         originShortcut: "AltLeft,Digit6",
         mappingShortcut: "ControlLeft,AltRight,Digit9"
      },
      {
         fromShotcut: "Option+7",
         toShortcut: "Right Alt (AltGr)+<",
         originShortcut: "AltLeft,Digit7",
         mappingShortcut: "ControlLeft,AltRight,IntlBackslash"
      },
      {
         fromShotcut: "Option+8",
         toShortcut: "Right Alt (AltGr)+7",
         originShortcut: "AltLeft,Digit8",
         mappingShortcut: "ControlLeft,AltRight,Digit7"
      },
      {
         fromShotcut: "Option+9",
         toShortcut: "Right Alt (AltGr)+0",
         originShortcut: "AltLeft,Digit9",
         mappingShortcut: "ControlLeft,AltRight,Digit0"
      },

      {
         fromShotcut: "Option+L",
         toShortcut: "Right Alt (AltGr)+Q",
         originShortcut: "AltLeft,KeyL",
         mappingShortcut: "ControlLeft,AltRight,KeyQ"
      },
      {
         fromShotcut: "Option+N",
         toShortcut: "Right Alt (AltGr)++",
         originShortcut: "AltLeft,KeyN",
         mappingShortcut: "ControlLeft,AltRight,BracketRight"
      },
      {
         fromShotcut: "Shift+Option+7",
         toShortcut: "Right Alt (AltGr)+ß",
         originShortcut: "ShiftLeft,AltLeft,Digit7",
         mappingShortcut: "ControlLeft,AltRight,Minus"
      },
      {
         fromShotcut: "Num Pad Equals(=)",
         toShortcut: "Shift+0",
         originShortcut: "NumpadEqual",
         mappingShortcut: "ShiftLeft,Digit0"
      }
   ],
   SPANISH_KEYBOARD: [
      {
         fromShotcut: "Option+º",
         toShortcut: "Rigth Alt (AltGr)+º",
         originShortcut: "AltLeft,Backquote",
         mappingShortcut: "ControlLeft,AltRight,Backquote"
      },
      {
         fromShotcut: "Option+1",
         toShortcut: "Rigth Alt (AltGr)+1",
         originShortcut: "AltLeft,Digit1",
         mappingShortcut: "ControlLeft,AltRight,Digit1"
      },
      {
         fromShotcut: "Option+2",
         toShortcut: "Rigth Alt (AltGr)+2",
         originShortcut: "AltLeft,Digit2",
         mappingShortcut: "ControlLeft,AltRight,Digit2"
      },
      {
         fromShotcut: "Option+3",
         toShortcut: "Rigth Alt (AltGr)+3",
         originShortcut: "AltLeft,Digit3",
         mappingShortcut: "ControlLeft,AltRight,Digit3"
      },
      {
         fromShotcut: "Option+4",
         toShortcut: "Rigth Alt (AltGr)+5",
         originShortcut: "AltLeft,Digit4",
         mappingShortcut: "ControlLeft,AltRight,Digit5"
      },
      {
         fromShotcut: "Option+6",
         toShortcut: "Rigth Alt (AltGr)+6",
         originShortcut: "AltLeft,Digit6",
         mappingShortcut: "ControlLeft,AltRight,Digit6"
      },
      {
         fromShotcut: "Option+7",
         toShortcut: "Shift+2",
         originShortcut: "AltLeft,Digit7",
         mappingShortcut: "ShiftLeft,Digit2"
      },
      {
         fromShotcut: "Option+`",
         toShortcut: "Rigth Alt (AltGr)+`",
         originShortcut: "AltLeft,BracketLeft",
         mappingShortcut: "ControlLeft,AltRight,BracketLeft"
      },
      {
         fromShotcut: "Option++",
         toShortcut: "Rigth Alt (AltGr)++",
         originShortcut: "AltLeft,BracketRight",
         mappingShortcut: "ControlLeft,AltRight,BracketRight"
      },
      {
         fromShotcut: "Option+´",
         toShortcut: "Rigth Alt (AltGr)+´",
         originShortcut: "AltLeft,Quote",
         mappingShortcut: "ControlLeft,AltRight,Quote"
      },
      {
         fromShotcut: "Option+ç",
         toShortcut: "Rigth Alt (AltGr)+ç",
         originShortcut: "AltLeft,Backslash",
         mappingShortcut: "ControlLeft,AltRight,Backslash"
      },
      {
         fromShotcut: "Num Pad Equals(=)",
         toShortcut: "Shift+0",
         originShortcut: "NumpadEqual",
         mappingShortcut: "ShiftLeft,Digit0"
      },
      {
         fromShotcut: "Num Pad Delete(,)",
         toShortcut: ",",
         originShortcut: "NumpadDecimal",
         mappingShortcut: "Comma"
      }
   ],
   FRENCH_KEYBOARD: [
      {
         fromShotcut: "§",
         toShortcut: "Shift+=",
         originShortcut: "Digit6",
         mappingShortcut: "ShiftLeft,Slash"
      },
      {
         fromShotcut: "!",
         toShortcut: "=",
         originShortcut: "Digit8",
         mappingShortcut: "Slash"
      },
      {
         fromShotcut: "-",
         toShortcut: "§",
         originShortcut: "Equal",
         mappingShortcut: "Digit6"
      },
      {
         fromShotcut: "`",
         toShortcut: "Rigth Alt (AltGr)+È",
         originShortcut: "Backslash",
         mappingShortcut: "ControlLeft,AltRight,Digit7"
      },
      {
         fromShotcut: "=",
         toShortcut: "-",
         originShortcut: "Slash",
         mappingShortcut: "Equal"
      },
      {
         fromShotcut: "@",
         toShortcut: "Rigth Alt (AltGr)+À",
         originShortcut: "Backquote",
         mappingShortcut: "ControlLeft,AltRight,Digit0"
      },
      {
         fromShotcut: "Shift+@",
         toShortcut: 'Rigth Alt (AltGr)+"',
         originShortcut: "ShiftLeft,Backquote",
         mappingShortcut: "ControlLeft,AltRight,Digit3"
      },
      {
         fromShotcut: "Shift+-",
         toShortcut: "!",
         originShortcut: "ShiftLeft,Equal",
         mappingShortcut: "Digit8"
      },
      {
         fromShotcut: "Shift+$",
         toShortcut: "`",
         originShortcut: "ShiftLeft,BracketRight",
         mappingShortcut: "Backslash"
      },
      {
         fromShotcut: "Shift+=",
         toShortcut: "Shit+-",
         originShortcut: "ShiftLeft,Slash",
         mappingShortcut: "ShiftLeft,Equal"
      },
      {
         fromShotcut: "Shift+`",
         toShortcut: "Shift+$",
         originShortcut: "ShiftLeft,Backslash",
         mappingShortcut: "ShiftLeft,BracketRight"
      },
      {
         fromShotcut: "Option+$",
         toShortcut: "Rigth Alt (AltGr)+E",
         originShortcut: "AltLeft,BracketRight",
         mappingShortcut: "ControlLeft,AltRight,KeyE"
      },
      {
         fromShotcut: "Option+(",
         toShortcut: "Rigth Alt (AltGr)+'",
         originShortcut: "AltLeft,Digit5",
         mappingShortcut: "ControlLeft,AltRight,Digit4"
      },
      {
         fromShotcut: "Option+)",
         toShortcut: "Rigth Alt (AltGr)+-",
         originShortcut: "AltLeft,Minus",
         mappingShortcut: "ControlLeft,AltRight,Equal"
      },
      {
         fromShotcut: "Option+`",
         toShortcut: "Rigth Alt (AltGr)+À",
         originShortcut: "AltLeft,Backslash",
         mappingShortcut: "ControlLeft,AltRight,Digit0"
      },
      {
         fromShotcut: "Num Pad Equals(=)",
         toShortcut: "-",
         originShortcut: "NumpadEqual",
         mappingShortcut: "Equal"
      },
      {
         fromShotcut: "Num Pad Delete(,)",
         toShortcut: ",",
         originShortcut: "NumpadDecimal",
         mappingShortcut: "KeyM"
      }
   ],
   AltGr_GERMAN: [
      {
         fromShotcut: "AltGr+2",
         toShortcut: "²",
         originShortcut: "ControlLeft,AltRight,Digit2",
         mappingShortcut: "AltRight,Digit2",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+3",
         toShortcut: "³",
         originShortcut: "ControlLeft,AltRight,Digit3",
         mappingShortcut: "AltRight,Digit3",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+7",
         toShortcut: "{",
         originShortcut: "ControlLeft,AltRight,Digit7",
         mappingShortcut: "AltRight,Digit7",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+8",
         toShortcut: "[",
         originShortcut: "ControlLeft,AltRight,Digit8",
         mappingShortcut: "AltRight,Digit8",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+9",
         toShortcut: "]",
         originShortcut: "ControlLeft,AltRight,Digit9",
         mappingShortcut: "AltRight,Digit9",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+0",
         toShortcut: "}",
         originShortcut: "ControlLeft,AltRight,Digit0",
         mappingShortcut: "AltRight,Digit0",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+ß",
         toShortcut: "\\",
         originShortcut: "ControlLeft,AltRight,Minus",
         mappingShortcut: "AltRight,Minus",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+Q",
         toShortcut: "@",
         originShortcut: "ControlLeft,AltRight,KeyQ",
         mappingShortcut: "AltRight,KeyQ",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+E",
         toShortcut: "€",
         originShortcut: "ControlLeft,AltRight,KeyE",
         mappingShortcut: "AltRight,KeyE",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr++",
         toShortcut: "~",
         originShortcut: "ControlLeft,AltRight,BracketRight",
         mappingShortcut: "AltRight,BracketRight",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+<",
         toShortcut: "|",
         originShortcut: "ControlLeft,AltRight,IntlBackslash",
         mappingShortcut: "AltRight,IntlBackslash",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+M",
         toShortcut: "µ",
         originShortcut: "ControlLeft,AltRight,KeyM",
         mappingShortcut: "AltRight,KeyM",
         platform: "Windows"
      }
   ],
   AltGr_SPANISH: [
      {
         fromShotcut: "AltGr+º",
         toShortcut: "\\",
         originShortcut: "ControlLeft,AltRight,Backquote",
         mappingShortcut: "AltRight,Backquote",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+1",
         toShortcut: "|",
         originShortcut: "ControlLeft,AltRight,Digit1",
         mappingShortcut: "AltRight,Digit1",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+2",
         toShortcut: "@",
         originShortcut: "ControlLeft,AltRight,Digit2",
         mappingShortcut: "AltRight,Digit2",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+3",
         toShortcut: "#",
         originShortcut: "ControlLeft,AltRight,Digit3",
         mappingShortcut: "AltRight,Digit3",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+4",
         toShortcut: "~",
         originShortcut: "ControlLeft,AltRight,Digit4",
         mappingShortcut: "AltRight,Digit4",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+5",
         toShortcut: "€",
         originShortcut: "ControlLeft,AltRight,Digit5",
         mappingShortcut: "AltRight,KeyE",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+6",
         toShortcut: "¬",
         originShortcut: "ControlLeft,AltRight,Digit6",
         mappingShortcut: "AltRight,Digit6",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+E",
         toShortcut: "€",
         originShortcut: "ControlLeft,AltRight,KeyE",
         mappingShortcut: "AltRight,KeyE",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+`",
         toShortcut: "[",
         originShortcut: "ControlLeft,AltRight,BracketLeft",
         mappingShortcut: "AltRight,BracketLeft",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr++",
         toShortcut: "]",
         originShortcut: "ControlLeft,AltRight,BracketRight",
         mappingShortcut: "AltRight,BracketRight",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+´",
         toShortcut: "{",
         originShortcut: "ControlLeft,AltRight,Quote",
         mappingShortcut: "AltRight,Quote",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+ç",
         toShortcut: "}",
         originShortcut: "ControlLeft,AltRight,Backslash",
         mappingShortcut: "AltRight,Backslash",
         platform: "Windows"
      }
   ],
   AltGr_FRENCH: [
      {
         fromShotcut: "AltGr+é",
         toShortcut: "~",
         originShortcut: "ControlLeft,AltRight,Digit2",
         mappingShortcut: "AltRight,Digit2",
         platform: "Windows"
      },
      {
         fromShotcut: 'AltGr+"',
         toShortcut: "#",
         originShortcut: "ControlLeft,AltRight,Digit3",
         mappingShortcut: "AltRight,Digit3",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+'",
         toShortcut: "{",
         originShortcut: "ControlLeft,AltRight,Digit4",
         mappingShortcut: "AltRight,Digit4",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+(",
         toShortcut: "[",
         originShortcut: "ControlLeft,AltRight,Digit5",
         mappingShortcut: "AltRight,Digit5",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+-",
         toShortcut: "|",
         originShortcut: "ControlLeft,AltRight,Digit6",
         mappingShortcut: "AltRight,Digit6",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+è",
         toShortcut: "`",
         originShortcut: "ControlLeft,AltRight,Digit7",
         mappingShortcut: "AltRight,Digit7",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+_",
         toShortcut: "\\",
         originShortcut: "ControlLeft,AltRight,Digit8",
         mappingShortcut: "AltRight,Digit8",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+ç",
         toShortcut: "^",
         originShortcut: "ControlLeft,AltRight,Digit9",
         mappingShortcut: "AltRight,Digit9",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+à",
         toShortcut: "@",
         originShortcut: "ControlLeft,AltRight,Digit0",
         mappingShortcut: "AltRight,Digit0",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+)",
         toShortcut: "]",
         originShortcut: "ControlLeft,AltRight,Minus",
         mappingShortcut: "AltRight,Minus",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+=",
         toShortcut: "}",
         originShortcut: "ControlLeft,AltRight,Equal",
         mappingShortcut: "AltRight,Equal",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+$",
         toShortcut: "¤",
         originShortcut: "ControlLeft,AltRight,BracketRight",
         mappingShortcut: "AltRight,BracketRight",
         platform: "Windows"
      },
      {
         fromShotcut: "AltGr+e",
         toShortcut: "€",
         originShortcut: "ControlLeft,AltRight,KeyE",
         mappingShortcut: "AltRight,KeyE",
         platform: "Windows"
      }
   ]
};

export const keyMappingsForLayout = {
   FRENCH_FRANCE: {
      FRENCH_KEYBOARD: ["FRENCH_KEYBOARD", "AltGr_FRENCH"],
      US_KEYBOARD: ["US_KEYBOARD"]
   },
   GERMAN_GERMANY: {
      GERMAN_KEYBOARD: ["GERMAN_KEYBOARD", "AltGr_GERMAN"],
      US_KEYBOARD: ["US_KEYBOARD"]
   },
   SPANISH_SPAIN: {
      SPANISH_KEYBOARD: ["SPANISH_KEYBOARD", "AltGr_SPANISH"],
      US_KEYBOARD: ["US_KEYBOARD"]
   },
   JAPANESE: {
      JAPANESE_KEYBOARD: ["US_KEYBOARD"],
      US_KEYBOARD: ["US_KEYBOARD"]
   },
   CHINESE_SIMPLIFIED: {
      US_KEYBOARD: ["US_KEYBOARD"]
   },
   CHINESE_TRADITIONAL: {
      US_KEYBOARD: ["US_KEYBOARD"]
   },
   KOREAN: {
      KOREAN_KEYBOARD: ["US_KEYBOARD"],
      US_KEYBOARD: ["US_KEYBOARD"]
   },
   ENGLISH_UNITED_STATES: {
      US_KEYBOARD: ["US_KEYBOARD"]
   }
};
