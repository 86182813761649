/**
 * ******************************************************
 * Copyright (C) 2014-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * idleTimeoutTimerController.js --
 *
 * timer and logic for idle timeout.
 *
 */

import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { Timer, timerTypeEnum } from "./timer";
import SetLastUserActivityHandler from "../controllers/setLastUserActivityHandler";
import UserActiveTimeSyncCtrl from "./userActiveTimeSyncCtrl";
import DoLockHandler from "../controllers/doLockHandler";
import { requestLastActiveTimeFromPortal, requestLastActiveTimeFromRemoteSession } from "./timer-util";
import { RequestLastUserActiveTimeAction, ShowAboutToTimeoutDialogAction } from "../jscdk-interface";

export default function IdleTimeoutTimerController() {
   if (this.brokerVersionInvalid()) {
      return;
   }
   Logger.debug("IdleTimeoutTimerController created");
   this.timeSyncBuff = { ui: null, broker: null, local: null };
   this.globalName = "idle-timeout-timer";
   this.idleTimeout = -1;
   this.aboutToIdleTimeout = -1;
   this.userActivityInterval = -1;
   this.hintTime = -1; //hint = idleTimeout - aboutToIdleTimeout
   this.intervalTimer = new Timer(timerTypeEnum.interval);
   this.aboutToTimeoutTimer = new Timer(timerTypeEnum.timeout);
   this.timeoutTimer = new Timer(timerTypeEnum.timeout);
   this.needDisplayDialogs = null;
   this.notifyLockEventToUI = true;
   this.requestLastActiveTimeFunction = null;
}

// only store functions
IdleTimeoutTimerController.prototype = {};
IdleTimeoutTimerController.constructor = IdleTimeoutTimerController;

(function () {
   //private functions:
   //----------------------------------------------------------------------------------------------
   function userActivityEvent(params) {
      const self = params[0];

      if (typeof self.requestLastActiveTimeFunction === "function") {
         self.requestLastActiveTimeFunction();
      } else {
         Logger.debug("requestLastActiveTimeFunction is not a function");
      }
   }

   function aboutToIdleTimeoutEvent() {
      const requestLastUserActiveTimeAction = {} as RequestLastUserActiveTimeAction;

      Logger.debug("aboutToIdleTimeout event reached");
      requestLastUserActiveTimeAction.name = "AboutToTimeout";
      JSCDKSetUI(JSON.stringify(requestLastUserActiveTimeAction));
   }

   function idleTimeoutEvent() {
      const requestLastUserActiveTimeAction = {} as RequestLastUserActiveTimeAction;

      Logger.debug("idleTimeout event reached");
      requestLastUserActiveTimeAction.name = "Timeout";
      JSCDKSetUI(JSON.stringify(requestLastUserActiveTimeAction));
   }

   function initCore(initType) {
      /*jshint valid this: true*/
      let idleTimeout;

      switch (initType) {
         // work on portal page for both webClient and chromeClient
         case "Portal":
            this.requestLastActiveTimeFunction = requestLastActiveTimeFromPortal;
            this.needDisplayDialogs = false;
            break;
         // work on desktop page for webClient
         case "Desktop":
            this.requestLastActiveTimeFunction = requestLastActiveTimeFromRemoteSession;
            this.needDisplayDialogs = true;
            break;
         default:
            Logger.debug("error: the initType is invalid");
            return;
      }

      // set timers enable property
      idleTimeout = this.idleTimeout;
      this.intervalTimer.setEnable();
      if (idleTimeout <= 0) {
         this.aboutToTimeoutTimer.setDisable();
         this.timeoutTimer.setDisable();
         this.idleTimeout = -1; // -1 means invalid
         this.aboutToIdleTimeout = -1; // -1 means invalid
      } else {
         this.timeoutTimer.setEnable();
         if (this.hintTime >= idleTimeout) {
            Logger.error("invalid time setting params for IdleTimeoutTimerController.init()!!");
            this.aboutToTimeoutTimer.setDisable();
            this.aboutToIdleTimeout = -1; // -1 means invalid
         } else {
            this.aboutToTimeoutTimer.setEnable();
         }
      }

      //init timer and bounding callback
      this.intervalTimer.init(userActivityEvent, this.userActivityInterval * 1000, [this]);
      if (this.needDisplayDialogs) {
         this.aboutToTimeoutTimer.init(aboutToIdleTimeoutEvent, this.aboutToIdleTimeout * 1000);
      } else {
         this.aboutToTimeoutTimer.setDisable();
      }
      this.timeoutTimer.init(idleTimeoutEvent, this.idleTimeout * 1000);
      Logger.debug("idle timeout controller inited");
   }

   function sendSetLastUserActivityXMLtoBrokerWith(inactiveTime) {
      let setLastUserActivityObject,
         handlerList,
         router = globalArray["router"],
         inactiveTimeVar,
         inactiveTimeStr;

      setLastUserActivityObject = globalArray["set-last-user-activity"];
      if (!setLastUserActivityObject) {
         setLastUserActivityObject = new SetLastUserActivityHandler();
         globalArray[setLastUserActivityObject.messageName] = setLastUserActivityObject;
         globalArray[setLastUserActivityObject.responseTag] = setLastUserActivityObject;
      } else {
         setLastUserActivityObject.resetData();
      }

      if (!!setLastUserActivityObject && !!router) {
         inactiveTimeVar = inactiveTime; //accept both number and string format
         inactiveTimeStr = inactiveTimeVar.toString();
         setLastUserActivityObject.setRequestXML(inactiveTimeStr);
         handlerList = setLastUserActivityObject.composeHandlerList();
         router.postMessage(handlerList, true, undefined, true);
      }
   }

   function sendDoLockXML() {
      let doLockObject,
         handlerList,
         router = globalArray["router"];

      doLockObject = globalArray["do-lock"];
      if (!doLockObject) {
         doLockObject = new DoLockHandler();
         globalArray[doLockObject.messageName] = doLockObject;
         globalArray[doLockObject.responseTag] = doLockObject;
      } else {
         doLockObject.resetData();
      }

      if (!!doLockObject && !!router) {
         doLockObject.setRequestXML();
         handlerList = doLockObject.composeHandlerList();
         router.postMessage(handlerList);
      }

      const lockingAction = { name: "Locking" };
      JSCDKSetUI(JSON.stringify(lockingAction));
   }

   //called in the this.compareToAct
   function setLocaleTimeForCompare() {
      /*jshint validthis: true*/
      const localTargetTime = this.timeoutTimer.getTargetTime();
      this.timeSyncBuff.local = localTargetTime - this.idleTimeout * 1000;
   }

   // public functions:
   //----------------------------------------------------------------------------------------------
   // class method to verify the version validation(using
   // IdleTimeoutTimerController as a namespace)
   IdleTimeoutTimerController.prototype.init = function (userActivityInterval, idleTimeout, initType) {
      if (this.brokerVersionInvalid()) {
         return;
      }
      Logger.debug(
         "init timer controller with userActivityInterval:" + userActivityInterval + ", idleTimeout:" + idleTimeout
      );

      // this value should be changed, in seconds
      this.hintTime = 30;

      //copy a set of time value
      this.idleTimeout = idleTimeout; // in s
      this.userActivityInterval = userActivityInterval; // in s
      this.aboutToIdleTimeout = this.idleTimeout - this.hintTime; // in s

      initCore.call(this, initType);
   };

   IdleTimeoutTimerController.prototype.reset = function (initType) {
      if (this.brokerVersionInvalid()) {
         return;
      }
      Logger.debug("reset timer controller for chrome: with type: " + initType);

      initCore.call(this, initType);
   };

   IdleTimeoutTimerController.prototype.brokerVersionInvalid = function () {
      if (!util.brokerSupportApplication()) {
         Logger.error("timerController related functions should only be involved when is broker version is new enough");
         return true;
      }
      return false;
   };

   IdleTimeoutTimerController.prototype.ensureUpdatedOrStarted = function () {
      this.intervalTimer.ensureUpdatedOrStarted();
      sendSetLastUserActivityXMLtoBrokerWith(0);
      this.aboutToTimeoutTimer.ensureUpdatedOrStarted();
      this.timeoutTimer.ensureUpdatedOrStarted();
      Logger.info("----all timer are ensureUpdatedOrStarted");
   };

   IdleTimeoutTimerController.prototype.start = function () {
      this.intervalTimer.start();
      sendSetLastUserActivityXMLtoBrokerWith(0);
      this.aboutToTimeoutTimer.start();
      this.timeoutTimer.start();
      Logger.info("----all timer started");
   };

   IdleTimeoutTimerController.prototype.stop = function () {
      //stop controller by discarding all timers
      this.intervalTimer.discard();
      this.aboutToTimeoutTimer.discard();
      this.timeoutTimer.discard();
      Logger.info("----all timer discarded");
   };

   IdleTimeoutTimerController.prototype.refresh = function (inactiveTime) {
      this.aboutToTimeoutTimer.updateTimeoutValue(this.aboutToIdleTimeout * 1000 - inactiveTime);
      this.timeoutTimer.updateTimeoutValue(this.idleTimeout * 1000 - inactiveTime);
      Logger.info("----all timer refreshed with passed time:" + inactiveTime);
   };

   IdleTimeoutTimerController.prototype.ensureStopped = function () {
      this.intervalTimer.ensureStopped();
      this.aboutToTimeoutTimer.ensureStopped();
      this.timeoutTimer.ensureStopped();
      Logger.debug("all timers is ensure to be stopped");
   };

   IdleTimeoutTimerController.prototype.getIdleTimeoutValue = function () {
      Logger.debug("in getIdleTimeoutValue return:" + this.idleTimeout);
      return this.idleTimeout;
   };

   IdleTimeoutTimerController.prototype.getSendIntervalValue = function () {
      Logger.debug("in getSendIntervalValue return:" + this.userActivityInterval);
      return this.userActivityInterval;
   };

   IdleTimeoutTimerController.prototype.setLastUserActiveTime = function (
      lastUserActiveTime,
      needSendXML,
      needRefreshTimer
   ) {
      let inactiveTime, currentTime;

      currentTime = new Date().getTime();
      inactiveTime = currentTime - lastUserActiveTime; //in ms
      Logger.debug("the lastUserActiveTime that passed in setLastUserActiveTime is: " + inactiveTime + "ms");
      if (needRefreshTimer) {
         this.refresh(inactiveTime);
      }
      if (needSendXML) {
         sendSetLastUserActivityXMLtoBrokerWith(parseInt((inactiveTime / 1000).toString(), 10));
      }
   };

   IdleTimeoutTimerController.prototype.setUITimeForCompare = function (UIlastUserActiveTime) {
      this.timeSyncBuff.ui = UIlastUserActiveTime;
   };

   //send getAuthStatus with actionTrigger
   IdleTimeoutTimerController.prototype.requestBrokerTimeForCompareFor = function (actionTrigger) {
      let userActiveTimeSyncCtrl = globalArray["get-broker-sso-time-ctrl"],
         callBackType = actionTrigger;
      if (!userActiveTimeSyncCtrl) {
         userActiveTimeSyncCtrl = new UserActiveTimeSyncCtrl();
         globalArray[userActiveTimeSyncCtrl.ctrlName] = userActiveTimeSyncCtrl;
      }
      userActiveTimeSyncCtrl.requestBrokerSSOTime(callBackType);
   };

   //called in the onupdate of getAuthStatusHandler
   IdleTimeoutTimerController.prototype.setBrokerTimeForCompare = function (timeToBrokerLocked) {
      const brokerAheadTime = 11, //extra time for broker is like 11s
         brokerLastUserActiveTime =
            new Date().getTime() - (this.idleTimeout - (timeToBrokerLocked - brokerAheadTime)) * 1000; //now-(timeout-rest)
      this.timeSyncBuff.broker = brokerLastUserActiveTime;
   };

   //called in the onupdate of getAuthStatusHandler
   IdleTimeoutTimerController.prototype.compareToAct = function (actionTrigger) {
      let newestTime,
         needSendLastUserActivityXML,
         timeDelayTolerance = 3000,
         showAboutToTimeoutDialogAction = {} as ShowAboutToTimeoutDialogAction;

      setLocaleTimeForCompare.apply(this);
      if (this.timeSyncBuff.ui > this.timeSyncBuff.broker) {
         newestTime = this.timeSyncBuff.ui;
         needSendLastUserActivityXML = true;
      } else {
         newestTime = this.timeSyncBuff.broker;
         needSendLastUserActivityXML = false;
      }

      //if already synced, this is a valid trigger, do actions
      if (Math.abs(newestTime - this.timeSyncBuff.local) < timeDelayTolerance) {
         if (actionTrigger === "syncWithTimer4AboutToTimeout") {
            //show about to timeout dialog
            Logger.debug("showAboutTimeOut dialog");
            showAboutToTimeoutDialogAction.name = "showAboutToTimeoutDialog";
            showAboutToTimeoutDialogAction.showTime = this.hintTime;
            JSCDKSetUI(JSON.stringify(showAboutToTimeoutDialogAction));
         } else if (actionTrigger === "syncWithTimer4Timeout") {
            //send do-lock
            Logger.debug("send do-lock");
            sendDoLockXML();
         }
      } else {
         // else sync the time first
         this.setLastUserActiveTime(newestTime, needSendLastUserActivityXML, true);
      }
      this.timeSyncBuff = { ui: null, broker: null, local: null };
   };

   IdleTimeoutTimerController.prototype.detectBrokerLocked = function () {
      let doLockAction;

      Logger.debug("detect broker is already locked");
      if (this.needDisplayDialogs) {
         doLockAction = { name: "DoLock", type: "detectBrokerLocked" };
         JSCDKSetUI(JSON.stringify(doLockAction));
      } else if (this.notifyLockEventToUI) {
         JSCDKSetUI(JSON.stringify({ name: "AlreadyLock" }));
      }
   };
})();
