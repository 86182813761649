/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * on extended monitor
 * @format
 */

import { Injectable } from "@angular/core";
import { EventBusService, Logger, BusEvent } from "@html-core";
import { ExtendedWindowUnity } from "./unity-exchange";
import { MessageService } from "../multimon/extended-page/monitor-message.service";
import { Monitor } from "../multimon/common/monitor-message";
import { UnityEventExchange } from "./unity-exchange";

@Injectable()
export class UnityMonitorConnector {
   private logger: Logger;
   constructor(
      private messageService: MessageService,
      private eventBusService: EventBusService
   ) {
      this.logger = new Logger(Logger.UNITY, null, Logger.Modules.Unity);
   }
   public init = (handler: ExtendedWindowUnity.Handler, mgr: ExtendedWindowUnity.Mgr) => {
      this.logger.info("Unity monitor connector inited on Extended monitor");
      this.messageService.responseTo(Monitor.UnityMsg.TYPE, (msg: Monitor.UnityMsg) => {
         const data = <UnityEventExchange.Message>msg.data;
         this.logger.dump("get unity message on extended monitor" + JSON.stringify(msg));
         switch (data.type) {
            case UnityEventExchange.MessageType.onWindowAdded: {
               const payload: UnityEventExchange.WindowAddedMsg = data.payload;
               handler.onAdd(payload.windowId, payload.execPath, payload.windowPath);
               break;
            }
            case UnityEventExchange.MessageType.onWindowRemoved: {
               const payload: UnityEventExchange.WindowRemovedMsg = data.payload;
               handler.onRemove(payload.windowId);
               break;
            }
            case UnityEventExchange.MessageType.onRegionUpdate: {
               const payload: UnityEventExchange.RegionUpdateMsg = data.payload;
               handler.onRegionUpdate(payload.windowId, payload.regions);
               break;
            }
            case UnityEventExchange.MessageType.onUpdateComplete: {
               const payload: UnityEventExchange.UpdateCompleteMsg = data.payload;
               handler.onUpdateComplete();
               break;
            }
            case UnityEventExchange.MessageType.onWindowMoved: {
               const payload: UnityEventExchange.WindowMovedMsg = data.payload;
               handler.onWindowMoved(payload.windowId, payload.rect);
               break;
            }
            case UnityEventExchange.MessageType.onReadyChanged: {
               const payload: UnityEventExchange.ReadyChangedMsg = data.payload;
               handler.onReadyChanged(payload.ready, payload.isOn, payload.paused);
               break;
            }
            case UnityEventExchange.MessageType.onVisibilityChanged: {
               const payload: UnityEventExchange.VisibilityChangedMsg = data.payload;
               handler.onVisibilityChanged(payload.show);
               break;
            }
            case UnityEventExchange.MessageType.onTitleBarAreaChanged: {
               const payload: UnityEventExchange.TitleBarAreaUpdateMsg = data.payload;
               handler.onTitleBarAreaChanged(payload.windowId, payload.windowPositionInfo);
               break;
            }
            case UnityEventExchange.MessageType.onWindowAttrChanged: {
               const payload: UnityEventExchange.WindowAttrChangedMsg = data.payload;
               handler.onAttrChanged(payload.windowId, payload.type, payload.value);
               break;
            }
            case UnityEventExchange.MessageType.onZorderUpdated: {
               const payload: UnityEventExchange.ZorderUpdatedMsg = data.payload;
               handler.onZorderUpdate(payload.components);
               break;
            }
            case UnityEventExchange.MessageType.onWindowSync: {
               handler.onWindowSync(data.payload.wmksKey, data.payload.windows, data.payload.zOrders);
               break;
            }
            default:
               Logger.error("unknown unity message type of " + data.type);
         }
      });

      this.eventBusService
         .listen(BusEvent.UnityClientRequest.MSG_TYPE)
         .subscribe((msg: BusEvent.UnityClientRequest) => {
            Logger.info("sending unity request from Extended monitor");
            const crossMonitorMessage = new Monitor.UnityMsg(msg.data);
            this.messageService.sendMessage(crossMonitorMessage);
         });
   };
}
