/**
 * *****************************************************
 * Copyright 2023 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { ENTITLE_TYPE, ICON_TYPE, TranslateService, clientUtil } from "@html-core";
import { DESKTOP_OPS, LAUNCH_TYPE, XmlApiService } from "../../desktop/sidebar/xml-api.service";
import { IdleSessionService } from "./idle-session.service";
import { BaseViewService } from "./base-view.service";
import JSCDKBrokerError from "../../jscdk/model/jscdk-broker-error.consts";
import { PROTOCOL_MISMATCH_ERROR_CODE, PROTOCOL_ERROR } from "../../desktop/sidebar/sidebar-constant";
import { SessionUtil } from "./session-util";
import { XMLPreference } from "./prefdata";
import { WmksService } from "../../../shared/desktop/common/wmks.service";
import { HtmlRemoteSessionManager } from "../../../html5-client/common/remote-session/html-remote-session-manager";
import { JscdkWrapper } from "../jscdk/jscdk-wrapper";
import { LaunchService } from "../../launcher/launchitems/session-launch.service";
import { ModalDialogService } from "../commondialog/dialog.service";
import { BusEvent, EventBusService } from "../../../core/services/event";
import Logger from "../../../core/libs/logger";
import { ConnectionRetryService } from "./connection-retry.service";
import { RootModel } from "../model/root-model";
import util from "../../jscdk/util";

@Injectable({
   providedIn: "root"
})
export class ConnectToItemService {
   // Whether the Not Authenticated Dialog is open or not
   private notAuthenticatedDialogOpen: boolean = false;
   private serverNotRespondingDialogId = null;
   private timeoutID = null;
   constructor(
      private translate: TranslateService,
      private eventBusService: EventBusService,
      private modalDialogService: ModalDialogService,
      private xmlApiService: XmlApiService,
      private idleSessionService: IdleSessionService,
      private baseViewService: BaseViewService,
      private sessionUtil: SessionUtil,
      private htmlRemoteSessionManager: HtmlRemoteSessionManager,
      private wmksService: WmksService,
      public jscdkWrapper: JscdkWrapper,
      private launchService: LaunchService,
      private connectionRetryService: ConnectionRetryService,
      private rootModel: RootModel
   ) {}

   public init = () => {
      this.eventBusService.listen("UpdateAzureWaitingUI").subscribe((msg) => {
         // Remove busy cursor.
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
         // ignore the case of dialog exist then enable connection retry
         // use jscdkWrapper instead of invoker since it's a wrapper with same API
         this.connectionRetryService.showDialog(msg.data.waitMinutes, msg.data.type, this.jscdkWrapper);
      });

      this.eventBusService.listen(BusEvent.ConnectItemMsg.MSG_TYPE).subscribe((msg: BusEvent.ConnectItemMsg) => {
         this.launchItem(msg.item);
      });
   };

   public launchItem = (item: EntitlementItem) => {
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
      if (item.type === ENTITLE_TYPE.APPLICATION) {
         // Launch application.
         this.connectionToItem(item, LAUNCH_TYPE.APPLICATION);
      } else {
         Logger.info("Desktop " + item.name + " has been selected.");
         this.connectionToItem(item, LAUNCH_TYPE.DESKTOP);
      }
   };

   /**
    * _notAuthenticatedCallback
    *
    * Callback to handle not authenticated error. Displays a dialog and
    * then moves the user back to the login page.
    *
    * @param keepConnected: flags whether we should keep the broker
    *    connection, we keep the broker connection on idle disconnect
    * @param errorMsg the human readable message. If this is undefined a
    *    default message will be used.
    */
   private _notAuthenticatedCallback = (event, params) => {
      let callback = () => {
            // Requesting a disconnect should either close this window,
            // or take it back to the login page. But in case it doesn't
            // we want to be able to open another dialog in case a not
            // authenticated error comes from somewhere else
            this.notAuthenticatedDialogOpen = false;
            this.idleSessionService.suppressMsg(false);

            if (params && params.keepConnected) {
               this.baseViewService.gotoPortal();
            } else {
               if (!clientUtil.isChromeClient()) {
                  this.wmksService.closeAllSessions();
               }
               this.eventBusService.dispatch(new BusEvent.LogOutMsg(true));
            }
         },
         errorMsg = this.translate._T("NOT_AUTHENTICATED_M");

      // No need to bring up another dialog if one is opened
      if (this.notAuthenticatedDialogOpen) {
         return;
      }

      this.notAuthenticatedDialogOpen = true;
      this.idleSessionService.suppressMsg(true);

      if (params && params.errorMsg) {
         errorMsg = params.errorMsg;
      }

      this.modalDialogService.showError({
         data: {
            title: this.translate._T("ERROR"),
            content: errorMsg
         },
         callbacks: {
            confirm: callback
         }
      });
   };

   private isProtocolMismatchError = (response) => {
      return (
         response.error &&
         ((response.error.errorType === JSCDKBrokerError.JSCDK_BROKER_ERROR_DESKTOP_LAUNCH_ERROR &&
            response.error.userMsg.indexOf(PROTOCOL_ERROR) > -1) ||
            response.error.errorType === PROTOCOL_MISMATCH_ERROR_CODE)
      );
   };

   private connectionToItem = (item: EntitlementItem, type: LAUNCH_TYPE) => {
      if (!clientUtil.isTitanClient()) {
         this.timeoutHandler();
      }
      this.xmlApiService
         .checkAuthStatus()
         .then(() => {
            return this.xmlApiService.getUserGlobalPref();
         })
         .then((prefData: XMLPreference) => {
            this.wmksService.setPreference(prefData);
            return this.xmlApiService.launchItem(item, type);
         })
         .then((response) => {
            if (!response) {
               return;
            }
            if (response.success && !!response.blastURL) {
               return this.generateBlastConnectionInfo(type, item, response);
            } else {
               //close the server not response dialog here.
               if (this.serverNotRespondingDialogId) {
                  this.modalDialogService.close(this.serverNotRespondingDialogId);
               }
               if (typeof response === "object" && !response.success) {
                  if (this.isProtocolMismatchError(response)) {
                     /*
                      * If we start an RDP connection to a RDSH agent,
                      * then try to steal the session from HTML Access,
                      * we get an error saying "you have an existing session
                      * that cannot be reconnected to with the specified protocol.
                      * If the desktop need to logoff first, show error dialog first
                      * then show logoff confirmation dialog, bug 3013667
                      */
                     this.modalDialogService.showError({
                        data: {
                           title: this.translate._T("ERROR"),
                           content: response.error.userMsg
                        },
                        callbacks: {
                           confirm: () => {
                              Logger.info("Log off Desktop: " + item.name);
                              this.xmlApiService.handleSessionOps(DESKTOP_OPS.LOGOFF, item);
                           }
                        }
                     });
                  } else if (
                     response.error &&
                     response.error.errorType !== JSCDKBrokerError.JSCDK_BROKER_ERROR_NOT_AUTHENTICATED
                  ) {
                     this.modalDialogService.showError({
                        data: {
                           title: this.translate._T("ERROR"),
                           content: response.error.userMsg
                        }
                     });
                  } else {
                     this._notAuthenticatedCallback(null, {
                        errorMsg: response.error.userMsg
                     });
                  }
               } else {
                  if (item.type === ENTITLE_TYPE.DESKTOP) {
                     item.canLogoff = true;
                  }
                  if (clientUtil.isChromeClient()) {
                     this.eventBusService.dispatch(new BusEvent.PanelEventMsg(true));
                  }
               }
            }
         })
         .then((info: BlastConnectInfo) => {
            if (info) {
               if (clientUtil.isChromeClient()) {
                  info.isWindows365 = false;
                  this.launchService.onLaunchDataReady(
                     {
                        controller: null,
                        launchData: info
                     },
                     this.jscdkWrapper
                  );
               } else {
                  //@ts-ignore
                  info.isWindows365 = item.iconType === ICON_TYPE.W365;
                  this.wmksService.connectToBlast(info);
               }
               this.htmlRemoteSessionManager.activeSession(info.key, true);
               if (info.isApplicationSession) {
                  this.eventBusService.dispatch(new BusEvent.ReconnectAllSessionsMsg(info.isMultiSession, info.key));
               }
            }
            this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
            // if the item is available, clear the timeout
            if (this.timeoutID) {
               clearTimeout(this.timeoutID);
               this.timeoutID = null;
            }
         })
         .catch((e) => {
            Logger.error(`Fail to launch item ${item.id} with error ${JSON.stringify(e)}`);
            if (this.timeoutID) {
               clearTimeout(this.timeoutID);
               this.timeoutID = null;
            }
            this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
         });
   };

   public timeoutHandler = () => {
      // User may cancel re-auth, if so don't show keep connect dialog again.
      this.eventBusService
         .listen(BusEvent.AuthenticationDeclined.MSG_TYPE)
         .subscribe((msg: BusEvent.AuthenticationDeclined) => {
            if (msg.data == "cancel") {
               clearTimeout(this.timeoutID);
               this.timeoutID = null;
            }
         });

      // When 30 seconds expire, display a warning dialog asking user
      // whether to cancel the request.
      this.timeoutID = setTimeout(() => {
         this.rootModel.set("isJSCDKResponse", false);
         // Remove busy cursor.
         this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
         this.timeoutID = null;
         // if there's no sso dialog,
         // don't show keep connect dialog again.
         if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
            return;
         }

         this.serverNotRespondingDialogId = this.modalDialogService.showConfirm({
            data: {
               titleKey: "SERVER_NOT_RESP_T",
               contentKey: "SERVER_NOT_RESP_M",
               buttonLabelConfirmKey: "OK",
               buttonLabelCancelKey: "CANCEL"
            },
            callbacks: {
               confirm: () => {
                  // Press OK btn, keep on waiting
                  if (!this.rootModel.get("isJSCDKResponse")) {
                     //if there's no error dialog, display busy cursor
                     this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
                  }
               },
               cancel: () => {
                  // Press cancel btn, cancel launching item request.
                  if (!this.rootModel.get("isJSCDKResponse")) {
                     let onDone = null;
                     const SAMLart = util.getUrlParam(window.location.search.toLowerCase(), "samlart");
                     if (SAMLart) {
                        onDone = {
                           name: "closeOnExit"
                        };
                     }
                     this.jscdkWrapper.abortRequest(onDone, false);
                  }
               }
            }
         });
      }, 30 * 1000);
   };

   private generateBlastConnectionInfo = async (
      type: LAUNCH_TYPE,
      item: EntitlementItem,
      response
   ): Promise<BlastConnectInfo> => {
      const info: BlastConnectInfo = {} as BlastConnectInfo;
      if (type === LAUNCH_TYPE.DESKTOP) {
         info.isApplicationSession = false;
         info.isMultiSession = false;
         info.name = item.name;
         info.isShadow = item.isShadow;
         info.key = item.id;
         info.enableUsb = response.enableUsb;
         info.usbTicket = response.usbTicket;
         info.sessionId = response.sessionId;
      } else {
         info.isApplicationSession = true;
         info.isMultiSession = await this.sessionUtil.isMultiSession(item.id);
         info.name = item.originId;
         info.isShadow = item.isShadow;
         info.sessionId = response.sessionId;
         info.key = SessionUtil.getSessionKey(info.isMultiSession, info.name, response.sessionId);
      }
      info.targetUrl = response.blastURL;
      info.reconnectToken = undefined;
      info.triedSSLVerify = undefined;

      if (clientUtil.isChromeClient()) {
         info.preferences = response.preferences;
         info.triggerItemInfo = response.triggerItemInfo;
         info.entitleId = response.entitleId;
         info.url = info.targetUrl;
         info.id = info.key;
      }

      if (clientUtil.isTitanClient()) {
         const titanEntitlement: TitanEntitlementItem = item as TitanEntitlementItem;
         info.brokerUrl = titanEntitlement?.spec?.brokerUrl;
         info.dspecId = titanEntitlement?.spec.dspecId;
         info.redirectSetting = response.redirectSetting;
      } else {
         info.brokerUrl = null;
         info.dspecId = null;
         info.redirectSetting = null;
      }
      return info;
   };
}
