/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/

// Chrome Client only

import { Subject } from "rxjs";
import { SDKRawEvent } from "../event-helper/event-helper";
import { EventExchangeBase, SDKReceivedRawRequest } from "./event-exchange.base";
import { Logger } from "../logger";

export class EventExchangeOneTime extends EventExchangeBase {
   constructor(
      allowedPeerAddresss: Array<string>,
      getTrustOriginList: (string) => Promise<Array<string>>,
      isTrustSyncMessage?: (SDKRawEvent) => boolean
   ) {
      super(allowedPeerAddresss, getTrustOriginList, isTrustSyncMessage);
      if (chrome && chrome.runtime && chrome.runtime.onMessageExternal) {
         chrome.runtime.onMessageExternal.addListener((message, sender, sendResponse) => {
            const senderOrigin = this.getOrigin(sender.url);
            if (!senderOrigin) {
               return false;
            }
            Logger.trace("get a message, with sender: " + sender.url + ", origin: " + senderOrigin);
            // only support full match
            this.getAllowedPeerAddress(senderOrigin, message).then((matchedPeerAddress) => {
               if (!matchedPeerAddress) {
                  Logger.warning("ignore message from " + senderOrigin);
                  return false;
               }
               // double check sender in code again
               const request: SDKReceivedRawRequest = {
                  request: message as SDKRawEvent,
                  sendResponse: (response: SDKRawEvent) => {
                     sendResponse(response);
                  },
                  peerAddress: matchedPeerAddress
               };
               this.message$.next(request);
            });
            return true;
         });
      }
   }

   public sendMessage = (peerAddress: string, data: SDKRawEvent): Promise<SDKRawEvent> => {
      return new Promise((resolve, reject) => {
         try {
            Logger.trace(`Sent data ${JSON.stringify(data)} to ${peerAddress}`);
            chrome.runtime.sendMessage(peerAddress, data, null, function(response: SDKRawEvent) {
               resolve(response);
            });
         } catch (e) {
            Logger.exception(e);
            reject(e);
         }
      });
   };
}
