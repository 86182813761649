/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * setLastUserActivityCommand.js --
 *
 *      Called to provent the broker from locking itself due to idle timeout.
 *
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import SetLastUserActivityHandler from "../controllers/setLastUserActivityHandler";

export default function SetLastUserActivityCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param SetLastUserActivityAction[in]
 *        - its property 'method' has the value 'SetLastUserActivity'
 */
SetLastUserActivityCommand.prototype.execute = function (SetLastUserActivityAction) {
   let setLastUserActivityObject,
      handlerList,
      router = globalArray["router"],
      inactiveTimeVar,
      inactiveTimeStr;

   if (!util.brokerSupportApplication()) {
      return;
   }
   setLastUserActivityObject = globalArray["set-last-user-activity"];
   if (!setLastUserActivityObject) {
      setLastUserActivityObject = new SetLastUserActivityHandler();
      globalArray[setLastUserActivityObject.messageName] = setLastUserActivityObject;
      globalArray[setLastUserActivityObject.responseTag] = setLastUserActivityObject;
   } else {
      setLastUserActivityObject.resetData();
   }

   if (!!setLastUserActivityObject && !!router) {
      //accept both number and string format
      inactiveTimeVar = SetLastUserActivityAction.data.inactiveTime;
      inactiveTimeStr = inactiveTimeVar.toString();
      setLastUserActivityObject.setRequestXML(inactiveTimeStr);
      handlerList = setLastUserActivityObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};
