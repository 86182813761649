/** @format */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileTransferPanelComponent, FileChange, FileDrop } from "./file-transfer.component";
import { FileTransferService } from "./file-transfer.service";
import { SharedCommonModule } from "../../common/shared.common.module";

@NgModule({
   imports: [CommonModule, SharedCommonModule],
   declarations: [FileTransferPanelComponent, FileChange, FileDrop],
   providers: [FileTransferService],
   exports: [FileTransferPanelComponent, FileDrop]
})
export class FTModule {}
