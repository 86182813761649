/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 *
 *
 * Since it's a temp solution, don't polish it and direct uses
 * JS object to store.
 */

import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root"
})
export class RootModel {
   private data: object;
   public set = (key: string, value: any): void => {
      this.data[key] = value;
   };
   public get = (key: string): any => {
      if (this.data.hasOwnProperty(key)) {
         return this.data[key];
      } else {
         return undefined;
      }
   };
   public remove = (key: string): void => {
      if (this.data.hasOwnProperty(key)) {
         delete this.data[key];
      }
   };
   constructor() {
      this.data = {};
   }
}
