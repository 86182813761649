/**
 * ******************************************************
 * Copyright (C) 2012-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * doLogoutHandler.js --
 *
 *      Implementation of the message handler to do logout.
 */

import $ from "jquery";
import { globalArray, JSCDKSetUI } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";

export default function DoLogoutHandler() {
   let router,
      // the handler has been initialized in getItemsControler
      setPrefHandler = globalArray["set-user-global-preferences"];

   // member variables below
   this.messageName = "do-logout";
   this.messageText = "do-logout";
   this.responseTag = "logout";
   this.needClearURIFlag = false;

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
   }
   // the composedHandlerList needs to keep this order, which is to let set
   // preference before log out.  Otherwise, it pops the un-authenticated
   // error.
   this.composedHandlerList = [setPrefHandler, this];
}

// inherits MessageHandler prototype
DoLogoutHandler.prototype = new MessageHandler();
// constructor
DoLogoutHandler.constructor = DoLogoutHandler;

DoLogoutHandler.prototype.setNeedClearURIFlag = function () {
   this.needClearURIFlag = true;
};
/**
 * parse configuration information from the response XML of GetConfiguration
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return Object. key-value pairs parsed from response, if error then return
 *    null
 */
DoLogoutHandler.prototype.parseResult = function (responseXML) {
   let logoutResponse = {},
      xmlDoc = $(responseXML),
      brokerTag,
      responses,
      result;

   brokerTag = $(xmlDoc.children()[0]);
   if (!brokerTag) {
      Logger.error("response of doLogoutHandler error");
      return null;
   }

   responses = brokerTag.children(this.responseTag);
   result = responses.children("result").text();
   util.addItemForJson(logoutResponse, "result", result);
   return logoutResponse;
};

DoLogoutHandler.prototype.onUpdated = function () {
   let logoutAction = {
         name: "Logout"
      },
      router = util.getObject(globalArray, "router"),
      urlHandler;

   MessageHandler.prototype.onUpdated.apply(this);
   /**
    * For doLogout message, the possible error is NOT_AUTHENTICATED. But if
    * user wants to logout from the broker, the NOT_AUTHENTICATED error can
    * be treated as successful too. So set the state to DONE even there is an
    * error occurs for doLogout message.
    */
   this.setState(StateEnum.DONE);
   if (this.needClearURIFlag) {
      //just for the first time after setting this flag.(user URI only take
      // affect for once)
      this.needClearURIFlag = false;
      // delete urlHandler referece from globalArray.
      urlHandler = util.getObject(globalArray, "url-handler");
      if (urlHandler) {
         globalArray["url-handler"] = null;
      }
   }
   JSCDKSetUI(JSON.stringify(logoutAction));
   if (router) {
      router.setDoLogout();
      Logger.info("Logout completed.");
   }
   if (!!this.content["parsedResult"] && this.content["parsedResult"]["result"] === "ok") {
      Logger.debug("Logout result is OK.");
   } else {
      Logger.error("Logout failed.");
   }

   // clear windows password cache for logged out session for bug 2175319
   const winCredsHandler = util.getObject(globalArray, "windows-password");
   if (!!winCredsHandler && !!winCredsHandler.cachedDomainUsername) {
      winCredsHandler.cachedDomainUsername = "";
   }

   const offlineSSOCacheTimerController = globalArray["offline-sso-cache-timer"];
   if (offlineSSOCacheTimerController) {
      Logger.debug("Destroy offline SSO timer");
      offlineSSOCacheTimerController.stop();
   }
};
