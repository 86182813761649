/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import { RemoteUsbDevice } from "../../usb-remote";
import { SplitDevice } from "./split-device";

export class SplitDeviceFilter {
   public static check(exintf: Array<any>, remoteUsb: RemoteUsbDevice): SplitDevice | null {
      if (clientUtil.isChromeClient()) {
         if (exintf.length > 0) {
            return new SplitDevice(remoteUsb, exintf);
         }
      }
      return null;
   }
}
