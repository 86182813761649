/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export const VDP_CONSTS = {
   /**
    * VDPService Vchan sidechannel name suffix.
    * PSC is short for "PCoIP Side Channel", but
    * it should be a lagecy name, and it represents
    * vchan sidechannel on Blast which is the only
    * protocol we support for now.
    *
    * @string
    * @readonly
    */
   VCHAN_SIDECHANNEL_SUFFIX: "PSC",

   /**
    * VDPService.Channel state.
    *
    * @enum {Integer}
    * @readonly
    */
   CHANNEL_STATE: {
      CLOSED: 0, // Channel has been closed.
      CLOSING: 1, // Channel has been requested to close.
      OPEN: 2, // Channel open.
      NOT_READY: 3, // Channel accepted but a Channel Object is PENDING.
      READY: 4 // Channel is ready to send RPCs.
   },

   /**
    * RPC type.
    *    REQUEST: A response will be sent back with the return parameters.
    *    POST: The RPC does not have a response.
    *
    * @enum {String}
    * @readonly
    */
   RPC_TYPE: {
      REQUEST: "REQUEST",
      POST: "POST"
   },

   /*
    * Used internally to read/set the RPC data types
    * used by the RPC Encoder/Decoder.
    *
    * @type {Integer}
    * @readonly
    */
   VARIANT_TYPE: {
      SIGNED_INTEGER: 3,
      CHAR: 16,
      UNSIGNED_INTEGER: 19,
      UNSIGNED_INTEGER_64: 21,
      STRING: 30,
      BLOB: 65
   },

   /*
    * Used internally to inform the server about the required action to be taken
    * for the RPC and also process incoming RPCs accordingly.
    *
    * @enum {Integer}
    * @readonly
    */
   RPC_ACTION: {
      REQUEST: 1,
      RESPONSE: 3,
      POST: 4
   },

   /*
    * The command IDs of channel RPCs.
    *
    * @enum {Integer}
    * @readonly
    */
   CHANNEL_COMMANDS: {
      CREATE_OBJECT: 0xffffff9c,
      DESTROY_OBJECT: 0x65,
      EXCHANGE_SECRET: 0x66,
      SEND_VCHAN_SIDECHANNAME: 0x67
   },

   /*
    * Identifier of internal RPC messages.
    *
    * @type {Integer}
    * @readonly
    */
   RPC_DATA_FORMAT: 0xffffff9c,

   /*
    * The invalid RPC Channel Object ID.
    *
    * @type {Integer}
    * @readonly
    */
   INVALID_CHANNEL_OBJECT_ID: 0xfffffffe,

   /*
    * The starting RPC request ID.
    *
    * @type {Integer}
    * @readonly
    */
   START_REQUEST_ID: 1,

   /*
    * The minimum length of the RESPONSE section of an encoded header.
    *
    * @type {Integer}
    * @readonly
    */
   MINIMUM_RETURN_SECTION_LENGTH: 20,

   /*
    * This version of VDPService supports POST RPCs and sidechannel
    * is disabled, but CDR will still uses sidechannel which is caused
    * by the agent logic. Set the capability field accordingly.
    * (capability = 0x0b)
    *
    * @type {Integer}
    * @readonly
    */
   CAPABILITY: 0x0b,

   /**
    * Work(by using '&') with CAPABILITY above to enable side channel
    */
   CAP_ENABLE_SIDE_CHANNEL: 0xfffd,

   /**
    * Work(by using '|') with CAPABILITY above to enable SNAPPY
    */
   CAP_ENABLE_SNAPPY: 0x0800,

   /*
    * The length of the RPC header.
    *
    * @type {Integer}
    * @readonly
    */
   RPC_HEADER_SIZE: 24,

   /**
    * RPC data format options (to be put in wireheader)
    */
   VDP_RPC_COMP_SNAPPY: 1 << 11
};
