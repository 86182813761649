/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export const HTML5MMR_CONST = {
   CHANNEL_NAME_PREFIX: "RPC#HTML5MMR#",
   CHANNEL_NAME_PREFIX_LOW_CASE: "RPC##html5mmr#",

   HTML5_MMR_MAIN_OBJECT: "HTML5MMRMainObj",
   HTML5_MMR_SIDE_CHANNEL_OBJECT: "HTML5MMRSideChannelObj_native",
   HTML5_GPS_REDIR_OBJECT: "HTML5GpsRedirObj",

   STATS_OVERLAY_LOCAL_STORAGE_KEY: "STATS_OVERLAY_ENABLED",
   MMR_RUNNNING_SESSION_FLAG: "MMRSessionFlag",

   GPSREDIR_CLIENT_RETURNCODE: 0,
   GPSREDIR_CLIENT_VERSION: 1,
   GPSREDIR_CLIENT_MINOR_VERSION: 1,
   GPSREDIR_CLIENT_CAPABILITY: 0,
   GPSREDIR_WATCH_TIMEOUT: 60000,
   GPSREDIR_MAXIMUMAGE: 15000,

   TOP_HEIGHT_FOR_VIDEO_ADJUSTMENT: 30,

   MMR_MESSAGE: {
      // Agent to client events
      HTML5MMR_SERVER_COMMAND: 0,

      HTML5MMR_CMD_EXCHANGE_VERSIONINFO: 20,
      HTML5MMR_CMD_DISCONNECT: 21,
      HTML5MMR_CMD_SEND_WEBTEXT: 22,
      HTML5MMR_CMD_SEND_WEBBINARY: 23,
      HTML5MMR_CMD_UPDATE_OVERLAY: 24,
      HTML5MMR_CMD_UPDATE_WINDOW: 25,
      HTML5MMR_CMD_UPDATE_MOUSE_KEYBOARD: 26,
      HTML5MMR_CMD_UPDATE_ENV: 27,
      HTML5MMR_CMD_GET_SCREEN_THUMBNAILS: 28,

      // client local command
      HTML5MMR_CMD_UPDATE_TOPOLOGY: 200,

      // client to agent commands.
      HTML5MMR_CLIENT_COMMAND: 500
   },

   /**
    * Environment Flags when HTML5MMR_CMD_UPDATE_ENV is received
    */
   MMR_ENV: {
      HTML5MMR_ENV_INVALID: 0,
      HTML5MMR_ENV_DPI: 1,
      HTML5MMR_ENV_VOLUME: 2,
      HTML5MMR_ENV_CLIENT_TOPOLOGY: 3
   },

   HTML5_FEATURE_TYPE: {
      INVALID_FEATURE: 0,
      HTML5_MMR_FEATURE: 1,
      GPS_REDIRECTION_FEATURE: 2,
      WEBRTC_REDIRECTION_FEATURE: 3,
      WEBRTC_REDIRECTION_NATIVE_FEATURE: 4,
      BROWSER_REDIRECTION_FEATURE: 5,
      BROWSER_REDIRECTION_POPUP_FEATURE: 6
   },

   FEATURES_SYNC_MSG_VERSION: "1",
   BROWSERREDIR_VERSION: "1",
   HTML5MMR_VERSION: "1",
   WEBRTCREDIR_VERSION: "1",
   CLIENT_CAPABILITY_DEFAULT: "0",
   DEFAULT_PLAYERHTML_VERSION: "1",
   VIDEO_MAXIMUM_WIDTH: 1280,

   ENABLED: "enabled",
   DISABLED: "disabled",
   TRUE: "true",
   FALSE: "fasle",
   UNAVAILABLE: "unavailable",
   ENUM_DEVICES: "enumDevices",
   GETUSERMEDIA: "getUserMedia",
   GETDISPLAYMEDIASHIM: "getDisplayMediaShim",
   UPDATE_OVERLAY: "updateOverlay",
   SETSINKID: "setSinkId",
   LOADEDMETADATA: "loadedmetadata",
   VDISCREENTOPOLOGYCHANGED: "vdiScreenTopologyChanged",
   VDIE911INFOCHANGED: "vdiE911InfoChanged",
   DEVICECHANGE: "devicechange",

   CREATE_INSTANCE: "createInstance",
   CREATE_INSTANCE_DONE: "createInstanceDone",

   WEBRTC_STR_INTERNAL_ERROR: "Internal error",
   INVALID_INPUT: "Invalid input",
   UNIFIED_PLAN: "unified-plan",
   PLAN_B: "plan-b",
   ICE_CANDIDATE: "icecandidate",
   ON_TRACK: "ontrack",
   ON_SENDERS: "onsenders",
   ON_TRANSCEIVERS: "ontransceivers",
   RMTRANSCEIVER_ID: "TransceiverRmt#",
   TONE_CHANGE: "tonechange",

   DEFAULT_CONSTRAITS: {
      AUDIO: true,
      VIDEO: {
         width: 1280,
         height: 720,
         frameRate: 30
      }
   },

   GET_STAT_CAPABILITY: {
      GETSTAT_CAPABILITY_1: 1 << 0, // diff + compression
      GETSTAT_CAPABILITY_2: 1 << 1 // compression only
   },

   // Binary Data related
   BINARY_DATA_LEN: 4,

   // Binary Audio Notify Data
   AUD_NOTIFY_HEADER_LEN: 16,
   AUD_NOTIFY_SIGNATURE_LEN: 4,
   AUD_NOTIFY_ID_OFFSET: 8,
   AUD_NOTIFY_SIGNATURE: 0x414e5446,

   BINARYSTREAM_READREADY: 0,
   BINARYSTREAM_END: 1,

   // Copied from: https://opengrok.eng.vmware.com/source/xref/main.perforce.1666/bora/apps/rde/html5mmr/web/webrtc/webRTCRedir.js
   RTCErrorType: {
      // No error.
      NONE: 0,

      /* An operation is valid, but currently unsupported.
       * Maps to OperationError DOMException.
       */
      UNSUPPORTED_OPERATION: 1,

      /* A supplied parameter is valid, but currently unsupported.
       * Maps to OperationError DOMException.
       */
      UNSUPPORTED_PARAMETER: 2,

      /* General error indicating that a supplied parameter is invalid.
       * Maps to InvalidAccessError or TypeError DOMException depending on context.
       */
      INVALID_PARAMETER: 3,

      /* Slightly more specific than INVALID_PARAMETER; a parameter's value was
       * outside the allowed range.
       * Maps to RangeError DOMException.
       */
      INVALID_RANGE: 4,

      /* Slightly more specific than INVALID_PARAMETER; an error occurred while
       * parsing string input.
       * Maps to SyntaxError DOMException.
       */
      SYNTAX_ERROR: 5,

      /* The object does not support this operation in its current state.
       * Maps to InvalidStateError DOMException.
       */
      INVALID_STATE: 6,

      /* An attempt was made to modify the object in an invalid way.
       * Maps to InvalidModificationError DOMException.
       */
      INVALID_MODIFICATION: 7,

      /* An error occurred within an underlying network protocol.
       * Maps to NetworkError DOMException.
       */
      NETWORK_ERROR: 8,

      /* Some resource has been exhausted; file handles, hardware resources, ports,
       * etc.
       * Maps to OperationError DOMException.
       */
      RESOURCE_EXHAUSTED: 9,

      /* The operation failed due to an internal error.
       * Maps to OperationError DOMException.
       */
      INTERNAL_ERROR: 10,

      /* An error occured that has additional data.
       * Maps to RTCError DOMException.
       */
      OPERATION_ERROR_WITH_DATA: 11
   },

   // index matches the error type value above.
   RTCErrorTypeNames: [
      "NONE",
      "UNSUPPORTED_OPERATION",
      "UNSUPPORTED_PARAMETER",
      "INVALID_PARAMETER",
      "INVALID_RANGE",
      "SYNTAX_ERROR",
      "INVALID_STATE",
      "INVALID_MODIFICATION",
      "NETWORK_ERROR",
      "RESOURCE_EXHAUSTED",
      "INTERNAL_ERROR",
      "OPERATION_ERROR_WITH_DATA"
   ],

   TRANSCEIVER_CMD: {
      TRANSCEIVER_ADD_TRANSCEIVER: 1,
      TRANSCEIVER_SET_CODECPREFERENCE: 2,
      TRANSCEIVER_STOP: 3,
      TRANSCEIVER_SET_DIRECTION: 4
   },

   SENDER_CMD: {
      SENDERCMD_SET_PARAMETERS: 1,
      SENDERCMD_REPLACE_TRACK: 2
   },

   RECEIVER_TRACKINFO_KEYS: ["id", "kind", "enabled", "readyState", "contentHint", "muted"],
   RECEIVER_SOURCE_KEYS: ["type", "source", "timestamp"],
   RECEIVER_SOURCE_OPTIONAL_KEYS: ["audioLevel"],
   TRANSCEIVER_DIRECTIONS: ["sendrecv", "sendonly", "recvonly", "inactive"],

   // Datachannel comand
   DC_CMD: {
      CREATE: 1,
      SEND: 2,
      SET_BUFFER_AMOUNT_LOW_THRESHOLD: 3,
      CLOSE: 4
   }
};

export const JKEYS = {
   FEATURES: "features", // Root node of features sync
   VERSION: "version",

   BROWSER_REDIRECT: "browserRedir",
   HTML5MMR: "html5mmr",
   WEBRTC_REDIRECT: "webRTCRedir",

   STATUS: "status",
   MIN_CLIENT_VERSION: "minClientVersion",

   AGC: "agc",
   ALLOW: "allow",
   BROWSER: "browser",
   CLIENT_OS_VERSION: "clientOsVersion",
   BROWSER_VER: "browserVer",
   FEATURE_SUPPORTED: "featuresSupported",
   MAX_WIDTH: "maxWidth",

   VIDEOID: "videoId",
   VIDEO_WIDTH: "videoWidth",
   VIDEO_HEIGHT: "videoHeight",
   CAPS: "caps",
   DEVICES: "devices",
   EVT: "evt",
   ID: "id",
   PEER: "peer",
   STREAM: "stream",
   ACTIVE: "active",
   TRACK: "track",
   TRACKS: "tracks",
   CONTENT_HINT: "contentHint",
   ENABLED: "enabled",
   ISOLATED: "isolated",
   KIND: "kind",
   LABEL: "label",
   MUTED: "muted",
   READ_ONLY: "readonly",
   READY_STATE: "readyState",
   REMOTE: "remote",
   SETTINGS: "settings",
   ASPECT_RATIO: "aspectRatio",
   DEVICE_ID: "deviceId",
   FRAME_RATE: "frameRate",
   HEIGHT: "height",
   WIDTH: "width",
   CMD: "cmd",
   COMMAND: "command",
   COMMAND_ID: "commandId",
   INSTANCE: "instance",
   FEATURE: "feature",
   VISIBLE: "visible",
   ERROR: "error",
   AUDIO_ID: "audioId",
   ARG1: "arg1",
   ARG2: "arg2",
   DETAILS: "details",
   API_CAPABILITIES: "api-capabilities",
   GET_STAT: "getstat",
   ICE_GATHERING_STATE: "iceGatheringState",
   LOCAL_DESCRIPTION: "localDescription",
   REMOTE_DESCRIPTION: "remoteDescription",
   SDP: "sdp",
   OPTIONS: "options",
   SID: "sid",
   DESC: "desc",
   LOG_TO_CONSOLE: "logToConsole",
   DEVID: "devId",
   TYPE: "type",
   AUDIO_LEVEL: "audioLevel",
   SOURCE: "source",
   TIME_STAMP: "timestamp",
   OP: "op",
   TRACKINFO: "trackInfo",
   SOURCEINFOS: "sourceInfos",
   RECEIVERS: "receivers",
   RECEIVER: "receiver",
   SOURCES: "sources",
   SENDER: "sender",
   SET_SINKID: "setSinkId",
   JPEG_COMPRESS: "jpegCompress",
   GET_SCREENS: "getScreens",
   BROWSER_REDIR_CLIENT_VERSION: "clientVersion",
   CLIENT_CAPABILITY: "clientCapability",
   PLAYERHTML_VERSION: "playerHTMLVersion",
   WEBRTC_REDIR_CLIENT_VERSION: "webRTCRedirClientVersion",
   CLIENT_SCRIPT: "clientScript",
   WEBRTC_CLIENT_VER: "webrtcClientVer",
   LOCAL_STREAMS: "localStreams",
   CODE: "code",
   NAME: "name",
   MESSAGE: "message",
   TRKID: "trkid",
   CONSTRAINTS: "constraints",
   CAPABILITY: "capability",
   SDP_SEMANTICS: "sdpSemantics",
   CANDIDATE: "candidate",
   SDP_MID: "sdpMid",
   TID: "tid",
   SENDERS: "senders",
   PARAMETERS: "parameters",
   SENDER_ID: "senderId",
   SENDER_CMD: "senderCmd",
   TRANSCEIVER_ID: "transceiverId",
   TRANSCEIVER_CMD: "transceiverCmd",
   TRACK_OR_KIND: "trackOrKind",
   INIT: "init",
   VALUE: "value",
   TRANSCEIVERS: "transceivers",
   TRANSCEIVER: "transceiver",
   HINT: "hint",
   CURRENT_DIRECTION: "currentdirection",
   DIRECTION: "direction",
   MID: "mid",
   MEDIA_TYPE: "mediaType",
   CONNECTIONSTATE: "connectionState",
   CODECS: "codecs",
   TRANSACTION_ID: "transactionId",
   DEGRADATION_PREFERENCE: "degradationPreference",
   PRIORITY: "priority",
   ENCODINGS: "encodings",
   CODEC_PAYLOAD_TYPE: "codecPayloadType",
   DTX: "dtx",
   MAX_BITRATE: "maxBitrate",
   MAX_FRAMERATE: "maxFramerate",
   P_TIME: "ptime",
   SCALE_RESOLUTION_DOWN_BY: "scaleResolutionDownBy",
   TRACK_ID: "trackId",
   NEW_TRACK_ID: "newTrackId",
   AUDIO: "audio",
   VIDEO: "video",
   RTP_CAPABILITIES: "rtpCapabilities",
   TRANSCEIVERS_REMOVED: "transceiversRemoved",
   CONNECTION_STATE: "connectionState",
   TONE: "tone",
   TONES: "tones",
   TONE_BUFFER: "toneBuffer",
   DURATION: "duration",
   GAP: "gap",
   DATACHANNEL: "datachannel",
   ENABLE_DATACHANNEL: "enableDatachannel",
   DATACHANNEL_CMD: "dataChannelCmd",
   SHIM_ID: "shimId",
   CHANNEL: "channel",
   RELIABLE: "reliable",
   ORDERED: "ordered",
   PROTOCOL: "protocol",
   NEGOTIATED: "negotiated",
   MAXRETRANSMITS: "maxRetransmits",
   MAXPACKETLIFETIME: "maxPacketLifeTime",
   DATA: "data",
   DATA_TYPE: "dataType",
   DATACHANNEL_ID: "dataChannelId",
   THRESHOLD: "threshold",
   TITLE: "title",
   URL: "url",
   LOADING: "loading",
   ERROR_CODE: "errorCode",
   FULLSCREEN: "fullscreen",
   POPUP: "popup",
   FORCED: "forced",
   SUPPORT_E911: "supportE911",
   PAYLOAD: "payload",
   ENHANCED: "enhanced",
   ENH_BCR_SCRIPT: "enhBCRScript",
   SHOW: "show",
   REF: "ref",
   RESP_NEEDED: "respNeeded",
   ENH_BCR_SUPPORTED: "enhBcrSupported",
   MOVED_TO_ENHANCED: "movedToEnhanced",
   URL_REDIRECT_CHAIN: "urlRedirectChain"
};

export const BCR_CONST = {
   WHITELIST: "result",
   NAVWHITELIST: "navWhitelist",
   ENHWHITELIST: "enhWhitelist",
   ALL_MATCH: "<all_urls>",
   MAX_URL_LEN: 2083,
   ENH_BCR: "enhBCR",
   ENH_BCR_RESPONSE: "enhancedBCRResponse"
};

export const BCR_CLIENT_ERROR = {
   URL_NOT_WHITELISTED: 6
};

export const WEBVIEW_CONST = {
   KEY_DOWN: "keydown",
   LINK: "link",
   WEBVIEW_OVERLAY_CONTAINER: "webview-overlay-container",
   ALL: "all",
   CRED_ERROR_CODE: -338,
   BCR_NEW_TAB: "bcr_new_tab",
   BCR_NEW_WINDOW: "bcr_new_window",
   BCR_FORWARD: "bcr_forward",
   BCR_BACK: "bcr_back",
   BCR_PRINT: "bcr_print"
};

export const WEB_COMMAND = {
   HTML5MMR_WEBRTC_EVT: -1,
   WEB_COMMAND_INVALID: 0,
   DEFAULT: 1,
   CREATEINSTANCE: 2,
   CREATEINSTANCE_RESULT: 3,
   DESTROYINSTANCE: 4,
   VIDEO_CONTROL: 5, // Play, pause, seek.
   UPDATE_OVERLAY: 6, // Enable/Visible/Location/Size
   ADDSOURCE_BUFFER: 7,
   STARTURL: 8,
   STOPURL: 9,
   SOURCEBUFFER_ABORT: 10,
   LOG_MESSAGE: 11, // Log messages sent by player html
   FATAL_ERROR: 12, // To handle fatal error sent by client html
   UPDATE_TITLE: 13, // title update for the frame.
   ADD_TEXT_TRACK: 14,
   ADD_CUES: 15,
   TEXT_TRACK_CHANGE: 16,
   FEATURES_SYNC: 17,
   FEATURES_SYNC_RESULT: 18,
   CREATEWEBRTC_INSTANCE: 30,
   CREATEWEBRTC_RESULT: 31,
   ADD_VIDEO: 32,
   REMOVE_VIDEO: 33,
   UPDATE_VIDEO: 34,
   CREATEBRWREDIR_INSTANCE: 35,
   NEW_FRAME: 36,
   REMOVE_FRAME: 37,

   // new web command for webrtc
   ENUM_DEVICE: 41,
   ENUM_VIDEODEVICE: 42,
   CREATE_PEERCONNECTION: 43,
   CREATE_OFFER: 44,
   CREATE_ANSWER: 45,
   SET_LOCALDESCRIPTION: 46,
   SET_REMOTEDESCRIPTION: 47,
   ADD_ICECANDICATE: 48,
   ADD_STREAM: 49,
   GET_LOCALSTEAMS: 50,
   GET_STATS: 51,
   REMOVE_STREAM: 52,
   CLOSE: 53,
   GETUSERMEDIASHIM: 54,
   MEDIATRK_STOP: 55,
   TRACK_ENABLED: 56,
   NEW_AUDIO_ELEMENT: 57,
   SET_SINKID: 58,
   UPDATE_SRCOBJECT: 59,
   NOTIFY_AUDIO: 60,
   MEDIA_STREM_CLONE: 61,
   MEDIA_TRACK_CLONE: 62,
   GETDISPLAYMEDIASHIM: 63,
   SETDEFAULTSINKID: 64,
   SHIM_LOG_TO_WEBSOCKET: 65,
   INSERT_DTMF: 66,
   GET_RECEIVERS: 67,
   GET_STATS_2: 68,
   CUSTOM_CLIPRECT: 69,
   APPLY_CONSTRAINTS: 70,
   GET_SCREENS: 71,
   SET_SCREENS_ID: 72,
   HID: 73,
   ADD_TRACK: 74,
   REMOVE_TRACK: 75,
   SENDER_CMDS: 76,
   CREATE_MEDIASTREAM: 77,
   CONNECTED_STATE_CHANGE: 78,
   UPDATE_OBJECTFIT: 79,

   // Webrtc event
   EVENT_ONCONNECTIOINSTATECHANGE: 80,
   EVENT_ONDATACHANNEL: 81,
   EVENT_ONICECANDIDATE: 82,
   EVENT_ONICECONNECTIONSTATECHANGE: 83,
   EVENT_ONICEGATHERINGSTATECHANGE: 84,
   EVENT_ONNEGOTIATIONNEEDED: 85,
   EVENT_ONSIGNALINGSTATECHANGE: 86,
   EVENT_ONADDSTREAM: 87,
   EVENT_ONREMOVESTREAM: 88,
   EVENT_ONDEVICECHANGED: 89,
   EVENT_ONRECEIVERS: 90,
   EVENT_ONSENDERS: 91,
   EVENT_ONTRANSCEIVERS: 92,
   EVENT_ONTRACK: 93,
   EVENT_ONREMOVESTREAM_V1: 94,

   // Webrtc internal event
   HTML5MMR_WEBRTC_EVENT_INTERNAL_INFO: 97,
   HTML5MMR_WEBRTC_EVENT_UPDATE_PREFS: 98,
   HTML5MMR_WEBRTC_EVENT_POSTEVENT: 99,

   // GPS Redirection commands
   HTML5MMR_GPS_COMMAND_GET_CURR_POS: 100,
   HTML5MMR_GPS_COMMAND_GET_CURR_POS_RESULT: 101,
   HTML5MMR_GPS_START_UPDATES: 102,
   HTML5MMR_GPS_STOP_UPDATES: 103,
   HTML5MMR_GPS_SEND_GEO_INFO: 104,

   // Browser redirection commands
   HTML5MMR_BRWREDIR_COMMAND_WEBSOCK_CONNECTED: 200,
   HTML5MMR_BRWREDIR_COMMAND_CREATE_BROWSER: 201,
   HTML5MMR_BRWREDIR_COMMAND_CREATEBROWSER_DONE: 202,
   HTML5MMR_BRWREDIR_COMMAND_LOAD_REMOTE_URL: 203,
   HTML5MMR_BRWREDIR_COMMAND_NEW_TAB: 204,
   HTML5MMR_BRWREDIR_COMMAND_NEW_WINDOW: 205,
   HTML5MMR_BRWREDIR_COMMAND_UPDATE_ADDR: 206,
   HTML5MMR_BRWREDIR_COMMAND_FULLSCREEN_MODE: 207,
   HTML5MMR_BRWREDIR_COMMAND_ASSOC_POPUP: 208,
   HTML5MMR_BRWREDIR_COMMAND_REMOVE_TAB: 209,
   HTML5MMR_BRWREDIR_COMMAND_SET_WHITELIST: 210,
   HTML5MMR_BRWREDIR_COMMAND_CLIENT_ERROR: 211,
   HTML5MMR_BRWREDIR_COMMAND_LOADING_STATE: 212,

   HTML5MMR_ENH_BRWREDIR_COMMAND_OVERLAY_VISIBILITY: 214,
   HTML5MMR_ENH_BRWREDIR_COMMAND_SEND_MESSAGE: 215,
   HTML5MMR_ENH_BRWREDIR_COMMAND_RESPONSE: 216,
   HTML5MMR_ENH_BRWREDIR_COMMAND_CHANGE_OVERLAY: 217,

   // New webrtc command
   WEBRTC_TRANSCEIVER_CMD: 300, // transceiver related commands.
   WEBRTC_DATACHANNEL_CMD: 301, // Data channel commands.
   WEBRTC_EVENT_DATACHANNEL: 302, // data channel event

   /*
    * Use a high value since this is a client side only
    * internal event.
    */
   HTML5MMR_WEBRTC_EVENT_MACHIGHLIGHTER_CHANGE: 5001,
   HTML5MMR_WEBRTC_EVENT_ON_E911_INFO_CHANGE: 5002
};

//Used for enhanced BCR communication
export const InjectedScriptMessageType = {
   INVALID: 0,
   OVERLAY_VISIBILITY: 1,
   SEND_MESSAGE: 2,
   REQUEST_DONE: 3,
   ENH_RESPONSE: 4,
   ENH_BCR_INITIALIZED: 5,
   INJECT_ENH_BCR_API_SCRIPT: 6,
   SEND_ENH_BCR_RESPONSE: 7,
   ENH_BCR_RECEIVED_MESSAGE: 8,
   REMOVE_ENH_BCR_API_SCRIPT: 9
};
