/**
 * ******************************************************
 * Copyright (C) 2017-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { IVDPServiceListener, VDPChannel, IRPC } from "../vdpservice";

export class RDEChannel implements IVDPServiceListener {
   static readonly namePrefix: string = "RPC#VMwareRde#";
   static readonly objNames: string[] = ["UnitySvcObject", "CommonSvcObject"];
   rdeChannel: VDPChannel = null;
   msgHandlers = [];

   constructor(vdpService: any) {
      vdpService.addChannelCreatedListener((channel: VDPChannel) => {
         if (channel.name.indexOf(RDEChannel.namePrefix) === 0) {
            if (vdpService.connectChannel(channel, RDEChannel.objNames)) {
               Logger.debug("Successfully accepted main channel");
            } else {
               Logger.error("Failed to open Unity main channel");
               return;
            }

            this.rdeChannel = channel;
            channel.addCallback(this);
         }
      });
   }
   public onReady = () => {
      Logger.debug("Main channel is ready for traffic.");
   };

   public onDisconnect = () => {
      this.rdeChannel = null;
      Logger.debug("Main channel was closed by the remote desktop.");
   };

   public onInvoke = (rpc: IRPC) => {
      this.handleRPCFromServer(rpc);
   };

   public handleRPCFromServer = (rpc: IRPC) => {
      for (let i = 0; i < this.msgHandlers.length; i++) {
         const ret = this.msgHandlers[i].handleRPCFromServer(rpc);
         if (ret === 0) {
            // means the message has been consumed
            break;
         }
      }
   };

   addMessageHandler = (handler) => {
      this.msgHandlers.push(handler);
   };

   invoke = (param) => {
      if (this.rdeChannel) {
         return this.rdeChannel.invoke(param);
      }
   };
}
