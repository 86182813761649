/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class KillSwitchService {
   public readonly killSwitchPrefix = "KillSwitch-";
   public abstract init(feature?);
}
