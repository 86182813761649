/**
 * *******************************************************************
 * Copyright (C) 2022-2024 VMware, Inc. All rights reserved.
 * ********************************************************************
 *
 * @format
 */

import { Logger } from "@html-core";

/**
 * dummy-service.js
 *
 * This file is introduced to bypass an CDR issue on RDSH caused by Agent
 * tries to wait Smart Card channel before using CDR.
 * This file should be removed once we've really support the Smart Card redirection
 * This is using same structure of RTAV, and should be splited into a few
 * class and service when really support smart card.
 *
 * Need do nothing on the channel or maintain its lifecycle in this class.
 *
 * See detail in bug 2079446
 * This should also be used even if we've disable Smart card redirection.
 */

export class smartCardDummyService {
   private sessionMap = {};
   constructor() {}

   private smartCardDummyChannel = (vvcSession) => {
      return {
         initialize: function () {
            const dummyChannel = vvcSession.openChannel("Smart Card Virtual Channel", 10);
            dummyChannel.onopen = function () {
               Logger.debug("SmartCard Dummy Channel has been connected");
            };
            dummyChannel.onclose = function () {
               Logger.debug("SmartCard Dummy Channel has been disconnected");
            };
            dummyChannel.onerror = function () {
               Logger.debug("SmartCard Dummy Channel encount error");
            };
         }
      };
   };

   public onConnecting = (id, vvcSession) => {
      if (!vvcSession) {
         Logger.warning("fail to create smart card dummuy channel for " + id);
         return;
      }
      Logger.trace("creating smart card dummuy channel for " + id);
      this.sessionMap[id] = this.smartCardDummyChannel(vvcSession);
   };

   public onConnected = (id) => {
      if (this.sessionMap.hasOwnProperty(id)) {
         Logger.trace("connecting smart card dummuy channel for " + id);
         this.sessionMap[id].initialize();
      } else {
         Logger.warning("fail to connect smart card dummuy channel for " + id);
      }
   };

   public onDisconnected = (id) => {
      if (this.sessionMap.hasOwnProperty(id)) {
         Logger.trace("deleting smart card dummuy channel for " + id);
         delete this.sessionMap[id];
      } else {
         Logger.warning("fail to disconnect smart card dummuy channel for " + id);
      }
   };
}
