/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * protocolRedirectController.js--
 *
 *      inherited Class to handle <get-desktop-connection-redirect>
 */

import GetDesktopConnectionRedirectHandler from "./getDesktopConnectionRedirectHandler";
import GetApplicationConnectionRedirectHandler from "./getApplicationConnectionRedirectHandler";
import GetApplicationSessionConnectionRedirectHandler from "./getApplicationSessionConnectionRedirectHandler";
import RedirectGateway from "./redirectGateway";
import Logger from "../../../../core/libs/logger";

export default function ProtocolRedirectionController() {
   this.gatewayMap = {};
   this.redirectionHandlers = {
      Desktop: new GetDesktopConnectionRedirectHandler(),
      Application: new GetApplicationConnectionRedirectHandler(),
      ApplicationSession: new GetApplicationSessionConnectionRedirectHandler()
   };
}

ProtocolRedirectionController.prototype.redirect = function (type, response, initialRequestInfo) {
   return new Promise((resolve, reject) => {
      const redirectSettings = response["redirect-settings"];
      const cachedResponse = JSON.parse(JSON.stringify(response));
      let redirectURL = redirectSettings["url"];
      if (!redirectURL) {
         Logger.error("bad XML response, missing redirectURL");
         return {};
      }
      if (redirectURL.indexOf("https://") !== 0) {
         redirectURL = "https://" + redirectURL;
      }
      if (!this.gatewayMap.hasOwnProperty(redirectURL)) {
         this.gatewayMap[redirectURL] = new RedirectGateway(redirectURL);
      }
      this.redirectionHandlers[type]
         .getCompleteConnectionInfo(
            this.gatewayMap[redirectURL],
            redirectSettings["protocol-redirect-token"],
            cachedResponse,
            initialRequestInfo
         )
         .then((completeResponse) => {
            Logger.error(completeResponse);
            resolve(completeResponse);
         })
         .catch(reject);
   });
};
