/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Logger } from "@html-core";
import { RemoteUsbDevice } from "../../usb-remote";
import { UsbProtocol } from "../../usb-protocol";
import { util } from "../../usb-util";

export const SplitDeviceConsts = {
   USB_DESCRIPTOR_LENGTH: 9,
   HID_USAGE_DESKTOP_KEYBOARD: 0x06,
   DESCRIPTOR_TYPE: {
      DEVICE_DESCRIPTOR: 0x01,
      CONFIGURATION_DESCRIPTOR: 0x02,
      INTERFACE_DESCRIPTOR: 0x04
   },
   INTERFACE_TYPE: {
      AUDIO: 0x01,
      HID: 0x03,
      PRINTER: 0x07
   },
   FAKE_INTERFACE: [
      //interfaceInfo
      0x09, // bLength, USBDEF_DT_INTERFACE_SIZE
      0x04, // bDescriptorType, USBDEF_DT_INTERFACE
      0, // bInterfaceNumber - filled later
      0, // bAlternateSetting - filled later
      0, // bNumEndpoints
      0x03, // bInterfaceClass
      0x00, // bInterfaceSubClass
      0x00, // bInterfacePro
      0, // iInterface
      //hidinfo
      0x09, // bLength, HID descriptor length is 0x09
      0x21, // bDescriptorType, HID's Descriptor type is 0x21
      0x10, // bcdHID, size 2
      0x01,
      0x00, // bCountryCode, Not supported
      0x01, // bNumDescriptors, 1 report exist at least
      0x22, // bDescriptorType: HID Report type is 0x22
      0x07, // wDescriptorLength, size 2,
      0
   ],
   END_POINT_DESCRIPTOR: [
      0x07, //bLength
      0x05, //bDescriptorType
      0x81, //bEndpointAddress
      0x03, //bmAttributes
      0x40, //wMaxPacketSize
      0x00,
      0x0a //bInterval
   ],
   fakeHIDReport: [
      0x05, //Header of HID_USAGE_PAGE
      0x01, //Usage page
      0x09, //Header of HID_USAGE
      0x06, //Usage ID
      0xa1, //Header of HID_COLLECTION
      0x01, //collection type
      0xc0 //End collection
   ]
};

export class SplitDevice {
   public excludedInterfaces: Array<number> = new Array<number>();
   protected remoteUsb: RemoteUsbDevice = null;
   private logger;
   private _rewroteDescriptor;
   private _lengthAfterRewrite = [];
   private _readLength = 0;

   constructor(remoteUsb: RemoteUsbDevice, excludedInterfaces: Array<number>) {
      this.remoteUsb = remoteUsb;
      this.excludedInterfaces = excludedInterfaces;
      this.logger = new Logger(Logger.SPLIT_USB);
   }

   public generateFakeHIDReport = () => {
      const outputConfig = new Uint8Array(SplitDeviceConsts.fakeHIDReport);
      return new USBInTransferResult("ok", new DataView(outputConfig.buffer));
   };

   private _getDescriptorInCache = (cachedDescriptors, interfaceNum: number) => {
      for (let i = 0; i < cachedDescriptors.length; i++) {
         if (cachedDescriptors[i].interface === interfaceNum) {
            return cachedDescriptors[i];
         }
      }
      return false;
   };

   private _cacheDescriptor = (cachedDescriptors, descriptor, HIDDescriptor, endPointDescriptors) => {
      const interfaceNum = descriptor[2];
      cachedDescriptors.push({
         interface: interfaceNum,
         descriptor: descriptor,
         HIDDescriptor: HIDDescriptor,
         endPointDescriptors: endPointDescriptors
      });
   };

   private _isValidDescriptor = (descriptor): boolean => {
      if (descriptor.length !== SplitDeviceConsts.USB_DESCRIPTOR_LENGTH) {
         this.logger.debug("descriptor's length is invalid: wrong length " + descriptor.length);
         return false;
      }
      if (
         Number(descriptor[0]) === SplitDeviceConsts.USB_DESCRIPTOR_LENGTH &&
         Number(descriptor[1]) === SplitDeviceConsts.DESCRIPTOR_TYPE.INTERFACE_DESCRIPTOR
      ) {
         if (this.excludedInterfaces.indexOf(Number(descriptor[2])) === -1) {
            return true;
         } else {
            this.logger.debug("descriptor is invalid: the interface is excluded");
            return false;
         }
      } else {
         this.logger.debug(
            "descriptor is invalid: descriptor[0] :" + descriptor[0] + ", descriptor[1] :" + descriptor[1]
         );
         return false;
      }
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _getFragment
    *
    * Read a fragfment from a Uint8Array,
    *
    * Input:
    *    input: the whole Uint8Array
    *    start: the start index in the Uint8Array
    *    length: the length to be readed
    *
    * Results:
    *     A new Uint8Array
    *---------------------------------------------------------------------------
    */
   private _getFragment = (start, length, input) => {
      let readedLength = 0;
      const output = [];
      for (let i = start; readedLength < length; i++) {
         output[readedLength] = input[i];
         readedLength += 1;
      }
      return output;
   };

   private _readInterfaceDescriptor = (packet, startIndex) => {
      const nextFragmentLength = packet[startIndex];
      const nextDescriptor: Array<Uint8Array> = this._getFragment(startIndex, nextFragmentLength, packet);
      this._readLength += nextFragmentLength;
      return nextDescriptor;
   };

   /*
    *---------------------------------------------------------------------------
    *
    * _uint8ArrayConcat
    *
    * Since Uint8Array doesn't have concat function, this function
    * will concat two uint8Array by convert them to array then convert
    * them back after concat.
    *
    * Input:
    *    Two Uint8arrays to be contact
    *
    * Results:
    *     A new Uint8Array
    *---------------------------------------------------------------------------
    */
   private _uint8ArrayConcat = (uint8ArrayA, uint8ArrayB): Uint8Array => {
      const arrayA = Array.from(uint8ArrayA);
      const arrayB = Array.from(uint8ArrayB);
      const res: Array<any> = arrayA.concat(arrayB);
      return new Uint8Array(res);
   };

   /*
    *---------------------------------------------------------------------------
    *
    * rewriteConfiguration
    *
    * Read the interfaces in a configuration descriptor, cache the valid interface
    * And replace the excluded interfaces' descriptor by faked ones.
    *
    *
    * Results:
    *     A new configuration descriptor with type of USBInTransferResult
    *---------------------------------------------------------------------------
    */
   public rewriteConfiguration = (configuration): USBInTransferResult => {
      let res = new Uint8Array([]);
      const inputConfig = new Uint8Array(configuration.data.buffer);
      let descriptorCount = 0;
      let epDescriptorCount = 0;
      const configurationHeader = inputConfig.slice(0, SplitDeviceConsts.USB_DESCRIPTOR_LENGTH);
      const interfacesAmount = configurationHeader[4];
      //Skip header, read the first interface descriptor
      this._readLength = 9;
      const cachedDescriptors = new Array<any>();

      //Find the valid usb descriptor
      while (inputConfig.length - this._readLength - SplitDeviceConsts.END_POINT_DESCRIPTOR.length > 0) {
         const nextDescriptor: Array<Uint8Array> = this._readInterfaceDescriptor(inputConfig, this._readLength);
         if (this._isValidDescriptor(nextDescriptor)) {
            this.logger.info("cache HID descriptor and its endpoint descriptor");
            let HIDDescriptor: Array<Uint8Array>;
            const endPointDescriptors = [];
            if (Number(nextDescriptor[5]) === SplitDeviceConsts.INTERFACE_TYPE.HID) {
               //Cache the HID descriptor
               HIDDescriptor = this._readInterfaceDescriptor(inputConfig, this._readLength);
               this.logger.debug("HIDDescriptor: " + HIDDescriptor);
            }
            const endPointsNum = Number(nextDescriptor[4]);
            let epDescriptor;
            for (let i = 0; i < endPointsNum; i++) {
               epDescriptor = this._readInterfaceDescriptor(inputConfig, this._readLength);
               endPointDescriptors.push(epDescriptor);
               this.logger.debug("endpoint descriptor #" + i + " - " + epDescriptor);
            }
            this._cacheDescriptor(cachedDescriptors, nextDescriptor, HIDDescriptor, endPointDescriptors);
         } else {
            //don't cache excluded descriptor
            this.logger.debug("Remove the invalid interface");
         }
      }

      this.logger.debug("Rewrite descriptor using faked and cached descriptors");
      for (let i = 0; i < interfacesAmount; i++) {
         if (this.excludedInterfaces.indexOf(i) === -1) {
            //Append the cached descriptor
            const cachedInfo = this._getDescriptorInCache(cachedDescriptors, i);
            res = this._uint8ArrayConcat(res, cachedInfo.descriptor);
            descriptorCount += 1;
            if (cachedInfo.HIDDescriptor) {
               res = this._uint8ArrayConcat(res, cachedInfo.HIDDescriptor);
               descriptorCount += 1;
               this.logger.debug("cached HID descriptor added");
            }
            if (cachedInfo.endPointDescriptors) {
               cachedInfo.endPointDescriptors.forEach((epDescriptor) => {
                  res = this._uint8ArrayConcat(res, epDescriptor);
                  epDescriptorCount += 1;
                  this.logger.debug("cached endpoint descriptor added");
               });
            }
         } else {
            //Append a fake descriptor to the array
            const fakedDescriptor = new Uint8Array(SplitDeviceConsts.FAKE_INTERFACE);
            //Rewrite the interface number
            fakedDescriptor[2] = i;
            res = this._uint8ArrayConcat(res, fakedDescriptor);
            descriptorCount += 2;
            this.logger.debug("Faked descriptor added for interface #" + i);
         }
      }

      //The rewritten configuration contains =
      //configuration descriptor + faked descriptors + endpoint descriptors
      const length = (descriptorCount + 1) * 9 + SplitDeviceConsts.END_POINT_DESCRIPTOR.length * epDescriptorCount;
      this._covertStringToHex(length);
      //Add configuration descriptor at the beginning, it contains the adjusted length
      configurationHeader[2] = this._lengthAfterRewrite[0];
      configurationHeader[3] = this._lengthAfterRewrite[1];
      res = this._uint8ArrayConcat(configurationHeader, res);
      this.logger.debug("Header with adjusted length data added: " + length);

      this._rewroteDescriptor = res;
      return new USBInTransferResult("ok", new DataView(res.buffer));
   };

   private _covertStringToHex = (input): void => {
      let lengthHex = input.toString(16);
      if (lengthHex.length === 3) {
         lengthHex = "0" + lengthHex;
      }
      this._lengthAfterRewrite[0] = parseInt(lengthHex.slice(0, 2), 16);
      this._lengthAfterRewrite[1] = parseInt(lengthHex.slice(2, 4), 16);
   };

   public rewriteDeviceDescriptorLength = (input) => {
      this.logger.debug("Rewrite device descriptor's length");
      const inputConfig = new Uint8Array(input.data.buffer);
      if (inputConfig[1] === SplitDeviceConsts.DESCRIPTOR_TYPE.CONFIGURATION_DESCRIPTOR) {
         inputConfig[2] = this._lengthAfterRewrite[0];
         inputConfig[3] = this._lengthAfterRewrite[1];
      }
      return new USBInTransferResult("ok", new DataView(inputConfig.buffer));
   };

   public getFakedData = (): USBInTransferResult => {
      this.logger.debug("Return rewrite descriptor");
      return new USBInTransferResult("ok", new DataView(this._rewroteDescriptor.buffer));
   };

   public getFakedDescriptorLength = (): number => {
      this.logger.debug("Faked descriptor length: " + this._rewroteDescriptor.buffer.byteLength);
      return this._rewroteDescriptor.buffer.byteLength;
   };

   public handleUrbMessage = (vUrb, packet, callback, device?) => {
      if (vUrb.itemType === UsbProtocol.VHUBITEM.Urb) {
         switch (vUrb.UrbHeader.function) {
            case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_DEVICE:
               if (vUrb.cntlDesc.DescriptorType === UsbProtocol.DESCRIPTOR.USB_CONFIGURATION_DESCRIPTOR_TYPE) {
                  return this.handleGetConfigDesc(vUrb, packet, callback);
               }
               return false;
            case UsbProtocol.URB_FUNCTION.GET_DESCRIPTOR_FROM_INTERFACE:
               // Need to check whether USB_HID_DESCRIPTOR_TYPE or USB_INTERFACE_DESCRIPTOR_TYPE
               if (vUrb.cntlDesc.DescriptorType === UsbProtocol.DESCRIPTOR.USB_HID_REPORT_TYPE) {
                  return this.handleInterDesc(vUrb, packet, callback);
               }
               return false;
            case UsbProtocol.URB_FUNCTION.CONTROL_TRANSFER:
               return this.handleControlTransfer(vUrb, packet, callback);
            case UsbProtocol.URB_FUNCTION.CLASS_INTERFACE:
               return this.handleCntlVendorClassTransfer(vUrb, packet, callback, device);
            default:
               return false;
         }
      }
      return false;
   };

   private handleGetConfigDesc = (vUrb, packet, callback): boolean => {
      try {
         const mock = new Uint8Array(this._rewroteDescriptor.buffer.slice(0, vUrb.urb.transferBuffer2Length));
         const reply = UsbProtocol.constructGetDescriptorReply(vUrb, mock, packet);
         util.dumpRawDesc("URB_FUNCTION.GET_DESCRIPTOR_FROM_DEVICE", new DataView(mock.buffer), true);
         callback(reply);
         return true;
      } catch (e) {
         this.logger.error(e);
      }
      return false;
   };

   private handleInterDesc = (vUrb, packet, callback): boolean => {
      if (this.excludedInterfaces.indexOf(vUrb.cntlDesc.LanguageId) !== -1) {
         this.logger.info("return faked HID Report");
         const mock = new Uint8Array(SplitDeviceConsts.fakeHIDReport);
         const reply = UsbProtocol.constructGetDescriptorReply(vUrb, mock, packet);
         util.dumpRawDesc("URB_FUNCTION.GET_DESCRIPTOR_FROM_INTERFACE", new DataView(mock.buffer), true);
         callback(reply);
         return true;
      }
      return false;
   };

   private handleControlTransfer = (vUrb, packet, callback): boolean => {
      if (
         vUrb.cntlTransfer.bRequest === 0x06 &&
         vUrb.cntlTransfer.wValue === UsbProtocol.DESCRIPTOR.USB_CONFIGURATION_DESCRIPTOR_TYPE
      ) {
         const mock = new Uint8Array(this._rewroteDescriptor.buffer.slice(0, vUrb.urb.transferBuffer2Length));
         const reply = UsbProtocol.constructControlTransferReply(vUrb, mock, 0);
         util.dumpRawDesc("URB_FUNCTION.CONTROL_TRANSFER", new DataView(mock.buffer), true);
         callback(reply);
         return true;
      } else if (
         vUrb.cntlTransfer.bRequest === 0x0a &&
         vUrb.cntlTransfer.wValue === UsbProtocol.DESCRIPTOR.USB_HID_REPORT_TYPE
      ) {
         const mock = new Uint8Array(SplitDeviceConsts.fakeHIDReport);
         const reply = UsbProtocol.constructGetDescriptorReply(vUrb, mock, packet);
         util.dumpRawDesc("URB_FUNCTION.CONTROL_TRANSFER", new DataView(mock.buffer), true);
         callback(reply);
         return true;
      }
      return false;
   };

   private handleCntlVendorClassTransfer = async (vUrb, packet, callback, device) => {
      switch (vUrb.CntlVendorClass.Request) {
         case UsbProtocol.USB_HID_COMMAND_TYPE.GET_REPORT: {
            this.logger.debug("Handle GET_REPORT, use common handler");
            break;
         }
         case UsbProtocol.USB_HID_COMMAND_TYPE.SET_REPORT: {
            this.logger.debug("Handle SET_REPORT, use split device handler");
            const data_length = packet.bytesRemaining();
            const toUsb = new ArrayBuffer(data_length);
            const dataView = new Uint8Array(toUsb, 0, packet.bytesRemaining());
            const data = packet.readArray(packet.bytesRemaining());
            if (data) {
               for (let i = 0; i < data.length; i++) {
                  dataView[i] = data[i];
               }
            }
            const setup: USBControlTransferParameters = {} as USBControlTransferParameters;
            setup.request = vUrb.CntlVendorClass.Request;
            setup.value = vUrb.CntlVendorClass.Value;
            setup.index = vUrb.CntlVendorClass.Index;
            setup.recipient = "interface";
            setup.requestType = "class";
            await device.controlTransferOut(setup, toUsb);
            const reply = UsbProtocol.constructGetClassInterfaceReply(vUrb, dataView, 0);
            callback(reply);
            return true;
         }
         case UsbProtocol.USB_HID_COMMAND_TYPE.SET_IDLE: {
            this.logger.debug("Handle SET_IDLE, use common handler");
            break;
         }
         default: {
            this.logger.debug("Not supported message, vUrb.CntlVendorClass.Request= " + vUrb.CntlVendorClass.Request);
            break;
         }
      }
      return false;
   };

   public reWriteInterfaceConfiguration = (currentConfig) => {
      const usbConfig: HorizonUSBConfiguration = {} as HorizonUSBConfiguration;
      usbConfig.configurationValue = currentConfig.configurationValue;
      usbConfig.configurationName = currentConfig.configurationName;
      usbConfig.interfaces = [] as HorizonUSBInterface[];
      for (let i = 0; i < currentConfig.interfaces.length; i++) {
         if (this.excludedInterfaces.indexOf(i) > -1) {
            const interfaceInfo = currentConfig.interfaces[i];
            //hardcode HID interface conifguration for mocked interfaces
            interfaceInfo.alternate.interfaceClass = 0x03;
            interfaceInfo.alternate.interfaceSubclass = 0x00;
            interfaceInfo.alternate.interfaceProtocol = 0x00;
            interfaceInfo.alternate.endpoints = [] as HorizonUSBEndpoint[];
            interfaceInfo.alternates = new Array<HorizonUSBAlternateInterface>();
            interfaceInfo.alternates.push(interfaceInfo.alternate);
            usbConfig.interfaces.push(interfaceInfo);
            this.logger.debug("reWriteConfiguration - Rewrite interface conifguration for faked interface");
         } else {
            usbConfig.interfaces.push(currentConfig.interfaces[i]);
            this.logger.debug("reWriteConfiguration - Keep interface conifguration for remaining interface");
         }
      }
      return usbConfig as USBConfiguration;
   };
}
