/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { JwtHelperService } from "@auth0/angular-jwt";
import { Injectable } from "@angular/core";
import { TitanRefreshTokenPayload } from "./restful/restful.module";
import { EventBusService, TitanMsg, LocalStorageService } from "@html-core";
import * as CST from "@html-core";
import { globalArray } from "../../shared/jscdk/jscdkClient";
import { TitanTokenStorage } from "./titan-secure-storage.service";
import { BrokerSessionStorageService } from "../../core/services/storage/broker-session-storage.service";
@Injectable({
   providedIn: "root"
})
export class TitanTokenService {
   private static readonly ID_TOKEN = "id_token";
   private static readonly ACCESS_TOKEN = "access_token";
   private static readonly REFRESH_TOKEN = "refresh_token";
   private static readonly DSPEC_TOKEN = "dspec_token";
   private static readonly IDP_TYPE = "idp_type";
   private static readonly IDP_TENANT_DOMAIN = "idp_tenant_domain";
   private static readonly VANITY = "vanity";
   private static readonly WS1_HORIZON_ID = "ws1_horizon_id";
   private static readonly KEEP_SIGNED_IN = "keep_signed_in";
   private static readonly AUTO_SIGN_OUT = "auto_sign_out";
   public refreshToken = null;
   public accessToken = null;
   public idToken = null;
   private useLocalStorage = CST.clientUtil.isChromeClient();

   constructor(
      private titanTokenStorage: TitanTokenStorage,
      private jwtHelper: JwtHelperService,
      private eventBusService: EventBusService,
      private brokerSessionStorageService: BrokerSessionStorageService,
      private localStorageService: LocalStorageService
   ) {
      this.eventBusService.listen(TitanMsg.TitanSessionExpiredMsg.MSG_TYPE).subscribe(() => {
         this.removeAccessToken();
      });
   }

   private uuidv4 = (): string => {
      const idToken = this.getIdToken();
      let index = 0;
      /* cspell: disable-next-line */
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
         const r = (idToken.charCodeAt(index) + idToken.charCodeAt(index + 1)) % 16 | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
         index += 2;
         return v.toString(16);
      });
   };

   public getAccessToken = (): string => {
      if (this.accessToken) {
         return this.accessToken;
      } else {
         return this.getFromStorage(TitanTokenService.ACCESS_TOKEN);
      }
   };

   public getRefreshToken = (): string => {
      if (this.refreshToken) {
         return this.refreshToken;
      } else {
         return this.getFromStorage(TitanTokenService.REFRESH_TOKEN);
      }
   };

   public saveTokensForKeepSignedIn = () => {
      this.saveToStorage(TitanTokenService.ACCESS_TOKEN, this.accessToken);
      this.saveToStorage(TitanTokenService.REFRESH_TOKEN, this.refreshToken);
      this.saveToStorage(TitanTokenService.ID_TOKEN, this.idToken);
   };

   public removeTokensForKeepSignedIn = () => {
      this.removeFromStorage(TitanTokenService.REFRESH_TOKEN);
      this.removeFromStorage(TitanTokenService.ACCESS_TOKEN);
      this.removeFromStorage(TitanTokenService.ID_TOKEN);
   };

   public setToken = (obj: TitanRefreshTokenPayload) => {
      this.refreshToken = obj.refresh_token;
      this.accessToken = obj.access_token;
      this.idToken = obj.id_token;
      if (this.getKeepSignedIn()) {
         this.saveToStorage(TitanTokenService.REFRESH_TOKEN, obj.refresh_token);
         this.saveToStorage(TitanTokenService.ACCESS_TOKEN, obj.access_token);
         this.saveToStorage(TitanTokenService.ID_TOKEN, obj.id_token);
      }
      this._storeUserInfo();
   };

   public removeAccessToken = () => {
      this.removeFromStorage(TitanTokenService.ACCESS_TOKEN);
      this.removeFromStorage(TitanTokenService.REFRESH_TOKEN);
      this.removeFromStorage(TitanTokenService.ID_TOKEN);
      this.titanTokenStorage.remove(TitanTokenService.WS1_HORIZON_ID);
      this.titanTokenStorage.remove(CST.COOKIE.USER_NAME);
      this.titanTokenStorage.remove(CST.COOKIE.DOMAIN_NAME);
      this.refreshToken = null;
      this.accessToken = null;
      this.idToken = null;
   };

   public setWSOneHorizonId = (id: string) => {
      this.titanTokenStorage.set(TitanTokenService.WS1_HORIZON_ID, id);
   };

   public getWSOneHorizonId = (): string => {
      return this.titanTokenStorage.get(TitanTokenService.WS1_HORIZON_ID);
   };

   public getIdToken = (): string => {
      if (this.idToken) {
         return this.idToken;
      } else {
         return this.getFromStorage(TitanTokenService.ID_TOKEN);
      }
   };

   public setDSpecToken = (spec: DSpecToken) => {
      globalArray["dspec_token"] = JSON.stringify(spec);
      return this.titanTokenStorage.setSession(TitanTokenService.DSPEC_TOKEN, JSON.stringify(spec));
   };

   public removeDSpecToken = () => {
      globalArray["dspec_token"] = null;
      return this.titanTokenStorage.removeSession(TitanTokenService.DSPEC_TOKEN);
   };

   public getHorizonId = (): string => {
      return this.uuidv4();
   };

   public getDomain = (): string => {
      return this.getClaimFromAccessToken("domain");
   };

   public getUserDomain = (): string => {
      return this.getClaimFromAccessToken("userDomain");
   };

   public getUsername = (): string => {
      return this.getClaimFromAccessToken("userName");
   };

   private _storeUserInfo = () => {
      if (!CST.clientUtil.isChromeClient()) {
         return;
      }
      // Store user info in the local storage for chrome native client ONLY.
      const username = this.getUsername();
      if (username) {
         this.titanTokenStorage.set(CST.COOKIE.USER_NAME, username);
      }

      //Current domain name
      const domain = this.getUserDomain();
      if (domain) {
         this.titanTokenStorage.set(CST.COOKIE.DOMAIN_NAME, domain);
      }
   };

   public getUserPrincipalName = (): string => {
      return this.getClaimFromAccessToken("userPrincipalName");
   };

   public getUserName = (): string => {
      return this.getClaimFromAccessToken("givenName");
   };

   public getKeepSignedIn = (): boolean => {
      if (CST.clientUtil.isChromeClient()) {
         // Don't enable the feature in web in Q2, always need to relogin again
         return false;
         // return this.titanTokenStorage.get(TitanTokenService.KEEP_SIGNED_IN);
      } else {
         // Don't enable the feature in web in Q2
         return true;
      }
   };

   public setKeepSignedIn = (isKeepSignedIn: boolean) => {
      this.titanTokenStorage.set(TitanTokenService.KEEP_SIGNED_IN, isKeepSignedIn);
   };

   public getClaimFromAccessToken = (key: string): string => {
      const accessToken = this.getAccessToken();
      let keyValue: string = null;
      if (accessToken) {
         const parsedToken = this.jwtHelper.decodeToken(accessToken);
         if (parsedToken && parsedToken.context && parsedToken.context[key]) {
            keyValue = parsedToken.context[key];
         }
      }
      return keyValue;
   };

   public getIdpType = (): string => {
      return this.titanTokenStorage.get(TitanTokenService.IDP_TYPE);
   };

   public removeSessionTenantDomain = () => {
      this.titanTokenStorage.remove(TitanTokenService.IDP_TENANT_DOMAIN);
   };

   public setVanity = (vanity: string) => {
      if (vanity) {
         this.titanTokenStorage.set(TitanTokenService.VANITY, vanity);
      }
   };

   public getVanity = (): string => {
      return this.titanTokenStorage.get(TitanTokenService.VANITY);
   };

   public setTitanAutoSignOut = (isSignOut: string) => {
      this.titanTokenStorage.set(TitanTokenService.AUTO_SIGN_OUT, isSignOut);
   };

   public getTitanAutoSignOut = () => {
      return this.titanTokenStorage.get(TitanTokenService.AUTO_SIGN_OUT);
   };

   public removeVanity = () => {
      this.titanTokenStorage.remove(TitanTokenService.VANITY);
   };

   public removeIdpAuthInfo = () => {
      this.titanTokenStorage.remove(TitanTokenService.IDP_TYPE);
      this.removeSessionTenantDomain();
      this.removeVanity();
   };

   public removeAll = () => {
      this.removeTokens();
      this.removeIdpAuthInfo();
      // clear brokerSessionStorage for previous broker session exist no more
      this.brokerSessionStorageService.clear();
      this.localStorageService.clearSession();
   };

   public removeTokens = () => {
      this.removeAccessToken();
      this.removeDSpecToken();
   };

   private saveToStorage(key: string, value: any) {
      if (this.useLocalStorage) {
         this.titanTokenStorage.set(key, value);
      } else {
         this.titanTokenStorage.setSession(key, value);
      }
   }

   private getFromStorage = (key: string): string => {
      if (this.useLocalStorage) {
         return this.titanTokenStorage.get(key);
      } else {
         return this.titanTokenStorage.getSession(key);
      }
   };

   private removeFromStorage(key: string) {
      // Remove value from both localStorage and sessionStorage
      this.titanTokenStorage.remove(key);
      this.titanTokenStorage.removeSession(key);
   }
}
