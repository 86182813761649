/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * An index set for shared state fields. Requires atomic access.
 * @enum {number}
 */
const States = {
   /** @type {number} A shared index for reading from the queue. (consumer) */
   Read: 0,
   /** @type {number} A shared index for writing into the queue. (producer) */
   Write: 1
};

/**
 * A shared storage for FreeQueue operation backed by SharedArrayBuffer.
 *
 * @typedef SharedBuffer
 * @property {Uint32Array} states Backed by SharedArrayBuffer.
 * @property {Array<Float32Array>} channelData The length must be > 0.
 * @property {number} length The frame buffer length. Should be identical
 *   throughout channels.
 * @property {number} channelCount same with channelData.length
 */

/**
 * This is a lock-free FIFO queue designed for single-producer/single-consumer scenarios,
 * utilizing SharedArrayBuffer for storage. Commonly, this setup is used in a pattern
 * where a worklet retrieves data from the queue, while a worker processes and supplies
 * audio data to populate the queue.
 */
export class FreeQueue {
   /**
    * A static SharedBuffer factory. A shared buffer created by this factory
    * will be shared between two threads.
    *
    * @param {number} length Frame buffer length.
    * @param {number} channelCount Total channel count.
    * @return {SharedBuffer}
    */
   static createSharedBuffer(length, channelCount) {
      const states = new Uint32Array(new SharedArrayBuffer(Object.keys(States).length * Uint32Array.BYTES_PER_ELEMENT));
      // Priming the buffer by moving the write index to the middle of buffer.
      states[States.Write] = Math.ceil(length * 0.5);
      const channelData = Array.from(
         { length: channelCount },
         () => new Float32Array(new SharedArrayBuffer((length + 1) * Float32Array.BYTES_PER_ELEMENT))
      );
      return { states, channelData, length, channelCount };
   }

   /**
    * @param {!SharedBuffer} sharedBuffer A backing SharedBuffer object.
    */
   constructor(sharedBuffer) {
      this._states = sharedBuffer.states;
      this._channelData = sharedBuffer.channelData;
      // Note that the allocated buffer has one extra bin.
      this._bufferLength = sharedBuffer.length + 1;
      this._channelCount = sharedBuffer.channelCount;
   }

   _reset() {
      this._channelData.forEach((channel) => channel.fill(0));
      Atomics.store(this._states, States.Read, 0);
      Atomics.store(this._states, States.Write, 0);
   }

   /**
    * Pushes the data into queue. Used by producer.
    *
    * @param {Array<Float32Array>} input Its length must match with the channel
    *   count of this queue.
    * @param {number} blockLength Input block frame length. It must be identical
    *   throughout channels.
    * @return {boolean} False if the operation fails.
    */
   push(input, blockLength) {
      const [currentWrite, currentRead] = [States.Write, States.Read].map((state) => Atomics.load(this._states, state));
      if (this._getAvailableWrite(currentWrite, currentRead) < blockLength) {
         return false;
      }
      let nextWrite = currentWrite + blockLength;
      if (this._bufferLength < nextWrite) {
         nextWrite -= this._bufferLength;
         for (let channel = 0; channel < this._channelCount; ++channel) {
            const blockA = this._channelData[channel].subarray(currentWrite);
            const blockB = this._channelData[channel].subarray(0, nextWrite);
            blockA.set(input[channel].subarray(0, blockA.length));
            blockB.set(input[channel].subarray(blockA.length));
         }
      } else {
         for (let channel = 0; channel < this._channelCount; ++channel) {
            this._channelData[channel].subarray(currentWrite, nextWrite).set(input[channel]);
         }
         if (nextWrite === this._bufferLength) {
            nextWrite = 0;
         }
      }

      Atomics.store(this._states, States.Write, nextWrite);
      return true;
   }

   /**
    * Pulls data out of the queue. Used by consumer.
    *
    * @param {Array<Float32Array>} output Its length must match with the channel
    *   count of this queue.
    * @param {number} blockLength output block length. It must be identical
    *   throughout channels.
    * @return {boolean} False if the operation fails.
    */
   pull(output, blockLength) {
      const [currentWrite, currentRead] = [States.Write, States.Read].map((state) => Atomics.load(this._states, state));
      const availableRead = this._getAvailableRead(currentWrite, currentRead);
      let nextRead = currentRead + Math.min(availableRead, blockLength);
      if (this._bufferLength < nextRead) {
         nextRead -= this._bufferLength;
         for (let channel = 0; channel < this._channelCount; ++channel) {
            const blockA = this._channelData[channel].subarray(currentRead);
            const blockB = this._channelData[channel].subarray(0, nextRead);
            output[channel].set(blockA);
            output[channel].set(blockB, blockA.length);
         }
      } else {
         for (let channel = 0; channel < this._channelCount; ++channel) {
            output[channel].set(this._channelData[channel].subarray(currentRead, nextRead));
         }
         if (nextRead === this._bufferLength) {
            nextRead = 0;
         }
      }

      Atomics.store(this._states, States.Read, nextRead);
      return true;
   }

   /**
    * Gets an estimate of the current amount of samples available to read
    * from the queue.
    *
    * @return {number}
    */
   getAvailableRead() {
      const currentWrite = Atomics.load(this._states, States.Write);
      const currentRead = Atomics.load(this._states, States.Read);
      return this._getAvailableRead(currentWrite, currentRead);
   }

   getBufferLength() {
      return this._bufferLength - 1;
   }

   _getAvailableWrite(writeIndex, readIndex) {
      const availableWrite = readIndex - writeIndex - 1;
      return availableWrite <= -1 ? availableWrite + this._bufferLength : availableWrite;
   }

   _getAvailableRead(writeIndex, readIndex) {
      const availableRead = writeIndex - readIndex;
      return availableRead >= 0 ? availableRead : availableRead + this._bufferLength;
   }
}
