/**
 * *************************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview rtav-status.ts
 *
 * Class that contains functions and logic for managing and formating RTAV configurable settings
 * @type {function}
 * It hasn't been fully implemented yet
 */

import { Injectable } from "@angular/core";
import { AVPluginState } from "./rtav.constants";
import { Logger } from "@html-core";

@Injectable()
export class RtavStatus {
   public currentStatus;

   constructor() {
      this.currentStatus = this.set("STChannelClose");
   }

   public set(statusName) {
      if (!AVPluginState.hasOwnProperty(statusName)) {
         Logger.error("bad statusName to be set for rtav session", Logger.RTAV);
      }
      this.currentStatus = AVPluginState[statusName];
      Logger.debug("status changed to " + statusName, Logger.RTAV);
   }
}
