/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * samlHandler.js --
 *
 *     handle SAML authentication
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function SamlHandler() {
   let router;

   // member variables below
   this.messageName = "saml";
   this.messageText = "saml";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
SamlHandler.prototype = new MessageHandler();
// constructor
SamlHandler.constructor = SamlHandler;

/**
 * Set the request XML for saml authentication.
 *
 * @param secret [in] base64-encoded SAML artifact.
 */

SamlHandler.prototype.setRequestXML = function (secret) {
   const nameText = util.createElement("name", "artifact");
   const secretText = util.createElement("values", util.createElement("value", secret));
   const paramText = util.createElement("param", nameText + secretText);
   const paramsText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", "saml");
   this.requestXML += paramsText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
