/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * share-folder-model.js
 *
 * per device per user model for share folders.
 *
 * init itself from storage with logged in user name
 * can add/remove folder and sync back to storage, and reject invalid adding(repeat, nested)
 * can check folder status
 *
 * This class suffer from race condition in theory, so UI reference must use await
 * and disable controls.
 *
 */
import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "@html-core";

@Injectable({
   providedIn: "root"
})
export class PreDataSetModel {
   private settingKeys = [
      "dataSharingAllowed",
      "anonymousLogin",
      "isCollectLogEnabled",
      "displaySetting",
      "selectedMonitors",
      "disableNetworkStateDisplay"
   ];
   public settingData = {};
   constructor(private localStorageService: LocalStorageService) {}

   public getAllDataValue = () => {
      const promises = this.settingKeys.map((key) => {
         return new Promise((resolve, reject) => {
            const value = this.localStorageService.get(key);
            this.updateValue(key, value);
            // @ts-ignore
            resolve();
         });
      });

      return Promise.all(promises).then((values) => {
         Logger.info("Get all local pre setting data.");
      });
   };

   public updateValue = (key, value) => {
      switch (key) {
         case "dataSharingAllowed":
            // Default value is true
            if (value === true || value === "true" || value === undefined || value === null) {
               this.settingData[key] = true;
            } else {
               this.settingData[key] = false;
            }
            break;
         case "anonymousLogin":
            // Default value is false
            if (value === true || value === "true") {
               this.settingData[key] = true;
            } else {
               this.settingData[key] = false;
            }
            break;
         case "isCollectLogEnabled":
            // Default value is false
            if (value === true || value === "true") {
               this.settingData[key] = true;
            } else {
               this.settingData[key] = false;
            }
            break;
         case "displaySetting":
            this.settingData[key] = value;
            break;
         case "selectedMonitors":
            this.settingData[key] = value;
            break;
         case "disableNetworkStateDisplay":
            if (value === true || value === "true") {
               this.settingData[key] = true;
            } else {
               this.settingData[key] = false;
            }
            break;
      }
   };

   public setDataValue = (key, value) => {
      this.localStorageService.set(key, value);
      this.settingData[key] = value;
   };

   public getDataValue = (key) => {
      return this.localStorageService.get(key);
   };
}
