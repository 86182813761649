/**
 * ******************************************************
 * Copyright (C) 2014-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * setLastUserActivityHandler.js --
 *
 *      Implementation of the message handler to set last user activity time.
 */

import $ from "jquery";
import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import { MessageHandler, StateEnum } from "./messageHandler";

export default function SetLastUserActivityHandler() {
   let router;

   // member variables
   this.messageName = "set-last-user-activity";
   this.messageText = "set-last-user-activity";
   this.responseTag = "set-last-user-activity";

   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
SetLastUserActivityHandler.prototype = new MessageHandler();
SetLastUserActivityHandler.constructor = SetLastUserActivityHandler;

/**
 * Set the request XML for set last activity.
 *
 * @param inactiveTime [in] t1-t0, in which t0 is the last time user have
 *    activities, t1 is current time, both in seconds from 1970.
 */
SetLastUserActivityHandler.prototype.setRequestXML = function (inactiveTime) {
   this.requestXML = util.createElement("last-user-activity", inactiveTime);
};

/**
 * callback when received notification from handlers in dependency list or
 * network
 *
 */
SetLastUserActivityHandler.prototype.onUpdated = function () {
   let parsedResult,
      errorInfo,
      errorCode,
      router = util.getObject(globalArray, "router");

   MessageHandler.prototype.onUpdated.apply(this); // call parent class's onUpdated

   if (this.content["error"]) {
      parsedResult = this.content["error"];
      if (parsedResult[this.responseTag]) {
         errorInfo = parsedResult[this.responseTag];
         errorCode = errorInfo["error-code"] || errorInfo["errorCode"];
         if (errorCode === "OUT_OF_SEQUENCE_ERROR" || errorCode === "NOT_AUTHENTICATED") {
            this.setState(StateEnum.DONE);
            Logger.debug("ignore error: " + errorCode);
         }
      }
   }

   // ensure handler has done its task and request is from "WindowsPassword" to
   // JSCDK
   if (this.state === StateEnum.DONE) {
      Logger.debug("SetLastUserActivity success"); //ok
   }
   // push error to UI
   router.pushErrorToUser(this);
};

/**
 * parse desktops information from the response XML
 *
 * @param responseXML[in] response of xmlhttprequest from view broker
 * @return key-value pairs parsed from response, if error then return null
 */
SetLastUserActivityHandler.prototype.parseResult = function (responseXML) {
   let responseBody,
      brokerTag,
      responses,
      resultText,
      setLastUserActivityResponse = {};

   if (!responseXML || responseXML === "") {
      return null;
   }
   responseBody = $(responseXML);
   brokerTag = $(responseBody.children()[0]);
   if (!brokerTag) {
      Logger.error("response of getAuthenticationStatus error");
      return null;
   }
   responses = brokerTag.children(this.responseTag);
   resultText = responses.children("result").text();
   if (!!resultText && resultText.trim() !== "") {
      setLastUserActivityResponse["result"] = resultText;
   } else {
      Logger.error("response of getAuthenticationStatus error, no valid result tag");
      return null;
   }
   //no need to deal with 'ok'
   if (resultText === "error") {
      responses.children().each(function () {
         const nameText = this.localName || this.baseName;
         util.addItemForJson(setLastUserActivityResponse, nameText, $(this).text());
      });
   }
   return setLastUserActivityResponse;
};
