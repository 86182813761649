/** @format */

import { Injectable } from "@angular/core";
import { Logger } from "@html-core";
import { CDRInfoInIndexedDB } from "../../../shared/desktop/cdr/cdr-lib/cdr-interface";

@Injectable({
   providedIn: "root"
})
class HorizonIndexedDB {
   INDEXED_DB_VERSION = 1;
   private openIndexedDB(dataBaseName: string): IDBOpenDBRequest {
      //@ts-ignore
      const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
      const request: IDBOpenDBRequest = indexedDB.open(dataBaseName, this.INDEXED_DB_VERSION);
      return request;
   }

   public get = (dataBaseName: string, storeName: string, key: string): Promise<Array<DirectoryEntry>> => {
      return new Promise((resolve, reject) => {
         const request = this.openIndexedDB(dataBaseName);
         if (request) {
            request.onerror = (event) => {
               //@ts-ignore
               Logger.error("Database error: " + event.target.errorCode);
               resolve([]);
            };
            request.onsuccess = (event) => {
               Logger.debug(dataBaseName + " - Success to open indexDB.");
               const db = request.result;
               if (db && db.objectStoreNames.contains(storeName)) {
                  const store = db.transaction(storeName, "readonly").objectStore(storeName);
                  try {
                     const openCursor = store.openCursor();
                     openCursor.onerror = (event) => {
                        Logger.error("Open cursor failed");
                        resolve([]);
                     };
                     openCursor.onsuccess = (event) => {
                        const target = event.target as IDBRequest;
                        const cursor = target.result;
                        if (cursor) {
                           if (cursor.key && cursor.key !== "") {
                              try {
                                 if (cursor.value.folderId === key) {
                                    resolve(cursor.value);
                                    return;
                                 }
                              } catch (e) {
                                 resolve([]);
                                 Logger.warning("Read form index fails.");
                                 Logger.exception(e);
                              }
                           }
                           cursor.continue();
                        } else {
                           Logger.debug("Get from indexDB ends.");
                        }
                     };
                  } catch (e) {
                     resolve([]);
                     Logger.debug(e);
                  }
               } else {
                  Logger.info(storeName + " doesn't exist in indexedDB");
                  resolve([]);
               }
            };
            request.onupgradeneeded = (event) => {
               const db = request.result;
               if (db && !db.objectStoreNames.contains(storeName)) {
                  const store = db.createObjectStore(storeName, { keyPath: "folderId" });
                  Logger.debug("Success to create indexDB for " + dataBaseName);
                  store.createIndex("key", "folderId", { unique: true });
                  const defaultValue = {
                     folderId: key,
                     folderEntry: [],
                     version: this.INDEXED_DB_VERSION
                  };
                  const writeData = store.put(defaultValue);
                  writeData.onsuccess = () => {
                     Logger.debug(key + " database initiation succeeds.");
                     resolve([]);
                  };
                  writeData.onerror = () => {
                     Logger.error(key + " database initiation fails.");
                     resolve([]);
                  };
               }
            };
         } else {
            Logger.info(dataBaseName + " indexedDB open failed");
            resolve([]);
         }
      });
   };

   public set = (dataBaseName: string, storeName: string, key: string, value: CDRInfoInIndexedDB): Promise<boolean> => {
      return new Promise((resolve, reject) => {
         const request = this.openIndexedDB(dataBaseName);
         if (request) {
            request.onerror = (event) => {
               //@ts-ignore
               Logger.error("Database error: " + event.target.errorCode);
               resolve(false);
            };
            request.onsuccess = (event) => {
               Logger.debug(dataBaseName + " - Success to open indexDB.");
               const db = request.result;
               if (db) {
                  if (db.objectStoreNames.contains(storeName)) {
                     const tx = db.transaction(storeName, "readwrite");
                     const store = tx.objectStore(storeName);
                     const index = store.index("key");
                     const readData = index.get(key);
                     readData.onsuccess = (event) => {
                        const target = event.target as IDBRequest;
                        const result = target.result;
                        if (result) {
                           if (key === result.folderId) {
                              const writeData = store.put(value);
                              writeData.onsuccess = () => {
                                 Logger.debug("Update succeeds. Key: " + key, " value: " + JSON.stringify(value));
                                 resolve(true);
                              };
                              writeData.onerror = () => {
                                 Logger.error("Update failed. Key: " + key, " value: " + JSON.stringify(value));
                                 resolve(false);
                              };
                           }
                        } else {
                           Logger.debug("User's info doesn't exists, add a new one: " + key);
                           const writeData = store.put(value);
                           writeData.onsuccess = () => {
                              Logger.debug("Update succeeds. Key: " + key, " value: " + JSON.stringify(value));
                              resolve(true);
                           };
                           writeData.onerror = () => {
                              Logger.error("Update failed. Key: " + key, " value: " + JSON.stringify(value));
                              resolve(false);
                           };
                        }
                     };
                     readData.onerror = (event) => {
                        Logger.error("Read data failed: ");
                        resolve(false);
                     };
                  } else {
                     resolve(false);
                     Logger.debug("Read file entry failed, entry doesn't exist ");
                  }
               }
            };
            request.onupgradeneeded = (event) => {
               // The database did not previously exist, so create object stores and indexes.
               Logger.debug("indexedDb onupgradeneeded is triggered");
               const db = request.result;
               if (db && !db.objectStoreNames.contains(storeName)) {
                  const store = db.createObjectStore(storeName, { keyPath: "folderId" });
                  Logger.debug("Success to create indexDB for " + dataBaseName);
                  store.createIndex("key", "folderId", { unique: true });
                  const writeData = store.put(value);
                  writeData.onsuccess = () => {
                     Logger.debug("Update succeeds. Key: " + key, " value: " + JSON.stringify(value));
                     resolve(true);
                  };
                  writeData.onerror = () => {
                     Logger.error("Update failed. Key: " + key, " value: " + JSON.stringify(value));
                     resolve(false);
                  };
               } else {
                  Logger.info("Failed on update to indexedDB: " + key, " value: " + JSON.stringify(value));
                  resolve(false);
               }
            };
         }
      });
   };
}

export const HorizonIndexedDBService = new HorizonIndexedDB();
