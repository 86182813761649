/**
 * ******************************************************
 * Copyright (C) 2014-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * applicationsessions-model.js --
 *
 * Module to provide application sessions information to decide session usage
 * when launching from Sidebar, this should be realized in commen folder as the
 * model for manipulate items, but put it here for now.
 *
 * The async API of getApplicationSessions is caused by using the jscdk to get
 * the list from the broker.
 */

import { Injectable } from "@angular/core";

import Logger from "../../../core/libs/logger";
import { CLIENT, clientUtil } from "@html-core";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";

@Injectable({
   providedIn: "root"
})
export class ApplicationSessionsModel {
   private applicationSessions = null;
   private callbackBuff = null;
   constructor(
      private viewClientModel: ViewClientModel,
      private jscdkCommonInvoker: JscdkCommonInvoker
   ) {}

   /**
    * should be in the commen codes, but put it here now
    */
   public _getApplicationSessions = (data, targetList) => {
      let applicationSessionSet, applicationSessionKey;
      if (data && data.content) {
         //store the application-sessions localContent
         applicationSessionSet = data.content.applicationSessions;
         for (applicationSessionKey in applicationSessionSet) {
            /* istanbul ignore else */
            if (applicationSessionSet.hasOwnProperty(applicationSessionKey)) {
               targetList.push(applicationSessionSet[applicationSessionKey]);
            }
         }
      }
   };

   /**
    * handle the readed object from safe storage with key(EntitledItems),
    * and construct entitled item.
    */
   public onStorageReaded = (localContent) => {
      // set the list using localContent
      this.applicationSessions = [];
      this._getApplicationSessions(localContent, this.applicationSessions);
      // clear buff first in case callback will call
      // getApplicationSessions again
      /* istanbul ignore else */
      if (this.callbackBuff) {
         const callbackFunc = this.callbackBuff;
         this.callbackBuff = null;
         callbackFunc(this.applicationSessions);
      }
   };

   /**
    * ApplicationSessionModel.getApplicationSessions
    *
    * Return a array of application session
    *
    */
   public getApplicationSessions = (callback, jscdkHandler, useBuffedData?: any) => {
      /* istanbul ignore if */
      if (this.callbackBuff) {
         Logger.debug("Error: the getApplicationSessions do not support parallal" + " usage");
         return;
      }
      if (typeof callback !== "function") {
         Logger.debug("Error: the callback passed to getApplicationSessions is" + " not a function");
         return;
      }
      if (this.applicationSessions !== null && useBuffedData) {
         Logger.debug("applicationSessions is already readed and used");
         callback(this.applicationSessions);
      } else {
         Logger.debug("applicationSessions not fully inited");

         jscdkHandler.handlerGetLaunchItems = (localContent) => {
            this.onStorageReaded(localContent);
         };
         this.callbackBuff = callback;
         this.viewClientModel.clientInfoSubject.subscribe(() => {
            /**
             * sync model into jscdk to init environment info for get-launch-item to
             * support: https://jira-hzn.eng.vmware.com/browse/DPM-1211
             * decouple from current _getEnvironmentInfo since they are different,
             * and under current code structure better to be seperated.
             */
            const environmentInfo = {};

            if (this.viewClientModel.ipAddress) {
               environmentInfo["IP_Address"] = this.viewClientModel.ipAddress;
               environmentInfo["Machine_Name"] = this.viewClientModel.machineName;
            }
            if (this.viewClientModel.clientID) {
               environmentInfo["Client_ID"] = this.viewClientModel.clientID;
            }
            environmentInfo["Client_Version"] = this.viewClientModel.version + "-" + this.viewClientModel.buildNum;
            environmentInfo["Type"] = CLIENT.viewclientType;
            if (this.viewClientModel.AssetID) {
               environmentInfo["Asset_ID"] = this.viewClientModel.AssetID;
            }
            if (this.viewClientModel.SerialNum) {
               environmentInfo["Serial_Number"] = this.viewClientModel.SerialNum;
            }
            // Start support Rdpvcbridge for Chrome Client from version 8.4
            // Todo double confirm titan support this
            environmentInfo["RDPVCBridge.Supported"] = clientUtil.isChromeClient() ? "true" : "false";
            // fix SR 2980519, put chromebook asset ID into Machine_Name instead of machine name
            if (this.viewClientModel.useAssetIdReplaceMachineName && this.viewClientModel.AssetID) {
               environmentInfo["Machine_Name"] = this.viewClientModel.AssetID;
               Logger.info("put chromebook asset ID into Machine_Name");
            }
            this.jscdkCommonInvoker.setEnvironmentInfo(environmentInfo);
            this.jscdkCommonInvoker.sendLaunchItemsXML();
         });
      }
   };
}
