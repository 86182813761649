/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

const sideChannelSchema = {
   macros: {
      SHA_256_length: 256 / 8
   },
   structs: {
      //@ts-ignore
      WIRE_HEADER: new Map([
         ["Length", "UINT32"],
         ["Capacity", "UINT32"],
         ["Format", "UINT32"],
         ["ObjectId", "UINT32"],
         ["RequestId", "UINT32"],
         ["Command", "UINT32"],
         //@ts-ignore
         [
            "Hmac",
            [
               {
                  type: "Enable",
                  format: new Map([["data", "DATA[..SHA_256_length]"]])
               },
               {
                  type: "Disable",
                  format: new Map([["data", "NONE"]])
               }
            ]
         ]
      ]),
      RPC: new Map([
         ["Header", "WIRE_HEADER"],
         ["Payload", "DATA[UNLIMITED]"]
      ])
   }
};

export default sideChannelSchema;
