/**
 * *****************************************************
 * Copyright 2020 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedCommonModule } from "../../common/shared.common.module";
import { UsbComponent } from "./usb.component";

@NgModule({
   imports: [CommonModule, SharedCommonModule],
   declarations: [UsbComponent],
   exports: [UsbComponent]
})
export class UsbRedirectionModule {}
