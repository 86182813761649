/**
 * ******************************************************
 * Copyright (C) 2019-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * redirectHandler.js--
 *
 *      Base Class to handle redirect XMLs,
 *      it needs to able to merge the XML response from
 *      <get-XXX-connection> and <get-XXX-redirect>.
 *      and invoke the passed in foreign UAG.
 */

import { clientUtil } from "@html-core";
import Logger from "../../../../core/libs/logger";
import JSCDKBrokerError from "../../model/jscdk-broker-error.consts";
import util from "../../util";

export default function RedirectHandler() {
   this.requestTag = ""; // the XML request tag name
   this.responseTag = ""; // the XML response tag name
}
RedirectHandler.prototype = new RedirectHandler(); // RedirectHandler prototype
RedirectHandler.constructor = RedirectHandler; // constructor

const defaultErrorMessage = util
   ? util._("Fail connect to VMware Unified Access Gateway.")
   : "Fail connect to VMware Unified Access Gateway";

const getError = function (code, content?, extraDetails?) {
   content = content || defaultErrorMessage;
   const connectionError = {};
   connectionError["result"] = "error";
   connectionError["error-code"] = code;
   connectionError["error-message"] = content;
   connectionError["user-message"] = content;
   if (extraDetails) {
      connectionError["error-details"] = extraDetails;
   }
   return connectionError;
};

const getUAGErrorMsg = function (uagAddress) {
   return (
      "Fail to connect to VMware Unified Access Gateway(" +
      uagAddress +
      "). Please check the DNS or allow pop-ups in current tab and accept the self-signed certificate"
   );
};

RedirectHandler.prototype._getRedirectResponse = async function (foreignServer, redirectToken, initialRequestInfo) {
   const self = this;
   let redirectTokenElement = util.createElement("protocol-redirect-token", redirectToken);
   if (clientUtil.isTitanClient()) {
      const protocolRecovery = util.createElement("protocol-redirect-recovery", "true");
      redirectTokenElement += protocolRecovery;
   }
   // Optional: add location.hostname in the extra tag
   const requestXML = util.createElement(self.requestTag, redirectTokenElement); //including the seal logic
   let result = null;
   try {
      const response = await foreignServer.send(requestXML); //including the parse logic
      const parsedResponse = response.responseObject;
      result = parsedResponse[self.responseTag];
   } catch (e) {
      Logger.info(e);
      const errorContent = getUAGErrorMsg(foreignServer.getURL());
      if (e === "needPageJump") {
         result = getError(JSCDKBrokerError.JSCDK_BROKER_ERROR_NEED_FOREIGN_JUMP_ERROR, errorContent, {
            serverAddress: foreignServer.getURL(),
            itemDetails: {
               isApplication: initialRequestInfo.isApplication,
               id: initialRequestInfo.id,
               name: initialRequestInfo.name, // desktop only
               "origin-id": initialRequestInfo["origin-id"], // app only
               isShadow: initialRequestInfo.isShadow // desktop only
            }
         });
      } else {
         result = getError(JSCDKBrokerError.JSCDK_BROKER_ERROR_CONNECT_TO_FOREIGN_ERROR, errorContent);
      }
   }
   return result;
};

RedirectHandler.prototype._mergeResponses = function (initialResponse, redirectResponse) {
   for (const key in redirectResponse) {
      if (redirectResponse.hasOwnProperty(key)) {
         if (key === "protocol-settings") {
            initialResponse["token"] = redirectResponse[key]["token"];
         } else {
            initialResponse[key] = redirectResponse[key];
         }
      }
   }
   return initialResponse;
};

RedirectHandler.prototype.getCompleteConnectionInfo = async function (
   foreignServer,
   redirectToken,
   initialResponse,
   initialRequestInfo
) {
   const redirectResponse = await this._getRedirectResponse(foreignServer, redirectToken, initialRequestInfo);
   if (!redirectResponse || redirectResponse["error-code"]) {
      return redirectResponse;
   } else {
      const completeConnectionInfo = this._mergeResponses(initialResponse, redirectResponse);
      return completeConnectionInfo;
   }
};
