/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input } from "@angular/core";
import { LoginTypes, LoginContentData, LoginData } from "../common/content-data";
import { clientUtil, TranslateService } from "@html-core";
import { ViewClientModel } from "../../common/model/viewclient-model";
import { imageAsset } from "../../common/image-asset/image-asset";

@Component({
   selector: "login-content",
   templateUrl: "./login-content.component.html"
})
export class LoginContentComponent {
   private _contentData: LoginData;
   public loginName: LoginTypes;
   public formData: LoginContentData;
   public logoAltText: string;
   public imageAsset = imageAsset;
   constructor(
      private translate: TranslateService,
      private viewClientModel: ViewClientModel
   ) {}

   @Input() set contentData(value: LoginData) {
      if (!value) {
         this.loginName = LoginTypes["Wait"];
         this.formData = null;
         this._contentData = value;
         return;
      }
      if (!value.name) {
         this.loginName = LoginTypes["Wait"];
      } else if (value.name === "SAML" && this.loginName === LoginTypes["showWindowsPassword"]) {
         // see details in bug 2424458
         return;
      } else {
         this.loginName = LoginTypes[value.name];
      }
      this.formData = value.content;
      this._contentData = value;
   }

   ngOnInit(): void {
      this.logoAltText = this.translate._T("horizon_logo");
      if (this.viewClientModel.rebrandName && !clientUtil.isChromeClient()) {
         this.logoAltText = this.viewClientModel.rebrandName + " " + this.translate._T("LOGO");
      }
   }

   get contentName(): LoginData {
      return this._contentData;
   }
}
