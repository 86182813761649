/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export const TabStatusEnum = {
   OPEN: "open",
   CLOSE: "close"
};

export const PostMessageEventNamesEnum = {
   LOADED: "__TAB__LOADED_EVENT__",
   CUSTOM: "__TAB__CUSTOM_EVENT__",
   HANDSHAKE: "__TAB__HANDSHAKE_EVENT__",
   ON_BEFORE_UNLOAD: "__TAB__ON_BEFORE_UNLOAD__",
   PARENT_DISCONNECTED: "__PARENT_DISCONNECTED__",
   HANDSHAKE_WITH_PARENT: "__HANDSHAKE_WITH_PARENT__",
   PARENT_COMMUNICATED: "__PARENT_COMMUNICATED__"
};

export const WarningTextEnum = {
   INVALID_JSON: "Invalid JSON Object!",
   INVALID_DATA: "Some wrong message is being sent by Parent.",
   CONFIG_REQUIRED: "Configuration options required. Please read docs.",
   CUSTOM_EVENT: "CustomEvent(and it's polyfill) is not supported in this browser.",
   URL_REQUIRED: "Url is needed for creating and opening a new window/tab. Please read docs."
};

export const MESSAGETYPE = {
   PUSH_TOKEN: "_PUSH_TOKEN_",
   REQUIRE_TOKEN: "_REQUIRE_TOKEN_",
   IDLE_TIME_OUT: "_IDLE_TIME_OUT",
   SESSION_EXPIRED: "_SESSION_EXPIRED_",
   LOG_OUT: "_LOG_OUT"
};
