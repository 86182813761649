/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * jscdkBrokerError.js --
 *
 * Enums JSCDK broker errors.
 *
 */

import util from "../util";
import JSCDKError from "./jscdk-error";

const JSCDKBrokerError = {
   JSCDK_BROKER_ERROR_ALREADY_AUTHENTICATED: "ALREADY_AUTHENTICATED",
   JSCDK_BROKER_ERROR_AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
   JSCDK_BROKER_ERROR_BROKER_DISABLED: "BROKER_DISABLED",
   JSCDK_BROKER_ERROR_DESKTOP_LAUNCH_ERROR: "DESKTOP_LAUNCH_ERROR",
   JSCDK_BROKER_ERROR_DESKTOP_MAINTENANCE_ERROR: "DESKTOP_MAINTENANCE_ERROR",
   JSCDK_BROKER_ERROR_DESKTOP_ROLLBACK_CHECKOUT_ERROR: "DESKTOP_ROLLBACK_CHECKOUT_ERROR",
   JSCDK_BROKER_ERROR_APPLICATION_LAUNCH_ERROR: "APPLICATION_LAUNCH_ERROR",
   JSCDK_BROKER_ERROR_DESKTOPS_ERROR: "DESKTOPS_ERROR",
   JSCDK_BROKER_ERROR_LAUNCH_ITEMS_ERROR: "LAUNCH_ITEMS_ERROR",
   JSCDK_BROKER_ERROR_INVALID_ROOT_ELEMENT: "INVALID_ROOT_ELEMENT",
   JSCDK_BROKER_ERROR_INVALID_VERSION: "INVALID_VERSION",
   JSCDK_BROKER_ERROR_INVALID_XML: "INVALID_XML",
   JSCDK_BROKER_ERROR_KILL_SESSION_ERROR: "KILL_SESSION_ERROR",
   JSCDK_BROKER_ERROR_MISSING_CONTENT: "MISSING_CONTENT",
   JSCDK_BROKER_ERROR_MISSING_VERSION: "MISSING_VERSION",
   JSCDK_BROKER_ERROR_NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
   JSCDK_BROKER_ERROR_NOT_ENTITLED: "NOT_ENTITLED",
   JSCDK_BROKER_ERROR_PREFERENCES_ERROR: "PREFERENCES_ERROR",
   JSCDK_BROKER_ERROR_READ_ERROR: "READ_ERROR",
   JSCDK_BROKER_ERROR_RESET_DESKTOP_ERROR: "RESET_DESKTOP_ERROR",
   JSCDK_BROKER_ERROR_TUNNEL_ERROR: "TUNNEL_ERROR",
   JSCDK_BROKER_ERROR_UNRECOGNIZED_CONTENT: "UNRECOGNIZED_CONTENT",
   JSCDK_BROKER_ERROR_UNSUPPORTED_VERSION: "UNSUPPORTED_VERSION",
   JSCDK_BROKER_ERROR_TIMEOUT: "TIMEOUT",
   JSCDK_BROKER_ERROR_CONNECT_TO_BROKER_ERROR: "CONNECT_TO_BROKER_ERROR",
   JSCDK_BROKER_ERROR_LAUNCH_HEADROOM_ERROR: "LAUNCH_HEADROOM_ERROR",
   JSCDK_BROKER_ERROR_CONNECT_TO_FOREIGN_ERROR: "CONNECT_TO_FOREIGN_ERROR",
   JSCDK_BROKER_ERROR_NEED_FOREIGN_JUMP_ERROR: "NEED_FOREIGN_JUMP_ERROR",
   JSCDK_BROKER_ERROR_MAX_MULTI_SESSION_LIMIT_REACHED: "MAX_MULTI_SESSION_LIMIT_REACHED",
   JSCDK_BROKER_ERROR_UNKNOWN: "UNKNOWN",
   JSCDK_BROKER_ERROR_KEY_EXCHANGE_ERROR: "KEY_EXCHANGE_ERROR",

   getError: function (errorCode) {
      let error = null;

      if (errorCode === this.JSCDK_BROKER_ERROR_TIMEOUT) {
         error = new JSCDKError("TIMEOUT", util._("JSCDK_ERROR_AJAX_TIMEOUT"));
      } else if (errorCode === this.JSCDK_BROKER_ERROR_CONNECT_TO_BROKER_ERROR) {
         error = new JSCDKError("CONNECT_TO_BROKER_ERROR", util._("JSCDK_ERROR_FAILED_CONNECT_SERVER"));
      } else if (errorCode === this.JSCDK_BROKER_ERROR_UNKNOWN) {
         error = new JSCDKError("UNKNOWN", util._("JSCDK_ERROR_UNKNOWN_ERROR"));
      }

      return error;
   }
};

export default JSCDKBrokerError;
