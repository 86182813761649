/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 *
 * @format
 */
import { Injectable } from "@angular/core";
import { clientUtil } from "../../../core/libs";
import Logger from "../../../core/libs/logger";
import { LocalStorageService } from "../../../core/services/storage/local-storage.service";
import { RemoteUsbDevice } from "./usb-remote";
import { ChromeUsbDevice } from "./usblib";
@Injectable({
   providedIn: "root"
})
export class USBDeviceProxyService {
   private logger = new Logger(Logger.USB);
   private sessionKey: string = null;
   private static readonly USB_DEVICE_KEY = "UsbDeviceMapV2";
   constructor(private localStorageService: LocalStorageService) {}

   public setSessionKey = (key: string) => {
      this.sessionKey = key;
   };

   //only for chrome native client
   public saveOpenedUsbHandle = (devices: Array<RemoteUsbDevice>) => {
      let usbDeviceInfo = {};
      if (!clientUtil.isChromeClient()) {
         return;
      }

      //session never active
      if (this.sessionKey === null) {
         return;
      }

      try {
         // read out current cache
         const usbHandleStr = this.localStorageService.get(USBDeviceProxyService.USB_DEVICE_KEY);
         if (usbHandleStr) {
            usbDeviceInfo = JSON.parse(usbHandleStr);
         }

         // clear directly
         if (devices === null || devices.length === 0) {
            delete usbDeviceInfo[this.sessionKey];
         } else {
            usbDeviceInfo[this.sessionKey] = this._generateHandle(devices);
         }

         this.localStorageService.set(USBDeviceProxyService.USB_DEVICE_KEY, JSON.stringify(usbDeviceInfo));
      } catch (e) {
         Logger.error(e);
      }
   };

   private _generateHandle = (devices: Array<RemoteUsbDevice>) => {
      const chromeHandles = [];
      for (const device of devices) {
         const chromeDevice: ChromeUsbDevice = device.localDevice as ChromeUsbDevice;
         chromeHandles.push(chromeDevice.connectionHandle);
      }
      return chromeHandles;
   };
}
