/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { TabUtilsService } from "./utils/tabUtil.service";
import { PostMessageListenerService } from "./utils/postmessage";
import { WarningTextEnum, PostMessageEventNamesEnum, TabStatusEnum } from "./utils/tabEnum";

@Injectable()
export class ParentTabService {
   public id: string | number;
   public status = "";
   public ref;
   public url;
   public name;
   public windowName;
   public tab;
   public heartBeatInterval: number = 500;
   public onChildDisconnect;
   public onHandshakeCallback;
   public heartBeat = null;

   constructor(
      private tabUtilsService: TabUtilsService,
      private postMessageListenerService: PostMessageListenerService
   ) {
      this.tabUtilsService.tabs = [];
   }

   public setFunctions = (config) => {
      this.onHandshakeCallback = config.onHandshakeCallback;
      this.onChildDisconnect = config.onChildDisconnect;
   };

   public create = (config) => {
      config = config || {};
      Object.assign(this, config);
      this.id = this.tabUtilsService.generateTabId() || this.tabUtilsService.tabs.length + 1;
      this.status = TabStatusEnum.OPEN;
      this.ref = window.open(this.url, config.windowName || "_blank", config.windowFeatures);

      this.postMessageListenerService.newlyTabOpened = {
         id: this.id,
         name: this.name || this.windowName,
         ref: this.ref
      };

      const tab = {
         id: this.id,
         status: this.status,
         ref: this.ref
      };
      this.tabUtilsService.addNew(tab);
      return tab;
   };

   public init = (config) => {
      this.addEventListeners();
      this.setFunctions(config);
      this.heartBeatInterval = config.heartBeatInterval;
   };

   public addInterval = () => {
      let i,
         tabs = this.tabUtilsService.getAll(),
         openedTabs = this.tabUtilsService.getOpened();
      if (!openedTabs || !openedTabs.length) {
         window.clearInterval(this.heartBeat);
         this.heartBeat = null;
         return false;
      }

      for (i = 0; i < tabs.length; i++) {
         if (tabs[i] && tabs[i].ref) {
            tabs[i].status = tabs[i].ref.closed ? TabStatusEnum.CLOSE : TabStatusEnum.OPEN;
         }
      }
   };

   public startPollingTabs = () => {
      this.heartBeat = window.setInterval(() => this.addInterval(), this.heartBeatInterval);
   };

   public watchStatus = (tab) => {
      if (!tab || !tab.ref) {
         return false;
      }
      const newStatus = tab.ref.closed ? TabStatusEnum.CLOSE : TabStatusEnum.OPEN,
         oldStatus = tab.status;

      if (!newStatus || newStatus === oldStatus) {
         return false;
      }

      if (oldStatus === TabStatusEnum.OPEN && newStatus === TabStatusEnum.CLOSE) {
         this.tabUtilsService._remove(tab);
      }
   };

   public onChildUnload = (ev) => {
      if (this.onChildDisconnect) {
         this.onChildDisconnect(ev.detail);
      }
   };

   public customEventUnListener = (ev) => {
      if (ev.detail && ev.detail.type === PostMessageEventNamesEnum.HANDSHAKE && this.onHandshakeCallback) {
         this.onHandshakeCallback(JSON.parse(ev.detail.tabInfo));
      }
   };

   public addEventListeners = () => {
      window.removeEventListener("message", this.postMessageListenerService.onNewTab);
      window.addEventListener("message", this.postMessageListenerService.onNewTab);

      window.removeEventListener("onCustomChildMessage", this.customEventUnListener);
      window.addEventListener("onCustomChildMessage", (ev) => this.customEventUnListener(ev));

      window.removeEventListener("onChildUnload", this.onChildUnload);
      window.addEventListener("onChildUnload", (ev) => this.onChildUnload(ev));

      window.onbeforeunload = () => {
         this.tabUtilsService.broadCastAll(PostMessageEventNamesEnum.PARENT_DISCONNECTED);
      };
   };

   public getOpenedTabs = () => {
      return this.tabUtilsService.getOpened();
   };

   public broadCastAll = (msg) => {
      return this.tabUtilsService.broadCastAll(msg);
   };

   public openNewTab = (config) => {
      if (!config) {
         throw new Error(WarningTextEnum.CONFIG_REQUIRED);
      }
      const url = config.url;
      if (!url) {
         throw new Error(WarningTextEnum.URL_REQUIRED);
      }

      const tab = this.create(config);

      if (!this.heartBeat) {
         this.startPollingTabs();
      }

      return tab;
   };
}
