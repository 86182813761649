/**
 * ******************************************************
 * Copyright (C) 2012-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * frontController.js --
 *
 * A base class for front controller,
 * that delegates requests to appropriate command classes.
 *
 * The role of front controller is to first register command handlers
 * against requests. Then per request, the front controller looks up
 * its table of registered events, finds the appropriate command for handling
 * the request and invokes the command by calling its execute() method.
 *
 */

import Logger from "../../../core/libs/logger";

export default function FrontController() {
   /**
    * Dictionary of command name to command class mappings.
    */
   const _commands = {};

   /**
    * Register a command class with the controller,
    * against a command name.
    *
    * @param commandName   command name
    * @param commandClass  command class e.g. ConnectToBrokerClass
    */
   this.addCommand = function (commandName, commandClass) {
      _commands[commandName] = commandClass;

      Logger.debug("Command [" + commandName + "] is added.");
   };

   /**
    * Returns the command class registered with the command name.
    *
    * @param commandName   command name
    * @returns  command class
    */
   this.getCommand = function (commandName) {
      if (_commands[commandName]) {
         return _commands[commandName];
      }

      Logger.warning("Command [" + commandName + "] is not registered.");
      return null;
   };

   /**
    * Deregister a command class with the command name.
    *
    * @param commandName   command name
    */
   this.removeCommand = function (commandName) {
      if (_commands[commandName]) {
         _commands[commandName] = null;
         delete _commands[commandName];

         Logger.debug("Command [" + commandName + "] is removed.");
      }
   };

   /**
    * Check if a command name to command class mapping is registered.
    *
    * @param commandName   command name
    * @returns Boolean true - registered, false - not registered
    */
   this.containCommand = function (commandName) {
      return !!this.getCommand(commandName);
   };

   /**
    * Execute a command by invoking its execute() method.
    *
    * @param action
    *        action is the request object
    *        which must contain a property called 'method'
    *        method corresponds to command name
    */
   this.execute = function (action) {
      const commandName = action.method;
      const commandClass = this.getCommand(commandName);
      if (commandClass) {
         return new commandClass().execute(action);
      }

      Logger.warning("Command [" + commandName + "] fails to execute.");
   };
}
