/**
 * ******************************************************
 * Copyright (C) 2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * unauthHandler.js --
 *
 *      A sub-handler for DoSubmitAuthenticationHandler.
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import { MessageHandler } from "./messageHandler";

export default function UnauthHandler() {
   let router;

   // member variables below
   this.messageName = "unauthenticated";
   this.messageText = "unauthenticated";

   // register dependencies here
   router = util.getObject(globalArray, "router");
   if (router) {
      this.registerHandler(router, "receiver");
      this.registerHandler(router, "doLogout");
      this.registerHandler(router, "doCancelAuthentication");
   }
}

// inherits MessageHandler prototype
UnauthHandler.prototype = new MessageHandler();
// constructor
UnauthHandler.constructor = UnauthHandler;

/**
 * Set the request XML for unauthenticated.
 *
 * @param username [in] username for the windows password authentication.
 */

UnauthHandler.prototype.setRequestXML = function (username) {
   let usernameText, paramText;

   usernameText = util.createElement("name", "username");
   usernameText += util.createElement("values", util.createElement("value", username.trim()));
   paramText = util.createElement("param", usernameText);

   paramText = util.createElement("params", paramText);
   this.requestXML = util.createElement("name", "unauthenticated");
   this.requestXML += paramText;
   this.requestXML = util.createElement("screen", this.requestXML);
};
