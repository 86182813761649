/**
 * ******************************************************
 * Copyright (C) 2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { ContextMenuUtilService } from "../context-menu-util.service";

@Component({
   selector: "share-contextmenu",
   templateUrl: "./contextmenu.component.html",
   styleUrls: ["./context-menu.component.less"]
})
export class ShareContextMenuComponent implements OnInit {
   @Input() app: TitanEntitlementItem;
   @Input() paneClass: string;
   @Input() contextmenuName: string;
   @Input() item: TitanEntitlementItem;
   @Input() server;
   @Output() protocolSelectEvent = new EventEmitter();
   @Output() appLaunchEvent = new EventEmitter();
   @Output() connectEvent: EventEmitter<any> = new EventEmitter();
   @Output() connectServerEvent: EventEmitter<any> = new EventEmitter();
   @Output() editServerEvent: EventEmitter<any> = new EventEmitter();
   @Output() removeServerEvent: EventEmitter<any> = new EventEmitter();

   constructor(
      private renderer2: Renderer2,
      private el: ElementRef,
      private contextMenuUtilService: ContextMenuUtilService
   ) {}

   ngOnInit(): void {
      this.dismissContentMenuFromOutside();
      this.contextMenuUtilService.setDirection(this.el.nativeElement, this.renderer2, this.paneClass);
   }

   private dismissContentMenuFromOutside(): void {
      this.renderer2.listen("document", "click", (event) => {
         const isTriggerElem = this.isTriggerElement(event, "overflow-button");
         if (!isTriggerElem) {
            if (this.app) {
               this.app.showContextMenu = false;
            }
            if (this.item) {
               this.item.showContextMenu = false;
            }
            if (this.server) {
               this.server.showContextMenu = false;
            }
         }
      });
   }

   public isTriggerElement(event: any, elementId: string): boolean {
      return event.target && event.target.getAttribute("data-id") === elementId;
   }

   public connectFunc(event): void {
      this.connectEvent.emit(event);
   }

   public protocolSelect(event): void {
      this.protocolSelectEvent.emit(event);
   }

   public appLaunch(event): void {
      this.appLaunchEvent.emit(event);
   }

   public connectServer(event): void {
      this.connectServerEvent.emit(event);
   }

   public editServer(event): void {
      this.editServerEvent.emit(event);
   }

   public removeServer(event): void {
      this.removeServerEvent.emit(event);
   }
}
