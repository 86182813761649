<!-- @format -->

<div [hidden]="!titanClient">
   <section class="horizon-preferences-content-container">
      <div class="preference-option-title">{{ "PREFERENCE_T" | i18nv2 }}</div>
      <form #preferredViewClient="ngForm" (change)="setPreferredViewClient()" novalidate>
         <fieldset>
            <div class="horizon-preference first">
               <input
                  name="preferredClient"
                  type="radio"
                  tabindex="-1"
                  value="HORIZON_CLIENT"
                  id="native"
                  class="preference-option-radio-input"
                  [(ngModel)]="preferredClient"
               />
               <label for="native">
                  <span
                     role="radio"
                     class="preference-option-container native"
                     tabindex="0"
                     [attr.aria-label]="'TITAN_NATIVE_CLIENT' | i18nv2"
                     (keyup.Space)="setPreferredViewClient('HORIZON_CLIENT')"
                     (keyup.enter)="setPreferredViewClient('HORIZON_CLIENT')"
                     [ngClass]="{ 'selected primary-border-color': preferredClient === 'HORIZON_CLIENT' }"
                  >
                     <span class="svg-icon icon-launch-logo-check"></span>
                     <span class="launch-client-logo horizon-native"></span>
                     <span class="preference-option-text">
                        {{ "TITAN_NATIVE_CLIENT" | i18nv2 }}
                     </span>
                  </span>
               </label>
               <a
                  href="https://www.vmware.com/go/viewclients"
                  target="_blank"
                  class="install-text viewclient-link primary-link-color"
                  [attr.aria-label]="'TITAN_INSTALL' | i18nv2"
                  [title]="'TITAN_INSTALL' | i18nv2"
               >
                  {{ "TITAN_INSTALL" | i18nv2 }}
               </a>
            </div>
            <div class="horizon-preference" *ngIf="isChromeOS">
               <input
                  name="preferredClient"
                  type="radio"
                  tabindex="-1"
                  value="HORIZON_CHROME_NATIVE"
                  id="chrome_native"
                  class="preference-option-radio-input"
                  [(ngModel)]="preferredClient"
               />
               <label for="chrome_native">
                  <span
                     role="radio"
                     class="preference-option-container chrome"
                     tabindex="0"
                     [attr.aria-label]="'TITAN_CHROME_NATIVE_CLIENT' | i18nv2"
                     (keyup.Space)="setPreferredViewClient('HORIZON_CHROME_NATIVE')"
                     (keyup.enter)="setPreferredViewClient('HORIZON_CHROME_NATIVE')"
                     [ngClass]="{ 'selected primary-border-color': preferredClient === 'HORIZON_CHROME_NATIVE' }"
                  >
                     <span class="svg-icon icon-launch-logo-check"></span>
                     <span class="launch-client-logo horizon-native-chromeos"></span>
                     <span class="preference-option-text">
                        {{ "TITAN_CHROME_NATIVE_CLIENT" | i18nv2 }}
                     </span>
                  </span>
               </label>
               <a
                  href="https://chrome.google.com/webstore/detail/vmware-horizon-client-for/ppkfnjlimknmjoaemnpidmdlfchhehel"
                  target="_blank"
                  class="install-text chromestore-link primary-link-color"
                  [attr.aria-label]="'TITAN_INSTALL' | i18nv2"
                  [title]="'TITAN_INSTALL' | i18nv2"
               >
                  {{ "TITAN_INSTALL" | i18nv2 }}
               </a>
            </div>
            <div class="horizon-preference">
               <input
                  name="preferredClient"
                  type="radio"
                  tabindex="-1"
                  value="BROWSER"
                  id="browser"
                  class="preference-option-radio-input"
                  [(ngModel)]="preferredClient"
               />
               <label for="browser">
                  <span
                     role="radio"
                     class="preference-option-container browser"
                     tabindex="0"
                     [attr.aria-label]="'TITAN_HTML5_CLIENT' | i18nv2"
                     (keyup.Space)="setPreferredViewClient('BROWSER')"
                     (keyup.enter)="setPreferredViewClient('BROWSER')"
                     [ngClass]="{ 'selected primary-border-color': preferredClient === 'BROWSER' }"
                  >
                     <span class="svg-icon icon-launch-logo-check"></span>
                     <span class="launch-client-logo browser"></span>
                     <span class="preference-option-text">
                        {{ "TITAN_HTML5_CLIENT" | i18nv2 }}
                     </span>
                  </span>
               </label>
            </div>
         </fieldset>
      </form>
   </section>
</div>
