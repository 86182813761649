/**
 * *************************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview rtavSettingManager.ts
 *
 * Class that contains functions and logic for managing and formatting RTAV configurable settings
 *
 */

import { Injectable } from "@angular/core";
import { settings } from "./rtav.settings";

@Injectable()
export class RtavSettingManager {
   private adminPolicy;
   private localConfig;

   constructor() {}

   private setLocalConfig(adminPolicy) {
      let localWidth, localHeight, localMaxFPS;

      if (adminPolicy.vdoDefResWidth !== 0) {
         // client set has no limit, use agent default value
         localWidth = adminPolicy.vdoDefResWidth;
      } else {
         if (adminPolicy.vdoMaxResWidth !== 0) {
            localWidth = Math.min(adminPolicy.vdoMaxResWidth, settings.video.defResWidth);
         } else {
            localWidth = settings.video.defResWidth;
         }
      }
      if (adminPolicy.vdoDefResHeight !== 0) {
         // client set has no limit, use agent default value
         localHeight = adminPolicy.vdoDefResHeight;
      } else {
         if (adminPolicy.vdoMaxResHeight !== 0) {
            localHeight = Math.min(adminPolicy.vdoMaxResHeight, settings.video.defResHeight);
         } else {
            localHeight = settings.video.defResHeight;
         }
      }
      if (adminPolicy.vdoMaxFPS !== 0) {
         if (settings.video.defMaxFPS !== 0) {
            localMaxFPS = Math.min(settings.video.defMaxFPS, adminPolicy.vdoMaxFPS);
         } else {
            localMaxFPS = adminPolicy.vdoMaxFPS;
         }
      } else {
         localMaxFPS = settings.video.defMaxFPS;
      }

      // round the resolution since cpmpression lib suggest so.
      if (localWidth % 16 !== 0) {
         localWidth = Math.floor(localWidth / 16) * 16;
      }
      if (localHeight % 16 !== 0) {
         localHeight = Math.floor(localHeight / 16) * 16;
      }

      this.localConfig = {
         versionNum: adminPolicy.versionNum,
         isEnabled: adminPolicy.isEnabled,
         vdoRes: {
            width: localWidth,
            height: localHeight
         },
         vdoFPS: localMaxFPS,
         reserved: 0
      };
   }

   public setAdminPolicy(policy) {
      this.adminPolicy = policy;
      this.setLocalConfig(this.adminPolicy);
   }

   public getLocalConfig() {
      return this.localConfig;
   }

   public getAudioDeviceSetting() {
      return settings.audio;
   }

   public getVideoDeviceSetting() {
      return {
         width: this.localConfig.vdoRes.width,
         height: this.localConfig.vdoRes.height,
         fps: this.localConfig.vdoFPS
      };
   }
}
