/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { environment } from "../environments/environment";

export abstract class PathService {
   public hostUrl: string = `${location.origin}`;
   //auth service
   public AUTH_ACCESS_TOKEN_PATH: string = `${environment.authUrl}/v1/token`;
   public AUTH_EXTERNAL_ACCESS_TOKEN_PATH: string = `${environment.authUrl}/v1/external/authenticator/view/v1/token`;

   public AUTH_REFRESH_TOKEN_PATH: string = `${environment.authUrl}/v1/refreshtoken`;
   public AUTH_AUTH_URL_PATH: string = `${environment.authUrl}/v1/auth-url?`;
   public AUTH_AUTH_LOGOUT: string = `${environment.authUrl}/v2/client/users/logout`;
   public AUTH_REVOKE_OTA: string = `${environment.authUrl}/v1/client/users/revoke-ota`;
   public AUTH_GET_LOGOUT: string = `${environment.authUrl}/v1/logout`;

   //portal service
   public PORTAL_LAUNCH_ITEMS: string = `${environment.portalUrl}/v2/users/launchitems`;
   public PORTAL_GENERATE_DSPEC: string = `${environment.portalUrl}/v1/users/spec`;
   public PORTAL_ERROR_REPORT: string = `${environment.portalUrl}/v2/users/report-error-detail`;

   //rx service v2
   public RX_USER_PROFILE_V2: string = `${environment.rxUrl}/v2/profile`;
   public RX_USER_PROFILE_V2_FAVORITES: string = `${environment.rxUrl}/v2/profile/favorites`;
   public RX_USER_PROFILE_V2_PREFERENCES: string = `${environment.rxUrl}/v2/profile/web-preferences`;
   public RX_V2_RDS_LICENSE: string = `${environment.rxUrl}/v2/rds-license`;

   public APP_SESSIONS: string = `${environment.portalUrl}/v1/users/sessions`;
   public USERS_ACTION: string = `${environment.portalUrl}/v2/users/action`;
   public USERS: string = `${environment.portalUrl}/v1/users`;
   public commonRedirectUri: string = environment.commonRedirectUri;
   public authEndpoint: string = environment.authEndpoint;
   public heartBeatURI: string = environment.heartBeatURI;
   public portalUrl: string = environment.portalUrl;
   public rxUrl: string = environment.rxUrl;

   public abstract setHostName(url: string): void;
   public abstract getSearchParameter(): string;
   public abstract navigateTo(url: string);
   public abstract getImagePath(relative: string);
   public abstract getPostLogoutUrl();
}
