/*********************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 *********************************************************
 *
 * @format
 */

import { ViewClientModel } from "../../common/model/viewclient-model";
import { UtilService } from "./util-service";
import { Localize } from "../../common/service/localize.service";
import { Component, ElementRef, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { TranslateService } from "@html-core";

@Component({
   selector: "help-link",
   template:
      '<a class="home-link" id="helpLink" href="" target="_blank" [hidden]="!showHelpLink" rel="{{linkRelValue}}"></a>'
})
export class HelpLinkComponent implements AfterViewInit {
   public showHelpLink: boolean;
   public linkRelValue: string;
   private element;
   constructor(
      private viewClientModel: ViewClientModel,
      private utilService: UtilService,
      private translate: TranslateService,
      private localize: Localize,
      private _el: ElementRef,
      private changeDetector: ChangeDetectorRef
   ) {}

   ngAfterViewInit() {
      this.element = $(this._el.nativeElement.children[0]);
      this.localize.localeReady$.subscribe((ready) => {
         setTimeout(() => {
            if (ready) {
               this.showLink();
            }
         });
      });

      this.linkRelValue = __REL_FOR_LINK__;
      this.changeDetector.detectChanges();
   }

   private showLink = () => {
      //Rebrand the help URL
      const helpUrl = this.viewClientModel.rebrandHelpUrl || this.utilService.getLinkByType("helpPages"),
         name = this.translate._T("help_with_vmware_horizon");
      this.element.html(name);
      this.element.attr("href", helpUrl);
      this.showHelpLink = true;
   };
}
