/**
 * ******************************************************
 * Copyright (C) 2020-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Unity } from "./unity";
import Logger from "../../../core/libs/logger";
import { AB } from "../../desktop/common/appblast-util.service";
import { UnityService } from "./unity.service";
import {
   UnityMessage,
   WindowAddMsg,
   WindowRemoveMsg,
   WindowTitleChangeMsg,
   WindowAttrChangeMsg,
   WindowTypeChangeMsg,
   WindowMoveMsg,
   WindowTitleBarAreaMsg,
   WindowPrimaryMsg,
   WindowSecondaryMsg,
   SystemTrayMsg,
   WindowRegionMsg,
   ZorderUpdateMsg,
   WindowIconChangeMsg,
   UnityAttrChangedMsg,
   UpdateCompleteMsg,
   UnityVisibilityChanged,
   UnityReady,
   UnityReadyChanged,
   UnityPaused,
   UnityNotReady,
   UnityDestroyMsg,
   UnitySubject,
   WindowSyncMsg,
   UnityUrlRedirectionMsg
} from "./unity-messages";

export class UnityHandler implements Unity.UnityOps {
   public static UNITY_FEATURES = Unity.UnityFeatures.SHOW_FLOATING_LANGUAGE_BAR;
   private logger: Logger;
   private logId: string;
   public unityInst: Unity.Mgr = null;
   private subject$: UnitySubject;
   constructor(
      private wmksKey: string,
      subject: UnitySubject,
      private unityService: UnityService
   ) {
      this.subject$ = subject;
      this.logger = new Logger(Logger.UNITY, this.logId);
   }

   public onAdd = (windowId: string, winPath: string, execPath: string) => {
      this.logger.debug("Window added with id " + windowId + ", windowPath " + winPath + ", and execPath " + execPath);
      this.unityService.updateEmptySession(this.wmksKey, true);
      this.subject$.next(new WindowAddMsg(this.wmksKey, windowId, winPath, execPath));
   };

   public onRemove = (windowId: string) => {
      this.logger.debug("Window removed with id " + windowId);
      this.unityService.updateEmptySession(this.wmksKey, false);
      this.subject$.next(new WindowRemoveMsg(this.wmksKey, windowId));
   };

   public onTitleChanged = (windowId: string, windowTitle: string) => {
      this.logger.debug("Window with id " + windowId + " now has title " + windowTitle);
      this.subject$.next(new WindowTitleChangeMsg(this.wmksKey, windowId, windowTitle));
   };

   public onAttrChanged = (windowId: string, type: Unity.UnityWindowAttribute, value: boolean) => {
      this.logger.debug("Window with id " + windowId + " has attribute " + type + " change to value " + value);
      this.subject$.next(new WindowAttrChangeMsg(this.wmksKey, windowId, type, value));
   };

   public onTypeChanged = (windowId: string, type: Unity.UnityWindowType) => {
      this.logger.debug("Window with id " + windowId + " now is of type " + type);
      this.subject$.next(new WindowTypeChangeMsg(this.wmksKey, windowId, type));
   };

   public onWindowMoved = (windowId: string, rect: any) => {
      this.logger.trace("Window: " + windowId + " is moving");
      this.subject$.next(new WindowMoveMsg(this.wmksKey, windowId, rect));
   };

   public onTitleBarAreaChanged = (windowId: string, windowPositionInfo: any) => {
      this.subject$.next(new WindowTitleBarAreaMsg(this.wmksKey, windowId, windowPositionInfo));
   };

   public onPrimaryWindowUpdate = (windowId: string, primary: string) => {
      this.subject$.next(new WindowPrimaryMsg(this.wmksKey, windowId, primary));
   };

   public onSecondaryWindowUpdate = (windowId: string, secondaryWindows: any[]) => {
      this.subject$.next(new WindowSecondaryMsg(this.wmksKey, windowId, secondaryWindows));
   };

   public onTrayIconChanged = (icons: any) => {
      this._getTrayIconHelper(this.wmksKey, icons);
      this.subject$.next(new SystemTrayMsg(this.wmksKey, icons));
   };

   public onRegionUpdate = (windowId: string, regions: any[]) => {
      this.subject$.next(new WindowRegionMsg(this.wmksKey, windowId, regions));
   };

   public onZorderUpdate = (components: any) => {
      this.subject$.next(new ZorderUpdateMsg(this.wmksKey, components));
   };

   public onIconChanged = (windowId: string) => {
      this.unityService.getIconDataHelper(this.wmksKey, windowId, (data) => {
         const iconSrc = data ? data : AB.ICONS.SMALL_DEFAULT_ICON;
         this.subject$.next(new WindowIconChangeMsg(this.wmksKey, windowId, iconSrc));
         // Use the 60ms delay with similar reason for hiding windows-login page as fix for bug 1869835
         setTimeout(() => {
            this.onVisibilityChanged(true);
         }, 60);
      });
   };

   public onAppAttrChanged(windowPath: string, execPath: string, name: string, iconSrc: any) {
      this.subject$.next(new UnityAttrChangedMsg(this.wmksKey, windowPath, execPath, name, iconSrc));
   }
   public onActiveChanged = (active: boolean) => {
      this.logger.debug("Unity is now " + (active ? "on" : "off"));
   };

   public onCapsChanged = (caps: any[]) => {
      this.logger.debug("Server advertised capabilities: " + caps);
   };

   public onUpdateComplete = () => {
      this.subject$.next(new UpdateCompleteMsg(this.wmksKey));
   };

   private _getTrayIconHelper = (wmksKey, systemTrayIcons) => {
      systemTrayIcons.iconSrc =
         "data:image/png;base64," + window.btoa(String.fromCharCode.apply(null, systemTrayIcons.iconSrc));
      systemTrayIcons.wmksKey = wmksKey;
   };

   public onVisibilityChanged = (show: boolean) => {
      this.subject$.next(new UnityVisibilityChanged(this.wmksKey, show));
   };

   public onReadyChanged = (ready) => {
      this.subject$.next(
         new UnityReadyChanged(this.wmksKey, {
            ready: ready,
            isOn: this.unityInst.isOn,
            paused: this.unityInst.paused
         })
      );
      this.logger.info("Server is " + (ready ? "ready" : "not ready") + " for unity.");
      if (ready) {
         if (this.unityInst.isOn) {
            if (this.unityInst.paused) {
               this.logger.info("UnPause Unity now.");
               this.subject$.next(new UnityReady(this.wmksKey));
               this.unityInst.unpause();
            } else {
               this.logger.info('Ignoring duplicate "Unity ready" notification.');
            }
         } else {
            this.logger.info("Entering Unity now.");
            this.subject$.next(new UnityReady(this.wmksKey));
            this.unityInst.on(UnityHandler.UNITY_FEATURES);
         }
      } else {
         if (this.unityInst.isOn) {
            if (this.unityInst.paused) {
               this.logger.info('Ignoring duplicate "Unity not ready" notification.');
            } else {
               this.logger.info("Pausing Unity now.");
               this.unityInst.pause();
               this.subject$.next(new UnityPaused(this.wmksKey));
            }
         } else {
            /*
             * chrome native Clients need this notification
             * but the HTML5 client don't need it.
             */
            this.subject$.next(new UnityNotReady(this.wmksKey));
            this.logger.info(' "Unity not ready" before entering Unity mode.');
            // Unity not ready, show login desktop directly.
            this.onVisibilityChanged(true);
         }
      }
   };

   public onUrlRedirection(url: string) {
      this.subject$.next(new UnityUrlRedirectionMsg(url));
   }
}

export abstract class UnitySubscription {
   public abstract onWindowAdded(wmksKey: string, windowId: string, windowPath: string, execPath: string);
   public abstract onWindowRemoved(wmksKey, windowId);
   public onWindowMoved(wmksKey, windowId, rect) {}
   public onRegionUpdate(wmksKey, windowId, regions) {}
   public abstract onAttrChanged(wmksKey: string, windowId: string, type: Unity.UnityWindowAttribute, value: boolean);
   public abstract onTypeChanged(wmksKey: string, windowId: string, type: Unity.UnityWindowType);
   public onPrimaryWindowUpdate(wmksKey, windowId, primaryWindowId) {}
   public onSecondaryWindowUpdate(wmksKey, windowId, ids) {}
   public abstract onUnityReady(wmksKey);
   public onUnityNotReady(wmksKey) {}
   public abstract onUnityPause(wmksKey);
   public onUpdateComplete(wmksKey) {}
   public onZorderUpdate(wmksKey, orders) {}
   public onTitleBarAreaUpdate(wmksKey, windowId, windowInfo) {}
   public abstract onTitleChanged(wmksKey, windowId, windowTitle);
   public abstract onIconChanged(wmksKey, windowId, iconSrc);
   public onAppAttrChanged(wmksKey, windowPath, execPath, name, iconSrc) {}
   public onDestroy(wmksKey) {}
   public onSystemTray(wmksKey, icons) {}
   public onVisibilityChanged(wmksKey, show) {}
   public onWindowSyncMsg(wmksKey, windows, zOrders) {}

   public handleUnityMessage = (raw: UnityMessage) => {
      const msg = null;
      Logger.debug("UnityWindowTracker received : " + raw.msgType, Logger.UNITY);
      switch (raw.msgType) {
         case WindowAddMsg.TYPE:
            {
               const msg: WindowAddMsg = raw as WindowAddMsg;
               this.onWindowAdded(msg.wmksKey, msg.windowId, msg.windowPath, msg.execPath);
            }
            break;
         case WindowRemoveMsg.TYPE:
            {
               const msg = raw as WindowRemoveMsg;
               this.onWindowRemoved(msg.wmksKey, msg.windowId);
            }
            break;
         case WindowMoveMsg.TYPE:
            {
               const msg = raw as WindowMoveMsg;
               this.onWindowMoved(msg.wmksKey, msg.windowId, msg.rect);
            }
            break;
         case WindowRegionMsg.TYPE:
            {
               const msg = raw as WindowRegionMsg;
               this.onRegionUpdate(msg.wmksKey, msg.windowId, msg.regions);
            }
            break;
         case WindowAttrChangeMsg.TYPE:
            {
               const msg = raw as WindowAttrChangeMsg;
               this.onAttrChanged(msg.wmksKey, msg.windowId, msg.type, msg.value);
            }
            break;
         case WindowTypeChangeMsg.TYPE:
            {
               const msg = raw as WindowTypeChangeMsg;
               this.onTypeChanged(msg.wmksKey, msg.windowId, msg.type);
            }
            break;
         case WindowPrimaryMsg.TYPE:
            {
               const msg = raw as WindowPrimaryMsg;
               this.onPrimaryWindowUpdate(msg.wmksKey, msg.windowId, msg.primaryWindowId);
            }
            break;
         case WindowSecondaryMsg.TYPE:
            {
               const msg = raw as WindowSecondaryMsg;
               this.onSecondaryWindowUpdate(msg.wmksKey, msg.windowId, msg.secondaryWindows);
            }
            break;
         case UnityReady.TYPE:
            {
               const msg = raw as UnityReady;
               this.onUnityReady(msg.wmksKey);
            }
            break;
         case UnityPaused.TYPE:
            {
               const msg = raw as UnityPaused;
               this.onUnityPause(msg.wmksKey);
            }
            break;
         case UnityNotReady.TYPE:
            {
               const msg = raw as UnityNotReady;
               this.onUnityNotReady(msg.wmksKey);
            }
            break;
         case UpdateCompleteMsg.TYPE:
            {
               const msg = raw as UpdateCompleteMsg;
               this.onUpdateComplete(msg.wmksKey);
            }
            break;
         case ZorderUpdateMsg.TYPE:
            {
               const msg = raw as ZorderUpdateMsg;
               this.onZorderUpdate(msg.wmksKey, msg.components);
            }
            break;
         case WindowTitleBarAreaMsg.TYPE:
            {
               const msg = raw as WindowTitleBarAreaMsg;
               this.onTitleBarAreaUpdate(msg.wmksKey, msg.windowId, msg.windowPositionInfo);
            }
            break;
         case WindowTitleChangeMsg.TYPE:
            {
               const msg = raw as WindowTitleChangeMsg;
               this.onTitleChanged(msg.wmksKey, msg.windowId, msg.windowTitle);
            }
            break;
         case WindowIconChangeMsg.TYPE:
            {
               const msg = raw as WindowIconChangeMsg;
               this.onIconChanged(msg.wmksKey, msg.windowId, msg.iconSrc);
            }
            break;
         case UnityAttrChangedMsg.TYPE:
            {
               const msg = raw as UnityAttrChangedMsg;
               this.onAppAttrChanged(msg.wmksKey, msg.windowPath, msg.execPath, msg.name, msg.iconSrc);
            }
            break;
         case UnityDestroyMsg.TYPE:
            {
               const msg = raw as UnityDestroyMsg;
               this.onDestroy(msg.wmksKey);
            }
            break;
         case SystemTrayMsg.TYPE:
            {
               const msg = raw as SystemTrayMsg;
               this.onSystemTray(msg.wmksKey, msg.icons);
            }
            break;
         case UnityVisibilityChanged.TYPE: {
            const msg = raw as UnityVisibilityChanged;
            this.onVisibilityChanged(msg.wmksKey, msg.isShow);
            break;
         }
         case WindowSyncMsg.TYPE: {
            const msg = raw as WindowSyncMsg;
            this.onWindowSyncMsg(msg.wmksKey, msg.windows, msg.zOrders);
            break;
         }
      }
   };
}
