/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * userActiveTimeSyncCtrl.js --
 *
 *      Implementation of the functions to handle response of
 * <authentication-status>.
 */

import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import GetAuthenticationStatusHandler from "../controllers/getAuthenticationStatusHandler";

export default function UserActiveTimeSyncCtrl() {
   this.idleTimerObject = util.getObject(globalArray, "idle-timeout-timer");
   if (!this.idleTimerObject) {
      Logger.error("UserActiveTimeSyncCtrl must be used after idleTimerObject is inited");
      return;
   }
   if (this.idleTimerObject.brokerVersionInvalid()) {
      return;
   }
   // member variables below
   this.ctrlName = "get-broker-sso-time-ctrl";
   this.callBackType = null;
   this.response = null;
}

UserActiveTimeSyncCtrl.prototype = {};
UserActiveTimeSyncCtrl.constructor = UserActiveTimeSyncCtrl;

// request send dealing functions
UserActiveTimeSyncCtrl.prototype.requestBrokerSSOTime = function (callBackType) {
   if (this.idleTimerObject.brokerVersionInvalid()) {
      return;
   }
   this.callBackType = callBackType;
   let getAuthenticationStatusObject,
      handlerList,
      router = globalArray["router"];

   getAuthenticationStatusObject = globalArray["get-authentication-status"];
   if (!getAuthenticationStatusObject) {
      getAuthenticationStatusObject = new GetAuthenticationStatusHandler();
      globalArray[getAuthenticationStatusObject.messageName] = getAuthenticationStatusObject;
      globalArray[getAuthenticationStatusObject.responseTag] = getAuthenticationStatusObject;
   } else {
      getAuthenticationStatusObject.resetData();
   }

   if (!!getAuthenticationStatusObject && !!router) {
      getAuthenticationStatusObject.setRequestXML(true);
      handlerList = getAuthenticationStatusObject.composeHandlerList();
      router.postMessage(handlerList, true, undefined, true);
   }
};

// response dealing functions
UserActiveTimeSyncCtrl.prototype.dealWithAuthenticationStatusXML = function (response) {
   if (this.idleTimerObject.brokerVersionInvalid()) {
      return;
   }
   this.response = response;
   if (this.callBackType === "syncWithTimer4AboutToTimeout" || this.callBackType === "syncWithTimer4Timeout") {
      this.dealWithSSOTime();
   } else {
      Logger.debug("unknown callBackType in GetAuthenticationStatusHandler");
   }
};

UserActiveTimeSyncCtrl.prototype.dealWithSSOTime = function () {
   let idleTimerObject = this.idleTimerObject,
      timeToBrokerLocked;

   if (this.callBackType === "syncWithTimer4AboutToTimeout" || this.callBackType === "syncWithTimer4Timeout") {
      if (!idleTimerObject) {
         Logger.error("timerController do not exist in GetAuthenticationStatusHandler.onUpdated()");
         return;
      }
      if (this.response.sso === "unlocked") {
         //push that time in the timer controller, and start the comparation
         timeToBrokerLocked = this.response["time-to-sso-lock"];
         idleTimerObject.setBrokerTimeForCompare(timeToBrokerLocked);
         idleTimerObject.compareToAct(this.callBackType);
      } else {
         this.detectBrokerLocked();
      }
   }
};

UserActiveTimeSyncCtrl.prototype.detectBrokerLocked = function () {
   //deal with locked case
   const idleTimerObject = this.idleTimerObject;
   if (idleTimerObject) {
      idleTimerObject.detectBrokerLocked();
   } else {
      Logger.error("timerController do not exist when UserActiveTimeSyncCtrl detect session locked");
   }
};
