/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { AB } from "./constants";

export function DPIScaleToValue(scale: number): string {
   return (scale * AB.DPI_100_PERCENT).toString();
}

class UtilFunction {
   private chromeClient: boolean = false;

   constructor() {
      try {
         // Edge has chrome, but doesn't has chrome.management
         this.chromeClient = !!chrome && !!chrome.management;
      } catch (e) {
         // Safari/Firefox/IE is throwing error here
      }
   }

   public isChromeClient() {
      return this.chromeClient;
   }
}

export const core = new UtilFunction();
