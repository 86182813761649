/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { MultiMonitor } from "./MultiMonitor";
import { EventBusService, BusEvent } from "@html-core";
import { Monitor } from "../../../../../../shared/desktop/multimon/common/monitor-message";

export class ExtendScreen extends MultiMonitor {
   public canvasId = "extendedCanvas";
   private _screenBase = { x: 0, y: 0 };
   private _port = null;

   constructor(
      remoteappBorderService,
      coordinateConverter,
      private _eventBusService: EventBusService
   ) {
      super();
      this._remoteappBorderService = remoteappBorderService;
      this._coordinateConverter = coordinateConverter;
      this._listen();
   }

   private _listen = () => {
      this._port = chrome.runtime.connect({ name: this._getPortName() });
      this._port.onMessage.addListener(this._onMessage);
      this._eventBusService.listen(BusEvent.MultiMonStatusUpdated.MSG_TYPE).subscribe(this._onMultimonUpdated);
   };

   protected _sendMessageToOtherScreen = (message: Monitor.MonitorMessage) => {
      this._port.postMessage(message);
   };

   private _onMultimonUpdated = (msg) => {
      this._screenBase = msg.offset;
   };

   public moveWindow = (drawingRect) => {
      const localDrawingRect = this._coordinateConverter.normalizePoint(drawingRect);
      this._port.postMessage(
         new Monitor.BorderMouseUpMsg(localDrawingRect, this._remoteappBorderService.topWindow.windowId)
      );
   };

   protected _toLocalPoint = (point) => {
      const offset = {
         x: screenX,
         y: screenY
      };

      // fixbug: 3103058
      if (screenX < 0 || screenY < 0) {
         const pos = this._coordinateConverter.normalize({
            x: screenX,
            y: screenY
         });

         if (screenX < 0) {
            offset.x = pos.x;
         }

         if (screenY < 0) {
            offset.y = pos.y;
         }
      }

      return {
         x: Math.round(point.x - offset.x - this._screenBase.x),
         y: Math.round(point.y - offset.y - this._screenBase.y)
      };
   };
}
