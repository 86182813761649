/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */
import { Injectable } from "@angular/core";
import * as CST from "@html-core";
import { clientUtil } from "@html-core";

@Injectable({
   providedIn: "root"
})
export abstract class LaunchService {
   protected eventBusService: CST.EventBusService = null;

   constructor(service: CST.EventBusService) {
      this.eventBusService = service;
   }

   //The return type should be chrome.app.AppWindow
   //use any to compatible between HTML5 and chrome native client.
   public abstract isDesktopLaunched(wmksKey: string): boolean;
   public abstract onNewAppReuseSession(sessionKey: string);
   public abstract createNewInstance(data, jscdkWrapper);

   public onLaunchDataReady = async (data, jscdkWrapper) => {
      if (!(clientUtil.isChromeClient() && clientUtil.isTitanClient())) {
         if (!data || !data.controller) {
            return;
         }

         const currentController = data.controller;
         if (!data.launchData || currentController.componentName !== "GetLaunchItems") {
            if (typeof data.controller.enable === "function") {
               data.controller.enable();
            }
            return;
         }
      }

      // will focus on the launch session
      data.launchData.isActive = true;

      if (!clientUtil.isChromeClient()) {
         this.eventBusService.dispatch({
            type: "postPrefData"
         });
      }

      await this.createNewInstance(data, jscdkWrapper);
   };
}
