/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { MediaStat } from "./model/media.models";

/**
 * MediaType enumuerations
 * Possible values: video|audio
 */
export enum MediaType {
   video = "video",
   audio = "audio"
}

/**
 * An entity interface that can manage media HTML element(s) and corresponding media stream tracks
 */
export interface MediaResourceInterface {
   /**
    * A map of MediaStreamTrack with its id as key
    */
   tracks: Map<string, MediaStreamTrack>;

   /**
    * Create a new HTMLMediaElement by given id and media parameters
    *
    * @param  {string} id given element id
    * @param  {any} params an JSON object that specify media element parameters
    */
   createElement: (id: string, params: any) => HTMLMediaElement | undefined;

   /**
    * Retrieve HTML media element by given id
    *
    * @param  {string} id given media element id
    * @returns HTMLMedialElement by given id or undefined if not found
    */
   getElement: (id: string) => HTMLMediaElement | undefined;

   /**
    * Retrieve Media elements
    */
   getElements: () => HTMLCollectionOf<HTMLMediaElement>;

   /**
    * Update HTMLMediaElement by given id and media parameters
    *
    * @param  {string} id  given element id
    * @param  {any} params an JSON object that specify media element parameters
    */
   updateElement: (id: string, params: any) => void;

   /**
    * Update global volume
    *
    * @param  {boolean} muted mute status
    * @param  {number} volume given volume in float
    */
   updateVolume: (muted: boolean, volume: number) => void;

   /**
    * Remove HTML media element by given id
    *
    * @param  {string} id given media element id
    * @returns HTMLMedialElement that is removed by given id or undefined if not found
    */
   removeElement: (id: string) => HTMLMediaElement | undefined;

   /**
    * Update srcObject property of the media element by given Id and MediaStream
    *
    * @param  {string} id given media element id
    * @param  {MediaStream} stream a stream of media content to be associated with given media element
    */
   updateSourceObject: (id: string, stream: MediaStream) => void;

   /**
    * Add new media stream track to media resource manager
    *
    * @param  {MediaStreamTrack} track
    */
   addTrack: (track: MediaStreamTrack) => void;

   /**
    * Update MediaStreamTrack by given track id
    *
    * @param  {string} tId given track id
    * @param  {MediaStreamTrack} track media track to be updated
    */
   updateTrack: (tId: string, track: MediaStreamTrack) => void;

   /**
    * Retrieve media stream track by given track id
    *
    * @param  {string} id given media stream track id
    * @returns MediaStreamTrack by given track id or undefined if not found
    */
   getTrack: (id: string) => MediaStreamTrack | undefined;

   /**
    * Stop media stream track by given track id
    *
    * @param  {string} id given media stream track id
    */
   stopTrack: (id: string) => void;

   /**
    * Remove media stream track
    *
    * @param  {MediaStreamTrack} track media stream track to be removed
    */
   removeTrack: (track: MediaStreamTrack) => void;

   /**
    * Enable/Disable media stream track by given id
    *
    * @param  {string} id given media track id
    * @param  {boolean} enabled boolean flag indicate if media track should be enabled/disabled
    */
   enableTrack: (id: string, enabled: boolean) => void;

   /**
    * Update media stats by given media element id and corresponding MediaStat model
    *
    * @param  {string} id givne media element id
    * @param  {MediaStat} model media stats metadata to be displayed on the stats overlay
    */
   updateStats: (id: string, model: MediaStat) => void;

   /**
    * Remove/Clear all [MediaStreamTrack] and [HTMLMediaElement] from local cache
    */
   clear: () => void;
}
