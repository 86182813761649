/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * getLaunchItemsCommand.js --
 *
 * get launchitem list.
 *
 */

import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import util from "../util";
import GetDesktopsHandler from "../controllers/getDesktopsHandler";
import GetLaunchItemsHandler from "../controllers/getLaunchItemsHandler";
import SetUserGlobalPrefHandler from "../controllers/setUserGlobalPrefHandler";

export default function GetLaunchItemsCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param getLaunchItemsAction[in]
 *        - its property 'method' has the value 'GetLaunchItems'
 */
GetLaunchItemsCommand.prototype.execute = function (getLaunchItemsAction) {
   let getLaunchItemsObject,
      getDesktopsObject,
      handlerList,
      supportedProtocols,
      supportedAppProtocols,
      type,
      router = globalArray["router"];

   if (!util.brokerSupportApplication()) {
      getDesktopsObject = globalArray["get-desktops"];
      if (!getDesktopsObject) {
         getDesktopsObject = new GetDesktopsHandler();
         globalArray[getDesktopsObject.messageName] = getDesktopsObject;
         globalArray[getDesktopsObject.responseTag] = getDesktopsObject;
      } else {
         getDesktopsObject.resetData();
      }

      if (!!getDesktopsObject && !!router) {
         supportedProtocols = globalArray.supportedProtocols;
         if (supportedProtocols) {
            getDesktopsObject.setRequestXML(supportedProtocols);
            handlerList = getDesktopsObject.composeHandlerList();
            router.postMessage(handlerList);
         } else {
            Logger.error("supported protocols is invalid.");
         }
      }
   } else {
      getLaunchItemsObject = globalArray["get-launch-items"];
      if (!getLaunchItemsObject) {
         getLaunchItemsObject = new GetLaunchItemsHandler(
            getLaunchItemsAction.skipTunnelAndPref,
            getLaunchItemsAction.skipTimer
         );
         globalArray[getLaunchItemsObject.messageName] = getLaunchItemsObject;
         globalArray[getLaunchItemsObject.responseTag] = getLaunchItemsObject;
      } else {
         getLaunchItemsObject.resetData();
      }

      if (!!getLaunchItemsObject && !!router) {
         supportedProtocols = globalArray.supportedProtocols || ["BLAST"];
         type = {};
         type.name = "remote";
         type.protocols = supportedProtocols;
         supportedAppProtocols = [];
         supportedAppProtocols[0] = type;
         if (supportedProtocols) {
            getLaunchItemsObject.setRequestXML(supportedProtocols, supportedAppProtocols);
            handlerList = getLaunchItemsObject.composeHandlerList();
            router.postMessage(handlerList);
         } else {
            Logger.error("supported protocols is invalid.");
         }
      }
   }

   if (!globalArray["set-user-global-preferences"]) {
      globalArray["set-user-global-preferences"] = new SetUserGlobalPrefHandler();
   }
};
