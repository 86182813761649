/**
 * ******************************************************
 * Copyright (C) 2022-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { clientUtil, LocalStorageService, Logger } from "@html-core";

export interface splitDeviceRule {
   vendorId: number;
   productId: number;
   excludedInterfaces?: Array<number>;
}

export interface splitDeviceeRuleResult {
   allowedSplitDevices: Array<splitDeviceRule>;
   blockedSplitDevices: Array<splitDeviceRule>;
}

@Injectable({
   providedIn: "root"
})
export class GoogleCommonSettings {
   private readonly IGNORE_CERT_ERRORS_BCR = "ignoreCertErrorsBcr";
   private readonly IGNORE_CERT_ERRORS_ENH_BCR = "ignoreCertErrorsEnhBcr";
   private readonly ENABLE_MEDIA_STREAM = "enableMediaStream";
   private readonly ENABLE_KEY_MAPPING = "enableKeyMapping";
   private readonly GOOGLE_COMMON_SETTINGS = "GoogleCommonSettings";
   private readonly BROWSER_REDIR = "browserRedir";
   private readonly BCR_ENABLED = "bcrEnabled";
   private readonly ENH_BCR_ENABLED = "enhBcrEnabled";

   private localStorageService: LocalStorageService = new LocalStorageService();
   private commonSettings: Map<string, boolean> = null;

   /**
    * Instance variable to store any setting that needs a default value
    */
   private defaultValues = {
      ignoreCertErrorsBcr: false,
      ignoreCertErrorsEnhBcr: true,
      enableMediaStream: true,
      enableKeyMapping: true,
      bcrEnabled: true,
      enhBcrEnabled: true
   };

   /**
    * Save the Google common admin settings passed in with local storage service.
    * @param settings
    */
   public setCommonSettings = (settings: any) => {
      if (settings === null) {
         Logger.error("Cannot save Google common admin settings to local storage, settings are null");
         return;
      }
      Logger.info("Saving Google common admin settings to local storage");
      this.localStorageService.set(this.GOOGLE_COMMON_SETTINGS, settings);
   };

   /**
    * Retrieve the boolean item from the common settings. If this is being called for the first
    * time, load the data from local storage to the commonSettings map for faster retrieval.
    * Will also load the defaultValues into the map the first time called.
    * @param key
    * @returns
    */
   private getCommonBooleanItem = (key: string): boolean => {
      if (!this.commonSettings) {
         Logger.info("Retrieving Google common settings for first time, load data from local storage");
         this.commonSettings = new Map<string, boolean>();
         this.loadCommonSettings();
         this.loadBrowserRedirSettings();
         this.loadDefaultValues();
      }
      return key !== null && this.commonSettings.has(key) && this.commonSettings.get(key);
   };

   /**
    * Return the value for ignoreCertError used in BCR
    */
   public isIgnoreCertErrorBcrEnabled = (): boolean => {
      return this.getCommonBooleanItem(this.IGNORE_CERT_ERRORS_BCR);
   };

   /**
    * Return the value for ignoreCertError used in ENH BCR
    */
   public isIgnoreCertErrorEnhBcrEnabled = (): boolean => {
      return this.getCommonBooleanItem(this.IGNORE_CERT_ERRORS_ENH_BCR);
   };

   /**
    * Return the value for enableMediaStream used in BCR
    */
   public isBCRMediaStreamEnabled = (): boolean => {
      return clientUtil.isChromeClient() && this.getCommonBooleanItem(this.ENABLE_MEDIA_STREAM);
   };

   public isKeyMappingnabled = (): boolean => {
      return this.getCommonBooleanItem(this.ENABLE_KEY_MAPPING);
   };

   public isBrowserRedirEnabled = (): boolean => {
      return this.getCommonBooleanItem(this.BCR_ENABLED);
   };

   public isEnhBrowserRedirEnabled = (): boolean => {
      return this.getCommonBooleanItem(this.ENH_BCR_ENABLED);
   };

   /**
    * Remove the Google common settings from the previous session.
    * Fixes edge case where you use policy and then remove it.
    */
   public removeCommonSettings = () => {
      Logger.info("Removing the Google common setting from previous session");
      this.localStorageService.remove(this.GOOGLE_COMMON_SETTINGS);
   };

   /**
    * Load the settings from local storage and save the boolean values in a map.
    * Ignores the editable setting and any value that is not a valid boolean.
    */
   private loadCommonSettings = () => {
      Logger.info("Load Google common settings from local storage");
      const tempCommonSettings = this.localStorageService.get(this.GOOGLE_COMMON_SETTINGS);

      if (tempCommonSettings) {
         for (const key in tempCommonSettings) {
            if (typeof tempCommonSettings[key] === "boolean") {
               this.commonSettings.set(key, tempCommonSettings[key]);
            }
         }
      } else {
         Logger.error("No local storage set for Google common settings");
      }
   };

   /**
    * if the commonAdminSettings map does not contain valid entries for keys in
    * defaultValues, load them into the map. Loops through default JSON adding them to map
    * if not already present.
    */
   private loadDefaultValues = () => {
      for (const key in this.defaultValues) {
         if (!this.commonSettings.has(key)) {
            Logger.info(`Google admin settings does not have value for ${key}, saving its default value`);
            this.commonSettings.set(key, this.defaultValues[key]);
         }
      }
   };

   /**
    * Add the BCR specific settings into the commonSettings map.
    */
   private loadBrowserRedirSettings = () => {
      Logger.info("Load Browser Content Redirection specific settings from common settings");
      const tempCommonSettings = this.localStorageService.get(this.GOOGLE_COMMON_SETTINGS);
      let googleBrowserRedir = null;
      if (!!tempCommonSettings && tempCommonSettings.hasOwnProperty(this.BROWSER_REDIR)) {
         googleBrowserRedir = tempCommonSettings[this.BROWSER_REDIR];
      } else {
         Logger.info("browserRedir rule is not in Google common policy");
      }

      if (googleBrowserRedir) {
         for (const key in googleBrowserRedir) {
            if (typeof googleBrowserRedir[key] === "boolean") {
               this.commonSettings.set(key, googleBrowserRedir[key]);
            }
         }
      }
   };

   public getSplitUsbSetting = (): splitDeviceeRuleResult => {
      let googleSplitRule = null;
      const tempCommonSettings = this.localStorageService.get(this.GOOGLE_COMMON_SETTINGS);

      if (!!tempCommonSettings && tempCommonSettings.hasOwnProperty("splitUSB")) {
         googleSplitRule = tempCommonSettings["splitUSB"];
      } else {
         Logger.info("splitUSB rule is not in Google common policy");
      }

      let allowedSplitDevices = new Array<splitDeviceRule>();
      let blockedSplitDevices = new Array<splitDeviceRule>();
      if (googleSplitRule) {
         const googleAllowList = googleSplitRule["allowList"];
         const googleBlockList = googleSplitRule["blockList"];

         if (googleAllowList && googleAllowList.length > 0) {
            allowedSplitDevices = this._formatSplitUSBData(googleAllowList, true);
         }
         if (googleBlockList && googleBlockList.length > 0) {
            blockedSplitDevices = this._formatSplitUSBData(googleBlockList, false);
         }
      }
      return {
         allowedSplitDevices: allowedSplitDevices,
         blockedSplitDevices: blockedSplitDevices
      };
   };

   private _formatSplitUSBData = (googlePolicyData: Array<any>, isAllowList: boolean): Array<splitDeviceRule> => {
      const resultList = new Array<splitDeviceRule>();
      for (let i = 0; i < googlePolicyData.length; i++) {
         let deviceId: string;
         if (isAllowList && googlePolicyData[i]) {
            if (googlePolicyData[i].device) {
               deviceId = googlePolicyData[i].device.split(":");
            }
         } else {
            deviceId = googlePolicyData[i].split(":");
         }
         let vid: number = 0,
            pid: number = 0,
            excludedInterfaces = new Array<any>();
         excludedInterfaces = googlePolicyData[i].excludedInterface;
         if (deviceId && deviceId.length === 2) {
            vid = parseInt(deviceId[0], 16) ? parseInt(deviceId[0], 16) : 0;
            pid = parseInt(deviceId[1], 16) ? parseInt(deviceId[1], 16) : 0;
         } else {
            Logger.info("split rule is not correct, please check in google policy");
            Logger.debug("split rule is not correct: " + googlePolicyData[i]);
            continue;
         }

         if (isAllowList) {
            if (typeof excludedInterfaces === "object" && excludedInterfaces.length > 0 && vid !== 0 && pid !== 0) {
               for (let j = 0; j < excludedInterfaces.length; j++) {
                  excludedInterfaces[j] = parseInt(excludedInterfaces[j], 16);
               }
               resultList.push({
                  vendorId: vid,
                  productId: pid,
                  excludedInterfaces: excludedInterfaces
               });
            } else {
               Logger.info("no interface info in split rule, please check in google policy");
               Logger.debug("split rule is incorrect: " + googlePolicyData[i]);
               continue;
            }
         } else {
            if (vid !== 0 && pid !== 0) {
               resultList.push({
                  vendorId: vid,
                  productId: pid
               });
            }
         }
      }
      return resultList;
   };
}
