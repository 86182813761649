/**
 * **************************************************************
 * Copyright (C) 2015-2020, 2023-2024 VMware, Inc. All rights reserved.
 * **************************************************************
 *
 * @format
 */

/**
 * @fileoverview mediaEncoder.js -- MediaEncoder
 */

import { Injectable } from "@angular/core";
import { StreamHeaderLength, CodecTypes } from "./rtav.constants";
import { LimitedSet } from "./v2/encoder.model";
import { Logger } from "@html-core";
import { toLength } from "lodash";

@Injectable()
export class MediaEncoder {
   private workers;
   private headers;
   private callbacks;
   private resourcesCount;
   private enabled;
   private encodingEnvs;
   private encoderStatus;
   private deviceManager;
   private codecPrefs;
   private buff;
   private occupied;
   private insert;
   private fetch;
   private audioLimited;
   private aaworkers;

   constructor() {
      this.enabled = false;
   }

   private resetEncoder = () => {
      this.headers = {
         audio: null,
         video: null,
         merged: null
      };
      this.callbacks = {
         video: null,
         audio: null,
         header: null
      };
      /**
       * number used for PV operation avoid taking too much of resources in the event queue,
       * and the values are get by testing on different machines, the bigger the number
       * the less data is dropped, but the more delay might exist.
       * in the release phase, if this object constains like 1, 1, we can change them into boolean,
       * but better to keep it for now without testing with the finalized codes.
       * @type {object}
       */
      this.resourcesCount = {
         audio: 50,
         video: 1
      };
      this.encodingEnvs = {
         audio: new LimitedSet(this.resourcesCount.audio),
         video: new LimitedSet(this.resourcesCount.video)
      };
      this.encoderStatus = {
         audio: {
            beingUsed: false
         },
         video: {
            beingUsed: false
         }
      };
      this.enabled = false;
   };

   public initInstance = () => {
      try {
         this.workers = {
            audio: new Worker("./audioworker." + __BUILD_NUMBER__ + ".js"),
            video: new Worker("./videoworker." + __BUILD_NUMBER__ + ".js")
         };
      } catch (e) {
         this.workers = {
            audio: null,
            video: null
         };
         Logger.error(e, Logger.RTAV);
      }
      this.workers.audio.onmessage = this.handleAudioMessage;
      this.workers.video.onmessage = this.handleVideoMessage;

      let handleWorkerError = (e) => {
         Logger.error(e, Logger.RTAV);
      };
      this.workers.audio.onerror = handleWorkerError;
      this.workers.video.onerror = handleWorkerError;
      this.resetEncoder();
   };

   private arrayCopy = (src, srcPtr, dst, dstPtr, length) => {
      let srcArray = new Uint8Array(src, srcPtr, length),
         dstArray = new Uint8Array(dst, dstPtr, length);

      dstArray.set(srcArray);
   };

   private clearWorker = (type) => {
      return new Promise<void>((resolve, reject) => {
         if (this.workers[type]) {
            Logger.debug("request clear of worker for " + type, Logger.RTAV);
            this.workers[type].onCleared = () => {
               Logger.debug("encoder cleared for " + type, Logger.RTAV);
               resolve();
            };
            this.workers[type].postMessage({
               type: "Clear"
            });
         } else {
            Logger.debug("skip clear since no worker found for " + type, Logger.RTAV);
            resolve();
         }
      });
   };

   private setMergedHeader = () => {
      let streamHeaderLength = StreamHeaderLength,
         videoHeaderLength = this.headers.video.length + streamHeaderLength,
         audioHeaderLength = this.headers.audio.length,
         mergedLength = videoHeaderLength + audioHeaderLength,
         mergedHeaderBuffer = new ArrayBuffer(mergedLength);

      this.arrayCopy(this.headers.video.buffer, 0, mergedHeaderBuffer, 0, videoHeaderLength);
      this.arrayCopy(
         this.headers.audio.buffer,
         streamHeaderLength,
         mergedHeaderBuffer,
         videoHeaderLength,
         audioHeaderLength
      );

      this.headers.merged = {
         buffer: mergedHeaderBuffer,
         length: mergedLength - streamHeaderLength
      };
   };

   private setAudioHeader = () => {
      let streamHeaderLength = StreamHeaderLength,
         audioHeaderLength = this.headers.audio.length + streamHeaderLength,
         mergedLength = audioHeaderLength,
         mergedHeaderBuffer = new ArrayBuffer(mergedLength);
      this.arrayCopy(this.headers.audio.buffer, 0, mergedHeaderBuffer, 0, audioHeaderLength);
      this.headers.merged = {
         buffer: mergedHeaderBuffer,
         length: mergedLength - streamHeaderLength
      };
   };

   private encode = (sample, type) => {
      let dataBuffer, envId;

      if (!this.workers[type] || !sample || !sample.data) {
         return;
      }
      if (this.resourcesCount[type] > 0) {
         this.resourcesCount[type]--;
         dataBuffer = sample.data;
         envId = this.encodingEnvs[type].insert({
            metaData: sample.timestamp,
            timestamp: sample.timestamp,
            others: sample.others
         });
         if (envId < 0) {
            Logger.error("when encoding media data, environment object can't be saved", Logger.RTAV);
            return;
         }
         this.workers[type].postMessage(
            {
               type: "Encode",
               data: dataBuffer,
               envId: envId
            },
            [dataBuffer]
         );
      }
   };

   private handleAudioMessage = (e) => {
      this.handleMessage(e, "audio");
   };

   private handleVideoMessage = (e) => {
      this.handleMessage(e, "video");
   };

   public handleMessage = (e, dataType) => {
      let message = e.data,
         callback,
         env;

      if (!this.enabled) {
         Logger.debug("ignore message " + message.type + " for " + dataType + " due to encoder disabled", Logger.RTAV);
         return;
      }
      switch (message.type) {
         case "InitDone":
            Logger.debug("media encoder handleMessage initDone enter", Logger.RTAV);
            this.headers[dataType] = message.data;
            if (typeof this.callbacks.header !== "function") {
               Logger.error("header callback doesn't exist", Logger.RTAV);
               return;
            }
            if (this.codecPrefs === CodecTypes["CodecVmwH264Opus"]) {
               // in situation 'CodecVmwH264Opus'
               Logger.debug("set merge header", Logger.RTAV);
               this.callbacks.header(this.headers.merged);
            } else if (this.codecPrefs === CodecTypes["CodecVmwH264Speex"]) {
               // in situation 'CodecVmwH264Speex'
               if (this.headers.video === null && !!this.headers.audio) {
                  Logger.debug("audio encoder header needed", Logger.RTAV);
                  this.setAudioHeader();
                  this.callbacks.header(this.headers.merged);
               }
            } else {
               // in situation 'CodecVmwTheoraSpeex'
               if (!!this.headers.video && !!this.headers.audio) {
                  this.setMergedHeader();
                  Logger.debug("merged audio and video header", Logger.RTAV);
                  this.callbacks.header(this.headers.merged);
               }
            }
            Logger.debug("media encoder handleMessage initDone exit", Logger.RTAV);
            break;
         case "Encoded":
            env = this.encodingEnvs[dataType].fetch(message.envId);
            if (!env || !env.metaData) {
               Logger.debug("negative: bad env read, might caused by switching devices to other desktop", Logger.RTAV);
               return;
            }
            callback = this.callbacks[dataType];
            if (typeof callback !== "function") {
               Logger.error("encoding callback doesn't exist", Logger.RTAV);
               return;
            }
            callback(message.data, env.metaData, env.others);
            this.resourcesCount[dataType]++;
            break;
         case "Cleared":
            Logger.debug(dataType + " encoder cleared:" + message.success, Logger.RTAV);
            if (typeof this.workers[dataType].onCleared === "function") {
               this.workers[dataType].onCleared(message.success);
               this.workers[dataType].onCleared = null;
            } else {
               Logger.error(dataType + " encoder cleared but no callback found", Logger.RTAV);
            }
            break;
      }
   };

   /**
    * Init the encoder using params and register the callbacks to handle header and encoded data
    * now we will provide no default value, so caller must prepare the good init param for initialize
    * @param  {object} audioParam      The param used to init audio encoder
    * @param  {object} videoParam      The param used to init video encoder
    * @param  {function} headerCallback  The callback use to handle inited header
    * @param  {function} audioCallback The callback use to handle encoded audio
    * @param  {function} videoCallback The callback use to handle encoded video
    */
   public init = (
      audioParam,
      videoParam,
      codecPref,
      hardwareAccelerationOption,
      enableRTAVDTX,
      headerCallback,
      audioCallback,
      videoCallback
   ) => {
      let videoWorker = this.workers.video,
         audioWorker = this.workers.audio;

      if (!videoWorker || !audioWorker || this.enabled) {
         return;
      }
      this.callbacks = {
         header: headerCallback,
         audio: audioCallback,
         video: videoCallback
      };
      this.codecPrefs = codecPref;
      audioParam.logLevel = Logger.getLogLevel();
      videoParam.logLevel = audioParam.logLevel;
      videoParam.codecPref = codecPref;
      videoParam.hardwareAccelerationOption = hardwareAccelerationOption;
      audioParam.codecPref = codecPref;
      audioParam.enableRTAVDTX = enableRTAVDTX;
      // although js is non-preemptive, put enabled = true to later place will add complex for UT
      this.enabled = true;
      audioWorker.postMessage({
         type: "Init",
         data: audioParam
      });
      videoWorker.postMessage({
         type: "Init",
         data: videoParam
      });
   };

   /**
    * This function is to release the resources for encoding.
    */
   public clear = (callback) => {
      Logger.debug("clear encoders for none of audio and video is used", Logger.RTAV);
      Promise.all([this.clearWorker("audio"), this.clearWorker("video")]).then(() => {
         Logger.debug("both worker cleared", Logger.RTAV);
         this.resetEncoder();
         callback(true);
      });
   };

   /**
    * @param  {object} sample {data: {Int16Array}, timeStamp: {number}}
    */
   public encodeAudio = (sample) => {
      this.encode(sample, "audio");
   };

   /**
    * @param  {object} sample {data: {Uint8Array}, timeStamp: {number}}
    */
   public encodeVideo = (sample) => {
      this.encode(sample, "video");
   };

   /**
    * When we don't need to use the encoder of type, one should call this function to release resources
    * and also set the media encoder to be ready for re-inited. it's related to stop_A and stop_V message
    * @param  {string} type The encoder type name, should be 'audio' or 'video'
    * @returns {Promise} This returns a promise resolved with boolean
    */
   public disable = (type) => {
      return new Promise((resolve) => {
         Logger.debug("disabling enc for " + type, Logger.RTAV);
         if (!this.encoderStatus[type].beingUsed) {
            Logger.debug("negative: no need to disable a disabled encoder: " + type, Logger.RTAV);
            resolve(true);
            return;
         }
         this.encoderStatus[type].beingUsed = false;
         // release all the resources when both encoder are in use no more.
         if (!this.encoderStatus["audio"].beingUsed && !this.encoderStatus["video"].beingUsed) {
            if (!this.deviceManager) {
               Logger.error("deviceManager don't exist when disable " + type, Logger.RTAV);
               resolve(false);
               return;
            }
            if (this.deviceManager.isPermissionPending()) {
               Logger.debug("negative: skip clear encoders since the permission dialog is still pending", Logger.RTAV);
               resolve(false);
            } else {
               Logger.debug("clear enc since last request had been disabled " + type, Logger.RTAV);
               this.clear(resolve);
            }
         } else {
            Logger.debug("enc status changed for " + type, Logger.RTAV);
            resolve(true);
         }
      });
   };

   /**
    * When we need to use the encoder of type, one should call this function to record the use status and
    * ready to release resources after both encoder are useded no more. it's related to start_A and start_V
    * message.
    * @param  {string} type The encoder type name, should be 'audio' or 'video'
    * @returns {Promise} This returns a promise resolved with boolean
    */
   public enable = (type) => {
      return new Promise((resolve) => {
         if (this.encoderStatus[type].beingUsed) {
            Logger.error("fail to enable a enabled encoder: " + type, Logger.RTAV);
            // treat enable a enabled encoder as success
            resolve(true);
            return;
         }
         this.encoderStatus[type].beingUsed = true;
         resolve(true);
      });
   };

   /**
    * return whether this encoder is inited
    */
   public isInited = () => {
      return this.enabled;
   };

   /**
    * Get previously generated header from the cache, it can faster the whole process and avoid blink in video
    */
   public fetchCachedHeader = () => {
      if (!this.enabled) {
         Logger.error("fail to get cached header for enc is not inited yet", Logger.RTAV);
         return;
      }
      if (typeof this.callbacks.header !== "function") {
         Logger.error("fail to get cached header for callback is not set yet", Logger.RTAV);
         return;
      }
      this.callbacks.header(this.headers.merged, true);
   };

   /**
    * Used for get whether the encoder is working, for bug 1750132.
    * @return {boolean} Whether any of audio or video is being used
    */
   public notInUse = () => {
      return !this.encoderStatus["audio"].beingUsed && !this.encoderStatus["video"].beingUsed;
   };

   /**
    * Used to check the mismatch status before clear up.
    * @param {object} manager A DeviceMessageManager instance
    */
   public setDeviceManager = (deviceManager) => {
      this.deviceManager = deviceManager;
   };

   /**
    * Used for Unit Tests to expose some of inner varibles, NEVER use it in the code.
    * @return {obj} This returns the obj contains UT needed data references
    */
   public utTestingAPIs = () => {
      return {
         headers: this.headers,
         callbacks: this.callbacks
      };
   };
}
