/**
 * ******************************************************
 * Copyright (C) 2014-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * timer.js --
 *
 * timer class
 *
 */

import Logger from "../../../core/libs/logger";

export const timerTypeEnum = {
   timeout: "timeoutTimer",
   interval: "intervalTimer"
};

export function Timer(timerType) {
   if (timerType !== timerTypeEnum.timeout && timerType !== timerTypeEnum.interval) {
      Logger.error("unknown type string passed in Timer()");
      return;
   }
   this.type = timerType;
   this.timerValue = -1;
   this.timerId = null;
   this.eventCallbackFunc = null;
   this.enable = false;
   this.params = null;
   if (this.type === timerTypeEnum.timeout) {
      this.targetTime = -1;
      this.timerCreateFunction = function (callback, millisecond, params) {
         if (this.timerId) {
            Logger.error("timeout timerId " + this.timerId + " do exist while try create it");
            return;
         }
         this.timerId = setTimeout(callback, millisecond, params);
         Logger.debug("timeout timer " + this.timerId + " created");
      };
      this.timerStopFunction = function () {
         if (!this.timerId) {
            Logger.error("timeout timerId don't exist when try stop it");
            this.setDisable();
            return;
         }
         clearTimeout(this.timerId);
         Logger.debug("timeout timer " + this.timerId + " deleted");
         this.timerId = null;
      };
   } else {
      this.timerCreateFunction = function (callback, millisecond, params) {
         if (this.timerId) {
            Logger.error("interval timerId " + this.timerId + " do exist while try create it");
            return;
         }
         this.timerId = setInterval(callback, millisecond, params);
         Logger.debug("interval timer " + this.timerId + " created");
      };
      this.timerStopFunction = function () {
         if (!this.timerId) {
            Logger.error("interval timerId don't exist when try stop it");
            this.setDisable();
            return;
         }
         clearInterval(this.timerId);
         Logger.debug("interval timer " + this.timerId + " deleted");
         this.timerId = null;
      };
   }
}

//only store functions in prototype
Timer.prototype = {};
Timer.constructor = Timer;

Timer.prototype.getCurrentTime = function () {
   return new Date().getTime();
};

Timer.prototype.isEnabled = function () {
   return this.enable === true;
};

Timer.prototype.init = function (eventCallbackFunc, timerValue, params) {
   if (!this.isEnabled()) {
      return;
   }
   if (timerValue <= 0) {
      //invalid
      if (this.isEnabled()) {
         Logger.debug("timer init with negative timeout value:" + timerValue);
         this.discard();
      }
      return;
   }
   this.timerValue = timerValue;
   this.eventCallbackFunc = eventCallbackFunc;
   this.params = params;
};

Timer.prototype.ensureUpdatedOrStarted = function () {
   if (!this.isEnabled()) {
      return;
   }
   if (this.timerId) {
      this.stop();
      this.start();
   } else {
      this.start();
   }
};

Timer.prototype.start = function () {
   if (!this.isEnabled()) {
      return;
   }
   if (this.type === timerTypeEnum.timeout) {
      //the absolute time in secs that once reached, the callback will be
      // triggered
      this.targetTime = this.getCurrentTime() + this.timerValue;
   }
   this.timerCreateFunction(this.eventCallbackFunc, this.timerValue, this.params);
};

Timer.prototype.stop = function () {
   if (!this.isEnabled()) {
      return;
   }
   this.timerStopFunction();
};

Timer.prototype.getTargetTime = function () {
   if (!this.isEnabled()) {
      return null;
   }
   let restTime = null;
   if (this.type === timerTypeEnum.timeout && this.targetTime > 0) {
      restTime = this.targetTime - this.getCurrentTime();
      Logger.debug("\nget timer rest time:" + restTime);
   } else {
      Logger.error("getRestTime is being misused!");
   }
   return this.targetTime;
};

Timer.prototype.updateTimeoutValue = function (newTimerValue) {
   if (!this.isEnabled()) {
      return;
   }
   if (this.type === timerTypeEnum.timeout) {
      if (newTimerValue < 0) {
         Logger.debug("timeout timer not reset with new timeout value:" + newTimerValue);
         return;
      }
      this.timerValue = newTimerValue;
      this.stop();
      this.start();
      Logger.debug("timeout timer reset with new timeout value:" + newTimerValue);
   } else {
      Logger.error("the updateTimeoutValue method is not for the intervalTimer");
   }
};

Timer.prototype.setEnable = function () {
   this.enable = true;
};

Timer.prototype.setDisable = function () {
   this.enable = false;
};

Timer.prototype.discard = function () {
   //only preserve type info
   this.stop();
   this.timerValue = -1;
   this.timerId = null;
   this.eventCallbackFunc = null;
   this.params = null;
   if (this.type === timerTypeEnum.timeout) {
      this.targetTime = -1;
   }
   this.setDisable();
};

Timer.prototype.ensureStopped = function () {
   if (!this.isEnabled()) {
      return;
   }
   this.discard();
};
