/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injector } from "@angular/core";
import { BusEvent, TitanMsg } from "@html-core";
import { takeUntil } from "rxjs/operators";
import { BlastWmks } from "../common/blast-wmks.service";
import { BlastOption, PROTOCOL_RECOVERY_STATE } from "./blast-option";
import { NetWorkMonitorType } from "./net-watch/network-monitor-type";
import { NetworkMonitorOptions } from "./net-watch/network-monitor.option";
import { NetworkMonitorService } from "./net-watch/network-monitor.service";
import {
   BlastCloseEvent,
   blastSessionErrorMessage,
   isMKSSessionClosedNormally,
   webMksReconnectFailure
} from "./wmks-error-handler";

export class TitanBlastSessionInstance extends BlastWmks {
   private blastMonitor: NetworkMonitorService = null;
   private networkConnected: boolean = true;
   private heartBeatStatus: number = 0;
   private titanDisconnectDialogId = null;
   private protocolRecoveryState: PROTOCOL_RECOVERY_STATE = PROTOCOL_RECOVERY_STATE.UNKNOWN;
   private retryWhenNetworkReady: boolean = false;
   constructor(injector: Injector, opt: BlastOption) {
      super(injector, opt);
      this.blastMonitor = new NetworkMonitorService(injector);

      this.blastMonitor
         .monitor()
         .pipe(takeUntil(this.destroy$))
         .subscribe((currentState) => {
            this.heartBeatStatus = currentState.heatBeatStatus;
            if (currentState.hasNetworkConnection && currentState.hasInternetAccess) {
               this.networkConnected = true;

               this.logger.debug("blast session ON_LINE");
               if (this.retryWhenNetworkReady) {
                  this.retryWhenNetworkReady = false;
                  this._startReconnection();
               } else {
                  this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
               }
            } else {
               this.networkConnected = false;
               this.logger.info("blast session OFF_LINE");
               if (this.isForeground()) {
                  this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
               }
               this.closeModalDialog();
            }
         });

      this.eventBusService
         .listen(TitanMsg.ProtocolRecoveryResponse.MSG_TYPE)
         .pipe(takeUntil(this.destroy$))
         .subscribe((msg: TitanMsg.ProtocolRecoveryResponse) => {
            if (msg.id === this.key) {
               this.url = msg.address;
               this.wasWmksConnectedOnce = false;
               this._wmksService.updateTriedSSLVerify();
               this.logger.info(`<Protocol-Recovery> done for ${msg.id}, change url to ${this.url}`);
               this.reconnectSession();
               this.protocolRecoveryState = PROTOCOL_RECOVERY_STATE.DONE;
            }
         });
   }

   public onInitialize = () => {
      const opt: NetworkMonitorOptions = {} as NetworkMonitorOptions;
      const heartbeatAddr = this.getHeartBeatAddress();
      // if (heartbeatAddr && false) {
      //    // TODO - TITAN UAG-RECONNECTION - HV-13496
      //    // Need the UAG and blast worker to enable CORS.
      //    opt.heartbeatUrl = heartbeatAddr;
      //    opt.requestMethod = "get";
      //    opt.networkMonitorType = NetWorkMonitorType.UAG_MONITOR;
      //    this.blastMonitor.updateOptions(opt);
      // }
   };

   public reconnectHandler = (code: number, closeReason: number) => {
      /*
       * Reconnect Flow
       * => 1. If the session fail to init the connection for the first time.
       *       => destroy session.
       * => 2. If the session is closed normally
       *       => destroy session.
       *       => show corresponding error message.
       * => 3. If the session is failed in reconnecting.
       *       => destroy session.
       *       => show corresponding error message.
       * => 4. If able to do UAG short term reconnection
       *       => UAG short term reconnection
       * => 5. If able to do network recovery
       *       => webmks reconnect
       */
      if (!this.wasWmksConnectedOnce) {
         this.logger.info(`Titan blast session init connection failed - ${code} - ${closeReason}`);
      } else if (isMKSSessionClosedNormally(code, closeReason)) {
         this.logger.info(`Titan blast session normal close - ${code} - ${closeReason}`);
      } else if (webMksReconnectFailure(code)) {
         this.logger.info(`Titan blast session reconnection failed - ${code} - ${closeReason}`);
      } else if (this.canDoNetworkRecovery(code, closeReason)) {
         if (this.isForeground()) {
            this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(true));
         }
         if (this.canDoReconnectForUAG(code, closeReason)) {
            this.logger.info("Titan blast session do UAG short Term Reconnection");
            this.sendProtocolRedirectionMsg();
         } else if (this.networkConnected) {
            this.logger.info("Titan blast session do network recovery now");
            this._startReconnection();
         } else {
            this.logger.info("Titan blast session network recovery suspend");
            this.retryWhenNetworkReady = true;
         }
         return;
      } else {
         this.logger.info(`Titan blast session unexpected disconnect - ${code} - ${closeReason}`);
      }
      this.destroy();
      this.eventBusService.dispatch(new BusEvent.AjaxBusyMsg(false));
      // only show close message if the session set up.
      if (this.vvc) {
         const displayMsg = blastSessionErrorMessage(code, closeReason);
         this.showModalDialog(displayMsg);
      }
   };

   private sendProtocolRedirectionMsg = () => {
      if (this.protocolRecoveryState === PROTOCOL_RECOVERY_STATE.PENDING) {
         this.logger.info("protocolRecovery command is already sent");
         return;
      }
      const msg: TitanMsg.ProtocolRecoveryRequest = new TitanMsg.ProtocolRecoveryRequest();
      msg.id = this.key;
      msg.isApplication = this.isApplicationSession;
      msg.protocolToken = this.redirectSetting?.protocolToken;
      msg.brokerUrl = this.brokerUrl;
      msg.uagUrl = this.redirectSetting?.url;
      msg.tunnelToken = this.redirectSetting?.tunnelToken;
      msg.dspecId = this.dspecId;
      this.eventBusService.dispatch(msg);
      this.protocolRecoveryState = PROTOCOL_RECOVERY_STATE.PENDING;
      this.logger.info("<Protocol-Recovery> send protocolRecovery command");
   };

   private getHeartBeatAddress = (): string => {
      const match = this.url.match(/wss:\/\/(\[?\w.+:\d+\/\S*\/)\S*/);
      if (!(match && match.length >= 2)) {
         return null;
      }
      return "https://" + match[1] + "certAccept.html";
   };

   private showModalDialog = (msg: string) => {
      if (this.titanDisconnectDialogId === null) {
         this.titanDisconnectDialogId = this.modalDialogService.showError({
            data: {
               title: this.translate._T("DESKTOP_DISCONNECTED_T"),
               content: this.translate._T(msg),
               buttonLabelConfirm: this.translate._T("CLOSE")
            }
         });
      }
   };

   private closeModalDialog = () => {
      if (this.titanDisconnectDialogId && this.modalDialogService.isDialogOpen(this.titanDisconnectDialogId)) {
         this.modalDialogService.close(this.titanDisconnectDialogId);
      }
      this.titanDisconnectDialogId = null;
   };

   private canDoReconnectForUAG = (code: number, reason: number) => {
      if (this.blastMonitor.getNetworkMonitorType() === NetWorkMonitorType.GLOBAL_MONITOR) {
         if (code === BlastCloseEvent.ABNORMAL_CLOSURE && this.networkConnected) {
            this.logger.info("do UAG reconnection with GLOBAL_MONITOR");
            return true;
         }
      }

      if (code === BlastCloseEvent.ABNORMAL_CLOSURE && this.networkConnected) {
         if (this.heartBeatStatus === 404) {
            this.logger.info("do UAG reconnection with UAG_MONITOR");
            return true;
         }
      }
      return false;
   };

   private canDoNetworkRecovery = (code: number, reason: number) => {
      return !this.isWindows365 && code === BlastCloseEvent.ABNORMAL_CLOSURE;
   };
}
