/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * ws1-mode.service.ts
 * Service for Workspace one integration
 */

import { globalArray } from "../jscdkClient";
import { Logger, clientUtil } from "@html-core";

export default function Ws1ModeService() {
   this.globalName = "ws1-mode-service";
   this.STORAGE_KEY = "ws1Mode";
}

Ws1ModeService.prototype = {};

Ws1ModeService.constructor = Ws1ModeService;

Ws1ModeService.prototype.updateWs1Mode = function (ws1ModeEnabled) {
   if (clientUtil.isChromeClient()) {
      return;
   }
   const writeLocalStorage = globalArray["write-function"];
   const removeLocalStorage = globalArray["remove-function"];

   if (ws1ModeEnabled) {
      if (!writeLocalStorage) {
         Logger.debug("globalArray['write-function'] is not defined");
      }

      /**
       * Set ws1 mode flag in session storage, then it could be used to detect
       * if client is in ws1 mode, even after refreshing the page.
       *
       * Store this flag in local storage, with extra effort to clear the flag
       * for bug 2575765
       */
      writeLocalStorage(this.STORAGE_KEY, "true");
   } else {
      // Please see the details of this in bug2280282 and bug2088669
      if (!removeLocalStorage) {
         Logger.debug("globalArray['remove-function'] is not defined");
      }
      removeLocalStorage(this.STORAGE_KEY);
   }
};
