/**
 * ***************************************************************
 * Copyright (C) 2015-2018, 2023-2024 VMware, Inc. All rights reserved.
 * ****************************************************************
 *
 * @format
 */

/**
 * * @fileoverview rtavSettings.ts -- settings
 * settings for rtav feature
 * will support setter and getter for run time change in the later patch
 */

export const settings = {
   // the default setting will be used only when no any agent setting read.
   videoV2: {
      defMaxFPS: 30,
      defResWidth: 320,
      defResHeight: 240,
      videoDevicesParam: []
   },
   audioV2: {
      audioDevicesParam: []
   },
   video: {
      defMaxFPS: 30,
      defResWidth: 320,
      defResHeight: 240
   },
   audio: {
      sampleRate: null,
      channels: null,
      bitsPerSample: null
      //audioDevicesParam: []
   },
   enabled: true,
   killSwitchOn: true
};
