/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { CLIENT } from "@html-core";

export class BaseViewClientModel {
   public vendor: string = "VMware";
   // Architecture of client
   public arch: string = "browser";
   public product: string = "";
   // Product version
   public version: string = CLIENT.clientVersion;
   // unique client ID
   public clientID: string = "";
   public linkModel = {
      // Help Pages, now Unique ID
      helpPages: {},
      // go to forum Pages
      gotoForumPages: {
         en: "https://communities.vmware.com/community/vmtn/horizon/view"
      },
      /**
       * EULAs of Chrome Client and HTML Access
       * https://confluence.eng.vmware.com/pages/viewpage.action?spaceKey=VRM&title=UniversalEULA
       *  TW, IT, ES, BR, RU, NL : We do not support localized download pages for these locales.
       *  Hence, we don't have localized EULA landing pages for them. Use the English EULA landing page for them instead.
       */
      eulaPages: {
         en: BaseViewClientModel.generateEULA("en"),
         de: BaseViewClientModel.generateEULA("de"),
         es: BaseViewClientModel.generateEULA("es"),
         fr: BaseViewClientModel.generateEULA("fr"),
         ja: BaseViewClientModel.generateEULA("ja"),
         ko: BaseViewClientModel.generateEULA("ko"),
         "zh-CN": BaseViewClientModel.generateEULA("zh-cn"),
         "zh-TW": BaseViewClientModel.generateEULA("zh-tw")
      },

      patentPages: {
         en: BaseViewClientModel.generatePatent("en"),
         de: BaseViewClientModel.generatePatent("de"),
         es: BaseViewClientModel.generatePatent("es"),
         fr: BaseViewClientModel.generatePatent("fr"),
         ja: BaseViewClientModel.generatePatent("jp"),
         ko: BaseViewClientModel.generatePatent("kr"),
         "zh-CN": BaseViewClientModel.generatePatent("cn"),
         "zh-TW": BaseViewClientModel.generatePatent("tw")
      },

      /**
       *  OSL of Chrome Client and HTML Access
       */
      oslPages: {
         en: BaseViewClientModel.generateOSL("en"),
         de: BaseViewClientModel.generateOSL("de"),
         es: BaseViewClientModel.generateOSL("en"),
         fr: BaseViewClientModel.generateOSL("fr"),
         ja: BaseViewClientModel.generateOSL("jp"),
         ko: BaseViewClientModel.generateOSL("kr"),
         "zh-CN": BaseViewClientModel.generateOSL("cn"),
         "zh-TW": BaseViewClientModel.generateOSL("en")
      }
   };

   public MIN_VERSION = {
      IE: 10,
      Firefox: 36,
      Gecko: 21,
      Chrome: 41,
      Safari: 7,
      WebKit: 536
   };

   public acceptLanguage = null;
   public clientVersion = null;
   public logLevel = null;
   public ipAddress = null;
   public rebrandName = null;
   public rebrandEnable: boolean = false;
   public rebrandHelpUrl = "";
   public rebrandGotoForumUrl = "";
   public windowsTimezone = null;

   /**
    *  Features in 'client-config' of broker response
    *  They're updated in 'session-data.service.ts' now.
    */
   public clientHideDomainList = false;
   public clientHideServerInformation = false;
   public enableCredentialCleanupForHTMLAccess = false;

   constructor(isChrome: boolean) {
      if (isChrome) {
         this.product = "VMware Horizon Client for Chrome";
         this.linkModel["helpPages"] = {
            en: BaseViewClientModel.generateChromeClientHelpLink("en"),
            de: BaseViewClientModel.generateChromeClientHelpLink("de"),
            es: BaseViewClientModel.generateChromeClientHelpLink("es"),
            fr: BaseViewClientModel.generateChromeClientHelpLink("fr"),
            ja: BaseViewClientModel.generateChromeClientHelpLink("jp"),
            ko: BaseViewClientModel.generateChromeClientHelpLink("kr"),
            "zh-CN": BaseViewClientModel.generateChromeClientHelpLink("cn"),
            "zh-TW": BaseViewClientModel.generateChromeClientHelpLink("tw")
         };
      } else {
         this.product = "VMware Horizon HTML Access";
         this.linkModel["helpPages"] = {
            en: BaseViewClientModel.generateHtmlAccessHelpLink("en"),
            de: BaseViewClientModel.generateHtmlAccessHelpLink("de"),
            es: BaseViewClientModel.generateHtmlAccessHelpLink("es"),
            fr: BaseViewClientModel.generateHtmlAccessHelpLink("fr"),
            ja: BaseViewClientModel.generateHtmlAccessHelpLink("jp"),
            ko: BaseViewClientModel.generateHtmlAccessHelpLink("kr"),
            "zh-CN": BaseViewClientModel.generateHtmlAccessHelpLink("cn"),
            "zh-TW": BaseViewClientModel.generateHtmlAccessHelpLink("tw")
         };
      }
   }

   public static generateEULA = (local) => {
      //https://www.vmware.com/content/dam/digitalmarketing/vmware/en/pdf/agreements/vmware-general-terms.pdf
      return (
         "https://www.vmware.com/content/dam/digitalmarketing/vmware/" +
         local +
         "/pdf/agreements/vmware-general-terms.pdf"
      );
   };

   public static generateOSL = (local) => {
      return (
         "https://my.vmware.com/" +
         local +
         "/web/vmware/details?" +
         "downloadGroup=" +
         CLIENT.cartName +
         "_CARTANDLINUX_OSS&productId=578"
      );
   };

   public static generateHtmlAccessHelpLink = (local) => {
      return (
         "https://docs.vmware.com/" +
         local +
         "/VMware-Horizon-HTML-Access/" +
         this._generateMajorVersion() +
         "/" +
         "html-access-installation/GUID-AD6BF5D5-92EA-4762-A8C7-6E30DC3B3908.html"
      );
   };

   public static generateChromeClientHelpLink = (local) => {
      return (
         "https://docs.vmware.com/" +
         local +
         "/VMware-Horizon-Client-for" +
         "-Chrome/" +
         this._generateMajorVersion() +
         "/chrome-client-installation/GUID-B9D1F460-E716-4DF1-8CE2-D7342A106531.html"
      );
   };

   public static generatePatent = (local) => {
      if (local === "en") {
         return "https://www.vmware.com/go/patents";
      }
      return "https://www.vmware.com/go/patents-" + local;
   };

   private static _generateMajorVersion = () => {
      //When there is a minor version, only return major version
      const versionArray = CLIENT.marketVersion.split(".");
      return versionArray[0];
   };
}
