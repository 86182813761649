/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component, Input } from "@angular/core";

// This is used inside of login page, instead of the general loading icon
@Component({
   selector: "loading-area",
   template: ` <div class="loading-area">
      <div
         *ngIf="blankOutContent && !cancelLoading"
         class="loginLoadingText"
         tabindex="0"
         attr.aria-label="{{ 'dialog_btn_signin_waiting' | i18nv2 }}"
         [autoFocus]="blankOutContent && !cancelLoading"
      >
         {{ "dialog_btn_signin_waiting" | i18nv2 }}
      </div>
      <div
         *ngIf="blankOutContent && cancelLoading"
         class="cancelLoadingText"
         tabindex="0"
         attr.aria-label="{{ 'cancel_loading_text' | i18nv2 }}"
         [autoFocus]="blankOutContent && cancelLoading"
      >
         {{ "cancel_loading_text" | i18nv2 }}
      </div>
      <loading-image-animation [showLoading]="blankOutContent"></loading-image-animation>
      <div></div>
   </div>`
})
export class loadingAreaComponent {
   @Input() public blankOutContent: boolean;
   @Input() public cancelLoading: boolean;
}
