/**
 * ******************************************************
 * Copyright (C) 2016-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * normalization-service.js -- normalizationService
 *
 * 3+ monitor support is not added in the 17Q2
 * And only support same DPI for now, checkpoint tracking has not been adapted yet.
 *
 */

import { Injectable } from "@angular/core";
import { clientUtil } from "@html-core";
import Logger from "../../core/libs/logger";
import { FeatureConfigs } from "../common/model/feature-configs";
import { DisplayCheckService } from "../desktop/common/display-check.service";
export class CoordinatePoint {
   public x: number;
   public y: number;
}

@Injectable()
export class NormalizationService {
   private rawSetting: any;
   private DPIEnabled: boolean;
   private normalizationModel: Map<string, any>;
   private normalizedSettings: Map<string, DisplayBaseInfo>;
   private defaultModel: NormalizationParam = {} as NormalizationParam;
   private agentDPI: any;
   private screenInfo: any;
   private screenLayout: any;
   private disableDisplayScale: boolean;
   private targetPerSystemDPI: boolean;
   private canFallbackPrimaryDPI: boolean;

   // all monitors under notch monitor
   private screenBaseUnderNotchMonitor = new Map();

   public clear = () => {
      this.rawSetting = {};
      this.DPIEnabled = false;
      this.normalizationModel = new Map<string, any>();
      this.normalizedSettings = new Map<string, DisplayBaseInfo>();
      this.defaultModel = {} as NormalizationParam;
      this.agentDPI = 1.0;
   };

   constructor(
      private _displayCheckService: DisplayCheckService,
      private _featureConfigs: FeatureConfigs
   ) {
      this.clear();
      if (clientUtil.isChromeClient()) {
         this.setScreensInfo();
      }
      this.defaultModel = {
         skew_x: 0,
         skew_y: 0,
         scale: devicePixelRatio
      };
      // Bug 2857170, for application on Chrome Client, always target at per System DPI sync
      this.targetPerSystemDPI =
         window.location.href.indexOf("app-window.html") >= 0 ||
         window.location.href.indexOf("app-extended-monitor.html") >= 0;
      /**
       * Bug 2858461 & 2872570, for desktop on Chrome Client and sessions on HTML Access, allow
       * fallback when Agent DPI is known and display scale is enabled
       */
      this.canFallbackPrimaryDPI = !this.targetPerSystemDPI;
   }

   public setAgentDPI = (dpi: number): void => {
      Logger.info("set Agent DPI for normalize service" + dpi, Logger.NORMALIZATION);
      this.agentDPI = dpi;
   };

   /**
    * Returns the scale factor, which is depending the definition of "High
    * Resolution" option
    * Now using agent DPI scale, since the final design is so, and logic for
    * single monitor also get changed accordingly.
    *
    * We use Per monitor DPI sync for supported Agents,
    * While use Per system DPI for old Agents, to avoid client regression rate,
    * Since most of primary monitor of laptop would has higher DPI than extended.
    */
   public getFactor = (id: string): number => {
      if (!this.rawSetting.hasOwnProperty(id)) {
         Logger.warning("missing screen info for screen " + id + ", use agentDPI to scale", Logger.NORMALIZATION);
         return this.agentDPI;
      }
      if (this.disableDisplayScale) {
         Logger.info("under per monitor setup", Logger.NORMALIZATION);
      } else {
         Logger.info("under per system setup", Logger.NORMALIZATION);
      }
      let clientScaleFactor;
      const localDPI = this.rawSetting[id].devicePixelRatio;
      let clientScreenFactor;
      if (this.disableDisplayScale) {
         clientScaleFactor = 1;
         Logger.info("use scale factor " + clientScaleFactor + " for screen" + id, Logger.NORMALIZATION);
      } else {
         // Check details in VHCH-6106, fallback to per system for old Agent
         let targetDPI;
         if (this.targetPerSystemDPI) {
            targetDPI = this.DPIEnabled ? this.rawSetting[0].devicePixelRatio : 1;
            Logger.info(
               "Target to primary DPI as " + targetDPI + " for application screens" + id,
               Logger.NORMALIZATION
            );
         } else {
            targetDPI = this.DPIEnabled ? this.rawSetting[id].devicePixelRatio : 1;
         }
         clientScaleFactor = this.agentDPI / targetDPI;
         const idealClientScreenFactor = clientScaleFactor * localDPI;

         // Use Primary DPI to fallback to old client behavior to avoid over size screen
         if (this.canFallbackPrimaryDPI) {
            if (
               Math.floor(idealClientScreenFactor * this.rawSetting[id].width) > 3840 ||
               Math.floor(idealClientScreenFactor * this.rawSetting[id].height) > 2560
            ) {
               targetDPI = this.DPIEnabled ? this.rawSetting[0].devicePixelRatio : 1;
               clientScaleFactor = this.agentDPI / targetDPI;
               Logger.info("Fallback to primary target DPI as " + targetDPI + " for screen" + id, Logger.NORMALIZATION);
            } else {
               Logger.info("Use diplay DPI as target as " + targetDPI + " for screen" + id, Logger.NORMALIZATION);
            }
         } else {
            Logger.info("skip DPI target fallback check for screen" + id, Logger.NORMALIZATION);
         }
         Logger.info("use scale factor " + clientScaleFactor + " for screen" + id, Logger.NORMALIZATION);
      }
      clientScreenFactor = clientScaleFactor * localDPI;
      Logger.info(
         "use screen factor " + clientScreenFactor + " for screen" + id + " with local screen DPI " + localDPI,
         Logger.NORMALIZATION
      );
      return clientScreenFactor;
   };

   public setDPISync = (enabled: boolean, disableDisplayScale: boolean = false) => {
      Logger.info(
         "set DPI sync for normalize service enabled:" + enabled + ", disableDisplayScale:" + disableDisplayScale,
         Logger.NORMALIZATION
      );
      this.DPIEnabled = enabled;
      this.disableDisplayScale = disableDisplayScale;
   };

   private getNormalizationParam = (target: CoordinatePoint, refer: CoordinatePoint, k: number): NormalizationParam => {
      const param: NormalizationParam = {} as NormalizationParam;
      Logger.trace("getNormalizationParam invoked by " + JSON.stringify({ target, refer, k }), Logger.NORMALIZATION);
      param.skew_x = refer.x - k * target.x;
      param.skew_y = refer.y - k * target.y;
      param.scale = k;
      Logger.trace("getNormalizationParam return" + JSON.stringify(param), Logger.NORMALIZATION);
      return param;
   };

   public normalize = (point: CoordinatePoint, model: NormalizationParam = null): CoordinatePoint => {
      if (!model) {
         if (!this.defaultModel) {
            return point;
         }
         model = this.defaultModel;
      }
      return {
         x: Math.round(point.x * model.scale + model.skew_x),
         y: Math.round(point.y * model.scale + model.skew_y)
      };
   };

   public revert = (point: CoordinatePoint, model: NormalizationParam = null): CoordinatePoint => {
      if (!model) {
         if (!this.defaultModel) {
            return point;
         }
         model = this.defaultModel;
      }
      return {
         x: Math.round((point.x - model.skew_x) / model.scale),
         y: Math.round((point.y - model.skew_y) / model.scale)
      };
   };

   /**
    * Since we only support 2 monitors this function need no param, and will
    * return the stable point compare with rawSetting[0]
    *
    * Using this way, we will have bug 1834512, but we will not fix it for 17Q2,
    * Using the dragging detection before entering multimon can fix it, but need
    *    new UI and workflow.
    *
    * Written in the readable way instead of with the simpliest logic.
    *
    * @return {object} The top or left point in the line segment of the sharing
    *    Edge.
    */
   private getStablePoint = (id: string, baseId = "0"): CoordinatePoint => {
      const isLeft = this.rawSetting[id].x + this.rawSetting[id].width <= this.rawSetting[baseId].x,
         isRight = this.rawSetting[id].x >= this.rawSetting[baseId].x + this.rawSetting[baseId].width,
         isTop = this.rawSetting[id].y + this.rawSetting[id].height <= this.rawSetting[baseId].y;

      if (isLeft) {
         return {
            x: this.rawSetting[baseId].x,
            y: Math.max(this.rawSetting[baseId].y, this.rawSetting[id].y)
         };
      } else if (isTop) {
         return {
            x: Math.max(this.rawSetting[baseId].x, this.rawSetting[id].x),
            y: this.rawSetting[baseId].y
         };
      } else if (isRight) {
         return {
            x: this.rawSetting[id].x,
            y: Math.max(this.rawSetting[baseId].y, this.rawSetting[id].y)
         };
      } else {
         return {
            x: Math.max(this.rawSetting[baseId].x, this.rawSetting[id].x),
            y: this.rawSetting[id].y
         };
      }
   };

   /**
    * RecalculatebaseKey again if the screens data return not in order
    */
   public calculateBaseKey = (screens?: Array<any>): void | Array<any> => {
      if (this._featureConfigs.getConfig("KillSwitch-WindowReplacementApi")) {
         if (screens) {
            // for windowReplacementApi: use monitor's edge to calculate the backKey
            const visited = [screens[0]];
            while (visited.length > 0) {
               const cur = visited.shift();
               for (let i = 1; i < screens.length; i++) {
                  const tmpScreen = screens[i];
                  if (typeof tmpScreen.baseKey === "undefined" && cur !== tmpScreen) {
                     const rect1 = {
                        x: cur.left,
                        y: cur.top,
                        width: cur.width,
                        height: cur.height
                     };
                     const rect2 = {
                        x: tmpScreen.left,
                        y: tmpScreen.top,
                        width: tmpScreen.width,
                        height: tmpScreen.height
                     };
                     if (this._displayCheckService.areRectanglesAdjacent(rect1, rect2)) {
                        tmpScreen.baseKey = String(parseInt(cur.baseKey || -1) + 1);
                        visited.push(tmpScreen);
                     }
                  }
               }
            }
            if (screens[2] && screens[1].baseKey > screens[2].baseKey) {
               const tmp = screens[1];
               screens[1] = screens[2];
               screens[2] = tmp;
            }
            if (screens[3] && screens[2].baseKey > screens[3].baseKey) {
               const tmp = screens[2];
               screens[2] = screens[3];
               screens[3] = tmp;
            }
            return screens;
         }
      } else {
         for (const key in this.rawSetting) {
            if (this.rawSetting.hasOwnProperty(key) && key !== "0") {
               this.rawSetting[key].baseKey = "0";
               if (clientUtil.isChromeClient()) {
                  const parentId = this.rawSetting[key].parentId;
                  if (!parentId) {
                     Logger.warning("missing parentId in rawSetting, use 0 as base screen");
                     continue;
                  }
                  for (const prop in this.rawSetting) {
                     // Set basekey to the screens according to parentid
                     if (this.rawSetting[prop].screenId === parentId) {
                        this.rawSetting[key].baseKey = prop;
                     }
                  }
               }
            }
         }
         if (clientUtil.isChromeClient()) {
            for (const key2 in this.rawSetting) {
               if (this.rawSetting.hasOwnProperty(key2) && key2 !== "0") {
                  const nextKey = "" + (parseInt(key2) + 1);
                  // Reorder the screens according to baseid
                  if (
                     this.rawSetting[nextKey] &&
                     parseInt(this.rawSetting[key2].baseKey) > parseInt(this.rawSetting[nextKey].baseKey)
                  ) {
                     const temp = this.rawSetting[key2];
                     this.rawSetting[key2] = this.rawSetting[nextKey];
                     this.rawSetting[nextKey] = temp;
                  }
               }
            }
            for (const key3 in this.rawSetting) {
               if (this.rawSetting.hasOwnProperty(key3) && key3 !== "0") {
                  this.rawSetting[key3].baseKey = "0";
                  const parentId = this.rawSetting[key3].parentId;
                  if (!parentId) {
                     Logger.warning("missing parentId in rawSetting, use 0 as base screen");
                     continue;
                  }
                  // Get base id again after the array is reordered.
                  for (const prop2 in this.rawSetting) {
                     if (this.rawSetting[prop2].screenId === parentId) {
                        this.rawSetting[key3].baseKey = prop2;
                     }
                  }
               }
            }
         }
      }
   };

   private generateModel = (): void => {
      let key;
      this.normalizationModel.clear();
      const param = this.getNormalizationParam(
         {
            x: this.rawSetting["0"].x,
            y: this.rawSetting["0"].y
         },
         {
            x: 0,
            y: 0
         },
         this.getFactor("0")
      );
      this.normalizationModel.set("0", param);
      for (key in this.rawSetting) {
         if (this.rawSetting.hasOwnProperty(key) && key !== "0") {
            const stablePoint = this.getStablePoint(key, this.rawSetting[key].baseKey);
            const tempParam = this.getNormalizationParam(
               stablePoint,
               this.normalize(stablePoint, this.normalizationModel.get(this.rawSetting[key].baseKey)),
               this.getFactor(key)
            );
            this.normalizationModel.set(key, tempParam);
         }
      }
   };

   private calculateNormalizedSetting = (): void => {
      let key, startPoint;

      this.normalizedSettings.clear();
      for (key in this.rawSetting) {
         if (this.rawSetting.hasOwnProperty(key) && this.normalizationModel.has(key)) {
            const factor = this.getFactor(key);
            const targetDPI = this.DPIEnabled ? this.rawSetting[key].devicePixelRatio : 1;
            startPoint = this.normalize(this.rawSetting[key], this.normalizationModel.get(key));
            const display: DisplayBaseInfo = {
               x: startPoint.x,
               y: startPoint.y,
               width: Math.round(this.rawSetting[key].width * factor),
               height: Math.round(this.rawSetting[key].height * factor),
               devicePixelRatio: targetDPI
            };
            this.normalizedSettings.set(key, display);
         }
      }
   };

   private clipSettings = (): void => {
      for (const value of this.normalizedSettings.values()) {
         const display = value;
         display.width -= display.width % 2;
         display.x -= display.x % 2;
      }
   };

   public setScreenModel = (model: NormalizationParam): void => {
      this.defaultModel = model;
   };

   public setScreensInfo = async () => {
      this.screenInfo = await this.getChromeScreensInfo();
      this.screenLayout = await this.getChromeScreenLayout();
   };

   public getChromeScreensInfo = () => {
      return new Promise((resolve, reject) => {
         try {
            chrome.system.display.getInfo((screens) => {
               resolve(screens);
            });
         } catch (e) {
            Logger.info("Failed to use getInfo.");
            reject(e);
         }
      });
   };

   public getChromeScreenLayout = () => {
      return new Promise((resolve, reject) => {
         try {
            chrome.system.display.getDisplayLayout((layout) => {
               resolve(layout);
            });
         } catch (e) {
            Logger.info("Failed to use getDisplayLayout.");
            reject(e);
         }
      });
   };
   /**
    * Set the raw setting gathered from browser, currently only enable for chrome
    */
   public setRawSetting = (id: string, setting: any): void => {
      Logger.info("raw setting for " + id + " as " + JSON.stringify(setting));
      if (clientUtil.isChromeClient() && this.screenInfo) {
         this.screenInfo.forEach((screen) => {
            const workArea = screen.bounds,
               left = workArea.left,
               top = workArea.top;
            if (left === setting.x && top === setting.y) {
               setting.screenId = screen.id;
            }
         });
         if (this.screenLayout) {
            this.screenLayout.forEach((layout) => {
               if (layout.id === setting.screenId) {
                  setting.parentId = layout.parentId;
               }
            });
         }
      }
      this.rawSetting[id] = setting;
   };

   /**
    * Set the raw setting gathered from browser, currently only enable for chrome
    */
   public removeRawSetting = (id: string): void => {
      delete this.rawSetting[id];
   };

   /**
    * Using the previous input data to calculate and derive the model and setting
    */
   public calculate = (): void => {
      Logger.info("calculate for normalization");
      this.adjustSettingsForNotchMonitor();
      this.calculateBaseKey();
      this.generateModel();
      this.calculateNormalizedSetting();
      this.clipSettings();
   };

   public adjustSettingsForNotchMonitor = () => {
      if (clientUtil.isChromeClient()) {
         return;
      }
      Logger.info("before adjust rawSetting" + JSON.stringify(this.rawSetting), Logger.NOTCH_MONITOR);
      for (const key in this.rawSetting) {
         // find notch monitor
         if (this.rawSetting[key].macNotchHeight && this.rawSetting[key].macNotchHeight > 0) {
            Logger.info(
               "Find a notchMonitor, key = " + key + ", rawSetting = " + JSON.stringify(this.rawSetting),
               Logger.NOTCH_MONITOR
            );
            this._adjustRelatedMonitorsY(key);
            this._adjustNotchMonitorHeight(key);
            return;
         }
      }
      Logger.info("after adjust rawSetting" + JSON.stringify(this.rawSetting), Logger.NOTCH_MONITOR);
   };

   private _adjustNotchMonitorHeight = (notchMonitorKey) => {
      this.rawSetting[notchMonitorKey].height -= this.rawSetting[notchMonitorKey].macNotchHeight;
   };

   // https://confluence.eng.vmware.com/display/~wyinglei/Multi+monitor+bugs+on+Notcn+Mac#MultimonitorbugsonNotcnMac-Whichmonitorsshouldbeadjusted?
   private _adjustRelatedMonitorsY = (notchMonitorKey) => {
      const notchHeight = this.rawSetting[notchMonitorKey].macNotchHeight;

      const yAdjustedMonitors = [];
      const doneMonitor = [notchMonitorKey];
      const needAdjustMonitors = [];

      const needAdjust = (key) => {
         needAdjustMonitors.push(key);
         yAdjustedMonitors.push(key);
         doneMonitor.push(key);
      };

      // adjust monitors under and adjacent to notch monitor
      for (const key in this.rawSetting) {
         if (key === notchMonitorKey) {
            continue;
         }
         if (this.rawSetting[key].y === this.rawSetting[notchMonitorKey].y + this.rawSetting[notchMonitorKey].height) {
            needAdjust(key);
         }
      }

      // adjust monitors that are around y-adjusted monitors
      while (yAdjustedMonitors.length > 0) {
         const tmpKey = yAdjustedMonitors.shift();
         for (const key in this.rawSetting) {
            if (doneMonitor.indexOf(key) === -1 && this._isAdjacentOrUpCrossAfterAdjust(tmpKey, key, notchHeight)) {
               needAdjust(key);
            }
         }
      }

      for (const key of needAdjustMonitors) {
         this.screenBaseUnderNotchMonitor.set(key, notchHeight * this.getFactor(key));
         this.rawSetting[key].y -= notchHeight;
      }
      Logger.info(
         "screenBaseUnderNotchMonitor=" + JSON.stringify(this.screenBaseUnderNotchMonitor),
         Logger.NOTCH_MONITOR
      );
   };

   // check if two monitor is adjacent both right now and after adjust
   private _isAdjacentOrUpCrossAfterAdjust = (key1, key2, notchHeight): boolean => {
      // before adjust
      const adjacent = this._displayCheckService.areRectanglesAdjacent(this.rawSetting[key1], this.rawSetting[key2]);
      Logger.info("Adjacent = " + adjacent, Logger.NOTCH_MONITOR);
      if (adjacent !== false) {
         return true;
      }

      // after adjust y, adjacent to top monitor
      const heightAfterAdjust = this.rawSetting[key2].y - notchHeight;
      if (heightAfterAdjust <= this.rawSetting[key1].y + this.rawSetting[key1].height) {
         const r1Left = this.rawSetting[key1].x;
         const r1Right = this.rawSetting[key1].x + this.rawSetting[key1].width;
         const r2Left = this.rawSetting[key2].x;
         const r2Right = this.rawSetting[key2].x + this.rawSetting[key2].width;
         if ((r2Left >= r1Left && r2Left <= r1Right) || (r2Right >= r1Left && r2Right <= r1Right)) {
            return true;
         }
      }

      return false;
   };

   public getScreenBaseUnderNotchMonitor = () => {
      return this.screenBaseUnderNotchMonitor;
   };

   /**
    * Get the derived model
    */
   public getNormalizationModel = (): Map<string, NormalizationParam> => {
      return this.normalizationModel;
   };

   /**
    * Get the derived normalized settings
    */
   public getNormalizedSettings = (): Map<string, DisplayBaseInfo> => {
      return this.normalizedSettings;
   };

   public getSizeFactor = (id: string): number => {
      return this.normalizationModel.get(id).scale;
   };

   public isScaleMismatched = (): boolean => {
      if (!this.rawSetting["0"] || this.rawSetting["0"].devicePixelRatio === undefined) {
         return false;
      }

      for (const key in this.rawSetting) {
         if (
            key !== "0" &&
            this.rawSetting.hasOwnProperty(key) &&
            this.rawSetting["0"].devicePixelRatio !== this.rawSetting[key].devicePixelRatio
         ) {
            return true;
         }
      }
      return false;
   };

   public scale = (point: CoordinatePoint, model: NormalizationParam = null): CoordinatePoint => {
      if (!model) {
         if (!this.defaultModel) {
            return point;
         }
         model = this.defaultModel;
      }
      return {
         x: point.x / model.scale,
         y: point.y / model.scale
      };
   };
}
