/***
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 **/
/**
 * support 2 type of format for now, plain and arrayBuffer.
 * The message format would be decoupled from the channel types.
 * And plain is easy to debug, while arrayBuffer is more efficient.
 * Thus for now, for client SDK, plain would be used,
 * for virtual channel, arrayBuffer would be used.
 *
 * If needed we can allow passing function in event based on
 * a RPC-like design as the 3rd helper with functionId and hidden message
 * exchanges.
 */

import { EventHelperBase } from "./event-helper.base";
import { EventHelperPlain } from "./event-helper.plain";
import { EventHelperArrayBuffer } from "./event-helper.array-buffer";

export enum EventHelperType {
   PlainText,
   ArrayBuffer
}

export function getEventHelper(
   type: EventHelperType,
   schema?: any
): EventHelperBase {
   if (type === EventHelperType.ArrayBuffer) {
      if (schema) {
         return new EventHelperArrayBuffer(schema);
      } else {
         return null;
      }
   } else {
      return new EventHelperPlain();
   }
}

export {
   EventHelperBase as EventHelper,
   SDKEvent,
   SDKRawEvent,
   StructSchema,
   RequestBundle
} from "./event-helper.base";
