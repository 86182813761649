<div class="modal-title dialog-title">{{ 'CHANGE_DEFAULT_FILE_ASSOCIATION_APP_TITLE' | i18nv2 }}</div>

<div class="modal-body dialog-content" fuzzy-free>
   <div class="fa-default-app-dialog-container">
      <div class="change-default-fa-app">
          <input id="app-id-input" [(ngModel)]="extensionName" class="app-input" type="text" placeholder="{{'DEFAULT_APP_SEARCH' | i18nv2}}" (keyup)="onKeypress($event)">
      </div>
      <div id="default-fa-app-search" class="modal-button-base modal-button-blue" [ngClass]="{'disable' : !allReady}" (click)="searchDefaultApp()">
          {{ 'Search' | i18nv2 }}
      </div>
  </div>
    <div class="modal-dialog-container">
        <div class="modal-dialog-setting">
            <div class="modal-dialog-list">
               <div [hidden]="hideAppWarning">{{'FILE_ASSOCIATION_WARN' | i18nv2 : extensionName}}</div>
               <div *ngFor="let app of filteredAppsInfo">
                  <div class="app-item .clearfix fa-default-apps">
                     <input class="fa-app-select" type="radio" (click)="changeDefaultApp(app)" value="{{app.id}}" name="default-app">
                      <div class="default-apps-id" title="{{app.displayName}}">{{app.displayName}} ({{app.brokerURL}})</div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer dialog-button-row">
    <div id="fa-default-app-setting-ok" class="modal-button-base modal-button-right modal-button-blue no-disable-hover" [ngClass]="{'disable' :!allReady}" (click)="defaultAppsOkClicked()">
        {{ 'OK' | i18nv2 }}
    </div>
    <div id="fa-defaultapp--setting-cancel" class="modal-button-base modal-button-right modal-button-blue" (click)="closeModal()">
        {{ 'CANCEL' | i18nv2 }}
    </div>
</div>