/**
 * ******************************************************
 * Copyright (C) 2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * tsdr-protocal-schema.js --
 *
 * schema used for TSDR protocol.
 */
export const tsdrSchema = {
   macros: {
      PREFERRED_DOS_NAME_LENGTH: 8
   },
   structs: {
      RDPDR_HEADER: new Map([
         ["Component", "USHORT"],
         ["PacketId", "USHORT"]
      ]),

      TSDR_CAPS_VERSION_EXCHANGE_RQE: new Map([
         ["Header", "RDPDR_HEADER"],
         ["Version", "USHORT"],
         ["Caps", "USHORT"]
      ]),

      DR_CORE_SERVER_ANNOUNCE_REQ: new Map([
         ["Header", "RDPDR_HEADER"],
         ["VersionMajor", "USHORT"],
         ["VersionMinor", "USHORT"],
         ["ClientId", "UINT32"]
      ]),

      DR_CORE_CLIENT_ANNOUNCE_RSP: new Map([
         ["Header", "RDPDR_HEADER"],
         ["VersionMajor", "USHORT"],
         ["VersionMinor", "USHORT"],
         ["ClientId", "UINT32"]
      ]),

      DR_CORE_CLIENT_NAME_REQ: new Map([
         ["Header", "RDPDR_HEADER"],
         ["UnicodeFlag", "UINT32"],
         ["CodePage", "UINT32"],
         ["ComputerNameLen", "UINT32"],
         ["ComputerName", "WCHAR[.ComputerNameLen/2]"]
      ]),

      //------------------------------------
      //https://opengrok.eng.vmware.com/source/xref/bfg-main.perforce.1666/bora/apps/rde/tsdr/common/RdpEfs.h#215
      //@ts-ignore
      RDP_DR_DEVICE_IOREQUEST: new Map([
         ["DeviceId", "UINT32"],
         /* Id from Client DeviceList Announce Request */
         ["FileId", "UINT32"],
         /* Id from Device Create Response */
         ["CompletionId", "UINT32"],
         /* Unique Id for Each request */
         ["MajorFunction", "UINT32"],
         /* The IRP_MJ_AAA request */
         ["MinorFunction", "UINT32"],
         /* The IRP_MNAAA function (With IRP_MJ_DIRECTORY_CONTROL) */
         //@ts-ignore
         [
            "Parameters",
            [
               /* IRP_MJ_CREATE [MS-RPDEFS 2.2.1.4.1] */
               {
                  type: "Create",
                  format: new Map([
                     ["DesiredAccess", "UINT32"], // Wanted level of access
                     ["AllocationSize", "LARGE_INTEGER"], // Initial allocaiton size for the file
                     ["FileAttributes", "UINT32"], // Attributes for the created file
                     ["SharedAccess", "UINT32"], // Sharing mode for the opened file
                     ["CreateDisposition", "UINT32"], // Action to take of file already exists
                     ["CreateOptions", "UINT32"], // Creation options
                     ["PathLength", "UINT32"], // Length in bytes of data (Path) following the structure
                     ["Path", "WCHAR[.PathLength/2]"]
                  ])
               },

               /* IRP_MJ_CLOSE [MS-RPDEFS 2.2.1.4.2]
                * No structure, 32 bytes padding
                */
               {
                  type: "Close",
                  format: new Map([["Padding", "PADDING[32]"]])
               },

               /* IRP_MJ_READ  [MS-RPDEFS 2.2.1.4.3] */
               {
                  type: "Read",
                  format: new Map([
                     ["Length", "UINT32"], // Maximum Number of chars to read
                     ["Offset", "LARGE_INTEGER"], // Byte offset where read starts
                     ["Padding", "PADDING[20]"] // Padding
                  ])
               },

               /* IRP_MJ_WRITE  [MS-RPDEFS 2.2.1.4.4]*/
               {
                  type: "Write",
                  format: new Map([
                     ["Length", "UINT32"], // Number of char's to write
                     ["Offset", "LARGE_INTEGER"], // Byte offset where write starts
                     ["Padding", "PADDING[20]"], // Padding
                     ["WriteData", "BYTE[.Length]"] // Variable lenght Data to write
                  ])
               },

               /* IRP_MJ_DEVICE_CONTROL  [MS-RPDEFS 2.2.1.4.5]*/
               {
                  type: "DeviceControl",
                  format: new Map([
                     ["OutputBufferLength", "UINT32"], // Maximum num of bytes in OutputBuffer (See DR_CONTROL_RSP)
                     ["InputBufferLength", "UINT32"], // Number of bytes in InputBuffer
                     ["IoControlCode", "UINT32"], // IOCTL_AAA
                     ["Padding", "PADDING[20]"], // Padding
                     ["InputBuffer", "BYTE[.InputBufferLength]"] // Variable lenght Data to send
                  ])
               },

               {
                  type: "QueryDirectory",
                  format: new Map([
                     ["FileInformationClass", "UINT32"],
                     ["InitialQuery", "BOOLEAN"],
                     ["PathLength", "UINT32"],
                     ["Padding", "PADDING[23]"],
                     ["szPath", "WCHAR[.PathLength/2]"] //byte holder not arrayLength
                  ])
               },

               {
                  type: "SetInformation",
                  //@ts-ignore
                  format: new Map([
                     ["FileInformationClass", "UINT32"],
                     ["Length", "UINT32"],
                     ["Padding", "PADDING[24]"],
                     //@ts-ignore
                     [
                        "Information",
                        [
                           {
                              type: "FileBasicInformation",
                              format: "FILE_BASIC_INFORMATION_PACKED"
                           },

                           {
                              //FILE_END_OF_FILE_INFORMATION
                              type: "FileEndOfFileInformation",
                              format: "FILE_END_OF_FILE_INFORMATION"
                           },

                           {
                              //FILE_ALLOCATION_INFORMATION
                              type: "FileAllocationInformation",
                              format: "FILE_ALLOCATION_INFORMATION"
                           },

                           {
                              //FILE_DISPOSITION_INFORMATION
                              type: "FileDispositionInformation",
                              format: "FILE_DISPOSITION_INFORMATION"
                           },

                           {
                              //FILE_RENAME_INFORMATION_PACKED
                              type: "FileRenameInformation",
                              format: "FILE_RENAME_INFORMATION_PACKED"
                           },

                           {
                              //When no data load
                              type: "None",
                              format: new Map([["NoData", "NONE"]])
                           }
                        ]
                     ]
                  ])
               },

               {
                  type: "QueryInformation",
                  format: new Map([
                     ["FsInformationClass", "UINT32"],
                     ["Padding", "PADDING[28]"]
                  ])
               },

               /* IRP_MJ_SET_VOLUME_INFORMATION  [MS-RPDEFS 2.2.3.3.7] */
               {
                  type: "SetVolumeInformation",
                  format: new Map([
                     ["FsInformationClass", "UINT32"],
                     ["Length", "UINT32"],
                     ["Padding", "PADDING[24]"]
                  ])
               },

               {
                  type: "QueryVolumeInformation",
                  format: new Map([
                     ["FsInformationClass", "UINT32"],
                     ["Padding", "PADDING[28]"]
                  ])
               },

               {
                  type: "NotifyChangeDirectory",
                  format: new Map([
                     ["WatchTree", "BOOLEAN"],
                     ["CompletionFilter", "UINT32"],
                     ["Padding", "PADDING[27]"]
                  ])
               },

               /* IRP_MJ_LOCK_CONTROL  [MS-RPDEFS 2.2.3.3.12] */
               {
                  type: "LockControl",
                  format: new Map([
                     ["Operation", "UINT32"], // Lock type
                     ["F", "UINT32"], // Fail immediately
                     ["NumLocks", "UINT32"], // Number of locks requested
                     ["Padding", "PADDING[20]"], // Padding
                     ["Locks", "RDP_LOCK_INFO[.NumLocks]"] // Variable length array of locks
                  ])
               }
            ]
         ]
      ]),

      /* RDP_LOCK_INFO [MS-RPDEFS 2.2.1.6] */
      RDP_LOCK_INFO: new Map([
         ["Length", "LARGE_INTEGER"],
         ["Offset", "LARGE_INTEGER"]
      ]),

      /* RDPDR_DEVICE_IOREQUEST [MS-RPDEFS 2.2.1.4] */
      DR_DEVICE_IOREQUEST: new Map([
         ["Header", "RDPDR_HEADER"],
         ["IoRequest", "RDP_DR_DEVICE_IOREQUEST"]
      ]),

      /*
       * RDP_DR_DEVICE_IOCOMPLETION
       * [MS-RPDEFS 2.2.1.5]
       * Sent by the client to indicate an I/O operation has completed
       * Note: Should contain Protocol Header, I have separated the
       *       Header from the request, it's included in the
       *       DR_DEVICE_IOCOMPLETION below
       */
      //@ts-ignore
      RDP_DR_DEVICE_IOCOMPLETION: new Map([
         // RDP_DR_DEVICE_IOCOMPLETION_HEADER
         ["DeviceId", "UINT32"], // Must match DeviceId used in the RDP_DR_DEVICE_IOREQUEST
         ["CompletionId", "UINT32"], // Must match the CompletionId in the RDP_DR_DEVICE_IOREQUEST
         ["IoStatus", "UINT32"], // NT Status code for the request
         //@ts-ignore
         [
            "Parameters",
            [
               /* IRP_MJ_CREATE [MS-RPDEFS 2.2.1.5.1] */
               {
                  type: "Create",
                  format: new Map([
                     ["FileId", "UINT32"], // Unique FileId for the created object
                     ["Information", "BOOLEAN"] // 1 byte, indicates the success of the Create/Open operation
                  ])
               },

               /* Placeholder for responses without workload
                * No structure, 32 bytes padding
                */
               {
                  type: "None",
                  format: new Map([["Padding", "PADDING[5]"]])
               },

               /* IRP_MJ_CLOSE [MS-RPDEFS 2.2.1.5.2] */
               {
                  type: "Close",
                  format: new Map([["Padding", "PADDING[5]"]])
               },

               /* IRP_MJ_READ [MS-RPDEFS 2.2.1.5.3] */
               {
                  type: "Read",
                  format: new Map([
                     ["Length", "UINT32"], // Number of bytes that were read
                     ["ReadData", "DATA[.Length]"] // Variable Output data from the read request
                  ])
               },

               /* IRP_MJ_WRITE [MS-RPDEFS 2.2.1.5.4] */
               {
                  type: "Write",
                  format: new Map([
                     ["Length", "UINT32"], // Number of bytes that were written
                     ["Padding", "PADDING[1]"] // Variable Output data from the read request
                  ])
               },

               /* IRP_MJ_DEVICE_CONTROL [MS-RPDEFS 2.2.1.5.5] */
               {
                  type: "DeviceControl",
                  format: new Map([
                     ["OutputBufferLength", "UINT32"], // Number of bytes in the OutputBuffer
                     ["OutputBuffer", "BYTE[.OutputBufferLength]"] // Variable lenght array of bytes
                  ])
               },

               {
                  type: "QueryVolumeInformation",
                  format: new Map([
                     ["Length", "UINT32"],
                     ["VolumeInformation", "DataDefinedClass"]
                  ])
               },

               {
                  type: "SetVolumeInformation",
                  format: new Map([
                     ["Length", "UINT32"],
                     ["Padding", "PADDING[1]"]
                  ])
               },

               {
                  type: "QueryInformation",
                  format: new Map([
                     ["Length", "UINT32"],
                     ["FileInformation", "DataDefinedClass"]
                  ])
               },

               {
                  //DR_DRIVE_SET_INFORMATION_RSP
                  type: "SetInformation",
                  format: new Map([
                     ["Length", "UINT32"],
                     ["Padding", "PADDING[1]"]
                  ])
               },

               {
                  type: "QueryDirectory",
                  format: new Map([
                     ["Length", "UINT32"],
                     ["FileInformation", "DataDefinedClass"]
                  ])
               },

               {
                  type: "NotifyChangeDirectory",
                  format: new Map([["Padding", "PADDING[5]"]])
               },

               /* IRP_MJ_LOCK_CONTROL  [MS-RPDEFS 2.2.3.4.12] */
               {
                  type: "LockControl",
                  format: new Map([["Padding", "PADDING[5]"]])
               }
            ]
         ]
      ]),

      /* [MS-RPDEFS 2.2.1.5] */
      DR_DEVICE_IOCOMPLETION: new Map([
         ["Header", "RDPDR_HEADER"],
         ["IoCompletion", "RDP_DR_DEVICE_IOCOMPLETION"]
      ]),

      /* [MS-RDPEFS 2.2.2.2 for server] and [MS-RDPEFS 2.2.2.6 for client] */
      DR_CORE_ANNOUNCE_REQ: new Map([
         ["Header", "RDPDR_HEADER"],
         ["VersionMajor", "USHORT"],
         ["VersionMinor", "USHORT"],
         ["ClientId", "UINT32"]
      ]),

      CAPABILITY_HEADER: new Map([
         ["CapabilityType", "USHORT"],
         ["CapabilityLength", "USHORT"],
         ["Version", "UINT32"]
      ]),

      /* [MS-RDPEFS 2.2.2.7.1] */
      GENERAL_CAPS_SET: new Map([
         ["Header", "CAPABILITY_HEADER"],
         ["osType", "UINT32"],
         ["osVersion", "UINT32"],
         ["protocolMajorVersion", "USHORT"],
         ["protocolMinorVersion", "USHORT"],
         ["ioCode1", "UINT32"],
         ["ioCode2", "UINT32"],
         ["extendedPDU", "UINT32"],
         ["extraFlags1", "UINT32"],
         ["extraFlags2", "UINT32"],
         ["SpecialTypeDeviceCap", "UINT32"]
      ]),

      PRINTER_CAPS_SET: new Map([["Header", "CAPABILITY_HEADER"]]),

      PORT_CAPS_SET: new Map([["Header", "CAPABILITY_HEADER"]]),

      DRIVE_CAPS_SET: new Map([["Header", "CAPABILITY_HEADER"]]),

      SMARTCARD_CAPS_SET: new Map([["Header", "CAPABILITY_HEADER"]]),

      /* [MS-RDPEFS 2.2.2.7 for server] and [MS-RDPEFS 2.2.2.8 for client] */
      DR_CORE_CAPABILITY_REQ: new Map([
         ["Header", "RDPDR_HEADER"],
         ["numCapabilities", "USHORT"],
         ["Padding", "USHORT"],
         ["CapabilityMessage", "Variance"]
      ]),

      DEVICE_ANNOUNCE_HEADER: new Map([
         ["DeviceType", "UINT32"],
         ["DeviceId", "UINT32"],
         ["DosName", "CHAR[..PREFERRED_DOS_NAME_LENGTH]"],
         ["DeviceDataLength", "UINT32"],
         ["DeviceData", "CHAR[.DeviceDataLength]"]
      ]),

      DR_CORE_DEVICELIST_ANNOUNCE_REQ: new Map([
         ["Header", "RDPDR_HEADER"],
         ["DeviceCount", "UINT32"],
         ["DeviceList", "DEVICE_ANNOUNCE_HEADER[1]"] //for now we send only one per request
         //Duplicated data in DEVICE_ANNOUNCE_HEADER, DeviceData", "CHAR[]"
      ]),

      DR_CORE_DEVICE_ANNOUNCE_RSP: new Map([
         ["Header", "RDPDR_HEADER"],
         ["DeviceId", "UINT32"],
         ["ResultCode", "UINT32"]
      ]),

      DR_DEVICELIST_REMOVE: new Map([
         ["Header", "RDPDR_HEADER"],
         ["DeviceCount", "UINT32"],
         ["DeviceIds", "UINT32[1]"] //for now we send only one per request
      ]),

      ///-----fs--------
      FILE_BASIC_INFORMATION: new Map([
         ["CreationTime", "LARGE_INTEGER"],
         ["LastAccessTime", "LARGE_INTEGER"],
         ["LastWriteTime", "LARGE_INTEGER"],
         ["ChangeTime", "LARGE_INTEGER"],
         ["FileAttributes", "ULONG"]
      ]),

      FILE_STANDARD_INFORMATION: new Map([
         ["AllocationSize", "LARGE_INTEGER"],
         ["EndOfFile", "LARGE_INTEGER"],
         ["NumberOfLinks", "ULONG"],
         ["DeletePending", "BOOLEAN"],
         ["Directory", "BOOLEAN"]
      ]),

      FILE_BOTH_DIR_INFORMATION_PACKED: new Map([
         ["NextEntryOffset", "UINT32"],
         ["FileIndex", "UINT32"],
         ["CreationTime", "LARGE_INTEGER"],
         ["LastAccessTime", "LARGE_INTEGER"],
         ["LastWriteTime", "LARGE_INTEGER"],

         ["ChangeTime", "LARGE_INTEGER"],
         ["EndOfFile", "LARGE_INTEGER"],
         ["AllocationSize", "LARGE_INTEGER"],
         ["FileAttributes", "UINT32"],
         ["FileNameLength", "UINT32"],

         ["EaSize", "UINT32"],
         ["ShortNameLength", "BYTE"],
         ["ShortName", "UINT16[12]"], //fixed length WCHAR, real value is in ShortNameLength
         ["FileName", "WCHAR[.FileNameLength/2]"]
      ]),

      FILE_FULL_DIR_INFORMATION_PACKED: new Map([
         ["NextEntryOffset", "UINT32"],
         ["FileIndex", "UINT32"],
         ["CreationTime", "LARGE_INTEGER"],
         ["LastAccessTime", "LARGE_INTEGER"],
         ["LastWriteTime", "LARGE_INTEGER"],

         ["ChangeTime", "LARGE_INTEGER"],
         ["EndOfFile", "LARGE_INTEGER"],
         ["AllocationSize", "LARGE_INTEGER"],
         ["FileAttributes", "UINT32"],
         ["FileNameLength", "UINT32"],

         ["EaSize", "UINT32"],
         ["FileName", "WCHAR[.FileNameLength/2]"]
      ]),

      FILE_BASIC_INFORMATION_PACKED: new Map([
         ["CreationTime", "LARGE_INTEGER"],
         ["LastAccessTime", "LARGE_INTEGER"],
         ["LastWriteTime", "LARGE_INTEGER"],

         ["ChangeTime", "LARGE_INTEGER"],
         ["FileAttributes", "UINT32"]
      ]),

      FILE_RENAME_INFORMATION_PACKED: new Map([
         ["ReplaceIfExists", "USHORT"],
         ["FileNameLength", "UINT32"],
         ["FileName", "WCHAR[.FileNameLength/2]"]
      ]),

      FILE_DIRECTORY_INFORMATION_PACKED: new Map([
         ["NextEntryOffset", "UINT32"],
         ["FileIndex", "UINT32"],
         ["CreationTime", "LARGE_INTEGER"],
         ["LastAccessTime", "LARGE_INTEGER"],
         ["LastWriteTime", "LARGE_INTEGER"],

         ["ChangeTime", "LARGE_INTEGER"],
         ["EndOfFile", "LARGE_INTEGER"],
         ["AllocationSize", "LARGE_INTEGER"],
         ["FileAttributes", "UINT32"],
         ["FileNameLength", "UINT32"],

         ["FileName", "WCHAR[.FileNameLength/2]"]
      ]),

      FILE_FS_VOLUME_INFORMATION_PACKED: new Map([
         ["VolumeCreationTime", "LARGE_INTEGER"],
         ["VolumeSerialNumber", "UINT32"],
         ["VolumeLabelLength", "UINT32"],
         ["SupportsObjects", "BOOLEAN"],
         ["VolumeLabel", "WCHAR[.VolumeLabelLength/2]"]
      ]),

      FILE_NAMES_INFORMATION_PACKED: new Map([
         ["NextEntryOffset", "ULONG"],
         ["FileIndex", "ULONG"],
         ["FileNameLength", "ULONG"],
         ["FileName", "WCHAR[.FileNameLength/2]"]
      ]),

      FILE_END_OF_FILE_INFORMATION: new Map([["EndOfFile", "LARGE_INTEGER"]]),

      FILE_ALLOCATION_INFORMATION: new Map([["AllocationSize", "LARGE_INTEGER"]]),

      FILE_DISPOSITION_INFORMATION: new Map([["DeleteFile", "BOOLEAN"]]),

      FILE_ATTRIBUTE_TAG_INFORMATION: new Map([
         ["FileAttributes", "UINT32"],
         ["ReparseTag", "UINT32"]
      ]),

      FILE_FS_LABEL_INFORMATION: new Map([
         ["VolumeLabelLength", "ULONG"],
         ["VolumeLabel", "WCHAR[.VolumeLabelLength/2]"]
      ]),

      FILE_FS_VOLUME_INFORMATION: new Map([
         ["VolumeCreationTime", "LARGE_INTEGER"],
         ["VolumeSerialNumber", "ULONG"],
         ["VolumeLabelLength", "ULONG"],
         ["SupportsObjects", "BOOLEAN"],
         ["VolumeLabel", "WCHAR[.VolumeLabelLength/2]"]
      ]),

      FILE_FS_SIZE_INFORMATION: new Map([
         ["TotalAllocationUnits", "LONGLONG"],
         ["AvailableAllocationUnits", "LONGLONG"],
         ["SectorsPerAllocationUnit", "UINT32"],
         ["BytesPerSector", "UINT32"]
      ]),

      FILE_FS_FULL_SIZE_INFORMATION: new Map([
         ["TotalAllocationUnits", "LARGE_INTEGER"],
         ["CallerAvailableAllocationUnits", "LARGE_INTEGER"],
         ["ActualAvailableAllocationUnits", "LARGE_INTEGER"],
         ["SectorsPerAllocationUnit", "ULONG"],
         ["BytesPerSector", "ULONG"]
      ]),

      FILE_FS_OBJECTID_INFORMATION: new Map([
         ["ObjectId", "UCHAR"],
         ["ExtendedInfo", "UCHAR"]
      ]),

      FILE_FS_ATTRIBUTE_INFORMATION: new Map([
         ["FileSystemAttributes", "ULONG"],
         ["MaximumComponentNameLength", "ULONG"],
         ["FileSystemNameLength", "ULONG"],
         ["FileSystemName", "WCHAR[.FileSystemNameLength/2]"]
      ]),

      FILE_FS_DRIVER_PATH_INFORMATION: new Map([
         ["DriverInPath", "BOOLEAN"],
         ["DriverNameLength", "ULONG"],
         ["DriverName", "WCHAR[.DriverNameLength/2]"]
      ]),

      FILE_FS_VOLUME_FLAGS_INFORMATION: new Map([["Flags", "ULONG"]]),

      FILE_FS_DEVICE_INFORMATION: new Map([
         ["DeviceType", "ULONG"],
         ["Characteristics", "UINT32"]
      ])
   }
};
