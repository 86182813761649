/**
 * ******************************************************
 * Copyright (C) 2012-2017 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * killSessionCommand.js --
 *
 * to send killSession using applicationId/desktopId, or reset all application
 * sessions. can be used to logoff desktop or reset application-sessions.
 * it rely on the get getLaunchItem or getDesktops(when connecting old broker)
 */

import { globalArray } from "../jscdkClient";
import util from "../util";
import Logger from "../../../core/libs/logger";
import KillSessionHandler from "../controllers/killSessionHandler";
import GetDesktopsHandler from "../controllers/getDesktopsHandler";
import GetLaunchItemsHandler from "../controllers/getLaunchItemsHandler";
import KillAllApplicationSessionsCtrl from "../controllers/killAllApplicationSessionsCtrl";

export default function KillSessionCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param killSessionAction[in]
 *        - its property 'method' has the value 'KillSession'
 * do not split this command into two command for its function is
 * the same. after JSCDK refactory it will be only little difference
 * between them(just parameter are different), so provide with same
 * API.
 */
KillSessionCommand.prototype.execute = function (killSessionAction) {
   if (killSessionAction.killAllApplicationSessions) {
      this.killAllApplicationSessions();
   } else if (killSessionAction.itemInfo.isMultiSession) {
      this.killMultiSession(killSessionAction.itemInfo);
   } else {
      this.killSingleSession(killSessionAction.itemInfo);
   }
};

KillSessionCommand.prototype.sendGetLaunchItems = function (getLaunchItemsObject) {
   let supportedProtocols,
      supportedAppProtocols,
      type,
      handlerList,
      router = globalArray["router"];

   if (!!getLaunchItemsObject && !!router) {
      supportedProtocols = globalArray.supportedProtocols || ["BLAST"];
      type = {};
      type.name = "remote";
      type.protocols = supportedProtocols;
      supportedAppProtocols = [];
      supportedAppProtocols[0] = type;
      if (supportedProtocols) {
         getLaunchItemsObject.setRequestXML(supportedProtocols, supportedAppProtocols, false);
         handlerList = getLaunchItemsObject.composeHandlerList();
         router.postMessage(handlerList);
      } else {
         Logger.error("supported protocols is invalid.");
      }
   }
};

KillSessionCommand.prototype.killAllApplicationSessions = function () {
   let killAllApplicationSessionsObject,
      getLaunchItemsObject,
      //please do not worry about the naming, it will be deleted after JSCDK
      // refactory
      getLaunchItemsObjectNameHeader = "pre-kill-all-application-sessions";

   if (!util.brokerSupportApplication()) {
      Logger.debug("error: broker do not support kill all application sessions!");
      return;
   }
   killAllApplicationSessionsObject = globalArray["kill-all-application-sessions"];
   if (!killAllApplicationSessionsObject) {
      killAllApplicationSessionsObject = new KillAllApplicationSessionsCtrl();
      globalArray[killAllApplicationSessionsObject.messageName] = killAllApplicationSessionsObject;
      globalArray[killAllApplicationSessionsObject.responseTag] = killAllApplicationSessionsObject;
   }

   getLaunchItemsObject = globalArray[getLaunchItemsObjectNameHeader + "get-launch-items"];
   if (!getLaunchItemsObject) {
      getLaunchItemsObject = new GetLaunchItemsHandler(true);
      globalArray[getLaunchItemsObjectNameHeader + getLaunchItemsObject.messageName] = getLaunchItemsObject;
      globalArray[getLaunchItemsObjectNameHeader + getLaunchItemsObject.responseTag] = getLaunchItemsObject;
   } else {
      getLaunchItemsObject.resetData();
   }

   getLaunchItemsObject.appendSubHandler(killAllApplicationSessionsObject);

   this.sendGetLaunchItems(getLaunchItemsObject);
};

KillSessionCommand.prototype.killMultiSession = function (itemInfo) {
   let itemId,
      killSessionObject,
      router = globalArray["router"];

   if (!itemInfo) {
      Logger.debug("error: the itemInfo do not exist for killSingleSession");
      return;
   }
   itemId = itemInfo.id;
   // Get KillSessionHandler
   killSessionObject = globalArray["kill-session" + itemId];
   if (!killSessionObject) {
      killSessionObject = new KillSessionHandler();
      globalArray[killSessionObject.messageName + itemId] = killSessionObject;
      globalArray[killSessionObject.responseTag + itemId] = killSessionObject;
   } else {
      killSessionObject.resetData();
   }
   // The targeted item info.
   killSessionObject.itemInfo = itemInfo;
   if (!!killSessionObject && !!router) {
      killSessionObject.setRequestXML(itemId);
      const handlerList = killSessionObject.composeHandlerList();
      router.postMessage(handlerList);
   }
};

KillSessionCommand.prototype.killSingleSession = function (itemInfo) {
   let handlerList,
      desktopId,
      itemId,
      killSessionObject,
      getDesktopsObject,
      getLaunchItemsObject,
      supportedProtocols,
      router = globalArray["router"];

   if (!itemInfo) {
      Logger.debug("error: the itemInfo do not exist for killSingleSession");
      return;
   }
   itemId = itemInfo.id;
   // Get KillSessionHandler
   killSessionObject = globalArray["kill-session" + itemId];
   if (!killSessionObject) {
      killSessionObject = new KillSessionHandler();
      globalArray[killSessionObject.messageName + itemId] = killSessionObject;
      globalArray[killSessionObject.responseTag + itemId] = killSessionObject;
   } else {
      killSessionObject.resetData();
   }
   // The targeted item info.
   killSessionObject.itemInfo = itemInfo;
   if (!util.brokerSupportApplication()) {
      if (!itemInfo.isDesktop) {
         Logger.debug("error: broker do not support kill application!");
         return;
      }
      desktopId = itemId;
      // Get GetDesktopsHandler
      getDesktopsObject = globalArray["get-desktops" + desktopId];
      if (!getDesktopsObject) {
         getDesktopsObject = new GetDesktopsHandler();
         globalArray[getDesktopsObject.messageName + desktopId] = getDesktopsObject;
         globalArray[getDesktopsObject.responseTag + desktopId] = getDesktopsObject;
      } else {
         getDesktopsObject.resetData();
      }
      // Trigger KillSessionHandler subsequent to GetDekstopsHandler.
      getDesktopsObject.appendSubHandler(killSessionObject);

      if (!!getDesktopsObject && !!router) {
         supportedProtocols = globalArray.supportedProtocols || ["BLAST"];
         if (supportedProtocols) {
            // DO not refresh UI at get-desktops response.
            getDesktopsObject.setRequestXML(supportedProtocols, false);
            handlerList = getDesktopsObject.composeHandlerList();
            router.postMessage(handlerList);
         } else {
            Logger.error("supported protocols is invalid.");
         }
      }
   } else {
      getLaunchItemsObject = globalArray["get-launch-items" + itemId];
      if (!getLaunchItemsObject) {
         getLaunchItemsObject = new GetLaunchItemsHandler(true);
         globalArray[getLaunchItemsObject.messageName + itemId] = getLaunchItemsObject;
         globalArray[getLaunchItemsObject.responseTag + itemId] = getLaunchItemsObject;
      } else {
         getLaunchItemsObject.resetData();
      }
      getLaunchItemsObject.appendSubHandler(killSessionObject);
      this.sendGetLaunchItems(getLaunchItemsObject);
   }
};
