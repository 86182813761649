/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Component } from "@angular/core";
import { AbstractModalComponent } from "../../../shared/common/commondialog/abstract-modal.component";
import { Logger } from "../../../core/libs/logger";
import { LocalStorageService } from "@html-core";
import { FileExtensionService } from "../file-extensions.service";
@Component({
   selector: "file-association-default-app",
   templateUrl: "./file-association-default-app-dialog.component.html",
   styles: [
      `
         /deep/ .modal.show .modal-dialog {
            width: 451.89px;
         }
      `
   ]
})
export class FileAssociationDefaultAppComponent extends AbstractModalComponent {
   public allReady: boolean = true;
   public trustedApps;
   public inputAppID: string;
   public extensionName: string;
   public fileExtensionList: Array<any> = [];
   public filteredAppsInfo = [];
   public showWarning: boolean;
   public searchedName: string = "";
   public hideAppWarning = true;

   private disabledHosts = [];
   private toBeUpdated = {};
   private defaultAppMap = {};

   constructor(
      private localStorageService: LocalStorageService,
      private faExtensionService: FileExtensionService
   ) {
      super();
      this._getDisabledHost();
      this._getExtensionInfoFromStorage();
   }

   private _getDisabledHost = () => {
      const res = this.localStorageService.get("fileAssociationDisabledHost");
      if (res) {
         try {
            this.disabledHosts = JSON.parse(res);
         } catch {
            Logger.error("Read disabledHostList from local storage fails");
         }
      }
   };

   public searchDefaultApp = () => {
      if (this.extensionName && this.extensionName.length > 0 && this.extensionName[0] !== ".") {
         this.extensionName = "." + this.extensionName;
      }
      if (this.defaultAppMap.hasOwnProperty(this.extensionName)) {
         this.filteredAppsInfo = this._getExtensionInfo(this.extensionName);
         this._updateRadioForDefaultApp();
         this.showWarning = false;
      } else {
         this.filteredAppsInfo = [];
         this.showWarning = true;
      }
      setTimeout(() => {
         this.searchedName = this.extensionName;
         this.hideAppWarning = !this.searchedName || this.filteredAppsInfo.length > 0;
      });
   };

   private _getExtensionInfo = (name) => {
      for (let i = 0; i < this.fileExtensionList.length; i++) {
         if (this.fileExtensionList[i].name === name) {
            return this.fileExtensionList[i].detailInfo;
         }
      }
      return null;
   };

   private _updateRadioForDefaultApp = () => {
      const appId = this.defaultAppMap[this.extensionName];
      setTimeout(() => {
         const elems = document.getElementsByClassName("fa-app-select");
         for (let i = 0; i < elems.length; i++) {
            //@ts-ignore
            if (elems[i].value === appId) {
               //@ts-ignore
               elems[i].checked = true;
            }
         }
      });
   };

   private _getExtensionInfoFromStorage = () => {
      if (!this.localStorageService.get("fileAssociationHorizon")) {
         Logger.info("No extension info in storage.");
         return;
      }
      const res = JSON.parse(this.localStorageService.get("fileAssociationHorizon"));
      if (res) {
         this.fileExtensionList = [];
         for (const extensionName in res) {
            const info = {
               name: extensionName,
               detailInfo: this._buildFADefaultAppList(res[extensionName], extensionName)
            };
            this.fileExtensionList.push(info);
         }
      } else {
         Logger.info("No extension info in storage.");
      }
   };

   private _buildFADefaultAppList = (infoMap, extensionName) => {
      if (extensionName === ".txt") {
         console.log("test");
      }
      const appInfoArray = [];
      this.defaultAppMap[extensionName] = infoMap["default"];
      const defaultAppServer = infoMap["default"].split("::")[0];
      const defaultAppId = infoMap["default"].split("::")[1];
      for (const appId in infoMap) {
         if (appId !== "default") {
            if (this.disabledHosts.indexOf(infoMap[appId].brokerURL) > -1) {
               continue;
            }
            infoMap[appId].default =
               infoMap[appId].appId === defaultAppId && infoMap[appId].brokerURL === defaultAppServer;
            infoMap[appId].id = appId;
            infoMap[appId].extensionName = extensionName;
            appInfoArray.push(infoMap[appId]);
         }
      }
      return appInfoArray;
   };

   public changeDefaultApp = (app) => {
      const newDefaultAppId = app.id;
      if (
         this.defaultAppMap.hasOwnProperty(app.extensionName) &&
         this.defaultAppMap[app.extensionName] === newDefaultAppId
      ) {
         //need to uncheck the radio if click on the selected radio
         this._uncheckSelectedApp();
         this._updateDefaultApp(app.extensionName, "");
         this.defaultAppMap[app.extensionName] = "";
      } else {
         this._updateDefaultApp(app.extensionName, newDefaultAppId);
         this.defaultAppMap[app.extensionName] = app.id;
      }
   };

   private _updateDefaultApp = (extensionName, appId) => {
      for (let i = 0; i < this.fileExtensionList.length; i++) {
         if (this.fileExtensionList[i].name === extensionName) {
            const appList = this.fileExtensionList[i].detailInfo;
            appList.forEach((app) => {
               app.default = app.id === appId;
            });
         }
      }
      this.toBeUpdated[extensionName] = appId;
   };

   public defaultAppsOkClicked = () => {
      Logger.debug("save default app: ");
      for (const extensionName in this.toBeUpdated) {
         const appId = this.toBeUpdated[extensionName];
         this.faExtensionService.updateDefaultApp(extensionName, appId);
      }
      this.closeModal();
   };

   private _uncheckSelectedApp = () => {
      const appsRadio = document.getElementsByClassName("fa-app-select");
      for (let i = 0; i < appsRadio.length; i++) {
         //@ts-ignore
         if (appsRadio[i].checked) {
            //@ts-ignore
            appsRadio[i].checked = false;
            Logger.debug("uncheck the default app.");
            break;
         }
      }
   };

   public onKeypress = (evt) => {
      if (this.searchedName !== this.extensionName) {
         this.filteredAppsInfo = [];
         this.hideAppWarning = true;
      } else {
         this.searchDefaultApp();
      }
   };
}
