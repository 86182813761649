/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * html5MMR-deviceMgr.ts --
 *
 * Manage audio/video devices and media stream for Html5 MMR plugin.
 */
import Logger from "../../../../core/libs/logger";
import { HTML5MMR_CONST, JKEYS, WEB_COMMAND } from "./html5MMR-consts";
import { HTML5MMR } from "./model/html5MMR.rpc.models";
import { webRTCAdapter } from "../../webrtc/webrtc-adapter";

export class Html5MMRDeviceMgr {
   private logger = new Logger(Logger.HTML5MMR);
   private mmrChannel;

   private deviceList;
   private camList;
   private camCheckCount;
   private videoCaps;

   private bIsVideoCapDone;
   private bVideoCapSent;

   private static instance: Html5MMRDeviceMgr;

   constructor() {
      this.deviceList = [];
      this.camList = [];
      this.camCheckCount = 0;

      this.videoCaps = {};
      this.bIsVideoCapDone = false;
      this.bVideoCapSent = false;
   }

   public static getInstance(): Html5MMRDeviceMgr {
      if (!Html5MMRDeviceMgr.instance) {
         Html5MMRDeviceMgr.instance = new Html5MMRDeviceMgr();
      }
      return Html5MMRDeviceMgr.instance;
   }

   public init = (MMRChannel) => {
      this.mmrChannel = MMRChannel;
   };

   /**
    * Access media stream, get full available device list and save locally.
    * Default selected device will be first one in the list.
    *
    */
   public getDeviceList = async () => {
      this.deviceList = [];
      this.camList = [];
      try {
         const tmpStream = await webRTCAdapter.getUserMedia({
            audio: true,
            video: true
         });
         try {
            const deviceList = await webRTCAdapter.enumerateDevices();
            this.deviceList = deviceList;
            for (let i = 0; i !== deviceList.length; i++) {
               if (deviceList[i].kind === "videoinput") {
                  this.camList.push(deviceList[i]);
               }
            }
         } catch (error) {
            this.logger.error("H5MMRChan failed to get device list: " + JSON.stringify(error));
         }
         tmpStream.getTracks().forEach((track) => {
            track.stop();
         });
      } catch (error) {
         this.logger.error("H5MMRChan getUserMedia failed while getting device list: " + JSON.stringify(error));
         return this.getDeviceListEx();
      }
   };

   /**
    * Access media stream, get full available device list and save locally.
    * Default selected device will be first one in the list.
    *
    */
   public getVideoCaps = async () => {
      this.camCheckCount = this.camList.length;
      if (this.camCheckCount <= 0) {
         await this.getDeviceList();
         this.camCheckCount = this.camList.length;
         if (this.camCheckCount <= 0) {
            this.logger.error("H5MMRChan getVideoCaps, there is no available camera connected.");
            this.bIsVideoCapDone = true;
            return;
         }
      }
      this.camList.forEach((cam) => {
         this._getVideoCaps(0, 0, cam);
      });
   };

   /**
    * Get the list of resolutions current camera support with given resolution candidate index
    * Save the video capabilites locally.
    *
    * For candidates in the same group, we will skip all the candidates after the one we can accquire successfully
    * This will save much time for better user experience
    *
    * @param {number} row, the row number in the ResolutionCandidate matrix
    * @param {number} col, the column number in the ResolutionCandidate matrix
    */
   private _getVideoCaps = (row, col, cam) => {
      if (row >= ResolutionCandidate.length) {
         this.camCheckCount--;
         if (this.camCheckCount === 0) {
            this.bIsVideoCapDone = true;
         }
         return;
      }
      if (col >= ResolutionCandidate[row].length) {
         this._getVideoCaps(row + 1, 0, cam); // move to next resolution group
         return;
      }

      const candidate = ResolutionCandidate[row][col];
      const deviceId = cam.deviceId;

      const constraints = {
         audio: false,
         video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
            width: { exact: candidate.width },
            height: { exact: candidate.height }
         }
      };

      webRTCAdapter
         .getUserMedia(constraints)
         .then((mediaStream) => {
            this.logger.debug(
               "Video capability test was successful with resolution: " + candidate.width + "x" + candidate.height
            );
            // Save current resolutin candicate and all the ones after
            this.videoCaps[cam.deviceId] = this.videoCaps[cam.deviceId] || [];
            for (let i = col; i < ResolutionCandidate[row].length; i++) {
               this.videoCaps[cam.deviceId].push(ResolutionCandidate[row][i]);
            }

            mediaStream.getTracks().forEach((track) => {
               track.stop();
            });
            return this._getVideoCaps(row + 1, 0, cam); // move to the next resolution group
         })
         .catch((error) => {
            this.logger.warning(
               "Video capability test was failed with resolution: " + candidate.width + "x" + candidate.height
            );
            return this._getVideoCaps(row, col + 1, cam); // move to the next candidate (might be in the next group)
         });
   };

   /**
    * Handle  WebRTC 'enumDevice' command from server.
    * For the first time of this command, client needs to send the video caps together.
    * @param {HTML5MMR.RPCRequest} rpc The incoming RPC object.
    */
   public handleEnumDevice = async (commandRequest: HTML5MMR.MMRWebTextRequest) => {
      this.logger.info("Html5MMR device manager handle enum instance cmd: " + commandRequest.webTextPayloadString);
      if (!commandRequest.isValid) {
         this.logger.error("Html5MMR device manager: invalid enum device command.");
         return false;
      }
      // Need to wait if video caps test is not done yet.
      if (!this.bIsVideoCapDone) {
         this.logger.debug("Html5MMR device manager video caps test is not done yet. Postpone handling enum device.");
         const self = this;
         setTimeout(function () {
            self.handleEnumDevice(commandRequest);
         }, 2000);
         return;
      }

      const cmdObj = commandRequest.webTextPayload;
      const respObj = {};

      if (!this.bVideoCapSent) {
         // Send out device list and video caps.
         this.bVideoCapSent = true;
         respObj[JKEYS.CAPS] = {};
         if (this.camList.length > 0) {
            respObj[JKEYS.CAPS] = this.videoCaps;
         }
      }
      try {
         this.deviceList = await webRTCAdapter.enumerateDevices();
         respObj[JKEYS.DEVICES] = this.deviceList;
         respObj[JKEYS.EVT] = HTML5MMR_CONST.ENUM_DEVICES;
         respObj[JKEYS.ID] = cmdObj[JKEYS.ID];
         respObj[JKEYS.PEER] = cmdObj[JKEYS.PEER];

         this.logger.info("Html5MMR device manager sending out device and video caps info: " + JSON.stringify(respObj));
         this.mmrChannel.sendRPC(HTML5MMR_CONST.MMR_MESSAGE.HTML5MMR_CMD_SEND_WEBTEXT, [
            commandRequest.instanceId,
            0,
            WEB_COMMAND.ENUM_DEVICE,
            JSON.stringify(respObj)
         ]);
      } catch (error) {
         this.logger.error("H5MMRChan failed to get device list: " + JSON.stringify(error));
      }
   };

   /**
    * Get device object by given deviceId
    * @param {string} deviceId
    * @returns {object} found deivce with given id or null if could not found
    */
   public getDevice = (deviceId) => {
      if (!deviceId) {
         return null;
      }
      let device = null;
      if (Array.isArray(this.deviceList)) {
         device = this.deviceList.find((item) => item.deviceId === deviceId);
      }
      return device;
   };

   /**
    * In case local machine might not have both audio & video devices
    * we retreive the device info separately
    * This is like the plan B
    *
    */
   private getDeviceListEx = async () => {
      this.deviceList = [];
      this.camList = [];
      // audio devices
      try {
         const tmpStream = await webRTCAdapter.getUserMedia({
            audio: true
         });
         try {
            const deviceList = await webRTCAdapter.enumerateDevices();
            for (let i = 0; i !== deviceList.length; i++) {
               if (deviceList[i].kind.includes("audio")) {
                  this.deviceList.push(deviceList[i]);
               }
            }
         } catch (error) {
            this.logger.error("H5MMRChan failed to get audio device list: " + JSON.stringify(error));
         }
         tmpStream.getTracks().forEach((track) => {
            track.stop();
         });
      } catch (error) {
         this.logger.error("H5MMRChan getUserMedia failed while getting audio device list: " + JSON.stringify(error));
      }
      // video devices
      try {
         const tmpStream = await webRTCAdapter.getUserMedia({
            video: true
         });
         try {
            const deviceList = await webRTCAdapter.enumerateDevices();
            for (let i = 0; i !== deviceList.length; i++) {
               if (deviceList[i].kind.includes("video")) {
                  this.deviceList.push(deviceList[i]);
                  if (deviceList[i].kind === "videoinput") {
                     this.camList.push(deviceList[i]);
                  }
               }
            }
         } catch (error) {
            this.logger.error("H5MMRChan failed to get video device list: " + JSON.stringify(error));
         }
         tmpStream.getTracks().forEach((track) => {
            track.stop();
         });
      } catch (error) {
         this.logger.error("H5MMRChan getUserMedia failed while getting video device list: " + JSON.stringify(error));
      }
   };
}

/**
 * Constant resolution candidates. Copied from native client.
 * Make sure candiates in the same group are in the descending order
 */
// TODO: consider to remove these weird or non-standard resolutions like: (848 : 480), (1280 : 800)
const ResolutionCandidate = [
   [
      { fps: 30, height: 720, width: 1280 },
      { fps: 30, height: 540, width: 960 },
      { fps: 30, height: 480, width: 848 },
      { fps: 30, height: 360, width: 640 },
      { fps: 30, height: 240, width: 424 },
      { fps: 30, height: 180, width: 320 }
   ], // 16 : 9 resolution group

   [
      { fps: 30, height: 480, width: 640 },
      { fps: 30, height: 240, width: 320 },
      { fps: 30, height: 120, width: 160 }
   ], // 4 : 3 resolution group

   [{ fps: 5, height: 800, width: 1280 }] // non-standard resolution
];
