/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * csrfTokenService.js
 *
 * Service for Workspace one integration
 */

import { globalArray } from "../jscdkClient";

export default function CsrfTokenService() {
   this.globalName = "csrf-token-service";
   this.STORAGE_KEY = "CSRF-TOKEN";
   this.csrfToken = null;
   this.csrfCheck = false;
}

CsrfTokenService.prototype = {};

CsrfTokenService.constructor = CsrfTokenService;

CsrfTokenService.prototype.getCsrfToken = function () {
   let token = null;
   const readLocalStorage = globalArray["read-function"];

   if (this.csrfToken) {
      token = this.csrfToken;
   } else {
      if (readLocalStorage) {
         token = readLocalStorage(this.STORAGE_KEY);
      }
      if (token) {
         this.csrfToken = token;
      }
   }
   return token;
};

CsrfTokenService.prototype.storeCsrfToken = function (token) {
   const writeLocalStorage = globalArray["write-function"];

   if (token) {
      if (writeLocalStorage) {
         writeLocalStorage(this.STORAGE_KEY, token);
      }
      this.csrfToken = token;
   }
};

CsrfTokenService.prototype.setCsrfEnforced = function (csrfCheck) {
   this.csrfCheck = csrfCheck;
};

CsrfTokenService.prototype.isCsrfEnforced = function () {
   return this.csrfCheck;
};
