/**
 * ******************************************************
 * Copyright (C) 2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class PrePrintDataService {
   public abstract checkPrinterList(callback): Promise<any>;
   public abstract getExchangePrinterList(callback): Promise<any>;
   public abstract constructExchangeClientInfoReply();
}
