/**
 * ******************************************************
 * Copyright (C) 2015-2020, 2023-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * rtavSessionControllerV2.ts --
 *
 * Class contains the logic for each rtav v2 action, and how to control classes to complete
 * a target action like "start streaming" or "enable audio".
 *
 * Class contains the main logic of rtav v2 session, which will be used
 * by the RTAV session
 *
 */

import { Injectable } from "@angular/core";
import { ResourceManagerV2 } from "./resourceManagerV2";
import { RtavDialogService } from "../rtavDialog.service";
import { settings } from "../rtav.settings";
import { EventBusService } from "@html-core";
import { ModalDialogService } from "../../../common/commondialog/dialog.service";
import { Logger } from "@html-core";

@Injectable()
export class RtavSessionControllerV2 {
   private sessionId;
   private deviceManager;
   private enableRTAVDTX;
   private hardwareAccelerationOptionV2;
   private hasResources = false;

   constructor(
      private resourceManager: ResourceManagerV2,
      private dialogService: RtavDialogService,
      private modalDialogService: ModalDialogService,
      private eventBusService: EventBusService
   ) {}

   public init = (sessionId, hardwareAccelerationOption) => {
      this.hasResources = false;
      this.sessionId = sessionId;
      this.hardwareAccelerationOptionV2 = hardwareAccelerationOption;
   };

   public initialize = () => {
      // inited header callback
   };

   private initResources(onDone) {
      Logger.debug("init resources start", Logger.RTAV);
      let stolenSessionId, confirmDeviceSteal, cancelDeviceSteal;

      if (!this.resourceManager.hasOccupiedResources(this.sessionId)) {
         if (this.resourceManager.hasUsableResources()) {
            this.resourceManager.occupyResources(this.sessionId, () => {
               Logger.debug("Ondone true in init resource", Logger.RTAV);
               onDone(true);
            });
         } else {
            if (this.dialogService.showDialog) {
               confirmDeviceSteal = () => {
                  this.resourceManager.stealSession(this.sessionId, () => {
                     Logger.debug(
                        "session " + this.sessionId + " trying to steal the devices from session " + stolenSessionId,
                        Logger.RTAV
                     );
                     onDone(true);
                  });
               };

               cancelDeviceSteal = () => {
                  Logger.debug(
                     "session " + this.sessionId + " failed to occupy devices, so RTAV function is not usable",
                     Logger.RTAV
                  );
               };

               stolenSessionId = this.resourceManager.getWorkingSessionId();
               // skip confirmation if devices are not used
               let showConflitDeviceDialog = false;
               this.eventBusService.listen("rtavShowConflictDialog").subscribe((msg) => {
                  showConflitDeviceDialog = msg.data;
               });
               this.eventBusService.dispatch({
                  type: "rtavIsUsingDevices",
                  data: stolenSessionId
               });
               if (showConflitDeviceDialog === false) {
                  confirmDeviceSteal();
                  return;
               } else {
                  onDone(false);
                  if (!this.modalDialogService.hasDialogOpen()) {
                     this.dialogService.showDialog(
                        {
                           stolenSessionId: stolenSessionId
                        },
                        confirmDeviceSteal,
                        cancelDeviceSteal
                     );
                  }
               }
            } else {
               Logger.debug(
                  "session " + this.sessionId + " failed to occupy devices, so RTAV function is not usable",
                  Logger.RTAV
               );
               onDone(false);
            }
         }
      } else {
         onDone(true);
      }
   }

   /**
    * release all local resources
    */
   private releaseResources() {
      // release
      if (this.resourceManager.hasOccupiedResources(this.sessionId)) {
         this.resourceManager.releaseResources(this.sessionId);
      }
      // reset flag
      this.hasResources = false;
      return true;
   }

   public setDeviceManager(deviceManager) {
      this.deviceManager = deviceManager;
   }

   /**
    * The API for start a rtav audio streaming
    * @param  {number} index The audio device index which can identify audio device
    */
   public async startAudioIn(deviceIndex) {
      if (!this.hasResources) {
         Logger.debug("no resource to start", Logger.RTAV);
         return false;
      }
      Logger.debug("start stream audio", Logger.RTAV);
      try {
         await this.deviceManager.startStreamAudio(deviceIndex);
      } catch (e) {
         Logger.error(e, Logger.RTAV);
      }
      return true;
   }

   /**
    * The API for stop a rtav audio device
    * @param  {number} index The video device index which can identify audio device
    */
   public stopAudioIn(deviceIndex, callback?) {
      if (!this.hasResources) {
         return;
      }
      Logger.debug("stop audio in", Logger.RTAV);
      this.disableAudioIn(deviceIndex, (sucess) => {
         if (typeof callback === "function") {
            callback(sucess);
         }
         Logger.debug("audio stopped " + sucess, Logger.RTAV);
      });
   }

   /**
    * The API for start a rtav video streaming
    * @param  {number} index The video device index which can identify video device
    */
   public async startVideoIn(deviceIndex) {
      if (!this.hasResources) {
         Logger.debug("no resource to start", Logger.RTAV);
         return false;
      }
      Logger.debug("start stream video", Logger.RTAV);
      await this.deviceManager.startStreamVideo(deviceIndex);
      return true;
   }

   /**
    * The API for stop a rtav video device
    * @param  {number} index The video device index which can identify video device
    */
   public stopVideoIn(deviceIndex, callback?) {
      if (!this.hasResources) {
         return;
      }
      Logger.debug("stop Video", Logger.RTAV);
      this.disableVideoIn(deviceIndex, (sucess) => {
         if (typeof callback === "function") {
            callback(sucess);
         }
         Logger.debug("video stopped " + sucess, Logger.RTAV);
      });
   }

   public setAudioDTXMode(isAudioDTXEnabled) {
      this.enableRTAVDTX = isAudioDTXEnabled;
   }

   /**
    * The API for enable audio in
    * @param {number} deviceIndex audio device index to be enabled
    * @param {function} callback callback when enabled succeed/fail
    */
   public async enableAudioIn(deviceIndex, callback) {
      Logger.debug("enableAudioIn in controllerv2", Logger.RTAV);
      let whenHasResources = async (canProcess) => {
         if (!canProcess) {
            Logger.debug("enable audio can't be done since current session has no resources", Logger.RTAV);
            callback(false);
            return;
         }
         try {
            let deviceAudioParam = settings.audioV2.audioDevicesParam.find((item) => item.deviceId === deviceIndex);
            const audioParam = {
               sampleRate: deviceAudioParam.sampleRate,
               channels: deviceAudioParam.channels,
               bitsPerSample: deviceAudioParam.bitsPerSample,
               codecPref: deviceAudioParam.codec,
               enableRTAVDTX: this.enableRTAVDTX
            };
            Logger.debug("before devicemnger start audio", Logger.RTAV);
            await this.deviceManager.startAudio(deviceIndex, audioParam);
            Logger.debug("after devicemnger start audio", Logger.RTAV);
            callback(true);
            this.resourceManager.emitDeviceStatusChanged();
         } catch (error) {
            Logger.debug(error, Logger.RTAV);
            Logger.debug("when using devicemnger open device has error", Logger.RTAV);
            callback(false);
         }
      };
      this.processWhenHaveResources(whenHasResources);
   }

   /**
    * The API for enable audio in
    * @param {number} deviceIndex video device index to be enabled
    * @param {function} callback callback when enabled succeed/fail
    */
   public async enableVideoIn(deviceIndex, callback) {
      Logger.debug("enableVideo in controllerv2", Logger.RTAV);
      let whenHasResources = async (canProcess) => {
         if (!canProcess) {
            Logger.debug("enable video can't be done since current session has no resources", Logger.RTAV);
            callback(false);
            return;
         }
         try {
            let deviceVideoParam = settings.videoV2.videoDevicesParam.find((item) => item.deviceId === deviceIndex);
            if (deviceVideoParam === undefined) {
               Logger.error("device param does not exist");
            }
            const videoParam = {
               width: deviceVideoParam.frameWidth,
               height: deviceVideoParam.frameHeight,
               fps: deviceVideoParam.frameRate,
               logLevel: 0,
               codecPref: deviceVideoParam.codec,
               hardwareAccelerationOption: this.hardwareAccelerationOptionV2
            };
            Logger.debug("before devicemnger start video", Logger.RTAV);
            await this.deviceManager.startVideo(deviceIndex, videoParam);
            Logger.debug("after devicemnger start video", Logger.RTAV);
            callback(true);
            this.resourceManager.emitDeviceStatusChanged();
         } catch (error) {
            Logger.debug(error, Logger.RTAV);
            Logger.debug("when using devicemnger open video device has error", Logger.RTAV);
            callback(false);
         }
      };
      this.processWhenHaveResources(whenHasResources);
   }

   /**
    * The API for disable audio in
    * @param {number} deviceIndex audio device index to be enabled
    * @param {function} callback callback when enabled succeed/fail
    */
   public async disableAudioIn(deviceIndex, callback) {
      Logger.debug("disabling audio for current session");
      if (!this.hasResources) {
         Logger.debug("disable success for the encoder and src has already been released", Logger.RTAV);
         callback(true);
         return;
      }
      try {
         await this.deviceManager.stopAudio(deviceIndex);
         callback(true);
         this.resourceManager.emitDeviceStatusChanged();
      } catch (error) {
         callback(false);
      }
   }

   /**
    * The API for disable video in
    * @param {number} deviceIndex video device index to be enabled
    * @param {function} callback callback when enabled succeed/fail
    */
   public async disableVideoIn(deviceIndex, callback) {
      Logger.debug("disabling video for current session");
      if (!this.hasResources) {
         Logger.debug("disable success", Logger.RTAV);
         callback(true);
         return;
      }
      try {
         await this.deviceManager.stopVideo(deviceIndex);
         callback(true);
         this.resourceManager.emitDeviceStatusChanged();
      } catch (error) {
         callback(false);
      }
   }

   /**
    * The API for stop video/audio in after close desktop
    * @param {number} deviceIndex video device index to be enabled
    * @param {function} callback callback when enabled succeed/fail
    */
   public async stopMediaIn(callback) {
      Logger.debug("stop video in and audio in for current session");
      if (!this.hasResources) {
         Logger.debug("stop audio in and video in success", Logger.RTAV);
         callback(true);
         return;
      }
      try {
         await this.deviceManager.stopAllAudio();
         await this.deviceManager.stopAllVideo();
         callback(true);
         this.resourceManager.emitDeviceStatusChanged();
      } catch (error) {
         callback(false);
      }
   }

   /**
    * The API for user level of stop of RTAV
    * should be called when the rtav is turned off by user for a specified session
    * @return {boolean} This returns whether the userStop action success
    */
   public userStop() {
      Logger.debug("user stop WebCam/Mic redirection", Logger.RTAV);

      this.releaseResources();
   }

   /**
    * The API for session level of stop of RTAV
    * should be called only when the hosted session is disconnected
    * @return {boolean} This returns whether the stop action success
    */
   public async stop() {
      Logger.debug("stop WebCam/Mic redirection", Logger.RTAV);
      await this.stopMediaIn(() => {
         Logger.debug("media closed", Logger.RTAV);
      });
      this.releaseResources();
   }

   /**
    * @return {Boolean} This returns whether the controller is send stream in any kind
    */
   public isSendingStream(type) {
      if (!this.hasResources) {
         return false;
      }
      if (type === "video") {
         return this.deviceManager.isVideoActive();
      } else if (type === "audio") {
         return this.deviceManager.isAudioActive();
      }
      return this.deviceManager.isAudioActive() || this.deviceManager.isVideoActive();
   }

   /**
    * Try to get the resource, and when do, call processFunc, and call callback(true)
    * if not, call callback(false)
    * @param {function} processFunc The function that will be called when the resources are ready
    */
   public processWhenHaveResources(processFunc) {
      Logger.debug("has resources is " + this.hasResources, Logger.RTAV);
      if (!this.hasResources) {
         this.initResources((success) => {
            if (success) {
               Logger.debug("get resources for operation", Logger.RTAV);
               this.hasResources = success;
               processFunc(true);
            } else {
               Logger.debug("don't get resources for operation", Logger.RTAV);
               processFunc(false);
            }
         });
      } else {
         processFunc(true);
      }
   }
}
