<div id="chrome-clipboard-container" *ngIf='chromeclient'>
   <chrome-clipboard></chrome-clipboard>
</div>

<div id="html5-clipboard-container" *ngIf='!chromeclient'>
   <div *ngIf="!isWebPanelFreeClipboard">
      <clipboard></clipboard>
   </div>
   <div *ngIf="isWebPanelFreeClipboard">
      <panel-free-clipboard></panel-free-clipboard>
   </div>
</div>

<div id="printer-redirection-container">
   <print-frame></print-frame>
</div>

<div id="file-transfer-container">
   <file-transfer-panel></file-transfer-panel>
</div>


<div id="usb-container" *ngIf='!isApplicationOnChromeclient'>
   <usb-redirection></usb-redirection>
</div>

<app-toasts></app-toasts>