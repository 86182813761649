/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/*
 * Store the log of chrome app with chrome.fileSystem API.
 * The localLogService is in an UNKNOWN state when the application started, the
 * localLogService will be injected by the global controller and it will read
 * the previous setting from the storage with LocalStorageService.
 *
 * The localLogService will only record log into the queue in STATE.UNKNOWN;
 * The localLogService will not record log in STATE.OFF;
 * The localLogService record log into the queue and write the queue into the
 * configured log fie when the queue.length > 20 (MAX_DEFAULT_LOG);
 *
 * And the log will flushed into file with max delay of 2s (MAX_LOG_DELAY).
 *
 */

import { Injectable } from "@angular/core";
import Logger from "../libs/logger";
import { LocalStorageService, clientUtil } from "@html-core";
import { ModalDialogService } from "../../shared/common/commondialog/dialog.service";

enum STATE {
   UNKNOWN = 0,
   ON = 1,
   OFF = 2
}

@Injectable({
   providedIn: "root"
})
export class LocalLogService {
   private static readonly MAX_LOG_DELAY = 2 * 1000;
   private static readonly MAX_DEFAULT_LOG = 20;

   private fileWriter = null;
   private logQueue = [];
   private readyToWriteLog = true;
   private logServiceState = STATE.UNKNOWN;
   private logWriteTimer;

   constructor(
      private localStorageService: LocalStorageService,
      private modalDialogService: ModalDialogService
   ) {
      if (clientUtil.isChromium()) {
         try {
            chrome.runtime.onConnect.addListener(this.listenerFunction);
         } catch (e) {
            Logger.warning(e);
         }
      }
   }

   public listenerFunction = (port) => {
      if (port.name === "LogService") {
         port.onMessage.addListener((msg) => {
            Logger.addRawLog(msg.log);
         });
      }
      if (port.name === "WriteFile") {
         port.onMessage.addListener((msg) => {
            this.write(msg.log);
         });
      }
   };

   private write = (logString) => {
      if (this.logServiceState === STATE.OFF) {
         return;
      }
      this.logQueue.push(logString);
      if (this.logServiceState === STATE.ON) {
         if (this.logQueue.length >= LocalLogService.MAX_DEFAULT_LOG) {
            this.pushLogToFile();
         }
         if (!this.logWriteTimer) {
            this.logWriteTimer = setTimeout(this.pushLogToFile, LocalLogService.MAX_LOG_DELAY);
         }
      }
   };

   private getFileNameByTime = () => {
      const currentDate = new Date(),
         year = currentDate.getFullYear(),
         month = currentDate.getMonth() + 1,
         day = currentDate.getDate();
      return (
         "vmware-horizon-chromeclient-" +
         year +
         "-" +
         (month >= 10 ? month : "0" + month) +
         "-" +
         (day >= 10 ? day : "0" + day) +
         ".log"
      );
   };

   private disableLog = () => {
      this.logServiceState = STATE.OFF;
      this.localStorageService.set("isCollectLogEnabled", "false");
      this.localStorageService.set("chromeClientLogPath", null);
   };

   private onFileGetInvalid = () => {
      this.fileWriter = null;
      if (this.logServiceState === STATE.ON) {
         this.disableLog();
         setTimeout(() => {
            this.modalDialogService.showError({
               data: {
                  titleKey: "WARNING",
                  contentKey: "CLIENT_LOG_INVALID"
               }
            });
         });
      }
   };

   private pushLogToFile = () => {
      if (!this.logQueue.length || this.logServiceState !== STATE.ON) {
         return;
      }
      if (this.logWriteTimer) {
         clearTimeout(this.logWriteTimer);
         this.logWriteTimer = null;
      }

      if (this.readyToWriteLog) {
         this.readyToWriteLog = false;
         const logStringBuffer = this.logQueue.join("\n") + "\n";
         this.logQueue = [];
         if (logStringBuffer) {
            try {
               if (!this.fileWriter || !!this.fileWriter.error) {
                  throw "log file get lost while using";
               }
               this.fileWriter.seek(this.fileWriter.length);
               this.fileWriter.write(new Blob([logStringBuffer]));
            } catch (e) {
               Logger.exception(e);
               this.onFileGetInvalid();
            }
         }
      }
   };

   private createFileWriter = (fileEntry) => {
      if (!fileEntry) {
         this.logServiceState = STATE.OFF;
         return;
      }

      fileEntry.createWriter((writer) => {
         writer.onwriteend = (e) => {
            this.readyToWriteLog = true;
         };

         writer.onerror = (e) => {
            this.readyToWriteLog = true;
            this.onFileGetInvalid();
         };

         this.fileWriter = writer;
         this.logServiceState = STATE.ON;
         const logFileEntryId = chrome.fileSystem.retainEntry(fileEntry);
         this.localStorageService.set("logFileEntryId", logFileEntryId);
         if (this.logQueue.length > 0) {
            this.pushLogToFile();
         }
      });
   };

   private restoreEntry = (id) => {
      return new Promise((resolve, reject) => {
         if (!id) {
            reject("no log id found");
            return;
         }
         chrome.fileSystem.isRestorable(id, (isRestorable) => {
            if (!isRestorable) {
               reject("invalid log on disk: " + id);
               return;
            }
            chrome.fileSystem.restoreEntry(id, (entry) => {
               if (!entry) {
                  reject("can't find the log on disk: " + id);
                  return;
               }
               entry.getMetadata((metaData) => {
                  if (metaData) {
                     resolve(entry);
                  } else {
                     reject("log file can't be accessed on disk");
                  }
               }, reject);
            });
         });
      });
   };

   /**
    * Init client log, throw error when previous setting get invalid
    */
   public init = async () => {
      this.logQueue = [];
      this.logQueue.push("\n\n");
      Logger.setLogFunction(this.write);

      chrome.storage.local.get(null, async (a) => {
         const logEnabled = this.localStorageService.get("isCollectLogEnabled") === "true";
         Logger.info("client log is " + (logEnabled ? "enabled" : "disabled"));
         if (!logEnabled) {
            this.logServiceState = STATE.OFF;
            return;
         }

         try {
            const logFileEntryId = this.localStorageService.get("logFileEntryId");
            const logEntry = await this.restoreEntry(logFileEntryId);
            this.createFileWriter(logEntry);
         } catch (e) {
            Logger.warning(e);
            this.disableLog();
            throw e;
         }
      });
   };

   public startLogging = (type?: string) => {
      // Get chosen log file.
      return new Promise((resolve, reject) => {
         chrome.fileSystem.chooseEntry(
            {
               type: "saveFile",
               suggestedName: this.getFileNameByTime(),
               acceptsAllTypes: true
            },
            (fileEntry) => {
               // Entry does not exist
               if (!fileEntry) {
                  resolve(fileEntry);
                  return;
               }
               // Use local storage to retain access to this directory.
               chrome.fileSystem.getDisplayPath(fileEntry, (entry) => {
                  setTimeout(() => {
                     Logger.debug("Log has been saved in '" + entry + "'.");
                     resolve(entry);
                  });
               });
               this.createFileWriter(fileEntry);
            }
         );
      });
   };

   public endLogging = () => {
      if (this.logServiceState === STATE.ON) {
         this.logQueue.push("End collect log\n");
         this.pushLogToFile();
         this.fileWriter = null;
         this.logServiceState = STATE.OFF;
      }
   };
}
