/**
 * *****************************************************
 * Copyright 2017-2024 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import TextParser from "../../../shared/desktop/common/text-parser.service";
import { MKSVCHAN_CONST } from "../../../shared/desktop/channels/MKSVchan/mksvchan-consts";
import { MKSVchan } from "../../../shared/desktop/channels/mksvchan";
import { FeatureConfigs } from "../../../shared/common/model/feature-configs";
import { MksvchanService } from "../../../shared/desktop/channels/mksvchan.service";
import { AB } from "../../../shared/desktop/common/appblast-util.service";
import { Component, Input, HostListener } from "@angular/core";
import { SessionLifeCycleService } from "../blast-common/session-life-cycle.service";
import { TranslateService, BusEvent } from "@html-core";
import { EventBusService } from "../../../core/services/event/event-bus.service";
import { ClipboardPrimaryDesktopMonitorService } from "./common-clipboard-desktop-monitor.service";

@Component({
   selector: "chrome-clipboard",
   templateUrl: "./clipboard.component.html"
})
export class ClipboardController {
   private _globalClipboard;
   public clipboard;
   public clipboardPolicy;
   public hasClipboardData: boolean = false;
   public clipboardReady: boolean = false;
   public copyEnabled: boolean = true;
   public pasteEnabled: boolean = true;
   public killSwitchOn: boolean = true;
   public windowListener: boolean = false;
   public lastContentHashValue: any = null;
   private timeOnFoucsCurrentWindow: number = 0;

   @Input() blastSession;

   @HostListener("window:focus", ["$event"]) handleWindowFocus(event) {
      Logger.info("primary window get focus", Logger.UI);
      this.timeOnFoucsCurrentWindow = new Date().getTime();
      if (this.windowListener) {
         this.OnWindowsGetFocus(event);
      }
   }
   @HostListener("window:blur", ["$event"]) handleWindowBlur(event) {
      Logger.info("primary window blur", Logger.UI);
      if (this.windowListener) {
         if (this.copyEnabled === false && this.pasteEnabled === true) {
            return;
         } else {
            this.OnWindowLoseFocus(event);
         }
      }
   }
   constructor(
      private translate: TranslateService,
      private mksvchanService: MksvchanService,
      private featureConfigs: FeatureConfigs,
      private sessionLifeCycle: SessionLifeCycleService,
      private eventBusService: EventBusService,
      private clipboardDesktopMonitorService: ClipboardPrimaryDesktopMonitorService
   ) {
      const defaultText = "";
      const defaultHtml = "";
      this._globalClipboard = new MKSVchan.Clipboard();
      this.clipboard = { text: defaultText, html: defaultHtml };
      this.clipboardPolicy = { text: "" };
      // Listen to server clipboard updates and sync panel with remote
      // clipboard data
      this.mksvchanService.addEventListener("clipboardChanged", this._handleClipboardChange);

      this.mksvchanService.addEventListener("clipboardCapabilitiesChanged", this._handleCapabilitiesChanged);

      this.mksvchanService.addEventListener("clipboardReady", (id) => {
         /**
          * Called by a wmks session when its clipboard is ready. If the
          * session is the current session, sync the remote clipboard with the
          * local clipboard
          */
         this.blastSession = this.sessionLifeCycle.getCurrentSession();

         if (!!this.blastSession && id === this.blastSession.key) {
            const mksVchanClient = this.mksvchanService.getClient(id);
            this._handleCapabilitiesChanged(
               id,
               mksVchanClient.ready,
               mksVchanClient.copyEnabled,
               mksVchanClient.pasteEnabled
            );
            this.pushClipboard();
         }
         this.clipboardDesktopMonitorService.onMonitorReady();
      });

      this.mksvchanService.addEventListener("clipboardPush", this.pushClipboard);

      this.featureConfigs.registerListener("KillSwitch-Clipboard", this.setKillSwitch);
      this.windowListener = true;

      this.eventBusService
         .listen(BusEvent.ClipboardClientContentMsg.MSG_TYPE)
         .subscribe((msg: BusEvent.ClipboardClientContentMsg) => {
            // Clear the local clipboard before set data
            this._globalClipboard.clear();
            if (msg.text !== undefined) {
               this.setClipboardText(msg.text);
            }
            if (msg.html !== undefined) {
               const cfHtml = TextParser.htmlToCFHtml(msg.html);
               if (cfHtml !== null && cfHtml !== "") {
                  this.setClipboardHtml(cfHtml);
               }
            }

            this.pushClipboard();
         });

      this.eventBusService
         .listen(BusEvent.ClipboardContentHashValue.MSG_TYPE)
         .subscribe((msg: BusEvent.ClipboardContentHashValue) => {
            this.lastContentHashValue = msg.clipboardContentHashValue;
         });
   }

   ngOnDestroy() {
      // Unbind the onFoucs/OnBlur event on body
      this.windowListener = false;
   }

   public setKillSwitch = (switchOn) => {
      this.killSwitchOn = switchOn;
   };

   private setDefaultPolicyText = () => {
      let policyText = "";
      if (!this.clipboardReady) {
         policyText = this.translate._T("COPY_PASTE_UNAVAILABLE_CONTENT");
      } else if (this.clipboardReady && this.copyEnabled && this.pasteEnabled) {
         policyText = this.translate._T("COPY_PASTE_ENABLED_CONTENT");
      } else if (this.clipboardReady && this.copyEnabled && !this.pasteEnabled) {
         policyText = this.translate._T("PASTE_DISABLED_CONTENT");
      } else if (this.clipboardReady && !this.copyEnabled && this.pasteEnabled) {
         policyText = this.translate._T("COPY_DISABLED_CONTENT");
      } else if (this.clipboardReady && !this.copyEnabled && !this.pasteEnabled) {
         policyText = this.translate._T("COPY_PASTE_DISABLED_CONTENT");
      }
      this.clipboardPolicy.text = policyText;
   };

   /*
    * Intercepts a copy event and replaces the copied text with the current
    * clipboard contents.
    */
   public clipboardCopy = (e) => {
      Logger.debug("Copy operation is captured.", Logger.CLIPBOARD);
      if (!this.hasClipboardData) {
         Logger.debug("There is no data in the clipboard.", Logger.CLIPBOARD);
         return;
      }
      Logger.debug("copy on blur text: " + this.clipboard.text?.length, Logger.CLIPBOARD);
      Logger.debug("copy on blur html: " + this.clipboard.html?.length, Logger.CLIPBOARD);

      if (this.clipboard.text === null || this.clipboard.text === "") {
         Logger.debug("primary monitor copy operation set clipboard text to empty", Logger.CLIPBOARD);
      }
      if (this.clipboard.html === null || this.clipboard.html === "") {
         Logger.debug("primary monitor copy operation set clipboard html to empty", Logger.CLIPBOARD);
      }

      AB.setClipboardText(e, this.clipboard.text ? this.clipboard.text : "");

      if (this.clipboard.html !== null) {
         const html = TextParser.CFHtmlToHtml(this.clipboard.html);
         if (html !== null) {
            AB.setClipboardHtml(e, html ? html : "");
         }
      }

      Logger.debug(this.translate._T("CLIPBOARD_COPIED_M"), Logger.CLIPBOARD);
      e.preventDefault();
   };

   /**
    * setClipboardText
    *
    * Set a local clipboard text change to the global clipboard.
    *
    * @params text: A string that we will update the remote clipboard with
    */
   private setClipboardText = (text) => {
      if (!this.blastSession) {
         return;
      }

      const client = this.mksvchanService.getClient(this.blastSession.key);
      if (!client || !client.pasteEnabled) {
         Logger.warning(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }

      this._globalClipboard.setText(text, MKSVCHAN_CONST.CP_FORMAT.TEXT);
   };

   /**
    * setClipboardHtml
    *
    * Set a local clipboard html change to the global clipboard.
    *
    * @params html: A string that we will update the remote clipboard with
    */
   private setClipboardHtml = (html) => {
      if (!this.blastSession) {
         return;
      }

      const client = this.mksvchanService.getClient(this.blastSession.key);
      if (!client || !client.pasteEnabled) {
         Logger.warning(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }

      this._globalClipboard.setHtml(html, MKSVCHAN_CONST.CP_FORMAT.HTML_FORMAT);
   };

   /*
    * Intercept a paste event to get paste data, then update clipboard and send
    * the appropriate call to update the server clipboard.
    */
   public clipboardPaste = async (e) => {
      let text, html, contentHashValue;
      let cfHtml;

      Logger.debug("Paste operation is captured and data is sent to remote server.", Logger.CLIPBOARD);
      text = AB.getClipboardText(e);
      html = AB.getClipboardHtml(e);
      Logger.debug("Paste event with text: " + text?.length, Logger.CLIPBOARD);
      Logger.debug("Paste event with html: " + html?.length, Logger.CLIPBOARD);
      if (!text && !html) {
         Logger.debug("Don't update to agent's clipboard since text and html are both empty.", Logger.CLIPBOARD);
         e.preventDefault();
         return;
      }
      this.clipboard.text = text;
      this.clipboard.html = html;

      if (this.clipboard.text === null || this.clipboard.text === "") {
         Logger.debug("primary monitor paste operation get clipboard text is empty", Logger.CLIPBOARD);
      }
      if (this.clipboard.html === null || this.clipboard.html === "") {
         Logger.debug("primary monitor paste operation get clipboard html is empty", Logger.CLIPBOARD);
      }
      contentHashValue = await this._digestMessage(text);

      /**
       * when clipboard GPO only enable local -> remote direction, we should not execute 'paste'
       * command when switch between two monitors within agent, only execute 'paste' command when
       * switch between client to agent, if user switch between two monitors, then the last paste
       * content hex string will be same as this time, if change from client to agent, the last paste
       * content hex string will be different with this time
       */
      if (this.copyEnabled === false && this.pasteEnabled === true && contentHashValue === this.lastContentHashValue) {
         Logger.debug("primary monitor paste content is the same as last time and return", Logger.CLIPBOARD);
         return;
      } else {
         this.eventBusService.dispatch(
            new BusEvent.ClipboardContentHashValue({
               clipboardContentHashValue: contentHashValue
            })
         );

         // Clear the local clipboard before set data
         this._globalClipboard.clear();
         this.setClipboardText(text);

         cfHtml = TextParser.htmlToCFHtml(html);
         if (cfHtml !== null) {
            this.setClipboardHtml(cfHtml);
         }

         this.pushClipboard();
         e.preventDefault();
         this.lastContentHashValue = contentHashValue;
      }
      Logger.debug("Paste Clipboard Panel Done in Primary Monitor", Logger.CLIPBOARD);
   };

   private _digestMessage = async (message) => {
      const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
      const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8); // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
      return hashHex;
   };

   private _handleCapabilitiesChanged = (id, clipboardReady, copyEnabled, pasteEnabled) => {
      /**
       * Notifies to update its clipboard capabilities. Note
       * that it always updates with the capabilities of currently active
       * session. If this session is not currently active
       * then the call is redundant, but harmless.
       */
      if (!!this.blastSession && id === this.blastSession.key) {
         setTimeout(() => {
            const oldStat = this.clipboardReady;

            this.clipboardReady = clipboardReady;
            this.copyEnabled = copyEnabled;
            this.pasteEnabled = pasteEnabled;
            /*
             * Sync local clipboard to remote once clipboard is ready
             */
            if (oldStat !== true && this.clipboardReady === true) {
               this.OnWindowsGetFocus(null);
            }
            this.setDefaultPolicyText();
            Logger.info(this.clipboardPolicy.text, Logger.CLIPBOARD);
         });
      }
   };

   private _handleClipboardChange = (id, clipboard, error) => {
      this._globalClipboard = clipboard;

      const data: any = {};

      data.text = this._globalClipboard.getText(MKSVCHAN_CONST.CP_FORMAT.TEXT);
      data.html = this._globalClipboard.getText(MKSVCHAN_CONST.CP_FORMAT.HTML_FORMAT);

      /*
       * File transfer and clipboard share the same error code here
       * Can't detect whether to show notification on ft or clipboard panel
       * So trigger both of them, can't use dialog, or it will effect normal
       * user experience in the desktop.
       */
      if (error === MKSVCHAN_CONST.CLIPBOARD_ERROR.DISALLOWED_BY_AUDIT) {
         Logger.info(this.translate._T("ERROR_MSG_DISALLOWED_BY_AUDIT"), Logger.CLIPBOARD);
         this.mksvchanService.emit("fileTransferBlockedByAudit");
         return;
      }

      if (data.text != null || data.html != null) {
         this._handleClipboardDataChange(data, error);
      }
      if (data.text === null && data.html === null) {
         //When there is a copy event and no text and html inside, we assume it's an image-copy
         Logger.info("There is an image copied");
         this.clipboard.text = null;
         this.clipboard.html = null;
         this.hasClipboardData = true;
         this.eventBusService.dispatch(new BusEvent.ClipboardAgentContentMsg({ text: null, html: null }));
      }
   };

   private _handleClipboardDataChange = (data, error) => {
      setTimeout(() => {
         Logger.info("Clipboard data in the remote server is synced to client.", Logger.CLIPBOARD);
         if (data === null) {
            Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
            return;
         }

         this.clipboard.text = data.text;
         this.clipboard.html = data.html;
         this.hasClipboardData = true;

         this.eventBusService.dispatch(
            new BusEvent.ClipboardAgentContentMsg({
               text: this.clipboard.text,
               html: this.clipboard.html
            })
         );

         if (error === MKSVCHAN_CONST.CLIPBOARD_ERROR.MAX_LIMIT_EXCEEDED) {
            Logger.info(this.translate._T("CLIPBOARD_TRUNCATED_M"), Logger.CLIPBOARD);
         } else {
            Logger.info(this.translate._T("CLIPBOARD_SYNCED_M"), Logger.CLIPBOARD);
         }
      });
   };

   /**
    * Pushes the local clipboard to the remote guest.
    */
   public pushClipboard = () => {
      if (!this.blastSession) {
         Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }
      const client = this.mksvchanService.getClient(this.blastSession.key);
      if (!client) {
         Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         return;
      }
      client.sendClipboard(
         this._globalClipboard,
         () => {
            Logger.debug("Clipboard synced with focused session", Logger.CLIPBOARD);
         },
         () => {
            Logger.info(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         }
      );
   };

   /**
    * Sends a local clipboard text change to the remote guest.
    */
   public sendClipboardText = (text) => {
      if (!this.blastSession) {
         return;
      }

      const mksVchanClient = this.mksvchanService.getClient(this.blastSession.key);
      if (!mksVchanClient || !mksVchanClient.pasteEnabled) {
         return;
      }

      mksVchanClient.sendClipboardText(
         text,
         (text, error) => {
            const data: any = {};
            data.text = text;
            Logger.debug("Clipboard update successfully sent", Logger.CLIPBOARD);
            this._globalClipboard.setText(text, MKSVCHAN_CONST.CP_FORMAT.TEXT);
            this._handleClipboardDataChange(data, error);
         },
         () => {
            Logger.warning(this.translate._T("CLIPBOARD_FAILED_M"), Logger.CLIPBOARD);
         }
      );
   };

   // When the root window gets the focus, simulate a paste command, copy client to agent
   public OnWindowsGetFocus = (e) => {
      if (!this.killSwitchOn) {
         return;
      }

      if (!this.clipboardReady) {
         Logger.debug("Clipboard is not ready.", Logger.CLIPBOARD);
         return;
      }

      let skip = this.clipboardDesktopMonitorService.shouldSkipPaste(this.timeOnFoucsCurrentWindow);

      if (skip) {
         Logger.info("Skip paste between monitors");
         e.preventDefault();
         return;
      }

      const panelHandle: HTMLInputElement = document.querySelector("#clipboard-input");
      /**
       * add a 100 ms timeout here as a workaround for situation when copy content from
       * extend monitor agent to primary monitor agent. At that time, maybe primary window
       * paste event will trigger before extend monitor finish copy event. So we need to
       * add a timeout to make sure paste event were triggered after copy event finished.
       * And after try, found that 50 ms can satisfy, so add a 100 ms timeout here make it
       * safer.
       */
      setTimeout(() => {
         panelHandle.focus();
         panelHandle.select();
         const ret = document.execCommand("paste");
         if (!ret) {
            Logger.info("Paste command execute error.", Logger.CLIPBOARD);
         }
         this.eventBusService.dispatch(
            new BusEvent.ClipboardGPOMsg({
               copyEnabled: this.copyEnabled,
               pasteEnabled: this.pasteEnabled
            })
         );
      }, 100);
   };

   // When the root window lose the focus, simulate a copy command, copy agent to client
   public OnWindowLoseFocus = (e) => {
      if (!this.killSwitchOn) {
         return;
      }

      if (!this.clipboardReady) {
         Logger.debug("Clipboard is not ready.", Logger.CLIPBOARD);
         return;
      }

      const panelHandle: HTMLInputElement = document.querySelector("#clipboard-input");
      panelHandle.focus();
      const ret = document.execCommand("copy");
      if (!ret) {
         Logger.info("Copy command execute error.", Logger.CLIPBOARD);
      }
      this.eventBusService.dispatch(
         new BusEvent.ClipboardGPOMsg({
            copyEnabled: this.copyEnabled,
            pasteEnabled: this.pasteEnabled
         })
      );
   };
}
