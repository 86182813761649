/**
 * ******************************************************
 * Copyright (C) 2014-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import * as CST from "@html-core";
import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { BaseViewComponent, BaseViewComponentInterface } from "../../view/base-view.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { VmwHorizonClientAuthType } from "../../../../../SDK/src/lib/model/enum";

import { JscdkCommonInvoker } from "../../common/jscdk/jscdk-common-invoker";

import { ReauthDialogService } from "../../../shared/desktop/re-auth/reauth-dialog.service";
import { ModalDialogService } from "../../common/commondialog/dialog.service";
import { repeatValidator } from "../common/validators";
import { Subject } from "rxjs";
import { TranslateService } from "@html-core";
import { LoginService } from "./../login-root/login.service";
interface SecurIDPinChangeData {
   content: {
      message?: string;
      error?: string;
      pin1?: string;
      pin1ReadOnly?: string;
      reAuth?: boolean;
      prompt?: string;
   };
}

class SecurIDPinChangeCredential {
   constructor(
      public pin1: string,
      public pin2: string,
      public reAuth: boolean
   ) {}
}

@Component({
   selector: "login-securid-pin-change",
   templateUrl: "./securid-pinchange.component.html"
})
export class SecurIDPinChangeComponent extends BaseViewComponent implements BaseViewComponentInterface {
   @Input() fromData;
   public cancelLoading: boolean = false;

   public error: string;
   public initialPin1: string;
   public pin1ReadOnly: boolean;
   public pin1Type: string;
   public prompt: string;
   private _reAuth: boolean;

   public securIDPin1Control: UntypedFormControl;
   public securIDPin2Control: UntypedFormControl;
   public securIDPinChangeForm: UntypedFormGroup;
   // private static refreshSubject$ = new Subject<any>();
   // public static redraw = function(data: any) {
   //    SecurIDPinChangeComponent.refreshSubject$.next(data);
   // };

   constructor(
      changeDetector: ChangeDetectorRef,
      private jscdkCommonInvoker: JscdkCommonInvoker,
      private reauthDialogService: ReauthDialogService,
      private translationService: TranslateService,
      private modalDialogService: ModalDialogService,
      private loginService: LoginService
   ) {
      super(changeDetector, "SecurIDPinChange");
      // SecurIDPinChangeComponent.refreshSubject$.subscribe(() => {
      //    this.onRefreshed();
      // });
      this.loginService.securIDPinChange$.subscribe((data) => {
         this.onRefreshed();
      });
      this.securIDPin1Control = new UntypedFormControl(this.initialPin1, [Validators.required]);
      this.securIDPin2Control = new UntypedFormControl("", [Validators.required]);
      this._initData();
      this.securIDPinChangeForm = new UntypedFormGroup(
         {
            pin1: this.securIDPin1Control,
            pin2: this.securIDPin2Control
         },
         [repeatValidator("pin1", "pin2")]
      );
   }

   public onRefreshed = () => {
      super.onRefreshed();
      this._initData();
   };

   private _initData = () => {
      const data = this.data as SecurIDPinChangeData;
      this.onAuthScreen(this.componentName, data);

      if (data.content.error) {
         this.error = data.content.error;
      } else {
         this.error = "";
      }

      if (data.content.prompt) {
         this.prompt = data.content.prompt;
      } else if (data.content.message) {
         this.prompt = this.translationService._T("dialog_prompt_pinchange") + data.content.message;
      } else {
         this.prompt = "";
      }
      /**
       * handle UI of three types of PIN change
       *
       * (1) MUST_CHOOSE_PIN: show textfields (pin1 and pin2) with
       * blank
       * (2) CANNOT_CHOOSE_PIN: show textfield pin1 with value
       * readonly returned from broker
       * (3) USER_SELECTABLE: show textfield pin1 with value
       * editable returned from broker
       *
       */
      if (data.content.pin1) {
         this.initialPin1 = data.content.pin1;
         this.securIDPin1Control.setValue(this.initialPin1);
      } else {
         this.initialPin1 = "";
      }

      this.pin1ReadOnly = data.content.pin1ReadOnly === "yes";

      if (this.pin1ReadOnly) {
         this.pin1Type = "text";
      } else {
         this.pin1Type = "password";
      }

      this._reAuth = !!data.content.reAuth;

      // If it is cancel button clicked
      this.cancelLoading = false;
   };

   private _getCredential = (): SecurIDPinChangeCredential => {
      const fromValue = this.securIDPinChangeForm.value;
      return new SecurIDPinChangeCredential(fromValue.pin1, fromValue.pin2, this._reAuth);
   };

   public onSubmit = (event) => {
      this.cancelLoading = false;
      /**
       * web API 'event.submitter' is not supported in IE and Safari
       * so add condition to verified if event.submitter is undefined here
       */
      if (
         !this.securIDPinChangeForm.invalid &&
         !this.blankOutContent &&
         (!event.submitter || (event.submitter && event.submitter.id !== "cancelLoginBtn"))
      ) {
         const credential = this._getCredential();
         if (credential.reAuth) {
            this.reauthDialogService.setReAuthCallBack();
         }
         this.jscdkCommonInvoker.submitSecurIDPinChange(credential.pin1, credential.pin2, credential.reAuth);
      }
      this.clearPin1();
      this.clearPin2();
   };

   public cancel = () => {
      this.cancelLoading = true;
      if (this.blankOutContent) {
         this.jscdkCommonInvoker.cancelCurrentRequest({
            name: "SecurIDPinChange",
            content: {
               prompt: this.prompt,
               pin1: this.initialPin1,
               pin1ReadOnly: this.pin1ReadOnly
            }
         });
         this.blankOutContent = false;
      } else {
         this.jscdkCommonInvoker.cancelAuthentication(<VmwHorizonClientAuthType>"SecurID_PIN_Change");
         if (this.modalDialogService.checkDialogOpenByType("reauth-window")) {
            this.modalDialogService.closeDialogByType("reauth-window");
         }
      }
   };

   public clearPin1 = () => {
      this.securIDPin1Control.setValue("");
   };
   public clearPin2 = () => {
      this.securIDPin2Control.setValue("");
   };
}
