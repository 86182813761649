/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../../core/libs/logger";

export class StructWithMarker {
   protected logger = new Logger(Logger.CHAN_UTIL);
   protected marks;
   protected randomAccessed;
   protected allowStructAlign: boolean;

   // virtual
   public getOffset: () => number;

   constructor(allowStructAlign: boolean) {
      this.marks = [];
      this.randomAccessed = false;
      this.allowStructAlign = allowStructAlign;
   }

   public getKey = (struct?): string => {
      if (struct) {
         return JSON.stringify(struct);
      }
      return "Default Key";
   };

   public getActiveMarker = (struct?) => {
      if (!this.allowStructAlign) {
         return null;
      }
      const key = this.getKey(struct);

      if (!this.marks || this.marks.length <= 0) {
         Logger.warning("no active marker found");
         return null;
      }
      const activedMarker = this.marks[this.marks.length - 1];

      if (struct !== undefined && activedMarker.key !== key) {
         Logger.error("unmatched active marker");
         return null;
      }
      return activedMarker;
   };

   public pushMark = (struct?) => {
      if (!this.allowStructAlign) {
         return;
      }
      if (this.randomAccessed) {
         Logger.trace("can't add marker with random access");
         return;
      }
      const key = this.getKey(struct);

      this.marks.push({
         key: key,
         innerOffset: 0,
         pointerOffset: this.getOffset()
      });
   };

   // not return the pop result.
   public popMark = (struct) => {
      if (!this.allowStructAlign) {
         return;
      }
      if (this.randomAccessed) {
         Logger.trace("can't remove marker with random access");
         return;
      }
      const activedMarker = this.getActiveMarker(struct);

      if (activedMarker) {
         this.marks.pop();
      } else {
         Logger.error("can't pop active marker");
      }
   };

   public shiftOnCurrentMarker = (shift) => {
      if (!this.allowStructAlign) {
         return;
      }
      const activeMarker = this.getActiveMarker();
      if (activeMarker) {
         activeMarker.innerOffset += shift;
      } else {
         Logger.warning("current operation is not for struct, but allowStructAlign is true");
      }
   };

   public resetMarker = () => {
      if (!this.allowStructAlign) {
         return;
      }
      this.marks = [];
      this.randomAccessed = false;
   };
}
