/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { NgbToastService } from "../../common/service/ngb-toast.service";
import Logger from "../../../core/libs/logger";
import { clientUtil, TranslateService } from "@html-core";
import { EventBusService } from "../../../core/services/event";
import { usb, NotificationType } from "./usb.event";

export interface UsbNotificationMsg {
   type: NotificationType;
   deviceName: string;
}

@Injectable({
   providedIn: "root"
})
export class USBNotificationService {
   private logger = new Logger(Logger.USB);
   private notId: number = 0;
   constructor(
      private translate: TranslateService,
      private toastService: NgbToastService,
      private eventBusService: EventBusService
   ) {}

   public init = () => {
      this.eventBusService.listen(usb.NotificationMsg.MSG_TYPE).subscribe((msg: usb.NotificationMsg) => {
         const notifyMsg = {} as UsbNotificationMsg;
         notifyMsg.type = msg.notifyMsg;
         notifyMsg.deviceName = msg.productName;
         this.showNotification(notifyMsg);
      });
   };

   private showNotification = (msg: UsbNotificationMsg) => {
      const title = this.translate._T("USB_DEVICE_REDIRECTION");
      let contentMsg: string = "";
      if (msg.type === NotificationType.NOT_SUPPORT_MSG) {
         if (clientUtil.isChromeClient()) {
            contentMsg = this.translate._T("USB_REDIRECTION_CHROME_ERROR", msg.deviceName);
         } else {
            contentMsg = this.translate._T("USB_REDIRECTION_HTML5_ERROR", msg.deviceName);
         }
      } else if (msg.type === NotificationType.ALREADY_REDIRECT_MSG) {
         contentMsg = this.translate._T("USB_ALREADY_REDIRECTED", msg.deviceName);
      } else if (msg.type === NotificationType.BLOCK_BY_POLICY) {
         contentMsg = this.translate._T("USB_BLOCKED_BY_POLICY", msg.deviceName);
      }
      this.logger.info("showNotification " + contentMsg);
      if (clientUtil.isChromeClient()) {
         this._showChromeNotification(title, contentMsg);
      } else {
         this._showHTML5Notification(title, contentMsg);
      }
   };

   private _showChromeNotification = (title: string, content: string) => {
      if (this.isKioskMode()) {
         this._showHTML5Notification(title, content);
      } else {
         const options: any = {
            type: "basic",
            iconUrl: "../../usb_notification.png",
            title: title,
            message: content,
            expandedMessage: content
         };
         const notificationID = "Horizon#" + this.notId++;
         chrome.notifications.create(notificationID, options, () => {
            //close the notification after 3 seconds.
            setTimeout(() => {
               chrome.notifications.clear(notificationID, (wasCleared) => {
                  this.logger.info(name + ": USB redirection fails");
               });
            }, 3000);
         });
      }
   };

   private _showHTML5Notification = (title: string, content: string) => {
      this.toastService.clearEarlyToast(this.toastService.TOAST_TYPE.USB);
      //Confirm the copy to your local clipboard
      this.toastService.infoWithCallBack(content, this.toastService.TOAST_TYPE.USB, () => {});
   };

   private isKioskMode = (): boolean => {
      return clientUtil.isChromeClient() && window.isKioskSession;
   };
}
