/**
 * *****************************************************
 * Copyright 2020-2022 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

/**
 * async-clipboard.service.ts
 *
 * Used for HTML5 client only, access clipboard, see detail
 * in bug 2510866
 *
 * Since logic is very simple, skip adding UT for now.
 */
import Logger from "../../../core/libs/logger";
import { Injectable } from "@angular/core";
import { clientUtil } from "../../../core/libs/client-util";

//will replace this with real class later
type DataTransfer = any;
type ClipboardItem = any;

@Injectable({
   providedIn: "root"
})
export class asyncClipboard {
   public hadSessionReadyForClipboardSync: boolean;
   private enabled: boolean;
   private sendClipboardText;
   private sendClipboardHTML;

   constructor() {
      if (clientUtil.isChromeClient()) {
         return;
      }
      this.hadSessionReadyForClipboardSync = false;
      this.sendClipboardText = null;
      this.sendClipboardHTML = null;
      this.init();
   }

   /**
    * @param  {string} permissionStatus
    */
   private updateEnableStatus = (permissionStatus: string) => {
      Logger.info("async clipboard status update: " + permissionStatus, Logger.CLIPBOARD);
      const currentStatus: boolean = permissionStatus === "granted" || permissionStatus === "prompt";
      const previousStatus: boolean = this.enabled;

      if (currentStatus === previousStatus) {
         return;
      }

      this.enabled = currentStatus;
      if (currentStatus) {
         window.addEventListener("focus", this.onFocus);
      } else {
         window.removeEventListener("focus", this.onFocus);
      }
   };

   /**
    * @param  {FocusEvent} e
    */
   public onFocus = (e) => {
      Logger.info("grab to sync clipboard", Logger.CLIPBOARD);
      if (!this.canSendClipboardContent()) {
         Logger.error("inner error, no send function found for clipboard syncing", Logger.CLIPBOARD);
         return;
      }
      if (!this.hadSessionReadyForClipboardSync) {
         return;
      }
      setTimeout(() => {
         this.getClipboard()
            .then(this.sendClipboardContent)
            .catch((e) => {
               Logger.exception(e);
            });
      });
   };

   private init = () => {
      if (!navigator.permissions || !navigator.permissions.query || !navigator.clipboard) {
         this.enabled = false;
         return;
      }

      navigator.permissions
         .query({
            //@ts-ignore
            name: "clipboard-read"
         })
         .then((permission) => {
            this.updateEnableStatus(permission.state);
            permission.onchange = () => {
               this.updateEnableStatus(permission.state);
            };
         })
         .catch((e) => {
            this.updateEnableStatus("invalid");
            Logger.debug("request clipboard-read permission failure" + e, Logger.CLIPBOARD);
         });
   };

   /**
    * @param {string} text Plain text string
    * @return {Promise} Resolved with undefined
    */
   public setClipboardText = (text: string) => {
      return new Promise((resolve, reject) => {
         if (!text) {
            reject();
         }
         Logger.info("set text to clipboard: ", Logger.CLIPBOARD);
         navigator.clipboard.writeText(text).then(resolve).catch(reject);
      });
   };

   /**
    * @param {string} htmlText HTML Text string
    * @return {Promise} Resolved with undefined
    */
   public setClipboardHTML = (htmlText: string) => {
      return new Promise((resolve, reject) => {
         if (!htmlText) {
            reject();
         }
         try {
            const clipboardHTML = htmlText.slice(htmlText.indexOf("<html>"));
            // filter out all the formats
            const clipboardPlain = $(clipboardHTML).text().trim();

            const dataHTML = new Blob([clipboardHTML], {
               type: "text/html"
            });

            // used when pasted to a app that is not support this format
            const dataPlain = new Blob([clipboardPlain], {
               type: "text/plain"
            });

            //@ts-ignore
            const item = new ClipboardItem({
               "text/html": dataHTML,
               "text/plain": dataPlain
            });
            Logger.info("try to set html to clipboard", Logger.CLIPBOARD);
            // currently chrome would always fail, but keep code here for zero day support of new version of Chrome.
            //@ts-ignore
            navigator.clipboard.write([item]).then(resolve).catch(reject);
         } catch (e) {
            Logger.warning("unexpected error when try to set client clipboard silently", Logger.CLIPBOARD);
            reject();
         }
      });
   };

   /**
    * below function would replace the toaster if success.
    * work only for text for now, extend to image and other format later
    * @param  {Object} data {text:string, html:string}
    * @return {Promise} Resolved with undefined
    */
   public setClipboard = async (data: { text: string; html: string }) => {
      Logger.info("set data to local data", Logger.CLIPBOARD);
      if (!data) {
         throw "missing data to set into client clipboard";
      }

      if (!data.text && !data.html) {
         throw "missing acceptable type to set client clipboard";
      }
      //@ts-ignore
      if (!this.enabled || !navigator.clipboard || !navigator.clipboard.write) {
         throw "missing API support, need to fallback to toaster";
      }
      /**
       * set rich text if exist, otherwise try set plain text to avoid set 2 contents.
       * Currently, all browser would fail to set client html content.
       */
      if (data.html) {
         try {
            await this.setClipboardHTML(data.html);
         } catch (e) {
            throw "failed to set html, fallback for toaster for set html to client";
         }
      } else if (data.text) {
         await this.setClipboardText(data.text);
      } else {
         throw "missing text and html when try to set client clipboard";
      }
   };

   /**
    * below is for panel free clipboard logic.
    * @return {Promise} Resolved with [ClipboardItem]
    */
   public getClipboard = async (): Promise<DataTransfer> => {
      //@ts-ignore
      if (!this.enabled || !navigator.clipboard.read) {
         throw "API check failure for clipboard";
      }
      //@ts-ignore
      const data: DataTransfer = await navigator.clipboard.read();
      if (!data || !(data.length > 0)) {
         throw "API disabled";
      }
      return data;
   };

   /**
    * @return {boolean}
    */
   private canSendClipboardContent = (): boolean => {
      return this.sendClipboardText && this.sendClipboardHTML;
   };

   /**
    * @param  {Array} clipboards [ClipboardItems]
    * @param  {string} targetType
    * @return {string}            This currently only able to return string of either plain text or rich text.
    */
   private getClipboardValue = (clipboards: Array<ClipboardItem>, targetType: string) => {
      return new Promise((resolve, reject) => {
         const findMatch = clipboards.some((clipboardItem) => {
            if (!clipboardItem.types) {
               return false;
            }
            return clipboardItem.types.some((type) => {
               if (type === targetType) {
                  try {
                     clipboardItem.getType(type).then(resolve).catch(reject);
                     return true;
                  } catch (e) {
                     Logger.info("skip syncing type " + type + " in client clipboard", Logger.CLIPBOARD);
                     return false;
                  }
               }
               return false;
            });
         });
         if (!findMatch) {
            reject("no content of type " + targetType + " found in the client clipboard");
         }
      });
   };

   /**
    * convert async blob to string, used for plain text and html text
    * @param  {Object} blob
    * @return {string}
    */
   private blobToString = async (blob) => {
      if (!blob) {
         throw "invalid blob";
      }
      if (!(blob.size > 0)) {
         throw "empty blob found";
      }
      const buffer = await blob.arrayBuffer();
      const dataArray = new Uint8Array(buffer);
      //@ts-ignore
      return dataArray.reduce((a, b) => {
         const str_a = typeof a === "number" ? String.fromCharCode(a) : a;
         return str_a + String.fromCharCode(b);
      });
   };

   /**
    * @param  {Array} clipboards [ClipboardItem]
    */
   private sendClipboardContent = async (clipboards: Array<ClipboardItem>) => {
      if (!this.canSendClipboardContent()) {
         return;
      }
      if (!clipboards) {
         return;
      }
      let htmlText, plainText;
      try {
         const htmlTextBlob = await this.getClipboardValue(clipboards, "text/html");
         htmlText = await this.blobToString(htmlTextBlob);
      } catch (e) {
         Logger.debug(e);
      }
      try {
         const plainTextBlob = await this.getClipboardValue(clipboards, "text/plain");
         //@ts-ignore
         plainText = await new Response(plainTextBlob).text();
      } catch (e) {
         Logger.debug(e);
      }
      if (htmlText) {
         Logger.debug("send client html text content on grab", Logger.CLIPBOARD);
         this.sendClipboardHTML(htmlText);
      }
      if (plainText) {
         Logger.debug("send client plain text content on grab", Logger.CLIPBOARD);
         this.sendClipboardText(plainText);
      }

      if (!htmlText && !plainText) {
         Logger.debug("no valid clipboard content read", Logger.CLIPBOARD);
      }
   };

   /**
    * @param  {function} sendClipboardText
    * @param  {function} sendClipboardHTML
    */
   public setSyncFunction = (sendClipboardText, sendClipboardHTML) => {
      this.sendClipboardText = sendClipboardText;
      this.sendClipboardHTML = sendClipboardHTML;
   };

   /**
    * Called to ensure we only read client clipboard when any session
    * could accept it, disconnect the session would not stop the client from
    * monitoring the clipboard, but nothing would be sync to remote if not allowed.
    * @param  {string} id             Session ID
    * @param  {boolean} copyEnabled
    * @param  {boolean} pasteEnabled
    * @param  {boolean} clipboardReady
    */
   public onSessionCapUpdate = (id: string, copyEnabled: boolean, pasteEnabled: boolean, clipboardReady: boolean) => {
      if (pasteEnabled && clipboardReady) {
         if (this.hadSessionReadyForClipboardSync) {
            Logger.debug(
               "session " +
                  id +
                  " enabled client to agent clipboard sync, and client is already monitoring client clipboard",
               Logger.CLIPBOARD
            );
         } else {
            Logger.info(
               "session " +
                  id +
                  " enabled client to agent clipboard sync, client would start to monitor client clipboard",
               Logger.CLIPBOARD
            );
            this.hadSessionReadyForClipboardSync = true;
         }
      } else {
         Logger.debug("session " + id + " not ready for client to agent clipboard sync", Logger.CLIPBOARD);
      }
   };
}
