<div class="modal-title dialog-title" *ngIf="currentModal.type !== modalType.ADD_NEW_KEY_MAPPING_MODAL.type">
   {{ "SETTINGS" | i18nv2 }}</div>
<div class="modal-title dialog-title" *ngIf="currentModal.type === modalType.ADD_NEW_KEY_MAPPING_MODAL.type">{{
   "KEY_MAPPING" | i18nv2 }}</div>
<div class="keyboard-setting" *ngIf="currentModal.type === modalType.PRIMARY_MODAL.type" fuzzy-free>
   <div class="modal-body dialog-content keyboard-setting-content">
      <ul>
         <li class="keyboard-setting-line">
            <div class="settings-container">
               <button type="button" class="modal-button-base modal-button-blue modal-button-set"
                  title="{{'KEYBOARD_SETTING_SET' | i18nv2}}" id="keyboardLayoutSettingBtn"
                  (click)="openLanguageSetting()">{{'KEYBOARD_SETTING_SET' | i18nv2}}</button>
               <span class="settings-label">{{'LANGUAGE_SETTING_T' | i18nv2}}</span>
               <span class="settings-text">
                  <span>{{currentLanguage | i18nv2}} - {{currentLayout | i18nv2}}</span>
                  <span class="circle-check-icon"></span>
               </span>
            </div>
         </li>
         <div class="keyboard-setting-separator settings-separator"></div>
         <li class="keyboard-setting-line">
            <div class="settings-container">
               <button type="button" class="modal-button-base modal-button-blue modal-button-set"
                  title="{{'KEYBOARD_SETTING_SET' | i18nv2}}" id="keyMappingSettingBtn"
                  (click)="openKeyMapping()">{{'KEYBOARD_SETTING_SET'
                  | i18nv2}}</button>
               <span class="settings-label">{{'KEY_MAPPING_SETTING_T' | i18nv2}}</span>
               <span class="settings-text">{{'KEY_MAPPING_SETTING_M' | i18nv2}}</span>
            </div>
         </li>
      </ul>
   </div>
</div>
<div class="language-setting" *ngIf="currentModal.type === modalType.KEYBOARD_LAYOUT_MODAL.type" fuzzy-free>
   <div class="sub-dialog-tittle">
      <span class="settings-label">{{ "LANGUAGE_SELECT"| i18nv2 }}
         <input class="keyboard-layout-search" type="text" placeholder="{{'Search' | i18nv2}}"
            [(ngModel)]="searchContent"></span>
   </div>
   <div class="keyboard-setting-separator settings-separator"></div>
   <div class="modal-body dialog-content keyboard-setting-content">
      <div class="keyboard-layout-content">
         <ul class="keyboard-layout-line-out" *ngFor="let language of languages">
            <li class="keyboard-layout-line-inner"
               *ngFor="let layout of (keyboardLayouts[language].layout | languageLayoutSearch:language:searchContent)"
               (click)="currentLanguage = language;currentLayout=layout">
               <div class="keyboard-layout-line-transform">
                  <div class="keyboard-layout-radio-icon selected"
                     *ngIf="currentLanguage === language&&currentLayout===layout"></div>
                  <div class="keyboard-layout-radio-icon unselected"
                     *ngIf="currentLanguage !== language||currentLayout!==layout">
                  </div>
                  <div class="keyboard-layout-language">
                     <span class="language-text">{{language | i18nv2}}</span>
                     <span class="keyboard-layout-dash">-</span>
                     <span class="layout-text">{{layout | i18nv2}}</span>
                  </div>
               </div>
            </li>
         </ul>
      </div>
   </div>
</div>

<div *ngIf="currentModal.type === modalType.KEY_MAPPING_MODAL.type" class="keyboard-setting-mapping"
   [ngClass]="isShowLanguageSpecificKeyMappings()?'enable-language-specific':'disable-language-specific'" fuzzy-free>
   <div class="sub-dialog-tittle">
      <span class="settings-label">{{ "KEY_MAPPING"| i18nv2 }}</span>
   </div>
   <div class="modal-body dialog-content keyboard-setting-content key-mapping-primary">
      <div class="key-mapping-title">
         <div class="title"> {{ 'OPEN' | i18nv2 }}</div>
         <div class="title"> {{ 'ORIGIN_SHORTCUT' | i18nv2 }}</div>
         <div class="title"> {{ 'MAPPING_SHORTCUT' | i18nv2 }}</div>
      </div>
      <div class="key-mapping-content">
         <ul class="key-mapping-list">
            <li class="key-mapping-row" *ngFor="let keyMapping of tempKeyMappingSetting;index as index"
               [hidden]="keyMapping.platform !== currentPlatform" [ngClass]="{isChosen: currentKeyMapping.originShortcut === keyMapping.originShortcut && currentKeyMapping.mappingShortcut === keyMapping.mappingShortcut,
                   isDefault: keyMapping.isDefault}" (click)="currentKeyMapping = keyMapping">
               <div class="key-mapping-checkbox" (click)="$event.stopPropagation()">
                  <input type="checkbox" id="checkboxForDialog" [checked]="keyMapping.isOn"
                     (change)="toggleKeyMapping(keyMapping)">
               </div>
               <div class="key-mapping-shortcut">{{keyMapping.fromShotcut}}
               </div>
               <div class="key-mapping-shortcut">{{keyMapping.toShortcut}}</div>
               <button type="button" id="editKeyMappingSetting" *ngIf="!keyMapping.isDefault" class="key-mapping-edit"
                  [attr.aria-describedby]="'EDIT_KEY_MAPPING_SETTING' | i18nv2"
                  (click)="editKeyMapping(keyMapping)">{{"EDIT" | i18nv2}}</button>
               <div class="duplicate-warn" [ngClass]="keyMapping.isDefault?'default':''"
                  [hidden]="!duplicateIndex.includes(index) || !keyMapping.isOn">
               </div>
            </li>
         </ul>
      </div>
      <div class="key-mapping-operation-row">
         <div class="key-mapping-button-column">
            <button type="button" class="button-minus-plus button-plus" id="addKeyMapping" (click)="openSubModal()"
               [attr.aria-describedby]="'ADD_KEY_MAPPING' | i18nv2"></button>
            <button type="button" class="button-minus-plus button-minus" id="deleteKeyMapping"
               (click)="deleteKeyMapping(currentKeyMapping)" [disabled]="currentKeyMapping.isDefault"
               [attr.aria-describedby]="'DELETE_KEY_MAPPING' | i18nv2"></button>
         </div>
         <div class="key-mapping-warning-column" [hidden]="duplicateIndex.length===0">
            <span class="duplicate-warning-message-icon"></span>
            <span class="duplicate-warning-message-text"> {{"DUPLICATE_WARN" | i18nv2}}</span>
         </div>
      </div>
      <div class="key-mapping-specific-language" (click)="$event.stopPropagation()">
         <input class="key-mapping-language-checkbox" type="checkbox" id="checkboxForLanguage"
            [checked]="isSpecificLanguageEnabled" (change)="updateIsSpecificLanguageEnabled()">
         <label class="key-mapping-language-label" for="checkboxForLanguage">
            <span>{{"ENABLE_LANGUAGE_SPECIFIC" | i18nv2}}</span>
            <span class="key-mapping-current-language">{{currentLayout | i18nv2}}</span></label>
      </div>
      <div class="key-mapping-specific" *ngIf="isShowLanguageSpecificKeyMappings()">
         <li class="key-mapping-row"
            *ngFor="let keyMapping of languageSpecificKeyMappingForCurrentPlatform;index as index">
            <div class="key-mapping-checkbox" (click)="$event.stopPropagation()">
               <input type="checkbox" id="checkboxForDialog" [checked]="true" [disabled]="true">
            </div>
            <div class="key-mapping-shortcut">{{keyMapping.fromShotcut}}
            </div>
            <div class="key-mapping-shortcut">{{keyMapping.toShortcut}}</div>
            <div class="duplicate-warn specific"
               [hidden]="!duplicateIndex.includes(index+tempKeyMappingSetting.length)">
            </div>
         </li>
      </div>
   </div>
</div>
<div *ngIf="currentModal.type === modalType.KEY_MAPPING_MODAL.type"
   class="modal-footer dialog-button-row key-mapping-primary-footer">
   <button type="button" id="resetKeyMappings" class="modal-button-base modal-button-right modal-button-grey"
      [attr.aria-describedby]="'RESTORE_DEFAULT' | i18nv2" (click)="restoreToDefault()">
      {{ 'RESTORE_DEFAULT' | i18nv2 }}
   </button>
   <button type="button" id="cancelKeyMappingOperation" class="modal-button-base modal-button-right modal-button-grey"
      [attr.aria-describedby]="'CANCEL_KEY_MAPPING_OPERTION' | i18nv2" (click)="cancelKeyMappingOperation()">
      {{ 'CANCEL' | i18nv2 }}
   </button>
   <button type="button" id="updateKeyboardMapping" class="modal-button-base modal-button-right modal-button-blue"
      [attr.aria-describedby]="'UPDATE_KEY_MAPPING' | i18nv2"
      (click)="currentModal = modalType.PRIMARY_MODAL;updateKeyMappingSetting()"
      [disabled]="duplicateKeyMapping.length !== 0">
      {{ 'SAVE' | i18nv2 }}
   </button>
</div>
<div *ngIf="currentModal.type === modalType.ADD_NEW_KEY_MAPPING_MODAL.type"
   class="modal-body dialog-content keyboard-setting-content" fuzzy-free>
   <div class="key-mapping-sub">
      <div class="key-mapping-originShortcut">
         <div class="key-mapping-fromTo">{{ 'FROM' | i18nv2 }}: {{fromShotcut}}</div>
         <ul class="origin-Modifier-Keys">
            <li class="origin-Modifier-Key" *ngFor="let originModifierKey of originModifierKeys">
               <button [ngClass]="'origin_'+ originModifierKey"
                  (click)="chooseOriginModifierKeys($event, true)">{{originModifierKey}}</button>
               <div class="vertical-line"></div>
            </li>
         </ul>
         <div class="origin-NonModifier-Key">
            <input class="origin-NonModifier-Key-input" type="text" placeholder="{{'ENTER_A_KEY' | i18nv2}}"
               [(ngModel)]="formatOriginNonModifierKey" (keydown)="enterNonModifierKey($event)" />
         </div>
      </div>
      <div class="key-mapping-mappingShortcut">
         <div class="key-mapping-fromTo">{{ 'TO' | i18nv2 }}: {{toShortcut}}</div>
         <div class="mapping-Modifier-Keys">
            <li class="mapping-Modifier-Key" *ngFor="let mappingModifierKey of mappingModifierKeys">
               <button [ngClass]="'mapping_'+ mappingModifierKey"
                  (click)="chooseOriginModifierKeys($event, false)">{{mappingModifierKey}}</button>
               <div class="vertical-line"></div>
            </li>
         </div>
         <div class="mapping-NonModifie-Key">
            <input type="text" class="mapping-NonModifier-Key-input" placeholder="{{'ENTER_A_KEY' | i18nv2}}"
               [(ngModel)]="formatMappingNonModifierKey" (keydown)="enterNonModifierKey($event)" />
         </div>
      </div>
      <div class="warning-message" *ngIf="warningMessage">
         <span class="warning-message-icon"></span>
         <span class="warning-message-text">{{warningMessage | i18nv2}}</span>
      </div>
   </div>
</div>
<div *ngIf="currentModal.type === modalType.PRIMARY_MODAL.type" class="modal-footer dialog-button-row">
   <button type="button" class="modal-button-base modal-button-right modal-button-blue"
      [attr.aria-describedby]="'CLOSE_KEY_MAPPING' | i18nv2" (click)="cancel()">
      {{ 'CLOSE' | i18nv2 }}
   </button>
</div>
<div *ngIf="currentModal.type === modalType.ADD_NEW_KEY_MAPPING_MODAL.type"
   class="modal-footer dialog-button-row key-mapping-sub-footer">
   <button type="button" id="updateKeyboardMapping" class="modal-button-base modal-button-right modal-button-grey"
      [attr.aria-describedby]="'CANCEL_ADD_KEY_MAPPING' | i18nv2" (click)="closeSubModal()">
      {{ 'CANCEL' | i18nv2 }}
   </button>
   <button type="button" id="updateKeyboardMapping" class="modal-button-base modal-button-right modal-button-blue"
      [attr.aria-describedby]="'CLEAR' | i18nv2" (click)="clear()">
      {{ 'CLEAR' | i18nv2 }}
   </button>
   <button *ngIf="!isEdited" type="button" id="updateKeyboardMapping"
      class="modal-button-base modal-button-right modal-button-blue"
      [attr.aria-describedby]="'ADD_NEW_KEY_MAPPING' | i18nv2" [disabled]="!isValidKeyMapping"
      (click)="addKeyMapping()">
      {{ 'ADD' | i18nv2 }}
   </button>
   <button *ngIf="isEdited" type="button" id="updateKeyboardMapping"
      class="modal-button-base modal-button-right modal-button-blue"
      [attr.aria-describedby]="'UPDATE_CURRENT_KEY_MAPPING' | i18nv2" [disabled]="!isValidKeyMapping"
      (click)="updateCurrentKeyMapping()">
      {{ 'UPDATE' | i18nv2 }}
   </button>
</div>
<div *ngIf="currentModal.type === modalType.KEYBOARD_LAYOUT_MODAL.type"
   class="modal-footer dialog-button-row key-mapping-primary-footer">
   <button *ngIf="!isPriKeyboardLayoutModal" type="button" id="confirmLanguage"
      class="modal-button-base modal-button-right modal-button-blue"
      [attr.aria-describedby]="'CANCEL_CHANGE_LAYOUT' | i18nv2" (click)="isPriKeyboardLayoutModal = true">
      {{ 'CANCEL' | i18nv2 }}
   </button>
   <button *ngIf="isPriKeyboardLayoutModal" type="button" id="confirmLanguage"
      class="modal-button-base modal-button-right modal-button-grey"
      [attr.aria-describedby]="'CLOSE_LANGUAGE_SETTING' | i18nv2" (click)="cancelUpdateLanguage()">
      {{ 'CANCEL' | i18nv2 }}
   </button>
   <button type="button" id="confirmLanguage" class="modal-button-base modal-button-right modal-button-blue"
      [attr.aria-describedby]="'CONFIRM_LANGUAGE_SETTING' | i18nv2" (click)="updateKeyboardLayout()"
      *ngIf="isPriKeyboardLayoutModal">
      {{ 'OK' | i18nv2 }}
   </button>
</div>