/**
 * ******************************************************
 * Copyright (C) 2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class OnRampService {
   public abstract getOnRampConfig();
}
