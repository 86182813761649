/**
 * ******************************************************
 * Copyright (C) 2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

export abstract class TranslateService {
   public abstract _T(...args: any[]);
}
