<form class="ui-widget-home" id="securIDPasscodeForm" name="securIDPasscodeForm" [formGroup]="securIDPasscodeForm" (keydown)="onKeypress($event)">
   <div class="ui-panel-content securid-passcode">
      <div [hidden]="blankOutContent">
         <div *ngIf="!error && (authType !== 'RADIUS')" class="ui-login-hint-text">
            <span class="ui-icon-rsa"></span>
            {{'dialog_prompt_passcode' | i18nv2:'RSA SecurID'}}
         </div>
         <div *ngIf="authType === 'RADIUS'" class="ui-login-hint-text-radius">{{titleString}}</div>
         <div *ngIf="error" class="alert alert-danger">
            {{error}}
         </div>
         <div class="file-association-warning-server" [hidden]="faServer===''">{{'file_association_server_notification' | i18nv2 : faServer}}</div>
         <div class="form-horizontal">
            <label for="securIDUsername">{{usernamePlaceholder}}</label>
            <input type="text" id="securIDUsername" name="username" type="text" class="form-control"
               placeholder="{{usernamePlaceholder}}" [readonly]="usernameReadOnly"
               [attr.disabled]="usernameReadOnly ? '' : null" [formControl]="securIDUsernameControl"
               [autoFocus]>
            <button *ngIf="securIDUsernameControl.value && !usernameReadOnly"
               class="ui-clear-btn ui-clear-securUsername-btn" id="clearSecuridUsernameBtn" type=button
               (click)="clearSecuridUsername()" tabindex="-1">{{'clear_username_btn' | i18nv2}}</button>

            <label for="securIDPasscode">{{passcodePlaceholder}}</label>
            <input id="securIDPasscode" name="passcode" type="text" (focus)="changeType('securIDPasscode')" class="form-control"
               [ngClass]="{'ui-input-group-textfield-addon': authType !== 'RADIUS'}"
               placeholder="{{passcodePlaceholder}}" [formControl]="securIDPasscodeControl">
            <button *ngIf="securIDPasscodeControl.value" class="ui-clear-btn ui-clear-passcode-btn"
               id="clearPasscodeBtn" type=button (click)="clearSecuridPasscode()"
               tabindex="-1">{{'clear_password_btn' | i18nv2}}</button>
         </div>
      </div>
      <loading-area [blankOutContent]="blankOutContent" [cancelLoading]="cancelLoading"></loading-area>
   </div>
   <div class="ui-panel-footer">
      <!--TODO-NG: fix submit later-->
      <button id="securLoginButton" class="btn btn-primary" [ngClass]="{'btnPrimaryWaiting':blankOutContent}"
         (click)="onSubmit()" [hidden]="blankOutContent"
         [disabled]="!securIDPasscodeForm.valid">{{ 'dialog_btn_signin' | i18nv2}}</button>
      <button id="securCancelLoginBtn" class="btn btn-default" [ngClass]="{'btnDefaultWaiting':blankOutContent}"
         (click)="cancel()">{{'CANCEL' | i18nv2}}</button>
   </div>
</form>