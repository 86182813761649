/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../core/libs/logger";
import { FullscreenService } from "./fullscreen-service";
import { FullScreenHelper } from "./full-screen-helper";
import { KeyboardLockService } from "./keyboard-lock-service";
import { Injectable } from "@angular/core";

@Injectable()
export class FullscreenHTML5Service extends FullscreenService {
   constructor(private keyboardLockService: KeyboardLockService) {
      super();
      Logger.debug("HTML5 Fullscreen service loaded");
   }

   public enterFullscreen = (elem?: any): void => {
      if (FullScreenHelper.isFullscreen()) {
         return;
      }
      // add F11 for bug: https://jira-euc.eng.vmware.com/jira/browse/VCART-1444
      this.keyboardLockService.overrideESCAndF11();
      if (elem.requestFullscreen) {
         elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
         elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
         elem.webkitRequestFullscreen(elem.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
         elem.msRequestFullscreen();
      } else {
         Logger.error("FullscreenHTML5Service: fail to enter fullscreen");
      }
   };

   public exitFullscreen = (): void => {
      if (FullScreenHelper.isFullscreen()) {
         if (window.document.exitFullscreen) {
            window.document.exitFullscreen();
            // @ts-ignore
         } else if (window.document.msExitFullscreen) {
            // @ts-ignore
            window.document.msExitFullscreen();
            // @ts-ignore
         } else if (window.document.mozCancelFullScreen) {
            // @ts-ignore
            window.document.mozCancelFullScreen();
            // @ts-ignore
         } else if (window.document.webkitExitFullscreen) {
            // @ts-ignore
            window.document.webkitExitFullscreen();
         } else {
            Logger.error("FullscreenHTML5Service: fail to quit fullscreen");
         }
      }
   };

   protected onEnterFullscreen = (): void => {
      this.fullScreen$.next(true);
   };

   protected onExitFullscreen = (): void => {
      this.keyboardLockService.releaseESCAndF11();
      this.fullScreen$.next(false);
   };

   public bindFullScreen = (): void => {
      this.unbindFullScreen();
      $(document).on(
         "webkitfullscreenchange mozfullscreenchange " + "fullscreenchange MSFullscreenChange",
         this.onFullscreenChanged
      );
   };

   public unbindFullScreen = (): void => {
      $(document).off(
         "webkitfullscreenchange mozfullscreenchange " + "fullscreenchange MSFullscreenChange",
         this.onFullscreenChanged
      );
   };

   public bindListenersForChromeBook = (enterFunc: any, exitFunc: any): void => {};
}
