/**
 * ******************************************************
 * Copyright (C) 2018-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Remote session manager is used for both launcher and desktop module.
 * And htmlRemoteSessionManager is the extended one from
 * baseRemoteSessionManager.
 *
 */
import { Injectable } from "@angular/core";
import {
   SessionMsg,
   RemoteSessionEventService
} from "../../../shared/common/remote-session/remote-session-event.service";
import { CommonRemoteSessionManager } from "../../../shared/common/remote-session/base-remote-session-manager";
import { BlastWmks } from "../../../shared/desktop/common/blast-wmks.service";
import { SessionDataService } from "../../../shared/common/service/session-data.service";
import { EventBusService } from "../../../core/services/event";

@Injectable({
   providedIn: "root"
})
export class HtmlRemoteSessionManager extends CommonRemoteSessionManager {
   constructor(
      private remoteSessionEventService: RemoteSessionEventService,
      private sessionDataService: SessionDataService,
      private eventBusService: EventBusService
   ) {
      super(sessionDataService);
   }
   public onAddSession = (id: string, inst: BlastWmks) => {
      if (!inst.isApplicationSession) {
         this.remoteSessionEventService.emit(SessionMsg.SESSION_ADDED_MSG, inst);
      }

      this.eventBusService.dispatch({
         type: "newProtocolSessionCreated",
         data: {
            remoteSession: {
               name: inst.name,
               id: inst.logId,
               sessionId: inst.key,
               type: inst.isApplicationSession ? "App" : "Desktop",
               protocol: "Blast"
            }
         }
      });
   };

   public OnRemoveSession = (id: string, session: BlastWmks) => {
      this.remoteSessionEventService.emit(SessionMsg.SESSION_REMOVED_MSG, session);
      if (this._currentSession && id === this._currentSession.key) {
         this._currentSession = null;
         if (!(session.isApplicationSession && session.isEmpty)) {
            if (this._sessionMap.size !== 0) {
               for (const key of this._sessionMap.keys()) {
                  this.activeSession(key);
               }
            } else {
               this.OnActiveSession(null, false);
            }
         }
      }

      this.eventBusService.dispatch({
         type: "protocolSessionDisconnected",
         data: {
            remoteSession: {
               name: session.name,
               id: session.logId,
               sessionId: session.key,
               type: session.isApplicationSession ? "App" : "Desktop",
               protocol: "Blast"
            },
            connectionFailed: false,
            errorMessage: (session.isApplicationSession ? "App" : "Desktop") + " disconnected."
         }
      });
   };

   public OnActiveSession = (session: BlastWmks, isNewSession: boolean) => {
      this.remoteSessionEventService.emit(SessionMsg.SESSION_CHANGED_MSG, session, isNewSession);
      this.updateRunningSessions(false);
   };

   public updateRunningSessions = (skip?: boolean) => {
      //let tempArray = this.getSessionTempArray();
      const info = this.getRemoteSessionInfo();
      this.remoteSessionEventService.emit("sessionPropertyUpdate", skip, info);
   };
}
