/**
 * ******************************************************
 * Copyright (C) 2018 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * Text-Parser.service.ts
 *
 * Utility functions for converting between normal html fragment to CF_Html according to the
 * Microsoft document:
 * https://msdn.microsoft.com/en-us/library/windows/desktop/ms649015(v=vs.85).aspx
 **/

import Logger from "../../../core/libs/logger";
import { StringUtils } from "@html-core";

const TextParser = {
   TAG: "TextParser",
   PADDING_WIDTH: 10,

   /**
    * htmlToCFHtml
    * Convert normal html fragment to CF_HTML
    *
    * @Param html
    * @return CF_HTML
    */
   htmlToCFHtml: function (html) {
      if (html === null || html === "") {
         Logger.debug("The parameter html is null.");
         return null;
      }

      // Append the <HTML> tag and <BODY> tag if they are missed.
      let htmlPrefix = "";
      let htmlSuffix = "";
      const htmlUpperCase = html.toUpperCase();
      if (!htmlUpperCase.includes("<HTML")) {
         htmlPrefix = "<HTML>";
         htmlSuffix = "</HTML>";

         if (!htmlUpperCase.includes("<BODY")) {
            htmlPrefix = htmlPrefix + "<BODY>";
            htmlSuffix = "</BODY>" + htmlSuffix;
         }
      }

      // Build the description
      const startHTML =
         "Version:1.0\r\n" +
         "StartHTML:          \r\n" +
         "EndHTML:          \r\n" +
         "StartFragment:          \r\n" +
         "EndFragment:          \r\n" +
         "SourceURL:about:blank\r\n";
      const nStartHtml = StringUtils.stringToUint8Array(startHTML).length;
      const nStartFragment =
         nStartHtml +
         StringUtils.stringToUint8Array(htmlPrefix).length +
         StringUtils.stringToUint8Array("<!--StartFragment-->").length;
      const nEndFragment = nStartFragment + StringUtils.stringToUint8Array(html).length;
      const nEndHtml =
         nEndFragment +
         StringUtils.stringToUint8Array("<!--EndFragment-->").length +
         StringUtils.stringToUint8Array(htmlSuffix).length;

      const description =
         "Version:1.0\r\n" +
         "StartHTML:" +
         TextParser.toPaddedString(nStartHtml) +
         "\r\n" +
         "EndHTML:" +
         TextParser.toPaddedString(nEndHtml) +
         "\r\n" +
         "StartFragment:" +
         TextParser.toPaddedString(nStartFragment) +
         "\r\n" +
         "EndFragment:" +
         TextParser.toPaddedString(nEndFragment) +
         "\r\n" +
         "SourceURL:about:blank\r\n";

      /**
       * Build the HTML body
       * <HTML><BODY><!--StartFragment-->...<!--EndFragment></BODY></HTML>
       */
      const cfHtml = description + htmlPrefix + "<!--StartFragment-->" + html + "<!--EndFragment-->" + htmlSuffix;

      return cfHtml;
   },

   /**
    * CFHtmlToHtml
    * Convert CF_HTML to normal html fragment
    *
    * @Param cfHtml
    * @return html
    */
   CFHtmlToHtml: function (cfHtml) {
      if (cfHtml === null || cfHtml === "") {
         return null;
      }

      let html;

      // Remove the description and the fragment tag
      const cuspBracketIndex = cfHtml.indexOf("<");
      if (cuspBracketIndex !== -1) {
         html = cfHtml.substring(cuspBracketIndex);
         html.replace("<!--StartFragment-->", "");
         html.replace("<!--EndFragment-->", "");
      }

      return html;
   },

   toPaddedString: function (number) {
      if (number < 0) {
         Logger.error(TextParser.TAG + "Number is illegal.");
         number = 0;
      }

      let numberStr = "" + number;
      if (numberStr.length < TextParser.PADDING_WIDTH) {
         let str = "";
         while (str.length < TextParser.PADDING_WIDTH - numberStr.length) {
            str = "0" + str;
         }

         numberStr = str + numberStr;
      }

      return numberStr;
   }
};

export default TextParser;
