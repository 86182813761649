/**
 * ******************************************************
 * Copyright (C) 2021-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { CoordinateConverter } from "../../../../shared/utils/coordinate-converter";
import { EventBusService } from "@html-core";
import { MultimonModel } from "../../../../shared/desktop/multimon/main-page/multimon-model";
import { OverlayCtlService } from "../app-border/overlay-control.service";
import { Logger } from "../../../../core/libs";
import { Injector, Injectable } from "@angular/core";
import { SingleMonitor } from "../app-border/monitor/SingleMonitor";
import { ExtendScreen } from "../app-border/monitor/MultiMonitor/ExtendScreen";
import { PrimaryScreen } from "../app-border/monitor/MultiMonitor/PrimaryScreen";
import { UnityWindowTrackerService } from "../app-window/unity-window-tracker.service";
@Injectable()
export class RemoteappBorderService {
   private _canvas = null;
   public isPrimaryScreen = true;
   public _isMultimon = false;
   private _proxy = null;
   private _singleMonProxy = null;
   public overlayCtlService: OverlayCtlService = null;
   public unityWindowTrackerService: UnityWindowTrackerService = null;
   public topWindow = null;
   constructor(
      public multimonModel: MultimonModel,
      private _injector: Injector
   ) {
      this.isPrimaryScreen = window.location.href.indexOf("app-window.html") >= 0;
      if (this.isPrimaryScreen) {
         this._proxy = new SingleMonitor(this);
      } else {
         this._isMultimon = true;
         this._proxy = new ExtendScreen(
            this,
            this._injector.get(CoordinateConverter),
            this._injector.get(EventBusService)
         );
      }
   }

   public enterMultimonMode = () => {
      this._isMultimon = true;
      // save for return back to singlemon mode
      this._singleMonProxy = this._proxy;
      this._proxy = new PrimaryScreen(this, this._injector.get(CoordinateConverter));
   };

   public enterSinglemonMode = () => {
      this._isMultimon = false;
      this._proxy = this._singleMonProxy;
      this._canvas = null;
   };

   public setOverlayCtlService = (overlayCtlService: OverlayCtlService) => {
      this.overlayCtlService = overlayCtlService;
   };

   public setVisibleBorder = (drawingRect) => {
      this._proxy.setVisibleBorder(drawingRect);
   };

   public moveWindow = (drawingRect) => {
      this._proxy.moveWindow(drawingRect);
   };

   public getDetectionTarget = () => {
      return this._proxy.getDetectionTarget();
   };

   public setTopWindow = (topWindow) => {
      this.topWindow = topWindow;
   };

   public setTopWindowById = (windowId) => {
      const windows = this.unityWindowTrackerService.getWindows();
      this.topWindow = windows[windowId];
   };

   public updateDrawingRect = (drawingRect) => {
      this._proxy.updateDrawingRect(drawingRect);
   };

   public hideVisibleShape = () => {
      this._proxy.hideVisibleShape();
   };

   public normalizeRect = (rect) => {
      return this._proxy.normalizeRect(rect);
   };

   public normalizeBorderRect = (rect) => {
      return this._proxy.normalizeBorderRect(rect);
   };

   public toLocalCoordinate = (rect) => {
      return this._proxy.toLocalCoordinate(rect);
   };

   public moveWindowDirect = (drawingRect) => {
      const reshapedAppWindowRect = {
         left: drawingRect.left,
         top: drawingRect.top,
         width: drawingRect.right - drawingRect.left,
         height: drawingRect.bottom - drawingRect.top
      };
      Logger.info("resize done, reshape app window: " + JSON.stringify(reshapedAppWindowRect), Logger.UNITY_BORDER);
      this.topWindow.moveWindow(reshapedAppWindowRect);
   };

   public setUnityWindowTrackerService = (service) => {
      this.unityWindowTrackerService = service;
   };

   public setCursor = (cursor) => {
      if (null === this._canvas) {
         const canvas = document.getElementById(this._proxy.canvasId);
         this.setCanvas(canvas);
      }
      if (this._canvas && this._canvas.style.cursor != cursor) {
         this._canvas.style.cursor = cursor;
      }
   };

   public setCanvas(value) {
      this._canvas = value;
   }
}
