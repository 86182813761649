/**
 * ******************************************************
 * Copyright (C) 2017-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { FeatureConfigs } from "../../../shared/common/model/feature-configs";
import Logger from "../../../core/libs/logger";

/**
 * Policy service for Google admin policy push
 *
 * This is a singleton which inits when it is injected to other module
 *
 * The policy from server is like:
 */

/**
 * The defaultPolicy is used for debugging.
 */
/*
const defaultPolicy = {
   "broker_list": {
       "settings": {
         "server-list": [{
           "server": "10.117.46.28",
           "default": false,
           "description": "broker",
           "username": "administrator",
           "domain": "webclient3",
           "settings": {
              "enableHighResolution": false,
              "enableMultiMonitor": true,
              "enableWindowsKey": true,
              "enableWindowsDeleteKey": true,
              "enableUsbRedirection": true,
              "enableWebRTCRedirection": true,
              "enableScreenSharing": true,
              "enableFolderSharing": true,
              "timezoneSync": {
                 "isSync": false,
                 "timezone": "-00:00"
              },
              "resolution": {
                 "width": 600,
                 "height": 600
              },
              "editable": {
                 "enableHighResolution": true,
                 "enableMultiMonitor": true,
                 "enableWindowsKey": true,
                 "enableWindowsDeleteKey": true,
                 "enableWebRTCRedirection": true,
                 "enableScreenSharing": true,
                 "enableFolderSharing": false,
                 "timezoneSync": false
              },
              "usbAllowList": [
               {
                "vid": "2303",
                "pid": "9552"
               }, {
                "vid": "1256",
                "pid": "13103"
               }
              ],
              "usbBlockList": [{
                 "vid": "1364",
                 "pid": "4097"
               }, {
                 "vid": "4097",
                 "pid": "5216"
               }]
           },
         },{
            "server": "dev1b-westus2-cp102.azcp.horizon.vmware.com"
         },
         {
            "server": "dev1b-westus2-cp103.azcp.horizon.vmware.com"
         }],
         "common-setting": {
            "delayKeysWhenMoveInside": true,
            "forcePrivateMode": true,
            "urlFiltering": {
               "protocols": [{
                  "brokerHostname": "10.117.46.28",
                  "protocol": "http",
                  "remoteItem": "win2022-rdsh",
                  "agentRules": ".*youtube.com"
               },
               {
                  "brokerHostname": "10.117.46.28",
                  "protocol": "https",
                  "remoteItem": "win2022-rdsh",
                  "agentRules": ".*youtube.com"
               }]
            },
            "powerSetting": {
               "keepAwakeLevel": "display"
            },
            "allowDataSharing": false,
            "enableAnonymousLogin": false,
            "enableMultiMonitor": true,
            "allowFileDownload": false,
            "allowFileUpload": false,
            //"display": {"width":400, "height":500},
            //"display": "singleMonitorFullscreen",
            //"enableRTAVH264Codec": false,
            //"enableRTAVOpusCodec": false,
            //"enableRTAVDTX": false,
            //"hardwareAccelerationOption": "prefer-hardware",
            "display": "{width:400, height:500}",
            "enableWindowsKey": true,
            "enableHighResolution": true,
            "redirectSystemTray": true,
            "enableFileAssociation": false,
            "browserRedir": {
               "bcrEnabled": true,
               "enhBcrEnabled": true,
               "enableMediaStream": true,
               "ignoreCertErrorsBcr": false,
               "ignoreCertErrorsEnhBcr": true,
            },
            "ws1WebviewMode": "secure-webview",
            "splitUSB": {
               "allowList": [{
                  "device": "0554:1011",
                  "excludedInterface": ["01","03","0a"]
               }],

               "blockList": ["1111:2222"]
            },
            "autoForwardUSB" : true,
            "autoForwardUSBPolicy": [
               {
                  "desktop": "HorizonAutoUsbAll",
                  "autoConnectAllOnStart": false,
                  "autoConnectAllOnInsert": true,
                  "policy": [
                     {
                        "device": "04e8:332f",
                        "autoConnectOnStart": false,
                        "autoConnectOnInsert": false,
                     },
                     {
                        "device": "08ff:2550",
                        "autoConnectOnStart": false,
                        "autoConnectOnInsert": false,
                     }
                  ]
               }
            ],
            "enableNetworkIndicator": true,
            "enableBlastCodec": true,
            "networkStateConfig": {
               "networkStateTcpRttMSLow": 2,
               "networkStateTcpRttMSHigh": 400,
               "networkStateQualityScoreTcpThresholdGood": 85,
               "networkStateQualityScoreTcpThresholdPoor": 40,
               "networkStateRttCheckPeriodMs": 15000
            },
            "hideSecondaryServer": true,
            "editable": {
               "allowDataSharing": false,
               "enableAnonymousLogin": false,
               "enableMultiMonitor": true,
               "enableHighResolution": false,
               "autoForwardUSB" : true,
               "display": true,
               "redirectSystemTray": false
            }
         }
      }
   }
 };
*/

export interface PlainServer {
   address: string;
   description: string;
   settings: any;
   // uses the username/domain from google policy by default in the login page.
   username: string;
   domain: string;
   // uses the desktopId/applicationId for URL launch.
   desktopId: string;
   applicationId: string;
}

export class Model {
   private _brokers;
   private _commonSetting;

   constructor(serverList = [], commonSetting = {}) {
      this._brokers = serverList;
      this._commonSetting = commonSetting;
   }

   public getDefaultBroker = () => {
      for (let i = 0; i < this._brokers.length; i++) {
         const brokerConfig = this._brokers[i];
         if (brokerConfig["default"] === true) {
            return brokerConfig;
         }
      }
      return null;
   };

   public getBrokerByServer = (server: string) => {
      for (let i = 0; i < this._brokers.length; i++) {
         const brokerConfig = this._brokers[i];
         if (brokerConfig["server"] === server) {
            return brokerConfig;
         }
      }
      return null;
   };

   public getPlainServerList = (): PlainServer[] => {
      const list = [];

      this._brokers.forEach((brokerConfig) => {
         list.push({
            address: brokerConfig["server"],
            description: brokerConfig["description"] || "",
            settings: brokerConfig["settings"] || {},
            // uses the username/domain from google policy by default in the login page.
            username: brokerConfig["username"] || "",
            domain: brokerConfig["domain"] || "",
            // uses the desktopId/applicationId for URL launch.
            desktopId: brokerConfig["desktopId"] || "",
            applicationId: brokerConfig["applicationId"] || ""
         });
      });

      return list;
   };

   public getCommonAdminSettings = () => {
      return this._commonSetting;
   };
}

@Injectable({
   providedIn: "root"
})
export class PolicyModel {
   public static readonly BROKER_LIST_KEY = "broker_list";
   public static readonly SETTINGS_KEY = "settings";
   public static readonly SERVER_LIST_KEY = "server-list";
   public static readonly COMMON_SETTING_KEY = "common-setting";

   private killSwitchOn: boolean = true;
   private policyLoadCount: number = 0;
   private model: Model = null;

   constructor(private featureConfigs: FeatureConfigs) {
      this.featureConfigs.registerListener("KillSwitch-ServerURL", (switchOn) => {
         this.killSwitchOn = switchOn;
      });
   }

   public getLoadCount = () => {
      return this.policyLoadCount;
   };

   public setLoadCount = () => {
      this.policyLoadCount += 1;
   };

   public getModel = (): Promise<Model> => {
      return new Promise<Model>((resolve, reject) => {
         if (!this.killSwitchOn) {
            resolve(null);
            return;
         }

         if (this.model) {
            resolve(this.model);
         }

         chrome.storage.managed.get((policy) => {
            /**
             * The defaultPolicy is used for debugging.
             */
            /*
            const _commonSetting =
               defaultPolicy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY][
               PolicyModel.COMMON_SETTING_KEY
            ];
            const _serverList =
               defaultPolicy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY][
               PolicyModel.SERVER_LIST_KEY
            ];
            this.model = new Model(_serverList, _commonSetting);
            Logger.info("Google Policy:" + JSON.stringify(this.model), Logger.UNITY);
            resolve(this.model);
            return;
            */
            /**
             * For testing, you can use the "/support/mock-policy" project.
             * Running this project starts a server that returns custom policy.
             * When defaultPolicy is changed, there is no need to rebuild the chrome client.
             */
            /*
            fetch("http://127.0.0.1:3000/policy")
               .then(res => res.json())
               .then(mockPolicy => {
                  if (
                     !mockPolicy ||
                     !mockPolicy[PolicyModel.BROKER_LIST_KEY] ||
                     !mockPolicy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY]
                  ) {
                     Logger.info("Google Policy: null", Logger.UNITY);
                     resolve(null);
                     return;
                  }
                  const _commonSetting = mockPolicy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY][PolicyModel.COMMON_SETTING_KEY];
                  const _serverList = mockPolicy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY][PolicyModel.SERVER_LIST_KEY];
                  this.model = new Model(_serverList, _commonSetting);
                  Logger.info("Google Policy:" + JSON.stringify(this.model), Logger.UNITY);
                  resolve(this.model);
               }).catch(reject);
            return;
            */
            if (
               !policy ||
               !policy[PolicyModel.BROKER_LIST_KEY] ||
               !policy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY]
            ) {
               Logger.info("Google Policy: null", Logger.POLICY);
               resolve(null);
               return;
            }

            const commonSetting =
               policy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY][PolicyModel.COMMON_SETTING_KEY];
            const serverList =
               policy[PolicyModel.BROKER_LIST_KEY][PolicyModel.SETTINGS_KEY][PolicyModel.SERVER_LIST_KEY];
            if (
               (!serverList || serverList.length === 0) &&
               (!commonSetting || Object.getOwnPropertyNames(commonSetting).length === 0)
            ) {
               Logger.info("Google Policy: null", Logger.POLICY);
               resolve(null);
               return;
            }

            this.model = new Model(serverList, commonSetting);

            Logger.info("Google Policy:" + JSON.stringify(this.model), Logger.POLICY);
            resolve(this.model);
         });
      });
   };

   public buildLaunchUri = (brokerConfig) => {
      if (!brokerConfig || !brokerConfig.server) {
         throw "Invalid broker config received!";
      }

      // Pretend to be same format with html client
      let res = "https://" + brokerConfig.server + "/portal/webclient/index.html?";
      if (brokerConfig.username) {
         res += "userName=" + encodeURIComponent(brokerConfig.username) + "&";
      }
      if (brokerConfig.domain) {
         res += "domainName=" + encodeURIComponent(brokerConfig.domain) + "&";
      }
      if (brokerConfig.desktopId) {
         res += "desktopId=" + encodeURIComponent(brokerConfig.desktopId) + "&";
      }
      if (brokerConfig.applicationId) {
         res += "applicationId=" + encodeURIComponent(brokerConfig.applicationId) + "&";
      }

      // trim the last & or ? (if there are no params appended)
      res = res.substring(0, res.length - 1);
      return res;
   };
}
