/**
 * ******************************************************
 * Copyright (C) 2018-2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { signal } from "../../common/service/signal";

export enum SessionMsg {
   SESSION_CONNECTING_MSG = "sessionConnecting",
   SESSION_CONNECTED_MSG = "sessionConnected",
   SESSION_DISCONNECTED_MSG = "sessionDisconnected",
   SESSION_REMOVED_MSG = "sessionRemoved",
   SESSION_TOKEN_UPDATED_MSG = "reconnectTokenUpdated",
   SESSION_ACTIVE_MSG = "sessionActive",
   SESSION_ADDED_MSG = "sessionAdded",
   SESSION_CHANGED_MSG = "activeSessionChanged",
   SESSION_MODAL_MSG = "sessionMessage"
}

@Injectable({
   providedIn: "root"
})
export class RemoteSessionEventService extends signal {
   constructor() {
      super();
      this.addSignal(SessionMsg.SESSION_ADDED_MSG);
      this.addSignal(SessionMsg.SESSION_CHANGED_MSG);
      this.addSignal(SessionMsg.SESSION_CONNECTED_MSG);
      this.addSignal(SessionMsg.SESSION_CONNECTING_MSG);
      this.addSignal(SessionMsg.SESSION_DISCONNECTED_MSG);
      this.addSignal(SessionMsg.SESSION_REMOVED_MSG);
      this.addSignal(SessionMsg.SESSION_ACTIVE_MSG);
      this.addSignal(SessionMsg.SESSION_MODAL_MSG);
      this.addSignal("updateReloadingStatus");
      this.addSignal("sessionPropertyUpdate");
   }
}
