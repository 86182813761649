/**
 * ******************************************************
 * Copyright (C) 2012-2022 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

/**
 * connectToApplicationCommand.js --
 *
 * Called to setup and retrieve the parameters for a application connection.
 * The returned parameters should be used to launch an appropriate client for
 * the connection protocol, e.g. an RDP client when <protocol>RDP</protocol> is
 * returned.
 *    1. GetApplicationConnection
 *
 */

import { globalArray } from "../jscdkClient";
import Logger from "../../../core/libs/logger";
import GetApplicationConnectionHandler from "../controllers/getApplicationConnectionHandler";
import SetUserGlobalPrefHandler from "../controllers/setUserGlobalPrefHandler";
import Router from "../controllers/router";
import ConnectionRetryController from "../timer/connectionRetryController";
import { SelectApplicationAction } from "../../common/jscdk/jscdk-interface";

export default function ConnectToApplicationCommand() {}

/**
 * Invoked by JSCDKController
 *
 * @param selectApplicationAction[in]
 *        - its property 'method' has the value 'ConnectToApplication'
 */
ConnectToApplicationCommand.prototype.execute = function (selectApplicationAction: SelectApplicationAction) {
   let handlerList,
      getApplicationConnObject = globalArray["get-application-connection"],
      router = globalArray["router"],
      setPrefHandler = globalArray["set-user-global-preferences"],
      connectionRetryController = globalArray["connection-retry-controller"];

   const checkExist = function (handler) {
      return handler !== undefined;
   };

   if (!router) {
      Logger.info("router is null.");

      router = new Router();
      globalArray[router.name] = router;
   }

   if (selectApplicationAction.prefData) {
      if (!setPrefHandler) {
         setPrefHandler = new SetUserGlobalPrefHandler();
         globalArray[setPrefHandler.messageName] = setPrefHandler;
      }
      setPrefHandler.setRequestXML(selectApplicationAction.prefData);
   }

   if (!connectionRetryController) {
      connectionRetryController = new ConnectionRetryController();
      globalArray[connectionRetryController.globalName] = connectionRetryController;
   }
   connectionRetryController.setConnectingStatus(true);

   if (!getApplicationConnObject) {
      getApplicationConnObject = new GetApplicationConnectionHandler();
      globalArray[getApplicationConnObject.messageName] = getApplicationConnObject;
      globalArray[getApplicationConnObject.responseTag] = getApplicationConnObject;
   } else {
      getApplicationConnObject.resetData();
   }

   if (selectApplicationAction.disableCombine) {
      getApplicationConnObject.sendAlong();
   } else {
      getApplicationConnObject.combineWithPerf();
   }

   if (!selectApplicationAction.connectionRetry) {
      globalArray["connection-retry-action"] = selectApplicationAction;
   }

   getApplicationConnObject.setRequestXML(
      selectApplicationAction.applicationId,
      selectApplicationAction.protocol,
      selectApplicationAction.environmentInfo,
      selectApplicationAction.maximized,
      selectApplicationAction.launchParam,
      selectApplicationAction.multiSession,
      selectApplicationAction.callbackParams || {},
      selectApplicationAction.reverseToken || null,
      selectApplicationAction.rdsLicenseInfo || null
   );

   getApplicationConnObject.saveOriginId(selectApplicationAction.originId);

   getApplicationConnObject.setPreferences(selectApplicationAction.preferences);

   handlerList = getApplicationConnObject.composeHandlerList();
   handlerList = handlerList.filter(checkExist);
   router.postMessage(handlerList);
};
