/**
 * *****************************************************
 * Copyright 2020-2021 VMware, Inc.  All rights reserved.
 * ******************************************************
 *
 * @format
 */

import { NgModule } from "@angular/core";
import { NormalizationService } from "./normalization-service";
import { WmksBaseService } from "./wmks-base-service";
import { CoordinateConverter } from "./coordinate-converter";

@NgModule({
   declarations: [],
   providers: [NormalizationService, WmksBaseService, CoordinateConverter]
})
export class UtilsModule {}
