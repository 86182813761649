/**
 * ******************************************************
 * Copyright (C) 2021-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { Injectable } from "@angular/core";
import { WarningMessageService } from "../../../chrome-client/common/service/warning-message.service";
import { PolicyModel } from "../../../chrome-client/launcher/server-connect/policy-model";
import { CLIENT, clientUtil, Logger } from "../../../core/libs";
import { BusEvent, EventBusService } from "../../../core/services/event";
import { BrokerSessionStorageService } from "../../../core/services/storage/broker-session-storage.service";
import { ConnectionURIModel } from "../../../shared/common/model/connection-uri-model";
import { Ws1Service } from "../../../shared/common/service/ws1.service";

import util from "../../../shared/jscdk/util";

@Injectable({
   providedIn: "root"
})
export class HorizonWSOneService extends Ws1Service {
   /**
    * Service provider ID.
    * vIDM/WS1 passes the value when it launches client.
    * Refer to
    * https://confluence.eng.vmware.com/display/WOR/Horizon+Client+SP+Init+Flow+a.k.a+Fix+Access+Policy+support+in+Horizon
    */
   private spID: string = null;
   public enableWs1Webview: boolean = true;
   public serverType: string;
   public hideClientMainWindow: boolean = true;
   public timerForWaitWS1Value; // This value is for UT

   constructor(
      private brokerSessionStorageService: BrokerSessionStorageService,
      private connectionURIModel: ConnectionURIModel,
      private warningMessageService: WarningMessageService,
      private eventBusService: EventBusService,
      private policyModel: PolicyModel
   ) {
      super();
      this.spID = brokerSessionStorageService.read(CLIENT.SP_ID_FOR_WS1_KEY);
      this.timerForWaitWS1Value = this.waitForWS1Value();
   }

   public isWS1Mode = (): boolean => {
      if (clientUtil.isChromeClient()) {
         return util.isWS1Mode();
      }

      const sessionFlag = window.localStorage.getItem("ws1Mode");
      if (sessionFlag === "true") {
         return true;
      }
      return util.isWS1Mode();
   };

   public storeSpId = (spID) => {
      if (!spID) {
         return;
      }
      this.brokerSessionStorageService.write(CLIENT.SP_ID_FOR_WS1_KEY, spID);
      this.spID = spID;
   };

   /**
    * Refer to
    * https://confluence.eng.vmware.com/display/WOR/Horizon+Client+SP+Init+Flow+a.k.a+Fix+Access+Policy+support+in+Horizon
    */
   public redirectToWS1 = (ws1ServerName, windowObject) => {
      if (!ws1ServerName) {
         return;
      }
      ws1ServerName = ws1ServerName.toLowerCase();
      if (ws1ServerName.indexOf("https://") !== 0) {
         ws1ServerName = "https://" + ws1ServerName;
      }

      if (clientUtil.isChromeClient()) {
         this.handleWs1NavigationChromeClient(ws1ServerName);
         return;
      }

      let serverAddress = ws1ServerName;

      let appName;
      if (!!this.connectionURIModel.params && !!this.connectionURIModel.params.applicationId) {
         appName = this.connectionURIModel.params.applicationId;
      } else if (!!this.connectionURIModel.params && !!this.connectionURIModel.params.desktopId) {
         appName = this.connectionURIModel.params.desktopId;
      }

      // If there is no app to launch, just handle off to ws1 server
      if (!appName) {
         windowObject.location.href = serverAddress;
         return;
      }

      const serverAPI = "/SAAS/API/1.0/GET/apps/launch/sp-init/";
      const spURLParam = "spURL=" + windowObject.location.host;

      // Append app name
      serverAddress += serverAPI + encodeURIComponent(appName);

      // Append spURL
      serverAddress += "?" + spURLParam;

      // Append spID
      if (this.spID) {
         serverAddress += "&spID=" + this.spID;
      }

      /**
       * Append relayInfo
       * Server side decode is placed in:
       * com.vmware.vdi.installer.ui.filters.RelayInfoFilter
       */
      const relayInfo = this.connectionURIModel.generateRelayInfo();
      if (relayInfo) {
         serverAddress += "&" + relayInfo;
      }

      // Tell vIDM we need launch html access client rather than native client
      serverAddress += "&clientType=BROWSER";

      windowObject.location.href = serverAddress;
   };

   private handleWs1NavigationChromeClient = (ws1ServerName: string) => {
      // When it is UAG with IDP or WS1, redirect to complete the auth
      const url = ws1ServerName.replace("/portal/nativeclient", "/portal/chromeclient");
      if (url.indexOf("/portal/chromeclient") > -1) {
         this.serverType = "uag";
      } else {
         this.serverType = "ws1";
      }

      this.policyModel.getModel().then((model) => {
         if (model) {
            const commonAdminSettings = model.getCommonAdminSettings();
            const ws1WebviewMode: string = commonAdminSettings.ws1WebviewMode;
            if (ws1WebviewMode === "browser") {
               this.enableWs1Webview = false;
            }
         }

         if (this.enableWs1Webview) {
            // Only support ws1 webview in kiosk session
            // Still use previous solution for ws1 in non-kiosk session
            if (window.isKioskSession || this.serverType === "uag") {
               Logger.info("This is a navigation for UAG/IDP and WS1 in kiosk");
               this.hideClientMainWindow = false;
               this.eventBusService.dispatch(
                  new BusEvent.ShowWs1WebViewMsg({
                     show: true,
                     url: url,
                     serverType: this.serverType
                  })
               );
            } else {
               Logger.info("This is a navigation for WS1 in non-kiosk session");
               clientUtil.closeAllWindows();
               window.open(url);
            }
         } else {
            Logger.info("This is a navigation for kill-switch as webview is disabled.");
            clientUtil.closeAllWindows();
            window.open(url);
         }
      });
   };

   public isLaunchedFromWS1 = () => {
      /**
       * If we have SAMLart and horizonId in URI, then we say it is launched
       * from WS1
       */
      return (
         !!this.connectionURIModel.params &&
         !!this.connectionURIModel.params.samlArt &&
         !!this.connectionURIModel.params.horizonId
      );
   };

   private waitForWS1Value = () => {
      if (!clientUtil.isChromeClient()) {
         return;
      }
      const waitTimer = setInterval(() => {
         if (this.isWS1Mode() !== undefined) {
            clearInterval(waitTimer);
            if (
               window.chromeClient &&
               window.chromeClient.onWS1ModeInited &&
               typeof window.chromeClient.onWS1ModeInited === "function"
            ) {
               const ws1ModeEnabled = this.isWS1Mode();
               window.chromeClient.onWS1ModeInited(ws1ModeEnabled, this.hideClientMainWindow);
               Logger.info("adjust client display according to ws1ModeEnabled: " + ws1ModeEnabled);
               if (clientUtil.isChromeClient()) {
                  this.warningMessageService.init();
               }
            } else {
               Logger.error("onSDKModeInited no usable when init SDK service");
            }
         }
      }, 1000);
      return waitTimer;
   };
}
