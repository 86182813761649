/**
 * ******************************************************
 * Copyright (C) 2020 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import { clientUtil } from "@html-core";
import { HorizonClientInfo, ClientInfoService } from "../../../shared/common/service/client-info.service";
import { getClientInfo } from "./html5-client-info-v2.service";

const LOG_LEVEL: string = "logLevel";

export class HTML5ClientInfoService extends ClientInfoService {
   public getConfigInfo = async (): Promise<HorizonClientInfo> => {
      if (clientUtil.isTitanClient()) {
         this.clientInfo = getClientInfo();
      } else {
         if (!this.clientInfo) {
            this.clientInfo = await $.ajax({
               type: "GET",
               url: "../info.jsp",
               dataType: "json",
               async: true,
               cache: false
            });
         }
      }

      // Provide a debug method to change Log Level inside the browser.
      if (window && window.localStorage) {
         const logLevel = window.localStorage.getItem(LOG_LEVEL);
         if (logLevel && typeof logLevel === "string" && Number.isNaN(logLevel)) {
            if (Number(logLevel) >= 0 && Number(logLevel) <= 4) {
               this.clientInfo.logLevel = Number(logLevel);
            }
         }
      }
      return this.generateClientInfo(this.clientInfo);
   };
}
