/**
 * ******************************************************
 * Copyright (C) 2020-2023 VMware, Inc. All rights reserved.
 * *******************************************************
 * Enter point of angular version of App
 * @format
 */

import { Component } from "@angular/core";
import { AppViewService } from "../../shared/common/service/app-view.service";
import { ModalDialogService } from "../../shared/common/commondialog/dialog.service";
import { FullscreenService } from "../../shared/utils/fullscreen-service";
import { BaseIndexComponent } from "../../shared/app-center.component";
import { BaseViewService } from "../../shared/common/service/base-view.service";
import { ClientModeService } from "../../shared/common/service/client-mode.service";
import { ConnectionURIModel } from "../../shared/common/model/connection-uri-model";
import { EventBusService } from "../../core/services/event/event-bus.service";
import { ViewClientModel } from "../../shared/common/model/viewclient-model";
import { Localize } from "../../shared/common/service/localize.service";
import { KillSwitchService } from "../../shared/common/service/kill-switch.service";
import { PreDataSetModel } from "../../shared/common/model/pre-data-set-model";
import { ConnectionServerModel } from "../../shared/common/model/connection-server-model";
import { SessionDataService } from "../../shared/common/service/session-data.service";
import { ClientInfoService } from "../../shared/common/service/client-info.service";
import { RootModel } from "../../shared/common/model/root-model";
import { DeactivateGuardService } from "../../shared/view/deactive-guard.service";
import { DebugConfigurationModel } from "../../shared/common/model/debug-configuration-model";
import JSCDKClientController from "../../shared/jscdk/controllers/jscdkClientController";
import { ExtendedApiService } from "../common/service/extended-api.service";
import { CommonSDKService } from "../../shared/common/service/sdk.service";

@Component({
   selector: "app-root",
   templateUrl: "./app-root.component.html",
   styleUrls: []
})
export class AppRootComponent extends BaseIndexComponent {
   public title: string = "VMware Horizon HTML Access Client";

   constructor(
      fullscreenService: FullscreenService,
      baseViewService: BaseViewService,
      clientModeService: ClientModeService,
      connectionURIModel: ConnectionURIModel,
      eventBusService: EventBusService,
      viewClientModel: ViewClientModel,
      modalDialogService: ModalDialogService,
      localize: Localize,
      killSwitchService: KillSwitchService,
      preDataSetModel: PreDataSetModel,
      connectionServerModel: ConnectionServerModel,
      sessionDataService: SessionDataService,
      appViewService: AppViewService,
      clientInfoService: ClientInfoService,
      rootModel: RootModel,
      debugConfigurationModel: DebugConfigurationModel,
      deactivateGuardService: DeactivateGuardService,
      private extendedApiService: ExtendedApiService,
      private sdkService: CommonSDKService
   ) {
      super(
         fullscreenService,
         baseViewService,
         clientModeService,
         connectionURIModel,
         debugConfigurationModel,
         eventBusService,
         viewClientModel,
         modalDialogService,
         localize,
         killSwitchService,
         preDataSetModel,
         connectionServerModel,
         sessionDataService,
         appViewService,
         clientInfoService,
         rootModel,
         deactivateGuardService
      );

      this._initServicesAndModels().then(() => {
         if (this.needToDisplayLogError) {
            this.needToDisplayLogError = false;
            this.modalDialogService.showError({
               data: {
                  titleKey: "WARNING",
                  contentKey: "CLIENT_LOG_INVALID"
               }
            });
         }
         /**
          * If input URL is https://xxxxx/portal/webclient/index.html#/desktop
          * that means user wants to enter desktop mode directly.
          * Otherwise, redirect page to launcher.
          */
         setTimeout(() => {
            if (window.location.hash.indexOf("#/desktop") === 0) {
               this.clientModeService.switchToDesktopMode();
            } else {
               this.clientModeService.switchToLauncherMode();
            }
         });
         this.extendedApiService.init();
      });
      JSCDKClientController.init();
   }

   private _initServicesAndModels = async () => {
      /**
       * Init work begins!
       * First retrieve client info from server/local for both clients, to init
       * view client model
       */
      let clientInfo;
      try {
         clientInfo = await this.clientInfoService.getConfigInfo();
         this.viewClientModel.initClientInfo(clientInfo);
      } catch (e) {
         this.viewClientModel.onFailReadingClientInfo();
      }

      await this.preDataSetModel.getAllDataValue();

      /**
       * Init kill switch after clientInfo inited. clientInfo.feature is only
       * used by html client in this case
       */
      this.killSwitchService.init(!!clientInfo && !!clientInfo.feature ? clientInfo.feature : {});

      await this.localize.setLocaleAndLoad(this.viewClientModel.acceptLanguage);

      /**
       * Html client inits connectionServerModel immediately, while
       * chrome client inits it after choosing server
       */
      this.connectionServerModel.onHostChosen(window.location.host);
      this.sessionDataService.getSessionData();

      // This is for IE11 only
      if (this._isLowerThanIE11()) {
         window.addEventListener("focus", () => {
            this._isFocusedWindow = true;
         });
         window.addEventListener("blur", () => {
            this._isFocusedWindow = false;
         });
      }

      this.sdkService.init().then(() => {
         setTimeout(() => {
            if (window.location.hash !== "#/desktop") {
               this.baseViewService.changeLocation("/home");
            } else {
               this.appViewService.jumpToDesktop();
            }
         });
      });
   };
}
