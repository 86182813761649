/**
 * ******************************************************
 * Copyright (C) 2020-2021 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

import Logger from "../../../core/libs/logger";
import { globalArray } from "../../../shared/jscdk/jscdkClient";
import { COOKIE, LocalStorageService, EventBusService } from "@html-core";
import { LaunchService } from "../../../shared/launcher/launchitems/session-launch.service";
import { MultiSiteService } from "../../../shared/common/service/multisite.service";
import { ConnectionServerModel } from "../../../shared/common/model/connection-server-model";
import { TransitionDataModel } from "../../../shared/common/model/transition-data-model";
import { SessionDataService } from "../../../shared/common/service/session-data.service";
import { ViewService } from "../../../shared/launcher/common/view-service";
import { Injectable } from "@angular/core";

@Injectable()
export class Html5LaunchService extends LaunchService {
   constructor(
      private connectionServerModel: ConnectionServerModel,
      private localStorageService: LocalStorageService,
      private transitionDataModel: TransitionDataModel,
      private sessionDataService: SessionDataService,
      private viewService: ViewService,
      private _eventBusService: EventBusService
   ) {
      super(_eventBusService);
   }

   public isDesktopLaunched = (wmksKey: string): boolean => {
      return false;
   };

   public onNewAppReuseSession = (sessionKey: string) => {};

   public createNewInstance = async (data, jscdkWrapper) => {
      const currentController = data.controller;
      const launchData = data.launchData;

      currentController.tearDown();

      if (launchData.redirectProperties) {
         try {
            MultiSiteService.redirectAndStartSession(launchData.redirectProperties);
            return;
         } catch (e) {
            Logger.warning("Fail to redirect due to: " + e);
         }
      }

      if (launchData.enableUsb === "true") {
         this.transitionDataModel.enableUsb = true;
         this.transitionDataModel.usbTicket = launchData.usbTicket;
      }
      /**
       * See details in bug 2222177.
       * Below codes should never be executed, but keep them there to avoid
       * possible regression related to 3rd party integration.
       *
       * but not overwrite the value from URL anymore.
       */
      if (!!launchData.userName && !this.connectionServerModel.username) {
         // Get valid username
         const containsAt = launchData.userName.indexOf("@");
         if (containsAt !== -1) {
            this.connectionServerModel.username = launchData.userName.substr(0, containsAt);
         } else {
            this.connectionServerModel.username = launchData.userName;
         }
         this.localStorageService.set(COOKIE.USER_NAME, this.connectionServerModel.username);
         Logger.warning("The username is not set properly, fall back to launch response");
      }
      if (!!launchData.domainName && !this.connectionServerModel.domain) {
         this.connectionServerModel.domain = launchData.domainName;
         this.localStorageService.set(COOKIE.DOMAIN_NAME, this.connectionServerModel.domain);
         Logger.warning("The domain is not set properly, fall back to launch response");
      }
      // Save item-launching data from XML response
      this.transitionDataModel.isApplicationSession = launchData.isApplicationSession;
      this.transitionDataModel.sessionId = launchData.sessionId;
      this.transitionDataModel.isShadow = launchData.isShadow;
      this.transitionDataModel.blastURL = launchData.url;
      const router = globalArray["router"];
      this.sessionDataService.updateBrokerUrl(router.brokerUrl);
      $(".ui-body").addClass("ui-full-page");
      this.viewService.goBlastPage();
   };
}
